/* eslint-disable react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const LaptopAndAbove = require('../../../../../../../common/react/media-queries/LaptopAndAbove.react');

const Section = ({ children, left, right }) => (
  <div className="briefing-section">
    <LaptopAndAbove>
      <div className="briefing-section__left">{left}</div>
    </LaptopAndAbove>
    <div className="briefing-section__center">{children}</div>
    <LaptopAndAbove>
      <div className="briefing-section__right">{right}</div>
    </LaptopAndAbove>
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
  left: PropTypes.node,
  right: PropTypes.node,
};

module.exports = Section;
