module.exports.GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
module.exports.GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

module.exports.FILTER_PROJECTS_REQUEST = 'FILTER_PROJECTS_REQUEST';
module.exports.FILTER_PROJECTS_SUCCESS = 'FILTER_PROJECTS_SUCCESS';

module.exports.UPDATE_VISIBLE_MONTHS_REQUEST = 'UPDATE_VISIBLE_MONTHS_REQUEST';
module.exports.UPDATE_VISIBLE_MONTHS_SUCCESS = 'UPDATE_VISIBLE_MONTHS_SUCCESS';

module.exports.PROJECT_SAVE_REQUEST = 'PROJECT_SAVE_REQUEST';
module.exports.PROJECT_SAVE_SUCCESS = 'PROJECT_SAVE_SUCCESS';

module.exports.HIDE_MODAL = 'HIDE_MODAL';
module.exports.SHOW_MODAL_CANNOT_SAVE = 'SHOW_MODAL_CANNOT_SAVE';
module.exports.SHOW_MODAL_CANNOT_AUTO_DISTRIBUTE_PAST = 'SHOW_MODAL_CANNOT_AUTO_DISTRIBUTE_PAST';
module.exports.SHOW_MODAL_EXPORT = 'SHOW_MODAL_EXPORT';

module.exports.EXPORT_REQUEST = 'EXPORT_REQUEST';
module.exports.EXPORT_SUCCESS = 'EXPORT_SUCCESS';

module.exports.UPDATE_PROJECT_DURATION = 'UPDATE_PROJECT_DURATION';

module.exports.HIDE_PROJECT_ACTIONS_DROPDOWN = 'HIDE_PROJECT_ACTIONS_DROPDOWN';
module.exports.SHOW_PROJECT_ACTIONS_DROPDOWN = 'SHOW_PROJECT_ACTIONS_DROPDOWN';

module.exports.APPLY_AUTO_DISTRIBUTION = 'APPLY_AUTO_DISTRIBUTION';
