const React = require('react');

module.exports = class StatusPortion extends React.Component {
  getStyle() {
    return { backgroundColor: this.props.color, width: `${this.props.ratio}%` };
  }

  render() {
    return (
      <span className="project-canvas-spreadsheet__cell--summary-status" style={this.getStyle()}>&nbsp;</span>
    );
  }
};
