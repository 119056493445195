/* eslint-disable eqeqeq */
const React = require('react');

class LevelType extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props.label);
  }

  render() {
    const isActive = this.props.activeType == this.props.label;
    const className = isActive ? `${this.props.className} switch__button--active` : this.props.className;

    return (
      <div className={className} onClick={this.handleClick}>
        {this.props.label}
        {' '}
      </div>
    );
  }
}

module.exports = LevelType;
