const React = require('react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const DraggableArea = require('../../containers/job-order-template/DraggableArea');

const EnabledView = ({ enable }) => {
  function handleDisableClick() {
    const enabled = false;
    enable(enabled);
  }

  return (
    <div>
      <Button onClick={handleDisableClick}
        className="company-settings__button company-settings__button--blue company-settings-margin-top-xl">
        Disable
      </Button>
      <DraggableArea />
    </div>
  );
};

module.exports = EnabledView;
