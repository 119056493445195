const React = require('react');
const withRandomize = require('../../../../common/HOCs/withRandomize');

class Headline extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = [
      'below you will find a summary of the main things that happened last week. Take a look!',
      'managing everything can be complicated and it isn’t always possible to have a complete picture of all of the relevant updates. For this reason, we prepared this weekly briefing for you. Enjoy!',
      'another week is starting, but first, take a look at this. We carefully prepared a briefing for you with the most important information to know about the week that just ended.',
      'the weeks go by so quickly, we know, but it is still essential not to miss anything which might fall through the cracks. For this reason, you can find the essentials in the briefing that we lovingly wrote for you.',
      'we have a lot of insights to share, but since our time is limited, and to understand concepts well, we need to connect the dots. Don’t worry, we have you covered with this briefing that brings together everything.',
    ];
  }

  render() {
    return (
      <span className="briefing-content__atom">{this.props.randomize(this.alternatives)}</span>
    );
  }
}

module.exports = withRandomize(Headline);
