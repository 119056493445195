const { connect } = require('react-redux');
const Component = require('../components/KnowledgeTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'knowledge').length > 0,
  tags: state.filteredInterests,
  activeTags: state.activeInterests,
  employees: state.filteredInterestEmployees,
  empSize: state.loadInterestEmployees.size,
  empPage: state.loadInterestEmployees.page,
  name: 'interests',
});

const mapDispatchToProps = (dispatch) => ({
  onTagClick: (interest) => dispatch(actions.toggleInterest(interest)),
  filterTags: (keyword) => dispatch(actions.filterInterests(keyword)),
  filterEmployees: (keyword) => dispatch(actions.filterInterestEmployees(keyword)),
  loadEmployees: (size, page) => dispatch(actions.loadInterestEmployees(size, page)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
