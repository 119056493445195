const constants = require('./constants');
const { getBillingGroupTotalK, getTotalInvoiced, getTotalPlanned, getPlanAuto, getEstimate, getBudgetRate, canBeSaved } = require('./services/invoicePlanManager');

class InvoicePlanReducer {
  /**
   * Return the state with additional plan information,
   * when the project is not linked to a billing group
   * @param state
   * @param isLinked
   * @returns {(*&{totalMasterCurrency: *, totalPlanned: *, autoPlan: {}, convertedEstimate: *, totalInvoiced: *})|*}
   */
  static initPlan(state, isLinked) {
    if (isLinked) return state;
    return {
      ...state,
      totalInvoiced: getTotalInvoiced(state.invoices, state.currency),
      totalPlanned: getTotalPlanned(
        state.plan.plan_amounts,
        state.plan.status, state.plan.total,
        state.currency,
        state.project.budget !== null ? state.project.budget.exchange_rate : null,
      ),
      autoPlan: getPlanAuto(state.project, state.plan.total),
      convertedEstimate: getEstimate(
        state.plan.total,
        state.currency,
        getBudgetRate(state.project),
      ),
      totalMasterCurrency: getTotalPlanned(
        state.plan.plan_amounts,
        state.plan.status, state.plan.total,
      ),
      view: 'full', // full or compact calendar view
      canBeSaved: false, // Whether the plan needs to be saved and can be saved
    };
  }

  constructor(state) {
    this.state = {
      ...InvoicePlanReducer.initPlan(state, state.isLinked),
      waitingFor: [], // contains a key for each pending request
      modalToShow: null,
      modalData: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    let updatedPlan;
    let totalPlanned;
    let totalPlannedMasterCurrency;
    switch (action.type) {
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.GET_INVOICE_PLAN_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-plan'),
        };
      }
      case constants.GET_INVOICE_PLAN_SUCCESS: {
        return {
          ...state,
          ...InvoicePlanReducer.initPlan({ ...state, ...action.plan }, action.isLinked),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-plan'),
          isLinked: action.isLinked,
        };
      }
      case constants.SAVE_PLAN_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save'),
        };
      }
      case constants.SAVE_PLAN_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save'),
          plan: action.plan,
          canBeSaved: false,
        };
      }
      case constants.UPDATE_PLAN: {
        if (action.plan && action.plan.amount) {
          // Update existing plan in given month
          updatedPlan = state.plan.plan_amounts ? {
            ...state.plan.plan_amounts,
            [action.date]: action.plan,
          } : { [action.date]: action.plan };
        } else if (state.plan.plan_amounts) {
          // Remove plan of given month
          ({ ...updatedPlan } = state.plan.plan_amounts);
          delete updatedPlan[action.date];
        }
        const budgetExchangeRate = state.project.budget ? state.project.budget.exchange_rate : null;

        totalPlanned = getTotalPlanned(updatedPlan, state.plan.status,
          state.plan.total, state.currency,
          budgetExchangeRate, state.invoices);
        totalPlannedMasterCurrency = getTotalPlanned(updatedPlan,
          state.plan.status, state.plan.total);

        return {
          ...state,
          plan: { ...state.plan, plan_amounts: updatedPlan },
          totalPlanned,
          totalMasterCurrency: totalPlannedMasterCurrency,
          canBeSaved: canBeSaved(totalPlanned, state.convertedEstimate),
        };
      }
      case constants.CHANGE_STATUS: {
        const budgetExchangeRate = state.project.budget ? state.project.budget.exchange_rate : null;
        totalPlanned = getTotalPlanned(state.plan.plan_amounts, action.mode,
          state.plan.total, state.currency, budgetExchangeRate, state.invoices);
        totalPlannedMasterCurrency = getTotalPlanned(state.plan.plan_amounts,
          action.mode, state.plan.total);
        return {
          ...state,
          plan: { ...state.plan, status: action.mode },
          totalPlanned,
          totalMasterCurrency: totalPlannedMasterCurrency,
          canBeSaved: canBeSaved(totalPlanned, state.convertedEstimate),
        };
      }
      case constants.CHANGE_VIEW: {
        return {
          ...state,
          view: action.view,
        };
      }
      case constants.DELEGATE_PROJECT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delegate'),
        };
      }
      case constants.DELEGATE_PROJECT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delegate'),
          isLinked: true,
          billingGroup: action.group.id,
          masterProject: action.group.master,
        };
      }
      case constants.DELEGATE_PROJECT_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delegate'),
        };
      }
      case constants.REVOKE_DELEGATION_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('revoke-delegation'),
        };
      }
      case constants.REVOKE_DELEGATION_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'revoke-delegation'),
        };
      }
      case constants.REVOKE_DELEGATION_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'revoke-delegation'),
        };
      }
      case constants.UPDATE_BILLING_GROUP: {
        const plan = state.plan ? {
          ...state.plan,
          billing_group: action.billingGroup,
          total: getBillingGroupTotalK(action.billingGroup, state.project.estimate),
        } : null;
        return {
          ...state,
          ...InvoicePlanReducer.initPlan({ ...state, plan }, false),
        };
      }
      default:
        return state;
    }
  }
}

module.exports = InvoicePlanReducer;
