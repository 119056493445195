/* eslint-disable class-methods-use-this */
/* eslint-disable guard-for-in,no-restricted-syntax,react/sort-comp,
 react/no-access-state-in-setstate,consistent-return */
const React = require('react');

module.exports = class Project extends React.Component {
  getFormattedMoneyValue(money) {
    return numeral(money).format('0,0');
  }

  render() {
    return (
      <li className="client-person-project">
        <div className="client-person__projects-list--name wethod-list-column">
          {this.props.name}
        </div>
        <div className="client-person__projects-list--pm wethod-list-column">
          {this.props.pm}
        </div>
        <div className="client-person__projects-list--account wethod-list-column">
          {this.props.account}
        </div>
        <div className="client-person__projects-list--value wethod-list-column">
          {this.getFormattedMoneyValue(this.props.value)}
          {' '}
          K
        </div>
        <div className="client-person__projects-list--probability wethod-list-column">
          {this.props.probability}
          {' '}
          %
        </div>
      </li>
    );
  }
};
