const React = require('react');
const View = require('./MilestoneBarView.react');
const Edit = require('./MilestoneBarEdit.react');

module.exports = ({
  style, canEdit, onResizeStart, ...rest
}) => {
  if (canEdit) {
    return <Edit style={style} onResizeStart={onResizeStart} {...rest} />;
  }
  return <View style={style} {...rest} />;
};
