/* eslint-disable react/sort-comp,react/no-did-update-set-state */
const React = require('react');
const PropTypes = require('prop-types');

/**
 * An enhanced text input with:
 *
 * VALIDATION
 * Pass a comma separated string of "constraints" that will be checked at any blur. Pass an
 * "onValidation" callback to get validation errors. Supported constraints: email, required.
 *
 * EDIT VALUE FROM PARENT
 * Just pass a new "value" to reset the input. This let you use this component by embedding it into
 * another.
 *
 * @type {module.TextInput}
 */
class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value : '',
      constraints: this.props.constraints ? this.props.constraints.split(',') : [],
      isValid: false,
      placeholder: this.props.placeholder,
    };

    this.errors = [];
    this.state.isValid = this.isValid();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const value = nextProps.value ? nextProps.value : '';
      this.setState({
        value,
        isValid: this.isValid(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.setState({ isValid: this.isValid() });
    }
  }

  email() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return this.state.value === '' || emailRegex.test(this.state.value);
  }

  required() {
    return this.state.value.trim() !== '';
  }

  isValid() {
    this.errors = this.validate();
    if (this.props.onValidate) {
      this.props.onValidate(this.props.name, this.errors);
    }
    return this.errors.length === 0;
  }

  validate() {
    const errors = [];
    for (let i = 0; i < this.state.constraints.length; i++) {
      if (!this[this.state.constraints[i]]()) {
        errors.push(this.state.constraints[i]);
      }
    }
    return errors;
  }

  afterChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, this.state.value);
    }
  }

  handleChange(e) {
    if (!this.props.disabled) {
      this.setState({ value: e.target.value }, this.afterChange.bind(this));
    }
  }

  needsPlaceholder() {
    return this.state.value === '';
  }

  handleBlur(e) {
    if (this.needsPlaceholder()) {
      this.setState({ placeholder: this.props.placeholder });
    }
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value, e);
    }
  }

  blur() {
    this.input.blur();
  }

  focus() {
    this.input.focus();
  }

  handleFocus(e) {
    this.setState({ placeholder: '' });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  isRequired() {
    return this.state.constraints.indexOf('required') !== -1;
  }

  getClassName() {
    let name = 'wethod-input';
    if (this.isRequired()) {
      name += ' wethod-input--required';
    }
    return name;
  }

  render() {
    return (
      <span className={this.getClassName()} ref={this.props.rootRef}>
        <input type="text"
          ref={(input) => {
            this.input = input;
          }}
          disabled={this.props.disabled}
          placeholder={this.state.placeholder}
          value={_.unescape(this.state.value)}
          onChange={this.handleChange.bind(this)}
          onKeyUp={this.props.onKeyUp}
          onBlur={this.handleBlur.bind(this)}
          onFocus={this.handleFocus.bind(this)} />
      </span>
    );
  }
}

TextInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  constraints: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  rootRef: PropTypes.func,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  constraints: null,
  onValidate: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  disabled: false,
  onKeyUp: null,
  rootRef: null,
};

module.exports = TextInput;
