const React = require('react');
const PrimaryInfo = require('./TaskPrimaryInfo.react');
const Name = require('./TaskName.react');
const Quantity = require('./ContingencyTaskQuantity.react');
const Levels = require('./TaskLevels.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const MoneyCell = require('../Cell/MoneyCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const Task = require('./Task.react');
const Price = require('../Cell/PriceCell.react');
const ExternalCosts = require('../../../containers/Calculator/Cell/ExternalCostHeaderCell');
const IconCell = require('../Cell/IconCell.react');

const ContingencyTask = ({
  levels, price, days, quantity, editable, previous,
  updateTaskInfo, id, column,
}) => {
  function saveQuantity(amount) {
    updateTaskInfo(id, { product_quantity: amount });
  }

  if (column === 'left') {
    return (
      <Task>
        <PrimaryInfo>
          <Name>Contingency</Name>
          <Quantity editable={editable}
            id="task-contingency-name"
            save={saveQuantity}
            previousValue={previous.quantity}>{quantity}
          </Quantity>
        </PrimaryInfo>
      </Task>
    );
  }
  if (column === 'center') {
    return (
      <Task>
        <PrimaryInfo>
          <Levels items={levels}
            previousItems={previous.levels} />
        </PrimaryInfo>
      </Task>
    );
  }
  return (
    <Task className="wethod-budget-task__content--right-column">
      <PrimaryInfo className="wethod-budget-task__primary-info--right-column">
        <ExternalCosts value={0} />
        <PercentageCell />
        <DaysCell>{days}</DaysCell>
        <MoneyCell />
        <Price>{price}</Price>
        <IconCell />
        <MoreOptions />
      </PrimaryInfo>
    </Task>
  );
};

module.exports = ContingencyTask;
