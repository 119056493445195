const React = require('react');
const Content = require('./CalendarColumns.react');
const Header = require('./CalendarHeader.react');
const Rows = require('./Rows.react');

module.exports = class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { width: 0 };
  }

  getRowsCount() {
    const itemsCount = this.props.rows.length;
    const headerItem = 1;
    const itemCreator = this.props.canEdit;

    return itemsCount + headerItem + itemCreator;
  }

  getContentHeight() {
    return this.getRowsCount() * 50;
  }

  updateWidth(width) {
    this.setState({ width });
    this.props.updateCalendarWidth(width);
  }

  render() {
    return (
      <div className="project-canvas-gantt__calendar" style={{ width: this.state.width }}>
        <Content columns={this.props.columns}
          type={this.props.type}
          height={this.getContentHeight()} />
        <Header
          columns={this.props.columns}
          type={this.props.type}
          updateCalendarWidth={this.updateWidth.bind(this)} />
        <Rows rows={this.props.rows}
          editBar={this.props.editBar}
          pointWidth={this.props.pointWidth}
          pointType={this.props.pointType} />
      </div>
    );
  }
};
