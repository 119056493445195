const React = require('react');
const Loader = require('../Loader/Loader.react');

const LoadingItem = () => (
  <li className="wethod-search-select__item wethod-search-select__item--loading">
    <Loader />
  </li>
);

module.exports = LoadingItem;
