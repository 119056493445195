/* eslint-disable react/sort-comp,no-param-reassign,react/jsx-no-bind,consistent-return,class-methods-use-this */
const React = require('react');
const DateInput = require('../../../../../../../../common/react/DateInput.react');
const PhoneInput = require('../../../../../../../../common/react/PhoneInput.react');
const TextInput = require('../../../../../../../../common/react/TextInput.react');
const Menu = require('../../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const Item = require('../../../../../../../../common/react/Menu/ListMenu/MenuItem.react');

module.exports = class ContactInfoBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      email: this.props.email,
      telephone: this.props.telephone,
      linkedin: this.props.linkedin,
      gender: this.props.gender,
      birthday: this.props.birthday ? moment(this.props.birthday).format('DD/MM/YYYY') : null,
      errors: [],
    };
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  /**
   * @see https://stackoverflow.com/questions/15491894/regex-to-validate-date-format-dd-mm-yyyy
   * @param date
   * @returns {boolean}
   */
  isValidBirthday(date) {
    const regex = new RegExp('^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$');
    const isValidFormat = regex.test(date);
    const isFuture = moment(date).isSameOrAfter(moment());

    return isValidFormat && !isFuture;
  }

  onValidate(name, errors) {
    if (this.state.errors.length > 0 && errors.length === 0) { // Reset errors
      this.setState({ errors: [] });
    } else if (this.state.errors.length === 0 && errors.length > 0) { // Add errors
      this.setState({ errors });
    }
  }

  onChange(name, value) {
    const nextState = { ...this.state };
    nextState[name] = value;
    this.setState({ ...nextState });
  }

  handleBirthdayBlur() {
    const value = this.state.birthday;
    if (value && value.trim !== '') {
      const date = moment(value, 'DD/MM/YYYY');
      if (date.isValid()) {
        const formattedDate = date.format('DD/MM/YYYY');

        if (!this.isValidBirthday(value)) {
          this.setState({
            errors: ['date'],
            birthday: formattedDate,
          });
        } else {
          this.setState({
            errors: [],
            birthday: formattedDate,
          });
        }
      } else {
        this.setState({ errors: ['date'] });
      }
    } else if (this.state.errors.length > 0) {
      this.setState({ errors: [] });
    }
  }

  onSaveClick() {
    const { errors, ...contactInfo } = this.state;
    if (!errors || errors.length === 0) {
      const birthday = contactInfo.birthday ? moment(contactInfo.birthday, 'DD/MM/YYYY')
        .format('YYYY-MM-DD') : null;
      this.props.editInfo({
        ...contactInfo,
        birthday,
        bio: this.props.bio,
        id: this.props.id,
      });
    }
  }

  getSaveStyle() {
    let style = 'wethod-button';
    if (this.state.errors.length > 0) {
      style += ' disabled';
    }
    return style;
  }

  getFeedback() {
    if (this.props.waiting) {
      return <div className="profile-contact-info__feedback">Saving...</div>;
    }
    return null;
  }

  getGenderList() {
    if (this.props.genderList) {
      return this.props.genderList.map((gender) => (
        <Item key={gender}
          onClick={() => this.onChange('gender', gender)}>{gender}
        </Item>
      ));
    }
  }

  getSelectedGender() {
    if (this.state.gender) {
      return <span className="profile-contact-info__gender">{this.state.gender}</span>;
    }

    return <span className="profile-contact-info__placeholder">Select your gender</span>;
  }

  getDateHelperText() {
    if (this.state.errors.length > 0) {
      return (
        <span className="profile-contact-info__input-helper-text
       profile-contact-info__input-helper-text--error">Please, insert a valid date
        </span>
      );
    }
  }

  getInputStyle() {
    let style = 'profile-contact-info__input';
    if (this.state.errors.length > 0) {
      style += ' profile-contact-info__input--error';
    }

    return style;
  }

  render() {
    return (
      <div>
        <div className="profile-contact-info--editable">
          <div className="profile-contact-info__input disabled">
            <div className="profile-contact-info__icon">
              <div className="wethod-icon-mail wethod-icon-mail--black" />
            </div>
            <TextInput name="email"
              placeholder="Add your email"
              disabled
              value={this.state.email}
              onChange={this.onChange.bind(this)} />
          </div>
          <div className="profile-contact-info__input">
            <div className="profile-contact-info__icon">
              <div className="wethod-icon-phone wethod-icon-phone--black" />
            </div>
            <PhoneInput name="telephone"
              placeholder="Add your phone"
              value={this.state.telephone}
              onChange={this.onChange.bind(this)} />
          </div>
          <div className="profile-contact-info__input">
            <div className="profile-contact-info__icon">
              <div className="wethod-icon-linkedin wethod-icon-linkedin--black" />
            </div>
            <TextInput name="linkedin"
              placeholder="Add your LinkedIn"
              value={this.state.linkedin}
              onChange={this.onChange.bind(this)} />
          </div>
          <div className={this.getInputStyle()}>
            <div className="profile-contact-info__icon">
              <div className="wethod-icon-birthday wethod-icon-birthday--black" />
            </div>
            <DateInput name="birthday"
              placeholder="DD/MM/YYYY"
              value={this.state.birthday}
              onBlur={this.handleBirthdayBlur.bind(this)}
              onChange={this.onChange.bind(this)} />
            {this.getDateHelperText()}
          </div>
          <div className="profile-contact-info__input">
            <div className="profile-contact-info__icon">
              <div className="wethod-icon-gender wethod-icon-gender--black" />
            </div>
            <button type="button"
              className="wethod-button wethod-input"
              ref={(el) => this.genderOptions = el}
              onClick={this.showMenu.bind(this)}>
              {this.getSelectedGender()}
            </button>
            <Menu open={this.state.showMenu}
              onClose={this.hideMenu.bind(this)}
              anchorEl={this.genderOptions}>
              {this.getGenderList()}
            </Menu>
          </div>
        </div>
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.onSaveClick.bind(this)}
            className={this.getSaveStyle()}>Save
          </button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
