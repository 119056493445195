const { connect } = require('react-redux');
const Component = require('../../components/modal/CategoryFilesDownloadModal.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  downloadFile: (file, travel, travelCarrier) => dispatch(actions
    .downloadFile(file, travel, travelCarrier)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
