const { connect } = require('react-redux');
const Component = require('../components/ShareModalBody.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  pm: state.pm,
  isWaiting: state.isLoadingPM,
});

const mapDispatchToProps = (dispatch) => ({
  onFilter: (keyword) => dispatch(actions.getFilteredPM(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
