const constants = require('../constants');

/**
 * Use this utility to show children only if user is in "Pipeline Opportunities"
 * @param type
 * @param children
 * @returns {null|*}
 * @constructor
 */
const ForOpportunities = ({ type, children }) => {
  if (type === constants.TYPE_OPPORTUNITIES) {
    return children;
  }
  return null;
};

module.exports = ForOpportunities;
