/* eslint-disable react/sort-comp,react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

/**
 * An item of the input's select.
 *
 * @type {module.SelectItem}
 */
module.exports = class SelectItem extends React.Component {
  getUses() {
    let sentence = `used ${this.props.count}`;
    if (this.props.count === 0 || this.props.count > 1) sentence += ' times';
    else sentence += ' time';
    return sentence;
  }

  handleClick() {
    this.props.onClick(this.props.name);
  }

  render() {
    return (
      <li className="wethod-tags__select-item"
        onClick={this.handleClick.bind(this)}>
        <div className="wethod-tags__select-item-name">{this.props.name}</div>
        <div className="wethod-tags__select-item-uses">{this.getUses()}</div>
      </li>
    );
  }
};
