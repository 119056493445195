module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SHOW_MODAL_RATING = 'SHOW_MODAL_RATING';
module.exports.SHOW_DELETE_RATING_MODAL = 'SHOW_DELETE_RATING_MODAL';
module.exports.SHOW_MODAL_CONFIRM_DELETE = 'SHOW_MODAL_CONFIRM_DELETE';
module.exports.SHOW_MODAL_MOVE_SUPPLIER = 'SHOW_MODAL_MOVE_SUPPLIER';
module.exports.SHOW_MODAL_CANNOT_MOVE = 'SHOW_MODAL_CANNOT_MOVE';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.GET_RATINGS_REQUEST = 'GET_RATINGS_REQUEST';
module.exports.GET_RATINGS_SUCCESS = 'GET_RATINGS_SUCCESS';

module.exports.SAVE_RATING_REQUEST = 'SAVE_RATING_REQUEST';
module.exports.SAVE_RATING_SUCCESS = 'SAVE_RATING_SUCCESS';

module.exports.DELETE_RATING_REQUEST = 'DELETE_RATING_REQUEST';
module.exports.DELETE_RATING_SUCCESS = 'DELETE_RATING_SUCCESS';

module.exports.DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
module.exports.DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
module.exports.DELETE_SUPPLIER_FAILURE = 'DELETE_SUPPLIER_FAILURE';

module.exports.UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST';
module.exports.UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
