/* eslint-disable consistent-return,react/prop-types */
const React = require('react');

module.exports = class MenuItemDesk extends React.Component {
  getItemIconClassName() {
    const icon = this.props.item.getIcon();
    if (icon) {
      return `app-nav-mobile-item__icon wethod-icon-${icon} wethod-icon-${icon}--black`;
    }
  }

  render() {
    return (
      <div className="app-nav-mobile-item">
        <a href={this.props.item.getUrl()}
          className="app-nav-mobile-item__button"
          onClick={this.props.onClick}>
          <span className={this.getItemIconClassName()} />
          <span className="app-nav-mobile-item__label">{this.props.item.getLabel()}</span>
        </a>
      </div>
    );
  }
};
