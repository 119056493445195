const React = require('react');
const withRandomize = require('../../../../common/HOCs/withRandomize');

class Hello extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = ['Ciao', 'Hola', 'Hallo', 'Salut', 'Ni hao', 'Hi', 'Hey'];
  }

  render() {
    return (
      <span className="briefing-content__atom">{this.props.randomize(this.alternatives)}</span>
    );
  }
}

module.exports = withRandomize(Hello);
