const React = require('react');
const PropTypes = require('prop-types');
const MenuItem = require('./MenuItem.react');

const EmptyItem = ({ children }) => (
  <MenuItem className="wethod-table__input wethod-table__input-select2">{children}</MenuItem>
);

EmptyItem.defaultProps = {
  children: 'There\'s nothing here',
};

EmptyItem.propTypes = {
  children: PropTypes.node,
};

module.exports = EmptyItem;
