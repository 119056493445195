// eslint-disable-next-line no-unused-vars
const LevelAmountPrevious = require('./LevelAmountNodePrevious');

module.exports = class SummaryNodePrevious {
  /**
   * @param externalCost
   * @param {LevelAmountPrevious[]} levels
   */
  constructor(externalCost, levels) {
    this.externalCost = externalCost;
    this.levels = levels;
  }

  getExternalCost() {
    return this.externalCost;
  }

  getLevels() {
    return this.levels;
  }

  toJson() {
    return {
      external_cost: this.getExternalCost(),
      levels: this.getLevels().map((level) => level.toJson()),
    };
  }
};
