const React = require('react');
const PropTypes = require('prop-types');
const Action = require('./SegmentSelectItemAction.react');
const SelectItemWithActions = require('../../inputs/Select/SelectItemWithActions.react');

const SegmentSelectItem = ({ value, onDeleteClick, onEditClick, onClick, children }) => {
  function handleEditClick() {
    onEditClick(value);
  }

  function handleDeleteClick() {
    onDeleteClick(value);
  }

  return (
    <SelectItemWithActions
      onClick={onClick}
      label={children}
      className="wethod-segment-select__item">
      <Action tooltip="Edit" onClick={handleEditClick} itemId={value} show={onEditClick !== null}>
        <span className="wethod-icon wethod-icon-medium-edit wethod-icon-medium-edit--black"
          data-testid="wethod-segment-edit" />
      </Action>
      <Action tooltip="Delete"
        onClick={handleDeleteClick}
        itemId={value}
        show={onDeleteClick !== null}>
        <span className="wethod-icon wethod-icon-medium-delete wethod-icon-medium-delete--black"
          data-testid="wethod-segment-delete" />
      </Action>
    </SelectItemWithActions>
  );
};

SegmentSelectItem.defaultProps = {
  value: '',
  onDeleteClick: null,
  onEditClick: null,
  onClick: null,
};

SegmentSelectItem.propTypes = {
  /**
   * A value which uniquely identifies this segment.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Call when clicking "delete" button.
   * @param segment {number} segment value
   */
  onDeleteClick: PropTypes.func,
  /**
   * Call when clicking "edit" button.
   * @param segment {number} segment value
   */
  onEditClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

module.exports = SegmentSelectItem;
