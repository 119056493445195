const React = require('react');
const CommonSectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');

const SectionHeader = () => (
  <CommonSectionHeader current_section="Project List"
    helper_url="pipeline/index/#projects-and-opportunities"
    tour_id={286036}
    tour_start_page="pipeline/projects"
    big />
);

module.exports = SectionHeader;
