const { connect } = require('react-redux');
const Component = require('../../components/job-title/CreationRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-job-title-')),
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: () => dispatch(actions.deleteNewJobTitle()),
  onSave: (jobTitle) => dispatch(actions.createJobTitle(jobTitle)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
