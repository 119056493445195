const React = require('react');
const TextArea = require('../TextArea/TextArea.react');
const Empty = require('../TextArea/Empty.react');
const Body = require('../TextArea/Body.react');

module.exports = class Concept extends React.Component {
  componentDidMount() {
    this.props.getConcept(this.props.projectId);
  }

  getEmpty() {
    if (this.props.canEdit) {
      if (this.props.concept.description) { // someone forgot to add a summary
        return (
          <Empty
            title=""
            description="Make this concept more clear by adding a summary"
            action="Add Summary"
            image={<div className="project-canvas-empty__concept" />} />
        );
      }
      return (
        <Empty
          title="Waiting for great ideas"
          description="Add a short description of the project proposal"
          action="Add a concept"
          image={<div className="project-canvas-empty__concept" />} />
      );
    }
    if (this.props.concept.description) { // someone forgot to add a summary
      return (
        <Empty
          title="No summary yet"
          action="Show more"
          image={<div className="project-canvas-empty__concept" />} />
      );
    }
    return (
      <Empty
        description="No concept yet"
        image={<div className="project-canvas-empty__concept" />} />
    );
  }

  render() {
    return (
      <div className="project-canvas__widget--concept">
        <TextArea
          title="Concept"
          onClick={this.props.showModal}
          waiting={this.props.isWaiting}
          isEmpty={this.props.isEmpty}
          empty={this.getEmpty()}>
          <Body editedBy={this.props.concept.updated_by}>
            {this.props.concept.summary}
          </Body>
        </TextArea>
      </div>
    );
  }
};
