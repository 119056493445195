const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Loading = require('../../../../../../common/react/LoadingSmall.react');
const { formatDate } = require('../../../../../../services/FormatService');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class RequestReviewModal extends React.Component {
  componentDidMount() {
    this.props.getLastReview(this.props.data.project.id);
  }

  handleReviewRequest() {
    this.props.requestReview(this.props.data.project.id);
  }

  getBody() {
    if (this.props.isWaiting) {
      return <Loading />;
    }
    const lastReviewData = this.props.data.lastReview
      ? (
        <span>
          <br />
          Last review was requested by
          {' '}
          {this.props.data.lastReview.created_by}
          {' '}
          on {formatDate(this.props.data.lastReview.created_at)}.
          <br />
          {this.props.data.lastReview.completed}
          {' '}
          on {this.props.data.lastReview.reviewers}
          {' '}
          members completed this review.
        </span>
      )
      : null;
    return (
      <div>
        <span>
          Do you want to <b>request a review of the team members on this project? </b>
        </span>
        <br />
        {lastReviewData}
      </div>
    );
  }

  render() {
    return (
      <Modal title="Project & team review" onCancelClick={this.props.onCloseClick}>
        {this.getBody()}
        <ShowIf condition={!this.props.isWaiting}>
          <div className="wethod-modal2__actions">
            <Button onClick={this.props.onCloseClick}>No, thanks</Button>
            <Button onClick={this.handleReviewRequest.bind(this)}
              disabled={!this.props.data.project
              || !this.props.data.project.id || this.props.isSaving}>
              Send request
            </Button>
          </div>
        </ShowIf>
        <div className="wethod-modal2__feedback">
          <ShowIf condition={this.props.isSaving}>
            <span>Sending...</span>
          </ShowIf>
        </div>
      </Modal>
    );
  }
};
