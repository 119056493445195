/* eslint-disable react/sort-comp,consistent-return,no-param-reassign,react/no-did-update-set-state */
const React = require('react');

module.exports = class TrendsItem extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      positive: '#4ED88D',
      neutral: '#f5a640',
      negative: '#DB4D69',
    };

    this.state = {
      data: this.props.data
        ? this.getSeriesWithFormattedMarkers(this.props.data) : [],
    };

    const parentFunctions = {
      getSeriesTrendType: props.getSeriesTrendType,
      getPointLabel: props.getPointLabel,
      getPointType: props.getPointType,
      getTooltip: props.getTooltip,
    };

    this.chartOptions = {
      chart: {
        type: 'spline',
        backgroundColor: '#FCFCFC',
        style: {
          fontFamily: 'Rubik',
        },
        spacing: [20, 80, 10, 30],
      },
      plotOptions: {
        series: {
          label: {
            enabled: false,
          },
          dataLabels: {
            props: parentFunctions, // needed to use component props in the formatter callback
            enabled: true,
            formatter: this.getPointLabel,
            style: {
              fontWeight: '400',
              fontSize: '13px',
              textShadow: 'none',
              textOutline: 0,
              color: this.getLastPointColor(),
            },
            crop: false,
            overflow: 'allow',
            align: 'left',
          },
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: '',
      },
      lang: {
        noData: 'No data to display',
      },
      noData: {
        position: {
          x: 30,
        },
      },
      xAxis: {
        type: 'datetime',
        visible: false,
      },
      yAxis: {
        gridLineWidth: 0,
        plotLines: this.getYPlotLines(),
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        props: parentFunctions, // needed to use component props in the formatter callback
        formatter: this.getTooltip,
      },
    };
  }

  getYPlotLines() {
    const zeroPlotLine = {
      color: '#E0E0E0',
      width: 1,
      value: 0,
      label: {
        style: {
          color: '#66666',
          fontSize: '11px',
        },
        text: '0',
        x: -22,
        y: 3,
      },
      zIndex: 1,
    };
    if (this.props.getYPlotLines) {
      return [zeroPlotLine].concat(this.props.getYPlotLines());
    }
    return [zeroPlotLine];
  }

  hasData() {
    return this.props.data.length > 0;
  }

  getSeriesColor() {
    if (this.hasData()) {
      const type = this.props.getSeriesTrendType();

      return this.colors[type];
    }
  }

  getPointLabel(label) {
    if (this.series.data.length > 0) {
      return label.props.getPointLabel(this.point, this.series, this.x, this.y);
    }
  }

  getLastPointColor() {
    if (this.hasData()) {
      const lastValue = this.props.data[this.props.data.length - 1].y;

      return this.getPointColor(lastValue);
    }
  }

  getPointColor(value) {
    const type = this.props.getPointType(value);
    return this.colors[type];
  }

  getTooltip(tooltip) {
    return tooltip.options.props.getTooltip(this.x, this.y);
  }

  getSeriesData() {
    const seriesData = [{
      data: this.state.data,
    }, { // Fake series used to make the 0 plotLine always visible
      enableMouseTracking: false,
      data: [{
        name: 'fake zero',
        x: this.state.data.length > 0 ? this.state.data[0].x : moment().valueOf(),
        y: 0,
        dataLabels: { enabled: false },
        marker: {
          enabled: false,
          states: {
            enabled: false,
            hover: {
              enabled: false,
            },
          },
        },
      }],
    }];

    if (this.props.getFakeSeriesData) {
      return seriesData.concat(this.props.getFakeSeriesData());
    }
    return seriesData;
  }

  getOptions() {
    return {
      ...this.chartOptions,
      plotOptions: {
        ...this.chartOptions.plotOptions,
        series: {
          ...this.chartOptions.plotOptions.series,
          color: this.getSeriesColor(),
        },
      },
      series: this.state.data.length > 0 ? this.getSeriesData() : [],
      yAxis: {
        ...this.chartOptions.yAxis,
        plotLines: this.getYPlotLines(),
      },
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getOptions());
  }

  getSeriesWithFormattedMarkers(series) {
    for (let i = 0; i < series.length; i++) {
      const color = this.getPointColor(series[i].y);
      if (i === series.length - 1) {
        series[i].color = color;
        series[i].marker = {
          enabled: true,
          fillColor: color,
          states: {
            fillColor: color,
            hover: {
              fillColor: color,
            },
          },
        };
      }
    }
    return series;
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({ data: this.getSeriesWithFormattedMarkers(this.props.data) },
        () => {
          this.renderChart();
        });
    }
  }

  getLegend() {
    if (this.props.legend) {
      return this.props.legend;
    }
    return null;
  }

  getClassName() {
    const { className } = this.props;
    if (className) {
      return `profile-trends__item ${this.props.className}`;
    }
    return 'profile-trends__item';
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="profile-trends__item-header">
          <h3 className="profile-trends__item-header-title">{this.props.title}</h3>
          <h4 className="profile-trends__item-header-subtitle">{this.props.subtitle}</h4>
        </div>
        <div className="profile-trends__item-content">
          <div className="profile-trends__item-content-report"
            ref={(chartContainer) => this.chartContainer = chartContainer} />
          {this.getLegend()}
        </div>
      </div>
    );
  }
};
