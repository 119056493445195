const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Header/Header.react');
const actions = require('../../../actions');
const FeatureService = require('../../../../../../../services/FeatureService');

const mapStateToProps = (state) => ({
  scrollingComponentAmount: state.scrollingComponentAmount,
  status: state.info.status,
  days: state.info.days,
  levelsExtended: state.info.levels,
  budgetId: state.info.id,
});
const mapDispatchToProps = (dispatch) => ({
  showPricelistModal: () => dispatch(actions.showModalPricelist()),
  setScrollingComponentScrollAmount: ((amount) => dispatch(actions
    .setScrollingComponentScrollAmount(amount))),
  setVisible: (idLevel, isVisible, budgetId = null) => dispatch(actions
    .setBudgetLevelVisible(idLevel, isVisible, budgetId)),
  isHierarchyBudgetFlagEnabled: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
