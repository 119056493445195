const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

/**
 * @deprecated please move remaining methods and new ones to js/models/Travel
 */
module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/travel/`;
  },

  createTravel() {
    const defer = $.Deferred();

    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  /**
   * @deprecated use js/models/Travel update()
   * @return {*}
   */
  updateTravel() {
    const defer = $.Deferred();

    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  /**
   * @deprecated use js/models/Travel delete()
   * @return {*}
   */
  deleteTravel() {
    const defer = $.Deferred();

    $.when(this.erase()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
