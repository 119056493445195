/* eslint-disable react/prefer-stateless-function,react/jsx-no-bind */
const React = require('react');

module.exports = class Item extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__item" onClick={() => { this.props.showsSidebar(this.props.id); }}>
        <span className="project-canvas-gantt__item-name">
          {this.props.name}
        </span>
      </div>
    );
  }
};
