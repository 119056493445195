module.exports = {
  BUDGET_SUBMIT: 'BUDGET_SUBMIT',
  BUDGET_APPROVAL: 'BUDGET_APPROVAL',
  BUDGET_DRAFT: 'BUDGET_DRAFT',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  DISABLE_MULTICURRENCY: 'DISABLE_MULTICURRENCY',
  ENABLE_MULTICURRENCY: 'ENABLE_MULTICURRENCY',
  PS_CORRECTION: 'PS_CORRECTION',
  CONSUMPTION_REPORT: 'CONSUMPTION_REPORT',
  LOG: 'LOG',
  NOTES: 'NOTES',
  TASK_DELETE: 'TASK_DELETE',
  TASK_MAKE_INTERCOMPANY: 'TASK_MAKE_INTERCOMPANY',
  TASK_SELECT_INTERCOMPANY_SUPPLIER: 'TASK_SELECT_INTERCOMPANY_SUPPLIER',
  TASK_INTERCOMPANY_INVITE_SUPPLIER: 'TASK_INTERCOMPANY_INVITE_SUPPLIER',
  TASK_SORT_MODAL: 'TASK_SORT_MODAL',
  API_ERROR_MODAL: 'API_ERROR_MODAL',
  TASK_INTERCOMPANY_DETAILS: 'TASK_INTERCOMPANY_DETAILS',
  BUDGET_CREATION_MODAL: 'BUDGET_CREATION_MODAL',
  BUDGET_TEMPLATE_LIST_MODAL: 'BUDGET_TEMPLATE_LIST',
  JOB_TITLE_INSERT_MODAL: 'JOB_TITLE_INSERT',
  JOB_TITLE_DELETE_MODAL: 'JOB_TITLE_DELETE',
  PRODUCT_INSERT_MODAL: 'PRODUCT_INSERT_INSERT',
  PRICELIST_MODAL: 'PRICELIST_MODAL',
  BASELINE_MODAL: 'BASELINE_MODAL',
  DAILY_COST_PRICE_LEVEL_MODAL: 'DAILY_COST_PRICE_LEVEL_MODAL',
};
