'use strict';

Wethod.module('PlanningApp', function (PlanningApp, Wethod, Backbone, Marionette, $) {
  this.AuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var section = this.get('section') ? this.get('section') : 'planning';
      return APIURL + '/authorization/settings/apppermissions?app=' + section;
    },
  });

  var API = {
    getAuthorization: function (section) {
      var getAuthorization = new PlanningApp.AuthorizationModel({ section: section });
      var defer = $.Deferred();

      getAuthorization.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:planning:authorization', function (section) {
    return API.getAuthorization(section);
  });
});
