const React = require('react');
const PropTypes = require('prop-types');
const SmallRating = require('../../../../Rating/SmallRating.react');

const Rating = ({ max, value }) => (
  <div className="widget-rating-overview-item__value">
    <SmallRating max={max} value={value} showNumericValue readOnly />
  </div>
);

Rating.propTypes = {
  /**
   * The current rating.
   */
  value: PropTypes.number.isRequired,
  /**
   * The max possible rating.
   */
  max: PropTypes.number.isRequired,
};

module.exports = Rating;
