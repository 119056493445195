const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

const AllocationConfirmDeleteModal = ({ onCloseClick, item, isSaving, onDelete }) => {
  function handleDelete() {
    if (!isSaving) {
      onDelete(item.id);
    }
  }

  const getBody = () => (
    <div>
      <p>
        You are about to delete this allocation request.
      </p>
      <p>
        Are you sure you want to continue?
      </p>
    </div>
  );

  return (
    <Modal title="Delete allocation request" onCancelClick={onCloseClick}>
      {getBody()}
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Not yet</Button>
        <Button onClick={handleDelete}
          disabled={isSaving}>
          Yes, delete
        </Button>
      </div>
      <ShowIf condition={isSaving}>
        <div className="wethod-modal2__feedback">Saving...</div>
      </ShowIf>
    </Modal>
  );
};

module.exports = AllocationConfirmDeleteModal;
