const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const Link = require('../../../../common/components/Link.react');

module.exports = ({ rri, prevMonthRri }) => {
  const getPrevMonth = () => {
    if (prevMonthRri) {
      return (
        <span>
          The average RRI of the previous month was{' '}
          <NV value={prevMonthRri} decimal />
          .
        </span>
      );
    }
    return null;
  };

  return (
    <Paragraph>
      The average
      {' '}
      <Link href="http://support.wethod.com/glossary/index/#roadrunner-index-rri">
        Road Runner Index
        (RRI)
      </Link>
      {' '}
      was
      {' '}
      <NV value={rri} decimal />
      , which means that for each worked day, the average
      progress of a
      project was of
      {' '}
      <NV value={rri} decimal />
      {' '}
      days.
      {' '}
      {getPrevMonth()}
    </Paragraph>
  );
};
