'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette) {
  this.Layout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#pipelineLayoutTemplate',
    regions: {
      pipelineHeaderSection: '[data-region="pipelineSectionHeader"]',
      pipelineBody: '[data-region="pipelineBody"]',
    },
  });
});
