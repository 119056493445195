const { connect } = require('react-redux');
const Component = require('../../components/ProjectAreasModal/ProjectAreasModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.savingProjectStatus,
});

const mapDispatchToProps = (dispatch) => ({
  save: (data) => dispatch(actions.saveProjectStatusByArea(data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
