const React = require('react');
const Sidebar = require('../../../../../../../../common/react/sidebar/Sidebar.react');
const CompanyEditableDetails = require('../containers/CompanyEditableDetails');
const CompanyReadOnlyDetails = require('../../../sidebar/company/CompanyReadOnlyDetails.react');
const Actions = require('../../../sidebar/company/CompanySidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * client: object corresponding the focused client to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.CompanySidebar}
 */
module.exports = class CompanySidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Return the client formatted for saving
   * @param client
   * @returns {*}
   */
  static getFormattedClient(client) {
    const bank = client.bank_account ? client.bank_account.id : null;
    const vatRate = client.vat_rate ? client.vat_rate.id : null;
    const country = client.country ? client.country.name : null;
    const province = client.province ? client.province.name : null;
    const paymentTerm = client.payment_term ? client.payment_term.id : null;
    const intentDate = client.intent_date ? client.intent_date : null;

    return {
      ...client,
      bank_account: bank,
      vat_rate: vatRate,
      country,
      province,
      payment_term: paymentTerm,
      intent_date: intentDate,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes (phone excluded), false
      // otherwise
      errors: [], // array containing the name of the attributes that have errors; if the list is
      // empty there are no errors
      client: this.props.client || {},
      editMode: false, // by default is true if client has no id (when we want to add a new client)
    };
  }

  // Save in the local state the modified client and check if there are unsaved changes
  handleInputChanges(name, value) {
    this.setState((prevState) => {
      const newClient = {
        ...prevState.client,
        [name]: value,
      };

      const hasUnsavedChanges = this.hasChanged(name, value);
      return {
        hasUnsavedChanges,
        client: newClient,
      };
    });
  }

  handleClientDelete() {
    if (this.props.onDelete) this.props.onDelete(this.state.client);
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    this.setState((prevState) => {
      let updatedErrors = prevState.errors;
      if (errors.length === 0) {
        updatedErrors = updatedErrors.filter((error) => error !== name);
      } else if (!updatedErrors.includes(name)) {
        updatedErrors.push(name);
      }

      return { errors: updatedErrors };
    });
  }

  handleSave() {
    if (this.state.hasUnsavedChanges) {
      this.props.onSave(CompanySidebar.getFormattedClient(this.state.client));

      this.setState({
        editMode: false,
        hasUnsavedChanges: false,
        unsavedChanges: [],
      });
    }
  }

  handleEditMode() {
    if (this.props.canEdit && !this.state.editMode) {
      this.setState({ editMode: true });
    }
  }

  getGroupCompany(id) {
    const found = this.props.availableGroupCompanies.filter((item) => item.id === id);
    return found.length ? found[0] : null;
  }

  getCompanyClient() {
    return {
      ...this.state.client,
      group_company: this.getGroupCompany(this.state.client.group_company),
    };
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <CompanyEditableDetails client={this.getCompanyClient()}
          hasIntercompanyGroup={this.props.hasIntercompanyGroup}
          onChange={this.handleInputChanges.bind(this)}
          onValidate={this.handleErrors.bind(this)} />
      );
    }
    return (
      <CompanyReadOnlyDetails client={this.getCompanyClient()}
        hasIntercompanyGroup={this.props.hasIntercompanyGroup} />
    );
  }

  getActions() {
    if (this.state.client && this.state.client.id && this.canEdit()) {
      return (
        <Actions canEdit={this.canEdit()}
          onDelete={this.handleClientDelete.bind(this)} />
      );
    }

    return null;
  }

  /**
   * Checks if it's safe to save a client: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEdit && !this.state.editMode;
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldClient = this.props.client || {};
    let oldVal = null;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'group':
        oldVal = oldClient.group ? oldClient.group.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'bank_account':
        oldVal = oldClient.bank_account ? oldClient.bank_account.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'vat_rate':
        oldVal = oldClient.vat_rate ? oldClient.vat_rate.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'country':
        oldVal = oldClient.country ? oldClient.country.name : null;
        newVal = newVal ? newVal.name : null;
        break;
      case 'province':
        oldVal = oldClient.province ? oldClient.province.name : null;
        newVal = newVal ? newVal.name : null;
        break;
      case 'payment_term':
        oldVal = oldClient.payment_term ? oldClient.payment_term.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      default:
        oldVal = oldClient[name];
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((CompanySidebar.isDirty(oldVal) || CompanySidebar.isDirty(newVal)) && (oldVal !== newVal)) {
      this.setState((prevState) => {
        // When the value has changed, we insert the name in the changes array, if not already present
        const newChanges = prevState.unsavedChanges;
        if (!newChanges.includes(name)) {
          newChanges.push(name);
        }
        this.setState({ unsavedChanges: newChanges });
      });
      totalChanges += 1;
    } else {
      this.setState((prevState) => {
        // When the value is equal to the props, we need to remove it from the changes array if
        // present
        let newChanges = prevState.unsavedChanges;
        newChanges = newChanges.filter((attr) => attr !== name);
        totalChanges = newChanges.length;

        return { unsavedChanges: newChanges };
      });
    }

    return totalChanges !== 0;
  }

  hasUnsavedChanges() {
    return this.state.hasUnsavedChanges;
  }

  render() {
    return (
      <Sidebar title="Company details"
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
