const { connect } = require('react-redux');
const Component = require('../../components/projects/AddItemButton.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  buFilter: state.buFilter,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (bu) => dispatch(actions.addProject(bu)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
