/* eslint-disable react/sort-comp */
const React = require('react');
const Select = require('../../../../../../common/react/material-input/SidebarSelectAddButton.react');
const IconComponent = require('./IconComponent.react');
const ItemComponent = require('../../containers/sidebar/ItemComponent');

module.exports = class FavoriteCitiesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.destination ? { city: this.props.destination } : null,
    };
  }

  componentDidMount() {
    this.props.getFavorites();
  }

  handleFilter(name, key = '') {
    this.props.filterFavorites(key);
  }

  addFavorite() {
    if (this.props.keyword !== '' && !this.props.isWaiting) {
      this.props.addFavorite({
        city: this.props.keyword,
        employee: Wethod.userInfo.get('employee_id'),
      });
      this.props.onChange('destination', {
        city: this.props.keyword,
        employee: Wethod.userInfo.get('employee_id'),
      });
      this.setState({ value: { city: this.props.keyword } });
    }
  }

  handleFocus() {
    if (!this.props.destination) {
      this.handleFilter('city', '');
    }
  }

  handleBlur(value, e) {
    const { relatedTarget } = e;

    // Hack: the blur event triggers before the onclick so im manually firing the addFavorite function in case the target is the heart button
    if (relatedTarget && relatedTarget.classList.contains('travel-favorite-button')) {
      this.addFavorite();
    }

    if (this.props.keyword) {
      this.props.onChange('destination', {
        city: this.props.keyword,
        employee: Wethod.userInfo.get('employee_id'),
      });
      this.setState({ value: { city: this.props.keyword } });
    }
  }

  render() {
    return (
      <Select name={this.props.name || 'destination'}
        placeholder={this.props.placeholder || 'Destination'}
        value={this.state.value}
        items={this.props.filteredFavorites}
        onFilter={this.handleFilter.bind(this)}
        onFocus={this.handleFocus.bind(this)}
        onChange={this.props.onChange}
        onCreateClick={this.addFavorite.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        param="city"
        itemComponent={ItemComponent}
        iconComponent={IconComponent}
        onValidate={this.props.onValidate}
        required={this.props.required}>
        {this.props.children}
      </Select>
    );
  }
};
