const React = require('react');

const OpenSidebarIcon = () => (
  <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99999 6.3998C6.99999 6.4998 6.99999 6.6998 6.89999 6.7998L0.899988 12.7998C0.699988 12.9998 0.399988 12.9998 0.199988 12.7998C-1.22041e-05 12.5998 -1.22041e-05 12.2998 0.199988 12.0998L5.79999 6.4998L0.199988 0.899804C-1.22041e-05 0.699804 -1.22041e-05 0.399805 0.199988 0.199805C0.399988 -0.00019531 0.699988 -0.00019531 0.899988 0.199805L6.89999 6.1998C6.69979 5.99961 6.99999 6.2998 6.99999 6.3998Z" fill="#3C3C3B" />
  </svg>

);

module.exports = OpenSidebarIcon;
