'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  /**
   * Widget renamed to Roadrunner Index
   */
  this.WidgetSparklineProdModel = Wethod.Utility.BaseModel.extend();

  /**
   * Widget renamed to Roadrunner Index
   *
   * Three order categories:
   * - First where last week rri is not 0, ordered by delta on 1
   * - Then where there is rri but not on last week
   * - Finally where there is no rri
   *
   * Legend:
   * / = no rri
   * . = has rri but not on last week
   * > = has rri on current week
   *
   * Return:
   * -1 = modelA first
   * 0 = model are equivalent
   * 1 = modelB first
   *
   * Cases:
   *   | modelA | modelB | return
   * --|--------|--------|-------
   * 1 |    /   |   /    |   0
   * 2 |    /   |   .    |   1
   * 3 |    /   |   >    |   1
   * 4 |    .   |   /    |   -1
   * 5 |    .   |   .    |   0
   * 6 |    .   |   >    |   1
   * 7 |    >   |   /    |   -1
   * 8 |    >   |   .    |   -1
   * 9 |    >   |   >    |   delta on last rri
   */
  this.WidgetSparklineProdColl = Wethod.Utility.BaseCollection.extend({
    model: DashboardApp.WidgetSparklineProdModel,
    _lastWeek: moment().startOf('isoweek').subtract(7, 'days').format('YYYY-MM-DD'),
    comparator: function (modelA, modelB) {
      // modeA
      var weeksA = modelA.get('weeks');
      var weeksArrayA = _.map(weeksA); // transform object to array
      var valuedWeeksA = weeksArrayA.filter(function (val) {
        return val;
      }); // remove all null and 0
      var hasRriA = valuedWeeksA.length > 0;
      var deltaRriA = weeksA[this._lastWeek] ? Math.abs(1 - weeksA[this._lastWeek]) : null;

      // modelB
      var weeksB = modelB.get('weeks');
      var weeksArrayB = _.map(weeksA);
      var valuedWeeksB = weeksArrayB.filter(function (val) {
        return val;
      });
      var hasRriB = valuedWeeksB.length > 0;
      var deltaRriB = weeksB[this._lastWeek] ? Math.abs(1 - weeksB[this._lastWeek]) : null;

      if (!hasRriA && !hasRriB) { // case 1
        return 0;
      }
      if (!hasRriA && hasRriB) { // case 2 and 3
        return 1;
      }
      if (hasRriA && !hasRriB) { // case 4 and 7
        return -1;
      }
      if (hasRriA && !deltaRriA && hasRriB && !deltaRriB) { // case 5
        return 0;
      }
      if (hasRriA && !deltaRriA && deltaRriB) { // case 6
        return 1;
      }
      if (deltaRriA && hasRriB && !deltaRriB) { // case 8
        return -1;
      }
      if (deltaRriA && deltaRriB) { // case 9
        return deltaRriA > deltaRriB ? -1 : 1;
      }
      return null;
    },
  });

  var API = {
    saveInvoiceWidget: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget/sparkline-product/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('save:user:sparkline:widget', function (options) {
    return API.saveInvoiceWidget(options);
  });
});
