const { connect } = require('react-redux');
const Clients = require('../components/Clients.react');

const mapStateToProps = (state) => {
  const showPersonSidebar = state.sidebarToShow === 'person-sidebar';
  const showCompanySidebar = state.sidebarToShow === 'company-sidebar';

  return {
    type: state.type,
    showPersonSidebar,
    showCompanySidebar,
  };
};

module.exports = connect(mapStateToProps)(Clients);
