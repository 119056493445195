const { connect } = require('react-redux');
const Component = require('../components/Profile.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  editable: state.editable,
  name: state.name,
  showKudos: state.isReviewEnabled,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  showEditModal: () => dispatch(actions.showContactInfoModal()),
  showBioModal: () => dispatch(actions.showBioModal()),
  showSkillsModal: () => dispatch(actions.showSkillsModal()),
  showInterestsModal: () => dispatch(actions.showInterestsModal()),
  showLanguagesModal: () => dispatch(actions.showLanguagesModal()),
  showPortfolioModal: () => dispatch(actions.showPortfolioModal()),
  showSponsorsModal: () => dispatch(actions.showSponsorsModal()),
  showYearResolutionModal: () => dispatch(actions.showYearResolutionModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
