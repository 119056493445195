const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ItemSidebarActions.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  reviewEnabled: state.projectReviewEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  shareProject: (project) => dispatch(actions.showShareProjectModal(project)),
  requestReview: (project) => dispatch(actions.showRequestReviewModal(project)),
  editFeedback: (project) => dispatch(actions.showEditFeedbackModal(project)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
