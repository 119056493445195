const React = require('react');
const PricelistSelect = require('./PricelistSelect.react');
const PricelistReadOnly = require('./PricelistReadonly.react');
const NameCell = require('../../Cell/NameCell.react');
const Tooltip = require('./PricelistTooltip.react');

const Pricelist = ({ name, onClick, pricelistIsEditable }) => {
  let component = <PricelistReadOnly name={name} />;

  if (pricelistIsEditable) {
    component = <PricelistSelect name={name} onClick={onClick} />;
  }

  return (
    <NameCell>
      <div className="wethod-budget-calculator__header-price-list" data-testid="pricelist-cell">
        <Tooltip pricelistName={name}>
          {component}
        </Tooltip>
      </div>
    </NameCell>
  );
};

module.exports = Pricelist;
