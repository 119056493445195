const { connect } = require('react-redux');
const Component = require('../../components/modals/DataReviewModal.react');
const actions = require('../../actions');
const status = require('../../segmentsStatus');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.includes('update-segments'),
  startDate: state.segments.find((segment) => segment.status === status.FREE)?.from,
  endDate: state.segments.find((segment) => segment.status === status.FREE)?.to,
});

const mapDispatchToProps = (dispatch) => ({
  save: (segments) => dispatch(actions.updateSegments(segments)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
