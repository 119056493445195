const request = require('./Request');

const ProductionPlan = {
  getAutoDistribution(projectId) {
    return request({
      method: 'get',
      url: `/production-plan/automatic/${projectId}`,
    });
  },
};

module.exports = ProductionPlan;
