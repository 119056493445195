const { connect } = require('react-redux');
const Component = require('../../../components/modals/ConversionModal/ConversionModal.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  id_budget: state.selected_project.budget.id,
  id_project: state.selected_project.id,
  isSaving: state.waiting_for.filter((request) => request === 'save-conversion' || request === 'project-availability').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  saveBudgetConversion: (projectId, areas) => dispatch(actions
    .saveBudgetConversion(projectId, areas)),
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
