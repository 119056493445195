const { connect } = require('react-redux');
const CreateForm = require('../../components/EventForm/EventForm.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  project: state.timeline.project,
  formStatus: state.newEventStatus,
});

const mapDispatchToProps = (dispatch) => ({
  save: (event) => dispatch(actions.saveEvent(event)),
  closeForm: () => dispatch(actions.closeNewEventForm()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(CreateForm);
