const React = require('react');
const Icon = require('../../../../../../../../common/react/Icon/Icon.react');
const BudgetFooterLink = require('./BudgetFooterLink.react');

const ReportLink = ({ projectId }) => (
  <BudgetFooterLink noBorder label="Report" href={`#project/${projectId}/report`}>
    <Icon icon="project-report" />
  </BudgetFooterLink>
);

module.exports = ReportLink;
