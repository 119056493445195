const React = require('react');
const ReactDOM = require('react-dom');

const BackboneService = {
  /**
   * Injects given React component with given props as child of given container.
   * @param {JSX.Element} component
   * @param {string} containerSelector An element identified by this selector must exist in DOM
   * before calling this method
   * @param {{}} props
   */
  injectReactComponent: (component, containerSelector, props = {}) => {
    const containerEl = document.querySelector(containerSelector);
    const element = React.createElement(component, props);

    ReactDOM.render(element, containerEl);
  },
};

module.exports = BackboneService;
