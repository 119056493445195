const React = require('react');
const _ = require('underscore');
const InputWrapper = require('../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('../../../../../../common/react/sidebar/SidebarInputRow.react');
const Icon = require('../../../../../../common/react/Icon/Icon.react');
const MonthSelect = require('../../../../../../common/react/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const NumericField = require('../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const Select = require('../../../../../../common/react/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItemColorful.react');

const ProjectSidebarBody = ({
  name, client, contact, purchaseOrder, account, joc, jobOrder,
  projectType, pm, probability, start, duration,
}) => (
  <div className="pipeline__sidebar-body">
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="name"
          id="sidebar-name"
          label="Name"
          value={name} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper icon={<Icon icon="building" />}>
        <TextField readOnly
          name="client"
          id="sidebar-client"
          label="Client"
          value={client} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="contact"
          id="sidebar-contact"
          label="Contact"
          value={contact} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="po"
          id="sidebar-po"
          label="Client PO"
          value={purchaseOrder} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper icon={<Icon icon="pm" />}>
        <TextField readOnly
          name="pm"
          id="sidebar-pm"
          label="PM"
          value={pm} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="account"
          id="sidebar-account"
          label="Account"
          value={account} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <Select readOnly
          value={joc.id}
          id="sidebar-joc"
          label="Job order category"
          color={joc.color}
          name="project_type">
          <SelectItem key={joc.id}
            value={joc.id}>
            {_.unescape(joc.name)}
          </SelectItem>
        </Select>
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="project_label"
          id="sidebar-project-type"
          label="Project type"
          value={projectType} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <TextField readOnly
          name="id_job_order"
          id="sidebar-job-order"
          label="Job order"
          value={jobOrder} />
      </InputWrapper>
    </Row>
    <Row>
      <InputWrapper>
        <NumericField readOnly
          name="probability"
          label="Probability"
          id="sidebar-probability"
          value={probability} />
      </InputWrapper>
    </Row>
    <Row className="pipeline__sidebar-multiple-row">
      <InputWrapper icon={<Icon icon="calendar" />}>
        <MonthSelect readOnly
          name="date_start"
          id="sidebar-date-start"
          label="Start"
          value={start} />
      </InputWrapper>
      <InputWrapper>
        <NumericField readOnly
          name="duration"
          label="Months"
          id="sidebar-duration"
          value={duration} />
      </InputWrapper>
    </Row>
  </div>
);

module.exports = ProjectSidebarBody;
