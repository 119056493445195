const EventService = {
  /**
   * Creates a clone of the given event (adding the given targetProps to event.target) and returns
   * it.
   * @param event
   * @param targetProps {{}}
   */
  cloneEvent: (event, targetProps) => {
    const nativeEvent = event.nativeEvent || event;
    const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);

    Object.defineProperty(clonedEvent, 'target', {
      writable: true,
      value: targetProps,
    });

    return clonedEvent;
  },
};

module.exports = EventService;
