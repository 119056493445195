/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return,react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

module.exports = class Item extends React.Component {
  getColorDot() {
    if (this.props.color) {
      return (
        <span className="wethod-search-select__dot"
          style={{ backgroundColor: this.props.color }} />
      );
    }
  }

  handleClick() {
    this.props.onClick({ ...this.props });
  }

  render() {
    return (
      <li className="wethod-search-select__item"
        onClick={this.handleClick.bind(this)}>
        {this.props.name}
        {this.getColorDot()}
      </li>
    );
  }
};
