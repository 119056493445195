const React = require('react');
const Delta = require('./RowLabel.react');

const RowCreatedLabel = () => (
  <Delta>
    Created
  </Delta>
);

module.exports = RowCreatedLabel;
