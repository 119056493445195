const React = require('react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');

const ChangeStatusButton = ({ showApproveModal, showRejectModal, item }) => {
  function onApproveClick() {
    showApproveModal(item);
  }

  function onRejectClick() {
    showRejectModal(item);
  }

  const buttons = {
    pending: <Button onClick={onApproveClick} className="allocation-request-item__change-status-action">Approve / Reject</Button>,
    conflict: <Button onClick={onRejectClick} className="allocation-request-item__change-status-action">Reject</Button>,
    approved: null,
    rejected: null,
  };

  return buttons[item.status];
};

module.exports = ChangeStatusButton;
