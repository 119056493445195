/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case */

const React = require('react');
const moment = require('moment');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');

const BudgetErrorBody = ({
  errors, onCancelClick, onPositiveButtonClick, isSaving, projectsInfo,
}) => {
  const projects = errors.map((error, index) => (
    <div
      key={index}
      className="planning-people-repeat-error__message-list-item">
      <span
        className="bold">
        {projectsInfo[error.project].name}
      </span>
      {' '}
      further
      than
      {' '}
      <span>{moment(error.end_date).format('ddd,DD MMM YYYY')}</span>
    </div>
  ));

  const getFeedBack = () => {
    if (isSaving) {
      return 'Planning...';
    }
    return '';
  };

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Insufficient budget to cover the following allocations:
        </ModalBodyBlock>
        <ModalBodyBlock>{projects}</ModalBodyBlock>
        <ModalBodyBlock>
          Do you still want to plan the available days?
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={getFeedBack()}>
        <ModalFooterAction onClick={onCancelClick}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onPositiveButtonClick}>
          Yes
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = BudgetErrorBody;
