const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const getLastThursday = require('../../../../../../services/DateService').getLastThursdayOfMonth;

module.exports = class HeadcountTrend extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      positive: '#4ED88D',
      neutral: '#48a5f9',
      negative: '#DB4D69',
      target: '#F8A01C',
    };

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      chart: {
        type: 'spline',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
        spacingBottom: 0,
      },
      title: {
        text: null,
      },
      legend: {
        itemStyle: {
          fontWeight: 100,
          fontSize: 11,
        },
        align: 'left',
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            color: '#a5a5a5',
          },
        },
        gridLineWidth: 0,
      },
      xAxis: {
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
            fillColor: null, // inherit from series
            lineWidth: 1,
            lineColor: null, // inherit from series
            symbol: 'circle',
          },
          events: {
            legendItemClick(e) {
              e.preventDefault();
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.headcount.length !== prevProps.headcount.length
      || this.props.target !== prevProps.target) {
      this.renderChart();
    }
  }

  getChartOptions() {
    const categories = this.getCategories();
    return {
      ...this.options,
      series: this.getSeries(),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1, // last category index, to force empty category to be shown anyway
        min: 0,
      },
      yAxis: {
        ...this.options.yAxis,
        plotLines: [{
          color: this.colors.target,
          width: 1,
          value: this.props.target,
          dashStyle: 'dash',
          zIndex: 4,
          label: {
            text: this.props.target,
            align: 'right',
            x: -10,
          },
        }],
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel())
      // Add additional breakpoint to give some padding to the graph
      // (to show the target label without overlapping the main chart)
      .concat('');
  }

  getSeries() {
    const { headcount, target } = this.props;
    const past = this.getHeadcountSeries(headcount);
    const forecast = this.getForecastSeries(headcount);
    /**
     * Add hidden series (no marker defined in plotOptions and hover disabled with enableMouseTracking flag)
     * to show the plotLine in the legend and to make the chart scale to always show the plotLine
     */
    const targetHiddenSeries = {
      name: 'Target',
      data: [target],
      color: this.colors.target,
      enableMouseTracking: false,
      dashStyle: 'dash',
    };

    return [past, forecast, targetHiddenSeries];
  }

  getHeadcountSeries(series) {
    const pastData = series.reduce((pastSeries, period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      if (index < this.todayBreakpointIndex) {
        pastSeries.push({ ...period, x: index, y: period.value });
      }
      return pastSeries;
    }, []);

    const lastPoint = this.todayBreakpointIndex > 0 ? this.todayBreakpointIndex - 1 : 0;

    if (pastData.length && this.todayBreakpointIndex > 0) {
      // Add label and marker to last point of the series
      pastData[lastPoint] = {
        ...pastData[lastPoint],
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: '600',
            fontSize: '10px',
            textShadow: 'none',
            textOutline: 0,
          },
        },
        marker: { enabled: true },
      };
    }

    const seriesColor = this.getSeriesColor(pastData[0], pastData[lastPoint]);

    return {
      name: 'Headcount',
      showInLegend: false,
      data: pastData,
      type: 'areaspline',
      color: seriesColor,
      fillColor: {
        linearGradient: {
          x1: 0, y1: 0, x2: 0, y2: 1,
        },
        stops: [
          [0, Highcharts.Color(seriesColor).setOpacity(1).get('rgba')],
          [1, Highcharts.Color(seriesColor).setOpacity(0).get('rgba')],
        ],
      },
      zIndex: 2,
    };
  }

  getForecastSeries(series) {
    const futureData = series.reduce((futureSeries, period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      // Keep last period in future series to have a continue trend
      if (index >= this.todayBreakpointIndex - 1) {
        futureSeries.push({ ...period, x: index, y: period.value });
      }
      return futureSeries;
    }, []);

    const lastPointIndex = futureData.length - 1;
    if (futureData.length) {
      // Add marker to last point of the series
      futureData[lastPointIndex] = {
        ...futureData[lastPointIndex],
        marker: { enabled: true },
      };
    }

    return {
      name: 'Forecast',
      data: futureData,
      color: this.getSeriesColor(futureData[this.todayBreakpointIndex], futureData[lastPointIndex]),
      dashStyle: 'dash',
      marker: {
        enabled: false,
        fillColor: '#FFFFFF',
      },
      zIndex: 1,
    };
  }

  getSeriesColor(startingPoint, finalPoint) {
    let color = this.colors.neutral;
    if (startingPoint && finalPoint) {
      if (finalPoint.y > startingPoint.y) {
        color = this.colors.positive;
      } else if (finalPoint.y < startingPoint.y) {
        color = this.colors.negative;
      }
    }
    return color;
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="demographics__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
