'use strict';

Wethod.module('FinanceApp.Client', function (Client, Wethod, Backbone, Marionette, $, _) {
  // model & collection client
  this.ClientModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/customer/',
  });
  this.ClientPersonPhoneModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/telephone/',
  });
  this.ClientPersonDetailModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/board/customer/',
  });
  this.ClientCompanyDetailModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/board/client/',
  });
  this.ClientCompanyModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/client/',
  });
  this.CompanyGroupModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/group/',
  });
  this.ClientResourceModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/resource/',
  });
  this.ClientsCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/client/',
    model: Client.ClientModel,
    comparator: function (model) {
      // ordino i client in ordine alfabetico decresente
      return model.get('corporate_name').toLowerCase();
    },
  });
  this.ConnectableCompaniesCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/intercompany/connectable-company',
    model: Backbone.Model,
  });
  this.Clients = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/board/contacts',
  });
  this.Companies = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/crm/client/',
  });

  // model & collection relativi all'autocompletamento
  this.AutocompleteModel = Wethod.Utility.BaseModel.extend({});
  this.AutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = '&like=' + encodeURIComponent(this.options.like);

      return APIURL + '/pipeline/autocomplete?on=client' + params;
    },
    model: Client.AutocompleteModel,
    comparator: function (model) {
      // TODO capire perchè arriva un modello che ha come unico attributo
      // like
      if (!_.isUndefined(model.get('hint'))) {
        // ordino i suggerimenti in ordine alfabetico decresente
        return model.get('hint').toLowerCase();
      }
      return '';
    },
  });

  var API = {
    getClientsList: function () {
      var collection = new Client.ClientsCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getConnectableCompanies: function () {
      var collection = new Client.ConnectableCompaniesCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data.toJSON());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getPersonClient: function (id) {
      var model = new Client.ClientPersonDetailModel({ id: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getCompanyClient: function (id) {
      var model = new Client.ClientCompanyDetailModel({ id: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getClients: function () {
      var collection = new Client.Clients();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getCompanies: function () {
      var collection = new Client.Companies();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    saveClient: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    saveCompanyGroup: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    updatePersonPhone: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    addPersonTag: function (tag) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/tag/customer/' + tag.customer_id,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify({
          id: tag.id,
          name: tag.name,
        }),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    addPersonPhone: function (phone) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/telephone/customer/' + phone.customer_id,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify({
          number: phone.number,
          label: phone.label,
        }),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    deletePersonPhone: function (phone) {
      var defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/crm/telephone/' + phone.id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    deletePersonTag: function (tag) {
      var defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/crm/tag/' + tag.id + '/customer/' + tag.customer_id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    deleteClient: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else if (response.code === 409) {
            defer.resolve(response);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (responseModel, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getClientAutocomplete: function (options, exludeClientId) {
      var collection = new Client.AutocompleteCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (responseCollection, response) {
          if (response.code === 200) {
            // rimuovo il modello da escludere
            responseCollection.remove([{ id: exludeClientId }]);

            if (responseCollection.size() > 0) {
              defer.resolve(responseCollection);
            } else {
              defer.resolve([]);
            }
          } else if (response.code === 404) {
            defer.resolve([]);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (data, response) {
          if (response.statusText !== 'abort') {
            defer.reject(undefined);
          } else {
            defer.resolve('aborted');
          }
        },
      });

      return defer.promise();
    },
    switchProjectsClient: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/client/' + options.fromId + '/switch-project/' + options.toId,
        xhrFields: {
          withCredentials: true,
        },
        data: {},
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    switchContactsClient: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/client/' + options.fromId + '/switch-customer/' + options.toId,
        xhrFields: {
          withCredentials: true,
        },
        data: {},
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    savePersonClientResource: function (clientId, resource) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/resource/customer/' + clientId,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(resource),
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    saveCompanyClientResource: function (clientId, resource) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/crm/resource/client/' + clientId,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(resource),
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    deleteClientResource: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else if (response.code === 409) {
            defer.resolve(response.data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (responseModel, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
  };

  // client list
  Wethod.reqres.setHandler('get:finance:client:list', function () { // old
    return API.getClientsList();
  });
  Wethod.reqres.setHandler('get:finance:client:person', function (id) {
    return API.getPersonClient(id);
  });
  Wethod.reqres.setHandler('get:finance:client:company', function (id) {
    return API.getCompanyClient(id);
  });
  Wethod.reqres.setHandler('save:finance:client:person:resource', function (clientId, resource) {
    return API.savePersonClientResource(clientId, resource);
  });
  Wethod.reqres.setHandler('save:finance:client:company:resource', function (clientId, resource) {
    return API.saveCompanyClientResource(clientId, resource);
  });
  Wethod.reqres.setHandler('delete:finance:client:resource', function (model) {
    return API.deleteClientResource(model);
  });
  Wethod.reqres.setHandler('get:finance:clients', function () {
    return API.getClients();
  });
  Wethod.reqres.setHandler('get:finance:companies', function () {
    return API.getCompanies();
  });
  // client autocomplete
  Wethod.reqres.setHandler('autocomplete:finance:client', function (options, exludeClientId) { // old
    return API.getClientAutocomplete(options, exludeClientId);
  });
  // client switch
  Wethod.reqres.setHandler('switch:finance:client:projects', function (options) { // old
    return API.switchProjectsClient(options);
  });
  Wethod.reqres.setHandler('switch:finance:client:contacts', function (options) { // old
    return API.switchContactsClient(options);
  });
  // save client
  Wethod.reqres.setHandler('save:finance:client', function (model) {
    return API.saveClient(model);
  });
  // save company group
  Wethod.reqres.setHandler('save:finance:company:group', function (model) {
    return API.saveCompanyGroup(model);
  });
  // delete client
  Wethod.reqres.setHandler('delete:finance:client', function (model) {
    return API.deleteClient(model);
  });
  // add tag to client
  Wethod.reqres.setHandler('add:finance:person:tag', function (tag) {
    return API.addPersonTag(tag);
  });
  // remove tag from client
  Wethod.reqres.setHandler('delete:finance:person:tag', function (tag) {
    return API.deletePersonTag(tag);
  });
  // add phone to client
  Wethod.reqres.setHandler('add:finance:person:phone', function (phone) {
    return API.addPersonPhone(phone);
  });
  // update client's phone
  Wethod.reqres.setHandler('update:finance:person:phone', function (model) {
    return API.updatePersonPhone(model);
  });
  // delete client's phone
  Wethod.reqres.setHandler('delete:finance:person:phone', function (model) {
    return API.deletePersonPhone(model);
  });
  // Get all the group's company that are still not connected to a client
  Wethod.reqres.setHandler('get:companies:connectable', function () {
    return API.getConnectableCompanies();
  });
});
