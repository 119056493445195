const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('./CollaborativeInputTooltip.react');

require('./style.scss');

class CollaborativeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focusedBy: null };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    socket.on('input-focus', this.onCollaboratorFocus.bind(this));
    socket.on('input-blur', this.onCollaboratorBlur.bind(this));
  }

  componentWillUnmount() {
    socket.off('input-focus');
    socket.off('input-blur');
  }

  onCollaboratorFocus(id, collaborator) {
    if (this.props.id === id) {
      this.setState({ focusedBy: collaborator });
    }
  }

  onCollaboratorBlur(id) {
    if (this.props.id === id) {
      this.setState({ focusedBy: null });
    }
  }

  onFocus() {
    socket.emit('input-focus', {
      room: this.props.sectionId,
      id: this.props.id,
    });
  }

  onBlur() {
    socket.emit('input-blur', {
      room: this.props.sectionId,
      id: this.props.id,
    });
  }

  getChildren() {
    return React.cloneElement(this.props.children, {
      focusedByCollaborator: this.state.focusedBy !== null,
      id: this.props.id,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    });
  }

  getCollaboratorName() {
    const collaborator = this.state.focusedBy;
    if (collaborator) {
      return `${collaborator.name} ${collaborator.surname}`;
    }

    return '';
  }

  render() {
    return <Tooltip label={this.getCollaboratorName()}>{this.getChildren()}</Tooltip>;
  }
}

CollaborativeInput.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
};

module.exports = CollaborativeInput;
