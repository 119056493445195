const React = require('react');
const MenuItem = require('../../Menu/ListMenu/MenuItem.react');
const Loader = require('../../Loader/Loader.react');

const LoadingItem = () => (
  <MenuItem className="textCenter">
    <Loader />
  </MenuItem>
);

module.exports = LoadingItem;
