const React = require('react');
const CurrencyManager = require('./CurrencyManager.react');
const Card = require('../containers/CalendarCard');
const Year = require('./YearDivider.react');

const YearlyPlan = ({ year, months, plans = {}, invoices = {} }) => {
  const getMonthEditableCards = () => months
    .map((month) => {
      const date = `${year}-${month}`;
      return (
        <CurrencyManager key={date}>
          <Card key={date}
            date={date}
            plan={plans ? plans[date] : null}
            invoices={invoices ? invoices[date] : []} />
        </CurrencyManager>
      );
    });

  return (
    <div className="invoice-plan__year-container">
      <Year year={year} />
      {getMonthEditableCards()}
    </div>
  );
};

module.exports = YearlyPlan;
