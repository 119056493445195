const React = require('react');
const WarningAccordion = require('../WarningAccordion.react');
const IntercompanyActivity = require('../../../containers/dashboard/intercompany/IntercompanyActivity');

const IntercompanyActivityAccordionReact = ({ counter }) => (
  <WarningAccordion
    title="Intercompany Activity"
    subtitle="Check on unlinked intercompany projects before changing data edit status."
    counter={counter}>
    <IntercompanyActivity />
  </WarningAccordion>
);

module.exports = IntercompanyActivityAccordionReact;
