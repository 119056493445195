const { connect } = require('react-redux');
const Component = require('../../../../../../common/react/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');

const mapStateToProps = (state) => ({
  availableOptions: state.projectLabels.map((type) => ({
    label: type.name,
    value: type.name,
  })),
});

module.exports = connect(mapStateToProps)(Component);
