/* eslint-disable react/prop-types,react/prefer-stateless-function */
const React = require('react');

class Tooltip extends React.Component {
  render() {
    return (
      <div id={this.props.id}
        className="tooltipReact"
        style={this.props.style}>
        {this.props.content}
      </div>
    );
  }
}

module.exports = Tooltip;
