/* eslint-disable react/no-access-state-in-setstate */
const React = require('react');
const Modal = require('../../TextArea/TextAreaModal/TextAreaModal.react');
const BodyView = require('../../../containers/Concept/ConceptModal/ConceptModalBodyView');
const BodyEdit = require('../../../containers/Concept/ConceptModal/ConceptModalBodyEdit');

module.exports = class ConceptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editor: false };
  }

  getBody() {
    if (this.state.editor) {
      return (
        <BodyEdit onCancelClick={this.props.onCancelClick}
          toggleEditor={this.toggleEditor.bind(this)} />
      );
    }
    return (
      <BodyView onCancelClick={this.props.onCancelClick}
        toggleEditor={this.toggleEditor.bind(this)} />
    );
  }

  toggleEditor() {
    this.setState({ editor: !this.state.editor });
  }

  render() {
    return (
      <Modal title="Edit concept"
        onCancelClick={this.props.onCancelClick}>{this.getBody()}
      </Modal>
    );
  }
};
