const React = require('react');

module.exports = ({
  totalSteps, currentStep, children, visibleStep,
}) => {
  const className = visibleStep === (currentStep - 1) ? 'project-review__step-footer-container project-review__step-footer-container--visible' : 'project-review__step-footer-container';

  return (
    <div className={className}>
      <div className="project-review__step-footer">
        <div className="project-review__step-counter">
          Step
          {`${currentStep} of ${totalSteps}`}
        </div>
        <div className="project-review__step-footer-actions">
          {children}
        </div>
      </div>
    </div>
  );
};
