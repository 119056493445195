/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

const MenuItemColorful = ({ onClick, color, children }) => {
  const style = { backgroundColor: color };
  return (
    <li className="wethod-menu__item wethod-menu__item--colorful"
      onClick={onClick}
      style={style}>
      {children}
    </li>
  );
};

module.exports = MenuItemColorful;
