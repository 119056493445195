/* eslint-disable class-methods-use-this,react/jsx-no-bind */
const React = require('react');

module.exports = class MilestoneBarEdit extends React.Component {
  getMilestoneIconClassName(type) {
    return `project-canvas-gantt__bar-icon wethod-icon wethod-icon-${type.toLowerCase()} wethod-icon-${type.toLowerCase()}--black`;
  }

  render() {
    return (
      <div className="project-canvas-gantt__bar" style={this.props.style} ref={(el) => this.barEl = el}>
        <span className={this.getMilestoneIconClassName(this.props.value.type)}>&nbsp;</span>
        <span
          className="project-canvas-gantt__bar-move-handler"
          onMouseDown={(e) => this.props.onResizeStart(e, e.target, this.barEl, this.props, 'move')} />
      </div>
    );
  }
};
