/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const { Transition } = require('react-transition-group');
const HistoryActions = require('./HistoryActions.react');
const EventsFuture = require('../containers/EventsFuture/EventsFuture');
const EventsPast = require('../containers/EventsPast/EventsPast');
const Modal = require('../containers/Modal');
const Loader = require('../../../../../../common/react/LoadingSmall.react');

module.exports = class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.prevScroll = 0;

    this.defaultStyle = {
      transition: 'opacity 200ms ease-in-out',
      opacity: 1,
    };

    this.transitionStyles = {
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
    };
  }

  componentDidMount() {
    dispatcher.on('html:page:scroll', this.onBodyScroll, this);
    this.historyHeight = $('.timeline__history').height();
    this.visibleHeight = $('#appBody').height() - 61;
  }

  componentDidUpdate() {
    this.historyHeight = $('.timeline__history').height();
  }

  isScrollingUp(scroll) {
    return scroll <= this.prevScroll;
  }

  onBodyScroll() {
    if (this.props.hasMorePages) {
      const scrollable = ((this.historyHeight - this.visibleHeight) / 4) * 3;
      const scrolled = $(window).scrollTop();
      if (!this.isScrollingUp(scrolled) && !this.props.loading && scrolled > scrollable) {
        this.props.getEventsPage(this.props.currentPage + 1, this.props.filter);
      }
      this.prevScroll = scrolled;
    }
  }

  getLoader() {
    if (this.props.loading) {
      return <Loader />;
    }
  }

  getEvents() {
    if (this.props.events.length === 0) {
      return (
        <div className="timeline__no-results">No events were found matching your
          criteria
        </div>
      );
    }
    return (state) => (
      <div className="timeline__events"
        style={{
          ...this.defaultStyle,
          ...this.transitionStyles[state],
        }}>
        <EventsFuture />
        <EventsPast />
        {this.getLoader()}
      </div>
    );
  }

  render() {
    return (
      <div className="timeline timeline-company wethod-section-body">
        <div className="timeline__history">
          <HistoryActions />
          <Transition in={!this.props.filtering} timeout={200}>
            {this.getEvents()}
          </Transition>
        </div>
        <Modal />
      </div>
    );
  }
};
