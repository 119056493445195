const { connect } = require('react-redux');
const TimesheetComponent = require('../components/Timesheet.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projects: state.filteredProjects,
  userId: state.userId,
  allProjects: state.projects,
  internationalCalendar: state.internationalCalendar,
  periodicity: state.periodicity,
  waitingFor: state.waitingFor,
  date: state.date,
  searchPlaceholder: 'Search for project, client, pm or job order',
  searchKeyword: state.projectsFilter,
  idTour: state.idTour,
  employeeCapacity: state.employeeWorkHourCapacity,
});

const mapDispatchToProps = (dispatch) => ({
  getPeriodicity: (userId) => dispatch(actions.getPeriodicity(userId)),
  updatePeriodicity: (periodicity, userId) => dispatch(actions
    .updatePeriodicity(periodicity, userId)),
  changeDate: (date, userId) => dispatch(actions.changeDateWeekly(date, userId)),
  updateHours: (project) => dispatch(actions.saveHoursWeekly(project)),
  deleteHours: (project) => dispatch(actions.deleteHoursWeekly(project)),
  search: (keyword) => dispatch(actions.filterProjects(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TimesheetComponent);
