const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');

const TableHeadRow = ({ sortBy, onSort, ...rest }) => {
  const getSort = (name) => {
    if (sortBy.col === name) {
      return sortBy.order;
    }
    return null;
  };

  return (
    <TableRow {...rest}>
      <TableCell className="allocation-request-item__project">
        Project
      </TableCell>
      <TableCell className="allocation-request-item__user">
        User
      </TableCell>
      <TableCell className="allocation-request-item__from"
        name="from"
        onSort={onSort}
        sort={getSort('from')}>
        From
      </TableCell>
      <TableCell className="allocation-request-item__to">
        To
      </TableCell>
      <TableCell className="allocation-request-item__hours">
        Hours
      </TableCell>
      <TableCell className="allocation-request-item__manager">
        Manager
      </TableCell>
      <TableCell className="allocation-request-item__status"
        name="status"
        onSort={onSort}
        sort={getSort('status')}>
        Status
      </TableCell>
      <TableCell className="allocation-request-item__actions" />
    </TableRow>
  );
};

module.exports = TableHeadRow;
