/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,
 jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class Item extends React.Component {
  onClick(e) {
    e.preventDefault();
    this.props.onClick({ ...this.props });
  }

  render() {
    return (
      <li className="wethod-filter-select__item"
        onClick={this.onClick.bind(this)}>
        <a href="#">{this.props.name}</a>
      </li>
    );
  }
};
