const React = require('react');

const SubsectionHeader = ({ id, subtitle, description }) => (
  <div>
    <h3 className="company-settings__subtitle" id={id}>
      {subtitle}
    </h3>
    <div className="company-settings__description">{description}</div>
  </div>
);

module.exports = SubsectionHeader;
