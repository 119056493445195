const React = require('react');
const { formatDate } = require('../../../../../../../../services/FormatService');
const Avatar = require('../../../../../../../../common/react/AvatarBox/Avatar.react');

module.exports = class SponsorItem extends React.Component {
  onDeleteClick() {
    this.props.deleteSponsor({ id: this.props.id });
  }

  onEndClick() {
    this.props.endSponsor({
      id: this.props.id,
      sponsor: this.props.sponsor,
      from_date: this.props.from_date,
      to_date: moment().format('YYYY-MM-DD'),
    }, this.props.employee);
  }

  getTags() {
    const tagsLength = this.props.tags.length;
    return this.props.tags.map((tag, index) => {
      if (index < tagsLength - 1) {
        return (
          <span key={tag.id}>
            {tag.name}
            ,
            {' '}
          </span>
        );
      }
      return <span key={tag.id}>{tag.name}</span>;
    });
  }

  getName() {
    return `${this.props.name} ${this.props.surname}`;
  }

  render() {
    return (
      <div className="profile-sponsor__list-item">
        <div className="profile-sponsor__list-item-pic">
          <Avatar name={this.getName()} pic={this.props.pic} size="medium" />
        </div>
        <div className="profile-sponsor__list-item-info">
          <div className="profile-sponsor__list-item-name">{this.getName()}</div>
          <div className="profile-sponsor__list-item-tags">{this.getTags()}</div>
          <div className="profile-sponsor__list-item-level">{this.props.level}</div>
        </div>
        <div className="profile-sponsor__list-item-period">
          Since {formatDate(this.props.from_date)}
        </div>
        <div className="profile-sponsor__list-item-actions">
          <button
            type="button"
            onClick={this.onEndClick.bind(this)}
            className="profile-sponsor__list-item-end wethod-button">
            End
          </button>
          <button
            type="button"
            onClick={this.onDeleteClick.bind(this)}
            className="profile-sponsor__list-item-delete wethod-icon-button">
            <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
          </button>
        </div>
      </div>
    );
  }
};
