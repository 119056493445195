const React = require('react');
const isEqual = require('react-fast-compare');

const FiltersTransformer = ({
  children, ...rest
}) => {
  const getSingleValueFilter = (name, filters) => filters[name];

  const getRangeStandardFilter = (name, filters) => {
    const minKey = `min${name}`;
    const maxKey = `max${name}`;
    const minValue = filters[minKey];
    const maxValue = filters[maxKey];
    const filter = {};

    if (minValue !== undefined) {
      filter.min = minValue;
    }
    if (maxValue !== undefined) {
      filter.max = maxValue;
    }

    return filter;
  };

  const getListStandardFilter = (name, filters) => {
    const values = filters[name];

    if (values) {
      return values.split(',');
    }
    return [];
  };

  const formatFiltersToStandard = (filters) => ({
    project: getListStandardFilter('projectName', filters),
    job_order: getListStandardFilter('jobOrder', filters),
    external: getSingleValueFilter('isEmployeeExternal', filters),
    employee: getListStandardFilter('employeeFullName', filters),
    mode: getSingleValueFilter('mode', filters),
    date: getRangeStandardFilter('Date', filters),
    client: getListStandardFilter('clientName', filters),
  });

  /**
   * Formats given filters in a way they can be easily used by AdvancedSearch.
   * @param filters {{}}
   * @returns {{}}
   */
  const getStandardFilters = (filters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersToStandard(filters),
    };

    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue.length === 0
        || isEqual(filterValue, {});
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  const getRangeFromStandard = (name, attribute, standardFilters) => (standardFilters[name]
    ? standardFilters[name][attribute]
    : null);

  const getListFromStandard = (name, standardFilters) => (standardFilters[name]
    ? standardFilters[name].join()
    : null);

  const formatFiltersFromStandard = (standardFilters) => ({
    projectName: getListFromStandard('project', standardFilters),
    jobOrder: getListFromStandard('job_order', standardFilters),
    isEmployeeExternal: getSingleValueFilter('external', standardFilters),
    employeeFullName: getListFromStandard('employee', standardFilters),
    mode: getSingleValueFilter('mode', standardFilters),
    minDate: getRangeFromStandard('date', 'min', standardFilters),
    maxDate: getRangeFromStandard('date', 'max', standardFilters),
    clientName: getListFromStandard('client', standardFilters),
  });

  /**
   * Returns flattened filters, this way they can be easily converted in query string.
   * @param standardFilters {{}}
   * @returns {{}}
   */
  const getFiltersFromStandard = (standardFilters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersFromStandard(standardFilters),
    };
    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue === null || filterValue === '';
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  return React
    .cloneElement(children, {
      ...rest,
      getStandardFilters,
      getFiltersFromStandard,
    });
};

module.exports = FiltersTransformer;
