const { connect } = require('react-redux');
const Actions = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isLoading: state.waitingFor.filter((key) => key === 'filter-travels' || key === 'load-travels' || 'get-travel-offices').length > 0,
  isSaving: state.waitingFor.filter((key) => key === 'save-travel').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  addTravel: () => dispatch(actions.showSidebar({})),
  showExportModal: () => dispatch(actions.showExportModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Actions);
