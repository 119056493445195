const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Spreadsheet/HeadContent.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  projectId: state.info.id,
  canEdit: state.canEdit,
});

const mapDispatchToProps = (dispatch) => ({
  editAttributeName: (boardId, attributeId, name) => dispatch(actions
    .editBoardStructureAttributeName(boardId, attributeId, name)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
