const React = require('react');

const CalendarHeaderItemWeek = ({ days, isFirstDayOfMonth, last }) => {
  const from = moment(days[0]);
  const to = moment(days[days.length - 1]);

  const getFormattedLabel = () => `${moment(from).format('DD')} - ${moment(to).format('DD')}`;

  const isCurrent = () => from.isSame(moment(), 'week');

  const getClassName = () => {
    let name = 'planning-calendar__header-item';
    if (isCurrent()) {
      name += ' planning-calendar__header-item--today';
    }
    return name;
  };

  const getMonth = () => {
    const style = last ? {
      left: 'auto',
      right: 0,
    } : null;
    if (isFirstDayOfMonth) {
      const label = moment(days[days.length - 1]).format('MMMM YYYY');

      return (
        <span
          className="planning-calendar__header-month"
          style={style}>
          {label}
        </span>
      );
    }
    return null;
  };

  return (
    <div className={getClassName()}>
      {getMonth()}
      {getFormattedLabel()}
    </div>
  );
};

module.exports = CalendarHeaderItemWeek;
