const React = require('react');

module.exports = class GrossMarginPerEmployeeChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        backgroundColor: '#FCFCFC',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
            formatter() {
              return numeral(this.y).format('0.0');
            },
          },
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null, // inherit from series
            marker: 'circle',
          },
        },
      },
      xAxis: {
        categories: [
          '3 m ago',
          '2 m ago',
          'Last',
          '',
        ],
        lineColor: '#CACACA',
        labels: {
          style: {
            textOverflow: 'none',
          },
        },
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        min: this.props.min,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        color: 'rgba(188,158,193,0.3)',
        marker: {
          symbol: 'circle',
        },
        data: [this.props.prevYearTwoMonthsAgo, this.props.prevYearPrevMonth,
          this.props.prevYearThisMonth],
      }, {
        color: '#BC9EC1',
        marker: {
          symbol: 'circle',
        },
        data: [this.props.twoMonthsAgo, this.props.prevMonth, {
          y: this.props.thisMonth,
          dataLabels: {

            style: {
              fontWeight: 'bold',
            },
          },
        }],
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="briefing-aside-chart"
        id="briefing-aside-chart-monthly__gross-margin-per-employee">
        <div className="briefing-aside-chart__header">
          <h5 className="briefing-aside-chart__title">Gross Margin Per Employee</h5>
          <h6 className="briefing-aside-chart__subtitle">in thousands</h6>
        </div>
        <div className="briefing-aside-chart__content"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
        <div className="briefing-aside-chart__legenda">
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--gmpe-this-year">
            This
            year
          </div>
          <div
            className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--gmpe-same-week-last-year">
            Same
            months last year
          </div>
        </div>
      </div>
    );
  }
};
