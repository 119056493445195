const { connect } = require('react-redux');
const Component = require('../../components/Kpi/Kpi.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  clientId: state.id,
  revenues: state.revenues,
  isWaitingForRevenues: state.isWaitingForRevenues || state.isWaitingForRevenuesTargets,
  isWaitingForProjects: state.isWaitingForProjects,
  timeFilter: state.timeFilter,
  projects: state.projects.filter((project) => project.probability >= 90),
  projectsInFrame: state.filteredProjects.filter((project) => project.probability >= 90),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeCompanySidebar()),
  updateTimeFilter: (filter) => dispatch(actions.updateTimeFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
