const { connect } = require('react-redux');
const Component = require('../../../components/Header/FinalPrice/FinalPrice.react');

const mapStateToProps = (state) => ({
  price: state.info.final_net_price,
  previousPrice: state.info.was ? state.info.was.final_net_price : null,
  totalCosts: state.info.total_cost,
  externalCosts: state.info.total_external_cost,
  exchangeRateDate: state.info.exchange_rate_date,
  budgetCurrencyCode: state.info.currency,
  invoiceDrivenExchangeRate: state.info.exchange_rate !== null,
  isIntercompanySupplier: state.project.intercompany,
});

module.exports = connect(mapStateToProps)(Component);
