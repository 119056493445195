/* eslint-disable no-underscore-dangle,class-methods-use-this,no-param-reassign */
/**
 * A calendar is made of columns (that represents its unit of measure: a day, a month, etc) and points
 * (which represents events that need to be drawn on the calendar).
 *
 * @type {CalendarModel}
 */
module.exports = class Calendar {
  /**
   * @param from {Moment} first day in the calendar
   * @param to {Moment} last day in the calendar
   * @param columnWidth {number} width of a column in px
   * @param pointWidth {number} width of a point in px
   * @param columnToScroll {number} How many columns to scroll when using navigator's arrows
   * @param type {string} days|weeks|months|years
   * @param pointType {string} days|weeks|months|years can differ from type (which is column's type)
   */
  constructor(from, to, columnWidth, pointWidth, columnToScroll, type, pointType) {
    this._columnWidth = columnWidth;
    this._pointWidth = pointWidth;
    this._columnsToScroll = columnToScroll;
    this._type = type;
    this._pointType = pointType;
    this._points = this._getPointsInPeriod(from, to);
  }

  /**
   * @return {number}
   */
  columnsToScroll() {
    return this._columnsToScroll;
  }

  /**
   * @return {number}
   */
  columnWidth() {
    return this._columnWidth;
  }

  /**
   * @return {number}
   */
  pointWidth() {
    return this._pointWidth;
  }

  /**
   * @return {[]}
   */
  points() {
    return this._points;
  }

  /**
   * @return {string}
   */
  type() {
    return this._type;
  }

  /**
   * @return {string}
   */
  pointType() {
    return this._pointType;
  }

  /**
   * Returns an array of points between from e to.
   * @param from {Moment}
   * @param to {Moment}
   * @return {[]}
   */
  _getPointsInPeriod() {
    throw new TypeError('Please instantiate a _getPointsInPeriod method');
  }

  /**
   * Add "amount" of time in the future to points and return them.
   * @param amount
   * @return {[]}
   */
  addFuture() {
    throw new TypeError('Please instantiate a addFuture method');
  }

  /**
   * Returns the count of points between "from" and the first point.
   * "From" is today if null given.
   * @param from {Moment}
   * @return {number}
   */
  getPast(from) {
    from = from || moment();
    return from.diff(this._points[0], this._type);
  }

  /**
   * Add "amount" of time in the past to points and return them.
   * @param amount
   * @return {[]}
   */
  addPast() {
    throw new TypeError('Please instantiate a addPast method');
  }

  /**
   * Returns a date ready to be displayed as a calendar bar.
   * @param attribute a date
   * @return {*}
   */
  getDate() {
    throw new TypeError('Please instantiate a getDate method');
  }

  /**
   * Returns a milestone ready to be displayed as a calendar bar.
   * @param attribute a milestone
   * @return {*}
   */
  getMilestone() {
    throw new TypeError('Please instantiate a getMilestone method');
  }
};
