const React = require('react');
const IconLinkTooltip = require('../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

const CompanySidebarActions = ({ client, canEdit, onDelete }) => {
  const link = client ? `#finance/client/company/${client.id}` : '';
  return (
    <div>
      <ShowIf condition={client != null}>
        <IconLinkTooltip href={link}
          label="Go to company page"
          borderless>
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLinkTooltip>
      </ShowIf>
      <ShowIf condition={canEdit && client != null}>
        <div className="wethod-vertical-line" />
      </ShowIf>
      <ShowIf condition={canEdit}>
        <IconButtonTooltip label="Delete client" onClick={onDelete} borderless>
          <span className="wethod-icon-delete wethod-icon-delete--black" />
        </IconButtonTooltip>
      </ShowIf>
    </div>
  );
};

module.exports = CompanySidebarActions;
