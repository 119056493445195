const { connect } = require('react-redux');
const DeleteCompanyFailureModal = require('../components/DeleteCompanyFailureModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  client: state.focusedItem,
  isWaiting: state.isDeletingClient,
  projects: state.linkedProjects,
  contacts: state.linkedContacts,
  companies: state.companies.filter((company) => (company.id !== state.focusedItem.id)),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
  replaceClient: (from, toId) => dispatch(actions.replaceClientCompany(from, toId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyFailureModal);
