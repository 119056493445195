/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const SidebarInput = require('./SidebarWrapperEditable.react');
const TextArea = require('../TextArea/TextArea.react');

/**
 * An outlined text area component, material styled.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name: string
 * value: string
 * rows: number of rows visible in the text-area
 * maxLength: max number of character editable in the text-area
 *
 * onFocus
 * onChange
 * onBlur
 *
 * @type {module.SidebarTextArea}
 *
 * @deprecated
 */
module.exports = class SidebarTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = this.getConstraints();
  }

  getConstraints() {
    if (this.props.required && this.props.required === true) return 'required';
  }

  render() {
    return (
      <SidebarInput placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}
        inputType="textarea">
        <TextArea name={this.props.name}
          rows={this.props.rows}
          maxLength={this.props.maxLength}
          value={this.props.value || ''}
          constraints={this.constraints}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          onBlur={this.props.onBlur} />
      </SidebarInput>
    );
  }
};
