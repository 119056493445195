const { connect } = require('react-redux');
const Component = require('../components/Plan.react');

// Get auto plan or manual plan values according to the status
function getPlan(state) {
  const manual = state.plan.status === 1;
  return manual ? state.plan.plan_amounts : state.autoPlan;
}

const mapStateToProps = (state) => ({
  plans: getPlan(state),
  invoices: state.invoices,
  compact: state.view === 'compact',
  start: state.project.date_start,
});

module.exports = connect(mapStateToProps, null)(Component);
