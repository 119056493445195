const React = require('react');
const PropTypes = require('prop-types');
require('./style.scss');

const InputLabel = ({ children, htmlFor, className }) => (
  <label className={`wethod-input-label ${className}`} htmlFor={htmlFor}>{children}</label>
);

InputLabel.defaultProps = {
  className: '',
};

InputLabel.propTypes = {
  /** Label content * */
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

module.exports = InputLabel;
