const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const DailyCostPriceLevelModalBody = require('./DailyCostPriceLevelModalBody.react');
const BudgetLevel = require('../../../../../../models/BudgetLevel');

module.exports = class DailyCostPriceLevelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getPriceListsForLevel();
  }

  getPriceListsForLevel() {
    this.setState({ isLoading: true });
    BudgetLevel.getBudgetPriceListsForLevel(
      this.props.projectId, this.props.budgetVersion, this.props.levelId,
    )
      .done((response) => this.setState({
        priceLists: response.levels && response.levels.length
          ? response.levels[0].price_list_levels
          : [],
      }))
      .always(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <Modal title={`${this.props.levelName} Pricelists`}
        onClose={this.props.closeModal}
        className="wethod-modal-pricelist">
        <DailyCostPriceLevelModalBody isLoading={this.state.isLoading}
          priceLists={this.state.priceLists || []} />
      </Modal>
    );
  }
};
