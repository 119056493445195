const React = require('react');

module.exports = class Notes extends React.Component {
  getUnescapedContent() {
    return _.unescape(this.props.content);
  }

  render() {
    return (
      <div className="client-person__notes">{this.getUnescapedContent()}</div>
    );
  }
};
