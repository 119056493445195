const request = require('./Request');

const BudgetPricelist = {
  /**
   * Update budget related pricelist.
   * @param {number} budgetId
   * @param {number} pricelistId
   * @return {*}
   */
  update(budgetId, pricelistId) {
    return request({
      method: 'put',
      url: `/br/budget/${budgetId}/price-list`,
      data: {
        price_list_id: pricelistId,
      },
    });
  },
};

module.exports = BudgetPricelist;
