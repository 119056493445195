const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/Reasons.react');

const mapStateToProps = (state) => ({
  mainWonReason: state.main_reason_why_won,
  mainWonReasonPerc: state.main_reason_why_won_perc,
  mainLostReason: state.main_reason_why_lost,
  mainLostReasonPerc: state.main_reason_why_lost_perc,
  wonProjects: state.won_projects_count,
  lostProjects: state.month_won_projects_count,
});

module.exports = connect(mapStateToProps)(Component);
