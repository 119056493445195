/* eslint-disable no-shadow,no-use-before-define,no-restricted-globals */
const constants = require('./constants');
const BriefModel = require('./models/BriefModel');
const ConceptModel = require('./models/ConceptModel');
const GoalModel = require('./models/GoalsCollection');
const BoardCollection = require('./models/BoardCollection');
const BoardModel = require('./models/BoardModel');
const BoardAttributeModel = require('./models/BoardAttributeModel');
const BoardItemModel = require('./models/BoardItemModel');
const BoardAttributeValueModel = require('./models/BoardAttributeValueModel');
const StatusModel = require('./models/StatusModel');
const EmployeeCollection = require('./models/EmployeeCollection');
const KudosModel = require('./models/KudosModel');

const getBriefSuccess = (brief) => ({
  type: constants.GET_BRIEF_SUCCESS,
  brief,
});

const getBriefRequest = () => ({
  type: constants.GET_BRIEF_REQUEST,
});

module.exports.getBrief = (projectId) => (dispatch) => {
  dispatch(getBriefRequest());
  const model = new BriefModel(null, { projectId });
  $.when(model.getModel())
    .done((brief) => dispatch(getBriefSuccess(brief)));
};

const saveBriefSuccess = (brief) => ({
  type: constants.SAVE_BRIEF_SUCCESS,
  brief,
});

const saveBriefRequest = () => ({
  type: constants.SAVE_BRIEF_REQUEST,
});

module.exports.saveBrief = (brief, projectId) => (dispatch) => {
  dispatch(saveBriefRequest());
  const model = new BriefModel(brief, { projectId });
  $.when(model.updateModel())
    .done((brief) => dispatch(saveBriefSuccess(brief)));
};

const getConceptSuccess = (concept) => ({
  type: constants.GET_CONCEPT_SUCCESS,
  concept,
});

const getConceptRequest = () => ({
  type: constants.GET_CONCEPT_REQUEST,
});

module.exports.getConcept = (projectId) => (dispatch) => {
  dispatch(getConceptRequest());
  const model = new ConceptModel(null, { projectId });
  $.when(model.getModel())
    .done((concept) => dispatch(getConceptSuccess(concept)));
};

const saveConceptSuccess = (concept) => ({
  type: constants.SAVE_CONCEPT_SUCCESS,
  concept,
});

const saveConceptRequest = () => ({
  type: constants.SAVE_CONCEPT_REQUEST,
});

module.exports.saveConcept = (concept, projectId) => (dispatch) => {
  dispatch(saveConceptRequest());
  const model = new ConceptModel(concept, { projectId });
  $.when(model.updateModel())
    .done((concept) => dispatch(saveConceptSuccess(concept)));
};

const getGoalsSuccess = (goals) => ({
  type: constants.GET_GOALS_SUCCESS,
  goals,
});

const getGoalsRequest = () => ({
  type: constants.GET_GOALS_REQUEST,
});

module.exports.getGoals = (projectId) => (dispatch) => {
  dispatch(getGoalsRequest());
  const model = new GoalModel(null, { projectId });
  $.when(model.getModel())
    .done((goals) => dispatch(getGoalsSuccess(goals)));
};

const saveGoalSuccess = (goal) => ({
  type: constants.SAVE_GOAL_SUCCESS,
  goal,
});

const saveGoalRequest = () => ({
  type: constants.SAVE_GOAL_REQUEST,
});

const createGoalSuccess = (goal) => ({
  type: constants.CREATE_GOAL_SUCCESS,
  goal,
});

const updateGoalWithoutPersist = (goal) => ({
  type: constants.UPDATE_GOAL,
  goal,
});

const createGoal = (goal, projectId) => (dispatch) => {
  dispatch(saveGoalRequest());

  const model = new GoalModel(goal, { projectId });
  $.when(model.saveModel(goal)).done((goal) => dispatch(createGoalSuccess(goal)));
};

const updateGoal = (goal, projectId, persist) => (dispatch) => {
  if (persist) {
    dispatch(saveGoalRequest());
    const model = new GoalModel(goal, { projectId });
    $.when(model.updateModel(goal)).done((goal) => dispatch(saveGoalSuccess(goal)));
  } else {
    dispatch(updateGoalWithoutPersist(goal));
  }
};

module.exports.saveGoal = (goal, projectId, persist = true) => {
  const formattedGoal = {
    ...goal,
    progress: parseInt(goal.progress),
    target: parseInt(goal.target),
  };
  if (formattedGoal.id) {
    return updateGoal(formattedGoal, projectId, persist);
  }
  return createGoal(formattedGoal, projectId);
};

const deleteGoalSuccess = (goal) => ({
  type: constants.DELETE_GOAL_SUCCESS,
  goal,
});

const deleteGoalRequest = () => ({
  type: constants.DELETE_GOAL_REQUEST,
});

module.exports.deleteGoal = (goal, projectId) => (dispatch) => {
  dispatch(deleteGoalRequest());
  const model = new GoalModel(goal, { projectId });
  $.when(model.deleteModel(goal))
    .done(() => dispatch(deleteGoalSuccess(goal)));
};

const closeModal = () => ({
  type: constants.MODAL_CLOSE,
});

module.exports.showBriefModal = () => ({
  type: constants.MODAL_SHOW_BRIEF,
});

module.exports.showDeleteConfirmModal = (data, elementType) => ({
  type: constants.MODAL_SHOW_DELETE,
  data,
  element: elementType,
});

module.exports.showMissingStatusForKanbanModal = () => ({
  type: constants.MODAL_MISSING_STATUS_FOR_KANBAN,
});

const showServerErrorModal = (data) => ({
  type: constants.MODAL_CANNOT_DOWNLOAD_FILE,
  data,
});

module.exports.showConceptModal = () => ({
  type: constants.MODAL_SHOW_CONCEPT,
});

module.exports.showGoalModal = (goalId = null) => ({
  type: constants.MODAL_SHOW_GOAL,
  goalId,
});

module.exports.showReviewRequestModal = (data) => ({
  type: constants.MODAL_REQUEST_REVIEW,
  data,
});

const showReviewStartModal = (data) => ({
  type: constants.MODAL_START_REVIEW,
  data,
});

const getBoardsSuccess = (boards) => ({
  type: constants.GET_BOARDS_SUCCESS,
  boards,
});

const getBoardsRequest = () => ({
  type: constants.GET_BOARDS_REQUEST,
});

module.exports.getBoards = (projectId) => (dispatch) => {
  dispatch(getBoardsRequest());
  const collection = new BoardCollection(null, { projectId });
  $.when(collection.getList())
    .done((boards) => dispatch(getBoardsSuccess(boards)));
};

const addBoardSuccess = (board) => ({
  type: constants.ADD_BOARD_SUCCESS,
  board,
});

const addBoardRequest = () => ({
  type: constants.ADD_BOARD_REQUEST,
});

module.exports.addBoard = (projectId, board) => (dispatch) => {
  dispatch(addBoardRequest());
  const model = new BoardModel(board, { projectId });
  $.when(model.saveModel())
    .done((newBoard) => dispatch(addBoardSuccess({
      type: 'spreadsheet',
      structure: [],
      ...newBoard,
    })));
};

const deleteBoardSuccess = (boardId) => ({
  type: constants.DELETE_BOARD_SUCCESS,
  boardId,
});

const deleteBoardRequest = (boardId) => ({
  type: constants.DELETE_BOARD_REQUEST,
  boardId,
});

module.exports.deleteBoard = (projectId, boardId) => (dispatch) => {
  dispatch(deleteBoardRequest(boardId));
  const model = new BoardModel({ id: boardId }, { projectId });
  $.when(model.deleteModel()).done(() => dispatch(deleteBoardSuccess(boardId)));
};

const editBoardNameSuccess = (boardId) => ({
  type: constants.EDIT_BOARD_NAME_SUCCESS,
  boardId,
});

const editBoardNameRequest = (boardId, name) => ({
  type: constants.EDIT_BOARD_NAME_REQUEST,
  boardId,
  name,
});

module.exports.editBoardName = (projectId, boardId, name) => (dispatch) => {
  dispatch(editBoardNameRequest(boardId, name));
  const model = new BoardModel({ name }, { projectId });
  $.when(model.saveName(boardId))
    .done(() => dispatch(editBoardNameSuccess(boardId)));
};

const getBoardContentSuccess = (boardId, content) => ({
  type: constants.GET_BOARD_CONTENT_SUCCESS,
  boardId,
  content,
});

const getBoardContentRequest = (boardId) => ({
  type: constants.GET_BOARD_CONTENT_REQUEST,
  boardId,
});

module.exports.getBoardContent = (projectId, boardId) => (dispatch) => {
  dispatch(getBoardContentRequest(boardId));
  const model = new BoardModel(null, { projectId });
  $.when(model.getContent(boardId))
    .done((content) => dispatch(getBoardContentSuccess(boardId, content)));
};

const addBoardItemSuccess = (boardId, item) => ({
  type: constants.ADD_BOARD_ITEM_SUCCESS,
  boardId,
  item,
});

const addBoardItemRequest = (boardId) => ({
  type: constants.ADD_BOARD_ITEM_REQUEST,
  boardId,
});

module.exports.addBoardItem = (boardId, item) => {
  const itemForModel = {
    ...item,
    attributes: item.attributes.map((attribute) => {
      if (attribute.value.id !== undefined) { // value is an object (like for status)
        return {
          id: attribute.id,
          value: attribute.value.id,
        };
      }
      return {
        id: attribute.id,
        value: attribute.value,
      };
    }),
  };
  return (dispatch) => {
    dispatch(addBoardItemRequest(boardId));
    const model = new BoardItemModel(itemForModel, { boardId });
    $.when(model.saveModel())
      .done((newItem) => dispatch(addBoardItemSuccess(boardId, {
        ...newItem,
        attributes: item.attributes,
      })));
  };
};

const editBoardItemNameSuccess = (boardId, itemId) => ({
  type: constants.EDIT_BOARD_ITEM_NAME_SUCCESS,
  boardId,
  itemId,
});

const editBoardItemNameRequest = (boardId, itemId, name) => ({
  type: constants.EDIT_BOARD_ITEM_NAME_REQUEST,
  boardId,
  itemId,
  name,
});

module.exports.editBoardItemName = (boardId, itemId, name) => (dispatch) => {
  dispatch(editBoardItemNameRequest(boardId, itemId, name));
  const model = new BoardItemModel({ name }, { boardId });
  $.when(model.saveName(itemId))
    .done(() => dispatch(editBoardItemNameSuccess(boardId, itemId)));
};

const deleteBoardItemSuccess = (boardId, itemId) => ({
  type: constants.DELETE_BOARD_ITEM_SUCCESS,
  boardId,
  itemId,
});

const deleteBoardItemRequest = (boardId, itemId) => ({
  type: constants.DELETE_BOARD_ITEM_REQUEST,
  boardId,
  itemId,
});

module.exports.deleteBoardItem = (boardId, itemId) => (dispatch) => {
  dispatch(deleteBoardItemRequest(boardId, itemId));
  const model = new BoardItemModel({ id: itemId }, { boardId });
  $.when(model.deleteModel())
    .done(() => dispatch(deleteBoardItemSuccess(boardId, itemId)));
};

const deleteBoardStructureAttributeSuccess = (boardId, attributeId) => ({
  type: constants.DELETE_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS,
  boardId,
  attributeId,
});

const deleteBoardStructureAttributeRequest = (boardId, attributeId) => ({
  type: constants.DELETE_BOARD_STRUCTURE_ATTRIBUTE_REQUEST,
  boardId,
  attributeId,
});

module.exports.deleteBoardStructureAttribute = (boardId, attributeId) => (dispatch) => {
  dispatch(deleteBoardStructureAttributeRequest(boardId, attributeId));
  const model = new BoardAttributeModel({ id: attributeId }, { boardId });
  $.when(model.deleteModel())
    .done(() => dispatch(deleteBoardStructureAttributeSuccess(boardId, attributeId)));
};

const addBoardStructureAttributeSuccess = (boardId, attribute, options) => ({
  type: constants.ADD_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS,
  boardId,
  attribute,
  options,
});

const addBoardStructureAttributeRequest = (boardId) => ({
  type: constants.ADD_BOARD_STRUCTURE_ATTRIBUTE_REQUEST,
  boardId,
});

module.exports.addBoardStructureAttribute = (boardId, attribute) => (dispatch) => {
  dispatch(addBoardStructureAttributeRequest(boardId));
  const model = new BoardAttributeModel(attribute, { boardId });
  $.when(model.saveModel())
    .done((newAttribute) => dispatch(addBoardStructureAttributeSuccess(boardId, newAttribute)));
};

const editBoardStructureAttributeNameSuccess = (boardId, attributeId) => ({
  type: constants.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_SUCCESS,
  boardId,
  attributeId,
});

const editBoardStructureAttributeNameRequest = (boardId, attributeId, name) => ({
  type: constants.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_REQUEST,
  boardId,
  attributeId,
  name,
});

module.exports.editBoardStructureAttributeName = (boardId, attributeId, name) => (dispatch) => {
  dispatch(editBoardStructureAttributeNameRequest(boardId, attributeId, name));
  const model = new BoardAttributeModel({ name }, { boardId });
  $.when(model.saveName(attributeId))
    .done(() => dispatch(editBoardStructureAttributeNameSuccess(boardId, attributeId)));
};

const editBoardAttributeValueSuccess = (boardId, itemId, attributeId) => ({
  type: constants.EDIT_BOARD_ATTRIBUTE_VALUE_SUCCESS,
  boardId,
  itemId,
  attributeId,
});

const editBoardAttributeValueRequest = (boardId, itemId, attributeId, value) => ({
  type: constants.EDIT_BOARD_ATTRIBUTE_VALUE_REQUEST,
  boardId,
  itemId,
  attributeId,
  value,
});

module.exports.editBoardAttributeValue = (boardId, itemId, attributeId, value) => (dispatch) => {
  dispatch(editBoardAttributeValueRequest(boardId, itemId, attributeId, value));
  const model = new BoardAttributeValueModel({ value }, {
    boardId,
    itemId,
    attributeId,
  });
  $.when(model.saveValue())
    .done(() => dispatch(editBoardAttributeValueSuccess(boardId, itemId, attributeId)));
};

const editBoardItemValuesSuccess = (boardId, itemId) => ({
  type: constants.EDIT_BOARD_ITEM_VALUES_SUCCESS,
  boardId,
  itemId,
});

const editBoardItemValuesRequest = (boardId, itemId, item) => ({
  type: constants.EDIT_BOARD_ITEM_VALUES_REQUEST,
  boardId,
  itemId,
  value: item,
});

// Update the values of an item, given the list of attributes with their values
module.exports.editBoardItemValues = (boardId, itemId, item) => (dispatch) => {
  dispatch(editBoardItemValuesRequest(boardId, itemId, item));
  const model = new BoardAttributeValueModel({}, {
    boardId,
    itemId,
  });
  $.when(model.saveItemValues(item))
    .done(() => dispatch(editBoardItemValuesSuccess(boardId, itemId)));
};

const addBoardAttributeMemberRequest = (boardId, itemId, attributeId, member) => ({
  type: constants.ADD_BOARD_ATTRIBUTE_MEMBER_REQUEST,
  boardId,
  itemId,
  attributeId,
  member,
});

module.exports.addBoardAttributeMember = (boardId, itemId, attributeId, member) => (dispatch) => {
  dispatch(addBoardAttributeMemberRequest(boardId, itemId, attributeId, member));
  const model = new BoardAttributeValueModel({ value: member.id }, {
    boardId,
    itemId,
    attributeId,
  });
  $.when(model.saveValue())
    .done(() => dispatch(editBoardAttributeValueSuccess(boardId, itemId, attributeId)));
};

const deleteStatusSuccess = (boardId, attributeId, statusId) => ({
  type: constants.DELETE_STATUS_SUCCESS,
  boardId,
  attributeId,
  statusId,
});

const deleteStatusRequest = (boardId, attributeId, statusId) => ({
  type: constants.DELETE_STATUS_REQUEST,
  boardId,
  attributeId,
  statusId,
});

module.exports.deleteStatus = (boardId, attributeId, statusId) => (dispatch) => {
  dispatch(deleteStatusRequest(boardId, attributeId, statusId));
  const model = new StatusModel(status, {
    boardId,
    attributeId,
  });
  $.when(model.deleteModel(statusId))
    .done(() => dispatch(deleteStatusSuccess(boardId, attributeId, statusId)));
};

const updateStatusSuccess = (boardId, attributeId, status) => ({
  type: constants.UPDATE_STATUS_SUCCESS,
  boardId,
  attributeId,
  status,
});

const updateStatusRequest = (boardId, attributeId, status) => ({
  type: constants.UPDATE_STATUS_REQUEST,
  boardId,
  attributeId,
  status,
});

module.exports.updateStatus = (boardId, attributeId, status) => (dispatch) => {
  dispatch(updateStatusRequest(boardId, attributeId, status));
  const model = new StatusModel(status, {
    boardId,
    attributeId,
  });
  $.when(model.updateModel(status.id))
    .done((status) => dispatch(updateStatusSuccess(boardId, attributeId, status)));
};

const createStatusSuccess = (boardId, attributeId, status) => ({
  type: constants.CREATE_STATUS_SUCCESS,
  boardId,
  attributeId,
  status,
});

const createStatusRequest = (boardId, attributeId) => ({
  type: constants.CREATE_STATUS_REQUEST,
  boardId,
  attributeId,
});

module.exports.createStatus = (boardId, attributeId, status) => (dispatch) => {
  dispatch(createStatusRequest(boardId, attributeId));
  const model = new StatusModel(status, {
    boardId,
    attributeId,
  });
  $.when(model.saveModel())
    .done((status) => dispatch(createStatusSuccess(boardId, attributeId, status)));
};

const saveBoardsSortSuccess = () => ({
  type: constants.SAVE_BOARDS_SORT_SUCCESS,
});

const saveBoardsSortRequest = () => ({
  type: constants.SAVE_BOARDS_SORT_REQUEST,
});

module.exports.saveBoardsSort = (projectId, boards) => (dispatch) => {
  dispatch(saveBoardsSortRequest());
  const model = new BoardModel(null, { projectId });
  $.when(model.saveSort(boards))
    .done(() => dispatch(saveBoardsSortSuccess()));
};

module.exports.updateBoardSort = (projectId, boards) => ({
  type: constants.UPDATE_BOARDS_SORT,
  boards,
});

const saveSpreadsheetItemsSortSuccess = (boardId) => ({
  type: constants.SAVE_SPREADSHEET_ITEMS_SORT_SUCCESS,
  boardId,
});

const saveSpreadsheetItemsSortRequest = (boardId) => ({
  type: constants.SAVE_SPREADSHEET_ITEMS_SORT_REQUEST,
  boardId,
});

module.exports.saveSpreadsheetItemsSort = (boardId, items) => (dispatch) => {
  dispatch(saveSpreadsheetItemsSortRequest(boardId));
  const model = new BoardItemModel(null, { boardId });
  $.when(model.saveSortSpreadsheet(items))
    .done(() => dispatch(saveSpreadsheetItemsSortSuccess(boardId)));
};

module.exports.updateSpreadsheetItemsSort = (boardId, items) => ({
  type: constants.UPDATE_SPREADSHEET_ITEMS_SORT,
  boardId,
  items,
});

const saveStatusAvailableValuesSortSuccess = (boardId, attributeId) => ({
  type: constants.SAVE_STATUS_AVAILABLE_VALUES_SORT_SUCCESS,
  boardId,
  attributeId,
});

const saveStatusAvailableValuesSortRequest = (boardId, attributeId) => ({
  type: constants.SAVE_STATUS_AVAILABLE_VALUES_SORT_REQUEST,
  boardId,
  attributeId,
});

module.exports.saveStatusAvailableValuesSort = (boardId, attributeId, values) => (dispatch) => {
  dispatch(saveStatusAvailableValuesSortRequest(boardId, attributeId));
  const model = new BoardAttributeModel(null, { boardId });
  $.when(model.saveStatusSort(attributeId, values))
    .done(() => dispatch(saveStatusAvailableValuesSortSuccess(boardId, attributeId)));
};

module.exports.updateStatusAvailableValuesSort = (boardId, attributeId, values) => ({
  type: constants.UPDATE_STATUS_AVAILABLE_VALUES_SORT,
  boardId,
  attributeId,
  values,
});

module.exports.updateKanbanItemsSort = (boardId, items) => {
  const model = new BoardItemModel(null, { boardId });
  model.saveSortKanban(items);

  return {
    type: constants.UPDATE_KANBAN_ITEMS_SORT,
    boardId,
    items,
  };
};

module.exports.updateKanbanItemList = (boardId, itemId, attributeId, value, sortedItems) => {
  const boardModel = new BoardItemModel(null, { boardId });
  const attributeModel = new BoardAttributeValueModel({ value }, {
    boardId,
    itemId,
    attributeId,
  });
  boardModel.saveSortKanban(sortedItems);
  attributeModel.saveValue();

  return {
    type: constants.UPDATE_KANBAN_ITEM_LIST,
    boardId,
    itemId,
    attributeId,
    value,
    sortedItems,
  };
};

const saveSpreadsheetColumnsSortSuccess = (boardId) => ({
  type: constants.SAVE_SPREADSHEET_COLUMNS_SORT_SUCCESS,
  boardId,
});

const saveSpreadsheetColumnsSortRequest = (boardId) => ({
  type: constants.SAVE_SPREADSHEET_COLUMNS_SORT_REQUEST,
  boardId,
});

module.exports.saveSpreadsheetColumnsSort = (boardId, columns) => (dispatch) => {
  dispatch(saveSpreadsheetColumnsSortRequest(boardId));
  const model = new BoardAttributeModel(null, { boardId });
  $.when(model.saveSort(columns))
    .done(() => dispatch(saveSpreadsheetColumnsSortSuccess(boardId)));
};

module.exports.updateSpreadsheetColumnsSort = (boardId, structure, items) => ({
  type: constants.UPDATE_SPREADSHEET_COLUMNS_SORT,
  boardId,
  structure,
  items,
});

const saveSpreadsheetColumnsSummarySuccess = (boardId, attributeId) => ({
  type: constants.SAVE_SPREADSHEET_COLUMN_SUMMARY_SUCCESS,
  boardId,
  attributeId,
});

const saveSpreadsheetColumnsSummaryRequest = (boardId, attributeId, summary) => ({
  type: constants.SAVE_SPREADSHEET_COLUMN_SUMMARY_REQUEST,
  boardId,
  attributeId,
  summary,
});

module.exports.saveSpreadsheetColumnsSummary = (boardId, attributeId, summary) => (dispatch) => {
  dispatch(saveSpreadsheetColumnsSummaryRequest(boardId, attributeId, summary));
  const model = new BoardAttributeModel({ summary }, { boardId });
  $.when(model.saveSummary(attributeId))
    .done(() => dispatch(saveSpreadsheetColumnsSummarySuccess(boardId, attributeId)));
};

const deleteMemberRequest = (boardId, itemId, attributeId, memberId) => ({
  type: constants.DELETE_ATTRIBUTE_MEMBER_VALUE_REQUEST,
  boardId,
  attributeId,
  itemId,
  memberId,
});

const deleteMemberSuccess = (boardId, itemId, attributeId, memberId) => ({
  type: constants.DELETE_ATTRIBUTE_MEMBER_VALUE_SUCCESS,
  boardId,
  attributeId,
  itemId,
  memberId,
});

module.exports.deleteMember = (boardId, itemId, attributeId, memberId) => (dispatch) => {
  dispatch(deleteMemberRequest(boardId, itemId, attributeId, memberId));
  const model = new BoardAttributeValueModel({ value: memberId }, {
    boardId,
    itemId,
    attributeId,
  });
  $.when(model.removeMember(memberId))
    .done(() => dispatch(deleteMemberSuccess(boardId, itemId, attributeId, memberId)));
};

const getPeopleSuccess = (people) => ({
  type: constants.GET_PEOPLE_SUCCESS,
  people,
});

const getPeopleRequest = () => ({
  type: constants.GET_PEOPLE_REQUEST,
});

module.exports.getPeople = () => (dispatch) => {
  dispatch(getPeopleRequest());
  const model = new EmployeeCollection();
  $.when(model.getList()).done((people) => dispatch(getPeopleSuccess(people)));
};

const getEventTypesSuccess = (eventTypes) => ({
  type: constants.GET_EVENT_TYPES_SUCCESS,
  eventTypes,
});

const getEventTypesRequest = () => ({
  type: constants.GET_EVENT_TYPES_REQUEST,
});

module.exports.getEventTypes = () => (dispatch) => {
  dispatch(getEventTypesRequest());
  const request = Wethod.request('get:planning:event:types');
  $.when(request).done((eventTypes) => dispatch(getEventTypesSuccess(eventTypes.toJSON())));
};

const deleteMilestoneRequest = (boardId, itemId, attributeId) => ({
  type: constants.DELETE_ATTRIBUTE_MILESTONE_VALUE_REQUEST,
  boardId,
  attributeId,
  itemId,
});

const deleteMilestoneSuccess = (boardId, itemId, attributeId) => ({
  type: constants.DELETE_ATTRIBUTE_MILESTONE_VALUE_SUCCESS,
  boardId,
  attributeId,
  itemId,
});

module.exports.deleteMilestone = (boardId, itemId, attributeId) => (dispatch) => {
  dispatch(deleteMilestoneRequest(boardId, itemId, attributeId));
  const model = new BoardAttributeValueModel({ value: null }, {
    boardId,
    itemId,
    attributeId,
  });
  $.when(model.removeMilestone())
    .done(() => dispatch(deleteMilestoneSuccess(boardId, itemId, attributeId)));
};

module.exports.changeBoardType = (boardId, type) => ({
  type: constants.CHANGE_BOARD_TYPE,
  boardId,
  boardType: type,
});

module.exports.updateKanbanOptions = (boardId, options) => ({
  type: constants.UPDATE_KANBAN_OPTIONS,
  boardId,
  options,
});

module.exports.updateActivityAttribute = (boardId, options) => ({
  type: constants.UPDATE_ACTIVITY_ATTRIBUTE,
  boardId,
  options,
});

const getKudosSuccess = (response) => ({
  type: constants.GET_KUDOS_SUCCESS,
  kudos: response.kudos,
  reviews_count: response.reviews_count,
  sessions_count: response.sessions_count,
});

const getKudosRequest = () => ({
  type: constants.GET_KUDOS_REQUEST,
});

module.exports.getKudos = (projectId) => (dispatch) => {
  dispatch(getKudosRequest());
  const model = new KudosModel(null, { projectId });
  $.when(model.getModel())
    .done((response) => dispatch(getKudosSuccess(response)));
};

const getLastReviewSuccess = (lastReview) => ({
  type: constants.GET_LAST_REVIEW_SUCCESS,
  lastReview,
});

const getLastReviewRequest = () => ({
  type: constants.GET_LAST_REVIEW_REQUEST,
});

module.exports.getLastReview = (projectId) => (dispatch) => {
  dispatch(getLastReviewRequest());
  const request = Wethod.request('get:project:review:last', { projectId });
  $.when(request)
    .done((response) => dispatch(getLastReviewSuccess(response)));
};

const createReviewSuccess = () => ({
  type: constants.CREATE_REVIEW_SUCCESS,
});

const createReviewRequest = () => ({
  type: constants.CREATE_REVIEW_REQUEST,
});

module.exports.createReview = (projectId) => (dispatch) => {
  dispatch(createReviewRequest());
  const request = Wethod.request('project:review:request', { projectId });
  $.when(request)
    .done((data) => {
      dispatch(createReviewSuccess());
      dispatch(showReviewStartModal(data));
    });
};

module.exports.closeModal = closeModal;
module.exports.showReviewStartModal = showReviewStartModal;
module.exports.showServerErrorModal = showServerErrorModal;
