const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({ revenues, prevMonthRevenues }) => {
  const getLastMonthComparator = () => {
    const labels = {
      much_lower: 'lower than',
      lower: 'lower than',
      almost_equal: 'in line with',
      equal: 'equal to',
      greater: 'higher than',
      much_greater: 'higher than',
    };

    return <Comparator a={revenues} b={prevMonthRevenues} labels={labels} />;
  };
  return (
    <Paragraph>
      Revenues per employee were
      {' '}
      <NV value={revenues} />
      ,
      {' '}
      {getLastMonthComparator()}
      {' '}
      the
      {' '}
      <NV
        value={prevMonthRevenues} />
      {' '}
      of
      the previous month.
    </Paragraph>
  );
};
