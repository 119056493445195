const React = require('react');
const dragula = require('react-dragula');
const isEqual = require('react-fast-compare');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const PlaceholderBlocks = require('./placeholder-blocks/PlaceholderBlocks.react');
const Templates = require('../../containers/job-order-template/template/Templates');
const JobOrderCategoriesManager = require('../../containers/job-order-template/JobOrderCategoriesManager');

// Id of the area where the base components are dragged from.
const templateComponentsAreaId = 'template-blocks';

module.exports = class DraggableArea extends React.Component {
  /**
   * Check whether the drop of an element is allowed.
   * The only actions not allowed to are:
   * - dropping in the template components area
   * - drag and drop from two different templates
   * @param fromTemplate
   * @param toTemplate
   * @returns {boolean}
   */
  static isDropAllowed(fromTemplate, toTemplate) {
    const isDroppedInComponentsArea = toTemplate === templateComponentsAreaId;
    const isDroppedInSameTemplate = fromTemplate === toTemplate;
    const isDraggedFromComponentsArea = fromTemplate === templateComponentsAreaId;

    return !isDroppedInComponentsArea
      && (isDroppedInSameTemplate || isDraggedFromComponentsArea);
  }

  /**
   * Checks whether the given element should be copied, depending on the source where it is dragged from.
   * @param el
   * @param source
   * @returns {boolean}
   */
  static shouldCopy(el, source) {
    return source.getAttribute('data-id') === templateComponentsAreaId;
  }

  /**
   * Checks whether the given element can be dropped in the target container.
   *
   * @param el
   * @param target
   * @returns {boolean}
   */
  static shouldAccept(el, target) {
    return target.getAttribute('data-id') !== templateComponentsAreaId;
  }

  constructor(props) {
    super(props);

    this.dragulaContainers = [];

    this.templateRefs = [];
  }

  componentDidMount() {
    this.templateDrake = this.initDragula(this.dragulaContainers);
  }

  /**
   * Handle the drop of an element of the templates.
   * The drop, when allowed, changes the order of the elements in the template.
   * @param el
   * @param target
   * @param source
   * @param sibling
   */
  onBlockDrop(el, target, source, sibling) {
    // Cancel the default drop behaviour to handle it with custom logic
    this.templateDrake.cancel(true);

    const fromTemplate = source.getAttribute('data-id');
    const toTemplate = target ? target.getAttribute('data-id') : '';

    // Stop the action when it is not allowed
    if (!DraggableArea.isDropAllowed(fromTemplate, toTemplate)) {
      return;
    }

    const templateRef = this.getTemplateRef(target);

    if (templateRef) {
      templateRef.handleBlockDrop(el, sibling);
    }
  }

  setRef(el) {
    const isValidRef = el != null;
    const isTemplateRefInList = isValidRef ? this.getTemplateRef(el.listEl) != null : false;

    if (!isTemplateRefInList && isValidRef) {
      this.templateRefs.push(el);
    }
  }

  /**
   * Find the right template ref based on the given element.
   * The element represents the template blocks list ref.
   * @param el
   * @returns {*}
   */
  getTemplateRef(el) {
    return this.templateRefs.find((template) => isEqual(template.listEl, el));
  }

  /**
   * Init Dragula to handle drag & drop on the given containers
   * The blocks' container is only used to drag the elements to be copied in the actual templates.
   * All the containers representing the custom templates can drag the elements to reorder them.
   * Elements cannot be drag out of the templates.
   * @param containers
   */
  initDragula(containers) {
    if (containers) {
      const options = {
        copy: DraggableArea.shouldCopy,
        accepts: DraggableArea.shouldAccept,
      };

      return dragula(containers, options).on('drop', this.onBlockDrop.bind(this));
    }
    return null;
  }

  /**
   * Add a container to the list given to dragula.
   * Each container added to the list will be involved in the drag & drop actions.
   * @param container
   */
  addDragulaContainer(container) {
    this.dragulaContainers.push(container);
  }

  render() {
    return (
      <div>
        <PlaceholderBlocks addContainer={this.addDragulaContainer.bind(this)} />
        <Button onClick={this.props.onAdd}
          disabled={this.props.isAddDisabled}
          className="company-settings__button company-settings__button--blue company-settings-margin-bottom-xl">
          + Template
        </Button>
        <JobOrderCategoriesManager>
          <Templates addContainer={this.addDragulaContainer.bind(this)}
            setRef={this.setRef.bind(this)} />
        </JobOrderCategoriesManager>
      </div>
    );
  }
};
