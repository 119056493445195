/* eslint-disable */
const React = require('react');
const SearchAndSelectModal = require('../../../SearchAndSelectModal/SearchAndSelectModal.react');
const PricelistModel = require('../../../../../../../../models/Pricelist');
const Option = require('./PricelistOption.react');
const RadioGroup = require('../../../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');

const MISSING_PERMISSION_ERROR = 'MISSING_PERMISSION_ERROR';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

module.exports = class PricelistModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pricelists: [],
      isLoading: false,
      error: '',
      checked: this.props.currentPricelistId,
      areaId: this.props.areaId,
    };

    this.loadPricelists = this.loadPricelists.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  static getError(code) {
    if (code === 403) {
      return MISSING_PERMISSION_ERROR;
    }
    return UNKNOWN_ERROR;
  }

  onRadioChange(e) {
    const checked = parseInt(e.target.value);

    this.setState({ checked });
  }

  onSaveClick() {
    if (this.state.areaId) {
      this.props.updateAreaPriceList(this.state.checked, this.state.areaId);
    } else {
      this.props.updatePricelist(this.state.checked);
    }
  }

  loadPricelists(keyword = '') {
    this.setState({
      isLoading: true,
      error: ''
    });
    const filters = {
      search: keyword,
      available: true,
      validForClient: this.props.projectClientId,
    };
    PricelistModel.list(filters)
      .done((pricelists) => this.setState({ pricelists }))
      .fail((message, code) => this.setState({ error: PricelistModalContent.getError(code) }))
      .always(() => this.setState({ isLoading: false }));
  }

  /**
   * Return true if no pricelist is currently selected.
   * @return {boolean}
   */
  emptySelection() {
    return this.state.checked === null;
  }

  getErrorMessage() {
    if (this.missingPermissions()) {
      return 'You are not allowed to see pricelists. You can change this from Permissions';
    }
    return '';
  }

  missingPermissions() {
    return this.state.error === MISSING_PERMISSION_ERROR;
  }

  isSaving() {
    return this.props.isPending('change-pricelist');
  }

  /**
   * Return true if given pricelistId is currently checked.
   * @param pricelistId
   * @return {boolean}
   */
  isChecked(pricelistId) {
    return this.state.checked === pricelistId;
  }

  hasNoItems() {
    return this.state.pricelists.length === 0;
  }

  renderPricelists() {
    return this.state.pricelists
      .map((pricelist) => {
        const isDefault = pricelist.default_for_client || pricelist.default_for_company;

        return (
          <Option key={`pricelist-${pricelist.id}`}
                  isDefault={isDefault}
                  pricelistName={pricelist.name}
                  pricelistDescription={pricelist.description}
                  value={pricelist.id} />
        );
      });
  }

  render() {
    return (
      <SearchAndSelectModal
        loadOptions={this.loadPricelists}
        isSaving={this.isSaving()}
        isLoading={this.state.isLoading}
        title={this.state.areaId ? 'Select pricelist' : 'Change pricelist'}
        closeModal={this.props.closeModal}
        isEmptySelection={this.emptySelection()}
        onSaveClick={this.onSaveClick}
        saveButtonLabel="Change"
        hasNoOptions={this.hasNoItems()}
        searchPlaceholder="Search pricelist"
        emptyMessage="No pricelists found"
        errorMessage={this.getErrorMessage()}>
        <RadioGroup name="pricelist" value={this.state.checked} onChange={this.onRadioChange}>
          {this.renderPricelists()}
        </RadioGroup>
      </SearchAndSelectModal>
    );
  }
};
