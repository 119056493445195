const { connect } = require('react-redux');
const Component = require('../components/RatingWidget.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'ratings').length > 0,
  ratings: state.ratings,
  supplierId: state.info.id,
  canEdit: state.canEditRating,
  canDeleteOthers: state.canDeleteOthersRating,
  employeeId: Wethod.userInfo.get('employee_id'),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
  getRatings: (supplierId) => dispatch(actions.getRatings(supplierId)),
  showRatingModal: (rating) => dispatch(actions.showRatingModal(rating)),
  showDeleteRatingModal: (rating) => dispatch(actions.showDeleteRatingModal(rating)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
