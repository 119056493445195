const $ = require('jquery');
const constants = require('./constants');
const ExportModel = require('./models/ExportModel');

const loadProjectsRequest = () => ({
  type: constants.GET_PROJECTS_REQUEST,
});

const loadProjectsSuccess = (projects, size) => ({
  type: constants.GET_PROJECTS_SUCCESS,
  items: projects,
  size,
});

module.exports.loadProjects = (size, page, keyword, type, bu) => (dispatch) => {
  dispatch(loadProjectsRequest());
  const offset = (page - 1) * size;

  const archived = type === 'archived';
  const mine = type === 'mine';

  const getProjectsRequest = Wethod.request('get:planning:production-plan', offset, keyword, size, archived, mine, bu.join());
  $.when(getProjectsRequest)
    .done((projects) => dispatch(loadProjectsSuccess(projects, size)));
};

const filterProjectsRequest = (keyword, filterType, bu) => ({
  type: constants.FILTER_PROJECTS_REQUEST,
  keyword,
  filterType,
  bu,
});

const filterProjectsSuccess = (projects, size) => ({
  type: constants.FILTER_PROJECTS_SUCCESS,
  items: projects,
  size,
});

module.exports.filterProjects = (size, keyword, type, bu) => (dispatch) => {
  dispatch(filterProjectsRequest(keyword, type, bu));
  const offset = 0;

  const archived = type === 'archived';
  const mine = type === 'mine';

  const getProjectsRequest = Wethod.request('get:planning:production-plan', offset, keyword, size, archived, mine, bu.join());
  $.when(getProjectsRequest)
    .done((projects) => dispatch(
      filterProjectsSuccess(projects, size),
    ));
};

const updateVisibleMonthsSuccess = (months) => ({
  type: constants.UPDATE_VISIBLE_MONTHS_SUCCESS,
  months,
});

const updateVisibleMonthsRequest = () => ({
  type: constants.UPDATE_VISIBLE_MONTHS_REQUEST,
});

module.exports.updateVisibleMonths = (months) => (dispatch) => {
  dispatch(updateVisibleMonthsRequest());

  setTimeout(() => {
    dispatch(updateVisibleMonthsSuccess(months));
  }, 0);
};

module.exports.hideModal = () => ({
  type: constants.HIDE_MODAL,
});

module.exports.hideProjectActionsDropdown = () => ({
  type: constants.HIDE_PROJECT_ACTIONS_DROPDOWN,
});

module.exports.showProjectActionsDropdown = (projectId) => ({
  type: constants.SHOW_PROJECT_ACTIONS_DROPDOWN,
  projectId,
});

module.exports.showCannotSaveModal = () => ({
  type: constants.SHOW_MODAL_CANNOT_SAVE,
});

module.exports.showCannotAutoDistributePastModal = () => ({
  type: constants.SHOW_MODAL_CANNOT_AUTO_DISTRIBUTE_PAST,
});

module.exports.updateProjectDuration = (projectId, start, end) => ({
  type: constants.UPDATE_PROJECT_DURATION,
  start,
  end,
  projectId,
});

const saveProjectSuccess = (project) => ({
  type: constants.PROJECT_SAVE_SUCCESS,
  project,
});

const saveProjectRequest = (project) => ({
  type: constants.PROJECT_SAVE_REQUEST,
  project,
});

module.exports.saveProject = (project) => (dispatch) => {
  dispatch(saveProjectRequest(project));

  Wethod.request('save:planning:production-plan', project)
    .done(() => dispatch(saveProjectSuccess(project)));
};

module.exports.changeBuFilter = (bu) => ({
  type: constants.CHANGE_BU_FILTER,
  bu,
});

module.exports.changeMismatchFilter = (keyword) => ({
  type: constants.CHANGE_MISMATCH_FILTER,
  keyword,
});

module.exports.showExportModal = () => ({
  type: constants.SHOW_MODAL_EXPORT,
});

const exportModuleRequest = () => ({
  type: constants.EXPORT_REQUEST,
});

const exportModuleSuccess = () => ({
  type: constants.EXPORT_SUCCESS,
});

const closeExportModal = () => ({
  type: constants.HIDE_MODAL,
});

module.exports.exportModule = (typeFilter, buFilter, year) => (dispatch) => {
  dispatch(exportModuleRequest());
  const model = new ExportModel({
    typeFilter,
    buFilter,
    year,
  });
  $.when(model.sendExport()).done(() => {
    dispatch(exportModuleSuccess());
    dispatch(closeExportModal());
  });
};
