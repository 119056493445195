const request = require('./Request');

const BudgetVersion = {
  get(projectId, version) {
    const formattedVersion = version || 'latest';
    return request({
      method: 'get',
      url: `/br/project/${projectId}/budget/${formattedVersion}`,
    });
  },
  // TODO after compress budgetId -> projectId
  getAll(budgetId) {
    return request({
      method: 'get',
      url: `/br/project/${budgetId}/budget`,
    });
  },
  getComparison(projectId, from, to) {
    return request({
      method: 'get',
      url: `/br/project/${projectId}/compare/${from}/with/${to}`,
    });
  },
  updateStatus(id, attributes) {
    return request({
      method: 'put',
      url: `/br/budget/${id}/status`,
      data: attributes,
    });
  },
};

module.exports = BudgetVersion;
