const React = require('react');
const PropTypes = require('prop-types');
const Checkbox = require('../Checkbox/Checkbox.react');
const Tree = require('../../Tree/Tree.react');

const CheckboxGroup = ({
  availableOptions,
  name,
  values,
  acceptedValues,
  onChange,
}) => {
  const statuses = {
    checked: 'checked',
    unchecked: 'unchecked',
    indeterminate: 'indeterminate',
  };

  /**
   * Map of accepted values for each available option.
   * Useful to quick access which accepted values belong to an available option: if an option
   * represent a group, all children accepted values belong to it.
   * @type {{}}
   */
  const valuesByOption = {};

  /**
   * Return if the given value is an accepted value.
   * @param value
   * @returns {boolean}
   */
  const isAccepted = (value) => acceptedValues.includes(value);

  /**
   * Return id the given value is currently checked. A value is checked if listed in "values" prop.
   * @param value
   * @returns {boolean}
   */
  const isChecked = (value) => values.includes(value);

  /**
   * Build valuesByOption.
   * Value V1 gets bound to option O1 if all the following are true:
   * - V1 belongs to O1 or to one of its children
   * - V1 is an accepted value
   * @param option
   * @returns {*[]}
   */
  const bindValues = (option) => {
    const { children } = option;
    let acceptableValues = isAccepted(option.value) ? [option.value] : [];
    for (let i = 0; i < children.length; i++) {
      const childOption = children[i];
      acceptableValues = acceptableValues.concat(bindValues(childOption));
    }
    valuesByOption[option.value] = acceptableValues;
    return acceptableValues;
  };

  /**
   * Return status for option with given value, based on valuesByOption.
   * Status can be:
   * - "checked": if all values bound to the option are checked
   * - "unchecked": if no value bound to the option is checked
   * - "indeterminate": if at least one BUT not all values bound to the option are checked
   *
   * @param {string} value
   * @returns {string}
   */
  const getStatus = (value) => {
    const boundValues = valuesByOption[value];
    const checkedBoundValues = [];

    boundValues.forEach((boundValue) => {
      if (isChecked(boundValue)) {
        checkedBoundValues.push(boundValue);
      }
    });

    if (checkedBoundValues.length === boundValues.length) {
      return statuses.checked;
    }
    if (checkedBoundValues.length > 0) {
      return statuses.indeterminate;
    }
    return statuses.unchecked;
  };

  /**
   * Return values merged with valuesToAdd (no duplicates).
   * @param {string[]} valuesToAdd
   * @returns {string[]}
   */
  const getValuesWith = (valuesToAdd) => {
    // Values not already checked
    const newValues = valuesToAdd.filter((value) => !values.includes(value));
    return values.concat(newValues);
  };

  /**
   * Return values without valuesToRemove.
   * @param {string[]} valuesToRemove
   * @returns {string[]}
   */
  const getValuesWithout = (valuesToRemove) => values
    .filter((value) => !valuesToRemove.includes(value));

  /**
   * Return the list of values to consider as "checked" after clickedValue is clicked.
   * @param {string} clickedValue
   * @returns {string[]}
   */
  const getNextValues = (clickedValue) => {
    const status = getStatus(clickedValue);
    const selectedValues = valuesByOption[clickedValue];

    if (status === statuses.checked) {
      return getValuesWithout(selectedValues);
    }
    return getValuesWith(selectedValues);
  };

  function handleChange(e) {
    const clickedValue = e.target.value;
    onChange(e, getNextValues(clickedValue));
  }

  /**
   * Return the root node of a tree reprensting the checkbox group.
   * @param {{}} option
   * @returns {{component: React.Element, children: *[]}}
   */
  const getNode = (option) => {
    const {
      children,
      value,
      label,
    } = option;

    const status = getStatus(option.value);

    const node = {
      component: <Checkbox name={name}
        id={`${name}-${value}`}
        value={value}
        label={label}
        onChange={handleChange}
        checked={status === statuses.checked}
        indeterminate={status === statuses.indeterminate} />,
      children: [],
    };

    for (let i = 0; i < children.length; i++) {
      const childOption = children[i];
      node.children = node.children.concat(getNode(childOption));
    }

    return node;
  };

  bindValues(availableOptions);

  return (
    <div className="wethod-checkbox-group">
      <Tree node={getNode(availableOptions)} />
    </div>
  );
};

const availableOptionShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

availableOptionShape.children = PropTypes.arrayOf(PropTypes.shape(availableOptionShape));

CheckboxGroup.defaultProps = {
  onChange: null,
};

CheckboxGroup.propTypes = {
  /**
   * Used to group checkbox. All buttons in the same group must have the same name.
   */
  name: PropTypes.string.isRequired,
  /**
   * List of currently selected values.
   */
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  /**
   * Group structure: a tree where each node represent a checkbox to render.
   */
  availableOptions: PropTypes.shape(availableOptionShape).isRequired,
  /**
   * List of the "real" values, that are availableOptions without values representing groups.
   * These are likely the leaf values.
   */
  acceptedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  /**
   * Function to call when user check or uncheck an option.
   * @param {SyntheticEvent} e
   * @param {string[]} values list of selected values
   */
  onChange: PropTypes.func,
};

module.exports = CheckboxGroup;
