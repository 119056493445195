const React = require('react');
const IconButton = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const PlanningButton = require('../PlanningButton.react');

const AllocationRequestActions = ({ item, canDelete, onDelete }) => (
  <div>
    <PlanningButton projectId={item.project.id} user={item.employee} />
    <ShowIf condition={canDelete}>
      <div className="wethod-vertical-line" />
    </ShowIf>
    <ShowIf condition={canDelete}>
      <IconButton label="Delete" onClick={onDelete} borderless>
        <span className="wethod-icon-delete wethod-icon-delete--black" />
      </IconButton>
    </ShowIf>
  </div>
);

module.exports = AllocationRequestActions;
