const request = require('./Request');

const Order = {
  create(attributes) {
    return request({
      method: 'post',
      url: '/orderboard/',
      data: attributes,
    });
  },
  update(id, attributes) {
    return request({
      method: 'put',
      url: `/orderboard/${id}`,
      data: attributes,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/orderboard/${id}`,
    });
  },
  send(orderId, contactId) {
    return request({
      method: 'post',
      url: `/orderboard/${orderId}/send`,
      data: {
        contact_id: contactId,
      },
    });
  },
  approve(orderId, recipientId = null, message = '') {
    return request({
      method: 'post',
      url: `/orderboard/${orderId}/authorize`,
      data: {
        contact_id: recipientId,
        notification_message: message,
      },
    });
  },
  setAsPaid(orderId) {
    return request({
      method: 'post',
      url: `/orderboard/${orderId}/paid`,
    });
  },
  exportList(from, to) {
    const periodQueryString = from && to ? `&from=${from}&to=${to}` : '';

    return request({
      method: 'get',
      url: `/export/entity?select=order&download=false${periodQueryString}`,
    });
  },
  getAvailableStatuses() {
    return request({
      method: 'get',
      url: '/orderboard/statuses',
    });
  },
  getStatusLabel(name) {
    /**
     * Maps status name with label to display.
     */
    const labelsMap = {
      NOT_SENT: 'Not sent',
      SENT: 'Pending approval',
      AUTHORIZED: 'Approved',
      PAID: 'Paid',
    };

    return labelsMap[name];
  },
};

module.exports = Order;
