const $ = require('jquery');
const constants = require('./constants');
const ProductModel = require('../../../../models/Product');
const modals = require('./modals');
const LevelModel = require('../../../../models/Level');

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.closeModal = closeModal;

const showModal = (key, data) => ({
  type: 'SHOW_MODAL',
  key,
  data,
});

module.exports.showModalCreationWarning = (product) => showModal(modals
  .CREATION_WARNING, { product });

module.exports.showSidebar = (id) => ({
  type: constants.SHOW_SIDEBAR,
  id,
});

const closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});
module.exports.closeSidebar = closeSidebar;

const getProductsRequest = (offset, limit, search, order, sort, available) => ({
  type: constants.GET_PRODUCTS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  available,
});

const getProductsSuccess = (products) => ({
  type: constants.GET_PRODUCTS_SUCCESS,
  products,
});

module.exports.getProducts = (
  offset, limit, search, order, sort, available,
) => (dispatch) => {
  const filters = {
    offset, limit, search, order, sort, available,
  };

  dispatch(getProductsRequest(offset, limit, search, order, sort, available));

  const getListRequest = ProductModel.list(filters);
  $.when(getListRequest)
    .done((products) => dispatch(getProductsSuccess(products)));
};

const updateProductRequest = (id) => ({
  type: constants.UPDATE_PRODUCT_REQUEST,
  id,
});

const updateProductSuccess = (product) => ({
  type: constants.UPDATE_PRODUCT_SUCCESS,
  product,
});

module.exports.updateProduct = (id, changes) => (dispatch) => {
  dispatch(updateProductRequest(id));

  $.when(ProductModel.update(id, changes)).done((response) => {
    dispatch(updateProductSuccess(response));
    dispatch(closeSidebar());
  });
};

const createProductRequest = () => ({
  type: constants.CREATE_PRODUCT_REQUEST,
});

const createProductSuccess = (product) => ({
  type: constants.CREATE_PRODUCT_SUCCESS,
  product,
});

module.exports.createProduct = (item) => (dispatch) => {
  dispatch(createProductRequest());

  $.when(ProductModel.create(item)).done((response) => {
    dispatch(createProductSuccess(response));
    dispatch(closeSidebar());
  });
};

module.exports.changeAvailabilityFilter = (key) => ({
  type: constants.AVAILABILITY_FILTER_CHANGE,
  key,
});

const getLevelsRequest = () => ({
  type: constants.GET_LEVELS_REQUEST,
});

const getLevelsSuccess = (levels) => ({
  type: constants.GET_LEVELS_SUCCESS,
  levels,
});

module.exports.getLevels = () => (dispatch) => {
  dispatch(getLevelsRequest());

  const getAllLevelsRequest = LevelModel.list();
  $.when(getAllLevelsRequest)
    .done((levels) => dispatch(getLevelsSuccess(levels)));
};

module.exports.changeSidebarEditMode = (editMode) => ({
  type: constants.CHANGE_SIDEBAR_EDIT_MODE,
  editMode,
});

module.exports.changeUnsavedChanges = (unsavedChanges) => ({
  type: constants.CHANGE_UNSAVED_CHANGES,
  unsavedChanges,
});
