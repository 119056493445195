// eslint-disable-next-line no-unused-vars
const Area = require('./AreaNode');
const Total = require('../NodeTotal/SummaryNodeTotal');
const Node = require('./StructureNode');
const LevelAmountTotal = require('../NodeTotal/LevelAmountNodeTotal');
// eslint-disable-next-line no-unused-vars
const AvailableLevel = require('../AvailableLevel');
const Previous = require('../NodePrevious/SummaryNodePrevious');
const LevelAmountPrevious = require('../NodePrevious/LevelAmountNodePrevious');
const MathService = require('../../../../../../services/MathService');

module.exports = class Summary extends Node {
  /**
   * @param summary
   * @param {Area[]} areas
   * @param {Summary | null} subtotal
   * @param {AvailableLevel[]} availableLevels
   */
  constructor(summary, areas, subtotal, availableLevels) {
    super();
    this.summary = summary;
    this.areas = areas;
    this.subtotal = subtotal;
    this.availableLevels = availableLevels;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  /**
   * @private
   * @returns {number}
   */
  getSubtotalDays() {
    return this.getSubtotal() ? this.getSubtotal().getTotal().getDays() : 0;
  }

  /**
   * @private
   * @returns {number}
   */
  getSubtotalCost() {
    return this.getSubtotal() ? this.getSubtotal().getTotal().getCost() : 0;
  }

  /**
   * @private
   * @returns {number}
   */
  getSubtotalPrice() {
    return this.getSubtotal() ? this.getSubtotal().getTotal().getPrice() : 0;
  }

  /**
   * @private
   * @returns {number}
   */
  getSubtotalExternalCost() {
    return this.getSubtotal() ? this.getSubtotal().getTotal().getExternalCost() : 0;
  }

  /**
   * @private
   * @returns {number}
   */
  getSubtotalPreviousExternalCost() {
    return this.getSubtotal() ? this.getSubtotal().getPrevious().getExternalCost() : 0;
  }

  /**
   * @private
   * @param levelId
   * @return {number}
   */
  getSubtotalDaysForLevel(levelId) {
    /** @var {LevelAmountNodeTotal[]} levels */
    const levels = this.getSubtotal() ? this.getSubtotal().getTotal().getLevels() : [];
    const found = levels.filter((level) => level.getLevelId() === levelId);

    return found.length ? found[0].getDays() : 0;
  }

  /**
   * @private
   * @param levelId
   * @return {number}
   */
  getSubtotalPreviousDaysForLevel(levelId) {
    /** @var {LevelAmountNodeTotal[]} levels */
    const levels = this.getSubtotal() ? this.getSubtotal().getPrevious().getLevels() : [];
    const found = levels.filter((level) => level.getLevelId() === levelId);

    return found.length ? found[0].getDays() : 0;
  }

  /**
   * @private
   * @returns {number}
   */
  getDays() {
    return MathService.round(
      this.getEnabledAreas().reduce((sum, area) => sum + area.getTotal().days, 0)
      + this.getSubtotalDays(), 1,
    );
  }

  /**
   * @private
   * @returns {number}
   */
  getCost() {
    return MathService.round(
      this.getEnabledAreas().reduce((sum, area) => sum + area.getTotal().cost, 0)
      + this.getSubtotalCost(), 2,
    );
  }

  /**
   * @private
   * @returns {number}
   */
  getPrice() {
    return MathService.round(
      this.getEnabledAreas().reduce((sum, area) => sum + area.getTotal().price, 0)
      + this.getSubtotalPrice(), 2,
    );
  }

  /**
   * @private
   * @returns {number}
   */
  getExternalCost() {
    return this.getEnabledAreas().reduce((sum, area) => sum + area.getExternalCost(), 0)
      + this.getSubtotalExternalCost();
  }

  /**
   * Return summary previous version external cost. Return null if any.
   * @return {null|number}
   */
  getPreviousExternalCost() {
    const areasPreviousExternalCost = this.getEnabledAreas()
      .reduce((sum, area) => {
        // Null previous external cost means last cost was equal to the current one
        const previousExternalCost = area.getPreviousExternalCost() !== null
          ? area.getPreviousExternalCost() : area.getPreviousExternalCost();
        return sum + previousExternalCost;
      }, 0);

    return areasPreviousExternalCost + this.getSubtotalPreviousExternalCost();
  }

  getAvailableLevels() {
    return this.availableLevels;
  }

  /**
   * Return summary areas days, summed by level.
   * Only enabled areas count towards totals.
   * @return {*[]}
   */
  getDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const subtotalDaysForLevel = this.getSubtotalDaysForLevel(level.getId());
      const daysForLevel = this.getEnabledAreas()
        .reduce((sum, area) => sum + area.getDaysForLevel(level.getId()), 0);

      daysByLevel
        .push(new LevelAmountTotal(daysForLevel + subtotalDaysForLevel, 0, 0, level.getId()));
    }
    return daysByLevel;
  }

  /**
   * @return {LevelAmountPrevious[]}
   */
  getPreviousDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const subtotalDaysForLevel = this.getSubtotalPreviousDaysForLevel(level.getId());
      const daysForLevel = this.getEnabledAreas()
        .reduce((sum, area) => sum + area.getDaysForPreviousLevel(level.getId()), 0);

      daysByLevel.push(new LevelAmountPrevious(daysForLevel + subtotalDaysForLevel, level.getId()));
    }
    return daysByLevel;
  }

  /**
   * @returns {Summary}
   */
  getSubtotal() {
    return this.subtotal;
  }

  /**
   * @returns {Area[]}
   */
  getAreas() {
    return this.areas;
  }

  /**
   * @return {Area[]}
   */
  getEnabledAreas() {
    return this.getAreas().filter((area) => area.isEnabled());
  }

  /**
   * @private
   * @returns {SummaryNodeTotal}
   */
  calculateTotal() {
    return new Total(this.getDays(), this.getCost(), this.getPrice(), this.getExternalCost(),
      this.getDaysByLevel());
  }

  /**
   * @returns {SummaryNodeTotal}
   */
  getTotal() {
    return this.total;
  }

  toJson() {
    const summary = {
      ...this.summary,
      areas: this.getAreas().map((area) => area.toJson()),
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };

    const subtotal = this.getSubtotal() ? this.getSubtotal().toJson() : [];

    return [summary].concat(subtotal);
  }

  /**
   * @return {SummaryNodePrevious}
   */
  calculatePrevious() {
    return new Previous(this.getPreviousExternalCost(), this.getPreviousDaysByLevel());
  }

  /**
   * @return {SummaryNodePrevious}
   */
  getPrevious() {
    return this.previous;
  }
};
