const React = require('react');
const CannotSaveModal = require('./modals/CannotSave/CannotSaveModal.react');
const CannotAutoDistributePastModal = require('./modals/CannotAutoDistributePastModal.react');
const ExportModal = require('../containers/modals/ExportModal');

module.exports = ({ toShow, hideModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'cannot-save':
        return <CannotSaveModal onCancelClick={hideModal} />;
      case 'cannot-auto-distribute-past':
        return <CannotAutoDistributePastModal onCancelClick={hideModal} />;
      case 'export':
        return <ExportModal onCancelClick={hideModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
