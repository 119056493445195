const constants = require('./constants');
const TimeFilterFactory = require('../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const KudosModel = require('./models/KudosModel');

Wethod.module('ReactBind', () => {
  const getKudosRequest = () => ({
    type: constants.GET_KUDOS_REQUEST,
  });

  const getKudosSuccess = (data) => ({
    type: constants.GET_KUDOS_SUCCESS,
    criteria: data.criteria,
    employees: data.employees,
  });

  const getKudos = (filter) => (dispatch) => {
    dispatch(getKudosRequest());
    const timeFilter = new TimeFilterFactory(filter);
    const start = timeFilter.getStartDate();
    const end = timeFilter.getEndDate();
    const model = new KudosModel({ from: start, to: end });
    $.when(model.getModel())
      .done((data) => {
        dispatch(getKudosSuccess(data));
      });
  };

  const updateTimeFilter = (filter) => ({
    type: constants.UPDATE_TIME_FILTER,
    filter,
  });

  module.exports.changeTimeFilter = (filter) => (dispatch) => {
    dispatch(updateTimeFilter(filter));
    dispatch(getKudos(filter));
  };

  module.exports.updateTeamCriterion = (criterion) => ({
    type: constants.UPDATE_TEAM_CRITERION,
    criterion,
  });

  module.exports.loadTeam = (size, page) => ({
    type: constants.LOAD_TEAM,
    size,
    page,
  });

  module.exports.filterTeam = (keyword) => ({
    type: constants.FILTER_TEAM,
    keyword,
  });

  module.exports.sortTeam = (col, order) => ({
    type: constants.SORT_TEAM,
    col,
    order,
  });

  module.exports.updatePMCriterion = (criterion) => ({
    type: constants.UPDATE_PM_CRITERION,
    criterion,
  });

  module.exports.loadPM = (size, page) => ({
    type: constants.LOAD_PM,
    size,
    page,
  });

  module.exports.filterPM = (keyword) => ({
    type: constants.FILTER_PM,
    keyword,
  });

  module.exports.sortPM = (col, order) => ({
    type: constants.SORT_PM,
    col,
    order,
  });

  module.exports.getKudos = getKudos;
});
