'use strict';

Wethod.module('BudgetApp.Budget', function () {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#budgetSectionTemplate',
    className: 'fluid',
    regions: {
      headerSection: '[data-region="budgetSectionHeader"]',
      bodySection: '[data-region="budgetBody"]',
    },
  });
});
