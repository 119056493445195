const React = require('react');
const PropTypes = require('prop-types');
const { formatDate } = require('../../../../../services/FormatService');
const Actions = require('./FileActions.react');
const ShowIf = require('../../../ShowIf/ShowIf.react');
const FileModel = require('../models/File');
const Loader = require('../../../Loader/Loader.react');

class File extends React.Component {
  static formatSize(size) {
    if (!size) {
      return '0 B';
    }

    const i = Math.floor(Math.log10(size) / 3);
    const convertedSize = (size / Math.pow(1000, i)).toFixed(2);
    return `${numeral(convertedSize).format('0.0,[00]')} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  }

  constructor(props) {
    super(props);

    this.state = { deleting: false };
  }

  handleDelete() {
    this.setState({ deleting: true });
    this.props.onDelete(this.props.file);
  }

  handleDownload() {
    this.props.onDownload(this.props.file);
  }

  getActions() {
    if (this.state.deleting) {
      return <div style={{ padding: '0 5px' }}><Loader /></div>;
    }
    if (this.props.editable) {
      return (
        <Actions onDeleteClick={this.handleDelete.bind(this)} />
      );
    }
    return null;
  }

  getCreationInfo() {
    const { uploadedAt } = this.props.file;
    if (uploadedAt) {
      return `Created on ${formatDate(uploadedAt)}`;
    }
    return null;
  }

  getName() {
    const { file } = this.props;
    if (file.webViewUrl) {
      return (
        <div className="project-canvas__file--name">
          <a href={file.webViewUrl} target="_blank" rel="noreferrer">{file.name}</a>
        </div>
      );
    }
    return <div className="project-canvas__file--name">{file.name}</div>;
  }

  render() {
    const { file } = this.props;
    return (
      <div className="project-canvas__file">
        {this.getName()}
        <div className="project-canvas__file--info">
          <span>{File.formatSize(file.size)}</span>
          <span>{this.getCreationInfo()}</span>
        </div>
        <ShowIf condition={this.props.onDownload !== null}>
          <div>
            <button type="button"
              onClick={this.handleDownload.bind(this)}
              className="wethod-icon-button project-canvas-spreadsheet__row-more-button">
              <div className="wethod-icon-medium-download wethod-icon-medium-download--black" />
            </button>
          </div>
        </ShowIf>
        <ShowIf condition={this.props.onDelete !== null}>
          <div>
            {this.getActions()}
          </div>
        </ShowIf>
      </div>
    );
  }
}

File.defaultProps = {
  onDelete: null,
  onDownload: null,
};

File.propTypes = {
  editable: PropTypes.bool.isRequired,
  file: PropTypes.instanceOf(FileModel).isRequired,
  /**
   * The function to call to delete a file.
   *
   * @param file {Object}
   */
  onDelete: PropTypes.func,
  /**
   * The function to call to download a file.
   *
   * @param file {Object}
   */
  onDownload: PropTypes.func,
};

module.exports = File;
