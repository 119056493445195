const FeatureService = require('../../../../../../services/FeatureService');

// TODO delete on 'JAKALA_FEATURES_NOVEMBER_BLOCK' contract
module.exports = {
  isEmployeeCapacityEnabled() {
    return Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK);
  },
  isWeeklyTimesheetDailyModeEnabled() {
    return Wethod.featureService.isEnabled(FeatureService.FEATURE_FLAG_WEEKLY_TIMESHEET_DAILY_MODE);
  },
};
