const { connect } = require('react-redux');
const Component = require('../../../components/Kpi/Trends/Trends.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  trends: state.trends,
  isWaiting: state.isWaitingForTrends,
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getTrends: () => dispatch(actions.getTrends()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
