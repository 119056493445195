const { connect } = require('react-redux');
const Component = require('../components/ChangeStatusButton.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  showApproveModal: (item) => dispatch(actions.showApproveAllocationModal(item)),
  showRejectModal: (item) => dispatch(actions.showRejectAllocationModal(item)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
