const AutocompleteModel = Wethod.Utility.BaseModel.extend({});

module.exports = Wethod.Utility.AutocompleteCollection.extend({
  url() {
    const params = `?search=${encodeURIComponent(this.options.key)}`;

    return `${APIURL}/billing-group/autocomplete${params}`;
  },
  model: AutocompleteModel,
  getAutoComplete(excludedId) {
    const defer = $.Deferred();

    this.fetch({
      success(responseData) {
        let list = responseData;
        if (responseData) {
          list = responseData.toJSON();
          if (excludedId) {
            list = list.filter((hint) => hint.id !== excludedId);
          }
        }
        defer.resolve(list);
      },
      error() {
        defer.reject(undefined);
      },
    });

    return defer.promise();
  },
});
