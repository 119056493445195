const constants = require('./constants');

class DeskReducer {
  constructor(state) {
    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      activities: [],
      favourites: null,
      modal: null, // key representing the modal to show
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_ACTIVITIES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('activities'),
        };
      }
      case constants.GET_ACTIVITIES_SUCCESS: {
        return {
          ...state,
          activities: action.activities,
          waitingFor: state.waitingFor.filter((key) => key !== 'activities'),
        };
      }
      case constants.UPDATE_STATUS_SUCCESS: {
        const updatedActivities = action.status.completed
          // Remove completed activity from the list
          ? state.activities.filter((activity) => activity.item.id !== action.itemId)
          // Update the activity status
          : state.activities.map((activity) => (activity.item.id === action.itemId
            ? {
              ...activity,
              selected_status: action.status,
            }
            : activity));
        return {
          ...state,
          activities: updatedActivities,
        };
      }
      case constants.GET_FAVOURITES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('favourites'),
        };
      }
      case constants.GET_FAVOURITES_SUCCESS: {
        return {
          ...state,
          favourites: action.favourites,
          waitingFor: state.waitingFor.filter((key) => key !== 'favourites'),
        };
      }
      case constants.DELETE_RESOURCE_FAVOURITES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-favourite-${action.resourceType}-${action.resourceId}`),
        };
      }
      case constants.DELETE_RESOURCE_FAVOURITES_SUCCESS: {
        const category = `${action.resourceType}s`;
        return {
          ...state,
          favourites: {
            ...state.favourites,
            [category]: state.favourites[category]
              .filter((favourite) => !favourite[action.resourceType]
                || favourite[action.resourceType].id !== action.resourceId),
          },
          waitingFor: state.waitingFor
            .filter((key) => key !== `delete-favourite-${action.resourceType}-${action.resourceId}`),
        };
      }
      case constants.DELETE_SEARCH_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-favourite-search-${action.searchId}`),
        };
      }
      case constants.DELETE_SEARCH_SUCCESS: {
        return {
          ...state,
          favourites: {
            ...state.favourites,
            searches: state.favourites.searches
              .filter((favourite) => favourite.id !== action.searchId),
          },
          waitingFor: state.waitingFor.filter((key) => key !== `delete-favourite-search-${action.searchId}`),
        };
      }
      case constants.UPDATE_SEARCH_LABEL_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`update-favourite-search-${action.searchId}`),
        };
      }
      case constants.UPDATE_SEARCH_LABEL_SUCCESS: {
        return {
          ...state,
          favourites: {
            ...state.favourites,
            searches: state.favourites.searches
              .map((favourite) => ((favourite.id === action.searchId)
                ? { ...action.search }
                : favourite)),
          },
          waitingFor: state.waitingFor.filter((key) => key !== `update-favourite-search-${action.searchId}`),
        };
      }
      case constants.MODAL_CLOSE: {
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = DeskReducer;
