const React = require('react');
const PropTypes = require('prop-types');
const ItemCreator = require('./ItemCreator.react');
const InputValidator = require('../../../InputValidator/InputValidator.react');
const TextField = require('../../TextField/OutlinedTextField/OutlinedTextField.react');

class ClientContactCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.value, // Initialize input value from parent
      surname: '',
      email: '',
      isSaving: false,
    };

    this.onChange = this.onChange.bind(this);
    this.create = this.create.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getTitle() {
    return `Is "${this.props.value}" a new contact for "${this.props.client.name}"?`;
  }

  create() {
    this.setState({ isSaving: true });
    const model = new Wethod.PipelineApp.Pipeline.ContactModel({
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      client: this.props.client.id,
    });
    const saveRequest = Wethod.request('save:pipeline:contact', model);
    $.when(saveRequest).done((response) => {
      this.setState({ isSaving: false });
      this.props.onCreate(response.attributes);
    });
  }

  render() {
    return (
      <ItemCreator title={this.getTitle()}
        close={this.props.close}
        create={this.create}
        isValid={this.props.isValid}
        isSaving={this.state.isSaving}>
        <InputValidator constraints={['required']}
          updateErrors={this.props.updateErrors}>
          <TextField onChange={this.onChange}
            disabled={this.state.isSaving}
            name="name"
            label="Name"
            id={`${this.props.id}-name`}
            value={this.state.name}
            errorText={this.props.errors.name} />
        </InputValidator>
        <InputValidator constraints={['required']}
          updateErrors={this.props.updateErrors}>
          <TextField onChange={this.onChange}
            disabled={this.state.isSaving}
            id={`${this.props.id}-surname`}
            name="surname"
            label="Surname"
            value={this.state.surname}
            errorText={this.props.errors.surname} />
        </InputValidator>
        <InputValidator constraints={['required', 'email']}
          updateErrors={this.props.updateErrors}>
          <TextField onChange={this.onChange}
            disabled={this.state.isSaving}
            id={`${this.props.id}-email`}
            name="email"
            label="Email"
            value={this.state.email}
            errorText={this.props.errors.email} />
        </InputValidator>
      </ItemCreator>
    );
  }
}

ClientContactCreator.defaultProps = {
  value: '',
  updateErrors: undefined,
  errors: {
    name: '',
    surname: '',
    email: '',
  },
  isValid: true,
  onCreate: null,
};

ClientContactCreator.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  /** Client for this contact * */
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  updateErrors: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
  }),
  /** Tells if form is valid and can be saved * */
  isValid: PropTypes.bool,
  /**
   * Function to call when user clicks on "cancel" button.
   * @param e {HTMLEvent}
   */
  close: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  /**
   * Function to call on item creation success.
   * @param item {{}} the created item, depends on create form
   */
  onCreate: PropTypes.func,
};

module.exports = ClientContactCreator;
