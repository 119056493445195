const { connect } = require('react-redux');
const Component = require('../components/KudosTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'kudos').length > 0,
  timeFilter: state.timeFilter,
  placeholder: 'Search team member',
  criteria: state.criteria,
  selectedCriterion: state.selectedTeamCriterion,
  employees: state.filteredTeam,
  role: 'as_team_member',
  size: state.loadTeam.size,
  page: state.loadTeam.page,
  sortBy: state.sortByTeam,
});

const mapDispatchToProps = (dispatch) => ({
  changeCriterion: (criterion) => dispatch(actions.updateTeamCriterion(criterion)),
  loadEmployees: (size, page) => dispatch(actions.loadTeam(size, page)),
  filter: (keyword) => dispatch(actions.filterTeam(keyword)),
  sort: (col, order) => dispatch(actions.sortTeam(col, order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
