const React = require('react');
const PropTypes = require('prop-types');
const SelectButton = require('../../SelectButton.react');
const Decorator = require('../../../../InputDecorator/RoundedColorfulInputDecorator/RoundedColorfulInputDecorator.react');

const RoundedColorfulSelectButton = ({ inputRef, color, value, disabled, readOnly, ...rest }) => (
  <Decorator
    inputRef={inputRef}
    color={color}
    disabled={disabled}
    readOnly={readOnly}>
    <SelectButton disabled={disabled} readOnly={readOnly} value={value} {...rest}>
      {value}
      <span>&nbsp;</span>
    </SelectButton>
  </Decorator>
);

RoundedColorfulSelectButton.defaultProps = {
  value: '',
  readOnly: undefined,
  disabled: undefined,
  inputRef: undefined,
  color: undefined,
};

RoundedColorfulSelectButton.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
  color: PropTypes.string,
};

module.exports = RoundedColorfulSelectButton;
