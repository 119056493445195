const React = require('react');
const moment = require('moment');
const isEqual = require('react-fast-compare');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const OpenSidebarButton = require('../../../../../../common/react/OpenSidebarButton.react');
const PmCell = require('./cells/PmCell.react');
const AccountCell = require('./cells/AccountCell.react');
const ClientCell = require('./cells/ClientCell.react');
const NameCell = require('./cells/NameCell/NameCell.react');
const MonthsCell = require('./cells/MonthsCell.react');
const ValueCell = require('./cells/ValueCell.react');
const StartCell = require('./cells/StartCell.react');
const BudgetStatusCell = require('./cells/BudgetStatusCell.react');
const MarginCell = require('./cells/MarginCell.react');
const CostsPercentageCell = require('./cells/ExternalCostsPercentageCell.react');
const CostsCell = require('./cells/ExternalCostsCell.react');
const ProbabilityCell = require('./cells/ProbabilityCell/ProbabilityCell.react');
const RiskCell = require('../../containers/Item/cells/RiskCell');
const ClientContactCell = require('./cells/ClientContactCell.react');
const PurchaseOrderCell = require('./cells/PurchaseOrderCell.react');
const JobOrderCategoryCell = require('../../containers/Item/cells/JobOrderCategoryCell');
const ProjectTypeCell = require('../../containers/Item/cells/ProjectTypeCell');
const JobOrderCell = require('./cells/JobOrderCell.react');
const BudgetConsumptionCell = require('./cells/BudgetConsumptionCell.react');
const ProgressCell = require('./cells/BudgetConsumptionCell.react');
const StageCell = require('../../containers/Item/cells/StageCell');
const DateCell = require('./cells/DateCell.react');
const WhitelistCell = require('./cells/WhitelistEnabledCell.react');

class Item extends React.Component {
  static parseNumericValue(value) {
    let parsedValue = parseInt(value);
    parsedValue = Number.isNaN(parsedValue) ? null : parsedValue;

    return parsedValue;
  }

  constructor(props) {
    super(props);

    this.state = {
      mouseIn: false, // True if user cursor is inside this item area
      focus: false, // True if user has focus on one of this item inputs
    };

    this.onOpenSidebarClick = this.onOpenSidebarClick.bind(this);
    this.save = this.save.bind(this);
    this.saveProject = this.saveProject.bind(this);
  }

  onOpenSidebarClick() {
    this.props.handleOpenSidebar({
      ...this.props.item,
    });
  }

  onMouseIn() {
    this.setState({ mouseIn: true });
  }

  onMouseOut() {
    this.setState({ mouseIn: false });
  }

  onFocusIn() {
    this.setState({ focus: true });
  }

  onFocusOut() {
    this.setState({ focus: false });
  }

  onNameBlur(e) {
    this.saveProject(e);
    this.onFocusOut();
  }

  onPurchaseOrderBlur(e) {
    this.savePurchaseOrder(e);
    this.onFocusOut();
  }

  onMonthsBlur(e) {
    this.onNumericInputBlur(e);
  }

  onValueBlur(e) {
    this.saveValue(e);
    this.onFocusOut();
  }

  onNumericInputBlur(e) {
    this.saveNumericInput(e);
    this.onFocusOut();
  }

  onJobOrderBlur(e) {
    this.saveJobOrder(e);
    this.onFocusOut();
  }

  getJocStyle() {
    return {
      background: this.props.item.project_type.color,
    };
  }

  getValueSuffix() {
    return this.props.isValueAsUnit ? null : 'K';
  }

  isSaving() {
    return this.props.itemsInSaving
      .filter((item) => item === this.props.item.project.id)
      .length > 0;
  }

  hasFocus() {
    return this.state.mouseIn || this.state.focus;
  }

  hasChanged(changes) {
    const item = {
      ...this.props.item,
      ...changes,
    };
    return !isEqual(item, this.props.item);
  }

  inputsAreVisible() {
    return this.props.canEditItem(this.props.item.pm.id, this.props.item.account.id)
      && (this.hasFocus() || !this.props.isValid || this.isSaving());
  }

  save(changes) {
    const isDirty = this.hasChanged(changes);

    if (isDirty && this.props.isValid) {
      this.props.onSave(changes);
    }
  }

  saveProject(e) {
    this.save({
      project: {
        [e.target.name]: e.target.value,
      },
    });
  }

  saveJobOrder(e) {
    const { value } = e.target;
    this.save({
      project: {
        id_job_order: value === '' ? null : value,
      },
    });
  }

  savePurchaseOrder(e) {
    const { value } = e.target;
    this.save({
      project: {
        client_po: value,
      },
    });
  }

  saveDate(e) {
    const date = e.target.value;
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
    this.save({
      project: {
        [e.target.name]: formattedDate,
      },
    });
  }

  saveNumericInput(e) {
    const { value } = e.target;
    const formattedValue = Item.parseNumericValue(value);

    this.save({
      project: {
        [e.target.name]: formattedValue,
      },
    });
  }

  saveValue(e) {
    const { value } = e.target;
    const parsedValue = Item.parseNumericValue(value);
    const changes = this.props.formatValueChanges(parsedValue);

    this.save({
      ...changes,
    });
  }

  /**
   * Saves client. When changing client, client contact must be cleared to avoid inconsistencies.
   * If we don't do that, contact of client A could be used for client B for an item, and this
   * makes no sense.
   * @see https://www.notion.so/wethod/Pipeline-0b8b2f6c3c824297961b7f7513000a25#056c6cbbea1241fd8b4498a539002372
   * @param e
   * @param client
   */
  saveClient(e, client) {
    this.save({
      client,
      customer: { id: null },
    });
  }

  saveCustomer(e, customer) {
    this.save({
      customer,
    });
  }

  savePm(e, pm) {
    this.save({
      pm,
    });
  }

  saveAccount(e, account) {
    const value = account !== null ? account : { id: null };
    this.save({
      account: value,
    });
  }

  saveRisk(e, value) {
    const risk = value !== null ? value : { id: null };
    this.save({
      risk,
    });
  }

  saveJoc(e, value) {
    const changes = this.props.formatJocChanges(value, this.props.item);
    this.save({
      ...changes,
      project: {
        ...changes.project,
      },
    });
  }

  saveProjectType(e, value) {
    const projectType = value !== null ? value : { id: null };
    this.save({
      project_label: projectType,
    });
  }

  saveStage(e, option) {
    const changes = this.props.formatStageChanges(option, this.props.item);
    this.save({
      ...changes,
      project: {
        ...changes.project,
      },
    });
  }

  saveProbability(e) {
    const { value } = e.target;
    const parsedValue = Item.parseNumericValue(value);

    const changes = this.props.formatProbabilityChanges(parsedValue, this.props.item);
    this.save({
      ...changes,
      project: {
        ...changes.project,
      },
    });
  }

  saveExternalCost(e) {
    this.save({
      external_cost: e.target.value,
    });
  }

  render() {
    const { item } = this.props;
    return (
      <TableRow key={item.project.id}
        visibleColumns={this.props.visibleColumns}
        feedback={this.isSaving() ? 'Saving' : ''}
        onMouseEnter={this.onMouseIn.bind(this)}
        onMouseLeave={this.onMouseOut.bind(this)}>
        <TableCell name="joc-badge" className="pipeline-item__joc-badge">
          <div style={this.getJocStyle()} />
        </TableCell>
        <NameCell
          name="name"
          label="Name"
          isIntercompany={item.project.intercompany}
          projectId={item.project.id}
          value={item.project.name}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onNameBlur.bind(this)}
          error={this.props.errors.name}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <ClientCell readOnly={!this.inputsAreVisible()}
          name="client"
          label="Client"
          onOpenSidebar={this.onOpenSidebarClick}
          rowHasFocus={this.hasFocus()}
          isContactRequired={this.props.isContactRequired}
          error={this.props.errors.client}
          value={item.client}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onFocusOut.bind(this)}
          onChange={this.saveClient.bind(this)}
          metadataService={this.props.metadataService} />
        <ClientContactCell readOnly={!this.inputsAreVisible()}
          name="customer"
          label="Client contact"
          onOpenSidebar={this.onOpenSidebarClick}
          rowHasFocus={this.hasFocus()}
          required={this.props.isContactRequired}
          error={this.props.errors.customer}
          value={item.customer}
          client={item.client}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onFocusOut.bind(this)}
          onChange={this.saveCustomer.bind(this)} />
        <PmCell readOnly={!this.inputsAreVisible()}
          name="pm"
          label="PM"
          error={this.props.errors.pm}
          value={item.pm}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onFocusOut.bind(this)}
          onChange={this.savePm.bind(this)}
          metadataService={this.props.metadataService} />
        <AccountCell readOnly={!this.inputsAreVisible()}
          name="account"
          label="Account"
          error={this.props.errors.account}
          value={item.account}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onFocusOut.bind(this)}
          onChange={this.saveAccount.bind(this)}
          metadataService={this.props.metadataService} />
        <JobOrderCategoryCell value={item.project_type.id}
          name="job-order-category"
          label="Job order category"
          color={item.project_type.color}
          updateErrors={this.props.updateErrors}
          error={this.props.errors.project_type}
          onChange={this.saveJoc.bind(this)}
          disabled={!this.props.canEditItemJoc(item)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <ProjectTypeCell readOnly={!this.inputsAreVisible()}
          value={item.project_label.id}
          name="project-type"
          label="Project type"
          onChange={this.saveProjectType.bind(this)}
          metadataService={this.props.metadataService} />
        <JobOrderCell
          name="job-order"
          label="Job order"
          value={item.project.id_job_order}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onJobOrderBlur.bind(this)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <DateCell readOnly={!this.inputsAreVisible()}
          name="contract_date"
          label="Signed on"
          error={this.props.errors.contract_date}
          value={item.project.contract_date}
          onFocus={this.onFocusIn.bind(this)}
          onChange={this.saveDate.bind(this)}
          metadataService={this.props.metadataService} />
        <StartCell readOnly={!this.inputsAreVisible()}
          name="date_start"
          label="Start"
          error={this.props.errors.date_start}
          value={item.project.date_start}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onChange={this.saveDate.bind(this)}
          metadataService={this.props.metadataService} />
        <MonthsCell readOnly={!this.inputsAreVisible()}
          name="duration"
          label="Months"
          error={this.props.errors.duration}
          value={item.project.duration}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onMonthsBlur.bind(this)}
          metadataService={this.props.metadataService} />
        <DateCell readOnly={!this.inputsAreVisible()}
          name="due_date"
          label="Due date"
          id={`${item.project.id}-due-date`}
          error={this.props.errors.due_date}
          value={item.project.due_date}
          onFocus={this.onFocusIn.bind(this)}
          onChange={this.saveDate.bind(this)}
          metadataService={this.props.metadataService} />
        <PurchaseOrderCell name="po"
          label="Client PO"
          value={item.project.client_po}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onPurchaseOrderBlur.bind(this)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <BudgetStatusCell status={item.budget.status} name="budget-status" />
        <ValueCell readOnly={!(this.props.canEditItemValue(item) && this.inputsAreVisible())}
          name="estimate"
          label="Value"
          error={this.props.errors.estimate}
          value={this.props.getValue()}
          suffix={this.getValueSuffix()}
          projectId={item.project.id}
          updateErrors={this.props.updateErrors}
          onFocus={this.onFocusIn.bind(this)}
          onBlur={this.onValueBlur.bind(this)}
          metadataService={this.props.metadataService} />
        <MarginCell value={this.props.getMargin()}
          name="margin"
          color={item.project_type.color} />
        <CostsPercentageCell value={this.props.getExternalCostsPercentage()}
          name="costs"
          label="External costs %"
          color={item.project_type.color}
          updateErrors={this.props.updateErrors}
          error={this.props.errors.costs}
          onChange={this.saveExternalCost.bind(this)}
          disabled={!this.props.canEditItemCosts(item.budget)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <CostsCell value={this.props.getExternalCostsValue()}
          name="costs-value"
          label="External costs"
          suffix={this.getValueSuffix()}
          readOnly />
        <ProbabilityCell value={item.project.probability}
          name="probability"
          label="Probability"
          color={item.project_type.color}
          error={this.props.errors.probability}
          updateErrors={this.props.updateErrors}
          onChange={this.saveProbability.bind(this)}
          archived={item.project.archived}
          showAsSelect={!this.props.isPipedriveIntegrationEnabled}
          disabled={!this.props.canEditItemProbability(item)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <BudgetConsumptionCell value={item.budget_consumption} name="budget-consumption" />
        <ProgressCell value={item.progress} name="progress" />
        <RiskCell value={item.risk.id}
          name="risk"
          label="Risk"
          color={item.risk.color}
          updateErrors={this.props.updateErrors}
          error={this.props.errors.risk}
          onChange={this.saveRisk.bind(this)}
          disabled={!this.props.canEditItemRisk(item)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <StageCell value={item.opportunity_status.id}
          name="stage"
          label="Stage"
          showStatus={item.project_type.track_opportunity_status}
          color={item.opportunity_status.color}
          updateErrors={this.props.updateErrors}
          error={this.props.errors.opportunity_status}
          onChange={this.saveStage.bind(this)}
          disabled={!this.props.canEditItemStage(item)}
          readOnly={!this.inputsAreVisible()}
          metadataService={this.props.metadataService} />
        <WhitelistCell enabled={item.project.timesheet_whitelist} name="timesheet_whitelist" />
        <DateCell readOnly
          name="archived-date"
          label="Archived on"
          id={`${item.project.id}-archived-date`}
          error={this.props.errors.archived_date}
          value={item.project.archived_date}
          metadataService={this.props.metadataService} />
        <TableCell className="pipeline-item__actions" name="actions">
          <OpenSidebarButton onClick={this.onOpenSidebarClick} />
        </TableCell>
      </TableRow>
    );
  }
}

module.exports = Item;
