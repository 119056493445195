/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class ImportantInfo extends React.Component {
  render() {
    return (
      <div>
        <div className="client-info--secondary">{this.props.person.client.corporate_name}</div>
        <div className="client-info--primary">{this.props.person.name}</div>
        <div className="client-info--primary">{this.props.person.surname}</div>
      </div>
    );
  }
};
