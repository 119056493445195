'use strict';

/* eslint-disable default-case */

Wethod.module('SettingsApp.Payment', function (Payment, Wethod, Backbone, Marionette, $, _) {
  this.CreditCardModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/payment/token',
    defaults: {
      cardNumber: '',
      exp_month: '',
      exp_year: '',
    },
  });

  this.PaymentInfoModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/payment/info',
  });

  this.ModalModel = Wethod.Utility.BaseModel.extend({});

  this.BillingInfoModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/billinginfo/',
    defaults: {
      type: 0,
      name: '',
      surname: '',
      business_name: '',
      country: '',
      address: '',
      telephone: '',
      vat: '',
      tax_code: '',
      sdi: '',
      pec: '',
    },
    validate: function (attrs) {
      var errors = [];
      var type = attrs.type;
      _.each(attrs, function (val, key) {
        var error = {};
        var field = key.replace('_', ' ').capitalize();

        if (key === 'business_name') {
          field = 'Company name';
        }

        if (type === 0) { // privato
          switch (key) {
            case 'name':
            case 'surname':
            case 'country':
            case 'address':
            case 'telephone':
            case 'tax_code':
              if (attrs[key].trim() === '') {
                error[key] = 'The ' + field + ' must be NOT empty';
                errors.push(error);
              }
              break;
          }
        } else if (type === 1) { // company
          switch (key) {
            case 'business_name':
            case 'country':
            case 'address':
            case 'telephone':
            case 'tax_code':
            case 'vat':
              if (attrs[key].trim() === '') {
                error[key] = 'The ' + field + ' must be NOT empty';
                errors.push(error);
              }
              break;
          }
        }
      });

      return (errors.length > 0) ? errors : false;
    },
  });

  var API = {
    sendPaymentToken: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/payment/customer/source',
        xhrFields: {
          withCredentials: true,
        },
        data: {
          source: options.stripe_token,
        },
      }).done(function (response) {
        if (response.code !== 200) {
          defer.resolve(undefined);
        } else {
          defer.resolve(response);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    getPaymentInfo: function () {
      var collection = new Payment.PaymentInfoModel();
      var defer = $.Deferred();

      collection.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });
      return defer.promise();
    },
    deletePaymentCard: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/payment/customer/source',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        // eslint-disable-next-line eqeqeq
        if (response.code != '200') {
          defer.resolve(undefined);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    getBillingInfo: function () {
      var model = new Payment.BillingInfoModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveBillingInfo: function (billingInfoModel) {
      var defer = $.Deferred();

      billingInfoModel.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteBillingInfo: function (billingInfoModel) {
      var defer = $.Deferred();

      billingInfoModel.destroy({
        success: function (model, response) {
          defer.resolve(response);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    checkPromocode: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/payment/coupon',
        xhrFields: {
          withCredentials: true,
        },
        data: { key: options.promocode },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
  };

  // card
  Wethod.reqres.setHandler('payment:set:token', function (options) {
    return API.sendPaymentToken(options);
  });
  Wethod.reqres.setHandler('payment:get:info', function () {
    return API.getPaymentInfo();
  });
  Wethod.reqres.setHandler('delete:payment:card', function () {
    return API.deletePaymentCard();
  });

  // billing info
  Wethod.reqres.setHandler('payment:get:billingInfo', function () {
    return API.getBillingInfo();
  });
  Wethod.reqres.setHandler('payment:save:billingInfo', function (model) {
    return API.saveBillingInfo(model);
  });
  Wethod.reqres.setHandler('payment:delete:billingInfo', function (model) {
    return API.deleteBillingInfo(model);
  });

  // promocode
  Wethod.reqres.setHandler('payment:check:promocode', function (options) {
    return API.checkPromocode(options);
  });
});
