const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../modal2/Modal.react');
const ShowIf = require('../../../ShowIf/ShowIf.react');
const Button = require('../../../Button/RoundedButton.react');
const TextInput = require('../../../TextInput.react');

class SaveSearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { label: '' };

    this.onLabelChange = this.onLabelChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  onLabelChange(name, value) {
    this.setState({ label: value });
  }

  onSaveClick() {
    this.props.onSaveClick(this.state.label);
  }

  canSave() {
    return !this.props.isSaving && this.state.label.length;
  }

  render() {
    return (
      <Modal title="Save search to pinboard" onCancelClick={this.props.onCloseClick}>
        <div>
          <p>Save search as:</p>
          <p><TextInput name="label" onChange={this.onLabelChange} /></p>
        </div>
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onCloseClick}>Cancel</Button>
          <Button onClick={this.onSaveClick} disabled={!this.canSave()}>Save</Button>
        </div>
        <div className="wethod-modal2__feedback">
          <ShowIf condition={this.props.isSaving}>
            <span>Saving...</span>
          </ShowIf>
        </div>
      </Modal>
    );
  }
}

SaveSearchModal.defaultProps = {
  isSaving: false,
};

SaveSearchModal.propTypes = {
  /**
   * If search is being saved.
   */
  isSaving: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  /**
   * Function to call when "save" button is clicked.
   *
   * @param label {string}
   */
  onSaveClick: PropTypes.func.isRequired,
};

module.exports = SaveSearchModal;
