const { connect } = require('react-redux');
const moment = require('moment');
const ActionsComponent = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  formattedDate: moment(state.date).format('DD/MM/YYYY'),
  buEnabled: state.buEnabled,
  buFilter: state.buFilter,
  projects: state.projects,
  userId: state.userId,
  totalWeekHours: state.totalWeekHours,
  employeeId: state.employee.id,
});

const mapDispatchToProps = (dispatch) => ({
  changeBusinessUnit: (name, units) => dispatch(actions.changeBuFilter(units)),
  showWeeklyHoursModal: (hours) => dispatch(actions.openWeeklyHoursModal(hours)),
  changeDate: (date, userId) => dispatch(actions.changeDate(date, userId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(ActionsComponent);
