const { connect } = require('react-redux');
const Component = require('../components/Backlog.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  getBacklogs: () => dispatch(actions.getBacklogs()),
  getProductions: () => dispatch(actions.getProductions()),
  getAvgRevenues: () => dispatch(actions.getAvgRevenues()),
});

module.exports = connect(null, mapDispatchToProps)(Component);
