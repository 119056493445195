const { connect } = require('react-redux');
const Component = require('../components/Budget.react');
const Reducer = require('../reducer');

const mapStateToProps = (state) => ({
  budgetInfo: state.info,
  project: state.project,
  targetVersion: state.targetVersion,
  isLoading: Reducer.waitingFor(state, 'get-budget-version') || Reducer.waitingFor(state, 'creation'),
});

module.exports = connect(mapStateToProps)(Component);
