'use strict';

Wethod.module('User', function (User, Wethod, Backbone, Marionette, $) {
  this.UserInfoModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authentication/userinfo',
  });

  var API = {
    getUserInfo: function () {
      var record = new User.UserInfoModel();
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          Wethod.Utility.requestMessage(response, 'getUserInfo');
          if (response.code !== 200) {
            defer.resolve(undefined);
          }
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(undefined);
          Wethod.Utility.requestMessage(response, 'getUserInfo', true);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('user:get:info', function () {
    return API.getUserInfo();
  });
});
