const { connect } = require('react-redux');
const Component = require('../../components/fiscal-year/FiscalYearSelect.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  fiscalYear: state.fiscalYear,
  isSaving: state.waitingFor.some((key) => key === 'get-fiscal-year'),
});

const mapDispatchToProps = (dispatch) => ({
  showFiscalYearChangeModal:
    (previousFiscalYear, fiscalYear) => {
      dispatch(actions.showFiscalYearChangeConfirmModal(previousFiscalYear, fiscalYear));
    },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
