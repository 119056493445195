'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette) {
  // SEARCH HINT
  this.SearchLoadingTemplateView = Marionette.ItemView.extend({
    className: 'searchFeedback',
    template: '#projectCharterSearchLoadingTemplate',
  });
  this.SearchNotFoundTemplateView = Marionette.ItemView.extend({
    className: 'searchFeedback',
    template: '#projectCharterSearchNotFoundTemplateView',
  });
  this.HintView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#projectCharterHintTemplate',
    ui: {
      hintEL: '[data-action="selectHint"]',
    },
    events: {
      'click @ui.hintEL': 'selectHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    selectHint: function (e) {
      e.preventDefault();
      // chiamo una funzione del parent per aggiornare i dati
      this.options.parentView.changeHint(this.model.get('id'), this.model.get('hint'));
    },
  });
  this.HintsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'list',
    childView: ProjectCharterApp.HintView,
  });
});
