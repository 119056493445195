const constants = require('./constants');

class TimesheetsReducer {
  constructor(state) {
    this.state = {
      ...state,
      timesheets: [],
      waitingFor: [],
      pageOffset: 0,
      pageLimit: 0,
      hasMorePages: true,
      search: '',
      sort: 'asc',
      order: 'date',
      filters: {},
      modalToShow: null,
      modalData: null,
      transferData: {
        timesheets: [],
        updatedTimesheets: [],
        pageOffset: 0,
        pageLimit: 0,
        hasMorePages: true,
        search: '',
        sort: 'asc',
        order: 'date',
        selectAll: false,
      },
      frozenUpTo: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_TIMESHEETS_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          search: action.search,
          order: action.order,
          sort: action.sort,
          timesheets: action.offset ? state.timesheets : [],
          waitingFor: state.waitingFor.concat('get-timesheets'),
        };
      }
      case constants.GET_TIMESHEETS_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.timesheets.length >= state.pageLimit,
          timesheets: state.timesheets.concat(action.timesheets),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-timesheets'),
        };
      }
      case constants.GET_TIMESHEETS_TO_TRANSFER_REQUEST:
        return {
          ...state,
          transferData: {
            ...state.transferData,
            timesheets: action.offset ? state.transferData.timesheets : [],
            pageLimit: action.limit,
            pageOffset: action.offset,
            hasMorePages: true,
            search: action.search,
            order: action.order,
            sort: action.sort,
          },
          waitingFor: state.waitingFor.concat('get-timesheets-to-transfer'),
        };
      case constants.GET_TIMESHEETS_TO_TRANSFER_SUCCESS:
        return {
          ...state,
          transferData: {
            ...state.transferData,
            hasMorePages: action.timesheets.length >= state.transferData.pageLimit,
            timesheets: state.transferData.timesheets.concat(action.timesheets),
          },
          waitingFor: state.waitingFor.filter((key) => key !== 'get-timesheets-to-transfer'),
        };
      case constants.RESET_TIMESHEETS_TO_TRANSFER:
        return {
          ...state,
          transferData: {
            timesheets: [],
            updatedTimesheets: [],
            pageOffset: 0,
            pageLimit: 0,
            hasMorePages: true,
            search: '',
            sort: 'asc',
            order: 'date',
            selectAll: false,
          },
        };
      case constants.SELECT_ALL_TRANSFER:
        // Toggle the flag to select all in the transfer data and update all the timesheets to reflect the change
        return {
          ...state,
          transferData: {
            ...state.transferData,
            selectAll: !state.transferData.selectAll,
            updatedTimesheets: state.transferData.updatedTimesheets.map((timesheet) => ({
              ...timesheet,
              selected: !state.transferData.selectAll,
            })),
          },
        };
      case constants.SELECT_TIMESHEET_TO_TRANSFER:
        // Toggle the selected flag for the timesheet with the given id
        // The 'select all' flag is not updated based on the selected timesheets,
        // it's only updated when the user clicks the 'select all' button
        return {
          ...state,
          transferData: {
            ...state.transferData,
            // Update the updatedTimesheets array with the selected timesheet
            // If the timesheet is not already in the array, add it
            updatedTimesheets: state.transferData.updatedTimesheets
              .some((timesheet) => `${timesheet.id_timetracking}-${timesheet.date}` === action.id)
              ? state.transferData.updatedTimesheets.map((timesheet) => {
                if (`${timesheet.id_timetracking}-${timesheet.date}` === action.id) {
                  return {
                    ...timesheet,
                    selected: action.selected,
                  };
                }
                return timesheet;
              })
              : state.transferData.updatedTimesheets
                .concat({
                  ...state.transferData.timesheets
                    .find((timesheet) => `${timesheet.id_timetracking}-${timesheet.date}` === action.id),
                  selected: action.selected,
                }),
          },
        };
      case constants.UPDATE_HOURS_TO_TRANSFER:
        return {
          ...state,
          transferData: {
            ...state.transferData,
            // Update the timesheet in the updatedTimesheets array with the new hours
            // If the timesheet is not already in the array, add it
            updatedTimesheets: state.transferData.updatedTimesheets
              .some((timesheet) => `${timesheet.id_timetracking}-${timesheet.date}` === action.id)
              ? state.transferData.updatedTimesheets.map((timesheet) => {
                if (`${timesheet.id_timetracking}-${timesheet.date}` === action.id) {
                  return {
                    ...timesheet,
                    selected: true,
                    hours_to_transfer: action.hours,
                  };
                }
                return timesheet;
              })
              : state.transferData.updatedTimesheets
                .concat({
                  ...state.transferData.timesheets
                    .find((timesheet) => `${timesheet.id_timetracking}-${timesheet.date}` === action.id),
                  selected: true,
                  hours_to_transfer: action.hours,
                }),
          },
        };
      case constants.TRANSFER_HOURS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('transfer-hours'),
        };
      case constants.TRANSFER_HOURS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'transfer-hours'),
        };
      case constants.TRANSFER_HOURS_ERROR:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'transfer-hours'),
        };
      case constants.CLOSE_SIDEBAR:
        return ({
          ...state,
          sidebarToShow: null,
        });
      case constants.SHOW_ADVANCED_SEARCH:
        return ({
          ...state,
          sidebarToShow: 'advanced-search',
        });
      case constants.APPLY_ADVANCED_SEARCH:
        return ({
          ...state,
          filters: action.filters,
          sidebarToShow: null,
        });
      case constants.SHOW_MODAL:
        return ({
          ...state,
          modalToShow: action.key,
          modalData: action.data,
        });
      case constants.CLOSE_MODAL:
        return ({
          ...state,
          modalToShow: null,
        });
      case constants.GET_FROZEN_PERIOD_REQUEST:
        return ({
          ...state,
          waitingFor: state.waitingFor.concat('get-frozen-period'),
        });
      case constants.GET_FROZEN_PERIOD_SUCCESS:
        return ({
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-frozen-period'),
          frozenUpTo: action.frozenUpTo,
        });
      case constants.GET_FROZEN_PERIOD_ERROR:
        return ({
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-frozen-period'),
          frozenUpTo: null,
        });
      default:
        return state;
    }
  }
}

module.exports = TimesheetsReducer;
