'use strict';

Wethod.module('BudgetTemplateApp', function (BudgetTemplateApp) {
  this.startWithParent = false;

  var API = {
    showBudgetTemplate: function () {
      BudgetTemplateApp.Controller.show();
    },
  };

  BudgetTemplateApp.on('start', function (options) {
    API[options.foo]();
  });
});
