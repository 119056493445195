'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod) {
  this.TerminalOperandModel = Wethod.Utility.BaseModel.extend({
    isNumeric: function () {
      return this.get('type') === 'numeric';
    },
    isSelect: function () {
      return this.get('type') === 'select';
    },
    parseToJson: function () {
      var json = {
        type: this.get('type'),
        value: this.get('value'),
      };
      return json;
    },
  });
});
