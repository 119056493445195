/* eslint-disable react/prop-types */
const React = require('react');
const Model = require('../models/AlertsWidget');
const Loading = require('./Loading.react');
const Empty = require('./Empty.react');
const Alert = require('./Alert.react');

module.exports = class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: true,
      alerts: [],
    };
  }

  componentDidMount() {
    const model = new Model(this.props.config);

    $.when(model.getModel()).done((alerts) => this.setState({
      alerts,
      waiting: false,
    }));
  }

  getBody() {
    if (this.state.waiting) {
      return <Loading />;
    } if (!this.state.alerts.length) {
      return <Empty />;
    }
    return this.state.alerts.map((alert) => <Alert key={alert.alert.id} {...alert} />);
  }

  render() {
    const title = this.props.title ? this.props.title : 'Alerts';
    return (
      <div className="wethod-widget widget-alerts">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">{title}</span>
        </div>
        <div className="wethod-widget__body">{this.getBody()}</div>
      </div>
    );
  }
};
