const React = require('react');
const TextFilter = require('../../../../../../../common/react/AdvancedSearch/filters/TextFilter/TextFilter.react');
const CheckboxFilter = require('../../../../../../../common/react/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');

const MetadataFilter = ({ name, value, onChange, expanded, label, type, availableValues }) => {
  if (type === 'list') {
    return (
      <CheckboxFilter name={name}
        label={label}
        onChange={onChange}
        value={value}
        expanded={expanded}
        availableOptions={availableValues} />
    );
  }
  return (
    <TextFilter name={name}
      label={label}
      onChange={onChange}
      value={value}
      expanded={expanded} />
  );
};

module.exports = MetadataFilter;
