'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Authorization = require('../../../models/Authorization');
var TimesheetSettings = require('../../../models/TimesheetSettings');
var TimesheetLogStore = require('../../../apps/core/modules/report/timesheet-changelog/store');
var TimesheetLogReducer = require('../../../apps/core/modules/report/timesheet-changelog/reducer');
var TimesheetLog = require('../../../apps/core/modules/report/timesheet-changelog/index');

Wethod.module('ReportApp.TimesheetChangelog', function (TimesheetChangelog, Wethod, Backbone, Marionette, $) {
  TimesheetChangelog.Controller = {
    showNoPermission: function () {
      var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
      Wethod.regions.body.show(permissionDeniedView);
    },
    show: function () {
      $.when(
        Authorization.getPermissions('reports'),
        TimesheetSettings.getStrategy()
      ).done(function (reportPermissions, strategy) {
        if (_.isUndefined(reportPermissions) || !reportPermissions.view_timesheet) {
          TimesheetChangelog.Controller.showNoPermission();
        } else {
          var layout = new TimesheetChangelog.StructureLayoutView();
          layout.render();

          var reducerWrapper = new TimesheetLogReducer({
            reportPermissions: reportPermissions,
            strategy: strategy.strategy,
          });
          var store = TimesheetLogStore(reducerWrapper.reduxReducer);
          var element = React.createElement(TimesheetLog, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        }
      }).fail(function () {
        TimesheetChangelog.Controller.showNoPermission();
      });
    },
  };
});
