/* eslint-disable react/no-array-index-key,no-shadow */
const React = require('react');
const Priority = require('./Priority.react');

module.exports = class PrioritySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.props.selectedPriority;

    this.updatePriority = this.updatePriority.bind(this);
  }

  getPriorities() {
    return this.props.availablePriorities.map((priority, index) => {
      const isSelected = (priority.id === this.state.id);
      return (
        <Priority key={index}
          selected={isSelected}
          updatePriority={this.updatePriority}
          {...priority} />
      );
    });
  }

  updatePriority(priority) {
    this.setState(priority, () => {
      const priority = { alertId: this.props.alertId, ...this.state };
      this.props.updatePriority(priority);
    });
  }

  render() {
    return (
      <div className="alerts-engine__alert-priorities">
        {this.getPriorities()}
      </div>
    );
  }
};
