/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions */
const React = require('react');
const ScaleDownTransition = require('../Transition/ScaleDownTransition/ScaleDownTransition.react');
const RevealTransition = require('../Transition/RevealTransition/RevealTransition.react');

/**
 * A modal component.
 *
 * PROPS
 * title
 * children: component to be displayed in the modal's body
 * onCancelClick: function(value)
 * className
 *
 * @deprecated
 *
 * @type {module.Modal}
 */
module.exports = class Modal extends React.Component {
  render() {
    return (
      <RevealTransition triggered animateFirstMount>
        <div className={`wethod-modal2 ${this.props.className}`}>
          <ScaleDownTransition triggered animateFirstMount>
            <div className="wethod-modal2__container">
              <div className="wethod-modal2__header">
                <h2>{this.props.title}</h2>
                <div
                  onClick={this.props.onCancelClick}
                  className="wethod-modal2__header-action wethod-icon-medium-discard wethod-icon-medium-discard--black" />
              </div>
              <div className="wethod-modal2__body">{this.props.children}</div>
            </div>
          </ScaleDownTransition>
        </div>
      </RevealTransition>
    );
  }
};
