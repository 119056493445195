const constants = require('./constants');

class ProjectReviewReducer {
  constructor(state) {
    this.state = {
      ...state,
      project: null,
      people: [],
      loading: true,
      saving: false,
      error: null, // 'not-found'|'completed'
      step: 0,
      totalSteps: 2,
      modal: null,
      people_filter: '',
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_REVIEW_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case constants.GET_REVIEW_SUCCESS: {
        return {
          ...state,
          ...action.review,
          project: {
            ...action.review.project,
            criteria: action.review.project_criteria
              .map((criterion) => ({ ...criterion, vote: 0 })),
          },
          people: action.review.people.map((person) => ({
            ...person,
            criteria: action.review.team_criteria.map((criterion) => ({ ...criterion, vote: 0 })),
            note: null,
          })),
          loading: false,
        };
      }
      case constants.GET_REVIEW_COMPLETED_FAILURE: {
        return {
          ...state,
          loading: false,
          error: 'completed',
          step: 2,
        };
      }
      case constants.GET_REVIEW_NOT_FOUND_FAILURE: {
        return {
          ...state,
          loading: false,
          error: 'not-found',
        };
      }
      case constants.GO_TO_STEP: {
        return {
          ...state,
          step: action.index,
        };
      }
      case constants.EDIT_PROJECT_VOTE: {
        return {
          ...state,
          project: {
            ...state.project,
            criteria: state.project.criteria.map((criterion) => {
              if (criterion.id === action.criterionId) {
                return { ...criterion, vote: action.vote };
              }
              return criterion;
            }),
          },
        };
      }
      case constants.EDIT_PERSON_VOTE: {
        return {
          ...state,
          people: state.people.map((person) => {
            if (person.id === action.personId) {
              return {
                ...person,
                criteria: person.criteria.map((criterion) => {
                  if (criterion.id === action.criterionId) {
                    return { ...criterion, vote: action.vote };
                  }
                  return criterion;
                }),
              };
            }
            return person;
          }),
        };
      }
      case constants.EDIT_PERSON_NOTE: {
        return {
          ...state,
          modal: null,
          people: state.people.map((person) => {
            if (person.id === action.personId) {
              return { ...person, note: action.note };
            }
            return person;
          }),
        };
      }
      case constants.SHOW_UNSAVED_CHANGES_MODAL: {
        return {
          ...state,
          modal: {
            name: 'unsaved-changes',
          },
        };
      }
      case constants.SHOW_SAVE_MODAL: {
        return {
          ...state,
          modal: {
            name: 'save',
          },
        };
      }
      case constants.SHOW_PERSON_NOTE_MODAL: {
        return {
          ...state,
          modal: {
            name: 'person-note',
            personId: action.personId,
            note: action.note,
          },
        };
      }
      case constants.SHOW_MISSING_VOTE_MODAL: {
        return {
          ...state,
          modal: {
            name: 'missing-vote',
            step: action.step,
          },
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modal: null,
        };
      }
      case constants.SAVE_REVIEW_REQUEST: {
        return {
          ...state,
          saving: true,
        };
      }
      case constants.SAVE_REVIEW_SUCCESS: {
        return {
          ...state,
          saving: false,
          error: 'completed',
          step: 2,
          modal: null,
        };
      }
      case constants.FILTER_PEOPLE: {
        return {
          ...state,
          people_filter: action.keyword,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = ProjectReviewReducer;
