const React = require('react');
const isEqual = require('react-fast-compare');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const IntercompanyService = require('../../../../../../services/IntercompanyService');
const AlertAccordion = require('./alerts/AlertAccordion.react');
const DashboardHeader = require('../../containers/dashboard/DashboardHeader');
const UnarchivedProjectsAccordion = require('./unarchived-projects/UnarchivedProjectsAccordion.react');
const OutstandingProjectsAccordion = require('./outstanding-projects/OutstandingProjectsAccordion.react');
const IntercompanyActivityAccordion = require('./intercompany/IntercompanyActivityAccordion.react');

module.exports = class DataFreezingDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSegment
      && !isEqual(this.props.selectedSegment, prevProps.selectedSegment)) {
      this.props.getTotals(this.props.selectedSegment.from, this.props.selectedSegment.to);
    }
  }

  render() {
    return (
      <div className="data-freezing-engine-mb--m">
        <DashboardHeader selectedSegment={this.props.selectedSegment} />
        <UnarchivedProjectsAccordion counter={this.props.totals?.unarchived_projects} />
        <OutstandingProjectsAccordion counter={this.props.totals?.outstanding_projects} />
        <ShowIf condition={IntercompanyService.isIntercompanyEnabled()}>
          <IntercompanyActivityAccordion counter={this.props.totals?.intercompany_activity} />
        </ShowIf>
        <AlertAccordion
          title="Alerts Overview"
          subtitle="Check on red alerts and eventually resolve them in order to guarantee quality of data."
          counter={this.props.totals?.alerts} />
      </div>
    );
  }
};
