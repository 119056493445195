'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  var chartBubbles = function (series, maxYAxis) {
    return {
      chart: {
        renderTo: 'statusChart',
        type: 'bubble',
        ignoreHiddenSeries: false,
      },
      title: {
        text: ' ',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        // title : '',
        title: {
          text: 'project status % (days)',
          align: 'high',
          style: {
            fontSize: '10px',
            color: '#ccc',
          },
        },
        labels: {
          enabled: false,
        },
        max: 100,
      },
      yAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        // title : '',
        title: {
          text: 'budget consuption % (days)',
          align: 'high',
          style: {
            fontSize: '10px',
            color: '#ccc',
          },
        },
        labels: {
          enabled: false,
        },
        min: 0,
        max: maxYAxis,
      },
      series: series,
      tooltip: {
        formatter: function () {
          return this.series.name;
        },
      },
      plotOptions: {
        bubble: {
          maxSize: 30,
          minSize: 5,
        },
      },
    };
  };

  this.WidgetBubblesItemView = Marionette.ItemView.extend({
    className: 'relative',
    template: '#widgetBubblesTemplate',
    ui: {
      chartRegion: '[data-region="bubblesChart"]',
      mdlInput: '[data-region="mdl-input"]',

      // hint wrapper
      wrapTooltipHintEl: '[data-region="wrapFilterTooltipHint"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      inputHintEl: '[data-action="inputHint"]',

      // save
      saveEl: '[data-action="saveWidget"]',
      // save feedback
      saveFeedbackEl: '[data-region="widgetSaveFeedback"]',
      // delete
      deleteEl: '[data-action="deleteWidget"]',
      // settings
      settingsRegionEl: '[data-region="widgetSettings"]',
      // footer popup
      footerEl: '[data-region="widgetFooter"]',
      footerInfoLabelEl: '[data-region="footerInfo"]',
      footerProjectLabelEl: '[data-region="footerProjectName"]',
      // settings
      settingsEl: '[data-action="settingsWidget"]',

      // from behavior
      showFilterEl: '[data-action="showFilterTooltip"]',
    },
    events: {
      'click @ui.saveEl': 'saveWidget',
      'touchstart @ui.saveEl': 'saveWidget',

      'click @ui.deleteEl': 'deleteWidget',
      'touchstart @ui.deleteEl': 'deleteWidget',

      'click @ui.chartRegion': 'closeDetail',
      'touchstart @ui.chartRegion': 'closeDetail',

      'click @ui.settingsEl': 'closeDetail',
      'touchstart @ui.settingsEl': 'closeDetail',
    },
    behaviors: {
      settingsWidget: {},
    },
    initialize: function (options) {
      this.options = options;

      this.options.filterType = options.model.get('config').active_filter.name || null;
      this.options.filterValue = options.model.get('config').active_filter.value || null;
    },
    onRender: function () {
      var that = this;
      var width = this.options.widgetView.$el.width();
      // resize chart region
      this.ui.chartRegion.css({ width: width });

      if (this.options.new) {
        this.ui.settingsRegionEl.addClass('isVisible');
      }

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      this.ui.chartRegion.highcharts(chartBubbles(that.options.series, that.options.maxYAxis));
    },
    closeDetail: function () {
      this.ui.footerEl.removeClass('isVisible');
    },
    saveWidget: function (e) {
      e.preventDefault();

      if (!this.ui.saveEl.hasClass('disabled')) {
        this.ui.saveEl.addClass('disabled');
        this.ui.showFilterEl.addClass('disabled');
        this.ui.inputHintEl.addClass('disabled');
        this.ui.deleteEl.hide();
        this.ui.saveFeedbackEl.show();

        var data = {
          filterType: this.options.filterType,
          filterValue: this.options.filterValue,
          filterLabel: this.options.filterLabel,
        };

        if (
          this.model.get('config').active_filter.name !== this.options.filterType
          || this.model.get('config').active_filter.value !== this.options.filterValue
        ) {
          DashboardApp.saveBubbles(this, data);
        } else {
          this.ui.settingsRegionEl.removeClass('isVisible');
        }
      }
    },
    deleteWidget: function (e) {
      e.preventDefault();
      if (!this.ui.deleteEl.hasClass('disabled')) {
        this.ui.deleteEl.addClass('disabled');
        DashboardApp.deleteBubbles(this);
      }
    },
  });
});
