const React = require('react');
const Row = require('../SettingsRow.react');
const Select = require('../SettingsSelect.react');
const SelectItem = require('../SettingsSelectItem.react');

const TimesheetReminder = ({ timeLimit, waitingForUpdating, onTimeLimitBufferChange }) => {
  const feedback = waitingForUpdating ? 'Saving...' : null;

  function handleTimeLimitBufferChange(e) {
    const { value } = e.target;

    if (onTimeLimitBufferChange) {
      onTimeLimitBufferChange({ project_end_buffer: value });
    }
  }

  return (
    <Row feedback={feedback} className={!timeLimit.enabled && 'company-settings__row-disabled'}>
      <p>
        Allow a time gap to edit or record worked hours
        <span style={{ fontWeight: 'bold' }}> after the due date of a project (from the following Monday)</span>,
        if specified. Otherwise after the end of a project
      </p>
      <Select
        name="timesheet-time-limit-buffer"
        label="timesheet-time-limit-buffer"
        value={timeLimit.project_end_buffer}
        onChange={handleTimeLimitBufferChange}
        disabled={!timeLimit.enabled || waitingForUpdating}>
        <SelectItem value="0">0</SelectItem>
        <SelectItem value="1">1</SelectItem>
        <SelectItem value="2">2</SelectItem>
        <SelectItem value="3">3</SelectItem>
        <SelectItem value="4">4</SelectItem>
      </Select>
    </Row>
  );
};

module.exports = TimesheetReminder;
