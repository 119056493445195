const NodeTotal = require('./StructureNodeTotal');

module.exports = class TaskNodeTotal extends NodeTotal {
  /**
   * @param {number} days
   * @param {number} cost
   * @param {number} price
   * @param {number} externalCost
   * @param {LevelAmountNodeTotal[]} levels
   */
  constructor(days, cost, price, externalCost, levels) {
    super(days, cost, price);
    this.levels = levels;
  }

  /**
   * @return {LevelAmountNodeTotal[]}
   */
  getLevels() {
    return this.levels;
  }

  toJson() {
    return {
      days: this.getDays(),
      cost: this.getCost(),
      price: this.getPrice(),
      levels: this.getLevels().map((level) => level.toJson()),
    };
  }
};
