const constants = require('./constants');

module.exports.showSidebar = () => ({
  type: constants.SHOW_SIDEBAR,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

module.exports.showConfirmDeleteModal = (supplier) => ({
  type: constants.SHOW_MODAL_CONFIRM_DELETE,
  data: supplier,
});

module.exports.showRatingModal = (rating) => ({
  type: constants.SHOW_MODAL_RATING,
  data: rating,
});

const showMoveSupplierModal = (data) => ({
  type: constants.SHOW_MODAL_MOVE_SUPPLIER,
  data,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const formatSupplierForSaving = (supplier) => {
  const status = supplier.status ? supplier.status.id : null;
  const paymentTerm = supplier.payment_term ? supplier.payment_term.id : null;
  const country = supplier.country ? supplier.country.name : null;
  const province = supplier.province ? supplier.province.name : null;
  return {
    ...supplier,
    status,
    payment_term: paymentTerm,
    country,
    province,
  };
};

const updateSupplierRequest = () => ({
  type: constants.UPDATE_SUPPLIER_REQUEST,
});

const updateSupplierSuccess = (supplier) => ({
  type: constants.UPDATE_SUPPLIER_SUCCESS,
  item: supplier,
});

module.exports.updateSupplier = (supplier) => (dispatch) => {
  dispatch(updateSupplierRequest());
  const supplierFormatted = formatSupplierForSaving(supplier);
  const model = new Wethod.FinanceApp.Supplier.SupplierModel(supplierFormatted);
  const updateRequest = Wethod.request('save:finance:supplier', model);
  $.when(updateRequest).done(() => dispatch(updateSupplierSuccess(supplier)));
};

const deleteSupplierRequest = () => ({
  type: constants.DELETE_SUPPLIER_REQUEST,
});

const deleteSupplierFailure = () => ({
  type: constants.DELETE_SUPPLIER_FAILURE,
});

const deleteSupplier = (supplier) => (dispatch) => {
  dispatch(deleteSupplierRequest());

  const model = new Wethod.FinanceApp.Supplier.SupplierModel(supplier);
  const deleteRequest = Wethod.request('delete:finance:supplier', model);
  $.when(deleteRequest).done((response) => {
    if (response === 200) {
      Wethod.navigate('finance/suppliers', { trigger: true });
    } else if (response.length > 0) {
      dispatch(deleteSupplierFailure());
      dispatch(showMoveSupplierModal({
        supplier,
        orders: response,
      }));
    }
  });
};

const showCannotMoveModal = (supplierFrom, supplierTo) => ({
  type: constants.SHOW_MODAL_CANNOT_MOVE,
  supplierFrom,
  supplierTo,
});

module.exports.moveSupplier = (oldSupplier, newSupplier) => (dispatch) => {
  const moveRequest = Wethod.request('switch:finance:supplier:recors', {
    fromId: oldSupplier.id,
    toId: newSupplier.id,
  });
  $.when(moveRequest).done((response) => {
    if (response) {
      dispatch(deleteSupplier(oldSupplier));
    } else {
      dispatch(showCannotMoveModal(oldSupplier, newSupplier));
    }
  });
};

const getRatingsRequest = () => ({
  type: constants.GET_RATINGS_REQUEST,
});

const getRatingsSuccess = (ratings) => ({
  type: constants.GET_RATINGS_SUCCESS,
  ratings,
});

module.exports.getRatings = (supplierId) => (dispatch) => {
  dispatch(getRatingsRequest());
  const request = Wethod.request('get:finance:supplier:ratings', { supplierId });
  $.when(request).done((ratings) => dispatch(getRatingsSuccess(ratings.toJSON())));
};

const saveRatingRequest = () => ({
  type: constants.SAVE_RATING_REQUEST,
});

const saveRatingSuccess = (rating, isNew) => ({
  type: constants.SAVE_RATING_SUCCESS,
  rating,
  isNew,
});

module.exports.saveRating = (supplierId, rating) => (dispatch) => {
  dispatch(saveRatingRequest());
  const request = Wethod.request('save:finance:supplier:rating', supplierId, rating);
  $.when(request).done((savedRating) => dispatch(
    saveRatingSuccess(savedRating.attributes, rating.id === undefined),
  ));
};

module.exports.showDeleteRatingModal = (rating) => ({
  type: constants.SHOW_DELETE_RATING_MODAL,
  rating,
});

const deleteRatingRequest = () => ({
  type: constants.DELETE_RATING_REQUEST,
});

const deleteRatingSuccess = (rating) => ({
  type: constants.DELETE_RATING_SUCCESS,
  rating,
});

module.exports.deleteRating = (supplierId, rating) => (dispatch) => {
  dispatch(deleteRatingRequest());
  const request = Wethod.request('delete:finance:supplier:rating', supplierId, rating.id);
  $.when(request).done(() => dispatch(
    deleteRatingSuccess(rating),
  ));
};

module.exports.showMoveSupplierModal = showMoveSupplierModal;
module.exports.deleteSupplier = deleteSupplier;
