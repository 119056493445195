const { connect } = require('react-redux');
const Component = require('../../components/modals/DeleteJOTemplateConfirmModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForDelete: state.waitingFor.filter((key) => key.startsWith('delete-template-')),
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) => dispatch(actions.deleteTemplate(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
