'use strict';

// This module retrieves invoices from api, mounts them in the given view and sets up the listener
// of all needed events
Wethod.module('FinanceApp.Invoice', function (Invoice, Wethod, Backbone, Marionette, $, _, Header) {
  this.Init = function (financeLayout, data) {
    var invoiceLayout = new Invoice.Layout(data);
    var invoiceHeaderView = new Invoice.HeaderView();

    financeLayout.render();
    var sectionHeaderModel = new Header.SectionHeaderModel({
      current_section: 'Invoices',
      preview_anchor_id: 'invoices',
      helper_url: 'finance/index/#invoices',
    });
    var headerRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });

    financeLayout.financeBody.show(invoiceLayout);
    invoiceLayout.header.show(invoiceHeaderView);
    invoiceLayout.sectionHeader.show(headerRegion);

    invoiceLayout.placeFixedHeader();

    // events
    dispatcher.on('finance:invoice:showModalNote', function (invoiceView) {
      var invoiceModalModel = new Invoice.ModalModel({
        note: invoiceView.model.get('note'),
      });
      var invoiceNoteModalView = new Invoice.NoteModalView({
        model: invoiceModalModel,
        parent: invoiceView,
      });
      invoiceLayout.dialog.show(invoiceNoteModalView);
    }, invoiceLayout);

    dispatcher.on('finance:invoice:showModalConfirm', function (invoiceView) {
      var confirmModalView = new Invoice.ConfirmModalView({
        parent: invoiceView,
      });
      invoiceLayout.dialog.show(confirmModalView);
    }, invoiceLayout);

    dispatcher.on('finance:invoice:showModalExchangeRate', function (invoiceView) {
      var modal = new Invoice.ExchangeRateModalView({
        parent: invoiceView,
        model: new Backbone.Model({
          rate: invoiceView.model.getExchangeRate(),
          currentMasterFinalNet: invoiceView.model.get('budget').final_net_price,
          budgetCurrency: invoiceView.model.get('currency').code,
          masterCurrency: Wethod.userInfo.attributes.currency.code,
          invoiceBudgetValue: invoiceView.model.get('change'),
          hasManualRate: invoiceView.model.get('exchange_rate') !== null,
          defaultRate: invoiceView.model.get('currency').rate,
        }),
      });
      invoiceLayout.dialog.show(modal);
    }, invoiceLayout);

    dispatcher.on('finance:invoice:showModalCannotSend', function (invoiceData) {
      var modal = new Invoice.MissingIntercompanyConnectionModalView({
        parent: invoiceData.view,
        model: invoiceData.model,
      });
      if (invoiceData.type === 'missing-client-connection') {
        modal = new Invoice.MissingClientConnectionModalView({
          parent: invoiceData.view,
          model: new Backbone.Model({
            invoice: invoiceData.model.attributes,
            intercompanyInfo: invoiceData.intercompanyInfo,
          }),
        });
      }
      invoiceLayout.dialog.show(modal);
    }, invoiceLayout);

    dispatcher.on('finance:invoice:showModalExport', function () {
      var exportModalModel = new Invoice.ModalModel({
        title: 'Export Invoices',
      });
      var exportModalView = new Invoice.ConfirmExportModalView({
        model: exportModalModel,
      });
      invoiceLayout.dialog.show(exportModalView);
    }, invoiceLayout);

    // evento che mostra la modal per scegliere il destinatario della mail
    dispatcher.on('finance:invoice:showModalRecipients', function (model) {
      var modal = new Invoice.ChooseRecipientModalView({
        contacts: data.contacts,
        invoiceModel: model,
      });
      invoiceLayout.dialog.show(modal);
    }, invoiceLayout);

    // edit details modal
    dispatcher.on('finance:invoice:showModalEditDetails', function (invoiceView) {
      var vatRateList = data.vatRates.toJSON();
      var vatRate = invoiceView.model.get('vat_rate');
      if (vatRate) {
        if (vatRate.is_archived) {
          /**
           * Add the current vat rate to the list to be sure it is included in the options.
           * Since it could be archived, it could not already be in the list.
           * It should be disabled since it cannot be selected again.
           */
          vatRateList.push(vatRate);
        }

        vatRate = vatRate.id; // The modal needs only the id of the current vat rate
      }
      var paymentTermList = data.paymentTerms.toJSON();
      var paymentTerm = invoiceView.model.get('payment_term');
      if (paymentTerm) {
        if (paymentTerm.is_archived) {
          /**
           * Add the current payment term to the list to be sure it is included in the options.
           * Since it could be archived, it could not already be in the list.
           * It should be disabled since it cannot be selected again.
           */
          paymentTermList.push(paymentTerm);
        }

        paymentTerm = paymentTerm.id; // The modal needs only the id of the current payment term
      }

      var modal = new Invoice.EditDetailsModalView({
        parent: invoiceView,
        model: new Backbone.Model({
          is_paid: invoiceView.model.get('status') === 2,
          has_stamp_tax: invoiceView.model.get('has_stamp_tax'),
          cig: invoiceView.model.get('cig'),
          bef: invoiceView.model.get('bef'),
          purchase_order: invoiceView.model.get('purchase_order'),
          statement: invoiceView.model.get('invoice_statement'),
          cost_accounting: invoiceView.model.get('cost_accounting'),
          credit_note_of: invoiceView.model.get('credit_note_of'),
          vat_rate: vatRate,
          vatRates: vatRateList,
          payment_term: paymentTerm,
          paymentTerms: paymentTermList,
          pay_date: invoiceView.model.get('pay_date') ? moment(invoiceView.model.get('pay_date')).format('DD/MM/YYYY') : null,
        }),
      });
      invoiceLayout.dialog.show(modal);
    }, invoiceLayout);

    // export single invoice as XML modal
    dispatcher.on('finance:invoice:showModalXmlExport', function (isLoading, title) {
      var downloadModalModel = new Invoice.ModalModel({
        title: title,
        isLoading: isLoading,
      });
      var exportModalView = new Invoice.DownloadExportModalView({
        model: downloadModalModel,
      });
      invoiceLayout.dialog.show(exportModalView);
    }, invoiceLayout);

    // show error modal with message
    dispatcher.on('finance:invoice:showErrorModal', function (message) {
      var errorModalModel = new Invoice.ModalModel({
        errorText: message,
      });
      var errorModalView = new Invoice.ErrorModalView({
        model: errorModalModel,
      });
      invoiceLayout.dialog.show(errorModalView);
    }, invoiceLayout);

    // show files modal with upload, download and delete
    dispatcher.on('finance:invoice:showFilesModal', function (invoiceView) {
      var filesModalModel = new Invoice.ModalModel({
        title: 'Documents',
        files: invoiceView.model.get('files'),
      });
      var filesModalView = new Invoice.FilesModalView({
        parent: invoiceView,
        model: filesModalModel,
      });
      invoiceLayout.dialog.show(filesModalView);
    }, invoiceLayout);

    // show files download error modal
    dispatcher.on('finance:invoice:showFileDownloadErrorModal', function (invoiceView, error) {
      var filesModalModel = new Invoice.ModalModel({
        error: error,
      });
      var filesModalView = new Invoice.DownloadErrorModalView({
        parent: invoiceView,
        model: filesModalModel,
      });
      invoiceLayout.dialog.show(filesModalView);
    }, invoiceLayout);

    // show files upload error modal
    dispatcher.on('finance:invoice:showFileUploadErrorModal', function (invoiceView, error) {
      var filesModalModel = new Invoice.ModalModel({
        error: error,
      });
      var filesModalView = new Invoice.UploadErrorModalView({
        parent: invoiceView,
        model: filesModalModel,
      });
      invoiceLayout.dialog.show(filesModalView);
    }, invoiceLayout);

    return financeLayout.financeBody.currentView.invoiceLayout;
  };
}, Wethod.module('HeaderApp.Header'));
