const React = require('react');
const SectionError = require('./SectionError.react');

const ForbiddenSectionError = () => {
  const message = 'It seems you\'ve found a secret garden... but such as you can\'t look around any more.';
  const description = 'Please, get a room anywhere you wish in WETHOD.';
  return (
    <SectionError message={message} description={description} />
  );
};

module.exports = ForbiddenSectionError;
