const React = require('react');
const Modal = require('../../../../../../../../../common/react/Modal/Modal.react');
const FormValidator = require('../../../../../../../../../common/react/FormValidator/FormValidator.react');
const ModalContent = require('./SaveTemplateModalContent.react');
const RequestManager = require('../../../../../containers/RequestManager');

module.exports = class SaveTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  onSaveClick() {
    this.props.save(this.props.budgetId,
      this.state.name, this.state.description);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  }

  render() {
    return (
      <Modal title="Save as template" onClose={this.props.closeModal}>
        <FormValidator>
          <RequestManager>
            <ModalContent onChange={this.onChange}
              closeModal={this.props.closeModal}
              isSaving={this.props.isSaving}
              onSaveClick={this.onSaveClick}
              name={this.state.name}
              description={this.state.description} />
          </RequestManager>
        </FormValidator>
      </Modal>
    );
  }
};
