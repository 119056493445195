/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const KeyResult = require('../../../containers/modals/Objective/KeyResult');
const Title = require('./ObjectiveTitle.react');
const Loading = require('../../../../../../../../common/react/LoadingSmall.react');

module.exports = class ObjectiveBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }

  getKeyResults() {
    return this.props.objective.key_results.map((kr) => (
      <KeyResult key={kr.id}
        updateFormErrors={this.updateFormErrors.bind(this)}
        {...kr}
        quarterId={this.props.objective.quarterId}
        objectiveId={this.props.objective.id} />
    ));
  }

  onAddKeyResultClick() {
    this.props.addKeyResult({
      name: '',
      progress: 0,
      target: 100,
    }, this.props.objective.quarterId, this.props.objective.id);
  }

  /**
   * @param errorId
   * @param type ('add' | 'remove') tells if you want to add or remove a languageID from the errors array
   */
  updateFormErrors(errorId, type) {
    this.setState((previusState) => {
      if (type === 'add') {
        return {
          ...previusState,
          errors: previusState.errors.concat(errorId),
        };
      }
      if (type === 'remove') {
        return {
          ...previusState,
          errors: previusState.errors.filter((error) => error !== errorId),
        };
      }
    });
  }

  getFeedback() {
    if (this.props.waiting) {
      return <div className="wethod-modal2__feedback">Saving...</div>;
    }
    if (this.props.creating) {
      return <div className="wethod-modal2__feedback">Creating...</div>;
    }
    if (this.state.errors.length > 0) {
      return (
        <div className="wethod-modal2__feedback wethod-modal2__feedback--error">
          Please provide a title for the objective plus a name and a target for each key result
        </div>
      );
    }
    return null;
  }

  onDoneClick() {
    this.props.hideModal();
  }

  onEditTitle(title) {
    this.props.editObjective({
      ...this.props.objective,
      name: title,
    }, this.props.objective.quarterId, this.props.employee);
  }

  getKeyResultsHeader() {
    if (this.props.objective.key_results.length) {
      return (
        <div className="wethod-modal2-list__header">
          <span className="wethod-modal2-list__header-item profile-objectives__key-result-name-col">Key Result</span>
          <span className="wethod-modal2-list__header-item profile-objectives__key-result-target-col">Target</span>
        </div>
      );
    }
    return null;
  }

  onDeleteClick() {
    this.props.deleteObjective(this.props.objective, this.props.objective.quarterId);
  }

  render() {
    if (this.props.objective && this.props.objective.id) {
      return (
        <div className="profile-objectives__item--editable">
          <div className="wethod-modal2-list__header">
            <span className="wethod-modal2-list__header-item">Name</span>
          </div>
          <Title title={this.props.objective.name}
            onEditTitle={this.onEditTitle.bind(this)}
            updateFormErrors={this.updateFormErrors.bind(this)} />
          <button type="button"
            onClick={this.onAddKeyResultClick.bind(this)}
            className="profile-objectives__item-add wethod-button">
            + Key Result
          </button>
          {this.getKeyResultsHeader()}
          <div className="profile-objectives__item-key-result-collection">
            {this.getKeyResults()}
          </div>
          <div className="profile-contact-info__actions">
            <button type="button"
              onClick={this.onDeleteClick.bind(this)}
              className="wethod-button wethod-button--warning">
              Delete Objective
            </button>
            <button type="button"
              onClick={this.onDoneClick.bind(this)}
              className="wethod-button">Done
            </button>
          </div>
          {this.getFeedback()}
        </div>
      );
    }
    return <Loading />;
  }
};
