const { connect } = require('react-redux');
const Component = require('../../components/modals/SaveModal.react');
const actions = require('../../actions');

const formatCriterion = (criterion) => ({
  id: criterion.id,
  vote: criterion.vote === 0 ? null : criterion.vote,
});

const mapStateToProps = (state) => ({
  saving: state.saving,
  id: state.id,
  review: {
    project: {
      criteria: state.project.criteria.map(formatCriterion),
    },
    people: state.people.map((person) => ({
      id: person.id,
      note: person.note,
      criteria: person.criteria.map(formatCriterion),
    })),
  },
});

const mapDispatchToProps = (dispatch) => ({
  save: (id, review) => dispatch(actions.saveReview(id, review)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
