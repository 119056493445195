/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');

/**
 * An outlined wrapper for input component, material styled. Requires an input as child.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary icon: icon shown on the left side of the input
 * required: boolean inputType: value given to 'data-input-type' attribute selector (default
 * 'text') children: input that will be used; it can be of any type (text, numeric, text-area,..)
 * but needs to have specific design: value is required accept onChange, onFocus, onBlur,
 * onValidate props.
 *
 * @type {module.SidebarWrapperEditable}
 */
module.exports = class SidebarWrapperEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      receivedFocus: false, // check if the input has ever received focus: errors should not be
      // shown before the first focus
      errors: null, // list of errors of the input, needed to set the error text
    };

    this.modifiedProps = {
      onFocus: this.handleFocus.bind(this),
      onBlur: this.handleBlur.bind(this),
      onValidate: this.handleErrors.bind(this),
    };
  }

  isValueDirty() {
    const value = this.props.children.props.value ? this.props.children.props.value : '';
    return (value != null && value !== '');
  }

  handleFocus() {
    this.setState({ isFocused: true });

    if (this.props.children.props.onFocus) {
      this.props.children.props.onFocus();
    }
  }

  handleBlur(state, e) {
    this.setState({
      isFocused: false,
      receivedFocus: true,
    });

    if (this.props.children.props.onBlur) {
      this.props.children.props.onBlur(state, e);
    }
  }

  handleErrors(name, errors) {
    if (errors.length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: null });
    }

    if (this.props.children.props.onValidate) {
      this.props.children.props.onValidate(name, errors);
    }
  }

  getInputClassName() {
    let style = 'material-input__input';
    if (this.state.isFocused) {
      style += ' material-input__input--focused';
    }
    if (this.props.error || (this.state.errors && this.state.receivedFocus)) {
      style += '  material-input__input--error';
    }
    return style;
  }

  getLabelClassName() {
    let style = 'material-input__label';
    if (this.state.isFocused || this.isValueDirty()) {
      style += ' material-input__label--minimized';
    }
    if (this.props.required) {
      style += ' material-input__label--required';
    }
    return style;
  }

  getIconClassName() {
    if (this.props.icon) {
      return 'material-input__icon';
    }
  }

  getErrorText(errors) {
    if (errors) {
      if (errors.includes('required')) {
        return 'This field is required';
      }
      if (errors.includes('email')) {
        return 'Please insert a valid email';
      }
      if (errors.includes('integer')) {
        return 'Please insert an integer number';
      }
    }
  }

  getHelperText() {
    if (this.props.error) {
      return this.props.error;
    }
    if (this.state.errors && this.state.receivedFocus) {
      return this.getErrorText(this.state.errors);
    }
    if (this.props.helperText) {
      return this.props.helperText;
    }
  }

  /**
   * Gives to the children the props modified to enable the behavior of the material input
   */
  getChildren() {
    if (this.props.children) {
      return ({
        ...this.props.children,
        props: { ...this.props.children.props, ...this.modifiedProps },
      });
    }
  }

  render() {
    return (
      <div className="material-input"
        data-input-type={this.props.inputType || 'text'}>
        <div className={this.getIconClassName()}>
          {this.props.icon}
        </div>
        <div className={this.getInputClassName()}>
          <div className={this.getLabelClassName()}>{this.props.placeholder}</div>
          {this.getChildren()}
          <div className="material-input__helper-text">{this.getHelperText()}</div>
        </div>
      </div>
    );
  }
};
