'use strict';

Wethod.module('PricelistApp', function (PricelistApp) {
  this.startWithParent = false;

  var API = {
    showPricelists: function () {
      PricelistApp.Controller.show();
    },
  };

  PricelistApp.on('start', function (options) {
    API[options.foo]();
  });
});
