const React = require('react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');

/**
 * Table header for weekly timesheet with daily switch off.
 * @param {number} hours total hours worked by employee in the current week
 * @param {number} capacity total amount of hours expected from employee (based on working hours capacity) in the current week
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
const WeeklyTableHead = ({ hours, capacity, ...rest }) => (
  <TableHead {...rest}>
    <TableRow className="timesheet-weekly__row">
      <TableCell className="timesheet-weekly__cell-options" />
      <TableCell className="timesheet-weekly__cell-project">Project</TableCell>
      <TableCell key="auto_timesheet" className="timesheet-weekly__cell-automatic" />
      <TableCell className="timesheet-weekly__cell-job-order hidden-mobile">Job order</TableCell>
      <TableCell className="timesheet-weekly__cell-notes hidden-mobile">Notes</TableCell>
      <TableCell className="timesheet-weekly__cell-total">Tot. Hours</TableCell>
    </TableRow>
    <TableRow className="timesheet-weekly__summary">
      <TableCell className="timesheet-weekly__cell-spacer" />
      <TableCell className="timesheet-weekly__cell-total">{`${hours}/${capacity}`}</TableCell>
    </TableRow>
  </TableHead>
);

module.exports = WeeklyTableHead;
