const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const BaseTableRow = require('../../../../../../common/react/Table2/TableRow.react');
const OpenSidebarButton = require('../../../../../../common/react/OpenSidebarButton.react');
const Escaper = require('../../../../../../common/react/formatters/Escaper.react');
const ChangeStatusButton = require('../containers/ChangeStatusButton');
const PlanningButton = require('./PlanningButton.react');
const StatusCell = require('./TableStatusCell.react');
const FormatService = require('../../../../../../services/FormatService');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const TableBodyRow = ({
  item, getStartDate, getEndDate, getTotalHours, getStatusLabel, showSidebar, canApproveItem,
}) => {
  const canApprove = canApproveItem(item.employee.allocation_manager);

  function onOpenSidebarClick() {
    showSidebar(item);
  }

  const getUser = () => {
    const user = item.employee;
    return `${user.name} ${user.surname}`;
  };

  const getManager = () => {
    const manager = item.employee.allocation_manager;

    return manager ? `${manager.name} ${manager.surname}` : '';
  };

  return (
    <BaseTableRow>
      <TableCell className="allocation-request-item__project">
        <Escaper>{item.project.name}</Escaper>
      </TableCell>
      <TableCell className="allocation-request-item__user">
        <Escaper>{getUser()}</Escaper>
      </TableCell>
      <TableCell className="allocation-request-item__from">
        {FormatService.formatDate(getStartDate())}
      </TableCell>
      <TableCell className="allocation-request-item__to">
        {FormatService.formatDate(getEndDate())}
      </TableCell>
      <TableCell className="allocation-request-item__hours">
        {FormatService.formatDecimalNumber(getTotalHours())}
      </TableCell>
      <TableCell className="allocation-request-item__manager">
        <Escaper>{getManager()}</Escaper>
      </TableCell>
      <TableCell className="allocation-request-item__status">
        <StatusCell status={item.status} label={getStatusLabel()} />
      </TableCell>
      <TableCell className="allocation-request-item__actions">
        <ShowIf condition={canApprove}>
          <ChangeStatusButton item={item} />
        </ShowIf>
        <PlanningButton projectId={item.project.id} user={item.employee} />
        <OpenSidebarButton onClick={onOpenSidebarClick} />
      </TableCell>
    </BaseTableRow>
  );
};

module.exports = TableBodyRow;
