const React = require('react');
const PropTypes = require('prop-types');
const Typography = require('../Typography/Typography.react');

require('./style.scss');

/**
 * Basic tag component, customizable.
 * It can be clickable, providing the onClick prop.
 * The tag can also be deleted providing the onDelete prop,
 * that makes a cross icon visible and clickable.
 * The content of the tag is given as children.
 * @param id
 * @param children
 * @param disabled
 * @param className
 * @param onClick
 * @param onDelete
 * @param inline
 * @param fontSize
 */
const Tag = ({ id, children, disabled, className, onClick, onDelete, inline, fontSize }) => {
  function isDeletable() {
    return !disabled && onDelete;
  }

  function isClickable() {
    return !disabled && onClick;
  }

  function handleDeleteClick(e) {
    e.preventDefault();
    onDelete(id, children, e);
  }

  function handleClick(e) {
    e.preventDefault();
    if (isClickable()) {
      onClick(id, children, e);
    }
  }

  /**
   * Activates the button with the enter or space key.
   * @param {KeyboardEvent} event
   */
  function handleKeyUp(event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      handleClick(event);
    }
  }

  function getDelete() {
    if (isDeletable()) {
      return (
        <button type="button"
          data-testid="wethod-tag-delete-button"
          className="wethod-icon-button"
          onClick={handleDeleteClick}>
          <span className="wethod-icon wethod-icon-small-discard" />
        </button>
      );
    }
    return null;
  }

  function getClass() {
    let style = 'wethod-tag';
    if (disabled) style += ' disabled';
    if (isClickable()) style += ' wethod-tag--clickable';
    if (inline) style += ' wethod-tag--inline';
    if (fontSize) style += ` wethod-typography--size-${fontSize}`;
    if (className) style += ` ${className}`;
    return style;
  }

  // Button behaviour
  if (isClickable()) {
    return (
      <span className={getClass()} role="button" tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
        {children}
        {getDelete()}
      </span>
    );
  }
  // Static behaviour
  return (
    <span className={getClass()}>
      {children}
      {getDelete()}
    </span>
  );
};

Tag.propTypes = {
  /**
   * Id of the tag. It will be given as argument for click and delete events.
   */
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Content shown in the tag. It can be a simple string or an element
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  /**
   * Whether the tag should be disabled or not, changing its behaviour and layout.
   * When it is disabled, it cannot be clicked or deleted, and the delete icon will not be shown.
   */
  disabled: PropTypes.bool,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * Function handling the click on the entire tag.
   * When this is given, the tag will serve as a button.
   * The click has no effect if the tag is disabled.
   * @param id {number || string} the given id
   * @param content {element || string} the given children
   * @param event {event} the event triggered by click
   */
  onClick: PropTypes.func,
  /**
   * Function handling the click on the delete icon.
   * When this is not given, the delete icon will not be shown.
   * This has no effect if the tag is disabled.
   * @param id {number || string} the given id
   * @param content {element || string} the given children
   * @param event {event} the event triggered by click
   */
  onDelete: PropTypes.func,
  /**
   * Whether the tag should be displayed inline or not.
   */
  inline: PropTypes.bool,
  /**
   * The font size of the tag.
   * @see Typography.SIZES
   */
  fontSize: PropTypes.oneOf(Object.values(Typography.SIZES)),
};

Tag.defaultProps = {
  id: null,
  disabled: false,
  className: null,
  onClick: undefined,
  onDelete: undefined,
  inline: false,
  fontSize: Typography.SIZES.PX12,
};

module.exports = Tag;
