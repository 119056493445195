const { connect } = require('react-redux');
const Component = require('../../../../../../../common/react/date-selectors/MonthSelector.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  internationalCalendar: state.internationalCalendar,
  date: state.date.format('YYYY-MM-DD'),
  isWaiting: state.waiting,
});

const mapDispatchToProps = (dispatch) => ({
  changeDate: (date) => dispatch(actions.getBriefing(date)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
