const { connect } = require('react-redux');
const Component = require('../../../components/TextArea/TextAreaModal/TextAreaModalBodyEdit.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  summary: state.brief.summary,
  description: state.brief.description,
  projectId: state.info.id,
  isSaving: state.waitingFor.filter((key) => key === 'save-brief').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  save: (brief, projectId) => dispatch(actions.saveBrief(brief, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
