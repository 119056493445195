const { connect } = require('react-redux');
const Component = require('../../components/modals/AllocationApproveModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'allocation-approve' || key === 'allocation-reject').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  approveAllocation: (id) => dispatch(actions.approveAllocation(id)),
  rejectAllocation: (id) => dispatch(actions.rejectAllocation(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
