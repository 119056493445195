const React = require('react');
const PropTypes = require('prop-types');

const ModalBox = ({ children }) => (
  <div className="wethod-modal__box">{children}</div>
);

ModalBox.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = ModalBox;
