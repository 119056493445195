module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.FILTER_EVENTS = 'FILTER_EVENTS';
module.exports.SHOW_DELETE_EVENT_MODAL = 'SHOW_DELETE_EVENT_MODAL';
module.exports.SHOW_EVENT_DETAILS = 'SHOW_EVENT_DETAILS';
module.exports.HIDE_EVENT_DETAILS = 'HIDE_EVENT_DETAILS';

module.exports.FILTER_EVENTS_REQUEST = 'FILTER_EVENTS_REQUEST';
module.exports.FILTER_EVENTS_SUCCESS = 'FILTER_EVENTS_SUCCESS';
module.exports.FILTER_EVENTS_FAILURE = 'FILTER_EVENTS_FAILURE';

module.exports.SAVE_EVENT_REQUEST = 'SAVE_EVENT_REQUEST';
module.exports.SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS';
module.exports.SAVE_EVENT_FAILURE = 'SAVE_EVENT_FAILURE';

module.exports.SAVE_FOLLOWUP_REQUEST = 'SAVE_FOLLOWUP_REQUEST';
module.exports.SAVE_FOLLOWUP_SUCCESS = 'SAVE_FOLLOWUP_SUCCESS';
module.exports.SAVE_FOLLOWUP_FAILURE = 'SAVE_FOLLOWUP_FAILURE';

module.exports.CLOSE_NEW_EVENT_FORM = 'CLOSE_NEW_EVENT_FORM';
module.exports.OPEN_NEW_EVENT_FORM = 'OPEN_NEW_EVENT_FORM';

module.exports.CLOSE_EDIT_EVENT_FORM = 'CLOSE_EDIT_EVENT_FORM';
module.exports.OPEN_EDIT_EVENT_FORM = 'OPEN_EDIT_EVENT_FORM';

module.exports.CLOSE_NEW_EVENT_FORM = 'CLOSE_NEW_EVENT_FORM';
module.exports.OPEN_NEW_EVENT_FORM = 'OPEN_NEW_EVENT_FORM';

module.exports.DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
module.exports.DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
module.exports.DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

module.exports.UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
module.exports.UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
module.exports.UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

module.exports.UPDATE_FOLLOWUP_REQUEST = 'UPDATE_FOLLOWUP_REQUEST';
module.exports.UPDATE_FOLLOWUP_SUCCESS = 'UPDATE_FOLLOWUP_SUCCESS';
module.exports.UPDATE_FOLLOWUP_FAILURE = 'UPDATE_FOLLOWUP_FAILURE';

module.exports.MORE_EVENTS_REQUEST = 'MORE_EVENTS_REQUEST';
module.exports.MORE_EVENTS_SUCCESS = 'MORE_EVENTS_SUCCESS';
module.exports.MORE_EVENTS_FAILURE = 'MORE_EVENTS_FAILURE';
