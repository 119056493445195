const React = require('react');

const PlaceholderBlock = ({ children, type, format }) => (
  <div className="company-settings-jo-template__block-draggable"
    data-id={type}
    data-format={format}>
    {children}
  </div>
);

module.exports = PlaceholderBlock;
