/* eslint-disable react/require-default-props,class-methods-use-this */
const React = require('react');
const PropTypes = require('prop-types');

/**
 * A button with icon, title and subtitle for navigation through steps.
 *
 * @type {module.NavigationButton}
 */
class NavigationButton extends React.Component {
  handleClick() {
    if (this.props.onClick) this.props.onClick(this.props.name);
  }

  getIcon() {
    return this.props.icon;
  }

  getArrow() {
    return (
      <span className="navigation-button__arrow wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
    );
  }

  getLabel() {
    return (
      <span className="navigation-button__label">
        <span className="navigation-button__title">
          {this.props.title}
        </span>
        <span className="navigation-button__subtitle">
          {this.props.subtitle}
        </span>
      </span>
    );
  }

  render() {
    return (
      <button type="button"
        onClick={this.handleClick.bind(this)}
        className="navigation-button">
        {this.getIcon()}
        {this.getLabel()}
        {this.getArrow()}
      </button>
    );
  }
}

NavigationButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,

  // Element to be shown on the side of the navigation button
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.node)]),

  // Function associated to click on the navigation button
  onClick: PropTypes.func,
};

module.exports = NavigationButton;
