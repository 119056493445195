const React = require('react');
const Token = require('../../containers/PersonalApiToken/Token');
const Table2 = require('../../../../../../../common/react/Table2/Table2.react');
const Header = require('./TokenListHeader.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');

module.exports = class TokenList extends React.Component {
  // Ready for pagination
  static loadMore() {
    return null;
  }

  componentDidMount() {
    this.props.getTokens();
    this.props.getTokenMetadata();
  }

  getRows() {
    return this.props.tokens
      .map((token) => (<Token key={token.id} {...token} />));
  }

  render() {
    return (
      <div className="company-settings__table">
        <Table2 maxHeight={375}>
          <Header />
          <TableBody hasMore={this.props.isLoading} loadMore={TokenList.loadMore} empty="No tokens yet">
            {this.getRows()}
          </TableBody>
        </Table2>
      </div>
    );
  }
};
