module.exports.FILTER_REVIEW_REQUESTS = 'FILTER_REVIEW_REQUESTS';
module.exports.FILTER_REVIEW_REQUESTS_SUCCESS = 'FILTER_REVIEW_REQUESTS_SUCCESS';

module.exports.LOAD_MORE_REVIEW_REQUESTS = 'LOAD_MORE_REVIEW_REQUESTS';
module.exports.LOAD_MORE_REVIEW_REQUESTS_SUCCESS = 'LOAD_MORE_REVIEW_REQUESTS_SUCCESS';

module.exports.CHANGE_YEAR = 'CHANGE_YEAR';

module.exports.CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST';
module.exports.CREATE_REVIEW_REQUEST_SUCCESS = 'CREATE_REVIEW_REQUEST_SUCCESS';

module.exports.DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
module.exports.DELETE_REVIEW_REQUEST_SUCCESS = 'DELETE_REVIEW_REQUEST_SUCCESS';

module.exports.GET_LAST_REVIEW_REQUEST = 'GET_LAST_REVIEW_REQUEST';
module.exports.GET_LAST_REVIEW_SUCCESS = 'GET_LAST_REVIEW_SUCCESS';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
