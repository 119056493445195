'use strict';

Wethod.module('PlanningApp', function (PlanningApp, Wethod, Backbone, Marionette, $, _) {
  this.getPermission = function (label) {
    if (!_.isUndefined(PlanningApp.authorization.get(label))) {
      return PlanningApp.authorization.get(label);
    }
    return false;
  };

  this.canEditBudget = function (amIPmOrAccount) {
    if (!_.isUndefined(PlanningApp.budgetAuthorization.get('edit')) && !_.isUndefined(PlanningApp.budgetAuthorization.get('edit_other'))) {
      return PlanningApp.budgetAuthorization.get('edit') && (PlanningApp.budgetAuthorization.get('edit_other') || amIPmOrAccount);
    }
    return false;
  };
});
