'use strict';

Wethod.module('FinanceApp', function (FinanceApp, Wethod) {
  // inibito start automatico del modulo allo start dell'aplicazione
  // in modo da utilizzare il router globale
  this.startWithParent = false;

  // API messe a disposizione per accedere ai vari moduli
  var API = {
    showInvoice: function (options) {
      FinanceApp.Controller.showInvoice(options);
    },
    prepareInvoice: function (options) {
      FinanceApp.Controller.prepareInvoice(options);
    },
    showOrder: function (options) {
      FinanceApp.Controller.showOrder(options);
    },
    showClient: function (options) {
      FinanceApp.Controller.showClient(options);
    },
    showPersonClient: function (id) {
      FinanceApp.Controller.showPersonClient(id);
    },
    showCompanyClient: function (id) {
      FinanceApp.Controller.showCompanyClient(id);
    },
    showSuppliers: function () {
      FinanceApp.Controller.showSuppliers();
    },
    showSupplierDetail: function (id) {
      FinanceApp.Controller.showSupplierDetail(id);
    },
  };

  // request utilizzata da widget dashboard per accedere alla pagina con invoice creati
  // automaticamnete
  dispatcher.on('url:invoice:show', function (data) {
    Wethod.navigate('finance/invoice');
    dispatcher.trigger('set:active:tab', 'finance');
    window.scrollTo(0, 0);
    API.prepareInvoice(data);
  }, dispatcher);

  // allo start del modulo faccio eseguire una delle API
  FinanceApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'finance');

    API[options.foo](options.params);
  });
});
