/* eslint-disable no-shadow */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

const FileModel = require('./FileModel');

module.exports = Backbone.Collection.extend({
  initialize(attributes, options) {
    this.projectId = options ? options.projectId : null;
  },
  model: FileModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  url() {
    return `${APIURL}/file-storage/`;
  },
  getFiles() {
    const defer = $.Deferred();
    this.url = `${this.url()}project/${this.projectId}`;
    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
  upload(attributes) {
    const model = new FileModel(attributes, { projectId: this.projectId });

    return model.upload();
  },
  download(id, name) {
    const model = new FileModel({ id });

    return model.download(name);
  },
  deleteModel(id) {
    const model = new FileModel({ id }, { projectId: this.projectId });
    const defer = $.Deferred();

    model.destroy({
      url: `${this.url() + id}/project`,
      success(model) {
        defer.resolve(model);
      },
      error(model, response) {
        defer.resolve(response);
      },
      wait: true,
    });
    return defer.promise();
  },
});
