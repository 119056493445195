const React = require('react');
const RequestManager = require('../../../../containers/RequestManager');
const Content = require('../../../../containers/Calculator/Task/JobTitleInsertModal/JobTitleInsertModalContent');
const ErrorModal = require('./JobTitleInsertModalError.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');

/**
 * Modal used to add job titles to a specific task.
 * Job titles can be added only to a "clean task", that is a task with no man-days.
 * @param closeModal
 * @param taskId
 * @param taskDays amount of days related to task (not counting job title days)
 * @param taskJobTitles list of job titles related to task
 * @return {JSX.Element}
 * @constructor
 */
const JobTitleInsertModal = ({ closeModal, taskId, taskDays, taskJobTitles = [] }) => (
  <ShowIf condition={taskDays === 0} showElse={<ErrorModal closeModal={closeModal} />}>
    <RequestManager>
      <Content
        taskId={taskId}
        taskDays={taskDays}
        taskJobTitles={taskJobTitles}
        closeModal={closeModal} />
    </RequestManager>
  </ShowIf>
);

module.exports = JobTitleInsertModal;
