const React = require('react');
const Header = require('./TimelineHeader.react');
const Body = require('../../containers/timeline/TimelineBody');
const Legend = require('./TimelineLegend.react');

module.exports = class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleMonths: [],
    };

    this.cellWidth = 36; // cell width + spacing
    this.headerReference = null;

    this.updateVisibleMonths = this.updateVisibleMonths.bind(this);
    this.setHeaderRef = this.setHeaderRef.bind(this);
    this.addPastMonths = this.addPastMonths.bind(this);
    this.addFutureMonths = this.addFutureMonths.bind(this);
    this.setDefaultMonths = this.setDefaultMonths.bind(this);
  }

  componentDidMount() {
    this.setDefaultMonths();
  }

  setHeaderRef(el) {
    this.headerReference = el;
  }

  setDefaultMonths() {
    this.updateVisibleMonths(this.getDefaultMonths());
  }

  /**
   * Get default months that can fit in header, starting from next month and going back
   * @returns {*[]}
   */
  getDefaultMonths() {
    // Get inner width of header
    const headerWidth = this.headerReference.offsetWidth;
    // Calc number of months that can fit in header
    const monthsCount = Math.floor(headerWidth / this.cellWidth);
    // Get first month
    const firstMonth = new Date();
    firstMonth.setDate(1);
    firstMonth.setMonth(firstMonth.getMonth() - (monthsCount - 1));
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() + 1);
    // Generate months
    const months = [];
    const month = new Date(firstMonth);
    while (month <= lastMonth) {
      months.push(new Date(month));
      month.setMonth(month.getMonth() + 1);
    }

    return months;
  }

  updateVisibleMonths(visibleMonths) {
    this.setState({ visibleMonths });
  }

  /**
   * Add 6 past months to visible months, and remove 6 future months
   */
  addPastMonths() {
    let { visibleMonths } = this.state;
    visibleMonths.splice(-6); // Remove last 6 months
    const firstMonth = visibleMonths[0];
    const newMonth = new Date(firstMonth);
    const months = Array.from({ length: 6 }).map(() => {
      newMonth.setMonth(newMonth.getMonth() - 1);
      return new Date(newMonth);
    });
    visibleMonths = months.reverse().concat(visibleMonths);
    this.updateVisibleMonths(visibleMonths);
  }

  /**
   * Add 6 future months to visible months, and remove 6 past months
   */
  addFutureMonths() {
    const { visibleMonths } = this.state;
    visibleMonths.splice(0, 6); // Remove first 6 months
    const lastMonth = visibleMonths[visibleMonths.length - 1];
    const newMonth = new Date(lastMonth);
    const months = Array.from({ length: 6 }).map(() => {
      newMonth.setMonth(newMonth.getMonth() + 1);
      return new Date(newMonth);
    });
    this.updateVisibleMonths(visibleMonths.concat(months));
  }

  render() {
    return (
      <div className="data-freezing-engine-mb--m">
        <Header visibleMonths={this.state.visibleMonths}
          setRef={this.setHeaderRef}
          onPrevClick={this.addPastMonths}
          onNextClick={this.addFutureMonths}
          onTodayClick={this.setDefaultMonths} />
        <Body visibleMonths={this.state.visibleMonths} />
        <Legend />
      </div>
    );
  }
};
