/* eslint-disable no-shadow */

'use strict';

Wethod.module('SettingsApp.Profile', function (Profile, Wethod, Backbone, Marionette, $) {
  this.ProfileModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/profile/',
  });
  this.PersonModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/person/',
  });
  this.GenderModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/person/gender',
  });
  this.SkillModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var skillId = this.options.skillId;
      if (skillId) return APIURL + '/skill/' + skillId + '/employee/';
      return APIURL + '/skill/employee/';
    },
  });
  this.InterestModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var interestId = this.options.interestId;
      if (interestId) return APIURL + '/interest/' + interestId + '/employee/';
      return APIURL + '/interest/employee/';
    },
  });
  this.LanguageModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/language/',
  });
  this.LanguagesCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/language/',
    model: Profile.LanguageModel,
  });
  this.PortfolioWorkModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/portfolio-item/',
  });
  this.SponsorshipModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/sponsorship/',
  });
  this.ObjectivesByQuarterModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var employeeId = this.options.employeeId;
      var year = this.options.year ? this.options.year : moment().format('YYYY');
      return APIURL + '/okr/' + employeeId + '?year=' + year;
    },
  });
  this.ObjectiveModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/okr/objective',
  });
  this.KeyResultModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var objectiveId = this.options.objective;
      return APIURL + '/okr/objective/' + objectiveId + '/key-result';
    },
  });
  this.PeopleItemModel = Wethod.Utility.BaseModel.extend({});
  this.PeopleCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/sponsorship/person',
    model: Profile.PeopleItemModel,
  });
  this.YearResolutionModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var year = this.options.year;
      return APIURL + '/okr/resolution/' + year;
    },
  });
  this.FridaynessTrendModel = Wethod.Utility.BaseModel.extend({});
  this.FridaynessTrendCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/profile-trends/fridayness/' + this.options.employeeId;
    },
    model: Profile.FridaynessTrendModel,
  });
  this.ChargeabilityTrendModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/profile-trends/chargeability/' + this.options.employeeId;
    },
  });
  this.DoThePlanTrendModel = Wethod.Utility.BaseModel.extend({});
  this.DoThePlanTrendCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/profile-trends/do-the-plan/' + this.options.employeeId;
    },
    model: Profile.DoThePlanTrendModel,
  });
  this.KeepTheLightsOffTrendModel = Wethod.Utility.BaseModel.extend({});
  this.KeepTheLightsOffTrendCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/profile-trends/keep-the-lights-off/' + this.options.employeeId;
    },
    model: Profile.KeepTheLightsOffTrendModel,
  });
  this.WorkingConnectionsTrendModel = Wethod.Utility.BaseModel.extend({});
  this.WorkingConnectionsTrendCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/profile-trends/working-connections/' + this.options.employeeId;
    },
    model: Profile.WorkingConnectionsTrendModel,
  });
  this.DoTheBudgetTrendModel = Wethod.Utility.BaseModel.extend({});
  this.DoTheBudgetTrendCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/profile-trends/do-the-budget/' + this.options.employeeId;
    },
    model: Profile.DoTheBudgetTrendModel,
  });

  this.KudosModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/profile/' + this.options.employeeId + '/review-charts?year=' + this.options.year;
    },
  });

  this.PermissionsModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/apppermissions?app=profile',
  });

  var API = {
    getProfile: function (id) {
      var model = new Profile.ProfileModel({ id: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    updatePerson: function (attributes) {
      var model = new Profile.PersonModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getGender: function () {
      var model = new Profile.GenderModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    addSkill: function (attributes) {
      var model = new Profile.SkillModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteSkill: function (attributes) {
      var model = new Profile.SkillModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    addInterest: function (attributes) {
      var model = new Profile.InterestModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteInterest: function (attributes) {
      var model = new Profile.InterestModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveLanguage: function (attributes) {
      var model = new Profile.LanguageModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    updateLanguage: function (attributes) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/language/' + attributes.id,
        data: JSON.stringify(attributes),
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getLanguagesList: function (attributes) {
      var collection = new Profile.LanguagesCollection(attributes);
      var defer = $.Deferred();

      collection.fetch({
        success: function (model) {
          defer.resolve(model.toJSON());
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteLanguage: function (attributes) {
      var model = new Profile.LanguageModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    savePortfolioWork: function (attributes) {
      var model = new Profile.PortfolioWorkModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deletePortfolioWork: function (attributes) {
      var model = new Profile.PortfolioWorkModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveSponsorship: function (attributes) {
      var model = new Profile.SponsorshipModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteSponsorship: function (attributes) {
      var model = new Profile.SponsorshipModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getObjectives: function (attributes) {
      var model = new Profile.ObjectivesByQuarterModel(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveObjective: function (attributes) {
      var model = new Profile.ObjectiveModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteObjective: function (attributes) {
      var model = new Profile.ObjectiveModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    updateQuarterStatus: function (attributes) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/okr/quarter-status',
        data: JSON.stringify(attributes),
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getKudos: function (attributes) {
      var model = new Profile.KudosModel(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model, response) {
          if (response.code !== 200) {
            defer.reject(response);
          } else {
            defer.resolve(model);
          }
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getPermissions: function () {
      var model = new Profile.PermissionsModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (model, response) {
          if (response.code !== 200) {
            defer.reject(response);
          } else {
            defer.resolve(model);
          }
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveKeyResult: function (attributes) {
      var model = new Profile.KeyResultModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteKeyResult: function (attributes) {
      var model = new Profile.KeyResultModel(attributes);
      var defer = $.Deferred();

      model.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getPeople: function (attributes) {
      var model = new Profile.PeopleCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveYearResolution: function (attributes) {
      var model = new Profile.YearResolutionModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    saveProfilePic: function (attributes) {
      var employeeId = attributes.employeeId;
      var formData = new FormData();
      formData.append('pic', attributes.pic);
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/profile/' + employeeId + '/pic',
        // Tell jQuery not to process data or worry about content-type
        // You *must* include these options!
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    deleteProfilePic: function (attributes) {
      var employeeId = attributes.employeeId;
      var defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/profile/' + employeeId + '/pic',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getFridaynessTrend: function (attributes) {
      var model = new Profile.FridaynessTrendCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getChargeabilityTrend: function (attributes) {
      var model = new Profile.ChargeabilityTrendModel(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getDoThePlanTrend: function (attributes) {
      var model = new Profile.DoThePlanTrendCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getKeepTheLightsOffTrend: function (attributes) {
      var model = new Profile.KeepTheLightsOffTrendCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getWorkingCorrectionsTrend: function (attributes) {
      var model = new Profile.WorkingConnectionsTrendCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getDoTheBudgetTrend: function (attributes) {
      var model = new Profile.DoTheBudgetTrendCollection(attributes);
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('profile:get', function (id) {
    return API.getProfile(id);
  });

  Wethod.reqres.setHandler('profile:person:update', function (attributes) {
    return API.updatePerson(attributes);
  });

  Wethod.reqres.setHandler('profile:get:gender', function () {
    return API.getGender();
  });

  Wethod.reqres.setHandler('profile:skill:add', function (attributes) {
    return API.addSkill(attributes);
  });

  Wethod.reqres.setHandler('profile:skill:delete', function (attributes) {
    return API.deleteSkill(attributes);
  });

  Wethod.reqres.setHandler('profile:interest:add', function (attributes) {
    return API.addInterest(attributes);
  });

  Wethod.reqres.setHandler('profile:interest:delete', function (attributes) {
    return API.deleteInterest(attributes);
  });

  Wethod.reqres.setHandler('profile:languages:get', function (attributes) {
    return API.getLanguagesList(attributes);
  });

  Wethod.reqres.setHandler('profile:language:save', function (attributes) {
    return API.saveLanguage(attributes);
  });

  Wethod.reqres.setHandler('profile:language:update', function (attributes) {
    return API.updateLanguage(attributes);
  });

  Wethod.reqres.setHandler('profile:language:delete', function (attributes) {
    return API.deleteLanguage(attributes);
  });

  Wethod.reqres.setHandler('profile:portfolio-work:save', function (attributes) {
    return API.savePortfolioWork(attributes);
  });

  Wethod.reqres.setHandler('profile:portfolio-work:delete', function (attributes) {
    return API.deletePortfolioWork(attributes);
  });

  Wethod.reqres.setHandler('profile:sponsorship:save', function (attributes) {
    return API.saveSponsorship(attributes);
  });

  Wethod.reqres.setHandler('profile:sponsorship:delete', function (attributes) {
    return API.deleteSponsorship(attributes);
  });

  Wethod.reqres.setHandler('profile:objectives:get', function (attributes) {
    return API.getObjectives(attributes);
  });

  Wethod.reqres.setHandler('profile:objective:save', function (attributes) {
    return API.saveObjective(attributes);
  });

  Wethod.reqres.setHandler('profile:objective:delete', function (attributes) {
    return API.deleteObjective(attributes);
  });

  Wethod.reqres.setHandler('profile:key-result:save', function (attributes) {
    return API.saveKeyResult(attributes);
  });

  Wethod.reqres.setHandler('profile:key-result:delete', function (attributes) {
    return API.deleteKeyResult(attributes);
  });

  Wethod.reqres.setHandler('profile:teammates:get', function (attributes) {
    return API.getPeople(attributes);
  });

  Wethod.reqres.setHandler('profile:year-resolution:save', function (attributes) {
    return API.saveYearResolution(attributes);
  });

  Wethod.reqres.setHandler('profile:pic:save', function (attributes) {
    return API.saveProfilePic(attributes);
  });

  Wethod.reqres.setHandler('profile:pic:delete', function (attributes) {
    return API.deleteProfilePic(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-fridayness:get', function (attributes) {
    return API.getFridaynessTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-chargeability:get', function (attributes) {
    return API.getChargeabilityTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-do-the-plan:get', function (attributes) {
    return API.getDoThePlanTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-keep-the-lights-off:get', function (attributes) {
    return API.getKeepTheLightsOffTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-working-connections:get', function (attributes) {
    return API.getWorkingCorrectionsTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:trend-do-the-budget:get', function (attributes) {
    return API.getDoTheBudgetTrend(attributes);
  });

  Wethod.reqres.setHandler('profile:objectives:quarter:status:save', function (attributes) {
    return API.updateQuarterStatus(attributes);
  });

  Wethod.reqres.setHandler('profile:kudos:get', function (attributes) {
    return API.getKudos(attributes);
  });

  Wethod.reqres.setHandler('profile:permissions:get', function () {
    return API.getPermissions();
  });
});
