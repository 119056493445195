const React = require('react');
const PropTypes = require('prop-types');
const Loader = require('./ModalLoader.react');

const ModalContent = ({ children, isLoading }) => {
  const getChildren = () => {
    if (isLoading) {
      return <Loader />;
    }
    return children;
  };

  return <div className="wethod-modal__content">{getChildren()}</div>;
};

ModalContent.defaultProps = {
  isLoading: false,
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

module.exports = ModalContent;
