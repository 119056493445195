const { connect } = require('react-redux');
const Component = require('../components/ProductionTrend.react');

const mapStateToProps = (state) => ({
  isLoading: state.isLoadingProduction || state.isLoadingRevenues,
  weeks: state.productions,
  fullPlannedWeeks: state.fullPlannedProductions,
  corePlannedWeeks: state.corePlannedProductions,
  avgRevenues: state.avgRevenues,
});

module.exports = connect(mapStateToProps, null)(Component);
