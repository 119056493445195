const React = require('react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({ a, b }) => {
  const labels = {
    much_lower: 'way less than',
    lower: 'less than',
    almost_equal: 'in line with',
    equal: 'equal to',
    greater: 'more than',
    much_greater: 'way more than',
  };

  return <Comparator a={a} labels={labels} b={b} />;
};
