const React = require('react');
const dragula = require('react-dragula');
const PropTypes = require('prop-types');

require('./style.scss');

class DraggableList extends React.Component {
  constructor(props) {
    super(props);

    this.dragulaOptions = {};

    this.drake = null;

    this.dragulaDecorator = this.dragulaDecorator.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  /**
   * el was dropped into target before a sibling element, and originally came from source
   * @param el
   * @param target
   */
  onDrop(el, target) {
    const itemElements = [...target.children];
    const orderedIds = itemElements.map((element) => Number(element.getAttribute('data-id')));
    this.props.onDrop(orderedIds);
  }

  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance) {
      this.drake = dragula([componentBackingInstance], this.dragulaOptions);

      this.drake.on('drop', this.onDrop);
    }
  }

  render() {
    return (
      <div className={`wethod-draggable-list ${this.props.className}`} ref={this.dragulaDecorator}>
        {this.props.children}
      </div>
    );
  }
}

DraggableList.defaultProps = {
  className: '',
};

DraggableList.propTypes = {
  /** Node to use as content * */
  children: PropTypes.node.isRequired,
  /**
   * Function to call when one item is moved.
   * @param sortedIds {[number]}
   */
  onDrop: PropTypes.func.isRequired,
  className: PropTypes.string,
};

module.exports = DraggableList;
