/* eslint-disable react/sort-comp */
const React = require('react');

module.exports = class Description extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: 'Add a description...',
    };
  }

  needsPlaceholder() {
    return this.props.value === '';
  }

  handleBlur() {
    if (this.needsPlaceholder()) this.setState({ placeholder: 'Add a description...' });
  }

  handleFocus() {
    this.setState({ placeholder: '' });
  }

  handleChange(e) {
    this.props.updateForm({ description: e.target.value });
  }

  render() {
    return (
      <div className="timeline-event-form-description">
        <div className="timeline-event-form-description__title">Description</div>
        <textarea
          placeholder={this.state.placeholder}
          value={this.props.content}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onFocus={this.handleFocus.bind(this)} />
      </div>
    );
  }
};
