const React = require('react');

const DeletePersonModalBody = ({ client }) => (
  <div>
    <p>
      You are about to delete
      {' '}
      <span className="bold">{client.name}</span>
      {' '}
      who may
      be the primary contact for a project in your pipeline.
    </p>
    <br />
    <p> Are you sure you want to continue?</p>
  </div>
);

module.exports = DeletePersonModalBody;
