'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Authorization = require('../../models/Authorization');
var PricelistStore = require('../../apps/core/modules/price-lists/store');
var PricelistReducer = require('../../apps/core/modules/price-lists/reducer');
var Pricelist = require('../../apps/core/modules/price-lists/index');

Wethod.module('PricelistApp', function (PricelistApp, Wethod, Backbone, Marionette, $) {
  PricelistApp.Controller = {
    show: function () {
      $.when(Authorization.getPermissions('pricelist')).done(function (permissions) {
        var layout = new PricelistApp.StructureLayoutView();
        layout.render();

        var reducerWrapper = new PricelistReducer({
          permissions: permissions,
        });
        var store = PricelistStore(reducerWrapper.reduxReducer);
        var element = React.createElement(Pricelist, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
  };
});
