const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const Escaper = require('../../../../../../../common/react/formatters/Escaper.react');

const ProjectInfoCell = ({ project }) => (
  <TableCell className="timesheet-weekly__cell-project">
    <div className="timesheet-weekly__multirow-info">
      <Escaper className="timesheet-weekly__multirow-info-row">{project.project.name}</Escaper>
      <span className="timesheet-weekly__multirow-info-row-secondary">{project.project.pm.pm_name} {project.project.pm.pm_surname}</span>
      <Escaper className="timesheet-weekly__multirow-info-row-secondary">{project.client.name}</Escaper>
    </div>
  </TableCell>
);

module.exports = ProjectInfoCell;
