'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.WidgetInvoiceModel = Wethod.Utility.BaseModel.extend();
  this.WidgetInvoiceCollection = Wethod.Utility.BaseCollection.extend({
    model: DashboardApp.WidgetInvoiceModel,
  });

  var API = {
    saveInvoiceWidget: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget/invoices/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });
      return defer.promise();
    },
  };

  // save widget
  Wethod.reqres.setHandler('save:user:invoice:widget', function (options) {
    return API.saveInvoiceWidget(options);
  });
});
