const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  buEnabled: state.buEnabled,
  buFilter: state.buFilter,
  typeFilter: state.typeFilter,
  size: state.size,
  keyword: state.keyword,
});

const mapDispatchToProps = (dispatch) => ({
  changeBusinessUnit: (size, keyword, type, bu) => dispatch(actions
    .filterProjects(size, keyword, type, bu)),
  showExportModal: () => dispatch(actions.showExportModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
