const { connect } = require('react-redux');
const Component = require('../../../../components/Footer/DefaultFooterContent/Actions/BudgetFooterActions.react');
const actions = require('../../../../actions');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
});

const mapDispatchToProps = (dispatch) => ({
  showModalSaveTemplate: () => dispatch(actions.showModalSaveTemplate()),
  showModalProjectStatusCorrection: () => dispatch(actions.showModalProjectStatusCorrection()),
  showModalConsumptionReport: () => dispatch(actions.showModalConsumptionReport()),
  showModalLog: () => dispatch(actions.showModalLog()),
  showModalNotes: () => dispatch(actions.showModalNotes()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
