const { connect } = require('react-redux');
const Component = require('../../components/Objectives/Quarter.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  employee: state.id,
  year: state.objectivesByQuarter.year,
  editable: state.editable,
  placeholder: 'No objectives for this quarter yet',
});

const mapDispatchToProps = (dispatch) => ({
  addObjective: (objective, quarterId, employeeId) => dispatch(actions
    .addObjective(objective, quarterId, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
