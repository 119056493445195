'use strict';

/* eslint-disable default-case */

Wethod.module('SettingsApp.Permission', function (Permission, Wethod, Backbone, Marionette, $, _) {
  this.AuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/getpermissionsjson',
  });

  this.AuthorizationAppModel = Wethod.Utility.BaseModel.extend({
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        switch (key) {
          case 'name':
            if (attrs[key].trim() === '') {
              error[key] = 'The Role Name must be NOT empty';
              errors.push(error);
            }
            break;
        }
      });

      return (errors.length > 0) ? errors : false;
    },
  });
  this.AuthorizationAppCollection = Wethod.Utility.BaseCollection.extend({
    model: Permission.AuthorizationAppModel,
  });

  var API = {
    getAuthorization: function () {
      var model = new Permission.AuthorizationModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (responseModel) {
          defer.resolve(responseModel);
        },
        error: function (esponse) {
          defer.resolve(esponse);
        },
      });
      return defer.promise();
    },
    saveAuthorization: function (data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/authorization/settings/setpermissionsjson',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    saveNewRole: function (model) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/role',
        xhrFields: {
          withCredentials: true,
        },
        data: { name: model.get('name') },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('permission:get:authorization', function () {
    return API.getAuthorization();
  });
  Wethod.reqres.setHandler('permission:save:authorization', function (data) {
    return API.saveAuthorization(data);
  });
  Wethod.reqres.setHandler('permission:save:new:role', function (model) {
    return API.saveNewRole(model);
  });
});
