const React = require('react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const Footer = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');

const MakeTaskIntercompanyModalContent = ({ isTaskEligible, closeModal, onConfirmClick }) => (
  <ModalContent>
    <ModalBody>
      <ModalBodyBlock>
        An intercompany task allows a company from your group to take ownership of the activity.
      </ModalBodyBlock>
      <ShowIf condition={!isTaskEligible}
        showElse={(<ModalBodyBlock>Are you sure you want to continue?</ModalBodyBlock>)}>
        <ModalBodyBlock>
          An intercompany task is expected to have just <span className="bold">name</span> and {' '}
          <span className="bold">external costs</span>, this
          means that you need to delete internal days and markup from this task in order to
          continue.
        </ModalBodyBlock>
      </ShowIf>
      <ModalBodyBlock>
        The cost of the task will be deducted from your company’s income statement and added
        to the relevant company as revenue.
      </ModalBodyBlock>
    </ModalBody>
    <ShowIf condition={isTaskEligible}>
      <Footer>
        <FooterAction onClick={closeModal}>Cancel</FooterAction>
        <FooterAction onClick={onConfirmClick} disabled={!isTaskEligible}>Yes</FooterAction>
      </Footer>
    </ShowIf>
  </ModalContent>
);

module.exports = MakeTaskIntercompanyModalContent;
