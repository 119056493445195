const React = require('react');
const PropTypes = require('prop-types');
const CheckboxGroup = require('../inputs/CheckboxGroup/CheckboxGroup.react');
const Button = require('../Button/RoundedButton.react');
const Menu = require('../Menu/Menu.react');

const MultipleSelectFilterMenu = ({
  open,
  onClose,
  anchorEl,
  title,
  values,
  name,
  acceptedValues,
  availableOptions,
  onChange,
  onApplyClick,
}) => (
  <Menu open={open} onClose={onClose} anchorEl={anchorEl} bottom>
    <div className="wethod-multiple-select-filter__menu">
      <h6 className="wethod-multiple-select-filter__menu-title">{title}</h6>
      <CheckboxGroup values={values}
        name={name}
        acceptedValues={acceptedValues}
        availableOptions={availableOptions}
        onChange={onChange} />
      <Button onClick={onApplyClick}
        className="wethod-multiple-select-filter__apply-button">
        Apply
      </Button>
    </div>
  </Menu>
);

const availableOptionShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

availableOptionShape.children = PropTypes.arrayOf(PropTypes.shape(availableOptionShape));

MultipleSelectFilterMenu.defaultProps = {
  title: '',
  open: false,
  onClose: null,
  values: [],
  onApplyClick: null,
  anchorEl: null,
};

MultipleSelectFilterMenu.propTypes = {
  /**
   * Name of the input.
   */
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  /**
   * The function to call when menu is closed.
   */
  onClose: PropTypes.func,
  /**
   * The DOM element used to set the position of the menu.
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * List of currently selected values.
   */
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  availableOptions: PropTypes.shape(availableOptionShape).isRequired,
  /**
   * List of the "real" values, that are availableOptions without values representing groups.
   * These are likely the leaf values.
   */
  acceptedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  /**
   * Function to call when user clicks "Apply" button.
   * @param {string[]} values list of selected values
   */
  onApplyClick: PropTypes.func,
  /**
   * Function to call when user check or uncheck an option.
   * @param {SyntheticEvent} e
   * @param {string[]} values list of selected values
   */
  onChange: PropTypes.func.isRequired,
};

module.exports = MultipleSelectFilterMenu;
