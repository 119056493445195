const { connect } = require('react-redux');
const Component = require('../components/LanguagesTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  selectedLanguage: state.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (languageName) => dispatch(actions.changeLanguage(languageName)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
