const request = require('./Request');

const Authorization = {
  getPermissions(appName) {
    return request({
      method: 'GET',
      url: `/authorization/settings/apppermissions?app=${appName}`,
    });
  },
};

module.exports = Authorization;
