const { connect } = require('react-redux');
const Component = require('../../components/modals/DeleteCompanyHolidayConfirmationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor.filter((key) => key.startsWith('delete-company-holiday-')),
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteCompanyHolidayConfirmationConfirm: (id) => {
    dispatch(actions.deleteCompanyHoliday(id));
  },
  onDeleteCompanyHolidayConfirmationCancel: (id) => {
    dispatch(actions.deleteCompanyHolidayFailure(id));
    dispatch(actions.closeModal());
  },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
