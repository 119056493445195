/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const Link = require('../../../../common/components/Link.react');

module.exports = class NewClients extends React.Component {
  getClient(client) {
    return (
      <span key={client.id}>
        <Link
          href={`#finance/client/company/${client.id}`}>
          {_.unescape(client.name)}
        </Link>
      </span>
    );
  }

  getClients() {
    const list = this.props.new_clients;
    const clients = list.map((client) => this.getClient(client));
    return <List list={list} terminator=".">{clients}</List>;
  }

  render() {
    if (this.props.new_clients.length) {
      return (
        <Paragraph>
          Our <span className="bold">new clients</span> are: {this.getClients()}
        </Paragraph>
      );
    }
    return null;
  }
};
