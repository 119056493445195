/* eslint-disable react/jsx-props-no-spreading */

const React = require('react');
const PropTypes = require('prop-types');

const acceptedTypes = ['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image', 'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url', 'week'];

const Input = ({
  type, value, id, className, placeholder, readOnly, required, disabled, rootRef,
  autoComplete, checked, max, min, maxLength, minLength, name, size, step, ...rest
}) => {
  const actualClassName = className ? `wethod-input-root ${className}` : 'wethod-input-root';

  return (
    <input type={type}
      value={value}
      id={id}
      className={actualClassName}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      checked={checked}
      max={max}
      min={min}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      size={size}
      step={step}
      ref={rootRef}
      {...rest} />
  );
};

Input.defaultProps = {
  type: 'text',
  value: undefined,
  name: undefined,
  id: null,
  className: '',
  placeholder: '',
  readOnly: undefined,
  required: undefined,
  disabled: undefined,
  autoComplete: 'off',
  checked: undefined,
  max: undefined,
  min: undefined,
  maxLength: undefined,
  minLength: undefined,
  size: undefined,
  step: undefined,
  rootRef: undefined,
};

Input.propTypes = {
  name: PropTypes.string,
  /** The element to display * */
  type: PropTypes.oneOf(acceptedTypes),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  checked: PropTypes.bool,
  /** The maximum value * */
  max: PropTypes.number,
  /** The minimum value * */
  min: PropTypes.number,
  /** The maximum number of characters allowed * */
  maxLength: PropTypes.number,
  /** The minimum number of characters required * */
  minLength: PropTypes.number,
  /** The width, in characters * */
  size: PropTypes.number,
  /** The interval between legal numbers * */
  step: PropTypes.number,
  /** Function to access to inptut DOM element
   * @param input {HTMLElement} DOM element ref
   * */
  rootRef: PropTypes.func,
};

module.exports = Input;
