/* eslint-disable react/no-array-index-key,react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');

class TabSwitcher extends React.Component {
  getItemClassName(key) {
    let name = 'wethod-tab-switcher__item';
    if (key === this.props.current) name += ' wethod-tab-switcher__item--selected';
    return name;
  }

  getItems() {
    return this.props.items.map((item, index) => (
      <button type="button"
        key={index}
        onClick={() => {
          this.props.handleClick(item.key);
        }}
        className={this.getItemClassName(item.key)}
        disabled={this.props.disabled}>
        {item.name}
      </button>
    ));
  }

  getClassName() {
    let name = 'wethod-tab-switcher';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getItems()}
      </div>
    );
  }
}

TabSwitcher.propTypes = {
  // Selected item
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // List of items to show in the switch
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  })),
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TabSwitcher.defaultProps = {
  current: null,
  items: [],
  className: null,
  disabled: false,
};

module.exports = TabSwitcher;
