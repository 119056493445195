/* eslint-disable no-underscore-dangle */

'use strict';

Wethod.module('SettingsApp.Permission', function (Permission, Wethod, Backbone, Marionette, $, _) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#permissionStructureTemplate',
    className: 'fluid',
    regions: {
      sectionHeader: '[data-region="sectionHeader"]',
      switch: '[data-region="switch"]',
      buttons: '[data-region="buttons"]',
      newRole: '[data-region="newRole"]',
      body: '[data-region="permissionBody"]',
      modal: '[data-region="appDialog"]',
    },
  });

  // PERMISSION AREA
  this.PermissionTemplateItemView = Marionette.ItemView.extend({
    className: 'wrapPermissionModule col_16',
    template: '#permissionBodyTemplate',
    ui: {
      role: '[data-region="rowRole"]',
      saveButton: '[data-action="savePermission"]',
      saveButtonLabel: '[data-region="actionText"]',
    },
    events: {
      'click @ui.saveButton': 'saveChangedPermission',
    },
    onShow: function () {
      Permission.displayAppRole(this);
    },
    saveChangedPermission: function (e) {
      e.preventDefault();
      if (!this.ui.saveButton.hasClass('disabled')) {
        Permission.saveChangedPermission(this, this.model.get('app'));
      }
    },
  });
  this.PermissionTemplateCollectionView = Marionette.CollectionView.extend({
    childView: Permission.PermissionTemplateItemView,
  });

  // LOADING MORE DATA
  this.LoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });

  // BUTTONS
  this.ButtonsTemplateItemView = Marionette.ItemView.extend({
    className: 'col_16 wrapPermissionButtons',
    template: '#permissionButtonsTemplate',
    ui: {
      addRole: '[data-action="addNewRole"]',
    },
    events: {
      'click @ui.addRole': 'addRole',
    },
    addRole: function () {
      Permission.addRole();
    },
  });

  // NEW ROLE
  this.NewRoleTemplateItemView = Marionette.ItemView.extend({
    className: 'col_16 left newRole relative',
    template: '#newRoleTemplate',
    ui: {
      input: '[data-region="roleName"]',
      newRoleButton: '[data-action="addNewRole"]',
      newRoleButtonLabel: '[data-region="addNewRoleLabel"]',
      deleteNewRoleButton: '[data-action="deleteNewRole"]',
      iconStatus: '[data-region="roleIconStatus"]',
      message: '[data-action="displayMessage"]',
    },
    events: {
      'click @ui.newRoleButton': 'addNewRole',
      'click @ui.deleteNewRoleButton': 'deleteNewRole',
      'click @ui.input': 'resetInput',
    },
    addNewRole: function (e) {
      e.preventDefault();
      var val = _.escape(this.ui.input.val().trim());

      Permission.insertRole(this, val);
    },
    deleteNewRole: function (e) {
      e.preventDefault();
      Permission.deleteInsertRole(this);
    },
    resetInput: function () {
      this.ui.iconStatus.removeClass('error').addClass('required');
      this.ui.message.text('');
    },
  });

  // CHECK PERMISSION TEMPLATE
  this.PermissionRolePermissionTemplateView = Marionette.ItemView.extend({
    tagName: 'td',
    _template: '#permissionRolePermissionTemplate',
    className: 'textCenter permissionHeader',
    _appObj: undefined,
    ui: {
      check: '[data-action="changePermission"]',
    },
    events: {
      'click @ui.check': 'changePermission',
    },
    initialize: function (options) {
      this.options = options;
      this._appObj = options.appObj;
      _.bindAll(this, 'template');
    },
    template: function (serializedModel) {
      var that = this;
      var template = $(that._template).html();
      var isBlock = false;
      var model = serializedModel;

      /*
      * To avoid privilege esclation issues, super user's settings permissions cannot be changed and
      * no one except super user can see permissions section.
      * */
      if (serializedModel.app === 'settings' && (serializedModel.role === 'ROLE_SUPER_USER' || serializedModel.name === 'permissions')) {
        isBlock = true;
      }
      model.isBlock = isBlock;
      return _.template(template)(model);
    },
    changePermission: function () {
      this._appObj.ui.saveButton.removeClass('disabled');

      Permission.changePermission(this);
    },
  });

  // ROLE ROW
  this.PermissionRoleListCompositeView = Marionette.CompositeView.extend({
    template: '#permissionRoleListTemplate',
    childViewContainer: '[data-region="permissionList"]',
    childView: Permission.PermissionRolePermissionTemplateView,
  });
});
