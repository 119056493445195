const React = require('react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const Infotip = require('../../../../../../../common/react/Infotip/Infotip.react');
const NumericField = require('../../../../../../../common/react/inputs/NumericField/NumericField.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const NameCell = require('./WorkHourCapacityNameCell.react');

const EditRow = ({
  weekDays,
  item,
  waitingForSave,
  waitingForDelete,
  onChange,
  onDelete,
  showDeleteModal,
}) => {
  function isSaving() {
    return waitingForSave.some((key) => key === `save-work-hour-capacity-${item.id}`);
  }

  function isDeleting() {
    return waitingForDelete.some((key) => key === `delete-work-hour-capacity-${item.id}`);
  }

  /**
   * Handle change of the work hour capacity.
   * @param name
   * @param value
   */
  function handleChange(name, value) {
    const updatedItem = { [name]: value };
    if (onChange && !isSaving()) {
      onChange(item.id, updatedItem);
    }
  }

  /**
   * Handle name change triggering the work hour capacity update.
   * @param e
   */
  function handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    handleChange(name, value);
  }

  /**
   * Handle hour change triggering the work hour capacity update.
   * @param e
   */
  function handleHourChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    handleChange(name, Number(value));
  }

  function handleDelete() {
    if (!waitingForDelete.length && item.used_by_employees) {
      showDeleteModal(item);
    } else if (!waitingForDelete.length) {
      onDelete(item.id);
    }
  }

  function getDays() {
    return weekDays.map((day) => (
      <NumericField
        key={day}
        className="company-settings-work-hour-capacity__table-cell-weekday company-settings-work-hour-capacity__table-cell-weekday--numeric-input"
        name={day}
        value={item[day]}
        positiveOnly
        onBlur={handleHourChange} />
    ));
  }

  function classNameRow() {
    let className = 'company-settings-work-hour-capacity__table-row company-settings--with-transparent-input';
    if (item.default) {
      className += ' company-settings-work-hour-capacity__table-row--default';
    }
    return className;
  }

  function feedback() {
    if (isSaving()) {
      return 'Saving';
    }
    if (isDeleting()) {
      return 'Deleting';
    }
    return '';
  }

  return (
    <TableRow feedback={feedback()}
      className={classNameRow()}>
      <NameCell name={item.name}
        handleNameChange={handleNameChange} />
      <TableCell className="company-settings-work-hour-capacity__table-cell-weekday">
        <div className="company-settings-work-hour-capacity__table-cell-weekdays">
          {getDays()}
        </div>
      </TableCell>
      <TableCell className="company-settings-work-hour-capacity__table-cell-action">
        <ShowIf
          condition={item.default}
          showElse={(
            <IconButton
              icon="delete"
              color="blue"
              size="medium"
              onClick={handleDelete}
              className="company-settings__icon-button" />
          )}>
          <Infotip label="Default" />
        </ShowIf>
      </TableCell>
    </TableRow>
  );
};

module.exports = EditRow;
