const React = require('react');
const PropTypes = require('prop-types');
const { CSSTransition } = require('react-transition-group');
const Manager = require('./AdvancedSearchManager.react');
const Body = require('./AdvancedSearchBody.react');

const AdvancedSearch = ({
  show, filters, onClose, onSave, children, canSave,
}) => (
  <CSSTransition in={show}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <Manager show={show}
      filters={filters}
      onClose={onClose}
      onSave={onSave}
      canSave={canSave}>
      <Body>
        {children}
      </Body>
    </Manager>
  </CSSTransition>
);

AdvancedSearch.defaultProps = {
  canSave: false,
  children: undefined,
};

AdvancedSearch.propTypes = {
  /**
   * Whether to show or hide the advanced search sidebar
   * @see AdvancedSearchManager
   */
  show: PropTypes.bool.isRequired,
  /**
   * Object representing the filters contained in the advanced search.
   * The object should have as keys the names of the filters,
   * and as values the values of the filters.
   * The values are injected in the children with the corresponding name
   * @see AdvancedSearchManager
   */
  filters: PropTypes.shape({}).isRequired,
  /**
   * @see Sidebar
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @see Sidebar
   */
  onSave: PropTypes.func.isRequired,
  /**
   * List of filters shown in the advanced search
   * @see AdvancedSearchManager
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    // Required check
    if (!prop) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`);
    }

    React.Children.forEach(prop, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.name === undefined || child.props.name === null) {
          // 'Name' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
        if (child.props.label === undefined || child.props.label === null) {
          // 'Label' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
      } else {
        // Type check
        error = new Error(`Invalid prop \`children\` of type \`${typeof child}\` supplied to \`${componentName}\`, children should be valid elements.`);
      }
    });
    return error;
  }, // isRequired
  /**
   * @see Sidebar
   */
  canSave: PropTypes.bool,
};

module.exports = AdvancedSearch;
