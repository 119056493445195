const React = require('react');
const BudgetArea = require('../containers/BudgetArea');
const { isAreaOn } = require('../../services/areaService');

module.exports = class BudgetAreas extends React.Component {
  constructor() {
    super();

    this.state = {
      showTooltip: false,
    };
  }

  handleMouseEnter() {
    this.setState({ showTooltip: true });
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  getAreas() {
    return this.props.areas.map((area, index) => (
      <BudgetArea key={area.id}
        showTooltip={this.shouldAreaShowTooltip(index)}
        projectId={this.props.projectId}
        canEdit={this.canEditArea(area)}
        isInWhitelist={this.props.isInWhitelist}
        {...area} />
    ));
  }

  /**
   * The tooltip is shown only above the first visible area.
   * It is visible when the user hover the budget areas.
   * @param index
   * @returns {false}
   */
  shouldAreaShowTooltip(index) {
    return index === 0 && this.state.showTooltip;
  }

  /**
   * Check whether the user can edit the project.
   * In order to edit it, the project should not be archived and
   * the user must be included in the whitelist, if enabled.
   * @returns {boolean}
   */
  canEditProject() {
    return this.props.canEdit;
  }

  /**
   * Check whether the user can edit a certain area.
   * In order to edit it, the project must be editable and the area must be active.
   * @param area
   * @returns {false|*}
   */
  canEditArea(area) {
    return this.canEditProject() && isAreaOn(area);
  }

  render() {
    return (
      <ul className="timesheet-project__budget-areas"
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}>
        {this.getAreas()}
      </ul>
    );
  }
};
