const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/Title.react');

const mapStateToProps = (state) => ({
  month: state.month,
  ebitda: state.month_ebitda,
  target: state.monthly_ebitda_target,
  prevMonthEbitda: state.prev_month_ebitda,
});

module.exports = connect(mapStateToProps)(Component);
