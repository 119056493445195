/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this,
 react/prefer-stateless-function */
const React = require('react');
const SidebarInput = require('./SidebarWrapperEditable.react');
const DateRangePicker = require('../DateRangeButtonPicker.react');

/**
 * An outlined text input component, material styled. On click it opens a calendar to choose a date
 * or interval
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name: string
 * value: object  // {start_date, end_date}
 *
 * onFocus
 * onChange
 * onBlur
 *
 * @type {module.SidebarDateRangeSelection}
 */
module.exports = class SidebarDateRangeSelection extends React.Component {
  render() {
    return (
      <SidebarInput placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}>
        <DateRangePicker name={this.props.name}
          value={this.props.value}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          deletable={!this.props.required} />
      </SidebarInput>
    );
  }
};
