const { connect } = require('react-redux');
const Component = require('../components/RequestReviewModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'last-review').length > 0,
  isSending: state.waitingFor.filter((key) => key === 'review-request').length > 0,
  lastReview: state.lastReview,
});

const mapDispatchToProps = (dispatch) => ({
  getLastReview: (projectId) => dispatch(actions.getLastReview(projectId)),
  requestReview: (projectId) => dispatch(actions.createReview(projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
