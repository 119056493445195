/* eslint-disable react/sort-comp,no-underscore-dangle,class-methods-use-this,react/no-access-state-in-setstate,array-callback-return */
const React = require('react');
const FiltersButton = require('./FiltersButton.react');
const FilterDropDown = require('./FilterDropDown.react');
const BusinessUnitService = require('../../../../../../../services/BusinessUnitService');

/**
 * FilterPeople manages the filter that can be applyed to the employee list
 *
 * Three group of filters:
 * - 'type' that can be 'internal', 'all' or 'external' and is a filter on the level type
 * - 'level' that filter on a level (like: {id: 1, name: "Junior"})
 * - 'tag' that filter on a tag of a category (like: {id: 1, name: "area", tags: [{id: 1, name:"design"}]})
 * - 'skill' that filter on person's skills
 *
 * Filter on the same group can be activated together and considered with an OR condition, filters on
 * different groups must be considered on a AND condition
 */
class FilterPeople extends React.Component {
  constructor(props) {
    super(props);

    const businessUnitService = new BusinessUnitService();
    const categories = this.sortCategories(this.props.filters.categories);
    const levels = this.sortLevels(this.props.filters.levels);

    this.state = {
      showDropDown: false,
      levels,
      categories,
      jobTitles: this.props.filters.job_titles,
    };

    this.businessUnits = businessUnitService.getForCompany();
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.selectType = this.selectType.bind(this);
    this.selectLevel = this.selectLevel.bind(this);
    this.removeLevel = this.removeLevel.bind(this);
    this.selectBu = this.selectBu.bind(this);
    this.removeBu = this.removeBu.bind(this);
    this.selectTag = this.selectTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.sortCategories = this.sortCategories.bind(this);
    this.sortLevels = this.sortLevels.bind(this);
    this.selectJobTitle = this.selectJobTitle.bind(this);
    this.removeJobTitle = this.removeJobTitle.bind(this);
  }

  componentDidMount() {
    // Manages 'close on focus out'
    $(document).click();
  }

  getActiveType() {
    return this.getFiltersByType('type')[0].target;
  }

  getSelectedLevels() {
    return this.getFiltersByType('level').map((filter) => filter.target);
  }

  getSelectedCategories() {
    return this.props.filters.categories.map((category) => {
      const tags = this.getFiltersByType('tag')
        .filter((tag) => tag.secondaryTarget.id === category.id)
        .map((tag) => tag.target);
      return {
        ...category,
        tags,
      };
    });
  }

  getSelectedBusinessUnits() {
    return this.getFiltersByType('bu').map((filter) => filter.target);
  }

  getSelectedJobTitles() {
    return this.getFiltersByType('job_title').map((filter) => filter.target);
  }

  /**
   * Return all applied filters with the given type.
   *
   * @param type
   * @return {*[]}
   */
  getFiltersByType(type) {
    return this.props.activeFilters.filter((filter) => filter.type === type);
  }

  /**
   * Sort tags for each category alphabetically
   * @param {array} categories
   */
  sortCategories(categories) {
    categories.forEach((category) => category.tags.sort(this._sortByname));
    categories.sort(this._sortByname);
    return categories;
  }

  /**
   * Sort levels alphabetically
   * @param {array} levels
   */
  sortLevels(levels) {
    return levels.sort(this._sortByname);
  }

  /**
   * Sort levels by name attributes
   * @param {*} a
   * @param {*} b
   */
  _sortByname(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  /**
   * Toggle Dropdown visibility
   */
  handleButtonClick() {
    this.setState({ showDropDown: !this.state.showDropDown });
  }

  /**
   * Select the active level type on the 'type switch'
   */
  selectType(type) {
    this.props.onFilterSelection('type', type);
  }

  /**
   * When a level filter is selected it must be removed from the dropdown and displayed on the 'active' filters list
   */
  selectLevel(targetLevel) {
    this.props.onFilterSelection('level', targetLevel);
  }

  /**
   * When a BU filter is selected it must be removed from the dropdown and displayed on the 'active' BU list
   */
  selectBu(targetBu) {
    this.props.onFilterSelection('bu', targetBu);
  }

  /**
   * When a BU filter is removed from the 'active' filter it must returns it the dropdown list
   */
  removeBu(targetBu) {
    this.props.onFilterDeselection('bu', targetBu);
  }

  /**
   * When a job title filter is selected it must be removed from the dropdown and displayed on the 'active' job title list
   */
  selectJobTitle(targetJobTitle) {
    this.props.onFilterSelection('job_title', targetJobTitle);
  }

  /**
   * When a job title filter is removed from the 'active' filter it must returns it the dropdown list
   */
  removeJobTitle(targetJobTitle) {
    this.props.onFilterDeselection('job_title', targetJobTitle);
  }

  /**
   * When a tag filter is selected it must be removed from the dropdown and displayed on the 'active' filters list
   * within the right category
   */
  selectTag(targetTag, targetCategory) {
    this.props.onFilterSelection('tag', targetTag, targetCategory);
  }

  /**
   * When a level filter is removed from the 'active' filter it must returns it the dropdown list
   */
  removeLevel(targetLevel) {
    this.props.onFilterDeselection('level', targetLevel);
  }

  /**
   * When a tag filter is removed from the 'active' filter it must returns it the dropdown list in the right category
   */
  removeTag(targetTag, targetCategory) {
    this.props.onFilterDeselection('tag', targetTag, targetCategory);
  }

  /**
   * Return whether there are active filters or not
   */
  hasActiveFilters() {
    let hasFilters = false;
    this.getSelectedCategories().map((category) => {
      hasFilters = hasFilters || Boolean(category.tags.length);
    });
    hasFilters = hasFilters || Boolean(this.getSelectedLevels().length);
    hasFilters = hasFilters || this.getActiveType() !== 'internal';
    hasFilters = hasFilters || this.getSelectedBusinessUnits().length;
    hasFilters = hasFilters || this.getSelectedJobTitles().length;
    return hasFilters;
  }

  /**
   * Remove all active filters
   */
  clearFilters() {
    this.props.onClearFilters();
  }

  getClassName() {
    return 'planning__filter-people';
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <FiltersButton
          label="filter people"
          mobileLabel="people"
          active={this.state.showDropDown}
          hasFilters={this.hasActiveFilters()}
          onClick={this.handleButtonClick}
          onClearFilters={this.clearFilters} />
        <FilterDropDown
          visible={this.state.showDropDown}
          activeType={this.getActiveType()}
          onTypeSelected={this.selectType}
          buEnabled={this.props.buEnabled}
          businessUnits={this.businessUnits}
          selectedBusinessUnits={this.getSelectedBusinessUnits()}
          onBuSelected={this.selectBu}
          onBuRemoved={this.removeBu}
          levels={this.state.levels}
          selectedLevels={this.getSelectedLevels()}
          onLevelSelected={this.selectLevel}
          onLevelRemoved={this.removeLevel}
          categories={this.state.categories}
          selectedCategories={this.getSelectedCategories()}
          onCategorySelected={this.selectTag}
          onCategoryRemoved={this.removeTag}
          selectedJobTitles={this.getSelectedJobTitles()}
          jobTitles={this.state.jobTitles}
          onJobTitleSelected={this.selectJobTitle}
          onJobTitleRemoved={this.removeJobTitle} />
      </div>
    );
  }
}

module.exports = FilterPeople;
