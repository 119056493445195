/* eslint-disable consistent-return,react/prop-types */
const React = require('react');

/**
 * An outlined wrapper for read only input component, material styled. Requires a child.
 *
 * PROPS
 * placeholder: string that will be shown as label
 * helperText: string shown at the bottom of the input to give info
 * icon: icon shown on the left side of the input
 * children: element that will be shown. It can be of any type (e.g. span, a)
 *
 * @type {module.SidebarWrapperReadOnly}
 */
module.exports = class SidebarWrapperReadOnly extends React.Component {
  getIconClassName() {
    if (this.props.icon) {
      return 'material-input__icon';
    }
  }

  getLabel() {
    if (this.props.placeholder && this.props.placeholder !== '') {
      return (
        <div className="material-input__label material-input__label--minimized">{this.props.placeholder}</div>
      );
    }
  }

  getHelperText() {
    if (this.props.helperText) {
      return (<div className="material-input__helper-text">{this.props.helperText}</div>);
    }
    return null;
  }

  render() {
    return (
      <div className="material-input">
        <div className={this.getIconClassName()}>
          {this.props.icon}
        </div>
        <div className="material-input__input material-input__input--readonly">
          {this.getLabel()}
          {this.props.children}
          {this.getHelperText()}
        </div>
      </div>
    );
  }
};
