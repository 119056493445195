/* eslint-disable react/sort-comp */
const React = require('react');
const FormatService = require('../../../../../../../../../services/FormatService');
const Loading = require('./RevenuesReportLoading.react');
const TimeFilterFactory = require('../../models/TimeFilter/TimeFilterFactory');
const QuarterFilter = require('../../models/TimeFilter/QuarterFilter');
const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

module.exports = class RevenuesReport extends React.Component {
  constructor(props) {
    super(props);
    this.fiscalYearService = new FiscalYearService();
    this.chartOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
      },
      title: {
        text: '',
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
          text: '',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            color: '#a5a5a5',
          },
        },
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,

          },
          grouping: false,
          shadow: false,
        },
        series: {
          stickyTracking: false,
        },
      },
      legend: {
        itemStyle: {
          fontWeight: 100,
          fontSize: 11,
        },
        align: 'left',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          return `<b>${FormatService.formatNumberAsUnit(this.y)}</b>`;
        },
      },
    };
  }

  getCategories() {
    return TimeFilterFactory(this.props.timeFilter)
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getRevenuesSeries() {
    const filter = TimeFilterFactory(this.props.timeFilter);
    const categories = filter.getBreakpoints();
    const data = categories.map((category) => this.props.revenues
      .filter((revenue) => {
        const month = moment(`${revenue.month}-01`);
        if (filter instanceof QuarterFilter) {
          const quarterMonth = category.getValue();
          const prevQuarterMonth = moment(quarterMonth).subtract(2, 'months').format('YYYY-MM-DD');
          return (month.isBefore(quarterMonth) || month.isSame(quarterMonth))
            && (month.isAfter(prevQuarterMonth) || month.isSame(prevQuarterMonth));
        }
        const startYear = this.fiscalYearService.getFiscalYearStartDate(category.getValue());
        const endYear = this.fiscalYearService.getFiscalYearEndDate(category.getValue());
        return (month.isAfter(startYear) || month.isSame(startYear))
          && (month.isBefore(endYear) || month.isSame(endYear));
      })
      .reduce((sum, project) => sum + project.value, 0));
    return {
      data,
      name: 'Revenues',
      pointPadding: 0.3,
      legendIndex: 1,
    };
  }

  getTargetsSeries() {
    const filter = TimeFilterFactory(this.props.timeFilter);
    const categories = filter.getBreakpoints();

    let data = [];
    if (filter instanceof QuarterFilter) {
      data = this.props.targets.reduce((sum, target) => [
        sum[0] + target.first_quarter,
        sum[1] + target.second_quarter,
        sum[2] + target.third_quarter,
        sum[3] + target.fourth_quarter,
      ], [0, 0, 0, 0]);
    } else {
      data = categories.map((category) => this.props.targets
        .filter((target) => target.year === category.getValue())
        .reduce((sum, target) => sum + target.first_quarter + target.second_quarter
          + target.third_quarter + target.fourth_quarter, 0));
    }

    return {
      name: 'Target',
      data: data.map((target) => target * 1000),
      color: 'rgba(245,166,64,0.5)',
      legendIndex: 2,
    };
  }

  getSeries() {
    return [this.getTargetsSeries(), this.getRevenuesSeries()];
  }

  getChartOptions() {
    return {
      ...this.chartOptions,
      series: this.getSeries(),
      xAxis: {
        ...this.chartOptions.xAxis,
        tickWidth: 0,
        categories: this.getCategories(),
      },
      plotOptions: {
        ...this.chartOptions.plotOptions,
        series: {
          ...this.chartOptions.plotOptions.series,
          color: 'rgba(79, 196, 247, 0.5)',

        },
      },
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  componentDidUpdate(prevProps) {
    const timeFilterHasChanged = this.props.timeFilter.period !== prevProps.timeFilter.period;
    if (!this.props.waiting || timeFilterHasChanged) {
      this.renderChart();
    }
  }

  getTotalRevenues() {
    const revenues = this.props.revenues.reduce((sum, revenue) => sum + revenue.value, 0);

    return FormatService.formatNumberAsUnit(revenues);
  }

  getTargetsButton() {
    if (this.props.canSeeEconomicsRevenues) {
      return (
        <a href={`/economics/#revenues-target?client_id=${this.props.clientId}`}
          className="client-content__add-button wethod-button">
          Target
        </a>
      );
    }
    return null;
  }

  getReport() {
    if (this.props.waiting) {
      return <Loading />;
    }
    return (
      <div>
        <div className="client-revenues-report__header">
          <div>
            <h3 className="client-revenues-report__header-title">Revenues</h3>
            <span className="client-revenues-report__total">{this.getTotalRevenues()}</span>
          </div>
          <div>{this.getTargetsButton()}</div>
        </div>
        <div className="client-revenues-report__chart"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
      </div>
    );
  }

  render() {
    return (
      <div className="client-revenues-report">
        {this.getReport()}
      </div>
    );
  }
};
