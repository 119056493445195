const { connect } = require('react-redux');
const Component = require('../../components/modals/ConfirmWeekendPlanModal.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  editPlan: (changes) => dispatch(actions.editPlan(changes)),
  confirmWeekendPlanning: () => dispatch(actions.confirmWeekendPlanning()),
});

module.exports = connect(null, mapDispatchToProps)(Component);
