/* eslint-disable react/sort-comp,consistent-return,class-methods-use-this */
const React = require('react');
const Menu = require('../../../../../../../../common/react/Menu/Menu.react');
const Search = require('../../../../../../../../common/react/SearchInput.react');
const CategoryElement = require('./CategoryElement.react');

module.exports = class TagSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      filteredCategories: this.props.categoriesWithTags,
      keyword: '',
    };
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of tags with the given keyword or with the given category
   * @param keyword
   */
  handleTagFilter(keyword) {
    const filtered = this.props.categoriesWithTags.reduce((filteredCategories, category) => {
      if (this.found(keyword, category.name)) {
        filteredCategories.push(category);
      } else {
        const filteredTags = category.tags.filter((tag) => this.found(keyword, tag.name));
        if (filteredTags.length > 0) {
          filteredCategories.push({
            ...category,
            tags: filteredTags,
          });
        }
      }
      return filteredCategories;
    }, []);
    this.setState({
      filteredCategories: filtered,
      keyword,
    });
  }

  handleTagClick(event, id) {
    const { target } = event;
    if (target.type === 'checkbox') {
      if (target.checked) {
        this.props.onCheck(id);
      } else {
        this.props.onUncheck(id);
      }
    }
  }

  onCloseMenu() {
    this.setState({
      showMenu: false,
      keyword: '',
    });
  }

  onMenuButtonClick() {
    this.setState({
      showMenu: true,
    });
  }

  getTagsResultList() {
    if (this.state.filteredCategories && this.state.filteredCategories.length > 0) {
      return this.state.filteredCategories.map((category) => (
        <CategoryElement key={category.id}
          onClick={this.handleTagClick.bind(this)}
          selectedTags={this.props.selectedTags}
          {...category} />
      ));
    }
    if (this.state.filteredCategories && this.state.filteredCategories.length === 0) {
      if (this.props.canEditSettings) {
        return (
          <span className="suppliers__tags-list--empty">
            Oops! Tag not found.
            <br />
            Add new tags from the
            {' '}
            <a href="#settings/company/suppliers">setting page</a>
            .
          </span>
        );
      }
      return <span className="suppliers__tags-list--empty">Oops! Tag not found.</span>;
    }
  }

  getTagsSearch() {
    return (
      <div className="suppliers__tags-select">
        <Search placeholder="Search tag"
          filter={this.handleTagFilter.bind(this)}
          keyword={this.state.keyword} />
        <div className="suppliers__tags-results">
          {this.getTagsResultList()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button type="button"
          ref={(el) => this.tagButton = el}
          className="material-input-button"
          onClick={this.onMenuButtonClick.bind(this)}>
          {this.props.value}
        </button>
        <Menu open={this.state.showMenu}
          className="suppliers__tags-menu"
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.tagButton}>
          {this.getTagsSearch()}
        </Menu>
      </div>
    );
  }
};
