const { connect } = require('react-redux');
const Component = require('../components/TravelRow.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isLoading: state.waitingFor.filter((key) => key === 'filter-travels' || key === 'load-travels' || 'get-travel-offices').length > 0,
  isSaving: state.waitingFor.filter((key) => key === 'save-travel').length > 0,
  travelOffices: state.travelOffices,
});

const mapDispatchToProps = (dispatch) => ({
  openSidebar: (travel) => dispatch(actions.showSidebar(travel)),
  sendTravelRequest: (travel) => dispatch(actions.updateTravel(travel)),
  showTravelOfficeModal: (data) => dispatch(actions.showTravelOfficeModal(data)),
  downloadFiles: (travel, category) => dispatch(actions.showCategoryFilesDownloadModal({
    travel,
    category,
  })),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
