/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Empty extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty">
        <span className="wethod-widget__empty-message">Nothing on the horizon yet.</span>
      </div>
    );
  }
};
