'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ProjectCanvasStore = require('../../apps/core/modules/project-canvas/store');
var ProjectCanvasReducer = require('../../apps/core/modules/project-canvas/reducer');
var ProjectCanvas = require('../../apps/core/modules/project-canvas/index');
var CanvasDriveIntegration = require('../../models/CanvasGoogleDriveIntegration');

Wethod.module('ProjectCanvasApp', function (ProjectCanvasApp, Wethod, Backbone, Marionette, $, _) {
  ProjectCanvasApp.Controller = {
    show: function (projectId, boardId) {
      var authorizationRequest = Wethod.request('get:pipeline:authorization');
      var projectRequest = Wethod.request('get:project:info', { projectId: projectId });
      var projectReviewConfigRequest = Wethod.request('get:company:pipeline:review:config');
      var externalDriveIntegrationConfigRequest = CanvasDriveIntegration
        .getProjectConfig(projectId);

      $.when(projectRequest, authorizationRequest, projectReviewConfigRequest,
        externalDriveIntegrationConfigRequest)
        .done(function (project, permissions, projectReviewConfig, externalDriveIntegrationConfig) {
          if (_.isUndefined(project)) { // no project with the given id
            Wethod.trigger('url:404:show');
          } else {
            var idEmployee = Wethod.userInfo.get('employee_id');
            var idPM = project.pm.id;
            var idAccount = project.account ? project.account.id : null;
            var projectBelongsToEmployee = Wethod
              .Authorization.isPmOrAccount(idEmployee, idPM, idAccount);
            var canView = (permissions.get('view') && projectBelongsToEmployee) || (permissions.get('view_other') && !projectBelongsToEmployee);
            var canEdit = (permissions.get('edit') && projectBelongsToEmployee) || (permissions.get('edit_other') && !projectBelongsToEmployee);
            var canReview = permissions.get('review');

            if (!canView) {
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            } else {
              var layout = new ProjectCanvasApp.StructureLayoutView();
              layout.render();

              var reducerWrapper = new ProjectCanvasReducer({
                info: project,
                canEdit: canEdit, // if you are here, you have at least 'view' permissions
                canReview: canReview,
                isReviewEnabled: projectReviewConfig.get('enabled'),
                focusedBoardId: parseInt(boardId) ? parseInt(boardId) : null,
                externalDriveConfig: externalDriveIntegrationConfig,
              });

              var store = ProjectCanvasStore(reducerWrapper.reduxReducer);
              var element = React.createElement(ProjectCanvas, { store: store });
              var container = document.getElementById('root');
              if (container !== null) {
                ReactDOM.render(element, container);
              }
            }
          }
        })
        .fail(function () {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        });
    },
  };
});
