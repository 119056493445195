const React = require('react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const Criterion = require('./CriterionRow.react');

module.exports = class CriteriaTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      size: 0,
    };
  }

  getLoadedCriteria() {
    return this.props.criteria.slice(0, this.state.page * this.state.size);
  }

  getCriteria() {
    const criteria = this.getLoadedCriteria();

    return criteria.map((criterion) => (
      <Criterion key={criterion.id}
        criterion={criterion}
        onClick={this.props.changeCriterion}
        selected={criterion.id === this.props.selectedCriterion} />
    ));
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.criteria.length > loaded;
  }

  loadMore(size, page) {
    this.setState({
      page,
      size,
    });
  }

  render() {
    return (
      <Table columns={1} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="criterion">Criterion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty="No criteria yet">
          {this.getCriteria()}
        </TableBody>
      </Table>
    );
  }
};
