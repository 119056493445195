const React = require('react');
const InputWrapper = require('../../../../../../../../common/react/material-input/SidebarWrapperEditable.react');
const TagSelect = require('../../containers/sidebar/TagSelect');
const Tag = require('../../../../../../../../common/react/Tag/Tag.react');

module.exports = class SidebarTags extends React.Component {
  onTagUnchecked(id) {
    const tagList = this.props.value ? this.props.value : [];
    this.props.onChange(this.props.name, tagList.filter((tag) => tag.id !== id));
  }

  onTagChecked(id) {
    const tagList = this.props.value ? this.props.value : [];
    const selectedTag = this.props.tags.filter((tag) => tag.id === id);
    this.props.onChange(this.props.name, tagList.concat(selectedTag));
  }

  getTagList() {
    if (this.props.value) {
      return this.props.value.map((tag) => (
        <Tag key={tag.id}
          id={tag.id}
          onDelete={this.onTagUnchecked.bind(this)}>
          {tag.name}
        </Tag>
      ));
    }

    return null;
  }

  render() {
    return (
      <div className="suppliers__tags">
        <InputWrapper placeholder={this.props.placeholder}
          icon={this.props.children}>
          <TagSelect
            name={this.props.name}
            selectedTags={this.props.value}
            categoriesWithTags={this.props.categoriesWithTags}
            onCheck={this.onTagChecked.bind(this)}
            onUncheck={this.onTagUnchecked.bind(this)} />
        </InputWrapper>
        <div className="suppliers__tags-list">
          {this.getTagList()}
        </div>
      </div>
    );
  }
};
