const React = require('react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Select = require('../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../../../../common/react/inputs/Select/SelectItem.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');

const MetadataField = ({ metadata, onChange, errors, updateErrors, readOnly }) => {
  const getOptions = () => metadata.availableValues.map((value) => (
    <SelectItem key={value} value={value}>{value}</SelectItem>
  ));

  function handleChange(e) {
    if (onChange) {
      const updatedMetadata = {
        selected_value: e.target.value === '' ? null : e.target.value,
        metadata_key: metadata.label,
      };
      onChange(e, updatedMetadata);
    }
  }

  const name = String(metadata.id);
  const error = errors[name];

  if (metadata.type === 'list') {
    return (
      <InputValidator updateErrors={updateErrors} constraints={metadata.constraints}>
        <Select readOnly={readOnly}
          value={metadata.value}
          id={`sidebar-${name}`}
          label={metadata.label}
          onChange={handleChange}
          name={name}
          errorText={error}>
          <SelectItem key="empty" value=""> </SelectItem>
          {getOptions()}
        </Select>
      </InputValidator>
    );
  }
  return (
    <InputValidator updateErrors={updateErrors} constraints={metadata.constraints}>
      <TextField readOnly={readOnly}
        value={metadata.value}
        id={`sidebar-${name}`}
        label={metadata.label}
        onChange={handleChange}
        name={name}
        errorText={error} />
    </InputValidator>
  );
};

module.exports = MetadataField;
