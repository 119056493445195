/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const HeaderDay = require('./HeaderDay.react');

class HeaderDays extends React.Component {
  render() {
    const days = this.props.days
      .map((day, index) => <HeaderDay day={day} key={index.toString()} />);
    return (
      <div className="header__days">
        {days}
      </div>
    );
  }
}

module.exports = HeaderDays;
