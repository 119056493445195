const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');
const BudgetChangelogModel = require('./BudgetChangelogModel');

module.exports = Backbone.Collection.extend({
  model: BudgetChangelogModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  offset: 0,
  limit: 50,
  search: null,
  orderBy: 'approved_on',
  sort: 'desc',
  requestPool: [],
  url() {
    let query = `?offset=${this.offset}&limit=${this.limit}`;
    query = this.search ? `${query}&search=${this.search}` : query;
    query = `${query}&orderBy=${this.orderBy}`;
    query = `${query}&sort=${this.sort}`;
    query = this.bu !== 'all' ? `${query}&bu=${this.bu}` : query;

    this.offset += this.limit;

    return `${APIURL}/budget-changelog/${query}`;
  },
  getBudgetChangelog(offset, size, keyword, orderBy, sort, bu) {
    if (keyword) {
      this.search = keyword.replace(/ /g, ','); // white space to comma
    }
    if (offset) {
      this.offset = offset;
    }
    if (size) {
      this.limit = size;
    }
    if (orderBy) {
      this.orderBy = orderBy;
    }
    if (sort) {
      this.sort = sort;
    }
    this.bu = bu;

    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
});
