const React = require('react');

const AddItemButton = ({ onClick }) => (
  <button type="button"
    className="wethod-button"
    onClick={onClick}>
    Add opportunity
  </button>
);

module.exports = AddItemButton;
