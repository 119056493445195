/* eslint-disable react/sort-comp,react/prop-types,class-methods-use-this */
const React = require('react');

module.exports = class Invoice extends React.Component {
  getUnescapedContent(content) {
    return _.unescape(content);
  }

  getClient() {
    const jobOrder = this.hasJobOrder() ? this.props.project.job_order : '';
    return `${this.props.client.acronym} ${jobOrder}`;
  }

  hasJobOrder() {
    return this.props.project.job_order !== null;
  }

  getPriorityStatusClassName() {
    const monthTogo = moment(this.props.date).diff(moment(), 'months');
    let name = 'widget-invoices__item-priority-status';
    if (monthTogo < 0) {
      name += ' widget-invoices__item-priority-status--alert';
    } else if (monthTogo === 0) {
      name += ' widget-invoices__item-priority-status--warning';
    }
    return name;
  }

  onCreateInvoiceClick() {
    const { currency } = this.props;

    const data = {
      value: this.props.delta,
      date: this.props.date,
      id_project: this.props.project.id,
      id_client: this.props.client.id,
      client: {
        id: this.props.client.id,
        corporate_name: this.props.client.name,
        acronym: this.props.client.acronym,
        vat_rate: this.props.client.vat_rate,
        payment_term: this.props.client.payment_term,
      },
      currency,
      exchange_rate: currency ? currency.rate.rate : null,
      project: {
        ...this.props.project,
        pm: {
          id: this.props.pm.id,
          pm_name: this.props.pm.name,
          pm_surname: this.props.pm.surname,
        },
        account: {
          id: this.props.account.id,
        },
      },
    };
    dispatcher.trigger('url:invoice:show', [data]);
  }

  canCreateInvoice() {
    const isPm = Wethod.userInfo.get('employee_id') === this.props.pm.id;
    const canCreateOwnInvoices = this.props.permissions.create && isPm;
    const canCreateOtherInvoices = this.props.permissions.create_other && !isPm;

    return this.hasJobOrder() && (canCreateOwnInvoices || canCreateOtherInvoices);
  }

  getCurrency() {
    const { currency } = this.props;
    if (currency) {
      return <div className="widget-invoices__item-currency">{currency.code}</div>;
    }
    return null;
  }

  getValue() {
    const { currency } = this.props;
    let value = this.props.delta;
    if (currency) {
      return value *= currency.rate;
    }
    return value;
  }

  getFormatted(value) {
    return `${numeral(value / 1000).format('0,0.00')} K`;
  }

  getCreateAction() {
    if (this.canCreateInvoice()) {
      return (
        <button type="button"
          onClick={this.onCreateInvoiceClick.bind(this)}
          className="wethod-button widget-invoices__item-action--create">
          Create
        </button>
      );
    }
    return null;
  }

  getError() {
    if (!this.hasJobOrder()) {
      return <div className="widget-invoices__item-error">Missing job order</div>;
    }
    return null;
  }

  render() {
    return (
      <li className="widget-invoices__item">
        <div className="widget-invoices__item-actions">
          <a href={`/#project/${this.props.project.id}/invoice-plan`}
            className="widget-invoices__item-action">
            <div className="wethod-icon-calendar wethod-icon-calendar--white" />
          </a>
          {this.getCreateAction()}
        </div>
        <div className="widget-invoices__item-overview">
          <div className="widget-invoices__item-priority">
            <span className={this.getPriorityStatusClassName()} />
          </div>
          <div className="widget-invoices__item-info">
            <div className="widget-invoices__item-client">{this.getClient()}</div>
            <div className="widget-invoices__item-project">{_.unescape(this.props.project.name)}</div>
            {this.getError()}
          </div>
          <div className="widget-invoices__item-value">
            {this.getCurrency()}
            <div className="widget-invoices__item-value-amount">{this.getFormatted(this.getValue())}</div>
          </div>
          <div className="widget-invoices__item-date">{moment(this.props.date).format('MMM')}</div>
        </div>
      </li>
    );
  }
};
