/* eslint-disable react/jsx-no-bind */
const React = require('react');
const TableCell = require('../../../../../../../../../common/react/Table2/TableCell.react');
const Menu = require('../../../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../../../../../common/react/Menu/ListMenu/MenuItem.react');

module.exports = class NumericSummaryCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  onClick() {
    this.setState({ showMenu: true });
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  changeSummary(func) {
    this.props.changeSummary(func, this.props.attribute.id);
  }

  render() {
    return (
      <TableCell
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell--summary project-canvas-spreadsheet__cell-button">
        <button type="button"
          ref={(el) => this.buttonEl = el}
          className="project-canvas-spreadsheet__cell-button-content"
          onClick={this.onClick.bind(this)}>
          {this.props.value}
        </button>
        <span className="project-canvas-spreadsheet__cell--summary-type">{this.props.function}</span>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.buttonEl}>
          <MenuItem onClick={() => this.changeSummary('sum')}>Sum</MenuItem>
          <MenuItem onClick={() => this.changeSummary('avg')}>Average</MenuItem>
          <MenuItem onClick={() => this.changeSummary('min')}>Min</MenuItem>
          <MenuItem onClick={() => this.changeSummary('max')}>Max</MenuItem>
        </Menu>
      </TableCell>
    );
  }
};
