const React = require('react');
const Bar = require('../../../../containers/Boards/Gantt/Bar');

module.exports = class Row extends React.Component {
  getBars() {
    return this.props.times.map((time) => (
      <Bar key={time.id}
        {...time}
        onDateResizeStart={this.props.onDateResizeStart}
        color={this.props.color} />
    ));
  }

  render() {
    return (
      <div className="project-canvas-gantt__item project-canvas-gantt__row">
        {this.getBars()}
      </div>
    );
  }
};
