const NodeTotal = require('./StructureNodeTotal');

module.exports = class AreaNodeTotal extends NodeTotal {
  /**
   * @param days
   * @param cost
   * @param price
   * @param externalCost
   * @param {LevelAmountNodeTotal[]} levels
   */
  constructor(days, cost, price, externalCost, levels) {
    super(days, cost, price);
    this.externalCost = externalCost;
    this.levels = levels;
  }

  getExternalCost() {
    return this.externalCost;
  }

  getLevels() {
    return this.levels;
  }

  toJson() {
    return {
      ...super.toJson(),
      external_cost: this.getExternalCost(),
      levels: this.getLevels().map((level) => level.toJson()),
    };
  }
};
