const React = require('react');
const PropTypes = require('prop-types');
const NumericValue = require('./NumericValue.react');

const PercentageValue = ({ value }) => (
  <span>
    <NumericValue value={value} />
    {' '}
    %
  </span>
);

PercentageValue.propTypes = {
  value: PropTypes.number.isRequired,
};

module.exports = PercentageValue;
