'use strict';

Wethod.module('PlanningApp', function (PlanningApp) {
  this.startWithParent = false;

  var API = {
    showPlanningPeople: function (query, idTour) {
      PlanningApp.Planning.Controller.showPlanningPeople(query, idTour);
    },
    showPlanningProjects: function () {
      PlanningApp.Planning.Controller.showPlanningProjects();
    },
    showProductionPlan: function (filter) {
      PlanningApp.Planning.Controller.showProductionPlan(filter);
    },
    showAllocationRequests: function (filter) {
      PlanningApp.Planning.Controller.showAllocationRequests(filter);
    },
  };

  PlanningApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'planning');
    if (options.foo === 'showProductionPlan') {
      API.showProductionPlan(options.filter);
    } else if (options.foo === 'showAllocationRequests') {
      API.showAllocationRequests(options.filter);
    } else {
      API[options.foo](options.query, options.idTour);
    }
  });
});
