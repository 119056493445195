/* eslint-disable prefer-destructuring */
const React = require('react');

module.exports = class Person extends React.Component {
  getName() {
    return `${this.props.person.name} ${this.props.person.surname}`;
  }

  getLevel() {
    const level = this.props.level.short_text.toUpperCase();
    let tagString = '';
    const tags = this.props.tags.map((tag) => tag.name);
    if (tags.length === 2) {
      tagString = `${tags[0]} & ${tags[1]}`;
    } else if (tags.length === 1) {
      tagString = tags[0];
    }
    return `${level} - ${tagString}`;
  }

  render() {
    return (
      <div className="timeline-person">
        <div className="timeline-person__pic" />
        <div className="timeline-person__info">
          <div className="timeline-person__name">{this.getName()}</div>
          <div className="timeline-person__level">{this.getLevel()}</div>
        </div>
      </div>
    );
  }
};
