const React = require('react');
const Kpi = require('../containers/Kpi/Kpi');
const WonProjects = require('../containers/WonProjects');
const OutstandingProjects = require('../containers/OutstandingProjects');
const LostProjects = require('../containers/LostProjects');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');

module.exports = class ProjectsOverview extends React.Component {
  static getWonProjectsTitle() {
    return (
      <span>
        Won projects {' '}
        <span
          className="client-content__main-description">
          (Probability higher than 75%)
        </span>
      </span>
    );
  }

  static getLostProjectsTitle() {
    return (
      <span>
        Lost projects {' '}
        <span
          className="client-content__main-description">
          (Probability equal to 0%)
        </span>
      </span>
    );
  }

  static getOutstandingProjectsTitle() {
    return (
      <span>
        Outstanding projects {' '}
        <span className="client-content__main-description">(Probability between 10% and 75%)</span>
      </span>
    );
  }

  componentDidMount() {
    this.props.getProjects();
  }

  render() {
    return (
      <div className="projectsOverview wethod-section-body">
        <SectionHeader current_section="Sales Performance" preview_anchor_id="sales-overview" helper_url="finance/index/#sales-overview" />
        <Kpi />
        <WonProjects
          title={ProjectsOverview.getWonProjectsTitle()}
          hasFeedback
          isValueAsUnit={this.props.isValueAsUnit} />
        <LostProjects
          title={ProjectsOverview.getLostProjectsTitle()}
          hasFeedback
          isValueAsUnit={this.props.isValueAsUnit} />
        <OutstandingProjects
          title={ProjectsOverview.getOutstandingProjectsTitle()}
          isValueAsUnit={this.props.isValueAsUnit} />
      </div>
    );
  }
};
