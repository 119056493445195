const React = require('react');
const EditableSection = require('./TravelOfficeEditableSection.react');
const ReadOnlySection = require('./TravelOfficeReadOnlySection.react');
const { getPermission } = require('../../../services/authManager');

module.exports = ({ travel = {}, mainTravel, editMode, ...restProps }) => {
  const status = mainTravel ? mainTravel.status : null;
  const employee = mainTravel ? mainTravel.employee_id : null;

  const canEditSent = () => getPermission('edit_sent', employee, status);

  if (canEditSent() && editMode) {
    return (
      <EditableSection value={travel.value}
        notes={travel.travel_office_notes}
        status={status}
        payment_method={travel.payment_method}
        {...restProps} />
    );
  }
  return (
    <ReadOnlySection value={travel.value}
      notes={travel.travel_office_notes}
      payment_method={travel.payment_method}
      canEdit={canEditSent()} />
  );
};
