const React = require('react');
const PropTypes = require('prop-types');

const Link = ({ href, children }) => <a href={href} target="_blank" rel="noreferrer">{children}</a>;

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

module.exports = Link;
