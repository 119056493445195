const MathService = {
  /**
   * Return a distribution of total on n terms with the given precision.
   * If total is not divisible for n, the remainder is put on the last term.
   * @param total
   * @param n
   * @param precision
   * @return {Array}
   */
  getDistribution(total, n, precision) {
    const term = total / n;
    const actualTerm = this.floor(term, precision);
    const remainingToTotal = this.round((total - actualTerm * n), precision);

    const distribution = [];
    for (let i = 0; i < n; i++) {
      distribution[i] = actualTerm;
    }
    distribution[n - 1] = this.round(distribution[n - 1] + remainingToTotal, precision);

    return distribution;
  },
  /**
   * Return value rounded with the given precision.
   * @param value
   * @param precision digits to keep after comma.
   */
  round(value, precision = 0) {
    const precisionFactor = Math.pow(10, precision);

    return Math.round(value * precisionFactor) / precisionFactor;
  },
  /**
   * Return value floored with the given precision.
   * @param value
   * @param precision
   * @return {number}
   */
  floor(value, precision = 0) {
    const precisionFactor = Math.pow(10, precision);

    return Math.floor(value * precisionFactor) / precisionFactor;
  },
};

module.exports = MathService;
