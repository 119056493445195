const React = require('react');
const PropTypes = require('prop-types');
const SelectItem = require('./SelectItem.react');

const SelectItemColorful = ({ value, color, className, onClick, children, ...rest }) => {
  const style = `${className} wethod-select-colorful`;

  const dotStyle = color ? { backgroundColor: color } : {};

  const coloredDot = <div className="material-input__dot" style={dotStyle} />;

  return (
    <SelectItem value={value} className={style} onClick={onClick} {...rest}>
      {children}
      {coloredDot}
    </SelectItem>
  );
};

SelectItemColorful.defaultProps = {
  color: null,
  onClick: null,
  className: '',
};

SelectItemColorful.propTypes = {
  /**
   * Item content.
   * @see SelectItem
   */
  children: PropTypes.node.isRequired,
  /**
   * Function called when item is clicked.
   * @see SelectItem
   */
  onClick: PropTypes.func,
  /**
   * A value which uniquely identifies this item.
   * @see SelectItem
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Color of the item dot
   */
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  rest: PropTypes.any,
  /**
   * Additional class given to the item
   */
  className: PropTypes.string,
};

module.exports = SelectItemColorful;
