const { connect } = require('react-redux');
const Component = require('../../components/job-order-template/DraggableArea.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  templates: state.jobOrderTemplates.templates,
  isAddDisabled: state.jobOrderTemplates.templates.some((jot) => !jot.id),
});

const mapDispatchToProps = (dispatch) => ({
  onAdd: () => dispatch(actions.addNewJobOrderTemplate()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
