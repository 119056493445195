const React = require('react');
const TableSearch = require('../../../../../../../common/react/Table2/TableSearch.react');

const ProjectSearch = ({ keyword, filter, ...rest }) => {
  const placeholder = 'Search by project, client, pm or project type';

  return (
    <div>
      <TableSearch
        placeholder={placeholder}
        keyword={keyword}
        filter={filter}
        {...rest} />
    </div>
  );
};

module.exports = ProjectSearch;
