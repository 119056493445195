const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const FormValidator = require('../../../../../../common/react/FormValidator/FormValidator.react');
const TimesheetTable = require('../containers/TimesheetsTable');
const FilterTransformer = require('./advanced-search/FiltersTransformer.react');
const AdvancedSearch = require('../containers/advanced-search/TimesheetsAdvancedSearch');
const Modal = require('../containers/modal/Modal');
const Actions = require('../containers/Actions');

const Timesheets = () => (
  <div className="wethod-section-body timesheets">
    <SectionHeader
      current_section="Timesheets"
      helper_url="/settings/index/#timesheet-report"
      big />
    <FilterTransformer>
      <Actions />
    </FilterTransformer>
    <TimesheetTable />
    <FormValidator>
      <FilterTransformer>
        <AdvancedSearch />
      </FilterTransformer>
    </FormValidator>
    <Modal />
  </div>
);

module.exports = Timesheets;
