/* eslint-disable consistent-return */
const React = require('react');
const formatters = require('../../../utilities/formatters');

module.exports = class BudgetLog extends React.Component {
  constructor(props) {
    super(props);

    this.changeable = ['value', 'margin'];
    this.messages = {
      value: (from, to) => `Budget's final net price changed from ${from} to ${to}`,
      margin: (from, to) => `Project's margin changed from ${from} % to ${to} %`,
    };
  }

  getWhatsChanged() {
    for (let i = 0; i < this.changeable.length; i++) {
      const prevValue = this.props[`from_${this.changeable[i]}`];
      const currentValue = this.props[`to_${this.changeable[i]}`];
      if (prevValue !== currentValue) return this.changeable[i];
    }
  }

  getCurrentValue(name) {
    return this.props[`to_${name}`];
  }

  getPreviousValue(name) {
    return this.props[`from_${name}`];
  }

  getMessage() {
    const changedName = this.getWhatsChanged();
    const from = this.getPreviousValue(changedName);
    const to = this.getCurrentValue(changedName);
    const formattedFrom = formatters.getFormattedMoneyValue(from);
    const formattedTo = formatters.getFormattedMoneyValue(to);
    return this.messages[changedName](formattedFrom, formattedTo);
  }

  getCreationDate() {
    const momentDate = moment(this.props.approved_on);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.approved_by;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  /**
   * Returns how much the attribute with name has changed.
   * @param name
   * @returns {number}
   */
  getChangeValue(name) {
    return this.props[`to_${name}`] - this.props[`from_${name}`];
  }

  getLogoClass() {
    const changes = this.getWhatsChanged();
    const delta = this.getChangeValue(changes);
    let name = 'wethod-icon-budget ';
    if (delta > 0) name += 'wethod-icon-budget--green';
    else if (delta < 0) name += 'wethod-icon-budget--red';
    else name += 'wethod-icon-budget--black';
    return name;
  }

  render() {
    if (this.getWhatsChanged()) {
      return (
        <div className="timeline-event-log">
          <div className="timeline-event-past__type-logo-container">
            <div className={this.getLogoClass()} />
          </div>
          <div className="timeline-event-log__type">
            <div className="timeline-event-log__type-name">{this.getMessage()}</div>
          </div>
          <div className="timeline-event-log__creation">
            {this.getCreation()}
          </div>
        </div>
      );
    }
    return null;
  }
};
