const React = require('react');

const OrderContact = ({ send, contact }) => {
  function sendOrder() {
    send(contact.id);
  }
  return (
    <div className="orders__send-order-modal--item" onClick={sendOrder}>
      {contact.alias}
      <div className="wethod-icon-arrow-right" />
    </div>
  );
};

module.exports = OrderContact;
