const React = require('react');

module.exports = class YearResolutionBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resolution: this.props.resolution,
    };
  }

  onChange(e) {
    this.setState({ resolution: e.target.value });
  }

  onSaveClick() {
    this.props.editYearResolution(this.state.resolution, this.props.year, this.props.employee);
  }

  getFeedback() {
    if (this.props.waiting) return <div className="profile-contact-info__feedback">Saving...</div>;
    return null;
  }

  render() {
    return (
      <div className="profile-year-resolution profile-year-resolution--editable">
        <textarea
          onChange={this.onChange.bind(this)}
          name="resolution"
          placeholder="Add some words about what you want to achieve this year"
          defaultValue={this.state.resolution} />
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onSaveClick.bind(this)} className="wethod-button">Save</button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
