const { connect } = require('react-redux');
const actions = require('../actions');
const Sidebar = require('../components/ClientSidebar.react');

const mapStateToProps = (state) => ({
  client: state.focusedItem,
  isSaving: state.isSavingClient,
  type: 'companies',
  canEdit: true,
  hasIntercompanyGroup: state.hasIntercompanyGroup,
  availableGroupCompanies: state.availableGroupCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  addClient: (client) => dispatch(actions.saveCompany(client)),
  updateClient: (client) => dispatch(actions.updateCompany(client)),
  onDelete: (client) => dispatch(actions.showDeleteCompanyModal(client)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
