const React = require('react');
const LevelType = require('./LevelType.react');

class TypeSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.handelClick = this.handelClick.bind(this);
  }

  handelClick(type) {
    this.props.onSelection(type);
  }

  render() {
    return (
      <div className="dropdown__switch">
        <div className="switch">
          <LevelType
            label="internal"
            className="switch__button switch__button--first"
            activeType={this.props.activeType}
            onClick={this.handelClick} />
          <LevelType
            label="all"
            className="switch__button"
            activeType={this.props.activeType}
            onClick={this.handelClick} />
          <LevelType
            label="external"
            className="switch__button switch__button--last"
            activeType={this.props.activeType}
            onClick={this.handelClick} />
        </div>
      </div>
    );
  }
}

module.exports = TypeSwitch;
