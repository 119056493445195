/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Header = require('../containers/Actions');
const Reviews = require('../containers/ReviewRequests');
const Modal = require('../containers/Modal');

module.exports = class ReviewRequestChangelog extends React.Component {
  render() {
    return (
      <div className="wethod-section-body review-changelog">
        <Header />
        <Reviews />
        <Modal />
      </div>
    );
  }
};
