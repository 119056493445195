const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../ShowIf/ShowIf.react');
require('./style.scss');

const InputDecorator = ({
  children, prefix, suffix, helperText, className, style, errorText, required, label, inputRef,
  disabled, readOnly, focusedByCollaborator,
}) => {
  const emptyHelperText = !helperText || !helperText.length;

  const getClassName = () => {
    let name = 'wethod-input-decorator';
    if (className) {
      name += ` ${className}`;
    }
    if (errorText.length || !emptyHelperText) {
      name += ' wethod-input--helper-text-visible';
    }
    if (errorText.length) {
      name += ' wethod-input--error';
    }
    if (required) {
      name += ' wethod-input--required';
    }
    if (disabled) {
      name += ' wethod-input--disabled';
    }
    if (readOnly) {
      name += ' wethod-input--readonly';
    }
    if (focusedByCollaborator) {
      name += ' wethod-input--collaborator-focused';
    }
    return name;
  };

  const text = errorText.length ? errorText : helperText;

  return (
    <div className={getClassName()} style={style}>
      <div className="wethod-input-decorator__child-wrapper" ref={inputRef}>
        <ShowIf condition={prefix !== null}>
          <div className="wethod-input-decorator__prefix">{prefix}</div>
        </ShowIf>
        <div className="wethod-input-decorator__child">
          <div className="wethod-input-decorator__label">{label}</div>
          <div className="wethod-input-decorator__input">{children}</div>
        </div>
        <ShowIf condition={suffix !== null}>
          <div className="wethod-input-decorator__suffix">{suffix}</div>
        </ShowIf>
      </div>
      <div className="wethod-input-decorator__helper-text">{text}</div>
    </div>
  );
};

InputDecorator.defaultProps = {
  prefix: null,
  suffix: null,
  helperText: '',
  className: '',
  style: undefined,
  errorText: '',
  required: false,
  disabled: undefined,
  readOnly: undefined,
  label: null,
  inputRef: undefined,
  focusedByCollaborator: false,
};

InputDecorator.propTypes = {
  /** Element to decorate * */
  children: PropTypes.node.isRequired,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  /** Error message to display below children. Takes precedence over helperText * */
  errorText: PropTypes.string,
  required: PropTypes.bool,
  /** Element to use as label * */
  label: PropTypes.node,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  focusedByCollaborator: PropTypes.bool,
};

module.exports = InputDecorator;
