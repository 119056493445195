/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const ReadOnlyField = require('../../../../../../common/react/material-input/SidebarReadOnlyField.react');
const BudgetTemplateStructure = require('../BudgetTemplateStructure.react');

module.exports = class BudgetTemplateReadOnlyDetails extends React.Component {
  render() {
    return (
      <div className="budget-template__sidebar-body">
        <ReadOnlyField placeholder="Template Name" value={this.props.template.name} />
        <ReadOnlyField placeholder="Description" value={this.props.template.description} />
        <ReadOnlyField
          placeholder="Structure"
          value={<BudgetTemplateStructure areas={this.props.template.areas} />} />
      </div>
    );
  }
};
