/* eslint-disable react/no-array-index-key,jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label */
const React = require('react');
const SearchSelect = require('../containers/DeleteCompanyFailureModalSearchSelect');

module.exports = class DeleteCompanyFailureModalBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getProjects(items) {
    return items.map((item, index) => (
      <li key={index}>
        <a href={`#pipeline/projects?id=${item.id}`}
          target="_blank"
          rel="noreferrer">
          {this.getUnescapedValue(item.name)}
        </a>
      </li>
    ));
  }

  getContacts(items) {
    return items.map((item, index) => (
      <li key={index}>
        <a href={`#finance/client/person/${item.id}`}
          target="_blank"
          rel="noreferrer">
          {item.name}
        </a>
      </li>
    ));
  }

  getTab() {
    switch (this.state.tab) {
      case 1:
        return (
          <ul className="wethod-modal-list">
            {this.getContacts(this.props.contacts)}
          </ul>
        );
      default:
        return (
          <ul className="wethod-modal-list">
            {this.getProjects(this.props.projects)}
          </ul>
        );
    }
  }

  selectTab(index) {
    if (index !== this.state.index) this.setState({ tab: index });
  }

  handleTabSelextorClick(e) {
    e.preventDefault();
    const index = parseInt($(e.target).data('index'));
    this.selectTab(index);
  }

  getTabClassName(index) {
    let name = 'wethod-modal-tab';
    if (index === this.state.tab) name += ' wethod-modal-tab--selected';
    return name;
  }

  render() {
    return (
      <div className="delete-client-company-modal__body">
        <p>
          Company
          {' '}
          <span className="bold">{this.props.client.corporate_name}</span>
          {' '}
          appears to be used by:
        </p>
        <div className="wethod-modal-tab-container">
          <a
            href="#"
            onClick={this.handleTabSelextorClick.bind(this)}
            data-index="0"
            className={this.getTabClassName(0)}>
            PROJECTS
          </a>
          <a
            href="#"
            onClick={this.handleTabSelextorClick.bind(this)}
            data-index="1"
            className={this.getTabClassName(1)}>
            CONTACTS
          </a>
        </div>
        {this.getTab()}
        <br />
        <p className="wethod-modal__spaced-paragraph">
          A client is required for a project, please pick a company to replace
          {' '}
          <span
            className="bold">
            {this.props.client.corporate_name}
          </span>
          .
        </p>
        <p className="wethod-modal__spaced-paragraph">
          Contacts of
          {' '}
          <span className="bold">{this.props.client.corporate_name}</span>
          {' '}
          will be moved accordingly.
        </p>
        <SearchSelect onChange={this.props.updateNextClient}
          placeholder="Choose new client"
          items={this.props.companies} />
      </div>
    );
  }
};
