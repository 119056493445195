const { connect } = require('react-redux');
const Component = require('../components/ProjectReview.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  id: state.id,
  loading: state.loading,
  notFoundError: state.error === 'not-found',
});

const mapDispatchToProps = (dispatch) => ({
  getReview: (id) => dispatch(actions.getReview(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
