const React = require('react');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('../../../../../common/react/FormValidator/FormValidator.react');
const Sidebar = require('../containers/sidebar/ExpenseSidebar');
const SaveManager = require('../containers/SaveManager');
const PermissionManager = require('../containers/PermissionManager');

const SidebarController = ({ showSidebar }) => (
  <CSSTransition in={showSidebar}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <FormValidator>
      <PermissionManager>
        <SaveManager>
          <Sidebar />
        </SaveManager>
      </PermissionManager>
    </FormValidator>
  </CSSTransition>
);

module.exports = SidebarController;
