/* eslint-disable react/prop-types */
const React = require('react');
const Cleave = require('cleave.js/react');
require('cleave.js/dist/addons/cleave-phone.us');

module.exports = class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.formatOptions = {
      phone: true,
      phoneRegionCode: 'US',
    };
  }

  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  handleInit(cleave) {
    cleave.setRawValue(this.props.value);
  }

  render() {
    return (
      <span className="wethod-phone-input wethod-input">
        <Cleave options={this.formatOptions}
          onInit={this.handleInit.bind(this)}
          onChange={this.handleChange.bind(this)}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur} />
      </span>
    );
  }
};
