const React = require('react');
const FileManagerInternalContent = require('../../../../../../common/react/widgets/Files/components/FileManagerInternalContent.react');
const File = require('../../../../../../common/react/widgets/Files/models/File');
const EmptyListMessage = require('../../../../../../common/react/widgets/Files/components/EmptyList.react');
const FileCollection = require('../../models/FilesCollection');
const FileModel = require('../../models/FileModel');
const CannotDownloadModal = require('./modals/CannotDownloadModal.react');
const CannotUploadModal = require('./modals/CannotUploadModal.react');

module.exports = class FileManagerS3Content extends React.Component {
  static getFormattedFiles(files) {
    return files.map((file) => new File(file.id, file.name, file.mime_type, null,
      file.size, file.url, file.uploaded_by, file.uploaded_at));
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  constructor(props) {
    super(props);

    this.state = {
      searchKeyword: '',
      isLoadingFiles: false,
      hasMore: true,
      isUploading: false,
      deletingFiles: [],
      files: [],
      uploadErrors: [],
      showModal: null,
    };

    this.loadMore = this.loadMore.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onDownloadFile = this.onDownloadFile.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onSearch(keyword) {
    this.setState({ searchKeyword: keyword });
  }

  onUpload(file) {
    this.setState({ isUploading: true }, () => {
      const model = new FileModel();
      $.when(model.upload(this.props.projectId, file))
        .done((uploadedFile) => {
          this.setState({
            files: this.addFiles([uploadedFile]),
            isUploading: false,
          });
        })
        .fail(() => {
          this.setState({
            showModal: 'cannot-upload',
            uploadErrors: ['network'],
            isUploading: false,
          });
        });
    });
  }

  onDownloadFile(file, name) {
    const { id } = file;
    const model = new FileModel();
    $.when(model.download(id, name))
      .fail((response) => {
        if (response.code === 404) {
          this.setState({ showModal: 'not-found' });
        } else {
          this.setState({ showModal: 'server-error' });
        }
      });
  }

  onDeleteFile(file) {
    const { id } = file;
    this.setState({ deletingFiles: this.addToDeletingFiles(id) }, () => {
      const model = new FileCollection(null, { projectId: this.props.projectId });
      $.when(model.deleteModel(id))
        .done(() => {
          this.setState({
            files: this.removeFile(id),
            deletingFiles: this.removeFromDeletingFiles(id),
          });
        });
    });
  }

  onUploadError(errors) {
    this.setState({
      showModal: 'cannot-upload',
      uploadErrors: errors,
    });
  }

  getFilteredFiles() {
    return this.state.files
      .filter((file) => FileManagerS3Content.found(this.state.searchKeyword, file.name));
  }

  getModal() {
    switch (this.state.showModal) {
      case 'not-found':
        return <CannotDownloadModal close={this.closeModal} type="not-found" />;
      case 'server-error':
        return <CannotDownloadModal close={this.closeModal} type="server-error" />;
      case 'cannot-upload':
        return <CannotUploadModal close={this.closeModal} errors={this.state.uploadErrors} />;
      default:
        return null;
    }
  }

  loadMore() {
    this.setState({ isLoadingFiles: true }, () => {
      const model = new FileCollection(null, { projectId: this.props.projectId });
      $.when(model.getFiles())
        .done((files) => {
          this.setState({
            files: FileManagerS3Content.getFormattedFiles(files),
            hasMore: false,
            isLoadingFiles: false,
          });
        });
    });
  }

  addFiles(files) {
    const formattedFiles = FileManagerS3Content.getFormattedFiles(files);
    return this.state.files.concat(formattedFiles);
  }

  addToDeletingFiles(id) {
    return this.state.deletingFiles.concat(id);
  }

  removeFromDeletingFiles(id) {
    return this.state.deletingFiles.filter((file) => file.id !== id);
  }

  removeFile(id) {
    return this.state.files.filter((file) => file.id !== id);
  }

  closeModal() {
    this.setState({ showModal: null });
  }

  render() {
    return (
      <FileManagerInternalContent loadMore={this.loadMore}
        onUploadError={this.onUploadError}
        onUpload={this.onUpload}
        onSearch={this.onSearch}
        emptyFilesMessage={(
          <EmptyListMessage
            title="Deliverables & docs storage"
            description="Share documents and key deliverables" />
        )}
        hasMore={this.state.hasMore}
        isUploading={this.state.isUploading}
        deletingFiles={this.state.deletingFiles}
        searchKeyword={this.state.searchKeyword}
        canEdit={this.props.canEdit}
        files={this.getFilteredFiles()}
        setHeaderAction={this.props.setHeaderAction}
        isLoadingFiles={this.state.isLoadingFiles}
        onDownloadFile={this.onDownloadFile}
        isExternalDriveEnabled={this.props.externalDriveConfig.enabled}
        modal={this.getModal()}
        onDeleteFile={this.onDeleteFile} />
    );
  }
};
