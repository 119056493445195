const React = require('react');
const PropTypes = require('prop-types');

const SectionError = ({ message, description }) => (
  <div className="full404">
    <div className="centerBox404 col_10 col_offset_sx_3 col_xs_14 col_xs_offset_sx_1 textCenter">
      <div className="text404Highlighted">{message}</div>
      <div>
        {description}
      </div>
    </div>
    <a href="/" className="fix404Link">
      Home
    </a>
  </div>
);

SectionError.defaultProps = {
  description: '',
};

SectionError.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
};

module.exports = SectionError;
