const React = require('react');
const Interests = require('../../../../../../../../common/react/Tag/TagsInput/TagsInput.react');

module.exports = class InterestsBody extends React.Component {
  onAdd(name) {
    this.props.addInterest({ name }, this.props.employee);
  }

  onDelete(interest) {
    this.props.deleteInterest({ id: interest.id, name: interest.name }, this.props.employee);
  }

  onDoneClick() {
    this.props.hideModal();
  }

  render() {
    return (
      <div className="profile__interests profile__interests--editable">
        <Interests editable
          tags={this.props.tags}
          knownTags={this.props.knownTags}
          handleAdd={this.onAdd.bind(this)}
          placeholder="Add interest"
          handleDelete={this.onDelete.bind(this)} />
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onDoneClick.bind(this)} className="wethod-button">Done</button>
        </div>
      </div>
    );
  }
};
