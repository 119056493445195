const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Area/AreaFactory.react');
const actions = require('../../../actions');
const FeatureService = require('../../../../../../../services/FeatureService');

const mapStateToProps = (state) => ({
  scrollingComponentAmount: state.scrollingComponentAmount,
  levelsExtended: state.info.levels,
  collapsedAreas: state.collapsedAreas,
  budgetPriceListLevels: state.info.budget_price_list_levels,
});
const mapDispatchToProps = (dispatch) => ({
  showDeleteTaskModal: (taskId) => dispatch(actions.showDeleteTaskModal(taskId)),
  showSortTaskModal: (areaId, areaOrder, tasks) => dispatch(actions
    .showSortTaskModal(areaId, areaOrder, tasks)),
  showMakeTaskIntercompanyModal: (task, days, markup, jobTitles) => dispatch(actions
    .showMakeTaskIntercompanyModal(task, days, markup, jobTitles)),
  showInviteIntercompanySupplierModal: (areaId, taskId, supplierCompany) => dispatch(actions
    .showInviteIntercompanySupplierModal(areaId, taskId, supplierCompany)),
  showIntercompanyDetailsModal: (taskId, intercompany) => dispatch(actions
    .showIntercompanyTaskDetailsModal(taskId, intercompany)),
  setScrollingComponentScrollAmount: ((amount) => dispatch(actions
    .setScrollingComponentScrollAmount(amount))),
  setContingencyAreaReferenceForHorizontalScroll: (ref) => dispatch(actions
    .setContingencyAreaReferenceForHorizontalScroll(ref)),
  setScrollingComponentReferenceForHorizontalScroll: ((ref) => dispatch(actions
    .setScrollingComponentReferenceForHorizontalScroll(ref))),
  collapseArea: (areaId) => dispatch(actions
    .collapseArea(areaId)),
  expandArea: (areaId) => dispatch(actions
    .expandArea(areaId)),
  isHierarchyBudgetFlagEnabled: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
