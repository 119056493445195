const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const CompanyHoliday = {
  list(filters = {}) {
    let url = '/holiday';
    const queryString = HTTPService.buildQueryString(filters);
    url += queryString ? `?${queryString}` : '';

    return request({
      method: 'get',
      url,
    });
  },
  get(id) {
    return request({
      method: 'get',
      url: `/holiday/${id}`,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/holiday',
      data,
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/holiday/${id}`,
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/holiday/${id}`,
    });
  },
};

module.exports = CompanyHoliday;
