/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate */

const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');
const PersonDetails = require('../PlanDetails/Person.react');
const Date = require('../PlanDetails/Date.react');
const AllocationList = require('../PlanDetails/AllocationList.react');
const ModeSelect = require('./ModeSelect.react');
const GranularitySelect = require('./GranularitySelect.react');
const PatternSelect = require('../../../containers/modals/RepeatModal/PatternSelect');
const DateEndSelect = require('./DateEndSelect.react');
const AllForm = require('./AllForm.react');
const Project = require('./Project.react');
const canPerform = require('../../../services/canPerform');

module.exports = class RepeatModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'add', // add | remove
      granularity: 'all', // all | single
    };

    this.state.plannings = this.getInitialPlannings();

    this.title = this.props.zoom === 'week' ? 'Weekly allocation' : 'Daily allocation';

    this.editableProjects = this.props.data.projects.filter((project) => canPerform('modify', this.props.projectsInfo[project.id]));

    this.firstRepeatableDay = moment(this.props.data.to).add(1, 'day').format('YYYY-MM-DD');
  }

  /**
   * Returns the default plannings end date.
   * @returns {string}
   */
  getInitialEndDate() {
    let startDate = moment(this.props.data.to);
    const today = moment();

    if (startDate.isBefore(today)) {
      startDate = today;
    }

    return startDate.clone().add(1, 'week').format('YYYY-MM-DD');
  }

  /**
   * Default plannings for each project.
   * @returns {*}
   */
  getInitialPlannings() {
    return this.props.data.projects.map((project) => ({
      project: project.id,
      amount: project.amount,
      pattern: 'weekly', // daily|weekly|monthly
      end_date: this.getInitialEndDate(),
    }));
  }

  /**
   * Returns plannings to repeat.
   * Each planning represents a project in a particular date with a given amount to repeat following the given
   * pattern until the end_date.
   * Projects with pattern "no-repeat" or for which user has no permissions are not added to plannings.
   * @returns {[]}
   */
  getPlanningsToRepeat() {
    const plannings = [];
    const { days } = this.props.data;
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      const { plans } = day;
      for (let j = 0; j < plans.length; j++) {
        const dayPlan = plans[j];
        const repeatSettings = this.state.plannings
          .filter((setting) => setting.project === dayPlan.project_id)[0];
        const editable = this.editableProjects
          .filter((editableProject) => editableProject.id === dayPlan.project_id).length > 0;

        if (editable && repeatSettings.pattern !== 'no-repeat') {
          const planning = {
            date: day.date,
            amount: dayPlan.amount,
            project: dayPlan.project_id,
            pattern: repeatSettings.pattern,
            end_date: repeatSettings.end_date,
          };

          plannings.push(planning);
        }
      }
    }
    return plannings;
  }

  onPlanClick() {
    const plannings = this.getPlanningsToRepeat();
    if (plannings.length) {
      if (this.state.mode === 'add') {
        this.props.recurringAdd(this.props.data.person.id, plannings);
      } else {
        this.props.recurringDelete(this.props.data.person.id, plannings);
      }
    }
  }

  getPlanButtonClassName() {
    return this.getPlanningsToRepeat().length ? 'wethod-button' : 'wethod-button disabled';
  }

  getFeedBack() {
    if (this.props.isSaving) {
      return <div className="planning-conversion-modal__feedback">Planning...</div>;
    }
    return null;
  }

  getProjects() {
    return this.props.data.projects.map((project) => {
      const info = this.props.projectsInfo[project.id];
      const plan = this.state.plannings.filter((planning) => planning.project === project.id)[0];
      const canUserEdit = this.editableProjects
        .filter((editableProject) => project.id === editableProject.id).length > 0;

      return (
        <Project key={project.id}
          granularity={this.state.granularity}
          disableWeekend={!this.props.international}
          project={{ ...project, ...info }}
          pattern={plan.pattern}
          date={plan.end_date}
          onChangeDate={this.onChangeDateEnd.bind(this)}
          onChangePattern={this.onChangePattern.bind(this)}
          disableRepeatBefore={this.firstRepeatableDay}
          canUserEdit={canUserEdit} />
      );
    });
  }

  onModeChange(e) {
    this.setState({ mode: e.target.value });
  }

  onGranularityChange(value) {
    this.setState({
      granularity: value,
      plannings: this.getInitialPlannings(),
    });
  }

  onChangePattern(projectId, value) {
    this.setState({
      plannings: this.state.plannings.map((plan) => {
        if (plan.project === projectId) {
          return {
            ...plan,
            pattern: value,
          };
        }
        return plan;
      }),
    });
  }

  onChangeDateEnd(projectId, value) {
    this.setState({
      plannings: this.state.plannings.map((plan) => {
        if (plan.project === projectId) {
          return {
            ...plan,
            end_date: moment(value).format('YYYY-MM-DD'),
          };
        }
        return plan;
      }),
    });
  }

  onChangeAllPatterns(value) {
    this.setState({
      plannings: this.state.plannings.map((plan) => ({
        ...plan,
        pattern: value,
      })),
    });
  }

  onChangeAllDateEnd(name, value) {
    this.setState({
      plannings: this.state.plannings.map((plan) => ({
        ...plan,
        end_date: moment(value).format('YYYY-MM-DD'),
      })),
    });
  }

  getClassName() {
    return this.props.isSaving ? 'project-canvas-alert planning-people-repeat planning-people-repeat--disabled' : 'project-canvas-alert planning-people-repeat';
  }

  render() {
    return (
      <Modal title={this.title}
        onCancelClick={this.props.onCancelClick}
        className={this.getClassName()}>
        <div className="planning-people__allocation-details">
          <PersonDetails person={this.props.data.person} />
          <Date zoom={this.props.zoom} from={this.props.data.from} to={this.props.data.to} />
          <ModeSelect mode={this.state.mode} onChange={this.onModeChange.bind(this)} />
          <GranularitySelect value={this.state.granularity}
            onChange={this.onGranularityChange.bind(this)} />
          <AllForm granularity={this.state.granularity}>
            <PatternSelect onChange={this.onChangeAllPatterns.bind(this)}
              value={this.state.plannings[0].pattern} />
            <DateEndSelect onChange={this.onChangeAllDateEnd.bind(this)}
              value={this.state.plannings[0].end_date}
              name="all"
              disabled={this.state.plannings[0].pattern === 'no-repeat'}
              disableWeekend={!this.props.international}
              disableBefore={this.firstRepeatableDay} />
          </AllForm>
          <AllocationList>
            {this.getProjects()}
          </AllocationList>
        </div>
        <div className="planning-people__allocation-details-actions profile-contact-info__actions">
          <button type="button"
            onClick={this.props.onCancelClick}
            className="wethod-button wethod-button--no-border">
            Cancel
          </button>
          <button type="button"
            onClick={this.onPlanClick.bind(this)}
            className={this.getPlanButtonClassName()}>
            Apply
          </button>
          {this.getFeedBack()}
        </div>
      </Modal>
    );
  }
};
