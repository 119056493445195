/* eslint-disable react/no-did-update-set-state,react/sort-comp,react/jsx-no-bind,
react/require-default-props,react/forbid-prop-types,class-methods-use-this */
const React = require('react');
const PropTypes = require('prop-types');
const Cleave = require('cleave.js/react');

/**
 * A date input with no calendar associated.
 *
 * VALIDATION
 * Check validity of value based on 'required' props
 *
 * LIFECYCLE HOOKS
 * onChange, onValidate, onBlur, onFocus.
 *
 * OTHER PROPS
 * value, name, placeholder, formatOptions, disabled, required
 *
 * EDIT VALUE FROM PARENT
 * Just pass a new "value" to reset the input. This let you use this component by embedding it
 * into another.
 *
 * OVERRIDE OR EDIT FORMAT OPTIONS
 * Just pass a formatOptions props (see cleave.js) that will be merged with the default one.
 *
 * @deprecated
 */
class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.defaultFormatOptions = {
      date: true,
      datePattern: ['d', 'm', 'Y'],
    };

    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.cleave.setRawValue(this.props.value);
      this.setState({ value: this.props.value });
    }
  }

  isEmpty(value) {
    return !value || value.trim() === '';
  }

  checkRequired(value) {
    if (this.props.required) return !this.isEmpty(value);
    return true;
  }

  // Check validation of value
  validate(value) {
    this.errors = this.getErrors(value);
    if (this.props.onValidate) this.props.onValidate(this.props.name, this.errors);
    return this.errors;
  }

  getErrors(value) {
    const errors = [];
    if (!this.checkRequired(value)) errors.push('required');
    return errors;
  }

  handleFocus() {
    if (this.props.onFocus) this.props.onFocus();
  }

  handleBlur() {
    if (this.props.onBlur) this.props.onBlur(this.state.value);
  }

  handleChange(e) {
    if (!this.props.disabled) {
      let { value } = e.target;
      value = this.isEmpty(value) ? null : value;
      if (this.state.value !== value) {
        this.setState({ value });
        if (this.props.onChange) this.props.onChange(this.props.name, value);
      }
    }
  }

  handleInit(cleave) {
    const value = this.state.value ? this.state.value : '';
    this.cleave = cleave;
    cleave.setRawValue(value);
  }

  getFormatProps() {
    const { formatOptions } = this.props;
    if (typeof formatOptions === 'object' && formatOptions !== null) {
      return formatOptions;
    }
    return null;
  }

  getOptions() {
    let options = this.defaultFormatOptions;
    const formatProps = this.getFormatProps();
    if (formatProps !== null) {
      options = { ...options, ...formatProps };
    }
    return options;
  }

  getClassName() {
    let name = 'wethod-input';
    if (this.props.required) name += ' wethod-input--required';
    if (this.props.disabled) name += ' disabled';
    return name;
  }

  getInput() {
    if (this.props.disabled) {
      if (this.state.value) return (<span>{this.state.value}</span>);
      return (<span>&#160;</span>);
    }
    return (
      <Cleave options={this.getOptions()}
        htmlRef={(ref) => this.input = ref}
        placeholder={this.props.placeholder}
        onInit={this.handleInit.bind(this)}
        onChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)} />
    );
  }

  render() {
    return (
      <span className={this.getClassName()}>
        {this.getInput()}
      </span>
    );
  }
}

DateInput.propTypes = {
  /**
   * The date shown in the input (format DD/MM/YYYY)
   */
  value: PropTypes.string,

  /**
   * Name associated to the component. Used as parameter of onChange prop.
   */
  name: PropTypes.string,

  /**
   * Placeholder of the input
   */
  placeholder: PropTypes.string,

  disabled: PropTypes.bool,
  required: PropTypes.bool,

  /**
   * Additional options given to the component.
   * @see cleave (https://github.com/nosir/cleave.js/blob/master/doc/options.md)
   */
  formatOptions: PropTypes.object,

  /**
   * Triggered on every input change. (Parameters: name, value of the input)
   */
  onChange: PropTypes.func,

  /**
   * Triggered on input blur. (Parameters: value of the input)
   */
  onBlur: PropTypes.func,

  /**
   * Triggered on input focus. (Parameters: -)
   */
  onFocus: PropTypes.func,

  /**
   * Triggered on 'value' prop change. (Parameters: name, errors array)
   */
  onValidate: PropTypes.func,
};

module.exports = DateInput;
