const request = require('./Request');

const EmployeeCompanyHoliday = {
  list(employeeId, from, to) {
    return request({
      method: 'get',
      url: `/employee/${employeeId}/holiday?from=${from}&to=${to}`,
    });
  },
};

module.exports = EmployeeCompanyHoliday;
