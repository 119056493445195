/* eslint-disable react/sort-comp,react/prop-types,react/no-access-state-in-setstate */
const React = require('react');
const Item = require('./Item.react');

module.exports = class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showItems: false };
  }

  getItems() {
    return this.props.items.map((item) => (
      <Item key={item.id}
        onClick={this.onItemClick.bind(this)}
        {...item} />
    ));
  }

  onClick() {
    this.setState({ showItems: !this.state.showItems });
  }

  onItemClick(item) {
    this.setState({ showItems: false });
    this.props.onItemClick(item);
  }

  getItemsClassName() {
    let name = 'wethod-select2__list';
    if (this.state.showItems) {
      name += ' wethod-select2__list--visible';
    }
    return name;
  }

  render() {
    return (
      <div className="wethod-select2">
        <button type="button"
          className="wethod-button"
          onClick={this.onClick.bind(this)}>
          {this.props.current.name}
        </button>
        <ul className={this.getItemsClassName()}>{this.getItems()}</ul>
      </div>
    );
  }
};
