/* eslint-disable consistent-return */
const People = require('../client-types/People');
const Companies = require('../client-types/Companies');

/**
 *
 * @param type
 * @returns {Clients}
 * @constructor
 */
const ClientTypeFactory = (type) => {
  if (type === 'people') return new People();
  if (type === 'companies') return new Companies();
};

module.exports = ClientTypeFactory;
