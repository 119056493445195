const { connect } = require('react-redux');
const Component = require('../components/TimesheetsTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.some((key) => key === 'get-timesheets'),
  timesheets: state.timesheets,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  hasMorePages: state.hasMorePages,
  search: state.search,
  sort: state.sort,
  order: state.order,
  filters: state.filters,
  isEnabledDataFreezingFeature: state.isEnabledDataFreezingFeature,
});

const mapDispatchToProps = (dispatch) => ({
  getTimesheets: (offset, limit, search, order, sort, filters) => dispatch(
    actions.getTimesheets(offset, limit, search, order, sort, filters),
  ),
  getFrozenPeriod: () => dispatch(actions.getFrozenPeriod()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
