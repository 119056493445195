const TaskNode = require('./TaskNode');
// eslint-disable-next-line no-unused-vars
const LevelAmountNode = require('./LevelAmountNode');
// eslint-disable-next-line no-unused-vars
const Total = require('../NodeTotal/TaskNodeTotal');
const Product = require('./ProductNode');
const LevelAmountTotal = require('../NodeTotal/LevelAmountNodeTotal');

/**
 * Represent a task created from a product.
 * @type {ProductTaskNode}
 */
module.exports = class ProductTaskNode extends TaskNode {
  /**
   * @param task
   * @param {LevelAmountNode[]} levelAmounts
   * @param {AvailableLevel[]} availableLevels
   * */
  constructor(task, levelAmounts, availableLevels) {
    super(task, levelAmounts, availableLevels);
    this.product = new Product(task.product);
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  /**
   * Return the parent product.
   * @return {ProductNode}
   */
  getProduct() {
    return this.product;
  }

  /**
   * @return {number}
   */
  getExternalCost() {
    return this.getProduct().getExternalCost() * this.getQuantity();
  }

  /**
   * @return {number}
   */
  getMarkup() {
    return this.getProduct().getMarkup();
  }

  /**
   * Return amount of days for the given level in this task.
   * @param levelId
   * @return {number|null}
   */
  getDaysForLevel(levelId) {
    return this.getProduct().getDaysForLevel(levelId) * this.getQuantity();
  }

  /**
   * Return list of levels, each level contains sum of days related to it.
   * @return {LevelAmountTotal[]}
   */
  getDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const daysForLevel = this.getDaysForLevel(level.getId());

      daysByLevel.push(new LevelAmountTotal(daysForLevel,
        level.getCost(), level.getPrice(), level.getId()));
    }
    return daysByLevel;
  }

  /**
   * Return total task days.
   * @private
   * @returns {number}
   */
  getDays() {
    return this.getDaysByLevel()
      .reduce((sum, levelAmount) => sum + levelAmount.getDays(), 0);
  }

  /**
   * Return cost of all days related to this task.
   * @private
   * @returns {number}
   */
  getDaysCost() {
    return this.getDaysByLevel()
      .reduce((sum, levelAmount) => sum + levelAmount.getDays() * levelAmount.getCost(), 0);
  }

  /**
   * Return price of all days related to this task.
   * @private
   * @returns {number}
   */
  getDaysPrice() {
    return this.getDaysByLevel()
      .reduce((sum, levelAmount) => sum + levelAmount.getDays() * levelAmount.getPrice(), 0);
  }

  /**
   * @private
   * @returns {Total}
   */
  calculateTotal() {
    const productFixedPrice = this.getProduct().getPrice();
    const days = this.getDays();
    const cost = this.getDaysCost() + this.getExternalCost();
    let price = this.getDaysPrice() + this.getExternalCost() + this.getMarkupAmount();
    if (productFixedPrice) {
      price = productFixedPrice * this.getQuantity();
    }

    return new Total(days, cost, price, 0, this.getDaysByLevel());
  }

  toJson() {
    return {
      ...this.task,
      external_cost: this.getExternalCost(),
      markup: this.getMarkup(),
      levels: this.getLevels().map((level) => level.toJson()),
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };
  }
};
