/* eslint-disable class-methods-use-this,no-case-declarations,no-param-reassign */
const constants = require('./constants');
const QuarterFilter = require('../../finance/clients/detail/company/models/TimeFilter/QuarterFilter');
const TimeFilterFactory = require('../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');

class SalesOverviewReducer {
  constructor(state) {
    const timeFilter = new QuarterFilter();

    this.state = {
      ...state,
      sidebarToShow: null,
      year: `${moment().day('Thursday').format('YYYY-MM')}-01`,
      timeFilter: timeFilter.toJSON(),
      projects: [],
      filteredProjects: [],
      revenues: [],
      trends: [],
      revenuesTargets: [],
      filteredRevenuesTargets: [],
      isWaitingForRevenues: true,
      isWaitingForRevenuesTargets: true,
      isWaitingForTrends: true,
      isWaitingForProjects: true,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  getTargetsInPeriod(filter, targets) {
    const timeFilter = new TimeFilterFactory(filter);
    const yearsString = filter.period === 1 ? [moment(this.state.year)
      .year()] : timeFilter.getBreakpoints().map((breakpoint) => breakpoint.getValue()).join();

    return targets.filter((target) => yearsString.indexOf(target.year) !== -1);
  }

  getProjectsConcretizedInFrame(filter, projects) {
    const timeFilter = new TimeFilterFactory(filter);
    const monthsString = timeFilter.getMonthsInFrame().join();

    return projects.filter((project) => monthsString
      .indexOf(project.last_probability_update) !== -1);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.UPDATE_TIME_FILTER:
        return {
          ...state,
          timeFilter: action.filter,
          filteredProjects: this.getProjectsConcretizedInFrame(action.filter, state.projects),
          filteredRevenuesTargets: this.getTargetsInPeriod(action.filter, state.revenuesTargets),
        };
      case constants.GET_REVENUES_REQUEST:
        return {
          ...state,
          isWaitingForRevenues: true,
        };
      case constants.GET_REVENUES_SUCCESS:
        return {
          ...state,
          revenues: action.revenues,
          isWaitingForRevenues: false,
        };
      case constants.GET_PROJECTS_REQUEST:
        return {
          ...state,
          isWaitingForProjects: true,
        };
      case constants.GET_PROJECTS_SUCCESS:
        return {
          ...state,
          projects: action.projects,
          filteredProjects: this.getProjectsConcretizedInFrame(state.timeFilter, action.projects),
          isWaitingForProjects: false,
        };
      case constants.GET_TRENDS_REQUEST:
        return {
          ...state,
          isWaitingForTrends: true,
        };
      case constants.GET_TRENDS_SUCCESS:
        return {
          ...state,
          trends: action.trends,
          isWaitingForTrends: false,
        };
      case constants.GET_REVENUES_TARGETS_REQUEST:
        return {
          ...state,
          isWaitingForRevenuesTargets: true,
        };
      case constants.GET_REVENUES_TARGETS_SUCCESS:
        return {
          ...state,
          revenuesTargets: action.targets,
          filteredRevenuesTargets: this.getTargetsInPeriod(state.timeFilter, action.targets),
          isWaitingForRevenuesTargets: false,
        };
      case constants.UPDATE_COMPANY_REQUEST:
      case constants.UPDATE_CONTACT_REQUEST:
      case constants.SAVE_CONTACT_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.DELETE_CONTACT_REQUEST:
      case constants.DELETE_COMPANY_REQUEST:
      case constants.REPLACE_COMPANY_REQUEST:
        return {
          ...state,
          isDeletingClient: true,
        };
      case constants.UPDATE_COMPANY_SUCCESS:
        return {
          ...state,
          isSaving: false,
          company: { ...this.state.company, ...action.company },
        };
      case constants.UPDATE_CONTACT_SUCCESS:
        return {
          ...state,
          isSaving: false,
          customers: state.customers.map((contact) => (contact.id === action.contact.id
            ? { ...action.contact }
            : contact)),
          focusedContact: action.contact,
        };
      case constants.ADD_CONTACT_REQUEST:
        const emptyContact = {
          client: state.company.id,
        };
        return {
          ...state,
          sidebarToShow: 'contact',
          focusedContact: emptyContact,
        };
      case constants.SAVE_CONTACT_SUCCESS:
        return {
          ...state,
          isSaving: false,
          customers: state.customers.concat(action.contact),
          focusedContact: action.contact,
        };
      case constants.DELETE_CONTACT_SUCCESS:
        return {
          ...state,
          sidebarToShow: null,
          customers: state.customers.filter((contact) => contact.id !== action.contactId),
          isDeletingClient: false,
        };
      case constants.GROUP_SAVE_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.GROUP_SAVE_SUCCESS:
        return {
          ...state,
          isSaving: false,
          groups: state.groups.concat(action.group),
        };
      case constants.UPDATE_CONTACT_PHONE_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.UPDATE_CONTACT_PHONE_SUCCESS:
        return {
          ...state,
          customers: state.customers.map((contact) => {
            if (contact.id === action.clientId) {
              return {
                ...contact,
                phones: action.phones,
              };
            }
            return contact;
          }),
          isSaving: false,
        };
      case constants.SHOW_COMPANY_SIDEBAR:
        return {
          ...state,
          sidebarToShow: 'company',
        };
      case constants.SHOW_CONTACT_SIDEBAR:
        return {
          ...state,
          sidebarToShow: 'contact',
          focusedContact: state.customers.find((contact) => contact.id === action.contactId),
        };
      case constants.HIDE_COMPANY_SIDEBAR:
      case constants.HIDE_CONTACT_SIDEBAR:
        return {
          ...state,
          sidebarToShow: null,
        };
      case constants.SHOW_DELETE_CONTACT_MODAL:
        return {
          ...state,
          modalToShow: 'delete-contact',
          sidebarToShow: null,
        };
      case constants.SHOW_DELETE_COMPANY_MODAL:
        return {
          ...state,
          modalToShow: 'delete-company',
          sidebarToShow: null,
        };
      case constants.SHOW_DELETE_COMPANY_FAILURE_MODAL:
        return {
          ...state,
          isDeletingClient: false,
          modalToShow: 'delete-company-failure',
          sidebarToShow: null,
          linkedProjects: action.projects,
          linkedContacts: action.customers,
          otherCompanies: action.companies.filter((company) => {
            company.name = company.corporate_name;
            return company.id !== state.company.id;
          }),
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
        };
      default:
        return state;
    }
  }
}

module.exports = SalesOverviewReducer;
