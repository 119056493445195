const { connect } = require('react-redux');
const Component = require('../../components/Calculator/Header/HeaderLevelTooltipMessage.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
  budgetVersion: state.info.version,
});

const mapDispatchToProps = (dispatch) => ({
  showDailyCostPriceLevelModal: (levelId, levelName, projectId, budgetVersion) => dispatch(
    actions.showDailyCostPriceLevelModal(levelId, levelName, projectId, budgetVersion),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
