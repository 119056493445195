/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');
const Date = require('../DateController.react');

module.exports = class OtherReadOnlyDetails extends React.Component {
  render() {
    return (
      <div>
        <Date editable={false}
          isRoundTrip
          date={this.props.travel.date}
          return_date={this.props.travel.return_date} />
        <ReadOnlyField placeholder="Additional requests" value={this.props.travel.additional_requests}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </ReadOnlyField>
        <TravelOfficeSection travel={this.props.travel} editMode={false} />
        <FileSection editMode={false} carrier={this.props.travel} />
      </div>
    );
  }
};
