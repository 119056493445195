const { connect } = require('react-redux');
const isEqual = require('react-fast-compare');
const Component = require('../components/TimesheetsSearch.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  search: state.search,
  filtersApplied: !isEqual(state.filters, {}),
});

const mapDispatchToProps = (dispatch) => ({
  onFilterClick: () => dispatch(actions.showAdvancedSearch()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
