/* eslint-disable react/prefer-stateless-function,react/jsx-no-bind */
const React = require('react');

module.exports = class CreateButton extends React.Component {
  render() {
    return (
      <div className="timeline-add-event">
        <div className="timeline-add-event__button-container">
          <div className="timeline-add-event__button" onClick={() => this.props.onClick()}>+</div>
        </div>
        <span className="timeline-add-event__hint">Add event to timeline</span>
      </div>
    );
  }
};
