const request = require('./Request');

const AllocationRequest = {
  getAll(options) {
    const query = `?offset=${options.offset}&limit=${options.limit}&ownership=${options.ownerFilter}&search=${options.searchFilter}&order=${options.orderBy}&sort=${options.sort}`;

    return request({
      method: 'get',
      url: `/allocation-request/${query}`,
    });
  },
  update(id, changes) {
    return request({
      method: 'patch',
      url: `/allocation-request/${id}`,
      data: changes,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/allocation-request/',
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/allocation-request/${id}`,
    });
  },
  getProjectsAutocomplete(search = '') {
    const query = `?search=${search}`;

    return request({
      method: 'get',
      url: `/allocation-request/projects${query}`,
    });
  },
  approve(id) {
    return request({
      method: 'post',
      url: `/allocation-request/${id}/approve`,
    });
  },
  reject(id) {
    return request({
      method: 'post',
      url: `/allocation-request/${id}/reject`,
    });
  },
  getProjectDetails(projectId, employeeId) {
    return request({
      method: 'get',
      url: `/allocation-request/project-details/${projectId}/${employeeId}`,
    });
  },
};

module.exports = AllocationRequest;
