const React = require('react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../common/react/Table2/TableHead.react');

const TimeOffListHeader = ({ ...rest }) => (
  <TableHead {...rest}>
    <TableRow>
      <TableCell className="time-off-item__user">User</TableCell>
      <TableCell className="time-off-item__hours-group" colSpan={5}>
        <span>Vacation</span>
        <span>(Hours)</span>
      </TableCell>
      <TableCell className="time-off-item__hours-group" colSpan={5}>
        <span>Leaves</span>
        <span>(Hours)</span>
      </TableCell>
      <TableCell className="time-off-item__hours-group" colSpan={5}>
        <span>Target</span>
        <span>(Hours)</span>
      </TableCell>
    </TableRow>
    <TableRow className="time-off-item__subheader">
      <TableCell className="time-off-item__user" />
      <TableCell className="time-off-item__cell">Backlog + accrued</TableCell>
      <TableCell className="time-off-item__cell">Used</TableCell>
      <TableCell className="time-off-item__cell">Planned</TableCell>
      <TableCell className="time-off-item__cell">Requested</TableCell>
      <TableCell className="time-off-item__cell">Remaining</TableCell>
      <TableCell className="time-off-item__cell">Backlog + accrued</TableCell>
      <TableCell className="time-off-item__cell">Used</TableCell>
      <TableCell className="time-off-item__cell">Planned</TableCell>
      <TableCell className="time-off-item__cell">Requested</TableCell>
      <TableCell className="time-off-item__cell">Remaining</TableCell>
      <TableCell className="time-off-item__cell">Yearly</TableCell>
      <TableCell className="time-off-item__cell">Used</TableCell>
      <TableCell className="time-off-item__cell">Planned</TableCell>
      <TableCell className="time-off-item__cell">Requested</TableCell>
      <TableCell className="time-off-item__cell">Remaining</TableCell>
    </TableRow>
  </TableHead>
);

module.exports = TimeOffListHeader;
