'use strict';

Wethod.getArchiveModal = function (archived) {
  // WARNING: Project archiving logic is now moved to project_archive rule
  // This file is no longer needed and it will be removed once rule-engine will be ready
  var data;

  if (archived) {
    data = {
      title: 'PROJECT UNARCHIVE',
      text: 'Are you sure you want to unarchive this project?',
      buttonOkText: 'UNARCHIVE',
      buttonKoText: 'GO BACK',
      action: 'unArchiveProject',
    };
  } else {
    data = {
      title: 'PROJECT ARCHIVE',
      text: 'Are you sure you want to archive this project?',
      buttonOkText: 'ARCHIVE',
      buttonKoText: 'GO BACK',
      action: 'archiveProject',
    };
  }

  return data;
};
