const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const FiscalYear = require('./containers/fiscal-year/FiscalYear');

module.exports = ({ store }) => (
  <Provider store={store}>
    <FiscalYear />
  </Provider>
);
