const React = require('react');
const PropTypes = require('prop-types');
const Escaper = require('../../../../../common/react/formatters/Escaper.react');
const MasterLabel = require('./MasterLabel.react');

const ProjectDescription = ({ name, joc, client, isMaster }) => {
  const getJocColor = () => ({ backgroundColor: joc });

  const masterLabel = isMaster ? <MasterLabel /> : null;

  return (
    <div className="wethod-info-header__description-content">
      <div className="invoice-plan__joc-color" style={getJocColor()} />
      <div className="wethod-info-header__description-text">
        <h1 className="wethod-info-header__title"><Escaper>{name}</Escaper></h1>
        <h2 className="wethod-info-header__subtitle"><Escaper>{client}</Escaper></h2>
      </div>
      {masterLabel}
    </div>
  );
};

module.exports = ProjectDescription;

ProjectDescription.propTypes = {
  // Project name
  name: PropTypes.string,
  // Client name
  client: PropTypes.string,
  // Joc color
  joc: PropTypes.string,

  isMaster: PropTypes.bool,
};

ProjectDescription.defaultProps = {
  name: null,
  client: null,
  joc: null,
  isMaster: false,
};
