/* eslint-disable react/prop-types,class-methods-use-this */
const React = require('react');
const NewsModel = require('../../models/AppBanner/NewsModel');
const NewsBanner = require('./NewsBanner.react');
const PlaygroundBanner = require('./PlaygroundBanner.react');

module.exports = class AppBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = { news: null };
  }

  componentDidMount() {
    const newsModel = new NewsModel();

    $.when(newsModel.getLast()).done((news) => {
      this.setState({ news });
    });
  }

  close() {
    this.setState({ news: null }, () => {
      this.props.onHeightChange(0);
    });
  }

  isInPlayground() {
    const currentLocation = Wethod.userInfo.get('company');
    return currentLocation === 'playground';
  }

  render() {
    const { news } = this.state;
    if (this.isInPlayground()) {
      return <PlaygroundBanner onHeightChange={this.props.onHeightChange} />;
    }
    if (news) {
      return (
        <NewsBanner {...news}
          onCloseClick={this.close.bind(this)}
          onHeightChange={this.props.onHeightChange} />
      );
    }
    return null;
  }
};
