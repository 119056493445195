const constants = require('./constants');

class ProductsReducer {
  constructor(state) {
    this.state = {
      ...state,
      showSidebar: false,
      focusedItem: null,
      editMode: false,
      unsavedChanges: [],
      waitingFor: [],
      availabilityFilter: state.availabilityFilter,
      modalToShow: null,
      modalData: null,
      pageOffset: 0,
      pageLimit: 0,
      hasMorePages: true,
      products: [],
      levels: [],
      search: '',
      sort: 'asc',
      order: 'name',
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_PRODUCTS_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          search: action.search,
          order: action.order,
          sort: action.sort,
          products: action.offset ? state.products : [],
          waitingFor: state.waitingFor.concat('get-products'),
        };
      }
      case constants.GET_PRODUCTS_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.products.length >= state.pageLimit,
          products: state.products.concat(action.products),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-products'),
        };
      }
      case constants.CREATE_PRODUCT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-product'),
        };
      }
      case constants.CREATE_PRODUCT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-product'),
          products: state.products.concat(action.product),
        };
      }
      case constants.UPDATE_PRODUCT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-product'),
        };
      }
      case constants.UPDATE_PRODUCT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-product'),
          products: state.products.map((product) => {
            if (product.id === action.product.id) {
              return action.product;
            }

            return product;
          }),
        };
      }
      case constants.AVAILABILITY_FILTER_CHANGE: {
        return {
          ...state,
          availabilityFilter: action.key,
        };
      }
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          focusedItem: action.id,
          showSidebar: true,
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          focusedItem: null,
          showSidebar: false,
          unsavedChanges: [],
        };
      }
      case constants.GET_LEVELS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-levels'),
        };
      }
      case constants.GET_LEVELS_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-levels'),
          levels: action.levels.map((level) => ({
            ...level,
            days: 0,
          })),
        };
      }
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SHOW_MODAL:
        return {
          ...state,
          modalToShow: action.key,
          modalData: action.data,
        };
      case constants.CHANGE_SIDEBAR_EDIT_MODE:
        return {
          ...state,
          editMode: action.editMode,
        };
      case constants.CHANGE_UNSAVED_CHANGES:
        return {
          ...state,
          unsavedChanges: action.unsavedChanges,
        };
      default:
        return state;
    }
  }
}

module.exports = ProductsReducer;
