const React = require('react');
const PercentageCell = require('../../Cell/PercentageCell.react');
const Delta = require('../../NumericDelta.react');
const CollaborativeInput = require('../../../../containers/BudgetCollaborativeInput');
const NumericField = require('../../../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');

module.exports = class ProductTaskQuantity extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.children };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const changedValueFromParent = prevProps.children !== this.props.children;

    if (changedValueFromParent) {
      this.updateValue(this.props.children);
    }
  }

  onChange(e) {
    let numericValue = parseFloat(e.target.value);
    numericValue = !Number.isNaN(numericValue) ? numericValue : 0;

    this.props.save(numericValue);
  }

  updateValue(value) {
    this.setState({ value });
  }

  render() {
    return (
      <PercentageCell>
        <Delta actualValue={this.state.value} previousValue={this.props.previousValue} />
        <CollaborativeInput id={this.props.id}>
          <NumericField onChange={this.onChange}
            value={this.state.value}
            readOnly={!this.props.editable}
            positiveOnly />
        </CollaborativeInput>
      </PercentageCell>
    );
  }
};
