const { connect } = require('react-redux');
const Component = require('../../components/modals/DeleteFailureModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'archive-project').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onArchive: (project) => dispatch(actions.archiveProject(project, true)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
