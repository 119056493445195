const React = require('react');
const Notes = require('./NoteModal.react');

const Modal = ({ toShow, closeModal, data }) => {
  const getModal = () => {
    switch (toShow) {
      case 'notes':
        return <Notes onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
