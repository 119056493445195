/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Card = require('../../../containers/Boards/Kanban/Card');
const CardCreator = require('./CardCreator.react');
const ListHeader = require('./ListHeader/ListHeader.react');

module.exports = class List extends React.Component {
  componentDidMount() {
    this.props.addDroppableContainer(this.listEl);
  }

  getCards() {
    return this.props.cards.map((card) => (
      <Card
        key={card.id}
        {...card}
        membersAttributeId={this.props.options.member}
        dateAttributeId={this.props.options.date}
        showSidebar={this.props.showSidebar} />
    ));
  }

  onAddCard(name) {
    this.props.addCard(name, this.props.cards.length,
      [{
        id: this.props.options.status,
        value: this.props.id,
      }]);
  }

  getCreator() {
    if (this.props.canEdit) {
      return <CardCreator onAddCard={this.onAddCard.bind(this)} waiting={this.props.addingCard} />;
    }
    return null;
  }

  render() {
    return (
      <div className="project-canvas-kanban__list" data-id={this.props.id}>
        <ListHeader name={this.props.name} color={this.props.color} canEdit={this.props.canEdit} />
        <div
          className="project-canvas-kanban__list-cards"
          ref={(el) => this.listEl = el}
          data-id={this.props.id}
          data-board-id={this.props.boardId}>
          {this.getCards()}
        </div>
        {this.getCreator()}
      </div>
    );
  }
};
