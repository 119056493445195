const request = require('./Request');

const ProjectReport = {
  get(projectId) {
    return request({
      method: 'get',
      url: `/report/project/?project_id=${projectId}`,
    });
  },
};

module.exports = ProjectReport;
