const React = require('react');
const DefaultContent = require('../../containers/Footer/DefaultFooterContent/DefaultFooterContent');
const VersionComparatorContent = require('../../containers/Footer/VersionComparatorFooterContent/VersionComparatorFooterContent');
const RequestManager = require('../../containers/RequestManager');
const FlipTransition = require('../../../../../../common/react/Transition/FlipTransition/FlipTransition.react');

const Footer = ({ compareMode }) => (
  <FlipTransition triggered={compareMode} className="wethod-budget-footer">
    <RequestManager>
      <DefaultContent />
    </RequestManager>
    <RequestManager>
      <VersionComparatorContent />
    </RequestManager>
  </FlipTransition>
);

module.exports = Footer;
