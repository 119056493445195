const { connect } = require('react-redux');
const Component = require('../../components/company-holidays/CompanyHolidaysTableBodyRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-company-holiday-')),
  waitingForDelete: state.waitingFor.filter((key) => key.startsWith('delete-company-holiday-')),
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (companyHoliday) => dispatch(actions.createCompanyHoliday(companyHoliday)),
  onUpdate: (id, companyHoliday) => dispatch(actions.updateCompanyHoliday(id, companyHoliday)),
  deleteNewCompanyHoliday: () => dispatch(actions.deleteNewCompanyHoliday()),
  showDeleteModal: (id) => dispatch(
    actions.showDeleteModalCompanyHoliday(id),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
