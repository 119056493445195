const React = require('react');
const EditNotesModal = require('../containers/modal/NotesModal');
const ApprovePaymentModal = require('../containers/modal/ApprovePaymentModal');
const ExportModal = require('../containers/modal/ExportModal');
const DeleteOrderModal = require('../containers/modal/DeleteOrderModal');
const SendOrderModal = require('../containers/modal/SendOrderModal');
const ExportSuccessModal = require('./modal/ExportSuccessModal.react');
const CannotUploadModal = require('../../../../../../common/react/modals/CannotUploadModal.react');
const CannotDownloadModal = require('../../../../../../common/react/modals/CannotDownloadModal.react');
const ApprovedNotificationModal = require('../containers/modal/ApprovedNotificationModal');
const SendErrorModal = require('./modal/SendOrderErrorModal.react');
const ServerErrorModal = require('../../../../../../common/react/Modal/SimpleModal/SimpleModal.react');

const Modal = ({
  toShow,
  closeModal,
  data,
}) => {
  const getModal = () => {
    switch (toShow) {
      case 'edit-notes':
        return <EditNotesModal onCancelClick={closeModal} data={data} />;
      case 'approve-payment':
        return <ApprovePaymentModal onCancelClick={closeModal} data={data} />;
      case 'send-order':
        return <SendOrderModal onCancelClick={closeModal} data={data} />;
      case 'delete':
        return <DeleteOrderModal onCancelClick={closeModal} data={data} />;
      case 'export':
        return <ExportModal onCancelClick={closeModal} data={data} />;
      case 'export-success-feedback':
        return <ExportSuccessModal onCancelClick={closeModal} />;
      case 'upload-error':
        return <CannotUploadModal onCancelClick={closeModal} data={data} />;
      case 'download-error':
        return <CannotDownloadModal onCancelClick={closeModal} data={data} />;
      case 'approved-notification':
        return <ApprovedNotificationModal onCancelClick={closeModal} order={data} />;
      case 'send-error':
        return <SendErrorModal onCancelClick={closeModal} message={data} />;
      case 'server-error':
        return (
          <ServerErrorModal onClose={closeModal} title={data.title}>
            {data.message}
          </ServerErrorModal>
        );
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
