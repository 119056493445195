const React = require('react');
const SectionTitle = require('../../../../common/components/SectionTitle.react');
const ComparatorConnector = require('../../../../common/components/ComparatorConnector.react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({
  month, target, production, prevMonthProduction,
}) => {
  const getTargetComparator = () => {
    const labels = {
      much_lower: 'well below',
      lower: 'below',
      almost_equal: 'in line with',
      equal: 'equal',
      greater: 'above',
      much_greater: 'well above',
    };

    return <Comparator a={production} b={target} labels={labels} />;
  };

  const getLastMonthComparator = () => {
    const labels = {
      much_lower: 'decreased compared to',
      lower: 'decreased compared to',
      almost_equal: 'remained in line with',
      equal: 'remained in line with',
      greater: 'increased compared to',
      much_greater: 'increased compared to',
    };

    return <Comparator a={production} b={prevMonthProduction} labels={labels} />;
  };

  const getOnTarget = () => {
    if (target) {
      const c1 = { a: production, b: prevMonthProduction };
      const c2 = { a: production, b: target };
      return (
        <span>
          <ComparatorConnector c1={c1} c2={c2} />
          {' '}
          was
          {' '}
          {getTargetComparator()}
          {' '}
          its monthly target
        </span>
      );
    }
    return null;
  };

  return (
    <SectionTitle>
      Production in
      {' '}
      {month}
      {' '}
      has
      {' '}
      {getLastMonthComparator()}
      {' '}
      the previous month
      {' '}
      {getOnTarget()}
    </SectionTitle>
  );
};
