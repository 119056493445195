const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/Backlog.react');

const mapStateToProps = (state) => ({
  backlog: state.month_backlog,
  backlogPrevMonth: state.prev_month_backlog,
  backlogPrevYear: state.prev_year_backlog,
  futureWeeks: state.backlog_weeks,
});

module.exports = connect(mapStateToProps)(Component);
