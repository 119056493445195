/* eslint-disable react/prop-types */
const React = require('react');
const Model = require('../models/ReviewsWidget');
const Loading = require('../../Loading.react');
const Empty = require('./Empty.react');
const Review = require('./Review.react');

module.exports = class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: true,
      reviews: [],
    };
  }

  componentDidMount() {
    const model = new Model();

    $.when(model.getModel()).done((reviews) => this.setState({
      reviews,
      waiting: false,
    }));
  }

  getBody() {
    if (this.state.waiting) {
      return <Loading />;
    } if (!this.state.reviews.length) {
      return <Empty />;
    }
    return this.state.reviews.map((review) => <Review key={review.id} {...review} />);
  }

  render() {
    const title = this.props.title ? this.props.title : 'Project & Team reviews';
    return (
      <div className="wethod-widget widget-reviews">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">{title}</span>
        </div>
        <div className="wethod-widget__body">{this.getBody()}</div>
      </div>
    );
  }
};
