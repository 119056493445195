const { connect } = require('react-redux');
const Component = require('../../components/sidebar/SupplierSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit,
  supplier: state.focusedSupplier,
  statusEnabled: state.statusEnabled,
  statuses: state.statuses,
  paymentTerms: state.paymentTerms,
  countries: state.countries,
  provinces: state.provinces,
  isSaving: state.waitingFor.filter((key) => key === 'save-supplier').length > 0,
  validationError: state.serverErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  closeSidebar: () => dispatch(actions.closeSidebar()),
  updateSupplier: (supplier) => dispatch(actions.updateSupplier(supplier)),
  addSupplier: (supplier) => dispatch(actions.addSupplier(supplier)),
  deleteSupplier: (supplier) => dispatch(actions.showConfirmDeleteModal(supplier)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
