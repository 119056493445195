const { connect } = require('react-redux');
const Component = require('../../components/Objectives/QuarterStatus.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  employee: state.id,
  year: state.objectivesByQuarter.year,
  editable: state.editable,
  sponsors: state.sponsors,
  waiting: state.waiting,
});

const mapDispatchToProps = (dispatch) => ({
  updateStatus: (status, year, quarter, employeeId) => dispatch(actions
    .updateQuarterStatus(status, year, quarter, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
