const React = require('react');
const Slider = require('../../../../../../../common/react/Slider.react');

module.exports = class KeyResult extends React.Component {
  constructor(props) {
    super(props);

    this.timer = 0;
  }

  onSliderChange(name, value) {
    this.props.editKeyResult({
      ...this.props,
      progress: value,
    }, this.props.quarterId,
    this.props.objectiveId);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.editKeyResult({
        ...this.props,
        progress: value,
      }, this.props.quarterId,
      this.props.objectiveId, true);
    }, 500);
  }

  isQuarterChecked() {
    return this.props.quarter_status === 2;
  }

  render() {
    return (
      <div className="profile-objectives__key-result">
        <span className="profile-objectives__key-result-title profile-objective__col-title">{this.props.name}</span>
        <span className="profile-objectives__key-result-progress profile-objective__col-progress">
          <div className="profile-objectives__key-result-progress-slider profile-objective__col-progress-slider">
            <Slider max={this.props.target}
              name="progress"
              onChange={this.onSliderChange.bind(this)}
              value={this.props.progress}
              disabled={!this.props.editable || this.isQuarterChecked()} />
          </div>
          <span
            className="profile-objectives__key-result-progress-amount profile-objective__col-progress-amount">
            {this.props.progress}
          </span>
        </span>
      </div>
    );
  }
};
