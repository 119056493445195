const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');

const Goodbye = ({ randomize }) => {
  const alternatives = ['Cheers!', 'Have a nice day!', ' Ciao!', 'Have a good day!', 'See you next week!', "That's all folks!"];

  return <Paragraph>{randomize(alternatives)}</Paragraph>;
};

module.exports = withRandomize(Goodbye);
