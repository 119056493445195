const { connect } = require('react-redux');
const Component = require('../../components/modals/ShareProjectModal.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  shareProject: (message, employeeId, path, projectId) => dispatch(actions
    .shareProject(message, employeeId, path, projectId)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
