const { connect } = require('react-redux');
const Component = require('../../components/Kpi/SoldValueReport.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  updateTimeFilter: (filter) => dispatch(actions.updateTimeFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
