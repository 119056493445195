/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,react/no-children-prop */
const React = require('react');
const Month = require('./HeaderMonth.react');
const DateService = require('../../../../../../services/DateService');
const Navigator = require('../containers/Navigator');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const Table2Head = require('../../../../../../common/react/Table2/TableHead.react');

module.exports = class TableHead extends React.Component {
  /**
   * Check if the given month is December and it's not the last visible month.
   * @param month
   * @param index
   * @return {boolean}
   */
  isYearBoundary(month, index) {
    return moment(month).month() === 11 && index !== 11;
  }

  /**
   * Return the year of the given month only if it's Jan or if it's Dec but not the last visible month.
   * @param month
   * @param index
   * @return {null}
   */
  getYear(month, index) {
    if (moment(month).month() === 0 || this.isYearBoundary(month, index)) return moment(month).format('YYYY');
    return null;
  }

  getMonths() {
    return this.props.visibleMonths.map((month, index) => (
      <Month
        key={month}
        value={moment(month).format('MMM')}
        isYearBoundary={this.isYearBoundary(month, index)}
        year={this.getYear(month, index)}
        isCurrentMonth={DateService.isCurrentMonth(month)} />
    ));
  }

  getHeaderColumns() {
    return []
      .concat(<Navigator key="info" />)
      .concat(this.getMonths())
      .concat(<TableCell key="actions" className="production-plan-project__actions" />);
  }

  render() {
    return (
      <Table2Head
        children={this.props.children}
        updateHeight={this.props.updateHeight}
        scrollBarWidth={this.props.scrollBarWidth}>
        <TableRow>
          {this.getHeaderColumns()}
        </TableRow>
      </Table2Head>
    );
  }
};
