/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

class EventType extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.select(this.props.type);
    this.props.closeDropdown();
  }

  render() {
    return (
      <li className="event-type" onClick={this.handleClick}>
        <div className={`event-type__icon icon--${this.props.type.name}`} />
        <div className="event-type__name">{this.props.type.name}</div>
      </li>
    );
  }
}

module.exports = EventType;
