const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const TextArea = require('../../../../../../common/react/inputs/TextArea/BasicTextArea/BasicTextArea.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class NotesModal extends React.Component {
  static hasChanged(oldValue, newValue) {
    return oldValue !== newValue;
  }

  constructor(props) {
    super(props);

    this.state = {
      notes: this.props.data.notes,
      unsavedChanges: false,
    };
  }

  handleChange(e) {
    const { value } = e.target;
    const hasChanged = NotesModal.hasChanged(value, this.props.data.notes);
    this.setState({ notes: value, unsavedChanges: hasChanged });
  }

  handleSave() {
    if (this.state.unsavedChanges && this.canEdit()) {
      this.setState({ unsavedChanges: false });
      const changes = { notes: this.state.notes };
      this.props.onSave(this.props.data.id, changes);
    }
  }

  canEdit() {
    return this.props.canEditItem(this.props.data.owner.id);
  }

  render() {
    return (
      <Modal title="Notes" onCancelClick={this.props.onClose} className="expenses__modal">
        <TextArea readOnly={!this.canEdit()}
          name="notes"
          label="Notes"
          value={this.state.notes}
          rows={4}
          onChange={this.handleChange.bind(this)} />
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onClose}>Cancel</Button>
          <ShowIf condition={this.canEdit()}>
            <Button onClick={this.handleSave.bind(this)}
              disabled={!this.state.unsavedChanges || this.props.isSaving}>
              Save
            </Button>
          </ShowIf>
        </div>
        <ShowIf condition={this.props.isSaving}>
          <div className="wethod-modal2__feedback">Saving...</div>
        </ShowIf>
      </Modal>
    );
  }
};
