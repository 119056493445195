const React = require('react');
const ReadOnlyField = require('../ReadOnlyField.react');
const { formatDecimalNumber: format } = require('../../../../../../../services/FormatService');

const ReadOnlyTravelOfficeCostsField = ({ value, placeholder }) => {
  const formattedValue = value ? format(value, 1) : value;
  return (
    <ReadOnlyField value={formattedValue} placeholder={placeholder} />
  );
};

module.exports = ReadOnlyTravelOfficeCostsField;
