/* eslint-disable no-param-reassign */
const Heatmap = {

  /**
     * Calculate new heatmap values considering 'visibility' and 'removed' attribute
     * @param {array} projects
     */
  updateHeatMap(projects) {
    let hottest = 1;

    // Find hottest day for non hidden projects
    projects.forEach((project) => {
      if (project.visibility !== false && project.removed !== true) {
        project.days.forEach((day) => {
          hottest = Math.max(hottest, day.rawHeat);
        });
      }
    });

    // Update 'heat' for each day
    projects.forEach((project) => {
      project.days.forEach((day) => {
        day.heat = Math.round((day.rawHeat / hottest) * 10) / 10;
      });
    });

    return projects;
  },
};

module.exports = Heatmap;
