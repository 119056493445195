const React = require('react');
const Loader = require('../Loader/Loader.react');

const ModalLoader = () => (
  <div className="wethod-modal__loader">
    <Loader />
  </div>
);

module.exports = ModalLoader;
