'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ProjectReviewStore = require('../../apps/core/modules/project-review/store');
var ProjectReviewReducer = require('../../apps/core/modules/project-review/reducer');
var ProjectReview = require('../../apps/core/modules/project-review/index');

Wethod.module('ProjectReviewApp', function (ProjectReviewApp, Wethod, Backbone, Marionette, $) {
  ProjectReviewApp.Controller = {
    show: function (idProject, idReview) {
      // aggiungere loading a body view
      var projectReviewConfigRequest = Wethod.request('get:company:pipeline:review:config');
      $.when(projectReviewConfigRequest).done(function (projectReviewConfig) {
        var reviewEnabled = projectReviewConfig.get('enabled');
        if (!reviewEnabled) { // project review not enabled for the company
          Wethod.trigger('url:404:show');
        } else {
          var layout = new ProjectReviewApp.StructureLayoutView();
          layout.render();

          var reducerWrapper = new ProjectReviewReducer({
            id: idReview,
            allowMissingPeopleCriteria: projectReviewConfig.get('allow_users_missing_criteria'),
            allowMissingProjectCriteria: projectReviewConfig.get('allow_projects_missing_criteria'),
          });

          var store = ProjectReviewStore(reducerWrapper.reduxReducer);
          var element = React.createElement(ProjectReview, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        }
      });
    },
  };
});
