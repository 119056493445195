module.exports.BUDGET_STATUS_ACTION_SEND = 'SEND';
module.exports.BUDGET_STATUS_ACTION_APPROVE = 'APPROVE';
module.exports.BUDGET_STATUS_ACTION_REJECT = 'REJECT';
module.exports.BUDGET_STATUS_ACTION_RECALL = 'RECALL';

module.exports.AREA_TYPE_DEFAULT = 'DEFAULT';
module.exports.AREA_TYPE_CONTINGENCY_DISCOUNT = 'CONTINGENCY-DISCOUNT';
module.exports.AREA_TYPE_TRAVEL_CORE_COST_EXPENSES = 'TRAVEL-CORE-COST-EXPENSES';
module.exports.AREA_TYPE_PM = 'PM';

module.exports.TASK_TYPE_DEFAULT = 'DEFAULT';
module.exports.TASK_TYPE_DISCOUNT = 'DISCOUNT';
module.exports.TASK_TYPE_CONTINGENCY = 'CONTINGENCY';
module.exports.TASK_TYPE_TRAVEL = 'TRAVEL';
module.exports.TASK_TYPE_EXPENSE = 'EXPENSE';
module.exports.TASK_TYPE_CORE_COST = 'CORE_COST';
module.exports.TASK_TYPE_CREATIVITY = 'CREATIVITY';
module.exports.TASK_TYPE_PRE_SALE = 'PRE_SALE';
module.exports.TASK_TYPE_PRODUCT = 'PRODUCT';
module.exports.TASK_TYPE_PM = 'PM';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';

module.exports.SHOW_SIDEBAR_PROJECT = 'SHOW_SIDEBAR_PROJECT';

module.exports.COMPARE_VERSIONS_MODE_ENTER = 'COMPARE_VERSIONS_MODE_ENTER';
module.exports.COMPARE_VERSIONS_MODE_EXIT = 'COMPARE_VERSIONS_MODE_EXIT';
module.exports.VERSION_COMPARATOR_CLEAR = 'VERSION_COMPARATOR_CLEAR';

module.exports.SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
module.exports.SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
module.exports.SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';

module.exports.UPDATE_INFO_REQUEST = 'UPDATE_INFO_REQUEST';
module.exports.UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS';
module.exports.UPDATE_INFO_FAILURE = 'UPDATE_INFO_FAILURE';
module.exports.COLLABORATOR_UPDATE_INFO = 'COLLABORATOR_UPDATE_INFO';

module.exports.APPLY_PS_CORRECTION_REQUEST = 'APPLY_PS_CORRECTION_REQUEST';
module.exports.APPLY_PS_CORRECTION_SUCCESS = 'APPLY_PS_CORRECTION_SUCCESS';

module.exports.GET_CONSUMPTION_REPORT_REQUEST = 'GET_CONSUMPTION_REPORT_REQUEST';
module.exports.GET_CONSUMPTION_REPORT_SUCCESS = 'GET_CONSUMPTION_REPORT_SUCCESS';

module.exports.GET_APPROVERS_REQUEST = 'GET_APPROVERS_REQUEST';
module.exports.GET_APPROVERS_SUCCESS = 'GET_APPROVERS_SUCCESS';
module.exports.GET_APPROVERS_FAILURE = 'GET_APPROVERS_FAILURE';

module.exports.CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
module.exports.CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';

module.exports.GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
module.exports.GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
module.exports.GET_VERSION_FAILURE = 'GET_VERSION_FAILURE';

module.exports.GET_VERSION_LIST_REQUEST = 'GET_VERSION_LIST_REQUEST';
module.exports.GET_VERSION_LIST_SUCCESS = 'GET_VERSION_LIST_SUCCESS';
module.exports.GET_VERSION_LIST_FAILURE = 'GET_VERSION_LIST_FAILURE';

module.exports.UPDATE_TASK_INFO_REQUEST = 'UPDATE_TASK_INFO_REQUEST';
module.exports.UPDATE_TASK_INFO_SUCCESS = 'UPDATE_TASK_INFO_SUCCESS';
module.exports.COLLABORATOR_UPDATE_TASK_INFO = 'COLLABORATOR_UPDATE_TASK_INFO';

module.exports.COLLABORATOR_UPDATE_TASK_VALUES = 'COLLABORATOR_UPDATE_TASK_VALUES';

module.exports.UPDATE_TASK_ORDER_REQUEST = 'UPDATE_TASK_ORDER_REQUEST';
module.exports.UPDATE_TASK_ORDER_SUCCESS = 'UPDATE_TASK_ORDER_SUCCESS';
module.exports.COLLABORATOR_UPDATE_TASK_ORDER = 'COLLABORATOR_UPDATE_TASK_ORDER';

module.exports.UPDATE_TASK_LEVEL_AMOUNT_REQUEST = 'UPDATE_TASK_LEVEL_AMOUNT_REQUEST';
module.exports.UPDATE_TASK_LEVEL_AMOUNT_SUCCESS = 'UPDATE_TASK_LEVEL_AMOUNT_SUCCESS';
module.exports.COLLABORATOR_UPDATE_TASK_LEVEL_AMOUNT = 'COLLABORATOR_UPDATE_TASK_LEVEL_AMOUNT';

module.exports.CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
module.exports.CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
module.exports.COLLABORATOR_CREATE_TASK = 'COLLABORATOR_CREATE_TASK';

module.exports.DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
module.exports.DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
module.exports.COLLABORATOR_DELETE_TASK = 'COLLABORATOR_DELETE_TASK';

module.exports.CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST';
module.exports.CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
module.exports.COLLABORATOR_CREATE_AREA = 'COLLABORATOR_CREATE_AREA';

module.exports.UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST';
module.exports.UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
module.exports.COLLABORATOR_UPDATE_AREA = 'COLLABORATOR_UPDATE_AREA';

module.exports.MAKE_TASK_INTERCOMPANY_REQUEST = 'MAKE_TASK_INTERCOMPANY_REQUEST';
module.exports.MAKE_TASK_INTERCOMPANY_SUCCESS = 'MAKE_TASK_INTERCOMPANY_SUCCESS';

module.exports.INVITE_INTERCOMPANY_SUPPLIER_REQUEST = 'INVITE_INTERCOMPANY_SUPPLIER_REQUEST';
module.exports.INVITE_INTERCOMPANY_SUPPLIER_SUCCESS = 'INVITE_INTERCOMPANY_SUPPLIER_SUCCESS';

module.exports.GET_VERSION_COMPARISON_REQUEST = 'GET_VERSION_COMPARISON_REQUEST';
module.exports.GET_VERSION_COMPARISON_SUCCESS = 'GET_VERSION_COMPARISON_SUCCESS';

module.exports.GET_INTERCOMPANY_TASK_INFO_REQUEST = 'GET_INTERCOMPANY_TASK_INFO_REQUEST';
module.exports.GET_INTERCOMPANY_TASK_INFO_SUCCESS = 'GET_INTERCOMPANY_TASK_INFO_SUCCESS';

module.exports.CREATE_BUDGET_REQUEST = 'CREATE_BUDGET_REQUEST';
module.exports.CREATE_BUDGET_SUCCESS = 'CREATE_BUDGET_SUCCESS';

module.exports.GET_PS_CORRECTION_REQUEST = 'GET_PS_CORRECTION_REQUEST';
module.exports.GET_PS_CORRECTION_SUCCESS = 'GET_PS_CORRECTION_SUCCESS';

module.exports.CLOSE_CREATE_BUDGET_MODAL = 'CLOSE_CREATE_BUDGET_MODAL';

module.exports.UPDATE_INFO_TOTAL = 'UPDATE_INFO_TOTAL';

module.exports.INSERT_JOB_TITLE_REQUEST = 'INSERT_JOB_TITLE_REQUEST';
module.exports.INSERT_JOB_TITLE_SUCCESS = 'INSERT_JOB_TITLE_SUCCESS';
module.exports.COLLABORATOR_INSERT_JOB_TITLE = 'COLLABORATOR_INSERT_JOB_TITLE';

module.exports.DELETE_JOB_TITLE_REQUEST = 'DELETE_JOB_TITLE_REQUEST';
module.exports.DELETE_JOB_TITLE_SUCCESS = 'DELETE_JOB_TITLE_SUCCESS';
module.exports.COLLABORATOR_DELETE_JOB_TITLE = 'COLLABORATOR_DELETE_JOB_TITLE';

module.exports.INSERT_PRODUCTS_REQUEST = 'INSERT_PRODUCTS_REQUEST';
module.exports.INSERT_PRODUCTS_SUCCESS = 'INSERT_PRODUCTS_SUCCESS';

module.exports.UPDATE_PRICELIST_REQUEST = 'UPDATE_PRICELIST_REQUEST';
module.exports.UPDATE_PRICELIST_SUCCESS = 'UPDATE_PRICELIST_SUCCESS';
module.exports.COLLABORATOR_CHANGE_PRICELIST = 'COLLABORATOR_CHANGE_PRICELIST';

module.exports.UPDATE_PRICE_LIST_AREA_REQUEST = 'UPDATE_PRICE_LIST_AREA_REQUEST';
module.exports.UPDATE_PRICE_LIST_AREA_SUCCESS = 'UPDATE_PRICE_LIST_AREA_SUCCESS';

module.exports.SET_HEADER_REFERENCE = 'SET_HEADER_REFERENCE';
module.exports.SET_TOTAL_SUMMARY_REFERENCE = 'SET_TOTAL_SUMMARY_REFERENCE';
module.exports.SET_TOTAL_AREA_SUMMARY_REFERENCE = 'SET_TOTAL_AREA_SUMMARY_REFERENCE';
module.exports.SET_CONTINGENCY_AREA_REFERENCE = 'SET_CONTINGENCY_AREA_REFERENCE';
module.exports.SET_AREA_SUMMARY_REFERENCE = 'SET_AREA_SUMMARY_REFERENCE';

module.exports.SET_BUDGET_LEVEL_VISIBLE = 'SET_BUDGET_LEVEL_VISIBLE';
module.exports.SET_SCROLLING_COMPONENT_AMOUNT = 'SET_SCROLLING_COMPONENT_AMOUNT';
module.exports.UPDATE_BUDGET_LEVEL_VISIBLE_SUCCESS = 'UPDATE_BUDGET_LEVEL_VISIBLE_SUCCESS';

module.exports.AREA_COLLAPSE = 'AREA_COLLAPSE';
module.exports.AREA_EXPAND = 'AREA_EXPAND';

module.exports.BASELINE_UPDATE_SUCCESS = 'BASELINE_UPDATE_SUCCESS';
module.exports.BASELINE_UPDATE_REQUEST = 'BASELINE_UPDATE_REQUEST';
