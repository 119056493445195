const React = require('react');
const $ = require('jquery');
const Table = require('../../../../../common/react/Table2/Table2.react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const TableBody = require('../../../../../common/react/Table2/TableBody.react');
const Row = require('../containers/Expense');
const Search = require('../containers/ExpensesSearch');
const Loader = require('../../../../../common/react/Loader/Loader.react');

module.exports = class ExpensesList extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return "There's nothing here! What about adding a new expense?";
  }

  getList() {
    return this.props.expenses.map((exp) => <Row key={exp.id} expense={exp} />);
  }

  getSearch() {
    return (
      <Search filter={this.filter.bind(this)} />
    );
  }

  updateTableHeight() {
    this.setState({ tableHeight: ExpensesList.getTableMaxHeight() });
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const offset = (page - 1) * size;
      this.props.getItems(offset, size, this.props.keyword, this.props.typeFilter);
    }
  }

  filter(keyword) {
    if (!this.props.isWaiting) {
      const offset = 0;
      this.props.getItems(offset, this.props.pageLimit, keyword, this.props.typeFilter);
    }
  }

  render() {
    return (
      <Table columns={8} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell className="expenses-table__joc-badge" />
            <TableCell className="expenses-table__category">Category</TableCell>
            <TableCell className="expenses-table__person">Owner</TableCell>
            <TableCell className="expenses-table__project">Project</TableCell>
            <TableCell className="expenses-table__date">Date</TableCell>
            <TableCell className="expenses-table__notes" />
            <TableCell className="expenses-table__value">Reimbursement</TableCell>
            <TableCell className="expenses-table__actions" />
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.props.hasMore}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
