const React = require('react');
const PropTypes = require('prop-types');
const BusinessUnitService = require('../../../services/BusinessUnitService');
const MultipleSelectFilter = require('../MultipleSelectFilter/MultipleSelectFilter.react');

const BusinessUnitSelectFilter = ({
  selectedValues,
  onApply,
}) => {
  const businessUnitService = new BusinessUnitService();
  /**
   * Save values in session storage.
   * @param values
   */
  const setGlobalUnits = (values) => {
    sessionStorage.setItem('business-units', JSON.stringify(values));
  };

  function onApplyClicked(name, values) {
    setGlobalUnits(values);
    onApply(name, values);
  }

  /**
   * Business units formatted to be used in MultipleSelectFilter.
   * @type {{children: [], label: *, value: *}[]}
   */
  const units = businessUnitService.getForCompany()
    .map((unit) => ({
      label: unit.name,
      value: unit.id,
      children: [],
    }));

  const acceptedValues = units.map((unit) => unit.value);

  const availableOptions = {
    label: 'Select all',
    value: 'all',
    children: units,
  };

  return (
    <MultipleSelectFilter className="wethod-business-unit-filter"
      name="business-unit"
      label="Filter BU"
      menuTitle="Business unit"
      acceptedValues={acceptedValues}
      availableOptions={availableOptions}
      values={selectedValues}
      onApply={onApplyClicked} />
  );
};

BusinessUnitSelectFilter.defaultProps = {
  selectedValues: [],
};

BusinessUnitSelectFilter.propTypes = {
  /**
   * List of currently selected values.
   */
  selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  /**
   * Function to call when user clicks "Apply" button.
   * @param {string} name input name
   * @param {string[]} values list of selected values
   */
  onApply: PropTypes.func.isRequired,
};

module.exports = BusinessUnitSelectFilter;
