const React = require('react');
const Trend = require('../containers/ColumnTrend');

const AgeTrend = ({ data, average }) => {
  const colors = ['#FED06E', '#FF6E54', '#27B791', '#7EB6EA', '#C89AF8', '#444E86', '#E1E1E1'];
  const avgColor = '#D94F6B';

  const formatValue = (value) => Math.round(value * 10) / 10;

  const yAxis = [
    {
      min: 0,
      allowDecimals: false,
      title: {
        text: 'Units',
        style: {
          textTransform: 'uppercase',
        },
      },
      gridLineWidth: 0,
    }, {
      id: 'avg',
      min: 0,
      max: 75,
      allowDecimals: false,
      opposite: true,
      title: {
        text: 'Average age',
        style: {
          color: 'rgb(219, 77, 105)',
          textTransform: 'uppercase',
        },
      },
      gridLineWidth: 0,
    }];

  const avgSeries = {
    name: 'Avg. age',
    values: average.map((avg) => ({ ...avg, value: formatValue(avg.value) })),
    color: avgColor,
    options: {
      yAxis: 'avg',
      zIndex: 100,
      type: 'spline',
      marker: {
        enabled: true,
        symbol: 'circle',
      },
    },
  };

  const dataWithColors = [avgSeries]
    .concat(data.map((series, index) => ({ ...series, color: colors[index] })));

  function tooltipFormatter(tooltip) {
    const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);
    // Add horizontal line before avg series
    defaultTooltip.splice(2, 0, '<hr style="margin-top: 4px; margin-bottom: 4px;">');
    // Add horizontal line between avg series and tooltip title
    defaultTooltip.splice(1, 0, '<hr style="margin-top: 4px; margin-bottom: 4px;">');
    return defaultTooltip;
  }

  return (
    <Trend data={dataWithColors} yAxis={yAxis} tooltipFormatter={tooltipFormatter} />
  );
};

module.exports = AgeTrend;
