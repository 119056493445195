const { connect } = require('react-redux');
const TableSearch = require('../../../../../../../common/react/Table2/TableSearch.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  placeholder: state.type === 'people' ? 'Search by name or company' : 'Search by name, acronym or group',
});

const mapDispatchToProps = (dispatch) => ({
  filter: (keyword) => dispatch(actions.filterClients(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TableSearch);
