const React = require('react');
const Level = require('./JobTitleLevel.react');
const PlaceholderLevel = require('./PlaceholderLevel.react');
const Levels = require('../Cell/LevelsCell.react');

const JobTitleLevels = ({ items, editable, saveLevel }) => {
  const levels = items.map((level) => {
    const { id } = level;

    if (level.placeholder) {
      return <PlaceholderLevel key={id} />;
    }

    return (
      <Level key={id}
        previousValue={level.was.days}
        value={level.days}
        readOnly={!editable}
        id={id}
        save={saveLevel} />
    );
  });

  return (
    <Levels className="wethod-budget-job-title__levels">{levels}</Levels>
  );
};

module.exports = JobTitleLevels;
