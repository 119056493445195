const React = require('react');
const ModalBody = require('../../../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooter = require('../../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBodyBlock = require('../../../../../../../../../common/react/Modal/ModalBodyBlock.react');

const DraftStatusModalContent = ({ closeModal, isPending, draftBudget }) => {
  const isSaving = isPending('change-status');
  const feedback = isSaving ? 'Saving...' : '';

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>If you proceed your budget will go back to draft.</ModalBodyBlock>
        <ModalBodyBlock>When you bring a budget back to draft, the price, rate, and name of the
          levels are updated with the latest ones available for the company.
        </ModalBodyBlock>
        <ModalBodyBlock>Do you want to continue?</ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={feedback}>
        <ModalFooterAction onClick={closeModal} disabled={isSaving}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={draftBudget}
          disabled={isSaving}>
          Yes
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = DraftStatusModalContent;
