'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.SelectView = Marionette.LayoutView.extend({
    /**
     *  _chosenOptionView : shorthand for the ChosenTooltipOptionView attached to this SelectView.
     *  _availableOptionsView : shorthand for the TooltipView attached to this SelectView.
     *  _clickable : false if the button is disabled.
     */
    _chosenOptionView: null,
    _availableOptionsView: null,
    _clickable: true,
    template: '#tooltipSelectTemplate',
    regions: {
      chosenOption: '[data-region=\'chosenOption\']',
      availableOptions: '[data-region=\'tooltip\']',
    },
    ui: {
      selectButton: '[data-region=\'chosenOption\']',
      tooltip: '[data-region=\'tooltip\']',
    },
    events: {
      'click @ui.selectButton': 'toggleTooltip',
    },
    onRender: function () {
      this.attachTooltipView();
      this.attachSelectButtonView();

      this._availableOptionsView.on('tooltip:item:selected', this.onTooltipOptionSelected, this);
    },
    attachSelectButtonView: function () {
      var firstTooltipOptionModel = this.collection.at(0);
      var chosenOptionModel = new RuleManager
        .TooltipOptionModel(firstTooltipOptionModel.attributes);
      var chosenOptionView = new RuleManager.ChosenTooltipOptionView({ model: chosenOptionModel });
      this.chosenOption.show(chosenOptionView);
      this._chosenOptionView = this.chosenOption.currentView;
    },
    attachTooltipView: function () {
      var tooltipView = new RuleManager.TooltipView({ collection: this.collection });
      this.availableOptions.show(tooltipView);
      this._availableOptionsView = this.availableOptions.currentView;
    },
    toggleTooltip: function () {
      if (this._clickable === true) {
        this.ui.tooltip.toggleClass('isVisible');
      }
    },
    /**
     * @param optionModel
     * @param toggle If boolean false than do not toggle the tooltip, ANY other value are
     *   considered truthy
     */
    onTooltipOptionSelected: function (optionModel, toggle) {
      if (toggle !== false) {
        this.toggleTooltip();
      }

      this._chosenOptionView.model.set(optionModel);
      this.trigger('tooltip:item:selected', optionModel);
    },
    setChosenOption: function (optionModel) {
      this._chosenOptionView.model.set(optionModel);
    },
    getChosenOption: function () {
      return this._chosenOptionView.model.get('name');
    },
    disable: function () {
      this._clickable = false;
      this.ui.selectButton.addClass('disabled');
    },
    enable: function () {
      this._clickable = true;
      this.ui.selectButton.removeClass('disabled');
    },
  });
});
