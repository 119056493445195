'use strict';

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette, $, _) {
  this.startWithParent = false;

  var API = {
    showBubblesReport: function (idTour) {
      ReportApp.Report.Controller.showReport(idTour);
    },
    showBubblesInvoiceReport: function () {
      ReportApp.Controller.showBubblesInvoice();
    },
    showPipelineForecastReport: function () {
      ReportApp.threeYP.Controller.showPipelineForecastReport();
    },
    showProjectReport: function (idProject, idTour) {
      ReportApp.Detail.Controller.showReportDetail(idProject, idTour);
    },
    showInvoicedReport: function () {
      ReportApp.Invoice.Controller.showInvoicedReport();
    },
    showPeople: function () {
      ReportApp.People.Controller.showPeople();
    },
    showTimesheetsList: function () {
      ReportApp.TimesheetsList.Controller.show();
    },
    showTimesheetChangelog: function () {
      ReportApp.TimesheetChangelog.Controller.show();
    },
    showProductionValue: function () {
      ReportApp.Controller.showProductionValue();
    },
    showProjectChangelog: function (options) {
      ReportApp.Controller.showProjectChangelog(options);
    },
    showBudgetChangelog: function (options) {
      ReportApp.Controller.showBudgetChangelog(options);
    },
    showSalesOverview: function (options) {
      ReportApp.Controller.showSalesOverview(options);
    },
    showBacklog: function (options) {
      ReportApp.Controller.showBacklog(options);
    },
    showReviewChangelog: function (options) {
      ReportApp.Controller.showReviewChangelog(options);
    },
    showTimesheetRoadrunner: function (options) {
      ReportApp.Controller.showTimesheetRoadrunner(options);
    },
    showDemographics: function (options) {
      ReportApp.Controller.showDemographics(options);
    },
    showChargeability: function (options) {
      ReportApp.Controller.showChargeability(options);
    },
    showKudos: function (options) {
      ReportApp.Controller.showKudos(options);
    },
    showKnowledge: function (options) {
      ReportApp.Controller.showKnowledge(options);
    },
  };

  ReportApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'report');

    if (!_.isUndefined(options.idProject)) {
      API[options.foo](options.idProject, options.idTour);
    } else {
      API[options.foo](options.idTour);
    }
  });
});
