const React = require('react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const NumericField = require('../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');

const InputCell = ({
  readOnly, value, name, label, disabled,
  onBlur, onChange, style, className,
}) => (
  <TableCell style={style} className={className}>
    <NumericField readOnly={readOnly}
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      name={name}
      label={label}
      precision={2} />
  </TableCell>
);

module.exports = InputCell;
