const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  getUserPermissions() {
    const defer = $.Deferred();

    $.when(this.getMenuPermissions())
      .done((permission) => {
        defer.resolve({
          ...permission,
          group: permission.group !== null,
        });
      });

    return defer.promise();
  },
  getMenuPermissions() {
    const deferred = new $.Deferred();
    $.ajax({
      url: `${APIURL}/authorization/settings/menu`,
      type: 'GET',
      crossDomain: true,
      xhrFields: { withCredentials: true },
      success(response) {
        deferred.resolve(response.data);
      },
    });
    return deferred;
  },
});
