const constants = require('./constants');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');

class SuppliersReducer {
  constructor(state) {
    this.businessUnitService = new BusinessUnitService();

    const categoriesWithTags = state.categories.map((category) => ({
      ...category,
      tags: state.tags.filter((tag) => tag.category === category.id),
    }));
    this.state = {
      ...state,
      pageLimit: 0,
      pageOffset: 0,
      hasMorePages: true,
      suppliers: [],
      keyword: '', // search keyword
      waitingFor: [], // contains a key for each pending request
      focusedSupplier: null,
      serverErrorMessage: null,
      showSidebar: false,
      modalToShow: null,
      modalData: null,
      sortBy: {
        col: 'name',
        order: 'asc',
      },
      categoriesWithTags,
      buEnabled: this.businessUnitService.isEnabled(),
      buFilter: this.businessUnitService.getLastSelected(),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          focusedSupplier: action.item,
          showSidebar: true,
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          focusedSupplier: null,
          showSidebar: false,
        };
      }
      case constants.ADD_SUPPLIER_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-supplier'),
          serverErrorMessage: null, // Reset error
        };
      }
      case constants.ADD_SUPPLIER_SUCCESS: {
        const updatedSuppliers = state.suppliers.concat(action.item);
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-supplier'),
          suppliers: updatedSuppliers,
          focusedSupplier: action.item,
        };
      }
      case constants.ADD_SUPPLIER_ERROR: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-supplier'),
          serverErrorMessage: action.message,
        };
      }
      case constants.GET_SUPPLIERS_REQUEST: {
        return {
          ...state,
          keyword: action.keyword,
          pageLimit: action.limit,
          pageOffset: action.offset,
          sortBy: action.sortBy,
          buFilter: action.bu,
          hasMorePages: true,
          suppliers: action.offset ? state.suppliers : [],
          waitingFor: state.waitingFor.concat('get-suppliers'),
        };
      }
      case constants.GET_SUPPLIERS_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.suppliers.length === state.pageLimit,
          suppliers: state.suppliers.concat(action.suppliers),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-suppliers'),
        };
      }
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.UPDATE_SUPPLIER_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-supplier'),
        };
      }
      case constants.UPDATE_SUPPLIER_SUCCESS: {
        const updatedSuppliers = state.suppliers.map((supplier) => (supplier.id === action.item.id
          ? { ...supplier, ...action.item }
          : supplier));
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-supplier'),
          suppliers: updatedSuppliers,
          focusedSupplier: action.item,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = SuppliersReducer;
