'use strict';

/* eslint-disable default-case,max-len */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  /**
   * [ dato un modello di widget ne costruisce l'endpoint ]
   * @param  {[Backbone model]} model [modello del widget]
   * @return {[String]}       [url su cui fare la chiamata per avere i dati dell'utente di quel
   *   widget]
   */
  this.getEndpoint = function (model) {
    var endpoint = '';
    switch (model.get('key')) {
      case 'sales-funnel':
      case 'sparkline-product':
        endpoint = model.get('endpoint') + '?filter=' + model.get('config').active_filter.name + '&value=' + model.get('config').active_filter.value;
        break;
      case 'invoices':
        var dateRange = '&from=' + moment()
          .subtract(3, 'months')
          .format('YYYY-MM') + '&to=' + moment().format('YYYY-MM');
        endpoint = model.get('endpoint') + '?filter=' + model.get('config').active_filter.name + '&value=' + model.get('config').active_filter.value + dateRange;
        break;
      default:
        endpoint = model.get('endpoint');
        break;
    }
    return endpoint;
  };

  this.getBubblesChartSeries = function (data, dataType, filter, viewObj) {
    var bubbles = {
      series: [],
      maxYAxis: 100,
    };

    _.each(data, function (info) {
      var serie = {};
      if (!_.isUndefined(info.budget) && !_.isEmpty(info.budget) && !_.isNull(info.budget.id)) {
        // se il progetto non è archiviato
        if (!info.project.archived) {
          var showThis = true;
          // filtro i progetti
          var type = (filter.type !== 'project type') ? filter.type : 'project_label';
          switch (type) {
            case 'pm':
            case 'account':
            case 'client':
            case 'project_label':
              if (_.isNull(info[type])) {
                showThis = false;
              } else if (info[type].id !== filter.value) {
                showThis = false;
              }
              break;
            case 'me':
              var userId = Wethod.userInfo.get('employee_id');
              if (info.pm.id !== userId && (!info.account || info.account.id !== userId)) {
                showThis = false;
              }
              break;
          }

          if (showThis) {
            if (parseInt(info[dataType]) > bubbles.maxYAxis) {
              bubbles.maxYAxis = parseInt(info[dataType]);
            }

            serie.name = info.project.name + ' <br> ' + info.client.name;
            switch (dataType) {
              case 'actual_invoice':
                serie.color = (info.progress >= info[dataType] ? '#d03557' : '#4ED88D');
                break;
              case 'actual':
                serie.color = (info.progress >= info[dataType] ? '#1390ee' : '#d03557');
                break;
            }

            serie.info = info;
            serie.data = [{
              x: parseFloat(info.progress),
              y: parseFloat(info[dataType]),
              z: parseFloat(info.budget.suggested_net_price || 0),
              id: info.project.id,
              name: info.project.name + ' - ' + info.client.name,
              dataLabels: {
                crop: false,
                enabled: true,
                color: 'black',
                formatter: function () {
                  var subClientName = (!_.isNull(info.client.acronym) && !_.isUndefined(info.client.acronym)) ? info.client.acronym : info.client.name;
                  var subJobOrder = (!_.isNull(info.project.job_order) && !_.isUndefined(info.project.job_order)) ? info.project.job_order : info.project.name;

                  subClientName = subClientName.substring(0, 3);
                  subJobOrder = subJobOrder.substring(subJobOrder.length - 3, subJobOrder.length);

                  var label = '';
                  if (subClientName.charAt(subClientName.length) === '_' || subJobOrder.charAt(0) === '_') {
                    label = subClientName + subJobOrder;
                  } else {
                    label = subClientName + '_' + subJobOrder;
                  }

                  return label.toLowerCase();
                },
              },
              events: {
                click: function (e) {
                  e.stopPropagation();

                  var href = '#project/' + this.id + '/report';
                  var lbels = this.name.split(' - ');

                  viewObj.ui.footerEl.attr({ href: href });

                  viewObj.ui.footerInfoLabelEl.html(lbels[1]);
                  viewObj.ui.footerProjectLabelEl.html(lbels[0]);

                  viewObj.ui.footerEl.addClass('isVisible');
                },
              },
            }];

            bubbles.series.push(serie);
          }
        }
      }
    });

    var divider = {
      name: '',
      enableMouseTracking: false,
      lineWidth: 1,
      type: 'line',
      marker: { enabled: false },
      color: '#B8B6B4',
      data: [
        [0, 0],
        [120, 120],
      ],
    };

    bubbles.series.push(divider);

    return bubbles;
  };

  // ORDER
  this.orderDashboard = function (data) {
    Wethod.request('save:user:widget:position', data);
  };

  // SEARCH
  this.dashboardAutocomplete = function (viewObj, on, search) {
    search = search.replace(' ', ',');

    var options = {
      search: search,
      on: on,
    };

    // loading
    var searchFeedbackTemplate = new DashboardApp.WidgetSearchLoadingTemplateView();
    viewObj.ui.wrapTooltipHintEl.html(searchFeedbackTemplate.render().$el);

    var hintRequest = Wethod.request('get:dashboard:autocomplete', options);
    $.when(hintRequest).done(function (hintResponse) {
      if (!_.isUndefined(hintResponse)) {
        if (hintResponse.length !== 0) {
          var widgetSalesFunnelHintsTemplate = new DashboardApp.WidgetSalesFunnelHintsCollectionView({
            collection: hintResponse,
            childViewOptions: {
              parentView: viewObj,
            },
          });

          viewObj.ui.wrapTooltipHintEl.html(widgetSalesFunnelHintsTemplate.render().$el);
        } else {
          // not found
          searchFeedbackTemplate = new DashboardApp.WidgetSearchNotFoundTemplateView();
          viewObj.ui.wrapTooltipHintEl.html(searchFeedbackTemplate.render().$el);
        }
      }
    });
  };
});
