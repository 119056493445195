/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');
const EventType = require('./EventType.react');

class EventTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: this.props.dropdown,
    };

    this.getEventTypes = this.getEventTypes.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.getDropdown = this.getDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  getEventTypes() {
    return this.props.types.map((type, index) => (
      <EventType
        key={index}
        type={type}
        select={this.props.handleTypeChange}
        closeDropdown={this.toggleDropdown} />
    ));
  }

  toggleDropdown() {
    this.setState({ showDropdown: !this.state.showDropdown });
  }

  handleButtonClick() {
    this.toggleDropdown();
  }

  handleDeleteClick() {
    this.props.delete();
  }

  getDropdown() {
    if (this.state.showDropdown) {
      return (
        <div>
          <ul className="type-select__dropdown">
            {this.getEventTypes()}
            <li className="event-type--delete" onClick={this.handleDeleteClick}>
              <div className="type-delete__icon" />
              <div className="type-delete__name">remove event</div>
            </li>
          </ul>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="type-select">
        <div className="type-select__current" onClick={this.handleButtonClick}>
          <div className={`event-type__icon icon--${this.props.selected.name}`} />
          <div className="event-type__name">{this.props.selected.name}</div>
        </div>
        {this.getDropdown()}
      </div>
    );
  }
}

module.exports = EventTypeSelect;
