const { connect } = require('react-redux');
const Component = require('../components/RecurringModeButton.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  enabled: state.recurring_mode_enabled,
});

const mapDispatchToProps = (dispatch) => ({
  toggleRecurringMode: () => dispatch(actions.toggleRecurringMode()),
  closeProjectInfo: () => dispatch(actions.closeProjectInfo()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
