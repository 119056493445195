const React = require('react');
const BriefingActions = require('../../common/components/BriefingActions.react');
const RefreshButton = require('../../common/components/RefreshButton.react');

module.exports = class Actions extends React.Component {
  handleRefreshClick() {
    this.props.refreshData(this.props.date, this.props.selectedCompany);
  }

  render() {
    return (
      <BriefingActions>
        <RefreshButton onClick={this.handleRefreshClick.bind(this)} />
      </BriefingActions>
    );
  }
};
