module.exports.UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';

module.exports.FILTER_EMPLOYEES = 'FILTER_EMPLOYEES';
module.exports.FILTER_EMPLOYEES_LEVEL = 'FILTER_EMPLOYEES_LEVEL';

module.exports.SORT_EMPLOYEES = 'SORT_EMPLOYEES';

module.exports.LOAD_EMPLOYEES = 'LOAD_EMPLOYEES';

module.exports.GET_CHARGEABILITY_REQUEST = 'GET_CHARGEABILITY_REQUEST';
module.exports.GET_CHARGEABILITY_SUCCESS = 'GET_CHARGEABILITY_SUCCESS';
