module.exports.UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';

module.exports.SHOW_TARGET_MODAL = 'SHOW_TARGET_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.GET_TRENDS_REQUEST = 'GET_TRENDS_REQUEST';
module.exports.GET_TRENDS_SUCCESS = 'GET_TRENDS_SUCCESS';

module.exports.SAVE_TARGET_REQUEST = 'SAVE_TARGET_REQUEST';
module.exports.SAVE_TARGET_SUCCESS = 'SAVE_TARGET_SUCCESS';
