const React = require('react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');

const DisabledView = ({ enable }) => {
  function handleClick() {
    const enabled = true;
    enable(enabled);
  }

  return (
    <div>
      <Button onClick={handleClick}
        className="company-settings__button company-settings__button--blue company-settings-margin-top-xl">
        Enable
      </Button>
    </div>
  );
};

module.exports = DisabledView;
