const React = require('react');
const moment = require('moment');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const Footer = require('../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const MonthPicker = require('../../../../../../common/react/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Icon = require('../../../../../../common/react/Icon/Icon.react');
const InlineFields = require('../InlineFields.react');
const status = require('../../segmentsStatus');

module.exports = class DataReviewModal extends React.Component {
  static getFormattedMonth(date) {
    return date ? moment(date).format('MMM YYYY') : '';
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: this.props.startDate,
      endDate: null,
    };
  }

  handleDateChange(e) {
    const formattedDate = e.target.value ? moment(e.target.value).format('YYYY-MM') : null;
    this.setState({
      endDate: formattedDate,
    });
  }

  handleSave() {
    const updatedSegment = {
      status: status.IN_REVIEW,
      from: this.state.startDate,
      to: this.state.endDate,
    };
    this.props.save([updatedSegment]);
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : null;
  }

  render() {
    return (
      <Modal title="Start data review" onClose={this.props.closeModal}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              Choose a time range to analyze before applying data freezing preferences:
            </ModalBodyBlock>
            <ModalBodyBlock>
              <InlineFields>
                <TextField readOnly
                  name="start_date"
                  label="From"
                  value={DataReviewModal.getFormattedMonth(this.state.startDate)}
                  onChange={this.handleDateChange.bind(this)} />
                <MonthPicker name="end_date"
                  label="To"
                  id="segment-end-date"
                  value={this.state.endDate}
                  onChange={this.handleDateChange.bind(this)}
                  disableBeforeEqual={this.state.startDate}
                  disableAfterEqual={this.props.endDate}
                  suffix={<Icon icon="calendar" label="end-date-icon" />} />
              </InlineFields>
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal} disabled={this.props.isSaving}>
              Dismiss
            </FooterAction>
            <FooterAction onClick={this.handleSave.bind(this)}
              disabled={this.props.isSaving || !this.state.endDate}>
              Confirm
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
};
