const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../Input/Input.react');

require('./style.scss');

const Switch = ({ checked, onChange, name, id, label, disabled, className, labelPlacement }) => {
  const getToggleClassName = () => {
    let style = checked ? 'toggle-button' : 'toggle-button toggle-button--off';

    if (disabled) {
      style += ' disabled';
    }

    return style;
  };

  const actualClassName = `wethod-switch wethod-switch--label-${labelPlacement} ${className}`;

  return (
    <label htmlFor={id}
      className={actualClassName}>
      <div className={getToggleClassName()}>
        <div className="toggle-button__pin" />
      </div>
      <Input id={id}
        aria-checked={checked}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled} />
      <span className="wethod-switch__label">{label}</span>
    </label>
  );
};

Switch.defaultProps = {
  checked: false,
  onChange: null,
  disabled: false,
  label: '',
  className: '',
  labelPlacement: 'start',
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  /**
   * Used to group checkbox. All buttons in the same group must have the same name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Used for accessibility and showed to the user.
   */
  label: PropTypes.node,
  /**
   * It says whether or not this radio button is the default-selected item in the group.
   */
  checked: PropTypes.bool,
  /**
   * Function to call when value changes.
   * @param event {HtmlEvent}
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Where to place label relative to input.
   */
  labelPlacement: PropTypes.oneOf(['start', 'end']),
};

module.exports = Switch;
