/* eslint-disable react/jsx-no-bind */
const React = require('react');

module.exports = class TypeSwitcher extends React.Component {
  getItemClassName(key) {
    let name = 'wethod-tab-switcher__item';
    if (key === this.props.current) name += ' wethod-tab-switcher__item--selected';
    return name;
  }

  getClassName() {
    let name = 'wethod-tab-switcher';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <button type="button"
          onClick={() => { this.props.handleClick('days'); }}
          className={this.getItemClassName('days')}>
          Days
        </button>
        <button type="button"
          onClick={() => { this.props.handleClick('weeks'); }}
          className={this.getItemClassName('weeks')}>
          Weeks
        </button>
        <button type="button"
          onClick={() => { this.props.handleClick('months'); }}
          className={this.getItemClassName('months')}>
          Months
        </button>
        <button type="button"
          onClick={() => { this.props.handleClick('years'); }}
          className={this.getItemClassName('years')}>
          Years
        </button>
      </div>
    );
  }
};
