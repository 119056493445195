const { connect } = require('react-redux');
const Component = require('../../components/work-hour-capacity/WorkHourCapacityCreationRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-work-hour-capacity-')),
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: () => dispatch(actions.deleteNewWorkHourCapacity()),
  onSave: (workHourCapacity) => dispatch(actions.createWorkHourCapacity(workHourCapacity)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
