const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');

module.exports = Backbone.Collection.extend({
  initialize(options = {}) {
    const from = options.from ? `&from=${options.from}` : '';
    const to = options.to ? `&to=${options.to}` : '';
    const query = options.to || options.from ? '?' : '';

    this.url = `${APIURL}/backlog/${query}${from}${to}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((collection) => {
      defer.resolve(collection.toJSON());
    });

    return defer.promise();
  },
});
