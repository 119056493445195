/* eslint-disable react/no-array-index-key */
const React = require('react');
const Column = require('./CalendarColumn/CalendarColumn.react');

module.exports = class CalendarColumns extends React.Component {
  getColumns() {
    return this.props.columns.map((column, index) => (
      <Column
        key={index}
        type={this.props.type}
        date={column}
        index={index} />
    ));
  }

  render() {
    return (
      <div
        className="project-canvas-gantt__calendar-content"
        style={{ height: this.props.height }}>
        {this.getColumns()}
      </div>
    );
  }
};
