'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var SuppliersStore = require('../../../apps/core/modules/finance/suppliers/overview/store');
var SuppliersReducer = require('../../../apps/core/modules/finance/suppliers/overview/reducer');
var Suppliers = require('../../../apps/core/modules/finance/suppliers/overview/index');
var SupplierDetailStore = require('../../../apps/core/modules/finance/suppliers/detail/store');
var SuppliersDetailReducer = require('../../../apps/core/modules/finance/suppliers/detail/reducer');
var SupplierDetail = require('../../../apps/core/modules/finance/suppliers/detail/index');

Wethod.module('FinanceApp.Supplier', function (Supplier, Wethod, Backbone, Marionette, $, _) {
  // richiesta spostamento record da un supplier ad un altro
  dispatcher.on('finance:supplier:moveRecords', function (options) {
    var view = options.view;
    var model = options.model;
    // pulisco options dai campi che non servono per la request
    delete options.view;
    delete options.model;

    view.ui.feedbackField.text('Loading...').addClass('messageModal__feedback--success');

    // chiamo l'API per lo switch dei dati
    var switchRequest = Wethod.request('switch:finance:supplier:recors', options);
    $.when(switchRequest).done(function (switchResponse) {
      if (!_.isUndefined(switchResponse)) {
        // triggero l'evento per la cancellazione di un modello
        dispatcher.trigger('finance:supplier:deleteSupplier', model, view);
      } else {
        view.destroy();
      }
    });
  });

  // TODO da rimuovere dopo aver inserito la ricerca con il nuovo componente
  // richiesta autocompletamento sui supplier
  dispatcher.on('finance:supplier:serchSupplier', function (search, view, model) {
    var reg = new RegExp(' ', 'g');
    search = search.replace(reg, ',');

    var params = { like: search };

    // loading
    var searchFeedbackTemplate = new Supplier.AutocompleteLoadingTemplateView();
    view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('autocomplete:finance:supplier', params, model.get('id'));
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        // richiesta cancellata
        if (autocompleteResponse === 'aborted') {
          // loading
          searchFeedbackTemplate = new Supplier.AutocompleteLoadingTemplateView();
          view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
        } else if (_.isEmpty(autocompleteResponse)) {
          // not found
          searchFeedbackTemplate = new Supplier.AutocompleteEmptyRowItemView();
          view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
        } else {
          // show
          var autocompleteTemplate = new Supplier.AutocompleteRowsCollectionView({
            collection: autocompleteResponse,
          });
          view.ui.hintsRegion.html(autocompleteTemplate.render().$el);
        }
      } else {
        // not found
        searchFeedbackTemplate = new Supplier.AutocompleteEmptyRowItemView();
        view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
      }
    });
  });

  this.InitSupplier = function (financeLayout) {
    var supplierStatusEnabledRequest = Wethod.request('get:company:supplier-status:enabled');
    var supplierCategoryRequest = Wethod.request('get:company:supplier-tag-category');
    var supplierTagRequest = Wethod.request('get:company:supplier-tag');
    var settingsAuthorizationRequest = Wethod.request('get:settings:authorization');
    var supplierRequest = Wethod.request('get:finance:supplier');
    var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
    var countriesRequest = Wethod.request('get:countries');
    var provincesRequest = Wethod.request('get:provinces');
    $.when(supplierStatusEnabledRequest, supplierCategoryRequest,
      supplierTagRequest, settingsAuthorizationRequest, supplierRequest, paymentTermsRequest,
      countriesRequest, provincesRequest)
      .done(function (supplierStatusEnabled, supplierCategory,
        supplierTag, settingsPermission, supplierResponse, paymentTermsResponse,
        countriesResponse, provincesResponse) {
        var canEdit = Wethod.FinanceApp.getPermission('edit');
        var canEditSettings = settingsPermission.get('company');
        var canSeeRating = Wethod.FinanceApp.getPermission('view_rating');
        var paymentTerms = paymentTermsResponse.models ? paymentTermsResponse.toJSON() : [];
        var countries = countriesResponse || [];
        var provinces = provincesResponse || [];

        if (supplierStatusEnabled.get('status_enabled')) {
          var canSetStatus = Wethod.FinanceApp.getPermission('set_status');

          var supplierStatusRequest = Wethod.request('get:company:supplier-status');
          $.when(supplierStatusRequest).done(function (supplierStatus) {
            // renderizzo il template del modulo
            financeLayout.render();

            var reducerWrapper = new SuppliersReducer({
              canEdit: canEdit,
              canEditSettings: canEditSettings,
              canSetStatus: canSetStatus,
              canSeeRating: canSeeRating,
              statusEnabled: true,
              statuses: supplierStatus.toJSON(),
              categories: supplierCategory.toJSON(),
              tags: supplierTag.toJSON(),
              paymentTerms: paymentTerms,
              info: supplierResponse.attributes,
              countries: countries,
              provinces: provinces,
            });

            var store = SuppliersStore(reducerWrapper.reduxReducer);
            var element = React.createElement(Suppliers, { store: store });
            var container = document.getElementById('financeRoot');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          });
        } else {
          // renderizzo il template del modulo
          financeLayout.render();

          var reducerWrapper = new SuppliersReducer({
            canEdit: canEdit,
            canEditSettings: canEditSettings,
            statusEnabled: false,
            categories: supplierCategory.toJSON(),
            tags: supplierTag.toJSON(),
            paymentTerms: paymentTerms,
            canSeeRating: canSeeRating,
            info: supplierResponse.attributes,
            countries: countries,
            provinces: provinces,
          });

          var store = SuppliersStore(reducerWrapper.reduxReducer);
          var element = React.createElement(Suppliers, { store: store });
          var container = document.getElementById('financeRoot');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
        }
      });
  };

  this.InitSupplierDetail = function (financeLayout, supplierId) {
    var supplierStatusEnabledRequest = Wethod.request('get:company:supplier-status:enabled');
    var supplierCategoryRequest = Wethod.request('get:company:supplier-tag-category');
    var supplierTagRequest = Wethod.request('get:company:supplier-tag');
    var settingsAuthorizationRequest = Wethod.request('get:settings:authorization');
    var supplierRequest = Wethod.request('get:finance:supplier', supplierId);
    var supplierSelectProjectsRequest = Wethod.request('get:finance:supplier:select:projects', supplierId);
    var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
    var countriesRequest = Wethod.request('get:countries');
    var provincesRequest = Wethod.request('get:provinces');
    $.when(supplierStatusEnabledRequest, supplierCategoryRequest,
      supplierTagRequest, settingsAuthorizationRequest, supplierRequest,
      supplierSelectProjectsRequest, paymentTermsRequest,
      countriesRequest, provincesRequest)
      .done(function (supplierStatusEnabled, supplierCategory, supplierTag, settingsPermission,
        supplierResponse, supplierSelectProjectResponse, paymentTermsResponse,
        countriesResponse, provincesResponse) {
        if (_.isUndefined(supplierResponse)) { // no project with the given id
          Wethod.trigger('url:404:show');
        } else {
          var canEdit = Wethod.FinanceApp.getPermission('edit');
          var canEditSettings = settingsPermission.get('company');

          var canSetStatus = Wethod.FinanceApp.getPermission('set_status');
          var canSeeRating = Wethod.FinanceApp.getPermission('view_rating');
          var canEditRating = Wethod.FinanceApp.getPermission('edit_rating');
          var canDeleteOthersRating = Wethod.FinanceApp.getPermission('delete_rating_other');
          var paymentTerms = paymentTermsResponse.models ? paymentTermsResponse.toJSON() : [];
          var countries = countriesResponse || [];
          var provinces = provincesResponse || [];

          var supplierStatusRequest = Wethod.request('get:company:supplier-status');
          $.when(supplierStatusRequest).done(function (supplierStatus) {
            // renderizzo il template del modulo
            financeLayout.render();

            var reducerWrapper = new SuppliersDetailReducer({
              canEdit: canEdit,
              canEditSettings: canEditSettings,
              canSetStatus: canSetStatus,
              statusEnabled: supplierStatusEnabled.get('status_enabled') === true,
              statuses: supplierStatus.toJSON(),
              categories: supplierCategory.toJSON(),
              tags: supplierTag.toJSON(),
              paymentTerms: paymentTerms,
              countries: countries,
              provinces: provinces,
              info: supplierResponse.attributes,
              // Supplier projects used for Select input
              supplierSelectProjects: supplierSelectProjectResponse.toJSON(),
              canSeeRating: canSeeRating,
              canEditRating: canEditRating,
              canDeleteOthersRating: canDeleteOthersRating,
            });

            var store = SupplierDetailStore(reducerWrapper.reduxReducer);
            var element = React.createElement(SupplierDetail, { store: store });
            var container = document.getElementById('financeRoot');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          });
        }
      });
  };
});
