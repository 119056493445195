const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');

module.exports = class ChargeabilityTrend extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      chargeability: '#000000',
      target: '#F8A01C',
    };

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      chart: {
        type: 'line',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        padding: 5,
        style: {
          fontSize: '10px',
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      xAxis: {
        visible: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
            fillColor: null, // inherit from series
            lineWidth: 1,
            lineColor: null, // inherit from series
            symbol: 'circle',
          },
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.selectedLevel ? prevProps.selectedLevel.id : null;
    const currentId = this.props.selectedLevel ? this.props.selectedLevel.id : null;
    if (prevId !== currentId) {
      this.renderChart();
    }
  }

  getChartOptions() {
    const categories = this.getCategories();
    return {
      ...this.options,
      series: this.getSeries(categories),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1, // last category index, to force empty category to be shown anyway
        min: 0,
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries(categories) {
    const { data, target } = this.props;
    const chargeabilitySeries = this.getChargeability(data);
    const targetSeries = this.getTarget(target, categories);

    return [chargeabilitySeries, targetSeries];
  }

  getChargeability(data) {
    const series = data.map((period) => {
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(period.date);
      const value = Math.round(period.value * 10) / 10;
      return { ...period, x: index, y: value };
    });

    if (series.length < 2) {
      // Add marker to first point, when it is the only one in the series (to prevent it from not being shown)
      series[0] = {
        ...series[0],
        marker: { enabled: true, radius: 2 },
      };
    }

    return {
      name: 'Chargeability',
      data: series,
      color: this.colors.chargeability,
      zIndex: 1,
    };
  }

  /**
   * Add series like plotLine representing the target
   */
  getTarget(target, categories) {
    const data = categories.map(() => target);

    return {
      name: 'Target',
      data,
      color: this.colors.target,
      enableMouseTracking: false,
      dashStyle: 'longDash',
      zIndex: 2,
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="chargeability__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
