const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const SingleValueSlider = require('../SingleValueSlider.react');
const Menu = require('../Menu/Menu.react');
const DayPicker = require('../DayCalendar.react');

/**
 * A day selector component with slider to select the next/previous and monthly calendar to pick a
 * single day.
 */
class DaySelector extends React.Component {
  static formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  handleMenuClose() {
    this.setState({
      showMenu: false,
    });
  }

  handleDayClick(date) {
    if (!this.props.isWaiting) {
      const momentDate = moment(date);
      if (!momentDate.isSame(this.props.date, 'day')) {
        this.props.changeDate(momentDate);
      }
      this.handleMenuClose();
    }
  }

  handleCalendarButtonClick() {
    if (!this.props.isWaiting) {
      this.setState({
        showMenu: true,
      });
    }
  }

  handlePreviousClick() {
    if (!this.props.isWaiting) {
      const amount = this.isMonday() && this.props.disableWeekend ? 3 : 1;
      const prev = moment(this.props.date).subtract(amount, 'days');
      this.props.changeDate(prev);
    }
  }

  handleNextClick() {
    if (!this.props.isWaiting) {
      const amount = this.isFriday() && this.props.disableWeekend ? 3 : 1;
      const next = moment(this.props.date).add(amount, 'days');
      this.props.changeDate(next);
    }
  }

  getStyleClass() {
    let style = 'date-selector';
    if (this.props.isWaiting) {
      style += ' disabled';
    }
    return style;
  }

  getMenuBody() {
    return (
      <DayPicker startDate={this.props.date}
        disableWeekend={this.props.disableWeekend}
        showOutsideDays
        onDayClick={this.handleDayClick.bind(this)}
        getHolidays={this.props.getHolidays} />
    );
  }

  getCalendarButton() {
    return (
      <div className="date-selector__calendar-button"
        ref={(el) => this.calendarButton = el}>
        <div className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        <span>{DaySelector.formatDate(this.props.date)}</span>
      </div>
    );
  }

  /**
   * Return true if the current date is a Monday.
   * @return {boolean}
   */
  isMonday() {
    return moment(this.props.date).day() === 1;
  }

  /**
   * Return true if the current date is a Friday.
   * @return {boolean}
   */
  isFriday() {
    return moment(this.props.date).day() === 5;
  }

  render() {
    return (
      <div>
        <SingleValueSlider className={this.getStyleClass()}
          value={this.getCalendarButton()}
          onTodayClicked={this.handleCalendarButtonClick.bind(this)}
          onPrevClicked={this.handlePreviousClick.bind(this)}
          onNextClicked={this.handleNextClick.bind(this)} />
        <Menu open={this.state.showMenu}
          onClose={this.handleMenuClose.bind(this)}
          anchorEl={this.calendarButton}>
          {this.getMenuBody()}
        </Menu>
      </div>
    );
  }
}

DaySelector.defaultProps = {
  disableWeekend: false,
  isWaiting: false,
  getHolidays: null,
};

DaySelector.propTypes = {
  /**
   * The selected date shown.
   */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]).isRequired,

  /**
   * If set to true, Saturday and Sunday will be disabled in the calendar.
   * @see DayPicker
   */
  disableWeekend: PropTypes.bool,

  /**
   * If set to true, the selector will be disabled and neither the slider nor the calendar will be
   * working.
   */
  isWaiting: PropTypes.bool,

  /**
   * Function called when a date (different from the given one) is selected, either with the slider
   * or the calendar. The slider always move one day at the time.
   */
  changeDate: PropTypes.func.isRequired,
  /**
   * Function to call to retrieve holidays to show in calendar.
   * If this function is null, no holidays are shown.
   * @param {Date} month
   * @return {Promise} Resolve to a list of EmployeeCompanyHoliday
   */
  getHolidays: PropTypes.func,
};

module.exports = DaySelector;
