const React = require('react');
const Actions = require('../containers/Actions');
const List = require('../containers/TimeOffList');
const Modal = require('../containers/Modal');

const TimeOff = () => (
  <div className="wethod-section-body time-off">
    <Actions />
    <List />
    <Modal />
  </div>
);
module.exports = TimeOff;
