const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/PipelineValueChart.react');

const thousandsValue = (value) => Math.round(value / 1000);

const mapStateToProps = (state) => ({
  thisMonth: thousandsValue(state.month_pipeline_value),
  twoMonthsAgo: thousandsValue(state.prev_month_pipeline_value),
  threeMonthsAgo: thousandsValue(state.two_months_ago_pipeline_value),
  thisMonthLastYear: thousandsValue(state.prev_year_pipeline_value),
  twoMonthsAgoLastYear: thousandsValue(state.prev_year_prev_month_pipeline_value),
  threeMonthsAgoLastYear: thousandsValue(state.prev_year_two_months_before_pipeline_value),
  min: thousandsValue(Math.min(state.month_pipeline_value, state.prev_month_pipeline_value,
    state.two_months_ago_pipeline_value, state.prev_year_pipeline_value,
    state.prev_year_prev_month_pipeline_value, state.prev_year_two_months_before_pipeline_value))
    * 0.8,
});

module.exports = connect(mapStateToProps)(Component);
