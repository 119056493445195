const { connect } = require('react-redux');
const Component = require('../../../components/sections/Time/Title.react');

const mapStateToProps = (state) => ({
  chargeability: state.chargeable_hours_perc,
  prev_week_chargeability: state.chargeable_hours_prev_week_perc,
  target: state.chargeability_target_perc,
});

module.exports = connect(mapStateToProps)(Component);
