/* eslint-disable react/sort-comp,jsx-a11y/click-events-have-key-events,
jsx-a11y/interactive-supports-focus */
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const DateRangePicker = require('./DateRangePicker.react');
const { formatDate } = require('../../services/FormatService');

/**
 * A button with month calendar to pick up an interval of two dates, from and to. It can be a
 * single day interval.
 *
 * @type {module.DateRangeButtonPicker}
 */
class DateRangeButtonPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
      updatedRange: this.props.value,
    };
  }

  componentDidMount() {
    $(document).click(this.closePicker.bind(this));
  }

  /**
   * Returns true if target has selector or if one of its ancestors have it.
   * @param target
   * @param selector
   */
  static isSameOrSon(target, selector) {
    const targetEl = $(target);
    const selectorEl = $(selector);

    return targetEl.is(selectorEl) || targetEl.parents(selector).length > 0;
  }

  closePicker(e) {
    if (this.state.showPicker) {
      if (!DateRangeButtonPicker.isSameOrSon(e.target, '.wethod-date-picker') && !DateRangeButtonPicker.isSameOrSon(e.target, '[data-input-type=range]')) {
        this.setState({ showPicker: false });

        if (this.hasChangedRange()) {
          this.props.onChange(this.props.name, this.state.updatedRange);
        }

        this.props.onBlur();
      }
    }
  }

  showPicker() {
    this.setState({ showPicker: true });
  }

  handleFocus() {
    if (!this.state.showPicker) {
      this.showPicker();
    }

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  static hasValue(range) {
    return range && range.start_date && range.end_date;
  }

  hasChangedRange() {
    if (!DateRangeButtonPicker.hasValue(this.props.value) && !this.state.updatedRange) {
      return false;
    }
    if (!DateRangeButtonPicker.hasValue(this.props.value) && this.state.updatedRange) {
      return true;
    }
    return !moment(this.state.updatedRange.start_date).isSame(this.props.value.start_date)
      || !moment(this.state.updatedRange.end_date).isSame(this.props.value.end_date);
  }

  handleIntervalChange(name, startDate, endDate) {
    this.setState({
      updatedRange: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  handleDelete(e) {
    e.stopPropagation();

    this.setState({
      updatedRange: null,
      showPicker: false,
    }, () => {
      this.props.onChange(this.props.name, this.state.updatedRange);
      this.props.onBlur();
    });
  }

  getPicker() {
    if (this.state.showPicker) {
      return (
        <DateRangePicker name={this.props.name}
          disableBeforeEqual={this.props.disableBeforeEqual}
          from={this.props.value ? this.props.value.start_date : null}
          to={this.props.value ? this.props.value.end_date : null}
          onChange={this.handleIntervalChange.bind(this)} />
      );
    }
    return null;
  }

  getFormattedDate() {
    if (this.props.value) {
      if (this.props.format) {
        return this.props.format(this.props.value);
      }
      return this.props.value.start_date === this.props.value.end_date
        ? formatDate(this.props.value.start_date)
        : `${formatDate(this.props.value.start_date)} - ${formatDate(this.props.value.end_date)}`;
    }
    return null;
  }

  getDeleteButton() {
    if (this.props.deletable && this.state.updatedRange) {
      return (
        <div role="button"
          className="wethod-icon-button wethod-icon-button--medium project-canvas-kanban-sidebar__delete"
          onClick={this.handleDelete.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--black" />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-date-picker" data-input-type="test">
        <button type="button"
          className="material-input-button wethod-date-picker-button--deletable"
          name={this.props.name}
          onClick={this.handleFocus.bind(this)}>
          <span>{this.getFormattedDate()}</span>
          {this.getDeleteButton()}
        </button>
        {this.getPicker()}
      </div>
    );
  }
}

DateRangeButtonPicker.propTypes = {
  value: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  /**
   * string that will be shown as placeholder and, when placeholder is not shown, as
   * label
   */
  placeholder: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  deletable: PropTypes.bool,
  format: PropTypes.func,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**
   * This day and all the ones before this are disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
};

DateRangeButtonPicker.defaultProps = {
  deletable: false,
  placeholder: {
    start: 'From',
    end: 'To',
  },
  format: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  disableBeforeEqual: null,
};

module.exports = DateRangeButtonPicker;
