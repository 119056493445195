/* eslint-disable react/jsx-no-bind */
const React = require('react');

module.exports = class DateBarEdit extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__bar" style={this.props.style} ref={(el) => this.barEl = el}>
        <span
          className="project-canvas-gantt__bar-resize-handler project-canvas-gantt__bar-resize-handler--begin"
          onMouseDown={(e) => this.props.onResizeStart(e, e.target, this.barEl, this.props, 'left')} />
        <span
          className="project-canvas-gantt__bar-move-handler"
          onMouseDown={(e) => this.props.onResizeStart(e, e.target, this.barEl, this.props, 'move')} />
        <span
          className="project-canvas-gantt__bar-resize-handler project-canvas-gantt__bar-resize-handler--end"
          onMouseDown={(e) => this.props.onResizeStart(e, e.target, this.barEl, this.props, 'right')} />
      </div>
    );
  }
};
