const { connect } = require('react-redux');
const SecondaryInfoController = require('../../components/SecondaryInfo/SecondaryInfoController.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.isSaving,
  person: state.person,
});

const mapDispatchToProps = (dispatch) => ({
  save: (person) => dispatch(actions.updatePerson(person)),
  addPhone: (personId, phone) => dispatch(actions.addPersonPhone(personId, phone)),
  updatePhone: (phone) => dispatch(actions.updatePersonPhone(phone)),
  deletePhone: (phone) => dispatch(actions.deletePersonPhone(phone)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SecondaryInfoController);
