'use strict';

Wethod.module('BriefingApp', function (BriefingApp) {
  this.startWithParent = false;

  var API = {
    showWeeklyBriefing: function () {
      BriefingApp.Controller.showWeekly();
    },
    showMonthlyBriefing: function () {
      BriefingApp.Controller.showMonthly();
    },
  };

  BriefingApp.on('start', function (options) {
    API[options.foo](options.idProject);
  });
});
