/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');
const Body = require('../../../containers/modals/ContactInfo/ContactInfoBody');
const Loader = require('../../../../../../../../common/react/Loader/Loader.react');

module.exports = class ContactInfoModal extends React.Component {
  getBody() {
    if (this.props.waiting) {
      return <Loader />;
    }
    return <Body />;
  }

  render() {
    return (
      <Modal title="Edit Contact Info"
        className="profile__contact-info-modal"
        onCancelClick={this.props.onCancelClick}>
        {this.getBody()}
      </Modal>
    );
  }
};
