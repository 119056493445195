const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('./Tooltip.react');

class IconButtonTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
  }

  getStyle() {
    let style = 'wethod-icon-button wethod-icon-button--tooltip';
    if (this.props.borderless) {
      style += ' wethod-icon-button--no-border';
    }
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    if (this.props.disabled) {
      style += ' disabled';
    }
    return style;
  }

  setRef(element) {
    this.buttonRef = element;
    if (this.props.rootRef) {
      this.props.rootRef(element);
    }
  }

  render() {
    return (
      <Tooltip label={this.props.label} className="wethod-tooltip--icon-button">
        <button type="button"
          aria-label={this.props.label}
          className={this.getStyle()}
          onClick={this.props.onClick}
          ref={this.setRef}
          disabled={this.props.disabled}>
          {this.props.children}
        </button>
      </Tooltip>
    );
  }
}

IconButtonTooltip.defaultProps = {
  borderless: false,
  className: null,
  onClick: null,
  children: null,
  disabled: false,
  rootRef: null,
};

IconButtonTooltip.propTypes = {
  /**
   * Label shown as tooltip content
   * @see Tooltip
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * When set to true, the button is shown without borders.
   */
  borderless: PropTypes.bool,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * Function called when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Element shown as body of the button.
   * (Should be an icon)
   */
  children: PropTypes.element,
  disabled: PropTypes.bool,
  rootRef: PropTypes.func,
};

module.exports = IconButtonTooltip;
