/* eslint-disable no-param-reassign */
const React = require('react');

module.exports = class InfiniteLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: null,
    };
  }

  hideLoader() {
    this.setState({ loader: null });
  }

  loadMore(direction) {
    if (!this.state.loader) {
      switch (direction) {
        case 'left':
          this.props.onLoadLeft();
          break;
        case 'right':
          this.props.onLoadRight();
          break;
        default:
          break;
      }
      if (direction !== 'left' && direction !== 'right') {
        direction = null;
      }
      this.setState({ loader: direction });
    }
  }

  render() {
    return this.props.children({
      loader: this.state.loader,
      loadMore: this.loadMore.bind(this),
      hideLoader: this.hideLoader.bind(this),
    });
  }
};
