const React = require('react');
const Row = require('../SettingsRow.react');
const Select = require('../SettingsSelect.react');
const SelectItem = require('../SettingsSelectItem.react');

const TimesheetReminder = ({ frequency, isSaving, onFrequencyChange }) => {
  const feedback = isSaving ? 'Saving...' : null;

  function handleFrequencyChange(e) {
    const { value } = e.target;

    if (onFrequencyChange) {
      onFrequencyChange(value);
    }
  }

  return (
    <Row feedback={feedback}>
      <div>Send a notification reminder via email to all team members:</div>
      <Select name="timesheet-reminder"
        label="timesheet-reminder"
        value={frequency}
        onChange={handleFrequencyChange}
        disabled={isSaving}>
        <SelectItem value="weekly">Weekly</SelectItem>
        <SelectItem value="daily">Daily</SelectItem>
        <SelectItem value="never">Never</SelectItem>
      </Select>
    </Row>
  );
};

module.exports = TimesheetReminder;
