const { connect } = require('react-redux');
const Component = require('../../components/job-title/EditRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-job-title-')),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (id, jobTitle) => dispatch(actions.updateJobTitle(id, jobTitle)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
