/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class SectionTitle extends React.Component {
  render() {
    return (
      <h3 className="briefing-section__title">{this.props.children}</h3>
    );
  }
};
