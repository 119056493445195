const React = require('react');
const $ = require('jquery');
const PropTypes = require('prop-types');

class TableFooter extends React.Component {
  constructor(props) {
    super(props);

    this.footerRef = null;
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.updateHeight('footer', $(this.footerRef).outerHeight(true));
  }

  onClick() {
    this.props.toggleExpanded(!this.props.expanded);
  }

  getLabel() {
    return this.props.expanded ? 'Show less' : 'Show more';
  }

  getIconClassName() {
    let name = 'wethod-table__footer-button-icon wethod-icon-medium-dropdown wethod-icon-medium-dropdown--black';
    if (this.props.expanded) {
      name += ' wethod-icon-medium-dropdown--expanded';
    }
    return name;
  }

  render() {
    return (
      <div className="wethod-table__footer" ref={(ref) => this.footerRef = ref}>
        <span className="wethod-table__footer-counter" />
        <button type="button"
          className="wethod-table__footer-button"
          onClick={this.onClick}>
          <span className="wethod-table__footer-button-label">{this.getLabel()}</span>
          <span className={this.getIconClassName()} />
        </button>
      </div>
    );
  }
}

TableFooter.propTypes = {
  expanded: PropTypes.bool.isRequired,
  /**
   * Function to call when clicking on the expand button.
   *
   * @param expanded {boolean} the new "expanded" value
   */
  toggleExpanded: PropTypes.func.isRequired,
  /**
   * The function to call to update search's height in parent.
   *
   * @see Table2
   *
   * @param value {integer}
   */
  updateHeight: PropTypes.func.isRequired,
};

module.exports = TableFooter;
