const { connect } = require('react-redux');
const actions = require('../actions');
const Sidebar = require('../components/CompanySidebar.react');

const mapStateToProps = (state) => ({
  client: state.company,
  isSaving: state.isSaving,
  canEdit: true,
  hasIntercompanyGroup: state.hasIntercompanyGroup,
  availableGroupCompanies: state.availableGroupCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeCompanySidebar()),
  onSave: (company) => dispatch(actions.updateCompany(company)),
  onDelete: () => dispatch(actions.showDeleteCompanyModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
