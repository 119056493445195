/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events */
const React = require('react');

module.exports = class Navigator extends React.Component {
  constructor(props) {
    super(props);

    this.onTodayClicked = this.onTodayClicked.bind(this);
  }

  onTodayClicked() {
    this.props.onTodayClick();
  }

  render() {
    return (
      <div className="planning-navigation">
        <div className="navigation__prev" onClick={this.props.onPrevClick}>
          <div className="planning__arrow-icon wethod-icon-back wethod-icon-back--black" />
        </div>
        <div className="navigation__today" onClick={this.onTodayClicked}>today</div>
        <div className="navigation__next" onClick={this.props.onNextClick}>
          <div className="planning__arrow-icon wethod-icon-forward wethod-icon-forward--black" />
        </div>
      </div>
    );
  }
};
