const React = require('react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');

const TokenDeleteModal = ({ closeModal, isDeleting, deleteToken, tokenId }) => {
  function onRevokeClick() {
    deleteToken(tokenId);
  }

  return (
    <Modal onClose={closeModal} title="Are you sure you want to revoke the token?">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            This will permanently delete the token and cannot be reversed.
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter>
          <ModalFooterAction onClick={closeModal} disabled={isDeleting}>
            Cancel
          </ModalFooterAction>
          <ModalFooterAction onClick={onRevokeClick} disabled={isDeleting}>
            Revoke
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = TokenDeleteModal;
