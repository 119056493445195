/* eslint-disable backbone/no-model-attributes */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/desk/favorite`;
  },
  getList() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
  deleteResourceFavourites(resourceId, type) {
    const defer = $.Deferred();
    $.ajax({
      method: 'DELETE',
      crossDomain: true,
      url: `${this.urlRoot()}/${type}/${resourceId}`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
