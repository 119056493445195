const { connect } = require('react-redux');
const Event = require('../../components/EventsFuture/Event.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  focusedEvent: state.focusedEvent,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (event) => dispatch(actions.showDeleteEventModal(event)),
  openEditForm: (event) => dispatch(actions.openEditEventForm(event)),
  closeForm: () => dispatch(actions.closeEditEventForm()),
  showDetails: (event) => dispatch(actions.showEventDetails(event)),
  hideDetails: () => dispatch(actions.hideEventDetails()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Event);
