const { connect } = require('react-redux');
const Component = require('../components/SaveManager.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  onSave: (id, changes) => dispatch(actions.updateAllocation(id, changes)),
  onCreate: (item) => dispatch(actions.createAllocation(item)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
