/* eslint-disable class-methods-use-this */
const Clients = require('./Clients');

module.exports = class People extends Clients {
  filter(collection, key) {
    return collection.filter((item) => {
      const foundInName = this.found(key, item.name);
      const foundInSurname = this.found(key, item.surname);
      const foundInCompany = this.found(key, item.client.corporate_name);
      return foundInName || foundInSurname || foundInCompany;
    });
  }

  order(collection, key, order) {
    return collection.sort((a, b) => {
      let valueA; let
        valueB;
      if (key === 'company') {
        valueA = a.client ? a.client.corporate_name : null;
        valueB = b.client ? b.client.corporate_name : null;
      } else {
        valueA = a[key];
        valueB = b[key];
      }

      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA.toLowerCase() > valueB.toLowerCase()) return order === 'asc' ? 1 : -1;
      if (valueA.toLowerCase() < valueB.toLowerCase()) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }
};
