/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */

const React = require('react');

module.exports = class AllocationItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showError: false };
  }

  getProjectType() {
    return this.props.project.type ? this.props.project.type
      : this.props.project.job_order_category;
  }

  getBadgeColor() {
    const { color } = this.getProjectType();
    if (this.props.project.is_pending) {
      const lightColor = color.replace('rgb', 'rgba').replace(')', ',0.5)');
      const darkColor = color;

      return `repeating-linear-gradient( 45deg, ${lightColor}, ${lightColor} 2px, ${darkColor} 1px, ${darkColor} 6px )`;
    }
    return color;
  }

  getBadgeStyle() {
    return { background: this.getBadgeColor() };
  }

  getContentClassName() {
    return this.props.canUserEdit ? 'planning-people__allocation-item-content' : 'planning-people__allocation-item-content disabled';
  }

  showError() {
    this.setState({ showError: true }, () => {
      setTimeout(() => {
        this.setState({ showError: false });
      }, 3000);
    });
  }

  onItemClick() {
    if (this.props.canUserEdit) {
      if (this.props.onClick) {
        this.props.onClick(this.props.project.id);
      }
    } else {
      this.showError();
    }
  }

  getErrorClassName() {
    return this.state.showError ? 'planning-people__allocation-item-error planning-people__allocation-item-error--visible' : 'planning-people__allocation-item-error';
  }

  render() {
    const { project } = this.props;

    return (
      <div className="planning-people__allocation-item" onClick={this.onItemClick.bind(this)}>
        <div className={this.getContentClassName()}>
          <div className="planning-people__allocation-item-hours">
            {project.amount}
            h
          </div>
          <div className="planning-people__allocation-item-info">
            <div className="planning-people__allocation-item-type" style={this.getBadgeStyle()} />
            <div className="planning-people__allocation-item-info-content">
              <div className="employee__name">{_.unescape(project.name)}</div>
              <div className="employee__area">{_.unescape(project.client.name)}</div>
              <div
                className="employee__level">
                PM:
                {' '}
                {`${_.unescape(project.pm.name)} ${_.unescape(project.pm.surname)}`}
              </div>
            </div>
          </div>
        </div>
        <div className={this.getErrorClassName()}>
          You don't have enough permissions to edit this plan
        </div>
      </div>
    );
  }
};
