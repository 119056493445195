const NodeTotal = require('./StructureNodeTotal');

module.exports = class LevelAmountNodeTotal extends NodeTotal {
  /**
   * @param {number} days
   * @param {number} cost
   * @param {number} price
   * @param {number} levelId
   */
  constructor(days, cost, price, levelId) {
    super(days, cost, price);
    this.levelId = levelId;
  }

  /**
   * @return {number}
   */
  getLevelId() {
    return this.levelId;
  }

  toJson() {
    const json = super.toJson();
    json.level_id = this.getLevelId();

    return json;
  }
};
