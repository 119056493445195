const { connect } = require('react-redux');
const actions = require('../actions');
const Sidebar = require('../components/ContactSidebar.react');

const mapStateToProps = (state) => ({
  client: state.focusedContact,
  isSaving: state.isSaving,
  canEdit: true,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeContactSidebar()),
  onDelete: (contact) => dispatch(actions.showDeleteContactModal(contact)),
  updateClient: (contact) => dispatch(actions.updateContact(contact)),
  addClient: (contact) => dispatch(actions.saveContact(contact)),
  addPhone: (contactId, phone) => dispatch(actions.addPersonPhone(contactId, phone)),
  updatePhone: (contactId, phone) => dispatch(actions.updatePersonPhone(contactId, phone)),
  deletePhone: (contactId, phone) => dispatch(actions.deletePersonPhone(contactId, phone)),
  getCompanies: () => dispatch(actions.getCompanies()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
