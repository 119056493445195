/* eslint-disable react/sort-comp,no-param-reassign */
const React = require('react');
const ProjectsTrend = require('../../../containers/Kpi/Trends/TrendProjects');
const ProposalsTrend = require('../../../containers/Kpi/Trends/TrendProposals');
const AverageProjectValueTrend = require('../../../containers/Kpi/Trends/TrendAverageProjectValue');
const ConversionRateTrend = require('../../../containers/Kpi/Trends/TrendConversionRate');
const AverageProfitabilityTrend = require('../../../containers/Kpi/Trends/TrendAverageProfitability');
const TimeFilterFactory = require('../../../models/TimeFilter/TimeFilterFactory');

module.exports = class Trends extends React.Component {
  /**
   * Returns trends belonging to months contained in current TimeFilter's frame.
   * @return {T[]}
   */
  getMonthsInFrame() {
    const monthsString = TimeFilterFactory(this.props.timeFilter).getMonthsInFrame().join();
    return this.props.trends.filter((trend) => monthsString.indexOf(trend.month) !== -1);
  }

  componentDidMount() {
    this.props.getTrends(this.props.clientId);
  }

  render() {
    const months = this.getMonthsInFrame();
    return (
      <div className="client-trends">
        <ProjectsTrend months={months} />
        <ProposalsTrend months={months} />
        <AverageProjectValueTrend months={months} />
        <ConversionRateTrend months={months} />
        <AverageProfitabilityTrend months={months} />
      </div>
    );
  }
};
