'use strict';

Wethod.module('HeaderApp', function (HeaderApp, Wethod, Backbone, Marionette, $) {
  this.UpdateNotificationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/notification/',
  });

  var API = {
    getNotification: function () {
      var model = new HeaderApp.UpdateNotificationModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (notification, response) {
          if (response.code === 200) {
            defer.resolve(notification);
          } else {
            defer.resolve(null);
          }
        },
        error: function (responseModel, response) {
          defer.resolve(response);
        },
      });
      return defer.promise();
    },
    markAsRead: function (notification) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/notification/' + notification.id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:update:notification', function () {
    return API.getNotification();
  });
  Wethod.reqres.setHandler('update:notification:mark-as-read', function (notification) {
    return API.markAsRead(notification);
  });
});
