const React = require('react');
const PropTypes = require('prop-types');
const Summary = require('./Summary/Summary.react');
const Empty = require('./Empty.react');
const Loading = require('../../Loading.react');

const RatingOverview = ({ children, onAddClick, ratings, maxRating, loading }) => {
  const addButton = () => {
    if (onAddClick) {
      return (
        <button type="button"
          onClick={onAddClick}
          className="wethod-icon-button widget-rating-overview__add-button">
          <div className="wethod-icon wethod-icon-medium-plus wethod-icon-medium-plus--black" />
        </button>
      );
    }
    return null;
  };

  const bodyContent = () => {
    if (loading) {
      return <Loading />;
    }
    if (ratings.length) {
      return (
        <div className="widget-rating-overview__body-content">
          <Summary maxRating={maxRating} ratings={ratings} />
          <div className="widget-rating-overview__list">
            {children}
          </div>
        </div>
      );
    }
    return <Empty />;
  };

  return (
    <div className="wethod-widget widget-rating-overview">
      <div className="wethod-widget__header">
        <span className="wethod-widget__title">Overall rating</span>
        {addButton()}
      </div>
      <div className="widget-rating-overview__body">
        {bodyContent()}
      </div>
    </div>
  );
};

RatingOverview.defaultProps = {
  children: null,
  onAddClick: null,
  loading: false,
};

RatingOverview.propTypes = {
  /**
   * Nodes to use as list items.
   */
  children: PropTypes.node,
  onAddClick: PropTypes.func,
  maxRating: PropTypes.number.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool,
};

module.exports = RatingOverview;
