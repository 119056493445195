const React = require('react');
const EmployeeTagsCategoryDeleteModal = require('./EmployeeTagsCategoryDeleteModal.react');
const EmployeeTagsItemDeleteModal = require('./EmployeeTagsItemDeleteModal.react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');

module.exports = class EmployeeTagsTableBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeTags: this.props.employeeTags,
      onDeleteCategory: null,
      onDeleteTag: null,
    };
  }

  componentDidUpdate() {
    const withIsNewProps = this.props.employeeTags.filter((el) => el.isNew)?.length;
    const withIsNewState = this.state.employeeTags.filter((el) => el.isNew)?.length;
    const withDisabledProps = this.props.employeeTags.filter((el) => el.disabled)?.length;
    const withDisabledState = this.state.employeeTags.filter((el) => el.disabled)?.length;

    let withDisabledTagsProps = 0;
    this.props.employeeTags.forEach(
      (el) => el.items?.forEach(
        (it) => {
          if (it.disabled) {
            withDisabledTagsProps += 1;
          }
        },
      ),
    );

    let withDisabledTagsState = 0;
    this.state.employeeTags.forEach(
      (el) => el.items?.forEach(
        (it) => {
          if (it.disabled) {
            withDisabledTagsState += 1;
          }
        },
      ),
    );

    let withNewItemsProps = 0;
    this.props.employeeTags.forEach(
      (el) => {
        withNewItemsProps += el.items?.length ? el.items.length : 0;
      },
    );
    let withNewItemsState = 0;
    this.state.employeeTags.forEach(
      (el) => {
        withNewItemsState += el.items?.length ? el.items.length : 0;
      },
    );

    if ((this.state.employeeTags.length !== this.props.employeeTags.length)
      || (withIsNewProps !== withIsNewState)
      || (withDisabledProps !== withDisabledState)
      || (withDisabledTagsProps !== withDisabledTagsState)
      || (withNewItemsProps !== withNewItemsState)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((prevState) => ({
        ...prevState,
        employeeTags: this.props.employeeTags,
      }));
    }
  }

  handleInputChanges(e) {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const updatedTags = prevState.employeeTags.map((el) => {
        if (el.id === parseInt(name.replace('employee-tag-category-', ''))) {
          return {
            ...el,
            name: value,
          };
        }
        return el;
      });
      return {
        employeeTags: updatedTags,
      };
    });
  }

  handleInputItemChanges(idCategory, e) {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const updatedTags = prevState.employeeTags.map((el) => {
        if (el.id === idCategory) {
          return {
            ...el,
            items: el.items.map((it) => {
              if (it.id === parseInt(name.replace('employee-tag-item-category-', ''))) {
                return {
                  ...it,
                  name: value,
                };
              }
              return it;
            }),
          };
        }
        return el;
      });
      return {
        employeeTags: updatedTags,
      };
    });
  }

  handleDeleteCategory(isNew, id, e) {
    if (isNew) {
      e.preventDefault();
      this.props.onDeleteCategory(parseInt(id));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        onDeleteCategory: this.props.employeeTags.find((el) => el.id === parseInt(id)),
      }));
    }
  }

  handleDeleteTag(idCategory, idTag) {
    this.setState((prevState) => ({
      ...prevState,
      onDeleteTag: this.props.employeeTags.find((el) => el.id === parseInt(idCategory)).items
        .find((el) => el.id === parseInt(idTag)),
    }));
  }

  handleOnClose() {
    this.setState(() => ({
      onDeleteCategory: null,
      onDeleteTag: null,
    }));
  }

  handleBlurChanges(isNew, e) {
    if (!isNew) {
      this.props.onBlurCategory(e);
    }
  }

  handleCreateNewCategory(category) {
    this.props.setDisabledCategory(category, true);
    this.props.onCreateCategory({
      ...category,
      isNew: undefined,
      disabled: undefined,
      id: null,
    });
  }

  handleDeleteCategoryModal() {
    this.props.setDisabledCategory(this.state.onDeleteCategory, true);
    this.props.onDeleteCategoryRequest(this.state.onDeleteCategory.id);
    this.setState(() => ({
      onDeleteCategory: null,
    }));
  }

  handleDeleteTagModal() {
    this.props.onDeleteTag(parseInt(this.state.onDeleteTag.id));
    this.setState(() => ({
      onDeleteTag: null,
    }));
  }

  handleAddTag(id) {
    this.props.onCreateTag({ name: '', isNew: true, id_category: id });
  }

  handleSaveTag(category, id) {
    const tag = this.state.employeeTags.find((el) => el.id === category)
      ?.items.find((it) => it.id === id);
    this.props.onSaveTag(tag);
  }

  // eslint-disable-next-line class-methods-use-this
  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  render() {
    if (this.state.employeeTags?.length > 0) {
      return (
        <div>
          {this.state.employeeTags.map((el) => (
            <div key={`employee-tag-${el.id}`} className={el.disabled ? 'disabled-metadata' : null}>
              <ul className="companyLabelRow">
                <li className="col_2 left">
                  <div className="company-settings-margin-top-m">
                    <TextField label="CategoryName"
                      placeholder="Category Name"
                      onChange={this.handleInputChanges.bind(this)}
                      onBlur={this.handleBlurChanges.bind(this, el.isNew)}
                      name={`employee-tag-category-${el.id}`}
                      value={el.name}
                      className="companyMetadataInput" />
                  </div>
                </li>
                <li className="col_4 left" />
                <li className="col_3 left" />
                <li className="col_3 left">
                  {el.disabled
                    && (
                      <span data-action="feedback" className="settings-feedback">
                        Saving...
                      </span>
                    )}
                </li>
                <li className="col_3 left">
                  {
                    (!el.isNew) ? (
                      <Button onClick={this.handleAddTag.bind(this, el.id)}
                        className="company-settings__button company-settings__button--blue company-settings-margin-top-m">
                        + Add tag
                      </Button>
                    )
                      : (
                        <Button
                          onClick={this.handleCreateNewCategory.bind(this, el)}
                          className="company-settings__button company-settings__button--blue company-settings-margin-top-m">
                          save
                        </Button>
                      )
                  }
                </li>
                <li className="col_1 left textCenter">
                  <div data-action="delete"
                    data-testid="deleteCategory"
                    className="spriteDeleteCompany iconCompanyDelete"
                    onClick={this.handleDeleteCategory.bind(this, el.isNew, el.id)} />
                </li>
                <li className="clear" />
              </ul>
              {
                el.items?.map((tag) => (
                  <ul className={`companyLabelRow tagRow ${tag.disabled ? ' disabled-metadata' : null}`} key={`employee-tag-item-${el.id}-${tag.id}`}>
                    <li className="col_4 left" />
                    <li className="col_4 left ">
                      <div className="company-settings-margin-top-m">
                        <TextField label="TagName"
                          placeholder="Tag Name"
                          onChange={this.handleInputItemChanges.bind(this, el.id)}
                          onBlur={this.handleSaveTag.bind(this, el.id, tag.id)}
                          name={`employee-tag-item-category-${tag.id}`}
                          value={tag.name}
                          className="companyMetadataInput" />
                      </div>
                    </li>
                    <li className="col_4 left">
                      {tag.disabled
                        && (
                          <span data-action="feedback" className="settings-feedback">
                            Saving...
                          </span>
                        )}
                    </li>
                    <li className="col_3 left" />
                    <li className="col_1 left textCenter">
                      <div data-action="delete"
                        className="spriteDeleteCompany iconCompanyDelete deleteTag"
                        onClick={this.handleDeleteTag.bind(this, el.id, tag.id)} />
                    </li>
                    <li className="clear" />
                  </ul>
                ))
              }
            </div>
          ))}

          {
            this.state.onDeleteCategory
            && (
            <EmployeeTagsCategoryDeleteModal onDeleteCategory={this.state.onDeleteCategory}
              onClose={this.handleOnClose.bind(this)}
              feedback={this.props.isDeleting}
              onOk={this.handleDeleteCategoryModal.bind(this)} />
            )
          }

          {
            this.state.onDeleteTag
            && (
              <EmployeeTagsItemDeleteModal onDeleteTag={this.state.onDeleteTag}
                onClose={this.handleOnClose.bind(this)}
                feedback={this.props.isDeleting}
                onOk={this.handleDeleteTagModal.bind(this)} />
            )
          }
        </div>
      );
    }
    return null;
  }
};
