const { connect } = require('react-redux');
const Component = require('../components/Demographics.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getTrends: (filter) => dispatch(actions.getTrends(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
