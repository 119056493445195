module.exports.GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
module.exports.GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';

module.exports.UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
module.exports.UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';

module.exports.GET_FAVOURITES_REQUEST = 'GET_FAVOURITES_REQUEST';
module.exports.GET_FAVOURITES_SUCCESS = 'GET_FAVOURITES_SUCCESS';

module.exports.DELETE_RESOURCE_FAVOURITES_REQUEST = 'DELETE_RESOURCE_FAVOURITES_REQUEST';
module.exports.DELETE_RESOURCE_FAVOURITES_SUCCESS = 'DELETE_RESOURCE_FAVOURITES_SUCCESS';

module.exports.DELETE_SEARCH_REQUEST = 'DELETE_SEARCH_REQUEST';
module.exports.DELETE_SEARCH_SUCCESS = 'DELETE_SEARCH_SUCCESS';

module.exports.UPDATE_SEARCH_LABEL_REQUEST = 'UPDATE_SEARCH_LABEL_REQUEST';
module.exports.UPDATE_SEARCH_LABEL_SUCCESS = 'UPDATE_SEARCH_LABEL_SUCCESS';

module.exports.MODAL_CLOSE = 'MODAL_CLOSE';
