const React = require('react');
const MoneyCell = require('../Cell/MoneyCell.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const CurrencyCell = require('../Cell/CurrencyCell.react');
const Cell = require('../Cell/Cell.react');

const HeaderExternalCost = ({ isCurrencyEnabled, children, className }) => (
  <Cell className={`wethod-budget-calculator__cell-external-cost ${className}`}>
    <MoneyCell className="wethod-budget-calculator__cell-external-cost-value">{children}</MoneyCell>
    <ShowIf condition={isCurrencyEnabled}>
      <CurrencyCell />
    </ShowIf>
  </Cell>
);

module.exports = HeaderExternalCost;
