'use strict';

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette) {
  this.SalesOverviewLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#salesOverviewTemplate',
    regions: {
      headerSection: '[data-region="salesOverviewSectionHeader"]',
      body: '[data-region="salesOverviewBody"]',
    },
  });
});
