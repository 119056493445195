const React = require('react');
const DraggableList = require('./DraggableList/DraggableList.react');

const TaskSortModalDraggableList = ({ onDrop, children }) => (
  <DraggableList onDrop={onDrop} className="wethod-budget-task-sort-modal__draggable-list">
    {children}
  </DraggableList>
);

module.exports = TaskSortModalDraggableList;
