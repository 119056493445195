const { connect } = require('react-redux');
const Component = require('../components/ProjectsOverview.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isValueAsUnit: state.isValueAsUnit,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (clientId) => dispatch(actions.getProjects(clientId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
