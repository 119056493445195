/* eslint-disable no-underscore-dangle */
const HTTPService = require('../services/HTTPService');
const ParserService = require('../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this._value = options ? options.value : null;
    this._id = attributes ? attributes.employeeId : null;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    const idParam = this._id ? `?employeeId=${this._id}` : '';
    return `${APIURL}/employee-preference/timetracking-periodicity${idParam}`;
  },
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
  updateModel() {
    const defer = $.Deferred();
    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });
  },
});
