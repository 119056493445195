const constants = require('./constants');

class ReviewChangelogReducer {
  constructor(state) {
    this.state = {
      ...state,
      waitingFor: [],
      reviews: [],
      lastPage: true,
      deletedElements: 0,
      createdElements: 0,
      keyword: null,
      year: moment().format('YYYY'),
      modal: null,
      modalData: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.FILTER_REVIEW_REQUESTS:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('filter-reviews'),
          reviews: [],
          lastPage: false,
        };
      case constants.FILTER_REVIEW_REQUESTS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'filter-reviews'),
          reviews: action.items,
          lastPage: action.items.length < action.size,
          keyword: action.keyword,
        };
      case constants.LOAD_MORE_REVIEW_REQUESTS:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('load-reviews'),
        };
      case constants.LOAD_MORE_REVIEW_REQUESTS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'load-reviews'),
          reviews: state.reviews.concat(action.items),
          lastPage: action.items.length < action.size,
        };
      case constants.CHANGE_YEAR:
        return {
          ...state,
          year: action.year,
          deletedElements: 0,
          createdElements: 0,
          reviews: [],
        };
      case constants.CREATE_REVIEW_REQUEST:
      case constants.DELETE_REVIEW_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-review'),
        };
      case constants.CREATE_REVIEW_REQUEST_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-review'),
          reviews: [action.item].concat(state.reviews),
          createdElements: state.createdElements + 1,
        };
      case constants.DELETE_REVIEW_REQUEST_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-review'),
          reviews: state.reviews.filter((item) => item.id !== action.item.id),
          deletedElements: state.deletedElements + 1,
        };
      case constants.GET_LAST_REVIEW_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('last-review'),
        };
      }
      case constants.GET_LAST_REVIEW_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'last-review'),
          lastReview: action.lastReview,
        };
      }
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modal: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modal: null,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = ReviewChangelogReducer;
