/* eslint-disable no-unused-vars */

const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Product = {
  list(filters = {}) {
    let url = '/product';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/product',
      data,
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/product/${id}`,
      data,
    });
  },
};

module.exports = Product;
