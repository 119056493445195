const React = require('react');
const Menu = require('../../../../../../../common/react/Menu/Menu.react');
const Switch = require('../../../../../../../common/react/inputs/Switch/Switch.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

class HeaderLevelsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.buttonRef = null;

    this.onButtonClick = this.onButtonClick.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  onButtonClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onMenuClose() {
    this.setState({ open: false });
  }

  onToggleClick(e) {
    const { name, checked } = e.target;
    if (this.props.isHierarchyBudgetFlagEnabled) {
      this.props.setVisible(name, checked, this.props.budgetId);
    } else {
      this.props.setVisible(name, checked);
    }
  }

  setButtonRef(ref) {
    this.buttonRef = ref;
  }

  getLevelId(level) {
    if (this.props.isHierarchyBudgetFlagEnabled) {
      return level.level.id;
    }
    return level.id;
  }

  getLevels() {
    return this.props.levels.map((column) => {
      const levelId = this.getLevelId(column);
      const hasDays = this.hasDays(levelId);
      const isDisabled = hasDays || this.isDisabledLastLevel(levelId);

      return (
        <div key={levelId.toString()} className="wethod-table-column-selector__column">
          <Switch checked={hasDays || column.is_visible}
            name={levelId.toString()}
            label={column.level_name}
            id={`wethod-column-selector-${levelId}`}
            onChange={this.onToggleClick}
            disabled={isDisabled} />
        </div>
      );
    });
  }

  hasDays(idLevel) {
    let hasDaysLevel = false;
    const levelsDays = this.props.isHierarchyBudgetFlagEnabled
      ? this.props.days.filter((el) => el.level.id === idLevel)
      : this.props.days.filter((el) => el.budget_level.id === idLevel);
    if (levelsDays?.length > 0) {
      hasDaysLevel = levelsDays.some((el) => el.days > 0);
    }
    return hasDaysLevel;
  }

  isDisabledLastLevel(idLevel) {
    return this.props.levels.filter((el) => el.is_visible)?.length === 1
        && this.props.levels.find((el) => this.getLevelId(el) === idLevel)?.is_visible;
  }

  render() {
    return (
      <div>
        <IconButtonTooltip label="User levels"
          borderless
          rootRef={this.setButtonRef}
          onClick={this.onButtonClick}>
          <Icon icon="more" className="wethod-budget-header__more-options" />
        </IconButtonTooltip>
        <Menu open={this.state.open}
          anchorEl={this.buttonRef}
          onClose={this.onMenuClose}
          bottom
          centered>
          <div className="wethod-table-column-selector__header">User levels</div>
          <div className="wethod-table-column-selector__body">
            {this.getLevels()}
          </div>
        </Menu>
      </div>
    );
  }
}

module.exports = HeaderLevelsSelector;
