const { connect } = require('react-redux');
const EventCreator = require('../../components/EventCreator/EventCreator.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  showForm: state.showNewEventForm,
});

const mapDispatchToProps = (dispatch) => ({
  openForm: () => dispatch(actions.openNewEventForm()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(EventCreator);
