const React = require('react');

module.exports = ({ title, actions, children }) => (
  <div className="wethod-widget profile-kudos__widget">
    <div className="wethod-widget__header">
      <span className="wethod-widget__title">{title}</span>
      <span>{actions}</span>
    </div>
    <div className="wethod-widget__body">{children}</div>
  </div>
);
