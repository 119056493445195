const { connect } = require('react-redux');
const Component = require('../../components/ProjectSidebar/ProjectSidebarBody.react');

const mapStateToProps = (state) => ({
  name: state.project.name,
  client: state.project.client.corporate_name,
  contact: state.project.customer ? `${state.project.customer.name} ${state.project.customer.surname}` : '',
  account: state.project.account ? `${state.project.account.person.name} ${state.project.account.person.surname}` : '',
  purchaseOrder: state.project.client_po,
  pm: `${state.project.pm.person.name} ${state.project.pm.person.surname}`,
  projectType: state.project.project_label ? state.project.project_label.name : '',
  jobOrder: state.project.job_order,
  probability: state.project.probability,
  start: state.project.date_start,
  duration: state.project.duration,
  joc: state.project.project_type,
});

module.exports = connect(mapStateToProps)(Component);
