const { connect } = require('react-redux');
const EventsFuture = require('../../components/EventsFuture/EventsFuture.react');
const actions = require('../../actions');

const allowedEventTypes = ['timeline_call', 'timeline_email', 'timeline_meeting', 'heatmap_event'];

const mapStateToProps = (state) => ({
  events: state.filteredEvents.filter((event) => moment(event.timestamp).isAfter(moment()
    .toISOString()) && allowedEventTypes.indexOf(event.type) !== -1).sort((a, b) => {
    const dateA = moment(a.timestamp);
    const dateB = moment(b.timestamp);
    if (dateA.isBefore(dateB.format('YYYY-MM-DD HH:mm'))) return -1;
    return 1;
  }),
  focusedEvent: state.focusedEvent,
  showEditEventForm: state.showEditEventForm,
});

const mapDispatchToProps = (dispatch) => ({
  closeForm: () => dispatch(actions.closeEditEventForm()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(EventsFuture);
