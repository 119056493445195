const { connect } = require('react-redux');
const Component = require('../components/Pipeline.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  basket: state.basket,
  type: state.type,
  visibleColumns: state.visibleColumns,
});

const mapDispatchToProps = (dispatch) => ({
  addOpportunity: (attributes) => dispatch(actions.addOpportunity(attributes)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
