/* eslint-disable default-case */

'use strict';

var paymentStructureTemplateHtml = require('../account/templates/PaymentStructureTemplate.html');

Wethod.module('SettingsApp.Payment', function (Payment, Wethod, Backbone, Marionette, $, _) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: _.template(paymentStructureTemplateHtml),
    className: '',
    regions: {
      billingBox: '[data-region="billingInfoBox"]',

      cardBox: '[data-region="creditCardBox"]',

      modal: '[data-region="appDialog"]',
    },
    ui: {
      editCard: '[data-action="editCreditCard"]',
      editCardLabel: '[data-region="editCreditCardLabel"]',
      deleteCard: '[data-action="deleteCreditCard"]',

      editBillingInfo: '[data-action="editBillingInfo"]',
      editBillingInfoLabel: '[data-region="editBillingInfoLabel"]',
      deleteBillingInfo: '[data-action="deleteBillingInfo"]',
    },
    events: {
      'click @ui.editCard': 'editCard',
      'click @ui.deleteCard': 'deleteCard',

      'click @ui.editBillingInfo': 'editBillingInfo',
      'click @ui.deleteBillingInfo': 'deleteBillingInfo',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
    },
    editCard: function (e) {
      e.preventDefault();
      if (!this.ui.editCard.hasClass('disabled')) {
        Payment.showAddCardModal();
      }
    },
    deleteCard: function (e) {
      e.preventDefault();
      var data = {
        title: 'DELETE CREDIT CARD',
        text: 'Are you sure you want to delete your credit card?',
        buttonOkText: 'DELETE',
        buttonKoText: 'CANCEL',
        action: 'deleteCard',
      };
      Payment.showModal(data);
    },
    editBillingInfo: function (e) {
      e.preventDefault();
      Payment.showBillingInfoModal();
    },
    deleteBillingInfo: function (e) {
      e.preventDefault();
      var data = {
        title: 'DELETE BILLING INFO',
        text: 'Are you sure you want to delete your billing info?',
        buttonOkText: 'DELETE',
        buttonKoText: 'CANCEL',
        action: 'deleteBillingInfo',
      };
      Payment.showModal(data);
    },
  });

  // CREDIT CARD
  this.NoCreditCardTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#noCreditCardTemplateItemView',
  });
  this.CreditCardTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#paymentCreditCardTemplate',
  });

  // card modal
  this.AddCardModalItemView = Marionette.ItemView.extend({
    template: '#paymentModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.paymentCardModalStructure',
      paymentFoorm: '[data-region="payment-form"]',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
      mdlInput: '[data-region="mdl-input"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - 350) / 2;
      var posTop = (contextH - 423) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        var form = $(this.ui.paymentFoorm);

        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        Stripe.card.createToken(form, Payment.stripeResponseHandler);
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // BILLING INFO
  this.NoBillingInfoTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#noBillingInfoTemplateItemView',
  });
  this.BillingInfoTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#paymentBillingRecapTemplate',
  });

  // BillingInfoModalItemView
  this.BillingInfoModalItemView = Marionette.ItemView.extend({
    template: '#paymentBillingInfoModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.paymentBillingInfoModalStructure',

      editBillingInfoArea: '[data-region="editBillingInfo"]',

      privateBilling: '[data-action="privateForm"]',
      companyBilling: '[data-action="companyForm"]',

      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',

      'click @ui.privateBilling': function (e) {
        this.switchForm(e, 0);
      },
      'click @ui.companyBilling': function (e) {
        this.switchForm(e, 1);
      },
    },
    initialize: function (options) {
      this.options = options;

      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    onShow: function () {
      // render form inserimento/modifica dati di fatturazione
      Payment.switchBillingType(this.model.get('type'), this);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - 600) / 2;
      var posTop = (contextH - 423) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    switchForm: function (e, type) {
      e.preventDefault();
      var target = $(e.target);
      if (!target.hasClass('sel')) {
        $('.sel').removeClass('sel');
        target.addClass('sel');
        Payment.switchBillingType(type, this);
      }
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');

        Payment.saveBillingInfo(this.options.userBillingInfo);
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // form modifica ed inserimento dati di fatturazione da renderizzare all'interno del modal
  this.BillingEditItemView = Marionette.ItemView.extend({
    template: '#paymentBillingEditTemplate',
    className: '',
    ui: {
      mdlInput: '[data-region="mdl-input"]',
      inputEl: '[data-action="updateModel"]',
    },
    events: {
      'focusin @ui.inputEl': 'resetFieldError',
      'change @ui.inputEl': 'updateModel',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    resetFieldError: function (e) {
      var input = e.target;

      $(input).removeClass('billingError');
      $(input).parent().find('.mdl-textfield__label').removeClass('billingError');
      $('[data-message="statusModalAction"]').html('');
    },
    updateModel: function (e) {
      var inputName = e.target.name;
      var value = _.escape(e.target.value.trim());

      // aggiorno il modello all'interno delle options della view parent
      this.options.parentView.options.userBillingInfo.attributes[inputName] = value;
    },
  });

  // modal
  var modalW = 350;
  var modalH = 200;
  this.ModalItemView = Marionette.ItemView.extend({
    template: '#paymentGeneralModalTemplate',
    className: 'modalWrapper',
    _externalModel: undefined,
    _externalView: undefined,
    ui: {
      modalEL: '.paymentModalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        switch (this.model.get('action')) {
          case 'deleteCard':
            Payment.stripeDeleteCard();
            break;
          case 'deleteBillingInfo':
            Payment.deleteBillingInfo();
            break;
        }
      }
    },
    cancelAction: function (e) {
      if (!this.ui.cancelButton.hasClass('disabled')) {
        e.preventDefault();
        this.destroy();
      }
    },
  });
});
