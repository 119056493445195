const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.projectId = options.projectId;
    this.urlRoot = `${APIURL}/review/kudos/${this.projectId}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
