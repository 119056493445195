const { connect } = require('react-redux');
const actions = require('../actions');
const Component = require('../../../sidebar/contact/ContactEditableDetails.react');

const mapStateToProps = (state) => ({
  companies: state.companies,
  isWaitingForCompanies: state.isWaitingForCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  onCompanySave: (company) => dispatch(actions.saveNewCompany(company)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
