// eslint-disable-next-line no-unused-vars
const LevelAmountPrevious = require('./LevelAmountNodePrevious');

module.exports = class JobTitleNodePrevious {
  constructor(days) {
    this.days = days;
  }

  getDays() {
    return this.days;
  }

  toJson() {
    return {
      days: this.getDays(),
    };
  }
};
