const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const NavigationButton = require('../../../../../../common/react/NavigationButton.react');

const CreationModeModal = ({
  closeModal, projectId, isWaiting, showTemplateModal, createBudget,
}) => {
  function handleNewBudgetClick() {
    createBudget(projectId);
  }

  return (
    <Modal title="Create budget" onClose={closeModal}>
      <ModalContent isLoading={isWaiting}>
        <ModalBody>
          <NavigationButton key="creation" title="New" subtitle="Create a new budget from scratch" onClick={handleNewBudgetClick} />
          <NavigationButton key="template" title="New from template" subtitle="Use a budget template" onClick={showTemplateModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

module.exports = CreationModeModal;
