const { connect } = require('react-redux');
const Component = require('../../components/modal/NotesModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-order').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (order) => dispatch(actions.updateOrder(order, true)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
