const { connect } = require('react-redux');
const Component = require('../components/Widgets.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  firstTag: state.tag1,
  secondTag: state.tag2,
  businessUnit: state.business_units,
  headcount: state.headcount,
  isWaiting: state.waitingFor.filter((key) => key === 'trends').length > 0,
  canEditSettings: state.canEditSettings,
});

const mapDispatchToProps = (dispatch) => ({
  showTargetModal: () => dispatch(actions.showTargetModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
