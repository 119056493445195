/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class ListHeaderView extends React.Component {
  render() {
    return (
      <div className="project-canvas-kanban__list-name" style={this.props.nameStyle}>
        {this.props.name}
      </div>
    );
  }
};
