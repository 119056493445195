const React = require('react');
const Tooltip = require('../../../../../../../common/react/TooltipFixed/Tooltip.react');

const FrozenDataTooltip = ({ children }) => {
  const message = "This information has been frozen. You won't be able to edit it again.";
  return (
    <Tooltip label={message}>{children}</Tooltip>
  );
};

module.exports = FrozenDataTooltip;
