const React = require('react');
const InputWrapper = require('../../../../../../../../common/react/material-input/SidebarWrapperEditable.react');
const Select = require('./Select.react');

class StyledSelect extends React.Component {
  onChange(name, value) {
    this.props.onChange(value);
  }

  render() {
    return (
      <InputWrapper placeholder={this.props.placeholder} icon={<span />}>
        <Select items={this.props.items}
          value={this.props.value}
          name={this.props.name}
          onChange={this.onChange.bind(this)} />
      </InputWrapper>
    );
  }
}

module.exports = StyledSelect;
