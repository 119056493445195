const { connect } = require('react-redux');
const Component = require('../../components/sidebar/JobOrderField.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isAutoJobOrderEnabled: state.isAutoJobOrderEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorModal: (error) => dispatch(actions.showErrorModal(error)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
