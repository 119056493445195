/* eslint-disable no-shadow */

'use strict';

Wethod.module('TimelineApp.Timeline', function (Timeline, Wethod, Backbone, Marionette, $) {
  this.TimelineModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/timeline/',
  });

  this.TimelineEventModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/timeline/event/',
  });

  var API = {
    getTimeline: function (id) {
      var model = new Timeline.TimelineModel({ id: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    getTimelineCompany: function (options) {
      var page = options.page ? options.page : 0;
      var size = options.size ? options.size : 100;
      var filter = options.filter ? options.filter : '';
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/crm/timeline/global?page=' + page + '&pageSize=' + size + '&search=' + encodeURIComponent(filter),
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    saveEvent: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (model, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(model);
          } else {
            defer.resolve(response.message);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteEvent: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else if (response.code === 409) {
            defer.resolve(response);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('timeline:get', function (id) {
    return API.getTimeline(id);
  });

  Wethod.reqres.setHandler('timeline:overview:get', function (options) {
    return API.getTimelineCompany(options);
  });

  Wethod.reqres.setHandler('timeline:event:save', function (model) {
    return API.saveEvent(model);
  });

  Wethod.reqres.setHandler('timeline:event:delete', function (model) {
    return API.deleteEvent(model);
  });
});
