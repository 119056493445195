const { connect } = require('react-redux');
const Component = require('../../../../../components/Footer/DefaultFooterContent/Actions/CompareVersionsAction/CompareVersionsAction.react');
const actions = require('../../../../../actions');

const mapStateToProps = (state) => ({
  versions: state.versions,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(actions.enterCompareVersionMode()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
