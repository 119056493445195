module.exports.HIDE_MODAL = 'HIDE_MODAL';
module.exports.SHOW_MODAL_APPROVE_ALLOCATION = 'SHOW_MODAL_APPROVE_ALLOCATION';
module.exports.SHOW_MODAL_REJECT_ALLOCATION = 'SHOW_MODAL_REJECT_ALLOCATION';
module.exports.SHOW_MODAL_OUT_OF_SYNC = 'SHOW_MODAL_OUT_OF_SYNC';
module.exports.SHOW_MODAL_CONFIRM_DELETE_ALLOCATION = 'SHOW_MODAL_CONFIRM_DELETE_ALLOCATION';
module.exports.SHOW_MODAL_PLAN_UPON_APPROVAL_ERROR = 'SHOW_MODAL_PLAN_UPON_APPROVAL_ERROR';
module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
module.exports.GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';

module.exports.OWNER_FILTER_CHANGE = 'OWNER_FILTER_CHANGE';

module.exports.SEARCH_FILTER_CHANGE = 'SEARCH_FILTER_CHANGE';

module.exports.ALLOCATION_REJECT_REQUEST = 'ALLOCATION_REJECT_REQUEST';
module.exports.ALLOCATION_REJECT_SUCCESS = 'ALLOCATION_REJECT_SUCCESS';

module.exports.ALLOCATION_APPROVE_REQUEST = 'ALLOCATION_APPROVE_REQUEST';
module.exports.ALLOCATION_APPROVE_SUCCESS = 'ALLOCATION_APPROVE_SUCCESS';

module.exports.ALLOCATION_UPDATE_REQUEST = 'ALLOCATION_UPDATE_REQUEST';
module.exports.ALLOCATION_UPDATE_SUCCESS = 'ALLOCATION_UPDATE_SUCCESS';

module.exports.ALLOCATION_CREATE_REQUEST = 'ALLOCATION_CREATE_REQUEST';
module.exports.ALLOCATION_CREATE_SUCCESS = 'ALLOCATION_CREATE_SUCCESS';

module.exports.ALLOCATION_DELETE_REQUEST = 'ALLOCATION_DELETE_REQUEST';
module.exports.ALLOCATION_DELETE_SUCCESS = 'ALLOCATION_DELETE_SUCCESS';

module.exports.SOLVED_CONFLICTS_APPLY = 'SOLVED_CONFLICTS_APPLY';
