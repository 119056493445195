/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Legend extends React.Component {
  render() {
    return (
      <div className="profile-trends__item-content-legend">
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--positive">&ge; 0%</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--neutral">&lt; 0% and &gt; -5%</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--negative">&le; -5%</div>
      </div>
    );
  }
};
