const { connect } = require('react-redux');
const Component = require('../../../components/modal/TransferHoursModal/TransferHoursTable.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.some((key) => key === 'get-timesheets-to-transfer'),
  timesheets: state.transferData.timesheets,
  updatedTimesheets: state.transferData.updatedTimesheets,
  pageOffset: state.transferData.pageOffset,
  pageLimit: state.transferData.pageLimit,
  hasMorePages: state.transferData.hasMorePages,
  search: state.transferData.search,
  sort: state.transferData.sort,
  order: state.transferData.order,
  filters: state.filters, // The filters are the ones used in the timesheets table
  selectAll: state.transferData.selectAll,
});

const mapDispatchToProps = (dispatch) => ({
  getTimesheets: (offset, limit, search, order, sort, filters) => dispatch(
    actions.getTimesheetsToTransfer(offset, limit, search, order, sort, filters),
  ),
  onSelectAllChange: () => dispatch(actions.selectAllTimesheetsToTransfer()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
