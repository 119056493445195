const { connect } = require('react-redux');
const Component = require('../../../../../common/react/TabSwitcher.react');

const items = [
  {
    key: 'mine',
    name: 'Mine',
  }, {
    key: 'others',
    name: 'Others',
  },
];

const mapStateToProps = (state) => ({
  items,
  current: state.typeFilter,
});

module.exports = connect(mapStateToProps, null)(Component);
