const constants = require('./constants');
const ReviewModel = require('./models/ProjectReview');

const getReviewSuccess = (review) => ({
  type: constants.GET_REVIEW_SUCCESS,
  review,
});

const getReviewRequest = () => ({
  type: constants.GET_REVIEW_REQUEST,
});

const getReviewNotFoundFailure = () => ({
  type: constants.GET_REVIEW_NOT_FOUND_FAILURE,
});

const getReviewCompletedFailure = () => ({
  type: constants.GET_REVIEW_COMPLETED_FAILURE,
});

module.exports.getReview = (id) => (dispatch) => {
  dispatch(getReviewRequest());
  const model = new ReviewModel(null, { id });
  $.when(model.getModel())
    .done((review) => dispatch(getReviewSuccess(review)))
    .fail((error) => {
      if (error.code === 409) {
        dispatch(getReviewCompletedFailure());
      } else {
        dispatch(getReviewNotFoundFailure());
      }
    });
};

module.exports.goToStep = (index) => ({
  type: constants.GO_TO_STEP,
  index,
});

module.exports.editProjectVote = (criterionId, vote) => ({
  type: constants.EDIT_PROJECT_VOTE,
  criterionId,
  vote,
});
module.exports.editPersonVote = (personId, criterionId, vote) => ({
  type: constants.EDIT_PERSON_VOTE,
  criterionId,
  personId,
  vote,
});
module.exports.editPersonNote = (personId, note) => ({
  type: constants.EDIT_PERSON_NOTE,
  personId,
  note,
});
module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.showSaveModal = () => ({
  type: constants.SHOW_SAVE_MODAL,
});
module.exports.showUnsavedChangesModal = () => ({
  type: constants.SHOW_UNSAVED_CHANGES_MODAL,
});
module.exports.showMissingVoteModal = (step) => ({
  type: constants.SHOW_MISSING_VOTE_MODAL,
  step,
});
module.exports.showPersonNoteModal = (personId, note) => ({
  type: constants.SHOW_PERSON_NOTE_MODAL,
  personId,
  note,
});
const saveReviewRequest = () => ({
  type: constants.SAVE_REVIEW_REQUEST,
});
const saveReviewSuccess = () => ({
  type: constants.SAVE_REVIEW_SUCCESS,
});

module.exports.saveReview = (id, review) => (dispatch) => {
  dispatch(saveReviewRequest());
  const model = new ReviewModel(null, { id });
  $.when(model.save(review))
    .done(() => dispatch(saveReviewSuccess()));
};

module.exports.filterPeople = (keyword) => ({
  type: constants.FILTER_PEOPLE,
  keyword,
});
