const React = require('react');
const Menu = require('../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../common/react/Menu/ListMenu/MenuItem.react');
const IconLink = require('../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const IconButton = require('../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Icon = require('../../../../../common/react/Icon/Icon.react');
const IconButtonTooltip = require('../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

module.exports = class Info extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  handleReviewClick() {
    const data = { projectId: this.props.id };
    this.props.showModal(data);
  }

  getReviewButton() {
    if (this.props.isReviewEnabled && this.props.canReview) {
      return (
        <IconButton label="Project and team review"
          onClick={this.handleReviewClick.bind(this)}>
          <span className="wethod-icon wethod-icon-star wethod-icon-star--black" />
        </IconButton>
      );
    }
    return null;
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MenuItem className="wethod-menu__item--link">
          <a href={`#planning/production-plan?project_id:${projectId}`}
            target="_blank"
            rel="noreferrer">
            Production plan
          </a>
        </MenuItem>
        <MenuItem className="wethod-menu__item--link">
          <a href={`/#project/${projectId}/invoice-plan`}
            target="_blank"
            rel="noreferrer">
            Invoice plan
          </a>
        </MenuItem>
        <MenuItem className="wethod-menu__item--link">
          <a href={`/#project-status/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Project status
          </a>
        </MenuItem>
      </Menu>
    );
  }

  setMenuAnchorRef(ref) {
    this.optionsButton = ref;
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  render() {
    return (
      <div className="project-canvas-info">
        <div className="project-canvas-info__description">
          <div
            className="project-canvas-info__job-order-category"
            style={{ backgroundColor: this.props.project_type.color }} />
          <div className="project-canvas-info__description-details">
            <h1 className="project-canvas-info__name">{_.unescape(this.props.name)}</h1>
            <div className="project-canvas-info__secondary">
              <span className="project-canvas-info__client">{_.unescape(this.props.client.corporate_name)}</span>
            </div>
          </div>
        </div>
        <div>
          {this.getReviewButton()}
          <IconLink label="Pipeline"
            href={`#pipeline/projects?id=${this.props.id}`}
            target="_blank">
            <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
          </IconLink>
          <IconLink label="Budget"
            href={`#pipeline/budget/${this.props.id}`}
            target="_blank">
            <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
          </IconLink>
          <IconLink label="Planning"
            href={`#planning/people?project_id=${this.props.id}`}
            target="_blank">
            <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
          </IconLink>
          <IconLink label="Timeline"
            href={`#timeline/${this.props.id}`}
            target="_blank">
            <span className="wethod-icon wethod-icon-timeline wethod-icon-timeline--black" />
          </IconLink>
          <IconLink label="Report"
            href={`#project/${this.props.id}/report`}
            target="_blank">
            <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
          </IconLink>
          <IconButtonTooltip label="More options"
            onClick={this.showMenu.bind(this)}
            rootRef={this.setMenuAnchorRef.bind(this)}>
            <Icon icon="more" />
          </IconButtonTooltip>
          {this.getOptionsMenu(this.props.id)}
        </div>
      </div>
    );
  }
};
