'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BudgetTemplateStore = require('../../apps/core/modules/budget-template/store');
var BudgetTemplateReducer = require('../../apps/core/modules/budget-template/reducer');
var BudgetTemplate = require('../../apps/core/modules/budget-template/index');

Wethod.module('BudgetTemplateApp', function (BudgetTemplateApp, Wethod, Backbone, Marionette, $) {
  BudgetTemplateApp.Controller = {
    show: function () {
      // TODO: change authorization
      var userAuthorizationRequest = Wethod.request('get:pipeline:authorization');
      $.when(userAuthorizationRequest).done(function () {
        var layout = new BudgetTemplateApp.StructureLayoutView();
        layout.render();

        var reducerWrapper = new BudgetTemplateReducer({});

        var store = BudgetTemplateStore(reducerWrapper.reduxReducer);
        var element = React.createElement(BudgetTemplate, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
  };
});
