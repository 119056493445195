const React = require('react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const DateInput = require('../../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');

const IssueDateInput = ({
  name,
  updateErrors,
  error,
  value,
  onChange,
  readOnly,
  disableBeforeEqual,
}) => {
  const constraints = disableBeforeEqual ? ['required', `minDate:${disableBeforeEqual}`] : ['required'];
  return (
    <InputValidator updateErrors={updateErrors} constraints={constraints}>
      <DateInput name={name}
        readOnly={readOnly}
        disableBeforeEqual={disableBeforeEqual}
        label="Issue date"
        id="sidebar-issue-date"
        value={value}
        onChange={onChange}
        errorText={error} />
    </InputValidator>
  );
};

module.exports = IssueDateInput;
