const { connect } = require('react-redux');
const Component = require('../../components/sidebar/TravelSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  travel: state.focusedTravel,
  canEdit: state.permissions.edit,
  isSaving: state.waitingFor.filter((key) => key === 'save-travel').length > 0,
  travelCarrierMetadata: state.travelCarrierMetadata,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  onSave: (travel) => dispatch(actions.updateTravel(travel)),
  onCreate: (travel) => dispatch(actions.createTravel(travel)),
  onDelete: (travel) => dispatch(actions.deleteTravel(travel)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
