/* eslint-disable react/jsx-no-bind */
const React = require('react');

const QuantitySelector = ({ name, quantity, onDecrease, onIncrease, min, max }) => {
  const getDecreaseButton = () => {
    if (min != null && min >= quantity) {
      return <button type="button" className="wethod-icon-button disabled">-</button>;
    }
    return (
      <button type="button"
        className="wethod-icon-button"
        onClick={() => onDecrease(name)}>-
      </button>
    );
  };

  const getIncreaseButton = () => {
    if (max != null && max <= quantity) {
      return <button type="button" className="wethod-icon-button disabled">+</button>;
    }
    return (
      <button type="button"
        className="wethod-icon-button"
        onClick={() => onIncrease(name)}>+
      </button>
    );
  };

  const formatQuantity = () => {
    if (quantity != null) {
      return quantity;
    }
    return ' - ';
  };

  return (
    <div>
      {getDecreaseButton()}
      <span className="travel__people-amount-select--quantity">{formatQuantity()}</span>
      {getIncreaseButton()}
    </div>
  );
};

module.exports = QuantitySelector;
