/* eslint-disable jsx-a11y/label-has-associated-control,react/jsx-no-bind */
const React = require('react');

const CategoryElement = ({
  name, tags, selectedTags, onClick,
}) => {
  const isTagSelected = (id) => (selectedTags
    ? selectedTags.filter((selectedTag) => selectedTag.id === id).length > 0 : false);

  const getTags = () => tags.map((tag) => (
    <label key={tag.id}>
      <input
        type="checkbox"
        checked={isTagSelected(tag.id)}
        onChange={(e) => onClick(e, tag.id)} />
      {tag.name}
    </label>
  ));

  return (
    <div className="suppliers__tags-category">
      <span className="suppliers__tags-category--title">{name}</span>
      {getTags()}
    </div>
  );
};

module.exports = CategoryElement;
