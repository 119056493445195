const React = require('react');
const moment = require('moment');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const SidebarButton = require('../../../../../common/react/OpenSidebarButton.react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');
const Infotip = require('../../../../../common/react/Infotip/Infotip.react');

const PricelistRow = ({ showSidebar, pricelist }) => {
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  function openPricelistSidebar() {
    showSidebar(pricelist.id);
  }

  function classNameRow() {
    return pricelist.default_for_company ? 'pricelists__row-company-default' : '';
  }

  return (
    <TableRow className={classNameRow()}>
      <TableCell className="pricelists__column-name">{pricelist.name}</TableCell>
      <TableCell className="pricelists__column-description">{pricelist.description}</TableCell>
      <TableCell className="pricelists__column-client">{pricelist.client?.corporate_name}</TableCell>
      <TableCell className="pricelists__column-available-from">{formatDate(pricelist.available_from)}</TableCell>
      <TableCell className="pricelists__column-available-to">{formatDate(pricelist.available_to)}</TableCell>
      <TableCell className="pricelists__column-default-markup">
        <ShowIf condition={pricelist.default_for_client || pricelist.default_for_company}>
          <Infotip label="Default" />
        </ShowIf>
      </TableCell>
      <TableCell className="pricelists__column-actions">
        <SidebarButton onClick={openPricelistSidebar} />
      </TableCell>
    </TableRow>
  );
};

module.exports = PricelistRow;
