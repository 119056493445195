const { connect } = require('react-redux');
const Component = require('../../../components/TextArea/TextAreaModal/TextAreaModalBodyView.react');

const mapStateToProps = (state) => ({
  summary: state.brief.summary,
  description: state.brief.description,
  canEdit: state.canEdit,
});

module.exports = connect(mapStateToProps)(Component);
