const constants = require('./constants');
const employeesFilter = require('./services/filterEmployees');
const searchFilter = require('./services/filterKnowledge');
const sortActive = require('./services/sortKnowledge');
const { sortByLanguage } = require('./services/sortEmployees');

class KnowledgeReducer {
  constructor(state) {
    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      languages: [],
      skills: [{ id: 'all', name: 'All', active: true }],
      interests: [{ id: 'all', name: 'All', active: true }],
      employees: [],
      languageFilteredEmployees: [], // List of filtered employees to show in the languages table
      selectedLanguage: null,
      activeSkills: [], // List of active skills
      employeesWithActiveSkills: [], // List of employees with active skills
      filteredSkillEmployees: [], // List of filtered employees to show in the skills table
      keywordSkillEmployees: '', // Search key for employees of the skills table
      filteredSkills: [], // List of filtered skills
      keywordSkills: '', // Search key for skills
      activeInterests: [], // List of active interests
      filteredInterests: [], // List of filtered interests
      keywordInterests: '', // Search key for interests
      employeesWithActiveInterests: [], // List of employees with active interests
      filteredInterestEmployees: [], // List of filtered employees to show in the interests table
      keywordInterestEmployees: '', // Search key for employees of the interests table
      loadLanguageEmployees: { // Pagination for employees in language table
        size: 0,
        page: 0,
      },
      loadSkillEmployees: { // Pagination for employees in skill table
        size: 0,
        page: 0,
      },
      loadInterestEmployees: { // Pagination for employees in interest table
        size: 0,
        page: 0,
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    let selectedLanguage = null;
    let activeSkills = [];
    let skills = [];
    let activeSkillEmployees = [];
    let filteredSkills = [];
    let activeInterests = [];
    let interests = [];
    let filteredInterests = [];
    let activeInterestEmployees = [];
    switch (action.type) {
      case constants.GET_KNOWLEDGE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('knowledge'),
        };
      case constants.GET_KNOWLEDGE_SUCCESS:
        selectedLanguage = action.languages && action.languages.length
          ? action.languages[0].name
          : null;
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'knowledge'),
          languages: action.languages,
          skills: state.skills.concat(action.skills),
          interests: state.interests.concat(action.interests),
          employees: action.employees,
          // Select the first available language, if any
          selectedLanguage,
          languageFilteredEmployees: sortByLanguage(
            employeesFilter.filterByLanguage(action.employees, selectedLanguage),
            selectedLanguage,
          ),
          activeSkills: [],
          employeesWithActiveSkills: action.employees,
          filteredSkillEmployees: action.employees,
          filteredSkills: state.skills.concat(action.skills),
          activeInterests: [],
          filteredInterests: state.interests.concat(action.interests),
          employeesWithActiveInterests: action.employees,
          filteredInterestEmployees: action.employees,
        };
      case constants.CHANGE_LANGUAGE:
        return {
          ...state,
          selectedLanguage: action.language,
          languageFilteredEmployees: sortByLanguage(
            employeesFilter.filterByLanguage(state.employees, action.language),
            action.language,
          ),
          loadLanguageEmployees: { // Reset pagination
            ...state.loadLanguageEmployees,
            page: 1,
          },
        };
      case constants.TOGGLE_SKILL:
        if (action.skill.id === 'all') {
          if (state.activeSkills.length) { // 'All' is not already active
            activeSkills = []; // The only active skill should be 'all'
            skills = state.skills.map((skill) => {
              const active = skill.id === 'all';
              return { ...skill, active };
            }); // Set all skills as not active
            filteredSkills = state.filteredSkills.map((skill) => {
              const active = skill.id === 'all';
              return { ...skill, active };
            }); // Set all skills as not active
          } else {
            activeSkills = state.activeSkills;
            skills = state.skills;
            filteredSkills = state.filteredSkills;
          }
        } else {
          let allActiveState = false; // 'All' should be disabled when selecting another skill
          if (action.skill.active) {
            activeSkills = state.activeSkills.concat(action.skill.id); // Add the given skill to active ones
          } else {
            activeSkills = state.activeSkills.filter((skillId) => skillId !== action.skill.id); // Remove given skill from active ones

            if (!activeSkills.length) { // Activate 'all' when no more skills are active
              allActiveState = true;
            }
          }

          skills = state.skills.map((skill) => { // Update given skill and 'all'
            if (skill.id === action.skill.id) {
              return action.skill;
            } if (skill.id === 'all') {
              return { ...skill, active: allActiveState };
            }
            return skill;
          });
          filteredSkills = state.filteredSkills.map((skill) => { // Update given skill and 'all'
            if (skill.id === action.skill.id) {
              return action.skill;
            } if (skill.id === 'all') {
              return { ...skill, active: allActiveState };
            }
            return skill;
          });
        }
        activeSkillEmployees = employeesFilter.filterBySkills(state.employees, activeSkills);
        return {
          ...state,
          skills,
          filteredSkills,
          activeSkills,
          employeesWithActiveSkills: activeSkillEmployees,
          filteredSkillEmployees: employeesFilter
            .filterByKey(state.keywordSkillEmployees, activeSkillEmployees),
          loadSkillEmployees: { // Reset pagination
            ...state.loadSkillEmployees,
            page: 1,
          },
        };
      case constants.FILTER_SKILL_EMPLOYEES:
        return {
          ...state,
          filteredSkillEmployees: employeesFilter
            .filterByKey(action.keyword, state.employeesWithActiveSkills),
          keywordSkillEmployees: action.keyword,
          loadSkillEmployees: { // Reset pagination
            ...state.loadSkillEmployees,
            page: 1,
          },
        };
      case constants.FILTER_SKILLS:
        return {
          ...state,
          filteredSkills: sortActive(searchFilter(action.keyword, state.skills)),
          keywordSkills: action.keyword,
        };
      case constants.LOAD_LANG_EMPLOYEES:
        return {
          ...state,
          loadLanguageEmployees: {
            size: action.size,
            page: action.page,
          },
        };
      case constants.LOAD_SKILL_EMPLOYEES:
        return {
          ...state,
          loadSkillEmployees: {
            size: action.size,
            page: action.page,
          },
        };
      case constants.TOGGLE_INTEREST:
        if (action.interest.id === 'all') {
          if (state.activeInterests.length) { // 'All' is not already active
            activeInterests = []; // The only active interest should be 'all'
            interests = state.interests.map((interest) => {
              const active = interest.id === 'all';
              return { ...interest, active };
            }); // Set all interests as not active
            filteredInterests = state.filteredInterests.map((interest) => {
              const active = interest.id === 'all';
              return { ...interest, active };
            }); // Set all interests as not active
          } else {
            activeInterests = state.activeInterests;
            interests = state.interests;
            filteredInterests = state.filteredInterests;
          }
        } else {
          let allActiveState = false; // 'All' should be disabled when selecting another interest
          if (action.interest.active) {
            activeInterests = state.activeInterests.concat(action.interest.id); // Add the given interest to active ones
          } else {
            activeInterests = state.activeInterests
              .filter((interestId) => interestId !== action.interest.id); // Remove given interest from active ones

            if (!activeInterests.length) { // Activate 'all' when no more interests are active
              allActiveState = true;
            }
          }

          interests = state.interests.map((interest) => { // Update given interest and 'all'
            if (interest.id === action.interest.id) {
              return action.interest;
            } if (interest.id === 'all') {
              return { ...interest, active: allActiveState };
            }
            return interest;
          });
          filteredInterests = state.filteredInterests.map((interest) => { // Update given interest and 'all'
            if (interest.id === action.interest.id) {
              return action.interest;
            } if (interest.id === 'all') {
              return { ...interest, active: allActiveState };
            }
            return interest;
          });
        }
        activeInterestEmployees = employeesFilter
          .filterByInterests(state.employees, activeInterests);
        return {
          ...state,
          interests,
          filteredInterests,
          activeInterests,
          employeesWithActiveInterests: activeInterestEmployees,
          filteredInterestEmployees: employeesFilter
            .filterByKey(state.keywordInterestEmployees, activeInterestEmployees),
          loadInterestEmployees: { // Reset pagination
            ...state.loadInterestEmployees,
            page: 1,
          },
        };
      case constants.FILTER_INTERESTS:
        return {
          ...state,
          filteredInterests: sortActive(searchFilter(action.keyword, state.interests)),
          keywordInterests: action.keyword,
        };
      case constants.FILTER_INTEREST_EMPLOYEES:
        return {
          ...state,
          filteredInterestEmployees: employeesFilter
            .filterByKey(action.keyword, state.employeesWithActiveInterests),
          keywordInterestEmployees: action.keyword,
          loadInterestEmployees: { // Reset pagination
            ...state.loadInterestEmployees,
            page: 1,
          },
        };
      case constants.LOAD_INTEREST_EMPLOYEES:
        return {
          ...state,
          loadInterestEmployees: {
            size: action.size,
            page: action.page,
          },
        };
      default:
        return state;
    }
  }
}

module.exports = KnowledgeReducer;
