const Parser = require('../../../../../../services/ParserService');
const HTTP = require('../../../../../../services/HTTPService');

/*
Example of usage

----- Require with:
const MilestoneModel = require('../models/MilestoneModel');

----- Creation:

let data = {
    "name": "name", // at least an empty string
    "description": "descr", // at least an empty string
    "date": "date", // 2017-11-03
    "project": project_id,
    "type": 1 // fixed to one for now
};

let milestoneModel = new MilestoneModel(data);
milestoneModel.persist();

----- Deletion:
let milestoneModel = new MilestoneModel(this.props.event);
milestoneModel.destroy();

*/

/**
 * Model to persist Milestones
 */
const MilestoneModel = Backbone.Model.extend({
  urlRoot: `${APIURL}/event/`,
  parse: Parser.parse,
  persist: HTTP.persist,
});

module.exports = MilestoneModel;
