const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');

const StartReviewModal = ({ data, onCancelClick }) => {
  const getBody = () => {
    if (!data || !data.id) {
      return (
        <div>
          Request has been sent.
          <div className="profile-contact-info__actions">
            <button type="button" onClick={onCancelClick} className="wethod-button">Ok</button>
          </div>
        </div>
      );
    }
    return (
      <div>
        Request has been sent.<br /><br />
        Do you want to <b>review the project and its team </b>now?
        <div className="profile-contact-info__actions">
          <button type="button" onClick={onCancelClick} className="wethod-button">I'll do it later</button>
          <a className="wethod-button wethod-button--confirm"
            href={`/#project/${data.request.project}/review/${data.id}`}>
            Yes
          </a>
        </div>
      </div>
    );
  };

  return (
    <Modal title="Project & team review" onCancelClick={onCancelClick} className="review-changelog__modal">
      {getBody()}
    </Modal>
  );
};

module.exports = StartReviewModal;
