const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../Modal.react');
const ModalContent = require('../ModalContent.react');
const ModalBody = require('../ModalBody.react');
const ModalBodyBlock = require('../ModalBodyBlock.react');
const ModalFooter = require('../ModalFooter.react');
const ModalFooterAction = require('../ModalFooterAction.react');

const SimpleModal = ({ onClose, title, children }) => (
  <Modal onClose={onClose} title={title}>
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>{children}</ModalBodyBlock>
      </ModalBody>
      <ModalFooter>
        <ModalFooterAction onClick={onClose}>OK</ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

SimpleModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

module.exports = SimpleModal;
