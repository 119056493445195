const constants = require('./constants');

const saveEventSuccess = (event) => ({
  type: constants.SAVE_EVENT_SUCCESS,
  event,
});

const saveEventRequest = () => ({
  type: constants.SAVE_EVENT_REQUEST,
});

module.exports.saveEvent = (event) => (dispatch) => {
  dispatch(saveEventRequest());
  const model = new Wethod.TimelineApp.Timeline.TimelineEventModel(event);
  Wethod.request('timeline:event:save', model)
    .then((response) => {
      dispatch(saveEventSuccess(response.attributes));
    });
};

const saveFollowUpSuccess = (event) => ({
  type: constants.SAVE_FOLLOWUP_SUCCESS,
  event,
});

const saveFollowUpRequest = () => ({
  type: constants.SAVE_FOLLOWUP_REQUEST,
});

module.exports.saveFollowUp = (event) => (dispatch) => {
  dispatch(saveFollowUpRequest());
  const model = new Wethod.TimelineApp.Timeline.TimelineEventModel(event);
  Wethod.request('timeline:event:save', model)
    .then((response) => {
      dispatch(saveFollowUpSuccess(response.attributes));
    });
};

const updateFollowUpSuccess = (event) => ({
  type: constants.UPDATE_FOLLOWUP_SUCCESS,
  event,
});

const updateFollowUpRequest = () => ({
  type: constants.UPDATE_FOLLOWUP_REQUEST,
});

module.exports.updateFollowUp = (event) => (dispatch) => {
  dispatch(updateFollowUpRequest());
  const model = new Wethod.TimelineApp.Timeline.TimelineEventModel(event);
  Wethod.request('timeline:event:save', model)
    .then((response) => {
      dispatch(updateFollowUpSuccess(response.attributes));
    });
};

const updateEventSuccess = (event) => ({
  type: constants.UPDATE_EVENT_SUCCESS,
  event,
});

const updateEventRequest = (event) => ({
  type: constants.UPDATE_EVENT_REQUEST,
  event,
});

module.exports.updateEvent = (event) => (dispatch) => {
  dispatch(updateEventRequest(event));
  const model = new Wethod.TimelineApp.Timeline.TimelineEventModel(event);
  Wethod.request('timeline:event:save', model)
    .then((response) => {
      dispatch(updateEventSuccess(response.attributes));
    });
};

const deleteEventSuccess = (event) => ({
  type: constants.DELETE_EVENT_SUCCESS,
  event,
});

const deleteEventRequest = () => ({
  type: constants.DELETE_EVENT_REQUEST,
});

module.exports.deleteEvent = (event) => (dispatch) => {
  dispatch(deleteEventRequest());
  const model = new Wethod.TimelineApp.Timeline.TimelineEventModel(event);
  Wethod.request('timeline:event:delete', model)
    .then(() => {
      dispatch(deleteEventSuccess(event));
    });
};
module.exports.openNewEventForm = () => ({
  type: constants.OPEN_NEW_EVENT_FORM,
});
module.exports.closeNewEventForm = () => ({
  type: constants.CLOSE_NEW_EVENT_FORM,
});
module.exports.openEditEventForm = (event) => ({
  type: constants.OPEN_EDIT_EVENT_FORM,
  event,
});
module.exports.closeEditEventForm = () => ({
  type: constants.CLOSE_EDIT_EVENT_FORM,
});
module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showDeleteEventModal = (event) => ({
  type: constants.SHOW_DELETE_EVENT_MODAL,
  event,
});

module.exports.showEventDetails = (event) => ({
  type: constants.SHOW_EVENT_DETAILS,
  event,
});

module.exports.hideEventDetails = () => ({
  type: constants.HIDE_EVENT_DETAILS,
});

module.exports.filterEvents = (keyword) => ({
  type: constants.FILTER_EVENTS,
  keyword,
});
