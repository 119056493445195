const { connect } = require('react-redux');
const Component = require('../components/CalendarRowDay.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  zoom: state.zoom,
  selectedProject: state.selected_project,
  highlightWeekend: state.highlight_weekend,
});

const mapDispatchToProps = (dispatch) => ({
  showMissingProjectModal: () => dispatch(actions.showModal('missing-project')),
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
  selectProject: (projectId) => dispatch(actions.getProjectAvailability(projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
