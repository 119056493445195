const $ = require('jquery');

const TYPE_STANDARD = 'TYPE_STANDARD';
const TYPE_FILE = 'TYPE_FILE';

const Request = (options, type = TYPE_STANDARD) => {
  const defer = $.Deferred();

  /**
   * Return true if response.data.code is in the 2xx range.
   * @param response
   * @return {boolean}
   */
  const isReallySuccessful = (response) => {
    const successRegex = /^2\d\d$/;

    return successRegex.test(response.code);
  };

  const onError = (error) => {
    // The server returned a HTTP 500 (a "real" 500, not the code hidden in the response content).
    // The error response may or may not have a JSON content.
    const { responseJSON } = error;
    if (responseJSON) {
      defer.reject(responseJSON.data || responseJSON.message, responseJSON.code);
    } else {
      defer.reject(null, error.status);
    }
  };

  const onSuccess = (response) => {
    // The server returned a 200 HTTP status code. Our server tries to always return 200 and hides
    // the actual status code in the response content, so here we may still be in a state of error.
    if (isReallySuccessful(response)) {
      defer.resolve(response.data);
    } else {
      defer.reject(response.data || response.message, response.code);
    }
  };

  /**
   * Ajax settings common for all request types.
   * @type {{method, xhrFields: {withCredentials: boolean}, crossDomain: boolean, url: string}}
   */
  const commonSettings = {
    method: options.method,
    crossDomain: true,
    url: APIURL + options.url,
    xhrFields: {
      withCredentials: true,
    },
  };

  /**
   * Ajax settings specific to standard request types.
   * @type {{method, xhrFields: {withCredentials: boolean}, data: string, crossDomain: boolean, contentType: string, url: string}}
   */
  const standardSettings = {
    ...commonSettings,
    contentType: 'application/json',
    data: JSON.stringify(options.data),
  };

  /**
   * Ajax settings specific to file request types.
   * @type {{processData: boolean, method, xhrFields: {withCredentials: boolean}, data, crossDomain: boolean, contentType: boolean, url: string}}
   */
  const fileSettings = {
    ...commonSettings,
    contentType: false,
    processData: false,
    data: options.data,
  };

  const settingsMap = {
    [TYPE_STANDARD]: standardSettings,
    [TYPE_FILE]: fileSettings,
  };

  /**
   * Perform an asynchronous HTTP (Ajax) request.
   * @return {*}
   */
  const perform = () => $.ajax(settingsMap[type]).done(onSuccess).fail(onError);

  perform();

  return defer.promise();
};

module.exports = Request;
module.exports.TYPE_STANDARD = TYPE_STANDARD;
module.exports.TYPE_FILE = TYPE_FILE;
