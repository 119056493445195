const React = require('react');
const Accordion = require('./DashboardAccordion.react');
const FilledTag = require('../../../../../../common/react/Tag/FilledTag.react');

const WarningAccordion = ({ title, subtitle, tag = 'Mandatory', counter, children }) => (
  <Accordion title={title}
    subtitle={subtitle}
    tag={tag}
    counter={counter}
    counterColor={FilledTag.COLORS.RASPBERRY_RED}>
    {children}
  </Accordion>
);

module.exports = WarningAccordion;
