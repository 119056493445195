'use strict';

/* eslint-disable */
Wethod.module('PipelineApp.Pipeline', function (Pipeline, Wethod, Backbone, Marionette, $, _) {
  // modal
  var modalW = 350;
  var modalH = 200;
  this.ModalItemView = Marionette.ItemView.extend({
    template: '#pipelineModalTemplate',
    className: 'modalWrapper',
    _externalModel: undefined,
    _externalView: undefined,
    ui: {
      modalEL: '.pipelinemodalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      this.ui.messageEl.html('Archiving...');
      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');

        switch (this.model.get('action')) {
          case 'closeNewProject':
            Pipeline.removeModel(this._externalModel);
            Pipeline.closeModal(this);
            break;
          case 'deleteProject':
            Pipeline.deleteModel(this._externalModel, this);
            break;
          case 'archiveProject':
            this.archiveProject(this);
            break;
          case 'unArchiveProject':
            Pipeline.archiveProject(this._externalModel, false);
            Pipeline.removeModel(this._externalModel);
            Pipeline.closeModal(this);
            break;
        }
      }
    },
    archiveProject: function (modal) {
      var pipelineRowModel = this._externalModel;
      var canStartReview = Wethod.PipelineApp.authorization.get('review');
      var requestReviewConfig = pipelineRowModel.get('project_type').request_review;
      var showReviewModal = requestReviewConfig !== null && pipelineRowModel.get('project').estimate >= (requestReviewConfig / 1000);

      // Wait for the response of the UPDATE request.
      if (!Pipeline.projectReviewEnabled || !canStartReview || !showReviewModal) {
        $.when(Pipeline.archiveProject(pipelineRowModel, true))
          .done(function (isArchived, errorMessage) {
            // Once the response arrives, close the 'Are you sure' modal
            Pipeline.removeModel(pipelineRowModel);
            Pipeline.closeModal(modal);

            if (isArchived === true) {
              // If the project was actually archived, remove the model and the view from the
              // current projects pipeline.
              pipelineRowModel.destroy();
              $('.hideNotActive').removeClass('active');
              // nascondo il layer che nasconde i progetti non attivi
              $('[data-region="hideNotActiveProject"]').removeClass('active');
            } else {
              // If the project can't be archived, show a modal with the error message.
              var data = {
                title: 'PROJECT ARCHIVE',
                text: errorMessage,
                buttonOkText: undefined,
                buttonKoText: 'CLOSE',
                action: 'closeModal',
              };
              Pipeline.showModal(data, pipelineRowModel);
            }
          });
      } else {
        $.when(Pipeline.archiveProject(pipelineRowModel, true))
          .done(function (isArchived, errorMessage) {
            $.when(Wethod.request('get:project:review:last', { projectId: pipelineRowModel.get('project').id }))
              .done(function (lastReview) {
                // Once the response arrives, close the 'Are you sure' modal
                Pipeline.removeModel(pipelineRowModel);
                Pipeline.closeModal(modal);

                if (isArchived === true) {
                  // If the project was actually archived, remove the model and the view from the
                  // current projects pipeline.
                  pipelineRowModel.destroy();
                  $('.hideNotActive').removeClass('active');
                  // nascondo il layer che nasconde i progetti non attivi
                  $('[data-region="hideNotActiveProject"]').removeClass('active');
                  Pipeline.showModalAskForReview(lastReview, pipelineRowModel.get('project').id);
                } else {
                  // If the project can't be archived, show a modal with the error message.
                  var data = {
                    title: 'PROJECT ARCHIVE',
                    text: errorMessage,
                    buttonOkText: undefined,
                    buttonKoText: 'CLOSE',
                    action: 'closeModal',
                  };
                  Pipeline.showModal(data, pipelineRowModel);
                }
              });
          });
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        this.destroy();
      }
    },
  });
});
