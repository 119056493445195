/* eslint-disable class-methods-use-this */
const Section = require('./Section');

module.exports = class SwitchCompanySection extends Section {
  // eslint-disable-next-line no-useless-constructor
  constructor(section, userPermissions) {
    super(section, userPermissions);
  }

  isVisible() {
    const nonPlaygroundCompanies = Wethod.userInfo.attributes.companies_available
      .filter((company) => company.name !== 'playground');

    return nonPlaygroundCompanies.length > 1;
  }
};
