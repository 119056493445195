const React = require('react');
const Icon = require('../../../../../../../../common/react/Icon/Icon.react');
const BudgetFooterLink = require('./BudgetFooterLink.react');

const CanvasLink = ({ projectId }) => (
  <BudgetFooterLink noBorder label="Canvas" href={`#project/${projectId}/canvas`}>
    <Icon icon="canvas" />
  </BudgetFooterLink>
);

module.exports = CanvasLink;
