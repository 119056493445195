const React = require('react');
const TagsInput = require('../../../../../../common/react/Tag/TagsInput/TagsInput.react');

module.exports = class Skills extends React.Component {
  getContent() {
    if (this.props.tags.length > 0) {
      return <TagsInput tags={this.props.tags} knownTags={this.props.knownTags} />;
    }
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile__skills">{this.getContent()}</div>
    );
  }
};
