const React = require('react');
const Table2 = require('../../../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const BodyRow = require('./TableBodyRow.react');
const HeaderRow = require('./TableHeaderRow.react');
const FormValidator = require('../../../../../../../common/react/FormValidator/FormValidator.react');

module.exports = class Table extends React.Component {
  static loadMore() {
    return null;
  }

  static getEmptyMessage() {
    return <span>No job titles yet. Click "Add job title" to create one</span>;
  }

  constructor(props) {
    super(props);

    this.state = { tableHeight: 375 };
  }

  getRows() {
    const defaultLocation = this.props.items.find((item) => item.default);
    return this.props.items ? this.props.items.sort((a, b) => {
      if (a.default === b.default) {
        return 0;
      }
      if (a.default) {
        return -1;
      }
      return 1;
    }).map((item) => (
      <FormValidator key={item.id}>
        <BodyRow item={item} defaultLocation={defaultLocation} />
      </FormValidator>
    )) : [];
  }

  hasMore() {
    return this.props.isWaiting;
  }

  render() {
    return (
      <div ref={(ref) => this.containerRef = ref} className="company-settings__table">
        <Table2 maxHeight={this.state.tableHeight}>
          <TableHead>
            <HeaderRow />
          </TableHead>
          <TableBody hasMore={this.hasMore()}
            loadMore={Table.loadMore}
            empty={Table.getEmptyMessage()}
            editable>
            {this.getRows()}
          </TableBody>
        </Table2>
      </div>
    );
  }
};
