const constants = require('./constants');

class AllocationRequestsReducer {
  constructor(state) {
    this.state = {
      ...state,
      modalToShow: null,
      modalData: null,
      showSidebar: false,
      waitingFor: [],
      ownerFilter: state.ownerFilter, // mine|other|all
      searchFilter: state.searchFilter,
      items: [],
      focusedItem: null,
      pageLimit: 0,
      pageOffset: 0,
      hasMorePages: true,
      sortBy: {
        col: 'status',
        order: 'desc',
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SHOW_MODAL_OUT_OF_SYNC:
        return {
          ...state,
          modalToShow: 'out-of-sync',
          waitingFor: [],
          modalData: null,
        };
      case constants.SHOW_MODAL_APPROVE_ALLOCATION:
        return {
          ...state,
          modalToShow: 'allocation-approve',
          modalData: action.item,
        };
      case constants.SHOW_MODAL_REJECT_ALLOCATION:
        return {
          ...state,
          modalToShow: 'allocation-reject',
          modalData: action.item,
        };
      case constants.SHOW_MODAL_CONFIRM_DELETE_ALLOCATION:
        return {
          ...state,
          modalToShow: 'allocation-confirm-delete',
          modalData: action.item,
        };
      case constants.SHOW_MODAL_PLAN_UPON_APPROVAL_ERROR:
        return {
          ...state,
          modalToShow: 'plan-upon-approval',
          waitingFor: [],
          modalData: null,
        };
      case constants.SHOW_SIDEBAR:
        return ({
          ...state,
          showSidebar: true,
          focusedItem: action.item,
        });
      case constants.CLOSE_SIDEBAR:
        return ({
          ...state,
          showSidebar: null,
          focusedItem: null,
        });
      case constants.SOLVED_CONFLICTS_APPLY: {
        return {
          ...state,
          items: state.items.map((item) => {
            const solvedItem = action.items.find((solvedConflict) => solvedConflict.id === item.id);

            return solvedItem || item;
          }),
        };
      }
      case constants.GET_ITEMS_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          sortBy: action.sortBy,
          hasMorePages: true,
          items: action.offset ? state.items : [],
          waitingFor: state.waitingFor.concat('get-items'),
        };
      }
      case constants.GET_ITEMS_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.items.length === state.pageLimit,
          items: state.items.concat(action.items),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-items'),
        };
      }
      case constants.OWNER_FILTER_CHANGE:
        return {
          ...state,
          ownerFilter: action.key,
        };
      case constants.SEARCH_FILTER_CHANGE:
        return {
          ...state,
          searchFilter: action.key,
        };
      case constants.ALLOCATION_APPROVE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('allocation-approve'),
        };
      }
      case constants.ALLOCATION_APPROVE_SUCCESS: {
        return {
          ...state,
          items: state.items.map((item) => (item.id === action.id ? ({
            ...item,
            status: 'approved',
          }) : item)),
          waitingFor: state.waitingFor.filter((key) => key !== 'allocation-approve'),
        };
      }
      case constants.ALLOCATION_REJECT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('allocation-reject'),
        };
      }
      case constants.ALLOCATION_REJECT_SUCCESS: {
        return {
          ...state,
          items: state.items.map((item) => (item.id === action.id ? ({
            ...item,
            status: 'rejected',
          }) : item)),
          waitingFor: state.waitingFor.filter((key) => key !== 'allocation-reject'),
        };
      }
      case constants.ALLOCATION_UPDATE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-allocation'),
        };
      }
      case constants.ALLOCATION_UPDATE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-allocation'),
          items: state.items.map((item) => {
            if (item.id === action.item.id) {
              return {
                ...item,
                ...action.item,
              };
            }
            return item;
          }),
        };
      }
      case constants.ALLOCATION_CREATE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-allocation'),
        };
      }
      case constants.ALLOCATION_CREATE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-allocation'),
          items: [action.item].concat(state.items),
        };
      }
      case constants.ALLOCATION_DELETE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('allocation-delete'),
        };
      }
      case constants.ALLOCATION_DELETE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'allocation-delete'),
          items: state.items.filter((item) => item.id !== action.id),
        };
      }
      default:
        return state;
    }
  }
}

module.exports = AllocationRequestsReducer;
