const React = require('react');
const TextArea = require('../../../../../../../../../common/react/material-input/SidebarTextArea.react');

const CommentInput = ({ value, onChange }) => (
  <div className="supplier__modal-rating-input">
    <h3 className="wethod-sidebar__subtitle">Written review</h3>
    <TextArea name="comment"
      value={value}
      rows={5}
      onChange={onChange} />
  </div>
);

module.exports = CommentInput;
