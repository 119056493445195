/* eslint-disable react/prop-types */
const React = require('react');
const Avatar = require('../../../../AvatarBox/Avatar.react');

module.exports = class HeaderItemUser extends React.Component {
  onClick(e) {
    if (!this.props.url) {
      e.preventDefault();
    }
    this.props.onClick(this.props.module);
  }

  getIcon() {
    const icon = this.props.module.getIcon();
    const baseName = 'app-nav__first-level-item-icon app-nav__first-level-item-icon';
    if (icon) {
      return (
        <span
          className={`${baseName} wethod-icon-${icon} wethod-icon-${icon}--black`} />
      );
    }
    return null;
  }

  getClassName() {
    let name = 'app-nav__first-level-item app-nav__first-level-item--profile-pic';
    if (this.props.isCurrent) {
      name += ' app-nav__first-level-item--current';
    }
    if (this.props.isClicked) {
      name += ' app-nav__first-level-item--clicked';
    }
    return name;
  }

  getUrl() {
    const url = this.props.module.getUrl();
    return url || '#';
  }

  render() {
    const info = Wethod.userInfo.attributes;
    const name = `${info.name} ${info.surname}`;

    return (
      <li className={this.getClassName()}>
        <a href={this.getUrl()} onClick={this.onClick.bind(this)}>
          <Avatar name={name} pic={info.pic} />
        </a>
      </li>
    );
  }
};
