/* eslint-disable react/sort-comp */
const React = require('react');
const Member = require('./CardMember.react');
const FormatService = require('../../../../../../../services/FormatService');

module.exports = class Card extends React.Component {
  getDate() {
    const attributes = this.props.attributes
      .filter((attribute) => attribute.attribute === this.props.dateAttributeId);
    if (attributes.length) {
      const { value } = attributes[0];
      if (value) {
        return value.start_date === value.end_date ? FormatService.formatDate(value.start_date)
          : `${FormatService.formatDate(value.start_date)} - ${FormatService.formatDate(value.end_date)}`;
      }
    }
    return null;
  }

  getMembers() {
    const attributes = this.props.attributes
      .filter((attribute) => attribute.attribute === this.props.membersAttributeId);
    if (attributes.length) {
      const members = attributes[0].value;
      if (members) {
        return members.map((member) => <Member key={member.id} {...member} />);
      }
    }
    return null;
  }

  getMembersComponent() {
    const members = this.getMembers();
    if (members) {
      return <div className="project-canvas-kanban__card-members">{members}</div>;
    }
    return null;
  }

  getDateComponent() {
    const date = this.getDate();
    if (date) {
      return <div className="project-canvas-kanban__card-date">{date}</div>;
    }
    return null;
  }

  handleClick() {
    this.props.showSidebar(this.props.id);
  }

  render() {
    return (
      <div className="project-canvas-kanban__card"
        data-id={this.props.id}
        onClick={this.handleClick.bind(this)}>
        <div className="project-canvas-kanban__card-description">{this.props.name}</div>
        {this.getMembersComponent()}
        {this.getDateComponent()}
      </div>
    );
  }
};
