const React = require('react');
const AdvancedFilters = require('../../containers/advanced-search/PipelineAdvancedFilters');

const PipelineSegment = ({
  show, segment, onClose, type, getStandardFilters, getFiltersFromStandard, errors, updateErrors,
  isValid, onCreate, onUpdate,
}) => {
  const sidebarToShow = show ? 'segment' : null;

  const standardFilters = segment ? getStandardFilters(segment.filters) : null;

  const formattedSegment = segment ? {
    ...segment,
    filters: standardFilters,
  } : null;

  function handleSave(updatedSegment) {
    const formattedFilters = getFiltersFromStandard(updatedSegment.filters);

    if (formattedSegment && formattedSegment.id) { // Update existing segment
      onUpdate({
        ...updatedSegment,
        filters: formattedFilters,
        section: type,
      });
    } else {
      onCreate({
        ...updatedSegment,
        filters: formattedFilters,
        section: type,
      });
    }
  }

  return (
    <AdvancedFilters show={sidebarToShow}
      onClose={onClose}
      onSave={handleSave}
      segment={formattedSegment}
      canSave={isValid}
      type={type}
      errors={errors}
      updateErrors={updateErrors} />
  );
};

module.exports = PipelineSegment;
