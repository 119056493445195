const { connect } = require('react-redux');
const Component = require('../components/ConfirmDeleteModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'save-review').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmClick: (request) => dispatch(actions.deleteReviewRequest(request)),
  onCancelClick: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
