const React = require('react');
const _ = require('underscore');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');
const Autocomplete = require('../../../../../../common/react/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const ClientModel = require('../../../../../../models/Client');

module.exports = class ClientSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.corporate_name);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    ClientModel.list({ search: keyword })
      .done((collection) => this.setState({
        availableItems: collection,
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const clientId = item ? item.value : null;
    let selectedValue = this.state.availableItems.filter((client) => client.id === clientId);

    selectedValue = selectedValue && selectedValue.length > 0 ? selectedValue[0] : null;

    const { name } = e.target;

    this.props.onChange(name, selectedValue);
  }

  getOptions() {
    return this.state.availableItems.map((client) => (
      <SelectItem key={client.id}
        value={client.id}>
        {_.unescape(client.corporate_name)}
      </SelectItem>
    ));
  }

  getValue() {
    if (!this.props.value) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={[]}>
        <Autocomplete readOnly={this.props.readOnly}
          label={this.props.label}
          onChange={this.onChange.bind(this)}
          value={this.getValue()}
          getInputValue={ClientSelect.getInputValue}
          name={this.props.name}
          id={this.props.id}
          onFilter={this.onFilter.bind(this)}
          errorText={this.props.errorText}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
