const React = require('react');
const Modal = require('../../../../../../../common/react/widgets/Files/modals/CannotSyncModal.react');

const CannotSyncModal = ({ close, createFolder, missingPath, isWaiting }) => (
  <Modal onCancelClick={close}
    createFolder={createFolder}
    path={missingPath}
    isWaiting={isWaiting} />
);

module.exports = CannotSyncModal;
