const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.length > 0,
  canEdit: state.canEdit,
  buEnabled: state.buEnabled,
  buFilter: state.buFilter,
  keyword: state.keyword,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
});

const mapDispatchToProps = (dispatch) => ({
  showSidebar: (supplier) => dispatch(actions.showSidebar(supplier)),
  getSuppliers: (offset, limit, search, orderBy, sort, bu) => dispatch(actions
    .getSuppliers(offset, limit, search, orderBy, sort, bu)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
