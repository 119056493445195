const React = require('react');
const Modal = require('../../../../../../common/react/modals/ErrorModal.react');
const WrongDataModal = require('./WrongImportDataModal.react');
const WrongColumnModal = require('./MissingColumnModal.react');

/**
 * Shows the correct import error modal depending on the error occurred.
 * When the error has a message but no additional data, the modal shows only the message.
 * Otherwise, depending on the data, different modals are shown:
 * - missing columns in the file
 * - some data in the file has errors
 * @param data
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ data, onClose }) => {
  const message = data && data.message ? data.message : undefined;
  const errorData = data && data.data ? data.data : undefined;

  function hasWrongColumns() {
    return Array.isArray(errorData);
  }

  function getWrongColumnsModal() {
    return <WrongColumnModal onClose={onClose} data={errorData} />;
  }

  function getWrongDataModal() {
    return <WrongDataModal onClose={onClose} data={errorData} />;
  }

  function getErrorMessageModal() {
    return <Modal onCancelClick={onClose} data={message} />;
  }

  function getModal() {
    if (errorData) {
      if (hasWrongColumns()) {
        return getWrongColumnsModal();
      }

      return getWrongDataModal();
    }

    // Modal with no additional data can show the error message
    return getErrorMessageModal();
  }

  return getModal();
};

module.exports = ErrorModal;
