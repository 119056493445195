const React = require('react');
const HeaderBoxSecondaryGroup = require('../HeaderBox/HeaderBoxSecondaryGroup.react');
const ProjectInfoSecondaryValue = require('../ProjectInfo/ProjectInfoSecondaryValue.react');
const RadialIndicator = require('../../../../../../../common/react/RadialIndicator/RadialIndicator.react');
const NumberFormatter = require('../../../../../../../common/react/formatters/NumberFormatter.react');

/**
 * Contains secondary price info described by label, value and radial percentage.
 * @param label
 * @param value
 * @param percentage
 * @param tooltip
 * @returns {JSX.Element}
 * @constructor
 */
const PriceSecondaryItem = ({
  label,
  value,
  percentage,
  tooltip,
}) => {
  const getColor = () => {
    if (percentage < 0) {
      return '#DB4D69';
    }
    if (percentage > 0) {
      return '#5BD87C';
    }
    return '#000';
  };

  return (
    <HeaderBoxSecondaryGroup
      label={label}
      tooltipMessage={tooltip}
      value={(
        <ProjectInfoSecondaryValue color={getColor()}>
          <NumberFormatter>{value}</NumberFormatter>
        </ProjectInfoSecondaryValue>
        )}
      icon={<RadialIndicator value={percentage} color={getColor()} />} />
  );
};

module.exports = PriceSecondaryItem;
