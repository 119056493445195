const request = require('./Request');

const SuppliersAutocomplete = {
  get(search = '') {
    const searchQuery = search.length > 0 ? `?hints=${encodeURIComponent(search)}` : '';

    return request({
      method: 'get',
      url: `/supplier/autocomplete${searchQuery}`,
    });
  },
};

module.exports = SuppliersAutocomplete;
