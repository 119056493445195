/**
 *
 * Sort item by 'active' attribute,
 * the active one will be shown first.
 * The item 'all' will always be the first one.
 *
 * @param items
 * @returns {Array} items sorted by active
 */
module.exports = (items) => items.sort((a, b) => {
  if (a.id === 'all') return -1;
  if (b.id === 'all') return 1;

  if (a.active) return -1;
  if (b.active) return 1;
  return 0;
});
