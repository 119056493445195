/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class EmptySearch extends React.Component {
  render() {
    return (
      <div className="project-canvas__file--no-results">No file found</div>
    );
  }
};
