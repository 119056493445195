const { connect } = require('react-redux');
const Component = require('../components/TimeOffSearch.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  keyword: state.keyword,
  year: state.year,
  canEditOthers: state.permissions.edit_other,
});

const mapDispatchToProps = (dispatch) => ({
  showExportModal: (year) => dispatch(actions.showExportModal(year)),
  showImportModal: (year) => dispatch(actions.showImportModal(year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
