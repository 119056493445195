module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.GET_WARNINGS_TOTAL_REQUEST = 'GET_WARNINGS_TOTAL_REQUEST';
module.exports.GET_WARNINGS_TOTAL_SUCCESS = 'GET_WARNINGS_TOTAL_SUCCESS';
module.exports.GET_WARNINGS_TOTAL_ERROR = 'GET_TOTALS_ERROR';

module.exports.GET_UNARCHIVED_PROJECTS_REQUEST = 'GET_UNARCHIVED_PROJECTS_REQUEST';
module.exports.GET_UNARCHIVED_PROJECTS_SUCCESS = 'GET_UNARCHIVED_PROJECTS_SUCCESS';
module.exports.GET_UNARCHIVED_PROJECTS_ERROR = 'GET_UNARCHIVED_PROJECTS_ERROR';

module.exports.GET_OUTSTANDING_PROJECTS_REQUEST = 'GET_OUTSTANDING_PROJECTS_REQUEST';
module.exports.GET_OUTSTANDING_PROJECTS_SUCCESS = 'GET_OUTSTANDING_PROJECTS_SUCCESS';
module.exports.GET_OUTSTANDING_PROJECTS_ERROR = 'GET_OUTSTANDING_PROJECTS_ERROR';

module.exports.GET_INTERCOMPANY_ACTIVITY_REQUEST = 'GET_INTERCOMPANY_ACTIVITY_REQUEST';
module.exports.GET_INTERCOMPANY_ACTIVITY_SUCCESS = 'GET_INTERCOMPANY_ACTIVITY_SUCCESS';
module.exports.GET_INTERCOMPANY_ACTIVITY_ERROR = 'GET_INTERCOMPANY_ACTIVITY_ERROR';

module.exports.GET_ALERTS_REQUEST = 'GET_ALERTS_REQUEST';
module.exports.GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
module.exports.GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';

module.exports.SELECT_SEGMENT = 'SELECT_SEGMENT';

module.exports.UPDATE_SEGMENTS_REQUEST = 'UPDATE_SEGMENTS_REQUEST';
module.exports.UPDATE_SEGMENTS_SUCCESS = 'UPDATE_SEGMENTS_SUCCESS';
module.exports.UPDATE_SEGMENTS_ERROR = 'UPDATE_SEGMENTS_ERROR';
