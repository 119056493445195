const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const FilledTag = require('../../../../../../../common/react/Tag/FilledTag.react');
const CounterTag = require('../CounterTag.react');
const alertLink = require('../../../../../../../common/react/widgets/Alerts/utils/alertLink');

const AlertDetail = ({ item, project }) => {
  const link = alertLink.getAlertLink(item, project);

  return (
    <TableRow className="data-freezing-engine__table-alerts-row">
      <TableCell className="data-freezing-engine__table-alerts-details-column-small">
        {/* Makes the entire row clickable as a link */}
        <a href={link} className="data-freezing-engine__table-alerts-details-link">
          <CounterTag counterColor={FilledTag.COLORS.RASPBERRY_RED} value="" />
        </a>
      </TableCell>
      <TableCell className="data-freezing-engine__table-alerts-details-column-medium">{item.alert_type?.label}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-details-column-large">{item.alert_type?.message}</TableCell>
    </TableRow>
  );
};

module.exports = AlertDetail;
