const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/Marketing.react');

const mapStateToProps = (state) => ({
  marketing: state.month_marketing,
  targetType: state.target_type,
  month: state.month,
  costsOnProduction: state.month_marketing_perc, // NULLABLE
  planned: state.marketing_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
