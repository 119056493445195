const React = require('react');

const FeedbackCannotEdit = ({ title, description }) => (
  <div className="timesheet-detailed__cannot-edit">
    <div className="info-text">
      <p style={{ fontWeight: 'bold' }}>{title}</p>
      <p>{description}</p>
    </div>
  </div>
);

module.exports = FeedbackCannotEdit;
