'use strict';

/* eslint-disable camelcase,no-underscore-dangle */

var React = require('react');
var ReactDOM = require('react-dom');
var InvoicedVsStatusButtons = require('../../../apps/core/modules/report/invoiced-vs-status/index');

Wethod.module('ReportApp.BubblesInvoice', function (BubblesInvoice, Wethod, Backbone, Marionette, $, _) {
  this.chartBubblesInvoice = function (series, maxYAxis) {
    return {
      chart: {
        renderTo: 'statusChart',
        type: 'bubble',
        ignoreHiddenSeries: false,
      },
      title: {
        text: ' ',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        title: {
          text: 'PROJECT STATUS % (days)',
          align: 'high',
        },
        max: 100,
      },
      yAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        title: {
          text: 'INVOICED %  ',
          align: 'high',
        },
        min: 0,
        max: maxYAxis,
      },
      series: series,
      tooltip: {
        formatter: function () {
          return this.series.name;
        },
      },
      plotOptions: {
        bubble: {
          maxSize: 80,
          minSize: 10,
        },
      },
    };
  };

  // layout pagina
  this.BubblesInvoiceLayout = Marionette.LayoutView.extend({
    template: '#bubblesInvoiceStructure',
    className: 'fluid',
    regions: {
      headerSection: '[data-region="reportSectionHeader"]',
      head: '[data-region="reportHeader"]',
      cachedNote: '[data-region="reportCachedNoteWrap"]',
      filterLabel: '[data-region="reportFilterByLabelWrap"]',
      chart: '[data-region="bubbleChart"]',
      info: '[data-region="reportInfo"]',
      mobileInfo: '[data-region="reportInfoMobile"]',
      filters: '[data-region="reportFilters"]',
    },
    ui: {
      notArchived: '[data-action="showCurrent"]',
      showArchivedAndNot: '[data-action="showAll"]',
      onlyArchived: '[data-action="showArchived"]',
    },
    events: {
      'click @ui.notArchived': function (e) {
        this.filterArchive(e, 'notArchived');
      },
      'click @ui.showArchivedAndNot': function (e) {
        this.filterArchive(e, 'showArchivedAndNot');
      },
      'click @ui.onlyArchived': function (e) {
        this.filterArchive(e, 'onlyArchived');
      },
    },
    filterArchive: function (e, filter) {
      e.preventDefault();
      var target = $(e.target);

      if (!target.hasClass('sel')) {
        $('.sel').removeClass('sel');
        target.addClass('sel');
        // pulisco tutti i filtri
        $('[data-action="reportFilterClient"]').val('');
        $('[data-action="reportFilterProject"]').val('');
        $('[data-action="reportFilterProjectType"]').val('');
        $('[data-action="reportFilterPM"]').val('');
        $('[data-action="reportFilterAccount"]').val('');
        $('[data-action="reportFilterJobNumber"]').val('');

        BubblesInvoice.switchArchiveFilter(filter);
      }
    },
  });

  // bubbles cached on
  this.CachedNoteView = Marionette.ItemView.extend({
    template: '#reportCachedNote',
    ui: {
      reloadButton: '[data-action="cachedReloadButton"]',
      reloadButtonLabel: '[data-region="cachedReloadLabel"]',
      reloadDateLabel: '[data-region="cachedReloadDate"]',
    },
    events: {
      'click @ui.reloadButton': 'reloadReport',
    },
    initialize: function (options) {
      this.options = options;
    },
    onShow: function () {
      if (!_.isUndefined(this.options.cached_on)) {
        this.ui.reloadButton.removeClass('hidden');
        this.ui.reloadButtonLabel.text('REFRESH DATA');
        this.ui.reloadDateLabel.removeClass('hidden');
        this.ui.reloadDateLabel.text('last update ' + moment(this.options.cached_on)
          .format('DD/MM/YYYY'));
      }
    },
    reloadReport: function () {
      if (!this.ui.reloadButton.hasClass('disabled')) {
        this.ui.reloadButton.addClass('disabled');
        $(this.ui.reloadDateLabel).addClass('disabled');
        this.ui.reloadButtonLabel.text('LOADING...');
        BubblesInvoice.reloadReport();
      }
    },
  });

  // project info
  this.InfoTemplateView = Marionette.ItemView.extend({
    className: 'col_16 no_padding',
    _template: '#invoiceBubbleReportInfoTemplate',
    ui: {
      infoButtons: '[data-region="reportInfoLink"]',
    },
    _info: undefined,
    _color: undefined,
    initialize: function (options) {
      this._info = options.projectInfo;
      this._color = options.color;

      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var model = serialized_model;
      var projectId = that._info.project.id;

      model.info = that._info;
      model.color = that._color;

      model.linkBudget = '#pipeline/budget/' + projectId;
      model.linkPlanning = '#planning';
      model.linkReport = '#project/' + projectId + '/report';
      model.linkInvoicePlan = '#project/' + projectId + '/invoice-plan';

      return _.template(template)(model);
    },
    onAttach: function () {
      this.showInfoButtons();
    },
    showInfoButtons: function () {
      var container = document.querySelector(this.ui.infoButtons.selector);
      var templateSection = React.createElement(InvoicedVsStatusButtons,
        { info: this._info });
      ReactDOM.render(templateSection, container);
    },
  });

  // content
  this.BubbleInvoiceChartTemplateView = Marionette.ItemView.extend({
    template: '#reportBubblesInvoiceContentTemplate',
    className: 'col_16 no_padding bubbleContainer',
    ui: {
      reportChart: '[data-region="displayReportChart"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onShow: function () {
      this.ui.reportChart
        .highcharts(BubblesInvoice.chartBubblesInvoice(this.options.series, this.options.maxYAxis));
    },
  });

  // report filters
  this.BubblesInvoiceFilterTemplateView = Marionette.ItemView.extend({
    template: '#reportBubblesInvoiceFilterTemplate',
    // _chart      : undefined,
    ui: {
      filters: '.reportFilterInput',
      clientFilters: '[data-action="reportFilterClient"]',
      projectFilters: '[data-action="reportFilterProject"]',
      projectTypeFilters: '[data-action="reportFilterProjectType"]',
      pmFilters: '[data-action="reportFilterPM"]',
      accountFilters: '[data-action="reportFilterAccount"]',
      jobOrderFilters: '[data-action="reportFilterJobNumber"]',
      tagFilters: '[data-action="reportFilterTags"]',
    },
    events: {
      'click @ui.filters': 'selectText',
      'change @ui.filters': 'updateChartFilter',
    },
    initialize: function (options) {
      this.opitons = options;
      // this._chart = options.chart;
    },
    selectText: function (e) {
      e.target.select();
    },
    updateChartFilter: function () {
      var filters = {
        clients: this.ui.clientFilters.val().toLowerCase().trim(),
        projects: this.ui.projectFilters.val().toLowerCase().trim(),
        projectTypes: this.ui.projectTypeFilters.val().toLowerCase().trim(),
        pms: this.ui.pmFilters.val().toLowerCase().trim(),
        accounts: this.ui.accountFilters.val().toLowerCase().trim(),
        jobOrders: this.ui.jobOrderFilters.val().toLowerCase().trim(),
      };

      BubblesInvoice.chartFilter(filters);
    },
  });
});
