const React = require('react');
const BriefModal = require('./Brief/BriefModal/BriefModal.react');
const ConceptModal = require('./Concept/ConceptModal/ConceptModal.react');
const GoalModal = require('../containers/Goals/GoalModal/GoalModal');
const MissingStatusForKanbanModal = require('./Boards/Kanban/MissingStatusForKanbanModal.react');
const ConfirmBoardDeleteModal = require('../containers/Boards/ConfirmBoardDeleteModal');
const ConfirmAttributeDeleteModal = require('../containers/Boards/ConfirmAttributeDeleteModal');
const ConfirmItemDeleteModal = require('../containers/Boards/ConfirmItemDeleteModal');
const RequestReviewModal = require('../containers/RequestReviewModal');
const StartReviewModal = require('./StartReviewModal.react');

const Modal = ({ toShow, canEdit, closeModal, data }) => {
  const getModal = () => {
    switch (toShow) {
      case 'brief':
        return <BriefModal canEdit={canEdit} onCancelClick={closeModal} />;
      case 'concept':
        return <ConceptModal canEdit={canEdit} onCancelClick={closeModal} />;
      case 'goal':
        return <GoalModal canEdit={canEdit} onCancelClick={closeModal} />;
      case 'missing-status-for-kanban':
        return <MissingStatusForKanbanModal canEdit={canEdit} onCancelClick={closeModal} />;
      case 'confirm-delete-board':
        return <ConfirmBoardDeleteModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'confirm-delete-attribute':
        return (
          <ConfirmAttributeDeleteModal canEdit={canEdit}
            onCancelClick={closeModal}
            data={data} />
        );
      case 'confirm-delete-item':
        return <ConfirmItemDeleteModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'request-review':
        return <RequestReviewModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'start-review':
        return <StartReviewModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
