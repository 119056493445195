const React = require('react');

const Cell = ({
  children, className = '', style = {}, setRef = undefined,
  onMouseEnter = undefined, onMouseLeave = undefined, label = '',
}) => (
  <div className={`wethod-budget-calculator__cell ${className}`}
    role="cell"
    aria-label={label}
    style={style}
    ref={setRef}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}>{children}
  </div>
);

module.exports = Cell;
