const React = require('react');
const PropTypes = require('prop-types');

const RadioGroup = ({ children, name, value, onChange, className }) => {
  const augmentWithCommonProps = (child) => React
    .cloneElement(child, {
      id: `${name}-${child.props.value}`,
      name,
      onClick: onChange,
      checked: String(value) === String(child.props.value),
    });

  const getButtons = () => React.Children.map(children, augmentWithCommonProps);

  const getStyle = () => {
    let style = 'wethod-radio-group';
    if (className) {
      style += ` ${className}`;
    }
    return style;
  };

  return (
    <div className={getStyle()} data-testid="wethod-radio-group">{getButtons()}</div>
  );
};

RadioGroup.defaultProps = {
  value: null,
  className: null,
  onChange: null,
};

RadioGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node.isRequired,
  /**
   * This will be assigned to all buttons in this group.
   */
  name: PropTypes.string.isRequired,
  /**
   * The current selected value.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Function to call when group value changes.
   * @param event {{}}
   */
  onChange: PropTypes.func,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
};

module.exports = RadioGroup;
