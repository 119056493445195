/* eslint-disable default-case */

'use strict';

Wethod.module('SettingsApp.Permission', function (Permission, Wethod, Backbone, Marionette, $, _, Header) {
  var structureTemplate = {};
  var savePermission = {};

  var resetData = function () {
    structureTemplate = {};
    savePermission = {};
  };

  var ACTIONS_NAMES_MAP = {
    time_tracking: 'timesheet',
    sales_funnel: 'revenue clock',
    sparkline_product: 'sparkline production',
    bubbles: 'budget consumption',
    bubbles_invoice: 'revenue progress',
    alert: 'alerts',
    '3yp': 'revenue pipeline',
    view_bubbles: 'budget consumption',
    view_3yp: 'revenue pipeline',
    view_details: 'project report',
    view_timesheet: 'timesheet',
    income_statement: 'economics',
  };

  var getDisplayName = function (name) {
    return name in ACTIONS_NAMES_MAP ? ACTIONS_NAMES_MAP[name] : name.replace(/_/g, ' ');
  };

  Permission.displayAppRole = function (appObj) {
    _.each(appObj.model.get('roles'), function (role) {
      var roleAlias = '';

      _.each(role.role.split('_'), function (alias) {
        if (alias !== 'ROLE') {
          if (alias === 'PM') {
            roleAlias = '[PM]*';
          } else if (alias === 'ACCOUNT') {
            roleAlias = '[ACCOUNT]*';
          } else {
            roleAlias = roleAlias + ' ' + alias.toLowerCase();
          }
        }
      });

      var roleModel = new Permission.AuthorizationAppModel({ role: roleAlias });
      var permissionList = [];

      _.each(role.permissions, function (val, key) {
        permissionList.push({
          app: appObj.model.get('app'),
          role: role.role,
          name: key,
          val: val,
        });
      });

      var permissionCollection = new Permission.AuthorizationAppCollection(permissionList);
      var roleTemplate = new Permission.PermissionRoleListCompositeView({
        model: roleModel,
        collection: permissionCollection,
        childViewOptions: {
          appObj: appObj,
        },
      });

      appObj.ui.role.append(roleTemplate.render().$el);
    });
  };

  Permission.addRole = function () {
    var roleModel = new Permission.AuthorizationAppModel({ name: '' });
    var newRoleTemplate = new Permission.NewRoleTemplateItemView({
      model: roleModel,
    });

    $('[data-action="addNewRole"]').addClass('disabled');
    structureTemplate.newRole.show(newRoleTemplate);
  };

  var displayError = function (errors, viewObj) {
    _.each(errors, function (error) {
      _.each(error, function (val, key) {
        switch (key) {
          case 'name':
            viewObj.ui.iconStatus.addClass('error').removeClass('required');
            break;
        }

        viewObj.ui.message.addClass('errorMessage');
        viewObj.ui.message.show();
        viewObj.ui.message.text(val);
      });
    });
  };

  var prepareModulesForDisplay = function (moduleApps) {
    // sort roles
    _.each(moduleApps.models, function (module) {
      _.each(module.get('roles'), function (value, key) {
        if (value.role === 'ROLE_PM') {
          module.get('roles')[key].sort = 999;
        } else {
          module.get('roles')[key].sort = key;
        }
      });
      module.set({
        roles: _.sortBy(module.get('roles'), function (role) {
          return role.sort;
        }),
      });
    });

    // add display name to app and actions
    var actionsDisplayName = [];
    _.each(moduleApps.models, function (module) {
      module.set('appDisplayName', getDisplayName(module.get('app')));

      actionsDisplayName = [];
      _.each(module.get('actions'), function (action) {
        actionsDisplayName.push(getDisplayName(action));
      });
      module.set('actionsDisplayName', actionsDisplayName);
    });
  };

  var showAppTemplate = function () {
    var loadingView = new Permission.LoadingMoreDataTemplateView();
    structureTemplate.body.show(loadingView);

    var permissionRequest = Wethod.request('permission:get:authorization');
    $.when(permissionRequest).done(function (permissionResponse) {
      var moduleApps = new Permission
        .AuthorizationAppCollection(_.toArray(permissionResponse.attributes));
      var moduleAppsTemplate = new Permission.PermissionTemplateCollectionView({
        collection: moduleApps,
      });

      prepareModulesForDisplay(moduleApps);

      loadingView.destroy();
      structureTemplate.body.show(moduleAppsTemplate);
    });
  };

  Permission.insertRole = function (viewObj, val) {
    var role = val.replace(' ', '_').toUpperCase();
    viewObj.model.set({ name: role });
    viewObj.ui.newRoleButtonLabel.text('SAVING...');

    if (viewObj.model.isValid()) {
      var newRoleRequest = Wethod.request('permission:save:new:role', viewObj.model);
      $.when(newRoleRequest).done(function (newRoleResponse) {
        if (newRoleResponse.code === 200) {
          $('[data-action="addNewRole"]').removeClass('disabled');
          viewObj.destroy();
          showAppTemplate();
        } else {
          viewObj.ui.newRoleButtonLabel.text('SAVE');
          displayError([{ name: newRoleResponse.message }], viewObj);
        }
      });
    } else {
      viewObj.ui.newRoleButtonLabel.text('SAVE');
      displayError(viewObj.model.validationError, viewObj);
    }
  };

  Permission.deleteInsertRole = function (obj) {
    $('[data-action="addNewRole"]').removeClass('disabled');
    obj.destroy();
  };

  Permission.saveChangedPermission = function (obj, app) {
    if (!_.isUndefined(savePermission[app])) {
      obj.ui.saveButtonLabel.text('SAVING ...');
      var saveRequest = Wethod.request('permission:save:authorization', [savePermission[app]]);
      $.when(saveRequest).done(function () {
        obj.ui.saveButtonLabel.text('SAVE');
        obj.ui.saveButton.addClass('disabled');
        // clear obj
        delete savePermission[app];
      });
    }
  };

  Permission.changePermission = function (permissionObj) {
    var app = permissionObj.model.get('app');
    var modifyPermission = function () {
      var roleData = {};
      roleData.role = permissionObj.model.get('role');
      roleData.permissions = {};

      roleData.permissions[permissionObj.model.get('name')] = permissionObj.model.get('val');

      savePermission[app].roles.push(roleData);
    };

    // update obj model
    permissionObj.model.set({ val: !permissionObj.model.get('val') });

    if (_.isUndefined(savePermission[app])) {
      // create 'application' object
      savePermission[app] = {};
      savePermission[app].app = app;
      savePermission[app].roles = [];

      modifyPermission();
    } else {
      var count = 0;
      _.each(savePermission[app].roles, function (role) {
        if (role.role === permissionObj.model.get('role')) {
          role.permissions[permissionObj.model.get('name')] = permissionObj.model.get('val');
        } else {
          count += 1;
        }
      });

      if (count === savePermission[app].roles.length) {
        modifyPermission();
      }
    }
  };

  Permission.Controller = {
    showPermissions: function (options) {
      resetData();

      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest).done(function (authorizationResponse) {
        if (_.isUndefined(authorizationResponse)) {
          return;
        }

        Wethod.SettingsApp.authorization = authorizationResponse;
        var isPlayground = Wethod.userInfo.get('company') === 'playground';
        if (Wethod.SettingsApp.getPermission('permissions') && !isPlayground) {
          var permissionRequest = Wethod.request('permission:get:authorization');
          $.when(permissionRequest).done(function (permissionResponse) {
            var moduleApps = new Permission
              .AuthorizationAppCollection(_.toArray(permissionResponse.attributes));
            var moduleAppsTemplate = new Permission.PermissionTemplateCollectionView({
              collection: moduleApps,
            });

            prepareModulesForDisplay(moduleApps);

            // app structure
            structureTemplate = new Permission.StructureLayoutView();
            var buttons = new Permission.ButtonsTemplateItemView();
            var sectionHeaderModel = new Header.SectionHeaderModel({
              current_section: 'Permissions',
              helper_url: 'settings/index/#permissions',
            });

            var sectionHeaderView = new Header.SectionHeaderView({ model: sectionHeaderModel });

            // RENDER
            structureTemplate.render();
            structureTemplate.sectionHeader.show(sectionHeaderView);
            structureTemplate.buttons.show(buttons);
            structureTemplate.body.show(moduleAppsTemplate);

            dispatcher.trigger('tour:start', options.idTour);
          });
        } else {
          loadingView.destroy();
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },
  };
}, Wethod.module('HeaderApp.Header'));
