/* eslint-disable class-methods-use-this */
const constants = require('./constants');

class AccountReducer {
  constructor(state) {
    this.state = {
      // Active personal API tokens
      personalTokens: [],
      modalToShow: null,
      modalData: null,
      // List of request to complete
      waitingFor: [],
      personalTokenMetadata: [], // metadata related to personal api token attributes
      ...state,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Return list of request to complete augmented with given key.
   * @param state
   * @param key
   * @returns {*}
   */
  static addWaitingFor(state, key) {
    return state.waitingFor.concat(key);
  }

  /**
   * Return list of request to complete without given key.
   * @param state
   * @param key
   * @returns {*}
   */
  static removeWaitingFor(state, key) {
    return state.waitingFor.filter((waitingKey) => waitingKey !== key);
  }

  /**
   * Return if there is a pending request with given key.
   * @param state
   * @param key
   * @returns {boolean}
   */
  static waitingFor(state, key) {
    return state.waitingFor.indexOf(key) !== -1;
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SHOW_MODAL:
        return {
          ...state,
          modalToShow: action.key,
          modalData: action.data,
        };
      case constants.PERSONAL_TOKEN_ADD_REQUEST:
        return {
          ...state,
          modalData: null,
          waitingFor: AccountReducer.addWaitingFor(state, constants.PERSONAL_TOKEN_ADD_REQUEST),
        };
      case constants.PERSONAL_TOKEN_ADD_SUCCESS:
        return {
          ...state,
          personalTokens: state.personalTokens.concat(action.token),
          waitingFor: AccountReducer.removeWaitingFor(state, constants.PERSONAL_TOKEN_ADD_REQUEST),
        };
      case constants.PERSONAL_TOKEN_ADD_FAILURE:
        return {
          ...state,
          modalData: {
            ...state.modalData,
            error: action.message,
          },
          waitingFor: AccountReducer.removeWaitingFor(state, constants.PERSONAL_TOKEN_ADD_REQUEST),
        };
      case constants.PERSONAL_TOKEN_DELETE_REQUEST:
        return {
          ...state,
          waitingFor: AccountReducer
            .addWaitingFor(state, constants.PERSONAL_TOKEN_DELETE_REQUEST),
        };
      case constants.PERSONAL_TOKEN_DELETE_SUCCESS:
        return {
          ...state,
          personalTokens: state.personalTokens.filter((token) => token.id !== action.id),
          waitingFor: AccountReducer
            .removeWaitingFor(state, constants.PERSONAL_TOKEN_DELETE_REQUEST),
        };
      case constants.PERSONAL_TOKEN_LIST_REQUEST:
        return {
          ...state,
          waitingFor: AccountReducer
            .addWaitingFor(state, constants.PERSONAL_TOKEN_LIST_REQUEST),
        };
      case constants.PERSONAL_TOKEN_LIST_SUCCESS:
        return {
          ...state,
          personalTokens: action.tokens,
          waitingFor: AccountReducer
            .removeWaitingFor(state, constants.PERSONAL_TOKEN_LIST_REQUEST),
        };
      case constants.PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST:
        return {
          ...state,
          waitingFor: AccountReducer
            .addWaitingFor(state, constants.PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST),
        };
      case constants.PERSONAL_TOKEN_PROPERTY_METADATA_SUCCESS:
        return {
          ...state,
          personalTokenMetadata: action.metadata,
          waitingFor: AccountReducer
            .removeWaitingFor(state, constants.PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST),
        };
      default:
        return state;
    }
  }
}

module.exports = AccountReducer;
