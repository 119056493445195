const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Sidebar/MilestoneSelect.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  eventTypes: state.eventTypes,
  isLoading: state.waitingFor.includes('get-event-types-list'),
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypes: () => dispatch(actions.getEventTypes()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
