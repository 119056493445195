const React = require('react');
const PropTypes = require('prop-types');
const Typography = require('../Typography/Typography.react');

require('./style.scss');

const Infotip = ({ label }) => (
  <span className="wethod-infotip" data-testid="infotip">
    <Typography size={10} uppercase>
      {label}
    </Typography>
  </span>
);

Infotip.propTypes = {
  label: PropTypes.string.isRequired,
};

module.exports = Infotip;
