const React = require('react');
const PropTypes = require('prop-types');
require('./style.scss');

const RadialIndicator = ({ value, color }) => {
  const style = { stroke: color };
  const text = value || '-';

  const getCircleClassName = () => {
    let name = 'radial-indicator__circle progress-';
    if (value <= 0) {
      name += '0';
    } else if (value >= 100) {
      name += '100';
    } else {
      name += value;
    }
    return name;
  };

  return (
    <div className="radial-indicator">
      <svg className={getCircleClassName()} viewBox="0 0 100 100">
        <circle className="radial-indicator__circle__base" cx="50" cy="50" r="45" />
        <circle className="radial-indicator__circle__progress"
          cx="50"
          cy="50"
          r="45"
          style={style} />
      </svg>
      <svg className="radial-indicator__label" viewBox="0 0 100 100">
        <text id="radial-indicator__label__text" x="50%" y="50%" dy=".4em">{text}</text>
      </svg>
    </div>
  );
};

RadialIndicator.defaultProps = {
  value: null,
  color: '#000',
};

RadialIndicator.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

module.exports = RadialIndicator;
