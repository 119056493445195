/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const Activities = require('../containers/Activities/Activities');
const Pinboard = require('../containers/Pinboard/Pinboard');
const Files = require('../containers/Files/FileManager');
const Invoices = require('../../../../../common/react/widgets/Invoices/components/Invoices.react');
const Alerts = require('../../../../../common/react/widgets/Alerts/components/Alerts.react');
const Intercompany = require('../../../../../common/react/widgets/IntercompanyActivities/components/IntercompanyActivities.react');
const Reviews = require('../../../../../common/react/widgets/Reviews/components/Reviews.react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Modal = require('../containers/Modal');

module.exports = class Desk extends React.Component {
  canSeeWidget(name) {
    return this.props.widgetPermissions[name] !== false;
  }

  getAlertsWidget() {
    if (this.canSeeWidget('alert')) {
      return <Alerts />;
    }
    return null;
  }

  getInvoicesWidget() {
    if (this.canSeeWidget('invoices')) {
      const to = moment().format('Y-M');
      const from = moment().subtract(3, 'M').format('Y-M');
      return <Invoices config={{ filter: 'me', from, to }} />;
    }
    return null;
  }

  getReviewsWidget() {
    if (this.props.isReviewEnabled) return <Reviews />;

    return null;
  }

  getIntercompanyWidget() {
    if (Wethod.userInfo.get('group')) return <Intercompany />;

    return null;
  }

  render() {
    return (
      <div className="desk wethod-section-body">
        <SectionHeader current_section="Desk" helper_url="desk/index/" />
        <div className="desk-widgets">
          <div className="desk-widgets-left">
            <Activities />
            <Pinboard />
            <Files />
          </div>
          <div className="desk-widgets-right">
            {this.getAlertsWidget()}
            {this.getInvoicesWidget()}
            {this.getReviewsWidget()}
            {this.getIntercompanyWidget()}
            {/* <Timeline/> */}
          </div>
        </div>
        {/* <Boards/> */}
        <Modal />
      </div>
    );
  }
};
