/* eslint-disable react/no-did-update-set-state,no-underscore-dangle */
const React = require('react');
const Sidebar = require('../../../../../../common/react/sidebar/Sidebar.react');
const ExpenseBody = require('./ExpenseBody.react');
const Actions = require('./ExpenseSidebarActions.react');
const { isValueRequired, isRouteRequired } = require('../../services/ExpenseCategoryService');
const FormMetadataManager = require('../../../../../../common/react/FormMetadataManager/FormMetadataManager.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.ExpenseSidebar}
 */
module.exports = class ExpenseSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  static isNew(item) {
    return !item || !item.id;
  }

  /**
   * Remove the given name from the list of changes
   * @param changes
   * @param name
   */
  static resetChanges(changes, name) {
    return changes.filter((attr) => attr !== name);
  }

  /**
   * Add the given name to the list of changes, if not already present
   * @param changes
   * @param name
   */
  static addChanges(changes, name) {
    if (!changes.includes(name)) {
      changes.push(name);
    }
    return changes;
  }

  /**
   * Return the updated item, merging the changes into the oldItem
   * @param oldItem
   * @param changes
   */
  static getUpdatedItem(oldItem, changes) {
    if (oldItem) {
      const updatedItem = { ...oldItem };
      if (changes) {
        Object.keys(changes).forEach((key) => {
          updatedItem[key] = changes[key];
        });
      }
      return updatedItem;
    }
    return null;
  }

  /**
   * Reset expense attributes based on selected category
   * @param category
   * @returns {{category}}
   */
  static getChangesFromCategory(category) {
    const changes = {
      category,
    };

    if (!isRouteRequired(category)) {
      changes.from = undefined;
      changes.to = undefined;
    }
    if (!isValueRequired(category)) {
      changes.value = undefined;
    }

    return changes;
  }

  /**
   * Return the new changes, properly formatted
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {*}
   */
  static getFormattedChanges(name, value) {
    switch (name) {
      case 'category':
        return ExpenseSidebar.getChangesFromCategory(value);
      default:
        return { [name]: value };
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      item: this.props.item,
      // by default is true if item has no id (when we want to  add a new one)
      editMode: ExpenseSidebar.isNew(this.props.item),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.item && this.props && this.props.item) {
      const updatedItem = { ...prevState.item };

      // Update the newly created item id once it is first saved
      const hasNewId = !prevState.item.id && this.props.item.id;

      // Update files when the list changes (new file uploaded or file deleted)
      const oldFiles = prevState.item.files ? prevState.item.files.length : 0;
      const newFiles = this.props.item.files ? this.props.item.files.length : 0;
      const hasFileChanges = oldFiles !== newFiles;

      if (hasNewId) {
        updatedItem.id = this.props.item.id;
      }
      if (hasFileChanges) {
        updatedItem.files = this.props.item.files;
      }

      if (hasNewId || hasFileChanges) {
        this.setState({
          item: updatedItem,
        });
      }
    }
  }

  /**
   * Update the item and unsaved changes
   * @param name
   * @param value
   */
  handleInputChanges(name, value) {
    this.setState((prevState) => {
      const formattedChanges = ExpenseSidebar.getFormattedChanges(name, value);

      const unsavedChanges = this
        .updateChangedAttributesList(prevState.unsavedChanges, formattedChanges);

      const updatedItem = ExpenseSidebar.getUpdatedItem(prevState.item, formattedChanges);

      return {
        item: updatedItem,
        unsavedChanges,
      };
    });
  }

  handleItemDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.state.item);
    }
  }

  /**
   * Save the item with the modified attributes.
   */
  handleSave() {
    const formattedChanges = this.getUnsavedChanges();
    const updatedItem = ExpenseSidebar.getUpdatedItem(this.props.item, formattedChanges);

    this.props.onSave(formattedChanges, updatedItem);

    this.setState({
      editMode: false,
      unsavedChanges: [],
    });
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  /**
   * Return the changes to be saved
   * @returns {{}}
   */
  getUnsavedChanges() {
    const changes = {};
    this.state.unsavedChanges.forEach((key) => {
      changes[key] = this.state.item[key];
    });
    return changes;
  }

  /**
   * Return current item metadata.
   * @return {*|[{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: [{name: string, value: string}]},{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: [{name: string, value: string}]}]|{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: []}|{cannot_edit_reason: string, name: string, can_edit: boolean, constraints: []}|[]|[{cannot_edit_reason: string, name: string, can_edit: boolean, constraints: []}]}
   */
  getMetadata() {
    return ExpenseSidebar.isNew(this.state.item)
      ? this.props.expenseMetadata : this.state.item._fields;
  }

  getBody() {
    const metadataNameOverrides = {
      project_id: 'project',
      category_id: 'category',
    };
    return (
      <FormMetadataManager metadata={this.getMetadata()} nameOverrides={metadataNameOverrides}>
        <ExpenseBody readOnly={!this.state.editMode}
          canEditOwner={this.canEditOwner()}
          creation={ExpenseSidebar.isNew(this.state.item)}
          item={this.state.item}
          errors={this.props.errors}
          updateErrors={this.props.updateErrors}
          onChange={this.handleInputChanges.bind(this)} />
      </FormMetadataManager>
    );
  }

  getActions() {
    if (!this.state.editMode) {
      return (
        <Actions item={this.state.item}
          canDelete={this.canDelete()}
          onDelete={this.handleItemDelete.bind(this)} />
      );
    }

    return null;
  }

  getTitle() {
    return ExpenseSidebar.isNew(this.props.item) ? 'New expense' : 'Expense details';
  }

  /**
   * Checks if it's safe to save a item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.props.isValid;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEditItem(this.props.item.owner.id) && !this.state.editMode;
  }

  /**
   * Check if the item can be deleted: you must have permissions and item should not be a new one
   * @returns {boolean}
   */
  canDelete() {
    return this.props.canDelete && !ExpenseSidebar.isNew(this.props.item);
  }

  /**
   * Check if the user can edit the owner: you must have permissions to edit others expenses
   * @returns {boolean}
   */
  canEditOwner() {
    return this.props.canEditOther;
  }

  hasUnsavedChanges() {
    return this.state.unsavedChanges && this.state.unsavedChanges.length > 0;
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldItem = this.props.item || {};
    let oldVal = null;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'project':
        oldVal = oldItem.project ? oldItem.project.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'category':
        oldVal = oldItem.category ? oldItem.category.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      default:
        oldVal = oldItem[name];
        break;
    }

    return (ExpenseSidebar.isDirty(oldVal) || ExpenseSidebar.isDirty(newVal))
      && (oldVal !== newVal);
  }

  /**
   * Return the updated list of changed attributes
   * @param oldChangesList
   * @param newChanges
   * @returns {*}
   */
  updateChangedAttributesList(oldChangesList, newChanges) {
    let changesList = oldChangesList;
    Object.keys(newChanges).forEach((key) => {
      const value = newChanges[key];

      if (this.hasChanged(key, value)) {
        changesList = ExpenseSidebar.addChanges(changesList, key);
      } else {
        changesList = ExpenseSidebar.resetChanges(changesList, key);
      }
    });

    return changesList;
  }

  render() {
    return (
      <Sidebar title={this.getTitle()}
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
