const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/RevenuesPerEmployeeChart.react');

const thousandsValue = (value) => Math.round(value / 100) / 10;

const mapStateToProps = (state) => ({
  thisMonth: thousandsValue(state.month_employee_revenue),
  prevMonth: thousandsValue(state.prev_month_employee_revenue),
  twoMonthsAgo: thousandsValue(state.two_months_ago_employee_revenue),
  prevYearThisMonth: thousandsValue(state.prev_year_employee_revenue),
  prevYearPrevMonth: thousandsValue(state.prev_year_prev_month_employee_revenue),
  prevYearTwoMonthsAgo: thousandsValue(state.prev_year_two_months_before_employee_revenue),
  min: thousandsValue(Math.min(state.month_employee_revenue, state.prev_month_employee_revenue,
    state.two_months_ago_employee_revenue, state.prev_year_employee_revenue,
    state.prev_year_prev_month_employee_revenue,
    state.prev_year_two_months_before_employee_revenue)) * 0.8,
});

module.exports = connect(mapStateToProps)(Component);
