const React = require('react');
const SectionTitle = require('../../../../common/components/SectionTitle.react');
const TargetComparator = require('../../../containers/sections/Production/TargetComparator');
const LastWeekProductionComparator = require('../../../containers/sections/Production/LastWeekProductionComparator');
const ComparatorConnector = require('../../../../common/components/ComparatorConnector.react');

module.exports = class Title extends React.Component {
  getAgainstTarget() {
    const target = this.props.weekly_target;
    if (target) {
      return (
        <span>
          <TargetComparator value={this.props.week_production} /> its target
        </span>
      );
    }
    return null;
  }

  getAgainstPrevWeek() {
    return <LastWeekProductionComparator value={this.props.week_production} />;
  }

  getContent() {
    const againstTarget = this.getAgainstTarget();
    const againstPrevWeek = this.getAgainstPrevWeek();
    const c1 = { a: this.props.week_production, b: this.props.prev_week_production };
    const c2 = { a: this.props.week_production, b: this.props.weekly_target };
    const connection = againstTarget ? (
      <span>
        <ComparatorConnector c1={c1} c2={c2} /> it's
      </span>
    ) : null;
    return (
      <span>
        Production for the week is {againstTarget} {connection} {againstPrevWeek}
      </span>
    );
  }

  render() {
    return <SectionTitle>{this.getContent()}</SectionTitle>;
  }
};
