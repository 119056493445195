/* eslint-disable consistent-return,react/no-array-index-key */
const React = require('react');
const formatters = require('../../../utilities/formatters');
const tokenize = require('../../../utilities/tokenize');

module.exports = class BudgetLog extends React.Component {
  constructor(props) {
    super(props);

    this.changeable = ['value', 'margin'];
    this.messages = {
      value: (value) => `Budget's final net price changed to ${value}`,
      margin: (value) => `Project's margin is now ${value} %`,
    };
  }

  getWhatsChanged() {
    for (let i = 0; i < this.changeable.length; i++) {
      const prevValue = this.props[`from_${this.changeable[i]}`];
      const currentValue = this.props[`to_${this.changeable[i]}`];
      if (prevValue !== currentValue) return this.changeable[i];
    }
  }

  getCurrentValue(name) {
    return this.props[`to_${name}`];
  }

  getMessage() {
    const changedName = this.getWhatsChanged();
    const value = this.getCurrentValue(changedName);
    const formattedValue = formatters.getFormattedMoneyValue(value);
    return this.messages[changedName](formattedValue);
  }

  getCreationDate() {
    const momentDate = moment(this.props.date);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.approved_by;
  }

  getProjectLink() {
    return <a href={`#timeline/${this.props.project_id}`}>{this.getHighlightedContent(_.unescape(this.props.project_name))}</a>;
  }

  getClientLink() {
    return <span>{this.getHighlightedContent(_.unescape(this.props.client_name))}</span>;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span className="timeline-event-log__creation-time">{date}</span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    if (this.getWhatsChanged()) {
      return (
        <div className="timeline-event-log">
          <div className="timeline-event-past__type-logo-container">
            <div className="wethod-icon-budget wethod-icon-budget--black" />
          </div>
          <div className="timeline-event-log__type">
            <div className="timeline-event-log__type-name">{this.getMessage()}</div>
          </div>
          <div className="timeline-event-log__creation">
            {this.getProjectLink()}
            {' '}
            -
            {this.getClientLink()}
          </div>
          <div className="timeline-event-log__creation">
            {this.getCreation()}
          </div>
        </div>
      );
    }
    return null;
  }
};
