const React = require('react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');

module.exports = class CannotUploadModal extends React.Component {
  getBody() {
    const { errors } = this.props;
    switch (errors[0]) {
      case 'size':
        return (
          <ModalBodyBlock>
            Files should not be more than <b>128 MB</b> in size, please choose another one and{' '}
            retry.
          </ModalBodyBlock>
        );
      case 'network':
        return (
          <ModalBodyBlock>
            Seems like you're <b>offline</b>, please check your connection and retry.
          </ModalBodyBlock>
        );
      case 'teamDriveFileLimitExceeded':
        return (
          <ModalBodyBlock>
            The file limit for your Google Shared Drive has been exceeded.
            Please remove some files or folders.
          </ModalBodyBlock>
        );
      default:
        return (
          <ModalBodyBlock>
            This kind of file is <b>not accepted</b>, please choose another one and retry.
          </ModalBodyBlock>
        );
    }
  }

  render() {
    return (
      <Modal title="Cannot upload"
        onClose={this.props.close}>
        <ModalContent>
          <ModalBody>
            {this.getBody()}
          </ModalBody>
          <ModalFooter>
            <ModalFooterAction onClick={this.props.close}>OK</ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
