const { connect } = require('react-redux');
const DeleteCompanyModal = require('../components/DeleteCompanyModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  client: state.focusedItem,
  isWaiting: state.isDeletingClient,
});

const mapDispatchToProps = (dispatch) => ({
  deleteClient: (client) => dispatch(actions.deleteCompany(client)),
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyModal);
