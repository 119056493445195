/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,no-param-reassign */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const dragula = require('react-dragula');
const List = require('../../../containers/Boards/Kanban/List');
const ItemSidebar = require('../Sidebar/ItemSidebar.react');
const arrayMover = require('../../../services/arrayMover');

module.exports = class Kanban extends React.Component {
  constructor(props) {
    super(props);
    this.cardsDroppableContainers = [];

    this.state = {
      sidebarItem: null,
    };
  }

  componentDidMount() {
    this.cardDrake = this.dragulaCardDecorator(this.cardsDroppableContainers);
    this.kanbanDrake = this.dragulaKanbanDecorator(this.kanbanEl);
  }

  /**
   * Returns the lists of which this kanban is made of.
   * A list is a group of cards which are items with the same value for the given pivot attribute.
   * A pivot attribute is always of type "status".
   * @return {*}
   */
  getLists() {
    const pivot = this.props.options.status;
    const listsMap = {}; // key = list's id, value = list's index in 'lists', useful to easily add cards to a given list
    const lists = this.props.structure
      .filter((attribute) => attribute.id === pivot)[0].available_values // lists = all available_values for attribute with id = pivot
      .map((list, index) => { // prepare 'cards' attribute and populate 'listsMap'
        listsMap[list.id] = index;
        return {
          ...list,
          cards: [],
        };
      });

    for (let i = 0; i < this.props.items.length; i++) { // put items in the right list's cards
      const item = this.props.items[i];
      const listId = item.attributes
        .filter((attribute) => attribute.attribute === pivot)[0].value.id;
      const listIndex = listsMap[listId];
      lists[listIndex].cards.push(item);
    }

    // keep list's cards sorted by kanban_sort
    return lists.sort((a, b) => a.sort - b.sort)
      .map((list) => ({
        ...list,
        cards: list.cards
          .sort((a, b) => a.kanban_sort - b.kanban_sort),
      }));
  }

  isSavingSidebarItem() {
    return this.props.isWaiting.includes(`edit-board-${this.props.boardId}-item-${this.state.sidebarItem}-values`);
  }

  showSidebar(itemId) {
    this.setState({ sidebarItem: itemId });
  }

  handleSidebarClose() {
    this.setState({ sidebarItem: null });
  }

  getSidebarItem() {
    if (this.state.sidebarItem) {
      return this.props.items.find((item) => item.id === this.state.sidebarItem);
    }
  }

  addCardsDroppableContainer(container) {
    this.cardsDroppableContainers.push(container);
  }

  handleItemDelete(itemId, itemName) {
    this.setState({ sidebarItem: null });
    this.props.deleteItem(itemId, itemName);
  }

  handleItemChange(itemId, itemValues) {
    this.props.editItemValues(this.props.boardId, itemId, itemValues);
  }

  /**
   * Sort cards within the same list or move card from a list to another one.
   * @param el
   * @param target
   * @param source
   * @param sibling
   */
  onDropCard(el, target, source, sibling) {
    this.cardDrake.cancel(true); // @see https://github.com/bevacqua/react-dragula/issues/23

    const sourceListId = parseInt(source.getAttribute('data-id'));
    const targetListId = parseInt(target.getAttribute('data-id'));

    const lists = this.getLists();
    const targetList = lists.filter((list) => list.id === targetListId)[0];
    const sourceList = lists.filter((list) => list.id === sourceListId)[0];

    const fromId = parseInt(el.getAttribute('data-id'));
    const from = this.getElementIndex(fromId, sourceList.cards);

    const siblingId = sibling ? parseInt(sibling.getAttribute('data-id')) : null;
    const siblingIndex = siblingId !== null ? this.getElementIndex(siblingId, targetList.cards)
      : targetList.cards.length;

    if (targetListId === sourceListId) { // sorting card within the same list
      const to = siblingIndex > from ? siblingIndex - 1 : siblingIndex;
      const sortedItems = arrayMover(from, to, targetList.cards)
        .map((item, index) => ({
          id: item.id,
          sort: index,
        })); // Set the right sort, based on index

      this.props.sortCards(this.props.boardId, sortedItems);
    } else { // moving card to another list
      const to = siblingIndex;
      const card = sourceList.cards.splice(from, 1)[0];
      const pivot = this.props.options.status;
      targetList.cards.push(card);
      const sortedItems = arrayMover(targetList.cards.length - 1, to, targetList.cards)
        .map((item, index) => ({
          id: item.id,
          sort: index,
        })); // Set the right sort, based on index

      const nextListValue = { ...targetList };
      delete nextListValue.cards;

      this.props.updateCardList(this.props.boardId, card.id, pivot, nextListValue, sortedItems);
    }
  }

  /**
   * Init Dragula on the element componentBackingInstance.
   * @param containers
   */
  dragulaCardDecorator(containers) {
    if (containers && this.props.canEdit) {
      const options = {};

      return dragula(containers, options).on('drop', this.onDropCard.bind(this));
    }
  }

  /**
   * Returns the index of element with element id in list.
   * @param elementId
   * @param list
   */
  getElementIndex(elementId, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === elementId) {
        return i;
      }
    }
    return -1;
  }

  onDropList(el, target, source, sibling) {
    const lists = this.getLists();
    const elId = parseInt(el.getAttribute('data-id'));
    const siblingId = sibling ? parseInt(sibling.getAttribute('data-id')) : null;
    const from = this.getElementIndex(elId, lists);
    const siblingIndex = siblingId !== null ? this.getElementIndex(siblingId, lists) : lists.length;
    const to = siblingIndex > from ? siblingIndex - 1 : siblingIndex;

    const sorted = arrayMover(from, to, lists)
      .map((list, index) => ({
        ...list,
        sort: index,
      }))// Set the right sort, based on index
      .map((list) => {
        delete list.cards;
        return list;
      }); // cards attribute is not used in state, so remove it

    this.props.updateListSort(this.props.boardId, this.props.options.status, sorted);
    clearTimeout(this.sortItemTimer);
  }

  dragulaKanbanDecorator(componentBackingInstance) {
    if (componentBackingInstance && this.props.canEdit) {
      const options = {
        // move only if using handle
        moves: (el, container, handle) => handle.classList
          .contains('project-canvas-kanban__list-drag-handle'),
      };

      return dragula([componentBackingInstance], options).on('drop', this.onDropList.bind(this));
    }
  }

  render() {
    return (
      <div className="project-canvas-kanban" ref={(el) => this.kanbanEl = el}>
        {this.getLists().map((list) => (
          <List key={list.id}
            showSidebar={this.showSidebar.bind(this)}
            canEdit={this.props.canEdit}
            addingCard={this.props.waitingForAddingItem}
            addCard={this.props.addItem}
            boardId={this.props.boardId}
            addDroppableContainer={this.addCardsDroppableContainer.bind(this)}
            {...list}
            options={this.props.options} />
        ))}
        <CSSTransition in={!!this.state.sidebarItem}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <ItemSidebar item={this.getSidebarItem()}
            structure={this.props.structure}
            canEdit={this.props.canEdit}
            isSaving={this.isSavingSidebarItem()}
            onClose={this.handleSidebarClose.bind(this)}
            onDelete={this.handleItemDelete.bind(this)}
            onSave={this.handleItemChange.bind(this)}
            onNameSave={this.props.editItemName} />
        </CSSTransition>
      </div>
    );
  }
};
