const React = require('react');
const PriceSecondaryItem = require('./PriceSecondaryItem.react');
const HeaderBoxSecondaryInfo = require('../HeaderBox/HeaderBoxSecondaryInfo.react');
const MathService = require('../../../../../../../services/MathService');

const PriceSecondarySection = ({
  grossMarginValue,
  grossMarginPercentage,
  contributionMarginValue,
  contributionMarginPercentage,
  grossMarginTooltip,
  constributionMarginTooltip,
}) => (
  <HeaderBoxSecondaryInfo>
    <PriceSecondaryItem id="gross-margin"
      tooltip={grossMarginTooltip}
      label="Gross Margin"
      value={grossMarginValue}
      percentage={MathService.round(grossMarginPercentage)} />
    <PriceSecondaryItem id="contribution-margin"
      tooltip={constributionMarginTooltip}
      label="Contribution Margin"
      value={contributionMarginValue}
      percentage={MathService.round(contributionMarginPercentage)} />
  </HeaderBoxSecondaryInfo>
);

module.exports = PriceSecondarySection;
