const React = require('react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');

/**
 * Modal to ask confirmation when user tries to plan over holidays.
 * @param {function} onCancelClick
 * @param changes
 * @param {function} editPlan
 * @param {function} confirmHolidayPlanning
 * @param isHoliday
 * @param person
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmHolidayPlanModal = ({
  onCancelClick,
  changes,
  editPlan,
  confirmHolidayPlanning,
  isHoliday,
  person,
}) => {
  function handleOkClick() {
    editPlan(changes);
    onCancelClick();
    confirmHolidayPlanning();
  }

  const getChangesWithoutHolidays = () => changes
    .filter((change) => !isHoliday(change.day, person));

  function handleCancelClick() {
    const changesWithoutWeekends = getChangesWithoutHolidays();
    if (changesWithoutWeekends.length) {
      editPlan(changesWithoutWeekends);
    }
    onCancelClick();
  }

  return (
    <Modal onClose={onCancelClick} title="Holidays allocation">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are going to change plan on holidays.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Would you like to continue or do you want to exclude changes affecting holidays?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter>
          <ModalFooterAction onClick={handleCancelClick}>
            Exclude them
          </ModalFooterAction>
          <ModalFooterAction onClick={handleOkClick}>
            Continue
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmHolidayPlanModal;
