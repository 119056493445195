'use strict';

Wethod.module('SettingsApp', function (SettingsApp, Wethod) {
  this.startWithParent = false;

  var API = {
    showAccount: function (options) {
      SettingsApp.Account.Controller.showAccount(options);
    },
    showTeam: function (options) {
      SettingsApp.Team.Controller.showTeam(options);
    },
    showCompany: function (options) {
      SettingsApp.Company.Controller.showCompany(options);
    },
    showPermissions: function (options) {
      SettingsApp.Permission.Controller.showPermissions(options);
    },
    showPayment: function (options) {
      Wethod.navigate('settings/account');
      SettingsApp.Account.Controller.showAccount(options);
      // SettingsApp.Payment.Controller.showPayment();
    },
    showRules: function (options) {
      SettingsApp.RuleManager.Controller.showRules(options);
    },
    showAlertsEngine: function (options) {
      SettingsApp.AlertsManager.Controller.showAlertsManager(options);
    },
    showProfile: function (options) {
      SettingsApp.Profile.Controller.showProfile(options.id);
    },
  };

  Wethod.on('url:settings:alerts:show', function () {
    Wethod.navigate('settings/alerts', { trigger: true });
  });

  Wethod.on('url:settings:rules:show', function () {
    Wethod.navigate('settings/rules', { trigger: true });
  });

  Wethod.on('url:settings:profile:show', function () {
    Wethod.navigate('settings/profile', { trigger: true });
  });

  Wethod.on('url:settings:account:show', function () {
    Wethod.navigate('settings/account', { trigger: true });
    // API.showAccount();
  });

  Wethod.on('url:settings:team:show', function () {
    Wethod.navigate('settings/team', { trigger: true });
    // API.showTeam();
  });

  Wethod.on('url:settings:company:show', function () {
    Wethod.navigate('settings/company', { trigger: true });
    // API.showCompany();
  });

  Wethod.on('url:settings:payment:show', function () {
    Wethod.navigate('settings/payment', { trigger: true });
  });

  SettingsApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'settings');
    API[options.foo](options);
  });
});
