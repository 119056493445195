const React = require('react');
const PropTypes = require('prop-types');

const ShowIf = ({ condition, children, showElse }) => {
  if (!React.isValidElement(children)) {
    return null;
  }
  if (condition) {
    return children;
  }
  return showElse;
};

ShowIf.defaultProps = {
  condition: false,
  showElse: null,
};

ShowIf.propTypes = {
  condition: PropTypes.bool,
  showElse: PropTypes.node,
  children: PropTypes.node.isRequired,
};

module.exports = ShowIf;
