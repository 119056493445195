const Metadata = {
  save(metadata, projectId) {
    const model = new Wethod.PipelineApp.Pipeline.MetadataModel({
      id: metadata.id,
      metadata: metadata.metadata_id,
      project: projectId,
      selected_value: metadata.selected_value,
    });

    return Wethod.request('save:pipeline:project:metadata', model);
  },
  delete(metadata, projectId) {
    const model = new Wethod.PipelineApp.Pipeline.MetadataModel({
      id: metadata.id,
      metadata: metadata.metadata_id,
      project: projectId,
      selected_value: metadata.selected_value,
    });

    return Wethod.request('delete:pipeline:project:metadata', model);
  },
};

module.exports = Metadata;
