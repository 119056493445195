/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const Infotip = require('../../../../../../common/react/Infotip/Infotip.react');

module.exports = class PSModeTag extends React.Component {
  render() {
    return (
      <TableRow className="project-status__row--tag" colSpan={8}>
        <TableCell className="project-status__column--tag">
          <Infotip label={this.props.mode} />
        </TableCell>
      </TableRow>
    );
  }
};
