const React = require('react');
const IconLink = require('../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');

const PlanningButton = ({ projectId, user }) => (
  <IconLink label="Show planning"
    borderless
    href={`#planning/people?project_id=${projectId}&search=${user.surname}+${user.name}`}
    target="_blank">
    <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
  </IconLink>
);

module.exports = PlanningButton;
