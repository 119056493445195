const { connect } = require('react-redux');
const Component = require('../../components/ProjectSelection/ProjectSelection.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  recurringModeEnabled: state.recurring_mode_enabled,
  loading: state.waiting_for.filter((request) => request === 'available-projects').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  showDisableRecurringModeModal: () => dispatch(actions.showModal('disable-recurring-mode')),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
