const React = require('react');
const PropTypes = require('prop-types');
const InputValidator = require('../../../../InputValidator/InputValidator.react');
const Input = require('../../../../inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');

const NumericRangeValidatedInput = ({
  onChange, errorText, name, value, suffix, placeholder, updateErrors, constraints,
}) => {
  const errorMessages = {
    max: (param) => `Cannot be over ${parseInt(param)}`,
    min: (param) => `Must be at least ${parseInt(param)}`,
  };

  return (
    <InputValidator updateErrors={updateErrors}
      constraints={constraints}
      customMessages={errorMessages}>
      <Input name={name}
        onChange={onChange}
        value={value}
        errorText={errorText}
        suffix={suffix}
        placeholder={placeholder} />
    </InputValidator>
  );
};

NumericRangeValidatedInput.defaultProps = {
  suffix: null,
  value: '',
  onChange: null,
  constraints: [],
  updateErrors: null,
  placeholder: '',
  errorText: '',
};

NumericRangeValidatedInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Function to call when value changes.
   * @param e {{}}
   */
  onChange: PropTypes.func,
  suffix: PropTypes.node,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  /**
   * Function to update form errors.
   * @param name {string}
   * @param error {{}}
   */
  updateErrors: PropTypes.func,
  constraints: PropTypes.arrayOf(PropTypes.string),
};

module.exports = NumericRangeValidatedInput;
