/* eslint-disable no-shadow */
/**
 *
 * @param key
 * @param items
 * @returns {Array} items filtered by key
 */
module.exports = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  const found = (key, value) => {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  };

  // TODO: project info now contains html entities so we need unescape; remove when not necessary
  const isInClient = (key, client) => found(key, _.unescape(client.name));

  const isInName = (key, project) => found(key, _.unescape(project.name));

  const isInJobOrder = (key, project) => found(key, _.unescape(project.job_order));

  const isInPm = (key, pm) => found(key, pm.name) || found(key, pm.surname);

  return items.filter((item) => isInName(key, item.project) || isInClient(key, item.client)
    || isInJobOrder(key, item.project) || isInPm(key, item.pm));
};
