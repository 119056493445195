const { connect } = require('react-redux');
const Component = require('../../components/Kudos/Kudos.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  kudos: state.kudos,
  projectId: state.info.id,
  totalReviews: state.totalReviews,
  totalSessions: state.totalSessions,
  isWaiting: state.waitingFor.filter((key) => key === 'kudos').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getKudos: (projectId) => dispatch(actions.getKudos(projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
