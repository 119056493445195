/* eslint-disable default-case */

'use strict';

Wethod.module('SettingsApp.Payment', function (Payment, Wethod, Backbone, Marionette, $, _) {
  var structureTemplate;
  var modalTemplate;
  var billingInfoModel;
  /**
   * [restituisce il numero della carta di credito son sole le ultime 4 cifre visibili]
   * @param  {[String]} brand [tipo carta di credito (visa,mastercard, ecc)]
   * @param  {[String]} last4 [ultime 4 cifre della carta di credito, uniche cifre visibili]
   * @return {[String]}       [stringa contenente i numeri della carta oscurati]
   */
  var getCardNumber = function (brand, last4) {
    var cardNumber = '';
    switch (brand.toLowerCase()) {
      case 'visa':
      case 'mastercard':
      case 'discover':
      case 'jqb':
        cardNumber = '**** **** **** ' + last4;
        break;
      case 'american express':
        cardNumber = '**** ****** * ' + last4;
        break;
      case 'diners club':
        cardNumber = '**** **** ** ' + last4;
        break;
    }
    return cardNumber;
  };

  /**
   * [intercetta la risposta del server Stripe alla richiesta di aggiunta della carta di credito]
   * @param  {[Integer]} status   [status della risposta]
   * @param  {[Object]} response [oggetto contenente tutti i dati ritornati da Stripe dopo il
   *   salvataggio della carta di credito]
   */
  this.stripeResponseHandler = function (status, response) {
    if (response.error) {
      modalTemplate.ui.messageEl.text(response.error.message)
        .addClass('koMessage')
        .removeClass('okMessage');
      modalTemplate.ui.actionButton.removeClass('disabled');
      modalTemplate.ui.cancelButton.removeClass('disabled');
    } else {
      modalTemplate.ui.messageEl.text('Saving...').addClass('okMessage').removeClass('koMessage');

      var token = response.id;
      var options = {
        stripe_token: token,
      };
      var setTokenRequest = Wethod.request('payment:set:token', options);
      $.when(setTokenRequest).done(function () {
        var getUserCardRequest = Wethod.request('payment:get:info');
        $.when(getUserCardRequest).done(function (getUserCardResponse) {
          if (_.isUndefined(getUserCardResponse)) {
            return;
          }

          // render nuovi dati carta
          var cardTemplate;
          var cardNumber = getCardNumber(getUserCardResponse.get('card').brand, getUserCardResponse.get('card').last4);
          var cardModel = new Payment.CreditCardModel();

          var month = (parseInt(getUserCardResponse.get('card').exp_month) > 9) ? parseInt(getUserCardResponse.get('card').exp_month) : '0' + parseInt(getUserCardResponse.get('card').exp_month);
          cardModel.set({
            cardNumber: cardNumber,
            exp_month: month,
            exp_year: getUserCardResponse.get('card').exp_year,
          });
          cardTemplate = new Payment.CreditCardTemplateItemView({
            model: cardModel,
          });

          structureTemplate.ui.deleteCard.removeClass('hide');
          structureTemplate.ui.editCardLabel.text('EDIT');
          structureTemplate.cardBox.show(cardTemplate);

          modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

          setTimeout(function () {
            modalTemplate.ui.actionButton.removeClass('disabled');
            modalTemplate.ui.cancelButton.removeClass('disabled');
            modalTemplate.destroy();
          }, 2000);
        });
      });
    }
  };

  /**
   * [cancella la carta di credito associata al customer in sessione]
   */
  this.stripeDeleteCard = function () {
    modalTemplate.ui.actionButton.addClass('disabled');
    modalTemplate.ui.cancelButton.addClass('disabled');
    modalTemplate.ui.messageEl.text('Deleting...').addClass('okMessage').removeClass('koMessage');

    var deleteCardRequest = Wethod.request('delete:payment:card');
    $.when(deleteCardRequest).done(function () {
      structureTemplate.ui.deleteCard.addClass('hide');
      structureTemplate.ui.editCardLabel.text('+ CARD');

      var cardTemplate = new Payment.NoCreditCardTemplateItemView();
      structureTemplate.cardBox.show(cardTemplate);

      modalTemplate.ui.messageEl.text('Deleted').addClass('okMessage').removeClass('koMessage');
      modalTemplate.destroy();
    });
  };

  /**
   * [renderizza modal generico per le azioni di conferma dell'utente]
   * @param  {[Object]} data [contiene tutti i dati per creare il modello del modal]
   */
  this.showModal = function (data) {
    var modalModel = new Payment.ModalModel(data);

    modalTemplate = new Payment.ModalItemView({
      model: modalModel,
    });
    structureTemplate.modal.show(modalTemplate);
  };

  /**
   * [renderizza modal associato all'aggiunta della carta di credito]
   */
  this.showAddCardModal = function () {
    modalTemplate = new Payment.AddCardModalItemView();
    structureTemplate.modal.show(modalTemplate);
  };

  /**
   * [renderizza modal con i dati di fatturazione]
   */
  this.showBillingInfoModal = function () {
    var modalModel = new Payment.ModalModel({
      type: billingInfoModel.get('type'),
    });
    modalTemplate = new Payment.BillingInfoModalItemView({
      model: modalModel,
    });
    structureTemplate.modal.show(modalTemplate);
  };

  /**
   * [ renderizza il form corretto a seconda della scelta dell'utente (private/company) ]
   * @param  {[Integer]}      type  [ valore della modalità associata a private = 0 /company = 1 ]
   * @param  {[Marionette Obj]}  modal [ obj marionette della view del modal ]
   */
  this.switchBillingType = function (type, modal) {
    var editModel;
    switch (type) {
      case 0: // private
        if (billingInfoModel.get('type') === 0) {
          editModel = billingInfoModel;
        } else {
          editModel = new Payment.BillingInfoModel();
        }
        break;
      case 1: // company
        if (billingInfoModel.get('type') === 1) {
          editModel = billingInfoModel;
        } else {
          editModel = new Payment.BillingInfoModel();
          editModel.set({ type: 1 });
        }
        break;
    }

    var editTemplate = new Payment.BillingEditItemView({
      model: editModel,
      parentView: modal,
    });

    modal.options.userBillingInfo = editModel;
    modal.ui.editBillingInfoArea.html(editTemplate.render().$el);
  };

  /**
   * [ visualizza gli errori all'interno del modal di inserimento dei dati di fatturazione ]
   * @param  {[Array]} errors [array di oggetti che contengono l'errore la key permette di
   *   individuare il campo che genera l'errore mentre il valore è il messaggio da visualizzare]
   */
  var displayBillingInfoError = function (errors) {
    _.each(errors, function (error) {
      _.each(error, function (val, key) {
        modalTemplate.ui.messageEl.text(val).addClass('koMessage').removeClass('okMessage');

        $('input[name="' + key + '"]').addClass('billingError');
        $('input[name="' + key + '"]')
          .parent()
          .find('.mdl-textfield__label')
          .addClass('billingError');
      });
    });
  };

  /**
   * [ valida il modello da salvare, se non ci sono errori fa il persist dell'oggetto sul db e
   * renderizza i dati inseriti nella view principale ]
   * @param  {[Backbone Obj]} model [ modello Backbone che contiene i dati di fatturazione
   *   dell'utente ]
   */
  this.saveBillingInfo = function (model) {
    if (model.isValid()) {
      if (!_.isUndefined(billingInfoModel.get('id'))) {
        model.set({ id: billingInfoModel.get('id') });
      }

      switch (model.get('type')) {
        case 0:
          model.set({ business_name: '' });
          break;
        case 1:
          model.set({
            name: '',
            surname: '',
          });
          break;
      }
      var saveBillingInfoRequest = Wethod.request('payment:save:billingInfo', model);
      modalTemplate.ui.messageEl.text('Saving...').addClass('okMessage').removeClass('koMessage');
      $.when(saveBillingInfoRequest).done(function (saveBillingInfoResponse) {
        billingInfoModel = saveBillingInfoResponse;
        var recapTemplate = new Payment.BillingInfoTemplateItemView({
          model: billingInfoModel,
        });

        modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

        // structureTemplate.ui.deleteBillingInfo.removeClass('hide');
        structureTemplate.ui.editBillingInfoLabel.text('EDIT');
        structureTemplate.billingBox.show(recapTemplate);
        // abilito il tasto per l'inserimento della carta di credito
        structureTemplate.ui.editCard.removeClass('disabled');

        modalTemplate.ui.messageEl.text('Saved!').addClass('okMessage').removeClass('koMessage');

        setTimeout(function () {
          modalTemplate.ui.actionButton.removeClass('disabled');
          modalTemplate.ui.cancelButton.removeClass('disabled');
          modalTemplate.destroy();
        }, 2000);
      });
    } else {
      modalTemplate.ui.actionButton.removeClass('disabled');
      modalTemplate.ui.cancelButton.removeClass('disabled');

      displayBillingInfoError(model.validationError);
    }
  };

  /**
   * [ riumuove i dati di fatturazione dal DB e aggiorna la view ]
   */
  this.deleteBillingInfo = function () {
    modalTemplate.ui.actionButton.addClass('disabled');
    modalTemplate.ui.cancelButton.addClass('disabled');
    modalTemplate.ui.messageEl.text('Deleting...').addClass('okMessage').removeClass('koMessage');

    var deleteCardRequest = Wethod.request('payment:delete:billingInfo', billingInfoModel);
    $.when(deleteCardRequest).done(function () {
      structureTemplate.ui.deleteBillingInfo.addClass('hide');
      structureTemplate.ui.editBillingInfoLabel.text('+ BILLING INFO');

      billingInfoModel = new Payment.BillingInfoModel();

      var noBillingInfoTemplate = new Payment.NoBillingInfoTemplateItemView();
      structureTemplate.billingBox.show(noBillingInfoTemplate);

      modalTemplate.ui.messageEl.text('Deleted').addClass('okMessage').removeClass('koMessage');
      modalTemplate.destroy();
    });
  };

  Payment.Controller = {
    showPayment: function () {
      var canEditCard = false;
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest).done(function (authorizationResponse) {
        if (_.isUndefined(authorizationResponse)) {
          return;
        }

        Wethod.SettingsApp.authorization = authorizationResponse;

        var getPaymentInfoRequest = Wethod.request('payment:get:info');
        var getBillingInfoRequest = Wethod.request('payment:get:billingInfo');

        $.when(getPaymentInfoRequest, getBillingInfoRequest)
          .done(function (getPaymentInfoResponse, getBillingInfoResponse) {
            if (_.isUndefined(getPaymentInfoResponse)) {
              return;
            }

            // render della struttura
            structureTemplate = new Payment.StructureLayoutView();
            structureTemplate.render();
            // CREDIT CARD
            var cardTemplate;
            // se c'è una carta già associata al customer la visualizza
            if (!_.isUndefined(getPaymentInfoResponse.get('card')) && !_.isUndefined(getPaymentInfoResponse.get('card').last4)) {
              var cardModel = new Payment.CreditCardModel();

              var cardNumber = getCardNumber(getPaymentInfoResponse.get('card').brand, getPaymentInfoResponse.get('card').last4);

              var month = (parseInt(getPaymentInfoResponse.get('card').exp_month) > 9) ? parseInt(getPaymentInfoResponse.get('card').exp_month) : '0' + parseInt(getPaymentInfoResponse.get('card').exp_month);

              cardModel.set({
                cardNumber: cardNumber,
                exp_month: month,
                exp_year: getPaymentInfoResponse.get('card').exp_year,
              });
              cardTemplate = new Payment.CreditCardTemplateItemView({
                model: cardModel,
              });

              structureTemplate.ui.deleteCard.removeClass('hide');
              structureTemplate.ui.editCardLabel.text('EDIT');
            } else {
              cardTemplate = new Payment.NoCreditCardTemplateItemView();
            }

            // BILLING INFO
            billingInfoModel = getBillingInfoResponse;

            var billingInfoTemplate;
            // in base ai dati ritornati dal server decido che view visualizzare
            if (_.isUndefined(getBillingInfoResponse.get('id'))) {
              billingInfoTemplate = new Payment.NoBillingInfoTemplateItemView();
            } else {
              canEditCard = true;
              billingInfoTemplate = new Payment.BillingInfoTemplateItemView({
                model: billingInfoModel,
              });

              // abilito il pulsante per rimuovere i dati di fatturazione
              // structureTemplate.ui.deleteBillingInfo.removeClass('hide');
              structureTemplate.ui.editBillingInfoLabel.text('EDIT');
            }

            structureTemplate.billingBox.show(billingInfoTemplate);
            structureTemplate.cardBox.show(cardTemplate);

            if (canEditCard) {
              structureTemplate.ui.editCard.removeClass('disabled');
            }
          });
      });
    },
  };
});
