const React = require('react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const Empty = require('../../../../../../common/react/lists/EmptyRow.react');
const Search = require('../../../../../../common/react/Table2/TableSearch.react');
const TagRow = require('./TagRow.react');

module.exports = class TagsTable extends React.Component {
  static loadMore() {}

  static updateHeight() {}

  getTags() {
    const all = this.props.tags.slice(0, 1); // First element is 'all'
    const tags = this.props.tags.slice(1);

    return []
      .concat(
        <TagRow key="all"
          tagList={all}
          onTagClick={this.props.onTagClick} />,
      ).concat(
        <TagRow key="list"
          tagList={tags}
          onTagClick={this.props.onTagClick}
          name={this.props.name} />,
      );
  }

  render() {
    return (
      <Table columns={1} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="tag" className="knowledge__column-tag capitalize">{this.props.name}</TableCell>
          </TableRow>
          <TableRow className="knowledge__search-row knowledge__search-row--first">
            <TableCell name="search" className="knowledge__search-cell">
              <Search placeholder={`Search ${this.props.name}`}
                updateHeight={TagsTable.updateHeight}
                filter={this.props.filter} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={false}
          loadMore={TagsTable.loadMore}
          empty={<Empty message={`No ${this.props.name} found`} />}>
          {this.getTags()}
        </TableBody>
      </Table>
    );
  }
};
