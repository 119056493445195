module.exports.GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
module.exports.GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
//
module.exports.FILTER_PRODUCTS_REQUEST = 'FILTER_PRODUCTS_REQUEST';
module.exports.FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
//
module.exports.UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
module.exports.UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
module.exports.UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

module.exports.CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
module.exports.CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';

module.exports.AVAILABILITY_FILTER_CHANGE = 'AVAILABILITY_FILTER_CHANGE';

module.exports.GET_LEVELS_REQUEST = 'GET_LEVELS_REQUEST';
module.exports.GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

// module.exports.DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
// module.exports.DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
//
// module.exports.SHOW_MODAL = 'SHOW_MODAL';
// module.exports.SHOW_EXPORT_MODAL = 'SHOW_EXPORT_MODAL';
// module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
//
// module.exports.EXPORT_REQUEST = ' EXPORT_REQUEST';
// module.exports.EXPORT_SUCCESS = ' EXPORT_SUCCESS';
//
// module.exports.RESET_PRODUCT_SERVER_ERROR = 'RESET_PRODUCT_SERVER_ERROR';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.CHANGE_SIDEBAR_EDIT_MODE = 'CHANGE_SIDEBAR_EDIT_MODE';
module.exports.CHANGE_UNSAVED_CHANGES = 'CHANGE_UNSAVED_CHANGES';
