/* eslint-disable consistent-return,default-case,no-shadow,react/jsx-no-bind */
const React = require('react');

module.exports = ({ selectCategory, totals, errors, missingValues, editable }) => {
  const categories = ['train', 'flight', 'car', 'accommodation', 'other'];

  const getIcon = (category) => {
    switch (category) {
      case 'train':
        return 'wethod-icon wethod-icon-train wethod-icon-train--black';
      case 'flight':
        return 'wethod-icon wethod-icon-travel wethod-icon-travel--black';
      case 'car':
        return 'wethod-icon wethod-icon-car wethod-icon-car--black';
      case 'accommodation':
        return 'wethod-icon wethod-icon-bed wethod-icon-bed--black';
      case 'other':
        return 'wethod-icon wethod-icon-asterisk wethod-icon-asterisk--black';
    }
  };

  const getLabel = (category) => {
    switch (category) {
      case 'train':
        return 'Train travel';
      case 'flight':
        return 'Flight travel';
      case 'car':
        return 'Car rental';
      case 'accommodation':
        return 'Accommodation';
      case 'other':
        return 'Other';
    }
  };

  const handleClick = (category) => {
    if (totals && totals[category]) {
      selectCategory(category);
    }
  };

  const getDot = (category) => {
    if (errors && errors[category]) {
      return <div className="travel__category-dot travel__category-dot--error" />;
    }
    if (totals && totals[category]) {
      return <div className="travel__category-dot">{totals[category]}</div>;
    }
  };

  const getStyle = (category, editable) => {
    let style = 'travel__category';
    if (!editable && (!totals || !totals[category])) {
      style += ' disabled';
    }
    if (missingValues && missingValues[category]) {
      style += ' travel-office--highlight';
    }
    return style;
  };

  const getCategories = () => {
    if (editable) {
      return categories.map((category) => (
        <div key={category}
          className={getStyle(category, true)}
          onClick={() => selectCategory(category)}>
          <span className={getIcon(category)} />
          <span className="travel__category-name">{getLabel(category)}</span>
          {getDot(category)}
          <span className="wethod-icon wethod-icon-forward wethod-icon-forward--black" />
        </div>
      ));
    }
    return categories.map((category) => (
      <div key={category} className={getStyle(category)} onClick={() => handleClick(category)}>
        <span className={getIcon(category)} />
        <span className="travel__category-name">{getLabel(category)}</span>
        {getDot(category)}
        <span className="wethod-icon wethod-icon-forward wethod-icon-forward--black" />
      </div>
    ));
  };

  return (
    <div>
      {getCategories()}
    </div>
  );
};
