const React = require('react');
const Tooltip = require('../../../../../../../common/react/tooltip/Tooltip.react');
const FeatureService = require('../../services/FeatureService');
const HolidayTextFormatter = require('../../common/HolidayTextFormatter.react');

const Counter = ({ id, label, value, onClick, tooltip, capacity, isHoliday = false }) => {
  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  const className = onClick ? 'timesheet-detailed-counter timesheet-detailed-counter--clickable' : 'timesheet-detailed-counter';

  const tooltipStyle = {
    top: '-27px',
    left: 'calc(50% - 37px)',
  };

  const getTooltip = () => (tooltip ? <Tooltip content={tooltip} style={tooltipStyle} /> : null);

  const labelId = `${id}-label`;

  const getValue = () => {
    if (FeatureService.isEmployeeCapacityEnabled()) {
      const formattedValue = `${value}/${capacity}`;
      if (isHoliday) {
        return <HolidayTextFormatter>{formattedValue}</HolidayTextFormatter>;
      }
      return formattedValue;
    }
    return value;
  };

  return (
    <div className={className} onClick={handleClick}>
      {getTooltip()}
      <div id={labelId} className="timesheet-detailed-counter__label">{label}</div>
      <div className="timesheet-detailed-counter__value" aria-labelledby={labelId}>{getValue()}</div>
    </div>
  );
};

module.exports = Counter;
