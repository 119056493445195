'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Authorization = require('../../../models/Authorization');
var TimesheetSettings = require('../../../models/TimesheetSettings');
var TimesheetsStore = require('../../../apps/core/modules/report/timesheets/store');
var TimesheetsReducer = require('../../../apps/core/modules/report/timesheets/reducer');
var Timesheets = require('../../../apps/core/modules/report/timesheets/index');
var FeatureService = require('../../../services/FeatureService');

Wethod.module('ReportApp.TimesheetsList', function (TimesheetsList, Wethod, Backbone, Marionette, $) {
  TimesheetsList.Controller = {
    showNoPermission: function () {
      var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
      Wethod.regions.body.show(permissionDeniedView);
    },
    show: function () {
      $.when(
        Authorization.getPermissions('reports'),
        TimesheetSettings.getStrategy(),
        Authorization.getPermissions('time_tracking')
      ).done(function (reportPermissions, strategy, timeTrackingPermissions) {
        if (_.isUndefined(reportPermissions) || !reportPermissions.view_timesheet) {
          TimesheetsList.Controller.showNoPermission();
        } else {
          var layout = new TimesheetsList.StructureLayoutView();
          layout.render();

          var reducerWrapper = new TimesheetsReducer({
            reportPermissions: reportPermissions,
            timesheetPermissions: timeTrackingPermissions,
            strategy: strategy.strategy,
            isEnabledDataFreezingFeature: Wethod.featureService
              .isEnabled(FeatureService.DATA_FREEZING),
          });
          var store = TimesheetsStore(reducerWrapper.reduxReducer);
          var element = React.createElement(Timesheets, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        }
      }).fail(function () {
        TimesheetsList.Controller.showNoPermission();
      });
    },
  };
});
