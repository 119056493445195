'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var InvoicePlanStore = require('../../apps/core/modules/invoice-plan/store');
var InvoicePlanReducer = require('../../apps/core/modules/invoice-plan/reducer');
var InvoicePlan = require('../../apps/core/modules/invoice-plan/index');

Wethod.module('InvoicePlanApp', function (InvoicePlanApp, Wethod, Backbone, Marionette, $, _) {
  InvoicePlanApp.Controller = {
    show: function (projectId) {
      var projectRequest = Wethod.request('get:project:info', { projectId: projectId });
      var invoicePlanRequest = Wethod.request('get:pipeline:invocePlan', projectId);
      var authorizationRequest = Wethod.request('get:pipeline:authorization');
      var invoiceAuthorizationRequest = Wethod.request('get:finance:authorization', { app: 'invoices' });

      $.when(projectRequest, authorizationRequest, invoiceAuthorizationRequest)
        .done(function (project, permissions, invoicePermissions) {
          if (_.isUndefined(project)) { // no project with the given id
            Wethod.trigger('url:404:show');
          } else {
            Wethod.InvoicePlanApp.authorization = permissions;
            Wethod.FinanceApp.authorization = invoicePermissions;

            var idEmployee = Wethod.userInfo.get('employee_id');
            var idPM = project.pm.id;
            var idAccount = project.account ? project.account.id : null;
            var projectBelongsToEmployee = Wethod
              .Authorization.isPmOrAccount(idEmployee, idPM, idAccount);
            var canView = (permissions.get('view') && projectBelongsToEmployee) || (permissions.get('view_other') && !projectBelongsToEmployee);
            var canEdit = (permissions.get('edit') && projectBelongsToEmployee) || (permissions.get('edit_other') && !projectBelongsToEmployee);

            if (!canView) {
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            } else {
              var state = {
                project: project,
                canEdit: canEdit, // if you are here, you have at least 'view' permissions
              };

              $.when(invoicePlanRequest).done(function (invoicePlan, isLinked) {
                function renderElement(definedState) {
                  definedState.isLinked = isLinked;

                  var layout = new InvoicePlanApp.StructureLayoutView();
                  layout.render();

                  var reducerWrapper = new InvoicePlanReducer(definedState);
                  var store = InvoicePlanStore(reducerWrapper.reduxReducer);
                  var element = React.createElement(InvoicePlan, { store: store });
                  var container = document.getElementById('root');
                  if (container !== null) {
                    ReactDOM.render(element, container);
                  }
                }

                if (isLinked) {
                  var masterProjectRequest = Wethod.request('get:project:info', { projectId: invoicePlan.get('master_project_id') });
                  $.when(masterProjectRequest).done(function (masterProject) {
                    state.billingGroup = invoicePlan.get('billing_group_id');
                    state.masterProject = {
                      id: masterProject.id,
                      name: masterProject.name,
                      // eslint-disable-next-line no-underscore-dangle
                      _fields: masterProject._fields,
                    };
                    renderElement(state);
                  });
                } else {
                  state.currency = invoicePlan.get('currency');
                  state.invoices = invoicePlan.get('invoices_by_month');
                  state.plan = invoicePlan.get('plan');
                  state.isLinked = isLinked;
                  renderElement(state);
                }
              });
            }
          }
        });
    },
  };
});
