const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ExpenseSidebar.react');
const actions = require('../../actions');
const getDefaultEmployee = require('../../../../../../services/UserService').getLoggedEmployee;

const getDefaultExpense = () => ({
  requested_by: getDefaultEmployee(),
  owner: getDefaultEmployee(),
});

const getFocusedExpense = (expenses, focusedExpenseId) => {
  const expense = expenses.filter((exp) => exp.id === focusedExpenseId);
  return expense && expense.length ? expense[0] : getDefaultExpense();
};

const mapStateToProps = (state) => ({
  item: getFocusedExpense(state.expenses, state.focusedItemId),
  expenseMetadata: state.expenseMetadata,
  isSaving: state.waitingFor.filter((key) => key === 'save-expense').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (item) => dispatch(actions.showDeleteModal(item)),
  onClose: () => dispatch(actions.closeSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
