/* eslint-disable class-methods-use-this,consistent-return */
const React = require('react');

module.exports = class SecondaryInfo extends React.Component {
  getUrl(string) {
    if (string) {
      if (string.indexOf('http') !== -1) return string;
      return `http://${string}`;
    }
    return '';
  }

  /**
   * Get number formatted as "333 123 3456".
   */
  getPhoneNumber() {
    if (this.props.phone) return this.props.phone.number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
  }

  render() {
    return (
      <div>
        <div className="client-info-row">
          <div className="client-info-row__title">Phone</div>
          <div className="client-info-row__value">
            <a
              href={`tel:${this.getPhoneNumber()}`}>
              {this.getPhoneNumber()}
            </a>
          </div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Email</div>
          <div className="client-info-row__value">
            <a href={`mailto:${this.props.person.email}`}>{this.props.person.email}</a>
          </div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Role</div>
          <div className="client-info-row__value">{this.props.person.role}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Business Unit</div>
          <div className="client-info-row__value">{this.props.person.unit}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">LinkedIn</div>
          <div className="client-info-row__value">
            <a href={this.getUrl(this.props.person.linkedin)}>{this.props.person.linkedin}</a>
          </div>
        </div>
      </div>
    );
  }
};
