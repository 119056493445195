const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../Accordion/Accordion.react');
const Counter = require('./ValueCounter.react');

require('./style.scss');

const FilterAccordion = ({ children, value, label, expanded, className }) => {
  const howManyValues = () => {
    if (Array.isArray(value)) {
      return value.length;
    }
    if (typeof value === 'object') {
      return Object.keys(value).length;
    }
    return value.length ? 1 : 0;
  };

  const getClassName = () => `wethod-advanced-filters-accordion ${className}`;

  return (
    <Accordion label={label}
      expanded={expanded}
      summary={<Counter value={howManyValues()} />}
      className={getClassName()}>
      {children}
    </Accordion>
  );
};

FilterAccordion.defaultProps = {
  expanded: false,
  label: '',
  className: '',
};

FilterAccordion.propTypes = {
  /** React element to use as content * */
  children: PropTypes.element.isRequired,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
};

module.exports = FilterAccordion;
