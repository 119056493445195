const React = require('react');
const IconButton = require('../../../../../../../../common/react/Button/IconButton.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');

const BlockInput = ({ block, children, deletable, className, onBlockDelete }) => {
  const style = className
    ? `company-settings-jo-template__block-input ${className}`
    : 'company-settings-jo-template__block-input';

  function handleBlockDelete() {
    onBlockDelete(block.sort);
  }

  return (
    <div data-sort={block.sort}
      key={block.sort}
      className={style}>
      {children}
      <ShowIf condition={deletable}>
        <IconButton onClick={handleBlockDelete} label={`Delete block in position ${block.sort + 1}`} icon="discard" size="small" color="black" />
      </ShowIf>
    </div>
  );
};

module.exports = BlockInput;
