const React = require('react');
const Project = require('./Work.react');

module.exports = class Portfolio extends React.Component {
  getProjects() {
    return this.props.works.map((work) => <Project key={work.id} {...work} />);
  }

  getContent() {
    if (this.props.works.length > 0) return this.getProjects();
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile-portfolio">{this.getContent()}</div>
    );
  }
};
