const { connect } = require('react-redux');
const Opportunity = require('../components/Opportunity.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  showSidebar: (opportunity) => dispatch(actions.showSidebar(opportunity)),
  addProject: (opportunity) => dispatch(actions.addProject(opportunity)),
});

module.exports = connect(null, mapDispatchToProps)(Opportunity);
