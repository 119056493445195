const React = require('react');
const PropTypes = require('prop-types');
const FileModel = require('../models/File');
const UploadButton = require('./UploadButton.react');
const SearchBar = require('./SearchBar.react');
const FileList = require('./FileList.react');
const SmallLoader = require('./SmallLoader.react');

/**
 * Component used to manage files coming from internal S3 storage.
 */
class FileManagerInternalContent extends React.Component {
  constructor(props) {
    super(props);

    this.onUpload = this.onUpload.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.accept = [
      'image/jpeg',
      'image/jpeg',
      'image/gif',
      'image/png',
      'image/bmp',
      'image/tiff',
      'image/tiff',
      'image/x-icon',
      'image/svg+xml',
      'video/x-ms-asf',
      'video/x-ms-asf',
      'video/x-ms-wmv',
      'video/x-ms-wmx',
      'video/x-ms-wm',
      'video/avi',
      'video/divx',
      'video/x-flv',
      'video/quicktime',
      'video/quicktime',
      'video/mpeg',
      'video/mpeg',
      'video/mpeg',
      'video/mp4',
      'video/mp4',
      'video/ogg',
      'video/webm',
      'video/x-matroska',
      'video/3gpp',
      'video/3gpp',
      'video/3gpp2',
      'video/3gpp2',
      'text/plain',
      'text/csv',
      'text/tab-separated-values',
      'text/calendar',
      'text/richtext',
      'text/css',
      'text/html',
      'text/html',
      'text/vtt',
      'application/ttaf+xml',
      'audio/mpeg',
      'audio/mpeg',
      'audio/mpeg',
      'audio/x-realaudio',
      'audio/x-realaudio',
      'audio/wav',
      'audio/ogg',
      'audio/ogg',
      'audio/midi',
      'audio/midi',
      'audio/x-ms-wma',
      'audio/x-ms-wax',
      'audio/x-matroska',
      'application/rtf',
      'application/javascript',
      'application/pdf',
      'application/x-shockwave-flash',
      'application/java',
      'application/x-tar',
      'application/zip',
      'application/x-gzip',
      'application/x-gzip',
      'application/rar',
      'application/x-7z-compressed',
      'application/octet-stream',
      'application/octet-stream',
      'application/postscript',
      'application/x-indesign',
      'application/msword',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-write',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel',
      'application/vnd.ms-access',
      'application/vnd.ms-project',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.slide',
      'application/vnd.ms-powerpoint.slide.macroEnabled.12',
      'application/onenote',
      'application/onenote',
      'application/onenote',
      'application/onenote',
      'application/oxps',
      'application/vnd.ms-xpsdocument',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.graphics',
      'application/vnd.oasis.opendocument.chart',
      'application/vnd.oasis.opendocument.database',
      'application/vnd.oasis.opendocument.formula',
      'application/wordperfect',
      'application/wordperfect',
      'application/vnd.apple.keynote',
      'application/vnd.apple.numbers',
      'application/vnd.apple.pages',
    ];
    this.maxSize = 128000000;
  }

  componentDidMount() {
    this.props.setHeaderAction(this.getAction());
  }

  componentDidUpdate(prevProps) {
    const changeUploading = prevProps.isUploading !== this.props.isUploading;
    if (changeUploading) {
      this.props.setHeaderAction(this.getAction());
    }
  }

  onUpload(file) {
    this.props.onUpload(file);
  }

  onSearch(keyword) {
    this.props.onSearch(keyword);
  }

  getAction() {
    if (this.props.isUploading) {
      return <SmallLoader />;
    }
    if (this.props.canEdit && !this.props.isExternalDriveEnabled) {
      return (
        <UploadButton onUpload={this.onUpload}
          accept={this.accept}
          maxSize={this.maxSize}
          onUploadError={this.props.onUploadError} />
      );
    }
    return null;
  }

  getClassName() {
    return `wethod-widget-files__content wethod-widget-files__content--internal ${this.props.className}`;
  }

  isSearching() {
    return this.props.searchKeyword !== '';
  }

  loadMore(size) {
    this.props.loadMore(size);
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <SearchBar onSearch={this.onSearch}
          keyword={this.props.searchKeyword} />
        <FileList hasMore={this.props.hasMore}
          loadMore={this.loadMore}
          isLoading={this.props.isLoadingFiles}
          deletingFiles={this.props.deletingFiles}
          files={this.props.files}
          onDelete={this.props.onDeleteFile}
          onDownload={this.props.onDownloadFile}
          canEdit={this.props.canEdit}
          isSearching={this.isSearching()}
          emptyFilesMessage={this.props.emptyFilesMessage} />
        {this.props.modal}
      </div>
    );
  }
}

FileManagerInternalContent.defaultProps = {
  files: [],
  canEdit: false,
  isLoadingFiles: false,
  searchKeyword: '',
  onDeleteFile: null,
  onDownloadFile: null,
  deletingFiles: [],
  isUploading: false,
  isExternalDriveEnabled: false,
  className: '',
  modal: null,
};

FileManagerInternalContent.propTypes = {
  /** Function to call to change parent header action.
   * @param {PropTypes.node} component
   */
  setHeaderAction: PropTypes.func.isRequired,
  /** Function to call in order to upload a file.
   * @param {Object} file
   * @param {string} parentId
   */
  onUpload: PropTypes.func.isRequired,
  /**
   * The function to call when search keyword is updated.
   * @param keyword {string}
   * @param {string} parentId
   */
  onSearch: PropTypes.func.isRequired,
  /**
   * The function to call to load files with given parent.
   * @param {integer} size
   * @param {string} parentId
   */
  loadMore: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileModel)),
  canEdit: PropTypes.bool,
  isLoadingFiles: PropTypes.bool,
  /** Id list of files being deleted * */
  deletingFiles: PropTypes.arrayOf(PropTypes.number),
  hasMore: PropTypes.bool.isRequired,
  searchKeyword: PropTypes.string,
  /**
   * The function to call to delete a file.
   *
   * @param file {Object}
   */
  onDeleteFile: PropTypes.func,
  /**
   * The function to call to download a file.
   *
   * @param file {Object}
   */
  onDownloadFile: PropTypes.func,
  /** Component to show when list is empty after loading * */
  emptyFilesMessage: PropTypes.node.isRequired,
  isUploading: PropTypes.bool,
  isExternalDriveEnabled: PropTypes.bool,
  className: PropTypes.string,
  modal: PropTypes.node,
  /**
   * The function to call when upload fails.
   *
   * @param errors {Array}
   */
  onUploadError: PropTypes.func.isRequired,
};

module.exports = FileManagerInternalContent;
