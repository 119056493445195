const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');
const FavoriteCityModel = require('./FavoriteCityModel');

module.exports = Backbone.Collection.extend({
  model: FavoriteCityModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  _requestPool: [],
  url() {
    return `${APIURL}/favorite-city/`;
  },
  getFavorites() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
});
