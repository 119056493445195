const request = require('./Request');

const TimesheetSettings = {
  getStrategy() {
    return request({
      method: 'get',
      url: '/authorization/settings/timetracking/strategy',
    });
  },
  setStrategy(strategy) {
    return request({
      method: 'post',
      url: `/authorization/settings/timetracking/strategy?strategy=${strategy}`,
    });
  },
  getFrequency() {
    return request({
      method: 'get',
      url: '/authorization/settings/timetracking/frequency',
    });
  },
  setFrequency(frequency) {
    return request({
      method: 'post',
      url: `/authorization/settings/timetracking/frequency?frequency=${frequency}`,
    });
  },
  getTimeLimit() {
    return request({
      method: 'get',
      url: '/authorization/settings/timetracking/timelimit',
    });
  },
  setTimeLimit(enabled) {
    return request({
      method: 'post',
      url: '/authorization/settings/timetracking/timelimit',
      data: enabled,
    });
  },
  updateTimeLimit(buffer) {
    return request({
      method: 'patch',
      url: '/authorization/settings/timetracking/timelimit',
      data: buffer,
    });
  },
  getDailyModeToggle() {
    // Get the flag value of the 'daily mode toggle' used when strategy is weekly
    return request({
      method: 'get',
      url: '/authorization/settings/timetracking/weekly/daily-mode',
    });
  },
  setDailyModeToggle(mode) {
    return request({
      method: 'post',
      url: '/authorization/settings/timetracking/weekly/daily-mode',
      data: { daily_mode: mode },
    });
  },
};

module.exports = TimesheetSettings;
