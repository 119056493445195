/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,consistent-return,prefer-destructuring */
const React = require('react');

module.exports = class FollowUp extends React.Component {
  getCreationDate(day) {
    const momentDate = moment(day);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  scrollToEvent(id) {
    $('html, body').animate({
      scrollTop: ($(`#${id}`).offset().top),
    }, 500);
  }

  handleParentEventTypeClick(e) {
    e.preventDefault();
    this.scrollToEvent(this.parentEvent.event.id);
  }

  render() {
    this.parentEvent = this.props.events
      .filter((event) => event.event.followed_by === this.props.id)[0];

    return (
      <div className="timeline-event-past__content">
        <div className="timeline-event-log__creation">
          <a href={`#timeline/${this.props.project.id}`}>{_.unescape(this.props.project.name)}</a>
        </div>
        <div className="timeline-event-past__description">{this.props.description}</div>
        <div className="timeline-event-log__creation">
          This is a follow-up to the
          {' '}
          <a href="#" onClick={this.handleParentEventTypeClick.bind(this)}>{this.parentEvent.event.type.label}</a>
          {' '}
          which
          {' '}
          {this.parentEvent.created_by}
          {' '}
          had on
          {' '}
          {this.getCreationDate(this.parentEvent.timestamp)}
        </div>
      </div>
    );
  }
};
