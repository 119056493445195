'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod) {
  this.GroupModel = Wethod.Utility.BaseModel.extend({
    parseToJson: function () {
      var json = {
        type: 'compound',
        operator: this.get('operator'),
        left: this.get('left').parseToJson(),
        right: this.get('right').parseToJson(),
      };
      return json;
    },
  });
});
