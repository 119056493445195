/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../Menu.react');

/**
 * A menu used to show a list of items
 */
const ListMenu = ({
  open, anchorEl, children, className = '', onClose, ...rest
}) => (
  <Menu open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    className={className}
    {...rest}>
    <ul onClick={onClose}>{children}</ul>
  </Menu>
);

ListMenu.propTypes = {
  /**
   * Menu contents, normally an array of `MenuItem`s.
   */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.node)]),
  /**
   * @see Menu
   */
  open: PropTypes.bool,
  /**
   * The function to call when menu is closed.
   * @see Menu
   */
  onClose: PropTypes.func,
  /**
   * The DOM element used to set the position of the menu.
   * @see Menu
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

module.exports = ListMenu;
