const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const Accordion = require('../../FilterAccordion/FilterAccordion.react');
const Partial = require('./DateRangePartialInput.react');

require('./style.scss');

class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startModifier: DateRangeFilter.getStartModifier(props.value),
      startValue: DateRangeFilter.getStartValue(props.value),
      endModifier: DateRangeFilter.getEndModifier(props.value),
      endValue: DateRangeFilter.getEndValue(props.value),
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onModifierChange = this.onModifierChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentDidUpdate(prevProps) {
    const prevStart = DateRangeFilter.getStartValue(prevProps.value);
    const prevEnd = DateRangeFilter.getEndValue(prevProps.value);
    const start = DateRangeFilter.getStartValue(this.props.value);
    const end = DateRangeFilter.getEndValue(this.props.value);
    const startCleared = prevStart && start === '';
    const endCleared = prevEnd && end === '';

    if (startCleared || endCleared) {
      this.resetValues(start, end);
    }
  }

  onModifierChange(e) {
    const { name, value } = e.target;
    if (name === 'modifier-start') {
      this.setState({ startModifier: value }, this.afterChange);
    }
    if (name === 'modifier-end') {
      this.setState({ endModifier: value }, this.afterChange);
    }
  }

  onDateChange(e) {
    const { name, value } = e.target;
    if (name === 'date-start') {
      this.setState({ startValue: value }, this.afterChange);
    }
    if (name === 'date-end') {
      this.setState({ endValue: value }, this.afterChange);
    }
  }

  onClear(name) {
    if (name === 'date-start') {
      this.setState({ startValue: '' }, this.afterChange);
    }
    if (name === 'date-end') {
      this.setState({ endValue: '' }, this.afterChange);
    }
  }

  getFormattedValueForParent() {
    const value = {};

    if (this.state.startValue) {
      value.start = {
        modifier: this.state.startModifier,
        value: moment(this.state.startValue).format('YYYY-MM-DD'),
      };
    }
    if (this.state.endValue) {
      value.end = {
        modifier: this.state.endModifier,
        value: moment(this.state.endValue).format('YYYY-MM-DD'),
      };
    }

    return value;
  }

  static getStart(value) {
    return value.start ? value.start : null;
  }

  static getEnd(value) {
    return value.end ? value.end : null;
  }

  static getStartModifier(value) {
    const start = DateRangeFilter.getStart(value);

    return start ? start.modifier : 'on';
  }

  static getStartValue(value) {
    const start = DateRangeFilter.getStart(value);

    return start ? start.value : '';
  }

  static getEndModifier(value) {
    const start = DateRangeFilter.getEnd(value);

    return start ? start.modifier : 'on';
  }

  static getEndValue(value) {
    const start = DateRangeFilter.getEnd(value);

    return start ? start.value : '';
  }

  afterChange() {
    this.props.onChange(this.props.name, this.getFormattedValueForParent());
  }

  resetValues(start, end) {
    this.setState({
      startValue: start,
      endValue: end,
    });
  }

  render() {
    // Is used from by Accordion to understand how many filters values has been selected
    const enumerableValue = [this.state.startValue, this.state.endValue]
      .filter((value) => value !== '');

    return (
      <Accordion expanded={this.props.expanded}
        label={this.props.label}
        value={enumerableValue}
        className={this.props.className}>
        <div className="wethod-advanced-search-filter-date-range__content">
          <Partial id={this.props.id}
            onModifierChange={this.onModifierChange}
            onDateChange={this.onDateChange}
            name="start"
            date={this.state.startValue}
            modifier={this.state.startModifier}
            modifierLabel="Start"
            onClear={this.onClear} />
          <Partial id={this.props.id}
            onModifierChange={this.onModifierChange}
            onDateChange={this.onDateChange}
            name="end"
            date={this.state.endValue}
            modifier={this.state.endModifier}
            modifierLabel="End"
            onClear={this.onClear} />
        </div>
      </Accordion>
    );
  }
}

DateRangeFilter.defaultProps = {
  expanded: false,
  label: '',
  className: '',
  value: {
    start: {
      modifier: 'on',
      value: '',
    },
    end: {
      modifier: 'on',
      value: '',
    },
  },
  onChange: null,
};

DateRangeFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    start: PropTypes.shape({
      modifier: PropTypes.string,
      value: PropTypes.string,
    }),
    end: PropTypes.shape({
      modifier: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
};

module.exports = DateRangeFilter;
