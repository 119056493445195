/* eslint-disable react/sort-comp,react/no-did-update-set-state,react/no-access-state-in-setstate,
 class-methods-use-this */
const React = require('react');
const RevenuesReport = require('../../containers/Kpi/RevenuesReport');
const SoldValueReport = require('../../containers/Kpi/SoldValueReport');
const YearFilter = require('../../models/TimeFilter/YearFilter');
const QuarterFilter = require('../../models/TimeFilter/QuarterFilter');
const Trends = require('../../containers/Kpi/Trends/Trends');
const TimeFilterFactory = require('../../models/TimeFilter/TimeFilterFactory');
const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

module.exports = class Kpi extends React.Component {
  constructor(props) {
    super(props);

    this.fiscalYearService = new FiscalYearService();
  }

  getRevenuesInPeriod() {
    const monthsString = TimeFilterFactory(this.props.timeFilter).getMonthsInPeriod().join();

    return this.props.revenues.filter((revenue) => monthsString.indexOf(revenue.month) !== -1);
  }

  getProjectsInPeriod() {
    const monthsString = TimeFilterFactory(this.props.timeFilter).getMonthsInPeriod().join();

    return this.props.projects.filter((project) => monthsString
      .indexOf(project.last_probability_update) !== -1);
  }

  getFilterButtonClassName(value) {
    let name = 'client-kpi__time-filter-item';
    if (TimeFilterFactory(this.props.timeFilter).getLabel() === value) {
      name += ' client-kpi__time-filter-item--selected';
    }
    return name;
  }

  onOneYearFilterClick() {
    const filter = new QuarterFilter();
    this.props.updateTimeFilter(filter.toJSON());
  }

  onThreeYearFilterClick() {
    const filter = new YearFilter(3);
    this.props.updateTimeFilter(filter.toJSON());
  }

  onFiveYearFilterClick() {
    const filter = new YearFilter(5);
    this.props.updateTimeFilter(filter.toJSON());
  }

  render() {
    return (
      <div className="client-kpi">
        <div className="client-kpi__time-filter">
          <button type="button"
            className={this.getFilterButtonClassName('1 year')}
            onClick={this.onOneYearFilterClick.bind(this)}>
            {this.fiscalYearService
              .getYearDynamically(this.fiscalYearService.getShiftedYear(moment().year()))}
          </button>
          <button type="button"
            className={this.getFilterButtonClassName('3 years')}
            onClick={this.onThreeYearFilterClick.bind(this)}>
            3 years
          </button>
          <button type="button"
            className={this.getFilterButtonClassName('5 years')}
            onClick={this.onFiveYearFilterClick.bind(this)}>
            5 years
          </button>
        </div>
        <RevenuesReport waiting={this.props.isWaitingForRevenues}
          revenues={this.getRevenuesInPeriod()} />
        <SoldValueReport
          waiting={this.props.isWaitingForProjects}
          projects={this.getProjectsInPeriod()}
          projectsInFrame={this.props.projectsInFrame} />
        <Trends />
      </div>
    );
  }
};
