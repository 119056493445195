'use strict';

Wethod.module('PermissionDenied', function (Views, Wethod, Backbone, Marionette) {
  /**
   * PermissionDenied View
   */
  this.PermissionDeniedTemplateView = Marionette.ItemView.extend({
    className: 'grid clear wrapPermissionDeniedText',
    template: '#permissionDeniedTemplate',
  });
});
