const React = require('react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const BudgetAreas = require('./BudgetAreas.react');
const FeedbackCannotEdit = require('./FeedbackCannotEdit');
const TimesheetModel = require('../../../../../../../models/TimesheetDetailed');

const ProjectHours = ({
  show, projectId, areas, joc, canEdit, archived, isInWhitelist, reasonCannotEdit,
  hasTodayImputedHours,
}) => {
  const DOMsizes = {
    hoursHeader: 60,
    budgetArea: 60,
  };
  const getStyle = () => {
    if (show) {
      return {
        maxHeight: `${DOMsizes.budgetArea * (hasTodayImputedHours ? areas.length + 1 : areas.length) + DOMsizes.hoursHeader}px`,
      };
    }

    return null;
  };

  const reasonWhyCannotEditHour = () => {
    let reason;
    switch (reasonCannotEdit) {
      case TimesheetModel.CANNOT_EDIT_BECAUSE_ARCHIVED:
        reason = (<FeedbackCannotEdit title="Archived Project" description="This project is being archived. Unarchive it to edit timesheet." />);
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_WHITELIST:
        reason = (<FeedbackCannotEdit title="Whitelisted Project" description="You need to be a whitelisted team member to update this timesheet." />);
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_AUTOMATIC:
        reason = (<FeedbackCannotEdit title="Automatic Timesheet" description="Only working hours of previous weeks can be edited on automatic timesheets." />);
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_PROJECT_SCOPE:
        reason = (<FeedbackCannotEdit title="This timesheet can't be edited" description="It is not possible to record working hours out of project scope." />);
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_TIME_BUFFER:
        reason = (<FeedbackCannotEdit title="This timesheet can't be edited anymore" description="The time allowed to record or edit working hours on this project has ended. Ask someone from your team with a permission to edit." />);
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_DATA_FROZEN:
        reason = (<FeedbackCannotEdit title="Read-only data" description="This information has been frozen. You won't be able to edit it again." />);
        break;
      default:
        reason = null;
    }
    return reason;
  };
  return (
    <div
      className="timesheet-project__hours"
      style={getStyle()}>
      <ShowIf condition={canEdit || hasTodayImputedHours} showElse={reasonWhyCannotEditHour()}>
        <div>
          <div className="timesheet-project__hours-header">
            <div className="timesheet-project__hours-icon">
              <div className="timesheet-project__hours-icon__picture">
                <div className="wethod-icon-ordinary-work" />
              </div>
              <div className="timesheet-project__hours-icon__label">
                Ordinary
              </div>
            </div>
            <div className="timesheet-project__hours-icon">
              <div className="timesheet-project__hours-icon__picture">
                <div className="wethod-icon-home" />
              </div>
              <div className="timesheet-project__hours-icon__label">
                Remote
              </div>
            </div>
            <div className="timesheet-project__hours-icon">
              <div className="timesheet-project__hours-icon__picture">
                <div className="wethod-icon-travel" />
              </div>
              <div className="timesheet-project__hours-icon__label">
                Travel
              </div>
            </div>
            <div className="timesheet-project__hours-icon">
              <div className="timesheet-project__hours-icon__picture">
                <div className="wethod-icon-night-shift" />
              </div>
              <div className="timesheet-project__hours-icon__label">
                Night Shift
              </div>
            </div>
            <div className="timesheet-project__hours-icon">
              <div className="timesheet-project__hours-icon__picture">
                <div className="wethod-icon-overtime" />
              </div>
              <div className="timesheet-project__hours-icon__label">
                Overtime
              </div>
            </div>
          </div>
          <BudgetAreas
            areas={areas}
            joc={joc}
            archived={archived}
            projectId={projectId}
            canEdit={canEdit}
            isInWhitelist={isInWhitelist} />
          {!canEdit && reasonWhyCannotEditHour()}
        </div>
      </ShowIf>
    </div>
  );
};

module.exports = ProjectHours;
