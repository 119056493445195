const { connect } = require('react-redux');
const CompanySelect = require('../components/ClientSearchSelectCreate.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  items: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (company) => dispatch(actions.saveCompany(company)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(CompanySelect);
