/* eslint-disable no-use-before-define */
const { connect } = require('react-redux');
const Component = require('../../../components/modals/Sponsors/SponsorsBody.react');
const actions = require('../../../actions');

const getItemsIds = (items) => items.map((item) => item.sponsor);

const getNonSponsorPeople = (people, sponsors) => {
  const actualSponsors = sponsors.filter(isActualSponsor);
  const actualSponsorsIds = getItemsIds(actualSponsors);
  return people.filter((person) => actualSponsorsIds.indexOf(person.id) === -1);
};

const isActualSponsor = (sponsor) => sponsor.to_date === null;

const mapStateToProps = (state) => ({
  sponsors: state.sponsors.filter(isActualSponsor),
  people: getNonSponsorPeople(state.teammates, state.sponsors),
  waiting: state.waiting,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
  addSponsor: (sponsor, employeeId) => dispatch(actions.addSponsor(sponsor, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
