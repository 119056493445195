const React = require('react');
const Edit = require('./BoardHeaderEdit.react');
const View = require('./BoardHeaderView.react');

module.exports = class BoardHeader extends React.Component {
  getAvailableBoardTypes() {
    const types = ['spreadsheet'];
    const { options } = this.props;
    if (options) {
      if (options.kanban.status) {
        types.push('kanban');
      }
    }
    types.push('gantt');
    return types.join();
  }

  render() {
    if (this.props.canEdit) {
      return (
        <Edit
          name={this.props.name}
          isDeletingBoard={this.props.isDeletingBoard}
          boardType={this.props.boardType}
          changeBoardType={this.props.changeType}
          availableBoardTypes={this.getAvailableBoardTypes()}
          waitingForOptions={this.props.options === null}
          showMissingStatusModal={this.props.showMissingStatusModal}
          editName={this.props.editName}
          type={this.props.type}
          structure={this.props.structure}
          updateKanbanOptions={this.props.updateKanbanOptions}
          options={this.props.options ? this.props.options : null}
          onDeleteClick={this.props.onDeleteClick}
          boardLink={this.props.boardLink} />
      );
    }
    return (
      <View
        name={this.props.name}
        boardType={this.props.boardType}
        changeBoardType={this.props.changeType}
        availableBoardTypes={this.getAvailableBoardTypes()}
        waitingForOptions={this.props.options === null} />
    );
  }
};
