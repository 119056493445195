const React = require('react');
const MenuItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

const MoreOptionsMenuItem = ({ children, onClick, show = true }) => (
  <ShowIf condition={show}>
    <MenuItem onClick={onClick}>
      {children}
    </MenuItem>
  </ShowIf>
);

module.exports = MoreOptionsMenuItem;
