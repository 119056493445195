const React = require('react');
const Select = require('../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');

const HoursSelect = ({ readOnly, name, date, label, id, value, onChange, error, updateErrors }) => {
  function handleChange(e) {
    const updatedValue = e.target.value;

    const formattedOption = { hours: updatedValue, date };

    onChange('days', formattedOption);
  }

  const options = Array(8).fill('')
    .map((item, index) => {
      const hours = index + 1;
      return (<SelectItem key={hours} value={hours}>{hours}H</SelectItem>);
    });

  return (
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <Select readOnly={readOnly}
        value={value}
        label={label}
        id={id}
        name={name}
        onChange={handleChange}
        errorText={error}>
        {options}
      </Select>
    </InputValidator>
  );
};

module.exports = HoursSelect;
