const React = require('react');
const PropTypes = require('prop-types');
const UploadButton = require('../widgets/Files/components/UploadButton.react');
const Loading = require('../widgets/Files/components/SmallLoader.react');
const ShowIf = require('../ShowIf/ShowIf.react');
const File = require('./File.react');
const Empty = require('./Empty.react');

require('./style.scss');

const FileSection = ({
  files, canDownload, canUpload, canDelete, onDownload, onDelete, onUpload, onUploadError,
  isUploading, waitingForFiles, className, title, accept, maxSize, empty,
}) => {
  const style = className ? `wethod-file-section ${className}` : 'wethod-file-section';

  function handleUpload(file) {
    if (onUpload) {
      onUpload(file);
    }
  }

  function isWaiting(fileId) {
    return waitingForFiles.filter((key) => key.toString() === fileId.toString()).length > 0;
  }

  function getUploadButton() {
    if (isUploading) {
      return <Loading />;
    }
    return (
      <UploadButton onUpload={handleUpload}
        onUploadError={onUploadError}
        accept={accept}
        maxSize={maxSize} />
    );
  }

  function getFileList() {
    if (!files || !files.length) {
      return empty;
    }
    return files.map((file) => (
      <File key={file.id}
        file={file}
        canDownload={canDownload}
        canDelete={canDelete}
        onDelete={onDelete}
        onDownload={onDownload}
        isWaiting={isWaiting(file.id)} />
    ));
  }

  return (
    <div className={style}>
      <h3 className="wethod-sidebar__subtitle">
        {title}
        <ShowIf condition={canUpload}>
          {getUploadButton()}
        </ShowIf>
      </h3>
      {getFileList()}
    </div>
  );
};

// list of accepted file extensions
const accepted = [
  'image/jpeg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/tiff',
  'image/x-icon',
  'image/svg+xml',
  'video/x-ms-asf',
  'video/x-ms-asf',
  'video/x-ms-wmv',
  'video/x-ms-wmx',
  'video/x-ms-wm',
  'video/avi',
  'video/divx',
  'video/x-flv',
  'video/quicktime',
  'video/quicktime',
  'video/mpeg',
  'video/mpeg',
  'video/mpeg',
  'video/mp4',
  'video/mp4',
  'video/ogg',
  'video/webm',
  'video/x-matroska',
  'video/3gpp',
  'video/3gpp',
  'video/3gpp2',
  'video/3gpp2',
  'text/plain',
  'text/csv',
  'text/tab-separated-values',
  'text/calendar',
  'text/richtext',
  'text/css',
  'text/html',
  'text/html',
  'text/vtt',
  'application/ttaf+xml',
  'audio/mpeg',
  'audio/mpeg',
  'audio/mpeg',
  'audio/x-realaudio',
  'audio/x-realaudio',
  'audio/wav',
  'audio/ogg',
  'audio/ogg',
  'audio/midi',
  'audio/midi',
  'audio/x-ms-wma',
  'audio/x-ms-wax',
  'audio/x-matroska',
  'application/rtf',
  'application/javascript',
  'application/pdf',
  'application/x-shockwave-flash',
  'application/java',
  'application/x-tar',
  'application/zip',
  'application/x-gzip',
  'application/x-gzip',
  'application/rar',
  'application/x-7z-compressed',
  'application/octet-stream',
  'application/octet-stream',
  'application/postscript',
  'application/x-indesign',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-write',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-access',
  'application/vnd.ms-project',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.slide',
  'application/vnd.ms-powerpoint.slide.macroEnabled.12',
  'application/onenote',
  'application/onenote',
  'application/onenote',
  'application/onenote',
  'application/oxps',
  'application/vnd.ms-xpsdocument',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.graphics',
  'application/vnd.oasis.opendocument.chart',
  'application/vnd.oasis.opendocument.database',
  'application/vnd.oasis.opendocument.formula',
  'application/wordperfect',
  'application/wordperfect',
  'application/vnd.apple.keynote',
  'application/vnd.apple.numbers',
  'application/vnd.apple.pages',
];

FileSection.defaultProps = {
  files: [],
  canDownload: false,
  canUpload: false,
  canDelete: false,
  onDownload: null,
  onDelete: null,
  onUpload: null,
  onUploadError: null,
  isUploading: false,
  waitingForFiles: [],
  className: null,
  title: 'Documents',
  accept: accepted,
  maxSize: 128000000,
  empty: <Empty />,
};

FileSection.propTypes = {
  /**
   * List of files.
   * @see File
   */
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  /**
   * Permission to upload: when this is false, the upload button is not shown.
   */
  canUpload: PropTypes.bool,
  /**
   * Permission to download: when this is false, the download button of each file is not shown.
   * @see File
   */
  canDownload: PropTypes.bool,
  /**
   * Permission to delete: when this is false, the delete button of each file is not shown.
   * @see File
   */
  canDelete: PropTypes.bool,
  /**
   * The function to call to download a file.
   * @param file {Object}
   *
   * @see File
   */
  onDownload: PropTypes.func,
  /**
   * The function to call to delete a file.
   * @param file {Object}
   *
   * @see File
   */
  onDelete: PropTypes.func,
  /**
   * The function to call in order to upload a file.
   * @param file {Object}
   *
   * @see UploadButton
   */
  onUpload: PropTypes.func,
  /**
   * The function to call when upload fails.
   * @param errors {Array}
   *
   * @see UploadButton
   */
  onUploadError: PropTypes.func,
  /**
   * A loader replace the upload button.
   */
  isUploading: PropTypes.bool,
  /**
   * List of files which are waiting.
   * Each file in this list shows a loader, hiding the delete action.
   * @see File
   */
  waitingForFiles: PropTypes.arrayOf(PropTypes.number),
  /**
   * Class given to the section.
   */
  className: PropTypes.string,
  /**
   * Text shown as title of the section.
   */
  title: PropTypes.string,
  /**
   * One or more unique file type specifiers describing file types to allow.
   * @see UploadButton
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * Max allowed size, in byte.
   * @see UploadButton
   */
  maxSize: PropTypes.number,
  /**
   * Element shown when the list is empty.
   */
  empty: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
};

module.exports = FileSection;
