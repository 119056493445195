const React = require('react');
const PercentageCell = require('../Cell/PercentageCell.react');
const NumericField = require('../../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const Delta = require('../NumericDelta.react');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

const TaskMarkup = ({ children, save, editable, previousValue, id }) => {
  function onChange(e) {
    let numericValue = parseFloat(e.target.value);
    numericValue = !Number.isNaN(numericValue) ? numericValue : 0;

    save(numericValue);
  }

  return (
    <PercentageCell>
      <Delta actualValue={children} previousValue={previousValue} />
      <CollaborativeInput id={id}>
        <NumericField onChange={onChange} value={children} readOnly={!editable} hideZero />
      </CollaborativeInput>
    </PercentageCell>
  );
};

module.exports = TaskMarkup;
