const { connect } = require('react-redux');
const Component = require('../components/BudgetChangelogList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  logs: state.logs,
  isWaiting: state.waitingFor.filter((key) => key === 'changelog').length > 0,
  size: state.size,
  keyword: state.keyword,
  sortBy: state.sortBy,
  hasMore: !state.lastPage,
});

const mapDispatchToProps = (dispatch) => ({
  showNoteModal: (message) => dispatch(actions.showModal('notes', { message })),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
