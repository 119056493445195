/**
 * @abstract
 * @type {StructureNode}
 */
module.exports = class StructureNode {
  constructor() {
    this.total = null;
    this.previous = null;
  }

  /**
   * Return node total.
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  getTotal() {
    throw new Error('Each StructureNode must implement "getTotal()"');
  }

  /**
   * Return node last approved version amounts.
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  getPrevious() {
    throw new Error('Each StructureNode must implement "getPrevious()"');
  }

  /**
   * Return node JSON representation.
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  toJson() {
    throw new Error('Each StructureNode must implement "toJson()"');
  }

  /**
   * Return node total: an object containing all total values for this node.
   * @private
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  calculateTotal() {
    throw new Error('Each StructureNode must implement "calculateTotal()"');
  }

  /**
   * Return an object containing node last approved version amounts
   * @abstract
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  calculatePrevious() {
    throw new Error('Each StructureNode must implement "calculatePrevious()"');
  }
};
