/* eslint-disable react/sort-comp */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Radio = require('../../../../../../../common/react/inputs/RadioGroup/Radio.react');
const RadioGroup = require('../../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');
const DateRange = require('../../../../../../../common/react/material-input/SidebarDateRangeDoubleSelection.react');

const TYPE_ALL = 'all';
const TYPE_SEGMENT = 'segment';

module.exports = class ExportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      type: TYPE_ALL,
    };
  }

  handleConfirm() {
    if (!this.props.isWaiting) {
      if (this.state.type === TYPE_ALL) {
        this.props.export(null, null);
      } else {
        this.props.export(this.state.start_date, this.state.end_date);
      }
    }
  }

  getFeedback() {
    if (this.props.isWaiting) return <span className="wethod-modal2__feedback orders__export-modal--feedback">Sending...</span>;
    return '';
  }

  getActionsStyle() {
    let style = 'profile-contact-info__actions';
    if (this.props.isWaiting) style += ' disabled';
    return style;
  }

  onRadioChange(e) {
    this.setState({ type: e.target.value });
  }

  onCalendarChange(name, value) {
    const startDate = value ? moment(value.start_date).format('YYYY-MM-DD') : null;
    const endDate = value ? moment(value.end_date).format('YYYY-MM-DD') : null;
    this.setState({ start_date: startDate, end_date: endDate });
  }

  getCalendar() {
    return (
      <div className="orders__export-modal--date-select">
        <DateRange name="range_date"
          placeholder={{ start: 'From', end: 'To' }}
          value={{ start_date: this.state.start_date, end_date: this.state.end_date }}
          onChange={this.onCalendarChange.bind(this)}
          required>
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        </DateRange>
      </div>
    );
  }

  getBody() {
    return (
      <div className="orders__export-modal--body">
        <RadioGroup name="export_radio" onChange={this.onRadioChange.bind(this)} value={this.state.type}>
          <Radio label="Export all orders" value={TYPE_ALL} />
          <Radio label="Export data segment" value={TYPE_SEGMENT} />
        </RadioGroup>
        {this.state.type === TYPE_SEGMENT && this.getCalendar()}
      </div>
    );
  }

  render() {
    return (
      <Modal title="Export data" onCancelClick={this.props.onCancelClick} className="orders__export-modal">
        {this.getBody()}
        <div className={this.getActionsStyle()}>
          <button type="button" onClick={this.props.onCancelClick} className="orders__cancel-button">Cancel</button>
          <button type="button" onClick={this.handleConfirm.bind(this)} className="orders__confirm-button">Email to me</button>
        </div>
        {this.getFeedback()}
      </Modal>
    );
  }
};
