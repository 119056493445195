const React = require('react');
const _ = require('underscore');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');

const NameCell = ({ name, handleNameChange }) => (
  <TableCell className="company-settings-work-hour-capacity__table-cell-name">
    <TextField name="name"
      label="Name"
      value={_.unescape(name)}
      onBlur={handleNameChange} />
  </TableCell>
);

module.exports = NameCell;
