module.exports.GET_TIME_OFF_REQUEST = 'GET_TIME_OFF_REQUEST';
module.exports.GET_TIME_OFF_SUCCESS = 'GET_TIME_OFF_SUCCESS';

module.exports.SAVE_TIME_OFF_REQUEST = 'SAVE_TIME_OFF_REQUEST';
module.exports.SAVE_TIME_OFF_SUCCESS = 'SAVE_TIME_OFF_SUCCESS';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.EXPORT_REQUEST = 'EXPORT_REQUEST';
module.exports.EXPORT_SUCCESS = 'EXPORT_SUCCESS';

module.exports.IMPORT_REQUEST = 'IMPORT_REQUEST';
module.exports.IMPORT_SUCCESS = 'IMPORT_SUCCESS';
module.exports.IMPORT_ERROR = 'IMPORT_ERROR';
