const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Gantt/Gantt.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit,
  isWaiting: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  editItemValues: (boardId, itemId, itemValues) => dispatch(actions.editBoardItemValues(boardId,
    itemId, itemValues)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
