/* eslint-disable default-case */
const React = require('react');

module.exports = class OkrQuarter extends React.Component {
  getClassName() {
    const status = this.props.quarter_status;
    let name = 'employee__okr-quarter';
    switch (status) {
      case 0:
        return name += ' employee__okr-quarter--draft';
      case 1:
        return name += ' employee__okr-quarter--discussed';
      case 2:
        return name += ' employee__okr-quarter--checked';
    }
    return name;
  }

  render() {
    return (
      <span className={this.getClassName()} />
    );
  }
};
