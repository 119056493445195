const { connect } = require('react-redux');
const Component = require('../../../components/Header/ProjectInfo/ProjectInfo.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  name: state.project.name,
  client: state.project.client.corporate_name,
  jobOrder: state.project.job_order,
  pm: state.project.pm.person,
  account: state.project.account ? state.project.account.person : null,
  start: state.project.date_start,
  duration: state.project.duration,
});

const mapDispatchToProps = (dispatch) => ({
  showProjectSidebar: () => dispatch(actions.showSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
