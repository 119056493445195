'use strict';

/* eslint-disable default-case */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  this.showProjectInvoicePlan = function (projectID) {
    var options = { search: 'id:' + projectID };
    Wethod.trigger('url:pipeline:show:filter', options);
  };

  this.doInvoice = function (invoiceData) {
    dispatcher.trigger('url:invoice:show', invoiceData);
  };

  /**
   * [controllo se ho i permessi di creazione di una fattura]
   * @param  {[type]} projectID       [description]
   * @param  {[type]} projectJobOrder [description]
   * @return {[type]}                 [description]
   */
  this.getInvoicePermissions = function (projectID, pmID, projectJobOrder) {
    var isPm = function (id) {
      if (parseInt(id) === parseInt(Wethod.userInfo.get('employee_id'))) {
        return true;
      }
      return false;
    };

    if (!_.isNull(projectJobOrder)) {
      if (
        (DashboardApp.invoicePermission.get('create') && isPm(pmID))
        || (DashboardApp.invoicePermission.get('create_other') && !isPm(pmID))
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  /**
   * [function description]
   * @param  {[type]} invoiceData [description]
   * @param  {[type]} action      [description]
   * @return {[type]}             [description]
   */
  this.modifyInvoiceList = function (parentView, invoiceData, action) {
    switch (action) {
      case 'add':
        parentView.options.invoicesList.push(invoiceData);
        break;
      case 'remove':
        var index;

        for (var i = 0; i < parentView.options.invoicesList.length; i++) {
          if (parentView.options.invoicesList[i].cid === invoiceData.cid) {
            index = i;
          }
        }
        parentView.options.invoicesList.splice(index, 1);

        break;
    }
    if (parentView.options.invoicesList.length > 0) {
      parentView.ui.footerEl.addClass('isVisible');
    } else {
      parentView.ui.footerEl.removeClass('isVisible');
    }
  };

  this.saveInvoice = function (viewObj, data) {
    var options = {
      id: viewObj.model.get('id'),
      filterType: data.filterType,
      filterValue: data.filterValue || null,
    };

    // allineo il modello
    viewObj.model.get('config').active_filter.name = data.filterType;
    viewObj.model.get('config').active_filter.value = data.filterValue;

    viewObj.ui.deleteEl.hide();

    var saveWidgetRequest = Wethod.request('save:user:invoice:widget', options);
    $.when(saveWidgetRequest).done(function () {
      var renderOptions = { endpoint: DashboardApp.getEndpoint(viewObj.model) };

      var userWidgetRequest = Wethod.request('get:user:widget', renderOptions);
      $.when(userWidgetRequest).done(function (userWidgetResponse) {
        viewObj.model.set({
          label: userWidgetResponse.get('label'),
        });

        var invoicesCollection = new DashboardApp.WidgetInvoiceCollection(_.map(userWidgetResponse.get('invoices')));

        viewObj.collection = invoicesCollection;
        viewObj.options.new = false;
        viewObj.render();

        viewObj.ui.settingsRegionEl.removeClass('isVisible');
      });
    });
  };

  this.deleteinvoice = function (viewObj) {
    var options = {
      id: viewObj.model.get('id'),
    };

    viewObj.ui.saveEl.hide();
    var deleteWidgetRequest = Wethod.request('delete:user:widget', options);
    $.when(deleteWidgetRequest).done(function () {
      viewObj.options.widgetView.destroy();
    });
  };
});
