const React = require('react');

module.exports = class LifecycleLog extends React.Component {
  constructor(props) {
    super(props);

    this.messages = {
      project_created: 'Project added to pipeline',
      project_started: 'Project started (first timesheet done)',
      project_ended: 'Project finished (according to project status)',
    };
  }

  getMessage() {
    return this.messages[this.props.type];
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    if (this.props.type === 'project_started' || this.props.type === 'project_ended') return `${date}`;
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.created_by;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  render() {
    return (
      <div className="timeline-event-log">
        <div className="timeline-event-past__type-logo-container">
          <div className="wethod-icon-pipeline wethod-icon-pipeline--black" />
        </div>
        <div className="timeline-event-log__type">
          <div className="timeline-event-log__type-name">{this.getMessage()}</div>
        </div>
        <div className="timeline-event-log__creation">
          {this.getCreation()}
        </div>
      </div>
    );
  }
};
