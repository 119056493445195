const { connect } = require('react-redux');
const Component = require('../components/TableHead.react');

const mapStateToProps = (state) => ({
  visibleMonths: state.visibleMonths,
  year: state.year,
  updatingVisibleMonths: state.updatingVisibleMonths,
});

module.exports = connect(mapStateToProps, null)(Component);
