/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this,
 react/no-access-state-in-setstate,react/require-default-props,react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const List = require('./List.react');

class FilterSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showList: false };
  }

  onButtonClicked() {
    this.setState({ showList: !this.state.showList });
  }

  onItemClicked(item) {
    this.setState({ showList: false });
    this.props.onFilterChosen(item);
  }

  getList() {
    if (this.state.showList) {
      return (
        <List title={this.props.title}
          items={this.props.items}
          onFilterChosen={this.onItemClicked.bind(this)} />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-filter-select">
        <button type="button"
          className="wethod-button wethod-filter-select__button"
          onClick={this.onButtonClicked.bind(this)}>
          {this.props.current}
        </button>
        {this.getList()}
      </div>
    );
  }
}

FilterSelect.propTypes = {
  /**
   * Function to call when clicking on an item of the list.
   *
   * @param item {Object} the chosen item.
   */
  onFilterChosen: PropTypes.func,
  /**
   * String to display as title of the item list.
   */
  title: PropTypes.string,
  items: PropTypes.array,
  /**
   * String to show as current selected item.
   */
  current: PropTypes.string.isRequired,
};

module.exports = FilterSelect;
