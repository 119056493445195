const request = require('./Request');

const BudgetProduct = {
  /**
   * Insert one task for each product id.
   * Create one day per task.
   *
   * @param {number} areaId
   * @param {{id:number, sort: number}[]} products
   * @return {*}
   */
  insert(areaId, products) {
    return request({
      method: 'post',
      url: `/br/budget-area/${areaId}/budget-product`,
      data: products,
    });
  },
};

module.exports = BudgetProduct;
