const React = require('react');

const DeleteCompanyModalBody = ({ client }) => (
  <div>
    <p>
      You are about to delete
      {' '}
      <span className="bold">{client.name}</span>
      ,
      are you sure you want to continue?
    </p>
  </div>
);

module.exports = DeleteCompanyModalBody;
