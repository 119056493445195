/* eslint-disable no-underscore-dangle */
const { connect } = require('react-redux');
const Component = require('../components/PermissionManager.react');
const Reducer = require('../reducer');

const mapStateToProps = (state) => ({
  editBudgetPermission: state.permissions.edit,
  editOtherBudgetPermission: state.permissions.edit_other,
  approveBudgetAsPmPermission: state.permissions.approve_as_pm,
  approveBudgetAsAccountPermission: state.permissions.approve_as_account,
  approveOtherBudgetPermission: state.permissions.approve_other,
  employeeId: state.user.employee_id,
  projectManagerId: state.project ? state.project.pm.id : null,
  accountId: state.project && state.project.account ? state.project.account.id : null,
  budgetStatus: state.info ? state.info.status : null,
  isSaving: Reducer.waitingFor(state, 'save-budget-info'),
  createBudgetPermission: state.permissions.create,
  createOtherBudgetPermission: state.permissions.create_other,
  viewBudgetPermission: state.permissions.view,
  viewOtherBudgetPermission: state.permissions.view_other,
  editProjectStatusPermission: state.projectStatusPermissions.edit,
  editOtherProjectStatusPermission: state.projectStatusPermissions.edit_other,
  editBudgetPricelist: state.permissions.edit_pricelist,
  editBaseline: state.permissions.edit_baseline,
  projectMetadata: state.project?._fields,
});

module.exports = connect(mapStateToProps)(Component);
