const { connect } = require('react-redux');
const Component = require('../components/Table.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  items: state.items,
  hasMore: state.hasMorePages,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  ownerFilter: state.ownerFilter,
  searchFilter: state.searchFilter,
  isWaiting: state.waitingFor.filter((key) => key === 'get-items').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (offset, limit, orderBy, sort, ownerFilter, searchFilter) => dispatch(actions
    .getItems(offset, limit, orderBy, sort, ownerFilter, searchFilter)),
  showSidebar: (item) => dispatch(actions.showSidebar(item)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
