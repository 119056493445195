const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../../../ShowIf/ShowIf.react');
const TabSwitcher = require('../../../TabSwitcher.react');

const FilesHeader = ({ title, action, sources, currentSource, onSourceChange }) => (
  <div className="wethod-widget__header wethod-widget-files__header">
    <div className="wethod-widget-files__header--left">
      <div className="wethod-widget__title" style={{ marginRight: 32 }}>{title}</div>
      <ShowIf condition={sources.length > 1}>
        <TabSwitcher current={currentSource} items={sources} handleClick={onSourceChange} />
      </ShowIf>
    </div>
    {action}
  </div>
);

FilesHeader.defaultProps = {
  title: 'Files',
  sources: [],
  action: null,
  currentSource: null,
  onSourceChange: null,
};

FilesHeader.propTypes = {
  title: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  action: PropTypes.node,
  currentSource: PropTypes.string,
  /**
   * Called when user select a different source.
   * @param key {string}
   */
  onSourceChange: PropTypes.func,
};

module.exports = FilesHeader;
