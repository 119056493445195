const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./RoundedButton.react');

const WarningButton = ({ className, children, ...rest }) => {
  const actualClassName = `wethod-button--warning ${className}`;

  return (
    <Button className={actualClassName} {...rest}>{children}</Button>
  );
};

WarningButton.defaultProps = {
  className: '',
};

WarningButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

module.exports = WarningButton;
