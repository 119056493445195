const { connect } = require('react-redux');
const Opportunities = require('../components/Opportunities.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  filteredItems: state.filteredItems,
  isLoading: state.isLoading,
  hasMore: !state.lastPage,
  keyword: state.keyword,
});

const mapDispatchToProps = (dispatch) => ({
  loadMore: (page, size, keyword) => dispatch(actions.loadMoreOpportunities(page, size, keyword)),
  filter: (keyword, size) => dispatch(actions.filterOpportunities(keyword, size)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Opportunities);
