/* eslint-disable camelcase,jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,
 react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');

const ClientIntercompanyActivity = ({
  accepted, project, sent, supplier_company, task,
}) => {
  const getUnescapedContent = (content) => _.unescape(content);

  const getPrintableValue = (value, attribute) => (value
    ? getUnescapedContent(value[attribute]) : null);

  const getSentLabel = () => (supplier_company ? `Supplied by ${supplier_company.name}` : null);

  const getStatus = () => {
    if (!sent) {
      return (
        <span className="widget-intercompany__status widget-intercompany__status--not-sent">Not sent</span>
      );
    }
    if (!accepted) {
      return (
        <span className="widget-intercompany__status widget-intercompany__status--pending">Pending</span>
      );
    }

    return (
      <span className="widget-intercompany__status widget-intercompany__status--accepted">Accepted</span>
    );
  };

  return (
    <div className="widget-intercompany__item">
      <div className="widget-intercompany__item--sliding">
        <div className="widget-intercompany__item-content">
          <span className="wethod-icon wethod-icon-outcoming wethod-icon-outcoming--black" />
          <span className="widget-intercompany__project">
            <span>{getPrintableValue(task, 'name')}</span>
            <span>{getPrintableValue(project, 'name')}</span>
            <span>{getSentLabel()}</span>
          </span>
          {getStatus()}
        </div>
        <div className="widget-intercompany__actions">
          <a href={`#pipeline/budget/${project.id}`}
            className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--white" />
        </div>
      </div>
    </div>
  );
};

ClientIntercompanyActivity.propTypes = {
  /**
   * Flag to check if the request has been accepted by the supplier
   */
  accepted: PropTypes.bool.isRequired,

  /**
   * Project of the intercompany task
   */
  project: PropTypes.object.isRequired,

  /**
   * Employee of the supplier company that received the request
   */
  recipient: PropTypes.object,

  /**
   * Flag to check if the request has been sent to a supplier
   */
  sent: PropTypes.bool.isRequired,

  /**
   * Supplier company that received the request
   */
  supplier_company: PropTypes.object,

  /**
   * Budget task intercompany
   */
  task: PropTypes.object.isRequired,
};

module.exports = ClientIntercompanyActivity;
