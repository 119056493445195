const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(options) {
    const filter = options.filter ? `?filter=${options.filter}` : '';
    const value = options.value ? `&value=${options.value}` : '';
    const from = options.from ? `&from=${options.from}` : '';
    const to = options.to ? `&to=${options.to}` : '';

    this.url = `${APIURL}/dashboard/user-widget/invoices${filter}${value}${from}${to}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.get('invoices'));
    });

    return defer.promise();
  },
});
