const DaysCalendar = require('./DaysCalendar');
const WeeksCalendar = require('./WeeksCalendar');
const MonthsCalendar = require('./MonthsCalendar');
const YearsCalendar = require('./YearsCalendar');

/**
 *
 * @param type
 * @return {CalendarModel}
 * @constructor
 */
const CalendarFactory = (type) => {
  switch (type) {
    case 'weeks': {
      const from = moment().subtract(4, 'weeks');
      const to = moment().add(7, 'weeks');
      return new WeeksCalendar(from, to);
    }
    case 'months': {
      const from = moment().subtract(4, 'months');
      const to = moment().add(7, 'months');
      return new MonthsCalendar(from, to);
    }
    case 'years': {
      const from = moment().subtract(4, 'years');
      const to = moment().add(7, 'years');
      return new YearsCalendar(from, to);
    }
    default: {
      const from = moment().subtract(10, 'days');
      const to = moment().add(30, 'days');
      return new DaysCalendar(from, to);
    }
  }
};

module.exports = CalendarFactory;
