/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class EmptyItem extends React.Component {
  render() {
    return (
      <li className="wethod-search-select__item wethod-search-select__item--empty">
        No results
      </li>
    );
  }
};
