const React = require('react');
const Input = require('../../../../../../common/react/NumericInput.react');

module.exports = class DaysLeftInput extends React.Component {
  onChange(name, value) {
    this.props.onChange(name, value);
  }

  /**
   * Formatted label of last available progress.
   * Only visible on mobile.
   * @returns {JSX.Element}
   */
  getLastProgressLabel() {
    const lastWeek = `Last was: ${this.props.lastWeekValue}`;

    let style = '';
    if (this.props.negativeProgress) {
      style += 'project-status__feedback project-status__feedback--warning';
    } else {
      style += 'project-status__last-week';
    }

    if (!this.props.desktop) {
      style += ' project-status__feedback--desktop-hidden';
    }

    return <div className={style}>{lastWeek}</div>;
  }

  getInfo() {
    if (this.props.feedback && this.props.feedback !== '') {
      return <div className="project-status__feedback">{this.props.feedback}</div>;
    }

    return this.getLastProgressLabel();
  }

  render() {
    return (
      <div className="project-status__days-left" data-testid="project-days-left-input">
        <Input
          name="days-left"
          value={this.props.value}
          formatOptions={{ numeralDecimalScale: 2, stripLeadingZeroes: false }}
          onBlur={this.props.onBlur}
          onChange={this.onChange.bind(this)}
          disabled={this.props.disabled} />
        {this.getInfo()}
      </div>
    );
  }
};
