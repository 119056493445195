const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Task/PriceList/PriceList.react');
const actions = require('../../../actions');

const mapDispatchToProps = (dispatch) => ({
  showPricelistModal:
    (areaId, currentPricelistId) => dispatch(
      actions.showModalPricelist(areaId, currentPricelistId),
    ),
});

module.exports = connect(null, mapDispatchToProps)(Component);
