const React = require('react');
const PropTypes = require('prop-types');
const FileModel = require('../models/File');
const PathModel = require('../models/Path');
const UploadButton = require('./UploadButton.react');
const SyncButton = require('./SyncButton.react');
const SearchBar = require('./SearchBar.react');
const Breadcrumb = require('./Breadcrumb.react');
const FileList = require('./FileList.react');
const ShowIf = require('../../../ShowIf/ShowIf.react');
const MissingProjectFolderMessage = require('./MissingProjectFolderMessage.react');
const MissingRootFolderMessage = require('./MissingRootFolderMessage.react');
const SmallLoader = require('./SmallLoader.react');
const ExtenralDrive = require('../models/ExternalDrive');

/**
 * Component used to manage files coming from external storages (like Google Drive or Dropbox).
 */
class FileManagerExternalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      path: this.props.path,
    };

    this.onUpload = this.onUpload.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onFolderClick = this.onFolderClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    this.props.setHeaderAction(this.getAction());
  }

  componentDidUpdate(prevProps, prevState) {
    const syncedFolder = !prevProps.externalDrive.hasProjectFolder()
      && this.props.externalDrive.hasProjectFolder();
    const changedPath = prevState.path.length() !== this.state.path.length();
    const changeUploading = prevProps.isUploading !== this.props.isUploading;
    const changeSyncing = prevProps.isSyncing !== this.props.isSyncing;
    if (syncedFolder) {
      this.updatePath(this.props.externalDrive.projectFolder);
    } else if (changeUploading || changeSyncing) {
      this.props.setHeaderAction(this.getAction());
    } else if (changedPath) {
      this.loadMore();
    }
  }

  onUpload(file) {
    this.props.onUpload(file, this.getCurrentFolderId());
  }

  onBackClick() {
    if (this.state.path.length() > 1) {
      this.setState((prevState) => ({
        path: prevState.path.pop(),
      }));
    }
  }

  /**
   *
   * @param {FileModel} folder
   */
  onFolderClick(folder) {
    const pathComponent = folder.toPathComponent();
    this.setState((prevState) => ({
      path: prevState.path.add(pathComponent),
    }));
  }

  onSearch(keyword) {
    this.props.onSearch(keyword, this.getCurrentFolderId());
  }

  getCurrentFolderId() {
    return this.state.path.getLast().id;
  }

  getAction() {
    if (this.props.isUploading || this.props.isSyncing) {
      return <SmallLoader />;
    }
    if (this.props.canEdit && !this.props.externalDrive.error) {
      if (!this.state.path.isEmpty()) {
        return <UploadButton onUpload={this.onUpload} />;
      }
      return <SyncButton onClick={this.props.onSync} />;
    }
    return null;
  }

  getErrorContent() {
    if (this.props.externalDrive.error) {
      return <MissingRootFolderMessage />;
    }
    if (this.state.path.isEmpty()) {
      return <MissingProjectFolderMessage />;
    }
    return null;
  }

  isSearching() {
    return this.props.searchKeyword !== '';
  }

  loadMore(size) {
    this.props.loadMore(size, this.getCurrentFolderId());
  }

  updatePath(pathComponent) {
    this.setState({ path: new PathModel([pathComponent]) },
      () => this.props.setHeaderAction(this.getAction()));
  }

  render() {
    return (
      <div>
        <ShowIf condition={!this.state.path.isEmpty() && !this.props.externalDrive.error}
          showElse={this.getErrorContent()}>
          <div>
            <SearchBar onSearch={this.onSearch}
              path={this.state.path}
              keyword={this.props.searchKeyword}
              onBackClick={this.onBackClick} />
            <FileList hasMore={this.props.hasMore}
              loadMore={this.loadMore}
              externalDrive={this.props.externalDrive}
              isLoading={this.props.isLoadingFiles}
              deletingFiles={this.props.deletingFiles}
              files={this.props.files}
              onDelete={this.props.onDeleteFile}
              onDownload={this.props.onDownloadFile}
              canEdit={this.props.canEdit}
              isSearching={this.isSearching()}
              emptyFilesMessage={this.props.emptyFilesMessage}
              onFolderClick={this.onFolderClick} />
            <Breadcrumb path={this.state.path} />
          </div>
        </ShowIf>
        {this.props.modal}
      </div>
    );
  }
}

FileManagerExternalContent.defaultProps = {
  files: [],
  canEdit: false,
  isLoadingFiles: false,
  searchKeyword: '',
  onDeleteFile: null,
  onDownloadFile: null,
  deletingFiles: [],
  isUploading: false,
  isSyncing: false,
  modal: null,
};

FileManagerExternalContent.propTypes = {
  /** Function to call to change parent header action.
   * @param {PropTypes.node} component
   */
  setHeaderAction: PropTypes.func.isRequired,
  /** Function to call in order to upload a file.
   * @param {Object} file
   * @param {string} parentId
   */
  onUpload: PropTypes.func.isRequired,
  /**
   * The function to call when search keyword is updated.
   * @param keyword {string}
   * @param {string} parentId
   */
  onSearch: PropTypes.func.isRequired,
  /**
   * The function to call to load files with given parent.
   * @param {integer} size
   * @param {string} parentId
   */
  loadMore: PropTypes.func.isRequired,
  /** Function to try syncing current canvas with external drive folder.
   */
  onSync: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileModel)),
  path: PropTypes.instanceOf(PathModel).isRequired,
  canEdit: PropTypes.bool,
  isLoadingFiles: PropTypes.bool,
  /** Id list of files being deleted * */
  deletingFiles: PropTypes.arrayOf(PropTypes.number),
  hasMore: PropTypes.bool.isRequired,
  searchKeyword: PropTypes.string,
  externalDrive: PropTypes.instanceOf(ExtenralDrive).isRequired,
  /**
   * The function to call to delete a file.
   *
   * @param file {Object}
   */
  onDeleteFile: PropTypes.func,
  /**
   * The function to call to download a file.
   *
   * @param file {Object}
   */
  onDownloadFile: PropTypes.func,
  /** Component to show when list is empty after loading * */
  emptyFilesMessage: PropTypes.node.isRequired,
  isUploading: PropTypes.bool,
  isSyncing: PropTypes.bool,
  modal: PropTypes.node,
};

module.exports = FileManagerExternalContent;
