const React = require('react');
const $ = require('jquery');
const Table = require('../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../common/react/Table2/TableBody.react');
const PricelistSearch = require('../containers/PricelistSearch');
const PricelistRow = require('./PricelistRow.react');
const Loader = require('../../../../../common/react/Loader/Loader.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../common/react/Table2/TableHead.react');

module.exports = class PricelistsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
    this.getLevels();
  }

  componentDidUpdate(prevProps) {
    const changedAvailability = prevProps.available
      !== this.props.available;

    const refreshTable = prevProps.refreshTable === false && this.props.refreshTable === true;

    if (changedAvailability || refreshTable) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      this.getPricelists(options);
    }
  }

  getList() {
    return this.props.pricelists.map((pricelist) => (
      <PricelistRow
        key={pricelist.id}
        id={pricelist.id}
        pricelist={pricelist}
        showSidebar={this.props.showSidebar} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <PricelistSearch filter={this.filter.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.pageOffset,
      limit: this.props.pageLimit,
      search: this.props.search,
      order: this.props.order,
      sort: this.props.sort,
      available: this.props.available,
    };
  }

  getPricelists(options) {
    if (!this.props.isWaiting) {
      this.props.getPricelists(
        options.offset,
        options.limit,
        options.search,
        options.order,
        options.sort,
        options.available,
      );
    }
  }

  getSort(order) {
    return this.props.order === order ? this.props.sort : null;
  }

  getLevels() {
    this.props.getLevels();
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = (page - 1) * size;
      options.limit = size;
      options.order = this.props.order;
      options.sort = this.props.sort;

      this.getPricelists(options);
    }
  }

  hasMorePages() {
    return this.props.hasMorePages;
  }

  updateTableHeight() {
    this.setState({ tableHeight: PricelistsTable.getTableMaxHeight() });
  }

  filter(search) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.search = search;

      this.getPricelists(options);
    }
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getPricelists(options);
    }
  }

  render() {
    return (
      <Table columns={8} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="name"
              className="pricelists__column-name"
              onSort={this.sort.bind(this)}
              sort={this.getSort('name')}>
              Name
            </TableCell>
            <TableCell name="description"
              className="pricelists__column-description"
              onSort={this.sort.bind(this)}
              sort={this.getSort('description')}>
              Description
            </TableCell>
            <TableCell name="client"
              className="pricelists__column-client"
              onSort={this.sort.bind(this)}
              sort={this.getSort('client')}>
              Client
            </TableCell>
            <TableCell name="availableFrom"
              className="pricelists__column-available-from"
              onSort={this.sort.bind(this)}
              sort={this.getSort('availableFrom')}>
              Available from
            </TableCell>
            <TableCell name="availableTo"
              className="pricelists__column-available-to"
              onSort={this.sort.bind(this)}
              sort={this.getSort('availableTo')}>
              Available to
            </TableCell>
            <TableCell name="default"
              className="pricelists__column-default-markup"
              onSort={this.sort.bind(this)}
              sort={this.getSort('default')} />
            <TableCell className="pricelists__column-actions" />
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
