/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,default-case,no-nested-ternary */
const React = require('react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');
const ColorReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyColorDotField.react');
const MemberElement = require('./MemberElement.react');
const { formatDate } = require('../../../../../../../services/FormatService');

module.exports = class ItemReadOnlyBody extends React.Component {
  /**
   * Returns the updated value of the status linked to the cell
   * (The status could have changed name/color since it was selected for the cell)
   */
  getStatus(attributeId, value) {
    const statusStructure = this.props.structure
      .find((attributeStructure) => attributeStructure.id === attributeId);
    const selectedStatus = statusStructure.available_values
      .find((status) => status.id === value.id);

    if (!selectedStatus) { // status has been deleted
      return statusStructure.available_values.find((status) => status.default === true);
    }

    return selectedStatus;
  }

  getMembersList(members) {
    if (!members || members.length === 0) {
      return (
        <div className="project-canvas-kanban-sidebar-member__element project-canvas-kanban-sidebar-member__element--empty">No
          members added
        </div>
      );
    }
    return members.map((member) => (
      <MemberElement key={member.id} {...member} />
    ));
  }

  getField(attribute, value) {
    switch (attribute.type) {
      case 'numeric':
        const number = value ? parseFloat(value).toLocaleString('it-IT') : null;
        return (
          <ReadOnlyField key={attribute.id}
            placeholder={attribute.name}
            value={number}><span />
          </ReadOnlyField>
        );
      case 'text':
        return (
          <ReadOnlyField key={attribute.id} placeholder={attribute.name} value={value}>
            <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
          </ReadOnlyField>
        );
      case 'status':
        const updatedStatus = this.getStatus(attribute.id, value);
        return (
          <div key={attribute.id} className="material-input__frame">
            <ColorReadOnlyField placeholder={attribute.name}
              value={updatedStatus.name}
              color={updatedStatus.color}><span />
            </ColorReadOnlyField>
          </div>
        );
      case 'date':
        const formattedDate = value
          ? value.start_date === value.end_date
            ? formatDate(value.start_date) : `${formatDate(value.start_date)} - ${formatDate(value.end_date)}`
          : null;
        return (
          <ReadOnlyField key={attribute.id} placeholder={attribute.name} value={formattedDate}>
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          </ReadOnlyField>
        );
      case 'milestone':
        const date = value ? formatDate(value.date) : null;
        const icon = value
          ? `wethod-icon wethod-icon-${value.type.toLowerCase()} wethod-icon-${value.type.toLowerCase()}--black`
          : '';
        return (
          <div key={attribute.id} className="material-input__frame">
            <ReadOnlyField placeholder={value ? value.type : attribute.name} value={date}>
              <span className={icon} />
            </ReadOnlyField>
          </div>
        );
      case 'member':
        return (
          <div key={attribute.id} className="project-canvas-kanban-sidebar-member">
            <div className="project-canvas-kanban-sidebar-member__header">
              <div className="wethod-icon wethod-icon-pm" />
              <div className="project-canvas-kanban-sidebar-member__header--title">{attribute.name}</div>
            </div>
            {this.getMembersList(value)}
          </div>
        );
    }
  }

  render() {
    return (
      <div>
        <div className="project-canvas-kanban-sidebar-name">{this.props.item.name}</div>
        {this.props.structure.map((attribute, index) => this.getField(attribute,
          this.props.item.attributes[index].value))}
      </div>
    );
  }
};
