module.exports.TYPE_PROJECTS = 'projects';
module.exports.TYPE_OPPORTUNITIES = 'opportunities';

module.exports.GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
module.exports.GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';

module.exports.HIDE_MODAL = 'HIDE_MODAL';
module.exports.SHOW_MODAL_INTERCOMPANY_LINK = 'SHOW_MODAL_INTERCOMPANY_LINK';
module.exports.SHOW_MODAL_INTERCOMPANY_DETAILS = 'SHOW_MODAL_INTERCOMPANY_DETAILS';
module.exports.SHOW_MODAL_SEARCH_SAVE = 'SHOW_MODAL_SEARCH_SAVE';
module.exports.SHOW_MODAL_SEARCH_EXPORT = 'SHOW_MODAL_SEARCH_EXPORT';
module.exports.SHOW_MODAL_SEARCH_SHARE = 'SHOW_MODAL_SEARCH_SHARE';
module.exports.SHOW_MODAL_CONFIRM_DELETE = 'SHOW_MODAL_CONFIRM_DELETE';
module.exports.SHOW_MODAL_DELETE_FAILURE = 'SHOW_MODAL_DELETE_FAILURE';
module.exports.SHOW_MODAL_CONFIRM_ARCHIVE = 'SHOW_MODAL_CONFIRM_ARCHIVE';
module.exports.SHOW_MODAL_ARCHIVE_FAILURE = 'SHOW_MODAL_ARCHIVE_FAILURE';
module.exports.SHOW_MODAL_PROJECT_SHARE = 'SHOW_MODAL_PROJECT_SHARE';
module.exports.SHOW_MODAL_REVIEW_REQUEST = 'SHOW_MODAL_REVIEW_REQUEST';
module.exports.SHOW_MODAL_REVIEW_START = 'SHOW_MODAL_REVIEW_START';
module.exports.SHOW_MODAL_EDIT_FEEDBACK = 'SHOW_MODAL_EDIT_FEEDBACK';
module.exports.SHOW_MODAL_SEGMENT_DELETE = 'SHOW_MODAL_SEGMENT_DELETE';
module.exports.SHOW_MODAL_SAVE_ERROR = 'SHOW_MODAL_SAVE_ERROR';

module.exports.GET_INTERCOMPANY_INVITATION_INFO_REQUEST = 'GET_INTERCOMPANY_INVITATION_INFO_REQUEST';
module.exports.GET_INTERCOMPANY_INVITATION_INFO_SUCCESS = 'GET_INTERCOMPANY_INVITATION_INFO_SUCCESS';
module.exports.GET_INTERCOMPANY_INVITATION_INFO_FAILURE = 'GET_INTERCOMPANY_INVITATION_INFO_FAILURE';

module.exports.CONNECT_INTERCOMPANY_PROJECT_REQUEST = 'CONNECT_INTERCOMPANY_PROJECT_REQUEST';
module.exports.CONNECT_INTERCOMPANY_PROJECT_SUCCESS = 'CONNECT_INTERCOMPANY_PROJECT_SUCCESS';
module.exports.CONNECT_INTERCOMPANY_PROJECT_FAILURE = 'CONNECT_INTERCOMPANY_PROJECT_FAILURE';

module.exports.SHOW_ADVANCED_SEARCH = 'SHOW_ADVANCED_SEARCH';
module.exports.SHOW_SEGMENT_EDIT = 'SHOW_SEGMENT_EDIT';
module.exports.SHOW_SEGMENT_CREATE = 'SHOW_SEGMENT_CREATE';
module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SEARCH_SAVE_REQUEST = 'SEARCH_SAVE_REQUEST';
module.exports.SEARCH_SAVE_SUCCESS = 'SEARCH_SAVE_SUCCESS';

module.exports.SEARCH_EXPORT_REQUEST = 'SEARCH_SAVE_REQUEST';

module.exports.SEARCH_SHARE_REQUEST = 'SEARCH_SHARE_REQUEST';

module.exports.PROJECT_SHARE_REQUEST = 'PROJECT_SHARE_REQUEST';

module.exports.ADD_PROJECT = 'ADD_PROJECT';

module.exports.DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
module.exports.DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
module.exports.DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

module.exports.ARCHIVE_PROJECT_REQUEST = 'ARCHIVE_PROJECT_REQUEST';
module.exports.ARCHIVE_PROJECT_SUCCESS = 'ARCHIVE_PROJECT_SUCCESS';
module.exports.ARCHIVE_PROJECT_FAILURE = 'ARCHIVE_PROJECT_FAILURE';

module.exports.GET_LAST_REVIEW_REQUEST = 'GET_LAST_REVIEW_REQUEST';
module.exports.GET_LAST_REVIEW_SUCCESS = 'GET_LAST_REVIEW_SUCCESS';

module.exports.CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST';
module.exports.CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';

module.exports.GET_REASON_WHY_REQUEST = 'GET_REASON_WHY_REQUEST';
module.exports.GET_REASON_WHY_SUCCESS = 'GET_REASON_WHY_SUCCESS';

module.exports.SAVE_REASON_WHY_REQUEST = 'SAVE_REASON_WHY_REQUEST';
module.exports.SAVE_REASON_WHY_SUCCESS = 'SAVE_REASON_WHY_SUCCESS';

module.exports.SAVE_ITEM_REQUEST = 'SAVE_ITEM_REQUEST';
module.exports.SAVE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
module.exports.SAVE_ITEM_FAILURE = 'SAVE_ITEM_FAILURE';

module.exports.CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST';
module.exports.CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
module.exports.CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE';

module.exports.CREATE_METADATA_REQUEST = 'CREATE_METADATA_REQUEST';
module.exports.CREATE_METADATA_SUCCESS = 'CREATE_METADATA_SUCCESS';

module.exports.UPDATE_METADATA_REQUEST = 'UPDATE_METADATA_REQUEST';
module.exports.UPDATE_METADATA_SUCCESS = 'UPDATE_METADATA_SUCCESS';

module.exports.DELETE_METADATA_REQUEST = 'DELETE_METADATA_REQUEST';
module.exports.DELETE_METADATA_SUCCESS = 'DELETE_METADATA_SUCCESS';

module.exports.UPDATE_SEGMENT_REQUEST = 'UPDATE_SEGMENT_REQUEST';
module.exports.UPDATE_SEGMENT_SUCCESS = 'UPDATE_SEGMENT_SUCCESS';

module.exports.CREATE_SEGMENT_REQUEST = 'CREATE_SEGMENT_REQUEST';
module.exports.CREATE_SEGMENT_SUCCESS = 'CREATE_SEGMENT_SUCCESS';

module.exports.DELETE_SEGMENT_REQUEST = 'DELETE_SEGMENT_REQUEST';
module.exports.DELETE_SEGMENT_SUCCESS = 'DELETE_SEGMENT_SUCCESS';

module.exports.APPLY_SEGMENT = 'APPLY_SEGMENT';

module.exports.APPLY_ADVANCED_SEARCH = 'APPLY_ADVANCED_SEARCH';

module.exports.SHOW_SNACKBAR = 'SHOW_SNACKBAR';
module.exports.CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

module.exports.COLUMN_CONFIGURATION_CHANGE = 'COLUMN_CONFIGURATION_CHANGE';
