const React = require('react');
const PropTypes = require('prop-types');
const PathComponent = require('../models/PathComponent');

const BreadcrumbPlace = ({ place }) => {
  const separator = place.hasChild()
    ? <span className="wethod-icon wethod-icon-medium-forward wethod-icon-medium-forward--black" /> : null;

  if (place.url) {
    return (
      <a href={place.url}
        target="_blank"
        rel="noreferrer"
        className="wethod-widget-files__breadcrumb-place">
        {place.name} {separator}
      </a>
    );
  }
  return <div className="wethod-widget-files__breadcrumb-place">{place.name} {separator}</div>;
};

BreadcrumbPlace.propTypes = {
  place: PropTypes.instanceOf(PathComponent).isRequired,
};

module.exports = BreadcrumbPlace;
