'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $, _) {
  this.ChosenTooltipOptionView = Marionette.ItemView.extend({
    className: 'text',
    tagName: 'span',
    template: _.template('<%-label%>'),
    modelEvents: {
      change: 'render',
    },
    toHtml: function () {
      return this.el.parentElement.outerHTML;
    },
  });
});
