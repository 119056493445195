const { connect } = require('react-redux');
const Component = require('../../components/PersonalApiToken/TokenList.react');
const actions = require('../../actions');
const AccountReducer = require('../../reducer');
const constants = require('../../constants');

const mapStateToProps = (state) => ({
  tokens: state.personalTokens,
  isLoading: AccountReducer.waitingFor(state, constants.PERSONAL_TOKEN_LIST_REQUEST),
});

const mapDispatchToProps = (dispatch) => ({
  getTokens: () => dispatch(actions.listPersonalTokens()),
  getTokenMetadata: () => dispatch(actions.getPersonalTokensMetadata()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
