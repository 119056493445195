'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  /**
     * [ renderizza la lista di hints relativi ai manager ]
     * @param  {[type]} viewObj [description]
     * @param  {[type]} text    [description]
     * @return {[type]}         [description]
     */
  this.renderManagerHints = function (viewObj, text) {
    text = text.replace(' ', ',');
    var params = {
      whereSearch: 'pm',
      whatSearch: (text === '') ? undefined : text,
    };
    // loading
    var searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
    viewObj.ui.managerHintEl.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('projectCharter:get:manager:hints', params);
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        var managerHintsTemplate = new ProjectCharterApp.HintsCollectionView({
          collection: autocompleteResponse,
          childViewOptions: {
            parentView: viewObj,
          },
        });
        viewObj.ui.managerHintEl.html(managerHintsTemplate.render().$el);
      } else {
        // not found
        searchFeedbackTemplate = new ProjectCharterApp.SearchNotFoundTemplateView();
        viewObj.ui.managerHintEl.html(searchFeedbackTemplate.render().$el);
      }
    });
  };
});
