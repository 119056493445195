const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/ExternalCosts.react');

const mapStateToProps = (state) => ({
  costs: state.month_external_cost,
  targetType: state.target_type,
  costsOnProduction: state.month_external_cost_perc, // NULLABLE
  planned: state.external_cost_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
