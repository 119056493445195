/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
const React = require('react');

module.exports = ({
  children, className, noBorder, ...rest
}) => {
  const classNameFromProps = noBorder ? 'wethod-table-light__row wethod-table-light__row--no-border' : 'wethod-table-light__row';

  return (
    <tr className={`${classNameFromProps} ${className}`} {...rest}>{children}</tr>
  );
};
