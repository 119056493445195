const { connect } = require('react-redux');
const Component = require('../../components/PersonalApiToken/Token.react');
const actions = require('../../actions');
const modals = require('../../modals');

const mapDispatchToProps = (dispatch) => ({
  showDeleteModal: (id) => dispatch(actions
    .showModal(modals.PERSONAL_TOKEN_DELETE, { tokenId: id })),
});

module.exports = connect(null, mapDispatchToProps)(Component);
