/* eslint-disable no-shadow */
const { connect } = require('react-redux');
const Component = require('../../components/Trends/TrendDoThePlan/TrendDoThePlan.react');
const actions = require('../../actions');

const getData = (months, filter) => {
  if (filter === 'month') {
    return months.filter((month, index, months) => index > months.length - 4);
  }
  return months;
};

const mapStateToProps = (state) => ({
  employee: state.id,
  title: 'Do the production plan',
  subtitle: "Percentage of production plan's correction accumulation",
  data: getData(state.doThePlanTrend, state.trendsFilter),
});

const mapDispatchToProps = (dispatch) => ({
  getTrend: (employeeId) => dispatch(actions.getDoThePlanTrend(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
