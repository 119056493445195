/* eslint-disable react/prop-types,jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class Item extends React.Component {
  onClick(e) {
    e.preventDefault();
    this.props.onClick({
      id: this.props.id,
      name: this.props.name,
    });
  }

  getDescription() {
    const { description } = this.props;
    if (description) {
      return <span className="wethod-select2__item-description">{description}</span>;
    }
    return null;
  }

  render() {
    return (
      <li className="wethod-select2__item">
        <a href="#"
          onClick={this.onClick.bind(this)}>
          <span className="wethod-select2__item-label">{this.props.name}</span>
          {this.getDescription()}
        </a>
      </li>
    );
  }
};
