'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.AddWidgetModel = Wethod.Utility.BaseModel.extend();
  this.AddWidgetsCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/dashboard/widget',
    model: DashboardApp.AddWidgetModel,
  });

  // widget default
  this.WidgetModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/' + this.options.endpoint;
    },
  });
  this.DashboardCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/dashboard/user-widget',
    model: DashboardApp.WidgetModel,
    comparator: function (model) {
      return model.get('position').sort;
    },
  });

  // autocomplete
  this.UserAutocompleteModel = Wethod.Utility.BaseModel.extend();
  this.UserAutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var url = APIURL + '/dashboard/autocomplete?on=' + this.options.on;
      if (this.options.search !== '') {
        url += '&search=' + this.options.search;
      }
      return url;
    },

    model: DashboardApp.UserAutocompleteModel,
  });

  var API = {
    getUserDashboard: function () {
      var widgetCollection = new DashboardApp.DashboardCollection();
      var defer = $.Deferred();

      widgetCollection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    getWidget: function () {
      var widgetCollection = new DashboardApp.AddWidgetsCollection();
      var defer = $.Deferred();

      widgetCollection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    getUserWidget: function (options) {
      var widgetModel = new DashboardApp.WidgetModel(options);
      var defer = $.Deferred();

      widgetModel.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    saveSalesFunnelWidget: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget/sales-funnel/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        if (response.code === 200 || response.code === 201) {
          defer.resolve(response.data || null);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },

    deleteUserWidget: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },

    getUserAutocomplete: function (options) {
      var autocompleteCollection = new DashboardApp.UserAutocompleteCollection(options);
      var defer = $.Deferred();

      autocompleteCollection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    insertUserWidget: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget',
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        if (response.code === 201) {
          defer.resolve(new DashboardApp.WidgetModel(response.data));
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },

    saveUserWidgetPosition: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/dashboard/user-widget/position',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(options),
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
  };

  // dasboard
  Wethod.reqres.setHandler('get:user:dashboard', function () {
    return API.getUserDashboard();
  });

  Wethod.reqres.setHandler('get:widgets', function () {
    return API.getWidget();
  });

  // widget model
  Wethod.reqres.setHandler('get:user:widget', function (options) {
    return API.getUserWidget(options);
  });

  // save widget
  Wethod.reqres.setHandler('save:user:salesFunnel:widget', function (options) {
    return API.saveSalesFunnelWidget(options);
  });

  // delete widget
  Wethod.reqres.setHandler('delete:user:widget', function (options) {
    return API.deleteUserWidget(options);
  });

  // insert widget
  Wethod.reqres.setHandler('insert:user:widget', function (options) {
    return API.insertUserWidget(options);
  });

  // autocomplete
  Wethod.reqres.setHandler('get:dashboard:autocomplete', function (options) {
    return API.getUserAutocomplete(options);
  });

  // save position
  Wethod.reqres.setHandler('save:user:widget:position', function (options) {
    return API.saveUserWidgetPosition(options);
  });
});
