const React = require('react');

module.exports = class Actions extends React.Component {
  getStyle() {
    return {
      width: `${this.props.width}px`,
      height: `${this.props.height}px`,
      marginTop: `${-this.props.height}px`,
    };
  }

  render() {
    return (
      <div className="row row--nav" style={this.getStyle()}>
        <div className="row__item">{this.props.children}</div>
      </div>
    );
  }
};
