/* eslint-disable consistent-return,class-methods-use-this */
const React = require('react');
const TableCell = require('../../../../../../../../../common/react/Table2/TableCell.react');

module.exports = class DateItemCellView extends React.Component {
  formatInterval(range) {
    if (range && range.start_date && range.end_date) {
      const from = moment(range.start_date);
      const to = moment(range.end_date);
      return !from.isSame(to) ? `${from.format('DD.MM.YY')} - ${to.format('DD.MM.YY')}` : from.format('DD.MM.YYYY');
    }
  }

  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell">{this.formatInterval(this.props.value)}</TableCell>
    );
  }
};
