const React = require('react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');

module.exports = class TextAreaModalBodyView extends React.Component {
  getActions() {
    if (this.props.canEdit) {
      return (
        <div className="project-canvas__text-area-modal-actions">
          <button type="button" onClick={this.props.toggleEditor} className="wethod-button">Edit</button>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="project-canvas__text-area-modal project-canvas__text-area-modal--readonly">
        <div className="project-canvas__text-area-modal-content">
          <div
            className="project-canvas__text-area-modal-content-input project-canvas__text-area-modal-content-input--summary">
            <ReadOnlyField placeholder="Summary" value={this.props.summary} />
          </div>
          <div
            className="project-canvas__text-area-modal-content-input project-canvas__text-area-modal-content-input--description">
            <ReadOnlyField placeholder="Description" value={this.props.description} />
          </div>
        </div>
        {this.getActions()}
      </div>
    );
  }
};
