const React = require('react');
const PropTypes = require('prop-types');
const $ = require('jquery');
const Button = require('../TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('../ShowIf/ShowIf.react');
const LaptopAndAbove = require('../media-queries/LaptopAndAbove.react');

class TableSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: this.props.keyword ? this.props.keyword : '',
    };

    this.timer = 0;
    this.lastKeyword = this.props.keyword ? this.props.keyword : '';
    this.searchRef = null;
  }

  componentDidMount() {
    this.props.updateHeight('search', $(this.searchRef).outerHeight(true));
  }

  handleChange(e) {
    const keyword = e.target.value;

    this.setState({ keyword }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.hasChanged()) {
          this.lastKeyword = this.state.keyword;
          this.props.filter(this.state.keyword);
        }
      }, 500);
    });
  }

  getStyle() {
    let style = 'wethod-table__search';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  hasChanged() {
    return this.state.keyword.trim().toLowerCase() !== this.lastKeyword.trim().toLowerCase();
  }

  isDirt() {
    return this.state.keyword.length !== 0 || this.props.filtersApplied;
  }

  render() {
    return (
      <div className={this.getStyle()}
        ref={(ref) => this.searchRef = ref}
        data-testid="wethod-table-search">
        <div className="wethod-table__search-icon wethod-icon-search wethod-icon-search--black" />
        <input type="text"
          placeholder={this.props.placeholder}
          value={this.state.keyword}
          onChange={this.handleChange.bind(this)} />
        <LaptopAndAbove>
          <ShowIf condition={this.props.onExportClick !== null}>
            <Button label="Export"
              onClick={this.props.onExportClick}
              className="wethod-icon-button--no-margin"
              borderless>
              <span className="wethod-icon-download wethod-icon-download--black" />
            </Button>
          </ShowIf>
        </LaptopAndAbove>
        <LaptopAndAbove>
          <ShowIf condition={this.props.onImportClick !== null}>
            <Button label="Import"
              onClick={this.props.onImportClick}
              className="wethod-icon-button--no-margin"
              borderless>
              <span className="wethod-icon-upload wethod-icon-upload--black" />
            </Button>
          </ShowIf>
        </LaptopAndAbove>
        <LaptopAndAbove>
          <ShowIf condition={this.props.onShareClick !== null}>
            <Button label="Share"
              onClick={this.props.onShareClick}
              className="wethod-icon-button--no-margin"
              disabled={!this.isDirt()}
              borderless>
              <span className="wethod-icon-share wethod-icon-share--black" />
            </Button>
          </ShowIf>
        </LaptopAndAbove>
        <LaptopAndAbove>
          <ShowIf condition={this.props.onSaveClick !== null}>
            <Button label="Save to pinboard"
              onClick={this.props.onSaveClick}
              className="wethod-icon-button--no-margin"
              disabled={!this.isDirt()}
              borderless>
              <span className="wethod-icon-bookmark-empty wethod-icon-bookmark-empty--black" />
            </Button>
          </ShowIf>
        </LaptopAndAbove>
        <ShowIf condition={this.props.onFilterClick !== null}>
          <div className="wethod-table__search-division">
            <Button label="Show filters"
              onClick={this.props.onFilterClick}
              className="wethod-icon-button--no-margin"
              borderless>
              <ShowIf condition={this.props.filtersApplied}
                showElse={
                  <span className="wethod-icon-filter-applied wethod-icon-filter-applied--black" />
                }>
                <span className="wethod-icon-filter wethod-icon-filter--black" />
              </ShowIf>
            </Button>
          </div>
        </ShowIf>
      </div>
    );
  }
}

TableSearch.defaultProps = {
  keyword: '',
  className: '',
  placeholder: '',
  filtersApplied: false,
  onExportClick: null,
  onShareClick: null,
  onSaveClick: null,
  onFilterClick: null,
  onImportClick: null,
};

TableSearch.propTypes = {
  /**
   * The function to call to update search's height in parent.
   *
   * @see Table2
   *
   * @param value {integer}
   */
  updateHeight: PropTypes.func.isRequired,
  /**
   * The function to call when search keyword is updated.
   * @param keyword {string}
   */
  filter: PropTypes.func.isRequired,
  /**
   * Initial value shown as keyword in the search bar.
   */
  keyword: PropTypes.string,
  /**
   * Additional class to be given to the search bar
   */
  className: PropTypes.string,
  placeholder: PropTypes.string,
  filtersApplied: PropTypes.bool,
  onExportClick: PropTypes.func,
  onShareClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  onImportClick: PropTypes.func,
};

module.exports = TableSearch;
