const React = require('react');
const Track = require('../containers/CalendarHeaderTrack');

const CalendarHeader = ({ tracks }) => {
  const tracksFragment = tracks.map((track, index) => (
    <Track
      key={track}
      items={track}
      last={index === tracks.length - 1} />
  ));

  return (
    <div className="planning-calendar__header">
      {tracksFragment}
    </div>
  );
};

module.exports = CalendarHeader;
