'use strict';

Wethod.module('FinanceApp.Supplier', function (Supplier, Wethod, Backbone, Marionette, $, _) {
  this.SupplierRatingModel = Wethod.Utility.BaseModel.extend({
    initialize: function (attributes, options) {
      this.options = options;
    },
    urlRoot: function () {
      return APIURL + '/supplier/' + this.options.supplierId + '/rating';
    },
  });
  this.SupplierRatingCollection = Wethod.Utility.BaseCollection.extend({
    model: Supplier.SupplierRatingModel,
    url: function () {
      return APIURL + '/supplier/' + this.options.supplierId + '/rating';
    },
  });
  // model & collection client
  this.SupplierSelectProjectModel = Wethod.Utility.BaseModel.extend();

  this.SupplierSelectProjectsCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/supplier/' + this.options.supplierId + '/projects/all';
    },
    model: Supplier.SupplierSelectProjectModel,
  });

  // model & collection client
  this.SupplierProjectModel = Wethod.Utility.BaseModel.extend();

  this.SupplierProjectsCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      var address = APIURL + '/supplier/' + this.options.supplierId + '/projects?limit='
        + this.options.limit
        + '&offset=' + this.options.offset
        + '&search=' + this.options.search
        + '&orderBy=' + this.options.orderBy
        + '&sort=' + this.options.sort;

      if (this.options.bu !== 'all') {
        address += '&bu=' + this.options.bu;
      }

      return address;
    },
    model: Supplier.SupplierProjectModel,
  });

  // model & collection client
  this.SupplierModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/supplier/',
  });
  this.SuppliersCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      var address = APIURL + '/supplier/?limit='
        + this.options.limit
        + '&offset=' + this.options.offset
        + '&search=' + encodeURIComponent(this.options.search)
        + '&orderBy=' + this.options.orderBy
        + '&sort=' + this.options.sort;

      if (this.options.bu !== 'all') {
        address += '&bu=' + this.options.bu;
      }

      return address;
    },
    model: Supplier.SupplierModel,
  });

  // model & collection relativi all'autocompletamento
  this.AutocompleteModel = Wethod.Utility.BaseModel.extend({});
  this.AutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = '?hints=' + encodeURIComponent(this.options.like);

      return APIURL + '/supplier/autocomplete' + params;
    },
    model: Supplier.AutocompleteModel,
    comparator: function (model) {
      // TODO capire perchè arriva un modello che ha come unico attributo
      // like
      if (!_.isUndefined(model.get('name'))) {
        // ordino i suggerimenti in ordine alfabetico decresente
        return model.get('name').toLowerCase();
      }
      return null;
    },
  });

  var API = {
    saveSupplierRating: function (supplierId, attributes) {
      var model = new Supplier.SupplierRatingModel(attributes, { supplierId: supplierId });
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    getSupplierRatings: function (options) {
      var collection = new Supplier.SupplierRatingCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        reset: true,
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getSupplier: function (id) {
      var model = new Supplier.SupplierModel({ id: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getSuppliersList: function (options) {
      var collection = new Supplier.SuppliersCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        reset: true,
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getSupplierSelectProjects: function (supplierId) {
      var collection = new Supplier.SupplierSelectProjectsCollection({ supplierId: supplierId });
      var defer = $.Deferred();

      collection.fetch({
        reset: true,
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getSupplierProjects: function (options) {
      var collection = new Supplier.SupplierProjectsCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        reset: true,
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveSupplier: function (model, force) {
      var defer = $.Deferred();

      if (force) {
        model.url = model.url() + '?forced=true';
      }

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.reject(response);
          }
        },
        error: function (response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteSupplierRating: function (supplierId, ratingId) {
      var model = new Supplier.SupplierRatingModel({
        id: ratingId,
      }, {
        supplierId: supplierId,
      });
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else if (response.code === 409) {
            defer.resolve(response.data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (responseModel, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteSupplier: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else if (response.code === 409) {
            defer.resolve(response.data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (responseModel, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getSupplierAutocomplete: function (options, exludeSupplierId) {
      var collection = new Supplier.AutocompleteCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (coll, response) {
          if (response.code === 200) {
            // rimuovo il modello da escludere
            coll.remove([{ id: exludeSupplierId }]);

            if (coll.size() > 0) {
              defer.resolve(coll.toJSON());
            } else {
              defer.resolve([]);
            }
          } else if (response.code === 404) {
            defer.resolve([]);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    switchSupplierRecords: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/supplier/' + options.fromId + '/switch/' + options.toId,
        xhrFields: {
          withCredentials: true,
        },
        data: {},
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.resolve(undefined);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
  };

  // supplier list
  Wethod.reqres.setHandler('get:finance:supplier:list', function (options) {
    return API.getSuppliersList(options);
  });
  // supplier detail
  Wethod.reqres.setHandler('get:finance:supplier', function (id) {
    return API.getSupplier(id);
  });
  // get supplier ratings
  Wethod.reqres.setHandler('get:finance:supplier:ratings', function (options) {
    return API.getSupplierRatings(options);
  });
  Wethod.reqres.setHandler('save:finance:supplier:rating', function (supplierId, rating) {
    return API.saveSupplierRating(supplierId, rating);
  });
  // supplier projects used in form select
  Wethod.reqres.setHandler('get:finance:supplier:select:projects', function (supplierId) {
    return API.getSupplierSelectProjects(supplierId);
  });
  Wethod.reqres.setHandler('get:finance:supplier:projects', function (options) {
    return API.getSupplierProjects(options);
  });
  // client autocomplete
  Wethod.reqres.setHandler('autocomplete:finance:supplier', function (options, exludeSupplierId) {
    return API.getSupplierAutocomplete(options, exludeSupplierId);
  });
  // client switch
  Wethod.reqres.setHandler('switch:finance:supplier:recors', function (options) {
    return API.switchSupplierRecords(options);
  });
  // save supplier
  Wethod.reqres.setHandler('save:finance:supplier', function (model, force) {
    return API.saveSupplier(model, force);
  });
  // delete supplier
  Wethod.reqres.setHandler('delete:finance:supplier', function (model) {
    return API.deleteSupplier(model);
  });
  // delete supplier rating
  Wethod.reqres.setHandler('delete:finance:supplier:rating', function (supplierId, ratingId) {
    return API.deleteSupplierRating(supplierId, ratingId);
  });
});
