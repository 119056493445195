const UserService = {
  /**
   * Check whether the given employee id is equal to the id of the logged user
   */
  isMe: (employeeId) => {
    const myId = Wethod.userInfo.get('employee_id');
    const empId = parseInt(employeeId, 10);
    return empId && empId === myId;
  },
  /**
   * Return logged user employee data, with:
   * id,
   * name,
   * surname
   */
  getLoggedEmployee: () => ({
    id: Wethod.userInfo.get('employee_id'),
    name: Wethod.userInfo.get('name'),
    surname: Wethod.userInfo.get('surname'),
  }),
};

module.exports = UserService;
