'use strict';

Wethod.module('SettingsApp.Profile', function (Profile, Wethod, Backbone, Marionette) {
  this.StructureLayout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#profileTemplate',
    regions: {
      sectionHeader: '[data-region="profileSectionHeader"]',
      body: '[data-region="profileBody"]',
    },
  });
});
