const { connect } = require('react-redux');
const Component = require('../components/Content.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  waiting: state.waiting,
  error: state.error,
  internationalCalendar: state.internationalCalendar,
  timeToRead: state.timeToRead,
  cachedOn: state.cachedOn,
});

const mapDispatchToProps = (dispatch) => ({
  updateTimeToRead: (time) => dispatch(actions.updateTimeToRead(time)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
