const React = require('react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const CreationRow = require('../../containers/job-title/CreationRow');
const EditRow = require('../../containers/job-title/EditRow');

const TableBodyRow = ({ item, levels }) => {
  const isNew = item.id == null || item.id === 'new-job-title';

  return (
    <ShowIf condition={isNew}
      showElse={<EditRow item={item} levels={levels} />}>
      <CreationRow item={item} levels={levels} />
    </ShowIf>
  );
};

module.exports = TableBodyRow;
