/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class People extends React.Component {
  getIconClassName() {
    if (this.props.icon) {
      return 'material-input__icon';
    }
  }

  getSaveClassName() {
    let style = 'wethod-button';
    if (!this.props.canSave) style += ' disabled';
    return style;
  }

  render() {
    return (
      <div className="material-input">
        <div className={this.getIconClassName()}>
          {this.props.icon}
        </div>
        <div className="material-input-box">
          <div className="material-input-box__title">{this.props.title}</div>
          {this.props.children}
          <div className="material-input-box__actions">
            <button type="button"
              className="wethod-button wethod-button--no-border"
              onClick={this.props.onCancel}>
              Cancel
            </button>
            <button type="button"
              className={this.getSaveClassName()}
              onClick={this.props.onSave}>
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }
};
