module.exports.UPDATE_TEAM_CRITERION = 'UPDATE_TEAM_CRITERION';
module.exports.LOAD_TEAM = 'LOAD_TEAM';
module.exports.FILTER_TEAM = 'FILTER_TEAM';
module.exports.SORT_TEAM = 'SORT_TEAM';

module.exports.UPDATE_PM_CRITERION = 'UPDATE_PM_CRITERION';
module.exports.LOAD_PM = 'LOAD_PM';
module.exports.FILTER_PM = 'FILTER_PM';
module.exports.SORT_PM = 'SORT_PM';

module.exports.UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';

module.exports.GET_KUDOS_REQUEST = 'GET_KUDOS_REQUEST';
module.exports.GET_KUDOS_SUCCESS = 'GET_KUDOS_SUCCESS';
