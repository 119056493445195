const { connect } = require('react-redux');
const Component = require('../../components/order-plan-settings/OrderPlanViewPreference.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  preference: state.orderPlanSettings.company_order_plan_view,
  isSaving: state.waitingFor.some((key) => key === 'set-order-plan-view-preference'),
});

const mapDispatchToProps = (dispatch) => ({
  onPreferenceChange: (preference) => dispatch(actions.setOrderPlanPreference(preference)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
