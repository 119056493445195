const React = require('react');
const T = require('../../../../../../../common/react/Typography/Typography.react');

const FrozenTooltipLabel = () => (
  <div className="wethod-project-status-frozen-tooltip__label">
    <div className="wethod-project-status-frozen-tooltip__label-title">
      <T weight={T.WEIGHTS.SEMIBOLD} size={T.SIZES.PX11}>Read-only data</T>
    </div>
    <div className="wethod-project-status-frozen-tooltip__label-line">This information has been frozen.</div>
    <div className="wethod-project-status-frozen-tooltip__label-line">You won't be able to edit it again</div>
  </div>
);

module.exports = FrozenTooltipLabel;
