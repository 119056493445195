const { connect } = require('react-redux');
const Component = require('../components/Calendar.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  people: state.filtered_people,
  plans: state.plans,
  days: state.days,
  zoom: state.zoom,
  international: state.international_calendar,
  selectedProject: state.selected_project,
  isLoadingPlan: state.waiting_for.filter((request) => request === 'plan').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getPlan: (from, to) => dispatch(actions.getPlan(from, to)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
