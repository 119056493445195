/* eslint-disable backbone/no-model-attributes */
module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.projectId = options.projectId;
    this.urlRoot = `${APIURL}/canvas/${this.projectId}/goal`;
  },
  save() {
    const defer = $.Deferred();
    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: this.urlRoot,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  update() {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot}/${this.attributes.id}`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
