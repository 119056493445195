const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({ dayValue, prevMonthDayValue }) => {
  const getLastMonthComparator = () => {
    const labels = {
      much_lower: 'a decrease compared to',
      lower: 'a decrease compared to',
      almost_equal: 'in line with',
      equal: 'equal to',
      greater: 'an increase compared to',
      much_greater: 'an increase compared to',
    };

    return <Comparator a={dayValue} b={prevMonthDayValue} labels={labels} />;
  };
  return (
    <Paragraph>
      The average value of a working day last month reached
      {' '}
      <NV value={dayValue} />
      . This
      is
      {' '}
      {getLastMonthComparator()}
      {' '}
      the
      {' '}
      <NV value={prevMonthDayValue} />
      {' '}
      recorded one month ago.
    </Paragraph>
  );
};
