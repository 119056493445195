const React = require('react');
const PropTypes = require('prop-types');

/**
 * This component tries to cut a given text at a given max length. If this operation is successful
 * (because text length is greater than the cut length), a "Show more" button is appended.
 * "Show less" function is not supported.
 */
class TextPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { preview: true };
  }

  onShowMoreClick() {
    this.setState({ preview: false });
  }

  getContent() {
    if (this.state.preview && this.hasMore()) {
      const preview = this.props.text.slice(0, this.props.maxLength);

      return (
        <span>{preview}...
          <button type="button"
            className="wethod-text-preview__button-more"
            onClick={this.onShowMoreClick.bind(this)}>
            Show more
          </button>
        </span>
      );
    }
    return this.props.text;
  }

  hasMore() {
    return this.props.text.length > this.props.maxLength;
  }

  render() {
    return <div className="wethod-text-preview">{this.getContent()}</div>;
  }
}

TextPreview.defaultProps = {
  text: '',
  maxLength: 200,
};

TextPreview.propTypes = {
  /**
   * String to be displayed.
   */
  text: PropTypes.string,
  /**
   * Max number of character to display as preview.
   */
  maxLength: PropTypes.number,
};

module.exports = TextPreview;
