/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return, react/no-array-index-key, react/jsx-no-bind, no-use-before-define,
 react/no-access-state-in-setstate */
const React = require('react');
const Table = require('../../../../../../../common/react/TableLight/TableLight.react');
const TableHead = require('../../../../../../../common/react/TableLight/TableHead.react');
const TableBody = require('../../../../../../../common/react/TableLight/TableBody.react');
const TableHeaderRow = require('../../../../../../../common/react/TableLight/TableHeaderRow.react');
const TableTotalRow = require('../../../../../../../common/react/TableLight/TableTotalRow.react');
const TableRow = require('../../../../../../../common/react/TableLight/TableRow.react');
const TableCell = require('../../../../../../../common/react/TableLight/TableCell.react');
const { formatByStep } = require('../../../../../../../services/FormatService');

const AreasTable = ({ areas }) => {
  const getUnescapedValue = (value) => _.unescape(value);

  const getValue = (value, symbol) => (value != null
    ? symbol ? formatByStep(value, 1, false) + symbol
      : formatByStep(value, 1, false)
    : '-');

  const getTotals = () => {
    const totals = areas.reduce((total, area) => ({
      days: area.days + total.days,
      prev_days_left: area.prev_days_left + total.prev_days_left,
      hasDaysLeft: total.hasDaysLeft || area.prev_days_left,
    }), {
      days: 0,
      prev_days_left: 0,
      hasDaysLeft: false,
    });
    // set to null the sum if no 'prev_days_left' was ever found
    totals.prev_days_left = totals.prev_days_left
    || totals.hasDaysLeft ? totals.prev_days_left : null;
    return totals;
  };

  const getProgress = (days, daysLeft) => {
    let progress = null;
    if (daysLeft !== null && !Number.isNaN(daysLeft) && days) {
      progress = Math.round(((days - daysLeft) / days) * 1000) / 10;
    }
    if (progress !== null) {
      return (Math.round(progress * 10) / 10);
    }
    return null;
  };

  const getAreas = () => areas.map((area) => {
    let style = '';
    let areaName = getUnescapedValue(area.name);
    if (!area.is_on) {
      style = 'project-status-areas__row--off-area';
      areaName = (
        <span>
          <span>{getUnescapedValue(area.name)}</span>
          <span>switched off</span>
        </span>
      );
    }

    return (
      <TableRow key={area.id}
        className={style}>
        <TableCell className="project-status-areas__col--name">{areaName}</TableCell>
        <TableCell>{getValue(area.days)}</TableCell>
        <TableCell>{getValue(area.prev_days_left)}</TableCell>
        <TableCell>{getValue(getProgress(area.days, area.prev_days_left), '%')}</TableCell>
      </TableRow>
    );
  });

  const totals = getTotals();

  return (
    <Table className="project-status__sidebar-areas">
      <TableHead>
        <TableHeaderRow>
          <TableCell>Area</TableCell>
          <TableCell>Budget days</TableCell>
          <TableCell>Days left</TableCell>
          <TableCell>Progress</TableCell>
        </TableHeaderRow>
        <TableTotalRow>
          <TableCell>Total</TableCell>
          <TableCell>{getValue(totals.days)}</TableCell>
          <TableCell>{getValue(totals.prev_days_left)}</TableCell>
          <TableCell>{getValue(getProgress(totals.days, totals.prev_days_left), '%')}</TableCell>
        </TableTotalRow>
      </TableHead>
      <TableBody>
        {getAreas()}
      </TableBody>
    </Table>
  );
};
module.exports = AreasTable;
