const React = require('react');
const Content = require('../../../../containers/Calculator/Task/InviteIntercompanySupplierModal/InviteIntercompanySupplierModalContent');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const FormValidator = require('../../../../../../../../common/react/FormValidator/FormValidator.react');
const RequestManager = require('../../../../containers/RequestManager');

const InviteIntercompanySupplierModal = ({ closeModal, areaId, taskId, taskSupplier }) => (
  <Modal title="Invite supplier" onClose={closeModal}>
    <FormValidator>
      <RequestManager>
        <Content
          taskId={taskId}
          areaId={areaId}
          supplierCompany={taskSupplier}
          closeModal={closeModal} />
      </RequestManager>
    </FormValidator>
  </Modal>
);

module.exports = InviteIntercompanySupplierModal;
