const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const NumericField = require('../../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const ValueCell = ({
  updateErrors, readOnly, value, onBlur,
  onFocus, error, name, label, style, suffix, metadataService,
}) => (
  <TableCell className="pipeline-item__value" style={style}>
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <InputMetadataManager metadataService={metadataService}
        name={name}
        canEdit={!readOnly}>
        <NumericField
          label={label}
          suffix={suffix}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          errorText={error} />
      </InputMetadataManager>
    </InputValidator>
  </TableCell>
);

module.exports = ValueCell;
