const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Alert = {
  list() {
    const url = '/dashboard/user-widget/alert';

    return request({
      method: 'get',
      url,
    });
  },
  getProjectAlerts(options, filters) {
    let url = '/projects-with-alerts';
    const optionsQueryString = HTTPService.buildQueryString(options);
    const filtersQueryString = HTTPService.buildQueryString(filters);

    if (optionsQueryString) {
      url += `?${optionsQueryString}`;
    }
    if (filtersQueryString) {
      url += `&${filtersQueryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
};

module.exports = Alert;
