const { connect } = require('react-redux');
const Component = require('../components/Kudos.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getKudos: (timeFilter) => dispatch(actions.getKudos(timeFilter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
