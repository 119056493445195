/* eslint-disable camelcase */
const React = require('react');
const Paragraph = require('../../../../../common/components/SectionParagraph.react');
const NV = require('../../../../../common/components/NumericValue.react');
const NS = require('../../../NumerableSentence.react');

module.exports = ({
  projects_count, clients_count, projects_value, projects_weighted_value,
}) => {
  if (projects_count) {
    return (
      <Paragraph>
        We have
        {' '}
        <span className="bold">
          <NV value={projects_count} />
          {' '}
          <NS
            quantity={projects_count}>
            new opportunities
          </NS>
        </span>
        {' '}
        with <NV value={clients_count} /> <NS quantity={clients_count}>clients</NS>
        {' '}
        in the pipeline, for a total unweighted value of <NV value={projects_value} />
        {' '}
        and a weighted value of <NV value={projects_weighted_value} />.
      </Paragraph>
    );
  }
  return null;
};
