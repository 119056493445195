/* eslint-disable react/sort-comp,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('./CollapsibleCard.react');

module.exports = class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getGroupName() {
    return this.props.company.group ? this.props.company.group.name : '';
  }

  getCountryName() {
    return this.props.company.country ? this.props.company.country.name : '';
  }

  render() {
    return (
      <Card
        title="Company info"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <div>
          <div className="client-info-row">
            <div className="client-info-row__title">Name</div>
            <div className="client-info-row__value">{this.props.company.corporate_name}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Website</div>
            <div className="client-info-row__value">{this.props.company.website}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Group</div>
            <div className="client-info-row__value">{this.getGroupName()}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Phone</div>
            <div className="client-info-row__value">
              <a href={`tel:${this.props.company.phone}`}>{this.props.company.phone}</a>
            </div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Street</div>
            <div className="client-info-row__value">{this.props.company.street}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">ZIP / Postal code</div>
            <div className="client-info-row__value">{this.props.company.zip_code}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Town</div>
            <div className="client-info-row__value">{this.props.company.town}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Country</div>
            <div className="client-info-row__value">{this.getCountryName()}</div>
          </div>
        </div>
      </Card>
    );
  }
};
