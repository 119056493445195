/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events */
const React = require('react');
const SingleForm = require('./SingleForm.react');
const PatternSelect = require('../../../containers/modals/RepeatModal/PatternSelect');
const DateEndSelect = require('./DateEndSelect.react');
const AllocationItem = require('../PlanDetails/AllocationItem.react');

const Project = ({
  pattern, onChangePattern, date, onChangeDate, disableWeekend, project, granularity,
  canUserEdit, disableRepeatBefore,
}) => {
  const onChangeProjectPattern = (value) => {
    onChangePattern(project.id, value);
  };

  const onChangeProjectDateEnd = (name, value) => {
    onChangeDate(project.id, value);
  };

  const getForm = () => {
    if (canUserEdit) {
      return (
        <SingleForm granularity={granularity}>
          <div className="planning-people-repeat-project__icon">
            <div className="wethod-icon-repeat wethod-icon-repeat--black" />
          </div>
          <div className="planning-people-repeat-project__form">
            <PatternSelect onChange={onChangeProjectPattern} value={pattern} />
            <DateEndSelect
              onChange={onChangeProjectDateEnd}
              value={date}
              name="single"
              disabled={pattern === 'no-repeat'}
              disableWeekend={disableWeekend}
              disableBefore={disableRepeatBefore} />
          </div>
        </SingleForm>
      );
    }
    return null;
  };

  return (
    <div>
      <AllocationItem key={project.id} project={project} canUserEdit={canUserEdit} />
      {getForm()}
    </div>
  );
};

module.exports = Project;
