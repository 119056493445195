const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  offset: state.pageOffset,
  limit: state.pageLimit,
  keyword: state.keyword,
  typeFilter: state.typeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  showSidebar: () => dispatch(actions.showSidebar()),
  showExportModal: () => dispatch(actions.showExportModal()),
  getItems: (offset, limit, search, typeFilter) => dispatch(actions
    .getExpenses(offset, limit, search, typeFilter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
