// eslint-disable-next-line no-unused-vars
const LevelAmountNode = require('./LevelAmountNode');
// eslint-disable-next-line no-unused-vars
const TaskTotal = require('../NodeTotal/TaskNodeTotal');
const Node = require('./StructureNode');
const Previous = require('../NodePrevious/JobTitleNodePrevious');
const Total = require('../NodeTotal/JobTitleNodeTotal');

/**
 * Represents a job title related to a specific budget task version.
 */
module.exports = class JobTitle extends Node {
  /**
   * @param jobTitle
   * @param {LevelAmountNode} levelAmount
   */
  constructor(jobTitle, levelAmount) {
    super();
    this.jobTitle = jobTitle;
    this.levelAmount = levelAmount;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  getDays() {
    return this.levelAmount.getDays();
  }

  getCost() {
    return this.levelAmount.getCost();
  }

  getPrice() {
    return this.levelAmount.getPrice();
  }

  /**
   * @private
   * @return {JobTitleNodeTotal}
   */
  calculateTotal() {
    return new Total(this.getDays(), this.getCost(), this.getPrice());
  }

  /**
   * @returns {LevelAmountNode}
   */
  getLevel() {
    return this.levelAmount;
  }

  /**
   * @returns {TaskTotal}
   */
  getTotal() {
    return this.total;
  }

  /**
   * @return {TaskNodePrevious}
   */
  getPrevious() {
    return this.previous;
  }

  getPreviousDays() {
    return this.levelAmount.getPreviousDays();
  }

  /**
   * @private
   * @return {JobTitleNodePrevious}
   */
  calculatePrevious() {
    return new Previous(this.getPreviousDays());
  }

  toJson() {
    return {
      id: this.levelAmount.getAmountId(),
      days: this.levelAmount.getDays(),
      is_new: this.levelAmount.isNew(),
      deleted: this.levelAmount.wasDeleted(),
      budget_job_title: this.jobTitle,
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };
  }
};
