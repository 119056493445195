/* eslint-disable no-shadow,no-use-before-define */
const constants = require('./constants');
const TravelModel = require('../../../../models/Travel');
const TravelModelOld = require('./models/TravelModel');
const TravelCollection = require('./models/TravelCollection');
const FavoriteCityModel = require('./models/FavoriteCityModel');
const FavoriteCitiesCollection = require('./models/FavoriteCitiesCollection');
const ContactsCollection = require('./models/ContactsCollection');
const ExportModel = require('./models/ExportModel');
const FileModel = require('./models/FileModel');

const filterTravelsRequest = () => ({
  type: constants.FILTER_TRAVELS_REQUEST,
});

const filterTravelsSuccess = (travels, keyword, size) => ({
  type: constants.FILTER_TRAVELS_SUCCESS,
  items: travels,
  keyword,
  size,
});

module.exports.filterTravels = (keyword, size) => (dispatch) => {
  dispatch(filterTravelsRequest());
  const collection = new TravelCollection();
  $.when(collection.loadMoreTravels(null, keyword, size))
    .done((travels) => dispatch(filterTravelsSuccess(travels, keyword, size)));
};

const loadMoreTravelsRequest = () => ({
  type: constants.LOAD_MORE_TRAVELS_REQUEST,
});

const loadMoreTravelsSuccess = (travels, size) => ({
  type: constants.LOAD_MORE_TRAVELS_SUCCESS,
  items: travels,
  size,
});

module.exports.loadMoreTravels = (page, size, keyword, deletedElements = 0) => (dispatch) => {
  dispatch(loadMoreTravelsRequest());
  const offset = (page - 1) * size - deletedElements;
  const collection = new TravelCollection();
  $.when(collection.loadMoreTravels(offset, keyword, size))
    .done((travels) => dispatch(loadMoreTravelsSuccess(travels, size)));
};

const updateTravelRequest = () => ({
  type: constants.UPDATE_TRAVEL_REQUEST,
});

const updateTravelSuccess = (travel) => ({
  type: constants.UPDATE_TRAVEL_SUCCESS,
  item: travel,
});

const updateTravelFailure = () => ({
  type: constants.UPDATE_TRAVEL_FAILURE,
});

module.exports.updateTravel = (travel) => (dispatch) => {
  dispatch(updateTravelRequest());

  $.when(TravelModel.update(travel.id, travel))
    .done((travel) => dispatch(updateTravelSuccess(travel)))
    .fail((message) => {
      const data = { title: 'Cannot save travel', message };
      dispatch(updateTravelFailure());
      dispatch(showServerErrorModal(data));
    });
};

const deleteTravelRequest = () => ({
  type: constants.DELETE_TRAVEL_REQUEST,
});

const deleteTravelSuccess = (travel) => ({
  type: constants.DELETE_TRAVEL_SUCCESS,
  item: travel,
});

const deleteTravelFailure = () => ({
  type: constants.DELETE_TRAVEL_FAILURE,
});

module.exports.deleteTravel = (travel) => (dispatch) => {
  dispatch(deleteTravelRequest());

  $.when(TravelModel.delete(travel.id))
    .done(() => dispatch(deleteTravelSuccess(travel)))
    .fail((message) => {
      const data = { title: 'Cannot delete travel', message };
      dispatch(deleteTravelFailure());
      dispatch(showServerErrorModal(data));
    });
};

const createTravelRequest = () => ({
  type: constants.CREATE_TRAVEL_REQUEST,
});

const createTravelSuccess = (travel) => ({
  type: constants.CREATE_TRAVEL_SUCCESS,
  item: travel,
});

module.exports.createTravel = (travel) => (dispatch) => {
  dispatch(createTravelRequest());
  const model = new TravelModelOld(travel);
  $.when(model.createTravel()).done((newTravel) => dispatch(createTravelSuccess(newTravel)));
};

const getFavoritesRequest = () => ({
  type: constants.GET_FAVORITES_REQUEST,
});

const getFavoritesSuccess = (favorites) => ({
  type: constants.GET_FAVORITES_SUCCESS,
  items: favorites,
});

module.exports.getFavorites = () => (dispatch) => {
  dispatch(getFavoritesRequest());
  const collection = new FavoriteCitiesCollection();

  $.when(collection.getFavorites()).done((favorites) => dispatch(getFavoritesSuccess(favorites)));
};

const addFavoriteRequest = () => ({
  type: constants.ADD_FAVORITE_REQUEST,
});

const addFavoriteSuccess = (favorite) => ({
  type: constants.ADD_FAVORITE_SUCCESS,
  item: favorite,
});

module.exports.addFavorite = (favorite) => (dispatch) => {
  dispatch(addFavoriteRequest());
  const model = new FavoriteCityModel(favorite);

  $.when(model.addFavorite()).done((favorite) => dispatch(addFavoriteSuccess(favorite)));
};

const deleteFavoriteRequest = () => ({
  type: constants.DELETE_FAVORITE_REQUEST,
});

const deleteFavoriteSuccess = (favorite) => ({
  type: constants.DELETE_FAVORITE_SUCCESS,
  item: favorite,
});

module.exports.deleteFavorite = (favorite) => (dispatch) => {
  dispatch(deleteFavoriteRequest());
  const model = new FavoriteCityModel(favorite);

  $.when(model.deleteFavorite()).done((favorite) => dispatch(deleteFavoriteSuccess(favorite)));
};

const getTravelOfficesRequest = () => ({
  type: constants.GET_TRAVEL_OFFICES_REQUEST,
});

const getTravelOfficesSuccess = (offices) => ({
  type: constants.GET_TRAVEL_OFFICES_SUCCESS,
  items: offices,
});

module.exports.geTravelOffices = () => (dispatch) => {
  dispatch(getTravelOfficesRequest());
  const collection = new ContactsCollection();
  $.when(collection.getList()).done((offices) => dispatch(getTravelOfficesSuccess(offices)));
};

const exportModuleRequest = () => ({
  type: constants.EXPORT_REQUEST,
});

const exportModuleSuccess = () => ({
  type: constants.EXPORT_SUCCESS,
});

module.exports.exportModule = (from, to) => (dispatch) => {
  dispatch(exportModuleRequest());
  const model = new ExportModel({
    from,
    to,
  });
  $.when(model.sendExport()).done(() => {
    dispatch(exportModuleSuccess());
    dispatch(closeModal());
  });
};

module.exports.downloadFile = (file, travel, travelCarrier) => (dispatch) => {
  const model = new FileModel();
  $.when(model.download(file.id)).fail((response) => {
    if (response.code === 404) {
      dispatch(deleteFileSuccess(travel, travelCarrier, file));
      dispatch(showCannotDownloadFileModal(['not-found']));
    } else {
      dispatch(showCannotDownloadFileModal(['server-error']));
    }
  });
};

module.exports.uploadFileSuccess = (travel, carrier, file) => ({
  type: constants.UPLOAD_FILE_SUCCESS,
  travel,
  carrier,
  file,
});

const deleteFileSuccess = (travel, carrier, file) => ({
  type: constants.DELETE_FILE_SUCCESS,
  travel,
  carrier,
  file,
});

module.exports.filterFavorites = (keyword) => ({
  type: constants.FILTER_FAVORITES,
  item: keyword,
});

const showCategoryFilesDownloadModal = (data) => ({
  type: constants.SHOW_CATEGORY_DOWNLOAD_MODAL,
  data,
});

module.exports.showCannotUploadFileModal = (data) => ({
  type: constants.SHOW_UPLOAD_ERROR_MODAL,
  data,
});

const showCannotDownloadFileModal = (data) => ({
  type: constants.SHOW_DOWNLOAD_ERROR_MODAL,
  data,
});

module.exports.showTravelOfficeModal = (data) => ({
  type: constants.SHOW_TRAVEL_OFFICE_MODAL,
  data,
});

module.exports.showExportModal = (data) => ({
  type: constants.SHOW_EXPORT_MODAL,
  data,
});

const showServerErrorModal = (data) => ({
  type: constants.SHOW_SERVER_ERROR_MODAL,
  data,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showSidebar = (travel) => ({
  type: constants.SHOW_SIDEBAR,
  item: travel,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

module.exports.deleteFileSuccess = deleteFileSuccess;
module.exports.showCannotDownloadFileModal = showCannotDownloadFileModal;
module.exports.showCategoryFilesDownloadModal = showCategoryFilesDownloadModal;
module.exports.closeModal = closeModal;
