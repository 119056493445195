/* eslint-disable no-underscore-dangle */
const React = require('react');
const $ = require('jquery');
const Empty = require('../../../../../common/react/lists/EmptyRow.react');
const Table = require('../../../../../common/react/Table2/Table2.react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const TableBody = require('../../../../../common/react/Table2/TableBody.react');
const TravelRow = require('../containers/TravelRow');
const Search = require('../containers/SearchBox');
const auth = require('../services/authManager');
const Loader = require('../../../../../common/react/Loader/Loader.react');
const FormMetadataManager = require('../../../../../common/react/FormMetadataManager/FormMetadataManager.react');

module.exports = class TravelList extends React.Component {
  static ownershipLabel(text = 'Mine') {
    return (
      <TableRow colSpan={8} className="travel-table__row--ownership" key={text}>
        <TableCell className="travel-table__column--ownership"><span className="ownerLabel">{text}</span></TableCell>
      </TableRow>
    );
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.travel__actions-wrapper')
      .outerHeight(true) + 46);
  }

  constructor(props) {
    super(props);

    this.extraRows = false;
    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getSearch() {
    return <Search filter={this.filterTravels.bind(this)} />;
  }

  getEmptyContent() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    return (
      <span className="travel__empty-row">There's nothing here! What about adding a new request?</span>);
  }

  getTravels() {
    this.extraRows = false;
    const body = this.props.travels.map((item) => (
      <FormMetadataManager metadata={item._fields} key={item.id}>
        <TravelRow travel={item} />
      </FormMetadataManager>
    ));
    const index = this.props.travels.findIndex((item) => !auth.isTravelMine(item.employee_id));
    if (auth.getPermission('view_other') || index !== -1) {
      // I check only the first element since the results list is ordered by ownership from the backend
      const first = this.props.travels[0] ? this.props.travels[0].employee_id : null;
      const firstIsMine = first ? auth.isTravelMine(this.props.travels[0].employee_id) : false;

      if (index !== -1) {
        body.splice(index, 0, TravelList.ownershipLabel('others'));
        this.extraRows = true;
      }

      if (body.length > 0) {
        if (!firstIsMine) {
          body.unshift(
            <TableRow colSpan={8} key="empty_mine">
              <TableCell className="wethod-table__cell wethod-table__cell--empty">
                <Empty key="empty_mine" message={this.getEmptyContent()} />
              </TableCell>
            </TableRow>,
          );
        }
        body.unshift(TravelList.ownershipLabel('mine'));
        this.extraRows = true;
      }
    }

    return body;
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isLoading) {
      const deletedElem = this.props.deletedElements - this.props.createdElements;
      if (deletedElem < 0) {
        this.extraRows = true;
      }
      const adjustedPage = this.extraRows ? page - 1 : page;
      this.props.loadMore(adjustedPage, size, this.props.keyword,
        (this.props.deletedElements - this.props.createdElements));
      this.setState({
        size,
      });
    }
  }

  filterTravels(keyword) {
    this.props.filter(keyword, this.state.size);
  }

  updateTableHeight() {
    this.setState({ tableHeight: TravelList.getTableMaxHeight() });
  }

  render() {
    return (
      <div>
        <Table columns={8} maxHeight={this.state.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow className="travel-table__row">
              <TableCell className="travel-table__column--project travel-table__row--project">Project</TableCell>
              <TableCell className="travel-table__column--joborder travel-table__row--joborder hidden-mobile">
                Requested By
              </TableCell>
              <TableCell className="travel-table__column--date-alt travel-table__row--date hidden-mobile">Date</TableCell>
              <TableCell className="travel-table__column--destination travel-table__row--destination">
                Main Destination
              </TableCell>
              <TableCell className="travel-table__column--to travel-table__row--to hidden-mobile">Office</TableCell>
              <TableCell className="travel-table__column--type travel-table__row--type hidden-mobile">Category</TableCell>
              <TableCell className="travel-table__column--status travel-table__row--status" />
              <TableCell className="travel-table__column--actions travel-table__row--actions" />
            </TableRow>
          </TableHead>
          <TableBody hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty={this.getEmptyContent()}>
            {this.getTravels()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
