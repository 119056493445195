/* eslint-disable
 react/prop-types,consistent-return,react/no-did-update-set-state,react/sort-comp,
 class-methods-use-this,react/no-access-state-in-setstate,no-prototype-builtins,
 no-param-reassign */
const React = require('react');
const Input = require('../TextInput.react');
const Item = require('./Item.react');
const EmptyItem = require('./EmptyItem.react');
const ItemCreator = require('./ItemCreator.react');

/**
 * An enhanced select with live search.
 * Pass an "onValidate" callback to get validation errors.
 * Supported constraints: same as TextInput.
 *
 * LIFECYCLE HOOKS
 * onValidate, onChange.
 *
 * OTHER PROPS
 * - name
 * - constraints
 * - current
 * - param: Optional, the alternative name of the param to show
 * - prefix: A prefix assigned to the uniqueID that is generated for each item of the list.
 *           This is used only if the items have no id field
 * - items: the list to filter through. Each item must have a name and and id
 * - creatorFields: pass it if you want to display a creator if no results are found.
 *                  Pass an empty array if your new created item just need its name to be saved.
 *                  Check ItemCreator for more info.
 *                  If creatorFields is undefined, no creation form will be shown.
 *
 * @type {module.SearchSelect}
 */
module.exports = class SearchSelect extends React.Component {
  constructor(props) {
    super(props);

    this.clickOutsideHandler = this.handleClickOutside.bind(this);

    this.state = {
      value: this.getValue(), // Text actually in search input
      showList: false,
      param: this.props.param ? this.props.param : 'name',
      current: this.props.current ? this.props.current : null, // Actually selected item
      items: this.props.items ? this.props.items : [], // All items
      filteredItems: this.props.items ? this.props.items : [], // Items to show in list
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.clickOutsideHandler, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutsideHandler, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.items.length !== this.state.items.length) this.setState({ showList: false });
  }

  handleClickOutside(event) {
    const domNode = this.node;

    if (!domNode || !domNode.contains(event.target)) {
      if (!this.state.current && this.state.value) {
        this.reset();
      } else if (this.state.current && !this.consistentValue()) {
        this.reset();
      } else {
        this.setState({ showList: false });
      }
    }
  }

  getItemCreatedAt(items, timestamp) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].created_at === timestamp) return items[i];
    }
  }

  getValue() {
    if (this.props.current) {
      const param = this.props.param ? this.props.param : 'name';
      return this.props.current[param];
    }
    return '';
  }

  /**
   * Checks a new items has been created inline using this input.
   *
   * @param nextProps
   * @returns {boolean}
   */
  createdNewItem(nextProps) {
    return this.props.creatorFields !== undefined && this.newItemsPassed(nextProps);
  }

  /**
   * Checks if items' length has changed to know if you want to update the list.
   * @param nextProps
   * @returns {boolean}
   */
  newItemsPassed(nextProps) {
    return this.state.items.length !== nextProps.items.length;
  }

  componentWillReceiveProps(nextProps) {
    if (this.createdNewItem(nextProps)) { // If a new item has been added through the select's form
      const nextCurrent = this.getItemCreatedAt(nextProps.items, this.state.current.created_at);
      this.setState({
        items: nextProps.items,
        filteredItems: nextProps.items,
        current: nextCurrent,
      });
      this.props.onChange(this.props.name, nextCurrent);
    } else if (this.newItemsPassed(nextProps)) { // If you just want to update items
      this.setState({
        items: nextProps.items,
        filteredItems: nextProps.items,
        current: null,
        value: '',
      });
    }
  }

  reset() {
    this.setState({
      value: '',
      current: null,
      showList: false,
      filteredItems: this.state.items,
    },
    () => {
      this.props.onChange(this.props.name, this.state.current);
      this.handleChange(this.props.name, this.state.value);
    });
  }

  consistentValue() {
    return this.state.value === this.state.current[this.state.param];
  }

  allowCreate() {
    return this.props.creatorFields;
  }

  onCreateClick(item) {
    this.updateCurrent(item);
    this.props.onCreateClick(item);
  }

  getEmpty() {
    if (this.allowCreate()) {
      return (
        <ItemCreator value={this.state.value}
          name={this.props.name}
          fields={this.props.creatorFields}
          onCreateClick={this.onCreateClick.bind(this)} />
      );
    }

    return <EmptyItem />;
  }

  getItems() {
    if (!this.hasResults()) {
      return this.getEmpty();
    }
    const prefix = this.props.prefix ? this.props.prefix : 'search_select-';
    return this.state.filteredItems.map((item) => {
      const itemId = item.id !== undefined ? item.id : _.uniqueId(prefix);
      return (
        <Item key={itemId}
          onClick={this.updateCurrent.bind(this)}
          {...item} />
      );
    });
  }

  getList() {
    if (this.state.showList) {
      return (
        <ul className="wethod-search-select__list">
          {this.getItems()}
        </ul>
      );
    }
    return null;
  }

  hasResults() {
    return this.state.filteredItems.length > 0;
  }

  updateCurrent(item) {
    if (!item.hasOwnProperty('id')) {
      item.created_at = +new Date();
    }
    this.setState({
      showList: false,
      current: item,
      value: item[this.state.param],
    },
    () => {
      this.props.onChange(this.props.name, this.state.current);
    });
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Returns collection filtered by key.
   * @param collection
   * @param key
   */
  filterItems(collection, key) {
    return collection.filter((item) => this.found(key, item[this.state.param]));
  }

  handleChange(name, value) {
    const results = this.filterItems(this.state.items, value);
    this.setState({
      value,
      filteredItems: results,
    });
  }

  handleFocus() {
    this.setState({ showList: true });

    if (this.props.onFocus) this.props.onFocus();
  }

  getColorDot() {
    if (this.state.current && this.state.current.color && this.consistentValue()) {
      return (
        <span className="wethod-search-select__dot"
          style={{ backgroundColor: this.state.current.color }} />
      );
    }
  }

  render() {
    return (
      <div className="wethod-search-select" ref={(node) => this.node = node}>
        <Input name={this.props.name}
          value={this.state.value}
          constraints={this.props.constraints}
          onValidate={this.props.onValidate}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.props.onBlur}
          placeholder={this.props.placeholder} />
        {this.getColorDot()}
        {this.getList()}
      </div>
    );
  }
};
