const { connect } = require('react-redux');
const Component = require('../../common/CapacityManager.react');
const FeatureService = require('../../services/FeatureService');

const mapStateToProps = (state) => ({
  employeeCapacity: FeatureService.isEmployeeCapacityEnabled()
    ? state.employeeWorkHourCapacity : null,
  includeWeekend: state.internationalCalendar,
  holidays: state.employeeHolidays,
});

module.exports = connect(mapStateToProps)(Component);
