const React = require('react');
const moment = require('moment');

const SaveManager = ({ children, onCreate, onSave, ...rest }) => {
  /**
   * Check whether the given item is new and not yet saved.
   * @param item
   * @returns {boolean}
   */
  function isNew(item) {
    return !item || !item.id;
  }

  function formatItem(changes) {
    const item = {};

    Object.keys(changes).forEach((key) => {
      const value = changes[key];
      let formattedValue;

      switch (key) {
        case 'category':
        case 'project':
        case 'owner':
          formattedValue = value.id;
          item[key] = formattedValue;
          break;
        case 'date':
          formattedValue = moment(value).format('YYYY-MM-DD');
          item[key] = formattedValue;
          break;
        case 'requested_by': // Ignore this field: it should not be saved
          break;
        default:
          item[key] = value;
          break;
      }
    });
    return item;
  }

  /**
   * Create or update the item
   * @param changes
   * @param updatedItem
   */
  function handleSave(changes, updatedItem) {
    if (isNew(updatedItem)) {
      const formattedChanges = formatItem(updatedItem);
      onCreate(formattedChanges);
    } else {
      const { id } = updatedItem;

      const formattedChanges = formatItem(changes);
      onSave(id, formattedChanges);
    }
  }

  return React.cloneElement(children, {
    ...rest,
    onSave: handleSave,
  });
};

module.exports = SaveManager;
