const { connect } = require('react-redux');
const Component = require('../../components/modals/ExportModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'export').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  exportModule: (year) => dispatch(actions.export(year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
