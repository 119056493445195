/* eslint-disable react/no-did-update-set-state, no-underscore-dangle */
const React = require('react');
const isEqual = require('react-fast-compare');
const Sidebar = require('../../../../../../common/react/sidebar/Sidebar.react');
const FormMetadataManager = require('../../../../../../common/react/FormMetadataManager/FormMetadataManager.react');
const ItemBody = require('./ItemBody.react');
const Actions = require('../../containers/sidebar/ItemSidebarActions');
const Title = require('./ItemSidebarTitle.react');
const ItemManager = require('../../containers/ItemManager');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.ItemSidebar}
 */
module.exports = class ItemSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  static isNew(item) {
    return !item || !item.project.id;
  }

  static getMetadata(metadataId, metadataList) {
    if (metadataList && metadataList.length > 0) {
      const metadata = metadataList.filter((meta) => meta.metadata_id === metadataId);
      return metadata && metadata.length > 0 ? metadata[0] : null;
    }
    return null;
  }

  static isMetadata(key) {
    return key.indexOf('metadata') >= 0;
  }

  /**
   * Remove the given name from the list of changes
   * @param changes
   * @param name
   */
  static resetChanges(changes, name) {
    return changes.filter((attr) => attr !== name);
  }

  /**
   * Add the given name to the list of changes, if not already present
   * @param changes
   * @param name
   */
  static addChanges(changes, name) {
    if (!changes.includes(name)) {
      changes.push(name);
    }
    return changes;
  }

  /**
   * Format the identifier of name and attribute
   * @param name
   * @param attribute
   * @returns {string|*}
   */
  static getFormattedKey(name, attribute) {
    return attribute ? `${name}-${attribute}` : name;
  }

  /**
   * Return the updated item, merging the changes into the oldItem
   * @param oldItem
   * @param changes
   */
  static getUpdatedItem(oldItem, changes) {
    if (oldItem) {
      const updatedItem = { ...oldItem };
      if (changes) {
        Object.keys(changes).forEach((key) => {
          if (key === 'project') {
            updatedItem[key] = { ...oldItem[key], ...changes[key] };
          } else {
            updatedItem[key] = changes[key];
          }
        });
      }
      return updatedItem;
    }
    return null;
  }

  /**
   * Return the metadata name to override
   * @returns {{}}
   */
  static getMetadataNameOverrides() {
    return {
      id_project_label: 'project_label',
      id_project_type: 'project_type',
      id_client: 'client',
      id_customer: 'customer',
      id_pm: 'pm',
      id_account: 'account',
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      item: this.props.item,
      // by default is true if item has no id (when we want to  add a new one)
      editMode: ItemSidebar.isNew(this.props.item),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.item && this.props && this.props.item) {
      // Update the newly created item id once it is first saved
      if (!prevState.item.project.id && this.props.item.project.id) {
        const updatedItem = {
          ...prevState.item,
          project: {
            ...this.props.item.project,
          },
        };
        this.setState({
          item: updatedItem,
        });
      }
    }
  }

  /**
   * Update the item and unsaved changes
   * @param name
   * @param value
   * @param attribute
   */
  handleInputChanges(name, value, attribute) {
    const formattedChanges = this.getFormattedChanges(name, value, attribute);

    this.setState((prevState) => {
      const unsavedChanges = this
        .updateChangedAttributesList(prevState.unsavedChanges, formattedChanges, name, attribute);

      const updatedItem = ItemSidebar.getUpdatedItem(prevState.item, formattedChanges);

      return {
        item: updatedItem,
        unsavedChanges,
      };
    });
  }

  handleItemDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.state.item);
    }
  }

  handleItemArchive(archive) {
    if (this.props.onArchive) {
      this.props.onArchive(this.props.item, archive);
    }
  }

  /**
   * Save the item with the modified attributes.
   * Metadata modified are separated in created, updated and deleted ones.
   */
  handleSave() {
    // Get the list of metadata that have been modified
    const metadataUnsavedChanges = this.state.unsavedChanges
      .filter((key) => ItemSidebar.isMetadata(key));
    const metadataToCreate = []; // List of metadata that needs to be created
    const metadataToUpdate = []; // List of metadata that needs to be updated
    const metadataToDelete = []; // List of metadata that needs to be deleted

    if (metadataUnsavedChanges && metadataUnsavedChanges.length > 0) {
      metadataUnsavedChanges.forEach((metadata) => {
        let metadataId = metadata.split('-')[1];
        metadataId = metadataId ? parseInt(metadataId) : null;
        // Find previous value of modified metadata
        const oldMetadataValue = ItemSidebar.getMetadata(metadataId, this.props.item.metadata);
        // Find current value of modified metadata
        const newMetadataValue = ItemSidebar.getMetadata(metadataId, this.state.item.metadata);

        // A metadata needs to be created when it had no previous value
        const created = (!oldMetadataValue || oldMetadataValue.id === null) && newMetadataValue;
        // A metadata needs to be update when it has both previous and current value
        const updated = (oldMetadataValue && oldMetadataValue.id) && newMetadataValue;
        // A metadata needs to be deleted when it had a previous value but no current one
        const deleted = (oldMetadataValue && oldMetadataValue.id) && !newMetadataValue;

        if (created) {
          metadataToCreate.push(newMetadataValue);
        } else if (updated) {
          metadataToUpdate.push(newMetadataValue);
        } else if (deleted) {
          metadataToDelete.push(oldMetadataValue);
        }
      });
    }

    this.setState({
      editMode: false,
      unsavedChanges: [],
    });

    const unsavedChanges = this.getUnsavedChanges();

    this.props.onSave(unsavedChanges, metadataToCreate, metadataToUpdate, metadataToDelete);
  }

  handleEditMode() {
    if (this.isEditable() && !this.state.editMode) {
      this.setState({ editMode: true });
    }
  }

  /**
   * Return the new changes, properly formatted
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @param {string} attribute - name of the nested attribute updated
   * @returns {*}
   */
  getFormattedChanges(name, value, attribute) {
    let updatedValue;
    switch (name) {
      case 'project':
        if (attribute === 'probability') {
          return this.props.formatProbabilityChanges(value, this.state.item);
        }
        if (attribute === 'estimate') {
          return this.props.formatValueChanges(value);
        }

        updatedValue = {
          [attribute]: ItemSidebar.isDirty(value) ? value : null,
        };

        // Set default value to empty array instead of null
        if (attribute === 'whitelisted_employee') {
          updatedValue[attribute] = updatedValue[attribute] ? updatedValue[attribute] : [];
        }

        // Set default whitelisted employees when necessary
        if (attribute === 'timesheet_whitelist') {
          return this.props.formatWhitelistChanges(value, this.state.item);
        }

        return {
          [name]: updatedValue,
        };
      case 'metadata':
        updatedValue = this.getUpdatedMetadataList(attribute, value);
        return {
          [name]: updatedValue,
        };
      case 'client':
        return {
          [name]: value,
          customer: {
            id: null,
          },
        };
      case 'opportunity_status':
        return this.props.formatStageChanges(value, this.state.item);
      case 'project_type':
        return this.props.formatJocChanges(value, this.state.item);
      default:
        return { [name]: value };
    }
  }

  /**
   * Return the changes to be saved
   * @returns {{}}
   */
  getUnsavedChanges() {
    const changes = {};
    this.state.unsavedChanges.forEach((key) => {
      if (!ItemSidebar.isMetadata(key)) {
        const changedField = key.split('-');
        const name = changedField[0];
        // Changes may not have a nested attribute
        if (changedField.length > 1) {
          const nestedAttribute = changedField[1];
          changes[name] = changes[name]
            ? { ...changes[name], [nestedAttribute]: this.state.item[name][nestedAttribute] }
            : { [nestedAttribute]: this.state.item[name][nestedAttribute] };
        } else {
          changes[name] = this.state.item[name];
        }
      }
    });
    return changes;
  }

  /**
   * Return current item metadata.
   * @returns {*|[{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: [{name: string, value: string}]},{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: [{name: string, value: string}]}]|{cannot_edit_reason: string, name: string, can_edit: boolean, constraints: []}|{cannot_edit_reason: null, name: string, can_edit: boolean, constraints: [{name: string, value: string}]}|[]|[{cannot_edit_reason: string, name: string, can_edit: boolean, constraints: []}]}
   */
  getPropertyMetadata() {
    return ItemSidebar.isNew(this.state.item)
      ? this.props.projectMetadata : this.state.item._fields;
  }

  getBody() {
    return (
      <ItemManager item={this.state.item}>
        <FormMetadataManager metadata={this.getPropertyMetadata()}
          nameOverrides={ItemSidebar.getMetadataNameOverrides()}>
          <ItemBody readOnly={!this.state.editMode}
            item={this.state.item}
            errors={this.props.errors}
            updateErrors={this.props.updateErrors}
            creation={ItemSidebar.isNew(this.state.item)}
            onChange={this.handleInputChanges.bind(this)}
            canEditJobOrder={this.props.canEditJobOrder}
            canEditProbability={this.props.canEditItemProbability(this.state.item)}
            canEditValue={this.props.canEditItemValue(this.state.item)}
            canEditCosts={this.props.canEditItemCosts(this.state.item.budget)}
            canEditRisk={this.props.canEditItemRisk(this.state.item)}
            canEditStage={this.props.canEditItemStage(this.state.item)}
            canEditJoc={this.props.canEditItemJoc(this.state.item)}
            isPipedriveIntegrationEnabled={this.props.isPipedriveIntegrationEnabled} />
        </FormMetadataManager>
      </ItemManager>
    );
  }

  getActions() {
    if (!this.state.editMode) {
      const showDelete = !ItemSidebar.isNew(this.state.item) && this.canDelete();
      const canArchive = !ItemSidebar.isNew(this.state.item) && this.canEdit();
      return (
        <ItemManager item={this.state.item}>
          <Actions project={this.state.item.project}
            budget={this.state.item.budget}
            canDelete={showDelete}
            canArchive={canArchive}
            isArchived={this.state.item.project.archived}
            canStartReview={this.props.canStartReview}
            onDelete={this.handleItemDelete.bind(this)}
            onArchive={this.handleItemArchive.bind(this)} />
        </ItemManager>
      );
    }
    return null;
  }

  /**
   * Return updated list of metadata associated to the focused item.
   * When the given metadata is new, add it to the list.
   * When it needs to be deleted, remove it from the list.
   * Otherwise update the existing value with the new one.
   * @param metadataId
   * @param value
   * @returns {*}
   */
  getUpdatedMetadataList(metadataId, value) {
    const oldMetadata = ItemSidebar.getMetadata(metadataId, this.state.item.metadata);
    const isNew = oldMetadata == null;
    const isEmpty = value == null;

    if (isEmpty) { // Remove metadata from list
      return this.state.item.metadata.filter((metadata) => metadata.metadata_id !== metadataId);
    }

    if (!isNew) { // merge updated metadata into old one
      return this.state.item.metadata.map((metadata) => {
        if (metadata.metadata_id === metadataId) {
          return { ...metadata, ...value };
        }
        return metadata;
      });
    }

    return this.state.item.metadata.concat({
      ...value,
      metadata_id: metadataId,
    }); // Add updated metadata to the list
  }

  /**
   * Return the updated list of changed attributes
   * @param oldChangesList
   * @param newChanges
   * @param name
   * @param attribute
   * @returns {*}
   */
  updateChangedAttributesList(oldChangesList, newChanges, name, attribute) {
    let changesList = oldChangesList;
    Object.keys(newChanges).forEach((key) => {
      let values = [];
      let attributes = [];

      if (key === 'project') {
        attributes = Object.keys(newChanges[key]);
        values = Object.values(newChanges[key]);
      } else if (key === 'metadata') {
        attributes = [attribute];
        values = [ItemSidebar.getMetadata(attribute, newChanges[key])];
      } else {
        attributes = key === name ? [attribute] : [null];
        values = [newChanges[key]];
      }

      // Attributes may be empty, but we still need to add the value to the changes list
      attributes.forEach((attr, index) => {
        const itemName = ItemSidebar.getFormattedKey(key, attr);
        if (this.hasChanged(key, values[index], attr)) {
          changesList = ItemSidebar.addChanges(changesList, itemName);
        } else {
          changesList = ItemSidebar.resetChanges(changesList, itemName);
        }
      });
    });

    return changesList;
  }

  canDelete() {
    const { item } = this.props;
    if (item) {
      return this.props.canDeleteItem(item.pm.id, item.account.id);
    }
    return false;
  }

  /**
   * Checks if it's safe to save a item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.isSaving() && this.props.isValid;
  }

  isEditable() {
    const { item } = this.props;
    if (item) {
      return this.props.canEditItem(item.pm.id, item.account.id);
    }
    return false;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.isEditable() && !this.state.editMode;
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @param {string} attribute - name of the nested attribute to be checked
   * @returns {boolean}
   */
  hasChanged(name, value, attribute) {
    const oldItem = this.props.item || {};
    let oldVal = null;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'project':
        oldVal = oldItem.project ? oldItem.project[attribute] : null;
        newVal = newVal || (newVal === false ? false : null);

        if (attribute === 'whitelisted_employee') {
          oldVal = oldItem.project ? oldItem.project[attribute] : [];
          newVal = newVal || [];
          return !isEqual(oldVal, newVal);
        }
        break;
      case 'metadata':
        oldVal = ItemSidebar.getMetadata(attribute, this.props.item.metadata);
        oldVal = oldVal ? oldVal.selected_value.trim() : null;
        newVal = value ? value.selected_value : null;
        break;
      case 'business_unit':
        oldVal = oldItem[name];
        break;
      case 'pipedrive_deals':
        oldVal = oldItem.pipedrive_deals && oldItem.pipedrive_deals.length
          ? oldItem.pipedrive_deals
          : [];
        newVal = newVal && newVal.length ? newVal : [];
        return !isEqual(oldVal, newVal);
      case 'name':
        oldVal = oldItem.project ? oldItem.project.name : null;
        break;
      default:
        oldVal = oldItem[name] ? oldItem[name].id : null;
        newVal = value ? value.id : null;
        break;
    }

    return (ItemSidebar.isDirty(oldVal) || ItemSidebar.isDirty(newVal)) && (oldVal !== newVal);
  }

  hasUnsavedChanges() {
    return this.state.unsavedChanges && this.state.unsavedChanges.length > 0;
  }

  isSaving() {
    if (this.props.item && this.props.item.project) {
      const creatingItem = 'create-item';
      const savingItem = `save-item-${this.props.item.project.id}`;
      const savingMetadata = `save-${this.props.item.project.id}-metadata`;
      return this.props.waitingFor.filter((key) => key === creatingItem
        || key === savingItem
        || key.indexOf(savingMetadata) >= 0).length > 0;
    }
    return false;
  }

  render() {
    return (
      <Sidebar title={<Title creation={ItemSidebar.isNew(this.props.item)} />}
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.isSaving()}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
