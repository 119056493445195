const React = require('react');
const PicPicker = require('../containers/ProfilePicPicker');

module.exports = class BasicInfo extends React.Component {
  getTags() {
    const tagsLength = this.props.tags.length;
    return this.props.tags.map((tag, index) => {
      if (index < tagsLength - 1) {
        return (
          <span key={tag.id}>
            {tag.name}
            ,
            {' '}
          </span>
        );
      }
      return <span key={tag.id}>{tag.name}</span>;
    });
  }

  render() {
    return (
      <div className="profile-basic-info">
        <div className="profile-basic-info__column">
          <PicPicker />
        </div>
        <div className="profile-basic-info__column profile-basic-info__column--data">
          <div className="profile-basic-info__name">{this.props.name}</div>
          <div className="profile-basic-info__tags">{this.getTags()}</div>
          {this.props.jobTitle.id
            ? <div className="profile-basic-info__level">{this.props.level}, {this.props.jobTitle.name}</div>
            : <div className="profile-basic-info__level">{this.props.level}</div>}
        </div>
      </div>
    );
  }
};
