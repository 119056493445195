const React = require('react');
const modals = require('../../modals');
const CreationWarningModal = require('./CreationWarningModal/CreationWarningModal.react');

const availableModals = {
  [modals.CREATION_WARNING]: CreationWarningModal,
};

module.exports = ({
  toShow,
  closeModal,
  modalData,
  saveProduct,
}) => {
  const Component = availableModals[toShow];
  if (Component) {
    return <Component closeModal={closeModal} saveProduct={saveProduct} {...modalData} />;
  }

  return null;
};
