/* eslint-disable react/prop-types */
const React = require('react');
const Area = require('./Area.react');

module.exports = class Menu extends React.Component {
  onSectionClick() {
    return this.props.onSectionClick(this.props.module);
  }

  getAreas() {
    const { module } = this.props;
    if (module) {
      return module.getAreas().map((area) => (
        <Area key={area.getLabel()}
          area={area}
          onSectionClick={this.onSectionClick.bind(this)} />
      ));
    }
    return null;
  }

  render() {
    if (this.props.module) {
      return (
        <div className="app-nav__second-level">
          <div className="app-nav__second-level-content">
            {this.getAreas()}
          </div>
        </div>
      );
    }
    return null;
  }
};
