const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('../TooltipFixed/Tooltip.react');

const CollaborativeInputTooltip = ({ label, children }) => (
  <Tooltip label={label} className="wethod-collaborative-input__tooltip">{children}</Tooltip>
);

CollaborativeInputTooltip.defaultProps = {
  label: '',
};

CollaborativeInputTooltip.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element.isRequired,
};

module.exports = CollaborativeInputTooltip;
