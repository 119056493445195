/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NumericValue = require('../../../../common/components/NumericValue.react');
const NumerableSentence = require('../../NumerableSentence.react');
const Link = require('../../../../common/components/Link.react');

module.exports = class ValuableProjects extends React.Component {
  getProject(project) {
    return (
      <span key={project.project.id}>
        <Link
          href={`#project/${project.project.id}/report`}>
          {_.unescape(project.project.name)}
        </Link>
        {' '}
        of
        {' '}
        <NumericValue
          value={project.project.value} />
        {' '}
        (
        {_.unescape(project.client.name)}
        )
      </span>
    );
  }

  getProjects() {
    const list = this.props.biggest_won_projects;
    const projects = list.map((project) => this.getProject(project));
    return <List list={list} terminator=".">{projects}</List>;
  }

  render() {
    const listSize = this.props.biggest_won_projects.length;

    if (listSize === 1) {
      return (
        <Paragraph>
          <span className="bold">Last week we won </span>
          {' '}
          {this.getProjects()}
        </Paragraph>
      );
    } if (listSize > 1) {
      return (
        <Paragraph>
          <span className="bold">
            The{' '}
            <NumerableSentence quantity={listSize}>projects</NumerableSentence>
            {' '}
            with the greatest value
          </span>
          {' '}
          that
          we won last week
          {' '}
          <NumerableSentence quantity={listSize}>are:</NumerableSentence>
          {' '}
          {this.getProjects()}
        </Paragraph>
      );
    }
    return null;
  }
};
