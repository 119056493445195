/* eslint-disable react/prop-types,react/sort-comp */
const React = require('react');
const Header = require('./Header.react');
const Menu = require('./Menu.react');
const Overlay = require('./Overlay.react');

module.exports = class Nav extends React.Component {
  constructor(props) {
    super(props);

    const { location } = Backbone.history;
    const currentUrl = `${location.pathname}${location.hash}`;
    const modulesWithUrl = this.props.model.getModulesWithUrl(currentUrl);
    const currentModule = modulesWithUrl.length ? modulesWithUrl[0] : null;

    this.state = {
      openModule: null,
      currentModule,
    };

    this.mainModules = this.getMainModules();
    this.userModules = this.getUserModules();
  }

  getMainModules() {
    const { model } = this.props;
    return model.getModules().filter((module) => module.getLabel() !== 'User');
  }

  getUserModules() {
    const { model } = this.props;
    return model.getModules().filter((module) => module.getLabel() === 'User');
  }

  isOpen(module) {
    if (this.state.openModule) {
      return module.getLabel() === this.state.openModule.getLabel();
    }
    return false;
  }

  onModuleClick(module) {
    if (module.getUrl()) { // If module is a clickable one (so it does not have areas)
      this.setState({
        openModule: null,
        currentModule: module,
      });
    } else if (this.isOpen(module)) { // clicked on a module already open
      this.setState({ openModule: null });
    } else {
      this.setState({ openModule: module });
    }
  }

  onSectionClick(module) {
    this.setState({
      openModule: null,
      currentModule: module,
    });
  }

  onOverlayClick() {
    this.setState({ openModule: null });
  }

  render() {
    return (
      <nav className="app-nav">
        <Header mainModules={this.mainModules}
          userModules={this.userModules}
          onModuleClick={this.onModuleClick.bind(this)}
          openModule={this.state.openModule}
          currentModule={this.state.currentModule} />
        <Menu module={this.state.openModule}
          onSectionClick={this.onSectionClick.bind(this)} />
        <Overlay show={this.state.openModule}
          onClick={this.onOverlayClick.bind(this)} />
      </nav>
    );
  }
};
