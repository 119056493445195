const React = require('react');
const PropTypes = require('prop-types');

const ModalBody = ({ children }) => (
  <div className="wethod-modal__body">{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = ModalBody;
