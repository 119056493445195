const React = require('react');
const FlightEdit = require('./FlightEditableDetails.react');
const FlightView = require('./FlightReadOnlyDetails.react');
const TrainEdit = require('./TrainEditableDetails.react');
const TrainView = require('./TrainReadOnlySection.react');
const CarEdit = require('./CarEditableDetails.react');
const CarView = require('./CarReadOnlyDetails.react');
const AccommodationEdit = require('./AccommodationEditableDetails.react');
const AccommodationView = require('./AccommodationReadOnlyDetails.react');
const OtherEdit = require('./OtherEditableDetails.react');
const OtherView = require('./OtherReadOnlyDetails.react');

module.exports = ({ category, editable, travel, ...restProps }) => {
  if (editable) {
    switch (category) {
      case 'flight':
        return <FlightEdit travel={travel} {...restProps} />;
      case 'train':
        return <TrainEdit travel={travel} {...restProps} />;
      case 'car':
        return <CarEdit travel={travel} {...restProps} />;
      case 'accommodation':
        return <AccommodationEdit travel={travel} {...restProps} />;
      case 'other':
      default:
        return <OtherEdit travel={travel} {...restProps} />;
    }
  } else {
    switch (category) {
      case 'flight':
        return <FlightView travel={travel} {...restProps} />;
      case 'train':
        return <TrainView travel={travel} {...restProps} />;
      case 'car':
        return <CarView travel={travel} {...restProps} />;
      case 'accommodation':
        return <AccommodationView travel={travel} {...restProps} />;
      case 'other':
      default:
        return <OtherView travel={travel} {...restProps} />;
    }
  }
};
