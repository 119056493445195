const React = require('react');
const Label = require('../../../SearchAndSelectModal/SearchAndSelectModalOptionLabel.react');
const Option = require('../../../SearchAndSelectModal/SearchAndSelectModalOption.react');

const ProductOption = ({ id, onChange, checked, name, description }) => (
  <Option
    name={`product-${id}`}
    type={Option.TYPE_CHECKBOX}
    checked={checked}
    value={id}
    id={`product-${id}`}
    label={<Label name={name} description={description} />}
    onChange={onChange} />
);

module.exports = ProductOption;
