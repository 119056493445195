/* eslint-disable default-case,no-underscore-dangle,camelcase,operator-assignment */

'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  // LAYOUT
  this.ProjectCharterDetailLayout = Marionette.LayoutView.extend({
    template: '#projectCharterDetailLayoutTemplate',
    className: 'fullHeight',
    regions: {
      headerSection: '[data-region="sectionHeader"]',
      header: '[data-region="projectCharterHead"]',
      projects: '[data-region="projectCharterProjects"]',
      resources: '[data-region="projectCharterResources"]',
      economics: '[data-region="projectCharterEconomics"]',
      planning: '[data-region="projectCharterPlanning"]',

      dialog: '[data-region="projectCharterDialog"]',
    },
    ui: {
      bottomSpaceEl: '[data-region="bottomSpace"]',
    },
  });

  // HEAD
  this.ProjectCharterDetailHeadTemplateView = Marionette.ItemView.extend({
    className: 'projectCharterHead',
    template: '#projectCharterDetailHead',
    ui: {
      // INPUT
      mdlInput: '[data-region="mdl-input"]',
      nameEl: '[data-region="programName" ]',
      descriptionEl: '[data-region="programDescription"]',

      // autocomplete
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      inputHintEl: '[data-region="programManager"]',
      managerHintEl: '[data-region="managerHint"]',
    },
    events: {
      'change @ui.nameEl': function (e) {
        return (this.updateModel(e, 'name'));
      },
      'change @ui.descriptionEl': function (e) {
        return (this.updateModel(e, 'description'));
      },
      // autocomplete
      'click @ui.inputHintEl': 'showManagerHints',
      'keyup @ui.inputHintEl': 'showManagerHints',
    },
    modelEvents: {
      change: 'modelUpdated',
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    modelUpdated: function () {
      ProjectCharterApp.updateProgram(this);
    },
    changeHint: function (id, hint) {
      this.model.set({ manager: id });
      this.options.hint = hint;
      this.cleanManagerName();
    },
    updateModel: function (e, attribute) {
      if (e.target.value.trim() !== '') {
        this.model.attributes[attribute] = e.target.value.trim();
      } else {
        this.model.attributes[attribute] = null;
      }
      ProjectCharterApp.updateProgram(this);
    },
    showManagerHints: function (e) {
      e.stopPropagation();
      // this.resetErrors();
      this.ui.managerHintEl.addClass('isVisible');
      ProjectCharterApp.renderManagerHints(this, e.target.value.trim());
      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.managerHintEl.selector, 'isVisible', 'remove');
    },
    cleanManagerName: function () {
      this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');
      this.ui.managerHintEl.removeClass('isVisible');
      this.ui.inputHintEl.val(this.options.hint);
    },
  });

  // PROJECT LIST
  this.ProjectCharterProjectsLayout = Marionette.LayoutView.extend({
    template: '#projectCharterProjectsLayoutTemplate',
    className: 'fullHeight',
    regions: {
      list: '[data-region="projectList"]',
    },
    ui: {
      addProjectEl: '[data-action="addProject"]',
      showEl: '[data-action="showProjects"]',
      showLabelEl: '[data-region="showProjectsLabel"]',
    },
    events: {
      'click @ui.addProjectEl': 'addProject',
      'click @ui.showEl': 'showProject',
    },
    onRender: function () {
      dispatcher.on('projectCharter:show:project:update:label', function (action, projects) {
        this.updateShowLabel(action, projects);
      }, this);

      dispatcher.on('projectCharter:show:project:display:label', function (action) {
        switch (action) {
          case 'show':
            this.ui.showEl.show();
            break;
          case 'hide':
            this.ui.showEl.hide();
            break;
        }
      }, this);
    },
    addProject: function (e) {
      e.preventDefault();
      if (!this.ui.addProjectEl.hasClass('disabled')) {
        this.ui.addProjectEl.addClass('disabled');
        ProjectCharterApp.addProject();
      }
    },
    showProject: function (e) {
      e.preventDefault();
      dispatcher.trigger('projectCharter:show:project:list');
    },
    updateShowLabel: function (action, projects) {
      var text = '';
      switch (action) {
        case 'less':
          text = 'hide [' + projects + '] projects';
          break;
        case 'more':
          text = 'show [' + projects + '] more projects';
          break;
      }

      this.ui.showLabelEl.text(text);
    },
  });
  this.ProjectCharterProjectTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'col_16 no_padding',
    _template: '#projectCharterDetailProjectTemplate',
    _newTemplate: '#projectCharterDetailAddProjectTemplate',
    ui: {
      // INPUT
      mdlInput: '[data-region="mdl-input"]',

      // AUTOCOMPLETE
      inputHintEl: '[data-region="projectName"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      projectHintEl: '[data-region="projectHint"]',

      // BUTTONS
      addProjcetEl: '[data-action="addProject"]',
      cancelAddProjectEl: '[data-action="cancelAdd"]',
      removeProjectEl: '[data-action="removeProject"]',

      // MORE ACTIONS
      moreActionsEl: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',

    },
    events: {
      // autocomplete
      'click @ui.inputHintEl': 'showProjectHints',
      'keyup @ui.inputHintEl': 'showProjectHints',
      // buttons
      'click @ui.addProjcetEl': 'addNewProject',
      'click @ui.cancelAddProjectEl': 'cancelNewProject',
      'click @ui.removeProjectEl': 'removeProject',
      // more actions
      'click @ui.moreActionsEl': 'moreActions',
    },
    modelEvents: {
      change: 'modelUpdated',
    },
    initialize: function () {
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = !_.isUndefined(this.model.get('new')) ? $(that._newTemplate)
        .html() : $(that._template).html();
      var model = serialized_model;

      if (!_.isNull(serialized_model.project)) {
        model.linkBudget = '#pipeline/budget/' + serialized_model.project.id;
        model.linkPipeline = '#pipeline/projects?id=' + serialized_model.project.id;
        model.linkReport = '#project/' + serialized_model.project.id + '/report';
        model.linkTimeline = '#timeline/' + serialized_model.project.id;
        model.linkCanvas = '#project/' + serialized_model.project.id + '/canvas';
        model.linkProductionPlan = '#planning/production-plan?project_id:' + serialized_model.project.id;
        model.linkInvoicePlan = '#project/' + serialized_model.project.id + '/invoice-plan';
        model.linkProjectStatus = '#project-status/' + serialized_model.project.id;
        model.linkPlanning = '#planning/people?project_id=' + serialized_model.project.id;

        model.isValueAsUnit = this.options.isValueAsUnit;
      }

      return _.template(template)(model);
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    onShow: function () {
      if (_.isUndefined(this.model.get('new'))) {
        if (this.model.get('project').probability < 90) {
          this.$el.addClass('tentative');
        }
      }
    },
    modelUpdated: function () {
      if (!_.isNull(this.model.get('project'))) {
        this.ui.addProjcetEl.removeClass('disabled');
      } else {
        this.ui.addProjcetEl.addClass('disabled');
      }
    },
    changeHint: function (id, hint) {
      this.model.set({ project: id });
      this.options.hint = hint;
      this.cleanManagerName();
    },
    showProjectHints: function (e) {
      e.stopPropagation();
      // this.resetErrors();
      if (e.target.value.trim() === '') {
        this.ui.addProjcetEl.addClass('disabled');
      }

      this.ui.projectHintEl.addClass('isVisible');
      ProjectCharterApp.renderProjectsHints(this, e.target.value.trim());
      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.projectHintEl.selector, 'isVisible', 'remove');
    },
    cleanManagerName: function () {
      this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');
      this.ui.projectHintEl.removeClass('isVisible');
      this.ui.inputHintEl.val(this.options.hint);
    },
    addNewProject: function (e) {
      e.preventDefault();
      if (!this.ui.addProjcetEl.hasClass('disabled')) {
        this.ui.addProjcetEl.addClass('disabled');

        ProjectCharterApp.persistNewProject(this);
      }
    },
    cancelNewProject: function (e) {
      e.preventDefault();
      $('[data-action="addProject"]').removeClass('disabled');
      this.model.destroy();
    },
    removeProject: function (e) {
      e.preventDefault();
      if (!this.ui.removeProjectEl.hasClass('disabled')) {
        this.ui.removeProjectEl.addClass('disabled');

        ProjectCharterApp.removeProgramProject(this);
      }
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        var visible = this.$el.find('.isVisible');
        visible.removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');

        // Hide select when click on 'html'
        e.stopPropagation();
        Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }
    },
  });
  this.ProjectCharterProjectEmptyView = Marionette.ItemView.extend({
    template: '#projectCharterDetailProjectEmptyRowTemplate',
    tagName: 'li',
    className: 'col_16 no_padding projectEmptyRow',
  });
  this.ProjectCharterProjectsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear projectCharterList',
    emptyView: ProjectCharterApp.ProjectCharterProjectEmptyView,
    childView: ProjectCharterApp.ProjectCharterProjectTemplateView,
    initialize: function (options) {
      this.options = options;
      this.options.justShow = false;
    },
    onRender: function () {
      dispatcher.on('projectCharter:show:project:list', function () {
        if (!this.$el.is(':animated')) {
          if (this.$el.height() > 61 * 5) {
            this.collapse('less');
          } else {
            this.collapse('more');
          }
        }
      }, this);

      this.options.justShow = true;

      if (this.collection.length <= 5) {
        dispatcher.trigger('projectCharter:show:project:display:label', 'hide');
      } else {
        this.$el.css({ overflow: 'hidden' });
        dispatcher.trigger('projectCharter:show:project:display:label', 'show');
        dispatcher.trigger('projectCharter:show:project:update:label', 'more', this.collection.length - 5);
      }
    },
    onAddChild: function () {
      if (this.collection.length >= 5) {
        var height = 5 * 61;
        this.$el.height(height);
      } else {
        this.$el.height('auto');
      }

      if (this.options.justShow) {
        var projects = this.collection.length - 5;

        if (this.collection.length > 5) {
          this.$el.css({ overflow: 'hidden' });
          dispatcher.trigger('projectCharter:show:project:display:label', 'show');
        }

        if (this.$el.height() > 61 * 5) {
          dispatcher.trigger('projectCharter:show:project:update:label', 'less', projects);
        } else {
          dispatcher.trigger('projectCharter:show:project:update:label', 'more', projects);
        }
      }
    },
    onRemoveChild: function () {
      if (this.options.justShow) {
        if (this.collection.length <= 5) {
          this.$el.css({ overflow: 'initial' });
          var height = this.collection.length * 61;
          this.$el.height(height);

          dispatcher.trigger('projectCharter:show:project:display:label', 'hide');
        }

        var projects = this.collection.length - 5;
        if (this.$el.height() > 61 * 5) {
          dispatcher.trigger('projectCharter:show:project:update:label', 'less', projects);
        } else {
          dispatcher.trigger('projectCharter:show:project:update:label', 'more', projects);
        }
      }
    },
    collapse: function (action) {
      var height;
      var operation = '';
      var label;
      var projects = this.collection.length - 5;
      switch (action) {
        case 'more':
          operation = '+=';
          label = 'less';
          // height = this.collection.length * 61
          break;
        case 'less':
          operation = '-=';
          label = 'more';
          // height = 61 * 5
          break;
      }

      height = (this.collection.length - 5) * 61;

      this.$el.animate({ height: operation + height }, 500, function () {
        dispatcher.trigger('projectCharter:show:project:update:label', label, projects);
      });
    },
    childViewOptions: function () {
      return {
        isValueAsUnit: this.options.isValueAsUnit.get('enabled'),
      };
    },
  });

  // RESOURCE TYPE LIST
  this.ResourceTypeView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#projectCharterDetailResourceTypeRowTemplate',
    ui: {
      hintEL: '[data-action="selectType"]',
    },
    events: {
      'click @ui.hintEL': 'selectType',
    },
    initialize: function (options) {
      this.options = options;
    },
    selectType: function (e) {
      e.preventDefault();
      this.options.parentView.changeType(this.model.get('id'), this.model.get('name'));
    },
  });
  this.ResourceTypeEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#projectCharterDetailResourceTypeEmptyRowTemplate',
  });
  this.ProjectCharterResourcesTypeCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear list',
    emptyView: ProjectCharterApp.ResourceTypeEmptyView,
    childView: ProjectCharterApp.ResourceTypeView,
  });

  // RESOURCE LIST
  this.ProjectCharterResourcesLayout = Marionette.LayoutView.extend({
    template: '#projectCharterResourcesLayoutTemplate',
    className: 'fullHeight',
    regions: {
      list: '[data-region="resourcesList"]',
    },
    ui: {
      addResourceEl: '[data-action="addResource"]',
      showEl: '[data-action="showResources"]',
      showLabelEl: '[data-region="showresourcesLabel"]',
    },
    events: {
      'click @ui.addResourceEl': 'addResource',
      'click @ui.showEl': 'showResource',
    },
    onRender: function () {
      dispatcher.on('projectCharter:show:resource:update:label', function (action, resources) {
        this.updateShowLabel(action, resources);
      }, this);

      dispatcher.on('projectCharter:show:resource:display:label', function (action) {
        switch (action) {
          case 'show':
            this.ui.showEl.show();
            break;
          case 'hide':
            this.ui.showEl.hide();
            break;
        }
      }, this);
    },
    addResource: function (e) {
      e.preventDefault();
      if (!this.ui.addResourceEl.hasClass('disabled')) {
        this.ui.addResourceEl.addClass('disabled');
        ProjectCharterApp.addResource();
      }
    },
    showResource: function (e) {
      e.preventDefault();
      dispatcher.trigger('projectCharter:show:resource:list');
    },
    updateShowLabel: function (action, resources) {
      var text = '';
      switch (action) {
        case 'less':
          text = 'hide [' + resources + '] resources';
          break;
        case 'more':
          text = 'show [' + resources + '] more resources';
          break;
      }

      this.ui.showLabelEl.text(text);
    },
  });
  this.ProjectCharterResourceTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'col_16 no_padding',
    ui: {
      // INPUT
      mdlInput: '[data-region="mdl-input"]',
      urlInputEl: '[data-region="resourceUrl" ]',
      urlAliasInputEl: '[data-region="resourceUrlAlias"]',
      resourceTypeEl: '[data-action="resourceType"]',
      resourceTypeLabelEl: '[data-region="resourceLabelType"]',

      // NOTES
      addNoteEl: '[data-action="addNotePopup"]',
      showNoteEl: '[data-action="showNotePopup"]',

      // AUTOCOMPLETE
      inputHintEl: '[data-region="projectName"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      programProjectHintEl: '[data-region="programProjectHint"]',

      // AUTOCOPLETE TYPE
      resourceTypeListWrapperEl: '[data-region="resourceTypeListWrap"]',

      // BUTTONS
      addResourceEl: '[data-action="addResource"]',
      cancelAddProjectEl: '[data-action="cancelAdd"]',
      removeResourceEl: '[data-action="removeResource"]',
    },
    events: {
      'keyup @ui.urlInputEl': function (e) {
        return this.updateData(e, 'content');
      },
      'keyup @ui.urlAliasInputEl': function (e) {
        return this.updateData(e, 'alias');
      },
      // autocomplete
      'click @ui.inputHintEl': 'showProgramProjectHints',
      'keyup @ui.inputHintEl': 'showProgramProjectHints',
      // type list
      'click @ui.resourceTypeEl': 'showResourceTypeList',
      // buttons
      'click @ui.addResourceEl': 'addNewResource',
      'click @ui.cancelAddProjectEl': 'cancelNewResource',
      'click @ui.removeResourceEl': 'removeResource',
      // notes
      'click @ui.addNoteEl': 'addNote',
      'click @ui.showNoteEl': 'showNote',
    },
    modelEvents: {
      change: 'modelUpdated',
    },
    initialize: function (options) {
      this.options = options;

      if (!_.isNull(this.model.get('content'))) {
        // se non presente aggiungo http://
        if (this.model.get('content').indexOf('http') === -1) {
          var link = 'http://' + this.model.get('content');
          this.model.attributes.content = link;
        }
      }
    },
    getTemplate: function () {
      if (!_.isUndefined(this.model.get('new'))) {
        return '#projectCharterDetailAddResourceTemplate';
      }
      return '#projectCharterDetailResourceTemplate';
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    modelUpdated: function () {
      if (
        !_.isNull(this.model.get('content'))
        && !_.isNull(this.model.get('category'))
      ) {
        this.ui.addResourceEl.removeClass('disabled');
      } else {
        this.ui.addResourceEl.addClass('disabled');
      }
    },
    changeHint: function (id, hint) {
      this.model.set({ program_project: id });
      this.options.hint = hint;
      this.cleanProject();
    },
    changeType: function (id, hint) {
      this.model.set({ category: id });
      this.options.hint = hint;
      this.ui.resourceTypeLabelEl.text(hint);
    },
    updateData: function (e, attribute) {
      var value = (e.target.value.trim() !== '') ? e.target.value.trim() : null;

      switch (attribute) {
        case 'content':
          this.model.set({ content: value });
          break;
        case 'alias':
          this.model.set({ alias: value });
          break;
      }
    },
    showProgramProjectHints: function (e) {
      e.stopPropagation();

      this.ui.programProjectHintEl.addClass('isVisible');
      ProjectCharterApp.renderProgramProjectsHints(this, e.target.value.trim());
      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.programProjectHintEl.selector, 'isVisible', 'remove');
    },
    showResourceTypeList: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.ui.resourceTypeListWrapperEl.addClass('isVisible');
      ProjectCharterApp.renderResourceTypeList(this);
      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.resourceTypeListWrapperEl.selector, 'isVisible', 'remove');
    },
    cleanProject: function () {
      this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');
      this.ui.programProjectHintEl.removeClass('isVisible');
      this.ui.inputHintEl.val(this.options.hint);
    },
    addNewResource: function (e) {
      e.preventDefault();
      if (!this.ui.addResourceEl.hasClass('disabled')) {
        this.ui.addResourceEl.addClass('disabled');

        ProjectCharterApp.persistNewResource(this);
      }
    },
    addNote: function (e) {
      e.preventDefault();
      ProjectCharterApp.notePopup('add', this.model);
    },
    showNote: function (e) {
      e.preventDefault();
      ProjectCharterApp.notePopup('show', this.model);
    },
    cancelNewResource: function (e) {
      e.preventDefault();
      this.model.destroy();
      $('[data-action="addResource"]').removeClass('disabled');
    },
    removeResource: function (e) {
      e.preventDefault();
      if (!this.ui.removeResourceEl.hasClass('disabled')) {
        this.ui.removeResourceEl.addClass('disabled');

        ProjectCharterApp.removeProgramResource(this);
      }
    },
  });
  this.ProjectCharterResourceEmptyView = Marionette.ItemView.extend({
    template: '#projectCharterDetailResourceEmptyRowTemplate',
    tagName: 'li',
    className: 'col_16 no_padding projectEmptyRow',
  });
  this.ProjectCharterResourcesCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear projectCharterList',
    emptyView: ProjectCharterApp.ProjectCharterResourceEmptyView,
    childView: ProjectCharterApp.ProjectCharterResourceTemplateView,
    initialize: function (options) {
      this.options = options;
      this.options.justShow = false;
    },
    onRender: function () {
      dispatcher.on('projectCharter:show:resource:list', function () {
        if (!this.$el.is(':animated')) {
          if (this.$el.height() > 61 * 5) {
            this.collapse('less');
          } else {
            this.collapse('more');
          }
        }
      }, this);

      this.options.justShow = true;

      if (this.collection.length <= 5) {
        dispatcher.trigger('projectCharter:show:resource:display:label', 'hide');
      } else {
        this.$el.css({ overflow: 'hidden' });
        dispatcher.trigger('projectCharter:show:resource:display:label', 'show');
        dispatcher.trigger('projectCharter:show:resource:update:label', 'more', this.collection.length - 5);
      }
    },
    onAddChild: function () {
      if (this.collection.length >= 5) {
        var height = 5 * 61;
        this.$el.height(height);
      } else {
        this.$el.height('auto');
      }

      if (this.options.justShow) {
        var resources = this.collection.length - 5;

        if (this.collection.length > 5) {
          this.$el.css({ overflow: 'hidden' });
          dispatcher.trigger('projectCharter:show:resource:display:label', 'show');
        }

        if (this.$el.height() > 61 * 5) {
          dispatcher.trigger('projectCharter:show:resource:update:label', 'less', resources);
        } else {
          dispatcher.trigger('projectCharter:show:resource:update:label', 'more', resources);
        }
      }
    },
    onRemoveChild: function () {
      if (this.options.justShow) {
        if (this.collection.length <= 5) {
          this.$el.css({ overflow: 'initial' });
          var height = this.collection.length * 61;
          this.$el.height(height);

          dispatcher.trigger('projectCharter:show:resource:display:label', 'hide');
        }

        var resources = this.collection.length - 5;
        if (this.$el.height() > 61 * 5) {
          dispatcher.trigger('projectCharter:show:resource:update:label', 'less', resources);
        } else {
          dispatcher.trigger('projectCharter:show:resource:update:label', 'more', resources);
        }
      }
    },
    collapse: function (action) {
      var height;
      var operation = '';
      var label;
      var resources = this.collection.length - 5;
      switch (action) {
        case 'more':
          operation = '+=';
          label = 'less';
          break;
        case 'less':
          operation = '-=';
          label = 'more';
          break;
      }

      height = (this.collection.length - 5) * 61;

      this.$el.animate({ height: operation + height }, 500, function () {
        dispatcher.trigger('projectCharter:show:resource:update:label', label, resources);
      });
    },
  });

  // NOTES
  var modalW = 450;
  var modalH = 412;
  this.ProjectCharterResourceTemplateView = Marionette.ItemView.extend({
    className: 'col_16 no_padding',
    template: '#projectCharterNoteModalTemplate',
    ui: {
      modalEL: '.modalBgCreationStructure',
      mdlInput: '[data-region="mdl-input"]',

      descriptionEl: '[data-region="noteDescription"]',

      // BUTTONS
      cancelEl: '[data-action="modalCancel"]',
      doActionEl: '[data-action="modalDoAction"]',
    },
    events: {
      'keyup @ui.descriptionEl': 'updateNote',

      // BUTTONS
      'click @ui.doActionEl': 'addNote',
      'click @ui.cancelEl': 'closeNote',
    },
    initialize: function (options) {
      this.options = options;
      this.options.note = this.options.parentModel.get('note');
    },
    onRender: function () {
      // posiziono la view
      this.placeModal();
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    updateNote: function (e) {
      this.options.note = e.target.value.trim();
    },
    addNote: function (e) {
      e.preventDefault();
      this.options.parentModel.set({ note: this.options.note });
      this.destroy();
    },
    closeNote: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // ECONOMICS
  this.ProjectCharterLoadingEconomicsTemplateView = Marionette.ItemView.extend({
    className: 'col_16 no_padding',
    template: '#projectCharterEconomicsLoadingTemplate',
  });
  this.ProjectCharterDetailEconomicsLayout = Marionette.LayoutView.extend({
    template: '#projectCharterDetailEconomicsLayoutTemplate',
    className: 'fullHeight',
    regions: {
      totals: '[data-region="totals"]',
    },
  });

  // totals
  this.ProjectCharterDetailEconomicsTotalViewOld = Marionette.ItemView.extend({
    template: '#projectCharterDetailEconomicsTotlaTemplateOld',
    _format: function (num, cost) {
      // todo this function is duplicated on detail-view.js

      cost = cost || false;

      if (cost) {
        if (num > 0) {
          num = '(' + numeral(num).format('0,0') + ')';
        } else if (num < 0) { // if 0 no parenthesis
          num = numeral(-1 * num).format('0,0');
        }
      } else if (num >= 0) {
        num = numeral(num).format('0,0');
      } else {
        num = '(' + numeral(-1 * num).format('0,0') + ')';
      }

      return num;
    },
    initialize: function () {
      // todo this function is duplicated on detail-view.js

      var baseline = this.model.get('baseline');
      var lastVersion = this.model.get('last_version');
      var forecast = this.model.get('forecast');

      // BASELINE
      baseline.display_revenues = this._format(baseline.revenues);
      baseline.display_internal_costs = this._format(baseline.internal_costs, true);
      baseline.display_external_costs = this._format(baseline.external_costs, true);
      baseline.display_expenses = this._format(baseline.expenses, true);
      baseline.display_travels = this._format(baseline.travels, true);
      baseline.display_margin = this._format(baseline.margin);
      baseline.display_wasted = this._format(baseline.wasted, true);
      baseline.display_margin_net = this._format(baseline.margin_net);

      // DELTA VS BASELINE
      baseline.display_delta_revenues = this._format(forecast.revenues - baseline.revenues);
      baseline.display_delta_internal_costs = this
        ._format(forecast.internal_costs - baseline.internal_costs, true);
      baseline.display_delta_external_costs = this
        ._format(forecast.external_costs - baseline.external_costs, true);
      baseline.display_delta_travels = this._format(forecast.travels - baseline.travels, true);
      baseline.display_delta_expenses = this._format(forecast.expenses - baseline.expenses, true);
      baseline.display_delta_margin = this._format(forecast.margin - baseline.margin);
      baseline.display_delta_wasted = this._format(forecast.wasted - baseline.wasted, true);
      baseline.display_delta_margin_net = this._format(forecast.margin_net - baseline.margin_net);

      // LAST VERSION
      lastVersion.display_revenues = this._format(lastVersion.revenues);
      lastVersion.display_internal_costs = this._format(lastVersion.internal_costs, true);
      lastVersion.display_external_costs = this._format(lastVersion.external_costs, true);
      lastVersion.display_expenses = this._format(lastVersion.expenses, true);
      lastVersion.display_travels = this._format(lastVersion.travels, true);
      lastVersion.display_margin = this._format(lastVersion.margin);
      lastVersion.display_wasted = this._format(lastVersion.wasted, true);
      lastVersion.display_margin_net = this._format(lastVersion.margin_net);

      // DELTA VS FORECAST
      lastVersion.display_delta_revenues = this._format(forecast.revenues - lastVersion.revenues);
      lastVersion.display_delta_internal_costs = this
        ._format(forecast.internal_costs - lastVersion.internal_costs, true);
      lastVersion.display_delta_external_costs = this
        ._format(forecast.external_costs - lastVersion.external_costs, true);
      lastVersion.display_delta_travels = this
        ._format(forecast.travels - lastVersion.travels, true);
      lastVersion.display_delta_expenses = this
        ._format(forecast.expenses - lastVersion.expenses, true);
      lastVersion.display_delta_margin = this._format(forecast.margin - lastVersion.margin);
      lastVersion.display_delta_wasted = this._format(forecast.wasted - lastVersion.wasted, true);
      lastVersion.display_delta_margin_net = this
        ._format(forecast.margin_net - lastVersion.margin_net);

      // FORECAST
      forecast.display_revenues = this._format(forecast.revenues);
      forecast.display_internal_costs = this._format(forecast.internal_costs, true);
      forecast.display_external_costs = this._format(forecast.external_costs, true);
      forecast.display_expenses = this._format(forecast.expenses, true);
      forecast.display_travels = this._format(forecast.travels, true);
      forecast.display_margin = this._format(forecast.margin);
      forecast.display_wasted = this._format(forecast.wasted, true);
      forecast.display_margin_net = this._format(forecast.margin_net);

      this.model.set('wastedHoursEnabled', this.model.get('wastedHoursEnabled') || forecast.wasted || lastVersion.wasted || baseline.wasted);
    },
  });

  this.ProjectCharterDetailEconomicsTotalView = Marionette.ItemView.extend({
    template: '#projectCharterDetailEconomicsTotlaTemplate',
    _format: function (num, cost) {
      // todo this function is duplicated on detail-view.js

      cost = cost || false;

      if (cost) {
        if (num > 0) {
          num = '(' + numeral(num).format('0,0') + ')';
        } else if (num < 0) { // if 0 no parenthesis
          num = numeral(-1 * num).format('0,0');
        }
      } else if (num >= 0) {
        num = numeral(num).format('0,0');
      } else {
        num = '(' + numeral(-1 * num).format('0,0') + ')';
      }

      return num;
    },
    initialize: function () {
      // todo this function is duplicated on detail-view.js

      var baseline = this.model.get('baseline');
      var lastVersion = this.model.get('last_version');
      var forecast = this.model.get('forecast');

      // BASELINE
      baseline.display_revenues = this._format(baseline.revenues);
      baseline.display_internal_costs = this._format(baseline.internal_costs, true);
      baseline.display_external_costs = this._format(baseline.external_costs, true);
      baseline.display_intercompany_costs = this._format(baseline.intercompany_costs, true);
      baseline.display_expenses = this._format(baseline.expenses, true);
      baseline.display_travels = this._format(baseline.travels, true);
      baseline.display_margin = this._format(baseline.margin);
      baseline.display_contribution_margin = this._format(baseline.contribution_margin);
      baseline.display_wasted = this._format(baseline.wasted, true);
      baseline.display_margin_net = this._format(baseline.margin_net);

      // DELTA VS BASELINE
      baseline.display_delta_revenues = this._format(forecast.revenues - baseline.revenues);
      baseline.display_delta_internal_costs = this
        ._format(forecast.internal_costs - baseline.internal_costs, true);
      baseline.display_delta_external_costs = this
        ._format(forecast.external_costs - baseline.external_costs, true);
      baseline.display_delta_intercompany_costs = this
        ._format(forecast.intercompany_costs - baseline.intercompany_costs, true);
      baseline.display_delta_travels = this._format(forecast.travels - baseline.travels, true);
      baseline.display_delta_expenses = this._format(forecast.expenses - baseline.expenses, true);
      baseline.display_delta_margin = this._format(forecast.margin - baseline.margin);
      baseline.display_delta_contribution_margin = this
        ._format(forecast.contribution_margin - baseline.contribution_margin);
      baseline.display_delta_wasted = this._format(forecast.wasted - baseline.wasted, true);
      baseline.display_delta_margin_net = this._format(forecast.margin_net - baseline.margin_net);

      // LAST VERSION
      lastVersion.display_revenues = this._format(lastVersion.revenues);
      lastVersion.display_internal_costs = this._format(lastVersion.internal_costs, true);
      lastVersion.display_external_costs = this._format(lastVersion.external_costs, true);
      lastVersion.display_intercompany_costs = this._format(lastVersion.intercompany_costs, true);
      lastVersion.display_expenses = this._format(lastVersion.expenses, true);
      lastVersion.display_travels = this._format(lastVersion.travels, true);
      lastVersion.display_margin = this._format(lastVersion.margin);
      lastVersion.display_contribution_margin = this._format(lastVersion.contribution_margin);
      lastVersion.display_wasted = this._format(lastVersion.wasted, true);
      lastVersion.display_margin_net = this._format(lastVersion.margin_net);

      // DELTA VS FORECAST
      lastVersion.display_delta_revenues = this._format(forecast.revenues - lastVersion.revenues);
      lastVersion.display_delta_internal_costs = this
        ._format(forecast.internal_costs - lastVersion.internal_costs, true);
      lastVersion.display_delta_external_costs = this
        ._format(forecast.external_costs - lastVersion.external_costs, true);
      lastVersion.display_delta_intercompany_costs = this
        ._format(forecast.intercompany_costs - lastVersion.intercompany_costs, true);
      lastVersion.display_delta_travels = this
        ._format(forecast.travels - lastVersion.travels, true);
      lastVersion.display_delta_expenses = this
        ._format(forecast.expenses - lastVersion.expenses, true);
      lastVersion.display_delta_margin = this._format(forecast.margin - lastVersion.margin);
      lastVersion.display_delta_contribution_margin = this
        ._format(forecast.contribution_margin - lastVersion.contribution_margin);
      lastVersion.display_delta_wasted = this._format(forecast.wasted - lastVersion.wasted, true);
      lastVersion.display_delta_margin_net = this
        ._format(forecast.margin_net - lastVersion.margin_net);

      // FORECAST
      forecast.display_revenues = this._format(forecast.revenues);
      forecast.display_internal_costs = this._format(forecast.internal_costs, true);
      forecast.display_external_costs = this._format(forecast.external_costs, true);
      forecast.display_intercompany_costs = this._format(forecast.intercompany_costs, true);
      forecast.display_expenses = this._format(forecast.expenses, true);
      forecast.display_travels = this._format(forecast.travels, true);
      forecast.display_margin = this._format(forecast.margin);
      forecast.display_contribution_margin = this._format(forecast.contribution_margin);
      forecast.display_wasted = this._format(forecast.wasted, true);
      forecast.display_margin_net = this._format(forecast.margin_net);

      this.model.set('wastedHoursEnabled', this.model.get('wastedHoursEnabled') || forecast.wasted || lastVersion.wasted || baseline.wasted);
      // this is needed to hide the "intercompany cost" row if the project has no intercompany costs
      this.model.set('hasIntercompanyCosts', forecast.intercompany_costs || lastVersion.intercompany_costs || baseline.intercompany_costs);
    },
  });

  // PLANNING
  this.ProjectCharterLoadingPlanningTemplateView = Marionette.ItemView.extend({
    className: 'col_16 no_padding',
    template: '#projectCharterPlanningLoadingTemplate',
  });
  this.ProjectCharterDetailPlanningLayout = Marionette.LayoutView.extend({
    template: '#projectCharterDetailPlanningLayoutTemplate',
    className: 'fullHeight',
    regions: {
      years: '[data-region="year"]',
      months: '[data-region="months"]',
      projects: '[data-region="projects"]',
    },
    ui: {
      // zoom
      zoomSwitchEl: '[data-region="switchPlanningZoom"]',
      zoom12El: '[data-action="planning12x"]',
      zoom8El: '[data-action="planning8x"]',
      zoom4El: '[data-action="planning4x"]',
      // arrows
      previousMontEl: '[data-action="previousMonth"]',
      nextMontEl: '[data-action="nextMonth"]',
      // overlay
      overlayEl: '[data-region="hideNoActiveProject"]',
    },
    events: {
      // zoom
      'click @ui.zoom12El': function (e) {
        return this.changeZoom(e, 'zoom12');
      },
      'click @ui.zoom8El': function (e) {
        return this.changeZoom(e, 'zoom8');
      },
      'click @ui.zoom4El': function (e) {
        return this.changeZoom(e, 'zoom4');
      },
      // arrows
      'click @ui.previousMontEl': function (e) {
        return (this.moveMonth(e, 'previous'));
      },
      'click @ui.nextMontEl': function (e) {
        return (this.moveMonth(e, 'next'));
      },
      // overlay
      'click @ui.overlayEl': 'removeOverlay',
    },
    initialize: function (opitons) {
      this.options = opitons;
      this.options.blockMove = false;

      dispatcher.on('slide:animate:end', function () {
        this.options.blockMove = false;
      }, this);
    },
    changeZoom: function (e, zoom) {
      e.preventDefault();

      $(this.ui.zoomSwitchEl.selector).children().find('.sel').removeClass('sel');
      $(e.target).addClass('sel');

      switch (zoom) {
        case 'zoom12':
          ProjectCharterApp.changeZoom(zoom);
          break;
        case 'zoom8':
          ProjectCharterApp.changeZoom(zoom);
          break;
        case 'zoom4':
          ProjectCharterApp.changeZoom(zoom);
          break;
      }
    },
    moveMonth: function (e, action) {
      e.preventDefault();
      if (!this.options.blockMove) {
        this.options.blockMove = true;
        ProjectCharterApp.moveMonth(action);
      }
    },
    removeOverlay: function () {
      var openTab = $('.charterPlanningVisibleDetail');
      var that = this;
      openTab.animate({ height: '-=' + (openTab.height() - 50) }, 500, function () {
        openTab.removeClass('charterPlanningVisibleDetail');
        that.ui.overlayEl.removeClass('active');
      });
    },
  });

  // years
  this.ProjectCharterYearHeadTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'pcYearHead left',
    template: '#projectCharterYearHeadTemplate',
  });
  this.ProjectCharterCalendarYearHeadCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear grid',
    childView: ProjectCharterApp.ProjectCharterYearHeadTemplateView,
  });

  // moths
  this.ProjectCharterMonthHeadTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'left',
    _template: '#projectCharterMonthHeadTemplate',
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var template = $(this._template).html();
      var model = serialized_model;

      if (model.first_day.slice(0, -3) === moment().format('YYYY-MM')) {
        model.active = 'active';
      }
      return _.template(template)(model);
    },
    onShow: function () {
      // adapt to zoom
      switch (this.options.monthNumber) {
        case 4:
          this.$el.addClass('pcMonthHead4');
          break;
        case 8:
          this.$el.addClass('pcMonthHead8');
          break;
        case 12:
          this.$el.addClass('pcMonthHead12');
          break;
      }
    },
  });
  this.ProjectCharterCalendarMonthsHeadCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear left relative pcWrapMonthHeadList',
    childView: ProjectCharterApp.ProjectCharterMonthHeadTemplateView,
    initialize: function (options) {
      this.options = options;
      this.options.slideWidth = 0;
    },
    onShow: function () {
      var width = this.$el.width() || 1024;
      this.$el.width(width);
      this.$el.css({ left: 0 });

      dispatcher.off('show:existent:prevous:month');
      dispatcher.on('show:existent:prevous:month', function (months) {
        this.moveLeft(months);
      }, this);

      dispatcher.off('show:existent:next:month');
      dispatcher.on('show:existent:next:month', function (months) {
        this.moveRight(months);
      }, this);
    },
    onAddChild: function (childView) {
      var width = this.$el.width() + childView.$el.outerWidth();
      var that = this;
      this.$el.width(width);

      this.options.childViewSize = childView.$el.outerWidth();
      switch (childView.model.get('action')) {
        case 'previous':
          var left = parseFloat(this.$el.css('left'));
          this.$el.css({ left: (left - childView.$el.outerWidth()) });

          if (!_.isUndefined(childView.model.get('last'))) {
            // show the added project
            dispatcher.trigger('slide:previous:month', Math.abs(parseFloat(this.$el.css('left'))));

            this.$el.animate({ left: '+=' + Math.abs(parseFloat(this.$el.css('left'))) }, 500, function () {
              childView.model.unset('last');
              dispatcher.trigger('slide:animate:end');
              $('[data-region="previousMonthLoading"]').hide();
              $('[data-action="previousMonth"]').removeAttr('style');
            });
          }

          // add project data
          dispatcher.trigger('show:previous:month', childView.model.get('first_day'));
          break;
        case 'next':
          this.options.slideWidth = this.options.slideWidth + this.options.childViewSize;
          if (!_.isUndefined(childView.model.get('last'))) {
            dispatcher.trigger('slide:next:month', this.options.slideWidth);

            this.$el.animate({ left: '-=' + this.options.slideWidth }, 500, function () {
              that.options.slideWidth = 0;
              childView.model.unset('last');
              dispatcher.trigger('slide:animate:end');
              $('[data-region="nextMonthLoading"]').hide();
              $('[data-action="nextMonth"]').removeAttr('style');
            });
          }
          // add project data
          dispatcher.trigger('show:next:month', childView.model.get('first_day'));
          break;
      }
    },
    moveLeft: function (months) {
      var width = this.options.childViewSize * months;
      this.$el.animate({ left: '+=' + width }, 500, function () {
        dispatcher.trigger('slide:animate:end');
      });
    },
    moveRight: function (months) {
      var width = this.options.childViewSize * months;
      this.$el.animate({ left: '-=' + width }, 500, function () {
        dispatcher.trigger('slide:animate:end');
      });
    },
  });

  // projects
  this.ProjectCharterProjectTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'clear col_16 projectProgramWrapper no_padding left',
    template: '#projectCharterProjectTemplate',
    ui: {
      monthsRegeion: '[data-region="months"]',

      employeesRegeion: '[data-region="projectEmployee"]',
      showProjectEmployeeEl: '[data-action="showProjectEmployee"]',
    },
    events: {
      'click @ui.showProjectEmployeeEl': 'showProjectEmployee',
    },
    initialize: function (options) {
      this.options = options;

      // MONTHS COLLECTION
      var projectMonths = new ProjectCharterApp.CalendarProjectMonthsCollection(this.model.get('aggregate'));
      this.options.projectMonthsTemplate = new ProjectCharterApp
        .ProjectCharterCalendarProjectMonthsCollectionView({
          collection: projectMonths,
          childViewOptions: {
            color: this.model.get('color'),
            probability: this.model.get('probability'),
            monthNumber: this.options.monthNumber,
            monthSize: 'big',
          },
        });

      var projectEmployee = new ProjectCharterApp.CalendarProjectEmployeesCollection(this.model.get('details'));
      this.options.projectEmployeesTemplate = new ProjectCharterApp
        .ProjectCharterCalendarProjectEmpoyeesCollectionView({
          collection: projectEmployee,
          childViewOptions: {
            projectId: this.model.get('id'),
            color: this.model.get('color'),
            probability: this.model.get('probability'),
            monthNumber: this.options.monthNumber,
          },
        });

      // EVENTS
      var projId = this.model.get('id');
      dispatcher.on('show:next:month', function (date) {
        // aggiungo il modello alla collection
        var loadingNextMonth = new ProjectCharterApp.ProjectMonthModel();
        loadingNextMonth.set({
          loading: true,
          action: 'next',
        });
        projectMonths.add(loadingNextMonth);

        ProjectCharterApp.addProjectMonth(date, loadingNextMonth, projId, 'next');
      }, projectMonths, projId);

      dispatcher.on('show:previous:month', function (date) {
        // aggiungo il modello alla collection
        var loadingPreviousMonth = new ProjectCharterApp.ProjectMonthModel();
        loadingPreviousMonth.set({
          loading: true,
          action: 'previous',
        });
        projectMonths.add(loadingPreviousMonth, { at: 0 });

        ProjectCharterApp.addProjectMonth(date, loadingPreviousMonth, projId, 'previous');
      }, projectMonths, projId);
    },
    onRender: function () {
      // render project month plan
      this.ui.monthsRegeion.html(this.options.projectMonthsTemplate.render().$el);
      // render project employee
      this.ui.employeesRegeion.html(this.options.projectEmployeesTemplate.render().$el);
    },
    showProjectEmployee: function (e) {
      e.preventDefault();
      var height = (40 * this.model.get('details').length);
      var action = '+=';

      var $view = this.$el;
      var openTab = $('.charterPlanningVisibleDetail');
      // TODO gestire animazione
      if (openTab.size() > 0) {
        openTab.animate({ height: '-=' + (openTab.height() - 50) }, 500, function () {
          if ($view.hasClass('charterPlanningVisibleDetail')) {
            $('.charterPlanningVisibleDetail').removeClass('charterPlanningVisibleDetail');
            $('[data-region="hideNoActiveProject"]').removeClass('active');
          } else {
            $('.charterPlanningVisibleDetail').removeClass('charterPlanningVisibleDetail');
            $view.addClass('charterPlanningVisibleDetail');

            $('[data-region="hideNoActiveProject"]').addClass('active');
            $view.animate({ height: action + height }, 500, function () {

            });
          }
        });
      } else {
        this.$el.addClass('charterPlanningVisibleDetail');
        $('[data-region="hideNoActiveProject"]').addClass('active');

        this.$el.animate({ height: action + height }, 500, function () {

        });
      }
    },
  });
  this.ProjectCharterCalendarProjectsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear col_16 no_padding left',
    childView: ProjectCharterApp.ProjectCharterProjectTemplateView,
  });

  // projects EMPLOYEE
  this.ProjectCharterProjectEmployeeTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'clear col_16 projectProgramEmployeeWrapper no_padding left',
    template: '#projectCharterProjectEmployeeTemplate',
    ui: {
      monthsRegeion: '[data-region="months"]',
    },
    initialize: function (options) {
      this.options = options;

      // MONTHS COLLECTION
      var projectMonths = new ProjectCharterApp
        .CalendarProjectMonthsCollection(this.model.get('months'));
      this.options.projectMonthsTemplate = new ProjectCharterApp
        .ProjectCharterCalendarProjectMonthsCollectionView({
          collection: projectMonths,
          childViewOptions: {
            color: this.options.color,
            probability: this.options.probability,
            monthNumber: this.options.monthNumber,
            monthSize: 'small',
          },
        });

      // EVENTS
      var projId = this.options.projectId;
      var empId = this.model.get('id');

      dispatcher.on('show:next:month', function (date) {
        // aggiungo il modello alla collection
        var loadingNextMonth = new ProjectCharterApp.ProjectMonthModel();
        loadingNextMonth.set({
          loading: true,
          action: 'next',
        });
        projectMonths.add(loadingNextMonth);

        ProjectCharterApp.addProjectMonth(date, loadingNextMonth, projId, 'next', empId);
      }, projectMonths, projId, empId);

      dispatcher.on('show:previous:month', function (date) {
        // aggiungo il modello alla collection
        var loadingPreviousMonth = new ProjectCharterApp.ProjectMonthModel();
        loadingPreviousMonth.set({
          loading: true,
          action: 'previous',
        });
        projectMonths.add(loadingPreviousMonth, { at: 0 });

        ProjectCharterApp.addProjectMonth(date, loadingPreviousMonth, projId, 'previous', empId);
      }, projectMonths, projId, empId);
    },
    onRender: function () {
      // render project month plan
      this.ui.monthsRegeion.html(this.options.projectMonthsTemplate.render().$el);
    },
  });
  this.ProjectCharterCalendarProjectEmpoyeesCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear col_16 no_padding left',
    childView: ProjectCharterApp.ProjectCharterProjectEmployeeTemplateView,
  });

  // project months
  this.ProjectCharterProjectMonthTemplateView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'left',
    _template: '#projectCharterProjectMonthTemplate',
    _loadingTemp: '#projectCharterProjectMonthLoadingTemplate',
    modelEvents: {
      'model:changed': 'modelChanged',
    },
    initialize: function (options) {
      this.options = options;
      // console.log('this.options', this.options);
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var model = serialized_model;
      var template = !_.isUndefined(model.loading) ? $(that._loadingTemp).html() : $(that._template)
        .html();

      model.monthSize = this.options.monthSize;
      model.color = this.options.color;
      model.probability = this.options.probability;

      return _.template(template)(model);
    },
    onRender: function () {
      // zoom
      switch (this.options.monthNumber) {
        case 4:
          this.$el.addClass('pcMonthHead4');
          break;
        case 8:
          this.$el.addClass('pcMonthHead8');
          break;
        case 12:
          this.$el.addClass('pcMonthHead12');
          break;
      }
    },
    modelChanged: function () {
      this.render();
    },
  });
  this.ProjectCharterCalendarProjectMonthsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear relative no_padding left',
    childView: ProjectCharterApp.ProjectCharterProjectMonthTemplateView,
    initialize: function (options) {
      this.options = options;
      this.options.slideWidth = 0;

      dispatcher.on('show:existent:prevous:month', function (months) {
        this.moveLeft(months);
      }, this);

      dispatcher.on('show:existent:next:month', function (months) {
        this.moveRight(months);
      }, this);
    },
    onRender: function () {
      var width = this.$el.width() || 1024;
      this.$el.width(width);
      this.$el.css({ left: 0 });

      dispatcher.on('slide:previous:month', function () {
        this.options.addedLast = true;
      }, this);

      dispatcher.on('slide:next:month', function () {
        this.options.addedLast = true;
      }, this);
    },
    onAddChild: function (childView) {
      var width = this.$el.width() + childView.$el.outerWidth();
      this.$el.width(width);

      this.options.childViewSize = childView.$el.outerWidth();
      var that = this;
      switch (childView.model.get('action')) {
        case 'previous':

          var left = parseFloat(this.$el.css('left'));
          this.$el.css({ left: (left - childView.$el.outerWidth()) });

          if (this.options.addedLast) {
            this.$el.animate({ left: '+=' + Math.abs(parseFloat(this.$el.css('left'))) }, 500, function () {
              that.options.addedLast = false;
            });
          }

          break;
        case 'next':
          this.options.slideWidth = this.options.slideWidth + this.options.childViewSize;
          if (this.options.addedLast) {
            this.$el.animate({ left: '-=' + this.options.slideWidth }, 500, function () {
              that.options.slideWidth = 0;
              that.options.addedLast = false;
            });
          }
          break;
      }
    },
    moveLeft: function (months) {
      var width = this.options.childViewSize * months;
      this.$el.animate({ left: '+=' + width }, 500, function () {
      });
    },
    moveRight: function (months) {
      var width = this.options.childViewSize * months;
      this.$el.animate({ left: '-=' + width }, 500, function () {
      });
    },
  });
});
