const { connect } = require('react-redux');
const Component = require('../../components/work-hour-capacity/WorkHourCapacityEditRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-work-hour-capacity-')),
  waitingForDelete: state.waitingFor.filter((key) => key.startsWith('delete-work-hour-capacity-')),
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) => dispatch(
    actions.deleteWorkHourCapacity(id),
  ),
  showDeleteModal: (workHourCapacity) => dispatch(
    actions.showDeleteModalWorkHourCapacity(workHourCapacity),
  ),

  onChange: (id, workHourCapacity) => dispatch(
    actions.updateWorkHourCapacity(id, workHourCapacity),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
