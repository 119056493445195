module.exports.GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
module.exports.GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

module.exports.GET_KPI_REQUEST = 'GET_KPI_REQUEST';
module.exports.GET_KPI_SUCCESS = 'GET_KPI_SUCCESS';

module.exports.GET_TRENDS_REQUEST = 'GET_TRENDS_REQUEST';
module.exports.GET_TRENDS_SUCCESS = 'GET_TRENDS_SUCCESS';

module.exports.UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';
