const { connect } = require('react-redux');
const Component = require('../../components/modal/ApprovedNotificationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  recipients: state.contacts,
  isSending: state.waitingFor.filter((key) => key === 'approve-order').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  approveOrder: (order, recipientId, message) => dispatch(actions
    .approveOrder(order, recipientId, message)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
