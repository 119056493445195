const React = require('react');
const SearchAndSelectModal = require('../../../SearchAndSelectModal/SearchAndSelectModal.react');
const Option = require('../../../SearchAndSelectModal/SearchAndSelectModalOption.react');

module.exports = class JobTitleInsertModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobTitles: this.props.availableJobTitles,
      checked: [],
    };

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onInsertClick = this.onInsertClick.bind(this);
    this.filterJobTitles = this.filterJobTitles.bind(this);
  }

  onCheckboxChange(e) {
    const changedJobTitleId = parseInt(e.target.value);
    const newChecked = [...this.state.checked];

    if (this.state.checked.includes(changedJobTitleId)) {
      this.setState({ checked: newChecked.filter((id) => id !== changedJobTitleId) });
    } else {
      this.setState({ checked: newChecked.concat(changedJobTitleId) });
    }
  }

  onInsertClick() {
    this.state.checked.forEach((id) => this.props.insertJobTitle(this.props.taskId, id));
  }

  /**
   * Return true if given jobTitleId is currently checked.
   * @param jobTitleId
   * @return {boolean}
   */
  isChecked(jobTitleId) {
    return this.state.checked.includes(jobTitleId);
  }

  /**
   * Return true if given jobTitleId should be disabled because it is already selected for this task.
   * @param jobTitleId
   * @return {boolean}
   */
  isDisabled(jobTitleId) {
    return this.props.taskJobTitles
      ? this.props.taskJobTitles.some((jobTitle) => jobTitle.budget_job_title.id === jobTitleId)
      : false;
  }

  /**
   * Return true if no job title is currently selected.
   * @return {boolean}
   */
  emptySelection() {
    return this.state.checked.length === 0;
  }

  /**
   * Return true if there are no job titles.
   * @return {boolean}
   */
  emptyJobTitles() {
    return this.state.jobTitles.length === 0;
  }

  filterJobTitles(keyword = '') {
    const results = this.props.availableJobTitles
      .filter((jobTitle) => jobTitle.name.toLowerCase().includes(keyword.toLowerCase()));

    this.setState({ jobTitles: results });
  }

  renderJobTitles(levelId) {
    return this.state.jobTitles
      .filter((jobTitle) => jobTitle.job_title_level.id === levelId)
      .map((jobTitle) => {
        const checked = this.isChecked(jobTitle.id);
        const disabled = this.isDisabled(jobTitle.id);

        return (
          <Option
            name={`level-${levelId}-job-title`}
            type={Option.TYPE_CHECKBOX}
            checked={checked}
            key={jobTitle.id}
            id={`job-title-${jobTitle.id}`}
            value={jobTitle.id}
            onChange={this.onCheckboxChange}
            label={jobTitle.name}
            disabled={disabled} />
        );
      });
  }

  renderLevels() {
    if (this.emptyJobTitles()) {
      return null;
    }
    return this.props.levels.map((level) => {
      const jobTitles = this.renderJobTitles(level.level.id);
      if (jobTitles.length === 0) {
        // Do not render level with no job titles
        return null;
      }
      return (
        <fieldset key={level.id} disabled={this.props.isSaving} className="wethod-budget-modal-job-title-insert__level">
          <legend className="wethod-budget-modal-job-title-insert__level-name">{level.level_name}</legend>
          {jobTitles}
        </fieldset>
      );
    });
  }

  render() {
    return (
      <SearchAndSelectModal hasNoOptions={this.emptyJobTitles()}
        loadOptions={this.filterJobTitles}
        isSaving={this.props.isSaving}
        isLoading={false}
        title="Insert job title"
        closeModal={this.props.closeModal}
        isEmptySelection={this.emptySelection()}
        onSaveClick={this.onInsertClick}
        saveButtonLabel="Insert"
        searchPlaceholder="Search for job titles"
        emptyMessage="No job titles found">
        {this.renderLevels()}
      </SearchAndSelectModal>
    );
  }
};
