'use strict';

/* eslint-disable no-underscore-dangle,camelcase,default-case */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  this.WidgetAlertItemView = Marionette.ItemView.extend({
    tagName: 'li',
    _template: '#widgetAlertTemplate',
    ui: {
      statusEl: '[data-region="statusInfo"]',
      actionEl: '[data-action="goToModule"]',
    },
    events: {
      'click @ui.actionEl': 'goToModule',
      'touchstart @ui.actionEl': 'goToModule',
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var link = '';
      var model = serialized_model;
      model.supportAlertLink = Wethod.getAlertSupportLink(model.alert.type);
      switch (model.alert.action) {
        case 'pipeline':
          link = '#pipeline/projects?id=' + model.project.id;
          break;
        case 'pipeline-search':
          link = '#pipeline/projects?job-order=' + model.project.job_order;
          break;
        case 'planning':
          link = !_.isNull(model.project) ? '#planning/people?project_id=' + model.project.id : '#planning/people';
          break;
        case 'project-status':
          link = !_.isNull(model.project) ? '#project-status/' + model.project.id : '#project-status';
          break;
        case 'timesheet':
          link = !_.isUndefined(this.options.noLink) ? 'noLink' : '#timesheet';
          break;
        case 'budget':
          link = '#pipeline/budget/' + model.project.id;
          break;
        case 'report-details':
          link = '#project/' + model.project.id + '/report';
          break;
        case 'basket':
          link = '#pipeline/basket/' + model.opportunity.id;
          break;
        case 'orders':
          link = '#finance/order';
          break;
        case 'invoices':
          link = '#finance/invoice';
          break;
        case 'production-plan':
          link = '#planning/production-plan?project_id=' + model.project.id;
          break;
        case 'budget-intercompany':
          link = '#pipeline/intercompany/' + model.intercompany_token;
          break;
        case 'canvas':
          link = '#project/' + model.project.id + '/canvas';
          break;
      }

      model.link = link;
      return _.template(template)(model);
    },
    onRender: function () {
      this.ui.statusEl.css('background', this.model.get('alert').color);
    },
    goToModule: function (e) {
      e.preventDefault();
    },
  });

  this.WidgetAlertEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'noAlert',
    template: '#widgetAlertEmptyTemplate',
  });

  this.WidgetAlertsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'alertsList',
    childView: DashboardApp.WidgetAlertItemView,
    emptyView: DashboardApp.WidgetAlertEmptyView,
  });

  this.WidgetAlertsCompositeView = Marionette.CompositeView.extend({
    template: '#widgetAlertsCompositeTemplate',
    childViewContainer: '[data-region="alertsList"]',
    childView: DashboardApp.WidgetAlertItemView,
    emptyView: DashboardApp.WidgetAlertEmptyView,
  });
});
