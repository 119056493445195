const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Kanban/Kanban.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit,
  isWaiting: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  sortCards: (boardId, cards) => dispatch(actions.updateKanbanItemsSort(boardId, cards)),
  updateListSort: (boardId, listId, values) => dispatch(actions
    .updateStatusAvailableValuesSort(boardId, listId, values)),
  saveListsSort: (boardId, listId, values) => dispatch(actions
    .saveStatusAvailableValuesSort(boardId, listId, values)),
  updateCardList: (boardId, itemId, attributeId, value, sortedItems) => dispatch(
    actions.updateKanbanItemList(boardId, itemId, attributeId, value, sortedItems),
  ),
  editItemValues: (boardId, itemId, itemValues) => dispatch(actions
    .editBoardItemValues(boardId, itemId, itemValues)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
