const { connect } = require('react-redux');
const Component = require('../components/Order.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  contacts: state.contacts,
  isWaitingToSend: state.waitingFor.filter((key) => key === 'send-order').length > 0,
  ordersBeingSent: state.waitingFor.filter((key) => key.startsWith('send:')),
  ordersBeingSetAsPaid: state.waitingFor.filter((key) => key.startsWith('set-paid:')),
  ordersBeingApproved: state.waitingFor.filter((key) => key.startsWith('approve:')),
});

const mapDispatchToProps = (dispatch) => ({
  showSidebar: (id) => dispatch(actions.showSidebar(id)),
  showEditNotesModal: (order) => dispatch(actions.showModal('edit-notes', order)),
  showApprovePaymentModal: (order) => dispatch(actions.showModal('approve-payment', order)),
  showSendOrderModal: (order) => dispatch(actions.showModal('send-order', order)),
  sendOrder: (order, contactId) => dispatch(actions.sendOrder(order, contactId)),
  setOrderAsPaid: (order) => dispatch(actions.setPaidOrder(order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
