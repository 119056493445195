const React = require('react');
const Event = require('./FocusedEvent/Event.react');
const HeatmapEvent = require('./FocusedEvent/HeatmapEvent.react');
const FollowUp = require('./FocusedEvent/FollowUp.react');

module.exports = class FocusedEvent extends React.Component {
  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props.event);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props.event);
  }

  markEventAsDone() {
    const event = {
      ...this.props.event,
      confirmed: true,
      type: this.props.event.type.id,
      schedule: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    delete event.followed_by;
    delete event.created_by;
    delete event.isFollowUp;
    delete event.isHeatmapEvent;

    this.props.save(event);
  }

  render() {
    if (this.props.event.isHeatmapEvent) {
      return <HeatmapEvent {...this.props.event} />;
    } if (this.props.event.isFollowUp) {
      return (
        <FollowUp {...this.props.event}
          events={this.props.events}
          save={this.markEventAsDone.bind(this)}
          handleDeleteClick={this.handleDeleteClick.bind(this)}
          handleEditClick={this.handleEditClick.bind(this)} />
      );
    }
    return (
      <Event {...this.props.event}
        save={this.markEventAsDone.bind(this)}
        handleDeleteClick={this.handleDeleteClick.bind(this)}
        handleEditClick={this.handleEditClick.bind(this)} />
    );
  }
};
