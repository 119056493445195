/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return,
 react/no-access-state-in-setstate */
const React = require('react');
const AddBox = require('../../finance/clients/overview/components/FormBox.react');
const SearchSelect = require('../../../../../common/react/material-input/SidebarSearchSelectAddButton.react');
const TextInput = require('../../../../../common/react/material-input/SidebarTextInput.react');

module.exports = class CompanySearchSelectCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.props.value,
      newItem: null,
      errors: [],
    };
  }

  getNewItem(oldItems) {
    return oldItems
      .find((item) => item.name === this.state.selectedItem.name
        && item.acronym === this.state.selectedItem.acronym);
  }

  hasNewItem(oldItems, newItems) {
    return (oldItems.length !== newItems.length && !this.state.selectedItem.id);
  }

  componentWillReceiveProps(nextProp) {
    if (this.hasNewItem(this.props.items, nextProp.items)) {
      const newItem = this.getNewItem(nextProp.items);
      this.setState({ selectedItem: newItem });
      if (this.props.onChange) {
        this.props.onChange(this.props.name, newItem);
      }
    }
    return true;
  }

  handleNewItemCreation(value) {
    this.setState({ newItem: { name: value } });
    if (this.props.required && this.props.onValidate) {
      this.props.onValidate(this.props.name, ['required']);
    }
  }

  handleCancel() {
    this.setState({
      newItem: null,
      errors: [],
    });

    if (this.props.onValidate) {
      this.props.onValidate(this.props.name, []);
    }
  }

  canSave() {
    return this.state.errors.length === 0;
  }

  handleSave() {
    if (this.canSave()) {
      const { newItem } = this.state;
      this.setState({
        selectedItem: newItem,
        newItem: null,
      });
      if (this.props.onSave) {
        this.props.onSave(newItem);
      }
      if (this.props.onChange) {
        this.props.onChange(this.props.name, newItem);
      }
      if (this.props.onValidate) {
        this.props.onValidate(this.props.name, []);
      }
    }
  }

  handleChange(name, value) {
    const newItem = {
      ...this.state.newItem,
      [name]: value,
    };

    this.setState({
      newItem,
    });
  }

  handleErrors(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  getBody() {
    if (this.state.newItem) {
      return (
        <AddBox icon={this.props.children}
          title="Add new company"
          onCancel={this.handleCancel.bind(this)}
          onSave={this.handleSave.bind(this)}
          canSave={this.canSave()}>
          <TextInput name="name"
            value={this.state.newItem ? this.state.newItem.name : ''}
            placeholder="Company"
            onChange={this.handleChange.bind(this)}
            onValidate={this.handleErrors.bind(this)}
            required />
          <div className="clients__sidebar--multiple-input-row">
            <TextInput name="acronym"
              value=""
              placeholder="Acronym"
              onChange={this.handleChange.bind(this)}
              onValidate={this.handleErrors.bind(this)}
              required />
            <div className="clients__sidebar--acronym clients__sidebar--multiple-input-element" />
          </div>
        </AddBox>
      );
    }
    return (
      <SearchSelect name={this.props.name}
        value={this.state.selectedItem}
        placeholder={this.props.placeholder}
        items={this.props.items}
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        onCreateClick={this.handleNewItemCreation.bind(this)}
        required={this.props.required}>
        {this.props.children}
      </SearchSelect>
    );
  }

  render() {
    return (
      <div>
        {this.getBody()}
      </div>
    );
  }
};
