const React = require('react');
const Summary = require('./Summary/SummaryFactoryColumn.react');
const Column = require('./Columns/Column.react');
const Header = require('../../containers/Calculator/Header/Header');
const RequestManager = require('../../containers/RequestManager');
const TotalSummary = require('../../containers/Calculator/Summary/TotalSummary');
const SummaryCalculator = require('../../containers/Calculator/Summary/Summary');
const AreaContainer = require('./Summary/SummaryAreaContainer.react');
const Area = require('../../containers/Calculator/Area/AreaFactory');
const constants = require('../../constants');

module.exports = class CalculatorNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShadows: false,
    };
    this.setShowShadows = this.setShowShadows.bind(this);
  }

  setShowShadows(showShadows) {
    this.setState({ showShadows });
  }

  render() {
    const summaryListLeft = this.props.summaries.map((summary) => (
      <Summary
        showShadows={this.state.showShadows}
        column="left"
        key={summary.name}
        previous={summary.was}
        id={summary.id}
        editableBudget={this.props.budgetIsEditable}
        name={summary.name}
        cost={summary.total.cost}
        price={summary.total.price}
        markup={summary.total.markup}
        externalCosts={summary.total.external_cost}
        levels={summary.total.levels}
        days={summary.total.days}
        areas={summary.areas} />
    ));
    const totalSummaryCenter = this.props.summaries.filter((summary) => summary.id.toLowerCase() === 'total').map((summary) => (
      <RequestManager key={summary.name}>
        <TotalSummary
          column="center"
          previous={summary.was}
          id={summary.id}
          editableBudget={this.props.budgetIsEditable}
          name={summary.name}
          cost={summary.total.cost}
          price={summary.total.price}
          markup={summary.total.markup}
          externalCosts={summary.total.external_cost}
          levels={summary.total.levels}
          days={summary.total.days}
          areas={summary.areas} />
      </RequestManager>
    ));
    const areaContingencyDiscountCenter = this.props.summaries
      .filter((summary) => summary.id.toLowerCase() === 'total')
      .map((summary) => summary.areas
        .filter((area) => area.type === constants.AREA_TYPE_CONTINGENCY_DISCOUNT)
        .map((area) => (
          <RequestManager key={area.id}>
            <Area
              horizontalScroll
              column="center"
              horizontalScrollClass="wethod-budget-area__content--horizontal-scroll"
              showModal={this.props.showModal}
              enabled={area.enabled}
              order={area.sort}
              id={area.id}
              days={area.total.days}
              editableBudget={this.props.budgetIsEditable}
              name={area.name}
              previous={area.was}
              tasks={area.tasks}
              markup={area.total.markup}
              externalCosts={area.total.external_cost}
              cost={area.total.cost}
              price={area.total.price}
              levels={area.total.levels}
              type={area.type}
              priceLists={area.total.price_lists} />
          </RequestManager>
        )));
    const summaryListCenter = this.props.summaries.filter((summary) => summary.id.toLowerCase() !== 'total').map((summary) => (
      <RequestManager key={summary.name}>
        <SummaryCalculator
          column="center"
          withAreaCreator={summary.id.toLowerCase() === 'user-areas'}
          previous={summary.was}
          id={summary.id}
          editableBudget={this.props.budgetIsEditable}
          name={summary.name}
          cost={summary.total.cost}
          price={summary.total.price}
          markup={summary.total.markup}
          externalCosts={summary.total.external_cost}
          levels={summary.total.levels}
          days={summary.total.days}
          areas={summary.areas} />
      </RequestManager>
    ));
    const summaryListRight = this.props.summaries.map((summary) => (
      <Summary
        showShadows={this.state.showShadows}
        column="right"
        key={summary.name}
        previous={summary.was}
        id={summary.id}
        editableBudget={this.props.budgetIsEditable}
        name={summary.name}
        cost={summary.total.cost}
        price={summary.total.price}
        markup={summary.total.markup}
        externalCosts={summary.total.external_cost}
        levels={summary.total.levels}
        days={summary.total.days}
        areas={summary.areas} />
    ));
    return (
      <div className="wethod-budget-calculator">
        <div className="wethod-budget-calculator__content wethod-budget-calculator__grid">
          <Column className="left">
            <Header
              column="left"
              levels={this.props.levels}
              budgetLevels={this.props.budgetLevels}
              pricelistIsEditable={this.props.pricelistIsEditable}
              pricelist={this.props.pricelist}
              showShadows={this.state.showShadows} />
            {summaryListLeft}
          </Column>
          <Column className="center">
            <div className="wethod-budget-calculator__sticky">
              <Header
                column="center"
                levels={this.props.levels}
                budgetLevels={this.props.budgetLevels}
                pricelistIsEditable={this.props.pricelistIsEditable}
                pricelist={this.props.pricelist}
                setShowShadows={this.setShowShadows} />
              {totalSummaryCenter}
            </div>
            <AreaContainer>
              {areaContingencyDiscountCenter}
            </AreaContainer>
            {summaryListCenter}
          </Column>
          <Column className="right">
            <Header
              column="right"
              levels={this.props.levels}
              budgetLevels={this.props.budgetLevels}
              pricelistIsEditable={this.props.pricelistIsEditable}
              pricelist={this.props.pricelist}
              showShadows={this.state.showShadows} />
            {summaryListRight}
          </Column>
        </div>
      </div>
    );
  }
};
