module.exports.UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
module.exports.UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

module.exports.GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
module.exports.GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';

module.exports.DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
module.exports.DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

module.exports.REPLACE_COMPANY_REQUEST = 'REPLACE_COMPANY_REQUEST';

module.exports.UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
module.exports.UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';

module.exports.ADD_CONTACT_REQUEST = 'ADD_CONTACT_REQUEST';

module.exports.SAVE_CONTACT_REQUEST = 'SAVE_CONTACT_REQUEST';
module.exports.SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';

module.exports.DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
module.exports.DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

module.exports.GROUP_SAVE_REQUEST = 'GROUP_SAVE_REQUEST';
module.exports.GROUP_SAVE_SUCCESS = 'GROUP_SAVE_SUCCESS';

module.exports.NEW_COMPANY_SAVE_REQUEST = 'NEW_COMPANY_SAVE_REQUEST';
module.exports.NEW_COMPANY_SAVE_SUCCESS = 'NEW_COMPANY_SAVE_SUCCESS';

module.exports.UPDATE_CONTACT_PHONE_SUCCESS = 'UPDATE_CONTACT_PHONE_SUCCESS';
module.exports.UPDATE_CONTACT_PHONE_REQUEST = 'UPDATE_CONTACT_PHONE_REQUEST';

module.exports.SHOW_COMPANY_SIDEBAR = 'SHOW_COMPANY_SIDEBAR';
module.exports.HIDE_COMPANY_SIDEBAR = 'HIDE_COMPANY_SIDEBAR';

module.exports.SHOW_CONTACT_SIDEBAR = 'SHOW_CONTACT_SIDEBAR';
module.exports.HIDE_CONTACT_SIDEBAR = 'HIDE_CONTACT_SIDEBAR';
module.exports.GET_REVENUES_REQUEST = 'GET_REVENUES_REQUEST';
module.exports.GET_REVENUES_SUCCESS = 'GET_REVENUES_SUCCESS';
module.exports.GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
module.exports.GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

module.exports.SHOW_DELETE_CONTACT_MODAL = 'SHOW_DELETE_CONTACT_MODAL';
module.exports.SHOW_DELETE_COMPANY_MODAL = 'SHOW_DELETE_COMPANY_MODAL';
module.exports.SHOW_DELETE_COMPANY_FAILURE_MODAL = 'SHOW_DELETE_COMPANY_FAILURE_MODAL';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.GET_TRENDS_REQUEST = 'GET_TRENDS_REQUEST';
module.exports.GET_TRENDS_SUCCESS = 'GET_TRENDS_SUCCESS';

module.exports.GET_REVENUES_TARGETS_REQUEST = 'GET_REVENUES_TARGETS_REQUEST';
module.exports.GET_REVENUES_TARGETS_SUCCESS = 'GET_REVENUES_TARGETS_SUCCESS';

module.exports.UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';
