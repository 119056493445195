/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind */
const React = require('react');
const MilestoneElement = require('./MilestoneElement.react');
const Loader = require('../../../../../../../../../../common/react/Loader/Loader.react');

module.exports = class MilestoneListMenu extends React.Component {
  handleMilestoneClick(id) {
    const selectedMilestone = this.props.eventTypes.find((milestone) => milestone.id === id);

    this.props.onChange(selectedMilestone.name);
  }

  getMilestoneList() {
    if (this.props.isLoading) {
      return (
        <div
          style={{
            position: 'relative',
            top: '10px',
            left: '4px',
          }}>
          <Loader />
        </div>
      );
    }
    if (this.props.eventTypes) {
      return (
        this.props.eventTypes.map((milestone) => (
          <MilestoneElement key={milestone.id}
            onClick={this.handleMilestoneClick.bind(this)}
            milestone={milestone} />
        ))
      );
    }
  }

  render() {
    return (
      <div>
        <div className="project-canvas-menu project-canvas-menu-milestone__list">
          {this.getMilestoneList()}
        </div>
      </div>
    );
  }
};
