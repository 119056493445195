const RequestService = {
  /**
   * Return if waitingRequests contains getItems requests
   * @param {string[]} waitingRequests
   * @return {boolean}
   */
  isWaitingForItems(waitingRequests) {
    const getItemsRegEx = /^get-items-\d+$/;

    return waitingRequests.some((requestKey) => getItemsRegEx.test(requestKey));
  },
  /**
   * Return if waitingRequests contains getItems with given requestId.
   * @param {string[]} waitingRequests
   * @param {string} requestId
   * @return {*}
   */
  isWaitingForItemsWithId(waitingRequests, requestId) {
    const key = `get-items-${requestId}`;
    return waitingRequests.includes(key);
  },
};

module.exports = RequestService;
