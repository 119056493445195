const { connect } = require('react-redux');
const Component = require('../components/CurrencyManager.react');

const mapStateToProps = (state) => ({
  currencies: state.currencies,
  isCurrencyEnabled: state.info.currency_enabled,
  budgetRate: state.info.exchange_rate,
});

module.exports = connect(mapStateToProps)(Component);
