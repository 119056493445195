/* eslint-disable react/prop-types,react/no-did-update-set-state,react/sort-comp,
 jsx-a11y/alt-text */
const React = require('react');

module.exports = class ProfilePic extends React.Component {
  constructor(props) {
    super(props);

    this.state = { src: null };

    // Preload image and show it only when completely loaded
    this.shadowImage = new Image();
    this.shadowImage.onload = this.onImageLoad.bind(this);
    if (this.props.pic) this.shadowImage.src = this.props.pic;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pic !== this.props.pic) {
      if (this.props.pic) {
        this.shadowImage.src = this.props.pic;
      } else {
        this.setState({ src: null });
      }
    }
  }

  componentWillUnmount() {
    this.shadowImage.onload = null;
  }

  onImageLoad() {
    this.setState({ src: this.props.pic });
  }

  getPic() {
    if (!this.state.src) return <div className="wethod-profile-pic__placeholder">{this.props.name}</div>;
    return <div className="wethod-profile-pic__image-container"><img src={this.state.src} alt={this.props.name} /></div>;
  }

  render() {
    return (
      <div className="wethod-profile-pic">
        <div className="wethod-profile-pic__image">{this.getPic()}</div>
      </div>
    );
  }
};
