const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../modal2/Modal.react');
const Button = require('../../../Button/RoundedButton.react');
const TextArea = require('../../../inputs/TextArea/BasicTextArea/BasicTextArea.react');
const Select2 = require('../../../Select2/Select2.react');
const LoadingItem = require('../../../Select2/LoadingItem.react');
const ShowIf = require('../../../ShowIf/ShowIf.react');

class ShareSearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      recipient: null,
      errors: [],
      feedback: '',
    };

    this.shareableLink = window.location.href;

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onRecipientChange = this.onRecipientChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
  }

  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  onRecipientChange(name, value) {
    this.setState({ recipient: value });
  }

  onValidate(name, errors) {
    this.setState({ errors });
  }

  onSendClick() {
    this.props.onSendClick(this.state.message, this.state.recipient.id);

    this.setState({ feedback: 'Sent' });

    this.setAutoClose();
  }

  onCopyClick() {
    this.textAreaRef.select();
    document.execCommand('copy');

    this.setState({ feedback: 'Copied' });

    this.setAutoClose();
  }

  setAutoClose() {
    setTimeout(() => {
      this.props.onCloseClick();
    }, 1000);
  }

  canSend() {
    return this.state.errors.length === 0;
  }

  render() {
    return (
      <Modal title="Share search" onCancelClick={this.props.onCloseClick}>
        <div>
          <textarea
            className="wethod-table__search-copyable-area"
            readOnly
            ref={(ref) => this.textAreaRef = ref}
            value={this.shareableLink} />
          <p>
            <button type="button"
              className="wethod-table__search-copy-button"
              onClick={this.onCopyClick}>Copy to clipboard
            </button>
            or type in your colleague’s name and select &quot;Send&quot;.
          </p>
          <TextArea name="message"
            onChange={this.onMessageChange}
            placeholder="Type a note"
            rows={5} />
          <Select2 onChange={this.onRecipientChange}
            current={this.state.recipient}
            empty={<ShowIf condition={this.props.isLoadingRecipients}><LoadingItem /></ShowIf>}
            constraints="required"
            name="recipient"
            placeholder="Select colleague"
            onValidate={this.onValidate}
            items={this.props.recipients}
            param={this.props.selectParam}
            onFilter={this.props.onRecipientFilter} />
        </div>
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onCloseClick}>Cancel</Button>
          <Button onClick={this.onSendClick} disabled={!this.canSend()}>Send</Button>
        </div>
        <div className="wethod-modal2__feedback">
          <ShowIf condition={this.state.feedback !== ''}>
            <span>{this.state.feedback}</span>
          </ShowIf>
        </div>
      </Modal>
    );
  }
}

ShareSearchModal.defaultProps = {
  selectParam: 'name',
  isLoadingRecipients: false,
};

ShareSearchModal.propTypes = {
  /**
   * The attribute to use as recipient name. By default is "name" but you can change it if
   * your recipients do not have a name attribute.
   */
  selectParam: PropTypes.string,
  /**
   * If the component must wait for new recipients to load.
   */
  isLoadingRecipients: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  /**
   * Function to call when "send" button is clicked.
   *
   * @param message {string}
   * @param recipient {integer}
   */
  onSendClick: PropTypes.func.isRequired,
  /**
   * Function gto call when recipent keyword changes.
   *
   * @param name {string}
   * @param keyword {string} string to use for filter
   */
  onRecipientFilter: PropTypes.func.isRequired,
};

module.exports = ShareSearchModal;
