/* eslint-disable default-case */
const React = require('react');

module.exports = class CanvasLog extends React.Component {
  constructor(props) {
    super(props);

    this.messages = {
      board_created: 'New board "{board_name}" added',
      brief_created: "Project's brief has been added",
      concept_created: "Project's concept has been added",
    };
  }

  getMessage() {
    let message = this.messages[this.props.type];
    switch (this.props.type) {
      case 'board_created':
        message = message.replace('{board_name}', this.props.event.name);
    }
    return message;
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    return momentDate.format('dddd, D.M.YYYY at HH:mm');
  }

  getCreation() {
    const date = this.getCreationDate();
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  render() {
    return (
      <div className="timeline-event-log">
        <div className="timeline-event-past__type-logo-container">
          <div className="wethod-icon-canvas wethod-icon-canvas--black" />
        </div>
        <div className="timeline-event-log__type">
          <div className="timeline-event-log__type-name">{this.getMessage()}</div>
        </div>
        <div className="timeline-event-log__creation">
          {this.getCreation()}
        </div>
        <div className="timeline-event-log__creation">
          Check it out on
          {' '}
          <a href={`#project/${this.props.project.id}/canvas`}>project's canvas</a>
        </div>
      </div>
    );
  }
};
