const { connect } = require('react-redux');
const TypeSelect = require('../components/MobileTypeSelect.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  items: [
    {
      id: 1,
      key: 'pm',
      name: 'As PM',
    }, {
      id: 2,
      key: 'account',
      name: 'As Account',
    }, {
      id: 3,
      key: 'all',
      name: 'All',
    },
  ],
  current: state.ownership,
  date: state.date,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (date, ownership) => dispatch(actions.getProjects(date, ownership)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TypeSelect);
