'use strict';

Wethod.module('DataFreezingApp', function (DataFreezing) {
  this.startWithParent = false;

  var API = {
    showDataFreezingEngine: function () {
      DataFreezing.DataFreezing.Controller.showDataFreezingEngine();
    },
  };

  DataFreezing.on('start', function (options) {
    API[options.foo]();
  });
});
