const React = require('react');
const ProjectStatusModel = require('../../../../../../models/ProjectStatus');

const PermissionManager = ({
  children,
  editStatusPermission,
  editOtherStatusPermission,
  employeeId,
  projectManagerId,
  accountId = null,
  reasonCannotEdit = null,
  ...rest
}) => {
  const userIsProjectPm = () => employeeId === projectManagerId;

  const userIsProjectAccount = () => employeeId === accountId;

  const userIsProjectOwner = () => userIsProjectPm() || userIsProjectAccount();

  const userHasStatusEditPermissions = () => {
    const canEditBecauseOwner = editStatusPermission && userIsProjectOwner();

    return editOtherStatusPermission || canEditBecauseOwner;
  };

  const missingApprovedBudget = reasonCannotEdit === ProjectStatusModel
    .CANNOT_EDIT_BECAUSE_MISSING_BUDGET;

  const missingBudgetDays = reasonCannotEdit === ProjectStatusModel
    .CANNOT_EDIT_BECAUSE_ZERO_DAYS_BUDGET;

  const statusTimeBased = reasonCannotEdit === ProjectStatusModel
    .CANNOT_EDIT_BECAUSE_TIME_BASED;

  const statusFrozen = reasonCannotEdit === ProjectStatusModel
    .CANNOT_EDIT_BECAUSE_DATA_FROZEN;

  const missingBudgetDaysWithPrevDaysLeft = reasonCannotEdit === ProjectStatusModel
    .CANNOT_EDIT_BECAUSE_ZERO_DAYS_BUDGET_PREV_DAYS_LEFT;

  /**
   * Return true if project status amount (days left or progress) can be edited by the current user.
   * @return {boolean}
   */
  const canEditStatus = userHasStatusEditPermissions()
    && !reasonCannotEdit;

  return React.cloneElement(children, {
    ...rest,
    userHasStatusEditPermissions,
    canEditStatus,
    missingApprovedBudget,
    missingBudgetDays,
    statusTimeBased,
    statusFrozen,
    missingBudgetDaysWithPrevDaysLeft,
  });
};

module.exports = PermissionManager;
