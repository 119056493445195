'use strict';

Wethod.module('FinanceApp', function (FinanceApp) {
  /**
     * Get true if the user can perform the action on a project where he can be pm or account
     *
     * @param action
     * @param isPmOrAccount
     * @returns bool
     */
  this.getPermissionAs = function (action, isPmOrAccount) {
    var authorized = FinanceApp.authorization.get(action);
    var authorizedOther = FinanceApp.authorization.get(action + '_other');

    return (authorized && (isPmOrAccount || authorizedOther));
  };

  /**
     * Get permission on an action
     *
     * @param action
     * @returns bool
     */
  this.getPermission = function (action) {
    return FinanceApp.authorization.get(action);
  };
});
