const React = require('react');
const Levels = require('../Cell/LevelsCell.react');
const Level = require('./HeaderLevel.react');

const HeaderLevels = ({ items, levelsExtended, isHierarchyBudgetFlagEnabled }) => {
  const getProperty = (level, property) => items.find((el) => el.id === level)[property];

  const sortedLevels = levelsExtended.filter((el) => el.is_visible);
  const levels = sortedLevels.map((level) => (
    <Level
      key={level.level.id}
      name={level.level_name}
      cost={getProperty(level.level.id, 'cost')}
      price={getProperty(level.level.id, 'price')}
      isHierarchyBudgetFlagEnabled={isHierarchyBudgetFlagEnabled}
      levelId={level.level.id}>
      {level.level_short_name}
    </Level>
  ));

  return (
    <Levels className="wethod-budget-calculator__header-levels">{levels}</Levels>
  );
};

module.exports = HeaderLevels;
