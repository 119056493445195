const TableColumnList = require('../../../../../common/react/Table2/models/TableColumnList');
const TableColumn = require('../../../../../common/react/Table2/models/TableColumn');
const constants = require('../constants');

class ColumnList extends TableColumnList {
  static showRisk(type) {
    return type === constants.TYPE_PROJECTS;
  }

  constructor(visibleColumns = [], type) {
    const columns = [
      new TableColumn('joc-badge', '', true, false, '4px'),
      new TableColumn('name', 'Name', true, false, '16%'),
      new TableColumn('client', 'Client'),
      new TableColumn('customer', 'Client contact'),
      new TableColumn('pm', 'PM'),
      new TableColumn('account', 'Account'),
      new TableColumn('job-order-category', 'Job order category'),
      new TableColumn('project-type', 'Project type'),
      new TableColumn('job-order', 'Job order'),
      new TableColumn('contract_date', 'Signed on', false, true, '140px'),
      new TableColumn('date_start', 'Start'),
      new TableColumn('duration', 'Months'),
      new TableColumn('due_date', 'Due date', false, true, '140px'),
      new TableColumn('po', 'Client PO'),
      new TableColumn('budget-status', 'Budget'),
      new TableColumn('estimate', 'Value'),
      new TableColumn('margin', 'Margin'),
      new TableColumn('costs', 'Ext. costs %'),
      new TableColumn('costs-value', 'Ext. costs'),
      new TableColumn('probability', 'Prob.'),
      new TableColumn('stage', 'Stage', false, ColumnList.showStage(type)),
      new TableColumn('budget-consumption', 'Budget consumption', false, ColumnList.showRisk(type)),
      new TableColumn('progress', 'Progress', false, ColumnList.showRisk(type)),
      new TableColumn('risk', 'Risk', false, ColumnList.showRisk(type)),
      new TableColumn('timesheet_whitelist', 'Whitelist'),
      new TableColumn('archived-date', 'Archived on', false, true),
      new TableColumn('actions', '', true, false, '54px'),
    ];
    super(columns);

    this.setVisibleList(visibleColumns);
  }

  static showStage(type) {
    return type === constants.TYPE_OPPORTUNITIES;
  }
}

module.exports = ColumnList;
