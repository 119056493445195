/* eslint-disable no-shadow,react/jsx-no-bind,no-use-before-define */
const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');

const withRandomize = (Component) => {
  const HOC = ({ weekNumber, ...props }) => {
    /**
     * Returns the alternative to use, based on weekNumber.
     * @param alternatives
     * @return {*}
     */
    const randomize = (alternatives) => {
      const alternativeIndex = (weekNumber - 1) % alternatives.length;
      const alternative = alternatives[alternativeIndex];

      if (typeof alternative === 'string') {
        return alternative;
      } if (alternative.type) {
        const Component = alternative.type;
        const props = { ...alternative.props, ...alternative.props };

        return <Component {...props} />;
      }
      const Component = alternatives[alternativeIndex];

      return <Component {...props} />;
    };

    return <Component randomize={randomize} {...props} />;
  };
  HOC.propTypes = {
    weekNumber: PropTypes.number.isRequired,
  };
  return connect(mapStateToProps)(HOC);
};

const mapStateToProps = (state) => ({
  weekNumber: state.date.isoWeek(),
});

withRandomize.propTypes = {
  Component: PropTypes.node.isRequired,
};

module.exports = withRandomize;
