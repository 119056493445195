/* eslint-disable react/sort-comp,no-param-reassign,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class TrendAverageProjectValue extends React.Component {
  getTotal(months) {
    if (months.length) {
      const initialValue = {
        value: 0,
        projects: 0,
      };

      const total = months.reduce((sum, month) => ({
        value: sum.value + month.total_value,
        projects: sum.projects + month.projects,
      }), initialValue);

      return total.projects ? total.value / total.projects : 0;
    }
    return null;
  }

  format(value) {
    return `${numeral(value / 1000).format('0,0.[0]')} K`;
  }

  tooltipFormatter(point) {
    return `<b>${this.format(point.y)}</b>`;
  }

  getTotalInFrame() {
    const value = this.getTotal(this.props.months);
    return this.format(value);
  }

  render() {
    return (
      <Trend
        totalInFrame={this.getTotalInFrame()}
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        title="Avg. Project Value"
        className="client-trend--average-project-value"
        getTotal={this.getTotal.bind(this)}
        {...this.props} />
    );
  }
};
