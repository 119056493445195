const React = require('react');

module.exports = class WeeklyPipelineChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'areaspline',
        backgroundColor: '#FCFCFC',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
          },
          marker: {
            fillColor: null,
            lineWidth: 1,
            lineColor: '#FFFFFF', // inherit from series
            marker: 'circle',
          },
        },
      },
      xAxis: {
        categories: [
          '3 w ago',
          '2 w ago',
          'Last',
        ],
        lineColor: '#CACACA',
        labels: {
          style: {
            textOverflow: 'none',
          },
        },
      },
      yAxis: {
        min: this.props.min,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        color: '#3F5C81',
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.Color('#3F5C81').setOpacity(1).get('rgba')],
            [1, Highcharts.Color('#3F5C81').setOpacity(0).get('rgba')],
          ],
        },
        marker: {
          symbol: 'circle',
        },
        data: [this.props.three_weeks_ago, this.props.two_weeks_ago, {
          y: this.props.last_week,
          dataLabels: {

            style: {
              fontWeight: 'bold',
            },
          },
        }],
      }, {
        color: '#98C1D9',
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.Color('#98C1D9').setOpacity(1).get('rgba')],
            [1, Highcharts.Color('#98C1D9').setOpacity(0).get('rgba')],
          ],
        },
        marker: {
          symbol: 'circle',
        },
        data: [this.props.last_year_three_weeks_ago, this.props.last_year_two_weeks_ago,
          this.props.last_year_last_week],
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="briefing-aside-chart" id="briefing-aside-chart-weekly__pipeline">
        <div className="briefing-aside-chart__header">
          <h5 className="briefing-aside-chart__title">Pipeline Value</h5>
          <h6 className="briefing-aside-chart__subtitle">in thousands</h6>
        </div>
        <div className="briefing-aside-chart__content"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
        <div className="briefing-aside-chart__legenda">
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--pipeline-this-year">
            This
            year
          </div>
          <div
            className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--pipeline-same-week-last-year">
            Same
            weeks last year
          </div>
        </div>
      </div>
    );
  }
};
