const $ = require('jquery');
const constants = require('./constants');
const PricelistModel = require('../../../../models/Pricelist');
const LevelModel = require('../../../../models/Level');
const modals = require('./modals');

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

const showModal = (key, data) => ({
  type: 'SHOW_MODAL',
  key,
  data,
});

module.exports.showModalChangePricelistClientDefaultWarning = (data) => (dispatch) => {
  dispatch(showModal(modals.CHANGE_PRICELIST_CLIENT_DEFAULT_WARNING, data));
};

module.exports.showModalSetPricelistClientDefaultWarning = (data) => (dispatch) => {
  dispatch(showModal(modals.SET_PRICELIST_CLIENT_DEFAULT_WARNING, data));
};

module.exports.showModalEditPricelistWarning = (data) => (dispatch) => {
  dispatch(showModal(modals.EDIT_PRICELIST_WARNING_WARNING, data));
};

const showApiErrorModal = (data) => (dispatch) => {
  dispatch(showModal(modals.API_ERROR_MODAL, data));
};
module.exports.showApiErrorModal = showApiErrorModal;

module.exports.showSidebar = (id) => ({
  type: constants.SHOW_SIDEBAR,
  id,
});

const closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});
module.exports.closeSidebar = closeSidebar;

const refreshPricelistsList = () => ({
  type: constants.REFRESH_PRICELISTS_LIST,
});

const getPricelistsRequest = (offset, limit, search, order, sort, available) => ({
  type: constants.GET_PRICELISTS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  available,
});

const getPricelistsSuccess = (pricelists) => ({
  type: constants.GET_PRICELISTS_SUCCESS,
  pricelists,
});

module.exports.getPricelists = (offset, limit, search, order, sort, available) => (dispatch) => {
  const filters = {
    offset, limit, search, order, sort, available,
  };

  dispatch(getPricelistsRequest(offset, limit, search, order, sort, available));

  const getListRequest = PricelistModel.list(filters);
  $.when(getListRequest)
    .done((pricelists) => dispatch(getPricelistsSuccess(pricelists)));
};

const getLevelsRequest = () => ({
  type: constants.GET_LEVELS_REQUEST,
});

const getLevelsSuccess = (levels) => ({
  type: constants.GET_LEVELS_SUCCESS,
  levels,
});

module.exports.getLevels = () => (dispatch) => {
  dispatch(getLevelsRequest());

  const getAllLevelsRequest = LevelModel.list();
  $.when(getAllLevelsRequest)
    .done((levels) => dispatch(getLevelsSuccess(levels)));
};

const updatePricelistRequest = (id) => ({
  type: constants.UPDATE_PRICELIST_REQUEST,
  id,
});

const updatePricelistSuccess = (pricelist) => ({
  type: constants.UPDATE_PRICELIST_SUCCESS,
  pricelist,
});

module.exports.updatePricelist = (id, changes) => (dispatch) => {
  dispatch(updatePricelistRequest(id));

  $.when(PricelistModel.update(id, changes))
    .done((response) => {
      dispatch(updatePricelistSuccess(response));
      dispatch(refreshPricelistsList());
      dispatch(closeModal());
      dispatch(closeSidebar());
    })
    .fail((message, code) => {
      dispatch(closeModal());
      const errorCodes = [400, 401, 403];
      if (errorCodes.includes(code)) {
        dispatch(showApiErrorModal(message));
      } else {
        dispatch(showApiErrorModal('Ops! Something went wrong. Please try again later.'));
      }
    });
};

const createPricelistRequest = () => ({
  type: constants.CREATE_PRICELIST_REQUEST,
});

const createPricelistSuccess = (pricelist) => ({
  type: constants.CREATE_PRICELIST_SUCCESS,
  pricelist,
});

module.exports.createPricelist = (item) => (dispatch) => {
  dispatch(createPricelistRequest());

  $.when(PricelistModel.create(item)).done((response) => {
    dispatch(createPricelistSuccess(response));
    dispatch(refreshPricelistsList());
    dispatch(closeModal());
    dispatch(closeSidebar());
  });
};

module.exports.changeAvailability = (key) => ({
  type: constants.AVAILABILITY_CHANGE,
  key,
});

module.exports.changeSidebarEditMode = (editMode) => ({
  type: constants.CHANGE_SIDEBAR_EDIT_MODE,
  editMode,
});

module.exports.changeUnsavedChanges = (unsavedChanges) => ({
  type: constants.CHANGE_UNSAVED_CHANGES,
  unsavedChanges,
});
