const React = require('react');
const BudgetFooterLink = require('./BudgetFooterLink.react');
const Icon = require('../../../../../../../../common/react/Icon/Icon.react');

const PipelineLink = ({ projectId }) => (
  <BudgetFooterLink label="Pipeline" href={`#pipeline/projects?id=${projectId}`}>
    <Icon icon="pipeline" />
  </BudgetFooterLink>
);

module.exports = PipelineLink;
