const { connect } = require('react-redux');
const Component = require('../components/Validator.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  selectedProject: state.selected_project,
  buEnabled: state.bu_enabled,
  highlightWeekend: state.highlight_weekend,
  planningWeekendConfirmed: state.planning_weekend_confirmed,
  planningHolidayConfirmed: state.planning_holiday_confirmed,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
