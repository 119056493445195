/* eslint-disable react/sort-comp,react/prop-types,consistent-return,default-case,
react/jsx-no-bind */
const React = require('react');
const alertLink = require('../utils/alertLink');

module.exports = class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showSupportLabel: false };
  }

  showSupportLabel() {
    this.setState({ showSupportLabel: true });
  }

  hideSupportLabel() {
    this.setState({ showSupportLabel: false });
  }

  getDescriptionMessage() {
    if (this.state.showSupportLabel) {
      return 'Learn more';
    }
    return this.props.alert.message;
  }

  getSupportLink() {
    return Wethod.getAlertSupportLink(this.props.alert.type);
  }

  getAlertLink() {
    const { alert } = this.props;
    const { project } = this.props;

    return alertLink
      .getAlertLink(alert, project, this.props.opportunity, this.props.intercompany_token);
  }

  /**
   * Return the project's component if present.
   * Not all alerts comes linked to a project (i.e. "No timesheet in the last 2 weeks" alert)
   * @return {null|*}
   */
  getProject() {
    const { project } = this.props;
    if (project) {
      return <span className="widget-alerts__item-project">{_.unescape(project.name)}</span>;
    }
    return null;
  }

  getTypeClassName() {
    let name = 'widget-alerts__item-type';
    if (!this.props.project) {
      return name += ' widget-alerts__item-type--center';
    }
    return name;
  }

  render() {
    return (
      <li className="widget-alerts__item">
        <a href={this.getAlertLink()}>
          <span className="widget-alerts__item-priority">
            <span className="widget-alerts__item-priority-status" style={{ background: this.props.alert.color }} />
          </span>
          <span className="widget-alerts__item-info">
            {this.getProject()}
            <span className={this.getTypeClassName()}>{this.props.alert.type}</span>
          </span>
          <span className="widget-alerts__item-description">
            <span className="widget-alerts__item-description-message">{this.getDescriptionMessage()}</span>
          </span>
        </a>
        <a href={this.getSupportLink()}
          className="widget-alerts__item-support"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => this.showSupportLabel()}
          onMouseLeave={() => this.hideSupportLabel()}>
          <span
            className="widget-alerts__item-support-icon wethod-icon-small wethod-icon-small-support wethod-icon-small-support--white" />
        </a>
      </li>
    );
  }
};
