const { connect } = require('react-redux');
const Component = require('../../components/sidebar/SaveManager.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  items: state.pricelists,
});

const mapDispatchToProps = (dispatch) => ({
  onCreate: (item) => dispatch(actions.createPricelist(item)),
  onUpdate: (id, changes) => dispatch(actions.updatePricelist(id, changes)),
  showModalChangePricelistClientDefaultWarning: (data) => dispatch(
    actions.showModalChangePricelistClientDefaultWarning(data),
  ),
  showModalSetPricelistClientDefaultWarning: (data) => dispatch(
    actions.showModalSetPricelistClientDefaultWarning(data),
  ),
  showModalEditPricelistWarning: (data) => dispatch(
    actions.showModalEditPricelistWarning(data),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
