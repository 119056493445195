const { connect } = require('react-redux');
const Component = require('../../components/Boards/DeleteModal/ConfirmItemDeleteModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (boardId, itemId) => dispatch(actions.deleteBoardItem(boardId, itemId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
