const $ = require('jquery');
const moment = require('moment');
const constants = require('./constants');
const updateUrl = require('../services/updateUrl');
const TimesheetModel = require('../../../../../../models/TimesheetDetailed');
const FavouriteModel = require('../../../../../../models/TimesheetFavourite');
const DateService = require('../../../../../../services/DateService');

module.exports.updateHours = (projectId, areaId, hours) => ({
  type: constants.HOURS_UPDATE,
  project_id: projectId,
  area_id: areaId,
  hours,
});

module.exports.closeWeeklyHoursModal = () => ({
  type: constants.WEEKLY_HOURS_CLOSE_MODAL,
});

module.exports.openWeeklyHoursModal = (hours) => ({
  type: constants.WEEKLY_HOURS_OPEN_MODAL,
  hours,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

module.exports.openNoteModal = (area) => ({
  type: constants.NOTE_OPEN_MODAL,
  area,
});

const saveNoteSuccess = (projectId, areaId, notes) => ({
  type: constants.NOTE_SAVE_SUCCESS,
  project_id: projectId,
  area_id: areaId,
  notes,
});

const saveNoteRequest = () => ({
  type: constants.NOTE_SAVE_REQUEST,
});

module.exports.saveNote = (projectId, areaId, date, notes, hourTypes, employeeId) => (dispatch) => {
  dispatch(saveNoteRequest());
  const employee = employeeId || Wethod.userInfo.get('employee_id');

  const data = {
    project_id: projectId,
    employee_id: employee,
    date: moment(date).format('YYYY-MM-DD'),
    hours: [{
      area_id: areaId,
      notes,
      types: hourTypes,
    }],
  };
  const saveRequest = TimesheetModel.save(data);
  $.when(saveRequest)
    .done(() => {
      dispatch(saveNoteSuccess(projectId, areaId, notes));
      dispatch(closeModal());
    });
};

const saveHoursFailure = () => ({
  type: constants.HOURS_SAVE_FAILURE,
});

const saveHoursSuccess = (projectId, areaId, hours) => ({
  type: constants.HOURS_SAVE_SUCCESS,
  project_id: projectId,
  areaId,
  hours,
});

const saveHoursRequest = (projectId, areaId) => ({
  type: constants.HOURS_SAVE_REQUEST,
  project_id: projectId,
  areaId,
});

module.exports.saveHours = (projectId, date, areaId,
  hourTypes, notes, employeeId) => (dispatch) => {
  dispatch(saveHoursRequest(projectId, areaId));
  const employee = employeeId || Wethod.userInfo.get('employee_id');

  const data = {
    project_id: projectId,
    employee_id: employee,
    date: moment(date).format('YYYY-MM-DD'),
    hours: [{
      area_id: areaId,
      types: hourTypes,
      notes,
    }],
  };
  const saveRequest = TimesheetModel.save(data);
  $.when(saveRequest)
    .done(() => dispatch(saveHoursSuccess(projectId, areaId, hourTypes)))
    .fail(() => dispatch(saveHoursFailure()));
};

const changeDateSuccess = (date, timesheet, employeeHolidays) => ({
  type: constants.DATE_CHANGE_SUCCESS,
  date,
  timesheet,
  employeeHolidays,
});

const changeDateRequest = () => ({
  type: constants.DATE_CHANGE_REQUEST,
});

module.exports.changeDate = (date, userId) => (dispatch) => {
  dispatch(changeDateRequest());
  const formattedDate = moment(date).format('YYYY-MM-DD');
  updateUrl(formattedDate, userId);

  const getListRequest = TimesheetModel.getAll(formattedDate, userId);
  const currentEmployeeId = userId || Wethod.userInfo.attributes.employee_id;
  $.when(getListRequest, DateService.getHolidayForWeekRequest(formattedDate, currentEmployeeId))
    .done((response, employeeHolidays) => dispatch(changeDateSuccess(formattedDate, response,
      employeeHolidays)));
};

module.exports.addFavorite = (projectId) => {
  const data = {
    id_employee: Wethod.userInfo.get('employee_id'),
    id_project: projectId,
  };

  FavouriteModel.save(data);
  return {
    type: constants.FAVORITE_ADD,
    project_id: projectId,
  };
};

module.exports.removeFavorite = (projectId, favoriteId) => {
  FavouriteModel.delete(favoriteId);

  return {
    type: constants.FAVORITE_REMOVE,
    project_id: projectId,
  };
};

module.exports.filterProjects = (keyword) => ({
  type: constants.FILTER_PROJECTS,
  keyword,
});

module.exports.loadMoreProjects = (pageSize) => ({
  type: constants.LOAD_MORE_PROJECTS,
  pageSize,
});

module.exports.alignHeaderColumns = (scrollbarWidth) => ({
  type: constants.ALIGN_HEADER_COLUMNS,
  scrollbarWidth,
});

module.exports.changeBuFilter = (units) => ({
  type: constants.CHANGE_BU_FILTER,
  bu: units,
});
