'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var TimelineStore = require('../../apps/core/modules/timeline/project/store');
var TimelineReducer = require('../../apps/core/modules/timeline/project/reducer');
var TimelineComponent = require('../../apps/core/modules/timeline/project/index');
var TimelineCompanyStore = require('../../apps/core/modules/timeline/company/store');
var TimelineCompanyReducer = require('../../apps/core/modules/timeline/company/reducer');
var TimelineCompanyComponent = require('../../apps/core/modules/timeline/company/index');

Wethod.module('TimelineApp.Timeline', function (Timeline, Wethod, Backbone, Marionette, $, _, Header) {
  Timeline.Controller = {

    showTimeline: function (projectId) {
      var authorizationRequest = Wethod.request('get:pipeline:authorization');
      var projectRequest = Wethod.request('budget:get:project', projectId);
      var timelineRequest = Wethod.request('timeline:get', projectId);
      $.when(authorizationRequest, projectRequest).done(function (permissions, project) {
        if (_.isUndefined(project)) { // no project with the given id
          Wethod.trigger('url:404:show');
        }
        var idEmployee = Wethod.userInfo.get('employee_id');
        var idPM = project.get('id_pm');
        var idAccount = project.get('id_account');

        if (!permissions.get('view') || (!permissions.get('view_other') && !Wethod.Authorization.isPmOrAccount(idEmployee, idPM, idAccount))) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        $.when(timelineRequest)
          .done(function (timeline) {
            var layout = new Timeline.StructureLayoutView();
            layout.render();

            var sentence = Wethod.Utility
              .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
            var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
            layout.timelineBody.show(loadingView);

            var reducerWrapper = new TimelineReducer({
              timeline: timeline.attributes,
              permissions: permissions.attributes,
            });

            var store = TimelineStore(reducerWrapper.reduxReducer);
            var element = React.createElement(TimelineComponent, { store: store });
            var container = document.getElementById('timelineRoot');
            if (container !== null) ReactDOM.render(element, container);
          });
      });
    },

    showCompanyTimeline: function () {
      var authorizationRequest = Wethod.request('get:pipeline:authorization');
      var timelineRequest = Wethod.request('timeline:overview:get', {
        page: 0,
        size: 100,
      });

      $.when(authorizationRequest).done(function (permissions) {
        if (!permissions.get('view')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        $.when(timelineRequest)
          .done(function (timeline) {
            var layout = new Timeline.StructureLayoutView();
            layout.render();
            var sectionHeaderModel = new Header.SectionHeaderModel({
              current_section: 'Timeline',
              preview_anchor_id: 'timeline',
              helper_url: 'pipeline/index/#timeline',
            });
            var headerRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });
            layout.headerSection.show(headerRegion);

            var sentence = Wethod.Utility
              .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
            var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
            layout.timelineBody.show(loadingView);

            var reducerWrapper = new TimelineCompanyReducer({
              timeline: { events: timeline },
              permissions: permissions.attributes,
            });

            var store = TimelineCompanyStore(reducerWrapper.reduxReducer);
            var element = React.createElement(TimelineCompanyComponent, { store: store });
            var container = document.getElementById('timelineRoot');
            if (container !== null) ReactDOM.render(element, container);
          });
      });
    },
  };
}, Wethod.module('HeaderApp.Header'));
