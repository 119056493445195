'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.saveSalesFunnel = function (viewObj, data) {
    var options = {
      id: viewObj.model.get('id'),
      target: parseFloat(data.target),
      filterType: data.filterType,
      filterValue: data.filterValue || null,
    };

    // Update model with new configuration
    viewObj.model.get('config').target = data.target;
    viewObj.model.get('config').active_filter.name = data.filterType;
    viewObj.model.get('config').active_filter.value = data.filterValue;

    viewObj.ui.deleteEl.hide();

    var saveWidgetRequest = Wethod.request('save:user:salesFunnel:widget', options);
    $.when(saveWidgetRequest).done(function (saveWidgetResponse) {
      var target = saveWidgetResponse.config ? saveWidgetResponse.config.target : data.target;
      var renderOptions = { endpoint: DashboardApp.getEndpoint(viewObj.model) };
      var userWidgetRequest = Wethod.request('get:user:widget', renderOptions);
      $.when(userWidgetRequest).done(function (userWidgetResponse) {
        viewObj.model.set({
          product: userWidgetResponse.get('product'),
          label: userWidgetResponse.get('label'),
          projects: userWidgetResponse.get('projects'),
          time_to_go: userWidgetResponse.get('time_to_go'),
        });
        viewObj.model.get('config').target = parseFloat(target);
        viewObj.options.new = false;
        viewObj.render();
      });
    });
  };

  this.deleteSalesFunnel = function (viewObj) {
    var options = {
      id: viewObj.model.get('id'),
    };
    viewObj.ui.saveEl.hide();
    var deleteWidgetRequest = Wethod.request('delete:user:widget', options);
    $.when(deleteWidgetRequest).done(function () {
      viewObj.options.widgetView.destroy();
    });
  };
});
