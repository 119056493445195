const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../../../../../common/react/material-input/SidebarNumericInput.react');
const Text = require('../../../../../common/react/material-input/SidebarReadOnlyField.react');
const format = require('../../../../../services/FormatService').formatDecimalNumber;
const Actions = require('../containers/CardActions');

class CardBodyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.inputFormatOptions = { numeralDecimalScale: 2 };
  }

  handlePlannedBlur(value) {
    this.props.onChange(value);
  }

  getFormattedValue(value) {
    const formattedValue = format(value, 2, false);
    return this.props.currencyCode ? `${formattedValue} ${this.props.currencyCode}` : formattedValue;
  }

  getInputError() {
    const hasError = this.hasError();

    return hasError ? 'Planned value is lower than invoiced' : null;
  }

  getPlannedInputWithCurrency() {
    if (this.props.currencyCode) {
      return (
        <div className="wethod-sidebar__multiple-input-row">
          <Input placeholder="Planned value"
            value={this.props.planned}
            formatOptions={this.inputFormatOptions}
            onBlur={this.handlePlannedBlur.bind(this)}
            error={this.getInputError()} />
          <span>{this.props.currencyCode}</span>
        </div>
      );
    }

    return (
      <Input placeholder="Planned value"
        value={this.props.planned}
        formatOptions={this.inputFormatOptions}
        onBlur={this.handlePlannedBlur.bind(this)}
        error={this.getInputError()} />
    );
  }

  getCompactView() {
    return (
      <div>
        {this.getPlannedInputWithCurrency()}
      </div>
    );
  }

  getExtendedView() {
    return (
      <div>
        {this.getPlannedInputWithCurrency()}
        <div className="wethod-sidebar__multiple-input-row">
          <Text placeholder="Invoiced" value={this.getFormattedValue(this.props.invoiced)} />
          <Text placeholder="To be invoiced" value={this.getFormattedValue(this.props.deltaInvoiced)} />
        </div>
        <Actions delta={this.props.deltaInvoiced} createInvoice={this.props.createInvoice} />
      </div>
    );
  }

  /**
   * Check whether the plan of the month has error:
   * when a month has some invoices, the planned should be at least equal to the invoices
   * @returns {boolean}
   */
  hasError() {
    return this.props.invoiced > 0 && this.props.deltaInvoiced < 0;
  }

  render() {
    return this.props.compact ? this.getCompactView() : this.getExtendedView();
  }
}

CardBodyEdit.propTypes = {
  planned: PropTypes.number,
  invoiced: PropTypes.number,
  deltaInvoiced: PropTypes.number,
  compact: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  currencyCode: PropTypes.string,
};

CardBodyEdit.defaultProps = {
  planned: 0,
  invoiced: 0,
  deltaInvoiced: 0,
  compact: false,
  currencyCode: null,
};

module.exports = CardBodyEdit;
