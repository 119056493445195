const moment = require('moment');

module.exports = class FiscalYearService {
  constructor() {
    this.fiscalYearStart = Wethod.userInfo.get('fiscal_year_start');
  }

  /**
   * Return current company fiscal year start.
   * @returns {number}
   */
  getFiscalYearStartMonth() {
    return this.fiscalYearStart;
  }

  /**
   * Return wether company is following the solar year or not.
   * @returns {boolean}
   */
  isSolarYear() {
    return this.fiscalYearStart === 1;
  }

  getFiscalYearStartDate(year) {
    return moment(`${year}-${this.fiscalYearStart}-01`, 'YYYY-MM-DD');
  }

  getFiscalYearEndDate(year) {
    const start = this.getFiscalYearStartDate(year);
    return moment(start, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days');
  }

  getShiftedYear(year = moment().year()) {
    const currentMonth = moment().month() + 1;
    return currentMonth < this.getFiscalYearStartMonth()
      ? parseInt(moment().year(year).subtract(1, 'years').format('YYYY'))
      : parseInt(moment().year(year).format('YYYY'));
  }

  // Get destination month index based on the given date and the company fiscal year start.
  // For example, if the fiscal year starts in March, given a date in February,
  // the destination month will be 12.
  getShiftedMonth(date) {
    const startingMonth = this.getFiscalYearStartMonth() - 1;
    const currentMonth = moment(date).month();
    if (currentMonth < startingMonth) {
      return 12 + currentMonth - (startingMonth - 1);
    }
    return currentMonth - (startingMonth - 1);
  }

  getYearDynamically(year) {
    const nextYear = moment().year(year).add(1, 'years').format('YY');
    return this.isSolarYear() ? year : `${moment().year(year).format('YY')}/${nextYear}`;
  }

  getListOfMonthsStartingFromFiscalYearStart(year) {
    const start = this.getFiscalYearStartDate(year);
    const end = this.getFiscalYearEndDate(year);
    const months = [];
    while (start.isSameOrBefore(end.format('YYYY-MM-DD'), 'month')) {
      months.push(parseInt(start.format('MM')));
      start.add(1, 'month');
    }
    return months;
  }
};
