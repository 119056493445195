'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette, $, _) {
  this.getPermission = function (label) {
    if (!_.isUndefined(PipelineApp.authorization.get(label))) {
      return PipelineApp.authorization.get(label);
    }
    return false;
  };
});
