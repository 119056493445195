const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/WonProjects/WonProjects.react');

const mapStateToProps = (state) => ({
  count: state.won_projects_count,
  value: state.won_projects_value,
  target: state.weekly_target,
});

module.exports = connect(mapStateToProps)(Component);
