const React = require('react');
const Summary = require('./Summary.react');

module.exports = class TotalSummary extends React.Component {
  componentDidUpdate(prevProps) {
    // State must be updated with changed budget totals
    const changedPrice = this.props.price !== prevProps.price;
    const changedCost = this.props.cost !== prevProps.cost;
    const changedDays = this.props.days !== prevProps.days;
    const changedExternalCost = this.props.externalCosts !== prevProps.externalCosts;

    if (changedPrice || changedCost || changedDays || changedExternalCost) {
      this.props.updateInfoTotals(this.props.price,
        this.props.cost,
        this.props.days,
        this.props.externalCosts);
    }
  }

  render() {
    return (
      <Summary {...this.props} className="wethod-budget-summary--total" />
    );
  }
};
