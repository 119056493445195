const React = require('react');
const isEqual = require('react-fast-compare');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');
const TableSearch = require('../TableSearch.react');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const ItemRow = require('./IntercompanyActivityRow.react');
const { filter } = require('../../../services/IntercompanyActivityFilter');

module.exports = class IntercompanyActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
      filteredItems: [],
      offset: 0,
      limit: 8,
      search: '',
      sort: 'asc',
      order: 'name',
      filters: {},
    };
  }

  /**
   * Get the maximum height of the table, fixed to 500
   * @returns {number}
   */
  static getTableMaxHeight() {
    return 500;
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    const filtersChanged = !isEqual(prevProps.filters, this.props.filters);
    if (filtersChanged) {
      this.fetchItems(this.props.filters);
    }

    const itemsChanged = !isEqual(prevProps.items, this.props.items);
    if (itemsChanged) {
      this.filter(this.props.filters);
    }
  }

  getList() {
    return this.state.filteredItems.map((item) => (
      <ItemRow
        key={item.id}
        item={item} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    if (!this.props.selectedSegment) {
      return 'Start data review from the timeline above to check on data';
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <TableSearch
        keyword={this.props.search}
        filter={this.search.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.state.offset,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      sort: this.state.sort,
      filters: this.state.filters,
    };
  }

  // Filter items based on options (search, sort, order, limit, offset)
  getItems(options) {
    if (!this.props.isWaiting && this.props.selectedSegment != null) {
      const filteredItems = filter(
        this.props.items,
        options.search,
        options.sort,
        options.order,
        options.offset,
        options.limit,
      );

      this.setState({
        offset: options.offset,
        limit: options.limit,
        search: options.search,
        sort: options.sort,
        order: options.order,
        filters: options.filters,
        filteredItems,
      });
    }
  }

  getSort(order) {
    return this.state.order === order ? this.state.sort : null;
  }

  fetchItems(filters) {
    if (!this.props.isWaiting && this.props.selectedSegment != null) {
      this.resetItems();

      this.props.getItems(
        filters,
      );
    }
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = (page - 1) * size;
      options.limit = size;

      this.getItems(options);
    }
  }

  hasMorePages() {
    return this.state.filteredItems.length >= this.state.offset + this.state.limit;
  }

  updateTableHeight() {
    this.setState({ tableHeight: IntercompanyActivity.getTableMaxHeight() });
  }

  search(search) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.search = search;

      this.getItems(options);
    }
  }

  filter(filters) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.filters = filters;

      this.getItems(options);
    }
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getItems(options);
    }
  }

  resetItems() {
    this.setState({
      filteredItems: [],
      offset: 0,
      search: '',
      filters: {},
    });
  }

  render() {
    return (
      <Table columns={6} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="name"
              className="data-freezing-engine__table-intercompany-column-large"
              onSort={this.sort.bind(this)}
              sort={this.getSort('name')}>
              Project
            </TableCell>
            <TableCell name="job_order"
              className="data-freezing-engine__table-intercompany-column-small"
              onSort={this.sort.bind(this)}
              sort={this.getSort('job_order')}>
              Job Order
            </TableCell>
            <TableCell name="task"
              className="data-freezing-engine__table-intercompany-column-small"
              onSort={this.sort.bind(this)}
              sort={this.getSort('task')}>
              Task
            </TableCell>
            <TableCell name="supplier_company"
              className="data-freezing-engine__table-intercompany-column-medium"
              onSort={this.sort.bind(this)}
              sort={this.getSort('supplier_company')}>
              Supplier Company
            </TableCell>
            <TableCell name="recipient"
              className="data-freezing-engine__table-intercompany-column-medium"
              onSort={this.sort.bind(this)}
              sort={this.getSort('recipient')}>
              Invitation Recipient
            </TableCell>
            <TableCell name="actions"
              className="data-freezing-engine__table-intercompany-actions" />
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
