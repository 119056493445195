const { connect } = require('react-redux');
const Component = require('../../components/job-order-template/JobOrderTemplates.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  enabled: state.jobOrderTemplates.enabled,
  isWaiting: state.waitingFor
    .filter((key) => key === 'set-enabled' || key === 'get-templates').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  enable: (enabled) => dispatch(actions.enableTemplates(enabled)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
