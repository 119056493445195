/* eslint-disable no-param-reassign,class-methods-use-this,no-throw-literal,consistent-return,
 no-underscore-dangle */
const TimeFilter = require('./TimeFilter');
const TimeBreakpoint = require('./TimeBreakpoint');
const DateService = require('../../../../../../../../../services/DateService');
const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

module.exports = class QuarterFilter extends TimeFilter {
  /**
   * @param quarter an integer between 0 and 3
   */
  constructor(quarter) {
    quarter = quarter !== undefined ? quarter : null;
    super(1, quarter);
    this.fiscalYearService = new FiscalYearService();
    this.sortedMonths = this.generateArrayOfSortedMonths();
    this._quartersBreakpoints = [{ // months that ends a quarter;
      name: 'Q1',
      value: this.sortedMonths[2],
    }, {
      name: 'Q2',
      value: this.sortedMonths[5],
    }, {
      name: 'Q3',
      value: this.sortedMonths[8],
    }, {
      name: 'Q4',
      value: this.sortedMonths[11],
    }];
    this.validate(this.frame);
  }

  generateArrayOfSortedMonths() {
    const currentYear = this.fiscalYearService.getShiftedYear(moment().year());
    const startingDate = this.fiscalYearService.getFiscalYearStartDate(currentYear);
    const sortedMonths = [];
    for (let i = 0; i < 12; i++) {
      sortedMonths.push(startingDate.clone().add(i, 'months').format('YYYY-MM-DD'));
    }
    return sortedMonths;
  }

  setFrame(value) {
    for (let i = 0; i < this._quartersBreakpoints.length; i++) {
      if (value === this._quartersBreakpoints[i].name) {
        this.frame = i;
        return;
      }
    }
    throw 'Invalid frame. Must be one of \'I\',\'II\',\'III\',\'IV\'';
  }

  validate(quarter) {
    if (quarter !== null && (!Number.isInteger(quarter) || quarter < 0 || quarter > 3)) {
      throw 'Quarter must be an integer between 0 and 3 included or null';
    }
  }

  getLabel() {
    return '1 year';
  }

  getBreakpoints() {
    return this._quartersBreakpoints.map((breakpoint) => new TimeBreakpoint(
      breakpoint.name, breakpoint.value,
    ));
  }

  /**
   * Return an array of months belonging to the filter's period's frame.
   *
   * @returns {Array} of months formatted as 2019-10-12
   */
  getMonthsInFrame() {
    if (this.frame === null) {
      return this.getMonthsInPeriod();
    }
    const month = moment(this._quartersBreakpoints[this.frame].value).month();
    const end = moment(`${this.currentYear}-${this.fiscalYearService.getFiscalYearStartMonth()}-01`)
      .month(month);
    const start = end.clone().subtract(2, 'months');
    return this.getMonths(start, end);
  }

  getBreakpointIndexForDate(date) {
    const month = DateService.getMonth(date) + 1;
    const sortedMonths = this.sortedMonths.map((sortedDate) => moment(sortedDate).month() + 1);

    const indexOfMonthInSortedMonths = sortedMonths.indexOf(month);
    if (this.frame === null) {
      switch (true) {
        case indexOfMonthInSortedMonths >= 9:
          return 3;
        case indexOfMonthInSortedMonths >= 6:
          return 2;
        case indexOfMonthInSortedMonths >= 3:
          return 1;
        default:
          return 0;
      }
    } else {
      const monthFrame = Math.floor((indexOfMonthInSortedMonths) / 3); // quarter the month is belonging
      if (monthFrame === this.frame) return indexOfMonthInSortedMonths % 3; // index of month in it's quarter
      return 2; // the month is past the frame, breakpoint is set to last month of the frame
    }
  }
};
