const React = require('react');
const Select = require('../../../../../../../../common/react/material-input/SidebarSelectAddButton.react');

module.exports = class PaymentTermsSelect extends React.Component {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static found(key, value) {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  }

  static getEmpty() {
    return (
      <li className="wethod-search-select__item">
        Oops! Payment term not found.
        <br />
        Add a new one from the <a href="#settings/company/payment-terms">settings page</a>.
      </li>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredItems: this.props.items,
    };
  }

  handleFilter(name, key) {
    const filtered = this.getFilteredItems(key);
    this.setState({
      filteredItems: filtered,
    });
  }

  getFilteredItems(keyword) {
    return this.props.items.filter((item) => PaymentTermsSelect.found(keyword, item.name));
  }

  getSelectedValue() {
    return (this.props.value && this.props.value.id) ? this.props.value : null;
  }

  render() {
    return (
      <Select
        name="payment_term"
        placeholder="Payment Terms"
        value={this.getSelectedValue()}
        items={this.state.filteredItems}
        onFilter={this.handleFilter.bind(this)}
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        empty={PaymentTermsSelect.getEmpty()}>
        <span />
      </Select>
    );
  }
};
