const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../../TooltipFixed/IconButtonTooltip.react');
const Menu = require('../../Menu/Menu.react');
const Switch = require('../../inputs/Switch/Switch.react');
const TableColumnList = require('../models/TableColumnList');

require('./style.scss');

class ColumnSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.buttonRef = null;

    this.onButtonClick = this.onButtonClick.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, prevContext) {
    // eslint-disable-next-line no-empty
    if (prevProps.columns !== this.props.columns) {

    }
  }

  onButtonClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onMenuClose() {
    this.setState({ open: false });
  }

  onToggleClick(e) {
    const { name, checked } = e.target;

    if (checked) {
      this.props.columns.setVisible(name);
    } else {
      this.props.columns.setInvisible(name);
    }

    const visibleColumnsKeys = this.props.columns.getVisible().toKeysArray();
    this.props.onChange(visibleColumnsKeys);
  }

  setButtonRef(ref) {
    this.buttonRef = ref;
  }

  getColumns() {
    return this.props.columns.getAll().map((column) => (
      <div key={column.key} className="wethod-table-column-selector__column">
        <Switch checked={column.visible}
          name={column.key}
          label={column.label}
          id={`wethod-column-selector-${column.key}`}
          onChange={this.onToggleClick} />
      </div>
    ));
  }

  render() {
    return (
      <div className="wethod-table-column-selector">
        <Button label="Edit columns"
          rootRef={this.setButtonRef}
          onClick={this.onButtonClick}
          className="wethod-icon-button--no-margin"
          borderless>
          <span style={{ margin: 4 }}
            className="wethod-icon wethod-icon-medium-more wethod-icon-medium-more--black" />
        </Button>
        <Menu open={this.state.open}
          anchorEl={this.buttonRef}
          onClose={this.onMenuClose}
          bottom
          centered>
          <div className="wethod-table-column-selector__header">Edit columns</div>
          <div className="wethod-table-column-selector__body">
            {this.getColumns()}
          </div>
        </Menu>
      </div>
    );
  }
}

ColumnSelector.propTypes = {
  columns: PropTypes.instanceOf(TableColumnList).isRequired,
  /**
   * @param columns {<string>[]} new visible columns
   * */
  onChange: PropTypes.func.isRequired,
};

module.exports = ColumnSelector;
