const { connect } = require('react-redux');
const Component = require('../../../components/TextArea/TextAreaModal/TextAreaModalBodyEdit.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  summary: state.concept.summary,
  description: state.concept.description,
  projectId: state.info.id,
  isSaving: state.waitingFor.filter((key) => key === 'save-concept').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  save: (concept, projectId) => dispatch(actions.saveConcept(concept, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
