/* eslint-disable camelcase */
const React = require('react');
const Comparator = require('../../../../common/components/Comparator.react');

const LastWeekProductionComparator = ({ value, prev_week_production }) => {
  const labels = {
    much_lower: 'falling',
    lower: 'falling',
    almost_equal: 'constant',
    equal: 'constant',
    greater: 'growing',
    much_greater: 'growing',
  };

  return <Comparator a={value} labels={labels} b={prev_week_production} />;
};

module.exports = LastWeekProductionComparator;
