/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

/**
 * Item component of select list.
 * It receives an item and shows the attribute corresponding the 'param' prop.
 *
 * LIFECYCLE HOOKS
 * onClick
 *
 * OTHER PROPS
 * - item
 * - param
 *
 * @type {module.Item}
 */
module.exports = class Item extends React.Component {
  handleClick() {
    this.props.onClick(this.props.item);
  }

  render() {
    return (
      <li className="wethod-search-select__item"
        onClick={this.handleClick.bind(this)}>
        {_.unescape(this.props.item[this.props.param])}
      </li>
    );
  }
};
