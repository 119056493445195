const React = require('react');
const Row = require('../Row.react');
const Input = require('./CreationRowInput.react');
const PermissionManager = require('../../../containers/PermissionManager');

const CreationRow = ({
  column,
  placeholder,
  isSaving,
  save,
  className,
}) => (
  <Row className={`wethod-budget-creation-row ${className} ${column ? `wethod-budget-creation-row--${column}` : ''}`}>
    <PermissionManager>
      <Input placeholder={placeholder} isSaving={isSaving} save={save} />
    </PermissionManager>
  </Row>
);

module.exports = CreationRow;
