const React = require('react');
const ProjectAreasModal = require('../containers/ProjectAreasModal/ProjectAreaModal');

const Modal = ({
  toShow, closeModal, data, canEdit,
}) => {
  const getModal = () => {
    switch (toShow) {
      case 'project-areas':
        return <ProjectAreasModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
