/* eslint-disable camelcase,no-underscore-dangle */

'use strict';

var BusinessUnitService = require('../../../services/BusinessUnitService');

Wethod.module('ReportApp.Report', function (Report, Wethod, Backbone, Marionette, $, _) {
  Report.chart = function (series, maxYAxis, projectStatusPercentage) {
    return {
      chart: {
        renderTo: 'statusChart',
        type: 'bubble',
        ignoreHiddenSeries: false,
      },
      credits: { enabled: false },
      title: { text: '' },
      legend: { enabled: false },
      xAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        title: {
          text: 'PROJECT STATUS % (days)',
          align: 'high',
        },
        min: 0,
        max: 100,
      },
      yAxis: {
        tickWidth: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        title: {
          text: projectStatusPercentage ? 'BUDGET CONSUMPTION %' : 'BUDGET CONSUMPTION % (days)',
          align: 'high',
        },
        min: 0,
        max: maxYAxis,
      },
      series: series,
      tooltip: {
        useHTML: true,
        formatter: function () {
          return this.series.name;
        },
      },
      plotOptions: {
        bubble: {
          maxSize: 80,
          minSize: 10,
        },
      },
    };
  };

  // layout pagina
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#reportStructure',
    className: 'fluid',
    regions: {
      headerSection: '[data-region="reportSectionHeader"]',
      head: '[data-region="reportHeader"]',
      cachedNote: '[data-region="reportCachedNoteWrap"]',
      filterLabel: '[data-region="reportFilterByLabelWrap"]',
      chart: '[data-region="bubbleChart"]',
      info: '[data-region="reportInfo"]',
      mobileInfo: '[data-region="reportInfoMobile"]',
      filters: '[data-region="reportFilters"]',
    },
    ui: {
      notArchived: '[data-action="showCurrent"]',
      showArchivedAndNot: '[data-action="showAll"]',
      onlyArchived: '[data-action="showArchived"]',
    },
    events: {
      'click @ui.notArchived': function (e) {
        this.filterArchive(e, 'notArchived');
      },
      'click @ui.showArchivedAndNot': function (e) {
        this.filterArchive(e, 'showArchivedAndNot');
      },
      'click @ui.onlyArchived': function (e) {
        this.filterArchive(e, 'onlyArchived');
      },
    },
    filterArchive: function (e, filter) {
      e.preventDefault();
      var target = $(e.target);

      if (!target.hasClass('sel')) {
        $('.sel').removeClass('sel');
        target.addClass('sel');
        // pulisco tutti i filtri
        $('[data-action="reportFilterClient"]').val('');
        $('[data-action="reportFilterProject"]').val('');
        $('[data-action="reportFilterProjectType"]').val('');
        $('[data-action="reportFilterPM"]').val('');
        $('[data-action="reportFilterAccount"]').val('');
        $('[data-action="reportFilterJobNumber"]').val('');

        Report.switchArchiveFilter(filter);
      }
    },
  });

  // header
  this.HeaderTemplateView = Marionette.ItemView.extend({
    template: '#reportHeaderTemplate',
    className: 'col_16 no_padding',
  });

  // header
  this.FilterLabelView = Marionette.ItemView.extend({
    template: '#reportFilterByLabel',
    className: 'col_16 no_padding',
  });

  // bubbles cached on
  this.CachedNoteView = Marionette.ItemView.extend({
    template: '#reportCachedNote',
    ui: {
      reloadButton: '[data-action="cachedReloadButton"]',
      reloadButtonLabel: '[data-region="cachedReloadLabel"]',
      reloadDateLabel: '[data-region="cachedReloadDate"]',
    },
    events: {
      'click @ui.reloadButton': 'reloadReport',
    },
    initialize: function (options) {
      this.options = options;
    },
    onShow: function () {
      if (!_.isUndefined(this.options.cached_on)) {
        $(this.ui.reloadButton).removeClass('hidden');
        $(this.ui.reloadButtonLabel).text('REFRESH DATA');
        $(this.ui.reloadDateLabel).removeClass('hidden');
        $(this.ui.reloadDateLabel)
          .text('last update ' + moment(this.options.cached_on).format('DD/MM/YYYY'));
      }
    },
    reloadReport: function () {
      if (!this.ui.reloadButton.hasClass('disabled')) {
        this.ui.reloadButton.addClass('disabled');
        $(this.ui.reloadDateLabel).addClass('disabled');
        this.ui.reloadButtonLabel.text('LOADING...');
        Report.reloadReport();
      }
    },
  });

  // content
  this.BubbleChartTemplateView = Marionette.ItemView.extend({
    template: '#reportContentTemplate',
    className: 'col_16 no_padding bubbleContainer',
    ui: {
      reportChart: '[data-region="displayReportChart"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onShow: function () {
      this.ui.reportChart.highcharts(Report.chart(this.options.series, this.options.maxYAxis,
        this.options.projectStatusPercentage));
    },
  });

  // report filters
  this.ChartFilterTemplateView = Marionette.ItemView.extend({
    template: '#reportFilterTemplate',
    _chart: undefined,
    ui: {
      filters: '.reportFilterInput',
      clientFilters: '[data-action="reportFilterClient"]',
      projectFilters: '[data-action="reportFilterProject"]',
      projectTypeFilters: '[data-action="reportFilterProjectType"]',
      pmFilters: '[data-action="reportFilterPM"]',
      accountFilters: '[data-action="reportFilterAccount"]',
      jobOrderFilters: '[data-action="reportFilterJobNumber"]',
      tagFilters: '[data-action="reportFilterTags"]',
      businessUnitFilters: '[data-action="reportFilterBusinessUnit"]',
    },
    events: {
      'click @ui.filters': 'selectText',
      'change @ui.filters': 'updateChartFilter',
    },
    onRender: function () {
      var businessUnitService = new BusinessUnitService();
      if (this.model.get('hasBusinessUnits')) {
        var lastSelectedBuId = businessUnitService.getLastSelected();
        var defaultBu = _.map(lastSelectedBuId, function (id) {
          return businessUnitService.findById(id).name;
        });
        this.ui.businessUnitFilters.val(defaultBu.join());
        this.updateChartFilter();
      }
    },
    initialize: function (options) {
      this._projectStatusPercentage = options.projectStatusPercentage;
      this._chart = options.chart;
    },
    selectText: function (e) {
      e.target.select();
    },
    updateChartFilter: function () {
      var filters = {
        clients: this.ui.clientFilters.val().toLowerCase().trim(),
        projects: this.ui.projectFilters.val().toLowerCase().trim(),
        projectTypes: this.ui.projectTypeFilters.val().toLowerCase().trim(),
        pms: this.ui.pmFilters.val().toLowerCase().trim(),
        accounts: this.ui.accountFilters.val().toLowerCase().trim(),
        jobOrders: this.ui.jobOrderFilters.val().toLowerCase().trim(),
      };

      if (this.model.get('hasBusinessUnits')) {
        filters.businessUnits = this.ui.businessUnitFilters.val().toLowerCase().trim();
      }

      Report.chartFilter(filters, this._projectStatusPercentage);
    },
  });

  // project info
  this.InfoTemplateView = Marionette.ItemView.extend({
    className: 'col_16 no_padding',
    _template: '#reportInfo',
    _info: undefined,
    _color: undefined,
    initialize: function (options) {
      this._info = options.projectInfo;
      this._color = options.color;

      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var model = serialized_model;
      var projectId = that._info.project.id;

      model.info = that._info;
      model.color = that._color;

      model.linkBudget = '#pipeline/budget/' + projectId;
      model.linkPlanning = '#planning';
      model.linkReport = '#project/' + projectId + '/report';

      return _.template(template)(model);
    },
  });
});
