const React = require('react');
const moment = require('moment');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const IconButtonTooltip = require('../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const TimesheetRow = ({ timesheet }) => {
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  const formatNonEmpty = (value) => (value || '-');

  return (
    <TableRow>
      <TableCell className="timesheets__column-employee">{timesheet.employee_full_name}</TableCell>
      <TableCell className="timesheets__column-date">
        {formatDate(timesheet.date)}
        <ShowIf condition={timesheet.mode.toLowerCase() === 'weekly'}>
          <IconButtonTooltip className="timesheets__date-tooltip"
            label="Week starting on this date">
            <span>i</span>
          </IconButtonTooltip>
        </ShowIf>
      </TableCell>
      <TableCell className="timesheets__column-mode">{formatNonEmpty(timesheet.mode)}</TableCell>
      <TableCell className="timesheets__column-job-order">{formatNonEmpty(timesheet.job_order)}</TableCell>
      <TableCell className="timesheets__column-project">{timesheet.project_name}</TableCell>
      <TableCell className="timesheets__column-hours">{timesheet.hours}</TableCell>
    </TableRow>
  );
};

module.exports = TimesheetRow;
