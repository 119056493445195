/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const HeaderWeek = require('./HeaderWeek.react');

class HeaderWeeks extends React.Component {
  render() {
    const weeks = this.props.weeks
      .map((week, index) => <HeaderWeek days={week} key={index.toString()} />);
    return (
      <div className="header__weeks">
        {weeks}
      </div>
    );
  }
}

module.exports = HeaderWeeks;
