const React = require('react');

/**
 * Injects all needed permission checks into the given children.
 * @param children
 * @param permissions {{}} contains permissions settings from server
 * @returns {React.DetailedReactHTMLElement<{canEdit: *, canDeleteOthers: *, canEditItemValue: (function({id: number}): boolean), canCreate: *, canViewOther: *, canStartReview: *, canView: *, canEditItemCosts: (function({id: number}): boolean), canEditItem: (function(number, number): *|boolean), canDeleteItem: (function(number, number): *|boolean), canEditOther: *, canEditJobOrder: *, canCreateOther: *, canDelete: *}, HTMLElement>}
 * @constructor
 */
const PermissionManager = ({ children, permissions, ...rest }) => {
  const currentUserId = Wethod.userInfo.get('employee_id');

  const checks = {
    canView: permissions.view,
    canEdit: permissions.edit,
    canCreate: permissions.create,
    canDelete: permissions.delete,
    canViewOther: permissions.view_other,
    canEditOther: permissions.edit_other,
    canCreateOther: permissions.create_other,
    canDeleteOthers: permissions.delete_other,
    canEditJobOrder: permissions.job_order,
    canStartReview: permissions.review,
  };

  /**
   * An item can be deleted if the user has "delete" permission and he's in charge for that item.
   * If user in not in charge, he can still delete the item if he has "delete_other" permission.
   * @param pmId {number} id of the item's PM
   * @param accountId {number} id of the item's account
   * @returns {boolean}
   */
  const canDeleteItem = (pmId, accountId) => {
    const userIsInCharge = currentUserId === pmId || currentUserId === accountId;

    return checks.canDelete && (userIsInCharge || checks.canDeleteOthers);
  };

  /**
   * An item can be edited if the user has "edit" permission and he's in charge for that item.
   * If user in not in charge, he can still delete the item if he has "edit_other" permission.
   * @param pmId {number} id of the item's PM
   * @param accountId {number} id of the item's account
   * @returns {boolean}
   */
  const canEditItem = (pmId, accountId) => {
    const userIsInCharge = currentUserId === pmId || currentUserId === accountId;

    return checks.canEdit && (userIsInCharge || checks.canEditOther);
  };

  const hasBudget = (budget) => budget.id !== null;

  /**
   * Item value can be edited from pipeline only if item does not have a budget yet and is it is not an intercompany supplier.
   * @param item {{budget:{id:number}, project:{intercompany: boolean}}}
   * @param isIntercompany {boolean}
   * @returns {boolean}
   */
  const canEditItemValue = (item) => !hasBudget(item.budget) && !item.project.intercompany;

  /**
   * Check if probability is editable based on the flag on the item.
   * If the flag is not given, the probability is considered editable by default.
   * @param item
   * @returns {*|boolean}
   */
  const isItemProbabilityEditable = (item) => (
    item.pipedrive_deals == null || item.pipedrive_deals.length === 0
  );

  /**
   * Item probability can be edited from pipeline only if item is not an intercompany supplier.
   * @param item {{project:{intercompany: boolean}}}
   * @return {boolean}
   */
  const canEditItemProbability = (item) => !item.project.intercompany
    && !item.project.archived
    && isItemProbabilityEditable(item);
  // TODO: update flag

  /**
   * Item costs can be edited from pipeline only if item does not have a budget yet.
   * @param budget {{id:number}}
   * @returns {boolean}
   */
  const canEditItemCosts = (budget) => !hasBudget(budget);

  /**
   * Item risk can be edited from pipeline only if item is not archived.
   * @param item {{project:{archived: boolean}}}
   * @return {boolean}
   */
  const canEditItemRisk = (item) => item.can_edit_risk && !item.project.archived;

  /**
   * Item stage can be edited from pipeline only if item is not archived.
   * @param item {{project:{archived: boolean}}}
   * @return {boolean}
   */
  const canEditItemStage = (item) => !item.project.archived;

  /**
   * Item job order category can be edited from pipeline only if item is not archived.
   * @param item {{project:{archived: boolean}}}
   * @return {boolean}
   */
  const canEditItemJoc = (item) => !item.project.archived;

  return React
    .cloneElement(children, {
      ...checks,
      canDeleteItem,
      canEditItem,
      canEditItemValue,
      canEditItemCosts,
      canEditItemProbability,
      canEditItemRisk,
      canEditItemStage,
      canEditItemJoc,
      ...rest,
    });
};

module.exports = PermissionManager;
