/* eslint-disable class-methods-use-this,no-case-declarations */
const constants = require('./constants');
const filter = require('./utilities/filter');

class TimelineCompanyReducer {
  constructor(state) {
    this.state = {
      ...state,
      showNewEventForm: false,
      showEditEventForm: false,
      newEventStatus: null,
      modalToShow: null,
      focusedEvent: null,
      isDeletingEvent: false,
      filteredEvents: state.timeline.events,
      isLoadingEvents: false,
      isFilteringEvents: false,
      filter: '',
      page: 0,
      hasMorePages: true,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    if (value) {
      return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    }
    return false;
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SAVE_EVENT_REQUEST:
        return {
          ...state,
          newEventStatus: 'saving',
        };
      case constants.SAVE_EVENT_SUCCESS:
        let newState = {
          ...state,
          timeline: {
            ...state.timeline,
            events: state.timeline.events.concat(action.event).sort((a, b) => {
              const dateA = moment(a.timestamp);
              const dateB = moment(b.timestamp);
              if (dateA.isAfter(dateB.format('YYYY-MM-DD HH:mm'))) {
                return -1;
              }
              return 1;
            }),
          },
          newEventStatus: 'saved',
        };
        newState.filteredEvents = newState.timeline.events;
        return newState;
      case constants.SAVE_FOLLOWUP_REQUEST:
        return {
          ...state,
          newEventStatus: 'saving',
        };
      case constants.SAVE_FOLLOWUP_SUCCESS:
        newState = {
          ...state,
          timeline: {
            ...state.timeline,
            events: state.timeline.events.concat(action.event).sort((a, b) => {
              const dateA = moment(a.timestamp);
              const dateB = moment(b.timestamp);
              if (dateA.isAfter(dateB.format('YYYY-MM-DD HH:mm'))) {
                return -1;
              }
              return 1;
            }),
          },
          newEventStatus: 'saved',
        };
        newState.timeline.events = newState.timeline.events.map((event) => {
          if (event.event.id === action.event.parent_id) {
            return {
              ...event,
              event: {
                ...event.event,
                followed_by: action.event.event.id,
              },
            };
          }
          return event;
        });
        newState.filteredEvents = newState.timeline.events;
        return newState;
      case constants.UPDATE_EVENT_REQUEST:
        return {
          ...state,
          newEventStatus: 'saving',
        };
      case constants.UPDATE_EVENT_SUCCESS:
        newState = {
          ...state,
          timeline: {
            ...state.timeline,
            events: state.timeline.events.map((event) => {
              if (event.event.id === action.event.id) {
                return action.event;
              }
              return event;
            }),
          },
          newEventStatus: 'saved',
        };
        newState.timeline.events = newState.timeline.events.map((event) => {
          if (event.event.id === action.event.parent_id) {
            return {
              ...event,
              event: action.event,
            };
          }
          return event;
        });
        newState.filteredEvents = newState.timeline.events;
        return newState;
      case constants.UPDATE_FOLLOWUP_REQUEST:
        return {
          ...state,
          newEventStatus: 'saving',
        };
      case constants.UPDATE_FOLLOWUP_SUCCESS:
        newState = {
          ...state,
          timeline: {
            ...state.timeline,
            events: state.timeline.events.map((event) => {
              if (event.event.id === action.event.id) {
                return {
                  ...action.event,
                  type: 'timeline_follow_up',
                };
              }
              if (event.event.id === action.event.parent_id) {
                return {
                  ...event,
                };
              }
              return event;
            }),
          },
          newEventStatus: 'saved',
        };
        newState.filteredEvents = newState.timeline.events;
        return newState;
      case constants.OPEN_NEW_EVENT_FORM:
        return {
          ...state,
          showNewEventForm: true,
        };
      case constants.CLOSE_NEW_EVENT_FORM:
        return {
          ...state,
          showNewEventForm: false,
          newEventStatus: null,
        };
      case constants.OPEN_EDIT_EVENT_FORM:
        return {
          ...state,
          timeline: {
            ...state.timeline,
            events: [...state.timeline.events],
          },
          showNewEventForm: false,
          newEventStatus: null,
          showEditEventForm: true,
          focusedEvent: action.event,
        };
      case constants.CLOSE_EDIT_EVENT_FORM:
        return {
          ...state,
          showEditEventForm: false,
          focusedEvent: null,
          newEventStatus: null,
        };
      case constants.SHOW_DELETE_EVENT_MODAL:
        return {
          ...state,
          modalToShow: 'delete-event',
          focusedEvent: action.event,
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          focusedItem: null,
        };
      case constants.DELETE_EVENT_REQUEST:
        return {
          ...state,
          isDeletingEvent: true,
        };
      case constants.DELETE_EVENT_SUCCESS:
        newState = {
          ...state,
          isDeletingEvent: false,
          modalToShow: null,
          focusedEvent: null,
          timeline: {
            ...state.timeline,
            events: state.timeline.events.map((event) => {
              if (event.event.followed_by === action.event.id) { // If deleting a follow up
                return {
                  ...event,
                  event: {
                    ...event.event,
                    followed_by: null,
                  },
                }; // Detach it from its parent
              }
              return event;
            }).filter((event) => {
              // Event has follow-up
              if (action.event.followed_by) {
                return event.event.id !== action.event.id
                  && event.event.id !== action.event.followed_by;
              }
              return event.event.id !== action.event.id;
            }),
          },
        };
        newState.filteredEvents = newState.timeline.events;
        return newState;
      case constants.SHOW_EVENT_DETAILS:
        return {
          ...state,
          focusedEvent: action.event,
          showEditEventForm: false,
        };
      case constants.HIDE_EVENT_DETAILS:
        return {
          ...state,
          focusedEvent: null,
          showEditEventForm: false,
        };
      case constants.FILTER_EVENTS:
        const key = action.keyword;
        return {
          ...state,
          filterKey: key,
          filteredEvents: filter(key, state.timeline.events),
        };
      case constants.MORE_EVENTS_REQUEST:
        return {
          ...state,
          isLoadingEvents: true,
        };
      case constants.MORE_EVENTS_SUCCESS:
        const nextTimelineEvents = state.timeline.events.concat(action.events);
        return {
          ...state,
          isLoadingEvents: false,
          page: action.page,
          timeline: {
            ...state.timeline,
            events: nextTimelineEvents,
          },
          filteredEvents: nextTimelineEvents,
          hasMorePages: action.events.length > 0,
        };
      case constants.FILTER_EVENTS_REQUEST:
        return {
          ...state,
          isFilteringEvents: true,
        };
      case constants.FILTER_EVENTS_SUCCESS:
        return {
          ...state,
          isFilteringEvents: false,
          filter: action.filter,
          timeline: {
            ...state.timeline,
            events: action.events,
          },
          filteredEvents: action.events,
          page: 0,
          hasMorePages: action.events.length > 0,
        };
      default:
        return state;
    }
  }
}

module.exports = TimelineCompanyReducer;
