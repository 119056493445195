const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');

const CannotMoveSupplierModal = ({ data, onClose }) => (
  <Modal title="Cannot move supplier orders"
    onCancelClick={onClose}
    className="suppliers__modal">
    <p>
      Supplier <b>{data.to.name}</b> is <b>not available for contracts</b>, this means that you
      cannot move orders from <b>{data.from.name}</b> to <b>{data.to.name}</b>.
    </p>
    <p>
      Please check suppliers and retry.
    </p>
    <div className="profile-contact-info__actions">
      <button type="button"
        onClick={onClose}
        className="wethod-button">
        OK
      </button>
    </div>
  </Modal>
);

module.exports = CannotMoveSupplierModal;
