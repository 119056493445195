const React = require('react');
const Modal = require('../containers/ModalController');
const Actions = require('../containers/Actions');
const Table = require('../containers/ProductionPlanList');

const ProductionPlan = () => (
  <div className="production-plan wethod-section-body">
    <Actions />
    <Table />
    <Modal />
  </div>
);

module.exports = ProductionPlan;
