const React = require('react');
const PropTypes = require('prop-types');

/**
 * Represents a single rating.
 * This is just a container, the content can be filled by using common components
 * (like Item/Header, Item/Comment, etc.) or context specific components.
 *
 * Used in RatingOverview.
 * @param children
 * @returns {*}
 * @constructor
 */
const Item = ({ children }) => (
  <div className="widget-rating-overview-item">
    {children}
  </div>
);

Item.defaultProps = {
  children: null,
};

Item.propTypes = {
  /**
   * Nodes to use as item body.
   */
  children: PropTypes.node,
};

module.exports = Item;
