const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const Escaper = require('../../../../../../common/react/formatters/Escaper.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const ConfirmArchiveModal = ({ data, isSaving, onCloseClick, onArchive }) => {
  function handleArchive() {
    onArchive(data.item, data.archive);
  }

  const projectName = data.item.project ? data.item.project.name : '';

  const action = data.archive ? 'archive' : 'unarchive';

  return (
    <Modal title={`Project ${action}`} onCancelClick={onCloseClick}>
      <p>
        Are you sure you want to {action} the project <b><Escaper>{projectName}</Escaper></b>?
      </p>
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Cancel</Button>
        <Button onClick={handleArchive} disabled={!data.item.project || !data.item.project.id || isSaving} className="capitalize">
          {action}
        </Button>
      </div>
      <div className="wethod-modal2__feedback">
        <ShowIf condition={isSaving}>
          <span>Saving...</span>
        </ShowIf>
      </div>
    </Modal>
  );
};

module.exports = ConfirmArchiveModal;
