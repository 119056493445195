const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../../Menu/ListMenu/ListMenu.react');
const EventService = require('../../../../services/EventService');
const Empty = require('../../Menu/ListMenu/EmptyItem.react');

require('./style.scss');

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      open: false,
    };

    this.onInputClick = this.onInputClick.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onInputClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onClose() {
    this.setState({ open: false });
  }

  onParentChange(e, option) {
    if (this.props.onChange) {
      this.props.onChange(e, option);
    }
  }

  onOptionClick(e) {
    const option = this.getOption(e.target.value);

    this.setState({ value: e.target.value }, () => {
      this.validate(e.target.value);
      this.onParentChange(e, option.props);
    });
  }

  getOptions() {
    return React.Children.map(this.props.children, (child) => React
      .cloneElement(child, {
        onClick: (e) => {
          // Cannot use original event because empty <li> value is converted to 0
          const event = EventService.cloneEvent(e, {
            name: this.props.name,
            value: child.props.value,
          });
          this.onOptionClick(event);
        },
        key: child.props.value,
      }));
  }

  getMenuContent() {
    if (this.isEmpty()) {
      return <Empty>{this.props.empty}</Empty>;
    }
    return this.getOptions();
  }

  getOption(value) {
    const found = React.Children.toArray(this.props.children)
      .filter((child) => String(child.props.value) === String(value));
    return found.length ? found[0] : null;
  }

  /**
   * Finds the option with the given value and returns the label to show inside Select button.
   * Default label for an option is its children. You can customize this by explicitly passing a
   * "label" prop to the option.
   * @param value
   * @returns {null|string|HTMLElement}
   */
  getOptionLabel(value) {
    if (value !== null) {
      const option = this.getOption(value);
      if (option) {
        const optionProps = option.props;
        if (optionProps.label !== undefined) {
          return optionProps.label;
        }
        // Children is an array if it's made of multiple words
        if (Array.isArray(optionProps.children)) {
          return optionProps.children.join('');
        }
        return optionProps.children;
      }
    }
    return null;
  }

  getButton() {
    const Button = this.props.button;

    return (
      <Button name={this.props.name}
        label={this.props.label}
        prefix={this.props.prefix}
        suffix={this.props.suffix}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        value={this.getOptionLabel(this.state.value)}
        id={this.props.id}
        required={this.props.required}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        onClick={this.onInputClick}
        inputRef={this.setMenuAnchorRef}
        {...this.props.buttonProps} />
    );
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
    });
  }

  isEmpty() {
    const visibleChildren = React.Children.toArray(this.props.children)
      .filter((child) => child.props.hidden !== true);

    return visibleChildren.length === 0;
  }

  render() {
    return (
      <div className={`wethod-select ${this.props.className}`}>
        {this.getButton()}
        <Menu open={this.state.open} onClose={this.onClose} anchorEl={this.menuAnchorRef} bottom className="wethod-select-menu">
          {this.getMenuContent()}
        </Menu>
      </div>
    );
  }
}

Select.defaultProps = {
  label: null,
  required: undefined,
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  validate: undefined,
  buttonProps: undefined,
  disabled: undefined,
  readOnly: undefined,
  onChange: null,
  value: null,
  placeholder: undefined,
  empty: 'There\'s nothing here',
  resetErrors: null,
  className: '',
};

Select.propTypes = {
  /** Current value. * */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Class to istantiate as button * */
  button: PropTypes.func.isRequired,
  /**
   * List containing options that can be selected.
   */
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  /**
   * Function to call when a new value is selected.
   * @param e {SyntheticEvent}
   * @param option {{}} selected option props
   */
  onChange: PropTypes.func,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /** Properties that need to be passed to button element * */
  buttonProps: PropTypes.shape({
    color: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  /** Content to show when children is empty * */
  empty: PropTypes.node,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  className: PropTypes.string,
};

module.exports = Select;
