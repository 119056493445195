const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const TextArea = require('../../../../../../common/react/inputs/TextArea/BasicTextArea/BasicTextArea.react');
const Select2 = require('../../../../../../common/react/Select2/Select2.react');
const LoadingItem = require('../../../../../../common/react/Select2/LoadingItem.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class ShareProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      recipient: null,
      errors: [],
      feedback: '',
      isLoading: false,
    };

    this.shareableLink = this.getShareableLink();

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onRecipientChange = this.onRecipientChange.bind(this);
    this.onRecipientFilter = this.onRecipientFilter.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
  }

  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  onRecipientChange(name, value) {
    this.setState({ recipient: value });
  }

  onRecipientFilter(name, keyword) {
    this.setState({ isLoading: true });

    AutocompleteModel.getManagers(keyword)
      .done((collection) => this.setState({
        recipients: collection.toJSON(),
        isLoading: false,
      }));
  }

  onValidate(name, errors) {
    this.setState({ errors });
  }

  onSendClick() {
    const path = window.location.host + window.location.pathname;

    const projectId = this.props.data ? this.props.data.id : null;

    this.props.shareProject(this.state.message, this.state.recipient.id,
      path, projectId);

    this.setState({ feedback: 'Sent' });

    this.setAutoClose();
  }

  onCopyClick() {
    this.textAreaRef.select();
    document.execCommand('copy');

    this.setState({ feedback: 'Copied' });

    this.setAutoClose();
  }

  setAutoClose() {
    setTimeout(() => {
      this.props.onCloseClick();
    }, 1000);
  }

  getShareableLink() {
    const path = window.URL;
    const projectId = this.props.data ? this.props.data.id : null;
    return projectId ? `${path}/#pipeline/projects?id=${projectId}` : `${path}/#pipeline/projects`;
  }

  canSend() {
    return this.state.errors.length === 0;
  }

  render() {
    return (
      <Modal title="Share project" onCancelClick={this.props.onCloseClick}>
        <div>
          <textarea
            className="wethod-table__search-copyable-area"
            readOnly
            ref={(ref) => this.textAreaRef = ref}
            value={this.shareableLink} />
          <p>
            <button type="button"
              className="wethod-table__search-copy-button"
              onClick={this.onCopyClick}>Copy to clipboard
            </button>
            or type in your colleague’s name and select &quot;Send&quot;.
          </p>
          <TextArea name="message"
            onChange={this.onMessageChange}
            placeholder="Type a note"
            rows={5} />
          <Select2 onChange={this.onRecipientChange}
            current={this.state.recipient}
            empty={<ShowIf condition={this.state.isLoading}><LoadingItem /></ShowIf>}
            constraints="required"
            name="recipient"
            placeholder="Select colleague"
            onValidate={this.onValidate}
            items={this.state.recipients}
            param="hint"
            onFilter={this.onRecipientFilter} />
        </div>
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onCloseClick}>Cancel</Button>
          <Button onClick={this.onSendClick} disabled={!this.canSend()}>Send</Button>
        </div>
        <div className="wethod-modal2__feedback">
          <ShowIf condition={this.state.feedback !== ''}>
            <span>{this.state.feedback}</span>
          </ShowIf>
        </div>
      </Modal>
    );
  }
};
