const request = require('./Request');

const Token = {
  getPlaceToken(sectionName = '', inputName = '') {
    const query = `?section_name=${sectionName}&input_name=${inputName}`;

    return request({
      method: 'get',
      url: `/token/google-place${query}`,
    });
  },
};

module.exports = Token;
