const React = require('react');

/**
 * Injects pricelists-related permissions into children.
 *
 * @param {boolean} editBudgetPermission
 * @param {boolean} viewPricelistsPermission
 * @param children
 * @param rest
 * @returns {React.DetailedReactHTMLElement<{budgetIsEditable: *}, HTMLElement>}
 * @constructor
 */
const PermissionManager = ({
  editPricelistsPermission, viewPricelistsPermission, children, ...rest
}) => React.cloneElement(children, {
  ...rest,
  canEdit: editPricelistsPermission,
  canView: viewPricelistsPermission,
});

module.exports = PermissionManager;
