const isOrderSentOrApproved = (orderStatus) => orderStatus > 0;

const isPmOrAccount = (order) => {
  if (order && order.project) {
    const idPm = order.project.pm.id;
    const idAccount = order.project.account ? order.project.account.id : null;
    return Wethod.Authorization.amIPmOrAccount(idPm, idAccount);
  }
  return false;
};

module.exports = {
  getPermission: (action, order) => {
    const { getPermission, getPermissionAs } = Wethod.FinanceApp;
    let allowed;
    const amIPmOrAccount = isPmOrAccount(order);
    const isSentOrApproved = order ? isOrderSentOrApproved(order.status) : false;

    switch (action) {
      case 'view':
        allowed = getPermissionAs('view', amIPmOrAccount);
        break;
      case 'edit':
        if (!order || !order.id) allowed = getPermission('create'); // New order
        else if (isSentOrApproved) allowed = getPermission('edit_sent');
        else allowed = getPermissionAs('edit', amIPmOrAccount);
        break;
      case 'edit_sent':
        if (isSentOrApproved) allowed = getPermission('edit_sent');
        else allowed = false;
        break;
      case 'delete':
        if (isSentOrApproved) allowed = getPermission('edit_sent');
        else allowed = getPermissionAs('delete', amIPmOrAccount);
        break;
      case 'send':
        if (isSentOrApproved) {
          allowed = false;
        } else {
          allowed = getPermissionAs('send', amIPmOrAccount);
        }
        break;
      case 'approve':
        allowed = getPermissionAs('edit', amIPmOrAccount);
        break;
      default:
        allowed = getPermissionAs(action, amIPmOrAccount) || false;
        break;
    }

    return allowed;
  },
};
