/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

const Body = require('./DeleteCompanyFailureModalBody.react');

module.exports = class DeleteCompanyFailureModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nextClientId: null,
    };
  }

  getOkActionClassName() {
    let name = 'roundButton okAction';
    if (!this.isNextClientSelected()) name += ' disabled';
    return name;
  }

  getActions() {
    return (
      <div className="clients-modal__actions budget-modal__actions--approve">
        <a
          href="#"
          className="roundButton koAction"
          onClick={this.handleClose.bind(this)}>
          <span className="text">CANCEL</span>
        </a>
        <a
          href="#"
          className={this.getOkActionClassName()}
          style={{ width: 'auto' }}
          onClick={this.handleDeleteAction.bind(this)}>
          <span className="text">DELETE & REPLACE</span>
        </a>
      </div>
    );
  }

  /**
   * Returns true if user has selected the client used to replace the one he's deleting.
   * @returns {boolean}
   */
  isNextClientSelected() {
    return this.state.nextClientId !== null;
  }

  handleDeleteAction(e) {
    e.preventDefault();
    if (!this.props.isWaiting && this.isNextClientSelected()) {
      this.props.replaceClient(this.props.client, this.state.nextClientId);
    }
  }

  handleClose(e) {
    e.preventDefault();
    if (!this.props.isWaiting) this.props.closeModal();
  }

  getTitle() {
    return 'Cannot delete company';
  }

  updateNextClient(name, client) {
    this.setState({
      nextClientId: client ? client.id : null,
    });
  }

  getFeedback() {
    if (this.props.isWaiting) return <span className="wethod-modal2__feedback">Replace & deleting...</span>;
    return '';
  }

  render() {
    return (
      <Modal onCancelClick={this.handleClose.bind(this)}
        title={this.getTitle()}>
        <div>
          <Body
            client={this.props.client}
            projects={this.props.projects}
            contacts={this.props.contacts}
            companies={this.props.companies}
            updateNextClient={this.updateNextClient.bind(this)}
            onClose={this.handleClose.bind(this)} />
          {this.getActions()}
          {this.getFeedback()}
        </div>
      </Modal>
    );
  }
};
