const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');

module.exports = ({ onCancelClick, step }) => {
  const getContent = () => {
    if (step === 'project') {
      return (
        <div className="project-review__modal-content">
          Please assign a vote
          {' '}
          <span className="bold">between 1 and 10</span>
          {' '}
          to each criteria in order to continue.
        </div>
      );
    }
    return (
      <div className="project-review__modal-content">
        <span className="bold">You have one or more incomplete reviews.</span>
        <br />
        {' '}
        Please complete them in order to
        continue.
      </div>
    );
  };

  return (
    <Modal onCancelClick={onCancelClick} title="Missing vote">
      {getContent()}
      <div className="project-review__modal-actions">
        <button type="button" onClick={onCancelClick} className="wethod-button">OK</button>
      </div>
    </Modal>
  );
};
