const React = require('react');

const modals = require('../modals');
const PersonalTokenAddModal = require('../containers/PersonalApiToken/TokenAddModal/TokenAddModal');
const PersonalTokenDeleteModal = require('../containers/PersonalApiToken/TokenDeleteModal/TokenDeleteModal');
const PersonalTokenCreatedModal = require('./PersonalApiToken/TokenCreatedModal/TokenCreatedModal.react');

const availableModals = {
  [modals.PERSONAL_TOKEN_ADD]: PersonalTokenAddModal,
  [modals.PERSONAL_TOKEN_DELETE]: PersonalTokenDeleteModal,
  [modals.PERSONAL_TOKEN_CREATED]: PersonalTokenCreatedModal,
};

module.exports = ({
  toShow,
  closeModal,
  modalData,
}) => {
  const Component = availableModals[toShow];
  if (Component) {
    return <Component closeModal={closeModal} {...modalData} />;
  }

  return null;
};
