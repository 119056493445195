'use strict';

var API = {
  getColumns: function (section) {
    var defer = $.Deferred();
    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: APIURL + '/section-columns/' + section,
      xhrFields: {
        withCredentials: true,
      },
    }).done(function (response) {
      defer.resolve(response.data);
    }).fail(function (response) {
      defer.reject(response);
    });
    return defer.promise();
  },
  updateColumns: function (section, columns) {
    var defer = $.Deferred();
    $.ajax({
      method: 'PATCH',
      crossDomain: true,
      url: APIURL + '/section-columns/' + section,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify({ columns: columns }),
    }).done(function (response) {
      defer.resolve(response.data);
    }).fail(function (response) {
      defer.reject(response);
    });
    return defer.promise();
  },
};

Wethod.reqres.setHandler('section:columns:get', function (section) {
  return API.getColumns(section);
});
Wethod.reqres.setHandler('section:columns:update', function (section, columns) {
  return API.updateColumns(section, columns);
});
