const { connect } = require('react-redux');
const Component = require('../../../components/TextArea/TextAreaModal/TextAreaModalBodyView.react');

const mapStateToProps = (state) => ({
  summary: state.concept.summary,
  description: state.concept.description,
  canEdit: state.canEdit,
});

module.exports = connect(mapStateToProps)(Component);
