'use strict';

Wethod.module('View.Autocomplete', function (Autocomplete, Wethod, Backbone, Marionette) {
  this.BaseEmptyView = Marionette.ItemView.extend({
    template: '#autocompleteEmptyTemplate',
    tagName: 'li',
    className: 'recordList__record__hints__list__empty',
  });

  this.BaseLoadingView = Marionette.ItemView.extend({
    template: '#autocompleteLoadingTemplate',
    tagName: 'li',
    className: 'recordList__record__hints__message',
  });

  this.ModalEmptyView = Marionette.ItemView.extend({
    template: '#autocompleteEmptyTemplate',
    className: 'messageModal__body__autocomplete__notFound',
  });

  this.ModalLoadingView = Marionette.ItemView.extend({
    template: '#autocompleteLoadingTemplate',
    className: 'messageModal__body__autocomplete__loading',
  });
});
