const request = require('./Request');

const OrderPlanSettings = {
  getPreference() {
    return request({
      method: 'get',
      url: '/authorization/settings/order-view',
    });
  },
  setPreference(preference) {
    return request({
      method: 'post',
      url: '/authorization/settings/order-view',
      data: preference,
    });
  },
};

module.exports = OrderPlanSettings;
