const React = require('react');
const PropTypes = require('prop-types');

const VirtualListContent = ({ height, children }) => {
  const style = {
    height,
  };

  return (
    <div className="wethod-virtual-list__content" style={style}>
      {children}
    </div>
  );
};

VirtualListContent.defaultProps = {
  children: null,
};

VirtualListContent.propTypes = {
  /**
   * The total height of the content as if all items where actually displayed, in pixels.
   */
  height: PropTypes.number.isRequired,
  /**
   * Nodes to use as list items.
   */
  children: PropTypes.node,
};

module.exports = VirtualListContent;
