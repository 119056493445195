const React = require('react');
const PropTypes = require('prop-types');

const ModalFooter = ({ feedback, children, error }) => (
  <div className="wethod-modal__footer">
    <div className="wethod-modal__footer-actions">{children}</div>
    <div className="wethod-modal__footer-feedback wethod-modal__footer-feedback--info">{feedback}</div>
    <div className="wethod-modal__footer-feedback wethod-modal__footer-feedback--error">{error}</div>
  </div>
);

ModalFooter.defaultProps = {
  feedback: '',
  error: '',
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  feedback: PropTypes.string,
  error: PropTypes.string,
};

module.exports = ModalFooter;
