const React = require('react');
const _ = require('underscore');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('../../../../../../common/react/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class AccountSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getAccounts(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    let account = null;

    if (item) {
      account = {
        id: value,
        name: item.name,
        surname: item.surname,
      };
    }

    this.props.onChange(e, account);
  }

  getOptions() {
    return this.state.availableItems.map((account) => {
      const splitted = account.hint.split(' ');
      const name = splitted[0];
      splitted.splice(0, 1);
      const surname = splitted.join(' ');

      return (
        <SelectItem key={account.id}
          value={account.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  getValue() {
    const { id } = this.props.value;
    if (id === null) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <Autocomplete readOnly={this.props.readOnly}
        onChange={this.onChange.bind(this)}
        value={this.getValue()}
        getInputValue={AccountSelect.getInputValue}
        name={this.props.name}
        label="Account"
        id="sidebar-account"
        onFilter={this.onFilter.bind(this)}
        errorText={this.props.error}
        loading={this.state.isLoading}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
};
