/* eslint-disable react/no-access-state-in-setstate,consistent-return,react/sort-comp,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class ProductionTrend extends React.Component {
  constructor(props) {
    super(props);

    this.seriesWithLabel = ['Production', 'Avg Revenues', 'Projection'];
  }

  /**
   * Check if the value is significant (not null, undefined or zero)
   * @param value
   * @returns boolean
   */
  isSignificantValue(value) {
    return value && value !== 0;
  }

  getLastSignificantValue() {
    return this.props.weeks.slice().reverse().find((week) => this.isSignificantValue(week.value));
  }

  format(value) {
    return `${numeral(value / 1000).format('0,0.[0]')} K`;
  }

  tooltipFormatter(point) {
    const pointDate = new Date(point.key);
    const start = moment(pointDate).startOf('isoWeek');
    const end = moment(pointDate).endOf('isoWeek');
    const formatted = `${start.format('YYYY-MM-DD')}/${end.format('DD')}`;
    return `<div>
                ${formatted}<br/>
                <b>${this.format(point.y)}</b>
            </div>`;
  }

  /**
   * Returns a formatted label for the given point.
   *
   * @param point
   * @param series
   * @param x
   * @param y
   * @param lastLabelIndex
   * @returns {string}
   */
  getPointLabel(point, series, x, y, lastLabelIndex) {
    if (point.index === lastLabelIndex && this.seriesWithLabel.includes(series.name)) { // is last point of certain series
      return `${this.format(y)}`;
    }
    return '';
  }

  getHorizontalData(futureWeeks) {
    const lastSignificantValue = this.getLastSignificantValue();

    if (lastSignificantValue && futureWeeks) {
      return futureWeeks.map((week) => ({
        week: week.week,
        value: lastSignificantValue.value,
      }));
    }
    return [];
  }

  getSecondarySeries() {
    const hData = this.getHorizontalData(this.props.fullPlannedWeeks);
    const hSeries = {
      name: 'Projection',
      data: hData,
      dashStyle: 'shortdash',
      color: '#9B9B9B',
      enableMouseTracking: false,
      visible: false,
    };

    const avgRevSeries = {
      name: 'Avg Revenues',
      data: this.props.avgRevenues,
      dashStyle: 'shortdot',
      color: '#48A5F9',
      enableMouseTracking: false,
    };

    const fullSeries = {
      name: 'Planned product (Full)',
      data: this.props.fullPlannedWeeks,
      dashStyle: 'shortdash',
    };

    const coreSeries = {
      name: 'Planned product (Core)',
      data: this.props.corePlannedWeeks,
      dashStyle: 'shortdot',
      visible: false,
    };

    return [hSeries, avgRevSeries, fullSeries, coreSeries];
  }

  getLegend() {
    return {
      itemStyle: {
        fontWeight: 100,
        fontSize: 11,
      },
      align: 'left',
    };
  }

  render() {
    return (
      <Trend
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        title="Weekly Production"
        seriesName="Production"
        description=""
        className="backlog-trend--production"
        getPointLabel={this.getPointLabel.bind(this)}
        secondarySeries={this.getSecondarySeries()}
        legend={this.getLegend()}
        {...this.props} />
    );
  }
};
