/* eslint-disable react/sort-comp */
const React = require('react');
const StatusListMenu = require('../SupplierStatusList.react');

/**
 * An outlined button select component with color dot on the side and colored select.
 *
 * PROPS
 * name: string
 * value: object corresponding to current selected object, required a 'name' attribute that will be
 * shown and a 'color' items: list of items to be shown in the picklist, required a 'name'
 * attribute that will be shown and a 'color' required: boolean
 *
 * onChange
 * @type {StatusSelectButton}
 */
module.exports = class StatusSelectButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  componentDidMount() {
    this.validate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) this.validate();
  }

  onCloseMenu() {
    if (this.props.onBlur) this.props.onBlur();
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    if (this.props.onFocus) this.props.onFocus();
    this.setState({ showMenu: true });
  }

  handleItemChange(item) {
    this.setState({ showMenu: false });
    if (item && item.id !== 'no-status') this.props.onChange(this.props.name, item);
  }

  validate() {
    const errors = [];
    if (this.props.required) if (!this.props.value) errors.push('required');
    if (this.props.onValidate) this.props.onValidate(this.props.name, errors);
  }

  getItems() {
    if (this.props.items && this.props.items.length > 0) return this.props.items;
    return [{
      id: 'no-status',
      name: 'No status available',
    }];
  }

  getStyle() {
    let style = 'material-input-button';
    if (!this.props.canSetStatus) style += ' suppliers__status--not-editable';
    return style;
  }

  render() {
    const selectedValue = this.props.value ? this.props.value : {};
    return (
      <div>
        <button type="button"
          ref={(el) => this.button = el}
          className={this.getStyle()}
          onClick={this.onMenuButtonClick.bind(this)}>
          <span>{selectedValue.name}</span>
          <span style={{ backgroundColor: selectedValue.color }}
            className="material-input__dot" />
        </button>
        <StatusListMenu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.button}
          statusList={this.getItems()}
          onStatusChange={this.handleItemChange.bind(this)} />
      </div>
    );
  }
};
