/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SearchBox = require('../containers/SearchBox');

module.exports = class HistoryActions extends React.Component {
  render() {
    return (
      <div className="timeline-history-actions">
        <SearchBox />
      </div>
    );
  }
};
