/* eslint-disable class-methods-use-this */
const React = require('react');
const Avatar = require('../../../../../../common/react/AvatarBox/Avatar.react');

module.exports = class FavouriteCompany extends React.Component {
  handleFavouriteDelete() {
    this.props.deleteFavourite(this.props.client.id, this.props.type);
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  render() {
    return (
      <div className="desk-widget__item desk-widget-pinboard__item">
        <div className="desk-widget-pinboard__pic">
          <Avatar name={this.props.client.name} />
        </div>
        <div className="desk-widget__multirow-info desk-widget-pinboard__info">
          <span className="desk-widget__text">{this.getUnescapedValue(this.props.client.name)}</span>
        </div>
        <div className="desk-widget-pinboard__actions">
          <a
            className="wethod-icon-button wethod-icon-button--no-border"
            href={`#finance/client/company/${this.props.client.id}`}>
            <div className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
          <span className="desk-widget-pinboard__vertical-bar" />
          <button type="button"
            className="wethod-icon-button wethod-icon-button--no-border desk-widget-pinboard__actions--bookmark"
            onClick={this.handleFavouriteDelete.bind(this)}>
            <div className="wethod-icon wethod-icon-bookmark-full wethod-icon-bookmark-full--black" />
          </button>
        </div>
      </div>
    );
  }
};
