const { connect } = require('react-redux');
const Component = require('../../../../../../../../common/react/AdvancedSearch/filters/RadioFilter/RadioFilter.react');

const weeklyStrategyOptions = [{
  label: 'Daily',
  value: 'daily',
}, {
  label: 'Weekly',
  value: 'weekly',
}, {
  label: 'All',
  value: 'all',
}];

const dailyStrategyOptions = [{
  label: 'Detailed',
  value: 'detailed',
}, {
  label: 'All',
  value: 'all',
}];

const mapStateToProps = (state) => ({
  availableOptions: state.strategy === 'weekly' ? weeklyStrategyOptions : dailyStrategyOptions,
});

module.exports = connect(mapStateToProps)(Component);
