/* eslint-disable no-unused-vars */
const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../Input/Input.react');

const SelectButton = ({
  name,
  id,
  onClick,
  disabled,
  readOnly,
  children,
  onFocus,
  onBlur,
  placeholder,
  value,
  label,
}) => {
  const isActive = !disabled && !readOnly;

  function handleClick(e) {
    if (isActive) {
      onClick(e);
    }
  }

  const isEmpty = () => value === '' || value === null;

  const displayPlaceholder = isEmpty() && placeholder;

  const getChildren = () => (displayPlaceholder ? placeholder : children);

  const getClassName = () => {
    const baseClass = 'wethod-input-root wethod-select__button';
    if (displayPlaceholder) {
      return `${baseClass} wethod-select__button--placeholder`;
    }
    return baseClass;
  };

  // We need an hidden input in order to capture click on related label, if exists.
  return (
    <div
      role="button"
      name={name}
      aria-label={label}
      tabIndex={0}
      className={getClassName()}
      onClick={handleClick}
      onKeyPress={handleClick}>
      {getChildren()}
      <Input id={id} className="wethod-select__hidden-input" value={value} readOnly />
    </div>
  );
};

SelectButton.defaultProps = {
  id: undefined,
  name: undefined,
  disabled: undefined,
  readOnly: undefined,
  onFocus: null,
  onBlur: null,
  placeholder: undefined,
  value: '',
  label: null,
};

SelectButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
};

module.exports = SelectButton;
