/**
 *
 * @param items
 * @returns {Array} items sorted by favourite
 */
module.exports = (items) => items.sort((a, b) => {
  if (a.favorite.id && !b.favorite.id) return -1;
  if (b.favorite.id && !a.favorite.id) return 1;
  return 0;
});
