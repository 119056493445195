const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../../../Menu/ListMenu/ListMenu.react');
const Item = require('./CollaboratorsMenuItem.react');

const CollaboratorsMenu = ({
  open,
  anchorEl,
  onClose,
  collaborators,
}) => {
  const items = collaborators.map((collaborator) => (
    <Item key={collaborator.id}
      surname={collaborator.surname}
      name={collaborator.name}
      pic={collaborator.pic} />
  ));

  return (
    <Menu open={open} className="wethod-collaborators__menu" anchorEl={anchorEl} onClose={onClose} bottom>
      {items}
    </Menu>
  );
};

CollaboratorsMenu.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    pic: PropTypes.string,
  })).isRequired,
  open: PropTypes.bool.isRequired,
  /**
   * The function to call when menu is closed.
   * @see Menu
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The DOM element used to set the position of the menu.
   * @see Menu
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

module.exports = CollaboratorsMenu;
