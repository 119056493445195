/* eslint-disable react/sort-comp,react/no-did-update-set-state,guard-for-in,no-restricted-syntax,
react/no-access-state-in-setstate */
const React = require('react');
const SecondaryInfo = require('../../containers/SecondaryInfo/SecondaryInfo');
const SecondaryInfoEditable = require('../../containers/SecondaryInfo/SecondaryInfoEditable');
const Card = require('../CollapsibleCard.react');

module.exports = class SecondaryInfoController extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      collapsed: false,
      errors: {},
      person: {},
      phone: {},
    };

    this.messages = {
      email: {
        required: 'Please add an email address',
        email: 'Please provide a valid email address',
      },
    };
  }

  hasBeenSaved(prevProps, prevState) {
    return (prevState.editable && prevProps.isSaving)
      && !this.props.isSaving;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.hasBeenSaved(prevProps, prevState)) this.setState({ editable: false });
  }

  getErrorMessages() {
    const errors = { ...this.state.errors };
    for (const error in errors) {
      errors[error] = errors[error].map((constraint) => this.messages[error][constraint]);
    }
    return errors;
  }

  /**
   * Returns true if all inputs are valid.
   * @returns {boolean}
   */
  isValid() {
    const { errors } = this.state;
    for (const error in errors) {
      if (errors[error].length > 0) return false;
    }
    return true;
  }

  onValidate(name, inputErrors) {
    const { errors } = this.state;
    errors[name] = inputErrors;
    this.setState({ errors });
  }

  onChange(name, value) {
    const person = { ...this.state.person };
    person[name] = value;
    this.setState({ person });
  }

  onPhoneChange(phone) {
    this.setState({ phone });
  }

  getFeedback() {
    if (this.props.isSaving) return 'Saving ';
    return '';
  }

  getContent() {
    if (this.state.editable) {
      return (
        <SecondaryInfoEditable
          errorMessages={this.getErrorMessages()}
          onValidate={this.onValidate.bind(this)}
          onChange={this.onChange.bind(this)}
          onPhoneChange={this.onPhoneChange.bind(this)} />
      );
    }
    return <SecondaryInfo />;
  }

  onEditClick() {
    this.setState({
      editable: true,
      collapsed: false,
    });
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  savePhone() {
    if (!this.state.phone.id) {
      this.props.addPhone(this.props.person.id, this.state.phone);
    } else if (SecondaryInfoController.isDirty(this.state.phone.number)) {
      this.props.updatePhone(this.state.phone);
    } else {
      this.props.deletePhone(this.state.phone);
    }
  }

  onSaveClick() {
    if (this.isValid()) {
      this.props.save({ ...this.props.person, ...this.state.person });
      this.savePhone();
    }
  }

  render() {
    return (
      <Card allowEdit
        editable={this.state.editable}
        title="Contact details"
        collapsed={this.state.collapsed}
        feedback={this.getFeedback()}
        toggleCollapsed={this.toggleCollapsed.bind(this)}
        onEditClick={this.onEditClick.bind(this)}
        onSaveClick={this.onSaveClick.bind(this)}>
        {this.getContent()}
      </Card>
    );
  }
};
