/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class CalendarColumnWeek extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__calendar-content-column project-canvas-gantt__calendar-column--week" />
    );
  }
};
