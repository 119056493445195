const { connect } = require('react-redux');
const Component = require('../components/Languages.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'knowledge').length > 0,
  languages: state.languages,
  employees: state.languageFilteredEmployees,
  empSize: state.loadLanguageEmployees.size,
  empPage: state.loadLanguageEmployees.page,
});

const mapDispatchToProps = (dispatch) => ({
  loadEmployees: (size, page) => dispatch(actions.loadLanguageEmployees(size, page)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
