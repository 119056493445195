const React = require('react');
const Row = require('../../../../../../common/react/sidebar/SidebarInputRow.react');
const Select = require('./PipedriveDealSelect.react');
const Tag = require('../../../../../../common/react/Tag/Tag.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const PipedriveDealsField = ({ name, value, client, readOnly, onChange }) => {
  /**
   * Check if the project has linked deals
   * @returns {boolean}
   */
  const hasValue = () => value && value.length > 0;

  /**
   * Get the list of deals linked to the project
   * @returns {*|*[]}
   */
  const getValues = () => (hasValue() ? value : []);

  /**
   * Check if the given deal is already selected and linked to the project
   * @param item
   */
  function isDuplicated(item) {
    return getValues().some((deal) => deal.id === item.id);
  }

  /**
   * Triggers the onChange when a deal is selected from the search list,
   * adding the deal to the list of linked ones.
   * The deal is not added if it was already linked to the project.
   * @param e
   * @param deal
   */
  function handleDealSelect(e, deal) {
    // Do not add duplicate deals
    if (!isDuplicated(deal)) {
      const newValue = getValues().concat(deal);

      onChange(name, newValue);
    }
  }

  /**
   * Triggers the onChange when a deal is deleted from the list of chips,
   * removing the deal from the list of linked ones.
   * @param id
   */
  function handleDealDelete(id) {
    const newValue = getValues().filter((val) => val.id !== id);

    onChange(name, newValue);
  }

  /**
   * Gives the list of chips corresponding to the deals linked to the project.
   * @returns {unknown[]}
   */
  function getTags() {
    return getValues()
      .map((deal) => (
        <Tag key={deal.id} id={deal.id} disabled={readOnly} onDelete={handleDealDelete}>
          {deal.title}
        </Tag>
      ));
  }

  return (
    <div>
      <h3 className="wethod-sidebar__subtitle">Linked Pipedrive deals</h3>
      <ShowIf condition={!readOnly}>
        <Row className="wethod-sidebar__multiple-input-row--space-bottom">
          <div>
            <div className="pipeline__sidebar-description pipeline__sidebar-description--bottom-spaced">
              Select a Pipedrive deal to link to this project.<br />
              Only deals with the same client are available.
            </div>
            <Select
              name="pipedrive-search"
              client={client}
              readOnly={readOnly}
              onChange={handleDealSelect} />
          </div>
        </Row>
      </ShowIf>
      <ShowIf condition={hasValue()}
        showElse={(
          <Row>
            <div className="pipeline__sidebar-description pipeline__sidebar-description--bottom-spaced">
              No linked deals yet.
            </div>
          </Row>
        )}>
        <Row>
          <div>{getTags()}</div>
        </Row>
      </ShowIf>
    </div>
  );
};

module.exports = PipedriveDealsField;
