'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Authorization = require('../../models/Authorization');
var ProductStore = require('../../apps/core/modules/products/store');
var ProductReducer = require('../../apps/core/modules/products/reducer');
var Product = require('../../apps/core/modules/products/index');

Wethod.module('ProductApp', function (ProductApp, Wethod, Backbone, Marionette, $) {
  ProductApp.Controller = {
    show: function () {
      $.when(Authorization.getPermissions('product')).done(function (permissions) {
        if (!permissions.view) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }
        var layout = new ProductApp.StructureLayoutView();
        layout.render();

        var reducerWrapper = new ProductReducer({
          permissions: permissions,
          availabilityFilter: 'true',
        });
        var store = ProductStore(reducerWrapper.reduxReducer);
        var element = React.createElement(Product, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
  };
});
