const { connect } = require('react-redux');
const Component = require('../components/Info.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  ...state.info,
  isReviewEnabled: state.isReviewEnabled,
  canReview: state.canReview,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (data) => dispatch(actions.showReviewRequestModal(data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
