'use strict';

Wethod.module('SettingsApp.Integration', function (Integration, Wethod, Backbone, Marionette, $) {
  this.IntegrationModel = Wethod.Utility.BaseModel.extend({});

  this.IntegrationCollection = Wethod.Utility.BaseCollection.extend({
    model: Integration.IntegrationModel,
    url: APIURL + '/integrations/',
  });

  this.ExternalDriveDefaultFolderModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/external-drive/canvas/',
  });

  this.ExternalDriveDefaultFolderCollection = Wethod.Utility.BaseCollection.extend({
    model: Integration.ExternalDriveDefaultFolderModel,
    url: APIURL + '/external-drive/canvas/',
  });

  var API = {
    getIntegrations: function () {
      var invitations = new Integration.IntegrationCollection();
      var defer = $.Deferred();

      invitations.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveExternalDriveDefaultFolder: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    deleteExternalDriveDefaultFolder: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    enableGoogleIntegration: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/google_oauth/auth-url?scope=' + options.scope + '&referer=' + options.referer,
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 500) {
          defer.resolve(undefined);
        } else if (response.code === 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    disableGoogleIntegration: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/google-calendar/timeline/disable',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 500) {
          defer.resolve(undefined);
        } else if (response.code === 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getGoogleToken: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/google_oauth/token?scope=' + options.scope,
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 500) {
          defer.resolve({ token: null });
        } else if (response.code === 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    enableGoogleDriveCanvasIntegration: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/google-drive/canvas/enable',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify({ root_id: options.rootId }),
        dataType: 'json',
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 500) {
          var reason = response.data ? response.data.reason : null;

          defer.resolve({
            token: null,
            reason: reason,
          });
        } else if (response.code === 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    disableGoogleDriveCanvasIntegration: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/google-drive/canvas/disable',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
      }).done(function () {
        defer.resolve();
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },

  };

  // archive
  Wethod.reqres.setHandler('integration:all', function () {
    return API.getIntegrations();
  });
  Wethod.reqres.setHandler('integration:enable:google', function (options) {
    return API.enableGoogleIntegration(options);
  });
  Wethod.reqres.setHandler('integration:disable:google', function (options) {
    return API.disableGoogleIntegration(options);
  });
  Wethod.reqres.setHandler('integration:google:token:get', function (options) {
    return API.getGoogleToken(options);
  });
  Wethod.reqres.setHandler('integration:google:drive:canvas:enable', function (options) {
    return API.enableGoogleDriveCanvasIntegration(options);
  });
  Wethod.reqres.setHandler('integration:google:drive:canvas:disable', function () {
    return API.disableGoogleDriveCanvasIntegration();
  });
  Wethod.reqres.setHandler('integration:external:drive:canvas:default-folder:save', function (model) {
    return API.saveExternalDriveDefaultFolder(model);
  });
  Wethod.reqres.setHandler('integration:external:drive:canvas:default-folder:delete', function (model) {
    return API.deleteExternalDriveDefaultFolder(model);
  });
});
