/* eslint-disable class-methods-use-this,react/sort-comp,consistent-return */
const React = require('react');
const EventForm = require('../../../containers/FollowUp/EventForm');
const EventFormUpdate = require('../../../containers/FollowUp/EventFormUpdate');
const FollowUp = require('../../../containers/FollowUp/FollowUp');

module.exports = class FollowUpController extends React.Component {
  exists() {
    return this.props.event.followed_by !== null;
  }

  getFollowUp() {
    for (let i = 0; i < this.props.events.length; i++) {
      if (this.props.events[i].event.id === this.props.event.followed_by) {
        return this.props.events[i].event;
      }
    }
  }

  render() {
    if (this.props.showForm) {
      if (this.exists()) {
        return (
          <EventFormUpdate
            parentId={this.props.event.id}
            inputs={['type-select', 'datetime-picker', 'description']}
            closeForm={this.props.hideFollowUp}
            {...this.getFollowUp()} />
        );
      }
      return (
        <EventForm
          parentId={this.props.event.id}
          inputs={['type-select', 'datetime-picker', 'description']}
          closeForm={this.props.hideFollowUp}
          {...this.getFollowUp()} />
      );
    }
    if (this.exists()) {
      return <FollowUp {...this.getFollowUp()} openEditForm={this.props.openEditForm} />;
    }
    return null;
  }
};
