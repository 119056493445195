'use strict';

Wethod.module('TimeOffApp', function (TimeOffApp) {
  this.startWithParent = false;

  var API = {
    showTimeOffManager: function () {
      TimeOffApp.TimeOff.Controller.showTimeOffManager();
    },
  };

  TimeOffApp.on('start', function (options) {
    API[options.foo]();
  });
});
