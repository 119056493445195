// eslint-disable-next-line no-unused-vars
const SummaryTotal = require('../NodeTotal/SummaryNodeTotal');
const TaskNode = require('./TaskNode');
const Total = require('../NodeTotal/TaskNodeTotal');

module.exports = class DiscountTaskNode extends TaskNode {
  /**
   * @param task
   * @param levelAmounts
   * @param {SummaryTotal} subtotal
   * @param {AvailableLevel[]} availableLevels
   */
  constructor(task, levelAmounts, subtotal, availableLevels) {
    super(task, levelAmounts, availableLevels);
    this.subtotal = subtotal;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  getQuantity() {
    return super.getQuantity();
  }

  getPrice() {
    return -(this.subtotal.getPrice() / 100) * this.getQuantity();
  }

  /**
   * @private
   * @returns {Total}
   */
  calculateTotal() {
    return new Total(0, 0, this.getPrice(), 0, this.getDaysByLevel());
  }
};
