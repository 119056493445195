const HTTPService = require('../../../../../../../services/HTTPService');
const ParserService = require('../../../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes) {
    const { date } = attributes;
    this.urlRoot = `${APIURL}/brief/?date=${date}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  getModel(useCache = true) {
    if (!useCache) {
      this.urlRoot += '&cache=false';
    }

    const defer = $.Deferred();

    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: this.urlRoot,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response);
    }).fail(() => {
      defer.resolve(undefined);
    });

    return defer.promise();
  },
});
