/* eslint-disable react/prop-types */
const React = require('react');
const Chart = require('./Chart.react');
const formatService = require('../../../../../services/FormatService');
const dateService = require('../../../../../services/DateService');

module.exports = class Body extends React.Component {
  getYearProgress() {
    const year = moment().year();
    const weeksInYear = dateService.getWeeksInYear(year);
    const pastWeeks = weeksInYear - this.props.weeksToGo;

    return (pastWeeks / weeksInYear) * 100;
  }

  getRevenuePipelineProgress() {
    return (this.props.revenuePipeline / this.props.target) * 100;
  }

  getProductionValueProgress() {
    return (this.props.productionValue / this.props.target) * 100;
  }

  render() {
    return (
      <div>
        <div className="widget-revenue-clock__chart-container">
          <Chart timeProgress={this.getYearProgress()}
            revenuePipelineProgress={this.getRevenuePipelineProgress()}
            productionValueProgress={this.getProductionValueProgress()} />
          <div className="widget-revenue-clock__target">
            <div className="widget-revenue-clock__target-title">Target</div>
            <div
              className="widget-revenue-clock__target-value">
              {formatService.formatByStep(this.props.target, 1)}
            </div>
          </div>
        </div>
        <div className="widget-revenue-clock__details">
          <div className="widget-revenue-clock__details-item">
            <div
              className="widget-revenue-clock__details-item-title widget-revenue-clock__details-item-title--revenue-pipeline">
              Revenue
              pipeline
            </div>
            <div
              className="widget-revenue-clock__details-item-value">
              {formatService.formatByStep(this.props.revenuePipeline, 1)}
            </div>
          </div>
          <div className="widget-revenue-clock__details-item">
            <div
              className="widget-revenue-clock__details-item-title widget-revenue-clock__details-item-title--production-value">
              Production
              value
            </div>
            <div
              className="widget-revenue-clock__details-item-value">
              {formatService.formatByStep(this.props.productionValue, 1)}
            </div>
          </div>
          <div className="widget-revenue-clock__details-item">
            <div className="widget-revenue-clock__details-item-title">Weeks to go</div>
            <div className="widget-revenue-clock__details-item-value">{this.props.weeksToGo}</div>
          </div>
        </div>
      </div>
    );
  }
};
