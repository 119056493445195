const { connect } = require('react-redux');
const Component = require('../components/RiskMenu.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  items: state.risks,
  anchorEl: state.riskSelect ? state.riskSelect.anchorEl : null,
  data: state.riskSelect ? state.riskSelect.data : null,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeRiskSelect()),
  saveRisk: (options) => dispatch(actions.saveRisk(options)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
