const React = require('react');
const moment = require('moment');
const formatNumber = require('../../../../../services/FormatService').formatDecimalNumber;
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const SidebarButton = require('../../../../../common/react/OpenSidebarButton.react');
const Tooltip = require('../../../../../common/react/TooltipFixed/Tooltip.react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');

const ProductRow = ({ showSidebar, product }) => {
  function getFormattedValue(value) {
    return value ? formatNumber(value, 2, false) : '-';
  }

  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  function openProductSidebar() {
    showSidebar(product.id);
  }

  function priceCell() {
    return <div className="products__column-price--tooltip">{getFormattedValue(product.price)}</div>;
  }

  return (
    <TableRow>
      <TableCell className="products__column-product">{product.name}</TableCell>
      <TableCell className="products__column-description">{product.description}</TableCell>
      <TableCell className="products__column-date-from">{formatDate(product.available_from)}</TableCell>
      <TableCell className="products__column-date-to">{formatDate(product.available_to)}</TableCell>
      <TableCell className="products__column-external-costs">{getFormattedValue(product.external_cost)}</TableCell>
      <TableCell className="products__column-price">
        <ShowIf condition={!product.price} showElse={priceCell()}>
          <Tooltip label="The price will be defined by the price list set in the budget">
            {priceCell()}
          </Tooltip>
        </ShowIf>
      </TableCell>
      <TableCell className="products__column-actions">
        <SidebarButton onClick={openProductSidebar} />
      </TableCell>
    </TableRow>
  );
};

module.exports = ProductRow;
