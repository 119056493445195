const { connect } = require('react-redux');
const Component = require('../../../components/modals/Interests/InterestsBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  tags: state.interests,
  knownTags: state.known_interests,
  waiting: state.waiting,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  addInterest: (interest, employeeId) => dispatch(actions.addInterest(interest, employeeId)),
  deleteInterest: (interest, employeeId) => dispatch(actions.deleteInterest(interest, employeeId)),
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
