const React = require('react');
const withRandomize = require('../../../../common/HOCs/withRandomize');

class Headline extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = [
      'this is a monthly flash report that gives an overview of the most important data, helping you analyze trends and progress of the last month so you can draw insights. It’s a summary of sales, operations and financial results. Enjoy!',
    ];
  }

  render() {
    return (
      <span className="briefing-content__atom">{this.props.randomize(this.alternatives)}</span>
    );
  }
}

module.exports = withRandomize(Headline);
