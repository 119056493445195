const React = require('react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');

const VersionNavigator = ({ scrollForward, scrollBack, canGoBack, canGoForward }) => (
  <div className="wethod-budget-versions__navigator">
    <IconButtonTooltip label="Show newer versions"
      disabled={!canGoBack}
      borderless
      onClick={scrollBack}>
      <Icon icon="back" />
    </IconButtonTooltip>
    <IconButtonTooltip label="Show older versions"
      disabled={!canGoForward}
      borderless
      onClick={scrollForward}>
      <Icon icon="forward" />
    </IconButtonTooltip>
  </div>
);

module.exports = VersionNavigator;
