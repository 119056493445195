/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Actions extends React.Component {
  render() {
    return (
      <div className="timeline-event-form-actions">
        <div className="timeline-event-form-actions--left">
          {this.props.feedback}
        </div>
        <div className="timeline-event-form-actions--right">
          <div className="wethod-button" onClick={this.props.onCancelClick}>Cancel</div>
          <div className="wethod-button" onClick={this.props.onSaveClick}>Save</div>
        </div>
      </div>
    );
  }
};
