'use strict';

Wethod.module('FinanceApp', function (FinanceApp, Wethod, Backbone, Marionette) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#financeTemplate',
    regions: {
      financeBody: '[data-region="financeBody"]',
    },
  });
});
