const React = require('react');
const PropTypes = require('prop-types');
const MonthSelect = require('../../../inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const IconButton = require('../../../TooltipFixed/IconButtonTooltip.react');

const DateRangeDateSelect = ({ id, onChange, onClear, name, value }) => {
  function onClearClick() {
    onClear(name);
  }

  return (
    <div className="wethod-advanced-search-filter-date-range__date-select">
      <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
      <MonthSelect name={name}
        onChange={onChange}
        value={value}
        id={id}
        label="Month" />
      <IconButton label="Clear"
        onClick={onClearClick}
        borderless>
        <span className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--black" />
      </IconButton>
    </div>
  );
};

DateRangeDateSelect.defaultProps = {
  value: '',
  onChange: null,
  onClear: null,
};

DateRangeDateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  /**
   * Function to call when "clear" button is clicked.
   */
  onClear: PropTypes.func,
};

module.exports = DateRangeDateSelect;
