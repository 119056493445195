const { connect } = require('react-redux');
const Component = require('../components/InOutTrend.react');

const mapStateToProps = (state) => ({
  peopleIn: state.in_and_out ? state.in_and_out.data.in : [],
  peopleOut: state.in_and_out ? state.in_and_out.data.out : [],
  timeFilter: state.timeFilter,
});

module.exports = connect(mapStateToProps, null)(Component);
