const React = require('react');
const Language = require('../../../containers/modals/Languages/Language');

module.exports = class LanguagesBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      newLanguage: null,
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, prevContext) {
    const creatingUpdated = !this.props.creating && prevProps.creating;
    const waitingUpdated = !this.props.waiting && prevProps.waiting;

    if (creatingUpdated || waitingUpdated) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ newLanguage: null });
    }
  }

  onAddLanguageClick() {
    this.setState({
      newLanguage: {
        id: _.uniqueId('employee-new-language-'),
        name: null,
        level: null,
        isFake: true,
      },
    });
  }

  onDoneClick() {
    this.props.hideModal();
  }

  getLanguages() {
    const savedLanguages = this.props.languages;
    const { newLanguage } = this.state;
    const languages = newLanguage ? [...savedLanguages, newLanguage] : savedLanguages;

    return languages.map((language) => {
      let disabled = false;
      if (language.isFake && (this.props.waiting || this.props.creating)) {
        disabled = true;
      }
      return (
        <Language key={language.id}
          updateFormErrors={this.updateFormErrors.bind(this)}
          addLanguage={this.saveLanguage.bind(this)}
          deleteLanguage={this.deleteLanguage.bind(this)}
          disabled={disabled}
          languages={savedLanguages}
          {...language} />
      );
    });
  }

  getFeedback() {
    if (this.props.waiting) {
      return <div className="wethod-modal2__feedback">Saving...</div>;
    }
    if (this.props.creating) {
      return <div className="wethod-modal2__feedback">Creating...</div>;
    }
    if (this.state.errors.length > 0) {
      return (
        <div className="wethod-modal2__feedback wethod-modal2__feedback--error">
          Please provide a name and a level
          for each language
        </div>
      );
    }
    return null;
  }

  getCollectionHeader() {
    const savedLanguages = this.props.languages;
    const { newLanguage } = this.state;
    const languages = newLanguage ? [...savedLanguages, newLanguage] : savedLanguages;

    if (languages.length > 0) {
      return (
        <div className="wethod-modal2-list__header">
          <span className="wethod-modal2-list__header-item profile-languages__item-language">Language</span>
          <span className="wethod-modal2-list__header-item profile-languages__item-level">Level</span>
        </div>
      );
    }
    return null;
  }

  getAddLanguageButtonStyle() {
    const fakeLanguage = this.state.newLanguage !== null;
    const disabled = this.props.waiting || this.props.creating || fakeLanguage ? 'profile-languages__add--disabled' : '';
    return `profile-languages__add wethod-button ${disabled}`;
  }

  saveLanguage(language) {
    this.props.addLanguage(language);
  }

  deleteLanguage(language) {
    if (!language.isFake) {
      this.props.deleteLanguage(language);
    }
    this.setState({ newLanguage: null });
  }

  /**
   * @param errorId
   * @param type ('add' | 'remove') tells if you want to add or remove a languageID from the errors array
   */
  updateFormErrors(errorId, type) {
    if (type === 'add') {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.concat(errorId),
      }));
    }

    if (type === 'remove') {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.filter((error) => error !== errorId),
      }));
    }
  }

  render() {
    return (
      <div className="profile-languages--editable">
        <button type="button"
          onClick={this.onAddLanguageClick.bind(this)}
          className={this.getAddLanguageButtonStyle()}>
          + Language
        </button>
        {this.getCollectionHeader()}
        <div className="profile-languages__collection">
          {this.getLanguages()}
        </div>
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.onDoneClick.bind(this)}
            className="wethod-button">Done
          </button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
