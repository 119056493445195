const { connect } = require('react-redux');
const ResourceEditable = require('../../components/Resources/ResourceEditable.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  person: state.person,
});

const mapDispatchToProps = (dispatch) => ({
  save: (personId, resource) => dispatch(actions.saveResource(personId, resource)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(ResourceEditable);
