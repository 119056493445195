const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
});

const mapDispatchToProps = (dispatch) => ({
  refreshData: (date) => dispatch(actions.getBriefing(date, false)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
