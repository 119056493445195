const { connect } = require('react-redux');
const TypeSwitcher = require('../components/TypeSwitcher.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  items: [
    {
      key: 'pm',
      name: 'As PM',
    }, {
      key: 'account',
      name: 'As Account',
    }, {
      key: 'all',
      name: 'All',
    },
  ],
  current: state.ownership,
  date: state.date,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (date, ownership) => dispatch(actions.getProjects(date, ownership)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TypeSwitcher);
