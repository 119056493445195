const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const IconLinkTooltip = require('../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');

const IntercompanyActivityRow = ({ item }) => {
  function getNonEmpty(value) {
    return value || '-';
  }

  function formatFullName(nameObj) {
    return nameObj ? `${nameObj.name} ${nameObj.surname}` : '-';
  }

  return (
    <TableRow>
      <TableCell className="data-freezing-engine__table-intercompany-column-large">{item.client_project?.name}</TableCell>
      <TableCell className="data-freezing-engine__table-intercompany-column-small">{getNonEmpty(item.client_project?.job_order)}</TableCell>
      <TableCell className="data-freezing-engine__table-intercompany-column-small">{getNonEmpty(item.client_task?.name)}</TableCell>
      <TableCell className="data-freezing-engine__table-intercompany-column-medium">{getNonEmpty(item.supplier_company?.name)}</TableCell>
      <TableCell className="data-freezing-engine__table-intercompany-column-medium">{formatFullName(item.recipient)}</TableCell>
      <TableCell className="data-freezing-engine__table-intercompany-actions">
        <IconLinkTooltip label="Pipeline" borderless href={`#pipeline/projects?id=${item.client_project?.id}`}>
          <Icon icon="pipeline" />
        </IconLinkTooltip>
      </TableCell>
    </TableRow>
  );
};

module.exports = IntercompanyActivityRow;
