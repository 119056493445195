/* eslint-disable react/prop-types */
const React = require('react');

module.exports = class HeaderItem extends React.Component {
  onClick(e) {
    if (!this.props.url) {
      e.preventDefault();
    }
    this.props.onClick(this.props.module);
  }

  getIcon() {
    const icon = this.props.module.getIcon();
    const baseName = 'app-nav__first-level-item-icon app-nav__first-level-item-icon';
    if (icon) {
      return (
        <span
          className={`${baseName} wethod-icon-${icon} wethod-icon-${icon}--black`} />
      );
    }
    return null;
  }

  getClassName() {
    let name = 'app-nav__first-level-item';
    if (this.props.isCurrent) {
      name += ' app-nav__first-level-item--current';
    }
    if (this.props.isClicked) {
      name += ' app-nav__first-level-item--clicked';
    }
    return name;
  }

  getUrl() {
    const url = this.props.module.getUrl();
    return url || '#';
  }

  render() {
    return (
      <li className={this.getClassName()}>
        <a href={this.getUrl()} onClick={this.onClick.bind(this)}>
          {this.getIcon()}
          <span className="app-nav__first-level-label">{this.props.module.getLabel()}</span>
        </a>
      </li>
    );
  }
};
