const React = require('react');
const { Provider } = require('react-redux');
const PermissionManager = require('./containers/PermissionManager');
const SectionErrorChecker = require('./components/SectionErrorChecker.react');
const Pricelists = require('./containers/Pricelists');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <PermissionManager>
      <SectionErrorChecker>
        <Pricelists />
      </SectionErrorChecker>
    </PermissionManager>
  </Provider>
);
