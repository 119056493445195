const React = require('react');
const Modal = require('./InfoModal.react');

const ConflictualPlanModal = ({ onCancelClick }) => (
  <Modal title="Planning outdated" onCancelClick={onCancelClick}>
    Seems like someone else is editing the same plan for the same person.
    <br />
    <br />
    Please
    {' '}
    <b>reload the page</b>
    {' '}
    to avoid unexpected results.
  </Modal>
);

module.exports = ConflictualPlanModal;
