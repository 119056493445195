const { connect } = require('react-redux');
const Component = require('../components/CalendarRowTrack/CalendarRowTrack.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  zoom: state.zoom,
  selectedProject: state.selected_project,
  userId: state.userId,
  buEnabled: state.bu_enabled,
});

const mapDispatchToProps = (dispatch) => ({
  showMissingProjectModal: () => dispatch(actions.showModal('missing-project')),
  editPlan: (changes) => dispatch(actions.editPlan(changes)),
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
  selectProject: (projectId) => dispatch(actions.getProjectAvailability(projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
