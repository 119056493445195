/* eslint-disable react/no-array-index-key */
const React = require('react');
const formatters = require('../../../utilities/formatters');
const tokenize = require('../../../utilities/tokenize');

module.exports = class FinancialLog extends React.Component {
  constructor(props) {
    super(props);

    this.messages = {
      invoice_created: (value) => `Invoice of ${value} created`,
      order_created: (value, supplier) => `Order of ${value} to ${supplier} created`,
      travel_created: (value) => `Imported order for travels with a value of ${value}`,
      expenses_created: (value) => `Imported order for expenses with a value of ${value}`,
    };

    this.iconClasses = {
      incoming: 'wethod-icon-collapse wethod-icon-collapse--black',
      outcoming: 'wethod-icon-expand wethod-icon-expand--black',
    };
  }

  getMessage() {
    const formattedValue = formatters.getFormattedMoneyValue(this.props.event.value);
    if (this.props.type === 'order_created') {
      const supplier = this.props.event.supplier.name;
      return this.messages[this.props.type](formattedValue, supplier);
    }
    return this.messages[this.props.type](formattedValue);
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.created_by;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  getLogoClass() {
    if (this.props.type === 'invoice_created') return this.iconClasses.incoming;
    return this.iconClasses.outcoming;
  }

  getProjectLink() {
    return <a href={`#timeline/${this.props.project.id}`}>{this.getHighlightedContent(_.unescape(this.props.project.name))}</a>;
  }

  getClientLink() {
    return (
      <span>
        {this.getHighlightedContent(_.unescape(this.props.project.client.corporate_name))}
      </span>
    );
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    return (
      <div className="timeline-event-log">
        <div className="timeline-event-past__type-logo-container">
          <div className={this.getLogoClass()} />
        </div>
        <div className="timeline-event-log__type">
          <div className="timeline-event-log__type-name">{this.getMessage()}</div>
        </div>
        <div className="timeline-event-log__creation">
          {this.getProjectLink()}
          {' '}
          -
          {this.getClientLink()}
        </div>
        <div className="timeline-event-log__creation">
          {this.getCreation()}
        </div>
      </div>
    );
  }
};
