/* eslint-disable no-shadow,no-underscore-dangle */

'use strict';

Wethod.module('ReportApp.Detail', function (Detail, Wethod, Backbone, Marionette, $) {
  // modal model
  this.ModalModel = Wethod.Utility.BaseModel.extend({});

  this.ProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/project/',
  });

  // economics model
  this.EconomicsModel = Wethod.Utility.BaseModel.extend();

  // autocomplete
  this.UserAutocompleteModel = Wethod.Utility.BaseModel.extend();
  this.UserAutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      return APIURL + '/report/' + this.options.id_project + '/autocomplete?params=' + this.options.params;
    },
    model: Detail.UserAutocompleteModel,
  });

  this.ModalModel = Wethod.Utility.BaseModel.extend();

  var API = {
    getProject: function (data) {
      var projectModel = new Detail.ProjectModel(data);
      var defer = $.Deferred();

      projectModel.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    shareReportLink: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/report/project/' + options.project_id + '/share-with/' + options.user_id,
        xhrFields: {
          withCredentials: true,
        },
        data: {
          path: options.path,
          message: options.message,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getUserAutocomplete: function (options) {
      var versions = new Detail.UserAutocompleteCollection(options);
      var defer = $.Deferred();

      versions.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('report:detail:get:project', function (data) {
    return API.getProject(data);
  });
  // SHARE LINK
  Wethod.reqres.setHandler('report:detail:share:link', function (options) {
    return API.shareReportLink(options);
  });
  // AUTOCOMPLETE
  Wethod.reqres.setHandler('report:detail:autocomplete', function (options) {
    return API.getUserAutocomplete(options);
  });
});
