const { connect } = require('react-redux');
const Component = require('../components/Info.react');
const actions = require('../actions');

const mapStateToProps = (state) => {
  const showCompanySidebar = state.sidebarToShow === 'company';

  return {
    company: state.company,
    showSidebar: showCompanySidebar,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openSidebar: () => dispatch(actions.openCompanySidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
