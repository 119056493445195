/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Step = require('./Step.react');
const StepContent = require('./StepContent.react');

module.exports = class FinalStep extends React.Component {
  render() {
    return (
      <Step>
        <StepContent>
          <div className="project-review__step-final">
            <div className="project-review__step-final-message">
              Review saved successfully!
            </div>
            <div className="project-review__step-final-actions">
              <a href="/#desk" className="wethod-button">Back to work</a>
            </div>
          </div>
        </StepContent>
      </Step>
    );
  }
};
