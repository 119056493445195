const React = require('react');
const Comparator = require('../../../../common/components/Comparator.react');

const TargetComparator = ({ value, target }) => {
  const labels = {
    much_lower: 'definitely below',
    lower: 'below',
    almost_equal: 'in line with',
    equal: 'equal',
    greater: 'above',
    much_greater: 'well above',
  };

  return <Comparator a={value} labels={labels} b={target} />;
};

module.exports = TargetComparator;
