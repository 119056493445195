/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');
const Body = require('../../../containers/modals/Interests/InterestsBody');

module.exports = class InterestsModal extends React.Component {
  render() {
    return (
      <Modal title="Edit Interests" onCancelClick={this.props.onCancelClick}>
        <Body />
      </Modal>
    );
  }
};
