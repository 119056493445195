const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(options) {
    if (options) {
      if (options.from) this.from = options.from;
      if (options.to) this.to = options.to;
    }
    let url = `${APIURL}/export/expenses`;
    if (this.from && this.to) {
      url += `?from=${this.from}&to=${this.to}`;
    }
    this.urlRoot = url;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  from: null, // default from first day of current year
  to: null, // default to current day
  sendExport() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model);
    });

    return defer.promise();
  },
});
