/**
 * Use Object.values or a Polyfill if not supported
 */
const Polyfill = {
  values(object) {
    try {
      return Object.values(object);
    } catch (e) {
      // console.log('Polyfill "values()" on', object);
      return Object.keys(object).map((index) => object[index]);
    }
  },
};

module.exports = Polyfill;
