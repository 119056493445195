const React = require('react');
const PropTypes = require('prop-types');
const OpenSidebarButton = require('./OpenSidebarButton.react');

const InfoHeader = ({ onOpenSidebarClick, actions, description }) => {
  const openSidebarButton = onOpenSidebarClick
    ? <OpenSidebarButton onClick={onOpenSidebarClick} /> : null;

  return (
    <div className="wethod-info-header">
      <div className="wethod-info-header__description">
        {description}
      </div>
      <div className="wethod-info-header__actions">{actions}</div>
      {openSidebarButton}
    </div>
  );
};

InfoHeader.defaultProps = {
  actions: null,
  description: null,
  onOpenSidebarClick: null,
};

InfoHeader.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  description: PropTypes.element,
  onOpenSidebarClick: PropTypes.func,
};

module.exports = InfoHeader;
