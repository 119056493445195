'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $) {
  this.RulesContainerView = Marionette.LayoutView.extend({
    /**
     *  _clickable : false if the button is disabled.
     *  _rulesAreaView : shorthand for the RulesAreaView attached to this RulesContainerView.
     *
     *  _allRulesArray: array of all the RuleModel
     *  _instantiatedRulesArray: array with all the RuleModel already instantiated by the user.
     *  _defaultRulesArray: array with all the RuleModel not instantiated by the user.
     *
     *  _rulesDependencies: all rules available in the database, associated with their dependencies.
     */
    _clickable: true,
    _rulesAreaView: null,
    _allRulesArray: [],
    _instantiatedRulesArray: [],
    _defaultRulesArray: [],
    _rulesDependencies: null,
    className: 'grid rule-manager__body',
    template: '#rulesContainerTemplate',
    ui: {
      newRuleButton: '[data-action="newRuleButton"]',
    },
    regions: {
      rulesArea: '[data-region="rulesArea"]',
    },
    events: {
      'click @ui.newRuleButton': 'addRule',
    },
    initialize: function (options) {
      this.splitRules(options.rules);
      this._rulesDependencies = options.dependencies;
    },
    /**
     * Populate _instantiatedRulesArray, _defaultRulesArray and _allRulesArray with the matching
     * RuleModel
     * @param rulesColl
     */
    splitRules: function (rulesColl) {
      var rulesArray = rulesColl.models;
      rulesColl.reset();

      rulesArray.forEach(function (ruleModel) {
        if (ruleModel.get('condition')) {
          this._instantiatedRulesArray.push(ruleModel);
        } else {
          this._defaultRulesArray.push(ruleModel);
        }
        this._allRulesArray.push(ruleModel);
      }.bind(this));
    },
    onRender: function () {
      var ruleCollection = new RuleManager.RuleCollection();
      var rulesArea = new RuleManager.RulesAreaView({ collection: ruleCollection });
      this.rulesArea.show(rulesArea);
      this._rulesAreaView = this.rulesArea.currentView;

      this.buildActiveRules();
      this.toggleNewRuleButton();

      this._rulesAreaView.on('rule:deleted', this.onRuleDestroyed, this);
      this._rulesAreaView.on('rule:saved', this.onRuleSaved, this);
    },
    addRule: function () {
      if (this._clickable === true) {
        var ruleModel = new RuleManager.RuleModel({
          available: this.getDefaultColl(),
          dependenciesByRule: this._rulesDependencies,
        });
        this._rulesAreaView.collection.add(ruleModel);
        this.disable(this.ui.newRuleButton);
      }
    },
    /**
     * Get a new RuleCollection with all the RuleModel
     * @returns RuleCollection
     */
    getAllRuleColl: function () {
      var rulesColl = new RuleManager.RuleCollection();
      this._allRulesArray.forEach(function (ruleModel) {
        var aRuleModel = new RuleManager.RuleModel(ruleModel.attributes);
        rulesColl.add(aRuleModel);
      });

      return rulesColl;
    },
    /**
     * Get a new RuleCollection with all the RuleModel not defined by the user
     * @returns RuleCollection
     */
    getDefaultColl: function () {
      var defaultRulesColl = new RuleManager.RuleCollection();
      this._defaultRulesArray.forEach(function (ruleModel) {
        var defaultRuleModel = new RuleManager.RuleModel(ruleModel.attributes);
        defaultRulesColl.add(defaultRuleModel);
      });

      return defaultRulesColl;
    },

    /**
     * Get a new RuleCollection with all the RuleModel already defined by the user
     * @returns RuleCollection
     */
    getInstantiatedColl: function () {
      var instantiatedRulesColl = new RuleManager.RuleCollection();
      this._instantiatedRulesArray.forEach(function (ruleModel) {
        var instantiatedRuleModel = new RuleManager.RuleModel(ruleModel.attributes);
        instantiatedRulesColl.add(instantiatedRuleModel);
      });

      return instantiatedRulesColl;
    },
    disable: function (domElement) {
      this._clickable = false;
      domElement.addClass('disabled');
    },
    enable: function (domElement) {
      this._clickable = true;
      domElement.removeClass('disabled');
    },
    /**
     * Remove the new rule from the _defaultRulesArray and add it to the _instantiatedRulesArray
     * @param addedRuleModel
     */
    onRuleSaved: function (addedRuleModel) {
      var index = this._defaultRulesArray.findIndex(function (ruleModel) {
        return addedRuleModel.get('name') === ruleModel.get('name');
      });

      if (index !== -1) {
        var ruleModel = this._defaultRulesArray[index];
        this._defaultRulesArray.splice(index, 1);
        var newRule = $.extend(true, new RuleManager.RuleModel(), ruleModel);
        this._instantiatedRulesArray.push(newRule);
      }

      this.toggleNewRuleButton();
    },
    /**
     * Remove the deleted rule from the _instantiatedRulesArray and add it to the _defaultRulesArray
     * @param destroyedRuleModel
     */
    onRuleDestroyed: function (destroyedRuleModel) {
      var index = this._instantiatedRulesArray.findIndex(function (ruleModel) {
        return destroyedRuleModel.get('name') === ruleModel.get('name');
      });

      if (index !== -1) {
        var ruleModel = this._instantiatedRulesArray[index];
        this._instantiatedRulesArray.splice(index, 1);
        var newRule = $.extend(true, new RuleManager.RuleModel(), ruleModel);
        this._defaultRulesArray.push(newRule);
      }

      this.toggleNewRuleButton();
    },
    /**
     * Show or hide the '+ new rule' button by checking _defaultRulesArray
     */
    toggleNewRuleButton: function () {
      if (this._defaultRulesArray.length) {
        this.enable(this.ui.newRuleButton);
      } else {
        this.disable(this.ui.newRuleButton);
      }
    },
    /**
     * Parse and render the json of the 'active' rules (rules already defined by the user).
     * It uses data from /rule and /dependencies api calls.
     */
    buildActiveRules: function () {
      var parser = RuleManager.RuleParser;
      var activeRulesColl = this.getInstantiatedColl();

      activeRulesColl.each(function (activeRuleModel) {
        // get the conditions tree
        var condition = parser.parseRule(activeRuleModel.get('condition'));

        activeRuleModel.set('available', this.getAllRuleColl());
        activeRuleModel.set('dependenciesByRule', this._rulesDependencies);

        this._rulesAreaView.collection.add(activeRuleModel);
        var ruleView = this._rulesAreaView.children.findByModel(activeRuleModel);
        ruleView.build(condition);
      }.bind(this));
    },
  });
});
