const React = require('react');
const PropTypes = require('prop-types');
const Actions = require('../widgets/Files/components/FileActions.react');
const Loading = require('../widgets/Files/components/SmallLoader.react');
const ShowIf = require('../ShowIf/ShowIf.react');
const IconButton = require('../TooltipFixed/IconButtonTooltip.react');

const File = ({ file, canDownload, canDelete, onDownload, onDelete, isWaiting }) => {
  function handleDownload() {
    if (canDownload) {
      onDownload(file);
    }
  }

  function handleDelete() {
    if (canDelete) {
      onDelete(file);
    }
  }

  return (
    <div className="wethod-file-section__file">
      <span className="wethod-file-section__file-name">
        {file.name}
      </span>
      <span className="wethod-file-section__file-actions">
        <IconButton label="Download" borderless onClick={handleDownload} disabled={!canDownload}>
          <span className="wethod-icon wethod-icon-download wethod-icon-download--black" />
        </IconButton>
        <ShowIf condition={canDelete}>
          <ShowIf condition={!isWaiting}
            showElse={<Loading />}>
            <Actions onDeleteClick={handleDelete} />
          </ShowIf>
        </ShowIf>
      </span>
    </div>
  );
};

File.propTypes = {
  /**
   * File shown.
   */
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  /**
   * Permission to download: when this is false, the download button is not shown.
   */
  canDownload: PropTypes.bool,
  /**
   * Permission to delete: when this is false, the delete button is not shown.
   */
  canDelete: PropTypes.bool,
  /**
   * The function to call to delete the file.
   *
   * @param file {Object}
   */
  onDelete: PropTypes.func,
  /**
   * The function to call to download the file.
   *
   * @param file {Object}
   */
  onDownload: PropTypes.func,
  /**
   * Delete is hidden and replaced by a loader.
   */
  isWaiting: PropTypes.bool,
};

File.defaultProps = {
  canDownload: false,
  canDelete: false,
  onDelete: null,
  onDownload: null,
  isWaiting: false,
};

module.exports = File;
