const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../ShowIf/ShowIf.react');
const Counter = require('./FilterAccordion/ValueCounter.react');

const FilterResume = ({ total, onReset }) => {
  const needsReset = total > 0;

  return (
    <div className="wethod-advanced-filters__filter wethod-advanced-filters__filter--highlight">
      <span className="wethod-advanced-filters__label">Filters applied</span>
      <span className="wethod-advanced-filters__summary"><Counter value={total} name="filter-resume" /></span>
      <ShowIf condition={needsReset}>
        <button className="wethod-advanced-filters__action" type="button" onClick={onReset}>Clear all</button>
      </ShowIf>
    </div>
  );
};

FilterResume.propTypes = {
  /**
   * Total of active filters
   */
  total: PropTypes.number,
  /**
   * Function called when reset button is clicked
   */
  onReset: PropTypes.func.isRequired,
};

FilterResume.defaultProps = {
  total: 0,
};

module.exports = FilterResume;
