'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.RulesAreaView = Marionette.CollectionView.extend({
    initialize: function () {
      this.childView = RuleManager.RuleView;
    },
    childEvents: {
      'rule:deleted': 'onChildRuleDeleted',
      'rule:saved': 'onChildRuleSaved',
    },
    onChildRuleDeleted: function (ruleModelOptions) {
      this.trigger('rule:deleted', ruleModelOptions.model);
    },
    onChildRuleSaved: function (ruleModelOptions) {
      this.trigger('rule:saved', ruleModelOptions.model);
    },
  });
});
