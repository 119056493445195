const React = require('react');
const PropTypes = require('prop-types');
const $ = require('jquery');
const TableColumnList = require('./models/TableColumnList');

class TableHead extends React.Component {
  constructor(props) {
    super(props);

    this.headRef = null;
  }

  componentDidMount() {
    if (this.props.updateHeight) {
      this.props.updateHeight('head', $(this.headRef).outerHeight(true));
    }
  }

  getChildren() {
    return React.Children
      .map(this.props.children, (child) => React.cloneElement(child, {
        variant: 'header',
        scrollBarWidth: this.props.scrollBarWidth,
        visibleColumns: this.props.visibleColumns,
      }));
  }

  render() {
    return (
      <thead className="wethod-table__header"
        data-testid="wethod-table-head"
        ref={(ref) => this.headRef = ref}>
        {this.getChildren()}
      </thead>
    );
  }
}

TableHead.defaultProps = {
  children: [],
  scrollBarWidth: null,
  visibleColumns: new TableColumnList(),
  updateHeight: null,
};

TableHead.propTypes = {
  /**
   * The function to call to update head's height in parent.
   *
   * @see Table2
   *
   * @param value {integer}
   */
  updateHeight: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  scrollBarWidth: PropTypes.number,
  visibleColumns: PropTypes.instanceOf(TableColumnList),
};

module.exports = TableHead;
