const React = require('react');
const Actions = require('../../../containers/Footer/DefaultFooterContent/Actions/BudgetFooterActions');
const StatusButton = require('../../../containers/Footer/DefaultFooterContent/StatusButton/StatusButtonFactory');
const PermissionManager = require('../../../containers/PermissionManager');
const Versions = require('../../../containers/Footer/Versions/Versions');
const FooterContent = require('../FooterContent.react');
const BaselineAction = require('../../../containers/Footer/DefaultFooterContent/Actions/BaselineAction/BaselineAction');

const DefaultFooterContent = ({
  getVersion,
  selectedVersion,
}) => {
  function getVersionInfo(label) {
    if (label === 'Current') {
      getVersion();
    } else {
      getVersion(label);
    }
  }

  return (
    <FooterContent primary>
      <PermissionManager>
        <BaselineAction />
      </PermissionManager>
      <Versions
        onVersionClick={getVersionInfo}
        selectedVersions={[selectedVersion]} />
      <Actions />
      <PermissionManager>
        <StatusButton />
      </PermissionManager>
    </FooterContent>
  );
};
module.exports = DefaultFooterContent;
