const React = require('react');
const Loading = require('../../chargeability/components/Loading.react');
const MultipleTable = require('../../chargeability/components/MultipleTable.react');
const TagsTable = require('./TagsTable.react');
const EmployeesTable = require('./EmployeesTagTable.react');

module.exports = class KnowledgeTable extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions')
      .outerHeight(true) + $('.knowledge__title')
      .outerHeight(true) + 24 + 24); // add padding and title height with margin
  }

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getTable() {
    if (this.props.isWaiting) {
      return <Loading />;
    }

    return (
      <MultipleTable columns={4}
        className="knowledge__multiple-table knowledge__multiple-table--tags"
        height={this.state.tableHeight}
        maxHeight={this.state.tableHeight}>
        <TagsTable tags={this.props.tags}
          name={this.props.name}
          onTagClick={this.props.onTagClick}
          filter={this.props.filterTags} />
        <EmployeesTable employees={this.props.employees}
          name={this.props.name}
          activeTags={this.props.activeTags}
          filter={this.props.filterEmployees}
          size={this.props.empSize}
          page={this.props.empPage}
          loadMore={this.props.loadEmployees} />
      </MultipleTable>
    );
  }

  updateTableHeight() {
    this.setState({ tableHeight: KnowledgeTable.getTableMaxHeight() });
  }

  render() {
    return (
      <div>
        {this.getTable()}
      </div>
    );
  }
};
