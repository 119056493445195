const { connect } = require('react-redux');
const Component = require('../components/CalendarCard.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit && state.plan.status === 1,
  isWaiting: state.waitingFor.length > 0,
  currency: state.currency,
  project: state.project,
  planMode: state.plan.status,
  invoices: state.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  showNotesModal: (plan, date) => dispatch(actions.showNotesModal(plan, date)),
  updatePlan: (plan, date) => dispatch(actions.updateInvoicePlan(plan, date)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
