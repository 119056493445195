const React = require('react');
const PropTypes = require('prop-types');
const RadialIndicator = require('../../RadialIndicator/RadialIndicator.react');
const SelectButton = require('../Select/SelectButton.react');
const Decorator = require('../../InputDecorator/InputDecorator.react');

const RadialSelectButton = ({
  inputRef, helperText, errorText, color, value, disabled, readOnly, ...rest
}) => (
  <Decorator
    inputRef={inputRef}
    helperText={helperText}
    errorText={errorText}
    readOnly={readOnly}
    disabled={disabled}>
    <SelectButton disabled={disabled} readOnly={readOnly} {...rest}>
      <RadialIndicator color={color} value={value} />
    </SelectButton>
  </Decorator>
);

RadialSelectButton.defaultProps = {
  value: 0,
  id: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  helperText: undefined,
  errorText: undefined,
  required: undefined,
  inputRef: undefined,
  color: '',
};

RadialSelectButton.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onClick: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inputRef: PropTypes.func,
};

module.exports = RadialSelectButton;
