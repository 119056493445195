const React = require('react');

const SaveManager = ({ children, onCreate, onSave, ...rest }) => {
  /**
   * Check whether the given item is new and not yet saved.
   * @param item
   * @returns {boolean}
   */
  function isNew(item) {
    return !item || !item.id;
  }

  function formatItem(changes) {
    const item = {};

    Object.keys(changes).forEach((key) => {
      const value = changes[key];
      let formattedValue;

      switch (key) {
        case 'project':
          formattedValue = value.id;
          item[key] = formattedValue;
          break;
        case 'days':
          formattedValue = value.map((day) => {
            const { conflicting, ...formattedDay } = day;
            return formattedDay;
          });
          item[key] = formattedValue;
          break;
        case 'notes':
          item[key] = value;
          break;
        default: // Ignore any other attribute given
          break;
      }
    });
    return item;
  }

  /**
   * Create or update the item
   * @param changes
   * @param updatedItem
   */
  function handleSave(changes, updatedItem) {
    if (isNew(updatedItem)) {
      const formattedChanges = formatItem(updatedItem);

      onCreate(formattedChanges);
    } else {
      const { id } = updatedItem;

      const formattedChanges = formatItem(changes);
      onSave(id, formattedChanges);
    }
  }

  return React.cloneElement(children, {
    ...rest,
    onSave: handleSave,
  });
};

module.exports = SaveManager;
