/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const Item = require('./Item.react');

module.exports = class List extends React.Component {
  onItemClicked(item) {
    this.props.onFilterChosen(item);
  }

  getItems() {
    return this.props.items.map((item) => (
      <Item key={item.id}
        {...item}
        onClick={this.onItemClicked.bind(this)} />
    ));
  }

  render() {
    return (
      <div className="wethod-filter-select__list"
        style={{ display: 'block' }}>
        <h1 className="wethod-filter-select__title">{this.props.title}</h1>
        <ul>
          {this.getItems()}
        </ul>
      </div>
    );
  }
};
