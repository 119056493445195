/* eslint-disable default-case,react/jsx-wrap-multilines,react/no-array-index-key,consistent-return */
const React = require('react');
const tokenize = require('../../../utilities/tokenize');

module.exports = class HeatmapEvent extends React.Component {
  constructor(props) {
    super(props);

    this.iconClasses = {
      Event: 'wethod-icon-event wethod-icon-event--black',
      Ship: 'wethod-icon-ship wethod-icon-ship--black',
      Meeting: 'wethod-icon-meeting wethod-icon-meeting--black',
      Travel: 'wethod-icon-travel wethod-icon-travel--black',
      Closing: 'wethod-icon-closing wethod-icon-closing--black',
      Other: 'wethod-icon-other wethod-icon-other--black',
    };
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    return `${date}`;
  }

  getCreator() {
    const updatedBy = this.props.updated_by;
    if (updatedBy) {
      return `${updatedBy.name} ${updatedBy.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  getDescription() {
    if (this.props.event.description) {
      return (<div className="timeline-event-log__heatmap-description">{this.getHighlightedContent(this.props.event.description)}</div>);
    }
  }

  getLogoClass() {
    return this.iconClasses[this.props.event.type.name];
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    return (
      <div className="timeline-event-log">
        <div className="timeline-event-log__type">
          <div className="timeline-event-past__type-logo-container">
            <div className={this.getLogoClass()} />
          </div>
          <div className="timeline-event-log__type-name">{this.getHighlightedContent(this.props.event.type.name)}</div>
        </div>
        <div className="timeline-event-log__creation">
          <a href={`#timeline/${this.props.project.id}`}>{this.getHighlightedContent(_.unescape(this.props.project.name))}</a>
          {' '}
          -
          <span>
            {this.getHighlightedContent(_.unescape(this.props.project.client.corporate_name))}
          </span>
        </div>
        <div className="timeline-event-log__creation">
          {this.getCreation()}
        </div>
        {this.getDescription()}
        <div className="timeline-event-log__creation">
          See this milestone in the
          {' '}
          <a href="#planning/projects">heatmap</a>
        </div>
      </div>
    );
  }
};
