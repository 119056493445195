/* eslint-disable class-methods-use-this,default-case,react/sort-comp */
const React = require('react');

module.exports = class Card extends React.Component {
  getClassName() {
    const hasClassName = this.props.className !== undefined && this.props.className !== null;
    if (hasClassName) {
      return `profile-card ${this.props.className}`;
    }
    return 'profile-card';
  }

  onEditButtonClick() {
    this.props.onEditClick();
  }

  getEditButton() {
    if (this.props.editable && this.props.onEditClick) {
      return (
        <button
          type="button"
          onClick={this.onEditButtonClick.bind(this)}
          className="wethod-icon-button profile-card__header-button">
          <div className="wethod-icon-medium-edit wethod-icon-medium-edit--black" />
        </button>
      );
    }
    return null;
  }

  getOtherActions() {
    if (this.props.actions) {
      return this.props.actions;
    }
    return null;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="profile-card__header">
          <h2>{this.props.title}</h2>
          {this.getEditButton()}
          {this.getOtherActions()}
        </div>
        {this.props.children}
      </div>
    );
  }
};
