const { connect } = require('react-redux');
const Component = require('../../../components/modals/ContactInfo/ContactInfoBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  id: state.person.id,
  email: state.email,
  telephone: state.person.telephone,
  linkedin: state.person.linkedin,
  gender: state.person.gender,
  birthday: state.person.birthday,
  bio: state.person.bio,
  waiting: state.waiting,
  genderList: state.genders,
});

const mapDispatchToProps = (dispatch) => ({
  editInfo: (info) => dispatch(actions.editInfo(info)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
