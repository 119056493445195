/* eslint-disable react/sort-comp */
const React = require('react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TextInput = require('../../../../../../../../common/react/TextInput.react');
const Menu = require('../../../../../../../../common/react/Menu/Menu.react');
const TextMenu = require('../ItemCell/TextItemCell/TextMenu/TextMenu.react');

module.exports = class ItemNameCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focusOnTitle: false, showMenu: false };
  }

  onBlur(value) {
    this.setState({ focusOnTitle: false, showMenu: false });
    if (value.trim() !== this.props.name) {
      this.props.editName(value);
    }
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  openMenu() {
    this.setState({ showMenu: true });
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.input.blur();
    }
  }

  getCellClassName() {
    let name = 'project-canvas-spreadsheet__cell--name';
    if (this.state.focusOnTitle) {
      name += ' project-canvas-spreadsheet__cell--focus';
    }
    return name;
  }

  getSlidingClassName() {
    let style = 'project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding';
    if (this.props.name && !this.state.focusOnTitle) style += ' project-canvas-spreadsheet__cell-button-content--sliding-enabled';

    return style;
  }

  onFocus() {
    this.setState({ focusOnTitle: true });
  }

  render() {
    return (
      <TableCell key="item-name" className={this.getCellClassName()}>
        <span className="project-canvas-spreadsheet__row-drag-handle__container">
          <span className="project-canvas-spreadsheet__row-drag-handle wethod-icon-draggable wethod-icon-draggable--black" />
        </span>
        <div className={this.getSlidingClassName()}>
          <div style={{ backgroundColor: 'white', padding: '0 8px', width: '100%' }} ref={(text) => { this.text = text; }}>
            <TextInput
              value={this.props.name}
              ref={(input) => { this.input = input; }}
              onKeyUp={this.handleKeyUp.bind(this)}
              onBlur={this.onBlur.bind(this)}
              onFocus={this.onFocus.bind(this)} />
          </div>
          <div className="project-canvas-spreadsheet__cell-button-content--delete">
            <button type="button" className="wethod-icon-button" onClick={this.openMenu.bind(this)}>
              <div className="wethod-icon wethod-icon-medium-expand wethod-icon-medium-expand--white" />
            </button>
          </div>
        </div>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.text}>
          <TextMenu value={this.props.name} canEdit onSave={this.onBlur.bind(this)} />
        </Menu>
      </TableCell>
    );
  }
};
