const React = require('react');
const Versions = require('../../../containers/Footer/Versions/Versions');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const FooterContent = require('../FooterContent.react');

module.exports = class VersionComparatorFooterContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { toCompare: [] };

    this.onClearClick = this.onClearClick.bind(this);
    this.onVersionClick = this.onVersionClick.bind(this);
    this.onExitClick = this.onExitClick.bind(this);
  }

  onClearClick() {
    this.setState({ toCompare: [] });
    this.props.clearVersionComparator();
  }

  onExitClick() {
    this.props.exitCompareVersionMode(this.props.projectId);
  }

  onVersionClick(label) {
    // Maximum 2 version can be compared
    if (this.state.toCompare.length === 2) {
      return;
    }
    this.setState((prevState) => ({ toCompare: prevState.toCompare.concat(label) }), () => {
      if (this.state.toCompare.length === 2) {
        // Always compare the newest version with the oldest one, never vice versa
        this.props.getVersionComparison(this.state.toCompare.sort((a, b) => b - a));
      }
    });
  }

  render() {
    return (
      <FooterContent>
        <h6 className="wethod-budget-footer__content-comparator__title">Compare</h6>
        <Versions onVersionClick={this.onVersionClick}
          selectedVersions={this.state.toCompare}
          showCurrent={false} />
        <div className="wethod-budget-footer__content-comparator__actions">
          <Button onClick={this.onClearClick}>Clear</Button>
          <Button onClick={this.onExitClick}>Close</Button>
        </div>
      </FooterContent>
    );
  }
};
