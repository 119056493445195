const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../modal2/Modal.react');
const Button = require('../Button/RoundedButton.react');

const CannotUploadModal = ({ onCancelClick, data, className }) => {
  function getBody() {
    switch (data) {
      case 'size':
        return (
          <span>Files should not be more than <b>128 MB</b> in size, please
            choose another one and retry.
          </span>
        );
      case 'network':
        return (
          <span>Seems like you&apos;re <b>offline</b>, please check your connection and
            retry.
          </span>
        );
      default:
        return (
          <span>This kind of file is <b>not accepted</b>, please choose another one and
            retry.
          </span>
        );
    }
  }

  const style = className ? `wethod-modal2--medium ${className}` : 'wethod-modal2--medium';

  return (
    <Modal title="Cannot upload" onCancelClick={onCancelClick} className={style}>
      {getBody()}
      <div className="wethod-modal2__actions">
        <Button onClick={onCancelClick}>Ok</Button>
      </div>
    </Modal>
  );
};

CannotUploadModal.propTypes = {
  /**
   * Function called to close the modal: both for 'Close' button and 'X' button
   * @see Modal
   */
  onCancelClick: PropTypes.func.isRequired,
  /**
   * Error used to render the modal, represented by a string.
   * Accepted errors are 'size', 'network', or generic (no string given)
   */
  data: PropTypes.oneOf(['size', 'network']),
  /**
   * Class given to the modal.
   */
  className: PropTypes.string,
};

CannotUploadModal.defaultProps = {
  data: null,
  className: null,
};

module.exports = CannotUploadModal;
