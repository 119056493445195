module.exports.HOURS_UPDATE = 'HOURS_UPDATE';

module.exports.NOTE_OPEN_MODAL = 'NOTE_OPEN_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.WEEKLY_HOURS_OPEN_MODAL = 'WEEKLY_HOURS_OPEN_MODAL';
module.exports.WEEKLY_HOURS_CLOSE_MODAL = 'WEEKLY_HOURS_CLOSE_MODAL';
module.exports.NOTE_SAVE_REQUEST = 'NOTE_SAVE_REQUEST';
module.exports.NOTE_SAVE_SUCCESS = 'NOTE_SAVE_SUCCESS';
module.exports.NOTE_SAVE_FAILURE = 'NOTE_SAVE_FAILURE';

module.exports.HOURS_SAVE_REQUEST = 'HOURS_SAVE_REQUEST';
module.exports.HOURS_SAVE_SUCCESS = 'HOURS_SAVE_SUCCESS';
module.exports.HOURS_SAVE_FAILURE = 'HOURS_SAVE_FAILURE';

module.exports.FAVORITE_ADD = 'FAVORITE_ADD';
module.exports.FAVORITE_REMOVE = 'FAVORITE_REMOVE';

module.exports.DATE_CHANGE_REQUEST = 'DATE_CHANGE_REQUEST';
module.exports.DATE_CHANGE_SUCCESS = 'DATE_CHANGE_SUCCESS';

module.exports.FILTER_PROJECTS = 'FILTER_PROJECTS';

module.exports.LOAD_MORE_PROJECTS = 'LOAD_MORE_PROJECTS';

module.exports.ALIGN_HEADER_COLUMNS = 'ALIGN_HEADER_COLUMNS';

module.exports.CHANGE_BU_FILTER = 'CHANGE_BU_FILTER';
