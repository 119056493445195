/* eslint-disable react/no-did-update-set-state */
const React = require('react');
const Sidebar = require('../../../../../../../../common/react/sidebar/Sidebar.react');
const ContactEditableDetails = require('../containers/ContactEditableDetails');
const ContactReadOnlyDetails = require('../../../sidebar/contact/ContactReadOnlyDetails.react');
const Actions = require('../../../sidebar/contact/ContactSidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * client: object corresponding the focused client to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * updateClient
 * addClient
 * addPhone
 * updatePhone
 * deletePhone
 * onClose
 * onDelete
 *
 * @type {module.ContactSidebar}
 */
module.exports = class ContactSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      errors: [], // array containing the name of the attributes that have errors; if the list is
      // empty there are no errors
      client: this.props.client,
      editMode: !this.props.client.id, // by default is true if client has no id (when we want to
      // add a new client)
    };
  }

  componentDidMount() {
    if (!this.props.companies) {
      this.props.getCompanies();
    }
  }

  /**
   * Check when an update of the client saved in the state is needed:
   * this happens when the client is firstly created, to update its id,
   * or when its phone has changed (added or deleted)
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.client && prevProps && prevProps.client) {
      const hasChangedClientId = !prevState.client.id && prevProps.client.id;
      const hasNewPhone = (!prevState.client.phones || !prevState.client.phones.id)
        && (this.props.client.phones && this.props.client.phones.id);
      const hasDeletedPhone = (prevState.client.phones && prevState.client.phones.id)
        && (!this.props.client.phones || !this.props.client.phones.id);
      const updatedClient = { ...prevState.client };
      let needsUpdate = false;

      if (hasChangedClientId) {
        updatedClient.id = prevProps.client.id;
        needsUpdate = true;
      }

      if (hasNewPhone || hasDeletedPhone) {
        updatedClient.phones = this.props.client.phones;
        needsUpdate = true;
      }
      if (needsUpdate) {
        this.setState({
          client: updatedClient,
        });
      }
    }
  }

  // Save in the local state the modified client and check if there are unsaved changes
  handleInputChanges(name, value) {
    const unsaved = this.getUnsavedChangesList(name, value);
    const client = this.getUpdatedClient(name, value);

    this.setState({
      unsavedChanges: unsaved,
      client,
    });
  }

  handleClientDelete() {
    if (this.props.onDelete) this.props.onDelete(this.state.client);
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    this.setState((prevState) => {
      let updatedErrors = prevState.errors;
      if (errors.length === 0) {
        updatedErrors = updatedErrors.filter((error) => error !== name);
      } else if (!updatedErrors.includes(name)) {
        updatedErrors.push(name);
      }

      return { errors: updatedErrors };
    });
  }

  handleSave() {
    if (this.state.client.id) {
      // We need to make a different call in case of phone changes
      if (this.hasUnsavedPhoneChanges()) {
        const phone = this.state.client.phones;
        if (phone.id) {
          if (ContactSidebar.isDirty(phone.number)) {
            this.props.updatePhone(this.state.client.id, phone);
          } else {
            this.props.deletePhone(this.state.client.id, phone);
          }
        } else {
          this.props.addPhone(this.state.client.id, phone);
        }
      }

      if (this.hasUnsavedClientChanges()) {
        this.props.updateClient(this.state.client);
      }
    } else {
      // When we add a new Client we don't need to check here if it has a phone: the request will
      // be handled afterwards
      this.props.addClient(this.state.client);
    }

    this.setState({
      editMode: false,
      unsavedChanges: [],
    });
  }

  handleEditMode() {
    if (this.props.canEdit && !this.state.editMode) {
      this.setState({ editMode: true });
    }
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <ContactEditableDetails client={this.state.client}
          onChange={this.handleInputChanges.bind(this)}
          onValidate={this.handleErrors.bind(this)} />
      );
    }
    return (
      <ContactReadOnlyDetails client={this.state.client} />
    );
  }

  getActions() {
    if (this.state.client && this.state.client.id && !this.state.editMode) {
      return (
        <Actions client={this.state.client}
          canEdit={this.canEdit()}
          onDelete={this.handleClientDelete.bind(this)} />
      );
    }

    return null;
  }

  /**
   * Return the updated list of unsaved changes:
   * Add the attribute that has changed to the list (if not already present)
   * Or remove it from the list when the changes makes it equal to the already saved value
   * @param name Name of the attribute that has changed
   * @param value New value of the attribute that has changed
   * @returns [] List of attributes that have unsaved changes
   */
  getUnsavedChangesList(name, value) {
    const hasChanged = this.hasChanged(name, value);

    if (hasChanged) {
      const isInUnsavedList = this.state.unsavedChanges.some((attr) => attr === name);
      if (!isInUnsavedList) {
        return this.state.unsavedChanges.concat(name);
      }
    } else {
      return this.state.unsavedChanges.filter((attr) => attr !== name);
    }

    return this.state.unsavedChanges;
  }

  /**
   * Return the client saved in the state merged with the given changes
   * @param name Name of the attribute that has changed
   * @param value New value of the attribute that has changed
   * @returns {*} Updated client
   */
  getUpdatedClient(name, value) {
    return {
      ...this.state.client,
      [name]: value,
    };
  }

  /**
   * Checks if it's safe to save a client: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEdit && !this.state.editMode;
  }

  /**
   * Check if the given attribute has changed value wrt the already saved value given by the props
   * @param {string} name Name of the attribute that has changed
   * @param {any} value New value of the attribute that has changed
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldClient = this.props.client || {};
    let oldVal;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'client':
        oldVal = oldClient.client ? oldClient.client.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'phones':
        oldVal = oldClient.phones ? oldClient.phones.number : null;
        newVal = newVal ? newVal.number : null;
        break;
      default:
        oldVal = oldClient[name];
        break;
    }

    return (ContactSidebar.isDirty(oldVal) || ContactSidebar.isDirty(newVal))
      && (oldVal !== newVal);
  }

  /**
   * Check whether there are unsaved changes to the attribute 'phones'
   * @returns {boolean}
   */
  hasUnsavedPhoneChanges() {
    return this.state.unsavedChanges.filter((item) => item === 'phones').length > 0;
  }

  /**
   * Check whether there are unsaved changes to the client object;
   * The changes to the 'phones' attribute are not considered
   * @returns {boolean}
   */
  hasUnsavedClientChanges() {
    return this.state.unsavedChanges.filter((item) => item !== 'phones').length > 0;
  }

  /**
   * Check whether there are any unsaved changes
   * @returns {boolean}
   */
  hasUnsavedChanges() {
    return this.state.unsavedChanges != null && this.state.unsavedChanges.length > 0;
  }

  render() {
    return (
      <Sidebar title="Contact details"
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
