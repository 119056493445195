/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

/**
 * An item of a dropdown element list
 */
class DropDownItem extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    this.props.projectSelected(this.props.project);
  }

  render() {
    const { project } = this.props;
    return (
      <li className="dropdown__item" onClick={this.clicked}>
        <p className="dropdown__title">
          {_.unescape(project.name)}
          {' '}
          -
          {' '}
          {_.unescape(project.client.name)}
        </p>
        <p className="dropdown__subtitle">
          {_.unescape(project.pm.name)}
          {' '}
          {_.unescape(project.pm.surname)}
        </p>
      </li>
    );
  }
}

module.exports = DropDownItem;
