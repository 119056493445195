const { connect } = require('react-redux');
const Actions = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  type: state.type,
});

const mapDispatchToProps = (dispatch) => ({
  addClient: () => dispatch(actions.showSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Actions);
