'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod) {
  this.startWithParent = false;

  var API = {
    showProjectCharter: function (id) {
      ProjectCharterApp.Controller.showProjectChart(id);
    },
    createAddProjectCharter: function (id) {
      ProjectCharterApp.Controller.createAddProjectCharter(id);
    },
  };

  // evento utilizzato per andare ad uno specifico project charter
  dispatcher.on('url:show:project-charter', function (id) {
    Wethod.navigate('program/' + id);
    API.showProjectCharter(id);
  });

  ProjectCharterApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'pipeline');
    API[options.foo](options.id);
  });
});
