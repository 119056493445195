const React = require('react');

const RowHorizontalScroll = ({
  children, className, onScroll, myRef,
}) => (
  <div
    ref={myRef}
    onScroll={onScroll}
    className={`wethod-budget-calculator__row ${className}`}>{children}
  </div>
);

module.exports = RowHorizontalScroll;
