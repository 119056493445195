/* eslint-disable class-methods-use-this */
/**
 * @abstract
 * @type {module.Clients}
 */
module.exports = class Clients {
  constructor() {
    if (!(this instanceof Clients)) throw new Error('Clients is an abstract class, you cannot instantiate it');
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  }

  /**
   *
   * @param collection
   * @param key
   * @returns {array}
   */
  filter() {
    throw new Error('You must instantiate filter(collection, key) before calling it');
  }

  loadMore(completeList, loadedList, sizeOfPage, page) {
    const firstItem = sizeOfPage * (page - 1); // First item to be added to the list
    const lastItem = (firstItem + sizeOfPage) > completeList.length
      ? completeList.length : (firstItem + sizeOfPage); // Last item to be added to the list

    return firstItem < completeList.length
      ? loadedList.concat(completeList.slice(firstItem, lastItem)) : loadedList;
  }
};
