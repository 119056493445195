const React = require('react');
const PropTypes = require('prop-types');
const Typography = require('../Typography/Typography.react');
const Tag = require('./Tag.react');

const FilledTag = ({
  id, disabled, className, color, backgroundColor, borderRadius,
  upperCase, inline, children, fontSize,
}) => {
  const getClassName = () => {
    const nameBase = 'wethod-tag-filled';
    let name = `${nameBase} ${className}`;

    const textBase = 'wethod-typography';
    if (upperCase) {
      name += ` ${textBase}--uppercase`;
    }
    if (color) {
      name += ` ${textBase}--color-${color}`;
    }
    if (backgroundColor) {
      name += ` ${nameBase}--background-color-${backgroundColor}`;
    }
    if (borderRadius) {
      name += ` ${nameBase}--border-radius-${borderRadius}`;
    }

    return name;
  };

  return (
    <Tag id={id} className={getClassName()} disabled={disabled} inline={inline} fontSize={fontSize}>
      {children}
    </Tag>
  );
};

FilledTag.COLORS = Typography.COLORS;

// List of different border rounding options: rounded or squared.
FilledTag.BORDER_RADIUS = {
  NONE: 'none',
  ROUNDED: 'rounded',
  SQUARED: 'squared',
};

FilledTag.propTypes = {
  /**
   * Id of the tag. It will be given as argument for click and delete events.
   * @see Tag
   */
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Content shown in the tag. It can be a simple string or an element
   * @see Tag
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  /**
   * Whether the tag should be disabled or not, changing its layout.
   * @see Tag
   */
  disabled: PropTypes.bool,
  /**
   * The class to be added to the component style.
   * @see Tag
   */
  className: PropTypes.string,
  /**
   * The color of the tag text.
   * @see Typography.COLORS
   */
  color: PropTypes.oneOf(Object.values(Typography.COLORS)),
  /**
   * The background color of the tag.
   * Default is transparent.
   * @see Typography.COLORS
   */
  backgroundColor: PropTypes.oneOf(Object.values(Typography.COLORS)),
  /**
   * The font size of the tag.
   * @see Typography.SIZES
   */
  fontSize: PropTypes.oneOf(Object.values(Typography.SIZES)),
  /**
   * The border rounding of the tag.
   * @see FilledTag.BORDER_RADIUS
   */
  borderRadius: PropTypes.oneOf(Object.values(FilledTag.BORDER_RADIUS)),
  /**
   * Whether the tag should be uppercase or not.
   * @see Typography
   */
  upperCase: PropTypes.bool,
  /**
   * Whether the tag should be displayed inline or not.
   * @see Tag
   */
  inline: PropTypes.bool,
};

FilledTag.defaultProps = {
  id: null,
  disabled: false,
  upperCase: false,
  className: '',
  color: Typography.COLORS.BLACK,
  fontSize: Typography.SIZES.PX14,
  backgroundColor: null,
  borderRadius: FilledTag.BORDER_RADIUS.ROUNDED,
  inline: false,
};

module.exports = FilledTag;
