/* eslint-disable react/prefer-stateless-function,array-callback-return,consistent-return,react/no-array-index-key */
const React = require('react');
const ProjectDay = require('./ProjectDay.react');

class ProjectDays extends React.Component {
  render() {
    const days = this.props.days.map((day, index) => {
      if (index !== 0 && index !== 6 && day) {
        return (
          <ProjectDay
            key={index}
            date={day.date}
            heat={day.heat}
            employees={day.employees}
            event={day.event}
            projectId={this.props.projectId}
            eventTypes={this.props.eventTypes}
            eventAdded={this.props.eventAdded}
            eventDeleted={this.props.eventDeleted} />
        );
      }
    });

    return (
      <div className="project__days">
        {days}
      </div>
    );
  }
}

module.exports = ProjectDays;
