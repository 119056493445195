/* eslint-disable class-methods-use-this */
const constants = require('./constants');

class AlertsEngineReducer {
  constructor(state) {
    this.state = {
      ...state,
      error: null,
      filteredAlerts: state.alerts,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  toggleAlert(state, id) {
    return {
      ...state,
      alerts: state.alerts.map((alert) => {
        if (alert.id === id) {
          return {
            ...alert,
            enabled: !alert.enabled,
          };
        }
        return alert;
      }),
      filteredAlerts: state.filteredAlerts.map((alert) => {
        if (alert.id === id) {
          return {
            ...alert,
            enabled: !alert.enabled,
          };
        }
        return alert;
      }),
    };
  }

  filterAlerts(state, key) {
    return state.alerts.filter((alert) => alert.label.toLowerCase()
      .indexOf(key.toLowerCase()) !== -1);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.TOGGLE_ALERT:
        return this.toggleAlert(state, action.id);
      case constants.EDIT_ALERT_PARAM_FAILED:
        return {
          ...state,
          error: action.message,
        };
      case constants.FILTER_ALERTS:
        return {
          ...state,
          filteredAlerts: this.filterAlerts(state, action.keyword),
        };
      default:
        return state;
    }
  }
}

module.exports = AlertsEngineReducer;
