'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  this.Controller = {
    showProjectChart: function (id) {
      var ProjectCharterProjectsRequest = Wethod.request('get:projectCharter:info', id);

      $.when(ProjectCharterProjectsRequest).done(function (projectsCollectionResponse) {
        if (_.isUndefined(projectsCollectionResponse)) {
          Wethod.trigger('url:404:show');
        } else {
          // TODO controllare i permessi
          var layout = new ProjectCharterApp.StructureLayoutView();
          layout.render();

          ProjectCharterApp.InitProjectCharter(layout, id);
        }
      });
    },
    /**
     * If no project id is given the user wants to create an empty program.
     * If a project id is given the user wants to create a program with that project inside or
     * wants to add the project to an existing program, so i must check that the project exist
     * and that it is not already in a program.
     *
     * @param projectId
     */
    createAddProjectCharter: function (projectId) {
      // TODO Check permissions (like pipeline)

      if (!_.isNull(projectId)) {
        $.when(Wethod.request('get:project', projectId)).done(function (projectResponse) {
          if (_.isUndefined(projectResponse)) {
            Wethod.trigger('url:404:show');
          } else {
            var programProject = projectResponse.get('program_project');
            if (programProject) {
              dispatcher.trigger('url:show:project-charter', programProject.program.id);
            } else {
              ProjectCharterApp.InitProjectCharterCreation(projectId);
            }
          }
        });
      } else {
        ProjectCharterApp.InitProjectCharterCreation();
      }
    },
  };
});
