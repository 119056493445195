const React = require('react');
const Day = require('../../containers/CalendarRowDay');
const ProjectSelector = require('../../containers/ProjectSelector');
const Editor = require('../../containers/PlanEditor');

module.exports = class CalendarRowTrack extends React.Component {
  getPrevDayPlan(dayIndex) {
    let prevDayPlans = [];
    if (dayIndex) {
      prevDayPlans = this.props.days[dayIndex - 1].plans;
    } else if (this.props.prevTrackDays.length
      && this.props.prevTrackDays[this.props.prevTrackDays.length - 1]) {
      prevDayPlans = this.props.prevTrackDays[this.props.prevTrackDays.length - 1].plans;
    }
    return prevDayPlans;
  }

  getDays() {
    return this.props.days.map((day, index) => {
      const prevDayPlans = this.getPrevDayPlan(index);
      return (
        <Day
          key={day.date}
          plans={day.plans}
          prevDayPlans={prevDayPlans}
          day={day.date}
          isHoliday={this.props.isHoliday(day.date, this.props.person)}
          person={this.props.person} />
      );
    });
  }

  getEditor() {
    if (this.props.showEditor) {
      return (
        <Editor
          days={this.props.days}
          editorMode={this.props.editorMode}
          person={this.props.person}
          selectedProject={this.props.selectedProject.id}
          updateHours={this.props.updateHours}
          updateChanges={this.props.updateChanges}
          updateMode={this.props.updateEditorMode} />
      );
    }
    return null;
  }

  render() {
    return (
      <ProjectSelector days={this.props.days} person={this.props.person}>
        {({ selectProject }) => (
          <div className="planning-calendar__track" onClick={selectProject}>
            {this.getDays()}
            {this.getEditor()}
          </div>
        )}
      </ProjectSelector>
    );
  }
};
