/* eslint-disable no-underscore-dangle,class-methods-use-this,no-param-reassign */
const Calendar = require('./Calendar');

module.exports = class YearsCalendar extends Calendar {
  /**
   * @param from {Moment}
   * @param to {Moment}
   */
  constructor(from, to) {
    super(from, to, 200, 50, 3, 'years', 'quarters');
  }

  _getPointsInPeriod(from, to) {
    let current = moment(from.startOf('year').format('YYYY-MM-DD')); // January 1st
    const points = [];
    while (current.isSameOrBefore(to)) {
      points.push(current);
      current = current.clone().add(1, 'year');
    }
    return points;
  }

  addFuture(amount) {
    amount = amount || 6;
    /**
     * @var {Moment}
     */
    const lastPoint = this._points[this._points.length - 1];
    const from = lastPoint.clone().add(1, 'year');
    const to = from.clone().add(amount, this._type);
    const future = this._getPointsInPeriod(from, to);
    this._points = this._points.concat(future);
    return this._points;
  }

  addPast(amount) {
    amount = amount || 6;
    /**
     * @var {Moment}
     */
    const firstPoint = this._points[0];
    const from = firstPoint.clone().subtract(amount, this._type);
    const to = firstPoint.clone().subtract(1, 'year');
    const past = this._getPointsInPeriod(from, to);
    this._points = past.concat(this._points);
    return this._points;
  }

  getDate(attribute) {
    const { value } = attribute;
    const widthInPoints = moment(value.end_date)
      .startOf('quarter')
      .diff(moment(value.start_date).startOf('quarter'), 'quarters') + 1;
    let leftInColumns = this.getPast(moment(value.start_date));
    leftInColumns = leftInColumns <= 0 ? leftInColumns - 1 : leftInColumns;

    return {
      ...attribute,
      width: widthInPoints * this._pointWidth,
      left: leftInColumns * this._columnWidth
        + ((moment(value.start_date).quarter() - 1) * this._pointWidth),
    };
  }

  getMilestone(attribute) {
    const { value } = attribute;
    const widthInPoints = 1;
    const leftInColumns = this.getPast(moment(value.date));

    return {
      ...attribute,
      width: widthInPoints * this._pointWidth,
      left: leftInColumns * this._columnWidth + ((moment(value.date)
        .quarter() - 1) * this._pointWidth),
    };
  }
};
