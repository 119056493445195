'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  /**
   * [ controlla che il modello sia valido e ne fa il persist su db in caso contrario visualizza
   * gli errori ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.CreateProjecterChart = function (viewObj) {
    viewObj.model.validate();
    if (viewObj.model.isValid()) {
      var insertProjecterChartRequest = Wethod.request('persist:projectCharter', viewObj.model);
      $.when(insertProjecterChartRequest).done(function (projecterChartModelResponse) {
        var projectModel = new ProjectCharterApp.ProjectCharterProjectModel({
          sort: 0,
          program: projecterChartModelResponse.get('id'),
          project: parseInt(viewObj.options.projectId) || null,
        });

        projectModel.validate();
        if (projectModel.isValid()) {
          var addProjectRequest = Wethod.request('projectCharter:insert:project', projectModel);
          $.when(addProjectRequest).done(function () {
            dispatcher.trigger('url:show:project-charter', projecterChartModelResponse.get('id'));
          });
        } else {
          dispatcher.trigger('url:show:project-charter', projecterChartModelResponse.get('id'));
        }
      });
    } else {
      var errors = viewObj.model.validate();
      var message;
      if (errors.name) {
        viewObj.ui.labelNameEl.addClass('error');
        message = errors.name;
      } else if (errors.manager) {
        viewObj.ui.labelManagerEl.addClass('error');
        message = errors.manager;
      }

      viewObj.ui.messageEl.addClass('error');
      viewObj.ui.messageEl.text(message);
      viewObj.ui.cancelEl.removeClass('disabled');
    }
  };

  this.InitProjectCharterCreation = function (projectId) {
    var creationLayout = new ProjectCharterApp
      .ProjectCharterCreationLayout({ projectId: projectId });
    creationLayout.render();

    if (!_.isUndefined(projectId)) {
      var selectorView = new ProjectCharterApp.ProjectCharterCreationSelectorView();
      creationLayout.selector.show(selectorView);
    }

    var programModel = new ProjectCharterApp.ProgramModel();
    var creationView = new ProjectCharterApp.ProjectCharterCreationView({
      model: programModel,
      projectId: projectId,
    });
    creationLayout.body.show(creationView);
  };
});
