const { connect } = require('react-redux');
const EventsPast = require('../../components/EventsPast/EventsPast.react');

const mapStateToProps = (state) => ({
  events: state.filteredEvents.filter((event) => moment(event.timestamp).isBefore(moment()
    .toISOString())),
  focusedEvent: state.focusedEvent,
  showEditEventForm: state.showEditEventForm,
  currentPage: state.page,
  filter: state.filter,
});
module.exports = connect(mapStateToProps)(EventsPast);
