const { connect } = require('react-redux');
const Component = require('../components/SaveManager.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  onCreate: (item) => dispatch(actions.createExpense(item)),
  onSave: (id, changes) => dispatch(actions.updateExpense(id, changes)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
