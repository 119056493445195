const { createStore } = require('redux');
const { applyMiddleware } = require('redux');
const { compose } = require('redux');
const thunk = require('redux-thunk').default;

const ProjectReviewStore = (reducer) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;// redux chrome dev
  return createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
};

module.exports = ProjectReviewStore;
