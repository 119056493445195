/* eslint-disable consistent-return */
const React = require('react');

module.exports = class HeatmapEvent extends React.Component {
  getDescription() {
    if (this.props.description) {
      return <div className="timeline-event-future__heatmap-description">{this.props.description}</div>;
    }
  }

  render() {
    return (
      <div className="timeline-event-past__content">
        <div className="timeline-event-future__jump-to-action">
          See this milestone in the
          {' '}
          <a href="#planning/projects">heatmap</a>
        </div>
        {this.getDescription()}
      </div>
    );
  }
};
