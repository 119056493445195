const React = require('react');

module.exports = class Scroller extends React.Component {
  getStyle() {
    return { height: `${this.props.height}px`, width: `${this.props.width}px` };
  }

  render() {
    return (
      <div className="virtual-grid__scroller" style={this.getStyle()}>
        {this.props.children}
      </div>
    );
  }
};
