const React = require('react');
const moment = require('moment');
const DateInput = require('../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const InputWrapper = require('../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const Row = require('../../../../../../common/react/sidebar/SidebarInputRow.react');
const RadioGroup = require('../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../../../../common/react/inputs/RadioGroup/Radio.react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class ExportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      export: 'all',
    };
  }

  handleConfirm() {
    if (!this.props.isWaiting) {
      if (this.state.export === 'all') {
        this.props.exportModule();
      } else {
        this.props.exportModule(this.state.startDate, this.state.endDate);
      }
    }
  }

  onRadioChange(e) {
    this.setState({ export: e.target.value });
  }

  onCalendarChange(e) {
    const { name, value } = e.target;
    const date = value ? moment(value).format('YYYY-MM-DD') : null;
    this.setState({ [name]: date });
  }

  getCalendar() {
    return (
      <Row>
        <InputWrapper icon={<span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />}>
          <DateInput name="startDate"
            id="export-from"
            label="From"
            value={this.state.startDate}
            onChange={this.onCalendarChange.bind(this)} />
        </InputWrapper>
        <InputWrapper>
          <DateInput name="endDate"
            id="export-to"
            label="To"
            value={this.state.endDate}
            onChange={this.onCalendarChange.bind(this)} />
        </InputWrapper>
      </Row>
    );
  }

  getBody() {
    return (
      <div>
        <RadioGroup name="export_radio" onChange={this.onRadioChange.bind(this)} value={this.state.export}>
          <Radio label="Export all expenses" value="all" />
          <Radio label="Export data segment" value="segment" />
        </RadioGroup>
        <ShowIf condition={this.state.export === 'segment'}>
          <div>
            <p className="expenses__modal-description">Choose a range of dates of <b>creation</b> of the expenses.</p>
            {this.getCalendar()}
          </div>
        </ShowIf>
      </div>
    );
  }

  render() {
    return (
      <Modal title="Export expenses" onCancelClick={this.props.onClose} className="expenses__modal">
        {this.getBody()}
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onClose} disabled={this.props.isWaiting}>Cancel</Button>
          <Button onClick={this.handleConfirm.bind(this)} disabled={this.props.isWaiting}>
            Email to me
          </Button>
        </div>
        <ShowIf condition={this.props.isWaiting}>
          <div className="wethod-modal2__feedback">Sending...</div>
        </ShowIf>
      </Modal>
    );
  }
};
