/* eslint-disable react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const TableRow = require('./TableRow.react');
const TableCell = require('./TableCell.react');

/**
 * A table row to use when no results are available. You can pass a custom cell to display.
 */
const TableEmptyRow = ({ children, columns }) => {
  const cell = children || 'No results were found';

  return (
    <TableRow>
      <TableCell colSpan={columns} emptyRow>
        {cell}
      </TableCell>
    </TableRow>
  );
};

TableEmptyRow.propTypes = {
  children: PropTypes.node,
  /**
   * The number of columns in the table.
   */
  columns: PropTypes.number.isRequired,
};

module.exports = TableEmptyRow;
