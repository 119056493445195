const React = require('react');
const PropTypes = require('prop-types');
const VirtualList = require('./VirtualList.react');

class DynamicVirtualList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Contains {height,index} for each list item.
      // At the beginning, each item has the given default height. This is updated with the actual
      // element height the first time item is rendered
      itemHeights: new Array(props.items.length)
        .fill(props.defaultItemHeight)
        .map((value, index) => ({
          value,
          index,
        })),
    };
  }

  /**
   * Update itemHeights only if given items heights have not been yet calculated.
   * @param items {node, index} node is the HTMLElement, index is the list index
   */
  onVisibleItemsChange(items) {
    const prevItemHeights = this.state.itemHeights;
    const firstIndex = items[0].index;
    const lastIndex = items[items.length - 1].index;
    let changed = false;

    for (let i = firstIndex; i <= lastIndex; i++) {
      // Height saved in itemHeights
      const currentValue = prevItemHeights[i].value;
      // Height retrieved from DOM node
      const actualValue = items[i - firstIndex].node.offsetHeight;
      if (actualValue !== currentValue) {
        prevItemHeights[i].value = actualValue;
        changed = true;
      }
    }

    if (changed) {
      this.setState({ itemHeights: prevItemHeights });
    }
  }

  get itemHeightValues() {
    return this.state.itemHeights.map((height) => height.value);
  }

  get defaultItemHeights() {
    return this.itemHeightValues.filter((height) => height === this.props.defaultItemHeight);
  }

  get index() {
    return this.isInitialized() ? this.props.index : 0;
  }

  /**
   * Returns true if at least one of the known heights is different from the default one.
   * This means that we know at least one actual item height.
   * @returns {boolean}
   */
  isInitialized() {
    return this.defaultItemHeights.length < this.state.itemHeights.length;
  }

  render() {
    return (
      <div className="wethod-dynamic-virtual-list" data-testid="dynamic-virtual-list">
        <VirtualList items={this.props.items}
          height={this.props.height}
          overscan={this.props.overscan}
          index={this.index}
          itemHeight={this.itemHeightValues}
          onVisibleItemsChange={this.onVisibleItemsChange.bind(this)}
          loading={this.props.loading}
          loadMore={this.props.loadMore} />
      </div>
    );
  }
}

DynamicVirtualList.defaultProps = {
  height: 1,
  index: 0,
  loading: false,
  loadMore: null,
  overscan: 0,
};

DynamicVirtualList.propTypes = {
  /**
   * A default height to assign to all item whose actual dimensions are still unknown.
   */
  defaultItemHeight: PropTypes.number.isRequired,
  /**
   * List items to virtualize and display.
   */
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  /**
   * The height of the visible portion of the list.
   */
  height: PropTypes.number,
  /**
   * The index of the first item to show.
   */
  index: PropTypes.number,
  /**
   * If it's waiting for new items to load.
   */
  loading: PropTypes.bool,
  /**
   * Fucntion to get more items.
   *
   * @param {number} direction The scroll direction (1 is forward, -1 is backward)
   */
  loadMore: PropTypes.func,
  /**
   * Number of rows to render above and below the visible bounds of the list.
   */
  overscan: PropTypes.number,
};

module.exports = DynamicVirtualList;
