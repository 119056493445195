const React = require('react');

class TableFooter extends React.Component {
  constructor(props) {
    super(props);

    this.footerRef = null;
  }

  componentDidMount() {
    this.props.updateHeight('footer', $(this.footerRef).outerHeight(true));
  }

  render() {
    return (
      <div className="chargeability__footer" ref={(ref) => this.footerRef = ref}>
        <span className="chargeability__legend" />
        Target
      </div>
    );
  }
}

module.exports = TableFooter;
