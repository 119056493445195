const React = require('react');
const Manager = require('../../../../../../common/react/widgets/Files/components/FileManager.react');
const S3Content = require('./FileManagerS3Content.react');

const FileManager = ({ canEdit, projectId }) => (
  <Manager className="project-canvas__widget--files">
    <S3Content label="wethod" canEdit={canEdit} projectId={projectId} />
  </Manager>
);

module.exports = FileManager;
