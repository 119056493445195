const { connect } = require('react-redux');
const Component = require('../../../components/dashboard/outstanding-projects/OutstandingProjects.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.includes('outstanding-projects'),
  items: state.outstandingProjectsData.items,
  search: state.outstandingProjectsData.search,
  filters: state.outstandingProjectsData.filters,
  offset: state.outstandingProjectsData.offset,
  limit: state.outstandingProjectsData.limit,
  hasMorePages: state.outstandingProjectsData.hasMorePages,
  sort: state.outstandingProjectsData.sort,
  order: state.outstandingProjectsData.order,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  getItems:
    (offset, limit, search, order, sort, filters) => dispatch(actions
      .getOutstandingProjects(offset, limit, search, order, sort, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
