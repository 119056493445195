const constants = require('./constants');

class TimesheetLogReducer {
  constructor(state) {
    this.state = {
      ...state,
      timesheetLogs: [],
      waitingFor: [],
      pageOffset: 0,
      pageLimit: 0,
      hasMorePages: true,
      search: '',
      sort: 'desc',
      order: 'created',
      filters: {},
      sidebarToShow: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_TIMESHEET_LOGS_REQUEST:
        return ({
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          search: action.search,
          order: action.order,
          sort: action.sort,
          timesheetLogs: action.offset ? state.timesheetLogs : [],
          waitingFor: state.waitingFor.concat('get-timesheet-logs'),
        });
      case constants.GET_TIMESHEET_LOGS_SUCCESS:
        return ({
          ...state,
          hasMorePages: action.timesheetLogs.length >= state.pageLimit,
          timesheetLogs: state.timesheetLogs.concat(action.timesheetLogs),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-timesheet-logs'),
        });
      case constants.CLOSE_SIDEBAR:
        return ({
          ...state,
          sidebarToShow: null,
        });
      case constants.SHOW_ADVANCED_SEARCH:
        return ({
          ...state,
          sidebarToShow: 'advanced-search',
        });
      case constants.APPLY_ADVANCED_SEARCH:
        return ({
          ...state,
          filters: action.filters,
          sidebarToShow: null,
        });
      default:
        return state;
    }
  }
}

module.exports = TimesheetLogReducer;
