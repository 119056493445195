const React = require('react');
const PropTypes = require('prop-types');
const Header = require('./FilesHeader.react');

require('../style.scss');

/**
 * ⚠️ This is a Frankenstein component, born from the combination of lack of time and poor design.
 * Do not expand it and do not use it out of its scope.
 *
 * Design issues to solve:
 * - Update/sync button is influenced by list type but these two components are placed at the same
 * hierarchy level
 * - Managing infinite scrolling list and normal list in the same component lead to great
 * duplication of methods. This is why infinite scroll here is fake and locked to 100 items
 * - All list must provide breadcrumb and folder navigation. Today this is not the case and this
 * led to great duplication of method
 * - Search is working on the current directory, not in all project folder. This is clearly useless
 * - Sync is only used for external drive, so why is it placed inside the common header?
 *
 * @deprecated
 */
class FileManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerAction: null,
      sources: [],
      currentSource: null,
    };

    this.onSourceChange = this.onSourceChange.bind(this);
    this.setHeaderAction = this.setHeaderAction.bind(this);
  }

  componentDidMount() {
    const sources = this.getSources();
    this.setState({
      sources,
      currentSource: sources.length ? sources[0].key : null,
    });
  }

  onSourceChange(key) {
    this.setState({ currentSource: key });
  }

  getClassName() {
    return `wethod-widget wethod-widget-files ${this.props.className}`;
  }

  getContents() {
    if (this.state.currentSource) {
      return React.cloneElement(this.getChild(this.state.currentSource), {
        setHeaderAction: this.setHeaderAction,
      });
    }
    return null;
  }

  getChild(label) {
    const children = React.Children.toArray(this.props.children)
      .filter((child) => child.props.label === label);
    if (!children.length) {
      throw new Error(`Cannot find child with label "${label}"`);
    }
    return children[0];
  }

  getSources() {
    this.checkChildrenLabel();
    return React.Children.map(this.props.children, (child) => ({
      key: child.props.label,
      name: child.props.label,
    }));
  }

  setHeaderAction(action) {
    this.setState({ headerAction: action });
  }

  checkChildrenLabel() {
    const missingLabels = React.Children.toArray(this.props.children)
      .filter((child) => child.props.label === undefined);
    if (missingLabels.length) {
      throw new Error('Missing label prop in one or more children');
    }
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <Header
          action={this.state.headerAction}
          sources={this.state.sources}
          currentSource={this.state.currentSource}
          onSourceChange={this.onSourceChange} />
        {this.getContents()}
      </div>
    );
  }
}

FileManager.defaultProps = {
  className: '',
};

FileManager.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

module.exports = FileManager;
