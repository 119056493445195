const React = require('react');

const TableStatusCell = ({ status, label }) => {
  const baseClassName = 'allocation-request-item__status-label';

  const classNameModifiers = {
    pending: 'warning',
    conflict: 'warning',
    approved: 'ok',
    rejected: 'error',
  };
  const className = `${baseClassName} ${baseClassName}--${classNameModifiers[status]}`;

  return (
    <span className={className}>{label}</span>
  );
};

module.exports = TableStatusCell;
