const React = require('react');
const isEqual = require('react-fast-compare');
const Row = require('./TimeOffRow.react');

module.exports = class SaveManager extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Add the given name to the list of changes, if not already present
   * @param changes
   * @param name
   */
  static addChanges(changes, name) {
    if (!changes.includes(name)) {
      changes.push(name);
    }
    return changes;
  }

  /**
   * Remove the given name from the list of changes
   * @param changes
   * @param name
   */
  static resetChanges(changes, name) {
    return changes.filter((attr) => attr !== name);
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      item: this.props.item,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.item, this.props.item)) {
      this.updateItem(this.props.item);
    }
  }

  /**
   * Update the item and unsaved changes
   * @param e
   */
  handleChange(e) {
    const { name, value } = e.target;

    let numericValue = parseFloat(value);
    numericValue = !Number.isNaN(numericValue) ? numericValue : 0;

    const unsavedChanges = this
      .updateChangedAttributesList(name, numericValue);

    const updatedItem = this.getUpdatedItem(name, numericValue);

    this.setState({
      item: updatedItem,
      unsavedChanges,
    });
  }

  handleBlur() {
    if (this.hasUnsavedChanges()) {
      this.setState({ unsavedChanges: [] });

      const unsavedChanges = this.state.item.time_off_targets;

      this.props.onSave(this.props.year, this.props.item.employee.id, unsavedChanges);
    }
  }

  getUpdatedItem(name, value) {
    return {
      ...this.state.item,
      time_off_targets: {
        ...this.state.item.time_off_targets,
        [name]: value,
      },
    };
  }

  updateChangedAttributesList(name, value) {
    let changesList = this.state.unsavedChanges;

    if (this.hasChanged(name, value)) {
      changesList = SaveManager.addChanges(changesList, name);
    } else {
      changesList = SaveManager.addChanges(changesList, name);
    }

    return changesList;
  }

  updateItem(updatedItem) {
    this.setState({ item: updatedItem });
  }

  hasChanged(name, value) {
    const oldVal = this.props.item.time_off_targets[name];

    return (SaveManager.isDirty(oldVal) || SaveManager.isDirty(value)) && (oldVal !== value);
  }

  hasUnsavedChanges() {
    return this.state.unsavedChanges && this.state.unsavedChanges.length > 0;
  }

  render() {
    return (
      <Row {...this.props}
        item={this.state.item}
        onChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)} />
    );
  }
};
