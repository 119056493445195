const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../ShowIf/ShowIf.react');

require('./style.scss');

const Snackbar = ({
  name, open, timeoutMs, onClose, children,
}) => {
  const shouldClose = onClose != null;

  function handleClose() {
    onClose(name);
  }

  if (open && shouldClose) {
    setTimeout(() => {
      handleClose();
    }, timeoutMs);
  }

  const className = open ? 'wethod-snackbar wethod-snackbar--visible' : 'wethod-snackbar';
  return (
    <div className={className} hidden={!open}>
      <div className="wethod-snackbar__content">
        {children}
        <div className="wethod-snackbar__actions">
          <ShowIf condition={shouldClose}>
            <button type="button"
              className="wethod-icon-button wethod-icon-button--medium wethod-icon-button--no-border wethod-icon-button--medium-white"
              onClick={handleClose}>
              <span className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--white" />
            </button>
          </ShowIf>
        </div>
      </div>
    </div>
  );
};

Snackbar.propTypes = {
  // Name of the snackbar.
  name: PropTypes.string,
  // Flag to show or hide the snackbar
  open: PropTypes.bool,
  /**
   * Milliseconds timeout to automatically close the snackbar.
   * The snackbar will not close automatically when the timeout is not given
   */
  timeoutMs: PropTypes.number,
  /**
   * Function called when the close button is clicked.
   * If omitted, the button is not shown.
   * Param: name
   */
  onClose: PropTypes.func,
  // Content of the snackbar
  children: PropTypes.node,
};

Snackbar.defaultProps = {
  name: null,
  open: false,
  timeoutMs: null,
  onClose: undefined,
  children: undefined,
};

module.exports = Snackbar;
