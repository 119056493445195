'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette) {
  // BEHAVIOR
  DashboardApp.Behaviors = {
    settingsWidget: Marionette.Behavior.extend({
      _filterWithoutValues: ['company overview', 'me'],
      ui: {
        showFilterEl: '[data-action="showFilterTooltip"]',
        showFilterLabel: '[data-region="filterSel"]',

        tooltipEl: '[data-region="filterTooltip"]',
        tooltipFilterEl: '[data-action="selectFilter"]',

        filterTypeLabel: '[data-region="filterType"]',
        wrapTooltipHintEl: '[data-region="wrapFilterTooltipHint"]',
        wrapInputHintEl: '[data-region="wrapInputHints"]',
        inputHintEl: '[data-action="inputHint"]',

        settingsRegionEl: '[data-region="widgetSettings"]',

        settingsEl: '[data-action="settingsWidget"]',

        // save
        saveEl: '[data-action="saveWidget"]',
        // save feedback
        saveFeedbackEl: '[data-region="widgetSaveFeedback"]',
        // delete
        deleteEl: '[data-action="deleteWidget"]',
      },
      events: {
        'click @ui.showFilterEl': 'showFilterTooltip',
        'click @ui.tooltipFilterEl': 'selectFilter',
        'click @ui.inputHintEl': 'showHitsList',
        'keyup @ui.inputHintEl': 'showHitsList',
        'click @ui.settingsEl': 'showSettings',
      },
      showSettings: function (e) {
        e.preventDefault();
        this.ui.settingsRegionEl.addClass('isVisible');
        this.ui.deleteEl.show();
        // reset
        this.ui.saveFeedbackEl.hide();
        this.ui.saveEl.removeClass('disabled');
        this.ui.showFilterEl.removeClass('disabled');
        this.ui.inputHintEl.removeClass('disabled');
      },
      showFilterTooltip: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.ui.showFilterEl.hasClass('disabled')) {
          this.ui.wrapTooltipHintEl.removeClass('isVisible');
          this.ui.tooltipEl.addClass('isVisible');

          // Hide select when click on 'html'
          Wethod.onHtmlClick(this.ui.tooltipEl.selector, 'isVisible', 'remove');
        }
      },
      selectFilter: function (e) {
        e.preventDefault();

        this.ui.showFilterLabel.text(e.target.dataset.value);
        this.ui.tooltipEl.removeClass('isVisible');
        this.ui.inputHintEl.val('');
        this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').removeClass('is-dirty');

        if (!this.filterNeedsValue(e.target.dataset.value)) {
          this.ui.wrapInputHintEl.removeClass('isVisible');
          this.ui.saveEl.show();
        } else {
          this.ui.wrapInputHintEl.addClass('isVisible');
          this.ui.filterTypeLabel.text(e.target.dataset.value);
          this.ui.saveEl.hide();
        }

        this.view.options.filterValue = null;
        this.view.options.filterType = e.target.dataset.value;
      },
      showHitsList: function (e) {
        e.stopPropagation();

        if (!this.ui.inputHintEl.hasClass('disabled')) {
          this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');

          if (e.type === 'click') {
            e.currentTarget.select();
          }

          if (e.target.value === '') {
            this.ui.saveEl.hide();
          }

          if (e.target.value === '' && e.type === 'click') {
            this.ui.wrapTooltipHintEl.addClass('isVisible');
            DashboardApp.dashboardAutocomplete(this.view, this.view.options.filterType, '');
          }

          if (e.type === 'keyup') {
            var value = e.target.value.trim();
            this.ui.wrapTooltipHintEl.addClass('isVisible');
            DashboardApp.dashboardAutocomplete(this.view, this.view.options.filterType, value);
          }

          // Hide select when click on 'html'
          Wethod.onHtmlClick(this.ui.wrapTooltipHintEl.selector, 'isVisible', 'remove');
        }
      },
      filterNeedsValue: function (filterName) {
        return (this._filterWithoutValues.indexOf(filterName) === -1);
      },
    }),

  };

  Marionette.Behaviors.behaviorsLookup = function () {
    return DashboardApp.Behaviors;
  };
});
