const React = require('react');
const HeaderBoxSecondaryGroup = require('../HeaderBox/HeaderBoxSecondaryGroup.react');
const ProjectInfoSecondaryValue = require('./ProjectInfoSecondaryValue.react');

/**
 * Contains secondary project info described by a label and a value.
 * @param label
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectInfoSecondaryGroup = ({
  label,
  value,
}) => (
  <HeaderBoxSecondaryGroup
    label={label}
    value={<ProjectInfoSecondaryValue>{value}</ProjectInfoSecondaryValue>}
    noBorder />
);

module.exports = ProjectInfoSecondaryGroup;
