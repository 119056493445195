const { connect } = require('react-redux');
const Component = require('../../../components/sections/Time/Planned.react');

const mapStateToProps = (state) => ({
  week: state.date.isoWeek(),
  planned: state.planned_perc,
  prev_planned: state.prev_week_planned_perc,
});

module.exports = connect(mapStateToProps)(Component);
