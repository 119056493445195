/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SpinnerImage = require('../../../img/icon/trasp.gif');

module.exports = class LoadingSmall extends React.Component {
  render() {
    return (
      <div className="clear grid">
        <div className="grid clear">
          <div className="col_2 col_offset_sx_7 col_xs_2 col_xs_offset_7 left miniLoadingWrap">
            <img src={SpinnerImage} className="loadingImg" alt="Loading..." />
            <div className="clear" />
          </div>
        </div>
      </div>
    );
  }
};
