/* eslint-disable no-use-before-define */
const constants = require('./constants');

const filterOpportunityRequest = () => ({
  type: constants.FILTER_OPPORTUNITIES_REQUEST,
});

const filterOpportunitiesSuccess = (opportunities, keyword, size) => ({
  type: constants.FILTER_OPPORTUNITIES_SUCCESS,
  items: opportunities,
  keyword,
  size,
});

module.exports.filterOpportunities = (keyword, size) => (dispatch) => {
  dispatch(filterOpportunityRequest());
  Wethod.request('get:pipeline:basket:coll', null, null, keyword, size)
    .then((response) => {
      const opportunities = response.toJSON();
      dispatch(filterOpportunitiesSuccess(opportunities, keyword, size));
    });
};

const loadMoreOpportunityRequest = () => ({
  type: constants.LOAD_MORE_OPPORTUNITIES_REQUEST,
});

const loadMoreOpportunitiesSuccess = (opportunities, size) => ({
  type: constants.LOAD_MORE_OPPORTUNITIES_SUCCESS,
  items: opportunities,
  size,
});

module.exports.loadMoreOpportunities = (page, size, keyword) => (dispatch) => {
  dispatch(loadMoreOpportunityRequest());
  const offset = (page - 1) * size;
  Wethod.request('get:pipeline:basket:coll', null, offset, keyword, size)
    .then((response) => {
      const opportunities = response.toJSON();
      dispatch(loadMoreOpportunitiesSuccess(opportunities, size));
    });
};

module.exports.addNewOpportunity = () => (dispatch) => {
  const newOpportunity = new Wethod.PipelineApp.Basket.Model();
  dispatch(showSidebar(newOpportunity.attributes));
};

const saveOpportunityRequest = () => ({
  type: constants.SAVE_OPPORTUNITY_REQUEST,
});

const saveOpportunitySuccess = (opportunity) => ({
  type: constants.SAVE_OPPORTUNITY_SUCCESS,
  item: opportunity,
});

module.exports.saveOpportunity = (opportunity) => (dispatch) => {
  dispatch(saveOpportunityRequest());
  const opportunityModel = new Wethod.PipelineApp.Basket.Model({
    ...opportunity,
  });
  Wethod.request('save:pipeline:basket', opportunityModel)
    .then((response) => {
      dispatch(saveOpportunitySuccess(response.attributes));
    });
};

const deleteOpportunityRequest = () => ({
  type: constants.DELETE_OPPORTUNITY_REQUEST,
});

const deleteOpportunitySuccess = (opportunity) => ({
  type: constants.DELETE_OPPORTUNITY_SUCCESS,
  item: opportunity,
});

module.exports.deleteOpportunity = (opportunity) => (dispatch) => {
  dispatch(deleteOpportunityRequest());
  const opportunityModel = new Wethod.PipelineApp.Basket.Model({
    ...opportunity,
  });
  Wethod.request('delete:pipeline:basket', opportunityModel)
    .then((response) => {
      dispatch(deleteOpportunitySuccess(response.attributes));
    });
};

module.exports.addProject = (opportunity) => () => {
  dispatcher.trigger('url:pipeline:basket:promote', opportunity);
};

const saveCompanyRequest = () => ({
  type: constants.SAVE_COMPANY_REQUEST,
});

const saveCompanySuccess = (company) => ({
  type: constants.SAVE_COMPANY_SUCCESS,
  company,
});

module.exports.saveCompany = (company) => (dispatch) => {
  dispatch(saveCompanyRequest());
  const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
    ...company,
    corporate_name: company.name,
  });
  Wethod.request('save:finance:client', model)
    .then((response) => {
      dispatch(saveCompanySuccess(response.attributes));
    });
};

const getCompaniesRequest = () => ({
  type: constants.GET_COMPANIES_REQUEST,
});

const getCompaniesSuccess = (clients) => ({
  type: constants.GET_COMPANIES_SUCCESS,
  items: clients,
});

module.exports.getCompanies = () => (dispatch) => {
  dispatch(getCompaniesRequest());
  Wethod.request('get:finance:companies')
    .then((response) => {
      const companies = response.toJSON();
      dispatch(getCompaniesSuccess(companies));
    });
};

const getEmployeesRequest = () => ({
  type: constants.GET_EMPLOYEES_REQUEST,
});

const getEmployeesSuccess = (employees) => ({
  type: constants.GET_EMPLOYEES_SUCCESS,
  items: employees,
});

module.exports.getEmployees = () => (dispatch) => {
  dispatch(getEmployeesRequest());
  const params = {
    whereSearch: 'account',
  };
  Wethod.request('get:pipeline:autocomplete', params)
    .then((response) => {
      const employees = response.toJSON();
      dispatch(getEmployeesSuccess(employees));
    });
};

const getPMRequest = () => ({
  type: constants.GET_PM_REQUEST,
});

const getPMSuccess = (employees) => ({
  type: constants.GET_PM_SUCCESS,
  items: employees,
});

module.exports.getFilteredPM = (keyword) => {
  const search = keyword ? keyword.trim() : keyword;
  return (dispatch) => {
    dispatch(getPMRequest());
    const params = {
      whereSearch: 'pm',
      whatSearch: search,
    };
    Wethod.request('get:pipeline:autocomplete', params)
      .then((response) => {
        const employees = response.toJSON();
        dispatch(getPMSuccess(employees));
      });
  };
};

const shareOpportunityRequest = () => ({
  type: constants.SHARE_OPPORTUNITY_REQUEST,
});

const shareOpportunitySuccess = () => ({
  type: constants.SHARE_OPPORTUNITY_SUCCESS,
});

module.exports.shareOpportunity = (notes, employeeId, opportunityId) => (dispatch) => {
  dispatch(shareOpportunityRequest());
  const note = notes ? notes.trim() : null;
  const options = {
    message: note,
    path: window.location.host + window.location.pathname,
    employeeId,
    basketId: opportunityId,
  };
  Wethod.request('send:pipeline:basket:share', options)
    .then(() => {
      dispatch(shareOpportunitySuccess());
    });
};

module.exports.showShareModal = () => ({
  type: constants.SHOW_SHARE_MODAL,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const showSidebar = (opportunity) => ({
  type: constants.SHOW_SIDEBAR,
  item: opportunity,
});

module.exports.showSidebar = showSidebar;

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});
