/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NV = require('../../../../common/components/NumericValue.react');
const NS = require('../../NumerableSentence.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');
const Link = require('../../../../common/components/Link.react');

class ProfitableProjects extends React.Component {
  getProject(project) {
    return (
      <span key={project.project.id}>
        <Link
          href={`#project/${project.project.id}/report`}>
          {_.unescape(project.project.name)}
        </Link>
        {' '}
        (
        {_.unescape(project.client.name)}
        )
      </span>
    );
  }

  getProjects() {
    const list = this.props.projects_with_most_prod;
    const projects = list.map((project) => this.getProject(project));
    return <List list={list} terminator=";">{projects}</List>;
  }

  render() {
    const introAlternatives = [
      <span>
        <span className="bold">
          The <NS quantity={this.props.projects_with_most_prod.length}>projects</NS>
          {' '}
          that produced most value last week
          {' '}
        </span>
        {' '}
        <NS
          quantity={this.props.projects_with_most_prod.length}>
          are:
        </NS>
      </span>,
      <span>
        <span className="bold">The highest production last week</span> was made by the following
        {' '}
        <NS
          quantity={this.props.projects_with_most_prod.length}>
          projects
        </NS>
        :
      </span>,
      <span>
        Here are
        {' '}
        <span className="bold">
          the <NS quantity={this.props.projects_with_most_prod.length}>projects</NS>
          {' '}
          that produced the most
        </span>
        {' '}
        in the week that just ended:
      </span>,
    ];

    const listSize = this.props.projects_with_most_prod.length;
    if (listSize) {
      return (
        <Paragraph>
          {this.props.randomize(introAlternatives)}
          {' '}
          {this.getProjects()}
          {' '}
          out of
          {' '}
          <NV
            value={this.props.projects_with_worked_hours} />
          {' '}
          <NS
            quantity={listSize}>
            projects
          </NS>
          {' '}
          our team worked on.
        </Paragraph>
      );
    }
    return null;
  }
}

module.exports = withRandomize(ProfitableProjects);
