class ArchivedStatus {
  constructor(key, name) {
    this.name = name;
    this.key = key;
  }

  get urlSafeName() {
    return this.name.toLowerCase();
  }
}

module.exports = ArchivedStatus;
