/* eslint-disable react/prop-types */
const React = require('react');
const Cleave = require('cleave.js/react');

module.exports = class TimeInput extends React.Component {
  constructor(props) {
    super(props);

    this.formatOptions = {
      time: true,
      timePattern: ['h', 'm'],
    };
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  handleInit(cleave) {
    cleave.setRawValue(this.props.value);
  }

  render() {
    return (
      <span className="wethod-time-input wethod-input">
        <Cleave options={this.formatOptions}
          onInit={this.handleInit.bind(this)}
          onChange={this.handleChange.bind(this)} />
      </span>
    );
  }
};
