module.exports.FILTER_OPPORTUNITIES_REQUEST = 'FILTER_OPPORTUNITIES_REQUEST';
module.exports.FILTER_OPPORTUNITIES_SUCCESS = 'FILTER_OPPORTUNITIES_SUCCESS';

module.exports.LOAD_MORE_OPPORTUNITIES_REQUEST = 'LOAD_MORE_OPPORTUNITIES_REQUEST';
module.exports.LOAD_MORE_OPPORTUNITIES_SUCCESS = 'LOAD_MORE_OPPORTUNITIES_SUCCESS';

module.exports.SAVE_OPPORTUNITY_REQUEST = 'SAVE_OPPORTUNITY_REQUEST';
module.exports.SAVE_OPPORTUNITY_SUCCESS = 'SAVE_OPPORTUNITY_SUCCESS';

module.exports.DELETE_OPPORTUNITY_REQUEST = 'DELETE_OPPORTUNITY_REQUEST';
module.exports.DELETE_OPPORTUNITY_SUCCESS = 'DELETE_OPPORTUNITY_SUCCESS';

module.exports.SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST';
module.exports.SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS';

module.exports.GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
module.exports.GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';

module.exports.GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST';
module.exports.GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';

module.exports.GET_PM_REQUEST = 'GET_PM_REQUEST';
module.exports.GET_PM_SUCCESS = 'GET_PM_SUCCESS';

module.exports.SHARE_OPPORTUNITY_REQUEST = 'SHARE_OPPORTUNITY_REQUEST';
module.exports.SHARE_OPPORTUNITY_SUCCESS = 'SHARE_OPPORTUNITY_SUCCESS';

module.exports.SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
