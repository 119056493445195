const React = require('react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class CreationRowInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      focus: false,
      hover: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onKeyUp(e) {
    const isEnterKey = e.keyCode === 13;
    const { value } = e.target;
    if (isEnterKey && this.canBeSaved()) {
      this.setState({ value: '', focus: false, hover: false });
      this.props.save(value);
    }
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  onBlur() {
    if (!this.canBeSaved()) {
      this.setState({ focus: false });
    }
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  getFeedback() {
    if (this.props.isSaving) {
      return 'Saving...';
    }
    if (this.canBeSaved()) {
      return 'Press ENTER to add';
    }
    return '';
  }

  isValueEmpty() {
    return this.state.value === '';
  }

  canBeSaved() {
    return !this.isValueEmpty();
  }

  render() {
    return (
      <div className="wethod-budget-creation-row__input"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <ShowIf condition={!this.props.isSaving}>
          <TextField onChange={this.onChange}
            readOnly={!this.state.focus && !this.state.hover}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            placeholder={this.props.placeholder}
            value={this.state.value}
            onKeyUp={this.onKeyUp} />
        </ShowIf>
        <div className="wethod-budget-creation-row__feedback">{this.getFeedback()}</div>
      </div>
    );
  }
};
