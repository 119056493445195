const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const RangeFilter = require('../RangeFilter.react');
const RangeInput = require('../../../../inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');

const DayRangeFilter = ({
  name,
  value,
  placeholder,
  expanded,
  label,
  className,
  suffix,
  errors,
  updateErrors,
  onChange,
  resettable,
}) => {
  function formatChange(inputName, inputValue) {
    const formattedValue = {};

    // Remove empty properties
    Object.keys(inputValue).forEach((key) => {
      if (inputValue[key]) {
        formattedValue[key] = moment(inputValue[key]).format('YYYY-MM-DD');
      }
    });

    onChange(inputName, formattedValue);
  }

  return (
    <RangeFilter name={name}
      value={value}
      placeholder={placeholder}
      expanded={expanded}
      label={label}
      className={className}
      suffix={suffix}
      input={RangeInput}
      errors={errors}
      updateErrors={updateErrors}
      onChange={formatChange}
      inputProps={{ resettable }} />
  );
};

DayRangeFilter.defaultProps = {
  expanded: false,
  label: '',
  className: '',
  suffix: null,
  value: {},
  placeholder: {
    min: 'From',
    max: 'To',
  },
  onChange: null,
  errors: {},
  updateErrors: null,
  resettable: true,
};

DayRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  value: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
    max: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
  }),
  placeholder: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {{}}
   */
  onChange: PropTypes.func,
  /**
   * Function to update form errors.
   * @param name {string}
   * @param error {{}}
   */
  updateErrors: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  suffix: PropTypes.node,
  /**
   * If set to true, the date can be cleared with a delete button.
   * The button is not shown when the input is readonly
   * @see DayPicker
   */
  resettable: PropTypes.bool,
};

module.exports = DayRangeFilter;
