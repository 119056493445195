const React = require('react');

const AddButton = ({ onClick }) => (
  <button type="button"
    className="wethod-button"
    onClick={onClick}>
    Add request
  </button>
);

module.exports = AddButton;
