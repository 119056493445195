const { connect } = require('react-redux');
const Component = require('../../components/modals/ConfirmHolidayPlanModal.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  editPlan: (changes) => dispatch(actions.editPlan(changes)),
  confirmHolidayPlanning: () => dispatch(actions.confirmHolidayPlanning()),
});

module.exports = connect(null, mapDispatchToProps)(Component);
