/* eslint-disable class-methods-use-this */
const React = require('react');

const { CSSTransition } = require('react-transition-group');
const CompanySidebar = require('../containers/CompanySidebar');
const Avatar = require('../../../../../../../../common/react/AvatarBox/Avatar.react');

module.exports = class Info extends React.Component {
  getGroup() {
    return this.props.company.group ? this.props.company.group.name : '';
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  render() {
    return (
      <div className="client-info">
        <div className="client-info__pic">
          <Avatar name={this.props.company.corporate_name} size="medium" />
        </div>
        <div className="client-info__description">
          <h1 className="client-info__name">{this.getUnescapedValue(this.props.company.corporate_name)}</h1>
          <div className="client-info__secondary">
            <span className="client-info__group">{this.getGroup()}</span>
            {/* <span> - </span>
          <span className={"client-info__other"}>Innovation, Strategy</span> */}
          </div>
        </div>
        <div className="client-info__button">
          <div className="wethod-icon wethod-icon-forward wethod-icon-forward--black" onClick={this.props.openSidebar} />
        </div>
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <CompanySidebar />
        </CSSTransition>
      </div>
    );
  }
};
