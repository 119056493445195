'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.RuleManagerView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    className: 'fluid rule-manager',
    template: '#ruleManagerTemplate',
    regions: {
      header: '[data-region="ruleManagerHeader"]',
      container: '[data-region="rulesContainer"]',
    },
  });
});
