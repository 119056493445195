const { connect } = require('react-redux');
const Component = require('../../../components/Kpi/Trends/Trends.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  clientId: state.id,
  trends: state.trends,
  isWaiting: state.isWaitingForTrends,
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getTrends: (clientId) => dispatch(actions.getTrends(clientId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
