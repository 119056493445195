const React = require('react');
const PropTypes = require('prop-types');
/**
 * A button used to let user export data.
 * @param onClick
 * @param label
 * @returns {*}
 * @constructor
 */
const ExportButton = ({ onClick, label }) => (
  <button type="button"
    className="wethod-export-button"
    onClick={onClick}>
    <div className="wethod-export-button__icon wethod-icon-download" />
    {label}
  </button>
);

ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

ExportButton.defaultProps = {
  label: 'Export data',
};

module.exports = ExportButton;
