const { connect } = require('react-redux');
const Component = require('../components/ChargeabilityTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'chargeability').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  filterEmployees: (keyword) => dispatch(actions.filterEmployees(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
