const React = require('react');
const Window = require('./Window.react');

module.exports = class HeaderWindow extends React.Component {
  getStyle() {
    return {
      marginLeft: `${this.props.left}px`,
      top: '0px',
      height: `${this.props.height}px`,
    };
  }

  render() {
    if (this.props.renderer) {
      return (
        <Window
          className={this.props.className}
          style={this.getStyle()}
          type="header">
          {this.props.renderer(this.props.items)}
        </Window>
      );
    }
    return null;
  }
};
