'use strict';

Wethod.module('DeskApp', function (DeskApp) {
  this.startWithParent = false;

  var API = {
    showDesk: function () {
      DeskApp.Controller.show();
    },
  };

  DeskApp.on('start', function (options) {
    API[options.foo]();
  });
});
