'use strict';

Wethod.module('SettingsApp.AlertsEngine', function (AlertsEngine, Wethod, Backbone, Marionette, $) {
  this.AlertModel = Backbone.Model.extend({
    url: APIURL + '/alerts-engine/alert',
  });

  this.PriorityModel = Backbone.Model.extend({
    url: APIURL + '/alerts-engine/priority',
  });

  this.ToggleAlertModel = Backbone.Model.extend({
    initialize: function (options) {
      this._id = options.index;
    },
    url: function () {
      return APIURL + '/alerts-engine/alert/' + this._id + '/toggle';
    },
  });

  this.UpdateAlertModel = Backbone.Model.extend({
    initialize: function (options) {
      this._id = options.index;
      this._key = options.key;
      this._value = options.value;
    },
    url: function () {
      return APIURL + '/alerts-engine/alert/' + this._id + '/param/' + this._key + '/' + this._value;
    },
  });

  this.UpdateAlertPriorityModel = Backbone.Model.extend({
    initialize: function (options) {
      this._id = options.alertId;
      this._priorityId = options.priorityId;
    },
    url: function () {
      return APIURL + '/alerts-engine/alert/' + this._id + '/priority/' + this._priorityId;
    },
  });

  var API = {
    _get: function (type) {
      // eslint-disable-next-line new-cap
      var model = new type();
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            // Return a pure array, we are using Backbone model just to call the server
            defer.resolve(data.attributes.data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getAlerts: function () {
      return this._get(AlertsEngine.AlertModel);
    },
    getPriorities: function () {
      return this._get(AlertsEngine.PriorityModel);
    },
    toggleAlert: function (data) {
      var model = new AlertsEngine.ToggleAlertModel(data);
      model.save();
      // TODO handle saving errors?
    },
    updateAlert: function (data) {
      var model = new AlertsEngine.UpdateAlertModel(data);
      model.save();
      // TODO handle saving errors?
    },
    updateAlertPriority: function (data) {
      var model = new AlertsEngine.UpdateAlertPriorityModel(data);
      model.save();
      // TODO handle saving errors?
    },
  };

  Wethod.reqres.setHandler('get:settings:alerts', function () {
    return API.getAlerts();
  });

  Wethod.reqres.setHandler('get:settings:alerts:priorities', function () {
    return API.getPriorities();
  });

  Wethod.reqres.setHandler('toggle:settings:alert', function (data) {
    API.toggleAlert(data);
  });

  Wethod.reqres.setHandler('update:settings:alert', function (data) {
    API.updateAlert(data);
  });

  Wethod.reqres.setHandler('update:settings:alert:priority', function (data) {
    API.updateAlertPriority(data);
  });
});
