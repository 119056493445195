const Section = require('./Section');
const SwitchCompanySection = require('./SwitchCompanySection');
const PlaygroundSection = require('./PlaygroundSection');
const GroupSection = require('./GroupSection');

/**
 *
 * @param section
 * @param userPermissions
 * @return {Section|*}
 * @constructor
 */
const SectionFactory = (section, userPermissions) => {
  if (section.url === '/access/#team/selection') {
    return new SwitchCompanySection(section, userPermissions);
  }
  if (section.label === 'Playground') {
    return new PlaygroundSection(section, userPermissions);
  }
  if (section.permission === 'group') {
    return new GroupSection(section, userPermissions);
  }
  return new Section(section, userPermissions);
};

module.exports = SectionFactory;
