const { connect } = require('react-redux');
const Budget = require('../components/BudgetContentBody.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
  showSidebar: state.showSidebar,
  currentVersion: state.targetVersion,
  budgetStatus: state.info.status,
  versions: state.versions,
  budgetLevels: state.info.levels,
});

const mapDispatchToProps = (dispatch) => ({
  updateInfoFromCollaborator: (info) => dispatch(actions.updateInfoFromCollaborator(info)),
  updateAreaFromCollaborator: (area) => dispatch(actions.updateAreaFromCollaborator(area)),
  updateTaskInfoFromCollaborator: (taskInfo) => dispatch(actions
    .updateTaskInfoFromCollaborator(taskInfo)),
  updateTaskLevelAmountFromCollaborator: (info) => dispatch(actions
    .updateTaskLevelAmountFromCollaborator(info)),
  updateTaskValuesFromCollaborator: (info) => dispatch(actions
    .updateTaskValuesFromCollaborator(info)),
  deleteTaskFromCollaborator: (info) => dispatch(actions
    .deleteTaskFromCollaborator(info)),
  createTaskFromCollaborator: (info) => dispatch(actions
    .createTaskFromCollaborator(info)),
  createAreaFromCollaborator: (info) => dispatch(actions
    .createAreaFromCollaborator(info)),
  updateTaskOrderFromCollaborator: (info) => dispatch(actions
    .updateTaskOrderFromCollaborator(info)),
  changeStatusFromCollaborator: (budgetId) => dispatch(actions
    .changeStatusFromCollaborator(budgetId)),
  insertJobTitleFromCollaborator: (info) => dispatch(actions
    .insertJobTitleFromCollaborator(info)),
  removeJobTitleFromCollaborator: (info) => dispatch(actions
    .removeJobTitleFromCollaborator(info)),
  changePricelistFromCollaborator: (info) => dispatch(actions
    .changePricelistFromCollaborator(info)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Budget);
