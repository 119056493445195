const { connect } = require('react-redux');
const Component = require('../components/LevelsTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  levels: state.levels,
  selectedLevel: state.selectedLevel,
  isWaiting: state.waitingFor.filter((key) => key === 'chargeability').length > 0,
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  selectLevel: (level) => dispatch(actions.filterEmployeesLevel(level)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
