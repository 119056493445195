'use strict';

Wethod.module('FridayApp', function (FridayApp, Wethod, Backbone, Marionette, $) {
  this.AuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/authorization/settings/apppermissions?app=' + this.options.app;
    },
  });

  var API = {
    getAuthorization: function (options) {
      options = options || {};
      var getAuthorization = new FridayApp.AuthorizationModel(options);
      var defer = $.Deferred();

      getAuthorization.fetch({
        success: function (model) {
          defer.resolve(model);
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:friday:authorization', function (options) {
    return API.getAuthorization(options);
  });
});
