const { connect } = require('react-redux');
const Component = require('../../components/Header/SuggestedPrice.react');

const mapStateToProps = (state) => ({
  price: state.info.total_price,
  totalCosts: state.info.total_cost,
  externalCosts: state.info.total_external_cost,
});

module.exports = connect(mapStateToProps)(Component);
