/* eslint-disable react/sort-comp,react/no-did-update-set-state,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('../CollapsibleCard.react');
const Notes = require('../../containers/Notes/Notes');
const NotesEditable = require('./NotesEditable.react');

module.exports = class NotesController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      collapsed: false,
      content: props.person.notes ? props.person.notes : '',
    };
  }

  hasBeenSaved(prevProps, prevState) {
    return (prevState.editable && prevProps.isSaving)
      && !this.props.isSaving;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.hasBeenSaved(prevProps, prevState)) this.setState({ editable: false });
  }

  onEditClick() {
    this.setState({ editable: true, collapsed: false });
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onSaveClick() {
    this.props.save({ ...this.props.person, notes: this.state.content });
  }

  onChange(e) {
    this.setState({ content: e.target.value });
  }

  getFeedback() {
    if (this.props.isSaving) return 'Saving ';
    return '';
  }

  getUnescapedContent() {
    return _.unescape(this.state.content);
  }

  getContent() {
    if (this.state.editable) {
      return (
        <NotesEditable
          content={this.getUnescapedContent()}
          onChange={this.onChange.bind(this)} />
      );
    }
    return <Notes />;
  }

  render() {
    return (
      <Card title="Notes"
        allowEdit
        editable={this.state.editable}
        collapsed={this.state.collapsed}
        feedback={this.getFeedback()}
        toggleCollapsed={this.toggleCollapsed.bind(this)}
        onEditClick={this.onEditClick.bind(this)}
        onSaveClick={this.onSaveClick.bind(this)}>
        {this.getContent()}
      </Card>
    );
  }
};
