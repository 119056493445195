/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const ImportantInfoController = require('../containers/ImportantInfo/ImportantInfoController');
const SecondaryInfoController = require('../containers/SecondaryInfo/SecondaryInfoController');
const CompanyInfo = require('../containers/CompanyInfo');
const Tags = require('../containers/Tags');
const Notes = require('../containers/Notes/NotesController');

module.exports = class About extends React.Component {
  render() {
    return (
      <div className="client-person__about">
        <div className="client__title">ABOUT</div>
        <div className="client-person-important-info">
          <ImportantInfoController />
        </div>
        <div className="client-person-secondary-info">
          <SecondaryInfoController />
        </div>
        <div className="client-person-notes">
          <Notes />
        </div>
        <div className="client-person-tags">
          <Tags />
        </div>
        <div className="client-person-company-info">
          <CompanyInfo />
        </div>
      </div>
    );
  }
};
