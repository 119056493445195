/* eslint-disable no-nested-ternary,react/require-default-props,react/forbid-prop-types */
const React = require('react');
const moment = require('moment');
const PropTypes = require('prop-types');
const ReactDayPicker = require('react-day-picker').DayPicker;
const DateService = require('../../services/DateService');
const FeatureService = require('../../services/FeatureService');

class DayCalendar extends React.Component {
  static isValidDate(date) {
    return date && date !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      month: DateService.getStartOfMonth(props.startDate),
      holidays: [],
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  componentDidMount() {
    this.loadHolidays();
  }

  componentDidUpdate(prevProps, prevState) {
    const changedMonth = this.state.month !== prevState.month;
    if (changedMonth) {
      this.loadHolidays();
    }
  }

  handleDayClick(day, e) {
    if (this.isDisabled(day)) {
      return;
    }
    this.props.onDayClick(day, e);
  }

  handleMonthChange(date) {
    this.setState({ month: DateService.getStartOfMonth(date) });
  }

  getDisabledDays() {
    const disabled = [];
    if (this.props.disableWeekend) {
      disabled.push({ daysOfWeek: [0, 6] });
    }
    if (this.props.disableBefore) {
      const limitDate = new Date(this.props.disableBefore);
      disabled.push({ before: limitDate });
    }
    if (this.props.disableBeforeEqual) {
      const limitDate = new Date(this.props.disableBeforeEqual);
      disabled.push(limitDate);
      disabled.push({ before: limitDate });
    }

    return disabled;
  }

  getInitialMonth() {
    return DayCalendar.isValidDate(this.props.initialMonth)
      ? moment(this.props.initialMonth).toDate()
      : DayCalendar.isValidDate(this.props.startDate)
        ? moment(this.props.startDate).toDate() : moment().toDate();
  }

  getSelectedDays() {
    const from = DayCalendar.isValidDate(this.props.startDate)
      ? moment(this.props.startDate).toDate() : null;
    if (!DayCalendar.isValidDate(this.props.endDate)) {
      return from;
    }
    const to = moment(this.props.endDate).toDate();
    return [from, {
      from,
      to,
    }];
  }

  getStyle() {
    let style = 'wethod-day-picker';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  getModifiers() {
    return {
      ...this.props.modifiers,
      holiday: this.state.holidays.map((day) => new Date(day.date)),
    };
  }

  /**
   * Return true ifa given day falls in a disabled period.
   * @param {string} day
   * @return {boolean}
   */
  inDisabledPeriod(day) {
    if (this.props.disableBefore) {
      return moment(day).isBefore(moment(this.props.disableBefore), 'day');
    }
    if (this.props.disableBeforeEqual) {
      return moment(day).isSameOrBefore(moment(this.props.disableBeforeEqual), 'day');
    }
    return false;
  }

  /**
   * Return true if given day is disabled.
   * @param {string} day
   * @return {boolean}
   */
  isDisabled(day) {
    const inDisabledPeriod = this.inDisabledPeriod(day);
    return this.props.modifiers.disabled || inDisabledPeriod;
  }

  loadHolidays() {
    if (Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)
      && this.props.getHolidays) {
      this.props.getHolidays(this.state.month)
        .done((employeeHolidays) => this.setState({ holidays: employeeHolidays }));
    }
  }

  render() {
    return (
      <ReactDayPicker
        {...this.props}
        className={this.getStyle()}
        firstDayOfWeek={this.props.firstDayOfWeek}
        initialMonth={this.getInitialMonth()}
        selectedDays={this.getSelectedDays()}
        disabledDays={this.getDisabledDays()}
        modifiers={this.getModifiers()}
        onDayClick={this.handleDayClick}
        onMonthChange={this.handleMonthChange} />
    );
  }
}

DayCalendar.defaultProps = {
  modifiers: {
    disabled: false,
  },
  getHolidays: null,
  firstDayOfWeek: 1,
};

DayCalendar.propTypes = {
  /**
   * The first day of week to be shown in the calendar (sun=0, mon=1, .., sat=6).
   */
  firstDayOfWeek: PropTypes.number,

  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,

  /**
   * The initial month shown in the calendar. If not specified, it's set to the month of the
   * startDate, or the current one.
   */
  initialMonth: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),

  /**
   * The selected date shown in the calendar. If not specified, no date will be selected.
   */
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * All days before this are disabled.
   */
  disableBefore: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * This day and all the ones before this are disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * The ending date of a selected range. If not specified, it's assumed to be a single-day range.
   */
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),

  /**
   * If set to true, Saturday and Sunday will be disabled in the calendar.
   */
  disableWeekend: PropTypes.bool,

  /**
   * The modifiers you want to use in style classes.
   * When a modifier matches a specific day, its day cells receives the modifier’s name as CSS
   * class.
   * @see react-day-picker
   */
  modifiers: PropTypes.object,

  onDayClick: PropTypes.func,
  /**
   * Function to call to retrieve holidays to show in calendar.
   * If this function is null, no holidays are shown.
   * @param {Date} month
   * @return {Promise} Resolve to a list of EmployeeCompanyHoliday
   */
  getHolidays: PropTypes.func,
};

module.exports = DayCalendar;
