'use strict';

Wethod.module('ProjectCanvasApp', function (ProjectCanvasApp) {
  this.startWithParent = false;

  var API = {
    showProjectCanvas: function (projectId, boardId) {
      ProjectCanvasApp.Controller.show(projectId, boardId);
    },
  };

  ProjectCanvasApp.on('start', function (options) {
    API[options.foo](options.idProject, options.idBoard);
  });
});
