/**
 *
 * @param items
 * @param {string} col Name of the column to sort
 * @param {string} order Order to use to sort ('asc', 'desc')
 * @returns {Array} items sorted by project_status_mode
 */
module.exports = (items, col, order = 'asc') => {
  const orderValue = order === 'asc' ? 1 : -1;

  const modeOrder = { 'days-left': 0, progress: 1, 'time-based': 2 };

  const progressOrder = { 'no-budget': 0, null: 1, number: 2 };

  const orderLower = { a: -1 * orderValue, b: orderValue };

  const getModeOrder = (item) => modeOrder[item.project.project_status_mode];

  /**
   * Calculate the last available progress percentage from previous days left and budget days.
   * When one of these is not available, null is returned.
   *
   * @param item
   * @returns {null|number}
   */
  const getLastProgressPercentage = (item) => {
    const daysLeft = item.prev_days_left;
    const budgetDays = item.budget.days;
    if (daysLeft === null || budgetDays === 0) {
      return null;
    }
    return ((budgetDays - daysLeft) / budgetDays) * 100;
  };

  /**
   * Return the last available progress of a project, calculated according to the project status mode:
   * - previous days left -> mode is 'days' or 'auto'
   * - previous progress percentage -> mode is 'progress'
   *
   * @param item
   * @returns {number|null}
   */
  const getLastValue = (item) => {
    if (item.project.project_status_mode === 'progress') {
      return getLastProgressPercentage(item);
    }

    return item.prev_days_left;
  };

  /**
   * Check whether the given item has an approved budget with more than 0 days.
   * Item with no budget are not considered approved.
   *
   * @param item
   * @returns {boolean}
   */
  const hasBudget = (item) => item.budget && item.budget.is_approved && item.budget.days > 0;

  /**
   * Return the order of the item based on the availability of the progress with the following order:
   * - projects with no budget
   * - null progress or no budget days
   * - projects with a valued progress and budget
   *
   * @param item
   * @param lastValue
   * @returns {number}
   */
  const getProgressOrder = (item, lastValue) => {
    let type = 'number';

    if (!hasBudget(item)) {
      type = 'no-budget';
    } else if (lastValue == null) {
      type = 'null';
    }

    return progressOrder[type];
  };

  const sortByPsMode = (a, b) => {
    const orderA = getModeOrder(a);
    const orderB = getModeOrder(b);
    if (orderA === orderB) return 0;
    if (orderA == null) return 1;
    if (orderB == null) return -1;

    return orderA - orderB;
  };

  const sortByName = (a, b) => {
    const nameA = a.project.name.toLowerCase();
    const nameB = b.project.name.toLowerCase();
    if (nameA < nameB) {
      return orderLower.a;
    }
    if (nameA > nameB) {
      return orderLower.b;
    }
    return 0;
  };

  /**
   * Sort by last available value.
   * Projects with no previous days left or with no budget days are placed:
   * - first when the order is asc
   * - last when the order is desc
   *
   * @param a
   * @param b
   * @returns {number}
   */
  const sortByLastProgress = (a, b) => {
    const lastA = getLastValue(a);
    const lastB = getLastValue(b);

    // Compare projects based on availability (no budget, null values, progress available)
    let orderA = getProgressOrder(a, lastA);
    let orderB = getProgressOrder(b, lastB);

    // Compare projects based on progress value
    if (orderA === orderB) {
      orderA = lastA;
      orderB = lastB;
    }

    if (orderA < orderB) {
      return orderLower.a;
    }

    if (orderA > orderB) {
      return orderLower.b;
    }

    return 0;
  };

  const sortByColumn = (a, b) => {
    switch (col) {
      case 'name':
        return sortByName(a, b);
      case 'last':
        return sortByLastProgress(a, b);
      default:
        return 0;
    }
  };

  const sortProjectStatus = (a, b) => {
    const modeSort = sortByPsMode(a, b);

    if (modeSort === 0) {
      return sortByColumn(a, b);
    }

    return modeSort;
  };

  return items.sort((sortProjectStatus));
};
