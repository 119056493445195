const { connect } = require('react-redux');
const Component = require('../components/TimeOffList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  isWaiting: state.waitingFor.filter((key) => key === 'get-time-off').length > 0,
  itemsInSaving: state.waitingFor
    .filter((key) => key.indexOf('save-item') !== -1)
    .map((message) => {
      const stringId = message.match(/save-item-(\d+)/)[1];
      return parseInt(stringId);
    }),
  list: state.items,
  offset: state.pageOffset,
  limit: state.pageLimit,
  keyword: state.keyword,
  year: state.year,
  hasMore: state.hasMorePages,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (offset, limit, keyword, year) => dispatch(actions
    .getItems(offset, limit, keyword, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
