const React = require('react');
const ContactInfoModal = require('../containers/modals/ContactInfo/ContactInfo');
const BioModal = require('./modals/Bio/BioModal.react');
const SkillsModal = require('./modals/Skills/SkillsModal.react');
const InterestsModal = require('./modals/Interests/InterestsModal.react');
const LanguagesModal = require('./modals/Languages/LanguagesModal.react');
const PortfolioModal = require('./modals/Portfolio/PortfolioModal.react');
const SponsorsModal = require('./modals/Sponsors/SponsorsModal.react');
const ObjectiveModal = require('./modals/Objective/ObjectiveModal.react');
const YearResolutionModal = require('./modals/YearResolution/YearResolutionModal.react');
const ProfilePicModal = require('./modals/ProfilePicPicker/ProfilePicPickerModal.react');
const WorkingConnectionsProjectsModal = require('../containers/modals/WorkingConnections/WorkingConnectionsModal');

module.exports = ({ toShow, hideModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'contact-info':
        return <ContactInfoModal onCancelClick={hideModal} />;
      case 'bio':
        return <BioModal onCancelClick={hideModal} />;
      case 'skills':
        return <SkillsModal onCancelClick={hideModal} />;
      case 'interests':
        return <InterestsModal onCancelClick={hideModal} />;
      case 'languages':
        return <LanguagesModal onCancelClick={hideModal} />;
      case 'portfolio':
        return <PortfolioModal onCancelClick={hideModal} />;
      case 'sponsors':
        return <SponsorsModal onCancelClick={hideModal} />;
      case 'objective':
        return <ObjectiveModal onCancelClick={hideModal} />;
      case 'year-resolution':
        return <YearResolutionModal onCancelClick={hideModal} />;
      case 'profile-pic':
        return <ProfilePicModal onCancelClick={hideModal} />;
      case 'working-connection':
        return <WorkingConnectionsProjectsModal onCancelClick={hideModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
