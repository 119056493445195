const { connect } = require('react-redux');
const Component = require('../components/Briefing.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
});

const mapDispatchToProps = (dispatch) => ({
  getBriefing: (date) => dispatch(actions.getBriefing(date)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
