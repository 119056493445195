const React = require('react');
const View = require('./StatusItemCellView.react');
const Edit = require('./StatusItemCellEdit.react');

module.exports = ({ canEdit, ...rest }) => {
  if (canEdit) {
    return <Edit {...rest} />;
  }
  return <View {...rest} />;
};
