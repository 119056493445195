const React = require('react');
const File = require('./File.react');

module.exports = class FileSectionReadOnly extends React.Component {
  getFileList() {
    if (!this.props.files || !this.props.files.length) {
      return (
        <div className="travel-files__empty">
          Travel bookings and reservations<br />
          will appear here
        </div>
      );
    }
    return this.props.files.map((file) => (
      <File key={file.id}
        file={file}
        onDownload={this.props.onDownload.bind(this)}
        downloadable={this.props.canDownload} />
    ));
  }

  getStyle() {
    let style = 'travel__carrier--body-end';
    if (this.props.canEdit) style += ' travel-office--highlight';
    return style;
  }

  render() {
    return (
      <div className={this.getStyle()}>
        <h3 className="travel__sidebar-subtitle">
          Booking and reservations
        </h3>
        {this.getFileList()}
      </div>
    );
  }
};
