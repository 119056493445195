const { connect } = require('react-redux');
const Component = require('../components/ProjectStep.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  project: state.project,
});

const mapDispatchToProps = (dispatch) => ({
  goToNextStep: () => dispatch(actions.goToStep(1)),
  exit: () => dispatch(actions.showUnsavedChangesModal()),
  showMissingVoteModal: () => dispatch(actions.showMissingVoteModal('project')),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
