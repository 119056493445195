'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var WeeklyBriefingStore = require('../../../apps/core/modules/report/briefing/weekly/store');
var WeeklyBriefingReducer = require('../../../apps/core/modules/report/briefing/weekly/reducer');
var WeeklyBriefing = require('../../../apps/core/modules/report/briefing/weekly/index');
var MonthlyBriefingStore = require('../../../apps/core/modules/report/briefing/monthly/store');
var MonthlyBriefingReducer = require('../../../apps/core/modules/report/briefing/monthly/reducer');
var MonthlyBriefing = require('../../../apps/core/modules/report/briefing/monthly/index');

Wethod.module('BriefingApp', function (BriefingApp, Wethod, Backbone, Marionette, $) {
  BriefingApp.Controller = {
    showWeekly: function () {
      var calendarPreferenceRequest = Wethod.request('get:company:planning:calendar');
      $.when(calendarPreferenceRequest).done(function (calendarPreferenceResponse) {
        var internationalCalendar = calendarPreferenceResponse.get('enabled');
        var layout = new BriefingApp.StructureLayoutView();
        layout.render();

        var reducerWrapper = new WeeklyBriefingReducer({
          internationalCalendar: internationalCalendar,
        });

        var store = WeeklyBriefingStore(reducerWrapper.reduxReducer);
        var element = React.createElement(WeeklyBriefing, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
    showMonthly: function () {
      var calendarPreferenceRequest = Wethod.request('get:company:planning:calendar');
      $.when(calendarPreferenceRequest).done(function (calendarPreferenceResponse) {
        var internationalCalendar = calendarPreferenceResponse.get('enabled');
        var layout = new BriefingApp.StructureLayoutView();
        layout.render();

        var reducerWrapper = new MonthlyBriefingReducer({
          internationalCalendar: internationalCalendar,
        });

        var store = MonthlyBriefingStore(reducerWrapper.reduxReducer);
        var element = React.createElement(MonthlyBriefing, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
  };
});
