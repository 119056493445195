/* eslint-disable eqeqeq */
const canPerform = require('../services/canPerform');
const DateService = require('../../../../../../services/DateService');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');
const FeatureService = require('../../../../../../services/FeatureService');

const Validator = ({
  children,
  person,
  selectedProject,
  showModal,
  buEnabled,
  plans,
  highlightWeekend,
  planningWeekendConfirmed,
  planningHolidayConfirmed,
  isHoliday,
}) => {
  const businessUnitService = new BusinessUnitService();

  const errorSentences = {
    late: 'Too late! It might sound a bit machinelike but... You are not allowed to plan anyone today or in the past.',
    archived: 'This project is Archived.',
    status: 'The budget for this project has not been approved yet.',
    days: 'The budget for this project has no days.',
    permission: 'You don\'t have permission to edit this planning.',
    'inter-bu_employee': 'You cannot plan people coming from business units different from yours',
    'inter-bu_project': 'You cannot plan people coming from your business unit on projects belonging to other business units',
  };

  /**
   * Returns true if all the given dates can be modified (planned/unplanned)
   * It uses Wethod.PlanningApp
   * @param {[]} dates
   */
  const isValidDate = (dates) => {
    const hasInvalidDate = dates.filter((date) => moment(date) <= moment()).length > 0;
    return !hasInvalidDate || Wethod.PlanningApp.getPermission('modify_past');
  };

  /**
   * Returns true if plan for the selected projects can be updated based on if it is archived or not
   * It uses state.availability
   */
  const isValidProjectMode = () => !selectedProject.archived || selectedProject.over_limit;

  /**
   * Returns true if plan for the selected projects can be updated based on budget status
   * It uses state.availability
   */
  const isValidBudgetStatus = () => selectedProject.budget.status == 2
    || selectedProject.over_limit;

  /**
   * Check if the logged user has the permission to modify the planning
   * @param {string} permission String permission as 'modify' or 'view', it will automatically append '_other' to check permission on other
   * @param {object} project project data object from server
   */
  const isUserAuthorized = (permission) => canPerform(permission, selectedProject);

  /**
   * Check if the logged user has the permission to modify the planning inter-BU
   * @param {string} permission String permission as 'modify' or 'view'
   */
  const isValidBuPermission = (permission) => Wethod.PlanningApp.getPermission(permission);

  const validateBu = () => {
    if (buEnabled) {
      const projectBu = selectedProject.business_unit;
      const employeeBu = person.business_unit;
      const userBu = businessUnitService.getForUser();

      if (BusinessUnitService.isEqual(userBu, employeeBu)) {
        if (!BusinessUnitService.isEqual(userBu, projectBu) && !isValidBuPermission('same-bu_people_on_inter-bu_projects')) { // cannot plan his employees to other bu
          showModal('error', { sentence: errorSentences['inter-bu_project'] });
          return false;
        }
      } else if (!isValidBuPermission('inter-bu_people')) { // cannot plan other bu's employees
        showModal('error', { sentence: errorSentences['inter-bu_employee'] });
        return false;
      }
    }
    return true;
  };

  /**
   * Return how many hours has been added by changes.
   * @param changes
   * @returns {number}
   */
  const getDiff = (changes) => {
    let diff = 0;
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];
      const prevPlan = plans
        .filter((plan) => plan.day === change.day && plan.project_id === change.project_id)[0];
      const prevAmount = prevPlan ? prevPlan.amount : 0;
      diff += change.amount - prevAmount;
    }
    return diff;
  };

  const isValidAmount = (changes, plannableHours) => getDiff(changes) <= plannableHours;

  const needsApproval = () => selectedProject.type.plan_upon_approval;

  /**
   * Planning for the weekend can be changed if one of the following is true:
   * - "Highlight weekends" company settings is disabled
   * - "Highlight weekends" is enabled and user already accepted the ConfirmWeekendPlanModal
   *
   * @returns {boolean}
   */
  const canPlanWeekends = () => {
    if (highlightWeekend) {
      return planningWeekendConfirmed;
    }

    return true;
  };

  /**
   * Return true if user can plan holidays.
   * @return {boolean}
   */
  const canPlanHolidays = () => planningHolidayConfirmed;

  /**
   * Return true if changes contains at least one weekend day.
   * @param changes
   * @returns {boolean}
   */
  const containsWeekends = (changes) => changes.filter((change) => DateService
    .isWeekend(change.day)).length > 0;

  const containsHolidays = (changes) => changes.some((change) => isHoliday(change.day, person));

  const validateChanges = (changes, plannableHours) => {
    if (!changes.length) {
      return false;
    }
    if (!isUserAuthorized('modify')) {
      showModal('error', { sentence: errorSentences.permission });
      return false;
    }
    if (!isValidDate(changes.map((change) => change.day))) {
      showModal('error', { sentence: errorSentences.late });
      return false;
    }
    if (!isValidProjectMode()) {
      showModal('error', { sentence: errorSentences.archived });
      return false;
    }
    if (!isValidBudgetStatus()) {
      showModal('error', { sentence: errorSentences.status });
      return false;
    }
    if (!validateBu()) {
      return false;
    }
    if (!isValidAmount(changes, plannableHours)) {
      showModal('no-hours', { person });
      return false;
    }
    if (needsApproval()) {
      showModal('approval-needed');
      return false;
    }
    if (containsWeekends(changes) && !canPlanWeekends()) {
      showModal('confirm-weekend-plan', { changes });
      return false;
    }
    if (Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)) {
      if (containsHolidays(changes) && !canPlanHolidays()) {
        showModal('confirm-holiday-plan', {
          changes,
          person,
        });
        return false;
      }
    }

    return true;
  };

  return children({ validateChanges });
};

module.exports = Validator;
