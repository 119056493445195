const { connect } = require('react-redux');
const Component = require('../components/Modal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  modalKey: state.modal?.key,
  modalData: state.modal?.data,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
