const React = require('react');
const PropTypes = require('prop-types');

const DraggableItem = ({ id, children }) => (
  <div className="wethod-draggable-list__item" data-id={id}>
    {children}
  </div>
);

DraggableItem.propTypes = {
  /** Node to use as content * */
  children: PropTypes.node.isRequired,
  /** Item identifier * */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

module.exports = DraggableItem;
