/* eslint-disable react/sort-comp */
const React = require('react');

module.exports = class Priority extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  getClassName() {
    let name = 'alerts-engine__priority';
    if (this.props.selected) name += ' alerts-engine__priority--selected';
    return name;
  }

  getStyle() {
    return { backgroundColor: this.props.color };
  }

  handleClick(e) {
    e.stopPropagation();
    this.props.updatePriority({
      color: this.props.color,
      id: this.props.id,
      level: this.props.level,
      name: this.props.name,
    });
  }

  render() {
    return (
      <div
        className={this.getClassName()}
        style={this.getStyle()}
        onClick={this.handleClick} />
    );
  }
};
