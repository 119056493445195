const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../../OutlinedSelect/OutlinedSelect.react');

const OutlinedColorfulSelect = ({ children, color, ...rest }) => {
  const dotStyle = color ? { backgroundColor: color } : {};

  const coloredDot = <div className="material-input__dot" style={dotStyle} />;

  return (
    <Select suffix={coloredDot} {...rest}>
      {children}
    </Select>
  );
};

OutlinedColorfulSelect.defaultProps = {
  helperText: undefined,
  errorText: undefined,
  color: null,
};

OutlinedColorfulSelect.propTypes = {
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  color: PropTypes.string,
};

module.exports = OutlinedColorfulSelect;
