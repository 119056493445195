const React = require('react');
const $ = require('jquery');
const isEqual = require('react-fast-compare');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const TimesheetLogRow = require('./TimesheetLogRow.react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableSearch = require('../containers/TimesheetLogSearch');

module.exports = class TimesheetLogTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (
      Wethod.getHeaderHeight()
      + $('.section-header').outerHeight(true)
      + 24
      + 16
    );
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    const filtersChanged = !isEqual(prevProps.filters, this.props.filters);
    if (filtersChanged) {
      this.filter(this.props.filters);
    }
  }

  getList() {
    return this.props.timesheetLogs.map((timesheetLog) => (
      <TimesheetLogRow
        key={timesheetLog.id}
        id={timesheetLog.id}
        timesheetLog={timesheetLog} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <TableSearch filter={this.search.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.pageOffset,
      limit: this.props.pageLimit,
      search: this.props.search,
      order: this.props.order,
      sort: this.props.sort,
      filters: this.props.filters,
    };
  }

  getTimesheetLogs(options) {
    if (!this.props.isWaiting) {
      this.props.getTimesheetLogs(
        options.offset,
        options.limit,
        options.search,
        options.order,
        options.sort,
        options.filters,
      );
    }
  }

  getSort(order) {
    return this.props.order === order ? this.props.sort : null;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = (page - 1) * size;
      options.limit = size;
      options.order = this.props.order;
      options.sort = this.props.sort;

      this.getTimesheetLogs(options);
    }
  }

  hasMorePages() {
    return this.props.hasMorePages;
  }

  updateTableHeight() {
    this.setState({ tableHeight: TimesheetLogTable.getTableMaxHeight() });
  }

  search(search) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.search = search;

      this.getTimesheetLogs(options);
    }
  }

  filter(filters) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.filters = filters;

      this.getTimesheetLogs(options);
    }
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getTimesheetLogs(options);
    }
  }

  render() {
    return (
      <Table columns={9} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="employee"
              className="timesheet-changelog__column-employee"
              onSort={this.sort.bind(this)}
              sort={this.getSort('employee')}>
              Employee
            </TableCell>
            <TableCell name="date"
              className="timesheet-changelog__column-date"
              onSort={this.sort.bind(this)}
              sort={this.getSort('date')}>
              Date
            </TableCell>
            <TableCell name="mode"
              className="timesheet-changelog__column-mode"
              onSort={this.sort.bind(this)}
              sort={this.getSort('mode')}>
              Mode
            </TableCell>
            <TableCell name="project-from"
              className="timesheet-changelog__column-project"
              onSort={this.sort.bind(this)}
              sort={this.getSort('project-from')}>
              Project
            </TableCell>
            <TableCell name="job-order"
              className="timesheet-changelog__column-job-order"
              onSort={this.sort.bind(this)}
              sort={this.getSort('job-order')}>
              Job Order
            </TableCell>
            <TableCell name="hours-to"
              className="timesheet-changelog__column-hours"
              onSort={this.sort.bind(this)}
              sort={this.getSort('hours-to')}>
              Hours
            </TableCell>
            <TableCell name="created"
              className="timesheet-changelog__column-date"
              onSort={this.sort.bind(this)}
              sort={this.getSort('created')}>
              Update Date
            </TableCell>
            <TableCell name="updated-by"
              className="timesheet-changelog__column-employee"
              onSort={this.sort.bind(this)}
              sort={this.getSort('updated-by')}>
              Updated By
            </TableCell>
            <TableCell name="project-to"
              className="timesheet-changelog__column-project"
              onSort={this.sort.bind(this)}
              sort={this.getSort('project-to')}>
              Transferred to
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
