/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');

class HeaderDay extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment().format('YYYY-MM-DD');
  }

  isToday() {
    return this.props.day.date === this.today;
  }

  getFormattedDay(day) {
    return moment(day, 'YYYY-MM-DD').format('DD');
  }

  render() {
    if (this.isToday()) {
      return (
        <div className="header__day">
          <div className="header__today-highlighter" />
          <div className="header--today">{this.getFormattedDay(this.props.day.date)}</div>
        </div>
      );
    }
    return <div className="header__day">{this.getFormattedDay(this.props.day.date)}</div>;
  }
}

module.exports = HeaderDay;
