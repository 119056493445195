/* eslint-disable react/no-did-update-set-state,no-underscore-dangle */
const React = require('react');
const moment = require('moment');
const isEqual = require('react-fast-compare');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const Body = require('./OrderSidebarBody.react');
const Actions = require('./OrderSidebarActions.react');
const { getPermission } = require('../../services/authManager');
const FormMetadataManager = require('../../../../../../../common/react/FormMetadataManager/FormMetadataManager.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.OrderSidebar}
 */
module.exports = class OrderSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes, false otherwise
      item: this.props.item,
      // by default is true if item has no id (when we want to  add a new one)
      editMode: !this.props.item.id,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.item && prevProps && prevProps.item) {
      // Update the newly created item id once it is first saved
      if (!prevState.item.id && prevProps.item.id) {
        const updatedItem = {
          ...prevState.item,
          id: prevProps.item.id,
        };
        this.setState({
          item: updatedItem,
        });
      }

      // Update files when the list changes (new file uploaded or file deleted)
      if (!isEqual(prevState.item.files, this.props.item.files)) {
        this.setState(({
          item: {
            ...prevState.item,
            files: this.props.item.files,
          },
        }));
      }

      // Set again edit mode when the saving is finished and there are validation errors due to save
      if (prevProps.isSaving && !this.props.isSaving && this.props.validationError !== null) {
        this.setState({ editMode: true });
      }
    }
  }

  // Save in the local state the modified item and check if there are unsaved changes
  handleInputChanges(name, value) {
    this.setState((prevState) => {
      let changes = {};
      /**
       * Special cases:
       * duration -> parseFloat || null
       * date, start -> format('YYYY-MM-DD')
       * supplier -> id_supplier
       * project -> id_project, budget_id, start, duration, area, currencies, ..
       */
      switch (name) {
        case 'duration':
          changes = {
            [name]: (value || value === 0) ? parseFloat(value) : null,
            id_area: this.props.item?.area?.id,
          };
          break;
        case 'date': // Date format
        case 'start':
        case 'date_paid_on':
          changes = {
            [name]: value ? moment(value).format('YYYY-MM-DD') : null,
            id_area: this.props.item?.area?.id,
          };
          break;
        case 'supplier':
          changes = {
            [name]: value,
            id_supplier: value ? value.id : null,
            id_area: this.props.item?.area?.id,
          };
          break;
        case 'area':
          changes = {
            [name]: value,
            id_area: value ? value.id : null,
          };
          break;
        case 'project':
          changes = {
            [name]: value,
            id_project: value ? value.id : null,
            budget_id: value ? value.budget_id : null,
            id_area: null,
            start: value ? value.date_start : null,
            duration: value ? value.duration : 1,
            area: null,
            currencies: [],
          };
          break;
        default:
          changes = { [name]: value };
          break;
      }

      const newItem = {
        ...prevState.item,
        ...changes,
      };

      const hasUnsavedChanges = this.hasChanged(name, value);

      return {
        hasUnsavedChanges,
        item: newItem,
      };
    });
  }

  handleItemDelete() {
    if (this.props.onDelete) {
      this.props.onDelete({ order: this.state.item });
    }
  }

  handleSave() {
    this.setState({
      editMode: false,
      hasUnsavedChanges: false,
    });

    if (this.props.onSave) {
      this.props.onSave(this.state.item);
    }
  }

  handleEditMode() {
    if (this.props.canEdit && !this.state.editMode) {
      this.setState({ editMode: true });
    }
  }

  handleClose() {
    if (!this.props.isSaving) {
      this.props.onClose();
    }
  }

  getBody() {
    const metadataNameOverrides = {
      id_project: 'project',
      id_supplier: 'supplier',
      budget_area_uid: 'area',
    };
    return (
      <FormMetadataManager metadata={this.state.item._fields} nameOverrides={metadataNameOverrides}>
        <Body item={this.state.item}
          onChange={this.handleInputChanges.bind(this)}
          errors={this.props.errors}
          serverError={this.props.validationError}
          readOnly={!this.state.editMode}
          resetServerError={this.props.resetOrderServerError}
          updateErrors={this.props.updateErrors} />
      </FormMetadataManager>
    );
  }

  getActions() {
    if (!this.state.editMode) {
      return (
        <Actions order={this.state.item}
          canDelete={this.canDelete()}
          closeSidebar={this.props.onClose}
          setAsApproved={this.props.approveOrder}
          onDelete={this.handleItemDelete.bind(this)} />
      );
    }
    return null;
  }

  /**
   * Checks if it's safe to save a item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.props.isValid;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEdit && !this.state.editMode;
  }

  canDelete() {
    return this.state.item.id && getPermission('delete', this.state.item);
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldItem = this.props.item || {};
    let oldVal = null;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'project':
        oldVal = oldItem.project ? oldItem.project.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'supplier':
        oldVal = oldItem.supplier ? oldItem.supplier.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'area':
        oldVal = oldItem.area ? oldItem.area.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      default:
        oldVal = oldItem[name];
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((OrderSidebar.isDirty(oldVal) || OrderSidebar.isDirty(newVal)) && (oldVal !== newVal)) {
      this.setState((prevState) => {
        // When the value has changed, we insert the name in the changes array, if not already present
        const newChanges = prevState.unsavedChanges;
        if (!newChanges.includes(name)) {
          newChanges.push(name);
        }
        this.setState({ unsavedChanges: newChanges });
      });

      totalChanges += 1;
    } else {
      this.setState((prevState) => {
        // When the value is equal to the props, we need to remove it from the changes array if
        // present
        let newChanges = prevState.unsavedChanges;
        newChanges = newChanges.filter((attr) => attr !== name);
        totalChanges = newChanges.length;

        return { unsavedChanges: newChanges };
      });
    }

    return totalChanges !== 0;
  }

  render() {
    return (
      <Sidebar title="Order details"
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.handleClose.bind(this)}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
