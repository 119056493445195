/* eslint-disable react/prop-types,react/sort-comp */
const React = require('react');
const NewsModel = require('../../models/AppBanner/NewsModel');

module.exports = class NewsBanner extends React.Component {
  componentDidMount() {
    this.props.onHeightChange(this.el.offsetHeight);
    dispatcher.trigger('app:banner:open');
  }

  getInternalLinkButton() {
    const link = this.props.internal_resource;
    if (link) {
      return <a href={link} className="update-banner__take-me-there">Take me there</a>;
    }
    return null;
  }

  getSupportLinkButton() {
    const link = this.props.external_resource;
    if (link) {
      return (
        <a href={link}
          target="_blank"
          rel="noreferrer"
          className="wethod-button update-banner__button update-banner__button--learn-more">
          Learn more
        </a>
      );
    }
    return null;
  }

  onCloseClick() {
    const newsModel = new NewsModel();
    newsModel.markAsRead(this.props);
    this.props.onCloseClick();
  }

  render() {
    return (
      <div className="update-banner" ref={(el) => this.el = el}>
        <div className="update-banner__content">
          <div className="update-banner__text">
            <p className="update-banner__title">{this.props.title}</p>
            <p className="update-banner__description">
              <span className="update-banner__description-text">{this.props.description}</span>
              {this.getInternalLinkButton()}
            </p>
          </div>
          <div className="update-banner__actions">
            {this.getSupportLinkButton()}
            <button type="button"
              className="wethod-button update-banner__button"
              onClick={this.onCloseClick.bind(this)}>
              Got it
            </button>
          </div>
        </div>
      </div>
    );
  }
};
