/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

/**
 * Big modal used to show TextArea content. It takes up all the available space in order to show more content with less
 * scroll.
 *
 * @see TextArea
 * @type {TextAreaModal}
 */
module.exports = class TextAreaModal extends React.Component {
  render() {
    return (
      <Modal title={this.props.title} onCancelClick={this.props.onCancelClick} className="project-canvas__text-area-modal-wrapper">
        {this.props.children}
      </Modal>
    );
  }
};
