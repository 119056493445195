const React = require('react');
const Version = require('./Version.react');

const VersionList = ({
  versions, selectedVersions = [], position, onVersionClick, setListEl,
  scrollWidth,
}) => {
  /**
   * Return if given version is currently selected.
   * @param version
   * @return {boolean}
   */
  const isVersionSelected = (version) => selectedVersions.includes(version);

  const children = versions.map((version) => (
    <Version key={version.version}
      label={version.version}
      onClick={onVersionClick}
      selected={isVersionSelected(version.version)}
      isBaseline={version.baseline} />
  ));

  const style = {
    left: position ? -position * scrollWidth : 0,
  };

  return (
    <div className="wethod-budget-version-list" style={style} ref={setListEl}>
      {children}
    </div>
  );
};

module.exports = VersionList;
