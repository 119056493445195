const React = require('react');
const Milestone = require('./MilestoneBar/MilestoneBar.react');
const Date = require('./DateBar/DateBar.react');

module.exports = ({ canEdit, onDateResizeStart, ...bar }) => {
  const style = {
    width: bar.width,
    left: bar.left,
    backgroundColor: bar.color,
  };

  if (bar.value.type) { // only milestones have "value.type"
    return <Milestone canEdit={canEdit} onResizeStart={onDateResizeStart} style={style} {...bar} />;
  }
  return <Date canEdit={canEdit} onResizeStart={onDateResizeStart} style={style} {...bar} />;
};
