const React = require('react');
const { isMe } = require('../../../../../services/UserService');

/**
 * Injects all needed permission checks into the given children.
 * @param children
 * @param permissions {{}} contains permissions settings from server
 * @param rest
 * @returns {React.DetailedReactHTMLElement<{canEdit: *, canViewOther: *, canView: *, canEditItem: (function(number): *|boolean), canEditOther: *, canDelete: *}, HTMLElement>}
 * @constructor
 */
const PermissionManager = ({ children, permissions, ...rest }) => {
  const authorizations = {
    canView: permissions.view,
    canEdit: permissions.edit,
    canDelete: permissions.delete,
    canViewOther: permissions.view_other,
    canEditOther: permissions.edit_other,
  };

  /**
   * An item can be edited if the user has "edit" permission and he's the owner of that item.
   * If user is not the owner, he can still edit the item if he has "edit_other" permission.
   * @param ownerId {number} id of the item's owner
   * @returns {boolean}
   */
  const canEditItem = (ownerId) => {
    const userIsOwner = isMe(ownerId);

    return authorizations.canEdit && (userIsOwner || authorizations.canEditOther);
  };

  return React
    .cloneElement(children, {
      ...authorizations,
      canEditItem,
      ...rest,
    });
};

module.exports = PermissionManager;
