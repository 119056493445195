/* eslint-disable react/prop-types,react/sort-comp */
const React = require('react');

/**
 * A component to reproduce infinite scroll.
 *
 * PROPS
 * className
 * hasMore (boolean) tells if there items left to pull
 * loadMore (function) called when the user has scrolled the list all the way down
 * itemHeight (integer) the height in px of a single list's item
 * children (array) the items list
 * handleScrollbar (function) called on didComponentUpdate, with the scrollbar's width as argument
 *
 * N.B: Define an height via CSS for this component, so it's available when this component mounts.
 * Otherwise this component will not be able to compute its pageSize and the scroll's limit which
 * trigger more items' load.
 *
 * @type {module.InfiniteScrollContainer}
 */
module.exports = class InfiniteScrollContainer extends React.Component {
  componentDidMount() {
    this.containerHeight = this.list.offsetHeight;
    this.contentHeight = this.list.scrollHeight;

    this.pageSize = Math.round(this.containerHeight / this.props.itemHeight) + 1;
    this.props.loadMore(this.pageSize);
  }

  componentDidUpdate() {
    this.contentHeight = this.list.scrollHeight;

    if (this.props.handleScrollbar) this.props.handleScrollbar(this.getScrollbarWidth());
  }

  getScrollbarWidth() {
    const containerWidth = this.list.offsetWidth;
    const itemWidth = this.list.children[0].offsetWidth;
    return containerWidth - itemWidth;
  }

  getClassName() {
    if (this.props.className) {
      return `${this.props.className} wethod-infinite-scroll`;
    }
    return 'wethod-infinite-scroll';
  }

  limitReached(scrollTop) {
    // Instead of load more items when the 100% of the list has been scrolled, start loading them
    // at 80% (1 - 0.2). This way the scroll feels smoother
    const pad = 0.2;
    const bottom = this.contentHeight - this.containerHeight;
    const limit = bottom - bottom * pad;
    return scrollTop >= limit;
  }

  onScroll(e) {
    if (!this.props.waiting && this.props.hasMore && this.limitReached(e.target.scrollTop)) {
      this.props.loadMore(this.pageSize);
    }
  }

  render() {
    return (
      <div className={this.getClassName()}
        ref={(element) => this.list = element}
        onScroll={this.onScroll.bind(this)}>
        {this.props.children}
      </div>
    );
  }
};
