const React = require('react');
const CreatedLabel = require('./RowCreatedLabel.react');
const DeletedLabel = require('./RowDeletedLabel.react');

/**
 * Returns component that can be used as label for a row.
 * A label is usually displayed in the upper left corner of the row.
 *
 * @param {boolean} exists tells if row exists
 * @param {boolean} existed tells if row has been deleted
 * @return {JSX.Element|null}
 * @constructor
 */
const RowLabelFactory = ({
  exists,
  existed,
}) => {
  // If "existed" is not available then RowLabel cannot be calculated, so there's no point in showing it
  if (existed === undefined) {
    return null;
  }

  const created = !existed && exists;
  const deleted = existed && !exists;

  if (created) {
    return <CreatedLabel />;
  }
  if (deleted) {
    return <DeletedLabel />;
  }
  return null;
};

module.exports = RowLabelFactory;
