/* eslint-disable no-shadow */
const React = require('react');
const DateService = require('../../../../../../../services/DateService');

module.exports = ({ date, timeToRead, cachedOn }) => {
  const getTimeToReadComponent = () => {
    const time = timeToRead;
    if (time) {
      return (
        <span className="briefing-content__info--time-to-read">
          {time}
          {' '}
          min read
        </span>
      );
    }
    return null;
  };

  /**
   * Return first Monday of the given month, based on ISO definition.
   * @param date string YYYY-MM
   * @return {Moment}
   */
  const getFirstMondayOfMonth = (date) => {
    const firstDay = moment(date).clone().date(1).day('Monday');

    return DateService.getMonth(firstDay.format('YYYY-MM-DD')) === moment(date).month() ? firstDay : firstDay.add(1, 'week');
  };

  const getCreatedAt = () => {
    if (cachedOn) {
      const cachedDate = moment(cachedOn);
      const formattedDate = cachedDate.format('dddd, MMM D, YYYY');
      const formattedTime = cachedDate.format('H a');
      return `${formattedDate} at ${formattedTime}`;
    }
    const nextMonth = date.clone().add(1, 'month');
    const firstMonday = getFirstMondayOfMonth(nextMonth.format('YYYY-MM'));
    const formattedDate = firstMonday.format('dddd, MMM D, YYYY');
    return `${formattedDate} at 6 am`;
  };

  return (
    <div className="briefing-content__info">
      <span
        className="briefing-content__info--created">
        <span>Freshly brewed with love on</span>
        {' '}
        {getCreatedAt()}
      </span>
      {getTimeToReadComponent()}
    </div>
  );
};
