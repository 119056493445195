/* eslint-disable react/jsx-no-bind */
const React = require('react');

module.exports = class TypeSwitcher extends React.Component {
  getItemClassName(key) {
    let name = 'wethod-tab-switcher__item';
    if (key === this.props.current) name += ' wethod-tab-switcher__item--selected';
    return name;
  }

  getClassName() {
    let name = 'wethod-tab-switcher';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <button type="button"
          onClick={() => { this.props.handleClick('spreadsheet'); }}
          className={this.getItemClassName('spreadsheet')}>
          <span className="project-canvas-board__type-icon wethod-icon-spreadsheet wethod-icon-spreadsheet--black" />
        </button>
        <button type="button"
          onClick={() => { this.props.handleClick('kanban'); }}
          className={this.getItemClassName('kanban')}>
          <span className="project-canvas-board__type-icon wethod-icon-kanban wethod-icon-kanban--black" />
        </button>
        <button type="button"
          onClick={() => { this.props.handleClick('gantt'); }}
          className={this.getItemClassName('gantt')}>
          <span className="project-canvas-board__type-icon wethod-icon-gantt wethod-icon-gantt--black" />
        </button>
      </div>
    );
  }
};
