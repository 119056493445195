const React = require('react');
const DuplicatesModal = require('../containers/modals/DuplicatesModal');

module.exports = ({ toShow, modalData, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'duplicates':
        return <DuplicatesModal data={modalData} onClose={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
