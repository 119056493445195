/**
 * Use this utility to show children only if business unit are enabled for the company.
 * @param enabled
 * @param children
 * @returns {null|*}
 * @constructor
 */
const WithBuEnabled = ({ enabled, children }) => {
  if (enabled) {
    return children;
  }
  return null;
};

module.exports = WithBuEnabled;
