const { connect } = require('react-redux');
const moment = require('moment');
const DayPickerComponent = require('../../../../../../../common/react/date-selectors/DaySelector.react');

const mapStateToProps = (state) => ({
  date: moment(state.date).format('YYYY-MM-DD'),
  disableWeekend: !state.internationalCalendar,
  isWaiting: state.isLoading,
});

module.exports = connect(mapStateToProps, null)(DayPickerComponent);
