module.exports = class LevelAmountNodePrevious {
  constructor(days, levelId) {
    this.days = days;
    this.levelId = levelId;
  }

  getDays() {
    return this.days;
  }

  getLevelId() {
    return this.levelId;
  }

  toJson() {
    return {
      days: this.getDays(),
      level_id: this.getLevelId(),
    };
  }
};
