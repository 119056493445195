const React = require('react');

module.exports = class CalendarHeaderColumnDay extends React.Component {
  getClassName() {
    const isToday = this.props.column.isSame(moment(), 'day');

    let name = 'project-canvas-gantt__calendar-header-column project-canvas-gantt__calendar-column--day';
    if (isToday) {
      name += ' project-canvas-gantt__calendar-header-column--today';
    }
    return name;
  }

  getMonth() {
    const firstDayOfMonth = this.props.column.date() === 1;
    if (firstDayOfMonth) {
      return <span className="project-canvas-gantt__calendar-header-month">{this.props.column.format('MMMM YYYY')}</span>;
    }
    return null;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getMonth()}
        {this.props.column.format('DD')}
      </div>
    );
  }
};
