/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,react/no-did-update-set-state,react/no-access-state-in-setstate */
const React = require('react');
const People = require('../containers/People');
const Row = require('../containers/CalendarRow');
const Header = require('../containers/CalendarHeader');
const VirtualGrid = require('./VirtualGrid/VirtualGrid.react');
const StickyGrid = require('./VirtualGrid/StickyGrid.react');
const InfiniteLoader = require('./VirtualGrid/InfiniteLoader.react');
const AutoSizer = require('./VirtualGrid/AutoSizer.react');
const Actions = require('./CalendarActions.react');
const Mobile = require('../../../../../../common/react/media-queries/Mobile.react');
const LaptopAndAbove = require('../../../../../../common/react/media-queries/LaptopAndAbove.react');
const Validator = require('../containers/Validator');
const HolidayManager = require('../containers/HolidayManager');

module.exports = class Calendar2 extends React.Component {
  constructor(props) {
    super(props);

    this.trackWidthByZoom = {
      hour: 44 * 4,
      day: props.international ? 44 * 7 : 44 * 5,
      week: props.international ? 12 * 7 : 12 * 5,
    };

    this.daysInTrack = {
      hour: 1,
      day: props.international ? 7 : 5,
      week: props.international ? 7 : 5,
    };

    this.state = {
      firstColumn: 2,
      firstRow: 0,
    };

    this.gridScrollLeft = null;
  }

  componentDidUpdate(prevProps) {
    const changedZoom = prevProps.zoom !== this.props.zoom;
    const prevSelectedProjectId = prevProps.selectedProject ? prevProps.selectedProject.id : null;
    const selectedProjectId = this.props.selectedProject ? this.props.selectedProject.id : null;
    const selectedProject = prevSelectedProjectId !== selectedProjectId;
    if (changedZoom) {
      this.setState({
        firstColumn: this.getColumnForChangedZoom(prevProps.zoom),
      });
    }
    if (selectedProject) {
      this.setState({
        firstRow: this.getChaosFactor(),
      });
    }
  }

  getColumnForChangedZoom(prevZoom) {
    const prevPastTracks = Math.floor(this.gridScrollLeft / this.trackWidthByZoom[prevZoom]);
    const pastTracks = Math.floor((prevPastTracks * this.daysInTrack[prevZoom])
      / this.daysInTrack[this.props.zoom]);

    return pastTracks + this.getChaosFactor();
  }

  /**
   * Return days grouped in tracks based on zoom and international calendar.
   * @returns {[]}
   */
  getTracks() {
    const tracks = [];

    const daysPerTrack = this.daysInTrack[this.props.zoom];
    for (let i = 0; i < this.props.days.length; i += daysPerTrack) {
      tracks.push([]);
      for (let j = 0; j < daysPerTrack; j++) {
        tracks[tracks.length - 1].push(this.props.days[i + j]);
      }
    }

    return tracks;
  }

  getClassName() {
    let name = 'planning-calendar';
    if (this.props.international) {
      name += ' planning-calendar--international';
    }
    if (this.props.selectedProject) {
      name += ' planning-calendar--project-selected';
    }
    name += ` planning-calendar--${this.props.zoom}`;
    return name;
  }

  getFuturePlan() {
    const lastDay = moment(this.props.days[this.props.days.length - 1]);
    const from = lastDay.add(1, 'weeks').isoWeekday(1);
    const to = from.clone().add(6, 'days').add(5, 'week');
    this.props.getPlan(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
  }

  getPastPlan() {
    const firstDay = moment(this.props.days[0]);
    const to = firstDay.subtract(1, 'days');
    const from = to.clone().subtract(3, 'weeks').isoWeekday(1);
    this.props.getPlan(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
  }

  /**
   * Return a random number between 0 and 0,1.
   * @returns {number}
   */
  getChaosFactor() {
    return Math.floor(Math.random() * 10) / 100;
  }

  /**
   * Scroll to the track containing the current date.
   * Issue: if this.state.firstColumn is 2, scroll changes and I want to get back to 2, I can't because this.state does not changes
   * and VirtualGrid does not get re-rendered.
   * Solution: add chaosFactor (a random number < 0,1) to the new firstColumn in order to make state change and trigger VirtualGrid re-render.
   */
  scrollToToday() {
    const today = moment();
    const pastDays = this.props.days.filter((day) => moment(day).isBefore(today));
    const pastTracks = Math.floor((pastDays.length - 1) / this.daysInTrack[this.props.zoom]);
    this.setState({ firstColumn: pastTracks + this.getChaosFactor() });
  }

  scrollToFuture() {
    this.setState({ firstColumn: this.state.firstColumn + 2 });
  }

  scrollToPast() {
    this.setState({ firstColumn: this.state.firstColumn - 2 });
  }

  fixedHeaderRenderer(columns) {
    const tracks = columns.map((column) => column.track);
    return <Header tracks={tracks} />;
  }

  leftFixedColumnRenderer(rows) {
    const people = rows.map((row) => row[0].person);
    return <People people={people} />;
  }

  rowsRenderer(rows) {
    return rows.map((row) => {
      const tracks = row.map((column) => column.track);
      const { person } = row[0];
      const plans = this.props.plans[person.id] ? this.props.plans[person.id] : [];
      const personWithPlans = {
        ...person,
        plans,
      };

      return (
        <HolidayManager key={person.id}>
          <Validator person={person} plans={plans}>
            {({ validateChanges }) => (
              <Row tracks={tracks}
                person={personWithPlans}
                validateChanges={validateChanges} />
            )}
          </Validator>
        </HolidayManager>
      );
    });
  }

  onGridScroll(e) {
    this.gridScrollTop = e.target.scrollTop;
    this.gridScrollLeft = e.target.scrollLeft;
  }

  render() {
    const items = this.props.people.map((person) => this.getTracks()
      .map((track) => ({
        person,
        track,
      })));

    return (
      <div className={this.getClassName()}>
        <AutoSizer>
          {({ width, height }) => (
            <InfiniteLoader
              onLoadLeft={this.getPastPlan.bind(this)}
              onLoadRight={this.getFuturePlan.bind(this)}>
              {({ loader, loadMore, hideLoader }) => (
                <div>
                  <Mobile>
                    <StickyGrid
                      headerRenderer={this.fixedHeaderRenderer.bind(this)}
                      headerHeight={57}
                      leftColumnRenderer={this.leftFixedColumnRenderer.bind(this)}
                      leftColumnWidth={110}>
                      {({ stickyElements, stickyElementsSize }) => (
                        <VirtualGrid
                          items={items}
                          rowHeight={54}
                          columnWidth={this.trackWidthByZoom[this.props.zoom]}
                          loader={loader}
                          loadMore={loadMore}
                          hideLoader={hideLoader}
                          onScroll={this.onGridScroll.bind(this)}
                          firstColumn={this.state.firstColumn}
                          firstRow={this.state.firstRow}
                          width={width}
                          height={height}
                          rowsRenderer={this.rowsRenderer.bind(this)}
                          actions={(
                            <Actions onTodayClick={this.scrollToToday.bind(this)}
                              onPrevClick={this.scrollToPast.bind(this)}
                              onNextClick={this.scrollToFuture.bind(this)} />
                          )}
                          stickyElements={stickyElements}
                          stickyElementsSize={stickyElementsSize} />
                      )}
                    </StickyGrid>
                  </Mobile>
                  <LaptopAndAbove>
                    <StickyGrid
                      headerRenderer={this.fixedHeaderRenderer.bind(this)}
                      headerHeight={57}
                      leftColumnRenderer={this.leftFixedColumnRenderer.bind(this)}
                      leftColumnWidth={220}>
                      {({ stickyElements, stickyElementsSize }) => (
                        <VirtualGrid
                          onScroll={this.onGridScroll.bind(this)}
                          items={items}
                          rowHeight={54}
                          columnWidth={this.trackWidthByZoom[this.props.zoom]}
                          loader={loader}
                          loadMore={loadMore}
                          hideLoader={hideLoader}
                          firstColumn={this.state.firstColumn}
                          firstRow={this.state.firstRow}
                          width={width}
                          height={height}
                          rowsRenderer={this.rowsRenderer.bind(this)}
                          actions={(
                            <Actions onTodayClick={this.scrollToToday.bind(this)}
                              onPrevClick={this.scrollToPast.bind(this)}
                              onNextClick={this.scrollToFuture.bind(this)} />
                          )}
                          stickyElements={stickyElements}
                          stickyElementsSize={stickyElementsSize} />
                      )}
                    </StickyGrid>
                  </LaptopAndAbove>
                </div>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    );
  }
};
