const React = require('react');
const View = require('./MemberItemCellView.react');
const Edit = require('../../../../../containers/Boards/Spreadsheet/ItemCell/MemberItemCellEdit');

module.exports = ({ canEdit, addMember, ...rest }) => {
  if (canEdit) {
    return <Edit addMember={addMember} {...rest} />;
  }
  return <View {...rest} />;
};
