/* eslint-disable no-unused-vars */

const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const JobTitle = {
  list(filters = {}) {
    let url = '/job-title';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
  create(name, levelId, archived = false) {
    return request({
      method: 'post',
      url: '/job-title',
      data: {
        name,
        level: levelId,
        archived,
      },
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/job-title/${id}`,
      data,
    });
  },
};

module.exports = JobTitle;
