const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const BuFilter = require('../../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
const TypeSwitcher = require('../containers/TypeSwitcher');
const ExportButton = require('../../../../../../common/react/ExportButton/ExportButton.react');

const Actions = ({
  buEnabled,
  buFilter,
  typeFilter,
  size,
  keyword,
  changeBusinessUnit,
  showExportModal,
}) => {
  function handleBUchange(name, units) {
    changeBusinessUnit(size, keyword, typeFilter, units);
  }

  const getBuFilter = () => {
    if (buEnabled) {
      return (
        <div className="production-plan__bu-select">
          <BuFilter onApply={handleBUchange} selectedValues={buFilter} />
        </div>
      );
    }
    return null;
  };
  function onExportClick(e) {
    e.preventDefault();
    showExportModal();
  }

  return (
    <div className="wethod-section-actions">
      <SectionHeader current_section="Production Plan"
        preview_anchor_id="production-plan"
        helper_url="planning/index/#production-plan" />
      <div className="production-plan__actions">
        {getBuFilter()}
        <div className="production-plan__type-switcher">
          <TypeSwitcher />
        </div>
        <div className="production-plan__actions--right">
          <ExportButton onClick={onExportClick} />
        </div>
      </div>
    </div>
  );
};

module.exports = Actions;
