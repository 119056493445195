const TaskNode = require('./TaskNode');
// eslint-disable-next-line no-unused-vars
const LevelAmountNode = require('./LevelAmountNode');
// eslint-disable-next-line no-unused-vars
const JobTitleNode = require('./JobTitleNode');
const Total = require('../NodeTotal/TaskNodeTotal');

/**
 * Represent a basic task, can have related job titles.
 */
module.exports = class BaseTaskNode extends TaskNode {
  /**
   * @param task
   * @param {LevelAmountNode[]} levelAmounts
   * @param {JobTitleNode[]} jobTitles
   * @param {AvailableLevel[]} availableLevels
   */
  constructor(
    task,
    levelAmounts,
    availableLevels,
    jobTitles = [],
  ) {
    super(task, levelAmounts, availableLevels);
    this.jobTitles = jobTitles;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  /**
   * @return {JobTitle[]}
   */
  getJobTitles() {
    return this.jobTitles;
  }

  /**
   * Return total task days, taking into account days related to job titles.
   * @private
   * @returns {number}
   */
  getDays() {
    return this.getLevels()
      .reduce((sum, level) => sum + level.getTotal().days, 0)
      + this.getJobTitlesDays();
  }

  /**
   * Return total amount of days related to job titles.
   * @return {number}
   */
  getJobTitlesDays() {
    return this.jobTitles.reduce((sum, jobTitle) => sum + jobTitle.getDays(), 0);
  }

  /**
   * Return cost of days related to job titles.
   * @return {number}
   */
  getJobTitlesCost() {
    return this.jobTitles.reduce((sum, jobTitle) => sum + jobTitle.getCost(), 0);
  }

  /**
   * Return price of days related to job titles.
   * @return {number}
   */
  getJobTitlesPrice() {
    return this.jobTitles.reduce((sum, jobTitle) => sum + jobTitle.getPrice(), 0);
  }

  /**
   * Return cost of all days related to this task (job title days included).
   * @private
   * @returns {number}
   */
  getDaysCost() {
    return this.getLevels()
      .reduce((sum, level) => sum + level.getTotal().cost, 0)
      + this.getJobTitlesCost();
  }

  /**
   * Return price of all days related to this task (job title days included).
   * @private
   * @returns {number}
   */
  getDaysPrice() {
    return this.getLevels()
      .reduce((sum, level) => sum + level.getTotal().price, 0)
      + this.getJobTitlesPrice();
  }

  /**
   * Return amount of days for the given level in this task.
   * Take into account days related to job titles.
   * @param levelId
   * @return {number}
   */
  getDaysForLevel(levelId) {
    const jobTitleLevelAmounts = this.jobTitles
      .filter((jobTitle) => jobTitle.getLevel().getId() === levelId);

    if (jobTitleLevelAmounts.length) {
      return jobTitleLevelAmounts
        .reduce((sum, jobTitleAmount) => sum + jobTitleAmount.getTotal().getDays(), 0);
    }
    return super.getDaysForLevel(levelId);
  }

  /**
   * Return previous budget version amount of days for the given level in this task.
   * Take into account days related to job titles.
   * @param levelId
   * @return {number}
   */
  getPreviousDaysForLevel(levelId) {
    const jobTitleLevelAmounts = this.jobTitles
      .filter((jobTitle) => jobTitle.getLevel().getId() === levelId);

    if (jobTitleLevelAmounts.length) {
      return jobTitleLevelAmounts
        .reduce((sum, jobTitleAmount) => sum + jobTitleAmount.getPreviousDays(), 0);
    }
    return super.getPreviousDaysForLevel(levelId);
  }

  /**
   * @private
   * @returns {Total}
   */
  calculateTotal() {
    const days = this.getDays();
    const cost = this.getDaysCost() + this.getExternalCost();
    const price = this.getDaysPrice() + this.getExternalCost() + this.getMarkupAmount();

    return new Total(days, cost, price, 0, this.getDaysByLevel());
  }

  toJson() {
    return {
      ...super.toJson(),
      jobTitles: this.jobTitles.map((jobTitle) => jobTitle.toJson()),
    };
  }
};
