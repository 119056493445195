const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

module.exports = class CannotDownloadModal extends React.Component {
  getBody() {
    switch (this.props.type) {
      case 'not-found':
        return (
          <span>
            Seems like this file <b>does not exist anymore</b>.
          </span>
        );
      default:
        return <span>Something went wrong, please retry or refresh the page.</span>;
    }
  }

  getTitle() {
    return this.props.type === 'not-found' ? 'Cannot download' : 'Oops';
  }

  render() {
    return (
      <Modal title={this.getTitle()} onCancelClick={this.props.close} className="project-canvas-alert">
        {this.getBody()}
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.close} className="wethod-button">OK</button>
        </div>
      </Modal>
    );
  }
};
