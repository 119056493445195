/* eslint-disable react/jsx-no-bind */
const React = require('react');

const RecurringModeButton = ({ enabled, toggleRecurringMode, closeProjectInfo }) => {
  const label = enabled ? 'Disable recurring mode' : 'Enable recurring mode';

  const className = enabled ? 'button planning__recurring-mode-button planning__recurring-mode-button--enabled' : 'button planning__recurring-mode-button';

  const onClick = () => {
    closeProjectInfo();
    toggleRecurringMode();
  };

  return (
    <div className={className} onClick={onClick}>
      <div className="planning__recurring-mode-button-icon wethod-icon-medium-repeat wethod-icon-medium-repeat--black" />
      <div className="button__label">{label}</div>
    </div>
  );
};

module.exports = RecurringModeButton;
