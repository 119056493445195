/* eslint-disable camelcase,react/sort-comp,react/jsx-no-bind */
const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');

module.exports = class TravelOfficeModal extends React.Component {
  sendTravel(send_to) {
    this.props.data.travel.status = 'sent';
    this.props.data.travel.sent_to = send_to;
    this.props.data.setSending(true);
    this.props.sendTravelRequest((this.props.data.travel));
    this.props.onCancelClick();
  }

  getBody() {
    return this.props.travelOffices.map((contact) => (
      <div key={contact.id} className="travel__modal-multi-choice-item" onClick={() => this.sendTravel(contact)}>
        {contact.alias}
        <div className="wethod-icon-arrow-right" />
      </div>
    ));
  }

  render() {
    return (
      <Modal title="Send request to" onCancelClick={this.props.onCancelClick} className="travel__modal-multi-choice-modal">
        <div className="travel__modal-multi-choice">
          {this.getBody()}
        </div>
      </Modal>
    );
  }
};
