'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ProfileStore = require('../../../apps/core/modules/settings/profile/store');
var ProfileReducer = require('../../../apps/core/modules/settings/profile/reducer');
var ProfileRoot = require('../../../apps/core/modules/settings/profile/index');

Wethod.module('SettingsApp.Profile', function (Profile, Wethod, Backbone, Marionette, $) {
  var hasPermission = function (permissionName, employeeIdToShow, permissions) {
    var employeeId = Wethod.userInfo ? Wethod.userInfo.get('employee_id') : null;
    var ownProfile = employeeId === parseInt(employeeIdToShow);

    if (ownProfile) {
      return permissions[permissionName];
    }
    return permissions[permissionName + '_other'];
  };

  var hasJobTitle = function (profile) {
    if (profile.get('job_title')) {
      return profile.get('job_title');
    }
    return {
      id: null,
      name: null,
    };
  };

  Profile.Controller = {
    showProfile: function (employeeToShowId) {
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      // Initialize views
      Profile.structureTemplate = new Profile.StructureLayout();
      Profile.structureTemplate.render();
      Profile.structureTemplate.body.show(loadingView);

      var userEmployeeId = Wethod.userInfo.get('employee_id');
      var profileRequest = Wethod.request('profile:get', employeeToShowId);
      var availableLanguagesRequest = Wethod.request('profile:languages:get');
      var projectReviewConfigRequest = Wethod.request('get:company:pipeline:review:config');
      var permissionsRequest = Wethod.request('profile:permissions:get');

      $.when(
        profileRequest,
        projectReviewConfigRequest,
        permissionsRequest,
        availableLanguagesRequest
      )
        .done(function (profile, projectReviewConfig, permissions, availableLanguages) {
          if (profile.status && profile.status !== 200) {
            Wethod.trigger('url:404:show');
          } else {
            var peopleRequest = Wethod.request('profile:teammates:get');
            $.when(peopleRequest)
              .done(function (people) {
                var reducerWrapper = new ProfileReducer({
                  id: profile.get('id'),
                  business_unit_id: profile.get('business_unit_id'),
                  isReviewEnabled: projectReviewConfig.get('enabled'),
                  editable: profile.get('id') === userEmployeeId,
                  email: profile.get('email'),
                  pic: profile.get('pic'),
                  level: profile.get('level'),
                  tags: profile.get('tags'),
                  skills: profile.get('skills'),
                  interests: profile.get('interests'),
                  known_interests: profile.get('known_interests'),
                  known_skills: profile.get('known_skills'),
                  portfolio: profile.get('portfolio'),
                  languages: profile.get('languages'),
                  sponsors: profile.get('sponsors'),
                  sponsored: profile.get('sponsored'),
                  name: profile.get('person').name + ' ' + profile.get('person').surname,
                  person: profile.get('person'),
                  people: people.toJSON(),
                  availableLanguages: availableLanguages,
                  permissions: {
                    okr: hasPermission('okr', employeeToShowId, permissions.attributes),
                    metrics: hasPermission('metrics', employeeToShowId, permissions.attributes),
                  },
                  jobTitle: hasJobTitle(profile),
                });

                var store = ProfileStore(reducerWrapper.reduxReducer);
                var element = React.createElement(ProfileRoot, { store: store });
                var container = document.getElementById('profileRoot');
                if (container !== null) ReactDOM.render(element, container);
              });
          }
        });
    },
  };
});
