const { connect } = require('react-redux');
const Component = require('../components/Expense.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  showSidebar: (item) => dispatch(actions.showSidebar(item)),
  showNotesModal: (item) => dispatch(actions.showNotesModal(item)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
