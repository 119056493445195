const request = require('./Request');

const Currency = {
  getForProject(id) {
    return request({
      method: 'get',
      url: `/currency/for-project/${id}`,
    });
  },
  getWithProjectRates(projectId) {
    return request({
      method: 'get',
      url: `/currency/rates/for-project/${projectId}`,
    });
  },
};

module.exports = Currency;
