const React = require('react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');

const BudgetConsumptionField = ({ value }) => {
  const formattedValue = value ? `${value} %` : '';

  return (
    <TextField
      readOnly
      id="sidebar-budget-consumption"
      label="Budget consumption"
      value={formattedValue} />
  );
};

module.exports = BudgetConsumptionField;
