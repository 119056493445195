const React = require('react');

const PricelistTooltipMessage = ({ name }) => (
  <div className="wethod-budget-calculator__level-info-tooltip-message">
    <div className="wethod-budget-calculator__level-info-tooltip-row">
      <div className="wethod-budget-calculator__level-info-tooltip-name">Pricelist</div>
    </div>
    <div className="wethod-budget-calculator__level-info-tooltip-row">
      <div className="wethod-budget-calculator__level-info-tooltip-value">
        {name}
      </div>
    </div>
  </div>
);

module.exports = PricelistTooltipMessage;
