const React = require('react');
const PropTypes = require('prop-types');

const SidebarInputWrapper = ({ icon, children }) => (
  <div className="material-input material-input--sidebar">
    <div className="material-input__icon">{icon}</div>
    <div className="material-input__input">
      {children}
    </div>
  </div>
);

SidebarInputWrapper.defaultProps = {
  icon: null,
};

SidebarInputWrapper.propTypes = {
  /**
   * Input content.
   */
  children: PropTypes.node.isRequired,
  /**
   * Element to use as side icon.
   */
  icon: PropTypes.node,
};

module.exports = SidebarInputWrapper;
