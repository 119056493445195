const React = require('react');

module.exports = class WeeklyWorkedChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        backgroundColor: '#FCFCFC',
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
            connectorWidth: 0,
            distance: 10,
            formatter() {
              return `${this.y}%<br> ${this.point.name}`;
            },
          },
        },
      },
      series: [{
        type: 'pie',
        innerSize: '50%',
        data: this.props.groups.map((group) => ({
          name: group.group,
          y: group.worked_hours_perc,
          color: group.color,
        })),
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    if (this.props.groups.length) {
      Highcharts.chart(this.chartContainer, this.getChartOptions());
    }
  }

  render() {
    if (this.props.groups.length) {
      return (
        <div className="briefing-aside-chart" id="briefing-aside-chart-weekly__worked">
          <div className="briefing-aside-chart__header">
            <h5 className="briefing-aside-chart__title">Weekly Worked Hours</h5>
            <h6 className="briefing-aside-chart__subtitle">in percentage</h6>
          </div>
          <div className="briefing-aside-chart__content"
            style={{ height: '150px' }}
            ref={(chartContainer) => this.chartContainer = chartContainer} />
        </div>
      );
    }
    return null;
  }
};
