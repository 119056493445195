const { connect } = require('react-redux');
const Component = require('../../components/modals/FiscalYearChangeConfirmationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForChange: state.waitingFor.filter((key) => key.startsWith('get-fiscal-year')),
  previousFiscalYear: state.previousFiscalYear,
});

const mapDispatchToProps = (dispatch) => ({
  onFiscalYearChangeCancel: (previousFiscalYear) => {
    dispatch(actions.setFiscalYearState(previousFiscalYear));
  },
  onFiscalYearChangeConfirm: (fiscalYear) => {
    dispatch(actions.setFiscalYear(fiscalYear));
  },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
