const React = require('react');
const ProjectInfo = require('../../containers/Header/ProjectInfo/ProjectInfo');
const SuggestedPrice = require('../../containers/Header/SuggestedPrice');
const FinalPrice = require('../../containers/Header/FinalPrice/FinalPrice');
const RequestManager = require('../../containers/RequestManager');
const PermissionManager = require('../../containers/PermissionManager');
const CurrencyManager = require('../../containers/CurrencyManager');

/**
 * Budget header.
 * @returns {JSX.Element}
 * @constructor
 */
const Header = () => (
  <div className="wethod-budget-header">
    <ProjectInfo />
    <SuggestedPrice />
    <PermissionManager>
      <RequestManager>
        <CurrencyManager>
          <FinalPrice />
        </CurrencyManager>
      </RequestManager>
    </PermissionManager>
  </div>
);

module.exports = Header;
