'use strict';

Wethod.module('View.MetadataValueFilter', function (MetadataValueFilter, Wethod, Backbone, Marionette, $, _) {
  this.ItemView = Marionette.ItemView.extend({
    template: _.template('<a href="#"><%= name %></a>'),
    className: 'wethod-filter-select__item',
    tagName: 'li',
    triggers: {
      click: 'choose:metadata:value',
    },
  });
  this.ListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    _triggerSet: false,
    childView: this.ItemView,
    triggers: {
      'choose:metadata:value': 'choose:metadata:value',
    },
  });
  this.SelectView = Marionette.LayoutView.extend({
    _listCollection: [],
    _listView: null,
    _metadata: null,
    template: '#buFilterSelectTemplate',
    className: 'wethod-filter-select',
    regions: {
      items: '[data-region="items"]',
    },
    ui: {
      isButtonDisabled: true,
      button: 'button',
      list: '.wethod-filter-select__list',
    },
    events: {
      'click @ui.button': 'onButtonClick',
    },
    childEvents: {
      'choose:metadata:value': 'onChildChooseItem',
    },
    initialize: function (options) {
      this._metadata = options.values;
      this._threeYP = options.threeYP;

      var metadataType = this._threeYP.metadataType;

      if (metadataType) {
        this.model = new Backbone.Model({
          title: 'Select a Metadata value',
          name: 'Select a metadata value',
        });
        this._listCollection = new Backbone.Collection(this._metadata[metadataType]);
      } else {
        this.model = new Backbone.Model({
          title: 'Select a Metadata Type first',
          name: 'Select a metadata type first',
        });
        this._listCollection = new Backbone.Collection();
      }

      this.on('choose:metadata:value', this.saveGlobalMetadata);
      dispatcher.on('choose:metadata:type', this.populateList.bind(this));
    },
    populateList: function (type) {
      this._threeYP.metadataValue = false;
      this._listCollection = new Backbone.Collection(this._metadata[type.id]);
      this._listView.collection = new Backbone.Collection(this._metadata[type.id]);
      this.model.set('name', 'Select a metadata value');
      this.render();
    },
    saveGlobalMetadata: function (model) {
      this._threeYP.metadataValue = model.get('id');
    },
    onRender: function () {
      this.showList();

      var metadataType = this._threeYP.metadataType;
      if (metadataType === 'No metadata' || metadataType === 'All') {
        this.ui.button.addClass('disabled-metadata');
        this.ui.isButtonDisabled = true;
      } else {
        this.ui.button.removeClass('disabled-metadata');
        this.ui.isButtonDisabled = false;
      }
    },
    showList: function () {
      var listView = new Wethod.View.MetadataValueFilter.ListView({
        collection: this._listCollection,
      });
      this.getRegion('items').show(listView);
      this._listView = this.getRegion('items').currentView;
    },
    onButtonClick: function () {
      if (!this.ui.isButtonDisabled) {
        this.ui.list.toggle();
      }
    },
    onChildChooseItem: function (childView, args) {
      var prevName = this.model.get('name');
      this.model.set('name', args.model.get('name'));
      this.render();
      if (args.model.get('name') !== prevName) {
        this.trigger('choose:metadata:value', args.model);
        dispatcher.trigger('choose:metadata:value', args.model);
      }
    },
  });
});
