const { connect } = require('react-redux');
const Component = require('../../components/modals/NotesModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-expense').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (id, changes) => dispatch(actions.updateExpense(id, changes)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
