'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $) {
  this.RuleDependencyModel = Wethod.Utility.BaseModel.extend({});

  this.RuleDependecyCollection = Wethod.Utility.BaseCollection.extend({
    model: RuleManager.RuleDependencyModel,
    url: APIURL + '/rule-engine/dependency',
    getDependenciesByRuleName: function (name) {
      var rule = this.findWhere({ rule_name: name });
      return rule.get('rule_dependencies');
    },
  });

  var API = {
    getRulesDependencies: function () {
      var ruleDependencies = new RuleManager.RuleDependecyCollection();
      var defer = $.Deferred();

      ruleDependencies.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:rules:dependencies', function () {
    return API.getRulesDependencies();
  });
});
