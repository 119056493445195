/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */
const React = require('react');
const TextInput = require('../../../../../../common/react/TextInput.react');

module.exports = class FavouriteSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusOnLabel: false,
    };
  }

  handleFavouriteDelete() {
    this.props.deleteFavourite(this.props.search.id);
  }

  handleBlur(value) {
    if (value.trim() !== this.props.search.label) {
      const newSearch = {
        ...this.props.search,
        label: value,
      };
      this.props.updateLabel(newSearch);
    }
    this.setState({ focusOnLabel: false });
  }

  handleFocus() {
    this.setState({ focusOnLabel: true });
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.input.blur();
    }
  }

  getInputStyle() {
    let style = 'desk-widget-pinboard__input';
    if (this.state.focusOnLabel) style += ' desk-widget-pinboard__input--focused';
    return style;
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getProjectSection() {
    const isSectionValid = this.props.search.section && this.props.search.section !== 'pipeline';
    return isSectionValid ? this.props.search.section : 'projects';
  }

  render() {
    return (
      <div className="desk-widget__item desk-widget-pinboard__item">
        <div className={this.getInputStyle()}>
          <TextInput value={this.props.search.label}
            ref={(input) => {
              this.input = input;
            }}
            onBlur={this.handleBlur.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onKeyUp={this.handleKeyUp.bind(this)} />
        </div>
        <div className="desk-widget-pinboard__actions">
          <a className="wethod-icon-button wethod-icon-button--no-border"
            href={`#pipeline/${this.getProjectSection()}?${this.props.search.search}`}>
            <div className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
          <span className="desk-widget-pinboard__vertical-bar" />
          <button type="button"
            className="wethod-icon-button wethod-icon-button--no-border desk-widget-pinboard__actions--bookmark"
            onClick={this.handleFavouriteDelete.bind(this)}>
            <div className="wethod-icon wethod-icon-bookmark-full wethod-icon-bookmark-full--black" />
          </button>
        </div>
      </div>
    );
  }
};
