const React = require('react');
const { Provider } = require('react-redux');
const TimesheetWeekly = require('./containers/Timesheet');

module.exports = ({ store }) => (
  <Provider store={store}>
    <div>
      <TimesheetWeekly />
    </div>
  </Provider>
);
