'use strict';

Wethod.module('PlanningApp', function (PlanningApp, Wethod, Backbone, Marionette, $) {
  this.ExportModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/export/entity?select=planning&download=false',
  });

  var API = {
    getExport: function () {
      var record = new PlanningApp.ExportModel();
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:planning:export', function () {
    return API.getExport();
  });
});
