const { connect } = require('react-redux');
const Component = require('../components/ShareModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  opportunity: state.focusedItem,
  isWaiting: state.isLoadingPM,
  isSaving: state.isSaving,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
  share: (notes, employeeId, opportunityId) => dispatch(actions.shareOpportunity(notes, employeeId,
    opportunityId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
