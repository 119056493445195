const React = require('react');
const InvoiceButton = require('./InvoiceCreationButton.react');
const invoicePermission = require('../services/authManager').getInvoicePermission;

const CardActions = ({ project, delta, budget, createInvoice }) => {
  const canCreateInvoice = invoicePermission('create', project);

  const createButton = canCreateInvoice
    ? (
      <InvoiceButton
        delta={delta}
        budget={budget}
        onClick={createInvoice}>New invoice
      </InvoiceButton>
    )
    : null;

  return createButton ? (
    <div className="invoice-plan__calendar-card-month-actions">
      {createButton}
    </div>
  ) : null;
};

module.exports = CardActions;
