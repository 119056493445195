'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.AddButtonView = Marionette.LayoutView.extend({
    /**
     *  _availableOptionsView : shorthand for the TooltipView attached to this SelectView.
     *  _clickable : false if the button is disabled.
     */
    _availableOptionsView: null,
    _clickable: true,
    template: '#addButtonTemplate',
    className: 'rule-button-wrapper col_4',
    regions: {
      availableOptions: '[data-region=\'tooltip\']',
    },
    ui: {
      newConditionButton: '[data-action=\'addConditionButton\']',
      newConditionButtonLabel: '[data-action=\'addConditionButton\'] .text',
      tooltip: '[data-region=\'tooltip\']',
    },
    events: {
      'click @ui.newConditionButton': 'toggleTooltip',
    },
    onRender: function () {
      this.attachTooltipView();

      this._availableOptionsView.on('tooltip:item:selected', this.onTooltipOptionSelected, this);
    },
    attachTooltipView: function () {
      var tooltipView = new RuleManager.TooltipView({ collection: this.collection });
      this.availableOptions.show(tooltipView);
      this._availableOptionsView = this.availableOptions.currentView;
    },
    toggleTooltip: function () {
      if (this._clickable === true) {
        this.ui.tooltip.toggleClass('isVisible');
      }
    },
    onTooltipOptionSelected: function (optionModel) {
      this.toggleTooltip();
      this.trigger('tooltip:item:selected', optionModel);
    },
    disable: function () {
      this._clickable = false;
      this.ui.newConditionButton.addClass('disabled');
    },
    enable: function () {
      this._clickable = true;
      this.ui.newConditionButton.removeClass('disabled');
    },
    setLabel: function (name) {
      this.ui.newConditionButtonLabel.text(name);
    },
  });
});
