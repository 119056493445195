/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const HeaderWeeks = require('./HeaderWeeks.react');
const TraspImage = require('../../../../../../../img/trasp.gif');

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(e) {
    e.preventDefault();
  }

  componentDidMount() {
    $(this.headerContent).on('wheel', this.handleScroll);
  }

  getClass() {
    let className = 'planning__loading';
    if (!this.props.isLoading) {
      className += ' hide';
    }
    return className;
  }

  render() {
    const daysInWeek = this.props.isInternational ? 7 : 5;
    const weeksArray = [];
    for (let i = 0; i < this.props.days.length; i += daysInWeek) {
      const week = [];
      for (let j = 0; j < daysInWeek; j++) {
        week.push(this.props.days[i + j]);
      }
      weeksArray.push(week);
    }
    return (
      <div
        className="planning__header"
        ref={(content) => {
          this.headerContent = content;
        }}>
        <div className="header__edge-placeholder">
          <div className={this.getClass()}>
            <img src={TraspImage} className="loadingImg" alt="Loading..." />
            <div className="clear" />
          </div>
        </div>
        <HeaderWeeks weeks={weeksArray} />
      </div>
    );
  }
}

module.exports = Header;
