const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ValueController.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  currencies: state.focusedItem ? state.focusedItem.currencies : [],
});

const mapDispatchToProps = (dispatch) => ({
  updateError: (error) => dispatch(actions.updateOrderError(error)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
