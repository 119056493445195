/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const Module = require('./Module');

module.exports = class DeskModule extends Module {
  constructor(module, userPermissions) {
    super(module, userPermissions);
  }

  isVisible() {
    return this._userPermissions.dashboard === true;
  }

  isMobile() {
    return this.getMobile();
  }

  hasAreasWithUrl(url) {
    return url === '/#desk';
  }

  hasUrl(url) {
    return this.getUrl() === url;
  }
};
