const { connect } = require('react-redux');
const Component = require('../../components/modals/DuplicatesModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-supplier').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (supplier, force) => dispatch(actions.addSupplier(supplier, force)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
