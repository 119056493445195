const React = require('react');
const Levels = require('../Cell/LevelsCell.react');
const Level = require('./SummaryLevel.react');
const LevelService = require('../../../services/LevelService');

const SummaryLevels = ({ items, levelsExtended, previousItems }) => {
  const getDays = (idLevel) => {
    const item = items.find((el) => el.level_id === idLevel);
    return item ? item.days : 0;
  };

  const sortedLevels = LevelService.sort(levelsExtended)?.filter((el) => el.is_visible);
  const levels = sortedLevels?.map((level) => (
    <Level
      key={level.id}
      previousValue={previousItems.find((el) => el.level_id === level.level.id).days}>
      {getDays(level.level.id)}
    </Level>
  ));

  return (
    <Levels className="wethod-budget-summary__levels">{levels}</Levels>
  );
};

module.exports = SummaryLevels;
