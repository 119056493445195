/* eslint-disable no-underscore-dangle,guard-for-in,no-shadow */

'use strict';

Wethod.module('PipelineApp.Basket', function (Basket, Wethod, Backbone, Marionette, $, _) {
  //----------
  // Utils
  //----------
  var _parse = function (response) {
    var data = (response.data) ? response.data : response;
    return response.code === 404 ? [] : data;
  };

  var _sync = function (method, model, options) {
    if (!options.crossDomain) {
      options.crossDomain = true;
    }

    if (!options.xhrFields) {
      options.xhrFields = { withCredentials: true };
    }

    _.each(this._requestPool, function (request) {
      if (request.readyState !== 4) {
        request.abort();
      }
    });

    if (method === 'read') {
      var xhr = proxiedSync(method, model, options);
      this._requestPool.push(xhr);
      return xhr;
    }
    return proxiedSync(method, model, options);
  };

  //-------
  // Models
  //-------
  this.Model = Backbone.Model.extend({
    defaults: function () {
      return {
        id: null,
        name: null,
        value: null,
        note: null,
        due_date: null,
        client_id: null,
        project_id: null,
        account_id: null,
        last_ps: null,
        client: {
          id: null,
          name: null,
        },
        project: {
          id: null,
          name: null,
          job_order: null,
        },
        account: {
          id: null,
          name: null,
          surname: null,
        },
      };
    },
    urlRoot: APIURL + '/opportunity/',
    parse: _parse,
  });

  this.Coll = Backbone.Collection.extend({
    model: Basket.Model,
    parse: _parse,
    _offset: 0,
    _limit: 50,
    _search: null,
    _requestPool: [],
    url: function () {
      var query = '?offset=' + this._offset + '&limit=' + this._limit;
      query = this._search ? query + '&search=' + this._search : query;
      this._offset += this._limit;
      return APIURL + '/opportunity/' + query;
    },

    sync: _sync,

    getNextPage: function (search, offset, limit) {
      if (search) {
        this._search = search.replace(/ /g, ','); // white space to comma
      }

      if (offset) {
        this._offset = offset;
      }

      if (limit) {
        this._limit = limit;
      }

      var defer = $.Deferred();
      this.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.reject(undefined);
        },

        remove: false,
      });

      return defer.promise();
    },
  });

  this.ClientModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/client/',
    /**
     * Visits http://thedersen.com/projects/backbone-validation/#built-in-validators.
     */
    validation: {
      corporate_name: [{
        required: true,
        msg: 'Name is required',
      }],
      acronym: [{
        required: true,
        msg: 'Acronym is required',
      }],
    },
    getValidationErrors: function () {
      var errors = this.validate();
      var errorNames = _.keys(errors);
      var errorsArray = [];
      _.each(errorNames, function (name) {
        var error = {};
        error[name] = errors[name];
        errorsArray.push(error);
      });
      return errorsArray;
    },
    getPlainErrors: function () {
      var errors = this.getValidationErrors();
      var plainErrors = [];
      for (var i = 0; i < errors.length; i++) {
        for (var error in errors[i]) {
          plainErrors.push(errors[i][error]);
        }
      }
      return plainErrors;
    },
  });

  this.AutoCompClientModel = Backbone.Model.extend();

  this.AutocompClientColl = Backbone.Collection.extend({
    initialize: function (options) {
      this._search = options.search;
    },
    model: Basket.AutoCompClientModel,
    _requestPool: [],
    __search: null,

    url: function () {
      return APIURL + '/pipeline/autocomplete?on=client&like=' + this._search;
    },

    sync: _sync,
    parse: _parse,
  });

  this.Modal = Backbone.Model.extend();

  //---------------
  // Sync Functions
  //---------------
  var saveModel = function (model) {
    var defer = $.Deferred();
    model.save(null, {
      success: function (model, response) {
        if (response.code === 200 || response.code === 201) {
          defer.resolve(model);
        } else {
          defer.resolve(response.message);
        }
      },

      error: function () {
        defer.reject(undefined);
      },

      wait: false,
    });
    return defer.promise();
  };

  var deleteModel = function (model) {
    var defer = $.Deferred();

    model.destroy({
      success: function (model, response) {
        if (response.code === 200) {
          defer.resolve(model);
        } else {
          defer.resolve(response.message);
        }
      },

      error: function () {
        defer.reject(undefined);
      },

      wait: true,
    });
    return defer.promise();
  };

  var share = function (options) {
    var message = options.message;
    var employeeId = options.employeeId;
    var basketId = options.basketId;
    var path = options.path;

    var defer = $.Deferred();
    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: APIURL + '/opportunity/' + basketId + '/share/' + employeeId,
      xhrFields: {
        withCredentials: true,
      },
      data: {
        message: message,
        path: path,
      },
    }).done(function (response) {
      defer.resolve(response);
    }).fail(function (response) {
      defer.reject(response);
    });

    return defer.promise();
  };

  var getAutoCompClient = function (search) {
    var defer = $.Deferred();
    var coll = new Basket.AutocompClientColl({ search: search });

    coll.fetch({
      success: function (collection, response) {
        if (response.code === 200) {
          defer.resolve(collection);
        } else {
          defer.resolve(undefined);
        }
      },

      error: function (data, response) {
        if (response.statusText !== 'abort') {
          defer.reject(undefined);
        } else {
          // TODO "request-aborted" viene usato nella view in caso di "done", mentre ora
          //  dovrebbe essere "fail"; eliminare entrambi
          defer.reject('request-aborted');
        }
      },
    });

    return defer.promise();
  };

  //---------
  // Handlers
  //---------
  Wethod.reqres.setHandler('get:pipeline:basket:coll', function (coll, offset, search, limit) {
    coll = !coll ? new Basket.Coll() : coll; // if no coll given init new one
    return coll.getNextPage(search, offset, limit);
  });

  Wethod.reqres.setHandler('save:pipeline:basket', function (model) {
    return saveModel(model);
  });

  Wethod.reqres.setHandler('delete:pipeline:basket', function (model) {
    return deleteModel(model);
  });

  Wethod.reqres.setHandler('send:pipeline:basket:share', function (options) {
    return share(options);
  });

  Wethod.reqres.setHandler('get:pipeline:basket:autoCompClient', function (search) {
    return getAutoCompClient(search);
  });

  Wethod.reqres.setHandler('save:pipeline:basket:client', function (model) {
    return saveModel(model);
  });
});
