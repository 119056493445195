const React = require('react');
const Paragraph = require('../../../../../common/components/SectionParagraph.react');
const TargetComparator = require('../../../../containers/sections/Production/TargetComparator');
const WonProjectsAgainstLastYear = require('../../../../containers/sections/Sales/WonProjectsAgainstLastYear');
const NumericValue = require('../../../../../common/components/NumericValue.react');
const NumerableSentence = require('../../../NumerableSentence.react');

module.exports = ({ count, value, target }) => {
  const getWonProjects = () => (
    <span>
      <span className="bold">
        Last week we won <NumericValue value={count} />
        {' '}
        <NumerableSentence quantity={count}>projects</NumerableSentence>
      </span>
      {' '}
      <NumerableSentence
        quantity={count}>
        for a total value of
      </NumerableSentence>
      {' '}
      <NumericValue
        value={value} />
    </span>
  );

  if (!target) {
    return (
      <Paragraph>
        {getWonProjects()}.
      </Paragraph>
    );
  }
  return (
    <Paragraph>
      {getWonProjects()}, which is <TargetComparator value={value} /> the average target of
      {' '}
      <NumericValue value={target} /> per week <WonProjectsAgainstLastYear />.
    </Paragraph>
  );
};
