'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var DeskStore = require('../../apps/core/modules/desk/store');
var DeskReducer = require('../../apps/core/modules/desk/reducer');
var Desk = require('../../apps/core/modules/desk/index');

Wethod.module('DeskApp', function (DeskApp, Wethod, Backbone, Marionette, $) {
  DeskApp.Controller = {
    show: function () {
      var userAuthorizationRequest = Wethod.request('get:dashboard:authorization');
      var projectReviewConfigRequest = Wethod.request('get:company:pipeline:review:config');
      $.when(userAuthorizationRequest, projectReviewConfigRequest)
        .done(function (permission, projectReviewConfig) {
          var layout = new DeskApp.StructureLayoutView();
          layout.render();

          var reducerWrapper = new DeskReducer({
            widgetPermissions: permission.attributes,
            isReviewEnabled: projectReviewConfig.get('enabled'),
          });

          var store = DeskStore(reducerWrapper.reduxReducer);
          var element = React.createElement(Desk, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
