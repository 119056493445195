/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,eqeqeq */
const React = require('react');
const BusinessUnit = require('./BusinessUnit.react');

class BusinessUnitList extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  toggleLabel(businessUnits) {
    return businessUnits.length ? <div className="dropdown__title">Business Units</div> : null;
  }

  isSelected(bu) {
    return Boolean(this.props.selectedBusinessUnits.find((selectedBu) => selectedBu.id == bu.id));
  }

  onClick(bu) {
    if (!this.isSelected(bu)) {
      this.props.onSelect(bu);
    } else {
      this.props.onRemove(bu);
    }
  }

  render() {
    const businessUnits = this.props.businessUnits.map((bu) => (
      <BusinessUnit
        active={this.isSelected(bu)}
        key={bu.id}
        bu={bu}
        onClick={this.onClick} />
    ));

    const style = !businessUnits.length ? { borderBottom: 'none' } : {};
    return (
      <div style={style} className="filter__block">
        {this.toggleLabel(businessUnits)}
        {businessUnits}
      </div>
    );
  }
}

module.exports = BusinessUnitList;
