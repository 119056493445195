const request = require('./Request');

const types = {
  text: 1,
  date: 2,
  number: 3,
};

const JobOrderTemplate = {
  isEnabled() {
    return request({
      method: 'get',
      url: '/authorization/settings/auto-job-order',
    });
  },
  setEnabled(enabled) {
    return request({
      method: 'post',
      url: `/authorization/settings/auto-job-order?enabled=${enabled}`,
    });
  },
  getAll() {
    return request({
      method: 'get',
      url: '/autojoborder/templates',
    });
  },
  update(id, changes) {
    return request({
      method: 'patch',
      url: `/autojoborder/template/${id}`,
      data: changes,
    });
  },
  create(data) {
    return request({
      method: 'patch',
      url: '/autojoborder/template',
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/autojoborder/template/${id}`,
    });
  },
  generate(jobOrderCategoryId) {
    return request({
      method: 'get',
      url: `/autojoborder/generate/${jobOrderCategoryId}`,
    });
  },
};

module.exports = JobOrderTemplate;

module.exports.types = types;
