const React = require('react');
const PropertyMetadata = require('../../../../../../../services/PropertyMetadataService/PropertyMetadata');
const FrozenDataTooltip = require('../../../../finance/orders/components/sidebar/FrozenDataTooltip.react');

/**
 * If children is not editable due to metadata then return a read-only field with explanation in
 * tooltip.
 * If children is editable then return the given child.
 *
 * ⚠️ This custom component is needed just because inputs are using deprecated components, on refactor
 * you can use the common InputMetadataManager component.
 *
 * @param {PropertyMetadataService} metadataService
 * @param {JSX.Element} children
 * @param {string} name
 * @param {JSX.Element} readOnlyComponent Component to show when children is not editable
 * @return {JSX.Element}
 * @constructor
 */
const FieldFactory = ({ metadataService, children, name, readOnlyComponent }) => {
  const editable = metadataService.getCanEdit(name);
  const reason = metadataService.getReason(name);

  if (!editable && reason === PropertyMetadata.CANNOT_EDIT_DATA_FROZEN) {
    return (
      <FrozenDataTooltip>
        {readOnlyComponent}
      </FrozenDataTooltip>
    );
  }
  return children;
};

module.exports = FieldFactory;
