const React = require('react');
const ZoomSwitcher = require('../containers/ZoomSwitcher');
const ProjectSelection = require('../containers/ProjectSelection/ProjectSelection');
const FilterPeople = require('./FilterPeople/FilterPeople.react');
const RecurringModeButton = require('../containers/RecurringModeButton');
const LaptopAndAbove = require('../../../../../../common/react/media-queries/LaptopAndAbove.react');

const Actions = ({
  projects, selectProject, peopleFilters, buEnabled, selectPeopleFilter,
  deselectPeopleFilter, activePeopleFilters, clearPeopleFilters,
}) => (
  <div className="planning-people__actions">
    <div className="planning-people__actions--left">
      <ZoomSwitcher />
    </div>
    <div className="planning-people__actions--right">
      <FilterPeople
        filters={peopleFilters}
        buEnabled={buEnabled}
        activeFilters={activePeopleFilters}
        onFilterSelection={selectPeopleFilter}
        onFilterDeselection={deselectPeopleFilter}
        onClearFilters={clearPeopleFilters} />
      <LaptopAndAbove><RecurringModeButton /></LaptopAndAbove>
      <ProjectSelection
        projects={projects}
        onSelectedProject={selectProject} />
    </div>
  </div>
);

module.exports = Actions;
