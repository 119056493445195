function getAvgOfSelectedCriterion(item, selectedCriterionId, role) {
  if (item) {
    const selectedCriterion = item[role]
      .filter((criterion) => criterion.criterion_id === selectedCriterionId);
    if (!selectedCriterion || !selectedCriterion.length) {
      return null;
    }
    return selectedCriterion[0].avg;
  }
  return null;
}

/**
 *
 * Sort employees by 'avg' of given criterion,
 * the employees are put with descending or ascending order (based on 'order' param)
 *
 * @param items
 * @param selectedCriterionId
 * @param role ('as_team_member' || 'as_pm')
 * @param order (desc || asc)
 * @returns {Array} items sorted by given col
 */
module.exports = (items, selectedCriterionId, role, order = 'desc') => items.sort((a, b) => {
  const avgA = getAvgOfSelectedCriterion(a, selectedCriterionId, role);
  const avgB = getAvgOfSelectedCriterion(b, selectedCriterionId, role);
  if (order === 'desc') {
    return avgB - avgA;
  }
  if (order === 'asc') {
    return avgA - avgB;
  }

  return 0;
});
