/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Navigator = require('./Navigator/Navigator.react');

module.exports = class CalendarActions extends React.Component {
  render() {
    return (
      <div className="planning-calendar__header-track planning-calendar__header-track--actions">
        <div className="planning-calendar__header-track--actions-content">
          <Navigator onTodayClick={this.props.onTodayClick}
            onPrevClick={this.props.onPrevClick}
            onNextClick={this.props.onNextClick} />
        </div>
      </div>
    );
  }
};
