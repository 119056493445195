/* eslint-disable backbone/no-model-attributes */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.projectId = options.projectId;
    this.urlRoot = `${APIURL}/canvas/${this.projectId}/concept`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  updateModel() {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: this.urlRoot,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
