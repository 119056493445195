/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const Slider = require('../../../../../../common/react/Slider.react');

/**
 * Goal element for widget body
 * @type {Goal}
 */
module.exports = class Goal extends React.Component {
  onSliderChange(name, value) {
    this.props.saveGoal({ ...this.props.goal, progress: value }, this.props.projectId, false);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.saveGoal({ ...this.props.goal, progress: value }, this.props.projectId);
    }, 500);
  }

  onClick() {
    if (this.props.canEdit && !this.props.isWaiting) {
      this.props.onClick(this.props.goal.id);
    }
  }

  formatNumber(number) {
    return number.toLocaleString('it-IT');
  }

  getStyle() {
    let style = 'project-canvas__goal';

    if (!this.props.canEdit || this.props.isWaiting) {
      style += ' project-canvas__goal--disabled';
    }

    return style;
  }

  render() {
    return (
      <div className={this.getStyle()}>
        <div onClick={this.onClick.bind(this)}>
          <div className="project-canvas__goal-name">{this.props.goal.name}</div>
          <div className="project-canvas__goal-progress">
            <span className="project-canvas__goal-progress--progress">{this.formatNumber(this.props.goal.progress)}</span>
            <span className="project-canvas__goal-progress--target">
              /
              {this.formatNumber(this.props.goal.target)}
            </span>
          </div>
        </div>
        <div>
          <Slider name={this.props.goal.name}
            value={this.props.goal.progress}
            max={this.props.goal.target}
            onChange={this.onSliderChange.bind(this)}
            disabled={!this.props.canEdit} />
        </div>
      </div>
    );
  }
};
