const { connect } = require('react-redux');
const Component = require('../components/Navigator.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  visibleMonths: state.visibleMonths,
  year: state.year,
  updatingVisibleMonths: state.updatingVisibleMonths,
});

const mapDispatchToProps = (dispatch) => ({
  updateVisibleMonths: (months) => dispatch(actions.updateVisibleMonths(months)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
