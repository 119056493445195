/* eslint-disable react/prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const FormatService = require('../../../../../../../services/FormatService');
const MathService = require('../../../../../../../services/MathService');

const NumericValue = ({ value, decimal }) => {
  if (decimal) {
    const rounded = MathService.round(value, 1);
    return <span>{numeral(rounded).format('0,0.[0]')}</span>;
  }
  return <span>{FormatService.formatNumberAsUnit(value)}</span>;
};

NumericValue.propTypes = {
  value: PropTypes.number.isRequired,
};

module.exports = NumericValue;
