const React = require('react');
const { Provider } = require('react-redux');
const DataFreezingEngine = require('./components/DataFreezingEngine.react');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <DataFreezingEngine />
  </Provider>
);
