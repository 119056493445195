/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const Section = require('./Section');

module.exports = class GroupSection extends Section {
  constructor(section, userPermissions) {
    super(section, userPermissions);
  }

  getUrl() {
    if (Section.prototype.isVisible.call(this)) { // calling parent method
      return Section.prototype.getUrl.call(this);
    }
    return 'http://support.wethod.com/group-setup/index/';
  }

  isVisible() {
    return true;
  }

  isBlocked() {
    return !Section.prototype.isVisible.call(this);
  }
};
