const { connect } = require('react-redux');
const Component = require('../components/ProductsList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'get-products').length > 0,
  products: state.products,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  hasMorePages: state.hasMorePages,
  availabilityFilter: state.availabilityFilter,
  search: state.search,
  sort: state.sort,
  order: state.order,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (offset, limit, search, order, sort, availabilityFilter) => dispatch(
    actions.getProducts(offset, limit, search, order, sort, availabilityFilter),
  ),
  getLevels: () => dispatch(actions.getLevels()),
  showSidebar: (id) => dispatch(actions.showSidebar(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
