const React = require('react');
const Button = require('./MulticurrencyActionButton.react');

const EnableMulticurrencyAction = ({
  disabled,
  showModalEnableMulticurrency,
}) => (
  <Button disabled={disabled} label="Enable multicurrency" onClick={showModalEnableMulticurrency} />
);

module.exports = EnableMulticurrencyAction;
