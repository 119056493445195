/* eslint-disable react/jsx-no-bind */
const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const YearNavigator = require('../../../../../../common/react/YearNavigator/YearNavigator.react');

const Actions = ({ isWaiting, year, changeYear }) => {
  const getSliderClass = () => {
    if (isWaiting) {
      return 'disabled';
    }
    return null;
  };

  return (
    <div className="wethod-section-actions">
      <SectionHeader current_section="Project & Team Reviews" helper_url="reports/index/#project-team-reviews-changelog" big />
      <div className="review-changelog__actions">
        <YearNavigator changeDate={changeYear} year={year} className={getSliderClass()} />
      </div>
    </div>
  );
};

module.exports = Actions;
