const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const YearFilter = require('../../../finance/clients/detail/company/models/TimeFilter/YearFilter');
const QuarterFilter = require('../../../finance/clients/detail/company/models/TimeFilter/QuarterFilter');
const FiscalYearService = require('../../../../../../services/FiscalYearService');

const TimeFilter = ({ title, timeFilter, changeFilter }) => {
  const fiscalYearService = new FiscalYearService();

  function getFilterButtonClassName(value) {
    let name = 'client-kpi__time-filter-item';
    if (TimeFilterFactory(timeFilter).getLabel() === value) {
      name += ' client-kpi__time-filter-item--selected';
    }
    return name;
  }

  function onOneYearFilterClick() {
    const filter = new QuarterFilter();
    changeFilter(filter.toJSON());
  }

  function onThreeYearFilterClick() {
    const filter = new YearFilter(3);
    changeFilter(filter.toJSON());
  }

  function onFiveYearFilterClick() {
    const filter = new YearFilter(5);
    changeFilter(filter.toJSON());
  }

  return (
    <div className="client-kpi__time-filter">
      <div className="chargeability__title">
        <span>{title}</span>
      </div>
      <button type="button"
        className={getFilterButtonClassName('1 year')}
        onClick={onOneYearFilterClick}>
        {fiscalYearService.getYearDynamically(fiscalYearService.getShiftedYear(moment().year()))}
      </button>
      <button type="button"
        className={getFilterButtonClassName('3 years')}
        onClick={onThreeYearFilterClick}>
        3 years
      </button>
      <button type="button"
        className={getFilterButtonClassName('5 years')}
        onClick={onFiveYearFilterClick}>
        5 years
      </button>
    </div>
  );
};

module.exports = TimeFilter;
