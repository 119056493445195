module.exports.GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
module.exports.GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
module.exports.GET_REVIEW_NOT_FOUND_FAILURE = 'GET_REVIEW_NOT_FOUND_FAILURE';
module.exports.GET_REVIEW_COMPLETED_FAILURE = 'GET_REVIEW_COMPLETED_FAILURE';
module.exports.GO_TO_STEP = 'GO_TO_STEP';
module.exports.EDIT_PROJECT_VOTE = 'EDIT_PROJECT_VOTE';
module.exports.EDIT_PERSON_VOTE = 'EDIT_PERSON_VOTE';
module.exports.EDIT_PERSON_NOTE = 'EDIT_PERSON_NOTE';
module.exports.SHOW_PERSON_NOTE_MODAL = 'SHOW_PERSON_NOTE_MODAL';
module.exports.SHOW_UNSAVED_CHANGES_MODAL = 'SHOW_UNSAVED_CHANGES_MODAL';
module.exports.SHOW_SAVE_MODAL = 'SHOW_SAVE_MODAL';
module.exports.SHOW_MISSING_VOTE_MODAL = 'SHOW_MISSING_VOTE_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SAVE_REVIEW_REQUEST = 'SAVE_REVIEW_REQUEST';
module.exports.SAVE_REVIEW_SUCCESS = 'SAVE_REVIEW_SUCCESS';
module.exports.FILTER_PEOPLE = 'FILTER_PEOPLE';
