module.exports.FILTER_PROJECTS = 'FILTER_PROJECTS';

module.exports.SORT_PROJECTS = 'SORT_PROJECTS';

module.exports.CHANGE_WEEK = 'CHANGE_WEEK';

module.exports.GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
module.exports.GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

module.exports.GET_INTERNATIONAL_CALENDAR_SETTINGS_REQUEST = 'GET_INTERNATIONAL_CALENDAR_SETTINGS_REQUEST';
module.exports.GET_INTERNATIONAL_CALENDAR_SETTINGS_SUCCESS = 'GET_INTERNATIONAL_CALENDAR_SETTINGS_SUCCESS';

module.exports.SAVE_PROJECT_STATUS_REQUEST = 'SAVE_PROJECT_STATUS_REQUEST';
module.exports.SAVE_PROJECT_STATUS_SUCCESS = 'SAVE_PROJECT_STATUS_SUCCESS';
module.exports.SAVE_PROJECT_STATUS_FAILURE = 'SAVE_PROJECT_STATUS_FAILURE';

module.exports.SAVE_RISK_REQUEST = 'SAVE_RISK_REQUEST';
module.exports.SAVE_RISK_SUCCESS = 'SAVE_RISK_SUCCESS';

module.exports.SHOW_PROJECT_AREAS_MODAL = 'SHOW_PROJECT_AREAS_MODAL';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_RISK_SELECT = 'SHOW_RISK_SELECT';
module.exports.CLOSE_RISK_SELECT = 'CLOSE_RISK_SELECT';

module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';

module.exports.GET_PROJECT_INFO_REQUEST = 'GET_PROJECT_INFO_REQUEST';
module.exports.GET_PROJECT_INFO_SUCCESS = 'GET_PROJECT_INFO_SUCCESS';

module.exports.SAVE_PROJECT_STATUS_BY_AREA_REQUEST = 'SAVE_PROJECT_STATUS_BY_AREA_REQUEST';
module.exports.SAVE_PROJECT_STATUS_BY_AREA_SUCCESS = 'SAVE_PROJECT_STATUS_BY_AREA_SUCCESS';
