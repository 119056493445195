const moment = require('moment');
const $ = require('jquery');
const HTTPService = require('../../../../../services/HTTPService');

const TranslatorService = {
  /**
   * Explodes the given URL in its base, search, archivedFilter parts and returns an object
   * containing them.
   *
   * @param url {string}
   * @returns {{archivedFilter: (boolean|number|null|string), search: {string}, base: {string}}}
   */
  explodeUrl(url) {
    const urlPartsMatcher = url.match(/(?<base>pipeline\/project)\/?(?<search>[^/]*)\/?(?<archived>.*)/);

    // Everything from "pipeline" word to "project", with or without final slash
    const { base } = urlPartsMatcher.groups;
    // Everything between "base" and the next slash (not included)
    const { search } = urlPartsMatcher.groups;
    // Everything after "search", without slash
    const archivedFilter = urlPartsMatcher.groups.archived;

    return {
      base,
      search,
      archivedFilter,
    };
  },

  /**
   * Returns true if the given filter is a known old filter.
   * @param filter {string} something like prob:90+ or po:3 or account:luca,andrea
   * @returns {boolean}
   */
  isValidFilter(filter) {
    const filterRegex = new RegExp('(client|project|id|value|joc|type|prob|start|end|status|plan|client|po|pm|account|program|archived-date):.*', 'i');
    return filterRegex.test(filter);
  },

  /**
   * Translates a number like "16", "16+","16-","16..17".
   * @param name {string} filter key
   * @param value {string} a string representing a number
   * @returns {string}
   */
  translateNumericFilter(name, value) {
    const numericParts = this.extractNumericParts(value);

    switch (numericParts.type) {
      case 'range': {
        return `min-${name}=${numericParts.min}&max-${name}=${numericParts.max}`;
      }
      case 'min': {
        return `min-${name}=${numericParts.number}`;
      }
      case 'max': {
        return `max-${name}=${numericParts.number}`;
      }
      default: {
        return `min-${name}=${numericParts.number}&max-${name}=${numericParts.number}`;
      }
    }
  },

  /**
   * Analyzes the given string and, based on infered type, return an object containing all its parts:
   * - range: {type:'range', min, max, operator}
   * - bottom limit: {type:'min', number, operator}
   * - top limit: {type:'max', number, operator}
   * - single value: {type:'equal', number, operator}
   *
   * @param string {string}
   * @returns {{[p: string]: *}|{number: *, type: string}}
   */
  extractNumericParts(string) {
    const rangeRegex = /(?<min>\d+)(?<operator>\.\.)(?<max>\d+)/;
    const limitRegex = /(?<number>\d+)(?<operator>\+|-)/;
    const rangeMatch = string.match(rangeRegex);
    const limitMatch = string.match(limitRegex);

    if (rangeMatch) {
      return { type: 'range', ...rangeMatch.groups };
    }
    if (limitMatch) {
      const { operator } = limitMatch.groups;

      if (operator === '+') {
        return { type: 'min', ...limitMatch.groups };
      }
      if (operator === '-') {
        return { type: 'max', ...limitMatch.groups };
      }
    }
    return {
      type: 'equal',
      number: string,
    };
  },

  /**
   * Translates a date like "0116", "0116+","0116-","0116..1216".
   * @param name {string} filter key
   * @param value {string} a string representing a date with format "MM-YY", like "0116"
   * @returns {string}
   */
  translateDate(name, value) {
    const numericParts = this.extractNumericParts(value);
    const valueFormat = 'MM-YY';

    switch (numericParts.type) {
      case 'range': {
        const min = moment(numericParts.min, valueFormat).format('YYYY-MM-DD');
        const max = moment(numericParts.max, valueFormat).format('YYYY-MM-DD');
        return `min-${name}=${min}&max-${name}=${max}`;
      }
      case 'min': {
        const date = moment(numericParts.number, valueFormat).format('YYYY-MM-DD');
        return `min-${name}=${date}`;
      }
      case 'max': {
        const date = moment(numericParts.number, valueFormat).format('YYYY-MM-DD');
        return `max-${name}=${date}`;
      }
      default: {
        const date = moment(numericParts.number, valueFormat).format('YYYY-MM-DD');
        return `min-${name}=${date}&max-${name}=${date}`;
      }
    }
  },

  translateProbability(value) {
    return this.translateNumericFilter('prob', value);
  },

  translateValue(value) {
    return this.translateNumericFilter('value', value);
  },

  translateJobOrderCategory(value) {
    return `job-order-category=${value}`;
  },

  translateStart(value) {
    return this.translateDate('start-date', value);
  },

  translateEnd(value) {
    return this.translateDate('end-date', value);
  },

  translateBudgetStatus(value) {
    const map = {
      draft: 'draft',
      approval: 'submitted',
      approved: 'approved',
      null: 'missing',
    };

    return `budget-status=${map[value]}`;
  },

  translateInvoicePlanMode(value) {
    return `invoice-plan-mode=${value}`;
  },

  translateProjectType(value) {
    return `project-type=${value}`;
  },

  translateArchived(value) {
    const map = {
      archived: true,
      current: false,
    };

    return `archived=${map[value]}`;
  },

  /**
   * Translates the given filter from the old way to the new one.
   * @param filter {string} something like prob:90
   * @returns {*}
   */
  translateFilter(filter) {
    // Functions that knows how to traslate filters
    const translators = {
      prob: this.translateProbability.bind(this),
      joc: this.translateJobOrderCategory.bind(this),
      value: this.translateValue.bind(this),
      start: this.translateStart.bind(this),
      end: this.translateEnd.bind(this),
      status: this.translateBudgetStatus.bind(this),
      plan: this.translateInvoicePlanMode.bind(this),
      type: this.translateProjectType.bind(this),
    };

    const match = filter.match(/(?<key>\w*):(?<value>[^;]*)/);
    const { key } = match.groups;
    const { value } = match.groups;
    const escapedValue = value.replace(/\s/g, '+');

    return translators[key] ? translators[key](escapedValue) : `${key}=${escapedValue}`;
  },

  /**
   * Translates all the given filters and returns a query string representing them.
   * @param filters {string[]}
   * @returns {string}
   */
  translateFilters(filters) {
    return filters.map((filter) => this.translateFilter(filter)).join('&');
  },

  /**
   * Extracts and returns a list of valid filters from the given string.
   * @param string {string}
   * @returns {string[]}
   */
  extractFilters(string) {
    const decoded = decodeURIComponent(string);
    // Everything starting with a word, separated by something else by ":" and terminated by ";"
    const possibleFilters = decoded.match(/\w*:[^;]*/g);
    return possibleFilters ? possibleFilters.filter((text) => this.isValidFilter(text)) : [];
  },

  /**
   * Translates given URL from the old way of representing filters to the new one.
   * The old way used key:value pairs and threated "archived" filter as a special one.
   * The new way uses a structure more similar to query string.
   *
   * The only meaning of this method is to keep backward compatibility with old saved URLs.
   *
   * @param url {string}
   * @returns {string}
   */
  translate(url) {
    // Try to match base, search, archived
    const exploded = this.explodeUrl(url);
    const filters = this.extractFilters(exploded.search);
    const translatedFilters = this.translateFilters(filters);
    let newUrl = 'pipeline/projects';

    // If search does not contains valid filters
    if (translatedFilters === '') {
      // If search its not empty
      if (exploded.search.length > 0) {
        // If search is a number, treat it like a project id
        if (Number(exploded.search)) {
          const id = Number(exploded.search);
          newUrl += `?id=${id}`;
        } else { // otherwise treat it like a base search
          newUrl += `?search=${exploded.search}`;
        }
      }
    } else {
      newUrl += `?${translatedFilters}`;
    }

    // Add archived filter, if exists
    if (exploded.archivedFilter !== '' && exploded.archivedFilter !== 'all') {
      newUrl += `&${this.translateArchived(exploded.archivedFilter)}`;
    }

    return newUrl;
  },

  /**
   * Returns default filters object based on given type:
   * - prob: 0 - 75 for type "opportunities", 90 - 100 otherwise
   *
   * @param type {string} projects|opportunities
   * @returns {{prob: {min: string, max: string}, archived: string}}
   */
  getDefaultFilters(type) {
    return {
      'min-prob': type === 'opportunities' ? '0' : '90',
      'max-prob': type === 'opportunities' ? '75' : '100',
    };
  },

  /**
   * Returns an object representing all filters found in the given query string.
   *
   * @param queryString {string}
   * @returns {*} object containing all found filters
   */
  parseFilters(queryString) {
    return HTTPService.getQueryParams(queryString);
  },

  /**
   * Transforms the given filter map into a query string ready to be appended to URL.
   *
   * @param map {{}} represents filters to encode: a key for each filter, value can be numeric or
   * string or array of number/string
   * @returns {string} encoded queryString
   */
  serializeFilters(map) {
    return HTTPService.buildQueryString(map);
  },

  /**
   * Composes and returns the right segment (URL part after #) based on the given type and filters.
   * @param type {string} projects|opportunities
   * @param filters {{}} map of filters
   * @returns {string}
   */
  getPipelineUrl(type, filters) {
    const queryString = this.serializeFilters(filters);
    const base = `pipeline/${type}`;

    if (queryString !== '') {
      return `${base}?${queryString}`;
    }
    return base;
  },

  /**
   * Resolves with the current sections for the project with given id.
   * Section is based on probability:
   * - 'opportunities' if < 90
   * - 'projects' otherwise
   *
   * @param id {number}
   * @returns {Promise}
   */
  getProjectSection(id) {
    const defer = $.Deferred();
    const hasId = id !== undefined && id !== '' && id !== null;

    if (hasId) {
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: `${APIURL}/pipeline/${id}`,
        xhrFields: {
          withCredentials: true,
        },
      }).done((response) => {
        const { probability } = response.data.project;
        const section = probability < 90 ? 'opportunities' : 'projects';
        defer.resolve(section);
      });
    } else {
      defer.resolve(null);
    }

    return defer.promise();
  },
};

module.exports = TranslatorService;
