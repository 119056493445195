const React = require('react');
const DayPicker = require('../../../../../../../common/react/DayCalendar.react');
const TimeInput = require('../../../../../../../common/react/TimeInput.react');

module.exports = class DateTimePicker extends React.Component {
  handleDayClick(day) {
    const date = moment(day)
      .set('hour', this.props.current.format('H'))
      .set('minute', this.props.current.format('m'));
    this.props.updateForm({ schedule: date });
  }

  handleTimeChange(time) {
    const date = moment(this.props.current)
      .set('hour', moment(time, 'HH:mm').format('H'))
      .set('minute', moment(time, 'HH:mm').format('m'));
    this.props.updateForm({ schedule: date });
  }

  render() {
    return (
      <div className="timeline-event-form-datetime-picker">
        <DayPicker
          startDate={this.props.current}
          onDayClick={this.handleDayClick.bind(this)} />
        <div className="timeline-event-form-time-picker">
          <span>
            At:
            {' '}
            <TimeInput
              value={this.props.current.format('HH:mm')}
              onChange={this.handleTimeChange.bind(this)} />
          </span>
        </div>
      </div>
    );
  }
};
