/* eslint-disable no-unused-vars */

const request = require('./Request');

const FiscalYear = {
  get() {
    const url = '/fiscal-year/starting-month';
    return request({
      method: 'get',
      url,
    });
  },
  set(monthStart) {
    return request({
      method: 'post',
      url: '/fiscal-year/starting-month',
      data: {
        fiscal_year_start: monthStart,
      },
    });
  },
};

module.exports = FiscalYear;
