const request = require('./Request');

const PipedriveIntegration = {
  /**
   * Check whether the pipedrive integration is enabled for the user's company
   * @returns {*}
   */
  isEnabled() {
    return request({
      method: 'get',
      url: '/pipedrive/integration-enabled',
    });
  },
  /**
   * Autocomplete on pipedrive deals.
   * The search keyword and the client id of the project are required to filter the list.
   * @param keyword
   * @param clientId
   * @returns {*}
   */
  getDeals(keyword, clientId) {
    return request({
      method: 'get',
      url: `/pipedrive/deals-by-client?query_string=${keyword}&client=${clientId}`,
    });
  },
};

module.exports = PipedriveIntegration;
