const { connect } = require('react-redux');
const Component = require('../../components/modals/ImportModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'import').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  import: (year, file) => dispatch(actions.import(year, file)),
  showCannotUploadFileModal: (error) => dispatch(actions.showUploadErrorModal(error)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
