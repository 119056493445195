const request = require('./Request');

const TravelAutocomplete = {
  getProjects(search) {
    const query = search ? `?hints=${search}` : '';

    return request({
      method: 'get',
      url: `/travel/autocomplete${query}`,
    });
  },
};

module.exports = TravelAutocomplete;
