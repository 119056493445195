/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions */
const React = require('react');
const Scroller = require('./Scroller.react');
const Window = require('./Window.react');
const Actions = require('./Actions.react');
const EmptyState = require('./EmptyState.react');

module.exports = class VirtualGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowIndex: this.props.firstRow | 0, // first visible row's index
      columnIndex: this.props.firstColumn | 0, // first visible column's index
      scrollerHeight:
        this.props.items.length * this.props.rowHeight
        + this.props.stickyElementsSize.top.height,
      // min-width > 0 is required to avoid transparent header when no users correspond to applied filters on first load
      scrollerWidth:
        this.props.items.length ? this.props.items[0].length * this.props.columnWidth
          + this.props.stickyElementsSize.left.width : 1,
    };

    this.visibleRowsLength = this.getContainedLength(
      this.props.height,
      this.props.rowHeight,
    );
    this.visibleColumnsLength = this.getContainedLength(
      this.props.width,
      this.props.columnWidth,
    );
  }

  scrollToColumn(index) {
    this.scrollerEl.scrollLeft = index * this.props.columnWidth;
  }

  scrollToRow(index) {
    this.scrollerEl.scrollTop = index * this.props.rowHeight;
  }

  componentDidMount() {
    this.scrollToColumn(this.state.columnIndex);
    this.scrollToRow(this.state.rowIndex);
  }

  componentDidUpdate(prevProps) {
    const changedRows = this.props.items.length !== prevProps.items.length;
    const changedColumns = this.props.items.length && prevProps.items.length
      ? this.props.items[0].length > prevProps.items[0].length : false;
    const changedWidth = this.props.width !== prevProps.width;
    const changedHeight = this.props.height !== prevProps.height;
    const changedFirstColumn = this.props.firstColumn !== prevProps.firstColumn;
    const changedFirstRow = this.props.firstRow !== prevProps.firstRow;
    const changedColumnWidth = this.props.columnWidth !== prevProps.columnWidth;
    if (changedFirstColumn) {
      const firstColumn = Math.max(0, this.props.firstColumn); // firstColumn must be > 0 (negative indexes don't exists)
      this.scrollToColumn(firstColumn);
    }
    if (changedFirstRow) {
      const firstRow = Math.max(0, this.props.firstRow); // firstRow must be > 0 (negative indexes don't exists)
      this.scrollToRow(firstRow);
    }
    // Rows and columns' length changes in response of a loading event
    if (changedRows) {
      this.setState({
        scrollerHeight: this.props.items.length * this.props.rowHeight,
      });
      this.props.hideLoader();
    } else if (changedColumns) {
      this.setState(
        {
          scrollerWidth: this.props.items[0].length * this.props.columnWidth,
        },
        () => {
          // If new columns are added at the beginning, scroll must be synced after adding
          if (prevProps.loader === 'left') {
            const addedLength = this.props.items[0].length - prevProps.items[0].length;
            this.scrollToColumn(addedLength);
          }
          this.props.hideLoader();
        },
      );
    }
    if (changedHeight || changedWidth) {
      this.visibleRowsLength = this.getContainedLength(
        this.props.height,
        this.props.rowHeight,
      );
      this.visibleColumnsLength = this.getContainedLength(
        this.props.width,
        this.props.columnWidth,
      );
    }
    if (changedColumnWidth) {
      this.setState({ scrollerWidth: this.props.items[0].length * this.props.columnWidth });
      this.visibleColumnsLength = this.getContainedLength(
        this.props.width,
        this.props.columnWidth,
      );
    }
  }

  /**
   * Returns how many contentSize can be contained in a containerSize.
   * @param {int} containerSize
   * @param {int} contentSize
   */
  getContainedLength(containerSize, contentSize) {
    return Math.ceil(containerSize / contentSize);
  }

  /**
   * Return the piece of this.props.items to render.
   * Issue: If row or column index is > than rows or columns length, an empty array is returned. This led to an error in client.
   * Solution: Row and column indexes are capped accordingly to rows or columns length, so the minimum window size is 1x1
   * @returns {*}
   */
  getVisibleItems() {
    if (!this.props.items.length) {
      return [];
    }
    const fromRow = Math.min(this.state.rowIndex, this.props.items.length - 1);
    const toRow = fromRow + this.visibleRowsLength;
    const fromColumn = Math.min(this.state.columnIndex, this.props.items[0].length - 1);
    const toColumn = fromColumn + this.visibleColumnsLength;
    const items = this.props.items.slice(fromRow, toRow); // get visible rows

    return items.map((row) => row.slice(fromColumn, toColumn)); // get visible columns for each row
  }

  /**
   * Return header's columns to render.
   * @returns {*}
   */
  getHeaderVisibleColumns() {
    const items = this.getVisibleItems();
    return items.length ? items[0] : [];
  }

  /**
   * Return left column's rows to render.
   * @returns {*}
   */
  getLeftColumnVisibleRows() {
    return this.getVisibleItems();
  }

  /**
   * Returns which limit (left, top, right, bottom) has been reached by rowIndex or columnIndex, if any.
   * This function is useful only if a this.props.loadMore function is present.
   * A limit has been reached if user cannot scroll more on that direction.
   * @param rowIndex index of the first rendered row
   * @param columnIndex index of the first rendered column
   * @returns {string|null}
   */
  getReachedScrollLimit(rowIndex, columnIndex) {
    if (!this.props.loadMore) {
      return null;
    }
    const totalRowsLength = this.getContainedLength(
      this.state.scrollerHeight - this.props.stickyElementsSize.top.height,
      this.props.rowHeight,
    );
    const totalColumnsLength = this.getContainedLength(
      this.state.scrollerWidth - this.props.stickyElementsSize.left.width,
      this.props.columnWidth,
    );
    const lastRowIndex = totalRowsLength - 1; // index of the last row for the entire set
    const lastColumnIndex = totalColumnsLength - 1; // index of the last column for the entire set
    const lastVisibleRowIndex = rowIndex + this.visibleRowsLength - 1; // index of the last rendered row
    const lastVisibleColumnIndex = columnIndex + this.visibleColumnsLength - 1; // index of the last rendered column

    if (this.state.rowIndex !== rowIndex) {
      if (lastVisibleRowIndex >= lastRowIndex) {
        return 'bottom';
      }
      if (rowIndex === 0) {
        return 'top';
      }
    }
    if (this.state.columnIndex !== columnIndex) {
      if (lastVisibleColumnIndex >= lastColumnIndex) {
        return 'right';
      }
      if (columnIndex === 0) {
        return 'left';
      }
    }
    return null;
  }

  onScroll(e) {
    e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    // Math.max is needed because bouncing scroll in Safari can led to negative scroll
    const scrollTop = Math.max(0, e.target.scrollTop);
    const scrollLeft = Math.max(0, e.target.scrollLeft);

    // First visible indexes, based on actual scroll
    const firstVisibleRowIndex = Math.floor(scrollTop / this.props.rowHeight);
    const firstVisibleColumnIndex = Math.floor(
      scrollLeft / this.props.columnWidth,
    );

    const reachedScrollLimit = this.getReachedScrollLimit(
      firstVisibleRowIndex,
      firstVisibleColumnIndex,
    );
    if (reachedScrollLimit) {
      this.props.loadMore(reachedScrollLimit);
    }

    // Row and column index are updated after every scroll
    this.setState({
      rowIndex: firstVisibleRowIndex,
      columnIndex: firstVisibleColumnIndex,
    }, () => {
      this.props.onScroll ? this.props.onScroll(e) : null;
    });
  }

  getStyle() {
    return {
      height: `${this.props.height}px`,
      width: `${this.props.width}px`,
    };
  }

  /**
   * Get sticky elements to render, if any.
   * @param windowTop
   * @param windowLeft
   * @returns {null|*}
   */
  getStickyElements(windowTop, windowLeft) {
    if (this.props.stickyElements.length) {
      return this.props.stickyElements.map((element) => {
        if (element.position === 'top') {
          return element.component({
            left: windowLeft,
            items: this.getHeaderVisibleColumns(),
          });
        }
        if (element.position === 'left') {
          return element.component({
            top: windowTop,
            items: this.getLeftColumnVisibleRows(),
          });
        }
        return element.position;
      });
    }
    return null;
  }

  /**
   * Return loader to render, if any.
   * @returns {{style: null, position}|null}
   */
  getLoader() {
    if (!this.props.loader) {
      return null;
    }
    return {
      position: this.props.loader,
      style: null,
    };
  }

  getContent() {
    const windowTop = this.state.rowIndex * this.props.rowHeight;
    const windowLeft = this.state.columnIndex * this.props.columnWidth;
    const actionsWidth = this.props.stickyElementsSize.left.width;
    const actionsHeight = this.props.stickyElementsSize.top.height;
    const contentTop = windowTop + actionsHeight;
    const contentLeft = windowLeft + actionsWidth;

    const items = this.getVisibleItems();

    if (!items.length) {
      return <EmptyState />;
    }
    return [
      <Actions key="actions" width={actionsWidth} height={actionsHeight}>
        {this.props.actions}
      </Actions>,
      <Scroller
        key="scroller"
        height={this.state.scrollerHeight}
        width={this.state.scrollerWidth}>
        {this.getStickyElements(windowTop, windowLeft)}
        <Window
          left={contentLeft}
          top={contentTop}
          loader={this.getLoader()}>
          {this.props.rowsRenderer(items)}
        </Window>
      </Scroller>,
    ];
  }

  render() {
    if (!this.props.width || !this.props.height) {
      return null;
    }

    return (
      <div
        className="virtual-grid"
        style={this.getStyle()}
        onScroll={this.onScroll.bind(this)}
        ref={(scrollerEl) => (this.scrollerEl = scrollerEl)}>
        {this.getContent()}
      </div>
    );
  }
};
