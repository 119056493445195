/* eslint-disable react/no-access-state-in-setstate,react/no-array-index-key,react/jsx-no-bind,consistent-return,react/sort-comp */
const React = require('react');
const TextInput = require('../../../../../../../common/react/material-input/SidebarTextInput.react');
const TextArea = require('../../../../../../../common/react/material-input/SidebarTextArea.react');
const DateRange = require('../../../../../../../common/react/material-input/SidebarDateRangeDoubleSelection.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const DestinationSelect = require('../../../containers/sidebar/FavoriteCitySelect');
const PeopleAmount = require('../PeopleAmountSelect.react');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');
const ReadOnlyDateRange = require('../ReadOnlyDateRangeField.react');
const FieldFactory = require('./FieldFactory.react');

module.exports = class AccommodationEditableDetails extends React.Component {
  handlePersonUpdate(type, index, field, value) {
    let list = this.props.travel[type];
    list = list ? [...list] : [];
    const oldPerson = list[index];
    const updatedPerson = {
      ...oldPerson,
      [field]: value,
    };
    list.splice(index, 1, updatedPerson); // update the person in the given index

    this.props.onChange(type, list);
  }

  getEmployeesInfo() {
    if (this.props.travel.employees) {
      return this.props.travel.employees.map((employee, index) => (
        <div key={index}>
          <TextInput name="name"
            placeholder="Name"
            value={employee.name}
            onChange={(name, value) => this.handlePersonUpdate('employees', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span className="wethod-icon wethod-icon-pm" />
          </TextInput>
          <TextInput name="surname"
            placeholder="Surname"
            value={employee.surname}
            onChange={(name, value) => this.handlePersonUpdate('employees', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span />
          </TextInput>
        </div>
      ));
    }
  }

  getGuestsInfo() {
    if (this.props.travel.guests) {
      return this.props.travel.guests.map((guest, index) => (
        <div key={index}>
          <TextInput name="name"
            placeholder="Name"
            value={guest.name}
            onChange={(name, value) => this.handlePersonUpdate('guests', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span className="wethod-icon wethod-icon-external wethod-icon-external--black" />
          </TextInput>
          <TextInput name="surname"
            placeholder="Surname"
            value={guest.surname}
            onChange={(name, value) => this.handlePersonUpdate('guests', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span />
          </TextInput>
        </div>
      ));
    }
  }

  /**
   * Get the updated list of people of the given type.
   * Add or remove people from the previous list based on the amount of people currently needed.
   * @param type
   * @param tot
   * @returns {T[]}
   */
  getUpdatedPeopleList(type, tot) {
    const prevList = this.props.travel[type] || [];
    const updatedPeople = prevList.slice();
    const diff = tot - prevList.length;
    if (diff) {
      if (diff > 0) { // Add empty people to the list
        for (let i = 0; i < diff; i++) {
          updatedPeople.push({});
        }
      } else if (diff < 0) { // Remove people from the list
        updatedPeople.splice(diff);
      }
    }
    return updatedPeople;
  }

  handlePeopleChange(name, tot) {
    const changes = {};
    changes.employees = this.getUpdatedPeopleList('employees', tot.employees);
    changes.guests = this.getUpdatedPeopleList('guests', tot.guests);

    this.props.onChange(name, changes);
  }

  getPeopleLabel() {
    const total = this.props.travel.employees.length + this.props.travel.guests.length;
    return total === 1 ? '1 person' : `${total} people`;
  }

  render() {
    return (
      <div>
        <DestinationSelect name="to"
          placeholder="Location"
          destination={this.props.travel.to}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </DestinationSelect>
        <div className="material-input">
          <PeopleAmount label={this.getPeopleLabel()}
            employees={this.props.travel.employees.length}
            guests={this.props.travel.guests.length}
            onChange={this.handlePeopleChange.bind(this)} />
        </div>
        <FieldFactory name="date"
          metadataService={this.props.metadataService}
          readOnlyComponent={(
            <ReadOnlyDateRange date={this.props.travel.date}
              returnDate={this.props.travel.return_date} />
)}>
          <DateRange name="range_date"
            placeholder={{
              start: 'Check in',
              end: 'Check out',
            }}
            value={{
              start_date: this.props.travel.date,
              end_date: this.props.travel.return_date,
            }}
            onChange={this.props.onChange}
            required>
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          </DateRange>
        </FieldFactory>
        {this.getEmployeesInfo()}
        {this.getGuestsInfo()}
        <TextArea name="additional_requests"
          placeholder="Additional requests"
          value={this.props.travel.additional_requests}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </TextArea>
        <TravelOfficeSection travel={this.props.travel}
          metadataService={this.props.metadataService}
          editMode
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <FileSection editMode carrier={this.props.travel} />
      </div>
    );
  }
};
