const React = require('react');

/**
 * Injects methods useful to deal with an item.
 * @param children
 * @param item
 * @param rest
 * @return {React.SFCElement<unknown>}
 * @constructor
 */
const ItemManager = ({ children, item, ...rest }) => {
  const getSortedDays = () => item.days.sort((dayA, dayB) => new Date(dayA) - new Date(dayB));

  const getStartDate = () => getSortedDays()[0].date;

  const getEndDate = () => {
    const days = getSortedDays();
    const lastIndex = days.length - 1;

    return days[lastIndex].date;
  };

  const getTotalHours = () => item.days.reduce((sum, day) => sum + day.hours, 0);

  const getStatusLabel = () => {
    const labels = {
      pending: 'Pending',
      conflict: 'Allocation clash',
      approved: 'Approved',
      rejected: 'Rejected',
    };

    return labels[item.status];
  };

  return React
    .cloneElement(children, {
      getStartDate,
      getEndDate,
      getTotalHours,
      getStatusLabel,
      item,
      ...rest,
    });
};

module.exports = ItemManager;
