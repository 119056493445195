const React = require('react');

/**
 * Used to create a horizontal section inside an HeaderBox, containing important info.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderBoxPrimaryInfo = ({ children }) => (
  <div className="wethod-budget-header-box__primary-info">{children}</div>
);

module.exports = HeaderBoxPrimaryInfo;
