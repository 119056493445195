const WonLostFeedback = {
  getAll() {
    return Wethod.request('get:company:wonLostFeedback');
  },
  create(attributes) {
    return Wethod.request('create:company:wonLostFeedback', attributes);
  },
  update(id, attributes) {
    const model = new Wethod.SettingsApp.Company.WonLostFeedbackModel({ id, ...attributes });

    return Wethod.request('update:company:wonLostFeedback', model);
  },
  delete(id) {
    const model = new Wethod.SettingsApp.Company.WonLostFeedbackModel({ id });

    return Wethod.request('delete:company:wonLostFeedback', model);
  },
  getUsage(id) {
    return Wethod.request('get:company:usage:wonLostFeedback', id);
  },
};

module.exports = WonLostFeedback;
