/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const TableCell = require('../../../../../../../../../common/react/Table2/TableCell.react');
const Menu = require('../../../../../../../../../common/react/Menu/Menu.react');
const MemberMenu = require('./MemberMenu/MemberMenu.react');
const AvatarBox = require('../../../../../../../../../common/react/AvatarBox/AvatarBox.react');

module.exports = class MemberItemCellView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      addedPeople: this.props.value || null,
    };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  getAvatars() {
    return this.state.addedPeople.map((avatar) => ({ ...avatar, name: `${avatar.name} ${avatar.surname}` }));
  }

  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <button type="button"
          ref={(el) => this.moreButtonEl = el}
          className="project-canvas-spreadsheet__cell-button-content"
          onClick={this.onMenuButtonClick.bind(this)}>
          <AvatarBox avatars={this.getAvatars()} />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MemberMenu
            canEdit={false}
            addedPeople={this.state.addedPeople} />
        </Menu>
      </TableCell>
    );
  }
};
