const React = require('react');
const ReadOnly = require('../../../../../../common/react/material-input/SidebarReadOnlyField.react');
const { formatDate } = require('../../../../../../services/FormatService');

const ReadOnlyDateRangeField = ({ date, returnDate, isRoundTrip = false }) => {
  if (isRoundTrip) {
    return (
      <div className="travel__sidebar-multiple-input-row">
        <div className="travel__sidebar-multiple-input-element">
          <ReadOnly placeholder="Departure" value={formatDate(date)}>
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          </ReadOnly>
        </div>
        <div className="travel__sidebar-multiple-input-element">
          <ReadOnly placeholder="Return" value={formatDate(returnDate)} />
        </div>
      </div>
    );
  }
  return (
    <ReadOnly placeholder="Departure" value={formatDate(date)}>
      <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
    </ReadOnly>
  );
};

module.exports = ReadOnlyDateRangeField;
