'use strict';

Wethod.module('PlanningApp.Planning', function (Planning, Wethod, Backbone, Marionette, $) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#planningTemplate',
    regions: {
      headerSection: '[data-region="planningSectionHeader"]',
      head: '[data-region="planningHead"]',
      body: '[data-region="planningBoby"]',
      modal: '[data-region="appDialog"]',
    },
    ui: {
      exportEl: '[data-action="export"]',
    },
    events: {
      'click @ui.exportEl': 'exportModule',
    },
    exportModule: function (e) {
      e.preventDefault();
      Planning.showModalExport();
    },
  });

  this.ProjectsStructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#planningProjectsTemplate',
    regions: {
      headerSection: '[data-region="planningSectionHeader"]',
      head: '[data-region="planningHead"]',
      body: '[data-region="planningBoby"]',
      modal: '[data-region="appDialog"]',
    },
  });

  // LOADING MORE DATA
  this.LoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });

  // EXPORT
  var modalW = 350;
  var modalH = 200;
  this.ModalExportItemView = Marionette.ItemView.extend({
    template: '#exportModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({ left: posLeft, top: posTop }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      Planning.doModuleExport(this);
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
