const React = require('react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const YearNavigator = require('../../../../../common/react/YearNavigator/YearNavigator.react');

const Actions = ({
  limit,
  keyword,
  year,
  getItems,
}) => {
  function onYearChange(date) {
    getItems(0, limit, keyword, date);
  }

  return (
    <div className="wethod-section-actions">
      <SectionHeader
        current_section="Time-off Manager"
        helper_url="reports/index/#time-off-manager"
        big />
      <div className="time-off__actions">
        <YearNavigator changeDate={onYearChange} year={year} className="time-off__year" />
      </div>
    </div>
  );
};

module.exports = Actions;
