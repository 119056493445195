const { connect } = require('react-redux');
const Component = require('../components/SuppliersList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  keyword: state.keyword,
  statusEnabled: state.statusEnabled,
  suppliers: state.suppliers,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  hasMore: state.hasMorePages,
  sortBy: state.sortBy,
  isWaiting: state.waitingFor.filter((key) => key === 'get-suppliers').length > 0,
  bu: state.buFilter,
  showRating: state.canSeeRating,
});

const mapDispatchToProps = (dispatch) => ({
  getSuppliers: (offset, limit, search, orderBy, sort, bu) => dispatch(actions
    .getSuppliers(offset, limit, search, orderBy, sort, bu)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
