/* eslint-disable no-underscore-dangle */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');
const TravelModel = require('./TravelModel');

module.exports = Backbone.Collection.extend({
  model: TravelModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  _offset: 0,
  _limit: 50,
  _search: null,
  _requestPool: [],
  url() {
    let query = `?offset=${this._offset}&limit=${this._limit}`;
    query = this._search ? `${query}&search=${this._search}` : query;
    this._offset += this._limit;
    return `${APIURL}/travel/${query}`;
  },
  loadMoreTravels(offset, search, limit) {
    if (search) {
      this._search = search.replace(/ /g, ','); // white space to comma
    }

    if (offset) {
      this._offset = offset;
    }

    if (limit) {
      this._limit = limit;
    }

    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
});
