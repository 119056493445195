/* eslint-disable react/sort-comp,consistent-return,react/no-access-state-in-setstate,jsx-a11y/control-has-associated-label,react/no-array-index-key */
const React = require('react');
const EnablerButton = require('../containers/AlertEnablerButton');
const AlertChunk = require('../containers/AlertChunk');
const PrioritySelector = require('../containers/PrioritySelector');

module.exports = class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: false,
      panelHeight: 200,
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.handleTogglePanelTransitionEnd = this.handleTogglePanelTransitionEnd.bind(this);
    this.getChunks = this.getChunks.bind(this);
    this.hasParams = this.hasParams.bind(this);
    this.getParamIcon = this.getParamIcon.bind(this);
  }

  /**
   * Save the real height of this alert's panel into the state.
   * This is useful to set the right toggle animation's timing.
   */
  handleTogglePanelTransitionEnd() {
    if (this.state.showPanel) {
      this.setState({ panelHeight: this.panel.offsetHeight });
    }
  }

  /**
   * Adds listener for the "transitionend" event to the panel.
   * transitionend is triggered once a transition ends.
   */
  addTogglePanelTransitionEndListener() {
    this.panel.addEventListener('transitionend', this.handleTogglePanelTransitionEnd);
    this.panel.addEventListener('webkitTransitionEnd', this.handleTogglePanelTransitionEnd);
    this.panel.addEventListener('oTransitionEnd', this.handleTogglePanelTransitionEnd);
    this.panel.addEventListener('MSTransitionEnd', this.handleTogglePanelTransitionEnd);
  }

  componentDidMount() {
    this.addTogglePanelTransitionEndListener();
  }

  togglePanel() {
    this.setState({ showPanel: !this.state.showPanel });
  }

  getPanelStyle() {
    if (this.state.showPanel) {
      return {
        maxHeight: `${this.state.panelHeight}px`,
      };
    }
  }

  getAccordionClassName() {
    let name = 'alerts-engine__accordion';
    if (!this.props.enabled) {
      name += '--disabled';
    }
    return name;
  }

  /**
   * Returns true if the description contains at least one parameter.
   * @returns {boolean}
   */
  hasParams() {
    return Boolean(this.props.description_chunks.find((chunk) => chunk.param));
  }

  getParamIcon() {
    return this.hasParams() ? <button type="button" className="alerts-engine__panel-gear" /> : null;
  }

  /**
   * Returns a Fragment representing alert's description.
   * An alert description is composed by description chunks.
   */
  getChunks() {
    return this.props.description_chunks.map((chunk, index) => (
      <AlertChunk
        key={index}
        enabled={this.props.enabled}
        alertId={this.props.id}
        {...chunk} />
    ));
  }

  /**
   * Returns the link to the alert's support page, based on the alert's name.
   * @returns {string}
   */
  getAlertSupportLink() {
    return Wethod.getAlertSupportLink(this.props.label);
  }

  render() {
    return (
      <div className="alerts-engine__alert">
        <div className={this.getAccordionClassName()}>
          <div className="alerts-engine__accordion-button" onClick={this.togglePanel}>
            <PrioritySelector
              alertId={this.props.id}
              selectedPriority={this.props.priority} />
            <div className="alerts-engine__alert-name">{this.props.label}</div>
            {this.getParamIcon()}
          </div>
          <div
            className="alerts-engine__accordion-panel"
            style={this.getPanelStyle()}
            ref={(panel) => {
              this.panel = panel;
            }}>
            <div className="alerts-engine__alert-message">
              {this.getChunks()}
            </div>
            <a href={this.getAlertSupportLink()}
              target="_blank"
              rel="noreferrer"
              className="alerts-engine__alert-info">
              Learn
              more
            </a>
          </div>
        </div>
        <div className="alerts-engine__enabled-button">
          <EnablerButton id={this.props.id} enabled={this.props.enabled} />
        </div>
      </div>
    );
  }
};
