const constants = require('./constants');
const BusinessUnitService = require('../../../../../services/BusinessUnitService');

class ProductionPlanReducer {
  constructor(state) {
    const start = moment().subtract(2, 'months').date(1);
    this.businessUnitService = new BusinessUnitService();
    this.state = {
      ...state,
      modalToShow: null,
      waitingFor: [],
      projects: state.projects || [],
      keyword: state.keyword ? state.keyword.replace('=', ':') : '',
      updatingVisibleMonths: false,
      visibleMonths: ProductionPlanReducer.getMonths(start, (start.clone()).add(11, 'months')),
      typeFilter: 'mine',
      mismatchFilter: 'all',
      visibleActionsProjectId: null, // the id of the project where to show the actions dropdown
      buEnabled: this.businessUnitService.isEnabled(),
      buFilter: this.businessUnitService.getLastSelected(),
      size: 0,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Return an array of month between start and end, formatted as 'YYYY-MM-DD'.
   *
   * @param start Moment
   * @param end Moment
   * @returns {Array}
   */
  static getMonths(start, end) {
    const length = end.diff(start, 'months') + 1;
    const months = [];
    for (let i = 0; i < length; i++) {
      const month = start.clone();
      months.push(month.add(i, 'months').format('YYYY-MM-DD'));
    }
    return months;
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return {
          ...state,
          modalToShow: null,
        };
      case constants.HIDE_PROJECT_ACTIONS_DROPDOWN:
        return {
          ...state,
          visibleActionsProjectId: null,
        };
      case constants.SHOW_PROJECT_ACTIONS_DROPDOWN:
        return {
          ...state,
          visibleActionsProjectId: action.projectId,
        };
      case constants.SHOW_MODAL_CANNOT_SAVE:
        return {
          ...state,
          modalToShow: 'cannot-save',
        };
      case constants.SHOW_MODAL_EXPORT:
        return {
          ...state,
          modalToShow: 'export',
        };
      case constants.EXPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('export'),
        };
      case constants.EXPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'export'),
        };
      case constants.SHOW_MODAL_CANNOT_AUTO_DISTRIBUTE_PAST:
        return {
          ...state,
          modalToShow: 'cannot-auto-distribute-past',
        };
      case constants.GET_PROJECTS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('load-projects'),
        };
      case constants.GET_PROJECTS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'load-projects'),
          projects: state.projects.concat(action.items),
          lastPage: action.items.length < action.size,
          size: action.size,
        };
      case constants.FILTER_PROJECTS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('filter-projects'),
          keyword: action.keyword,
          typeFilter: action.filterType,
          buFilter: action.bu,
          projects: [],
        };
      case constants.FILTER_PROJECTS_SUCCESS:
        return {
          ...state,
          waitingFor: this.state.waitingFor.filter((key) => key !== 'filter-projects'),
          projects: action.items,
          lastPage: action.items.length < action.size,
          size: action.size,
        };
      case constants.UPDATE_VISIBLE_MONTHS_REQUEST:
        return {
          ...state,
          updatingVisibleMonths: true,
        };
      case constants.UPDATE_VISIBLE_MONTHS_SUCCESS:
        return {
          ...state,
          updatingVisibleMonths: false,
          visibleMonths: action.months,
        };
      case constants.UPDATE_PROJECT_DURATION:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === action.projectId) {
              return {
                ...project,
                date_start: action.start,
                date_end: action.end,
              };
            }
            return project;
          }),
        };
      case constants.PROJECT_SAVE_REQUEST:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === action.project.id) {
              return {
                ...project,
                isSaving: true,
              };
            }
            return project;
          }),
        };
      case constants.PROJECT_SAVE_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === action.project.id) {
              return {
                ...project,
                months: action.project.months,
                isSaving: false,
              };
            }
            return project;
          }),
        };
      default:
        return state;
    }
  }
}

module.exports = ProductionPlanReducer;
