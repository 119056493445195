/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const PercentageValue = require('../../../../common/components/PercentageValue.react');

module.exports = class WonProjectsAgainstLastYear extends React.Component {
  render() {
    const ratio = this.props.prev_year_delta_won_perc;
    if (ratio === null) {
      return null;
    } if (ratio === 0) {
      return (
        <span>, and is the same as it was during the same week last year</span>
      );
    }
    return (
      <span>
        , and is <PercentageValue value={Math.abs(ratio)} /> {ratio > 0 ? 'more' : 'less'}
        {' '}
        than the same week last year
      </span>
    );
  }
};
