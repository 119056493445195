'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.AbsoluteView = Marionette.LayoutView.extend({
    /**
     *  _dependencies : the rule's dependencies.
     */
    _availableAbsoluteConditions: null,
    _absoluteRegionView: null,
    className: 'absolute-wrapper col_4',
    template: '#absoluteConditionTemplate',
    regions: {
      absoluteRegion: '[data-region="absoluteRegion"]',
    },

    initialize: function () {
      this._availableAbsoluteConditions = this.model.get('dependencies');
    },
    onRender: function () {
      this.addAbsolute(this._availableAbsoluteConditions[0]);
    },
    addAbsolute: function (optionModel) {
      var availableAbsolutes = new RuleManager
        .TooltipOptionCollection(this._availableAbsoluteConditions);
      var absolute = new RuleManager.SelectView({ collection: availableAbsolutes });
      this.absoluteRegion.show(absolute);
      this._absoluteRegionView = this.absoluteRegion.currentView;

      this._absoluteRegionView.setChosenOption(optionModel);
      this._absoluteRegionView.on('tooltip:item:selected', this.addAbsolute, this);

      var currentSelection = optionModel.name;
      this.updateModel('value', currentSelection);
    },
    /**
     * Updated the attributeName's attribute of this view AbsoluteModel with the newValue.
     *
     * @param attributeName
     * @param newValue
     */
    updateModel: function (attributeName, newValue) {
      this.model.set(attributeName, newValue);
    },
    /**
     * Build operands and operator for a TerminalView
     * @param condition
     */
    build: function (condition) {
      this.addAbsolute(condition.get('value'));
      var dependenciesColl = new RuleManager
        .RuleDependecyCollection(this._availableAbsoluteConditions);
      var value = dependenciesColl.findWhere({ name: condition.get('value') });
      this.addAbsolute(value.attributes);
    },
  });
});
