const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../modal2/Modal.react');
const Button = require('../Button/RoundedButton.react');

/**
 * Basic error modal:
 * it shows a given text or element as body, and the 'ok' action to close the modal.
 * The default 'Error' title is also customizable.
 *
 * @param title
 * @param data
 * @param className
 * @param onCancelClick
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorModal = ({ title, data, className, onCancelClick }) => {
  const style = className ? `wethod-modal2--medium ${className}` : 'wethod-modal2--medium';

  return (
    <Modal title={title} onCancelClick={onCancelClick} className={style}>
      <div>{data}</div><br />
      <div className="wethod-modal2__actions">
        <Button onClick={onCancelClick}>Ok</Button>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  /**
   * Function called to close the modal: both for 'Close' button and 'X' button
   * @see Modal
   */
  onCancelClick: PropTypes.func.isRequired,
  /**
   * Error shown in the modal.
   * It can be the error message or a more complex body
   */
  data: PropTypes.node,
  /**
   * Class given to the modal.
   */
  className: PropTypes.string,
  /**
   * Title of the modal
   */
  title: PropTypes.string,
};

ErrorModal.defaultProps = {
  title: 'Error',
  data: 'Something went wrong. Please try again.',
  className: null,
};

module.exports = ErrorModal;
