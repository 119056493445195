const React = require('react');
const modals = require('../modals');
const DataReviewModal = require('../containers/modals/DataReviewModal');
const EditPeriodModal = require('../containers/modals/EditPeriodModal');
const EditStatusModal = require('../containers/modals/EditStatusModal');
const IgnoreAlertsModal = require('../containers/modals/IgnoreAlertsModal');
const ConfirmPauseModal = require('../containers/modals/ConfirmPauseModal');
const ConfirmFreezeModal = require('../containers/modals/ConfirmFreezeModal');

const availableModals = {
  [modals.START_REVIEW]: DataReviewModal,
  [modals.EDIT_STATUS]: EditStatusModal,
  [modals.IGNORE_ALERTS]: IgnoreAlertsModal,
  [modals.CONFIRM_PAUSE]: ConfirmPauseModal,
  [modals.CONFIRM_FREEZE]: ConfirmFreezeModal,
  [modals.EDIT_PERIOD]: EditPeriodModal,
};

module.exports = ({
  modalKey,
  closeModal,
  modalData = {},
}) => {
  const Component = availableModals[modalKey];
  if (Component) {
    return <Component closeModal={closeModal} {...modalData} />;
  }

  return null;
};
