const React = require('react');
const Select = require('./StyledSelect.react');

const PatternSelect = ({ zoom, value, onChange }) => {
  const items = [
    {
      value: 'daily',
      label: 'Every day',
    }, {
      value: 'weekly',
      label: 'Every week',
    }, {
      value: 'monthly',
      label: 'Every month',
    }, {
      value: 'no-repeat',
      label: "Don't repeat",
    }];

  const allowedItems = zoom === 'week' ? items.filter((item) => item.value !== 'daily') : items;

  return (
    <div className="planning-people-repeat-pattern">
      <Select items={allowedItems} onChange={onChange} value={value} placeholder="Pattern" name="pattern" />
    </div>
  );
};

module.exports = PatternSelect;
