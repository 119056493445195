const { connect } = require('react-redux');
const Component = require('../../../components/PersonalApiToken/TokenAddModal/TokenAddModalContent.react');
const AccountReducer = require('../../../reducer');
const constants = require('../../../constants');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: AccountReducer.waitingFor(state, constants.PERSONAL_TOKEN_ADD_REQUEST),
});

const mapDispatchToProps = (dispatch) => ({
  createToken: (name, description) => dispatch(actions.addPersonalToken(name, description)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
