const React = require('react');
const _ = require('underscore');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');

const NameCell = ({ name, isArchived, handleNameChange }) => (
  isArchived ? (
    <TableCell className="company-settings-job-title__table-cell-name">
      <div className="company-settings-job-title__table-cell-name--archived">
        <span>{_.unescape(name)}</span><br />
        <span>Archived</span>
      </div>
    </TableCell>
  )
    : (
      <TableCell className="company-settings-job-title__table-cell-name">
        <TextField name="name"
          label="Name"
          value={_.unescape(name)}
          onBlur={handleNameChange} />
      </TableCell>
    ));

module.exports = NameCell;
