const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const SingleValueSlider = require('../SingleValueSlider.react');
const FiscalYearService = require('../../../services/FiscalYearService');
/**
 * A year navigator component with slider to select the next/previous years.
 * Depending on the company fiscal year start will show one year with format YYYY
 * or 2 years with format YY/YY.
 */
class YearNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.fiscalYearService = new FiscalYearService();

    this.state = {
      year: parseInt(this.props.year),
    };
  }

  handlePreviousClick() {
    if (!this.props.disabled) {
      this.setState((prevState) => {
        const prev = prevState.year - 1;
        this.props.changeDate(prev);
        return { year: prev };
      });
    }
  }

  handleNextClick() {
    if (!this.props.disabled) {
      this.setState((prevState) => {
        const next = prevState.year + 1;
        this.props.changeDate(next);
        return { year: next };
      });
    }
  }

  getStyle() {
    let style = '';
    if (this.props.className) {
      style += this.props.className;
    }

    if (this.props.disabled) {
      style += ' disabled';
    }

    return style;
  }

  render() {
    return (
      <SingleValueSlider
        className={this.getStyle()}
        value={this.fiscalYearService.getYearDynamically(this.state.year)}
        onPrevClicked={this.handlePreviousClick.bind(this)}
        onNextClicked={this.handleNextClick.bind(this)} />
    );
  }
}

YearNavigator.defaultProps = {
  disabled: false,
  year: moment().format('YYYY'),
  className: '',
};

YearNavigator.propTypes = {
  disabled: PropTypes.bool,
  /**
   * The selected year shown. (format YYYY)
   */
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  /**
   * Function called when a year (different from the given one) is selected.
   * @param year (string with format YYYY)
   */
  changeDate: PropTypes.func.isRequired,
};

module.exports = YearNavigator;
