const { connect } = require('react-redux');
const PrioritySelectorComponent = require('../components/PrioritySelector.react');
const { editAlertPriority } = require('../actions');

const mapStateToProps = (state) => ({ availablePriorities: state.priorities });

const mapDispatchToProps = (dispatch) => ({
  updatePriority: (priority) => dispatch(editAlertPriority(priority)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(PrioritySelectorComponent);
