'use strict';

Wethod.module('FridayApp', function (FridayApp) {
  // Don't automatically start module on parent module startup but use global router
  this.startWithParent = false;

  var API = {
    showTimesheet: function (idTour, id, date) {
      if (Number.isNaN(id) || (id && id === Wethod.userInfo.get('employee_id'))) {
        id = null;
        var dateParam = date ? '/date=' + date : '';
        var tourParam = idTour ? '?product_tour_id=' + idTour : '';
        Wethod.navigate('timesheet' + dateParam + tourParam, { replace: true });
      }
      FridayApp.Controller.showTimesheet(id, date, idTour);
    },

    showProjectStatus: function (idTour, id) {
      FridayApp.Controller.showProjectStatus(idTour, id);
    },
  };

  // On module start run the selected API function
  FridayApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'friday');
    API[options.foo](options.idTour, options.id, options.date);
  });
});
