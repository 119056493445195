/* eslint-disable react/sort-comp */
const React = require('react');

module.exports = class NotesEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: 'Add notes...',
    };
  }

  needsPlaceholder() {
    return this.props.value === '';
  }

  handleBlur() {
    if (this.needsPlaceholder()) this.setState({ placeholder: 'Add notes...' });
  }

  handleFocus() {
    this.setState({ placeholder: '' });
  }

  render() {
    return (
      <textarea
        className="client-company__notes client-company__notes--editable"
        placeholder={this.state.placeholder}
        value={this.props.content}
        onChange={this.props.onChange}
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)} />
    );
  }
};
