/* eslint-disable class-methods-use-this */
const React = require('react');
const TrendsItem = require('../TrendsItem.react');
const { formatDate } = require('../../../../../../../../services/FormatService');
const Legend = require('./Legend.react');

module.exports = class TrendChargeability extends React.Component {
  componentDidMount() {
    this.props.getTrend(this.props.employee);
  }

  /**
   * Returns the series' trend. The string returned must match with one of the colors' key given in TrendsItem
   * component.
   *
   * @returns {string}
   */
  getSeriesTrendType() {
    const firstValue = this.props.data[0].y;
    const lastValue = this.props.data[this.props.data.length - 1].y;

    if (this.props.data.length > 0) {
      if (lastValue < firstValue) return 'negative';
    }
    return 'positive';
  }

  /**
   * Returns a formatted label for the given point.
   *
   * @param point
   * @param series
   * @param x
   * @param y
   * @returns {string}
   */
  getPointLabel(point, series, x, y) {
    if (point.index === series.data.length - 1) { // is last point
      const formattedValue = numeral(y).format('+0,0.[0]');
      return `${formattedValue}%`;
    }
    return '';
  }

  /**
   * Returns the type of the given value. The string returned must match with one of the colors' key given in TrendsItem
   * @param value
   * @returns {string}
   */
  getPointType(value) {
    const neutralLimit = this.props.levelAverage - this.props.levelAverage * 0.1; // avg - 10%
    if (value >= this.props.levelAverage) return 'positive';
    if (value >= neutralLimit) return 'neutral';
    return 'negative';
  }

  /**
   * Returns a formatted tooltip for the given point.
   *
   * @param x
   * @param y
   * @returns {string}
   */
  getTooltip(x, y) {
    const monday = formatDate(moment(x));
    const friday = formatDate(moment(x).add(4, 'days'));
    const formattedValue = numeral(y).format('+0,0.[0]');
    const value = `${formattedValue}%`;
    return `${monday} - ${friday} <br/> <b>${value}</b>`;
  }

  /**
   * Returns an additional plotLine used to highlight the level's average.
   *
   * @returns Object
   */
  getYPlotLines() {
    return {
      color: '#a8a8a8',
      dashStyle: 'LongDash',
      width: 1,
      value: this.props.levelAverage,
      label: {
        style: {
          color: '#66666',
          fontSize: '11px',
        },
        text: `${Math.round(this.props.levelAverage)}%`,
        x: -28,
        y: 3,
      },
      zIndex: 1,
    };
  }

  /**
   * Returns an invisible series to make plotLines always visible in the chart.
   * Highcharts tend to hide plotLines if they're too far from the series but, by adding a ghost point on the plotLine,
   * it will be always visible.
   *
   * Do you think this is kinda hack? You're right, boy!
   *
   * @returns {{enableMouseTracking: boolean, data: *[]}}
   */
  getFakeSeriesData() {
    const yPlotLines = this.getYPlotLines();
    return { // Fake series used to make the average plotLine always visible
      enableMouseTracking: false,
      data: [{
        name: 'fake average',
        x: this.props.data.length > 0 ? this.props.data[0].x : moment().valueOf(),
        y: yPlotLines.value,
        dataLabels: { enabled: false },
        marker: {
          enabled: false,
          states: {
            enabled: false,
            hover: {
              enabled: false,
            },
          },
        },
      }],
    };
  }

  render() {
    return (
      <TrendsItem
        {...this.props}
        className="profile-trends__item--chargeability"
        getSeriesTrendType={this.getSeriesTrendType.bind(this)}
        getPointLabel={this.getPointLabel}
        getPointType={this.getPointType.bind(this)}
        getTooltip={this.getTooltip.bind(this)}
        getYPlotLines={this.getYPlotLines.bind(this)}
        getFakeSeriesData={this.getFakeSeriesData.bind(this)}
        legend={<Legend label={this.props.levelLabel} />} />
    );
  }
};
