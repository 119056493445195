const React = require('react');
const Tooltip = require('../../../../../../../common/react/TooltipFixed/Tooltip.react');
const Label = require('./FrozenTooltipLabel.react');

const FrozenTooltip = ({ children }) => (
  <Tooltip label={<Label />}>
    {children}
  </Tooltip>
);

module.exports = FrozenTooltip;
