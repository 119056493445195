const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Client = {
  list(filters = {}) {
    let url = '/crm/client/';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
};

module.exports = Client;
