/* eslint-disable react/sort-comp,react/prop-types,react/no-access-state-in-setstate */
const React = require('react');
const Header = require('./Header.react');
const Menu = require('./Menu.react');

module.exports = class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      area: null,
      item: null,
      currentSection: null,
    };
  }

  componentDidMount() {
    this.updateCurrentSection($('.section-title__current').text());

    dispatcher.on('section-header:mounted', this.updateCurrentSection.bind(this));
  }

  updateCurrentSection(title) {
    this.setState({ currentSection: title });
  }

  getClassName() {
    let name = 'app-nav-mobile';
    if (this.state.showMenu) {
      name += ' app-nav-mobile--menu-open';
    }
    return name;
  }

  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
      item: null,
      area: null,
      currentSection: null,
    });
  }

  showArea(area) {
    this.setState({ area });
  }

  showItems() {
    this.showArea(null);
  }

  updateOpenItem(item) {
    this.setState({
      area: null,
      item,
    });
  }

  render() {
    return (
      <nav className={this.getClassName()}>
        <Header toggleMenu={this.toggleMenu.bind(this)}
          showItems={this.showItems.bind(this)}
          area={this.state.area}
          currentSection={this.state.currentSection}
          showMenu={this.state.showMenu} />
        <Menu close={this.toggleMenu.bind(this)}
          showArea={this.showArea.bind(this)}
          area={this.state.area}
          items={this.props.model.getMobileModules()}
          openItem={this.state.item}
          updateOpenItem={this.updateOpenItem.bind(this)} />
      </nav>
    );
  }
};
