const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  segments: state.segments,
});

const mapDispatchToProps = (dispatch) => ({
  openReviewModal: () => dispatch(actions.showReviewModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
