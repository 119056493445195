'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var moment = require('moment');
var TimesheetSettingsModel = require('../../../models/TimesheetSettings');
var TimesheetStore = require('../../../apps/core/modules/friday/timesheet/store');
var TimesheetWeeklyReducer = require('../../../apps/core/modules/friday/timesheet/weekly/reducer');
var TimesheetWeekly = require('../../../apps/core/modules/friday/timesheet/weekly/index');
var TimesheetDailyReducer = require('../../../apps/core/modules/friday/timesheet/detailed/reducer');
var TimesheetDaily = require('../../../apps/core/modules/friday/timesheet/detailed/index');
var EmployeeWorkHourCapacity = require('../../../models/EmployeeWorkHourCapacity');
var FeatureService = require('../../../services/FeatureService');
var DateService = require('../../../services/DateService');

Wethod.module('FridayApp.Timesheet', function (Timesheet, Wethod, Backbone, Marionette, $) {
  this.Init = function (layout, id, date, idTour) {
    var strategyRequest = Wethod.request('get:company:timesheet:detailed');
    var calendarPreferenceRequest = Wethod.request('get:company:planning:calendar');
    var employeeId = id || Wethod.userInfo.attributes.employee_id;
    var day = date || moment().format('YYYY-MM-DD');
    var employeeCapacityRequest = Wethod.featureService
      .isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)
      ? EmployeeWorkHourCapacity.get(employeeId) : null;
    $.when(strategyRequest, calendarPreferenceRequest, employeeCapacityRequest,
      TimesheetSettingsModel.getDailyModeToggle(),
      DateService.getHolidayForWeekRequest(day, employeeId))
      .done(function (strategyResponse, calendarPreferenceResponse, employeeCapacity,
        dailyToggleResponse, employeeHolidays) {
        var strategy = strategyResponse.get('strategy');
        var internationalCalendar = calendarPreferenceResponse.get('enabled');
        var dailyModeToggle = dailyToggleResponse.daily_mode;

        var element = null;
        var reducerWrapper = null;
        var store = null;
        var container = null;
        if (strategy === 'daily') {
          var timesheetCollDetailedRequest = Wethod.request('get:friday:timesheet:detailed:coll', day, id);

          $.when(timesheetCollDetailedRequest).done(function (timesheetModel) {
            layout.render();

            var reducerParams = {
              date: day,
              projects: timesheetModel.get('projects'),
              // Timesheet owner, can differ from current logged employee
              employee: timesheetModel.get('employee'),
              // ID of employee to show, if different from current employee
              userId: id,
              internationalCalendar: internationalCalendar,
              totalWeekHours: timesheetModel.get('total_week_hours'),
              employeeWorkHourCapacity: employeeCapacity,
              // Employee holidays for the week
              employeeHolidays: Wethod.featureService
                .isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK) ? employeeHolidays : [],
            };

            reducerWrapper = new TimesheetDailyReducer(reducerParams);
            store = TimesheetStore(reducerWrapper.reduxReducer);

            element = React.createElement(TimesheetDaily, { store: store });

            container = document.getElementById('bodyRoot');
            if (container !== null) ReactDOM.render(element, container);
          });
        } else {
          var timesheetCollRequest = Wethod.request('get:friday:timesheet:coll', day, id);
          $.when(timesheetCollRequest).done(function (timesheetCollResponse) {
            if (!timesheetCollResponse.get('employee')) {
              Wethod.trigger('url:404:show');
              return;
            }
            layout.render();

            var reducerParams = {
              date: day,
              projects: timesheetCollResponse.get('projects'),
              // Timesheet owner, can differ from current logged employee
              employee: timesheetCollResponse.get('employee'),
              internationalCalendar: internationalCalendar,
              // ID of employee to show, if different from current employee
              userId: id,
              idTour: idTour,
              employeeWorkHourCapacity: employeeCapacity,
              dailyModeToggle: dailyModeToggle,
              // Employee holidays for the week
              employeeHolidays: Wethod.featureService
                .isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK) ? employeeHolidays : [],
            };

            reducerWrapper = new TimesheetWeeklyReducer(reducerParams);
            store = TimesheetStore(reducerWrapper.reduxReducer);

            element = React.createElement(TimesheetWeekly, { store: store });

            container = document.getElementById('bodyRoot');
            if (container !== null) ReactDOM.render(element, container);
          });
        }
      });
  };
});
