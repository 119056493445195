const constants = require('./constants');
const BacklogsModel = require('./models/Backlogs');
const ProductionsModel = require('./models/Productions');
const AvgRevTargetModel = require('./models/AvgRevenuesTarget');

const getBacklogsSuccess = (backlogs) => ({
  type: constants.GET_BACKLOGS_SUCCESS,
  items: backlogs,
});

const getBacklogsRequest = () => ({
  type: constants.GET_BACKLOGS_REQUEST,
});

module.exports.getBacklogs = () => (dispatch) => {
  dispatch(getBacklogsRequest());
  const collection = new BacklogsModel();

  $.when(collection.getModel()).done((backlogs) => dispatch(getBacklogsSuccess(backlogs)));
};

const getProductionsSuccess = (productions) => ({
  type: constants.GET_PRODUCTIONS_SUCCESS,
  items: productions,
});

const getProductionsRequest = () => ({
  type: constants.GET_PRODUCTIONS_REQUEST,
});

module.exports.getProductions = () => (dispatch) => {
  dispatch(getProductionsRequest());
  const collection = new ProductionsModel();

  $.when(collection.getModel())
    .done((productions) => dispatch(getProductionsSuccess(productions[0])));
};

const getAvgRevsSuccess = (revenues) => ({
  type: constants.GET_AVG_REV_SUCCESS,
  items: revenues,
});

const getAvgRevsRequest = () => ({
  type: constants.GET_AVG_REV_REQUEST,
});

module.exports.getAvgRevenues = () => (dispatch) => {
  dispatch(getAvgRevsRequest());
  const collection = new AvgRevTargetModel();

  $.when(collection.getModel()).done((revenues) => dispatch(getAvgRevsSuccess(revenues)));
};
