const { connect } = require('react-redux');
const Component = require('../../components/modals/ShareSearchModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  filters: state.filters,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  shareSearch: (message, employeeId, path) => dispatch(actions
    .shareSearch(message, employeeId, path)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
