/* eslint-disable no-shadow */
module.exports = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  const found = (key, value) => {
    if (value) {
      return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    }
    return false;
  };

  const foundInTypeLabel = (key, event) => {
    if (event.event.type.label) {
      return found(key, event.event.type.label);
    }
    return false;
  };

  const foundInTypeName = (key, event) => {
    if (event.event.type.name) {
      return found(key, event.event.type.name);
    }
    return false;
  };
  const foundInTypeSlug = (key, event) => found(key, event.type);

  const isInType = (key, item) => {
    if (item.event.type) {
      return foundInTypeLabel(key, item) || foundInTypeName(key, item)
        || foundInTypeSlug(key, item);
    }
    if (item.type) {
      return foundInTypeSlug(key, item);
    }
    return false;
  };

  return items.filter((item) => {
    const foundInAuthor = found(key, item.created_by);
    const foundInType = isInType(key, item);
    const foundInDescription = found(key, item.event.description);
    const foundInProject = found(key, item.project.name);
    const foundInClient = found(key, item.project.client.corporate_name);
    return foundInAuthor || foundInType || foundInDescription || foundInProject || foundInClient;
  });
};
