const isPmOrAccount = (project) => {
  if (project) {
    const idPm = project.pm.id;
    const idAccount = project.account ? project.account.id : null;
    return Wethod.Authorization.amIPmOrAccount(idPm, idAccount);
  }
  return false;
};

module.exports = {
  getInvoicePermission: (action, project) => {
    const { getPermissionAs } = Wethod.FinanceApp;
    const amIPmOrAccount = isPmOrAccount(project);

    return getPermissionAs(action, amIPmOrAccount);
  },
};
