const React = require('react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const Search = require('../../../../../../common/react/Table2/TableSearch.react');
const Row = require('../containers/Order');
const Loader = require('../../../../../../common/react/Loader/Loader.react');

module.exports = class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions')
      .outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getSort(col) {
    const currentSort = this.props.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  getList() {
    return this.props.orders.map((item) => (
      <Row key={item.id} order={item} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    const regex = new RegExp(/^\w+:\d+$/);
    const style = regex.test(this.props.keyword) ? 'wethod-highlighter--search-keyword' : '';
    return (
      <Search
        filter={this.filter.bind(this)}
        className={style}
        keyword={this.props.keyword}
        onExportClick={this.props.showExportModal}
        placeholder="Search by project, job order, purchase code, supplier or pm" />
    );
  }

  updateTableHeight() {
    this.setState({ tableHeight: OrderList.getTableMaxHeight() });
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const deletedElements = this.props.deletedElements - this.props.createdElements;
      const adjustedPage = deletedElements < 0 ? page - 1 : page;
      this.props
        .loadMore(
          size,
          adjustedPage,
          this.props.keyword,
          this.props.sortBy.col,
          this.props.sortBy.order,
          this.props.statuses,
        );
      this.setState({
        size,
      });
    }
  }

  filter(keyword) {
    this.props.filter(this.state.size, keyword, this.props.sortBy.col, this.props.sortBy.order,
      this.props.statuses);
  }

  sort(col, order) {
    if (!this.props.isWaiting) {
      this.props.filter(this.state.size, this.props.keyword, col, order, this.props.statuses);
    }
  }

  render() {
    return (
      <div>
        <Table columns={10} maxHeight={this.state.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell name="project_name"
                className="orders__column-project"
                onSort={this.sort.bind(this)}
                sort={this.getSort('project_name')}>
                Project
              </TableCell>
              <TableCell name="area"
                className="orders__column-area"
                onSort={this.sort.bind(this)}
                sort={this.getSort('area')}>
                Area
              </TableCell>
              <TableCell name="supplier_name"
                className="orders__column-supplier"
                onSort={this.sort.bind(this)}
                sort={this.getSort('supplier_name')}>
                Supplier
              </TableCell>
              <TableCell name="supply_period"
                className="orders__column-period"
                onSort={this.sort.bind(this)}
                sort={this.getSort('supply_period')}>
                Period of supply
              </TableCell>
              <TableCell name="issue_date"
                className="orders__column-date"
                onSort={this.sort.bind(this)}
                sort={this.getSort('issue_date')}>
                Issue date
              </TableCell>
              <TableCell name="code"
                className="orders__column-code"
                onSort={this.sort.bind(this)}
                sort={this.getSort('code')}>
                Purchase code
              </TableCell>
              <TableCell name="value"
                className="orders__column-value"
                onSort={this.sort.bind(this)}
                sort={this.getSort('value')}>
                Order value
              </TableCell>
              <TableCell name="date_paid_on"
                className="orders__column-date"
                onSort={this.sort.bind(this)}
                sort={this.getSort('date_paid_on')}>
                Paid on
              </TableCell>
              <TableCell name="status"
                className="orders__column-status"
                onSort={this.sort.bind(this)}
                sort={this.getSort('status')} />
              <TableCell name="notes"
                className="orders__column-actions" />
              <TableCell name="actions"
                className="orders__column-actions" />
            </TableRow>
          </TableHead>
          <TableBody
            hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            editable
            empty={this.getEmptyContent()}>
            {this.getList()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
