/* eslint-disable react/no-array-index-key */
const React = require('react');
const Column = require('./CalendarHeaderColumn/CalendarHeaderColumn.react');

module.exports = class CalendarHeader extends React.Component {
  componentDidMount() {
    this.props.updateCalendarWidth(this.el.offsetWidth);
  }

  componentDidUpdate(prevProps) {
    const rangeChanged = prevProps.columns.length !== this.props.columns.length;
    if (rangeChanged) {
      this.props.updateCalendarWidth(this.el.offsetWidth);
    }
  }

  getColumns() {
    return this.props.columns
      .map((column, index) => <Column key={index} type={this.props.type} column={column} />);
  }

  render() {
    return (
      <div className="project-canvas-gantt__calendar-header" ref={(el) => this.el = el}>
        <div className="project-canvas-gantt__calendar-header-columns">
          {this.getColumns()}
        </div>
      </div>
    );
  }
};
