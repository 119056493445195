'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette, $, _, Header) {
  var layout;

  // SEARCH
  this.searchProgram = function (val) {
    var reg = new RegExp(' ', 'g');
    val = val.replace(reg, ',');

    var params = { search: val };

    // renderizzo il loading
    var loadingTemplate = new PipelineApp.PipelineProgramLoadingMoreDataTemplateView();
    layout.programList.show(loadingTemplate);

    var searchProgramRequest = Wethod.request('pipeline:get:program:list', params);
    $.when(searchProgramRequest).done(function (searchProgramCollection) {
      var pipelineProgramCollectionTemplate;
      if (!_.isUndefined(searchProgramCollection)) {
        loadingTemplate.destroy();
        if (!_.isEmpty(searchProgramCollection.models)) {
          pipelineProgramCollectionTemplate = new PipelineApp.PipelineProgramsCollectionView({
            collection: searchProgramCollection,
            isValueAsUnit: this.isValueAsUnit,
          });
        } else {
          pipelineProgramCollectionTemplate = new PipelineApp.PipelineProgramsNotFoundView();
        }

        layout.programList.show(pipelineProgramCollectionTemplate);
      }
    });
  };

  this.initProgram = function () {
    var pipelineProgramsRequest = Wethod.request('pipeline:get:program:list');
    var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');

    $.when(pipelineProgramsRequest, isValueAsUnitRequest)
      .done(function (pipelineProgramsCollection, isValueAsUnitResponse) {
        var programButtonsTemplate = new PipelineApp.PipelineProgramButtounsView();

        this.isValueAsUnit = isValueAsUnitResponse ? isValueAsUnitResponse.get('enabled') : false;

        var pipelineProgramCollectionTemplate = new PipelineApp.PipelineProgramsCollectionView({
          collection: pipelineProgramsCollection,
          isValueAsUnit: this.isValueAsUnit,
        });

        layout = new PipelineApp.ProgramStructureLayoutView();
        layout.render();

        var sectionHeaderModel = new Header.SectionHeaderModel({
          current_section: 'Programs',
          preview_anchor_id: 'programs',
          helper_url: 'pipeline/index/#programs',
        });
        var programsHeaderRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });

        layout.programHeaderSection.show(programsHeaderRegion);

        layout.programList.show(pipelineProgramCollectionTemplate);
        layout.buttons.show(programButtonsTemplate);
        layout.placeFixedHeader();
      });
  };
}, Wethod.module('HeaderApp.Header'));
