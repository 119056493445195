const React = require('react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const FavouriteIcon = ({
  projectId, favorite, archived, canEdit,
  addFavourite, removeFavourite,
}) => {
  function handleClick(e) {
    e.stopPropagation();
    if (canEdit) {
      if (favorite && favorite.id) {
        removeFavourite(projectId, favorite.id);
      } else {
        addFavourite(projectId);
      }
    }
  }

  function getHeartClassName() {
    let name = 'timesheet-weekly__cell-options-icon wethod-icon-heart timesheet-weekly__button';
    if (favorite && favorite.id) name += ' wethod-icon-heart-full';
    if (!canEdit) name += ' disabled';
    return name;
  }

  return (
    <ShowIf condition={archived}
      showElse={(
        <div className={getHeartClassName()}
          aria-label="Favourite"
          onClick={handleClick} />
      )}>
      <div className="timesheet-weekly__archived" data-testid="timesheet-label-archived">
        ARCHIVED
      </div>
    </ShowIf>
  );
};

module.exports = FavouriteIcon;
