const React = require('react');
const PropTypes = require('prop-types');
const SelectItem = require('./SelectItem.react');

const SelectItemDisabled = ({ value, className, children, hidden }) => {
  const getClassName = () => {
    let name = `${className} wethod-menu__item--disabled`;

    if (hidden) {
      name += ' wethod-menu__item--hidden';
    }
    return name;
  };

  function onClick(e) {
    // Prevent menu closing when clicking on disabled item
    e.stopPropagation();
  }

  return (
    <SelectItem value={value} className={getClassName()} onClick={onClick}>{children}</SelectItem>
  );
};

SelectItemDisabled.defaultProps = {
  className: '',
  hidden: false,
};

SelectItemDisabled.propTypes = {
  /**
   * Item content.
   */
  children: PropTypes.node.isRequired,
  /**
   * A value which uniquely identifies this item.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Additional class given to the item
   */
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

module.exports = SelectItemDisabled;
