const { connect } = require('react-redux');
const Component = require('../components/BudgetChangelog.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  size: state.size,
  keyword: state.keyword,
  orderBy: state.sortBy.col,
  sort: state.sortBy.order,
  bu: state.buFilter,
});

const mapDispatchToProps = (dispatch) => ({
  loadChangelogs: (size, page, keyword, orderBy, sort, bu) => dispatch(
    actions.loadChangelogs(size, page, keyword, orderBy, sort, bu),
  ),
  filter: (size, keyword, orderBy, sort, bu) => dispatch(
    actions.filterChangelogs(size, keyword, orderBy, sort, bu),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
