/* eslint-disable react/sort-comp */
const PropTypes = require('prop-types');
const React = require('react');
const moment = require('moment');
const SidebarInput = require('./SidebarWrapperEditable.react');
const DateRangePicker = require('../DateRangeButtonPicker.react');
const { formatDate } = require('../../../services/FormatService');
const Button = require('./Button.react');

/**
 * An outlined input-like button component, material styled. On click it opens a calendar to
 * choose a date or interval
 * The start date and the end date are shown in two different buttons.
 *
 * @type {module.SidebarDateRangeDoubleSelection}
 */
class SidebarDateRangeDoubleSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({ initialMonth: 'from' });
  }

  static formatStart(date) {
    return formatDate(date.start_date);
  }

  static formatEnd(date) {
    return formatDate(date.end_date);
  }

  handleClick() {
    this.setState({ initialMonth: 'to' }, () => this.calendarButton.showPicker());
  }

  getInitialMonth() {
    return this.state.initialMonth === 'to' ? this.props.value.end_date : this.props.value.start_date;
  }

  handleFocus() {
    if (this.state.initialMonth !== 'from') {
      this.setState({ initialMonth: 'from' });
    }
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  render() {
    return (
      <span>
        <SidebarInput placeholder={this.props.placeholder.start || this.props.placeholder}
          helperText={this.props.helperText}
          icon={this.props.children}
          required={this.props.required}
          inputType="range">
          <DateRangePicker name={this.props.name}
            disableBeforeEqual={this.props.disableBeforeEqual}
            value={this.props.value}
            ref={(calendarButton) => this.calendarButton = calendarButton}
            format={SidebarDateRangeDoubleSelection.formatStart}
            initialMonth={this.getInitialMonth()}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            deletable={!this.props.required} />
        </SidebarInput>
        <SidebarInput placeholder={this.props.placeholder.end || this.props.placeholder}
          helperText={this.props.helperText}
          required={this.props.required}
          inputType="range">
          <Button name={this.props.name}
            value={this.props.value}
            onClick={this.handleClick.bind(this)}>
            {SidebarDateRangeDoubleSelection.formatEnd(this.props.value)}
          </Button>
        </SidebarInput>
      </span>
    );
  }
}

SidebarDateRangeDoubleSelection.propTypes = {
  value: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  /**
   * string that will be shown as placeholder and, when placeholder is not shown, as
   * label
   */
  placeholder: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  required: PropTypes.bool,
  /**
   * string shown at the bottom of the input to give info; it will otherwise be replaced
   * by an error text when necessary
   */
  helperText: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**
   * This day and all the ones before this are disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
};

SidebarDateRangeDoubleSelection.defaultProps = {
  required: false,
  helperText: null,
  placeholder: {
    start: 'From',
    end: 'To',
  },
  children: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  disableBeforeEqual: null,
};

module.exports = SidebarDateRangeDoubleSelection;
