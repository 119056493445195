const { connect } = require('react-redux');
const Component = require('../components/OrderList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  orders: state.orders,
  isWaiting: state.waitingFor.filter((key) => key === 'load-orders' || key === 'filter-orders').length > 0,
  size: state.size,
  keyword: state.keyword,
  sortBy: state.sortBy,
  hasMore: !state.lastPage,
  statuses: state.statusFilter,
});

const mapDispatchToProps = (dispatch) => ({
  loadMore: (size, page, keyword, orderBy, sort, statuses) => dispatch(
    actions.loadOrders(size, page, keyword, orderBy, sort, statuses),
  ),
  filter: (size, keyword, orderBy, sort, statuses) => dispatch(
    actions.filterOrders(size, keyword, orderBy, sort, statuses),
  ),
  showExportModal: () => dispatch(actions.showModal('export', null)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
