const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/desk/research`;
  },
  deleteSearch(searchId) {
    const defer = $.Deferred();
    $.ajax({
      method: 'DELETE',
      crossDomain: true,
      url: `${this.urlRoot()}/${searchId}`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  updateSearchLabel(searchId, search) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/${searchId}`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(search),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
