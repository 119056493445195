/* eslint-disable default-case */

'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  /**
   * Project-charter creation/add modal
   */
  this.ProjectCharterCreationLayout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#projectCharterCreation',
    regions: {
      selector: '[data-region="selector"]',
      body: '[data-region="body"]',
    },
    _selectedButton: null,
    _projectId: null,
    initialize: function (options) {
      this._projectId = options.projectId;

      // Show create/add view
      dispatcher.on('project-charter:selector:selected', function (value) {
        if (this._selectedButton !== value) {
          switch (value) {
            case 'create':
              this.showCreateTab();
              this._selectedButton = value;
              break;
            case 'add':
              this.showAddTab();
              this._selectedButton = value;
              break;
          }
        }
      }.bind(this));
    },
    onRender: function () {
      this.placeModal();
    },
    showCreateTab: function () {
      var creationView = new ProjectCharterApp.ProjectCharterCreationView({
        model: new ProjectCharterApp.ProgramModel(),
        projectId: this._projectId,
      });
      this.body.show(creationView);
    },
    showAddTab: function () {
      var addView = new ProjectCharterApp.ProjectCharterAddView({
        model: new ProjectCharterApp.ProjectCharterProjectModel(),
        projectId: this._projectId,
      });
      this.body.show(addView);
    },
    placeModal: function () {
      var modalW = 450;
      var modalH = 412;
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      // Todo better way to handle this?
      this.$el.find('.modalBgCreationStructure').css({ left: posLeft, top: posTop }).show();
    },
  });

  /**
   * Project-charter creation/add selector
   */
  this.ProjectCharterCreationSelectorView = Marionette.ItemView.extend({
    template: '#projectCharterCreationSelector',
    className: 'projectCharterSelector',
    ui: {
      buttons: '[data-action="select"]',
    },
    events: {
      'click @ui.buttons': 'onSelection',
    },
    onSelection: function (e) {
      e.preventDefault();
      this.ui.buttons.removeClass('sel');
      $(e.currentTarget).addClass('sel');
      dispatcher.trigger('project-charter:selector:selected', $(e.currentTarget).attr('data-value'));
    },
  });

  /**
   * Project-charter 'creation' view
   */
  this.ProjectCharterCreationView = Marionette.ItemView.extend({
    template: '#projectCharterCreate',
    ui: {
      managerHintEl: '[data-region="managerHint"]',

      // INPUT
      mdlInput: '[data-region="mdl-input"]',
      nameEl: '[data-region="programName"]',
      descriptionEl: '[data-region="programDescription"]',

      // AUTOCOMPLETE
      inputHintEl: '[data-region="programManager"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',

      // LABEL
      labelNameEl: '[for="name"]',
      labelManagerEl: '[for="manager"]',

      // MESSAGE
      messageEl: '[data-message="statusModalAction"]',
      // BUTTONS
      cancelEl: '[data-action="modalCancel"]',
      doActionEl: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.doActionEl': 'createProjectCharter',

      'click @ui.nameEl': 'resetErrors',
      'change @ui.nameEl': function (e) {
        return (this.updateModel(e, 'name'));
      },
      'change @ui.descriptionEl': function (e) {
        return (this.updateModel(e, 'description'));
      },
      // autocomplete
      'click @ui.inputHintEl': 'showManagerHints',
      'keyup @ui.inputHintEl': 'showManagerHints',
    },
    modelEvents: {
      change: 'modelUpdated',
      invalid: 'showErrors',
    },
    initialize: function (options) {
      this.options = options;
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    showErrors: function (model, errors) {
      var error = errors[0];
      if (error.name) {
        this.ui.labelNameEl.addClass('error');
        this.ui.messageEl.html(error.name);
      } else if (error.manager) {
        this.ui.labelManagerEl.addClass('error');
        this.ui.messageEl.html(error.manager);
      }
      this.ui.messageEl.addClass('error');
      // buttons
      this.ui.doActionEl.addClass('disabled');
      this.ui.cancelEl.addClass('disabled');
    },
    modelUpdated: function () {
      this.cleanManagerName();
    },
    changeHint: function (id, hint) {
      this.model.set({ manager: id });
      this.options.hint = hint;
      this.cleanManagerName();
    },
    resetErrors: function () {
      // label
      this.ui.labelNameEl.removeClass('error');
      this.ui.labelManagerEl.removeClass('error');
      // message
      this.ui.messageEl.html('');
      this.ui.messageEl.removeClass('error');
      // buttons
      this.ui.doActionEl.removeClass('disabled');
      this.ui.cancelEl.removeClass('disabled');
    },
    updateModel: function (e, attribute) {
      this.resetErrors();

      if (e.target.value.trim() !== '') {
        this.model.attributes[attribute] = e.target.value.trim();
      } else {
        this.model.attributes[attribute] = null;
      }
    },
    showManagerHints: function (e) {
      e.stopPropagation();
      this.resetErrors();
      this.ui.managerHintEl.addClass('isVisible');
      ProjectCharterApp.renderManagerHints(this, e.target.value.trim());
      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.managerHintEl.selector, 'isVisible', 'remove');
    },
    cleanManagerName: function () {
      this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');
      this.ui.managerHintEl.removeClass('isVisible');
      this.ui.inputHintEl.val(this.options.hint);
    },
    createProjectCharter: function (e) {
      e.preventDefault();
      if (!this.ui.doActionEl.hasClass('disabled')) {
        this.ui.doActionEl.addClass('disabled');
        this.ui.cancelEl.addClass('disabled');

        this.ui.messageEl.text('Creating...');

        ProjectCharterApp.CreateProjecterChart(this);
      }
    },
  });

  /**
   * Project-charter 'add' view
   */
  this.ProjectCharterAddView = Marionette.ItemView.extend({
    template: '#projectCharterAdd',
    ui: {
      mdlInput: '[data-region="mdl-input"]', // material design inputs label
      programInput: '[data-region="program"]',
      labelProgram: '[for="program"]',
      programHints: '[data-region="programHint"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      addButton: '[data-action="modalDoAction"]',
      message: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.programInput': 'showPrograms',
      'keyup @ui.programInput': 'showPrograms',
      'click @ui.addButton': 'addProject',
    },
    initialize: function (options) {
      this.model.set('project', options.projectId);
      this.model.set('sort', 0);
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    showPrograms: function (e) {
      e.stopPropagation();

      this.resetErrors();
      this.ui.programHints.addClass('isVisible');

      var searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
      this.ui.programHints.html(searchFeedbackTemplate.render().$el);

      Wethod.Utility.withDelay(function () {
        this.fetchAutocomplete(e.target.value.trim().replace(' ', ','));
      }.bind(this), 350);
    },
    fetchAutocomplete: function (search) {
      var autocompleteRequest = Wethod.request('program:get:hints', search);

      $.when(autocompleteRequest).done(function (autocompleteResponse) {
        if (!_.isUndefined(autocompleteResponse)) {
          if (autocompleteResponse.length) {
            var managerHintsTemplate = new ProjectCharterApp.HintsCollectionView({
              collection: autocompleteResponse,
              childViewOptions: { parentView: this },
            });
            this.ui.programHints.html(managerHintsTemplate.render().$el);
          } else {
            var searchFeedbackTemplate = new ProjectCharterApp.SearchNotFoundTemplateView();
            this.ui.programHints.html(searchFeedbackTemplate.render().$el);
          }
        }
      }.bind(this));

      Wethod.onHtmlClick(this.ui.programHints.selector, 'isVisible', 'remove');
    },
    changeHint: function (id, hint) {
      this.model.set('program', id);
      this.options.hint = hint;
      this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').addClass('is-dirty');
      this.ui.programHints.removeClass('isVisible');
      this.ui.programInput.val(this.options.hint);
    },
    addProject: function (e) {
      e.preventDefault();
      var errors = this.model.validate();

      if (!this.model.isValid()) {
        if (errors.program) {
          this.ui.labelProgram.addClass('error');
          this.ui.message.addClass('error');
          this.ui.message.text(errors.program);
        }
      } else {
        var request = Wethod.request('projectCharter:insert:project', this.model);
        $.when(request).done(function () {
          dispatcher.trigger('url:show:project-charter', this.model.get('program'));
        }.bind(this));
      }
    },
    resetErrors: function () {
      this.ui.labelProgram.removeClass('error');
      this.ui.message.removeClass('error');
      this.ui.message.html('');
      this.ui.message.removeClass('error');
    },
  });
});
