const React = require('react');
const TrendFridayness = require('../../containers/Trends/TrendFridayness');
const TrendChargeability = require('../../containers/Trends/TrendChargeability');
const TrendKeepTheLightsOff = require('../../containers/Trends/TrendKeepTheLightsOff');
const TrendDoThePlan = require('../../containers/Trends/TrendDoThePlan');
const TrendDoTheBudget = require('../../containers/Trends/TrendDoTheBudget');

module.exports = class Trends extends React.Component {
  getMeaning() {
    if (this.props.filter === 'year') return 'One year to today moving average';
    return 'Three months to today moving average';
  }

  render() {
    return (
      <div className="profile-trends">
        <div className="profile-trends__header">
          <div className="profile-trends__legend">
            <div className="profile-trends__legend-item">
              <div className="profile-trends__legend-item-image profile-trends__legend-item-image--positive" />
              <div className="profile-trends__legend-item-name">Getting better</div>
            </div>
            <div className="profile-trends__legend-item">
              <div className="profile-trends__legend-item-image profile-trends__legend-item-image--negative" />
              <div className="profile-trends__legend-item-name">Getting worse</div>
            </div>
          </div>
          <div className="profile-trends__meaning">
            {this.getMeaning()}
          </div>
        </div>
        <div className="profile-trends__collection">
          <TrendFridayness />
          <TrendChargeability />
          <TrendDoThePlan />
          <TrendKeepTheLightsOff />
          <TrendDoTheBudget />
        </div>
      </div>
    );
  }
};
