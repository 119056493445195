/* eslint-disable react/sort-comp,jsx-a11y/anchor-is-valid,consistent-return,class-methods-use-this,prefer-destructuring */
const React = require('react');

module.exports = class FollowUp extends React.Component {
  scrollToEvent(id) {
    $('html, body').animate({
      scrollTop: ($(`#${id}`).offset().top - 126),
    }, 500);
  }

  handleParentEventTypeClick(e) {
    e.preventDefault();
    this.scrollToEvent(this.parentEvent.event.id);
  }

  getParentLink() {
    if (this.parentEvent) {
      return (
        <div className="timeline-event-log__creation">
          This is a follow-up to the
          {' '}
          <a
            href="#"
            onClick={this.handleParentEventTypeClick.bind(this)}>
            {this.parentEvent.event.type.label}
          </a>
          {' '}
          which
          {' '}
          {this.parentEvent.created_by}
          {' '}
          had
          on
          {' '}
          {this.getCreationDate(this.parentEvent.timestamp)}
        </div>
      );
    }
    return null;
  }

  getCreationDate(day) {
    const momentDate = moment(day);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    const createdBy = this.props.created_by;
    if (createdBy) {
      return `${createdBy.person.name} ${createdBy.person.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate(this.props.schedule);
    if (creator) {
      return (
        <span>
          <span className="timeline-event-past__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-past__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-past__creation-time">{date}</span>
    );
  }

  render() {
    this.parentEvent = this.props.events
      .filter((event) => event.event.followed_by === this.props.id)[0];

    return (
      <div className="timeline-event-past__content">
        <div className="timeline-event-future__creation">
          {this.getCreation()}
          {this.getParentLink()}
        </div>
        <div className="timeline-event-past__description">{this.props.description}</div>
        <div className="timeline-event-past__actions">
          <div className="timeline-event-past__actions-left" />
          <div className="timeline-event-past__actions-right">
            <div
              className="clients__delete-button wethod-icon-button"
              onClick={this.props.handleDeleteClick}>
              <div className="wethod-icon-medium-delete" />
            </div>
            <a
              href="#"
              className="clients__delete-button wethod-icon-button"
              onClick={this.props.handleEditClick}>
              <div className="wethod-icon-medium-edit" />
            </a>
          </div>
        </div>
      </div>
    );
  }
};
