const React = require('react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');

const TableHeaderRow = ({ levels, ...rest }) => (
  <TableRow className="company-settings-location__table-row" {...rest}>
    <TableCell className="company-settings-location__table-cell-name">
      Name
    </TableCell>
  </TableRow>
);

module.exports = TableHeaderRow;
