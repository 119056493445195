const { connect } = require('react-redux');
const Component = require('../../../components/modals/IntercompanyProject/IntercompanyProjectBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.length > 0,
  intercompany: state.intercompany,
  error: state.serverError,
});

const mapDispatchToProps = (dispatch) => ({
  getIntercompanyInfo: (token) => dispatch(actions.getIntercompanyInvitationInfo(token)),
  connectProject: (token, projectId) => dispatch(actions.connectProject(token, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
