const React = require('react');
const WarningAccordion = require('../WarningAccordion.react');
const UnarchivedProjects = require('../../../containers/dashboard/unarchived-projects/UnarchivedProjects');

const UnarchivedProjectsAccordion = ({ counter }) => (
  <WarningAccordion
    title="Unarchived Projects"
    subtitle="Archive all projects on the list before suspending changes to data of the selected segment."
    counter={counter}>
    <UnarchivedProjects />
  </WarningAccordion>
);

module.exports = UnarchivedProjectsAccordion;
