/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return, react/no-array-index-key, react/jsx-no-bind */
const React = require('react');
const Project = require('./Project.react');
const EmptyTimesheet = require('./EmptyTimesheet.react');
const InfiniteScrollContainer = require('../../../../../../../common/react/InfiniteScrollContainer.react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');

const TimesheetList = ({
  projects, isLoading, allProjects, filter, userId,
  scrollbarWidth, loadMoreProjects, alignHeaderColumns,
}) => {
  const getProjects = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (projects.length === 0) {
      return <EmptyTimesheet filter={filter} />;
    }
    return projects.map((project) => (
      <Project key={project.project.id}
        {...project} />
    ));
  };

  const hasMoreProjects = () => projects.length < allProjects.length;

  const handleScrollbar = (width) => {
    if (width !== scrollbarWidth) {
      alignHeaderColumns(width);
    }
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <li className="timesheet-list__projects-loading">
          <Loader />
        </li>
      );
    }

    const style = userId != null
      ? 'timesheet-project__collection timesheet-project__collection--others-view'
      : 'timesheet-project__collection';

    return (
      <InfiniteScrollContainer
        className={style}
        key={0}
        itemHeight={60}
        hasMore={hasMoreProjects()}
        loadMore={loadMoreProjects}
        handleScrollbar={handleScrollbar}>
        <div className="timesheet-project__collection-content-wrapper">
          <div className="timesheet-project__collection-content">
            {getProjects()}
          </div>
        </div>
      </InfiniteScrollContainer>
    );
  };

  const getStyle = () => ({
    paddingRight: scrollbarWidth,
  });

  return (
    <div className="timesheet-list">
      <div className="timesheet-list__header wethod-list-header"
        style={getStyle()}>
        <div className="timesheet__column--favourite wethod-list-column" />
        <div className="timesheet__column--project-name wethod-list-column">Project</div>
        <div className="timesheet__column--automatic wethod-list-column" />
        <div className="timesheet__column--client wethod-list-column">Client</div>
        <div className="timesheet__column--job-order wethod-list-column">Job order</div>
        <div className="timesheet__column--job-order-category wethod-list-column">
          Job Order Category
        </div>
        <div className="timesheet__column--hours wethod-list-column">Hours</div>
      </div>
      <ul className="timesheet-list__projects">
        {getContent()}
      </ul>
    </div>
  );
};

module.exports = TimesheetList;
