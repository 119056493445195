'use strict';

Wethod.module('SettingsApp', function (SettingsApp, Wethod, Backbone, Marionette, $, _) {
  this.getPermission = function (label) {
    if (!_.isUndefined(SettingsApp.authorization.get(label))) {
      return SettingsApp.authorization.get(label);
    }
    return false;
  };
});
