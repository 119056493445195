/**
 * Check whether the given job order category provides automatic timesheet.
 * @param joc // Job order category
 * @returns {*}
 */
const isTimesheetAutomatic = (joc) => joc && joc.is_timesheet_automatic;

/**
 * Check whether the given date is editable.
 * When the job order category has automatic timesheet, we also check the canEdit flag.
 * @param joc
 * @param canEdit
 * @returns {*}
 */
const isDayEditable = (joc, canEdit) => !isTimesheetAutomatic(joc) || canEdit;

/**
 * Check whether the user is not in the whitelist of given project.
 * @returns {*}
 */
const isInWhitelist = (project) => project.whitelist_permission;

module.exports.isTimesheetAutomatic = isTimesheetAutomatic;
module.exports.isDayEditable = isDayEditable;
module.exports.isInWhitelist = isInWhitelist;
