const React = require('react');
const ImageInput = require('../../../../../../../../common/react/ImageInput.react');

module.exports = class ProfilePicPickerBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pic: this.props.pic ? this.props.pic : '',
      errors: [],
    };
  }

  onUploadClick() {
    this.props.updateProfilePic(this.state.pic, this.props.employee);
  }

  onImageChange(image) {
    this.setState({ pic: image });
  }

  onValidate(name, errors) {
    this.setState({ errors });
  }

  getFeedback() {
    if (this.props.waiting) {
      return <div className="wethod-modal2__feedback">Saving...</div>;
    }
    if (this.state.errors.length > 0) {
      return (
        <div className="wethod-modal2__feedback wethod-modal2__feedback--error">
          The image cannot be used.
          {' '}
          <br />
          Please check that your image is not more than 200KB in size and its format is one of JPEG,
          PNG or GIF
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile-pic-picker--editable">
        <span className="profile-card__placeholder">
          Select the image you wish to upload. Images should not be more than 200KB in size.
        </span>
        <ImageInput
          onChange={this.onImageChange.bind(this)}
          name="pic"
          size={200}
          onValidate={this.onValidate.bind(this)} />
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.props.hideModal}
            className="wethod-button">Cancel
          </button>
          <button type="button"
            onClick={this.onUploadClick.bind(this)}
            className="wethod-button">Save
          </button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
