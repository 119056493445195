const { connect } = require('react-redux');
const Component = require('../../../../components/Footer/DefaultFooterContent/StatusButton/StatusButtonFactory.react');
const actions = require('../../../../actions');

const mapStateToProps = (state) => ({
  status: state.info.status,
  selectedVersion: state.targetVersion,
  versions: state.versions,
});

const mapDispatchToProps = (dispatch) => ({
  showModalBudgetSubmit: () => dispatch(actions.showModalBudgetSubmit()),
  showModalBudgetApproval: () => dispatch(actions.showModalBudgetApproval()),
  showModalBudgetDraft: () => dispatch(actions.showModalBudgetDraft()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
