const React = require('react');
const Row = require('../Row.react');
const RowHorizontalScroll = require('../RowHorizontalScroll.react');

module.exports = class SummaryHeader extends React.Component {
  constructor(props) {
    super(props);
    this.totalSummaryReference = null;
    this.totalAreaSummaryReference = null;
    this.onScrollTotalSummary = this.onScrollTotalSummary.bind(this);
    this.onScrollTotalAreaSummary = this.onScrollTotalAreaSummary.bind(this);
    this.setTotalSummaryRef = this.setTotalSummaryRef.bind(this);
    this.setTotalAreaSummaryRef = this.setTotalAreaSummaryRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollingComponentAmount !== this.props.scrollingComponentAmount) {
      if (this.props.column === 'center') {
        if (this.props.name === 'total') {
          this.totalSummaryReference.scrollTo({ left: this.props.scrollingComponentAmount });
        } else {
          this.totalAreaSummaryReference
            .scrollTo({ left: this.props.scrollingComponentAmount });
        }
      }
    }
  }

  onScrollTotalSummary() {
    this.props
      .setScrollingComponentScrollAmount(this.totalSummaryReference.scrollLeft);
  }

  onScrollTotalAreaSummary() {
    this.props
      .setScrollingComponentScrollAmount(this.totalAreaSummaryReference.scrollLeft);
  }

  setTotalSummaryRef(el) {
    this.totalSummaryReference = el;
  }

  setTotalAreaSummaryRef(el) {
    this.totalAreaSummaryReference = el;
  }

  render() {
    if (this.props.column === 'center') {
      if (this.props.name === 'total') {
        return (
          <RowHorizontalScroll
            myRef={this.setTotalSummaryRef}
            onScroll={this.onScrollTotalSummary}
            className={`wethod-budget-summary__header ${this.props.horizontalScrollClass} wethod-budget-summary__header--${this.props.column ? this.props.column : 'total'}`}>{this.props.children}
          </RowHorizontalScroll>
        );
      }
      return (
        <RowHorizontalScroll
          myRef={this.setTotalAreaSummaryRef}
          onScroll={this.onScrollTotalAreaSummary}
          className={`wethod-budget-summary__header ${this.props.horizontalScrollClass} wethod-budget-summary__header--${this.props.column ? this.props.column : 'total'}`}>{this.props.children}
        </RowHorizontalScroll>
      );
    }
    return (
      <Row
        className={`wethod-budget-summary__header--${this.props.total
          ? 'total'
          : this.props.column} wethod-budget-summary__header ${this.props.showShadows
          ? `wethod-budget-shadow-${this.props.column}`
          : ''} ${this.props.id === 'user-areas' ? `wethod-budget-summary__header--user-areas--${this.props.column}` : ''}`}>{this.props.children}
      </Row>
    );
  }
};
