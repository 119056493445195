const { connect } = require('react-redux');
const Component = require('../../components/modals/SaveSearchModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'search-save').length > 0,
  filters: state.filters,
  search: state.search,
  section: state.type,
});

const mapDispatchToProps = (dispatch) => ({
  saveSearch: (search, label, section) => dispatch(actions.saveSearch(search, label, section)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
