/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */

const React = require('react');
const Menu = require('../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const StatusListMenu = require('../../../project-canvas/components/Boards/Spreadsheet/ItemCell/StatusItemCell/StatusMenu/StatusListMenu.react');
const StatusButton = require('../../../../../../common/react/ColoredButton.react');

module.exports = class Activity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuToShow: null,
    };
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  onMenuButtonClick(type) {
    this.setState({ menuToShow: type });
  }

  onCloseMenu() {
    this.setState({ menuToShow: null });
  }

  handleStatusChange(status) {
    if (status.id !== this.props.selected_status.id) {
      this.props.updateStatus(status, this.props.item.id, this.props.board.id,
        this.props.attribute.id);
    }
  }

  render() {
    return (
      <div className="desk-widget__item">
        <div className="desk-widget__multirow-info desk-widget-activities__name">
          <span className="desk-widget__text">{this.props.item.name}</span>
          <span className="desk-widget__multirow-info--secondary desk-widget__text">{this.props.board.name}</span>
        </div>
        <div className="desk-widget__multirow-info desk-widget-activities__project">
          <span className="desk-widget__text">{this.getUnescapedValue(this.props.project.name)}</span>
          <span className="desk-widget__multirow-info--secondary desk-widget__text">{this.getUnescapedValue(this.props.client.name)}</span>
        </div>
        <StatusButton
          setRef={(el) => this.statusButton = el}
          value={this.props.selected_status.name}
          color={this.props.selected_status.color}
          onClick={() => {
            this.onMenuButtonClick('status');
          }} />
        <Menu
          open={this.state.menuToShow === 'status'}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.statusButton}>
          <StatusListMenu
            statusList={this.props.available_statuses}
            onStatusChange={this.handleStatusChange.bind(this)} />
        </Menu>
        <button type="button"
          className="wethod-icon-button project-canvas-spreadsheet__row-more-button"
          ref={(el) => this.optionsButton = el}
          onClick={() => {
            this.onMenuButtonClick('options');
          }}>
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <Menu
          open={this.state.menuToShow === 'options'}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.optionsButton}>
          <MenuItem>
            <a className="desk-widget-activities__link"
              href={`#project/${this.props.project.id}/canvas?board=${this.props.board.id}`}>
              Go to
              canvas
            </a>
          </MenuItem>
        </Menu>
      </div>
    );
  }
};
