const { connect } = require('react-redux');
const Component = require('../../../components/sidebar/FileSection/FileSectionEditable.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  travel: state.focusedTravel,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadSuccess: (travel, carrier, file) => dispatch(actions
    .uploadFileSuccess(travel, carrier, file)),
  onDeleteSuccess: (travel, carrier, file) => dispatch(actions
    .deleteFileSuccess(travel, carrier, file)),
  showCannotUploadFileModal: (data) => dispatch(actions.showCannotUploadFileModal(data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
