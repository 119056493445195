const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Spreadsheet/Spreadsheet.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit,
  focusedBoardId: state.focusedBoardId,
});

const mapDispatchToProps = (dispatch) => ({
  saveRowsSort: (boardId, items) => dispatch(actions.saveSpreadsheetItemsSort(boardId, items)),
  sortRows: (boardId, items) => dispatch(actions.updateSpreadsheetItemsSort(boardId, items)),
  sortColumns: (boardId, structure, items) => dispatch(actions.updateSpreadsheetColumnsSort(boardId,
    structure, items)),
  saveColumnsSort: (boardId, attributes) => dispatch(actions.saveSpreadsheetColumnsSort(boardId,
    attributes)),
  saveColumnSummary: (boardId, attributeId, summary) => dispatch(actions
    .saveSpreadsheetColumnsSummary(boardId, attributeId, summary)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
