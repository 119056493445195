const React = require('react');
const ConfirmDelete = require('../containers/modal/ConfirmDeleteModal');
const MoveSupplier = require('../containers/modal/MoveSupplierModal');
const RatingModal = require('../containers/modal/RatingModal/RatingModal');
const ConfirmDeleteRating = require('../containers/modal/ConfirmDeleteRatingModal');
const CannotMoveSupplier = require('./modals/CannotMoveSupplierModal.react');

const Modal = ({ modalToShow, data, closeModal }) => {
  const getModal = () => {
    switch (modalToShow) {
      case 'confirm-delete':
        return <ConfirmDelete data={data} onClose={closeModal} />;
      case 'move-supplier':
        return <MoveSupplier data={data} onClose={closeModal} />;
      case 'rating':
        return <RatingModal data={data} onClose={closeModal} />;
      case 'delete-rating':
        return <ConfirmDeleteRating data={data} onClose={closeModal} />;
      case 'cannot-move':
        return <CannotMoveSupplier data={data} onClose={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
