class TableColumnList {
  /**
   * Represents a list of Table2 columns configurations.
   * @param columns {Array.<TableColumn>}
   */
  constructor(columns = []) {
    this.list = columns;
  }

  /**
   * Returns list total width.
   * If pixels and percentage width are mixed in list throws Error.
   * @throws Error
   * @return {number}
   */
  getTotalWidth() {
    const percentageFixedColumns = this.getPercentageFixedColumns();
    const pixelFixedColumns = this.getPixelFixedColumns();
    const differentUnitOfMeasure = percentageFixedColumns.length > 0
      && pixelFixedColumns.length > 0;

    if (differentUnitOfMeasure) {
      throw Error('Cannot calculate total width because different unit of measure are present');
    } else {
      return this.list.reduce((sum, column) => sum + column.getNumericWidth(), 0);
    }
  }

  /**
   * Returns columns whose width must be dynamically calculated based on available space.
   * @return {TableColumnList}
   */
  getElasticColumns() {
    return this.filter((column) => column.isElastic());
  }

  /**
   * Returns all columns in list with percentage width.
   * @return {TableColumnList}
   */
  getPercentageFixedColumns() {
    return this.filter((column) => column.hasPercentageWidth());
  }

  /**
   * Returns all columns in list with pixel width.
   * @return {TableColumnList}
   */
  getPixelFixedColumns() {
    return this.filter((column) => column.hasPixelWidth());
  }

  /**
   * Returns the value to use in CSS style "width" for elastic columns.
   * @return {string}
   */
  getElasticWidth() {
    const visibleColumns = this.getVisible();
    // Visible columns with fixed width expressed as percentage
    const percentageFixedColumns = visibleColumns.getPercentageFixedColumns();
    // Visible columns with fixed width expressed in pixels
    const pixelFixedColumns = visibleColumns.getPixelFixedColumns();
    // Sum of all the fixed percentage width in visible columns
    const percentageFixedAmount = percentageFixedColumns.getTotalWidth();
    // Sum of all the fixed pixel width in visible columns
    const pixelFixedAmount = pixelFixedColumns.getTotalWidth();
    const elasticColumnsAmount = visibleColumns.getElasticColumns().length;

    if (elasticColumnsAmount === 0) {
      return '0';
    }

    const availableWidthPerColumn = (100 - percentageFixedAmount) / elasticColumnsAmount;
    /* Needed because of "precision overflow": adding toghether numbers obtained by division can
     lead to sum an unexpected number of decimals. Browsers tend not to play well with decimals, so
     they round them ending in lost of precision. In this case, this lost of precision takes
     sometimes the last column on new line. We estimatate precison to have a standard deviation of
      1 px, so to balance it we are going to subtract standard deviation to each column width. */
    const pixelDeviationPerColumn = (1 / elasticColumnsAmount);
    const occupiedSpaceDistributionPerColumn = (pixelFixedAmount / elasticColumnsAmount)
      + pixelDeviationPerColumn;

    return `calc(${availableWidthPerColumn}% - ${occupiedSpaceDistributionPerColumn}px)`;
  }

  /**
   * Returns list where each column is augmented with elastic width.
   * @return {TableColumnList}
   */
  withElasticWidth() {
    const elasticWidth = this.getElasticWidth();
    const withDynamicWidth = this.list.map((column) => ({
      ...column,
      elasticWidth: column.isElastic() ? elasticWidth : column.width,
    }));

    return new TableColumnList(withDynamicWidth);
  }

  /**
   * Returns column with the given key or null it does not exists.
   * @param key {string}
   * @return {TableColumn | null}
   */
  findByKey(key) {
    const found = this.list.filter((column) => column.key === key);
    return found.length > 0 ? found[0] : null;
  }

  /**
   * Returns list filtered using the given callback.
   * @param callback {function}
   * @return {TableColumnList}
   */
  filter(callback) {
    const filteredList = this.list.filter(callback);
    return new TableColumnList(filteredList);
  }

  /**
   * Returns all visble columns.
   * @return {TableColumnList}
   */
  getVisible() {
    return this.filter((column) => column.visible);
  }

  /**
   * Returns all editable columns.
   * @return {TableColumnList}
   */
  getEditable() {
    return this.filter((column) => column.editable);
  }

  /**
   * Returns all columns.
   * @return {TableColumn[]}
   */
  getAll() {
    return this.list;
  }

  /**
   * Sets the column with the given key as "visible".
   * @param key {string}
   */
  setVisible(key) {
    const column = this.findByKey(key);
    if (column) {
      column.setVisible();
    }
  }

  /**
   * Sets visible all the columns with key equal to those in the given list.
   * @param list {[]}
   */
  setVisibleList(list) {
    list.forEach((key) => this.setVisible(key));
  }

  /**
   * Sets the column with the given key as "invisible".
   * @param key {string}
   */
  setInvisible(key) {
    const column = this.findByKey(key);
    if (column) {
      column.setInvisible();
    }
  }

  /**
   * Returns if column with the given key exists in list.
   * @param key {string}
   * @return {boolean}
   */
  has(key) {
    return this.findByKey(key) !== null;
  }

  get length() {
    return this.list.length;
  }

  /**
   * Returns list as an array of its columns keys.
   * @return {<Array>.string}
   */
  toKeysArray() {
    return this.list.map((column) => column.key);
  }
}

module.exports = TableColumnList;
