const React = require('react');
const _ = require('underscore');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const Radio = require('../../../../../../../common/react/inputs/RadioGroup/Radio.react');
const RadioGroup = require('../../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');

module.exports = class CreationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.item,
      archived: false,
    };

    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleItemChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.handleItemChange(name, value);
  }

  handleLevelChange(e) {
    const { name } = e.target;
    let { value } = e.target;

    value = parseInt(value);
    value = Number.isNaN(value) ? null : value;

    this.handleItemChange(name, value);
  }

  onSave() {
    this.props.onSave(this.state);
  }

  getLevels() {
    return this.props.levels ? this.props.levels.map((level) => (
      <Radio key={level.id}
        value={level.id}
        label=""
        className="company-settings-job-title__table-cell-level
        company-settings-job-title__table-cell-level--no-margin--display-block" />
    )) : [];
  }

  /**
   * Check whether the save button should be disabled.
   * All the fields must be filled in, and the job title must not be saving.
   * @returns {boolean|*}
   */
  isSaveDisabled() {
    return !this.state.name || !this.state.level || this.isSaving();
  }

  isSaving() {
    return this.props.waitingForSave.some((key) => key === `save-job-title-${this.props.item.id}`);
  }

  render() {
    return (
      <TableRow feedback={this.isSaving() ? 'Saving' : ''}
        className="company-settings-job-title__table-row company-settings--with-transparent-input">
        <TableCell className="company-settings-job-title__table-cell-name">
          <TextField name="name"
            label="Name"
            placeholder="Enter job title name"
            value={_.unescape(this.state.name)}
            onBlur={this.handleNameChange} />
        </TableCell>
        <TableCell className="company-settings-job-title__table-cell-level">
          <RadioGroup name="level"
            className="company-settings-job-title__table-cell-levels"
            value={this.state.level}
            onChange={this.handleLevelChange}>
            {this.getLevels()}
          </RadioGroup>
        </TableCell>
        <TableCell className="company-settings-job-title__table-cell-action">
          <Button onClick={this.onSave}
            disabled={this.isSaveDisabled()}
            className="company-settings__button company-settings__button--blue company-settings-margin-right-l">
            Save
          </Button>
          <IconButton icon="delete" color="blue" size="medium" onClick={this.props.onDelete} className="company-settings__icon-button" />
        </TableCell>
      </TableRow>
    );
  }
};
