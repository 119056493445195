const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('./Tooltip.react');

class IconLinkTooltip extends React.Component {
  getStyle() {
    let style = 'wethod-icon-button wethod-icon-button--tooltip';
    if (this.props.borderless) style += ' wethod-icon-button--no-border';
    if (this.props.className) style += ` ${this.props.className}`;
    return style;
  }

  getRel() {
    if (this.props.target && this.props.target === '_blank') {
      return 'noreferrer';
    }
    return null;
  }

  render() {
    return (
      <Tooltip label={this.props.label} className="wethod-tooltip--icon-link">
        <a className={this.getStyle()}
          href={this.props.href}
          target={this.props.target}
          rel={this.getRel()}>
          {this.props.children}
        </a>
      </Tooltip>
    );
  }
}

IconLinkTooltip.propTypes = {
  /**
   * Label shown as tooltip content
   * @see Tooltip
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * When set to true, the button is shown without borders.
   */
  borderless: PropTypes.bool,
  /**
   * Specifies target of the link
   */
  target: PropTypes.string,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * Link associated to the anchor
   */
  href: PropTypes.string,
  /**
   * Element shown as body of the button.
   * (Should be an icon)
   */
  children: PropTypes.element,
};

IconLinkTooltip.defaultProps = {
  label: null,
  borderless: false,
  target: null,
  className: null,
  href: '',
  children: null,
};

module.exports = IconLinkTooltip;
