const moment = require('moment');
const constants = require('./constants');
const FiscalYearService = require('../../../../services/FiscalYearService');

class TimeOffReducer {
  constructor(state) {
    this.fiscalYearService = new FiscalYearService();
    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      modalToShow: null,
      modalData: null,
      pageOffset: 0,
      pageLimit: 0,
      hasMorePages: true,
      keyword: '',
      items: [],
      year: this.fiscalYearService.getShiftedYear(moment().year()),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_TIME_OFF_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          keyword: action.search,
          year: action.year,
          typeFilter: action.typeFilter,
          items: action.offset ? state.items : [],
          waitingFor: state.waitingFor.concat('get-time-off'),
        };
      }
      case constants.GET_TIME_OFF_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.items.length >= state.pageLimit,
          items: state.items.concat(action.items),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-time-off'),
        };
      }
      case constants.SAVE_TIME_OFF_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-item-${action.id}`),
        };
      }
      case constants.SAVE_TIME_OFF_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-item-${action.item.employee.id}`),
          items: state.items.map((item) => {
            if (item.employee.id === action.item.employee.id) {
              return {
                ...item,
                time_off_targets: {
                  leave: action.item.leave,
                  target: action.item.target,
                  vacation: action.item.vacation,
                },
              };
            }
            return item;
          }),
        };
      }
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.EXPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('export'),
        };
      case constants.EXPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'export'),
        };
      case constants.IMPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('import'),
        };
      case constants.IMPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'import'),
        };
      case constants.IMPORT_ERROR:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'import'),
        };
      default:
        return state;
    }
  }
}

module.exports = TimeOffReducer;
