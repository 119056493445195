const { connect } = require('react-redux');
const Component = require('../components/ProjectStatus.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  projects: state.filteredProjects,
  ownership: state.ownership,
  isWaiting: state.isLoadingProjects,
  isSaving: state.savingProjectStatus,
  keyword: state.keyword,
  projectFilterId: state.projectFilterId,
  availableRisks: state.risks,
  showSidebar: !!state.focusedProjectId,
  idTour: state.idTour,
  sortBy: state.sortBy,
  isEnabledDataFreezingFeature: state.isEnabledDataFreezingFeature,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (date, ownership) => dispatch(actions.getProjects(date, ownership)),
  getInternationalCalendarSettings: () => dispatch(actions.getInternationalCalendarSettings()),
  filterProjects: (keyword) => dispatch(actions.filterProjects(keyword)),
  sortProjects: (column, order) => dispatch(actions.sortProjects(column, order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
