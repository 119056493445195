const React = require('react');

module.exports = class EmptyBoards extends React.Component {
  getClassName() {
    let name = 'project-canvas-boards__empty';
    if (this.props.canEdit) {
      name += ' project-canvas-boards__empty--editable';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()} onClick={this.props.onClick}>
        <div className="project-canvas-empty__image project-canvas-boards__empty-image">
          {this.props.image}
        </div>
        <div className="project-canvas-empty__text project-canvas-boards__empty-text">
          <div className="project-canvas-empty__text-title">{this.props.title}</div>
          <div className="project-canvas-empty__text-description">{this.props.description}</div>
          <div className="project-canvas-empty__text-action wethod-widget__empty-message-link">{this.props.action}</div>
        </div>
      </div>
    );
  }
};
