const React = require('react');
const { Provider } = require('react-redux');
const AllocationRequests = require('./components/AllocationRequests.react');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <AllocationRequests />
  </Provider>
);
