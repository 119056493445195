const { connect } = require('react-redux');
const Component = require('../../components/timesheet/TimesheetStrategy.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  strategy: state.timesheet.strategy,
  dailyModeToggle: state.timesheet.dailyModeToggle,
  isSaving: state.waitingFor.some((key) => key === 'set-timesheet-strategy'),
  isSavingDailyModeToggle: state.waitingFor.some((key) => key === 'set-timesheet-daily-mode'),
});

const mapDispatchToProps = (dispatch) => ({
  onStrategyChange: (strategy) => dispatch(actions.setTimesheetStrategy(strategy)),
  onDailyModeChange: (mode) => dispatch(actions.setTimesheetDailyMode(mode)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
