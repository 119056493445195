'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $, _, Header) {
  // Views
  var layoutView;
  var containerView;

  RuleManager.Controller = {
    showRules: function () {
      // Splash screen loading
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      var rulesDependenciesRequest = Wethod.request('get:rules:dependencies');
      var rulesRequest = Wethod.request('get:rules');
      $.when(authorizationRequest, rulesRequest, rulesDependenciesRequest)
        .done(function (authorizationResponse, rules, rulesDependencies) {
          if (!_.isUndefined(authorizationResponse)) {
            Wethod.SettingsApp.authorization = authorizationResponse;

            if (Wethod.SettingsApp.getPermission('rules_engine')) {
              // Initialize views
              layoutView = new RuleManager.RuleManagerView();
              containerView = new RuleManager.RulesContainerView({
                rules: rules,
                dependencies: rulesDependencies,
              });

              var sectionHeaderModel = new Header.SectionHeaderModel({
                current_section: 'Rules Engine',
              });
              var sectionHeaderView = new Header.SectionHeaderView({ model: sectionHeaderModel });

              layoutView.render();
              layoutView.header.show(sectionHeaderView);
              layoutView.container.show(containerView);

              // containerView.rulesArea.show(rulesArea);
            } else {
              loadingView.destroy();
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            }
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
