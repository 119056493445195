const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');

module.exports = class StartReviewModal extends React.Component {
  getModal() {
    if (!this.props.data || !this.props.data.id) {
      return (
        <div>
          Request has been sent.
          <div className="wethod-modal2__actions">
            <Button onClick={this.props.onCloseClick}>Ok</Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        Request has been sent.
        <br />
        <br />
        Do you want to
        {' '}
        <b>review the project and its team </b>
        now?
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onCloseClick}>I'll do it later</Button>
          <a
            className="wethod-button"
            href={`/#project/${this.props.data.request.project}/review/${this.props.data.id}`}>
            Yes
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal title="Project & team review" onCancelClick={this.props.onCloseClick}>
        {this.getModal()}
      </Modal>
    );
  }
};
