/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NS = require('../../NumerableSentence.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');
const Link = require('../../../../common/components/Link.react');

class StartedProjects extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = [
      <span>
        We also
        {' '}
        <span className="bold"> started working on
          {' '}
          <NS quantity={this.props.started_projects.length}>these new projects</NS>
        </span>:
      </span>,
      <span>During the week we <span className="bold">started working on</span>:</span>,
    ];
  }

  getProject(project) {
    return (
      <span key={project.project.id}>
        <Link
          href={`#project/${project.project.id}/report`}>
          {_.unescape(project.project.name)}
        </Link>
        {' '}
        (
        {_.unescape(project.client.name)}
        )
      </span>
    );
  }

  getProjects() {
    const list = this.props.started_projects;
    const projects = list.map((project) => this.getProject(project));
    return <List list={list} terminator=".">{projects}</List>;
  }

  render() {
    if (this.props.started_projects.length) {
      return (
        <Paragraph>
          {this.props.randomize(this.alternatives)}
          {' '}
          {this.getProjects()}
        </Paragraph>
      );
    }
    return null;
  }
}

module.exports = withRandomize(StartedProjects);
