const React = require('react');
const TableSearch = require('../../../../../common/react/Table2/TableSearch.react');

const TimeOffSearch = ({
  keyword, year, canEditOthers, filter,
  showExportModal, showImportModal, ...rest
}) => {
  function onExportClick() {
    showExportModal(year);
  }

  function onImportClick() {
    showImportModal(year);
  }

  const importFunction = canEditOthers ? onImportClick : null;

  return (
    <TableSearch placeholder="Search team member"
      keyword={keyword}
      filter={filter}
      onImportClick={importFunction}
      onExportClick={onExportClick}
      {...rest} />
  );
};

module.exports = TimeOffSearch;
