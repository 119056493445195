const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const Escaper = require('../../../../../../common/react/formatters/Escaper.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const DeleteFailureModal = ({ data, isSaving, onCloseClick, onArchive }) => {
  function handleArchive() {
    onArchive(data);
  }

  const projectName = data ? data.project.name : '';

  return (
    <Modal title="Project terminator" onCancelClick={onCloseClick}>
      <p>
        You cannot delete the project <b><Escaper>{projectName}</Escaper></b>,
        {' '}
        but you sure can archive it!
      </p>
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Cancel</Button>
        <Button onClick={handleArchive} disabled={!data || !data.project.id || isSaving}>
          Archive
        </Button>
      </div>
      <div className="wethod-modal2__feedback">
        <ShowIf condition={isSaving}>
          <span>Saving...</span>
        </ShowIf>
      </div>
    </Modal>
  );
};

module.exports = DeleteFailureModal;
