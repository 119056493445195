/* eslint-disable react/sort-comp,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');

class UploadButton extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = this.getConstraints();
    this.errors = [];
  }

  onUploadClick() {
    this.input.click();
  }

  size(file) {
    return file.size <= this.props.maxSize;
  }

  accepted(file) {
    for (let i = 0; i < this.props.accept.length; i++) {
      if (file.type === this.props.accept[i]) return true;
    }
    return false;
  }

  getConstraints() {
    const constraints = [];
    if (this.props.maxSize) {
      constraints.push('size');
    }
    if (this.props.accept) {
      constraints.push('accepted');
    }
    return constraints;
  }

  validate(file) {
    const errors = [];
    for (let i = 0; i < this.constraints.length; i++) {
      if (!this[this.constraints[i]](file)) errors.push(this.constraints[i]);
    }
    return errors;
  }

  isValid(file) {
    this.errors = this.validate(file);
    return this.errors.length === 0;
  }

  onFileChange(e) {
    const file = e.target.files[0];
    if (this.isValid(file)) {
      this.props.onUpload(file);
    } else if (this.props.onUploadError) {
      this.props.onUploadError(this.errors);
    }
  }

  render() {
    return (
      <div className="project-canvas__file-picker">
        <button type="button"
          className="wethod-button"
          onClick={this.onUploadClick.bind(this)}>
          Upload
        </button>
        <input ref={(input) => this.input = input}
          data-testid="file-upload"
          type="file"
          name="file"
          accept={this.props.accept ? this.props.accept.toString() : []}
          onChange={this.onFileChange.bind(this)} />
      </div>
    );
  }
}

UploadButton.propTypes = {
  /**
   * One or more unique file type specifiers describing file types to allow.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * Max allowed size, in byte.
   */
  maxSize: PropTypes.number,
  /**
   * The function to call in order to upload a file.
   *
   * @param file {Object}
   */
  onUpload: PropTypes.func.isRequired,
  /**
   * The function to call when upload fails.
   *
   * @param errors {Array}
   */
  onUploadError: PropTypes.func,
};

module.exports = UploadButton;
