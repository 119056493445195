const React = require('react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');
const TableSearch = require('../TableSearch.react');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');
const DetailRow = require('./AlertDetail.react');

module.exports = class AlertDetailsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
      search: '',
      filteredItems: this.props.selectedRow?.alerts || [],
    };
  }

  /**
   * Get the maximum height of the table, fixed to 500
   * @returns {number}
   */
  static getTableMaxHeight() {
    return 500;
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  handleBackClick() {
    this.props.back();
  }

  getList() {
    return this.state.filteredItems.map((item) => (
      <DetailRow
        key={item.id}
        item={item}
        project={this.props.selectedRow} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    if (!this.props.selectedSegment) {
      return 'Start data review from the timeline above to check on data';
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <TableSearch
        keyword={this.state.search}
        filter={this.search.bind(this)} />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  loadMore() {}

  // eslint-disable-next-line class-methods-use-this
  hasMorePages() {
    return false;
  }

  updateTableHeight() {
    this.setState({ tableHeight: AlertDetailsTable.getTableMaxHeight() });
  }

  /**
   * Filter the items based on the search keyword match with alert type and message
   * @param search
   */
  search(search) {
    // Search keyword match with alert type, message
    const filteredItems = this.props.selectedRow?.alerts
      .filter((item) => item.alert_type?.label.toLowerCase().includes(search.toLowerCase())
        || item.alert_type?.message.toLowerCase().includes(search.toLowerCase()));

    this.setState({ search, filteredItems });
  }

  render() {
    return (
      <Table columns={3} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="action" className="data-freezing-engine__table-alerts-details-column-small">
              <IconButton icon="arrow-left" border={false} onClick={this.handleBackClick.bind(this)} />
            </TableCell>
            <TableCell className="data-freezing-engine__table-alerts-details-column-large">
              Alerts Overview - {this.props.selectedRow?.name}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
