const React = require('react');
const TypeSwitch = require('./TypeSwitch.react');
const TagList = require('./TagList.react');
const LevelList = require('./LevelList.react');
const BusinessUnitList = require('./BusinessUnitList.react');
const JobTitleList = require('./JobTitleList.react');

class FilterDropDown extends React.Component {
  getBuList() {
    if (this.props.buEnabled) {
      return (
        <BusinessUnitList
          businessUnits={this.props.businessUnits}
          selectedBusinessUnits={this.props.selectedBusinessUnits}
          onSelect={this.props.onBuSelected}
          onRemove={this.props.onBuRemoved} />
      );
    }
    return null;
  }

  getJobTitleList() {
    if (this.props.jobTitles.length) {
      return (
        <JobTitleList
          jobTitles={this.props.jobTitles}
          selectedJobTitles={this.props.selectedJobTitles}
          onSelect={this.props.onJobTitleSelected}
          onRemove={this.props.onJobTitleRemoved} />
      );
    }
    return null;
  }

  render() {
    const categories = this.props.categories.map((category) => (
      <TagList
        key={category.id}
        category={category}
        selectedCategories={this.props.selectedCategories}
        onSelect={this.props.onCategorySelected}
        onRemove={this.props.onCategoryRemoved} />
    ));

    return (
      <div className="dropdown" style={{ display: this.props.visible ? 'block' : 'none' }}>
        <div className="dropdown__list">
          <TypeSwitch activeType={this.props.activeType} onSelection={this.props.onTypeSelected} />
          {this.getBuList()}
          <LevelList
            levels={this.props.levels}
            selectedLevels={this.props.selectedLevels}
            onSelect={this.props.onLevelSelected}
            onRemove={this.props.onLevelRemoved} />
          {this.getJobTitleList()}
          {categories}
        </div>
      </div>
    );
  }
}

module.exports = FilterDropDown;
