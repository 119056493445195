const React = require('react');
const NS = require('../../common/components/NumerableSentence.react');

const NumerableSentence = ({ children, quantity }) => {
  const alternatives = {
    'new opportunities__1': 'new opportunity',
    'for a total value of__1': 'valued',
    'clients were added to the pipeline for a total__1': 'client was added to the pipeline for an',
    clients__1: 'client',
    projects__1: 'project',
    'are:__1': 'is',
    hours__1: 'hour',
    them__1: 'it',
    were__1: 'was',
    'they were__1': 'it was',
    'these hours were__1': 'this hour was',
    'these new projects__1': 'this new project',
    'these projects have come to an end__1': 'this project has come to an end',
    'So we can finally archive them.__1': 'So we can finally archive it.',
    'these are the main projects that clients chose not to start (or not to start with us) and why:__1': 'this is the project that is not started an why:',
    'These opportunities are:__1': 'This opportunity is',
  };

  return <NS alternatives={alternatives} quantity={quantity}>{children}</NS>;
};

module.exports = NumerableSentence;
