const React = require('react');

const CalendarHour = ({
  project, projects, zoom, first, selectedProject, leftProject,
  topProject,
}) => {
  const type = projects[project].type ? projects[project].type
    : projects[project].job_order_category;
  const { color } = type;
  const projectName = zoom === 'hour' && first ? projects[project].name : '';

  const getClassName = () => {
    let name = 'planning-calendar__hour';
    if (selectedProject && selectedProject.id !== project) {
      name += ' planning-calendar__hour--disabled';
    }
    return name;
  };

  const style = {
    backgroundColor: color,
    borderTop: project !== topProject ? '1px solid white' : 'none',
    borderLeft: project !== leftProject ? '1px solid white' : 'none',
  };

  return (
    <div className={getClassName()} style={style}>{_.unescape(projectName)}</div>
  );
};

module.exports = CalendarHour;
