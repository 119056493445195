const constants = require('./constants');
const TimeFilterFactory = require('../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const ChargeabilityModel = require('./models/ChargeabilityModel');

const updateTimeFilter = (filter) => ({
  type: constants.UPDATE_TIME_FILTER,
  filter,
});

const getChargeabilityRequest = () => ({
  type: constants.GET_CHARGEABILITY_REQUEST,
});

const getChargeabilitySuccess = (data) => ({
  type: constants.GET_CHARGEABILITY_SUCCESS,
  levels: data.levels,
  employees: data.employees,
});

const getChargeability = (filter) => (dispatch) => {
  dispatch(getChargeabilityRequest());
  const timeFilter = new TimeFilterFactory(filter);
  const start = timeFilter.getStartDate();
  const end = timeFilter.getEndDate();
  const model = new ChargeabilityModel({ from: start, to: end });
  $.when(model.getModel())
    .done((data) => {
      dispatch(getChargeabilitySuccess(data));
    });
};

module.exports.changeFilter = (filter) => (dispatch) => {
  dispatch(updateTimeFilter(filter));
  dispatch(getChargeability(filter));
};

module.exports.filterEmployees = (keyword) => ({
  type: constants.FILTER_EMPLOYEES,
  keyword,
});

module.exports.filterEmployeesLevel = (level) => ({
  type: constants.FILTER_EMPLOYEES_LEVEL,
  level,
});

module.exports.sortEmployees = (col, order) => ({
  type: constants.SORT_EMPLOYEES,
  col,
  order,
});

module.exports.loadEmployees = (size, page) => ({
  type: constants.LOAD_EMPLOYEES,
  page,
  size,
});

module.exports.getChargeability = getChargeability;
