const React = require('react');
const Section = require('../../../../common/components/Section.react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');
const Mobile = require('../../../../../../../../../common/react/media-queries/Mobile.react');
const BrewedDate = require('../../BrewedDate.react');
const Image1 = require('../../../../../../../../../../img/illustrations/briefing_1.svg');
const Image2 = require('../../../../../../../../../../img/illustrations/briefing_2.svg');
const Image3 = require('../../../../../../../../../../img/illustrations/briefing_3.svg');
const Image4 = require('../../../../../../../../../../img/illustrations/briefing_4.svg');
const Image5 = require('../../../../../../../../../../img/illustrations/briefing_5.svg');

class Title extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = [Image1, Image2, Image3, Image4, Image5];
  }

  getLastDayOfWeek() {
    const toAdd = this.props.internationalCalendar ? 6 : 4;
    return this.props.date.clone().add(toAdd, 'days');
  }

  getImage() {
    return (
      <img className="briefing-content__intro-image"
        alt="Weekly briefing"
        src={this.props.randomize(this.alternatives)} />
    );
  }

  render() {
    return (
      <Section>
        <h2
          className="briefing-content__week-number">
          <span>Week ending on</span>
          {' '}
          {this.getLastDayOfWeek().format('MMMM D, YYYY')}
        </h2>
        <Mobile><BrewedDate date={this.props.date} timeToRead={this.props.timeToRead} /></Mobile>
        <Paragraph>{this.getImage()}</Paragraph>
      </Section>
    );
  }
}

module.exports = withRandomize(Title);
