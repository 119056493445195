const request = require('./Request');

const BudgetJobTitle = {
  create(taskId, jobTitleId) {
    return request({
      method: 'post',
      url: `/br/budget-task/${taskId}/budget-job-title`,
      data: {
        // ID of budget job title, that is the "versioned" job title related to a budget version
        budget_job_title_id: jobTitleId,
      },
    });
  },
  delete(id, taskId) {
    return request({
      method: 'delete',
      url: `/br/budget-task/${taskId}/budget-job-title/${id}`,
    });
  },
};

module.exports = BudgetJobTitle;
