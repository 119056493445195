const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ProductSidebar.react');
const actions = require('../../actions');

const getFocusedProduct = (products, focusedProductId, levels) => {
  const product = products.find((p) => p.id === focusedProductId);
  if (product) {
    return product;
  }
  return {
    external_cost: 0.00,
    markup: 0,
    price: null,
    product_levels: levels?.map((level) => ({
      days: 0,
      level,
    })),
  };
};

const mapStateToProps = (state) => ({
  item: getFocusedProduct(state.products, state.focusedItem, state.levels),
  levels: state.levels,
  isSaving: state.waitingFor.filter((key) => key === 'save-product').length > 0,
  canEdit: state.permissions.edit,
  editMode: state.editMode,
  unsavedChanges: state.unsavedChanges,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  changeSidebarEditMode: (editMode) => dispatch(actions.changeSidebarEditMode(editMode)),
  changeUnsavedChanges: (unsavedChanges) => dispatch(actions.changeUnsavedChanges(unsavedChanges)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
