const React = require('react');
const PropTypes = require('prop-types');
const Box = require('./ModalBox.react');
const Header = require('./ModalHeader.react');
const Backdrop = require('../Backdrop/Backdrop.react');
const ScaleDownTransition = require('../Transition/ScaleDownTransition/ScaleDownTransition.react');

require('./style.scss');

const Modal = ({
  children,
  title,
  onClose,
  className,
}) => (
  <div className={`wethod-modal ${className}`}>
    <Backdrop>
      <ScaleDownTransition triggered animateFirstMount>
        <Box>
          <Header onClose={onClose} title={title} />
          {children}
        </Box>
      </ScaleDownTransition>
    </Backdrop>
  </div>
);

Modal.defaultProps = {
  className: '',
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

module.exports = Modal;
