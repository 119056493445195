/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Legend extends React.Component {
  render() {
    return (
      <div className="profile-trends__item-content-legend">
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--positive">&lt; 1 day</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--neutral">1 and 2 days</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--negative">&gt; 2 days</div>
      </div>
    );
  }
};
