const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/Production.react');

const mapStateToProps = (state) => ({
  prev_year_delta_prod: state.prev_year_delta_prod,
  prev_week_production: state.prev_week_production,
  two_weeks_ago_production: state.two_weeks_ago_production,
  prev_year_prod: state.prev_year_prod,
  weekly_target: state.weekly_target,
  weekly_dynamic_target: state.weekly_dynamic_target,
  week_production: state.week_production,
});

module.exports = connect(mapStateToProps)(Component);
