'use strict';

Wethod.module('ReportApp.ProductionValue', function (ProductionValue, Wethod, Backbone, Marionette, $) {
  // production value model
  this.ProductionValueModel = Wethod.Utility.BaseModel.extend({
    bu: '',
    initialize: function (options) {
      this.year = options.year;
      this.bu = options.bu;
    },
    urlRoot: function () {
      var url = APIURL + '/report/product?year=' + this.year;
      if (this.bu !== 'all') url += '&bu=' + this.bu;
      return url;
    },
  });

  // model & collection client
  this.ClientModel = Wethod.Utility.BaseModel.extend();
  this.ClientsCollection = Wethod.Utility.BaseCollection.extend({
    model: ProductionValue.ClientModel,
    comparator: function (model) {
      // sort clients by production
      return -model.get('product_weighted');
    },
  });

  // model & collection project
  this.ProjectModel = Wethod.Utility.BaseModel.extend();
  this.ProjectsCollection = Wethod.Utility.BaseCollection.extend({
    model: ProductionValue.ClientModel,
    comparator: function (model) {
      // sort projects by production plan adjustment
      if (model.get('adjustment') !== null) {
        return model.get('adjustment');
      }
      return model.get('product_weighted') - model.get('production_plan_week_target');
    },
  });

  this.ProdProjModel = Backbone.Model.extend();
  this.ProdProjColl = Wethod.Utility.BaseCollection.extend({
    model: ProductionValue.ProdProjModel,
    comparator: function (modelA, modelB) {
      if (modelA.get('product_weighted') === modelB.get('product_weighted')) {
        return 0;
      } if (modelA.get('product_weighted') <= modelB.get('product_weighted')) {
        return 1;
      }
      return -1;
    },
  });
  this.ProdWeekModel = Backbone.Model.extend();
  this.ProdWeekColl = Wethod.Utility.BaseCollection.extend({
    model: ProductionValue.ProdWeekModel,
    comparator: function (modelA, modelB) {
      if (modelA.get('week') === modelB.get('week')) {
        return 0;
      } if (modelA.get('week') <= modelB.get('week')) {
        return -1;
      }
      return 1;
    },
    /**
     * Returns all the projects active in the given week.
     * @param week
     * @returns {{}}
     */
    getProjectsByWeek: function (week) {
      var weekProjects = {};
      this.each(function (weekModel) {
        if (weekModel.get('week') === week) {
          weekProjects = weekModel.get('projs');
        }
      });
      return weekProjects;
    },
  });
  this.ProdWeekLabelModel = Backbone.Model.extend();
  this.ProdWeekLabelColl = Backbone.Collection.extend({
    model: ProductionValue.ProdWeekLabelModel,
  });

  // production value export model
  this.ProductionValueExportModel = Wethod.Utility.BaseModel.extend({
    initialize: function (options) {
      this.date = options.date;
    },
    urlRoot: function () {
      return APIURL + '/export/product-project?date=' + this.date;
    },
  });

  var API = {
    getProductionValue: function (year, bu) {
      var reportProductModel = new ProductionValue
        .ProductionValueModel({ year: year, bu: bu.join() });
      var defer = $.Deferred();

      reportProductModel.fetch({
        success: function (model, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    exportProductionValue: function (date) {
      var exportModel = new ProductionValue.ProductionValueExportModel({ date: date });
      var defer = $.Deferred();

      exportModel.fetch({
        success: function (model, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  // model
  Wethod.reqres.setHandler('get:report:productionValue', function (year, bu) {
    return API.getProductionValue(year, bu);
  });

  Wethod.reqres.setHandler('export:productionValue', function (date) {
    return API.exportProductionValue(date);
  });
});
