const React = require('react');
const PropTypes = require('prop-types');
const Slider = require('../../../../Slider.react');
const Star = require('../../../../Rating/icons/FullStarIcon.react');

const Histogram = ({ rating, percentage }) => (
  <div className="widget-rating-overview-summary__histogram">
    <div className="widget-rating-overview-summary__histogram-value">
      <span>{rating}</span>
      <Star />
    </div>
    <Slider min={0} max={100} value={percentage} disabled />
    <div className="widget-rating-overview-summary__histogram-percentage">{percentage}%</div>
  </div>
);

Histogram.propTypes = {
  rating: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};

module.exports = Histogram;
