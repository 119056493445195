/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const List = require('../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const Item = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');

module.exports = class OptionsCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  handleDelete() {
    this.props.showDeleteConfirmModal({ review: this.props.review });
  }

  handleNewRequest() {
    this.props.showNewRequestModal({ review: this.props.review });
  }

  goToCanvas() {
    const projectId = this.props.review && this.props.review.project
      ? this.props.review.project : null;
    if (projectId) {
      return `/#project/${projectId}/canvas`;
    }
  }

  startReview() {
    const projectId = this.props.review && this.props.review.project
      ? this.props.review.project : null;

    const userId = Wethod.userInfo.get('employee_id');
    const reviews = this.props.review && this.props.review.reviews
      ? this.props.review.reviews : null;

    // search in the reviews array the review assigned to the user loggedin in order
    // to return the correct link address
    const review = reviews ? reviews.find((elem) => elem.reviewer_id === userId) : null;
    const reviewId = review ? review.id : null;

    if (projectId && reviewId) {
      return `/#project/${projectId}/review/${reviewId}`;
    }
  }

  render() {
    const authorized = Wethod.ReportApp && Wethod.ReportApp.authorization && Wethod.ReportApp.authorization.get('review');
    const reviewToBeCompleted = this.props.review.reviews.some((elem) => (Wethod.userInfo.get('employee_id') === elem.reviewer_id && !elem.submitted));

    return (
      <TableCell className="review-changelog__column-options">
        <button type="button"
          ref={(el) => this.optionButton = el}
          className="wethod-icon-button review-changelog-more-button"
          onClick={this.showMenu.bind(this)}>
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <List open={this.state.showMenu}
          onClose={this.closeMenu.bind(this)}
          anchorEl={this.optionButton}
          centered>
          {authorized && (
            <Item onClick={this.handleNewRequest.bind(this)}>Request new review</Item>)}
          {reviewToBeCompleted && (
            <Item>
              <a className="review-changelog__canvas-link" href={this.startReview()}>Start the
                review
              </a>
            </Item>
          )}
          <Item>
            <a className="review-changelog__canvas-link" href={this.goToCanvas()}>Go to project
              canvas
            </a>
          </Item>
          {authorized && (
            <Item onClick={this.handleDelete.bind(this)}><span className="review-changelog__buttons-delete">Delete review request</span></Item>)}
        </List>
      </TableCell>
    );
  }
};
