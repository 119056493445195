/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,eqeqeq */
const React = require('react');
const Level = require('./Level.react');

class LevelList extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  toggleLabel(levels) {
    return levels.length ? <div className="dropdown__title">Levels</div> : null;
  }

  isSelected(level) {
    return Boolean(this.props.selectedLevels.find((selectedLevel) => selectedLevel.id == level.id));
  }

  onClick(level) {
    if (!this.isSelected(level)) {
      this.props.onSelect(level);
    } else {
      this.props.onRemove(level);
    }
  }

  render() {
    const levels = this.props.levels.map((level) => (
      <Level
        active={this.isSelected(level)}
        key={level.id}
        level={level}
        onClick={this.onClick} />
    ));

    const style = !levels.length ? { borderBottom: 'none' } : {};
    return (
      <div style={style} className="filter__block">
        {this.toggleLabel(levels)}
        {levels}
      </div>
    );
  }
}

module.exports = LevelList;
