const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Timesheet = {
  list(options = {}, filters = {}) {
    let url = '/timesheet-logs';
    const filtersQueryString = HTTPService.buildQueryString(filters);
    const queryString = HTTPService.buildQueryString(options);
    if (queryString) {
      url += `?${queryString}`;
      if (filtersQueryString) {
        url += `&${filtersQueryString}`;
      }
    }

    return request({
      method: 'get',
      url,
    });
  },
};

module.exports = Timesheet;
