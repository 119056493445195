const React = require('react');
const DeleteEventModal = require('../containers/DeleteEventModal');

const Modal = ({ toShow }) => {
  const getModal = () => {
    switch (toShow) {
      case 'delete-event':
        return <DeleteEventModal show />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
