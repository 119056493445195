const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const InputValidator = ({
  constraints,
  children,
  updateErrors,
  customMessages,
  ...rest
}) => {
  const isCustomConstraint = (constraint) => typeof constraint !== 'string';

  const constraintNames = constraints.filter((constraint) => !isCustomConstraint(constraint));

  const required = (value) => value !== '' && value !== null && value !== undefined;

  const maxLength = (value, limit) => value.length <= limit;

  const minLength = (value, limit) => value.length >= limit;

  const max = (value, limit) => value === '' || Number(value) <= Number(limit);

  const min = (value, limit) => value === '' || Number(value) >= Number(limit);

  const minDate = (value, limit) => value === '' || moment(value).isAfter(moment(limit));

  const minMonth = (value, limit) => value === '' || moment(value).isAfter(moment(limit), 'month');

  const email = (value) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value);
  };

  /**
   * Maps constraints to available messages.
   * @type {{min: (function(*): string), max: (function(*): string),
   * minLength: (function(*): string), required: (function(): string),
   * maxLength: (function(*): string)}}
   */
  const messages = {
    required: () => 'Is required',
    maxLength: (param) => `Not more than ${param} chars`,
    minLength: (param) => `At least ${param} chars`,
    max: (param) => `Must be below ${parseInt(param) + 1}`,
    min: (param) => `Must be over ${parseInt(param) - 1}`,
    email: () => 'Must be a valid email',
    minDate: (param) => `Must be after ${moment(param).format('DD/MM/YYYY')}`,
    minMonth: (param) => `Must be after ${moment(param).format('MMM YYYY')}`,
    ...customMessages,
  };

  /**
   * Maps constraints to available validators.
   * @type {{min: (function(*, *): boolean), max: (function(*, *): boolean), minLength:
   * (function(*, *): boolean), required: (function(*=): boolean), maxLength:
   * (function(*, *): boolean)}}
   */
  const validators = {
    required,
    maxLength,
    minLength,
    max,
    min,
    email,
    minDate,
    minMonth,
  };

  const bindCustomConstraints = () => {
    const customConstraints = constraints.filter((constraint) => isCustomConstraint(constraint));
    customConstraints.forEach((constraint) => {
      constraintNames.push(constraint.name);
      validators[constraint.name] = constraint.validator;
      messages[constraint.name] = constraint.message;
    });
  };

  bindCustomConstraints();

  /**
   * Tests given value on given constraint.
   * If value is valid, null is returned.
   * An object containing constraint and related message is returned otherwise.
   * @param value
   * @param constraint {string}
   * @returns {null|{validatorName: *, message: *}}
   */
  const validate = (value, attributes, constraint) => {
    const validatorMatch = constraint.match(/(?<name>\w+):?(?<param>.*)/);
    const validatorName = validatorMatch.groups.name;
    const validatorParam = validatorMatch.groups.param;
    const isValid = validators[validatorName](value, validatorParam, attributes);
    if (!isValid) {
      return {
        validatorName,
        message: messages[validatorName](validatorParam),
      };
    }
    return null;
  };

  /**
   * Returns all the errors for the given value with the given constraints.
   * @param value
   * @returns {(null|{validatorName: *, message: *})[]}
   */
  const getErrors = (value, attributes) => constraintNames
    .map((constraint) => validate(value, attributes, constraint)) // Find error for each constraint
    .filter((error) => error !== null); // Remove "non-error" from the list

  /**
   * Use the given updateErrors function to sync errors with parent.
   * @param name
   * @param value
   * @returns {null}
   */
  const syncErrors = (name, value, attributes) => updateErrors(name, getErrors(value, attributes));

  /**
   * Returns true if a costraints with the given name is being tested.
   * @param name
   * @returns {boolean}
   */
  const hasConstraint = (name) => constraintNames.filter((contraint) => name === contraint)
    .length > 0;

  /**
   * Reset errors for the input with given name.
   * @param name
   * @return {null}
   */
  const resetErrors = (name) => updateErrors(name, []);

  return React
    .cloneElement(children, {
      validate: syncErrors,
      resetErrors,
      required: hasConstraint('required'),
      ...rest,
    });
};

InputValidator.propTypes = {
  /**
   * List of constraints to test on children value prop.
   * A constraint can be:
   * - A string equal to the validator it represents, for example "required" is related to
   * required() validator
   * - An auto-contained object representing a constraint defined by client
   *
   * Constraints can pass a parameter using colon, for example "minLength:3" is passing "3" as
   * parameter to minLength() validator.
   * */
  constraints: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    name: PropTypes.string.isRequired,
    /**
     * Function to call in order to validate the constraint
     * @param value {*} current input value
     * @param parameter {string} constraint parameter
     * @param attributes {*} additional data linked to value. Passed by children input
     * @errors errors {[]}
     */
    validator: PropTypes.func.isRequired,
    message: PropTypes.func.isRequired,
  })])).isRequired,
  /** Element that needs validation * */
  children: PropTypes.node.isRequired,
  /**
   * Function to call to update errors.
   * @param name {string}
   * @errors errors {[]}
   */
  updateErrors: PropTypes.func.isRequired,
};

module.exports = InputValidator;
