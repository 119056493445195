const React = require('react');

class FiltersButton extends React.Component {
  constructor(props) {
    super(props);
    this.toggleResetButton = this.toggleResetButton.bind(this);
  }

  toggleResetButton() {
    return this.props.hasFilters
      ? <span className="filter__clear" onClick={this.props.onClearFilters}>clear filters</span>
      : null;
  }

  render() {
    let className = 'button';
    className = this.props.active ? 'button button--active' : className;
    className = this.props.hasFilters ? 'button button--selected' : className;
    return (
      <div>
        {this.toggleResetButton()}
        <button type="button" className={className} onClick={this.props.onClick}>
          <span className="button__label">{this.props.label}</span>
          <span className="button__label--mobile">{this.props.mobileLabel}</span>
          <span className="button__icon wethod-icon-dropdown wethod-icon-dropdown--black" />
        </button>
      </div>
    );
  }
}

module.exports = FiltersButton;
