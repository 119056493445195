const { connect } = require('react-redux');
const Component = require('../components/Contacts.react');
const actions = require('../actions');

const mapStateToProps = (state) => {
  const showContactSidebar = state.sidebarToShow === 'contact';

  return {
    contacts: state.customers,
    showSidebar: showContactSidebar,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addContact: () => dispatch(actions.addContact()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
