require('./style.scss');

const React = require('react');
const { Provider } = require('react-redux');
const InvoicePlan = require('./containers/InvoicePlan');

module.exports = ({ store }) => (
  <Provider store={store}>
    <InvoicePlan />
  </Provider>
);
