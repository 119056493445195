/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');

module.exports = class TrendsItemLoading extends React.Component {
  render() {
    return (
      <div className="wethod-widget__loading">
        <Loader />
      </div>
    );
  }
};
