require('./style.scss');

const React = require('react');
const PropTypes = require('prop-types');
const Content = require('./VirtualListContent.react');
const Items = require('./VirtualListItems.react');
const Loader = require('./VirtualListLoader.react');

const SCROLL_DIRECTION_FORWARD = 'forward';
const SCROLL_DIRECTION_BACKWARD = 'backward';

class VirtualList extends React.Component {
  constructor(props) {
    super(props);

    // TODO loadMore must be adapted to DynamicVirtualList

    this.state = {
      scrollTop: 0,
      scrollDirection: SCROLL_DIRECTION_FORWARD,
    };

    this.onScroll = this.onScroll.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    if (this.props.index > 0) {
      this.ref.scrollTop = this.getScrollTo(this.props.index);
    }
    this.onVisibleItemsChange();
  }

  componentDidUpdate(prevProps) {
    const changedIndex = this.props.index !== prevProps.index;
    const contentLoaded = !this.props.loading && prevProps.loading;
    const canLoadMore = !this.props.loading && this.props.loadMore !== null
      && (this.bottomReached() || this.topReached());

    if (contentLoaded) {
      if (this.state.scrollDirection === SCROLL_DIRECTION_BACKWARD) {
        // New items has just been added on top
        const loadedItemsAmount = this.props.items.length - prevProps.items.length;
        const actualIndex = this.getFirstVisibleIndex() + loadedItemsAmount;
        this.ref.scrollTop = this.getScrollTo(actualIndex);
      }
    } else if (canLoadMore) {
      this.props.loadMore(this.state.scrollDirection);
    } else if (changedIndex) {
      this.ref.scrollTop = this.getScrollTo(this.props.index);
    } else {
      this.onVisibleItemsChange();
    }
  }

  onVisibleItemsChange() {
    if (this.props.onVisibleItemsChange) {
      this.props.onVisibleItemsChange(this.itemNodes);
    }
  }

  onScroll(e) {
    const { scrollTop } = e.target;
    this.updateScroll(scrollTop);
  }

  getScrollTo(index) {
    return this.itemHeight
      // Get only heights of items prior to the desired one
      .filter((height, heightIndex) => heightIndex < index)
      // Sum heights
      .reduce((sum, height) => sum + height);
  }

  get itemHeight() {
    return Array.isArray(this.props.itemHeight) ? this.props.itemHeight
      : new Array(this.props.items.length).fill(this.props.itemHeight);
  }

  getScrollDirection(scrollTop) {
    return scrollTop > this.state.scrollTop ? SCROLL_DIRECTION_FORWARD : SCROLL_DIRECTION_BACKWARD;
  }

  /**
   * Return the index of the first item which offset from top i greater than scroll.
   * @returns {number}
   */
  getFirstVisibleIndex() {
    let offset = 0;
    let index = null;

    for (let i = 0; i < this.itemHeight.length && index === null; i++) {
      offset += this.itemHeight[i];
      if (offset > this.state.scrollTop) {
        index = i;
      }
    }

    return index;
  }

  getLastVisibleIndex() {
    return this.getFirstVisibleIndex() + this.getVisibleItemsAmount();
  }

  getVisibleItemsAmount() {
    const additionalRows = this.props.overscan * 2;
    const firtVisibleIndex = this.getFirstVisibleIndex();
    let visibleItemsHeight = 0;
    let index = 0;

    for (let i = firtVisibleIndex; i < this.itemHeight.length && index === 0; i++) {
      visibleItemsHeight += this.itemHeight[i];
      if (visibleItemsHeight > this.height) {
        index = i + 1;
      }
    }

    return index - firtVisibleIndex + additionalRows;
  }

  getContentHeight() {
    const calculatedHeight = this.itemHeight
      .reduce((sum, height) => sum + height);

    return Math.max(this.height, calculatedHeight);
  }

  getAugmentedWithLoader(items) {
    const loader = <Loader key="loader" />;

    if (this.props.loading) {
      if (this.state.scrollDirection === SCROLL_DIRECTION_FORWARD) {
        return items.concat(loader);
      }
      if (this.state.scrollDirection === SCROLL_DIRECTION_BACKWARD) {
        return [loader].concat(items);
      }
    }
    return items;
  }

  getVisibleItems() {
    const visibleItems = this.props.items.filter((item, index) => this.isVisible(index));

    return this.getAugmentedWithLoader(visibleItems);
  }

  /**
   * Returns the amount of offset from the top that need to be applied in order to resemple scroll
   * of a real list.
   * @returns {number}
   */
  getContentOffset() {
    let offset = 0;
    const firstVisibleIndex = this.getFirstVisibleIndex();

    for (let i = 0; i < this.itemHeight.length && i < firstVisibleIndex; i++) {
      offset += this.itemHeight[i];
    }

    return offset;
  }

  get height() {
    // Height must always be > 0, otherwise setting custom index on mount does not work
    return Math.max(1, this.props.height);
  }

  get itemNodes() {
    const firstVisibleIndex = this.getFirstVisibleIndex();
    return [...this.itemsRef.children].map((node, index) => ({
      node,
      index: firstVisibleIndex + index,
    }));
  }

  setItemsRef(ref) {
    this.itemsRef = ref;
  }

  setRef(ref) {
    this.ref = ref;
  }

  topReached() {
    return this.state.scrollTop === 0;
  }

  updateScroll(scrollTop) {
    this.setState({
      scrollTop,
      scrollDirection: this.getScrollDirection(scrollTop),
    });
  }

  bottomReached() {
    const maxScroll = this.getContentHeight() - this.height;
    return this.state.scrollTop === maxScroll;
  }

  isVisible(index) {
    return (index >= this.getFirstVisibleIndex())
      && (index <= this.getLastVisibleIndex());
  }

  render() {
    return (
      <div className="wethod-virtual-list"
        data-testid="virtual-list"
        onScroll={this.onScroll}
        ref={this.setRef}
        style={{
          height: this.height,
        }}>
        <Content height={this.getContentHeight()}>
          <Items offset={this.getContentOffset()} setRef={this.setItemsRef.bind(this)}>
            {this.getVisibleItems()}
          </Items>
        </Content>
      </div>
    );
  }
}

VirtualList.defaultProps = {
  height: 1,
  index: 0,
  loading: false,
  loadMore: null,
  overscan: 0,
  onVisibleItemsChange: null,
};

VirtualList.propTypes = {
  /**
   * The height of the visible portion of the list.
   */
  height: PropTypes.number,
  /**
   * Contains the heights for all the list item. Pass a number if all items has the same height,
   * use an array of number otherwise.
   */
  itemHeight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
    .isRequired,
  /**
   * List items to virtualize and display.
   */
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  /**
   * The index of the first item to show.
   */
  index: PropTypes.number,
  /**
   * If it's waiting for new items to load.
   */
  loading: PropTypes.bool,
  /**
   * Fucntion to get more items.
   *
   * @param {number} direction The scroll direction (1 is forward, -1 is backward)
   */
  loadMore: PropTypes.func,
  /**
   * Number of rows to render above and below the visible bounds of the list.
   */
  overscan: PropTypes.number,
  /**
   * Function called when the visible items change.
   *
   * @param {items} array of HTMLELements, each item is augmented with its list index.
   */
  onVisibleItemsChange: PropTypes.func,
};

module.exports = VirtualList;
