/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const ModuleFactory = require('./ModuleFactory');
const { modules } = require('./modules.json');

module.exports = class Nav {
  constructor(userPermissions) {
    this._modules = modules.map((module) => new ModuleFactory(module, userPermissions))
      .filter((module) => module.isVisible());
    this._mobileModules = this._modules.filter((module) => module.isMobile());
    this._userPermission = userPermissions;
  }

  getModules() {
    return this._modules;
  }

  getMobileModules() {
    return this._mobileModules;
  }

  isVisible() {
    return this.getModules().length > 0;
  }

  getModule(id) {
    return this.getModules().filter((module) => module.getId() === id)[0];
  }

  getModulesWithUrl(url) {
    return this.getModules().filter((module) => module.hasUrl(url));
  }
};
