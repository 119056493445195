/* eslint-disable react/prop-types,react/prefer-stateless-function */
const React = require('react');

module.exports = class MenuTab extends React.Component {
  render() {
    return (
      <div className="app-nav-mobile-tab" style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
};
