const React = require('react');
const PropTypes = require('prop-types');
const InputLabel = require('../../inputs/InputLabel/OutlinedInputLabel/OutlinedInputLabel.react');
const InputDecorator = require('../InputDecorator.react');

require('./style.scss');

class OutlinedInputDecorator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focus: false };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onParentFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onFocus(e) {
    if (e.persist) {
      // Only SyntheticEvents need to be persisted
      e.persist();
    }
    this.setState({ focus: true }, () => this.onParentFocus(e));
  }

  onParentBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onBlur(e) {
    if (e.persist) {
      // Only SyntheticEvents need to be persisted
      e.persist();
    }
    this.setState({ focus: false }, () => this.onParentBlur(e));
  }

  getClassName() {
    let name = 'wethod-input--outlined';
    if (this.isFocused()) {
      name += ' wethod-input--focused';
    }
    return name;
  }

  getLabel() {
    if (this.props.label) {
      const minimized = this.isFocused() || this.hasPlaceholder() || this.hasAdornments()
        || !this.isEmpty();

      return (
        <InputLabel htmlFor={this.props.id} minimized={minimized}>
          {this.props.label}
        </InputLabel>
      );
    }
    return null;
  }

  getPlaceholder() {
    if (!this.props.placeholder && this.isEmpty() && this.props.readOnly) {
      return '-';
    }
    return this.props.placeholder;
  }

  getChildren() {
    return React.cloneElement(this.props.children, {
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      id: this.props.id,
      required: this.props.required,
      placeholder: this.getPlaceholder(),
      value: this.props.value,
    });
  }

  hasPlaceholder() {
    const placeholder = this.getPlaceholder();
    return placeholder && placeholder.length > 0;
  }

  hasAdornments() {
    return this.props.prefix !== undefined || this.props.suffix !== undefined;
  }

  isEmpty() {
    return this.props.value === null || String(this.props.value).length === 0;
  }

  isFocused() {
    return this.state.focus;
  }

  render() {
    return (
      <InputDecorator className={this.getClassName()}
        label={this.getLabel()}
        prefix={this.props.prefix}
        suffix={this.props.suffix}
        inputRef={this.props.inputRef}
        required={this.props.required}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        focusedByCollaborator={this.props.focusedByCollaborator}>
        {this.getChildren()}
      </InputDecorator>
    );
  }
}

OutlinedInputDecorator.defaultProps = {
  value: '',
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  label: undefined,
  onBlur: undefined,
  onFocus: undefined,
  placeholder: undefined,
  helperText: '',
  errorText: '',
  required: false,
  disabled: false,
  readOnly: false,
  inputRef: undefined,
  focusedByCollaborator: false,
};

OutlinedInputDecorator.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  placeholder: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /** Element to style as outlined * */
  children: PropTypes.node.isRequired,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  /** Text to display below children * */
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  focusedByCollaborator: PropTypes.bool,
};

module.exports = OutlinedInputDecorator;
