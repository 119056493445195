'use strict';

Wethod.module('ProductApp', function (ProductApp) {
  this.startWithParent = false;

  var API = {
    showProducts: function () {
      ProductApp.Controller.show();
    },
  };

  ProductApp.on('start', function (options) {
    API[options.foo]();
  });
});
