const { connect } = require('react-redux');
const Component = require('../components/ProjectRow.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  allowMissingCriteria: state.allowMissingProjectCriteria,
});

const mapDispatchToProps = (dispatch) => ({
  editVote: (criterionId, vote) => dispatch(actions.editProjectVote(criterionId, vote)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
