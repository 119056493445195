const request = require('./Request');

const Budget = {
  update(id, attributes) {
    return request({
      method: 'patch',
      url: `/br/budget/${id}`,
      data: attributes,
    });
  },
  getAvailableApprovers(projectId) {
    return request({
      method: 'get',
      url: `/budgetboard/whocanapprove/${projectId}`,
    });
  },
  getComparison(id, versions) {
    return request({
      method: 'get',
      url: `/budgetboard/${id}/info/compare/${versions[0]}/with/${versions[1]}`,
    });
  },
  sort(positions, budgetId) {
    return request({
      method: 'post',
      url: `/br/budget/${budgetId}/tasks/reorder`,
      data: positions,
    });
  },
  create(projectId, templateId) {
    const query = templateId ? `?template=${templateId}` : '';

    return request({
      method: 'post',
      url: `/br/project/${projectId}/budget${query}`,
    });
  },
  swap(projectId, areas) {
    return request({
      method: 'post',
      url: `/br/project/${projectId}/swap`,
      data: areas,
    });
  },
  getForSwap(projectId) {
    return request({
      method: 'get',
      url: `/br/project/${projectId}/swap/info`,
    });
  },
  getLogs(projectId) {
    return request({
      method: 'get',
      url: `/br/project/${projectId}/budget-logs`,
    });
  },
};

Budget.STATUS_DRAFT = 'DRAFT';
Budget.STATUS_SUBMITTED = 'SUBMITTED';
Budget.STATUS_APPROVED = 'APPROVED';

module.exports = Budget;
