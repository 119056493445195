const React = require('react');
const Avatar = require('../../../../../../../../../common/react/AvatarBox/Avatar.react');

module.exports = class LocationResultElement extends React.Component {
  handleClick() {
    if (!this.props.disabled) this.props.onClick(this.props.id);
  }

  getStyle() {
    let style = 'project-canvas-menu-members__result';
    if (this.props.disabled) style += ' disabled';
    return style;
  }

  render() {
    return (
      <div className={this.getStyle()} onClick={this.handleClick.bind(this)}>
        <div className="project-canvas-menu-members__pic">
          <Avatar name={`${this.props.name.substring(0, 1)} ${this.props.name.substring(1, 2)}`} pic={this.props.pic} />
        </div>
        <div className="project-canvas-menu-members__result--name">
          {this.props.name}
        </div>
      </div>
    );
  }
};
