/* eslint-disable consistent-return,react/prop-types,react/sort-comp,
 class-methods-use-this,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions */
const React = require('react');
const HowToTour = require('./HowToTour.react');

module.exports = class HelperModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { secondTab: null };
  }

  showSecondTab(id) {
    this.setState({ secondTab: id });
  }

  hideSecondTab() {
    this.setState({ secondTab: null });
  }

  getTour() {
    const url = this.props.model.get('tour_url');
    if (url) {
      return (
        <li className="helper-modal__item helper-modal__quick-link">
          <a href={url}
            data-action="goToPageTour">
            <span className="helper-modal__quick-link-icon wethod-icon-tours" />
            <span className="helper-modal__item-label">
              <span className="helper-modal__quick-link-title">Page tour</span>
              <span className="helper-modal__quick-link-subtitle">Explore it with a guided tutorial</span>
            </span>
            <span
              className="helper-modal__item-arrow wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </li>
      );
    }
  }

  getSupport() {
    const url = this.props.model.get('support_url');
    if (url) {
      return (
        <li className="helper-modal__item helper-modal__quick-link">
          <a href={url}
            target="_blank"
            rel="noreferrer"
            data-action="goToPageSupport">
            <span className="helper-modal__quick-link-icon wethod-icon-support" />
            <span className="helper-modal__item-label">
              <span className="helper-modal__quick-link-title">Support</span>
              <span className="helper-modal__quick-link-subtitle">Read page documentation</span>
            </span>
            <span className="helper-modal__item-arrow wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </li>
      );
    }
  }

  getPlayground() {
    const url = this.props.model.get('playground_url');
    if (url) {
      return (
        <li className="helper-modal__item helper-modal__quick-link">
          <a href={url}
            data-action="goToPagePlayground">
            <span className="helper-modal__quick-link-icon wethod-icon-playground" />
            <span className="helper-modal__item-label">
              <span className="helper-modal__quick-link-title">Playground</span>
              <span className="helper-modal__quick-link-subtitle">Learn by doing in a safe place</span>
            </span>
            <span className="helper-modal__item-arrow wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </li>
      );
    }
  }

  getTabsClassName() {
    let name = 'helper-modal__tabs';
    if (this.state.secondTab) {
      name += ' helper-modal__tabs--second-visible';
    }
    return name;
  }

  getHowToTours() {
    return this.props.model.get('how_to_tours').map((tour) => (
      <HowToTour key={tour.get('id')}
        {...tour.attributes}
        showSecondTab={this.showSecondTab.bind(this)} />
    ));
  }

  getCompanies() {
    const tourId = this.state.secondTab;
    if (tourId) {
      return this.props.model.getTourForOwnedCompanies(tourId).map((tour) => (
        <HowToTour
          key={tour.get('title')}
          {...tour.attributes}
          onClick={this.onHowToTourClick.bind(this)} />
      ));
    }
    return null;
  }

  getStyle() {
    const firstLevelMenuWrapHeight = Wethod.getHeaderHeight();
    return {
      height: `calc(100vh - ${firstLevelMenuWrapHeight}px)`,
      marginTop: `${firstLevelMenuWrapHeight}px`,
    };
  }

  getContainerStyle() {
    return {
      width: '360px',
      maxHeight: '100vh',
      opacity: 1,
    };
  }

  onHowToTourClick() {
  }

  render() {
    return (
      <div className="helper-modal__wrapper"
        style={this.getStyle()}>
        <div className="helper-modal__container"
          style={this.getContainerStyle()}>
          <div className="wethod-modal2__header helper-modal__header"
            data-region="header">
            <h2>Learning Center</h2>
            <div onClick={this.props.onClose}
              className="wethod-modal2__header-action wethod-icon-medium-discard wethod-icon-medium-discard--black" />
          </div>
          <div className={this.getTabsClassName()}>
            <div className="wethod-modal2__body helper-modal__body"
              data-region="body">
              <ul className="helper-modal__list">
                {this.getTour()}
                {this.getSupport()}
                {this.getPlayground()}
              </ul>
              <h2 className="helper-modal__subtitle">How to...</h2>
              <div data-region="tours">
                <ul className="helper-modal__list">
                  {this.getHowToTours()}
                </ul>
              </div>
            </div>
            <div className="wethod-modal2__body helper-modal__body helper-modal__body--second">
              <button type="button"
                className="wethod-button helper-modal__button"
                onClick={this.hideSecondTab.bind(this)}>
                <span className="wethod-icon-medium-back helper-modal__button-icon wethod-icon-medium-back--black" />
                <span>Back</span>
              </button>
              <div className="helper-modal__paragraph">
                Seems like you own more than a company, in which one do you want to continue the
                tour?
              </div>
              <div data-region="companies">
                <ul className="helper-modal__list">
                  {this.getCompanies()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
