const React = require('react');
const { Provider } = require('react-redux');
const DeveloperSettings = require('./components/DeveloperSettings.react');
const Modal = require('./containers/Modal');

require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <div>
      <DeveloperSettings />
      <Modal />
    </div>
  </Provider>
);
