const ArchivedStatus = require('./ArchivedStatus');

class ArchivedStatusList {
  constructor() {
    this.list = [
      new ArchivedStatus('false', 'Current'),
      new ArchivedStatus('true', 'Archived'),
      new ArchivedStatus('all', 'All'),
    ];
  }

  getByKey(key) {
    const found = this.list.filter((item) => item.key === key);

    return found.length ? found[0] : null;
  }

  getByName(name) {
    const found = this.list.filter((item) => item.name.toLowerCase() === name.toLowerCase());

    return found.length ? found[0] : null;
  }
}

module.exports = ArchivedStatusList;
