const React = require('react');
const PropTypes = require('prop-types');
const SelectBase = require('../Select.react');
const Button = require('./OutlinedSelectButton.react');

const OutlinedSelect = ({ children, ...rest }) => (
  <SelectBase button={Button} {...rest}>
    {children}
  </SelectBase>
);

OutlinedSelect.defaultProps = {
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
};

OutlinedSelect.propTypes = {
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
};

module.exports = OutlinedSelect;
