/* eslint-disable consistent-return,class-methods-use-this */
const React = require('react');
const Pic = require('../../../../settings/profile/components/ProfilePicPicker.react');
const TextInput = require('../../../../../../../common/react/material-input/SidebarTextInput.react');
const PhoneInput = require('../../../../../../../common/react/material-input/SidebarPhoneInput.react');
const NoteInput = require('../../../../../../../common/react/material-input/SidebarTextArea.react');
const CompanySelect = require('./CompanySearchSelectCreate.react');
const Loading = require('../../../../../../../common/react/LoadingSmall.react');

module.exports = class ContactEditableDetails extends React.Component {
  handlePhoneChange(name, value) {
    let phones = this.props.client.phones ? this.props.client.phones : {};
    phones = {
      ...phones,
      number: value,
      label: ' ',
    };
    this.props.onChange(name, phones);
  }

  handleClientCompanySave(client) {
    if (this.props.onCompanySave) {
      const formattedClient = {
        ...client,
        corporate_name: client.name,
      };
      this.props.onCompanySave(formattedClient);
    }
  }

  getPhoneNumber() {
    if (this.props.client.phones) return this.props.client.phones.number;
  }

  getCompany() {
    const company = this.props.client.client;
    if (company) {
      return company.corporate_name ? {
        ...company,
        name: company.corporate_name,
      } : company;
    }

    return company;
  }

  getCompaniesList() {
    if (this.props.isWaitingForCompanies) {
      return <Loading />;
    }
    return (
      <CompanySelect name="client"
        value={this.getCompany()}
        placeholder="Company"
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        required
        items={this.props.companies}
        onSave={this.handleClientCompanySave.bind(this)}>
        <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
      </CompanySelect>
    );
  }

  getName() {
    let name = this.props.client.name ? this.props.client.name : '';
    name = this.props.client.surname ? `${name} ${this.props.client.surname}` : name;

    return name;
  }

  // TODO: change PicPicker!
  render() {
    return (
      <div>
        <div className="wethod-sidebar__picture">
          <Pic name={this.getName()}
            editable={false} />
        </div>
        <TextInput name="name"
          value={this.props.client.name}
          placeholder="Name"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-pm" />
        </TextInput>
        <TextInput name="surname"
          value={this.props.client.surname}
          placeholder="Surname"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span />
        </TextInput>
        {this.getCompaniesList()}
        <TextInput name="email"
          value={this.props.client.email}
          placeholder="Email"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required
          email>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </TextInput>
        <PhoneInput name="phones"
          value={this.getPhoneNumber()}
          placeholder="Phone"
          onChange={this.handlePhoneChange.bind(this)}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </PhoneInput>
        <TextInput name="linkedin"
          value={this.props.client.linkedin}
          placeholder="LinkedIn"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </TextInput>
        <TextInput name="role"
          value={this.props.client.role}
          placeholder="Role"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        <NoteInput name="notes"
          value={this.props.client.notes}
          placeholder="Notes"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </NoteInput>
      </div>
    );
  }
};
