const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const StatusFilter = require('../containers/StatusFilter');
const { getPermission } = require('../services/authManager');

module.exports = class Actions extends React.Component {
  handleClick() {
    if (!this.props.isSaving && !this.props.isLoading) {
      this.props.addOrder();
    }
  }

  getAddButtonClassName() {
    let style = 'orders__add-button wethod-button';
    if (this.props.isSaving || this.props.isLoading) {
      style += ' disabled';
    }
    return style;
  }

  getAddButton() {
    if (getPermission('edit')) {
      return (
        <button type="button"
          className={this.getAddButtonClassName()}
          onClick={this.handleClick.bind(this)}>
          New Order
        </button>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-section-actions">
        <SectionHeader current_section="Orders"
          preview_anchor_id="orders"
          helper_url="finance/index/#orders"
          big />
        <div className="orders__actions">
          <StatusFilter />
          {this.getAddButton()}
        </div>
      </div>
    );
  }
};
