const { connect } = require('react-redux');
const Budget = require('../components/BudgetCreationBody.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
});

const mapDispatchToProps = (dispatch) => ({
  showCreateModal: () => dispatch(actions.showCreationModeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Budget);
