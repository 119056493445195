/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Calendar = require('./Calendar/Calendar.react');
const Items = require('./Items.react');
const ItemCreator = require('./ItemCreator.react');
const ItemSidebar = require('../Sidebar/ItemSidebar.react');
const TypeSwitcher = require('./TypeSwitcher.react');
const CalendarFactory = require('../../../models/Gantt/CalendarFactory');

module.exports = class Gantt extends React.Component {
  constructor(props) {
    super(props);

    /**
     * @type {CalendarModel}
     */
    this.calendar = new CalendarFactory('days');

    this.state = {
      days: this.calendar.points(),
      type: this.calendar.type(),
      sidebarItem: null,
    };

    this.calendarWidth = 0;
  }

  scrollLeft(value) {
    $(this.ganttEl).animate({ scrollLeft: value });
  }

  moveToNow() {
    const past = this.calendar.getPast();
    this.scrollLeft(past * this.calendar.columnWidth() - 200);
  }

  moveOn() {
    this.scrollLeft(this.ganttEl.scrollLeft
      + (this.calendar.columnsToScroll() * this.calendar.columnWidth()));
  }

  moveBack() {
    this.scrollLeft(this.ganttEl.scrollLeft
      - (this.calendar.columnsToScroll() * this.calendar.columnWidth()));
  }

  componentDidMount() {
    this.moveToNow();
  }

  componentDidUpdate(prevProps, prevState) {
    const typeChanged = prevState.type !== this.state.type;
    if (typeChanged) {
      this.moveToNow();
    }
  }

  isSavingSidebarItem() {
    return this.props.isWaiting.includes(`edit-board-${this.props.boardId}-item-${this.state.sidebarItem}-values`);
  }

  showSidebar(itemId) {
    this.setState({ sidebarItem: itemId });
  }

  handleSidebarClose() {
    this.setState({ sidebarItem: null });
  }

  getSidebarItem() {
    if (this.state.sidebarItem) {
      return this.props.items.find((item) => item.id === this.state.sidebarItem);
    }
  }

  handleItemDelete(itemId, itemName) {
    this.setState({ sidebarItem: null });
    this.props.deleteItem(itemId, itemName);
  }

  handleItemChange(itemId, itemValues) {
    this.props.editItemValues(this.props.boardId, itemId, itemValues);
  }

  addFuture() {
    const days = this.calendar.addFuture();
    this.setState({ days });
  }

  addPast() {
    const days = this.calendar.addPast();
    const added = days.length - this.state.days.length;
    this.setState({ days }, () => {
      this.ganttEl.scrollLeft = added * this.calendar.columnWidth();
    });
  }

  updateCalendarWidth(width) {
    this.calendarWidth = width;
  }

  onScroll(e) {
    const { scrollLeft } = e.target;
    const completeScrollLeft = this.calendarWidth - 1022; // real container's width = 1024 - 2 px of border
    if (scrollLeft === completeScrollLeft) {
      this.addFuture();
    } else if (scrollLeft === 0) {
      this.addPast();
    }
  }

  /**
   * Returns color to use for the given item's bars.
   * @param item
   * @return {string}
   */
  getBarsColor(item) {
    const firstStatus = this.getFirstStatusAttribute();
    const defaultColor = 'rgba(175, 181, 183,0.5)';
    return firstStatus ? item.attributes
      .filter((attribute) => attribute.attribute === firstStatus.id)[0].value.color : defaultColor;
  }

  /**
   * Returns attributes of the given type related to the given item.
   * @param type
   * @param item
   * @return {*}
   */
  getAttributesByType(type, item) {
    const structureAttributes = this.props.structure.filter((attribute) => attribute.type === type);
    return item.attributes
      .filter((attribute) => structureAttributes
        .filter((structureAttribute) => structureAttribute.id === attribute.attribute).length > 0)
      .filter((attribute) => attribute.value !== null);
  }

  /**
   * Returns items augmented with "times": a list of all the times that needs to be displayed as chart bars for the
   * given item.
   * @return {*}
   */
  getRows() {
    return this.props.items.map((item) => {
      const dateAttributes = this.getAttributesByType('date', item);
      const milestoneAttributes = this.getAttributesByType('milestone', item);

      const dates = dateAttributes
        .map(this.calendar.getDate.bind(this.calendar))
        .sort((a, b) => b.width - a.width); // Give faster times more priority;

      const milestones = milestoneAttributes
        .map(this.calendar.getMilestone.bind(this.calendar));

      return {
        ...item,
        times: dates.concat(milestones),
        color: this.getBarsColor(item),
      };
    });
  }

  /**
   * Returns first structure attribute of type "status", if exists.
   * @return {null}
   */
  getFirstStatusAttribute() {
    const statusAttributes = this.props.structure.filter((attribute) => attribute.type === 'status');
    return statusAttributes.length ? statusAttributes[0] : null;
  }

  changeType(type) {
    this.calendar = new CalendarFactory(type);
    this.setState({
      type,
      days: this.calendar.points(),
    });
  }

  getGanttClassName() {
    return `project-canvas-gantt project-canvas-gantt--${this.state.type}`;
  }

  getItemCreator() {
    if (this.props.canEdit) {
      return (
        <ItemCreator
          waiting={this.props.waitingForAddingItem}
          onAddItem={this.props.addItem}
          numItems={this.props.items.length} />
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="project-canvas-gantt__actions">
          <div className="project-canvas-gantt__type-switcher">
            <TypeSwitcher current={this.state.type} handleClick={this.changeType.bind(this)} />
          </div>
        </div>
        <div className="project-canvas-gantt-container">
          <div className={this.getGanttClassName()}
            onScroll={this.onScroll.bind(this)}
            ref={(el) => this.ganttEl = el}>
            <Calendar columns={this.calendar.points()}
              type={this.state.type}
              rows={this.getRows()}
              updateCalendarWidth={this.updateCalendarWidth.bind(this)}
              canEdit={this.props.canEdit}
              editBar={this.props.editAttributeValue}
              pointWidth={this.calendar.pointWidth()}
              pointType={this.calendar.pointType()} />
            <Items items={this.props.items}
              showSidebar={this.showSidebar.bind(this)}
              moveOn={this.moveOn.bind(this)}
              moveBack={this.moveBack.bind(this)}
              moveToNow={this.moveToNow.bind(this)} />
            {this.getItemCreator()}
          </div>
        </div>
        <CSSTransition in={!!this.state.sidebarItem}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <ItemSidebar item={this.getSidebarItem()}
            structure={this.props.structure}
            canEdit={this.props.canEdit}
            isSaving={this.isSavingSidebarItem()}
            onClose={this.handleSidebarClose.bind(this)}
            onDelete={this.handleItemDelete.bind(this)}
            onSave={this.handleItemChange.bind(this)}
            onNameSave={this.props.editItemName} />
        </CSSTransition>
      </div>
    );
  }
};
