const { connect } = require('react-redux');
const Actions = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isLoading: state.waitingFor.filter((key) => key === 'filter-orders' || key === 'load-orders' || 'get-travel-offices').length > 0,
  isSaving: state.waitingFor.filter((key) => key === 'save-order').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  addOrder: () => dispatch(actions.showSidebar(null)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Actions);
