const React = require('react');
const _ = require('underscore');
const moment = require('moment');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const IconButton = require('../../../../../../../common/react/Button/IconButton.react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const CheckBoxInput = require('../../../../../../../common/react/inputs/Checkbox/Checkbox.react');
const DateField = require('../../../../../../../common/react/inputs/DateField/BasicDateField/BasicDateField.react');
const LocationPicker = require('./LocationPicker/LocationPicker.react');

module.exports = class BodyRow extends React.Component {
  static getDateConstraint(dateFormat) {
    return {
      name: 'date_format',
      // If false, value does not validate against this constraint
      validator: (value) => {
        if (!value) return true;
        // Validate the date with moment.isValid()
        // Accept DD/MM/YYYY and DD/MM
        const regex = new RegExp('^(?:0[1-9]|[1-2][0-9]|3[01])/(?:0[1-9]|1[0-2])(?:/\\d{4})?$');
        const isValidFormat = regex.test(value);
        const isValidDate = moment(value, dateFormat).isValid();
        return isValidFormat && isValidDate;
      },
      // Message to show when validator fails, you can use name parameter here
      message: () => 'Must be a valid date',
    };
  }

  static getLocationConstraint() {
    return {
      name: 'locations',
      validator: (value) => value.length > 0,
      message: () => 'Must select at least one location',
    };
  }

  static arraysAreEqual(array1, array2) {
    const array1Length = array1?.length ?? 0;
    const array2Length = array2?.length ?? 0;

    if (array1Length !== array2Length) {
      return false; // Different number of elements
    }

    return array1.every((value, index) => value.id === array2[index].id);
  }

  constructor(props) {
    super(props);

    this.state = {
      item: {
        ...props.item,
      },
      unsavedChanges: {},

      addedLocations: props.item.locations ?? [],
    };

    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleExactDateChange = this.handleExactDateChange.bind(this);
    this.handleRepeatingDateChange = this.handleRepeatingDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  handleItemChange(name, value) {
    // if the value is the same as in the props, pop the property from the unsavedChanges
    // otherwise add it to the unsavedChanges

    const unsavedChanges = value === this.props.item[name]
      ? _.omit(this.state.unsavedChanges, name)
      : { ...this.state.unsavedChanges, [name]: value };

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
      unsavedChanges,
    }));
  }

  handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.handleItemChange(name, value);
  }

  handleExactDateChange(e) {
    const { value } = e.target;
    const exactDate = value ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

    const changes = {
      repeating_day: null,
      repeating_month: null,
      exact_date: exactDate,
    };

    this.setItemChanges(changes);
  }

  handleRepeatingDateChange(e) {
    const { value } = e.target;
    const date = value ? moment(value, 'DD/MM') : null;

    const day = date?.date();
    const month = date ? date.month() + 1 : null;

    const changes = {
      repeating_day: day,
      repeating_month: month,
      exact_date: null,
    };

    this.setItemChanges(changes);
  }

  handleCheckboxChange(e) {
    const { checked } = e.target;
    let changes;

    if (checked) {
      // The date is repeating: exact date is null, repeating day and month are set
      const previousDate = this.state.item.exact_date ? moment(this.state.item.exact_date) : null;
      const month = previousDate ? previousDate.month() + 1 : null;
      const day = previousDate ? previousDate.date() : null;

      changes = {
        exact_date: null,
        repeating: true,
        repeating_day: day,
        repeating_month: month,
      };
    } else {
      // The date is not repeating: exact date is set, repeating day and month are null
      // Get previous date from month and day, if both set
      const previousDate = this.state.item.repeating_day && this.state.item.repeating_month
        ? moment().month(this.state.item.repeating_month - 1).date(this.state.item.repeating_day)
        : null;

      changes = {
        repeating_day: null,
        repeating_month: null,
        repeating: false,
        exact_date: previousDate ? previousDate.format('YYYY-MM-DD') : null,
      };
    }

    this.setItemChanges(changes);
  }

  handleLocationChange() {
    if (!BodyRow.arraysAreEqual(this.state.addedLocations, this.props.item.locations)) {
      const changes = {
        locations: this.state.addedLocations,
      };

      this.setItemChanges(changes);
    } else {
      const unsavedChanges = _.omit(this.state.unsavedChanges, 'locations');

      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          locations: prevState.addedLocations,
        },
        unsavedChanges,
      }));
    }
  }

  handleSave() {
    return this.isNew() ? this.onSave() : this.onUpdate();
  }

  onSave() {
    this.props.onSave(this.state.item);
    this.setState({ unsavedChanges: {} });
  }

  onUpdate() {
    this.props.onUpdate(this.state.item.id, this.state.unsavedChanges);
    this.setState({ unsavedChanges: {} });
  }

  onDelete() {
    return this.isNew()
      ? this.props.deleteNewCompanyHoliday()
      : this.props.showDeleteModal(this.state.item.id);
  }

  onDeleteLocation(id) {
    this.setState((prevState) => ({
      addedLocations: prevState.addedLocations.filter((location) => location.id !== id),
    }));
  }

  onAddLocation(id) {
    this.setState((prevState) => ({
      addedLocations:
        prevState.addedLocations.concat(
          this.props.locations.find((location) => location.id === id),
        ),
    }));
  }

  setItemChanges(changes) {
    this.setState((prevState) => ({
      item: { ...prevState.item, ...changes },
      unsavedChanges: { ...prevState.unsavedChanges, ...changes },
    }));
  }

  getFeedback() {
    if (this.isSaving()) {
      return 'Saving...';
    }
    if (this.isDeleting()) {
      return 'Deleting...';
    }
    return '';
  }

  getFormattedRepeatingDate() {
    if (!this.state.item.repeating_day || !this.state.item.repeating_month) {
      return null;
    }
    const date = moment();
    date.month(this.state.item.repeating_month - 1);
    date.date(this.state.item.repeating_day);

    return date.isValid() ? date.format('DD/MM') : null;
  }

  getFormattedExactDate() {
    if (!this.state.item.exact_date) {
      return null;
    }
    const date = moment(this.state.item.exact_date);
    return date.isValid() ? date.format('DD/MM/YYYY') : null;
  }

  isNew() {
    return this.state.item.id == null || this.state.item.id === 'new-company-holiday';
  }

  /**
   * Check whether the save button should be disabled based on item validation
   * @returns {boolean|*}
   */
  isSaveDisabled() {
    return !this.props.isValid;
  }

  isSaving() {
    return this.props.waitingForSave.some((key) => key === `save-company-holiday-${this.props.item.id}`);
  }

  isDeleting() {
    return this.props.waitingForDelete.some((key) => key === `delete-company-holiday-${this.props.item.id}`);
  }

  /**
   * Check whether the item has unsaved changes
   * @returns {boolean}
   */
  hasUnsavedChanges() {
    return Object.keys(this.state.unsavedChanges).length !== 0;
  }

  render() {
    return (
      <TableRow feedback={this.getFeedback()}
        className="company-settings-company-holidays__table-row company-settings--with-transparent-input">
        <TableCell className="company-settings-company-holidays__table-cell-name">
          <InputValidator constraints={['required', 'maxLength: 255']} updateErrors={this.props.updateErrors}>
            <TextField name="name"
              label="Name"
              placeholder="Holiday Name"
              errorText={this.props.errors.name}
              value={_.unescape(this.state.item.name)}
              onChange={this.handleNameChange} />
          </InputValidator>
        </TableCell>
        <TableCell className="company-settings-company-holidays__table-cell-date">
          <ShowIf condition={this.state.item.repeating}>
            <InputValidator constraints={['required', BodyRow.getDateConstraint('DD/MM')]} updateErrors={this.props.updateErrors}>
              <DateField name="repeating_date"
                label="Repeating Date"
                placeholder="DD/MM"
                errorText={this.props.errors.repeating_date}
                datePattern={['d', 'm']}
                value={this.getFormattedRepeatingDate()}
                onBlur={this.handleRepeatingDateChange} />
            </InputValidator>
          </ShowIf>
          <ShowIf condition={!this.state.item.repeating}>
            <InputValidator constraints={['required', BodyRow.getDateConstraint('DD/MM/YYYY')]} updateErrors={this.props.updateErrors}>
              <DateField name="exact_date"
                label="Exact Date"
                placeholder="DD/MM/YYYY"
                errorText={this.props.errors.exact_date}
                value={this.getFormattedExactDate()}
                onBlur={this.handleExactDateChange} />
            </InputValidator>
          </ShowIf>
        </TableCell>
        <TableCell className="company-settings-company-holidays__table-cell-location">
          <InputValidator
            constraints={[BodyRow.getLocationConstraint()]}
            updateErrors={this.props.updateErrors}>
            <LocationPicker
              locations={this.props.locations}
              addedLocations={this.state.addedLocations}
              onChange={this.handleLocationChange.bind(this)}
              deleteLocation={this.onDeleteLocation.bind(this)}
              addLocation={this.onAddLocation.bind(this)}
              errorText={this.props.errors.locations} />
          </InputValidator>
        </TableCell>
        <TableCell className="company-settings-company-holidays__table-cell-recurring-year-toggle">
          <CheckBoxInput id={`repeating-${this.state.item.id}`}
            name="repeating"
            label=""
            value={`${this.state.item.repeating}`}
            checked={this.state.item.repeating}
            onChange={this.handleCheckboxChange} />
        </TableCell>
        <TableCell className="company-settings-company-holidays__table-cell-action">
          <ShowIf condition={this.isNew() || this.hasUnsavedChanges()}>
            <Button onClick={this.handleSave}
              disabled={this.isSaveDisabled()}
              className="company-settings__button company-settings__button--blue company-settings-margin-right-l">
              Save
            </Button>
          </ShowIf>
          <IconButton icon="delete" color="blue" size="medium" onClick={this.onDelete} className="company-settings__icon-button" />
        </TableCell>
      </TableRow>
    );
  }
};
