const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./Button.react');
const Icon = require('../Icon/Icon.react');

const IconButton = ({
  className, disabled, onClick, inputRef, label, border, margin,
  icon, size, color,
}) => {
  const getClassName = () => {
    const defaultClass = 'wethod-icon-button';
    const sizeClass = 'wethod-icon-button--common'; // Size of the button depends on the size of the icon
    const colorClass = 'wethod-icon-button--black'; // Default button color is black

    let style = `${defaultClass} ${sizeClass} ${colorClass}`;
    style = className ? `${style} ${className}` : style;
    style = border ? style : `${style} wethod-icon-button--no-border`;
    style = margin ? style : `${style} wethod-icon-button--no-margin`;

    return style;
  };

  return (
    <Button className={getClassName()}
      onClick={onClick}
      inputRef={inputRef}
      label={label}
      disabled={disabled}>
      <Icon icon={icon}
        size={size}
        color={color} />
    </Button>
  );
};

IconButton.SIZE_SMALL = 'small';
IconButton.SIZE_MEDIUM = 'medium';
IconButton.SIZE_BIG = 'big';

IconButton.propTypes = {
  /**
   * Class given to the button
   * @see Button
   */
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  /**
   * Expose button ref to parent.
   * @see Button
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  /**
   * Label used for accessibility.
   * @see Button
   */
  label: PropTypes.string,
  /**
   * Name of the icon. Make sure to give an existing name in order to render the icon correctly.
   * @see Icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * Size of the icon, from a list of available ones.
   * @see Icon
   */
  size: PropTypes.oneOf([IconButton.SIZE_SMALL, IconButton.SIZE_MEDIUM, IconButton.SIZE_BIG]),
  /**
   * Color of the icon, from a list of available ones.
   * @see Icon
   */
  color: PropTypes.oneOf(['black', 'white', 'blue', 'green', 'red']),
  /**
   * Whether to show the button with or without the border
   */
  border: PropTypes.bool,
  /**
   * Whether to show the button with or without the margin
   */
  margin: PropTypes.bool,
};

IconButton.defaultProps = {
  className: '',
  disabled: false,
  inputRef: undefined,
  label: undefined,
  size: IconButton.SIZE_BIG,
  color: undefined,
  border: true,
  margin: true,
};

module.exports = IconButton;
