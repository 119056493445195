const React = require('react');
const moment = require('moment');
const HeaderBox = require('../HeaderBox/HeaderBox.react');
const PrimarySection = require('./ProjectInfoPrimarySection.react');
const SecondarySection = require('./ProjectInfoSecondarySection.react');

const ProjectInfo = ({
  name,
  client,
  jobOrder,
  pm,
  start,
  duration,
  showProjectSidebar,
  account,
}) => {
  const formattedPm = `${pm.name} ${pm.surname}`;
  const formattedAccount = account ? `${account.name} ${account.surname}` : '-';
  const formattedDuration = duration > 1 ? `${duration} months` : `${duration} month`;
  const formattedPeriod = `${moment(start).format('MMM YY')} - ${formattedDuration}`;

  return (
    <HeaderBox className="wethod-budget-header-box--project">
      <PrimarySection name={name}
        client={client}
        jobOrder={jobOrder}
        onClick={showProjectSidebar} />
      <SecondarySection pm={formattedPm} duration={formattedPeriod} account={formattedAccount} />
    </HeaderBox>
  );
};

module.exports = ProjectInfo;
