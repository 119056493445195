/* eslint-disable react/sort-comp */
const React = require('react');

/**
 * A simple user can just take the status back to draft, a sponsor can cause all the status' transitions (draft, discussed, checked).
 */
module.exports = class QuarterStatus extends React.Component {
  constructor(props) {
    super(props);

    this.statuses = [{
      id: 0,
      label: 'Draft',
      label_next: this.props.editable ? null : 'Mark as discussed',
      label_prev: null,
    }, {
      id: 1,
      label: 'Discussed',
      label_next: this.props.editable ? null : 'Mark as checked',
      label_prev: 'Back to draft',
    }, {
      id: 2,
      label: 'Checked',
      label_next: null,
      label_prev: 'Back to draft',
    }];
  }

  getStatus(id) {
    for (let i = 0; i < this.statuses.length; i++) {
      if (this.statuses[i].id === id) {
        return this.statuses[i];
      }
    }
    return null;
  }

  isSponsor() {
    const viewerId = Wethod.userInfo.get('employee_id');

    return this.props.sponsors.filter((sponsor) => sponsor.sponsor === viewerId).length > 0;
  }

  canUseButtons() {
    return this.props.editable || this.isSponsor();
  }

  getPrev(status) {
    const label = status.label_prev;
    if (this.canUseButtons() && label) {
      return (
        <button type="button"
          onClick={this.onPrevClick.bind(this)}
          className="wethod-button profile-objectives__quarter-status-prev wethod-button--warning">
          {label}
        </button>
      );
    }
    return null;
  }

  getNext(status) {
    const label = status.label_next;
    if (this.canUseButtons() && label) {
      return (
        <button type="button"
          onClick={this.onNextClick.bind(this)}
          className="wethod-button profile-objectives__quarter-status-next">
          {label}
        </button>
      );
    }
    return null;
  }

  onNextClick() {
    if (!this.props.waiting) {
      this.props.updateStatus(this.props.status + 1, this.props.year, this.props.quarter,
        this.props.employee);
    }
  }

  onPrevClick() {
    if (!this.props.waiting) {
      const { status } = this.props;
      // If "checked" get back to draft, otherwise get back to the previous status
      const nextStatus = status === 2 ? 0 : status - 1;
      this.props.updateStatus(nextStatus, this.props.year, this.props.quarter, this.props.employee);
    }
  }

  getClassName() {
    let name = 'profile-objectives__quarter-status';
    if (this.props.waiting) {
      name += ' disabled';
    }
    return name;
  }

  render() {
    const status = this.getStatus(this.props.status);
    return (
      <div className={this.getClassName()}>
        <span className="profile-objectives__quarter-status-current">{status.label}</span>
        {this.getPrev(status)}
        {this.getNext(status)}
      </div>
    );
  }
};
