const React = require('react');
const Sidebar = require('../../../../../../common/react/sidebar/Sidebar.react');
const Body = require('../../containers/ProjectSidebar/ProjectSidebarBody');
const Actions = require('./ProjectSidebarActions.react');

// eslint-disable-next-line react/prefer-stateless-function
module.exports = class ProjectSidebar extends React.Component {
  render() {
    return (
      <Sidebar title="Project details"
        onClose={this.props.onClose}
        onCancel={this.props.onClose}
        body={<Body />}
        actions={<Actions projectId={this.props.project.id} />} />
    );
  }
};
