const React = require('react');

const SaveManager = ({
  children,
  onCreate,
  onUpdate,
  showModalChangePricelistClientDefaultWarning,
  showModalSetPricelistClientDefaultWarning,
  showModalEditPricelistWarning,
  items,
  ...rest
}) => {
  /**
   * Check whether the given item is new and not yet saved.
   * @param item
   * @returns {boolean}
   */
  function isNew(item) {
    return !item || !item.id;
  }

  function formatItemForPayload(changes) {
    const item = {};

    Object.keys(changes).forEach((key) => {
      const value = changes[key];
      let formattedValue;

      switch (key) {
        case 'price_list_levels':
          formattedValue = value.map((pricelistLevel) => ({
            id: pricelistLevel.level.id,
            price: pricelistLevel.price,
            cost: pricelistLevel.cost,
          }));
          item.levels = formattedValue;
          break;
        case 'client':
          item[key] = value ? value.id : null;
          break;
        default:
          item[key] = value;
          break;
      }
    });
    return item;
  }

  function saveItem(changes, updatedItem) {
    if (isNew(updatedItem)) {
      const formattedChanges = formatItemForPayload(updatedItem);
      onCreate(formattedChanges);
    } else {
      const { id } = updatedItem;
      const formattedChanges = formatItemForPayload(changes);
      onUpdate(id, formattedChanges);
    }
  }

  /**
   * Create or update the item
   * @param changes
   * @param updatedItem
   */
  function handleSave(changes, updatedItem) {
    const data = {
      callback: saveItem,
      callbackArgs: [changes, updatedItem],
    };
    // check if exists a pricelist into items with default_for_client = true for the same client of updatedItem and different id
    const pricelistClientDefaultForSameClient = items.find(
      (item) => item.default_for_client
        && item.client?.id === updatedItem.client?.id
        && item.id !== updatedItem.id
        && updatedItem.default_for_client,
    );

    const pricelistClientDefault = !pricelistClientDefaultForSameClient
      && updatedItem.default_for_client;

    const pricelistNonDefault = !updatedItem.default_for_client && !isNew(updatedItem);

    if (pricelistClientDefaultForSameClient) {
      showModalChangePricelistClientDefaultWarning(data);
    } else if (pricelistClientDefault) {
      showModalSetPricelistClientDefaultWarning(data);
    } else if (pricelistNonDefault) {
      showModalEditPricelistWarning(data);
    } else {
      saveItem(changes, updatedItem);
    }
  }

  return React.cloneElement(children, {
    ...rest,
    onSave: handleSave,
  });
};

module.exports = SaveManager;
