const React = require('react');
const Segment = require('./TimelineSegment.react');
const status = require('../../segmentsStatus');

const TimelineBody = ({ visibleMonths, segments, selectedSegment, selectSegment }) => {
  function isSelected(segment) {
    return segment.status === selectedSegment?.status;
  }

  /**
   * Check if segment is selectable, free segments are not selectable
   * @param segment
   * @returns {boolean}
   */
  function isSelectable(segment) {
    return segment.status !== status.FREE;
  }

  /**
   * Render timeline segments
   * The first and last segments are rendered as infinite segments
   * @returns {*}
   */
  function timelineSegments() {
    return segments.map((segment, index) => (
      <Segment key={segment.status}
        segment={{
          ...segment,
          from: index === 0 ? null : segment.from,
          to: index === segments.length - 1 ? null : segment.to,
        }}
        visibleFrom={visibleMonths[0]}
        visibleTo={visibleMonths[visibleMonths.length - 1]}
        selected={isSelected(segment)}
        selectable={isSelectable(segment)}
        onClick={selectSegment} />
    ));
  }

  return (
    <div className="segment-timeline__body">
      {timelineSegments()}
    </div>
  );
};

module.exports = TimelineBody;
