'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette, $, _) {
  this.PipelineProgramModel = Wethod.Utility.BaseModel.extend();
  this.PipelineProgramsCollection = Wethod.Utility.AutocompleteCollection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      if (!_.isUndefined(this.options.search)) {
        var params = '?search=' + encodeURIComponent(this.options.search);
        return APIURL + '/project-charter/pipeline' + params;
      }
      return APIURL + '/project-charter/pipeline';
    },
    model: PipelineApp.PipelineProgramModel,
  });

  var API = {
    getPipelinePrograms: function (options) {
      var collection = new PipelineApp.PipelineProgramsCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new PipelineApp.PipelineProgramsCollection());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('pipeline:get:program:list', function (options) {
    return API.getPipelinePrograms(options);
  });
});
