const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./TooltipFixed/IconButtonTooltip.react');

const OpenSidebarButton = ({ onClick }) => (
  <Button label="Open details" onClick={onClick} className="wethod-icon-button--no-margin" borderless>
    <span className="wethod-icon-arrow-right wethod-icon-arrow-right--black" />
  </Button>
);

OpenSidebarButton.propTypes = {
  /**
   * Function called when the button is clicked
   * @see TooltipIconButton
   */
  onClick: PropTypes.func,
};

OpenSidebarButton.defaultProps = {
  onClick: null,
};

module.exports = OpenSidebarButton;
