const React = require('react');
const DeletePersonModal = require('../containers/DeletePersonModal');
const DeleteCompanyModal = require('../containers/DeleteCompanyModal');
const DeleteCompanyFailureModal = require('../containers/DeleteCompanyFailureModal');

const Modal = ({ toShow }) => {
  const getModal = () => {
    switch (toShow) {
      case 'delete-company':
        return <DeleteCompanyModal />;
      case 'delete-company-failure':
        return <DeleteCompanyFailureModal />;
      case 'delete-person':
        return <DeletePersonModal />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
