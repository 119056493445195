const constants = require('./constants');
const SupplierModel = require('../../../../../../models/Supplier');

module.exports.showSidebar = (supplier) => ({
  type: constants.SHOW_SIDEBAR,
  item: supplier,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const formatSupplierForSaving = (supplier) => {
  const status = supplier.status ? supplier.status.id : null;
  const paymentTerm = supplier.payment_term ? supplier.payment_term.id : null;
  const country = supplier.country ? supplier.country.name : null;
  const province = supplier.province ? supplier.province.name : null;

  return {
    ...supplier,
    status,
    payment_term: paymentTerm,
    country,
    province,
  };
};

const addSupplierRequest = () => ({
  type: constants.ADD_SUPPLIER_REQUEST,
});

const addSupplierSuccess = (supplier) => ({
  type: constants.ADD_SUPPLIER_SUCCESS,
  item: supplier,
});

const addSupplierError = (message) => ({
  type: constants.ADD_SUPPLIER_ERROR,
  message,
});

module.exports.addSupplier = (supplier, force = false) => (dispatch) => {
  dispatch(addSupplierRequest());
  const supplierFormatted = formatSupplierForSaving(supplier);

  $.when(SupplierModel.create(supplierFormatted, force)).done((response) => {
    const updatedSupplier = {
      ...supplier,
      id: response.get('id'),
    };
    dispatch(addSupplierSuccess(updatedSupplier));
    dispatch(closeModal());
  }).fail((response) => {
    const { data, message } = response;

    const modalData = { supplier, duplicates: data };
    dispatch(showModal('duplicates', modalData));
    dispatch(addSupplierError(message));
  });
};

const getSuppliersSuccess = (suppliers) => ({
  type: constants.GET_SUPPLIERS_SUCCESS,
  suppliers,
});

const getSuppliersRequest = (offset, limit, search, col, order, bu) => ({
  type: constants.GET_SUPPLIERS_REQUEST,
  keyword: search,
  offset,
  limit,
  bu,
  sortBy: {
    col,
    order,
  },
});

module.exports.getSuppliers = (offset, limit, search, orderBy, sort, bu) => (dispatch) => {
  dispatch(getSuppliersRequest(offset, limit, search, orderBy, sort, bu));
  const options = {
    offset,
    limit,
    search,
    orderBy,
    sort,
    bu: bu.join(),
  };
  $.when(SupplierModel.getAll(options))
    .done((suppliers) => dispatch(getSuppliersSuccess(suppliers.toJSON())));
};

const updateSupplierRequest = () => ({
  type: constants.UPDATE_SUPPLIER_REQUEST,
});

const updateSupplierSuccess = (supplier) => ({
  type: constants.UPDATE_SUPPLIER_SUCCESS,
  item: supplier,
});

module.exports.updateSupplier = (supplier) => (dispatch) => {
  dispatch(updateSupplierRequest());
  const supplierFormatted = formatSupplierForSaving(supplier);

  $.when(SupplierModel.update(supplier.id, supplierFormatted))
    .done(() => dispatch(updateSupplierSuccess(supplier)));
};

module.exports.closeModal = closeModal;
