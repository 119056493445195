const constants = require('./constants');

class SuppliersReducer {
  constructor(state) {
    const categoriesWithTags = state.categories.map((category) => ({
      ...category,
      tags: state.tags.filter((tag) => tag.category === category.id),
    }));
    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      showSidebar: false,
      modalToShow: null,
      modalData: null,
      categoriesWithTags,
      ratings: [],
      projects: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          showSidebar: true,
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          showSidebar: false,
        };
      }
      case constants.UPDATE_SUPPLIER_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-supplier'),
        };
      }
      case constants.UPDATE_SUPPLIER_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-supplier'),
          info: { ...state.info, ...action.item },
        };
      }
      case constants.GET_RATINGS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('ratings'),
        };
      }
      case constants.GET_RATINGS_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'ratings'),
          ratings: action.ratings,
        };
      }
      case constants.SAVE_RATING_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-rating'),
        };
      }
      case constants.SAVE_RATING_SUCCESS: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-rating'),
          ratings: action.isNew ? state.ratings.concat(action.rating) : state.ratings
            .map((rating) => (rating.id === action.rating.id ? action.rating : rating)),
        };
      }
      case constants.DELETE_RATING_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-rating'),
        };
      }
      case constants.DELETE_RATING_SUCCESS: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-rating'),
          ratings: state.ratings.filter((rating) => rating.id !== action.rating.id),
        };
      }
      case constants.DELETE_SUPPLIER_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-supplier'),
        };
      }
      case constants.DELETE_SUPPLIER_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-supplier'),
        };
      }
      case constants.SHOW_DELETE_RATING_MODAL: {
        return {
          ...state,
          modalToShow: 'delete-rating',
          modalData: action.rating,
        };
      }
      case constants.SHOW_MODAL_CANNOT_MOVE: {
        return {
          ...state,
          modalToShow: 'cannot-move',
          modalData: {
            from: action.supplierFrom,
            to: action.supplierTo,
          },
        };
      }
      case constants.SHOW_MODAL_CONFIRM_DELETE: {
        return {
          ...state,
          showSidebar: false,
          modalToShow: 'confirm-delete',
          modalData: action.data,
        };
      }
      case constants.SHOW_MODAL_RATING: {
        return {
          ...state,
          showSidebar: false,
          modalToShow: 'rating',
          modalData: action.data,
        };
      }
      case constants.SHOW_MODAL_MOVE_SUPPLIER: {
        return {
          ...state,
          modalToShow: 'move-supplier',
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = SuppliersReducer;
