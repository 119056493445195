const React = require('react');
const Body = require('./BudgetBody.react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Modal = require('../containers/Modal');

module.exports = class BudgetCreationBody extends React.Component {
  componentDidMount() {
    this.props.showCreateModal();
  }

  render() {
    return (
      <Body>
        <SectionHeader
          current_section="Budget"
          preview_anchor_id="overview"
          helper_url="budget/index/"
          big
          bookmark_fragment={`project/${this.props.projectId}/budget`} />
        <Modal />
      </Body>
    );
  }
};
