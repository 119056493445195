/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const ProjectDays = require('./ProjectDays.react');

class ProjectWeek extends React.Component {
  render() {
    return (
      <div className="project__week">
        <ProjectDays
          projectId={this.props.projectId}
          days={this.props.days}
          eventTypes={this.props.eventTypes}
          eventAdded={this.props.eventAdded}
          eventDeleted={this.props.eventDeleted} />
      </div>
    );
  }
}

module.exports = ProjectWeek;
