const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../../../Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../Menu/ListMenu/MenuItem.react');

class FileActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  render() {
    return (
      <div>
        <button type="button"
          data-testid="more-options"
          ref={(el) => this.moreButtonEl = el}
          onClick={this.onMenuButtonClick.bind(this)}
          className="wethod-icon-button project-canvas-spreadsheet__row-more-button">
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <Menu open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MenuItem onClick={this.props.onDeleteClick}>Delete</MenuItem>
        </Menu>
      </div>
    );
  }
}

FileActions.propTypes = {
  /**
   * The function to call when user clicks on "Delete" button.
   */
  onDeleteClick: PropTypes.func.isRequired,
};

module.exports = FileActions;
