const React = require('react');

const Date = ({ zoom, from, to }) => {
  const formatDay = (day) => moment(day).format('ddd, MMMM DD, YYYY');

  if (zoom === 'week') {
    return (
      <div className="planning-people__allocation-details-date planning-people__allocation-details-date--week">
        <div
          className="planning-people__allocation-details-date-icon wethod-icon-calendar wethod-icon-calendar--black" />
        <div className="planning-people__allocation-details-dates">
          <div className="planning-people__allocation-details-label">
            <div className="planning-people__allocation-details-label-what">From</div>
            <div>{formatDay(from)}</div>
          </div>
          <div className="planning-people__allocation-details-label">
            <div className="planning-people__allocation-details-label-what">To</div>
            <div>{formatDay(to)}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="planning-people__allocation-details-date">
      <div
        className="planning-people__allocation-details-date-icon wethod-icon-calendar wethod-icon-calendar--black" />
      <div className="planning-people__allocation-details-dates">
        <div className="planning-people__allocation-details-label">{formatDay(from)}</div>
      </div>
    </div>
  );
};

module.exports = Date;
