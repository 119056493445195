const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/Title.react');

const mapStateToProps = (state) => ({
  month: state.month,
  target: state.monthly_revenue_target, // NULLABLE
  sales: state.prev_month_won_projects_value,
  prevMonthSales: state.prev_month_revenue,
});

module.exports = connect(mapStateToProps)(Component);
