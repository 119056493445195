/* eslint-disable react/prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const withCompare = require('../HOCs/withCompare');

const Comparator = ({ a, b, labels, compare }) => {
  const label = labels[compare(a, b)];

  return <span>{label}</span>;
};

Comparator.propTypes = {
  a: PropTypes.number.isRequired,
  b: PropTypes.number.isRequired,
  /**
   * Labels to return for the different steps.
   */
  labels: PropTypes.shape({
    much_lower: PropTypes.string.isRequired,
    lower: PropTypes.string.isRequired,
    almost_equal: PropTypes.string.isRequired,
    equal: PropTypes.string.isRequired,
    greater: PropTypes.string.isRequired,
    much_greater: PropTypes.string.isRequired,
  }).isRequired,
};

module.exports = withCompare(Comparator);
