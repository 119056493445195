const React = require('react');

const IntercompanyLabel = ({ showIntercompanyDetailsModal, projectId }) => {
  function onClick() {
    showIntercompanyDetailsModal(projectId);
  }

  return (
    <button className="pipeline-item__intercompany-label"
      type="button"
      onClick={onClick}>
      Intercompany
    </button>
  );
};

module.exports = IntercompanyLabel;
