const request = require('./Request');

const BudgetLevel = {
  update(id, isVisible) {
    return request({
      method: 'patch',
      url: `/br/budget-level/${id}`,
      data: {
        is_visible: isVisible,
      },
    });
  },
  getBudgetPriceListsForLevel(projectId, budgetVersion, levelId) {
    return request({
      method: 'get',
      url: `/project/${projectId}/budget/${budgetVersion}/price-list-levels?levelId=${levelId}`,
    });
  },
};

module.exports = BudgetLevel;
