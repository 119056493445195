const { connect } = require('react-redux');
const Component = require('../../components/modal/SendOrderModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  contacts: state.contacts,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
  sendOrder: (order, contactId) => dispatch(actions.sendOrder(order, contactId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
