/* eslint-disable class-methods-use-this,react/no-array-index-key */
const React = require('react');

module.exports = class BudgetTemplateStructure extends React.Component {
  getTasks(area) {
    return area.tasks.map((task, index) => (
      <li key={`task-${index}`}>
        {task.name}
      </li>
    ));
  }

  getAreas() {
    return this.props.areas.map((area, index) => (
      <li key={`area-${index}`}>
        <strong>{area.name}</strong>
        <ul>
          {this.getTasks(area)}
        </ul>
      </li>
    ));
  }

  render() {
    return (
      <ul className="budget-template__structure">
        {this.getAreas()}
      </ul>
    );
  }
};
