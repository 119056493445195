/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Sidebar = require('../containers/sidebar/TravelSidebar');
const TravelsTable = require('../containers/TravelList');
const Actions = require('../containers/Actions');
const Modal = require('../containers/Modal');

module.exports = class Travel extends React.Component {
  componentDidMount() {
    this.props.getTravelOffices();
  }

  render() {
    return (
      <div className="travel wethod-section-body">
        <Actions />
        <TravelsTable />
        <Modal />
        <CSSTransition in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <Sidebar />
        </CSSTransition>
      </div>
    );
  }
};
