const React = require('react');
const ToggleButton = require('../../../../../../common/react/inputs/Switch/Switch.react');

module.exports = class AlertEnablerButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.enabled !== nextProps.enabled;
  }

  handleClick() {
    this.props.toggleAlert(this.props.id);
  }

  render() {
    return (
      <ToggleButton
        id={`alert-toggle-${this.props.id}`}
        name={`${this.props.id}`}
        checked={this.props.enabled}
        onChange={this.handleClick} />
    );
  }
};
