const { connect } = require('react-redux');
const Component = require('../../components/modals/NoHoursModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  selectedProject: state.selected_project,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
  changeZoom: (zoom) => dispatch(actions.changeZoom(zoom)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
