const React = require('react');
const Day = require('./CalendarColumnDay.react');
const Week = require('./CalendarColumnWeek.react');
const Month = require('./CalendarColumnMonth.react');
const Year = require('./CalendarColumnYear.react');

module.exports = ({ type, date, ...rest }) => {
  switch (type) {
    case 'weeks':
      return <Week date={date} {...rest} />;
    case 'months':
      return <Month date={date} {...rest} />;
    case 'years':
      return <Year date={date} {...rest} />;
    default:
      return <Day date={date} {...rest} />;
  }
};
