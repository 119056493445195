const $ = require('jquery');
const constants = require('./constants');
const ExpensesModel = require('../../../../models/Expense');
const ExportModel = require('./models/ExportModel');

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

module.exports.showNotesModal = (data) => (dispatch) => {
  dispatch(showModal('notes', data));
};

module.exports.showDeleteModal = (data) => (dispatch) => {
  dispatch(showModal('confirm-delete', data));
};

module.exports.showExportModal = () => (dispatch) => {
  dispatch(showModal('export'));
};

module.exports.showCannotUploadFileModal = (data) => (dispatch) => {
  dispatch(showModal('upload-error', data));
};

module.exports.showCannotDownloadFileModal = (data) => (dispatch) => {
  dispatch(showModal('download-error', data));
};

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showSidebar = (itemId) => ({
  type: constants.SHOW_SIDEBAR,
  itemId,
});

const closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

const getExpensesRequest = (offset, limit, search, typeFilter) => ({
  type: constants.GET_EXPENSES_REQUEST,
  offset,
  limit,
  search,
  typeFilter,
});

const getExpensesSuccess = (expenses) => ({
  type: constants.GET_EXPENSES_SUCCESS,
  expenses,
});

module.exports.getExpenses = (offset, limit, search, typeFilter) => (dispatch) => {
  dispatch(getExpensesRequest(offset, limit, search, typeFilter));
  const mine = typeFilter === 'mine';

  const getListRequest = ExpensesModel.getAll(offset, limit, search, mine);
  $.when(getListRequest)
    .done((expenses) => dispatch(getExpensesSuccess(expenses)));
};

const deleteExpenseRequest = (expense) => ({
  type: constants.DELETE_EXPENSES_REQUEST,
  item: expense,
});

const deleteExpenseFailure = () => ({
  type: constants.DELETE_EXPENSES_FAILURE,
});

const deleteExpenseSuccess = (expense) => ({
  type: constants.DELETE_EXPENSES_SUCCESS,
  item: expense,
});

module.exports.deleteExpense = (expense) => (dispatch) => {
  dispatch(deleteExpenseRequest(expense));

  $.when(ExpensesModel.delete(expense.id)).done(() => {
    dispatch(deleteExpenseSuccess(expense));
    dispatch(closeSidebar());
    dispatch(closeModal());
  }).fail((message) => {
    const data = { title: 'Cannot delete expense', message };
    dispatch(deleteExpenseFailure());
    dispatch(closeSidebar());
    dispatch(showModal('server-error', data));
  });
};

const updateExpenseRequest = (id) => ({
  type: constants.UPDATE_EXPENSES_REQUEST,
  id,
});

const updateExpenseSuccess = (expense) => ({
  type: constants.UPDATE_EXPENSES_SUCCESS,
  item: expense,
});

module.exports.updateExpense = (id, changes) => (dispatch) => {
  dispatch(updateExpenseRequest(id));

  $.when(ExpensesModel.update(id, changes)).done((response) => {
    dispatch(updateExpenseSuccess(response));
    dispatch(closeSidebar());
    dispatch(closeModal());
  });
};

const createExpenseRequest = () => ({
  type: constants.CREATE_EXPENSES_REQUEST,
});

const createExpenseSuccess = (expense) => ({
  type: constants.CREATE_EXPENSES_SUCCESS,
  item: expense,
});

module.exports.createExpense = (item) => (dispatch) => {
  dispatch(createExpenseRequest());

  $.when(ExpensesModel.create(item)).done((response) => {
    dispatch(createExpenseSuccess(response));
    dispatch(closeSidebar());
    dispatch(closeModal());
  });
};

const exportModuleRequest = () => ({
  type: constants.EXPORT_REQUEST,
});

const exportModuleSuccess = () => ({
  type: constants.EXPORT_SUCCESS,
});

module.exports.exportModule = (from, to) => (dispatch) => {
  dispatch(exportModuleRequest());
  const model = new ExportModel({
    from,
    to,
  });
  $.when(model.sendExport()).done(() => {
    dispatch(exportModuleSuccess());
    dispatch(closeModal());
  });
};

module.exports.uploadFileSuccess = (item, file) => ({
  type: constants.UPLOAD_FILE_SUCCESS,
  item,
  file,
});

module.exports.deleteFileSuccess = (item, file) => ({
  type: constants.DELETE_FILE_SUCCESS,
  item,
  file,
});

module.exports.closeModal = closeModal;
module.exports.closeSidebar = closeSidebar;
