/* eslint-disable react/sort-comp,no-param-reassign,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class TrendProject extends React.Component {
  getTotal(months) {
    if (months.length) {
      return months.reduce((sum, month) => sum + month.projects, 0);
    }
    return null;
  }

  tooltipFormatter(point) {
    return `<b>${point.y}</b>`;
  }

  render() {
    return (
      <Trend
        totalInFrame={this.getTotal(this.props.months)}
        tooltipFormatter={this.tooltipFormatter}
        title="Won projects"
        className="client-trend--projects"
        getTotal={this.getTotal.bind(this)}
        {...this.props} />
    );
  }
};
