const React = require('react');
const Area = require('./Area.react');
const Header = require('./AreaHeader.react');
const TaskContainer = require('./AreaTaskContainer.react');
const Name = require('./AreaName.react');
const Levels = require('./AreaLevels.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const Price = require('../Cell/PriceCell.react');
const Cost = require('../Cell/CostCell.react');
const Accordion = require('../../../../../../../common/react/Accordion/Accordion.react');
const ExternalCosts = require('../../../containers/Calculator/Cell/ExternalCostHeaderCell');
const IconCell = require('../Cell/IconCell.react');

const BaseArea = ({
  horizontalScrollClass,
  fitContentWidth,
  column,
  name,
  levels,
  externalCosts,
  markup,
  cost,
  price,
  tasks,
  days,
  previous,
  enabled,
  horizontalScroll,
  levelsExtended,
  isCollapsed,
  onAccordionChange,
  ...props
}) => {
  if (column === 'left') {
    const header = (
      <Header>
        <Name>{name}</Name>
        <PercentageCell />
      </Header>
    );
    return (
      <Area enabled={enabled} column={column} showShadows={props.showShadows}>
        <Accordion summary={header}
          showStatusIcon={false}
          expanded={!isCollapsed}
          onChange={onAccordionChange}>
          <TaskContainer>
            {tasks}
          </TaskContainer>
        </Accordion>
      </Area>
    );
  }
  if (column === 'center') {
    const header = (
      <Header>
        <Levels
          items={levels}
          previousItems={previous.levels}
          levelsExtended={levelsExtended} />
      </Header>
    );
    return (
      <Area
        column={column}
        fitContentWidth={fitContentWidth}
        horizontalScroll={horizontalScroll}
        enabled={enabled}
        horizontalScrollClass={horizontalScrollClass}
        setScrollingComponentScrollAmount={
          props.setScrollingComponentScrollAmount
        }
        scrollingComponentAmount={props.scrollingComponentAmount}>
        <Accordion summary={header}
          showStatusIcon={false}
          expanded={!isCollapsed}
          onChange={onAccordionChange}
          overflowXHidden>
          <TaskContainer>
            {tasks}
          </TaskContainer>
        </Accordion>
      </Area>
    );
  }
  const header = (
    <Header>
      <ExternalCosts value={externalCosts} previousValue={previous.external_cost} />
      <PercentageCell>{markup}</PercentageCell>
      <DaysCell>{days}</DaysCell>
      <Cost>{cost}</Cost>
      <Price>{price}</Price>
      <IconCell />
      <MoreOptions />
    </Header>
  );
  return (
    <Area enabled={enabled} rightColumn showShadows={props.showShadows}>
      <Accordion summary={header}
        showStatusIcon={false}
        expanded={!isCollapsed}
        onChange={onAccordionChange}>
        <TaskContainer>
          {tasks}
        </TaskContainer>
      </Accordion>
    </Area>
  );
};

module.exports = BaseArea;
