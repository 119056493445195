const { connect } = require('react-redux');
const Component = require('../components/HeadcountTrend.react');

const mapStateToProps = (state) => ({
  headcount: state.headcount ? state.headcount.data.headcount : [],
  target: state.headcount ? state.headcount.target : null,
  timeFilter: state.timeFilter,
});

module.exports = connect(mapStateToProps, null)(Component);
