const React = require('react');
const EmptyRow = require('../../../../../../../../../common/react/lists/EmptyRow.react');

module.exports = () => {
  const message = (
    <span>
      No projects for this company, what about <a href="#pipeline/projects">adding one</a>?
    </span>
  );

  return <EmptyRow message={message} />;
};
