const React = require('react');
const RequestManager = require('../../../../containers/RequestManager');
const ModalContent = require('./IntercompanyDetailsModalContent.react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');

const IntercompanyDetailsModal = ({ closeModal, taskId, infoMap, intercompany }) => {
  const info = infoMap[taskId];

  return (
    <Modal title="Intercompany details" onClose={closeModal}>
      <RequestManager>
        <ModalContent
          taskId={taskId}
          info={info}
          intercompany={intercompany}
          closeModal={closeModal} />
      </RequestManager>
    </Modal>
  );
};

module.exports = IntercompanyDetailsModal;
