const { connect } = require('react-redux');
const Component = require('../components/Modal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  toShow: state.modal,
  data: state.modal_data,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
