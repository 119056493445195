'use strict';

Wethod.module('FridayApp.Timesheet', function (Timesheet, Wethod, Backbone, Marionette, $, _) {
  //--------------------
  // Utils
  //--------------------
  var fetch = function (coll) {
    var defer = $.Deferred();
    coll.fetch({
      success: function (data, response) {
        if (response.code === 200 || response.code === 404) {
          defer.resolve(data);
        } else {
          defer.resolve(undefined);
        }
      },
    });

    return defer.promise();
  };

  var save = function (model) {
    var defer = $.Deferred();
    model.save(null, {
      success: function (responseModel, response) {
        if (response.code === 200 || response.code === 201) {
          defer.resolve(responseModel);
        } else {
          defer.resolve(response.message);
        }
      },

      wait: true,
    });
    return defer.promise();
  };

  var destroy = function (model) {
    var defer = $.Deferred();
    model.destroy({
      success: function (data, response) {
        if (response.code === 200 || response.code === 404) {
          defer.resolve(data);
        } else {
          defer.resolve(undefined);
        }
      },

      error: function (responeModel, response) {
        defer.reject(response);
      },

      wait: true,
    });
    return defer.promise();
  };

  var parse = function (response) {
    var data = (response.data) ? response.data : response;
    return response.code === 404 ? [] : data;
  };

  //--------------------
  // Models
  //--------------------
  this.TimesheetModel = Backbone.Model.extend({
    urlRoot: APIURL + '/timetracking/',
    initialize: function (options) {
      // if the model is created via collection's fetch
      if (!_.isUndefined(this.collection)) {
        // eslint-disable-next-line no-underscore-dangle
        this.set('date', moment(this.collection._week).startOf('isoWeek').format('YYYY-MM-DD'));
      }
      if (options.employeeId) {
        this._id = options.employeeId;
      }
      if (options.week) {
        this._week = options.week;
        var idParam = this._id ? '&employeeId=' + this._id : '';
        this.url = APIURL + '/timetrackingboard/?date=' + this._week + idParam;
      }
    },

    validate: function (attrs) {
      var errors = [];
      if (attrs.hours > 999999) {
        errors.push('Max hours value is 999999');
      }
      return (errors.length > 0) ? errors : false;
    },

    parse: parse,
  });

  this.TimesheetDetailedModel = Backbone.Model.extend({
    initialize: function (options) {
      var idParam = '';
      if (options.employeeId) {
        this._id = options.employeeId;
        idParam = '&employeeId=' + this._id;
      }
      if (options.week) {
        this._week = options.week;
        this.url = APIURL + '/timetrackingboard/?date=' + this._week + idParam;
      }
    },
    parse: parse,
  });

  this.TimesheetDetailedColl = Backbone.Collection.extend({
    initialize: function (options) {
      this._week = options.week;
    },
    model: Timesheet.TimesheetDetailedModel,
    _week: null,
    url: function () {
      return APIURL + '/timetrackingboard/?date=' + this._week;
    },

    parse: parse,
  });

  this.FavoritetModel = Backbone.Model.extend({
    urlRoot: APIURL + '/favoritetimetracking/',
    parse: parse,
  });

  this.ModalModel = Wethod.Utility.BaseModel.extend();

  //--------------------
  // Functions
  //--------------------
  var getColl = function (week, id) {
    var timesheetModel = new Timesheet.TimesheetModel({ week: week, employeeId: id });
    return fetch(timesheetModel);
  };

  var getDetailedColl = function (week, id) {
    var timesheetModel = new Timesheet.TimesheetDetailedModel({ week: week, employeeId: id });
    return fetch(timesheetModel);
  };

  var saveTimesheet = function (model) {
    if (!(model instanceof Timesheet.TimesheetModel)) model = new Timesheet.TimesheetModel(model);
    return save(model);
  };

  var deleteTimesheet = function (model) {
    // create a deletable copy of the model so the destroy() will not remove view
    var deletableModel = new Timesheet.TimesheetModel(model);
    deletableModel.set('id', model.id);
    return destroy(deletableModel);
  };

  var saveFavorite = function (data) {
    var model = new Timesheet.FavoritetModel(data);
    return save(model);
  };

  var deleteFavorite = function (data) {
    var model = new Timesheet.FavoritetModel(data);
    return destroy(model);
  };

  //--------------------
  // Handlers
  //--------------------
  Wethod.reqres.setHandler('get:friday:timesheet:coll', function (week, id) {
    return getColl(week, id);
  });

  Wethod.reqres.setHandler('get:friday:timesheet:detailed:coll', function (week, id) {
    return getDetailedColl(week, id);
  });

  Wethod.reqres.setHandler('save:friday:timesheet', function (model) {
    return saveTimesheet(model);
  });

  Wethod.reqres.setHandler('delete:friday:timesheet', function (model) {
    return deleteTimesheet(model);
  });

  Wethod.reqres.setHandler('save:friday:timesheet:favorite', function (data) {
    return saveFavorite(data);
  });

  Wethod.reqres.setHandler('delete:friday:timesheet:favorite', function (data) {
    return deleteFavorite(data);
  });
});
