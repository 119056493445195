const React = require('react');
const Criterion = require('./Criterion.react');

module.exports = class ProjectRow extends React.Component {
  onCriterionChange(name, value, id) {
    this.props.editVote(id, parseInt(value));
  }

  getCriteria() {
    return this.props.criteria.map((criterion) => (
      <Criterion
        key={criterion.id}
        {...criterion}
        allowMissingCriteria={this.props.allowMissingCriteria}
        onChange={this.onCriterionChange.bind(this)} />
    ));
  }

  getFeedback() {
    if (!this.props.complete) {
      return (
        <span
          className="project-review__row-feedback project-review__row-feedback--negative">
          incomplete
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="project-review__step-table-row">
        <div className="project-review__step-table-row-header">
          <div className="project-review__step-table-cell project-review__step-table-cell--name">
            <div className="project-review__project-name">{_.unescape(this.props.name)}</div>
            <div className="project-review__project-client">{_.unescape(this.props.client)}</div>
            <div className="project-review__project-pm">
              PM:
              {_.unescape(this.props.pm)}
            </div>
            <div className="project-review__project-joc"
              style={{ background: this.props.job_order_category.color }} />
          </div>
          <div
            className="project-review__step-table-cell project-review__step-table-cell--feedback project-review__step-table-cell--feedback-project">
            {this.getFeedback()}
          </div>
          <div className="project-review__step-table-cell project-review__step-table-cell--note" />
        </div>
        <div className="project-review__step-table-row-content">
          {this.getCriteria()}
        </div>
      </div>
    );
  }
};
