const { connect } = require('react-redux');
const Component = require('../components/Project.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  riskSelect: state.riskSelect,
  isEnabledDataFreezingFeature: state.isEnabledDataFreezingFeature,
});

const mapDispatchToProps = (dispatch) => ({
  save: (projectStatus) => dispatch(actions.saveProjectStatus(projectStatus)),
  showProjectAreasModal: (data) => dispatch(actions.showProjectAreasModal(data)),
  showRiskSelect: (anchorEl, projectId) => dispatch(actions.showRiskSelect(anchorEl, projectId)),
  openSidebar: (item) => dispatch(actions.showSidebar(item)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
