const React = require('react');

const CalendarHeaderItemDay = ({
  day,
  zoom,
  isFirstDayOfMonth,
  last,
}) => {
  const date = moment(day);

  const getDailyLabel = () => {
    const dayNumber = date.format('DD');
    const dayInitial = date.format('dd')[0];

    return (
      <div className="planning-calendar__header-day-label">
        <div className="planning-calendar__header-day-label-name">{dayInitial}</div>
        <div className="planning-calendar__header-day-label-number">{dayNumber}</div>
      </div>
    );
  };

  const getFormattedLabel = () => {
    if (zoom === 'hour') {
      return date.format('dddd DD');
    }
    return getDailyLabel();
  };

  const isToday = () => date.isSame(moment(), 'day');

  const getClassName = () => {
    let name = 'planning-calendar__header-item';
    if (isToday()) {
      name += ' planning-calendar__header-item--today';
    }
    return name;
  };

  const getMonth = () => {
    const style = last ? {
      left: 'auto',
      right: 0,
    } : null;
    if (isFirstDayOfMonth) {
      return (
        <span className="planning-calendar__header-month"
          style={style}>{date.format('MMMM YYYY')}
        </span>
      );
    }
    return null;
  };

  return (
    <div className={getClassName()}>
      {getMonth()}
      {getFormattedLabel()}
    </div>
  );
};

module.exports = CalendarHeaderItemDay;
