/* eslint-disable camelcase */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const PV = require('../../../../common/components/PercentageValue.react');
const Comparator = require('./PlannedPercentageComparator.react');

module.exports = ({ week, planned, prev_planned }) => (
  <Paragraph>
    <span className="bold">
      Week {week + 1}
    </span>
    {' '}
    started with{' '}
    <PV value={planned} />
    {' '}
    of the time planned ahead, which is{' '}
    <Comparator a={planned} b={prev_planned} />
    {' '}
    the{' '}
    <PV value={prev_planned} />
    {' '}
    of{' '}
    <span
      className="bold">
      week {week}
    </span>
    .
  </Paragraph>
);
