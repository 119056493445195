const React = require('react');
const Avatar = require('../../../../../../../../common/react/AvatarBox/Avatar.react');

const Person = ({ person }) => {
  const getTagString = () => {
    // Get no tags, one ore two tag names
    let tagString = '';
    tagString += person.tags[0] ? person.tags[0].name : '';
    tagString += person.tags[1] ? `, ${person.tags[1].name}` : '';

    return tagString;
  };

  /**
   * Return a name formatted as "John Travolta".
   * Pass reverse = true to get something like "Travolta John"
   * @returns {string}
   */
  const getName = (reverse) => {
    const namef = _.unescape(person.name);
    const surnamef = _.unescape(person.surname);
    if (reverse) return `${surnamef} ${namef}`;
    return `${namef} ${surnamef}`;
  };

  return (
    <div className="planning-people__allocation-details-person">
      <div className="planning-people__allocation-details-person-content">
        <div className="employee__image">
          <Avatar name={getName(true)} pic={person.pic} size="medium" />
        </div>
        <div className="employee__info">
          <div className="employee__name">{getName(true)}</div>
          <div className="employee__area">{getTagString()}</div>
          <div className="employee__level">{person.level.short_name}</div>
        </div>
      </div>
    </div>
  );
};

module.exports = Person;
