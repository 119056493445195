const React = require('react');
const Loading = require('../../../../../../common/react/LoadingSmall.react');

module.exports = class YearResolution extends React.Component {
  getContent() {
    if (this.props.waiting) {
      return <Loading />;
    }
    if (this.props.resolution !== '') return <div className="profile-year-resolution__content">{this.props.resolution}</div>;
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile-year-resolution">{this.getContent()}</div>
    );
  }
};
