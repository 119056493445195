/* eslint-disable consistent-return */
const React = require('react');
const TextInput = require('../../../../../../../../common/react/material-input/SidebarTextInput.react');
const PhoneInput = require('../../../../../../../../common/react/material-input/SidebarPhoneInput.react');
const NoteInput = require('../../../../../../../../common/react/material-input/SidebarTextArea.react');
const StatusSelect = require('./StatusSelect.react');
const SidebarTags = require('../../containers/sidebar/SidebarTags');
const PaymentTermsSelect = require('./PaymentTermsSelect.react');
const SearchSelect = require('../../../../../../../../common/react/material-input/SidebarSearchSelectAddButton.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class SupplierEditableDetails extends React.Component {
  static isItaly(country) {
    const name = country ? country.name : '';
    return name.toLowerCase() === 'italy';
  }

  handleCountryChange(name, value) {
    this.props.onChange(name, value);

    if (!SupplierEditableDetails.isItaly(value) && this.props.supplier.province) { // Province needs reset
      this.props.onChange('province', null);
    }

    this.handleCountryValidate(name, []);
  }

  handleCountryValidate(name, errors) {
    if (!this.isValidCountry(this.props.supplier.country)) {
      errors.push('country');
    }
    this.props.onValidate(name, errors);
  }

  getStatus() {
    if (this.props.statusEnabled) {
      const status = this.props.supplier.status ? this.props.supplier.status : null;
      return (
        <StatusSelect
          name="status"
          placeholder="Status"
          value={status}
          className="suppliers__status--not-editable"
          items={this.props.statuses}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </StatusSelect>
      );
    }
  }

  getCountryError() {
    if (this.hasError('country')) {
      return 'This country is not valid';
    }
    return null;
  }

  hasError(name) {
    return this.props.errors.includes(name);
  }

  isValidCountry(country) {
    if (country) {
      const selectedCountryName = country.name;
      return this.props.countries.some((item) => item.name === selectedCountryName);
    }
    return true;
  }

  render() {
    return (
      <div className="suppliers__sidebar-body">
        {this.getStatus()}
        <TextInput
          name="name"
          placeholder="Name"
          value={this.props.supplier.name}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </TextInput>
        <TextInput
          name="email"
          placeholder="Email"
          value={this.props.supplier.email}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </TextInput>
        <PhoneInput
          name="telephone"
          placeholder="Telephone"
          value={this.props.supplier.telephone}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </PhoneInput>
        <SidebarTags
          name="tags"
          placeholder="Tag"
          value={this.props.supplier.tags}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </SidebarTags>
        <NoteInput
          name="notes"
          placeholder="Notes"
          value={this.props.supplier.notes}
          rows={4}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </NoteInput>
        <TextInput
          name="address"
          placeholder="Address"
          value={this.props.supplier.address}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </TextInput>
        <TextInput
          name="town"
          placeholder="Town"
          value={this.props.supplier.town}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        <div className="suppliers__sidebar--multiple-input-row">
          <div className="suppliers__sidebar--multiple-input-element">
            <TextInput name="zip"
              placeholder="ZIP"
              value={this.props.supplier.zip}
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}>
              <span />
            </TextInput>
          </div>
          <div className="suppliers__sidebar--multiple-input-element">
            <SearchSelect
              name="country"
              placeholder="Country"
              value={this.props.supplier.country}
              items={this.props.countries}
              error={this.getCountryError()}
              onChange={this.handleCountryChange.bind(this)}
              onValidate={this.handleCountryValidate.bind(this)} />
          </div>
        </div>
        <ShowIf condition={SupplierEditableDetails.isItaly(this.props.supplier.country)}>
          <div className="suppliers__province">
            <SearchSelect
              name="province"
              placeholder="Province"
              value={this.props.supplier.province}
              items={this.props.provinces}
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}
              readOnly={!this.props.canEdit}>
              <span />
            </SearchSelect>
          </div>
        </ShowIf>
        <TextInput
          name="website"
          placeholder="Website"
          value={this.props.supplier.website}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-internet wethod-icon-internet--black" />
        </TextInput>
        <TextInput
          name="linkedin"
          placeholder="Linkedin"
          value={this.props.supplier.linkedin}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </TextInput>
        <TextInput
          name="vat"
          placeholder="VAT"
          value={this.props.supplier.vat}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        <TextInput name="payment_conditions"
          placeholder="Payment conditions"
          value={this.props.supplier.payment_conditions}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        <PaymentTermsSelect
          value={this.props.supplier.payment_term}
          items={this.props.paymentTerms}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </PaymentTermsSelect>
      </div>
    );
  }
};
