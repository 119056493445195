'use strict';

Wethod.module('View.MetadataTypeFilter', function (MetadataTypeFilter, Wethod, Backbone, Marionette, $, _) {
  this.ItemView = Marionette.ItemView.extend({
    template: _.template('<a href="#"><%= name %></a>'),
    className: 'wethod-filter-select__item',
    tagName: 'li',
    triggers: {
      click: 'choose:metadata:type',
    },
  });
  this.ListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    childView: this.ItemView,
    triggers: {
      'choose:metadata:type': 'choose:metadata:type',
    },
  });
  this.SelectView = Marionette.LayoutView.extend({
    _listCollection: [],
    _listView: null,
    _metadata: null,
    template: '#buFilterSelectTemplate',
    className: 'wethod-filter-select',
    regions: {
      items: '[data-region="items"]',
    },
    ui: {
      button: 'button',
      list: '.wethod-filter-select__list',
    },
    events: {
      'click @ui.button': 'onButtonClick',
    },
    childEvents: {
      'choose:metadata:type': 'onChildChooseItem',
    },
    initialize: function (options) {
      this._metadata = options.types;
      this._threeYP = options.threeYP;
      this.model = new Backbone.Model({
        title: 'Select a Metadata Type',
        name: 'Select a metadata type',
      });
      this.model.set({
        id: 'All',
        name: 'All',
      });
      this._threeYP.metadataType = 'All';
      this._metadata.push({
        id: 'All',
        name: 'All',
      });
      this._listCollection = new Backbone.Collection(this._metadata);
      this.on('choose:metadata:type', this.saveGlobalMetadata);
    },
    saveGlobalMetadata: function (model) {
      this._threeYP.metadataType = model.get('id');
    },
    onRender: function () {
      this.showList();
    },
    showList: function () {
      var listView = new Wethod.View.MetadataTypeFilter.ListView({
        collection: this._listCollection,
      });
      this.getRegion('items').show(listView);
      this._listView = this.getRegion('items').currentView;
    },
    onButtonClick: function () {
      this.ui.list.toggle();
    },

    onChildChooseItem: function (childView, args) {
      var prevName = this.model.get('name');
      this.model.set('name', args.model.get('name'));
      this.render();
      if (args.model.get('name') !== prevName) {
        this.trigger('choose:metadata:type', args.model);
        dispatcher.trigger('choose:metadata:type', args.model);
      }
    },
  });
});
