/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const File = require('../sidebar/FileSection/File.react');

const CategoryFilesDownloadModal = ({ data, onCancelClick, downloadFile }) => {
  const travel = data ? data.travel : null;
  const category = data ? data.category : '';

  const handleDownload = (file, carrier) => {
    downloadFile(file, travel, carrier);
  };

  const getFileList = () => {
    const files = [];
    if (travel) {
      travel.travel_carriers.forEach((travelCarrier) => {
        if (travelCarrier.type === category && travelCarrier.files) {
          const fileElems = travelCarrier.files.map((file) => (
            <File key={file.id}
              file={file}
              onDownload={() => handleDownload(file, travelCarrier)}
              downloadable />
          ));
          files.push(...fileElems);
        }
      });
    }
    return files;
  };

  return (
    <Modal title={`Download ${category} files`}
      onCancelClick={onCancelClick}
      className="project-canvas-alert travel__download-modal">
      {getFileList()}
    </Modal>
  );
};

module.exports = CategoryFilesDownloadModal;
