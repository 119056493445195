/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,react/no-access-state-in-setstate */
const React = require('react');
const ArrowButton = require('./ArrowButton.react');
const ProjectDropDown = require('./ProjectDropDown.react');

/**
 * This is the project selection dropdown used in planning section. It is composed by a button and a dropdown list.
 */
class ProjectSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showList: false };
    this.buttonClick = this.buttonClick.bind(this);
    this.projectSelected = this.projectSelected.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    // Manages 'close on focus out'
    $(document).click();
  }

  buttonClick() {
    if (this.props.recurringModeEnabled) {
      this.props.showDisableRecurringModeModal();
    } else {
      this.setState({
        showList: !this.state.showList,
      });
    }
  }

  projectSelected(project) {
    this.setState({
      showList: !this.state.showList,
    });

    Wethod.navigate(`planning/people?project_id=${project.id}`, { trigger: false });
    this.props.onSelectedProject(project);
  }

  getClassName() {
    if (this.props.recurringModeEnabled) {
      return 'planning__project-select disabled';
    }
    return 'planning__project-select';
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <ArrowButton
          label="select project"
          mobileLabel="projects"
          active={this.state.showList}
          onClick={this.buttonClick} />
        <ProjectDropDown
          visible={this.state.showList}
          loading={this.props.loading}
          projects={this.props.projects}
          projectSelected={this.projectSelected} />
      </div>
    );
  }
}

module.exports = ProjectSelection;
