const { connect } = require('react-redux');
const Component = require('../../components/location/Actions.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isAddDisabled: state.locations.some((item) => item.id === 'new-location'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdd: () => dispatch(actions.addNewLocation()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
