/* eslint-disable no-case-declarations */
const constants = require('./constants');

class ClientPersonReducer {
  constructor(state) {
    this.state = {
      ...state,
      isSaving: false,
      isSavingResource: false,
      isSavingNotes: false,
      companies: state.companies.map((company) => ({
        ...company,
        name: company.corporate_name,
      })),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.UPDATE_PERSON_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.UPDATE_PERSON_SUCCESS:
        return {
          ...state,
          isSaving: false,
          person: { ...state.person, ...action.person },
        };
      case constants.PERSON_TAG_SAVE_REQUEST:
        let newPerson = {
          ...state.person,
          tags: state.person.tags.concat(action.tag),
        };
        const isInTags = (name) => {
          for (let i = 0; i < state.knownTags.length; i++) {
            if (state.knownTags[i].name === name) return true;
          }
          return false;
        };
        return {
          ...state,
          person: newPerson,
          tags: !isInTags(action.tag.name) ? state.knownTags.concat(action.tag)
            : state.knownTags.map((tag) => {
              if (tag.name === action.tag.name) {
                return {
                  ...tag,
                  count: action.tag.count,
                };
              }
              return tag;
            }),
        };
      case constants.PERSON_TAG_SAVE_SUCCESS:
        newPerson = {
          ...state.person,
          tags: state.person.tags.map((tag) => {
            if (tag.name === action.tag.name) {
              return {
                ...tag,
                id: action.tag.id,
              };
            }
            return tag;
          }),
        };
        return {
          ...state,
          person: newPerson,
          tags: state.person.tags.map((tag) => {
            if (tag.name === action.tag.name) {
              return {
                ...tag,
                id: action.tag.id,
              };
            }
            return tag;
          }),
        };
      case constants.PERSON_TAG_DELETE_REQUEST:
        newPerson = {
          ...state.person,
          tags: state.person.tags.filter((tag) => tag.name !== action.tag.name),
        };
        const newTags = state.knownTags.map((tag) => {
          if (tag.name === action.tag.name) {
            return {
              ...tag,
              count: action.tag.count,
            };
          }
          return tag;
        });
        return {
          ...state,
          person: newPerson,
          tags: newTags.filter((tag) => tag.count > 0),
        };
      case constants.RESOURCE_SAVE_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.RESOURCE_SAVE_SUCCESS:
        return {
          ...state,
          resources: state.resources.concat(action.resource),
          isSaving: false,
        };
      case constants.RESOURCE_DELETE_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.RESOURCE_DELETE_SUCCESS:
        return {
          ...state,
          resources: state.resources.filter((resource) => resource.id !== action.resource.id),
          isSaving: false,
        };
      case constants.PERSON_NOTE_SAVE_REQUEST:
        return {
          ...state,
          isSavingNotes: true,
        };
      case constants.PERSON_NOTE_SAVE_SUCCESS:
        return {
          ...state,
          person: {
            ...state.person,
            notes: action.notes,
          },
          isSavingNotes: false,
        };
      case constants.COMPANY_SAVE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.COMPANY_SAVE_SUCCESS:
        return {
          ...state,
          isSavingClient: false,
          companies: state.companies.concat({
            ...action.company,
            name: action.company.corporate_name,
          }),
        };
      case constants.PERSON_PHONE_ADD_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.PERSON_PHONE_ADD_SUCCESS:
        return {
          ...state,
          isSavingClient: false,
          phones: state.phones.concat(action.phone),
        };
      case constants.PERSON_PHONE_EDIT_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.PERSON_PHONE_EDIT_SUCCESS:
        return {
          ...state,
          isSavingClient: false,
          phones: state.phones.map((phone) => {
            if (phone.id === action.phone.id) return action.phone;
            return phone;
          }),
        };
      case constants.PERSON_PHONE_DELETE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.PERSON_PHONE_DELETE_SUCCESS:
        return {
          ...state,
          isSavingClient: false,
          phones: state.phones.filter((phone) => phone.id !== action.phone.id),
        };
      default:
        return state;
    }
  }
}

module.exports = ClientPersonReducer;
