/* eslint-disable guard-for-in,no-restricted-syntax,react/sort-comp,
 react/no-access-state-in-setstate,consistent-return */
const React = require('react');

module.exports = class Resource extends React.Component {
  getUrl() {
    if (this.props.url.indexOf('http') !== -1) return this.props.url;
    return `http://${this.props.url}`;
  }

  onDeleteClick() {
    this.props.delete(this.props.person.id, this.props);
  }

  render() {
    return (
      <li className="client-person-resource">
        <a href={this.getUrl()} className="client-person__resources-list--name wethod-list-column">
          {this.props.alias}
        </a>
        <div className="client-person__resources-list--actions wethod-list-column">
          <div
            className="clients__delete-button wethod-icon-button"
            onClick={this.onDeleteClick.bind(this)}>
            <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
          </div>
        </div>
      </li>
    );
  }
};
