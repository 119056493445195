const React = require('react');
const Filter = require('../../../../../../../common/react/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');
const ForOpportunities = require('../../../containers/ForOpportunities');

const StageFilter = ({ name, label, availableOptions, ...rest }) => (
  <ForOpportunities>
    <Filter name={name} label={label} availableOptions={availableOptions} {...rest} />
  </ForOpportunities>
);

module.exports = StageFilter;
