const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const Empty = require('../../../../../../common/react/lists/EmptyRow.react');
const Tag = require('./TagFilter.react');

const TagRow = ({ tagList, onTagClick, name }) => {
  function getTagList() {
    if (tagList && tagList.length) {
      return tagList.map((tag) => <Tag key={tag.id} tag={tag} onClick={onTagClick} />);
    }
    return (
      <Empty message={`No ${name} found`} />
    );
  }

  return (
    <TableRow className="knowledge__tags-row">
      <TableCell name="tags" className="knowledge__column-tag">
        {getTagList()}
      </TableCell>
    </TableRow>
  );
};

module.exports = TagRow;
