/* eslint-disable react/sort-comp,consistent-return,react/no-array-index-key */
const React = require('react');
const FollowUp = require('../../containers/FollowUp/FollowUpController');
const tokenize = require('../../utilities/tokenize');

module.exports = class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFollowUpForm: false,
    };

    this.typeNames = {
      timeline_call: 'Call',
      timeline_email: 'Email',
      timeline_meeting: 'Meeting',
    };
    this.iconClasses = {
      Call: 'wethod-icon-phone wethod-icon-phone--black',
      Email: 'wethod-icon-mail wethod-icon-mail--black',
      Meeting: 'wethod-icon-meeting wethod-icon-meeting--black',
    };
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    const createdBy = this.props.event.created_by;
    if (createdBy) {
      return `${createdBy.person.name} ${createdBy.person.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-past__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span
            className="timeline-event-past__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-past__creation-time">{date}</span>
    );
  }

  getLogoClass() {
    return this.iconClasses[this.typeNames[this.props.type]];
  }

  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props.event);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props.event);
  }

  showFollowUpForm() {
    this.setState({ showFollowUpForm: true });
  }

  hideFollowUp() {
    this.props.closeForm();
    this.setState({ showFollowUpForm: false });
  }

  getContentStyle() {
    const done = this.props.event.confirmed;
    if (!done) return { borderLeft: '3px solid #DB4D69' };
  }

  getProjectLink() {
    return (
      <a
        href={`#timeline/${this.props.project.id}`}>
        {this.getHighlightedContent(_.unescape(this.props.project.name))}
      </a>
    );
  }

  getClientLink() {
    return (
      <span>
        {this.getHighlightedContent(_.unescape(this.props.project.client.corporate_name))}
      </span>
    );
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    return (
      <div className="timeline-event-past" id={this.props.event.id}>
        <div className="timeline-event-past__type">
          <div className="timeline-event-past__type-logo-container">
            <div className={this.getLogoClass()} />
          </div>
          <div
            className="timeline-event-past__type-name">
            {this.getHighlightedContent(this.typeNames[this.props.type])}
          </div>
        </div>
        <div className="timeline-event-log__creation">
          {this.getProjectLink()}
          {' '}
          -
          {this.getClientLink()}
        </div>
        <div className="timeline-event-past__creation">
          {this.getCreation()}
        </div>
        <div className="timeline-event-past__content" style={this.getContentStyle()}>
          <div
            className="timeline-event-past__description">
            {this.getHighlightedContent(this.props.event.description)}
          </div>
        </div>
        <FollowUp
          showForm={this.state.showFollowUpForm}
          {...this.props}
          hideFollowUp={this.hideFollowUp.bind(this)}
          openEditForm={this.showFollowUpForm.bind(this)} />
      </div>
    );
  }
};
