const { connect } = require('react-redux');
const Component = require('../components/Project.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  visibleMonths: state.visibleMonths,
  visibleActionsProjectId: state.visibleActionsProjectId,
  year: state.year,
});

const mapDispatchToProps = (dispatch) => ({
  showContactModal: () => dispatch(actions.showCannotSaveModal()),
  saveProject: (project) => dispatch(actions.saveProject(project)),
  updateProjectDuration: (projectId, start, end) => dispatch(actions
    .updateProjectDuration(projectId, start, end)),
  showActionsDropdown: (projectId) => dispatch(actions.showProjectActionsDropdown(projectId)),
  closeActionsDropdown: () => dispatch(actions.hideProjectActionsDropdown()),
  showCannotAutoDistributePastModal: () => dispatch(actions.showCannotAutoDistributePastModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
