/* eslint-disable react/sort-comp */
const React = require('react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const Menu = require('../../../../../../../../common/react/Menu/Menu.react');
const TextMenu = require('../ItemCell/TextItemCell/TextMenu/TextMenu.react');

module.exports = class ItemNameCellView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  openMenu() {
    this.setState({ showMenu: true });
  }

  getSlidingClassName() {
    let style = 'project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding';
    if (this.props.name) {
      style += ' project-canvas-spreadsheet__cell-button-content--sliding-enabled';
    }

    return style;
  }

  render() {
    return (
      <TableCell
        key="item-name"
        className="project-canvas-spreadsheet__cell--name"
        style={{ transform: `translateX(${this.props.translateX}px)` }}>
        <div
          className={this.getSlidingClassName()}
          ref={(text) => {
            this.text = text;
          }}>
          <div
            className="project-canvas-spreadsheet__cell-button-content--text"
            style={{ backgroundColor: 'white' }}>
            {this.props.name}
          </div>
          <div className="project-canvas-spreadsheet__cell-button-content--delete">
            <button type="button" className="wethod-icon-button" onClick={this.openMenu.bind(this)}>
              <div className="wethod-icon wethod-icon-medium-expand wethod-icon-medium-expand--white" />
            </button>
          </div>
        </div>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.text}>
          <TextMenu value={this.props.name} canEdit={false} />
        </Menu>
      </TableCell>
    );
  }
};
