/* eslint-disable consistent-return */
const React = require('react');
const Info = require('../containers/Info');
const Brief = require('../containers/Brief/Brief');
const Concept = require('../containers/Concept/Concept');
const Goals = require('../containers/Goals/Goals');
const Kudos = require('../containers/Kudos/Kudos');
const Boards = require('../containers/Boards/Boards');
const Modal = require('../containers/Modal');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Files = require('../containers/Files/FileManager');

module.exports = class ProjectCanvas extends React.Component {
  getKudos() {
    if (this.props.isReviewEnabled) {
      return (
        <Kudos />
      );
    }
  }

  render() {
    return (
      <div className="project-canvas wethod-section-body">
        <SectionHeader
          current_section="Canvas"
          helper_url="pipeline/index/#project-canvas"
          bookmark_fragment={`project/${this.props.projectId}/canvas`} />
        <Info />
        <div className="project-canvas__widgets">
          <div className="project-canvas__widget-container--left">
            <Brief />
            <Concept />
            <Files />
          </div>
          <div className="project-canvas__widget-container--right">
            <Goals />
            {this.getKudos()}
          </div>
        </div>
        <Boards />
        <Modal />
      </div>
    );
  }
};
