const React = require('react');

const ProgramBar = ({ program, project }) => {
  const hasProgram = program != null && program.id != null;

  const getActions = () => (
    hasProgram
      ? (
        <span>
          <a className="pipeline__program-button" href={`/#program/${program.id}`}>{program.name}</a>
        </span>
      )
      : (
        <span>
          <a className="pipeline__program-button" href={`/#program/new/${project.id}`}>Create new/Link to program</a>
        </span>
      )
  );

  return (
    <div className="pipeline__program-bar">
      <span className="pipeline__program-title">Program charter</span>
      {getActions()}
    </div>
  );
};

module.exports = ProgramBar;
