const React = require('react');
const Radio = require('../../../../../../common/react/inputs/RadioGroup/Radio.react');
const RadioGroup = require('../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');

const AlertEngineRadio = ({ currentValue, elements, onChange, disabled }) => {
  const getRadioElements = () => (
    elements.map((element) => (
      <Radio label={element.label} value={element.value} key={_.uniqueId('alert-engine-radio')} />))
  );

  const disabledClass = disabled ? 'alerts-engine__radio-group--disabled' : '';
  const radioClass = `alerts-engine__radio-group ${disabledClass}`;

  return (
    <div className={radioClass}>
      <RadioGroup name="alert-engine-radio" onChange={onChange} value={currentValue}>
        {getRadioElements()}
      </RadioGroup>
    </div>
  );
};

module.exports = AlertEngineRadio;
