const React = require('react');
const Search = require('../../../../../../common/react/Table2/TableSearch.react');
const Loading = require('../../chargeability/components/Loading.react');
const MultipleTable = require('../../chargeability/components/MultipleTable.react');
const CriteriaTable = require('./CriteriaTable.react');
const EmployeesTable = require('./EmployeesTable.react');

module.exports = class KudosTable extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions')
      .outerHeight(true) + 24 + 56); // add padding and title height with margin
  }

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getSearch() {
    return (
      <Search
        placeholder={this.props.placeholder}
        filter={this.props.filter} />
    );
  }

  getTable() {
    if (this.props.isWaiting) {
      return <Loading />;
    }

    return (
      <MultipleTable columns={7}
        className="kudos__multiple-table"
        search={this.getSearch()}
        height={this.state.tableHeight}
        maxHeight={this.state.tableHeight}>
        <CriteriaTable criteria={this.props.criteria}
          changeCriterion={this.props.changeCriterion}
          selectedCriterion={this.props.selectedCriterion} />
        <EmployeesTable timeFilter={this.props.timeFilter}
          employees={this.props.employees}
          role={this.props.role}
          selectedCriterion={this.props.selectedCriterion}
          size={this.props.size}
          page={this.props.page}
          sortBy={this.props.sortBy}
          loadMore={this.props.loadEmployees}
          sort={this.props.sort} />
      </MultipleTable>
    );
  }

  updateTableHeight() {
    this.setState({ tableHeight: KudosTable.getTableMaxHeight() });
  }

  render() {
    return (
      <div>
        {this.getTable()}
      </div>
    );
  }
};
