/* eslint-disable react/sort-comp,no-unused-expressions,no-plusplus,class-methods-use-this,no-underscore-dangle */
const React = require('react');
const MainTravelEditableDetails = require('./categories/MainTravelEditableBody.react');
const MainTravelReadOnlyDetails = require('./categories/MainTravelReadOnlyDetails.react');
const CategoryBody = require('./CategoryBody.react');
const FormMetadataManager = require('../../../../../../common/react/FormMetadataManager/FormMetadataManager.react');

module.exports = class SidebarBody extends React.Component {
  getCategoriesErrors() {
    const errors = {};
    if (this.props.errors) {
      this.props.errors.forEach((error) => {
        const keys = error.split('-');
        if (keys && keys.length > 1) {
          errors[keys[0]]
            ? errors[keys[0]]++
            : errors[keys[0]] = 1;
        }
      });
    }
    return errors;
  }

  getCategoriesWithMissingValue() {
    const missing = {};
    if (this.props.missingValues) {
      this.props.missingValues.forEach((travel) => {
        missing[travel.type] ? missing[travel.type]++ : missing[travel.type] = 1;
      });
    }
    return missing;
  }

  getErrorByCategory(category) {
    const categoryErrors = this.props.errors.filter((error) => {
      const keys = error.split('-');
      if (keys && keys.length > 1) {
        return keys[0] === category;
      }
      return false;
    });

    const errors = {};
    categoryErrors.forEach((error) => {
      const keys = error.split('-');
      if (keys && keys.length > 2) {
        errors[keys[1]]
          ? errors[keys[1]]++
          : errors[keys[1]] = 1;
      }
    });

    return errors;
  }

  handleCategoryAdd(newItem) {
    if (this.props.travel) {
      const updatedCategories = this.props.travel.travel_carriers.concat(newItem);
      this.props.onCategoryValidate('missing');
      this.props.onChange('travel_carriers', updatedCategories);
    }
  }

  handleCategoryDelete(id) {
    if (this.props.travel) {
      const updatedCategories = this.props.travel.travel_carriers.filter((item) => item.id !== id);
      this.props.onChange('travel_carriers', updatedCategories);
      this.props.resetErrors(id);
    }
  }

  handleCategoryUpdate(updatedItem) {
    if (this.props.travel) {
      const updatedCategories = this.props.travel.travel_carriers.map((item) => {
        if (item.id === updatedItem.id) return updatedItem;
        return item;
      });
      this.props.onChange('travel_carriers', updatedCategories);
    }
  }

  getDefaultLabel(category) {
    switch (category) {
      case 'accommodation':
        return 'Accommodation';
      case 'other':
        return 'Other';
      case 'train':
        return 'Train travel';
      case 'flight':
        return 'Flight travel';
      case 'car':
        return 'Car rental';
      default:
        return category;
    }
  }

  getCategoryTravelList(category) {
    return this.props.travel && this.props.travel.travel_carriers
      ? this.props.travel.travel_carriers.filter((item) => item.type === category)
      : [];
  }

  getPage() {
    switch (this.props.page) {
      case 'main':
        if (this.props.editable) {
          return (
            <FormMetadataManager metadata={this.props.travel._fields}>
              <MainTravelEditableDetails travel={this.props.travel}
                changePage={this.props.changePage}
                onChange={this.props.onChange}
                onValidate={this.props.onValidate}
                errors={this.getCategoriesErrors()}
                missingValues={this.getCategoriesWithMissingValue()} />
            </FormMetadataManager>
          );
        }
        return (
          <MainTravelReadOnlyDetails travel={this.props.travel}
            changePage={this.props.changePage}
            missingValues={this.getCategoriesWithMissingValue()} />
        );

      default:
        return (
          <CategoryBody category={this.props.page}
            travelCarrierMetadata={this.props.travelCarrierMetadata}
            travels={this.getCategoryTravelList(this.props.page)}
            onAdd={this.handleCategoryAdd.bind(this)}
            onDelete={this.handleCategoryDelete.bind(this)}
            onUpdate={this.handleCategoryUpdate.bind(this)}
            onValidate={this.props.onCategoryValidate}
            defaultLabel={this.getDefaultLabel(this.props.page)}
            mainDestination={this.props.travel.destination}
            changePage={this.props.changePage}
            errors={this.getErrorByCategory(this.props.page)}
            editable={this.props.editable} />
        );
    }
  }

  render() {
    return (this.getPage());
  }
};
