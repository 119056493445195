'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod) {
  this.AbsoluteModel = Wethod.Utility.BaseModel.extend({
    parseToJson: function () {
      var json = {
        type: 'absolute',
        value: this.get('value'),
      };
      return json;
    },
  });
});
