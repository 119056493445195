'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#dashboardTemplate',
    regions: {
      sectionHeader: '[data-region="dashboardSectionHeader"]',
      dashboardBody: '[data-region="dashboardBody"]',
      modal: '[data-region="dashboardDialog"]',
    },
  });
});
