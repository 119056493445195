const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../modal2/Modal.react');
const Button = require('../../../Button/RoundedButton.react');

const ExportSearchModal = ({ onCloseClick, onExportClick }) => {
  function onOkClick() {
    onExportClick();
    onCloseClick();
  }

  return (
    <Modal title="Export data" onCancelClick={onCloseClick}>
      <div>
        <p>
          We will send you an email containing an export in <b>xlsx</b> format.
        </p>
        <p>
          Do you want to proceed?
        </p>
      </div>
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Cancel</Button>
        <Button onClick={onOkClick}>OK</Button>
      </div>
    </Modal>
  );
};

ExportSearchModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  /**
   * Function to call when "ok" button is clicked.
   */
  onExportClick: PropTypes.func.isRequired,
};

module.exports = ExportSearchModal;
