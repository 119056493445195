'use strict';

Wethod.module('InvoicePlanApp', function (InvoicePlanApp) {
  this.startWithParent = false;

  var API = {
    showProjectInvoicePlan: function (projectId) {
      InvoicePlanApp.Controller.show(projectId);
    },
  };

  InvoicePlanApp.on('start', function (options) {
    API[options.foo](options.idProject);
  });
});
