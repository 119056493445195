const constants = require('./constants');
const BudgetTemplateModel = require('./models/BudgetTemplateModel');
const BudgetTemplateCollection = require('./models/BudgetTemplateCollection');

Wethod.module('ReactBind', () => {
  const getTemplatesSuccess = (templates) => ({
    type: constants.GET_TEMPLATES_SUCCESS,
    items: templates,
  });

  const getTemplatesRequest = () => ({
    type: constants.GET_TEMPLATES_REQUEST,
  });

  module.exports.getTemplates = () => (dispatch) => {
    dispatch(getTemplatesRequest());

    const collection = new BudgetTemplateCollection();
    $.when(collection.getTemplates()).done((templates) => dispatch(getTemplatesSuccess(templates)));
  };

  const updateTemplateSuccess = (template) => ({
    type: constants.UPDATE_TEMPLATE_SUCCESS,
    item: template,
  });

  const updateTemplateRequest = () => ({
    type: constants.UPDATE_TEMPLATE_REQUEST,
  });

  module.exports.updateTemplate = (template) => (dispatch) => {
    dispatch(updateTemplateRequest());
    const model = new BudgetTemplateModel(template);

    $.when(model.updateTemplate()).done(() => dispatch(updateTemplateSuccess(template)));
  };

  const deleteTemplateSuccess = (template) => ({
    type: constants.DELETE_TEMPLATE_SUCCESS,
    item: template,
  });

  const deleteTemplateRequest = () => ({
    type: constants.DELETE_TEMPLATE_REQUEST,
  });

  module.exports.deleteTemplate = (template) => (dispatch) => {
    dispatch(deleteTemplateRequest());
    const model = new BudgetTemplateModel(template);

    $.when(model.deleteTemplate()).done(() => dispatch(deleteTemplateSuccess(template)));
  };

  module.exports.filterTemplates = (keyword) => ({
    type: constants.FILTER_TEMPLATES,
    keyword,
  });

  module.exports.showSidebar = (template) => ({
    type: constants.SHOW_SIDEBAR,
    item: template,
  });

  module.exports.closeSidebar = () => ({
    type: constants.CLOSE_SIDEBAR,
  });
});
