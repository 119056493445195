/* eslint-disable camelcase,no-shadow,react/jsx-no-bind */
const React = require('react');
const DaysLeftInput = require('../DaysLeftInput.react');
const ProgressInput = require('../ProgressInput.react');
const TableCell = require('../../../../../../../common/react/TableLight/TableCell.react');
const TableRow = require('../../../../../../../common/react/TableLight/TableRow.react');
const { formatByStep } = require('../../../../../../../services/FormatService');

module.exports = ({
  name, days, prev_days_left, days_left, mode, isWaiting, onChange, id, is_on,
}) => {
  const format = (value) => formatByStep(value, 1, false);

  /**
   * Check if the project has a negative progress with respect to previous project status
   * (This week days left is greater than last known days left)
   * @returns {boolean}
   */
  const hasNegativeProgress = () => {
    const currentDays = parseFloat(days_left);
    const prevDays = prev_days_left;

    if (Number.isNaN(currentDays) || prevDays == null) {
      return false;
    }

    return currentDays > prevDays;
  };

  const getProgressValue = (daysLeft, budgetDays) => {
    if (daysLeft === null || Number.isNaN(daysLeft) || budgetDays === 0) {
      return null;
    }
    return Math.round(((budgetDays - daysLeft) / budgetDays) * 1000) / 10;
  };

  const onChangeDaysLeft = (name, daysLeft) => {
    const formatted = (daysLeft === '' || Number.isNaN(daysLeft)) ? null : daysLeft;
    onChange(id, formatted);
  };

  const onChangeProgress = (name, progress) => {
    const progressParsed = progress;
    let daysLeft = null;
    if (progress !== null) {
      const budgetDays = days;
      daysLeft = budgetDays - ((progressParsed / 100) * budgetDays);
    }
    onChange(id, daysLeft);
  };

  /**
   * Return last available progress, formatted as days left
   * @returns {string}
   */
  const getLastDaysLeft = () => {
    const value = prev_days_left;
    if (value != null) {
      return format(value);
    }
    return '-';
  };

  const getDaysLeft = () => {
    if (mode === 'days-left' && days !== 0) {
      return (
        <DaysLeftInput
          value={days_left}
          lastWeekValue={getLastDaysLeft()}
          negativeProgress={hasNegativeProgress()}
          onChange={onChangeDaysLeft}
          disabled={isWaiting}
          desktop />
      );
    }
    if (days_left !== null && days !== null) {
      return numeral(days_left).format('0,0.[00]');
    }
    return null;
  };

  /**
   * Return the last available progress, formatted as percentage
   * @returns {string}
   */
  const getLastWeekProgressValue = () => {
    const daysLeft = prev_days_left;
    const budgetDays = days;
    if (daysLeft === null || budgetDays === 0) {
      return '-';
    }
    const progress = ((budgetDays - daysLeft) / budgetDays) * 100;
    return `${format(progress)} %`;
  };

  const getProgress = () => {
    const progress = getProgressValue(days_left, days);
    const budgetDays = days;
    if (mode === 'progress' && budgetDays !== 0) {
      return (
        <ProgressInput
          value={progress}
          lastWeekValue={getLastWeekProgressValue()}
          budgetDays={budgetDays}
          negativeProgress={hasNegativeProgress()}
          onChange={onChangeProgress}
          disabled={isWaiting}
          desktop />
      );
    }
    if (progress !== null && budgetDays !== null) {
      const formattedProgress = numeral(Math.round(progress * 10) / 10).format('0.[0]');
      return `${formattedProgress} %`;
    }
    return null;
  };

  const getClassName = () => {
    let className = 'project-status-areas__row';
    if (!is_on) {
      className += ' project-status-areas__row--off-area';
    }
    return className;
  };

  const getUnescapedValue = (value) => _.unescape(value);

  const getAreaName = () => {
    if (!is_on) {
      return (
        <span>
          <span>{getUnescapedValue(name)}</span>
          <span>switched off</span>
        </span>
      );
    }

    return name;
  };

  return (
    <TableRow className={getClassName()}>
      <TableCell className="project-status-areas__col--name">{getAreaName()}</TableCell>
      <TableCell>{format(days)}</TableCell>
      <TableCell>{getDaysLeft()}</TableCell>
      <TableCell>{getProgress()}</TableCell>
    </TableRow>
  );
};
