const request = require('./Request');

const TimesheetFavourite = {
  save(data) {
    return request({
      method: 'post',
      url: '/favoritetimetracking/',
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/favoritetimetracking/${id}`,
    });
  },
};

module.exports = TimesheetFavourite;
