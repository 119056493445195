const React = require('react');
const PropTypes = require('prop-types');
const { Portal } = require('react-portal');

class TooltipMessage extends React.Component {
  getOpacity() {
    return this.props.show ? { opacity: '1' } : { opacity: '0' };
  }

  getPosition() {
    if (this.props.anchorEl && this.tooltipEl) {
      const marginFromAnchor = 4;
      const paddingFromBorder = 24;
      const anchorPosition = this.props.anchorEl.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const anchorWidth = this.props.anchorEl.offsetWidth;
      const tooltipWidth = this.tooltipEl.offsetWidth;
      const tooltipHeight = this.tooltipEl.offsetHeight;

      const tooltipTop = anchorPosition.top - tooltipHeight - marginFromAnchor;
      let tooltipLeft = anchorPosition.left + (anchorWidth / 2) - (tooltipWidth / 2);

      // Check if tooltip going out of the viewport's width on the left side
      const overflowLeft = tooltipLeft < paddingFromBorder;
      if (overflowLeft) {
        tooltipLeft = paddingFromBorder;
      }
      // Check if tooltip going out of the viewport's width on the right side
      const overflowRight = (tooltipLeft + tooltipWidth) > (windowWidth - paddingFromBorder);
      if (overflowRight) {
        tooltipLeft = anchorPosition.left + anchorWidth - tooltipWidth;
      }

      return {
        left: `${tooltipLeft}px`,
        top: `${tooltipTop}px`,
      };
    }
    return null;
  }

  getStyle() {
    const position = this.getPosition();
    const opacity = this.getOpacity();
    return { ...position, ...opacity };
  }

  getClassName() {
    let style = 'tooltipReact tooltipReact--fixed';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }

    return style;
  }

  render() {
    if (this.props.children) {
      return (
        <Portal>
          <div data-testid="tooltip-message"
            className={this.getClassName()}
            style={this.getStyle()}
            ref={(el) => this.tooltipEl = el}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}>
            {this.props.children}
          </div>
        </Portal>
      );
    }
    return null;
  }
}

TooltipMessage.propTypes = {
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  show: PropTypes.bool,
  /**
   * The DOM element used to set the position of the menu.
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Content of the tooltip
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

TooltipMessage.defaultProps = {
  show: false,
  anchorEl: null,
  className: null,
};

module.exports = TooltipMessage;
