const { connect } = require('react-redux');
const Component = require('../../../components/PersonalApiToken/TokenDeleteModal/TokenDeleteModal.react');
const AccountReducer = require('../../../reducer');
const constants = require('../../../constants');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isDeleting: AccountReducer.waitingFor(state, constants.PERSONAL_TOKEN_DELETE_REQUEST),
});

const mapDispatchToProps = (dispatch) => ({
  deleteToken: (id) => dispatch(actions.deletePersonalToken(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
