const { connect } = require('react-redux');
const Component = require('../../components/modals/ExportModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'export').length > 0,
  buFilter: state.buFilter,
  typeFilter: state.typeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  exportModule: (typeFilter = null, buFilter = 'all', year = null) => {
    dispatch(actions.exportModule(typeFilter, buFilter, year));
  },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
