const React = require('react');
const PropTypes = require('prop-types');
const Tooltip = require('./Tooltip.react');
const Icon = require('../Icon/Icon.react');

/**
 * Info icon (question mark) with customizable tooltip
 */
class InfoIconTooltip extends React.Component {
  getStyle() {
    let style = 'wethod-info-tooltip';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    if (this.props.disabled) {
      style += ' disabled';
    }
    return style;
  }

  render() {
    return (
      <Tooltip label={this.props.label}>
        <div className={this.getStyle()}>
          <Icon icon="info" label="Info" color="black" size="small" className="wethod-info-tooltip__icon" />
        </div>
      </Tooltip>
    );
  }
}

InfoIconTooltip.defaultProps = {
  className: null,
  disabled: false,
};

InfoIconTooltip.propTypes = {
  /**
   * Label shown as tooltip content
   * @see Tooltip
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

module.exports = InfoIconTooltip;
