const templateHtml = require('../templates/ModuleLoadingTemplate.html');
const SentenceView = require('./SentenceView');

/**
 * Module loading view (big spinning 'W' with sentences)
 */
const BigView = Marionette.LayoutView.extend({
  template: _.template(templateHtml),
  className: 'appLoading',
  _randomSentence: undefined,
  regions: {
    randomSentence: '[data-region="randomSentence"]',
  },
  ui: {
    randomSentence: '[data-region="randomSentence"]',
  },
  initialize(options) {
    this._randomSentence = options.sentence;
  },
  showRegions() {
    const sentence = new SentenceView({ sentence: this._randomSentence });
    this.getRegion('randomSentence').show(sentence);
  },
  onRender() {
    if (!_.isUndefined(this._randomSentence)) {
      this.showRegions();
    }
  },

  onBeforeDestroy() {
    this.$el.fadeOut();
  },
});

module.exports = BigView;
