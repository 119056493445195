/* eslint-disable class-methods-use-this */
const React = require('react');
const Areas = require('./Areas.react');

class ProjectAreasBody extends React.Component {
  getUnescapedValue(value) {
    return _.unescape(value);
  }

  projectStatusCompiled() {
    const compiledByArea = this.props.areas.filter((area) => area.days_left !== null).length > 0;
    const compiledByTotal = this.props.days_left !== null;

    return compiledByArea || compiledByTotal;
  }

  // TODO definire isWaiting
  canEditRisk() {
    return this.projectStatusCompiled() && !this.props.isWaiting;
  }

  canShowRisk() {
    return this.projectStatusCompiled();
  }

  render() {
    return (
      <div className="project-status-areas">
        <div className="project-status-areas__header">
          <div className="project-status-areas__project-info">
            <div className="project-status__column--project-info">
              <span>{this.getUnescapedValue(this.props.project.name)}</span>
              <span>{this.getUnescapedValue(this.props.client.name)}</span>
              <span>{this.getUnescapedValue(this.props.project.job_order)}</span>
            </div>
          </div>
          {/* <Risk risk={this.props.risk} canEdit={this.canEditRisk.bind(this)}
                canShow={this.canShowRisk.bind(this)}
                onChange={this.props.onRiskChange}/> */}
        </div>
        <div className="project-status-areas__body">
          <Areas
            areas={this.props.areas}
            project={this.props.project}
            isSaving={this.props.isSaving}
            totalDaysLeft={this.props.days_left}
            onAreaChange={this.props.onAreaChange}
            budget={this.props.budget} />
        </div>
      </div>
    );
  }
}

module.exports = ProjectAreasBody;
