/* eslint-disable no-nested-ternary */
const React = require('react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');
const ColorReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyColorDotField.react');
const AreasTable = require('./AreasTable.react');
const ProductionChart = require('./ProductionChart.react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');
const format = require('../../../../../../../services/FormatService').formatDecimalNumber;

const ProjectStatusSidebarBody = ({
  areas, info, isLoadingInfo, date,
}) => {
  const getDuration = (duration) => (
    <span className="project-status__sidebar-duration">
      {duration}
      <span>Months</span>
    </span>
  );

  const getValue = (value, symbol) => (value != null
    ? symbol ? `${numeral(value).format('0,0.[00]')}${symbol}` : numeral(value).format('0,0.[00]')
    : '-');

  const getUnescapedValue = (value) => _.unescape(value);

  const getName = (person) => {
    if (person) {
      return `${person.name} ${person.surname}`;
    }
    return '';
  };

  const getMonthProduction = () => {
    const prevWeekMonday = moment(date).subtract(1, 'weeks').day(1).format('YYYY-MM-DD');
    const prevWeekProduction = info.weekly_production
      .filter((production) => production.week === prevWeekMonday)[0];
    return prevWeekProduction ? getValue(prevWeekProduction.production, '%') : '-';
  };

  const getPrevWeekFriday = () => moment(date).subtract(1, 'weeks').day(5).format('MMMM DD, YYYY');

  /**
   * If project status is compiled as percentage, we need to display the costs for timesheet already done.
   * Otherwise we'll display the total hours of timesheet already done.
   * This is because in the first case budget consumption is based on timesheet cost while in the latter
   * is based just on hours (1 hour worked by a JR is equal to 1 hour of a SR).
   */
  const getTimesheetValue = () => {
    if (info.job_order_category.is_progress_percent) {
      return getValue(info.timesheet_costs, ' (timesheet costs)');
    }
    return getValue(info.timesheet_days, ' days');
  };

  const getDetailedBody = () => {
    if (isLoadingInfo) {
      return (
        <div className="project-status__sidebar-loading">
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <div className="project-status__sidebar-statistic">
          <h4 className="project-status__sidebar-statistic-title">
            Days planned <br />for the future
          </h4>
          <span className="project-status__sidebar-statistic-value">
            {info.planned_days ? format(info.planned_days, 1) : info.planned_days}
          </span>
        </div>
        <div className="project-status__sidebar-statistic">
          <h4 className="project-status__sidebar-statistic-title">
            Budget consumption
          </h4>
          <span className="project-status__sidebar-statistic-value">
            {getTimesheetValue()}
          </span>
          <span className="project-status__sidebar-statistic-value">
            {getValue(info.budget_consumption, '%')}
          </span>
        </div>
        <div className="project-status__sidebar-statistic">
          <h4 className="project-status__sidebar-statistic-title">
            Production
            {' '}
            <br />
            of the month
          </h4>
          <span className="project-status__sidebar-statistic-value">
            {getMonthProduction()}
          </span>
        </div>
        <ProductionChart weeks={info.weekly_production}
          planned={info.month_production_plan} />
        <h3 className="project-status__sidebar-project-title">Project details</h3>
        <ReadOnlyField
          placeholder="Name"
          value={getUnescapedValue(info.project.name)}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField
          placeholder="Client"
          value={info.client.name}>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </ReadOnlyField>
        <ReadOnlyField
          placeholder="PM"
          value={getName(info.pm)}>
          <span className="wethod-icon wethod-icon-pm" />
        </ReadOnlyField>
        <ReadOnlyField
          placeholder="Account"
          value={getName(info.account)}>
          <span />
        </ReadOnlyField>
        <ColorReadOnlyField
          placeholder="Job Order Category"
          value={getUnescapedValue(info.job_order_category.name)}
          color={info.job_order_category.color}>
          <span />
        </ColorReadOnlyField>
        <ReadOnlyField
          placeholder="Job Order"
          value={info.project.job_order}>
          <span />
        </ReadOnlyField>
        <div className="suppliers__sidebar--multiple-input-row">
          <div className="suppliers__sidebar--multiple-input-element">
            <ReadOnlyField
              placeholder="Start"
              value={moment(info.project.date_start).format('MMM YYYY')}>
              <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
            </ReadOnlyField>
          </div>
          <div className="suppliers__sidebar--multiple-input-element">
            <ReadOnlyField
              placeholder="Duration"
              value={getDuration(info.project.duration)} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h2 className="project-status__sidebar-date">{`As of last friday, ${getPrevWeekFriday(date)}`}</h2>
      <AreasTable areas={areas} />
      {getDetailedBody()}
    </div>
  );
};
module.exports = ProjectStatusSidebarBody;
