const { connect } = require('react-redux');
const Component = require('../components/ExpensesList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'get-expenses').length > 0,
  expenses: state.expenses,
  hasMore: state.hasMorePages,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  keyword: state.keyword,
  typeFilter: state.typeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (offset, limit, search, typeFilter) => dispatch(actions
    .getExpenses(offset, limit, search, typeFilter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
