const { connect } = require('react-redux');
const Component = require('../components/YearResolution.react');

const mapStateToProps = (state) => ({
  resolution: state.objectivesByQuarter.resolution,
  placeholder: 'Set some guidelines for the year',
  waiting: state.waitingForObjectives,
});

module.exports = connect(mapStateToProps)(Component);
