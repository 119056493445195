const React = require('react');
const PropTypes = require('prop-types');
const Node = require('./TreeNode.react');

require('./style.scss');

const Tree = ({
  node,
  className,
}) => {
  const getClassName = () => `wethod-tree ${className}`;

  return (
    <div className={getClassName()}>
      <Node component={node.component}>{node.children}</Node>
    </div>
  );
};

// This is a way to express recursive PropTypes, see https://medium.com/@spencerwhitehead7/defining-recursive-proptypes-6f5586d0bd35
const nodeShape = {
  component: PropTypes.element.isRequired,
};

nodeShape.children = PropTypes.arrayOf(PropTypes.shape(nodeShape));

Tree.defaultProps = {
  className: '',
};

Tree.propTypes = {
  className: PropTypes.string,
  node: PropTypes.shape(nodeShape).isRequired,
};

module.exports = Tree;
