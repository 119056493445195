const React = require('react');
const PropTypes = require('prop-types');

/**
 * Basic card with header and body;
 * the header is composed of a title, shown on the left, and some actions, shown on the right.
 * The body of the card is given as children.
 * The card style is customizable giving a className prop.
 *
 * @param title
 * @param actions
 * @param children
 * @param className
 */
const Card = ({ title, actions, children, className }) => {
  const getStyle = () => {
    let style = 'invoice-plan__card';
    if (className) style += ` ${className}`;
    return style;
  };

  const getBody = () => (
    children ? (
      <div className="invoice-plan__card-body">
        {children}
      </div>
    ) : null
  );

  return (
    <div className={getStyle()}>
      <div className="invoice-plan__card-header">
        <span className="invoice-plan__card-title">{title}</span>
        <span>{actions}</span>
      </div>
      {getBody()}
    </div>
  );
};

Card.propTypes = {
  // Title of the card, shown on the left of the header
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  // Actions of the card, shown on the right of the header
  actions: PropTypes.oneOfType([
    PropTypes.node, PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  // Body of the card
  children: PropTypes.oneOfType([
    PropTypes.string, PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  // Class added to the card style
  className: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  actions: null,
  children: null,
  className: null,
};

module.exports = Card;
