const { connect } = require('react-redux');
const Component = require('../../../components/Goals/GoalModal/GoalModalBodyEdit.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  goal: state.goals.find((goal) => goal.id === state.focusedGoalId),
  projectId: state.info.id,
  isSaving: state.waitingFor.filter((key) => key === 'save-goal').length > 0,
  isDeleting: state.waitingFor.filter((key) => key === 'delete-goal').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (goal, projectId) => dispatch(actions.saveGoal(goal, projectId)),
  onDelete: (goal, projectId) => dispatch(actions.deleteGoal(goal, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
