const React = require('react');

const DistributionChart = ({ data }) => {
  const formattedData = data != null ? Math.round(data * 10) / 10 : '-';

  // Calc the width based on available space: 100% - width of chart label
  const getStyle = () => (data ? { width: `calc(${data} / 100 * (100% - 50px))` } : null);

  return (
    <div className="knowledge__distribution">
      <div className="knowledge__distribution-chart" style={getStyle()} />
      <span className="knowledge__distribution-label">{formattedData}%</span>
    </div>
  );
};

module.exports = DistributionChart;
