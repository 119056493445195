const React = require('react');
const AlertEngineRadio = require('./AlertEngineRadio.react');

module.exports = class AlertChunk extends React.Component {
  constructor(props) {
    super(props);

    let toggle = false;
    let enabled = false;
    if (props.param && props.param.type === 'boolean') {
      toggle = true;

      if ('value' in props.param) {
        enabled = props.param.value;
      } else if ('default' in props.param) {
        enabled = props.param.default;
      }
    }

    this.state = {
      value: props.param ? props.param.value : null,
      toggle,
      enabled,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.getInput = this.getInput.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  static handleFocus(event) {
    event.target.select();
  }

  handleBlur() {
    if (this.state.value) {
      this.props.editAlertParam({
        alertId: this.props.alertId,
        index: this.props.alertId,
        key: this.props.param.key,
        value: this.state.value,
      });
    }
  }

  static handleKeyDown(event) {
    const isValid = event.which === 8 || event.which === 91
      || (event.which >= 48 && event.which <= 57);
    if (!isValid) {
      event.preventDefault();
    }
  }

  handleRadioChange(e) {
    if (!this.props.enabled) {
      return;
    }
    this.props.editAlertParam({
      alertId: this.props.alertId,
      index: this.props.alertId,
      key: this.props.param.key,
      value: e.target.value,
    });

    this.setState((prevState) => ({
      enabled: !prevState.enabled,
    }));
  }

  getRadioElements() {
    const { options } = this.props.param;
    const elements = [];
    Object.keys(options).forEach((value) => {
      const key = options[value];
      elements.push({
        label: key,
        value: value === 'true',
      });
    });
    return elements;
  }

  getInput() {
    if (this.props.param) {
      if (this.state.toggle) {
        return (
          <AlertEngineRadio
            onChange={this.handleRadioChange}
            currentValue={this.state.enabled}
            disabled={!this.props.enabled}
            elements={this.getRadioElements()} />
        );
      }

      return (
        <input
          className="alerts-engine__chunk-param"
          value={this.state.value}
          onChange={this.handleChange}
          onFocus={AlertChunk.handleFocus}
          onKeyDown={AlertChunk.handleKeyDown}
          disabled={!this.props.enabled}
          onBlur={this.handleBlur} />
      );
    }
    return null;
  }

  render() {
    return (
      <span>
        {this.props.content}
        {this.getInput()}
      </span>
    );
  }
};
