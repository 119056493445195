const React = require('react');

module.exports = class ChargeabilityChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        backgroundColor: '#FCFCFC',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
          },
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null, // inherit from series
            marker: 'circle',
          },
        },
      },
      xAxis: {
        categories: [
          '3 m ago',
          '2 m ago',
          'Last',
          '',
        ],
        lineColor: '#CACACA',
        labels: {
          style: {
            textOverflow: 'none',
          },
        },
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        min: this.props.min,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
        plotLines: [{
          color: '#F8A01C',
          width: 1,
          value: this.props.target,
          dashStyle: 'dash',
          zIndex: 4,
        }],
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        color: '#cec3c4',
        marker: {
          symbol: 'circle',
        },
        data: [this.props.prevYearTwoMonthsAgo, this.props.prevYearPrevMonth,
          this.props.prevYearThisMonth, null],
      }, {
        color: '#804E49',
        marker: {
          symbol: 'circle',
        },
        data: [this.props.twoMonthsAgo, this.props.prevMonth, {
          y: this.props.thisMonth,
          dataLabels: {

            style: {
              fontWeight: 'bold',
            },
          },
        }, null],
      }, {
        marker: {
          enabled: false,
        },
        color: 'rgba(0,0,0,0)',
        data: [null, null, null, this.props.target],
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="briefing-aside-chart" id="briefing-aside-chart-monthly__chargeability">
        <div className="briefing-aside-chart__header">
          <h5 className="briefing-aside-chart__title">Monthly Chargeability</h5>
          <h6 className="briefing-aside-chart__subtitle">in percentage</h6>
        </div>
        <div className="briefing-aside-chart__content"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
        <div className="briefing-aside-chart__legenda">
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--target">
            Monthly average
            target for current year
          </div>
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--sales-this-year">
            This
            year
          </div>
          <div
            className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--sales-same-week-last-year">
            Same
            months last year
          </div>
        </div>
      </div>
    );
  }
};
