const { connect } = require('react-redux');
const Component = require('../../../components/modals/WorkingConnections/WorkingConnectionsBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  projects: state.workingConnectionsProjects.projects,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
