const React = require('react');
const NumberFormatter = require('../../../../../../../common/react/formatters/NumberFormatter.react');

const HeaderLevelTooltipMessage = ({
  isHierarchyBudgetFlagEnabled, levelId, name,
  projectId, budgetVersion, cost, price, showDailyCostPriceLevelModal,
}) => {
  function showDailyCostPriceLevelModalLevel() {
    showDailyCostPriceLevelModal(levelId, name, projectId, budgetVersion);
  }

  if (isHierarchyBudgetFlagEnabled) {
    return (
      <div className="wethod-budget-calculator__level-info-tooltip-message-click">
        <div className="wethod-budget-calculator__level-info-tooltip-row"
          onClick={showDailyCostPriceLevelModalLevel}>
          <b>See Daily Cost and Price &#8594;</b>
        </div>
      </div>
    );
  }
  return (
    <div className="wethod-budget-calculator__level-info-tooltip-message">
      <div className="wethod-budget-calculator__level-info-tooltip-row">
        <div className="wethod-budget-calculator__level-info-tooltip-name">{name}</div>
      </div>
      <div className="wethod-budget-calculator__level-info-tooltip-row">
        <div className="wethod-budget-calculator__level-info-tooltip-value">
          Daily Cost:
          <NumberFormatter>
            {cost}
          </NumberFormatter>
        </div>
      </div>
      <div className="wethod-budget-calculator__level-info-tooltip-row">
        <div className="wethod-budget-calculator__level-info-tooltip-value">
          Daily Price:
          <NumberFormatter>
            {price}
          </NumberFormatter>
        </div>
      </div>
    </div>
  );
};

module.exports = HeaderLevelTooltipMessage;
