const React = require('react');
const _ = require('underscore');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const SelectItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItemColorful.react');
const RoundedSelect = require('../../../../../../../common/react/inputs/Select/ColorfulSelect/RoundedColorfulSelect/RoundedColorfulSelect.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const TooltipItem = require('../../TooltipItem.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const StageCell = ({
  updateErrors, availableValues, readOnly,
  value, label, color, showStatus, onChange, error, disabled, style, metadataService,
}) => {
  const options = availableValues.map((stage) => {
    const nameLabel = stage.is_default ? '' : stage.name;
    return (
      <SelectItem key={stage.id}
        value={stage.id}
        color={stage.color}
        label={nameLabel}>
        <TooltipItem label={stage.description}>
          {_.unescape(nameLabel)}
        </TooltipItem>
      </SelectItem>
    );
  });

  function handleChange(e) {
    const selected = availableValues.filter((stage) => stage.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  return (
    <TableCell className="pipeline-item__stage pipeline__stage" style={style}>
      <ShowIf condition={showStatus}>
        <InputValidator updateErrors={updateErrors} constraints={[]}>
          <InputMetadataManager metadataService={metadataService}
            name="stage-select"
            canEdit={!readOnly && !disabled}>
            <RoundedSelect
              label={label}
              value={value}
              placeholder=""
              errorText={error}
              color={color}
              onChange={handleChange}>
              {options}
            </RoundedSelect>
          </InputMetadataManager>
        </InputValidator>
      </ShowIf>
    </TableCell>
  );
};

module.exports = StageCell;
