'use strict';

Wethod.module('FridayApp', function (FridayApp, Wethod, Backbone, Marionette) {
  this.Layout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#fridayTemplate',
    regions: {
      headerSection: '[data-region="fridaySectionHeader"]',
      fridayBody: '[data-region="fridayBody"]',
    },
  });
});
