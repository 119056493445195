'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod) {
  this.startWithParent = false;

  var API = {
    redirectToNewPipeline: function () {
      PipelineApp.Controller.redirectToNewPipeline();
    },

    showPipelineProject: function (options) {
      PipelineApp.Controller.showPipeline(options);
    },

    showPipelineProjectInvoicePlan: function (options) {
      PipelineApp.Controller.showPipeline(options);
    },

    showPipelineWithBasket: function (basket) {
      PipelineApp.Controller.showPipeline({
        basket: basket,
        type: 'opportunities',
      });
    },

    showPipelineProgram: function () {
      PipelineApp.Controller.showPipelineProgram();
    },

    showPipelineBasket: function (options) {
      PipelineApp.Controller.showPipelineBasket(options);
    },

    linkIntercompanyProject: function (options) {
      this.showPipelineProject({ token: options.params });
    },
  };

  // usato dai widget della dashboard
  Wethod.on('url:pipeline:show:filter', function (options) {
    Wethod.navigate('pipeline/projects');
    API.showPipelineProject(options);
  });

  // used by basket 'promote' functionality
  dispatcher.on('url:pipeline:basket:promote', function (basket) {
    // show loading
    var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
    var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
    Wethod.regions.body.show(loadingView);

    Wethod.navigate('pipeline/opportunities');
    dispatcher.trigger('set:active:tab', 'pipeline');

    API.showPipelineWithBasket(basket);
  }, dispatcher);

  PipelineApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'pipeline');
    API[options.foo](options);
  });
});
