const React = require('react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');

module.exports = class Window extends React.Component {
  getStyle() {
    if (this.props.style) {
      return this.props.style;
    }
    return { left: `${this.props.left}px`, top: `${this.props.top}px` };
  }

  getLoader() {
    if (this.props.loader) {
      return (
        <div
          style={this.props.loader.style}
          className={`virtual-grid__window-loader virtual-grid__window-loader--${this.props.loader.position}`}>
          <Loader />
        </div>
      );
    }
    return null;
  }

  getClassName() {
    let name = `virtual-grid__window virtual-grid__window--${this.props.type}`;
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        {this.getLoader()}
        {this.props.children}
      </div>
    );
  }
};
