const React = require('react');
const PropTypes = require('prop-types');

const ListItem = ({ terminator, children }) => (
  <span>
    {children}
    {terminator}
  </span>
);

ListItem.propTypes = {
  terminator: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

module.exports = ListItem;
