/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TextArea = require('../../../../../../../common/react/material-input/SidebarTextArea.react');
const Date = require('../DateController.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');

module.exports = class OtherEditableDetails extends React.Component {
  render() {
    return (
      <div>
        <Date name="date"
          metadataService={this.props.metadataService}
          editable
          isRoundTrip
          date={this.props.travel.date}
          return_date={this.props.travel.return_date}
          onChange={this.props.onChange} />
        <TextArea name="additional_requests"
          placeholder="Additional requests"
          value={this.props.travel.additional_requests}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </TextArea>
        <TravelOfficeSection travel={this.props.travel}
          metadataService={this.props.metadataService}
          editMode
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <FileSection editMode carrier={this.props.travel} />
      </div>
    );
  }
};
