const { connect } = require('react-redux');
const Component = require('../../components/Calculator/CalculatorManager.react');
const constants = require('../../constants');
const FeatureService = require('../../../../../../services/FeatureService');

const getLevelsData = (priceListLevels, budgetPricelist, oldLevels) => {
  if (Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)) {
    return priceListLevels
      .map((priceListLevel) => ({
        id: priceListLevel.id,
        level: priceListLevel.level,
        level_name: priceListLevel.name,
        level_short_name: priceListLevel.short_name,
        level_cost: priceListLevel.cost,
        level_price: priceListLevel.price,
        sort: priceListLevel.sort,
        is_visible: priceListLevel.is_visible ?? true,
      }));
  }
  return oldLevels;
};

const mapStateToProps = (state) => ({
  // All levels related to budget version
  levelsData: getLevelsData(state.info.budget_price_list_levels, state.info.price_list,
    state.info.levels),
  // Days amounts related to each task and level for the current budget version
  levelAmounts: state.info.days,
  areas: state.info.areas,
  tasks: state.info.tasks,
  budgetJobTitles: state.info.job_titles,
  pricelist: state.info.price_list,
  summaries: [{
    name: 'total',
    id: 'total',
    subtotal_id: 'user-areas',
    // Area types contained in this summary
    area_types: [constants.AREA_TYPE_CONTINGENCY_DISCOUNT],
  }, {
    name: 'areas & tasks',
    id: 'user-areas',
    subtotal_id: null,
    area_types: [constants.AREA_TYPE_TRAVEL_CORE_COST_EXPENSES, constants.AREA_TYPE_PM,
      constants.AREA_TYPE_DEFAULT],
  }],
  budgetPriceListLevels: state.info.budget_price_list_levels,
  budgetLevels: state.info.levels,
});

module.exports = connect(mapStateToProps)(Component);
