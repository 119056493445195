const { connect } = require('react-redux');
const DeleteEventModal = require('../components/DeleteEventModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  event: state.focusedEvent,
  isDeletingEvent: state.isDeletingEvent,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEvent: (event) => dispatch(actions.deleteEvent(event)),
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(DeleteEventModal);
