/* eslint-disable class-methods-use-this */
const React = require('react');

module.exports = class WorkingConnectionsProject extends React.Component {
  getNumeralValue(value) {
    return numeral(value).format('0,0');
  }

  getName(string) {
    if (string !== null && string !== undefined) {
      const components = string.split(' ');
      const name = components[0].charAt(0);
      const surname = components.slice(1).join(' ');
      return `${name} ${surname}`;
    }
    return '';
  }

  render() {
    return (
      <a href={`#pipeline/projects?id=${this.props.id}`} className="profile-working-connections__projects-item">
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-name">
          {this.props.name}
        </span>
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-pm">
          {this.getName(this.props.pm)}
        </span>
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-account">
          {this.getName(this.props.account)}
        </span>
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-client">
          {this.props.client}
        </span>
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-value">
          {this.getNumeralValue(this.props.value)}
          {' '}
          K
        </span>
        <span
          className="profile-working-connections__projects-item-col profile-working-connections__projects-item-col-hours">
          {this.getNumeralValue(this.props.hours)}
        </span>
      </a>
    );
  }
};
