'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $) {
  this.RuleView = Marionette.LayoutView.extend({
    /**
     *  _selectRuleView : shorthand for the rule's name SelectView attached to this RuleView.
     *  _conditionRegionView : shorthand for the region where the condition's tree will be
     * rendered. Initially this region hosts the AddButtonView needed to add a new condition to the
     * rule.
     *  _operatorRegionView : shorthand for the left SelectView attached to this TerminalView.
     *  _availableConditionOperator : a collection containing the available options of the
     * condition's AddButton's drop-down (basically, Group & Terminal).
     *  _availableRules : the Collection of the rules that aren't already been set by the user.
     *  _dependenciesByRule : the rules' dependencies, grouped by rule.
     *  _conditionToInstantiate : the number of conditions which need to be instantiated. These are
     * the nested
     *  "+ CONDITION" button still present in the RuleView.
     */
    _selectRuleView: null,
    _conditionRegionView: null,
    _availableConditionOperator: null,
    _availableRules: null,
    _dependenciesByRule: null,
    _conditionToInstantiate: 0,
    template: '#ruleTemplate',
    className: 'rule',
    ui: {
      saveButton: '[data-action="saveRuleButton"]',
      saveButtonLabel: '.rule__buttons__save__label',
      deleteButton: '[data-action="deleteRuleButton"]',
      selectRuleButton: '[data-region="selectRuleName"]',
    },
    events: {
      'click @ui.deleteButton': 'onDeleteButtonClicked',
      'click @ui.saveButton': 'onSaveButtonClicked',
    },
    regions: {
      selectRule: '[data-region="selectRuleName"]',
      conditionRegion: '[data-region="conditionRegion"]',
      modalRegion: '[data-region="modalRegion"]',
    },
    initialize: function () {
      this._availableRules = this.model.get('available');
      this._dependenciesByRule = this.model.get('dependenciesByRule');
      var availableConditionOperator = [
        {
          label: 'Group',
          name: 'group',
        },
        {
          label: 'Terminal',
          name: 'terminal',
        },
        {
          label: 'Always / Never',
          name: 'absolute',
        },
      ];
      this._availableConditionOperator = new RuleManager
        .TooltipOptionCollection(availableConditionOperator);
    },
    onRender: function () {
      var selectRule = new RuleManager.SelectView({ collection: this._availableRules });
      this.selectRule.show(selectRule);
      this._selectRuleView = this.selectRule.currentView;
      this._selectRuleView.$el.addClass('rule-select-wrapper col_16');

      this.attachAddConditionButton();

      this.on('condition:root:added', this.showSaveButton, this);
    },
    onChildConditionButtonAdded: function () {
      this._conditionToInstantiate += 1;
    },
    onChildConditionInstantiated: function () {
      this._conditionToInstantiate -= 1;
    },
    /**
     * Shows the condition's AddButton in its region, using the _availableConditionOperator to
     * populate AddButton's drop-down.
     */
    attachAddConditionButton: function () {
      var selectCondition = new RuleManager
        .AddButtonView({ collection: this._availableConditionOperator });
      this.conditionRegion.show(selectCondition);
      this._conditionRegionView = this.conditionRegion.currentView;
      this._conditionRegionView.setLabel('+ CONDITION');

      this._conditionRegionView.on('tooltip:item:selected', this.addCondition, this);
    },
    /**
     * Checks if the current rule can be saved.
     * @returns {boolean}
     */
    canSave: function () {
      return this._conditionToInstantiate === 0;
    },
    onSaveButtonClicked: function () {
      if (this.canSave()) {
        this.setButtonLabel(this.ui.saveButtonLabel, 'SAVING');
        this.saveRule();
      } else {
        var data = {
          title: 'SAVE RULE',
          text: 'In order to save a rule, you must provide all the needed terminal conditions.<br><br>'
            + 'Please choose a terminal condition from all the <br> "+ CONDITION" buttons you left in the rule.',
          buttonOkText: undefined,
          buttonKoText: 'CLOSE',
          action: 'closeModal',
        };

        var saveModalModel = new Wethod.PipelineApp.Pipeline.ModalModel(data);
        var saveModalView = new RuleManager.RuleModalView({ model: saveModalModel });

        this.modalRegion.show(saveModalView);
      }
    },
    onDeleteButtonClicked: function () {
      var data = {
        title: 'DELETE RULE',
        text: 'Deleting a rule means that the default behaviour for this rule will be applied.<br><br>'
          + 'Are you sure you want to delete this rule?',
        buttonOkText: 'DELETE',
        buttonKoText: 'GO BACK',
      };
      var deleteModalModel = new Wethod.PipelineApp.Pipeline.ModalModel(data);
      var deleteModalView = new RuleManager.RuleModalView({ model: deleteModalModel });
      deleteModalView.on('rule:delete', this.deleteRule, this);

      this.modalRegion.show(deleteModalView);
    },
    /**
     * Deletes a rule's model from the rule's collection. This automatically destroys the view in
     * which the rule was rendered.
     *
     * @triggers rule:deleted
     */
    deleteRule: function () {
      // If you destroy a model, it is removed from any collections that was containing it.
      this.trigger('rule:deleted', this.model);
      this.model.destroy();
    },
    /**
     * Saves a rule into the database, after parsing into a known JSON format.
     * @triggers rule:saved
     */
    saveRule: function () {
      this.model.set('condition_json', this.model.parseToJson());
      this.model.set('available', null); // avoid loop in conversion to json

      var saveRuleRequest = Wethod.request('save:rule', this.model);
      $.when(saveRuleRequest).done(function () {
        this.setButtonLabel(this.ui.saveButtonLabel, 'SAVED');
        setTimeout(function () {
          this.setButtonLabel(this.ui.saveButtonLabel, 'SAVE');
        }.bind(this), 3000);
        this.trigger('rule:saved', this.model);
      }.bind(this));
    },
    /**
     * Add the root Condition (either a Group or a Terminal) to this rule. The proper condition's
     * View is added to this RuleView and the corresponding model is set as this.model.condition.
     *
     * @param optionModel contains the attribute name = "group" || "terminal"
     * @triggers condition:root:added
     */
    addCondition: function (optionModel) {
      var conditionKey = optionModel.name;
      this.model.set('name', this._selectRuleView.getChosenOption());
      var condition;
      if (conditionKey === 'group') {
        condition = this.addGroupCondition();
        this.model.set('condition', condition.model);
      } else if (conditionKey === 'terminal') {
        condition = this.addTerminalCondition();
        this.model.set('condition', condition.model);
      } else if (conditionKey === 'absolute') {
        condition = this.addAbsoluteCondition();
        this.model.set('condition', condition.model);
      }
      this.trigger('condition:root:added');
    },
    addAbsoluteCondition: function () {
      var availableAbsoluteConditions = [
        {
          label: 'Always',
          name: 'always',
        },
        {
          label: 'Never',
          name: 'never',
        },
      ];
      var absoluteModel = new RuleManager
        .AbsoluteModel({ dependencies: availableAbsoluteConditions });
      var absolute = new RuleManager.AbsoluteView({ model: absoluteModel });
      this.conditionRegion.show(absolute);
      this._selectRuleView.disable();
      return absolute;
    },
    /**
     * Creates and display a new GroupView as child of the conditionRegion, passing it the right
     * _dependenciesByRule
     * (these are the dependencies corresponding to the user's selected rule's name).
     * After showing the GroupView, the region hosting it is expanded to be as larger as possible
     * to avoid
     * (as much as possible) the condition's views to go on more than 1 line.
     *
     * @returns RuleManager.GroupView
     */
    addGroupCondition: function () {
      var dependencies = this._dependenciesByRule.findWhere({ rule_name: this.model.get('name') });
      var groupModel = new RuleManager.GroupModel({ dependencies: dependencies.get('rule_dependencies') });
      var group = new RuleManager.GroupView({ model: groupModel });
      group.on('condition:button:added', this.onChildConditionButtonAdded, this);
      group.on('condition:instantiated', this.onChildConditionInstantiated, this);
      this.conditionRegion.show(group);
      this.conditionRegion.$el.removeClass('col_2');
      this.conditionRegion.$el.addClass('col_9');
      this._selectRuleView.disable();
      return group;
    },
    /**
     * Creates and display a new TerminalView as child of the conditionRegion, passing it the right
     * _dependenciesByRule
     * (these are the dependencies corresponding to the user's selected rule's name).
     * After showing the TerminalView, the region hosting it is expanded to be as larger as
     * possible to avoid
     * (as much as possible) the condition's views to go on more than 1 line.
     *
     * @returns RuleManager.TerminalView
     */
    addTerminalCondition: function () {
      var dependencies = this._dependenciesByRule.findWhere({ rule_name: this.model.get('name') });
      var terminalModel = new RuleManager.TerminalModel({ dependencies: dependencies.get('rule_dependencies') });
      var terminal = new RuleManager.TerminalView({ model: terminalModel });
      this.conditionRegion.show(terminal);
      this.conditionRegion.$el.removeClass('col_2');
      this.conditionRegion.$el.addClass('col_9');
      this._selectRuleView.disable();
      return terminal;
    },
    setButtonLabel: function (buttonLabelEl, newLabel) {
      buttonLabelEl.text(newLabel);
    },
    /**
     * Shows the save button.
     */
    showSaveButton: function () {
      this.show(this.ui.saveButton);
    },
    /**
     * Shows a particular DOM element.
     *
     * @param domElement jQuery element
     */
    show: function (domElement) {
      domElement.removeClass('hidden');
    },
    /**
     * Build a rule from a tree of conditions. Build a TerminalView or a GroupView for the root
     * node and let them build their child views.
     * @param condition The tree of conditions
     */
    build: function (condition) {
      var rootView;
      var model = this.model;

      if (condition.get('type') === 'terminal') {
        rootView = this.addTerminalCondition();
      } else if (condition.get('type') === 'compound') {
        rootView = this.addGroupCondition();
      } else if (condition.get('type') === 'absolute') {
        rootView = this.addAbsoluteCondition();
      }
      rootView.build(condition);
      model.set('condition', rootView.model);
      this.trigger('condition:root:added');

      // Trigger element selection
      this._selectRuleView.onTooltipOptionSelected({
        name: model.get('name'),
        label: model.get('label'),
      }, false);
    },
  });
});
