const React = require('react');
const NumericField = require('../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');

const ProbabilityInput = ({
  readOnly, disabled, name, value, label, id,
  updateErrors, errorText, onChange,
}) => (
  <InputValidator updateErrors={updateErrors}
    constraints={['required', 'min:0', 'max:100']}>
    <NumericField readOnly={readOnly}
      disabled={disabled}
      name={name}
      label={label}
      id={id}
      errorText={errorText}
      value={value}
      onBlur={onChange}
      suffix="%" />
  </InputValidator>
);

module.exports = ProbabilityInput;
