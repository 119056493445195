const React = require('react');
const TimesheetList = require('../containers/TimesheetList');
const TimesheetHeader = require('../containers/Actions');
const Modal = require('../containers/ModalController');
const WeeklyHoursModal = require('../containers/WeeklyHoursModal');
const CapacityManager = require('../containers/CapacityManager');
const FeatureService = require('../../services/FeatureService');

const Timesheet = () => {
  if (FeatureService.isEmployeeCapacityEnabled()) {
    return (
      <div className="timesheet timesheet-detailed wethod-section-body">
        <CapacityManager>
          <TimesheetHeader />
        </CapacityManager>
        <TimesheetList />
        <Modal />
        <WeeklyHoursModal />
      </div>
    );
  }
  return (
    <div className="timesheet timesheet-detailed wethod-section-body">
      <TimesheetHeader />
      <TimesheetList />
      <Modal />
      <WeeklyHoursModal />
    </div>
  );
};

module.exports = Timesheet;
