const React = require('react');
const Modal = require('../../../../../../../../../common/react/modal2/Modal.react');
const VoteInput = require('./VoteInput.react');
const ProjectInput = require('../../../containers/modal/RatingModal/ProjectInput');
const CommentInput = require('./CommentInput.react');

module.exports = class RatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.rating = this.props.data || {};

    this.state = {
      value: this.rating.value || 1,
      project: this.rating.project || null,
      comment: this.rating.comment || '',
      // array containing the name of the attributes that have errors; if the list is empty there are no errors
      errors: [],
    };
  }

  handleProjectChange(name, value) {
    this.setState({
      project: value,
    });
  }

  handleCommentChange(name, value) {
    this.setState({ comment: value });
  }

  handleRatingChange(value) {
    this.setState({ value });
  }

  /**
   * Check for input errors and keep the state list of errors updated.
   * @param name
   * @param errors
   */
  handleErrors(name, errors) {
    this.setState((prevState) => {
      let updatedErrors = prevState.errors;
      if (errors.length === 0) {
        updatedErrors = updatedErrors.filter((error) => error !== name);
      } else if (!updatedErrors.includes(name)) {
        updatedErrors.push(name);
      }

      return { errors: updatedErrors };
    });
  }

  getFeedBack() {
    if (this.props.isSaving) {
      return <div className="profile-contact-info__feedback">Saving</div>;
    }
    return null;
  }

  getSaveButtonStyle() {
    let style = 'wethod-button';
    if (this.state.errors.length) {
      style += ' disabled';
    }
    return style;
  }

  getClassName() {
    let name = 'supplier__modal supplier__modal-rating';
    if (this.props.isSaving) {
      name += ' wethod-modal2--disabled';
    }
    return name;
  }

  save() {
    const rating = {
      value: this.state.value,
      comment: this.state.comment,
      project_id: this.state.project.id,
    };
    if (this.rating) {
      rating.id = this.rating.id;
    }

    this.props.onSave(this.props.supplierId, rating);
  }

  render() {
    return (
      <Modal title="Supplier rating"
        onCancelClick={this.props.onClose}
        className={this.getClassName()}>
        <VoteInput value={this.state.value} onChange={this.handleRatingChange.bind(this)} />
        <ProjectInput value={this.state.project}
          onChange={this.handleProjectChange.bind(this)}
          onValidate={this.handleErrors.bind(this)} />
        <CommentInput value={this.state.comment} onChange={this.handleCommentChange.bind(this)} />
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.props.onClose}
            className="wethod-button">
            Cancel
          </button>
          <button type="button"
            onClick={this.save.bind(this)}
            disabled={this.state.errors.length > 0}
            className={this.getSaveButtonStyle()}>
            Save
          </button>
        </div>
        {this.getFeedBack()}
      </Modal>
    );
  }
};
