/* eslint-disable react/prop-types */
const React = require('react');
const Section = require('./Section.react');
const SectionLogout = require('./SectionLogout.react');
const SectionProfile = require('./SectionProfile.react');

module.exports = class Area extends React.Component {
  getSections() {
    const sections = this.props.area.getSections()
      .map((section) => (
        <Section key={section.getLabel()}
          section={section}
          blocked={this.props.area.isBlocked()}
          onClick={this.props.onSectionClick} />
      ));

    if (this.props.area.getLabel() === 'Account') {
      return [<SectionProfile key="Profile" onClick={this.props.onSectionClick} />]
        .concat(sections, <SectionLogout key="Logout" onClick={this.props.onSectionClick} />);
    }

    return sections;
  }

  getListClassName() {
    let name = 'app-nav__area-section-list';
    if (this.props.area.isBlocked()) {
      name += ' app-nav__area-section-list--blocked';
    }
    return name;
  }

  getBlockedIcon() {
    if (this.props.area.isBlocked()) {
      return (
        <span className="app-nav__area-title-icon wethod-icon-blocked wethod-icon-blocked--black" />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="app-nav__area">
        <div className="app-nav__area-title">
          <span className="app-nav__area-title-text">{this.props.area.getLabel()}</span>
          {this.getBlockedIcon()}
        </div>
        <ul className={this.getListClassName()}>
          {this.getSections()}
        </ul>
      </div>
    );
  }
};
