const React = require('react');
const { CSSTransition } = require('react-transition-group');
const BasketSidebar = require('../containers/BasketSidebar');

const SidebarWrapper = ({ showSidebar, item }) => (
  <CSSTransition
    in={showSidebar}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <BasketSidebar opportunity={item} />
  </CSSTransition>
);

module.exports = SidebarWrapper;
