const React = require('react');

const SettingsRow = ({ children, feedback, className, border = true }) => {
  const style = border ? 'company-settings__row company-settings__row--border' : 'company-settings__row';

  return (
    <div className={`${style} ${className}`}>
      {children}
      <div className="company-settings__row-feedback">
        {feedback}
      </div>
    </div>
  );
};

module.exports = SettingsRow;
