const { connect } = require('react-redux');
const Component = require('../components/LinkedPlan.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  masterProject: state.masterProject,
  project: state.project,
  billingGroupId: state.billingGroup,
});

const mapDispatchToProps = (dispatch) => ({
  onRevoke: (project, masterProject, billingGroupId) => dispatch(actions
    .showRevokeDelegationModal(project, masterProject, billingGroupId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
