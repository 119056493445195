const React = require('react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');

const Actions = ({ onAdd, isAddDisabled }) => (
  <div className="company-settings-margin-top-xl company-settings--spaced">
    <Button onClick={onAdd}
      disabled={isAddDisabled}
      className="company-settings__button company-settings__button--blue company-settings-margin-bottom-xl">
      + Add holiday
    </Button>
  </div>
);

module.exports = Actions;
