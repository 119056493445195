/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const dragula = require('react-dragula');
const Board = require('../../containers/Boards/Board');
const Empty = require('./EmptyBoards.react');
const Loading = require('./LoadingBoards.react');
const arrayMover = require('../../services/arrayMover');
const Loader = require('../../../../../../common/react/Loader/Loader.react');

module.exports = class Boards extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dragging: false };
  }

  componentDidMount() {
    this.drake = this.dragulaDecorator(this.boardsEl);
    this.props.getBoards(this.props.projectId);
  }

  getContent() {
    if (this.props.isWaitingForBoards) {
      return <Loading />;
    }
    if (this.props.boards.length === 0) {
      if (this.props.canEdit) {
        return (
          <Empty title="Organization & Management"
            canEdit={this.props.canEdit}
            description="Organize, manage and monitor information and tasks with easy-to-use tools."
            action="Add board"
            onClick={this.onAddBoardClick.bind(this)}
            image={<div className="project-canvas-empty__boards" />} />
        );
      }
      return (
        <Empty description="No boards yet"
          image={<div className="project-canvas-empty__boards" />} />
      );
    }
    return this.props.boards
      .map((board) => <Board key={board.id} dragging={this.state.dragging} {...board} />);
  }

  onAddBoardClick() {
    this.props.addBoard(this.props.projectId, {
      name: `Board ${this.props.boards.length + 1}`,
      sort: this.props.boards.length,
    });
  }

  getAddButton() {
    if (this.props.isWaitingForNewBoard) {
      return <Loader />;
    }
    if (this.props.canEdit) {
      return (
        <button type="button" className="wethod-button" onClick={this.onAddBoardClick.bind(this)}>
          New board
        </button>
      );
    }
    return null;
  }

  getContentClassName() {
    let name = 'project-canvas-boards__content';
    if (this.props.isWaitingForBoards) {
      name += 'project-canvas-boards__content--blocked';
    }
    return name;
  }

  onDrag() {
    this.setState({ dragging: true });
  }

  onDragEnd() {
    this.setState({ dragging: false });
  }

  onDrop(el, target, source, sibling) {
    const from = parseInt(el.getAttribute('data-sort'));
    const toNextSiblingSort = sibling ? parseInt(sibling.getAttribute('data-sort')) : this.props.boards.length;
    const to = toNextSiblingSort > from ? toNextSiblingSort - 1 : toNextSiblingSort;

    const sortedBoards = arrayMover(from, to, this.props.boards)
      .map((board, index) => ({
        ...board,
        sort: index,
      })); // Set the right sort, based on index

    this.props.sortBoards(this.props.projectId, sortedBoards);

    clearTimeout(this.sortBoardsTimer);
    this.sortBoardsTimer = setTimeout(() => {
      const map = sortedBoards.map((board) => ({
        id: board.id,
        sort: board.sort,
      }));
      this.props.saveBoardsSort(this.props.projectId, map);
    }, 1000);
  }

  /**
   * Init Dragula on the element componentBackingInstance.
   * @param componentBackingInstance
   */
  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance && this.props.canEdit) {
      const options = {
        // move only if using handle
        moves: (el, container, handle) => handle.classList
          .contains('project-canvas-board-drag-handle'),
      };

      return dragula([componentBackingInstance], options)
        .on('drag', this.onDrag.bind(this))
        .on('drop', this.onDrop.bind(this))
        .on('dragend', this.onDragEnd.bind(this));
    }
  }

  render() {
    return (
      <div className="project-canvas-boards">
        <div className="project-canvas-boards__header">
          <h2>Boards</h2>
          <div className="project-canvas-boards__actions">
            {this.getAddButton()}
          </div>
        </div>
        <div className={this.getContentClassName()} ref={(el) => this.boardsEl = el}>
          {this.getContent()}
        </div>
      </div>
    );
  }
};
