const React = require('react');
const PropTypes = require('prop-types');
const AutocompleteBase = require('../Autocomplete.react');
const TextField = require('../../TextField/BasicTextField/BasicTextField.react');

const BasicAutocomplete = ({ children, ...rest }) => (
  <AutocompleteBase input={TextField} {...rest}>
    {children}
  </AutocompleteBase>
);

BasicAutocomplete.defaultProps = {
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
};

BasicAutocomplete.propTypes = {
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
};

module.exports = BasicAutocomplete;
