module.exports = class AvailableLevel {
  constructor(level) {
    this.level = level;
  }

  getCost() {
    return this.level.level_cost;
  }

  getPrice() {
    return this.level.level_price;
  }

  getAcronym() {
    return this.level.level_short_name;
  }

  getName() {
    return this.level.level_name;
  }

  getId() {
    return this.level.level.id;
  }

  getPriceListLevelId() {
    return this.level.id;
  }

  getOrder() {
    return this.level.sort;
  }
};
