const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const BuFilter = require('../../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');

const Actions = ({ buEnabled, buFilter, changeBU }) => {
  const getBuFilter = () => {
    if (buEnabled) {
      return (
        <div className="budget-changelog__actions">
          <BuFilter onApply={changeBU}
            selectedValues={buFilter} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wethod-section-actions">
      <SectionHeader current_section="Budgets Approval"
        preview_anchor_id="budget-changelog"
        helper_url="reports/index/#budget-changelog"
        big />
      {getBuFilter()}
    </div>
  );
};

module.exports = Actions;
