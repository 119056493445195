const constants = require('./constants');
const QuarterFilter = require('../../finance/clients/detail/company/models/TimeFilter/QuarterFilter');
const searchFilter = require('./services/filterEmployees');
const sortEmployees = require('./services/sortEmployees');

class KudosReducer {
  constructor(state) {
    const timeFilter = new QuarterFilter();

    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      timeFilter: timeFilter.toJSON(),
      criteria: [],
      employees: [],
      // Team member data
      selectedTeamCriterion: null,
      filteredTeam: [],
      loadTeam: {
        size: 0,
        page: 0,
      },
      keywordTeam: '', // search keyword
      sortByTeam: {
        col: 'avg',
        order: 'desc',
      },
      // PM data
      selectedPMCriterion: null,
      filteredPM: [],
      loadPM: {
        size: 0,
        page: 0,
      },
      keywordPM: '', // search keyword
      sortByPM: {
        col: 'avg',
        order: 'desc',
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    let selectedCriterion = null;
    switch (action.type) {
      case constants.GET_KUDOS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('kudos'),
          criteria: [],
          employees: [],
          // Team member data
          selectedTeamCriterion: null,
          keywordTeam: '', // Reset keyword
          filteredTeam: [],
          loadTeam: {
            ...state.loadTeam,
            page: 1, // Reset employees to first page
          },
          // PM data
          selectedPMCriterion: null,
          keywordPM: '', // Reset keyword
          filteredPM: [],
          loadPM: {
            ...state.loadPM,
            page: 1, // Reset employees to first page
          },
        };
      case constants.GET_KUDOS_SUCCESS:
        selectedCriterion = action.criteria.length ? action.criteria[0].id : null;
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'kudos'),
          criteria: action.criteria,
          employees: action.employees,
          // Team member data
          selectedTeamCriterion: selectedCriterion,
          filteredTeam: sortEmployees(
            searchFilter(state.keywordTeam, action.employees, selectedCriterion, 'as_team_member'),
            selectedCriterion,
            'as_team_member',
            state.sortByTeam.order,
          ),
          // PM data
          selectedPMCriterion: selectedCriterion,
          filteredPM: sortEmployees(
            searchFilter(state.keywordPM, action.employees, selectedCriterion, 'as_pm'),
            selectedCriterion,
            'as_pm',
            state.sortByPM.order,
          ),
        };
      case constants.UPDATE_TIME_FILTER:
        return {
          ...state,
          timeFilter: action.filter,
        };
      case constants.UPDATE_TEAM_CRITERION:
        return {
          ...state,
          selectedTeamCriterion: action.criterion,
          filteredTeam: sortEmployees(
            searchFilter(state.keywordTeam, state.employees, action.criterion, 'as_team_member'),
            action.criterion,
            'as_team_member',
            state.sortByTeam.order,
          ),
          loadTeam: {
            ...state.loadTeam,
            page: 1, // Reset employees to first page
          },
        };
      case constants.LOAD_TEAM:
        return {
          ...state,
          loadTeam: {
            size: action.size,
            page: action.page,
          },
        };
      case constants.FILTER_TEAM:
        return {
          ...state,
          keywordTeam: action.keyword,
          filteredTeam: sortEmployees(
            searchFilter(action.keyword, state.employees, state.selectedTeamCriterion, 'as_team_member'),
            state.selectedTeamCriterion,
            'as_team_member',
            state.sortByTeam.order,
          ),
          loadTeam: {
            ...state.loadTeam,
            page: 1, // Reset employees to first page
          },
        };
      case constants.SORT_TEAM:
        return {
          ...state,
          sortByTeam: {
            col: action.col,
            order: action.order,
          },
          filteredTeam: sortEmployees(
            state.filteredTeam,
            state.selectedTeamCriterion,
            'as_team_member',
            action.order,
          ),
          loadTeam: {
            ...state.loadTeam,
            page: 1, // Reset employees to first page
          },
        };
      case constants.UPDATE_PM_CRITERION:
        return {
          ...state,
          selectedPMCriterion: action.criterion,
          filteredPM: sortEmployees(
            searchFilter(state.keywordPM, state.employees, action.criterion, 'as_pm'),
            action.criterion,
            'as_pm',
            state.sortByPM.order,
          ),
          loadPM: {
            ...state.loadPM,
            page: 1, // Reset employees to first page
          },
        };
      case constants.LOAD_PM:
        return {
          ...state,
          loadPM: {
            size: action.size,
            page: action.page,
          },
        };
      case constants.FILTER_PM:
        return {
          ...state,
          keywordPM: action.keyword,
          filteredPM: sortEmployees(
            searchFilter(action.keyword, state.employees, state.selectedPMCriterion, 'as_pm'),
            state.selectedPMCriterion,
            'as_pm',
            state.sortByPM.order,
          ),
          loadPM: {
            ...state.loadPM,
            page: 1, // Reset employees to first page
          },
        };
      case constants.SORT_PM:
        return {
          ...state,
          sortByPM: {
            col: action.col,
            order: action.order,
          },
          filteredPM: sortEmployees(
            state.filteredPM,
            state.selectedPMCriterion,
            'as_pm',
            action.order,
          ),
          loadPM: {
            ...state.loadPM,
            page: 1, // Reset employees to first page
          },
        };
      default:
        return state;
    }
  }
}

module.exports = KudosReducer;
