const React = require('react');
const Language = require('../../containers/Languages/Language');

module.exports = class Languages extends React.Component {
  getLanguages() {
    return this.props.languages.map((language) => <Language key={language.id} {...language} />);
  }

  getContent() {
    if (this.props.languages.length > 0) return this.getLanguages();
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile-languages">
        {this.getContent()}
      </div>
    );
  }
};
