/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */
const React = require('react');
const Bubble = require('../../../../../../common/react/bubble/Bubble.react');
const EventController = require('./EventController.react.js');
const MilestoneModel = require('../models/MilestoneModel');

class ProjectDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bubble: {
        show: false,
      },
      event: this.props.event,
    };

    this.clickY = null;

    this.toggleBubble = this.toggleBubble.bind(this);
    this.create = this.create.bind(this);
    this.getDot = this.getDot.bind(this);
    this.getBubblePosition = this.getBubblePosition.bind(this);
    this.getClass = this.getClass.bind(this);
    this.delete = this.delete.bind(this);
    this.hideBubble = this.hideBubble.bind(this);
    this.showBubble = this.showBubble.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      event: nextProps.event,
    });
  }

  toggleBubble(event) {
    // Update click position
    this.clickY = event.clientY;

    // Close all 'bubble'
    dispatcher.trigger('heatmap:close:bubbles');

    // If we are showing the bubble add a listener to close the 'bubble', otherwise stop listening
    if (this.state.bubble.show) {
      dispatcher.off('heatmap:close:bubbles', this.hideBubble);
    } else if (!this.props.isBubbleShown) {
      dispatcher.on('heatmap:close:bubbles', this.hideBubble);
      this.showBubble();
    }
  }

  hideBubble() {
    this.setState({ bubble: { show: false } });
    this.create(this.state.event);
    dispatcher.off('heatmap:close:bubbles', this.hideBubble);
  }

  showBubble() {
    this.setState({ bubble: { show: true } });
  }

  getBubblePosition() {
    if (this.clickY < 372) {
      return 'bottom';
    }
    return 'top';
  }

  getOpacity() {
    if (this.state.bubble.show) {
      return {
        background: '#EBEEF1',
      };
    }
    return {
      background: `rgba(54, 143, 245,${this.props.heat})`,
    };
  }

  /**
   * Add or update an 'event'
   * @param event
   */
  create(event) {
    if (event) {
      event.date = this.props.date;
      event.project = this.props.projectId;

      const eventModel = JSON.parse(JSON.stringify(event));
      if (Number.isNaN(event.type)) {
        eventModel.type = event.type.id;
      }

      const milestoneModel = new MilestoneModel(eventModel);
      milestoneModel.persist().then((response) => {
        this.setState({ event: response.attributes });
        this.props.eventAdded(response.attributes);
      });
    }
  }

  /**
   * Delete an 'event'
   * @param event
   */
  delete(event) {
    const milestoneModel = new MilestoneModel({ id: event.id });
    milestoneModel.destroy();
    this.props.eventDeleted(event.project, event.date);

    this.setState({
      bubble: {
        show: false,
      },
      event: null,
    });
  }

  getDot() {
    if (this.state.event) {
      if (this.props.heat > 0.4) {
        return (<div className={`event-dot icon--${this.state.event.type.name} icon-light`} />);
      }
      return (<div className={`event-dot icon--${this.state.event.type.name}`} />);
    }
  }

  getClass() {
    let className = 'project__day';
    if (this.state.event) {
      className += ' project__day--event';
    }
    return className;
  }

  render() {
    return (
      <div className={this.getClass()} onClick={this.toggleBubble}>
        {this.getDot()}
        <div className="project__day-content" style={this.getOpacity()} />
        <Bubble
          show={this.state.bubble.show}
          position={this.getBubblePosition()}
          content={(
            <EventController
              people={this.props.employees}
              event={this.state.event}
              eventTypes={this.props.eventTypes}
              saveEvent={this.create}
              deleteEvent={this.delete} />
          )} />
      </div>
    );
  }
}

module.exports = ProjectDay;
