const constants = require('./constants');
const QuarterFilter = require('../../finance/clients/detail/company/models/TimeFilter/QuarterFilter');

class DemographicsReducer {
  constructor(state) {
    const timeFilter = new QuarterFilter();

    this.state = {
      ...state,
      waitingFor: [],
      modal: null,
      modalData: null,
      timeFilter: timeFilter.toJSON(),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.UPDATE_TIME_FILTER:
        return {
          ...state,
          timeFilter: action.filter,
        };
      case constants.SHOW_TARGET_MODAL:
        return {
          ...state,
          modal: 'target',
          modalData: {
            target: state.headcount ? state.headcount.target : null,
            canEditSettings: state.canEditSettings,
          },
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      case constants.GET_TRENDS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('trends'),
        };
      case constants.GET_TRENDS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'trends'),
          ...action.data,
        };
      case constants.SAVE_TARGET_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('target'),
        };
      case constants.SAVE_TARGET_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'target'),
          headcount: {
            ...state.headcount,
            target: action.target,
          },
          modal: null,
          modalData: null,
        };
      default:
        return state;
    }
  }
}

module.exports = DemographicsReducer;
