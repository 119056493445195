const React = require('react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const IconLink = require('../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Escaper = require('../../../../../../../common/react/formatters/Escaper.react');
const Number = require('../../../../../../../common/react/formatters/NumberFormatter.react');

const Project = ({ id, name, pm, periodOfSupply, ordered, projectType, jobOrder }) => (
  <TableRow>
    <TableCell key="name"
      name="name"
      className="supplier-project__column--name">
      <div className="supplier-project__multirow-info">
        <Escaper>{name}</Escaper><Escaper className="supplier-project__job-order">{jobOrder}</Escaper>
      </div>
    </TableCell>
    <TableCell key="pm"
      name="pm"
      className="supplier-project__column--pm">
      <Escaper>{pm.name}</Escaper>
    </TableCell>
    <TableCell key="project_type"
      name="project_type"
      className="supplier-project__column--type">
      <Escaper>{projectType ? projectType.name : ''}</Escaper>
    </TableCell>
    <TableCell key="period"
      name="period"
      className="supplier-project__column--period">
      {periodOfSupply}
    </TableCell>
    <TableCell key="ordered"
      name="ordered"
      className="supplier-project__column--ordered">
      <Number>{ordered}</Number>
    </TableCell>
    <TableCell key="actions"
      className="supplier-project_column--actions">
      <IconLink label="Go to project"
        borderless
        href={`/#pipeline/projects?id=${id}`}
        target="_blank">
        <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
      </IconLink>
    </TableCell>
  </TableRow>
);

module.exports = Project;
