const { connect } = require('react-redux');
const Component = require('../components/Skills.react');

const mapStateToProps = (state) => ({
  tags: state.skills,
  knownTags: state.known_skills,
  placeholder: 'Your secret weapons',
});

module.exports = connect(mapStateToProps)(Component);
