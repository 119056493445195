const React = require('react');
const Content = require('./PricelistModalContent.react');
const RequestManager = require('../../../../containers/RequestManager');

/**
 * Modal used to change pricelist applyed to budget.
 * @param closeModal
 * @param {number} projectClientId ID of the budget project client
 * @param areaId ID of the area
 * @param {number} currentPricelistId ID of the current selected pricelist
 * @return {JSX.Element}
 * @constructor
 */
const PricelistModal = ({
  closeModal,
  projectClientId,
  areaId,
  selectedPriceListId,
  currentPricelistId = null,
}) => (
  <RequestManager>
    <Content
      closeModal={closeModal}
      projectClientId={projectClientId}
      currentPricelistId={selectedPriceListId || currentPricelistId}
      areaId={areaId} />
  </RequestManager>
);

module.exports = PricelistModal;
