const React = require('react');
const NumericValue = require('../../../../../common/components/NumericValue.react');

module.exports = ({ value }) => {
  if (value > 0) {
    return (
      <span>
        (
        <NumericValue value={value} />
        )
      </span>
    );
  }
  return <NumericValue value={value} />;
};
