'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.saveBubbles = function (viewObj, data) {
    data.filterLabel = (data.filterType === 'company overview') ? '/' : data.filterLabel;
    data.filterLabel = (data.filterType === 'me') ? '/' : data.filterLabel;

    var options = {
      id: viewObj.model.get('id'),
      filterType: data.filterType,
      filterValue: data.filterValue || null,
      label: data.filterLabel || null,
    };

    // allineo il modello
    viewObj.model.get('config').active_filter.name = data.filterType;
    viewObj.model.get('config').active_filter.value = data.filterValue;
    viewObj.model.get('config').active_filter.label = data.filterLabel;
    viewObj.model.set({ label: data.filterLabel });

    viewObj.ui.deleteEl.hide();

    var saveWidgetRequest = Wethod.request('save:user:bubbles:widget', options);
    $.when(saveWidgetRequest).done(function () {
      var renderOptions = { endpoint: DashboardApp.getEndpoint(viewObj.model) };

      var userWidgetRequest = Wethod.request('get:user:widget', renderOptions);
      $.when(userWidgetRequest).done(function (responseModel) {
        delete responseModel.attributes.cached_on;
        delete responseModel.attributes.endpoint;

        // recupero i dati del grafico
        var filter = {
          value: viewObj.model.get('config').active_filter.value,
          type: viewObj.model.get('config').active_filter.name,
        };
        var chartBubbles = DashboardApp.getBubblesChartSeries(responseModel.attributes, 'actual', filter, viewObj);

        // aggiotno i dati del grafico
        viewObj.options.series = chartBubbles.series;
        viewObj.options.maxYAxis = chartBubbles.maxYAxis;

        viewObj.options.new = false;
        viewObj.render();
      });
    });
  };

  this.deleteBubbles = function (viewObj) {
    var options = {
      id: viewObj.model.get('id'),
    };

    viewObj.ui.saveEl.hide();
    var deleteWidgetRequest = Wethod.request('delete:user:widget', options);
    $.when(deleteWidgetRequest).done(function () {
      viewObj.options.widgetView.destroy();
    });
  };
});
