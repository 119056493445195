/* eslint-disable consistent-return,react/prop-types,react/sort-comp */
const React = require('react');
const MenuArea = require('./Area.react');

module.exports = class MenuItem extends React.Component {
  getItemIconClassName() {
    const icon = this.props.item.getIcon();
    if (icon) {
      return `app-nav-mobile-item__icon wethod-icon-${icon} wethod-icon-${icon}--black`;
    }
  }

  getDropdownIconClassName() {
    let name = 'app-nav-mobile-item__icon-dropdown wethod-icon-dropdown  wethod-icon-dropdown--black';
    if (this.props.open) {
      name += ' wethod-icon-dropdown--expanded';
    }
    return name;
  }

  onClick() {
    const item = this.props.open ? null : this.props.item;
    this.props.showItem(item);
  }

  getPanelStyle() {
    if (this.props.open) {
      return { maxHeight: `${57 * this.props.areas.length}px` };
    }
  }

  getAreas() {
    return this.props.areas.map((area) => (
      <MenuArea key={area.getLabel()}
        area={area}
        showArea={this.props.showArea} />
    ));
  }

  render() {
    return (
      <div className="app-nav-mobile-item">
        <button type="button"
          className="app-nav-mobile-item__button"
          onClick={this.onClick.bind(this)}>
          <span className={this.getItemIconClassName()} />
          <span className="app-nav-mobile-item__label">{this.props.item.getLabel()}</span>
          <span className={this.getDropdownIconClassName()} />
        </button>
        <ul className="app-nav-mobile-item__panel"
          style={this.getPanelStyle()}>
          {this.getAreas()}
        </ul>
      </div>
    );
  }
};
