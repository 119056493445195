const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const PV = require('../../../../common/components/PercentageValue.react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({
  payroll, targetType, costsOnProduction, planned,
}) => {
  const getProductionComparator = () => {
    const labels = {
      much_lower: 'well below',
      lower: 'below',
      almost_equal: 'in line with',
      equal: 'equal to',
      greater: 'above',
      much_greater: 'well above',
    };

    return <Comparator a={costsOnProduction} b={planned} labels={labels} />;
  };

  const getOnPlanned = () => {
    if (planned) {
      return (
        <span>
          which is {getProductionComparator()} the <PV value={planned} /> planned in the
          {' '}
          {targetType}
        </span>
      );
    }
    return null;
  };

  const getOnProduction = () => {
    if (costsOnProduction) {
      return (
        <span>
          , <PV value={costsOnProduction} /> of the monthly production {getOnPlanned()}
        </span>
      );
    }
    return null;
  };

  return (
    <Paragraph>
      The overall payroll amount of <NV value={payroll} /> {getOnProduction()}.
    </Paragraph>
  );
};
