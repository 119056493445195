const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const Footer = require('../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');

const IgnoreAlertsModal = ({ updatedSegments, closeModal, showConfirmPauseModal }) => {
  function handleSave() {
    showConfirmPauseModal(updatedSegments);
  }

  return (
    <Modal title="Are you sure you want to ignore the alerts?" onClose={closeModal}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You can still continue, but ignoring an alert could lead to data {' '}
            inconsistencies within a frozen segment.
          </ModalBodyBlock>
        </ModalBody>
        <Footer>
          <FooterAction onClick={closeModal}>
            Back
          </FooterAction>
          <FooterAction onClick={handleSave}>
            Yes, ignore alerts
          </FooterAction>
        </Footer>
      </ModalContent>
    </Modal>
  );
};

module.exports = IgnoreAlertsModal;
