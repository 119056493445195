// eslint-disable-next-line no-unused-vars
const LevelAmountNode = require('./LevelAmountNode');
// eslint-disable-next-line no-unused-vars
const TaskTotal = require('../NodeTotal/TaskNodeTotal');
const Node = require('./StructureNode');
const Previous = require('../NodePrevious/TaskNodePrevious');
const LevelAmountPrevious = require('../NodePrevious/LevelAmountNodePrevious');
const LevelAmountTotal = require('../NodeTotal/LevelAmountNodeTotal');

/**
 * @abstract
 * @type {Task}
 */
module.exports = class Task extends Node {
  /**
   * @param task
   * @param {LevelAmountNode[]} levelAmounts
   * @param {AvailableLevel[]} availableLevels
   */
  constructor(task, levelAmounts, availableLevels) {
    super();
    this.task = task;
    this.levelAmounts = levelAmounts;
    this.availableLevels = availableLevels;
  }

  /**
   * Transforms the given array into an object map using the give key.
   * @param array
   * @private
   * @param key
   * @returns {{}}
   */
  static getMapFromArray(array, key) {
    const map = {};
    array.forEach((item) => map[item[key]] = item);
    return map;
  }

  /**
   * @returns {LevelAmountNode[]}
   */
  getLevels() {
    return this.levelAmounts;
  }

  getMarkup() {
    return this.task.markup;
  }

  getExternalCost() {
    return this.task.external_cost;
  }

  getOrder() {
    return this.task.order;
  }

  getQuantity() {
    return this.task.product_quantity;
  }

  getPreviousExternalCost() {
    const { was } = this.task;
    return was ? was.external_cost : this.getExternalCost();
  }

  getPreviousMarkup() {
    const { was } = this.task;
    return was ? was.markup : this.getMarkup();
  }

  getPreviousQuantity() {
    const { was } = this.task;
    return was ? was.product_quantity : this.getQuantity();
  }

  getMarkupAmount() {
    return (this.getExternalCost() / 100) * this.getMarkup();
  }

  /**
   * Return amount of days for the given level in this task.
   * @param levelId
   * @return {number|null}
   */
  getDaysForLevel(levelId) {
    const found = this.levelAmounts.filter((level) => level.getId() === levelId);

    return found.length ? found[0].getTotal().getDays() : null;
  }

  /**
   * Return list of levels, each level contains sum of days related to it.
   * @return {LevelAmountTotal[]}
   */
  getDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const daysForLevel = this.getDaysForLevel(level.getId());

      daysByLevel.push(new LevelAmountTotal(daysForLevel, 0, 0, level.getId()));
    }
    return daysByLevel;
  }

  /**
   * Return previous budget version amount of days for the given level in this task.
   * @param levelId
   * @return {number|null}
   */
  getPreviousDaysForLevel(levelId) {
    const found = this.levelAmounts.filter((level) => level.getId() === levelId);

    return found.length ? found[0].getPrevious().getDays() : null;
  }

  /**
   * @returns {TaskTotal}
   */
  getTotal() {
    return this.total;
  }

  /**
   * @return {TaskNodePrevious}
   */
  getPrevious() {
    return this.previous;
  }

  /**
   * @return {AvailableLevel[]}
   */
  getAvailableLevels() {
    return this.availableLevels;
  }

  /**
   * Return list of levels, each level contains sum of previous days related to it.
   * @return {*[]}
   */
  getPreviousDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const daysForLevel = this.getPreviousDaysForLevel(level.getId());

      daysByLevel.push(new LevelAmountPrevious(daysForLevel, level.getId()));
    }
    return daysByLevel;
  }

  /**
   * @return {TaskNodePrevious}
   */
  calculatePrevious() {
    return new Previous(this.getPreviousExternalCost(), this.getPreviousMarkup(),
      this.getPreviousQuantity(), true, this.getPreviousDaysByLevel());
  }

  toJson() {
    return {
      ...this.task,
      levels: this.getLevels().map((level) => level.toJson()),
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };
  }
};
