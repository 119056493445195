const { connect } = require('react-redux');
const Component = require('../components/StatusFilter.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  sort: state.sortBy.order,
  orderBy: state.sortBy.col,
  keyword: state.keyword,
  size: state.size,
  isWaiting: state.waitingFor.filter((key) => key === 'load-orders' || key === 'filter-orders').length > 0,
  selectedValues: state.statusFilter,
  availableStatuses: state.availableStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  onApply: (size, keyword, orderBy, sort, statuses) => dispatch(
    actions.filterOrders(size, keyword, orderBy, sort, statuses),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
