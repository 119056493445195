const request = require('./Request');

const ProjectStatusCorrection = {
  apply(budgetId) {
    return request({
      method: 'post',
      url: `/project-status-correction/${budgetId}`,
    });
  },
  get(budgetId) {
    return request({
      method: 'get',
      url: `/project-status-correction/${budgetId}`,
    });
  },
};

module.exports = ProjectStatusCorrection;
