/* eslint-disable react/no-did-update-set-state,react/sort-comp,no-prototype-builtins,
no-param-reassign,react/no-access-state-in-setstate,react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../TextInput.react');
const Item = require('./Item.react');
const EmptyItem = require('../SearchSelect/EmptyItem.react');

/**
 * Select component with custom search.
 * The items should always be filtered: the component does not include a default search;
 * Pass an "onValidate" callback to get validation errors.
 * Supported constraints: same as TextInput.
 *
 * @type {module.Select2}
 */
class Select2 extends React.Component {
  constructor(props) {
    super(props);

    this.clickOutsideHandler = this.handleClickOutside.bind(this);

    this.state = {
      param: this.props.param ? this.props.param : 'name',
      value: this.getValue(), // Text currently in search input
      showList: false,
      current: this.props.current ? this.props.current : null, // Currently selected item
      iconComponent: this.props.iconComponent ? this.props.iconComponent : (({ onClick }) => (
        <button type="button"
          className="wethod-button wethod-search-select__add-button"
          onClick={onClick}>
          +
        </button>
      )),
      itemComponent: this.props.itemComponent ? this.props.itemComponent : Item,
    };
  }

  getValue() {
    if (this.props.current) {
      const param = this.props.param ? this.props.param : 'name';
      return this.props.current[param];
    }
    return '';
  }

  componentDidMount() {
    document.addEventListener('click', this.clickOutsideHandler, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const oldVal = prevProps.current ? prevProps.current[prevState.param] : '';
    const newVal = this.props.current ? this.props.current[prevState.param] : '';
    if (oldVal !== newVal) {
      this.setState({
        showList: false,
        current: this.props.current,
        value: newVal,
      });
      this.props.onChange(this.props.name, this.props.current);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutsideHandler, true);
  }

  handleClickOutside(event) {
    const domNode = this.node;
    // const { current } = this.state;

    if (!domNode || !domNode.contains(event.target)) {
      if (!this.state.current && this.state.value) {
        this.reset();
      } else if (this.state.current && !this.consistentValue()) {
        this.reset();
      } else {
        this.setState({ showList: false });
      }
    }
  }

  reset() {
    this.setState({
      value: '',
      current: null,
      showList: false,
    },
    () => {
      this.props.onChange(this.props.name, this.state.current);
      this.handleChange(this.props.name, this.state.value);
    });
  }

  consistentValue() {
    return this.state.value === this.state.current[this.state.param];
  }

  onCreateClick() {
    this.props.onCreateClick(this.state.value);
  }

  getEmptyItem() {
    if (this.props.empty) {
      return this.props.empty;
    }

    return <EmptyItem />;
  }

  getItems() {
    const prefix = this.props.prefix ? this.props.prefix : 'select2-';
    return this.props.items.map((item) => {
      const itemId = item.id !== undefined ? item.id : _.uniqueId(prefix);
      const ItemComponent = this.state.itemComponent;
      return (
        <ItemComponent key={itemId}
          onClick={this.updateCurrent.bind(this)}
          item={item}
          param={this.state.param} />
      );
    });
  }

  getList() {
    const { value } = this.state;
    const IconComponent = this.state.iconComponent;
    if (this.props.onCreateClick) {
      if (!this.props.items.find((item) => item[this.state.param].toLowerCase() === value.toLowerCase() || value === '')) {
        return (
          <div>
            <ul className="wethod-search-select__list">
              {this.getItems()}
            </ul>
            <IconComponent onClick={this.onCreateClick.bind(this)} />
          </div>
        );
      }
    }

    return (
      <ul className="wethod-search-select__list">
        {this.getItems()}
      </ul>
    );
  }

  getElements() {
    const IconComponent = this.state.iconComponent;
    if (this.state.showList) {
      if (!this.hasResults()) {
        if (this.props.onCreateClick) {
          return (
            <IconComponent onClick={this.onCreateClick.bind(this)} />
          );
        }
        return (
          <ul className="wethod-search-select__list">
            {this.getEmptyItem()}
          </ul>
        );
      }
      return (
        this.getList()
      );
    }
    return null;
  }

  hasResults() {
    return this.props.items ? this.props.items.length > 0 : false;
  }

  updateCurrent(item) {
    if (!item.hasOwnProperty('id')) {
      item.created_at = +new Date();
    }
    this.setState({
      showList: false,
      current: item,
      value: item[this.state.param],
    },
    () => {
      this.props.onChange(this.props.name, this.state.current);
    });
  }

  handleChange(name, value) {
    this.setState({ value });

    if (this.props.onFilter) {
      this.props.onFilter(this.props.name, value);
    }
  }

  handleFocus(e) {
    this.setState({ showList: true });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  render() {
    return (
      <div className="wethod-search-select" ref={(node) => this.node = node}>
        <Input name={this.props.name}
          value={this.state.value}
          constraints={this.props.constraints}
          onValidate={this.props.onValidate}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.props.onBlur}
          placeholder={this.props.placeholder} />
        {this.getElements()}
      </div>
    );
  }
}

Select2.propTypes = {

  name: PropTypes.string.isRequired,

  /**
   * The attribute of every item to be shown in search input and list,
   * by default is 'name'
   */
  param: PropTypes.string,

  placeholder: PropTypes.string,

  /**
   * Validation parameters
   * @see TextInput
   */
  constraints: PropTypes.string,

  /**
   * Currently selected item
   */
  current: PropTypes.object,

  /**
   * The list of items to show, already filtered
   */
  items: PropTypes.array,

  /**
   * A prefix assigned to the uniqueID that is generated for each item of the list.
   * This is used only if the items have no id field
   */
  prefix: PropTypes.string,

  /**
   * Filter function, called on every input change
   */
  onFilter: PropTypes.func,

  /**
   * Function called when an item of the list is selected
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Function called when no item is present and the 'add' button is clicked
   */
  onCreateClick: PropTypes.func,

  onValidate: PropTypes.func,

  onFocus: PropTypes.func,

  onBlur: PropTypes.func,
  iconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),

  /**
   * Element shown when the list is empty
   */
  empty: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
};

Select2.defaultProps = {
  param: '',
  placeholder: '',
  constraints: null,
  current: null,
  items: [],
  onFilter: null,
  onCreateClick: null,
  onValidate: null,
  onFocus: null,
  onBlur: null,
  iconComponent: null,
  itemComponent: null,
  empty: null,
  prefix: null,
};

module.exports = Select2;
