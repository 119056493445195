/* eslint-disable class-methods-use-this */
const React = require('react');
const Card = require('../../../../../../common/react/Card.react');

module.exports = class ImportantInfo extends React.Component {
  getProjectTypeStyle() {
    return { backgroundColor: this.props.project.project_type.color };
  }

  getUnescapedContent(text) {
    return _.unescape(text);
  }

  render() {
    return (
      <Card>
        <div>
          <div
            className="timeline-project__project-type"
            style={this.getProjectTypeStyle()} />
          <div className="client-info--secondary">{this.props.project.client.corporate_name}</div>
          <div className="client-info--primary">{this.getUnescapedContent(this.props.project.name)}</div>
        </div>
      </Card>
    );
  }
};
