const React = require('react');
const { formatDecimalNumber } = require('../../../../../services/FormatService');

/**
 * Injects methods useful to deal with an item.
 * @param children
 * @param item
 * @param isValueAsUnit
 * @param rest
 * @returns {React.FunctionComponentElement<unknown>}
 * @constructor
 */
const OpportunityManager = ({ children, item, isValueAsUnit, ...rest }) => {
  /**
   * Return the value as K when it was in unit
   * @param value
   * @returns {number}
   */
  const formatValueAsK = (value) => Math.round(value) / 1000;

  /**
   * Return the properly formatted value as K, ready to be saved
   * @param value
   * @returns {*}
   */
  const formatValueChanges = (value) => (isValueAsUnit ? formatValueAsK(value) : value);

  /**
   * Calc the value of the project in units
   * @returns {number}
   */
  const getValueAsUnit = () => Math.round(item.value * 1000);

  /**
   * Calc the value of the project in K, rounded
   * @returns {number}
   */
  const getValueAsK = () => Math.round(item.value);

  /**
   * Returns project value in K or units, depending on the company preferences.
   * @returns {number}
   */
  const getValue = () => (isValueAsUnit ? getValueAsUnit() : getValueAsK());

  /**
   * Returns project value in K or units, depending on the company preferences.
   * @returns {number}
   */
  const getFormattedValue = () => (formatDecimalNumber(getValue(), 0));

  return React
    .cloneElement(children, {
      getValue,
      getFormattedValue,
      isValueAsUnit,
      formatValueChanges,
      ...rest,
    });
};

module.exports = OpportunityManager;
