/* eslint-disable class-methods-use-this */
const constants = require('./constants');
const searchFilter = require('../services/filterDetailed');
const businessUnitFilter = require('../services/businessUnitFilter');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');
const FeatureService = require('../../../../../../services/FeatureService');
const ModalController = require('./components/ModalController.react');

class TimesheetDetailedReducer {
  constructor(state) {
    this.businessUnitService = new BusinessUnitService();

    this.state = {
      ...state,
      projectsFilter: '',
      filteredProjects: [],
      isLoading: false,
      isSaving: false,
      modal: null,
      showWeeklyHoursModal: false,
      projectsPageSize: 0,
      scrollbarWidth: 0,
      buFilter: this.getBuForInitialFilter(state.userId),
      buEnabled: this.businessUnitService.isEnabled(),
      formData: {},
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Returns the window of projects already loaded into the DOM by infinite scroll.
   *
   * @param projects
   * @param currentLength
   * @param pageSize
   */
  getVisibleProjects(projects, currentLength, pageSize) {
    return projects.slice(0, currentLength + pageSize);
  }

  getFilteredProjects(filters, projects) {
    return searchFilter(filters.keyword, businessUnitFilter(filters.bu, projects));
  }

  /**
   * Return first business unit filter to use when rendering section:
   * - Last selected business units if user is seeing his own timesheet (no user filter applied)
   * - All business units otherwise. This is to avoid case in whicha user is editing another user
   * timesheet but cannot find a projects because the two guys belong to different units
   *
   * @param userFilter user filter. If missing, user is viewing his own timesheet
   * @returns {string[]}
   */
  getBuForInitialFilter(userFilter) {
    const userFilterApplied = userFilter !== null;
    return userFilterApplied ? this.businessUnitService.getIdListForCompany()
      : this.businessUnitService.getLastSelected();
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
          formData: {},
        };
      case constants.WEEKLY_HOURS_CLOSE_MODAL:
        return {
          ...state,
          showWeeklyHoursModal: false,
          formData: {},
        };
      case constants.NOTE_OPEN_MODAL:
        return {
          ...state,
          modal: ModalController.NOTES_MODAL,
          formData: action.area,
        };
      case constants.WEEKLY_HOURS_OPEN_MODAL:
        return {
          ...state,
          showWeeklyHoursModal: true,
          formData: action.hours,
        };
      case constants.FILTER_PROJECTS: {
        const filters = {
          keyword: action.keyword,
          bu: state.buFilter,
        };
        const filteredProjects = this.getFilteredProjects(filters, state.projects);
        return {
          ...state,
          projectsFilter: action.keyword,
          filteredProjects: this.getVisibleProjects(filteredProjects, 0, state.projectsPageSize),
        };
      }
      case constants.CHANGE_BU_FILTER: {
        const filters = {
          keyword: state.projectsFilter,
          bu: action.bu,
        };
        const filteredProjects = this.getFilteredProjects(filters, state.projects);
        return {
          ...state,
          buFilter: action.bu,
          filteredProjects: this.getVisibleProjects(filteredProjects, state.filteredProjects.length,
            state.projectsPageSize),
        };
      }
      case constants.LOAD_MORE_PROJECTS: {
        const filters = {
          keyword: state.projectsFilter,
          bu: state.buFilter,
        };
        const filteredProjects = this.getFilteredProjects(filters, state.projects);
        return {
          ...state,
          projectsPageSize: action.pageSize,
          filteredProjects: this.getVisibleProjects(filteredProjects, state.filteredProjects.length,
            action.pageSize),
        };
      }
      case constants.NOTE_SAVE_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.NOTE_SAVE_SUCCESS:
        return {
          ...state,
          isSaving: false,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.area_id) {
                    return {
                      ...area,
                      notes: action.notes,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.area_id) {
                    return {
                      ...area,
                      notes: action.notes,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
        };
      case constants.DATE_CHANGE_REQUEST:
        return {
          ...state,
          filteredProjects: [],
          isLoading: true,
        };
      case constants.DATE_CHANGE_SUCCESS: {
        const filters = {
          keyword: state.projectsFilter,
          bu: state.buFilter,
        };
        const newWeekProjects = action.timesheet.projects;
        const totalWeekHours = action.timesheet.total_week_hours;
        const filteredProjects = this.getFilteredProjects(filters, newWeekProjects);
        return {
          ...state,
          isLoading: false,
          date: action.date,
          projects: newWeekProjects,
          filteredProjects: this.getVisibleProjects(filteredProjects, 0, state.projectsPageSize),
          totalWeekHours,
          employeeHolidays: Wethod.featureService
            .isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)
            ? action.employeeHolidays : [],
        };
      }
      case constants.HOURS_SAVE_REQUEST:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.areaId) {
                    return {
                      ...area,
                      isSaving: true,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.areaId) {
                    return {
                      ...area,
                      isSaving: true,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
        };
      case constants.HOURS_SAVE_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.areaId) {
                    return {
                      ...area,
                      isSaving: false,
                      hours: action.hours,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                areas: project.areas.map((area) => {
                  if (area.id === action.areaId) {
                    return {
                      ...area,
                      isSaving: false,
                      hours: action.hours,
                    };
                  }
                  return area;
                }),
              };
            }
            return project;
          }),
        };
      case constants.HOURS_SAVE_FAILURE:
        return {
          ...state,
          modal: ModalController.ERROR_MODAL,
        };
      case constants.FAVORITE_ADD:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                /* 777 is a fake id, it's only purpose is to create a bridge between the existing
                 Backbone's API and Redux.
                 */
                favorite: { id: 777 },
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                /* 777 is a fake id, it's only purpose is to create a bridge between the existing
                 Backbone's API and Redux.
                 */
                favorite: { id: 777 },
              };
            }
            return project;
          }),
        };
      case constants.FAVORITE_REMOVE:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: null },
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: null },
              };
            }
            return project;
          }),
        };
      case constants.ALIGN_HEADER_COLUMNS:
        return {
          ...state,
          scrollbarWidth: action.scrollbarWidth,
        };
      default:
        return state;
    }
  }
}

module.exports = TimesheetDetailedReducer;
