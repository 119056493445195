const React = require('react');
const Day = require('./CalendarHeaderColumnDay.react');
const Week = require('./CalendarHeaderColumnWeek.react');
const Month = require('./CalendarHeaderColumnMonth.react');
const Year = require('./CalendarHeaderColumnYear.react');

module.exports = ({ type, column, ...rest }) => {
  switch (type) {
    case 'weeks':
      return <Week column={column} {...rest} />;
    case 'months':
      return <Month column={column} {...rest} />;
    case 'years':
      return <Year column={column} {...rest} />;
    default:
      return <Day column={column} {...rest} />;
  }
};
