const { connect } = require('react-redux');
const DeletePersonModal = require('../components/DeletePersonModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  client: state.focusedItem,
  isWaiting: state.isDeletingClient,
});

const mapDispatchToProps = (dispatch) => ({
  deleteClient: (client) => dispatch(actions.deletePerson(client)),
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(DeletePersonModal);
