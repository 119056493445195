const React = require('react');
const Banner = require('./AppBanner/AppBanner.react');
const Nav = require('./AppNav/AppNav.react');
const LaptopAndAbove = require('../../media-queries/LaptopAndAbove.react');

module.exports = class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { bannerHeight: 0, navHeight: 0 };
  }

  componentDidMount() {
    dispatcher.trigger('header:rendered');
  }

  componentDidUpdate(prevProps, prevState) {
    // When banner's height changes
    const prevHeight = prevState.bannerHeight + prevState.navHeight;
    const height = this.state.bannerHeight + this.state.navHeight;
    if (prevHeight > height) {
      // Launch event to let components re-calculate their space
      dispatcher.trigger('app:banner:closed');
    }
  }

  getSpacerStyle() {
    const height = this.state.bannerHeight + this.state.navHeight;

    return { height: `${height}px` };
  }

  updateBannerHeight(height) {
    this.setState({ bannerHeight: height });
  }

  updateNavHeight(height) {
    this.setState({ navHeight: height });

    dispatcher.trigger('app:nav:height:updated');
  }

  render() {
    return (
      <div className="wethod-app-header">
        <div className="wethod-app-header__fixed">
          <LaptopAndAbove>
            <Banner onHeightChange={this.updateBannerHeight.bind(this)} />
          </LaptopAndAbove>
          <Nav onHeightChange={this.updateNavHeight.bind(this)} />
        </div>
        <div className="wethod-app-header__spacer" style={this.getSpacerStyle()} />
      </div>
    );
  }
};
