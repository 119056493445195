const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');

module.exports = ({ value, prevMonthValue }) => {
  const conclusion = value > prevMonthValue ? "That's great!" : 'Must try harder!';

  if (value !== prevMonthValue) {
    return (
      <Paragraph>
        Overall during last month, the pipeline changed from
        {' '}
        <NV value={prevMonthValue} />
        {' '}
        to
        {' '}
        <NV
          value={value} />
        .
        {' '}
        {conclusion}
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      Overall during last month, the pipeline remained constant.
    </Paragraph>
  );
};
