const _ = require('underscore');

class BusinessUnitService {
  constructor() {
    this.enabled = Wethod.userInfo.attributes.business_units_enabled;
    this.units = Wethod.userInfo.attributes.business_units;
    // User business unit in the current company
    this.userUnit = Wethod.userInfo.attributes.current_business_unit;
    // True if "Default BU for filters" company setting is checked
    this.filterOnUserUnitByDefault = Wethod.userInfo.attributes.filter_on_user_bu;
  }

  /**
   * Return if business unit are enabled in current company.
   * @returns {boolean}
   */
  isEnabled() {
    return this.enabled;
  }

  /**
   * Proxy current company business units.
   * Used to normalize collection obtained by API.
   * @returns {{id:string,name:string}}
   */
  getForCompany() {
    return this.units.map((unit) => ({
      id: String(unit.id),
      name: _.unescape(unit.name),
    }));
  }

  /**
   * Return current company business units id list.
   * @returns {string[]}
   */
  getIdListForCompany() {
    return this.getForCompany().map((unit) => unit.id);
  }

  /**
   * Return user business unit for current company.
   * @returns {string}
   */
  getForUser() {
    // Make null value a string because BusinessUnitSelectFilter does not accept null as value
    return String(this.userUnit);
  }

  /**
   * Return the last units (eventually none) actively selected by the user in session.
   * Return null if there are no units preferences saved.
   * @returns {string[]|null}
   */
  static getSaved() {
    const savedUnits = window.sessionStorage.getItem('business-units');

    return JSON.parse(savedUnits);
  }

  /**
   * Return the default BU list to use based on "Default BU for filters" company setting.
   * Default list can be the user unit or all available units.
   * @returns {string[]}
   */
  getDefaultForFilter() {
    return this.filterOnUserUnitByDefault ? [this.getForUser()] : this.getIdListForCompany();
  }

  /**
   * Return the list of currently selected business units id, using this rule:
   * - Use the last units actively selected by the user (if they exist)
   * - Otherwise rely on the "Default BU for filters" company setting (user's unit or all units)
   * @returns {string[]}
   */
  getLastSelected() {
    let initialList = [];
    let selectedList = [];
    const unitIdList = this.getIdListForCompany();
    if (this.isEnabled()) {
      initialList = this.getDefaultForFilter();
      selectedList = BusinessUnitService.getSaved();
      return selectedList === null ? initialList : selectedList;
    }
    return unitIdList;
  }

  /**
   * Format given business unit value into a canonical business unit id accepted by API.
   * @param {string} value
   * @returns {null|number}
   */
  static formatForSaving(value) {
    // Transform null string into a null value because API expect it like that
    if (value === 'null') {
      return null;
    }
    return Number.parseInt(value, 10);
  }

  /**
   * Return default unit to assign to an item which needs a business unit to be created.
   * Example: if you're going to create a new project, you can use this method to the default unit
   * to assing it.
   * @returns {null|integer}
   */
  getDefaultForItem() {
    const selectedValues = this.getLastSelected();
    const unit = selectedValues.length === 1 ? selectedValues[0] : 'null';

    return BusinessUnitService.formatForSaving(unit);
  }

  /**
   * Retrieve business unit with the given id from the company's business units.
   * @param {*} id
   * @returns {{id:*,name:string}}
   */
  findById(id) {
    return this.getForCompany().find((unit) => unit.id === String(id));
  }

  /**
   * Return if the current list of selected business units matches the whole available units list.
   * @returns {boolean}
   */
  areAllSelected() {
    return this.getForCompany().length === this.getLastSelected().length;
  }

  /**
   * Return if two units are the same one based on their ids.
   * This is useful because all id are stringified by this service (to be consistent with
   * BusinessUnitSelectFilter) but items coming from database are returned with numeric or null
   * unit.
   * @param {string|integer|null} firstId
   * @param {string|integer|null} secondId
   * @returns {boolean}
   */
  static isEqual(firstId, secondId) {
    return String(firstId) === String(secondId);
  }
}

module.exports = BusinessUnitService;
