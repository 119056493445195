module.exports.SHOW_MODAL_INTERESTS = 'SHOW_MODAL_INTERESTS';
module.exports.SHOW_MODAL_PORTFOLIO = 'SHOW_MODAL_PORTFOLIO';

module.exports.SHOW_MODAL_CONTACT_INFO = 'SHOW_MODAL_CONTACT_INFO';
module.exports.HIDE_MODAL = 'HIDE_MODAL';
module.exports.SHOW_MODAL_BIO = 'SHOW_MODAL_BIO';
module.exports.SHOW_MODAL_SKILLS = 'SHOW_MODAL_SKILLS';
module.exports.SHOW_MODAL_LANGUAGES = 'SHOW_MODAL_LANGUAGES';
module.exports.SHOW_MODAL_SPONSORS = 'SHOW_MODAL_SPONSORS';
module.exports.SHOW_MODAL_OBJECTIVE = 'SHOW_MODAL_OBJECTIVE';
module.exports.SHOW_MODAL_RESOLUTION = 'SHOW_MODAL_RESOLUTION';
module.exports.SHOW_MODAL_PROFILE_PIC = 'SHOW_MODAL_PROFILE_PIC';
module.exports.SHOW_MODAL_WORKING_CONNECTION = 'SHOW_MODAL_WORKING_CONNECTION';

module.exports.CHOOSE_QUARTERS_FILTER = 'CHOOSE_QUARTERS_FILTER';

module.exports.CHOOSE_TRENDS_FILTER = 'CHOOSE_TRENDS_FILTER';

module.exports.EDIT_INFO = 'EDIT_INFO';
module.exports.EDIT_INFO_SUCCESS = 'EDIT_INFO_SUCCESS';
module.exports.EDIT_INFO_REQUEST = 'EDIT_INFO_REQUEST';

module.exports.ADD_SKILL_REQUEST = 'ADD_SKILL_REQUEST';
module.exports.ADD_SKILL_SUCCESS = 'ADD_SKILL_SUCCESS';

module.exports.DELETE_SKILL_REQUEST = 'DELETE_SKILL_REQUEST';
module.exports.DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';

module.exports.ADD_INTEREST_REQUEST = 'ADD_INTEREST_REQUEST';
module.exports.ADD_INTEREST_SUCCESS = 'ADD_INTEREST_SUCCESS';

module.exports.DELETE_INTEREST_REQUEST = 'DELETE_INTEREST_REQUEST';
module.exports.DELETE_INTEREST_SUCCESS = 'DELETE_INTEREST_SUCCESS';

module.exports.ADD_LANGUAGE_REQUEST = 'ADD_LANGUAGE_REQUEST';
module.exports.ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';

module.exports.UPDATE_LANGUAGE_REQUEST = 'UPDATE_LANGUAGE_REQUEST';
module.exports.UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';

module.exports.DELETE_LANGUAGE_REQUEST = 'DELETE_LANGUAGE_REQUEST';
module.exports.DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';

module.exports.ADD_WORK_REQUEST = 'ADD_WORK_REQUEST';
module.exports.ADD_WORK_SUCCESS = 'ADD_WORK_SUCCESS';

module.exports.UPDATE_WORK_REQUEST = 'UPDATE_WORK_REQUEST';
module.exports.UPDATE_WORK_SUCCESS = 'UPDATE_WORK_SUCCESS';

module.exports.DELETE_WORK_REQUEST = 'DELETE_WORK_REQUEST';
module.exports.DELETE_WORK_SUCCESS = 'DELETE_WORK_SUCCESS';

module.exports.ADD_SPONSOR_REQUEST = 'ADD_SPONSOR_REQUEST';
module.exports.ADD_SPONSOR_SUCCESS = 'ADD_SPONSOR_SUCCESS';

module.exports.DELETE_SPONSOR_REQUEST = 'DELETE_SPONSOR_REQUEST';
module.exports.DELETE_SPONSOR_SUCCESS = 'DELETE_SPONSOR_SUCCESS';

module.exports.END_SPONSOR_REQUEST = 'END_SPONSOR_REQUEST';
module.exports.END_SPONSOR_SUCCESS = 'END_SPONSOR_SUCCESS';

module.exports.EDIT_KEY_RESULT_REQUEST = 'EDIT_KEY_RESULT_REQUEST';
module.exports.EDIT_KEY_RESULT_SUCCESS = 'EDIT_KEY_RESULT_SUCCESS';

module.exports.DELETE_KEY_RESULT_REQUEST = 'DELETE_KEY_RESULT_REQUEST';
module.exports.DELETE_KEY_RESULT_SUCCESS = 'DELETE_KEY_RESULT_SUCCESS';

module.exports.ADD_KEY_RESULT_REQUEST = 'ADD_KEY_RESULT_REQUEST';
module.exports.ADD_KEY_RESULT_SUCCESS = 'ADD_KEY_RESULT_SUCCESS';

module.exports.ADD_OBJECTIVE_REQUEST = 'ADD_OBJECTIVE_REQUEST';
module.exports.ADD_OBJECTIVE_SUCCESS = 'ADD_OBJECTIVE_SUCCESS';

module.exports.EDIT_OBJECTIVE_REQUEST = 'EDIT_OBJECTIVE_REQUEST';
module.exports.EDIT_OBJECTIVE_SUCCESS = 'EDIT_OBJECTIVE_SUCCESS';

module.exports.DELETE_OBJECTIVE_REQUEST = 'DELETE_OBJECTIVE_REQUEST';
module.exports.DELETE_OBJECTIVE_SUCCESS = 'DELETE_OBJECTIVE_SUCCESS';

module.exports.EDIT_RESOLUTION_REQUEST = 'EDIT_RESOLUTION_REQUEST';
module.exports.EDIT_RESOLUTION_SUCCESS = 'EDIT_RESOLUTION_SUCCESS';

module.exports.GET_OBJECTIVES_REQUEST = 'GET_OBJECTIVES_REQUEST';
module.exports.GET_OBJECTIVES_SUCCESS = 'GET_OBJECTIVES_SUCCESS';

module.exports.UPDATE_PROFILE_PIC_REQUEST = 'UPDATE_PROFILE_PIC_REQUEST';
module.exports.UPDATE_PROFILE_PIC_SUCCESS = 'UPDATE_PROFILE_PIC_SUCCESS';

module.exports.DELETE_PROFILE_PIC_REQUEST = 'DELETE_PROFILE_PIC_REQUEST';
module.exports.DELETE_PROFILE_PIC_SUCCESS = 'DELETE_PROFILE_PIC_SUCCESS';

module.exports.UPDATE_QUARTER_STATUS_REQUEST = 'UPDATE_QUARTER_STATUS_REQUEST';
module.exports.UPDATE_QUARTER_STATUS_SUCCESS = 'UPDATE_QUARTER_STATUS_SUCCESS';

module.exports.GET_FRIDAYNESS_TREND_REQUEST = 'GET_FRIDAYNESS_TREND_REQUEST';
module.exports.GET_FRIDAYNESS_TREND_SUCCESS = 'GET_FRIDAYNESS_TREND_SUCCESS';
module.exports.GET_DO_THE_BUDGET_TREND_REQUEST = 'GET_DO_THE_BUDGET_TREND_REQUEST';
module.exports.GET_DO_THE_BUDGET_TREND_SUCCESS = 'GET_DO_THE_BUDGET_TREND_SUCCESS';
module.exports.GET_DO_THE_PLAN_TREND_REQUEST = 'GET_DO_THE_PLAN_TREND_REQUEST';
module.exports.GET_DO_THE_PLAN_TREND_SUCCESS = 'GET_DO_THE_PLAN_TREND_SUCCESS';
module.exports.GET_KEEP_THE_LIGHTS_OFF_TREND_REQUEST = 'GET_KEEP_THE_LIGHTS_OFF_TREND_REQUEST';
module.exports.GET_KEEP_THE_LIGHTS_OFF_TREND_SUCCESS = 'GET_KEEP_THE_LIGHTS_OFF_TREND_SUCCESS';
module.exports.GET_CHARGEABILITY_TREND_REQUEST = 'GET_CHARGEABILITY_TREND_REQUEST';
module.exports.GET_CHARGEABILITY_TREND_SUCCESS = 'GET_CHARGEABILITY_TREND_SUCCESS';
module.exports.GET_WORKING_CONNECTION_TREND_REQUEST = 'GET_WORKING_CONNECTION_TREND_REQUEST';
module.exports.GET_WORKING_CONNECTION_TREND_SUCCESS = 'GET_WORKING_CONNECTION_TREND_SUCCESS';

module.exports.GET_KUDOS_REQUEST = 'GET_KUDOS_REQUEST';
module.exports.GET_KUDOS_SUCCESS = 'GET_KUDOS_SUCCESS';
module.exports.GET_KUDOS_FAILURE = 'GET_KUDOS_FAILURE';

module.exports.GET_GENDER_REQUEST = 'GET_GENDER_REQUEST';
module.exports.GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS';
