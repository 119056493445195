/* eslint-disable react/prop-types,react/jsx-no-bind */
const React = require('react');

/**
 * A colored button with text
 * @param value
 * @param color
 * @param className
 * @param disabled  //when disabled, click event is not triggered
 * @param onClick
 * @param setRef  //to get the button ref
 * @returns {*}
 */
const ColoredButton = ({
  value, color, className, disabled, onClick, setRef,
}) => {
  const getStyle = () => {
    let style = 'wethod-button wethod-button--colored';
    if (className) style += ` ${className}`;
    if (disabled) style += ' disabled';
    return style;
  };

  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <button type="button"
      ref={setRef}
      className={getStyle()}
      style={{ backgroundColor: color }}
      onClick={handleClick}
      disabled={disabled}>
      {value}
      &nbsp;
    </button>
  );
};

module.exports = ColoredButton;
