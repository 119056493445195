/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,no-param-reassign,no-nested-ternary */
const React = require('react');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const ReadOnlyBody = require('./SidebarReadOnlyBody.react');
const EditBody = require('./SidebarEditBody.react');
const { formatDate } = require('../../../../../../../services/FormatService');
const Actions = require('./ItemSidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.ItemSidebar}
 */
module.exports = class ItemSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false,
      item: this.props.item || {},
      editMode: false,
    };
  }

  /**
   * Checks if it's safe to save an item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEdit && !this.state.editMode;
  }

  isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Check if every member is contained in prevMembers. Every member must have a unique id
   *
   * @param {array} prevMembers
   * @param {array} members
   */
  hasSameMembers(prevMembers, members) {
    return !members.some((memb) => !prevMembers.some((prev) => prev.id === memb.id));
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @param {string} type - type of the attribute
   * @returns {boolean}
   */
  hasChanged(name, value, type) {
    let oldAttribute = this.props.item.attributes.find((attribute) => attribute.attribute === name);
    oldAttribute = oldAttribute ? oldAttribute.value : null;
    let oldVal = null;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (type) {
      case 'item-name':
        oldVal = this.props.item.name;
        break;
      case 'status':
        oldVal = oldAttribute ? oldAttribute.id : null;
        value = value ? value.id : null;
        break;
      case 'date':
        oldVal = oldAttribute && oldAttribute.start_date
          ? `${formatDate(oldAttribute.start_date)} - ${formatDate(oldAttribute.end_date)}`
          : null;
        value = value ? `${formatDate(value.start_date)} - ${formatDate(value.end_date)}` : null;
        break;
      case 'milestone':
        oldVal = oldAttribute && oldAttribute.type && oldAttribute.date ? `${oldAttribute.type} - ${formatDate(oldAttribute.date)}` : null;
        value = value && value.type && value.date ? `${value.type} - ${formatDate(value.date)}` : null;
        break;
      case 'member':
        // When old members and new members are actually equals, the value to be compared is the length;
        // otherwise the new value is set to -1 to be different for sure from the old one
        oldVal = oldAttribute ? oldAttribute.length : 0;
        value = value
          ? this.hasSameMembers(oldAttribute || [], value) ? value.length : -1
          : 0;
        break;
      default:
        oldVal = oldAttribute;
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((this.isDirty(oldVal) || this.isDirty(value)) && (oldVal !== value)) {
      // When the value has changed, we insert the name in the changes array, if not already present
      const newChanges = this.state.unsavedChanges;
      if (!newChanges.includes(name)) {
        newChanges.push(name);
      }
      this.setState({ unsavedChanges: newChanges });
      totalChanges += 1;
    } else {
      // When the value is equal to the props, we need to remove it from the changes array if present
      let newChanges = this.state.unsavedChanges;
      newChanges = newChanges.filter((attr) => attr !== name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges = newChanges.length;
    }

    return totalChanges !== 0;
  }

  // Save in the local state the modified item and check if there are unsaved changes
  handleInputChanges(name, value, type) {
    let newItem = null;

    switch (type) {
      case 'item-name':
        newItem = {
          ...this.state.item,
          name: value,
        };
        break;
      default:
        newItem = {
          ...this.state.item,
          attributes: this.state.item.attributes.map((attribute) => {
            if (attribute.attribute === name) {
              return {
                ...attribute,
                value,
              };
            }
            return attribute;
          }),
        };
        break;
    }

    const hasUnsavedChanges = this.hasChanged(name, value, type);
    this.setState({
      hasUnsavedChanges,
      item: newItem,
    });
  }

  handleSave() {
    this.setState({
      editMode: false,
      hasUnsavedChanges: false,
      unsavedChanges: [],
    });
    let nameChanges = 0;

    if (this.state.unsavedChanges.includes('item-name')) {
      nameChanges = 1;
      this.props.onNameSave(this.state.item.id, this.state.item.name);
    }

    if (this.props.onSave && this.state.unsavedChanges.length > nameChanges) {
      this.props.onSave(this.state.item.id, this.state.item.attributes.map((attribute) => ({
        attribute: attribute.attribute,
        value: attribute.value,
      })));
    }
  }

  handleDelete() {
    this.props.onDelete(this.state.item.id, this.state.item.name);
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <EditBody item={this.state.item}
          structure={this.props.structure}
          onChange={this.handleInputChanges.bind(this)} />
      );
    }
    return (
      <ReadOnlyBody item={this.state.item} structure={this.props.structure} />);
  }

  getActions() {
    if (this.state.item && this.state.item.id && this.canEdit()) {
      return (
        <Actions onDelete={this.handleDelete.bind(this)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Sidebar title="Item Details"
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onSave={this.handleSave.bind(this)}
        onEdit={this.handleEditMode.bind(this)}
        onClose={this.props.onClose}
        onCancel={this.props.onClose}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
