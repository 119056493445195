const React = require('react');
const AreaSwitchDelta = require('./AreaSwitchDelta.react');

module.exports = class Area extends React.Component {
  constructor(props) {
    super(props);
    this.contingencyAreaReference = null;
    this.onScroll = this.onScroll.bind(this);
    this.setContingencyAreaRef = this.setContingencyAreaRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollingComponentAmount !== this.props.scrollingComponentAmount) {
      if (this.props.horizontalScroll) {
        this.contingencyAreaReference.scrollTo({ left: this.props.scrollingComponentAmount });
      }
    }
  }

  onScroll() {
    this.props
      .setScrollingComponentScrollAmount(this.contingencyAreaReference.scrollLeft);
  }

  getContentClassName() {
    let className = 'wethod-budget-area__content';
    if (!this.props.enabled) {
      className += ' wethod-budget-area__content--disabled';
    }
    if (this.props.horizontalScrollClass) {
      className += ` ${this.props.horizontalScrollClass}`;
    }
    if (this.props.rightColumn) {
      className += ' wethod-budget-area__content--right-column';
    } else {
      className += ` wethod-budget-area__content--${this.props.column}-column`;
    }

    if (this.props.fitContentWidth) {
      className += ' wethod-budget-area__content--center-column-fit-content';
    }

    if (this.props.column !== 'center' && this.props.showShadows) {
      className += ` wethod-budget-shadow-${this.props.column ? this.props.column : 'right'}`;
    }

    return className;
  }

  setContingencyAreaRef(el) {
    this.contingencyAreaReference = el;
  }

  render() {
    if (this.props.horizontalScroll) {
      return (
        <div className="wethod-budget-area">
          <AreaSwitchDelta
            previousValue={this.props.wasEnabled}
            actualValue={this.props.enabled} />
          <div
            data-container-id="contingency-area"
            className={this.getContentClassName()}
            ref={this.setContingencyAreaRef}
            onScroll={this.onScroll}>
            {this.props.children}
          </div>
        </div>
      );
    }
    return (
      <div className="wethod-budget-area">
        <AreaSwitchDelta
          previousValue={this.props.wasEnabled}
          actualValue={this.props.enabled} />
        <div className={this.getContentClassName()}>
          {this.props.children}
        </div>
      </div>
    );
  }
};
