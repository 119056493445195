/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TextInput = require('../../../../../../../../common/react/TextInput.react');
const Menu = require('../../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const Loader = require('../../../../../../../../common/react/Loader/Loader.react');

module.exports = class HeadCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      focusOnTitle: false,
    };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  onBlur(value) {
    this.setState({ focusOnTitle: false });
    if (value.trim() !== this.props.name) {
      this.props.editName(this.props.id, value);
    }
  }

  onDefaultClick() {
    this.props.updateActivityAttribute(this.props.id, this.props.type);
  }

  onDeleteClick() {
    this.props.onDeleteClick(this.props.id, this.props.name);
  }

  getWrapperClassName() {
    let name = 'project-canvas-spreadsheet__col-more-button-wrapper';
    if (this.state.focusOnTitle) {
      name += ' project-canvas-spreadsheet__col-more-button-wrapper--focus';
    }
    return name;
  }

  onFocus() {
    this.setState({ focusOnTitle: true });
  }

  getMenuBody() {
    if ((this.props.type === 'status' || this.props.type === 'member') && !this.props.isActivityDefault) {
      const type = this.props.type === 'status' ? 'status' : 'assignee';
      return []
        .concat(
          <MenuItem key="default"
            onClick={this.onDefaultClick.bind(this)}>{`Use as activity's ${type}`}
          </MenuItem>,
        )
        .concat(<MenuItem key="delete" onClick={this.onDeleteClick.bind(this)}>Delete</MenuItem>);
    }
    return (
      <MenuItem onClick={this.onDeleteClick.bind(this)}>Delete</MenuItem>
    );
  }

  getLabel() {
    if (this.props.isActivityDefault) {
      const text = this.props.type === 'status' ? 'Activity\'s status' : 'Activity\'s assignee';
      return (
        <span className="project-canvas-spreadsheet__cell--header-name-label">{text}</span>
      );
    }
  }

  getContent() {
    if (this.props.isDeleting) {
      return (
        <div
          style={{
            position: 'relative',
            top: '10px',
            left: '4px',
          }}>
          <Loader />
        </div>
      );
    }
    return (
      <span className={this.getWrapperClassName()}>
        <span className="project-canvas-spreadsheet__cell--header-drag-handle-container">
          <span
            className="project-canvas-spreadsheet__cell--header-drag-handle wethod-icon-draggable wethod-icon-draggable--black" />
        </span>
        <div className="project-canvas-spreadsheet__cell--header-name">
          <TextInput value={this.props.name}
            onBlur={this.onBlur.bind(this)}
            onFocus={this.onFocus.bind(this)} />
          {this.getLabel()}
        </div>
        <button
          type="button"
          ref={(el) => this.moreButtonEl = el}
          onClick={this.onMenuButtonClick.bind(this)}
          className="wethod-icon-button project-canvas-spreadsheet__col-more-button">
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          {this.getMenuBody()}
        </Menu>
      </span>
    );
  }

  render() {
    return (
      <TableCell
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell--header"
        data-board-id={this.props.boardId}
        data-id={this.props.id}
        data-sort={this.props.dataSort}>
        {this.getContent()}
      </TableCell>
    );
  }
};
