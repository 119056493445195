/* eslint-disable camelcase,no-underscore-dangle,no-shadow */

'use strict';

var Order = require('../../../models/Order');

Wethod.module('ReportApp.Detail', function (Detail, Wethod, Backbone, Marionette, $, _) {
  var chart = function (series, maxYAxis, projectStatusPercentage) {
    return {
      chart: {
        renderTo: 'actualProgressChart',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          margin: 20,
          text: 'PROJECT STATUS % (days)',
          align: 'high',
          style: {
            color: '#333',
          },
        },
        lineWidth: 1,
        max: 100,
        tickLength: 0,
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          margin: 28,
          text: projectStatusPercentage ? 'BUDGET CONSUMPTION % (timesheet costs)' : 'BUDGET CONSUMPTION % (days)',
          align: 'high',
          style: {
            color: '#333',
          },
        },
        lineWidth: 1,
        min: 0,
        max: maxYAxis,
      },
      series: series,
      tooltip: {
        snap: 5,
        hideDelay: 100,
        borderWidth: 0,
        useHTML: true,
        formatter: function () {
          var trackedHoursValue = this.series.data[1].trackedHoursValue;
          var html = '<div>' + this.series.name + '</div>';
          if (trackedHoursValue) {
            html += '<div>Worked hours\' value: ' + numeral(trackedHoursValue)
              .format('0,0') + '</div>';
          }
          return html;
        },
      },
      plotOptions: {
        series: {
          stickyTracking: false,
        },
        line: {
          dashStyle: 'Dot',
        },
      },
    };
  };

  var allowTextChar = function (charCode) {
    return (charCode >= 46 && charCode <= 90) || (charCode >= 96 && charCode <= 111)
      || _.contains([8, 9, 187, 189], charCode);
  };

  this.ProjectReportStructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#projectReportDetailStructureTemplate',
    className: 'fluid',
    regions: {
      headerSection: '[data-region="reportSectionHeader"]',
      header: '[data-region="prjReportHead"]',
      actualProgressContainer: '[data-region="actualProgressContainer"]',
      economicsContainer: '[data-region="economicsContainer"]',
      hoursWeeksContainer: '[data-region="hoursWeeksContainer"]',
      hoursUsersContainer: '[data-region="hoursUsersContainer"]',
      projectEconomicsContainer: '[data-region="projectEconomicsContainer"]',
      invoicesContainer: '[data-region="reportInvoicesContainer"]',
      ordersContainer: '[data-region="reportOrdersContainer"]',
      dialog: '[data-region="appDialog"]',
    },
    initialize: function (options) {
      this.options = options;
    },
  });

  this.ActualProgressLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="actualProgressContainer"]',
    template: '#actualProgressTemplate',
    regions: {
      hoursDetailsContainer: '[data-region="hoursDetailsContainer"]',
    },
    ui: {
      actualProgressChart: '[data-action="actualProgressChart"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.ui.actualProgressChart.highcharts(chart(this.model.get('chartSeriesData'), this.options.maxYAxis, this.options.projectStatusPercentage));
    },
  });

  this.HoursDetailsItemView = Marionette.ItemView.extend({
    template: '#hoursDetailsTemplate',
    className: 'hoursDetails',
  });

  this.HoursUsersTableContentView = Marionette.LayoutView.extend({
    template: '#hoursUsersTableContentTemplate',
    regions: {
      hoursUsersRows: '[data-region="hoursUsersRows"]',
      hoursUsersFooter: '[data-region="hoursUsersFooter"]',
      hoursUsersValue: '[data-region="hoursUsersValue"]',
    },
    ui: {
      choosePeriodButton: '[data-action="choosePeriod"]',
    },
    events: {
      'click @ui.choosePeriodButton': 'showPeriodModal',
    },
    modelEvents: {
      change: 'onModelChange',
    },
    initialize: function (options) {
      this.options = options;
      dispatcher.on('report:detail:hour-user:set-period', this.updatePeriod, this);
    },
    onRender: function () {
      this.showRows();
      this.showFooter();
      this.showValue();
    },
    updatePeriod: function (dates, employees) {
      this.options.timetrackingByEmployee = this.getTimetrackingByEmployee(employees);
      this.model.set('from', dates.get('from'));
      this.model.set('to', dates.get('to'));
    },
    getTimetrackingByEmployee: function (employees) {
      var employeesMap = {};
      _.map(employees, function (employee) {
        employeesMap[employee.employee_id] = employee;
      });
      return _.map(this.options.timetrackingByEmployee,
        function (employee) {
          employee.last_week_planned_hours = employeesMap[employee.employee_id].planned_hours;
          employee.last_week_worked_hours = employeesMap[employee.employee_id].worked_hours;
          return employee;
        });
    },
    onModelChange: function () {
      this.render();
    },
    showPeriodModal: function (e) {
      e.preventDefault();
      Detail.showTimetrackingPeriodModal(this.model.get('from'), this.model.get('to'), this.options.projectId);
    },
    showRows: function () {
      var hoursUsersCollection = new Wethod.ReportApp.Report
        .HoursUsersDetailsCollection(this.options.timetrackingByEmployee);
      var HoursUsersTemplate = new Detail.HoursUsersDetailsCollectionView({
        collection: hoursUsersCollection,
      });
      this.hoursUsersRows.show(HoursUsersTemplate);
    },
    showFooter: function () {
      var hoursUsersCollection = new Wethod.ReportApp.Report
        .HoursUsersDetailsCollection(this.options.timetrackingByEmployee);
      var hoursUsersTotals = new Wethod.ReportApp.Report
        .HoursUsersFooterModel(hoursUsersCollection.getTotals());
      var hoursUsersFooterView = new Detail.HoursUserFooterRowView({
        model: hoursUsersTotals,
      });
      this.hoursUsersFooter.show(hoursUsersFooterView);
    },
    showValue: function () {
      var hoursUsersValue = new Backbone
        .Model({ value: this.options.trackedHoursValue });
      var hoursUsersValueView = new Detail.HoursUserValueRowView({
        model: hoursUsersValue,
      });
      this.hoursUsersValue.show(hoursUsersValueView);
    },
  });

  this.HoursUsersTableLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="hoursUsersContainer"]',
    template: '#hoursUsersTableTemplate',
    className: 'grid',
    regions: {
      content: '[data-region="content"]',
    },
    ui: {
      export: '[data-action="export"]',
    },
    events: {
      'click @ui.export': function (e) {
        e.preventDefault();
        e.stopPropagation();
        Detail.showModalExport(this.options.projectId);
      },
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      var today = moment();
      var previousWeekMonday = today.clone().subtract(1, 'week').day(1);
      var previousWeekSunday = previousWeekMonday.clone().day(7);
      var params = {
        project_id: this.options.projectId,
        from: previousWeekMonday.format('YYYY-MM-DD'),
        to: previousWeekSunday.format('YYYY-MM-DD'),
      };
      $.when(Wethod.request('report:hours-users:get', params)).done(function (employees) {
        var options = this.options;
        options.model = new Backbone.Model({
          from: params.from,
          to: params.to,
        });
        options.timetrackingByEmployee = this.getTimetrackingByEmployee(employees);
        var view = new Detail.HoursUsersTableContentView(options);
        this.content.show(view);
      }.bind(this));
    },
    getTimetrackingByEmployee: function (employees) {
      var employeesMap = {};
      _.map(employees, function (employee) {
        employeesMap[employee.employee_id] = employee;
      });
      return _.map(this.options.timetrackingByEmployee,
        function (employee) {
          employee.last_week_planned_hours = employeesMap[employee.employee_id].planned_hours;
          employee.last_week_worked_hours = employeesMap[employee.employee_id].worked_hours;
          return employee;
        });
    },
  });

  this.HoursUserDetailsRowView = Marionette.ItemView.extend({
    template: '#hoursUserDetailsRowTemplate',
    tagName: 'li',
    className: 'reportRow',
  });

  this.HoursUserFooterRowView = Marionette.ItemView.extend({
    template: '#hoursUserFooterRowTemplate',
    tagName: 'ul',
    className: 'reportRow',
  });

  this.HoursUserValueRowView = Marionette.ItemView.extend({
    template: '#hoursUserValueTemplate',
    tagName: 'ul',
    className: 'reportRow',
  });

  this.HoursUsersDetailsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: '',
    childView: Detail.HoursUserDetailsRowView,
  });

  this.ProjectEconomicsItemView = Marionette.ItemView.extend({
    _template: '#projectEconomicsTemplate',
    className: 'projectContainer',
    ui: {
      shareButtonEl: '[data-action="sendLink"]',
      moreActionsEl: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',
      archiveEl: '[data-action="archiveProject"]',
      archiveLabelEl: '[data-region="archiveProjectLabel"]',
    },
    events: {
      'click @ui.shareButtonEl': 'showShareLinkModal',
      'click @ui.moreActionsEl': 'moreActions',
      'click @ui.archiveEl': 'archiveProject',
    },
    initialize: function () {
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var model = serialized_model;
      var projectId = model.project.id;

      model.linkTimeline = '#timeline/' + projectId;
      model.linkPipeline = '#pipeline/projects?id=' + projectId;
      model.linkCanvas = '#project/' + projectId + '/canvas';
      model.linkProductionPlan = '#planning/production-plan?project_id:' + projectId;
      model.linkInvoicePlan = '#project/' + projectId + '/invoice-plan';
      model.linkProjectStatus = '#project-status/' + projectId;

      if (!_.isNull(model.program)) {
        model.linkProgram = '#program/' + model.program.id;
      } else {
        model.linkProgram = '#program/new/' + projectId;
      }

      model.linkBudget = '#pipeline/budget/' + projectId;
      if (!_.isNull(model.budget.id)) {
        model.linkPlanning = '#planning/people?project_id=' + projectId;
      } else {
        model.linkPlanning = '#planning';
      }
      return _.template(template)(model);
    },
    onRender: function () {
      if (this.model.get('project').archived === true) {
        this.ui.archiveLabelEl.text('UNARCHIVE');
      } else {
        this.ui.archiveLabelEl.text('ARCHIVE');
      }
    },
    showShareLinkModal: function (e) {
      e.preventDefault();
      Detail.showModalShareLink();
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        var visible = this.$el.find('.isVisible');
        visible.removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');

        // Hide select when click on 'html'
        e.stopPropagation();
        Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }
    },
    archiveProject: function (e) {
      e.preventDefault();
      var data = Wethod.getArchiveModal(this.model.get('project').archived);
      Detail.showModal(data, this.model, this);
    },
  });

  this.HoursEconomicsDetailsItemViewOld = Marionette.ItemView.extend({
    template: '#economicsDetailsTemplateOld',
    _format: function (num, cost) {
      // todo this function is duplicated on project-charter-view.js

      cost = cost || false;

      if (cost) {
        if (num > 0) {
          num = '(' + numeral(num).format('0,0') + ')';
        } else if (num < 0) { // if 0 no parenthesis
          num = numeral(-1 * num).format('0,0');
        }
      } else if (num >= 0) {
        num = numeral(num).format('0,0');
      } else {
        num = '(' + numeral(-1 * num).format('0,0') + ')';
      }

      return num;
    },
    initialize: function () {
      // todo this function is duplicated on project-charter-view.js

      var baseline = this.model.get('baseline');
      var lastVersion = this.model.get('last_version');
      var forecast = this.model.get('forecast');

      // BASELINE
      baseline.display_revenues = this._format(baseline.revenues);
      baseline.display_internal_costs = this._format(baseline.internal_costs, true);
      baseline.display_external_costs = this._format(baseline.external_costs, true);
      baseline.display_expenses = this._format(baseline.expenses, true);
      baseline.display_travels = this._format(baseline.travels, true);
      baseline.display_margin = this._format(baseline.margin);
      baseline.display_wasted = this._format(baseline.wasted, true);
      baseline.display_margin_net = this._format(baseline.margin_net);

      // DELTA VS BASELINE
      baseline.display_delta_revenues = this._format(forecast.revenues - baseline.revenues);
      baseline.display_delta_internal_costs = this
        ._format(forecast.internal_costs - baseline.internal_costs, true);
      baseline.display_delta_external_costs = this
        ._format(forecast.external_costs - baseline.external_costs, true);
      baseline.display_delta_travels = this._format(forecast.travels - baseline.travels, true);
      baseline.display_delta_expenses = this._format(forecast.expenses - baseline.expenses, true);
      baseline.display_delta_margin = this._format(forecast.margin - baseline.margin);
      baseline.display_delta_wasted = this._format(forecast.wasted - baseline.wasted, true);
      baseline.display_delta_margin_net = this._format(forecast.margin_net - baseline.margin_net);

      // LAST VERSION
      lastVersion.display_revenues = this._format(lastVersion.revenues);
      lastVersion.display_internal_costs = this._format(lastVersion.internal_costs, true);
      lastVersion.display_external_costs = this._format(lastVersion.external_costs, true);
      lastVersion.display_expenses = this._format(lastVersion.expenses, true);
      lastVersion.display_travels = this._format(lastVersion.travels, true);
      lastVersion.display_margin = this._format(lastVersion.margin);
      lastVersion.display_wasted = this._format(lastVersion.wasted, true);
      lastVersion.display_margin_net = this._format(lastVersion.margin_net);

      // DELTA VS FORECAST
      lastVersion.display_delta_revenues = this._format(forecast.revenues - lastVersion.revenues);
      lastVersion.display_delta_internal_costs = this
        ._format(forecast.internal_costs - lastVersion.internal_costs, true);
      lastVersion.display_delta_external_costs = this
        ._format(forecast.external_costs - lastVersion.external_costs, true);
      lastVersion.display_delta_travels = this
        ._format(forecast.travels - lastVersion.travels, true);
      lastVersion.display_delta_expenses = this
        ._format(forecast.expenses - lastVersion.expenses, true);
      lastVersion.display_delta_margin = this._format(forecast.margin - lastVersion.margin);
      lastVersion.display_delta_wasted = this._format(forecast.wasted - lastVersion.wasted, true);
      lastVersion.display_delta_margin_net = this
        ._format(forecast.margin_net - lastVersion.margin_net);

      // FORECAST
      forecast.display_revenues = this._format(forecast.revenues);
      forecast.display_internal_costs = this._format(forecast.internal_costs, true);
      forecast.display_external_costs = this._format(forecast.external_costs, true);
      forecast.display_expenses = this._format(forecast.expenses, true);
      forecast.display_travels = this._format(forecast.travels, true);
      forecast.display_margin = this._format(forecast.margin);
      forecast.display_wasted = this._format(forecast.wasted, true);
      forecast.display_margin_net = this._format(forecast.margin_net);

      this.model.set('wastedHoursEnabled', this.model.get('wastedHoursEnabled') || forecast.wasted || lastVersion.wasted || baseline.wasted);
    },
  });

  // ECONOMICS
  this.HoursEconomicsDetailsItemView = Marionette.ItemView.extend({
    template: '#economicsDetailsTemplate',
    _format: function (num, cost) {
      // todo this function is duplicated on project-charter-view.js

      cost = cost || false;

      if (cost) {
        if (num > 0) {
          num = '(' + numeral(num).format('0,0') + ')';
        } else if (num < 0) { // if 0 no parenthesis
          num = numeral(-1 * num).format('0,0');
        }
      } else if (num >= 0) {
        num = numeral(num).format('0,0');
      } else {
        num = '(' + numeral(-1 * num).format('0,0') + ')';
      }

      return num;
    },
    initialize: function () {
      // todo this function is duplicated on project-charter-view.js

      var baseline = this.model.get('baseline');
      var lastVersion = this.model.get('last_version');
      var forecast = this.model.get('forecast');

      // BASELINE
      baseline.display_revenues = this._format(baseline.revenues);
      baseline.display_internal_costs = this._format(baseline.internal_costs, true);
      baseline.display_external_costs = this._format(baseline.external_costs, true);
      baseline.display_intercompany_costs = this._format(baseline.intercompany_costs, true);
      baseline.display_expenses = this._format(baseline.expenses, true);
      baseline.display_travels = this._format(baseline.travels, true);
      baseline.display_margin = this._format(baseline.margin);
      baseline.display_contribution_margin = this._format(baseline.contribution_margin);
      baseline.display_wasted = this._format(baseline.wasted, true);
      baseline.display_margin_net = this._format(baseline.margin_net);

      // DELTA VS BASELINE
      baseline.display_delta_revenues = this._format(forecast.revenues - baseline.revenues);
      baseline.display_delta_internal_costs = this
        ._format(forecast.internal_costs - baseline.internal_costs, true);
      baseline.display_delta_external_costs = this
        ._format(forecast.external_costs - baseline.external_costs, true);
      baseline.display_delta_intercompany_costs = this
        ._format(forecast.intercompany_costs - baseline.intercompany_costs, true);
      baseline.display_delta_travels = this._format(forecast.travels - baseline.travels, true);
      baseline.display_delta_expenses = this._format(forecast.expenses - baseline.expenses, true);
      baseline.display_delta_margin = this._format(forecast.margin - baseline.margin);
      baseline.display_delta_contribution_margin = this
        ._format(forecast.contribution_margin - baseline.contribution_margin);
      baseline.display_delta_wasted = this._format(forecast.wasted - baseline.wasted, true);
      baseline.display_delta_margin_net = this._format(forecast.margin_net - baseline.margin_net);

      // LAST VERSION
      lastVersion.display_revenues = this._format(lastVersion.revenues);
      lastVersion.display_internal_costs = this._format(lastVersion.internal_costs, true);
      lastVersion.display_external_costs = this._format(lastVersion.external_costs, true);
      lastVersion.display_intercompany_costs = this._format(lastVersion.intercompany_costs, true);
      lastVersion.display_expenses = this._format(lastVersion.expenses, true);
      lastVersion.display_travels = this._format(lastVersion.travels, true);
      lastVersion.display_margin = this._format(lastVersion.margin);
      lastVersion.display_contribution_margin = this._format(lastVersion.contribution_margin);
      lastVersion.display_wasted = this._format(lastVersion.wasted, true);
      lastVersion.display_margin_net = this._format(lastVersion.margin_net);

      // DELTA VS FORECAST
      lastVersion.display_delta_revenues = this._format(forecast.revenues - lastVersion.revenues);
      lastVersion.display_delta_internal_costs = this
        ._format(forecast.internal_costs - lastVersion.internal_costs, true);
      lastVersion.display_delta_external_costs = this
        ._format(forecast.external_costs - lastVersion.external_costs, true);
      lastVersion.display_delta_intercompany_costs = this
        ._format(forecast.intercompany_costs - lastVersion.intercompany_costs, true);
      lastVersion.display_delta_travels = this
        ._format(forecast.travels - lastVersion.travels, true);
      lastVersion.display_delta_expenses = this
        ._format(forecast.expenses - lastVersion.expenses, true);
      lastVersion.display_delta_margin = this._format(forecast.margin - lastVersion.margin);
      lastVersion.display_delta_contribution_margin = this
        ._format(forecast.contribution_margin - lastVersion.contribution_margin);
      lastVersion.display_delta_wasted = this._format(forecast.wasted - lastVersion.wasted, true);
      lastVersion.display_delta_margin_net = this
        ._format(forecast.margin_net - lastVersion.margin_net);

      // FORECAST
      forecast.display_revenues = this._format(forecast.revenues);
      forecast.display_internal_costs = this._format(forecast.internal_costs, true);
      forecast.display_external_costs = this._format(forecast.external_costs, true);
      forecast.display_intercompany_costs = this._format(forecast.intercompany_costs, true);
      forecast.display_expenses = this._format(forecast.expenses, true);
      forecast.display_travels = this._format(forecast.travels, true);
      forecast.display_margin = this._format(forecast.margin);
      forecast.display_contribution_margin = this._format(forecast.contribution_margin);
      forecast.display_wasted = this._format(forecast.wasted, true);
      forecast.display_margin_net = this._format(forecast.margin_net);

      this.model.set('wastedHoursEnabled', this.model.get('wastedHoursEnabled') || forecast.wasted || lastVersion.wasted || baseline.wasted);
      // This is needed to hide the "intercompany cost" row if the project has no intercompany costs
      this.model.set('hasIntercompanyCosts', forecast.intercompany_costs || lastVersion.intercompany_costs || baseline.intercompany_costs);
    },
  });

  // Timesheet/Project Status CHART
  this.HoursWeeksChartItemView = Marionette.ItemView.extend({
    template: '#hoursWeeksChartTemplate',
    className: '',
    chartColors: {
      workedHours: '#1390ee',
      daysLeft: '#4ed88d',
      rri: '#db4d69',
    },
    ui: {
      rriChart: '[data-action="rri-chart"]',
      daysLeftChart: '[data-action="days-left-chart"]',
      workedHoursChart: '[data-action="worked-hours-chart"]',
    },
    commonChartConfig: {
      chart: {
        zoomType: 'x',
      },
      title: {
        align: 'left',
        margin: 10,
        x: 0,
        useHTML: true,
      },
      subtitle: {
        align: 'left',
        margin: 10,
        x: 0,
        useHTML: true,
      },
      yAxis: {
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          enabled: false,
        },
        events: {
          setExtremes: this.syncExtremes,
        },
        type: 'datetime',
        crosshair: true,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '18px',
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
    },
    getRriChartConfig: function (series) {
      var config = JSON.parse(JSON.stringify(this.commonChartConfig));
      var that = this;
      config.title.x = -35;
      config.subtitle.x = -35;
      config.chart.spacingLeft = 45;
      config.chart.type = 'spline';
      config.title.text = 'Roadrunner Index';
      config.subtitle.text = 'Weekly ratio between reported progress and timesheet';
      config.yAxis.title = { text: 'Index' };
      config.yAxis.plotLines = [{
        value: 1,
        color: '#D8D8D8',
        width: 1,
        label: {
          text: '1',
          x: -25,
          y: 3,
          style: {
            color: '#606060',
            fontWeight: 100,
          },
        },
      }];
      config.yAxis.labels = {
        enabled: false,
      };
      config.yAxis.title.x = -35;
      config.yAxis.gridLineWidth = 0;
      config.xAxis.events = {
        setExtremes: that.syncExtremes,
      };
      config.xAxis.labels = {
        formatter: function () {
          return moment(this.value).format('DD/MM/YY');
        },
      };
      config.plotOptions.series.color = this.chartColors.rri;
      config.plotOptions.series.point = {
        events: {
          mouseOver: function (event) {
            var left = event.target.series.chart.plotLeft + event.target.clientX - 36;

            var labelEl = $('.report-detail__label-week');
            var labelArrowHeight = 14;
            var labelTotalHeight = labelEl.outerHeight() + labelArrowHeight;
            var isTooHigh = event.target.plotY < labelTotalHeight;
            var top = event.target.series.chart.plotTop - 45;
            if (event.target.y !== 0) { // Prevent "Infinite page length" bug (ask atombolato)
              top = event.target.series.chart.plotTop + event.target.plotY - 45;
            }
            if (isTooHigh) {
              var pointHeight = 18;
              top += labelTotalHeight + pointHeight;
              labelEl.addClass('report-detail__label-week--bottom');
            } else {
              labelEl.removeClass('report-detail__label-week--bottom');
            }
            labelEl.html(moment(event.target.x).format('DD/MM/YY'));
            labelEl.css({
              left: left,
              top: top,
            });
          },
        },
      };
      config.series = [{
        name: 'Roadrunner Index',
        data: this.rriSeries,
      }];
      config.tooltip.positioner = function () {
        return {
          x: this.chart.chartWidth - this.label.width, // right aligned
          y: 0, // align to title
        };
      };
      config.tooltip.formatter = function () {
        var value = series[this.point.index][1];
        if (value === 0) {
          return '';
        }
        return value;
      };
      config.tooltip.valueDecimals = 1;
      config.tooltip.headerFormat = '';

      return config;
    },
    getDaysLeftChartConfig: function (budgetDays) {
      var config = JSON.parse(JSON.stringify(this.commonChartConfig));
      var that = this;

      config.chart.type = 'areaspline';
      config.title.text = 'Days Left';
      config.subtitle.text = 'Amount of days needed to complete the project (based on Project Status)';
      config.yAxis.title = { text: 'Days' };
      config.xAxis.events = {
        setExtremes: that.syncExtremes,
      };
      config.xAxis.labels = {
        formatter: function () {
          return moment(this.value).format('DD/MM/YY');
        },
      };
      config.plotOptions.series.color = this.chartColors.daysLeft;
      config.plotOptions.series.fillColor = {
        linearGradient: [0, 0, 1, 200],
        stops: [
          [0, '#4ed88d'],
          [1, 'rgba(78,216,141,0)'],
        ],
      };
      config.plotOptions.series.point = {
        events: {
          mouseOver: function (event) {
            var left = event.target.series.chart.plotLeft + event.target.clientX - 36;

            var labelEl = $('.report-detail__label-week--days-left');
            var labelArrowHeight = 14;
            var labelTotalHeight = labelEl.outerHeight() + labelArrowHeight;
            var isTooHigh = event.target.plotY < labelTotalHeight;
            var top = event.target.series.chart.plotTop + event.target.plotY - 45;
            if (isTooHigh) {
              var pointHeight = 18;
              top += labelTotalHeight + pointHeight;
              labelEl.addClass('report-detail__label-week--days-left--bottom');
            } else {
              labelEl.removeClass('report-detail__label-week--days-left--bottom');
            }
            labelEl.html(moment(event.target.x).format('DD/MM/YY'));
            labelEl.css({
              left: left,
              top: top,
            });
          },
        },
      };
      config.series = [{
        name: 'Days Left',
        data: this.daysLeftSeries,
      }];
      config.tooltip.useHTML = true;
      config.tooltip.positioner = function () {
        return {
          x: this.chart.chartWidth - this.label.width, // right aligned
          y: 0, // align to title
        };
      };
      config.tooltip.formatter = function () {
        var previousPointIndex = parseInt(this.point.index) - 1;
        var previousProjectStatus = budgetDays;
        if (this.series.data[parseInt(this.point.index)].isNull) {
          return '';
        }
        if (previousPointIndex !== -1 && !this.series.data[previousPointIndex].isNull) {
          previousProjectStatus = this.series.data[previousPointIndex].y;
        }
        var progress = previousProjectStatus - this.y;
        return '<span style=\'float: right\'>' + this.y + '</span><br/>'
          + '<div style=\'font-size: 10px;position: relative;top: 5px;\'>'
          + '<span class=\'label-option__name\'>Progress: </span>'
          + '<span class=\'label-option__value\'>' + (Math.round(progress * 10) / 10) + '</span>'
          + '</div>';
      };

      return config;
    },
    getWorkedHoursChartConfig: function () {
      var config = JSON.parse(JSON.stringify(this.commonChartConfig));
      var that = this;

      config.chart.type = 'areaspline';
      config.title.text = 'Worked Hours';
      config.subtitle.text = 'Amount of hours spent on the project (based on Timesheet)';
      config.yAxis.title = { text: 'Hours' };
      config.xAxis.events = {
        setExtremes: that.syncExtremes,
      };
      config.xAxis.labels = {
        formatter: function () {
          return moment(this.value).format('DD/MM/YY');
        },
      };
      config.plotOptions.series.color = {
        linearGradient: [0, 0, 1, 200],
        stops: [
          [0, '#1390ee'],
          [1, 'rgba(19,144,238,0)'],
        ],
      };
      config.plotOptions.series.point = {
        events: {
          mouseOver: function (event) {
            var left = event.target.series.chart.plotLeft + event.target.clientX - 36;

            var labelEl = $('.report-detail__label-week--worked-hours');
            var labelArrowHeight = 14;
            var labelTotalHeight = labelEl.outerHeight() + labelArrowHeight;
            var isTooHigh = event.target.plotY < labelTotalHeight;
            var top = event.target.series.chart.plotTop + event.target.plotY - 45;
            if (isTooHigh) {
              var pointHeight = 18;
              top += labelTotalHeight + pointHeight;
              labelEl.addClass('report-detail__label-week--worked-hours--bottom');
            } else {
              labelEl.removeClass('report-detail__label-week--worked-hours--bottom');
            }
            labelEl.html(moment(event.target.x).format('DD/MM/YY'));
            labelEl.css({
              left: left,
              top: top,
            });
          },
        },
      };
      config.series = [{
        name: 'Worked Hours',
        data: this.workedHoursSeries,
      }];
      config.tooltip.useHTML = true;
      config.tooltip.positioner = function () {
        return {
          x: this.chart.chartWidth - this.label.width, // right aligned
          y: 0, // align to title
        };
      };
      config.tooltip.formatter = function () {
        var days = Math.round((this.y / 8) * 10) / 10;

        return '<span style=\'float: right\'>' + this.y + '</span><br/>'
          + '<div style=\'font-size: 10px;position: relative;top: 5px;\'>'
          + '<span class=\'label-option__name\'>In Days: </span>'
          + '<span class=\'label-option__value\'>' + days + '</span>'
          + '</div>';
      };

      return config;
    },
    minRri: 0,
    initialize: function () {
      this.workedHoursSeries = this.formatSeriesForHighcharts(this.model.get('tt_serie'));
      this.daysLeftSeries = this.formatSeriesForHighcharts(this.model.get('ps_serie'));
      this.rriSeries = this.formatSeriesForHighcharts(this.model.get('roadrunner_serie'));
    },
    /**
     * Returns the given series formatted and ready to be used by Highcharts.
     * @param {Array} series
     */
    formatSeriesForHighcharts: function (series) {
      return series.map(function (step) {
        return [moment(step.name).valueOf(), step.y];
      });
    },
    syncCrosshairs: function (e) {
      var chart;
      var point;
      var i;
      var event;
      var guidePoint = Highcharts.charts[3].series[0].searchPoint(e.originalEvent, true);
      var index = guidePoint ? guidePoint.index : null;

      for (i = 1; i < Highcharts.charts.length; i++) {
        chart = Highcharts.charts[i];
        event = chart.pointer.normalize(e.originalEvent); // Find coordinates within the chart
        point = chart.series[0].data[index]; // Get the hovered point

        chart.xAxis[0].drawCrosshair(event, point); // Show the crosshair

        if (point) {
          chart.tooltip.refresh(point); // Show the tooltip
          point.onMouseOver(); // Show the hover marker
        }
      }
    },
    setSync: function () {
      /*
       * In order to synchronize tooltips and crosshairs, override the
       * built-in events with handlers defined on the parent element.
       */
      $('#rri-chart').bind('mousemove touchmove touchstart', this.syncCrosshairs);
      $('#days-left-chart').bind('mousemove touchmove touchstart', this.syncCrosshairs);
      $('#worked-hours-chart').bind('mousemove touchmove touchstart', this.syncCrosshairs);
      /*
       * Override the reset function, we don't need to hide the tooltips and crosshairs.
       */
      Highcharts.Pointer.prototype.reset = function () {
        return undefined;
      };
    },
    /**
     * Synchronize zooming through the setExtremes event handler.
     */
    syncExtremes: function (e) {
      var thisChart = this.chart;

      if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function (chart) {
          if (chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) { // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, true, { trigger: 'syncExtremes' });
            }
          }
        });
      }
    },

    onShow: function () {
      this.setSync();

      this.ui.rriChart.highcharts(this.getRriChartConfig(this.rriSeries, this.minRri));
      this.ui.daysLeftChart.highcharts(this.getDaysLeftChartConfig(this.model.get('budget_days')));
      this.ui.workedHoursChart.highcharts(this.getWorkedHoursChartConfig());

      Highcharts.charts[1].xAxis[0].setExtremes(undefined, undefined);
    },
  });

  /**
   * Represents a single invoice row in the "Invoices" panel of the "Project Report" section.
   */
  this.InvoiceItemView = Marionette.ItemView.extend({
    template: '#invoicesEconomicsTemplate',
    tagName: 'div',
    className: 'invoices-list__row',
    initialize: function () {
      this.setOrderUrl(this.model.get('id'));
    },
    /**
     * Sets (in the model) the url to follow to reach the finance/invoice section filtered by the
     * given invoiceId.
     * @param invoiceId
     */
    setOrderUrl: function (invoiceId) {
      var url = '/#finance/invoice/' + invoiceId;
      this.model.set('link', url);
    },
  });

  this.InvoiceEmptyItemView = Marionette.ItemView.extend({
    template: '#invoicesEconomicsEmptyTemplate',
    tagName: 'div',
  });

  this.InvoiceCompositeView = Marionette.CompositeView.extend({
    template: '#reportInvoiceTemplate',
    className: 'projectContainer',
    childViewContainer: '[data-region="invoicesCollection"]',
    childView: Detail.InvoiceItemView,
    emptyView: Detail.InvoiceEmptyItemView,
  });

  /**
   * Represents a single order row in the "Orders" panel of the "Project Report" section.
   * A different template will be applied if the order is a "travel" or an "expense".
   */
  this.OrderItemView = Marionette.ItemView.extend({
    template: '#ordersEconomicsTemplate',
    tagName: 'div',
    className: 'orders-list__row',
    initialize: function () {
      if (this.isSpecial()) {
        if (this.isATravel()) {
          this.template = '#travelSpecialOrdersEconomicsTemplate';
          this.setTravelUrl(this.model.get('travel_id'));
        } else if (this.isAnExpense()) {
          this.template = '#expenseSpecialOrdersEconomicsTemplate';
          this.setExpenseUrl(this.model.get('expense_id'));
        } else {
          this.template = '#specialOrdersEconomicsTemplate';
        }
      } else {
        this.setStatus();
        this.setSupplierName();
        this.setOrderUrl(this.model.get('id'));
      }
    },
    /**
     * Checks whether the current order is of "special" type or not. An order is intended to be
     * special if it's an expense or a travel.
     * @returns {boolean}
     */
    isSpecial: function () {
      var orderType = this.model.get('type');
      return orderType === 'special';
    },
    isATravel: function () {
      var orderCode = this.model.get('code');
      return orderCode === 'travel';
    },
    isAnExpense: function () {
      var orderCode = this.model.get('code');
      return orderCode === 'expense';
    },
    /**
     * Sets (in the model) the correct supplier's name for the current order:
     * - If no supplier is provided -> it sets '-'
     */
    setSupplierName: function () {
      var currentSupplierName = this.model.attributes.supplier.name;
      if (currentSupplierName === null) {
        this.model.attributes.supplier.name = '-';
      }
    },
    setTravelUrl: function (travelId) {
      var url = '/#travel/' + travelId;
      this.model.set('link', url);
    },
    setExpenseUrl: function (expenseId) {
      var url = '/#expenses/' + expenseId;
      this.model.set('link', url);
    },
    /**
     * Sets (in the model) the url to follow to reach the finance/order section filtered by the
     * given orderId.
     * @param orderId
     */
    setOrderUrl: function (orderId) {
      var url = '/#finance/order/' + orderId;
      this.model.set('link', url);
    },
    setStatus: function () {
      var status = this.model.get('status');
      status.label = Order.getStatusLabel(status.name);
      this.model.set('status', status);
    },
  });

  this.OrderEmptyItemView = Marionette.ItemView.extend({
    template: '#ordersEconomicsEmptyTemplate',
    tagName: 'ul',
  });

  this.OrderCompositeView = Marionette.CompositeView.extend({
    template: '#reportOrderTemplate',
    className: 'projectContainer',
    childViewContainer: '[data-region="ordersCollection"]',
    childView: Detail.OrderItemView,
    emptyView: Detail.OrderEmptyItemView,
  });

  // SHARE LINK
  this.ShareLinkModalTemplateView = Marionette.ItemView.extend({
    className: 'modalWrapper',
    template: '#reportShareLinkModalTemplate',
    ui: {
      modalEL: '.modalStructure',
      cancelButton: '[data-action="modalCancel"]',
      actionButton: '[data-action="modalDoAction"]',
      userInputEl: '[data-action="autocompleteShareLink"]',
      mdlInput: '[data-region="mdl-input"]',
      autocompleteArea: '[data-region="autocompleteArea"]',
      textEl: '[data-action="modalMessage"]',
      statusEl: '[data-message="statusModalAction"]',
      linkToShare: '[data-region="linkToShare"]',
      copyToClipboardButton: '[data-action="copyToClipboard"]',
    },
    events: {
      'click @ui.userInputEl': 'clickSearchUser',
      'keyup @ui.userInputEl': 'searchUser',
      'click @ui.cancelButton': 'doKoAction',
      'click @ui.actionButton': 'doOkAction',
      'click @ui.copyToClipboardButton': 'copyToClipboard',

      'click @ui.modalEL': 'closeHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      this.ui.actionButton.addClass('disabled');

      var path = window.URL;
      var projectId = this.model.get('id');
      var link = path + '/#project/' + projectId + '/report';

      this.ui.linkToShare.val(link);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var modalW = 350;
      var modalH = 300;
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      if ($(window).width() > 600) {
        this.ui.modalEL.css({
          left: posLeft,
          top: posTop,
        }).show();
      } else {
        this.ui.modalEL.css({
          left: 0,
          top: posTop,
        }).show();
      }
    },
    closeHint: function () {
      this.ui.autocompleteArea.removeClass('isVisible');
    },
    clickSearchUser: function (e) {
      e.stopPropagation();
      var target = $(e.target);

      var value = String(_.escape(target.val().trim()));

      if (value === '') {
        Detail.autocomplete('', this);
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    searchUser: function (e) {
      var target = $(e.target);
      var keyCode = e.keyCode;
      var value = String(_.escape(target.val().trim()));

      if (allowTextChar(keyCode)) {
        Detail.autocomplete(value, this);
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        Detail.shareReportWidth(this.model.get('user_id'), this.ui.textEl.val().trim(), this);
      }
    },
    doKoAction: function (e) {
      e.preventDefault();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.destroy();
      }
    },
    copyToClipboard: function () {
      this.ui.linkToShare.select();
      document.execCommand('copy');

      this.ui.statusEl.text('Copied').addClass('messageModal__feedback--success').show();
      setTimeout(function () {
        this.destroy();
      }.bind(this), 1000);
    },

  });

  // share autocomplete
  this.AutocompleteRecordItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#reportDetailAutocompleteTemplate',
    ui: {
      recordEL: '[data-action="selectRecord"]',
    },
    events: {
      'click @ui.recordEL': 'selectRecord',
    },
    initialize: function (options) {
      this.options = options;
    },
    selectRecord: function (e) {
      e.preventDefault();
      var that = this;

      this.options.parent.ui.userInputEl.parents('.mdl-textfield').addClass('is-dirty is-focused');
      this.options.parent.ui.userInputEl.val(that.model.get('name') + ' ' + that.model.get('surname'));

      this.options.parent.model.set({ user_id: that.model.get('id') });
      this.options.parent.ui.autocompleteArea.removeClass('isVisible');

      this.options.parent.ui.actionButton.removeClass('disabled');
      // $('.budgetAutocompleteList').removeClass('isVisible');
    },
  });

  this.AutocompleteRecordsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'budgetAutocompleteList',
    childView: Detail.AutocompleteRecordItemView,
    onRender: function (options) {
      // this.$el.addClass('isVisible');
      options.options.childViewOptions.parent.ui.autocompleteArea.addClass('isVisible');
    },
  });

  this.SearchNotFoundTemplateView = Marionette.ItemView.extend({
    template: '#reportDetailAutocompleteNotFoundTemplate',
  });

  this.SearchLoadingTemplateView = Marionette.ItemView.extend({
    template: '#reportDetailAutocompleteLoadingTemplate',
  });

  // modal
  var modalW = 350;
  var modalH = 200;
  this.ModalItemView = Marionette.ItemView.extend({
    template: '#reportDetailModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.reportDetailModalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        this.ui.messageEl.text('Saving');
        Detail.updateProjectStatus(this.options.viewObj, 'archive');
      }
    },

    cancelAction: function (e) {
      e.preventDefault();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        this.destroy();
      }
    },
  });

  this.ModalTimetrackingPeriodView = Marionette.LayoutView.extend({
    template: '#reportDetailTimetrackingPeriodModal',
    className: 'modalWrapper',
    regions: {
      content: '[data-region="content"]',
    },
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      fromInput: '[data-action="from"]',
      toInput: '[data-action="to"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this.options = options;
    },
    modelEvents: {
      change: 'onModelChange',
    },
    onRender: function () {
      this.placeModal();
      this.initDatePicker();
    },
    onModelChange: function () {
      this.render();
    },
    initDatePicker: function () {
      var commonOptions = {
        firstDay: 1,
        dateFormat: 'yy-mm-dd',
      };
      var fromOptions = _.clone(commonOptions);
      fromOptions.defaultDate = moment(this.model.get('from')).format('YYYY-MM-DD');
      fromOptions.maxDate = moment(this.model.get('to')).toDate();
      fromOptions.onSelect = function (dateText) {
        this.model.set('from', dateText);
      }.bind(this);

      var toOptions = _.clone(commonOptions);
      toOptions.defaultDate = moment(this.model.get('to')).format('YYYY-MM-DD');
      toOptions.maxDate = moment().toDate();
      toOptions.minDate = moment(this.model.get('from')).toDate();
      toOptions.onSelect = function (dateText) {
        this.model.set('to', dateText);
      }.bind(this);

      fromOptions.beforeShowDay = function (date) {
        var td = date.getDay();
        return [(date.getDay() !== 2 && date.getDay() !== 3 && date.getDay() !== 4 && date.getDay() !== 5 && date.getDay() !== 6 && date.getDay() !== 0), '', (td !== 'Tue' && td !== 'Wed' && td !== 'Thu' && td !== 'Fri' && td !== 'Sat' && td !== 'Sun') ? '' : 'only on mondays'];
      };
      toOptions.beforeShowDay = function (date) {
        var td = date.getDay();
        return [(date.getDay() !== 2 && date.getDay() !== 3 && date.getDay() !== 4 && date.getDay() !== 5 && date.getDay() !== 6 && date.getDay() !== 1), '', (td !== 'Tue' && td !== 'Wed' && td !== 'Thu' && td !== 'Fri' && td !== 'Sat' && td !== 'Mon') ? '' : 'only on sundays'];
      };

      this.ui.fromInput.datepicker(fromOptions);
      this.ui.toInput.datepicker(toOptions);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    showLoading: function () {
      var view = new Detail.MiniLoadingView();
      this.content.show(view);
    },
    doOkAction: function (e) {
      e.preventDefault();
      this.showLoading();
      var params = {
        from: this.model.get('from'),
        to: this.model.get('to'),
        project_id: this.options.projectId,
      };
      $.when(Wethod.request('report:hours-users:get', params)).done(function (employees) {
        dispatcher.trigger('report:detail:hour-user:set-period', this.model, employees);
        this.destroy();
      }.bind(this));
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.MiniLoadingView = Marionette.ItemView.extend({
    template: '#miniLoadingTemplate',
  });

  this.ModalExportItemView = Marionette.ItemView.extend({
    template: '#exportModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function () {

    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      Detail.doModuleExport(this);
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
