/* eslint-disable camelcase */
const React = require('react');
const { isMe } = require('../../../../../../services/UserService');
const { formatDate } = require('../../../../../../services/FormatService');
const formatName = require('../../../../../../services/FormatService').formatOneLetterPersonName;

const RequestEmployee = ({ employee_id, employee, status, sent_to, sent_date, created_on }) => {
  const getTravelOffice = () => {
    if (sent_to) {
      return ` and sent to ${sent_to.alias}`;
    }
    return '';
  };

  const getRequestMessage = () => (status !== 'created' ? 'Requested by ' : 'Created by ');

  if (employee_id && !isMe(employee_id)) {
    return (
      <div className="travel__sidebar-employee">
        {getRequestMessage()}
        {formatName(`${employee.name} ${employee.surname}`)} on {status !== 'created'
          ? formatDate(sent_date) : formatDate(created_on)}
        {getTravelOffice()}
      </div>
    );
  }
  return null;
};

module.exports = RequestEmployee;
