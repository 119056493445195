/**
 * Utility for using feature flags.
 */
class FeatureService {
  /**
   * @param {string[]} enabledFeatures list of current company enabled feature-flag keys
   */
  constructor(enabledFeatures) {
    this.enabledFeatures = enabledFeatures;
  }

  /**
   * Return if feature identified by the given name is enabled for current company.
   * @param {string} key
   * @return {boolean}
   */
  isEnabled(key) {
    return this.enabledFeatures.includes(key);
  }
}

FeatureService.FEATURE_FLAG_WEEKLY_TIMESHEET_DAILY_MODE = 'weekly-timesheet-daily-mode';
FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK = 'jakala-features-november-block';
FeatureService.DATA_FREEZING = 'data-freezing';
FeatureService.DATA_FREEZING_PRODUCTION = 'data-freezing-production';
FeatureService.PUBLIC_API = 'public-api';
FeatureService.INFINITE_LEVELS = 'infinite-levels';
FeatureService.HIERARCHY_BUDGET = 'budget-gerarchie';

module.exports = FeatureService;
