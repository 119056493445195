/* eslint-disable react/sort-comp,class-methods-use-this,react/no-array-index-key */
const React = require('react');
const Event = require('../../containers/EventsPast/Event');
const EventForm = require('../../containers/EventsPast/EventForm');
const Log = require('./Log/Log.react');
const PastFollowUp = require('../../containers/FollowUp/FollowUpPast');
const HeatmapEvent = require('./HeatmapEvent/HeatmapEvent.react');

module.exports = class EventsPast extends React.Component {
  isLog(type) {
    return type.indexOf('changelog') !== -1 || this.isLifecycleLog(type)
      || this.isFinancialLog(type) || this.isCanvasLog(type);
  }

  isLifecycleLog(type) {
    const lifecycleTypes = 'iproject_created,project_started,project_ended';
    return lifecycleTypes.indexOf(type) !== -1;
  }

  isFinancialLog(type) {
    const financialTypes = 'invoice_created,order_created,travel_created,expenses_created';
    return financialTypes.indexOf(type) !== -1;
  }

  isCanvasLog(type) {
    const canvasTypes = 'board_created,brief_created,concept_created';
    return canvasTypes.indexOf(type) !== -1;
  }

  isPastFollowUp(type) {
    return type.indexOf('follow_up') !== -1;
  }

  isHeatmapEvent(type) {
    return type === 'heatmap_event';
  }

  /**
   * Returns true if the user requested to edit the event with the given id.
   * @param id
   * @returns {*|boolean}
   */
  isEditingEvent(id) {
    const focusedEventId = this.props.focusedEvent ? this.props.focusedEvent.id : null;
    return this.props.showEditEventForm && focusedEventId === id;
  }

  getEvents() {
    return this.props.events.map((event, index) => {
      if (this.isLog(event.type)) {
        return <Log key={index} filter={this.props.filter} {...event} />;
      }
      if (this.isPastFollowUp(event.type)) {
        return <PastFollowUp key={index} filter={this.props.filter} {...event} />;
      }
      if (this.isEditingEvent(event.event.id)) {
        return (
          <EventForm
            key={index}
            {...event.event}
            inputs={['type-select', 'datetime-picker', 'description']} />
        );
      }
      if (this.isHeatmapEvent(event.type)) {
        return <HeatmapEvent key={index} filter={this.props.filter} {...event} />;
      }
      return <Event key={index} {...event} />;
    });
  }

  render() {
    return (
      <div className="timeline-events-past">
        <div className="timeline-events__list">
          {this.getEvents()}
        </div>
      </div>
    );
  }
};
