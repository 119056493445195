const React = require('react');
/**
 * Used to display a value of a ProjectInfoSecondaryItem.
 * @param children
 * @param color
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectInfoSecondaryValue = ({ children, color }) => (
  <div className="wethod-budget-header__project-info-value--secondary" style={{ color }}>{children}</div>
);

module.exports = ProjectInfoSecondaryValue;
