const React = require('react');

const Column = ({ children, className, column, ...props }) => (
  <div ref={props.myRef}
    onScroll={props.onScroll}
    className={`planning-conversion-modal__areas-column-${column} ${className} ${props.showShadows ? `planning-show-shadow-${column}` : ''}`}>
    {children}
  </div>
);

module.exports = Column;
