'use strict';

var BusinessUnitService = require('../../../services/BusinessUnitService');

Wethod.module('ReportApp.threeYP', function (threeYP, Wethod, Backbone, Marionette, $, _, Header) {
  var structureTemplate;
  var reportData;

  var resetData = function () {
    structureTemplate = {};
    reportData = undefined;
  };

  this.budgetBreakdownGraphSeries = function (data) {
    return [{
      name: 'Budget 2021',
      data: [data.budget, 0, null],
      enableMouseTracking: false,
      dataLabels: { enabled: false },
      color: 'rgba(72,165,249,1)',
    }, {
      name: 'Bluesky',
      cursor: undefined,
      point: {
        events: { click: undefined },
      },
      data: [null, data.bluesky.value, null],
      color: 'rgba(72,165,249,0.3)',
      dataLabels: {
        formatter: function () {
          return data.bluesky.percentage + '%';
        },
      },
    }, {
      name: 'Delta- Revenue Target',
      data: [null, data.delta_revenue_target.value, null],
      color: 'rgba(72,165,249,0.6)',
      dataLabels: {
        formatter: function () {
          return data.delta_revenue_target.percentage + '%';
        },
      },
    }, {
      name: 'Production',
      data: [null, data.pipeline.value, null],
      color: 'rgba(72,165,249,1)',
      dataLabels: {
        formatter: function () {
          return data.pipeline.percentage + '%';
        },
      },
    }];
  };

  this.probabilityGraphSeries = function (years, data) {
    var probabilities = [];
    var series = [];
    // recupero le probabilità
    _.each(data, function (dateVal) {
      var dataProb = (_.isObject(dateVal)) ? _.keys(dateVal) : [];
      probabilities = _.union(probabilities, dataProb);
    });

    _.each(probabilities, function (probability) {
      var probabilityData = {};
      probabilityData.name = parseInt(probability);
      probabilityData.data = [];
      probabilityData.color = '#48a5f9';
      _.each(years, function (year) {
        var value = 0;
        if (!_.isUndefined(data[year]) && !_.isUndefined(data[year][probability])) {
          _.each(data[year][probability], function (project) {
            value += parseFloat((project.amount * project.probability) / 100);
          });
        }
        probabilityData.data.push(value);
      });
      series.push(probabilityData);
    });
    return _.sortBy(series, 'name');
  };

  // a partire dal dato delle probablità disegno il grafico per i client
  this.clientGraphSeries = function (years, data) {
    var serie = [];
    _.each(years, function (year) {
      var value = 0;
      if (!_.isUndefined(data[year])) {
        _.each(data[year], function (client) {
          value += parseFloat(client.amount);
        });
      }
      serie.push({
        name: year,
        y: value,
      });
    });
    return [{
      color: '#48a5f9',
      data: serie,
    }];
  };

  this.projectLabelGraphSeries = function (years, data) {
    var serie = [];
    _.each(years, function (year) {
      var value = 0;
      if (!_.isUndefined(data[year])) {
        _.each(data[year], function (type) {
          value += parseFloat(type.amount);
        });
      }
      serie.push({
        name: year,
        y: value,
      });
    });
    return [{
      color: '#48a5f9',
      data: serie,
    }];
  };

  this.projectLabelGroupGraphSeries = function (years, data) {
    var serie = [];
    _.each(years, function (year) {
      var value = 0;
      if (!_.isUndefined(data[year])) {
        _.each(data[year], function (type) {
          value += parseFloat(type.amount);
        });
      }
      serie.push({
        name: year,
        y: value,
      });
    });
    return [{
      color: '#48a5f9',
      data: serie,
    }];
  };

  this.metadataGraphSeries = function (years, data) {
    if (!threeYP.metadataType || (!threeYP.metadataValue && (threeYP.metadataType !== 'No metadata' && threeYP.metadataType !== 'All'))) {
      return [{
        color: '#48a5f9',
        data: [],
      }];
    }

    var serie = [];
    _.each(years, function (year) {
      var value = 0;
      if (!_.isUndefined(data[year])) {
        _.each(data[year], function (metadata, currentType) {
          if (threeYP.metadataType === 'All' || currentType === threeYP.metadataType) {
            if (currentType === 'No metadata') {
              _.each(metadata, function (client) {
                value += parseFloat(client.amount);
              });
            }
            if (currentType !== 'No metadata') {
              _.each(metadata, function (metadataValue, currentValue) {
                if (currentValue === threeYP.metadataValue || threeYP.metadataType === 'All') {
                  _.each(metadataValue, function (client) {
                    value += parseFloat(client.partial_amount);
                  });
                }
              });
            }
          }
        });
      }
      serie.push({
        name: year,
        y: value,
      });
    });
    return [{
      color: '#48a5f9',
      data: serie,
    }];
  };

  threeYP.showBudgetBreakdownDetail = function (type, year, totalValue) {
    var data = [];
    _.each(reportData.get('budget_breakdown').by_client, function (client) {
      var record = {
        id: client.id,
        name: client.name,
        production: {
          quarters: _.map(client.production.quarters, function (quarter) {
            return quarter / 1000;
          }),
          total: client.production.total / 1000,
        },
        revenue_target: {
          quarters: _.map(client.revenue_target.quarters, function (quarter) {
            return quarter / 1000;
          }),
          total: client.revenue_target.total / 1000,
        },
        revenue_target_actual: {
          quarters: _.map(client.revenue_target_actual.quarters, function (quarter) {
            return quarter / 1000;
          }),
          total: client.revenue_target_actual.total / 1000,
        },
        delta: {
          quarters: _.map(client.delta.quarters, function (quarter) {
            return quarter / 1000;
          }),
          total: client.delta.total / 1000,
        },
        type: type,
      };
      data.push(record);
    });

    data = _.sortBy(data, function (item) {
      return type === 'Production' ? -item.production.total : item.delta.total;
    });

    var detailsModel = new threeYP.DetailModel({
      year: year,
      title: type,
      totalValue: totalValue,
    });
    var detailsCollection = new threeYP.GraphDetailsCollection(data);
    var detailTemplate = new threeYP.DetailCompositeBudgetBreakdownView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    structureTemplate.budgetBreakdownGraphDetail.show(detailTemplate);
    structureTemplate.ui.budgetBreakdownWrap.addClass('isVisible');
  };

  threeYP.showByProbabilityDetail = function (probability, year) {
    var fullYear = year.toString().includes('/') ? '20' + year.split('/')[0] : year;
    var data = [];
    _.each(reportData.get('by_probabilities')[fullYear][probability], function (project) {
      var record = {
        client: project.client.name,
        project: project.name,
        value: project.amount / 1000,
        probability: probability,
        amount: ((project.amount * probability) / 100) / 1000,
      };
      data.push(record);
    });

    data = _.sortBy(data, function (item) {
      return -item.value;
    });

    var detailsModel = new threeYP.DetailModel({
      year: year,
      probability: probability,
    });
    var detailsCollection = new threeYP.GraphDetailsCollection(data);
    var detailTemplate = new threeYP.DetailCompositeView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    structureTemplate.byProbabilityGraphDetail.show(detailTemplate);
    structureTemplate.ui.byProbabilityDetailWrap.addClass('isVisible');
  };

  threeYP.showByClientDetail = function (totalAmount, year) {
    totalAmount = parseFloat(totalAmount) / 1000;
    var data = [];
    var fullYear = year.toString().length <= 2 || year.toString().includes('/') ? '20' + year.toString().split('/')[0] : year;

    _.each(reportData.get('by_clients')[fullYear], function (client) {
      data.push({
        name: client.name,
        value: client.amount / 1000,
        perc: parseFloat((client.amount / 1000 / totalAmount) * 100),
      });
    });

    data = _.sortBy(data, function (item) {
      return -item.value;
    });

    var formattedYear = year.toString().length === 2 ? year + '/' + (year + 1) : year;

    var detailsModel = new threeYP.DetailModel({ year: formattedYear });
    var detailsCollection = new threeYP.GraphDetailsCollection(data);
    var detailTemplate = new threeYP.DetailCompositeClientView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    structureTemplate.byClientGraphDetail.show(detailTemplate);
    structureTemplate.ui.byClientDetailWrap.addClass('isVisible');
  };

  threeYP.showByProjectLabelDetail = function (totalAmount, year) {
    totalAmount = parseFloat(totalAmount) / 1000;
    var data = [];
    var fullYear = year.toString().length <= 2 || year.toString().includes('/') ? '20' + year.toString().split('/')[0] : year;
    var formattedYear = year.toString().length === 2 ? year + '/' + (year + 1) : year;

    _.each(reportData.get('by_types')[fullYear], function (type) {
      data.push({
        name: type.name,
        value: type.amount / 1000,
        perc: parseFloat((type.amount / 1000 / totalAmount) * 100),
      });
    });

    data = _.sortBy(data, function (item) {
      return -item.value;
    });

    var detailsModel = new threeYP.DetailModel({ year: formattedYear });
    var detailsCollection = new threeYP.GraphDetailsCollection(data);
    var detailTemplate = new threeYP.DetailCompositeProjectLabelView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    structureTemplate.byProjectLabelGraphDetail.show(detailTemplate);
    structureTemplate.ui.byProjectLabelDetailWrap.addClass('isVisible');
  };

  threeYP.showByProjectLabelGroupDetail = function (totalAmount, year) {
    totalAmount = parseFloat(totalAmount) / 1000;
    var data = [];
    var fullYear = year.toString().length <= 2 || year.toString().includes('/') ? '20' + year.toString().split('/')[0] : year;
    var formattedYear = year.toString().length === 2 ? year + '/' + (year + 1) : year;

    _.each(reportData.get('by_category')[fullYear], function (type) {
      data.push({
        name: type.category,
        value: type.amount / 1000,
        perc: parseFloat((type.amount / 1000 / totalAmount) * 100),
      });
    });

    data = _.sortBy(data, function (item) {
      return -item.value;
    });

    var detailsModel = new threeYP.DetailModel({ year: formattedYear });
    var detailsCollection = new threeYP.GraphDetailsCollection(data);
    var detailTemplate = new threeYP.DetailCompositeProjectLabelGroupView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    structureTemplate.byProjectLabelGroupGraphDetail.show(detailTemplate);
    structureTemplate.ui.byProjectLabelGroupDetailWrap.addClass('isVisible');
  };

  threeYP.showByMetadataDetail = function (totalAmount, year) {
    totalAmount = parseFloat(totalAmount) / 1000;
    var data = [];
    var fullYear = year.toString().length <= 2 || year.toString().includes('/') ? '20' + year.toString().split('/')[0] : year;
    var formattedYear = year.toString().length === 2 ? year + '/' + (year + 1) : year;

    var metadataType = threeYP.metadataType;
    var metadataValue = threeYP.metadataValue;

    var detailsModel = null;
    var detailsCollection = null;
    var detailTemplate = null;

    if (metadataType === 'No metadata') {
      _.each(reportData.get('by_metadata')[fullYear][metadataType], function (client) {
        data.push({
          name: client.name,
          value: client.amount / 1000,
          perc: parseFloat((client.amount / 1000 / totalAmount) * 100),
        });
      });
    }

    if (metadataType === 'All') {
      _.each(reportData.get('by_clients')[fullYear], function (client) {
        data.push({
          name: client.name,
          value: client.amount / 1000,
          perc: parseFloat((client.amount / 1000 / totalAmount) * 100),
        });
      });
    }

    if (metadataValue && metadataType && metadataType !== 'No metadata' && metadataType !== 'All') {
      _.each(reportData.get('by_metadata')[fullYear][metadataType][metadataValue], function (client) {
        data.push({
          name: client.name,
          value: client.amount / 1000,
          perc: parseFloat((client.amount / 1000 / totalAmount) * 100),
        });
      });
    }

    data = _.sortBy(data, function (item) {
      return -item.value;
    });

    if ($(window).width() <= 600) {
      window.scrollTo(0, window.pageYOffset + 400);
    }

    detailsModel = new threeYP.DetailModel({
      year: formattedYear,
      metadataValue: threeYP.metadataValue,
    });
    detailsCollection = new threeYP.GraphDetailsCollection(data);
    detailTemplate = new threeYP.DetailCompositeMetadataView({
      model: detailsModel,
      collection: detailsCollection,
    });

    if (data.length < 1) {
      detailTemplate = new threeYP.DetailCompositeEmptyMetadataView({
        model: detailsModel,
        collection: detailsCollection,
      });
    }

    structureTemplate.byMetadataGraphDetail.show(detailTemplate);
    structureTemplate.ui.byMetadataDetailWrap.addClass('isVisible');
  };

  threeYP.closeMetadataDetail = function () {
    structureTemplate.ui.byMetadataDetailWrap.removeClass('isVisible');
  };

  threeYP.saveMetadataType = function (data) {
    threeYP.metadataType = data.attributes.name;
  };

  threeYP.saveMetadataValue = function (data) {
    threeYP.metadataValue = data.attributes.name;
  };

  // EXPORT
  this.showModalExport = function () {
    var modalExport = new threeYP.ModalExportItemView();
    structureTemplate.modal.show(modalExport);
  };
  this.doModuleExport = function (viewObj) {
    var moduleExportRequest = Wethod.request('get:3yp:prob:export');
    $.when(moduleExportRequest).done(function () {

    });
    viewObj.destroy();
  };

  threeYP.Controller = {
    showPipelineForecastReport: function () {
      var businessUnitService = new BusinessUnitService();

      resetData();

      if (!threeYP.metadataType) {
        threeYP.metadataType = 'All';
      }

      // loading
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var reportAuthorizationRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthorizationRequest).done(function (reportAuthorizationResponse) {
        if (_.isUndefined(reportAuthorizationResponse)) {
          return;
        }

        Wethod.ReportApp.authorization = reportAuthorizationResponse;

        if (Wethod.ReportApp.getPermission('view_3yp')) {
          var bu = businessUnitService.getLastSelected();
          var reportDataRequest = Wethod.request('get:report:threeYP', bu);
          $.when(reportDataRequest).done(function (reportDataResponse) {
            if (_.isUndefined(reportDataResponse)) {
              return;
            }

            if (reportDataResponse.get('code') !== 403) {
              reportData = reportDataResponse;

              structureTemplate = new threeYP.StructureLayoutView({
                reports: reportDataResponse,
              });

              var sectionHeaderModel = new Header.SectionHeaderModel({
                current_section: 'Sales Pipeline',
                preview_anchor_id: 'revenue-pipeline',
                helper_url: 'reports/index/#revenue-pipeline',
              });
              var revenuePipelineHeaderRegion = new Header
                .SectionHeaderView({ model: sectionHeaderModel });

              structureTemplate.render();
              structureTemplate.headerSection.show(revenuePipelineHeaderRegion);
              structureTemplate.on('report:data:updated', function (data) {
                reportData = data;
              });
            } else {
              loadingView.destroy();
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            }

            dispatcher.on('choose:metadata:type', threeYP.saveMetadataType);
            dispatcher.on('choose:metadata:value', threeYP.saveMetadataValue);
          });
        } else {
          loadingView.destroy();
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },
  };
}, Wethod.module('HeaderApp.Header'));
