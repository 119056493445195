const { connect } = require('react-redux');
const Actions = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isSaving: state.isSaving,
  isLoading: state.isLoadingCompanies || state.isLoadingEmployees,
});

const mapDispatchToProps = (dispatch) => ({
  addOpportunity: () => dispatch(actions.addNewOpportunity()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Actions);
