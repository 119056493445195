const { connect } = require('react-redux');
const Component = require('../../../components/modals/Portfolio/Work.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  updateWork: (work, employeeId) => dispatch(actions.updateWork(work, employeeId)),
  deleteWork: (work) => dispatch(actions.deleteWork(work)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
