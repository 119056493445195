const { connect } = require('react-redux');
const Component = require('../../../../../../../common/react/TabSwitcher.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  items: [{
    key: 'month',
    name: 'Last 3 months',
  }, {
    key: 'year',
    name: 'Last year',
  }],
  current: state.trendsFilter,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (filter) => dispatch(actions.chooseTrendsFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
