'use strict';

Wethod.module('ProjectReviewApp', function (ProjectReviewApp, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#appTemplate',
    regions: {
      headerSection: '[data-region="sectionHeader"]',
      timelineBody: '[data-region="body"]',
    },
  });
});
