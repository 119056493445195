const React = require('react');
const moment = require('moment');
const MonthSelect = require('../../../../../../common/react/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const DaySelect = require('../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const WithBuEnabled = require('../../containers/WithBuEnabled');
const Description = require('./ItemDescription.react');
const BuSelect = require('./BuSelect.react');
const ClientSelect = require('./ClientSelect.react');
const ContactSelect = require('../../containers/sidebar/CustomerSelect');
const JocSelect = require('../../containers/sidebar/JobOrderCategorySelect');
const ProjectTypeSelect = require('../../containers/sidebar/ProjectTypeSelect');
const MetadataField = require('./MetadataField.react');
const ProbabilityField = require('./ProbabilityField.react');
const ExternalCostSelect = require('./ExternalCostSelect.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');
const InputMetadataManager = require('../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');
const TextField = require('../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const NumericField = require('../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const PmSelect = require('./PmSelect.react');
const AccountSelect = require('./AccountSelect.react');
const Row = require('../../../../../../common/react/sidebar/SidebarInputRow.react');
const InputWrapper = require('../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const ProgramBar = require('./ProgramBar.react');
const MarginField = require('./MarginField.react');
const StageSelect = require('../../containers/sidebar/StageSelect');
const ForOpportunities = require('../../containers/ForOpportunities');
const RiskSelect = require('../../containers/sidebar/RiskSelect');
const ForProjects = require('../../containers/ForProjects');
const BudgetConsumptionField = require('./BudgetConsumptionField.react');
const ProgressField = require('./ProgressField.react');
const WhitelistField = require('./WhitelistField.react');
const JobOrderField = require('../../containers/sidebar/JobOrderField');
const PipedriveField = require('./PipedriveDealsField.react');

module.exports = class ItemBody extends React.Component {
  static getAttribute(object, attribute) {
    return object ? object[attribute] : null;
  }

  constructor(props) {
    super(props);

    this.handleProjectInputChange = this.handleProjectInputChange.bind(this);
    this.handleWhitelistChange = this.handleWhitelistChange.bind(this);
    this.handleWhitelistEnable = this.handleWhitelistEnable.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (this.props.onChange) {
      this.props.onChange(name, value);
    }
  }

  /**
   * Format the changes to some project attributes:
   * create the updated project object to be given as changes
   * @param name
   * @param value
   */
  handleProjectInputChange(e) {
    const { name, value } = e.target;
    if (this.props.onChange) {
      this.props.onChange('project', value, name);
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const formattedDate = value ? moment(value).format('YYYY-MM-DD') : null;
    this.props.onChange('project', formattedDate, name);
  }

  handleNumericChange(e) {
    const { name, value } = e.target;
    let formattedValue = parseInt(value);
    formattedValue = Number.isNaN(formattedValue) ? null : formattedValue;
    this.props.onChange('project', formattedValue, name);
  }

  handleMetadataChange(e, option) {
    const { name } = e.target;
    if (this.props.onChange) {
      const metadataId = parseInt(name);
      const selectedValue = option && option.selected_value ? option : null;
      this.props.onChange('metadata', selectedValue, metadataId);
    }
  }

  handleNonEmptyChange(e, option) {
    const { name } = e.target;
    if (this.props.onChange) {
      if (option) {
        this.props.onChange(name, option);
      } else {
        const defaultObject = { id: null };
        this.props.onChange(name, defaultObject);
      }
    }
  }

  /**
   * Format the changes to whitelist attribute
   * @param name
   * @param value
   */
  handleWhitelistChange(name, value) {
    if (this.props.onChange) {
      this.props.onChange('project', value, name);
    }
  }

  /**
   * Format the changes to whitelist attribute
   * @param e
   */
  handleWhitelistEnable(e) {
    const { name, checked } = e.target;
    if (this.props.onChange) {
      this.props.onChange('project', checked, name);
    }
  }

  /**
   * Format the changes to job order
   * @param name
   * @param value
   */
  handleJobOrderChanges(name, value) {
    if (this.props.onChange) {
      this.props.onChange('project', value, name);
    }
  }

  /**
   * Return the metadata value selected in the item, given a generic metadata
   * @param metadata
   * @returns {null|*}
   */
  getSelectedMetadataValue(metadata) {
    if (this.props.item.metadata && this.props.item.metadata.length) {
      const selected = this.props.item.metadata
        .filter((selectedMetadata) => selectedMetadata.metadata_id === metadata.id);
      return selected && selected.length ? selected[0] : null;
    }
    return null;
  }

  /**
   * Return the list of metadata select from the list of available metadata in the company
   * @returns {null|Array}
   */
  getMetadata() {
    const metadataList = this.props.getMetadata();
    if (metadataList.length) {
      return metadataList.map((metadata) => (
        <Row key={metadata.id}>
          <InputWrapper>
            <MetadataField readOnly={this.props.readOnly}
              metadata={metadata}
              onChange={this.handleMetadataChange.bind(this)}
              errors={this.props.errors}
              updateErrors={this.props.updateErrors} />
          </InputWrapper>
        </Row>
      ));
    }

    return null;
  }

  getRiskHelperText() {
    if (!this.props.item.can_edit_risk && !this.props.readOnly) {
      return 'Edit your first project status in order to set the risk.';
    }
    return '';
  }

  getValueSuffix() {
    return this.props.isValueAsUnit ? null : 'K';
  }

  getDurationMinValue() {
    return this.props.metadataService.getConstraintValue('duration', 'greater_than') || 1;
  }

  render() {
    return (
      <div className="pipeline__sidebar-body">
        <ShowIf condition={this.props.readOnly}
          showElse={<Description creation={this.props.creation} />}>
          <ProgramBar project={this.props.item.project} program={this.props.item.program} />
        </ShowIf>
        <ForOpportunities>
          <ShowIf condition={this.props.item.project_type.track_opportunity_status}>
            <Row className="pipeline__stage">
              <InputWrapper>
                <InputMetadataManager name="opportunity_status"
                  metadataService={this.props.metadataService}
                  canEdit={!this.props.readOnly && this.props.canEditStage}>
                  <StageSelect
                    value={this.props.item.opportunity_status.id}
                    color={this.props.item.opportunity_status.color}
                    error={this.props.errors.opportunity_status}
                    updateErrors={this.props.updateErrors}
                    onChange={this.handleNonEmptyChange.bind(this)} />
                </InputMetadataManager>
              </InputWrapper>
            </Row>
          </ShowIf>
        </ForOpportunities>
        {this.props.item.project.archived
          && (
            <Row>
              <InputWrapper icon={
                <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
              }>
                <DaySelect readOnly
                  name="archived_date"
                  id="sidebar-archived-date"
                  label="Archived on"
                  helperText="The date the project was archived"
                  errorText={this.props.errors.archived_date}
                  value={this.props.item.project.archived_date} />
              </InputWrapper>
            </Row>
          )}
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required', 'maxLength:500']}>
              <InputMetadataManager name="name"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly}>
                <TextField
                  id="sidebar-name"
                  label="Name"
                  value={this.props.item.project.name}
                  onBlur={this.handleChange.bind(this)}
                  errorText={this.props.errors.name} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
          }>
            <InputMetadataManager name="client"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <ClientSelect
                value={this.props.item.client}
                onChange={this.handleNonEmptyChange.bind(this)}
                error={this.props.errors.client}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="customer"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <ContactSelect
                value={this.props.item.customer}
                client={this.props.item.client}
                onChange={this.handleNonEmptyChange.bind(this)}
                error={this.props.errors.customer}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="client_po"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <TextField
                id="sidebar-client-po"
                label="Client PO"
                value={this.props.item.project.client_po ? this.props.item.project.client_po : ''}
                onBlur={this.handleProjectInputChange} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper icon={<span className="wethod-icon wethod-icon-pm" />}>
            <InputMetadataManager name="pm"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <PmSelect
                error={this.props.errors.pm}
                value={this.props.item.pm}
                onChange={this.handleNonEmptyChange.bind(this)}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="account"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <AccountSelect
                error={this.props.errors.account}
                value={this.props.item.account}
                onChange={this.handleNonEmptyChange.bind(this)}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="project_type"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly && this.props.canEditJoc}>
              <JocSelect
                value={this.props.item.project_type.id}
                color={this.props.item.project_type.color}
                error={this.props.errors.project_type}
                updateErrors={this.props.updateErrors}
                onChange={this.handleNonEmptyChange.bind(this)} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="project_label"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <ProjectTypeSelect
                value={this.props.item.project_label.id}
                error={this.props.errors.project_label}
                updateErrors={this.props.updateErrors}
                onChange={this.handleNonEmptyChange.bind(this)} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager name="id_job_order"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly && this.props.canEditJobOrder}>
              <JobOrderField
                id="sidebar-job-order"
                label="Job order"
                value={this.props.item.project.id_job_order ? this.props.item.project.id_job_order : ''}
                joc={this.props.item.project_type}
                project={this.props.item.project}
                onChange={this.handleJobOrderChanges.bind(this)} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <ForProjects>
          <Row>
            <InputWrapper>
              <InputMetadataManager name="risk"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly && this.props.canEditRisk}>
                <RiskSelect
                  value={this.props.item.risk.id}
                  color={this.props.item.risk.color}
                  helperText={this.getRiskHelperText()}
                  error={this.props.errors.risk}
                  updateErrors={this.props.updateErrors}
                  onChange={this.handleNonEmptyChange.bind(this)} />
              </InputMetadataManager>
            </InputWrapper>
          </Row>
        </ForProjects>
        <Row className="pipeline__sidebar-multiple-row">
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
          }>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
              <InputMetadataManager name="estimate"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly && this.props.canEditValue}>
                <NumericField
                  className="pipeline__sidebar-estimate"
                  id="sidebar-estimate"
                  label="Value"
                  suffix={this.getValueSuffix()}
                  value={this.props.getValue()}
                  onBlur={this.handleNumericChange.bind(this)}
                  errorText={this.props.errors.estimate} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
          <InputWrapper>
            <InputMetadataManager name="probability"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly && this.props.canEditProbability}>
              <ProbabilityField
                value={this.props.item.project.probability}
                label="Probability"
                errorText={this.props.errors.probability}
                updateErrors={this.props.updateErrors}
                onChange={this.handleNumericChange.bind(this)}
                creation={this.props.creation}
                showAsSelect={!this.props.isPipedriveIntegrationEnabled} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row className="pipeline__sidebar-multiple-row">
          <InputWrapper>
            <NumericField
              className="pipeline__sidebar-estimate"
              readOnly
              id="sidebar-costs-value"
              label="External costs"
              suffix={this.getValueSuffix()}
              value={this.props.getExternalCostsValue()} />
          </InputWrapper>
          <InputWrapper>
            <InputMetadataManager name="external_cost"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly && this.props.canEditCosts}>
              <ExternalCostSelect
                value={this.props.getExternalCostsPercentage()}
                onChange={this.handleProjectInputChange} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row className="pipeline__sidebar-multiple-row">
          <InputWrapper>
            <MarginField value={this.props.getMargin()} />
          </InputWrapper>
        </Row>
        <Row className="pipeline__sidebar-multiple-row">
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          }>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
              <InputMetadataManager name="date_start"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly}>
                <MonthSelect
                  id="sidebar-date-start"
                  label="Start"
                  errorText={this.props.errors.date_start}
                  value={this.props.item.project.date_start}
                  disableBeforeEqual={this.props.metadataService.getConstraintValue('date_start', 'greater_than')}
                  onChange={this.handleDateChange.bind(this)} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
          <InputWrapper>
            <InputValidator updateErrors={this.props.updateErrors}
              constraints={['required', `min:${this.getDurationMinValue()}`]}>
              <InputMetadataManager name="duration"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly}>
                <NumericField
                  label="Months"
                  id="sidebar-duration"
                  errorText={this.props.errors.duration}
                  value={this.props.item.project.duration || 0}
                  onBlur={this.handleNumericChange.bind(this)} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          }>
            <InputMetadataManager name="due_date"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <DaySelect
                resettable
                id="sidebar-due-date"
                label="Due date"
                errorText={this.props.errors.due_date}
                value={this.props.item.project.due_date}
                onChange={this.handleDateChange.bind(this)} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          }>
            <InputMetadataManager name="contract_date"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <DaySelect
                resettable
                id="sidebar-contract-date"
                label="Signed on"
                helperText="The date the agreement was signed"
                errorText={this.props.errors.contract_date}
                value={this.props.item.project.contract_date}
                onChange={this.handleDateChange.bind(this)} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <ForProjects>
          <Row>
            <InputWrapper>
              <BudgetConsumptionField value={this.props.item.budget_consumption} />
            </InputWrapper>
          </Row>
        </ForProjects>
        <ForProjects>
          <Row>
            <InputWrapper>
              <ProgressField value={this.props.item.progress} />
            </InputWrapper>
          </Row>
        </ForProjects>
        <WithBuEnabled>
          <Row>
            <InputWrapper>
              <InputMetadataManager name="business_unit"
                metadataService={this.props.metadataService}
                canEdit={!this.props.readOnly}>
                <BuSelect
                  value={this.props.item.business_unit || null}
                  onChange={this.props.onChange} />
              </InputMetadataManager>
            </InputWrapper>
          </Row>
        </WithBuEnabled>
        {this.getMetadata()}
        <ShowIf condition={!this.props.readOnly || this.props.item.project.timesheet_whitelist}>
          <div>
            <h3 className="wethod-sidebar__subtitle">Timesheet whitelist</h3>
            <InputMetadataManager name="timesheet_whitelist"
              metadataService={this.props.metadataService}
              canEdit={!this.props.readOnly}>
              <WhitelistField enabled={this.props.item.project.timesheet_whitelist}
                value={this.props.item.project.whitelisted_employee}
                onChange={this.handleWhitelistChange}
                onEnable={this.handleWhitelistEnable} />
            </InputMetadataManager>
          </div>
        </ShowIf>
        <ShowIf condition={this.props.isPipedriveIntegrationEnabled}>
          <PipedriveField name="pipedrive_deals"
            value={this.props.item.pipedrive_deals}
            client={this.props.item.client}
            readOnly={this.props.readOnly}
            onChange={this.props.onChange} />
        </ShowIf>
      </div>
    );
  }
};
