/* eslint-disable react/sort-comp,react/no-did-update-set-state,class-methods-use-this */
const React = require('react');

module.exports = class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.scores
        ? this.getSeriesWithFormattedMarkers(this.props.scores) : [],
    };

    this.colors = {
      positive: '#4ED88D',
      neutral: '#f5a640',
      negative: '#DB4D69',
    };

    this.chartOptions = {
      chart: {
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)',
        style: {
          fontFamily: 'Rubik',
        },
      },
      plotOptions: {
        series: {
          label: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            formatter() {
              const { point } = this;
              const { series } = this;
              const { y } = this;
              let lastPointIndex = null;
              for (let i = 0; i < series.data.length; i++) {
                if (series.data[i].y !== null) {
                  lastPointIndex = i;
                }
              }
              if (point.index === lastPointIndex) { // is last point
                const formattedValue = numeral(y).format('0,0.[0]');
                return `${formattedValue}`;
              }
              return '';
            },
            style: {
              fontWeight: '400',
              fontSize: '13px',
              textShadow: 'none',
              textOutline: 0,
            },
            crop: false,
            align: 'left',
          },
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: '',
      },
      lang: {
        noData: 'No data to display',
      },
      noData: {
        position: {
          x: 30,
        },
      },
      xAxis: {
        categories: ['Q1', 'Q2', 'Q3', 'Q4'],
        lineColor: 'rgba(0,0,0,0)',
        tickColor: 'rgba(0,0,0,0)',
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          const formattedValue = numeral(this.y).format('0,0.[0]');
          const value = `${formattedValue}`;
          return `${this.x}: <b>${value}</b>`;
        },
      },
    };
  }

  getSeriesColor() {
    const firstValue = this.props.scores.q1;
    const lastValue = this.props.scores.q4;

    if (lastValue < firstValue) {
      return 'negative';
    }
    return 'positive';
  }

  getOptions() {
    return {
      ...this.chartOptions,
      plotOptions: {
        ...this.chartOptions.plotOptions,
        series: {
          ...this.chartOptions.plotOptions.series,
          color: this.colors[this.getSeriesColor()],
        },
      },
      series: [{
        data: this.state.data,
      }],
    };
  }

  getSeriesWithFormattedMarkers(scores) {
    const series = [{ y: scores.q1 }, { y: scores.q2 }, { y: scores.q3 }, { y: scores.q4 }];
    let lastPointIndex = null;
    for (let i = 0; i < series.length; i++) {
      if (series[i].y !== null) {
        lastPointIndex = i;

        const prev = i > 0 ? series[i - 1].y : null;
        const next = i < series.length - 1 ? series[i + 1].y : null;
        if (prev === null && next === null) {
          series[i].marker = {
            enabled: true,
          };
        }
      }
    }
    if (lastPointIndex !== null) {
      series[lastPointIndex].marker = {
        enabled: true,
      };
    }
    return series;
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getOptions());
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.setState({ data: this.getSeriesWithFormattedMarkers(this.props.scores) }, () => {
        this.renderChart();
      });
    }
  }

  render() {
    return (
      <div className="trend__report-chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
