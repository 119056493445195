/* eslint-disable react/no-array-index-key */
const React = require('react');
const Resource = require('../../containers/Resources/Resource');
const ResourceEditable = require('../../containers/Resources/ResourceEditable');

module.exports = class Resources extends React.Component {
  getResources() {
    return this.props.resources.map((item, index) => <Resource key={index} {...item} />);
  }

  render() {
    return (
      <div className="client-person__resources">
        <div className="client__title">RESOURCES</div>
        <div className="client-person__resources-list">
          <div className="client-person__resources-list-header wethod-list-header">
            <div className="client-person__resources-list--name wethod-list-column" />
            <div className="client-person__resources-list--notes wethod-list-column" />
            <div className="client-person__resources-list--actions wethod-list-column" />
          </div>
          <div className="client-person__resources-list-items">
            <ul className="client-person__resources-list-items--new">
              <ResourceEditable />
            </ul>
            <ul>
              {this.getResources()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};
