const React = require('react');
const BudgetModel = require('../../../../../models/Budget');
const PropertyMetadataService = require('../../../../../services/PropertyMetadataService/PropertyMetadataService');
const PropertyMetadata = require('../../../../../services/PropertyMetadataService/PropertyMetadata');

/**
 * Injects budget-related permissions into children.
 *
 * @param editBudgetPermission
 * @param editOtherBudgetPermission
 * @param approveBudgetAsPmPermission
 * @param approveBudgetAsAccountPermission
 * @param approveOtherBudgetPermission
 * @param employeeId
 * @param projectManagerId
 * @param accountId
 * @param budgetStatus
 * @param children
 * @param isSaving
 * @param createBudgetPermission
 * @param createOtherBudgetPermission
 * @param viewBudgetPermission
 * @param viewOtherBudgetPermission
 * @param editProjectStatusPermission
 * @param editOtherProjectStatusPermission
 * @param editBudgetPricelist
 * @param rest
 * @returns {React.DetailedReactHTMLElement<{budgetIsEditable: *}, HTMLElement>}
 * @constructor
 */
const PermissionManager = ({
  editBudgetPermission, editOtherBudgetPermission, approveBudgetAsPmPermission,
  approveBudgetAsAccountPermission, approveOtherBudgetPermission, employeeId, projectManagerId,
  accountId, budgetStatus, children, isSaving, createBudgetPermission, createOtherBudgetPermission,
  viewBudgetPermission, viewOtherBudgetPermission, editProjectStatusPermission,
  editOtherProjectStatusPermission, editBudgetPricelist, projectMetadata, ...rest
}) => {
  const userIsProjectPm = () => employeeId === projectManagerId;

  const userIsProjectAccount = () => employeeId === accountId;

  const userIsProjectOwner = () => userIsProjectPm() || userIsProjectAccount();

  const userHasBudgetEditPermissions = () => {
    const canEditBecauseOwner = editBudgetPermission && userIsProjectOwner();

    return editOtherBudgetPermission || canEditBecauseOwner;
  };

  const userHasBudgetViewPermissions = () => {
    const canViewBecauseOwner = viewBudgetPermission && userIsProjectOwner();

    return viewOtherBudgetPermission || canViewBecauseOwner;
  };

  const userHasBudgetApprovePermissions = () => {
    const canApproveBecausePm = userIsProjectPm() && approveBudgetAsPmPermission;
    const canApproveBecauseAccount = userIsProjectAccount() && approveBudgetAsAccountPermission;

    return canApproveBecausePm || canApproveBecauseAccount || approveOtherBudgetPermission;
  };

  /**
   * Return true if budget's project is frozen.
   * @return {boolean}
   */
  const projectIsFrozen = () => {
    const metadataService = new PropertyMetadataService(projectMetadata);
    const canEdit = metadataService.getCanEdit('budgets');
    const frozenDataReason = metadataService.getReason('budgets') === PropertyMetadata.CANNOT_EDIT_DATA_FROZEN;

    return !canEdit && frozenDataReason;
  };

  const budgetIsEditable = () => {
    const canEditBecauseDraft = userHasBudgetEditPermissions()
      && budgetStatus === BudgetModel.STATUS_DRAFT;
    const canEditBecauseSubmitted = userHasBudgetApprovePermissions()
      && userHasBudgetEditPermissions() && budgetStatus === BudgetModel.STATUS_SUBMITTED;

    return !projectIsFrozen() && (canEditBecauseDraft || canEditBecauseSubmitted);
  };

  const userHasBudgetCreatePermissions = () => {
    const canCreateBecausePm = userIsProjectPm() && createBudgetPermission;
    const canCreateBecauseAccount = userIsProjectAccount() && createBudgetPermission;

    return canCreateBecausePm || canCreateBecauseAccount || createOtherBudgetPermission;
  };

  const userHasProjectStatusEditPermissions = () => {
    const canEditBecauseOwner = editBudgetPermission && userIsProjectOwner();

    return editOtherProjectStatusPermission || canEditBecauseOwner;
  };

  const pricelistIsEditable = () => budgetIsEditable() && editBudgetPricelist;

  return React.cloneElement(children, {
    ...rest,
    budgetIsEditable: budgetIsEditable(),
    userHasBudgetApprovePermissions: userHasBudgetApprovePermissions(),
    userHasBudgetEditPermissions: userHasBudgetEditPermissions(),
    userHasBudgetCreatePermissions: userHasBudgetCreatePermissions(),
    userHasBudgetViewPermissions: userHasBudgetViewPermissions(),
    userHasProjectStatusEditPermissions: userHasProjectStatusEditPermissions(),
    pricelistIsEditable: pricelistIsEditable(),
    projectIsFrozen: projectIsFrozen(),
  });
};

module.exports = PermissionManager;
