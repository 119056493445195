/* eslint-disable default-case,camelcase */

'use strict';

var BusinessUnitService = require('../../../services/BusinessUnitService');

Wethod.module('ReportApp.Report', function (Report, Wethod, Backbone, Marionette, $, _, Header) {
  var maxYAxis = {
    notArchived: 100,
    showArchivedAndNot: 100,
    onlyArchived: 100,
  };
  var structureTemplate;
  var chartSeriesTemplate;
  var archiveFilter = 'notArchived';
  var notArchiveChart = [];
  var archiveChart = [];
  var allDataChart = [];

  var resetData = function () {
    structureTemplate = {};
  };

  var createBubbleChartSeries = function (data, filter) {
    var series = [];

    _.each(data, function (info) {
      var serie = {};
      if (!_.isUndefined(info.budget) && !_.isEmpty(info.budget) && !_.isNull(info.budget.id)) {
        if (parseInt(info.actual) > maxYAxis[filter]) {
          maxYAxis[filter] = parseInt(info.actual);
        }

        serie.name = info.project.name + ' <br> ' + info.client.name;
        serie.color = (info.progress >= info.actual ? '#1390ee' : '#d03557');
        serie.info = info;
        serie.data = [{
          x: parseFloat(info.progress),
          y: parseFloat(info.actual),
          z: parseFloat(info.budget.suggested_net_price || 0),
          id: info.project.id,
          name: info.project.name + ' - ' + info.client.name,
          dataLabels: {
            crop: false,
            enabled: true,
            color: 'black',
            formatter: function () {
              var subClientName = (!_.isNull(info.client.acronym)
                && !_.isUndefined(info.client.acronym)) ? info.client.acronym : info.client.name;
              var subJobOrder = (!_.isNull(info.project.job_order)
                && !_.isUndefined(info.project.job_order))
                ? info.project.job_order : info.project.name;

              subClientName = subClientName.substring(0, 3);
              subJobOrder = subJobOrder.substring(subJobOrder.length - 3, subJobOrder.length);

              var label = '';
              if (subClientName.charAt(subClientName.length) === '_' || subJobOrder.charAt(0) === '_') {
                label = subClientName + subJobOrder;
              } else {
                label = subClientName + '_' + subJobOrder;
              }

              return label.toLowerCase();
            },
          },
          events: {
            click: function () {
              Report.Controller.showProjectInfo(
                this.series.options.info,
                this.series.options.color
              );
            },
          },
        }];

        series.push(serie);
      }
    });

    var divider = {
      name: '',
      enableMouseTracking: false,
      lineWidth: 1,
      type: 'line',
      marker: { enabled: false },
      color: '#B8B6B4',
      data: [
        [0, 0],
        [120, 120],
      ],
    };
    series.push(divider);
    return series;
  };

  this.switchArchiveFilter = function (filter) {
    archiveFilter = filter;
    Report.chartFilter(null);
  };

  this.reloadReport = function () {
    var reportReloadRequest = Wethod.request('report:reload');
    $.when(reportReloadRequest).done(function () {
      Wethod.navigate('reports/budget-consumption');
      Report.Controller.showReport();
    });
  };

  this.chartFilter = function (filters, projectStatusPercentage) {
    filters = filters || null;
    var series;
    var client;
    var project;
    var pmName;
    var accountName;
    var jobOrder;
    var projectType;
    var
      businessUnit;

    switch (archiveFilter) {
      case 'notArchived':
        series = notArchiveChart;
        break;
      case 'showArchivedAndNot':
        series = allDataChart;
        break;
      case 'onlyArchived':
        series = archiveChart;
        break;
    }

    _.each(series, function (serie) {
      if (!serie.info) {
        return; // series can contains some non-bubble data
      }

      // If no filters all series are visible
      if (!filters) {
        // eslint-disable-next-line no-use-before-define
        serie.visible = visible;
        return;
      }

      client = serie.info.client.name.toLowerCase();
      project = _.unescape(serie.info.project.name.toLowerCase());
      pmName = serie.info.pm.name.toLowerCase() + ' ' + serie.info.pm.surname.toLowerCase();
      accountName = serie.info.account ? serie.info.account.name.toLowerCase() + ' ' + serie.info.account.surname.toLowerCase() : '';
      projectType = serie.info.project_type.name.toLowerCase();
      jobOrder = serie.info.project.job_order || '';

      var visible = true;
      visible = visible && this.applyFilter(filters.clients, client);
      visible = visible && this.applyFilter(filters.projects, project);
      visible = visible && this.applyFilter(filters.pms, pmName);
      visible = visible && this.applyFilter(filters.accounts, accountName);
      visible = visible && this.applyFilter(filters.jobOrders, jobOrder);
      visible = visible && this.applyFilter(filters.projectTypes, projectType);

      if (filters.businessUnits) {
        // List of BU name coming from filter input
        var selectedBusinessUnitNames = filters.businessUnits.trim().toLowerCase().split(',');
        // Series BU
        businessUnit = String(serie.info.business_unit);
        var businessUnitService = new BusinessUnitService();
        // BU with the same name written on the input
        var buToSearch = businessUnitService.getForCompany().filter(function (bu) {
          return selectedBusinessUnitNames.indexOf(bu.name.toLowerCase()) !== -1;
        });
        var idToSearch = buToSearch.map(function (bu) {
          return String(bu.id);
        });

        // Text in input is not a known BU name
        if (!buToSearch.length) {
          visible = false;
        } else {
          visible = visible && idToSearch.indexOf(businessUnit) !== -1;
        }
      }

      serie.visible = visible;
    }.bind(this));

    chartSeriesTemplate.ui.reportChart.highcharts(Report.chart(series, maxYAxis[archiveFilter],
      projectStatusPercentage));
  };

  /**
   * Apply an 'or' filter of 'filters' on key
   *
   * @param string filters
   * @param string key
   */
  this.applyFilter = function (filters, key) {
    var visible = false;
    // eslint-disable-next-line array-callback-return
    filters.split(' ').map(function (filter) {
      visible = visible || key.includes(filter);
    });

    return visible;
  };

  Report.Controller = {
    showReport: function (idTour) {
      var businessUnitService = new BusinessUnitService();

      resetData();

      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var reportAuthorizationRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthorizationRequest).done(function (reportAuthorizationResponse) {
        if (_.isUndefined(reportAuthorizationResponse)) {
          return;
        }

        Wethod.ReportApp.authorization = reportAuthorizationResponse;

        if (Wethod.ReportApp.getPermission('view_bubbles')) {
          var reportRequest = Wethod.request('report:get:all');
          $.when(reportRequest).done(function (reportResponse) {
            var availableBusinessUnits = businessUnitService.getForCompany();

            if (_.isUndefined(reportResponse)) {
              return;
            }

            // check if "cached_on" is set on response
            var cached_on;
            if (!_.isUndefined(reportResponse.attributes.cached_on)) {
              cached_on = reportResponse.attributes.cached_on;
              delete reportResponse.attributes.cached_on;
            }

            if (reportResponse.get('code') !== 403) {
              // separo in tre array i dati relativi ai progetti
              _.each(reportResponse.attributes, function (bubble) {
                if (!bubble.project.archived) {
                  // proj non archiviati
                  notArchiveChart.push(bubble);
                } else {
                  // proj archiviati
                  archiveChart.push(bubble);
                }
                // tutti i proj
                allDataChart.push(bubble);
              });

              notArchiveChart = createBubbleChartSeries(notArchiveChart, 'notArchived');
              archiveChart = createBubbleChartSeries(archiveChart, 'onlyArchived');
              allDataChart = createBubbleChartSeries(allDataChart, 'showArchivedAndNot');
              var progressPercent = _.find(reportResponse.attributes, function (project) {
                return project.project_type.progress_percent;
              });

              var bubbleChartTemplate = new Report.BubbleChartTemplateView({
                series: notArchiveChart,
                maxYAxis: maxYAxis.notArchived,
                availableBusinessUnits: availableBusinessUnits,
                projectStatusPercentage: progressPercent,
              });

              chartSeriesTemplate = bubbleChartTemplate;

              var headerTemplate = new Report.HeaderTemplateView();
              var chartFilterTemplate = new Report.ChartFilterTemplateView({
                chart: bubbleChartTemplate,
                projectStatusPercentage: progressPercent,
                model: new Backbone.Model({ hasBusinessUnits: businessUnitService.isEnabled() }),
              });
              var labelFiler = new Report.FilterLabelView();
              var cachedNote = new Report.CachedNoteView({ cached_on: cached_on });

              structureTemplate = new Report.StructureLayoutView();
              structureTemplate.render();
              var sectionHeaderModel = new Header.SectionHeaderModel({
                current_section: 'Consumption vs Status',
                tour_id: 74343,
                tour_start_page: 'reports/budget-consumption',
                helper_url: 'reports/index/#budget-consumption',
              });
              var budgetConsumptionHeaderRegion = new Header
                .SectionHeaderView({ model: sectionHeaderModel });

              structureTemplate.headerSection.show(budgetConsumptionHeaderRegion);
              structureTemplate.head.show(headerTemplate);
              structureTemplate.cachedNote.show(cachedNote);
              structureTemplate.filterLabel.show(labelFiler);
              structureTemplate.chart.show(bubbleChartTemplate);
              structureTemplate.filters.show(chartFilterTemplate);

              if (businessUnitService.isEnabled()) {
                var chartHeight = $('.reportFilter').outerHeight() + 323;
                $('.highcharts-container').css('height', chartHeight + 'px');
              }

              dispatcher.trigger('tour:start', idTour);
            } else {
              loadingView.destroy();
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            }
          });
        } else {
          loadingView.destroy();
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },
    showProjectInfo: function (info, color) {
      var show = false;
      // controllo di avere i permessi per vedere il dettaglio
      // se il progetto è mio ne vedo sempre il dettaglio
      if (info.pm.id === Wethod.userInfo.get('employee_id') || Wethod.ReportApp.getPermission('view_details')) {
        show = true;
      }

      info.showDetail = show;
      var infoItemView = new Report.InfoTemplateView({
        projectInfo: info,
        color: color,
      });

      if ($(window).width() <= 600) {
        structureTemplate.mobileInfo.show(infoItemView);
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        structureTemplate.info.show(infoItemView);
      }
    },
  };
}, Wethod.module('HeaderApp.Header'));
