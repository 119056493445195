'use strict';

// TODO on budget-refactor contract delete this file?

Wethod.module('BudgetApp.Budget', function (Budget, Wethod, Backbone, Marionette, $) {
  this.ModalModel = Wethod.Utility.BaseModel.extend();

  this.GroupCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/intercompany/company';
    },
    model: Wethod.Utility.BaseModel,
  });

  var API = {
    getGroup: function () {
      var record = new Budget.GroupCollection();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('company:get:group', function () {
    return API.getGroup();
  });
});
