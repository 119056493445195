const { connect } = require('react-redux');
const Component = require('../../components/Goals/Goal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'save-goal').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  saveGoal: (goal, projectId, persist) => dispatch(actions.saveGoal(goal, projectId, persist)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
