const { connect } = require('react-redux');
const DeleteCompanyFailureModal = require('../../../overview/components/DeleteCompanyFailureModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  client: state.company,
  isWaiting: state.isDeletingClient,
  projects: state.linkedProjects,
  contacts: state.linkedContacts,
  companies: state.otherCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
  replaceClient: (from, toId) => dispatch(actions.replaceCompany(from, toId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyFailureModal);
