const React = require('react');
const YearNavigator = require('../../../../../../../common/react/YearNavigator/YearNavigator.react');

const Filters = ({ year, getKudos, employee }) => {
  function onYearChange(changedYear) {
    getKudos(employee, changedYear);
  }

  return (
    <div className="profile-objectives__filters">
      <YearNavigator
        year={year}
        changeDate={onYearChange} />
    </div>
  );
};

module.exports = Filters;
