const React = require('react');
const PropTypes = require('prop-types');
const Avatar = require('../AvatarBox/Avatar.react');

const Description = ({ title, subtitle, pic, showPic }) => {
  const getPic = () => {
    if (showPic) {
      return (
        <div className="wethod-info-header__pic">
          <Avatar name={title} pic={pic} size="medium" />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wethod-info-header__description-content">
      {getPic()}
      <div className="wethod-info-header__description-text">
        <h1 className="wethod-info-header__title">{_.unescape(title)}</h1>
        <h2 className="wethod-info-header__subtitle">{_.unescape(subtitle)}</h2>
      </div>
    </div>
  );
};

Description.defaultProps = {
  title: '',
  subtitle: '',
  pic: null,
  showPic: false,
};

Description.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pic: PropTypes.string,
  showPic: PropTypes.bool,
};

module.exports = Description;
