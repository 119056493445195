const { connect } = require('react-redux');
const Component = require('../../../components/dashboard/unarchived-projects/UnarchivedProjects.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.includes('unarchived-projects'),
  items: state.unarchivedProjectsData.items,
  search: state.unarchivedProjectsData.search,
  filters: state.unarchivedProjectsData.filters,
  offset: state.unarchivedProjectsData.offset,
  limit: state.unarchivedProjectsData.limit,
  hasMorePages: state.unarchivedProjectsData.hasMorePages,
  sort: state.unarchivedProjectsData.sort,
  order: state.unarchivedProjectsData.order,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  getItems:
    (offset, limit, search, order, sort, filters) => dispatch(actions
      .getUnarchivedProjects(offset, limit, search, order, sort, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
