'use strict';

Wethod.module('PlanningApp.Planning', function (ProductionPlan, Wethod, Backbone, Marionette, $) {
  this.ProductionPlanModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/production-plan/',
  });
  this.ProductionPlanCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/production-plan/',
    model: ProductionPlan.ProductionPlanModel,
    _offset: 0,
    _limit: 50,
    _search: null,
    _archived: false,
    _mine: null,
    _bu: 'all',
    initialize: function (options) {
      if (options.search) {
        this._search = options.search.replace(/ /g, ','); // white space to comma
      }

      if (options.offset) {
        this._offset = options.offset;
      }

      if (options.limit) {
        this._limit = options.limit;
      }

      if (options.archived) {
        this._archived = options.archived;
      }

      if (options.mine) {
        this._mine = options.mine;
      }

      this._bu = options.bu;

      var query = '?offset=' + this._offset + '&limit=' + this._limit;
      query = this._search ? query + '&search=' + this._search : query;
      query = this._archived ? query + '&archived_only=' + this._archived : query;
      query = this._mine ? query + '&mine=' + this._mine : query;
      query = this._bu !== 'all' ? query + '&bu=' + this._bu : query;
      this._offset += this._limit;
      this.url = APIURL + '/production-plan/' + query;
    },
  });

  var API = {
    getProductionPlan: function (offset, search, limit, archived, mine, units) {
      var collection = new ProductionPlan.ProductionPlanCollection({
        offset: offset,
        search: search,
        limit: limit,
        archived: archived,
        mine: mine,
        bu: units,
      });

      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data.toJSON());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    saveProductionPlan: function (attributes) {
      var model = new ProductionPlan.ProductionPlanModel(attributes);
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel) {
          defer.resolve(responseModel);
        },
        error: function (responseModel, response) {
          defer.reject(response);
        },
        wait: true,
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:planning:production-plan', function (offset, search, limit, archived, mine, units) {
    return API.getProductionPlan(offset, search, limit, archived, mine, units);
  });
  Wethod.reqres.setHandler('save:planning:production-plan', function (attributes) {
    return API.saveProductionPlan(attributes);
  });
});
