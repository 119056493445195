/**
 * Filter items on a given key
 * The key must be contained in some attribute for the item to be shown and have the given level
 *
 * @param key
 * @param items
 * @param levelId
 * @returns {Array} items filtered by key
 */
module.exports = (key, items, levelId) => {
  /**
   * Returns true if value contains key.
   *
   * @param keyword
   * @param value
   * @returns {boolean}
   */
  const found = (keyword, value) => {
    if (value) return value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    return false;
  };

  const isInName = (keyword, name) => found(keyword, _.unescape(name));

  const isInSurname = (keyword, surname) => found(keyword, _.unescape(surname));

  // Returns true if the item has the selected level. When no level is selected, all items are returned
  const isInCurrentLevel = (selectedLevel, employeeLevel) => (
    selectedLevel ? selectedLevel === employeeLevel : true
  );

  // Returns true if the item has data for the selected level.
  const isInLevel = (selectedLevel, employeeLevels) => {
    const selectedLevelFound = employeeLevels.filter((lvl) => lvl.id === selectedLevel);
    return selectedLevelFound && selectedLevelFound.length;
  };

  return items.filter((item) => (
    isInName(key, item.name) || isInSurname(key, item.surname))
    && (isInCurrentLevel(levelId, item.current_level) || isInLevel(levelId, item.levels)));
};
