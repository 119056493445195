/* eslint-disable react/prop-types,react/jsx-no-bind,react/prefer-stateless-function */
const React = require('react');

module.exports = class Button extends React.Component {
  render() {
    return (
      <button type="button"
        className="material-input-button"
        name={this.props.name}
        ref={(el) => this.button = el}
        onClick={(e) => {
          if (this.props.onClick) {
            this.props.onClick(e, this.props.name);
          }
        }}
        onFocus={this.props.onFocus}>
        {this.props.children}
      </button>
    );
  }
};
