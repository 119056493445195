/* eslint-disable class-methods-use-this,default-case,react/sort-comp */
const React = require('react');
const format = require('../../../../../../services/FormatService').formatDate;

module.exports = class ContactInfo extends React.Component {
  componentDidMount() {
    if (!this.props.isWaitingForGender) this.props.getGenders();
  }

  getFormattedDate(date) {
    if (date) return format(date);

    return date;
  }

  getUrl(address) {
    if (address) {
      if (address.indexOf('http') !== -1) return address;
      return `http://${address}`;
    }
    return '';
  }

  infoExists(name) {
    const info = this.props[name];
    return info !== null && info !== undefined && info !== '';
  }

  getInfo(name) {
    if (this.infoExists(name)) {
      switch (name) {
        case 'email':
          return (
            <a href={`mailto:${this.props.email}`}
              title="Email"
              className="profile-contact-info__item profile-contact-info__item--email">{this.props.email}
            </a>
          );
        case 'telephone':
          return (
            <a href={`tel:${this.props.telephone}`}
              title="Phone"
              className="profile-contact-info__item profile-contact-info__item--telephone">{this.props.telephone}
            </a>
          );
        case 'skype':
          return (
            <a href={`skype:${this.props.skype}?chat`}
              title="Skype"
              className="profile-contact-info__item profile-contact-info__item--skype">{this.props.skype}
            </a>
          );
        case 'linkedin':
          return (
            <a href={this.getUrl(this.props.linkedin)}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
              className="profile-contact-info__item profile-contact-info__item--linkedin">{this.props.linkedin}
            </a>
          );
        case 'birthday':
          return <span className="profile-contact-info__item profile-contact-info__item--birthday">{this.getFormattedDate(this.props.birthday)}</span>;
        case 'gender':
          return <span className="profile-contact-info__item profile-contact-info__item--gender">{this.props.gender}</span>;
      }
    }
    return <span className={`profile-contact-info__item profile-contact-info__item--${name}`} />;
  }

  render() {
    return (
      <div className="profile-contact-info">
        <div className="profile-contact-info__column">
          {this.getInfo('email')}
          {this.getInfo('telephone')}
          {this.getInfo('birthday')}
        </div>
        <div className="profile-contact-info__column">
          {this.getInfo('linkedin')}
          {this.getInfo('gender')}
        </div>
      </div>
    );
  }
};
