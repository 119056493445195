const { connect } = require('react-redux');
const Component = require('../components/ReviewRequests.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  year: state.year,
  isWaiting: state.waitingFor.filter((key) => key === 'filter-reviews' || key === 'load-reviews').length > 0,
  keyword: state.keyword,
  hasMore: !state.lastPage,
  deletedElements: state.deletedElements,
  createdElements: state.createdElements,
});

const mapDispatchToProps = (dispatch) => ({
  loadMore: (page, size, keyword, deletedElements, year) => dispatch(actions
    .loadMoreReviewRequests(page, size, keyword, deletedElements, year)),
  filter: (keyword, size, year) => dispatch(actions.filterReviewRequests(keyword, size, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
