'use strict';

Wethod.module('ProjectReviewApp', function (ProjectReviewApp) {
  this.startWithParent = false;

  var API = {
    showProjectReview: function (idProject, idReview) {
      ProjectReviewApp.Controller.show(idProject, idReview);
    },
  };

  ProjectReviewApp.on('start', function (options) {
    API[options.foo](options.idProject, options.idReview);
  });
});
