/* eslint-disable react/prop-types */
const React = require('react');

module.exports = class HowToTour extends React.Component {
  onClick(e) {
    if (this.props.show_owned_companies) {
      e.preventDefault();
      this.props.showSecondTab(this.props.id);
    }
  }

  render() {
    return (
      <li className="helper-modal__item helper-modal__how-to-link">
        <a href={this.props.tour_url}
          onClick={this.onClick.bind(this)}>
          <span className="helper-modal__item-label">
            <span className="helper-modal__how-to-link-title">{this.props.title}</span>
          </span>
          <span className="helper-modal__item-arrow wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </a>
      </li>
    );
  }
};
