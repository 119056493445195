/* eslint-disable consistent-return */
const React = require('react');
const NPV = require('./NullablePercentageValue.react');

module.exports = ({ a, b, cost }) => {
  if (a === null || b === null) {
    return <span>-</span>;
  }
  if (a === b) {
    return <NPV value={a} />;
  }
  if (cost) {
    if (a > b) {
      return <span className="briefing-aside-chart-table__value--bad"><NPV value={a} /></span>;
    }
    if (a < b) {
      return <span className="briefing-aside-chart-table__value--good"><NPV value={a} /></span>;
    }
  } else {
    if (a < b) {
      return <span className="briefing-aside-chart-table__value--bad"><NPV value={a} /></span>;
    }
    if (a > b) {
      return <span className="briefing-aside-chart-table__value--good"><NPV value={a} /></span>;
    }
  }
};
