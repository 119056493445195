const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Order = require('../Order.react');
const authManager = require('../../services/authManager');
const Multicurrency = require('../../services/multicurrencyService');

const ApprovePaymentModal = ({
  onCancelClick,
  data,
  showApprovedNotificationModal,
}) => {
  const canApproveOrder = () => authManager.getPermission('approve', data.order);

  function handleSave() {
    if (canApproveOrder()) {
      const updatedOrder = {
        ...data.order,
        status: Order.STATUS_APPROVED,
      };
      showApprovedNotificationModal(updatedOrder);
    }
  }

  const getSaveStyle = () => {
    let style = 'orders__confirm-button';
    if (!canApproveOrder()) {
      style += ' orders__confirm-button--disabled';
    }
    return style;
  };

  const getBody = () => {
    const value = Multicurrency.getFormattedConvertedValue(data.order, 2);
    const supplier = data.order.supplier.name;

    return (
      <div className="orders__approve-payment-modal__body">
        <p>
          You are about to approve a payment of
          <b> {value} {data.order.currency}</b> to <b>{supplier}</b>.
        </p>
        <p>
          Are you sure you want to continue?
        </p>
      </div>
    );
  };

  const getActions = () => (
    <div className="orders__approve-payment-modal__actions">
      <button type="button" onClick={onCancelClick} className="orders__cancel-button">Not yet
      </button>
      <button type="button" onClick={handleSave} className={getSaveStyle()}>Yes, pay them</button>
    </div>
  );

  return (
    <Modal title="Approve payment"
      onCancelClick={onCancelClick}
      className="orders__approve-payment-modal">
      {getBody()}
      {getActions()}
    </Modal>
  );
};

module.exports = ApprovePaymentModal;
