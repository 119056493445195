const React = require('react');
const TextInput = require('../../../../../../../../../common/react/TextInput.react');
const SearchSelect = require('../../containers/CompanySearchSelect');

module.exports = class ImportantInfoEditable extends React.Component {
  /**
   * Returns the inputs' error message.
   */
  getErrorMessage(name) {
    const inputErrors = this.props.errorMessages ? this.props.errorMessages[name] : null;
    if (inputErrors) {
      return inputErrors[0];
    }
    return '';
  }

  getCompany() {
    return { ...this.props.person.client, name: this.props.person.client.corporate_name };
  }

  render() {
    return (
      <div>
        <div className="client-info-row">
          <div className="client-info-row__title">Company</div>
          <div className="client-info-row__value">
            <SearchSelect name="company"
              current={this.getCompany()}
              onValidate={this.props.onValidate}
              onChange={this.props.onChange} />
          </div>
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('company')}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Name</div>
          <div className="client-info-row__value">
            <TextInput name="name"
              value={this.props.person.name}
              constraints="required"
              onValidate={this.props.onValidate}
              onChange={this.props.onChange} />
          </div>
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('name')}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Surname</div>
          <div className="client-info-row__value">
            <TextInput name="surname"
              value={this.props.person.surname}
              constraints="required"
              onValidate={this.props.onValidate}
              onChange={this.props.onChange} />
          </div>
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('surname')}</div>
        </div>
      </div>
    );
  }
};
