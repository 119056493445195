/* eslint-disable react/no-array-index-key */
const React = require('react');
const Search = require('../containers/PeopleSearchBox');

module.exports = ({ title, legendItems, showSearch }) => {
  const getLegend = () => {
    const items = legendItems || ['1 - 6 Not likely', '7 - 8 Neutral', '9 - 10 Very likely'];

    return items.map((item, index) => (
      <span
        key={index}
        className="project-review__step-header-subtitle-atom">
        {item}
      </span>
    ));
  };

  const getSearch = () => {
    if (showSearch) {
      return <Search />;
    }
    return null;
  };

  return (
    <div className="project-review__step-header">
      <h2 className="project-review__step-header-title">{title}</h2>
      <div className="project-review__step-header-subtitle">
        {getLegend()}
        <div
          className="project-review__step-header-subtitle-atom project-review__step-header-subtitle-atom--anonymous">
          Your
          review is completely anonymous
        </div>
      </div>
      {getSearch()}
    </div>
  );
};
