const constants = require('./constants');

class BacklogReducer {
  constructor(state) {
    this.state = {
      ...state,
      backlogs: [],
      productions: [],
      avgProductions: [],
      fullPlannedProductions: [],
      corePlannedProductions: [],
      avgRevenues: [],
      isLoadingBacklog: false,
      isLoadingProduction: false,
      isLoadingRevenues: false,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_BACKLOGS_REQUEST:
        return {
          ...state,
          isLoadingBacklog: true,
        };
      case constants.GET_BACKLOGS_SUCCESS:
        return {
          ...state,
          isLoadingBacklog: false,
          backlogs: action.items,
        };
      case constants.GET_PRODUCTIONS_REQUEST:
        return {
          ...state,
          isLoadingProduction: true,
        };
      case constants.GET_PRODUCTIONS_SUCCESS:
        return {
          ...state,
          isLoadingProduction: false,
          productions: action.items.production,
          avgProductions: action.items.avg_production,
          fullPlannedProductions: action.items.planned.full,
          corePlannedProductions: action.items.planned.core,
        };
      case constants.GET_AVG_REV_REQUEST:
        return {
          ...state,
          isLoadingRevenues: true,
        };
      case constants.GET_AVG_REV_SUCCESS:
        return {
          ...state,
          isLoadingRevenues: false,
          avgRevenues: action.items,
        };
      default:
        return state;
    }
  }
}

module.exports = BacklogReducer;
