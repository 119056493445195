const { connect } = require('react-redux');
const Component = require('../../../components/modals/Bio/BioBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  contactInfo: {
    email: state.email,
    telephone: state.person.telephone,
    linkedin: state.person.linkedin,
    gender: state.person.gender,
    birthday: state.person.birthday,
  },
  id: state.person.id,
  bio: state.person.bio,
  waiting: state.waiting,
});

const mapDispatchToProps = (dispatch) => ({
  editInfo: (info) => dispatch(actions.editInfo(info)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
