const React = require('react');
const BudgetFooterButton = require('../BudgetFooterButton.react');
const BaselineIcon = require('./BaselineIcon.react');

const BaselineAction = ({ showModalBaseline, editBaseline, projectIsFrozen = false }) => {
  if (editBaseline) {
    return (
      <BudgetFooterButton
        classNameButton="wethod-budget-baseline__icon"
        label="Baseline"
        onClick={showModalBaseline}
        noBorder
        disabled={projectIsFrozen}>
        <BaselineIcon />
      </BudgetFooterButton>
    );
  }
  return null;
};

module.exports = BaselineAction;
