const { connect } = require('react-redux');
const Component = require('../../components/Kudos/Filters.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  year: state.kudos.year,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  getKudos: (employeeId, year) => dispatch(actions.getKudos(employeeId, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
