const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../Button/RoundedButton.react');

const ModalFooterAction = ({ children, onClick, disabled }) => (
  <Button onClick={onClick} disabled={disabled} border className="wethod-modal__footer-action">{children}</Button>
);

ModalFooterAction.defaultProps = {
  disabled: false,
};

ModalFooterAction.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

module.exports = ModalFooterAction;
