const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const Footer = require('../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const Select = require('../../../../../../common/react/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('../../../../../../common/react/inputs/Select/SelectItemColorful.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');
const Tooltip = require('../../../../../../common/react/TooltipFixed/Tooltip.react');
const status = require('../../segmentsStatus');

class EditStatusModal extends React.Component {
  static getTooltipLabel(optionStatus) {
    if (optionStatus !== status.PAUSED && optionStatus !== status.FROZEN) {
      return null;
    }
    const title = optionStatus === status.PAUSED ? 'Can’t pause data edit' : 'Can’t freeze data';
    return (
      <span>
        <Typography size={Typography.SIZES.PX11} weight={Typography.WEIGHTS.SEMIBOLD}>
          {title}
        </Typography>
        <br />
        Review and resolve mandatory warnings below<br />
        before applying changes to data edit status.
      </span>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: this.props.segment.status,
    };

    this.AVALIABLE_STATUSES = EditStatusModal.AVAILABLE_STATUSES(this.props.segments);
  }

  handleStatusChange(e) {
    this.setState({
      selectedStatus: e.target.value,
    });
  }

  handleSave() {
    const isStatusEqual = this.state.selectedStatus === this.props.segment.status;
    if (isStatusEqual) {
      this.props.closeModal();
      return;
    }
    if (this.isSaveDisabled()) {
      return;
    }

    switch (this.state.selectedStatus) {
      case status.PAUSED:
        this.handlePauseSave();
        break;
      case status.FROZEN:
        this.handleFreezeSave();
        break;
      case status.IN_REVIEW:
        this.handleStatusSave();
        break;
      case status.FREE:
        this.handleStatusSave();
        break;
      default:
        break;
    }
  }

  handlePauseSave() {
    // Remove segment with old status
    const updatedSegment = {
      status: this.props.segment.status,
      from: null,
      to: null,
    };
    // Add new segment with selected status
    const newSegment = {
      status: status.PAUSED,
      from: this.props.segment.from,
      to: this.props.segment.to,
    };
    const segments = [updatedSegment, newSegment];

    if (this.hasAlerts()) {
      this.props.showIgnoreAlertsModal(segments);
    } else {
      this.showConfirmPauseModal(segments);
    }
  }

  handleFreezeSave() {
    // Remove segment with old status
    const updatedSegment = {
      status: this.props.segment.status,
      from: null,
      to: null,
    };
    // Add new segment with selected status
    const newSegment = {
      status: status.FROZEN,
      from: this.props.segment.from,
      to: this.props.segment.to,
    };
    const segments = [updatedSegment, newSegment];

    this.props.showConfirmFreezeModal(segments);
  }

  /**
   * Remove segment with old status
   * Segment with new status will automatically be added
   */
  handleStatusSave() {
    const updatedSegment = {
      status: this.props.segment.status,
      from: null,
      to: null,
    };
    this.props.save([updatedSegment]);
  }

  handleChange(e) {
    this.setState({
      selectedStatus: e.target.value,
    });
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : null;
  }

  /**
   * Return options for select based on current segment status
   * If segment has warnings, disable option and show tooltip for PAUSED and FROZEN statuses
   * @return {Array}
   */
  getOptions() {
    const hasWarnings = this.hasWarnings();
    return this.AVALIABLE_STATUSES[this.props.segment.status].map((key) => {
      const isEqual = key === this.props.segment.status;
      const tooltipLabel = EditStatusModal.getTooltipLabel(key);
      const needsTooltip = !isEqual && hasWarnings && tooltipLabel;
      if (needsTooltip) {
        return (
          <Tooltip label={tooltipLabel} key={key}>
            <SelectItem value={key} color={status.COLORS[key]} disabled>
              {EditStatusModal.LABELS[key]}
            </SelectItem>
          </Tooltip>
        );
      }
      return (
        <SelectItem key={key} value={key} color={status.COLORS[key]}>
          {EditStatusModal.LABELS[key]}
        </SelectItem>
      );
    });
  }

  /**
   * Check if segment has warnings based on totals
   * Alerts are not considered mandatory warnings
   * @returns {*}
   */
  hasWarnings() {
    return this.props.totals
      ? Object.keys(this.props.totals).some((key) => key !== 'alerts' && this.props.totals[key] > 0)
      : false;
  }

  /**
   * Check if segment has alerts based on totals
   * @returns {*|boolean}
   */
  hasAlerts() {
    return this.props.totals && this.props.totals.alerts > 0;
  }

  isSaveDisabled() {
    return !this.state.selectedStatus || this.props.isSaving;
  }

  render() {
    return (
      <Modal title="Data editing preferences" onClose={this.props.closeModal}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              Apply data editing preferences on segment.
            </ModalBodyBlock>
            <ModalBodyBlock>
              <Select value={this.state.selectedStatus} color={status.COLORS[this.state.selectedStatus]} name="segment-status" onChange={this.handleChange.bind(this)}>
                {this.getOptions()}
              </Select>
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal}>
              Dismiss
            </FooterAction>
            <FooterAction onClick={this.handleSave.bind(this)}
              disabled={this.isSaveDisabled()}>
              Confirm
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
}

EditStatusModal.LABELS = {
  [status.FREE]: 'Free',
  [status.IN_REVIEW]: 'Data review',
  [status.PAUSED]: 'Pause data edit',
  [status.FROZEN]: 'Permanently freeze data',
};

EditStatusModal.AVAILABLE_STATUSES = (segments) => ({
  [status.FREE]: [
    status.FREE,
    status.IN_REVIEW,
  ],
  [status.IN_REVIEW]: [
    status.FREE,
    status.IN_REVIEW,
    status.PAUSED,
  ],
  [status.PAUSED]: [
    segments && segments.some((segment) => segment.status === status.IN_REVIEW)
      ? status.IN_REVIEW
      : status.FREE,
    status.PAUSED,
    status.FROZEN,
  ],
  [status.FROZEN]: [
    status.FROZEN,
  ],
});

module.exports = EditStatusModal;
