'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ProjectStatusStore = require('../../apps/core/modules/friday/project-status/store');
var ProjectStatusReducer = require('../../apps/core/modules/friday/project-status/reducer');
var ProjectStatus = require('../../apps/core/modules/friday/project-status/index');
var FeatureService = require('../../services/FeatureService');

Wethod.module('FridayApp', function (FridayApp, Wethod, Backbone, Marionette, $, _) {
  // Check permissions and show selected module
  FridayApp.Controller = {

    showTimesheet: function (id, date, idTour) {
      var permissionRequest = Wethod.request('get:friday:authorization', { app: 'time_tracking' });
      $.when(permissionRequest).done(function (permission) {
        Wethod.FridayApp.authorization = permission;
        if (_.isUndefined(permission) || !Wethod.FridayApp.getPermission('view')
          || (id && !Wethod.FridayApp.getPermission('edit_other'))) { // View another user's page requires permission to edit it
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        FridayApp.Timesheet.Init(new FridayApp.Layout(), id, date, idTour);
      });
    },

    showProjectStatus: function (idTour, id) {
      var permissionRequest = Wethod.request('get:friday:authorization', { app: 'project_status' });
      $.when(permissionRequest).done(function (permission) {
        // TODO remove on DATA_FREEZING contract
        Wethod.FridayApp.authorization = permission;
        if (_.isUndefined(permission) || !Wethod.FridayApp.getPermission('view')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        } else {
          var risksRequest = Wethod.request('get:company:project-status:risks');
          $.when(risksRequest).done(function (risksResponse) {
            var fridayLayout = new FridayApp.Layout();

            var projectId = Number.parseInt(id) ? Number.parseInt(id) : null;

            fridayLayout.render();

            var initialState = {
              projectFilterId: projectId,
              risks: risksResponse.toJSON(),
              idTour: idTour,
              isEnabledDataFreezingFeature: Wethod.featureService
                .isEnabled(FeatureService.DATA_FREEZING_PRODUCTION),
            };

            if (Wethod.featureService.isEnabled(FeatureService.DATA_FREEZING_PRODUCTION)) {
              initialState.permissions = permission.attributes;
              initialState.employeeId = Wethod.userInfo.attributes.employee_id;
            }

            var reducerWrapper = new ProjectStatusReducer(initialState);

            var store = ProjectStatusStore(reducerWrapper.reduxReducer);
            var element = React.createElement(ProjectStatus, { store: store });
            var container = document.getElementById('bodyRoot');
            if (container !== null) ReactDOM.render(element, container);
          });
        }
      });
    },
  };
});
