const { connect } = require('react-redux');
const Component = require('../../../components/modals/Objective/ObjectiveBody.react');
const actions = require('../../../actions');

/**
 * Find in quarters the objective with the given id in the given quarter.
 * If no id is given, we assume it's because the objective is being created and we are waiting for the API response.
 * In this case no objective is returned.
 * @param id
 * @param quarterId
 * @param quarters
 * @param year
 * @returns {*}
 */
// eslint-disable-next-line consistent-return
const getObjective = (id, quarterId, quarters) => {
  if (id !== null && id !== undefined) {
    for (let i = 0; i < quarters.length; i++) {
      if (quarters[i].quarter === quarterId) {
        const { objectives } = quarters[i];
        for (let j = 0; j < objectives.length; j++) {
          if (objectives[j].id === id) {
            return {
              ...objectives[j],
              quarterId,
            };
          }
        }
      }
    }
    return null;
  }
};

const mapStateToProps = (state) => ({
  objective: getObjective(state.focusedObjective.id, state.focusedObjective.quarterId,
    state.objectivesByQuarter.quarters, state.objectivesByQuarter.year),
  waiting: state.waiting,
  creating: state.creating,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
  addKeyResult: (keyResult, quarterId, objectiveId) => dispatch(actions
    .addKeyResult(keyResult, quarterId, objectiveId)),
  editObjective: (objective, quarterId, employeeId) => dispatch(actions
    .editObjective(objective, quarterId, employeeId)),
  deleteObjective: (objective, quarterId) => dispatch(actions
    .deleteObjective(objective, quarterId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
