'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  this.getPermission = function (label) {
    switch (label) {
      case 'all':
        var viewPermission = [];
        _.each(DashboardApp.appPermission.attributes, function (val) {
          if (!val) {
            viewPermission.push(false);
          }
        });

        if (viewPermission.length === _.map(DashboardApp.appPermission.attributes).length) {
          return false;
        }
        return true;
      default:
        if (!_.isUndefined(DashboardApp.appPermission.get(label))) {
          return DashboardApp.appPermission.get(label);
        }
        return false;
    }
  };
});
