/* eslint-disable react/sort-comp,class-methods-use-this,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const SingleValueSlider = require('../SingleValueSlider.react');
const Menu = require('../Menu/Menu.react');
const MonthPicker = require('../MonthPicker.react');

/**
 * A month selector component with slider to select the next/previous and yearly calendar to pick a
 * month.
 */
class MonthSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  formatDate(date) {
    return moment(date).format('MMM YYYY');
  }

  handleMenuClose() {
    this.setState({
      showMenu: false,
    });
  }

  handleDateClick(date) {
    if (!this.props.isWaiting) {
      const momentDate = moment(date);
      if (!momentDate.isSame(this.props.date, 'month')) this.props.changeDate(momentDate);
      this.handleMenuClose();
    }
  }

  handleCalendarButtonClick() {
    if (!this.props.isWaiting) {
      this.setState({
        showMenu: true,
      });
    }
  }

  handlePreviousClick() {
    if (!this.props.isWaiting) {
      const prev = moment(this.props.date).subtract(1, 'months');
      this.props.changeDate(prev);
    }
  }

  handleNextClick() {
    if (!this.props.isWaiting) {
      const next = moment(this.props.date).add(1, 'months');
      this.props.changeDate(next);
    }
  }

  getStyleClass() {
    let style = 'date-selector';
    if (this.props.isWaiting) {
      style += ' disabled';
    }
    return style;
  }

  getMenuBody() {
    return (
      <MonthPicker date={this.props.date}
        onMonthClick={this.handleDateClick.bind(this)} />
    );
  }

  getCalendarButton() {
    return (
      <div className="date-selector__calendar-button"
        ref={(el) => this.calendarButton = el}>
        <div className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        <span>{this.formatDate(this.props.date)}</span>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SingleValueSlider className={this.getStyleClass()}
          value={this.getCalendarButton()}
          onTodayClicked={this.handleCalendarButtonClick.bind(this)}
          onPrevClicked={this.handlePreviousClick.bind(this)}
          onNextClicked={this.handleNextClick.bind(this)} />
        <Menu open={this.state.showMenu}
          onClose={this.handleMenuClose.bind(this)}
          anchorEl={this.calendarButton}>
          {this.getMenuBody()}
        </Menu>
      </div>
    );
  }
}

MonthSelector.propTypes = {
  /**
   * The selected date shown: given a date, the entire belonging month will be selected.
   */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]).isRequired,

  /**
   * If set to true, the selector will be disabled and neither the slider nor the calendar will be
   * working.
   */
  isWaiting: PropTypes.bool,

  /**
   * Function called when a month (different from the given one) is selected, either with the
   * slider or the calendar. The slider always move one month at the time, keeping the day of the
   * given month. The calendar always give the first day of the selected month.
   */
  changeDate: PropTypes.func.isRequired,
};

module.exports = MonthSelector;
