module.exports.GET_PRICELISTS_REQUEST = 'GET_PRICELISTS_REQUEST';
module.exports.GET_PRICELISTS_SUCCESS = 'GET_PRICELISTS_SUCCESS';

module.exports.GET_LEVELS_REQUEST = 'GET_LEVELS_REQUEST';
module.exports.GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';

module.exports.UPDATE_PRICELIST_REQUEST = 'UPDATE_PRICELIST_REQUEST';
module.exports.UPDATE_PRICELIST_SUCCESS = 'UPDATE_PRICELIST_SUCCESS';
module.exports.UPDATE_PRICELIST_ERROR = 'UPDATE_PRICELIST_ERROR';

module.exports.CREATE_PRICELIST_REQUEST = 'CREATE_PRICELIST_REQUEST';
module.exports.CREATE_PRICELIST_SUCCESS = 'CREATE_PRICELIST_SUCCESS';

module.exports.AVAILABILITY_CHANGE = 'AVAILABILITY_CHANGE';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_API_ERROR_MODAL = 'SHOW_API_ERROR_MODAL';

module.exports.CHANGE_SIDEBAR_EDIT_MODE = 'CHANGE_SIDEBAR_EDIT_MODE';

module.exports.CHANGE_UNSAVED_CHANGES = 'CHANGE_UNSAVED_CHANGES';

module.exports.REFRESH_PRICELISTS_LIST = 'REFRESH_PRICELISTS_LIST';
