const React = require('react');
const ForProjects = require('../containers/ForProjects');
const ForOpportunities = require('../containers/ForOpportunities');
const OpportunitiesSectionHeader = require('./opportunities/SectionHeader.react');
const ProjectsSectionHeader = require('./projects/SectionHeader.react');

const SectionHeader = () => (
  <div>
    <ForProjects><ProjectsSectionHeader /></ForProjects>
    <ForOpportunities><OpportunitiesSectionHeader /></ForOpportunities>
  </div>
);

module.exports = SectionHeader;
