const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({ worked }) => (
  <Paragraph>
    We worked a total of <NV value={worked} /> <NS quantity={worked}>hours</NS> last week.
  </Paragraph>
);
