/* eslint-disable class-methods-use-this */
const React = require('react');
const Loading = require('../TextArea/Loading.react');

module.exports = class Kudos extends React.Component {
  componentDidMount() {
    this.props.getKudos(this.props.projectId);
  }

  getFormattedValue(value) {
    return value || '-';
  }

  getFooter() {
    return (
      <div className="project-canvas__widget--kudos-footer">
        <div>
          <span>Nr. of reviews received</span>
          <span>{this.getFormattedValue(this.props.totalReviews)}</span>
        </div>
        <div>
          <span>Sessions</span>
          <span>{this.getFormattedValue(this.props.totalSessions)}</span>
        </div>
      </div>
    );
  }

  getKudos() {
    return (
      this.props.kudos.map((criterion) => (
        <div key={criterion.id} className="project-canvas__widget--kudos-criteria">
          <span>{criterion.name}</span>
          <span>{this.getFormattedValue(criterion.avg_score)}</span>
        </div>
      ))
    );
  }

  getContent() {
    if (this.props.isWaiting) {
      return (
        <div className="wethod-widget__body project-canvas__widget--kudos-body">
          <Loading />
        </div>
      );
    }
    return (
      <div className="wethod-widget__body project-canvas__widget--kudos-body">
        <div className="project-canvas__widget--kudos-subheader">
          average based on NPS
        </div>
        <div className="project-canvas__widget--kudos-list">
          {this.getKudos()}
        </div>
        {this.getFooter()}
      </div>
    );
  }

  render() {
    return (
      <div className="wethod-widget project-canvas__widget project-canvas__widget--kudos">
        <div className="wethod-widget__header project-canvas__widget">
          <span className="wethod-widget__title">Kudos</span>
        </div>
        {this.getContent()}
      </div>
    );
  }
};
