/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class TrendEmpty extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty">
        <span className="wethod-widget__empty-message">Not enough reviews yet</span>
      </div>
    );
  }
};
