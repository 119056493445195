/* eslint-disable react/prop-types,jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class Hamburger extends React.Component {
  onClick(e) {
    e.preventDefault();
    this.props.onClick();
  }

  render() {
    return (
      <a href="#"
        className="mobileUserMenu table right relative userNav"
        onClick={this.onClick.bind(this)}>
        <div id="hamburger">
          <span />
          <span />
          <span />
          <span />
        </div>
      </a>
    );
  }
};
