const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');

module.exports = class ChargeabilityTrend extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      positive: '#4ED88D',
      negative: '#DB4D69',
    };

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      chart: {
        type: 'spline',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Open Sans',
        },
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        padding: 5,
        style: {
          fontSize: '10px',
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        title: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      xAxis: {
        visible: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
            fillColor: null, // inherit from series
            lineWidth: 1,
            lineColor: null, // inherit from series
            symbol: 'circle',
          },
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.selectedCriterion;
    const currentId = this.props.selectedCriterion;
    if (prevId !== currentId) {
      this.renderChart();
    }
  }

  getChartOptions() {
    const categories = this.getCategories();
    return {
      ...this.options,
      series: this.getSeries(categories),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1, // last category index, to force empty category to be shown anyway
        min: 0,
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries() {
    const { data } = this.props;
    const series = this.getKudos(data);

    return [series];
  }

  getKudos(data) {
    const series = data.map((period) => {
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(period.date);
      const value = Math.round(period.value * 10) / 10;
      return { ...period, x: index, y: value };
    });

    const lastPointIndex = series.length ? series.length - 1 : null;

    if (lastPointIndex != null) {
      // Add marker to last point
      series[lastPointIndex] = {
        ...series[lastPointIndex],
        marker: { enabled: true },
      };
    }

    return {
      name: 'Kudos',
      data: series,
      color: this.getSeriesColor(series[0], series[lastPointIndex]),
      zIndex: 1,
    };
  }

  getSeriesColor(startingPoint, finalPoint) {
    let color = this.colors.positive;
    if (startingPoint && finalPoint) {
      if (finalPoint.y < startingPoint.y) {
        color = this.colors.negative;
      }
    }
    return color;
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="chargeability__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
