const constants = require('./constants');

class ProjectCanvasReducer {
  constructor(state) {
    this.state = {
      ...state,
      kudos: [],
      brief: {
        summary: '',
        description: '',
      },
      concept: {
        summary: '',
        description: '',
      },
      goals: [],
      waitingFor: [], // contains a key for each pending request
      modal: null, // key representing the modal to show
      focusedGoalId: null, // goal to be shown in the modal
      boards: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_BRIEF_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('brief'),
        };
      }
      case constants.GET_BRIEF_SUCCESS: {
        return {
          ...state,
          brief: action.brief,
          waitingFor: state.waitingFor.filter((key) => key !== 'brief'),
        };
      }
      case constants.GET_CONCEPT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('concept'),
        };
      }
      case constants.GET_CONCEPT_SUCCESS: {
        return {
          ...state,
          concept: action.concept,
          waitingFor: state.waitingFor.filter((key) => key !== 'concept'),
        };
      }
      case constants.GET_GOALS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('goals'),
        };
      }
      case constants.GET_GOALS_SUCCESS: {
        return {
          ...state,
          goals: action.goals,
          waitingFor: state.waitingFor.filter((key) => key !== 'goals'),
        };
      }
      case constants.MODAL_CLOSE: {
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      }
      case constants.SAVE_BRIEF_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-brief'),
        };
      }
      case constants.SAVE_BRIEF_SUCCESS: {
        return {
          ...state,
          brief: action.brief,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-brief'),
        };
      }
      case constants.MODAL_SHOW_BRIEF: {
        return {
          ...state,
          modal: 'brief',
        };
      }
      case constants.MODAL_SHOW_DELETE: {
        return {
          ...state,
          modal: `confirm-delete-${action.element}`,
          modalData: action.data,
        };
      }
      case constants.MODAL_MISSING_STATUS_FOR_KANBAN: {
        return {
          ...state,
          modal: 'missing-status-for-kanban',
        };
      }
      case constants.MODAL_CANNOT_UPLOAD_FILE: {
        return {
          ...state,
          modal: 'cannot-upload-file',
          modalData: action.data,
          waitingFor: state.waitingFor.filter((key) => key !== 'upload-file'),
        };
      }
      case constants.MODAL_CANNOT_DOWNLOAD_FILE: {
        return {
          ...state,
          modal: 'cannot-download-file',
          modalData: action.data,
          files: state.files.filter((file) => file.id !== action.fileId),
          waitingFor: state.waitingFor.filter((key) => key !== 'download-file'),
        };
      }
      case constants.MODAL_SERVER_ERROR: {
        return {
          ...state,
          modal: 'cannot-download-file',
          modalData: action.data,
          waitingFor: state.waitingFor.filter((key) => key !== 'download-file'),
        };
      }
      case constants.MODAL_REQUEST_REVIEW: {
        return {
          ...state,
          modal: 'request-review',
          modalData: action.data,
        };
      }
      case constants.MODAL_START_REVIEW: {
        return {
          ...state,
          modal: 'start-review',
          modalData: action.data,
        };
      }
      case constants.SAVE_CONCEPT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-concept'),
        };
      }
      case constants.SAVE_CONCEPT_SUCCESS: {
        return {
          ...state,
          concept: action.concept,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-concept'),
        };
      }
      case constants.MODAL_SHOW_CONCEPT: {
        return {
          ...state,
          modal: 'concept',
        };
      }
      case constants.UPDATE_GOAL: {
        const updatedWaiting = state.waitingFor.indexOf('save-goal') === -1
          ? state.waitingFor.concat('save-goal') : state.waitingFor;
        return {
          ...state,
          goals: state.goals.map((goal) => (goal.id === action.goal.id ? action.goal : goal)),
          waitingFor: updatedWaiting,
          modal: null,
          focusedGoalId: null,
        };
      }
      case constants.SAVE_GOAL_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-goal'),
        };
      }
      case constants.SAVE_GOAL_SUCCESS: {
        return {
          ...state,
          goals: state.goals.map((goal) => (goal.id === action.goal.id ? action.goal : goal)),
          waitingFor: state.waitingFor.filter((key) => key !== 'save-goal'),
          modal: null,
          focusedGoalId: null,
        };
      }
      case constants.CREATE_GOAL_SUCCESS: {
        return {
          ...state,
          goals: state.goals.concat(action.goal),
          waitingFor: state.waitingFor.filter((key) => key !== 'save-goal'),
          modal: null,
          focusedGoalId: null,
        };
      }
      case constants.DELETE_GOAL_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-goal'),
        };
      }
      case constants.DELETE_GOAL_SUCCESS: {
        return {
          ...state,
          goals: state.goals.filter((goal) => goal.id !== action.goal.id),
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-goal'),
          modal: null,
          focusedGoalId: null,
        };
      }
      case constants.MODAL_SHOW_GOAL: {
        return {
          ...state,
          modal: 'goal',
          focusedGoalId: action.goalId,
        };
      }
      case constants.GET_BOARDS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('boards'),
        };
      }
      case constants.GET_BOARDS_SUCCESS: {
        return {
          ...state,
          boards: action.boards.map((board) => ({
            ...board,
            items: [],
            options: null,
          })), // boards are sorted
          waitingFor: state.waitingFor.filter((key) => key !== 'boards'),
        };
      }
      case constants.ADD_BOARD_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('new-board'),
        };
      }
      case constants.ADD_BOARD_SUCCESS: {
        return {
          ...state,
          boards: state.boards.concat({
            ...action.board,
            items: [],
            options: null,
          }),
          waitingFor: state.waitingFor.filter((key) => key !== 'new-board'),
        };
      }
      case constants.DELETE_BOARD_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-board-${action.boardId}`),
        };
      }
      case constants.DELETE_BOARD_SUCCESS: {
        return {
          ...state,
          modal: null,
          boards: state.boards.filter((board) => board.id !== action.boardId)
            .map((board, index) => ({
              ...board,
              sort: index,
            }))
            .sort((a, b) => a.sort - b.sort),
          waitingFor: state.waitingFor.filter((key) => key !== `delete-board-${action.boardId}`),
        };
      }
      case constants.ADD_BOARD_ITEM_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`add-board-${action.boardId}-item`),
        };
      }
      case constants.GET_BOARD_CONTENT_SUCCESS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                ...action.content,
              }; // items, structure, cells are sorted
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `get-board-items-${action.boardId}`),
        };
      }
      case constants.GET_BOARD_CONTENT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`get-board-items-${action.boardId}`),
        };
      }
      case constants.ADD_BOARD_ITEM_SUCCESS: {
        const defaultAttributesMap = {};
        action.item.attributes
          .map((attribute) => defaultAttributesMap[attribute.id] = attribute.value);

        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.concat({
                  id: action.item.id,
                  name: action.item.name,
                  sort: action.item.sort,
                  kanban_sort: action.item.kanban_sort,
                  attributes: board.structure.map((attribute) => ({
                    id: `${action.item.id}${attribute.id}`,
                    item: action.item.id,
                    attribute: attribute.id,
                    value: defaultAttributesMap[attribute.id]
                      ? attribute.available_values
                        .filter((value) => value.id === defaultAttributesMap[attribute.id])[0]
                      : attribute.default_value,
                  })),
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `add-board-${action.boardId}-item`),
        };
      }
      case constants.ADD_BOARD_STRUCTURE_ATTRIBUTE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}`),
        };
      }
      case constants.ADD_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            const nextOptions = () => {
              let { options } = board;
              // If the added attribute is used as pivot and it has not ben set yet, use it
              if (board.options.kanban[action.attribute.type] === null) {
                options = {
                  ...board.options,
                  kanban: {
                    ...board.options.kanban,
                    [action.attribute.type]: action.attribute.id,
                  },
                };
              }
              if (action.attribute.type === 'member' || action.attribute.type === 'status') {
                // If the added attribute is used as activity column and it has not ben set yet, use it
                if (!board.options.spreadsheet) {
                  options = {
                    ...options,
                    spreadsheet: {
                      [action.attribute.type]: action.attribute.id,
                    },
                  };
                } else if (!board.options.spreadsheet[action.attribute.type]) {
                  options = {
                    ...options,
                    spreadsheet: {
                      ...board.options.spreadsheet,
                      [action.attribute.type]: action.attribute.id,
                    },
                  };
                }
              }
              return options;
            };
            if (board.id === action.boardId) {
              return {
                ...board,
                options: nextOptions(),
                structure: board.structure.concat(action.attribute),
                items: board.items.map((item) => ({
                  ...item,
                  attributes: item.attributes.concat({
                    id: `${item.id}${action.attribute.id}`,
                    item: item.id,
                    attribute: action.attribute.id,
                    value: action.attribute.default_value,
                  }),
                })),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}`),
        };
      }
      case constants.EDIT_BOARD_NAME_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}-name`),
        };
      }
      case constants.EDIT_BOARD_NAME_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                name: action.name,
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-name`),
        };
      }
      case constants.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}-structure-attribute-${action.attributeId}`),
        };
      }
      case constants.DELETE_BOARD_ITEM_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-board-${action.boardId}-item-${action.itemId}`),
        };
      }
      case constants.DELETE_BOARD_ITEM_SUCCESS: {
        return {
          ...state,
          modal: null,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.filter((item) => item.id !== action.itemId)
                  .map((item, index) => ({
                    ...item,
                    sort: index,
                  }))
                  .sort((a, b) => a.sort - b.sort),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `delete-board-${action.boardId}-item-${action.itemId}`),
        };
      }
      case constants.DELETE_BOARD_STRUCTURE_ATTRIBUTE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-board-${action.boardId}-structure-attribute-${action.attributeId}`),
        };
      }
      case constants.DELETE_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS: {
        return {
          ...state,
          modal: null,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              const nextStructure = board.structure
                .filter((attribute) => attribute.id !== action.attributeId);
              const deletedAttribute = board.structure
                .filter((attribute) => attribute.id === action.attributeId)[0];
              const nextOptions = () => {
                let { options } = board;
                // If deleting the attribute used as pivot for kanban, update boardOptions
                if (board.options.kanban[deletedAttribute.type]
                  && board.options.kanban[deletedAttribute.type] === action.attributeId) {
                  // Find the next attribute with the same type as the deleting one
                  const sameTypeAttributes = nextStructure
                    .filter((attribute) => attribute.type === deletedAttribute.type);
                  // If exists, use it as pivot
                  const nextPivot = sameTypeAttributes.length ? sameTypeAttributes[0].id : null;
                  options = {
                    ...board.options,
                    kanban: {
                      ...board.options.kanban,
                      [deletedAttribute.type]: nextPivot,
                    },
                  };
                }
                if (deletedAttribute.type === 'member' || deletedAttribute.type === 'status') {
                  // If the added attribute is used as activity column and it has not ben set yet, use it
                  if (board.options.spreadsheet
                    && board.options.spreadsheet[deletedAttribute.type]) {
                    // Find the next attribute with the same type as the deleting one
                    const sameTypeAttributes = nextStructure
                      .filter((attribute) => attribute.type === deletedAttribute.type);
                    // If exists, use it as pivot
                    const nextActivityAttribute = sameTypeAttributes.length
                      ? sameTypeAttributes[0].id : null;
                    options = {
                      ...options,
                      spreadsheet: {
                        ...board.options.spreadsheet,
                        [deletedAttribute.type]: nextActivityAttribute,
                      },
                    };
                  }
                }
                return options;
              };
              return {
                ...board,
                options: nextOptions(),
                items: board.items.map((item) => ({
                  ...item,
                  attributes: item.attributes
                    .filter((attribute) => attribute.attribute !== action.attributeId),
                })),
                structure: nextStructure,
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor
            .filter((key) => key !== `delete-board-${action.boardId}-structure-attribute-${action.attributeId}`),
        };
      }
      case constants.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      name: action.name,
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-structure-attribute-${action.attributeId}`),
        };
      }
      case constants.EDIT_BOARD_ITEM_NAME_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}-item-${action.itemId}-name`),
        };
      }
      case constants.EDIT_BOARD_ITEM_NAME_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      name: action.name,
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-item-${action.itemId}-name`),
        };
      }
      case constants.EDIT_BOARD_ATTRIBUTE_VALUE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}`),
        };
      }
      case constants.EDIT_BOARD_ATTRIBUTE_VALUE_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes.map((attribute) => {
                        if (attribute.attribute === action.attributeId) {
                          return {
                            ...attribute,
                            value: action.value,
                          };
                        }
                        return attribute;
                      }),
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}`),
        };
      }
      case constants.EDIT_BOARD_ITEM_VALUES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `edit-board-${action.boardId}-item-${action.itemId}-values`),
        };
      }
      case constants.EDIT_BOARD_ITEM_VALUES_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes
                        .map((attribute, index) => ({ ...attribute, ...action.value[index] })),
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-item-${action.itemId}-values`),
        };
      }
      case constants.ADD_BOARD_ATTRIBUTE_MEMBER_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes.map((attribute) => {
                        if (attribute.attribute === action.attributeId) {
                          return {
                            ...attribute,
                            value: attribute.value === null ? [action.member]
                              : attribute.value.concat(action.member),
                          };
                        }
                        return attribute;
                      }),
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`edit-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}`),
        };
      }
      case constants.DELETE_STATUS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-status-${action.statusId}-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.DELETE_STATUS_SUCCESS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      available_values: attribute.available_values
                        .filter((status) => status.id !== action.statusId),
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `delete-status-${action.statusId}-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.UPDATE_STATUS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`update-status-${action.status.id}-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.UPDATE_STATUS_SUCCESS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      available_values: attribute.available_values.map((status) => {
                        if (status.id === action.status.id) {
                          return action.status;
                        }
                        return status;
                      }),
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `update-status-${action.status.id}-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.CREATE_STATUS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`create-status-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.CREATE_STATUS_SUCCESS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      available_values: attribute.available_values.concat(action.status),
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `create-status-board-${action.boardId}-attribute-${action.attributeId}`),
        };
      }
      case constants.UPDATE_BOARDS_SORT: {
        return {
          ...state,
          boards: [...action.boards],
        };
      }
      case constants.SAVE_BOARDS_SORT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('update-boards-sort'),
        };
      }
      case constants.SAVE_BOARDS_SORT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'update-boards-sort'),
        };
      }
      case constants.UPDATE_SPREADSHEET_ITEMS_SORT: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: [...action.items],
              };
            }
            return board;
          }),
        };
      }
      case constants.UPDATE_KANBAN_ITEMS_SORT: {
        const sortMap = {};
        action.items.map((item) => sortMap[item.id] = item.sort);

        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (sortMap[item.id] !== undefined) {
                    return {
                      ...item,
                      kanban_sort: sortMap[item.id],
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
        };
      }
      case constants.UPDATE_KANBAN_ITEM_LIST: {
        const sortMap = {};
        action.sortedItems.map((item) => sortMap[item.id] = item.sort);

        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => { // update list
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes.map((attribute) => {
                        if (attribute.attribute === action.attributeId) {
                          return {
                            ...attribute,
                            value: action.value,
                          };
                        }
                        return attribute;
                      }),
                    };
                  }
                  return item;
                }).map((item) => { // update kanban_sort
                  if (sortMap[item.id] !== undefined) {
                    return {
                      ...item,
                      kanban_sort: sortMap[item.id],
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
        };
      }
      case constants.SAVE_SPREADSHEET_ITEMS_SORT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`update-board-${action.boardId}-spreadsheet-items`),
        };
      }
      case constants.SAVE_SPREADSHEET_ITEMS_SORT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `update-board-${action.boardId}-spreadsheet-items`),
        };
      }
      case constants.UPDATE_SPREADSHEET_COLUMNS_SORT: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: [...action.structure],
                items: [...action.items],
              };
            }
            return board;
          }),
        };
      }
      case constants.SAVE_SPREADSHEET_COLUMNS_SORT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`update-board-${action.boardId}-spreadsheet-columns`),
        };
      }
      case constants.SAVE_SPREADSHEET_COLUMNS_SORT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `update-board-${action.boardId}-spreadsheet-columns`),
        };
      }
      case constants.GET_PEOPLE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-people-list'),
        };
      }
      case constants.GET_PEOPLE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-people-list'),
          people: action.people,
        };
      }
      case constants.DELETE_ATTRIBUTE_MEMBER_VALUE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(
            `delete-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}-member`,
          ),
        };
      }
      case constants.DELETE_ATTRIBUTE_MEMBER_VALUE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}-member`),
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes.map((attribute) => {
                        if (attribute.attribute === action.attributeId) {
                          return {
                            ...attribute,
                            value: attribute.value === null
                              ? attribute.value
                              : attribute.value.filter((member) => member.id !== action.memberId),
                          };
                        }
                        return attribute;
                      }),
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
        };
      }
      case constants.DELETE_ATTRIBUTE_MILESTONE_VALUE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(
            `delete-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}-milestone`,
          ),
        };
      }
      case constants.DELETE_ATTRIBUTE_MILESTONE_VALUE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-board-${action.boardId}-item-${action.itemId}-attribute-${action.attributeId}-milestone`),
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                items: board.items.map((item) => {
                  if (item.id === action.itemId) {
                    return {
                      ...item,
                      attributes: item.attributes.map((attribute) => {
                        if (attribute.attribute === action.attributeId) {
                          return {
                            ...attribute,
                            value: null,
                          };
                        }
                        return attribute;
                      }),
                    };
                  }
                  return item;
                }),
              };
            }
            return board;
          }),
        };
      }
      case constants.SAVE_SPREADSHEET_COLUMN_SUMMARY_REQUEST: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      summary: action.summary,
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
          waitingFor: state.waitingFor.concat(`update-board-${action.boardId}-attribute-${action.attributeId}-summary`),
        };
      }
      case constants.SAVE_SPREADSHEET_COLUMN_SUMMARY_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `update-board-${action.boardId}-attribute-${action.attributeId}-summary`),
        };
      }
      case constants.GET_EVENT_TYPES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-event-types-list'),
        };
      }
      case constants.GET_EVENT_TYPES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-event-types-list'),
          eventTypes: action.eventTypes,
        };
      }
      case constants.CHANGE_BOARD_TYPE: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                type: action.boardType,
              };
            }
            return board;
          }),
        };
      }
      case constants.UPDATE_STATUS_AVAILABLE_VALUES_SORT: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                structure: board.structure.map((attribute) => {
                  if (attribute.id === action.attributeId) {
                    return {
                      ...attribute,
                      available_values: action.values,
                    };
                  }
                  return attribute;
                }),
              };
            }
            return board;
          }),
        };
      }
      case constants.SAVE_STATUS_AVAILABLE_VALUES_SORT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-board-${action.boardId}-status-${action.attributeId}-values-sort`),
        };
      }
      case constants.SAVE_STATUS_AVAILABLE_VALUES_SORT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-board-${action.boardId}-status-${action.attributeId}-values-sort`),
        };
      }
      case constants.UPDATE_KANBAN_OPTIONS: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                options: {
                  ...board.options,
                  kanban: action.options,
                },
              };
            }
            return board;
          }),
        };
      }
      case constants.UPDATE_ACTIVITY_ATTRIBUTE: {
        return {
          ...state,
          boards: state.boards.map((board) => {
            if (board.id === action.boardId) {
              return {
                ...board,
                options: {
                  ...board.options,
                  spreadsheet: action.options.spreadsheet,
                },
              };
            }
            return board;
          }),
        };
      }
      case constants.GET_KUDOS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('kudos'),
        };
      }
      case constants.GET_KUDOS_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'kudos'),
          kudos: action.kudos,
          totalReviews: action.reviews_count,
          totalSessions: action.sessions_count,
        };
      }
      case constants.GET_LAST_REVIEW_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('last-review'),
        };
      }
      case constants.GET_LAST_REVIEW_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'last-review'),
          lastReview: action.lastReview,
        };
      }
      case constants.CREATE_REVIEW_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('review-request'),
        };
      }
      case constants.CREATE_REVIEW_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'review-request'),
        };
      }
      default:
        return state;
    }
  }
}

module.exports = ProjectCanvasReducer;
