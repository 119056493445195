'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var PlanningPeopleStore = require('../../../apps/core/modules/planning/people/store');
var PlanningPeopleReducer = require('../../../apps/core/modules/planning/people/reducer');
var PlanningPeople = require('../../../apps/core/modules/planning/people/index');
var ProductionPlanStore = require('../../../apps/core/modules/planning/production-plan/store');
var ProductionPlanReducer = require('../../../apps/core/modules/planning/production-plan/reducer');
var ProductionPlan = require('../../../apps/core/modules/planning/production-plan/index');
var PlanningProjects = require('../../../apps/core/modules/planning/projects/PlanningProjects.react');
var AllocationRequestsStore = require('../../../apps/core/modules/planning/allocation-requests/store');
var AllocationRequestsReducer = require('../../../apps/core/modules/planning/allocation-requests/reducer');
var AllocationRequests = require('../../../apps/core/modules/planning/allocation-requests/index');
var HTTPService = require('../../../services/HTTPService');

Wethod.module('PlanningApp.Planning', function (Planning, Wethod, Backbone, Marionette, $, _) {
  // EXPORT
  this.showModalExport = function () {
    var modalExport = new Planning.ModalExportItemView();
    Planning.structureTemplate.modal.show(modalExport);
  };
  this.doModuleExport = function (viewObj) {
    Wethod.request('get:planning:export');
    viewObj.destroy();
  };

  Planning.Controller = {
    showPlanningPeople: function (query, idTour) {
      var planningAuthorizationRequest = Wethod.request('get:planning:authorization');
      var budgetAuthorizationRequest = Wethod.request('get:user:authorization');
      $.when(planningAuthorizationRequest, budgetAuthorizationRequest)
        .done(function (planningAuthorizationResponse, budgetAuthorizationResponse) {
          if (_.isUndefined(planningAuthorizationResponse)
            || _.isUndefined(budgetAuthorizationResponse)) {
            return;
          }

          Wethod.PlanningApp.authorization = planningAuthorizationResponse;
          Wethod.PlanningApp.budgetAuthorization = budgetAuthorizationResponse;
          if (Wethod.PlanningApp.getPermission('view')) {
            Planning.structureTemplate = new Planning.StructureLayoutView();
            Planning.structureTemplate.render();

            var filters = HTTPService.getQueryParams(query);
            var reducerWrapper = new PlanningPeopleReducer({
              projects: [],
              selected_project_id: filters.project_id,
              searchFilter: filters.search,
              idTour: idTour,
              planning_permissions: Wethod.PlanningApp.authorization.attributes,
            });

            var store = PlanningPeopleStore(reducerWrapper.reduxReducer);
            var element = React.createElement(PlanningPeople, { store: store });
            var container = document.getElementById('planningRoot');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          } else {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },

    showPlanningProjects: function () {
      var planningAuthorizationRequest = Wethod.request('get:planning:authorization');
      $.when(planningAuthorizationRequest).done(function (planningAuthorizationResponse) {
        if (_.isUndefined(planningAuthorizationResponse)) {
          return;
        }

        Wethod.PlanningApp.authorization = planningAuthorizationResponse;
        if (Wethod.PlanningApp.getPermission('view')) {
          Planning.structureTemplate = new Planning.ProjectsStructureLayoutView();
          Planning.structureTemplate.render();

          var loadingTemplate = new Planning.LoadingMoreDataTemplateView();
          Planning.structureTemplate.body.show(loadingTemplate);

          // move this inside planning
          var planningProjectsRequest = Wethod.request('get:planning:projects');
          var eventTypesRequest = Wethod.request('get:planning:event:types');

          $.when(planningProjectsRequest, eventTypesRequest)
            .done(function (planningProjectsResponse, eventTypesResponse) {
              var element = React.createElement(PlanningProjects, {
                projects: planningProjectsResponse.attributes,
                event_types: eventTypesResponse.toJSON(),
              });
              var container = document.getElementById('planningProjectsRoot');
              if (container !== null) {
                ReactDOM.render(element, container);
              }
            });
        } else {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },

    showProductionPlan: function (filter) {
      var planningAuthorizationRequest = Wethod.request('get:planning:authorization', 'production_plan');
      $.when(planningAuthorizationRequest).done(function (planningAuthorizationResponse) {
        if (_.isUndefined(planningAuthorizationResponse)) {
          return;
        }

        Wethod.PlanningApp.authorization = planningAuthorizationResponse;
        if (Wethod.PlanningApp.getPermission('view')) {
          Planning.structureTemplate = new Planning.ProductionPlan();
          Planning.structureTemplate.render();

          var loadingTemplate = new Planning.LoadingMoreDataTemplateView();
          Planning.structureTemplate.body.show(loadingTemplate);

          var reducerWrapper = new ProductionPlanReducer({
            year: moment().format('YYYY'),
            keyword: filter,
          });

          var store = ProductionPlanStore(reducerWrapper.reduxReducer);
          var element = React.createElement(ProductionPlan, { store: store });
          var container = document.getElementById('productionPlanRoot');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
        } else {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },

    showAllocationRequests: function (filter) {
      var authRequest = Wethod.request('get:planning:authorization', 'allocation_requests');
      var calendarPreferenceRequest = Wethod.request('get:company:planning:calendar');
      $.when(authRequest, calendarPreferenceRequest)
        .done(function (authResponse, calendarPreferenceResponse) {
          if (_.isUndefined(authResponse)) {
            return;
          }

          if (authResponse.get('view')) {
            Planning.structureTemplate = new Planning.StructureLayoutView();
            Planning.structureTemplate.render();
            var filterMap = HTTPService.getQueryParams(filter);

            var reducerWrapper = new AllocationRequestsReducer({
              permissions: authResponse.attributes,
              internationalCalendar: calendarPreferenceResponse.get('enabled'),
              highlightWeekends: calendarPreferenceResponse.get('highlight_weekend'),
              searchFilter: filterMap.id ? 'id:' + filterMap.id : '',
              ownerFilter: filterMap.ownership ? filterMap.ownership : 'mine',
            });

            var store = AllocationRequestsStore(reducerWrapper.reduxReducer);
            var element = React.createElement(AllocationRequests, { store: store });
            var container = document.getElementById('planningRoot');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          } else {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },
  };
});
