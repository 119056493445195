const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const Tag = require('../../../../../../common/react/Tag/Tag.react');
const EmployeeInfo = require('./EmployeeInfo');

module.exports = class EmployeeTagRow extends React.Component {
  constructor(props) {
    super(props);

    this.firstRowTop = null;
    this.tags = [];

    this.state = {
      countHidden: 0,
    };
  }

  componentDidMount() {
    this.countHiddenTags();
  }

  componentDidUpdate(prevProps) {
    const prevActiveTags = prevProps.activeTags ? prevProps.activeTags.length : 0;
    const activeTags = this.props.activeTags ? this.props.activeTags.length : 0;
    if (prevActiveTags !== activeTags) {
      this.countHiddenTags();
    }
  }

  handleClick() {
    this.props.onClick(this.props.employee.id);
  }

  getIconClass() {
    let style = 'wethod-icon wethod-icon-dropdown wethod-icon-dropdown--black';
    if (this.props.expanded) {
      style += ' wethod-icon-dropdown--expanded';
    }
    return style;
  }

  getDropdown() {
    if (this.state.countHidden) { // Hide dropdown when there is no need to expand the row
      return (
        <button type="button"
          onClick={this.handleClick.bind(this)}
          className="wethod-icon-button wethod-icon-button--no-border knowledge__button">
          <div className={this.getIconClass()} />
        </button>
      );
    }

    return null;
  }

  getRowClass() {
    let style = 'knowledge__employee-row';
    if (this.props.expanded) {
      style += ' knowledge__employee-row--expanded';
    }
    return style;
  }

  getCounter() {
    if (!this.props.expanded && this.state.countHidden) {
      return (
        <span key="counter" className="knowledge__tags-counter">
          <Tag id="counter">
            + {this.state.countHidden}
          </Tag>
        </span>
      );
    }
    return null;
  }

  getInactiveRow(inactive, offset) {
    const inactiveTags = inactive
      .map((tag, index) => (
        <span key={tag.id} ref={(el) => this.tags[index + offset] = el}>
          <Tag id={tag.id}
            disabled>
            {tag.name}
          </Tag>
        </span>
      ));

    return (
      <div key="inactive">
        {inactiveTags}
      </div>
    );
  }

  getActiveRow(active) {
    const activeTags = active
      .map((tag, index) => (
        <span key={tag.id} ref={(el) => this.tags[index] = el}>
          <Tag id={tag.id}>
            {tag.name}
          </Tag>
        </span>
      ));

    return (
      <div key="active">
        {activeTags}
      </div>
    );
  }

  getTagList() {
    const tagList = this.props.employee[this.props.tagType]
      ? this.props.employee[this.props.tagType]
      : null;
    if (tagList) {
      let active = [];
      const inactive = [];

      if (!this.props.activeTags || !this.props.activeTags.length) { // Filter 'all'
        active = tagList;
      } else { // Find which employee's tags are active filters
        tagList.forEach((tag) => {
          if (this.isTagActive(tag.id)) {
            active.push(tag);
          } else {
            inactive.push(tag);
          }
        });
      }

      return [this.getActiveRow(active)]
        .concat(this.getInactiveRow(inactive, active.length))
        .concat(this.getCounter());
    }
    return null;
  }

  /**
   * Count hidden tags: the tags which are not in the first row (where the first tag is) are hidden
   */
  countHiddenTags() {
    if (this.tags.length) {
      const firstRowTop = this.tags[0].getBoundingClientRect().top;
      let countHidden = 0;
      this.tags.forEach((tag) => {
        const tagTop = tag.getBoundingClientRect().top;
        if (tagTop > firstRowTop) { // The tag does not lie on first row
          countHidden += 1;
        }
      });

      if (countHidden !== this.state.countHidden) {
        this.setState({ countHidden });
      }
    }
  }

  isTagActive(tagId) {
    return this.props.activeTags.indexOf(tagId) >= 0;
  }

  render() {
    return (
      <TableRow className={this.getRowClass()}>
        <TableCell name="name" className="knowledge__column-employee knowledge__column-employee-tag">
          <EmployeeInfo employee={this.props.employee} />
        </TableCell>
        <TableCell name="tags" className="knowledge__column-emp-tag">
          {this.getTagList()}
        </TableCell>
        <TableCell name="dropdown" className="knowledge__column-link">
          {this.getDropdown()}
        </TableCell>
      </TableRow>
    );
  }
};
