/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const About = require('../containers/About');
const Resources = require('../containers/Resources/Resources');
const Projects = require('../containers/Projects/Projects');
const SectionHeader = require('../../../../../../../../common/react/SectionHeader/components/SectionHeader.react');

module.exports = class Person extends React.Component {
  render() {
    return (
      <div className="client-person wethod-section-body">
        <SectionHeader
          current_section="Contact Details"
          preview_anchor_id="Client Details"
          helper_url="finance/index/#clients"
          bookmark_fragment={`customer/${this.props.id}/details`} />
        <div className="client-person__info">
          <About />
        </div>
        <div className="client-person__links">
          <Projects />
          <Resources />
        </div>
      </div>
    );
  }
};
