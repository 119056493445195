module.exports = class ProductNode {
  /**
   * @param {{
   *   id: number,
   *   name: string,
   *   description: string,
   *   available_from: string|null,
   *   available_to: string|null,
   *   external_cost: number,
   *   markup: number,
   *   price: number|null,
   *   product_levels: {}[],
   * }} product
   */
  constructor(product) {
    this.product = product;
  }

  getExternalCost() {
    return this.product.external_cost;
  }

  /**
   * @return {number}
   */
  getMarkup() {
    return this.product.markup;
  }

  /**
   * Return amount of days for the given level in this product.
   * @param levelId
   * @return {number|null}
   */
  getDaysForLevel(levelId) {
    const found = this.product.product_levels.find((level) => level.level.id === levelId);

    return found ? found.days : null;
  }

  getPrice() {
    return this.product.price;
  }
};
