const { connect } = require('react-redux');
const Component = require('../../../components/modals/PlanDetails/PlanDetails.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  projectsInfo: state.planned_projects,
  zoom: state.zoom,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (projectId) => dispatch(actions.getProjectAvailability(projectId)),
  changeZoom: (zoom) => dispatch(actions.changeZoom(zoom)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
