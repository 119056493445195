/* eslint-disable consistent-return */
const React = require('react');
const Modal = require('../../../../../common/react/modal2/Modal.react');
const Loading = require('../../../../../common/react/LoadingSmall.react');
const { formatDate } = require('../../../../../services/FormatService');

module.exports = class RequestReviewModal extends React.Component {
  componentDidMount() {
    this.props.getLastReview(this.props.data.projectId);
  }

  handleReviewRequest() {
    this.props.requestReview(this.props.data.projectId);
  }

  getActions() {
    if (!this.props.isWaiting) {
      return (
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">No, thanks</button>
          <button
            type="button"
            onClick={this.handleReviewRequest.bind(this)}
            className="wethod-button wethod-button--confirm">
            Send request
          </button>
        </div>
      );
    }
  }

  getBody() {
    if (this.props.isWaiting) {
      return <Loading />;
    }
    const lastReviewData = this.props.lastReview
      ? (
        <span>
          <br />
          Last review was requested by
          {' '}
          {this.props.lastReview.created_by}
          {' '}
          on
          {' '}
          {formatDate(this.props.lastReview.created_at)}
          .
          <br />
          {this.props.lastReview.completed}
          {' '}
          on
          {' '}
          {this.props.lastReview.reviewers}
          {' '}
          members completed this review.
        </span>
      )
      : null;
    return (
      <div>
        <span>
          Do you want to <b>request a review of the team members on this project? </b>
        </span>
        <br />
        {lastReviewData}
      </div>
    );
  }

  getFeedback() {
    if (this.props.isSending) return <span className="wethod-modal2__feedback">Sending...</span>;
    return '';
  }

  render() {
    return (
      <Modal title="Project & team review" onCancelClick={this.props.onCancelClick} className="project-canvas-alert">
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
