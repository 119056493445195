const { connect } = require('react-redux');
const Component = require('../../components/modal/DelegateInvoiceModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'delegate').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onDelegate: (projectId, masterId) => dispatch(actions.delegateProject(projectId, masterId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
