const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/Capex.react');

const mapStateToProps = (state) => ({
  month: state.month,
  capex: state.month_capex,
  projectsCount: state.month_count_active_rd_projects,
  externalCostsCapex: state.month_external_cost_capex,
  payrollCapex: state.month_payroll_capex,
});

module.exports = connect(mapStateToProps)(Component);
