const React = require('react');

/**
 * Box used for grouping budget header info.
 *
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderBox = ({ children, className = '' }) => (
  <div className={`wethod-budget-header-box ${className}`}>{children}</div>
);

module.exports = HeaderBox;
