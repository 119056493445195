/**
 * @abstract
 * @type {StructureNodeTotal}
 */
module.exports = class StructureNodeTotal {
  /**
   * @param days
   * @param cost
   * @param price
   */
  constructor(days, cost, price) {
    this.days = days;
    this.cost = cost;
    this.price = price;
  }

  getDays() {
    return this.days;
  }

  getCost() {
    return this.cost;
  }

  getPrice() {
    return this.price;
  }

  /**
   * @abstract
   */
  toJson() {
    return {
      days: this.getDays(),
      cost: this.getCost(),
      price: this.getPrice(),
    };
  }
};
