const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/Title.react');

const mapStateToProps = (state) => ({
  month: state.month,
  target: state.monthly_revenue_target, // NULLABLE
  production: state.month_revenue,
  prevMonthProduction: state.prev_month_revenue,
});

module.exports = connect(mapStateToProps)(Component);
