/* eslint-disable class-methods-use-this,react/sort-comp,consistent-return,react/no-array-index-key */
const React = require('react');
const Event = require('../../containers/EventsFuture/Event');
const EventForm = require('../../containers/EventsFuture/EventForm');
const EventFollowupForm = require('../../containers/FollowUp/EventFormUpdate');
const FocusedEvent = require('../../containers/EventsFuture/FocusedEvent');

module.exports = class EventsFuture extends React.Component {
  /**
   * Returns true if the focusedEvent a future event.
   * @returns {boolean}
   */
  isFutureFocusedEvent() {
    return this.props.events
      .filter((event) => event.event.id === this.props.focusedEvent.id).length > 0;
  }

  getFocusedEvent() {
    if (this.props.focusedEvent && this.isFutureFocusedEvent()) {
      if (this.props.showEditEventForm) {
        if (this.props.focusedEvent.isFollowUp) {
          return (
            <EventFollowupForm
              {...this.props.focusedEvent}
              closeForm={this.props.closeForm}
              inputs={['datetime-picker', 'description']} />
          );
        }
        return (
          <EventForm
            {...this.props.focusedEvent}
            inputs={['type-select', 'datetime-picker', 'description']} />
        );
      }
      return <FocusedEvent event={this.props.focusedEvent} />;
    }
  }

  getEvents() {
    return this.props.events.map((event, index) => <Event key={index} {...event} />);
  }

  render() {
    return (
      <div className="timeline-events-future">
        <div className="timeline-events__title">Upcoming events</div>
        <div className="timeline-events__list">
          {this.getEvents()}
        </div>
        {this.getFocusedEvent()}
      </div>
    );
  }
};
