const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const PV = require('../../../../common/components/PercentageValue.react');

module.exports = ({ productionValue, trendOnForecast, trendOnPreviousMonth }) => {
  const getTargetComparator = () => {
    if (trendOnForecast > 0) {
      return (
        <span>
          <PV value={Math.abs(trendOnForecast)} />
          {' '}
          above
        </span>
      );
    }
    if (trendOnForecast < 0) {
      return (
        <span>
          <PV value={Math.abs(trendOnForecast)} />
          {' '}
          below
        </span>
      );
    }
    return <span>in line with</span>;
  };

  const getLastMonthComparator = () => {
    if (trendOnPreviousMonth > 0) {
      return (
        <span>
          growing by{' '}
          <PV value={Math.abs(trendOnPreviousMonth)} />
        </span>
      );
    }
    if (trendOnPreviousMonth < 0) {
      return (
        <span>
          decreasing by{' '}
          <PV value={Math.abs(trendOnPreviousMonth)} />
        </span>
      );
    }
    return <span>in line</span>;
  };

  return (
    <Paragraph>
      The total production value was
      {' '}
      <NV value={productionValue} />
      ,
      {' '}
      {getTargetComparator()}
      {' '}
      the production forecast,
      {' '}
      {getLastMonthComparator()}
      {' '}
      over the previous month.
    </Paragraph>
  );
};
