const { connect } = require('react-redux');
const Component = require('../../components/sidebar/AllocationRequestSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-allocation').length > 0,
  internationalCalendar: state.internationalCalendar,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (item) => dispatch(actions.showConfirmDeleteAllocationModal(item)),
  onClose: () => dispatch(actions.closeSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
