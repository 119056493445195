const React = require('react');
const PersonalApiToken = require('./PersonalApiToken/PersonalApiToken.react');

const DeveloperSettings = () => (
  <div className="spacedBox">
    <div className="header">
      <span className="cell">Developer settings</span>
    </div>
    <PersonalApiToken />
  </div>
);

module.exports = DeveloperSettings;
