/* eslint-disable react/no-array-index-key */
const React = require('react');
const Quarter = require('./OkrQuarter.react');

module.exports = class OkrStatus extends React.Component {
  getQuarters() {
    return this.props.quarters.map((quarter, index) => <Quarter key={index} {...quarter} />);
  }

  atLeastOneQuarterCompiled() {
    return this.props.quarters && this.props.quarters
      .filter((quarter) => quarter.quarter_status !== null).length > 0;
  }

  render() {
    if (this.atLeastOneQuarterCompiled()) {
      return (
        <div className="employee__okr">{this.getQuarters()}</div>
      );
    }
    return null;
  }
};
