const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const OrderPlanSettings = require('./components/order-plan-settings/OrderPlanSettings.react');

module.exports = ({ store }) => (
  <Provider store={store}>
    <OrderPlanSettings />
  </Provider>
);
