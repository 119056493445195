'use strict';

/* eslint-disable default-case,max-len */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _, Header) {
  var widgetCollection;
  var addWidgetCollection;

  var getWidgetTemplate = function (viewModel, responseModel) {
    var widgetTemplate;
    var label;
    var filter;
    switch (viewModel.get('key')) {
      case 'sales-funnel':
        responseModel.set({
          id: viewModel.get('id'),
          config: viewModel.get('config'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
        });
        widgetTemplate = new DashboardApp.WidgetSalesFunnelItemView({ model: responseModel });
        break;
      case 'invoices':
        var invoiceModel = new DashboardApp.WidgetInvoiceModel({
          id: viewModel.get('id'),
          config: viewModel.get('config'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          label: responseModel.get('label'),
        });
        var invoicesCollection = new DashboardApp.WidgetInvoiceCollection(_.map(responseModel.get('invoices')));
        widgetTemplate = new DashboardApp.WidgetInvoicesCompositeView({
          model: invoiceModel,
          collection: invoicesCollection,
        });
        break;
      case 'alert':
        // rimuovo endpoint dalla risposta in modo da non averlo nella collection
        delete responseModel.attributes.endpoint;
        var alertsCollection = new DashboardApp.WidgetAlertCollection(_.map(responseModel.attributes));
        var alertModel = new DashboardApp.WidgetAlertModel({
          id: viewModel.get('id'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          inconsistencies: alertsCollection.length,
        });
        widgetTemplate = new DashboardApp.WidgetAlertsCompositeView({
          model: alertModel,
          collection: alertsCollection,
        });
        break;
      case 'alert-overview':
        delete responseModel.attributes.endpoint;
        var alertsOverviewCollection = new DashboardApp.WidgetAlertCollection(_.map(responseModel.attributes));
        var alertOverviewModel = new DashboardApp.WidgetAlertModel({
          id: viewModel.get('id'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          inconsistencies: alertsOverviewCollection.length,
        });
        widgetTemplate = new DashboardApp.WidgetAlertsOverviewLayout({
          model: alertOverviewModel,
          alertsOverviewCollection: alertsOverviewCollection,
        });
        break;
      case 'bubbles':
        delete responseModel.attributes.cached_on;
        delete responseModel.attributes.endpoint;
        label = viewModel.get('config').active_filter.label;
        if (
          _.isUndefined(viewModel.get('config').active_filter.label)
          || viewModel.get('config').active_filter.label === ''
        ) {
          label = '/';
        }

        var bublesModel = new DashboardApp.WidgetAlertModel({
          id: viewModel.get('id'),
          config: viewModel.get('config'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          label: label,
        });
        widgetTemplate = new DashboardApp.WidgetBubblesItemView({ model: bublesModel });

        filter = {
          value: viewModel.get('config').active_filter.value,
          type: viewModel.get('config').active_filter.name,
        };

        var chartBubbles = DashboardApp.getBubblesChartSeries(responseModel.attributes, 'actual', filter, widgetTemplate);
        widgetTemplate.options.series = chartBubbles.series;
        widgetTemplate.options.maxYAxis = chartBubbles.maxYAxis;
        break;
      case 'bubbles-invoice':
        delete responseModel.attributes.cached_on;
        delete responseModel.attributes.endpoint;
        label = viewModel.get('config').active_filter.label;
        if (
          _.isUndefined(viewModel.get('config').active_filter.label)
          || viewModel.get('config').active_filter.label === ''
        ) {
          label = '/';
        }

        var bublesInvoiceModel = new DashboardApp.WidgetAlertModel({
          id: viewModel.get('id'),
          config: viewModel.get('config'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          label: label,
        });

        widgetTemplate = new DashboardApp.WidgetBubblesInvoiceItemView({ model: bublesInvoiceModel });
        filter = {
          value: viewModel.get('config').active_filter.value,
          type: viewModel.get('config').active_filter.name,
        };

        var chartBubblesInvoice = DashboardApp.getBubblesChartSeries(responseModel.attributes, 'actual_invoice', filter, widgetTemplate);
        widgetTemplate.options.series = chartBubblesInvoice.series;
        widgetTemplate.options.maxYAxis = chartBubblesInvoice.maxYAxis;
        break;
      case 'sparkline-product':
        responseModel.set({
          id: viewModel.get('id'),
          config: viewModel.get('config'),
          endpoint: viewModel.get('endpoint'),
          key: viewModel.get('key'),
          label: responseModel.get('label'),
        });
        widgetTemplate = new DashboardApp.WidgetSparklineProdLayout({ model: responseModel });
        break;
    }
    return widgetTemplate;
  };

  this.insertWidget = function (widgetID, widgetView, renderRegion) {
    var options = {
      widgetId: widgetID,
      sort: widgetCollection.length,
    };
    var insertWidgetRequest = Wethod.request('insert:user:widget', options);
    $.when(insertWidgetRequest).done(function (widgetModel) { // widgetView.model
      // eslint-disable-next-line no-shadow
      var options = { endpoint: DashboardApp.getEndpoint(widgetModel) };

      var userWidgetRequest = Wethod.request('get:user:widget', options);
      $.when(userWidgetRequest).done(function (userWidgetResponse) {
        var widgetTemplate = getWidgetTemplate(widgetModel, userWidgetResponse);

        // rimuovo dalla lista dei widget inseribili l'indicazione new
        _.each(addWidgetCollection.models, function (model) {
          if (model.get('key') === widgetModel.get('key')) {
            model.set({ is_new: false });
          }
        });

        // se aggiungo l'alert overview lo rimuovo dalla lista
        if (widgetModel.get('key') === 'alert-overview') {
          for (var i = 0; i < addWidgetCollection.models.length; i++) {
            if (addWidgetCollection.models[i].get('key') === 'alert-overview') {
              addWidgetCollection.models.splice(i, 1);
            }
          }
        }

        // aggiorno il data set della view con l'id del widget appena inserito ( viene usato per il
        // salvataggio della posizione )
        widgetView.el.dataset.item = widgetModel.get('id');

        widgetTemplate.options.widgetView = widgetView;
        widgetTemplate.options.new = true;
        renderRegion.html(widgetTemplate.render().$el);

        var addWidgetModel = new DashboardApp.AddWidgetModel({
          addTemplate: true,
          position: {
            sort: widgetCollection.length + 1,
          },
        });
        widgetCollection.add(addWidgetModel);
      });
    });
  };

  /**
   * Funzione che viene richiamata al render degli item della collection di caricamento, istanzia
   * e renderizza il widget corretto
   * @param  {[type]} widgetView
   * @param  {[type]} renderRegion
   */
  this.renderWidget = function (widgetView, renderRegion) {
    if (_.isUndefined(widgetView.model.options.addTemplate)) {
      var options = { endpoint: DashboardApp.getEndpoint(widgetView.model) };
      var userWidgetRequest = Wethod.request('get:user:widget', options);

      $.when(userWidgetRequest).done(function (userWidgetResponse) {
        if (!_.isUndefined(userWidgetResponse)) {
          var widgetTemplate = getWidgetTemplate(widgetView.model, userWidgetResponse);
          // aggiungo al template un riferimento alla view del caricamento
          widgetTemplate.options.widgetView = widgetView;
          renderRegion.html(widgetTemplate.render().$el);
        } else {
          widgetView.destroy();
        }
      });
    } else {
      var widgetList = [];
      var countNews = 0;
      _.each(addWidgetCollection.models, function (model) {
        if (model.get('is_new')) {
          countNews += 1;
        }
        widgetList.push({
          id: model.get('id'),
          name: model.get('key'),
          label: model.get('label'),
          is_new: model.get('is_new'),
        });
      });

      var addWidgetModel = new DashboardApp.AddWidgetModel({ widgetList: widgetList });
      var addWidgetTemplate = new DashboardApp.AddWidgetItemView({
        model: addWidgetModel,
        countNews: countNews,
      });
      addWidgetTemplate.options.widgetView = widgetView;
      renderRegion.html(addWidgetTemplate.render().$el);
    }
  };

  DashboardApp.Controller = {
    showDashboard: function () {
      var userAuthorizationRequest = Wethod.request('get:dashboard:authorization');
      var userInvoiceAuthorizationRequest = Wethod.request('get:dashboard:invoice:authorization');
      $.when(userAuthorizationRequest, userInvoiceAuthorizationRequest)
        .done(function (permission, invoicePermission) {
          if (_.isUndefined(permission)) {
            return;
          }

          // salvo i permessi relativi al modulo invoice
          DashboardApp.invoicePermission = invoicePermission;
          // salvo i permessi del modulo dashboard
          DashboardApp.appPermission = permission;
          if (DashboardApp.getPermission('all')) {
            // app layout
            var layout = new DashboardApp.StructureLayoutView();
            var sectionHeaderModel = new Header.SectionHeaderModel({
              current_section: 'Main Dashboard',
              preview_anchor_id: 'overview',
              helper_url: 'dashboard/index',
            });
            var dashboardHeaderRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });

            var userDashboardRequest = Wethod.request('get:user:dashboard');
            var widgetsRequest = Wethod.request('get:widgets');
            $.when(userDashboardRequest, widgetsRequest)
              .done(function (userDashboardResponse, widgetsResponse) {
                layout.render();
                // layout.dashboardHeaderSection.show(dashboardHeaderRegion);
                // se è già stato aggiunto il widget alert overview lo rimuovo dalla lista
                _.each(userDashboardResponse.models, function (model) {
                  if (model.get('key') === 'alert-overview') {
                    for (var i = 0; i < widgetsResponse.models.length; i++) {
                      if (widgetsResponse.models[i].get('key') === 'alert-overview') {
                        widgetsResponse.models.splice(i, 1);
                      }
                    }
                  }
                });

                addWidgetCollection = widgetsResponse;
                widgetCollection = userDashboardResponse;
                var widgetTemplateCollection = new DashboardApp.WidgetsCollectionView({
                  collection: widgetCollection,
                });

                // aggiungo il tasto per l'inserimento nuovi widget
                var addWidgetModel = new DashboardApp.AddWidgetModel({
                  addTemplate: true,
                  position: {
                    sort: widgetCollection.length + 1,
                  },
                });
                widgetCollection.add(addWidgetModel);
                layout.sectionHeader.show(dashboardHeaderRegion);
                layout.dashboardBody.show(widgetTemplateCollection);
              });
          } else {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
