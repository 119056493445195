const React = require('react');
const PropTypes = require('prop-types');
const FullIcon = require('./icons/FullStarIcon.react');
const EmptyIcon = require('./icons/EmptyStarIcon.react');

class Star extends React.Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  onInputChange() {
    if (this.isEditable()) {
      this.props.onChange(this.props.value);
    }
  }

  onEnter() {
    if (this.isEditable()) {
      this.props.onMouseEnter(this.props.value);
    }
  }

  getClassName() {
    let className = 'wethod-rating__star';
    if (this.props.disabled) {
      className += ' disabled';
    }
    if (this.props.readOnly) {
      className += ' wethod-rating__star--read-only';
    }
    return className;
  }

  getIcon() {
    return this.props.full ? <FullIcon /> : <EmptyIcon />;
  }

  isEditable() {
    return !this.props.disabled && !this.props.readOnly;
  }

  render() {
    return (
      <label onMouseEnter={this.onEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={this.getClassName()}>
        {this.getIcon()}
        <input type="radio"
          name={this.props.name}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.onInputChange}
          disabled={this.props.disabled} />
        <span className="wethod-rating__label">{this.props.label}</span>
      </label>
    );
  }
}

Star.defaultProps = {
  name: 'rating',
  checked: false,
  onChange: null,
  disabled: false,
  full: false,
  readOnly: false,
};

Star.propTypes = {
  /**
   * Used to group stars. All star in the same rating must have the same name.
   */
  name: PropTypes.string,
  /**
   * The unique value associated with the star.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Used for accessibility and showed to the user.
   */
  label: PropTypes.string.isRequired,
  /**
   * It says whether or not this star is the default-selected item in the group.
   */
  checked: PropTypes.bool,
  /**
   * It says whether or not this star must be displayed as full.
   */
  full: PropTypes.bool,
  /**
   * Callback fired when a star is selected.
   *
   * @param {string} value The `value` of the selected star
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when the mouse pointer enters this star area.
   */
  onMouseEnter: PropTypes.func.isRequired,
  /**
   * Callback fired when the mouse pointer leaves this star area.
   */
  onMouseLeave: PropTypes.func.isRequired,
  /**
   * If `true`, the switch will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the switch cannot be edited.
   */
  readOnly: PropTypes.bool,
};

module.exports = Star;
