const { connect } = require('react-redux');
const Component = require('../../../components/modals/Portfolio/PortfolioBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  works: state.portfolio,
  waiting: state.waiting,
  creating: state.creating,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
  addWork: (work, employeeId) => dispatch(actions.addWork(work, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
