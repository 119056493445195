/* eslint-disable react/sort-comp,react/prop-types,class-methods-use-this,consistent-return,
 react/no-array-index-key */
const React = require('react');
const SelectItem = require('./SelectItem.react');

module.exports = class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      showSelect: false,
    };
  }

  componentDidMount() {
    $(document).click();
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  isEnter(code) {
    return code === 13;
  }

  isEmpty() {
    return this.state.value.trim() === '';
  }

  handleKeyDown(e) {
    const code = (e.keyCode ? e.keyCode : e.which);
    const value = this.state.value.trim();
    if (this.isEnter(code) && !this.isEmpty()) {
      if (this.props.isInTags(value)) {
        this.props.highlight(value);
      } else {
        this.props.add(value);
        this.setState({ value: '' });
      }
    }
  }

  handleItemSelection(value) {
    if (this.props.isInTags(value)) {
      this.props.highlight(value);
    } else {
      this.props.add(value);
      this.setState({ value: '' });
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Returns collection filtered by key.
   * @param collection
   * @param key
   */
  filterItems(collection, key) {
    return collection.filter((item) => this.found(key, item.name));
  }

  /**
   * A compare function (pass it as a callback to a sort method) to sort two tags like this:
   * - Show the most used first
   * - If a,b have the same number of uses, display them in alphabetical order
   *
   * @param a
   * @param b
   * @returns {number}
   */
  compareByUsesAndNames(a, b) {
    if (a.count > b.count) return -1;
    if (a.count < b.count) return 1;
    if (a.count === b.count) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  }

  /**
   * Returns an array of known tags element filtered by this.state.value and sorted using
   * this.compareByUsesAndNames.
   */
  getKnownTags() {
    return this.filterItems(this.props.knownTags, this.state.value)
      .sort(this.compareByUsesAndNames)
      .map((tag, index) => (
        <SelectItem key={index}
          onClick={this.handleItemSelection.bind(this)}
          {...tag} />
      ));
  }

  getSelect() {
    if (this.state.showSelect) {
      return (
        <ul className="wethod-tags__select">
          {this.getKnownTags()}
        </ul>
      );
    }
    return null;
  }

  handleFocus() {
    this.setState({ showSelect: true });
  }

  getPlaceholder() {
    if (this.props.placeholder) return this.props.placeholder;
    return 'Add tag';
  }

  render() {
    return (
      <div className="wethod-tags__add-item">
        <input type="text"
          placeholder={this.getPlaceholder()}
          value={this.state.value}
          onKeyDown={this.handleKeyDown.bind(this)}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)} />
        {this.getSelect()}
      </div>
    );
  }
};
