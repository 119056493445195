const React = require('react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const SearchInput = require('../../../../../../common/react/SearchInput.react');
const RadioGroup = require('../../../../../../common/react/inputs/RadioGroup/RadioGroup.react');
const Template = require('../../../../../../common/react/inputs/RadioGroup/RadioCollapsible.react');
const TemplateStructure = require('../../../budget-template/components/BudgetTemplateStructure.react');
const EmptyTemplateList = require('./EmptyTemplateList.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const searchFilter = require('../../../budget-template/services/searchFilter');

module.exports = class TemplateListModalBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      filteredTemplates: this.props.templates,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(keyword) {
    this.setState({
      keyword,
      filteredTemplates: searchFilter(keyword, this.props.templates),
    });
  }

  isFiltering() {
    return this.state.keyword !== '';
  }

  render() {
    return (
      <ModalBody>
        <ShowIf condition={this.isFiltering() || this.props.templates.length}
          showElse={<EmptyTemplateList key="empty" />}>
          <ModalBodyBlock>
            <SearchInput filter={this.onFilter} />
            <RadioGroup name="template"
              key="template"
              value={this.props.selectedTemplate}
              onChange={this.props.handleInputChange}
              className="budget-creation__list">
              {this.state.filteredTemplates.map((template) => (
                <Template key={template.id}
                  value={template.id}
                  title={template.name}
                  description={template.description}
                  expandedBody={<TemplateStructure areas={template.areas} />} />
              ))}
            </RadioGroup>
          </ModalBodyBlock>
        </ShowIf>
      </ModalBody>
    );
  }
};
