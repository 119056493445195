const request = require('./Request');

const TimesheetDetailed = {
  getAll(date, employeeId = '') {
    let query = `?date=${date}`;
    if (employeeId) {
      query += `&employeeId=${employeeId}`;
    }

    return request({
      method: 'get',
      url: `/timetrackingboard/${query}`,
    });
  },
  save(data) {
    return request({
      method: 'post',
      url: '/timetracking/',
      data,
    });
  },
};

TimesheetDetailed.CANNOT_EDIT_BECAUSE_ARCHIVED = 'CompilationLimitArchived';
TimesheetDetailed.CANNOT_EDIT_BECAUSE_WHITELIST = 'CompilationLimitWhitelist';
TimesheetDetailed.CANNOT_EDIT_BECAUSE_AUTOMATIC = 'CompilationLimitAutomaticTimesheet';
TimesheetDetailed.CANNOT_EDIT_BECAUSE_PROJECT_SCOPE = 'CompilationLimitOutProjectScope';
TimesheetDetailed.CANNOT_EDIT_BECAUSE_TIME_BUFFER = 'CompilationLimitOutBufferTime';
TimesheetDetailed.CANNOT_EDIT_BECAUSE_DATA_FROZEN = 'CompilationLimitDataFrozen';

module.exports = TimesheetDetailed;
