const React = require('react');
const NoteInput = require('../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const IssueDateInput = require('./IssueDateInput.react');
const SupplyPeriodInput = require('./SupplyPeriodInput.react');
const ProjectAutocomplete = require('../../containers/sidebar/ProjectSelect');
const TextField = require('../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const SupplierAutocomplete = require('./SupplierSelect.react');
const AreaSelect = require('../../containers/sidebar/AreaSelect');
const Value = require('../../containers/sidebar/ValueController');
const FileSection = require('../../containers/sidebar/FileSection');
const Row = require('../../../../../../../common/react/sidebar/SidebarInputRow.react');
const InputWrapper = require('../../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const DateInput = require('../../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const Order = require('../Order.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

module.exports = class OrderSidebarBody extends React.Component {
  constructor(props) {
    super(props);
    this.handleSimpleInputChange = this.handleSimpleInputChange.bind(this);
  }

  handleSimpleInputChange(e) {
    this.props.onChange(e.target.name, e.target.value);
  }

  // eslint-disable-next-line class-methods-use-this
  getAttribute(object, attribute) {
    return object
      ? object[attribute]
      : null;
  }

  /**
   * Files cannot be uploaded or deleted before order is created
   * @returns {boolean}
   */
  canEditFiles() {
    return this.props.item.id != null;
  }

  isOrderPaid() {
    return this.props.item.status === Order.STATUS_PAID;
  }

  render() {
    return (
      <div className="orders__sidebar-body">
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
          }>
            <InputMetadataManager metadataService={this.props.metadataService} name="project" canEdit={!this.props.readOnly}>
              <ProjectAutocomplete value={this.props.item.project}
                onFocus={this.props.resetServerError}
                error={this.props.errors.project}
                updateErrors={this.props.updateErrors}
                onChange={this.props.onChange} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <TextField name="job-order"
              value={this.props.item.project ? this.props.item.project.job_order : ''}
              label="Job order"
              id="sidebar-job-order"
              readOnly />
          </InputWrapper>
        </Row>
        <h3 className="wethod-sidebar__subtitle">Supplier & order details</h3>
        <Row>
          <InputWrapper>
            <InputMetadataManager canEdit={!this.props.readOnly}
              name="supplier"
              metadataService={this.props.metadataService}>
              <SupplierAutocomplete value={this.props.item.supplier}
                onChange={this.props.onChange}
                error={this.props.errors.supplier}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager
              canEdit={!this.props.readOnly && Wethod.FinanceApp.purchaseCodeEditable}
              name="code"
              metadataService={this.props.metadataService}>
              <TextField name="code"
                value={this.props.item.code ? this.props.item.code : ''}
                onChange={this.handleSimpleInputChange}
                label="Purchase code"
                id="sidebar-purchase-code"
                helperText={Wethod.FinanceApp.purchaseCodeEditable ? '' : 'Auto generated'} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager canEdit={!this.props.readOnly}
              name="value"
              metadataService={this.props.metadataService}>
              <Value order={this.props.item}
                onChange={this.props.onChange}
                onFocus={this.props.resetServerError}
                updateErrors={this.props.updateErrors}
                error={this.props.serverError ? this.props.serverError : this.props.errors.value} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputMetadataManager canEdit={!this.props.readOnly}
              name="area"
              metadataService={this.props.metadataService}>
              <AreaSelect value={this.props.item.area}
                projectId={this.getAttribute(this.props.item.project, 'id')}
                onChange={this.props.onChange} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          }>
            <InputMetadataManager canEdit={!this.props.readOnly}
              name="date"
              metadataService={this.props.metadataService}>
              <IssueDateInput
                label="Issue date"
                disableBeforeEqual={this.props.metadataService.getConstraintValue('date', 'greater_than')}
                value={this.props.item.date}
                onChange={this.handleSimpleInputChange}
                error={this.props.errors.date}
                updateErrors={this.props.updateErrors} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <ShowIf condition={this.isOrderPaid()}>
          <Row>
            <InputWrapper icon={
              <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
            }>
              <InputMetadataManager canEdit={!this.props.readOnly}
                name="date_paid_on"
                metadataService={this.props.metadataService}>
                <DateInput
                  label="Paid on"
                  disableBeforeEqual={this.props.metadataService.getConstraintValue('date_paid_on', 'greater_than')}
                  id="sidebar-paid-on-date"
                  value={this.props.item.date_paid_on}
                  onChange={this.handleSimpleInputChange}
                  errorText={this.props.errors.date_paid_on} />
              </InputMetadataManager>
            </InputWrapper>
          </Row>
        </ShowIf>
        <h3 className="wethod-sidebar__subtitle">Period of supply</h3>
        <SupplyPeriodInput onChange={this.handleSimpleInputChange}
          metadataService={this.props.metadataService}
          updateErrors={this.props.updateErrors}
          start={this.props.item.start}
          readOnly={this.props.readOnly}
          duration={this.props.item.duration}
          errors={this.props.errors} />
        <h3 className="wethod-sidebar__subtitle">Notes</h3>
        <Row>
          <InputWrapper icon={
            <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
          }>
            <InputMetadataManager canEdit={!this.props.readOnly}
              name="note"
              metadataService={this.props.metadataService}>
              <NoteInput
                label="Notes"
                id="sidebar-notes"
                value={this.props.item.note ? this.props.item.note : ''}
                onChange={this.handleSimpleInputChange}
                rows={4} />
            </InputMetadataManager>
          </InputWrapper>
        </Row>
        <FileSection item={this.props.item}
          canDownload
          canDelete={!this.props.readOnly && this.canEditFiles()}
          canUpload={!this.props.readOnly && this.canEditFiles()} />
      </div>
    );
  }
};
