const React = require('react');
const BudgetFooterAction = require('./BudgetFooterAction.react');
const IconButtonTooltip = require('../../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

const BudgetFooterButton = ({ label, disabled, children, onClick, noBorder, classNameButton }) => (
  <BudgetFooterAction noBorder={noBorder} classNameButton={classNameButton}>
    <IconButtonTooltip label={label}
      borderless
      disabled={disabled}
      onClick={onClick}>
      {children}
    </IconButtonTooltip>
  </BudgetFooterAction>
);

module.exports = BudgetFooterButton;
