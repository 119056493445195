const { connect } = require('react-redux');
const ItemComponent = require('../../components/sidebar/ItemComponent.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  favorites: state.favorites,
  isWaiting: state.waitingFor.filter((key) => key === 'load-favorites' || key === 'save-favorite' || key === 'delete-favorite').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFavorite: (favorite) => dispatch(actions.deleteFavorite(favorite)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(ItemComponent);
