/* eslint-disable react/jsx-props-no-spreading */
const React = require('react');
const PropTypes = require('prop-types');
const FeedbackTableCell = require('./FeedbackTableCell.react');
const TableColumnList = require('./models/TableColumnList');

/**
 *  A table row.
 */
const TableRow = ({
  children, variant, summary, scrollBarWidth, className, rootRef, feedback, visibleColumns, ...rest
}) => {
  const getClassName = () => {
    let name = 'wethod-table__row';
    if (className !== undefined) {
      name += ` ${className}`;
    }
    if (variant === 'header') {
      name += ' wethod-table__row--header';
    }
    if (summary) {
      name += ' wethod-table__row--summary';
    }
    return name;
  };

  const getChildWidth = (child) => {
    const cell = visibleColumns.findByKey(child.props.name);
    return cell && cell.elasticWidth;
  };

  const injectProps = (child) => {
    if (child) {
      return React.cloneElement(child, {
        variant,
        style: { width: getChildWidth(child) },
      });
    }
    return null;
  };

  /**
   * Returns children that can be displayed:
   * - If using "visibleColumns", returns only the ones which name is in visibleCells
   * - Returns all otherwise
   *
   * @throws {Error} if using "visibleColumns" but children are without "name"
   * @return {React.ReactChild}
   */
  const getVisibleChildren = () => {
    if (visibleColumns.length) {
      return React.Children.toArray(children)
        .filter((child) => {
          if (!child.props.name) {
            throw Error('TableRow children must have a "name" when you use "visibleColumns" prop. Otherwise children to render cannot be found.');
          }
          return visibleColumns.has(child.props.name);
        });
    }
    return children;
  };

  /**
   * Returns children to render.
   * An additional child containing feedback message is appended if message is not empty.
   * @return {unknown[]}
   */
  const getChildren = () => {
    const augmentedChildren = React.Children.map(getVisibleChildren(), injectProps);
    if (feedback) {
      augmentedChildren.push(<FeedbackTableCell key="feedback">{feedback}</FeedbackTableCell>);
    }
    return augmentedChildren;
  };

  return (
    <tr className={getClassName()}
      data-testid="wethod-table-row"
      style={{ paddingRight: scrollBarWidth }}
      ref={rootRef}
      {...rest}>
      {getChildren()}
    </tr>
  );
};

TableRow.defaultProps = {
  children: null,
  rootRef: null,
  variant: null,
  summary: false,
  scrollBarWidth: null,
  className: '',
  feedback: null,
  visibleColumns: new TableColumnList(),
};

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  rootRef: PropTypes.func,
  /**
   * Passed to children.
   *
   * @see TableCell
   */
  variant: PropTypes.oneOf(['header']),
  /**
   * True if this row represents a summary row.
   *
   * @see TableSummaryRow
   */
  summary: PropTypes.bool,
  scrollBarWidth: PropTypes.number,
  /**
   * className to be add to the base one.
   */
  className: PropTypes.string,
  /**
   * Text to show as feedback.
   */
  feedback: PropTypes.string,
  /**
   * List of visible TableColum.
   * If empty, this props is ignored and all column are considered visible.
   */
  visibleColumns: PropTypes.instanceOf(TableColumnList),
};

module.exports = TableRow;
