module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.PERSONAL_TOKEN_ADD_REQUEST = 'PERSONAL_TOKEN_ADD_REQUEST';
module.exports.PERSONAL_TOKEN_ADD_SUCCESS = 'PERSONAL_TOKEN_ADD_SUCCESS';
module.exports.PERSONAL_TOKEN_ADD_FAILURE = 'PERSONAL_TOKEN_ADD_FAILURE';

module.exports.PERSONAL_TOKEN_DELETE_REQUEST = 'PERSONAL_TOKEN_DELETE_REQUEST';
module.exports.PERSONAL_TOKEN_DELETE_SUCCESS = 'PERSONAL_TOKEN_DELETE_SUCCESS';

module.exports.PERSONAL_TOKEN_LIST_REQUEST = 'PERSONAL_TOKEN_LIST_REQUEST';
module.exports.PERSONAL_TOKEN_LIST_SUCCESS = 'PERSONAL_TOKEN_LIST_SUCCESS';

module.exports.PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST = 'PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST';
module.exports.PERSONAL_TOKEN_PROPERTY_METADATA_SUCCESS = 'PERSONAL_TOKEN_PROPERTY_METADATA_SUCCESS';
