const React = require('react');
const PropTypes = require('prop-types');
const Transition = require('../Transition.react');

require('./style.scss');

const FlipTransition = ({ children, triggered, animateFirstMount, className }) => {
  const flipTimeout = 400;

  const revealTimeout = flipTimeout / 2;

  const getChildren = (position) => React.Children.toArray(children)[position];
  return (
    <Transition triggered={triggered} className="wethod-transition-flip" animateFirstMount={animateFirstMount} mountOnEnter={false} unmountOnExit={false} timeout={flipTimeout}>
      <div className={className}>
        <Transition triggered={!triggered} className="wethod-transition-flip__side" animateFirstMount={animateFirstMount} timeout={revealTimeout}>
          <div className="wethod-transition-flip__side wethod-transition-flip__side--front">
            {getChildren(0)}
          </div>
        </Transition>
        <Transition triggered={triggered} className="wethod-transition-flip__side" animateFirstMount={animateFirstMount} timeout={revealTimeout}>
          <div className="wethod-transition-flip__side wethod-transition-flip__side--back">
            {getChildren(1)}
          </div>
        </Transition>
      </div>
    </Transition>
  );
};

FlipTransition.defaultProps = {
  animateFirstMount: false,
  className: '',
};

FlipTransition.propTypes = {
  /**
   * The two elements to animate: first child is visible when transition not triggered, second child
   * gets visible once transition triggered.
   */
  // eslint-disable-next-line react/require-default-props
  children(props, propName) {
    if (React.Children.count(props[propName]) !== 2) return new Error(`"${propName}" must be exactly two components.`);

    return null;
  },
  /**
   * Show the component; triggers the enter or exit states
   */
  triggered: PropTypes.bool.isRequired,
  /**
   * Normally a component is not transitioned if it is shown when the `<Transition>` component
   * mounts.
   * If you want to transition on the first mount set `animateFirstMount` to `true`, and the
   * component will transition in as soon as the `<Transition>` mounts.
   */
  animateFirstMount: PropTypes.bool,
  /**
   * CSS class to apply to root child.
   */
  className: PropTypes.string,
};

module.exports = FlipTransition;
