const React = require('react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const T = require('../../../../../../../../common/react/Typography/Typography.react');
const CopyableTextInput = require('./CopyableTextInput.react');

const TokenCreatedModal = ({ closeModal, value, name, description }) => (
  <Modal onClose={closeModal} title="Create new token">
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Your new token has been created. Make sure to copy it now as you will not be able to
          see it again.
        </ModalBodyBlock>
        <ModalBodyBlock>
          <div>
            <T weight={T.WEIGHTS.SEMIBOLD}>{name}</T>
          </div>
          <div>
            <T size={T.SIZES.PX11} color={T.COLORS.PLACEHOLDER_GRAY}>{description}</T>
          </div>
        </ModalBodyBlock>
        <ModalBodyBlock>
          <CopyableTextInput value={value} />
        </ModalBodyBlock>
      </ModalBody>
    </ModalContent>
  </Modal>
);

module.exports = TokenCreatedModal;
