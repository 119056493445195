const { connect } = require('react-redux');
const Component = require('../components/ProjectInfo.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  ...state.project,
  isLinked: state.isLinked,
  isMaster: state.plan && state.plan.billing_group != null,
});

const mapDispatchToProps = (dispatch) => ({
  showDelegateModal: (project) => dispatch(actions.showDelegateModal(project)),
  showLinkedProjectsModal: () => dispatch(actions.showLinkedProjectsModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
