const React = require('react');
const EnableTimeLimit = require('./EnableTimeLimit.react');
const BufferTimeLimit = require('./BufferTimeLimit.react');

const TimesheetTimeLimit = ({
  timeLimit,
  waitingForSetting,
  waitingForUpdating,
  onTimeLimitEnabledChange,
  onTimeLimitBufferChange,
}) => (
  <div>
    <EnableTimeLimit
      timeLimit={timeLimit}
      waitingForSetting={waitingForSetting}
      onTimeLimitEnabledChange={onTimeLimitEnabledChange} />
    <BufferTimeLimit
      timeLimit={timeLimit}
      waitingForUpdating={waitingForUpdating}
      onTimeLimitBufferChange={onTimeLimitBufferChange} />
  </div>
);

module.exports = TimesheetTimeLimit;
