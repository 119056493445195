const React = require('react');
const Select = require('./StyledSelect.react');

const GranularitySelect = ({ onChange, value }) => {
  const items = [{ value: 'all', label: 'All allocations' }, { value: 'single', label: 'Single allocation' }];

  return (
    <div className="planning-people-repeat-granularity">
      <Select items={items} onChange={onChange} value={value} placeholder="Apply to" name="granularity" />
    </div>
  );
};

module.exports = GranularitySelect;
