/* eslint-disable react/no-array-index-key */
const React = require('react');
const Project = require('../../containers/Projects/Project');
const EmptyRow = require('./EmptyProject.react');

module.exports = class Projects extends React.Component {
  componentDidMount() {
    const tableBodyWidth = $('.client-person__projects-list-items').outerWidth();
    const tableRowWidth = $('.client-person-project').outerWidth();
    if (tableRowWidth) {
      const scrollbarWidth = tableBodyWidth - tableRowWidth;
      const header = $('.client-person__resources-list-header');
      header.css('padding-right', scrollbarWidth);
    }
  }

  getProjects() {
    if (this.props.projects.length > 0) {
      return this.props.projects.map((item, index) => <Project key={index} {...item} />);
    }
    return <EmptyRow />;
  }

  render() {
    return (
      <div className="client-person__projects">
        <div className="client__title">PROJECTS</div>
        <div className="client-person__projects-list">
          <div className="client-person__resources-list-header wethod-list-header">
            <div className="client-person__projects-list--name wethod-list-column" />
            <div className="client-person__projects-list--pm wethod-list-column">PM</div>
            <div className="client-person__projects-list--account wethod-list-column">Account</div>
            <div className="client-person__projects-list--value wethod-list-column">Value</div>
            <div className="client-person__projects-list--probability wethod-list-column">Probability</div>
          </div>
          <div className="client-person__projects-list-items">
            <ul>
              {this.getProjects()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};
