/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,eqeqeq */

const React = require('react');
const Tag = require('./Tag.react');

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  toggleLabel(tags) {
    return tags.length ? <div className="dropdown__title">{this.props.category.name}</div> : null;
  }

  isSelected(tag) {
    const category = this.props.selectedCategories
      .find((selected) => selected.id == this.props.category.id);
    return Boolean(category.tags.find((selected) => selected.id == tag.id));
  }

  onClick(tag) {
    if (!this.isSelected(tag)) {
      this.props.onSelect(tag, this.props.category);
    } else {
      this.props.onRemove(tag, this.props.category);
    }
  }

  render() {
    const tags = this.props.category.tags.map((tag) => (
      <Tag
        active={this.isSelected(tag)}
        key={tag.id}
        tag={tag}
        onClick={this.onClick} />
    ));

    return (
      <div className="filter__block">
        {this.toggleLabel(tags)}
        {tags}
      </div>
    );
  }
}

module.exports = TagList;
