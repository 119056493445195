const React = require('react');
/**
 * Wrapper for TextArea body content.
 * @see TextArea
 * @type {Body}
 */
module.exports = class Body extends React.Component {
  getLastEditAuthor() {
    if (this.props.editedBy) {
      const { person } = this.props.editedBy;
      return `${person.name} ${person.surname}`;
    }
    return null;
  }

  render() {
    return (
      <div className="project-canvas__widget-text-area-body">
        <div className="project-canvas__widget-text-area-body-content">
          {this.props.children}
        </div>
        <div className="project-canvas__widget-text-area-body-footer">
          Last edited by
          {' '}
          <span>{this.getLastEditAuthor()}</span>
        </div>
      </div>
    );
  }
};
