const React = require('react');

module.exports = ({ date, timeToRead, cachedOn }) => {
  const getTimeToReadComponent = () => {
    const time = timeToRead;
    if (time) {
      return (
        <span className="briefing-content__info--time-to-read">
          {time}
          {' '}
          min read
        </span>
      );
    }
    return null;
  };

  const getCreatedAt = () => {
    if (cachedOn) {
      const cachedDate = moment(cachedOn);
      const formattedDate = cachedDate.format('dddd, MMM D, YYYY');
      const formattedTime = cachedDate.format('H a');
      return `${formattedDate} at ${formattedTime}`;
    }
    const formattedDate = date.clone().add(1, 'week').format('dddd, MMM D, YYYY');
    return `${formattedDate} at 6 am`;
  };

  return (
    <div className="briefing-content__info">
      <span
        className="briefing-content__info--created">
        <span>Freshly brewed with love on</span>
        {' '}
        {getCreatedAt()}
      </span>
      {getTimeToReadComponent()}
    </div>
  );
};
