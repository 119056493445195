const React = require('react');
const BudgetFooterAction = require('./BudgetFooterAction.react');
const IconLink = require('../../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');

const BudgetFooterLink = ({ noBorder, label, href, children }) => (
  <BudgetFooterAction noBorder={noBorder}>
    <IconLink label={label}
      borderless
      href={href}
      target="_blank">
      {children}
    </IconLink>
  </BudgetFooterAction>
);

module.exports = BudgetFooterLink;
