const request = require('./Request');

const DataFreezingSegment = {
  list() {
    const url = '/data-freezing/segments';

    return request({
      method: 'get',
      url,
    });
  },
  getWarningsTotal(from, to) {
    let url = '/data-freezing/warnings-total';

    url += from ? `?from=${from}` : '';
    url += to ? `&to=${to}` : '';

    return request({
      method: 'get',
      url,
    });
  },
  updateSegments(segments) {
    const url = '/data-freezing/segments';

    return request({
      method: 'POST',
      url,
      data: {
        segments,
      },
    });
  },
};

module.exports = DataFreezingSegment;
