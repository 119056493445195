const React = require('react');
const Widget = require('../containers/Widget');
const HeadcountTrend = require('../containers/HeadcountTrend');
const CostRevenuesTrend = require('../containers/CostRevenuesTrend');
const InOutTrend = require('../containers/InOutTrend');
const GenderTrend = require('../containers/GenderTrend');
const AgeTrend = require('../containers/AgeTrend');
const TagTrend = require('../containers/ColumnTrend');
const Menu = require('../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const Item = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');

module.exports = class Widgets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  getTagWidgets() {
    const tagsWidgets = [];
    if (this.props.firstTag) {
      tagsWidgets.push(
        <Widget key="first-tag" title={this.props.firstTag.label} subtitle="in units">
          <TagTrend data={this.props.firstTag.data} />
        </Widget>,
      );
    }
    if (this.props.secondTag) {
      tagsWidgets.push(
        <Widget key="second-tag" title={this.props.secondTag.label} subtitle="in units">
          <TagTrend data={this.props.secondTag.data} />
        </Widget>,
      );
    }
    if (this.props.businessUnit) {
      tagsWidgets.push(
        <Widget key="business-unit" title="Business Units" subtitle="in units">
          <TagTrend data={this.props.businessUnit.data} />
        </Widget>,
      );
    }
    return tagsWidgets;
  }

  getOptionsButton() {
    let style = 'wethod-icon-button wethod-icon-button--no-border';
    if (!this.isHeadcountAvailable() || !this.props.canEditSettings) style += ' disabled';
    return (
      <span>
        <button type="button"
          className={style}
          onClick={this.showMenu.bind(this)}
          ref={(el) => this.button = el}>
          <div className="wethod-icon wethod-icon-more wethod-icon-more--black" />
        </button>
        <Menu anchorEl={this.button}
          open={this.state.showMenu}
          onClose={this.hideMenu.bind(this)}>
          <Item onClick={this.props.showTargetModal}>Set target</Item>
        </Menu>
      </span>
    );
  }

  showMenu() {
    if (this.isHeadcountAvailable() && this.props.canEditSettings) {
      this.setState({ showMenu: true });
    }
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  isHeadcountAvailable() {
    return !this.props.isWaiting && this.props.headcount && this.props.headcount.data;
  }

  render() {
    return (
      <div className="demographics__widgets">
        <Widget title="Headcount" subtitle="in units" actions={this.getOptionsButton()}>
          <HeadcountTrend />
        </Widget>
        <Widget title="Costs and revenues per employee" subtitle="(monthly average) in thousands">
          <CostRevenuesTrend />
        </Widget>
        <Widget title="Gender distribution" subtitle="in units">
          <GenderTrend />
        </Widget>
        <Widget title="Age distribution" subtitle="in units">
          <AgeTrend />
        </Widget>
        <Widget title="In & Out" subtitle="in units">
          <InOutTrend />
        </Widget>
        {this.getTagWidgets()}
      </div>
    );
  }
};
