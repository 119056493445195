const React = require('react');
const ProjectStep = require('../containers/ProjectStep');
const PeopleStep = require('../containers/PeopleStep');
const FinalStep = require('./FinalStep.react');
const ProjectStepNoMembers = require('../containers/ProjectStepNoMembers');

module.exports = class Steps extends React.Component {
  getFirstStepStyle() {
    return {
      marginLeft: `${-100 * this.props.step}%`,
    };
  }

  render() {
    if (this.props.completedError) {
      return (
        <div className="project-review__steps">
          <FinalStep />
        </div>
      );
    }
    if (this.props.hasMembers) {
      return (
        <div className="project-review__steps">
          <ProjectStep incomplete={this.props.hasIncompleteProjectReview}
            style={this.getFirstStepStyle()} />
          <PeopleStep incompleteReviews={this.props.incompletePeopleReviews} />
          <FinalStep />
        </div>
      );
    }
    return (
      <div className="project-review__steps">
        <ProjectStepNoMembers incomplete={this.props.hasIncompleteProjectReview}
          style={this.getFirstStepStyle()} />
        <PeopleStep />
        <FinalStep />
      </div>
    );
  }
};
