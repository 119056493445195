const React = require('react');
const PropTypes = require('prop-types');
const FileModel = require('../models/File');

const Folder = ({ folder, onClick }) => {
  function handleClick() {
    if (onClick) {
      onClick(folder);
    }
  }

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="project-canvas__file project-canvas__file--folder" onClick={handleClick}>
      <div className="project-canvas__folder--icon">
        <div className="wethod-icon-folder wethod-icon-folder--black" />
      </div>
      <div className="project-canvas__file--name">{folder.name}</div>
      <div className="project-canvas__file--info" />
      <div />
    </div>
  );
};

Folder.defaultProps = {
  onClick: null,
};

Folder.propTypes = {
  folder: PropTypes.instanceOf(FileModel).isRequired,
  /**
   * The function to call when this folder is clicked.
   *
   * @param folder {Object}
   */
  onClick: PropTypes.func,
};

module.exports = Folder;
