const React = require('react');
const PropTypes = require('prop-types');

require('./style.scss');

class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onChange(e) {
    e.persist();

    this.setState({ value: e.target.value }, () => {
      this.validate(e.target.value);
      this.onParentChange(e);
    });
  }

  getClassName() {
    return this.props.className ? `wethod-input-root ${this.props.className}` : 'wethod-input-root';
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
    });
  }

  render() {
    return (
      <div className="wethod-textarea">
        <textarea
          className={this.getClassName()}
          ref={this.props.rootRef}
          value={this.state.value}
          id={this.props.id}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          required={this.props.required}
          aria-label={this.props.label}
          cols={this.props.cols}
          rows={this.props.rows} />
      </div>
    );
  }
}

TextArea.defaultProps = {
  value: '',
  id: undefined,
  placeholder: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  onFocus: undefined,
  onBlur: undefined,
  rootRef: undefined,
  validate: undefined,
  required: undefined,
  onChange: undefined,
  label: undefined,
  cols: 20,
  rows: 2,
  className: '',
  resetErrors: null,
};

TextArea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /** Function to access to inptut DOM element
   * @param input {HTMLElement} DOM element ref
   * */
  rootRef: PropTypes.func,
  /** Specifies the visible width */
  cols: PropTypes.number,
  /** Specifies the visible number of lines */
  rows: PropTypes.number,
  className: PropTypes.string,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
};

module.exports = TextArea;
