/* eslint-disable react/prefer-stateless-function */
const React = require('react');

/**
 * Wrapper for TextArea empty content.
 * @see TextArea
 * @type {Empty}
 */
module.exports = class Empty extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty project-canvas-empty">
        <div className="project-canvas-empty__image">
          {this.props.image}
        </div>
        <div className="project-canvas-empty__text">
          <div className="project-canvas-empty__text-title">{this.props.title}</div>
          <div className="project-canvas-empty__text-description">{this.props.description}</div>
          <div className="project-canvas-empty__text-action wethod-widget__empty-message-link">{this.props.action}</div>
        </div>
      </div>
    );
  }
};
