const React = require('react');
const Event = require('./FocusedEvent/Event.react');
const HeatmapEvent = require('./FocusedEvent/HeatmapEvent.react');
const FollowUp = require('./FocusedEvent/FollowUp.react');

module.exports = class FocusedEvent extends React.Component {
  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props.event);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props.event);
  }

  render() {
    if (this.props.event.isHeatmapEvent) {
      return <HeatmapEvent {...this.props.event} />;
    } if (this.props.event.isFollowUp) {
      return (
        <FollowUp
          {...this.props.event}
          events={this.props.events}
          handleDeleteClick={this.handleDeleteClick.bind(this)}
          handleEditClick={this.handleEditClick.bind(this)} />
      );
    }
    return (
      <Event
        {...this.props.event}
        handleDeleteClick={this.handleDeleteClick.bind(this)}
        handleEditClick={this.handleEditClick.bind(this)} />
    );
  }
};
