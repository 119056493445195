const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  planned: state.totalPlanned,
  invoiced: state.totalInvoiced,
  value: state.convertedEstimate,
  currencyCode: state.currency ? state.currency.code : null,
  isWaiting: state.waitingFor.length > 0,
  isSaving: state.waitingFor.filter((key) => key === 'save').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  changeMode: (mode) => dispatch(actions.changeMode(mode)),
  changeView: (view) => dispatch(actions.changeView(view)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
