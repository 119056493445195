const NodeTotal = require('./StructureNodeTotal');

module.exports = class JobTitleNodeTotal extends NodeTotal {
  /**
   * @param days
   * @param cost
   * @param price
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(days, cost, price) {
    super(days, cost, price);
  }

  toJson() {
    return super.toJson();
  }
};
