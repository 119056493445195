'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  /**
   * Widget sparkline production layout. Manages widget settings and creates the collection of
   * sparkline when rendered
   */
  this.WidgetSparklineProdLayout = Marionette.LayoutView.extend({
    _template: '#widgetSparklineProdLayout',
    className: 'widgetSparklineProd',
    ui: {
      settings: '[data-action="settingsWidget"]',
      settingsRegionEl: '[data-region="widgetSettings"]',
      saveEl: '[data-action="saveWidget"]',
      saveFeedbackEl: '[data-region="widgetSaveFeedback"]',
      deleteEl: '[data-action="deleteWidget"]',
      wrapTooltipHintEl: '[data-region="wrapFilterTooltipHint"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      inputHintEl: '[data-action="inputHint"]',
      mdlInput: '[data-region="mdl-input"]',
    },
    regions: {
      coll: '[data-region="sparklineProdList"]',
    },
    events: {
      'click @ui.saveEl': 'saveWidget',
      'touchstart @ui.saveEl': 'saveWidget',
      'click @ui.deleteEl': 'deleteWidget',
      'touchstart @ui.deleteEl': 'deleteWidget',
    },
    behaviors: {
      settingsWidget: {},
    },
    initialize: function (options) {
      // the options are used by dashboard/view.js
      this.options.filterType = options.model.get('config').active_filter.name || null;
      this.options.filterValue = options.model.get('config').active_filter.value || null;
      this.options.coll = new DashboardApp.WidgetSparklineProdColl(_.map(options.model.get('projects')));
      _.bindAll(this, 'template');
    },

    template: function (serializedModel) {
      return _.template($(this._template).html())(serializedModel);
    },

    onRender: function () {
      if (this.options.new) {
        this.ui.settingsRegionEl.addClass('isVisible');
      }

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      var sparklineColl = new DashboardApp
        .WidgetSparklineProdCollView({ collection: this.options.coll });
      this.coll.show(sparklineColl);
    },

    saveWidget: function () {
      if (this.ui.saveEl.hasClass('disabled')) {
        return;
      }

      this.ui.saveEl.addClass('disabled');
      this.ui.saveFeedbackEl.show();

      if (this.model.get('config').active_filter.name === this.options.filterType
        && this.model.get('config').active_filter.value === this.options.filterValue) {
        this.ui.settingsRegionEl.removeClass('isVisible');
        return;
      }

      this.model.get('config').active_filter.name = this.options.filterType;
      this.model.get('config').active_filter.value = this.options.filterValue;

      var options = {
        id: this.model.get('id'),
        filterType: this.options.filterType,
        filterValue: this.options.filterValue,
      };

      this.ui.deleteEl.hide();
      var saveWidgetRequest = Wethod.request('save:user:sparkline:widget', options);
      $.when(saveWidgetRequest).done(function () {
        var renderOptions = { endpoint: DashboardApp.getEndpoint(this.model) };
        var userWidgetRequest = Wethod.request('get:user:widget', renderOptions);
        $.when(userWidgetRequest).done(function (userWidgetResponse) {
          this.options.coll = new DashboardApp.WidgetSparklineProdColl(_.map(userWidgetResponse.get('projects')));
          var sparklineCollView = new DashboardApp
            .WidgetSparklineProdCollView({ collection: this.options.coll });
          this.coll.show(sparklineCollView);

          this.model.set({ label: userWidgetResponse.get('label') });
          this.ui.settingsRegionEl.removeClass('isVisible');
          this.ui.saveEl.removeClass('disabled');
          this.ui.saveFeedbackEl.hide();
          this.render();
        }.bind(this));
      }.bind(this));
    },

    deleteWidget: function (e) {
      e.preventDefault();
      if (!this.ui.deleteEl.hasClass('disabled')) {
        this.ui.deleteEl.addClass('disabled');
        this.ui.saveEl.hide();

        var deleteRequest = Wethod.request('delete:user:widget', { id: this.model.get('id') });
        $.when(deleteRequest).done(function () {
          // options.widgetView is set by dashboard-controller renderWidget()
          this.options.widgetView.destroy();
        }.bind(this));
      }
    },
  });

  /**
   * Get default options for sparkline single serie
   */
  var getSerieOptions = function () {
    return {
      type: 'spline',
      color: '#48a5f4',
      dataLabels: {
        useHTML: true,
        stacking: 'normal',
        overflow: 'none',
        crop: false,
        formatter: function () {
          return this.point.label;
        },
        x: 2,
        y: -1,
        align: 'left',
        verticalAlign: 'middle',
        style: {
          fontSize: '8px',
          color: '#878B8E',
          fontWeight: 'normal',
        },
      },
      marker: {
        radius: 0,
      },
    };
  };

  /**
   * Get default options for sparkline production widget
   */
  var getSparklineOptions = function () {
    return {
      chart: {
        backgroundColor: 'transparent',
        width: 330,
        height: 40,
        marginLeft: 190,
        marginRight: 35,

        spacingBottom: 5,
        spacingTop: 5,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        enabled: false,
        useHTML: true,
        floating: true,
        text: '',
        align: 'left',
        width: 140,
        x: 10,
        y: 14,
        style: {
          fontSize: '11px',
          color: '#878B8E',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      xAxis: {
        title: { text: null },
        tickPositions: [],
        endOnTick: false,
        startOnTick: false,
        lineColor: 'transparent',
        min: parseInt(moment().startOf('isoweek').subtract(9, 'w').format('X')),
        max: parseInt(moment().startOf('isoweek').subtract(1, 'w').format('X')),
      },
      yAxis: {
        min: 0.4,
        max: 1.6,
        title: { text: null },
        endOnTick: false,
        startOnTick: false,
        tickPositions: [],
        lineColor: 'transparent',
        plotLines: [{
          value: 1,
          width: 1,
          color: '#EBEEF1',
        }],
      },
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: { enabled: false },
    };
  };

  /**
   * A sparkline production. Manages series data and render sparkline chart
   */
  this.WidgetSparklineProdView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#widgetSparklineProdItem',
    className: 'widgetSparklineProd__list__item',
    ui: {
      chart: '[data-region="sparklineProdChart"]',
    },
    onRender: function () {
      var serie = [];
      var series = [];
      var red = '#DB4D69';
      var green = '#4ED88D';
      var lastWeek = moment().startOf('isoweek').subtract(1, 'w');

      _.each(this.model.get('weeks'), function (roadrunner, week) {
        var roundedRri = Math.round(roadrunner * 10) / 10;
        var limitedRri = Math.max(roundedRri, 0.5);
        limitedRri = Math.min(limitedRri, 1.5);

        var point = {
          x: moment(week).format('X'),
          date: moment(week).format('DD/MM/YY'),
        };

        if (!_.isNull(roadrunner)) {
          point.y = limitedRri;
          point.label = roundedRri;
        } else {
          point.y = null;
          point.label = '';
        }

        // If point is on last week show colored marker
        if (moment(week).format('X') === lastWeek.format('X')) {
          point.marker = {
            enabled: true,
            radius: 3,
            symbol: 'circle',
            fillColor: (roadrunner && roadrunner >= 1) ? green : red,
          };
          point.dataLabels = {
            enabled: true,
          };
        }

        if (!roadrunner) {
          if (serie.length) {
            var options = getSerieOptions();
            options.data = serie;
            series.push(options);
            serie = [];
          }
        } else {
          serie.push(point);
        }
      });

      // add last serie
      if (serie.length) {
        var options = getSerieOptions();
        options.data = serie;
        series.push(options);
      }

      // add an empty series to be sure plotline are always shown
      series.push({
        type: 'scatter',
        marker: { enabled: false },
        data: [],
      });

      var sparkLineOptions = getSparklineOptions();
      sparkLineOptions.title.text = this.model.get('name');
      sparkLineOptions.series = series;

      this.ui.chart.highcharts(sparkLineOptions);
    },
  });

  /**
   * Sparkline production empty view
   */
  this.WidgetSparklineProdEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#widgetSparklineProdEmpty',
  });

  /**
   * Sparkline production collection
   */
  this.WidgetSparklineProdCollView = Marionette.CollectionView.extend({
    className: 'widgetSparklineProd__list',
    tagName: 'ul',
    childView: DashboardApp.WidgetSparklineProdView,
    emptyView: DashboardApp.WidgetSparklineProdEmptyView,
  });
});
