const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/WonProjects.react');

const mapStateToProps = (state) => ({
  month: state.month,
  target: state.monthly_revenue_target, // NULLABLE
  projectsCount: state.month_won_projects_count,
  projectsValue: state.month_won_projects_value,
  trendOnLastYear: state.prev_year_won_projects_perc,
});

module.exports = connect(mapStateToProps)(Component);
