const React = require('react');
const PropTypes = require('prop-types');
const Input = require('../Input/Input.react');

const TextInput = ({
  value, id, className, placeholder, readOnly, required, disabled,
  autoComplete, maxLength, minLength, name, size, rootRef, ...rest
}) => (
  <Input type="text"
    value={value}
    id={id}
    className={className}
    placeholder={placeholder}
    readOnly={readOnly}
    required={required}
    disabled={disabled}
    autoComplete={autoComplete}
    maxLength={maxLength}
    minLength={minLength}
    name={name}
    size={size}
    rootRef={rootRef}
    {...rest} />
);

TextInput.defaultProps = {
  id: null,
  className: undefined,
  placeholder: undefined,
  readOnly: undefined,
  required: undefined,
  disabled: undefined,
  autoComplete: 'off',
  maxLength: undefined,
  minLength: undefined,
  size: undefined,
  rootRef: null,
  value: undefined,
  name: undefined,
};

TextInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  /** The maximum number of characters allowed * */
  maxLength: PropTypes.number,
  /** The minimum number of characters required * */
  minLength: PropTypes.number,
  /** The width, in characters * */
  size: PropTypes.number,
  /** Function to access to inptut DOM element
   * @param input {HTMLElement} DOM element ref
   * */
  rootRef: PropTypes.func,
};

module.exports = TextInput;
