const $ = require('jquery');
const request = require('./Request');

const TimeOff = {
  getAll(offset = 0, limit = 50, search = '', year) {
    let query = `?offset=${offset}`;
    query += `&limit=${limit}`;
    query += `&search=${search}`;
    query += `&year=${year}`;

    return request({
      method: 'get',
      url: `/timeoff/${query}`,
    });
  },
  update(year, employeeId, changes) {
    return request({
      method: 'patch',
      url: `/timeoff/${year}/employee/${employeeId}`,
      data: changes,
    });
  },
  export(year) {
    const query = `?year=${year}`;

    return request({
      url: `/export/timeoff${query}`,
    });
  },
  import(year, file) {
    const query = `?year=${year}`;

    const formData = new FormData();
    formData.append('import_file', file);
    const defer = $.Deferred();

    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: `${APIURL}/import/timeoff${query}`,
      // Tell jQuery not to process data or worry about content-type
      // You *must* include these options!
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code !== 200) {
        defer.reject(response.message, response.data, response);
      } else {
        defer.resolve(response.data);
      }
    }).fail(() => {
      // Unhandled error (status 500)
      defer.reject();
    });
    return defer.promise();
  },
};

module.exports = TimeOff;
