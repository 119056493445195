const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const FileSection = require('../../../../../../common/react/FileSection/FileSection.react');
const Empty = require('../../../../../../common/react/FileSection/Empty.react');
const FiscalYearService = require('../../../../../../services/FiscalYearService');

const accepted = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.apple.numbers',
];

module.exports = class ImportModal extends React.Component {
  constructor(props) {
    super(props);

    this.fiscalYearService = new FiscalYearService();

    this.state = {
      file: [],
    };
  }

  /**
   * Proceed with the import of the selected file
   */
  handleConfirm() {
    if (this.state.file.length) {
      this.props.import(this.props.data.year, this.state.file[0]);
    }
  }

  /**
   * Select the file used for the import
   * @param file
   */
  handleUpload(file) {
    const formattedFile = file;
    formattedFile.id = 9999;
    this.setState({ file: [formattedFile] });
  }

  handleUploadError(errors) {
    const error = errors && errors.length ? errors[0] : null;
    this.props.showCannotUploadFileModal(error);
  }

  /**
   * Remove the file selected for import
   */
  handleFileDelete() {
    this.setState({ file: [] });
  }

  render() {
    return (
      <Modal title="Import" onCancelClick={this.props.onClose} className="time-off__modal">
        <p>
          {/* eslint-disable-next-line max-len */}
          The year you selected for the import is: <b>{this.fiscalYearService.getYearDynamically(this.props.data.year)}</b>.<br />
        </p>
        <p>
          You can import vacation, leaves and target hours for your team,
          using the <a href="https://wethod-prod.s3.eu-west-1.amazonaws.com/templates/import-timeoff-template.xlsx">given template</a>.
        </p>
        <br />
        <FileSection title=""
          className="time-off__modal-file-section"
          canDownload={false}
          canUpload={!this.state.file.length}
          canDelete
          accept={accepted}
          empty={<Empty>Upload .xls or .xlxs files only.</Empty>}
          onDelete={this.handleFileDelete.bind(this)}
          files={this.state.file}
          onUpload={this.handleUpload.bind(this)}
          onUploadError={this.handleUploadError.bind(this)} />
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onClose} disabled={this.props.isWaiting}>Cancel</Button>
          <Button onClick={this.handleConfirm.bind(this)}
            disabled={this.props.isWaiting || !this.state.file.length}>
            Import
          </Button>
        </div>
        <ShowIf condition={this.props.isWaiting}>
          <div className="wethod-modal2__feedback">Uploading...</div>
        </ShowIf>
      </Modal>
    );
  }
};
