/* eslint-disable react/prop-types */
const React = require('react');
const TextInput = require('./TextInput.react');
const DayPicker = require('./DayCalendar.react');

/**
 * An input component with month calendar to pick up a date.
 *
 * PROPS
 * name: string
 * value: string
 * constraints: supported constraints equals to TextInput (email, required)
 * format: string representing the format of the date to be shown, default 'DD/MM/YYYY'
 * disableBeforeEqual
 *
 * onFocus
 * onChange
 * onBlur
 * onValidate
 *
 * @type {module.DatePicker}
 * @deprecated
 */
class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
    };
  }

  componentDidMount() {
    $(document).click();
  }

  handleFocus() {
    if (!this.state.showPicker) this.setState({ showPicker: true });

    if (this.props.onFocus) this.props.onFocus();
  }

  handleDayClick(day) {
    this.setState({ showPicker: false });
    this.props.onChange(this.props.name, moment(day).format());
  }

  getDate() {
    const format = this.props.format ? this.props.format : 'DD/MM/YYYY';
    return this.props.value
      ? moment(this.props.value).format(format)
      : '';
  }

  getPicker() {
    if (this.state.showPicker && !this.props.disabled) {
      return (
        <DayPicker startDate={this.props.value}
          disableBeforeEqual={this.props.disableBeforeEqual}
          disableWeekend={this.props.disableWeekend}
          disableBefore={this.props.disableBefore}
          onDayClick={this.handleDayClick.bind(this)} />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-date-picker">
        <TextInput name={this.props.name}
          disabled={this.props.disabled}
          value={this.getDate()}
          constraints={this.props.constraints}
          onFocus={this.handleFocus.bind(this)}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onValidate={this.props.onValidate} />
        {this.getPicker()}
      </div>
    );
  }
}

module.exports = DatePicker;
