const React = require('react');
const moment = require('moment');
const Typography = require('../../../../../../common/react/Typography/Typography.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

const HeaderMonth = ({ date }) => {
  const dateObject = moment(date);
  const isCurrentMonth = dateObject.isSame(new Date(), 'month');
  const isFirstMonthOfYear = dateObject.month() === 0;

  const month = dateObject.format('MMM');
  const year = dateObject.format('YYYY');

  const getMonthStyle = () => {
    let style = 'segment-timeline__header-month';
    if (isCurrentMonth) {
      style += ' segment-timeline__header-month--current';
    }
    return style;
  };

  return (
    <div className="segment-timeline__header-cell">
      <ShowIf condition={isFirstMonthOfYear}>
        <Typography size={Typography.SIZES.PX12}>{year}</Typography>
      </ShowIf>
      <div className={getMonthStyle()}>
        <Typography size={Typography.SIZES.PX12} capitalize>{month}</Typography>
      </div>
    </div>
  );
};

module.exports = HeaderMonth;
