const React = require('react');

const RadioFullIcon = () => (
  <svg className="wethod-radio__icon wethod-radio__icon--empty"
    focusable="false"
    viewBox="0 0 16 16">
    <path d="M8,3C5.2,3,3,5.2,3,8s2.2,5,5,5s5-2.2,5-5S10.8,3,8,3z" />
    <path
      d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z" />
  </svg>
);

module.exports = RadioFullIcon;
