const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const Menu = require('../../Menu/Menu.react');
const EventService = require('../../../../services/EventService');
const Calendar = require('../../DayCalendar.react');
const IconButton = require('../../Button/IconButton.react');

class DayPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      open: false,
    };

    this.onInputClick = this.onInputClick.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onInputClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onClose() {
    this.setState({ open: false });
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onDayClick(date, e) {
    const formattedDate = date != null ? moment(date).format() : undefined;

    const event = EventService.cloneEvent(e, {
      name: this.props.name,
      value: formattedDate,
    });

    this.setState({ value: event.target.value }, () => {
      this.validate(event.target.value);
      this.onParentChange(event);
      this.onClose();
    });
  }

  onClearClick(e) {
    this.onDayClick(undefined, e);
  }

  getFormattedValue() {
    const dateString = this.state.value;
    return dateString ? moment(dateString).format('DD/MM/YYYY') : '';
  }

  /**
   * Return the correct suffix to be shown:
   * the given suffix or the clear button when the input is resettable
   * @returns {JSX.Element|null|*}
   */
  getSuffix() {
    if (this.props.suffix) {
      return this.props.suffix;
    }

    if (this.hasResetButton()) {
      return (
        <IconButton icon="discard"
          size="small"
          color="black"
          label="reset-date"
          disabled={this.props.disabled}
          className="wethod-date-picker__reset wethod-icon-button--no-margin wethod-icon-button--no-border"
          onClick={this.onClearClick.bind(this)} />
      );
    }

    return null;
  }

  getButton() {
    const Button = this.props.button;

    return (
      <Button name={this.props.name}
        label={this.props.label}
        prefix={this.props.prefix}
        suffix={this.getSuffix()}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        value={this.getFormattedValue()}
        id={this.props.id}
        placeholder={this.props.placeholder}
        required={this.props.required}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        onClick={this.onInputClick}
        inputRef={this.setMenuAnchorRef} />
    );
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
      this.onClose();
    });
  }

  /**
   * Check whether the reset button should be shown.
   * The button is shown when the input is resettable and is not readonly.
   * @returns {*}
   */
  hasResetButton() {
    return this.props.resettable && !this.props.readOnly;
  }

  render() {
    return (
      <div className="wethod-date-picker">
        {this.getButton()}
        <Menu open={this.state.open} onClose={this.onClose} anchorEl={this.menuAnchorRef} bottom className="wethod-date-picker-menu">
          <Calendar firstDayOfWeek={this.props.firstDayOfWeek}
            initialMonth={this.props.initialMonth}
            className={this.props.className}
            startDate={this.props.value}
            disableBefore={this.props.disableBefore}
            disableBeforeEqual={this.props.disableBeforeEqual}
            disableWeekend={this.props.disableWeekend}
            modifiers={this.props.modifiers}
            onDayClick={this.onDayClick} />
        </Menu>
      </div>
    );
  }
}

DayPicker.defaultProps = {
  label: null,
  required: undefined,
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  validate: undefined,
  disabled: undefined,
  readOnly: undefined,
  onChange: null,
  placeholder: undefined,
  firstDayOfWeek: 1,
  className: '',
  initialMonth: undefined,
  value: undefined,
  disableBefore: undefined,
  disableBeforeEqual: undefined,
  disableWeekend: false,
  modifiers: {},
  resetErrors: null,
  resettable: false,
};

DayPicker.propTypes = {
  /** Class to istantiate as button * */
  button: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  /**
   * Function to call when a new value is selected.
   * @param e {SyntheticEvent}
   * @param item {{}} selected item props
   */
  onChange: PropTypes.func,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  /**
   * The first day of week to be shown in the calendar (sun=0, mon=1, .., sat=6)
   * default is set to 1 (monday).
   */
  firstDayOfWeek: PropTypes.number,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * The initial month shown in the calendar. If not specified, it's set to the month of the
   * startDate, or the current one.
   */
  initialMonth: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * The selected date shown in the calendar. If not specified, no date will be selected.
   */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * All days before this are disabled.
   */
  disableBefore: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * This day and all the ones before this are disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),

  /**
   * If set to true, Saturday and Sunday will be disabled in the calendar.
   */
  disableWeekend: PropTypes.bool,
  /**
   * The modifiers you want to use in style classes.
   * When a modifier matches a specific day, its day cells receives the modifier’s name as CSS
   * class.
   * @see react-day-picker
   */
  modifiers: PropTypes.shape({}),
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  /**
   * If set to true, the date can be cleared with a delete button.
   * The button is not shown when the input is readonly
   */
  resettable: PropTypes.bool,
};

module.exports = DayPicker;
