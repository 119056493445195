module.exports = {
  /**
   * Sort given levels using their "sort" attribute, from lower to higher.
   * @param levels
   * @return {[*]}
   */
  sort(levels) {
    return levels.sort((levelA, levelB) => levelA.sort - levelB.sort);
  },
};
