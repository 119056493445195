/* eslint-disable react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const Select2 = require('../Select2/Select2.react');

/**
 * Select component with live search.
 * The item will be filtered on the attribute given as 'param' prop;
 * if this prop is not given, the filter will be based on 'name' attribute
 * When no item is present a '+' button will appear on the side of the input:
 * the behaviour on click is customizable through a 'onCreateClick' prop.
 * If this prop is not present, the button will not be shown.
 * Pass an "onValidate" callback to get validation errors.
 * Supported constraints: same as TextInput.
 *
 * LIFECYCLE HOOKS
 * onChange: function called when an item of the list is selected
 * onCreateClick: function called when no item is present and the 'add' button is clicked
 * onValidate, onFocus, onBlur, onCreateClick.
 *
 * OTHER PROPS
 * - name
 * - value:     optional, text currently in search input
 * - param:     optional, the attribute of every item to be shown in search input and list and used
 * to filter, default name
 * - placeholder
 * - constraints
 * - current:   optional, currently selected item
 * - items:     the list of items to be filtered
 *
 * @type {module.SearchSelect2}
 */
module.exports = class SearchSelect2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      param: this.props.param ? this.props.param : 'name',
      filteredItems: this.props.items ? this.props.items : [],
    };
  }

  hasResults() {
    return this.props.items ? this.props.items.length > 0 : false;
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toString().toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Returns collection filtered by key.
   * @param collection
   * @param key
   */
  filterItems(collection, key) {
    return collection.filter((item) => this.found(key, item[this.state.param]));
  }

  handleFilter(name, keyword) {
    const results = this.hasResults()
      ? this.filterItems(this.props.items, keyword)
      : [];
    this.setState({ filteredItems: results });
  }

  render() {
    return (
      <Select2 name={this.props.name}
        placeholder={this.props.placeholder}
        param={this.props.param}
        constraints={this.props.constraints}
        current={this.props.current}
        items={this.state.filteredItems}
        onFilter={this.handleFilter.bind(this)}
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onCreateClick={this.props.onCreateClick} />
    );
  }
};
