const { connect } = require('react-redux');
const Component = require('../../components/dashboard/DataFreezingDashboard.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  selectedSegment: state.selectedSegment,
  totals: state.warningsTotals,
});

const mapDispatchToProps = (dispatch) => ({
  getTotals: (from, to) => dispatch(actions.getWarningsTotal(from, to)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
