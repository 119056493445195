module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

// TIMESHEET SETTINGS
module.exports.SET_TIMESHEET_STRATEGY_REQUEST = 'SET_TIMESHEET_STRATEGY_REQUEST';
module.exports.SET_TIMESHEET_STRATEGY_SUCCESS = 'SET_TIMESHEET_STRATEGY_SUCCESS';

module.exports.SET_TIMESHEET_FREQUENCY_REQUEST = 'SET_TIMESHEET_FREQUENCY_REQUEST';
module.exports.SET_TIMESHEET_FREQUENCY_SUCCESS = 'SET_TIMESHEET_FREQUENCY_SUCCESS';

module.exports.GET_TIMESHEET_TIME_LIMIT_REQUEST = 'GET_TIMESHEET_TIME_LIMIT_REQUEST';
module.exports.GET_TIMESHEET_TIME_LIMIT_SUCCESS = 'GET_TIMESHEET_TIME_LIMIT_SUCCESS';

module.exports.SET_TIMESHEET_TIME_LIMIT_REQUEST = 'SET_TIMESHEET_TIME_LIMIT_REQUEST';
module.exports.SET_TIMESHEET_TIME_LIMIT_SUCCESS = 'SET_TIMESHEET_TIME_LIMIT_SUCCESS';

module.exports.UPDATE_TIMESHEET_TIME_LIMIT_REQUEST = 'UPDATE_TIMESHEET_TIME_LIMIT_REQUEST';
module.exports.UPDATE_TIMESHEET_TIME_LIMIT_SUCCESS = 'UPDATE_TIMESHEET_TIME_LIMIT_SUCCESS';

module.exports.SET_TIMESHEET_DAILY_MODE_REQUEST = 'SET_TIMESHEET_DAILY_MODE_REQUEST';
module.exports.SET_TIMESHEET_DAILY_MODE_SUCCESS = 'SET_TIMESHEET_DAILY_MODE_SUCCESS';

// JOB ORDER TEMPLATES SETTINGS
module.exports.ENABLE_TEMPLATES_REQUEST = 'ENABLE_TEMPLATES_REQUEST';
module.exports.ENABLE_TEMPLATES_SUCCESS = 'ENABLE_TEMPLATES_SUCCESS';

module.exports.DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
module.exports.DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
module.exports.DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

module.exports.ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
module.exports.DELETE_NEW_TEMPLATE = 'DELETE_NEW_TEMPLATE';

module.exports.SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
module.exports.SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
module.exports.SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';

module.exports.GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
module.exports.GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';

module.exports.GET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST = 'GET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST';
module.exports.GET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS = 'GET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS';

module.exports.SET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST = 'SET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST';
module.exports.SET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS = 'SET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS';

module.exports.SAVE_JOB_TITLE_REQUEST = 'SAVE_JOB_TITLE_REQUEST';
module.exports.SAVE_JOB_TITLE_SUCCESS = 'SAVE_JOB_TITLE_SUCCESS';
module.exports.SAVE_JOB_TITLE_FAILURE = 'SAVE_JOB_TITLE_FAILURE';

module.exports.ADD_NEW_JOB_TITLE = 'ADD_NEW_JOB_TITLE';
module.exports.DELETE_NEW_JOB_TITLE = 'DELETE_NEW_JOB_TITLE';

module.exports.GET_JOB_TITLES_REQUEST = 'GET_JOB_TITLES_REQUEST';
module.exports.GET_JOB_TITLES_SUCCESS = 'GET_JOB_TITLES_SUCCESS';

module.exports.ADD_LEVEL = 'ADD_LEVEL';
module.exports.DELETE_LEVEL = 'DELETE_LEVEL';
module.exports.UPDATE_LEVEL = 'UPDATE_LEVEL';

module.exports.GET_FISCAL_YEAR_REQUEST = 'GET_FISCAL_YEAR_REQUEST';
module.exports.GET_FISCAL_YEAR_SUCCESS = 'GET_FISCAL_YEAR_SUCCESS';
module.exports.SET_FISCAL_YEAR_REQUEST = 'SET_FISCAL_YEAR_REQUEST';
module.exports.SET_FISCAL_YEAR_SUCCESS = 'SET_FISCAL_YEAR_SUCCESS';
module.exports.SET_FISCAL_YEAR_STATE = 'SET_FISCAL_YEAR_STATE';
module.exports.SET_PREVIOUS_FISCAL_YEAR_STATE = 'SET_PREVIOUS_FISCAL_YEAR_STATE';

// WORK HOUR CAPACITY SETTINGS

module.exports.SAVE_WORK_HOUR_CAPACITY_REQUEST = 'SAVE_WORK_HOUR_CAPACITY_REQUEST';
module.exports.SAVE_WORK_HOUR_CAPACITY_SUCCESS = 'SAVE_WORK_HOUR_CAPACITY_SUCCESS';
module.exports.SAVE_WORK_HOUR_CAPACITY_FAILURE = 'SAVE_WORK_HOUR_CAPACITY_FAILURE';

module.exports.ADD_NEW_WORK_HOUR_CAPACITY = 'ADD_NEW_WORK_HOUR_CAPACITY';
module.exports.DELETE_NEW_WORK_HOUR_CAPACITY = 'DELETE_NEW_WORK_HOUR_CAPACITY';

module.exports.GET_WORK_HOUR_CAPACITIES_REQUEST = 'GET_WORK_HOUR_CAPACITIES_REQUEST';
module.exports.GET_WORK_HOUR_CAPACITIES_SUCCESS = 'GET_WORK_HOUR_CAPACITIES_SUCCESS';

module.exports.DELETE_WORK_HOUR_CAPACITY_REQUEST = 'DELETE_WORK_HOUR_CAPACITY_REQUEST';
module.exports.DELETE_WORK_HOUR_CAPACITY_SUCCESS = 'DELETE_WORK_HOUR_CAPACITY_SUCCESS';
module.exports.DELETE_WORK_HOUR_CAPACITY_FAILURE = 'DELETE_WORK_HOUR_CAPACITY_FAILURE';

module.exports.SET_WORK_HOUR_CAPACITY_EMPLOYEES = 'SET_WORK_HOUR_CAPACITY_EMPLOYEES';

module.exports.DAILY_MODE_USER_CHOICE = 'user-choice';
module.exports.DAILY_MODE_ALWAYS_ON = 'force-daily';

module.exports.GET_EMPLOYEE_TAGS_REQUEST = 'GET_EMPLOYEE_TAGS_REQUEST';
module.exports.GET_EMPLOYEE_TAGS_SUCCESS = 'GET_EMPLOYEE_TAGS_SUCCESS';
module.exports.SET_EMPLOYEE_TAGS_REQUEST = 'SET_EMPLOYEE_TAGS_REQUEST';
module.exports.SET_EMPLOYEE_TAGS_SUCCESS = 'SET_EMPLOYEE_TAGS_SUCCESS';

module.exports.SET_EMPLOYEE_TAGS_STATE = 'SET_EMPLOYEE_TAGS_STATE';
module.exports.SET_PREVIOUS_EMPLOYEE_TAGS_STATE = 'SET_EMPLOYEE_TAGS_STATE';
module.exports.SET_EMPLOYEE_TAGS_CATEGORY = 'SET_EMPLOYEE_TAGS_CATEGORY';
module.exports.SET_EMPLOYEE_TAGS_CATEGORY_NAME = 'SET_EMPLOYEE_TAGS_CATEGORY_NAME';
module.exports.ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
module.exports.DELETE_CATEGORY = 'DELETE_CATEGORY';
module.exports.DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
module.exports.SET_EMPLOYEE_TAGS_DELETE = 'SET_EMPLOYEE_TAGS_DELETE';
module.exports.SET_EMPLOYEE_TAGS_DELETE_COMPLETE = 'SET_EMPLOYEE_TAGS_DELETE_COMPLETE';
module.exports.SET_EMPLOYEE_TAGS_DELETE_SUCCESS = 'SET_EMPLOYEE_TAGS_DELETE_SUCCESS';
module.exports.SET_EMPLOYEE_TAGS_ITEMS = 'SET_EMPLOYEE_TAGS_ITEMS';
module.exports.SET_EMPLOYEE_TAG_NEW_SUCCESS = 'SET_EMPLOYEE_TAG_NEW_SUCCESS';
module.exports.DELETE_TAG = 'DELETE_TAG';
module.exports.SET_NEW_EMPLOYEE_TAGS = 'SET_NEW_EMPLOYEE_TAGS';
module.exports.SET_EMPLOYEE_TAGS_UPDATE_SUCCESS = 'SET_EMPLOYEE_TAGS_UPDATE_SUCCESS';

module.exports.SET_EMPLOYEE_TAG_CATEGORY_SAVING = 'SET_EMPLOYEE_TAG_CATEGORY_SAVING';
module.exports.SET_EMPLOYEE_TAGS_ITEM_SAVING = 'SET_EMPLOYEE_TAGS_ITEM_SAVING';

module.exports.GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
module.exports.GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

module.exports.ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';
module.exports.DELETE_NEW_LOCATION = 'DELETE_NEW_LOCATION';

module.exports.SAVE_LOCATION_REQUEST = 'SAVE_LOCATION_REQUEST';
module.exports.SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
module.exports.SAVE_LOCATION_FAILURE = 'SAVE_LOCATION_FAILURE';

module.exports.DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
module.exports.DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
module.exports.DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

// COMPANY HOLIDAYS SETTINGS
module.exports.SAVE_COMPANY_HOLIDAY_REQUEST = 'SAVE_COMPANY_HOLIDAY_REQUEST';
module.exports.SAVE_COMPANY_HOLIDAY_SUCCESS = 'SAVE_COMPANY_HOLIDAY_SUCCESS';
module.exports.SAVE_COMPANY_HOLIDAY_FAILURE = 'SAVE_COMPANY_HOLIDAY_FAILURE';

module.exports.ADD_NEW_COMPANY_HOLIDAY = 'ADD_NEW_COMPANY_HOLIDAY';
module.exports.DELETE_NEW_COMPANY_HOLIDAY = 'DELETE_NEW_COMPANY_HOLIDAY';

module.exports.GET_COMPANY_HOLIDAYS_REQUEST = 'GET_COMPANY_HOLIDAYS_REQUEST';
module.exports.GET_COMPANY_HOLIDAYS_SUCCESS = 'GET_COMPANY_HOLIDAYS_SUCCESS';

module.exports.DELETE_COMPANY_HOLIDAY_REQUEST = 'DELETE_COMPANY_HOLIDAY_REQUEST';
module.exports.DELETE_COMPANY_HOLIDAY_SUCCESS = 'DELETE_COMPANY_HOLIDAY_SUCCESS';
module.exports.DELETE_COMPANY_HOLIDAY_FAILURE = 'DELETE_WORK_HOUR_CAPACITY_FAILURE';
