const React = require('react');
const PropTypes = require('prop-types');
const Avatar = require('../../../../../../common/react/AvatarBox/Avatar.react');

const EmployeeDetails = ({ pic, name, level, tags }) => {
  const getTags = () => {
    if (tags) {
      return tags.map((tag, index) => {
        let tagName = tag.name;
        if (index >= 1) tagName = `| ${tag.name}`;
        return (
          <span key={tag.id}>{tagName}</span>
        );
      });
    }
    return null;
  };

  return (
    <div className="timesheet__employee">
      <div className="timesheet__employee-pic">
        <Avatar pic={pic} name={name} size="medium" />
      </div>
      <div className="timesheet__employee-info">
        <span>{name}</span>
        <span>{getTags()}</span>
        <span>{level}</span>
      </div>
    </div>
  );
};

EmployeeDetails.propTypes = {
  /**
   * Link to the picture of the employee
   */
  pic: PropTypes.string,
  /**
   * Name of the employee: format 'Name Surname'
   */
  name: PropTypes.string.isRequired,
  /**
   * Short name of the level of the employee
   */
  level: PropTypes.string.isRequired,
  /**
   * List of tags associated to the employee
   */
  tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};

EmployeeDetails.defaultProps = {
  pic: undefined,
  tags: undefined,
};

module.exports = EmployeeDetails;
