const React = require('react');
const Notes = require('../containers/NoteModal');
const ConfirmDelete = require('../containers/ConfirmDeleteModal');

const Modal = ({ toShow, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'notes':
        return <Notes onCancelClick={closeModal} />;
      case 'confirm-delete':
        return <ConfirmDelete onCancelClick={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
