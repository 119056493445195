module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.GET_EXPENSES_REQUEST = 'GET_EXPENSES_REQUEST';
module.exports.GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';

module.exports.DELETE_EXPENSES_REQUEST = 'DELETE_EXPENSES_REQUEST';
module.exports.DELETE_EXPENSES_SUCCESS = 'DELETE_EXPENSES_SUCCESS';
module.exports.DELETE_EXPENSES_FAILURE = 'DELETE_EXPENSES_FAILURE';

module.exports.UPDATE_EXPENSES_REQUEST = 'UPDATE_EXPENSES_REQUEST';
module.exports.UPDATE_EXPENSES_SUCCESS = 'UPDATE_EXPENSES_SUCCESS';

module.exports.CREATE_EXPENSES_REQUEST = 'CREATE_EXPENSES_REQUEST';
module.exports.CREATE_EXPENSES_SUCCESS = 'CREATE_EXPENSES_SUCCESS';

module.exports.EXPORT_REQUEST = 'EXPORT_REQUEST';
module.exports.EXPORT_SUCCESS = 'EXPORT_SUCCESS';

module.exports.DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';

module.exports.UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
