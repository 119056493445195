const React = require('react');
const View = require('./ListHeaderView.react');
const Edit = require('./ListHeaderEdit.react');

module.exports = ({ canEdit, color, ...rest }) => {
  const nameStyle = { backgroundColor: color };

  if (canEdit) {
    return <Edit nameStyle={nameStyle} {...rest} />;
  }
  return <View nameStyle={nameStyle} {...rest} />;
};
