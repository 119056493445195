const { connect } = require('react-redux');
const Component = require('../components/Snackbar.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  show: state.showSnackbar,
  data: state.snackbarData,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSnackbar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
