/* eslint-disable backbone/event-scope */

'use strict';

Wethod.module('FinanceApp.Invoice', function (Invoice, Wethod, Backbone, Marionette, $, _) {
  var parse = function (response) {
    var data = (response.data) ? response.data : response;
    return response.code === 404 ? [] : data;
  };
  var sync = function (method, model, options) {
    if (!options.crossDomain) {
      options.crossDomain = true;
    }

    if (!options.xhrFields) {
      options.xhrFields = { withCredentials: true };
    }

    _.each(this._requestPool, function (request) {
      if (request.readyState !== 4) {
        request.abort();
      }
    });

    if (method === 'read') {
      var xhr = proxiedSync(method, model, options);
      this._requestPool.push(xhr);
      return xhr;
    }
    return proxiedSync(method, model, options);
  };
  // quando si modifica questo modello controllare in Dashbord/invoice/invoice-view che l'oggetto
  // rispecchi questa struttura
  this.InvoiceModel = Backbone.Model.extend({
    defaults: function () {
      return {
        id: null,
        value: null,
        status: 0,
        note: null,
        date: moment().format() || null,
        invoice_number: null,
        invoice_number_automatic: false,
        export_enabled: false,
        id_project: null,
        id_client: null,
        id_area: null,
        budget_areas: [],
        area: {
          uid: null,
          name: null,
        },
        client: {
          id: null,
          corporate_name: null,
          acronym: null,
        },
        change: null, // value's conversion obtained by using currency's rate
        exchange_rate: null,
        currency: null,
        project: {
          id: null,
          name: null,
          job_order: null,
          pm: {
            id: null,
            pm_name: '',
            pm_surname: '',
          },
          account: {
            id: null,
          },
        },
        has_stamp_tax: false,
        files: [],
      };
    },
    initialize: function (options) {
      this.options = options || {};
      this.set('id_project', this.get('project').id);
      this.set('id_client', this.get('client').id);
      // Set flag invoice number auto when available
      if (Wethod.FinanceApp.isInvoiceNumberAutomatic != null) {
        this.set('invoice_number_automatic', Wethod.FinanceApp.isInvoiceNumberAutomatic);
      }
      // Set flag export enabled when available
      if (Wethod.FinanceApp.isExportEnabled != null) {
        this.set('export_enabled', Wethod.FinanceApp.isExportEnabled);
      }
      this.on('change:id_project', this.onChangeProject);
      this.on('change:currency', this.onCurrencyChange);
      this.on('change:exchange_rate', this.onChangeManualRate);
      this.on('change:change', this.onUpdateChange);
      this.updateChange();
    },
    urlRoot: APIURL + '/invoiceboard/',
    parse: parse,
    onUpdateChange: function () {
      this.updateValue();
    },
    onChangeManualRate: function () {
      this.updateValue();
    },
    onChangeProject: function () {
      this.get('area').uid = null;
      this.get('area').name = null;
      this.set('id_area', null);
    },
    updateChange: function () {
      var rate = this.getExchangeRate();
      var change = this.get('value') * rate;
      this.set('change', change);
    },
    updateValue: function () {
      var rate = this.getExchangeRate();
      var value = this.get('change') / rate;
      this.set('value', value);
    },
    onChangeValue: function () {
      this.updateValue();
    },
    onCurrencyChange: function () {
      this.updateValue();
    },
    getExchangeRate: function () {
      var rate = this.get('exchange_rate');
      if (rate === null) {
        var currency = this.get('currency');
        if (currency !== null) {
          rate = currency.rate;
        } else {
          rate = 1;
        }
      }
      return rate;
    },
    /**
     * Original Backbone method to override Backbone Validation behaviour.
     * @param options
     * @returns {*|boolean}
     */
    isValid: function (options) {
      return this._validate({}, _.defaults({ validate: true }, options));
    },
    validate: function (attrs) {
      var errors = [];

      if (_.isNull(attrs.id_project)) {
        errors.push('The Project must be NOT empty');
      }
      if (_.isNull(attrs.date)) {
        errors.push('The Date must be NOT empty');
      }
      if (_.isNull(attrs.value)) {
        errors.push('The Order Value must be NOT empty');
      }

      return (errors.length > 0) ? errors : false;
    },
  });

  this.InvoiceCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    model: Invoice.InvoiceModel,
    _limit: 30,
    _offset: 0,
    _orderBy: 'date',
    _sort: 'desc',
    _search: null,
    _requestPool: [],
    parse: parse,
    url: function () {
      var url = APIURL + '/invoiceboard/';
      url += '?limit=' + this._limit + '&offset=' + this._offset;
      url = this._search ? url + '&search=' + this._search : url;
      url += '&orderBy=' + this._orderBy + '&sort=' + this._sort;
      this._offset += this._limit;
      return url;
    },
    sync: sync,
    getNextPage: function (search, orderBy, sort) {
      if (search) {
        this._search = search.replace(/ /g, ','); // white space to comma
      }
      if (orderBy) {
        this._orderBy = orderBy;
      }
      if (sort) {
        this._sort = sort;
      }

      var defer = $.Deferred();

      this.fetch({
        success: function (models, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(models);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        remove: false,
      });
      return defer.promise();
    },
  });

  this.ModalModel = Backbone.Model.extend({});

  this.AreasCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/areaboard/project/' + this.options.projectId;
    },
  });

  this.AutomaticInvoiceNumberModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/automatic-invoice-number',
  });

  this.ExportXmlEnabledModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authorization/settings/fpa-export',
  });

  var API = {
    getInvoiceList: function () {
      var collection = new Invoice.InvoiceCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveInvoice: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(response.message);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteInvoice: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(response.message);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    sendInvoice: function (model, contactId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/invoiceboard/' + model.get('id') + '/send',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify({
          contact_id: contactId,
        }),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    setPaidInvoice: function (model) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/invoiceboard/' + model.get('id') + '/paid',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    getProjectAreas: function (projectId) {
      var collection = new Invoice.AreasCollection({ projectId: projectId });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getAutomaticInvoiceNumber: function () {
      var model = new Invoice.AutomaticInvoiceNumberModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getExportXmlEnabled: function () {
      var model = new Invoice.ExportXmlEnabledModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:finance:invoice:list', function () {
    return API.getInvoiceList();
  });
  Wethod.reqres.setHandler('get:finance:invoice:project:areas', function (projectId) {
    return API.getProjectAreas(projectId);
  });
  Wethod.reqres.setHandler('get:invoices:coll', function (coll, search, orderBy, sort) {
    coll = !coll ? new Invoice.InvoiceCollection() : coll; // if no coll given init new one
    return coll.getNextPage(search, orderBy, sort);
  });
  Wethod.reqres.setHandler('save:finance:invoice', function (model) {
    return API.saveInvoice(model);
  });
  Wethod.reqres.setHandler('delete:finance:invoice', function (model) {
    return API.deleteInvoice(model);
  });
  Wethod.reqres.setHandler('send:finance:invoice', function (model, contactId) {
    return API.sendInvoice(model, contactId);
  });
  Wethod.reqres.setHandler('paid:finance:invoice', function (model) {
    return API.setPaidInvoice(model);
  });
  Wethod.reqres.setHandler('get:finance:invoice:automatic:number', function () {
    return API.getAutomaticInvoiceNumber();
  });
  Wethod.reqres.setHandler('get:finance:invoice:export:enabled', function () {
    return API.getExportXmlEnabled();
  });
});
