const React = require('react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

const ItemSidebarActions = ({ onDelete }) => (
  <div>
    <IconButtonTooltip label="Delete item" onClick={onDelete} borderless>
      <span className="wethod-icon-delete wethod-icon-delete--black" />
    </IconButtonTooltip>
  </div>
);

module.exports = ItemSidebarActions;
