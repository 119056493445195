const { connect } = require('react-redux');
const Component = require('../../components/sidebar/BudgetTemplateSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  template: state.focusedTemplate,
  isSaving: state.waitingFor.filter((key) => key === 'save-budget-template' || key === 'delete-budget-template').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  closeSidebar: () => dispatch(actions.closeSidebar()),
  updateTemplate: (template) => dispatch(actions.updateTemplate(template)),
  deleteTemplate: (template) => dispatch(actions.deleteTemplate(template)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
