const React = require('react');
const PropTypes = require('prop-types');
const TableCell = require('./TableCell.react');

const FeedbackTableCell = ({ children, ...rest }) => {
  if (typeof children !== 'string') {
    throw new TypeError('children must be a string');
  }
  return <TableCell className="wethod-table__cell--feedback" {...rest}>{children}</TableCell>;
};

FeedbackTableCell.defaultProps = {
  children: null,
};

FeedbackTableCell.propTypes = {
  /**
   * Text to show as feedback
   */
  children: PropTypes.node,
};

module.exports = FeedbackTableCell;
