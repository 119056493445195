/* eslint-disable react/sort-comp */
const React = require('react');
const TextArea = require('../../../../../../../common/react/material-input/SidebarTextArea.react');

module.exports = class TextAreaModalBodyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.summaryCharLimit = 300;

    this.state = {
      summary: this.props.summary,
      description: this.props.description,
      summaryCharLeft: this.getSummaryCharLeft(this.props.summary),
    };
  }

  componentDidUpdate(prevProps) {
    const saved = prevProps.isSaving && !this.props.isSaving;
    if (saved) {
      this.props.toggleEditor();
    }
  }

  getSummaryCharLeft(summary) {
    return this.summaryCharLimit - summary.length;
  }

  onChangeSummary(name, value) {
    const summary = value.substring(0, this.summaryCharLimit);
    this.setState({ summary, summaryCharLeft: this.getSummaryCharLeft(summary) });
  }

  onChangeDescription(name, value) {
    this.setState({ description: value });
  }

  save() {
    const summary = this.state.summary.trim();
    const description = this.state.description.trim();

    const brief = { summary, description };
    this.props.save(brief, this.props.projectId);
  }

  getFeedback() {
    if (this.props.isSaving) return <div className="profile-contact-info__feedback">Saving...</div>;
    return null;
  }

  getCounterStyle() {
    const left = this.state.summaryCharLeft;
    let color = '#CCCBCC';
    if (left === 0) {
      color = 'rgb(219, 77, 105)';
    } else if (left < 50) {
      color = 'rgb(255, 185, 72)';
    }

    return { color };
  }

  getActionsClassName() {
    let name = 'project-canvas__text-area-modal-actions';
    if (this.props.isSaving) {
      name += ' project-canvas__text-area-modal-actions--disabled';
    }
    return name;
  }

  render() {
    return (
      <div className="project-canvas__text-area-modal">
        <div className="project-canvas__text-area-modal-content">
          <div
            className="project-canvas__text-area-modal-content-input project-canvas__text-area-modal-content-input--summary">
            <TextArea
              maxLength={300}
              rows={4}
              name="summary"
              value={this.state.summary}
              placeholder="Summary"
              onChange={this.onChangeSummary.bind(this)} />
            <div className="project-canvas__text-area-modal-content-input-counter" style={this.getCounterStyle()}>
              {this.state.summaryCharLeft}
              {' '}
              characters left
            </div>
          </div>
          <div
            className="project-canvas__text-area-modal-content-input project-canvas__text-area-modal-content-input--description">
            <TextArea
              name="description"
              value={this.state.description}
              placeholder="Description"
              onChange={this.onChangeDescription.bind(this)} />
          </div>
        </div>
        <div className={this.getActionsClassName()}>
          <button type="button" onClick={this.props.toggleEditor} className="wethod-button">Cancel</button>
          <button type="button" onClick={this.save.bind(this)} className="wethod-button">Save</button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
