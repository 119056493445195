/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,array-callback-return */
const React = require('react');
const Projects = require('./components/Projects.react');
const Header = require('./components/Header.react');
const Modal = require('./components/Modal.react');
const Actions = require('./components/Actions.react');
const Heatmap = require('./services/Heatmap');

class PlanningProjects extends React.Component {
  constructor(props) {
    super(props);

    dispatcher.on('app:banner:closed', this.placeFixedHeader);

    this.state = {
      days: this.props.projects.days, // header days (array)
      projects: this.props.projects.projects, // employees that are not tammates (object / associative array)
      isLoading: false, // the state where the planning is loading
      showModal: false,
      modalTitle: 'oops!',
      modalBody: '',
    };

    this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);
    this.toggleProjectVisibility = this.toggleProjectVisibility.bind(this);
    this.searchProjects = this.searchProjects.bind(this);
    this.eventAdded = this.eventAdded.bind(this);
    this.eventDeleted = this.eventDeleted.bind(this);
  }

  placeFixedHeader() {
    Wethod.placeFixedHeader('.top', '.planning__content--projects');
  }

  /**
   * Scrolls planning (calendar + header) of the given amount of pixel. scrollLeft follows the same conventions
   * of jQuery scrollLeft (https://api.jquery.com/scrollleft/).
   * @param scrollLeft
   */
  scroll(scrollLeft) {
    this.planningContentEl.animate({ scrollLeft }, 500);
    this.companionScroll(this.planningContentEl, this.headerEl);
  }

  /**
   * If the starterEl is horizontally scrolled, the targetEl will be scrolled accordingly.
   * This way you can keep aligned two horizontally scrollable elements.
   *
   * @param starterEl
   * @param targetEl
   */
  companionScroll(starterEl, targetEl) {
    targetEl.scrollLeft(starterEl.scrollLeft());
  }

  /**
   * Triggered by planning's content's horizontal scroll.
   */
  handleHorizontalScroll() {
    this.companionScroll(this.planningContentEl, this.headerEl);
  }

  componentDidMount() {
    this.setProjectsContainerHeight();
    this.placeFixedHeader();

    this.headerEl = $('.planning__header');
    this.planningContentEl = $(this.planningContent);

    // real width is 217.5 and has 1px of border, jquery width gets 218 so we should add only half pixel border
    this.weekWidth = $('.employee__days').first().width() + 0.5;
    this.planningContentEl.scroll(this.handleHorizontalScroll);

    // Add 'click' handler to app body
    $('body').on('click', (event) => {
      if ($(event.target).closest('.project__day').length === 0) {
        dispatcher.trigger('heatmap:close:bubbles');
      }
    });
  }

  setProjectsContainerHeight() {
    const fromTop = +$('.planning__content--projects')
      .offset().top + +$('.planning__content--projects').css('padding-top').replace('px', '');
    const bodyHeight = $('body').height();
    const scrollBarHeight = 16;
    const paddingTop = 140;
    const height = bodyHeight - fromTop + scrollBarHeight + paddingTop;
    $('.planning__content--projects').css('min-height', `${height}px`);
  }

  // Remove 'click' handler to app body
  componentWillUnmount() {
    $('body').off('click'); // this is not workging, componentWillUnmount() is never called
  }

  /**
   * Toggle project.visibility attributes and update the heatmap
   * @param {integer} projectId
   */
  toggleProjectVisibility(projectId) {
    let { projects } = this.state;
    const index = projects.findIndex((project) => project.id === projectId);
    projects[index].visibility = projects[index].visibility !== undefined
      ? !projects[index].visibility : false;
    projects = Heatmap.updateHeatMap(projects);
    this.setState({ projects });
  }

  /**
   * Filter projects by 'search' string and update the heatmap
   * @param {string} search
   */
  searchProjects(search) {
    Wethod.Utility.withDelay(() => {
      let { projects } = this.props.projects;
      const keys = search.toLowerCase().split(' ');

      projects.forEach((project) => {
        let found = true;
        keys.map((key) => {
          const concat = [project.name, project.client.name, project.client.acronym, project.type.name].join(' ');
          found = found && concat.toLowerCase().includes(key);
        });
        project.removed = !found;
      });

      projects = Heatmap.updateHeatMap(projects);
      this.setState({ projects });
    }, 250);
  }

  /**
   * When an event is created or updated keep the global projects list synched
   * @param event
   */
  eventAdded(event) {
    const { projects } = this.state;
    const projectIndex = projects.findIndex((project) => project.id === event.project);
    const { days } = projects[projectIndex];
    const dayIndex = days.findIndex((day) => day.date === event.date);
    days[dayIndex].event = event;
    this.setState({ projects });
  }

  /**
   * When an event is deleted remove it fromt he global projects list to mantein synch
   * @param event
   */
  eventDeleted(projectId, date) {
    const { projects } = this.state;
    const projectIndex = projects.findIndex((project) => project.id === projectId);
    const { days } = projects[projectIndex];
    const dayIndex = days.findIndex((day) => day.date === date);
    days[dayIndex].event = null;
    this.setState({ projects });
  }

  render() {
    return (
      <div className="section">
        <div className="top">
          <Actions searchWith={this.searchProjects} />
          <Header isLoading={this.state.isLoading}
            companionScroll={this.companionScroll}
            days={this.state.days} />
        </div>
        <div
          ref={(content) => {
            this.planningContent = content;
          }}
          className="planning__content--projects">
          <Projects
            projects={this.state.projects}
            toggleVisibility={this.toggleProjectVisibility}
            eventTypes={this.props.event_types}
            eventAdded={this.eventAdded}
            eventDeleted={this.eventDeleted} />
        </div>
        <Modal
          show={this.state.showModal}
          title={this.state.modalTitle}
          body={this.state.modalBody}
          handleClose={this.handleCloseModal} />
      </div>
    );
  }
}

module.exports = PlanningProjects;
