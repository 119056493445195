const React = require('react');
const View = require('./HeadCellView.react');
const Edit = require('./HeadCellEdit.react');

module.exports = ({
  canEdit, editName, onDeleteClick, isDeleting, isActivityDefault, updateActivityAttribute, ...rest
}) => {
  if (canEdit) {
    return (
      <Edit editName={editName}
        onDeleteClick={onDeleteClick}
        isDeleting={isDeleting}
        isActivityDefault={isActivityDefault}
        updateActivityAttribute={updateActivityAttribute}
        {...rest} />
    );
  }
  return <View {...rest} />;
};
