const React = require('react');

module.exports = function DailyCostPriceLevelModalRow(props) {
  return (
    <div className="wethod-budget-header__pricelist__container">
      <div className="wethod-budget-header__pricelist__name">
        {props.item.price_list.name}
      </div>
      <div className="wethod-budget-header__pricelist__price">
        Daily Cost: {props.item.cost} - Daily price: {props.item.price}
      </div>
    </div>
  );
};
