const React = require('react');
const Delta = require('../../../containers/Calculator/CellDelta');

const RowLabel = ({ children }) => (
  <Delta className="wethod-budget-calculator__cell-delta--task-label">
    {children}
  </Delta>
);

module.exports = RowLabel;
