const React = require('react');

const CurrencyManager = ({ children, ...rest }) => {
  /**
   * Difference between actual invoiced and initially expected one:
   * invoice in master - invoice expected in master (inv currency / rate initial)
   */
  // function getDeltaInvoiceInMaster(invoice, initialRate) {
  //   const invoiceValue = invoice.value;
  //   const invoiceRate = invoice.rate || initialRate;
  //   const invoiceValueInCurrency = invoiceValue * invoiceRate;
  //
  //   // Value initially expected for the invoice, considering the initial rate of the budget
  //   const expectedInvoiceValue = invoiceValueInCurrency / initialRate;
  //
  //   // Difference between the actual invoice value and the initially expected one
  //   const deltaInvoice = invoiceValue - expectedInvoiceValue;
  //
  //   // Ignore errors lower than cents
  //   return Math.abs(deltaInvoice) > 0.01 ? deltaInvoice : 0;
  // }

  /**
   * Calculate the rate of the plan of a month,
   * given the planned value in currency
   * and the list of invoices of the month.
   *
   * The resulting rate considers the manual rates used in the invoices.
   *
   * @param {array} invoices // List of invoices of the month
   * @param {number} planInCurrency // Amount planned for the month
   * @param {number} initialRate // Initial rate of the budget
   * @returns {number}
   */
  // function calcInvoicePlanRate(invoices = [], planInCurrency = 0, initialRate = 1) {
  //   // Total planned in master with initial rate
  //   const planInMaster = planInCurrency / initialRate;
  //
  //   /**
  //    * Delta between the value (in master) actually received in invoices (considering manual rate)
  //    * and the value expected with the initial rate.
  //    * @type {*}
  //    */
  //   const deltaInvoicedInMaster = invoices.reduce((tot, invoice) => {
  //     const delta = getDeltaInvoiceInMaster(invoice, initialRate);
  //     return tot + delta;
  //   }, 0);
  //
  //   /**
  //    * Planned value in master with the delta invoiced
  //    * This is the actual value in master we expect to invoice on that month
  //    */
  //   const planInMasterRevalued = planInMaster + deltaInvoicedInMaster;
  //
  //   /**
  //    * Plan rate.
  //    * This is the revalued rate of the month, considering the invoices with manual rate.
  //    * @type {number}
  //    */
  //   return planInCurrency / planInMasterRevalued;
  // }

  function calcInvoicePlanRate(invoices, value) {
    const totalMasterCurrency = invoices.reduce((tot, invoice) => tot + invoice.value, 0);
    return value / totalMasterCurrency;
  }
  /**
   * Get the correct rate used for conversions:
   * - manual rate is applied when rate is given
   * - budget rate is applied when multicurrency is enabled for the budget
   * - neutral rate is applied when multicurrency is not enabled for the budget
   * @param rate
   * @param currency
   * @returns {number}
   */
  function getRate(rate, currency) {
    if (rate) {
      return rate;
    }
    if (currency) {
      return currency.exchange_rate;
    }

    return 1;
  }

  /**
   * Convert the given value in the given currency.
   * The rate used for the conversion is the given one or the default one.
   * @param value
   * @param rate
   * @param currency
   * @returns {number}
   */
  function convertToCurrency(value, rate, currency) {
    return value * getRate(rate, currency);
  }

  /**
   * Convert the given value (expressed in given currency) in master currency.
   * The rate used for the conversion is the given one or the default one.
   * @param value
   * @param rate
   * @param currency
   * @returns {number}
   */
  function convertToMaster(value, rate, currency) {
    return value / getRate(rate, currency);
  }

  /**
   * Convert K value to unit value
   * @param kValue
   * @returns {number|number}
   */
  function convertToUnit(kValue) {
    return kValue ? kValue * 1000 : 0;
  }

  /**
   * Convert unit value to K value
   * @param value
   * @returns {number}
   */
  function convertToK(value) {
    const parsedValue = Number.parseFloat(value);
    if (Number.isNaN(parsedValue)) {
      return 0;
    }
    return parsedValue / 1000;
  }

  return React.cloneElement(children, {
    ...rest,
    convertToCurrency,
    convertToMaster,
    convertToUnit,
    convertToK,
    calcInvoicePlanRate,
  });
};

module.exports = CurrencyManager;
