const React = require('react');

module.exports = class BioBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: this.props.bio,
    };
  }

  onChange(e) {
    this.setState({ bio: e.target.value });
  }

  onSaveClick() {
    this.props.editInfo({ ...this.props.contactInfo, bio: this.state.bio, id: this.props.id });
  }

  getFeedback() {
    if (this.props.waiting) return <div className="profile-contact-info__feedback">Saving...</div>;
    return null;
  }

  render() {
    return (
      <div className="profile-bio profile-bio--editable">
        <textarea onChange={this.onChange.bind(this)}
          name="bio"
          placeholder="Add some words about you"
          defaultValue={this.state.bio} />
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onSaveClick.bind(this)} className="wethod-button">Save</button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
