'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $, _) {
  this.TerminalView = Marionette.LayoutView.extend({
    /**
     *  _leftRegionView : shorthand for the left AddButtonView attached to this TerminalView.
     *  _rightRegionView : shorthand for the right AddButtonView attached to this TerminalView.
     *  _operatorRegionView : shorthand for the left SelectView attached to this TerminalView.
     *  _dependencies : the rule's dependencies.
     */
    _leftRegionView: null,
    _rightRegionView: null,
    _operatorRegionView: null,
    _dependencies: null,
    className: 'terminal-wrapper',
    template: '#terminalConditionTemplate',
    regions: {
      leftRegion: '[data-action="left"]',
      operatorRegion: '[data-action="operator"]',
      rightRegion: '[data-action="right"]',
    },
    initialize: function () {
      this._dependencies = this.model.get('dependencies');
    },
    onRender: function () {
      var availableLeftOperands = new RuleManager.TooltipOptionCollection(this._dependencies);
      this.attachLeftOperand(availableLeftOperands);
    },
    /**
     * Shows the left operand AddButton in its region, using the @param to populate AddButton's
     * drop-down.
     * @param availableOperands
     */
    attachLeftOperand: function (availableOperands) {
      var left = new RuleManager.AddButtonView({ collection: availableOperands });
      this.leftRegion.show(left);
      this._leftRegionView = this.leftRegion.currentView;
      this.addLeftOperand(availableOperands.at(0).attributes);

      this._leftRegionView.on('tooltip:item:selected', this.addLeftOperand, this);
    },
    /**
     * Shows the operator SelectView in its region, using the @param to populate SelectView's
     * drop-down.
     * @param availableOperators
     */
    attachOperator: function (availableOperators) {
      var operator = new RuleManager.SelectView({ collection: availableOperators });
      this.operatorRegion.show(operator);
      this._operatorRegionView = this.operatorRegion.currentView;
      var currentSelection = availableOperators.at(0).attributes.name;
      this.updateModel('operator', currentSelection);
      this._operatorRegionView.on('tooltip:item:selected', this.onSelectedOperator, this);
    },
    /**
     * Shows the right operand AddButton in its region, using the @param to populate AddButton's
     * drop-down.
     * @param availableOperands
     */
    attachRightOperand: function (availableOperands) {
      var right = new RuleManager.SelectView({ collection: availableOperands });
      this.rightRegion.show(right);
      this._rightRegionView = this.rightRegion.currentView;

      this._rightRegionView.on('tooltip:item:selected', this.addRightOperand, this);
    },

    /**
     * Shows the left operand SelectView in its region, using the @param to populate SelectView's
     * drop-down. When the left operand is added (or when another is selected from its tooltip),
     * the operator's SelectView and the right operand's SelectView are populated with the right
     * dependencies linked to the selected left operand.
     *
     * @param optionModel
     */
    addLeftOperand: function (optionModel) {
      var availableOperands = new RuleManager.TooltipOptionCollection(this._dependencies);
      var operator = new RuleManager.SelectView({ collection: availableOperands });
      this.leftRegion.show(operator);
      this._leftRegionView = this.leftRegion.currentView;

      this._leftRegionView.setChosenOption(optionModel);
      this._leftRegionView.on('tooltip:item:selected', this.addLeftOperand, this);

      var currentSelection = optionModel.name;
      this.updateModel('left', new RuleManager.TerminalOperandModel({
        type: 'key',
        value: currentSelection,
      }));

      var operatorArrayOfObjects = _.map(optionModel.operators, function (op) {
        return {
          name: op,
          label: op,
        };
      });
      var operatorCollection = new RuleManager.TooltipOptionCollection(operatorArrayOfObjects);
      this.attachOperator(operatorCollection);

      var dependencies = this._dependencies.find(function (dep) {
        return dep.name === optionModel.name;
      });
      var operandCollection = new RuleManager.TooltipOptionModel(dependencies.target_value);
      this.addRightOperand(operandCollection);
    },
    /**
     * Shows the right operand view in its region.
     * The right operand could be of different types:
     * - NUMERIC or STRING: in this case a text input is shown;
     * - SELECT: in this case a SelectView is shown, which available options are gained from the
     * @param.
     *
     * @param optionModel
     */
    addRightOperand: function (optionModel) {
      var operator;
      if (optionModel.get('type') === 'numeric' || optionModel.get('type') === 'string') {
        var model = new RuleManager.TerminalOperandModel({
          type: 'value',
          value: optionModel.get('value') || null,
        });
        operator = new RuleManager.OperandInputView({ model: model });
        this.rightRegion.show(operator);
        this._rightRegionView = this.rightRegion.currentView;

        this.updateModel('right', model);
      } else if (optionModel.get('type') === 'select') {
        var operandArrayOfObjects = [];
        if (optionModel.get('available_values').length === 0) {
          operandArrayOfObjects = _.map(this._dependencies, function (dependency) {
            return {
              name: dependency.name,
              label: dependency.label,
            };
          });
        } else {
          operandArrayOfObjects = _.map(optionModel.get('available_values'), function (op) {
            return {
              name: op.value,
              label: op.label,
            };
          });
        }
        var availableOperands = new RuleManager.TooltipOptionCollection(operandArrayOfObjects);

        operator = new RuleManager.SelectView({ collection: availableOperands });
        this.rightRegion.show(operator);
        this._rightRegionView = this.rightRegion.currentView;

        var selectedOptionModel = availableOperands.at(0);
        this._rightRegionView.setChosenOption(selectedOptionModel);
        this.updateModel('right', new RuleManager.TerminalOperandModel({
          type: 'key',
          value: selectedOptionModel.get('name'),
        }));
        this._rightRegionView.on('tooltip:item:selected', this.onSelectedRightOperand, this);
      }
    },
    /**
     * Responds to the 'tooltip:item:selected' event on the operator's SelectView.
     * @param options
     */
    onSelectedOperator: function (options) {
      var operatorName = options.name;
      this.updateModel('operator', operatorName);
    },
    /**
     * Responds to the 'tooltip:item:selected' event on the right operand's SelectView.
     * @param options
     */
    onSelectedRightOperand: function (options) {
      var operandName = options.name;
      this.updateModel('right', new RuleManager.TerminalOperandModel({
        type: 'key',
        value: operandName,
      }));
    },
    /**
     * Updated the attributeName's attribute of this view TerminalModel with the newValue.
     *
     * @param attributeName
     * @param newValue
     */
    updateModel: function (attributeName, newValue) {
      this.model.set(attributeName, newValue);
    },
    /**
     * Build operands and operator for a TerminalView
     * @param condition
     */
    build: function (condition) {
      var dependenciesColl = new RuleManager.RuleDependecyCollection(this._dependencies);

      // Left
      var leftValue = dependenciesColl.findWhere({ name: condition.get('left').get('value') });
      this.addLeftOperand(leftValue.attributes);

      // Operator
      var selectedOperator = condition.get('operator');
      this._operatorRegionView.onTooltipOptionSelected({
        name: selectedOperator,
        label: selectedOperator,
      }, false);

      // Right
      if (condition.get('right').get('type') === 'key') {
        var selectedOption = leftValue.get('target_value').available_values.find(function (elem) {
          return elem.value === condition.get('right').get('value');
        });
        this._rightRegionView.setChosenOption({
          label: selectedOption.label,
          name: selectedOption.value,
        });
        var terminal = new RuleManager.TerminalOperandModel({
          type: 'key',
          value: selectedOption.value,
        });
        this.updateModel('right', terminal);
      } else {
        var rightValue = new RuleManager.TooltipOptionModel({
          type: leftValue.get('target_value').type,
          value: condition.get('right').get('value'),
        });
        this.addRightOperand(rightValue);
      }
    },
  });
});
