const React = require('react');
const PropTypes = require('prop-types');
const TableCell = require('./TableCell.react');
const TableColumnList = require('./models/TableColumnList');
const ColumnSelector = require('./ColumnSelector/ColumnSelector.react');
const LaptopAndAbove = require('../media-queries/LaptopAndAbove.react');

const ColumnSelectorTableCell = ({ columns, className, name, onColumnsChange }) => {
  const actualClassName = `wethod-table__cell--selector ${className}`;

  return (
    <LaptopAndAbove>
      <TableCell className={actualClassName} name={name}>
        <ColumnSelector columns={columns} onChange={onColumnsChange} />
      </TableCell>
    </LaptopAndAbove>
  );
};

ColumnSelectorTableCell.defaultProps = {
  className: '',
};

ColumnSelectorTableCell.propTypes = {
  columns: PropTypes.instanceOf(TableColumnList).isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  /**
   * @param columns {<string>[]} new visible columns
   * */
  onColumnsChange: PropTypes.func.isRequired,
};

module.exports = ColumnSelectorTableCell;
