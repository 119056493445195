const React = require('react');
const Header = require('../SubsectionHeader.react');
const OrderPlanViewPreference = require('../../containers/order-plan-settings/OrderPlanViewPreference');

const OrderPlanSettings = () => (
  <div>
    <Header
      subtitle="Company Order Plan View"
      id="company-order-plan-view" />
    <OrderPlanViewPreference />
  </div>
);

module.exports = OrderPlanSettings;
