const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  filters: state.filters,
  permissions: state.timesheetPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  showTransferHoursModal: () => dispatch(actions.showTransferHoursModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
