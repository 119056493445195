const React = require('react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({ value, target }) => {
  const labels = {
    much_lower: 'far below',
    lower: 'below',
    almost_equal: 'in line with',
    equal: 'equal to',
    greater: 'above',
    much_greater: 'way above',
  };

  return <Comparator a={value} labels={labels} b={target} />;
};
