const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/Revenues.react');

const mapStateToProps = (state) => ({
  revenues: state.month_revenue,
  targetType: state.target_type,
  target: state.monthly_revenue_target,
  constOnTarget: state.revenues_on_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
