'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.OperandInputView = Marionette.ItemView.extend({
    template: '#inputOperandTemplate',
    className: 'rule-select-wrapper',
    ui: {
      input: 'input',
    },
    events: {
      'blur @ui.input': 'onInputBlur',
      keypress: 'onKeyPress',
    },
    onShow: function () {
      this.ui.input.blur();
    },
    /**
     * Responds to the 'blur' event on the input, saving the inserted value.
     */
    onInputBlur: function () {
      var inputValue = this.ui.input.val();
      this.updateModel('value', inputValue);
    },
    /**
     * Checks whether a keyboard key is accepted or not. In this case returns if the key represents
     * a number or not.
     * @param key
     * @returns {boolean}
     */
    isAccepted: function (key) {
      return key === 8 || !Number.isNaN(String.fromCharCode(key));
    },
    /**
     * Responds to the 'keypress' event, preventing non acceptable values to being inserted.
     * @param event
     */
    onKeyPress: function (event) {
      var key = event.which;
      if (!this.isAccepted(key)) {
        // Stop input from being entered
        event.preventDefault();
      }
    },
    /**
     * Updated the attributeName's attribute of this view TerminalModel with the newValue.
     * The newValue is parsed to the corresponding integer, an empty string is treated as '0'.
     *
     * @param attributeName
     * @param newValue
     */
    updateModel: function (attributeName, newValue) {
      if (newValue === '') {
        newValue = 0;
      } else {
        newValue = parseInt(newValue);
      }
      this.model.set(attributeName, newValue);
    },
  });
});
