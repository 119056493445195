class PropertyMetadata {
  constructor(name) {
    this.name = name;
    this.canEdit = false;
    this.reason = null;
    this.constraints = [];
  }
}

PropertyMetadata.CANNOT_EDIT_DATA_FROZEN = 'DataFrozen';

module.exports = PropertyMetadata;
