const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

module.exports = class CannotUploadModal extends React.Component {
  getBody() {
    const errors = this.props.data;
    switch (errors[0]) {
      case 'size':
        return (
          <span>
            Files should not be more than
            <b>128 MB</b>
            {' '}
            in size, please choose another one and retry.
          </span>
        );
      case 'network':
        return (
          <span>
            Seems like you're
            <b>offline</b>
            , please check your connection and retry.
          </span>
        );
      default:
        return (
          <span>
            This kind of file is
            <b>not accepted</b>
            , please choose another one and retry.
          </span>
        );
    }
  }

  render() {
    return (
      <Modal title="Cannot upload" onCancelClick={this.props.onCancelClick} className="project-canvas-alert">
        {this.getBody()}
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">OK</button>
        </div>
      </Modal>
    );
  }
};
