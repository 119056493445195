'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $) {
  // years
  this.yearModel = Wethod.Utility.BaseModel.extend({
    defaults: {
      name: null,
    },
  });
  this.calendarYearCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    model: ProjectCharterApp.yearModel,
  });

  // months
  this.MonthModel = Wethod.Utility.BaseModel.extend({
    defaults: {
      name: null,
      first_day: null,
      active: false,
    },
  });
  this.CalendarCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    model: ProjectCharterApp.MonthModel,
  });

  // project
  this.ProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/dashboard/user-widget/planning',
  });

  this.ProjectMonthModel = Wethod.Utility.BaseModel.extend();
  this.CalendarProjectMonthsCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    model: ProjectCharterApp.ProjectMonthModel,
  });

  this.ProjectEmployeeModel = Wethod.Utility.BaseModel.extend();
  this.CalendarProjectEmployeesCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    model: ProjectCharterApp.ProjectEmployeeModel,
  });

  var API = {
    getProjectCharterPlannings: function () {
      var model = new ProjectCharterApp.ProjectModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectModel());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
  };
  // PLANNING
  // get project charter planning
  Wethod.reqres.setHandler('projectCharter:get:project:plan', function () {
    return API.getProjectCharterPlannings();
  });
});
