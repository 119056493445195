/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const SidebarInput = require('./SidebarWrapperEditable.react');
const PhoneInput = require('../PhoneInput.react');

/**
 * An outlined phone input component, material styled.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name
 * value
 * required: boolean
 * formatOptions: object used to set Cleave options
 *
 * onFocus
 * onChange
 * onBlur
 * onValidate
 *
 * @type {module.SidebarPhoneInput}
 */
module.exports = class SidebarPhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = this.getConstraints();
  }

  getConstraints() {
    if (this.props.required && this.props.required === true) return 'required';
  }

  render() {
    return (
      <SidebarInput placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}>
        <PhoneInput name={this.props.name}
          value={this.props.value}
          constraints={this.constraints}
          formatOptions={this.props.formatOptions}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onValidate={this.props.onValidate} />
      </SidebarInput>
    );
  }
};
