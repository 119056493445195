const { connect } = require('react-redux');
const Component = require('../../components/modal/Modal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  toShow: state.modalToShow,
  modalData: state.modalData,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
