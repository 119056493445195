const moment = require('moment');
const FeatureService = require('./FeatureService');
const EmployeeCompanyHoliday = require('../models/EmployeeCompanyHoliday');

module.exports = {
  /**
   * By ISO 8601 standard, today's week belongs to month A in today's week's thursday belongs to
   * month A.
   * @param month string YYYY-MM-DD
   * @returns {boolean}
   */
  isCurrentMonth: (month) => {
    const currentWeekThursday = moment().day('Thursday');
    const currentWeekThursdayMonth = `${currentWeekThursday.format('YYYY-MM')}-01`;
    return currentWeekThursdayMonth === month;
  },

  isPastMonth: (month) => {
    const currentWeekThursday = moment().day('Thursday');
    return moment(month).isBefore(currentWeekThursday, 'month');
  },

  isPastYear: (date) => {
    const currentWeekThursday = moment().day('Thursday');
    return moment(date).isBefore(currentWeekThursday, 'year');
  },

  /**
   * Return ISO month index of the given date.
   * @param date string YYYY-MM-DD
   * @returns {number}
   */
  getMonth: (date) => moment(date).day('Thursday').month(),

  /**
   * Return ISO year of the given date.
   * @param date string YYYY-MM-DD
   * @returns {number}
   */
  getYear: (date) => moment(date).day('Thursday').year(),

  /**
   * Returns the number of weeks in the given year.
   * @param year
   * @return {number}
   */
  getWeeksInYear: (year) => Math.max(moment(new Date(year, 11, 31))
    .isoWeek(), moment(new Date(year, 11, 31 - 7)).isoWeek()),

  /**
   * Return last thursday of the month of the given date.
   * @param date string YYYY-MM-DD || Moment
   * @returns {Moment}
   */
  getLastThursdayOfMonth: (date) => {
    const endOfMonth = moment(date).endOf('month');
    // Subtract days to get the prev thursday
    return endOfMonth.subtract((endOfMonth.day() + 3) % 7, 'days');
  },
  /**
   * Return if given date is Saturday or Sunday.
   * @param date string YYYY-MM-DD || Moment
   * @returns {boolean}
   */
  isWeekend(date) {
    const weekDay = moment(date).day();
    return weekDay === 0 || weekDay === 6;
  },
  /**
   * Returns the first day of the week to which the date passed as a parameter belongs.
   * @param {string} date
   * @return {string} First day of week, formatted as YYYY-MM-DD
   */
  getFirstDayOfWeek(date) {
    return moment(date).startOf('isoWeek').format('YYYY-MM-DD');
  },
  /**
   * Returns the last day of the week to which the date passed as a parameter belongs.
   * @param {string} date
   * @return {string} Last day of week, formatted as YYYY-MM-DD
   */
  getLastDayOfWeek(date) {
    return moment(date).endOf('isoWeek').format('YYYY-MM-DD');
  },
  /**
   * Returns the first day of the month to which the date passed as a parameter belongs.
   * @param date
   * @return {string} First day of month, formatted as YYYY-MM-DD
   */
  getStartOfMonth(date) {
    return moment(date).startOf('month').format('YYYY-MM-DD');
  },
  /**
   * Returns the last day of the month to which the date passed as a parameter belongs.
   * @param {string} date
   * @return {string} Last day of month, formatted as YYYY-MM-DD
   */
  getEndOfMonth(date) {
    return moment(date).endOf('month').format('YYYY-MM-DD');
  },
  /**
   * Call API to get holidays for a given month and employee.
   * @param {Date} day
   * @param {number} employeeId
   * @return {Promise}
   */
  getHolidayForMonthRequest(day, employeeId) {
    if (!Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)) {
      return null;
    }
    const from = this.getStartOfMonth(day);
    const to = this.getEndOfMonth(day);

    return EmployeeCompanyHoliday.list(employeeId, from, to);
  },
  /**
   * Call API to get holidays for a given week and employee.
   * @param {Date} day
   * @param {number} employeeId
   * @return {Promise}
   */
  getHolidayForWeekRequest(day, employeeId) {
    if (!Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)) {
      return null;
    }
    const from = this.getFirstDayOfWeek(day);
    const to = this.getLastDayOfWeek(day);

    return EmployeeCompanyHoliday.list(employeeId, from, to);
  },
};
