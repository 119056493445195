const React = require('react');
const SectionTitle = require('../../../../common/components/SectionTitle.react');
const TargetComparator = require('../../../containers/sections/Production/TargetComparator');
const PrevWeekComparator = require('../../../containers/sections/Sales/PrevWeekSalesComparator');
const ComparatorConnector = require('../../../../common/components/ComparatorConnector.react');

module.exports = class Title extends React.Component {
  getAgainstTarget() {
    const target = this.props.weekly_target;
    if (target) {
      return (
        <span>
          <TargetComparator value={this.props.won_projects_value} /> their target
        </span>
      );
    }
    return null;
  }

  getAgainstPrevWeek() {
    return <PrevWeekComparator value={this.props.won_projects_value} />;
  }

  getContent() {
    const againstTarget = this.getAgainstTarget();
    const againstPrevWeek = this.getAgainstPrevWeek();
    const c1 = {
      a: this.props.won_projects_value,
      b: this.props.prev_week_won_projects_value,
    };
    const c2 = {
      a: this.props.won_projects_value,
      b: this.props.weekly_target,
    };
    const connection = againstTarget ? (
      <span>
        <ComparatorConnector c1={c1} c2={c2} /> they're
      </span>
    ) : null;
    return (
      <span>
        Sales are {againstTarget} {connection} {againstPrevWeek}
      </span>
    );
  }

  render() {
    return <SectionTitle>{this.getContent()}</SectionTitle>;
  }
};
