const React = require('react');
const Modal = require('./InfoModal.react');

const ApprovalNeededModal = ({ onCancelClick }) => (
  <Modal title="Approval needed" onCancelClick={onCancelClick}>
    You cannot plan this project just like that, you need to
    make an <a href="/#planning/allocation-requests" target="_blank">allocation request</a>.
  </Modal>
);

module.exports = ApprovalNeededModal;
