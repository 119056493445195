const React = require('react');
const Tooltip = require('../../../../../../../common/react/TooltipFixed/Tooltip.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

/**
 * Group of secondary info inside an HeaderBox.
 * This group always has a label and a value, can have an icon and a tooltip too.
 * @param label
 * @param value
 * @param icon
 * @param noBorder
 * @param tooltip
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderBoxSecondaryGroup = ({
  label,
  value,
  icon,
  noBorder,
  tooltipMessage = '',
}) => {
  const className = noBorder ? 'wethod-budget-header-box__secondary-group wethod-budget-header-box__secondary-group--no-border' : 'wethod-budget-header-box__secondary-group';

  return (
    <div className={className}>
      <div className="wethod-budget-header-box__secondary-group-info">
        <div className="wethod-budget-header-box__secondary-group-info-label">
          <ShowIf condition={tooltipMessage.length > 0} showElse={<span>{label}</span>}>
            <Tooltip label={tooltipMessage}>{label}</Tooltip>
          </ShowIf>
        </div>
        <div aria-label={label} className="wethod-budget-header-box__secondary-group-info-value">{value}</div>
      </div>
      <div className="wethod-budget-header-box__secondary-group-icon">{icon}</div>
    </div>
  );
};

module.exports = HeaderBoxSecondaryGroup;
