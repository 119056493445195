const React = require('react');
const Header = require('../SubsectionHeader.react');
const Actions = require('../../containers/company-holidays/CompanyHolidaysActions');
const List = require('../../containers/company-holidays/CompanyHolidaysTable');

const CompanyHolidays = () => (
  <div className="bottomSpace">
    <Header subtitle="Bank Holidays Planner"
      id="bank-holidays"
      description="Below is a list of national and local holidays during which our offices will be closed for observance." />
    <Actions />
    <List />
  </div>
);

module.exports = CompanyHolidays;
