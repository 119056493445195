const React = require('react');

const DeleteEventModalBody = () => (
  <div>
    <p>
      You are about to delete an event from the timeline.
    </p>
    <br />
    <p> Are you sure you want to continue?</p>
  </div>
);

module.exports = DeleteEventModalBody;
