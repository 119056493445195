const React = require('react');
const { CSSTransition } = require('react-transition-group');
const PropTypes = require('prop-types');

const Transition = ({
  children, timeout, mountOnEnter, unmountOnExit, className, animateFirstMount,
  triggered,
}) => (
  <CSSTransition
    classNames={className}
    timeout={timeout}
    appear={animateFirstMount}
    mountOnEnter={mountOnEnter}
    unmountOnExit={unmountOnExit}
    in={triggered}>
    {children}
  </CSSTransition>
);

Transition.defaultProps = {
  timeout: 300,
  mountOnEnter: true,
  unmountOnExit: true,
  animateFirstMount: false,
};

Transition.propTypes = {
  /**
   * Element to animate.
   */
  children: PropTypes.node.isRequired,
  /**
   * The duration of the transition, in milliseconds.
   */
  timeout: PropTypes.number,
  /**
   * By default the child component is "lazy mounted" on the first `triggered={true}`.
   * If you want to immediately mount child component along with the parent `Transition`
   * component you can set `mountOnEnter`. After the first enter transition the component
   * will stay mounted, even on "exited", unless you also specify `unmountOnExit`.
   */
  mountOnEnter: PropTypes.bool,
  /**
   * By default the child component stays mounted after it reaches the `'exited'` state.
   * Set `unmountOnExit` if you'd prefer to unmount the component after it finishes exiting.
   */
  unmountOnExit: PropTypes.bool,
  /**
   * Show the component; triggers the enter or exit states
   */
  triggered: PropTypes.bool.isRequired,
  /**
   * Normally a component is not transitioned if it is shown when the `<Transition>` component
   * mounts.
   * If you want to transition on the first mount set `appear` to `true`, and the
   * component will transition in as soon as the `<Transition>` mounts.
   *
   * > Note: there are no specific "appear" states. `appear` only adds an additional
   * `enter` transition.
   */
  animateFirstMount: PropTypes.bool,
  /**
   * The animation classNames applied to the component as it enters, exits or
   * has finished the transition. A single name can be provided and it will be
   * suffixed for each stage: e.g.
   *
   * `classNames="fade"` applies `fade-enter`, `fade-enter-active`,
   * `fade-enter-done`, `fade-exit`, `fade-exit-active`, `fade-exit-done`,
   * `fade-appear`, `fade-appear-active`, and `fade-appear-done`.
   *
   * **Note**: `fade-appear-done` and `fade-enter-done` will _both_ be applied.
   * This allows you to define different behavior for when appearing is done and
   * when regular entering is done, using selectors like
   * `.fade-enter-done:not(.fade-appear-done)`. For example, you could apply an
   * epic entrance animation when element first appears in the DOM using
   * [Animate.css](https://daneden.github.io/animate.css/). Otherwise you can
   * simply use `fade-enter-done` for defining both cases.
   */
  className: PropTypes.string.isRequired,
};

module.exports = Transition;
