module.exports.GET_BRIEF_REQUEST = 'GET_BRIEF_REQUEST';
module.exports.GET_BRIEF_SUCCESS = 'GET_BRIEF_SUCCESS';

module.exports.SAVE_BRIEF_REQUEST = 'SAVE_BRIEF_REQUEST';
module.exports.SAVE_BRIEF_SUCCESS = 'SAVE_BRIEF_SUCCESS';

module.exports.GET_CONCEPT_REQUEST = 'GET_CONCEPT_REQUEST';
module.exports.GET_CONCEPT_SUCCESS = 'GET_CONCEPT_SUCCESS';

module.exports.SAVE_CONCEPT_REQUEST = 'SAVE_CONCEPT_REQUEST';
module.exports.SAVE_CONCEPT_SUCCESS = 'SAVE_CONCEPT_SUCCESS';

module.exports.GET_GOALS_REQUEST = 'GET_GOALS_REQUEST';
module.exports.GET_GOALS_SUCCESS = 'GET_GOALS_SUCCESS';

module.exports.SAVE_GOAL_REQUEST = 'SAVE_GOAL_REQUEST';
module.exports.SAVE_GOAL_SUCCESS = 'SAVE_GOAL_SUCCESS';
module.exports.CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS';
module.exports.UPDATE_GOAL = 'UPDATE_GOAL';

module.exports.DELETE_GOAL_REQUEST = 'DELETE_GOAL_REQUEST';
module.exports.DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';

module.exports.MODAL_CLOSE = 'MODAL_CLOSE';

module.exports.MODAL_SHOW_BRIEF = 'MODAL_SHOW_BRIEF';

module.exports.MODAL_SHOW_CONCEPT = 'MODAL_SHOW_CONCEPT';

module.exports.MODAL_SHOW_GOAL = 'MODAL_SHOW_GOAL';

module.exports.MODAL_SHOW_DELETE = 'MODAL_SHOW_DELETE';

module.exports.MODAL_MISSING_STATUS_FOR_KANBAN = 'MODAL_MISSING_STATUS_FOR_KANBAN';

module.exports.MODAL_SERVER_ERROR = 'MODAL_SERVER_ERROR';

module.exports.MODAL_REQUEST_REVIEW = 'MODAL_REQUEST_REVIEW';

module.exports.MODAL_START_REVIEW = 'MODAL_START_REVIEW';

module.exports.GET_BOARDS_REQUEST = 'GET_BOARDS_REQUEST';
module.exports.GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';

module.exports.ADD_BOARD_REQUEST = 'ADD_BOARD_REQUEST';
module.exports.ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';

module.exports.EDIT_BOARD_NAME_REQUEST = 'EDIT_BOARD_NAME_REQUEST';
module.exports.EDIT_BOARD_NAME_SUCCESS = 'EDIT_BOARD_NAME_SUCCESS';

module.exports.DELETE_BOARD_REQUEST = 'DELETE_BOARD_REQUEST';
module.exports.DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';

module.exports.GET_BOARD_CONTENT_REQUEST = 'GET_BOARD_CONTENT_REQUEST';
module.exports.GET_BOARD_CONTENT_SUCCESS = 'GET_BOARD_CONTENT_SUCCESS';

module.exports.ADD_BOARD_ITEM_REQUEST = 'ADD_BOARD_ITEM_REQUEST';
module.exports.ADD_BOARD_ITEM_SUCCESS = 'ADD_BOARD_ITEM_SUCCESS';

module.exports.EDIT_BOARD_ITEM_NAME_REQUEST = 'EDIT_BOARD_ITEM_NAME_REQUEST';
module.exports.EDIT_BOARD_ITEM_NAME_SUCCESS = 'EDIT_BOARD_ITEM_NAME_SUCCESS';

module.exports.DELETE_BOARD_ITEM_REQUEST = 'DELETE_BOARD_ITEM_REQUEST';
module.exports.DELETE_BOARD_ITEM_SUCCESS = 'DELETE_BOARD_ITEM_SUCCESS';

module.exports.ADD_BOARD_STRUCTURE_ATTRIBUTE_REQUEST = 'ADD_BOARD_STRUCTURE_ATTRIBUTE_REQUEST';
module.exports.ADD_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS = 'ADD_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS';

module.exports.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_REQUEST = 'EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_REQUEST';
module.exports.EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_SUCCESS = 'EDIT_BOARD_STRUCTURE_ATTRIBUTE_NAME_SUCCESS';

module.exports.DELETE_BOARD_STRUCTURE_ATTRIBUTE_REQUEST = 'DELETE_BOARD_STRUCTURE_ATTRIBUTE_REQUEST';
module.exports.DELETE_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS = 'DELETE_BOARD_STRUCTURE_ATTRIBUTE_SUCCESS';

module.exports.EDIT_BOARD_ATTRIBUTE_VALUE_REQUEST = 'EDIT_BOARD_ATTRIBUTE_VALUE_REQUEST';
module.exports.EDIT_BOARD_ATTRIBUTE_VALUE_SUCCESS = 'EDIT_BOARD_ATTRIBUTE_VALUE_SUCCESS';

module.exports.EDIT_BOARD_ITEM_VALUES_REQUEST = 'EDIT_BOARD_ITEM_VALUES_REQUEST';
module.exports.EDIT_BOARD_ITEM_VALUES_SUCCESS = 'EDIT_BOARD_ITEM_VALUES_SUCCESS';

module.exports.ADD_BOARD_ATTRIBUTE_MEMBER_REQUEST = 'ADD_BOARD_ATTRIBUTE_MEMBER_REQUEST';

module.exports.CREATE_STATUS_REQUEST = 'CREATE_STATUS_REQUEST';
module.exports.CREATE_STATUS_SUCCESS = 'CREATE_STATUS_SUCCESS';

module.exports.UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
module.exports.UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';

module.exports.DELETE_STATUS_REQUEST = 'DELETE_STATUS_REQUEST';
module.exports.DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS';

module.exports.UPDATE_BOARDS_SORT = 'UPDATE_BOARDS_SORT';
module.exports.SAVE_BOARDS_SORT_REQUEST = 'SAVE_BOARDS_SORT_REQUEST';
module.exports.SAVE_BOARDS_SORT_SUCCESS = 'SAVE_BOARDS_SORT_SUCCESS';

module.exports.UPDATE_SPREADSHEET_ITEMS_SORT = 'UPDATE_SPREADSHEET_ITEMS_SORT';
module.exports.SAVE_SPREADSHEET_ITEMS_SORT_REQUEST = 'SAVE_SPREADSHEET_ITEMS_SORT_REQUEST';
module.exports.SAVE_SPREADSHEET_ITEMS_SORT_SUCCESS = 'SAVE_SPREADSHEET_ITEMS_SORT_SUCCESS';

module.exports.UPDATE_STATUS_AVAILABLE_VALUES_SORT = 'UPDATE_STATUS_AVAILABLE_VALUES_SORT';
module.exports.SAVE_STATUS_AVAILABLE_VALUES_SORT_REQUEST = 'SAVE_STATUS_AVAILABLE_VALUES_SORT_REQUEST';
module.exports.SAVE_STATUS_AVAILABLE_VALUES_SORT_SUCCESS = 'SAVE_STATUS_AVAILABLE_VALUES_SORT_SUCCESS';

module.exports.UPDATE_KANBAN_ITEMS_SORT = 'UPDATE_KANBAN_ITEMS_SORT';
module.exports.UPDATE_KANBAN_ITEM_LIST = 'UPDATE_KANBAN_ITEM_LIST';

module.exports.UPDATE_SPREADSHEET_COLUMNS_SORT = 'UPDATE_SPREADSHEET_COLUMNS_SORT';
module.exports.SAVE_SPREADSHEET_COLUMNS_SORT_REQUEST = 'SAVE_SPREADSHEET_COLUMNS_SORT_REQUEST';
module.exports.SAVE_SPREADSHEET_COLUMNS_SORT_SUCCESS = 'SAVE_SPREADSHEET_COLUMNS_SORT_SUCCESS';

module.exports.DELETE_ATTRIBUTE_MEMBER_VALUE_REQUEST = 'DELETE_ATTRIBUTE_MEMBER_VALUE_REQUEST';
module.exports.DELETE_ATTRIBUTE_MEMBER_VALUE_SUCCESS = 'DELETE_ATTRIBUTE_MEMBER_VALUE_SUCCESS';

module.exports.GET_PEOPLE_REQUEST = 'GET_PEOPLE_REQUEST';
module.exports.GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';

module.exports.SAVE_SPREADSHEET_COLUMN_SUMMARY_REQUEST = 'SAVE_SPREADSHEET_COLUMN_SUMMARY_REQUEST';
module.exports.SAVE_SPREADSHEET_COLUMN_SUMMARY_SUCCESS = 'SAVE_SPREADSHEET_COLUMN_SUMMARY_SUCCESS';

module.exports.GET_EVENT_TYPES_REQUEST = 'GET_EVENT_TYPES_REQUEST';
module.exports.GET_EVENT_TYPES_SUCCESS = 'GET_EVENT_TYPES_SUCCESS';

module.exports.DELETE_ATTRIBUTE_MILESTONE_VALUE_REQUEST = 'DELETE_ATTRIBUTE_MILESTONE_VALUE_REQUEST';
module.exports.DELETE_ATTRIBUTE_MILESTONE_VALUE_SUCCESS = 'DELETE_ATTRIBUTE_MILESTONE_VALUE_SUCCESS';

module.exports.CHANGE_BOARD_TYPE = 'CHANGE_BOARD_TYPE';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';

module.exports.UPDATE_KANBAN_OPTIONS = 'UPDATE_KANBAN_OPTIONS';

module.exports.UPDATE_ACTIVITY_ATTRIBUTE = 'UPDATE_ACTIVITY_ATTRIBUTE';

module.exports.GET_KUDOS_REQUEST = 'GET_KUDOS_REQUEST';
module.exports.GET_KUDOS_SUCCESS = 'GET_KUDOS_SUCCESS';

module.exports.GET_LAST_REVIEW_REQUEST = 'GET_LAST_REVIEW_REQUEST';
module.exports.GET_LAST_REVIEW_SUCCESS = 'GET_LAST_REVIEW_SUCCESS';

module.exports.CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST';
module.exports.CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
