const React = require('react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const TransferHoursBody = require('./TransferHoursBody.react');

module.exports = class TransferHoursModal extends React.Component {
  static formatTimesheets(timesheets) {
    return timesheets.map((timesheet) => {
      const hoursToTransfer = timesheet.hours_to_transfer != null
        ? timesheet.hours_to_transfer
        : timesheet.hours;
      return ({
        id_timetracking: timesheet.id_timetracking,
        date: timesheet.date ? moment(timesheet.date).format('YYYY-MM-DD') : null,
        // If the timesheet is selected, transfer the hours, otherwise transfer 0
        hours: timesheet.selected
          ? hoursToTransfer
          : 0,
      });
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      project: null,
      date: null,
    };
  }

  onProjectChange(e, project) {
    this.setState({
      project,
    });
  }

  onDateChange(e) {
    let date = e.target.value;
    date = date ? moment(date).format('YYYY-MM-DD') : null;
    this.setState({
      date,
    });
  }

  onSave() {
    const project = this.state.project ? this.state.project.id : null;
    this.props.onSave(
      this.props.selectAll,
      project,
      this.state.date,
      TransferHoursModal.formatTimesheets(this.props.updatedTimesheets),
      this.props.filters,
    );
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : '';
  }

  /**
   * Check if the form is ready to be saved
   * The form is ready to be saved if the project or the date are set and
   * at least one timesheet is selected
   * and the form is not currently saving
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving
      && (this.state.project || this.state.date)
      && (this.props.hasSelectedTimesheets || this.props.selectAll) && this.props.isValid;
  }

  render() {
    return (
      <Modal className="timesheets-transfer-hours__modal"
        title="Move Timesheet Hours"
        onClose={this.props.closeModal}>
        <ModalContent>
          <TransferHoursBody
            project={this.state.project}
            date={this.state.date}
            onProjectChange={this.onProjectChange.bind(this)}
            onDateChange={this.onDateChange.bind(this)}
            frozenUpTo={this.props.frozenUpTo}
            updateErrors={this.props.updateErrors} />
          <ModalFooter feedback={this.getFeedback()}>
            <ModalFooterAction onClick={this.onSave.bind(this)} disabled={!this.canSave()}>
              Confirm
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
