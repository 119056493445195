'use strict';

Wethod.module('TimelineApp.Timeline', function (Timeline, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#timelineTemplate',
    regions: {
      headerSection: '[data-region="timelineSectionHeader"]',
      timelineBody: '[data-region="timelineBody"]',
    },
  });
});
