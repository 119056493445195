/* eslint-disable consistent-return */
const React = require('react');
const SectionHeader = require('../../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const BuFilter = require('../../../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');

module.exports = class Actions extends React.Component {
  handleClick() {
    if (!this.props.isWaiting) {
      this.props.showSidebar({});
    }
  }

  getButtonStyle() {
    let style = 'wethod-button';
    if (this.props.isWaiting) {
      style += ' disabled';
    }
    return style;
  }

  getAddButton() {
    if (this.props.canEdit) {
      return (
        <button type="button"
          className={this.getButtonStyle()}
          onClick={this.handleClick.bind(this)}>
          New supplier
        </button>
      );
    }
  }

  getBuFilter() {
    if (this.props.buEnabled) {
      const className = this.props.isWaiting ? 'suppliers__bu-select disabled' : 'suppliers__bu-select';
      return (
        <div className={className}>
          <BuFilter onApply={this.filterBusinessUnit.bind(this)}
            selectedValues={this.props.buFilter} />
        </div>
      );
    }
    return null;
  }

  filterBusinessUnit(name, units) {
    if (!this.props.isWaiting) {
      this.props.getSuppliers(0, this.props.pageLimit, this.props.keyword, this.props.sortBy.col,
        this.props.sortBy.order, units);
    }
  }

  render() {
    return (
      <div className="suppliers__actions wethod-section-actions" ref={(ref) => this.rootRef = ref}>
        <SectionHeader
          current_section="Suppliers"
          helper_url="finance/index/#suppliers"
          big />
        {this.getBuFilter()}
        {this.getAddButton()}
      </div>
    );
  }
};
