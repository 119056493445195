/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */
const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');

module.exports = class ProjectMonth extends React.Component {
  getAmount() {
    if (this.props.amount) return numeral(this.props.amount).format('0.[0]');
    return '';
  }

  getProductionValue() {
    return (this.props.totalProductionValue / 100) * this.props.amount;
  }

  getValue() {
    const value = this.getProductionValue();
    if (value) return numeral(value).format('0,0');
    return '';
  }

  getBorderStyle() {
    return this.props.isBoundary ? 'dashed' : 'solid';
  }

  getStyle() {
    if (this.props.isProjectMonth) {
      return {
        borderBottomStyle: this.getBorderStyle(),
        borderBottomColor: 'rgba(72,165,249,1)',
      };
    }
  }

  getAmountStyle() {
    if (this.props.isPastMonth) {
      return {
        opacity: 0.25,
      };
    }
  }

  getDelta() {
    if (this.props.delta) {
      const delta = Math.round(this.props.delta * 10) / 10;
      if (delta !== 0) {
        const formattedDelta = numeral(delta).format('+0,0.[0]');
        return <span className="production-plan-project__month-delta">{formattedDelta}</span>;
      }
    }
    return null;
  }

  render() {
    return (
      <TableCell className="production-plan-project__month" style={this.getStyle()}>
        <span className="production-plan-project__month-content">
          {this.getDelta()}
          <span className="production-plan-project__month-value" style={this.getAmountStyle()}>{this.getAmount()}</span>
          <span
            className="production-plan-project__month-value production-plan-project__month-value--production"
            style={this.getAmountStyle()}>
            {this.getValue()}
          </span>
        </span>
      </TableCell>
    );
  }
};
