'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#projectCharterLayoutTemplate',
    regions: {
      projectCharterBody: '[data-region="projectCharterBody"]',
      modal: '[data-region="projectCharterDialog"]',
    },
  });
});
