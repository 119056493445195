/* eslint-disable react/no-access-state-in-setstate,class-methods-use-this,react/jsx-no-bind,
 jsx-a11y/control-has-associated-label,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

/**
 * Yearly calendar component to pick up a month.
 */
class MonthPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleYear: this.getYear(this.props.date),
    };
  }

  handlePrevClick() {
    const year = moment(this.state.visibleYear, 'YYYY').subtract(1, 'years').format('YYYY');
    this.setState({
      visibleYear: year,
    });
  }

  handleNextClick() {
    const year = moment(this.state.visibleYear, 'YYYY').add(1, 'years').format('YYYY');
    this.setState({
      visibleYear: year,
    });
  }

  getYear(date) {
    const momentDate = date ? moment(date) : moment();
    return momentDate.format('YYYY');
  }

  getMonthStyle(date) {
    const isSameMonth = this.props.date ? moment(this.props.date).isSame(date, 'month') : false;
    const isDisabled = this.isDisabled(date);
    let style = 'wethod-month-picker__month';
    if (isSameMonth) {
      style += ' wethod-month-picker__month--selected';
    }
    if (isDisabled) {
      style += ' wethod-month-picker__month--disabled';
    }
    return style;
  }

  getMonths() {
    return moment.monthsShort().map((month) => {
      const date = moment().date(1).month(month).year(this.state.visibleYear);
      return (
        <div key={date.format('MMM YYYY')}>
          <button type="button"
            disabled={this.isDisabled(date)}
            className={this.getMonthStyle(date)}
            onClick={(e) => !this.isDisabled(date) && this.props.onMonthClick(date, e)}>
            {month}
          </button>
        </div>
      );
    });
  }

  isDisabled(date) {
    let disabled = false;
    if (this.props.disableBefore) {
      disabled = disabled || moment(date).isBefore(this.props.disableBefore, 'month');
    }
    if (this.props.disableBeforeEqual) {
      disabled = disabled || moment(date).isSameOrBefore(this.props.disableBeforeEqual, 'month');
    }
    if (this.props.disableAfter) {
      disabled = disabled || moment(date).isAfter(this.props.disableAfter, 'month');
    }
    if (this.props.disableAfterEqual) {
      disabled = disabled || moment(date).isSameOrAfter(this.props.disableAfterEqual, 'month');
    }
    return disabled;
  }

  render() {
    return (
      <div className="wethod-month-picker">
        <div className="wethod-month-picker__header">
          <span>{this.state.visibleYear}</span>
          <div>
            <button type="button"
              onClick={this.handlePrevClick.bind(this)}
              className="wethod-icon-back" />
            <button type="button"
              onClick={this.handleNextClick.bind(this)}
              className="wethod-icon-forward" />
          </div>
        </div>
        <div className="wethod-month-picker__grid">
          {this.getMonths()}
        </div>
      </div>
    );
  }
}

MonthPicker.defaultProps = {
  disableBefore: undefined,
  disableBeforeEqual: undefined,
  disableAfter: undefined,
  disableAfterEqual: undefined,
};

MonthPicker.propTypes = {
  /**
   * The selected date shown in the calendar. If not specified, no date will be selected.
   */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment), PropTypes.string]),

  /**
   * Function called when a month is selected. A full date corresponding the first day of the month
   * will be given.
   */
  onMonthClick: PropTypes.func.isRequired,
  /**
   * If specified, all months before this date will be disabled.
   */
  disableBefore: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, this date month and all months before will be disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, all months after this date will be disabled.
   */
  disableAfter: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, this date month and all months after will be disabled.
   */
  disableAfterEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
};

module.exports = MonthPicker;
