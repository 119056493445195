const React = require('react');
const ConfirmDelete = require('../containers/ConfirmDeleteModal');
const NewRequest = require('../containers/NewRequestModal');
const StartReview = require('./StartReviewModal.react');

const Modal = ({ toShow, closeModal, data }) => {
  const getModal = () => {
    switch (toShow) {
      case 'confirm-delete':
        return <ConfirmDelete onCancelClick={closeModal} data={data} />;
      case 'new-request':
        return <NewRequest onCancelClick={closeModal} data={data} />;
      case 'request-sent':
        return <StartReview onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
