const React = require('react');
const Section = require('../../../../common/components/Section.react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const Mobile = require('../../../../../../../../../common/react/media-queries/Mobile.react');
const BrewedDate = require('../../BrewedDate.react');
const Image1 = require('../../../../../../../../../../img/illustrations/briefing_monthly_1.svg');
const Image2 = require('../../../../../../../../../../img/illustrations/briefing_monthly_2.svg');
const Image3 = require('../../../../../../../../../../img/illustrations/briefing_monthly_3.svg');
const Image4 = require('../../../../../../../../../../img/illustrations/briefing_monthly_4.svg');
const Image5 = require('../../../../../../../../../../img/illustrations/briefing_monthly_5.svg');
const Image6 = require('../../../../../../../../../../img/illustrations/briefing_monthly_6.svg');
const Image7 = require('../../../../../../../../../../img/illustrations/briefing_monthly_7.svg');
const Image8 = require('../../../../../../../../../../img/illustrations/briefing_monthly_8.svg');
const Image9 = require('../../../../../../../../../../img/illustrations/briefing_monthly_9.svg');
const Image10 = require('../../../../../../../../../../img/illustrations/briefing_monthly_10.svg');
const Image11 = require('../../../../../../../../../../img/illustrations/briefing_monthly_11.svg');
const Image12 = require('../../../../../../../../../../img/illustrations/briefing_monthly_12.svg');

class Title extends React.Component {
  getImage() {
    const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10,
      Image11, Image12];

    return (
      <img className="briefing-content__intro-image"
        alt="Monthly briefing"
        src={images[this.props.date.month()]} />
    );
  }

  render() {
    return (
      <Section>
        <h2 className="briefing-content__week-number">
          <span>About</span>
          {' '}
          {this.props.date.format('MMMM YYYY')}
        </h2>
        <Mobile><BrewedDate date={this.props.date} timeToRead={this.props.timeToRead} /></Mobile>
        <Paragraph>
          {this.getImage()}
        </Paragraph>
      </Section>
    );
  }
}

module.exports = Title;
