const React = require('react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');

/**
 * Apply special style to text representing a holiday.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const HolidayTextFormatter = ({ children }) => (
  <Typography color={Typography.COLORS.SUNSET_ORANGE} size={Typography.SIZES.INHERIT}>
    {children}
  </Typography>
);

module.exports = HolidayTextFormatter;
