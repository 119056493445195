const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../modal2/Modal.react');
const Body = require('./CannotSyncModalBody.react');

const CannotSyncModal = ({ onCancelClick, createFolder, path, isWaiting }) => (
  <Modal title="Missing Google Drive folder"
    onCancelClick={onCancelClick}
    className="project-canvas-alert">
    <Body createProjectFolder={createFolder}
      path={path}
      waiting={isWaiting} />
  </Modal>
);

CannotSyncModal.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  isWaiting: PropTypes.bool.isRequired,
};

module.exports = CannotSyncModal;
