/* eslint-disable jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class Card extends React.Component {
  handleEditClick(e) {
    e.preventDefault();
    this.props.onEditClick();
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.onSaveClick();
  }

  getEditButton() {
    const { editable } = this.props;
    const allowEdit = this.props.allowEdit ? this.props.allowEdit : false;
    if (allowEdit) {
      if (editable) {
        return (
          <a
            href="#"
            className="clients__delete-button wethod-icon-button"
            onClick={this.handleSaveClick.bind(this)}>
            <div className="wethod-icon-medium-checked wethod-icon-medium-checked--black" />
          </a>
        );
      }

      return (
        <a
          href="#"
          className="clients__delete-button wethod-icon-button"
          onClick={this.handleEditClick.bind(this)}>
          <div className="wethod-icon-medium-edit wethod-icon-medium-edit--black" />
        </a>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-card">
        <div className="wethod-card__header">
          <span className="wethod-collapsible-card__title">{this.props.feedback}</span>
          {this.getEditButton()}
        </div>
        <div className="wethod-card__content">{this.props.children}</div>
      </div>
    );
  }
};
