'use strict';

Wethod.module('HeaderApp', function (HeaderApp, Wethod, Backbone, Marionette, $) {
  this.AuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var fragment = APIURL + '/authorization/settings/apppermissions?app=header';
      return fragment;
    },
  });

  this.EconomicsAuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/authorization/settings/apppermissions?app=income_statement';
    },
  });

  var API = {
    getAuthorization: function () {
      var getAuthorization = new HeaderApp.AuthorizationModel();
      var defer = $.Deferred();

      getAuthorization.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
      });
      return defer.promise();
    },
    getEconomicsAuthorization: function () {
      var getAuthorization = new HeaderApp.EconomicsAuthorizationModel();
      var defer = $.Deferred();

      getAuthorization.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:header:authorization', function () {
    return API.getAuthorization();
  });

  Wethod.reqres.setHandler('get:economics:authorization', function () {
    return API.getEconomicsAuthorization();
  });
});
