const { connect } = require('react-redux');
const Component = require('../components/ContactInfo.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  email: state.email,
  telephone: state.person.telephone,
  linkedin: state.person.linkedin,
  gender: state.person.gender,
  birthday: state.person.birthday,
  isWaitingForGender: state.waitingForGender,
});

const mapDispatchToProps = (dispatch) => ({
  getGenders: () => dispatch(actions.getGenders()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
