/* eslint-disable react/prop-types,react/sort-comp */
const React = require('react');
const TraspImage = require('../../../img/icon/trasp.gif');

module.exports = class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: this.props.keyword ? this.props.keyword : '',
    };

    this.timer = 0;
    this.lastKeyword = '';

    this.handleChange = this.handleChange.bind(this);
  }

  hasChanged() {
    return this.state.keyword.trim().toLowerCase() !== this.lastKeyword.trim().toLowerCase();
  }

  handleChange(e) {
    const keyword = e.target.value;

    this.setState({ keyword }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.hasChanged()) {
          this.lastKeyword = this.state.keyword;
          this.props.filter(this.state.keyword);
        }
      }, 300);
    });
  }

  getLoading() {
    if (this.props.isLoading) {
      return (
        <div className="search-input__loading">
          <img src={TraspImage} alt="Loading..." />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="search-input">
        <div className="wethod-icon-medium-search wethod-icon-medium-search--black" />
        <input type="text"
          placeholder={this.props.placeholder}
          value={this.state.keyword}
          onChange={this.handleChange} />
        {this.getLoading()}
      </div>
    );
  }
};
