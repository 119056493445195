const { connect } = require('react-redux');
const FocusedEvent = require('../../components/EventsFuture/FocusedEvent.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  events: state.filteredEvents,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (event) => dispatch(actions.showDeleteEventModal(event)),
  openEditForm: (event) => dispatch(actions.openEditEventForm(event)),
  closeForm: () => dispatch(actions.closeEditEventForm()),
  save: (event) => dispatch(actions.updateEvent(event)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(FocusedEvent);
