const React = require('react');
const EnableAction = require('./EnableMulticurrencyAction.react');
const DisableAction = require('./DisableMulticurrencyAction.react');

const MulticurrencyAction = ({
  enabled,
  currenciesAvailable,
  budgetIsEditable,
  showModalDisableMulticurrency,
  showModalEnableMulticurrency,
  isIntercompanySupplier,
}) => {
  if (!currenciesAvailable) {
    return null;
  }

  if (enabled) {
    return (
      <DisableAction disabled={!budgetIsEditable}
        showModalDisableMulticurrency={showModalDisableMulticurrency} />
    );
  }

  return (
    <EnableAction disabled={!budgetIsEditable || isIntercompanySupplier}
      showModalEnableMulticurrency={showModalEnableMulticurrency} />
  );
};

module.exports = MulticurrencyAction;
