/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class EmptyPlan extends React.Component {
  render() {
    return (
      <div className="production-plan-project-row production-plan-project-row--empty">
        <span>
          There are no projects yet, try adding one to your{' '}
          <a href="#pipeline/projects">pipeline</a>
        </span>
      </div>
    );
  }
};
