const { connect } = require('react-redux');
const Component = require('../../components/advanced-search/PipelineAdvancedFilters.react');

const mapStateToProps = (state) => ({
  availableMetadata: state.metadata.map((metadataItem) => ({
    ...metadataItem,
    availableValues: metadataItem.availableValues.map((value) => ({
      label: value,
      value,
    })),
  })),
});

module.exports = connect(mapStateToProps)(Component);
