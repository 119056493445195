/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class EmptyFuture extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty project-canvas__goal-empty">
        <div className="project-canvas-empty__text-action wethod-widget__empty-message-link">
          <div className="briefing-empty__future" />
        </div>
        <div className="project-canvas-empty__text">
          <div className="project-canvas-empty__text-title">Don't wait, make it happen!</div>
          <div className="project-canvas-empty__text-description">Come back next Monday for more updates</div>
        </div>
      </div>
    );
  }
};
