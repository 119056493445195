const { connect } = require('react-redux');
const Component = require('../../components/Kudos/TrendWidget.react');

const mapStateToProps = (state) => ({
  year: state.kudos.year,
  title: 'As PM',
  data: state.kudos.as_pm,
});

module.exports = connect(mapStateToProps)(Component);
