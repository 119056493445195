const { connect } = require('react-redux');
const Component = require('../components/NewRequestModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'last-review').length > 0,
  isSending: state.waitingFor.filter((key) => key === 'save-review').length > 0,
  lastReview: state.lastReview,
  year: state.year,
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmClick: (projectId, year) => dispatch(actions.createReviewRequest(projectId, year)),
  getLastReview: (projectId) => dispatch(actions.getLastReview(projectId)),
  onCancelClick: () => dispatch(actions.closeModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
