'use strict';

var ModuleLoadingView = require('../../../common/marionette/loading/views/ModuleLoadingView');

Wethod.module('View.Loading', function (Loading, Wethod, Backbone, Marionette) {
  /**
   * Module loading view (big spinning 'W' with sentences)
   */
  this.BigView = ModuleLoadingView;

  /**
   * Small loading view (small spinning 'W')
   */
  this.SmallView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });
});
