const React = require('react');
const List = require('../containers/BudgetChangelogList');
const Modal = require('../containers/Modal');
const Actions = require('../containers/Actions');

const BudgetChangelog = ({
  size,
  keyword,
  orderBy,
  sort,
  bu,
  filter,
  loadChangelogs,
}) => {
  function changeBu(name, businessUnit) {
    return filter(size, keyword, orderBy, sort, businessUnit);
  }

  function loadMore(sizeToLoad, page) {
    return loadChangelogs(sizeToLoad, page, keyword, orderBy, sort, bu);
  }

  function filterKeyword(key) {
    return filter(size, key, orderBy, sort, bu);
  }

  function sortChangelogs(column, order) {
    return filter(size, keyword, column, order, bu);
  }

  return (
    <div className="wethod-section-body budget-changelog">
      <Actions changeBU={changeBu} />
      <List filter={filterKeyword} loadMore={loadMore} sort={sortChangelogs} />
      <Modal />
    </div>
  );
};

module.exports = BudgetChangelog;
