const { connect } = require('react-redux');
const Component = require('../../../components/dashboard/alerts/AlertsTable.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.includes('alerts'),
  items: state.alertsData.items,
  search: state.alertsData.search,
  filters: state.alertsData.filters,
  offset: state.alertsData.offset,
  limit: state.alertsData.limit,
  hasMorePages: state.alertsData.hasMorePages,
  sort: state.alertsData.sort,
  order: state.alertsData.order,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  getItems:
    (offset, limit, search, order, sort, filters) => dispatch(actions
      .getAlerts(offset, limit, search, order, sort, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
