const React = require('react');
const ProjectInfoSecondaryGroup = require('./ProjectInfoSecondaryGroup.react');
const HeaderBoxSecondaryInfo = require('../HeaderBox/HeaderBoxSecondaryInfo.react');

/**
 * Used in ProjectInfo to display secondary info.
 * @param pm
 * @param duration
 * @param account
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectInfoSecondarySection = ({ pm, duration, account }) => (
  <HeaderBoxSecondaryInfo>
    <ProjectInfoSecondaryGroup label="PM" value={pm} />
    <ProjectInfoSecondaryGroup label="Account" value={account} />
    <ProjectInfoSecondaryGroup label="Duration" value={duration} />
  </HeaderBoxSecondaryInfo>
);

module.exports = ProjectInfoSecondarySection;
