module.exports.GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
module.exports.GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';

module.exports.FILTER_ORDERS_REQUEST = 'FILTER_ORDERS_REQUEST';
module.exports.FILTER_ORDERS_SUCCESS = 'FILTER_ORDERS_SUCCESS';

module.exports.UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
module.exports.UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
module.exports.UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';
module.exports.CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

module.exports.DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
module.exports.DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
module.exports.DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

module.exports.SEND_ORDER_REQUEST = 'SEND_ORDER_REQUEST';
module.exports.SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS';
module.exports.SEND_ORDER_ERROR = 'SEND_ORDER_ERROR';

module.exports.APPROVE_ORDER_REQUEST = 'APPROVE_ORDER_REQUEST';
module.exports.APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS';

module.exports.SET_PAID_ORDER_REQUEST = 'SET_PAID_ORDER_REQUEST';
module.exports.SET_PAID_ORDER_SUCCESS = 'SET_PAID_ORDER_SUCCESS';

module.exports.GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
module.exports.GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';

module.exports.GET_PROJECT_AREAS_REQUEST = 'GET_PROJECT_ARES_REQUEST';
module.exports.GET_PROJECT_AREAS_SUCCESS = 'GET_PROJECT_AREAS_SUCCESS';

module.exports.GET_PROJECT_CURRENCIES_REQUEST = 'GET_PROJECT_CURRENCIES_REQUEST';
module.exports.GET_PROJECT_CURRENCIES_SUCCESS = 'GET_PROJECT_CURRENCIES_SUCCESS';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.SHOW_EXPORT_MODAL = 'SHOW_EXPORT_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.EXPORT_REQUEST = ' EXPORT_REQUEST';
module.exports.EXPORT_SUCCESS = ' EXPORT_SUCCESS';

module.exports.UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

module.exports.DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';

module.exports.RESET_ORDER_SERVER_ERROR = 'RESET_ORDER_SERVER_ERROR';
