/* eslint-disable no-shadow */
const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const Options = require('../containers/OptionsCell');

const ReviewRequest = ({ review }) => {
  const projectName = review && review.project_entity ? review.project_entity.name : null;
  const userName = review && review.requested_by ? review.requested_by.name.capitalize() : null;
  const userSurname = review && review.requested_by
    ? review.requested_by.surname.capitalize() : null;
  const date = review && review.requested_on ? review.requested_on.date : null;

  const requestedOn = moment(date).format('DD/MM/YYYY - HH:MM');

  const total = review ? review.total_reviews : 0;
  const submitted = review ? review.received_reviews : 0;

  const reviewStyle = (total, submitted) => {
    const style = 'review-changelog__column-total';
    return total === submitted ? `${style}--completed` : `${style}--missing`;
  };

  return (
    <TableRow>
      <TableCell className="review-changelog__column-project">{projectName}</TableCell>
      <TableCell className="review-changelog__column-employee">{userName} {userSurname}</TableCell>
      <TableCell className="review-changelog__column-date">{requestedOn}</TableCell>
      <TableCell className="review-changelog__column-total"><span className={reviewStyle(total, submitted)}>{submitted}/{total}</span></TableCell>
      <Options review={review} />
    </TableRow>
  );
};

module.exports = ReviewRequest;
