/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Navigator extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__calendar-navigator">
        <button
          onClick={this.props.moveToNow}
          className="wethod-button wethod-calendar-table__navigator-button--now"
          type="button">
          Today
        </button>
        <span
          onClick={this.props.moveBack}
          className="wethod-calendar-table__navigator-button wethod-calendar-table__navigator-button--first wethod-icon-medium-back wethod-icon-medium-back--black" />
        <span
          onClick={this.props.moveOn}
          className="wethod-calendar-table__navigator-button wethod-icon-medium-forward wethod-icon-medium-forward--black" />
      </div>
    );
  }
};
