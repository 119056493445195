const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../FilterAccordion/FilterAccordion.react');

require('./style.scss');

const RangeFilter = ({
  name, value, placeholder, onChange, expanded, label, className, suffix,
  errors, updateErrors, input, constraints, inputProps,
}) => {
  const handleChange = (nextValue) => {
    const clearValue = {};

    // Remove empty properties
    Object.keys(nextValue).forEach((key) => {
      if (nextValue[key] !== '') {
        clearValue[key] = nextValue[key];
      }
    });

    onChange(name, clearValue);
  };

  function handleMinChange(e) {
    const nextPartialValue = e.target.value;
    const nextValue = {
      ...value,
      min: nextPartialValue,
    };
    handleChange(nextValue);
  }

  function handleMaxChange(e) {
    const nextPartialValue = e.target.value;
    const nextValue = {
      ...value,
      max: nextPartialValue,
    };
    handleChange(nextValue);
  }

  const Input = input;

  const getValue = (type) => (value[type] === undefined ? '' : value[type]);

  return (
    <Accordion expanded={expanded} label={label} value={value} className={className}>
      <div className="wethod-advanced-search-filter-range__content">
        <Input onChange={handleMinChange}
          errorText={errors[`min-${name}`]}
          value={getValue('min')}
          suffix={suffix}
          name={`min-${name}`}
          placeholder={placeholder.min}
          constraints={constraints}
          updateErrors={updateErrors}
          {...inputProps} />
        <Input onChange={handleMaxChange}
          suffix={suffix}
          constraints={constraints}
          updateErrors={updateErrors}
          errorText={errors[`max-${name}`]}
          value={getValue('max')}
          name={`max-${name}`}
          placeholder={placeholder.max}
          {...inputProps} />
      </div>
    </Accordion>
  );
};

RangeFilter.defaultProps = {
  expanded: false,
  label: '',
  className: '',
  suffix: null,
  value: {},
  placeholder: {
    min: 'Min',
    max: 'Max',
  },
  onChange: null,
  errors: {},
  updateErrors: null,
  constraints: [],
  inputProps: {},
};

RangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  value: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    max: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  placeholder: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {{}}
   */
  onChange: PropTypes.func,
  /**
   * Function to update form errors.
   * @param name {string}
   * @param error {{}}
   */
  updateErrors: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  suffix: PropTypes.node,
  input: PropTypes.func.isRequired,
  constraints: PropTypes.arrayOf(PropTypes.string),
  /**
   * Additional props given to the input
   */
  inputProps: PropTypes.shape(),
};

module.exports = RangeFilter;
