const { connect } = require('react-redux');
const Component = require('../components/OptionsCell.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  showDeleteConfirmModal: (data) => dispatch(actions.showModal('confirm-delete', data)),
  showNewRequestModal: (data) => dispatch(actions.showModal('new-request', data)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
