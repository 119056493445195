const { connect } = require('react-redux');
const Component = require('../../components/modals/MoveSupplierModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isDeleting: state.waitingFor.filter((key) => key === 'delete-supplier').length > 0,
  statusEnabled: state.statusEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  moveSupplier: (oldSupplier, newSupplier) => dispatch(actions
    .moveSupplier(oldSupplier, newSupplier)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
