const React = require('react');
const _ = require('underscore');
const ReadOnlyField = require('./ReadOnlyField.react');

const ReadOnlyProjectField = ({ project, placeholder }) => {
  const icon = <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />;
  const getUnescapedValue = (value) => _.unescape(value);
  const getProjectName = () => (project
    ? getUnescapedValue(project.name) : null);
  return (
    <ReadOnlyField value={getProjectName()} placeholder={placeholder} icon={icon} />
  );
};

module.exports = ReadOnlyProjectField;
