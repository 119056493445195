const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const PV = require('../../../../common/components/PercentageValue.react');

module.exports = ({
  month, prevMonth, workedHours, chargeableOnWorked, prevMonthChargeableOnWorked,
}) => (
  <Paragraph>
    The total number of worked hours is equal to
    {' '}
    <NV value={workedHours} />
    . Chargeable hours went
    from
    {' '}
    <PV value={prevMonthChargeableOnWorked} />
    {' '}
    in
    {' '}
    {prevMonth}
    {' '}
    to
    {' '}
    <PV value={chargeableOnWorked} />
    {' '}
    in
    {' '}
    {month}
    .
  </Paragraph>
);
