const { connect } = require('react-redux');
const Component = require('../../components/Files/FileManager.react');

const mapStateToProps = (state) => ({
  canEdit: state.canEdit,
  projectId: state.info.id,
  projectName: state.info.name,
  clientName: state.info.client.corporate_name,
  externalDriveConfig: state.externalDriveConfig,
});

module.exports = connect(mapStateToProps)(Component);
