/* eslint-disable class-methods-use-this */
const Clients = require('./Clients');

module.exports = class Companies extends Clients {
  filter(collection, key) {
    return collection.filter((item) => {
      const foundInName = this.found(key, item.name);
      const foundInGroup = item.group ? this.found(key, item.group.name) : false;
      const foundInAcronym = item.acronym ? this.found(key, item.acronym) : false;
      const foundInExternalId = item.external_ids && item.external_ids.length
        ? this.found(key, item.external_ids[0].external_id)
        : false;
      return foundInName || foundInGroup || foundInAcronym || foundInExternalId;
    });
  }

  order(collection, key, order) {
    return collection.sort((a, b) => {
      let valueA; let
        valueB;
      if (key === 'group') {
        valueA = a.group ? a.group.name : null;
        valueB = b.group ? b.group.name : null;
      } else {
        valueA = a[key];
        valueB = b[key];
      }

      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA.toLowerCase() > valueB.toLowerCase()) return order === 'asc' ? 1 : -1;
      if (valueA.toLowerCase() < valueB.toLowerCase()) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }
};
