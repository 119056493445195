const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const Link = require('../../../../common/components/Link.react');

module.exports = ({ clients }) => {
  const getClient = (client) => (
    <span key={client.id}>
      <Link
        href={`#finance/client/company/${client.id}`}>
        {_.unescape(client.name)}
      </Link>
    </span>
  );

  const getClients = () => {
    const list = clients.map((client) => getClient(client));
    return <List list={clients} terminator=".">{list}</List>;
  };

  if (clients.length) {
    return (
      <Paragraph>
        These are the <span className="bold">new clients</span> acquired last month: {getClients()}
      </Paragraph>
    );
  }
  return null;
};
