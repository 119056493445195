/* eslint-disable react/prop-types,react/no-did-update-set-state,react/sort-comp */
const React = require('react');

/**
 * An input of type range, enhanced with a stiled progress working in each browser.
 *
 * LIFECYCLE HOOKS
 * onChange.
 *
 * OTHER PROPS
 * - name
 * - min
 * - max
 * - value
 * - disabled
 *
 * @type {module.Slider}
 */
module.exports = class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.props.value) { // If props.value is updated bby a parent
      this.setState({ value: this.props.value });
    }
    if (this.state.value > this.props.max) { // If the given max is < value
      if (this.props.onChange) this.props.onChange(this.props.name, this.props.max);
    }
  }

  getMin() {
    if (this.props.min) return this.props.min;
    return 0;
  }

  handleChange(e) {
    this.setState({ value: e.target.value }, () => {
      if (this.props.onChange) this.props.onChange(this.props.name, this.state.value);
    });
  }

  getProgressStyle() {
    const progress = (this.props.value / this.props.max) * 100;
    return {
      width: `${progress}%`,
    };
  }

  getClassName() {
    let name = 'wethod-slider';
    if (this.props.disabled) {
      name += ' wethod-slider--disabled';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="wethod-slider__track" />
        <div className="wethod-slider__progress"
          style={this.getProgressStyle()} />
        <input type="range"
          min={this.getMin()}
          max={this.props.max}
          value={this.state.value}
          name={this.props.name}
          onChange={this.handleChange.bind(this)}
          disabled={this.props.disabled}
          className="wethod-slider__input" />
      </div>
    );
  }
};
