/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return,react/no-array-index-key */
const React = require('react');
const formatters = require('../../../utilities/formatters');
const tokenize = require('../../../utilities/tokenize');

module.exports = class ProjectLog extends React.Component {
  constructor(props) {
    super(props);

    this.changeable = ['probability', 'estimate'];
  }

  /**
   * Returns all the changeable that which are actually changed.
   * @returns {Array}
   */
  getWhatsChanged() {
    const changes = [];
    for (let i = 0; i < this.changeable.length; i++) {
      const prevValue = this.props[`${this.changeable[i]}_from`];
      const currentValue = this.props[`${this.changeable[i]}_to`];
      if (prevValue !== currentValue) changes.push(this.changeable[i]);
    }
    return changes;
  }

  /**
   * Returns the currents value of everything's changed.
   * @param names
   */
  getCurrentValues(names) {
    return names.map((name) => this.props[`${name}_to`]);
  }

  /**
   * Given a value in K, this method returns the unit value already formatted as 100.000.
   * @param value
   * @returns {*}
   */
  getUnitValue(value) {
    return formatters.getFormattedMoneyValue(value * 1000);
  }

  /**
   * Returns true if changes contains changeableString.
   * @param changes
   * @param changeableString
   * @returns {boolean}
   */
  areChangesIn(changes, changeableString) {
    return changes.toString().indexOf(changeableString) !== -1;
  }

  /**
   * Returns a message which describes everything's changed.
   * @returns {string}
   */
  getMessage() {
    const changes = this.getWhatsChanged();
    const values = this.getCurrentValues(changes);
    if (this.areChangesIn(changes, 'probability,estimate')) {
      return `Probability changed to ${values[0]} % and project's value changed to ${this.getUnitValue(values[1])} `;
    } if (this.areChangesIn(changes, 'probability')) {
      return `Probability changed to ${values[0]} %`;
    } if (this.areChangesIn(changes, 'estimate')) {
      return `Project's value changed to ${this.getUnitValue(values[0])}`;
    }
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    const updatedBy = this.props.updated_by;
    if (updatedBy) {
      return `${updatedBy.name} ${updatedBy.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  getProjectLink() {
    return <a href={`#timeline/${this.props.project.id}`}>{this.getHighlightedContent(_.unescape(this.props.project.name))}</a>;
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    if (this.getWhatsChanged().length > 0) {
      return (
        <div className="timeline-event-log">
          <div className="timeline-event-past__type-logo-container">
            <div className="wethod-icon-pipeline wethod-icon-pipeline--black" />
          </div>
          <div className="timeline-event-log__type">
            <div className="timeline-event-log__type-name">{this.getMessage()}</div>
          </div>
          <div className="timeline-event-log__creation">
            {this.getProjectLink()}
          </div>
          <div className="timeline-event-log__creation">
            {this.getCreation()}
          </div>
        </div>
      );
    }
    return null;
  }
};
