const request = require('./Request');

const Group = {
  getCompanies() {
    return request({
      method: 'GET',
      url: '/intercompany/company',
    });
  },
};

module.exports = Group;
