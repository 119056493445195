const React = require('react');

const HeaderTitle = ({ children, highlighted }) => {
  const className = highlighted ? 'wethod-budget-calculator__header-title wethod-budget-calculator__header-title--highlighted' : 'wethod-budget-calculator__header-title';
  return (
    <div className={className}>{children}</div>
  );
};

module.exports = HeaderTitle;
