const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const WorkHourCapacity = {
  list(filters = {}) {
    let url = '/work-hour-capacity';
    const queryString = HTTPService.buildQueryString(filters);
    url += queryString ? `?${queryString}` : '';

    return request({
      method: 'get',
      url,
    });
  },
  get(id) {
    return request({
      method: 'get',
      url: `/work-hour-capacity/${id}`,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/work-hour-capacity',
      data,
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/work-hour-capacity/${id}`,
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/work-hour-capacity/${id}`,
    });
  },
};

module.exports = WorkHourCapacity;
