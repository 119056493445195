const { connect } = require('react-redux');
const Component = require('../../components/modals/DeleteWorkHourCapacityConfirmationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor.filter((key) => key.startsWith('delete-work-hour-capacity-')),
  workHourCapacityEmployees: state.workHourCapacityEmployees,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteWorkHourCapacityConfirmationConfirm: (id) => {
    dispatch(actions.deleteWorkHourCapacity(id));
  },
  onDeleteWorkHourCapacityConfirmationCancel: (workHourCapacityEmployees) => {
    dispatch(actions.setWorkHourCapacityEmployees(workHourCapacityEmployees));
    dispatch(actions.closeModal());
  },
  getWorkHourCapacityEmployees: (id) => {
    dispatch(actions.getWorkHourCapacityEmployees(id));
  },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
