const request = require('./Request');

const SectionSegment = {
  getAll(section) {
    return request({
      method: 'get',
      url: `/segment?section=${section}`,
    });
  },
  update(id, changes) {
    return request({
      method: 'patch',
      url: `/segment/${id}`,
      data: changes,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/segment/',
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/segment/${id}`,
    });
  },
};

module.exports = SectionSegment;
