const React = require('react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const Content = require('./MakeTaskIntercompanyModalContent.react');

const MakeTaskIntercompanyModal = ({
  closeModal, task, days, markup,
  showSelectIntercompanyTaskSupplier, jobTitles,
}) => {
  function onConfirmClick() {
    showSelectIntercompanyTaskSupplier(task);
  }

  const isTaskEligible = !days && !markup && jobTitles.length === 0;

  return (
    <Modal title="Intercompany task" onClose={closeModal}>
      <Content
        closeModal={closeModal}
        onConfirmClick={onConfirmClick}
        isTaskEligible={isTaskEligible} />
    </Modal>
  );
};

module.exports = MakeTaskIntercompanyModal;
