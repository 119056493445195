const { connect } = require('react-redux');
const Component = require('../components/Supplier.react');

const mapStateToProps = (state) => ({
  info: state.info,
  showSidebar: state.showSidebar,
  showRating: state.canSeeRating,
});

module.exports = connect(mapStateToProps)(Component);
