'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Authorization = require('../../models/Authorization');
var DataFreezingModel = require('../../models/DataFreezingSegment');
var DataFreezingEngineStore = require('../../apps/core/modules/data-freezing-engine/store');
var DataFreezingEngineReducer = require('../../apps/core/modules/data-freezing-engine/reducer');
var DataFreezingEngine = require('../../apps/core/modules/data-freezing-engine');
var FeatureService = require('../../services/FeatureService');

Wethod.module('DataFreezingApp.DataFreezing', function (DataFreezing, Wethod, Backbone, Marionette, $) {
  DataFreezing.Controller = {
    showNoPermission: function () {
      var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
      Wethod.regions.body.show(permissionDeniedView);
    },
    showDataFreezingEngine: function () {
      $.when(
        Authorization.getPermissions('data_freezing'),
        DataFreezingModel.list()
      ).done(function (permissions, segments) {
        if (_.isUndefined(permissions) || !permissions.view || !Wethod.featureService
          .isEnabled(FeatureService.DATA_FREEZING)) {
          DataFreezing.Controller.showNoPermission();
        } else {
          var layout = new DataFreezing.StructureLayoutView();
          layout.render();

          var reducerWrapper = new DataFreezingEngineReducer({
            permissions: permissions,
            isEnabledDataFreezingFeature: Wethod.featureService
              .isEnabled(FeatureService.DATA_FREEZING),
            segments: segments,
          });
          var store = DataFreezingEngineStore(reducerWrapper.reduxReducer);
          var element = React.createElement(DataFreezingEngine, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        }
      }).fail(function () {
        DataFreezing.Controller.showNoPermission();
      });
    },
  };
});
