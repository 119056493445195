'use strict';

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#reportTemplate',
    regions: {
      reportBody: '[data-region="reportBody"]',
      reportModal: '[data-region="reportDialog"]',
    },
  });
});
