const { connect } = require('react-redux');
const Tags = require('../components/Tags.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  person: state.person,
  knownTags: state.knownTags,
});

const mapDispatchToProps = (dispatch) => ({
  addTag: (personId, tag) => dispatch(actions.addPersonTag(personId, tag)),
  deleteTag: (personId, tag) => dispatch(actions.deletePersonTag(personId, tag)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Tags);
