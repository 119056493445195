const { connect } = require('react-redux');
const Component = require('../components/Steps.react');
const actions = require('../actions');

const getMissingVotes = (criteria) => criteria.filter((criterion) => criterion.vote === 0);

/**
 * Return true if criteria have been partially evaluated.
 * @param criteria
 * @return {boolean}
 */
const hasIncompleteReview = (criteria) => {
  const missingVotes = getMissingVotes(criteria);
  return missingVotes.length && missingVotes.length !== criteria.length;
};

const getIncompletePeopleReviews = (people, allowMissingCriteria) => {
  if (allowMissingCriteria) return [];

  return people.filter((person) => hasIncompleteReview(person.criteria));
};

const hasIncompleteProjectReview = (project, allowMissingCriteria) => {
  if (allowMissingCriteria) return false;

  return project && getMissingVotes(project.criteria).length > 0;
};

const mapStateToProps = (state) => ({
  step: state.step,
  completedError: state.error === 'completed',
  hasMembers: state.people.length > 0,
  incompletePeopleReviews: getIncompletePeopleReviews(
    state.people,
    state.allowMissingPeopleCriteria,
  ),
  hasIncompleteProjectReview: hasIncompleteProjectReview(
    state.project,
    state.allowMissingProjectCriteria,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getReview: (id) => dispatch(actions.getReview(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
