const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const PV = require('../../../../common/components/PercentageValue.react');

module.exports = ({
  revenues, targetType, target, constOnTarget,
}) => {
  const getTrend = () => (constOnTarget > 0 ? <span>above</span> : <span>below</span>);
  const getOnTarget = () => {
    if (constOnTarget) {
      return (
        <span>
          , which are <PV value={Math.abs(constOnTarget)} /> {getTrend()} the monthly average
          {' '}
          {targetType} of <NV value={target} />
        </span>
      );
    }
    return null;
  };
  return (
    <Paragraph>
      Revenues reached <NV value={revenues} /> {getOnTarget()}.
    </Paragraph>
  );
};
