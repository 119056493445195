/* eslint-disable no-shadow */
const _ = require('underscore');

/**
 *
 * @param key
 * @param items
 * @returns {Array} items filtered by key
 */
module.exports = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  const found = (key, value) => {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  };

  // TODO: most of project's info contains html entities instead of utf character so now we need to
  // do the unescape;
  const isInClient = (key, project) => found(key, _.unescape(project.client));

  const isInName = (key, project) => found(key, _.unescape(project.name));

  const isInJobOrder = (key, project) => found(key, _.unescape(project.job_order));

  const isInProjectType = (key, project) => found(key, project.project_type.name);

  const isInPmName = (key, project) => found(key, project.pm.pm_name);

  const isInPmSurname = (key, project) => found(key, project.pm.pm_surname);

  return items.filter((item) => isInName(key, item.project)
    || isInClient(key, item)
    || isInJobOrder(key, item.project)
    || isInProjectType(key, item.project)
    || isInPmName(key, item.project)
    || isInPmSurname(key, item.project));
};
