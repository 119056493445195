'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BudgetStore = require('../../apps/core/modules/budget/store');
var BudgetReducer = require('../../apps/core/modules/budget/reducer');
var BudgetComponent = require('../../apps/core/modules/budget/index');
var PipelineItemModel = require('../../models/PipelineItem');
var AuthorizationModel = require('../../models/Authorization');
var GroupModel = require('../../models/Group');
var FeatureService = require('../../services/FeatureService');

Wethod.module('BudgetApp.Budget', function (Budget, Wethod) {
  Budget.Controller = {
    showBudget: function (projectId, version, withCorrection, idTour) {
      var project = null;
      var groupCompanies = [];
      var permissions = null;
      var projectStatusPermissions = null;

      var projectRequest = PipelineItemModel.get(projectId)
        .done(function (projectData) {
          project = projectData;
        });
      var groupCompaniesRequest = GroupModel.getCompanies()
        .done(function (companies) {
          groupCompanies = companies;
        });
      var permissionRequest = AuthorizationModel.getPermissions('budget')
        .done(function (permissionList) {
          permissions = permissionList;
        });
      var projectStatusPermissionRequest = AuthorizationModel.getPermissions('project_status')
        .done(function (permissionList) {
          projectStatusPermissions = permissionList;
        });

      // TODO make more readable
      // eslint-disable-next-line no-undef
      Promise.allSettled([projectRequest, groupCompaniesRequest, permissionRequest,
        projectStatusPermissionRequest])
        .finally(function () {
          var budgetLayout = new Budget.StructureLayoutView();
          budgetLayout.render();

          var sentence = Wethod.Utility
            .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
          var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
          budgetLayout.bodySection.show(loadingView);

          var reducerWrapper = new BudgetReducer({
            permissions: permissions,
            user: Wethod.userInfo.attributes,
            project: project,
            targetVersion: version,
            // Companies belonging to the same group and available as intercompany suppliers
            intercompanySuppliers: groupCompanies,
            projectStatusPermissions: projectStatusPermissions,
            isEnabledDataFreezingFeature: Wethod.featureService
              .isEnabled(FeatureService.DATA_FREEZING_PRODUCTION),
          });

          var store = BudgetStore(reducerWrapper.reduxReducer);
          var element = React.createElement(BudgetComponent, { store: store });
          var container = document.getElementById('budgetRoot');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
          if (projectId !== null) {
            dispatcher.trigger('tour:start', idTour);
          }
        });
    },
  };
});
