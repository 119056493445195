const React = require('react');
const Chart = require('../../containers/WorkingConnections/Chart');

module.exports = class WorkingConnections extends React.Component {
  componentDidMount() {
    this.props.getTrend(this.props.employee);
  }

  getContent() {
    if (this.props.data.length > 0) {
      return (
        <div className="profile-working-connections__content">
          <div className="profile-working-connections__header">
            <div className="profile-working-connections__header-col">
              <span>Project Manager</span>
            </div>
            <div
              className="profile-working-connections__header-col profile-working-connections__header-col--project-type">
              <span>Project Type</span>
            </div>
            <div className="profile-working-connections__header-col profile-working-connections__header-col--client">
              <span>Client</span>
            </div>
          </div>
          <Chart />
        </div>
      );
    }
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile-working-connections">
        <h4 className="profile-trends__item-header-subtitle">
          A distribution based on the timesheets you have done in the
          last 12 months
        </h4>
        {this.getContent()}
      </div>
    );
  }
};
