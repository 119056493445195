const { connect } = require('react-redux');
const Component = require('../../components/modals/EditStatusModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.includes('update-segments'),
  totals: state.warningsTotals,
  segments: state.segments,
});

const mapDispatchToProps = (dispatch) => ({
  save: (segments) => dispatch(actions.updateSegments(segments)),
  showIgnoreAlertsModal: (segments) => dispatch(actions.showIgnoreAlertsModal(segments)),
  showConfirmPauseModal: (segments) => dispatch(actions.showConfirmPauseModal(segments)),
  showConfirmFreezeModal: (segments) => dispatch(actions.showConfirmFreezeModal(segments)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
