/* eslint-disable class-methods-use-this */
const React = require('react');
const ProjectWeeks = require('./ProjectWeeks.react');

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  /**
   * Return a name formatted as "Client - Project Name"
   * @returns {string}
   */
  getName() {
    const { acronym } = this.props.project.client;
    let name = '';
    if (acronym) name += `${acronym.substring(0, 5).toUpperCase()} - `;
    return name + this.props.project.name;
  }

  /**
   * Dynamically calculates the the user's info box must be disabled or not.
   * @returns {string}
   */
  getInfoClass() {
    return 'project__info';
  }

  toggleVisibility() {
    this.props.toggleVisibility(this.props.project.id);
  }

  render() {
    const hideProjectStyle = this.props.project.visibility === false ? { color: '#DB4D69' } : {};
    const detailStyle = { borderLeft: `3px ${this.props.project.type.color} solid` };
    return (
      <div className="planning__project">
        <div className="project__details" style={detailStyle}>
          <div className="project__hide" style={hideProjectStyle} onClick={this.toggleVisibility}>x</div>
          <div className={this.getInfoClass()}>
            <div className="employee__name" title={this.getName()}>{this.getName()}</div>
          </div>
        </div>
        <ProjectWeeks
          projectId={this.props.id}
          days={this.props.days}
          eventTypes={this.props.eventTypes}
          visibility={this.props.project.visibility}
          eventAdded={this.props.eventAdded}
          eventDeleted={this.props.eventDeleted} />
      </div>
    );
  }
}

module.exports = Project;
