const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const PriceList = {
  list(filters = {}) {
    let url = '/price-list';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/price-list',
      data,
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/price-list/${id}`,
      data,
    });
  },
  getDailyCost(id) {
    return request({
      method: 'get',
      url: `/price-list/${id}/daily-cost`,
    });
  },
  updateBudgetAreaPriceList(priceListId, budgetAreaId) {
    return request({
      method: 'patch',
      url: `/budget-area/${budgetAreaId}/price-list/${priceListId}`,
    });
  },
};

module.exports = PriceList;
