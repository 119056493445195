const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize() {
    this.urlRoot = `${APIURL}/hr-insights/knowledge`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
