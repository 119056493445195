const { connect } = require('react-redux');
const Component = require('../components/ConfirmDeleteModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  project: state.focusedProject,
  waitingFor: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  deleteHours: (project) => dispatch(actions.deleteHoursWeekly(project, true)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
