const { connect } = require('react-redux');
const Component = require('../../components/modals/ImportSuccessModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  limit: state.pageLimit,
  offset: state.pageOffset,
  keyword: state.keyword,
  year: state.year,
});

const mapDispatchToProps = (dispatch) => ({
  reloadData: (offset, limit, search, year) => dispatch(actions
    .getItems(offset, limit, search, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
