const React = require('react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const ModalFooter = require('../../../../../../common/react/Modal/ModalFooter.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalFooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const Escaper = require('../../../../../../common/react/formatters/Escaper.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');

const ConfirmDeleteModal = ({ data, isSaving, onCloseClick, onDelete }) => {
  function handleDelete() {
    onDelete(data);
  }

  const projectName = data ? data.project.name : '';

  return (
    <Modal onClose={onCloseClick} title="Delete project">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are about to delete project
            <Typography weight="bold"><Escaper>{projectName}</Escaper></Typography> along with
            information which might be useful to the team.
          </ModalBodyBlock>
          <ModalBodyBlock>Are you sure you want to get rid of everything?</ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={isSaving ? 'Deleting...' : ''}>
          <ModalFooterAction onClick={onCloseClick}>Cancel</ModalFooterAction>
          <ModalFooterAction onClick={handleDelete}
            disabled={!data || !data.project.id || isSaving}>
            Yes
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmDeleteModal;
