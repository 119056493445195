/* eslint-disable react/sort-comp,no-param-reassign,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class TrendConversionRate extends React.Component {
  getTotal(months) {
    if (months.length) {
      const initialValue = {
        proposals: 0,
        projects: 0,
      };

      const total = months.reduce((sum, month) => ({
        proposals: sum.proposals + month.proposals,
        projects: sum.projects + month.projects,
      }), initialValue);

      return total.proposals ? (total.projects / total.proposals) * 100 : 0;
    }
    return null;
  }

  format(value) {
    return `${numeral(value).format('0,0.[0]')} %`;
  }

  tooltipFormatter(point) {
    return `<b>${this.format(point.y)}</b>`;
  }

  getTotalInFrame() {
    const value = this.getTotal(this.props.months);
    return this.format(value);
  }

  render() {
    return (
      <Trend
        totalInFrame={this.getTotalInFrame()}
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        title="Conversion rate"
        className="client-trend--conversion-rate"
        getTotal={this.getTotal.bind(this)}
        {...this.props} />
    );
  }
};
