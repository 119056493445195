const { connect } = require('react-redux');
const actions = require('../../../../../actions');
const Component = require('../../../../../components/Footer/DefaultFooterContent/Actions/BaselineAction/BaselineAction.react');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
});

const mapDispatchToProps = (dispatch) => ({
  showModalBaseline: () => dispatch(actions.showModalBaseline()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
