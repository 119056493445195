const React = require('react');
const Avatar = require('../../../../../../common/react/AvatarBox/Avatar.react');

const EmployeeInfo = ({ employee }) => {
  const getName = () => {
    const nameString = _.unescape(employee.name);
    const surnameString = _.unescape(employee.surname);

    return `${nameString} ${surnameString}`;
  };

  // Get no tags, one ore two tag names
  const tagString = () => {
    let tag = '';
    tag += employee.tags[0] ? employee.tags[0].name : '';
    tag += employee.tags[1] ? `, ${employee.tags[1].name}` : '';
    return tag;
  };

  return (
    <div className="knowledge__employee-info">
      <div className="chargeability__employee-pic">
        <Avatar name={getName()} pic={employee.pic} size="medium" />
      </div>
      <div className="chargeability__employee-info">
        <div>{getName()}</div>
        <div>{tagString()}</div>
        <div className="uppercase">{employee.current_level}</div>
      </div>
    </div>
  );
};

module.exports = EmployeeInfo;
