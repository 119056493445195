const React = require('react');
const AdvancedFilters = require('../../containers/advanced-search/PipelineAdvancedFilters');

const PipelineAdvancedSearch = ({
  show, filters, onClose, applySearch, type,
  getStandardFilters, getFiltersFromStandard, errors, updateErrors, isValid,
}) => {
  const sidebarToShow = show ? 'filters' : null;

  const standardFilters = getStandardFilters(filters);

  function handleSave(updatedFilters) {
    const formattedFilters = getFiltersFromStandard(updatedFilters);
    applySearch(formattedFilters);
  }

  return (
    <AdvancedFilters show={sidebarToShow}
      onClose={onClose}
      onSave={handleSave}
      filters={standardFilters}
      canSave={isValid}
      type={type}
      errors={errors}
      updateErrors={updateErrors} />
  );
};

module.exports = PipelineAdvancedSearch;
