const React = require('react');
const Files = require('../../../../../../../common/react/FileSection/FileSection.react');
const FileModel = require('../../../../../../../models/OrderFile');

module.exports = class FileSection extends React.Component {
  constructor(props) {
    super(props);

    this.onUploadError = this.onUploadError.bind(this);
    this.upload = this.upload.bind(this);
    this.download = this.download.bind(this);
    this.delete = this.delete.bind(this);

    this.state = {
      isUploading: null,
      waitingForFiles: [],
    };
  }

  onUploadError(errors) {
    this.setState({ isUploading: false });

    const error = errors && errors.length ? errors[0] : null;
    this.props.showCannotUploadFileModal(error);
  }

  download(file) {
    $.when(FileModel.download(file.id)).fail((response) => {
      if (response.code === 404) {
        this.props.onDeleteSuccess(this.props.item, file);
        this.props.showCannotDownloadFileModal('not-found');
      } else {
        this.props.showCannotDownloadFileModal('server-error');
      }
    });
  }

  delete(file) {
    this.setState((prevState) => {
      const waitingFor = prevState.waitingForFiles.concat(file.id);
      return { waitingForFiles: waitingFor };
    }, () => {
      this.setState((prevState) => {
        $.when(FileModel.delete(file.id)).done(() => {
          const waitingFor = prevState.waitingForFiles.filter((key) => key !== file.id);

          this.props.onDeleteSuccess(this.props.item, file);
          return { waitingForFiles: waitingFor };
        }).fail(() => {
          const waitingFor = prevState.waitingForFiles.filter((key) => key !== file.id);

          this.props.onDeleteSuccess(this.props.item, file);
          return { waitingForFiles: waitingFor };
        });
      });
    });
  }

  upload(uploadedFile) {
    this.setState({ isUploading: true });
    $.when(FileModel.upload(uploadedFile, this.props.item.id)).done((file) => {
      this.setState({ isUploading: false });
      this.props.onUploadSuccess(this.props.item, file);
    }).fail(() => {
      this.onUploadError(['network']);
    });
  }

  render() {
    return (
      <Files files={this.props.item.files}
        waitingForFiles={this.state.waitingForFiles}
        isUploading={this.state.isUploading}
        canDownload={this.props.canDownload}
        canUpload={this.props.canUpload}
        canDelete={this.props.canDelete}
        onUploadError={this.onUploadError}
        onUpload={this.upload}
        onDownload={this.download}
        onDelete={this.delete} />
    );
  }
};
