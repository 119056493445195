const { connect } = require('react-redux');
const BudgetAreaComponent = require('../components/BudgetArea.react');
const updateHours = require('../actions').saveHours;
const { openNoteModal } = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  employeeId: state.userId,
});

const mapDispatchToProps = (dispatch) => ({
  updateHours: (projectId, date, areaId, hours, note, employeeId) => dispatch(updateHours(projectId,
    date, areaId, hours, note, employeeId)),
  openNote: (area) => dispatch(openNoteModal(area)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(BudgetAreaComponent);
