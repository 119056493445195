/* eslint-disable react/sort-comp */
const React = require('react');
const Project = require('./WorkingConnectionsProject.react');

module.exports = class WorkingConnectionsBody extends React.Component {
  getProjects() {
    return this.props.projects.map((project) => <Project key={project.id} {...project} />);
  }

  onDoneClick() {
    this.props.hideModal();
  }

  render() {
    return (
      <div className="profile-working-connections__projects">
        <div className="wethod-modal2-list__header">
          <span className="wethod-modal2-list__header-item
          profile-working-connections__projects-item-col-name">
            Name
          </span>
          <span className="wethod-modal2-list__header-item profile-working-connections__projects-item-col-pm">PM</span>
          <span
            className="wethod-modal2-list__header-item profile-working-connections__projects-item-col-account">
            Account
          </span>
          <span
            className="wethod-modal2-list__header-item profile-working-connections__projects-item-col-client">
            Client
          </span>
          <span
            className="wethod-modal2-list__header-item profile-working-connections__projects-item-col-value">
            Value
          </span>
          <span
            className="wethod-modal2-list__header-item profile-working-connections__projects-item-col-hours">
            Hours
          </span>
        </div>
        <div className="profile-working-connections__collection">
          {this.getProjects()}
        </div>
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onDoneClick.bind(this)} className="wethod-button">Close</button>
        </div>
      </div>
    );
  }
};
