const { connect } = require('react-redux');
const Component = require('../../components/Activities/Activities.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  activities: state.activities,
  canEdit: state.canEdit,
  isWaiting: state.waitingFor.filter((key) => key === 'activities').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getActivities: () => dispatch(actions.getActivities()),
  updateStatus: (status, itemId, boardId, attributeId) => dispatch(actions
    .updateActivityStatus(status, itemId, boardId, attributeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
