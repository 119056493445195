const request = require('./Request');

const HolidayInstance = {
  list(from, to) {
    return request({
      method: 'get',
      url: `/holiday-instance?from=${from}&to=${to}`,
    });
  },
};

module.exports = HolidayInstance;
