/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NS = require('../../NumerableSentence.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');
const Link = require('../../../../common/components/Link.react');

class ClosedProjects extends React.Component {
  constructor(props) {
    super(props);

    this.alternatives = [
      <span>
        Instead,
        {' '}
        <span className="bold">
          <NS
            quantity={this.props.closed_projects.length}>
            these projects have come to an end
          </NS>:
        </span>
        {' '}
        {this.getProjects()}
        <span> </span>
        <NS quantity={this.props.closed_projects.length}>so we can finally archive them.</NS>
      </span>,
      <span>
        We can <span className="bold">finally archive</span> {this.getProjects()}<span> </span>
        {' '}
        since we completed
        {' '}
        <NS
          quantity={this.props.closed_projects.length}>
          them
        </NS>
        {' '}
        during the week.
      </span>,
      <span>
        We can <span className="bold">finally archive</span> {this.getProjects()}<span> </span>
        {' '}
        since <NS quantity={this.props.closed_projects.length}>they where</NS>
        {' '}
        completed during the week.
      </span>,
    ];
  }

  getProject(project) {
    return (
      <span key={project.project.id}>
        <Link
          href={`#project/${project.project.id}/report`}>
          {_.unescape(project.project.name)}
        </Link>
        {' '}
        (
        {_.unescape(project.client.name)}
        )
      </span>
    );
  }

  getProjects() {
    const list = this.props.closed_projects;
    const projects = list.map((project) => this.getProject(project));
    return <List list={list} terminator=";">{projects}</List>;
  }

  render() {
    if (this.props.closed_projects.length) {
      return (
        <Paragraph>
          {this.props.randomize(this.alternatives)}
        </Paragraph>
      );
    }
    return null;
  }
}

module.exports = withRandomize(ClosedProjects);
