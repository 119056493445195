const { connect } = require('react-redux');
const Component = require('../../../components/modals/Sponsors/SponsorItem.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSponsor: (sponsor) => dispatch(actions.deleteSponsor(sponsor)),
  endSponsor: (sponsor, employeeId) => dispatch(actions.endSponsor(sponsor, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
