const React = require('react');
const Modal = require('./InfoModal.react');

const MissingProjectModal = ({ onCancelClick, recurringModeEnabled }) => {
  if (recurringModeEnabled) {
    return (
      <Modal title={"There's nothing here"} onCancelClick={onCancelClick}>
        Please
        {' '}
        <b>select at least a plan</b>
        {' '}
        to
        repeat.
      </Modal>
    );
  }
  return (
    <Modal title={"There's nothing here"} onCancelClick={onCancelClick}>
      Please
      {' '}
      <b>select a project</b>
      {' '}
      before start
      planning.
    </Modal>
  );
};

module.exports = MissingProjectModal;
