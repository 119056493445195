const { connect } = require('react-redux');
const Component = require('../../components/modals/AllocationRejectModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'allocation-reject').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  rejectAllocation: (id) => dispatch(actions.rejectAllocation(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
