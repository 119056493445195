const React = require('react');
const FormValidator = require('../../../../../../../../common/react/FormValidator/FormValidator.react');
const Template = require('../../../containers/job-order-template/template/Template');

const Templates = ({
  templates, addContainer,
  jobOrderCategories, onJobOrderCategoryChange,
  setRef,
}) => {
  function getTemplates() {
    return templates.map((template) => (
      <FormValidator key={template.id}>
        <Template
          template={template}
          jobOrderCategories={jobOrderCategories}
          onJobOrderCategoryChange={onJobOrderCategoryChange}
          addContainer={addContainer}
          setRef={setRef} />
      </FormValidator>
    ));
  }

  return (
    <div className="company-settings-jo-template-list">
      {getTemplates()}
    </div>
  );
};

module.exports = Templates;
