const React = require('react');
const Loader = require('../../../Loader/Loader.react');

const SmallLoader = () => (
  <div className="wethod-widget-files__collection-loader">
    <Loader />
  </div>
);

module.exports = SmallLoader;
