const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(options) {
    if (options) {
      this.typeFilter = null;
      this.year = '';
      if (options.typeFilter) this.typeFilter = options.typeFilter;
      if (options.year) this.year = `&year=${options.year}`;
      if (options.buFilter) this.buFilter = options.buFilter;
    }
    let url = `${APIURL}/export/production-plan?`;
    const buParam = this.buFilter === 'all' ? '' : `&bu=${this.buFilter}`;
    let filter = '';
    switch (this.typeFilter) {
      case 'archived':
        filter = '&archived_only=true';
        break;
      case 'mine':
        filter = '&mine=true';
        break;
      case 'active-only':
        filter = '&active_only=true';
        break;
      case 'all':
        filter = '&all=true';
        break;
      default:
        filter = '';
    }

    url += `${this.year}${buParam}${filter}`;

    this.urlRoot = url;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  from: null, // default from first day of current year
  to: null, // default to current day
  sendExport() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model);
    });

    return defer.promise();
  },
});
