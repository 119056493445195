const React = require('react');
const MenuItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');

const MoreOptionsMenuItem = ({ href, children }) => (
  <MenuItem className="wethod-menu__item--link">
    <a href={href}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  </MenuItem>
);

module.exports = MoreOptionsMenuItem;
