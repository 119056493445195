const { connect } = require('react-redux');
const Timeline = require('../components/Timeline.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  currentPage: state.page,
  loading: state.isLoadingEvents,
  filtering: state.isFilteringEvents,
  filter: state.filter,
  events: state.filteredEvents,
  hasMorePages: state.hasMorePages,
});

const mapDispatchToProps = (dispatch) => ({
  getEventsPage: (page, filter) => dispatch(actions.getEventsPage(page, filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Timeline);
