const PathComponent = require('./PathComponent');

/**
 * @type {File}
 */
module.exports = class File {
  constructor(id, name, mimeType, webViewUrl = null, size = 0, downloadUrl = null,
    uploadedBy = null, uploadedAt = null) {
    this.id = id;
    this.name = name;
    this.mimeType = mimeType;
    this.size = size;
    this.webViewUrl = webViewUrl;
    this.downloadUrl = downloadUrl;
    this.uploadedBy = uploadedBy;
    this.uploadedAt = uploadedAt;

    this.folderMimeTypes = ['application/vnd.google-apps.folder'];
  }

  isFolder() {
    return this.folderMimeTypes.includes(this.mimeType);
  }

  toPathComponent() {
    return new PathComponent(this.id, this.name, this.webViewUrl);
  }
};
