const React = require('react');

module.exports = class Bio extends React.Component {
  getContent() {
    if (this.props.bio !== '' && this.props.bio !== null) return this.props.bio;
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  render() {
    return (
      <div className="profile-bio">{this.getContent()}</div>
    );
  }
};
