const React = require('react');

const OpportunitySidebarDescription = () => (
  <span>
    An opportunity has a probability between 0% and 75%.{' '}
    Otherwise create a new project from <a href="/#pipeline/projects">Project list</a>
  </span>
);

module.exports = OpportunitySidebarDescription;
