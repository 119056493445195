const { connect } = require('react-redux');
const Component = require('../../../components/modals/YearResolution/YearResolutionBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  resolution: state.objectivesByQuarter.resolution,
  waiting: state.waiting,
  employee: state.id,
  year: state.objectivesByQuarter.year,
});

const mapDispatchToProps = (dispatch) => ({
  editYearResolution: (resolution, year, employeeId) => dispatch(actions
    .editYearResolution(resolution, year, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
