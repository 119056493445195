const React = require('react');

const SingleForm = ({ granularity, children }) => {
  if (granularity === 'single') {
    return <div className="planning-people-repeat-form--single">{children}</div>;
  }
  return null;
};

module.exports = SingleForm;
