const React = require('react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const Footer = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const SelectItem = require('../../../../../../../../common/react/inputs/Select/SelectItem.react');
const InputValidator = require('../../../../../../../../common/react/InputValidator/InputValidator.react');
const Select = require('../../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');

module.exports = class InviteIntercompanySupplierContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { supplierId: '' };

    this.onChange = this.onChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({ supplierId: value });
  }

  onSaveClick() {
    this.props.inviteIntercompanySupplier(this.props.areaId, this.props.taskId,
      this.state.supplierId);
  }

  getSupplierCompanyEmployees() {
    const supplierCompany = this.props.availableSuppliers
      .find((supplier) => supplier.id === this.props.supplierCompany.id);
    return supplierCompany.employees;
  }

  getAvailableForInvitation() {
    return this.getSupplierCompanyEmployees()
      .map((supplier) => (
        <SelectItem key={supplier.id} value={supplier.id}>
          {supplier.name}
        </SelectItem>
      ));
  }

  getFeedback() {
    return this.isSaving() ? 'Sending...' : '';
  }

  isSaving() {
    return this.props.isPending('invite-intercompany-supplier');
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            Invite a person from <span className="bold">{this.props.supplierCompany.name}</span> to
            link one of their projects to this task.
          </ModalBodyBlock>
          <ModalBodyBlock>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
              <Select name="supplier"
                disabled={this.isSaving()}
                value={this.state.supplierId}
                id="task-intercompany-supplier"
                errorText={this.props.errors.supplier}
                onChange={this.onChange}>
                {this.getAvailableForInvitation()}
              </Select>
            </InputValidator>
          </ModalBodyBlock>
          <ModalBodyBlock>
            Changes applied to this task's external costs and to your project’s probability will be
            reflected in the final net price and in the probability of the supplier’s linked
            project.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Do you want to proceed?
          </ModalBodyBlock>
        </ModalBody>
        <Footer feedback={this.getFeedback()}>
          <FooterAction onClick={this.props.closeModal} disabled={this.isSaving()}>
            Cancel
          </FooterAction>
          <FooterAction onClick={this.onSaveClick}
            disabled={this.isSaving() || !this.props.isValid}>
            Yes
          </FooterAction>
        </Footer>
      </ModalContent>
    );
  }
};
