const request = require('./Request');

const ProjectStatus = {
  /**
   * Get the list of possible project statuses
   * @param date Week to fetch; format YYYY-MM-DD
   * @param ownership pm/account/all
   * @returns {*}
   */
  getAll(date, ownership) {
    const formattedDate = date || moment().format('YYYY-MM-DD');

    let query = `?date=${formattedDate}`;
    query += `&ownership=${ownership}`;

    return request({
      method: 'get',
      url: `/project-status/${query}`,
    });
  },
  save(data) {
    return request({
      method: 'post',
      url: '/project-status/',
      data,
    });
  },
  saveAreas(data) {
    return request({
      method: 'post',
      url: '/project-status/areas',
      data,
    });
  },
  saveRisk(data) {
    return request({
      method: 'post',
      url: '/project-status/risk',
      data,
    });
  },
  /**
   * Get project status details of given project
   * @param date Week to fetch; format YYYY-MM-DD
   * @param projectId
   * @returns {*}
   */
  getInfo(date, projectId) {
    const formattedDate = date || moment().format('YYYY-MM-DD');

    let query = projectId;
    query += `?date=${formattedDate}`;

    return request({
      method: 'get',
      url: `/project-status/info/${query}`,
    });
  },
};

ProjectStatus.CANNOT_EDIT_BECAUSE_DATA_FROZEN = 'CompilationLimitDataFrozen';
ProjectStatus.CANNOT_EDIT_BECAUSE_MISSING_BUDGET = 'CompilationLimitMissingBudget';
ProjectStatus.CANNOT_EDIT_BECAUSE_ZERO_DAYS_BUDGET = 'CompilationLimitBudgetZeroDays';
ProjectStatus.CANNOT_EDIT_BECAUSE_TIME_BASED = 'CompilationLimitTimeBased';
ProjectStatus.CANNOT_EDIT_BECAUSE_ZERO_DAYS_BUDGET_PREV_DAYS_LEFT = 'CompilationLimitBudgetZeroDaysWithPrevDaysLeft';

module.exports = ProjectStatus;
