const { connect } = require('react-redux');
const ImportantInfoController = require('../../components/ImportantInfo/ImportantInfoController.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.isSaving,
  person: state.person,
});

const mapDispatchToProps = (dispatch) => ({
  save: (person) => dispatch(actions.updatePerson(person)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(ImportantInfoController);
