'use strict';

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette, $, _) {
  var allowChar = function (charCode) {
    if ((charCode >= 46 && charCode <= 90) || (charCode >= 96 && charCode <= 111)
      || _.contains([8, 9, 187, 189], charCode)) {
      return true;
    }
    return false;
  };

  // STRUCTURE
  this.ProgramStructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#pipelineProgramStructureTemplate',
    className: 'fluid',
    ui: {
      body: '.program__body',
      spacer: '.marginTopPipelineProgramScroll',
    },
    regions: {
      programHeaderSection: '[data-region="programSectionHeader"]',
      programList: '[data-region="programList"]',
      buttons: '[data-region="buttonArea"]',
    },
    initialize: function () {
      dispatcher.on('app:banner:closed', this.placeFixedHeader.bind(this));
    },
    /**
     * Place fixed header taking care of space occupied by app header and section header.
     */
    placeFixedHeader: function () {
      Wethod.placeFixedHeader(this.ui.body, this.ui.spacer);
    },
  });

  this.PipelineProgramButtounsView = Marionette.ItemView.extend({
    template: '#pipelineProgramButtounsTemplate',
    ui: {
      searchIcon: '[data-action="openSearch"]',
      search: '[data-region="searchProject"]',
    },
    events: {
      'click @ui.searchIcon': 'openSearch',
      'keyup @ui.search': 'searchProject',
    },
    initialize: function (options) {
      this.options = options;
      this.options.previousSearch = '';
    },
    openSearch: function (e) {
      e.preventDefault();
    },
    searchProject: function (e) {
      var target = $(e.target);
      var keyCode = e.keyCode;
      var value = String(_.escape(target.val().trim()));

      if (allowChar(keyCode) && (value !== this.options.previousSearch)) {
        this.options.previousSearch = value;
        PipelineApp.searchProgram(value);
      }
    },
  });

  this.PipelineProgramLoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });

  this.PipelineProgramsNotFoundView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#pipelineProgramNotFoundTemplate',
  });

  this.PipelineProgramView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'col_16 no_padding',
    _template: '#pipelineProgramItemTemplate',
    ui: {
      hintEL: '[data-action="selectType"]',
      deleteButton: '[data-action="deleteProgram"]',
    },
    events: {
      'click @ui.hintEL': 'selectType',
      'click @ui.deleteButton': 'deleteProgram',
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serializeModel) {
      var template = $(this._template).html();
      var model = serializeModel;

      model.link = '#program/' + model.id;
      model.isValueAsUnit = this.options.isValueAsUnit;

      return _.template(template)(model);
    },
    selectType: function (e) {
      e.preventDefault();
      this.options.parentView.changeType(this.model.get('id'), this.model.get('name'));
    },
    deleteProgram: function (e) {
      e.preventDefault();
      if (!this.ui.deleteButton.hasClass('disabled')) {
        this.ui.deleteButton.addClass('disabled');

        Wethod.ProjectCharterApp.deleteProgram(this);
      }
    },
  });
  this.PipelineProgramEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'col_16 no_padding',
    template: '#pipelineProgramEmptyItemTemplate',
  });
  this.PipelineProgramsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'clear pipelineProgramList',
    emptyView: PipelineApp.PipelineProgramEmptyView,
    childView: PipelineApp.PipelineProgramView,
    childViewOptions: function () {
      return {
        isValueAsUnit: this.options.isValueAsUnit,
      };
    },
  });
});
