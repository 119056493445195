/* eslint-disable react/prop-types */
const React = require('react');

module.exports = class MenuItemLogout extends React.Component {
  onClick(e) {
    e.preventDefault();
    Wethod.trigger('user:logout');
    this.props.onClick();
  }

  render() {
    return (
      <div className="app-nav-mobile-item">
        <button type="button"
          className="app-nav-mobile-item__button app-nav-mobile-item__button--logout"
          onClick={this.onClick.bind(this)}>
          <span className="app-nav-mobile-item__label">Logout</span>
        </button>
      </div>
    );
  }
};
