const React = require('react');
const SectionTitle = require('../../../../common/components/SectionTitle.react');
const NV = require('../../../../common/components/NumericValue.react');
const Comparator = require('../../../../common/components/Comparator.react');

module.exports = ({
  month, ebitda, target, prevMonthEbitda,
}) => {
  const getQuality = () => (ebitda > target ? <span>well</span> : null);

  const getType = () => (ebitda > 0 ? <span>margin</span> : <span>loss</span>);

  const getLastMonthComparator = () => {
    const labels = {
      much_lower: 'declining',
      lower: 'declining',
      almost_equal: 'constant',
      equal: 'constant',
      greater: 'growing',
      much_greater: 'growing',
    };

    return <Comparator a={ebitda} b={prevMonthEbitda} labels={labels} />;
  };

  return (
    <SectionTitle>
      {month} concluded {getQuality()} with a {getType()} of
      {' '}
      <NV value={Math.abs(ebitda)} />
      {' '} and a {getLastMonthComparator()} trend compared to the previous month
    </SectionTitle>
  );
};
