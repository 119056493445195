/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Event extends React.Component {
  render() {
    return (
      <div className="timeline-event-past__content">
        <div className="timeline-event-log__creation">
          <a href={`#timeline/${this.props.project.id}`}>{_.unescape(this.props.project.name)}</a>
        </div>
        <div className="timeline-event-past__description">{this.props.description}</div>
      </div>
    );
  }
};
