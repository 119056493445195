const { connect } = require('react-redux');
const Component = require('../components/Info.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  info: state.info,
  statusEnabled: state.statusEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  showSidebar: () => dispatch(actions.showSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
