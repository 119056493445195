const { connect } = require('react-redux');
const Component = require('../../components/Objectives/Filters.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  year: state.objectivesByQuarter.year,
  employee: state.id,
  currentQuarter: state.quartersFilter,
  quarters: [
    { id: 0, name: 'All', quarterId: null },
    { id: 1, name: 'Quarter 1', quarterId: 1 },
    { id: 2, name: 'Quarter 2', quarterId: 2 },
    { id: 3, name: 'Quarter 3', quarterId: 3 },
    { id: 4, name: 'Quarter 4', quarterId: 4 },
  ],
});

const mapDispatchToProps = (dispatch) => ({
  getObjectives: (year, employeeId) => dispatch(actions.getObjectives(year, employeeId)),
  chooseQuartersFilter: (filter) => dispatch(actions.chooseQuartersFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
