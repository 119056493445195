/* eslint-disable react/sort-comp,consistent-return,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('../../../../../../common/react/CollapsibleCard.react');

module.exports = class Goals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    if (!this.props.project.budget || !this.props.project.budget.goal) {
      this.setState({ collapsed: true });
    }
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getUnescapedContent() {
    const { budget } = this.props.project;
    if (budget) {
      return _.unescape(budget.goal);
    }
  }

  render() {
    return (
      <Card
        title="Goals"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <div className="client-person__notes">{this.getUnescapedContent()}</div>
      </Card>
    );
  }
};
