/* eslint-disable react/sort-comp */
const React = require('react');
const TypeSelect = require('./EventTypeSelect.react');

const placeholders = {
  name: 'New event',
  description: 'Add a note...',
};

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: this.props.event,
      placeholders: {
        name: placeholders.name,
        description: placeholders.description,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.setPlaceholder = this.setPlaceholder.bind(this);
    this.setEventAttribute = this.setEventAttribute.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const currentState = this.state;
    currentState.event = nextProps.event;
    this.setState(currentState);
  }

  /**
     * An helper method to help you update the inputs' placeholder.
     * Needed because React isn't good at merge multi-level state's objects.
     *
     * @param key
     * @param value
     */
  setPlaceholder(key, value) {
    const currentState = this.state;
    currentState.placeholders[key] = value;
    this.setState(currentState);
  }

  /**
     * An helper method to help you update the inputs' attributes.
     * Needed because React isn't good at merge multi-level state's objects.
     * @param key
     * @param value
     */
  setEventAttribute(key, value) {
    const currentState = this.state;
    currentState.event[key] = value;
    this.setState(
      currentState,
    );
  }

  handleChange(event) {
    this.setEventAttribute(event.target.name, event.target.value);
  }

  handleTypeChange(type) {
    this.setEventAttribute('type', type);
  }

  handleBlur(event) {
    if (event.target.value === '') {
      this.setPlaceholder(event.target.name, placeholders[event.target.name]);
    }
  }

  handleDeleteClick() {
    this.props.handleDelete(this.state.event);
  }

  handleInputFocus(event) {
    this.setPlaceholder(event.target.name, '');
  }

  render() {
    return (
      <div className="event">
        <TypeSelect
          selected={this.state.event.type}
          types={this.props.eventTypes}
          handleTypeChange={this.handleTypeChange}
          delete={this.handleDeleteClick}
          dropdown={this.props.firstAdded} />
        <input
          type="text"
          className="event__title"
          name="name"
          placeholder={this.state.placeholders.name}
          value={this.state.event.name || ''}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleInputFocus} />
        <textarea
          className="event__description"
          name="description"
          rows="3"
          placeholder={this.state.placeholders.description}
          value={this.state.event.description || ''}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleInputFocus} />
      </div>
    );
  }
}

module.exports = Event;
