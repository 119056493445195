const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/NewOpportunities/NewOpportunities.react');

const mapStateToProps = (state) => ({
  projects_count: state.new_opportunity_count,
  clients_count: state.new_opportunities_clients,
  projects_value: state.new_opportunities_value,
  projects_weighted_value: state.new_opportunities_weighted_value,
});

module.exports = connect(mapStateToProps)(Component);
