/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Project extends React.Component {
  render() {
    return (
      <div className="profile-portfolio__work">
        <span className="profile-portfolio__work-name">{this.props.name}</span>
        <a href={this.props.url} className="wethod-button" target="_blank" rel="noreferrer">Open</a>
      </div>
    );
  }
};
