/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this,
 react/prefer-stateless-function */
const React = require('react');
const ReadOnlyField = require('./SidebarReadOnlyField.react');

/**
 * An outlined read only field component, material styled, with a colored dot on the side.
 * It can be plain text or a link. Requires an icon as child.
 *
 * PROPS
 * placeholder: string that will be shown as label
 * children: icon shown on the left side of the input
 * value: text that will be shown
 * link: href associated with the field
 * color: color of the dot shown
 *
 * @type {module.SidebarReadOnlyColorDotField}
 */
module.exports = class SidebarReadOnlyColorDotField extends React.Component {
  render() {
    return (
      <div className="material-input--dotted">
        <ReadOnlyField placeholder={this.props.placeholder}
          value={this.props.value}
          link={this.props.link}>
          {this.props.children}
        </ReadOnlyField>
        <span style={{ backgroundColor: this.props.color }}
          className="material-input__dot" />
      </div>
    );
  }
};
