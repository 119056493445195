const $ = require('jquery');
const constants = require('./constants');
const TimesheetLogModel = require('../../../../../models/TimesheetLog');

const getTimesheetLogsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_TIMESHEET_LOGS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getTimesheetLogsSuccess = (timesheetLogs) => ({
  type: constants.GET_TIMESHEET_LOGS_SUCCESS,
  timesheetLogs,
});

module.exports.getTimesheetLogs = (offset, limit, search, order, sort, filters) => (dispatch) => {
  const options = {
    offset, limit, search, order, sort,
  };

  dispatch(getTimesheetLogsRequest(offset, limit, search, order, sort, options));

  const getListRequest = TimesheetLogModel.list(options, filters);
  $.when(getListRequest)
    .done((timesheetLogs) => dispatch(getTimesheetLogsSuccess(timesheetLogs)));
};

module.exports.showAdvancedSearch = () => ({
  type: constants.SHOW_ADVANCED_SEARCH,
});

module.exports.applyAdvancedSearch = (filters) => ({
  type: constants.APPLY_ADVANCED_SEARCH,
  filters,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});
