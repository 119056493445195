/* eslint-disable consistent-return */
const React = require('react');

module.exports = class Language extends React.Component {
  getLevelName(id) {
    for (let i = 0; i < this.props.levels.length; i++) {
      if (id === this.props.levels[i].id) return this.props.levels[i].name;
    }
  }

  render() {
    return (
      <div className="profile-languages__item">
        <span className="profile-languages__item-name">{this.props.name}</span>
        <span className="profile-languages__item-level">{this.getLevelName(this.props.level)}</span>
      </div>
    );
  }
};
