const React = require('react');
const TableSearch = require('../../../../../../../common/react/Table2/TableSearch.react');

const Search = ({ keyword, filter, statusEnabled, canSearchByRating, ...rest }) => {
  const placeholder = statusEnabled ? 'Search by name, email, status or tag'
    : 'Search by name, email or tag';

  const getRegex = () => {
    if (canSearchByRating) {
      return new RegExp('(rating|client|project):.*', 'i');
    }
    return new RegExp('(client|project):.*', 'i');
  };

  const specialKeywordRegex = getRegex();

  const usingSpecialKeyword = specialKeywordRegex.test(keyword);

  const className = usingSpecialKeyword ? 'wethod-highlighter--search-keyword' : '';

  return (
    <div className={className}>
      <TableSearch
        placeholder={placeholder}
        keyword={keyword}
        filter={filter}
        {...rest} />
    </div>
  );
};

module.exports = Search;
