const React = require('react');
const _ = require('underscore');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const SelectItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('../../../../../../../common/react/inputs/Autocomplete/BasicAutocomplete/BasicAutocomplete.react');
const AutocompleteModel = require('../../../../../../../models/PipelineAutocomplete');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

module.exports = class AccountCell extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getAccounts(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    let account = null;

    if (item) {
      account = {
        id: value,
        name: item.name,
        surname: item.surname,
      };
    }

    this.props.onChange(e, account);
  }

  getOptions() {
    return this.state.availableItems.map((account) => {
      const splitted = account.hint.split(' ');
      const name = splitted[0];
      splitted.splice(0, 1);
      const surname = splitted.join(' ');

      return (
        <SelectItem key={account.id}
          value={account.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  getValue() {
    const { id } = this.props.value;
    if (id === null) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <TableCell className="pipeline-item__account" style={this.props.style}>
        <InputMetadataManager metadataService={this.props.metadataService}
          name={this.props.name}
          canEdit={!this.props.readOnly}>
          <Autocomplete
            onFocus={this.props.onFocus}
            onChange={this.onChange.bind(this)}
            onBlur={this.props.onBlur}
            value={this.getValue()}
            getInputValue={AccountCell.getInputValue}
            label={this.props.label}
            id={`${this.props.projectId}-account`}
            onFilter={this.onFilter.bind(this)}
            loading={this.state.isLoading}>
            {this.getOptions()}
          </Autocomplete>
        </InputMetadataManager>
      </TableCell>
    );
  }
};
