const React = require('react');
const CompareVersionsAction = require('../../../../containers/Footer/DefaultFooterContent/Actions/CompareVersionsAction/CompareVersionsAction');
const SaveTemplateAction = require('./SaveTemplateAction/SaveTemplateAction.react');
const MulticurrencyAction = require('../../../../containers/Footer/DefaultFooterContent/Actions/MulticurrencyAction/MulticurrencyAction');
const PermissionManager = require('../../../../containers/PermissionManager');
const ProjectStatusCorrectionAction = require('../../../../containers/Footer/DefaultFooterContent/Actions/ProjectStatusCorrectionAction/ProjectStatusCorrectionAction');
const ConsumptionReportAction = require('./ConsumptionReportAction/ConsumptionReportAction.react');
const LogAction = require('./LogAction/LogAction.react');
const NotesAction = require('../../../../containers/Footer/DefaultFooterContent/Actions/NotesAction/NotesAction');
const PipelineLink = require('./PipelineLink.react');
const PlanningLink = require('./PlanningLink.react');
const ReportLink = require('./ReportLink.react');
const CanvasLink = require('./CanvasLink.react');
const MoreOptionsMenuItem = require('../../../ProjectSidebar/MoreOptionMenuItem.react');
const MoreOptions = require('../../../ProjectSidebar/MoreOptionsButton.react');

const BudgetFooterActions = ({
  showModalSaveTemplate,
  showModalProjectStatusCorrection,
  showModalConsumptionReport,
  showModalLog,
  showModalNotes,
  projectId,
}) => (
  <div className="wethod-budget-footer__actions">
    <CompareVersionsAction />
    <SaveTemplateAction onClick={showModalSaveTemplate} />
    <PermissionManager>
      <MulticurrencyAction />
    </PermissionManager>
    <PermissionManager>
      <ProjectStatusCorrectionAction onClick={showModalProjectStatusCorrection} />
    </PermissionManager>
    <ConsumptionReportAction onClick={showModalConsumptionReport} />
    <LogAction onClick={showModalLog} />
    <NotesAction onClick={showModalNotes} />
    <div className="wethod-budget-footer__actions-links">
      <PipelineLink projectId={projectId} />
      <PlanningLink projectId={projectId} />
      <ReportLink projectId={projectId} />
      <CanvasLink projectId={projectId} />
      <MoreOptions>
        <MoreOptionsMenuItem href={`#project/${projectId}/invoice-plan`}>Invoice
          plan
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#timeline/${projectId}`}>Timeline</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project-status/${projectId}`}>Project
          status
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#planning/production-plan?project_id:${projectId}`}>Production
          plan
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem
          href={`#finance/order/?project=${projectId}`}>Orders
        </MoreOptionsMenuItem>
      </MoreOptions>
    </div>
  </div>
);

module.exports = BudgetFooterActions;
