const { connect } = require('react-redux');
const Component = require('../../../../../../common/react/AdvancedSearch/filters/RadioFilter/RadioFilter.react');

const mapStateToProps = (state) => ({
  availableOptions: state.archivedStatusList.list.map((archivedStatus) => ({
    label: archivedStatus.name,
    value: archivedStatus.key,
  })),
});

module.exports = connect(mapStateToProps)(Component);
