const { connect } = require('react-redux');
const Component = require('../../../../../../common/react/date-selectors/WeekSelector.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  date: state.date,
  isWaiting: state.isLoadingProjects || state.savingProjectStatus.length > 0,
  internationalCalendar: state.internationalCalendar,
});

const mapDispatchToProps = (dispatch) => ({
  changeDate: (date) => dispatch(actions.changeWeek(date)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
