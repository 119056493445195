const { connect } = require('react-redux');
const NoteModalComponent = require('../components/NoteModal.react');
const actions = require('../actions');

const getFocusedProject = (projects, id) => {
  const focusedProject = projects.find((project) => project.project.id === id);
  return focusedProject || {};
};

const mapStateToProps = (state) => ({
  project: getFocusedProject(state.projects, state.focusedProject),
  isSaving: state.waitingFor.filter((key) => key === 'notes').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  saveNote: (project) => dispatch(actions.saveNoteWeekly(project)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(NoteModalComponent);
