const { connect } = require('react-redux');
const Component = require('../../../../../components/Footer/DefaultFooterContent/Actions/SaveTemplateAction/SaveTemplateModal.react');
const actions = require('../../../../../actions');

const mapStateToProps = (state) => ({
  budgetId: state.info.id,
});

const mapDispatchToProps = (dispatch) => ({
  save: (budget, name, description) => dispatch(actions.saveTemplate(budget, name, description)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
