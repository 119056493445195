const request = require('./Request');

/**
 * Special request used when files are involved.
 * @param options
 * @return {*}
 * @constructor
 */
const FileRequest = (options) => {
  const formData = new FormData();
  formData.append('file', options.file);

  return request({
    ...options,
    data: formData,
  }, request.TYPE_FILE);
};

module.exports = FileRequest;
