const React = require('react');

module.exports = class CalendarHeaderColumnWeek extends React.Component {
  getClassName() {
    const isToday = moment().isoWeek() === this.props.column.isoWeek();
    let name = 'project-canvas-gantt__calendar-header-column project-canvas-gantt__calendar-column--week';
    if (isToday) {
      name += ' project-canvas-gantt__calendar-header-column--today';
    }
    return name;
  }

  getMonth() {
    const prevMonday = this.props.column.clone().subtract(1, 'week');
    const firstWeekOfMonth = prevMonday.month() !== this.props.column.month();
    if (firstWeekOfMonth) {
      return (
        <span
          className="project-canvas-gantt__calendar-header-month">
          {this.props.column.format('MMMM YYYY')}
        </span>
      );
    }
    return null;
  }

  render() {
    const from = this.props.column.format('DD');
    const to = this.props.column.clone().add(6, 'days').format('DD');
    return (
      <div className={this.getClassName()}>
        {this.getMonth()}
        {`${from} - ${to}`}
      </div>
    );
  }
};
