'use strict';

Wethod.module('NotFoundApp', function (NotFoundApp, Wethod) {
  this.startWithParent = false;

  var API = {
    showNotFound: function () {
      NotFoundApp.Controller.showNotFound();
    },
  };

  // usato per mostrare la pagina 404 in casi particolari
  Wethod.on('url:404:show', function () {
    API.showNotFound();
  });

  NotFoundApp.on('start', function () {
    API.showNotFound();
  });
});
