const { connect } = require('react-redux');
const Component = require('../../components/Kudos/Kudos.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  year: state.kudos.year,
  employeeId: state.id,
  waiting: state.waitingForKudos,
  empty: !state.kudos.as_pm && !state.kudos.as_team_member && !state.kudos.people_say.length,
});

const mapDispatchToProps = (dispatch) => ({
  getKudos: (employeeId, year) => dispatch(actions.getKudos(employeeId, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
