const { connect } = require('react-redux');
const Component = require('../components/InvoicePlan.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.length > 0,
  canBeSaved: state.canBeSaved,
  plan: state.plan,
  projectId: state.project.id,
  isLinked: state.isLinked,
  isLoading: state.waitingFor.filter((key) => key === 'get-plan').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  saveInvoicePlan: (plan, projectId) => dispatch(actions.saveInvoicePlan(plan, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
