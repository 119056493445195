const React = require('react');

/**
 * Injects all needed permission checks into the given children.
 * @param children
 * @param permissions {{}} contains permissions settings from server
 * @param rest
 * @return {React.SFCElement<unknown>}
 * @constructor
 */
const PermissionManager = ({ children, permissions, ...rest }) => {
  const currentUserId = Wethod.userInfo.get('employee_id');

  const checks = {
    canView: permissions.view,
    canEdit: permissions.edit,
    canViewOther: permissions.view_other,
    canEditOther: permissions.edit_other,
    canApproveManaged: permissions.approve_managed,
    canApproveOther: permissions.approve_other,
  };

  /**
   * A user has permission to edit an item if he has "edit" permission and he owns that item.
   * If user does not own it, he can still edit the item if he has "edit_other" permission.
   * @param ownerId {number} id of the item's owner
   * @returns {boolean}
   */
  const hasPermissionToEdit = (ownerId) => {
    const isOwner = currentUserId === ownerId;

    return checks.canEdit && (isOwner || checks.canEditOther);
  };

  /**
   * An item can be edited if the user has "edit" permission and he owns that item.
   * If user does not own it, he can still edit the item if he has "edit_other" permission.
   * The item should also be in pending or conflict status in order to be edited.
   * @param ownerId {number} id of the item's owner
   * @param requestStatus status of the allocation request
   * @returns {boolean}
   */
  const canEditItem = (ownerId, requestStatus) => {
    const isStatusEditable = requestStatus === 'pending' || requestStatus === 'conflict';

    return isStatusEditable && hasPermissionToEdit(ownerId);
  };

  /**
   * An item can be deleted if the user has permission to edit it.
   * @param ownerId {number} id of the item's owner
   * @returns {boolean}
   */
  const canDeleteItem = (ownerId) => hasPermissionToEdit(ownerId);

  /**
   * An item can be approved if one of the following is true:
   * - User can approve managed requests and he is the designed allocation manager for the request
   * - User can approve requests managed by others
   *
   * @param {{id:number}|null} manager id of the item manager
   * @returns {boolean}
   */
  const canApproveItem = (manager) => {
    const managerId = manager ? manager.id : null;

    return (checks.canApproveManaged && currentUserId === managerId)
      || checks.canApproveOther;
  };

  /**
   * User can create items if he has "edit" permission.
   * @returns {boolean}
   */
  const canCreateItem = () => checks.canEdit;

  return React
    .cloneElement(children, {
      ...checks,
      canDeleteItem,
      canEditItem,
      canCreateItem,
      canApproveItem,
      ...rest,
    });
};

module.exports = PermissionManager;
