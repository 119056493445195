const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('./Menu.react');
const Loading = require('./LoadingItem.react');
const Empty = require('../../Menu/ListMenu/EmptyItem.react');
const FormValidator = require('../../FormValidator/FormValidator.react');
const EventService = require('../../../../services/EventService');

require('./style.scss');

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      inputValue: this.getInputValue(props.value),
      open: false,
    };

    this.timer = 0;

    this.onOptionClick = this.onOptionClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.closeCreator = this.closeCreator.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.inputValue, this.state.value);
  }

  componentDidUpdate(prevProps) {
    if (this.valueChangedFromParent(prevProps)) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onClose(e) {
    const clickedOnSuggestion = e.target.classList.contains('wethod-menu__item');
    // Useful if option item is composed of more than one children (complex option design)
    const clickedOnSuggestionChild = e.target.closest('.wethod-menu__item') !== null;

    if (!clickedOnSuggestion && !clickedOnSuggestionChild) {
      this.resetValue();
    }
    this.closeMenu();
  }

  onParentChange(e, option) {
    if (this.props.onChange) {
      this.props.onChange(e, option);
    }
  }

  onOptionClick(e) {
    const option = this.getOption(e.target.value);

    this.updateValue(option.props, () => {
      this.onParentChange(e, this.state.value);
    });
  }

  onInputChange(e) {
    const { value } = e.target;
    const deleted = value.length === 0 && this.state.value !== null;

    if (deleted) {
      this.updateValue(null, () => {
        this.closeMenu();
        this.onParentChange(e, this.state.value);
      });
    } else {
      this.setState({
        open: true,
        inputValue: value,
      }, () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.props.onFilter(this.props.name, value), 500);
      });
    }
  }

  onCreate(option) {
    this.updateValue(option);
    this.props.onCreate(option);
  }

  onBlur(e) {
    if (!(this.state.open && this.showCreator()) && this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  getInputValue(value) {
    return value === null ? '' : this.props.getInputValue(value);
  }

  getOptions() {
    return React.Children.map(this.props.children, (child) => React
      .cloneElement(child, {
        onClick: (e) => {
          // Cannot use original event because empty <li> value is converted to 0
          const event = EventService.cloneEvent(e, {
            name: this.props.name,
            value: child.props.value,
          });
          this.onOptionClick(event);
        },
      }));
  }

  getOption(value) {
    const found = React.Children.toArray(this.props.children)
      .filter((child) => child.props.value.toString() === value.toString());
    return found.length ? found[0] : null;
  }

  getCreator() {
    const Creator = this.props.creator;
    if (Creator) {
      return (
        <FormValidator>
          <Creator id={this.props.id}
            name={this.props.name}
            value={this.state.inputValue}
            close={this.closeCreator}
            onCreate={this.onCreate}
            {...this.props.creatorProps} />
        </FormValidator>
      );
    }
    return null;
  }

  getMenuContent() {
    if (this.showCreator()) {
      return this.getCreator();
    }
    if (this.props.loading) {
      return <Loading />;
    }
    if (this.isEmpty()) {
      return <Empty>Nothing found</Empty>;
    }
    return this.getOptions();
  }

  getTextInput() {
    const TextField = this.props.input;

    return (
      <TextField prefix={this.props.prefix}
        suffix={this.props.suffix}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        onChange={this.onInputChange}
        placeholder={this.props.placeholder}
        name={this.props.name}
        value={this.state.inputValue}
        onFocus={this.props.onFocus}
        onBlur={this.onBlur}
        id={this.props.id}
        label={this.props.label}
        inputRef={this.setMenuAnchorRef}
        rootRef={this.setInputRef}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        required={this.props.required} />
    );
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  setInputRef(ref) {
    this.inputRef = ref;
  }

  getClassName() {
    return `wethod-autocomplete ${this.props.className}`;
  }

  /**
   * Return if this autocomplete has the ability to create a new item.
   * @return {boolean}
   */
  canCreate() {
    return this.props.creator !== null && !this.props.disabled && !this.props.readOnly;
  }

  /**
   * Reset input value to last selected value.
   */
  resetValue() {
    this.updateValue(this.props.value);
  }

  /**
   * Return if creator form must be rendered.
   * @return {boolean}
   */
  showCreator() {
    return !this.props.loading && this.isEmpty() && this.canCreate();
  }

  validate(value, attributes) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value, attributes);
    }
  }

  updateValue(value, afterUpdate) {
    const inputValue = this.getInputValue(value);
    this.validate(inputValue, value);

    this.setState({
      value,
      inputValue,
    }, afterUpdate);
  }

  syncWithParent() {
    this.updateValue(this.props.value);
  }

  closeMenu() {
    this.inputRef.focus();
    this.setState({ open: false });
  }

  closeCreator() {
    this.resetValue();
    this.closeMenu();
  }

  isEmpty() {
    return React.Children.count(this.props.children) === 0;
  }

  valueChangedFromParent(prevProps) {
    const valueId = this.props.value ? this.props.value.id : null;
    const prevValueId = prevProps.value ? prevProps.value.id : null;

    return valueId !== prevValueId;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getTextInput()}
        <Menu open={this.state.open}
          className="wethod-autocomplete-menu"
          footer={this.props.menuFooter}
          onClose={this.onClose}
          isList={!this.showCreator()}
          anchorEl={this.menuAnchorRef}
          bottom>
          {this.getMenuContent()}
        </Menu>
      </div>
    );
  }
}

Autocomplete.defaultProps = {
  loading: false,
  validate: undefined,
  label: null,
  required: undefined,
  disabled: undefined,
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  creator: null,
  onCreate: null,
  readOnly: undefined,
  onChange: null,
  onBlur: null,
  onFocus: null,
  value: null,
  creatorProps: {},
  placeholder: '',
  resetErrors: null,
  menuFooter: null,
  className: '',
};

Autocomplete.propTypes = {
  /** Last selected value. * */
  value: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  menuFooter: PropTypes.node,
  /**
   * Function to call when a new value is selected.
   * @param event {HTMLEvent}
   * @param option {{}} selected option props
   */
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /** Class to istantiate as text input * */
  input: PropTypes.func.isRequired,
  /**
   * Function to call when input value changes.
   * @param name {string}
   * @param value {string}
   */
  onFilter: PropTypes.func.isRequired,
  /**
   * Function that returns the string to show in input.
   * @param value {{}} represents the Autocomplete value
   * @return {string}
   */
  getInputValue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /** Class to istantiate and show when children are empty. Used for in context creation * */
  creator: PropTypes.func,
  /**
   * Function to call when a new option has been created.
   * @param option {{}} the created option, depends on create form
   */
  onCreate: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  creatorProps: PropTypes.shape({}),
  placeholder: PropTypes.string,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  className: PropTypes.string,
};

module.exports = Autocomplete;
