const React = require('react');
const TableSearch = require('../../../../../../../../common/react/Table2/TableSearch.react');

const TransferHoursSearch = ({
  keyword, ...rest
}) => (
  <TableSearch
    keyword={keyword}
    {...rest} />
);

module.exports = TransferHoursSearch;
