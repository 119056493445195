/* eslint-disable consistent-return,react/sort-comp,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class AvgProductionTrend extends React.Component {
  format(value) {
    if (value) {
      return `${numeral(value / 1000).format('0,0.[0]')} K`;
    }
  }

  tooltipFormatter(point) {
    const pointDate = new Date(point.key);
    const start = moment(pointDate).startOf('isoWeek');
    const end = moment(pointDate).endOf('isoWeek');
    const formatted = `${start.format('YYYY-MM-DD')}/${end.format('DD')}`;
    return `<div>
                    ${formatted}<br/>
                    <b>${this.format(point.y)}</b>
                </div>`;
  }

  /**
   * Returns a formatted label for the given point.
   *
   * @param point
   * @param series
   * @param x
   * @param y
   * @param lastLabelIndex
   * @returns {string}
   */
  getPointLabel(point, series, x, y, lastLabelIndex) {
    if (point.index === lastLabelIndex) { // is last point
      return `${this.format(y)}`;
    }
    return '';
  }

  render() {
    return (
      <Trend
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        title="Average Weekly Production"
        description="Moving average of previous twelve weeks of production"
        getPointLabel={this.getPointLabel.bind(this)}
        {...this.props} />
    );
  }
};
