/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const SidebarWrapper = require('./SidebarWrapperReadOnly.react');

/**
 * An outlined read only field component, material styled. It can be plain text or a link. Requires
 * an icon as child.
 *
 * PROPS
 * placeholder: string that will be shown as label
 * helperText: string shown at the bottom of the input to give info
 * children: icon shown on the left side of the input
 * value: text that will be shown
 * link: href associated with the field
 *
 * @type {module.SidebarReadOnlyField}
 */
module.exports = class SidebarReadOnlyField extends React.Component {
  isDirty(value) {
    return (value !== undefined && value !== null && value !== '');
  }

  getField() {
    if (this.props.value && this.isDirty(this.props.value)) {
      if (this.props.link) {
        return (
          <a className="wethod-input"
            href={this.props.link}>{this.props.value}
          </a>
        );
      }
      return (
        <span className="wethod-input">{this.props.value}</span>
      );
    }
    return (
      <span className="wethod-input disabled">-</span>
    );
  }

  render() {
    return (
      <SidebarWrapper placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}>
        {this.getField()}
      </SidebarWrapper>
    );
  }
};
