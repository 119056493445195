const React = require('react');
const CollaborativeInput = require('../../../../../common/react/CollaborativeInput/CollaborativeInput.react');

const BudgetCollaborativeInput = ({ projectId, id, children }) => (
  <CollaborativeInput id={id} sectionId={`budget-${projectId}`}>
    {children}
  </CollaborativeInput>
);

module.exports = BudgetCollaborativeInput;
