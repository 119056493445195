const React = require('react');
const Row = require('../SettingsRow.react');
const Select = require('../SettingsSelect.react');
const SelectItem = require('../SettingsSelectItem.react');

const OrderPlanViewPreference = ({ preference, isSaving, onPreferenceChange }) => {
  const feedback = isSaving ? 'Saving...' : null;

  function handlePreferenceChange(e) {
    const { value } = e.target;
    if (onPreferenceChange) {
      onPreferenceChange({ company_order_plan_view: value });
    }
  }

  return (
    <Row feedback={feedback}>
      <div>Time distribution of order’s costs based on the:</div>
      <Select
        name="order-plan-preference"
        label="order-plan-preference"
        value={preference}
        onChange={handlePreferenceChange}
        disabled={isSaving}>
        <SelectItem value="period_of_supply">Period of Supply</SelectItem>
        <SelectItem value="issue_date">Issue Date</SelectItem>
      </Select>
    </Row>
  );
};

module.exports = OrderPlanViewPreference;
