/* eslint-disable consistent-return */
const React = require('react');
const ImportantInfo = require('../containers/ImportantInfo');
const ProjectDetails = require('../containers/ProjectDetails');
const ContactDetails = require('../containers/ContactDetails');
const ProjectActions = require('../containers/ProjectActions');
const People = require('../containers/People/People');
const Goals = require('../containers/Goals');

module.exports = class About extends React.Component {
  getContactDetails() {
    if (this.props.project.customer) {
      return (
        <div className="timeline-project__about-item timeline-project__client-contact">
          <ContactDetails />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="timeline-project__about">
        <div className="timeline-project__about-item">
          <ImportantInfo />
        </div>
        <div className="timeline-project__about-item timeline-project__actions">
          <ProjectActions />
        </div>
        <div className="timeline-project__about-item timeline-project__details">
          <ProjectDetails />
        </div>
        {this.getContactDetails()}
        <div className="timeline-project__about-item timeline-project__people">
          <People />
        </div>
        <div className="timeline-project__about-item timeline-project__goals">
          <Goals />
        </div>
      </div>
    );
  }
};
