/* eslint-disable class-methods-use-this,no-case-declarations,no-shadow */
const constants = require('./constants');
const searchFilter = require('../services/filterWeekly');
const sortFavourites = require('../services/sortFavourites');
const businessUnitFilter = require('../services/businessUnitFilter');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');
const FeatureService = require('../services/FeatureService');

class TimesheetWeeklyReducer {
  constructor(state) {
    const { projects } = state;

    this.businessUnitService = new BusinessUnitService();

    this.state = {
      ...state,
      projects,
      projectsFilter: '',
      waitingFor: [], // contains a key for each pending request
      modal: null,
      focusedProject: {},
      showTotalAlert: false,
      buFilter: this.getBuForInitialFilter(state.userId),
      buEnabled: this.businessUnitService.isEnabled(),
    };

    this.state.filteredProjects = this.getFilteredProjects({
      keyword: this.state.projectsFilter,
      bu: this.state.buFilter,
    }, projects);

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  getFilteredProjects(filters, projects) {
    return searchFilter(filters.keyword, businessUnitFilter(filters.bu, projects));
  }

  /**
   * Return first business unit filter to use when rendering section:
   * - Last selected business units if user is seeing his own timesheet (no user filter applied)
   * - All business units otherwise. This is to avoid case in whicha user is editing another user
   * timesheet but cannot find a projects because the two guys belong to different units
   *
   * @param userFilter user filter. If missing, user is viewing his own timesheet
   * @returns {string[]}
   */
  getBuForInitialFilter(userFilter) {
    const userFilterApplied = userFilter !== null;
    return userFilterApplied ? this.businessUnitService.getIdListForCompany()
      : this.businessUnitService.getLastSelected();
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
          focusedProject: null,
        };
      case constants.NOTE_OPEN_MODAL:
        return {
          ...state,
          modal: 'notes',
          focusedProject: action.project.project.id,
        };
      case constants.CONFIRM_DELETE_OPEN_MODAL:
        return {
          ...state,
          modal: 'confirm-delete',
          focusedProject: action.project,
        };
      case constants.FILTER_PROJECTS:
        const filters = {
          keyword: action.keyword,
          bu: state.buFilter,
        };
        return {
          ...state,
          projectsFilter: action.keyword,
          filteredProjects: this.getFilteredProjects(filters, state.projects),
        };
      case constants.NOTE_SAVE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('notes'),
        };
      case constants.NOTE_SAVE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'notes'),
          modal: null,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                notes: action.note,
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                notes: action.note,
              };
            }
            return project;
          }),
        };
      case constants.CHANGE_BU_FILTER: {
        const filters = {
          keyword: state.projectsFilter,
          bu: action.bu,
        };
        return {
          ...state,
          buFilter: action.bu,
          filteredProjects: this.getFilteredProjects(filters, state.projects),
        };
      }
      case constants.DATE_CHANGE_REQUEST:
        return {
          ...state,
          filteredProjects: [],
          waitingFor: state.waitingFor.concat('get-projects'),
        };
      case constants.DATE_CHANGE_SUCCESS: {
        const filters = {
          keyword: state.projectsFilter,
          bu: state.buFilter,
        };
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-projects'),
          date: action.date,
          projects: action.projects,
          employee: action.employee,
          filteredProjects: this.getFilteredProjects(filters, action.projects),
          employeeHolidays: FeatureService.isEmployeeCapacityEnabled()
            ? action.employeeHolidays : [],
        };
      }
      case constants.DAY_CHANGE:
        return {
          ...state,
          date: action.date,
        };
      case constants.HOURS_SAVE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-project-hours-${action.project.project.id}`),
        };
      case constants.HOURS_SAVE_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project.project.id) {
              return {
                ...project,
                id: action.id,
                hours: action.project.hours,
                detail: action.project.detail,
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project.project.id) {
              return {
                ...project,
                id: action.id,
                hours: action.project.hours,
                detail: action.project.detail,
              };
            }
            return project;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `save-project-hours-${action.project.project.id}`),
        };
      case constants.HOURS_DELETE_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project.project.id) {
              return {
                ...project,
                id: null,
                hours: null,
                detail: action.project.detail,
                notes: null,
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project.project.id) {
              return {
                ...project,
                id: null,
                hours: null,
                detail: action.project.detail,
                notes: null,
              };
            }
            return project;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== `save-project-hours-${action.project.project.id}`),
        };
      case constants.FAVORITE_ADD_REQUEST:
        return {
          ...state,
          projects: sortFavourites(state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                /* 777 is a fake id, its only purpose is to create a bridge between the existing
                 Backbone's API and Redux.
                 */
                favorite: { id: 777 },
              };
            }
            return project;
          })),
          filteredProjects: sortFavourites(state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                /* 777 is a fake id, its only purpose is to create a bridge between the existing
                 Backbone's API and Redux.
                 */
                favorite: { id: 777 },
              };
            }
            return project;
          })),
        };
      case constants.FAVORITE_ADD_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: action.favorite_id },
              };
            }
            return project;
          }),
          filteredProjects: state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: action.favorite_id },
              };
            }
            return project;
          }),
        };
      case constants.FAVORITE_REMOVE:
        return {
          ...state,
          projects: sortFavourites(state.projects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: null },
              };
            }
            return project;
          })),
          filteredProjects: sortFavourites(state.filteredProjects.map((project) => {
            if (project.project.id === action.project_id) {
              return {
                ...project,
                favorite: { id: null },
              };
            }
            return project;
          })),
        };
      case constants.GET_PERIODICITY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('periodicity'),
        };
      case constants.GET_PERIODICITY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'periodicity'),
          periodicity: action.periodicity,
        };
      default:
        return state;
    }
  }
}

module.exports = TimesheetWeeklyReducer;
