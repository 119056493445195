/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');

class SidebarHeader extends React.Component {
  showActions() {
    if (this.props.isSaving) {
      return (
        <div className="wethod-sidebar__header-actions">
          <div className="wethod-sidebar__header-feedback">Saving</div>
        </div>
      );
    }
    if (!this.props.hasUnsavedChanges) {
      // When there's no changes we can close the sidebar
      if (this.props.canEdit) {
        return (
          <div className="wethod-sidebar__header-actions">
            <button type="button"
              className="wethod-button wethod-button--no-border"
              onClick={this.props.onEdit}>
              Edit
            </button>
            <div className="wethod-icon wethod-icon-discard wethod-icon-discard--black"
              onClick={this.props.onClose} />
          </div>
        );
      }
      return (
        <div className="wethod-sidebar__header-actions">
          <div className="wethod-icon wethod-icon-discard wethod-icon-discard--black"
            onClick={this.props.onClose} />
        </div>
      );
    }
    if (this.props.canSave) {
      // When there's some fields updated (and it's safe to save) we can enable save and cancel
      // actions
      return (
        <div className="wethod-sidebar__header-actions">
          <button type="button"
            className="wethod-button wethod-button--no-border"
            onClick={this.props.onCancel}>
            Cancel
          </button>
          <button type="button"
            className="wethod-button"
            onClick={this.props.onSave}>
            Save
          </button>
        </div>
      );
    }
    // If it's not safe to save we can see the disabled actions
    return (
      <div className="wethod-sidebar__header-actions">
        <button type="button"
          className="wethod-button wethod-button--no-border"
          onClick={this.props.onCancel}>
          Cancel
        </button>
        <button type="button"
          className="wethod-button disabled">
          Save
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="wethod-sidebar__header">
        <div className="wethod-sidebar__header-title">{this.props.title}</div>
        {this.showActions()}
      </div>
    );
  }
}

SidebarHeader.propTypes = {
  /**
   * String or element to be shown as sidebar title
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node, PropTypes.element]),

  hasUnsavedChanges: PropTypes.bool,

  /**
   * Boolean flag, used to get feedback when there's a pending saving
   */
  isSaving: PropTypes.bool,

  /**
   * Boolean flag, permission to edit
   */
  canEdit: PropTypes.bool,

  /**
   * The function associated with the click on cancel button
   */
  onCancel: PropTypes.func,

  /**
   * The function associated with the click on save button
   */
  onSave: PropTypes.func,

  /**
   * The function associated with the click on close button
   */
  onClose: PropTypes.func,

  /**
   * The function associated with the click on the edit button
   */
  onEdit: PropTypes.func,
};

module.exports = SidebarHeader;
