const React = require('react');
const RequestManager = require('../../../containers/RequestManager');
const TotalSummary = require('../../../containers/Calculator/Summary/TotalSummary');
const Summary = require('../../../containers/Calculator/Summary/Summary');

const SummaryFactoryColumn = ({
  name, cost, price, markup, externalCosts, levels, days, areas,
  editableBudget, previous, id, column, ...props
}) => {
  const type = id.toLowerCase();
  if (type === 'total') {
    return (
      <RequestManager>
        <TotalSummary
          showShadows={props.showShadows}
          column={column}
          editableBudget={editableBudget}
          name={name}
          cost={cost}
          price={price}
          markup={markup}
          previous={previous}
          externalCosts={externalCosts}
          levels={levels}
          days={days}
          areas={areas}
          id={id} />
      </RequestManager>
    );
  }
  return (
    <RequestManager>
      <Summary
        showShadows={props.showShadows}
        column={column}
        withAreaCreator={type === 'user-areas'}
        editableBudget={editableBudget}
        name={name}
        cost={cost}
        price={price}
        previous={previous}
        markup={markup}
        externalCosts={externalCosts}
        levels={levels}
        days={days}
        areas={areas}
        id={id} />
    </RequestManager>
  );
};

module.exports = SummaryFactoryColumn;
