/* eslint-disable no-shadow */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

const GoalModel = require('./GoalModel');

module.exports = Backbone.Collection.extend({
  initialize(attributes, options) {
    this.projectId = options.projectId;
  },
  model: GoalModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  url() {
    return `${APIURL}/canvas/${this.projectId}/goal`;
  },
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
  saveModel(attributes) {
    const model = new GoalModel(attributes, { projectId: this.projectId });

    return model.save();
  },
  updateModel(attributes) {
    const model = new GoalModel(attributes, { projectId: this.projectId });

    return model.update();
  },
  deleteModel(attributes) {
    const model = new GoalModel(attributes, { projectId: this.projectId });
    const defer = $.Deferred();

    model.destroy({
      success(model) {
        defer.resolve(model);
      },
      error(model, response) {
        defer.resolve(response);
      },
      wait: true,
    });
    return defer.promise();
  },
});
