const React = require('react');
const PropTypes = require('prop-types');

/**
 * A table cell which is able to render as:
 * - td
 * - th (with or without sort)
 * - loading
 * - empty (no results)
 */
const TableCell = ({
  children, name, variant, onSort, sort, emptyRow, loadingRow, className, rootRef, ...rest
}) => {
  const Component = variant === 'header' ? 'th' : 'td';

  const isSortable = onSort !== null;

  const getClassName = () => {
    let actualClassName = 'wethod-table__cell';
    if (className !== undefined) {
      actualClassName += ` ${className}`;
    }
    if (loadingRow) {
      actualClassName += ' wethod-table__cell--loading';
    }
    if (emptyRow) {
      actualClassName += ' wethod-table__cell--empty';
    }
    if (variant === 'header') {
      actualClassName += ' wethod-table__cell--header';
    }
    if (isSortable) {
      actualClassName += ' wethod-table__cell--sortable';
    }
    return actualClassName;
  };

  const getSortLabel = () => {
    if (isSortable) {
      if (sort === 'asc') {
        return (
          <span className="wethod-table__cell-icon-sort wethod-icon-medium-arrow-up wethod-icon-medium-arrow-up--black"
            data-testid="wethod-table-cell-sort-icon-asc" />
        );
      }
      if (sort === 'desc') {
        return (
          <span className="wethod-table__cell-icon-sort wethod-icon-medium-arrow-down wethod-icon-medium-arrow-down--black"
            data-testid="wethod-table-cell-sort-icon-desc" />
        );
      }
    }
    return null;
  };

  function handleClick() {
    if (isSortable) {
      if (sort === 'asc') {
        onSort(name, 'desc');
      } else if (sort === 'desc') {
        onSort(name, 'asc');
      } else {
        onSort(name, 'asc');
      }
    }
  }

  return (
    <Component className={getClassName()}
      onClick={handleClick}
      ref={rootRef}
      {...rest}>
      {getSortLabel()}
      {children}
    </Component>
  );
};

TableCell.defaultProps = {
  children: null,
  name: null,
  variant: null,
  onSort: null,
  sort: null,
  emptyRow: false,
  loadingRow: false,
  className: '',
  rootRef: null,
};

TableCell.propTypes = {
  children: PropTypes.node,
  /**
   * The name of the column this cell belongs to. Used for sort.
   */
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Used to decide which HTML Element to render between td and th.
   */
  variant: PropTypes.oneOf(['header']),
  /**
   * The function to call when cell is clicked. Used for sort.
   *
   * @param col {string} the name of the column which is being sorted
   * @param order {string} the order used to sort the column (asc|desc)
   */
  onSort: PropTypes.func,
  /**
   * Tells which kind of sort is applied to the column.
   */
  sort: PropTypes.oneOf(['asc', 'desc', null]),
  /**
   * True if cell represents an empty row.
   */
  emptyRow: PropTypes.bool,
  /**
   * True if cell represents a loading row.
   */
  loadingRow: PropTypes.bool,
  /**
   * className to be add to the base one.
   */
  className: PropTypes.string,
  /**
   * Expose cell DOM element ref to parent.
   * @param ref {HTMLElement}
   * */
  rootRef: PropTypes.func,
};

module.exports = TableCell;
