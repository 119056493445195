/* eslint-disable no-shadow,react/jsx-no-bind */
const React = require('react');
const moment = require('moment');
const HolidayTextFormatter = require('../../common/HolidayTextFormatter.react');
const FeatureService = require('../../services/FeatureService');

const DaySelect = ({
  isWaiting, date, onChange, internationalCalendar, dailyHours, isHoliday,
}) => {
  const getDaysList = () => {
    const daysList = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    return internationalCalendar ? daysList : daysList.slice(0, 5);
  };

  const getNextDate = (date) => date.add(1, 'days');

  const handleChange = (date) => {
    if (!isWaiting) {
      onChange(date);
    }
  };

  const getStampStyle = (hours) => {
    let style = 'timesheet-weekly__day-stamp';
    if (hours >= 8) {
      style += ' timesheet-weekly__day-stamp--completed';
    }
    return style;
  };

  const getDayStyle = (day) => {
    let style = 'timesheet-weekly__day';
    if (day.isSame(date)) {
      style += ' timesheet-weekly__day--selected';
    }
    return style;
  };

  const getBody = () => {
    const dayOfWeek = moment(date).startOf('isoWeek').subtract(1, 'days');
    return getDaysList().map((day) => {
      getNextDate(dayOfWeek);
      const formattedNumber = dayOfWeek.format('DD');
      const formattedDate = dayOfWeek.format('YYYY-MM-DD');
      const hours = dailyHours ? dailyHours[day] : 0;

      if (FeatureService.isEmployeeCapacityEnabled() && isHoliday(formattedDate)) {
        return (
          <div key={day} className={getDayStyle(dayOfWeek)}>
            <span>
              <HolidayTextFormatter>{day}</HolidayTextFormatter>
            </span>
            <button type="button"
              className="timesheet-weekly__day-button"
              onClick={() => handleChange(formattedDate)}>
              <HolidayTextFormatter>{formattedNumber}</HolidayTextFormatter>
            </button>
            <span className={getStampStyle(hours)} />
          </div>
        );
      }
      return (
        <div key={day} className={getDayStyle(dayOfWeek)}>
          <span>{day}</span>
          <button type="button"
            className="timesheet-weekly__day-button"
            onClick={() => handleChange(formattedDate)}>
            {formattedNumber}
          </button>
          <span className={getStampStyle(hours)} />
        </div>
      );
    });
  };

  const getStyle = () => {
    let style = 'timesheet-weekly__day-select';
    if (isWaiting) {
      style += ' disabled';
    }
    return style;
  };

  return (
    <div className={getStyle()}>
      {getBody()}
    </div>
  );
};

module.exports = DaySelect;
