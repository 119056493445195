const React = require('react');
const NotesModal = require('../containers/modals/NotesModal');
const ConfirmDeleteModal = require('../containers/modals/ConfirmDeleteModal');
const ExportModal = require('../containers/modals/ExportModal');
const CannotUploadModal = require('../../../../../common/react/modals/CannotUploadModal.react');
const CannotDownloadModal = require('../../../../../common/react/modals/CannotDownloadModal.react');
const PermissionManager = require('../containers/PermissionManager');
const ServerErrorModal = require('../../../../../common/react/Modal/SimpleModal/SimpleModal.react');

module.exports = ({ toShow, modalData, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'notes':
        return (
          <PermissionManager>
            <NotesModal data={modalData} onClose={closeModal} />
          </PermissionManager>
        );
      case 'confirm-delete':
        return <ConfirmDeleteModal data={modalData} onClose={closeModal} />;
      case 'export':
        return <ExportModal data={modalData} onClose={closeModal} />;
      case 'upload-error':
        return <CannotUploadModal data={modalData} onCancelClick={closeModal} />;
      case 'download-error':
        return <CannotDownloadModal data={modalData} onCancelClick={closeModal} />;
      case 'server-error':
        return (
          <ServerErrorModal onClose={closeModal} title={modalData.title}>
            {modalData.message}
          </ServerErrorModal>
        );
      default:
        return null;
    }
  };

  return getModal();
};
