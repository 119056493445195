/* eslint-disable react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events */
const React = require('react');
const Block = require('./PlanEditorBlock.react');

const PlanEditorWeekly = ({
  days, selectedProject, onMouseOverBlock, editorMode, isHighlighted, updateMode, onBlockClick,
  onMouseOut,
}) => {
  const hasSelectedProjectPlan = days
    .filter((day) => day.plans
      .filter((plan) => plan.project_id === selectedProject.id).length).length > 0;

  /**
   * Return a list of changes for selectedProject in the given days.
   * A change is an object like {day:'2020-06-20', amount:6}, change.amount is the new hours' amount for the selectedProject in the given day.
   * @param mode add|remove
   * @returns {[]}
   */
  const getChanges = (mode) => {
    const changes = [];

    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      const { plans } = day;
      const freeHours = plans.reduce((sum, plan) => sum - plan.amount, 8);
      const selectedProjectHours = plans
        .reduce((sum, plan) => (plan.project_id === selectedProject.id
          ? sum + plan.amount : sum), 0);
      if (mode === 'add') {
        const change = {
          day: day.date,
          amount: freeHours + selectedProjectHours,
        };
        changes.push(change);
      } else if (mode === 'remove') {
        const change = {
          day: day.date,
          amount: 0,
        };
        changes.push(change);
      }
    }
    return changes;
  };

  const onMouseMove = (id) => {
    if (editorMode) {
      const changes = getChanges(editorMode);
      onMouseOverBlock(id, changes);
    }
  };

  const onClick = () => {
    const mode = hasSelectedProjectPlan ? 'remove' : 'add';
    const changes = getChanges(mode);

    onBlockClick(changes, mode);
  };

  const onMouseDown = () => {
    if (hasSelectedProjectPlan) {
      updateMode('remove');
    } else {
      updateMode('add');
    }
  };

  const getBlocks = () => [undefined];

  const getBlocksFragment = () => getBlocks().map(() => {
    const index = days[0].date;
    return (
      <Block
        key={index}
        onMouseMove={() => onMouseMove(index)}
        onClick={onClick}
        onMouseDown={onMouseDown}
        highlighted={isHighlighted(index)} />
    );
  });

  return (
    <div className="planning-calendar__editor" onMouseOut={onMouseOut}>
      {getBlocksFragment()}
    </div>
  );
};

module.exports = PlanEditorWeekly;
