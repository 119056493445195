const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../../../Button/RoundedButton.react');
require('./style.scss');

class ItemCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
    };
  }

  componentDidUpdate(prevProps) {
    const saved = prevProps.isSaving && !this.props.isSaving;
    if (saved) {
      this.props.close();
    }
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving' : '';
  }

  render() {
    return (
      <div className="wethod-autocomplete-item-creator" data-testid="autocomplete-item-creator">
        <div className="wethod-autocomplete-item-creator__title">
          {this.state.title}
        </div>
        <div className="wethod-autocomplete-item-creator__body">
          {this.props.children}
        </div>
        <div className="wethod-autocomplete-item-creator__actions">
          <Button onClick={this.props.close} disabled={this.props.isSaving}>Cancel</Button>
          <Button onClick={this.props.create}
            disabled={this.props.isSaving || !this.props.isValid}>
            Create
          </Button>
        </div>
        <div className="wethod-autocomplete-item-creator__feedback">
          {this.getFeedback()}
        </div>
      </div>
    );
  }
}

ItemCreator.propTypes = {
  title: PropTypes.string.isRequired,
  /** Input needed for creation * */
  children: PropTypes.node.isRequired,
  /**
   * Function to call to close creator.
   * @param e {HTMLEvent}
   */
  close: PropTypes.func.isRequired,
  /**
   * Function to call when to create a new item.
   * @param item {{}} the item to create, depends on create form
   */
  create: PropTypes.func.isRequired,
  /** Tells if we are waiting for creation to complete * */
  isSaving: PropTypes.bool.isRequired,
  /** Tells if form is valid and can be saved * */
  isValid: PropTypes.bool.isRequired,
};

module.exports = ItemCreator;
