const React = require('react');
const PropTypes = require('prop-types');
const IconButton = require('../../TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('../../ShowIf/ShowIf.react');

class SegmentSelectItemAction extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.onClick(e);
  }

  render() {
    return (
      <ShowIf condition={this.props.show}>
        <IconButton label={this.props.tooltip}
          onClick={this.onClick}
          borderless>
          {this.props.children}
        </IconButton>
      </ShowIf>
    );
  }
}

SegmentSelectItemAction.defaultProps = {
  onClick: null,
  show: false,
};

SegmentSelectItemAction.propTypes = {
  /** Node to use as icon * */
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

module.exports = SegmentSelectItemAction;
