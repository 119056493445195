const { connect } = require('react-redux');
const Component = require('../components/TimeFilter.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  timeFilter: state.timeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(actions.changeTimeFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
