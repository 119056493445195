/**
 * @type {PathComponent}
 */
module.exports = class PathComponent {
  /**
   * PathComponent constructor.
   * @param {string} id
   * @param {string} name
   * @param {string} url
   * @param {int} child
   */
  constructor(id, name, url = null, child = null) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.child = child;
  }

  hasChild() {
    return this.child !== null;
  }
};
