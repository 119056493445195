/* eslint-disable react/prop-types */
const React = require('react');
const Tooltip = require('./Tooltip.react');

/**
 * Info icon with tooltip on hover with given content
 */
const InfoIconTooltip = ({
  content, className, tooltipId, tooltipStyle,
}) => {
  const getStyle = () => {
    let style = 'wethod-info-tooltip';
    if (className) style += ` ${className}`;
    return style;
  };

  return (
    <div className={getStyle()}>
      <Tooltip id={tooltipId}
        content={content}
        style={tooltipStyle} />
      <div className="wethod-icon wethod-icon-small-info wethod-icon-small-info--black" />
    </div>
  );
};

module.exports = InfoIconTooltip;
