const React = require('react');
const _ = require('underscore');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');
const Autocomplete = require('../../../../../../common/react/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class PmSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getManagers(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    let pm = null;

    if (item) {
      pm = {
        id: value,
        name: item.name,
        surname: item.surname,
      };
    }

    this.props.onChange(e, pm);
  }

  getOptions() {
    return this.state.availableItems.map((pm) => {
      const splitted = pm.hint.split(' ');
      const name = splitted[0];
      splitted.splice(0, 1);
      const surname = splitted.join(' ');

      return (
        <SelectItem key={pm.id}
          value={pm.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  getValue() {
    const { id } = this.props.value;
    if (id === null) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
        <Autocomplete readOnly={this.props.readOnly}
          onChange={this.onChange.bind(this)}
          value={this.getValue()}
          getInputValue={PmSelect.getInputValue}
          name={this.props.name}
          label="PM"
          id="sidebar-pm"
          onFilter={this.onFilter.bind(this)}
          errorText={this.props.error}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
