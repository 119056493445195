'use strict';

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette, $, _) {
  this.createBubbleChartSeries = function (data, dataType) {
    var bubbles = {
      series: [],
      maxYAxis: 100,
    };

    _.each(data, function (info) {
      var serie = {};
      if (!_.isUndefined(info.budget) && !_.isEmpty(info.budget) && !_.isNull(info.budget.id)) {
        // if( parseInt(info.actual) > maxYAxis[filter]){
        //     maxYAxis[filter] = parseInt(info.actual);
        // }

        if (parseInt(info[dataType]) > bubbles.maxYAxis) {
          bubbles.maxYAxis = parseInt(info[dataType]);
        }

        serie.name = info.project.name + ' <br> ' + info.client.name;
        serie.color = (info.progress >= info.actual ? '#1390ee' : '#d03557');
        serie.info = info;
        serie.data = [{
          x: parseFloat(info.progress),
          y: parseFloat(info.actual),
          z: parseFloat(info.budget.suggested_net_price || 0),
          id: info.project.id,
          name: info.project.name + ' - ' + info.client.name,
          dataLabels: {
            crop: false,
            enabled: true,
            color: 'black',
            formatter: function () {
              var subClientName = (!_.isNull(info.client.acronym)
                && !_.isUndefined(info.client.acronym)) ? info.client.acronym : info.client.name;
              var subJobOrder = (!_.isNull(info.project.job_order)
                && !_.isUndefined(info.project.job_order))
                ? info.project.job_order : info.project.name;

              subClientName = subClientName.substring(0, 3);
              subJobOrder = subJobOrder.substring(subJobOrder.length - 3, subJobOrder.length);

              var label = '';
              if (subClientName.charAt(subClientName.length) === '_' || subJobOrder.charAt(0) === '_') {
                label = subClientName + subJobOrder;
              } else {
                label = subClientName + '_' + subJobOrder;
              }

              return label.toLowerCase();
            },
          },
        }];

        bubbles.series.push(serie);
      }
    });

    var divider = {
      name: '',
      enableMouseTracking: false,
      lineWidth: 1,
      type: 'line',
      marker: { enabled: false },
      color: '#B8B6B4',
      data: [
        [0, 0],
        [120, 120],
      ],
    };
    bubbles.series.push(divider);
    return bubbles;
  };
});
