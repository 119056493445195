const { connect } = require('react-redux');
const Component = require('../../../components/sidebar/FileSection/FileSection.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  travel: state.focusedTravel,
});

const mapDispatchToProps = (dispatch) => ({
  showCannotDownloadFileModal: (data) => dispatch(actions.showCannotDownloadFileModal(data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
