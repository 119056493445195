/* eslint-disable react/sort-comp,jsx-a11y/anchor-is-valid,class-methods-use-this */
const React = require('react');
const Modal = require('../../../../../../common/react/Modal.react');
const Body = require('./DeleteEventModalBody.react');

module.exports = class DeleteEventModal extends React.Component {
  getActions() {
    return (
      <div className="clients-modal__actions budget-modal__actions--approve">
        <a
          href="#"
          className="roundButton koAction"
          onClick={this.handleClose.bind(this)}>
          <span className="text">NO</span>
        </a>
        <a
          href="#"
          className="roundButton okAction"
          onClick={this.handleDeleteAction.bind(this)}>
          <span className="text">YES</span>
        </a>
      </div>
    );
  }

  handleClose(e) {
    e.preventDefault();
    if (!this.props.isDeletingEvent) this.props.closeModal();
  }

  handleDeleteAction(e) {
    e.preventDefault();
    if (!this.props.isDeletingEvent) this.props.deleteEvent(this.props.event);
  }

  getFeedback() {
    if (this.props.isDeletingEvent) return 'Deleting...';
    return '';
  }

  getTitle() {
    return 'Delete Event';
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        title={this.getTitle()}
        handleClose={this.handleClose.bind(this)}
        body={<Body client={this.props.client} />}
        actions={this.getActions()}
        feedback={this.getFeedback()} />
    );
  }
};
