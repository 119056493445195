/* eslint-disable react/prefer-stateless-function */
const React = require('react');

/**
 * Button component, takes an 'active' props to set itself 'active' via css class and calls props.onClick when clicked
 */
class ArrowButton extends React.Component {
  render() {
    const className = this.props.active ? 'button button--active' : 'button';
    return (
      <button type="button" className={className} onClick={this.props.onClick}>
        <span className="button__label">{this.props.label}</span>
        <span className="button__label--mobile">{this.props.mobileLabel}</span>
        <span className="button__icon wethod-icon-dropdown wethod-icon-dropdown--black" />
      </button>
    );
  }
}

module.exports = ArrowButton;
