const { connect } = require('react-redux');
const Component = require('../../../components/Boards/Sidebar/MemberSelect.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  people: state.people,
  isLoading: state.waitingFor.includes('get-people-list'),
});

const mapDispatchToProps = (dispatch) => ({
  getPeople: () => dispatch(actions.getPeople()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
