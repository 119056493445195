'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var TimeOffStore = require('../../apps/core/modules/time-off/store');
var TimeOffReducer = require('../../apps/core/modules/time-off/reducer');
var TimeOffComponent = require('../../apps/core/modules/time-off/index');

// eslint-disable-next-line no-unused-vars
Wethod.module('TimeOffApp.TimeOff', function (TimeOff, Wethod, Backbone, Marionette, $) {
  TimeOff.Controller = {

    showTimeOffManager: function () {
      var authorizationRequest = Wethod.request('get:time-off:authorization');
      $.when(authorizationRequest).done(function (permissions) {
        if (!permissions.get('view')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        var layout = new TimeOff.StructureLayoutView();
        layout.render();

        var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
        var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
        layout.body.show(loadingView);

        var reducerWrapper = new TimeOffReducer({
          permissions: permissions.attributes,
        });

        var store = TimeOffStore(reducerWrapper.reduxReducer);
        var element = React.createElement(TimeOffComponent, { store: store });
        var container = document.getElementById('root');
        if (container !== null) ReactDOM.render(element, container);
      });
    },
  };
});
