const React = require('react');
const PropTypes = require('prop-types');
const RangeFilter = require('../RangeFilter.react');
const ValidatedRangeInput = require('./NumericRangeValidatedInput.react');
const RangeInput = require('../../../../inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');

const NumericRangeFilter = ({
  name,
  value,
  expanded,
  label,
  className,
  max,
  min,
  suffix,
  errors,
  updateErrors,
  onChange,
  placeholder,
}) => {
  const getConstraints = () => {
    const constraints = [];
    if (max !== undefined) {
      constraints.push(`max:${max}`);
    }
    if (min !== undefined) {
      constraints.push(`min:${min}`);
    }

    return constraints;
  };

  const hasConstraints = () => max !== undefined || min !== undefined;

  const Input = hasConstraints() ? ValidatedRangeInput : RangeInput;

  return (
    <RangeFilter name={name}
      value={value}
      placeholder={placeholder}
      expanded={expanded}
      label={label}
      className={className}
      suffix={suffix}
      input={Input}
      constraints={getConstraints()}
      errors={errors}
      updateErrors={updateErrors}
      onChange={onChange} />
  );
};

NumericRangeFilter.defaultProps = {
  expanded: false,
  label: '',
  className: '',
  min: undefined,
  max: undefined,
  suffix: null,
  value: {},
  placeholder: {
    min: 'Min',
    max: 'Max',
  },
  onChange: null,
  errors: {},
  updateErrors: null,
};

NumericRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  value: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    max: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  placeholder: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {{}}
   */
  onChange: PropTypes.func,
  /**
   * Function to update form errors.
   * @param name {string}
   * @param error {{}}
   */
  updateErrors: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  suffix: PropTypes.node,
};

module.exports = NumericRangeFilter;
