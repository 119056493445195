const React = require('react');
const PropTypes = require('prop-types');
const isEqual = require('react-fast-compare');
const Sidebar = require('../sidebar/Sidebar.react');

require('./style.scss');

class AdvancedSearchManager extends React.Component {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static found(key, value) {
    if (!key) return true;
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  }

  /**
   * Check whether the given value is not empty
   * @param value
   * @returns {boolean}
   */
  static isDirty(value) {
    return value != null && value !== '' && !isEqual(value, {}) && value.length !== 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      // Name of the expanded filter: there should be only one expanded filter at a time
      expanded: null,
      keyword: null, // Search keyword
      filters: this.props.filters, // Object representing all filters
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChildChange = this.handleChildChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSave() {
    this.props.onSave(this.state.filters);
  }

  handleClose() {
    this.props.onClose();
  }

  /**
   * Set the given child as the expanded one
   * @param name
   */
  handleExpand(name) {
    this.setState({ expanded: name });
  }

  /**
   * Unset the given child as the expanded one
   * @param name
   */
  handleCollapse(name) {
    if (this.state.expanded === name) {
      this.setState({ expanded: null });
    }
  }

  /**
   * Filter visible children on their 'label' attribute based on the search keyword
   * Unset the expanded child
   * @param e
   */
  handleSearch(e) {
    const { value } = e.target;
    this.setState({ keyword: value, expanded: null });
  }

  /**
   * Reset saved filters as empty
   */
  handleReset() {
    this.setState({ filters: {} });
  }

  /**
   * When the filter with the given name changes,
   * update the list of filters based on the given value.
   * @param name
   * @param value
   */
  handleChildChange(name, value) {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, [name]: value },
    }));
  }

  /**
   * Return the list of filters with additional props:
   * - {bool} expanded // Whether the child should be expanded or collapsed
   * - {func} expand // Function called on child expand
   * - {func} collapse // Function called on child collapse
   * - {func} onChange // Function called on child change
   *
   * The filters are filtered based on the search keyword and their attribute 'label'
   * @returns {Array<Exclude<React.DetailedReactHTMLElement<{
   * expanded: boolean,
   * expand: AdvancedSearchManager.handleExpand,
   * collapse: AdvancedSearchManager.handleCollapse
   * }, HTMLElement>, boolean | null | undefined>>}
   */
  getFilters(children) {
    const visibleFilters = [];
    React.Children.forEach(children, (child) => {
      if (this.isVisible(child)) {
        const elementAugmentedWithProps = React.cloneElement(child, {
          key: child.props.name,
          expanded: this.isExpanded(child),
          expand: this.handleExpand,
          collapse: this.handleCollapse,
          onChange: this.handleChildChange,
          value: this.getFilterValue(child),
        });
        visibleFilters.push(elementAugmentedWithProps);
      }
    });

    return visibleFilters;
  }

  /**
   * Return the body with additional props:
   * - children // Children of the body with additional props
   * - totalActive // Number of active filters
   * - onSearch // Function called when searching a filter
   * @returns {Array<Exclude<React.DetailedReactHTMLElement<{
   * children: Array<Exclude<React.DetailedReactHTMLElement<{
   *   expanded: boolean,
   *   expand: AdvancedSearchManager.handleExpand,
   *   collapse: AdvancedSearchManager.handleCollapse}, HTMLElement>, boolean|null|undefined>>,
   *   totalActive,
   *   onSearch: AdvancedSearchManager.handleSearch}, HTMLElement>, boolean | null | undefined>>}
   */
  getBody() {
    return React.cloneElement(this.props.children, {
      children: this.getFilters(this.props.children.props.children),
      totalActive: this.getTotalActive(),
      onSearch: this.handleSearch,
      onReset: this.handleReset,
    });
  }

  /**
   * Return the amount of children with a value which is not empty
   * @returns {number}
   */
  getTotalActive() {
    return Object.keys(this.state.filters).filter((filterName) => this.isActive(filterName)).length;
  }

  /**
   * Return the value corresponding the given filter
   * @param child
   */
  getFilterValue(child) {
    return this.state.filters[child.props.name];
  }

  /**
   * Check whether the given child should be expanded
   * @param child
   * @returns {boolean}
   */
  isExpanded(child) {
    return this.state.expanded === child.props.name;
  }

  /**
   * Check whether the given child is visible
   * @param child
   * @returns {boolean}
   */
  isVisible(child) {
    return AdvancedSearchManager.found(this.state.keyword, child.props.label);
  }

  /**
   * Check whether the child with the given name is active.
   * A filter is considered active when its value is non-empty.
   * @param name
   * @returns {boolean}
   */
  isActive(name) {
    return AdvancedSearchManager.isDirty(this.state.filters[name]);
  }

  render() {
    return (
      <Sidebar show={this.props.show}
        title={this.props.title}
        hasUnsavedChanges={this.props.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.props.canSave}
        canEdit={this.props.canEdit}
        onClose={this.handleClose}
        onSave={this.handleSave}
        onCancel={this.handleClose}
        body={this.getBody()} />
    );
  }
}

AdvancedSearchManager.propTypes = {
  // Whether to show or hide the advanced search sidebar
  show: PropTypes.bool,
  /**
   * Object representing the filters contained in the advanced search.
   * The object should have as keys the names of the filters,
   * and as values the values of the filters.
   * The values are injected in the children with the corresponding name
   * @see AdvancedSearchManager
   */
  filters: PropTypes.shape({}).isRequired,
  /**
   * Title of the advanced search sidebar
   * @see Sidebar
   */
  title: PropTypes.node,
  /**
   * Whether there are changes that needs to be applied
   * @see Sidebar
   */
  hasUnsavedChanges: PropTypes.bool,
  /**
   * Body of the sidebar
   */
  children: PropTypes.node.isRequired,
  /**
   * @see Sidebar
   */
  isSaving: PropTypes.bool,
  /**
   * @see Sidebar
   */
  canSave: PropTypes.bool,
  /**
   * @see Sidebar
   */
  canEdit: PropTypes.bool,
  /**
   * @see Sidebar
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @see Sidebar
   */
  onSave: PropTypes.func.isRequired,
};

AdvancedSearchManager.defaultProps = {
  show: false,
  title: 'Filters',
  hasUnsavedChanges: true,
  isSaving: false,
  canSave: true,
  canEdit: true,
};

module.exports = AdvancedSearchManager;
