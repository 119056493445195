const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const getLastThursday = require('../../../../../../services/DateService').getLastThursdayOfMonth;

module.exports = class CostRevenuesTrend extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      cost: '#3F5C81',
      revenue: '#98C1D9',
    };

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      chart: {
        type: 'areaspline',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
        spacingBottom: 0,
      },
      title: {
        text: null,
      },
      legend: {
        itemStyle: {
          fontWeight: 100,
          fontSize: 11,
        },
        align: 'left',
      },
      tooltip: {
        valueSuffix: 'K',
        shared: true,
        formatter(tooltip) {
          // Hide points with custom attribute from shared tooltip
          const points = this.points.filter((point) => point.point.tooltipDisabled !== true);
          // Use the default formatter with updated points list
          return tooltip.defaultFormatter.call({ ...this, points }, tooltip);
        },
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            color: '#a5a5a5',
          },
        },
        gridLineWidth: 0,
      },
      xAxis: {
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          marker: {
            fillColor: null, // inherit from series
            lineWidth: 1,
            lineColor: '#FFFFFF',
            symbol: 'circle',
          },
          dataLabels: {
            enabled: true,
          },
          events: {
            legendItemClick(e) {
              e.preventDefault();
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    const categories = this.getCategories();
    return {
      ...this.options,
      series: this.getSeries(),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1,
        min: 0,
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries() {
    const { costs, revenues } = this.props;
    const costSeries = this.getCostSeries(costs);
    const revenueSeries = this.getRevenueSeries(revenues);

    return [...revenueSeries, ...costSeries];
  }

  getCostSeries(series) {
    const seriesOptions = {
      name: 'Costs',
      color: this.colors.cost,
    };

    const pastSeriesOptions = {
      ...seriesOptions,
      fillColor: {
        linearGradient: {
          x1: 0, y1: 0, x2: 0, y2: 1,
        },
        stops: [
          [0, Highcharts.Color(this.colors.cost).setOpacity(1).get('rgba')],
          [1, Highcharts.Color(this.colors.cost).setOpacity(0).get('rgba')],
        ],
      },
    };

    return [
      this.getPastSeries(series, pastSeriesOptions),
      this.getFutureSeries(series, seriesOptions),
    ];
  }

  getRevenueSeries(series) {
    const seriesOptions = {
      name: 'Revenues',
      color: this.colors.revenue,
    };

    const pastSeriesOptions = {
      ...seriesOptions,
      fillColor: {
        linearGradient: {
          x1: 0, y1: 0, x2: 0, y2: 1,
        },
        stops: [
          [0, Highcharts.Color(this.colors.revenue).setOpacity(1).get('rgba')],
          [1, Highcharts.Color(this.colors.revenue).setOpacity(0).get('rgba')],
        ],
      },
    };

    return [
      this.getPastSeries(series, pastSeriesOptions),
      this.getFutureSeries(series, seriesOptions),
    ];
  }

  getPastSeries(series, seriesOptions) {
    const pastData = series.reduce((pastSeries, period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      if (index < this.todayBreakpointIndex) {
        // Format as K with 2 decimal places (max)
        const formattedValue = Math.round((period.value / 1000) * 100) / 100;
        pastSeries.push({ ...period, x: index, y: formattedValue });
      }
      return pastSeries;
    }, []);

    return {
      ...seriesOptions,
      data: pastData,
      zIndex: 2,
    };
  }

  getFutureSeries(series, seriesOptions) {
    const futureData = series.reduce((futureSeries, period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      // Format as K with 2 decimal places (max)
      const formattedValue = Math.round((period.value / 1000) * 100) / 100;
      /** Keep last period in future series to have a continue trend
       *  and add attribute to hide tooltip
       */
      if (index === this.todayBreakpointIndex - 1) {
        futureSeries.push({
          ...period,
          x: index,
          y: formattedValue,
          tooltipDisabled: true,
        });
      } else if (index > this.todayBreakpointIndex - 1) {
        futureSeries.push({ ...period, x: index, y: formattedValue });
      }
      return futureSeries;
    }, []);

    return {
      ...seriesOptions,
      type: 'spline',
      showInLegend: false,
      data: futureData,
      dashStyle: 'dash',
      zIndex: 1,
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="demographics__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
