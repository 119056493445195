/* eslint-disable react/sort-comp */
const React = require('react');
const ObjectiveItem = require('../../containers/Objectives/ObjectiveItem');
const QuarterStatus = require('../../containers/Objectives/QuarterStatus');

module.exports = class Quarter extends React.Component {
  isDraft() {
    return this.props.quarter_status < 1;
  }

  canEdit() {
    return this.props.editable && this.isDraft();
  }

  getObjectiveItems() {
    if (this.props.objectives.length > 0) {
      return this.props.objectives.map((objective) => (
        <ObjectiveItem
          key={objective.id}
          {...objective}
          quarterId={this.props.quarter} />
      ));
    }
    if (!this.canEdit()) {
      return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
    }
    return null;
  }

  onAddObjectiveClick() {
    this.props.addObjective({
      name: '',
      year: this.props.year,
      status: 2,
      quarter_status: 0,
    }, this.props.quarter, this.props.employee);
  }

  getAddButton() {
    if (this.canEdit()) {
      return (
        <button
          type="button"
          className="wethod-button profile-objectives__quarter-add-objective"
          onClick={this.onAddObjectiveClick.bind(this)}>
          + Objective
        </button>
      );
    }
    return null;
  }

  getStatus() {
    const status = this.props.quarter_status;
    if (status !== null && status !== undefined) {
      return <QuarterStatus status={status} quarter={this.props.quarter} />;
    }
    return null;
  }

  render() {
    return (
      <div className="profile-objectives__quarter">
        <div className="profile-objectives__quarter-header">
          <h3>{this.props.title}</h3>
          {this.getStatus()}
        </div>
        {this.getAddButton()}
        <div className="profile-objectives__item-collection">
          {this.getObjectiveItems()}
        </div>
      </div>
    );
  }
};
