const { connect } = require('react-redux');
const Component = require('../../components/sidebar/FileSection.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  onUploadSuccess: (item, file) => dispatch(actions.uploadFileSuccess(item, file)),
  onDeleteSuccess: (item, file) => dispatch(actions.deleteFileSuccess(item, file)),
  showCannotUploadFileModal: (errors) => dispatch(actions.showCannotUploadFileModal(errors)),
  showCannotDownloadFileModal: (errors) => dispatch(actions.showCannotDownloadFileModal(errors)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
