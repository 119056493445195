const React = require('react');
const TableSearch = require('../../../../../../common/react/Table2/TableSearch.react');

const TimesheetLogSearch = ({
  search, filter, ...rest
}) => (
  <TableSearch placeholder="Search"
    keyword={search}
    filter={filter}
    {...rest} />
);

module.exports = TimesheetLogSearch;
