/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return, react/no-array-index-key */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

module.exports = class ConfirmDeleteModal extends React.Component {
  isWaiting() {
    return this.props.waitingFor.filter((key) => key === `save-project-hours-${this.props.project.project.id}`).length > 0;
  }

  handleCancelClick() {
    if (!this.isWaiting()) this.props.onCancelClick();
  }

  handleSave() {
    if (!this.isWaiting()) this.props.deleteHours(this.props.project);
  }

  getSaveButtonStyle() {
    let style = 'wethod-button';
    if (this.isWaiting()) style += ' disabled';
    return style;
  }

  getCancelButtonStyle() {
    let style = 'wethod-button wethod-button--no-border';
    if (this.isWaiting()) style += ' disabled';
    return style;
  }

  getBody() {
    return (
      <div className="timesheet-weekly__modal--delete">
        <span>The notes belonging to this project's timesheet will be permanently deleted.</span>
        <br />
        <br />
        <span>Are you sure you want to continue?</span>
      </div>
    );
  }

  getActions() {
    return (
      <div className="timesheet-weekly__modal-actions">
        <button type="button" onClick={this.handleCancelClick.bind(this)} className={this.getCancelButtonStyle()}>
          Dismiss
        </button>
        <button type="button" onClick={this.handleSave.bind(this)} className={this.getSaveButtonStyle()}>Yes, delete</button>
      </div>
    );
  }

  getFeedback() {
    if (this.isWaiting()) return <div className="profile-contact-info__feedback">Deleting...</div>;
    return null;
  }

  render() {
    return (
      <Modal title="Delete confirmation" onCancelClick={this.props.onCancelClick} className="timesheet-weekly__modal">
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
