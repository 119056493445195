/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const TableSearch = require('../../../../../common/react/Table2/TableSearch.react');
const Table = require('../../../../../common/react/Table2/Table2.react');
const TableCell = require('../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../common/react/Table2/TableHead.react');
const TableRow = require('../../../../../common/react/Table2/TableRow.react');
const TableBody = require('../../../../../common/react/Table2/TableBody.react');
const BudgetTemplate = require('../containers/BudgetTemplate');
const Loader = require('../../../../../common/react/Loader/Loader.react');

module.exports = class BudgetTemplateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      size: 0,
    };
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.section-header').outerHeight(true) + 24);
  }

  filter(keyword) {
    this.setState({ page: 1 });
    this.props.filter(keyword);
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.templates.length > loaded;
  }

  loadMore(size, page) {
    this.setState({ page, size });
  }

  getTemplates() {
    const loadedTemplates = this.props.templates.slice(0, this.state.page * this.state.size);
    return loadedTemplates
      .map((template) => <BudgetTemplate key={template.id} template={template} />);
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return 'No budget templates yet';
  }

  getSearch() {
    return (
      <TableSearch
        placeholder="Search template"
        keyword={this.props.searchKeyword}
        filter={this.filter.bind(this)} />
    );
  }

  render() {
    return (
      <Table
        columns={4}
        maxHeight={this.getTableMaxHeight()}
        search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell className="budget-template-table__column--name">Template</TableCell>
            <TableCell className="budget-template-table__column--description">Description</TableCell>
            <TableCell className="budget-template-table__column--info" />
            <TableCell className="budget-template-table__column--actions" />
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getTemplates()}
        </TableBody>
      </Table>
    );
  }
};
