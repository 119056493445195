/* eslint-disable no-case-declarations,no-fallthrough */
const constants = require('./constants');
const TypeFactory = require('./factories/ClientTypeFactory');

class ClientsReducer {
  constructor(state) {
    this.state = {
      type: 'companies', // List to show: people|companies
      ...state,
      modalToShow: null,
      sidebarToShow: null,
      companies: state.companies.map((company) => ({
        ...company,
        name: company.corporate_name,
      })),
      people: state.people.map((person) => {
        const phone = person.phones && person.phones.length > 0 ? person.phones[0] : null;
        return {
          ...person,
          phones: phone,
        };
      }),
      knownGroups: [], // Company's groups already created
      keyword: '',
      sortBy: {
        key: 'name',
        order: 'asc',
      },
      load: {
        size: 0,
        page: 0,
      },
      loadedItems: [], // The items loaded in the view
      isLoading: false, // Are we waiting for a general API call response?
      isSavingClient: false, // Are we waiting for the "save client" API response?
      isDeletingClient: false, // Are we waiting for the "delete client" API response?
      focusedItem: null,
      linkedProjects: [], // Projects linked to a client user is deleting
      linkedContacts: [], // Contacts linked to a client user is deleting
    };

    this.state.filteredItems = TypeFactory(this.state.type)
      .order(this.state[this.state.type], 'name', 'asc'); // Items to show in the main list,
    // ordered by name

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SWITCH_CLIENT_TYPE:
        if (state.type !== action.key) {
          return {
            ...state,
            type: action.key,
            modalToShow: null,
            filteredItems: TypeFactory(action.key).order(state[action.key], 'name', 'asc'),
            sortBy: {
              key: 'name',
              order: 'asc',
            },
            load: {
              size: 0,
              page: 0,
            },
            loadedItems: [],
          };
        }
        return state;
      case constants.SHOW_DELETE_PERSON_MODAL:
        return {
          ...state,
          modalToShow: 'delete-person',
          sidebarToShow: null,
          focusedItem: action.item,
        };
      case constants.SHOW_DELETE_COMPANY_MODAL:
        return {
          ...state,
          modalToShow: 'delete-company',
          sidebarToShow: null,
          focusedItem: action.item,
        };
      case constants.SHOW_SIDEBAR:
        const sidebar = state.type === 'people' ? 'person-sidebar' : 'company-sidebar';
        return {
          ...state,
          sidebarToShow: sidebar,
          focusedItem: action.item,
        };
      case constants.CLOSE_SIDEBAR:
        return {
          ...state,
          sidebarToShow: null,
          focusedItem: null,
        };
      case constants.SHOW_MODAL_PROFILE_PIC:
        return {
          ...state,
          modalToShow: 'profile-pic',
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          focusedItem: null,
          isSavingClient: false,
          linkedProjects: [],
          linkedContacts: [],
        };
      case constants.FILTER_CLIENTS:
        let filteredItems = TypeFactory(state.type).filter(state[state.type], action.keyword);
        let sortedItems = TypeFactory(state.type)
          .order(filteredItems, state.sortBy.key, state.sortBy.order);
        return {
          ...state,
          filteredItems: sortedItems,
          loadedItems: TypeFactory(state.type).loadMore(sortedItems, [], state.load.size, 1),
          keyword: action.keyword,
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.ORDER_CLIENTS:
        sortedItems = TypeFactory(state.type).order(state.filteredItems, action.key, action.order);
        return {
          ...state,
          filteredItems: sortedItems,
          loadedItems: TypeFactory(state.type).loadMore(sortedItems, [], state.load.size, 1),
          sortBy: {
            key: action.key,
            order: action.order,
          },
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.LOAD_CLIENTS:
        return {
          ...state,
          loadedItems: TypeFactory(state.type)
            .loadMore(state.filteredItems, state.loadedItems, action.size, action.page),
          load: {
            size: action.size,
            page: action.page,
          },
        };
      case constants.PERSON_SAVE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.PERSON_SAVE_SUCCESS:
        const newPeople = state.people.concat({
          ...action.person,
          client: action.person.client,
        });
        filteredItems = TypeFactory(state.type).filter(newPeople, state.keyword);
        sortedItems = TypeFactory(state.type)
          .order(filteredItems, state.sortBy.key, state.sortBy.order);

        return {
          ...state,
          people: newPeople,
          filteredItems: sortedItems,
          focusedItem: action.person,
          loadedItems: TypeFactory(state.type).loadMore(sortedItems, [], state.load.size, 1),
          load: {
            size: state.load.size,
            page: 1,
          },
          isSavingClient: false,
        };
      case constants.PERSON_UPDATE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
          focusedItem: action.person,
        };
      case constants.PERSON_UPDATE_SUCCESS:
        let newState = {
          ...state,
          isSavingClient: false,
          modalToShow: null,
          focusedItem: null,
        };

        newState.people = state.people.map((person) => {
          if (person.id === action.person.id) return { ...person, ...action.person };
          return person;
        });
        filteredItems = TypeFactory(state.type).filter(newState.people, state.keyword);
        sortedItems = TypeFactory(state.type)
          .order(filteredItems, state.sortBy.key, state.sortBy.order);

        return {
          ...newState,
          filteredItems: newState.people,
          loadedItems: TypeFactory(state.type).loadMore(sortedItems, [], state.load.size, 1),
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.PERSON_DELETE_REQUEST:
        return {
          ...state,
          isDeletingClient: true,
        };
      case constants.PERSON_DELETE_SUCCESS:
        filteredItems = state.filteredItems.filter((company) => company.id !== action.id);
        return {
          ...state,
          isDeletingClient: false,
          modalToShow: null,
          sidebarToShow: null,
          focusedItem: null,
          people: state.people.filter((person) => person.id !== action.id),
          filteredItems,
          loadedItems: TypeFactory(state.type).loadMore(filteredItems, [], state.load.size, 1),
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.PERSON_PHONE_ADD_REQUEST:
      case constants.PERSON_PHONE_EDIT_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.PERSON_PHONE_ADD_SUCCESS:
      case constants.PERSON_PHONE_EDIT_SUCCESS:
        return {
          ...state,
          people: state.people.map((person) => {
            if (person.id === action.clientId) {
              return {
                ...person,
                phones: action.phone,
              };
            }
            return person;
          }),
          filteredItems: state.filteredItems.map((person) => {
            if (person.id === action.clientId) {
              return {
                ...person,
                phones: action.phone,
              };
            }
            return person;
          }),
          loadedItems: state.loadedItems.map((person) => {
            if (person.id === action.clientId) {
              return {
                ...person,
                phones: action.phone,
              };
            }
            return person;
          }),
          focusedItem: {
            ...state.focusedItem,
            phones: action.phone,
          },
          isSavingClient: false,
        };
      case constants.COMPANY_SAVE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.COMPANY_SAVE_SUCCESS:
        newState = {
          ...state,
          isSavingClient: false,
          companies: state.companies.concat({
            ...action.company,
            name: action.company.corporate_name,
          }),
        };

        if (state.type === 'companies') {
          filteredItems = TypeFactory(state.type).filter(newState.companies, state.keyword);
          sortedItems = TypeFactory(state.type)
            .order(filteredItems, state.sortBy.key, state.sortBy.order);

          newState.filteredItems = sortedItems;
          newState.loadedItems = TypeFactory(state.type)
            .loadMore(sortedItems, [], state.load.size, 1);
          newState.load = {
            size: state.load.size,
            page: 1,
          };
          newState.focusedItem = action.company;
        }
        return newState;
      case constants.COMPANY_UPDATE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.COMPANY_UPDATE_SUCCESS:
        newState = {
          ...state,
          isSavingClient: false,
          modalToShow: null,
          focusedItem: null,
        };

        newState.companies = state.companies.map((company) => {
          if (company.id === action.company.id) {
            return {
              ...company,
              ...action.company,
              name: action.company.corporate_name,
            };
          }
          return company;
        });

        filteredItems = TypeFactory(state.type).filter(newState.companies, state.keyword);
        sortedItems = TypeFactory(state.type)
          .order(filteredItems, state.sortBy.key, state.sortBy.order);

        return {
          ...newState,
          filteredItems: sortedItems,
          loadedItems: TypeFactory(state.type).loadMore(sortedItems, [], state.load.size, 1),
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.COMPANY_DELETE_REQUEST:
        return {
          ...state,
          isDeletingClient: true,
        };
      case constants.COMPANY_DELETE_FAILURE:
        return {
          ...state,
          modalToShow: 'delete-company-failure',
          sidebarToShow: null,
          isDeletingClient: false,
          linkedProjects: action.projects,
          linkedContacts: action.customers,
        };
      case constants.CLIENT_COMPANY_REPLACE_REQUEST:
        return {
          ...state,
          isDeletingClient: true,
        };
      case constants.CLIENT_COMPANY_REPLACE_SUCCESS:
        return {
          ...state,
          isDeletingClient: false,
          modalToShow: null,
          focusedItem: null,
          linkedProjects: [],
          linkedContacts: [],
        };
      case constants.COMPANY_DELETE_SUCCESS:
        filteredItems = state.filteredItems.filter((company) => company.id !== action.id);
        return {
          ...state,
          isDeletingClient: false,
          modalToShow: null,
          sidebarToShow: null,
          focusedItem: null,
          companies: state.companies.filter((company) => company.id !== action.id),
          filteredItems,
          loadedItems: TypeFactory(state.type).loadMore(filteredItems, [], state.load.size, 1),
          load: {
            size: state.load.size,
            page: 1,
          },
        };
      case constants.GROUP_SAVE_REQUEST:
        return {
          ...state,
          isSavingClient: true,
        };
      case constants.GROUP_SAVE_SUCCESS:
        return {
          ...state,
          isSavingClient: false,
          groups: state.groups.concat(action.group),
        };
      default:
        return state;
    }
  }
}

module.exports = ClientsReducer;
