/* eslint-disable default-case */

'use strict';

var paymentStructureTemplateHtml = require('./templates/PaymentStructureTemplate.html');

Wethod.module('SettingsApp.Account', function (Account, Wethod, Backbone, Marionette, $, _) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#accountTemplate',
    className: 'fluid',
    regions: {
      head: '[data-region="accountHead"]',
      body: '[data-region="accountBody"]',
      modal: '[data-region="accountModal"]',

      payment: '[data-region="accountPayment"]',
      import: '[data-region="accountImport"]',
      delete: '[data-region="accountDelete"]',
      integrations: '[data-region="accountIntegrations"]',
      reactSection: '[data-region="reactSection"]',
    },
    ui: {
      accountHeader: '[data-region="accountHeader"]',
      paymentSection: '[data-region="paymentSection"]',
      deleteSection: '[data-region="deleteSection"]',
      integrationsSection: '[data-region="integrationsSection"]',
    },
  });

  this.AccountHeadItemView = Marionette.ItemView.extend({
    template: '#accountHeadTemplate',
    className: 'grid',
  });

  this.AccountBodyItemView = Marionette.ItemView.extend({
    template: '#accountBodyTemplate',
    className: 'grid',
    ui: {
      nameInput: '[data-action="userNameInput"]',
      surnameInput: '[data-action="userSurnameInput"]',
      saveButton: '[data-action="saveUserInfoButton"]',
      currentPassword: '[data-action="currentPassword"]',
      newPassword: '[data-action="newPassword"]',
      confirmPassword: '[data-action="confirmPassword"]',
      notificationLabel: '[data-action="accountNotificationLabel"]',
    },
    events: {
      'click @ui.saveButton': 'saveData',
    },
    saveData: function () {
      if (!this.ui.saveButton.hasClass('disabled')) {
        var infoData = {
          name: this.ui.nameInput.val().trim(),
          surname: this.ui.surnameInput.val().trim(),
        };

        var passwordData = {
          old_password: this.ui.currentPassword.val().trim(),
          new_password_first: this.ui.newPassword.val().trim(),
          new_password_second: this.ui.confirmPassword.val().trim(),
        };

        Account.updateUserData(infoData, passwordData, this);
      }
    },
    disableSave: function () {
      if (!this.ui.saveButton.hasClass('disabled')) this.ui.saveButton.addClass('disabled');
    },
    enableSave: function () {
      if (this.ui.saveButton.hasClass('disabled')) this.ui.saveButton.removeClass('disabled');
    },
    removeNotification: function () {
      this.ui.notificationLabel.hide().fadeOut(100);
    },
    notifyFeedback: function (message) {
      this.ui.notificationLabel.hide().fadeIn(100);
      this.ui.notificationLabel.removeClass('correctMessage');
      this.ui.notificationLabel.removeClass('errorMessage');
      this.ui.notificationLabel.addClass('feedbackMessage');
      this.ui.notificationLabel.text(message);
    },
    notifyError: function (message) {
      this.ui.notificationLabel.hide().fadeIn(100);
      this.ui.notificationLabel.removeClass('correctMessage');
      this.ui.notificationLabel.removeClass('feedbackMessage');
      this.ui.notificationLabel.addClass('errorMessage');
      this.ui.notificationLabel.text(message);
    },
  });

  // INTEGRATIONS
  this.IntegrationsStructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="accountIntegrations"]',
    template: '#integrationsStructureTemplate',
    className: '',
    _integrations: [],
    regions: {
      billingBox: '[data-region="billingInfoBox"]',
    },
    ui: {
      enableGoogleCalendarButton: '[data-action="enableGoogleCalendar"]',
      disableGoogleCalendarButton: '[data-action="disableGoogleCalendar"]',
    },
    events: {
      'click @ui.disableGoogleCalendarButton': 'disableGoogleCalendar',
    },
    initialize: function (options) {
      this._integrations = options.enabled;
    },
    onRender: function () {
      var googleOauth2 = this._integrations
        .where({ name: 'google_oauth2', scope: 'https://www.googleapis.com/auth/calendar' })
        .length > 0;
      if (googleOauth2) {
        this.ui.enableGoogleCalendarButton.addClass('hide');
        this.ui.disableGoogleCalendarButton.removeClass('hide');
      } else {
        this.ui.enableGoogleCalendarButton.removeClass('hide');
        this.ui.disableGoogleCalendarButton.addClass('hide');
      }
    },
    disableGoogleCalendar: function (e) {
      e.preventDefault();
      var disableRequest = Wethod.request('integration:disable:google', { scope: 'calendar' });
      $.when(disableRequest).done(function () {
        this.ui.enableGoogleCalendarButton.removeClass('hide');
        this.ui.disableGoogleCalendarButton.addClass('hide');
      }.bind(this));
    },

  });

  // PAYMENT
  this.PaymentStructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="accountPayment"]',
    template: _.template(paymentStructureTemplateHtml),
    className: '',
    regions: {
      billingBox: '[data-region="billingInfoBox"]',

      cardBox: '[data-region="creditCardBox"]',

      modal: '[data-region="appDialog"]',
    },
    ui: {
      editCard: '[data-action="editCreditCard"]',
      editCardLabel: '[data-region="editCreditCardLabel"]',
      deleteCard: '[data-action="deleteCreditCard"]',

      mdlInput: '[data-region="mdl-input"]',

      promocode: '[data-region="promocode"]',
      promocodeButton: '[data-action="applayPromocode"]',
      promocodeFeedback: '[data-region="promocodeFeedback"]',
      promocodeInfo: '[data-region="promocodeInfo"]',
      promocodeAlert: '[data-region="alertPromocodeNotAvailable"]',
      promocodeAdd: '[data-region="addPromocode"]', // area dove si può inserire il promocode

      activePromocode: '[data-reggion="activePromocode"]',
      activePromocodeUntil: '[data-reggion="activePromocodeUntil"]',

      editBillingInfo: '[data-action="editBillingInfo"]',
      editBillingInfoLabel: '[data-region="editBillingInfoLabel"]',
      deleteBillingInfo: '[data-action="deleteBillingInfo"]',
    },
    events: {
      'click @ui.editCard': 'editCard',
      'click @ui.deleteCard': 'deleteCard',

      'click @ui.promocodeButton': 'activePromocode',

      'click @ui.editBillingInfo': 'editBillingInfo',
      'click @ui.deleteBillingInfo': 'deleteBillingInfo',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    editCard: function (e) {
      e.preventDefault();
      if (!this.ui.editCard.hasClass('disabled')) {
        Account.showAddCardModal();
      }
    },
    activePromocode: function (e) {
      e.preventDefault();

      if (!this.ui.promocodeButton.hasClass('disabled')) {
        this.ui.promocodeButton.addClass('disabled');
        var promocode = this.ui.promocode.val().trim();
        this.ui.promocodeFeedback.removeClass('payment-promocode-feedback-error').text('');

        if (promocode !== '') {
          this.ui.promocodeButton.addClass('disabled');
          Account.activePromocode(promocode, this);
        } else {
          this.ui.promocodeButton.removeClass('disabled');
          var emptyError = 'Insert a promocode';
          this.ui.promocodeFeedback.addClass('payment-promocode-feedback-error').text(emptyError);
        }
      }
    },
    deleteCard: function (e) {
      e.preventDefault();
      var data = {
        title: 'DELETE CREDIT CARD',
        text: 'Are you sure you want to delete your credit card?',
        buttonOkText: 'DELETE',
        buttonKoText: 'CANCEL',
        action: 'deleteCard',
      };
      Account.showModal(data);
    },
    editBillingInfo: function (e) {
      e.preventDefault();
      Account.showBillingInfoModal();
    },
    deleteBillingInfo: function (e) {
      e.preventDefault();
      var data = {
        title: 'DELETE BILLING INFO',
        text: 'Are you sure you want to delete your billing info?',
        buttonOkText: 'DELETE',
        buttonKoText: 'CANCEL',
        action: 'deleteBillingInfo',
      };
      Account.showModal(data);
    },
  });

  // CREDIT CARD
  this.NoCreditCardTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#noCreditCardTemplateItemView',
  });
  this.CreditCardTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#paymentCreditCardTemplate',
  });

  // card modal
  this.AddCardModalItemView = Marionette.ItemView.extend({
    template: '#paymentModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.paymentCardModalStructure',
      paymentFoorm: '[data-region="payment-form"]',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
      mdlInput: '[data-region="mdl-input"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - 350) / 2;
      var posTop = (contextH - 423) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        var form = $(this.ui.paymentFoorm);

        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        Stripe.card.createToken(form, Account.stripeResponseHandler);
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // BILLING INFO
  this.NoBillingInfoTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#noBillingInfoTemplateItemView',
  });
  this.BillingInfoTemplateItemView = Marionette.ItemView.extend({
    className: '',
    template: '#paymentBillingRecapTemplate',
  });

  // BillingInfoModalItemView
  this.BillingInfoModalItemView = Marionette.ItemView.extend({
    template: '#paymentBillingInfoModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.paymentBillingInfoModalStructure',

      editBillingInfoArea: '[data-region="editBillingInfo"]',

      privateBilling: '[data-action="privateForm"]',
      companyBilling: '[data-action="companyForm"]',

      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',

      'click @ui.privateBilling': function (e) {
        this.switchForm(e, 0);
      },
      'click @ui.companyBilling': function (e) {
        this.switchForm(e, 1);
      },
    },
    initialize: function (options) {
      this.options = options;

      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    onShow: function () {
      // render form inserimento/modifica dati di fatturazione
      Account.switchBillingType(this.model.get('type'), this);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - 600) / 2;
      var posTop = (contextH - 423) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    switchForm: function (e, type) {
      e.preventDefault();
      var target = $(e.target);
      if (!target.hasClass('sel')) {
        $('.sel').removeClass('sel');
        target.addClass('sel');
        Account.switchBillingType(type, this);
      }
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        $(this.ui.paymentFoorm);

        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        Account.saveBillingInfo(this.options.userBillingInfo);
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // form modifica ed inserimento dati di fatturazione da renderizzare all'interno del modal
  this.BillingEditItemView = Marionette.ItemView.extend({
    template: '#paymentBillingEditTemplate',
    className: '',
    ui: {
      mdlInput: '[data-region="mdl-input"]',
      inputEl: '[data-action="updateModel"]',
    },
    events: {
      'focusin @ui.inputEl': 'resetFieldError',
      'change @ui.inputEl': 'updateModel',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    resetFieldError: function (e) {
      var input = e.target;

      $(input).removeClass('billingError');
      $(input).parent().find('.mdl-textfield__label').removeClass('billingError');
      $('[data-message="statusModalAction"]').html('');
    },
    updateModel: function (e) {
      var inputName = e.target.name;
      var value = _.escape(e.target.value.trim());

      // aggiorno il modello all'interno delle options della view parent
      this.options.parentView.options.userBillingInfo.attributes[inputName] = value;
    },
  });

  // modal
  var modalW = 350;
  var modalH = 200;
  this.ModalItemView = Marionette.ItemView.extend({
    template: '#paymentGeneralModalTemplate',
    className: 'modalWrapper',
    _externalModel: undefined,
    _externalView: undefined,
    ui: {
      modalEL: '.paymentModalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        switch (this.model.get('action')) {
          case 'deleteCard':
            Account.stripeDeleteCard();
            break;
          case 'deleteBillingInfo':
            Account.deleteBillingInfo();
            break;
        }
      }
    },
    cancelAction: function (e) {
      if (!this.ui.cancelButton.hasClass('disabled')) {
        e.preventDefault();
        this.destroy();
      }
    },
  });

  // DELETE
  this.AccountDeleteBodyItemView = Marionette.ItemView.extend({
    template: '#accountDeleteBodyTemplate',
    className: 'grid',
    ui: {
      deleteButtonEl: '[data-action="deleteCompany"]',
    },
    events: {
      'click @ui.deleteButtonEl': 'deleteCompany',
    },
    deleteCompany: function (e) {
      e.preventDefault();
      Account.showDeleteModal();
    },
  });

  // card modal
  this.AccountModalDeleteItemView = Marionette.ItemView.extend({
    template: '#accountModalDeleteTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.paymentCardModalStructure',
      passwordEl: '[data-region="password"]',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
      mdlInput: '[data-region="mdl-input"]',
    },
    events: {
      'keyup @ui.passwordEl': 'allowDelete',
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._externalModel = options.externalModel;
      this._externalView = options.viewObj;
    },
    onRender: function () {
      this.placeModal();
    },
    onShow: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - 350) / 2;
      var posTop = (contextH - 402) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    allowDelete: function (e) {
      if (e.target.value.trim() !== '') {
        this.ui.actionButton.removeClass('disabled');
      } else {
        this.ui.actionButton.addClass('disabled');
      }
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');

        Account.DeleteCompany(this.ui.passwordEl.val().trim());
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
