const React = require('react');
const Modal = require('../containers/ModalController');
const Actions = require('../containers/Actions');
const Sidebar = require('../containers/Sidebar');
const Table = require('../containers/Table');
const PermissionManager = require('../containers/PermissionManager');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');

const AllocationRequests = () => (
  <div className="allocation-requests wethod-section-body">
    <SectionHeader current_section="Allocation Requests"
      helper_url="planning/index/#allocation-requests"
      big />
    <PermissionManager>
      <Actions />
    </PermissionManager>
    <Table />
    <Modal />
    <Sidebar />
  </div>
);

module.exports = AllocationRequests;
