const React = require('react');
const moment = require('moment');

/**
 * Component that injects in child utility methods to deal with employee capacity and company holidays.
 *
 * @param children
 * @param {{monday: number, tuesday: number, wednesday: number, thursday: number, friday: number, saturday: number, sunday: number}} employeeCapacity
 * @param [{date:string}] holidays List of holiday for the week
 * @param rest
 * @return {React.FunctionComponentElement<unknown>}
 * @constructor
 */
const CapacityManager = ({
  children, employeeCapacity, holidays,
  includeWeekend = false, ...rest
}) => {
  const formatDate = (date) => moment(date).format('YYYY-MM-DD');
  /**
   * Format capacity to be more usable.
   * @return {{thu: number, tue: number, wed: number, sat: number, fri: number, mon: number, sun: number}}
   */
  const getFormattedCapacity = () => {
    const holidayDays = holidays.map((holiday) => moment(holiday.date).format('ddd').toLowerCase());
    const formatted = {
      mon: employeeCapacity.monday,
      tue: employeeCapacity.tuesday,
      wed: employeeCapacity.wednesday,
      thu: employeeCapacity.thursday,
      fri: employeeCapacity.friday,
      sat: employeeCapacity.saturday,
      sun: employeeCapacity.sunday,
    };
    if (!includeWeekend) {
      formatted.sat = 0;
      formatted.sun = 0;
    }
    holidayDays.forEach((day) => formatted[day] = 0);

    return formatted;
  };

  const capacity = getFormattedCapacity();

  /**
   * Return true if given date is a company holiday for the employee.
   * @param {string} date
   * @return {boolean}
   */
  const isHoliday = (date) => holidays
    .map((holiday) => formatDate(holiday.date))
    .includes(formatDate(date));
  /**
   * Return employee capacity for the given date.
   * Capacity for a holiday is 0.
   * @param {string} date
   * @return {number}
   */
  const getDayCapacity = (date) => {
    if (isHoliday(date)) {
      return 0;
    }
    const dayIndex = moment(date).format('ddd').toLowerCase();
    return capacity[dayIndex];
  };

  /**
   * Return weekly sum of user capacity.
   * Capacity for holiday is 0,
   * @return {number}
   */
  const getWeekCapacity = () => capacity.mon + capacity.tue + capacity.wed + capacity.thu
      + capacity.fri + capacity.sat + capacity.sun;

  return React.cloneElement(children, {
    ...rest,
    getDayCapacity,
    isHoliday,
    getWeekCapacity,
  });
};

module.exports = CapacityManager;
