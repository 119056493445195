const { connect } = require('react-redux');
const Component = require('../../../components/modals/Languages/LanguagesBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  languages: state.languages,
  employee: state.id,
  waiting: state.waiting,
  creating: state.creating,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
  addLanguage: (language) => dispatch(actions.addLanguage(language)),
  deleteLanguage: (language) => dispatch(actions.deleteLanguage(language)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
