const { connect } = require('react-redux');
const ProjectInfoComponent = require('../components/ProjectInfo.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = (dispatch) => ({
  addFavorite: (projectId) => dispatch(actions.addFavorite(projectId)),
  removeFavorite: (projectId, favoriteId) => dispatch(actions
    .removeFavorite(projectId, favoriteId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(ProjectInfoComponent);
