const React = require('react');
const IconLink = require('../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../../../../../common/react/Icon/Icon.react');
const MoreOptionsMenuItem = require('./MoreOptionMenuItem.react');
const MoreOptions = require('./MoreOptionsButton.react');

const ProjectSidebarActions = ({ projectId }) => (
  <div>
    <IconLink label="Pipeline"
      borderless
      href={`#pipeline/projects?id=${projectId}`}
      target="_blank">
      <Icon icon="pipeline" />
    </IconLink>
    <IconLink label="Planning"
      borderless
      href={`#planning/people?project_id=${projectId}`}
      target="_blank">
      <Icon icon="planning" />
    </IconLink>
    <IconLink label="Canvas"
      borderless
      href={`#project/${projectId}/canvas`}
      target="_blank">
      <Icon icon="canvas" />
    </IconLink>
    <IconLink label="Report"
      borderless
      href={`#project/${projectId}/report`}
      target="_blank">
      <Icon icon="project-report" />
    </IconLink>
    <IconLink label="Invoice plan"
      className="hidden-mobile"
      borderless
      href={`#project/${projectId}/invoice-plan`}
      target="_blank">
      <Icon icon="calendar" />
    </IconLink>
    <div className="wethod-vertical-line" />
    <MoreOptions>
      <MoreOptionsMenuItem href={`#timeline/${projectId}`}>Timeline</MoreOptionsMenuItem>
      <MoreOptionsMenuItem href={`/#project-status/${projectId}`}>Project status</MoreOptionsMenuItem>
      <MoreOptionsMenuItem href={`#planning/production-plan?project_id:${projectId}`}>Production plan</MoreOptionsMenuItem>
      <MoreOptionsMenuItem href={`#finance/order/?project=${projectId}`}>Orders</MoreOptionsMenuItem>
    </MoreOptions>
  </div>
);

module.exports = ProjectSidebarActions;
