const React = require('react');

module.exports = class CalendarHeaderColumnYear extends React.Component {
  getClassName() {
    const isToday = moment().year() === this.props.column.year();
    let name = 'project-canvas-gantt__calendar-header-column project-canvas-gantt__calendar-column--year';
    if (isToday) {
      name += ' project-canvas-gantt__calendar-header-column--today';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.props.column.format('YYYY')}
      </div>
    );
  }
};
