const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/WorkedHours.react');

const mapStateToProps = (state) => ({
  month: state.month,
  prevMonth: state.date.clone().subtract(1, 'month').format('MMMM'),
  workedHours: state.month_workable_hours,
  chargeableOnWorked: state.month_chargeable_hours_perc,
  prevMonthChargeableOnWorked: state.prev_month_chargeable_hours_perc,
});

module.exports = connect(mapStateToProps)(Component);
