/* eslint-disable react/jsx-no-bind */
const React = require('react');
const TraspImage = require('../../../../../../../img/trasp.gif');

/**
 * Input with 'search' icon:
 * - it calls 'this.props.searchWith(value)' whe someting is written on it
 * - it uses 'this.props.label' as placeholder
 */
class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const search = event.target.value;
    this.setState({ value: search });
    this.props.searchWith(search);
  }

  render() {
    return (
      <div className="left searchWrap"
        style={{
          display: 'inline-block',
          height: '30px',
        }}>
        <img src={TraspImage} className="left searchIcon" alt="search" />
        <input
          ref={(input) => this.searchInput = input}
          className="searchInput"
          value={this.state.value}
          placeholder={this.props.label}
          onClick={() => this.searchInput.select()}
          onChange={this.handleChange} />
      </div>
    );
  }
}

module.exports = SearchInput;
