const React = require('react');
const HeaderBox = require('./FinalPriceHeaderBox.react');
const PrimarySection = require('./FinalPricePrimarySection.react');
const SecondarySection = require('../Price/PriceSecondarySection.react');

const FinalPrice = ({
  price,
  totalCosts,
  externalCosts,
  saveInfo = null,
  saveInfoOptimistic = null,
  budgetIsEditable = false,
  previousPrice = null,
  budgetCurrencyCode = null,
  exchangeRateDate = null,
  getAmountInMasterCurrency = null,
  getAmountInCurrency = null,
  masterCurrency = null,
  isCurrencyEnabled = false,
  invoiceDrivenExchangeRate = false,
  isIntercompanySupplier = false,
}) => {
  const grossMarginValue = price - externalCosts;
  const grossMarginPercentage = price ? (grossMarginValue / price) * 100 : 0;
  const contributionMarginValue = price - totalCosts;
  const contributionMarginPercentage = price ? (contributionMarginValue / price) * 100 : 0;

  function onMasterValueChange(value) {
    saveInfo({ final_net_price: value });
  }

  function onCurrencyValueChange(value) {
    saveInfo({
      final_net_price_currency: value,
    });
  }

  function onCurrencyChange(currencyCode) {
    const currencyValue = getAmountInCurrency(price, budgetCurrencyCode);
    const masterCurrencyValue = getAmountInMasterCurrency(currencyValue, currencyCode);
    saveInfoOptimistic({ final_net_price: masterCurrencyValue, currency: currencyCode });
  }

  const masterCurrencyCode = masterCurrency ? masterCurrency.code : null;

  const currencySection = !isCurrencyEnabled ? null : (
    <PrimarySection label="Currency Final Net Price"
      hideLabel
      isCurrencyEnabled={isCurrencyEnabled}
      editable={budgetIsEditable && !isIntercompanySupplier}
      currencyEditable={budgetIsEditable && !isIntercompanySupplier}
      value={getAmountInCurrency(price, budgetCurrencyCode, true)}
      onValueChange={onCurrencyValueChange}
      onCurrencyChange={onCurrencyChange}
      exchangeRateDate={exchangeRateDate}
      invoiceDrivenExchangeRate={invoiceDrivenExchangeRate}
      currencyCode={budgetCurrencyCode} />
  );

  return (
    <HeaderBox>
      <PrimarySection label="Final Net Price"
        editable={budgetIsEditable && !isIntercompanySupplier}
        isCurrencyEnabled={isCurrencyEnabled}
        value={price}
        onValueChange={onMasterValueChange}
        previousValue={previousPrice}
        currencyEditable={false}
        currencyCode={masterCurrencyCode} />
      <SecondarySection grossMarginValue={grossMarginValue}
        grossMarginPercentage={grossMarginPercentage}
        contributionMarginValue={contributionMarginValue}
        contributionMarginPercentage={contributionMarginPercentage}
        grossMarginTooltip="Difference between Final Net Price and Ext. Costs"
        constributionMarginTooltip="Difference between Final Net Price and all costs (Costs and Ext. Costs)" />
      {currencySection}
    </HeaderBox>
  );
};

module.exports = FinalPrice;
