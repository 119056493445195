/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('../../../../../../../../../common/react/Table2/TableCell.react');

module.exports = class NumericItemCellView extends React.Component {
  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell">{this.props.value}</TableCell>
    );
  }
};
