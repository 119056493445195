const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');

const CriterionRow = ({ criterion, selected, onClick }) => {
  function handleRowClick() {
    onClick(criterion.id);
  }

  function getRowStyle() {
    let style = 'kudos__criterion-row';
    if (selected) style += ' kudos__criterion-row--selected';

    return style;
  }

  return (
    <TableRow className={getRowStyle()} onClick={handleRowClick}>
      <TableCell name="criterion" className="kudos__column-criterion">{criterion.name}</TableCell>
    </TableRow>
  );
};

module.exports = CriterionRow;
