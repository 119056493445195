const { connect } = require('react-redux');
const TypeSwitcher = require('../components/TypeSwitcher.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  current: state.typeFilter,
  size: state.size,
  keyword: state.keyword,
  bu: state.buFilter,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (size, keyword, type, bu) => dispatch(actions
    .filterProjects(size, keyword, type, bu)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TypeSwitcher);
