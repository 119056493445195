const React = require('react');
const TableCell = require('../../../../../../../../../common/react/Table2/TableCell.react');
const Portion = require('./StatusPortion.react');

module.exports = class StatusSummaryCellEdit extends React.Component {
  getPortions() {
    return this.props.value.map((portion) => <Portion key={portion.id} {...portion} />);
  }

  render() {
    return (
      <TableCell
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell--summary project-canvas-spreadsheet__cell--summary-status-container">
        {this.getPortions()}
      </TableCell>
    );
  }
};
