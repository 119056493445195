/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind */
const React = require('react');

module.exports = class MilestoneElement extends React.Component {
  handleClick() {
    this.props.onClick(this.props.milestone.id);
  }

  getIcon() {
    return `wethod-icon wethod-icon-${this.props.milestone.name.toLowerCase()}`;
  }

  render() {
    return (
      <div
        className="project-canvas-menu-milestone__element"
        onClick={this.handleClick.bind(this)}>
        <div className={this.getIcon()} />
        <span>{this.props.milestone.name}</span>
      </div>
    );
  }
};
