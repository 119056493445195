const React = require('react');
const VersionList = require('./VersionList.react');
const Navigator = require('./VersionNavigator.react');

module.exports = class Versions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { scrollPosition: 0, containerEl: null, listEl: null };

    // Scroll amount for each position change
    this.scrollWidth = 100;

    this.previousPosition = this.previousPosition.bind(this);
    this.nextPosition = this.nextPosition.bind(this);
    this.setContainerEl = this.setContainerEl.bind(this);
    this.setListEl = this.setListEl.bind(this);
  }

  setContainerEl(el) {
    this.setState({ containerEl: el });
  }

  setListEl(el) {
    this.setState({ listEl: el });
  }

  getMaxForwardScroll() {
    if (!this.state.containerEl || !this.state.listEl) {
      return 0;
    }
    const containerWidth = this.state.containerEl.offsetWidth;
    const listWidth = this.state.listEl.offsetWidth;

    return (listWidth - containerWidth) / this.scrollWidth;
  }

  nextPosition() {
    this.setState((prevState) => ({ scrollPosition: prevState.scrollPosition + 1 }));
  }

  previousPosition() {
    this.setState((prevState) => ({ scrollPosition: prevState.scrollPosition - 1 }));
  }

  canGoForward() {
    return this.state.scrollPosition < this.getMaxForwardScroll();
  }

  render() {
    return (
      <div className="wethod-budget-versions">
        <div className="wethod-budget-version-list-container" ref={this.setContainerEl}>
          {(this.props.versions?.length > 0)
            ? (
              <VersionList versions={this.props.versions}
                scrollWidth={this.scrollWidth}
                onVersionClick={this.props.onVersionClick}
                position={this.state.scrollPosition}
                setListEl={this.setListEl}
                selectedVersions={this.props.selectedVersions} />
            )
            : null }
        </div>
        <Navigator scrollBack={this.previousPosition}
          scrollForward={this.nextPosition}
          canGoForward={this.canGoForward()}
          canGoBack={this.state.scrollPosition > 0} />
      </div>
    );
  }
};
