'use strict';

Wethod.module('TimelineApp', function (TimelineApp) {
  this.startWithParent = false;

  var API = {
    showTimeline: function (id) {
      TimelineApp.Timeline.Controller.showTimeline(id);
    },
    showCompanyTimeline: function () {
      TimelineApp.Timeline.Controller.showCompanyTimeline();
    },
  };

  TimelineApp.on('start', function (options) {
    API[options.foo](options.id);
  });
});
