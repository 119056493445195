/* eslint-disable no-unused-vars */

const request = require('./Request');

const EmployeeTags = {
  get() {
    const url = '/tagempcategory?type=SELECT';
    return request({
      method: 'get',
      url,
    });
  },
  set(id, data) {
    return request({
      method: 'put',
      url: `/tagempcategory/${id}`,
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/tagempcategory/${id}`,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/tagempcategory',
      data,
    });
  },
  getItems() {
    const url = '/tagemp';
    return request({
      method: 'get',
      url,
    });
  },
  createItem(data) {
    const url = '/tagemp';
    return request({
      method: 'post',
      url,
      data,
    });
  },
  deleteItem(id) {
    const url = `/tagemp/${id}`;
    return request({
      method: 'delete',
      url,
    });
  },
  updateTag(tag) {
    const url = `/tagemp/${tag.id}`;
    return request({
      method: 'put',
      url,
      data: {
        name: tag.name,
      },
    });
  },
};

module.exports = EmployeeTags;
