const React = require('react');
const _ = require('underscore');
const $ = require('jquery');
const OrdersAutocomplete = require('../../../../../../../models/OrdersAutocomplete');
const SelectItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const Autocomplete = require('../../../../../../../common/react/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');

module.exports = class ProjectSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.name);
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredProjects: [],
      keyword: null,
      isLoading: false,
    };
  }

  handleFilter(name, key) {
    this.setState({ isLoading: true });
    $.when(OrdersAutocomplete.getProjects(key)).done((projects) => {
      if (projects) {
        this.setState({
          filteredProjects: projects,
          keyword: key,
          isLoading: false,
        });
      }
    });
  }

  handleFocus() {
    if ((!this.state.filteredProjects || this.state.filteredProjects.length === 0)
      && !this.state.keyword) {
      this.handleFilter('project', '');
    }
  }

  handleChange(e, item) {
    const projectId = item ? item.value : null;

    const oldProjectId = this.props.value ? this.props.value.id : null;
    if (projectId !== oldProjectId) {
      this.props.changeProject(projectId);
      if (this.props.onChange) {
        const project = item ? item.project : null;
        this.props.onChange(e.target.name, project);
      }
    }
  }

  getSelectedProject() {
    return (this.props.value && this.props.value.id) ? this.props.value : null;
  }

  getOptions() {
    return this.state.filteredProjects.map((project) => {
      const name = _.unescape(project.name);

      return (
        <SelectItem key={project.id}
          project={project}
          value={project.id}
          name={name}>
          {name}
        </SelectItem>
      );
    });
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
        <Autocomplete readOnly={this.props.readOnly}
          onChange={this.handleChange.bind(this)}
          value={this.getSelectedProject()}
          getInputValue={ProjectSelect.getInputValue}
          name={this.props.name}
          label="Project"
          id="sidebar-project"
          onFocus={this.props.onFocus}
          onFilter={this.handleFilter.bind(this)}
          errorText={this.props.error}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
