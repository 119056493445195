const React = require('react');
const Window = require('./Window.react');

module.exports = class LeftColumnWindow extends React.Component {
  getStyle() {
    return {
      left: '0px',
      marginTop: `${this.props.top}px`,
      width: `${this.props.width}px`,
    };
  }

  render() {
    if (this.props.renderer) {
      return (
        <Window
          className={this.props.className}
          style={this.getStyle()}
          type="left-column">
          {this.props.renderer(this.props.items)}
        </Window>
      );
    }
    return null;
  }
};
