const { connect } = require('react-redux');
const Component = require('../../components/sidebar/AreaSelect.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  areas: state.selectedProjectAreas ? state.selectedProjectAreas : [],
  isLoading: state.waitingFor.filter((key) => key === 'areas').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getAreas: (projectId) => dispatch(actions.getProjectAreas(projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
