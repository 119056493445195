/* eslint-disable consistent-return,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const Header = require('./SidebarHeader.react');

/**
 * A sidebar component.
 */
class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    /**
     * forbiddenClosing - boolean flag set true when the user tries to close the sidebar clicking
     * on the overlay with unsaved changes
     */
    this.state = {
      forbiddenClosing: false,
      sidebarFocused: false,
    };
  }

  // Clear timeout and set a flag to avoid setState on unmounted component
  componentWillUnmount() {
    this.unmount = true;
    clearTimeout(this.timeout);
    this.timeout = 0;
  }

  handleFocus() {
    this.setState({ sidebarFocused: true });
  }

  /**
   * sidebarFocus is set true when the user trigger the mouseDown on the sidebar (e.g. selecting
   * text) to prevent the closing of the sidebar when the mouse is released on the overlay It is
   * set again to false after a delay to prevent the state to be stuck on "true"
   */
  handleMouseDown() {
    this.setState({ sidebarFocused: true });
    this.timeout = setTimeout(() => {
      if (!this.unmount && this.state.sidebarFocused) {
        this.setState({ sidebarFocused: false });
      }
    }, 1000);
  }

  // Stop propagation is needed in order to prevent the sidebar to close itself when clicked
  // (parent overlay behaviour)
  handleSidebarClick(e) {
    this.setState({ sidebarFocused: false });
    e.stopPropagation();
  }

  /**
   * ForbiddenClosing is set to true to notify that the user tried to close the sidebar,
   * it is set again to false after a short delay to let the animation be triggered again on next
   * click
   */
  handleOverlayClick() {
    if (!this.state.sidebarFocused && !this.state.forbiddenClosing) {
      if (this.props.hasUnsavedChanges) {
        this.setState({ forbiddenClosing: true });
        this.timeout = setTimeout(() => {
          this.setState({ forbiddenClosing: false });
        }, 500);
      } else {
        this.props.onClose();
      }
    }
  }

  getStyle() {
    let style = 'wethod-sidebar__overlay';

    if (this.state.forbiddenClosing) {
      style += ' wethod-sidebar__overlay--animate';
    }

    return style;
  }

  getBar() {
    if (this.props.error) {
      return (
        <div className="wethod-sidebar__actions-bar wethod-sidebar__actions-bar--error">
          {this.props.error}
        </div>
      );
    }
    if (this.props.actions) {
      return (
        <div className="wethod-sidebar__actions-bar">
          {this.props.actions}
        </div>
      );
    }
  }

  getAlertBar() {
    if (this.props.alert) {
      return (
        <div className="wethod-sidebar__actions-bar wethod-sidebar__actions-bar--alert">
          {this.props.alert}
        </div>
      );
    }
  }

  render() {
    return (
      <div className={this.getStyle()} onClick={this.handleOverlayClick.bind(this)}>
        <div className="wethod-sidebar"
          onClick={this.handleSidebarClick.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onMouseDown={this.handleMouseDown.bind(this)}>
          <Header title={this.props.title}
            onCancel={this.props.onCancel}
            onSave={this.props.onSave}
            onClose={this.props.onClose}
            onEdit={this.props.onEdit}
            hasUnsavedChanges={this.props.hasUnsavedChanges}
            isSaving={this.props.isSaving}
            canSave={this.props.canSave}
            canEdit={this.props.canEdit} />
          {this.getBar()}
          {this.getAlertBar()}
          <div className="wethod-sidebar__body">
            {this.props.body}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  alert: PropTypes.element,
  error: PropTypes.element,
  /**
   * String or element to be shown as sidebar title in the header
   * @see SidebarHeader
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node, PropTypes.element]),

  hasUnsavedChanges: PropTypes.bool,

  /**
   * Boolean flag, used to get feedback when there's a pending saving
   */
  isSaving: PropTypes.bool,

  /**
   * Boolean flag, safe to save
   */
  canSave: PropTypes.bool,

  /**
   * Boolean flag, permission to edit
   * @see SidebarHeader
   */
  canEdit: PropTypes.bool,

  /**
   * Children component to be displayed in the sidebar's body
   */
  body: PropTypes.element.isRequired,

  /**
   * Children component to be displayed in the sidebar's action bar
   */
  actions: PropTypes.element,

  /**
   * The function associated with the click on cancel button
   * @see SidebarHeader
   */
  onCancel: PropTypes.func,

  /**
   * The function associated with the click on save button
   * @see SidebarHeader
   */
  onSave: PropTypes.func,

  /**
   * The function associated with the click on close button
   * @see SidebarHeader
   */
  onClose: PropTypes.func,

  /**
   * The function associated with the click on the edit button
   * @see SidebarHeader
   */
  onEdit: PropTypes.func,
};

module.exports = Sidebar;
