const { connect } = require('react-redux');
const Component = require('../components/ProjectStepNoMembers.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  project: state.project,
  visibleStep: state.step,
});

const mapDispatchToProps = (dispatch) => ({
  save: () => dispatch(actions.showSaveModal()),
  exit: () => dispatch(actions.showUnsavedChangesModal()),
  showMissingVoteModal: () => dispatch(actions.showMissingVoteModal('project')),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
