/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */
const React = require('react');

module.exports = class FavouriteProject extends React.Component {
  handleFavouriteDelete() {
    this.props.deleteFavourite(this.props.project.id, this.props.type);
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getIcon(section) {
    switch (section) {
      case 'budget':
        return 'wethod-icon-budget wethod-icon-budget--black';
      case 'report':
        return 'wethod-icon-project-report wethod-icon-project-report--black';
      case 'canvas':
        return 'wethod-icon-canvas wethod-icon-canvas--black';
      default:
        return '';
    }
  }

  getLink(section, projectId) {
    switch (section) {
      case 'budget':
        return `#pipeline/budget/${projectId}`;
      case 'report':
        return `#project/${projectId}/report`;
      case 'canvas':
        return `#project/${projectId}/canvas`;
      default:
        return '';
    }
  }

  getActions() {
    return this.props.favorites.map((favorite) => (
      <a
        key={favorite.id}
        className="wethod-icon-button wethod-icon-button--no-border"
        href={this.getLink(favorite.section, favorite.resource)}>
        <div className={`wethod-icon ${this.getIcon(favorite.section)}`} />
      </a>
    ));
  }

  render() {
    return (
      <div className="desk-widget__item desk-widget-pinboard__item desk-widget-pinboard__item--project">
        <span className="desk-widget-pinboard__color-bar" style={{ backgroundColor: this.props.job_order_category.color }} />
        <div className="desk-widget__multirow-info desk-widget-pinboard__info">
          <span className="desk-widget__text">{this.getUnescapedValue(this.props.project.name)}</span>
          <span className="desk-widget__multirow-info--secondary desk-widget__text">
            {this.getUnescapedValue(this.props.client.name)}
          </span>
        </div>
        <div className="desk-widget-pinboard__actions">
          {this.getActions()}
          <span className="desk-widget-pinboard__vertical-bar" />
          <button type="button"
            className="wethod-icon-button wethod-icon-button--no-border desk-widget-pinboard__actions--bookmark"
            onClick={this.handleFavouriteDelete.bind(this)}>
            <div className="wethod-icon wethod-icon-bookmark-full wethod-icon-bookmark-full--black" />
          </button>
        </div>
      </div>
    );
  }
};
