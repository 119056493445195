const constants = require('./constants');

class PricelistReducer {
  constructor(state) {
    this.state = {
      ...state,
      showSidebar: false,
      focusedItem: null,
      editMode: false,
      unsavedChanges: [],
      waitingFor: [],
      available: 'true',
      modalToShow: null,
      modalData: null,
      pageOffset: 0,
      pageLimit: 0,
      hasMorePages: true,
      pricelists: [],
      levels: [],
      search: '',
      sort: 'asc',
      order: 'name',
      refreshTable: false,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.REFRESH_PRICELISTS_LIST: {
        return {
          ...state,
          refreshTable: true,
        };
      }
      case constants.GET_PRICELISTS_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          search: action.search,
          order: action.order,
          sort: action.sort,
          available: action.available,
          pricelists: action.offset ? state.pricelists : [],
          waitingFor: state.waitingFor.concat('get-pricelists'),
          refreshTable: false,
        };
      }
      case constants.GET_PRICELISTS_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.pricelists.length >= state.pageLimit,
          pricelists: state.pricelists.concat(action.pricelists),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-pricelists'),
        };
      }
      case constants.GET_LEVELS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-levels'),
        };
      }
      case constants.GET_LEVELS_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-levels'),
          levels: action.levels.map((level) => ({
            ...level,
            price: 0,
          })),
        };
      }
      case constants.CREATE_PRICELIST_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-pricelist'),
        };
      }
      case constants.CREATE_PRICELIST_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-pricelist'),
          pricelists: state.pricelists.concat(action.pricelist),
        };
      }
      case constants.UPDATE_PRICELIST_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-pricelist'),
        };
      }
      case constants.UPDATE_PRICELIST_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-pricelist'),
          pricelists: state.pricelists.map((pricelist) => {
            if (pricelist.id === action.pricelist.id) {
              return action.pricelist;
            }

            return pricelist;
          }),
        };
      }
      case constants.AVAILABILITY_CHANGE: {
        return {
          ...state,
          available: action.key,
        };
      }
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          focusedItem: action.id,
          showSidebar: true,
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          focusedItem: null,
          showSidebar: false,
          unsavedChanges: [],
        };
      }
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-pricelist'),
        };
      case constants.SHOW_MODAL:
        return {
          ...state,
          modalToShow: action.key,
          modalData: action.data,
        };
      case constants.SHOW_API_ERROR_MODAL:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-pricelist'),
          modalToShow: action.key,
          modalData: action.data,
        };
      case constants.CHANGE_SIDEBAR_EDIT_MODE:
        return {
          ...state,
          editMode: action.editMode,
        };
      case constants.CHANGE_UNSAVED_CHANGES:
        return {
          ...state,
          unsavedChanges: action.unsavedChanges,
        };
      default:
        return state;
    }
  }
}

module.exports = PricelistReducer;
