/* eslint-disable no-shadow,no-use-before-define */
const constants = require('./constants');
const RevenuesModel = require('./models/Revenues');
const ProjectsModel = require('./models/Projects');
const TrendsModel = require('./models/Trends');
const RevenuesTargetsModel = require('./models/RevenuesTargets');

Wethod.module('ReactBind', () => {
  const getCompaniesSuccess = (companies) => ({
    type: constants.GET_COMPANIES_SUCCESS,
    companies,
  });

  const getCompaniesRequest = () => ({
    type: constants.GET_COMPANIES_REQUEST,
  });

  module.exports.getCompanies = () => (dispatch) => {
    dispatch(getCompaniesRequest());
    Wethod.request('get:finance:companies')
      .then((response) => {
        dispatch(getCompaniesSuccess(response.toJSON()));
      });
  };

  const updateCompanySuccess = (company) => ({
    type: constants.UPDATE_COMPANY_SUCCESS,
    company,
  });

  const updateCompanyRequest = () => ({
    type: constants.UPDATE_COMPANY_REQUEST,
  });

  module.exports.updateCompany = (company) => (dispatch) => {
    dispatch(updateCompanyRequest());
    const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
      ...company,
      group: company.group ? company.group.id : null,
    });
    Wethod.request('save:finance:client', model)
      .then((response) => {
        dispatch(updateCompanySuccess(response.attributes));
      });
  };

  module.exports.updateTimeFilter = (filter) => ({
    type: constants.UPDATE_TIME_FILTER,
    filter,
  });

  const getRevenuesSuccess = (revenues) => ({
    type: constants.GET_REVENUES_SUCCESS,
    revenues,
  });

  const getRevenuesRequest = () => ({
    type: constants.GET_REVENUES_REQUEST,
  });

  module.exports.getRevenues = (clientId) => (dispatch) => {
    dispatch(getRevenuesRequest());
    const collection = new RevenuesModel({ id: clientId });

    $.when(collection.getModel()).done((revenues) => dispatch(getRevenuesSuccess(revenues)));
  };

  const getProjectsSuccess = (projects) => ({
    type: constants.GET_PROJECTS_SUCCESS,
    projects,
  });

  const getProjectsRequest = () => ({
    type: constants.GET_PROJECTS_REQUEST,
  });

  module.exports.getProjects = (clientId) => (dispatch) => {
    dispatch(getProjectsRequest());
    const collection = new ProjectsModel({ id: clientId });

    $.when(collection.getModel()).done((projects) => dispatch(getProjectsSuccess(projects)));
  };

  const getTrendsSuccess = (trends) => ({
    type: constants.GET_TRENDS_SUCCESS,
    trends,
  });

  const getTrendsRequest = () => ({
    type: constants.GET_TRENDS_REQUEST,
  });

  module.exports.getTrends = (clientId) => (dispatch) => {
    dispatch(getTrendsRequest());
    const model = new TrendsModel({ id: clientId });

    $.when(model.getModel()).done((trends) => dispatch(getTrendsSuccess(trends)));
  };

  const getRevenuesTargetsSuccess = (targets) => ({
    type: constants.GET_REVENUES_TARGETS_SUCCESS,
    targets,
  });

  const getRevenuesTargetsRequest = () => ({
    type: constants.GET_REVENUES_TARGETS_REQUEST,
  });

  module.exports.getRevenuesTargets = (clientId) => (dispatch) => {
    dispatch(getRevenuesTargetsRequest());
    const model = new RevenuesTargetsModel({ id: clientId });

    $.when(model.getModel()).done((targets) => dispatch(getRevenuesTargetsSuccess(targets)));
  };

  const deleteCompanySuccess = () => {
    Wethod.navigate('/finance/client/companies', { trigger: true });
  };

  const deleteCompanyRequest = () => ({
    type: constants.DELETE_COMPANY_REQUEST,
  });

  module.exports.deleteCompany = (company) => (dispatch) => {
    dispatch(deleteCompanyRequest());
    if (company.id) {
      const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
        ...company,
      });
      Wethod.request('delete:finance:client', model)
        .then((response) => {
          if (response.code === 409) {
            const { projects } = response.data;
            const { customers } = response.data;

            Wethod.request('get:finance:companies', model)
              .then((response) => {
                const companies = response.toJSON();
                dispatch(showDeleteCompanyFailureModal(projects, customers, companies));
              });
          } else {
            deleteCompanySuccess();
          }
        });
    } else {
      deleteCompanySuccess();
    }
  };

  const replaceCompanyRequest = () => ({
    type: constants.REPLACE_COMPANY_REQUEST,
  });

  module.exports.replaceCompany = (from, toId) => (dispatch) => {
    dispatch(replaceCompanyRequest());
    const options = {
      fromId: from.id,
      toId,
    };
    const switchProjectsClientRequest = Wethod.request('switch:finance:client:projects', options);
    const switchContactsClientRequest = Wethod.request('switch:finance:client:contacts', options);
    $.when(switchProjectsClientRequest, switchContactsClientRequest)
      .done(() => {
        const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
          ...from,
        });
        Wethod.request('delete:finance:client', model)
          .then((response) => {
            if (response.code === 409) {
              dispatch(showDeleteCompanyFailureModal());
            } else {
              deleteCompanySuccess();
            }
          });
      });
  };

  const updateContactSuccess = (contact) => ({
    type: constants.UPDATE_CONTACT_SUCCESS,
    contact,
  });

  const updateContactRequest = (contact) => ({
    type: constants.UPDATE_CONTACT_REQUEST,
    contact,
  });

  module.exports.updateContact = (contact) => (dispatch) => {
    dispatch(updateContactRequest(contact));
    if (contact.id) {
      const contactModel = new Wethod.FinanceApp.Client.ClientModel({
        ...contact,
        client: contact.client.id,
      });
      Wethod.request('save:finance:client', contactModel)
        .then(() => {
          dispatch(updateContactSuccess(contact));
        });
    } else {
      dispatch(updateContactSuccess(contact));
    }
  };

  module.exports.addContact = () => ({
    type: constants.ADD_CONTACT_REQUEST,
  });

  const saveContactSuccess = (contact) => ({
    type: constants.SAVE_CONTACT_SUCCESS,
    contact,
  });

  const saveContactRequest = () => ({
    type: constants.SAVE_CONTACT_REQUEST,
  });

  module.exports.saveContact = (contact) => (dispatch) => {
    dispatch(saveContactRequest());
    const contactModel = new Wethod.FinanceApp.Client.ClientModel({
      ...contact,
      client: contact.client.id,
    });
    Wethod.request('save:finance:client', contactModel)
      .then((response) => {
        // We need to add the phone in the specific request after we know the id of the client we
        // have created
        if (contact.phones && contact.phones.number) {
          dispatch(addPersonPhone(response.attributes.id, contact.phones));
        }
        const newContact = response.toJSON();
        newContact.client = contact.client;

        dispatch(saveContactSuccess(newContact));
      });
  };

  const deleteContactSuccess = (contactId) => ({
    type: constants.DELETE_CONTACT_SUCCESS,
    contactId,
  });

  const deleteContactRequest = () => ({
    type: constants.DELETE_CONTACT_REQUEST,
  });

  module.exports.deleteContact = (contact) => (dispatch) => {
    dispatch(deleteContactRequest());
    const contactModel = new Wethod.FinanceApp.Client.ClientModel({
      ...contact,
    });
    Wethod.request('delete:finance:client', contactModel)
      .then(() => {
        dispatch(deleteContactSuccess(contact.id));
        dispatch(closeModal());
      });
  };

  const saveGroupSuccess = (group) => ({
    type: constants.GROUP_SAVE_SUCCESS,
    group,
  });

  const saveGroupRequest = () => ({
    type: constants.GROUP_SAVE_REQUEST,
  });

  module.exports.saveGroup = (group) => (dispatch) => {
    dispatch(saveGroupRequest());
    const model = new Wethod.FinanceApp.Client.CompanyGroupModel(group);
    Wethod.request('save:finance:company:group', model)
      .then((response) => {
        dispatch(dispatch(saveGroupSuccess(response.attributes)));
      });
  };

  const saveNewCompanySuccess = (company) => ({
    type: constants.NEW_COMPANY_SAVE_SUCCESS,
    company,
  });

  const saveNewCompanyRequest = () => ({
    type: constants.NEW_COMPANY_SAVE_REQUEST,
  });

  module.exports.saveNewCompany = (company) => (dispatch) => {
    dispatch(saveNewCompanyRequest());
    const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
      ...company,
      corporate_name: company.name,
      group: company.group ? company.group.id : null,
    });
    Wethod.request('save:finance:client', model)
      .then((response) => {
        dispatch(saveNewCompanySuccess(response.attributes));
      });
  };

  const updatePersonPhoneSuccess = (clientId, phone) => ({
    type: constants.UPDATE_CONTACT_PHONE_SUCCESS,
    clientId,
    phones: phone,
  });

  const updatePersonPhoneRequest = () => ({
    type: constants.UPDATE_CONTACT_PHONE_REQUEST,
  });

  module.exports.updatePersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(updatePersonPhoneRequest());
    const model = new Wethod.FinanceApp.Client.ClientPersonPhoneModel(phone);
    Wethod.request('update:finance:person:phone', model)
      .then(() => {
        dispatch(updatePersonPhoneSuccess(clientId, phone));
      });
  };

  const addPersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(updatePersonPhoneRequest());
    Wethod.request('add:finance:person:phone', {
      ...phone,
      customer_id: clientId,
    }).then((response) => {
      dispatch(updatePersonPhoneSuccess(clientId, response.data));
    });
  };

  module.exports.addPersonPhone = addPersonPhone;

  module.exports.deletePersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(updatePersonPhoneRequest());
    Wethod.request('delete:finance:person:phone', phone)
      .then(() => {
        dispatch(updatePersonPhoneSuccess(clientId, null));
      });
  };

  module.exports.openCompanySidebar = () => ({
    type: constants.SHOW_COMPANY_SIDEBAR,
  });

  module.exports.closeCompanySidebar = () => ({
    type: constants.HIDE_COMPANY_SIDEBAR,
  });

  module.exports.openContactSidebar = (contactId) => ({
    type: constants.SHOW_CONTACT_SIDEBAR,
    contactId,
  });

  module.exports.closeContactSidebar = () => ({
    type: constants.HIDE_CONTACT_SIDEBAR,
  });

  module.exports.showDeleteContactModal = () => ({
    type: constants.SHOW_DELETE_CONTACT_MODAL,
  });

  module.exports.showDeleteCompanyModal = () => ({
    type: constants.SHOW_DELETE_COMPANY_MODAL,
  });

  const showDeleteCompanyFailureModal = (projects, customers, companies) => ({
    type: constants.SHOW_DELETE_COMPANY_FAILURE_MODAL,
    projects,
    customers,
    companies,
  });

  const closeModal = () => ({
    type: constants.CLOSE_MODAL,
  });

  module.exports.closeModal = closeModal;
});
