/* eslint-disable react/prop-types,jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class SectionLogout extends React.Component {
  onClick(e) {
    e.preventDefault();
    Wethod.trigger('user:logout');
    this.props.onClick();
  }

  render() {
    return (
      <li className="app-nav__section">
        <a href="#"
          onClick={this.onClick.bind(this)}>
          Logout
        </a>
      </li>
    );
  }
};
