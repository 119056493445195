const React = require('react');
const PrimaryInfo = require('../HeaderBox/HeaderBoxPrimaryInfo.react');

const PricePrimarySection = ({
  label, children,
}) => (
  <PrimaryInfo>
    <div className="wethod-budget-header__price-primary-section">
      <h2 className="wethod-budget-header__price-primary-section-title">{label}</h2>
      {children}
    </div>
  </PrimaryInfo>
);

module.exports = PricePrimarySection;
