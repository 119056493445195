'use strict';

Wethod.module('PricelistApp', function (PricelistApp, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#appTemplate',
    regions: {
      headerSection: '[data-region="sectionHeader"]',
      body: '[data-region="body"]',
    },
  });
});
