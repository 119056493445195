const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../../../inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../inputs/Select/SelectItem.react');

const DateRangeModifierSelect = ({ id, value, name, onChange, label }) => (
  <Select value={value}
    name={name}
    onChange={onChange}
    id={id}
    label={label}>
    <SelectItem value="on">On</SelectItem>
    <SelectItem value="before">Before (Incl.)</SelectItem>
    <SelectItem value="after">After (Incl.)</SelectItem>
  </Select>
);

DateRangeModifierSelect.defaultProps = {
  label: '',
  value: '',
  onChange: null,
};

DateRangeModifierSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  label: PropTypes.string,
};

module.exports = DateRangeModifierSelect;
