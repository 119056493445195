/* eslint-disable consistent-return */
const React = require('react');
const Note = require('../../../../../common/react/inputs/TextArea/BasicTextArea/BasicTextArea.react');
const Select2 = require('../../../../../common/react/Select2/Select2.react');

module.exports = class ShareModalBody extends React.Component {
  handleFilter(name, keyword) {
    this.props.onFilter(keyword);
  }

  onNotesChange(e) {
    this.props.onNotesChange(e.target.name, e.target.value);
  }

  getItems() {
    if (!this.props.pm && !this.props.isWaiting) {
      this.props.onFilter();
    } else {
      return this.props.pm;
    }
  }

  render() {
    return (
      <div className="basket-modal__body">
        <p>
          Who do you want to share
          {' '}
          <span className="bold">{this.props.opportunity.name}</span>
          {' '}
          with?
          <br />
          Type in your colleague’s name and select “Share”.
          <br />
          We will take care of the rest.
        </p>
        <Note name="note" placeholder="Note" onChange={this.onNotesChange.bind(this)} />
        <Select2
          name="pm"
          items={this.getItems()}
          onFilter={this.handleFilter.bind(this)}
          onChange={this.props.updatePerson}
          placeholder="Colleague's name" />
      </div>
    );
  }
};
