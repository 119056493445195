const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');

module.exports = class ProjectInfo extends React.Component {
  static getFormatted(value) {
    if (value) return numeral(value).format('0,0');
    return 0;
  }

  shouldComponentUpdate() {
    return false;
  }

  getPipelineProjectUrl() {
    return `#pipeline/projects?id=${this.props.id}`;
  }

  getProbabilityLabel() {
    if (this.props.probability !== null) {
      return <span className="production-plan__probability-label">{`${this.props.probability} %`}</span>;
    }
    return null;
  }

  getTimeBasedLabel() {
    if (this.isTimeBased()) {
      return (
        <span className="production-plan-project__info-label uppercase">
          Time based
        </span>
      );
    }

    return null;
  }

  getContentStyle() {
    let style = 'production-plan-project__info-content';
    if (this.isTimeBased()) {
      style += ' production-plan-project__info-content--disabled';
    }
    return style;
  }

  isTimeBased() {
    return this.props.isTimeBased;
  }

  render() {
    return (
      <TableCell className="production-plan-project__info">
        {this.getProbabilityLabel()}
        <a className="production-plan-project__info-link" href={this.getPipelineProjectUrl()}>
          <span className={this.getContentStyle()}>
            <span className="production-plan-project__info-name">
              <span className="production-plan-project__info-name-content">{_.unescape(this.props.name)}</span>
            </span>
            <span className="production-plan-project__info-client">
              <span className="production-plan-project__info-client-content">{_.unescape(this.props.jobOrder)}</span>
            </span>
            <span className="production-plan-project__info-client">
              <span className="production-plan-project__info-client-content">{_.unescape(this.props.client)}</span>
            </span>
            <span className="production-plan-project__info-pm">
              <span
                className="production-plan-project__info-client-content">
                {`${ProjectInfo.getFormatted(this.props.totalProductionValue)} on ${ProjectInfo.getFormatted(this.props.finalNet)}`}
              </span>
            </span>
            <span className="production-plan-project__info-pm">
              <span className="production-plan-project__info-pm-content">{_.unescape(this.props.pm.name)}</span>
            </span>
            {this.getTimeBasedLabel()}
          </span>
        </a>
      </TableCell>
    );
  }
};
