const React = require('react');

const AllForm = ({ granularity, children }) => {
  if (granularity === 'all') {
    return <div className="planning-people-repeat-form--all">{children}</div>;
  }
  return null;
};

module.exports = AllForm;
