const React = require('react');
const Step = require('./Step.react');
const StepHeader = require('./StepHeader.react');
const StepContent = require('./StepContent.react');
const StepFooter = require('../containers/StepFooter');
const ProjectRow = require('../containers/ProjectRow');

module.exports = class ProjectStep extends React.Component {
  onCloseClick() {
    this.props.exit();
  }

  onNextClick() {
    if (this.props.incomplete) {
      this.props.showMissingVoteModal();
    } else {
      this.props.goToNextStep();
    }
  }

  render() {
    return (
      <Step style={this.props.style}>
        <StepHeader
          title="Based on given criteria, how would you rate this project?"
          legendItems={['1 - 6 Not good', '7 - 8 Neutral', '9 - 10 Very good']} />
        <StepContent>
          <ProjectRow {...this.props.project} complete={!this.props.incomplete} />
        </StepContent>
        <StepFooter currentStep={1}>
          <button type="button"
            className="wethod-button project-review__step-footer-action"
            onClick={this.onCloseClick.bind(this)}>
            Close
          </button>
          <button type="button"
            className="wethod-button project-review__step-footer-action"
            onClick={this.onNextClick.bind(this)}>
            Next
          </button>
        </StepFooter>
      </Step>
    );
  }
};
