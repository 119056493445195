const React = require('react');
const Select = require('../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../../../../common/react/inputs/Select/SelectItem.react');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');

const BuSelect = ({ value, onChange, name, readOnly }) => {
  const businessUnitService = new BusinessUnitService();

  const availableValues = businessUnitService.getForCompany();

  function handleChange(e) {
    onChange(name, BusinessUnitService.formatForSaving(e.target.value));
  }

  const getOptions = () => availableValues.map((bu) => (
    <SelectItem key={bu.id} value={bu.id}>{bu.name}</SelectItem>
  ));

  return (
    <Select readOnly={readOnly}
      value={String(value)}
      id="sidebar-bu"
      label="Business unit"
      onChange={handleChange}
      name={name}>
      {getOptions()}
    </Select>
  );
};

module.exports = BuSelect;
