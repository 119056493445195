const { connect } = require('react-redux');
const Component = require('../../components/timesheet/TimesheetTimeLimit.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  timeLimit: state.timesheet.timeLimit,
  waitingForSetting: state.waitingFor.some((key) => key === 'set-time-limit'),
  waitingForUpdating: state.waitingFor.some((key) => key === 'update-time-limit'),
});

const mapDispatchToProps = (dispatch) => ({
  onTimeLimitEnabledChange: (enabled) => dispatch(actions.setTimesheetTimeLimit(enabled)),
  onTimeLimitBufferChange: (buffer) => dispatch(actions.updateTimesheetTimeLimit(buffer)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
