const React = require('react');
const PropTypes = require('prop-types');
const RevealTransition = require('../Transition/RevealTransition/RevealTransition.react');

require('./style.scss');

const Backdrop = ({ children, className = '' }) => (
  <RevealTransition triggered animateFirstMount>
    <div className={`wethod-backdrop ${className}`}>
      {children}
    </div>
  </RevealTransition>
);

Backdrop.defaultProps = {
  children: null,
  className: '',
};

Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

module.exports = Backdrop;
