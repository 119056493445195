const React = require('react');
const PropTypes = require('prop-types');
const Folder = require('./Folder.react');
const File = require('./File.react');
const FileModel = require('../models/File');

const FileItem = ({ file, onDelete, onDownload, editable, onFolderClick, scrollbarWidth }) => {
  if (file.isFolder()) {
    return <Folder folder={file} scrollbarWidth={scrollbarWidth} onClick={onFolderClick} />;
  }
  return <File file={file} editable={editable} onDelete={onDelete} onDownload={onDownload} />;
};

FileItem.defaultProps = {
  onFolderClick: null,
  onDelete: null,
  onDownload: null,
};

FileItem.propTypes = {
  file: PropTypes.instanceOf(FileModel).isRequired,
  editable: PropTypes.bool.isRequired,
  scrollbarWidth: PropTypes.number.isRequired,
  /**
   * The function to call when this folder is clicked.
   *
   * @param folder {FileModel}
   */
  onFolderClick: PropTypes.func,
  /**
   * The function to call to delete a file.
   *
   * @param file {FileModel}
   */
  onDelete: PropTypes.func,
  /**
   * The function to call to download a file.
   *
   * @param file {FileModel}
   */
  onDownload: PropTypes.func,
};

module.exports = FileItem;
