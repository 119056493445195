const { connect } = require('react-redux');
const Component = require('../components/ZoomSwitcher.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  current: state.zoom,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (zoom) => dispatch(actions.changeZoom(zoom)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
