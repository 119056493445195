const { connect } = require('react-redux');
const SecondaryInfoEditable = require('../../components/SecondaryInfo/SecondaryInfoEditable.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  person: state.person,
  isSaving: state.isSaving,
  phone: state.phones[0],
});

const mapDispatchToProps = (dispatch) => ({
  save: (person) => dispatch(actions.updatePerson(person)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SecondaryInfoEditable);
