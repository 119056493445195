const React = require('react');
const Tooltip = require('../../../../../common/react/tooltip/Tooltip.react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');

const TooltipItem = ({ id, label, children }) => {
  const showTooltip = label != null && label.toString().trim() !== '';

  return (
    <div className="pipeline__stage-item">
      <ShowIf condition={showTooltip}>
        <Tooltip id={id} content={label} />
      </ShowIf>
      {children}
    </div>
  );
};

module.exports = TooltipItem;
