/* eslint-disable backbone/no-model-attributes */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.boardId = options.boardId;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/canvas/board/${this.boardId}/attribute`;
  },
  saveModel() {
    const defer = $.Deferred();

    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  deleteModel() {
    const defer = $.Deferred();

    $.when(this.erase()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  saveName(id) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/${id}`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveSummary(id) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/${id}/summary`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveSort(map) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/sort`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(map),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveStatusSort(attributeId, map) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/${attributeId}/status/sort`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(map),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  savePivot(id) {
    const defer = $.Deferred();
    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: `${this.urlRoot()}/${id}/pivot`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveActivityAttribute(id) {
    const defer = $.Deferred();
    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: `${this.urlRoot()}/${id}/activity`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
