const React = require('react');
const Loading = require('./Loading.react');

/**
 * A 1x1 widget which body can be clicked.
 * - title
 * - isEmpty
 * - empty: component to show if isEmpty === true
 * - children: component to show in content
 * - onClick: function to call on body click
 *
 * @type {TextArea}
 */
module.exports = class TextArea extends React.Component {
  getBody() {
    if (this.props.waiting) {
      return <Loading />;
    } if (this.props.isEmpty) {
      return this.props.empty;
    }
    return this.props.children;
  }

  render() {
    return (
      <div className="wethod-widget project-canvas__widget project-canvas__widget-text-area">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">{this.props.title}</span>
        </div>
        <div className="wethod-widget__body project-canvas__widget-text-area-body-wrapper" onClick={this.props.onClick}>{this.getBody()}</div>
      </div>
    );
  }
};
