const HTTPService = require('../../../../../services/HTTPService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.urlRoot = `${APIURL}/review/${options.id}`;
  },
  load: HTTPService.load,
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      if (model.get('code') && model.get('code') !== 200) {
        defer.reject(model.attributes);
      } else {
        defer.resolve(model.attributes.data);
      }
    });

    return defer.promise();
  },
  save(review) {
    const defer = $.Deferred();
    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: this.urlRoot,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(review),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
