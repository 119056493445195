const React = require('react');
const View = require('./CardBodyView.react');
const Edit = require('./CardBodyEdit.react');

module.exports = ({
  canEdit, compact, projectId, planned, deltaInvoiced,
  invoiced, invoices, currencyCode, ...rest
}) => {
  if (canEdit) {
    return (
      <Edit planned={planned}
        invoiced={invoiced}
        deltaInvoiced={deltaInvoiced}
        invoices={invoices}
        compact={compact}
        currencyCode={currencyCode}
        {...rest} />
    );
  }
  return (
    <View planned={planned}
      invoiced={invoiced}
      deltaInvoiced={deltaInvoiced}
      invoices={invoices}
      compact={compact}
      currencyCode={currencyCode}
      {...rest} />
  );
};
