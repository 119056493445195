/* eslint-disable react/prop-types */
const React = require('react');
const BookmarkModel = require('../models/BookmarkModel');

module.exports = class Bookmark extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: null,
      action: 'loading', // 'loading' | 'updating' | null
    };
  }

  componentDidMount() {
    const model = new BookmarkModel();
    $.when(model.getFavorite(this.props.fragment))
      .done((favorite) => this.setState({
        favorite,
        action: null,
      }))
      .fail(() => this.setState({ action: null }));
  }

  onChange() {
    if (this.state.action !== 'updating') {
      const model = new BookmarkModel();
      const { favorite } = this.state;

      this.setState({ action: 'updating' });

      if (!favorite) {
        $.when(model.saveFavorite(this.props.fragment))
          .done((nextFavorite) => this.setState({
            favorite: nextFavorite,
            action: null,
          }));
      } else {
        $.when(model.removeFavorite(favorite.id))
          .done(() => this.setState({
            favorite: null,
            action: null,
          }));
      }
    }
  }

  render() {
    if (this.state.action === 'loading') {
      return null;
    }
    return (
      <span className="section-bookmark">
        <label className="section-bookmark__label"
          htmlFor="pinboard-bookmark">
          <input type="checkbox"
            id="pinboard-bookmark"
            checked={this.state.favorite !== null}
            onChange={this.onChange.bind(this)} />
          <span className="section-bookmark__checkmark" />
          <span className="section-bookmark__label-text-container">
            <span className="section-bookmark__label-text section-bookmark__label-text--hint-save">Save section to Pinboard</span>
            <span className="section-bookmark__label-text section-bookmark__label-text--hint-remove">Remove section from Pinboard</span>
          </span>
        </label>
      </span>
    );
  }
};
