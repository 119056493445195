/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions */
const React = require('react');

module.exports = class SingleValueSlider extends React.Component {
  handleTodayClick() {
    if (this.props.onTodayClicked) this.props.onTodayClicked();
  }

  getClassName() {
    if (this.props.className !== undefined && this.props.className !== null) {
      return `planning-navigation ${this.props.className}`;
    }
    return 'planning-navigation';
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="navigation__prev"
          onClick={this.props.onPrevClicked}>
          <div className="wethod-icon-back wethod-icon-back--black" />
        </div>
        <div className="navigation__today"
          onClick={this.handleTodayClick.bind(this)}>
          {this.props.value}
        </div>
        <div className="navigation__next"
          onClick={this.props.onNextClicked}>
          <div className="wethod-icon-forward wethod-icon-forward--black" />
        </div>
      </div>
    );
  }
};
