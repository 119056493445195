const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const Trend = require('../containers/ChargeabilityTrend');

const LevelRow = ({ level, selected, onClick }) => {
  const isAbove = (value, target) => target && value && (value - target > 0);

  const isBelow = (value, target) => target && value && (target - value > 0);

  function handleRowClick() {
    onClick(level);
  }

  function getNonEmptyFormattedValue(value) {
    return (value || value === 0) ? formatNumber(value, 1, false) : '-';
  }

  function getAvgStyle(avg, target) {
    let style = 'chargeability__column-avg chargeability__value';
    if (isAbove(avg, target)) style += ' chargeability__value--positive';
    if (isBelow(avg, target)) style += ' chargeability__value--negative';

    return style;
  }

  function getLevelStyle(id) {
    let style = 'chargeability__column-level';
    if (id != null) style += ' uppercase';

    return style;
  }

  function getRowStyle() {
    let style = 'chargeability__level-row';
    if (selected) style += ' chargeability__level-row--selected';

    return style;
  }

  return (
    <TableRow className={getRowStyle()} onClick={handleRowClick}>
      <TableCell name="level" className={getLevelStyle(level.id)}>{level.short_name}</TableCell>
      <TableCell name="trend" className="chargeability__column-trend">
        <Trend target={level.target} data={level.data} />
      </TableCell>
      <TableCell name="target" className="chargeability__column-target">
        {getNonEmptyFormattedValue(level.target)}
      </TableCell>
      <TableCell name="avg" className={getAvgStyle(level.avg_chargeability, level.target)}>
        {getNonEmptyFormattedValue(level.avg_chargeability)}
      </TableCell>
    </TableRow>
  );
};

module.exports = LevelRow;
