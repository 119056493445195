/* eslint-disable react/prop-types,consistent-return,react/jsx-no-bind,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
const React = require('react');

/**
 * A Bubble is a container that looks like a comics' speech bubble.
 * Pass a 'position' (top | bottom) prop to show the bubble under or above its container.
 *  __________       _____/\____
 * |          |     |          |
 * |____  ____|     |__________|
 *      \/
 */
class Bubble extends React.Component {
  constructor(props) {
    super(props);

    this.getStyle = this.getStyle.bind(this);
    this.getProng = this.getProng.bind(this);
  }

  getStyle() {
    if (this.props.position === 'bottom') {
      return { top: 30 };
    }
    return { bottom: 30 };
  }

  getContentStyle() {
    if (this.props.position === 'bottom') {
      return { top: 0 };
    }
    return { bottom: 0 };
  }

  getProng(type) {
    if (type === this.props.position) {
      return `bubble__prong--${type}`;
    }
  }

  render() {
    if (this.props.show) {
      return (
        <div className="bubble"
          style={this.getStyle()}
          onClick={(e) => e.stopPropagation()}>
          <div className={this.getProng('top')} />
          <div className="bubble__content"
            style={this.getContentStyle()}>
            {this.props.content}
          </div>
          <div className={this.getProng('bottom')} />
        </div>
      );
    }
    return null;
  }
}

module.exports = Bubble;
