const request = require('./Request');

const BudgetTemplate = {
  getAll() {
    return request({
      method: 'get',
      url: '/budgettemplate/',
    });
  },
  createTemplate(budgetId, template) {
    return request({
      method: 'post',
      url: `/br/budget/${budgetId}/template`,
      data: template,
    });
  },
};

module.exports = BudgetTemplate;
