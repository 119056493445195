/* eslint-disable backbone/event-scope */

'use strict';

/* eslint-disable default-case */

Wethod.module('FinanceApp.Order', function (Order, Wethod, Backbone, Marionette, $, _) {
  var sync = function (method, model, options) {
    if (!options.crossDomain) {
      options.crossDomain = true;
    }

    if (!options.xhrFields) {
      options.xhrFields = { withCredentials: true };
    }

    _.each(this._requestPool, function (request) {
      if (request.readyState !== 4) {
        request.abort();
      }
    });

    if (method === 'read') {
      var xhr = proxiedSync(method, model, options);
      this._requestPool.push(xhr);
      return xhr;
    }
    return proxiedSync(method, model, options);
  };
  // order model
  this.OrderModel = Backbone.Model.extend({
    defaults: function () {
      return {
        id: null,
        id_project: null,
        id_area: null,
        id_supplier: null,
        note: null,
        status: 0,
        authorized: false,
        date: moment().format() || null,
        start: null, // use project start
        duration: 1, // todo use project duration
        code: null,
        value: null,
        budget_areas: [],
        area: {
          id: null,
          name: null,
        },
        supplier: {
          id: null,
          name: null,
        },
        change: null, // value's conversion obtained by using currency's rate
        exchange_rate: null, // manual set rate
        currency: null,
        rate: null, // rate coming from currency, if present
        currencies: [], // currencies used for external costs in the budget => available currencies
        // for the order
        project: {
          id: null,
          job_order: null,
          name: null,
          pm: {
            id: null,
          },
          account: {
            id: null,
          },
        },
      };
    },
    initialize: function (options) {
      this.options = options || {};
      // aggiorno il valore di id_project perchè la struttura di insert e
      // di update sono differenti
      var idProject = this.get('project').id;
      this.set('id_project', idProject);
      this.set('id_supplier', this.get('supplier').id);
      this.on('change:id_project', this.onChangeProject);
      this.on('change:change', this.onUpdateChange);
      this.on('change:currency', this.onCurrencyChange);
      this.on('change:exchange_rate', this.onChangeManualRate);
      this.updateChange();
    },
    urlRoot: APIURL + '/orderboard/',
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        case 400:
          return { message: data.message };
        default:
          return data;
      }
    },
    getFirstNonMasterCurrency: function () {
      var currencies = this.get('currencies');
      for (var i = 0; i < currencies.length; i++) {
        if (!currencies[i].is_master) {
          return currencies[i];
        }
      }
      return null;
    },
    getCurrencyByCode: function (code) {
      var currencies = this.get('currencies');
      if (code === null) {
        return this.getMasterCurrency();
      }
      for (var i = 0; i < currencies.length; i++) {
        if (currencies[i].code === code) {
          return currencies[i];
        }
      }
      return null;
    },
    hasCurrencies: function () {
      return this.get('currencies').length > 1 || (this.get('currencies').length > 0 && this.getMasterCurrency() === null);
    },
    getMasterCurrency: function () {
      var currencies = this.get('currencies');
      for (var i = 0; i < currencies.length; i++) {
        if (currencies[i].is_master) {
          return currencies[i];
        }
      }
      return null;
    },
    getExchangeRate: function () {
      var rate = this.get('exchange_rate');
      if (rate === null) {
        var code = this.get('currency');
        if (code !== null) {
          var currency = this.getCurrencyByCode(code);
          if (currency !== null) {
            return currency.rate;
          }
        }
        return 1;
      }
      return rate;
    },
    onChangeManualRate: function () {
      this.updateValue();
    },
    onCurrencyChange: function () {
      this.updateValue();
    },
    updateChange: function () {
      var rate = this.getExchangeRate();
      var change = this.get('value') * rate;
      this.set('change', change);
    },
    updateValue: function () {
      var rate = this.getExchangeRate();
      var value = this.get('change') / rate;
      this.set('value', value);
    },
    onUpdateChange: function () {
      this.updateValue();
    },
    onChangeProject: function () {
      this.get('area').id = null;
      this.get('area').name = null;
      this.set('id_area', null);
    },
    validate: function (attrs) {
      var errors = [];
      var error;
      _.each(attrs, function (val, key) {
        switch (key) {
          case 'id_project':
            if (_.isNull(attrs[key])) {
              error = 'The Project must be NOT empty';
              errors.push(error);
            }
            break;
          case 'id_supplier':
            if (_.isNull(attrs[key])) {
              error = 'The Supplier must be NOT empty';
              errors.push(error);
            }
            break;
          case 'date':
            if (_.isNull(attrs[key])) {
              error = 'The Date must be NOT empty';
              errors.push(error);
            }
            break;
          case 'start':
            if (_.isNull(attrs[key])) {
              error = 'The Start must be NOT empty';
              errors.push(error);
            }
            break;
          case 'duration':
            if (_.isNull(attrs[key])) {
              error = 'The Duration must be NOT empty';
              errors.push(error);
            }
            break;
          case 'value':
            if (_.isNull(attrs[key])) {
              error = 'The Order Value must be NOT empty';
              errors.push(error);
            }
            break;
        }
      });
      return (errors.length > 0) ? errors : false;
    },
  });
  // order collection
  this.OrdersCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    _limit: 30,
    _offset: 0,
    _search: null,
    _orderBy: 'date',
    _sort: 'desc',
    _statuses: [],
    _requestPool: [],
    url: function () {
      var url = APIURL + '/orderboard/';
      url += '?limit=' + this._limit + '&offset=' + this._offset;
      url = this._search ? url + '&search=' + this._search : url;
      url += '&orderBy=' + this._orderBy;
      url += '&sort=' + this._sort;
      url += '&statuses=' + this._statuses;
      this._offset += this._limit;
      return url;
    },
    sync: sync,
    getNextPage: function (search, offset, size, orderBy, sort, statuses) {
      if (search) {
        this._search = search.replace(/ /g, ','); // white space to comma
      }
      if (offset) {
        this._offset = offset;
      }
      if (size) {
        this._limit = size;
      }
      if (orderBy) {
        this._orderBy = orderBy;
      }
      if (sort) {
        this._sort = sort;
      }
      if (statuses) {
        this._statuses = statuses.join();
      }

      var defer = $.Deferred();

      this.fetch({
        success: function (models, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(models.toJSON());
          } else {
            defer.resolve([]);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        remove: false,
      });
      return defer.promise();
    },
    model: Order.OrderModel,
  });

  this.MoadalModel = Wethod.Utility.BaseModel.extend();

  // model supplier
  this.SupplierModel = Backbone.Model.extend({
    defaults: {
      name: null,
      email: null,
    },
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    urlRoot: APIURL + '/supplier/',
    validate: function (attrs) {
      var errors = [];
      var error;
      _.each(attrs, function (val, key) {
        switch (key) {
          case 'name':
            if (_.isNull(attrs[key])) {
              error = 'Please enter the supplier name';
              errors.push(error);
            }
            break;
          case 'email':
            var emailRegExp = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/;
            if (!_.isNull(attrs[key]) && !emailRegExp.test(attrs[key])) {
              error = 'Please enter a valid email address';
              errors.push(error);
            }
            break;
        }
      });
      return (errors.length > 0) ? errors : false;
    },
  });

  this.AreasCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/areaboard/project/' + this.options.projectId;
    },
  });

  this.CurrenciesCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/currency/for-project/' + this.options.projectId;
    },
  });

  // purchase code
  this.PurchaseCodeEditableModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/orderboard/is-purchase-code-editable',
  });

  var API = {
    getOrderList: function () {
      var collection = new Order.OrdersCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getProjectAreas: function (projectId) {
      var collection = new Order.AreasCollection({ projectId: projectId });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getProjectCurrencies: function (projectId) {
      var collection = new Order.CurrenciesCollection({ projectId: projectId });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveOrder: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(responseModel);
          } else {
            defer.resolve(response.message);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    sendOrder: function (model, contactId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/orderboard/' + model.get('id') + '/send',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify({
          contact_id: contactId,
        }),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    confirmOrder: function (model) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/orderboard/' + model.get('id') + '/authorize',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    deleteOrder: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (responseModel, response) {
          if (response.code === 200) {
            defer.resolve(200);
          } else {
            defer.resolve(response.message);
          }
        },
        error: function (responseModel, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    isPurchaseCodeEditable: function () {
      var model = new Order.PurchaseCodeEditableModel();
      var defer = $.Deferred();

      model.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });
      return defer.promise();
    },
  };

  // get the orders list
  Wethod.reqres.setHandler('get:finance:order:list', function () {
    return API.getOrderList();
  });
  Wethod.reqres.setHandler('get:finance:order:project:areas', function (projectId) {
    return API.getProjectAreas(projectId);
  });
  Wethod.reqres.setHandler('get:finance:order:project:currencies', function (projectId) {
    return API.getProjectCurrencies(projectId);
  });
  Wethod.reqres.setHandler('get:orders:coll', function (coll, search, offset, size, orderBy, sort, statuses) {
    coll = !coll ? new Order.OrdersCollection() : coll; // if no coll given init new one
    return coll.getNextPage(search, offset, size, orderBy, sort, statuses);
  });
  // save an order ( insert/update )
  Wethod.reqres.setHandler('save:finance:order', function (model) {
    return API.saveOrder(model);
  });
  // send an order
  Wethod.reqres.setHandler('send:finance:order', function (model, contactId) {
    return API.sendOrder(model, contactId);
  });
  // confirm an order
  Wethod.reqres.setHandler('confirm:finance:order', function (model) {
    return API.confirmOrder(model);
  });
  // delete an order
  Wethod.reqres.setHandler('delete:finance:order', function (model) {
    return API.deleteOrder(model);
  });
  // check if purchase code is editable
  Wethod.reqres.setHandler('get:finance:order:purchase-code-editable', function () {
    return API.isPurchaseCodeEditable();
  });
});
