const React = require('react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalBodyBlock = require('../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalFooter = require('../../../../../../common/react/Modal/ModalFooter.react');

/**
 * Modal that ask for confirmation when user is trying to create a pricelist.
 * @param {function} inClose - Invoked to close this modal
 * @param {boolean} isSaving - Tells if the given item is saving
 * @param {{}} data - Callback and callback args
 * @return {JSX.Element}
 * @constructor
 */
const ChangePricelistClientDefaultWarning = ({ data, onClose, isSaving }) => {
  function onConfirmClick() {
    data.callback(...data.callbackArgs);
  }
  const feedback = isSaving ? 'Saving...' : '';

  return (
    <Modal title="Set pricelist as default" onClose={onClose}>
      <div className="pricelists__modal-content">
        <ModalBody>
          <ModalBodyBlock>This client had already a default price list.
            Do you want to make this price list as default for this client?
            These changes will affect the rates of new budget versions.
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={feedback}>
          <ModalFooterAction onClick={onClose}>Cancel</ModalFooterAction>
          <ModalFooterAction onClick={onConfirmClick}
            disabled={isSaving}>Proceed
          </ModalFooterAction>
        </ModalFooter>
      </div>
    </Modal>
  );
};

module.exports = ChangePricelistClientDefaultWarning;
