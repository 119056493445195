/* eslint-disable class-methods-use-this,default-case,react/sort-comp,no-shadow,consistent-return,react/no-did-update-set-state,react/no-unused-state */
const React = require('react');

module.exports = class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      active: '#48A5F9',
      normal: '#48A5F9',
    };

    this.chartOptions = {
      chart: {
        backgroundColor: '#FCFCFC',
        style: {
          fontFamily: 'Rubik',
        },
      },
      colors: ['#48A5F9'],
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    };
  }

  /**
   * Selects all links so they get hidden.
   * @param links
   * @param selectedNode
   */
  hideNodesAndLinks(links, selectedNode) {
    Highcharts.each(links, (link) => {
      link.select(true, true);
      const { toNode } = link;
      if (toNode.id !== selectedNode.id) {
        toNode.selected = false;
      }
    });
  }

  /**
   * Deselects all links to make them visible.
   * @param links
   */
  showNodesAndLinks(links) {
    Highcharts.each(links, (link) => { // Deselect all nodes and links
      link.select(false, true);
    });
  }

  /**
   * Deselects all links in "from" position to make them visible.
   * @param links
   */
  showLinksFrom(links) {
    Highcharts.each(links, (link) => {
      const highlightFrom = (link) => {
        link.select(false, true);
        const { toNode } = link;
        for (let i = 0; i < toNode.linksFrom.length; i++) {
          highlightFrom(toNode.linksFrom[i]);
        }
      };
      highlightFrom(link);
    });
  }

  /**
   * Deselects all links in "to" position to make them visible.
   * @param links
   */
  showLinksTo(links) {
    Highcharts.each(links, (link) => {
      const highlightTo = (link) => {
        link.select(false, true);
        const { fromNode } = link;
        for (let i = 0; i < fromNode.linksTo.length; i++) {
          highlightTo(fromNode.linksTo[i]);
        }
      };
      highlightTo(link);
    });
  }

  getOptions() {
    const parentFunctions = {
      showProjectModal: this.props.showWorkingConnectionModal,
      hideNodesAndLinks: this.hideNodesAndLinks,
      showNodesAndLinks: this.showNodesAndLinks,
      showLinksFrom: this.showLinksFrom,
      showLinksTo: this.showLinksTo,
    };

    return {
      ...this.chartOptions,
      series: [{
        cursor: 'pointer',
        parentFunctions,
        nodeWidth: 152,
        name: '',
        type: 'sankey',
        data: this.props.data,
        linkOpacity: 0.2,
        states: {
          select: {
            color: '#FCFCFC',
          },
        },
        point: {
          events: {
            click() {
              if (this.isNode) { // Is node
                this.series.userOptions.parentFunctions.hideNodesAndLinks(this.series.data, this);
                if (!this.selected) { // It wasn't selected
                  this.series.userOptions.parentFunctions.showLinksFrom(this.linksFrom);
                  this.series.userOptions.parentFunctions.showLinksTo(this.linksTo);
                } else {
                  this.series.userOptions.parentFunctions.showNodesAndLinks(this.series.data);
                }
                this.selected = !this.selected;
              } else if (this.state !== 'select') { // Is visible
                let title = `Projects of ${this.from} for ${this.to}`;
                if (this.type === 'pm-type') {
                  title = `Projects with ${this.from} in ${this.to}`;
                }

                this.series.userOptions.parentFunctions.showProjectModal(this.projects, title);
              }
            },
          },
        },
        dataLabels: {
          style: {
            textOutline: 0,
            fontWeight: 300,
            fontSize: 12,
            color: 'white',
          },
          nodeFormatter() {
            if (this.point.shapeArgs.height > 24) { // Display labels only for nodes higher than 24px
              return this.key;
            }
          },
        },
      }],
      tooltip: {
        nodeFormatter() {
          const sum = numeral(this.sum).format('0,0');
          return `${this.id}: <b>${sum}</b> hours<br/>`;
        },
        pointFormatter() {
          const weight = numeral(this.weight).format('0,0');
          if (this.type === 'pm-type') {
            return `<b>${weight}</b> hours with <b>${this.from}</b> in <b>${this.to}</b>`;
          }
          return `<b>${weight}</b> hours of <b>${this.from}</b> for <b>${this.to}</b>`;
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({ data: this.props.data }, () => {
        this.renderChart();
      });
    }
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getOptions());
  }

  render() {
    return (
      <div className="profile-working-connections__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
