/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const NumericInput = require('../../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');

module.exports = class OrderValueInput extends React.Component {
  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
        <NumericInput name={this.props.name}
          onFocus={this.props.onFocus}
          id="sidebar-value"
          suffix={this.props.suffix}
          prefix={this.props.prefix}
          precision={2}
          label="Value"
          value={this.props.value} // Visible value is converted in the selected currency
          helperText={this.props.helperText}
          readOnly={this.props.readOnly}
          errorText={this.props.error}
          onChange={this.props.onChange}
          required />
      </InputValidator>
    );
  }
};
