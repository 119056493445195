const React = require('react');

const FooterContent = ({ children, className, primary }) => {
  const getClassName = () => {
    let name = 'wethod-budget-footer__content';
    if (className) {
      name += ` ${className}`;
    }
    if (primary) {
      name += ` ${name}--primary`;
    }

    return name;
  };

  return (
    <div className={getClassName()}>{children}</div>
  );
};

module.exports = FooterContent;
