const { connect } = require('react-redux');
const Component = require('../../../components/modals/ProfilePicPicker/ProfilePicPickerBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  pic: state.pic,
  waiting: state.waiting,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfilePic: (pic, employeeId) => dispatch(actions.updateProfilePic(pic, employeeId)),
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
