const React = require('react');
const { Provider } = require('react-redux');
const Budget = require('./containers/Budget');
const RequestManager = require('./containers/RequestManager');
const PermissionManager = require('./containers/PermissionManager');

require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <PermissionManager>
      <RequestManager>
        <Budget />
      </RequestManager>
    </PermissionManager>
  </Provider>
);
