/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class DateBarView extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__bar" style={this.props.style} />
    );
  }
};
