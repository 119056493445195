/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TextMenuView = require('./TextMenuView.react');
const TextMenuEdit = require('./TextMenuEdit.react');

module.exports = class TextMenu extends React.Component {
  render() {
    if (this.props.canEdit) {
      return <TextMenuEdit value={this.props.value} onSave={this.props.onSave} />;
    }
    return <TextMenuView value={this.props.value} />;
  }
};
