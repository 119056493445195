'use strict';

Wethod.module('ReportApp.ProjectChangelog', function (ProjectChangelog, Wethod, Backbone, Marionette, $) {
  this.ProjectChangelogModel = Backbone.Model.extend({
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      return response.code === 404 ? [] : data;
    },
  });

  this.ProjectChangelogColl = Backbone.Collection.extend({
    model: ProjectChangelog.ProjectChangelogModel,
    _offset: 0,
    _limit: 50,
    url: function () {
      var query = '?offset=' + this._offset + '&limit=' + this._limit;
      this._offset += this._limit;
      return APIURL + '/project-changelog/' + query;
    },

    getNextPage: function () {
      var defer = $.Deferred();
      this.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },

        error: function () { defer.resolve(undefined); },

        remove: false,
      });

      return defer.promise();
    },

    parse: function (response) {
      var data = (response.data) ? response.data : response;
      return response.code === 404 ? [] : data;
    },
  });

  Wethod.reqres.setHandler('get:report:project-changelog:coll', function (collection) {
    // If no collection is given this is the first fetch so create a new collection
    if (!collection) {
      collection = new ProjectChangelog.ProjectChangelogColl();
    }

    return collection.getNextPage();
  });
});
