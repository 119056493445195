/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const Work = require('../../../containers/modals/Portfolio/Work');

module.exports = class PortfolioBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }

  getWorks() {
    return this.props.works.map((work) => (
      <Work key={work.id}
        updateFormErrors={this.updateFormErrors.bind(this)}
        {...work} />
    ));
  }

  onAddWorkClick() {
    this.props.addWork({
      name: '',
      link: '',
    }, this.props.employee);
  }

  /**
   * @param errorId
   * @param type ('add' | 'remove') tells if you want to add or remove a languageID from the errors array
   */
  updateFormErrors(errorId, type) {
    this.setState((previusState) => {
      if (type === 'add') {
        return {
          ...previusState,
          errors: previusState.errors.concat(errorId),
        };
      }
      if (type === 'remove') {
        return {
          ...previusState,
          errors: previusState.errors.filter((error) => error !== errorId),
        };
      }
    });
  }

  getFeedback() {
    if (this.props.waiting) {
      return <div className="wethod-modal2__feedback">Saving...</div>;
    }
    if (this.props.creating) {
      return <div className="wethod-modal2__feedback">Creating...</div>;
    }
    if (this.state.errors.length > 0) {
      return (
        <div className="wethod-modal2__feedback wethod-modal2__feedback--error">
          Please provide a title and a link
          for each work
        </div>
      );
    }
    return null;
  }

  onDoneClick() {
    this.props.hideModal();
  }

  getCollectionHeader() {
    if (this.props.works.length > 0) {
      return (
        <div className="wethod-modal2-list__header">
          <span className="wethod-modal2-list__header-item profile-portfolio__item-name">Title</span>
          <span className="wethod-modal2-list__header-item profile-portfolio__item-link">Link</span>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile-portfolio--editable">
        <button type="button"
          onClick={this.onAddWorkClick.bind(this)}
          className="profile-portfolio__work-add wethod-button">
          + Work
        </button>
        {this.getCollectionHeader()}
        <div className="profile-portfolio__collection">
          {this.getWorks()}
        </div>
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.onDoneClick.bind(this)}
            className="wethod-button">Done
          </button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
