const { connect } = require('react-redux');
const Component = require('../../components/Boards/Board.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  projectId: state.info.id,
  canEdit: state.canEdit,
  waitingFor: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  getContent: (projectId, boardId) => dispatch(actions.getBoardContent(projectId, boardId)),
  showDeleteModal: (data, elementType) => dispatch(actions.showDeleteConfirmModal(data,
    elementType)),
  addItem: (boardId, item) => dispatch(actions.addBoardItem(boardId, item)),
  addStructureAttribute: (boardId, attribute) => dispatch(actions
    .addBoardStructureAttribute(boardId, attribute)),
  editName: (projectId, boardId, name) => dispatch(actions.editBoardName(projectId, boardId, name)),
  editItemName: (boardId, itemId, name) => dispatch(actions
    .editBoardItemName(boardId, itemId, name)),
  editAttributeValue: (boardId, itemId, attributeId, value) => dispatch(actions
    .editBoardAttributeValue(boardId, itemId, attributeId, value)),
  addAttributeValueMember: (boardId, itemId, attributeId, member) => dispatch(actions
    .addBoardAttributeMember(boardId, itemId, attributeId, member)),
  deleteItem: (boardId, itemId) => dispatch(actions.deleteBoardItem(boardId, itemId)),
  deleteAvailableStatus: (boardId, attributeId, statusId) => dispatch(actions
    .deleteStatus(boardId, attributeId, statusId)),
  updateAvailableStatus: (boardId, attributeId, status) => dispatch(actions
    .updateStatus(boardId, attributeId, status)),
  createAvailableStatus: (boardId, attributeId, status) => dispatch(actions
    .createStatus(boardId, attributeId, status)),
  deleteMilestone: (boardId, itemId, attributeId) => dispatch(actions
    .deleteMilestone(boardId, itemId, attributeId)),
  deleteMember: (boardId, itemId, attributeId, memberId) => dispatch(actions
    .deleteMember(boardId, itemId, attributeId, memberId)),
  changeType: (boardId, type) => dispatch(actions
    .changeBoardType(boardId, type)),
  showMissingStatusForKanbanModal: () => dispatch(actions
    .showMissingStatusForKanbanModal()),
  updateKanbanOptions: (boardId, options) => dispatch(actions
    .updateKanbanOptions(boardId, options)),
  updateActivityAttribute: (boardId, options) => dispatch(actions
    .updateActivityAttribute(boardId, options)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
