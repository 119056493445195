function getAvgOfSelectedLevel(item, selectedLevelId) {
  if (item) {
    const selectedLevel = item.levels.filter((level) => level.id === selectedLevelId);
    if (!selectedLevel || !selectedLevel.length) {
      return null;
    }
    return selectedLevel[0].avg_chargeability;
  }
  return null;
}

function isCurrentEqualToSelectedLevel(item, selectedLevelId) {
  return item.current_level === selectedLevelId;
}

function isEmployeeArchived(item) {
  return item.archived;
}

function sortByAvg(items, selectedLevelId, order = 'desc') {
  return items.sort((a, b) => {
    const avgA = getAvgOfSelectedLevel(a, selectedLevelId);
    const avgB = getAvgOfSelectedLevel(b, selectedLevelId);
    if (order === 'desc') {
      return avgB - avgA;
    }
    if (order === 'asc') {
      return avgA - avgB;
    }

    return 0;
  });
}

function sortByCurrLevel(a, b, selectedLevelId) {
  if (isCurrentEqualToSelectedLevel(a, selectedLevelId)) {
    return -1;
  }
  if (isCurrentEqualToSelectedLevel(b, selectedLevelId)) {
    return 1;
  }

  return 0;
}

function sortByLevel(items, selectedLevelId) {
  return items.sort((a, b) => {
    const isAArchived = isEmployeeArchived(a);
    const isBArchived = isEmployeeArchived(b);

    if (isAArchived === isBArchived) {
      return sortByCurrLevel(a, b, selectedLevelId);
    }

    return isAArchived ? 1 : -1;
  });
}

/**
 * Sort employees by given col with given level
 *
 * When sorting by 'current_level',
 * first are put the employees with the current_level equal to the selectedLevel,
 * then all the others (in the same order they were given before).
 * The order param is not taken into consideration.
 * When the selectedLevel is null, the order of the employees is not changed.
 *
 * When sorting by 'avg_chargeability',
 * the employees are put with descending or ascending order (based on 'order' param)
 * of the avg_chargeability of the selectedLevel
 *
 * @param items
 * @param selectedLevelId
 * @param col (current_level || avg_chargeability)
 * @param order (desc || asc)
 * @returns {Array} items sorted by given col
 */
module.exports = (items, selectedLevelId, col, order = 'desc') => {
  if (col === 'avg_chargeability') return sortByAvg(items, selectedLevelId, order);
  if (col === 'current_level') return sortByLevel(items, selectedLevelId);

  return items;
};
