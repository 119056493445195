const React = require('react');
const PrimaryInfo = require('./TaskPrimaryInfo.react');
const Name = require('./TaskName.react');
const Quantity = require('./DiscountTaskQuantity.react');
const Levels = require('./TaskLevels.react');
const DaysCell = require('../Cell/DaysCell.react');
const MoneyCell = require('../Cell/MoneyCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const Task = require('./Task.react');
const Price = require('../Cell/PriceCell.react');
const ExternalCosts = require('../../../containers/Calculator/Cell/ExternalCostHeaderCell');
const IconCell = require('../Cell/IconCell.react');

const DiscountTask = ({
  levels, price, quantity, editable, updateTaskInfo, previous,
  id, column,
}) => {
  function saveQuantity(amount) {
    updateTaskInfo(id, { product_quantity: amount });
  }

  if (column === 'left') {
    return (
      <Task>
        <PrimaryInfo>
          <Name>Discount</Name>
          <Quantity editable={editable} save={saveQuantity} id="task-discount-name" previousValue={previous.quantity}>{quantity}</Quantity>
        </PrimaryInfo>
      </Task>
    );
  }
  if (column === 'center') {
    return (
      <Task>
        <PrimaryInfo>
          <Levels items={levels}
            previousItems={previous.levels} />
        </PrimaryInfo>
      </Task>
    );
  }
  return (
    <Task className="wethod-budget-task__content--right-column">
      <PrimaryInfo className="wethod-budget-task__primary-info--right-column">
        <ExternalCosts value={0} />
        <PercentageCell />
        <DaysCell />
        <MoneyCell />
        <Price>{price}</Price>
        <IconCell />
        <MoreOptions />
      </PrimaryInfo>
    </Task>
  );
};

module.exports = DiscountTask;
