const request = require('./Request');

const BudgetDay = {
  update(id, attributes) {
    return request({
      method: 'patch',
      url: `/br/budget-day/${id}`,
      data: attributes,
    });
  },
};

module.exports = BudgetDay;
