/* eslint-disable react/sort-comp,react/no-did-update-set-state,class-methods-use-this,no-param-reassign,no-case-declarations,react/no-access-state-in-setstate,camelcase,consistent-return,react/jsx-no-bind */
const React = require('react');
const Sidebar = require('../../../../../../common/react/sidebar/Sidebar.react');
const Body = require('./SidebarBody.react');
const { getPermission } = require('../../services/authManager');
const { formatDate } = require('../../../../../../services/FormatService');
const Actions = require('./TravelSidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * travel: object corresponding the focused travel request to show in the sidebar
 * isSaving: boolean, check for pending saving
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.TravelSidebar}
 */
module.exports = class TravelSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes, false otherwise
      errors: [], // array containing the name of the attributes that have errors; if the list is empty there are no errors
      categoriesErrors: [], // array containing the name of the category attributes that have errors; if the list is empty there are no errors
      travel: this.getTravel(),
      editMode: !this.props.travel.id, // by default is true if travel request has no id (when we want to add a new one)
      page: 'main',
      errorBar: null,
      showAlertBar: this.canEditSent(),
      valuesErrors: [], // array containing the name of the category value missing
    };
  }

  changePage(category) {
    this.setState({ page: category });
  }

  getTravel() {
    if (this.props.travel && !this.props.travel.id && !this.props.travel.date) { // New travel
      return {
        ...this.props.travel,
        date: moment().format('YYYY-MM-DDThh:mm:ssZ'),
        return_date: moment().format('YYYY-MM-DDThh:mm:ssZ'),
        travel_carriers: [],
      };
    }
    return this.props.travel;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.travel && prevProps && prevProps.travel) {
      if (!prevState.travel.id && prevProps.travel.id) {
        const updatedTravel = {
          ...prevState.travel,
          id: prevProps.travel.id,
        };
        this.setState({
          travel: updatedTravel,
        });
      }
    }
  }

  componentDidMount() {
    this.handleMissingValues(this.state.travel.travel_carriers);
  }

  /**
   * Checks if it's safe to save a travel request: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    const employee = this.props.travel ? this.props.travel.employee_id : null;
    const status = this.props.travel ? this.props.travel.status : null;
    return getPermission('edit', employee, status) && !this.state.editMode;
  }

  canEditSent() {
    const employee = this.props.travel ? this.props.travel.employee_id : null;
    const status = this.props.travel ? this.props.travel.status : null;

    return getPermission('edit_sent', employee, status);
  }

  isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldTravel = this.props.travel || {};
    let oldVal = null;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'date':
        oldVal = oldTravel && oldTravel.date
          ? `${formatDate(oldTravel.date)} - ${formatDate(oldTravel.return_date)}`
          : null;
        value = value ? `${formatDate(value.start_date)} - ${formatDate(value.end_date)}` : null;
        break;
      case 'project':
        oldVal = oldTravel.project_id;
        value = value ? value.id : null;
        break;
      case 'destination':
        oldVal = oldTravel.destination;
        value = value ? value.city : null;
        break;
      default:
        oldVal = oldTravel[name];
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((this.isDirty(oldVal) || this.isDirty(value)) && (oldVal !== value)) {
      // When the value has changed, we insert the name in the changes array, if not already present
      const newChanges = this.state.unsavedChanges;
      if (!newChanges.includes(name)) {
        newChanges.push(name);
      }
      this.setState({ unsavedChanges: newChanges });
      totalChanges += 1;
    } else {
      // When the value is equal to the props, we need to remove it from the changes array if present
      let newChanges = this.state.unsavedChanges;
      newChanges = newChanges.filter((attr) => attr !== name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges = newChanges.length;
    }

    return totalChanges !== 0;
  }

  // Save in the local state the modified travel request and check if there are unsaved changes
  handleInputChanges(name, value) {
    let changes = {};
    switch (name) {
      case 'project':
        const projectId = value ? value.id : null;
        changes = {
          project: value,
          project_id: projectId,
        };
        break;
      case 'destination':
        const destination = value ? value.city : null;
        changes = { destination };
        break;
      case 'travel_carriers':
        this.handleMissingValues(value);
        changes = { [name]: value };
        break;
      default:
        changes = { [name]: value };
        break;
    }

    const newTravel = { ...this.state.travel, ...changes };

    const hasUnsavedChanges = this.hasChanged(name, value);
    this.setState({
      hasUnsavedChanges,
      travel: newTravel,
    });
  }

  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.state.travel);
    }
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  /**
   * Check for input errors regarding the categories and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleCategoryErrors(name, errors) {
    let updatedErrors = this.state.categoriesErrors;
    if (!errors || errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
      if (updatedErrors.length === 0) {
        this.showErrorBar();
      }
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ categoriesErrors: updatedErrors });
  }

  resetCategoryErrors(id) {
    let updatedErrors = this.state.categoriesErrors;
    updatedErrors = updatedErrors.filter((error) => {
      const keys = error.split('-');
      if (keys && keys.length > 2) {
        return keys[1] !== id;
      }
      return true;
    });

    this.setState({ categoriesErrors: updatedErrors });
  }

  // Check if some travel category items have a missing cost, and update the sidebar state
  handleMissingValues(travel_carriers) {
    if (this.canEditSent()) {
      let missingValues = []; // empty values (zero is not considered as empty value)
      if (travel_carriers) {
        missingValues = travel_carriers.filter((travel) => travel.value == null
          || Number.isNaN(travel.value));
      }

      this.setState({ valuesErrors: missingValues });
    }
  }

  handleSave() {
    if (!this.state.travel.travel_carriers || this.state.travel.travel_carriers.length === 0) {
      this.handleCategoryErrors('missing', ['missing required']);
      this.showErrorBar('missing');
    } else if (this.state.categoriesErrors && this.state.categoriesErrors.length > 0) {
      this.changePage('main');
      this.showErrorBar('required');
    } else {
      this.setState({
        editMode: false,
        hasUnsavedChanges: false,
      });

      if (!this.state.travel.id) {
        if (this.props.onCreate) {
          this.props.onCreate(this.state.travel);
        }
      } else if (this.props.onSave) {
        this.props.onSave(this.state.travel);
      }
    }
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  showErrorBar(error) {
    if (error === 'missing') {
      this.setState({ errorBar: 'Please, add a travel category detail before saving.' });
    } else if (error === 'required') {
      this.setState({ errorBar: 'Please, check the information below.' });
    } else {
      this.setState({ errorBar: null });
    }
  }

  hideAlertBar() {
    this.setState({ showAlertBar: false });
  }

  // Get the error bar to be shown in the sidebar; the bar is hidden when we are not in the main page
  getErrorBar() {
    return this.state.page === 'main' ? this.state.errorBar : null;
  }

  getAlertBar() {
    if (this.state.showAlertBar && this.state.valuesErrors.length > 0 && this.state.page === 'main') {
      return (
        <div className="travel__alert-bar">
          Insert travel cost for each of the travel requests below.
          <div className="wethod-button" onClick={this.hideAlertBar.bind(this)}>Ok, got it</div>
        </div>
      );
    }
  }

  getBody() {
    return (
      <Body travel={this.state.travel}
        travelCarrierMetadata={this.props.travelCarrierMetadata}
        page={this.state.page}
        editable={this.state.editMode}
        onChange={this.handleInputChanges.bind(this)}
        onDelete={this.handleDelete.bind(this)}
        onValidate={this.handleErrors.bind(this)}
        resetErrors={this.resetCategoryErrors.bind(this)}
        onCategoryValidate={this.handleCategoryErrors.bind(this)}
        errors={this.state.categoriesErrors}
        missingValues={this.state.valuesErrors}
        changePage={this.changePage.bind(this)} />
    );
  }

  getTitle() {
    if (this.state.page === 'main') {
      return 'Travel request';
    }
    return (
      <span className="travel__sidebar-title">
        <button type="button"
          className="wethod-icon-button wethod-icon-button--no-border"
          onClick={() => this.changePage('main')}>
          <div className="wethod-icon wethod-icon-arrow-left wethod-icon-arrow-left--black" />
        </button> Travel request
      </span>
    );
  }

  getActions() {
    if (this.state.travel && this.state.travel.id && this.canEdit()) {
      return (
        <Actions onDelete={this.handleDelete.bind(this)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Sidebar title={this.getTitle()}
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        error={this.getErrorBar()}
        alert={this.getAlertBar()}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
