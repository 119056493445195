const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/Title.react');

const mapStateToProps = (state) => ({
  week_production: state.week_production,
  prev_week_production: state.prev_week_production,
  weekly_target: state.weekly_target,
  target_type: state.target_type,
});

module.exports = connect(mapStateToProps)(Component);
