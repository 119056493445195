'use strict';

Wethod.module('Authorization', function () {
  /**
   * Return true if the employee is pm
   *
   * @param employeeId
   * @param idPm
   * @returns {boolean}
   */
  this.isPm = function (employeeId, idPm) {
    // eslint-disable-next-line eqeqeq
    return employeeId == idPm;
  };

  /**
   * Return true if the employee account
   *
   * @param employeeId
   * @param idAccount
   * @returns {boolean}
   */
  this.isAccount = function (employeeId, idAccount) {
    // eslint-disable-next-line eqeqeq
    return employeeId == idAccount;
  };

  /**
   * Return true if the employee is pm or account
   *
   * @param employeeId
   * @param idPm
   * @param idAccount
   * @returns {boolean}
   */
  this.isPmOrAccount = function (employeeId, idPm, idAccount) {
    // eslint-disable-next-line eqeqeq
    return employeeId == idPm || employeeId == idAccount;
  };

  /**
   * Return true if the logged user is pm
   *
   * @param pmId
   * @returns {boolean}
   */
  this.amIPm = function (pmId) {
    return this.isPm(Wethod.userInfo.get('employee_id'), pmId);
  };

  /**
   * Return true if the logged user is account
   *
   * @param accountId
   * @returns {boolean}
   */
  this.amIAccount = function (accountId) {
    return this.isAccount(Wethod.userInfo.get('employee_id'), accountId);
  };

  /**
   * Return true if the logged user is pm or account
   *
   * @param pmId
   * @param accountId
   * @returns {boolean}
   */
  this.amIPmOrAccount = function (pmId, accountId) {
    var employeeId = Wethod.userInfo.get('employee_id');
    return this.isPm(employeeId, pmId) || this.isAccount(employeeId, accountId);
  };
});
