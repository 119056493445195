'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ExpensesStore = require('../../apps/core/modules/expenses/store');
var ExpensesReducer = require('../../apps/core/modules/expenses/reducer');
var ExpensesComponent = require('../../apps/core/modules/expenses/index');
var PropertyMetadataModel = require('../../models/PropertyMetadata');

// eslint-disable-next-line no-unused-vars
Wethod.module('ExpensesApp.Expenses', function (Expenses, Wethod, Backbone, Marionette, $) {
  Expenses.Controller = {

    showExpenses: function (expensesId) {
      var authorizationRequest = Wethod.request('get:expenses:authorization');
      $.when(authorizationRequest).done(function (permissions) {
        if (!permissions.get('view')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        var expenseCategoriesRequest = Wethod.request('get:company:expense-category', true);

        $.when(expenseCategoriesRequest,
          PropertyMetadataModel.list(PropertyMetadataModel.EXPENSE_ENTITY))
          .done(function (expenseCategories, expenseMetadata) {
            var layout = new Expenses.StructureLayoutView();
            layout.render();

            var sentence = Wethod.Utility
              .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
            var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
            layout.expensesBody.show(loadingView);

            var reducerWrapper = new ExpensesReducer({
              id: expensesId,
              permissions: permissions.attributes,
              categories: expenseCategories.toJSON(),
              expenseMetadata: expenseMetadata,
            });

            var store = ExpensesStore(reducerWrapper.reduxReducer);
            var element = React.createElement(ExpensesComponent, { store: store });
            var container = document.getElementById('root');
            if (container !== null) ReactDOM.render(element, container);
          });
      });
    },
  };
});
