/* eslint-disable react/sort-comp,react/no-did-update-set-state,react/no-access-state-in-setstate,
 class-methods-use-this */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Table = require('../../../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../../../common/react/Table2/TableBody.react');
const Contact = require('../containers/Contact');

const ContactSidebar = require('../containers/ContactSidebar');

module.exports = class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 0,
      page: 0,
      filtered: [],
      sortBy: {
        col: 'name',
        order: 'asc',
      },
    };
  }

  handleSort(col, order) {
    this.setState({
      sortBy: {
        col,
        order,
      },
    });
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  loadMore(size, page) {
    this.setState({
      page,
      size,
    });
  }

  hasMore() {
    return this.state.filtered < this.props.contacts;
  }

  compare(a, b) {
    const valueA = a[this.state.sortBy.col];
    const valueB = b[this.state.sortBy.col];

    if (!valueA) {
      return 1;
    }
    if (!valueB) {
      return -1;
    }
    if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return this.state.sortBy.order === 'asc' ? 1 : -1;
    }
    if (valueA.toLowerCase() < valueB.toLowerCase()) {
      return this.state.sortBy.order === 'asc' ? -1 : 1;
    }
    return 0;
  }

  componentDidUpdate(prevProps, prevState) {
    const listChanged = this.props.contacts.length !== prevProps.contacts.length;
    const sortChanged = this.state.sortBy.col !== prevState.sortBy.col
      || this.state.sortBy.order !== prevState.sortBy.order;
    if (prevState.page !== this.state.page || sortChanged || listChanged) {
      const sorted = this.props.contacts.sort(this.compare.bind(this));
      this.setState({ filtered: sorted.slice(0, this.state.page * this.state.size) });
    }
  }

  getContacts() {
    return this.state.filtered.map((contact) => <Contact key={contact.id} {...contact} />);
  }

  render() {
    return (
      <div className="client-contacts client-content__main-section">
        <h2 className="client-content__main-title">Contacts</h2>
        <button type="button"
          className="client-content__add-button wethod-button"
          onClick={this.props.addContact}>
          Add
        </button>
        <div className="client-content__list">
          <Table columns={5}
            maxHeight={200}>
            <TableHead>
              <TableRow>
                <TableCell
                  className="client-contacts__column--name"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('name')}
                  name="name">
                  Name
                </TableCell>
                <TableCell
                  className="client-contacts__column--surname"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('surname')}
                  name="surname">
                  Surname
                </TableCell>
                <TableCell
                  className="client-contacts__column--role"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('role')}
                  name="role">
                  Role
                </TableCell>
                <TableCell className="client-contacts__column--email">Email</TableCell>
                <TableCell className="client-contacts__column--actions" />
              </TableRow>
            </TableHead>
            <TableBody
              hasMore={this.hasMore()}
              loadMore={this.loadMore.bind(this)}
              empty="There's nothing here! What about adding a contact?">
              {this.getContacts()}
            </TableBody>
          </Table>
        </div>
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <ContactSidebar />
        </CSSTransition>
      </div>
    );
  }
};
