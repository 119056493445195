'use strict';

Wethod.module('Utility', function (Utility, Wethod, Backbone, Marionette, $, _) {
  this.BaseModel = Backbone.Model.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;

      // if "cached_on" exist add it to data
      if (!_.isUndefined(response.cached_on)) {
        data.cached_on = response.cached_on;
      }

      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
  });

  this.BaseCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    /**
     * Returns an array of model, sorted by their 'attributeName' value.
     * This function solves the problem by which Backbone.Collection.sortBy() cannot reverse sort
     * Backbone.Collection by using a string attribute as comparator.
     *
     * More info at https://gist.github.com/malandrew/950240.
     *
     * @param attributeName
     * @param direction (asc | desc)
     * @returns {Array|TModel[]|*}
     */
    universalSortBy: function (attributeName, direction) {
      return this.sortBy(function (model) {
        var attributeValue = model.get(attributeName);
        if (direction === 'asc') {
          if (typeof attributeValue === 'string') {
            attributeValue = attributeValue.toLowerCase();
          }
          return attributeValue;
        }
        if (typeof attributeValue === 'string') {
          var str = attributeValue.toLowerCase();
          str = str.split('');
          str = _.map(str, function (letter) {
            return String.fromCharCode(-(letter.charCodeAt(0)));
          });
          return str;
        }
        return -attributeValue;
      });
    },
  });

  // autocomplete
  this.AutocompleteCollection = Wethod.Utility.BaseCollection.extend({
    _autocompletePool: [],
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    sync: function (method, model, options) {
      options = options || {};
      if (!options.crossDomain) {
        options.crossDomain = true;
      }
      if (!options.xhrFields) {
        options.xhrFields = { withCredentials: true };
      }

      _.each(this._autocompletePool, function (request) {
        if (request.readyState !== 4) {
          request.abort();
        }
      });

      if (method === 'read') {
        var xhr = proxiedSync(method, model, options);
        this._autocompletePool.push(xhr);
        return xhr;
      }
      return proxiedSync(method, model, options);
    },
  });
});
