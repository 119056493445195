/* eslint-disable react/sort-comp,react/no-access-state-in-setstate,consistent-return */
const React = require('react');
const TextInput = require('../../../../../../../common/react/material-input/SidebarTextInput.react');
const NumericInput = require('../../../../../../../common/react/material-input/SidebarNumericInput.react');

module.exports = class GoalModalBodyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      goal: this.props.goal || { target: 100, progress: 0 },
      errors: [],
    };
  }

  hasErrors() {
    return this.state.errors.length > 0;
  }

  onGoalChange(name, value) {
    const newGoal = { ...this.state.goal, [name]: value };
    this.setState({ goal: newGoal });
  }

  onValidate(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  handleSave() {
    if (!this.hasErrors()) {
      const goalToSave = this.state.goal.target < this.state.goal.progress
        ? { ...this.state.goal, progress: this.state.goal.target }
        : this.state.goal;
      this.props.onSave(goalToSave, this.props.projectId);
    }
  }

  handleDelete() {
    this.props.onDelete(this.state.goal, this.props.projectId);
  }

  getFeedback() {
    if (this.props.isSaving) return <div className="profile-contact-info__feedback">Saving...</div>;
    if (this.props.isDeleting) return <div className="profile-contact-info__feedback">Deleting...</div>;
    return null;
  }

  getActionsClassName() {
    let name = 'project-canvas__text-area-modal-actions';
    if (this.props.isSaving || this.props.isDeleting || this.hasErrors()) {
      name += ' project-canvas__text-area-modal-actions--disabled';
    }
    return name;
  }

  getDeleteAction() {
    if (this.state.goal.id) {
      return (
        <button type="button" onClick={this.handleDelete.bind(this)} className="wethod-button wethod-button--warning">Delete</button>
      );
    }
  }

  render() {
    return (
      <div className="project-canvas__goal-modal-input-wrapper">
        <div className="project-canvas__goal-modal-input">
          <div className="project-canvas__goal-modal-input--name">
            <TextInput name="name"
              value={this.state.goal.name}
              placeholder="Details"
              onChange={this.onGoalChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              required />
          </div>
          <div className="project-canvas__goal-modal-input--target">
            <NumericInput name="target"
              value={this.state.goal.target.toString()}
              placeholder="Target"
              onChange={this.onGoalChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              required />
          </div>
        </div>
        <div className={this.getActionsClassName()}>
          {this.getDeleteAction()}
          <button type="button" onClick={this.handleSave.bind(this)} className="wethod-button">Save</button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
