const React = require('react');
const IconLink = require('../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');

const ButtonContainer = ({ info }) => {
  const projectId = info.project.id;
  const linkBudget = `#pipeline/budget/${projectId}`;
  const linkPlanning = '#planning';
  const linkReport = `#project/${projectId}/report`;
  const linkInvoicePlan = `#project/${projectId}/invoice-plan`;
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
      <IconLink key="report"
        label="Report"
        href={linkReport}
        target="_blank">
        <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
      </IconLink>
      <IconLink key="planning"
        label="Planning"
        href={linkPlanning}
        target="_blank">
        <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
      </IconLink>
      <IconLink key="budget"
        label="Budget"
        href={linkBudget}
        target="_blank">
        <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
      </IconLink>
      <IconLink key="calendar"
        label="Invoice plan"
        href={linkInvoicePlan}
        target="_blank">
        <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
      </IconLink>
    </div>
  );
};

module.exports = ButtonContainer;
