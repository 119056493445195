const React = require('react');
const formatWithLeadingZeroes = require('../../../../../../../../services/FormatService').formatNumberWithLeadingZeroes;
const NumericInput = require('../../../../../../../../common/react/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const Block = require('./BlockInput.react');

const NumberBlock = ({ templateId, block, deletable, onChange, onBlockDelete }) => {
  function handleChange(e) {
    const { name, value } = e.target;
    const formattedValue = formatWithLeadingZeroes(value, block.format);
    onChange(name, formattedValue, block.sort);
  }

  return (
    <Block block={block} deletable={deletable} onBlockDelete={onBlockDelete}>
      <NumericInput
        id={`${templateId}-number-${block.sort}`}
        name="value"
        label="Starting progressive nr"
        placeholder="Insert number"
        data-sort={block.sort}
        value={block.value}
        required
        onBlur={handleChange}
        delimiter="" />
    </Block>
  );
};

module.exports = NumberBlock;
