const { connect } = require('react-redux');
const Component = require('../../components/CreationModal/CreationModeModal.react');
const actions = require('../../actions');
const Reducer = require('../../reducer');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
  isWaiting: Reducer.waitingFor(state, 'creation'),
});

const mapDispatchToProps = (dispatch) => ({
  showTemplateModal: () => dispatch(actions.showCreationTemplateModal()),
  createBudget: (projectId) => dispatch(actions.createBudget(projectId)),
  closeModal: () => dispatch(actions.closeCreateBudgetModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
