const React = require('react');
const PropTypes = require('prop-types');
const MathService = require('../../../../../../services/MathService');
const Rating = require('../../../../Rating/SmallRating.react');
const Histogram = require('./Histogram.react');

/**
 * Shows average, distribution and the total amount of the given ratings.
 * Used by RatingOverview.
 *
 * @param maxRating
 * @param ratings
 * @returns {*}
 * @see RatingOverview
 * @constructor
 */
const Summary = ({ maxRating, ratings }) => {
  const counter = ratings.length;

  const average = counter ? ratings.reduce((sum, rating) => sum + rating, 0) / counter : null;

  const formattedAverage = MathService.round(average, 1);

  const roundedAverage = counter ? Math.floor(average) : null;

  /**
   * Return a list cotaining ratings distribution on all possible values.
   * List is sorted from greater values to lower.
   * @returns {{percentage: number, rating: number}[]}
   */
  const getDistribution = () => {
    const factors = new Array(maxRating).fill(0);

    for (let i = 0; i < ratings.length; i++) {
      factors[ratings[i] - 1] += 1;
    }

    return factors
      .map((factor, index) => ({
        rating: index + 1,
        percentage: Math.round((factor / counter) * 100),
      }))
      .sort((a, b) => b.rating - a.rating);
  };

  const histograms = getDistribution()
    .map((factor) => (
      <Histogram key={factor.rating}
        rating={factor.rating}
        percentage={factor.percentage} />
    ));

  const counterText = counter === 1 ? '1 rating' : `${counter} ratings`;

  return (
    <div className="widget-rating-overview-summary">
      <div className="widget-rating-overview-summary__totals">
        <div className="widget-rating-overview-summary__totals-left">
          <div className="widget-rating-overview-summary__average-value">
            {formattedAverage}
          </div>
        </div>
        <div className="widget-rating-overview-summary__totals-right">
          <div className="widget-rating-overview-summary__average-stars">
            <Rating max={maxRating} value={roundedAverage} readOnly />
          </div>
          <div className="widget-rating-overview-summary__counter">
            {counterText}
          </div>
        </div>
      </div>
      <div className="widget-rating-overview-summary__distribution">
        {histograms}
      </div>
    </div>
  );
};

Summary.propTypes = {
  maxRating: PropTypes.number.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.number).isRequired,
};

module.exports = Summary;
