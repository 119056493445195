const { connect } = require('react-redux');
const Component = require('../../components/location/EditRow.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-locations-')),
  waitingForDelete: state.waitingFor.filter((key) => key.startsWith('delete-locations-')),
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (id, location) => dispatch(actions.updateLocation(id, location)),
  onDelete: (id) => dispatch(actions.deleteLocation(id)),
  showDeleteModal: (data) => {
    dispatch(actions.showDeleteLocationConfirmModal(data));
  },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
