'use strict';

Wethod.module('PlanningApp.Planning', function (PlanningApp, Wethod, Backbone, Marionette) {
  this.ProductionPlan = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#productionPlanTemplate',
    regions: {
      sectionHeader: '[data-region="productionPlanSectionHeader"]',
      body: '[data-region="productionPlanBody"]',
    },
  });

  // LOADING MORE DATA
  this.LoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });
});
