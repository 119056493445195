/* eslint-disable react/prefer-stateless-function,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions,react/jsx-no-bind,react/prop-types */
const React = require('react');

module.exports = class Area extends React.Component {
  render() {
    return (
      <li className="app-nav-mobile-area"
        onClick={() => this.props.showArea(this.props.area)}>
        <span className="app-nav-mobile-area__label">{this.props.area.getLabel()}</span>
        <span className="app-nav-mobile-area__icon wethod-icon-forward wethod-icon-forward--black" />
      </li>
    );
  }
};
