const constants = require('./constants');
const QuarterFilter = require('../../finance/clients/detail/company/models/TimeFilter/QuarterFilter');
const searchFilter = require('./services/filterEmployees');
const sortEmployees = require('./services/sortEmployees');

class ChargeabilityReducer {
  constructor(state) {
    const timeFilter = new QuarterFilter();

    this.state = {
      ...state,
      waitingFor: [],
      timeFilter: timeFilter.toJSON(),
      levels: [],
      employees: [],
      filteredEmployees: [],
      keyword: '', // search keyword
      selectedLevel: { id: null },
      load: {
        page: 0,
        size: 0,
      },
      sortBy: {
        col: 'current_level',
        order: 'desc',
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    let { selectedLevel } = state;
    switch (action.type) {
      case constants.UPDATE_TIME_FILTER:
        return {
          ...state,
          timeFilter: action.filter,
        };
      case constants.GET_CHARGEABILITY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('chargeability'),
          selectedLevel: { id: null },
          keyword: '', // Reset keyword
          levels: [],
          employees: [],
          filteredEmployees: [],
          sortBy: {
            col: 'current_level',
            order: 'desc',
          },
          load: {
            ...state.load,
            page: 1, // Reset employees loading to first page
          },
        };
      case constants.GET_CHARGEABILITY_SUCCESS:
        [selectedLevel] = action.levels.filter((lvl) => !lvl.id); // Selected level 'all'
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'chargeability'),
          levels: action.levels.sort((levelA) => (levelA.id ? 0 : -1)), // Place level 'all' on top
          selectedLevel,
          employees: action.employees,
          filteredEmployees: sortEmployees(
            searchFilter(state.keyword, action.employees, null),
            selectedLevel,
            state.sortBy.col,
            state.sortBy.order,
          ),
        };
      case constants.FILTER_EMPLOYEES:
        [selectedLevel] = state.levels.filter((lvl) => !lvl.id); // Select level 'all' when searching with keyword
        return {
          ...state,
          keyword: action.keyword,
          selectedLevel,
          filteredEmployees: sortEmployees(
            searchFilter(action.keyword, state.employees, null),
            selectedLevel.id,
            state.sortBy.col,
            state.sortBy.order,
          ),
          load: {
            ...state.load,
            page: 1, // Reset employees loading to first page
          },
        };
      case constants.FILTER_EMPLOYEES_LEVEL:
        selectedLevel = action.level;
        return {
          ...state,
          selectedLevel,
          filteredEmployees: sortEmployees(
            searchFilter(state.keyword, state.employees, action.level.id),
            selectedLevel.id,
            'current_level',
            state.sortBy.order,
          ),
          sortBy: {
            ...state.sortBy,
            col: 'current_level', // Show first the employees with selected level
          },
          load: {
            ...state.load,
            page: 1, // Reset employees loading to first page
          },
        };
      case constants.SORT_EMPLOYEES:
        return {
          ...state,
          sortBy: {
            col: action.col,
            order: action.order,
          },
          filteredEmployees: sortEmployees(
            state.filteredEmployees,
            state.selectedLevel.id,
            action.col,
            action.order,
          ),
          load: {
            ...state.load,
            page: 1, // Reset employees loading to first page
          },
        };
      case constants.LOAD_EMPLOYEES:
        return {
          ...state,
          load: {
            page: action.page,
            size: action.size,
          },
        };
      default:
        return state;
    }
  }
}

module.exports = ChargeabilityReducer;
