const { isMe } = require('../../../../../services/UserService');

const isSent = (travelStatus) => travelStatus === 'sent';

const isApproved = (travelStatus) => travelStatus === 'approved';

module.exports = {
  getPermission: (action, travelEmployeeId = null, travelStatus = 'created') => {
    const auth = Wethod.TravelApp.authorization;
    let allowed = false;

    switch (action) {
      case 'view':
        allowed = auth.get('view') && (isMe(travelEmployeeId) || auth.get('view_other'));
        break;
      case 'edit':
        if (isSent(travelStatus) || isApproved(travelStatus)) allowed = auth.get('edit_sent');
        else allowed = auth.get('edit') && (isMe(travelEmployeeId) || auth.get('edit_other'));
        break;
      case 'edit_sent':
        if (isSent(travelStatus) || isApproved(travelStatus)) allowed = auth.get('edit_sent');
        else allowed = false;
        break;
      default:
        allowed = auth.get(action) || false;
        break;
    }

    return allowed;
  },
  isTravelMine: (travelEmployeeId) => isMe(travelEmployeeId),
};
