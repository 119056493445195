const { connect } = require('react-redux');
const Sidebar = require('../components/BasketSidebar.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  canEdit: true,
  canDelete: state.permissions.delete,
  isSaving: state.isSaving,
  isLoading: state.isLoadingEmployees || state.isLoadingCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (opportunity) => dispatch(actions.saveOpportunity(opportunity)),
  onDelete: (opportunity) => dispatch(actions.deleteOpportunity(opportunity)),
  onClose: () => dispatch(actions.closeSidebar()),
  share: () => dispatch(actions.showShareModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
