const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const PV = require('../../../../common/components/PercentageValue.react');
const Comparator = require('../../../../common/components/Comparator.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({
  month, projectsCount, projectsValue, target, trendOnLastYear,
}) => {
  const getTargetComparator = () => {
    const labels = {
      much_lower: 'well below',
      lower: 'below',
      almost_equal: 'in line with',
      equal: 'equal',
      greater: 'above',
      much_greater: 'well above',
    };

    return <Comparator a={projectsValue} b={target} labels={labels} />;
  };

  const getLastYearComparator = () => {
    if (trendOnLastYear > 0) {
      return (
        <span>
          increased by{' '}
          <PV value={Math.abs(trendOnLastYear)} />
        </span>
      );
    }
    if (trendOnLastYear < 0) {
      return (
        <span>
          decreased by{' '}
          <PV value={Math.abs(trendOnLastYear)} />
        </span>
      );
    }
    return <span>are constant</span>;
  };

  return (
    <Paragraph>
      In
      {' '}
      {month}
      {' '}
      we won
      {' '}
      <NV value={projectsCount} />
      {' '}
      new
      {' '}
      <NS quantity={projectsCount}>projects</NS>
      {' '}
      for a total value of
      {' '}
      <NV value={projectsValue} />
      , which is
      {' '}
      {getTargetComparator()}
      {' '}
      the average monthly target of
      {' '}
      <NV value={target} />
      .
      Sales
      {' '}
      {getLastYearComparator()}
      {' '}
      compared to the same month last year.
    </Paragraph>
  );
};
