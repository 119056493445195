/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Menu = require('../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const Item = require('../../../../../../../common/react/Menu/ListMenu/MenuItemColorful.react');

const SupplierStatusList = ({
  open, onClose, anchorEl, statusList, onStatusChange,
}) => {
  const getStatusList = () => statusList.map((status) => (
    <Item
      key={status.id}
      color={status.color}
      onClick={() => onStatusChange(status)}>
      {status.name}
    </Item>
  ));
  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
      {getStatusList()}
    </Menu>
  );
};

module.exports = SupplierStatusList;
