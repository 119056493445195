module.exports.FILTER_SUPPLIERS = 'FILTER_SUPPLIERS';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.ADD_SUPPLIER_REQUEST = 'ADD_SUPPLIER_REQUEST';
module.exports.ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
module.exports.ADD_SUPPLIER_ERROR = 'ADD_SUPPLIER_ERROR';

module.exports.GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST';
module.exports.GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';

module.exports.UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST';
module.exports.UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
