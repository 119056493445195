const React = require('react');
const NameCell = require('../Cell/NameCell.react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

const AreaName = ({ children, editable, save, id }) => {
  function onChange(e) {
    save({ name: e.target.value });
  }

  function onClick(e) {
    e.stopPropagation();
  }

  return (
    <NameCell className="wethod-budget-area__name" tooltip={children}>
      <CollaborativeInput id={id}>
        <TextField readOnly={!editable} value={children} onChange={onChange} onClick={onClick} label="Area name" />
      </CollaborativeInput>
    </NameCell>
  );
};

module.exports = AreaName;
