const React = require('react');
const { isMe } = require('../../../../../../services/UserService');
const { formatDate } = require('../../../../../../services/FormatService');

const CreationInfo = ({ owner, creator, date }) => {
  /**
   * Showing creator info only when the logged user is not the creator or is not the owner of the expense
   * @type {string|string}
   */
  const createdBy = (!isMe(owner.id) || !isMe(creator.id))
    ? ` by ${creator.name} ${creator.surname}`
    : '';
  const formattedDate = formatDate(date);

  return (
    <div className="expenses-sidebar__description">
      Created{createdBy} on {formattedDate}.
    </div>
  );
};

module.exports = CreationInfo;
