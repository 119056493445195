const Node = require('./StructureNode');
const Total = require('../NodeTotal/LevelAmountNodeTotal');
const Previous = require('../NodePrevious/LevelAmountNodePrevious');
const FeatureService = require('../../../../../../services/FeatureService');

/**
 * Represents an amount of days related to a specific level.
 */
module.exports = class LevelAmount extends Node {
  /**
   * @param levelAmount
   * @param {AvailableLevel} levelData
   */
  constructor(levelAmount, levelData) {
    super();
    this.levelAmount = levelAmount;
    this.levelData = levelData;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  // TODO why this method does not return levelAmount.id?
  getId() {
    return this.levelData.getId();
  }

  getAmountId() {
    return this.levelAmount.id;
  }

  getDays() {
    return this.levelAmount.days;
  }

  isNew() {
    return this.levelAmount.is_new;
  }

  wasDeleted() {
    return this.levelAmount.deleted;
  }

  getPreviousDays() {
    const hasPrevious = this.levelAmount.was;
    return hasPrevious ? this.levelAmount.was.days : this.getDays();
  }

  getDailyCost() {
    if (Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)) {
      return this.levelAmount.budget_price_list_level.cost;
    }
    return this.levelData.getCost();
  }

  getDailyPrice() {
    if (Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)) {
      return this.levelAmount.budget_price_list_level.price;
    }
    return this.levelData.getPrice();
  }

  /**
   *
   * @returns {number}
   */
  getCost() {
    return this.getDays() * this.getDailyCost();
  }

  /**
   * @private
   * @returns {number}
   */
  getPrice() {
    return this.getDays() * this.getDailyPrice();
  }

  /**
   * @return {number}
   */
  getOrder() {
    return this.levelData.getOrder();
  }

  /**
   * @returns {LevelAmountNodeTotal}
   */
  getTotal() {
    return this.total;
  }

  /**
   * @return {LevelAmountNodePrevious}
   */
  getPrevious() {
    return this.previous;
  }

  /**
   * @private
   * @returns {LevelAmountNodeTotal}
   */
  calculateTotal() {
    return new Total(this.getDays(), this.getCost(), this.getPrice());
  }

  /**
   * @private
   * @return {LevelAmountNodePrevious}
   */
  calculatePrevious() {
    return new Previous(this.getPreviousDays());
  }

  toJson() {
    return {
      ...this.levelAmount,
      ...this.levelData,
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };
  }
};
