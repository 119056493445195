const { connect } = require('react-redux');
const Component = require('../../components/advanced-search/filters/RiskFilter.react');

const mapStateToProps = (state) => ({
  availableOptions: state.risks.map((type) => ({
    label: type.name,
    value: type.name,
    color: type.color,
  })),
});

module.exports = connect(mapStateToProps)(Component);
