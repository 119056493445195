const PipelineAutocomplete = {
  getAll(type, keyword) {
    const params = {
      whereSearch: type,
      whatSearch: keyword,
    };

    return Wethod.request('get:pipeline:autocomplete', params);
  },
  getClients(keyword) {
    return this.getAll('client', keyword);
  },
  getAccounts(keyword) {
    return this.getAll('account', keyword);
  },
  getClientContacts(keyword, clientId) {
    const params = {
      whereSearch: 'customer',
      whatSearch: keyword,
      client_id: clientId,
    };

    return Wethod.request('get:pipeline:autocomplete', params);
  },
  getManagers(keyword) {
    return this.getAll('pm', keyword);
  },
  getPrograms(keyword) {
    return this.getAll('program', keyword);
  },
  getWhitelist(keyword) {
    return this.getAll('whitelist', keyword);
  },
};

module.exports = PipelineAutocomplete;
