const { connect } = require('react-redux');
const Component = require('../components/KnowledgeTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'knowledge').length > 0,
  tags: state.filteredSkills,
  employees: state.filteredSkillEmployees,
  activeTags: state.activeSkills,
  empSize: state.loadSkillEmployees.size,
  empPage: state.loadSkillEmployees.page,
  name: 'skills',
});

const mapDispatchToProps = (dispatch) => ({
  onTagClick: (skill) => dispatch(actions.toggleSkill(skill)),
  filterEmployees: (keyword) => dispatch(actions.filterSkillEmployees(keyword)),
  filterTags: (keyword) => dispatch(actions.filterSkills(keyword)),
  loadEmployees: (size, page) => dispatch(actions.loadSkillEmployees(size, page)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
