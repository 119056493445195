const request = require('./Request');

const BudgetBaseline = {
  updateBaseline(budgetId) {
    return request({
      method: 'put',
      url: `/budget/${budgetId}/update-baseline`,
    });
  },
};

module.exports = BudgetBaseline;
