const React = require('react');
const _ = require('underscore');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('../../../../../../common/react/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const AutocompleteModel = require('../../../../../../models/PipedriveIntegration');

module.exports = class PipedriveDealSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.name);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
      inputValue: undefined,
      error: null,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
      error: null,
    });

    AutocompleteModel.getDeals(keyword, this.getClient())
      .done((list) => this.setState({
        availableItems: list,
        isLoading: false,
      })).fail((e) => {
        this.setState({
          error: e || 'Some error occurred',
          availableItems: [],
          isLoading: false,
        });
      });
  }

  onChange(e, item) {
    const { value } = e.target;
    let deal = null;
    if (item) {
      deal = {
        id: value,
        title: item.name,
      };

      this.props.onChange(e, deal);

      // Set the item and then remove it to trigger the update of the Autocomplete and clear the input
      this.setState({ inputValue: item }, () => this.resetInput());
    }
  }

  getOptions() {
    return this.state.availableItems.map((deal) => (
      <SelectItem key={deal.id}
        value={deal.id}
        name={deal.title}>
        {deal.title}
      </SelectItem>
    ));
  }

  getClient() {
    return this.props.client ? this.props.client.id : null;
  }

  resetInput() {
    this.setState({ inputValue: undefined });
  }

  render() {
    return (
      <Autocomplete readOnly={this.props.readOnly}
        onChange={this.onChange.bind(this)}
        value={this.state.inputValue}
        getInputValue={PipedriveDealSelect.getInputValue}
        name={this.props.name}
        label=""
        placeholder="Search on Pipedrive"
        id="sidebar-pipedrive-search"
        onFilter={this.onFilter.bind(this)}
        errorText={this.state.error || this.props.error}
        loading={this.state.isLoading}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
};
