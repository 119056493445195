const React = require('react');
const PropTypes = require('prop-types');

require('./style.scss');

const Typography = ({
  component,
  weight,
  uppercase,
  capitalize,
  className,
  children,
  color,
  size,
}) => {
  const Component = component;

  const getClassName = () => {
    const nameBase = 'wethod-typography';
    let name = `${nameBase} ${className}`;
    name += ` ${nameBase}--weight-${weight}`;

    if (uppercase) {
      name += ` ${nameBase}--uppercase`;
    }
    if (capitalize) {
      name += ` ${nameBase}--capitalize`;
    }
    if (color) {
      name += ` ${nameBase}--color-${color}`;
    }
    if (size) {
      name += ` ${nameBase}--size-${size}`;
    }

    return name;
  };

  return (
    <Component className={getClassName()}> {children} </Component>
  );
};

Typography.COLORS = {
  INHERIT: 'inherit',
  WHITE: 'white',
  MALDIVIAN_SAND_WHITE: 'maldivian-sand-white',
  CREAM_WHITE: 'cream-white',
  ALABASTER_WHITE: 'alabaster-white',
  DARK_ALABASTER_WHITE: 'dark-alabaster-white',
  SPRING_WOOD_WHITE: 'spring-wood-white',
  BLACK: 'black',
  BLACKBOARD_BLACK: 'blackboard-black',
  DUSTY_GRAY: 'dusty-gray',
  MORNING_FOG_GRAY: 'morning-fog-gray',
  CONCRETE_GRAY: 'concrete-gray',
  STEEL_GRAY: 'steel-gray',
  PLACEHOLDER_GRAY: 'placeholder-gray',
  BRIGHT_SKY_BLUE: 'bright-sky-blue',
  RASPBERRY_RED: 'raspberry-red',
  GARDEN_GREEN: 'garden-green',
  SUNSET_ORANGE: 'sunset-orange',
  AMETHYST_VIOLET: 'amethyst-violet',
};

Typography.WEIGHTS = {
  INHERIT: 'inherit', LIGHT: 'light', NORMAL: 'normal', SEMIBOLD: 'semibold', BOLD: 'bold',
};

Typography.SIZES = {
  PX10: 10, PX11: 11, PX12: 12, PX14: 14, PX18: 18, INHERIT: 'inherit',
};

Typography.COMPONENTS = {
  SPAN: 'span',
  P: 'p',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

Typography.defaultProps = {
  weight: 'inherit',
  className: '',
  capitalize: false,
  uppercase: false,
  component: 'span',
  color: 'inherit',
  size: 14,
};

Typography.propTypes = {
  /**
   * Set the weight on the component.
   */
  weight: PropTypes.oneOf(Object.values(Typography.WEIGHTS)),
  /**
   * Set the font size on the component.
   */
  size: PropTypes.oneOf(Object.values(Typography.SIZES)),
  /**
   * Set the color on the component.
   */
  color: PropTypes.oneOf(Object.values(Typography.COLORS)),
  /**
   * The content of the component.
   */
  children: PropTypes.node.isRequired,
  /**
   * Extend CSS class applied to the component.
   */
  className: PropTypes.string,
  /**
   *  Controls whether the Typography is capitalized or not.
   */
  capitalize: PropTypes.bool,
  /**
   *  Controls whether the Typography is uppercase or not.
   */
  uppercase: PropTypes.bool,
  /**
   * The string to use as DOM element or a component. By default, it maps the variant to a span.
   */
  component: PropTypes.oneOf(Object.values(Typography.COMPONENTS)),
};

module.exports = Typography;
