/* eslint-disable react/sort-comp,jsx-a11y/no-noninteractive-element-interactions,react/no-array-index-key,react/jsx-no-bind */
const React = require('react');

module.exports = class TypeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.iconClasses = {
      Call: 'wethod-icon-phone',
      Email: 'wethod-icon-mail',
      Meeting: 'wethod-icon-meeting',
    };
  }

  getIconClass(name) {
    return this.iconClasses[name];
  }

  isCurrent(type) {
    return this.props.current === type.id;
  }

  getTypeClass(type) {
    let name = 'timeline-event-type-select__type';
    if (this.isCurrent(type)) name += ' timeline-event-type-select__type--selected';
    return name;
  }

  getTypes() {
    return this.props.types.map((type, index) => (
      <li
        key={index}
        className={this.getTypeClass(type)}
        onClick={() => this.props.updateForm({ type: type.id })}>
        <div className={this.getIconClass(type.label)} />
      </li>
    ));
  }

  render() {
    return (
      <div className="timeline-event-type-select-container">
        <ul className="timeline-event-type-select">
          {this.getTypes()}
        </ul>
      </div>
    );
  }
};
