// Service to Search, sort, order, slice on intercompany activities (no fetch required)

const IntercompanyActivityFilter = {
  sortableFields: (item) => ({
    name: item.client_project?.name || '',
    job_order: item.client_project?.job_order || '',
    task: item.client_task?.name || '',
    supplier_company: item.supplier_company?.name || '',
    recipient: item.recipient ? `${item.recipient?.name} ${item.recipient?.surname}` : '',
  }),
  filter: (items, search, sort, order, offset, limit) => {
    let filteredActivities = items;

    if (search) {
      // Search on activity client_project name, client_project job_order, client_task name, supplier_company company_name
      const searchLower = search.toLowerCase();
      filteredActivities = filteredActivities.filter((activity) => {
        const clientProjectName = activity.client_project?.name.toLowerCase() || '';
        const clientProjectJobOrder = activity.client_project?.job_order?.toLowerCase() || '';
        const clientTaskName = activity.client_task?.name.toLowerCase() || '';
        const supplierCompanyName = activity.supplier_company?.name.toLowerCase() || '';

        return clientProjectName.includes(searchLower)
          || clientProjectJobOrder.includes(searchLower)
          || clientTaskName.includes(searchLower)
          || supplierCompanyName.includes(searchLower);
      });
    }

    if (order) {
      // Sort on client_project name, client_project job_order, client_task name, company name
      filteredActivities = filteredActivities.sort((a, b) => {
        const aValue = IntercompanyActivityFilter.sortableFields(a)[order];
        const bValue = IntercompanyActivityFilter.sortableFields(b)[order];

        if (sort === 'asc') {
          return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
      });
    }

    // Keep all items from start to limit
    return filteredActivities.slice(0, offset + limit);
  },
};

module.exports = IntercompanyActivityFilter;
