'use strict';

Wethod.module('SettingsApp', function (SettingsApp, Wethod, Backbone, Marionette, $) {
  this.BudgetTemplateModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/budgettemplate/',
  });
  this.BudgetTemplateCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: APIURL + '/budgettemplate/',
    model: SettingsApp.BudgetTemplateModel,
  });

  var API = {
    getTemplate: function () {
      var budgetTemplates = new SettingsApp.BudgetTemplateCollection();
      var defer = $.Deferred();

      budgetTemplates.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(new SettingsApp.BudgetTemplateCollection());
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    saveTemplate: function (options) {
      var budgetTemplate = new SettingsApp.BudgetTemplateModel(options);
      var defer = $.Deferred();

      budgetTemplate.save(null, {
        success: function (model, response) {
          if (response.code === 201 || response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteTemplate: function (options) {
      var budgetTemplate = new SettingsApp.BudgetTemplateModel(options);
      var defer = $.Deferred();

      budgetTemplate.destroy({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('company:get:templates', function () {
    return API.getTemplate();
  });
  Wethod.reqres.setHandler('company:save:template', function (options) {
    return API.saveTemplate(options);
  });
  Wethod.reqres.setHandler('company:delete:template', function (options) {
    return API.deleteTemplate(options);
  });
});
