const React = require('react');

const CopyIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_773_50)">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.5 3.5L3.5 2.5C3.5 1.94772 3.94771 1.5 4.5 1.5L14 1.5C14.5523 1.5 15 1.94772 15 2.5V12C15 12.5523 14.5523 13 14 13H13L13 5.5C13 4.39543 12.1046 3.5 11 3.5L3.5 3.5ZM2.5 3.5L2.5 2.5C2.5 1.39543 3.39543 0.5 4.5 0.5H14C15.1046 0.5 16 1.39543 16 2.5V12C16 13.1046 15.1046 14 14 14H13V14.5C13 15.6046 12.1046 16.5 11 16.5L2 16.5C0.89543 16.5 0 15.6046 0 14.5V5.5C0 4.39543 0.89543 3.5 2 3.5H2.5ZM2.5 4.5L2 4.5C1.44771 4.5 1 4.94772 1 5.5L1 14.5C1 15.0523 1.44772 15.5 2 15.5L11 15.5C11.5523 15.5 12 15.0523 12 14.5V14V13L12 5.5C12 4.94772 11.5523 4.5 11 4.5L3.5 4.5H2.5Z" fill="#212121" />
    </g>
    <defs>
      <clipPath id="clip0_773_50">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = CopyIcon;
