const React = require('react');
const isEqual = require('react-fast-compare');

const FiltersTransformer = ({
  children, ...rest
}) => {
  const getSingleValueFilter = (name, filters) => filters[name];

  const getRangeStandardFilter = (name, filters) => {
    const minKey = `${name}-from`;
    const maxKey = `${name}-to`;
    const minValue = filters[minKey];
    const maxValue = filters[maxKey];
    const filter = {};

    if (minValue !== undefined) {
      filter.min = minValue;
    }
    if (maxValue !== undefined) {
      filter.max = maxValue;
    }

    return filter;
  };

  const getListStandardFilter = (name, filters) => {
    const values = filters[name];

    if (values) {
      return values.split(',');
    }
    return [];
  };

  const formatFiltersToStandard = (filters) => ({
    category: getSingleValueFilter('employee-category', filters),
    mode: getSingleValueFilter('timesheet-mode', filters),
    date: getRangeStandardFilter('date', filters),
    updated: getRangeStandardFilter('update-date', filters),
    project: getListStandardFilter('project', filters),
    employee: getListStandardFilter('employee', filters),
    client: getListStandardFilter('client', filters),
    'updated-by': getListStandardFilter('updated-by', filters),
  });

  /**
   * Formats given filters in a way they can be easily used by AdvancedSearch.
   * @param filters {{}}
   * @returns {{}}
   */
  const getStandardFilters = (filters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersToStandard(filters),
    };

    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue.length === 0
        || isEqual(filterValue, {});
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  const getRangeFromStandard = (name, attribute, standardFilters) => (standardFilters[name]
    ? standardFilters[name][attribute]
    : null);

  const getListFromStandard = (name, standardFilters) => (standardFilters[name]
    ? standardFilters[name].join()
    : null);

  const formatFiltersFromStandard = (standardFilters) => ({
    'employee-category': getSingleValueFilter('category', standardFilters),
    'timesheet-mode': getSingleValueFilter('mode', standardFilters),
    'date-from': getRangeFromStandard('date', 'min', standardFilters),
    'date-to': getRangeFromStandard('date', 'max', standardFilters),
    'update-date-from': getRangeFromStandard('updated', 'min', standardFilters),
    'update-date-to': getRangeFromStandard('updated', 'max', standardFilters),
    project: getListFromStandard('project', standardFilters),
    employee: getListFromStandard('employee', standardFilters),
    client: getListFromStandard('client', standardFilters),
    'updated-by': getListFromStandard('updated-by', standardFilters),
  });

  /**
   * Returns flattened filters, this way they can be easily converted in query string.
   * @param standardFilters {{}}
   * @returns {{}}
   */
  const getFiltersFromStandard = (standardFilters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersFromStandard(standardFilters),
    };
    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue === null || filterValue === '';
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  return React
    .cloneElement(children, {
      ...rest,
      getStandardFilters,
      getFiltersFromStandard,
    });
};

module.exports = FiltersTransformer;
