const React = require('react');
const Switch = require('../../../../../../../common/react/inputs/Switch/Switch.react');
const IconCell = require('../Cell/IconCell.react');

const AreaSwitch = ({ save, editable, checked, id }) => {
  function onChange(e) {
    save({ enabled: e.target.checked });
  }
  return (
    <IconCell label="Area switch">
      <Switch name={String(id)}
        id={String(id)}
        onChange={onChange}
        disabled={!editable}
        checked={checked} />
    </IconCell>
  );
};

module.exports = AreaSwitch;
