/* eslint-disable react/prop-types,react/sort-comp,jsx-a11y/anchor-is-valid,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
const React = require('react');

module.exports = class CollapsibleCard extends React.Component {
  handleEditClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onEditClick();
  }

  handleSaveClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSaveClick();
  }

  getEditButton() {
    const { editable } = this.props;
    const allowEdit = this.props.allowEdit ? this.props.allowEdit : false;
    if (allowEdit) {
      if (editable) {
        return (
          <a href="#"
            className="clients__delete-button wethod-icon-button"
            onClick={this.handleSaveClick.bind(this)}>
            <div className="wethod-icon-medium-checked" />
          </a>
        );
      }

      return (
        <a href="#"
          className="clients__delete-button wethod-icon-button"
          onClick={this.handleEditClick.bind(this)}>
          <div className="wethod-icon-medium-edit" />
        </a>
      );
    }
    return null;
  }

  handleHeaderClick(e) {
    e.stopPropagation();
    this.props.toggleCollapsed();
  }

  getArrowClassName() {
    let name = 'wethod-collapsible-card__arrow wethod-icon-medium-dropdown';
    if (this.props.collapsed) name += ' wethod-icon-medium-dropdown--collapsed';
    return name;
  }

  getContent() {
    if (!this.props.collapsed) return <div className="wethod-collapsible-card__content">{this.props.children}</div>;
    return null;
  }

  render() {
    return (
      <div className="wethod-collapsible-card">
        <div className="wethod-collapsible-card__header"
          onClick={this.handleHeaderClick.bind(this)}>
          <div className="wethod-collapsible-card__header-left">
            <span className="wethod-collapsible-card__title">{this.props.title}</span>
          </div>
          <div className="wethod-collapsible-card__header-right">
            <span className="wethod-collapsible-card__title">{this.props.feedback}</span>
            {this.getEditButton()}
            <span className={this.getArrowClassName()} />
          </div>
        </div>
        {this.getContent()}
      </div>
    );
  }
};
