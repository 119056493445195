/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,default-case,consistent-return */

const React = require('react');
const HourlyEditor = require('./PlanEditorHourly.react');
const DailyEditor = require('./PlanEditorDaily.react');
const WeeklyEditor = require('./PlanEditorWeekly.react');

module.exports = class PlanEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBlocks: [],
    };
    this.lastBlockOvered = null;
  }

  componentDidUpdate(prevProps) {
    const stoppedSelection = prevProps.editorMode !== null && this.props.editorMode === null;
    if (stoppedSelection) {
      this.setState({ selectedBlocks: [] });
    }
  }

  isHighlighted(id) {
    return this.state.selectedBlocks.filter((blockId) => blockId === id).length > 0;
  }

  getAugmentedChanges(changes) {
    return changes.map((change) => ({
      ...change,
      person: this.props.person.id,
      project_id: this.props.selectedProject.id,
    }));
  }

  updateChanges(changes) {
    this.props.updateChanges(this.getAugmentedChanges(changes));
  }

  onBlockClick(changes, mode) {
    this.props.updateHours(this.getAugmentedChanges(changes, mode));
  }

  onMouseOverBlock(id, changes) {
    const highlighting = this.props.editorMode !== null;
    const { selectedBlocks } = this.state;
    if (highlighting) {
      const alreadyHighlighted = this.isHighlighted(id);
      const newBlock = this.lastBlockOvered !== id;

      if (newBlock) { // Moving cursor inside the same block is a no-op
        if (alreadyHighlighted) {
          this.setState({ selectedBlocks: selectedBlocks.filter((currentId) => currentId !== id) });
        } else {
          this.setState({ selectedBlocks: selectedBlocks.concat(id) });
        }
        this.updateChanges(changes);
      }
      this.lastBlockOvered = id;
    }
  }

  onMouseOut() {
    this.lastBlockOvered = null;
  }

  render() {
    switch (this.props.zoom) {
      case 'hour':
        return (
          <HourlyEditor {...this.props}
            selectedBlocks={this.state.selectedBlocks}
            onMouseOverBlock={this.onMouseOverBlock.bind(this)}
            isHighlighted={this.isHighlighted.bind(this)}
            onBlockClick={this.onBlockClick.bind(this)}
            onMouseOut={this.onMouseOut.bind(this)} />
        );
      case 'day':
        return (
          <DailyEditor {...this.props}
            selectedBlocks={this.state.selectedBlocks}
            onMouseOverBlock={this.onMouseOverBlock.bind(this)}
            isHighlighted={this.isHighlighted.bind(this)}
            onBlockClick={this.onBlockClick.bind(this)}
            onMouseOut={this.onMouseOut.bind(this)} />
        );
      case 'week':
        return (
          <WeeklyEditor {...this.props}
            selectedBlocks={this.state.selectedBlocks}
            onMouseOverBlock={this.onMouseOverBlock.bind(this)}
            isHighlighted={this.isHighlighted.bind(this)}
            onBlockClick={this.onBlockClick.bind(this)}
            onMouseOut={this.onMouseOut.bind(this)} />
        );
    }
  }
};
