const React = require('react');
const Notes = require('../containers/NoteModal');
const Error = require('./ErrorModal.react');

/**
 * Decides which modal to show.
 * @param toShow
 * @param modalData
 * @param closeModal
 * @return {*}
 * @constructor
 */
const Modal = ({ toShow, modalData, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case Modal.NOTES_MODAL:
        return <Notes data={modalData} onCancelClick={closeModal} />;
      case Modal.ERROR_MODAL:
        return <Error closeModal={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};

Modal.NOTES_MODAL = 'notes';
Modal.ERROR_MODAL = 'error';

module.exports = Modal;
