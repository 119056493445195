const React = require('react');
const PropTypes = require('prop-types');
const TextPreview = require('../../../../TextPreview.react');

const Comment = ({ text }) => (
  <div className="widget-rating-overview-item__comment"><TextPreview text={text} /></div>
);

Comment.defaultProps = {
  text: '',
};

Comment.propTypes = {
  /**
   * Text to display.
   */
  text: PropTypes.string,
};

module.exports = Comment;
