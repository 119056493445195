/* eslint-disable no-case-declarations,class-methods-use-this */
const moment = require('moment');
const constants = require('./constants');
const searchFilter = require('./services/searchFilter');
const projectIdFilter = require('./services/projectIdFilter');
const sortPs = require('./services/projectStatusSort');

class ProjectStatusReducer {
  constructor(state) {
    const keyword = state.projectFilterId ? `id:${state.projectFilterId}` : '';
    const defaultOwnershipFilter = state.projectFilterId ? 'all' : 'pm';
    this.state = {
      ...state,
      date: moment().startOf('isoWeek'),
      filteredProjects: [],
      keyword,
      ownership: defaultOwnershipFilter,
      internationalCalendar: false,
      isLoadingProjects: false,
      savingProjectStatus: [],
      modal: null,
      modalData: null,
      riskSelect: null,
      projectsInfo: [],
      focusedProjectId: null,
      isLoadingProjectInfo: [],
      sortBy: {
        col: 'name',
        order: 'asc',
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Return the project id of the filter, if id filter is applied, null instead
   * @param keyword
   * @returns {null|number}
   */
  getProjectIdFilter(keyword) {
    const projectIdFilterPattern = new RegExp(/^id:\d+$/);
    const idPattern = new RegExp(/\d+/);
    const id = idPattern.exec(keyword) ? parseInt(idPattern.exec(keyword)[0]) : null;
    if (projectIdFilterPattern.test(keyword)) { // If a special ID filter is being applied
      return id;
    }
    return null;
  }

  /**
   * Returns all projects filtered by filters.
   *
   * @param filters
   * @param projects
   */
  getFilteredProjects(filters, projects) {
    // Search by id or by keyword
    return filters.idFilter ? projectIdFilter(filters.idFilter, projects)
      : searchFilter(filters.search, projects, filters.idFilter);
  }

  reduxReducer(state = this.state, action) {
    let idFilter = state.projectFilterId;
    switch (action.type) {
      case constants.FILTER_PROJECTS:
        idFilter = this.getProjectIdFilter(action.keyword);
        let filters = {
          search: action.keyword,
          idFilter,
        };
        let filteredProjects = sortPs(
          this.getFilteredProjects(filters, state.projects),
          state.sortBy.col,
          state.sortBy.order,
        );
        return {
          ...state,
          keyword: action.keyword,
          projectFilterId: idFilter,
          filteredProjects,
        };
      case constants.SORT_PROJECTS:
        return {
          ...state,
          filteredProjects: sortPs(state.filteredProjects, action.col, action.order),
          projects: sortPs(state.projects, action.col, action.order),
          sortBy: {
            col: action.col,
            order: action.order,
          },
        };
      case constants.GET_PROJECTS_REQUEST:
        return {
          ...state,
          isLoadingProjects: true,
          projects: [],
          filteredProjects: [],
          projectsInfo: [],
          ownership: action.ownership,
        };
      case constants.CHANGE_WEEK:
        return {
          ...state,
          date: action.date,
        };
      case constants.GET_PROJECTS_SUCCESS:
        const sortedProject = sortPs(action.projects, state.sortBy.col, state.sortBy.order);
        filters = {
          search: state.keyword,
          idFilter: state.projectFilterId,
        };
        filteredProjects = this.getFilteredProjects(filters, sortedProject);
        return {
          ...state,
          isLoadingProjects: false,
          projects: sortedProject,
          filteredProjects,
        };
      case constants.SAVE_RISK_REQUEST: {
        const projects = state.projects.map((project) => {
          if (project.project.id === action.projectStatus.project) {
            return {
              ...project,
              risk: action.projectStatus.risk,
            };
          }
          return project;
        });
        filters = {
          search: state.keyword,
          idFilter: state.projectFilterId,
        };
        filteredProjects = this.getFilteredProjects(filters, projects);
        return {
          ...state,
          projects,
          filteredProjects,
          savingProjectStatus: state.savingProjectStatus.concat(action.projectStatus.project),
        };
      }
      case constants.SAVE_PROJECT_STATUS_BY_AREA_REQUEST:
      case constants.SAVE_PROJECT_STATUS_REQUEST:
        return {
          ...state,
          savingProjectStatus: state.savingProjectStatus.concat(action.projectId),
        };
      case constants.SAVE_RISK_SUCCESS:
        return {
          ...state,
          savingProjectStatus: state.savingProjectStatus
            .filter((projectId) => projectId !== action.projectStatus.project),
        };
      case constants.SAVE_PROJECT_STATUS_BY_AREA_SUCCESS:
      case constants.SAVE_PROJECT_STATUS_SUCCESS:
        const projects = state.projects.map((project) => {
          if (project.project.id === action.projectStatus.project) {
            const nextProject = { ...project };
            // set back default risk if days_left gets deleted
            if (action.projectStatus.days_left === null) {
              nextProject.risk = state.risks.filter((risk) => risk.is_default)[0].id;
            }
            // reset areas if compiled by totals
            if (action.projectStatus.days_left !== undefined) {
              nextProject.days_left = action.projectStatus.days_left;
              nextProject.areas = project.areas.map((area) => ({
                ...area,
                days_left: null,
              }));
            }
            // reset total days_left if compiled by area
            if (action.projectStatus.areas !== undefined) {
              nextProject.days_left = action.projectStatus.areas
                .reduce((sum, area) => sum + area.days_left, 0);
              nextProject.areas = project.areas.map((area) => {
                const daysLeft = action.projectStatus.areas
                  .filter((newArea) => area.id === newArea.id)[0].days_left;

                return {
                  ...area,
                  days_left: daysLeft,
                };
              });
            }
            return nextProject;
          }
          return project;
        });
        filters = {
          search: state.keyword,
          idFilter: state.projectFilterId,
        };
        filteredProjects = this.getFilteredProjects(filters, projects);
        return {
          ...state,
          projects,
          filteredProjects,
          savingProjectStatus: state.savingProjectStatus
            .filter((projectId) => projectId !== action.projectStatus.project),
        };
      case constants.GET_INTERNATIONAL_CALENDAR_SETTINGS_SUCCESS:
        return {
          ...state,
          internationalCalendar: action.enabled,
        };
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      }
      case constants.SHOW_PROJECT_AREAS_MODAL: {
        return {
          ...state,
          modal: 'project-areas',
          modalData: action.data,
        };
      }
      case constants.SHOW_RISK_SELECT:
        return {
          ...state,
          riskSelect: {
            anchorEl: action.anchorEl,
            data: action.data,
          },
        };
      case constants.CLOSE_RISK_SELECT:
        return {
          ...state,
          riskSelect: null,
        };

      case constants.GET_PROJECT_INFO_REQUEST:
        return {
          ...state,
          isLoadingProjectInfo: state.isLoadingProjectInfo.concat(action.projectId),
        };
      case constants.GET_PROJECT_INFO_SUCCESS:
        return {
          ...state,
          isLoadingProjectInfo: state.isLoadingProjectInfo
            .filter((project) => project !== action.projectInfo.project.id),
          projectsInfo: state.projectsInfo.concat(action.projectInfo),
        };
      case constants.SHOW_SIDEBAR:
        return {
          ...state,
          focusedProjectId: action.projectId,
        };
      case constants.CLOSE_SIDEBAR:
        return {
          ...state,
          focusedProjectId: null,
        };
      default:
        return state;
    }
  }
}

module.exports = ProjectStatusReducer;
