const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/budgettemplate`;
  },

  updateTemplate() {
    const defer = $.Deferred();

    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  deleteTemplate() {
    const defer = $.Deferred();

    $.when(this.erase()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
