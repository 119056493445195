const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/Sales.react');

const mapStateToProps = (state) => ({
  won_projects_count: state.won_projects_count,
  weekly_target: state.weekly_target,
  weekly_dynamic_target: state.weekly_dynamic_target,
  won_projects_value: state.won_projects_value,
  prev_week_won_projects_value: state.prev_week_won_projects_value,
  two_weeks_ago_won_projects_value: state.two_weeks_ago_won_projects_value,
  prev_year_won_projects_value: state.prev_year_won_projects_value,
  pipeline_value: state.pipeline_value,
  prev_week_pipeline_value: state.prev_week_pipeline_value,
  two_weeks_ago_pipeline_value: state.two_weeks_ago_pipeline_value,
  prev_year_pipeline_value: state.prev_year_pipeline_value,
  prev_year_prev_week_pipeline_value: state.prev_year_prev_week_pipeline_value,
  prev_year_two_weeks_before_pipeline_value: state.prev_year_two_weeks_before_pipeline_value,
});

module.exports = connect(mapStateToProps)(Component);
