const { connect } = require('react-redux');
const Component = require('../components/PermissionManager.react');

const mapStateToProps = (state) => ({
  editStatusPermission: state.permissions.edit,
  editOtherStatusPermission: state.permissions.edit_other,
  employeeId: state.employeeId,
});

module.exports = connect(mapStateToProps)(Component);
