const React = require('react');
const PropTypes = require('prop-types');
const Tag = require('./Tag.react');

/**
 * Highlight tag component, customizable.
 * It is a tag that has a blink animation to highlight it.
 * To trigger the animation, use the prop highlight set to true.
 * The tag can be deleted providing the onDelete prop,
 * that makes a cross icon visible and clickable.
 * The content of the tag is given as children.
 */
class HighlightTag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      highlight: this.props.highlight ? this.props.highlight : false,
    };
    this.animationDuration = 500;
    this.timer = null;
  }

  componentDidUpdate() {
    if (this.state.highlight !== this.props.highlight) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ highlight: this.props.highlight }, this.resetHighlightAnimation);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  getClass() {
    let name = '';
    if (this.state.highlight) name += 'wethod-tag-blink';
    if (this.props.className) name += ` ${this.props.className}`;
    return name;
  }

  /**
   * Waits until the end of the highlight animation an then reset the state.
   */
  resetHighlightAnimation() {
    this.timer = setTimeout(() => {
      this.setState({ highlight: false }, () => {
      });
    }, this.animationDuration);
  }

  render() {
    return (
      <Tag id={this.props.id}
        className={this.getClass()}
        onDelete={this.props.onDelete}
        disabled={this.props.disabled}>
        {this.props.children}
      </Tag>
    );
  }
}

HighlightTag.propTypes = {
  /**
   * Id of the tag. It will be given as argument for delete event.
   * @see Tag
   */
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Content shown in the tag. It can be a simple string or an element
   * @see Tag
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  /**
   * Whether the tag should be disabled or not, changing its behaviour and layout.
   * When it is disabled, it cannot be deleted, and the delete icon will not be shown.
   * @see Tag
   */
  disabled: PropTypes.bool,
  /**
   * Whether the tag should be highlighted or not, changing its layout.
   * When highlight is set to true, the tag will start a short blink animation.
   */
  highlight: PropTypes.bool,
  /**
   * The class to be added to the component style.
   * @see Tag
   */
  className: PropTypes.string,
  /**
   * Function handling the click on the delete icon.
   * When this is not given, the delete icon will not be shown.
   * This has no effect if the tag is disabled.
   * @param id {number || string} the given id
   * @param content {element || string} the given children
   * @param event {event} the event triggered by click
   * @see Tag
   */
  onDelete: PropTypes.func,
};

HighlightTag.defaultProps = {
  id: null,
  disabled: false,
  highlight: false,
  className: null,
  onDelete: undefined,
};

module.exports = HighlightTag;
