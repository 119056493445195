const React = require('react');
const Alert = require('./Alert.react');
const EmptyRow = require('../containers/EmptyRow');

module.exports = class AlertsList extends React.Component {
  constructor(props) {
    super(props);

    this.getAlerts = this.getAlerts.bind(this);
  }

  getAlerts() {
    if (this.props.alerts.length > 0) {
      return this.props.alerts.map((alert) => (
        <Alert key={alert.id}
          id={alert.id}
          {...alert} />
      ));
    }
    return <EmptyRow />;
  }

  render() {
    return (
      <div>
        <div className="alerts-engine__alerts">
          {this.getAlerts()}
        </div>
      </div>
    );
  }
};
