/* eslint-disable max-len */
const { connect } = require('react-redux');
const Component = require('../../../components/sections/Time/Time.react');

const colors = ['#EF5D60', '#F4CD6C', '#F48668', '#FFEE72', '#F1A66A'];

const mapStateToProps = (state) => ({
  chargeable_hours: state.chargeable_hours,
  chargeable_hours_perc: state.chargeable_hours_perc,
  chargeable_hours_prev_week_perc: state.chargeable_hours_prev_week_perc,
  chargeable_hours_prev_year_perc: state.chargeable_hours_prev_year_perc,
  chargeable_hours_prev_year_two_weeks_before_perc: state.chargeable_hours_prev_year_two_weeks_before_perc,
  chargeable_hours_prev_year_prev_week_perc: state.chargeable_hours_prev_year_prev_week_perc,
  chargeable_hours_two_weeks_ago_perc: state.chargeable_hours_two_weeks_ago_perc,
  chargeability_target_perc: state.chargeability_target_perc,
  groups: state.worked_hours_perc_by_group
    .filter((group) => group.worked_hours_perc)
    .map((group, index) => ({
      ...group,
      color: colors[index % colors.length],
    })),
});

module.exports = connect(mapStateToProps)(Component);
