/* eslint-disable no-use-before-define */
const constants = require('./constants');

Wethod.module('ReactBind', () => {
  module.exports.switchClientType = (key) => ({
    type: constants.SWITCH_CLIENT_TYPE,
    key,
  });

  module.exports.closeModal = () => ({
    type: constants.CLOSE_MODAL,
  });

  module.exports.showDeletePersonModal = (item) => ({
    type: constants.SHOW_DELETE_PERSON_MODAL,
    item,
  });

  module.exports.showDeleteCompanyModal = (item) => ({
    type: constants.SHOW_DELETE_COMPANY_MODAL,
    item,
  });

  module.exports.showSidebar = (item) => ({
    type: constants.SHOW_SIDEBAR,
    item,
  });

  module.exports.closeSidebar = () => ({
    type: constants.CLOSE_SIDEBAR,
  });

  module.exports.showProfilePicModal = () => ({
    type: constants.SHOW_MODAL_PROFILE_PIC,
  });

  module.exports.filterClients = (keyword) => ({
    type: constants.FILTER_CLIENTS,
    keyword,
  });

  module.exports.orderClients = (col, order) => ({
    type: constants.ORDER_CLIENTS,
    key: col,
    order,
  });

  module.exports.loadMoreClients = (size, page) => ({
    type: constants.LOAD_CLIENTS,
    size,
    page,
  });

  module.exports.addClient = () => ({
    type: constants.ADD_CLIENT,
  });

  module.exports.removeClient = () => ({
    type: constants.REMOVE_CLIENT,
  });

  const savePersonSuccess = (person) => ({
    type: constants.PERSON_SAVE_SUCCESS,
    person,
  });

  const savePersonRequest = () => ({
    type: constants.PERSON_SAVE_REQUEST,
  });

  module.exports.savePerson = (person) => (dispatch) => {
    dispatch(savePersonRequest());
    const personModel = new Wethod.FinanceApp.Client.ClientModel({
      ...person,
      client: person.client.id,
    });
    Wethod.request('save:finance:client', personModel)
      .then((response) => {
        // We need to add the phone in the specific request after we know the id of the client we
        // have created
        if (person.phones && person.phones.number && person.phones.number !== '') {
          dispatch(addPersonPhone(response.attributes.id, person.phones));
        }
        dispatch(savePersonSuccess({
          ...response.attributes,
          client: {
            ...response.attributes.client,
            corporate_name: person.client.corporate_name,
          },
        }));
      });
  };

  const updatePersonSuccess = (person) => ({
    type: constants.PERSON_UPDATE_SUCCESS,
    person,
  });

  const updatePersonRequest = (person) => ({
    type: constants.PERSON_UPDATE_REQUEST,
    person,
  });

  module.exports.updatePerson = (person) => (dispatch) => {
    dispatch(updatePersonRequest(person));
    if (person.id) {
      const personModel = new Wethod.FinanceApp.Client.ClientModel({
        ...person,
        client: person.client.id,
      });
      Wethod.request('save:finance:client', personModel)
        .then(() => {
          dispatch(updatePersonSuccess(person));
        });
    } else {
      dispatch(updatePersonSuccess(person));
    }
  };

  const deletePersonSuccess = (id) => ({
    type: constants.PERSON_DELETE_SUCCESS,
    id,
  });

  const deletePersonRequest = () => ({
    type: constants.PERSON_DELETE_REQUEST,
  });

  module.exports.deletePerson = (person) => (dispatch) => {
    dispatch(deletePersonRequest());
    if (person.id) {
      const personModel = new Wethod.FinanceApp.Client.ClientModel({
        ...person,
      });
      Wethod.request('delete:finance:client', personModel)
        .then(() => {
          dispatch(deletePersonSuccess(person.id));
        });
    } else {
      dispatch(deletePersonSuccess());
    }
  };

  const addPersonPhoneSuccess = (clientId, phone) => ({
    type: constants.PERSON_PHONE_ADD_SUCCESS,
    clientId,
    phone,
  });

  const addPersonPhoneRequest = (phone) => ({
    type: constants.PERSON_PHONE_ADD_REQUEST,
    phone,
  });

  const addPersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(addPersonPhoneRequest(phone));
    Wethod.request('add:finance:person:phone',
      {
        ...phone,
        customer_id: clientId,
      })
      .then((response) => {
        dispatch(addPersonPhoneSuccess(clientId, response.data));
      });
  };

  module.exports.addPersonPhone = addPersonPhone;

  const updatePersonPhoneSuccess = (clientId, phone) => ({
    type: constants.PERSON_PHONE_EDIT_SUCCESS,
    clientId,
    phone,
  });

  const updatePersonPhoneRequest = (phone) => ({
    type: constants.PERSON_PHONE_EDIT_REQUEST,
    phone,
  });

  module.exports.updatePersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(updatePersonPhoneRequest(phone));
    const model = new Wethod.FinanceApp.Client.ClientPersonPhoneModel(phone);
    Wethod.request('update:finance:person:phone', model)
      .then(() => {
        dispatch(updatePersonPhoneSuccess(clientId, phone));
      });
  };

  module.exports.deletePersonPhone = (clientId, phone) => (dispatch) => {
    dispatch(updatePersonPhoneRequest(phone));
    Wethod.request('delete:finance:person:phone', phone)
      .then(() => {
        dispatch(updatePersonPhoneSuccess(clientId, null));
      });
  };

  const saveCompanySuccess = (company) => ({
    type: constants.COMPANY_SAVE_SUCCESS,
    company,
  });

  const saveCompanyRequest = () => ({
    type: constants.COMPANY_SAVE_REQUEST,
  });

  module.exports.saveCompany = (company) => (dispatch) => {
    dispatch(saveCompanyRequest());
    const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
      ...company,
      group: company.group ? company.group.id : null,
      bank_account: company.bank_account ? company.bank_account.id : null,
      vat_rate: company.vat_rate ? company.vat_rate.id : null,
      country: company.country ? company.country.name : null,
      province: company.province ? company.province.name : null,
      payment_term: company.payment_term ? company.payment_term.id : null,
      intent_date: company.intent_date ? company.intent_date : null,
    });
    Wethod.request('save:finance:client', model)
      .then((response) => {
        dispatch(saveCompanySuccess(response.attributes));
      });
  };

  const updateCompanySuccess = (company) => ({
    type: constants.COMPANY_UPDATE_SUCCESS,
    company,
  });

  const updateCompanyRequest = () => ({
    type: constants.COMPANY_UPDATE_REQUEST,
  });

  module.exports.updateCompany = (company) => (dispatch) => {
    dispatch(updateCompanyRequest());
    if (company.id) {
      const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
        ...company,
        group: company.group ? company.group.id : null,
        bank_account: company.bank_account ? company.bank_account.id : null,
        vat_rate: company.vat_rate ? company.vat_rate.id : null,
        country: company.country ? company.country.name : null,
        province: company.province ? company.province.name : null,
        payment_term: company.payment_term ? company.payment_term.id : null,
        intent_date: company.intent_date ? company.intent_date : null,
      });
      Wethod.request('save:finance:client', model)
        .then(() => {
          dispatch(updateCompanySuccess(company));
        });
    } else {
      dispatch(updateCompanySuccess(company));
    }
  };

  const deleteCompanySuccess = (id) => ({
    type: constants.COMPANY_DELETE_SUCCESS,
    id,
  });

  const deleteCompanyRequest = () => ({
    type: constants.COMPANY_DELETE_REQUEST,
  });

  const deleteCompanyFailure = (projects, customers) => ({
    type: constants.COMPANY_DELETE_FAILURE,
    projects,
    customers,
  });

  module.exports.deleteCompany = (company) => (dispatch) => {
    dispatch(deleteCompanyRequest());
    if (company.id) {
      const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
        ...company,
      });
      Wethod.request('delete:finance:client', model)
        .then((response) => {
          if (response.code === 409) {
            dispatch(deleteCompanyFailure(response.data.projects, response.data.customers));
          } else {
            dispatch(deleteCompanySuccess(company.id));
          }
        });
    } else {
      dispatch(deleteCompanySuccess());
    }
  };

  const saveGroupSuccess = (group) => ({
    type: constants.GROUP_SAVE_SUCCESS,
    group,
  });

  const saveGroupRequest = () => ({
    type: constants.GROUP_SAVE_REQUEST,
  });

  module.exports.saveGroup = (group) => (dispatch) => {
    dispatch(saveGroupRequest());
    const model = new Wethod.FinanceApp.Client.CompanyGroupModel(group);
    Wethod.request('save:finance:company:group', model)
      .then((response) => {
        dispatch(dispatch(saveGroupSuccess(response.attributes)));
      });
  };

  const replaceClientCompanyRequest = () => ({
    type: constants.CLIENT_COMPANY_REPLACE_REQUEST,
  });

  module.exports.replaceClientCompany = (from, toId) => (dispatch) => {
    dispatch(replaceClientCompanyRequest());
    const options = {
      fromId: from.id,
      toId,
    };
    const switchProjectscClientRequest = Wethod.request('switch:finance:client:projects', options);
    const switchContactsClientRequest = Wethod.request('switch:finance:client:contacts', options);
    $.when(switchProjectscClientRequest, switchContactsClientRequest)
      .done(() => {
        const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
          ...from,
        });
        Wethod.request('delete:finance:client', model)
          .then((response) => {
            if (response.code === 409) {
              dispatch(deleteCompanyFailure());
            } else {
              dispatch(deleteCompanySuccess(from.id));
            }
          });
      });
  };
});
