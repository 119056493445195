module.exports.HOURS_UPDATE = 'HOURS_UPDATE';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.CONFIRM_DELETE_OPEN_MODAL = 'CONFIRM_DELETE_OPEN_MODAL';

module.exports.NOTE_OPEN_MODAL = 'NOTE_OPEN_MODAL';
module.exports.NOTE_SAVE_REQUEST = 'NOTE_SAVE_REQUEST';
module.exports.NOTE_SAVE_SUCCESS = 'NOTE_SAVE_SUCCESS';
module.exports.NOTE_SAVE_FAILURE = 'NOTE_SAVE_FAILURE';

module.exports.HOURS_SAVE_REQUEST = 'HOURS_SAVE_REQUEST';
module.exports.HOURS_SAVE_SUCCESS = 'HOURS_SAVE_SUCCESS';
module.exports.HOURS_SAVE_FAILURE = 'HOURS_SAVE_FAILURE';

module.exports.HOURS_DELETE_SUCCESS = 'HOURS_DELETE_SUCCESS';

module.exports.FAVORITE_ADD_REQUEST = 'FAVORITE_ADD_REQUEST';
module.exports.FAVORITE_ADD_SUCCESS = 'FAVORITE_ADD_SUCCESS';
module.exports.FAVORITE_REMOVE = 'FAVORITE_REMOVE';

module.exports.DATE_CHANGE_REQUEST = 'DATE_CHANGE_REQUEST';
module.exports.DATE_CHANGE_SUCCESS = 'DATE_CHANGE_SUCCESS';

module.exports.DAY_CHANGE = 'DATE_CHANGE';

module.exports.FILTER_PROJECTS = 'FILTER_PROJECTS';

module.exports.GET_PERIODICITY_REQUEST = 'GET_PERIODICITY_REQUEST';
module.exports.GET_PERIODICITY_SUCCESS = 'GET_PERIODICITY_SUCCESS';

module.exports.CHANGE_BU_FILTER = 'CHANGE_BU_FILTER';
