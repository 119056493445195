/* eslint-disable react/jsx-no-bind */
const React = require('react');

module.exports = class ZoomSwitcher extends React.Component {
  getItemClassName(key) {
    let name = 'wethod-tab-switcher__item';
    if (key === this.props.current) {
      name += ' wethod-tab-switcher__item--selected';
    }
    return name;
  }

  render() {
    return (
      <div className="wethod-tab-switcher wethod-tab-switcher--black">
        <button type="button"
          onClick={() => {
            this.props.handleClick('hour');
          }}
          className={this.getItemClassName('hour')}>
          Hourly
        </button>
        <button type="button"
          onClick={() => {
            this.props.handleClick('day');
          }}
          className={this.getItemClassName('day')}>
          Daily
        </button>
        <button type="button"
          onClick={() => {
            this.props.handleClick('week');
          }}
          className={this.getItemClassName('week')}>
          Weekly
        </button>
      </div>
    );
  }
};
