/* eslint-disable react/no-access-state-in-setstate,consistent-return,react/sort-comp,class-methods-use-this,no-param-reassign,no-shadow */
const React = require('react');
const Loading = require('./TrendsItemLoading.react');

module.exports = class TrendsItem extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      positive: '#4ED88D',
      neutral: '#48a5f9',
      negative: '#DB4D69',
    };

    const parentFunctions = {
      getPointLabel: props.getPointLabel,
      getLastIndex: this.getLastSignificantValueIndex.bind(this),
    };

    this.chartOptions = {
      props: {
        tooltipFormatter: props.tooltipFormatter,
      },
      chart: {
        type: 'spline',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacing: [20, 70, 10, 0],
      },
      title: {
        text: '',
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1000 * 24 * 3600 * 30 * 3, // 3 months interval
        dateTimeLabelFormats: {
          month: '%b \'%y',
        },
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0,
        labels: {
          style: {
            color: '#CCCBCC',
          },
          rotation: 0,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
          dataLabels: {
            props: parentFunctions, // needed to use component props in the formatter callback
            enabled: true,
            formatter: this.getPointLabel,
            style: {
              fontWeight: '400',
              fontSize: '13px',
              textShadow: 'none',
              textOutline: 0,
              color: this.getLastPointColor(),
            },
            crop: false,
            overflow: 'allow',
            align: 'left',
          },
        },
      },
      tooltip: {
        formatter(tooltip) {
          return tooltip.chart.userOptions.props.tooltipFormatter(this);
        },
      },
    };
  }

  getChartOptions() {
    return {
      ...this.chartOptions,
      series: this.getSeries(),
      legend: this.props.legend ? this.props.legend : { ...this.chartOptions.legend },
      xAxis: {
        ...this.chartOptions.xAxis,
        min: this.getMinDate(),
        max: this.getMaxDate(),
      },
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  componentDidUpdate(prevProps) {
    const haveWeeksUpdate = this.props.weeks.length !== prevProps.weeks.length;
    let hasSecondarySeries = false;
    let haveSecondarySeriesUpdate = false;
    if (this.props.secondarySeries && prevProps.secondarySeries) {
      hasSecondarySeries = true;
      haveSecondarySeriesUpdate = !this.props.isLoading && prevProps.isLoading;
    }
    if ((haveWeeksUpdate && !hasSecondarySeries) || ((haveWeeksUpdate
      || this.props.weeks.length > 0) && haveSecondarySeriesUpdate)) {
      this.renderChart();
    }
  }

  getClassName() {
    let style = 'backlog-trend';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  getMinDate() {
    const weekNumber = moment().weeks();
    const startWeek = moment().subtract(1, 'years').week(weekNumber).day(0)
      .subtract(3, 'weeks');
    const date = moment.utc(startWeek);

    return date.valueOf();
  }

  getMaxDate() {
    let week;

    if (this.props.secondarySeries) {
      week = moment().add(52, 'weeks').day(0);
    } else {
      week = moment();
    }
    const date = moment.utc(week);

    return date.valueOf();
  }

  getLastSignificantValueIndex() {
    const lastSignificantReverseIndex = this.props.weeks.slice()
      .reverse()
      .findIndex((week) => this.isSignificantValue(week.value));

    return this.props.weeks.length - 1 - lastSignificantReverseIndex;
  }

  getLastPointColor() {
    if (this.props.weeks.length > 0) {
      const significantWeeks = this.props.weeks
        .filter((week) => this.isSignificantValue(week.value));
      return this.getSeriesColor(significantWeeks);
    }
  }

  getSeriesColor(series) {
    if (series.length > 0) {
      let color = this.colors.neutral;
      if (series[series.length - 1].value > series[0].value) {
        color = this.colors.positive;
      }
      if (series[series.length - 1].value < series[0].value) {
        color = this.colors.negative;
      }
      return color;
    }
  }

  /**
   * Check if the value is significant (not null, undefined or zero)
   * @param value
   * @returns boolean
   */
  isSignificantValue(value) {
    return value && value !== 0;
  }

  getSeries() {
    const { weeks } = this.props;
    let data = weeks.map((week) => {
      const weekDate = new Date(week.week);
      const valueToShow = this.isSignificantValue(week.value) ? week.value : null;
      return {
        x: Date.parse(weekDate),
        y: valueToShow,
      };
    });
    const significantWeeks = weeks.filter((week) => this.isSignificantValue(week.value));
    const color = this.getSeriesColor(significantWeeks);
    data = this.getSeriesWithFormattedMarkers(data, color);
    const seriesName = this.props.seriesName ? this.props.seriesName : this.props.title;

    if (this.props.secondarySeries) {
      const secondaryData = this.props.secondarySeries.map((series) => {
        const color = (series.color || series.data.length <= 0)
          ? series.color : this.getSeriesColor(series.data);
        return {
          ...series,
          color,
          data: series.data.map((week) => {
            const weekDate = new Date(week.week);
            const valueToShow = this.isSignificantValue(week.value) ? week.value : null;
            return {
              x: Date.parse(weekDate),
              y: valueToShow,
            };
          }),
        };
      });

      return [{
        name: seriesName,
        data,
        color,
      }, ...secondaryData];
    }
    return [{
      name: seriesName,
      data,
      color,
    }];
  }

  getSeriesWithFormattedMarkers(series, color) {
    if (series.length > 0) {
      const lastValueIndex = this.getLastSignificantValueIndex();

      if (lastValueIndex >= 0 && lastValueIndex < series.length) {
        series[lastValueIndex].marker = {
          enabled: true,
          fillColor: color,
          states: {
            fillColor: color,
            hover: {
              fillColor: color,
            },
          },
        };
      }
    }

    return series;
  }

  getPointLabel(label) {
    if (this.series.data.length > 0) {
      const lastLabelIndex = label.props.getLastIndex();
      return label.props.getPointLabel(this.point, this.series, this.x, this.y, lastLabelIndex);
    }
  }

  getChart() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.weeks.length > 0) {
      return (
        <div>
          <div className="backlog-trend__overview">
            <h3 className="backlog-trend__title">{this.props.title}</h3>
            <div className="backlog-trend__description">{this.props.description}</div>
          </div>
          <div className="backlog-trend__report">
            <div className="backlog-trend__report-chart"
              ref={(chartContainer) => this.chartContainer = chartContainer} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="backlog-trend__overview">
          <h3 className="backlog-trend__title">{this.props.title}</h3>
          <div className="backlog-trend__description">{this.props.description}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getChart()}
      </div>
    );
  }
};
