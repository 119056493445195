const request = require('./Request');

const EmployeeWorkHoursCapacity = {
  get(employeeId) {
    return request({
      method: 'get',
      url: `/employee/${employeeId}/work-hour-capacity`,
    });
  },
};

module.exports = EmployeeWorkHoursCapacity;
