const React = require('react');
const PropTypes = require('prop-types');

const SidebarInputRow = ({ children, className }) => (
  <div className={`wethod-sidebar__multiple-input-row ${className}`}>
    {children}
  </div>
);

SidebarInputRow.propTypes = {
  /**
   * Row content. Usually a SidebarInputWrapper.
   */
  children: PropTypes.node.isRequired,
  /**
   * Additional class given to the row
   */
  className: PropTypes.string,
};

SidebarInputRow.defaultProps = {
  className: '',
};

module.exports = SidebarInputRow;
