const React = require('react');
const PropTypes = require('prop-types');

const ModalBodyBlock = ({ children }) => (
  <div className="wethod-modal__body-block">{children}</div>
);

ModalBodyBlock.defaultProps = {
  children: null,
};

ModalBodyBlock.propTypes = {
  children: PropTypes.node,
};

module.exports = ModalBodyBlock;
