const request = require('./Request');

const Expense = {
  getAll(offset = 0, limit = 50, search = '', mine = true) {
    let query = `?offset=${offset}`;
    query += `&limit=${limit}`;
    query += `&search=${search}`;
    query += `&mine=${mine}`;

    return request({
      method: 'get',
      url: `/expenses/${query}`,
    });
  },
  update(id, changes) {
    return request({
      method: 'patch',
      url: `/expenses/${id}`,
      data: changes,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/expenses/',
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/expenses/${id}`,
    });
  },
  getOwnerAutocomplete(keyword) {
    return request({
      method: 'get',
      url: `/expenses/autocomplete-owner?hints=${keyword}`,
    });
  },
};

module.exports = Expense;
