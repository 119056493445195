/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class TravelIconComponent extends React.Component {
  render() {
    return (
      <div className="travel-favorite-button wethod-icon" onClick={this.props.onClick} />
    );
  }
};
