/* eslint-disable no-case-declarations */
const constants = require('./constants');

const filterFavorites = (keyword, favorites) => {
  if (!favorites || favorites.length === 0) {
    return [];
  }
  return favorites.filter((item) => item.city && item.city.toLowerCase()
    .includes(keyword.toLowerCase()));
};

class TravelReducer {
  constructor(state) {
    this.state = {
      travelMetadata: [], // metadata related to travel attributes
      travelCarrierMetadata: [], // metadata related to travel carrier attributes
      ...state,
      travels: [],
      focusedTravel: null,
      waitingFor: [], // contains a key for each pending request
      lastPage: false,
      favorites: [],
      filteredFavorites: [],
      searchKeyword: '',
      keyword: state.id ? `id:${state.id}` : null,
      travelOffices: [],
      deletedElements: 0,
      createdElements: 0,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.FILTER_TRAVELS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('filter-travels'),
          travels: [],
          lastPage: false,
        };
      case constants.FILTER_TRAVELS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'filter-travels'),
          travels: action.items,
          lastPage: action.items.length < action.size,
          keyword: action.keyword,
        };
      case constants.LOAD_MORE_TRAVELS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('load-travels'),
        };
      case constants.LOAD_MORE_TRAVELS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'load-travels'),
          travels: state.travels.concat(action.items),
          lastPage: action.items.length < action.size,
        };
      case constants.UPDATE_TRAVEL_REQUEST:
      case constants.CREATE_TRAVEL_REQUEST:
      case constants.DELETE_TRAVEL_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-travel'),
        };
      case constants.UPDATE_TRAVEL_SUCCESS:
        const updatedTravels = state.travels.map((travel) => {
          if (travel.id === action.item.id) {
            return action.item;
          }
          return travel;
        });
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-travel'),
          travels: updatedTravels,
          focusedTravel: action.item,
        };
      case constants.CREATE_TRAVEL_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-travel'),
          travels: [action.item].concat(state.travels),
          focusedTravel: action.item,
          createdElements: state.createdElements + 1,
        };
      case constants.DELETE_TRAVEL_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-travel'),
          travels: state.travels.filter((item) => item.id !== action.item.id),
          focusedTravel: null,
          showSidebar: false,
          deletedElements: state.deletedElements + 1,
        };
      case constants.UPDATE_TRAVEL_FAILURE:
      case constants.DELETE_TRAVEL_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-travel'),
          focusedTravel: null,
          showSidebar: false,
        };
      case constants.GET_FAVORITES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('load-favorites'),
        };
      case constants.GET_FAVORITES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'load-favorites'),
          favorites: action.items,
          filteredFavorites: filterFavorites(state.searchKeyword, action.items),
        };
      case constants.ADD_FAVORITE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-favorite'),
        };
      case constants.DELETE_FAVORITE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-favorite'),
        };
      case constants.ADD_FAVORITE_SUCCESS:
        const favorites = state.favorites.concat(action.item);
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-favorite'),
          favorites,
          filteredFavorites: filterFavorites(state.searchKeyword, favorites),
        };
      case constants.DELETE_FAVORITE_SUCCESS:
        const favs = state.favorites.filter((item) => item.city !== action.item.city);
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-favorite'),
          favorites: favs,
          filteredFavorites: filterFavorites(state.searchKeyword, favs),
        };
      case constants.UPLOAD_FILE_SUCCESS:
        const updatedTravel = state.focusedTravel.id === action.travel.id ? {
          ...state.focusedTravel,
          travel_carriers: state.focusedTravel.travel_carriers.map((carrier) => {
            if (carrier.id === action.carrier.id) {
              return {
                ...carrier,
                files: carrier.files.concat(action.file),
              };
            }
            return carrier;
          }),
        } : state.focusedTravel;
        return {
          ...state,
          travels: state.travels.map((travel) => {
            if (travel.id === action.travel.id) {
              return {
                ...travel,
                travel_carriers: travel.travel_carriers.map((carrier) => {
                  if (carrier.id === action.carrier.id) {
                    return {
                      ...carrier,
                      files: carrier.files.concat(action.file),
                    };
                  }
                  return carrier;
                }),
              };
            }
            return travel;
          }),
          focusedTravel: updatedTravel,
        };
      case constants.DELETE_FILE_SUCCESS:
        const focusedTravel = state.focusedTravel.id === action.travel.id ? {
          ...state.focusedTravel,
          travel_carriers: state.focusedTravel.travel_carriers.map((carrier) => {
            if (carrier.id === action.carrier.id) {
              return {
                ...carrier,
                files: carrier.files.filter((file) => file.id !== action.file.id),
              };
            }
            return carrier;
          }),
        } : state.focusedTravel;
        return {
          ...state,
          travels: state.travels.map((travel) => {
            if (travel.id === action.travel.id) {
              return {
                ...travel,
                travel_carriers: travel.travel_carriers.map((carrier) => {
                  if (carrier.id === action.carrier.id) {
                    return {
                      ...carrier,
                      files: carrier.files.filter((file) => file.id !== action.file.id),
                    };
                  }
                  return carrier;
                }),
              };
            }
            return travel;
          }),
          focusedTravel,
        };
      case constants.GET_TRAVEL_OFFICES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-travel-offices'),
        };
      case constants.GET_TRAVEL_OFFICES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-travel-offices'),
          travelOffices: action.items,
        };
      case constants.EXPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('export'),
        };
      case constants.EXPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'export'),
        };
      case constants.FILTER_FAVORITES:
        return {
          ...state,
          searchKeyword: action.item,
          filteredFavorites: filterFavorites(action.item, state.favorites),
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      case constants.SHOW_CATEGORY_DOWNLOAD_MODAL: {
        return {
          ...state,
          modal: 'category-files-download',
          modalData: action.data,
        };
      }
      case constants.SHOW_SERVER_ERROR_MODAL: {
        return {
          ...state,
          modal: 'server-error',
          modalData: action.data,
        };
      }
      case constants.SHOW_UPLOAD_ERROR_MODAL: {
        return {
          ...state,
          modal: 'cannot-upload-file',
          modalData: action.data,
        };
      }
      case constants.SHOW_DOWNLOAD_ERROR_MODAL: {
        return {
          ...state,
          modal: 'cannot-download-file',
          modalData: action.data,
        };
      }
      case constants.SHOW_TRAVEL_OFFICE_MODAL: {
        return {
          ...state,
          modal: 'travel-office',
          modalData: action.data,
        };
      }
      case constants.SHOW_EXPORT_MODAL: {
        return {
          ...state,
          modal: 'export',
          modalData: action.data,
        };
      }
      case constants.SHOW_SIDEBAR:
        return {
          ...state,
          focusedTravel: { _fields: state.travelMetadata, ...action.item },
        };
      case constants.CLOSE_SIDEBAR:
        return {
          ...state,
          focusedTravel: null,
        };
      default:
        return state;
    }
  }
}

module.exports = TravelReducer;
