/* eslint-disable no-underscore-dangle,no-throw-literal,class-methods-use-this */
module.exports = class TimeBreakpoint {
  constructor(label, value) {
    this._label = label;
    this._value = value;
  }

  validate(label, value) {
    if (label === null || label === undefined || value === null || value === undefined) {
      throw 'TimeBreakpoint needs a label and a value';
    }
  }

  getLabel() {
    return this._label;
  }

  getValue() {
    return this._value;
  }
};
