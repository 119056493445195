const constants = require('./constants');
const ProjectStatusModel = require('../../../../../models/ProjectStatus');

module.exports.filterProjects = (keyword) => ({
  type: constants.FILTER_PROJECTS,
  keyword,
});

module.exports.sortProjects = (col, order) => ({
  type: constants.SORT_PROJECTS,
  col,
  order,
});

module.exports.changeWeek = (date) => ({
  type: constants.CHANGE_WEEK,
  date,
});

const getInternationalCalendarSettingsSuccess = (enabled) => ({
  type: constants.GET_INTERNATIONAL_CALENDAR_SETTINGS_SUCCESS,
  enabled,
});

const getInternationalCalendarSettingsRequest = () => ({
  type: constants.GET_INTERNATIONAL_CALENDAR_SETTINGS_REQUEST,
});

module.exports.getInternationalCalendarSettings = () => (dispatch) => {
  dispatch(getInternationalCalendarSettingsRequest());

  $.when(Wethod.request('get:company:planning:calendar'))
    .done((response) => dispatch(
      getInternationalCalendarSettingsSuccess(response.attributes.enabled),
    ));
};

const getProjectsSuccess = (projects) => ({
  type: constants.GET_PROJECTS_SUCCESS,
  projects,
});

const getProjectsRequest = (ownership) => ({
  type: constants.GET_PROJECTS_REQUEST,
  ownership,
});

module.exports.getProjects = (date, ownership) => (dispatch) => {
  dispatch(getProjectsRequest(ownership));

  const getListRequest = ProjectStatusModel.getAll(date.format('YYYY-MM-DD'), ownership);
  $.when(getListRequest)
    .done((projects) => dispatch(getProjectsSuccess(projects)));
};

const saveProjectStatusSuccess = (projectStatus) => ({
  type: constants.SAVE_PROJECT_STATUS_SUCCESS,
  projectStatus,
});

const saveProjectStatusRequest = (projectId) => ({
  type: constants.SAVE_PROJECT_STATUS_REQUEST,
  projectId,
});

module.exports.saveProjectStatus = (data) => (dispatch) => {
  dispatch(saveProjectStatusRequest(data.project));

  const saveRequest = ProjectStatusModel.save(data);
  $.when(saveRequest)
    .done(() => dispatch(saveProjectStatusSuccess(data)));
};

const saveRiskSuccess = (projectStatus) => ({
  type: constants.SAVE_RISK_SUCCESS,
  projectStatus,
});

const saveRiskRequest = (projectStatus) => ({
  type: constants.SAVE_RISK_REQUEST,
  projectStatus,
});

module.exports.saveRisk = (data) => (dispatch) => {
  dispatch(saveRiskRequest(data));

  const saveRisk = ProjectStatusModel.saveRisk(data);
  $.when(saveRisk)
    .done(() => dispatch(saveRiskSuccess(data)));
};

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

module.exports.showProjectAreasModal = (data) => ({
  type: constants.SHOW_PROJECT_AREAS_MODAL,
  data,
});

module.exports.showRiskSelect = (anchorEl, data) => ({
  type: constants.SHOW_RISK_SELECT,
  data,
  anchorEl,
});

module.exports.closeRiskSelect = () => ({
  type: constants.CLOSE_RISK_SELECT,
});

const getProjectInfoSuccess = (projectInfo) => ({
  type: constants.GET_PROJECT_INFO_SUCCESS,
  projectInfo,
});

const getProjectInfoRequest = (projectId) => ({
  type: constants.GET_PROJECT_INFO_REQUEST,
  projectId,
});

module.exports.getProjectInfo = (date, projectId) => (dispatch) => {
  dispatch(getProjectInfoRequest(projectId));

  const getInfoRequest = ProjectStatusModel.getInfo(date.format('YYYY-MM-DD'), projectId);
  $.when(getInfoRequest)
    .done((projectInfo) => dispatch(getProjectInfoSuccess(projectInfo)));
};

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

module.exports.showSidebar = (projectId) => ({
  type: constants.SHOW_SIDEBAR,
  projectId,
});

const saveProjectStatusByAreaRequest = (projectId) => ({
  type: constants.SAVE_PROJECT_STATUS_REQUEST,
  projectId,
});

module.exports.saveProjectStatusByArea = (data) => (dispatch) => {
  dispatch(saveProjectStatusByAreaRequest(data.project));

  const saveAreas = ProjectStatusModel.saveAreas(data);
  $.when(saveAreas)
    .done(() => {
      dispatch(saveProjectStatusSuccess(data));
      dispatch(closeModal());
    });
};
