const { connect } = require('react-redux');
const Component = require('../../components/modal/RevokeDelegationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'revoke-delegation').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onRevoke: (project, billingGroupId) => dispatch(actions
    .revokeDelegation(project, billingGroupId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
