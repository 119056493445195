const React = require('react');
const FileManagerContentError = require('./FileManagerContentError.react');

const MissingRootFolderMessage = () => (
  <FileManagerContentError title="Cannot access Shared Drive">
    <div>
      This may happen if you remove wethod access from Google Drive root folder or from the
      parent Shared Drive.
      <br />
      Please try re-enabling Google Drive integration from your
      <span> <a href="#settings/company/google-drive-sync">company settings</a></span>.
    </div>
  </FileManagerContentError>
);

module.exports = MissingRootFolderMessage;
