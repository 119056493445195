module.exports = Backbone.Model.extend({
  initialize() {
    this.urlRoot = `${APIURL}/file-storage/`;
  },
  upload(file) {
    const formData = new FormData();
    formData.append('file', file);
    const defer = $.Deferred();

    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: `${this.urlRoot}employee`,
      // Tell jQuery not to process data or worry about content-type
      // You *must* include these options!
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  deleteModel(fileId) {
    const defer = $.Deferred();

    this.destroy({
      url: `${this.urlRoot + fileId}/employee`,
      success(model) {
        defer.resolve(model);
      },
      error(model, response) {
        defer.resolve(response);
      },
      wait: true,
    });
    return defer.promise();
  },
  download(fileId) {
    const defer = $.Deferred();

    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: `${this.urlRoot + fileId}/employee/url`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code === 200) {
        // Download file simulating a click on a href
        window.location.href = response.data.download_url;
        defer.resolve(response.data);
      } else {
        defer.reject(response);
      }
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
