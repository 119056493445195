/* eslint-disable react/prefer-stateless-function,react/prop-types */
const React = require('react');

module.exports = class SectionProfile extends React.Component {
  render() {
    return (
      <li className="app-nav__section">
        <a href={`/#settings/profile/${Wethod.userInfo.get('employee_id')}`}
          onClick={this.props.onClick}>
          Profile
        </a>
      </li>
    );
  }
};
