const React = require('react');
const MathService = require('../../../../../services/MathService');

const CurrencyManager = ({
  isCurrencyEnabled = false,
  budgetRate,
  children,
  currencies = [],
  ...rest
}) => {
  /** Currencies available in company */
  const availableCurrencies = currencies;

  const masterCurrency = availableCurrencies.find((currency) => currency.is_master);
  /**
   * Return currency by code.
   * If code null return master currency.
   * @param {string} code
   * @return {*}
   */
  const getCurrency = (code) => {
    const found = availableCurrencies.find((curr) => curr.code === code);

    return found || masterCurrency;
  };

  const getCurrencyRate = (code) => getCurrency(code).rate;

  /**
   * Return given amount conversion in master currency.
   * When the budget has a custom exchange rate and the useBudgetCustomRate is given,
   * the amount conversion uses the proper rate instead of the default currency rate.
   * @return {number}
   */
  const getAmountInMasterCurrency = (amount, currencyCode, useBudgetCustomRate) => {
    const needsCustomRate = useBudgetCustomRate && budgetRate != null;

    const rate = needsCustomRate ? budgetRate : getCurrencyRate(currencyCode);

    return MathService.round(amount / rate, 6);
  };

  /**
   * Return given amount conversion in current currency;
   * When the budget has a custom exchange rate and the useBudgetCustomRate is given,
   * the amount conversion uses the proper rate instead of the default currency rate.
   * @return {number}
   */
  const getAmountInCurrency = (amount, currencyCode, useBudgetCustomRate) => {
    const needsCustomRate = useBudgetCustomRate && budgetRate != null;

    const rate = needsCustomRate ? budgetRate : getCurrencyRate(currencyCode);

    return MathService.round(amount * rate, 6);
  };

  return React.cloneElement(children, {
    ...rest,
    getCurrency,
    getCurrencyRate,
    getAmountInMasterCurrency,
    getAmountInCurrency,
    masterCurrency,
    isCurrencyEnabled,
    availableCurrencies,
  });
};

module.exports = CurrencyManager;
