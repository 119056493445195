const { connect } = require('react-redux');
const Component = require('../../components/Concept/Concept.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  concept: state.concept,
  projectId: state.info.id,
  canEdit: state.canEdit,
  isWaiting: state.waitingFor.filter((key) => key === 'concept').length > 0,
  isEmpty: state.concept.summary === '',
});

const mapDispatchToProps = (dispatch) => ({
  getConcept: (projectId) => dispatch(actions.getConcept(projectId)),
  showModal: () => dispatch(actions.showConceptModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
