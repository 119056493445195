/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const SectionFactory = require('./SectionFactory');

module.exports = class Area {
  constructor(area, userPermissions) {
    this._label = area.label;
    this._sections = area.sections.map((section) => new SectionFactory(section, userPermissions))
      .filter((section) => section.isVisible());
    this._mobileSections = this._sections.filter((section) => section.isMobile());
    this._userPermissions = userPermissions;
  }

  getLabel() {
    return this._label;
  }

  getId() {
    return this._id;
  }

  getSections() {
    return this._sections;
  }

  getMobileSections() {
    return this._mobileSections;
  }

  isVisible() {
    return this.getSections().length > 0;
  }

  isMobile() {
    return this.getSections().filter((section) => section.isMobile()).length > 0;
  }

  isBlocked() {
    return this.getSections().filter((section) => !section.isBlocked()).length === 0;
  }

  hasSectionsWithUrl(url) {
    const sections = this.getSections().filter((section) => section.getUrl() === url);

    return sections.length > 0;
  }

  hasUrl(url) {
    return this.getSections().filter((section) => section.hasUrl(url)).length > 0;
  }
};
