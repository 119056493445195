/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Item = require('./Item.react');
const CalendarNavigator = require('./Calendar/Navigator.react');

module.exports = class Items extends React.Component {
  render() {
    return (
      <div className="project-canvas-gantt__items">
        <div className="project-canvas-gantt__item project-canvas-gantt__item--header">
          <CalendarNavigator moveToNow={this.props.moveToNow}
            moveBack={this.props.moveBack}
            moveOn={this.props.moveOn} />
        </div>
        {this.props.items.map((item) => (
          <Item key={item.id}
            showsSidebar={this.props.showSidebar}
            {...item} />
        ))}
      </div>
    );
  }
};
