'use strict';

Wethod.module('ReportApp.People', function (People, Wethod, Backbone, Marionette, $, _) {
  // recupero dati planning in un dato intervallo di tempo
  this.ReportTimetracking = Wethod.Utility.BaseModel.extend({
    _requestPool: [],
    urlRoot: function () {
      return APIURL + '/report/timetracking/?date=' + this.options.date;
    },
    sync: function (method, model, options) {
      options = options || {};
      if (!options.crossDomain) {
        options.crossDomain = true;
      }
      if (!options.xhrFields) {
        options.xhrFields = { withCredentials: true };
      }

      if (method === 'read') {
        this.stopRequest();
        var xhr = proxiedSync(method, model, options);
        this._requestPool.push(xhr);
        return xhr;
      }
      return proxiedSync(method, model, options);
    },
    stopRequest: function () {
      _.each(this._requestPool, function (request) {
        if (request.readyState !== 4) {
          request.abort();
        }
      });
    },
  });

  // modello & collection per la lista degli employee
  this.EmployeeModel = Wethod.Utility.BaseModel.extend({});
  this.EmployeesCollection = Wethod.Utility.BaseCollection.extend({
    model: People.EmployeeModel,
  });

  // modello & collection per la lista dei project type
  this.ProjectTypeModel = Wethod.Utility.BaseModel.extend({});
  this.ProjectTypesCollection = Wethod.Utility.BaseCollection.extend({
    model: People.ProjectTypeModel,
  });

  var API = {
    getReportTimetracking: function (options) {
      var record = new People.ReportTimetracking(options);
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.resolve(response);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:report:timetracking', function (options) {
    return API.getReportTimetracking(options);
  });
});
