const React = require('react');
const Button = require('../../../../../common/react/Button/RoundedButton.react');
const Typography = require('../../../../../common/react/Typography/Typography.react');
const status = require('../segmentsStatus');

const Actions = ({ segments = [], openReviewModal }) => {
  function startNewReview() {
    openReviewModal();
  }

  const hasReviewSegment = segments.some((segment) => segment.status === status.IN_REVIEW);

  return (
    <div className="wethod-section-actions">
      <Typography color={Typography.COLORS.DUSTY_GRAY}>
        Start a new analysis on a period you want to pause or freeze to resolve possible {' '}
        inconsistencies before stopping changes on the data from that period.
      </Typography>
      <Button onClick={startNewReview} disabled={hasReviewSegment}>Start data review</Button>
    </div>
  );
};

module.exports = Actions;
