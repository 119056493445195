const { connect } = require('react-redux');
const Component = require('../../components/SponsorList/SponsorList.react');

const sortByDescEndDate = (a, b) => {
  if (moment(a.to_date).isAfter(moment(b.to_date))) return -1;
  return 1;
};

const mapStateToProps = (state) => ({
  currentList: state.sponsors.filter((item) => item.to_date === null),
  pastList: state.sponsors.filter((item) => item.to_date !== null).sort(sortByDescEndDate),
  placeholder: 'One or more key figures in your team who support your professional growth',
});

module.exports = connect(mapStateToProps)(Component);
