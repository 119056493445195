const React = require('react');
const SectionTitle = require('../../../../common/components/SectionTitle.react');
const PrevWeekComparator = require('../../../containers/sections/Time/PrevWeekChargeabilityComparator');
const TargetComparator = require('../../../containers/sections/Time/TargetComparator');
const ComparatorConnector = require('../../../../common/components/ComparatorConnector.react');

module.exports = class Title extends React.Component {
  getAgainstPrevWeek() {
    return <PrevWeekComparator value={this.props.chargeability} />;
  }

  getAgainstTarget() {
    const { target } = this.props;
    if (target) {
      return (
        <span>
          <TargetComparator value={this.props.chargeability} /> its target
        </span>
      );
    }
    return null;
  }

  getContent() {
    const againstTarget = this.getAgainstTarget();
    const againstPrevWeek = this.getAgainstPrevWeek();
    const c1 = { a: this.props.chargeability, b: this.props.prev_week_chargeability };
    const c2 = { a: this.props.chargeability, b: this.props.target };
    const connection = againstTarget ? (
      <span>
        <ComparatorConnector c1={c1} c2={c2} /> it's
      </span>
    ) : null;
    return (
      <span>
        Chargeability is {againstTarget} {connection} {againstPrevWeek}
      </span>
    );
  }

  render() {
    return <SectionTitle>{this.getContent()}</SectionTitle>;
  }
};
