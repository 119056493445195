const React = require('react');

class Level extends React.Component {
  constructor(props) {
    super(props);
    this.handelClick = this.handelClick.bind(this);
  }

  handelClick() {
    this.props.onClick(this.props.level);
  }

  render() {
    const className = this.props.active ? 'filter__item filter__item--active' : 'filter__item';
    return (
      <div className={className} onClick={this.handelClick}>{this.props.level.name}</div>
    );
  }
}

module.exports = Level;
