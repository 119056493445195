const { connect } = require('react-redux');
const Component = require('../components/PeopleStep.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  people: state.people,
  filter: state.people_filter,
});

const mapDispatchToProps = (dispatch) => ({
  goToPrevStep: () => dispatch(actions.goToStep(0)),
  save: () => dispatch(actions.showSaveModal()),
  showMissingVoteModal: (step) => dispatch(actions.showMissingVoteModal(step)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
