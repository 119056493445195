const request = require('./Request');

const Travel = {
  update(id, changes) {
    return request({
      method: 'put',
      url: `/travel/${id}`,
      data: changes,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/travel/${id}`,
    });
  },
};

Travel.STATUS_CREATED = 'created';
Travel.STATUS_SENT = 'sent';
Travel.STATUS_APPROVED = 'approved';

Travel.CARRIER_TYPE_TRAIN = 'train';
Travel.CARRIER_TYPE_FLIGHT = 'flight';
Travel.CARRIER_TYPE_CAR = 'car';
Travel.CARRIER_TYPE_ACCOMODATION = 'accommodation';
Travel.CARRIER_TYPE_OTHER = 'other';

module.exports = Travel;
