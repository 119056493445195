const React = require('react');
const PropTypes = require('prop-types');
const TableRow = require('./TableRow.react');
const TableCell = require('./TableCell.react');
const Loader = require('../Loader/Loader.react');

/**
 * A table row to use use on loading.
 */
const TableLoadingRow = ({ columns }) => (
  <TableRow data-testid="wethod-table-loading-row">
    <TableCell colSpan={columns} loadingRow>
      <Loader />
    </TableCell>
  </TableRow>
);

TableLoadingRow.propTypes = {
  /**
   * The number of columns in the table.
   */
  columns: PropTypes.number.isRequired,
};

module.exports = TableLoadingRow;
