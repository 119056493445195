const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projects: state.available_projects,
  peopleFilters: state.available_people_filters,
  buEnabled: state.bu_enabled,
  activePeopleFilters: state.people_filters,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (project) => dispatch(actions.getProjectAvailability(project.id)),
  selectPeopleFilter: (type, target, secondaryTarget) => dispatch(actions.selectPeopleFilter(type,
    target, secondaryTarget)),
  deselectPeopleFilter: (type, target, secondaryTarget) => dispatch(actions
    .deselectPeopleFilter(type, target, secondaryTarget)),
  clearPeopleFilters: () => dispatch(actions.clearPeopleFilters()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
