const { connect } = require('react-redux');
const Component = require('../../components/Objectives/Objectives.react');
const actions = require('../../actions');

const getNonEmptyQuarters = (objectivesByQuarter, filter) => objectivesByQuarter
  .quarters.filter((quarter) => {
    if (filter !== null) {
      return quarter.quarter === filter;
    }
    return true;
  });

const mapStateToProps = (state) => ({
  editable: state.editable,
  employee: state.id,
  quartersFilter: state.quartersFilter,
  quarters: getNonEmptyQuarters(state.objectivesByQuarter, state.quartersFilter),
  waiting: state.waitingForObjectives,
  year: state.objectivesByQuarter.year,
});

const mapDispatchToProps = (dispatch) => ({
  getObjectives: (year, employeeId) => dispatch(actions.getObjectives(year, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
