const React = require('react');
const NumberFormatter = require('../../../../../../../common/react/formatters/NumberFormatter.react');
const MoneyCell = require('./MoneyCell.react');
const Delta = require('../NumericDelta.react');

const CostCell = ({ children, className, previousValue = null, label = '' }) => (
  <MoneyCell className={className} label={label}>
    <Delta previousValue={previousValue} actualValue={children} />
    <NumberFormatter hideZero>
      {children}
    </NumberFormatter>
  </MoneyCell>
);

module.exports = CostCell;
