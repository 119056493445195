/* eslint-disable react/prop-types,react/no-did-update-set-state,
 react/no-access-state-in-setstate */
const React = require('react');
const moment = require('moment');
const DayPicker = require('./DayCalendar.react');

/**
 * Month calendar to pick up an interval of two dates, from and to. It can be a single day interval.
 *
 * PROPS
 * name: string,
 * from: string (date),
 * to: string (date),
 *
 * onChange(name, from, to) //format YYYY-MM-DD'
 *
 * @type {DateRangePicker}
 */
module.exports = class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: true,
      range: {
        from: this.props.from ? new Date(this.props.from) : this.props.from,
        to: this.props.to ? new Date(this.props.to) : this.props.to,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      this.setState({
        range: {
          from: this.props.from ? new Date(this.props.from) : this.props.from,
          to: this.props.to ? new Date(this.props.to) : this.props.to,
        },
      });
    }
  }

  handleClick() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  handleDayClick(day) {
    const { from, to } = this.state.range;
    let updatedRange = null;

    if (!from && !to) { // first selection: suppose it'a a single day selection
      updatedRange = {
        from: day,
        to: day,
      };
    } else if (day < from) { // when is selected a date previous the 'from', it is updated
      updatedRange = {
        from: day,
        to,
      };
    } else if (day.getTime() === to.getTime()) {
      // when is selected a date equals the 'to', it's a single day selection
      updatedRange = {
        from: to,
        to,
      };
    } else {
      // in any other case, the 'to' is updated (it can also be equals the 'from', having a single
      // day selection)
      updatedRange = {
        from,
        to: day,
      };
    }
    this.setState({ range: updatedRange });

    this.props.onChange(this.props.name,
      moment(updatedRange.from).format('YYYY-MM-DD'),
      moment(updatedRange.to).format('YYYY-MM-DD'));
  }

  getPicker() {
    const { from, to } = this.state.range;
    const modifiers = {
      start: from,
      end: to,
    };

    if (this.state.showPicker) {
      return (
        <DayPicker className="wethod-date-range-picker"
          disableBeforeEqual={this.props.disableBeforeEqual}
          startDate={from}
          endDate={to}
          modifiers={modifiers}
          onDayClick={this.handleDayClick.bind(this)} />
      );
    }
    return null;
  }

  render() {
    return (
      this.getPicker()
    );
  }
};
