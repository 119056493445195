/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class ListHeaderEdit extends React.Component {
  render() {
    return (
      <div className="project-canvas-kanban__list-name" style={this.props.nameStyle}>
        <span className="project-canvas-kanban__list-drag-handle__container">
          <span
            className="project-canvas-kanban__list-drag-handle wethod-icon-draggable wethod-icon-draggable--black" />
        </span>
        {this.props.name}
      </div>
    );
  }
};
