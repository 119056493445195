const request = require('./Request');

const BudgetTask = {
  create(areaId, attributes) {
    return request({
      method: 'post',
      url: `/br/budget-area/${areaId}/budget-task`,
      data: attributes,
    });
  },
  update(id, attributes) {
    return request({
      method: 'patch',
      url: `/br/budget-task/${id}`,
      data: attributes,
    });
  },
  delete(budgetId, id) {
    return request({
      method: 'delete',
      url: `/br/budget-task/${id}`,
    });
  },
  makeIntercompany(id, supplierId) {
    return request({
      method: 'post',
      url: '/intercompany/promote',
      data: { task: id, supplier: supplierId },
    });
  },
  inviteIntercompanySupplier(id, recipientId) {
    return request({
      method: 'post',
      url: '/intercompany/send',
      data: { task: id, recipient: recipientId },
    });
  },
  getIntercompanyInfo(id) {
    return request({
      method: 'get',
      url: `/intercompany/info/by-task/${id}`,
    });
  },
};

module.exports = BudgetTask;
