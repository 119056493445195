/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const SidebarInput = require('./SidebarWrapperEditable.react');
const SearchSelect = require('../SearchSelectAddButton/SearchSelect2.react');

/**
 * An outlined search select component, material styled.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name: string
 * value: object corresponding to current selected object, required a 'name' attribute that will be
 * shown if no prop param is given param: (optional) the attribute of every item to be shown in
 * search input and list, default name required: boolean email: boolean items: list of items to be
 * shown in the picklist
 *
 * onFocus
 * onChange
 * onBlur
 * onValidate
 * onCreateClick
 *
 * @type {module.SidebarSearchSelectAddButton}
 *
 * @deprecated
 */
module.exports = class SidebarSearchSelectAddButton extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = this.getConstraints();
  }

  getConstraints() {
    let constraints = '';

    if (this.props.email && this.props.email === true) {
      constraints = 'email';
    }
    if (this.props.required && this.props.required === true) {
      if (constraints === '') {
        constraints = 'required';
      } else {
        constraints += ',required';
      }
    }

    return constraints;
  }

  getValue() {
    const param = this.props.param || 'name';
    return this.props.value ? this.props.value[param] : null;
  }

  render() {
    return (
      <SidebarInput placeholder={this.props.placeholder}
        error={this.props.error}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}>
        <SearchSelect name={this.props.name}
          param={this.props.param}
          value={this.getValue()}
          current={this.props.value}
          constraints={this.constraints}
          items={this.props.items}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onValidate={this.props.onValidate}
          onCreateClick={this.props.onCreateClick} />
      </SidebarInput>
    );
  }
};
