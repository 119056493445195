const React = require('react');
const ReadOnlyField = require('./ReadOnlyField.react');

const ReadOnlyTotalTravelCostField = ({ value, placeholder }) => {
  const icon = <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />;
  return (
    <ReadOnlyField value={value} placeholder={placeholder} icon={icon} />
  );
};

module.exports = ReadOnlyTotalTravelCostField;
