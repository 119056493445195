const { connect } = require('react-redux');
const Component = require('../components/CostRevenuesTrend.react');

const mapStateToProps = (state) => ({
  costs: state.revenues ? state.revenues.data.costs : [],
  revenues: state.revenues ? state.revenues.data.revenues : [],
  timeFilter: state.timeFilter,
});

module.exports = connect(mapStateToProps, null)(Component);
