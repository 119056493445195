/* eslint-disable camelcase */
const React = require('react');
const Paragraph = require('../../../../../common/components/SectionParagraph.react');
const NV = require('../../../../../common/components/NumericValue.react');

module.exports = ({ value, prev_value }) => {
  if (value === prev_value) {
    return (
      <Paragraph>
        At the end of last week, the pipeline had a value of
        {' '}
        <NV value={value} />
        , which is the same of the previous
        week.
      </Paragraph>
    );
  }
  return (
    <Paragraph>
      At the end of last week, the pipeline had a value of
      {' '}
      <NV value={value} />
      , compared to
      {' '}
      <NV
        value={prev_value} />
      {' '}
      from the previous week.
    </Paragraph>
  );
};
