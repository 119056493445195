/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Body = require('./DeletePersonModalBody.react');

module.exports = class DeletePersonModal extends React.Component {
  getActions() {
    return (
      <div className="clients-modal__actions budget-modal__actions--approve">
        <a
          href="#"
          className="roundButton koAction"
          onClick={this.handleClose.bind(this)}>
          <span className="text">Cancel</span>
        </a>
        <a
          href="#"
          className="roundButton okAction"
          onClick={this.handleDeleteAction.bind(this)}>
          <span className="text">Yes, delete</span>
        </a>
      </div>
    );
  }

  handleClose(e) {
    e.preventDefault();
    if (!this.props.isWaiting) this.props.closeModal();
  }

  handleDeleteAction(e) {
    e.preventDefault();
    if (!this.props.isWaiting) this.props.deleteClient(this.props.client);
  }

  getFeedback() {
    if (this.props.isWaiting) return <span className="wethod-modal2__feedback">Deleting...</span>;
    return '';
  }

  getTitle() {
    return 'Delete person';
  }

  render() {
    return (
      <Modal
        title={this.getTitle()}
        onCancelClick={this.handleClose.bind(this)}>
        <div>
          <Body client={this.props.client} />
          {this.getActions()}
          {this.getFeedback()}
        </div>
      </Modal>
    );
  }
};
