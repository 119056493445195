/* eslint-disable react/sort-comp,consistent-return,react/jsx-no-bind */
const React = require('react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const Cell = require('./ItemCell/ItemCell.react');
const NameCell = require('./ItemNameCell/ItemNameCell.react');
const ActionsCell = require('./ItemActionsCell.react');

module.exports = class Item extends React.Component {
  getAttributesCells() {
    return this.props.attributes.map((attribute, index) => {
      const item = { id: this.props.id, sort: this.props.sort };
      return (
        <Cell
          key={attribute.id}
          item={item}
          structure={this.props.structure[index]}
          canEdit={this.props.canEdit}
          deleteAvailableStatus={this.props.deleteAvailableStatus}
          updateAvailableStatus={this.props.updateAvailableStatus}
          createAvailableStatus={this.props.createAvailableStatus}
          deleteMember={this.props.deleteMember}
          deleteMilestone={this.props.deleteMilestone}
          isWaitingForCellSaving={this.props.isWaitingForCellSaving}
          isWaitingForMemberDeleting={this.props.isWaitingForMemberDeleting}
          addMember={this.props.addAttributeValueMember}
          editValue={this.props.editAttributeValue}
          {...attribute} />
      );
    });
  }

  editName(value) {
    this.props.onEditName(this.props.id, value);
  }

  delete() {
    this.props.deleteItem(this.props.id, this.props.name);
  }

  getCells() {
    const cells = [<NameCell
      key="name"
      name={this.props.name}
      canEdit={this.props.canEdit}
      editName={this.editName.bind(this)} />].concat(this.getAttributesCells());
    if (this.props.canEdit) {
      return cells.concat(<ActionsCell
        key="actions"
        waiting={this.props.isDeleting}
        onDeleteClick={this.delete.bind(this)} />);
    }
    return cells;
  }

  render() {
    return (
      <TableRow
        className="project-canvas-spreadsheet__row"
        data-board-id={this.props.boardId}
        data-id={this.props.id}
        data-sort={this.props.dataSort}>
        {this.getCells()}
      </TableRow>
    );
  }
};
