'use strict';

Wethod.module('ExpensesApp', function (ExpensesApp) {
  this.startWithParent = false;

  var API = {
    showExpenses: function (id) {
      ExpensesApp.Expenses.Controller.showExpenses(id);
    },
  };

  ExpensesApp.on('start', function (options) {
    API[options.foo](options.id);
  });
});
