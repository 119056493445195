/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const AreaFactory = require('./AreaFactory');

module.exports = class Module {
  constructor(module, userPermissions) {
    this._label = module.label;
    this._areas = module.areas.map((area) => new AreaFactory(area, userPermissions))
      .filter((area) => area.isVisible());
    this._mobileAreas = this._areas.filter((area) => area.isMobile());
    this._mobile = module.mobile;
    this._icon = module.icon;
    this._url = module.url;
    this._userPermissions = userPermissions;
  }

  getLabel() {
    return this._label;
  }

  getId() {
    return this._id;
  }

  /**
   *
   * @return [Area]
   */
  getAreas() {
    return this._areas;
  }

  getMobileAreas() {
    return this._mobileAreas;
  }

  getIcon() {
    return this._icon;
  }

  getUrl() {
    return this._url;
  }

  getMobile() {
    return this._mobile === true;
  }

  isVisible() {
    return this.getAreas().length > 0;
  }

  isMobile() {
    return this.getAreas().filter((area) => area.isMobile()).length > 0;
  }

  hasAreasWithUrl(url) {
    const areas = this.getAreas().filter((area) => area.hasSectionsWithUrl(url));

    return areas.length > 0;
  }

  hasUrl(url) {
    return this.getAreas().filter((area) => area.hasUrl(url)).length > 0;
  }
};
