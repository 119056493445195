/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const DateTimePicker = require('./DateTimePicker.react');
const Description = require('./Description.react');
const Actions = require('./Actions.react');
const TypeSelect = require('../../containers/EventForm/TypeSelect');

module.exports = class EventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type ? this.props.type.id : 1,
      schedule: this.props.schedule ? moment(this.props.schedule) : moment(),
      description: this.props.description ? this.props.description : '',
    };

    this.typeNames = ['call', 'email', 'meeting'];
  }

  componentDidUpdate() {
    if (this.isSaved()) this.props.closeForm();
  }

  update(state) {
    this.setState(state);
  }

  save() {
    this.props.save({
      ...this.state,
      id: this.props.id ? this.props.id : undefined,
      parent_id: this.props.parentId ? this.props.parentId : undefined,
      schedule: this.state.schedule.format('YYYY-MM-DD HH:mm:ss'),
      project: this.props.project.id,
      confirmed: this.state.schedule.isBefore(moment().format('YYYY-MM-DD HH:mm:ss')),
    });
  }

  isSaving() {
    return this.props.formStatus === 'saving';
  }

  isSaved() {
    return this.props.formStatus === 'saved';
  }

  getFeedback() {
    if (this.isSaving()) return 'Saving...';
    return '';
  }

  getTypeSelect() {
    if (this.props.inputs.indexOf('type-select') !== -1) return <TypeSelect current={this.state.type} updateForm={this.update.bind(this)} />;
  }

  getDateTimePicker() {
    if (this.props.inputs.indexOf('datetime-picker') !== -1) return <DateTimePicker current={this.state.schedule} updateForm={this.update.bind(this)} />;
  }

  getDescription() {
    if (this.props.inputs.indexOf('description') !== -1) return <Description content={this.state.description} updateForm={this.update.bind(this)} />;
  }

  render() {
    return (
      <div className="timeline-add-event-form">
        {this.getTypeSelect()}
        {this.getDateTimePicker()}
        {this.getDescription()}
        <Actions feedback={this.getFeedback()}
          onCancelClick={this.props.closeForm}
          onSaveClick={this.save.bind(this)} />
      </div>
    );
  }
};
