const React = require('react');
const PropTypes = require('prop-types');
const FullIcon = require('./icons/RadioFullIcon.react');
const EmptyIcon = require('./icons/RadioEmptyIcon.react');
const Input = require('../Input/Input.react');

const Radio = ({ id, name, value, label, checked, onClick, disabled, className }) => {
  const getStyle = () => {
    let style = 'wethod-radio';
    if (disabled) {
      style += ' disabled';
    }
    if (className) {
      style += ` ${className}`;
    }
    return style;
  };

  const getIcon = () => (checked ? <FullIcon /> : <EmptyIcon />);

  function handleClick(e) {
    if (!disabled) {
      onClick(e);
    }
  }

  return (
    <label htmlFor={id}
      className={getStyle()}>
      {getIcon()}
      <Input id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onClick={handleClick}
        disabled={disabled} />
      <span className="wethod-radio__label">{label}</span>
    </label>
  );
};

Radio.defaultProps = {
  name: undefined,
  checked: false,
  disabled: false,
  onClick: null,
  id: null,
  className: null,
};

Radio.propTypes = {
  id: PropTypes.string,
  /**
   * Used to group radio buttons. All buttons in the same group must have the same name.
   */
  name: PropTypes.string,
  /**
   * The unique value associated with the radio button.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Used for accessibility and showed to the user.
   */
  label: PropTypes.node.isRequired,
  /**
   * It says whether or not this radio button is checked.
   */
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
};

module.exports = Radio;
