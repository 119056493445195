const { connect } = require('react-redux');
const EventForm = require('../../components/EventForm/EventForm.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  project: state.timeline.project,
  formStatus: state.newEventStatus,
});

const mapDispatchToProps = (dispatch) => ({
  save: (event) => dispatch(actions.saveFollowUp(event)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(EventForm);
