const React = require('react');
const View = require('./NumericSummaryCellView.react');
const Edit = require('./NumericSummaryCellEdit.react');

module.exports = ({ canEdit, values, func, changeSummary, ...rest }) => {
  const { length } = values;

  const getSum = () => values.reduce((sum, value) => sum + value.value, 0);

  const getAverage = () => values.reduce((sum, value) => sum + value.value, 0) / length;

  const getMin = () => values.sort((a, b) => a.value - b.value)[0].value;

  const getMax = () => values.sort((a, b) => b.value - a.value)[0].value;

  const getSummary = () => {
    let summary = null;

    if (length) {
      switch (func) {
        case 'sum':
          summary = getSum();
          break;
        case 'avg':
          summary = getAverage();
          break;
        case 'min':
          summary = getMin();
          break;
        case 'max':
          summary = getMax();
          break;
        default:
          summary = null;
          break;
      }
    }

    return summary !== null ? numeral(summary).format('0,0.[0]') : '-';
  };

  if (canEdit) {
    return <Edit value={getSummary()} function={func} changeSummary={changeSummary} {...rest} />;
  }
  return <View value={getSummary()} function={func} {...rest} />;
};
