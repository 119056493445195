const React = require('react');
const NV = require('../../../../../common/components/NumericValue.react');
const CostValue = require('./CostValue.react');
const ComparedPercentage = require('./ComparedPercentage.react');
const NullablePercentage = require('./NullablePercentageValue.react');
const Table = require('../../../../../../../../../../common/react/TableLight/TableLight.react');
const TableHead = require('../../../../../../../../../../common/react/TableLight/TableHead.react');
const TableBody = require('../../../../../../../../../../common/react/TableLight/TableBody.react');
const TableHeaderRow = require('../../../../../../../../../../common/react/TableLight/TableHeaderRow.react');
const TableRow = require('../../../../../../../../../../common/react/TableLight/TableRow.react');
const TableCell = require('../../../../../../../../../../common/react/TableLight/TableCell.react');

module.exports = ({
  month, revenue, externalCosts, externalCostsOnRevenues, externalCostsOnForecast, grossMargin,
  grossMarginOnRevenues, grossMarginOnForecast, payroll, payrollCostsOnRevenues,
  payrollCostsOnForecast, netMargin, netMarginOnRevenues, netMarginOnForecast, ga, gaOnRevenues,
  gaOnForecast, marketing, marketingOnRevenues, marketingOnForecast, ebitda, ebitdaOnRevenues,
  ebitdaOnForecast,
}) => (
  <div className="briefing-aside-chart" id="briefing-aside-chart-monthly__profit-loss">
    <div className="briefing-aside-chart__header">
      <h5 className="briefing-aside-chart__title">Monthly Profit & Loss</h5>
      <h6 className="briefing-aside-chart__subtitle">in thousands</h6>
    </div>
    <div className="briefing-aside-chart__content">
      <Table className="briefing-aside-chart-table">
        <TableHead>
          <TableHeaderRow>
            <TableCell className="briefing-aside-chart-table__col--first" />
            <TableCell>{month}</TableCell>
            <TableCell />
            <TableCell>Forecast</TableCell>
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="bold briefing-aside-chart-table__col--first">Revenues</TableCell>
            <TableCell><NV value={revenue} /></TableCell>
            <TableCell>100%</TableCell>
            <TableCell>100%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="briefing-aside-chart-table__col--first">Ext Costs</TableCell>
            <TableCell><CostValue value={externalCosts} /></TableCell>
            <TableCell><ComparedPercentage a={externalCostsOnRevenues}
              b={externalCostsOnForecast}
              cost />
            </TableCell>
            <TableCell><NullablePercentage value={externalCostsOnForecast} /></TableCell>
          </TableRow>
          <TableRow className="briefing-aside-chart-table__row--sum">
            <TableCell className="bold briefing-aside-chart-table__col--first">Gross
              Margin
            </TableCell>
            <TableCell><NV value={grossMargin} /></TableCell>
            <TableCell><ComparedPercentage a={grossMarginOnRevenues}
              b={grossMarginOnForecast} />
            </TableCell>
            <TableCell><NullablePercentage value={grossMarginOnForecast} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="briefing-aside-chart-table__col--first">Payroll</TableCell>
            <TableCell><CostValue value={payroll} /></TableCell>
            <TableCell><ComparedPercentage a={payrollCostsOnRevenues}
              b={payrollCostsOnForecast}
              cost />
            </TableCell>
            <TableCell><NullablePercentage value={payrollCostsOnForecast} /></TableCell>
          </TableRow>
          <TableRow className="briefing-aside-chart-table__row--sum">
            <TableCell className="bold briefing-aside-chart-table__col--first">Net
              Margin
            </TableCell>
            <TableCell><NV value={netMargin} /></TableCell>
            <TableCell><ComparedPercentage a={netMarginOnRevenues}
              b={netMarginOnForecast} />
            </TableCell>
            <TableCell><NullablePercentage value={netMarginOnForecast} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="briefing-aside-chart-table__col--first">G & A</TableCell>
            <TableCell><CostValue value={ga} /></TableCell>
            <TableCell><ComparedPercentage a={gaOnRevenues} b={gaOnForecast} cost /></TableCell>
            <TableCell><NullablePercentage value={gaOnForecast} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="briefing-aside-chart-table__col--first">Marketing</TableCell>
            <TableCell><CostValue value={marketing} /></TableCell>
            <TableCell><ComparedPercentage a={marketingOnRevenues}
              b={marketingOnForecast}
              cost />
            </TableCell>
            <TableCell><NullablePercentage value={marketingOnForecast} /></TableCell>
          </TableRow>
          <TableRow className="briefing-aside-chart-table__row--sum">
            <TableCell className="bold briefing-aside-chart-table__col--first">EBITDA</TableCell>
            <TableCell><NV value={ebitda} /></TableCell>
            <TableCell><ComparedPercentage a={ebitdaOnRevenues} b={ebitdaOnForecast} /></TableCell>
            <TableCell><NullablePercentage value={ebitdaOnForecast} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </div>
);
