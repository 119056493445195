/* eslint-disable class-methods-use-this */
const React = require('react');

module.exports = class MilestoneBarView extends React.Component {
  getMilestoneIconClassName(type) {
    return `project-canvas-gantt__bar-icon wethod-icon wethod-icon-${type.toLowerCase()} wethod-icon-${type.toLowerCase()}--black`;
  }

  render() {
    return (
      <div className="project-canvas-gantt__bar" style={this.props.style}>
        <span className={this.getMilestoneIconClassName(this.props.value.type)}>&nbsp;</span>
      </div>
    );
  }
};
