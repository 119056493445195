'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.TooltipOptionView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#selectOptionTemplate',
    events: {
      'click a': 'onClickedOption',
    },
    onClickedOption: function (event) {
      event.preventDefault();
      this.trigger('tooltip:item:selected', { options: this.model.attributes });
    },
  });
});
