const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const Link = require('../../../../common/components/Link.react');
const NV = require('../../../../common/components/NumericValue.react');

module.exports = ({ projects }) => {
  const getProject = (project) => (
    <span key={project.project.id}>
      <Link
        href={`#project/${project.project.id}/report`}>
        {_.unescape(project.project.name)}
      </Link>
      {' '}
      (
      {_.unescape(project.client.name)}
      )
    </span>
  );

  const getProjects = () => {
    const list = projects.map((project) => getProject(project));
    return <List list={projects} terminator=".">{list}</List>;
  };

  if (projects.length === 1) {
    return (
      <Paragraph>
        The project with the greatest value that we won this
        month is
        {' '}
        {getProjects()}
      </Paragraph>
    );
  }
  if (projects.length) {
    return (
      <Paragraph>
        These are the
        {' '}
        <NV value={projects.length} />
        {' '}
        projects with the greatest value that we won this
        month:
        {' '}
        {getProjects()}
      </Paragraph>
    );
  }
  return null;
};
