const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../FilterAccordion/FilterAccordion.react');
const Checkbox = require('../../../inputs/Checkbox/Checkbox.react');

require('./style.scss');

const CheckboxFilter = ({
  name, value: values, onChange, expanded, label, className, availableOptions,
}) => {
  const isChecked = (value) => values
    .filter((checkedValue) => value === checkedValue).length > 0;

  function handleChange(e) {
    const changedValue = e.target.value;
    let checkedOptions = [];
    if (!isChecked(changedValue)) {
      checkedOptions = values.concat(changedValue);
    } else {
      checkedOptions = values.filter((value) => value !== changedValue);
    }
    onChange(name, checkedOptions);
  }

  const getLabel = (text, color) => (
    <span className="wethod-advanced-filter-checkbox__label">
      <span className="wethod-advanced-filter-checkbox__label-text">{text}</span>
      <span className="wethod-advanced-filter-checkbox__label-color"
        data-testid="wethod-advanced-filter-checkbox-label-color"
        style={{ backgroundColor: color }} />
    </span>
  );

  const getList = () => availableOptions.map((option, index) => {
    const key = `${option.value.replace(' ', '-')}-${index}`;

    return (
      <Checkbox key={key}
        id={`wethod-advanced-filter-checkbox-${key}`}
        name={name}
        onChange={handleChange}
        label={getLabel(option.label, option.color)}
        checked={isChecked(option.value)}
        value={option.value} />
    );
  });

  return (
    <Accordion expanded={expanded} label={label} value={values} className={className}>
      <div className="wethod-advanced-filter-checkbox">
        {getList()}
      </div>
    </Accordion>
  );
};

CheckboxFilter.defaultProps = {
  expanded: false,
  label: '',
  availableOptions: [],
  className: '',
  value: [],
  onChange: null,
};

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param values {array}
   */
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    color: PropTypes.string,
  })),
  className: PropTypes.string,
};

module.exports = CheckboxFilter;
