const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const CompanyHolidays = require('./components/company-holidays/CompanyHolidays.react');

module.exports = ({ store }) => (
  <Provider store={store}>
    <CompanyHolidays />
  </Provider>
);
