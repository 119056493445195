const constants = require('./constants');
const DateService = require('../../../../../../services/DateService');

class ClientCompanyReducer {
  constructor(state) {
    const currentMonth = DateService.getMonth(moment());
    const date = moment().month(currentMonth - 1);
    this.state = {
      ...state,
      date,
      month: date.format('MMMM'),
      waiting: true,
      error: null,
      timeToRead: null,
      cachedOn: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_BRIEFING_REQUEST: {
        return {
          ...state,
          waiting: true,
          error: null,
          date: action.date,
          cachedOn: null,
        };
      }
      case constants.GET_BRIEFING_SUCCESS: {
        return {
          ...state,
          waiting: false,
          ...action.briefing,
          date: state.date,
          month: state.date.format('MMMM'),
          cachedOn: action.cachedOn ? action.cachedOn : null,
        };
      }
      case constants.GET_BRIEFING_FAILURE: {
        return {
          ...state,
          waiting: false,
          error: action.error,
          date: state.date,
        };
      }
      case constants.UPDATE_TIME_TO_READ: {
        return {
          ...state,
          timeToRead: action.time,
        };
      }
      default:
        return state;
    }
  }
}

module.exports = ClientCompanyReducer;
