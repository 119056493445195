'use strict';

var LogOutModel = Backbone.Model.extend({
  urlRoot: APIURL + '/authentication/logout',
});

var USER_API = {
  getLogout: function () {
    var record = new LogOutModel();
    var defer = $.Deferred();

    record.fetch({
      success: function (data) {
        defer.resolve(data);
      },
      error: function () {
        defer.resolve(undefined);
      },
    });

    return defer.promise();
  },
};

Wethod.reqres.setHandler('user:logout', function () {
  return USER_API.getLogout();
});
