const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const TargetComparator = require('../../../containers/sections/Production/TargetComparator');
const NumericValue = require('../../../../common/components/NumericValue.react');
const withRandomize = require('../../../../common/HOCs/withRandomize');
const Link = require('../../../../common/components/Link.react');

class ProductionAgainstTarget extends React.Component {
  getLastWeekProduction() {
    const alternatives = [
      <span>
        Last week we produced{' '}
        <Link href="#reports/production-value">
          <NumericValue
            value={this.props.week_production} />
        </Link>
        {' '}
        overall
      </span>,
      <span>
        The week ended with a production value of{' '}
        <Link href="#reports/production-value">
          <NumericValue
            value={this.props.week_production} />
        </Link>
      </span>,
      <span>
        Last week's production value was{' '}
        <Link href="#reports/production-value">
          <NumericValue
            value={this.props.week_production} />
        </Link>
      </span>,
    ];

    return this.props.randomize(alternatives);
  }

  render() {
    if (!this.props.weekly_target) {
      return (
        <Paragraph>
          {this.getLastWeekProduction()}
          .
        </Paragraph>
      );
    }
    return (
      <Paragraph>
        {this.getLastWeekProduction()}
        , which is{' '}
        <TargetComparator value={this.props.week_production} />
        {' '}
        the average
        weekly production of{' '}
        <NumericValue value={this.props.weekly_target} />
        {' '}
        expected from the target.
      </Paragraph>
    );
  }
}

module.exports = withRandomize(ProductionAgainstTarget);
