const { connect } = require('react-redux');
const Component = require('../../components/PersonalApiToken/Actions.react');
const actions = require('../../actions');
const modals = require('../../modals');

const mapDispatchToProps = (dispatch) => ({
  onAddClick: () => dispatch(actions.showModal(modals.PERSONAL_TOKEN_ADD)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
