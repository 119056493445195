const React = require('react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const PriceList = require('./DailyCostPriceLevelModalRow.react');

module.exports = function DailyCostPriceLevelModalBody(props) {
  const getPriceLists = () => (
    <div className="wethod-budget-header__pricelist__list">
      {props.priceLists.map(
        (priceList) => (
          <PriceList
            key={priceList.id}
            item={priceList} />
        ),
      )}
    </div>
  );
  return (
    <ModalContent isLoading={props.isLoading}>
      <ModalBody>
        {getPriceLists()}
      </ModalBody>
    </ModalContent>
  );
};
