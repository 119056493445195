const { connect } = require('react-redux');
const Component = require('../components/ProfilePicPicker.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  pic: state.pic,
  name: state.name,
  employee: state.id,
  editable: state.editable,
});

const mapDispatchToProps = (dispatch) => ({
  showProfilePicModal: () => dispatch(actions.showProfilePicModal()),
  deleteProfilePic: (employeeId) => dispatch(actions.deleteProfilePic(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
