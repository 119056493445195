module.exports.UPDATE_PERSON_REQUEST = 'UPDATE_PERSON_REQUEST';
module.exports.UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';

module.exports.PERSON_TAG_SAVE_REQUEST = 'PERSON_TAG_SAVE_REQUEST';
module.exports.PERSON_TAG_SAVE_SUCCESS = 'PERSON_TAG_SAVE_SUCCESS';

module.exports.PERSON_TAG_DELETE_REQUEST = 'PERSON_TAG_DELETE_REQUEST';
module.exports.PERSON_TAG_DELETE_SUCCESS = 'PERSON_TAG_DELETE_SUCCESS';

module.exports.PERSON_PHONE_ADD_REQUEST = 'PERSON_PHONE_ADD_REQUEST';
module.exports.PERSON_PHONE_ADD_SUCCESS = 'PERSON_PHONE_ADD_SUCCESS';

module.exports.PERSON_PHONE_EDIT_REQUEST = 'PERSON_PHONE_EDIT_REQUEST';
module.exports.PERSON_PHONE_EDIT_SUCCESS = 'PERSON_PHONE_EDIT_SUCCESS';

module.exports.PERSON_PHONE_DELETE_REQUEST = 'PERSON_PHONE_DELETE_REQUEST';
module.exports.PERSON_PHONE_DELETE_SUCCESS = 'PERSON_PHONE_DELETE_SUCCESS';

module.exports.RESOURCE_SAVE_REQUEST = 'RESOURCE_SAVE_REQUEST';
module.exports.RESOURCE_SAVE_SUCCESS = 'RESOURCE_SAVE_SUCCESS';

module.exports.RESOURCE_DELETE_REQUEST = 'RESOURCE_DELETE_REQUEST';
module.exports.RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';

module.exports.PERSON_NOTE_SAVE_REQUEST = 'PERSON_NOTE_SAVE_REQUEST';
module.exports.PERSON_NOTE_SAVE_SUCCESS = 'PERSON_NOTE_SAVE_SUCCESS';

module.exports.COMPANY_SAVE_REQUEST = 'COMPANY_SAVE_REQUEST';
module.exports.COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS';
