const React = require('react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const CreationRow = require('../../containers/work-hour-capacity/WorkHourCapacityCreationRow');
const EditRow = require('../../containers/work-hour-capacity/WorkHourCapacityEditRow');

const TableBodyRow = ({ item }) => {
  const isNew = item.id == null || item.id === 'new-work-hour-capacity';

  const weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  return (
    <ShowIf condition={isNew}
      showElse={<EditRow item={item} weekDays={weekDays} />}>
      <CreationRow item={item} weekDays={weekDays} />
    </ShowIf>
  );
};

module.exports = TableBodyRow;
