const React = require('react');
const PropTypes = require('prop-types');
const PickerBase = require('../DayPicker.react');
const Button = require('../../Select/BasicSelect/BasicSelectButton.react');

const BasicDayPicker = ({ ...rest }) => (
  <PickerBase button={Button} {...rest} />
);

BasicDayPicker.defaultProps = {
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
};

BasicDayPicker.propTypes = {
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
};

module.exports = BasicDayPicker;
