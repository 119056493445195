const React = require('react');
const PropTypes = require('prop-types');
const { CSSTransition } = require('react-transition-group');
const Manager = require('./SegmentManager.react');

const Segment = ({ show, segment, children, onClose, onSave, canSave, errors, updateErrors }) => (
  <CSSTransition in={show}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <Manager show={show}
      segment={segment}
      onClose={onClose}
      onSave={onSave}
      canSave={canSave}
      errors={errors}
      updateErrors={updateErrors}>
      {children}
    </Manager>
  </CSSTransition>
);

Segment.propTypes = {
  /**
   * Segment
   * contains the id, the name and the filters
   * @see AdvancedSearchManager
   */
  segment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filters: PropTypes.shape({}),
  }),
  /**
   * Whether to show or hide the advanced search sidebar
   * @see AdvancedSearchManager
   */
  show: PropTypes.bool.isRequired,
  /**
   * @see Sidebar
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @see Sidebar
   */
  onSave: PropTypes.func.isRequired,
  /**
   * List of filters shown in the advanced search
   * @see AdvancedSearchManager
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    // Required check
    if (!prop) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`);
    }

    React.Children.forEach(prop, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.name === undefined || child.props.name === null) {
          // 'Name' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
        if (child.props.label === undefined || child.props.label === null) {
          // 'Label' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
      } else {
        // Type check
        error = new Error(`Invalid prop \`children\` of type \`${typeof child}\` supplied to \`${componentName}\`, children should be valid elements.`);
      }
    });
    return error;
  }, // isRequired
  canSave: PropTypes.bool,
  errors: PropTypes.shape({}),
  updateErrors: PropTypes.func.isRequired,
};

Segment.defaultProps = {
  canSave: true,
  errors: {},
  segment: { id: null, name: '', filters: {} },
  children: undefined,
};

module.exports = Segment;
