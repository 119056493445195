/* eslint-disable react/sort-comp */
const React = require('react');
const KeyResult = require('../../containers/Objectives/KeyResult');
const Slider = require('../../../../../../../common/react/Slider.react');

module.exports = class ObjectiveItem extends React.Component {
  getKeyResults() {
    return this.props.key_results.map((item) => (
      <KeyResult key={item.id}
        {...item}
        quarterId={this.props.quarterId}
        quarter_status={this.props.quarter_status}
        objectiveId={this.props.id} />
    ));
  }

  getProgress() {
    return this.props.key_results
      .reduce((totalProgress, keyResult) => ((keyResult.progress / keyResult.target) * 100)
        / this.props.key_results.length + totalProgress, 0);
  }

  getFormattedProgress() {
    return parseInt(this.getProgress());
  }

  onEditButtonClick() {
    this.props.showObjectiveModal({ ...this.props });
  }

  isQuarterInDraft() {
    return this.props.quarter_status === 0;
  }

  getEditButton() {
    if (this.props.editable && this.isQuarterInDraft()) {
      return (
        <button type="button"
          onClick={this.onEditButtonClick.bind(this)}
          className="wethod-icon-button profile-objectives__item-edit">
          <div className="wethod-icon-medium-edit wethod-icon-medium-edit--black" />
        </button>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile-objectives__item">
        <div className="profile-objectives__item-header">
          <div className="profile-objectives__item-title profile-objective__col-title">{this.props.name}</div>
          <div className="profile-objectives__item-progress profile-objective__col-progress">
            <div className="profile-objectives__item-progress-slider profile-objective__col-progress-slider">
              <Slider max={100} name="progress" value={this.getFormattedProgress()} disabled />
            </div>
            <span
              className="profile-objectives__item-progress-amount profile-objective__col-progress-amount">
              {this.getFormattedProgress()}
              {' '}
              %
            </span>
          </div>
          {this.getEditButton()}
        </div>
        <div className="profile-objectives__item-key-result-collection">
          {this.getKeyResults()}
        </div>
      </div>
    );
  }
};
