/* eslint-disable react/sort-comp,react/no-did-update-set-state,guard-for-in,no-restricted-syntax,
react/no-access-state-in-setstate */
const React = require('react');
const ImportantInfo = require('../../containers/ImportantInfo/ImportantInfo');
const ImportantInfoEditable = require('../../containers/ImportantInfo/ImportantInfoEditable');
const Card = require('../Card.react');

module.exports = class ImportantInfoController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      collapsed: false,
      errors: {},
      person: {},
    };

    this.messages = {
      name: {
        required: 'Please add a first name',
      },
      surname: {
        required: 'Please add a last name',
      },
      company: {
        required: 'Please select a company',
      },
    };
  }

  hasBeenSaved(prevProps, prevState) {
    return (prevState.editable && prevProps.isSaving)
      && !this.props.isSaving;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.hasBeenSaved(prevProps, prevState)) this.setState({ editable: false });
  }

  getErrorMessages() {
    const errors = { ...this.state.errors };
    for (const error in errors) {
      errors[error] = errors[error].map((constraint) => this.messages[error][constraint]);
    }
    return errors;
  }

  /**
   * Returns true if all inputs are valid.
   * @returns {boolean}
   */
  isValid() {
    const { errors } = this.state;
    for (const error in errors) {
      if (errors[error].length > 0) return false;
    }
    return true;
  }

  onValidate(name, inputErrors) {
    const { errors } = this.state;
    errors[name] = inputErrors;
    this.setState({ errors });
  }

  onChange(name, value) {
    const person = { ...this.state.person };
    person[name] = value;
    this.setState({ person });
  }

  getFeedback() {
    if (this.props.isSaving) return 'Saving ';
    return '';
  }

  getContent() {
    if (this.state.editable) {
      return (
        <ImportantInfoEditable errorMessages={this.getErrorMessages()}
          onValidate={this.onValidate.bind(this)}
          onChange={this.onChange.bind(this)} />
      );
    }
    return <ImportantInfo />;
  }

  onEditClick() {
    this.setState({
      editable: true,
      collapsed: false,
    });
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getCompany() {
    if (this.state.person.company) return this.state.person.company;
    return this.props.person.client;
  }

  onSaveClick() {
    if (this.isValid()) {
      this.props.save({
        ...this.props.person,
        ...this.state.person,
        client: this.getCompany(),
      });
    }
  }

  render() {
    return (
      <Card allowEdit
        editable={this.state.editable}
        feedback={this.getFeedback()}
        onEditClick={this.onEditClick.bind(this)}
        onSaveClick={this.onSaveClick.bind(this)}>
        {this.getContent()}
      </Card>
    );
  }
};
