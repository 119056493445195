module.exports = {
  /**
   * Returns the list of language levels available with their id and name
   * @returns {[{id, name}]} array of levels
   */
  getLanguageLevels: () => [
    {
      id: 0,
      name: 'Native',
    },
    {
      id: 1,
      name: 'C2',
    },
    {
      id: 2,
      name: 'C1',
    },
    {
      id: 3,
      name: 'B2',
    },
    {
      id: 4,
      name: 'B1',
    },
    {
      id: 5,
      name: 'A2',
    },
    {
      id: 6,
      name: 'A1',
    },
  ],

  /**
   * Returns the name of the language level corresponding to the given id.
   * No name is returned if no corresponding level is found.
   * @param languageId
   * @returns {null|string}
   */
  getLanguageLevelName: (languageId) => {
    const language = Wethod.getLanguageLevels().filter((lang) => lang.id === languageId);

    if (language && language.length) {
      return language[0].name;
    }

    return null;
  },
};
