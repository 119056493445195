const React = require('react');
const PropTypes = require('prop-types');
const TextAreaBase = require('../TextArea.react');
const Decorator = require('../../../InputDecorator/BasicInputDecorator/BasicInputDecorator.react');

const BasicTextArea = ({
  required, inputRef, helperText, errorText, disabled, readOnly, ...rest
}) => (
  <Decorator required={required}
    disabled={disabled}
    readOnly={readOnly}
    inputRef={inputRef}
    helperText={helperText}
    errorText={errorText}>
    <TextAreaBase required={required} disabled={disabled} readOnly={readOnly} {...rest} />
  </Decorator>
);

BasicTextArea.defaultProps = {
  value: '',
  id: undefined,
  className: undefined,
  placeholder: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  size: undefined,
  helperText: undefined,
  errorText: undefined,
  onFocus: undefined,
  rootRef: undefined,
  validate: undefined,
  required: undefined,
  onBlur: undefined,
  inputRef: undefined,
  onChange: undefined,
};

BasicTextArea.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  /** The width, in characters * */
  size: PropTypes.number,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /** Function to access to inptut DOM element
   * @param input {HTMLElement} DOM element ref
   * */
  rootRef: PropTypes.func,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
};

module.exports = BasicTextArea;
