const React = require('react');
const Project = require('./Project.react');
const Polyfill = require('../../../../../../services/Polyfill');
const EmptyPlan = require('./EmptyPlan.react');

class Projects extends React.Component {
  getProjects() {
    const projectsArray = Polyfill.values(this.props.projects);
    const projects = projectsArray.reduce((result, project, index) => {
      if (project.hotness !== 0 && !project.removed) {
        result.push(
          <Project
            id={project.id}
            project={project}
            days={project.days}
            key={index.toString()}
            eventTypes={this.props.eventTypes}
            toggleVisibility={this.props.toggleVisibility}
            eventAdded={this.props.eventAdded}
            eventDeleted={this.props.eventDeleted} />,
        );
      }
      return result;
    }, []);

    if (projects.length > 0) return projects;
    return <EmptyPlan />;
  }

  render() {
    return (
      <div className="planning__employees">
        {this.getProjects()}
      </div>
    );
  }
}

module.exports = Projects;
