'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager) {
  this.RuleParser = {
    parseRule: function (condition) {
      if (condition.type === 'terminal') {
        return new RuleManager.TerminalModel({
          type: condition.type,
          operator: condition.operator,
          left: new RuleManager.TerminalOperandModel(condition.subject),
          right: new RuleManager.TerminalOperandModel(condition.target),
        });
      }
      if (condition.type === 'compound') {
        return new RuleManager.GroupModel({
          type: condition.type,
          operator: condition.operator,
          left: this.parseRule(condition.left),
          right: this.parseRule(condition.right),
        });
      }
      if (condition.type === 'absolute') {
        return new RuleManager.AbsoluteModel({
          type: condition.type,
          value: condition.value,
        });
      }
      return null;
    },
  };
});
