const React = require('react');
const _ = require('underscore');
const Select = require('../../../../../../common/react/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('../../../../../../common/react/Menu/ListMenu/MenuItemColorful.react');
const InputValidator = require('../../../../../../common/react/InputValidator/InputValidator.react');

const JobOrderCategorySelect = ({
  updateErrors, value, color, onChange, error, name, availableValues, readOnly, disabled,
}) => {
  function handleChange(e) {
    const selected = availableValues.filter((joc) => joc.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues.map((joc) => (
    <SelectItem key={joc.id}
      value={joc.id}
      color={joc.color}>
      {_.unescape(joc.name)}
    </SelectItem>
  ));

  return (
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <Select readOnly={readOnly}
        disabled={!readOnly && disabled}
        value={value}
        id="sidebar-joc"
        label="Job order category"
        color={color}
        onChange={handleChange}
        name={name}
        errorText={error}>
        {getOptions()}
      </Select>
    </InputValidator>
  );
};

module.exports = JobOrderCategorySelect;
