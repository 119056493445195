const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');

module.exports = ({
  month, capex, projectsCount, externalCostsCapex, payrollCapex,
}) => {
  const getProjects = () => {
    if (projectsCount) {
      return (
        <span>
          for <NV value={projectsCount} /> projects
        </span>
      );
    }
    return null;
  };

  return (
    <Paragraph>
      Finally, in {month} we invested <NV value={capex} /> in CapEx {getProjects()}. Out of
      {' '}
      which, <NV value={externalCostsCapex} /> of external costs and <NV value={payrollCapex} />
      {' '}
      for capitalizing internal labor.
    </Paragraph>
  );
};
