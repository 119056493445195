const PathComponent = require('./PathComponent');

module.exports = class ExternalDrive {
  constructor(attributes) {
    this.enabled = attributes.enabled;
    this.error = attributes.error;
    this.root = {
      id: attributes.root_id,
      name: attributes.root_name,
      url: attributes.root_url,
    };
    const projectFolder = attributes.storage;
    this.projectFolder = null;
    if (projectFolder) {
      this.projectFolder = new PathComponent(projectFolder.external_id, projectFolder.name,
        projectFolder.url);
    }
  }

  isEnabled() {
    return this.enabled;
  }

  hasProjectFolder() {
    return this.projectFolder !== null;
  }
};
