const { connect } = require('react-redux');
const Component = require('../components/Supplier.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  statusEnabled: state.statusEnabled,
  canSetStatus: state.canSetStatus,
  statuses: state.statuses,
  isWaiting: state.waitingFor.filter((key) => key === 'save-supplier').length > 0,
  showRating: state.canSeeRating,
});

const mapDispatchToProps = (dispatch) => ({
  updateSupplier: (supplier) => dispatch(actions.updateSupplier(supplier)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
