const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Autocomplete = {
  getPlacePredictions(options) {
    const query = `?${HTTPService.buildQueryString(options)}`;

    return request({
      method: 'get',
      url: `/autocomplete/place${query}`,
    });
  },
  getProjectsAutocomplete(keyword) {
    return request({
      method: 'get',
      url: `/autocomplete/projects?hints=${keyword}`,
    });
  },
  getProjectsByNameOrJobOrderAutocomplete(keyword) {
    return request({
      method: 'get',
      url: `/autocomplete/projects-by-project-name-or-job-order?hints=${keyword}`,
    });
  },
  getEmployeesAutocomplete(keyword) {
    return request({
      method: 'get',
      url: `/autocomplete/employee-by-employee-name-or-surname?hints=${keyword}`,
    });
  },
};

module.exports = Autocomplete;
