/* eslint-disable no-case-declarations */
const constants = require('./constants');

class BasketReducer {
  constructor(state) {
    this.state = {
      ...state,
      filteredItems: [],
      focusedItem: null,
      isLoading: false,
      isSaving: false,
      showSidebar: false,
      lastPage: false,
      keyword: state.keyword ? `id:${state.keyword}` : null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.FILTER_OPPORTUNITIES_REQUEST:
        return ({
          ...state,
          isLoading: true,
          filteredItems: [],
          lastPage: false,
        });
      case constants.LOAD_MORE_OPPORTUNITIES_REQUEST:
        return ({
          ...state,
          isLoading: true,
        });
      case constants.FILTER_OPPORTUNITIES_SUCCESS:
        return ({
          ...state,
          isLoading: false,
          filteredItems: action.items,
          lastPage: action.items.length < action.size,
          keyword: action.keyword,
        });
      case constants.LOAD_MORE_OPPORTUNITIES_SUCCESS:
        return ({
          ...state,
          isLoading: false,
          filteredItems: state.filteredItems.concat(action.items),
          lastPage: action.items.length < action.size,
        });
      case constants.SAVE_OPPORTUNITY_REQUEST:
      case constants.DELETE_OPPORTUNITY_REQUEST:
      case constants.SAVE_COMPANY_REQUEST:
        return ({
          ...state,
          isSaving: true,
        });
      case constants.SAVE_OPPORTUNITY_SUCCESS:
        const itemIndex = state.filteredItems.findIndex((item) => item.id === action.item.id);
        let newItems = [];
        if (itemIndex > -1) { // Old item, update
          newItems = state.filteredItems.slice();
          newItems[itemIndex] = action.item;
        } else { // New item, create and put on top
          newItems = [action.item].concat(state.filteredItems);
        }
        return ({
          ...state,
          isSaving: false,
          filteredItems: newItems,
          focusedItem: action.item,
        });
      case constants.DELETE_OPPORTUNITY_SUCCESS:
        return ({
          ...state,
          isSaving: false,
          filteredItems: state.filteredItems.filter((item) => item.id !== action.item.id),
          focusedItem: null,
          showSidebar: false,
        });
      case constants.SAVE_COMPANY_SUCCESS:
        return ({
          ...state,
          isSaving: false,
          companies: state.companies.concat({
            ...action.company,
            name: action.company.corporate_name,
          }),
        });
      case constants.GET_COMPANIES_REQUEST:
        return ({
          ...state,
          isLoadingCompanies: true,
        });
      case constants.GET_COMPANIES_SUCCESS:
        return ({
          ...state,
          isLoadingCompanies: false,
          companies: action.items.map((company) => ({
            ...company,
            name: company.corporate_name,
          })),
        });
      case constants.GET_EMPLOYEES_REQUEST:
        return ({
          ...state,
          isLoadingEmployees: true,
        });
      case constants.GET_EMPLOYEES_SUCCESS:
        return ({
          ...state,
          isLoadingEmployees: false,
          employees: action.items.map((employee) => ({
            ...employee,
            name: employee.hint,
          })),
        });
      case constants.GET_PM_REQUEST:
        return ({
          ...state,
          isLoadingPM: true,
        });
      case constants.GET_PM_SUCCESS:
        return {
          ...state,
          isLoadingPM: false,
          pm: action.items.map((employee) => ({
            ...employee,
            name: employee.hint,
          })),
        };
      case constants.SHARE_OPPORTUNITY_REQUEST:
        return {
          ...state,
          isSaving: true,
        };
      case constants.SHARE_OPPORTUNITY_SUCCESS:
        return {
          ...state,
          isSaving: false,
          modalToShow: null,
        };
      case constants.SHOW_SHARE_MODAL:
        return {
          ...state,
          showSidebar: false,
          modalToShow: 'share',
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          focusedItem: null,
        };
      case constants.SHOW_SIDEBAR:
        return ({
          ...state,
          showSidebar: true,
          focusedItem: action.item,
        });
      case constants.CLOSE_SIDEBAR:
        return ({
          ...state,
          showSidebar: false,
          focusedItem: null,
        });
      default:
        return state;
    }
  }
}

module.exports = BasketReducer;
