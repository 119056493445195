const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  limit: state.pageLimit,
  keyword: state.keyword,
  year: state.year,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (offset, limit, keyword, year) => dispatch(actions
    .getItems(offset, limit, keyword, year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
