const { connect } = require('react-redux');
const Component = require('../components/BasicInfo.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  name: state.name,
  tags: state.tags,
  level: state.level,
  jobTitle: state.jobTitle,
});

const mapDispatchToProps = (dispatch) => ({
  showProfilePicModal: () => dispatch(actions.showProfilePicModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
