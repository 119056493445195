const { connect } = require('react-redux');
const Component = require('../../components/sidebar/PricelistSidebar.react');
const actions = require('../../actions');

const getFocusedPricelist = (pricelists, focusedPricelistId, levels) => {
  const pricelist = pricelists.find((p) => p.id === focusedPricelistId);
  if (pricelist) {
    // add new levels to pricelist.price_list_levels
    pricelist.price_list_levels = levels?.map((level) => pricelist.price_list_levels
      .find((l) => l.level.id === level.id)
        ?? {
          price: 0.00,
          cost: 0,
          level,
        });
    return pricelist;
  }
  return {
    default_for_client: false,
    default_for_company: false,
    client: null,
    price_list_levels: levels?.map((level) => ({
      price: 0,
      cost: 0,
      level,
    })),
  };
};

const mapStateToProps = (state) => ({
  item: getFocusedPricelist(state.pricelists, state.focusedItem, state.levels),
  levels: state.levels,
  isSaving: state.waitingFor.filter((key) => key === 'save-pricelist').length > 0,
  canEdit: state.permissions.edit,
  editMode: state.editMode,
  unsavedChanges: state.unsavedChanges,
  companyDefaultPricelist: state.pricelists.find((pricelist) => pricelist.default_for_company),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  changeSidebarEditMode: (editMode) => dispatch(actions.changeSidebarEditMode(editMode)),
  changeUnsavedChanges: (unsavedChanges) => dispatch(actions.changeUnsavedChanges(unsavedChanges)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
