const React = require('react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');

module.exports = class Actions extends React.Component {
  handleClick() {
    if (!this.props.isSaving && !this.props.isLoading) {
      this.props.addOpportunity();
    }
  }

  getAddButtonClassName() {
    let style = 'basket__add-button wethod-button';
    if (this.props.isSaving || this.props.isLoading) {
      style += ' disabled';
    }
    return style;
  }

  render() {
    return (
      <div className="basket__actions-wrapper">
        <SectionHeader current_section="Basket"
          preview_anchor_id="basket"
          helper_url="pipeline/index/#basket" />
        <div className="basket__actions wethod-section-actions">
          <button type="button"
            className={this.getAddButtonClassName()}
            onClick={this.handleClick.bind(this)}>
            Add
          </button>
        </div>
      </div>
    );
  }
};
