'use strict';

Wethod.module('FinanceApp', function (FinanceApp, Wethod, Backbone, Marionette, $) {
  this.ExportModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/export/entity?select=' + this.options.select + '&download=false';
    },
  });

  var API = {
    getExport: function (options) {
      var record = new FinanceApp.ExportModel(options);
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    downloadInvoiceXmlExport: function (id) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/export/invoice-fpa/' + id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code === 200) {
          // Download file simulating a click on a href
          window.location.href = response.data.download_url;
          defer.resolve(response.data);
        } else {
          defer.reject(response);
        }
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },

    downloadInvoicePdfExport: function (id) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/export/invoice-fpa-pdf/' + id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code === 200) {
          // Download file simulating a click on a href
          window.location.href = response.data.download_url;
          defer.resolve(response.data);
        } else {
          defer.reject(response);
        }
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('send:order:export:mail', function () {
    return API.getExport({ select: 'order' });
  });
  Wethod.reqres.setHandler('get:invoice:export:email', function () {
    return API.getExport({ select: 'invoice' });
  });
  Wethod.reqres.setHandler('get:invoice:export:xml', function (id) {
    return API.downloadInvoiceXmlExport(id);
  });
  Wethod.reqres.setHandler('get:invoice:export:pdf', function (id) {
    return API.downloadInvoicePdfExport(id);
  });
});
