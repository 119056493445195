const React = require('react');
const Header = require('../SubsectionHeader.react');
const Strategy = require('../../containers/timesheet/TimesheetStrategy');
const EmailReminder = require('../../containers/timesheet/TimesheetReminder');
const TimeLimit = require('../../containers/timesheet/TimesheetTimeLimit');

const TimesheetSettings = () => (
  <div>
    <Header subtitle="Timesheet" id="timesheet" />
    <EmailReminder />
    <Strategy />
    <TimeLimit />
  </div>
);

module.exports = TimesheetSettings;
