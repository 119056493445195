const React = require('react');
const View = require('./ItemNameCellView.react');
const Edit = require('./ItemNameCellEdit.react');

module.exports = ({ canEdit, editName, ...rest }) => {
  if (canEdit) {
    return <Edit editName={editName} {...rest} />;
  }
  return <View {...rest} />;
};
