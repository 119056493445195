const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

const AllocationRejectModal = ({
  item, isSaving, onCloseClick, rejectAllocation,
}) => {
  function handleReject() {
    rejectAllocation(item.id);
  }

  return (
    <Modal title="Reject allocation request" onCancelClick={onCloseClick}>
      <p>
        Are you sure you want to reject this request?
      </p>
      <br />
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Back</Button>
        <Button onClick={handleReject} disabled={isSaving}>Yes</Button>
      </div>
      <div className="wethod-modal2__feedback">
        <ShowIf condition={isSaving}>
          <span>Saving...</span>
        </ShowIf>
      </div>
    </Modal>
  );
};

module.exports = AllocationRejectModal;
