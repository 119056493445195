const { connect } = require('react-redux');
const Component = require('../../../components/modals/Skills/SkillsBody.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  tags: state.skills,
  knownTags: state.known_skills,
  waiting: state.waiting,
  employee: state.id,
});

const mapDispatchToProps = (dispatch) => ({
  addSkill: (skill, employeeId) => dispatch(actions.addSkill(skill, employeeId)),
  deleteSkill: (skill, employeeId) => dispatch(actions.deleteSkill(skill, employeeId)),
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
