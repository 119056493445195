const React = require('react');
const PropTypes = require('prop-types');
const FormatService = require('../../../services/FormatService');

const NumberFormatter = ({ children, decimalPlaces, explicitSign, className, hideZero }) => {
  if (Number.isNaN(children)) {
    throw new TypeError('children must be number');
  }

  let formattedNumber = FormatService.formatDecimalNumber(children, decimalPlaces, explicitSign);

  if (children === 0 && hideZero) {
    formattedNumber = '';
  }

  return (
    <span className={className}>{formattedNumber}</span>
  );
};

NumberFormatter.defaultProps = {
  className: '',
  decimalPlaces: 0,
  explicitSign: false,
  hideZero: false,
};

NumberFormatter.propTypes = {
  /**
   * The number to format.
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  decimalPlaces: PropTypes.number,
  explicitSign: PropTypes.bool,
  /**
   * Display empty when value is 0.
   */
  hideZero: PropTypes.bool,
};

module.exports = NumberFormatter;
