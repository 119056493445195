'use strict';

Wethod.module('TravelApp', function (TravelApp) {
  this.startWithParent = false;

  var API = {
    showTravel: function (id) {
      TravelApp.Travel.Controller.showTravel(id);
    },
  };

  TravelApp.on('start', function (options) {
    API[options.foo](options.id);
  });
});
