const { connect } = require('react-redux');
const Component = require('../../components/Kpi/RevenuesReport.react');

const mapStateToProps = (state) => ({
  clientId: state.id,
  timeFilter: state.timeFilter,
  targets: state.filteredRevenuesTargets,
  canSeeEconomicsRevenues: state.revenues_permissions,
});

module.exports = connect(mapStateToProps)(Component);
