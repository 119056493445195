const React = require('react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const AlertsTable = require('../../../containers/dashboard/alerts/AlertsTable');
const AlertDetailsTable = require('../../../containers/dashboard/alerts/AlertDetailsTable');

module.exports = class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
    };
  }

  handleRowClick(row) {
    this.setState({ selectedRow: row });
  }

  handleBackClick() {
    this.setState({ selectedRow: null });
  }

  render() {
    return (
      <div>
        <ShowIf condition={this.state.selectedRow != null}
          showElse={(
            <AlertsTable
              selectRow={this.handleRowClick.bind(this)} />
          )}>
          <AlertDetailsTable
            selectedRow={this.state.selectedRow}
            back={this.handleBackClick.bind(this)} />
        </ShowIf>
      </div>
    );
  }
};
