'use strict';

Wethod.module('SettingsApp.AlertsManager', function (AlertsManager, Wethod, Backbone, Marionette) {
  this.StructureLayout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#alertsEngineTemplate',
    regions: {
      sectionHeader: '[data-region="alertsEngineSectionHeader"]',
      body: '[data-region="alertsEngineBody"]',
    },
  });

  // LOADING MORE DATA
  this.LoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });
});
