const React = require('react');
const _ = require('underscore');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const PurchaseOrderCell = ({
  readOnly, value, onBlur, onFocus, name, label, style, metadataService,
}) => (
  <TableCell className="pipeline-item__client-po" style={style}>
    <InputMetadataManager metadataService={metadataService}
      name={name}
      canEdit={!readOnly}>
      <TextField
        value={_.unescape(value)}
        onBlur={onBlur}
        onFocus={onFocus}
        label={label} />
    </InputMetadataManager>
  </TableCell>
);

module.exports = PurchaseOrderCell;
