const React = require('react');

module.exports = class ConsumptionReportChart extends React.Component {
  static getDottedSerie(actual, progress, name, color, nameXpos) {
    return {
      color,
      enableMouseTracking: false,
      type: 'line',
      lineWidth: 1,
      dashStyle: 'Dot',
      tooltip: {
        enabled: false,
        formatter() {
          return false;
        },
      },
      data: [{
        x: parseFloat(progress),
        y: 0,
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          format: '{x}',
          crop: false,
          overflow: 'none',
          y: +21,
        },
      },
      {
        x: parseFloat(progress),
        y: parseFloat(actual),
        marker: {
          enabled: true,
          symbol: 'circle',
        },
        dataLabels: {
          enabled: true,
          format: ' ',
          color,
          shadow: false,
          crop: false,
          overflow: 'none',
          x: nameXpos, // -45,
          y: 4,
          style: {
            textShadow: 'none',
            background: 'rgb(255, 255, 255)',
            fontWeight: 'normal',
            fontSize: '10px',
          },
        },
      },
      {
        x: 0,
        y: parseFloat(actual),
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none',
          x: -12,
          y: +10,
        },
      }],
    };
  }

  static getMarkerSerie(actual, progress, name, color) {
    return {
      name,
      color,
      data: [
        {
          x: parseFloat(progress),
          y: parseFloat(actual),
          marker: {
            enabled: true,
            symbol: 'circle',
          },
        },
      ],
    };
  }

  static chart(series, maxYAxis) {
    return {
      chart: {
        marginLeft: 40,
        marginBottom: 40,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          margin: 20,
          text: 'PROJECT STATUS % (days)',
          align: 'high',
          style: {
            fontSize: '10px',
            color: '#333',
          },
        },
        lineWidth: 1,
        max: 100,
        tickLength: 0,
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          margin: 28,
          text: 'BUDGET CONSUMPTION % (days)',
          align: 'high',
          style: {
            fontSize: '10px',
            color: '#333',
          },
        },
        lineWidth: 1,
        min: 0,
        max: maxYAxis,
      },
      series,
      tooltip: {
        snap: 5,
        hideDelay: 100,
        borderWidth: 0,
        formatter() {
          return this.series.name;
        },
      },
      plotOptions: {
        series: {
          stickyTracking: false,
        },
        line: {
          dashStyle: 'Dot',
        },
      },
    };
  }

  static chartSeries(seriesData) {
    let markerSerie;
    const series = [{
      name: null,
      enableMouseTracking: false,
      tooltip: {
        enabled: false,
        formatter() {
          return false;
        },
      },
      lineWidth: 1,
      type: 'line',
      marker: {
        enabled: false,
      },
      color: '#DCDCDE',
      zIndex: 1,
      data: [
        [0, 0],
        [100, 100],
      ],
      dashStyle: 'solid',
    }];
    let serie;

    // retta che unisce forecast a projected
    const forcastProjectSerie = {
      color: 'rgb(204,204,204)',
      enableMouseTracking: false,
      type: 'line',
      lineWidth: 1,
      dashStyle: 'Dot',
      tooltip: {
        enabled: false,
        formatter() {
          return false;
        },
      },
      marker: {
        enabled: false,
      },
      data: [],
    };

    _.each(seriesData, (data) => {
      serie = {};
      markerSerie = {};

      serie.lineWidth = 1;
      serie.marker = {
        enabled: true,
        symbol: 'circle',
      };

      switch (data.versions) {
        case 'actual': // Forecast
          serie = ConsumptionReportChart.getDottedSerie(data.actual, data.progress, 'Forecast', 'rgb(72,165,244)', -45);
          serie.zIndex = 2;

          markerSerie = ConsumptionReportChart.getMarkerSerie(data.actual, data.progress, 'Forecast', 'rgb(72,165,244)');
          markerSerie.zIndex = 2;

          series.push(serie);
          series.push(markerSerie);

          // aggiungo le coordinate del punto forecast
          forcastProjectSerie.data.push([data.progress, data.actual]);

          break;
        case 'ghost': // Baseline
          serie = ConsumptionReportChart.getDottedSerie(data.actual, data.progress, 'Baseline', 'rgb(219,77,105)', -28);
          serie.zIndex = 1;

          markerSerie = ConsumptionReportChart.getMarkerSerie(data.actual, data.progress, 'Baseline', 'rgb(219,77,105)');
          markerSerie.zIndex = 1;

          series.push(serie);
          series.push(markerSerie);

          break;
        case 'wasted': // Wasted
          serie = ConsumptionReportChart.getDottedSerie(data.actual, data.progress, 'Wasted', 'rgb(255,185, 72)', -24);
          serie.zIndex = -1;

          markerSerie = ConsumptionReportChart.getMarkerSerie(data.actual, data.progress, 'Wasted', 'rgb(255,185, 72)');
          markerSerie.zIndex = 1;

          series.push(serie);
          series.push(markerSerie);
          break;
        case 'forecast': // Projected
          serie = ConsumptionReportChart.getDottedSerie(data.actual, data.progress, 'Projected', 'rgb(78, 216, 141)', -28);
          serie.zIndex = 1;

          markerSerie = ConsumptionReportChart.getMarkerSerie(data.actual, data.progress, 'Projected', 'rgb(78, 216, 141)');
          markerSerie.zIndex = 1;

          series.push(serie);
          series.push(markerSerie);

          // aggiungo le coordinate del punto projected
          forcastProjectSerie.data.push([data.progress, data.actual]);

          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const name = `version ${data.versions}<br/>${moment(data.snapshot_at)
            .format('DD/MM/YYYY')}`;
          serie = ConsumptionReportChart.getMarkerSerie(data.actual, data.progress, name, 'rgb(204,204,204)');

          series.push(serie);
      }
    });

    // aggiungo la retta che collega forecast a projected
    series.push(forcastProjectSerie);

    return series;
  }

  consumptionStatusChart(data) {
    const consumptionStatusdata = data;

    // init + versions
    const chartData = _.isUndefined(consumptionStatusdata.versions) ? []
      : _.map(consumptionStatusdata.versions);

    // l'ordine di aggiunta a chartData determina con che gerarchia vengono disegnate

    // FORECAST
    const actualData = {
      actual: consumptionStatusdata.actual,
      progress: consumptionStatusdata.progress,
      versions: 'actual',
    };
    chartData.push(actualData);

    // BASELINE
    if (!_.isEmpty(consumptionStatusdata.ghost)) {
      const ghostData = {
        actual: consumptionStatusdata.ghost.actual,
        progress: consumptionStatusdata.ghost.progress,
        versions: 'ghost',
      };
      chartData.push(ghostData);
    }

    // PROJECTED
    const forecastData = {
      actual: consumptionStatusdata.forecast.actual,
      progress: consumptionStatusdata.forecast.progress,
      versions: 'forecast',
    };
    chartData.push(forecastData);

    // WASTED
    // add the wasted serie if wasted hours is > 0
    if (consumptionStatusdata.timetracking.wasted_hours > 0) {
      const wastedData = {
        actual: consumptionStatusdata.wasted.actual,
        progress: consumptionStatusdata.wasted.progress,
        versions: 'wasted',
      };
      chartData.push(wastedData);
    }

    // transform the data in an highcharts series
    const chartSeriesData = ConsumptionReportChart.chartSeries(chartData);

    // find the max Y value
    this.maxYAxis = 100;
    _.each(chartData, (serie) => {
      if (parseInt(serie.actual) > this.maxYAxis) {
        this.maxYAxis = parseInt(serie.actual);
      }
    });

    return chartSeriesData;
  }

  mountChart(container) {
    $(container)
      .highcharts(ConsumptionReportChart
        .chart(this.consumptionStatusChart(this.props.data), this.maxYAxis));
  }

  render() {
    return (
      <div
        className="wethod-budget__consumption-report"
        ref={(chartContainer) => (this.mountChart(chartContainer))} />
    );
  }
};
