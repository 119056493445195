const React = require('react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');
const IconLinkTooltip = require('../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const IconButtonTooltip = require('../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

const BasketSidebarActions = ({ opportunity, canEdit, canDelete, share, onDelete }) => {
  const showDelete = opportunity.id != null && canEdit && canDelete;

  return (
    <div>
      <ShowIf condition={opportunity.project_id != null}>
        <IconLinkTooltip href={`#pipeline/projects?id=${opportunity.project_id}`}
          label="Go to project"
          borderless>
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLinkTooltip>
      </ShowIf>
      <ShowIf condition={opportunity.id != null}>
        <IconButtonTooltip onClick={share} label="Share" borderless>
          <span className="wethod-icon wethod-icon-share wethod-icon-share--black" />
        </IconButtonTooltip>
      </ShowIf>
      <ShowIf condition={showDelete}>
        <div className="wethod-vertical-line" />
      </ShowIf>
      <ShowIf condition={showDelete}>
        <IconButtonTooltip label="Delete opportunity" onClick={onDelete} borderless>
          <span className="wethod-icon-delete wethod-icon-delete--black" />
        </IconButtonTooltip>
      </ShowIf>
    </div>
  );
};

module.exports = BasketSidebarActions;
