const React = require('react');
const AllocationApproveModal = require('../containers/modals/AllocationApproveModal');
const AllocationRejectModal = require('../containers/modals/AllocationRejectModal');
const AllocationConfirmDeleteModal = require('../containers/modals/AllocationConfirmDeleteModal');
const OutOfSyncModal = require('./modals/OutOfSyncModal.react');
const PlanUponApprovalErrorModal = require('./modals/PlanUponApprovalErrorModal.react');

module.exports = ({ toShow, hideModal, modalData }) => {
  const getModal = () => {
    switch (toShow) {
      case 'allocation-approve':
        return <AllocationApproveModal onCloseClick={hideModal} item={modalData} />;
      case 'allocation-reject':
        return <AllocationRejectModal onCloseClick={hideModal} item={modalData} />;
      case 'allocation-confirm-delete':
        return <AllocationConfirmDeleteModal onCloseClick={hideModal} item={modalData} />;
      case 'out-of-sync':
        return <OutOfSyncModal onCloseClick={hideModal} />;
      case 'plan-upon-approval':
        return <PlanUponApprovalErrorModal onCloseClick={hideModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
