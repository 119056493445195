const React = require('react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const InputValidator = require('../../../../../../../../common/react/InputValidator/InputValidator.react');
const TextInput = require('../../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const TextAreaInput = require('../../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');

module.exports = class TokenAddModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onCreateClick() {
    this.props.createToken(this.state.name, this.state.description);
  }

  getFeedback() {
    return this.props.isWaiting ? 'Saving...' : '';
  }

  /**
   * Format metadata constraints for given property to be used with InputValidator.
   * TODO: put this logic in a common component to avoid duplication
   * @param name
   * @return {*}
   */
  getConstraint(name) {
    const metadataConstraints = this.props.metadataService.getConstraints(name);

    return metadataConstraints.map((constraint) => {
      if (constraint.name === 'not_null') {
        return 'required';
      }
      if (constraint.name === 'length') {
        if (constraint.value.max) {
          return `maxLength:${constraint.value.max}`;
        }
      }
      return null;
    });
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            <InputValidator updateErrors={this.props.updateErrors} constraints={this.getConstraint('name')}>
              <TextInput id="personal-token-name" name="name" onChange={this.onChange} value={this.state.name} label="Name" errorText={this.props.errors.name} />
            </InputValidator>
          </ModalBodyBlock>
          <ModalBodyBlock>
            <InputValidator updateErrors={this.props.updateErrors} constraints={this.getConstraint('description')}>
              <TextAreaInput id="personal-token-description" name="description" onChange={this.onChange} value={this.state.description} label="Description" errorText={this.props.errors.description} />
            </InputValidator>
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={this.getFeedback()} error={this.props.error}>
          <ModalFooterAction onClick={this.props.closeModal} disabled={this.props.isWaiting}>
            Cancel
          </ModalFooterAction>
          <ModalFooterAction onClick={this.onCreateClick}
            disabled={this.props.isWaiting || !this.props.isValid}>
            Create
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    );
  }
};
