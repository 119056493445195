const React = require('react');
const StatusButton = require('../../../../../../../common/react/ColoredButton.react');
const StatusList = require('../../overview/components/SupplierStatusList.react');

module.exports = class StatusSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showList: false };
  }

  handleStatusChange(status) {
    if (status && status.id !== 'no-status') {
      const updatedSupplier = {
        ...this.props.supplier,
        status,
      };
      this.props.updateSupplier(updatedSupplier);
    }
    this.hideList();
  }

  getClassName() {
    let name = 'supplier-info__status';
    if (!this.props.canSetStatus) {
      name += ' supplier-info__status--not-editable';
    }
    return name;
  }

  getStatusColor() {
    return this.props.supplier.status ? this.props.supplier.status.color : '';
  }

  getStatusValue() {
    return this.props.supplier.status ? this.props.supplier.status.name : '';
  }

  getItems() {
    if (this.props.statuses && this.props.statuses.length > 0) {
      return this.props.statuses;
    }
    return [{
      id: 'no-status',
      name: 'No status available',
    }];
  }

  setRef(element) {
    this.statusButton = element;
  }

  hideList() {
    this.setState({ showList: false });
  }

  showList() {
    this.setState({ showList: true });
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <StatusButton setRef={this.setRef.bind(this)}
          value={this.getStatusValue()}
          color={this.getStatusColor()}
          onClick={this.showList.bind(this)}
          disabled={this.props.isWaiting} />
        <StatusList open={this.state.showList}
          anchorEl={this.statusButton}
          onClose={this.hideList.bind(this)}
          statusList={this.getItems()}
          onStatusChange={this.handleStatusChange.bind(this)} />
      </div>
    );
  }
};
