const { connect } = require('react-redux');
const Component = require('../../components/advanced-search/PipelineSegment.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  show: state.sidebarToShow === 'segment',
  segment: state.focusedSegment,
  businessUnit: state.buFilter,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  type: state.type,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  onCreate: (segment) => dispatch(actions.createSegment(segment)),
  onUpdate: (segment) => dispatch(actions.updateSegment(segment)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
