/* eslint-disable react/sort-comp,react/prop-types */
const React = require('react');
const Item = require('./HeaderItemController.react');

module.exports = class Header extends React.Component {
  isCurrent(module) {
    return this.props.currentModule && this.props.currentModule.getLabel() === module.getLabel();
  }

  isClicked(module) {
    return this.props.openModule && this.props.openModule.getLabel() === module.getLabel();
  }

  getCenterItems() {
    return this.props.mainModules.map((module) => (
      <Item key={module.getLabel()}
        module={module}
        isClicked={this.isClicked(module)}
        isCurrent={this.isCurrent(module)}
        onClick={this.props.onModuleClick} />
    ));
  }

  getRightItems() {
    return this.props.userModules.map((module) => (
      <Item key={module.getLabel()}
        module={module}
        isClicked={this.isClicked(module)}
        isCurrent={this.isCurrent(module)}
        onClick={this.props.onModuleClick} />
    ));
  }

  render() {
    return (
      <div className="app-nav__first-level">
        <div className="app-nav__first-level-content">
          <div className="app-nav__first-level-left">
            <div className="wethod-logo wethod-logo--monogram" />
          </div>
          <div className="app-nav__first-level-center">
            <ul className="app-nav__first-level-items">{this.getCenterItems()}</ul>
          </div>
          <div className="app-nav__first-level-right">
            <ul className="app-nav__first-level-items">{this.getRightItems()}</ul>
          </div>
        </div>
      </div>
    );
  }
};
