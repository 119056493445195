'use strict';

var FiscalYearService = require('../../../services/FiscalYearService');
Wethod.module('ReportApp.TimesheetRoadrunner', function (TimesheetRoadrunner, Wethod, Backbone, Marionette, $, _, Header) {
  var RRILabel = 'roadrunner index';
  /**
   * Get highcharts series for worked hours, one serie for each project type.
   *
   * @param TTColl
   * @returns {Array}
   */
  var getProjectTypeSeries = function (TTColl) {
    var series = {};

    TTColl.each(function (TTModel) {
      _.each(TTModel.get('types'), function (type) {
        series[type.name] = {
          name: type.name,
          color: type.color,
          type: 'column',
          yAxis: 0,
          data: [],
        };
      });
    });

    TTColl.each(function (TTModel) {
      _.each(TTModel.get('types'), function (type) {
        series[type.name].data.push(type.hours);
      });
    });

    return _.map(series, function (value) {
      return value;
    });
  };

  /**
   * Get highcharts serie for Roadrunner Index.
   *
   * @param RRIColl
   * @returns {Object}
   */
  var getRRISerie = function (RRIColl) {
    var serie = [];

    RRIColl.each(function (RRIModel) {
      serie.push(RRIModel.get('roadrunner_index'));
    });

    return {
      name: RRILabel,
      color: 'rgb(219, 77, 105)',
      yAxis: 1,
      type: 'spline',
      data: serie,
    };
  };

  /**
   * The categories array for x axis (52 weeks or 12 month).
   *
   * @param coll
   * @returns {Array}
   */
  var getCategories = function (coll) {
    var categories = [];

    coll.each(function (model) {
      categories.push(moment(model.get('date')).unix());
    });

    return categories;
  };

  /**
   * Get data to display Timesheet Roadrunner chart
   *
   * @param TTColl
   * @param RRIColl
   * @param range
   * @return
   */
  var getChartData = function (TTColl, RRIColl, range) {
    var categories = getCategories(TTColl);
    var projectTypesSeries = getProjectTypeSeries(TTColl);
    var RRISerie = getRRISerie(RRIColl);
    var series = projectTypesSeries;
    series.push(RRISerie);

    return {
      title: { text: '' },
      credits: { enabled: false },
      legend: { enabled: false },
      plotOptions: { column: { stacking: 'normal' } },
      xAxis: {
        categories: categories,
        title: { enabled: false },
        labels: {
          formatter: function () {
            if (range === 'weekly') {
              var fiscalYearService = new FiscalYearService();
              if (fiscalYearService.isSolarYear()) {
                return moment.unix(this.value).format('DD/MM');
              }
              return moment.unix(this.value).format('DD/MM/YY');
            }
            return moment.unix(this.value).format('MMM');
          },
        },
      },
      yAxis: [
        { // Timesheet axis
          title: { text: 'WORKED HOURS' },
        },
        { // Roadrunner Index axis
          opposite: true,
          min: 0,
          max: 2,
          title: {
            text: RRILabel,
            style: {
              color: 'rgb(219, 77, 105)',
              textTransform: 'uppercase',
            },
          },
        },
      ],
      series: series,
      tooltip: {
        snap: 5,
        hideDelay: 100,
        borderWidth: 0,
        useHTML: true,
        formatter: function () {
          var fiscalYearService = new FiscalYearService();
          var date;
          var pos;
          if (range === 'weekly') {
            date = moment.unix(this.x).format('DD/MM/YYYY');
            pos = moment.unix(this.x).isoWeek() - 1;
          } else {
            date = moment.unix(this.x).format('MMMM');
            pos = moment.unix(this.x);
            pos = fiscalYearService.getShiftedMonth(new Date(pos)) - 1;
          }

          var RRITooltip = '';
          var TTTooltip = '<p>Worked Hours:</p>';
          _.each(this.series.chart.series, function (serie) {
            if (serie.name === RRILabel) {
              RRITooltip
                += '<p><span style="display:inline-block;width:120px;">Roadrunner index:</span><span style="display:inline-block;width:50px;text-align:right;">'
                + (serie.points[pos] ? numeral(serie.points[pos].y).format('0.00') : '-')
                + '</span></p><hr>';
            } else {
              var name = serie.name;
              var hours = numeral(serie.points[pos].y).format('0.00');
              if (hours) {
                TTTooltip += '<p><span style="display:inline-block;width:120px;">' + name + '</span><span style="display:inline-block;width:50px;text-align:right;">' + hours + '</span></p>';
              }
            }
          });

          var total = numeral(this.series.chart.series[0].data[pos].total).format('0.00');
          TTTooltip += '<p><span style="display:inline-block;width:120px;font-weight:700;">TOTAL </span><span style="display:inline-block;width:50px;text-align:right;font-weight:700;">' + total + '</span></p>';

          return '<p>' + date + '</p><hr>' + RRITooltip + TTTooltip;
        },
      },
    };
  };

  /**
   * TimesheetRoadrunner page layout
   */
  this.Layout = Marionette.LayoutView.extend({
    template: '#timesheetRoadrunnerLayoutTemplate',
    className: 'timesheetRoadrunner pageSection',
    _selectedYear: '',
    _range: 'monthly',
    _isLoading: false,
    regions: {
      sectionHeader: '[data-region="sectionHeader"]',
      chart: '[data-region="timesheetRoadrunnerChart"]',
    },
    ui: {
      prevYear: '[data-region="prevYear"]',
      yearLabel: '[data-region="yearLabel"]',
      nextYear: '[data-region="nextYear"]',
      switchButton: '[data-region="switchButton"]',
      monthlyRange: '[data-action="monthlyRange"]',
      weeklyRange: '[data-action="weeklyRange"]',
    },
    events: {
      'click @ui.prevYear': 'showPrevYear',
      'click @ui.nextYear': 'showNextYear',
      'click @ui.monthlyRange': 'showMonthlyRange',
      'click @ui.weeklyRange': 'showWeeklyRange',
    },
    onRender: function () {
      var sectionHeaderModel = new Header.SectionHeaderModel({
        current_section: 'Timesheet Summary',
        preview_anchor_id: 'timesheet-roadrunner',
        helper_url: 'reports/index/#timesheet-roadrunner',
      });
      var fiscalYearService = new FiscalYearService();
      this._selectedYear = fiscalYearService.getShiftedYear(parseInt(moment().format('YYYY')));
      var sectionHeaderView = new Header.SectionHeaderView({ model: sectionHeaderModel });
      this.sectionHeader.show(sectionHeaderView);
    },
    onShow: function () {
      var fiscalYearService = new FiscalYearService();
      var dynamicYear = fiscalYearService.getYearDynamically(this._selectedYear);
      this.ui.yearLabel.text(dynamicYear);
      this.ui.monthlyRange.addClass('sel');

      this.showChart(this._selectedYear, this._range);
    },
    showPrevYear: function () {
      this.showYear(this._selectedYear - 1);
    },
    showNextYear: function () {
      this.showYear(this._selectedYear + 1);
    },
    showYear: function (year) {
      var fiscalYearService = new FiscalYearService();

      if (!this._isLoading) {
        this._selectedYear = year;
        var dynamicYear = fiscalYearService.getYearDynamically(this._selectedYear);
        this.ui.yearLabel.text(dynamicYear);
        this.showChart(this._selectedYear, this._range);
      }
    },
    showMonthlyRange: function (e) {
      e.preventDefault();
      this.showRange('monthly');
    },
    showWeeklyRange: function (e) {
      e.preventDefault();
      this.showRange('weekly');
    },
    showRange: function (range) {
      if (this._range !== range && !this._isLoading) {
        this._range = range;
        if (this._range === 'monthly') {
          this.ui.weeklyRange.removeClass('sel');
          this.ui.monthlyRange.addClass('sel');
        } else if (this._range === 'weekly') {
          this.ui.weeklyRange.addClass('sel');
          this.ui.monthlyRange.removeClass('sel');
        }
        this.showChart(this._selectedYear, this._range);
      }
    },
    showChart: function (year, range) {
      this.showLoadingView();
      this.disableInput();

      var params = {
        year: year,
        range: range,
      };
      var TTRequest = Wethod.request('get:timesheet:by-type', params);
      var RRIRequest = Wethod.request('get:project:roadrunner', params);

      $.when(TTRequest, RRIRequest).done(function (TTResponse, RRIResponse) {
        var chartData = getChartData(TTResponse, RRIResponse, this._range);
        var chartView = new TimesheetRoadrunner.ChartItemView();
        this.chart.show(chartView);
        chartView.$el.highcharts(chartData);
        this._isLoading = false;
        this.enableInput();
      }.bind(this));
    },
    showLoadingView: function () {
      this._isLoading = true;
      var loadingView = new Wethod.View.Loading.SmallView();
      this.chart.show(loadingView);
    },
    disableInput: function () {
      this.ui.prevYear.addClass('disabled');
      this.ui.nextYear.addClass('disabled');
      this.ui.switchButton.addClass('disabled');
    },
    enableInput: function () {
      this.ui.prevYear.removeClass('disabled');
      this.ui.nextYear.removeClass('disabled');
      this.ui.switchButton.removeClass('disabled');
    },
  });

  /**
   * TimesheetRoadrunner chart view
   */
  this.ChartItemView = Marionette.ItemView.extend({
    template: '#timesheetRoadrunnerChartTemplate',
  });
}, Wethod.module('HeaderApp.Header'));
