/* eslint-disable no-useless-constructor,no-underscore-dangle,class-methods-use-this */
const Section = require('./Section');

module.exports = class PlaygroundSection extends Section {
  constructor(section, userPermissions) {
    super(section, userPermissions);
  }

  _isInPlayground() {
    const currentLocation = Wethod.userInfo.get('company');
    return currentLocation === 'playground';
  }

  getUrl() {
    const userCompanies = Wethod.userInfo.get('companies_available');
    const namedPlayground = userCompanies.filter((company) => company.name === 'playground');
    if (namedPlayground.length > 0) {
      const { protocol } = window.location;
      return `${protocol}//${namedPlayground[0].url}`;
    }
    return null;
  }

  isVisible() {
    return !this._isInPlayground();
  }
};
