const React = require('react');

const EmptyPast = () => (
  <div className="wethod-widget__empty project-canvas__goal-empty">
    <div className="project-canvas-empty__text-action wethod-widget__empty-message-link">
      <div className="briefing-empty__past" />
    </div>
    <div className="project-canvas-empty__text">
      <div className="project-canvas-empty__text-title">Oh dear! We haven't met yet.</div>
      <div className="project-canvas-empty__text-description">You've travelled too far!</div>
    </div>
  </div>
);

module.exports = EmptyPast;
