/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return */
const React = require('react');
const BudgetLog = require('./BudgetLog.react');
const ProjectLog = require('./ProjectLog.react');
const LifecycleLog = require('./LifecycleLog.react');
const FinancialLog = require('./FinancialLog.react');
const CanvasLog = require('./CanvasLog.react');

module.exports = class Log extends React.Component {
  isLifecycleLog(type) {
    const lifecycleTypes = 'iproject_created,project_started,project_ended';
    return lifecycleTypes.indexOf(type) !== -1;
  }

  isFinancialLog(type) {
    const financialTypes = 'invoice_created,order_created,travel_created,expenses_created';
    return financialTypes.indexOf(type) !== -1;
  }

  isCanvasLog(type) {
    const canvasTypes = 'board_created,brief_created,concept_created';
    return canvasTypes.indexOf(type) !== -1;
  }

  getLogType() {
    const changelogIndex = this.props.type.indexOf('_changelog');
    if (changelogIndex !== -1) {
      return this.props.type.substring(0, changelogIndex);
    }
    if (this.isLifecycleLog(this.props.type)) {
      return 'lifecycle';
    } if (this.isFinancialLog(this.props.type)) {
      return 'financial';
    } if (this.isCanvasLog(this.props.type)) {
      return 'canvas';
    }
  }

  render() {
    if (this.getLogType() === 'budget') return <BudgetLog filter={this.props.filter} {...this.props.event} />;
    if (this.getLogType() === 'project') return <ProjectLog filter={this.props.filter} {...this.props.event} />;
    if (this.getLogType() === 'lifecycle') return <LifecycleLog filter={this.props.filter} {...this.props} />;
    if (this.getLogType() === 'financial') return <FinancialLog filter={this.props.filter} {...this.props} />;
    if (this.getLogType() === 'canvas') return <CanvasLog filter={this.props.filter} {...this.props} />;
  }
};
