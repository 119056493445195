const request = require('./Request');

const Location = {
  get() {
    const url = '/location';
    return request({
      method: 'get',
      url,
    });
  },
  create(location) {
    return request({
      method: 'post',
      url: '/location',
      data: location,
    });
  },
  update(id, location) {
    return request({
      method: 'patch',
      url: `/location/${id}`,
      data: location,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/location/${id}`,
    });
  },
};

module.exports = Location;
