/* eslint-disable default-case,no-shadow */

'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _) {
  // project charter info
  this.ProgramModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/program/',
    defaults: {
      name: null,
      description: null,
      manager: null,
    },
    /**
     * Visits http://thedersen.com/projects/backbone-validation/#built-in-validators.
     */
    validation: {
      name: [{
        required: true,
        msg: 'The Program name must be NOT empty',
      }],
      manager: [{
        required: true,
        msg: 'The Program leader must be NOT empty',
      }],
    },
    getValidationErrors: function () {
      var errors = this.validate();
      var errorNames = _.keys(errors);
      var errorsArray = [];
      _.each(errorNames, function (name) {
        var error = {};
        error[name] = errors[name];
        errorsArray.push(error);
      });
      return errorsArray;
    },
    getPlainErrors: function () {
      var errors = this.getValidationErrors();
      var plainErrors = [];
      for (var i = 0; i < errors.length; i++) {
        // eslint-disable-next-line guard-for-in
        for (var error in errors[i]) {
          plainErrors.push(errors[i][error]);
        }
      }
      return plainErrors;
    },
  });

  // project charter detail
  this.ProgramInfoModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/info';
    },
  });

  // PROJECT
  this.ProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/project/' + this.options.projectId;
    },
  });

  // project charter projects
  this.ProjectCharterProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/program-project/',
    defaults: {
      program: null,
      project: null,
      sort: null,
    },
    /**
     * Visits http://thedersen.com/projects/backbone-validation/#built-in-validators.
     */
    validation: {
      project: [{
        required: true,
        msg: 'You must select a project',
      }],
      program: [{
        required: true,
        msg: 'Program must be not NULL',
      }],
    },
  });
  this.ProjectCharterProjectsCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/projects';
    },
    model: ProjectCharterApp.ProjectCharterProjectModel,
  });

  // RESOURCES
  this.ProjectCharterResourceModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/program-resource/',
    defaults: {
      program: null,
      program_project: null,
      category: null,
      content: null,
      alias: null,
      note: null,
      sort: null,
    },
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        switch (key) {
          case 'content':
            if (_.isNull(attrs[key])) {
              error[key] = 'The content nust be not empty';
              errors.push(error);
            }
            break;
          case 'category':
            if (_.isNull(attrs[key])) {
              error[key] = 'You must select a category';
              errors.push(error);
            }
            break;
          case 'program':
            if (_.isNull(attrs[key])) {
              error[key] = 'Program must be not NULL';
              errors.push(error);
            }
            break;
        }
      });
      return (errors.length > 0) ? errors : false;
    },
  });

  this.ProjectCharterResourcesCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/resources';
    },
    model: ProjectCharterApp.ProjectCharterResourceModel,
  });

  // AUTOCOMPLETE
  this.AutocompleteModel = Wethod.Utility.BaseModel.extend({});
  // manager autocomplete
  this.AutocompleteManagersCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.whatSearch)) ? '?on=' + this.options.whereSearch + '&like=' + this.options.whatSearch : '?on=' + this.options.whereSearch;

      return APIURL + '/pipeline/autocomplete' + params;
    },
    model: ProjectCharterApp.AutocompleteModel,
  });
  // project autocomplete
  this.AutocompleteProjectsCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.whatSearch)) ? '?like=' + this.options.whatSearch : '';

      return APIURL + '/project-charter/project/autocomplete' + params;
    },
    model: ProjectCharterApp.AutocompleteModel,
  });
  // program project autocomplete
  this.AutocompleteProgramProjectsCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.whatSearch)) ? '?like=' + this.options.whatSearch : '';

      return APIURL + '/project-charter/' + this.options.program_id + '/resources/autocomplete' + params;
    },
    model: ProjectCharterApp.AutocompleteModel,
  });

  // Autocomplete for program
  this.AutocompleteProgramCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.search)) ? '?search=' + this.options.search : '';
      return APIURL + '/program/autocomplete' + params;
    },
    model: ProjectCharterApp.AutocompleteModel,
  });

  // resource type
  this.ProjectCharterResourcesTypeModel = Wethod.Utility.BaseModel.extend({});
  this.ProjectCharterResourcesTypeCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/resource-category/';
    },
    model: ProjectCharterApp.ProjectCharterResourcesTypeModel,
  });
  // MODAL
  this.ProjectCharterModalModel = Wethod.Utility.BaseModel.extend({});

  // PLANNING
  this.ProjectCharterPlanStructureModel = Wethod.Utility.BaseModel.extend();
  // range
  this.ProjectCharterPlanRangeModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/report/planning/range';
    },
  });
  // economics
  this.ProjectCharterEconomicsModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/economics';
    },
  });
  this.ProjectCharterEconomicsTotalModel = Wethod.Utility.BaseModel.extend();

  // plannings
  this.ProjectCharterPlanModel = Wethod.Utility.BaseModel.extend({});
  this.ProjectCharterPlanningCollection = Backbone.Collection.extend({
    initialize: function (options) {
      this.options = options || {};
    },
    parse: function (response) {
      var data = (response.data) ? response.data : response;
      switch (response.code) {
        case 404:
          return [];
        default:
          return data;
      }
    },
    url: function () {
      return APIURL + '/project-charter/' + this.options.programId + '/report/planning?from=' + this.options.dateStart + '&to=' + this.options.dateEnd;
    },
    model: ProjectCharterApp.ProjectCharterPlanModel,
  });

  var API = {
    getProject: function (id) {
      var model = new ProjectCharterApp.ProjectModel({ projectId: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterInfo: function (id) {
      var model = new ProjectCharterApp.ProgramInfoModel({ programId: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterProjects: function (id) {
      var collection = new ProjectCharterApp.ProjectCharterProjectsCollection({ programId: id });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterProjectsCollection());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterResources: function (id) {
      var collection = new ProjectCharterApp.ProjectCharterResourcesCollection({ programId: id });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterResourcesCollection());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    persistProjectCharter: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (model, response) {
          if (response.code === 201 || response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    getProjectManagerHints: function (options) {
      var collection = new ProjectCharterApp.AutocompleteManagersCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProgramHints: function (search) {
      var collection = new ProjectCharterApp.AutocompleteProgramCollection({ search: search });
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200 || response.code === 404) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterProjectHints: function (options) {
      var collection = new ProjectCharterApp.AutocompleteProjectsCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (data, response) {
          if (response.statusText !== 'abort') {
            defer.reject(undefined);
          } else {
            defer.resolve('loading');
          }
        },
      });

      return defer.promise();
    },
    getProjectCharterProgramProjectHints: function (options) {
      var collection = new ProjectCharterApp.AutocompleteProgramProjectsCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (data, response) {
          if (response.statusText !== 'abort') {
            defer.reject(undefined);
          } else {
            defer.resolve('loading');
          }
        },
      });

      return defer.promise();
    },
    addProjectCharterProject: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (model, response) {
          if (response.code === 201) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteProjectCharterProject: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(response);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getProjectCharterResourceType: function () {
      var collection = new ProjectCharterApp.ProjectCharterResourcesTypeCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterResourcesTypeCollection());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    addProjectCharterResource: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (model, response) {
          if (response.code === 201) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteProjectCharterResource: function (model) {
      var defer = $.Deferred();

      model.destroy({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(response);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getProjectCharterEconomics: function (id) {
      var model = new ProjectCharterApp.ProjectCharterEconomicsModel({ programId: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterEconomicsModel());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterPlanningsRange: function (id) {
      var model = new ProjectCharterApp.ProjectCharterPlanRangeModel({ programId: id });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterPlanRangeModel());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectCharterPlannings: function (options) {
      var collection = new ProjectCharterApp.ProjectCharterPlanningCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else if (response.code === 404) {
            defer.resolve(new ProjectCharterApp.ProjectCharterPlanningCollection());
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
  };

  // CREATE
  // chck project
  Wethod.reqres.setHandler('get:project', function (id) {
    return API.getProject(id);
  });
  // create new program
  Wethod.reqres.setHandler('persist:projectCharter', function (model) {
    return API.persistProjectCharter(model);
  });
  // project charter manager hint list
  Wethod.reqres.setHandler('projectCharter:get:manager:hints', function (options) {
    return API.getProjectManagerHints(options);
  });

  // project charter
  Wethod.reqres.setHandler('program:get:hints', function (search) {
    return API.getProgramHints(search);
  });

  // DETAIL
  // get project charter info
  Wethod.reqres.setHandler('get:projectCharter:info', function (id) {
    return API.getProjectCharterInfo(id);
  });
  // get project charter projects list
  Wethod.reqres.setHandler('get:projectCharter:projects', function (id) {
    return API.getProjectCharterProjects(id);
  });
  // get project charter resources list
  Wethod.reqres.setHandler('get:projectCharter:resources', function (id) {
    return API.getProjectCharterResources(id);
  });
  // get project charter project hint list
  Wethod.reqres.setHandler('projectCharter:get:project:hints', function (options) {
    return API.getProjectCharterProjectHints(options);
  });
  // get project charter associated project hint list
  Wethod.reqres.setHandler('projectCharter:get:program:project:hints', function (options) {
    return API.getProjectCharterProgramProjectHints(options);
  });
  // get project charter resource type list
  Wethod.reqres.setHandler('projectCharter:get:resource:type', function (options) {
    return API.getProjectCharterResourceType(options);
  });
  // add new project to this program
  Wethod.reqres.setHandler('projectCharter:insert:project', function (model) {
    return API.addProjectCharterProject(model);
  });
  // add new resource to this program
  Wethod.reqres.setHandler('projectCharter:insert:resource', function (model) {
    return API.addProjectCharterResource(model);
  });
  // delete program project
  Wethod.reqres.setHandler('projectCharter:delete:project', function (model) {
    return API.deleteProjectCharterProject(model);
  });
  // delete program project
  Wethod.reqres.setHandler('projectCharter:delete:resource', function (model) {
    return API.deleteProjectCharterResource(model);
  });

  // ECONOMICS
  Wethod.reqres.setHandler('projectCharter:get:economics', function (id) {
    return API.getProjectCharterEconomics(id);
  });

  // PLANNING
  // get project charter planning
  Wethod.reqres.setHandler('projectCharter:get:plannings:range', function (id) {
    return API.getProjectCharterPlanningsRange(id);
  });
  // get project charter planning
  Wethod.reqres.setHandler('projectCharter:get:plannings', function (options) {
    return API.getProjectCharterPlannings(options);
  });
});
