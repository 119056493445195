const React = require('react');
const ProjectInfo = require('../containers/ProjectInfo');
const ProjectHours = require('./ProjectHours.react');
const {
  hasHours,
  isAreaGeneric,
  isAreaOn,
} = require('../../services/areaService');
const { isInWhitelist } = require('../../services/projectService');

module.exports = class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHours: false,
    };
  }

  getVisibleAreas() {
    return this.props.areas.filter((area) => (
      isAreaGeneric(area)
      || isAreaOn(area)
      || hasHours(area)
    ));
  }

  toggleShowHours() {
    this.setState((prevState) => ({ showHours: !prevState.showHours }));
  }

  render() {
    return (
      <li className="timesheet-project">
        <ProjectInfo
          {...this.props}
          handleClick={this.toggleShowHours.bind(this)} />
        <ProjectHours
          show={this.state.showHours}
          canEdit={this.props.can_edit}
          reasonCannotEdit={this.props.reason_cannot_edit}
          archived={this.props.project.archived}
          isInWhitelist={isInWhitelist(this.props.project)}
          joc={this.props.project.project_type}
          projectId={this.props.project.id}
          areas={this.getVisibleAreas()}
          hasTodayImputedHours={this.props.has_today_imputed_hours} />
      </li>
    );
  }
};
