const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../modal2/Modal.react');
const Button = require('../../Button/RoundedButton.react');
const ShowIf = require('../../ShowIf/ShowIf.react');

const SegmentDeleteModal = ({ segment, isWaiting, onCloseClick, onDeleteClick }) => {
  function handleDelete() {
    onDeleteClick(segment);
  }

  return (
    <Modal title="Delete segment" onCancelClick={onCloseClick}>
      <p>
        Are you sure you want to <b>delete</b> segment <b>{segment.name}</b>?
      </p>
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>No</Button>
        <Button onClick={handleDelete}>
          Yes
        </Button>
      </div>
      <div className="wethod-modal2__feedback">
        <ShowIf condition={isWaiting}>
          <span>Deleting...</span>
        </ShowIf>
      </div>
    </Modal>
  );
};

SegmentDeleteModal.propTypes = {
  segment: PropTypes.shape({
    filters: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  /**
   * Function to call to close modal.
   * @param e {{}}
   */
  onCloseClick: PropTypes.func.isRequired,
  /**
   * Function to call to delete segment.
   * @param option {{}} segment
   */
  onDeleteClick: PropTypes.func.isRequired,
  /**
   * Whether the segment is waiting for delete.
   * The modal shows a feedback while waiting.
   */
  isWaiting: PropTypes.bool,
};

SegmentDeleteModal.defaultProps = {
  isWaiting: false,
};

module.exports = SegmentDeleteModal;
