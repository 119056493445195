/* eslint-disable react/prop-types,react/prefer-stateless-function */
const React = require('react');

module.exports = class Section extends React.Component {
  render() {
    return (
      <li className="app-nav-mobile-section">
        <a className="app-nav-mobile-section__content"
          href={this.props.section.getUrl()}
          onClick={this.props.onClick}>
          {this.props.section.getLabel()}
        </a>
      </li>
    );
  }
};
