const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const OrderContact = require('./OrderContact.react');

const SendOrderModal = ({ onCancelClick, data, contacts, sendOrder }) => {
  function send(sendTo) {
    sendOrder(data.order, sendTo);
    onCancelClick();
  }

  const getBody = () => contacts.map((contact) => (
    <OrderContact key={contact.id} send={send} contact={contact} />
  ));

  return (
    <Modal title="Send request to" onCancelClick={onCancelClick} className="orders__send-order-modal">
      <div className="orders__send-order-modal--body">
        {getBody()}
      </div>
    </Modal>
  );
};

module.exports = SendOrderModal;
