const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./RoundedButton.react');

const PositiveButton = ({ className, children, ...rest }) => {
  const actualClassName = `wethod-button--confirm ${className}`;

  return (
    <Button className={actualClassName} {...rest}>{children}</Button>
  );
};

PositiveButton.defaultProps = {
  className: '',
};

PositiveButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

module.exports = PositiveButton;
