const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    const id = this.get('projectId');
    const root = `${APIURL}/review/request/`;
    if (id) return root + id;
    return root;
  },

  createReviewRequest() {
    const defer = $.Deferred();
    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  deleteReviewRequest() {
    const defer = $.Deferred();

    $.when(this.erase()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
