const { connect } = require('react-redux');
const Component = require('../../components/timesheet/TimesheetReminder.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  frequency: state.timesheet.frequency,
  isSaving: state.waitingFor.some((key) => key === 'set-timesheet-reminder'),
});

const mapDispatchToProps = (dispatch) => ({
  onFrequencyChange: (frequency) => dispatch(actions.setTimesheetFrequency(frequency)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
