const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');

module.exports = ({ onCancelClick }) => (
  <Modal onCancelClick={onCancelClick} className="timesheet-weekly__modal">
    <div className="project-review__modal-content">
      <span className="bold">Your review won't be saved</span>
      , are you sure you want to exit this page?
    </div>
    <div className="project-review__modal-actions">
      <button type="button" onClick={onCancelClick} className="wethod-button">Cancel</button>
      <a href="/#desk" className="wethod-button">Yes</a>
    </div>
  </Modal>
);
