const React = require('react');

const IntercompanyDetailsBody = ({ details }) => (
  <div className="pipeline-projects__intercompany-details-modal-body">
    <p>
      This intercompany opportunity was sent
      by <span className="bold">{details.client.sender}</span> and it is currently linked
      to <span className="bold">{details.client.project.name}</span> from
      company <span className="bold">{details.client.company.name}</span>.
    </p>
    <p>
      Accepted by <span className="bold">{details.supplier.name}</span>.
    </p>
  </div>
);

module.exports = IntercompanyDetailsBody;
