const { connect } = require('react-redux');
const Component = require('../../components/Kudos/TrendWidget.react');

const mapStateToProps = (state) => ({
  year: state.kudos.year,
  title: 'As team member',
  data: state.kudos.as_team_member,
});

module.exports = connect(mapStateToProps)(Component);
