const React = require('react');
const PropTypes = require('prop-types');
const ModifierSelect = require('./DateRangeModifierSelect.react');
const DateSelect = require('./DateRangeDateSelect.react');

const DateRangePartialInput = ({
  name, modifier, date, onModifierChange, onDateChange, modifierLabel, onClear, id,
}) => (
  <div className="wethod-advanced-search-filter-date-range__content-row">
    <ModifierSelect name={`modifier-${name}`}
      id={`${id}-modifier-${name}`}
      value={modifier}
      onChange={onModifierChange}
      label={modifierLabel} />
    <DateSelect name={`date-${name}`}
      id={`${id}-date-${name}`}
      onChange={onDateChange}
      value={date}
      onClear={onClear} />
  </div>
);

DateRangePartialInput.defaultProps = {
  modifier: '',
  date: '',
  modifierLabel: '',
  onClear: null,
};

DateRangePartialInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  modifierLabel: PropTypes.string,
  modifier: PropTypes.string,
  date: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onModifierChange: PropTypes.func.isRequired,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onDateChange: PropTypes.func.isRequired,
  /**
   * Function to call when "clear" button is clicked.
   */
  onClear: PropTypes.func,
};

module.exports = DateRangePartialInput;
