/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events */
const React = require('react');
const Hamburger = require('./Hamburger.react');

module.exports = class Header extends React.Component {
  getContentLeft() {
    if (this.props.area) {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="app-nav-mobile__back-button wethod-icon-arrow-left wethod-icon-arrow-left--black"
          onClick={this.props.showItems} />
      );
    }
    return <div className="wethod-logo wethod-logo--monogram" />;
  }

  getCurrentLabel() {
    if (!this.props.showMenu) {
      return this.props.currentSection;
    }
    const { area } = this.props;
    if (area) {
      return area.getLabel();
    }

    return null;
  }

  render() {
    return (
      <div className="app-nav-mobile-header">
        <div className="app-nav-mobile-header__content">
          <div className="app-nav-mobile-header__content-left">
            {this.getContentLeft()}
          </div>
          <div className="app-nav-mobile-header__content-center">
            <span className="app-nav-mobile-header__current-page">{this.getCurrentLabel()}</span>
          </div>
          <div className="app-nav-mobile-header__content-right">
            <Hamburger onClick={this.props.toggleMenu} />
          </div>
        </div>
      </div>
    );
  }
};
