const React = require('react');
const PropTypes = require('prop-types');
const InputDecorator = require('../InputDecorator.react');

require('./style.scss');

const BasicInputDecorator = ({
  children, prefix, suffix, required, inputRef, helperText, errorText, disabled, readOnly,
  focusedByCollaborator,
}) => (
  <InputDecorator className="wethod-input--basic"
    required={required}
    disabled={disabled}
    readOnly={readOnly}
    inputRef={inputRef}
    helperText={helperText}
    errorText={errorText}
    focusedByCollaborator={focusedByCollaborator}
    prefix={prefix}
    suffix={suffix}>
    {children}
  </InputDecorator>
);

BasicInputDecorator.defaultProps = {
  prefix: null,
  suffix: null,
  helperText: '',
  errorText: '',
  required: false,
  disabled: false,
  readOnly: false,
  inputRef: undefined,
  focusedByCollaborator: false,
};

BasicInputDecorator.propTypes = {
  /** Element to decorate * */
  children: PropTypes.node.isRequired,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children. Takes precedence over helperText * */
  errorText: PropTypes.string,
  required: PropTypes.bool,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  focusedByCollaborator: PropTypes.bool,
};

module.exports = BasicInputDecorator;
