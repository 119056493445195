const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');

module.exports = class HeaderMonth extends React.Component {
  getYear() {
    if (this.props.year) {
      return <span className="production-plan-project__month-year"><span>{this.props.year}</span></span>;
    }
    return null;
  }

  getValue() {
    if (this.props.isCurrentMonth) {
      return <span className="wethod-calendar-table__now">{this.props.value}</span>;
    }
    return this.props.value;
  }

  render() {
    return (
      <TableCell className="production-plan-project__month production-plan-project__month--header">
        {this.getYear()}
        {this.getValue()}
      </TableCell>
    );
  }
};
