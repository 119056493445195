const { connect } = require('react-redux');
const Component = require('../components/RequestManager.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
  projectId: state.project ? state.project.id : null,
  budgetId: state.info ? state.info.id : null,
  clientId: state.project ? state.project.client.id : null,
  budgetInfo: state.info,
});

const mapDispatchToProps = (dispatch) => ({
  consumptionReportRequest: (projectId) => dispatch(actions.getConsumptionReport(projectId)),
  updateInfo: (projectId, budgetId, info, optimistic) => dispatch(actions
    .updateInfo(projectId, budgetId, info, optimistic)),
  applyPsCorrection: (budgetId) => dispatch(actions
    .applyProjectStatusCorrection(budgetId)),
  approversRequest: (projectId) => dispatch(actions.getAvailableApprovers(projectId)),
  changeStatus: (projectId, budgetId, status, message, approverId) => dispatch(actions
    .changeStatus(projectId, budgetId, status, message, approverId)),
  getVersionRequest: (budgetId, version) => dispatch(actions.getVersion(budgetId, version)),
  getVersionComparisonRequest: (projectId, versions) => dispatch(actions
    .getVersionComparison(projectId, versions)),
  updateTaskInfoRequest: (taskId, taskInfo, projectId) => dispatch(actions
    .updateTaskInfo(taskId, taskInfo, projectId)),
  updateTaskLevelAmountRequest: (taskLevelAmountId, levelId, budgetTaskId,
    days, projectId) => dispatch(actions
    .updateTaskLevelAmount(taskLevelAmountId, levelId,
      budgetTaskId, days, projectId)),
  createTaskRequest: (projectId, areaId, taskInfo) => dispatch(actions
    .createTask(projectId, areaId, taskInfo)),
  createAreaRequest: (name, projectId) => dispatch(actions
    .createArea(name, projectId)),
  deleteTaskRequest: (projectId, budgetId, id) => dispatch(actions
    .deleteTask(projectId, budgetId, id)),
  updateAreaRequest: (id, changes, projectId) => dispatch(actions
    .updateArea(id, changes, projectId)),
  makeTaskIntercompanyRequest: ((task, supplierId) => dispatch(actions
    .makeTaskIntercompany(task, supplierId))),
  inviteIntercompanySupplierRequest: ((areaId, taskId, recipientId) => dispatch(actions
    .inviteIntercompanySupplier(areaId, taskId, recipientId))),
  updateTaskOrderRequest: (positions, projectId, budgetId) => dispatch(actions
    .updateTaskOrder(positions, projectId, budgetId)),
  getIntercompanyTaskInfoRequest: (taskId) => dispatch(actions.getIntercompanyTaskInfo(taskId)),
  getVersionListRequest: (budgetId) => dispatch(actions.getVersionList(budgetId)),
  getProjectStatusCorrectionRequest: (budgetId) => dispatch(actions
    .getProjectStatusCorrection(budgetId)),
  insertJobTitleRequest: (taskId, jobTitleId, projectId) => dispatch(actions
    .insertJobTitle(taskId, jobTitleId, projectId)),
  deleteJobTitleRequest: (id, taskId, jobTitleId, projectId) => dispatch(actions
    .deleteJobTitle(id, taskId, jobTitleId, projectId)),
  insertProductsRequest: (areaId, products, projectId) => dispatch(actions
    .insertProducts(areaId, products, projectId)),
  updatePricelistRequest: (projectId, budgetId, pricelistId) => dispatch(actions
    .updatePricelist(projectId, budgetId, pricelistId)),
  updateAreaPriceListRequest: (pricelistId, areaId) => dispatch(actions
    .updateAreaPriceList(pricelistId, areaId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
