const request = require('./Request');

const PropertyMetadata = {
  list(entityName) {
    return request({
      method: 'get',
      url: `/property-metadata/${entityName}`,
    });
  },
};

PropertyMetadata.ORDER_ENTITY = 'order';
PropertyMetadata.TIMESHEET_ENTITY = 'timesheet';
PropertyMetadata.EXPENSE_ENTITY = 'expense';
PropertyMetadata.TRAVEL_ENTITY = 'travel';
PropertyMetadata.TRAVEL_CARRIER_ENTITY = 'travel-carrier';
PropertyMetadata.PROJECT_ENTITY = 'project';
PropertyMetadata.API_TOKEN_ENTITY = 'api-token';
PropertyMetadata.INVOICE_ENTITY = 'invoice';

module.exports = PropertyMetadata;
