const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const getLastThursday = require('../../../../../../services/DateService').getLastThursdayOfMonth;

module.exports = class ColumnTrend extends React.Component {
  constructor(props) {
    super(props);

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'column',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      xAxis: {
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    const suffix = this.props.percentage ? '%' : null;
    const categories = this.getCategories();
    const yAxis = this.props.yAxis ? this.props.yAxis : this.options.yAxis;
    return {
      ...this.options,
      series: this.getSeries(),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1,
        min: 0,
      },
      yAxis,
      tooltip: {
        ...this.options.tooltip,
        useHTML: true,
        formatter: this.props.tooltipFormatter,
        valueSuffix: suffix,
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries() {
    const { data } = this.props;

    return data.map((seriesData) => this.formatSeries(seriesData));
  }

  formatSeries(series) {
    const data = series.values.map((period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      let className;
      if (index >= this.todayBreakpointIndex) {
        className = 'demographics__chart--disabled'; // Set opacity to column of current period (and future ones)
      }
      return {
        ...period,
        className,
        x: index,
        y: period.value,
      };
    });

    const customOptions = series.options ? series.options : {};

    return {
      name: series.name,
      data,
      color: series.color, // if not specified, it takes a random color defined by highcharts
      ...customOptions,
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="demographics__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
