const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const PV = require('../../../../common/components/PercentageValue.react');
const NV = require('../../../../common/components/NumericValue.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({
  mainWonReason, mainWonReasonPerc, mainLostReason, mainLostReasonPerc, wonProjects, lostProjects,
}) => {
  if (wonProjects && lostProjects) {
    return (
      <Paragraph>
        Usually, there’s a reason why things happen. By analyzing the monthly data, one can observe
        that
        {' '}
        <span className="bold">{mainWonReason}</span>
        {' '}
        accounts for
        {' '}
        <PV value={mainWonReasonPerc} />
        {' '}
        of the reasons
        why we won
        {' '}
        <NV
          value={wonProjects} />
        {' '}
        new
        {' '}
        <NS quantity={wonProjects}>projects</NS>
        . While
        {' '}
        <span className="bold">{mainLostReason}</span>
        {' '}
        accounts
        for
        {' '}
        <PV value={mainLostReasonPerc} />
        {' '}
        of the reasons why we lost
        {' '}
        <NV
          value={lostProjects} />
        {' '}
        <NS quantity={lostProjects}>projects</NS>
        .
      </Paragraph>
    );
  }
  if (wonProjects) {
    return (
      <Paragraph>
        Usually, there’s a reason why things happen. By analyzing the monthly data, one can observe
        that
        {' '}
        <span className="bold">{mainWonReason}</span>
        {' '}
        accounts for
        {' '}
        <PV value={mainWonReasonPerc} />
        {' '}
        of the reasons
        why we won
        {' '}
        <NV
          value={wonProjects} />
        {' '}
        new
        {' '}
        <NS quantity={wonProjects}>projects</NS>
        .
      </Paragraph>
    );
  }
  if (lostProjects) {
    return (
      <Paragraph>
        Usually, there’s a reason why things happen. By analyzing the monthly data, one can observe
        that
        {' '}
        <span className="bold">{mainLostReason}</span>
        {' '}
        accounts
        for
        {' '}
        <PV value={mainLostReasonPerc} />
        {' '}
        of the reasons why we lost
        {' '}
        <NV
          value={lostProjects} />
        {' '}
        <NS quantity={lostProjects}>projects</NS>
        .
      </Paragraph>
    );
  }
  return null;
};
