const React = require('react');
const IconCell = require('../Cell/IconCell.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Menu = require('../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class MoreOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  setMenuAnchorRef(ref) {
    this.button = ref;
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  showMenu(e) {
    e.stopPropagation();
    this.setState({ showMenu: true });
  }

  hasOptions() {
    return React.Children.count(this.props.children) > 0;
  }

  render() {
    return (
      <IconCell>
        <ShowIf condition={this.hasOptions() && this.props.editable} showElse={null}>
          <div>
            <IconButtonTooltip label="Show options"
              borderless
              onClick={this.showMenu}
              rootRef={this.setMenuAnchorRef}>
              <Icon icon="more" />
            </IconButtonTooltip>
            <Menu
              open={this.state.showMenu}
              onClose={this.closeMenu}
              anchorEl={this.button}>
              {this.props.children}
            </Menu>
          </div>
        </ShowIf>
      </IconCell>
    );
  }
};
