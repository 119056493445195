const { connect } = require('react-redux');
const Component = require('../components/BudgetTemplateList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'budget-templates').length > 0,
  templates: state.filteredTemplates,
  searchKeyword: state.searchKeyword,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(actions.getTemplates()),
  filter: (keyword) => dispatch(actions.filterTemplates(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
