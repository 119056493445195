const React = require('react');
const PropertyMetadata = require('../../../services/PropertyMetadataService/PropertyMetadata');
const FrozenDataTooltip = require('../../../apps/core/modules/finance/orders/components/sidebar/FrozenDataTooltip.react');

/**
 * Adds tooltip based on metadata reason to children.
 * @param children input
 * @param {string} name input name
 * @param {boolean} canEdit true if input is editable before checking its metadata. Apart from
 * metadata, input could not be editable due to permissions, form state, etc.
 * @param {PropertyMetadataService} metadataService
 * @param rest
 * @return {JSX.Element|*}
 * @constructor
 */
const InputMetadataManager = ({
  children,
  name,
  canEdit,
  metadataService,
  ...rest
}) => {
  const reason = metadataService.getReason(name);

  /**
   * Input must be read-only if input is not in edit mode or if its metadata impose that
   * input is not editable.
   * @return {boolean}
   */
  const isReadOnly = () => !canEdit || (metadataService.getMetadata(name)
    && !metadataService.getCanEdit(name));

  const augmentedChildren = React.cloneElement(children, {
    ...rest,
    name,
    readOnly: isReadOnly(),
  });

  // Tooltip must be shown only when input is in edit mode
  if (canEdit && reason === PropertyMetadata.CANNOT_EDIT_DATA_FROZEN) {
    return <FrozenDataTooltip>{augmentedChildren}</FrozenDataTooltip>;
  }
  return augmentedChildren;
};

module.exports = InputMetadataManager;
