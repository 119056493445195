const { connect } = require('react-redux');
const Event = require('../../components/EventsPast/Event.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  filter: state.filter,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (event) => dispatch(actions.showDeleteEventModal(event)),
  openEditForm: (event) => dispatch(actions.openEditEventForm(event)),
  closeForm: () => dispatch(actions.closeEditEventForm()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Event);
