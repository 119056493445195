'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.GroupView = Marionette.LayoutView.extend({
    /**
     *  _leftRegionView : shorthand for the left AddButtonView attached to this GroupView.
     *  _rightRegionView : shorthand for the right AddButtonView attached to this GroupView.
     *  _operatorRegionView : shorthand for the left SelectView attached to this GroupView.
     */
    className: 'condition-wrapper',
    _leftRegionView: null,
    _rightRegionView: null,
    _operatorRegionView: null,
    _dependencies: null,
    template: '#groupConditionTemplate',
    regions: {
      leftRegion: '[data-action="left"]',
      operatorRegion: '[data-action="operator"]',
      rightRegion: '[data-action="right"]',
    },
    initialize: function () {
      this._dependencies = this.model.get('dependencies');
    },
    onRender: function () {
      var availableOperators = new RuleManager.TooltipOptionCollection(
        [
          {
            label: 'And',
            name: 'and',
          },
          {
            label: 'Or',
            name: 'or',
          },
        ]
      );
      var availableOperands = new RuleManager.TooltipOptionCollection(
        [
          {
            label: 'Group',
            name: 'group',
          },
          {
            label: 'Terminal',
            name: 'terminal',
          },
        ]
      );
      this.attachLeftOperand(availableOperands);
      this.attachOperator(availableOperators);
      this.attachRightOperand(availableOperands);
    },
    /**
     * Shows the leftOperand's AddButton in its region, using the availableOperands param to
     * populate AddButton's drop-down.
     * @param availableOperands
     * @triggers condition:button:added
     */
    attachLeftOperand: function (availableOperands) {
      var left = new RuleManager.AddButtonView({ collection: availableOperands });
      this.leftRegion.show(left);
      this._leftRegionView = this.leftRegion.currentView;
      this._leftRegionView.setLabel('+ CONDITION');

      this._leftRegionView.on('tooltip:item:selected', this.addLeftCondition, this);

      this.trigger('condition:button:added');
    },
    /**
     * Shows the operator's AddButton in its region, using the availableOperators param to populate
     * AddButton's drop-down.
     * @param availableOperators
     */
    attachOperator: function (availableOperators) {
      var operator = new RuleManager.SelectView({ collection: availableOperators });
      this.operatorRegion.show(operator);
      this._operatorRegionView = this.operatorRegion.currentView;
      this._operatorRegionView.on('tooltip:item:selected', this.onOperatorSelected, this);
      this.updateModel('operator', availableOperators.at(0).attributes.name);
    },
    /**
     * Responds to the 'tooltip:item:selected' event on the operator's SelectView.
     *
     * @param operatorObject
     */
    onOperatorSelected: function (operatorObject) {
      var operatorName = operatorObject.name;
      this.updateModel('operator', operatorName);
    },
    /**
     * Shows the rightOperand's AddButton in its region, using the availableOperands param to
     * populate AddButton's drop-down.
     * @param availableOperands
     * @triggers condition:button:added
     */
    attachRightOperand: function (availableOperands) {
      var right = new RuleManager.AddButtonView({ collection: availableOperands });
      this.rightRegion.show(right);
      this._rightRegionView = this.rightRegion.currentView;
      this._rightRegionView.setLabel('+ CONDITION');

      this._rightRegionView.on('tooltip:item:selected', this.addRightCondition, this);

      this.trigger('condition:button:added');
    },
    /**
     * Shows the left operand SelectView in its region, using the @param to know if adding a Group
     * or a Terminal.
     * @param optionModel
     * @triggers condition:instantiated
     */
    addLeftCondition: function (optionModel) {
      var conditionKey = optionModel.name;
      var childView;
      if (conditionKey === 'group' || conditionKey === 'compound') {
        childView = this.addGroupCondition(this.leftRegion);
        this.updateModel('left', childView.model);
      } else if (conditionKey === 'terminal') {
        childView = this.addTerminalCondition(this.leftRegion);
        this.updateModel('left', childView.model);
      }
      this.trigger('condition:instantiated');
      return childView;
    },
    /**
     * Shows the right operand SelectView in its region, using the @param to know if adding a Group
     * or a Terminal.
     * @param optionModel
     * @triggers condition:instantiated
     */
    addRightCondition: function (optionModel) {
      var conditionKey = optionModel.name;
      var childView;
      if (conditionKey === 'group' || conditionKey === 'compound') {
        childView = this.addGroupCondition(this.rightRegion);
        this.updateModel('right', childView.model);
      } else if (conditionKey === 'terminal') {
        childView = this.addTerminalCondition(this.rightRegion);
        this.updateModel('right', childView.model);
      }
      this.trigger('condition:instantiated');
      return childView;
    },
    /**
     * Rimbalza l'evento verso la view padre.
     */
    onConditionButtonAdded: function () {
      this.trigger('condition:button:added');
    },
    onConditionInstantiated: function () {
      this.trigger('condition:instantiated');
    },
    /**
     * Adds a GroupView in the given region, populated with the dependencies in _dependencies.
     *
     * @param region
     * @returns RuleManager.GroupView
     */
    addGroupCondition: function (region) {
      var groupModel = new RuleManager.GroupModel({ dependencies: this._dependencies });
      var group = new RuleManager.GroupView({ model: groupModel });
      group.on('condition:button:added', this.onConditionButtonAdded, this);
      group.on('condition:instantiated', this.onConditionInstantiated, this);
      region.show(group);
      return group;
    },
    /**
     * Adds a TerminalView in the given region, populated with the dependencies in _dependencies.
     *
     * @param region
     * @returns RuleManager.TerminalView
     */
    addTerminalCondition: function (region) {
      var terminalModel = new RuleManager.TerminalModel({ dependencies: this._dependencies });
      var terminal = new RuleManager.TerminalView({ model: terminalModel });
      region.show(terminal);
      return terminal;
    },
    /**
     * Updated the attributeName's attribute of this view TerminalModel with the newValue.
     *
     * @param attributeName
     * @param newValue
     */
    updateModel: function (attributeName, newValue) {
      this.model.set(attributeName, newValue);
    },
    /**
     * Build left and right child of a GroupView and let them build their child views. It also
     * select the correct operator.
     * @param condition
     */
    build: function (condition) {
      // Left
      var leftView = this.addLeftCondition({ name: condition.get('left').get('type') });
      leftView.build(condition.get('left'));

      // Operator
      var operator = condition.get('operator');
      this._operatorRegionView.onTooltipOptionSelected({
        name: operator,
        label: operator,
      }, false);

      // Right
      var rightView = this.addRightCondition({ name: condition.get('right').get('type') });
      rightView.build(condition.get('right'));
    },
  });
});
