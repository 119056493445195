'use strict';

var FiscalYearService = require('../../../services/FiscalYearService');
Wethod.module('ReportApp.ProductionValue', function (ProductionValue, Wethod, Backbone, Marionette, $, _) {
  /**
   * Get data for the monthly product chart
   *
   * @param monthProd
   * @param monthRoadrunner
   * @param total
   * @param year
   * @returns {{series: Array, categories: Array}}
   */
  this.totalChartData = function (monthProd, monthRoadrunner, total, year) {
    var fiscalYearService = new FiscalYearService();
    var prodSeries = [];
    var roadrunnerSeries = [];
    var prodWeightedSeries = [];
    var categories = [];
    var prodSerie;
    var roadrunnerSerie;
    var prodWeightedSerie;
    var compareDate;
    var fiscalYear = year;
    var months = fiscalYearService.getListOfMonthsStartingFromFiscalYearStart(year);
    var changeYear = false;

    for (var i = 0; i < months.length; i++) {
      if (changeYear) {
        fiscalYear += 1;
      }
      changeYear = months[i] === 12;
      prodSerie = {};
      prodWeightedSerie = {};
      roadrunnerSerie = {};
      var monthString = '' + months[i];
      var formattedMonthString = monthString.length === 2 ? monthString : '0' + monthString;
      var dateString = fiscalYear + '-' + formattedMonthString + '-01';
      compareDate = moment(dateString).format('YYYY-MM-DD');
      categories.push({
        name: moment(compareDate).format('MMM'),
        dateUnix: moment(compareDate).unix(),
      });

      // Production serie
      // eslint-disable-next-line no-loop-func
      _.each(monthProd, function (amount, date) {
        if (date === compareDate) {
          prodSerie.y = amount.product_weighted;
          prodSerie.date = date;
          prodSerie.dateUnix = moment(date).unix();
          prodSerie.name = moment(date).format('MMM');

          prodWeightedSerie.y = amount.delta;
          prodWeightedSerie.date = date;
          prodWeightedSerie.dateUnix = moment(date).unix();
          prodWeightedSerie.name = moment(date).format('MMM');
        }
      });
      if (_.isEmpty(prodSerie)) {
        prodSerie.y = 0;
        prodSerie.date = compareDate;
        prodSerie.dateUnix = moment(compareDate).unix();
        prodSerie.name = moment(compareDate).format('MMM');
      }
      if (_.isEmpty(prodWeightedSerie)) {
        prodWeightedSerie.y = 0;
        prodWeightedSerie.date = compareDate;
        prodWeightedSerie.dateUnix = moment(compareDate).unix();
        prodWeightedSerie.name = moment(compareDate).format('MMM');
      }
      prodSeries.push(prodSerie);
      prodWeightedSeries.push(prodWeightedSerie);

      // Roadrunner serie
      // eslint-disable-next-line no-loop-func
      _.each(monthRoadrunner, function (amount, date) {
        if (date === compareDate) {
          roadrunnerSerie.y = amount.roadrunner_index
            ? Math.round(amount.roadrunner_index * 10) / 10 : null;
          roadrunnerSerie.date = date;
          roadrunnerSerie.dateUnix = moment(date).unix();
          roadrunnerSerie.name = moment(date).format('MMM');
        }
      });
      roadrunnerSeries.push(roadrunnerSerie);
    }

    return {
      prodSeries: prodSeries,
      prodWeightedSeries: prodWeightedSeries,
      roadrunnerSeries: roadrunnerSeries,
      categories: categories,
      yearlyProd: total,
      year: year,
    };
  };

  /**
   * @param reportLayout, Report page layout
   * @param year
   * @constructor
   */
  this.InitProductionValue = function (reportLayout, year) {
    var fiscalYearService = new FiscalYearService();
    year = year || fiscalYearService.getShiftedYear(moment().format('YYYY'));
    var productionValueLayout = new ProductionValue.ProductionValueLayout({ year: year });

    reportLayout.render();
    reportLayout.reportBody.show(productionValueLayout);
  };

  this.TargetClockModel = Backbone.Model.extend({
    initialize: function () {
      this.fixTarget();
      this.setFormatted();
    },
    setFormatted: function () {
      this.set('weeksLeft', this.getWeeksLeft());
      this.set('targetFormatted', this.getTargetFormatted());
      this.set('grossFormatted', this.getGrossFormatted());
      this.set('weightedFormatted', this.getWeightedFormatted());
    },
    /**
     * If the requested year is different form the current one, the target is intended to be equal
     * to the requested years's production value (gross).
     */
    fixTarget: function () {
      var fiscalYearService = new FiscalYearService();
      var currentYear = fiscalYearService.getShiftedYear(parseInt(moment().format('YYYY')));
      var year = parseInt(this.get('year'));
      if (year !== currentYear) {
        this.set('target', numeral(this.get('total') / 1000));
      }
    },
    getYearlyProdFormatted: function () {
      var yearlyProd = this.get('yearlyProd');
      return yearlyProd === 0 ? 0 : numeral(yearlyProd / 1000).format('0,0');
    },
    /**
     * Returns the percentage of the current year already gone.
     * @returns {number}
     */
    getYearProgress: function () {
      var fiscalYearService = new FiscalYearService();
      var year = this.get('year');

      var endOfYear = fiscalYearService.getFiscalYearEndDate(year);
      var weeksLeft = endOfYear.diff(moment(), 'weeks');
      var weeksInAYear = 52;

      if (weeksLeft > weeksInAYear) {
        return 100;
      }
      if (weeksLeft < 0) {
        return 0;
      }

      var currentWeek = weeksInAYear - weeksLeft;
      return Math.round((currentWeek / weeksInAYear) * 100);
    },
    /**
     * Returns the amount of weeks left in the given year.
     * @returns {number}
     */
    getWeeksLeft: function () {
      var fiscalYearService = new FiscalYearService();
      var year = this.get('year');
      var endOfYear = fiscalYearService.getFiscalYearEndDate(year);
      var weeksLeft = endOfYear.diff(moment(), 'weeks');
      var weeksInAYear = 52;
      if (weeksLeft > weeksInAYear) {
        return weeksInAYear;
      }
      if (weeksLeft < 0) {
        return 0;
      }
      return weeksLeft;
    },
    /**
     * Returns the target's quota already produced by projects with >= 90% probability.
     * @returns {number}
     */
    getProjectValueProgress: function () {
      return (this.get('total') / (this.get('target') * 1000)) * 100;
    },
    /**
     * Returns the target's quota already produced by projects with < 90% probability.
     * @returns {number}
     */
    getWeightedProgress: function () {
      var weighted = this.get('totalWeighted');
      return (weighted / (this.get('target') * 1000)) * 100;
    },
    /**
     * Returns the target's value in M if it's > 999, in K otherwise.
     * @returns {number}
     */
    getTargetFormatted: function () {
      var target = this.get('target');
      var formatted = 0;
      if (target >= 1000) {
        formatted = numeral(target / 1000).format('0,0.0');
        var slice = formatted.split(',');
        formatted = (parseInt(slice[1]) === 0) ? slice[0] : formatted;
        formatted += ' M';
      } else {
        formatted = numeral(target).format('0,0');
        formatted += ' K';
      }
      return formatted;
    },
    getWeightedFormatted: function () {
      var weighted = this.get('totalWeighted');
      return weighted === 0 ? 0 : numeral(weighted / 1000).format('0,0');
    },
    getGrossFormatted: function () {
      var total = this.get('total');
      return total === 0 ? 0 : numeral(total / 1000).format('0,0');
    },
  });
});
