/* eslint-disable consistent-return,react/prop-types,react/sort-comp,class-methods-use-this */
const React = require('react');
const SidebarInput = require('./SidebarWrapperEditable.react');
const DatePicker = require('../DatePicker.react');

/**
 * An outlined text input component, material styled. On click it opens a calendar to choose a
 * date.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name: string
 * value: string
 * required: boolean
 * format: string representing the format of the date to be shown
 * disableBeforeEqual
 *
 * onFocus
 * onChange
 * onBlur
 * onValidate
 *
 * @type {module.SidebarDateSelection}
 *
 * @deprecated
 */
module.exports = class SidebarDateSelection extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = this.getConstraints();
  }

  getConstraints() {
    return (this.props.required && this.props.required === true) ? 'required' : '';
  }

  render() {
    return (
      <SidebarInput placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}>
        <DatePicker name={this.props.name}
          disableBeforeEqual={this.props.disableBeforeEqual}
          value={this.props.value}
          constraints={this.constraints}
          format={this.props.format}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onValidate={this.props.onValidate} />
      </SidebarInput>
    );
  }
};
