const React = require('react');
const Blocks = require('./PlaceholderBlocksList.react');

const PlaceholderBlocks = ({ addContainer }) => (
  <div className="company-settings-jo-template__block-list company-settings-margin-top-xl company-settings-margin-bottom-xl">
    <div className="company-settings__label company-settings-margin-bottom-s">Template components</div>
    <div className="company-settings__description company-settings-margin-bottom-xl">Drag components to a template.</div>
    <Blocks addContainer={addContainer} />
  </div>
);

module.exports = PlaceholderBlocks;
