const React = require('react');
const Actions = require('../containers/Actions');
const Opportunities = require('../containers/Opportunities');
const Sidebar = require('../containers/SidebarWrapper');
const Modal = require('../containers/Modal');

module.exports = class Basket extends React.Component {
  componentDidMount() {
    this.props.getEmployees();
    this.props.getCompanies();
  }

  render() {
    return (
      <div className="basket wethod-section-body">
        <Actions />
        <Opportunities />
        <Sidebar />
        <Modal />
      </div>
    );
  }
};
