const request = require('./Request');
const fileRequest = require('./FileRequest');

const CanvasGoogleDriveIntegration = {
  getProjectConfig(projectId) {
    return request({
      method: 'get',
      url: `/pipeline/${projectId}/external-file-storage/config`,
    });
  },
  getFiles(projectId, search = '', nextPageToken = '', parentId) {
    let query = `?search=${search}`;
    if (nextPageToken !== null) {
      query += `&next-page-token=${nextPageToken}`;
    }

    return request({
      method: 'get',
      url: `/google-drive/canvas/${projectId}/files/${parentId}${query}`,
    });
  },
  deleteFile(projectId, fileId) {
    return request({
      method: 'delete',
      url: `/google-drive/canvas/${projectId}/files/${fileId}`,
    });
  },
  syncProjectFolder(projectId) {
    return request({
      method: 'post',
      url: `/google-drive/canvas/${projectId}/config/sync`,
    });
  },
  createProjectFolder(projectId) {
    return request({
      method: 'post',
      url: `/google-drive/canvas/${projectId}/folder`,
    });
  },
  uploadFile(file, projectId, parentId) {
    return fileRequest({
      method: 'post',
      url: `/google-drive/canvas/${projectId}/folder/${parentId}/files`,
      file,
    });
  },
};

module.exports = CanvasGoogleDriveIntegration;
