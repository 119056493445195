const React = require('react');
const PropTypes = require('prop-types');

const FileManagerContentError = ({ title, children }) => (
  <div className="wethod-widget__empty wethod-widget-files__empty wethod-widget-files__empty--missing-folder">
    <div className="project-canvas-empty__text">
      <div className="project-canvas-empty__text-title">{title}</div>
      <div className="project-canvas-empty__text-description">
        {children}
      </div>
    </div>
  </div>
);

FileManagerContentError.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = FileManagerContentError;
