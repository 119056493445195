/* eslint-disable react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');

const NumerableSentence = ({ children, quantity, alternatives }) => {
  const quantityKey = (quantity !== 0 && quantity !== 1) ? 'n' : quantity;

  const alternative = alternatives[`${children}__${quantityKey}`];

  const text = alternative || children;

  return <span>{text}</span>;
};

NumerableSentence.propTypes = {
  children: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  alternatives: PropTypes.object.isRequired,
};

module.exports = NumerableSentence;
