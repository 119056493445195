const React = require('react');
const PropTypes = require('prop-types');
const InputDecorator = require('../InputDecorator.react');

require('./style.scss');

const RoundedColorfulInputDecorator = ({ children, inputRef, color, disabled, readOnly }) => (
  <InputDecorator className="wethod-input--rounded-colorful"
    inputRef={inputRef}
    style={{ '--color': color }}
    disabled={disabled}
    readOnly={readOnly}>
    {children}
  </InputDecorator>
);

// var style = { "--my-css-var": 10 } as React.CSSProperties;
// return <div style={style}>...</div>
RoundedColorfulInputDecorator.defaultProps = {
  disabled: false,
  readOnly: false,
  inputRef: undefined,
  color: undefined,
};

RoundedColorfulInputDecorator.propTypes = {
  /** Element to decorate * */
  children: PropTypes.node.isRequired,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  /**
   * Background color
   */
  color: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

module.exports = RoundedColorfulInputDecorator;
