const { connect } = require('react-redux');
const NoteModalComponent = require('../components/NoteModal.react');
const actions = require('../actions');

/**
 * Return the updated area that should be shown in the modal
 * @param projects
 * @param modalArea
 * @returns {{}|*}
 */
const getModalArea = (projects, modalArea) => {
  if (!modalArea) return null;

  const modalProject = projects.find((project) => project.id === modalArea.projectId);
  return modalProject
    ? modalProject.areas.find((area) => area.id === modalArea.areaId)
    : null;
};

const mapStateToProps = (state) => ({
  isSaving: state.isSaving,
  date: state.date,
  employeeId: state.userId,
  area: getModalArea(state.projects, state.formData),
});

const mapDispatchToProps = (dispatch) => ({
  saveNote: (projectId, areaId, date, note, hours, employeeId) => dispatch(actions
    .saveNote(projectId, areaId, date, note, hours, employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(NoteModalComponent);
