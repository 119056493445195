const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('../../../../Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../Menu/ListMenu/MenuItem.react');

/**
 * Contains all the actions to be inserted in a RatingOverview item header.
 * Actions are displayed in a menu.
 * To display an action you just need to add an object like {label: 'Edit', onClick: edit} to
 * this component 'items' prop.
 */
class HeaderActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  getItems() {
    return this.props.items.map((item) => (
      <MenuItem key={item.label} onClick={item.onClick}>{item.label}</MenuItem>));
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  render() {
    const menuItems = this.getItems();

    if (menuItems.length) {
      return (
        <div className="widget-rating-overview-item__actions">
          <button type="button"
            className="wethod-icon-button wethod-icon-button--no-border widget-rating-overview-item__actions-button-more"
            onClick={this.showMenu.bind(this)}
            ref={(el) => this.button = el}>
            <div className="wethod-icon wethod-icon-more wethod-icon-more--black" />
          </button>
          <Menu anchorEl={this.button}
            open={this.state.showMenu}
            onClose={this.hideMenu.bind(this)}>
            {menuItems}
          </Menu>
        </div>
      );
    }
    return null;
  }
}

HeaderActions.defaultProps = {
  items: [],
};

HeaderActions.propTypes = {
  /**
   * Menu items.
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string, // Item label
    onClick: PropTypes.func, // Fuction to call on item click
  })),
};

module.exports = HeaderActions;
