const React = require('react');
const HeadcountTarget = require('../containers/HeadcountTargetModal');

const Modal = ({ toShow, data, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'target':
        return <HeadcountTarget onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
