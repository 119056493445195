const React = require('react');
const ContingencyTask = require('./ContingencyTask.react');
const DiscountTask = require('./DiscountTask.react');
const BaseTask = require('../../../containers/Calculator/Task/BaseTask');
const ProductTask = require('./ProductTask/ProductTask.react');
const IntercompanyTask = require('./IntercompanyTask/IntercompanyTask.react');
const constants = require('../../../constants');

/**
 *
 * @param price
 * @param levels
 * @param id
 * @param updateTaskInfo
 * @param updateTaskLevel
 * @param quantity
 * @param currency
 * @param order
 * @param editableBudget
 * @param name
 * @param cost
 * @param days
 * @param externalCosts
 * @param markup
 * @param onDeleteTaskClick
 * @param onMakeTaskIntercompanyClick
 * @param intercompany
 * @param previous
 * @param onInviteIntercompanySupplierClick
 * @param budgetTaskId
 * @param updateQuantity
 * @param isDefault
 * @param levelsTotals
 * @param onIntercompanyMessageClick
 * @param type
 * @param uid
 * @param deleted
 * @param created
 * @param jobTitles
 * @param product
 * @return {JSX.Element}
 * @constructor
 */
const TaskFactory = ({
  price, levels, id, updateTaskInfo, updateTaskLevel, quantity,
  currency, order, editableBudget, name, cost, days, externalCosts, markup,
  onDeleteTaskClick, onMakeTaskIntercompanyClick, intercompany, previous,
  onInviteIntercompanySupplierClick, budgetTaskId, updateQuantity, isDefault, levelsTotals,
  onIntercompanyMessageClick, type, uid, deleted, created, jobTitles = [], product = null,
  column,
}) => {
  const getType = () => (type);

  const isType = (value) => value === getType();

  function handleTaskDelete() {
    onDeleteTaskClick(id);
  }

  function handleIntercompanyTaskCreation() {
    onMakeTaskIntercompanyClick({ uid, id }, days, markup, jobTitles);
  }

  if (isType(constants.TASK_TYPE_CONTINGENCY)) {
    return (
      <ContingencyTask
        column={column}
        price={price}
        editable={editableBudget}
        id={id}
        levels={levels}
        updateQuantity={updateQuantity}
        quantity={quantity}
        updateTaskInfo={updateTaskInfo}
        previous={previous}
        budgetTaskId={budgetTaskId}
        days={days} />
    );
  }
  if (isType(constants.TASK_TYPE_DISCOUNT)) {
    return (
      <DiscountTask
        column={column}
        price={price}
        id={id}
        levels={levels}
        updateQuantity={updateQuantity}
        updateTaskInfo={updateTaskInfo}
        quantity={quantity}
        previous={previous}
        budgetTaskId={budgetTaskId}
        editable={editableBudget}
        days={days} />
    );
  }

  if (isType(constants.TASK_TYPE_PRODUCT)) {
    return (
      <ProductTask
        column={column}
        name={product.name}
        levelsTotals={levelsTotals}
        id={id}
        levels={levels}
        days={days}
        quantity={quantity}
        cost={cost}
        price={price}
        deleted={deleted}
        markup={markup}
        created={created}
        deleteTask={handleTaskDelete}
        currency={currency}
        externalCosts={externalCosts}
        previous={previous}
        updateTaskInfo={updateTaskInfo}
        editableBudget={editableBudget}
        priceIsFixed={product.price !== null} />
    );
  }
  if (intercompany) {
    return (
      <IntercompanyTask
        column={column}
        intercompany={intercompany}
        created={created}
        uid={uid}
        onIntercompanyMessageClick={onIntercompanyMessageClick}
        budgetTaskId={budgetTaskId}
        name={name}
        externalCosts={externalCosts}
        markup={markup}
        cost={cost}
        price={price}
        levels={levels}
        editable={editableBudget && !deleted}
        id={id}
        updateTaskInfo={updateTaskInfo}
        onInviteSupplierClick={onInviteIntercompanySupplierClick}
        currency={currency}
        order={order}
        deleted={deleted}
        previous={previous}
        deleteTask={handleTaskDelete} />
    );
  }
  return (
    <BaseTask
      column={column}
      previous={previous}
      deleted={deleted}
      created={created}
      budgetTaskId={budgetTaskId}
      updateTaskInfo={updateTaskInfo}
      updateTaskLevel={updateTaskLevel}
      currency={currency}
      order={order}
      id={id}
      deleteTask={handleTaskDelete}
      showIntercompanyTaskModal={handleIntercompanyTaskCreation}
      onMakeTaskIntercompanyClick
      editable={editableBudget && !deleted}
      isDefault={isDefault}
      name={name}
      cost={cost}
      price={price}
      days={days}
      levels={levels}
      levelsTotals={levelsTotals}
      jobTitles={jobTitles}
      externalCosts={externalCosts}
      markup={markup} />
  );
};

module.exports = TaskFactory;
