/* eslint-disable no-underscore-dangle,class-methods-use-this,no-param-reassign */
const Calendar = require('./Calendar');

module.exports = class MonthsCalendar extends Calendar {
  /**
   * @param from {Moment}
   * @param to {Moment}
   */
  constructor(from, to) {
    super(from, to, 200, 50, 3, 'months', 'weeks');
  }

  _getPointsInPeriod(from, to) {
    let current = moment(from.startOf('month').format('YYYY-MM-DD')); // 1st day of month
    const points = [];
    while (current.isSameOrBefore(to)) {
      points.push(current);
      current = current.clone().add(1, 'month');
    }
    return points;
  }

  addFuture(amount) {
    amount = amount || 4;
    /**
     * @var {Moment}
     */
    const lastPoint = this._points[this._points.length - 1];
    const from = lastPoint.clone().add(1, 'month');
    const to = from.clone().add(amount, this._type);
    const future = this._getPointsInPeriod(from, to);
    this._points = this._points.concat(future);
    return this._points;
  }

  addPast(amount) {
    amount = amount || 4;
    /**
     * @var {Moment}
     */
    const firstPoint = this._points[0];
    const from = firstPoint.clone().subtract(amount, this._type);
    const to = firstPoint.clone().subtract(1, 'month');
    const past = this._getPointsInPeriod(from, to);
    this._points = past.concat(this._points);
    return this._points;
  }

  getDate(attribute) {
    const { value } = attribute;
    const widthInPoints = moment(value.end_date)
      .startOf('isoWeek')
      .diff(moment(value.start_date).startOf('isoWeek'), 'weeks') + 1;
    let yearDiff = moment(value.end_date).diff(moment(value.start_date), 'years');
    yearDiff = yearDiff > 0 ? yearDiff + 1 : yearDiff;
    const leftInColumns = this.getPast(moment(value.start_date));

    return {
      ...attribute,
      width: (widthInPoints - (this._columnWidth / this._pointWidth) * yearDiff) * this._pointWidth,
      left: leftInColumns * this._columnWidth
        + (Math.floor(moment(value.start_date).date() / 8) * this._pointWidth),
    };
  }

  getMilestone(attribute) {
    const { value } = attribute;
    const widthInPoints = 1;
    const leftInColumns = this.getPast(moment(value.date));

    return {
      ...attribute,
      width: widthInPoints * this._pointWidth,
      left: leftInColumns * this._columnWidth
        + (Math.floor(moment(value.start_date).date() / 8) * this._pointWidth),
    };
  }
};
