const { connect } = require('react-redux');
const Component = require('../../components/work-hour-capacity/WorkHourCapacityActions.react');
const workHourCapacityActions = require('../../actions');

const mapStateToProps = (state) => ({
  isAddDisabled: state.workHourCapacities.some((item) => item.id === 'new-work-hour-capacity'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdd: () => dispatch(workHourCapacityActions.addNewWorkHourCapacity()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
