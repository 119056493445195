const React = require('react');
const moment = require('moment');
const Typography = require('../../../../../../common/react/Typography/Typography.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const OutlinedTag = require('../../../../../../common/react/Tag/OutlinedTag.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const status = require('../../segmentsStatus');

const DashboardHeader = ({ selectedSegment, editStatus, editPeriod, permissions }) => {
  const getFormattedDate = (date) => {
    if (!date) return '-';
    const dateObj = moment(date);
    return dateObj.format('MMM YYYY');
  };

  const formattedRange = selectedSegment
    ? `${getFormattedDate(selectedSegment.from)} - ${getFormattedDate(selectedSegment.to)}`
    : '';

  function handleEditStatus() {
    editStatus(selectedSegment);
  }

  function handleEditSegment() {
    editPeriod(selectedSegment);
  }

  const isEditVisible = () => selectedSegment
    && selectedSegment.status !== status.FREE
    && selectedSegment.status !== status.FROZEN;

  // Permissions for editing segment
  const canEdit = () => isEditVisible() && permissions.freeze && permissions.pause;

  return (
    <div className="data-freezing-engine-mb--l data-freezing-engine__dashboard-header">
      <div className="data-freezing-engine__dashboard-header-title">
        <Typography className="data-freezing-engine-mb--s"
          component={Typography.COMPONENTS.H2}
          size={Typography.SIZES.PX18}
          weight={Typography.WEIGHTS.SEMIBOLD}>
          Data Review Dashboard
        </Typography>
        <ShowIf condition={!!selectedSegment}>
          <div className="data-freezing-engine-mb--s data-freezing-engine__dashboard-header-subtitle">
            <Typography
              component={Typography.COMPONENTS.H2}
              size={Typography.SIZES.PX18}
              weight={Typography.WEIGHTS.LIGHT}>
              {formattedRange}
            </Typography>
            <OutlinedTag upperCase
              inline
              fontSize={Typography.SIZES.PX10}
              color={
                DashboardHeader.statusColor[selectedSegment?.status]
                || OutlinedTag.COLORS.DUSTY_GRAY
              }
              borderRadius={OutlinedTag.BORDER_RADIUS.SQUARED}>
              {status.LABEL[selectedSegment?.status] || ''}
            </OutlinedTag>
          </div>
        </ShowIf>
        <Typography
          component={Typography.COMPONENTS.P}
          color={Typography.COLORS.DUSTY_GRAY}>
          Start data review from the timeline above to check on data{' '}
          before changing data edit status.
        </Typography>
      </div>
      <ShowIf condition={isEditVisible()}>
        <Button onClick={handleEditStatus} disabled={!canEdit()}>Edit Status</Button>
      </ShowIf>
      <ShowIf condition={isEditVisible()}>
        <Button onClick={handleEditSegment} disabled={!canEdit()}>Edit Segment</Button>
      </ShowIf>
    </div>
  );
};

DashboardHeader.statusColor = {
  [status.FREE]: OutlinedTag.COLORS.GARDEN_GREEN,
  [status.IN_REVIEW]: OutlinedTag.COLORS.BRIGHT_SKY_BLUE,
  [status.PAUSED]: OutlinedTag.COLORS.SUNSET_ORANGE,
  [status.FROZEN]: OutlinedTag.COLORS.DUSTY_GRAY,
};

module.exports = DashboardHeader;
