const { connect } = require('react-redux');
const isEqual = require('react-fast-compare');
const Component = require('../components/ItemsSearch.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  placeholder: `Search for ${state.type}`,
  keyword: state.search,
  filtersApplied: !isEqual(state.filters, {}),
});

const mapDispatchToProps = (dispatch) => ({
  onSaveClick: () => dispatch(actions.showSaveSearchkModal()),
  onExportClick: () => dispatch(actions.showExportSearchModal()),
  onShareClick: () => dispatch(actions.showShareSearchModal()),
  onFilterClick: () => dispatch(actions.showAdvancedSearch()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
