module.exports.GET_KNOWLEDGE_REQUEST = 'GET_KNOWLEDGE_REQUEST';
module.exports.GET_KNOWLEDGE_SUCCESS = 'GET_KNOWLEDGE_SUCCESS';

module.exports.CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

module.exports.TOGGLE_SKILL = 'TOGGLE_SKILL';

module.exports.FILTER_SKILL_EMPLOYEES = 'FILTER_SKILL_EMPLOYEES';

module.exports.FILTER_SKILLS = 'FILTER_SKILLS';

module.exports.LOAD_LANG_EMPLOYEES = 'LOAD_LANG_EMPLOYEES';

module.exports.LOAD_SKILL_EMPLOYEES = 'LOAD_SKILL_EMPLOYEES';

module.exports.TOGGLE_INTEREST = 'TOGGLE_INTEREST';

module.exports.FILTER_INTERESTS = 'FILTER_INTERESTS';

module.exports.FILTER_INTEREST_EMPLOYEES = 'FILTER_INTEREST_EMPLOYEES';

module.exports.LOAD_INTEREST_EMPLOYEES = 'LOAD_INTEREST_EMPLOYEES';
