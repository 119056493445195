const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const Menu = require('../../Menu/Menu.react');
const EventService = require('../../../../services/EventService');
const Calendar = require('../../MonthPicker.react');

class MonthPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      open: false,
    };

    this.onInputClick = this.onInputClick.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onInputClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onClose() {
    this.setState({ open: false });
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onMonthClick(date, e) {
    const event = EventService.cloneEvent(e, {
      name: this.props.name,
      value: moment(date).format(),
    });

    this.setState({ value: event.target.value }, () => {
      this.validate(event.target.value);
      this.onParentChange(event);
      this.onClose();
    });
  }

  getFormattedValue() {
    const dateString = this.state.value;
    return dateString ? moment(dateString).format('MMM YYYY') : '';
  }

  getButton() {
    const Button = this.props.button;

    return (
      <Button name={this.props.name}
        label={this.props.label}
        prefix={this.props.prefix}
        suffix={this.props.suffix}
        helperText={this.props.helperText}
        errorText={this.props.errorText}
        value={this.getFormattedValue()}
        id={this.props.id}
        placeholder={this.props.placeholder}
        required={this.props.required}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        onClick={this.onInputClick}
        inputRef={this.setMenuAnchorRef} />
    );
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
      this.onClose();
    });
  }

  render() {
    return (
      <div className="wethod-date-picker">
        {this.getButton()}
        <Menu open={this.state.open} onClose={this.onClose} anchorEl={this.menuAnchorRef} bottom>
          <Calendar date={this.state.value}
            onMonthClick={this.onMonthClick}
            disableBefore={this.props.disableBefore}
            disableBeforeEqual={this.props.disableBeforeEqual}
            disableAfter={this.props.disableAfter}
            disableAfterEqual={this.props.disableAfterEqual} />
        </Menu>
      </div>
    );
  }
}

MonthPicker.defaultProps = {
  label: null,
  required: undefined,
  id: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  validate: undefined,
  disabled: undefined,
  readOnly: undefined,
  onChange: null,
  value: null,
  placeholder: undefined,
  resetErrors: null,
  disableBefore: undefined,
  disableBeforeEqual: undefined,
  disableAfter: undefined,
  disableAfterEqual: undefined,
};

MonthPicker.propTypes = {
  /** Current value. Must be in the format "2014-09-08T08:02:17-05:00"  * */
  value: PropTypes.string,
  /** Class to instantiate as button * */
  button: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  /**
   * Function to call when a new value is selected.
   * @param e {SyntheticEvent}
   * @param item {{}} selected item props
   */
  onChange: PropTypes.func,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  /**
   * If specified, all months before this date will be disabled.
   */
  disableBefore: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, this date month and all months before will be disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, all months after this date will be disabled.
   */
  disableAfter: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If specified, this date month and all months after will be disabled.
   */
  disableAfterEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
};

module.exports = MonthPicker;
