/* eslint-disable consistent-return */
const React = require('react');
const Loading = require('../../../../../../common/react/LoadingSmall.react');
const { formatDate } = require('../../../../../../services/FormatService');
const Modal = require('../../../../../../common/react/modal2/Modal.react');

module.exports = class NewRequestModal extends React.Component {
  componentDidMount() {
    const projectId = this.props.data && this.props.data.review && this.props.data.review.project
      ? this.props.data.review.project : null;
    if (projectId) {
      this.props.getLastReview(projectId);
    }
  }

  handleCancelClick() {
    if (!this.props.isWaiting) {
      this.props.onCancelClick();
    }
  }

  handleConfirmClick() {
    if (!this.props.isWaiting) {
      const projectId = this.props.data && this.props.data.review && this.props.data.review.project
        ? this.props.data.review.project : null;
      this.props.onConfirmClick(projectId, this.props.year);
      // this.props.onCancelClick();
    }
  }

  getFeedback() {
    if (this.props.isSending) {
      return <span className="wethod-modal2__feedback">Sending...</span>;
    }
    return '';
  }

  getBody() {
    if (this.props.isWaiting) {
      return <Loading />;
    }
    const lastReviewData = this.props.lastReview
      ? (
        <span>
          <br />
          Last review was requested by {this.props.lastReview.created_by} on
          {formatDate(this.props.lastReview.created_at)}.<br />
          {this.props.lastReview.completed} on {this.props.lastReview.reviewers} members
          completed this review.
        </span>
      )
      : null;
    return (
      <div>
        <span>Do you want to <b>request a review of the team members on this project? </b>
        </span><br />
        {lastReviewData}
      </div>
    );
  }

  getActions() {
    if (!this.props.isWaiting) {
      return (
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.handleCancelClick.bind(this)}
            className="wethod-button">No, thanks
          </button>
          <button type="button"
            onClick={this.handleConfirmClick.bind(this)}
            className="wethod-button wethod-button--confirm">Send request
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal title="Project & team review"
        onCancelClick={this.handleCancelClick.bind(this)}
        className="review-changelog__modal">
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
