/* eslint-disable no-case-declarations */
const constants = require('./constants');
const searchFilter = require('./services/searchFilter');

class BudgetTemplateReducer {
  constructor(state) {
    this.state = {
      ...state,
      budgetTemplates: [],
      filteredTemplates: [],
      searchKeyword: '',
      waitingFor: [], // contains a key for each pending request
      focusedTemplate: null,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_TEMPLATES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('budget-templates'),
        };
      case constants.GET_TEMPLATES_SUCCESS:
        return {
          ...state,
          budgetTemplates: action.items,
          filteredTemplates: action.items,
          waitingFor: state.waitingFor.filter((key) => key !== 'budget-templates'),
        };
      case constants.UPDATE_TEMPLATE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-budget-template'),
        };
      case constants.UPDATE_TEMPLATE_SUCCESS:
        const updatedTemplates = state.budgetTemplates.map((template) => {
          if (template.id === action.item.id) return action.item;
          return template;
        });
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-budget-template'),
          budgetTemplates: updatedTemplates,
          filteredTemplates: searchFilter(state.searchKeyword, updatedTemplates),
        };
      case constants.DELETE_TEMPLATE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-budget-template'),
        };
      case constants.DELETE_TEMPLATE_SUCCESS:
        const budgetTemplates = state.budgetTemplates
          .filter((template) => template.id !== action.item.id);
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-budget-template'),
          focusedTemplate: null,
          budgetTemplates,
          filteredTemplates: searchFilter(state.searchKeyword, budgetTemplates),
        };
      case constants.FILTER_TEMPLATES:
        return {
          ...state,
          searchKeyword: action.keyword,
          filteredTemplates: searchFilter(action.keyword, state.budgetTemplates),
        };
      case constants.SHOW_SIDEBAR:
        return {
          ...state,
          focusedTemplate: action.item,
        };
      case constants.CLOSE_SIDEBAR:
        return {
          ...state,
          focusedTemplate: null,
        };
      default:
        return state;
    }
  }
}

module.exports = BudgetTemplateReducer;
