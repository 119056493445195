const React = require('react');
const Paragraph = require('../../../../../common/components/SectionParagraph.react');
const TargetComparator = require('../../../../containers/sections/Production/TargetComparator');
const WonProjectsAgainstLastYear = require('../../../../containers/sections/Sales/WonProjectsAgainstLastYear');
const NumericValue = require('../../../../../common/components/NumericValue.react');

module.exports = ({ count, value, target }) => {
  const getWonProjects = () => (
    <span>
      The number of projects
      {' '}
      <span
        className="bold">
        won last week was <NumericValue value={count} />
      </span>
      {' '}
      , equal to a value of <NumericValue value={value} />
    </span>
  );

  if (!target) {
    return (
      <Paragraph>
        {getWonProjects()}.
      </Paragraph>
    );
  }
  return (
    <Paragraph>
      {getWonProjects()}, which is <TargetComparator value={value} /> the average target of
      {' '}
      <NumericValue value={target} /> per week <WonProjectsAgainstLastYear />.
    </Paragraph>
  );
};
