/* eslint-disable react/jsx-no-bind */
const React = require('react');
const { isMe } = require('../../../../../../../services/UserService');
const FileEdit = require('../../../containers/sidebar/FileSection/FileSectionEditable');
const FileView = require('./FileSectionReadOnly.react');
const FileModel = require('../../../models/FileModel');
const { getPermission } = require('../../../services/authManager');

const FileSection = ({ travel, editMode, carrier, removeFile, showCannotDownloadFileModal }) => {
  const focusedCarrier = (travel && travel.travel_carriers)
    ? travel.travel_carriers.find((elem) => elem.id === carrier.id) : null;

  // list of accepted file extensions
  const accept = [
    'image/jpeg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/tiff',
    'image/tiff',
    'image/x-icon',
    'image/svg+xml',
    'video/x-ms-asf',
    'video/x-ms-asf',
    'video/x-ms-wmv',
    'video/x-ms-wmx',
    'video/x-ms-wm',
    'video/avi',
    'video/divx',
    'video/x-flv',
    'video/quicktime',
    'video/quicktime',
    'video/mpeg',
    'video/mpeg',
    'video/mpeg',
    'video/mp4',
    'video/mp4',
    'video/ogg',
    'video/webm',
    'video/x-matroska',
    'video/3gpp',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp2',
    'text/plain',
    'text/csv',
    'text/tab-separated-values',
    'text/calendar',
    'text/richtext',
    'text/css',
    'text/html',
    'text/html',
    'text/vtt',
    'application/ttaf+xml',
    'audio/mpeg',
    'audio/mpeg',
    'audio/mpeg',
    'audio/x-realaudio',
    'audio/x-realaudio',
    'audio/wav',
    'audio/ogg',
    'audio/ogg',
    'audio/midi',
    'audio/midi',
    'audio/x-ms-wma',
    'audio/x-ms-wax',
    'audio/x-matroska',
    'application/rtf',
    'application/javascript',
    'application/pdf',
    'application/x-shockwave-flash',
    'application/java',
    'application/x-tar',
    'application/zip',
    'application/x-gzip',
    'application/x-gzip',
    'application/rar',
    'application/x-7z-compressed',
    'application/octet-stream',
    'application/octet-stream',
    'application/postscript',
    'application/x-indesign',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-write',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-access',
    'application/vnd.ms-project',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.ms-powerpoint.slide.macroEnabled.12',
    'application/onenote',
    'application/onenote',
    'application/onenote',
    'application/onenote',
    'application/oxps',
    'application/vnd.ms-xpsdocument',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.chart',
    'application/vnd.oasis.opendocument.database',
    'application/vnd.oasis.opendocument.formula',
    'application/wordperfect',
    'application/wordperfect',
    'application/vnd.apple.keynote',
    'application/vnd.apple.numbers',
    'application/vnd.apple.pages',
  ];

  const canEditSent = () => {
    const employee = travel ? travel.employee_id : null;
    const status = travel ? travel.status : null;

    return getPermission('edit_sent', employee, status);
  };

  const canDownload = () => {
    if (travel) {
      return isMe(travel.employee_id) || canEditSent() || (travel.project && Wethod.userInfo.get('employee_id') === travel.project.pm);
    }
    return false;
  };

  const download = (file) => {
    const model = new FileModel();
    $.when(model.download(file.id)).fail((response) => {
      if (response.code === 404) {
        removeFile(travel, focusedCarrier, file);
        showCannotDownloadFileModal(['not-found']);
      } else {
        showCannotDownloadFileModal(['server-error']);
      }
    });
  };

  const getFiles = () => (focusedCarrier ? focusedCarrier.files : null);

  if (canEditSent() && editMode) {
    return (
      <FileEdit files={getFiles()}
        carrier={focusedCarrier}
        accept={accept}
        onDownload={download}
        canDownload={canDownload()} />
    );
  }
  return (
    <FileView files={getFiles()}
      canEdit={canEditSent()}
      accept={accept}
      onDownload={download}
      canDownload={canDownload()} />
  );
};

module.exports = FileSection;
