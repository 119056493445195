'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette, $) {
  this.RuleModel = Wethod.Utility.BaseModel.extend({
    idAttribute: 'name',
    parseToJson: function () {
      return this.get('condition').parseToJson();
    },
  });

  this.RuleCollection = Wethod.Utility.BaseCollection.extend({
    model: RuleManager.RuleModel,
    url: APIURL + '/rule-engine/rule',
  });

  var API = {
    getRules: function () {
      var rules = new RuleManager.RuleCollection();
      var defer = $.Deferred();

      rules.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },

    saveRule: function (ruleModel) {
      var defer = $.Deferred();

      ruleModel.save(null, {
        success: function (model, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(model);
          } else {
            defer.resolve(response.message);
          }
        },

        error: function () {
          defer.resolve(undefined);
        },

        wait: false,
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:rules', function () {
    return API.getRules();
  });

  Wethod.reqres.setHandler('save:rule', function (ruleModel) {
    return API.saveRule(ruleModel);
  });
});
