const React = require('react');
const Section = require('../../../../common/components/Section.react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const Hello = require('./Hello.react');
const Headline = require('./Headline.react');
const BrewedDate = require('../../BrewedDate.react');

const Intro = ({ date, timeToRead, cachedOn }) => (
  <Section
    left={(
      <BrewedDate
        date={date}
        timeToRead={timeToRead}
        cachedOn={cachedOn} />
      )}>
    <Paragraph>
      <Hello />
      {' '}
      {Wethod.userInfo.get('name')}
      ,
      {' '}
      <Headline />
    </Paragraph>
  </Section>
);

module.exports = Intro;
