/* eslint-disable backbone/no-model-attributes */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.boardId = options.boardId;
    this.itemId = options.itemId;
    this.attributeId = options.attributeId;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/canvas/board/${this.boardId}/item/${this.itemId}/attribute/${this.attributeId}`;
  },
  saveValue() {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: this.urlRoot(),
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveItemValues(values) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${APIURL}/canvas/board/${this.boardId}/item/${this.itemId}/bulk`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(values),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  removeMember(memberId) {
    const defer = $.Deferred();
    $.ajax({
      method: 'DELETE',
      crossDomain: true,
      url: `${this.urlRoot()}/member/${memberId}`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  removeMilestone() {
    const defer = $.Deferred();
    $.ajax({
      method: 'DELETE',
      crossDomain: true,
      url: `${this.urlRoot()}/milestone`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
