const FeatureService = require('../../../../../services/FeatureService');

const formatLevel = (priceListLevel) => ({
  id: priceListLevel.id,
  level: priceListLevel.level,
  level_name: priceListLevel.name,
  level_short_name: priceListLevel.short_name,
  level_cost: priceListLevel.cost,
  level_price: priceListLevel.price,
  is_visible: priceListLevel.is_visible,
  sort: priceListLevel.sort,
});

/**
 * Get budget levels associated to the budget price list
 * @param budgetPriceListLevels
 * @param budgetPriceList
 * @returns {*}
 */
const getBudgetLevels = (budgetPriceListLevels, budgetPriceList) => (
  budgetPriceListLevels
    .filter((priceListLevel) => priceListLevel.price_list.id === budgetPriceList.id)
    .map((priceListLevel) => formatLevel(priceListLevel))
);

const getVersionedBudgetLevels = (budgetPriceListLevels, budgetPriceList) => (
  budgetPriceListLevels
    .filter((priceListLevel) => priceListLevel.from.price_list.id === budgetPriceList.id)
    .map((priceListLevel) => ({
      from: priceListLevel.from ? formatLevel(priceListLevel.from) : null,
      to: priceListLevel.to ? formatLevel(priceListLevel.to) : null,
    }))
);

const formatBudgetInfo = (info) => ({
  ...info,
  levels: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)
    ? getBudgetLevels(info.budget_price_list_levels, info.price_list)
    : info.levels,
  budget_price_list_levels: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)
    ? info.budget_price_list_levels
    : undefined,
});

const formatBudgetInfoVersion = (info) => ({
  ...info,
  levels: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)
    ? getVersionedBudgetLevels(info.budget_price_list_levels, info.to.price_list)
    : info.levels,
  budget_price_list_levels: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)
    ? info.budget_price_list_levels
    : undefined,
});

module.exports = {
  formatBudgetInfo,
  formatBudgetInfoVersion,
};
