/* eslint-disable no-underscore-dangle,no-param-reassign,class-methods-use-this */
const Calendar = require('./Calendar');

module.exports = class WeeksCalendar extends Calendar {
  /**
   * @param from {Moment}
   * @param to {Moment}
   */
  constructor(from, to) {
    super(from, to, 140, 20, 4, 'weeks', 'days');
  }

  _getPointsInPeriod(from, to) {
    let current = moment(from.startOf('isoWeek').format('YYYY-MM-DD')); // last Monday
    const points = [];
    while (current.isSameOrBefore(to)) {
      points.push(current);
      current = current.clone().add(1, 'weeks');
    }
    return points;
  }

  addFuture(amount) {
    amount = amount || 6;
    /**
     * @var {Moment}
     */
    const lastPoint = this._points[this._points.length - 1];
    const from = lastPoint.clone().add(1, 'week');
    const to = from.clone().add(amount, this._type);
    const future = this._getPointsInPeriod(from, to);
    this._points = this._points.concat(future);
    return this._points;
  }

  /**
   * Add "amount" of time in the past to points and return them.
   * @param amount
   * @return {[]}
   */
  addPast(amount) {
    amount = amount || 6;
    /**
     * @var {Moment}
     */
    const firstPoint = this._points[0];
    const from = firstPoint.clone().subtract(amount, this._type);
    const to = firstPoint.clone().subtract(1, 'week');
    const past = this._getPointsInPeriod(from, to);
    this._points = past.concat(this._points);
    return this._points;
  }

  getDate(attribute) {
    const { value } = attribute;
    const widthInDays = moment(value.end_date).diff(moment(value.start_date), 'days') + 1;
    const monday = moment(value.start_date).startOf('isoWeek');
    let leftInWeeks = this.getPast(moment(value.start_date));
    leftInWeeks = leftInWeeks <= 0 ? leftInWeeks - 1 : leftInWeeks;

    return {
      ...attribute,
      width: widthInDays * this._pointWidth,
      left: leftInWeeks * this._columnWidth + (moment(value.start_date).diff(monday, 'days') * this._pointWidth),
    };
  }

  getMilestone(attribute) {
    const { value } = attribute;
    const widthInDays = 1;
    const monday = moment(value.date).startOf('isoWeek');
    const leftInWeeks = this.getPast(moment(value.date));

    return {
      ...attribute,
      width: widthInDays * this._pointWidth,
      left: leftInWeeks * this._columnWidth + (moment(value.date).diff(monday, 'days') * this._pointWidth),
    };
  }
};
