const { connect } = require('react-redux');
const Component = require('../../components/Pinboard/Pinboard.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  favourites: state.favourites,
  canEdit: state.canEdit,
  isWaiting: state.waitingFor.filter((key) => key === 'favourites').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getFavourites: () => dispatch(actions.getFavourites()),
  deleteResourceFavourites: (resource, type) => dispatch(actions
    .deleteResourceFavourites(resource, type)),
  deleteSearch: (searchId) => dispatch(actions.deleteSearch(searchId)),
  updateSearchLabel: (searchId, search) => dispatch(actions.updateSearchLabel(searchId, search)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
