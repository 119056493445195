const React = require('react');

module.exports = class ProductionChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'column',
        spacingBottom: 0,
        backgroundColor: '#FCFCFC',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
          },
        },
        column: {
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
          },
          borderColor: '#77966D',
          borderWidth: 2,
        },
        scatter: {
          color: 'rgba(0,0,0,0)',
          marker: {
            radius: 3,
            lineWidth: 1,
            lineColor: '#6622CC',
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
      xAxis: {
        categories: [
          'Same month last year',
          '',
          '3 m ago',
          '2 m ago',
          'Last',
          '',
        ],
        lineColor: '#CACACA',
        labels: {
          style: {
            textOverflow: 'none',
          },
        },
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
        plotLines: [{
          color: '#F8A01C',
          width: 1,
          value: this.props.target,
          dashStyle: 'dash',
          zIndex: 4,
        }],
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        data: [{
          y: this.props.lastYear,
          color: 'rgba(119,150,109,0.3)',
          borderColor: 'rgba(119,150,109,0.3)',
        }, { y: this.props.target, color: 'rgba(0,0,0,0)', borderColor: 'rgba(0,0,0,0)' }, {
          y: this.props.threeMonthsAgo,
          color: 'rgba(0,0,0,0)',
        }, { y: this.props.twoMonthsAgo, color: 'rgba(0,0,0,0)' }, {
          y: this.props.thisMonth,
          color: '#77966D',
          dataLabels: {

            style: {
              fontWeight: 'bold',
            },
          },
        }],
      }, {
        type: 'scatter',
        data: [{
          x: 5,
          y: this.props.lastForecast,
          color: '#6622CC',
          dataLabels: {
            enabled: true,
          },
        }, [5, this.props.minForecast], [5, this.props.maxForecast]],
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="briefing-aside-chart" id="briefing-aside-chart-monthly__production">
        <div className="briefing-aside-chart__header">
          <h5 className="briefing-aside-chart__title">Monthly Production</h5>
          <h6 className="briefing-aside-chart__subtitle">in thousands</h6>
        </div>
        <div className="briefing-aside-chart__content"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
        <div className="briefing-aside-chart__legend">
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--target">
            Monthly average
            target for current year
          </div>
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--max-forecast">
            Min/max production forecast
          </div>
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--last-forecast">
            Last production forecast
          </div>
        </div>
      </div>
    );
  }
};
