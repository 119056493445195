const React = require('react');
const ForProjects = require('../../containers/ForFocusedProject');
const ForOpportunities = require('../../containers/ForFocusedOpportunity');
const ProjectTitle = require('../projects/sidebar/ProjectSidebarTitle.react');
const OpportunityTitle = require('../opportunities/sidebar/OpportunitySidebarTitle.react');

const ItemSidebarTitle = ({ creation }) => (
  <div>
    <ForProjects><ProjectTitle creation={creation} /></ForProjects>
    <ForOpportunities><OpportunityTitle creation={creation} /></ForOpportunities>
  </div>
);

module.exports = ItemSidebarTitle;
