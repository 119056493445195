const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/TotalProduction.react');

const mapStateToProps = (state) => ({
  productionValue: state.month_revenue,
  trendOnForecast: state.revenue_on_production_plan_perc,
  trendOnPreviousMonth: state.revenue_on_prev_month_perc,
});

module.exports = connect(mapStateToProps)(Component);
