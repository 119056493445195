const React = require('react');
const isEqual = require('react-fast-compare');

const FiltersTransformer = ({
  children, availableMetadata = [],
  ...rest
}) => {
  const getSingleValueFilter = (name, filters) => filters[name];

  const getRangeStandardFilter = (name, filters) => {
    const minKey = `min-${name}`;
    const maxKey = `max-${name}`;
    const minValue = filters[minKey];
    const maxValue = filters[maxKey];
    const filter = {};

    if (minValue !== undefined) {
      filter.min = minValue;
    }
    if (maxValue !== undefined) {
      filter.max = maxValue;
    }

    return filter;
  };

  const getDurationRangeStandardPartial = (min, max) => {
    let modifier = 'on';
    let value = min;

    if (min !== max) {
      if (min !== undefined) {
        modifier = 'after';
      } else if (max !== undefined) {
        modifier = 'before';
        value = max;
      }
    }

    return {
      modifier,
      value,
    };
  };

  const getDurationRangeStandardFilter = (filters) => {
    const minStartKey = 'min-start-date';
    const maxStartKey = 'max-start-date';
    const minEndKey = 'min-end-date';
    const maxEndKey = 'max-end-date';
    const minStartValue = filters[minStartKey];
    const maxStartValue = filters[maxStartKey];
    const minEndValue = filters[minEndKey];
    const maxEndValue = filters[maxEndKey];
    const startPartial = getDurationRangeStandardPartial(minStartValue, maxStartValue);
    const endPartial = getDurationRangeStandardPartial(minEndValue, maxEndValue);
    const filter = {};

    if (startPartial.value !== undefined) {
      filter.start = startPartial;
    }
    if (endPartial.value !== undefined) {
      filter.end = endPartial;
    }

    return filter;
  };

  const getListStandardFilter = (name, filters) => {
    const values = filters[name];

    if (values) {
      return values.split(',');
    }
    return [];
  };

  /**
   * Returns map of filters representing metadata.
   * @param filters
   * @return {{}}
   */
  const getMetadataFiltersToStandard = (filters) => {
    const metadataFilters = {};

    availableMetadata.forEach((metadata) => {
      const key = metadata.name;
      let value = '';

      if (metadata.type === 'list') {
        value = getListStandardFilter(key, filters);
      } else {
        value = getSingleValueFilter(key, filters);
      }
      metadataFilters[key] = value;
    });

    return metadataFilters;
  };
  const formatFiltersToStandard = (filters) => ({
    id: getSingleValueFilter('id', filters),
    project: getSingleValueFilter('project', filters),
    value: getRangeStandardFilter('value', filters),
    prob: getRangeStandardFilter('prob', filters),
    po: getSingleValueFilter('po', filters),
    client: getListStandardFilter('client', filters),
    pm: getListStandardFilter('pm', filters),
    account: getListStandardFilter('account', filters),
    duration: getDurationRangeStandardFilter(filters),
    margin: getRangeStandardFilter('margin', filters),
    progress: getRangeStandardFilter('progress', filters),
    'budget-consumption': getRangeStandardFilter('budget-consumption', filters),
    'budget-status': getListStandardFilter('budget-status', filters),
    'ext-cost': getRangeStandardFilter('ext-cost', filters),
    'ext-cost-value': getRangeStandardFilter('ext-cost-value', filters),
    probability: getRangeStandardFilter('probability', filters),
    'client-contact': getListStandardFilter('client-contact', filters),
    'project-type': getListStandardFilter('project-type', filters),
    'job-order': getSingleValueFilter('job-order', filters),
    'job-order-category': getListStandardFilter('job-order-category', filters),
    'invoice-plan-mode': getListStandardFilter('invoice-plan-mode', filters),
    program: getListStandardFilter('program', filters),
    archived: getSingleValueFilter('archived', filters),
    'archived-date': getRangeStandardFilter('archived-date', filters),
    ownership: getListStandardFilter('ownership', filters),
    stage: getListStandardFilter('stage', filters),
    risk: getListStandardFilter('risk', filters),
    'due-date': getRangeStandardFilter('due-date', filters),
    'contract-date': getRangeStandardFilter('contract-date', filters),
    whitelist: getSingleValueFilter('whitelist', filters),
  });

  /**
   * Formats given filters in a way they can be easily used by AdvancedSearch.
   * @param filters {{}}
   * @returns {{}}
   */
  const getStandardFilters = (filters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersToStandard(filters),
      ...getMetadataFiltersToStandard(filters),
    };

    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue.length === 0
        || isEqual(filterValue, {});
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  const getDurationRangeFromStandard = (name, attribute, standardFilters) => {
    const filterValue = standardFilters[name];

    if (!filterValue) {
      return '';
    }

    const formatted = {};

    const { start } = filterValue;
    const { end } = filterValue;

    if (start) {
      if (start.modifier === 'on') {
        formatted['min-start'] = start.value;
        formatted['max-start'] = start.value;
      } else if (start.modifier === 'after') {
        formatted['min-start'] = start.value;
      } else if (start.modifier === 'before') {
        formatted['max-start'] = start.value;
      }
    }

    if (end) {
      if (end.modifier === 'on') {
        formatted['min-end'] = end.value;
        formatted['max-end'] = end.value;
      } else if (filterValue.end.modifier === 'after') {
        formatted['min-end'] = end.value;
      } else if (filterValue.end.modifier === 'before') {
        formatted['max-end'] = end.value;
      }
    }

    return formatted[attribute];
  };

  const getRangeFromStandard = (name, attribute, standardFilters) => (standardFilters[name]
    ? standardFilters[name][attribute]
    : null);

  const getListFromStandard = (name, standardFilters) => (standardFilters[name]
    ? standardFilters[name].join()
    : null);

  const getMetadataFiltersFromStandard = (standardFilters) => {
    const metadataFilters = {};

    availableMetadata.forEach((metadata) => {
      const key = metadata.name;
      let value = '';

      if (metadata.type === 'list') {
        value = getListFromStandard(key, standardFilters);
      } else {
        value = getSingleValueFilter(key, standardFilters);
      }
      metadataFilters[key] = value;
    });

    return metadataFilters;
  };

  const formatFiltersFromStandard = (standardFilters) => ({
    id: getSingleValueFilter('id', standardFilters),
    project: getSingleValueFilter('project', standardFilters),
    'min-value': getRangeFromStandard('value', 'min', standardFilters),
    'max-value': getRangeFromStandard('value', 'max', standardFilters),
    'min-prob': getRangeFromStandard('prob', 'min', standardFilters),
    'max-prob': getRangeFromStandard('prob', 'max', standardFilters),
    po: getSingleValueFilter('po', standardFilters),
    client: getListFromStandard('client', standardFilters),
    pm: getListFromStandard('pm', standardFilters),
    account: getListFromStandard('account', standardFilters),
    'min-start-date': getDurationRangeFromStandard('duration', 'min-start', standardFilters),
    'max-start-date': getDurationRangeFromStandard('duration', 'max-start', standardFilters),
    'min-end-date': getDurationRangeFromStandard('duration', 'min-end', standardFilters),
    'max-end-date': getDurationRangeFromStandard('duration', 'max-end', standardFilters),
    'min-margin': getRangeFromStandard('margin', 'min', standardFilters),
    'max-margin': getRangeFromStandard('margin', 'max', standardFilters),
    'min-progress': getRangeFromStandard('progress', 'min', standardFilters),
    'max-progress': getRangeFromStandard('progress', 'max', standardFilters),
    'min-budget-consumption': getRangeFromStandard('budget-consumption', 'min', standardFilters),
    'max-budget-consumption': getRangeFromStandard('budget-consumption', 'max', standardFilters),
    'budget-status': getListFromStandard('budget-status', standardFilters),
    'min-ext-cost': getRangeFromStandard('ext-cost', 'min', standardFilters),
    'max-ext-cost': getRangeFromStandard('ext-cost', 'max', standardFilters),
    'min-ext-cost-value': getRangeFromStandard('ext-cost-value', 'min', standardFilters),
    'max-ext-cost-value': getRangeFromStandard('ext-cost-value', 'max', standardFilters),
    'min-probability': getRangeFromStandard('probability', 'min', standardFilters),
    'max-probability': getRangeFromStandard('probability', 'max', standardFilters),
    'client-contact': getListFromStandard('client-contact', standardFilters),
    'project-type': getListFromStandard('project-type', standardFilters),
    'job-order': getSingleValueFilter('job-order', standardFilters),
    'job-order-category': getListFromStandard('job-order-category', standardFilters),
    'invoice-plan-mode': getListFromStandard('invoice-plan-mode', standardFilters),
    program: getListFromStandard('program', standardFilters),
    archived: getSingleValueFilter('archived', standardFilters),
    ownership: getListFromStandard('ownership', standardFilters),
    stage: getListFromStandard('stage', standardFilters),
    risk: getListFromStandard('risk', standardFilters),
    'min-due-date': getRangeFromStandard('due-date', 'min', standardFilters),
    'max-due-date': getRangeFromStandard('due-date', 'max', standardFilters),
    'min-contract-date': getRangeFromStandard('contract-date', 'min', standardFilters),
    'max-contract-date': getRangeFromStandard('contract-date', 'max', standardFilters),
    'min-archived-date': getRangeFromStandard('archived-date', 'min', standardFilters),
    'max-archived-date': getRangeFromStandard('archived-date', 'max', standardFilters),
    whitelist: getSingleValueFilter('whitelist', standardFilters),
  });

  /**
   * Returns flattened filters, this way they can be easily converted in query string.
   * @param standardFilters {{}}
   * @returns {{}}
   */
  const getFiltersFromStandard = (standardFilters) => {
    const clean = {};

    const formattedFilters = {
      ...formatFiltersFromStandard(standardFilters),
      ...getMetadataFiltersFromStandard(standardFilters),
    };
    Object.keys(formattedFilters).forEach((key) => {
      const filterValue = formattedFilters[key];
      const isEmpty = filterValue === undefined || filterValue === null || filterValue === '';
      if (!isEmpty) {
        clean[key] = filterValue;
      }
    });

    return clean;
  };

  return React
    .cloneElement(children, {
      ...rest,
      getStandardFilters,
      getFiltersFromStandard,
    });
};

module.exports = FiltersTransformer;
