/**
 * Filter items on a given key.
 * The key must be contained in some attributes for the item to be shown.
 *
 * @param key
 * @param items
 * @returns {Array} items filtered by key
 */
module.exports = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param keyword
   * @param value
   * @returns {boolean}
   */
  const found = (keyword, value) => {
    if (value) return value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    return false;
  };

  const isInName = (keyword, name) => found(keyword, name);

  const isAll = (id) => id === 'all';

  const isActive = (item) => item.active;

  return key
    ? items.filter((item) => isAll(item.id) || isInName(key, item.name) || isActive(item))
    : items;
};
