const React = require('react');
const numeral = require('numeral');
const DayPicker = require('../containers/DayPicker');
const SearchBox = require('../containers/SearchBox');
const SectionHeader = require('../../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const BuFilter = require('../../../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
const Counter = require('./Counter.react');
const EmployeeDetails = require('../containers/EmployeeDetails');
const FeatureService = require('../../services/FeatureService');
const DateService = require('../../../../../../../services/DateService');

const Actions = ({
  buEnabled,
  buFilter,
  changeBusinessUnit,
  projects,
  showWeeklyHoursModal,
  date,
  userId,
  changeDate,
  totalWeekHours,
  getDayCapacity,
  getWeekCapacity,
  isHoliday,
  employeeId,
}) => {
  const getBuFilter = () => {
    if (buEnabled) {
      return (
        <div className="timesheet-detailed__bu-select">
          <BuFilter onApply={changeBusinessUnit} selectedValues={buFilter} />
        </div>
      );
    }
    return null;
  };

  const getEmployeeDetails = () => {
    if (userId != null) {
      return (
        <EmployeeDetails />
      );
    }
    return null;
  };

  /**
   * Return the total hours in the given area.
   * @param area
   * @returns {number}
   */
  const getAreaHours = (area) => {
    const { hours } = area;
    return hours.internal + hours.remote + hours.travel + hours.night_shift + hours.overtime;
  };

  /**
   * Return the total hours for the given project in the current date.
   * @param project
   * @returns {number}
   */
  const getProjectDailyHours = (project) => project.areas
    .reduce((sum, area) => sum + getAreaHours(area), 0);

  /**
   * Return the total hours in the current date.
   * @returns {number}
   */
  const getDailyHours = () => projects
    .reduce((sum, project) => sum + getProjectDailyHours(project), 0);

  /**
   * Return the day-by-day sum for the hours in the current week.
   * @returns {*}
   */
  const getWeeklyHours = () => totalWeekHours.map((day) => {
    if (day.date === date) {
      return {
        ...day,
        value: getDailyHours(),
      };
    }
    return day;
  });

  const weeklyHoursTotal = getWeeklyHours().reduce((sum, day) => sum + day.value, 0);

  const formatHours = (value) => numeral(value).format('0,0.[00]');

  const weeklyHours = formatHours(weeklyHoursTotal);

  const dailyHours = formatHours(getDailyHours());

  function onDateChange(selectedDate) {
    changeDate(selectedDate, userId);
  }

  function onWeeklyCounterClick() {
    showWeeklyHoursModal(getWeeklyHours());
  }

  function getStyle() {
    let style = 'timesheet__actions wethod-section-actions';
    if (userId != null) {
      style += ' timesheet__actions--others-view';
    }
    return style;
  }

  /**
   * Return total amount of week capacity.
   * @type {number}
   */
  const weeklyCapacity = FeatureService.isEmployeeCapacityEnabled() ? getWeekCapacity() : 40;

  /**
   * Return capacity for the selected day.
   * @return {number}
   */
  const dailyCapacity = FeatureService.isEmployeeCapacityEnabled() ? getDayCapacity(date) : 8;

  function getHolidayForMonth(month) {
    return DateService.getHolidayForMonthRequest(month, employeeId);
  }

  return (
    <div className={getStyle()}>
      <SectionHeader
        current_section="Timesheet"
        preview_anchor_id="timesheet"
        helper_url="friday/index/#timesheet" />
      <div className="timesheet-detailed__actions-container">
        <div className="timesheet-detailed__actions-container--left">
          <DayPicker changeDate={onDateChange}
            getHolidays={getHolidayForMonth} />
          {getBuFilter()}
          <SearchBox />
        </div>
        <div className="timesheet-detailed__actions-container--right">
          <Counter label="Hours/Week"
            id="hours-week-counter"
            value={`${weeklyHours}`}
            onClick={onWeeklyCounterClick}
            tooltip="Open details"
            capacity={weeklyCapacity} />
          <Counter label="Hours/Day"
            value={`${dailyHours}`}
            id="hours-day-counter"
            capacity={dailyCapacity}
            isHoliday={FeatureService.isEmployeeCapacityEnabled() ? isHoliday(date) : false} />
        </div>
      </div>
      {getEmployeeDetails()}
    </div>
  );
};

module.exports = Actions;
