const { connect } = require('react-redux');
const Component = require('../../components/employee-tags/EmployeeTagsTable.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  employeeTags: state.employeeTags,
  isWaiting: state.waitingFor
    .filter((key) => key === 'get-employee-tags').length > 0,
  isDeleting: state.waitingFor
    .filter((key) => key === 'delete-employee-tags').length > 0,
  isVisible: true,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployeeTags: () => dispatch(actions.getEmployeeTags()),
  onBlurCategory: (event) => dispatch(actions.saveEmployeeTagCategory({
    id: parseInt(event.target.name.replace('employee-tag-category-', '')),
    name: event.target.value,
    sort: 999,
  })),
  addNewCategory: () => dispatch(actions.addNewCategory()),
  onDeleteCategory: (id) => dispatch(actions.deleteCategory(id)),
  onDeleteCategoryRequest: (id) => dispatch(actions.deleteCategoryRequest(id)),
  onCreateCategory:
    (employeeTagsCategory) => dispatch(actions.createEmployeeTagsCategory(employeeTagsCategory)),
  onCreateTag: (tag) => dispatch(actions.createEmployeeTag(tag)),
  onDeleteTag: (tag) => dispatch(actions.deleteEmployeeTagItem(tag)),
  onSaveTag: (tag) => dispatch(actions.saveEmployeeTagItem(tag)),
  setDisabledCategory:
    (category, status) => dispatch(actions.setEmployeeTagCategorySaving(category, status)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
