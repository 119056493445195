const { connect } = require('react-redux');
const actions = require('../actions');
const Sidebar = require('../components/ClientSidebar.react');

const mapStateToProps = (state) => ({
  client: state.focusedItem,
  isSaving: state.isSavingClient,
  type: 'people',
  canEdit: true,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  addClient: (client) => dispatch(actions.savePerson(client)),
  updateClient: (client) => dispatch(actions.updatePerson(client)),
  addPhone: (clientId, phone) => dispatch(actions.addPersonPhone(clientId, phone)),
  updatePhone: (clientId, phone) => {
    dispatch(actions.updatePersonPhone(clientId, phone));
  },
  deletePhone: (contactId, phone) => dispatch(actions.deletePersonPhone(contactId, phone)),
  onDelete: (client) => dispatch(actions.showDeletePersonModal(client)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
