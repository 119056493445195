const { connect } = require('react-redux');
const Component = require('../components/Interests.react');

const mapStateToProps = (state) => ({
  tags: state.interests,
  knownTags: state.known_interests,
  placeholder: "What's up your alley?",
});

module.exports = connect(mapStateToProps)(Component);
