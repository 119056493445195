const React = require('react');
const formatNumber = require('../../../../../../../services/FormatService').formatDecimalNumber;
const Multicurrency = require('../../services/multicurrencyService');
const MulticurrencyValue = require('./MulticurrencyValue.react');
const ValueInput = require('./OrderValueInput.react');

const ValueController = ({
  order,
  currencies,
  error,
  onChange,
  updateErrors,
  name,
  readOnly,
  onFocus,
}) => {
  const updatedOrder = {
    ...order,
    currencies,
  };

  function getHelperText() {
    if (Multicurrency.isOrderMulticurrency(updatedOrder) && updatedOrder.currency) {
      const masterCurrency = Multicurrency
        .getCurrencyCode(Multicurrency.getMasterCurrency(updatedOrder));
      const conversionRate = formatNumber(Multicurrency.getConversionRate(updatedOrder), 10);

      const formattedValue = formatNumber(updatedOrder.value, 2);

      const conversionRateLabel = `(1 ${masterCurrency} = ${conversionRate} ${updatedOrder.currency})`;
      const valueLabel = updatedOrder.value ? `${formattedValue} ${masterCurrency} ` : '';

      return `${valueLabel}${conversionRateLabel}`;
    }
    return null;
  }

  function handleMulticurrencyValueChange(n, value) {
    let parsedValue = parseFloat(value);
    parsedValue = !Number.isNaN(parsedValue) ? parsedValue : null;

    onChange(name, parsedValue);
  }

  function handleValueChange(e) {
    const { value } = e.target;
    let parsedValue = parseFloat(value);
    parsedValue = !Number.isNaN(parsedValue) ? parsedValue : null;

    onChange(name, parsedValue);
  }

  if (Multicurrency.isOrderMulticurrency(updatedOrder)) {
    return ( // Multicurrency edit
      <MulticurrencyValue order={updatedOrder}
        readOnly={readOnly}
        onChange={onChange}
        onValueChange={handleMulticurrencyValueChange}
        name={name}
        error={error}
        onFocus={onFocus}
        updateErrors={updateErrors} />
    );
  }
  return (
    <ValueInput name={name}
      readOnly={readOnly}
      value={updatedOrder.value}
      error={error}
      onFocus={onFocus}
      helperText={readOnly ? getHelperText() : ''}
      updateErrors={updateErrors}
      onChange={handleValueChange} />
  );
};

module.exports = ValueController;
