const constants = require('./constants');
const ArchivedStatusList = require('./models/ArchivedStatusList');
const TranslatorService = require('./services/TranslatorService');
const HTTPService = require('../../../../services/HTTPService');
const RequestService = require('./services/RequestService');

class PipelineReducer {
  constructor(state) {
    const archivedStatusList = new ArchivedStatusList();

    this.state = {
      projectMetadata: [], // metadata related to project attributes
      ...state,
      items: [],
      currentSegment: null,
      modalToShow: PipelineReducer.getModalToShow(state),
      modalData: null,
      sidebarToShow: null,
      focusedItem: null,
      showSnackbar: false,
      snackbarData: null,
      waitingFor: [], // contains a key for each pending request
      serverError: null,
      pageLimit: 0,
      pageOffset: 0,
      hasMorePages: true,
      archivedStatusList,
      sortBy: {
        col: 'date_start',
        order: 'desc',
      },
    };

    this.state.metadata = this.getNormalizedMetadata();

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Remove pending getItems requests and add the last one.
   * @param state
   * @param requestId
   * @return {*}
   */
  static addWaitingForItems(state, requestId) {
    return state.waitingFor
      .filter((key) => !key.includes('get-items'))
      .concat(`get-items-${requestId}`);
  }

  static getModalToShow(state) {
    if (state.intercompany) {
      return 'intercompany-link';
    }
    return null;
  }

  static getSidebarToShow(item) {
    if (item && item.project) {
      return item.project.probability > 75 ? constants.TYPE_PROJECTS : constants.TYPE_OPPORTUNITIES;
    }
    return null;
  }

  static getFilteredItems(items, projectToFilterId) {
    if (projectToFilterId) {
      return items.filter((project) => project.project.id !== projectToFilterId);
    }
    return items;
  }

  static needsReview(item, archive, reviewEnabled) {
    if (archive && reviewEnabled) {
      const requestReview = item ? item.project_type.request_review : null;
      const estimate = item ? item.project.estimate : null;
      return requestReview != null ? estimate >= requestReview : false;
    }
    return false;
  }

  static updateURL(search, filters, type) {
    const actualFilters = search !== '' ? {
      ...filters,
      search,
    } : { ...filters };

    const url = TranslatorService.getPipelineUrl(type, actualFilters);

    Wethod.navigate(url);
  }

  /**
   * Formats available metadata in a way easier to understand and use.
   * @returns {*}
   */
  getNormalizedMetadata() {
    return this.state.metadata.map((metadata) => ({
      id: metadata.id,
      label: metadata.key,
      name: `metadata-${metadata.key}`,
      constraints: metadata.required ? ['required'] : [],
      availableValues: metadata.value_list,
      type: metadata.value_list.length ? 'list' : 'text',
    }));
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SHOW_MODAL_INTERCOMPANY_LINK:
        return {
          ...state,
          modalToShow: 'intercompany-link',
        };
      case constants.SHOW_MODAL_SEARCH_SAVE:
        return {
          ...state,
          modalToShow: 'search-save',
        };
      case constants.SHOW_MODAL_SEARCH_EXPORT:
        return {
          ...state,
          modalToShow: 'search-export',
        };
      case constants.SHOW_MODAL_SEARCH_SHARE:
        return {
          ...state,
          modalToShow: 'search-share',
        };
      case constants.SHOW_MODAL_INTERCOMPANY_DETAILS:
        return {
          ...state,
          modalToShow: 'intercompany-details',
          modalData: action.projectId,
        };
      case constants.SHOW_MODAL_CONFIRM_DELETE:
        return {
          ...state,
          modalToShow: 'confirm-delete',
          modalData: action.item ? action.item : null,
        };
      case constants.SHOW_MODAL_SEGMENT_DELETE:
        return {
          ...state,
          modalToShow: 'segment-confirm-delete',
          modalData: action.segment,
        };
      case constants.SHOW_MODAL_DELETE_FAILURE:
        return {
          ...state,
          modalToShow: 'delete-failure',
          modalData: action.project,
        };
      case constants.SHOW_MODAL_CONFIRM_ARCHIVE:
        return {
          ...state,
          modalToShow: 'confirm-archive',
          modalData: {
            item: action.item ? action.item : null,
            archive: action.archive,
          },
        };
      case constants.SHOW_MODAL_ARCHIVE_FAILURE:
        return {
          ...state,
          modalToShow: 'archive-failure',
          modalData: action.project,
        };
      case constants.SHOW_MODAL_PROJECT_SHARE:
        return {
          ...state,
          modalToShow: 'project-share',
          modalData: action.project,
        };
      case constants.SHOW_MODAL_REVIEW_REQUEST:
        return {
          ...state,
          modalToShow: 'review-request',
          modalData: { project: action.project },
        };
      case constants.SHOW_MODAL_REVIEW_START: {
        return {
          ...state,
          modalToShow: 'review-start',
          modalData: action.data,
        };
      }
      case constants.SHOW_MODAL_EDIT_FEEDBACK: {
        return {
          ...state,
          modalToShow: 'edit-feedback',
          modalData: { project: action.project, handleFeedbackSave: action.callback },
        };
      }
      case constants.SHOW_MODAL_SAVE_ERROR: {
        return {
          ...state,
          modalToShow: 'save-error',
          modalData: action.message,
        };
      }
      case constants.GET_INTERCOMPANY_INVITATION_INFO_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('intercompany-invitation-info'),
        };
      case constants.GET_INTERCOMPANY_INVITATION_INFO_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'intercompany-invitation-info'),
          intercompany: {
            ...state.intercompany,
            ...action.info,
            projects: action.projects,
          },
        };
      case constants.GET_INTERCOMPANY_INVITATION_INFO_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'intercompany-invitation-info'),
          modalToShow: 'intercompany-invalid',
        };
      case constants.CONNECT_INTERCOMPANY_PROJECT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('connect-intercompany-project'),
        };
      case constants.CONNECT_INTERCOMPANY_PROJECT_SUCCESS:
        return {
          ...state,
          items: state.items
            .map((item) => (item.project.id === action.payload.projectId
              ? { ...item, project: { ...item.project, intercompany: true } } : item)),
          waitingFor: state.waitingFor.filter((key) => key !== 'connect-intercompany-project'),
          modalToShow: null,
          intercompany: {
            ...state.intercompany,
            accepted: true,
          },
        };
      case constants.CONNECT_INTERCOMPANY_PROJECT_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'connect-intercompany-project'),
          serverError: action.message,
        };
      case constants.SHOW_SEGMENT_EDIT:
        return ({
          ...state,
          sidebarToShow: 'segment',
          focusedSegment: {
            ...action.segment,
            filters: HTTPService.getQueryParams(action.segment.filters),
          },
        });
      case constants.SHOW_SEGMENT_CREATE:
        return ({
          ...state,
          sidebarToShow: 'segment',
          // New segments have currently applied filters
          focusedSegment: {
            filters: state.filters,
          },
        });
      case constants.SHOW_ADVANCED_SEARCH:
        return ({
          ...state,
          sidebarToShow: 'advanced-search',
        });
      case constants.SHOW_SIDEBAR:
        return ({
          ...state,
          sidebarToShow: PipelineReducer.getSidebarToShow(action.item),
          focusedItem: action.item,
        });
      case constants.CLOSE_SIDEBAR:
        return ({
          ...state,
          sidebarToShow: null,
          focusedItem: null,
        });
      case constants.GET_ITEMS_REQUEST: {
        PipelineReducer.updateURL(action.search, action.filters, state.type);

        return {
          ...state,
          search: action.search,
          pageLimit: action.limit,
          pageOffset: action.offset,
          sortBy: action.sortBy,
          filters: action.filters,
          buFilter: action.bu,
          hasMorePages: true,
          items: action.offset ? state.items : [],
          waitingFor: PipelineReducer.addWaitingForItems(state, action.requestId),
        };
      }
      case constants.GET_ITEMS_SUCCESS: {
        if (!RequestService.isWaitingForItemsWithId(state.waitingFor, action.requestId)) {
          return state;
        }
        return {
          ...state,
          hasMorePages: action.items.length === state.pageLimit,
          items: state.items.concat(action.items),
          waitingFor: state.waitingFor.filter((key) => key !== `get-items-${action.requestId}`),
        };
      }
      case constants.SEARCH_SAVE_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('search-save'),
        };
      }
      case constants.SEARCH_SAVE_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'search-save'),
          modalToShow: null,
        };
      }
      case constants.DELETE_PROJECT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-project'),
        };
      }
      case constants.DELETE_PROJECT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-project'),
          items: PipelineReducer.getFilteredItems(state.items, action.project.id),
          modalToShow: null,
          modalData: null,
          sidebarToShow: null,
          focusedItem: null,
        };
      }
      case constants.DELETE_PROJECT_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-project'),
        };
      }
      case constants.ARCHIVE_PROJECT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('archive-project'),
        };
      }
      case constants.ARCHIVE_PROJECT_SUCCESS: {
        let visibleItems = state.items;

        /*
         When archived projects are not visible, remove the project archived
         When only archived projects are visible, remove the project unarchived
         */
        if ((state.filters.archived === false && action.archive)
          || (state.filters.archived === true && !action.archive)) {
          visibleItems = PipelineReducer.getFilteredItems(visibleItems, action.item.project.id);
        }

        // Show review modal when needed, close modal otherwise
        const modal = PipelineReducer
          .needsReview(action.item, action.archive, state.projectReviewEnabled)
          ? {
            modalToShow: 'review-request',
            modalData: { project: action.item.project },
          }
          : {
            modalToShow: null,
            modalData: null,
          };

        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'archive-project'),
          items: visibleItems.map((item) => {
            if (item.project.id === action.item.project.id) {
              return {
                ...item,
                project: {
                  ...item.project,
                  archived: action.archive,
                  archived_date: action.archivedDate,
                },
              };
            }
            return item;
          }),
          ...modal,
          sidebarToShow: null,
          focusedItem: null,
        };
      }
      case constants.ARCHIVE_PROJECT_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'archive-project'),
        };
      }
      case constants.GET_LAST_REVIEW_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('last-review'),
        };
      }
      case constants.GET_LAST_REVIEW_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'last-review'),
          modalData: state.modalData ? {
            ...state.modalData,
            lastReview: action.lastReview,
          } : null,
        };
      }
      case constants.CREATE_REVIEW_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('review-request'),
        };
      }
      case constants.CREATE_REVIEW_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'review-request'),
        };
      }
      case constants.GET_REASON_WHY_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('reason-why'),
        };
      }
      case constants.GET_REASON_WHY_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'reason-why'),
          modalData: state.modalData
            ? {
              ...state.modalData,
              reasonWhyList: action.reasons,
            } : null,
        };
      }
      case constants.SAVE_REASON_WHY_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-reason-why'),
        };
      }
      case constants.SAVE_REASON_WHY_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-reason-why'),
          modalToShow: null,
          modalData: null,
          focusedItem: state.focusedItem
            ? {
              ...state.focusedItem,
              project: {
                ...state.focusedItem.project,
                reason_why: action.reasonId,
              },
            }
            : state.focusedItem,
          items: state.items.map((item) => {
            if (item.project.id === action.projectId) {
              return {
                ...item,
                project: {
                  ...item.project,
                  reason_why: action.reasonId,
                },
              };
            }
            return item;
          }),
        };
      }
      case constants.SAVE_ITEM_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-item-${action.id}`),
        };
      }
      case constants.SAVE_ITEM_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-item-${action.item.project.id}`),
          items: state.items.map((item) => {
            if (item.project.id === action.item.project.id) {
              return {
                ...item,
                ...action.item,
              };
            }
            return item;
          }),
          focusedItem: state.focusedItem ? action.item : null,
        };
      }
      case constants.SAVE_ITEM_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-item-${action.id}`),
        };
      }
      case constants.CREATE_ITEM_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('create-item'),
        };
      }
      case constants.CREATE_ITEM_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'create-item'),
          items: [action.item].concat(state.items),
          focusedItem: state.focusedItem ? action.item : null,
        };
      }
      case constants.CREATE_ITEM_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'create-item'),
        };
      }
      case constants.UPDATE_METADATA_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .concat(`save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
        };
      }
      case constants.UPDATE_METADATA_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .filter((key) => key !== `save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
          items: state.items.map((item) => {
            if (item.project.id === action.projectId) {
              return {
                ...item,
                metadata: item.metadata.map((metadata) => {
                  if (metadata.id === action.metadata.id) {
                    return {
                      ...action.metadata,
                    };
                  }
                  return metadata;
                }),
              };
            }
            return item;
          }),
        };
      }
      case constants.CREATE_METADATA_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .concat(`save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
        };
      }
      case constants.CREATE_METADATA_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .filter((key) => key !== `save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
          items: state.items.map((item) => {
            if (item.project.id === action.projectId) {
              return {
                ...item,
                metadata: item.metadata.concat(action.metadata),
              };
            }
            return item;
          }),
        };
      }
      case constants.DELETE_METADATA_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .concat(`save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
        };
      }
      case constants.DELETE_METADATA_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor
            .filter((key) => key !== `save-${action.projectId}-metadata-${action.metadata.metadata_id}`),
          items: state.items.map((item) => {
            if (item.project.id === action.projectId) {
              return {
                ...item,
                metadata: item.metadata.filter((metadata) => metadata.id !== action.metadata.id),
              };
            }
            return item;
          }),
        };
      }
      case constants.CREATE_SEGMENT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('create-segment'),
        };
      }
      case constants.CREATE_SEGMENT_SUCCESS: {
        const filters = HTTPService.getQueryParams(action.segment.filters);

        return {
          ...state,
          currentSegment: action.segment.id,
          availableSegments: state.availableSegments.concat(action.segment),
          waitingFor: state.waitingFor.filter((key) => key !== 'create-segment'),
          filters,
          sidebarToShow: null,
          focusedItem: null,
        };
      }
      case constants.UPDATE_SEGMENT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('update-segment'),
        };
      }
      case constants.UPDATE_SEGMENT_SUCCESS: {
        return {
          ...state,
          availableSegments: state.availableSegments.map((segment) => {
            if (segment.id === action.segment.id) {
              return action.segment;
            }
            return segment;
          }),
          waitingFor: state.waitingFor.filter((key) => key !== 'update-segment'),
          filters: action.segment.id === state.currentSegment
            ? HTTPService.getQueryParams(action.segment.filters) : state.filters,
          sidebarToShow: null,
          focusedItem: null,
        };
      }
      case constants.DELETE_SEGMENT_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-segment'),
        };
      }
      case constants.DELETE_SEGMENT_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-segment'),
          availableSegments: state.availableSegments
            .filter((segment) => action.segment.id !== segment.id),
        };
      }
      case constants.APPLY_SEGMENT: {
        const filters = HTTPService.getQueryParams(action.segment.filters);

        return {
          ...state,
          currentSegment: action.segment.id,
          filters,
        };
      }
      case constants.APPLY_ADVANCED_SEARCH: {
        return {
          ...state,
          currentSegment: null,
          filters: action.filters,
          sidebarToShow: null,
          focusedItem: null,
        };
      }
      case constants.SHOW_SNACKBAR:
        return ({
          ...state,
          showSnackbar: true,
          snackbarData: action.data,
        });
      case constants.CLOSE_SNACKBAR:
        return ({
          ...state,
          showSnackbar: false,
          snackbarData: null,
        });
      case constants.COLUMN_CONFIGURATION_CHANGE:
        return ({
          ...state,
          visibleColumns: action.columns,
        });
      default:
        return state;
    }
  }
}

module.exports = PipelineReducer;
