const React = require('react');
const PropTypes = require('prop-types');
const PropertyMetadataService = require('../../../services/PropertyMetadataService/PropertyMetadataService');

const FormMetadataManager = ({ children, metadata = [], nameOverrides = {}, ...rest }) => {
  const metadataService = new PropertyMetadataService(metadata, nameOverrides);

  return React
    .cloneElement(children, {
      metadataService,
      ...rest,
    });
};

FormMetadataManager.propTypes = {
  children: PropTypes.node.isRequired,
  metadata: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    can_edit: PropTypes.bool.isRequired,
    cannot_edit_reason: PropTypes.string,
    constraints: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
  })),
};

module.exports = FormMetadataManager;
