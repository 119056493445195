const React = require('react');

module.exports = class WeeklyProductionChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'column',
        spacingBottom: 0,
        backgroundColor: '#FCFCFC',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
          },
          borderColor: '#77966D',
          borderWidth: 2,
        },
      },
      xAxis: {
        categories: [
          'Same week last year',
          '',
          '3 w ago',
          '2 w ago',
          'Last',
          '',
        ],
        lineColor: '#CACACA',
        labels: {
          style: {
            textOverflow: 'none',
          },
        },
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
        plotLines: [{
          color: '#F8A01C',
          width: 1,
          value: this.props.target,
          dashStyle: 'dash',
          zIndex: 4,
          label: {
            text: this.props.target,
            align: 'right',
            y: this.props.target >= this.props.dynamic_target ? -5 : 13,
            x: -5,
            style: {
              fontSize: '11px',
            },
          },
        }, {
          color: '#EF5D60',
          width: 1,
          value: this.props.dynamic_target,
          dashStyle: 'dash',
          zIndex: 4,
          label: {
            text: this.props.dynamic_target,
            align: 'right',
            y: this.props.target >= this.props.dynamic_target ? 13 : -5,
            x: -5,
            style: {
              fontSize: '11px',
            },
          },
        }],
        softMax: Math.max(this.props.target, this.props.dynamic_target),
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        data: [
          { y: this.props.last_year, color: 'rgba(119,150,109,0.3)', borderColor: 'rgba(119,150,109,0.3)' },
          null,
          { y: this.props.three_weeks_ago, color: 'rgba(0,0,0,0)' },
          { y: this.props.two_weeks_ago, color: 'rgba(0,0,0,0)' },
          {
            y: this.props.one_week_ago,
            color: '#77966D',
            dataLabels: {
              style: {
                fontWeight: 'bold',
              },
            },
          },
          null,
        ],
      }],
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="briefing-aside-chart" id="briefing-aside-chart-weekly__production">
        <div className="briefing-aside-chart__header">
          <h5 className="briefing-aside-chart__title">Weekly Production</h5>
          <h6 className="briefing-aside-chart__subtitle">in thousands</h6>
        </div>
        <div className="briefing-aside-chart__content"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
        <div className="briefing-aside-chart__legend">
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--target">
            Weekly average
            target for current year
          </div>
          <div className="briefing-aside-chart__legend-item briefing-aside-chart__legend-item--dynamic-target">
            Dynamic weekly target for current year
          </div>
        </div>
      </div>
    );
  }
};
