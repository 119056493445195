const constants = require('./constants');
const BudgetChangelogCollection = require('./models/BudgetChangelogCollection');

const loadChangelogsRequest = () => ({
  type: constants.LOAD_CHANGELOG_REQUEST,
});

const loadChangelogsSuccess = (changelogs, size) => ({
  type: constants.LOAD_CHANGELOG_SUCCESS,
  items: changelogs,
  size,
});

module.exports.loadChangelogs = (size, page, keyword, orderBy, sort, units) => (dispatch) => {
  dispatch(loadChangelogsRequest());
  const offset = (page - 1) * size;
  const collection = new BudgetChangelogCollection();
  $.when(collection.getBudgetChangelog(offset, size, keyword, orderBy, sort, units.join()))
    .done((changelogs) => dispatch(loadChangelogsSuccess(changelogs, size)));
};

const filterChangelogsRequest = () => ({
  type: constants.FILTER_CHANGELOG_REQUEST,
});

const filterChangelogsSuccess = (changelogs, keyword, size, orderBy, sort, bu) => ({
  type: constants.FILTER_CHANGELOG_SUCCESS,
  items: changelogs,
  keyword,
  size,
  orderBy,
  sort,
  bu,
});

module.exports.filterChangelogs = (size, keyword, orderBy, sort, units) => (dispatch) => {
  dispatch(filterChangelogsRequest());
  const collection = new BudgetChangelogCollection();
  $.when(collection.getBudgetChangelog(0, size, keyword, orderBy, sort, units.join()))
    .done((changelogs) => dispatch(
      filterChangelogsSuccess(changelogs, keyword, size, orderBy, sort, units),
    ));
};

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showModal = showModal;
