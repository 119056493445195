module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_DELETE_PERSON_MODAL = 'SHOW_DELETE_PERSON_MODAL';
module.exports.SHOW_DELETE_COMPANY_MODAL = 'SHOW_DELETE_COMPANY_MODAL';
module.exports.SHOW_MODAL_PROFILE_PIC = 'SHOW_MODAL_PROFILE_PIC';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SWITCH_CLIENT_TYPE = 'SWITCH_CLIENT_TYPE';

module.exports.FILTER_CLIENTS = 'FILTER_CLIENTS';

module.exports.ORDER_CLIENTS = 'ORDER_CLIENTS';

module.exports.LOAD_CLIENTS = 'LOAD_CLIENTS';

module.exports.ADD_CLIENT = 'ADD_CLIENT';
module.exports.REMOVE_CLIENT = 'REMOVE_CLIENT';

module.exports.PERSON_SAVE_REQUEST = 'PERSON_SAVE_REQUEST';
module.exports.PERSON_SAVE_SUCCESS = 'PERSON_SAVE_SUCCESS';

module.exports.PERSON_UPDATE_REQUEST = 'PERSON_UPDATE_REQUEST';
module.exports.PERSON_UPDATE_SUCCESS = 'PERSON_UPDATE_SUCCESS';

module.exports.PERSON_DELETE_REQUEST = 'PERSON_DELETE_REQUEST';
module.exports.PERSON_DELETE_SUCCESS = 'PERSON_DELETE_SUCCESS';

module.exports.PERSON_PHONE_ADD_SUCCESS = 'PERSON_PHONE_ADD_SUCCESS';
module.exports.PERSON_PHONE_ADD_REQUEST = 'PERSON_PHONE_ADD_REQUEST';
module.exports.PERSON_PHONE_EDIT_SUCCESS = 'PERSON_PHONE_EDIT_SUCCESS';
module.exports.PERSON_PHONE_EDIT_REQUEST = 'PERSON_PHONE_EDIT_REQUEST';

module.exports.COMPANY_SAVE_REQUEST = 'COMPANY_SAVE_REQUEST';
module.exports.COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS';

module.exports.COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
module.exports.COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';

module.exports.COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
module.exports.COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
module.exports.COMPANY_DELETE_FAILURE = 'COMPANY_DELETE_FAILURE';

module.exports.GROUP_SAVE_REQUEST = 'GROUP_SAVE_REQUEST';
module.exports.GROUP_SAVE_SUCCESS = 'GROUP_SAVE_SUCCESS';

module.exports.CLIENT_COMPANY_REPLACE_REQUEST = 'CLIENT_COMPANY_REPLACE_REQUEST';
module.exports.CLIENT_COMPANY_REPLACE_SUCCESS = 'CLIENT_COMPANY_REPLACE_SUCCESS';
