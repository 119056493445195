/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

module.exports = class CannotAutoDistributePastModal extends React.Component {
  render() {
    return (
      <Modal title="Cannot auto distribute plan" onCancelClick={this.props.onCancelClick}>
        <div className="production-plan__modal">
          <p>
            Cannot proceed with auto distribution because this project ends in the past.
          </p>
          <p>
            You can always change this project's duration in pipeline or by manually editing it's
            plan.
          </p>
          <div className="profile-contact-info__actions">
            <button type="button"
              onClick={this.props.onCancelClick}
              className="wethod-button">OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }
};
