const React = require('react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const DateInput = require('../../../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const TextInput = require('../../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const ProjectSelect = require('./ProjectSelect.react');
const Table = require('../../../containers/modal/TransferHours/TransferHoursTable');

module.exports = ({ project, date, onProjectChange, onDateChange, frozenUpTo, updateErrors }) => (
  <ModalBody>
    <ModalBodyBlock>
      <div className="timesheets-inline-inputs">
        <ProjectSelect
          id="target-project"
          label="Target Project"
          name="project"
          onChange={onProjectChange}
          value={project} />
        <div className="timesheets-transfer-hours__input--small">
          <TextInput
            readOnly
            name="job-order"
            id="target-job-order"
            label="Job Order"
            value={project ? project.jobOrder : ''} />
        </div>
        <div className="timesheets-transfer-hours__input--small">
          <DateInput
            resettable
            name="date"
            id="target-date"
            label="Target Date"
            value={date}
            disableBeforeEqual={frozenUpTo}
            onChange={onDateChange} />
        </div>
      </div>
    </ModalBodyBlock>
    <Table updateErrors={updateErrors} />
  </ModalBody>
);
