const { connect } = require('react-redux');
const Component = require('../components/PersonRow.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  allowMissingCriteria: state.allowMissingPeopleCriteria,
});

const mapDispatchToProps = (dispatch) => ({
  editVote: (personId, criterionId, vote) => dispatch(actions.editPersonVote(personId, criterionId,
    vote)),
  showNoteModal: (personId, note) => dispatch(actions.showPersonNoteModal(personId, note)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
