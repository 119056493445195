/* eslint-disable react/jsx-no-bind */
const React = require('react');

const withCompare = (Component) => ({ ...props }) => {
  /**
   *
   * @param a
   * @param b
   * @return {string}
   */
  const compare = (a, b) => {
    let key = '';
    if (!b) { // cannot compare by ratio
      if (a === b) {
        key = 'equal';
      } else if (a > b) {
        key = 'grater';
      } else {
        key = 'lower';
      }
    } else {
      const ratio = ((a - b) / b) * 100;

      if (ratio < -20) {
        key = 'much_lower';
      } else if (ratio < -10) {
        key = 'lower';
      } else if (ratio < 0) {
        key = 'almost_equal';
      } else if (ratio === 0) {
        key = 'equal';
      } else if (ratio < 10) {
        key = 'almost_equal';
      } else if (ratio < 20) {
        key = 'greater';
      } else {
        key = 'much_greater';
      }
    }
    return key;
  };

  return <Component compare={compare} {...props} />;
};

module.exports = withCompare;
