const $ = require('jquery');
const moment = require('moment');
const constants = require('./constants');
const OrderModel = require('../../../../../models/Order');
const BudgetAreaModel = require('../../../../../models/BudgetArea');
const CurrencyModel = require('../../../../../models/Currency');

const loadOrdersRequest = () => ({
  type: constants.GET_ORDERS_REQUEST,
});

const loadOrdersSuccess = (orders, size) => ({
  type: constants.GET_ORDERS_SUCCESS,
  items: orders,
  size,
});

module.exports.loadOrders = (size, page, keyword, orderBy, sort, statuses) => (dispatch) => {
  dispatch(loadOrdersRequest());
  const offset = (page - 1) * size;
  const getOrdersRequest = Wethod.request('get:orders:coll', null, keyword, offset, size, orderBy, sort, statuses);
  $.when(getOrdersRequest)
    .done((orders) => dispatch(loadOrdersSuccess(orders, size)));
};

const filterOrdersRequest = (keyword, orderBy, sort, statusFilter) => ({
  type: constants.FILTER_ORDERS_REQUEST,
  keyword,
  orderBy,
  sort,
  statusFilter,
});

const filterOrdersSuccess = (orders, size) => ({
  type: constants.FILTER_ORDERS_SUCCESS,
  items: orders,
  size,
});

module.exports.filterOrders = (size, keyword, orderBy, sort, statuses) => (dispatch) => {
  dispatch(filterOrdersRequest(keyword, orderBy, sort, statuses));
  const offset = 0;
  const getOrdersRequest = Wethod.request('get:orders:coll', null, keyword, offset, size, orderBy, sort, statuses);
  $.when(getOrdersRequest)
    .done((orders) => dispatch(
      filterOrdersSuccess(orders, size),
    ));
};

const getProjectAreasRequest = () => ({
  type: constants.GET_PROJECT_AREAS_REQUEST,
});

const getProjectAreasSuccess = (areas) => ({
  type: constants.GET_PROJECT_AREAS_SUCCESS,
  items: areas,
});

const getProjectAreas = (projectId) => (dispatch) => {
  dispatch(getProjectAreasRequest());
  if (projectId) { // Get project currencies, if a project is selected
    $.when(BudgetAreaModel.getForProject(projectId))
      .done((areas) => dispatch(
        getProjectAreasSuccess(areas.toJSON()),
      ));
  }
};

const getProjectCurrenciesRequest = () => ({
  type: constants.GET_PROJECT_CURRENCIES_REQUEST,
});

const getProjectCurrenciesSuccess = (currencies) => ({
  type: constants.GET_PROJECT_CURRENCIES_SUCCESS,
  items: currencies,
});

const getProjectCurrencies = (projectId) => (dispatch) => {
  dispatch(getProjectCurrenciesRequest());
  if (projectId) { // Get project currencies, if a project is selected
    const projectCurrenciesRequest = CurrencyModel.getForProject(projectId);
    $.when(projectCurrenciesRequest)
      .done((currencies) => dispatch(
        getProjectCurrenciesSuccess(currencies),
      ));
  }
};

module.exports.updateProject = (projectId) => (dispatch) => {
  dispatch(getProjectAreas(projectId));
  dispatch(getProjectCurrencies(projectId));
};

module.exports.showSidebar = (id) => ({
  type: constants.SHOW_SIDEBAR,
  id,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const updateOrderRequest = () => ({
  type: constants.UPDATE_ORDER_REQUEST,
});

const updateOrderSuccess = (order, closeExistingModal) => ({
  type: constants.UPDATE_ORDER_SUCCESS,
  item: order,
  updateSidebar: !closeExistingModal,
});

const updateOrderError = (message) => ({
  type: constants.UPDATE_ORDER_ERROR,
  message,
});

module.exports.updateOrder = (order, closeExistingModal = false) => (dispatch) => {
  dispatch(updateOrderRequest());

  const formattedOrder = {
    ...order,
    date: moment(order.date).format(),
    start: moment(order.start).format(),
    date_paid_on: order.date_paid_on ? moment(order.date_paid_on).format() : null,
    id_project: order.project.id,
    id_supplier: order.supplier.id,
  };

  const request = order.id === null ? OrderModel.create(formattedOrder)
    : OrderModel.update(order.id, formattedOrder);

  request.done((newOrder) => {
    const formattedNewOrder = { ...formattedOrder, ...newOrder };
    dispatch(updateOrderSuccess(formattedNewOrder, closeExistingModal));
    if (closeExistingModal) {
      dispatch(closeModal());
    }
  }).fail((message) => {
    dispatch(updateOrderError(message));
  });
};

const deleteOrderRequest = () => ({
  type: constants.DELETE_ORDER_REQUEST,
});

const deleteOrderSuccess = (order) => ({
  type: constants.DELETE_ORDER_SUCCESS,
  item: order,
});

const deleteOrderFailure = () => ({
  type: constants.DELETE_ORDER_FAILURE,
});

module.exports.deleteOrder = (order, closeExistingModal = false) => (dispatch) => {
  dispatch(deleteOrderRequest());

  OrderModel.delete(order.id).done(() => {
    dispatch(deleteOrderSuccess(order));
    if (closeExistingModal) {
      dispatch(closeModal());
    }
  }).fail((message) => {
    const data = { title: 'Cannot delete order', message };
    dispatch(deleteOrderFailure());
    dispatch(showModal('server-error', data));
  });
};

const sendOrderRequest = (order) => ({
  type: constants.SEND_ORDER_REQUEST,
  item: order,
});

const sendOrderSuccess = (order) => ({
  type: constants.SEND_ORDER_SUCCESS,
  item: order,
});

const sendOrderError = () => ({
  type: constants.SEND_ORDER_ERROR,
});

module.exports.sendOrder = (order, contactId) => (dispatch) => {
  dispatch(sendOrderRequest(order));

  $.when(OrderModel.send(order.id, contactId)).done(() => {
    const newOrder = order;
    newOrder.status = 1;
    dispatch(sendOrderSuccess(newOrder));
  }).fail(() => {
    dispatch(sendOrderError());
  });
};

const approveOrderRequest = (order) => ({
  type: constants.APPROVE_ORDER_REQUEST,
  item: order,
});

const approveOrderSuccess = (order) => ({
  type: constants.APPROVE_ORDER_SUCCESS,
  item: order,
});

module.exports.approveOrder = (order, recipientId = null, message = '') => (dispatch) => {
  dispatch(approveOrderRequest(order));

  $.when(OrderModel.approve(order.id, recipientId, message)).done((updatedOrder) => {
    dispatch(approveOrderSuccess(updatedOrder));
  });
};

const setPaidOrderRequest = (order) => ({
  type: constants.SET_PAID_ORDER_REQUEST,
  item: order,
});

const setPaidOrderSuccess = (order) => ({
  type: constants.SET_PAID_ORDER_SUCCESS,
  item: order,
});

module.exports.setPaidOrder = (order) => (dispatch) => {
  dispatch(setPaidOrderRequest(order));

  $.when(OrderModel.setAsPaid(order.id)).done((updatedOrder) => {
    dispatch(setPaidOrderSuccess(updatedOrder));
  });
};

const exportRequest = () => ({
  type: constants.EXPORT_REQUEST,
});

const exportSuccess = () => ({
  type: constants.EXPORT_SUCCESS,
  modalToShow: 'export-success-feedback',
});

module.exports.export = (from, to) => (dispatch) => {
  dispatch(exportRequest());

  $.when(OrderModel.exportList(from, to)).done(() => {
    dispatch(closeModal());
    dispatch(exportSuccess());
  });
};

module.exports.uploadFileSuccess = (item, file) => ({
  type: constants.UPLOAD_FILE_SUCCESS,
  item,
  file,
});

module.exports.deleteFileSuccess = (item, file) => ({
  type: constants.DELETE_FILE_SUCCESS,
  item,
  file,
});

module.exports.showCannotUploadFileModal = (data) => (dispatch) => {
  dispatch(showModal('upload-error', data));
};

module.exports.showCannotDownloadFileModal = (data) => (dispatch) => {
  dispatch(showModal('download-error', data));
};

module.exports.resetOrderServerError = () => ({
  type: constants.RESET_ORDER_SERVER_ERROR,
});

module.exports.showModal = showModal;
module.exports.closeModal = closeModal;
module.exports.getProjectAreas = getProjectAreas;
module.exports.updateOrderError = updateOrderError;
