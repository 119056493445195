const React = require('react');
const FormValidator = require('../../../../../../../../common/react/FormValidator/FormValidator.react');
const RequestManager = require('../../../../containers/RequestManager');
const ModalContent = require('../../../../containers/Calculator/Task/SelectIntercompanySupplierModal/SelectIntercompanySupplierModalContent');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');

const SelectIntercompanySupplierModal = ({ closeModal, task }) => (
  <Modal title="Select supplier company" onClose={closeModal}>
    <FormValidator>
      <RequestManager>
        <ModalContent
          task={task}
          closeModal={closeModal} />
      </RequestManager>
    </FormValidator>
  </Modal>
);

module.exports = SelectIntercompanySupplierModal;
