const React = require('react');
const PropTypes = require('prop-types');
const RoundedSelect = require('../../inputs/Select/RoundedSelect/RoundedSelect.react');
const Option = require('./SegmentSelectItem.react');
const Creator = require('./SegmentSelectItemCreator.react');

require('./style.scss');

const SegmentSelect = ({
  value, availableOptions, onChange, onEditSegmentClick, onDeleteSegmentClick, onCreateClick,
}) => {
  const defaultOptions = [{
    id: 'all',
    name: 'All',
    filters: '',
  }];

  const isDefaultOption = (id) => defaultOptions.filter((option) => option.id === id).length > 0;

  const augmentedOptions = defaultOptions.concat(availableOptions);

  const getSegmentData = (id) => {
    const found = augmentedOptions.filter((option) => option.id === id);

    return found.length ? found[0] : null;
  };

  function handleChange(e, segment) {
    onChange(getSegmentData(segment.value));
  }

  function handleEditSegmentClick(id) {
    onEditSegmentClick(getSegmentData(id));
  }

  function handleDeleteSegmentClick(id) {
    onDeleteSegmentClick(getSegmentData(id));
  }

  const options = augmentedOptions.map((option) => {
    const isDefault = isDefaultOption(option.id);

    return (
      <Option key={option.id}
        onEditClick={isDefault ? null : handleEditSegmentClick}
        onDeleteClick={isDefault ? null : handleDeleteSegmentClick}
        value={option.id}
        label={option.name}>
        {option.name}
      </Option>
    );
  });

  return (
    <RoundedSelect name="segment-select"
      value={value}
      placeholder="Select segment"
      onChange={handleChange}>
      {options}
      <Creator value={value} onCreateClick={onCreateClick} />
    </RoundedSelect>
  );
};

SegmentSelect.defaultProps = {
  value: '',
  availableOptions: [],
  onChange: null,
  onEditSegmentClick: null,
  onDeleteSegmentClick: null,
  onCreateClick: null,
};

SegmentSelect.propTypes = {
  /** Current value. * */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })),
  /**
   * Function to call when a new value is selected.
   * @param option {{}} selected segment props
   */
  onChange: PropTypes.func,
  /**
   * Call when "edit" button of a segment is clicked
   * @param option {{}} selected segment props
   */
  onEditSegmentClick: PropTypes.func,
  /**
   * Call when "delete" button of a segment is clicked
   * @param option {{}} selected segment props
   */
  onDeleteSegmentClick: PropTypes.func,
  /**
   * Call when "create" button is clicked
   * @param e {SyntheticEvent}
   */
  onCreateClick: PropTypes.func,
};

module.exports = SegmentSelect;
