const { connect } = require('react-redux');
const Component = require('../../components/modal/LinkedProjectsModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  billingGroup: state.plan && state.plan.billing_group ? state.plan.billing_group : null,
  masterProject: state.project,
});

const mapDispatchToProps = (dispatch) => ({
  onRevoke: (project, masterProject, billingGroup) => dispatch(actions
    .showMasterRevokeDelegationModal(project, masterProject, billingGroup)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
