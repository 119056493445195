const React = require('react');
const Row = require('./Row.react');

module.exports = class Rows extends React.Component {
  constructor(props) {
    super(props);

    this.resizeEvent = null;
  }

  onDateResizeStart(e, handlerEl, resizableEl, resizableData, direction) {
    if (direction !== 'move') {
      this.rowsEl.classList.add('project-canvas-gantt__rows--resizing');
    }

    this.resizeEvent = {
      component: {
        data: resizableData,
        el: resizableEl,
      },
      handlerEl,
      direction,
      startX: e.pageX,
      endX: null,
    };
  }

  onMouseMove(e) {
    if (this.resizeEvent) {
      this.resizeEvent.endX = e.pageX;
      const delta = this.resizeEvent.endX - this.resizeEvent.startX;
      const normalizedDelta = Math.round(delta / this.props.pointWidth) * this.props.pointWidth;
      let { width } = this.resizeEvent.component.data;
      let { left } = this.resizeEvent.component.data;
      const { direction } = this.resizeEvent;

      if (direction === 'right') {
        width += normalizedDelta;
      } else if (direction === 'left') {
        left += normalizedDelta;
        width -= normalizedDelta;
      } else if (direction === 'move') {
        left += normalizedDelta;
      }

      if (width >= this.props.pointWidth) {
        this.resizeEvent.component.el.style.left = `${left}px`;
        this.resizeEvent.component.el.style.width = `${width}px`;
      }
    }
  }

  onMouseUp() {
    if (this.resizeEvent) {
      const itemData = this.resizeEvent.component.data;
      const resizableEl = this.resizeEvent.component.el;
      let item = null;
      const deltaLeftInPoints = (resizableEl.offsetLeft - itemData.left) / this.props.pointWidth;

      if (itemData.value.type) { // is milestone
        const date = moment(itemData.value.date).add(deltaLeftInPoints, this.props.pointType);
        item = {
          item: itemData.item,
          attribute: itemData.attribute,
          value: {
            ...itemData.value,
            date: date.format('YYYY-MM-DD'),
          },
        };
      } else {
        const widthInPoints = resizableEl.offsetWidth / this.props.pointWidth - 1;
        const startDate = moment(itemData.value.start_date)
          .add(deltaLeftInPoints, this.props.pointType);
        item = {
          item: itemData.item,
          attribute: itemData.attribute,
          value: {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: startDate.clone().add(widthInPoints, this.props.pointType).format('YYYY-MM-DD'),
          },
        };
      }

      this.props.editBar(item.item, item.attribute, item.value);
      if (this.resizeEvent.direction !== 'move') {
        this.rowsEl.classList.remove('project-canvas-gantt__rows--resizing');
      }
      this.resizeEvent = null;
    }
  }

  render() {
    return (
      <div className="project-canvas-gantt__rows"
        ref={(el) => this.rowsEl = el}
        onMouseMove={this.onMouseMove.bind(this)}
        onMouseUp={this.onMouseUp.bind(this)}
        onMouseLeave={this.onMouseUp.bind(this)}>
        <div className="project-canvas-gantt__item  project-canvas-gantt__row">&nbsp;</div>
        {this.props.rows.map((row) => (
          <Row key={row.id}
            {...row}
            onDateResizeStart={this.onDateResizeStart.bind(this)}
            color={row.color} />
        ))}
      </div>
    );
  }
};
