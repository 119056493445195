/* eslint-disable no-shadow */
const { connect } = require('react-redux');
const Component = require('../../components/Trends/TrendChargeability/TrendChargeability.react');
const actions = require('../../actions');

const getData = (weeks, filter) => {
  if (filter === 'month') {
    return weeks.filter((week, index, weeks) => index > weeks.length - 13);
  }
  return weeks;
};

const mapStateToProps = (state) => ({
  employee: state.id,
  title: 'Chargeability',
  subtitle: 'Percentage of time spent on chargeable projects',
  data: getData(state.chargeabilityTrend.chargeability, state.trendsFilter),
  levelAverage: state.chargeabilityTrend.level_average,
  levelLabel: state.chargeabilityTrend.level_label,
});

const mapDispatchToProps = (dispatch) => ({
  getTrend: (employeeId) => dispatch(actions.getChargeabilityTrend(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
