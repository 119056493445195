/* eslint-disable camelcase,react/require-default-props,react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const { formatDate } = require('../../../../../services/FormatService');

const Review = ({
  id, created_at, project,
}) => {
  const getMonth = () => moment(created_at).format('MMM');

  const getInfo = () => `Pending review of ${formatDate(created_at)}`;

  const getUnescapedContent = (content) => _.unescape(content);

  return (
    <div className="widget-reviews__item">
      <div className="widget-reviews__item--sliding">
        <div className="widget-reviews__item-review">
          <div className="widget-reviews__item-project">
            <div className="widget-reviews__item-project-name">{getUnescapedContent(project.name)}</div>
            <div className="widget-reviews__item-project-date">{getInfo()}</div>
          </div>
          <div className="widget-reviews__item-month">{getMonth()}</div>
        </div>
        <div className="widget-reviews__item-actions">
          <a className="wethod-button"
            href={`/#project/${project.id}/review/${id}`}>
            Start Review
          </a>
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  id: PropTypes.number,

  /**
   * Project related: contains at least name and id
   */
  project: PropTypes.object.isRequired,

  /**
   * Date of creation of the review request.
   */
  created_at: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]).isRequired,
};

module.exports = Review;
