const React = require('react');
const NotesModal = require('../containers/modal/NotesModal');
const DelegateModal = require('../containers/modal/DelegateInvoiceModal');
const LinkedProjectsModal = require('../containers/modal/LinkedProjectsModal');
const RevokeDelegationModal = require('../containers/modal/RevokeDelegationModal');
const RevokeDelegationFromMasterModal = require('../containers/modal/RevokeDelegationFromMasterModal');
const ErrorModal = require('./modal/ErrorModal.react');

const Modal = ({ modalToShow, closeModal, modalData }) => {
  const getModal = () => {
    switch (modalToShow) {
      case 'notes':
        return <NotesModal onClose={closeModal} data={modalData} />;
      case 'delegate':
        return <DelegateModal onClose={closeModal} data={modalData} />;
      case 'linked-projects':
        return <LinkedProjectsModal onClose={closeModal} data={modalData} />;
      case 'revoke':
        return <RevokeDelegationModal onClose={closeModal} data={modalData} />;
      case 'revoke-from-master':
        return <RevokeDelegationFromMasterModal onClose={closeModal} data={modalData} />;
      case 'error':
        return <ErrorModal onClose={closeModal} data={modalData} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
