/* eslint-disable react/no-did-update-set-state,react/sort-comp */
const React = require('react');
const NumberFormat = require('react-number-format/dist/react-number-format');
const PropTypes = require('prop-types');

/**
 * An enhanced percentage input with:
 *
 * VALIDATION
 * Pass a comma separated string of "constraints" that will be checked at any blur. Pass an
 * "onValidation" callback to get validation errors. Supported constraints: integer, required.
 *
 *
 * EDIT VALUE FROM PARENT
 * Just pass a new "value" to reset the input. This let you use this component by embedding it into
 * another.
 *
 *
 * @type {module.NumericInput}
 */
class PercentageInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      constraints: this.props.constraints ? this.props.constraints.split(',') : [],
      isValid: false,
      placeholder: this.props.placeholder,
    };

    this.errors = [];
    this.state.isValid = this.isValid();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
        isValid: this.isValid(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.setState({ isValid: this.isValid() });
    }
  }

  required() {
    return this.state.value.trim() !== '';
  }

  integer() {
    return Number.isInteger(parseFloat(this.state.value));
  }

  isValid() {
    this.errors = this.validate();
    if (this.props.onValidate) {
      this.props.onValidate(this.props.name, this.errors);
    }
    return this.errors.length === 0;
  }

  validate() {
    const errors = [];
    for (let i = 0; i < this.state.constraints.length; i++) {
      if (!this[this.state.constraints[i]]()) {
        errors.push(this.state.constraints[i]);
      }
    }
    return errors;
  }

  afterChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, this.state.value);
    }
  }

  handleChange(e) {
    let value = parseFloat(e.floatValue);
    value = Number.isNaN(value) ? null : value;
    if (this.state.value !== value) {
      this.setState({ value }, this.afterChange.bind(this));
    }
  }

  needsPlaceholder() {
    return this.state.value === '';
  }

  handleBlur() {
    if (this.needsPlaceholder()) {
      this.setState({ placeholder: this.props.placeholder });
    }
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value);
    }
  }

  handleFocus() {
    this.setState({ placeholder: '' });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  isRequired() {
    return this.state.constraints.indexOf('required') !== -1;
  }

  getClassName() {
    let name = 'wethod-input';
    if (this.isRequired()) {
      name += ' wethod-input--required';
    }
    if (this.props.disabled) {
      name += ' disabled';
    }
    return name;
  }

  getInput() {
    if (this.props.disabled) {
      const value = `${this.state.value || '0'} %`;
      return (
        <span>{value}</span>
      );
    }
    return (
      <NumberFormat placeholder={this.state.placeholder}
        onValueChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={1}
        value={this.state.value}
        suffix=" %" />

    );
  }

  render() {
    return (
      <span className={this.getClassName()}>
        {this.getInput()}
      </span>
    );
  }
}

PercentageInput.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  constraints: PropTypes.string,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

PercentageInput.defaultProps = {
  value: null,
  placeholder: '',
  constraints: null,
  onValidate: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  disabled: false,
};

module.exports = PercentageInput;
