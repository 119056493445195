const { connect } = require('react-redux');
const Component = require('../../components/job-title/Table.react');

const mapStateToProps = (state) => ({
  items: state.jobTitleSettings.jobTitles,
  levels: state.levels,
  isWaiting: state.waitingFor.some((key) => key.includes('get-job-titles')),
});

module.exports = connect(mapStateToProps, null)(Component);
