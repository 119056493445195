const { connect } = require('react-redux');
const Component = require('../../components/Brief/Brief.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  brief: state.brief,
  projectId: state.info.id,
  canEdit: state.canEdit,
  isWaiting: state.waitingFor.filter((key) => key === 'brief').length > 0,
  isEmpty: state.brief.summary === '',
});

const mapDispatchToProps = (dispatch) => ({
  getBrief: (projectId) => dispatch(actions.getBrief(projectId)),
  showModal: () => dispatch(actions.showBriefModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
