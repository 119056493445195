/* eslint-disable react/prefer-stateless-function,react/prop-types */
const React = require('react');

module.exports = class EmptyList extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty project-canvas__goal-empty">
        <div className="project-canvas-empty__image">
          {this.props.image}
        </div>
        <div className="project-canvas-empty__text">
          <div className="project-canvas-empty__text-action wethod-widget__empty-message-link">
            <div className="project-canvas-empty__files" />
          </div>
          <div className="project-canvas-empty__text-title">{this.props.title}</div>
          <div className="project-canvas-empty__text-description">{this.props.description}</div>
        </div>
      </div>
    );
  }
};
