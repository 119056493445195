const React = require('react');
const PropTypes = require('prop-types');

const TreeNode = ({
  component,
  children,
}) => {
  const getChildren = () => children.map((node, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TreeNode key={index} component={node.component}>{node.children}</TreeNode>));

  return (
    <div className="wethod-tree__node">
      {component}
      {getChildren()}
    </div>
  );
};

// This is a way to express recursive PropTypes, see https://medium.com/@spencerwhitehead7/defining-recursive-proptypes-6f5586d0bd35
const nodeShape = {
  component: PropTypes.element.isRequired,
};

nodeShape.children = PropTypes.arrayOf(PropTypes.shape(nodeShape)).isRequired;

TreeNode.propTypes = { ...nodeShape };

module.exports = TreeNode;
