const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../Button/RoundedButton.react');

const MultipleSelectFilterButton = ({
  label,
  onClick,
  inputRef,
  selectedValues,
  acceptedValues,
}) => (
  <Button onClick={onClick}
    inputRef={inputRef}
    className="wethod-multiple-select-filter__main-button">
    <span className="wethod-multiple-select-filter__main-button-label">{label}</span>
    <span className="wethod-multiple-select-filter__main-button-counter">{selectedValues.length} of {acceptedValues.length}</span>
  </Button>
);

MultipleSelectFilterButton.defaultProps = {
  onClick: null,
  selectedValues: [],
};

MultipleSelectFilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  /**
   * Function to call when user clicks button.
   * @param event {HtmlEvent}
   */
  onClick: PropTypes.func,
  /**
   * Expose button ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func.isRequired,
  /**
   * List of currently selected values.
   */
  selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  /**
   * List of the "real" values.
   */
  acceptedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
};

module.exports = MultipleSelectFilterButton;
