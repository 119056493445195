// eslint-disable-next-line no-unused-vars
const TaskNode = require('./TaskNode');
const Node = require('./StructureNode');
const Total = require('../NodeTotal/AreaNodeTotal');
// eslint-disable-next-line no-unused-vars
const AvailableLevel = require('../AvailableLevel');
const LevelAmountTotal = require('../NodeTotal/LevelAmountNodeTotal');
const LevelAmountPrevious = require('../NodePrevious/LevelAmountNodePrevious');
const Previous = require('../NodePrevious/AreaNodePrevious');

module.exports = class Area extends Node {
  /**
   * @param area
   * @param {TaskNode[]} tasks
   * @param {AvailableLevel[]} availableLevels
   */
  constructor(area, tasks, availableLevels) {
    super();
    this.area = area;
    this.tasks = tasks;
    this.availableLevels = availableLevels;
    this.total = this.calculateTotal();
    this.previous = this.calculatePrevious();
  }

  /**
   * @return {boolean}
   */
  isEnabled() {
    return this.area.enabled;
  }

  getId() {
    return this.area.id;
  }

  /**
   * @returns {TaskNode[]}
   */
  getTasks() {
    return this.tasks;
  }

  /**
   * @private
   * @returns {number}
   */
  getDays() {
    return this.getTasks().reduce((sum, task) => sum + task.getTotal().getDays(), 0);
  }

  /**
   * @private
   * @returns {number}
   */
  getCost() {
    return this.getTasks().reduce((sum, task) => sum + task.getTotal().getCost(), 0);
  }

  /**
   * @private
   * @returns {number}
   */
  getPrice() {
    return this.getTasks().reduce((sum, task) => sum + task.getTotal().getPrice(), 0);
  }

  /**
   * @private
   * @returns {number}
   */
  getExternalCost() {
    return this.getTasks().reduce((sum, task) => sum + task.getExternalCost(), 0);
  }

  getAvailableLevels() {
    return this.availableLevels;
  }

  getDaysForLevel(levelId) {
    const days = this.getTotal().levels
      .filter((level) => level.getLevelId() === levelId);
    return days.length ? days[0].getDays() : 0;
  }

  getDaysForPreviousLevel(levelId) {
    const days = this.getPrevious().getLevels()
      .filter((level) => level.getLevelId() === levelId);
    return days.length ? days[0].getDays() : 0;
  }

  /**
   * Return area previous version external cost. Return null if any.
   * @return {null|number}
   */
  getPreviousExternalCost() {
    return this.getTasks()
      .reduce((sum, task) => {
        // Null previous external cost means last cost was equal to the current one
        const previousExternalCost = task.getPreviousExternalCost() !== null
          ? task.getPreviousExternalCost() : task.getExternalCost();
        return sum + previousExternalCost;
      }, 0);
  }

  getPreviousOn() {
    const { was } = this.area;
    return was ? was.enabled : null;
  }

  getDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const daysForLevel = this.getTasks()
        .reduce((sum, task) => sum + task.getDaysForLevel(level.getId()), 0);

      daysByLevel.push(new LevelAmountTotal(daysForLevel, 0, 0, level.getId()));
    }
    return daysByLevel;
  }

  /**
   * @return {LevelAmountPrevious[]}
   */
  getPreviousDaysByLevel() {
    const daysByLevel = [];
    const availableLevels = this.getAvailableLevels();
    for (let l = 0; l < availableLevels.length; l++) {
      const level = availableLevels[l];
      const daysForLevel = this.getTasks()
        .reduce((sum, task) => sum + task.getPreviousDaysForLevel(level.getId()), 0);

      daysByLevel.push(new LevelAmountPrevious(daysForLevel, level.getId()));
    }
    return daysByLevel;
  }

  getPreviousPriceList() {
    const { was } = this.area;
    return was ? was.price_list : null;
  }

  /**
   * @private
   * @returns {AreaNodeTotal}
   */
  calculateTotal() {
    return new Total(this.getDays(), this.getCost(), this.getPrice(), this.getExternalCost(),
      this.getDaysByLevel());
  }

  /**
   * @private
   * @return {AreaNodePrevious}
   */
  calculatePrevious() {
    return new Previous(this.getPreviousExternalCost(), this.getPreviousOn(),
      this.getPreviousDaysByLevel(), this.getPreviousPriceList());
  }

  /**
   * @returns {AreaNodeTotal}
   */
  getTotal() {
    return this.total;
  }

  toJson() {
    return {
      ...this.area,
      tasks: this.getTasks().map((task) => task.toJson()),
      total: this.getTotal().toJson(),
      was: this.getPrevious().toJson(),
    };
  }

  /**
   * @return {Previous}
   */
  getPrevious() {
    return this.previous;
  }
};
