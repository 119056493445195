/* eslint-disable react/prop-types */
const React = require('react');
const Item = require('./HeaderItem.react');
const ItemUser = require('./HeaderItemUser.react');
const ItemDesk = require('./HeaderItemDesk.react');

module.exports = ({ module, onClick, isCurrent, isClicked }) => {
  switch (module.getLabel()) {
    case 'Desk':
      return (
        <ItemDesk module={module}
          isClicked={isClicked}
          isCurrent={isCurrent}
          onClick={onClick} />
      );
    case 'User':
      return (
        <ItemUser module={module}
          isClicked={isClicked}
          isCurrent={isCurrent}
          onClick={onClick} />
      );
    default:
      return (
        <Item module={module}
          isClicked={isClicked}
          isCurrent={isCurrent}
          onClick={onClick} />
      );
  }
};
