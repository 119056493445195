const React = require('react');
const Form = require('../../containers/EventCreator/EventForm');
const AddButton = require('./CreateButton.react');

module.exports = class EventCreator extends React.Component {
  getContent() {
    if (this.props.showForm) {
      return <Form inputs={['type-select', 'datetime-picker', 'description']} />;
    }
    return <AddButton onClick={this.showForm.bind(this)} />;
  }

  showForm() {
    this.props.openForm();
  }

  render() {
    return (
      <div className="timeline-event-creator">
        {this.getContent()}
      </div>
    );
  }
};
