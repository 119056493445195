const React = require('react');
const Delta = require('../../../containers/Calculator/CellDelta');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

const AreaSwitchDelta = ({ actualValue, previousValue = null }) => {
  const turnedOn = previousValue === false && actualValue === true;

  const turnedOff = previousValue === true && actualValue === false;
  const label = turnedOn ? 'on' : 'off';
  return (
    <ShowIf condition={turnedOff || turnedOn} showElse={null}>
      <Delta className="wethod-budget-calculator__cell-delta--area-switch">
        Switched {label}
      </Delta>
    </ShowIf>
  );
};

module.exports = AreaSwitchDelta;
