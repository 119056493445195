const constants = require('./constants');
const KnowledgeModel = require('./models/KnowledgeModel');

Wethod.module('ReactBind', () => {
  const getKnowledgeRequest = () => ({
    type: constants.GET_KNOWLEDGE_REQUEST,
  });

  const getKnowledgeSuccess = (data) => ({
    type: constants.GET_KNOWLEDGE_SUCCESS,
    languages: data.languages,
    skills: data.skills,
    interests: data.interests,
    employees: data.employees,
  });

  module.exports.getKnowledge = () => (dispatch) => {
    dispatch(getKnowledgeRequest());
    const model = new KnowledgeModel();
    $.when(model.getModel())
      .done((data) => {
        dispatch(getKnowledgeSuccess(data));
      });
  };

  module.exports.changeLanguage = (languageName) => ({
    type: constants.CHANGE_LANGUAGE,
    language: languageName,
  });

  module.exports.toggleSkill = (skill) => ({
    type: constants.TOGGLE_SKILL,
    skill,
  });

  module.exports.filterSkillEmployees = (keyword) => ({
    type: constants.FILTER_SKILL_EMPLOYEES,
    keyword,
  });

  module.exports.filterSkills = (keyword) => ({
    type: constants.FILTER_SKILLS,
    keyword,
  });

  module.exports.loadLanguageEmployees = (size, page) => ({
    type: constants.LOAD_LANG_EMPLOYEES,
    size,
    page,
  });

  module.exports.loadSkillEmployees = (size, page) => ({
    type: constants.LOAD_SKILL_EMPLOYEES,
    size,
    page,
  });

  module.exports.toggleInterest = (interest) => ({
    type: constants.TOGGLE_INTEREST,
    interest,
  });

  module.exports.filterInterests = (keyword) => ({
    type: constants.FILTER_INTERESTS,
    keyword,
  });

  module.exports.filterInterestEmployees = (keyword) => ({
    type: constants.FILTER_INTEREST_EMPLOYEES,
    keyword,
  });

  module.exports.loadInterestEmployees = (size, page) => ({
    type: constants.LOAD_INTEREST_EMPLOYEES,
    size,
    page,
  });
});
