const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');
const BudgetTemplateModel = require('./BudgetTemplateModel');

module.exports = Backbone.Collection.extend({
  model: BudgetTemplateModel,
  load: HTTPService.load,
  parse: ParserService.parse,
  url() {
    return `${APIURL}/budgettemplate/`;
  },
  getTemplates() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
});
