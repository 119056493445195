const { connect } = require('react-redux');
const Event = require('../../components/EventsPast/Event.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  openModal: (event) => dispatch(actions.showDeleteEventModal(event)),
  openEditForm: (event) => dispatch(actions.openEditEventForm(event)),
  closeForm: () => dispatch(actions.closeEditEventForm()),
  save: (event) => dispatch(actions.updateEvent(event)),
});

module.exports = connect(null, mapDispatchToProps)(Event);
