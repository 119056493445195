'use strict';

Wethod.module('SettingsApp.Account', function (Account, Wethod, Backbone, Marionette, $) {
  this.UserInfoModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/authentication/userinfo',
  });

  var API = {
    getUserInfo: function () {
      var record = new Account.UserInfoModel();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    setUserInfo: function (data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/account/updateperson',
        xhrFields: {
          withCredentials: true,
        },
        data: data,
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    setUserPassword: function (data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/updatepassword',
        xhrFields: {
          withCredentials: true,
        },
        data: data,
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    deleteCompanyAccount: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/deleteaccount',
        xhrFields: {
          withCredentials: true,
        },
        data: options,
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:settings:userInfo', function () {
    return API.getUserInfo();
  });

  Wethod.reqres.setHandler('set:settings:userInfo', function (data) {
    return API.setUserInfo(data);
  });

  Wethod.reqres.setHandler('set:settings:userPassword', function (data) {
    return API.setUserPassword(data);
  });
  // DELETE COMPANY
  Wethod.reqres.setHandler('delete:company:account', function (options) {
    return API.deleteCompanyAccount(options);
  });
});
