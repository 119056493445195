/* eslint-disable no-param-reassign,react/no-array-index-key,react/forbid-prop-types,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const Item = require('./ListItem.react');

const List = ({ list, terminator, children }) => {
  terminator = terminator || '.';

  const items = children.map((child, index) => {
    const isLast = index === list.length - 1;
    const endsWith = isLast ? terminator : ', ';

    return <Item key={index} terminator={endsWith}>{child}</Item>;
  });

  return <span>{items}</span>;
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  terminator: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

module.exports = List;
