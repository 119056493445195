const { connect } = require('react-redux');
const Basket = require('../components/Basket.react');
const actions = require('../actions');

const mapDispatchToProps = (dispatch) => ({
  getOpportunities: () => dispatch(actions.filterOpportunities()),
  getCompanies: () => dispatch(actions.getCompanies()),
  getEmployees: () => dispatch(actions.getEmployees()),
});

module.exports = connect(null, mapDispatchToProps)(Basket);
