'use strict';

/* eslint-disable default-case,array-callback-return,camelcase */

Wethod.module('ReportApp.BubblesInvoice', function (BubblesInvoice, Wethod, Backbone, Marionette, $, _, Header) {
  var cacheLayout;
  var moduleLayout;
  var maxYAxis = {
    notArchived: 100,
    showArchivedAndNot: 100,
    onlyArchived: 100,
  };
  var chartSeriesTemplate;
  var archiveFilter = 'notArchived';
  var notArchiveChart = [];
  var archiveChart = [];
  var allDataChart = [];

  var showProjectInfo = function (info, color) {
    var show = false;
    // controllo di avere i permessi per vedere il dettaglio
    // se il progetto è mio ne vedo sempre il dettaglio
    if (info.pm.id === Wethod.userInfo.get('employee_id') || Wethod.ReportApp.getPermission('view_details')) {
      show = true;
    }

    info.showDetail = show;
    var infoItemView = new BubblesInvoice.InfoTemplateView({
      projectInfo: info,
      color: color,
    });
    // var infoButtonsContainer = new BubblesInvoice.InfoTemplateButtons({
    //   showDetail: show,
    // });
    if ($(window).width() <= 600) {
      moduleLayout.mobileInfo.show(infoItemView);
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      moduleLayout.info.show(infoItemView);
      // moduleLayout.info.show(infoButtonsContainer);
    }
  };

  var createBubbleChartSeries = function (data, filter) {
    var series = [];

    _.each(data, function (info) {
      var serie = {};
      if (!_.isUndefined(info.budget) && !_.isEmpty(info.budget) && !_.isNull(info.budget.id)) {
        if (parseInt(info.actual_invoice) > maxYAxis[filter]) {
          maxYAxis[filter] = parseInt(info.actual_invoice);
        }
        serie.name = info.project.name + ' <br> ' + info.client.name;
        if (info.progress > info.actual_invoice) {
          serie.color = '#d03557';
        } else if (info.progress === info.actual_invoice) {
          serie.color = '#e0e0e0';
        } else {
          serie.color = '#4ED88D';
        }
        serie.info = info;
        serie.data = [{
          x: parseFloat(info.progress),
          y: parseFloat(info.actual_invoice),
          z: parseFloat(info.budget.suggested_net_price || 0),
          id: info.project.id,
          name: info.project.name + ' - ' + info.client.name,
          dataLabels: {
            crop: false,
            enabled: true,
            color: 'black',
            formatter: function () {
              var subClientName = (!_.isNull(info.client.acronym)
                && !_.isUndefined(info.client.acronym)) ? info.client.acronym : info.client.name;
              var subJobOrder = (!_.isNull(info.project.job_order)
                && !_.isUndefined(info.project.job_order))
                ? info.project.job_order : info.project.name;

              subClientName = subClientName.substring(0, 3);
              subJobOrder = subJobOrder.substring(subJobOrder.length - 3, subJobOrder.length);

              var label = '';
              if (subClientName.charAt(subClientName.length) === '_' || subJobOrder.charAt(0) === '_') {
                label = subClientName + subJobOrder;
              } else {
                label = subClientName + '_' + subJobOrder;
              }

              return label.toLowerCase();
            },
          },
          events: {
            click: function () {
              showProjectInfo(this.series.options.info, this.series.options.color);
            },
          },
        }];

        series.push(serie);
      }
    });

    var divider = {
      name: '',
      enableMouseTracking: false,
      lineWidth: 1,
      type: 'line',
      marker: { enabled: false },
      color: '#B8B6B4',
      data: [
        [0, 0],
        [120, 120],
      ],
    };
    series.push(divider);
    return series;
  };

  this.reloadReport = function () {
    var reportReloadRequest = Wethod.request('bubbleInvoice:cache:clear');
    $.when(reportReloadRequest).done(function () {
      BubblesInvoice.initBubblesInvoice(cacheLayout);
    });
  };

  this.switchArchiveFilter = function (filter) {
    archiveFilter = filter;
    BubblesInvoice.chartFilter(null);
  };

  /**
   * Apply an 'or' filter of 'filters' on key
   *
   * @param string filters
   * @param string key
   */
  var applyFilter = function (filters, key) {
    var visible = false;
    filters.split(' ').map(function (filter) {
      visible = visible || key.includes(filter);
    });

    return visible;
  };
  this.chartFilter = function (fieldFilters) {
    fieldFilters = fieldFilters || null;

    var series;
    var client;
    var project;
    var pmName;
    var accountName;
    var jobOrder;
    var
      projectType;

    // filtro archiviazione
    switch (archiveFilter) {
      case 'notArchived':
        series = notArchiveChart; // JSON.parse( JSON.stringify( notArchiveChart ) );
        break;
      case 'showArchivedAndNot':
        series = allDataChart; // JSON.parse( JSON.stringify( allDataChart ) );
        break;
      case 'onlyArchived':
        series = archiveChart; // JSON.parse( JSON.stringify( archiveChart ) );
        break;
    }

    _.each(series, function (serie) {
      if (!serie.info) {
        return; // series can contains some non-bubble data
      }

      client = serie.info.client.name.toLowerCase();
      project = _.unescape(serie.info.project.name.toLowerCase());
      pmName = serie.info.pm.name.toLowerCase() + ' ' + serie.info.pm.surname.toLowerCase();
      accountName = serie.info.account ? serie.info.account.name.toLowerCase() + ' ' + serie.info.account.surname.toLowerCase() : '';
      jobOrder = serie.info.project.job_order ? serie.info.project.job_order.toLowerCase() : null;
      projectType = serie.info.project_type.name.toLowerCase();

      var visible = true;
      visible = visible && applyFilter(fieldFilters.clients, client);
      visible = visible && applyFilter(fieldFilters.projects, project);
      visible = visible && applyFilter(fieldFilters.pms, pmName);
      visible = visible && applyFilter(fieldFilters.accounts, accountName);
      visible = visible && applyFilter(fieldFilters.jobOrders, jobOrder);
      visible = visible && applyFilter(fieldFilters.projectTypes, projectType);

      serie.visible = visible;
    });

    chartSeriesTemplate.ui.reportChart
      .highcharts(BubblesInvoice.chartBubblesInvoice(series, maxYAxis[archiveFilter]));
  };

  this.initBubblesInvoice = function (reportLayout) {
    cacheLayout = reportLayout;

    var reportRequest = Wethod.request('get:report:bubbleInvoice');
    $.when(reportRequest).done(function (reportResponse) {
      if (_.isUndefined(reportResponse)) {
        return;
      }

      // renderizzo il layout del modulo report
      reportLayout.render();

      moduleLayout = new BubblesInvoice.BubblesInvoiceLayout();
      cacheLayout.reportBody.show(moduleLayout);

      var cached_on = reportResponse.cached_on;

      // separo in tre array i dati relativi ai progetti
      _.each(reportResponse.data, function (bubble) {
        if (!bubble.project.archived) {
          // proj non archiviati
          notArchiveChart.push(bubble);
        } else {
          // proj archiviati
          archiveChart.push(bubble);
        }
        // tutti i proj
        allDataChart.push(bubble);
      });

      notArchiveChart = createBubbleChartSeries(notArchiveChart, 'notArchived');
      archiveChart = createBubbleChartSeries(archiveChart, 'onlyArchived');
      allDataChart = createBubbleChartSeries(allDataChart, 'showArchivedAndNot');

      var bubbleInvoiceChartTemplate = new BubblesInvoice.BubbleInvoiceChartTemplateView({
        series: notArchiveChart,
        maxYAxis: maxYAxis.notArchived,
      });

      chartSeriesTemplate = bubbleInvoiceChartTemplate;

      var chartFilterTemplate = new BubblesInvoice.BubblesInvoiceFilterTemplateView();

      var cachedNote = new BubblesInvoice.CachedNoteView({ cached_on: cached_on });
      var sectionHeaderModel = new Header.SectionHeaderModel({
        current_section: 'Invoiced vs Status',
        preview_anchor_id: 'revenue-progress',
        helper_url: 'reports/index/#revenue-progress',
      });
      var revenueProgressHeaderRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });

      moduleLayout.headerSection.show(revenueProgressHeaderRegion);
      moduleLayout.chart.show(bubbleInvoiceChartTemplate);
      moduleLayout.filters.show(chartFilterTemplate);
      moduleLayout.cachedNote.show(cachedNote);
    });
  };
}, Wethod.module('HeaderApp.Header'));
