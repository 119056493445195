const $ = require('jquery');
const request = require('./Request');
const fileRequest = require('./FileRequest');

const url = `${APIURL}/file-storage`;

const OrderFile = {
  upload(file, itemId) {
    return fileRequest({
      method: 'post',
      url: `/file-storage/order/${itemId}`,
      file,
    });
  },
  download(fileId) {
    const defer = $.Deferred();

    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: `${url}/${fileId}/order/url`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code === 200) {
        // Download file simulating a click on a href
        window.location.href = response.data.download_url;
        defer.resolve(response.data);
      } else {
        defer.reject(response);
      }
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  delete(fileId) {
    return request({
      method: 'delete',
      url: `/file-storage/${fileId}/order`,
    });
  },
};

module.exports = OrderFile;
