/* eslint-disable no-shadow */
const React = require('react');
const View = require('./StatusSummaryView.react');

module.exports = ({ canEdit, attribute, values, ...rest }) => {
  const getRatio = (count, length) => (count ? (count / length) * 100 : 0);

  const getSummary = () => {
    const summary = [...attribute.available_values].map((attribute) => ({
      ...attribute,
      count: 0,
    }));
    const summaryMap = {}; // key-value pairs representing an available value id and index in summary

    for (let i = 0; i < summary.length; i++) {
      summaryMap[summary[i].id] = i;
    }

    for (let i = 0; i < values.length; i++) {
      const status = values[i].value;
      summary[summaryMap[status.id]].count += 1;
    }

    return summary.map((value) => ({
      ...value,
      ratio: getRatio(value.count, values.length),
    }));
  };

  return <View value={getSummary()} {...rest} />;
};
