/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events */
const React = require('react');
const Block = require('./PlanEditorBlock.react');

const PlanEditorHourly = ({
  days, selectedProject, selectedBlocks, onMouseOverBlock, editorMode, isHighlighted, updateMode,
  onBlockClick, onMouseOut,
}) => {
  const day = days[0];
  /**
   * Return an array of the given size and fulfilled with the given placeholder.
   * @param size
   * @returns {[]}
   */
  const getArray = (size) => {
    const array = [];
    for (let i = 0; i < size; i++) {
      array[i] = undefined;
    }
    return array;
  };

  /**
   * Return the amount of hours of the given projectId in the given day.
   * @param projectId
   * @param day
   * @returns {number}
   */
  const getProjectHours = (projectId, day) => {
    const plan = day.plans.filter((plan) => plan.project_id === projectId)[0];
    return plan ? plan.amount : 0;
  };

  /**
   * Return a list of changes for projectId in the current day.
   * A change is an object like {day:'2020-06-20', amount:6}, change.amount is the new hours' amount for the selectedProject in the given day.
   * @returns {[]}
   * @param id
   * @param projectId
   */
  const onMouseMoveOnBlock = (id, projectId) => {
    const mode = editorMode;
    const projectHoursAmount = getProjectHours(selectedProject.id, day);
    const selectedBlockAmount = selectedBlocks.length + 1;
    const change = { day: day.date };

    if (mode === 'add' && !projectId) {
      change.amount = Math.min(8, projectHoursAmount + selectedBlockAmount); // cannot add more than 8 hours
      onMouseOverBlock(id, [change]);
    } else if (mode === 'remove' && projectId) {
      change.amount = Math.max(0, projectHoursAmount - selectedBlockAmount); // cannot have less than 0 hours
      onMouseOverBlock(id, [change]);
    }
  };

  const onMouseDownOnBlock = (projectId) => {
    if (projectId) {
      updateMode('remove');
    } else {
      updateMode('add');
    }
  };

  /**
   * Return a list of blocks belonging to this editor. Each block may have a project's hour already planned.
   * @returns {*[]}
   */
  const getBlocks = () => {
    const blocks = getArray(8);
    const { plans } = day;
    const projectHours = [];
    for (let i = 0; i < plans.length; i++) {
      const plan = plans[i];
      for (let j = 0; j < plan.amount; j++) {
        projectHours.push(plan.project_id);
      }
    }
    projectHours.map((hour, index) => blocks[index] = hour); // merge projectHours into hours

    return blocks;
  };

  const onClick = (projectId) => {
    const projectHoursAmount = getProjectHours(selectedProject.id, day);
    const change = { day: day.date };

    if (!projectId) {
      change.amount = Math.min(8, projectHoursAmount + 1); // cannot add more than 8 hours
      onBlockClick([change], 'add');
    } else if (projectId === selectedProject.id) {
      change.amount = Math.max(0, projectHoursAmount - 1); // cannot have less than 0 hours
      onBlockClick([change], 'remove');
    }
  };

  const getBlocksFragment = () => getBlocks().map((projectId, index) => (
    <Block
      key={index}
      onMouseMove={() => onMouseMoveOnBlock(index, projectId)}
      onClick={() => onClick(projectId)}
      onMouseDown={() => onMouseDownOnBlock(projectId)}
      highlighted={isHighlighted(index)} />
  ));

  return (
    <div className="planning-calendar__editor" onMouseOut={onMouseOut}>
      <div className="planning-calendar__row-day">
        {getBlocksFragment()}
      </div>
    </div>
  );
};

module.exports = PlanEditorHourly;
