'use strict';

require('../../../scss/main.scss');

Wethod.module('Utility', function (Utility, Wethod, Backbone, Marionette, $, _) {
  /**
   * Transforms the given object into an array by pushing each attribute as an array's element.
   *
   * @param object
   * @param exceptions object's attributes' names to exclude from transformation
   * @returns {Array}
   */
  this.toArray = function (object, exceptions) {
    var array = [];
    for (var attribute in object) {
      if (exceptions !== undefined) {
        if (exceptions.indexOf(attribute) === -1) {
          array.push(object[attribute]);
        }
      } else {
        array.push(object[attribute]);
      }
    }
    return array;
  };
  /**
   * Is char allowed?
   * @param  {int} charCode
   */
  this.allowChar = function (charCode) {
    if (
      (charCode >= 46 && charCode <= 90)
      || (charCode >= 96 && charCode <= 111)
      || charCode === 8
      || charCode === 9
    ) {
      return true;
    }
    return false;
  };

  this.requestMessage = function (response, surce, error) {
    error = error || false;
    var result = false;

    if (!error) {
      switch (response.code) {
        case 200:
        case 201:
          result = true;
          break;
        case 404:
          break;
        default:
          // console.log('%c' + 'ERROR (' + surce + ') : ' + response.message, 'color:red');
          // console.log('%c' + 'STATUS : ' + response.status, 'color:#E6772E;');
          // console.log('%c' + 'CODE   : ' + response.code, 'color:#E6772E;');
          break;
      }
    } else {
      // console.log('%c' + 'ERROR (' + surce + ') ', 'color:#E64A45');
      // console.log('%c' + 'look at NETWORK -> PREVIEW', 'color:#E6772E;');
    }

    return result;
  };

  /**
   * Get a formatted display name from Name and Surname
   * @param  {[type]} name    [description]
   * @param  {[type]} surname [description]
   * @return {[type]}         [description]
   */
  this.displayName = function (name, surname) {
    name = name || '';
    surname = surname || '';
    var surnameEdit = surname.charAt(0).toUpperCase() + surname.slice(1);
    var displayName = name.substring(0, 1).toUpperCase() + ' ' + surnameEdit;
    return displayName;
  };

  this.nameFirsthChar = function (name, surname) {
    if (name && surname) {
      return name.charAt(0) + surname.charAt(0);
    }
    return '';
  };

  this.getUserInitials = function () {
    var name = Wethod.userInfo.get('name');
    var surname = Wethod.userInfo.get('surname');

    if (name && surname) {
      return name.charAt(0) + surname.charAt(0);
    }
    return '...';
  };

  this.displayDate = function (date, towards) {
    towards = towards || 'normal';
    var dataArray = date.split('-');
    return (towards === 'normal') ? dataArray[1] + '.' + dataArray[0].slice(2) : dataArray[1] + '.' + dataArray[2].slice(2);
  };

  this.hexToRgb = function (h) {
    var cutHex = function (hex) {
      return (hex.charAt(0) === '#') ? hex.substring(1, 7) : h;
    };

    var r = parseInt((cutHex(h)).substring(0, 2), 16);
    var g = parseInt((cutHex(h)).substring(2, 4), 16);
    var b = parseInt((cutHex(h)).substring(4, 6), 16);

    return 'rgb(' + r + ',' + g + ',' + b + ')';
  };

  this.randomSentence = function (sentencesDays, sentencesSong) {
    sentencesDays = sentencesDays || undefined;
    sentencesSong = sentencesSong || undefined;

    if (!_.isUndefined(sentencesDays) && !_.isUndefined(sentencesSong)) {
      var variousSentences = _.union(sentencesDays.data, sentencesSong.data);
      var todaySentences = [];

      _.each(variousSentences, function (val) {
        todaySentences.push(val.text);
      });

      return todaySentences[Math.floor(Math.random() * variousSentences.length)];
    }
    return '';
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  /**
   * Call a function with a delay, if recalled in within the delay reset timer
   */
  this.withDelay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  }());
});
