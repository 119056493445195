'use strict';

Wethod.module('ReportApp.TimesheetRoadrunner', function (TimesheetRoadrunner, Wethod, Backbone, Marionette, $) {
  this.RoadrunnerModel = Wethod.Utility.BaseModel.extend({});

  this.RoadrunnerColl = Wethod.Utility.BaseCollection.extend({
    _year: null,
    _range: null,
    model: TimesheetRoadrunner.RoadrunnerModel,
    url: function () {
      return APIURL + '/project/roadrunner?year=' + this._year + '&range=' + this._range;
    },
    initialize: function (options) {
      this._year = options.params.year;
      this._range = options.params.range;
    },
  });

  this.TimesheetModel = Wethod.Utility.BaseModel.extend({
    /**
     * Sort types by their "sort" attribute.
     * @param response
     * @return {*}
     */
    parse: function (response) {
      response.types = response.types.sort(function (typeA, typeB) {
        return typeA.sort - typeB.sort;
      });
      return response;
    },
  });

  this.TimesheetColl = Wethod.Utility.BaseCollection.extend({
    _year: null,
    _range: null,
    model: TimesheetRoadrunner.TimesheetModel,
    url: function () {
      return APIURL + '/timetrackingboard/by-type?year=' + this._year + '&range=' + this._range;
    },
    initialize: function (options) {
      this._year = options.params.year;
      this._range = options.params.range;
    },
  });

  var API = {
    getRoadrunner: function (params) {
      var record = new TimesheetRoadrunner.RoadrunnerColl({ params: params });
      var defer = $.Deferred();
      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getTimesheetByType: function (params) {
      var record = new TimesheetRoadrunner.TimesheetColl({ params: params });
      var defer = $.Deferred();
      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:project:roadrunner', function (params) {
    return API.getRoadrunner(params);
  });

  Wethod.reqres.setHandler('get:timesheet:by-type', function (params) {
    return API.getTimesheetByType(params);
  });
});
