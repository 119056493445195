const Module = require('./Module');
const DeskModule = require('./DeskModule');

/**
 *
 * @param module
 * @param userPermissions
 * @return {Module|*}
 * @constructor
 */
const ModuleFactory = (module, userPermissions) => {
  switch (module.url) {
    case '/#desk': {
      return new DeskModule(module, userPermissions);
    }
    default: {
      return new Module(module, userPermissions);
    }
  }
};

module.exports = ModuleFactory;
