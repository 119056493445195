const React = require('react');
const PropTypes = require('prop-types');
const InputDecorator = require('../InputDecorator.react');

require('./style.scss');

const RoundedInputDecorator = ({ children, inputRef, disabled, readOnly }) => (
  <InputDecorator className="wethod-input--rounded"
    inputRef={inputRef}
    disabled={disabled}
    readOnly={readOnly}>
    {children}
  </InputDecorator>
);

RoundedInputDecorator.defaultProps = {
  disabled: false,
  readOnly: false,
  inputRef: undefined,
};

RoundedInputDecorator.propTypes = {
  /** Element to decorate * */
  children: PropTypes.node.isRequired,
  /**
   * Expose input child wrapper ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

module.exports = RoundedInputDecorator;
