const { connect } = require('react-redux');
const AlertsListComponent = require('../components/AlertsList.react');

const byName = (alertA, alertB) => {
  if (alertA.label < alertB.label) {
    return -1;
  }
  if (alertA.label > alertB.label) {
    return 1;
  } // If 2 alerts have the same name, compare ids
  if (alertA.id > alertB.id) {
    return -1;
  }
  if (alertA.id < alertB.id) {
    return 1;
  }
  return 0;
};

const mapStateToProps = (state) => ({ alerts: state.filteredAlerts.sort(byName) });

module.exports = connect(mapStateToProps)(AlertsListComponent);
