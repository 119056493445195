const templateHtml = require('../templates/SentenceLink.html');

/**
 * Sentence showed in the loading. with link to spotify playlist
 */
const SentenceView = Marionette.ItemView.extend({
  template: _.template(templateHtml),
  ui: {
    sentence: '[data-region="sentence"]',
  },
  onRender() {
    if (!_.isUndefined(this.options.sentence)) {
      this.ui.sentence.html(this.options.sentence);
    }
  },
  onBeforeDestroy() {
    this.$el.fadeOut();
  },
});

module.exports = SentenceView;
