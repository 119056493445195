const { connect } = require('react-redux');
const Component = require('../../components/WorkingConnections/WorkingConnections.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  employee: state.id,
  data: state.workingConnectionTrend,
  placeholder: 'No data to display. This report will be available once you compile at least one timesheet',
});

const mapDispatchToProps = (dispatch) => ({
  getTrend: (employeeId) => dispatch(actions.getWorkingConnectionTrend(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
