const React = require('react');
const RequestManager = require('../../../../containers/RequestManager');
const Content = require('./DeleteJobTitleModalContent.react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');

const DeleteTaskModal = ({ closeModal, jobTitleId, taskId, dayId }) => (
  <Modal title="Delete job title" onClose={closeModal}>
    <RequestManager>
      <Content dayId={dayId} taskId={taskId} jobTitleId={jobTitleId} closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = DeleteTaskModal;
