const { connect } = require('react-redux');
const Component = require('../components/TravelList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isLoading: state.waitingFor.filter((key) => key === 'filter-travels' || key === 'load-travels').length > 0,
  travels: state.travels,
  hasMore: !state.lastPage,
  keyword: state.keyword,
  deletedElements: state.deletedElements,
  createdElements: state.createdElements,
});

const mapDispatchToProps = (dispatch) => ({
  loadMore: (page, size, keyword, deletedElements) => dispatch(actions
    .loadMoreTravels(page, size, keyword, deletedElements)),
  filter: (keyword, size) => dispatch(actions.filterTravels(keyword, size)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
