const { connect } = require('react-redux');
const Component = require('../../../components/sections/Sales/Title.react');

const mapStateToProps = (state) => ({
  won_projects_value: state.won_projects_value,
  prev_week_won_projects_value: state.prev_week_won_projects_value,
  weekly_target: state.weekly_target,
});

module.exports = connect(mapStateToProps)(Component);
