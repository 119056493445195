const React = require('react');
const moment = require('moment');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../../../common/react/Table2/TableRow.react');
const IconButtonTooltip = require('../../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('../../../../../../../../common/react/ShowIf/ShowIf.react');
const NumericInput = require('../../../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const Checkbox = require('../../../../../../../../common/react/inputs/Checkbox/Checkbox.react');
const PropertyMetadataService = require('../../../../../../../../services/PropertyMetadataService/PropertyMetadataService');
const InputValidator = require('../../../../../../../../common/react/InputValidator/InputValidator.react');
const Tooltip = require('../../../../../../../../common/react/TooltipFixed/Tooltip.react');
const Typography = require('../../../../../../../../common/react/Typography/Typography.react');

const TransferHoursRow = ({
  timesheet,
  selectAll,
  onTimesheetSelect,
  updateHoursToTransfer,
  isEnabledDataFreezingFeature,
  updateErrors,
}) => {
  const hoursToTansfer = timesheet.hours_to_transfer != null
    ? timesheet.hours_to_transfer
    : timesheet.hours;
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  const formatNonEmpty = (value) => (value || '-');

  function handleTransferHoursChange(e) {
    updateHoursToTransfer(`${timesheet.id_timetracking}-${timesheet.date}`, e.target.value);
  }

  /**
   * Handle the selection of the timesheet to transfer
   */
  function handleTimesheetSelection(e) {
    const { checked } = e.target;
    onTimesheetSelect(`${timesheet.id_timetracking}-${timesheet.date}`, checked);
  }

  // Checking if timesheet hours can be edited
  const canEdit = () => {
    if (isEnabledDataFreezingFeature) {
      // eslint-disable-next-line no-underscore-dangle
      const propertyMetadataService = new PropertyMetadataService(timesheet.timetracking._fields);
      return propertyMetadataService.getCanEdit('hours');
    }
    return true;
  };

  // A timesheet is checked if it is selected or if the select all checkbox is checked
  const isSelected = () => (timesheet.selected || selectAll) && canEdit();

  const getTableRow = () => (
    <TableRow>
      <TableCell className="timesheets-transfer-hours__column-checkbox">
        <Checkbox name="enable-transfer"
          id={`enable-transfer-${timesheet.id_timetracking}-${timesheet.date}`}
          value={`${timesheet.id_timetracking}-${timesheet.date}`}
          checked={isSelected()}
          label=""
          onChange={handleTimesheetSelection}
          disabled={!canEdit()} />
      </TableCell>
      <TableCell className="timesheets-transfer-hours__column-date">
        {formatDate(timesheet.date)}
        <ShowIf condition={timesheet.mode.toLowerCase() === 'weekly'}>
          <IconButtonTooltip className="timesheets__date-tooltip"
            label="Week starting on this date">
            <span>i</span>
          </IconButtonTooltip>
        </ShowIf>
      </TableCell>
      <TableCell className="timesheets-transfer-hours__column-employee">{timesheet.employee_full_name}</TableCell>
      <TableCell className="timesheets-transfer-hours__column-job-order">{formatNonEmpty(timesheet.job_order)}</TableCell>
      <TableCell className="timesheets-transfer-hours__column-hours">{timesheet.hours}</TableCell>
      <TableCell className="timesheets-transfer-hours__column-transfer">
        <ShowIf condition={isSelected()}>
          <InputValidator
            updateErrors={updateErrors}
            constraints={['required', 'min:0', `max:${timesheet.hours}`]}>
            <NumericInput label="Hours to transfer"
              name="transfer-hours"
              value={hoursToTansfer}
              min={0}
              max={timesheet.hours}
              precision={2}
              onChange={handleTransferHoursChange} />
          </InputValidator>
        </ShowIf>
        <ShowIf condition={!canEdit()}>
          <Typography
            size={Typography.SIZES.PX11}
            color={Typography.COLORS.PLACEHOLDER_GRAY}
            weight={Typography.WEIGHTS.LIGHT}>
            Frozen Data
          </Typography>
        </ShowIf>
      </TableCell>
    </TableRow>
  );
  const getTooltipMessage = () => (
    <Typography size={Typography.SIZES.PX11}>
      <Typography
        weight={Typography.WEIGHTS.SEMIBOLD}
        size={Typography.SIZES.PX11}>
        Read only data
      </Typography>
      : this information has been frozen. You won't be able to edit it.
    </Typography>
  );

  return (
    <ShowIf condition={!canEdit()}
      showElse={getTableRow()}>
      <Tooltip label={getTooltipMessage()}>
        {getTableRow()}
      </Tooltip>
    </ShowIf>
  );
};

module.exports = TransferHoursRow;
