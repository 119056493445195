const React = require('react');
const $ = require('jquery');
const PropTypes = require('prop-types');
const Autocomplete = require('../Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const Model = require('../../../../models/Autocomplete');
const TokenModel = require('../../../../models/Token');
const SelectItem = require('../Select/SelectItem.react');
const MenuFooter = require('./MenuFooter.react');

require('./style.scss');

class PlaceAutocomplete extends React.Component {
  static getInputValue(item) {
    return item.value;
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
      sessionToken: null,
    };

    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    $.when(TokenModel.getPlaceToken(this.props.sectionName, this.props.name))
      .done((response) => this.setState({
        sessionToken: response.sessiontoken,
      }));
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    $.when(Model.getPlacePredictions({ input: keyword, session_token: this.state.sessionToken }))
      .done((predictions) => this.setState({
        availableItems: predictions,
        isLoading: false,
      }))
      .fail(() => this.setState({
        isLoading: false,
        availableItems: [],
      }));
  }

  onChange(e, item) {
    if (item) {
      this.props.onChange(e, item);
    }
  }

  getOptions() {
    return this.state.availableItems.map((prediction) => (
      <SelectItem key={prediction.id} value={prediction.id}>
        {prediction.hint}
      </SelectItem>
    ));
  }

  getValue() {
    return {
      id: this.props.value,
      value: this.props.value,
    };
  }

  render() {
    return (
      <Autocomplete
        {...this.props}
        className="wethod-place-autocomplete"
        menuFooter={<MenuFooter />}
        loading={this.state.isLoading}
        value={this.getValue()}
        onChange={this.props.onChange}
        getInputValue={PlaceAutocomplete.getInputValue}
        onFilter={this.onFilter}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
}

PlaceAutocomplete.defaultProps = {
  required: undefined,
  disabled: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  readOnly: undefined,
  onChange: null,
  onBlur: null,
  onFocus: null,
  value: null,
  className: '',
};

PlaceAutocomplete.propTypes = {
  /** Last selected value. * */
  value: PropTypes.string,
  /**
   * Function to call when a new value is selected.
   * @param event {HTMLEvent}
   * @param option {{}} selected option props
   */
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  /** Element to use as label * */
  label: PropTypes.node.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  /** Name of the wethod session containing this input */
  sectionName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

module.exports = PlaceAutocomplete;
