const React = require('react');
const PropTypes = require('prop-types');
const SelectButton = require('../SelectButton.react');
const Decorator = require('../../../InputDecorator/RoundedInputDecorator/RoundedInputDecorator.react');

const RoundedSelectButton = ({ inputRef, value, disabled, readOnly, ...rest }) => (
  <Decorator
    inputRef={inputRef}
    disabled={disabled}
    readOnly={readOnly}>
    <SelectButton disabled={disabled} readOnly={readOnly} value={value} {...rest}>
      {value}
      <span />
    </SelectButton>
  </Decorator>
);

RoundedSelectButton.defaultProps = {
  value: '',
  readOnly: undefined,
  disabled: undefined,
  inputRef: undefined,
};

RoundedSelectButton.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
};

module.exports = RoundedSelectButton;
