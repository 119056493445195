/* eslint-disable no-underscore-dangle,no-param-reassign,class-methods-use-this */
const Calendar = require('./Calendar');

module.exports = class DaysCalendar extends Calendar {
  /**
   * @param from {Moment}
   * @param to {Moment}
   */
  constructor(from, to) {
    super(from, to, 50, 50, 10, 'days', 'days');
  }

  _getPointsInPeriod(from, to) {
    let current = from;
    const days = [];
    while (current.isSameOrBefore(to)) {
      days.push(current);
      current = current.clone().add(1, 'days');
    }
    return days;
  }

  addFuture(amount) {
    amount = amount || 30;
    /**
     * @var {Moment}
     */
    const lastPoint = this._points[this._points.length - 1];
    const from = lastPoint.clone().add(1, 'days');
    const to = from.clone().add(amount, this._type);
    const future = this._getPointsInPeriod(from, to);
    this._points = this._points.concat(future);
    return this._points;
  }

  addPast(amount) {
    amount = amount || 30;
    /**
     * @var {Moment}
     */
    const firstPoint = this._points[0];
    const from = firstPoint.clone().subtract(amount, this._type);
    const to = firstPoint.clone().subtract(1, 'days');
    const past = this._getPointsInPeriod(from, to);
    this._points = past.concat(this._points);
    return this._points;
  }

  getDate(attribute) {
    const { value } = attribute;
    const widthInDays = moment(value.end_date).diff(moment(value.start_date), this._type) + 1;
    let leftInDays = this.getPast(moment(value.start_date)) + 1;
    leftInDays = leftInDays > 0 ? leftInDays : leftInDays - 1;

    return {
      ...attribute,
      width: widthInDays * this._pointWidth,
      left: leftInDays * this._pointWidth,
    };
  }

  getMilestone(attribute) {
    const { value } = attribute;
    const widthInDays = 1;
    const leftInDays = this.getPast(moment(value.date)) + 1;

    return {
      ...attribute,
      width: widthInDays * this._pointWidth,
      left: leftInDays * this._pointWidth,
    };
  }
};
