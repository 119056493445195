const constants = require('./constants');

class ExpensesReducer {
  constructor(state) {
    this.state = {
      expenseMetadata: [], // metadata related to expense attributes
      ...state,
      waitingFor: [], // contains a key for each pending request
      modalToShow: null,
      modalData: null,
      typeFilter: 'mine',
      pageLimit: 0,
      pageOffset: 0,
      hasMorePages: true,
      keyword: state.id ? `id:${state.id}` : '',
      expenses: [],
      focusedItemId: null,
      showSidebar: false,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.GET_EXPENSES_REQUEST: {
        return {
          ...state,
          pageLimit: action.limit,
          pageOffset: action.offset,
          hasMorePages: true,
          keyword: action.search,
          typeFilter: action.typeFilter,
          expenses: action.offset ? state.expenses : [],
          waitingFor: state.waitingFor.concat('get-expenses'),
        };
      }
      case constants.GET_EXPENSES_SUCCESS: {
        return {
          ...state,
          hasMorePages: action.expenses.length >= state.pageLimit,
          expenses: state.expenses.concat(action.expenses),
          waitingFor: state.waitingFor.filter((key) => key !== 'get-expenses'),
        };
      }
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          focusedItemId: action.itemId,
          showSidebar: true,
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          focusedItemId: null,
          showSidebar: false,
        };
      }
      case constants.DELETE_EXPENSES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-expense'),
        };
      }
      case constants.DELETE_EXPENSES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-expense'),
          expenses: state.expenses.filter((item) => item.id !== action.item.id),
        };
      }
      case constants.DELETE_EXPENSES_FAILURE: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-expense'),
        };
      }
      case constants.UPDATE_EXPENSES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-expense'),
        };
      }
      case constants.UPDATE_EXPENSES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-expense'),
          expenses: state.expenses.map((item) => {
            if (item.id === action.item.id) {
              return {
                ...item,
                ...action.item,
              };
            }
            return item;
          }),
        };
      }
      case constants.CREATE_EXPENSES_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-expense'),
        };
      }
      case constants.CREATE_EXPENSES_SUCCESS: {
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-expense'),
          expenses: [action.item].concat(state.expenses),
        };
      }
      case constants.EXPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('export'),
        };
      case constants.EXPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'export'),
        };
      case constants.DELETE_FILE_SUCCESS:
        return {
          ...state,
          expenses: state.expenses.map((item) => {
            if (item.id === action.item.id) {
              return {
                ...item,
                files: item.files.filter((file) => file.id !== action.file.id),
              };
            }
            return item;
          }),
        };
      case constants.UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          expenses: state.expenses.map((item) => {
            if (item.id === action.item.id) {
              return {
                ...item,
                files: item.files.concat(action.file),
              };
            }
            return item;
          }),
        };
      default:
        return state;
    }
  }
}

module.exports = ExpensesReducer;
