const React = require('react');
const InputValidator = require('../../../../../../../../../common/react/InputValidator/InputValidator.react');
const TextInput = require('../../../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const TextArea = require('../../../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const ModalContent = require('../../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../../common/react/Modal/ModalFooterAction.react');

const SaveTemplateModalContent = ({
  name, description, onChange, closeModal, updateErrors, errors,
  isValid, isPending, onSaveClick,
}) => {
  const isSaving = () => isPending('save-template');

  const feedback = isSaving() ? 'Saving...' : '';

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <TextInput name="name"
              disabled={isSaving()}
              value={name}
              id="budget-template-name"
              errorText={errors.name}
              label="Template name"
              onChange={onChange} />
          </InputValidator>
        </ModalBodyBlock>
        <ModalBodyBlock>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <TextArea name="description"
              disabled={isSaving()}
              value={description}
              id="budget-template-description"
              errorText={errors.description}
              label="Description"
              onChange={onChange} />
          </InputValidator>
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={feedback}>
        <ModalFooterAction onClick={closeModal} disabled={isSaving()}>
          Dismiss
        </ModalFooterAction>
        <ModalFooterAction onClick={onSaveClick} disabled={isSaving() || !isValid}>
          Save
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = SaveTemplateModalContent;
