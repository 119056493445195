const React = require('react');
const ModeSwitch = require('../containers/ModeSwitch');
const ViewSwitch = require('../containers/ViewSwitch');
const Total = require('./Total.react');
const format = require('../../../../../services/FormatService').formatDecimalNumber;

/**
 * Auto/manual switch
 * Compact/extended view
 * Totals: invoiced, to be invoiced, planned
 */
const Actions = ({
  planned = 0, invoiced = 0, value = 0,
  currencyCode, isWaiting, isSaving, changeMode, changeView,
}) => {
  function getDelta() {
    return planned - invoiced;
  }

  function getError() {
    let missing = value - planned;
    if (missing !== 0) {
      missing = format(missing, 2, false);
      const currency = currencyCode ? ` ${currencyCode}` : '';
      return `Unsaved changes / Missing ${missing}${currency}`;
    }
    return null;
  }

  function getFeedback() {
    return isSaving ? 'Saving...' : null;
  }

  function getSwitchClass() {
    return isWaiting ? 'disabled' : null;
  }

  function handleModeClick(key) {
    if (!isWaiting) changeMode(key);
  }

  function handleViewClick(key) {
    if (!isWaiting) changeView(key);
  }

  return (
    <div className="invoice-plan__actions">
      <div>
        <ModeSwitch className={getSwitchClass()} handleClick={handleModeClick} />
        <ViewSwitch className={getSwitchClass()} handleClick={handleViewClick} />
      </div>
      <div>
        <Total value={invoiced} label="Invoiced" currency={currencyCode} />
        <Total value={getDelta()} label="To be invoiced" currency={currencyCode} />
        <Total value={planned}
          secondValue={value}
          label="Planned"
          currency={currencyCode}
          feedback={getFeedback()}
          error={getError()} />
      </div>
    </div>
  );
};

module.exports = Actions;
