const React = require('react');

/**
 * Used to create a horizontal section inside an HeaderBox, containing less important info.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderBoxSecondaryInfo = ({ children }) => (
  <div className="wethod-budget-header-box__secondary-info">{children}</div>
);

module.exports = HeaderBoxSecondaryInfo;
