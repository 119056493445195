/* eslint-disable no-param-reassign,class-methods-use-this,no-throw-literal,consistent-return */
const TimeFilter = require('./TimeFilter');
const TimeBreakpoint = require('./TimeBreakpoint');
const DateService = require('../../../../../../../../../services/DateService');
const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

module.exports = class YearFilter extends TimeFilter {
  /**
   *
   * @param range the number of last years to consider
   * @param year the specific year to consider, if any
   */
  constructor(range, year) {
    year = year !== undefined ? year : null;
    super(range, year);

    this.validateRange(range);
    this.validateYear(year);
    this.fiscalYearService = new FiscalYearService();
    this.sortedMonths = this.generateArrayOfSortedMonths();
  }

  validateYear(year) {
    if (year !== null && !Number.isInteger(year)) {
      throw 'Year must be an integer or null';
    }
  }

  validateRange(range) {
    if (!Number.isInteger(range)) {
      throw 'Range must be an integer';
    }
  }

  /**
   * Returns the label used for this filter.
   *
   * @return {string}
   */
  getLabel() {
    return `${this.period} years`;
  }

  generateArrayOfSortedMonths() {
    const startingDate = this.fiscalYearService.getFiscalYearStartDate(this.currentYear);
    const sortedMonths = [];
    for (let i = 0; i < 12; i++) {
      sortedMonths.push(startingDate.clone().add(i, 'months').format('YYYY-MM-DD'));
    }
    return sortedMonths;
  }

  /**
   * @return TimeBreakpoint
   */
  getBreakpoints() {
    const breakpoints = [];
    for (let i = 0; i < this.period; i++) {
      const year = this.currentYear - i;
      const yearLabel = this.fiscalYearService.getYearDynamically(year);
      breakpoints.push(new TimeBreakpoint(yearLabel, year));
    }
    breakpoints.sort((a, b) => a.getValue() - b.getValue());
    return breakpoints;
  }

  setFrame(value) {
    const breakpoints = this.getBreakpoints().map((breakpoint) => breakpoint.getLabel());
    const breakpointsString = breakpoints.join();

    if (breakpointsString.indexOf(value) !== -1) {
      this.frame = parseInt(value);
    } else {
      throw `Invalid frame. Must be one of ${breakpointsString}`;
    }
  }

  /**
   * Return an array of months belonging to the filter's period's frame.
   *
   * @returns {Array} of months formatted as 2019-10-12
   */
  getMonthsInFrame() {
    if (this.frame === null) {
      return this.getMonthsInPeriod();
    }

    const start = this.fiscalYearService.getFiscalYearStartDate(this.frame);
    const end = moment(this.fiscalYearService.getFiscalYearEndDate(this.frame)).startOf('month');

    return this.getMonths(start, end);
  }

  getBreakpointIndexForDate(date) {
    const year = DateService.getYear(date);
    const shiftedYear = this.fiscalYearService.getShiftedYear(year);
    if (this.frame === null) {
      const breakpoints = this.getBreakpoints();

      for (let i = 0; i < breakpoints.length; i++) {
        if (shiftedYear === breakpoints[i].getValue()) {
          return i;
        }
      }
    } else {
      if (shiftedYear < this.frame) { // current year < selected frame's year
        return -1;
      }
      if (shiftedYear > this.frame) { // current year > selected frame's year
        return 3;
      } // is current year
      const month = DateService.getMonth(date);
      const sortedMonths = this.sortedMonths.map((sortedDate) => moment(sortedDate).month() + 1);

      const indexOfMonthInSortedMonths = sortedMonths.indexOf(month);

      switch (true) {
        case indexOfMonthInSortedMonths >= 9:
          return 3;
        case indexOfMonthInSortedMonths >= 6:
          return 2;
        case indexOfMonthInSortedMonths >= 3:
          return 1;
        default:
          return 0;
      }
    }
  }
};
