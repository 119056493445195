'use strict';

Wethod.module('FridayApp', function (FridayApp, Wethod, Backbone, Marionette, $, _) {
  var isPm = function (pmId) {
    return (_.isUndefined(pmId) || (parseInt(pmId) === Wethod.userInfo.get('employee_id')));
  };

  this.getPermission = function (label, pmId) {
    return FridayApp.authorization.get(label) && (isPm(pmId) || FridayApp.authorization.get(label + '_other'));
  };
});
