const React = require('react');
const PropTypes = require('prop-types');
const SelectItem = require('../../inputs/Select/SelectItemWithActions.react');

const SegmentSelectItemCreator = ({ onCreateClick }) => (
  <SelectItem label="Create segment" onClick={onCreateClick} className="wethod-segment-select__item wethod-segment-select__item--creator" />
);

SegmentSelectItemCreator.propTypes = {
  /**
   * Called on item clicked.
   * @param e {HTMLEvent}
   */
  onCreateClick: PropTypes.func.isRequired,
};

module.exports = SegmentSelectItemCreator;
