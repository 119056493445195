const request = require('./Request');

const Level = {
  list() {
    return request({
      method: 'get',
      url: '/levels/',
    });
  },
};

module.exports = Level;
