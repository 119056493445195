const React = require('react');
const PropTypes = require('prop-types');
const TableRow = require('./TableRow.react');

/**
 * A table row to use inside TableHead in order to display totals grouped by column.
 */
const TableSummaryRow = ({ children, variant, scrollBarWidth }) => (
  <TableRow summary scrollBarWidth={scrollBarWidth} variant={variant}>
    {children}
  </TableRow>
);

TableSummaryRow.defaultProps = {
  children: [],
  variant: 'header',
  scrollBarWidth: null,
};

TableSummaryRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /**
   * Passed to children.
   *
   * @see TableCell
   */
  variant: PropTypes.oneOf(['header']),
  scrollBarWidth: PropTypes.number,
};

module.exports = TableSummaryRow;
