const React = require('react');

class EventCreateButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.handleClick();
  }

  render() {
    if (this.props.creating) {
      return (
        <div className="event-create-button--adding disabled">adding...</div>
      );
    }
    return (
      <div className="event-create-button" onClick={this.handleClick}>add event</div>
    );
  }
}

module.exports = EventCreateButton;
