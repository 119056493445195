const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../FilterAccordion/FilterAccordion.react');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');

const RadioFilter = ({ name, value, onChange, expanded, label, className, availableOptions }) => {
  function handleChange(e) {
    onChange(name, e.target.value);
  }

  const getRadioList = () => availableOptions.map((option) => (
    <Radio key={option.value}
      label={option.label}
      value={option.value} />
  ));

  const formattedValue = value || '';

  return (
    <Accordion expanded={expanded} label={label} value={formattedValue} className={className}>
      <RadioGroup name={name} onChange={handleChange} value={formattedValue}>
        {getRadioList()}
      </RadioGroup>
    </Accordion>
  );
};

RadioFilter.defaultProps = {
  expanded: false,
  label: '',
  availableOptions: [],
  className: '',
  value: '',
  onChange: null,
};

RadioFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  className: PropTypes.string,
};

module.exports = RadioFilter;
