/* eslint-disable react/prop-types */
const React = require('react');

module.exports = class Section extends React.Component {
  getTarget() {
    return this.props.blocked ? '_blank' : '_self';
  }

  render() {
    return (
      <li className="app-nav__section">
        <a href={this.props.section.getUrl()}
          onClick={this.props.onClick}
          target={this.getTarget()}>
          {this.props.section.getLabel()}
        </a>
      </li>
    );
  }
};
