const { connect } = require('react-redux');
const Component = require('../../components/Boards/DeleteModal/ConfirmBoardDeleteModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (projectId, boardId) => dispatch(actions.deleteBoard(projectId, boardId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
