const React = require('react');
const Text = require('./TextItemCell/TextItemCell.react');
const Numeric = require('./NumericItemCell/NumericItemCell.react');
const Status = require('./StatusItemCell/StatusItemCell.react');
const Member = require('./MemberItemCell/MemberItemCell.react');
const Date = require('./DateItemCell/DateItemCell.react');
const Milestone = require('./MilestoneItemCell/MilestoneItemCell.react');

module.exports = ({
  structure,
  deleteAvailableStatus, updateAvailableStatus, createAvailableStatus,
  deleteMilestone, addMember,
  deleteMember, isWaitingForMemberDeleting, isWaitingForCellSaving,
  ...rest
}) => {
  switch (structure.type) {
    case 'numeric':
      return <Numeric structure={structure} {...rest} />;
    case 'status':
      return (
        <Status structure={structure}
          deleteAvailableStatus={deleteAvailableStatus}
          updateAvailableStatus={updateAvailableStatus}
          createAvailableStatus={createAvailableStatus}
          {...rest} />
      );
    case 'member':
      return (
        <Member structure={structure}
          deleteMember={deleteMember}
          addMember={addMember}
          isWaitingForMemberDeleting={isWaitingForMemberDeleting}
          isWaitingForCellSaving={isWaitingForCellSaving}
          {...rest} />
      );
    case 'date':
      return <Date structure={structure} {...rest} />;
    case 'milestone':
      return (
        <Milestone structure={structure}
          deleteMilestone={deleteMilestone}
          {...rest} />
      );
    default:
      return <Text structure={structure} {...rest} />;
  }
};
