const constants = require('./constants');

module.exports.hideModal = () => ({
  type: constants.HIDE_MODAL,
});

module.exports.showContactInfoModal = () => ({
  type: constants.SHOW_MODAL_CONTACT_INFO,
});

module.exports.showBioModal = () => ({
  type: constants.SHOW_MODAL_BIO,
});

module.exports.showSkillsModal = () => ({
  type: constants.SHOW_MODAL_SKILLS,
});

module.exports.showLanguagesModal = () => ({
  type: constants.SHOW_MODAL_LANGUAGES,
});

module.exports.showInterestsModal = () => ({
  type: constants.SHOW_MODAL_INTERESTS,
});

module.exports.showLanguagesModal = () => ({
  type: constants.SHOW_MODAL_LANGUAGES,
});

module.exports.showSponsorsModal = () => ({
  type: constants.SHOW_MODAL_SPONSORS,
});

module.exports.showObjectiveModal = (objective) => ({
  type: constants.SHOW_MODAL_OBJECTIVE,
  objective,
});

module.exports.showYearResolutionModal = () => ({
  type: constants.SHOW_MODAL_RESOLUTION,
});

module.exports.showProfilePicModal = () => ({
  type: constants.SHOW_MODAL_PROFILE_PIC,
});

module.exports.showWorkingConnectionModal = (projects, title) => ({
  type: constants.SHOW_MODAL_WORKING_CONNECTION,
  projects,
  title,
});

module.exports.chooseQuartersFilter = (filter) => ({
  type: constants.CHOOSE_QUARTERS_FILTER,
  filter,
});

module.exports.chooseTrendsFilter = (filter) => ({
  type: constants.CHOOSE_TRENDS_FILTER,
  filter,
});

const editInfoSuccess = (info) => ({
  type: constants.EDIT_INFO_SUCCESS,
  info,
});

const editInfoRequest = () => ({
  type: constants.EDIT_INFO_REQUEST,
});

/**
 * @param info object containing key-value pairs of info to update.
 * @returns {Function}
 */
module.exports.editInfo = (info) => (dispatch) => {
  dispatch(editInfoRequest());

  Wethod.request('profile:person:update', info)
    .done(() => dispatch(editInfoSuccess(info)));
};

const addSkillSuccess = (skill) => ({
  type: constants.ADD_SKILL_SUCCESS,
  skill,
});

const addSkillRequest = (skill) => ({
  type: constants.ADD_SKILL_REQUEST,
  skill,
});

module.exports.addSkill = (skill, employeeId) => (dispatch) => {
  dispatch(addSkillRequest(skill));
  const options = {
    ...skill,
    id: employeeId,
  };
  Wethod.request('profile:skill:add', options)
    .done((response) => dispatch(addSkillSuccess({ ...response.attributes })));
};

const deleteSkillRequest = (skill) => ({
  type: constants.DELETE_SKILL_REQUEST,
  skill,
});

module.exports.deleteSkill = (skill, employeeId) => (dispatch) => {
  dispatch(deleteSkillRequest(skill));

  const options = {
    ...skill,
    id: employeeId,
    skillId: skill.id,
  };

  Wethod.request('profile:skill:delete', options);
};

const addInterestSuccess = (interest) => ({
  type: constants.ADD_INTEREST_SUCCESS,
  interest,
});

const addInterestRequest = (interest) => ({
  type: constants.ADD_INTEREST_REQUEST,
  interest,
});

module.exports.addInterest = (interest, employeeId) => (dispatch) => {
  dispatch(addInterestRequest(interest));
  const options = {
    ...interest,
    id: employeeId,
  };
  Wethod.request('profile:interest:add', options)
    .done((response) => dispatch(addInterestSuccess({ ...response.attributes })));
};

const deleteInterestRequest = (interest) => ({
  type: constants.DELETE_INTEREST_REQUEST,
  interest,
});

module.exports.deleteInterest = (interest, employeeId) => (dispatch) => {
  dispatch(deleteInterestRequest(interest));

  const options = {
    ...interest,
    id: employeeId,
    interestId: interest.id,
  };

  Wethod.request('profile:interest:delete', options);
};

const deleteLanguageSuccess = (language) => ({
  type: constants.DELETE_LANGUAGE_SUCCESS,
  language,
});

const deleteLanguageRequest = (language) => ({
  type: constants.DELETE_LANGUAGE_REQUEST,
  language,
});

module.exports.deleteLanguage = (language) => (dispatch) => {
  dispatch(deleteLanguageRequest(language));

  const options = {
    id: language.id,
  };

  Wethod.request('profile:language:delete', options)
    .done(() => dispatch(deleteLanguageSuccess(language)));
};

const addLanguageSuccess = (language) => ({
  type: constants.ADD_LANGUAGE_SUCCESS,
  language,
});

const addLanguageRequest = () => ({
  type: constants.ADD_LANGUAGE_REQUEST,
});

module.exports.addLanguage = (language) => (dispatch) => {
  dispatch(addLanguageRequest());

  const options = {
    ...language,
  };

  Wethod.request('profile:language:save', options)
    .done((response) => dispatch(addLanguageSuccess({
      ...language,
      id: response.attributes.id,
    })));
};

const updateWorkSuccess = (work) => ({
  type: constants.UPDATE_WORK_SUCCESS,
  work,
});

const updateWorkRequest = (work) => ({
  type: constants.UPDATE_WORK_REQUEST,
  work,
});

module.exports.updateWork = (work, employeeId) => (dispatch) => {
  dispatch(updateWorkRequest(work));

  const options = {
    ...work,
    employee: employeeId,
  };

  Wethod.request('profile:portfolio-work:save', options)
    .done(() => dispatch(updateWorkSuccess(work)));
};

module.exports.showPortfolioModal = () => ({
  type: constants.SHOW_MODAL_PORTFOLIO,
});

const deleteWorkSuccess = (work) => ({
  type: constants.DELETE_WORK_SUCCESS,
  work,
});

const deleteWorkRequest = (work) => ({
  type: constants.DELETE_WORK_REQUEST,
  work,
});

module.exports.deleteWork = (work) => (dispatch) => {
  dispatch(deleteWorkRequest(work));

  Wethod.request('profile:portfolio-work:delete', { id: work.id })
    .done(() => dispatch(deleteWorkSuccess(work)));
};

const addWorkSuccess = (work) => ({
  type: constants.ADD_WORK_SUCCESS,
  work,
});

const addWorkRequest = () => ({
  type: constants.ADD_WORK_REQUEST,
});

module.exports.addWork = (work, employeeId) => (dispatch) => {
  dispatch(addWorkRequest());

  const options = {
    ...work,
    employee: employeeId,
  };

  Wethod.request('profile:portfolio-work:save', options)
    .done((response) => dispatch(addWorkSuccess({
      ...work,
      id: response.attributes.id,
    })));
};

const deleteSponsorSuccess = (sponsor) => ({
  type: constants.DELETE_SPONSOR_SUCCESS,
  sponsor,
});

const deleteSponsorRequest = (sponsor) => ({
  type: constants.DELETE_SPONSOR_REQUEST,
  sponsor,
});

module.exports.deleteSponsor = (sponsor) => (dispatch) => {
  dispatch(deleteSponsorRequest(sponsor));

  Wethod.request('profile:sponsorship:delete', { id: sponsor.id })
    .done(() => dispatch(deleteSponsorSuccess(sponsor)));
};

const endSponsorSuccess = (sponsor) => ({
  type: constants.END_SPONSOR_SUCCESS,
  sponsor,
});

const endSponsorRequest = (sponsor) => ({
  type: constants.END_SPONSOR_REQUEST,
  sponsor,
});

module.exports.endSponsor = (sponsor, employeeId) => (dispatch) => {
  dispatch(endSponsorRequest(sponsor));

  const options = {
    ...sponsor,
    sponsored: employeeId,
  };

  Wethod.request('profile:sponsorship:save', options)
    .done(() => dispatch(endSponsorSuccess(sponsor)));
};

const addSponsorSuccess = (sponsor) => ({
  type: constants.ADD_SPONSOR_SUCCESS,
  sponsor,
});

const addSponsorRequest = (sponsor) => ({
  type: constants.ADD_SPONSOR_REQUEST,
  sponsor,
});

module.exports.addSponsor = (sponsor, employeeId) => (dispatch) => {
  dispatch(addSponsorRequest(sponsor));

  const options = {
    ...sponsor,
    sponsored: employeeId,
  };

  Wethod.request('profile:sponsorship:save', options)
    .done((response) => dispatch(addSponsorSuccess(response.attributes)));
};

const editKeyResultSuccess = (keyResult) => ({
  type: constants.EDIT_KEY_RESULT_SUCCESS,
  keyResult,
});

const editKeyResultRequest = (keyResult, quarterId, objectiveId) => ({
  type: constants.EDIT_KEY_RESULT_REQUEST,
  keyResult,
  quarterId,
  objectiveId,
});

/**
 * @param keyResult
 * @param quarterId
 * @param objectiveId
 * @param persist (bool) true if you want to persist the new value in DB
 * @returns {Function}
 */
module.exports.editKeyResult = (keyResult, quarterId, objectiveId, persist) => (dispatch) => {
  dispatch(editKeyResultRequest(keyResult, quarterId, objectiveId));

  const options = {
    ...keyResult,
    objective: objectiveId,
  };

  if (persist) {
    Wethod.request('profile:key-result:save', options)
      .done(() => dispatch(editKeyResultSuccess(keyResult)));
  } else {
    dispatch(editKeyResultSuccess(keyResult));
  }
};

const deleteKeyResultSuccess = (keyResult) => ({
  type: constants.DELETE_KEY_RESULT_SUCCESS,
  keyResult,
});

const deleteKeyResultRequest = (keyResult, quarterId, objectiveId) => ({
  type: constants.DELETE_KEY_RESULT_REQUEST,
  keyResult,
  quarterId,
  objectiveId,
});

module.exports.deleteKeyResult = (keyResult, quarterId, objectiveId) => (dispatch) => {
  dispatch(deleteKeyResultRequest(keyResult, quarterId, objectiveId));

  const options = {
    id: keyResult.id,
    objective: objectiveId,
  };

  Wethod.request('profile:key-result:delete', options)
    .done(() => dispatch(deleteKeyResultSuccess(keyResult)));
};

const addKeyResultSuccess = (keyResult, quarterId, objectiveId) => ({
  type: constants.ADD_KEY_RESULT_SUCCESS,
  keyResult,
  quarterId,
  objectiveId,
});

const addKeyResultRequest = (keyResult, quarterId, objectiveId) => ({
  type: constants.ADD_KEY_RESULT_REQUEST,
  keyResult,
  quarterId,
  objectiveId,
});

module.exports.addKeyResult = (keyResult, quarterId, objectiveId) => (dispatch) => {
  dispatch(addKeyResultRequest(keyResult, quarterId, objectiveId));

  const options = {
    ...keyResult,
    objective: objectiveId,
  };

  Wethod.request('profile:key-result:save', options)
    .done((response) => dispatch(addKeyResultSuccess({
      ...keyResult,
      id: response.get('id'),
    }, quarterId, objectiveId)));
};

const deleteObjectiveSuccess = (objective) => ({
  type: constants.DELETE_OBJECTIVE_SUCCESS,
  objective,
});

const deleteObjectiveRequest = (objective, quarterId) => ({
  type: constants.DELETE_OBJECTIVE_REQUEST,
  quarterId,
  objective,
});

module.exports.deleteObjective = (objective, quarterId) => (dispatch) => {
  dispatch(deleteObjectiveRequest(objective, quarterId));

  Wethod.request('profile:objective:delete', { id: objective.id })
    .done(() => dispatch(deleteObjectiveSuccess(objective)));
};

const editObjectiveSuccess = (objective, quarterId) => ({
  type: constants.EDIT_OBJECTIVE_SUCCESS,
  quarterId,
  objective,
});

const editObjectiveRequest = (objective, quarterId) => ({
  type: constants.EDIT_OBJECTIVE_REQUEST,
  quarterId,
  objective,
});

module.exports.editObjective = (objective, quarterId, employeeId) => (dispatch) => {
  dispatch(editObjectiveRequest(objective, quarterId));

  const options = {
    ...objective,
    quarter: quarterId,
    employee: employeeId,
  };

  Wethod.request('profile:objective:save', options)
    .done(() => dispatch(editObjectiveSuccess(objective, quarterId)));
};

const addObjectiveSuccess = (objective, quarterId) => ({
  type: constants.ADD_OBJECTIVE_SUCCESS,
  objective,
  quarterId,
});

const addObjectiveRequest = (objective, quarterId) => ({
  type: constants.ADD_OBJECTIVE_REQUEST,
  objective,
  quarterId,
});

module.exports.addObjective = (objective, quarterId, employeeId) => (dispatch) => {
  dispatch(addObjectiveRequest(objective, quarterId));

  const options = {
    ...objective,
    quarter: quarterId,
    employee: employeeId,
  };

  Wethod.request('profile:objective:save', options)
    .done((response) => dispatch(addObjectiveSuccess({
      ...objective,
      id: response.get('id'),
      key_results: [],
    }, quarterId)));
};

const editYearResolutionSuccess = (resolution) => ({
  type: constants.EDIT_RESOLUTION_SUCCESS,
  resolution,
});

const editYearResolutionRequest = () => ({
  type: constants.EDIT_RESOLUTION_REQUEST,
});

module.exports.editYearResolution = (resolution, year, employeeId) => (dispatch) => {
  dispatch(editYearResolutionRequest());

  const options = {
    content: resolution,
    id: employeeId,
    year,
  };

  Wethod.request('profile:year-resolution:save', options)
    .done(() => dispatch(editYearResolutionSuccess(resolution)));
};

const getObjectivesSuccess = (objectives) => ({
  type: constants.GET_OBJECTIVES_SUCCESS,
  objectives,
});

const getObjectivesRequest = () => ({
  type: constants.GET_OBJECTIVES_REQUEST,
});

module.exports.getObjectives = (year, employeeId) => (dispatch) => {
  dispatch(getObjectivesRequest());

  const options = {
    employeeId,
    year,
  };

  Wethod.request('profile:objectives:get', options)
    .done((response) => dispatch(getObjectivesSuccess(response.attributes)));
};

const updateProfilePicSuccess = (pic) => {
  dispatcher.trigger('header:profile-pic:update', pic);

  return {
    type: constants.UPDATE_PROFILE_PIC_SUCCESS,
    pic,
  };
};

const updateProfilePicRequest = () => ({
  type: constants.UPDATE_PROFILE_PIC_REQUEST,
});

module.exports.updateProfilePic = (pic, employeeId) => (dispatch) => {
  dispatch(updateProfilePicRequest());

  const options = {
    employeeId,
    pic,
  };

  Wethod.request('profile:pic:save', options)
    .done((response) => dispatch(updateProfilePicSuccess(response.pic)));
};

const deleteProfilePicSuccess = () => ({
  type: constants.DELETE_PROFILE_PIC_SUCCESS,
});

const deleteProfilePicRequest = () => {
  dispatcher.trigger('header:profile-pic:update', null);

  return {
    type: constants.DELETE_PROFILE_PIC_REQUEST,
  };
};

module.exports.deleteProfilePic = (employeeId) => (dispatch) => {
  dispatch(deleteProfilePicRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:pic:delete', options)
    .done(() => dispatch(deleteProfilePicSuccess()));
};

const getFridaynessTrendSuccess = (data) => ({
  type: constants.GET_FRIDAYNESS_TREND_SUCCESS,
  data,
});

const getFridaynessTrendRequest = () => ({
  type: constants.GET_FRIDAYNESS_TREND_REQUEST,
});

module.exports.getFridaynessTrend = (employeeId) => (dispatch) => {
  dispatch(getFridaynessTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-fridayness:get', options)
    .done((response) => dispatch(getFridaynessTrendSuccess(response.toJSON())));
};

const getDoTheBudgetTrendSuccess = (data) => ({
  type: constants.GET_DO_THE_BUDGET_TREND_SUCCESS,
  data,
});

const getDoTheBudgetTrendRequest = () => ({
  type: constants.GET_DO_THE_BUDGET_TREND_REQUEST,
});

module.exports.getDoTheBudgetTrend = (employeeId) => (dispatch) => {
  dispatch(getDoTheBudgetTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-do-the-budget:get', options)
    .done((response) => dispatch(getDoTheBudgetTrendSuccess(response.toJSON())));
};

const getDoThePlanTrendSuccess = (data) => ({
  type: constants.GET_DO_THE_PLAN_TREND_SUCCESS,
  data,
});

const getDoThePlanTrendRequest = () => ({
  type: constants.GET_DO_THE_PLAN_TREND_REQUEST,
});

module.exports.getDoThePlanTrend = (employeeId) => (dispatch) => {
  dispatch(getDoThePlanTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-do-the-plan:get', options)
    .done((response) => dispatch(getDoThePlanTrendSuccess(response.toJSON())));
};

const getKeepTheLightsOffTrendSuccess = (data) => ({
  type: constants.GET_KEEP_THE_LIGHTS_OFF_TREND_SUCCESS,
  data,
});

const getKeepTheLightsOffTrendRequest = () => ({
  type: constants.GET_KEEP_THE_LIGHTS_OFF_TREND_REQUEST,
});

module.exports.getKeepTheLightsOffTrend = (employeeId) => (dispatch) => {
  dispatch(getKeepTheLightsOffTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-keep-the-lights-off:get', options)
    .done((response) => dispatch(getKeepTheLightsOffTrendSuccess(response.toJSON())));
};

const getChargeabilityTrendSuccess = (data) => ({
  type: constants.GET_CHARGEABILITY_TREND_SUCCESS,
  data,
});

const getChargeabilityTrendRequest = () => ({
  type: constants.GET_CHARGEABILITY_TREND_REQUEST,
});

module.exports.getChargeabilityTrend = (employeeId) => (dispatch) => {
  dispatch(getChargeabilityTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-chargeability:get', options)
    .done((response) => dispatch(getChargeabilityTrendSuccess(response.attributes)));
};

const getWorkingConnectionTrendSuccess = (data) => ({
  type: constants.GET_WORKING_CONNECTION_TREND_SUCCESS,
  data,
});

const getWorkingConnectionTrendRequest = () => ({
  type: constants.GET_WORKING_CONNECTION_TREND_REQUEST,
});

module.exports.getWorkingConnectionTrend = (employeeId) => (dispatch) => {
  dispatch(getWorkingConnectionTrendRequest());

  const options = {
    employeeId,
  };

  Wethod.request('profile:trend-working-connections:get', options)
    .done((response) => dispatch(getWorkingConnectionTrendSuccess(response.toJSON())));
};

const updateQuarterStatusSuccess = () => ({
  type: constants.UPDATE_QUARTER_STATUS_SUCCESS,
});

const updateQuarterStatusRequest = (status, quarter, year) => ({
  type: constants.UPDATE_QUARTER_STATUS_REQUEST,
  status,
  quarter,
  year,
});

module.exports.updateQuarterStatus = (status, year, quarter, employeeId) => (dispatch) => {
  dispatch(updateQuarterStatusRequest(status, quarter, year));

  const options = {
    quarter_status: status,
    year: parseInt(year),
    quarter,
    employee_id: employeeId,
  };

  Wethod.request('profile:objectives:quarter:status:save', options)
    .done(() => dispatch(updateQuarterStatusSuccess()));
};

const getKudosSuccess = (data) => ({
  type: constants.GET_KUDOS_SUCCESS,
  data,
});

const getKudosRequest = () => ({
  type: constants.GET_KUDOS_REQUEST,
});

const getKudosFailure = (year) => ({
  type: constants.GET_KUDOS_FAILURE,
  year,
});

module.exports.getKudos = (employeeId, year) => (dispatch) => {
  dispatch(getKudosRequest());

  const options = {
    employeeId,
    year,
  };

  Wethod.request('profile:kudos:get', options)
    .done((response) => dispatch(getKudosSuccess({
      ...response.attributes,
      year,
    })))
    .fail(() => dispatch(getKudosFailure(year)));
};

const getGendersSuccess = (data) => ({
  type: constants.GET_GENDER_SUCCESS,
  data,
});

const getGendersRequest = () => ({
  type: constants.GET_GENDER_REQUEST,
});

module.exports.getGenders = () => (dispatch) => {
  dispatch(getGendersRequest());

  Wethod.request('profile:get:gender')
    .done((response) => {
      let data = response.toJSON();
      const keys = Object.keys(data);
      data = keys.map((key) => data[key]);
      dispatch(getGendersSuccess(data));
    });
};

const updateLanguageSuccess = (language) => ({
  type: constants.UPDATE_LANGUAGE_SUCCESS,
  language,
});

const updateLanguageRequest = (language) => ({
  type: constants.UPDATE_LANGUAGE_REQUEST,
  language,
});

module.exports.updateLanguage = (language) => (dispatch) => {
  dispatch(updateLanguageRequest(language));

  const options = {
    ...language,
  };

  Wethod.request('profile:language:update', options)
    .done(() => dispatch(updateLanguageSuccess(language)));
};
