/* eslint-disable react/prop-types,react/no-access-state-in-setstate,
 react/sort-comp,react/no-array-index-key,no-restricted-syntax,consistent-return,guard-for-in */
const React = require('react');
const TextInput = require('../TextInput.react');

/**
 * You can add this component to a SearchSelect to allow the creation of a new items when no
 * results are found. Props:
 * - name: used in intro sentence
 * - value: name of the new item you want to create
 * - fields: an array containing info for each input you want to insert in the creator.
 * - onCreateClick
 * Each field must have the following structure:
 * {
 *  name: "acronym"
 *  constraints:"required",
 *  messages:{
 *      required: "Acronym is required"
 *    }
 * }
 * @type {module.ItemCreator}
 */
module.exports = class ItemCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        name: this.props.value,
      },
      errors: {},
      isValid: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        item: {
          ...this.state.item,
          name: nextProps.value,
        },
      });
    }
  }

  getIntro() {
    return (
      <div className="wethod-item-creator__intro">
        Is
        {' '}
        <span className="wethod-item-creator__value">{this.props.value}</span>
        {' '}
        a new
        {' '}
        {this.props.name}
        ?
      </div>
    );
  }

  onValidate(name, inputErrors) {
    const { errors } = this.state;
    errors[name] = inputErrors;
    this.setState({ errors });
  }

  handleInputChange(name, value) {
    const item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  }

  getRequiredInputs() {
    return this.props.fields.map((field, index) => (
      <div key={index}
        className="wethod-item-creator__field">
        <label htmlFor={field.name}>{field.name}</label>
        <TextInput id={field.name}
          name={field.name}
          constraints={field.constraints}
          onValidate={this.onValidate.bind(this)}
          onChange={this.handleInputChange.bind(this)} />
      </div>
    ));
  }

  isValid() {
    const { errors } = this.state;
    for (const error in errors) {
      if (errors[error].length > 0) return false;
    }
    return true;
  }

  handleSaveClick() {
    this.setState({ isValid: this.isValid() }, () => {
      if (this.state.isValid) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ feedback: 'Creating' }, () => {
          this.props.onCreateClick(this.state.item);
        });
      }
    });
  }

  /**
   * Returns a field by name.
   * @param name
   * @returns {*}
   */
  getField(name) {
    for (let i = 0; i < this.props.fields.length; i++) {
      if (this.props.fields[i].name === name) return this.props.fields[i];
    }
  }

  getErrorMessages() {
    const messages = [];
    const fields = this.state.errors;
    for (const field in fields) { // The fields names
      for (let i = 0; i < fields[field].length; i++) {
        const error = fields[field][i]; // The error type
        const message = this.getField(field).messages[error];
        messages.push(message);
      }
    }
    return messages;
  }

  getFeedback() {
    if (this.state.isValid) {
      return '';
    }
    return this.getErrorMessages()[0];
  }

  getFeedbackClassName() {
    let name = 'wethod-item-creator__feedback';
    if (!this.state.isValid) {
      name += ' wethod-item-creator__feedback--error';
    }
    return name;
  }

  render() {
    return (
      <li className="wethod-search-select__item wethod-item-creator">
        {this.getIntro()}
        <div className="wethod-item-creator__form">
          {this.getRequiredInputs()}
        </div>
        <div className="wethod-item-creator__actions">
          <button type="button"
            className="clients__save-client-button wethod-button"
            onClick={this.handleSaveClick.bind(this)}>
            Create
          </button>
        </div>
        <div className={this.getFeedbackClassName()}>{this.getFeedback()}</div>
      </li>
    );
  }
};
