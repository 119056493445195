/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key */
const React = require('react');

const LevelAvailability = ({ name, days }) => {
  const getAmountClassName = () => {
    let name = 'planning-people__project-info-level-availability-amount';
    if (days > 0) {
      name += ' planning-people__project-info-level-availability-amount--not-zero';
    }
    return name;
  };

  const format = (days) => {
    let daysAmount = Math.abs(days);
    let hoursAmount = 0;
    if (days % 1 !== 0) { // is float
      const parts = String(daysAmount).split('.');
      daysAmount = parseInt(parts[0]);
      hoursAmount = Math.floor(parseFloat(`0.${parts[1]}`) / 0.125);
    }

    return `${days < 0 ? '-' : ''} ${daysAmount} days ${hoursAmount}h`;
  };

  return (
    <div className="planning-people__project-info-level-availability">
      <div className="planning-people__project-info-level-availability-name">{name}</div>
      <div className={getAmountClassName()}>{format(days)}</div>
    </div>
  );
};

module.exports = LevelAvailability;
