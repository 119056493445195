const { connect } = require('react-redux');
const actions = require('../actions');
const Component = require('../../../sidebar/company/CompanyEditableDetails.react');

const mapStateToProps = (state) => ({
  groups: state.groups,
  bankAccounts: state.bankAccounts,
  vatRates: state.vatRates,
  countries: state.countries,
  provinces: state.provinces,
  paymentTerms: state.paymentTerms,
});

const mapDispatchToProps = (dispatch) => ({
  onGroupSave: (group) => dispatch(actions.saveGroup(group)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
