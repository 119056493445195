/* eslint-disable react/no-array-index-key */
const React = require('react');
const tokenize = require('../../../utilities/tokenize');

module.exports = class LifecycleLog extends React.Component {
  constructor(props) {
    super(props);

    this.messages = {
      project_created: 'Project added to pipeline',
      project_started: 'Project started (first timesheet done)',
      project_ended: 'Project finished (according to project status)',
    };
  }

  getMessage() {
    return this.messages[this.props.type];
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.created_by;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{this.getHighlightedContent(creator)}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  getProjectLink() {
    return <a href={`#timeline/${this.props.project.id}`}>{this.getHighlightedContent(_.unescape(this.props.project.name))}</a>;
  }

  getClientLink() {
    return (
      <span>{this.getHighlightedContent(_.unescape(this.props.project.client.corporate_name))}
      </span>
    );
  }

  getHighlightedContent(string) {
    const keys = this.props.filter.toLowerCase();
    let pattern = keys;
    if (keys.match(' ')) pattern = `(${keys.replace(/\s/g, '|')})`;
    const regex = new RegExp(pattern, 'gi');
    if (keys === '' || !regex.test(string)) {
      return string;
    }
    return tokenize(regex, string).map((token, index) => <span key={index}>{token}</span>);
  }

  render() {
    return (
      <div className="timeline-event-log">
        <div className="timeline-event-past__type-logo-container">
          <div className="wethod-icon-pipeline wethod-icon-pipeline--black" />
        </div>
        <div className="timeline-event-log__type">
          <div className="timeline-event-log__type-name">{this.getMessage()}</div>
        </div>
        <div className="timeline-event-log__creation">
          {this.getProjectLink()}
          {' '}
          -
          {this.getClientLink()}
        </div>
        <div className="timeline-event-log__creation">
          {this.getCreation()}
        </div>
      </div>
    );
  }
};
