const React = require('react');
const AsPmChart = require('../../containers/Kudos/AsPmTrendWidget');
const AsTeamMemberChart = require('../../containers/Kudos/AsTeamMemberTrendWidget');
const Notes = require('../../containers/Kudos/Notes');
const Loading = require('../../../../../../../common/react/LoadingSmall.react');

module.exports = class Kudos extends React.Component {
  componentDidMount() {
    this.props.getKudos(this.props.employeeId, this.props.year);
  }

  getContent() {
    if (this.props.waiting) {
      return <Loading />;
    } if (!this.props.empty) {
      return (
        <div className="profile-kudos__widgets">
          <AsPmChart />
          <AsTeamMemberChart />
          <Notes />
        </div>
      );
    }
    return <span className="profile-card__placeholder">There's no significant data to show your glory yet! Come back another time</span>;
  }

  render() {
    return (
      <div className="profile-kudos">
        {this.getContent()}
      </div>
    );
  }
};
