const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/GrossMarginPerEmployeeChart.react');

const thousandsValue = (value) => Math.round(value / 100) / 10;

const mapStateToProps = (state) => ({
  thisMonth: thousandsValue(state.month_employee_gross_margin),
  prevMonth: thousandsValue(state.prev_month_employee_gross_margin),
  twoMonthsAgo: thousandsValue(state.two_months_ago_employee_gross_margin),
  prevYearThisMonth: thousandsValue(state.prev_year_employee_gross_margin),
  prevYearPrevMonth: thousandsValue(state.prev_year_prev_month_employee_gross_margin),
  prevYearTwoMonthsAgo: thousandsValue(state.prev_year_two_months_before_employee_gross_margin),
  min: thousandsValue(Math.min(
    state.month_employee_gross_margin, state.prev_month_employee_gross_margin,
    state.two_months_ago_employee_gross_margin, state.prev_year_employee_gross_margin,
    state.prev_year_prev_month_employee_gross_margin,
    state.prev_year_two_months_before_employee_gross_margin,
  )) * 0.8,
});

module.exports = connect(mapStateToProps)(Component);
