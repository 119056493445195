/* eslint-disable no-param-reassign,class-methods-use-this,no-throw-literal,consistent-return,
no-shadow */
const YearFilter = require('./YearFilter');
const QuarterFilter = require('./QuarterFilter');

const TimeFilterFactory = (options) => {
  const validate = (options) => {
    if (!options.period) {
      throw 'TimeFilterFactory needs an object like {period:1,frame:2019}. period is required and must be an integer > 0';
    }
  };

  validate(options);

  if (options.period === 1) {
    return new QuarterFilter(options.frame);
  }
  return new YearFilter(options.period, options.frame);
};

module.exports = TimeFilterFactory;
