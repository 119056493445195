const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const EmployeeTags = require('./containers/employee-tags/EmployeeTags');

module.exports = ({ store }) => (
  <Provider store={store}>
    <EmployeeTags />
  </Provider>
);
