const React = require('react');
const Ellipser = require('../../../../../../../../common/react/Ellipser/Ellipser.react');
const Typography = require('../../../../../../../../common/react/Typography/Typography.react');

const PricelistReadonly = ({ name }) => (
  <Typography weight="normal">
    <Ellipser>{name}</Ellipser>
  </Typography>
);

module.exports = PricelistReadonly;
