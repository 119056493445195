/* eslint-disable camelcase,no-underscore-dangle */

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var InvoicesFileSection = require('../../../apps/core/modules/finance/invoices/components/FileSection.react');
var DownloadErrorModal = require('../../../common/react/modals/CannotDownloadModal.react');
var UploadErrorModal = require('../../../common/react/modals/CannotUploadModal.react');
var PropertyMetadataService = require('../../../services/PropertyMetadataService/PropertyMetadataService');

Wethod.module('FinanceApp.Invoice', function (Invoice, Wethod, Backbone, Marionette, $, _) {
  this.Layout = Marionette.LayoutView.extend({
    /**
     * @attr _invoicesSkeletonsCount: the number of invoice skeletons still in the invoices list.
     *   An
     * "invoice skeleton" is an invoice item row in regions.list corresponding to an invoice ready
     *   to be created
     * (by now, _invoicesSkeletonsCount > 0 only if this layout is being created by the invoice
     *   widget in Dashboard)
     */
    template: '#financeInvoiceLayoutTemplate',
    className: 'financeInvoice pageSection',
    _paramId: null, // id url param
    _orderBy: 'date',
    _sort: 'desc',
    _invoicesSkeletonsCount: 0,
    regions: {
      buttons: '[data-region="invoiceButtons"]',
      header: '[data-region="invoiceHead"]',
      list: '[data-region="invoiceList"]',
      dialog: '[data-region="invoiceDialog"]',
      sectionHeader: '[data-region="financeSectionHeader"]',
    },
    ui: {
      listLoading: '[data-region="invoiceListLoading"]',
      search: '[data-region="searchInvoices"]',
      addInvoiceButton: '[data-action="addInvoice"]',
      exportModuleButton: '[data-action="export"]',
      headerActions: '[data-region="headerActions"]',
      spacer: '.financeInvoice__fixedContentPush',
      changeOrder: '[data-action="changeOrder"]',
    },
    events: {
      'click @ui.addInvoiceButton': 'addInvoice',
      'click @ui.exportModuleButton': 'exportModule',
      'keyup @ui.search': 'onSearchKeyup',
      'click @ui.search': 'selectText',
      'click @ui.changeOrder': 'changeOrder',
    },
    initialize: function (options) {
      this.options = options;
      this._paramId = options.filter ? 'id:' + options.filter : null;

      dispatcher.on('finance:invoice:enableAddButton', function () {
        this.ui.addInvoiceButton.removeClass('disabled');
      }, this);
      dispatcher.on('finance:invoice:created', this.onInvoiceCreated, this);
      dispatcher.on('app:banner:closed', this.placeFixedHeader.bind(this));
    },
    onBeforeShow: function () {
      this.ui.search.val(this._paramId);
      dispatcher.on('invoice:collection:fetched', this.onInvoiceListFetched, this);
      this.setAddDisabled(true);
      this.fetchColl(this._paramId);
    },
    onRender: function () {
      if (!Wethod.FinanceApp.getPermission('create')) {
        this.ui.addInvoiceButton.css({
          visibility: 'hidden',
        });
      }
    },
    /**
     * Triggered when the invoice collection is completely fetched.
     * The suggested invoice date is set to the last day of the month the user choose in the
     * invoice plan.
     */
    onInvoiceListFetched: function () {
      dispatcher.off('invoice:collection:fetched');
      var invoiceListData = this.options.invoice;
      if (invoiceListData) {
        this.setAddDisabled(true);
        this._invoicesSkeletonsCount = invoiceListData.length;
        for (var i = 0; i < invoiceListData.length; i++) {
          invoiceListData[i].date = this.getInvoiceDate(invoiceListData[i].date);
          this.list.currentView.onAddInvoiceButtonClicked(invoiceListData[i]);
        }
      }
    },
    /**
     * Return a valid date for the invoice, properly formatted.
     * If the given date is empty or not valid, the default date is today.
     * @param date
     * @returns {string}
     */
    getInvoiceDate: function (date) {
      var invoiceDate = moment(date);
      var isDateInCurrenMonth = invoiceDate.isSame(moment(), 'month');

      // Set valid date to today
      if (!invoiceDate.isValid() || isDateInCurrenMonth) {
        invoiceDate = moment();
      } else {
        invoiceDate = invoiceDate.endOf('month');
      }

      return invoiceDate.format('YYYY-MM-DD');
    },
    /**
     * Triggered when an invoice skeleton is compiled into a "living" invoice.
     */
    onInvoiceCreated: function () {
      this._invoicesSkeletonsCount -= 1;
      if (this._invoicesSkeletonsCount <= 0) {
        this.setAddDisabled(false);
      } else {
        this.setAddDisabled(true);
      }
    },
    /**
     * Triggered when the "Add Invoice" button is clicked.
     * @param e
     */
    addInvoice: function (e) {
      e.preventDefault();
      if (
        Wethod.FinanceApp.getPermission('create') && !this.ui.addInvoiceButton.hasClass('disabled')
      ) {
        this.setAddDisabled(true);
        Wethod.scrollTop(0);

        dispatcher.trigger('finance:invoice:addInvoice');
      }
    },
    exportModule: function (e) {
      e.preventDefault();
      dispatcher.trigger('finance:invoice:showModalExport');
    },
    /**
     * Toggles 'disabled' CSS class on the addOrderButton.
     * @param bool
     */
    setAddDisabled: function (bool) {
      this._isDisabled = bool;
      if (bool) {
        this.ui.addInvoiceButton.addClass('disabled');
      } else {
        this.ui.addInvoiceButton.removeClass('disabled');
      }
    },
    /**
     * Fetch a new collection with search params
     * @param  {string} search search keyword
     * @param orderBy
     * @param sort
     */
    fetchColl: function (search, orderBy, sort) {
      this.list.reset();
      dispatcher.trigger('show:page:loading', this.ui.listLoading);
      var collRequest = Wethod.request('get:invoices:coll', null, search, orderBy, sort);
      $.when(collRequest).done(function (collResponse) {
        if (!collResponse) {
          return;
        }

        var collView = new Invoice.InvoiceCollView({
          collection: collResponse,
          contacts: this.options.contacts,
          vatRates: this.options.vatRates.toJSON(),
          paymentTerms: this.options.paymentTerms.toJSON(),
          propertyMetadata: this.options.propertyMetadata,
          layout: this,
        });
        this.list.show(collView);
        this.setAddDisabled(false);
        dispatcher.trigger('invoice:collection:fetched');
        dispatcher.trigger('remove:page:loading', this.ui.listLoading);
      }.bind(this));
    },
    /**
     * Triggered when user stops typing for 300 milliseconds in the search's input.
     */
    onSearchKeyup: function (e) {
      if (!Wethod.Utility.allowChar(e.keyCode)) {
        return;
      }
      this.list.reset();
      this.setAddDisabled(true);
      dispatcher.trigger('show:page:loading', this.ui.listLoading);
      Wethod.Utility.withDelay(function () {
        this.fetchColl(this.ui.search.val(), this._orderBy, this._sort);
      }.bind(this), 300);
    },
    /**
     * Select the text contained in the event's target.
     * @param event
     */
    selectText: function (event) {
      event.target.select();
    },
    changeOrder: function (event) {
      this.list.reset();
      this.setAddDisabled(true);
      dispatcher.trigger('show:page:loading', this.ui.listLoading);
      $('.recordsHeader__recordName.active').removeClass('active');
      var currentClicked = this.getTarget(event).closest('.recordsHeader__recordName');
      this._orderBy = currentClicked.data('order');
      currentClicked.addClass('active');
      var arrow = currentClicked.find('.orderDirectionIcon');
      this._sort = this._sort === 'asc' ? this._sort = 'desc' : this._sort = 'asc';
      Wethod.Utility.withDelay(function () {
        this.fetchColl(this.ui.search.val(), this._orderBy, this._sort);
      }.bind(this), 300);
      if (this._sort === 'asc') {
        arrow.addClass('wethod-icon-medium-arrow-up');
        arrow.addClass('wethod-icon-medium-arrow-up--white');
        arrow.removeClass('wethod-icon-medium-arrow-down');
        arrow.removeClass('wethod-icon-medium-arrow-down--white');
      } else {
        arrow.addClass('wethod-icon-medium-arrow-down');
        arrow.addClass('wethod-icon-medium-arrow-down--white');
        arrow.removeClass('wethod-icon-medium-arrow-up');
        arrow.removeClass('wethod-icon-medium-arrow-up--white');
      }
    },
    getTarget: function (event) {
      return $(event.target);
    },
    manageTopContainerBorder: function () {
      $(window).scroll(function () {
        if ($(window).scrollTop()) {
          this.ui.headerActions.css({ marginTop: 0 });
        } else {
          this.ui.headerActions.css({ marginTop: '2px' });
        }
      }.bind(this));
    },
    /**
     * Place fixed header taking care of space occupied by app header and section header.
     */
    placeFixedHeader: function () {
      Wethod.placeFixedHeader(this.ui.headerActions, this.ui.spacer);
    },
    onShow: function () {
      this.placeFixedHeader();

      this.manageTopContainerBorder();
    },
    onDestroy: function () {
      $(window).off('scroll');
    },
  });

  this.HeaderView = Marionette.ItemView.extend({
    template: '#financeInvoiceHeadTemplate',
    className: 'recordsHeader',
  });

  // itemView utilizzato per mostra il feedback di loading all'utente
  this.AutocompleteLoadingItemView = Marionette.ItemView.extend({
    template: '#autocompleteLoadingTemplate',
    className: 'recordList__record__hints__message',
  });

  /**
   * A row representing a single invoice.
   * Constraints:
   * - Value needs to be displayed only when > 0 and when rate !== 1. This is because we assume
   * that a rate === 1 stands for the company currency. We need to use this trick because we only
   * store "value" in DB ("change" is derived)
   * - "Set manual rate" needs to be displayed only for invoices which have a currency
   * - You must be able to set manual rate even after sending invoice
   * - You cannot edit in any way an invoice if you don't have the right permissions
   */
  this.InvoiceView = Marionette.ItemView.extend({
    tagName: 'li',
    className: function () {
      if (this.model.isNew() || (this.hasPermissions() && !this.isLockedDueToDataFreezing())) {
        return 'recordList__record';
      }
      return 'recordList__record recordList__record--no-permissions';
    },
    ui: {
      projectField: '[data-region="projectField"]',
      moreOptionsButton: '[data-action="moreOptions"]',
      moreOptionsList: '[data-action="moreOptionsList"]',
      projectFieldContainer: '[data-region="projectFieldContainer"]',
      areaField: '[data-region="areaField"]',
      invoiceNumberField: '[data-region="invoiceNumberField"]',
      invoiceValueField: '[data-region="invoiceValueField"]',
      invoiceCurrencyField: '[data-region="invoiceCurrencyField"]',
      invoiceChangeField: '[data-region="invoiceChangeField"]',
      clientField: '[data-region="clientField"]',
      jobOrderField: '[data-region="jobOrderField"]',
      dateField: '[data-region="dateField"]',
      pmField: '[data-region="pmField"]',
      manageNote: '[data-action="manageNote"]',
      sendInvoiceButton: '[data-action="sendInvoice"]',
      paidInvoiceButton: '[data-action="paidInvoice"]',
      saveInvoiceButton: '[data-action="saveInvoice"]',
      setUnpaidInvoiceButton: '[data-action="setUnpaidInvoice"]',
      deleteInvoiceButton: '[data-action="deleteInvoice"]',
      setManualRateButton: '[data-action="setManualRate"]',
      editDetailsButton: '[data-action="editDetails"]',
      exportXMLButton: '[data-action="exportXML"]',
      exportPDFButton: '[data-action="exportPDF"]',
      toolTip: '[data-region="projectTooltip"]',
      areaTooltip: '[data-region="areaTooltip"]',
      feedbackField: '[data-region="feedbackField"]',
      noteFieldIcon: '[data-region="noteIcon"]',
      paidLabel: '[data-region="paidLabel"]',
      mdlInput: '[data-region="mdl-input"]',
      filesButton: '[data-action="filesInvoice"]',
      frozenDataLabel: '[data-region="frozenDataLabel"]',
    },
    events: {
      'click @ui.sendInvoiceButton': 'sendInvoice',
      'click @ui.paidInvoiceButton': 'setPaidInvoice',
      'click @ui.saveInvoiceButton': 'saveInvoice',
      'click @ui.setUnpaidInvoiceButton': 'setUnpaidInvoice',
      'click @ui.deleteInvoiceButton': 'deleteInvoice',
      'click @ui.moreOptionsButton': 'toggleMoreOptions',
      'click @ui.setManualRateButton': 'openManualRateModal',
      'click @ui.editDetailsButton': 'openDetailsModal',
      'click @ui.exportXMLButton': 'downloadXML',
      'click @ui.exportPDFButton': 'downloadPDF',
      'click @ui.projectFieldContainer': 'openSearch',
      'keyup @ui.projectField': 'search',
      'click @ui.areaField': 'searchArea',
      'keyup @ui.areaField': 'searchArea',
      'keyup @ui.invoiceNumberField': function (e) {
        return this.updateModel(e, 'invoice_number');
      },
      'keyup @ui.invoiceValueField': 'updateChangeValue',
      'click @ui.noteFieldIcon': 'showNote',
      'click @ui.filesButton': 'openFilesModal',
    },
    modelEvents: {
      change: 'modelChanged',
      'change:budget_areas': 'budgetAreasChanged',
      'change:id_project': 'onChangeProject',
      'change:value': 'onChangeValue',
      'change:change': 'onChangeValueChange',
      'email:ship': 'shipEmail',
    },
    openManualRateModal: function (e) {
      e.preventDefault();
      if (this.hasPermissions()) {
        dispatcher.trigger('finance:invoice:showModalExchangeRate', this);
        this.ui.moreOptionsList.hide();
      }
    },
    openDetailsModal: function (e) {
      e.preventDefault();

      // Check on edit permission is made by the modal
      dispatcher.trigger('finance:invoice:showModalEditDetails', this);
      this.ui.moreOptionsList.hide();
    },
    downloadXML: function (e) {
      e.preventDefault();
      // open loading modal
      dispatcher.trigger('finance:invoice:showModalXmlExport', true, 'Download XML Invoice');
      var downloadRequest = Wethod.request('get:invoice:export:xml', this.model.get('id'));
      $.when(downloadRequest).done(function () {
        // open download success modal
        dispatcher.trigger('finance:invoice:showModalXmlExport', false, 'Download XML Invoice');
      }).fail(function (data) {
        dispatcher.trigger('finance:invoice:showErrorModal', data.message);
      });
      this.ui.moreOptionsList.hide();
    },
    downloadPDF: function (e) {
      e.preventDefault();
      // open loading modal
      dispatcher.trigger('finance:invoice:showModalXmlExport', true, 'Download PDF Invoice');
      var downloadRequest = Wethod.request('get:invoice:export:pdf', this.model.get('id'));
      $.when(downloadRequest).done(function () {
        // open download success modal
        dispatcher.trigger('finance:invoice:showModalXmlExport', false, 'Download PDF Invoice');
      }).fail(function (data) {
        dispatcher.trigger('finance:invoice:showErrorModal', data.message);
      });
      this.ui.moreOptionsList.hide();
    },
    openFilesModal: function (e) {
      e.preventDefault();
      dispatcher.trigger('finance:invoice:showFilesModal', this);
      this.ui.moreOptionsList.hide();
    },
    openFileDownloadErrorModal: function (error) {
      dispatcher.trigger('finance:invoice:showFileDownloadErrorModal', this, error);
    },
    openFileUploadErrorModal: function (error) {
      dispatcher.trigger('finance:invoice:showFileUploadErrorModal', this, error);
    },
    toggleMoreOptions: function (e) {
      e.preventDefault();
      this.ui.moreOptionsList.toggle();
    },
    /**
     * Updates the exchange rate's text.
     */
    updateValue: function () {
      var value = '';
      if (this.model.getExchangeRate() !== 1) {
        value = numeral(this.model.get('value')).format('0,0.00');
      }
      this.ui.invoiceChangeField.text(value);
    },
    onChangeValueChange: function () {
      this.updateValue();
    },
    onChangeValue: function () {
      this.updateValue();
    },
    budgetAreasChanged: function () {
      this.areaCollectionView = new Invoice.AutocompleteAreaRowsCollectionView(
        {
          collection: this.model.get('budget_areas'),
          row: this,
        }
      );
      this.ui.areaTooltip.html(this.areaCollectionView.render().$el);
    },
    onChangeProject: function () {
      this.ui.areaField.val(this.model.get('area').name);
    },
    initialize: function (options) {
      this.options = options;
      this.on('finance:invoice:selectedInvoiceProject', function (projectModel) {
        this.model.set('budget_areas', []);
        this.model.set('id_project', projectModel.get('project_id'));
        this.model.set('id_client', projectModel.get('client_id'));
        this.model.get('project').id = projectModel.get('project_id');
        this.model.get('project').name = projectModel.get('project_name');
        this.model.get('project').job_order = projectModel.get('job_order');
        this.model.get('project').pm.id = projectModel.get('pm_id');
        this.model.get('project').pm.pm_name = projectModel.get('pm_name');
        this.model.get('project').pm.pm_surname = projectModel.get('pm_surname');
        this.model.get('project').account.id = projectModel.get('account_id');
        this.model.get('project')._fields = projectModel.get('_fields');
        this.model.get('client').id = projectModel.get('client_id');
        this.model.get('client').corporate_name = projectModel.get('client_name');
        this.model.set('currency', projectModel.get('currency'));

        // Update vat rate when we select a project with a client with vat rate
        var clientVatRateId = projectModel.get('client_vat_rate_id');
        // Find client vat rate if it is still available
        var clientVatRate = this.options.vatRates.filter(function (item) {
          return item.id === clientVatRateId;
        });
        clientVatRate = clientVatRate && clientVatRate.length ? clientVatRate[0] : null;
        this.model.set('vat_rate', clientVatRate);

        // Update payment term when we select a project
        var clientPaymentTermId = projectModel.get('client_payment_term_id');
        // Find client payment term if it is still available
        var clientPaymentTerm = this.options.paymentTerms.filter(function (item) {
          return item.id === clientPaymentTermId;
        });
        clientPaymentTerm = clientPaymentTerm && clientPaymentTerm.length
          ? clientPaymentTerm[0]
          : null;
        this.model.set('payment_term', clientPaymentTerm);

        // Update purchase order when we select a project
        var purchaseOrder = projectModel.get('purchase_order');
        this.model.set('purchase_order', purchaseOrder);

        this.ui.projectField.val(projectModel.get('project_name'));
        this.ui.clientField.text(projectModel.get('client_name'));
        this.ui.jobOrderField.text(projectModel.get('job_order'));
        this.ui.pmField.text(projectModel.get('pm_name') + ' ' + projectModel.get('pm_surname'));
        this.ui.projectField.parent().addClass('is-dirty is-upgraded');
        this.ui.invoiceCurrencyField.text(projectModel.get('currency') ? projectModel.get('currency').code : '');
        this.updateValue();
        var request = Wethod.request('get:finance:invoice:project:areas',
          projectModel.get('project_id'));
        $.when(request).done(function (response) {
          this.model.set('budget_areas', response);
        }.bind(this));

        // Disable the save button if project.invoices is not editable
        this.updateSaveButtonForDataFreezing();

        // Show / hide the "frozen data" label
        this.updateFrozenDataLabel();
      }, this);

      this.on('finance:invoice:selectedInvoiceArea', function (areaModel) {
        this.model.set('id_area', areaModel.get('id'));

        this.model.get('area').uid = areaModel.get('id');
        this.model.get('area').name = areaModel.get('name');

        this.ui.areaField.val(areaModel.get('name'));
        this.ui.areaField.parent().addClass('is-dirty is-upgraded');
      }, this);

      this.on('finance:invoice:updateNote', function (noteModalView) {
        var note = noteModalView.model.get('note');
        this.model.set('note', note);
        noteModalView.destroy();

        if (_.isNull(this.model.get('note'))) {
          this.ui.noteFieldIcon.addClass('financeInvoice__note__button__emptyIcon');
        } else {
          this.ui.noteFieldIcon.addClass('financeInvoice__note__button__filledIcon');
        }
      }, this);

      this.on('finance:invoice:deleteInvoice', function (confirmModalView) {
        var deleteRequest = Wethod.request('delete:finance:invoice', this.model);
        $.when(deleteRequest).done(function () {
          confirmModalView.destroy();
        });
      }, this);
    },
    /**
     * Check whether the user has permission to edit the invoice:
     * - sent or paid invoices -> edit_sent is needed
     * - not sent invoices -> edit or edit_other is needed
     * @returns {*}
     */
    hasPermissions: function () {
      var idPm = this.model.get('project').pm.id;
      var idAccount = this.model.get('project').account.id;
      var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

      // The invoice is sent or paid
      var isSent = this.model.get('status') >= 1;

      if (isSent) {
        return Wethod.FinanceApp.getPermission('edit_sent');
      }

      return Wethod.FinanceApp.getPermissionAs('edit', amIPmOrAccount);
    },
    /**
     * Returns the template to use.
     * @return {string}
     */
    getTemplate: function () {
      if (this.model.isNew()) {
        return '#financeInvoiceRowInsertTemplate';
      }
      if (!this.hasPermissions() || this.isLockedDueToDataFreezing()) {
        return '#financeInvoiceRowNoPermissionTemplate';
      }
      if (this.model.get('status') === 0) {
        return '#financeInvoiceRowTemplate';
      }
      if (this.model.get('status') === 1) {
        return '#financeInvoiceSentRowTemplate';
      }
      return '#financeInvoicePaidRowTemplate';
    },
    updateModel: function (e, field) {
      var value = (e.target.value.trim() === '') ? null : e.target.value.trim();
      if (this.model.get(field) !== value) {
        this.ui.feedbackField.text('').removeClass('recordList__record__feedBack--error').hide();
        this.model.set(field, value);
      }
    },
    onRender: function () {
      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      this.ui.pmField.text(this.model.get('project').pm.pm_name + ' ' + this.model.get('project').pm.pm_surname);

      var dateConstraintValue = this.getPropertyMetadataService().getConstraintValue('date', 'greater_than');
      var dataFreezingMinDate = dateConstraintValue ? moment(dateConstraintValue).add(1, 'days') : null;
      var that = this;
      $(this.ui.dateField).datepicker({
        constrainInput: true,
        firstDay: 1,
        format: 'dd/mm/y',
        altFormat: 'dd/mm/y',
        dateFormat: 'yy-mm-dd',
        defaultDate: moment(that.model.get('date')).format('YYYY-MM-DD'),
        onSelect: function (dateText) {
          that.model.set('date', moment(dateText).format());
          $(this).val(moment(dateText).format('DD/MM/YYYY'));
        },
        minDate: dataFreezingMinDate ? dataFreezingMinDate.format('YYYY-MM-DD') : null,
      });

      if (_.isNull(this.model.get('note'))) {
        this.ui.noteFieldIcon.addClass('financeInvoice__note__button__emptyIcon');
      } else {
        this.ui.noteFieldIcon.addClass('financeInvoice__note__button__filledIcon');
      }

      this.ui.invoiceValueField.autoNumeric('init', {
        aSep: '.',
        aDec: ',',
      });

      if (!this.model.isNew()) {
        var idPm = this.model.get('project').pm.id;
        var idAccount = this.model.get('project').account.id;
        var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

        if (!Wethod.FinanceApp.getPermissionAs('send', amIPmOrAccount)) {
          this.ui.sendInvoiceButton.addClass('disabled');
          this.ui.paidInvoiceButton.addClass('disabled');
        }
        if (!Wethod.FinanceApp.getPermissionAs('delete', amIPmOrAccount)) {
          this.ui.deleteInvoiceButton.addClass('financeInvoice__delete__button--disabled');
        }
      }

      // Disable the save button if project.invoices is not editable
      this.updateSaveButtonForDataFreezing();

      // Show / hide the "frozen data" label
      this.updateFrozenDataLabel();
    },
    sendInvoice: function (e) {
      e.preventDefault();
      var contacts = this.options.contacts;
      if (contacts.length > 1) {
        dispatcher.trigger('finance:invoice:showModalRecipients', this.model);
      } else {
        var contactId = null;
        if (contacts.length === 1) {
          contactId = contacts.at(0).get('id');
        }
        this.shipEmail(contactId);
      }
    },
    shipEmail: function (contactId) {
      var idPm = this.model.get('project').pm.id;
      var idAccount = this.model.get('project').account.id;
      var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

      if (Wethod.FinanceApp.getPermissionAs('send', amIPmOrAccount) && !this.ui.sendInvoiceButton.hasClass('disabled')) {
        var that = this;
        this.ui.sendInvoiceButton.addClass('disabled');
        var sendRequest = Wethod.request('send:finance:invoice', this.model, contactId);
        $.when(sendRequest).done(function (sendResponse) {
          var responseCode = sendResponse.code;
          if (responseCode === 400) {
            var intercompanyInfoRequest = Wethod.request('pipeline:intercompany:supplier:details',
              { projectId: that.model.get('project').id });
            $.when(intercompanyInfoRequest).done(function (intercompanyInfoResponse) {
              dispatcher.trigger('finance:invoice:showModalCannotSend', {
                view: this,
                type: sendResponse.data.type,
                model: that.model,
                intercompanyInfo: intercompanyInfoResponse.data,
              });
            });
            that.ui.sendInvoiceButton.removeClass('disabled');
          } else {
            that.model.set('status', 1);
            that.render();
          }
        });
      }
    },
    setPaidInvoice: function (e) {
      e.preventDefault();
      var idPm = this.model.get('project').pm.id;
      var idAccount = this.model.get('project').account.id;
      var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

      if (Wethod.FinanceApp.getPermissionAs('send', amIPmOrAccount) && !this.ui.paidInvoiceButton.hasClass('disabled')) {
        var that = this;
        this.ui.paidInvoiceButton.addClass('disabled');
        var sendRequest = Wethod.request('paid:finance:invoice', this.model);
        $.when(sendRequest).done(function () {
          that.model.set('status', 2);
          // Set pay date as current date
          that.model.set('pay_date', moment().format());

          that.render();
        });
      }
    },
    setUnpaidInvoice: function (e) {
      e.preventDefault();
      var idPm = this.model.get('project').pm.id;
      var idAccount = this.model.get('project').account.id;
      var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

      if (Wethod.FinanceApp.getPermissionAs('send', amIPmOrAccount)) {
        this.ui.setUnpaidInvoiceButton.addClass('disabled');
        var sendRequest = Wethod.request('send:finance:invoice', this.model);
        $.when(sendRequest).done(function () {
          this.model.set('status', 1);
          this.render();
        }.bind(this));
      }
    },
    saveExchangeRate: function () {
      if (this.model.isValid()) {
        var that = this;

        this.ui.feedbackField.text('Saving...')
          .addClass('recordList__record__feedBack--success')
          .show();

        var saveRequest = Wethod.request('save:finance:invoice', this.model);
        $.when(saveRequest).done(function () {
          that.ui.feedbackField.text('')
            .removeClass('recordList__record__feedBack--success')
            .hide();

          dispatcher.trigger('finance:invoice:exchange-rate:updated', that.model);
        });
      } else {
        this.ui.feedbackField
          .text(this.model.validationError[0])
          .addClass('recordList__record__feedBack--error').show();
      }
    },
    saveInvoice: function (e) {
      e.preventDefault();
      if (this.model.isValid()) {
        var isSaveButtonDisabled = this.ui.saveInvoiceButton.hasClass('disabled');
        if (!isSaveButtonDisabled) {
          var that = this;
          var wasNew = this.model.isNew();

          this.ui.pmField.text(this.model.get('project').pm.pm_name + ' ' + this.model.get('project').pm.pm_surname);

          this.model.set('date', moment(this.model.get('date')).format());

          var pay_date = this.model.get('pay_date');
          if (pay_date) {
            this.model.set('pay_date', moment(this.model.get('pay_date')).format());
          }

          this.ui.saveInvoiceButton.addClass('disabled');
          this.ui.feedbackField.text('Saving...')
            .addClass('recordList__record__feedBack--success')
            .show();

          var saveRequest = Wethod.request('save:finance:invoice', this.model);
          $.when(saveRequest).done(function () {
            dispatcher.trigger('finance:invoice:created');
            that.ui.saveInvoiceButton.removeClass('disabled').hide();
            that.ui.sendInvoiceButton.show();
            that.ui.paidInvoiceButton.show();
            that.ui.paidLabel.show();
            that.ui.feedbackField.text('')
              .removeClass('recordList__record__feedBack--success')
              .hide();

            if (wasNew) {
              that.render();
            }
          });
        }
      } else {
        this.ui.feedbackField
          .text(this.model.validationError[0])
          .addClass('recordList__record__feedBack--error').show();
      }
    },
    deleteInvoice: function (e) {
      e.preventDefault();
      if (this.model.isNew()) {
        this.model.destroy();
        dispatcher.trigger('finance:invoice:enableAddButton');
      } else {
        var idPm = this.model.get('project').pm.id;
        var idAccount = this.model.get('project').account.id;
        var amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(idPm, idAccount);

        if (Wethod.FinanceApp.getPermissionAs('delete', amIPmOrAccount)) {
          this.ui.moreOptionsList.hide();
          dispatcher.trigger('finance:invoice:showModalConfirm', this);
        }
      }
    },
    searchArea: function (e) {
      var search = e.target.value.trim();
      if (search === '') {
        this.model.set('id_area', null);
        this.model.get('area').uid = null;
        this.model.get('area').name = null;
      }
      if (
        (e.type === 'click' && search === '')
        || e.type === 'keyup'
      ) {
        this.ui.areaTooltip.addClass('isVisible');
        this.ui.feedbackField.text('').removeClass('recordList__record__feedBack--error').hide();
        e.stopPropagation();

        if (this.model.get('budget_areas').length === 0) {
          var projectId = this.model.get('id_project');

          var loadingTemplate = new Invoice.AutocompleteLoadingItemView();
          this.ui.areaTooltip.html(loadingTemplate.render().$el);

          if (projectId) {
            var request = Wethod.request('get:finance:invoice:project:areas',
              this.model.get('id_project'));
            $.when(request).done(function (response) {
              this.model.set('budget_areas', response);
            }.bind(this));
          } else {
            this.model.set('budget_areas', null);
          }
        } else {
          this.areaCollectionView.filterKey = e.target.value;
          this.areaCollectionView.render();
        }
      }
    },
    openSearch: function () {
      var search = $(this.ui.projectFieldContainer).find('input').val().trim();
      if (search === '') {
        this.model.set('id_project', null);
        this.model.get('project').id = null;
        this.model.get('project').job_order = null;
        this.model.get('project').name = null;
        this.model.get('project').pm.id = null;
        this.model.set('id_area', null);
        this.model.get('area').uid = null;
        this.model.get('area').name = null;
      }

      this.ui.feedbackField.text('').removeClass('recordList__record__feedBack--error').hide();

      var params = (search !== '') ? '?hints=' + encodeURIComponent(search) : '';
      var url = '/invoiceboard/autocomplete' + params;
      var collectionView = Invoice.AutocompleteProjectRowsCollectionView;

      var options = {
        url: url,
      };
      this.ui.toolTip.addClass('isVisible');
      dispatcher.trigger('get:autocomplete', options, collectionView, this.ui.toolTip, this);
    },
    search: function (e) {
      var search = e.target.value.trim();
      if (search === '') {
        this.model.set('id_project', null);
        this.model.get('project').id = null;
        this.model.get('project').job_order = null;
        this.model.get('project').name = null;
        this.model.get('project').pm.id = null;
        this.model.set('id_area', null);
        this.model.get('area').uid = null;
        this.model.get('area').name = null;
      }

      if (
        (e.type === 'click' && search === '')
        || e.type === 'keyup'
      ) {
        this.ui.feedbackField.text('').removeClass('recordList__record__feedBack--error').hide();

        var params = (search !== '') ? '?hints=' + encodeURIComponent(search) : '';
        var url = '/invoiceboard/autocomplete' + params;
        var collectionView = Invoice.AutocompleteProjectRowsCollectionView;

        var options = {
          url: url,
        };
        this.ui.toolTip.addClass('isVisible');
        dispatcher.trigger('get:autocomplete', options, collectionView, this.ui.toolTip, this);
      }
    },
    modelChanged: function () {
      var exchangeRateChanged = this.model.changed.exchange_rate !== undefined;
      var filesChanged = this.model.changed.files !== undefined;

      /*
       * The only way to update exchange_rate is by doing it manually, when this happens we don't
       * want to init the SAVE-SEND-PAID cycle but we just need to save the manual rate to the
       *  server.
       * Files changes (like upload and delete) also should not trigger the invoice saving.
       */
      if (!exchangeRateChanged && !filesChanged) {
        this.ui.sendInvoiceButton.hide();
        this.ui.paidInvoiceButton.hide();
        this.ui.paidLabel.hide();
        this.ui.saveInvoiceButton.css({
          display: 'block',
        });
      }
    },
    showNote: function (e) {
      e.preventDefault();
      dispatcher.trigger('finance:invoice:showModalNote', this);
    },
    updateChangeValue: function (e) {
      var change = numeral().unformat(e.target.value);
      if (parseInt(change) !== 0) {
        this.model.set('change', change);
        this.ui.invoiceValueField.parent().addClass('is-dirty is-upgraded');
      } else {
        this.model.set('change', null);
        this.ui.invoiceValueField.parent().removeClass('is-dirty is-upgraded');
      }
    },
    /**
     * Check whether the invoice is locked due to data freezing. This checks the "value" property
     * to lock the entire invoice, a better way would be to check each property individually and
     * lock only the ones that are impacted by data freezing.
     * @returns {boolean}
     */
    isLockedDueToDataFreezing: function () {
      var propertyMetadataService = this.getPropertyMetadataService();
      return !propertyMetadataService.getCanEdit('value')
        && propertyMetadataService.getReason('value') === 'DataFrozen';
    },
    /**
     * Show or hide the "frozen data" label based on the invoice's status.
     */
    updateFrozenDataLabel: function () {
      var projectPropertyMetadataService = this.getProjectPropertyMetadataService();
      if (projectPropertyMetadataService.hasMetadata()) {
        if (
          !projectPropertyMetadataService.getCanEdit('invoices')
          && projectPropertyMetadataService.getReason('invoices') === 'DataFrozen'
        ) {
          this.showFrozenDataLabel();
        } else {
          this.hideFrozenDataLabel();
        }
      } else if (this.isLockedDueToDataFreezing()) {
        this.showFrozenDataLabel();
      } else {
        this.hideFrozenDataLabel();
      }
    },
    updateSaveButtonForDataFreezing: function () {
      var projectPropertyMetadataService = this.getProjectPropertyMetadataService();
      if (
        !projectPropertyMetadataService.getCanEdit('invoices')
        && projectPropertyMetadataService.getReason('invoices') === 'DataFrozen'
      ) {
        this.ui.saveInvoiceButton.addClass('disabled');
      } else {
        this.ui.saveInvoiceButton.removeClass('disabled');
      }
    },
    /**
     * Returns the PropertyMetadataService for the current model that is the generic "Invoice"
     * metadata if the invoice is new or the invoice specific metadata if the invoice is not new.
     * @returns {PropertyMetadataService}
     */
    getPropertyMetadataService: function () {
      if (this.model.isNew()) {
        return new PropertyMetadataService(this.options.propertyMetadata);
      }
      return new PropertyMetadataService(this.model.get('_fields'));
    },
    /**
     * Returns the PropertyMetadataService for the project of the current invoice model. If not
     * available, an empty PropertyMetadataService is returned.
     * @returns {PropertyMetadataService}
     */
    getProjectPropertyMetadataService: function () {
      var projectPropertyMetadata = this.model.get('project')._fields;
      if (projectPropertyMetadata) {
        return new PropertyMetadataService(projectPropertyMetadata);
      }
      return new PropertyMetadataService([]);
    },
    showFrozenDataLabel: function () {
      this.ui.frozenDataLabel.show();
    },
    hideFrozenDataLabel: function () {
      this.ui.frozenDataLabel.hide();
    },
  });
  /**
   * The view to be rendered when no invoice is available.
   */
  this.EmptyInvoiceView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'recordList__record',
    template: '#financeInvoiceEmptyRowTemplate',
    initialize: function (options) {
      this.options = options || {};
      /**
       * Stop listening to page scroll when adding an empty order row.
       */
      dispatcher.off('html:page:scroll');
    },
  });

  /**
   * Represents the invoices list.
   */
  this.InvoiceCollView = Marionette.CollectionView.extend({
    childView: Invoice.InvoiceView,
    emptyView: Invoice.EmptyInvoiceView,
    tagName: 'ul',
    className: 'recordList',
    _loading: false,
    initialize: function (options) {
      this.options = options || {};
      this._loading = this.collection.length < this.collection._offset;
      this.childViewOptions = {
        contacts: options.contacts,
        vatRates: options.vatRates,
        paymentTerms: options.paymentTerms,
        propertyMetadata: this.options.propertyMetadata,
      };

      dispatcher.on('html:page:scroll', this.onScroll, this);
      dispatcher.on('finance:invoice:addInvoice', this.onAddInvoiceButtonClicked, this);
      dispatcher.on('finance:invoice:deleteInvoice', this.deleteInvoice, this);
      dispatcher.on('finance:invoice:exchange-rate:updated', this.onExchangeRateUpdated, this);
    },
    /**
     * Updates the final_net_price of all the invoices coming from the same project of invoiceModel.
     * @param invoiceModel
     */
    onExchangeRateUpdated: function (invoiceModel) {
      this.collection = this.collection.map(function (invoice) {
        if (invoice.get('project').id === invoiceModel.get('project').id) {
          invoice.get('budget').final_net_price = invoiceModel.get('budget').final_net_price;
        }
        return invoice;
      });
    },
    /**
     * Triggered when the user scroll the page.
     */
    onScroll: function () {
      if (this._loading === true) {
        return;
      }

      if ($(window).scrollTop() + 50 >= $(document).height() - $(window).height()) {
        this._loading = true;
        var region = this.options.layout.ui.listLoading;
        dispatcher.trigger('show:page:loading', region);
        $.when(this.collection.getNextPage()).done(function () {
          dispatcher.trigger('remove:page:loading', region);
          this._loading = this.collection.length < this.collection._offset;
        }.bind(this));
      }
    },
    /**
     * Adds the given invoice to the top of the list.
     * @param invoiceModel
     */
    addInvoice: function (invoiceModel) {
      this.collection.add(invoiceModel, { at: 0 });
    },
    /**
     * Triggered when the "add invoice" button is clicked.
     */
    onAddInvoiceButtonClicked: function (invoiceData) {
      var newModel = new Invoice.InvoiceModel(invoiceData);
      this.addInvoice(newModel);
    },
    /**
     * Deletes the given invoice from the list and manages the delete's modal accordingly.
     * @param invoiceModel
     * @param deleteModal
     */
    deleteOrder: function (invoiceModel, deleteModal) {
      // creo una copia del modello da cancellare in modo che il destroy
      // fatto dalla request non tolga il modello dalla collection view
      var modelCopy = new Invoice.InvoiceModel(invoiceModel.attributes);

      deleteModal.ui.feedbackField.text('Loading...').addClass('messageModal__feedback--success');

      // utilizzo la copia del modello per effettuare la cancellazione
      // del record dal database
      var deleteRequest = Wethod.request('delete:finance:invoice', modelCopy);
      $.when(deleteRequest).done(function (deleteResponse) {
        if (!_.isUndefined(deleteResponse)) {
          // rimuovo il modello dalla collection in modo da
          // farlo scomparire dalla collection view
          this.collection.remove(invoiceModel);
          // rimuovo la view del modal
          deleteModal.destroy();
        }
      }.bind(this));
    },
  });

  this.AutocompleteAreaRowItemView = Marionette.ItemView.extend({
    template: '#financeOrderAreaAutocompleteRowTemplate',
    tagName: 'li',
    className: '',
    ui: {
      hintButton: '[data-action="selectHint"]',
    },
    events: {
      'click @ui.hintButton': 'selectHint',
    },
    selectHint: function (e) {
      e.preventDefault();
      this.options.parent.trigger('finance:invoice:selectedInvoiceArea', this.model);
    },
  });

  this.AutocompleteProjectRowItemView = Marionette.ItemView.extend({
    template: '#financeInvoiceProjectAutocompleteRowTemplate',
    tagName: 'li',
    className: '',
    ui: {
      hintButton: '[data-action="selectHint"]',
    },
    events: {
      'click @ui.hintButton': 'selectHint',
    },
    selectHint: function (e) {
      e.preventDefault();
      this.options.parent.trigger('finance:invoice:selectedInvoiceProject', this.model);
    },
  });
  this.AutocompleteEmptyRowItemView = Marionette.ItemView.extend({
    template: '#financeInvoiceAutocompleteEmptyRowTemplate',
    tagName: 'li',
    className: 'recordList__record__hints__list__empty',
  });
  this.AutocompleteProjectRowsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'recordList__record__hints__list',
    emptyView: Invoice.AutocompleteEmptyRowItemView,
    childView: Invoice.AutocompleteProjectRowItemView,
    filter: function (child) {
      // Filter out projects for which invoice creation is not allowed due to data freezing
      var propertyMetadataService = new PropertyMetadataService(child.get('_fields'));
      return propertyMetadataService.getCanEdit('invoices');
    },
    onRender: function () {
      Wethod.onHtmlClick('[data-region="projectTooltip"]', 'isVisible', 'remove');
    },
  });

  this.AutocompleteAreaRowsCollectionView = Marionette.CollectionView.extend({
    filterKey: '',
    tagName: 'ul',
    className: 'recordList__record__hints__list',
    emptyView: Invoice.AutocompleteEmptyRowItemView,
    childView: Invoice.AutocompleteAreaRowItemView,
    initialize: function (options) {
      this.options = options;
    },
    childViewOptions: function () {
      return { parent: this.options.row };
    },
    onRender: function () {
      Wethod.onHtmlClick('[data-region="areaTooltip"]', 'isVisible', 'remove');
    },
    filter: function (child) {
      return child.get('name').toLowerCase().indexOf(this.filterKey.toLowerCase()) !== -1;
    },
  });

  this.ChooseRecipientItemView = Marionette.ItemView.extend({
    className: 'finance-modal__recipient-item',
    template: '#financeChooseRecipientModalItemTemplate',
    events: {
      click: 'onClick',
    },
    onClick: function (e) {
      e.preventDefault();
      this.trigger('selected', this.model);
    },
  });

  this.ChooseRecipientListView = Marionette.CollectionView.extend({
    childView: this.ChooseRecipientItemView,
    childEvents: {
      selected: 'onRecipientSelected',
    },
    onRecipientSelected: function (childView, model) {
      this.trigger('recipient:selected', model.get('id'));
    },
  });

  this.ChooseRecipientModalView = Marionette.LayoutView.extend({
    template: '#financeChooseRecipientModalTemplate',
    className: 'messageModalPageOverlay',
    regions: {
      items: '[data-region="contacts"]',
    },
    ui: {
      // body della modal
      modalBody: '[data-region="modalBody"]',

      feedbackField: '[data-region="modalFeedback"]',

      // BUTTONS
      cancelButton: '[data-action="modalClose"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.showItems();
    },
    showItems: function () {
      var view = new Invoice.ChooseRecipientListView({
        collection: this.options.contacts,
        invoiceModel: this.options.invoiceModel,
      });
      view.on('recipient:selected', this.onRecipientSelected, this);
      this.items.show(view);
    },
    onRecipientSelected: function (id) {
      this.options.invoiceModel.trigger('email:ship', id);
      this.destroy();
    },
    onDomRefresh: function () {
      // posiziono la view
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.NoteModalView = Marionette.ItemView.extend({
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      textField: '[data-region="noteText"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
      saveNoteButton: '[data-action="saveInvoiceNote"]',
    },
    events: {
      'keyup @ui.textField': 'updateModel',
      'click @ui.cancelButton': 'closeModal',
      'click @ui.saveNoteButton': 'saveInvoiceNote',
    },
    initialize: function (options) {
      this.options = options;
    },
    getTemplate: function () {
      if (
        !this.options.parent.hasPermissions()
        || this.options.parent.isLockedDueToDataFreezing()
      ) {
        return '#financeInvoiceNoteBlockedModalTemplate';
      }
      return '#financeInvoiceNoteModalTemplate';
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    updateModel: function (e) {
      var value = (e.target.value.trim() === '') ? null : e.target.value.trim();
      this.model.set('note', value);
    },
    saveInvoiceNote: function (e) {
      e.preventDefault();
      this.options.parent.trigger('finance:invoice:updateNote', this);
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.ExchangeRateModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceExchangeRateModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
      saveButton: '[data-action="modalSave"]',
      rateInput: '[data-action="rateInput"]',
      nextMasterFinalNet: '[data-action="nextMasterFinalNet"]',
      updatedAtText: '[data-action="updatedAtText"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
      'click @ui.saveButton': 'saveRate',
      'keyup @ui.rateInput': 'onRateChange',
    },
    initialize: function (options) {
      this.options = options;
      this.model.set('nextMasterFinalNet', this.model.get('currentMasterFinalNet'));
      this.model.set('currentRate', this.model.get('rate'));
      this.model.set('budgetFinalNet', this.model.get('currentMasterFinalNet') * this.model.get('rate'));
    },
    onRender: function () {
      this.ui.rateInput.autoNumeric('init', {
        aSep: '.',
        aDec: ',',
        mDec: '10',
      });

      if (this.model.get('hasManualRate')) {
        this.ui.updatedAtText.hide();
      }
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    updateMasterFinalNet: function (rate) {
      var nextRate = rate;
      if (rate === null) {
        nextRate = this.model.get('defaultRate');
      }
      var currentMasterFinalNet = this.model.get('currentMasterFinalNet');
      var invoiceBudgetValue = this.model.get('invoiceBudgetValue');
      var currentRate = this.model.get('currentRate');
      var nextMasterFinalNet = currentMasterFinalNet - invoiceBudgetValue
        * (1 / currentRate - 1 / nextRate);
      var formattedFinalNet = numeral(nextMasterFinalNet).format('0,0');

      this.ui.nextMasterFinalNet.html(formattedFinalNet);
    },
    onRateChange: function () {
      if (this.ui.rateInput.val() === '') {
        this.updateMasterFinalNet(null);
        this.model.set('rate', null);
      } else {
        var rate = numeral().unformat(this.ui.rateInput.val());
        var prevRate = this.model.get('rate');
        if (rate !== 0 && prevRate !== rate) {
          this.updateMasterFinalNet(rate);
          this.model.set('rate', rate);
        }
      }
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    saveRate: function (e) {
      e.preventDefault();
      var invoiceView = this.options.parent;
      var invoiceModel = invoiceView.model;
      var invoiceRateChanged = this.model.get('rate') !== invoiceModel.get('exchange_rate');
      if (!this.ui.saveButton.hasClass('disabled') && invoiceRateChanged) {
        this.ui.cancelButton.addClass('disabled');
        this.ui.saveButton.addClass('disabled');
        invoiceModel.set({
          exchange_rate: this.model.get('rate'),
          date: moment(invoiceModel.get('date')).format(),
        });
        var currentMasterFinalNet = this.model.get('currentMasterFinalNet');
        var invoiceBudgetValue = this.model.get('invoiceBudgetValue');
        var nextMasterFinalNet = currentMasterFinalNet - invoiceBudgetValue;
        this.model.set('nextMasterFinalNet', nextMasterFinalNet);
        this.options.parent.model.get('budget').final_net_price = nextMasterFinalNet;
        invoiceView.saveExchangeRate();
      }
      this.destroy();
    },
    closeModal: function (e) {
      e.preventDefault();
      // rimuovo l'attributo list in modo che riaprendo il modal si carichi
      this.destroy();
    },
  });

  this.MissingIntercompanyConnectionModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceMissingIntercompanyConnectionModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      cancelButton: '[data-action="modalClose"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    closeModal: function (e) {
      e.preventDefault();
      // rimuovo l'attributo list in modo che riaprendo il modal si carichi
      this.destroy();
    },
  });

  this.MissingClientConnectionModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceMissingClientConnectionModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      cancelButton: '[data-action="modalClose"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    closeModal: function (e) {
      e.preventDefault();
      // rimuovo l'attributo list in modo che riaprendo il modal si carichi
      this.destroy();
    },
  });

  this.ConfirmModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceConfirmModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
      deleteButton: '[data-action="deleteInvoice"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
      'click @ui.deleteButton': 'deleteInvoice',
    },
    initialize: function (options) {
      this.options = options;
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    deleteInvoice: function (e) {
      e.preventDefault();
      if (!this.ui.deleteButton.hasClass('disabled')) {
        this.ui.cancelButton.addClass('disabled');
        this.ui.deleteButton.addClass('disabled');
        this.options.parent.trigger('finance:invoice:deleteInvoice', this);
      }
    },
    closeModal: function (e) {
      e.preventDefault();
      // rimuovo l'attributo list in modo che riaprendo il modal si carichi
      this.destroy();
    },
  });

  this.ConfirmExportModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceConfirmExportModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
      exportButton: '[data-action="exportModule"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
      'click @ui.exportButton': 'exportModule',
    },
    initialize: function (options) {
      this.options = options;
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    exportModule: function (e) {
      e.preventDefault();
      if (!this.ui.exportButton.hasClass('disabled')) {
        this.ui.cancelButton.addClass('disabled');
        this.ui.exportButton.addClass('disabled');

        this.ui.feedbackField.text('Exporting...').addClass('messageModal__feedback--success');

        var that = this;
        var sendRequest = Wethod.request('get:invoice:export:email');
        $.when(sendRequest).done(function () {
          that.destroy();
        });
      }
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.DownloadExportModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceDownloadExportModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    initialize: function (options) {
      this.options = options;
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.EditDetailsModalView = Marionette.ItemView.extend({
    template: '#financeInvoiceEditDetailsModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
      saveButton: '[data-action="modalSave"]',
      cigInput: '[data-action="cigInput"]',
      befInput: '[data-action="befInput"]',
      purchaseOrderInput: '[data-action="purchaseOrderInput"]',
      stampTaxInput: '[data-action="stampTaxInput"]',
      statementInput: '[data-action="statementInput"]',
      costAccountingInput: '[data-action="costAccountingInput"]',
      vatRateInput: '[data-action="vatRateInput"]',
      paymentTermInput: '[data-action="paymentTermInput"]',
      creditNoteInput: '[data-action="creditNoteInput"]',
      payDateInput: '[data-region="payDateField"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
      'click @ui.saveButton': 'saveDetails',
      'change @ui.cigInput': 'onCigChange',
      'change @ui.befInput': 'onBefChange',
      'change @ui.purchaseOrderInput': 'onPurchaseOrderChange',
      'click @ui.stampTaxInput': 'onStampTaxChange',
      'change @ui.statementInput': 'onStatementChange',
      'change @ui.costAccountingInput': 'onCostAccountingChange',
      'change @ui.vatRateInput': 'onVatRateChange',
      'change @ui.paymentTermInput': 'onPaymentTermChange',
      'change @ui.creditNoteInput': 'onCreditNoteChange',
      'change @ui.payDateInput': 'onPayDateChange',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      var that = this;
      $(this.ui.payDateInput).datepicker({
        constrainInput: true,
        firstDay: 1,
        format: 'dd/mm/y',
        altFormat: 'dd/mm/y',
        dateFormat: 'yy-mm-dd',
        defaultDate: moment(that.model.get('pay_date'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        onSelect: function (dateText) {
          that.model.set('pay_date', moment(dateText).format('DD/MM/YYYY'));
          $(this).val(moment(dateText).format('DD/MM/YYYY'));
        },
      });
    },
    getTemplate: function () {
      if (
        this.options.parent.model.isNew()
        || (
          this.options.parent.hasPermissions() && !this.options.parent.isLockedDueToDataFreezing()
        )
      ) {
        return '#financeInvoiceEditDetailsModalTemplate';
      }
      return '#financeInvoiceEditDetailsBlockedModalTemplate';
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    onCigChange: function () {
      var cig = this.ui.cigInput.val();
      var prevCig = this.model.get('cig');
      if (prevCig !== cig) {
        this.model.set('cig', cig);
      }
    },
    onBefChange: function () {
      var bef = this.ui.befInput.val();
      var prevBef = this.model.get('bef');
      if (prevBef !== bef) {
        this.model.set('bef', bef);
      }
    },
    onPurchaseOrderChange: function () {
      var purchaseOrder = this.ui.purchaseOrderInput.val();
      var prevPurchaseOrder = this.model.get('purchase_order');
      if (prevPurchaseOrder !== purchaseOrder) {
        this.model.set('purchase_order', purchaseOrder);
      }
    },
    onStampTaxChange: function () {
      var stampTax = this.ui.stampTaxInput.is(':checked');
      var prevStampTax = this.model.get('has_stamp_tax');
      if (prevStampTax !== stampTax) {
        this.model.set('has_stamp_tax', stampTax);
      }
    },
    onStatementChange: function () {
      var statement = this.ui.statementInput.val();
      var prevStatement = this.model.get('statement');
      if (prevStatement !== statement) {
        this.model.set('statement', statement);
      }
    },
    onCostAccountingChange: function () {
      var costAccounting = this.ui.costAccountingInput.val();
      var prevCostAccounting = this.model.get('cost_accounting');
      if (prevCostAccounting !== costAccounting) {
        this.model.set('cost_accounting', costAccounting);
      }
    },
    onVatRateChange: function () {
      var vatRate = this.ui.vatRateInput.val();

      if (vatRate === 'go-to-settings') {
        this.ui.vatRateInput.val('');
        Wethod.navigate('settings/company/vat-rates', { trigger: true });
      } else {
        vatRate = parseInt(vatRate);
        vatRate = vatRate != null && !Number.isNaN(vatRate) ? vatRate : null;
        var prevVatRate = this.model.get('vat_rate');
        if (prevVatRate !== vatRate) {
          this.model.set('vat_rate', vatRate);
        }
      }
    },
    onPaymentTermChange: function () {
      var paymentTerm = this.ui.paymentTermInput.val();

      if (paymentTerm === 'go-to-settings') {
        this.ui.paymentTermInput.val('');
        Wethod.navigate('settings/company/payment-terms', { trigger: true });
      } else {
        paymentTerm = parseInt(paymentTerm);
        paymentTerm = paymentTerm != null && !Number.isNaN(paymentTerm) ? paymentTerm : null;
        var prevPaymentTerm = this.model.get('payment_term');
        if (prevPaymentTerm !== paymentTerm) {
          this.model.set('payment_term', paymentTerm);
        }
      }
    },
    onCreditNoteChange: function () {
      var creditNote = this.ui.creditNoteInput.val();
      var prevCreditNote = this.model.get('credit_note_of');
      if (prevCreditNote !== creditNote) {
        this.model.set('credit_note_of', creditNote);
      }
    },
    onPayDateChange: function () {
      var payDate = this.ui.payDateInput.val();
      var prevPayDate = this.model.get('pay_date');

      if (!payDate) {
        payDate = null;
      }

      if (prevPayDate !== payDate) {
        this.model.set('pay_date', payDate);
      }
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    /**
     * Check whether the given date format is valid
     * The accepted format is 'D/M/YYYY' or the server standard 'YYYY-MM-DD',
     * this includes both days with one or two digits (ex. 1 or 01)
     * and months with one or two digits (ex. 2 or 02)
     * @param date
     * @returns {boolean}
     */
    isDateFormatValid: function (date) {
      return moment(date, ['D/M/YYYY', 'YYYY-MM-DD'], true).isValid();
    },
    isValid: function () {
      var payDate = this.model.get('pay_date');
      var isPaid = this.model.get('is_paid');
      var errors = [];
      if (isPaid) {
        if (_.isNull(payDate)) {
          errors.push('The payment date must NOT be empty');
        }
        if (!this.isDateFormatValid(payDate)) {
          errors.push('The payment date format should be "DD/MM/YYYY"');
        }
      }

      this.model.set('errors', errors);
      return errors.length === 0;
    },
    saveDetails: function (e) {
      e.preventDefault();
      var invoiceView = this.options.parent;
      var invoiceModel = invoiceView.model;

      if (!this.ui.saveButton.hasClass('disabled')) {
        if (this.isValid()) {
          // Avoid further actions and proceed with the saving
          this.ui.cancelButton.addClass('disabled');
          this.ui.saveButton.addClass('disabled');

          invoiceModel.set({
            has_stamp_tax: this.model.get('has_stamp_tax'),
            cig: this.model.get('cig'),
            bef: this.model.get('bef'),
            purchase_order: this.model.get('purchase_order'),
            invoice_statement: this.model.get('statement'),
            cost_accounting: this.model.get('cost_accounting'),
            vat_rate: this.model.get('vat_rate') ? {
              id: this.model.get('vat_rate'),
            } : null,
            payment_term: this.model.get('payment_term') ? {
              id: this.model.get('payment_term'),
            } : null,
            credit_note_of: this.model.get('credit_note_of'),
            pay_date: this.model.get('pay_date') ? moment(this.model.get('pay_date'), 'DD/MM/YYYY') : null,
          });

          invoiceView.saveInvoice(e);

          this.destroy();
        } else {
          // Show the validation error and stop the saving
          this.ui.feedbackField
            .text(this.model.get('errors')[0])
            .addClass('messageModal__feedback--error').show();
        }
      }
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.ErrorModalView = Marionette.ItemView.extend({
    template: '#errorModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalEl: '.modalStructure',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    initialize: function (options) {
      this.options = options;
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalEl.width()) / 2;
      var posTop = (contextH - this.ui.modalEl.height()) / 2;

      this.ui.modalEl.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.FilesModalView = Marionette.LayoutView.extend({
    template: '#financeInvoiceFilesModalTemplate',
    className: 'messageModalPageOverlay',
    ui: {
      modalBody: '[data-region="modalBody"]',
      feedbackField: '[data-region="modalFeedback"]',
      cancelButton: '[data-action="modalClose"]',
    },
    events: {
      'click @ui.cancelButton': 'closeModal',
    },
    modelEvents: {
      'change:files': 'showFilesList', // Reload the list of files when it changes
    },
    regions: {
      fileSectionRegion: '[data-region="invoiceFileSection"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onDomRefresh: function () {
      this.placeModal();
    },
    onAttach: function () {
      this.showFilesList();
    },
    showFilesList: function () {
      var canEdit = this.options.parent.hasPermissions();
      // Show React FileSection component to handle file list and actions (delete, download, upload)
      var fileSection = React.createElement(InvoicesFileSection, {
        item: this.options.parent.model.toJSON(),
        canDelete: canEdit,
        canUpload: canEdit,
        canDownload: true, // Every user who can see the invoice can see the attached files
        onUploadSuccess: this.onFileUpload.bind(this),
        onDeleteSuccess: this.onFileDelete.bind(this),
        showCannotUploadFileModal: this.onUploadError.bind(this),
        showCannotDownloadFileModal: this.onDownloadError.bind(this),
        title: '',
      });
      var container = document.querySelector(this.fileSectionRegion.el);
      ReactDOM.render(fileSection, container);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.modalBody.width()) / 2;
      var posTop = (contextH - this.ui.modalBody.height()) / 2;

      this.ui.modalBody.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    onFileUpload: function (invoice, file) {
      // Add file to the list
      var files = this.model.get('files').concat(file);

      this.updateFileList(files);
    },
    onFileDelete: function (invoice, file) {
      // Remove the file from the list
      var files = this.model.get('files').filter(function (modelFile) { return modelFile.id !== file.id; });

      this.updateFileList(files);
    },
    onDownloadError: function (error) {
      var downloadError = error && error.length ? error[0] : undefined;
      this.options.parent.openFileDownloadErrorModal(downloadError);
    },
    onUploadError: function (error) {
      var uploadError = error && error.length ? error[0] : undefined;
      this.options.parent.openFileUploadErrorModal(uploadError);
    },
    updateFileList: function (files) {
      this.options.parent.model.set('files', files); // Update invoice model
      this.model.set('files', files); // Update modal list of files
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.DownloadErrorModalView = Marionette.LayoutView.extend({
    template: '#financeInvoiceFileErrorModalTemplate',
    regions: {
      modalRegion: '[data-region="modalBody"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      var downloadErrorModal = React.createElement(DownloadErrorModal, {
        onCancelClick: this.closeModal.bind(this),
        data: this.model.get('error'),
      });
      var container = document.querySelector(this.modalRegion.el);
      ReactDOM.render(downloadErrorModal, container);
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.UploadErrorModalView = Marionette.LayoutView.extend({
    template: '#financeInvoiceFileErrorModalTemplate',
    regions: {
      modalRegion: '[data-region="modalBody"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      var downloadErrorModal = React.createElement(UploadErrorModal, {
        onCancelClick: this.closeModal.bind(this),
        data: this.model.get('error'),
      });
      var container = document.querySelector(this.modalRegion.el);
      ReactDOM.render(downloadErrorModal, container);
    },
    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
