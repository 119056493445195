'use strict';

var FiscalYearService = require('../../../services/FiscalYearService');

Wethod.module('View.MonthPicker', function (Autocomplete, Wethod, Backbone, Marionette) {
  /**
   * Returns a collection of all months for the given year.
   * @param year
   * @returns {Array}
   */
  this.getMonths = function (year) {
    var fiscalYearService = new FiscalYearService();
    var months = [];
    for (var i = 0; i < 12; i++) {
      var startDate = fiscalYearService.getFiscalYearStartDate(year);
      var monthAndYear = startDate.add(i, 'month');
      var month = {
        value: monthAndYear.format('YYYY-MM'),
        name: monthAndYear.format('MMMM'),
      };
      months.push(month);
    }
    return months;
  };

  /**
   * Get a collection of years, between start and end. Each year contains all its months.
   * @param start
   * @param end
   */
  this.getYearsCollection = function (start, end) {
    var fiscalYearService = new FiscalYearService();
    start = parseInt(start);
    end = parseInt(end);
    var period = end - start;
    var years = [];
    for (var i = 0; i < period; i++) {
      var year = {
        name: fiscalYearService.getYearDynamically(start + i),
        months: this.getMonths(start + i),
      };
      years.push(year);
    }
    return years;
  };

  this.MonthView = Marionette.ItemView.extend({
    template: '#pipelineMonthInputMonthTemplate',
    className: 'month-input__month',
    events: {
      click: 'handleClick',
    },
    handleClick: function () {
      this.triggerMethod('monthPicker:month:selected', this.model.get('value'));
    },
  });

  this.MonthsView = Marionette.CollectionView.extend({
    className: 'month-input__months',
    childView: this.MonthView,
    childEvents: {
      'monthPicker:month:selected': 'onMonthSelected',
    },
    onMonthSelected: function (childView, value) {
      this.trigger('monthPicker:month:selected', value);
    },
  });

  this.YearView = Marionette.LayoutView.extend({
    template: '#pipelineMonthInputYearTemplate',
    className: 'month-input__year',
    ui: {
      label: '.month-input__year-label',
    },
    regions: {
      months: '[data-region="months"]',
    },
    childEvents: {
      'monthPicker:month:selected': 'onMonthSelected',
    },
    initialize: function () {
      this.monthsCollection = new Backbone.Collection(this.model.get('months'));
    },
    onBeforeShow: function () {
      var monthsView = new Wethod.View.MonthPicker.MonthsView({
        collection: this.monthsCollection,
      });
      this.getRegion('months').show(monthsView);
    },
    pushLabel: function () {
      this.ui.label.addClass('push');
    },
    unpushLabel: function () {
      this.ui.label.removeClass('push');
    },
    stretch: function () {
      this.$el.addClass('stretch');
    },
    unstretch: function () {
      this.$el.removeClass('stretch');
    },
    onMonthSelected: function (childView, value) {
      this.trigger('monthPicker:month:selected', value);
    },
  });

  this.YearsView = Marionette.CollectionView.extend({
    className: 'month-input__years',
    childView: this.YearView,
    initialize: function (options) {
      var start = parseInt(options.start);
      var years = Wethod.View.MonthPicker.getYearsCollection(start - 10, start + 10);
      this.collection = new Backbone.Collection(years);
    },
    onShow: function () {
      // Height calculation on hidden objects doesn't work properly
      // this.monthHeight = $('.month-input__year-label').outerHeight();
      this.monthHeight = 30;
      this.yearsLength = 12;
      this.labelEls = this.$el.find('.month-input__year-label');
      this.yearEls = this.$el.find('.month-input__year');
      this.yearEls.first().addClass('stretch');
      this.labelEls.first().addClass('push');
      this.$el.scroll(this.handleScroll.bind(this));
    },
    scrollYears: function (amount) {
      this.$el.scrollTop(amount);
    },
    childEvents: {
      'monthPicker:month:selected': 'onMonthSelected',
    },
    onMonthSelected: function (childView, value) {
      this.trigger('monthPicker:month:selected', value);
    },
    handleScroll: function () {
      var scroll = this.$el.scrollTop();
      var offset = scroll / ((this.yearsLength + 1) * this.monthHeight);
      this.labelEls.removeClass('push');
      this.yearEls.removeClass('stretch');
      if (Number.isInteger(offset)) {
        this.labelEls.eq(offset).addClass('push');
        this.yearEls.eq(offset).addClass('stretch');
      } else {
        this.labelEls.eq(Math.floor(offset)).addClass('push');
        this.yearEls.eq(Math.floor(offset)).addClass('stretch');
      }
    },
  });
});
