/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,react/jsx-no-bind */
const React = require('react');
const Hour = require('../containers/CalendarHour');
const PlanBlock = require('../containers/PlanBlock');
const ProjectSelector = require('../containers/ProjectSelector');
const DateService = require('../../../../../../services/DateService');

const CalendarRowDay = ({
  plans, zoom, day, prevDayPlans, person, highlightWeekend, isHoliday = false,
}) => {
  /**
   * Transform the given project into an array of hours.
   * @param project
   * @param length
   * @returns {[]}
   */
  const getProjectHours = (project, length) => {
    const hours = [];
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        hours.push({
          project,
          first: true,
        });
      } else {
        hours.push({ project });
      }
    }
    return hours;
  };

  /**
   * Transform the given plans into an array of hours.
   * @param plans
   * @returns {[]}
   */
  const getHours = (plans) => {
    let hours = [];
    for (let i = 0; i < plans.length; i++) {
      const project = plans[i].project_id;
      const length = plans[i].amount;
      hours = hours.concat(getProjectHours(project, length));
    }
    return hours;
  };

  /**
   * How many hours in the day before the first hour of the given project.
   * Return null if there are no projectId's hours in the given hours.
   * @param hours
   * @param projectId
   */
  const getPrevHours = (hours, projectId) => {
    for (let i = 0; i < hours.length; i++) {
      if (hours[i].project === projectId) {
        return i;
      }
    }
    return null;
  };

  /**
   * Return the unique ID for the given plan.
   * Each plan describes a single (project, allocation request) pair. This means that could exist
   * different plans about the same project only if they represents different allocation request
   * for that project.
   * @param plan
   * @return {string} A safe unique ID in the form {project_id}_{request_id}
   */
  const getUniqueIdForPlan = (plan) => {
    const allocationRequestId = plan.allocation_request_id;
    const requestKeySegment = allocationRequestId === null ? '' : `_${allocationRequestId}`;
    // Key must be unique even between 2 plans for same project but referred by diffeerent allocation requests
    return `${plan.project_id}${requestKeySegment}`;
  };

  /**
   * Return borders for plan due to prevDayPlan.
   */
  const getBordersLeft = (plan) => {
    const prevDayHours = getHours(prevDayPlans);
    const hours = getHours(plans);
    const projectId = plan.project_id;
    const hourHeight = 54 / 8;
    const prevDayPlan = prevDayPlans
      .filter((prevDayPlan) => getUniqueIdForPlan(prevDayPlan) === getUniqueIdForPlan(plan))[0];

    let borders = [];
    if (!prevDayPlan) {
      const border = {
        top: 0,
        height: 'all',
      };
      borders.push(border);
    } else {
      const planTop = getPrevHours(hours, projectId);
      const planHeight = plan.amount;
      const prevPlanTop = getPrevHours(prevDayHours, projectId);
      const prevPlanHeight = prevDayPlan.amount;

      const upperBorder = {
        top: 0,
        height: prevPlanTop - planTop,
      };
      const lowerBorder = {
        top: prevPlanTop + prevPlanHeight - planTop,
        height: (planTop + planHeight) - (prevPlanTop + prevPlanHeight),
      };

      borders = [upperBorder, lowerBorder];
    }
    if (borders.length > 1) {
      borders = borders
        .filter((border) => border.height > 0) // borders with no height are useless
        .map((border) => ({
          top: border.top * hourHeight,
          height: border.height * hourHeight,
        })); // transform in px
    }

    return borders;
  };

  /**
   * Returns a list of PlanBlock components for this day.
   * @returns {*}
   */
  const getBlocksFragment = () => {
    // Distance from top used to place next block
    let prevHours = 0;

    return plans.map((plan) => {
      const block = (
        <PlanBlock
          key={getUniqueIdForPlan(plan)}
          project={plan}
          top={prevHours * (54 / 8)}
          bordersLeft={getBordersLeft(plan)} />
      );
      prevHours += plan.amount;
      return block;
    });
  };

  /**
   * Returns a list of Hour components for this day.
   * @returns {*}
   */
  const getHoursFragment = () => {
    const hours = getHours(plans);
    return hours.map((hour, index) => {
      const topProject = index - 4 >= 0 ? hours[index - 4].project : null; // se ha sopra qualcosa, allora ha senso capire cosa
      const leftProject = index % 4 === 0 ? null : hours[index - 1].project;
      return (
        <Hour
          key={index}
          project={hour.project}
          leftProject={leftProject}
          topProject={topProject}
          first={hour.first === true} />
      );
    });
  };

  const getFragment = () => {
    if (zoom === 'hour') {
      return getHoursFragment();
    }
    return getBlocksFragment();
  };

  const onClick = (e, callback) => {
    e.stopPropagation();
    callback();
  };

  const getDayClassName = () => {
    let name = 'planning-calendar__row-day';
    if (DateService.isWeekend(day) && highlightWeekend) {
      name += ' planning-calendar__row-day--weekend-highlight';
    } else if (isHoliday) {
      name += ' planning-calendar__row-day--holiday';
    }
    return name;
  };

  return (
    <ProjectSelector days={[{
      date: day,
      plans,
    }]}
      person={person}>
      {({ selectProject }) => (
        <div className={getDayClassName()} onClick={(e) => onClick(e, selectProject)}>
          {getFragment()}
        </div>
      )}
    </ProjectSelector>
  );
};

module.exports = CalendarRowDay;
