const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  year: state.year,
  isWaiting: state.waitingFor.length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  changeYear: (year) => dispatch(actions.changeYear(year)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
