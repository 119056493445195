/* eslint-disable camelcase,no-unused-vars,no-use-before-define,array-callback-return,default-case,
no-shadow,no-loop-func */

'use strict';

Wethod.module('ProjectCharterApp', function (ProjectCharterApp, Wethod, Backbone, Marionette, $, _, Header) {
  var program_id;
  var moduleLayout;
  var planningLayout;
  var resourceTypeCollection = {};
  var projectsListCollection = {};
  var resourcesListCollection = {};
  var newPlanningData;
  var calendarMonth;
  var calendarFirsthMonth;
  var calendarLastMonth;
  var planningSlide;
  var excessProject;

  var resetData = function () {
    program_id = undefined;
    moduleLayout = undefined;
    planningLayout = undefined;
    resourceTypeCollection = {};
    projectsListCollection = {};
    resourcesListCollection = {};
    newPlanningData = undefined;
    calendarMonth = undefined;
    calendarFirsthMonth = undefined;
    calendarLastMonth = undefined;
    planningSlide = undefined;
    excessProject = undefined;
  };

  /**
   * [ aggiorna i dati relativi al progetto ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.updateProgram = function (viewObj) {
    var managerId;

    if (_.isUndefined(viewObj.model.get('manager').id)) {
      managerId = viewObj.model.get('manager');
    } else {
      managerId = viewObj.model.get('manager').id;
    }

    var programModel = new ProjectCharterApp.ProgramModel({
      id: viewObj.model.get('id'),
      name: viewObj.model.get('name'),
      description: viewObj.model.get('description'),
      manager: managerId,
    });
    programModel.validationError = programModel.getValidationErrors();
    if (programModel.isValid()) {
      var updateProjecterChartRequest = Wethod.request('persist:projectCharter', programModel);
      $.when(updateProjecterChartRequest).done(function (projecterChartModelResponse) {
        // console.log('program updated');
      });
    } else {
      _.each(programModel.validationError, function (error) {
        // console.log('updateProgram -> error', error);
      });
    }
  };

  /**
   * [aggiunge un modello per l'inserimento di un progetto nell collection dei progetti]
   * @return {[type]} [description]
   */
  this.addResource = function () {
    var addModel = new ProjectCharterApp.ProjectCharterResourceModel();
    addModel.set({ new: true });
    resourcesListCollection.add(addModel, { at: 0 });
  };

  this.persistNewResource = function (viewObj) {
    viewObj.model.unset('new');
    viewObj.model.set({
      sort: 0,
      program: program_id,
    });

    if (!_.isNull(viewObj.model.get('content'))) {
      // se non presente aggiungo http://
      if (viewObj.model.get('content').indexOf('http') === -1) {
        var link = 'http://' + viewObj.model.get('content');
        viewObj.model.set({ content: link });
      }
    }

    if (viewObj.model.isValid()) {
      var insertProjectRequest = Wethod.request('projectCharter:insert:resource', viewObj.model);
      $.when(insertProjectRequest).done(function (insertModelResponse) {
        // aggiungo il nuovo modello
        viewObj.render();
        $('[data-action="addResource"]').removeClass('disabled');
      });
    } else {
      _.each(viewObj.model.validationError, function (error) {
        // console.log('error', error);
      });
    }
  };

  /**
   * [ render resource list type ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.renderResourceTypeList = function (viewObj) {
    var resourceTypeListTemplate = new ProjectCharterApp.ProjectCharterResourcesTypeCollectionView({
      collection: resourceTypeCollection,
      childViewOptions: {
        parentView: viewObj,
      },
    });
    viewObj.ui.resourceTypeListWrapperEl.html(resourceTypeListTemplate.render().$el);
  };

  /**
   * [ elimina una risorsa associata al programma ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.removeProgramResource = function (viewObj) {
    var insertProjectRequest = Wethod.request('projectCharter:delete:resource', viewObj.model);
    $.when(insertProjectRequest).done(function (insertModelResponse) {
      viewObj.destroy();
    });
  };

  /**
   * [aggiunge un modello per l'inserimento di un progetto nell collection dei progetti]
   * @return {[type]} [description]
   */
  this.addProject = function () {
    var addModel = new ProjectCharterApp.ProjectCharterProjectModel();
    addModel.set({ new: true });
    projectsListCollection.add(addModel, { at: 0 });
  };

  /**
   * [salva nel db il nuovo progetto]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.persistNewProject = function (viewObj) {
    viewObj.model.unset('new');
    viewObj.model.set({
      sort: 0,
      program: program_id,
    });
    viewObj.model.validate();

    if (viewObj.model.isValid()) {
      var insertProjectRequest = Wethod.request('projectCharter:insert:project', viewObj.model);
      $.when(insertProjectRequest).done(function (insertModelResponse) {
        // aggiungo il nuovo modello
        viewObj.render();
        $('[data-action="addProject"]').removeClass('disabled');
        // TODO update economics

        // aggiorno GANTT (planning)
        // loading planning
        var planningLoadingTemplate = new ProjectCharterApp
          .ProjectCharterLoadingPlanningTemplateView();
        moduleLayout.planning.show(planningLoadingTemplate);// render planning loading view

        var projectCharterPlanningRangeRequest = Wethod.request('projectCharter:get:plannings:range', program_id);
        $.when(projectCharterPlanningRangeRequest).done(function (planningRangeModelResponse) {
          planningCalendar(planningRangeModelResponse.get('from'), planningRangeModelResponse.get('to'));
        });

        // aggiorno ECONOMICS
        renderCharterEconomics();
      });
    } else {
      _.each(viewObj.model.validationError, function (error) {
        // console.log('error', error);
      });
    }
  };

  /**
   * [ dissocia un progetto da un programma ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.removeProgramProject = function (viewObj) {
    var progectId = viewObj.model.get('project').id;
    var removeProjectRequest = Wethod.request('projectCharter:delete:project', viewObj.model);
    $.when(removeProjectRequest).done(function (removeModelResponse) {
      if (!_.isUndefined(removeModelResponse)) {
        // rimuovo il modello dalla collection globale
        resourcesListCollection.find(function (model) {
          if (!_.isUndefined(model)) {
            // TODO controllare bene il controllo
            if (!_.isUndefined(model.get('project')) && !_.isNull(model.get('project'))
              && model.get('project').id === progectId
            ) {
              model.destroy();
            }
          }
        });

        // cancello la view
        viewObj.destroy();

        // aggiorno GANTT (planning)
        var planningLoadingTemplate = new ProjectCharterApp
          .ProjectCharterLoadingPlanningTemplateView();
        moduleLayout.planning.show(planningLoadingTemplate);// render planning loading view

        var projectCharterPlanningRangeRequest = Wethod.request('projectCharter:get:plannings:range', program_id);
        $.when(projectCharterPlanningRangeRequest).done(function (planningRangeModelResponse) {
          planningCalendar(planningRangeModelResponse.get('from'), planningRangeModelResponse.get('to'));
        });

        // aggiorno ECONOMICS
        renderCharterEconomics();
      }
    });
  };

  /**
   * [ elimina un programma ]
   * @param  {[type]} viewObj [description]
   * @return {[type]}         [description]
   */
  this.deleteProgram = function (viewObj) {
    var programId = viewObj.model.get('id');
    var programModel = new this.ProgramModel({ id: programId });
    programModel.destroy();
    // cancello la view
    viewObj.destroy();
  };

  /**
   * [autocompletamento progetto]
   * @param  {[type]} viewObj [description]
   * @param  {[type]} text    [description]
   * @return {[type]}         [description]
   */
  this.renderProjectsHints = function (viewObj, text) {
    var reg = new RegExp(' ', 'g');
    text = text.replace(reg, ',');

    var params = {
      whatSearch: (text === '') ? undefined : text,
    };
    // loading
    var searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
    viewObj.ui.projectHintEl.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('projectCharter:get:project:hints', params);
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        if (autocompleteResponse === 'loading') {
          searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
          viewObj.ui.projectHintEl.html(searchFeedbackTemplate.render().$el);
        } else {
          var projectHintsTemplate = new ProjectCharterApp.HintsCollectionView({
            collection: autocompleteResponse,
            childViewOptions: {
              parentView: viewObj,
            },
          });
          viewObj.ui.projectHintEl.html(projectHintsTemplate.render().$el);
        }
      } else {
        // not found
        searchFeedbackTemplate = new ProjectCharterApp.SearchNotFoundTemplateView();
        viewObj.ui.projectHintEl.html(searchFeedbackTemplate.render().$el);
      }
    });
  };

  /**
   * [autocompletamento progetti associati al programma]
   * @param  {[type]} viewObj [description]
   * @param  {[type]} text    [description]
   * @return {[type]}         [description]
   */
  this.renderProgramProjectsHints = function (viewObj, text) {
    var reg = new RegExp(' ', 'g');
    text = text.replace(reg, ',');

    var params = {
      program_id: program_id,
      whatSearch: (text === '') ? undefined : text,
    };
    // loading
    var searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
    viewObj.ui.programProjectHintEl.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('projectCharter:get:program:project:hints', params);
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        if (autocompleteResponse === 'loading') {
          searchFeedbackTemplate = new ProjectCharterApp.SearchLoadingTemplateView();
          viewObj.ui.programProjectHintEl.html(searchFeedbackTemplate.render().$el);
        } else {
          var programProjectHintsTemplate = new ProjectCharterApp.HintsCollectionView({
            collection: autocompleteResponse,
            childViewOptions: {
              parentView: viewObj,
            },
          });
          viewObj.ui.programProjectHintEl.html(programProjectHintsTemplate.render().$el);
        }
      } else {
        // not found
        searchFeedbackTemplate = new ProjectCharterApp.SearchNotFoundTemplateView();
        viewObj.ui.programProjectHintEl.html(searchFeedbackTemplate.render().$el);
      }
    });
  };

  this.moveMonth = function (action) {
    var alreadyInsert = function (collection, day) {
      var model = collection.findWhere({ first_day: day });
      if (_.isUndefined(model)) {
        return false;
      }
      return model;
    };

    var setModel = function (data) {
      var model = new ProjectCharterApp.MonthModel();
      model.set({
        name: data.format('MMM') + ' / ' + data.format('YY'),
        number: data.format('MM'),
        first_day: data.format('YYYY') + '-' + data.format('MM') + '-01',
      });

      return model;
    };

    var findMonths = function (startMonth, collection, monthNumber, direction) {
      var start = moment(startMonth);
      var model;

      for (var i = 0; i < monthNumber; i++) {
        switch (direction) {
          case 'forward':
            start.add(1, 'months');
            break;
          case 'back':
            start.subtract(1, 'months');
            break;
        }

        model = collection.findWhere({ first_day: start.format('YYYY-MM-DD') });

        if (_.isUndefined(model)) {
          var requestData = {
            from: start.format('YYYY-MM-DD'),
            to: (direction === 'forward') ? start.add(monthNumber - (i + 1), 'months')
              .format('YYYY-MM-DD') : start.subtract(monthNumber - (i + 1), 'months')
              .format('YYYY-MM-DD'),
          };
          return requestData;
        }
      }

      return model.get('first_day');
    };

    // TODO fare quì la chiamata per avere il mese successivo e passare i dati tramite l'evento
    var ProjectCharterPlanningRequest;
    var newMonth;
    var index;
    var findModel;
    var monthsInterval;
    var
      options;
    switch (action) {
      case 'previous':
        monthsInterval = findMonths(calendarFirsthMonth, calendarMonth, planningSlide, 'back');

        if (_.isObject(monthsInterval)) {
          // feedback loading
          $('[data-action="previousMonth"]').hide();
          $('[data-region="previousMonthLoading"]').show();

          options = {
            programId: program_id,
            dateStart: monthsInterval.from,
            dateEnd: monthsInterval.to,
          };
          ProjectCharterPlanningRequest = Wethod.request('projectCharter:get:plannings', options);
          $.when(ProjectCharterPlanningRequest).done(function (ProjectCharterPlanningResponse) {
            newPlanningData = ProjectCharterPlanningResponse;
            // aggiungo un mese (intestazione)
            var index = moment(monthsInterval.from);

            while (index.diff(moment(monthsInterval.to).subtract(1, 'months')) !== 0) {
              newMonth = setModel(index);
              newMonth.set({ action: action });

              if (index.diff(moment(monthsInterval.to)) === 0) {
                newMonth.set({ last: true });
              }

              calendarMonth.add(newMonth, { at: 0 });

              index.subtract(1, 'month');
            }

            // sistemo gli indici
            calendarFirsthMonth = index.add(1, 'months').format('YYYY-MM-DD');
            calendarLastMonth = moment(calendarLastMonth)
              .subtract(planningSlide, 'months')
              .format('YYYY-MM-DD');
          });
        } else {
          calendarFirsthMonth = monthsInterval;
          calendarLastMonth = moment(calendarLastMonth)
            .subtract(planningSlide, 'months')
            .format('YYYY-MM-DD');
          dispatcher.trigger('show:existent:prevous:month', planningSlide);
        }
        break;
      case 'next':

        monthsInterval = findMonths(calendarLastMonth, calendarMonth, planningSlide, 'forward');

        if (_.isObject(monthsInterval)) {
          // feedback loading
          $('[data-action="nextMonth"]').hide();
          $('[data-region="nextMonthLoading"]').show();

          options = {
            programId: program_id,
            dateStart: monthsInterval.from,
            dateEnd: monthsInterval.to,
          };
          ProjectCharterPlanningRequest = Wethod.request('projectCharter:get:plannings', options);
          $.when(ProjectCharterPlanningRequest).done(function (ProjectCharterPlanningResponse) {
            newPlanningData = ProjectCharterPlanningResponse;
            // aggiungo un mese (intestazione)
            var index = moment(monthsInterval.from);

            while (index.diff(moment(monthsInterval.to).add(1, 'months')) !== 0) {
              newMonth = setModel(index);
              newMonth.set({ action: action });

              if (index.diff(moment(monthsInterval.to)) === 0) {
                newMonth.set({ last: true });
              }

              calendarMonth.add(newMonth);
              index.add(1, 'month');
            }

            // sistemo gli indici
            calendarFirsthMonth = moment(calendarFirsthMonth)
              .add(planningSlide, 'months')
              .format('YYYY-MM-DD');
            calendarLastMonth = index.subtract(1, 'months').format('YYYY-MM-DD');
          });
        } else {
          calendarFirsthMonth = moment(calendarFirsthMonth)
            .add(planningSlide, 'months')
            .format('YYYY-MM-DD');
          calendarLastMonth = monthsInterval;
          dispatcher.trigger('show:existent:next:month', planningSlide);
        }
        break;
    }
  };

  this.addProjectMonth = function (date, model, projectId, action, empId) {
    if (!_.isUndefined(newPlanningData)) {
      var projectModel = _.findWhere(newPlanningData.models, { id: projectId });
      var datePartial = date.split('-');

      var month;
      var emp;
      if (!_.isUndefined(empId)) {
        emp = _.isUndefined(projectModel) ? undefined : _.findWhere(projectModel.get('details'), { id: empId });
        if (!_.isUndefined(emp)) {
          month = _.findWhere(emp.months, { month_date: datePartial[0] + '-' + datePartial[1] });
        } else {
          month = undefined;
        }
      } else {
        month = _.isUndefined(projectModel) ? undefined : _.findWhere(projectModel.get('aggregate'), { month_date: datePartial[0] + '-' + datePartial[1] });
      }

      var percentage;
      var newWeek;
      var
        week;
      if (!_.isUndefined(month)) {
        for (var j = 1; j <= howManyMonday(date); j++) {
          week = _.findWhere(month.weeks, { week_number: j });
          percentage = 100 / howManyMonday(date);

          if (_.isUndefined(week)) {
            newWeek = {
              percentage: percentage,
              type: 'empty',
              week_number: j,
            };
            month.weeks.push(newWeek);
          } else {
            week.percentage = percentage;
          }
        }
        month.weeks = _.sortBy(month.weeks, 'week_number');
      } else {
        month = {
          month_date: datePartial[0] + '-' + datePartial[1], // momentMonth.format('YYYY-MM'),
          weeks: [],
        };
        for (var k = 1; k <= howManyMonday(date); k++) {
          percentage = 100 / howManyMonday(date);
          newWeek = {
            percentage: percentage,
            type: 'empty',
            week_number: k,
          };
          month.weeks.push(newWeek);
        }
      }

      model.set({
        action: action,
        month_date: month.month_date,
        weeks: month.weeks,
      });
      model.unset('loading');
      model.trigger('model:changed');
    }
  };

  this.changeZoom = function (zoomLevel) {
    switch (zoomLevel) {
      case 'zoom12':
        planningCalendar(calendarFirsthMonth, moment(calendarFirsthMonth)
          .add(11, 'months')
          .format('YYYY-MM-DD'));
        break;
      case 'zoom8':
        planningCalendar(calendarFirsthMonth, moment(calendarFirsthMonth)
          .add(7, 'months')
          .format('YYYY-MM-DD'));
        break;
      case 'zoom4':
        planningCalendar(calendarFirsthMonth, moment(calendarFirsthMonth)
          .add(3, 'months')
          .format('YYYY-MM-DD'));
        break;
    }
  };

  var completeMonth = function (collection, monthDate) {
    // cerco il mese all'interno del progetto
    var month = _.isUndefined(collection) ? undefined
      : _.findWhere(collection, { month_date: monthDate });
    var percentage;
    var newWeek;
    var week;

    // calcolo in percentuale quanto vale ogni settimana
    percentage = 100 / howManyMonday(monthDate);

    // se trovo il mese
    if (!_.isUndefined(month)) {
      // scorro le settimane del mese
      for (var j = 1; j <= howManyMonday(monthDate); j++) {
        // cerco la settimana all'interno del mese del progetto
        week = _.findWhere(month.weeks, { week_number: j });

        // se non trovo la settimana all'interno del mese
        if (_.isUndefined(week)) {
          // assegno alla settimana la percentuale e il type empty
          newWeek = {
            percentage: percentage,
            type: 'empty',
            week_number: j,
          };
          // aggiungo la settimana al mese
          month.weeks.push(newWeek);
        } else {
          // se la settimana viene trovata il type è già impostato dal server
          // aggiungo la percentuale
          week.percentage = percentage;
        }
      }
      // ordino le settimane del mese
      month.weeks = _.sortBy(month.weeks, 'week_number');
    } else {
      // creo il mese mancante
      month = {
        month_date: monthDate,
        weeks: [],
      };

      // per ogni settimana del mese
      for (var k = 1; k <= howManyMonday(monthDate); k++) {
        // creo la settimana
        newWeek = {
          percentage: percentage,
          type: 'empty',
          week_number: k,
        };
        // aggiungo la settimana al mese
        month.weeks.push(newWeek);
      }
      // aggiungo il mese al progetto
      collection.push(month);
    }
  };

  var howManyMonday = function (date) {
    var monday = moment(date).startOf('month').day('Monday');
    var mondayCount = 0;

    if (monday.date() > 7) {
      monday.add(7, 'd');
    }

    var month = monday.month();
    while (month === monday.month()) {
      mondayCount += 1;
      monday.add(7, 'd');
    }
    return mondayCount;
  };

  /**
   * [ renderizza il planning con il numero di mesi selezionato ]
   * @param  {[type]} monthNumber [description]
   * @return {[type]}             [description]
   */
  var planningCalendar = function (fromDate, toDate) {
    var monthNumber;
    var monthDifference = moment(toDate).add(1, 'month').diff(moment(fromDate), 'months');

    // set the zoom
    if (monthDifference <= 4) {
      monthNumber = 4;
      planningSlide = 1;
    } else if (monthDifference >= 5 && monthDifference <= 8) {
      monthNumber = 8;
      planningSlide = 2;
    } else {
      monthNumber = 12;
      planningSlide = 4;
    }

    // update global month variables
    toDate = moment(fromDate).add(monthNumber - 1, 'months').format('YYYY-MM-DD');
    calendarFirsthMonth = fromDate;
    calendarLastMonth = toDate;

    var months = [];

    // intestazione
    var momentMonth = moment(fromDate);
    for (var i = 0; i < monthNumber; i++) {
      var collectionMonth = {};

      collectionMonth.active = !!((
        moment().format('YYYY') === momentMonth.format('YYYY')
        && moment().format('MM') === momentMonth.format('MM')
      ));

      collectionMonth.name = momentMonth.format('MMM') + ' / ' + momentMonth.format('YY');
      collectionMonth.number = momentMonth.format('MM');
      collectionMonth.first_day = momentMonth.format('YYYY') + '-' + momentMonth.format('MM') + '-01';

      months.push(collectionMonth);

      momentMonth.add(1, 'months');
    }

    calendarMonth = new ProjectCharterApp.CalendarCollection(months);
    var calendarMonthTemplate = new ProjectCharterApp
      .ProjectCharterCalendarMonthsHeadCollectionView({
        collection: calendarMonth,
        monthNumber: monthNumber,
        childViewOptions: {
          monthNumber: monthNumber,
        },
      });

    // recupero dati progetto
    var options = {
      programId: program_id,
      dateStart: fromDate,
      dateEnd: toDate,
    };
    var ProjectCharterPlanningRequest = Wethod.request('projectCharter:get:plannings', options);
    $.when(ProjectCharterPlanningRequest).done(function (ProjectCharterPlanningResponse) {
      // scorro i progetti
      _.each(ProjectCharterPlanningResponse.models, function (model) {
        momentMonth = moment(fromDate);
        // scorro i mesi ( il numero di mesi è determinato dallo zoom )
        for (var i = 0; i < monthNumber; i++) {
          // completo i dati relativi ai mesi e settimane
          completeMonth(model.get('aggregate'), momentMonth.format('YYYY-MM'));

          // PROJECT EMPLOYEE
          _.each(model.get('details'), function (employee) {
            if (!_.isUndefined(employee.name)) {
              completeMonth(employee.months, momentMonth.format('YYYY-MM'));
              // ordino i mesi del progetto in base alla data
              employee.months = _.sortBy(employee.months, 'month_date');
            }
          });
          // passo al mese successivo
          momentMonth.add(1, 'months');
        }
        // ordino i mesi del progetto in base alla data
        model.set({ aggregate: _.sortBy(model.get('aggregate'), 'month_date') });
      });

      var projectCollectionTemplate = new ProjectCharterApp
        .ProjectCharterCalendarProjectsCollectionView({
          collection: ProjectCharterPlanningResponse,
          childViewOptions: {
            monthNumber: monthNumber,
          },
        });

      planningLayout = new ProjectCharterApp.ProjectCharterDetailPlanningLayout({
        model: new ProjectCharterApp.ProjectCharterPlanStructureModel({ zoom: monthNumber }),
      });
      moduleLayout.planning.show(planningLayout);

      planningLayout.months.show(calendarMonthTemplate);
      // planningLayout.years.show( calendarYearTemplate );
      planningLayout.projects.show(projectCollectionTemplate);
    });
  };

  var renderCharterEconomics = function () {
    // loading
    var economicsLoadingTemplate = new ProjectCharterApp
      .ProjectCharterLoadingEconomicsTemplateView();
    moduleLayout.economics.show(economicsLoadingTemplate);// render economics loading view

    var projectCharterEconomicsRequest = Wethod.request('projectCharter:get:economics', program_id);
    var getWastedHoursSettings = Wethod.request('get:wasted:hours:settings');
    $.when(projectCharterEconomicsRequest, getWastedHoursSettings)
      .done(function (economicsModelResponse, wastedHoursSettings) {
        // ECONOMICS
        // creo i totali
        if (!_.isUndefined(economicsModelResponse)) {
          var economicsTotalData = {
            forecast: {
              revenues: 0,
              internal_costs: 0,
              external_costs: 0,
              intercompany_costs: 0,
              margin: 0,
              contribution_margin: 0,
              contribution_margin_percent: 0,
              margin_net: 0,
              margin_percent: 0,
              wasted: 0,
              expenses: 0,
              travels: 0,
            },
            last_version: {
              revenues: 0,
              internal_costs: 0,
              external_costs: 0,
              intercompany_costs: 0,
              margin: 0,
              contribution_margin: 0,
              contribution_margin_percent: 0,
              margin_net: 0,
              margin_percent: 0,
              wasted: 0,
              expenses: 0,
              travels: 0,
            },
            baseline: {
              revenues: 0,
              internal_costs: 0,
              external_costs: 0,
              intercompany_costs: 0,
              margin: 0,
              contribution_margin: 0,
              contribution_margin_percent: 0,
              margin_net: 0,
              margin_percent: 0,
              wasted: 0,
              expenses: 0,
              travels: 0,
            },
          };

          // creo i totali degli economics a partire da quelli dei project
          _.each(economicsModelResponse.attributes, function (project) {
            if (_.isObject(project)) {
              _.each(economicsTotalData, function (val, key) {
                _.each(economicsTotalData[key], function (val1, key1) {
                  economicsTotalData[key][key1] += project[key][key1];
                });
              });
            }
          });

          var economictTotalModel = new ProjectCharterApp.ProjectCharterEconomicsTotalModel(economicsTotalData).set('wastedHoursEnabled', wastedHoursSettings.get('enabled'));

          var economictTotalTemplate = new ProjectCharterApp
            .ProjectCharterDetailEconomicsTotalView({
              model: economictTotalModel,
            });
          var economicsTemplate = new ProjectCharterApp.ProjectCharterDetailEconomicsLayout();
          moduleLayout.economics.show(economicsTemplate);

          economicsTemplate.totals.show(economictTotalTemplate);
        }
      });
  };

  this.notePopup = function (type, parentModel) {
    var modalModel = new ProjectCharterApp.ProjectCharterModalModel({
      add: true,
    });
    if (type === 'show') {
      modalModel.set({
        add: false,
      });
    }
    // passo il contenuto al pop-up
    modalModel.set({ note: parentModel.get('note') });

    var noteModalTemplate = new ProjectCharterApp.ProjectCharterResourceTemplateView({
      model: modalModel,
      parentModel: parentModel,
    });
    moduleLayout.dialog.show(noteModalTemplate);
  };

  this.InitProjectCharter = function (layout, id) {
    resetData();

    program_id = parseInt(id);

    moduleLayout = new ProjectCharterApp.ProjectCharterDetailLayout();

    layout.projectCharterBody.show(moduleLayout);

    var ProjectCharterProjectsRequest = Wethod.request('get:projectCharter:projects', id);
    var ProjectCharterResourcesRequest = Wethod.request('get:projectCharter:resources', id);
    var ProjectCharterHeaderRequest = Wethod.request('get:projectCharter:info', id);
    var ProjectCharterResourceTypeRequest = Wethod.request('projectCharter:get:resource:type');
    var projectCharterPlanningRangeRequest = Wethod.request('projectCharter:get:plannings:range', id);
    var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');

    $.when(ProjectCharterHeaderRequest, ProjectCharterProjectsRequest,
      ProjectCharterResourcesRequest,
      ProjectCharterResourceTypeRequest, projectCharterPlanningRangeRequest,
      isValueAsUnitRequest)
      .done(function (infoModelResponse, projectsCollectionResponse, resourcesCollectionResponse,
        resourceTypeResponse, planningRangeModelResponse, isValueAsUnitResponse) {
        projectsListCollection = projectsCollectionResponse;
        resourcesListCollection = resourcesCollectionResponse;
        resourceTypeCollection = resourceTypeResponse;

        // projects
        var projectLayout = new ProjectCharterApp.ProjectCharterProjectsLayout();
        var projectsCollectionTemplate = new ProjectCharterApp
          .ProjectCharterProjectsCollectionView({
            collection: projectsListCollection,
            isValueAsUnit: isValueAsUnitResponse,
          });

        // resources
        var resourcesLayout = new ProjectCharterApp.ProjectCharterResourcesLayout();
        var resourcesCollectionTemplate = new ProjectCharterApp
          .ProjectCharterResourcesCollectionView({
            collection: resourcesListCollection,
          });

        // loading planning
        var planningLoadingTemplate = new ProjectCharterApp
          .ProjectCharterLoadingPlanningTemplateView();

        // info
        var headTemplate = new ProjectCharterApp.ProjectCharterDetailHeadTemplateView({
          model: infoModelResponse,
        });

        moduleLayout.header.show(headTemplate);

        // RENDER
        var sectionHeaderModel = new Header.SectionHeaderModel({
          current_section: 'Program Details',
          preview_anchor_id: 'programs',
          helper_url: 'pipeline/index/#programs',
        });
        var headerRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });
        moduleLayout.headerSection.show(headerRegion);

        // resources
        moduleLayout.resources.show(resourcesLayout); // render resource layout
        resourcesLayout.list.show(resourcesCollectionTemplate);

        // project
        moduleLayout.projects.show(projectLayout); // render project layout
        projectLayout.list.show(projectsCollectionTemplate);

        // gantt
        moduleLayout.planning.show(planningLoadingTemplate);// render planning loading view

        // ECONOMICS
        renderCharterEconomics();

        // GANTT (planning)
        planningCalendar(planningRangeModelResponse.get('from'), planningRangeModelResponse.get('to'));
      });
  };
}, Wethod.module('HeaderApp.Header'));
