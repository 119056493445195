const React = require('react');
const LogIcon = require('./LogIcon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const LogAction = ({ onClick }) => (
  <BudgetFooterButton label="Show logs" onClick={onClick} noBorder>
    <LogIcon />
  </BudgetFooterButton>
);

module.exports = LogAction;
