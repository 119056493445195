const React = require('react');
const PropTypes = require('prop-types');

const Footer = ({ timestamp }) => (
  <div className="widget-rating-overview-item__footer">
    <div className="widget-rating-overview-item__timestamp">
      {timestamp}
    </div>
  </div>
);

Footer.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

module.exports = Footer;
