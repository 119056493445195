const request = require('./Request');

const PeopleAllocation = {
  getProgress(projectId) {
    return request({
      method: 'get',
      url: `/planningboard/progress?id_project=${projectId}`,
    });
  },
};

module.exports = PeopleAllocation;
