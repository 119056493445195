const { connect } = require('react-redux');
const Component = require('../../components/company-holidays/CompanyHolidaysActions.react');
const companyHolidaysActions = require('../../actions');

const mapStateToProps = (state) => ({
  isAddDisabled: state.companyHolidays.some((item) => item.id === 'new-company-holiday'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdd: () => dispatch(companyHolidaysActions.addNewCompanyHoliday()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
