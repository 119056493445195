/* eslint-disable class-methods-use-this */
const React = require('react');
const PlannedPeople = require('./PlannedPeople.react');
const EventCreateButton = require('./EventCreateButton.react');
const Event = require('./Event.react');

/**
 * EventController decides what to render inside the Event Bubble.
 */
class EventController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      firstAdded: false,
    };

    this.handleAdd = this.handleAdd.bind(this);
  }

  handleClick(event) {
    event.stopPropagation();
  }

  handleAdd() {
    this.setState({ creating: true, firstAdded: true });
    this.props.saveEvent({ type: this.props.eventTypes[0] });
  }

  /**
     * Returns the "add" button if no event is already present for the day, otherwise returns the event form.
     * @returns {XML}
     */
  getEventContent() {
    if (this.props.event) {
      return (
        <Event
          event={this.props.event}
          handleDelete={this.props.deleteEvent}
          handleUpdate={this.props.saveEvent}
          eventTypes={this.props.eventTypes}
          firstAdded={this.state.firstAdded} />
      );
    }
    return <EventCreateButton handleClick={this.handleAdd} creating={this.state.creating} />;
  }

  render() {
    return (
      <div className="" onClick={this.handleClick}>
        <div className="event-container">
          {this.getEventContent()}
        </div>
        <PlannedPeople people={this.props.people} />
      </div>
    );
  }
}

module.exports = EventController;
