const React = require('react');
const Trend = require('../containers/ColumnTrend');

const GenderTrend = ({ data }) => {
  const colors = {
    male: '#0336FF',
    female: '#FEAFA3',
    unset: '#E1E1E1',
    nonBinary: '#FFD166',
  };

  const getColor = (seriesName) => {
    const name = seriesName.toLowerCase().trim();

    switch (name) {
      case 'male':
        return colors.male;
      case 'female':
        return colors.female;
      case 'non binary':
        return colors.nonBinary;
      case 'not specified':
        return colors.unset;
      default:
        return null; // Gives random color defined by highcharts
    }
  };

  const dataWithColors = data.map((series) => ({ ...series, color: getColor(series.name) }));

  return (
    <Trend data={dataWithColors} />
  );
};

module.exports = GenderTrend;
