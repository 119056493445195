const { connect } = require('react-redux');
const Component = require('../../../../../../common/react/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');

const mapStateToProps = () => ({
  availableOptions: [{
    label: 'Manual',
    value: 'manual',
  }, {
    label: 'Automatic',
    value: 'auto',
  }],
});

module.exports = connect(mapStateToProps)(Component);
