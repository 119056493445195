const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const NavSlider = require('../../../../../../common/react/SingleValueSlider.react');

module.exports = class Navigator extends React.Component {
  /**
   * Return an array of month between start and end, formatted as 'YYYY-MM-DD'.
   *
   * @param start Moment
   * @param end Moment
   * @returns {Array}
   */
  static getMonths(start, end) {
    const length = end.diff(start, 'months') + 1;
    const months = [];
    for (let i = 0; i < length; i++) {
      const month = start.clone();
      months.push(month.add(i, 'months').format('YYYY-MM-DD'));
    }
    return months;
  }

  moveOn() {
    const start = moment(this.props.visibleMonths[6]);
    const end = start.clone().add(11, 'months');

    this.props.updateVisibleMonths(Navigator.getMonths(start, end));
  }

  moveBack() {
    const end = moment(this.props.visibleMonths[5]);
    const start = end.clone().subtract(11, 'months');

    this.props.updateVisibleMonths(Navigator.getMonths(start, end));
  }

  moveToNow() {
    const start = moment().date(1).subtract(2, 'months');
    const end = start.clone().add(11, 'months');

    this.props.updateVisibleMonths(Navigator.getMonths(start, end));
  }

  render() {
    return (
      <TableCell className="production-plan-project__info production-plan-project__info--nav">
        <NavSlider value="Today"
          className="production-plan__navigator"
          onPrevClicked={this.moveBack.bind(this)}
          onNextClicked={this.moveOn.bind(this)}
          onTodayClicked={this.moveToNow.bind(this)} />
      </TableCell>
    );
  }
};
