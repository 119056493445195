const React = require('react');
const Accordion = require('../../../../../../common/react/Accordion/Accordion.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');
const OutlinedTag = require('../../../../../../common/react/Tag/OutlinedTag.react');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const AccordionCounter = require('./CounterTag.react');

module.exports = class DashboardAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  handleAccordionClick() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  getClassName() {
    let name = 'data-freezing-engine__accordion data-freezing-engine-padding--m data-freezing-engine-mb--s';
    if (this.state.expanded) {
      name += ' data-freezing-engine__accordion--expanded';
    }
    return name;
  }

  render() {
    return (
      <Accordion className={this.getClassName()}
        expanded={this.state.expanded}
        onChange={this.handleAccordionClick.bind(this)}
        label={(
          <AccordionTitle title={this.props.title}
            subtitle={this.props.subtitle}
            tag={this.props.tag} />
        )}
        summary={(
          <ShowIf condition={!!this.props.counter}>
            <AccordionCounter value={this.props.counter} counterColor={this.props.counterColor} />
          </ShowIf>
        )}>
        {this.props.children}
      </Accordion>
    );
  }
};

const AccordionTitle = ({ title, subtitle, tag = '' }) => (
  <div>
    <Typography
      capitalize
      weight={Typography.WEIGHTS.SEMIBOLD}>
      {title}
    </Typography>
    <ShowIf condition={!!tag}>
      <OutlinedTag upperCase
        inline
        fontSize={Typography.SIZES.PX10}
        color={OutlinedTag.COLORS.RASPBERRY_RED}
        borderRadius={OutlinedTag.BORDER_RADIUS.SQUARED}>
        {tag}
      </OutlinedTag>
    </ShowIf>
    <br />
    <br />
    <Typography
      color={Typography.COLORS.DUSTY_GRAY}>
      {subtitle}
    </Typography>
  </div>
);
