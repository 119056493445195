/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Actions = require('../containers/Actions');
const People = require('../containers/People');
const Companies = require('../containers/Companies');
const Modal = require('../containers/Modal');
const ContactSidebar = require('../containers/ContactSidebar');
const CompanySidebar = require('../containers/ClientSidebar');

module.exports = class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tableHeight: null };
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.clients__actions').outerHeight(true) + 24);
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getList() {
    if (this.props.type === 'people') return <People tableHeight={this.state.tableHeight} />;
    return <Companies tableHeight={this.state.tableHeight} />;
  }

  render() {
    return (
      <div className="clients wethod-section-body">
        <Actions />
        {this.getList()}
        <Modal />
        <CSSTransition
          in={this.props.showPersonSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <ContactSidebar />
        </CSSTransition>
        <CSSTransition
          in={this.props.showCompanySidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <CompanySidebar />
        </CSSTransition>
      </div>
    );
  }
};
