const { connect } = require('react-redux');
const Component = require('../../components/Objectives/ObjectiveItem.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  editable: state.editable,
});

const mapDispatchToProps = (dispatch) => ({
  showObjectiveModal: (objective) => dispatch(actions.showObjectiveModal(objective)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
