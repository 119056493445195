const React = require('react');

const EmptyTimesheet = ({ filter }) => {
  let content = null;

  if (filter) {
    content = <span>No projects found with the given criteria</span>;
  } else {
    content = (
      <span>
        There are no timesheets to compile yet, try
        <a href={`${URL}/#pipeline/projects`}> adding a new project</a>
      </span>
    );
  }

  return (
    <div className="timesheet-project__info-list timesheet-project__info-list--empty">
      {content}
    </div>
  );
};

module.exports = EmptyTimesheet;
