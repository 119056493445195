/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Actions = require('../containers/Actions');
const SuppliersList = require('../containers/SuppliersList');
const Sidebar = require('../containers/sidebar/SupplierSidebar');
const Modal = require('../containers/ModalController');

module.exports = class Suppliers extends React.Component {
  render() {
    return (
      <div className="suppliers wethod-section-body">
        <Actions />
        <SuppliersList />
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <Sidebar />
        </CSSTransition>
        <Modal />
      </div>
    );
  }
};
