const { connect } = require('react-redux');
const Component = require('../components/HeadcountTargetModal.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'target').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  saveTarget: (target) => dispatch(actions.saveTarget(target)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
