/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

module.exports = class MissingStatusForKanbanModal extends React.Component {
  render() {
    return (
      <Modal title="Hey, you need a status!" onCancelClick={this.props.onCancelClick} className="project-canvas-alert">
        Kanban groups items with the same status, this means you need at least a column of type
        {' '}
        <b>status</b>
        {' '}
        to see
        this Spreadsheet as a Kanban.
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">OK</button>
        </div>
      </Modal>
    );
  }
};
