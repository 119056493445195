const React = require('react');
const PropTypes = require('prop-types');
const MenuItem = require('../../../Menu/ListMenu/MenuItem.react');
const Avatar = require('../../../AvatarBox/Avatar.react');

const CollaboratorsMenuItem = ({
  name,
  surname,
  pic,
}) => {
  const fullName = `${name} ${surname}`;

  return (
    <MenuItem className="wethod-collaborators__menu-item">
      <Avatar name={fullName} pic={pic} />
      {name} {surname}
    </MenuItem>
  );
};

CollaboratorsMenuItem.defaultProps = {
  pic: '',
};

CollaboratorsMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  pic: PropTypes.string,
};

module.exports = CollaboratorsMenuItem;
