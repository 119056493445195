/* eslint-disable class-methods-use-this */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NumericValue = require('../../../../common/components/NumericValue.react');
const NumerableSentence = require('../../NumerableSentence.react');
const Link = require('../../../../common/components/Link.react');

module.exports = class MissedOpportunities extends React.Component {
  getReasonWhy(reason) {
    if (!reason) {
      return null;
    }
    return (
      <span>
        (
        {reason}
        )
      </span>
    );
  }

  getProject(project) {
    return (
      <span key={project.project.id}>
        <Link
          href={`#project/${project.project.id}/report`}>
          {_.unescape(project.project.name)}
        </Link>
        {' '}
        for
        {' '}
        {_.unescape(project.client.name)}
        {' '}
        valued
        {' '}
        <NumericValue
          value={project.project.value} />
        {' '}
        {this.getReasonWhy(project.reason_why)}
      </span>
    );
  }

  getProjects() {
    const list = this.props.lost_projects;
    const projects = list.map((project) => this.getProject(project));
    return <List list={list} terminator=".">{projects}</List>;
  }

  render() {
    const listSize = this.props.lost_projects.length;
    if (listSize) {
      return (
        <Paragraph>
          Unfortunately, we also missed some opportunities. Sometimes, it happens, but it’s
          important to
          understand why. So,
          <NumerableSentence quantity={listSize}>
            these are the main projects that clients chose not
            to start (or not to start with us) and why:
          </NumerableSentence>
          {' '}
          {this.getProjects()}
        </Paragraph>
      );
    }
    return (
      <Paragraph>
        No missed opportunities for the last week, good job!
      </Paragraph>
    );
  }
};
