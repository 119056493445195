const React = require('react');
const ModalContent = require('../../../../../../common/react/Modal/ModalContent.react');
const Modal = require('../../../../../../common/react/Modal/Modal.react');
const ModalBody = require('../../../../../../common/react/Modal/ModalBody.react');
const ModalFooter = require('../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../common/react/Modal/ModalFooterAction.react');

const ApiErrorModal = ({ onClose, message, title = 'Something went wrong' }) => (
  <Modal onClose={onClose} title={title}>
    <ModalContent>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <ModalFooterAction onClick={onClose}>
          Ok
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

module.exports = ApiErrorModal;
