/* eslint-disable camelcase,no-underscore-dangle,default-case */

'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  this.WidgetAlertOverviewItemView = Marionette.ItemView.extend({
    tagName: 'li',
    _template: '#widgetAlertOverviewTemplate',
    ui: {
      highLevelAlertsEl: '[data-region="highLevelAlert"]',
      mediumLevelAlertsEl: '[data-region="mediumLevelAlert"]',
      lowLevelAlertsEl: '[data-region="lowLevelAlert"]',
      userRecordEl: '[data-action="showUserAlerts"]',
    },
    events: {
      'click @ui.userRecordEl': 'showUserData',
      'touchstart @ui.userRecordEl': 'showUserData',
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var link = '';
      var model = serialized_model;

      model.link = link;
      return _.template(template)(model);
    },
    onRender: function () {
      var that = this;
      _.each(this.model.get('count'), function (val) {
        var alertLevelEl = that.ui.highLevelAlertsEl;
        switch (parseInt(val.level)) {
          case 2:
            alertLevelEl = that.ui.mediumLevelAlertsEl;
            break;
          case 3:
            alertLevelEl = that.ui.lowLevelAlertsEl;
            break;
        }
        alertLevelEl.parent('.statusInfo')
          .css({
            background: val.color,
            visibility: 'visible',
          });
        alertLevelEl.html(val.amount);
      });
    },
    showUserData: function (e) {
      e.preventDefault();
      this.trigger('alertOverview:showDetail');
    },
  });

  this.WidgetAlertOverviewEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'noAlert',
    template: '#widgetAlertOverviewEmptyTemplate',
  });
  this.WidgetAlertsOverviewCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'alertsOverviewList',
    childView: DashboardApp.WidgetAlertOverviewItemView,
    emptyView: DashboardApp.WidgetAlertOverviewEmptyView,
    initialize: function (options) {
      this.opptions = options;
    },
    childEvents: {
      'alertOverview:showDetail': 'onChildShowDetail',
    },
    onChildShowDetail: function (childView) {
      var user = {
        name: childView.model.get('name'),
        surname: childView.model.get('surname'),
      };
      this.triggerMethod('user:switchView', childView.model.get('alerts'), user);
    },
  });

  // this.WidgetAlertsOverviewCompositeView = Marionette.CompositeView.extend({
  this.WidgetAlertsOverviewLayout = Marionette.LayoutView.extend({
    template: '#widgetAlertsOverviewCompositeTemplate',
    regions: {
      userList: '[data-region="alertsList"]',
      detailList: '[data-region="showUserAlertsDetails"]',
    },
    ui: {
      sliderEl: '[data-region="overviewSlider"]',
      userListEl: '[data-region="alertsList"]',

      detailListEl: '[data-region="showUserAlertsDetails"]',
      backToListEl: '[data-action="backToList"]',
      userLabelEl: '[data-region="userLabel"]',

      overviewWrapEl: '[data-region="overviewWrap"]',
    },
    events: {
      'click @ui.backToListEl': 'slideBack',
      'touchstart @ui.backToListEl': 'slideBack',
    },
    initialize: function (options) {
      this.options = options;
    },
    childEvents: {
      'user:switchView': 'onChildSwitchView',
    },
    onRender: function () {
      var that = this;
      var width = this.options.widgetView.$el.context.offsetWidth - 6;

      this.ui.overviewWrapEl.css({ width: width });
      this.ui.sliderEl.css({ width: width * 2 });

      this.ui.detailListEl.css({ width: width });
      this.ui.userListEl.css({ width: width });

      this.userList.show(
        new DashboardApp.WidgetAlertsOverviewCollectionView({
          collection: that.options.alertsOverviewCollection,
        })
      );
    },
    onChildSwitchView: function (childView, detailData, selectedUser) {
      if (!this.ui.sliderEl.is(':animated')) {
        // mostro il tasto per tornare alla lista degli utenti
        this.ui.backToListEl.show();
        // indico di chi è il dettaglio visualizzato
        this.ui.userLabelEl.html(' - ' + selectedUser.name + ' ' + selectedUser.surname);

        // mostro la lista degli alert
        this.detailList.show(
          new DashboardApp.WidgetAlertsCollectionView({
            collection: new DashboardApp.WidgetAlertCollection(detailData),
            childViewOptions: {
              noLink: true,
            },
          })
        );
        var width = this.options.widgetView.$el.context.offsetWidth - 6;
        // this.ui.sliderEl.css({'margin-left' : '-335px'});
        this.ui.sliderEl.animate({ 'margin-left': '-=' + width });
      }
    },
    slideBack: function (e) {
      e.preventDefault();
      if (!this.ui.sliderEl.is(':animated')) {
        var that = this;
        // this.ui.sliderEl.css({'margin-left' : '0px'});
        this.ui.sliderEl.animate({ 'margin-left': '0px' }, function () {
          // nascondo il tasto per tornare alla lista degli utenti
          that.ui.backToListEl.hide();
          // elimino le info di dettaglio
          that.ui.userLabelEl.html('');
          that.ui.detailListEl.html('');
        });
      }
    },
  });
});
