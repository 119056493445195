const React = require('react');
const { Provider } = require('react-redux');
const Pipeline = require('./containers/Pipeline');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <Pipeline />
  </Provider>
);
