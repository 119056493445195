'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var TravelStore = require('../../apps/core/modules/travel/store');
var TravelReducer = require('../../apps/core/modules/travel/reducer');
var TravelComponent = require('../../apps/core/modules/travel/index');
var PropertyMetadataModel = require('../../models/PropertyMetadata');

Wethod.module('TravelApp.Travel', function (Travel, Wethod, Backbone, Marionette, $) {
  Travel.Controller = {

    showTravel: function (travelId) {
      var authorizationRequest = Wethod.request('get:travel:authorization');
      $.when(authorizationRequest,
        PropertyMetadataModel.list(PropertyMetadataModel.TRAVEL_ENTITY),
        PropertyMetadataModel.list(PropertyMetadataModel.TRAVEL_CARRIER_ENTITY))
        .done(function (permissions, travelMetadata, travelCarrierMetadata) {
          Wethod.TravelApp.authorization = permissions;

          if (!permissions.get('view')) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }

          var layout = new Travel.StructureLayoutView();
          layout.render();

          var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
          var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
          layout.travelBody.show(loadingView);

          var reducerWrapper = new TravelReducer({
            id: travelId,
            permissions: permissions.attributes,
            travelMetadata: travelMetadata,
            travelCarrierMetadata: travelCarrierMetadata,
          });

          var store = TravelStore(reducerWrapper.reduxReducer);
          var element = React.createElement(TravelComponent, { store: store });
          var container = document.getElementById('root');
          if (container !== null) ReactDOM.render(element, container);
        });
    },
  };
});
