const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./MultipleSelectFilterButton.react');
const Menu = require('./MultipleSelectFilterMenu.react');

require('./style.scss');

class MultipleSelectFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.values,
      open: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onMainButtonClick = this.onMainButtonClick.bind(this);
    this.onApplyClick = this.onApplyClick.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  onClose() {
    this.setState({ open: false });
  }

  onMainButtonClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  onChange(e, values) {
    this.setState({ values });
  }

  onApplyClick() {
    this.props.onApply(this.props.name, this.state.values);
    this.onClose();
  }

  getClassName() {
    return `wethod-multiple-select-filter ${this.props.className}`;
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <Button inputRef={this.setMenuAnchorRef}
          label={this.props.label}
          acceptedValues={this.props.acceptedValues}
          selectedValues={this.state.values}
          onClick={this.onMainButtonClick} />
        <Menu onChange={this.onChange}
          name={this.props.name}
          acceptedValues={this.props.acceptedValues}
          availableOptions={this.props.availableOptions}
          values={this.state.values}
          onApplyClick={this.onApplyClick}
          open={this.state.open}
          onClose={this.onClose}
          anchorEl={this.menuAnchorRef}
          title={this.props.menuTitle} />
      </div>
    );
  }
}

const availableOptionShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

availableOptionShape.children = PropTypes.arrayOf(PropTypes.shape(availableOptionShape));

MultipleSelectFilter.defaultProps = {
  className: '',
  values: [],
  menuTitle: '',
  onApply: null,
};

MultipleSelectFilter.propTypes = {
  className: PropTypes.string,
  /**
   * List of currently selected values.
   */
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  label: PropTypes.string.isRequired,
  menuTitle: PropTypes.string,
  availableOptions: PropTypes.shape(availableOptionShape).isRequired,
  /**
   * List of the "real" values, that are availableOptions without values representing groups.
   * These are likely the leaf values.
   */
  acceptedValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  /**
   * Function to call when user clicks "Apply" button.
   * @param {string} name input name
   * @param {string[]} values list of selected values
   */
  onApply: PropTypes.func,
  /**
   * Name of the checkbox group. Must be unique in the page.
   */
  name: PropTypes.string.isRequired,
};

module.exports = MultipleSelectFilter;
