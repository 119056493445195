const React = require('react');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');

const LoadingView = () => (
  <div className="textCenter company-settings-margin-top-xl">
    <Loader />
  </div>
);

module.exports = LoadingView;
