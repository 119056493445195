const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const Locations = require('./components/location/Locations.react');

module.exports = ({ store, isJakalaFeaturesNovemberBlockEnabled }) => (
  isJakalaFeaturesNovemberBlockEnabled
    ? (
      <Provider store={store}>
        <Locations />
      </Provider>
    )
    : null
);
