/* eslint-disable camelcase */
const React = require('react');
const Paragraph = require('../../../../../common/components/SectionParagraph.react');
const NV = require('../../../../../common/components/NumericValue.react');

module.exports = ({ value, prev_value }) => {
  if (value === prev_value) {
    return (
      <Paragraph>
        The pipeline has a value of
        {' '}
        <NV value={value} />
        , which is the same of last week.
      </Paragraph>
    );
  }
  return (
    <Paragraph>
      Overall during the week, the pipeline changed from
      {' '}
      <NV value={prev_value} />
      {' '}
      to
      {' '}
      <NV value={value} />
      .
    </Paragraph>
  );
};
