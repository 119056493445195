const React = require('react');

const LogIcon = () => (
  <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999043 8.00039C0.92571 8.00039 0.869043 7.98039 0.829043 7.94039C0.789043 7.89372 0.769043 7.83706 0.769043 7.77039V1.23039C0.769043 1.16372 0.789043 1.11039 0.829043 1.07039C0.869043 1.02372 0.92571 1.00039 0.999043 1.00039H1.52904C1.59571 1.00039 1.64904 1.02372 1.68904 1.07039C1.73571 1.11039 1.75904 1.16372 1.75904 1.23039V7.13039H5.07904C5.15238 7.13039 5.20904 7.15372 5.24904 7.20039C5.29571 7.24039 5.31904 7.29706 5.31904 7.37039V7.77039C5.31904 7.83706 5.29571 7.89372 5.24904 7.94039C5.20904 7.98039 5.15238 8.00039 5.07904 8.00039H0.999043Z" fill="black" />
    <path d="M8.82615 8.10039C8.25282 8.10039 7.76282 8.00372 7.35615 7.81039C6.94949 7.61039 6.63615 7.30706 6.41615 6.90039C6.19615 6.48706 6.07282 5.97039 6.04615 5.35039C6.03949 5.05706 6.03615 4.77706 6.03615 4.51039C6.03615 4.23706 6.03949 3.95372 6.04615 3.66039C6.07282 3.04706 6.19949 2.53706 6.42615 2.13039C6.65949 1.71706 6.97949 1.41039 7.38615 1.21039C7.79949 1.00372 8.27949 0.900391 8.82615 0.900391C9.36615 0.900391 9.83949 1.00372 10.2462 1.21039C10.6595 1.41039 10.9828 1.71706 11.2162 2.13039C11.4495 2.53706 11.5762 3.04706 11.5962 3.66039C11.6095 3.95372 11.6162 4.23706 11.6162 4.51039C11.6162 4.77706 11.6095 5.05706 11.5962 5.35039C11.5762 5.97039 11.4528 6.48706 11.2262 6.90039C11.0062 7.30706 10.6928 7.61039 10.2862 7.81039C9.87949 8.00372 9.39282 8.10039 8.82615 8.10039ZM8.82615 7.25039C9.33282 7.25039 9.74615 7.09706 10.0662 6.79039C10.3928 6.48372 10.5695 5.98706 10.5962 5.30039C10.6095 5.00039 10.6162 4.73372 10.6162 4.50039C10.6162 4.26039 10.6095 3.99372 10.5962 3.70039C10.5828 3.24039 10.4962 2.86706 10.3362 2.58039C10.1828 2.29372 9.97615 2.08372 9.71615 1.95039C9.45615 1.81706 9.15949 1.75039 8.82615 1.75039C8.49282 1.75039 8.19615 1.81706 7.93615 1.95039C7.67615 2.08372 7.46615 2.29372 7.30615 2.58039C7.15282 2.86706 7.06615 3.24039 7.04615 3.70039C7.03949 3.99372 7.03615 4.26039 7.03615 4.50039C7.03615 4.73372 7.03949 5.00039 7.04615 5.30039C7.07282 5.98706 7.24949 6.48372 7.57615 6.79039C7.90282 7.09706 8.31949 7.25039 8.82615 7.25039Z" fill="black" />
    <path d="M15.6237 8.10039C15.0304 8.10039 14.5271 7.99039 14.1137 7.77039C13.7071 7.55039 13.3937 7.24039 13.1737 6.84039C12.9604 6.44039 12.8404 5.97039 12.8137 5.43039C12.8071 5.13706 12.8037 4.82706 12.8037 4.50039C12.8037 4.16706 12.8071 3.85039 12.8137 3.55039C12.8404 3.01039 12.9604 2.54372 13.1737 2.15039C13.3937 1.75039 13.7104 1.44372 14.1237 1.23039C14.5371 1.01039 15.0371 0.900391 15.6237 0.900391C16.0904 0.900391 16.4971 0.967057 16.8437 1.10039C17.1904 1.23372 17.4737 1.40372 17.6937 1.61039C17.9204 1.81039 18.0871 2.02039 18.1937 2.24039C18.3071 2.46039 18.3671 2.65372 18.3737 2.82039C18.3804 2.88039 18.3637 2.93039 18.3237 2.97039C18.2837 3.00372 18.2304 3.02039 18.1637 3.02039H17.5737C17.5071 3.02039 17.4571 3.00706 17.4237 2.98039C17.3971 2.95372 17.3704 2.90706 17.3437 2.84039C17.2837 2.66706 17.1837 2.49706 17.0437 2.33039C16.9104 2.16372 16.7271 2.02706 16.4937 1.92039C16.2671 1.80706 15.9771 1.75039 15.6237 1.75039C15.0904 1.75039 14.6604 1.89372 14.3337 2.18039C14.0137 2.46706 13.8404 2.94039 13.8137 3.60039C13.7937 4.19372 13.7937 4.78706 13.8137 5.38039C13.8404 6.04706 14.0171 6.52706 14.3437 6.82039C14.6704 7.10706 15.0971 7.25039 15.6237 7.25039C15.9771 7.25039 16.2904 7.18372 16.5637 7.05039C16.8437 6.91706 17.0637 6.71706 17.2237 6.45039C17.3837 6.17706 17.4637 5.83039 17.4637 5.41039V4.99039H15.9637C15.8971 4.99039 15.8404 4.97039 15.7937 4.93039C15.7537 4.88372 15.7337 4.82372 15.7337 4.75039V4.43039C15.7337 4.35706 15.7537 4.30039 15.7937 4.26039C15.8404 4.21372 15.8971 4.19039 15.9637 4.19039H18.2237C18.2971 4.19039 18.3537 4.21372 18.3937 4.26039C18.4337 4.30039 18.4537 4.35706 18.4537 4.43039V5.39039C18.4537 5.93706 18.3404 6.41372 18.1137 6.82039C17.8937 7.22706 17.5704 7.54372 17.1437 7.77039C16.7171 7.99039 16.2104 8.10039 15.6237 8.10039Z" fill="black" />
  </svg>
);

module.exports = LogIcon;
