/* eslint-disable camelcase,jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,
 react/forbid-prop-types,react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');

const SupplierIntercompanyActivity = ({
  accepted, client_company, client_project, client_task, supplier_project, token,
}) => {
  const getUnescapedContent = (content) => _.unescape(content);

  const getPrintableValue = (value, attribute) => (value
    ? getUnescapedContent(value[attribute]) : null);

  const getLink = () => (accepted ? `#pipeline/projects?id=${supplier_project.id}`
    : `#pipeline/intercompany/${token}`);

  const getStatus = () => {
    if (!accepted) {
      return (
        <span className="widget-intercompany__status widget-intercompany__status--pending">Pending</span>
      );
    }

    return (
      <span className="widget-intercompany__status widget-intercompany__status--accepted">Linked</span>
    );
  };

  return (
    <div className="widget-intercompany__item">
      <div className="widget-intercompany__item--sliding">
        <div className="widget-intercompany__item-content">
          <span className="wethod-icon wethod-icon-incoming wethod-icon-incoming--black" />
          <span className="widget-intercompany__project">
            <span>{getPrintableValue(client_task, 'name')}</span>
            <span>{getPrintableValue(client_project, 'name')}</span>
            <span>
              Requested by
              {client_company.name}
            </span>
          </span>
          {getStatus()}
        </div>
        <div className="widget-intercompany__actions">
          <a href={getLink()}
            className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--white" />
        </div>
      </div>
    </div>
  );
};

SupplierIntercompanyActivity.propTypes = {
  /**
   * Flag to check if the request has been accepted by the supplier
   */
  accepted: PropTypes.bool.isRequired,

  /**
   * Client company that sent the request
   */
  client_company: PropTypes.object.isRequired,

  /**
   * Client project of the intercompany task
   */
  client_project: PropTypes.object.isRequired,

  /**
   * Client budget task intercompany
   */
  client_task: PropTypes.object.isRequired,

  /**
   * Supplier project linked to the intercompany request
   */
  supplier_project: PropTypes.object,

  /**
   * Unique token to link the request to an existing project
   */
  token: PropTypes.string.isRequired,
};

module.exports = SupplierIntercompanyActivity;
