/* eslint-disable no-shadow */
const { connect } = require('react-redux');
const Component = require('../../components/Trends/TrendDoTheBudget/TrendDoTheBudget.react');
const actions = require('../../actions');

const getData = (weeks, filter) => {
  if (filter === 'month') {
    return weeks.filter((week, index, weeks) => index > weeks.length - 13);
  }
  return weeks;
};

const mapStateToProps = (state) => ({
  employee: state.id,
  title: 'Do the budget',
  subtitle: "Difference between baseline and actual budgets' margin",
  data: getData(state.doTheBudgetTrend, state.trendsFilter),
});

const mapDispatchToProps = (dispatch) => ({
  getTrend: (employeeId) => dispatch(actions.getDoTheBudgetTrend(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
