const React = require('react');

const HolidayManager = ({
  holidays,
  children,
  ...rest
}) => {
  /**
   * Return true if given date is a company holiday for the employee.
   * @param {string} date formatted YYYY-MM-DD
   * @param {{location:{id:number}}} person
   * @return {boolean}
   */
  const isHoliday = (date, person) => holidays.some((holiday) => {
    const isRelatedToPersonLocation = holiday.locations
      .some((location) => location.id === person.location.id);
    const isRelatedToDate = holiday.date === date;

    return isRelatedToDate && isRelatedToPersonLocation;
  });

  return React.cloneElement(children, {
    ...rest,
    isHoliday,
  });
};

module.exports = HolidayManager;
