const { connect } = require('react-redux');
const Component = require('../../../components/modals/Languages/Language.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  levels: state.languageLevels,
  availableLanguages: state.availableLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  updateLanguage: (language) => dispatch(actions.updateLanguage(language)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
