/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Legend extends React.Component {
  render() {
    return (
      <div className="profile-trends__item-content-legend">
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--avg">
          {this.props.label.toUpperCase()}
          {' '}
          average
        </div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--positive">&ge; average</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--neutral">&ge; average -10%</div>
        <div className="profile-trends__item-content-legend-row profile-trends__item-content-legend-row--negative">&lt; average -10%</div>
      </div>
    );
  }
};
