const React = require('react');
const SubmitButton = require('./SubmitStatusButton/SubmitStatusButton.react');
const RecallButton = require('./RecallStatusButton.react');
const ApprovalButton = require('./ApprovalStatusButton/ApprovalStatusButton.react');
const DraftButton = require('./DraftStatusButton/DraftStatusButton.react');
const BudgetModel = require('../../../../../../../../models/Budget');

const StatusButtonFactory = ({
  userHasBudgetEditPermissions,
  userHasBudgetApprovePermissions,
  status,
  showModalBudgetSubmit,
  showModalBudgetApproval,
  showModalBudgetDraft,
  selectedVersion,
  versions,
  projectIsFrozen,
}) => {
  const isCurrentVersionSelected = () => {
    if (versions.length === 0) {
      return false;
    }
    const descendingOrderedVersions = versions.sort((a, b) => b.version - a.version);
    const lastVersion = descendingOrderedVersions[0];

    return lastVersion.version === selectedVersion;
  };
  const isEditableDraft = status === BudgetModel.STATUS_DRAFT && userHasBudgetEditPermissions;
  const isEditableSubmitted = status === BudgetModel.STATUS_SUBMITTED
    && userHasBudgetEditPermissions;
  const isApprovable = status === BudgetModel.STATUS_SUBMITTED
    && userHasBudgetApprovePermissions;
  const isEditableApproved = status === BudgetModel.STATUS_APPROVED
    && userHasBudgetEditPermissions;

  if (isCurrentVersionSelected()) {
    if (isEditableDraft) {
      return <SubmitButton onClick={showModalBudgetSubmit} isProjectFrozen={projectIsFrozen} />;
    }
    if (isApprovable) {
      return <ApprovalButton onClick={showModalBudgetApproval} isProjectFrozen={projectIsFrozen} />;
    }
    if (isEditableSubmitted) {
      return <RecallButton onClick={showModalBudgetDraft} isProjectFrozen={projectIsFrozen} />;
    }
    if (isEditableApproved) {
      return <DraftButton onClick={showModalBudgetDraft} isProjectFrozen={projectIsFrozen} />;
    }
  }
  return null;
};

module.exports = StatusButtonFactory;
