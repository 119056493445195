const { connect } = require('react-redux');
const Component = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  buEnabled: state.buEnabled,
  buFilter: state.buFilter,
  userId: state.userId,
  dailyModeToggle: state.dailyModeToggle,
  employeeId: state.employee.id,
});

const mapDispatchToProps = (dispatch) => ({
  changeBusinessUnit: (name, units) => dispatch(actions.changeBuFilter(units)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
