const React = require('react');

/**
 * Keeps updated the list of levels, which are updated in older sections.
 *
 * @type {LevelEventsManager}
 */
module.exports = class LevelEventsManager extends React.Component {
  constructor(props) {
    super(props);

    this.onLevelAdded = this.onLevelAdded.bind(this);
    this.onLevelDeleted = this.onLevelDeleted.bind(this);
    this.onLevelUpdated = this.onLevelUpdated.bind(this);
  }

  componentDidMount() {
    dispatcher.on('user:level:added', this.onLevelAdded);
    dispatcher.on('user:level:deleted', this.onLevelDeleted);
    dispatcher.on('user:level:updated', this.onLevelUpdated);
  }

  /**
   * Add the given level model to the list
   * @param model
   */
  onLevelAdded(model) {
    if (model != null) {
      const level = model.toJSON();
      this.props.addLevel(level);
    }
  }

  /**
   * Remove from the list of levels the corresponding one
   * @param model
   */
  onLevelDeleted(model) {
    if (model != null) {
      const level = model.toJSON();
      this.props.deleteLevel(level);
    }
  }

  /**
   * Update the level in the list with the data from the model
   * @param model
   */
  onLevelUpdated(model) {
    if (model != null) {
      const level = model.toJSON();
      this.props.updateLevel(level);
    }
  }

  render() {
    return null;
  }
};
