const React = require('react');
const PropTypes = require('prop-types');
const SelectButton = require('../SelectButton.react');
const Decorator = require('../../../InputDecorator/OutlinedInputDecorator/OutlinedInputDecorator.react');

const OutlinedSelectButton = ({
  label, id, placeholder, prefix, value, suffix, required, inputRef, helperText,
  errorText, disabled, readOnly, ...rest
}) => (
  <Decorator label={label}
    required={required}
    disabled={disabled}
    readOnly={readOnly}
    inputRef={inputRef}
    helperText={helperText}
    errorText={errorText}
    id={id}
    prefix={prefix}
    suffix={suffix}
    placeholder={placeholder}
    value={value}>
    <SelectButton disabled={disabled} readOnly={readOnly} value={value} {...rest}>
      {value}
      <span>&nbsp;</span>
    </SelectButton>
  </Decorator>
);

OutlinedSelectButton.defaultProps = {
  value: '',
  id: undefined,
  className: undefined,
  placeholder: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  label: undefined,
  required: undefined,
  inputRef: undefined,
};

OutlinedSelectButton.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputRef: PropTypes.func,
};

module.exports = OutlinedSelectButton;
