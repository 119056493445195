/* eslint-disable consistent-return,jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class Event extends React.Component {
  getCreationDate() {
    const momentDate = moment(this.props.schedule);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    const createdBy = this.props.created_by;
    if (createdBy) {
      return `${createdBy.person.name} ${createdBy.person.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-past__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-past__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-past__creation-time">{date}</span>
    );
  }

  getDoneButtonText() {
    const done = this.props.confirmed;
    if (!done) return 'Mark as done';
    return 'Mark as not done';
  }

  render() {
    return (
      <div className="timeline-event-future__content">
        <div className="timeline-event-future__creation">
          {this.getCreation()}
        </div>
        <div className="timeline-event-past__description">{this.props.description}</div>
        <div className="timeline-event-past__actions">
          <div className="timeline-event-past__actions-left" />
          <div className="timeline-event-past__actions-right">
            <div
              className="wethod-button"
              onClick={this.props.save}>
              {this.getDoneButtonText()}
            </div>
            <div
              className="clients__delete-button wethod-icon-button"
              onClick={this.props.handleDeleteClick}>
              <div className="wethod-icon-medium-delete" />
            </div>
            <a
              href="#"
              className="clients__delete-button wethod-icon-button"
              onClick={this.props.handleEditClick}>
              <div className="wethod-icon-medium-edit" />
            </a>
          </div>
        </div>
      </div>
    );
  }
};
