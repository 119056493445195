const React = require('react');
const TaskLabel = require('../RowLabel/RowLabelFactory.react');

const Task = ({ children, exists, existed, className, column }) => (
  <div className="wethod-budget-task">
    <TaskLabel exists={exists} existed={existed} />
    <div className={`wethod-budget-task__content ${className} ${column ? `wethod-budget-task__content__${column}` : ''}`}>
      {children}
    </div>
  </div>
);

module.exports = Task;
