const { connect } = require('react-redux');
const Component = require('../components/People.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  selectedProject: state.selected_project,
});

const mapDispatchToProps = (dispatch) => ({
  func: (arg) => dispatch(actions.func(arg)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
