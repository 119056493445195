'use strict';

Wethod.module('View.Modal', function (Modal, Wethod, Backbone, Marionette, $, _) {
  /**
     * Base View for a Text Modal. It manages render, placement and closing of the Modal.
     */
  this.BaseView = Marionette.ItemView.extend({
    className: 'messageModalPageOverlay',
    ui: {
      body: '[data-region="body"]',
      titleLabel: '[data-region="title"]',
      closeButton: '[data-action="close"]',
      feedbackLabel: '[data-region="feedback"]',
    },
    events: {
      'click @ui.closeButton': 'closeModal',
    },
    onShow: function () {
      this.placeModal();
    },

    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this.ui.body.width()) / 2;
      var posTop = (contextH - this.ui.body.height()) / 2;
      this.ui.body.css({ left: posLeft, top: posTop }).show();
    },

    closeModal: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  // When extended carry on ui and events
  this.BaseView.extend = function (child) {
    var view = Marionette.ItemView.extend.apply(this, arguments);
    view.prototype.ui = _.extend({}, this.prototype.ui, child.ui);
    view.prototype.events = _.extend({}, this.prototype.events, child.events);
    return view;
  };
});
