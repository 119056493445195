'use strict';

/* eslint-disable camelcase,no-underscore-dangle */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  this.widgetInvoiceItemView = Marionette.ItemView.extend({
    tagName: 'li',
    _template: '#widgetInvoiceTemplate',
    ui: {
      invoiceButtonEl: '[data-action="doThisInvoice"]',
      planButtonEl: '[data-action="goToThisInvoicePlan"]',
      itemEl: '[data-action="selectItem"]',
      statusInfo: '[data-region="statusInfo"]',
    },
    events: {
      'click @ui.invoiceButtonEl': 'doThisInvoice',
      'touchstart @ui.invoiceButtonEl': 'doThisInvoice',

      'click @ui.planButtonEl': 'goToThisInvoicePlan',
      'touchstart @ui.planButtonEl': 'goToThisInvoicePlan',

      'click @ui.itemEl': 'checkInvoice',
      'touchstart @ui.itemEl': 'checkInvoice',
    },
    /**
     * Use this method to group all the values' conversion needed when multicurrency is used.
     */
    setConvertedValues: function () {
      var currency = this.model.get('currency');
      if (currency) {
        var delta = this.model.get('delta');
        var convertedDelta = delta * currency.rate;
        this.model.set('delta', convertedDelta);
      }
    },
    initialize: function (options) {
      this.options = options;
      this.setConvertedValues();
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var model = serialized_model;

      model.link = '#project/' + model.project.id + '/invoice-plan';
      return _.template(template)(model);
    },
    onRender: function () {
      // check sui permessi
      if (
        !DashboardApp.getInvoicePermissions(this.model.get('project').id, this.model.get('pm').id, this.model.get('project').job_order)
      ) {
        this.ui.invoiceButtonEl.hide();
        this.ui.itemEl.addClass('notInvoiceable');
      }

      var monthTogo = moment(this.model.get('date')).diff(moment(), 'months');
      if (monthTogo < 0) {
        this.ui.statusInfo.addClass('red');
      } else if (monthTogo === 0) {
        this.ui.statusInfo.addClass('orange');
      }
    },
    doThisInvoice: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.invoiceButtonEl.hasClass('disabled')) {
        // preparo la struttura del modello dell'invoice
        var invoiceData = {
          value: this.model.get('currency') ? this.model.get('delta') / this.model.get('currency').rate : this.model.get('delta'),
          date: this.model.get('date'),
          id_project: this.model.get('project').id,
          id_client: this.model.get('client').id,
          client: {
            id: this.model.get('client').id,
            corporate_name: this.model.get('client').name,
            acronym: this.model.get('client').acronym,
          },
          currency: this.model.get('currency'),
          exchange_rate: this.model.get('currency') ? this.model.get('currency').rate : null,
          project: {
            id: this.model.get('project').id,
            name: this.model.get('project').name,
            job_order: this.model.get('project').job_order,
            _fields: this.model.get('project')._fields,
            pm: {
              id: this.model.get('pm').id,
              pm_name: this.model.get('pm').name,
              pm_surname: this.model.get('pm').surname,
            },
            account: {
              id: this.model.get('account').id,
            },
          },
        };
        invoiceData = [invoiceData];
        DashboardApp.doInvoice(invoiceData);
      }
    },
    goToThisInvoicePlan: function (e) {
      e.preventDefault();
      var projectID = this.model.get('project').id;
      DashboardApp.showProjectInvoicePlan(projectID);
    },
    checkInvoice: function (e) {
      var invoiceData = {
        cid: this.cid,
        value: this.model.get('currency') ? this.model.get('delta') / this.model.get('currency').rate : this.model.get('delta'),
        date: this.model.get('date'),
        id_project: this.model.get('project').id,
        id_client: this.model.get('client').id,
        client: {
          id: this.model.get('client').id,
          corporate_name: this.model.get('client').name,
          acronym: this.model.get('client').acronym,
        },
        currency: this.model.get('currency'),
        exchange_rate: this.model.get('currency') ? this.model.get('currency').rate : null,
        project: {
          id: this.model.get('project').id,
          name: this.model.get('project').name,
          job_order: this.model.get('project').job_order,
          _fields: this.model.get('project')._fields,
          pm: {
            id: this.model.get('pm').id,
            pm_name: this.model.get('pm').name,
            pm_surname: this.model.get('pm').surname,
          },
          account: {
            id: this.model.get('account').id,
          },
        },
      };

      var target = $(e.currentTarget);
      if (!target.hasClass('notInvoiceable')) {
        if (!target.hasClass('selectInvoice')) {
          target.addClass('selectInvoice');
          DashboardApp.modifyInvoiceList(this.options.parentView, invoiceData, 'add');
        } else {
          target.removeClass('selectInvoice');
          DashboardApp.modifyInvoiceList(this.options.parentView, invoiceData, 'remove');
        }
      }
    },
  });

  this.WidgetInvoiceEmptyView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'noInvoice',
    template: '#widgetInvoiceEmptyTemplate',
  });

  this.WidgetInvoicesCompositeView = Marionette.CompositeView.extend({
    template: '#widgetInvoicesCompositeTemplate',
    childViewContainer: '[data-region="invoicesList"]',
    childView: DashboardApp.widgetInvoiceItemView,
    emptyView: DashboardApp.WidgetInvoiceEmptyView,
    ui: {
      multipleInvoiceEl: '[data-action="doMultipleInvoice"]',
      footerEl: '[data-region="widgetFooter"]',

      mdlInput: '[data-region="mdl-input"]',

      // hint wrapper
      wrapTooltipHintEl: '[data-region="wrapFilterTooltipHint"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      inputHintEl: '[data-action="inputHint"]',

      settingsRegionEl: '[data-region="widgetSettings"]',

      // settings
      settingsEl: '[data-action="settingsWidget"]',
      // save
      saveEl: '[data-action="saveWidget"]',
      // save feedback
      saveFeedbackEl: '[data-region="widgetSaveFeedback"]',
      // delete
      deleteEl: '[data-action="deleteWidget"]',

      // from behavior
      showFilterEl: '[data-action="showFilterTooltip"]',
    },
    events: {
      'click @ui.multipleInvoiceEl': 'doMultipleInvoices',
      'touchstart @ui.multipleInvoiceEl': 'doMultipleInvoices',

      'click @ui.saveEl': 'saveWidget',
      'touchstart @ui.saveEl': 'saveWidget',

      'click @ui.deleteEl': 'deleteWidget',
      'touchstart @ui.deleteEl': 'deleteWidget',
    },
    behaviors: {
      settingsWidget: {},
    },
    initialize: function (options) {
      this.options = options;
      this.options.invoicesList = [];

      this.options.filterType = options.model.get('config').active_filter.name || null;
      this.options.filterValue = options.model.get('config').active_filter.value || null;
    },
    childViewOptions: function () {
      return { parentView: this };
    },
    onRender: function () {
      if (this.options.new) {
        this.ui.settingsRegionEl.addClass('isVisible');
      }

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });
    },
    doMultipleInvoices: function (e) {
      e.preventDefault();
      DashboardApp.doInvoice(this.options.invoicesList);
    },
    saveWidget: function (e) {
      e.preventDefault();

      if (!this.ui.saveEl.hasClass('disabled')) {
        this.ui.saveEl.addClass('disabled');
        this.ui.showFilterEl.addClass('disabled');
        this.ui.inputHintEl.addClass('disabled');
        this.ui.deleteEl.hide();
        this.ui.saveFeedbackEl.show();

        var data = {
          filterType: this.options.filterType,
          filterValue: this.options.filterValue,
        };

        if (
          this.model.get('config').active_filter.name !== this.options.filterType
          || this.model.get('config').active_filter.value !== this.options.filterValue
        ) {
          DashboardApp.saveInvoice(this, data);
        } else {
          this.ui.settingsRegionEl.removeClass('isVisible');
        }
      }
    },
    deleteWidget: function (e) {
      e.preventDefault();
      if (!this.ui.deleteEl.hasClass('disabled')) {
        this.ui.deleteEl.addClass('disabled');
        DashboardApp.deleteinvoice(this);
      }
    },
  });
});
