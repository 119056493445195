const React = require('react');
const PropTypes = require('prop-types');
const Tag = require('./Tag.react');

/**
 * Switch tag component, customizable.
 * It is a clickable tag that represents two status: switched on and switched off.
 * The status shown has to be given with the boolean prop active.
 * Every status change triggers the onClick prop.
 * The content of the tag is given as children.
 * @param id
 * @param active
 * @param children
 * @param disabled
 * @param className
 * @param onClick
 */
const SwitchTag = ({ id, active, children, disabled, className, onClick }) => {
  function handleTagClick(tagId) {
    const updatedActive = !active;
    onClick(tagId, updatedActive);
  }

  function getClassName() {
    let style = 'wethod-tag-switch';
    if (active) style += ' wethod-tag-switch--selected';
    if (className) style += ` ${className}`;
    return style;
  }

  function getIconClass() {
    let style = 'wethod-icon';
    style += active
      ? ' wethod-icon-medium-eye--opened wethod-icon-medium-eye--white'
      : ' wethod-icon-medium-eye--closed  wethod-icon-medium-eye--black';

    return style;
  }

  return (
    <Tag id={id}
      onClick={handleTagClick}
      className={getClassName()}
      disabled={disabled}>
      {children}
      <div className={getIconClass()} />
    </Tag>
  );
};

SwitchTag.propTypes = {
  /**
   * Id of the tag. It will be given as argument for click event.
   * @see Tag
   */
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Whether the tag should be active or not, changing its layout.
   * When it is active, the tag is switched on, otherwise it is switched off.
   */
  active: PropTypes.bool,
  /**
   * Content shown in the tag, before the switch icon. It can be a simple string or an element
   * @see Tag
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  /**
   * Whether the tag should be disabled or not, changing its behaviour and layout.
   * When it is disabled, it cannot be clicked.
   * @see Tag
   */
  disabled: PropTypes.bool,
  /**
   * The class to be added to the component style.
   * @see Tag
   */
  className: PropTypes.string,
  /**
   * Function handling the click on the entire tag.
   * When this is given, the tag will serve as a button.
   * The click has no effect if the tag is disabled.
   * The click gives the tag updated state of the switch,
   * as the opposed of the active state it has before.
   * @param id {number || string} the given id
   * @param active {boolean} the status of the switch after the click
   * @see Tag
   */
  onClick: PropTypes.func.isRequired,
};

SwitchTag.defaultProps = {
  id: null,
  active: false,
  disabled: false,
  className: null,
};

module.exports = SwitchTag;
