/* eslint-disable default-case */

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BuFilter = require('../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
var BusinessUnitService = require('../../../services/BusinessUnitService');
var FiscalYearService = require('../../../services/FiscalYearService');

Wethod.module('ReportApp.threeYP', function (threeYP, Wethod, Backbone, Marionette, $, _) {
  var columnGraph = function (categories, series, plotOptions, tooltip) {
    return {
      chart: {
        type: 'column',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: true },
      legend: { enabled: false },
      xAxis: {
        lineWidth: 1,
        title: {
          enabled: false,
        },
        categories: categories,
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
        },
        title: {
          enabled: false,
        },
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numeral(this.total).format('0,0');
          },
          style: {
            fontFamily: 'Rubik',
            color: '#363a4d',
          },
        },
      },
      tooltip: tooltip,
      plotOptions: plotOptions,
      series: series,
    };
  };

  var byClientChart = function (categories, series, plotOptions, tooltip) {
    return {
      chart: {
        type: 'column',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: true },
      legend: { enabled: false },
      xAxis: {
        lineWidth: 1,
        title: {
          enabled: false,
        },
        categories: categories,
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
        },
        title: {
          enabled: false,
        },
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numeral(this.total).format('0,0');
          },
          style: {
            fontFamily: 'Rubik',
            color: '#363a4d',
          },
        },
      },
      tooltip: tooltip,
      plotOptions: plotOptions,
      series: series,
    };
  };

  var byProjectLabelChart = function (categories, series, plotOptions, tooltip) {
    return {
      chart: {
        type: 'column',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: true },
      legend: { enabled: false },
      xAxis: {
        lineWidth: 1,
        title: {
          enabled: false,
        },
        categories: categories,
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
        },
        title: {
          enabled: false,
        },
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numeral(this.total).format('0,0');
          },
          style: {
            fontFamily: 'Rubik',
            color: '#363a4d',
          },
        },
      },
      tooltip: tooltip,
      plotOptions: plotOptions,
      series: series,
    };
  };

  var byProjectLabelGroupChart = function (categories, series, plotOptions, tooltip) {
    return {
      chart: {
        type: 'column',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: true },
      legend: { enabled: false },
      xAxis: {
        lineWidth: 1,
        title: {
          enabled: false,
        },
        categories: categories,
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
        },
        title: {
          enabled: false,
        },
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numeral(this.total).format('0,0');
          },
          style: {
            fontFamily: 'Rubik',
            color: '#363a4d',
          },
        },
      },
      tooltip: tooltip,
      plotOptions: plotOptions,
      series: series,
    };
  };

  var byMetadataChart = function (categories, series, plotOptions, tooltip) {
    return {
      chart: {
        type: 'column',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: true },
      legend: { enabled: false },
      xAxis: {
        lineWidth: 1,
        title: {
          enabled: false,
        },
        categories: categories,
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
        },
        title: {
          enabled: false,
        },
        style: {
          fontFamily: 'Rubik',
          color: '#363a4d',
        },
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter: function () {
            return numeral(this.total).format('0,0');
          },
          style: {
            fontFamily: 'Rubik',
            color: '#363a4d',
          },
        },
      },
      tooltip: tooltip,
      plotOptions: plotOptions,
      series: series,
    };
  };

  this.FilterItemView = Marionette.ItemView.extend({
    template: '#filterSelectItemTemplate',
    className: 'wethod-filter-select__item',
    tagName: 'li',
    triggers: {
      click: 'choose:item',
    },
  });

  this.FilterListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    childView: threeYP.FilterItemView,
    triggers: {
      'choose:item': 'choose:item',
    },
  });

  this.FilterSelectView = Marionette.LayoutView.extend({
    _listCollection: [],
    _listView: null,
    template: '#filterSelectTemplate',
    className: 'wethod-filter-select',
    regions: {
      items: '[data-region="items"]',
    },
    ui: {
      button: 'button',
      list: '.wethod-filter-select__list',
    },
    events: {
      'click @ui.button': 'onButtonClick',
    },
    childEvents: {
      'choose:item': 'onChildChooseItem',
    },
    initialize: function (options) {
      this._listCollection = options.listCollection;
    },
    onRender: function () {
      this.showList();
    },
    showList: function () {
      var listView = new threeYP.FilterListView({ collection: this._listCollection });
      this.getRegion('items').show(listView);
      this._listView = this.getRegion('items').currentView;
    },
    onButtonClick: function () {
      this.ui.list.toggle();
    },

    onChildChooseItem: function (childView, args) {
      var prevName = this.model.get('name');
      this.model.set('name', args.model.get('name'));
      this.render();
      if (args.model.get('name') !== prevName) {
        this.trigger('choose:item', args.model);
      }
    },
  });

  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#report3YPStructureTemplate',
    className: 'fluid',
    _availableBusinessUnits: [],
    _businessUnit: '',
    _reports: null,
    regions: {
      headerSection: '[data-region="reportSectionHeader"]',
      businessUnitSelect: '[data-region="businessUnitSelect"]',
      byProbabilityGraph: '[data-region="byProbability"]',
      byProbabilityGraphDetail: '[data-region="byProbabilityGraphDetail"]',

      byClientGraph: '[data-region="byClient"]',
      byClientGraphDetail: '[data-region="byClientGraphDetail"]',

      byProjectLabelGraph: '[data-region="byProjectLabel"]',
      byProjectLabelGraphDetail: '[data-region="byProjectLabelGraphDetail"]',

      byProjectLabelGroupGraph: '[data-region="byProjectLabelGroup"]',
      byProjectLabelGroupGraphDetail: '[data-region="byProjectLabelGroupGraphDetail"]',

      budgetBreakdownGraph: '[data-region="budgetBreakdown"]',
      budgetBreakdownGraphDetail: '[data-region="budgetBreakdownGraphDetail"]',

      byMetadataGraph: '[data-region="byMetadata"]',
      byMetadataGraphDetail: '[data-region="byMetadataGraphDetail"]',
      metadataTypeSelect: '[data-region="metadataTypeSelect"]',
      metadataValueSelect: '[data-region="metadataValueSelect"]',

      modal: '[data-region="appDialog"]',
    },
    ui: {
      byProbabilityDetailWrap: '[data-region="byProbabilityDetailWrap"]',
      byClientDetailWrap: '[data-region="byClientDetailWrap"]',
      byProjectLabelDetailWrap: '[data-region="byProjectLabelDetailWrap"]',
      byProjectLabelGroupDetailWrap: '[data-region="byProjectLabelGroupDetailWrap"]',
      byMetadataDetailWrap: '[data-region="byMetadataDetailWrap"]',
      exportEl: '[data-action="export"]',
      budgetBreakdownWrap: '[data-region="budgetBreakdownDetailWrap"]',
    },
    events: {
      'click @ui.exportEl': 'exportModule',
    },
    initialize: function (options) {
      this._businessUnitService = new BusinessUnitService();
      this._fiscalYearService = new FiscalYearService();
      this._reports = options.reports;
      this.model = new Backbone.Model({ showByCategoryReport: !_.isUndefined(this._reports.get('by_category')) });
    },
    getYears: function () {
      var currentYear = this._fiscalYearService.getShiftedYear(moment().format('YYYY'));
      return [
        currentYear - 1,
        currentYear,
        currentYear + 1,
      ];
    },
    exportModule: function (e) {
      e.preventDefault();
      threeYP.showModalExport();
    },
    onRender: function () {
      if (!_.isUndefined(this._reports.get('by_category'))) {
        this.showBusinessUnitFilter();
      }
      this.showReports();
      this.showMetadataTypeFilter();
      this.showMetadataValueFilter();

      dispatcher.on('choose:metadata:type', this.noMetadataReport.bind(this));
      dispatcher.on('choose:metadata:value', this.showMetadataReport.bind(this));
    },
    noMetadataReport: function () {
      if (threeYP.metadataType === 'No metadata' || threeYP.metadataType === 'All') {
        this.showMetadataReport();
      }
    },
    showReports: function () {
      this.showProbabilityReport();
      this.showClientReport();
      this.showProjectTypeReport();
      this.showProjectTypeGroupReport();
      this.showMetadataReport();
      this.showBudgetBreakdownReport();
    },
    hideReportsDetails: function () {
      this.ui.byProbabilityDetailWrap.removeClass('isVisible');
      this.ui.byClientDetailWrap.removeClass('isVisible');
      this.ui.byProjectLabelDetailWrap.removeClass('isVisible');
      this.ui.byMetadataDetailWrap.removeClass('isVisible');
      this.ui.budgetBreakdownWrap.removeClass('isVisible');
      if (this._reports.get('by_types')) {
        this.ui.byProjectLabelGroupDetailWrap.removeClass('isVisible');
      }
    },
    showBudgetBreakdownReport: function () {
      var data = this._reports.get('budget_breakdown');
      var series = threeYP.budgetBreakdownGraphSeries(data);
      var graphModel = new threeYP.GraphModel({
        series: series,
      });
      var graphView = new threeYP.ColumnTemplateView({
        model: graphModel,
        type: 'budgetBreakdown',
      });

      this.budgetBreakdownGraph.show(graphView);
    },
    showProbabilityReport: function () {
      var years = this.getYears();
      var data = this._reports.get('by_probabilities');
      var probabilitySeries = threeYP.probabilityGraphSeries(years, data);
      var fiscalYearService = this._fiscalYearService;
      var formattedYears = years.map(function (year) {
        if (fiscalYearService.isSolarYear()) {
          return year;
        }
        return fiscalYearService
          .getYearDynamically(year);
      });
      var probabilityGraph = new threeYP.GraphModel({
        categories: formattedYears,
        series: probabilitySeries,
      });
      var probabilityGraphTemplate = new threeYP.ColumnTemplateView({
        model: probabilityGraph,
        type: 'probability',
      });

      this.byProbabilityGraph.show(probabilityGraphTemplate);
    },
    showClientReport: function () {
      var years = this.getYears();
      var data = this._reports.get('by_clients');
      var clientSeries = threeYP.clientGraphSeries(years, data);
      var fiscalYearService = this._fiscalYearService;
      var formattedYears = years.map(function (year) {
        if (fiscalYearService.isSolarYear()) {
          return year;
        }
        return fiscalYearService
          .getYearDynamically(year);
      });

      var clientGraphModel = new threeYP.GraphModel({
        categories: formattedYears,
        series: clientSeries,
      });
      var clientGraphTemplate = new threeYP.ColumnTemplateView({
        model: clientGraphModel,
        type: 'byClient',
      });

      this.byClientGraph.show(clientGraphTemplate);
    },
    showProjectTypeReport: function () {
      var years = this.getYears();
      var data = this._reports.get('by_types');
      var projectLabelSeries = threeYP.projectLabelGraphSeries(years, data);
      var fiscalYearService = this._fiscalYearService;
      var formattedYears = years.map(function (year) {
        if (fiscalYearService.isSolarYear()) {
          return year;
        }
        return fiscalYearService
          .getYearDynamically(year);
      });
      var projectLabelGraphModel = new threeYP.GraphModel({
        categories: formattedYears,
        series: projectLabelSeries,
      });
      var projectLabelGraphTemplate = new threeYP.ColumnTemplateView({
        model: projectLabelGraphModel,
        type: 'byProjectLabel',
      });

      this.byProjectLabelGraph.show(projectLabelGraphTemplate);
    },
    showProjectTypeGroupReport: function () {
      var years = this.getYears();
      var data = this._reports.get('by_category');
      if (data) {
        var projectLabelGroupSeries = threeYP.projectLabelGroupGraphSeries(years, data);
        var fiscalYearService = this._fiscalYearService;
        var formattedYears = years.map(function (year) {
          if (fiscalYearService.isSolarYear()) {
            return year;
          }
          return fiscalYearService
            .getYearDynamically(year);
        });
        var projectLabelGroupGraphModel = new threeYP.GraphModel({
          categories: formattedYears,
          series: projectLabelGroupSeries,
        });
        var projectLabelGroupGraphTemplate = new threeYP.ColumnTemplateView({
          model: projectLabelGroupGraphModel,
          type: 'byProjectLabelGroup',
        });

        this.byProjectLabelGroupGraph.show(projectLabelGroupGraphTemplate);
      }
    },
    showMetadataReport: function () {
      var years = this.getYears();
      var data = this._reports.get('by_metadata');
      var metadataSeries = threeYP.metadataGraphSeries(years, data);
      var fiscalYearService = this._fiscalYearService;
      var formattedYears = years.map(function (year) {
        if (fiscalYearService.isSolarYear()) {
          return year;
        }
        return fiscalYearService
          .getYearDynamically(year);
      });
      var metadataGraphModel = new threeYP.GraphModel({
        categories: formattedYears,
        series: metadataSeries,
      });
      var metadataGraphTemplate = new threeYP.ColumnTemplateView({
        model: metadataGraphModel,
        type: 'byMetadata',
      });

      this.byMetadataGraph.show(metadataGraphTemplate);
    },
    showBusinessUnitFilter: function () {
      if (this._businessUnitService.isEnabled()) {
        var businessUnitSelect = React.createElement(BuFilter, {
          selectedValues: this._businessUnitService.getLastSelected(),
          onApply: this.onBusinessUnitChanged.bind(this),
        });
        var container = document.querySelector(this.businessUnitSelect.el);
        ReactDOM.render(businessUnitSelect, container);
      }
    },
    showMetadataTypeFilter: function () {
      var types = this.getMetadataTypes();
      var select = new Wethod.View.MetadataTypeFilter.SelectView({
        types: types,
        threeYP: threeYP,
      });
      this.getRegion('metadataTypeSelect').show(select);
      return this.getRegion('metadataTypeSelect').currentView;
    },
    showMetadataValueFilter: function () {
      var values = this.getMetadataValues();
      var select = new Wethod.View.MetadataValueFilter.SelectView({
        values: values,
        threeYP: threeYP,
      });
      this.getRegion('metadataValueSelect').show(select);
      return this.getRegion('metadataValueSelect').currentView;
    },
    showLoading: function () {
      this.byProbabilityGraph.show(new Wethod.View.Loading.SmallView());
      this.byClientGraph.show(new Wethod.View.Loading.SmallView());
      this.byProjectLabelGraph.show(new Wethod.View.Loading.SmallView());
      this.byProjectLabelGroupGraph.show(new Wethod.View.Loading.SmallView());
      this.byMetadataGraph.show(new Wethod.View.Loading.SmallView());
      this.budgetBreakdownGraph.show(new Wethod.View.Loading.SmallView());
    },
    onBusinessUnitChanged: function (name, selectedValues) {
      this.hideReportsDetails();
      this.showLoading();
      this._businessUnit = selectedValues;
      var reportDataRequest = Wethod.request('get:report:threeYP', this._businessUnit);
      $.when(reportDataRequest).done(function (reportDataResponse) {
        this._reports = reportDataResponse;
        this.trigger('report:data:updated', reportDataResponse);
        this.showReports();
      }.bind(this));
    },
    getMetadataTypes: function () {
      var types = [];
      var years = this._reports.get('by_metadata');
      _.each(years, function (year) {
        var values = _.keys(year);
        _.each(values, function (value) {
          if (!_.includes(types, value)) {
            types.push(value);
          }
        });
      });
      return _.map(types, function (item) {
        return {
          id: item,
          name: item,
        };
      });
    },
    getMetadataValues: function () {
      var metadataValues = [];
      var years = this._reports.get('by_metadata');
      _.each(years, function (type) {
        var values = _.keys(type);
        _.each(values, function (value) {
          if (!_.includes(metadataValues, value)) {
            metadataValues[value] = [];
          }
        });
      });

      _.each(years, function (type) {
        _.each(type, function (metadata, currentType) {
          var values = _.keys(metadata);
          _.each(values, function (value) {
            if (!_.includes(metadataValues[currentType], value)) {
              metadataValues[currentType].push(value);
            }
          });
        });
      });

      var types = _.keys(metadataValues);
      for (var i = 0; i < types.length; i++) {
        metadataValues[types[i]] = _.map(metadataValues[types[i]], function (item) {
          return {
            id: item,
            name: item,
          };
        });
      }
      return metadataValues;
    },
  });

  this.ColumnTemplateView = Marionette.ItemView.extend({
    template: '#report3YPColumnGraphTemplate',
    className: 'col_16 no_padding',
    ui: {
      graphContainerEl: '[data-region="ColumnGraphContainer"]',
    },
    initialize: function (options) {
      this.options = options || {};
      this._fiscalYearService = new FiscalYearService();
    },
    onShow: function () {
      var plotOptions = {};
      var tooltip = {};

      switch (this.options.type) {
        case 'probability':
          plotOptions = {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return (this.y > 0) ? this.series.name + '%' : '';
                },
                color: '#FFFFFF',
                style: {
                  fontFamily: 'Rubik',
                  textShadow: '0',
                  textOutline: 0,
                },
              },
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP.showByProbabilityDetail(this.series.name, this.category);
                  },
                },
              },
            },
          };
          tooltip = {
            formatter: function () {
              return '<b>' + this.x + '</b><br/>'
                + this.series.name + '% : ' + numeral(parseInt(this.y)).format('0,0') + '<br/>';
            },
          };

          this.ui.graphContainerEl.highcharts(
            columnGraph(this.model.get('categories'), this.model.get('series'), plotOptions, tooltip)
          );
          break;
        case 'byClient':
          plotOptions = {
            column: { stacking: 'normal' },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP.showByClientDetail(parseInt(this.stackTotal), parseInt(this.category));
                  },
                },
              },
            },
          };
          tooltip = { enabled: false };
          this.ui.graphContainerEl.highcharts(
            byClientChart(this.model.get('categories'), this.model.get('series'), plotOptions, tooltip)
          );
          break;
        case 'byProjectLabel':
          plotOptions = {
            column: { stacking: 'normal' },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP
                      .showByProjectLabelDetail(parseInt(this.stackTotal), parseInt(this.category));
                  },
                },
              },
            },
          };
          tooltip = { enabled: false };
          this.ui.graphContainerEl.highcharts(
            byProjectLabelChart(this.model.get('categories'), this.model.get('series'), plotOptions, tooltip)
          );
          break;
        case 'byProjectLabelGroup':
          plotOptions = {
            column: { stacking: 'normal' },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP.showByProjectLabelGroupDetail(
                      parseInt(this.stackTotal),
                      parseInt(this.category)
                    );
                  },
                },
              },
            },
          };
          tooltip = { enabled: false };
          this.ui.graphContainerEl.highcharts(
            byProjectLabelGroupChart(this.model.get('categories'), this.model.get('series'), plotOptions, tooltip)
          );
          break;
        case 'byMetadata':
          dispatcher.on('choose:metadata:value', threeYP.closeMetadataDetail);
          dispatcher.on('choose:metadata:type', threeYP.closeMetadataDetail);

          plotOptions = {
            column: { stacking: 'normal' },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP
                      .showByMetadataDetail(parseInt(this.stackTotal), parseInt(this.category));
                  },
                },
              },
            },
          };
          tooltip = { enabled: false };
          this.ui.graphContainerEl.highcharts(
            byMetadataChart(this.model.get('categories'), this.model.get('series'), plotOptions, tooltip)
          );
          break;
        case 'budgetBreakdown':
          var year = moment().format('YYYY');
          var dynamicYear = this._fiscalYearService
            .getYearDynamically(this._fiscalYearService.getShiftedYear(year));
          var categories = ['Budget ' + dynamicYear, 'Projected ' + dynamicYear, ''];

          plotOptions = {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
                color: '#FFFFFF',
                style: {
                  fontFamily: 'Rubik',
                  textShadow: '0',
                  textOutline: 0,
                },
              },
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    threeYP.showBudgetBreakdownDetail(this.series.name, dynamicYear, this.y);
                  },
                },
              },
            },
          };
          tooltip = {
            formatter: function () {
              return '<b>' + dynamicYear + '</b><br/><b>'
                + this.series.name + '</b><br/>' + numeral(parseInt(this.y))
                .format('0,0') + '<br/>';
            },
          };

          this.ui.graphContainerEl.highcharts(
            columnGraph(categories, this.model.get('series'), plotOptions, tooltip)
          );
          break;
      }
    },
  });

  // EXPORT
  var modalW = 350;
  var modalH = 200;
  this.ModalExportItemView = Marionette.ItemView.extend({
    template: '#exportModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      threeYP.doModuleExport(this);
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.ColumnTemplateDetailBudgetBreakdownView = Marionette.ItemView.extend({
    template: '#report3YPBudgetBreakdownColumnGraphDetailRowTemplate',
    tagName: 'li',
    className: 'col_16 padding_tb budget-breakdown__row',
  });

  this.DetailCompositeBudgetBreakdownView = Marionette.CompositeView.extend({
    template: '#report3YPBudgetBreakdownColumnGraphDetailTemplate',
    className: 'col_16',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailBudgetBreakdownView,
  });

  this.ColumnTemplateDetailView = Marionette.ItemView.extend({
    template: '#report3YPByProbabilityColumnGraphDetailRowTemplate',
    tagName: 'li',
    className: 'col_16 padding_tb',
  });

  this.DetailCompositeView = Marionette.CompositeView.extend({
    template: '#report3YPByProbabilityColumnGraphDetailTemplate',
    className: 'col_16',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailView,
  });

  this.ColumnTemplateDetailClientView = Marionette.ItemView.extend({
    template: '#report3YPByClientColumnGraphDetailRowTemplate',
    tagName: 'li',
    className: 'col_16 padding_tb',
  });

  this.DetailCompositeClientView = Marionette.CompositeView.extend({
    template: '#report3YPByClientColumnGraphDetailTemplate',
    className: 'col_16',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailClientView,
  });

  this.ColumnTemplateDetailProjectLabelView = Marionette.ItemView.extend({
    template: '#report3YPByProjectLabelColumnGraphDetailRowTemplate',
    tagName: 'li',
    className: 'col_16 padding_tb',
  });

  this.DetailCompositeProjectLabelView = Marionette.CompositeView.extend({
    template: '#report3YPByProjectLabelColumnGraphDetailTemplate',
    className: 'col_16',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailProjectLabelView,
  });

  this.DetailCompositeProjectLabelGroupView = Marionette.CompositeView.extend({
    template: '#report3YPByProjectLabelGroupColumnGraphDetailTemplate',
    className: 'col_16 threeYP__last-section',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailProjectLabelView,
  });

  this.ColumnTemplateDetailMetadataView = Marionette.ItemView.extend({
    template: '#report3YPByMetadataColumnGraphDetailRowTemplate',
    tagName: 'li',
    className: 'col_16 padding_tb',
  });

  this.DetailCompositeMetadataView = Marionette.CompositeView.extend({
    template: '#report3YPByMetadataColumnGraphDetailTemplate',
    className: 'col_16',
    childViewContainer: '[data-region="monthList"]',
    childView: threeYP.ColumnTemplateDetailMetadataView,
  });

  this.DetailCompositeEmptyMetadataView = Marionette.CompositeView.extend({
    template: '#report3YPByMetadataColumnGraphDetailEmptyTemplate',
    className: 'col_16',
  });
});
