const React = require('react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const RequestManager = require('../../../../containers/RequestManager');
const Content = require('./TaskSortModalContent.react');

const TaskSortModal = ({ closeModal, areaId, areaOrder, tasks }) => (
  <Modal title="Reorder tasks" onClose={closeModal}>
    <RequestManager>
      <Content areaId={areaId} areaOrder={areaOrder} tasks={tasks} closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = TaskSortModal;
