const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  urlRoot: `${APIURL}/notification/`,
  getLast() {
    const defer = $.Deferred();

    $.when(this.load())
      .done((model) => {
        if (!model.attributes.title) {
          defer.reject();
        } else {
          defer.resolve(model.attributes);
        }
      });

    return defer.promise();
  },
  markAsRead(news) {
    const defer = $.Deferred();

    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: `${APIURL}/notification/${news.id}`,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      defer.resolve(response);
    }).fail((response) => {
      defer.reject(response);
    });

    return defer.promise();
  },
});
