const React = require('react');
const RequestManager = require('../../../../containers/RequestManager');
const Content = require('./ProductInsertModalContent.react');

/**
 * Modal used to insert multiple product for an area.
 *
 * @param {function} closeModal Function that closes modal
 * @param {number} areaId ID of area where products must be added
 * @param {number} lastTaskSort Sort number of the next task related to area
 * @return {JSX.Element}
 * @constructor
 */
const ProductInsertModal = ({ closeModal, areaId, nextTaskOrder }) => (
  <RequestManager>
    <Content
      areaId={areaId}
      nextTaskOrder={nextTaskOrder}
      closeModal={closeModal} />
  </RequestManager>
);

module.exports = ProductInsertModal;
