const { connect } = require('react-redux');
const Resource = require('../../components/Resources/Resource.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  person: state.person,
});

const mapDispatchToProps = (dispatch) => ({
  delete: (personId, resource) => dispatch(actions.deleteResource(personId, resource)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Resource);
