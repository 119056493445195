const React = require('react');
const Levels = require('../../Cell/LevelsCell.react');
const Button = require('./IntercompanyTaskInviteButton.react');
const DaysCell = require('../../Cell/DaysCell.react');
const IntercompanyLabel = require('./IntercompanyLabel.react');

const IntercompanyTaskLevels = ({
  isSent, isConnected, supplier, budgetIsEditable, items,
  onInviteClick, onLabelClick,
}) => {
  const levels = items
    .filter((level) => level.is_visible).map((level) => <DaysCell key={level.id} />);

  return (
    <Levels className="wethod-budget-task__levels wethod-budget-task__levels--intercompany">
      {levels}
      <div className="wethod-budget-task__intercompany-data">
        <IntercompanyLabel onClick={onLabelClick} isSent={isSent}>
          Intercompany task supplied by {supplier.name}
        </IntercompanyLabel>
        <Button isTaskConnected={isConnected}
          editable={!budgetIsEditable}
          isInviteSent={isSent}
          onClick={onInviteClick} />
      </div>
    </Levels>
  );
};

module.exports = IntercompanyTaskLevels;
