'use strict';

Wethod.module('ExpensesApp.Expenses', function (Expenses, Wethod, Backbone, Marionette) {
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#appTemplate',
    regions: {
      headerSection: '[data-region="sectionHeader"]',
      expensesBody: '[data-region="body"]',
    },
  });
});
