/**
 * Filter items on a given language.
 * The item must contain the given language.
 *
 * @param items
 * @param language
 * @returns {Array} items filtered by language
 */
module.exports.filterByLanguage = (items, language) => {
  /**
   * Returns true if value corresponds key.
   *
   * @param keyword
   * @param value
   * @returns {boolean}
   */
  const found = (keyword, value) => {
    if (value && keyword) {
      return value.toLowerCase() === keyword.toLowerCase();
    }
    return false;
  };

  // Returns true if the item has data for the selected language.
  const isInLanguage = (item) => {
    if (item.languages && item.languages.length) {
      const selectedLanguageFound = item.languages
        .filter((lang) => found(language, lang.name));
      return selectedLanguageFound && selectedLanguageFound.length;
    }
    return false;
  };

  return items.filter((item) => (isInLanguage(item)));
};

/**
 * Filter items on a given list of skills.
 * The item must contain ALL the given skills.
 *
 * @param {Array} items
 * @param {Array} skills
 * @returns {Array} items filtered by skills
 */
module.exports.filterBySkills = (items, skills) => {
  // Returns true if the item has the given skill.
  const hasGivenSkill = (item, skill) => item.skills.some((itemSkill) => itemSkill.id === skill);

  const hasAllSkills = (item) => {
    if (item.skills && item.skills.length) {
      return skills.every((skill) => hasGivenSkill(item, skill));
    }

    return false;
  };

  const selectAll = () => !skills || !skills.length || skills.indexOf('all') >= 0;

  return selectAll() ? items : items.filter((item) => (hasAllSkills(item)));
};

/**
 * Filter items on a given list of interests.
 * The item must contain ALL the given interests.
 *
 * @param {Array} items
 * @param {Array} interests
 * @returns {Array} items filtered by interests
 */
module.exports.filterByInterests = (items, interests) => {
  // Returns true if the item has the given interest.
  const hasGivenInterest = (item, interest) => item.interests
    .some((itemInterest) => itemInterest.id === interest);

  const hasAllInterests = (item) => {
    if (item.interests && item.interests.length) {
      return interests.every((interest) => hasGivenInterest(item, interest));
    }

    return false;
  };

  const selectAll = () => !interests || !interests.length || interests.indexOf('all') >= 0;

  return selectAll() ? items : items.filter((item) => (hasAllInterests(item)));
};

/**
 * Filter items on a given key.
 * The key must be contained in some attributes for the item to be shown.
 *
 * @param key
 * @param items
 * @returns {Array} items filtered by key
 */
module.exports.filterByKey = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param keyword
   * @param value
   * @returns {boolean}
   */
  const found = (keyword, value) => {
    if (value) return value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    return false;
  };

  const isInName = (keyword, name) => found(keyword, _.unescape(name));

  const isInSurname = (keyword, surname) => found(keyword, _.unescape(surname));

  return key
    ? items.filter((item) => (isInName(key, item.name) || isInSurname(key, item.surname)))
    : items;
};
