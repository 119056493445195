/* eslint-disable react/sort-comp,consistent-return,class-methods-use-this,react/no-access-state-in-setstate */
const React = require('react');
const TextInput = require('../../../../../../../../common/react/TextInput.react');

module.exports = class Work extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      url: this.props.url,
      errors: [],
    };

    this.timer = 0;
  }

  getUrl(address) {
    if (address) {
      if (address.indexOf('http') !== -1) {
        return address;
      }
      return `http://${address}`;
    }
    return '';
  }

  save() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.state.errors.length) {
        this.props.updateWork({
          id: this.props.id,
          name: this.state.name,
          url: this.getUrl(this.state.url),
        }, this.props.employee);
      }
    }, 500);
  }

  onChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.save();
    });
  }

  addError(name) {
    // Passing a function to setState lets you avoid async state update problems.
    if (this.state.errors.indexOf(name) === -1) {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.concat(name),
      }), () => {
        this.props.updateFormErrors(this.props.id, 'add');
      });
    }
  }

  removeError(name) {
    this.setState({ errors: this.state.errors.filter((error) => error !== name) }, () => {
      this.props.updateFormErrors(this.props.id, 'remove');
    });
  }

  onValidate(name, errors) {
    if (errors.length > 0) {
      this.addError(name);
    } else {
      this.removeError(name);
    }
  }

  getInputClassName(name) {
    let className = `profile-portfolio__item-${name}`;
    if (this.state.errors.indexOf(name) !== -1) {
      className = `${className} wethod-modal2-input--error`;
    }
    return className;
  }

  onDeleteClick() {
    this.props.deleteWork({
      id: this.props.id,
      name: this.state.name,
      url: this.state.url,
    });
    this.props.updateFormErrors(this.props.id, 'remove');
  }

  render() {
    return (
      <div className="profile-portfolio__work">
        <span className={this.getInputClassName('name')}>
          <TextInput name="name"
            value={this.state.name}
            constraints="required"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
        </span>
        <span className={this.getInputClassName('url')}>
          <TextInput name="url"
            value={this.state.url}
            constraints="required"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
        </span>
        <button type="button"
          onClick={this.onDeleteClick.bind(this)}
          className="profile-languages__item-delete wethod-icon-button">
          <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
        </button>
      </div>
    );
  }
};
