const ValidationService = {
  isEmpty(value) {
    return value.trim() === '';
  },
  isValidURL(string) {
    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=]+$/;

    return pattern.test(string);
  },
};

module.exports = ValidationService;
