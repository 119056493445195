const React = require('react');
const CannotUploadFileModal = require('./Files/modals/CannotUploadModal.react');
const CannotDownloadFileModal = require('./Files/modals/CannotDownloadModal.react');

const Modal = ({
  toShow, canEdit, closeModal, data,
}) => {
  const getModal = () => {
    switch (toShow) {
      case 'cannot-upload-file':
        return <CannotUploadFileModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'cannot-download-file':
        return <CannotDownloadFileModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
