const { connect } = require('react-redux');
const Component = require('../../../../../components/Footer/DefaultFooterContent/Actions/BaselineAction/BaselineModal.react');
const actions = require('../../../../../actions');

const mapStateToProps = (state) => ({
  baselines: state.baselines,
  value: state.baselines.find((baseline) => baseline.baseline).budgetId,
  isPending: state.waitingFor.filter((key) => key === 'save-budget-info').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (baseline) => dispatch(actions.setNewBaseline(baseline)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
