/* eslint-disable no-useless-constructor,class-methods-use-this */
const Area = require('./Area');

module.exports = class AccountArea extends Area {
  constructor(area, userPermissions) {
    super(area, userPermissions);
  }

  isVisible() {
    return true;
  }

  isBlocked() {
    return false;
  }
};
