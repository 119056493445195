/* eslint-disable react/sort-comp */
const React = require('react');

module.exports = class ProductionChart extends React.Component {
  constructor(props) {
    super(props);

    const roundedPlannedProduction = Math.round(this.props.planned * 100) / 100;

    this.options = {
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'none',
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontWeight: 100,
          fontSize: 11,
        },
        symbolHeight: 0.001,
        align: 'left',
        itemHoverStyle: {
          cursor: 'default',
        },
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            color: 'rgba(0,0,0,0.87)',
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
            },
            formatter() {
              return `${this.y}%`;
            },
          },
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 1,
            lineColor: null, // inherit from series
            marker: 'circle',
          },
          events: {
            legendItemClick(e) {
              e.preventDefault();
            },
          },
        },
      },
      xAxis: {
        categories: this.getBreakpoints(),
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0,
        labels: {
          enabled: this.props.weeks.length > 0,
          style: {
            textOverflow: 'none',
            color: '#CCCBCC',
          },
        },
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        // min: this.props.min,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        gridLineWidth: 0,
        plotLines: [{
          color: '#F8A01C',
          width: 1,
          value: roundedPlannedProduction,
          dashStyle: 'dash',
          zIndex: 4,
          label: {
            text: `${roundedPlannedProduction}%`,
            align: 'right',
            x: -10,
          },
        }],
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        name: 'Weekly production',
        data: this.props.weeks.map((week) => week.production),
      },
      { // Fake series corresponding to plot line to adapt max and show legend
        color: '#F8A01C',
        name: 'Production plan for the current month',
        dashStyle: 'dash',
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        data: [roundedPlannedProduction],
      }],
    };
  }

  getBreakpoints() {
    const { weeks } = this.props;

    return weeks.map((week, index) => `W${index + 1}`);
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    return { ...this.options };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div>
        <div ref={(chartContainer) => this.chartContainer = chartContainer} style={{ height: '150px' }} />
      </div>
    );
  }
};
