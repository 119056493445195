function getLevelByLanguage(item, languageName) {
  if (item) {
    const language = item.languages
      .filter((lang) => lang.name.toLowerCase() === languageName.toLowerCase());
    if (!language || !language.length) return null;

    return language[0].level;
  }
  return null;
}

/**
 *
 * Sort item by language level.
 * The language considered is the one given as selectedLanguage.
 *
 * @param items
 * @param selectedLanguage
 * @param order
 * @returns {Array} items sorted by language level
 */
module.exports.sortByLanguage = (items, selectedLanguage, order = 'desc') => items.sort((a, b) => {
  const levelA = getLevelByLanguage(a, selectedLanguage);
  const levelB = getLevelByLanguage(b, selectedLanguage);

  if (order === 'desc') {
    return levelA - levelB;
  }
  if (order === 'asc') {
    return levelB - levelA;
  }

  return 0;
});
