const { connect } = require('react-redux');
const Component = require('../../../../../components/Footer/DefaultFooterContent/Actions/MulticurrencyAction/MulticurrencyAction.react');
const Reducer = require('../../../../../reducer');
const actions = require('../../../../../actions');

const mapStateToProps = (state) => ({
  enabled: state.info.currency_enabled,
  currenciesAvailable: Reducer.currenciesAvailable(state),
  isIntercompanySupplier: state.project.intercompany,
});

const mapDispatchToProps = (dispatch) => ({
  showModalDisableMulticurrency: () => dispatch(actions.showModalDisableMulticurrency()),
  showModalEnableMulticurrency: () => dispatch(actions.showModalEnableMulticurrency()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
