const React = require('react');
const Switch = require('../../../../../../common/react/TabSwitcher.react');

const TypeSwitcher = ({ items, current, date, onChange }) => {
  function hasChanged(item) {
    return item && item !== current;
  }

  function handleClick(item) {
    if (hasChanged(item)) {
      onChange(date, item);
    }
  }

  return (
    <Switch items={items} current={current} handleClick={handleClick} />
  );
};

module.exports = TypeSwitcher;
