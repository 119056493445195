const { connect } = require('react-redux');
const Component = require('../../components/WorkingConnections/Chart.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  data: state.workingConnectionTrend,
});

const mapDispatchToProps = (dispatch) => ({
  showWorkingConnectionModal: (projects, title) => dispatch(actions
    .showWorkingConnectionModal(projects, title)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
