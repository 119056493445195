'use strict';

Wethod.module('ReportApp', function (BriefingApp, Wethod, Backbone, Marionette) {
  this.ReactLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#appTemplate',
    regions: {
      body: '[data-region="body"]',
    },
  });
});
