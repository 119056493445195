/* eslint-disable react/prop-types,class-methods-use-this */
const React = require('react');

module.exports = class PlaygroundBanner extends React.Component {
  componentDidMount() {
    this.props.onHeightChange(this.el.offsetHeight);
  }

  getExitUrl() {
    return URL + TEAMSELECTION;
  }

  render() {
    return (
      <div className="playground-banner"
        ref={(el) => this.el = el}>
        <div className="playground-banner__content">
          <span className="playground-banner__message">
            <span className="bold">You are in Playground</span>
            {' '}
            <span
              className="playground-banner__message-description">
              – Learn how to use wethod without messing up your company data.
            </span>
          </span>
          <a href={this.getExitUrl()}
            className="wethod-button playground-banner__button">
            Exit Playground
          </a>
        </div>
      </div>
    );
  }
};
