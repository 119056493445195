const React = require('react');
const PropTypes = require('prop-types');
const InputLabelBase = require('../InputLabel.react');

const OutlinedInputLabel = ({ children, htmlFor, minimized }) => {
  const className = minimized ? 'wethod-input-label--minimized' : '';

  return (
    <InputLabelBase htmlFor={htmlFor}
      className={className}>
      {children}
    </InputLabelBase>
  );
};

OutlinedInputLabel.defaultProps = {
  minimized: false,
};

OutlinedInputLabel.propTypes = {
  /** Label content * */
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
};

module.exports = OutlinedInputLabel;
