const React = require('react');
const PropTypes = require('prop-types');

const sizeModifiers = {
  small: '-small',
  medium: '-medium',
  big: '',
};

const colorModifiers = {
  blue: '',
  black: '--black',
  white: '--white',
  green: '--green',
  red: '--red',
};

const Icon = ({ icon, size, color, className, label, variant }) => {
  /**
   * Construct the class that defines an icon.
   * An icon should have:
   * - base class
   * - icon class
   * - color class
   * - custom given classname
   *
   * @returns {string}
   */
  const getClassName = () => {
    const sizeModifier = sizeModifiers[size];
    const colorModifier = colorModifiers[color];

    const defaultClass = `wethod-icon${sizeModifier} wethod-icon--inline`;
    const baseIconClass = `wethod-icon${sizeModifier}-${icon}`;
    const iconClass = variant !== '' ? `${baseIconClass}--${variant}` : baseIconClass;
    const colorClass = `${baseIconClass}${colorModifier}`;

    let style = `${defaultClass} ${iconClass} ${colorClass}`;
    style = className ? `${style} ${className}` : style;

    return style;
  };

  return (
    <span className={getClassName()}
      aria-label={label} />
  );
};

Icon.propTypes = {
  /**
   * Name of the icon. Make sure to give an existing name in order to render the icon correctly.
   */
  icon: PropTypes.string.isRequired,
  /**
   * Size of the icon, from a list of available ones.
   */
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  /**
   * Color of the icon, from a list of available ones.
   */
  color: PropTypes.oneOf(['black', 'white', 'blue', 'green', 'red']),
  className: PropTypes.string,
  /**
   * Text used as aria-label when the icon needs to be accessible.
   */
  label: PropTypes.string,
  /**
   * Some icons has variants. I.e. in "notes--done", "done" is a variant of the base icon "notes".
   */
  variant: PropTypes.string,
};

Icon.defaultProps = {
  size: 'big',
  color: 'black',
  className: '',
  label: undefined,
  variant: '',
};

module.exports = Icon;
