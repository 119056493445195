const { round } = require('../../../../../services/MathService');

/**
 * Return the total in K of the billing group.
 * This include the project estimate (of the master project) and the value of all the linked projects.
 * When no billing group is given, the total is equal to the project estimate.
 * @param billingGroup
 * @param projectEstimate
 * @returns {*}
 */
function getBillingGroupTotalK(billingGroup, projectEstimate) {
  if (billingGroup) {
    const linkedTotal = billingGroup.linked_projects
      .reduce((total, project) => total + project.final_net_value, 0);

    return linkedTotal + projectEstimate;
  }

  return projectEstimate;
}
function formatDecimalValue(value) {
  return value ? round(value, 2) : value;
}

function getTotalInvoiced(invoices, currency) {
  let tot = 0;
  if (invoices) {
    Object.keys(invoices).forEach((date) => {
      invoices[date].forEach((invoice) => {
        if (currency) {
          const invoiceRate = invoice.rate || currency.default_exchange_rate;
          tot += formatDecimalValue(invoice.value * invoiceRate);
        } else {
          tot += invoice.value;
        }
      });
    });
  }
  return formatDecimalValue(tot);
}

function sumPlans(plans, currency) {
  let tot = 0;
  if (plans) {
    Object.keys(plans).forEach((date) => {
      const monthPlan = plans[date];
      if (currency) {
        const monthRate = monthPlan.exchange_rate || currency.default_exchange_rate;
        tot += formatDecimalValue(monthPlan.amount * monthRate * 1000);
      } else {
        tot += monthPlan.amount * 1000;
      }
    });
  }
  return formatDecimalValue(tot);
}

// In manual mode the total is based on plan, in auto mode it is always equal to the estimate
// If we have automatic mode on we need to calculate the total based on the rate in case of multicurrecy budget.
// The rate will be either the base rate if invoices with custom rates were not created yet, or the budget rate (recalculated rate based on invoices value and custom rate)
function getTotalPlanned(plans, status, estimate, currency, budgetExchangeRate) {
  if (status === 0) {
    let rate = 1;
    if (currency) {
      rate = currency.exchange_rate;
    }
    if (budgetExchangeRate) {
      rate = budgetExchangeRate;
    }
    return formatDecimalValue(estimate * rate * 1000);
  }
  return sumPlans(plans, currency);
}

function getPlanAuto(project, estimate) {
  const planAmounts = {};
  const date = moment(project.date_start);
  if (estimate) {
    const monthlyValue = estimate / project.duration;
    for (let i = 0; i < project.duration; i++) {
      const month = date.format('YYYY-MM');
      planAmounts[month] = { amount: monthlyValue };
      date.add(1, 'month');
    }
  }
  return planAmounts;
}

function getEstimate(estimate, currency, budgetRate) {
  const rate = budgetRate || (currency ? currency.exchange_rate : 1);

  return formatDecimalValue(estimate * rate * 1000);
}

function getBudgetRate(project) {
  return project.budget ? project.budget.exchange_rate : null;
}

function canBeSaved(planned, estimate) {
  return formatDecimalValue(planned)
    === formatDecimalValue(estimate);
}

module.exports = {
  getBillingGroupTotalK,
  getTotalInvoiced,
  getTotalPlanned,
  getPlanAuto,
  getEstimate,
  getBudgetRate,
  canBeSaved,
};
