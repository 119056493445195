const React = require('react');
const ItemDay = require('./CalendarHeaderItem/CalendarHeaderItemDay.react');
const ItemWeek = require('./CalendarHeaderItem/CalendarHeaderItemWeek.react');

const CalendarHeaderTrack = ({ items, zoom, international, last }) => {
  let children = null;

  const isFirstDayOfMonth = (date) => {
    if (date.date() === 1) {
      return true;
    }
    if (!international) {
      // In a 5 days calendar, the first day of month can be lost in the weekend
      return date.format('dddd') === 'Monday' && date.date() < 4;
    }
    return false;
  };

  const containsFirstDayOfMonth = (dates) => dates.find((date) => isFirstDayOfMonth(moment(date)));

  if (zoom === 'week') {
    children = (
      <ItemWeek days={items}
        isFirstDayOfMonth={containsFirstDayOfMonth(items)}
        last={last} />
    );
  } else {
    children = items.map((item) => (
      <ItemDay
        key={item}
        label={item}
        day={item}
        zoom={zoom}
        isFirstDayOfMonth={containsFirstDayOfMonth([item])}
        last={last} />
    ));
  }

  return (
    <div className="planning-calendar__header-track">{children}</div>
  );
};

module.exports = CalendarHeaderTrack;
