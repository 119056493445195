/* eslint-disable no-shadow,react/sort-comp,jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');

module.exports = class TravelItemComponent extends React.Component {
  handleClick() {
    this.props.onClick(this.props.item);
  }

  removeFavorite(e) {
    e.stopPropagation();
    if (!this.props.isWaiting) {
      const favorite = this.props.favorites
        .find((favorite) => favorite.city.toLowerCase() === this.props.item[this.props.param]
          .toLowerCase());
      this.props.deleteFavorite(favorite);
    }
  }

  getClass() {
    const style = 'wethod-icon travel-li__icon';
    return this.props.isWaiting ? `${style} disabled` : style;
  }

  render() {
    return (
      <li className="travel-li"
        onClick={this.handleClick.bind(this)}>
        <div className="travel-li__text">{_.unescape(this.props.item[this.props.param])}</div>
        <div className={this.getClass()} onClick={this.removeFavorite.bind(this)} />
      </li>

    );
  }
};
