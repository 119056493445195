const HTTPService = require('../../../../services/HTTPService');
const ParserService = require('../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  urlRoot: `${APIURL}/desk/favorite/`,
  getFavorite(fragment) {
    const defer = $.Deferred();

    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: this.urlRoot + fragment,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code !== 200) {
        defer.reject();
      } else {
        defer.resolve(response.data);
      }
    }).fail((response) => {
      defer.reject(response);
    });

    return defer.promise();
  },
  saveFavorite(fragment) {
    const defer = $.Deferred();

    $.ajax({
      method: 'POST',
      crossDomain: true,
      url: this.urlRoot + fragment,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code !== 200) {
        defer.reject();
      } else {
        defer.resolve(response.data);
      }
    }).fail((response) => {
      defer.reject(response);
    });

    return defer.promise();
  },
  removeFavorite(id) {
    const defer = $.Deferred();

    $.ajax({
      method: 'DELETE',
      crossDomain: true,
      url: this.urlRoot + id,
      xhrFields: {
        withCredentials: true,
      },
    }).done((response) => {
      if (response.code !== 200) {
        defer.reject();
      } else {
        defer.resolve(response);
      }
    }).fail((response) => {
      defer.reject(response);
    });

    return defer.promise();
  },
});
