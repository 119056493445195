const React = require('react');
const ForProjects = require('../containers/ForProjects');
const ForOpportunities = require('../containers/ForOpportunities');
const OpportunitiesAddButton = require('../containers/opportunities/AddItemButton');
const ProjectsAddButton = require('../containers/projects/AddItemButton');

const AddItemButton = () => (
  <div>
    <ForProjects><ProjectsAddButton /></ForProjects>
    <ForOpportunities><OpportunitiesAddButton /></ForOpportunities>
  </div>
);

module.exports = AddItemButton;
