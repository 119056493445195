/* eslint-disable no-use-before-define */
const constants = require('./constants');
const ReviewRequestModel = require('./models/ReviewRequestModel');
const ReviewRequestsCollection = require('./models/ReviewRequestsCollection');

const filterReviewRequests = () => ({
  type: constants.FILTER_REVIEW_REQUESTS,
});

const filterReviewRequestsSuccess = (requests, keyword, size) => ({
  type: constants.FILTER_REVIEW_REQUESTS_SUCCESS,
  items: requests,
  keyword,
  size,
});

module.exports.filterReviewRequests = (keyword, size, year) => (dispatch) => {
  dispatch(filterReviewRequests());
  const collection = new ReviewRequestsCollection();
  $.when(collection.loadMoreReviewRequests(null, keyword, size, year))
    .done((requests) => dispatch(filterReviewRequestsSuccess(requests, keyword, size)));
};

const changeYear = (year) => ({
  type: constants.CHANGE_YEAR,
  year,
});

const loadMoreReviewRequests = () => ({
  type: constants.LOAD_MORE_REVIEW_REQUESTS,
});

const loadMoreReviewRequestsSuccess = (requests, size) => ({
  type: constants.LOAD_MORE_REVIEW_REQUESTS_SUCCESS,
  items: requests,
  size,
});

module.exports.loadMoreReviewRequests = (page, size, keyword, deletedElements = 0,
  year) => (dispatch) => {
  dispatch(loadMoreReviewRequests());
  const offset = (page - 1) * size - deletedElements;
  const collection = new ReviewRequestsCollection();
  $.when(collection.loadMoreReviewRequests(offset, keyword, size, year))
    .done((requests) => dispatch(loadMoreReviewRequestsSuccess(requests, size)));
};

const createReviewRequest = () => ({
  type: constants.CREATE_REVIEW_REQUEST,
});

const createReviewRequestSuccess = (createdElem) => {
  const req = createdElem && createdElem.request ? createdElem.request : createdElem;
  return {
    type: constants.CREATE_REVIEW_REQUEST_SUCCESS,
    item: req,
  };
};

module.exports.createReviewRequest = (request, year) => (dispatch) => {
  dispatch(createReviewRequest());
  const model = new ReviewRequestModel({ projectId: request });
  $.when(model.createReviewRequest())
    .done((newRequest) => {
      dispatch(createReviewRequestSuccess(newRequest));
      dispatch(showModal('request-sent', newRequest));

      const currentYear = moment().format('YYYY');
      if (year !== currentYear) { // Show current year when a new request is created to make it immediately visible
        dispatch(changeYear(currentYear));
      }
    });
};

const deleteReviewRequest = () => ({
  type: constants.DELETE_REVIEW_REQUEST,
});

const deleteReviewRequestSuccess = (request) => ({
  type: constants.DELETE_REVIEW_REQUEST_SUCCESS,
  item: request,
});

const getLastReviewSuccess = (lastReview) => ({
  type: constants.GET_LAST_REVIEW_SUCCESS,
  lastReview,
});

const getLastReviewRequest = () => ({
  type: constants.GET_LAST_REVIEW_REQUEST,
});

module.exports.getLastReview = (projectId) => (dispatch) => {
  dispatch(getLastReviewRequest());
  const request = Wethod.request('get:project:review:last', { projectId });
  $.when(request)
    .done((response) => dispatch(getLastReviewSuccess(response)));
};

module.exports.deleteReviewRequest = (request) => (dispatch) => {
  dispatch(deleteReviewRequest());
  const model = new ReviewRequestModel(request);
  $.when(model.deleteReviewRequest()).done(() => dispatch(deleteReviewRequestSuccess(request)));
};

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showModal = showModal;
module.exports.changeYear = changeYear;
