const React = require('react');

const ArchivedIcon = () => (
  <svg version="1.1"
    className="pipeline-item__probability-archived-icon"
    viewBox="0 0 30 30">
    <title>Archived</title>
    <path style={{ fill: '#4CA6F0' }}
      d="M22.5,22h-15C7.2,22,7,21.8,7,21.5v-9C7,12.2,7.2,12,7.5,12h15c0.3,0,0.5,0.2,0.5,0.5v9
  C23,21.8,22.8,22,22.5,22z M8,21h14v-8H8V21z M18,16.6v-1.2c0-0.3-0.2-0.5-0.5-0.5S17,15.1,17,15.4v0.7h-4v-0.7
  c0-0.3-0.2-0.5-0.5-0.5S12,15.1,12,15.4v1.2c0,0.3,0.2,0.5,0.5,0.5h5C17.8,17.1,18,16.9,18,16.6z M22,10.5c0-0.3-0.2-0.5-0.5-0.5
  h-13C8.2,10,8,10.2,8,10.5S8.2,11,8.5,11h13C21.8,11,22,10.8,22,10.5z M21,8.5C21,8.2,20.8,8,20.5,8h-11C9.2,8,9,8.2,9,8.5
  S9.2,9,9.5,9h11C20.8,9,21,8.8,21,8.5z" />
  </svg>
);

module.exports = ArchivedIcon;
