const constants = require('./constants');
const BillingGroupModel = require('./models/BillingGroupModel');

Wethod.module('ReactBind', () => {
  const getInvoicePlanSuccess = (plan, isLinked) => ({
    type: constants.GET_INVOICE_PLAN_SUCCESS,
    plan,
    isLinked,
  });

  const getInvoicePlanRequest = () => ({
    type: constants.GET_INVOICE_PLAN_REQUEST,
  });

  const getInvoicePlan = (projectId) => (dispatch) => {
    dispatch(getInvoicePlanRequest());

    Wethod.request('get:pipeline:invocePlan', projectId)
      .then((plan, isLinked) => {
        const formattedPlan = {};
        if (isLinked) {
          formattedPlan.billingGroup = plan.get('billing_group_id');
          formattedPlan.masterProject = {
            id: plan.get('master_project_id'),
            name: plan.get('master_project_name'),
          };
        } else {
          formattedPlan.currency = plan.get('currency');
          formattedPlan.invoices = plan.get('invoices_by_month');
          formattedPlan.plan = plan.get('plan');
        }

        dispatch(getInvoicePlanSuccess(formattedPlan, isLinked));
      });
  };

  module.exports.getInvoicePlan = getInvoicePlan;

  const showModal = (name, data) => ({
    type: constants.SHOW_MODAL,
    name,
    data,
  });

  module.exports.showNotesModal = (plan, date) => (dispatch) => {
    dispatch(showModal('notes', { plan, date }));
  };

  module.exports.showDelegateModal = (project) => (dispatch) => {
    dispatch(showModal('delegate', { project }));
  };

  module.exports.showLinkedProjectsModal = () => (dispatch) => {
    dispatch(showModal('linked-projects'));
  };

  module.exports.showRevokeDelegationModal = (
    project,
    masterProject,
    billingGroupId,
  ) => (dispatch) => {
    dispatch(showModal('revoke', { project, masterProject, billingGroupId }));
  };

  module.exports.showMasterRevokeDelegationModal = (
    project,
    masterProject,
    billingGroupId,
  ) => (dispatch) => {
    dispatch(showModal('revoke-from-master', { project, masterProject, billingGroupId }));
  };

  const showErrorModal = (message) => (dispatch) => {
    dispatch(showModal('error', { message }));
  };

  const closeModal = () => ({
    type: constants.CLOSE_MODAL,
  });

  module.exports.closeModal = closeModal;

  const saveInvoicePlanSuccess = (plan) => ({
    type: constants.SAVE_PLAN_SUCCESS,
    plan,
  });

  const saveInvoicePlanRequest = (plan) => ({
    type: constants.SAVE_PLAN_REQUEST,
    plan,
  });

  module.exports.saveInvoicePlan = (plan, projectId) => (dispatch) => {
    dispatch(saveInvoicePlanRequest(plan));

    Wethod.request('save:pipeline:invoicePlan', plan, projectId)
      .then(() => {
        dispatch(saveInvoicePlanSuccess(plan));
      }).fail((response) => {
        let message = 'The plan cannot be saved.';
        if (response && response.message) {
          message += ` ${response.message}.`;
        }

        dispatch(showErrorModal(message));
        dispatch(saveInvoicePlanSuccess(plan));
      });
  };

  module.exports.updateInvoicePlan = (monthPlan, date) => ({
    type: constants.UPDATE_PLAN,
    plan: monthPlan,
    date,
  });

  module.exports.changeMode = (mode) => ({
    type: constants.CHANGE_STATUS,
    mode,
  });

  module.exports.changeView = (view) => ({
    type: constants.CHANGE_VIEW,
    view,
  });

  const delegateProjectFailure = () => ({
    type: constants.DELEGATE_PROJECT_FAILURE,
  });

  const delegateProjectSuccess = (group) => ({
    type: constants.DELEGATE_PROJECT_SUCCESS,
    group,
  });

  const delegateProjectRequest = () => ({
    type: constants.DELEGATE_PROJECT_REQUEST,
  });

  module.exports.delegateProject = (project, masterProject) => (dispatch) => {
    dispatch(delegateProjectRequest());

    const model = new BillingGroupModel();
    const masterProjectRequest = Wethod.request('get:project:info', { projectId: masterProject });

    $.when(model.delegateProject(project, masterProject), masterProjectRequest)
      .done((delegateResponse, masterProjectResponse) => {
        dispatch(delegateProjectSuccess({
          ...delegateResponse,
          master: masterProjectResponse,
        }));
        dispatch(closeModal());
      }).fail((message) => {
        dispatch(delegateProjectFailure());
        dispatch(showErrorModal(message));
      });
  };

  const revokeDelegationSuccess = () => ({
    type: constants.REVOKE_DELEGATION_SUCCESS,
  });

  const revokeDelegationRequest = () => ({
    type: constants.REVOKE_DELEGATION_REQUEST,
  });

  const revokeDelegationFailure = () => ({
    type: constants.REVOKE_DELEGATION_FAILURE,
  });

  module.exports.revokeDelegation = (project, billingGroupId) => (dispatch) => {
    dispatch(revokeDelegationRequest());

    const model = new BillingGroupModel();

    $.when(model.removeProject(project.id, billingGroupId)).done(() => {
      dispatch(revokeDelegationSuccess());
      dispatch(closeModal());
      dispatch(getInvoicePlan(project.id));
    }).fail((message) => {
      dispatch(revokeDelegationFailure());
      dispatch(showErrorModal(message));
    });
  };

  const updateBillingGroup = (billingGroup) => ({
    type: constants.UPDATE_BILLING_GROUP,
    billingGroup,
  });

  module.exports.revokeDelegationFromMaster = (project, billingGroupId) => (dispatch) => {
    dispatch(revokeDelegationRequest());

    const model = new BillingGroupModel();

    $.when(model.removeProject(project.id, billingGroupId)).done((response) => {
      const billingGroup = response ? response.billing_group : null;
      dispatch(revokeDelegationSuccess());
      dispatch(closeModal());
      dispatch(updateBillingGroup(billingGroup));
    }).fail((message) => {
      dispatch(revokeDelegationFailure());
      dispatch(showErrorModal(message));
    });
  };
});
