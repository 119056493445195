const React = require('react');
const Chart = require('./ConsumptionReportChart.react');
const Content = require('../../../../../../../../../common/react/Modal/ModalContent.react');
const Body = require('../../../../../../../../../common/react/Modal/ModalBody.react');

module.exports = class ConsumptionReportModalContent extends React.Component {
  componentDidMount() {
    if (!this.props.data) {
      this.props.getConsumptionReport();
    }
  }

  isLoading() {
    return this.props.isPending('project-report');
  }

  render() {
    return (
      <Content isLoading={!this.props.data || this.isLoading()}>
        <Body>
          <Chart data={this.props.data} />
        </Body>
      </Content>
    );
  }
};
