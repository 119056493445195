const { connect } = require('react-redux');
const Component = require('../../../components/modals/Objective/KeyResult.react');
const actions = require('../../../actions');

const mapDispatchToProps = (dispatch) => ({
  deleteKeyResult: (keyResult, quarterId, objectiveId) => dispatch(actions
    .deleteKeyResult(keyResult, quarterId, objectiveId)),
  editKeyResult: (keyResult, quarterId, objectiveId, persist) => dispatch(actions
    .editKeyResult(keyResult, quarterId, objectiveId, persist)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
