'use strict';

Wethod.module('BudgetApp.Budget', function (Budget, Wethod, Backbone, Marionette, $) {
  this.ProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/project/',
    defaults: {
      id_type: null,
      id_budget: null,
      id_job_order: null,
      description: '',
    },
  });

  var API = {
    getProject: function (id) {
      var getProject = new Budget.ProjectModel({ id: id });
      var defer = $.Deferred();

      getProject.fetch({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('budget:get:project', function (id) {
    return API.getProject(id);
  });
});
