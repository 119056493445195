'use strict';

Wethod.module('NotFoundApp', function (NotFoundApp, Wethod, Backbone, Marionette, $) {
  NotFoundApp.Controller = {
    showNotFound: function () {
      // rimuovo la selezione sull'header
      $('.selHeaderNav').removeClass('selHeaderNav');
      // structure
      var structureTemplate = new NotFoundApp.StructureLayoutView();
      structureTemplate.render();
    },
  };
});
