const React = require('react');
const MissingProjectModal = require('../containers/modals/MissingProjectModal');
const NoHoursModal = require('../containers/modals/NoHoursModal');
const PlanDetailsModal = require('../containers/modals/PlanDetails/PlanDetails');
const ErrorModal = require('./modals/ErrorModal.react');
const ConversionModal = require('../containers/modals/ConversionModal/ConversionModal');
const ConflictualPlanModal = require('./modals/ConflictualPlanModal.react');
const RepeatModal = require('../containers/modals/RepeatModal/RepeatModal');
const DisableRecurringModeModal = require('./modals/DisableRecurringModeModal.react');
const RepeatErrorModal = require('../containers/modals/RepeatErrorModal/RepeatErrorModal');
const ApprovalNeededModal = require('./modals/ApprovalNeededModal.react');
const ConfirmWeekendPlanModal = require('../containers/modals/ConfirmWeekendPlanModal');
const ConfirmHolidayPlanModal = require('../containers/modals/ConfirmHolidayPlanModal');
const HolidayManager = require('../containers/HolidayManager');

const Modal = ({ toShow, closeModal, data }) => {
  const getModal = () => {
    switch (toShow) {
      case 'missing-project':
        return <MissingProjectModal onCancelClick={closeModal} />;
      case 'no-hours':
        return <NoHoursModal onCancelClick={closeModal} data={data} />;
      case 'approval-needed':
        return <ApprovalNeededModal onCancelClick={closeModal} />;
      case 'plan-details':
        return <PlanDetailsModal onCancelClick={closeModal} data={data} />;
      case 'error':
        return <ErrorModal onCancelClick={closeModal} data={data} />;
      case 'conversion':
        return <ConversionModal onCancelClick={closeModal} data={data} />;
      case 'conflictual-plan':
        return <ConflictualPlanModal onCancelClick={closeModal} data={data} />;
      case 'repeat':
        return <RepeatModal onCancelClick={closeModal} data={data} />;
      case 'disable-recurring-mode':
        return <DisableRecurringModeModal onCancelClick={closeModal} />;
      case 'recurring-failure':
        return <RepeatErrorModal onCancelClick={closeModal} data={data} />;
      case 'confirm-weekend-plan':
        return <ConfirmWeekendPlanModal onCancelClick={closeModal} changes={data.changes} />;
      case 'confirm-holiday-plan':
        return (
          <HolidayManager>
            <ConfirmHolidayPlanModal onCancelClick={closeModal}
              changes={data.changes}
              person={data.person} />
          </HolidayManager>
        );
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
