const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const RadialSelect = require('../../../../../../../common/react/inputs/RadialSelect/RadialSelect.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const CostsCell = ({
  updateErrors, readOnly, value, color, onChange, error, disabled, name, label, style,
  metadataService,
}) => {
  /**
   * The selectable options. Disabled external costs value may differ from "canonical" available
   * options that user can select. This is because disabled external costs are obtained from budget.
   * To address this condition we create a fake array of available values containing only a single
   * option equal to the given value.
   * This is needed to keep select behaviour coherent avoiding extra components.
   */
  const availableValues = disabled || readOnly ? [value]
    : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const getOptions = () => availableValues.map((percentage) => (
    <SelectItem key={percentage} value={percentage} label={percentage}>{percentage} %</SelectItem>
  ));

  return (
    <TableCell className="pipeline-item__costs-percentage" style={style}>
      <InputValidator updateErrors={updateErrors} constraints={['required']}>
        <InputMetadataManager metadataService={metadataService}
          name={name}
          canEdit={!readOnly && !disabled}>
          <RadialSelect value={value}
            color={color}
            onChange={onChange}
            label={label}
            errorText={error}>
            {getOptions()}
          </RadialSelect>
        </InputMetadataManager>
      </InputValidator>
    </TableCell>
  );
};

module.exports = CostsCell;
