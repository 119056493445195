const { connect } = require('react-redux');
const Component = require('../../components/modals/ConfirmDeleteRatingModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isDeleting: state.waitingFor.filter((key) => key === 'delete-rating').length > 0,
  supplierId: state.info.id,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (supplierId, rating) => dispatch(actions.deleteRating(supplierId, rating)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
