const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const PV = require('../../../../common/components/PercentageValue.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({ groups, worked }) => {
  const getGroupName = (name) => {
    switch (name.toLowerCase()) {
      case 'chargeable':
        return `${name} projects`;
      case 'other':
        return `${name} activities`;
      default:
        return name;
    }
  };

  const getGroup = (group, index) => {
    if (index === 0) {
      return (
        <span key={index}>
          The <PV value={group.worked_hours_perc} /> of
          {' '}
          <NS
            quantity={worked}>
            these hours were
          </NS>
          {' '}
          worked on
          {' '}
          <span
            className="bold">
            {getGroupName(group.group)}
          </span>
        </span>
      );
    }
    return (
      <span key={index}>
        the{' '}
        <PV value={group.worked_hours_perc} />
        {' '}
        <NS quantity={worked}>were</NS>
        {' '}
        worked on
        {' '}
        <span
          className="bold">
          {getGroupName(group.group)}
        </span>
      </span>
    );
  };

  const getGroups = () => {
    const activeGroups = groups.filter((group) => group.worked_hours_perc);
    const groupsComponent = activeGroups.map((group, index) => getGroup(group, index));
    return <List list={activeGroups} terminator=".">{groupsComponent}</List>;
  };

  return (
    <Paragraph>{getGroups()}</Paragraph>
  );
};
