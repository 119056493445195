const React = require('react');
const Modal = require('../../../../../../../../../common/react/Modal/Modal.react');
const PermissionManager = require('../../../../../containers/PermissionManager');
const RequestManager = require('../../../../../containers/RequestManager');
const ModalContent = require('./EnableMulticurrencyModalContent.react');

const EnableMulticurrencyModal = ({ closeModal }) => (
  <Modal title="Enable multicurrency" onClose={closeModal}>
    <PermissionManager>
      <RequestManager>
        <ModalContent closeModal={closeModal} />
      </RequestManager>
    </PermissionManager>
  </Modal>
);

module.exports = EnableMulticurrencyModal;
