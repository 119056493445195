/* eslint-disable react/sort-comp */
const React = require('react');
const Step = require('./Step.react');
const StepHeader = require('./StepHeader.react');
const StepContent = require('./StepContent.react');
const StepFooter = require('../containers/StepFooter');
const PersonRow = require('../containers/PersonRow');
const EmptySearch = require('./EmptyPeopleSearch.react');

module.exports = class PeopleStep extends React.Component {
  onSaveClick() {
    if (this.props.incompleteReviews.length > 0) {
      this.props.showMissingVoteModal('people');
    } else {
      this.props.save();
    }
  }

  onBackClick() {
    this.props.goToPrevStep();
  }

  isComplete(personId) {
    return this.props.incompleteReviews.filter((person) => person.id === personId).length === 0;
  }

  getFilteredPeople() {
    return this.props.people
      .filter((person) => person.name.toLowerCase()
        .indexOf(this.props.filter.toLowerCase()) !== -1);
  }

  getRows() {
    const people = this.getFilteredPeople();
    if (this.props.filter !== '' && !people.length) {
      return <EmptySearch />;
    }
    return people.map((person) => (
      <PersonRow key={person.id}
        {...person}
        complete={this.isComplete(person.id)} />
    ));
  }

  render() {
    return (
      <Step>
        <StepHeader
          showSearch
          title="Based on given criteria, how likely would you recommend working with these colleagues?" />
        <StepContent>
          {this.getRows()}
        </StepContent>
        <StepFooter currentStep={2}>
          <button type="button"
            className="wethod-button project-review__step-footer-action"
            onClick={this.onBackClick.bind(this)}>
            Back
          </button>
          <button type="button"
            className="wethod-button project-review__step-footer-action"
            onClick={this.onSaveClick.bind(this)}>
            Save review
          </button>
        </StepFooter>
      </Step>
    );
  }
};
