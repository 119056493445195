const { connect } = require('react-redux');
const Component = require('../components/PricelistsTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'get-pricelists').length > 0,
  pricelists: state.pricelists,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  hasMorePages: state.hasMorePages,
  available: state.available,
  search: state.search,
  sort: state.sort,
  order: state.order,
  refreshTable: state.refreshTable,
});

const mapDispatchToProps = (dispatch) => ({
  getPricelists: (offset, limit, search, order, sort, available) => dispatch(
    actions.getPricelists(offset, limit, search, order, sort, available),
  ),
  getLevels: () => dispatch(actions.getLevels()),
  showSidebar: (id) => dispatch(actions.showSidebar(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
