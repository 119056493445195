const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const TextArea = require('../../../../../../../common/react/inputs/TextArea/BasicTextArea/BasicTextArea.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

module.exports = class NoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: this.props.data.notes,
      unsavedChanges: false,
    };
  }

  handleChange(e) {
    const { value } = e.target;
    const hasChanged = value !== this.props.data.notes;

    this.setState({ notes: value, unsavedChanges: hasChanged });
  }

  handleSave() {
    if (this.state.unsavedChanges && this.props.data.canEdit) {
      const { hours } = this.props.area;

      this.props.saveNote(this.props.data.projectId, this.props.data.areaId, this.props.date,
        this.state.notes, hours, this.props.employeeId);
    }
  }

  render() {
    return (
      <Modal title="Insert a note" onCancelClick={this.props.onCancelClick} className="timesheet-weekly__modal">
        <TextArea readOnly={!this.props.data.canEdit}
          name="notes"
          label="Notes"
          value={this.state.notes}
          rows={4}
          onChange={this.handleChange.bind(this)} />
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onCancelClick}>Cancel</Button>
          <ShowIf condition={this.props.data.canEdit}>
            <Button onClick={this.handleSave.bind(this)}
              disabled={!this.state.unsavedChanges || this.props.isSaving}>
              Save
            </Button>
          </ShowIf>
        </div>
        <ShowIf condition={this.props.isSaving}>
          <div className="wethod-modal2__feedback">Saving...</div>
        </ShowIf>
      </Modal>
    );
  }
};
