const React = require('react');
const PropTypes = require('prop-types');
const SelectButton = require('../SelectButton.react');
const Decorator = require('../../../InputDecorator/BasicInputDecorator/BasicInputDecorator.react');

const BasicSelectButton = ({
  id, prefix, suffix, required, inputRef, helperText, errorText, value, disabled, readOnly, ...rest
}) => (
  <Decorator
    required={required}
    inputRef={inputRef}
    helperText={helperText}
    errorText={errorText}
    prefix={prefix}
    disabled={disabled}
    readOnly={readOnly}
    suffix={suffix}>
    <SelectButton disabled={disabled} readOnly={readOnly} {...rest}>
      {value}
      <span>&nbsp;</span>
    </SelectButton>
  </Decorator>
);

BasicSelectButton.defaultProps = {
  value: '',
  id: undefined,
  className: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  required: undefined,
  inputRef: undefined,
};

BasicSelectButton.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onClick: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inputRef: PropTypes.func,
};

module.exports = BasicSelectButton;
