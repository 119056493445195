const request = require('./Request');

const OrdersAutocomplete = {
  getProjects(search = '') {
    const searchQuery = search.length > 0 ? `?hints=${search}` : '';

    return request({
      method: 'get',
      url: `/orderboard/autocomplete${searchQuery}`,
    });
  },
};

module.exports = OrdersAutocomplete;
