module.exports.GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
module.exports.GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';

module.exports.UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
module.exports.UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';

module.exports.DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
module.exports.DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';

module.exports.FILTER_TEMPLATES = 'FILTER_TEMPLATES';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
