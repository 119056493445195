const { connect } = require('react-redux');
const FavoriteCity = require('../../components/sidebar/FavoriteCitySelect.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  favorites: state.favorites,
  filteredFavorites: state.filteredFavorites,
  keyword: state.searchKeyword,
  isWaiting: state.waitingFor.filter((key) => key === 'load-favorites' || key === 'save-favorite' || key === 'delete-favorite').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getFavorites: () => dispatch(actions.getFavorites()),
  addFavorite: (favorite) => dispatch(actions.addFavorite(favorite)),
  filterFavorites: (keyword) => dispatch(actions.filterFavorites(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(FavoriteCity);
