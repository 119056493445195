/**
 * Moves the array's item from 'from' to 'to'.
 *
 * @param from (int) initial position of the item to move in array
 * @param to (int) the position where the item needs to be moved
 * @param array (Array) where the item to move is contained
 * @return {*[]}
 */
module.exports = (from, to, array) => {
  const arrayCopy = [...array]; // Let's create a copy because we're going to use splice() which modify the given array

  const toAdd = arrayCopy.splice(from, 1)[0]; // Remove item in "from" position

  arrayCopy.splice(to, 0, toAdd); // Add item in the right "to" position

  return arrayCopy;
};
