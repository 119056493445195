/* eslint-disable react/sort-comp,jsx-a11y/anchor-is-valid */
const React = require('react');

module.exports = class FollowUp extends React.Component {
  constructor(props) {
    super(props);

    this.iconClasses = {
      Call: 'wethod-icon-phone wethod-icon-phone--black',
      Email: 'wethod-icon-mail wethod-icon-mail--black',
      Meeting: 'wethod-icon-meeting wethod-icon-meeting--black',
    };
  }

  getDate() {
    return moment(this.props.schedule).format('D MMM');
  }

  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props);
  }

  getLogoClass() {
    if (this.props.type) return this.iconClasses[this.props.type.label];
    return null;
  }

  render() {
    return (
      <div className="timeline-followup">
        <div className="timeline-followup__info-important">
          <div className="timeline-followup__date">{this.getDate()}</div>
          <div className="timeline-followup__type">
            <div className={this.getLogoClass()} />
          </div>
        </div>
        <div className="timeline-followup__info-secondary">
          <span className="timeline-followup__description">{this.props.description}</span>
          <div className="timeline-followup__actions">
            <div
              className="clients__delete-button wethod-icon-button"
              onClick={this.handleDeleteClick.bind(this)}>
              <div className="wethod-icon-medium-delete" />
            </div>
            <a
              href="#"
              className="clients__delete-button wethod-icon-button"
              onClick={this.handleEditClick.bind(this)}>
              <div className="wethod-icon-medium-edit" />
            </a>
          </div>
        </div>
      </div>
    );
  }
};
