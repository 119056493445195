const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/ChargeabilityChart.react');

const mapStateToProps = (state) => ({
  target: state.chargeability_target_perc,
  thisMonth: state.month_chargeable_hours_perc,
  prevMonth: state.prev_month_chargeable_hours_perc,
  twoMonthsAgo: state.two_months_ago_chargeable_hours_perc,
  prevYearThisMonth: state.prev_year_chargeable_hours,
  prevYearPrevMonth: state.prev_year_prev_month_chargeable_hours,
  prevYearTwoMonthsAgo: state.prev_year_two_months_before_chargeable_hours,
  min: Math.min(state.chargeability_target_perc, state.month_chargeable_hours_perc,
    state.prev_month_chargeable_hours_perc,
    state.two_months_ago_chargeable_hours_perc, state.prev_year_chargeable_hours,
    state.prev_year_prev_month_chargeable_hours, state.prev_year_two_months_before_chargeable_hours)
    * 0.8,
});

module.exports = connect(mapStateToProps)(Component);
