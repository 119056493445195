const React = require('react');
const PropTypes = require('prop-types');
const _ = require('underscore');

/**
 * Escape the string passed as child.
 *
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
const Escaper = ({ children, className }) => (
  <span className={className}>{_.unescape(children)}</span>
);

Escaper.defaultProps = {
  className: '',
  children: '',
};

Escaper.propTypes = {
  /**
   * The string to escape.
   */
  children: PropTypes.string,
  className: PropTypes.string,
};

module.exports = Escaper;
