const React = require('react');
const PropTypes = require('prop-types');
const Escaper = require('../../../../formatters/Escaper.react');
const Avatar = require('../../../../AvatarBox/Avatar.react');

const Header = ({ author, authorInfo, authorInfoSecond, pic, actions }) => (
  <div className="widget-rating-overview-item__header">
    <div className="widget-rating-overview-item__author">
      <div className="widget-rating-overview-item__author-pic">
        <Avatar name={author} pic={pic} size="medium" />
      </div>
      <div className="widget-rating-overview-item__author-info">
        <div className="widget-rating-overview-item__author-info-first">
          <Escaper>{author}</Escaper>
        </div>
        <div className="widget-rating-overview-item__author-info-second">
          {authorInfo}
        </div>
        <div className="widget-rating-overview-item__author-info-third">
          {authorInfoSecond}
        </div>
      </div>
    </div>
    {actions}
  </div>
);

Header.defaultProps = {
  actions: null,
  authorInfo: '',
  authorInfoSecond: '',
  pic: '',
};

Header.propTypes = {
  /**
   * Actions to place in this header.
   */
  actions: PropTypes.element,
  /**
   * Author's name.
   */
  author: PropTypes.string.isRequired,
  authorInfo: PropTypes.string,
  authorInfoSecond: PropTypes.string,
  pic: PropTypes.string,
};

module.exports = Header;
