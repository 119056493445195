/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case */

const React = require('react');
const moment = require('moment');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');

const ClashErrorBody = ({
  errors,
  onCancelClick,
  onPositiveButtonClick,
  isSaving,
}) => {
  const dates = errors.map((error, index) => (
    <div
      key={index}
      className="planning-people-repeat-error__message-list-item">
      <span>{moment(error.date).format('DD MMM YYYY')}</span>
    </div>
  ));

  const getFeedBack = () => {
    if (isSaving) {
      return 'Planning...';
    }
    return '';
  };

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Cannot repeat on the following dates because there is not enough space:
        </ModalBodyBlock>
        <ModalBodyBlock>
          {dates}
        </ModalBodyBlock>
        <ModalBodyBlock>Do you still want to repeat on the remaining days?</ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={getFeedBack()}>
        <ModalFooterAction onClick={onCancelClick}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onPositiveButtonClick}>
          Yes
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = ClashErrorBody;
