const React = require('react');

/**
 * Used in ProjectInfo to display primary info.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectInfoPrimarySectionSubtitle = ({ children }) => (
  <div className="wethod-budget-header__project-subtitle">{children}</div>
);

module.exports = ProjectInfoPrimarySectionSubtitle;
