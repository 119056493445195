/* eslint-disable consistent-return,react/prop-types,react/sort-comp */
const React = require('react');
const Tab = require('./MenuTab.react');
const MenuItem = require('./MenuItemController.react');
const MenuItemLogout = require('./MenuItemLogout.react');
const MenuSection = require('./Section.react');

module.exports = class Menu extends React.Component {
  getFirstTabStyle() {
    if (this.props.area) {
      return { marginLeft: '-100%' };
    }
  }

  isOpenItem(item) {
    return this.props.openItem && this.props.openItem.getLabel() === item.getLabel();
  }

  getItems() {
    return this.props.items.map((item) => (
      <MenuItem key={item.getLabel()}
        item={item}
        open={this.isOpenItem(item)}
        showArea={this.props.showArea}
        showItem={this.props.updateOpenItem}
        closeMenu={this.props.close} />
    ));
  }

  getSections() {
    const { area } = this.props;
    if (area) {
      return area.getMobileSections().map((section) => (
        <MenuSection key={section.getLabel()}
          section={section}
          onClick={this.props.close} />
      ));
    }
  }

  render() {
    return (
      <div className="app-nav-mobile-menu">
        <Tab style={this.getFirstTabStyle()}>
          <ul className="app-nav-mobile-menu__items">
            {this.getItems()
              .concat(<MenuItemLogout key="Logout"
                onClick={this.props.close} />)}
          </ul>
        </Tab>
        <Tab>
          <ul className="app-nav-mobile-menu__sections">
            {this.getSections()}
          </ul>
        </Tab>
      </div>
    );
  }
};
