const React = require('react');
const withCompare = require('../HOCs/withCompare');

const ComparatorConnector = ({ c1, c2, compare }) => {
  const negativeKeys = ['much_lower', 'lower'];

  const isNegative = (key) => negativeKeys.join().indexOf(key) !== -1;

  const areOpposite = () => {
    const key1 = compare(c1.a, c1.b);
    const key2 = compare(c2.a, c2.b);

    const isNegativeKey1 = isNegative(key1);
    const isNegativeKey2 = isNegative(key2);

    return isNegativeKey1 !== isNegativeKey2;
  };

  const text = areOpposite() ? 'but' : 'and';

  return <span>{text}</span>;
};

module.exports = withCompare(ComparatorConnector);
