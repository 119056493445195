/* eslint-disable react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const ClientActivity = require('./ClientIntercompanyActivity.react');
const SupplierActivity = require('./SupplierIntercompanyActivity.react');

const IntercompanyActivityController = ({ type, activity }) => {
  if (type === 'client') {
    return (
      <ClientActivity {...activity} />
    );
  }
  if (type === 'supplier') {
    return (
      <SupplierActivity {...activity} />
    );
  }
  return null;
};

IntercompanyActivityController.propTypes = {
  /**
   * Type of the activity: it can be 'client' or 'supplier', any other type is not recognized
   */
  type: PropTypes.string.isRequired,

  /**
   * Intercompany activity: contains different attributes depending on the type
   */
  activity: PropTypes.object.isRequired,
};

module.exports = IntercompanyActivityController;
