const { connect } = require('react-redux');
const Component = require('../../../../components/Calculator/Task/MakeTaskIntercompanyModal/MakeTaskIntercompanyModal.react');
const actions = require('../../../../actions');

const mapDispatchToProps = (dispatch) => ({
  showSelectIntercompanyTaskSupplier: (task) => dispatch(actions
    .showSelectIntercompanyTaskSupplier(task)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
