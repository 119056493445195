const constants = require('./constants');
const TrendsModel = require('./models/DemographicTrends');
const TimeFilterFactory = require('../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');

const updateTimeFilter = (filter) => ({
  type: constants.UPDATE_TIME_FILTER,
  filter,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showTargetModal = () => ({
  type: constants.SHOW_TARGET_MODAL,
});

const getTrendsRequest = () => ({
  type: constants.GET_TRENDS_REQUEST,
});

const getTrendsSuccess = (data) => ({
  type: constants.GET_TRENDS_SUCCESS,
  data,
});

const getTrends = (filter) => (dispatch) => {
  dispatch(getTrendsRequest());
  const timeFilter = new TimeFilterFactory(filter);
  const start = timeFilter.getStartDate();
  const end = timeFilter.getEndDate();
  const model = new TrendsModel({ from: start, to: end });
  $.when(model.getTrends())
    .done((data) => {
      dispatch(getTrendsSuccess(data));
    });
};

const saveTargetRequest = () => ({
  type: constants.SAVE_TARGET_REQUEST,
});

const saveTargetSuccess = (target) => ({
  type: constants.SAVE_TARGET_SUCCESS,
  target,
});

module.exports.saveTarget = (target) => (dispatch) => {
  dispatch(saveTargetRequest());
  const headcountTargetRequest = Wethod.request('set:company:headcount-target', { target });
  $.when(headcountTargetRequest).done(() => {
    dispatch(saveTargetSuccess(target));
  });
};

module.exports.changeFilter = (filter) => (dispatch) => {
  dispatch(updateTimeFilter(filter));
  dispatch(getTrends(filter));
};

module.exports.getTrends = getTrends;
