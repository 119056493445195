const constants = require('./constants');
const ProjectsModel = require('./models/Projects');
const TrendsModel = require('./models/Trends');

module.exports.updateTimeFilter = (filter) => ({
  type: constants.UPDATE_TIME_FILTER,
  filter,
});

const getProjectsSuccess = (projects) => ({
  type: constants.GET_PROJECTS_SUCCESS,
  projects,
});

const getProjectsRequest = () => ({
  type: constants.GET_PROJECTS_REQUEST,
});

module.exports.getProjects = () => (dispatch) => {
  dispatch(getProjectsRequest());
  const collection = new ProjectsModel();

  $.when(collection.getModel()).done((projects) => dispatch(getProjectsSuccess(projects)));
};

const getTrendsSuccess = (trends) => ({
  type: constants.GET_TRENDS_SUCCESS,
  trends,
});

const getTrendsRequest = () => ({
  type: constants.GET_TRENDS_REQUEST,
});

module.exports.getTrends = () => (dispatch) => {
  dispatch(getTrendsRequest());
  const model = new TrendsModel();

  $.when(model.getModel()).done((trends) => dispatch(getTrendsSuccess(trends)));
};
