/* eslint-disable no-underscore-dangle */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(options) {
    if (options) {
      if (options.from) this._from = options.from;
      if (options.to) this._to = options.to;
    }
    let url = `${APIURL}/export/travel`;
    if (this._from && this._to) {
      url += `?from=${this._from}&to=${this._to}`;
    }
    this.urlRoot = url;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  _from: null, // default from first day of current year
  _to: null, // default to current day
  sendExport() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model);
    });

    return defer.promise();
  },
});
