const React = require('react');
const PropTypes = require('prop-types');

const Button = ({ children, disabled, className, label, onClick, inputRef }) => {
  const getClassName = () => {
    let name = '';
    name = className ? `${name} ${className}` : name;
    name = disabled ? `${name} disabled` : name;

    return name;
  };

  return (
    <button type="button"
      aria-label={label}
      onClick={onClick}
      disabled={disabled}
      ref={inputRef}
      className={getClassName()}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  className: '',
  inputRef: null,
  label: '',
};

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  /**
   * Expose button ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
};

module.exports = Button;
