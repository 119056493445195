const React = require('react');
const PropTypes = require('prop-types');

class SelectItemWithActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ mouseOver: true });
  }

  onMouseLeave() {
    this.setState({ mouseOver: false });
  }

  getClassName() {
    let name = 'wethod-select-item-with-actions';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    if (this.state.mouseOver) {
      name += ' wethod-select-item-with-actions--mouse-over';
    }
    return name;
  }

  getActions() {
    if (this.props.children) {
      return (
        <div className="wethod-select-item-with-actions__actions">{this.props.children}</div>
      );
    }
    return null;
  }

  render() {
    return (
      <li className={this.getClassName()}>
        <div tabIndex="-1"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          role="option"
          aria-selected={false}
          onKeyPress={this.props.onClick}
          className="wethod-select-item-with-actions__label"
          onClick={this.props.onClick}>
          {this.props.label}
        </div>
        {this.getActions()}
      </li>
    );
  }
}

SelectItemWithActions.defaultProps = {
  className: '',
  onClick: null,
  children: null,
};

SelectItemWithActions.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

module.exports = SelectItemWithActions;
