const React = require('react');
const moment = require('moment');
const FeatureService = require('../../services/FeatureService');
const HolidayTextFormatter = require('../../common/HolidayTextFormatter.react');

const WeeklyHoursModalBody = ({ hours, getDayCapacity, isHoliday }) => {
  const getText = (text, isDateHoliday) => {
    if (FeatureService.isEmployeeCapacityEnabled() && isDateHoliday) {
      return <HolidayTextFormatter>{text}</HolidayTextFormatter>;
    }
    return text;
  };

  const getRow = (day) => {
    const date = moment(day.date).format('dddd DD.MM');
    const isDateHoliday = FeatureService.isEmployeeCapacityEnabled() ? isHoliday(day.date) : false;
    const capacity = FeatureService.isEmployeeCapacityEnabled()
      ? getDayCapacity(day.date, isDateHoliday) : 8;
    const value = FeatureService.isEmployeeCapacityEnabled() ? `${day.value}/${capacity}` : day.value;

    return (
      <li key={day.date} className="timesheet-detailed__weekly-hours-modal-row">
        <span className="timesheet-detailed__weekly-hours-modal-date">
          {getText(date, isDateHoliday)}
        </span>
        <span className="timesheet-detailed__weekly-hours-modal-value" aria-label={date}>
          {getText(value, isDateHoliday)}
        </span>
      </li>
    );
  };

  const getHours = () => hours.map((day) => getRow(day));

  return (
    <ul className="timesheet-detailed__weekly-hours-modal">{getHours()}</ul>
  );
};

module.exports = WeeklyHoursModalBody;
