const React = require('react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const Footer = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');

const DeleteTaskModalContent = ({ closeModal, isPending, deleteTask, taskId }) => {
  const isSaving = () => isPending('delete-task');

  const feedback = isSaving() ? 'Deleting...' : '';

  function handleDelete() {
    deleteTask(taskId);
  }

  return (
    <ModalContent>
      <ModalBody>
        Do you really want to delete this task?
      </ModalBody>
      <Footer feedback={feedback}>
        <FooterAction onClick={closeModal} disabled={isSaving()}>Cancel</FooterAction>
        <FooterAction onClick={handleDelete} disabled={isSaving()}>Yes</FooterAction>
      </Footer>
    </ModalContent>
  );
};

module.exports = DeleteTaskModalContent;
