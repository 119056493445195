/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const TextInput = require('../../../../../../../../../common/react/TextInput.react');
const BusinessUnitSearchSelect = require('../../containers/BusinessUnitSearchSelect');
const PhoneInput = require('../../../../../../../../../common/react/PhoneInput.react');

module.exports = class SecondaryInfoEditable extends React.Component {
  /**
   * Returns the inputs' error message.
   */
  getErrorMessage(name) {
    const inputErrors = this.props.errorMessages ? this.props.errorMessages[name] : null;
    if (inputErrors) {
      return inputErrors[0];
    }
    return '';
  }

  onPhoneChange(name, value) {
    this.props.onPhoneChange({
      ...this.props.phone,
      number: value,
      label: ' ',
    });
  }

  getInitPhoneNumber() {
    if (this.props.phone) return this.props.phone.number;
  }

  render() {
    return (
      <div>
        <div className="client-info-row">
          <div className="client-info-row__title">Phone</div>
          <div className="client-info-row__value">
            <PhoneInput name="phone"
              value={this.getInitPhoneNumber()}
              onChange={this.onPhoneChange.bind(this)} />
          </div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Email</div>
          <div className="client-info-row__value">
            <TextInput name="email"
              value={this.props.person.email}
              constraints="required,email"
              onValidate={this.props.onValidate}
              onChange={this.props.onChange} />
          </div>
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('email')}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Role</div>
          <div className="client-info-row__value">
            <TextInput name="role" value={this.props.person.role} onChange={this.props.onChange} />
          </div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">Business unit</div>
          <div className="client-info-row__value">
            <BusinessUnitSearchSelect current={this.props.business_unit}
              onChange={this.props.onChange} />
          </div>
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('company')}</div>
        </div>
        <div className="client-info-row">
          <div className="client-info-row__title">LinkedIn</div>
          <div className="client-info-row__value">
            <TextInput name="linkedin" value={this.props.person.linkedin} onChange={this.props.onChange} />
          </div>
        </div>
      </div>
    );
  }
};
