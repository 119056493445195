/* eslint-disable react/sort-comp */
const React = require('react');
const TypeSwitcher = require('./TypeSwitcher.react');

module.exports = class BoardHeaderView extends React.Component {
  /**
   * Returns true if boards of the given type can be shown.
   * @param type
   * @return {boolean}
   */
  canShow(type) {
    return this.props.availableBoardTypes.indexOf(type) !== -1;
  }

  getTypeSwitcher() {
    if (this.canShow('spreadsheet') && this.canShow('kanban')) {
      return (
        <div className="project-canvas-board__actions-type-switcher">
          <TypeSwitcher current={this.props.boardType} handleClick={this.props.changeBoardType} />
        </div>
      );
    }
    return null;
  }

  getClassName() {
    let name = 'project-canvas-board__header-container';
    if (this.props.waitingForOptions) {
      name += ' project-canvas-board__header-container--disabled';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="project-canvas-board__header">
          <div className="project-canvas-board__title">{this.props.name}</div>
          <div className="project-canvas-board__actions">
            {this.getTypeSwitcher()}
          </div>
        </div>
      </div>
    );
  }
};
