const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const FilledTag = require('../../../../../../../common/react/Tag/FilledTag.react');
const CounterTag = require('../CounterTag.react');

const Alert = ({ item, onClick }) => {
  function getNonEmpty(value) {
    return value || '-';
  }

  function getFormattedDate(date) {
    return date ? moment(date).format('MMM YYYY') : '-';
  }

  function handleClick() {
    onClick(item);
  }

  return (
    <TableRow onClick={handleClick} className="data-freezing-engine__table-alerts-row">
      <TableCell className="data-freezing-engine__table-alerts-column-large">{item.name}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-column-medium">{getNonEmpty(item.job_order)}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-column-medium">{getNonEmpty(item.client)}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-column-medium">{getNonEmpty(item.pm)}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-date-start">{getFormattedDate(item.start_date)}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-column-small">{getNonEmpty(item.duration)}</TableCell>
      <TableCell className="data-freezing-engine__table-alerts-column-small">
        <CounterTag counterColor={FilledTag.COLORS.RASPBERRY_RED} value={item.alerts_amount} />
      </TableCell>
    </TableRow>
  );
};

module.exports = Alert;
