/* eslint-disable backbone/no-model-attributes */
const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  initialize(attributes, options) {
    this.boardId = options.boardId;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/canvas/board/${this.boardId}/item`;
  },
  saveModel() {
    const defer = $.Deferred();

    $.when(this.persist()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  deleteModel() {
    const defer = $.Deferred();

    $.when(this.erase()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
  saveName(id) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/${id}`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(this.attributes),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveSortSpreadsheet(map) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/sort-spreadsheet`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(map),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
  saveSortKanban(map) {
    const defer = $.Deferred();
    $.ajax({
      method: 'PUT',
      crossDomain: true,
      url: `${this.urlRoot()}/sort-kanban`,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(map),
    }).done((response) => {
      defer.resolve(response.data);
    }).fail((response) => {
      defer.reject(response);
    });
    return defer.promise();
  },
});
