const React = require('react');
const moment = require('moment');
const TextInput = require('../../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Block = require('./BlockInput.react');
const FiscalYearService = require('../../../../../../../../services/FiscalYearService');

const YearBlock = ({ templateId, block, deletable, onBlockDelete }) => {
  const fiscalYearService = new FiscalYearService();
  const format = 'Y'.repeat(block.format);
  const year = fiscalYearService.getShiftedYear(moment().format(format));

  return (
    <Block block={block}
      deletable={deletable}
      onBlockDelete={onBlockDelete}
      className="company-settings-jo-template__block-input--year">
      <TextInput
        id={`${templateId}-year-${block.sort}`}
        label="Year"
        data-sort={block.sort}
        value={year}
        disabled />
    </Block>
  );
};

module.exports = YearBlock;
