/* eslint-disable react/prop-types,class-methods-use-this,react/sort-comp,
 react/require-default-props */
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const SingleValueSlider = require('../SingleValueSlider.react');
const Menu = require('../Menu/Menu.react');
const DayPicker = require('../DayCalendar.react');

/**
 * A week selector component with slider to select the next/previous and monthly calendar to pick
 * an entire week.
 */
class WeekSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      hoverRange: null,
    };
  }

  getWeekStart(date) {
    return moment(date).startOf('isoWeek');
  }

  getWeekEnd(startDate) {
    const endOfWeek = this.props.internationalCalendar ? 6 : 4;
    return moment(startDate).add(endOfWeek, 'days');
  }

  formatDate(date) {
    let start = this.getWeekStart(date);
    const end = moment(this.getWeekEnd(start)).format('DD/MM');
    start = start.format('DD/MM');
    return `${start} - ${end}`;
  }

  getWeekRange(date) {
    const weekStart = this.getWeekStart(date);
    return {
      from: weekStart.toDate(),
      to: this.getWeekEnd(weekStart).toDate(),
    };
  }

  handleDayEnter(date) {
    this.setState({
      hoverRange: this.getWeekRange(date),
    });
  }

  handleDayLeave() {
    this.setState({
      hoverRange: null,
    });
  }

  handleMenuClose() {
    this.setState({
      showMenu: false,
    });
  }

  handleDateClick(date) {
    if (!this.props.isWaiting) {
      const startDate = this.getWeekStart(date);
      if (!startDate.isSame(this.props.date, 'week')) this.props.changeDate(startDate);
      this.handleMenuClose();
    }
  }

  handleCalendarButtonClick() {
    if (!this.props.isWaiting) {
      this.setState({
        showMenu: true,
      });
    }
  }

  handlePreviousClick() {
    if (!this.props.isWaiting) {
      const prev = moment(this.props.date).subtract(1, 'weeks');
      this.props.changeDate(prev);
    }
  }

  handleNextClick() {
    if (!this.props.isWaiting) {
      const next = moment(this.props.date).add(1, 'weeks');
      this.props.changeDate(next);
    }
  }

  getModifiers() {
    const { hoverRange } = this.state;
    const selectedRange = this.getWeekRange(this.props.date);

    return {
      hoverRange,
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRange,
      selectedRangeStart: selectedRange && selectedRange.from,
      selectedRangeEnd: selectedRange && selectedRange.to,
    };
  }

  getStyleClass() {
    let style = 'date-selector';
    if (this.props.isWaiting) {
      style += ' disabled';
    }
    return style;
  }

  getMenuBody() {
    const weekRange = this.getWeekRange(this.props.date);
    return (
      <DayPicker className="date-selector-weekly"
        startDate={weekRange.from}
        endDate={weekRange.to}
        modifiers={this.getModifiers()}
        disableWeekend={!this.props.internationalCalendar}
        showOutsideDays
        getHolidays={this.props.getHolidays}
        onDayClick={this.handleDateClick.bind(this)}
        onDayMouseEnter={this.handleDayEnter.bind(this)}
        onDayMouseLeave={this.handleDayLeave.bind(this)} />
    );
  }

  getCalendarButton() {
    return (
      <div className="date-selector__calendar-button"
        ref={(el) => this.calendarButton = el}>
        <div className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        <span>{this.formatDate(this.props.date)}</span>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SingleValueSlider className={this.getStyleClass()}
          value={this.getCalendarButton()}
          onTodayClicked={this.handleCalendarButtonClick.bind(this)}
          onPrevClicked={this.handlePreviousClick.bind(this)}
          onNextClicked={this.handleNextClick.bind(this)} />
        <Menu open={this.state.showMenu}
          onClose={this.handleMenuClose.bind(this)}
          anchorEl={this.calendarButton}>
          {this.getMenuBody()}
        </Menu>
      </div>
    );
  }
}

WeekSelector.defaultProps = {
  getHolidays: null,
};

WeekSelector.propTypes = {
  /**
   * The selected date shown: given a date, the entire belonging week will be selected.
   */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]).isRequired,

  /**
   * If set to true, the selector will be disabled and neither the slider nor the calendar will be
   * working.
   */
  isWaiting: PropTypes.bool,

  /**
   * Function called when a week (different from the given one) is selected, either with the slider
   * or the calendar. The slider always move one week at the time, keeping the day of the given
   * week. The calendar always give the first day of the selected week.
   */
  changeDate: PropTypes.func.isRequired,
  /**
   * Function to call to retrieve holidays to show in calendar.
   * If this function is null, no holidays are shown.
   * @param {Date} month
   * @return {Promise} Resolve to a list of EmployeeCompanyHoliday
   */
  getHolidays: PropTypes.func,
};

module.exports = WeekSelector;
