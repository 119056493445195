const React = require('react');
const TextField = require('../../../../../../../../common/react/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const CopyButton = require('./CopyButton.react');

/**
 * TextInput with button that copies input value to clipboard.
 * Value is not editable.
 * @param value
 * @return {JSX.Element}
 * @constructor
 */
const CopyableTextInput = ({ value }) => {
  function onCopyClick() {
    navigator.clipboard.writeText(value);
  }

  return (
    <div className="wethod-input-copy-text">
      <TextField value={value} disabled suffix={<CopyButton onClick={onCopyClick} />} />
    </div>
  );
};

module.exports = CopyableTextInput;
