const formatNumber = require('../../../../../services/FormatService').formatDecimalNumber;

function isTotal(category) {
  return category.reimbursement_type.key === 'total';
}

function isMax(category) {
  return category.reimbursement_type.key === 'max';
}

function isFlatRate(category) {
  return category.reimbursement_type.key === 'flat';
}

function isMileage(category) {
  return category.reimbursement_type.key === 'mileage';
}

function isQuantity(category) {
  return category.reimbursement_type.key === 'quantity';
}

const expenseCategoryService = {
  isMileage,
  /**
   * Check whether the given category needs a value input or not
   * @param category
   * @returns {boolean}
   */
  isValueRequired(category) {
    if (category) {
      return category.reimbursement_type.is_value_required;
    }
    return false;
  },
  /**
   * Check whether the given category needs to specify the route or not
   * @param category
   * @returns {boolean}
   */
  isRouteRequired(category) {
    if (category) {
      return category.is_route_required;
    }
    return false;
  },
  /**
   * Return the appropriate label of value input based on given category
   * @param category
   * @returns {string}
   */
  getValueLabel(category) {
    if (category) {
      if (isMileage(category)) {
        return 'Total miles/km';
      }
      if (isQuantity(category)) {
        return 'Total quantity';
      }
      return 'Expense value';
    }
    return '';
  },
  /**
   * Return the appropriate helper text of reimbursement input based on given category
   * @param category
   * @returns {null}
   */
  getReimbursementHelperText(category) {
    if (category) {
      const reimbursement = category.reimbursement
        ? formatNumber(category.reimbursement, 4, false)
        : null;

      if (reimbursement) {
        if (isMax(category)) {
          return `max ${reimbursement}`;
        }
        if (isMileage(category)) {
          return `${reimbursement} per mi/km`;
        }
        if (isQuantity(category)) {
          return `${reimbursement} per unit`;
        }
      }
    }
    return null;
  },
  /**
   * Return the total reimbursement based on the given category and value
   * @param category
   * @param value
   * @returns {number|null|*|null|number}
   */
  getActualReimbursement(category, value = 0) {
    if (category) {
      if (isFlatRate(category)) { // Reimbursement correspond to the one defined for the category
        return category.reimbursement;
      }
      if (isMileage(category)) { // Reimbursement is proportional to the value (km/mi) and reimbursement defined per km/mi
        return value * category.reimbursement;
      }
      if (isQuantity(category)) { // Reimbursement is proportional to the value (quantity) and reimbursement defined per quantity
        return value * category.reimbursement;
      }
      if (isTotal(category)) { // The total amount is reimbursed
        return value;
      }
      if (isMax(category)) { // A maximum reimbursement is defined
        return value < category.reimbursement ? value : category.reimbursement;
      }
    }
    return null;
  },
};

module.exports = expenseCategoryService;
