/* eslint-disable react/no-did-update-set-state */
const React = require('react');
const isEqual = require('react-fast-compare');
const moment = require('moment');
const Sidebar = require('../../../../../../common/react/sidebar/Sidebar.react');
const ProductBody = require('./ProductBody.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 *
 * onSave
 * onClose
 *
 * @type {ProductSidebar}
 */
module.exports = class ProductSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  static isNew(item) {
    return !item || !item.id;
  }

  /**
   * Remove the given name from the list of changes
   * @param changes
   * @param name
   */
  static resetChanges(changes, name) {
    return changes.filter((attr) => attr !== name);
  }

  /**
   * Add the given name to the list of changes, if not already present
   * @param changes
   * @param name
   */
  static addChanges(changes, name) {
    if (!changes.includes(name)) {
      changes.push(name);
    }
    return changes;
  }

  /**
   * Return the updated item, merging the changes into the oldItem
   * @param oldItem
   * @param changes
   */
  static getUpdatedItem(oldItem, changes) {
    if (oldItem) {
      const updatedItem = { ...oldItem };
      if (changes) {
        Object.keys(changes).forEach((key) => {
          updatedItem[key] = changes[key];
        });
      }
      return updatedItem;
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item,
    };
  }

  componentDidMount() {
    const isNew = ProductSidebar.isNew(this.props.item);
    this.props.changeSidebarEditMode(isNew);
    this.props.changeUnsavedChanges([]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.item && this.props && this.props.item) {
      const updatedItem = { ...prevState.item };

      // Update the newly created item id once it is first saved
      const hasNewId = !prevState.item.id && this.props.item.id;

      if (hasNewId) {
        updatedItem.id = this.props.item.id;

        this.setState({
          item: updatedItem,
        });
      }
    }
  }

  /**
   * Update the item and unsaved changes
   * @param name
   * @param value
   */
  handleInputChanges(name, value) {
    this.setState((prevState) => {
      const formattedChanges = this.getFormattedChanges(name, value);

      const unsavedChanges = this
        .updateChangedAttributesList(this.props.unsavedChanges, formattedChanges);

      const updatedItem = ProductSidebar.getUpdatedItem(prevState.item, formattedChanges);

      this.props.changeUnsavedChanges(unsavedChanges);

      return {
        item: updatedItem,
      };
    });
  }

  /**
   * Save the item with the modified attributes.
   */
  handleSave() {
    const formattedChanges = this.getUnsavedChanges();
    const updatedItem = ProductSidebar.getUpdatedItem(this.props.item, formattedChanges);

    this.props.onSave(formattedChanges, updatedItem);
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.props.changeSidebarEditMode(true);
    }
  }

  /* avoid to set the available_from date after the available_to date */
  getFormattedDateFrom(name, value) {
    const availableFrom = value ? moment(value) : null;
    const availableTo = this.state.item.available_to
      ? moment(this.state.item.available_to) : null;

    const from = availableFrom ? availableFrom.format('YYYY-MM-DD') : null;
    let to = availableTo ? availableTo.format('YYYY-MM-DD') : null;

    if (availableFrom && availableTo && availableFrom.isSameOrAfter(availableTo, 'day')) {
      to = availableFrom.add(1, 'days').format('YYYY-MM-DD');
    }
    return { available_from: from, available_to: to };
  }

  /* avoid to set the available_to date before the available_from date */

  getFormattedDateTo(name, value) {
    const availableFrom = this.state.item.available_from
      ? moment(this.state.item.available_from) : null;
    const availableTo = value ? moment(value) : null;

    let from = availableFrom?.format('YYYY-MM-DD');
    const to = availableTo?.format('YYYY-MM-DD');

    if (availableFrom && availableTo && availableFrom.isSameOrAfter(availableTo, 'day')) {
      from = availableTo.subtract(1, 'days').format('YYYY-MM-DD');
    }
    return { available_from: from, available_to: to };
  }

  /**
   * Return the new changes, properly formatted
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {*}
   */
  getFormattedChanges(name, value) {
    switch (name) {
      case 'available_from':
        return this.getFormattedDateFrom(name, value);
      case 'available_to':
        return this.getFormattedDateTo(name, value);
      default:
        return { [name]: value };
    }
  }

  /**
   * Return the changes to be saved
   * @returns {{}}
   */
  getUnsavedChanges() {
    const changes = {};
    this.props.unsavedChanges.forEach((key) => {
      changes[key] = this.state.item[key];
    });
    return changes;
  }

  getBody() {
    return (
      <ProductBody readOnly={!this.props.editMode}
        item={this.state.item}
        levels={this.props.levels}
        errors={this.props.errors}
        updateErrors={this.props.updateErrors}
        isNew={ProductSidebar.isNew(this.state.item)}
        onChange={this.handleInputChanges.bind(this)} />
    );
  }

  getTitle() {
    return ProductSidebar.isNew(this.props.item) ? 'New product' : 'Product';
  }

  /**
   * Checks if it's safe to save an item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.props.isValid;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEdit && !this.props.editMode;
  }

  hasUnsavedChanges() {
    return this.props.unsavedChanges && this.props.unsavedChanges.length > 0;
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldItem = this.props.item || {};
    let oldVal = null;
    const newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      default:
        oldVal = oldItem[name];
        break;
    }

    return (ProductSidebar.isDirty(oldVal) || ProductSidebar.isDirty(newVal))
      && (!isEqual(oldVal, newVal));
  }

  /**
   * Return the updated list of changed attributes
   * @param oldChangesList
   * @param newChanges
   * @returns {*}
   */
  updateChangedAttributesList(oldChangesList, newChanges) {
    let changesList = oldChangesList;
    Object.keys(newChanges).forEach((key) => {
      const value = newChanges[key];

      if (this.hasChanged(key, value)) {
        changesList = ProductSidebar.addChanges(changesList, key);
      } else {
        changesList = ProductSidebar.resetChanges(changesList, key);
      }
    });

    return changesList;
  }

  render() {
    return (
      <Sidebar title={this.getTitle()}
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()} />
    );
  }
};
