const { connect } = require('react-redux');
const Component = require('../../../components/Calculator/Summary/TotalSummary.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  version: state.targetVersion,
  scrollingComponentAmount: state.scrollingComponentAmount,
  levelsExtended: state.info.levels,
});

const mapDispatchToProps = (dispatch) => ({
  updateInfoTotals: (price, cost, days, externalCosts) => dispatch(actions
    .updateInfoTotals(price, cost, days, externalCosts)),
  setScrollingComponentScrollAmount: ((amount) => dispatch(actions
    .setScrollingComponentScrollAmount(amount))),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
