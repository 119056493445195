const Supplier = {
  getAll(options) {
    return Wethod.request('get:finance:supplier:list', options);
  },
  create(attributes, force) {
    const model = new Wethod.FinanceApp.Supplier.SupplierModel(attributes);

    return Wethod.request('save:finance:supplier', model, force);
  },
  update(id, attributes) {
    const model = new Wethod.FinanceApp.Supplier.SupplierModel({ id, ...attributes });

    return Wethod.request('save:finance:supplier', model);
  },
  delete(id) {
    const model = new Wethod.FinanceApp.Supplier.SupplierModel({ id });

    return Wethod.request('delete:finance:supplier', model);
  },
};

module.exports = Supplier;
