const { connect } = require('react-redux');
const Component = require('../../components/Boards/Boards.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  boards: state.boards,
  projectId: state.info.id,
  canEdit: state.canEdit,
  isWaitingForBoards: state.waitingFor.filter((key) => key === 'boards').length > 0,
  isWaitingForNewBoard: state.waitingFor.filter((key) => key === 'new-board').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getBoards: (projectId) => dispatch(actions.getBoards(projectId)),
  addBoard: (projectId, board) => dispatch(actions.addBoard(projectId, board)),
  sortBoards: (projectId, boards) => dispatch(actions.updateBoardSort(projectId, boards)),
  saveBoardsSort: (projectId, boards) => dispatch(actions.saveBoardsSort(projectId, boards)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
