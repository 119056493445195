'use strict';

Wethod.module('BudgetApp', function (BudgetApp, Wethod) {
  this.startWithParent = false;

  var API = {
    showBudget: function (id, version, withCorrection, idTour) {
      BudgetApp.Budget.Controller.showBudget(id, version, withCorrection, idTour);
    },
  };

  // usato dalla pagina di creazione del budget per reindirizzare alla pagina del
  // pagina di modifica
  Wethod.on('url:budget:show', function (id) {
    Wethod.navigate('pipeline/budget/' + id);
    API.showBudget(id, null);
  });

  BudgetApp.on('start', function (options) {
    dispatcher.trigger('set:active:tab', 'pipeline');
    API[options.foo](options.id, options.version, options.withCorrection, options.idTour);
  });
});
