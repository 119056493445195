/* eslint-disable no-shadow */
const { connect } = require('react-redux');
const Component = require('../../components/Trends/TrendFridayness/TrendFridayness.react');
const actions = require('../../actions');

const getData = (weeks, filter) => {
  if (filter === 'month') {
    return weeks.filter((week, index, weeks) => index > weeks.length - 13);
  }
  return weeks;
};

const mapStateToProps = (state) => ({
  employee: state.id,
  title: 'Fridayness',
  subtitle: 'Days of delay in compiling timesheet & project status',
  data: getData(state.fridaynessTrend, state.trendsFilter),
});

const mapDispatchToProps = (dispatch) => ({
  getTrend: (employeeId) => dispatch(actions.getFridaynessTrend(employeeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
