/* eslint-disable react/require-default-props,react/prefer-stateless-function */
/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-noninteractive-element-interactions */
const React = require('react');
const PropTypes = require('prop-types');

class MenuItem extends React.Component {
  onClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  getStyle() {
    let style = 'wethod-menu__item';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  render() {
    return (
      <li className={this.getStyle()} onClick={this.onClick.bind(this)}>{this.props.children}</li>
    );
  }
}

MenuItem.propTypes = {
  /**
   * Item content.
   */
  children: PropTypes.node,
  /**
   * The function to call when item is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Additional class given to the item
   */
  className: PropTypes.string,
};

module.exports = MenuItem;
