'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod, Backbone, Marionette) {
  this.TooltipView = Marionette.CollectionView.extend({
    tagName: 'ul',
    childView: RuleManager.TooltipOptionView,
    childEvents: {
      'tooltip:item:selected': 'onChildItemSelected',
    },
    onChildItemSelected: function (childView, parameters) {
      this.trigger('tooltip:item:selected', parameters.options);
    },
    toHtml: function () {
      return this.el.parentElement.outerHTML;
    },
  });
});
