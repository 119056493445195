/* eslint-disable react/sort-comp,no-param-reassign,class-methods-use-this,consistent-return,no-console */
const React = require('react');
const Spreadsheet = require('../../containers/Boards/Spreadsheet/Spreadsheet');
const Kanban = require('../../containers/Boards/Kanban/Kanban');
const Gantt = require('../../containers/Boards/Gantt/Gantt');
const BoardHeader = require('./BoardHeader/BoardHeader.react');
const LoadingContent = require('./LoadingBoards.react');
const BoardAttributeModel = require('../../models/BoardAttributeModel');

module.exports = class Board extends React.Component {
  componentDidMount() {
    this.props.getContent(this.props.projectId, this.props.id);
  }

  scrollToBoard() {
    if (this.focusedBoard) {
      this.focusedBoard.scrollIntoView();

      // now account for fixed header
      const scrolledY = window.scrollY;
      if (scrolledY) {
        window.scroll(0, scrolledY - Wethod.getHeaderHeight());
      }
    }
  }

  saveKanbanOptions(attributeId) {
    const model = new BoardAttributeModel(null, { boardId: this.props.id });
    model.savePivot(attributeId);
  }

  saveSpreadsheetOptions(attributeId) {
    const model = new BoardAttributeModel(null, { boardId: this.props.id });
    model.saveActivityAttribute(attributeId);
  }

  handleActivityAttributeUpdate(attributeId, attributeType) {
    if (this.props.options.spreadsheet) {
      const updatedOptions = {
        ...this.props.options,
        spreadsheet: {
          ...this.props.options.spreadsheet,
          [attributeType]: attributeId,
        },
      };
      this.props.updateActivityAttribute(this.props.id, updatedOptions);
    }
  }

  /**
   * Return which kanban options has changed, if any.
   * @param prevProps
   * @return {null|*}
   */
  getKanbanOptionsLastChange(prevProps) {
    const prevBoardOptions = prevProps.options;
    const boardOptions = this.props.options;
    if (prevBoardOptions && boardOptions) {
      const prevKanbanOptions = prevBoardOptions.kanban;
      const kanbanOptions = boardOptions.kanban;
      const changedStatusPivot = prevKanbanOptions.status !== kanbanOptions.status;
      const changedMemberAttribute = prevKanbanOptions.member !== kanbanOptions.member;
      const changedDateAttribute = prevKanbanOptions.date !== kanbanOptions.date;

      if (changedStatusPivot && kanbanOptions.status !== null) {
        return kanbanOptions.status;
      }
      if (changedMemberAttribute && kanbanOptions.status !== null) {
        return kanbanOptions.member;
      }
      if (changedDateAttribute && kanbanOptions.status !== null) {
        return kanbanOptions.date;
      }
    }
    return null;
  }

  /**
   * Return which spreadsheet options has changed, if any.
   * @param prevProps
   * @return {null|*}
   */
  getSpreadsheetOptionsLastChange(prevProps) {
    const prevBoardOptions = prevProps.options;
    const boardOptions = this.props.options;
    if (prevBoardOptions && boardOptions) {
      const prevSpreadsheetOptions = prevBoardOptions.spreadsheet;
      const spreadsheetOptions = boardOptions.spreadsheet;
      if (spreadsheetOptions) {
        let changedStatus;
        let
          changedMember;
        if (!prevSpreadsheetOptions) {
          changedStatus = !!spreadsheetOptions.status;
          changedMember = !!spreadsheetOptions.member;
        } else {
          changedStatus = prevSpreadsheetOptions.status !== spreadsheetOptions.status;
          changedMember = prevSpreadsheetOptions.member !== spreadsheetOptions.member;
        }

        if (changedStatus && spreadsheetOptions.status) {
          return spreadsheetOptions.status;
        }
        if (changedMember && spreadsheetOptions.member) {
          return spreadsheetOptions.member;
        }
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const changedBoardKanbanOption = this.getKanbanOptionsLastChange(prevProps);
    if (changedBoardKanbanOption !== null) {
      this.saveKanbanOptions(changedBoardKanbanOption);
    }
    const changedBoardSpreadsheetOption = this.getSpreadsheetOptionsLastChange(prevProps);
    if (changedBoardSpreadsheetOption !== null) {
      this.saveSpreadsheetOptions(changedBoardSpreadsheetOption);
    }
  }

  isDeletingBoard() {
    return this.props.waitingFor.filter((key) => key === `delete-board-${this.props.id}`).length > 0;
  }

  isDeletingBoardItem(itemId) {
    if (itemId !== undefined) { // Check if deleting the specific item with itemId
      return this.props.waitingFor.filter((key) => key === `delete-board-${this.props.id}-item-${itemId}`).length > 0;
    } // Check if deleting an item of this board
    return this.props.waitingFor.filter((key) => key.indexOf(`delete-board-${this.props.id}-item`) !== -1).length > 0;
  }

  isDeletingBoardStructureAttribute(attributeId) {
    if (attributeId !== undefined) { // Check if deleting the specific attribute with attributeId
      return this.props.waitingFor.filter((key) => key === `delete-board-${this.props.id}-structure-attribute-${attributeId}`).length > 0;
    } // Check if deleting an attribute of this board
    return this.props.waitingFor.filter((key) => key.indexOf(`delete-board-${this.props.id}-structure-attribute`) !== -1).length > 0;
  }

  isAddingStructureAttribute() {
    return this.props.waitingFor.filter((key) => key === `edit-board-${this.props.id}`).length > 0;
  }

  isWaitingForAddingItem() {
    return this.props.waitingFor.filter((key) => key === `add-board-${this.props.id}-item`).length > 0;
  }

  isWaitingForItems() {
    return this.props.waitingFor.filter((key) => key === `get-board-items-${this.props.id}`).length > 0;
  }

  isWaitingForCellSaving(itemId, attributeId) {
    return this.props.waitingFor.filter((key) => key === `edit-board-${this.props.id}-item-${itemId}-attribute-${attributeId}`).length > 0;
  }

  isWaitingForMemberDeleting(itemId, attributeId) {
    return this.props.waitingFor.filter((key) => key === `delete-board-${this.props.id}-item-${itemId}-attribute-${attributeId}-member`).length > 0;
  }

  onDeleteClick() {
    const modalData = {
      projectId: this.props.projectId,
      boardId: this.props.id,
      boardName: this.props.name,
    };
    this.props.showDeleteModal(modalData, 'board');
  }

  getBoardLink() {
    return `${window.location.href.split('?')[0]}?board=${this.props.id}`;
  }

  /**
   * Returns how many cards there are in the default kanban's list.
   * The default kanban's list is the one corresponding to the default value for the status attribute used as pivot.
   * @return {*}
   */
  getKanbanDefaultCardsLength() {
    const kanbanOptions = this.props.options.kanban;
    const pivot = kanbanOptions.status;
    if (pivot === null) {
      return this.props.items.length;
    }
    const defaultCards = this.props.items.filter((item) => {
      const pivotAttribute = item.attributes
        .filter((attribute) => attribute.attribute === pivot)[0];
      return pivotAttribute.value.default;
    });

    return defaultCards.length;
  }

  addItem(name, kanbanSort, attributes) {
    attributes = attributes || [];

    const item = {
      sort: this.props.items.length,
      kanban_sort: kanbanSort !== undefined ? kanbanSort : this.getKanbanDefaultCardsLength(),
      name,
      attributes,
    };
    this.props.addItem(this.props.id, item);
  }

  getMaxSortValue(array) {
    const max = array.reduce((prev, current) => ((prev.sort > current.sort) ? prev : current), 0);
    return max ? max.sort : 0;
  }

  getSummaryFunction(type) {
    switch (type) {
      case 'numeric':
        return 'sum';
      case 'status':
        return 'ratio';
      default:
        return null;
    }
  }

  addAttribute(type) {
    const attribute = {
      sort: this.getMaxSortValue(this.props.structure) + 1,
      type,
      summary: this.getSummaryFunction(type),
    };
    this.props.addStructureAttribute(this.props.id, attribute);
  }

  editItemName(itemId, name) {
    this.props.editItemName(this.props.id, itemId, name);
  }

  deleteItem(itemId, itemName) {
    const modalData = {
      boardId: this.props.id,
      itemId,
      itemName,
    };
    this.props.showDeleteModal(modalData, 'item');
  }

  deleteAttribute(attributeId, attributeName) {
    const modalData = {
      boardId: this.props.id,
      attributeId,
      attributeName,
    };
    this.props.showDeleteModal(modalData, 'attribute');
  }

  editAttributeValue(itemId, attributeId, value) {
    this.props.editAttributeValue(this.props.id, itemId, attributeId, value);
  }

  addAttributeValueMember(itemId, attributeId, member) {
    this.props.addAttributeValueMember(this.props.id, itemId, attributeId, member);
  }

  deleteAvailableStatus(attributeId, statusId) {
    this.props.deleteAvailableStatus(this.props.id, attributeId, statusId);
  }

  updateAvailableStatus(attributeId, status) {
    this.props.updateAvailableStatus(this.props.id, attributeId, status);
  }

  createAvailableStatus(attributeId, status) {
    this.props.createAvailableStatus(this.props.id, attributeId, status);
  }

  deleteMember(itemId, attributeId, memberId) {
    this.props.deleteMember(this.props.id, itemId, attributeId, memberId);
  }

  deleteMilestone(itemId, attributeId) {
    this.props.deleteMilestone(this.props.id, itemId, attributeId);
  }

  getByType() {
    switch (this.props.type) {
      case 'spreadsheet':
        return (
          <Spreadsheet waiting={this.isWaitingForItems()}
            items={this.props.items}
            structure={this.props.structure}
            options={this.props.options ? this.props.options.spreadsheet : null}
            scrollToBoard={this.scrollToBoard.bind(this)}
            addItem={this.addItem.bind(this)}
            addAttribute={this.addAttribute.bind(this)}
            updateActivityAttribute={this.handleActivityAttributeUpdate.bind(this)}
            editItemName={this.editItemName.bind(this)}
            deleteItem={this.deleteItem.bind(this)}
            editAttributeValue={this.editAttributeValue.bind(this)}
            addAttributeValueMember={this.addAttributeValueMember.bind(this)}
            deleteAvailableStatus={this.deleteAvailableStatus.bind(this)}
            updateAvailableStatus={this.updateAvailableStatus.bind(this)}
            createAvailableStatus={this.createAvailableStatus.bind(this)}
            deleteMember={this.deleteMember.bind(this)}
            boardId={this.props.id}
            isDeletingItem={this.isDeletingBoardItem.bind(this)}
            isDeletingAttribute={this.isDeletingBoardStructureAttribute.bind(this)}
            deleteAttribute={this.deleteAttribute.bind(this)}
            deleteMilestone={this.deleteMilestone.bind(this)}
            waitingForAddingItem={this.isWaitingForAddingItem()}
            waitingForAddingAttribute={this.isAddingStructureAttribute()}
            isWaitingForCellSaving={this.isWaitingForCellSaving.bind(this)}
            isWaitingForMemberDeleting={this.isWaitingForMemberDeleting.bind(this)} />
        );
      case 'kanban':
        return (
          <Kanban items={this.props.items}
            options={this.props.options.kanban}
            structure={this.props.structure}
            addItem={this.addItem.bind(this)}
            editItemName={this.editItemName.bind(this)}
            deleteItem={this.deleteItem.bind(this)}
            editAttributeValue={this.editAttributeValue.bind(this)}
            addAttributeValueMember={this.addAttributeValueMember.bind(this)}
            deleteMember={this.deleteMember.bind(this)}
            boardId={this.props.id}
            isDeletingItem={this.isDeletingBoardItem.bind(this)}
            isDeletingAttribute={this.isDeletingBoardStructureAttribute.bind(this)}
            deleteAttribute={this.deleteAttribute.bind(this)}
            waitingForAddingItem={this.isWaitingForAddingItem()}
            isWaitingForMemberDeleting={this.isWaitingForMemberDeleting.bind(this)} />
        );
      case 'gantt':
        return (
          <Gantt items={this.props.items}
            structure={this.props.structure}
            addItem={this.addItem.bind(this)}
            editItemName={this.editItemName.bind(this)}
            deleteItem={this.deleteItem.bind(this)}
            editAttributeValue={this.editAttributeValue.bind(this)}
            boardId={this.props.id}
            isDeletingItem={this.isDeletingBoardItem.bind(this)}
            waitingForAddingItem={this.isWaitingForAddingItem()} />
        );
      default:
        console.log('Type not found');
    }
  }

  getContent() {
    if (this.isWaitingForItems()) {
      return <LoadingContent />;
    }
    return this.getByType();
  }

  getClassName() {
    let name = 'project-canvas-board';
    const waiting = this.isWaitingForAddingItem() || this.isAddingStructureAttribute()
      || this.isDeletingBoardItem() || this.isDeletingBoardStructureAttribute();
    if (waiting) {
      name += ' project-canvas-board--disabled';
    }
    return name;
  }

  editName(value) {
    this.props.editName(this.props.projectId, this.props.id, value);
  }

  getContentStyle() {
    if (this.props.dragging) {
      return { display: 'none' };
    }
  }

  changeType(type) {
    this.props.changeType(this.props.id, type);
  }

  updateKanbanOptions(options) {
    this.props.updateKanbanOptions(this.props.id, options);
  }

  render() {
    return (
      <div className={this.getClassName()}
        ref={(boardRef) => {
          if (boardRef) {
            this.focusedBoard = boardRef;
          }
        }}
        data-id={this.props.id}
        data-sort={this.props.sort}>
        <BoardHeader
          canEdit={this.props.canEdit}
          name={this.props.name}
          boardType={this.props.type}
          editName={this.editName.bind(this)}
          changeType={this.changeType.bind(this)}
          isDeletingBoard={this.isDeletingBoard()}
          updateKanbanOptions={this.updateKanbanOptions.bind(this)}
          type={this.props.type}
          options={this.props.options}
          structure={this.props.structure}
          showMissingStatusModal={this.props.showMissingStatusForKanbanModal}
          onDeleteClick={this.onDeleteClick.bind(this)}
          boardLink={this.getBoardLink()} />
        <div className="project-canvas-board__content" style={this.getContentStyle()}>
          {this.getContent()}
        </div>
      </div>
    );
  }
};
