const React = require('react');
const ShareModal = require('../containers/ShareModal');

const Modal = ({ toShow }) => {
  const getModal = () => {
    switch (toShow) {
      case 'share':
        return <ShareModal />;
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
