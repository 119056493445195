const { connect } = require('react-redux');
const Component = require('../components/BudgetTemplates.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
  showSidebar: !!state.focusedTemplate,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(actions.getTemplates()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
