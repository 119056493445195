const React = require('react');
const PropTypes = require('prop-types');
const AdvancedSearch = require('./AdvancedSearch.react');
const Segment = require('./Segment.react');

const AdvancedFiltersController = ({
  show,
  filters,
  onClose,
  onSave,
  children,
  canSave,
  segment,
  errors,
  updateErrors,
}) => {
  if (show === 'segment') {
    return (
      <Segment show
        segment={segment}
        onClose={onClose}
        onSave={onSave}
        canSave={canSave}
        errors={errors}
        updateErrors={updateErrors}>
        {children}
      </Segment>
    );
  }
  if (show === 'filters') {
    return (
      <AdvancedSearch show
        filters={filters}
        onClose={onClose}
        onSave={onSave}
        canSave={canSave}>
        {children}
      </AdvancedSearch>
    );
  }

  return null;
};

AdvancedFiltersController.propTypes = {
  /**
   * Segment
   * contains the id, the name and the filters
   * @see AdvancedSearchManager
   */
  segment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filters: PropTypes.shape({}),
  }),
  /**
   * Which sidebar should be shown
   * @see AdvancedSearch
   */
  show: PropTypes.oneOf(['segment', 'filters']),
  /**
   * Object representing the filters contained in the advanced search.
   * The object should have as keys the names of the filters,
   * and as values the values of the filters.
   * The values are injected in the children with the corresponding name
   * @see AdvancedSearch
   */
  filters: PropTypes.shape({}),
  /**
   * @see Sidebar
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @see Sidebar
   */
  onSave: PropTypes.func.isRequired,
  /**
   * List of filters shown in the advanced search
   * @see AdvancedSearch
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    // Required check
    if (!prop) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`);
    }

    React.Children.forEach(prop, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.name === undefined || child.props.name === null) {
          // 'Name' prop is required in every children
          error = new Error(`\`${componentName}\` children should have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
        if (child.props.label === undefined || child.props.label === null) {
          // 'Label' prop is required in every children
          error = new Error(`\`${componentName}\` children should have \`label\` prop, but its value is \`${child.props.label}\`.`);
        }
      } else {
        // Type check
        error = new Error(`Invalid prop \`children\` of type \`${typeof child}\` supplied to \`${componentName}\`, children should be valid elements.`);
      }
    });
    return error;
  }, // isRequired
  /**
   * @see Sidebar
   */
  canSave: PropTypes.bool,
  errors: PropTypes.shape({}),
  updateErrors: PropTypes.func,
};

AdvancedFiltersController.defaultProps = {
  show: null,
  canSave: false,
  errors: {},
  updateErrors: undefined,
  segment: { id: null, name: '', filters: {} },
  filters: {},
  children: undefined,
};

module.exports = AdvancedFiltersController;
