/* eslint-disable class-methods-use-this,no-param-reassign */
const constants = require('./constants');
const BusinessUnitService = require('../../../../../services/BusinessUnitService');
const FiscalYearService = require('../../../../../services/FiscalYearService');
const { getLanguageLevels } = require('../../../services/LanguageService');

class ProfileReducer {
  constructor(state) {
    this.businessUnitService = new BusinessUnitService();
    this.fiscalYearService = new FiscalYearService();
    const tags = this.businessUnitService.isEnabled()
      ? state.tags.concat(this.businessUnitService.findById(state.business_unit_id)) : state.tags;

    this.state = {
      ...state,
      tags,
      modalToShow: null,
      waiting: false,
      creating: false,
      workingConnectionsProjects: {
        title: '',
        projects: [],
      },
      languages: state.languages.sort(this.sortByDescId),
      portfolio: state.portfolio.sort(this.sortByDescId),
      sponsors: state.sponsors.sort(this.sortByDescId),
      waitingForObjectives: false,
      quartersFilter: null,
      trendsFilter: 'month',
      teammates: state.people.map((person) => ({
        id: person.id,
        name: `${person.name} ${person.surname}`,
      })),
      focusedObjective: null,
      languageLevels: getLanguageLevels(),
      objectivesByQuarter: {
        year: this.fiscalYearService.getShiftedYear(),
        resolution: '',
        quarters: [],
      },
      fridaynessTrend: [],
      doTheBudgetTrend: [],
      doThePlanTrend: [],
      keepTheLightsOffTrend: [],
      workingConnectionTrend: [],
      chargeabilityTrend: {
        chargeability: [],
        level_label: '',
        level_average: 0.001,
      },
      waitingForKudos: true,
      kudos: {
        year: this.fiscalYearService.getShiftedYear(),
        as_pm: null,
        as_team_member: null,
        people_say: [],
      },
      waitingForGender: false,
      genders: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  sortByAscId(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    return 1;
  }

  updateSkillId(collection, skill) {
    return collection.map((item) => {
      if (item.name === skill.name) {
        return {
          ...item,
          id: skill.id,
        };
      }
      return item;
    });
  }

  updateInterestId(collection, interest) {
    return collection.map((item) => {
      if (item.name === interest.name) {
        return {
          ...item,
          id: interest.id,
        };
      }
      return item;
    });
  }

  addKnownSkill(collection, skill) {
    let found = false;
    for (let i = 0; i < collection.length && !found; i++) {
      if (collection[i].name === skill.name) {
        collection[i].count += 1;
        found = true;
      }
    }
    if (!found) {
      return collection.concat({
        ...skill,
        count: 1,
      });
    }
    return [...collection];
  }

  addKnownInterest(collection, interest) {
    let found = false;
    for (let i = 0; i < collection.length && !found; i++) {
      if (collection[i].name === interest.name) {
        collection[i].count += 1;
        found = true;
      }
    }
    if (!found) {
      return collection.concat({
        ...interest,
        count: 1,
      });
    }
    return [...collection];
  }

  deleteSkill(collection, skill) {
    return collection.filter((item) => item.name !== skill.name);
  }

  deleteInterest(collection, interest) {
    return collection.filter((item) => item.name !== interest.name);
  }

  deleteKnownSkill(collection, skill) {
    let found = false;
    for (let i = 0; i < collection.length && !found; i++) {
      if (collection[i].name === skill.name) {
        collection[i].count -= 1;
        found = true;
      }
    }
    return collection.filter((item) => item.count > 0);
  }

  deleteKnownInterest(collection, interest) {
    let found = false;
    for (let i = 0; i < collection.length && !found; i++) {
      if (collection[i].name === interest.name) {
        collection[i].count -= 1;
        found = true;
      }
    }
    return collection.filter((item) => item.count > 0);
  }

  getSponsorPerson(id) {
    const { people } = this.state;
    for (let i = 0; i < people.length; i++) {
      if (people[i].id === id) {
        return people[i];
      }
    }
    return {};
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return {
          ...state,
          modalToShow: null,
          focusedObjective: null,
        };
      case constants.SHOW_MODAL_CONTACT_INFO:
        return {
          ...state,
          modalToShow: 'contact-info',
        };
      case constants.SHOW_MODAL_BIO:
        return {
          ...state,
          modalToShow: 'bio',
        };
      case constants.SHOW_MODAL_SKILLS:
        return {
          ...state,
          modalToShow: 'skills',
        };
      case constants.SHOW_MODAL_INTERESTS:
        return {
          ...state,
          modalToShow: 'interests',
        };
      case constants.SHOW_MODAL_LANGUAGES:
        return {
          ...state,
          modalToShow: 'languages',
        };
      case constants.SHOW_MODAL_PORTFOLIO:
        return {
          ...state,
          modalToShow: 'portfolio',
        };
      case constants.SHOW_MODAL_SPONSORS:
        return {
          ...state,
          modalToShow: 'sponsors',
        };
      case constants.SHOW_MODAL_RESOLUTION:
        return {
          ...state,
          modalToShow: 'year-resolution',
        };
      case constants.SHOW_MODAL_PROFILE_PIC:
        return {
          ...state,
          modalToShow: 'profile-pic',
        };
      case constants.SHOW_MODAL_WORKING_CONNECTION:
        return {
          ...state,
          modalToShow: 'working-connection',
          workingConnectionsProjects: {
            title: action.title,
            projects: action.projects,
          },
        };
      case constants.SHOW_MODAL_OBJECTIVE:
        return {
          ...state,
          modalToShow: 'objective',
          focusedObjective: {
            id: action.objective.id,
            quarterId: action.objective.quarterId,
          },
        };
      case constants.EDIT_INFO_REQUEST:
        return {
          ...state,
          waiting: true,
        };
      case constants.EDIT_INFO_SUCCESS:
        return {
          ...state,
          person: { ...state.person, ...action.info },
          waiting: false,
          modalToShow: null,
        };
      case constants.EDIT_RESOLUTION_REQUEST:
        return {
          ...state,
          waiting: true,
        };
      case constants.EDIT_RESOLUTION_SUCCESS:
        return {
          ...state,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            resolution: action.resolution,
          },
          waiting: false,
          modalToShow: null,
        };
      case constants.ADD_SKILL_REQUEST:
        return {
          ...state,
          skills: state.skills.concat(action.skill),
          known_skills: this.addKnownSkill(state.known_skills, action.skill),
        };
      case constants.ADD_SKILL_SUCCESS:
        return {
          ...state,
          skills: this.updateSkillId(state.skills, action.skill),
          known_skills: this.updateSkillId(state.known_skills, action.skill),
        };
      case constants.DELETE_SKILL_REQUEST:
        return {
          ...state,
          skills: this.deleteSkill(state.skills, action.skill),
          known_skills: this.deleteKnownSkill(state.known_skills, action.skill),
        };
      case constants.ADD_INTEREST_REQUEST:
        return {
          ...state,
          interests: state.interests.concat(action.interest),
          known_interests: this.addKnownInterest(state.known_interests, action.interest),
        };
      case constants.ADD_INTEREST_SUCCESS:
        return {
          ...state,
          interests: this.updateInterestId(state.interests, action.interest),
          known_interests: this.updateInterestId(state.known_interests, action.interest),
        };
      case constants.DELETE_INTEREST_REQUEST:
        return {
          ...state,
          interests: this.deleteInterest(state.interests, action.interest),
          known_interests: this.deleteKnownInterest(state.known_interests, action.interest),
        };
      case constants.UPDATE_LANGUAGE_REQUEST:
        return {
          ...state,
          waiting: true,
          languages: state.languages.map((language) => {
            if (language.id === action.language.id) {
              return { ...action.language };
            }
            return language;
          }),
        };
      case constants.UPDATE_LANGUAGE_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.DELETE_LANGUAGE_REQUEST:
        return {
          ...state,
          waiting: true,
          languages: state.languages.filter((language) => language.id !== action.language.id),
        };
      case constants.DELETE_LANGUAGE_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.ADD_LANGUAGE_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case constants.ADD_LANGUAGE_SUCCESS:
        return {
          ...state,
          languages: state.languages.concat(action.language).sort(this.sortByAscId),
          creating: false,
        };
      case constants.UPDATE_WORK_REQUEST:
        return {
          ...state,
          waiting: true,
          portfolio: state.portfolio.map((work) => {
            if (work.id === action.work.id) {
              return { ...action.work };
            }
            return work;
          }),
        };
      case constants.UPDATE_WORK_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.DELETE_WORK_REQUEST:
        return {
          ...state,
          waiting: true,
          portfolio: state.portfolio.filter((work) => work.id !== action.work.id),
        };
      case constants.DELETE_WORK_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.ADD_WORK_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case constants.ADD_WORK_SUCCESS:
        return {
          ...state,
          creating: false,
          portfolio: state.portfolio.concat(action.work).sort(this.sortByDescId),
        };
      case constants.ADD_SPONSOR_REQUEST:
        return {
          ...state,
          waiting: true,
        };
      case constants.ADD_SPONSOR_SUCCESS:
        return {
          ...state,
          sponsors: state.sponsors
            .concat({ ...this.getSponsorPerson(action.sponsor.sponsor), ...action.sponsor })
            .sort(this.sortByDescId),
          waiting: false,
        };
      case constants.DELETE_SPONSOR_REQUEST:
        return {
          ...state,
          waiting: true,
          sponsors: state.sponsors.filter((sponsor) => sponsor.id !== action.sponsor.id),
        };
      case constants.DELETE_SPONSOR_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.END_SPONSOR_REQUEST:
        return {
          ...state,
          waiting: true,
          sponsors: state.sponsors.map((sponsor) => {
            if (sponsor.id === action.sponsor.id) {
              return {
                ...sponsor,
                to_date: action.sponsor.to_date,
              };
            }
            return sponsor;
          }),
        };
      case constants.END_SPONSOR_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.EDIT_KEY_RESULT_REQUEST:
        return {
          ...state,
          waiting: true,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                return {
                  ...quarter,
                  objectives: quarter.objectives.map((objective) => {
                    if (objective.id === action.objectiveId) {
                      return {
                        ...objective,
                        key_results: objective.key_results.map((kr) => {
                          if (kr.id === action.keyResult.id) {
                            return { ...action.keyResult };
                          }
                          return kr;
                        }),
                      };
                    }
                    return objective;
                  }),
                };
              }
              return quarter;
            }),
          },
        };
      case constants.EDIT_KEY_RESULT_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.DELETE_KEY_RESULT_REQUEST:
        return {
          ...state,
          waiting: true,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                return {
                  ...quarter,
                  objectives: quarter.objectives.map((objective) => {
                    if (objective.id === action.objectiveId) {
                      return {
                        ...objective,
                        key_results: objective.key_results
                          .filter((kr) => kr.id !== action.keyResult.id),
                      };
                    }
                    return objective;
                  }),
                };
              }
              return quarter;
            }),
          },
        };
      case constants.UPDATE_QUARTER_STATUS_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.GET_FRIDAYNESS_TREND_REQUEST:
        return {
          ...state,
          waitingForFridaynessTrend: true,
        };
      case constants.GET_FRIDAYNESS_TREND_SUCCESS:
        return {
          ...state,
          waitingForFridaynessTrend: false,
          fridaynessTrend: action.data,
        };
      case constants.GET_DO_THE_BUDGET_TREND_REQUEST:
        return {
          ...state,
          waitingForDoTheBudgetTrend: true,
        };
      case constants.GET_DO_THE_BUDGET_TREND_SUCCESS:
        return {
          ...state,
          waitingForDoTheBudgetTrend: false,
          doTheBudgetTrend: action.data,
        };
      case constants.GET_DO_THE_PLAN_TREND_REQUEST:
        return {
          ...state,
          waitingForDoThePlanTrend: true,
        };
      case constants.GET_DO_THE_PLAN_TREND_SUCCESS:
        return {
          ...state,
          waitingForDoThePlanTrend: false,
          doThePlanTrend: action.data,
        };
      case constants.GET_KEEP_THE_LIGHTS_OFF_TREND_REQUEST:
        return {
          ...state,
          waitingKeepTheLightsOffTrend: true,
        };
      case constants.GET_KEEP_THE_LIGHTS_OFF_TREND_SUCCESS:
        return {
          ...state,
          waitingKeepTheLightsOffTrend: false,
          keepTheLightsOffTrend: action.data,
        };
      case constants.GET_CHARGEABILITY_TREND_REQUEST:
        return {
          ...state,
          waitingForChargeabilityTrend: true,
        };
      case constants.GET_CHARGEABILITY_TREND_SUCCESS:
        return {
          ...state,
          waitingForChargeabilityTrend: false,
          chargeabilityTrend: action.data,
        };
      case constants.GET_WORKING_CONNECTION_TREND_REQUEST:
        return {
          ...state,
          waitingForWorkingConnectionTrend: true,
        };
      case constants.GET_WORKING_CONNECTION_TREND_SUCCESS:
        return {
          ...state,
          waitingForWorkingConnectionTrend: false,
          workingConnectionTrend: action.data,
        };
      case constants.UPDATE_PROFILE_PIC_REQUEST:
        return {
          ...state,
          waiting: true,
        };
      case constants.UPDATE_PROFILE_PIC_SUCCESS:
        return {
          ...state,
          waiting: false,
          modalToShow: null,
          pic: action.pic,
        };
      case constants.DELETE_PROFILE_PIC_REQUEST:
        return {
          ...state,
          pic: null,
        };
      case constants.CHOOSE_QUARTERS_FILTER:
        return {
          ...state,
          quartersFilter: action.filter.quarterId,
        };
      case constants.CHOOSE_TRENDS_FILTER:
        return {
          ...state,
          trendsFilter: action.filter,
        };
      case constants.GET_KUDOS_REQUEST:
        return {
          ...state,
          waitingForKudos: true,
        };
      case constants.GET_KUDOS_SUCCESS:
        return {
          ...state,
          waitingForKudos: false,
          kudos: action.data,
        };
      case constants.GET_KUDOS_FAILURE:
        return {
          ...state,
          waitingForKudos: false,
          kudos: {
            ...state.kudos,
            year: action.year,
            as_pm: null,
            as_team_member: null,
            people_say: [],
          },
        };
      case constants.GET_GENDER_REQUEST:
        return {
          ...state,
          waitingForGender: true,
        };
      case constants.GET_GENDER_SUCCESS:
        return {
          ...state,
          waitingForGender: false,
          genders: action.data,
        };
      case constants.DELETE_KEY_RESULT_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.ADD_KEY_RESULT_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case constants.ADD_KEY_RESULT_SUCCESS:
        return {
          ...state,
          creating: false,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                return {
                  ...quarter,
                  objectives: quarter.objectives.map((objective) => {
                    if (objective.id === action.objectiveId) {
                      return {
                        ...objective,
                        key_results: objective.key_results.concat(action.keyResult),
                      };
                    }
                    return objective;
                  }),
                };
              }
              return quarter;
            }),
          },
        };
      case constants.EDIT_OBJECTIVE_REQUEST:
        return {
          ...state,
          waiting: true,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                return {
                  ...quarter,
                  objectives: quarter.objectives.map((objective) => {
                    if (objective.id === action.objective.id) {
                      return {
                        ...objective,
                        name: action.objective.name,
                      };
                    }
                    return objective;
                  }),
                };
              }
              return quarter;
            }),
          },
        };
      case constants.EDIT_OBJECTIVE_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.DELETE_OBJECTIVE_REQUEST:
        return {
          ...state,
          waiting: true,
          focusedObjective: null,
          modalToShow: null,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                const objectives = quarter.objectives
                  .filter((objective) => objective.id !== action.objective.id);
                return {
                  ...quarter,
                  objectives,
                  quarter_status: objectives.length > 0 ? quarter.quarter_status : null,

                };
              }
              return quarter;
            }),
          },
        };
      case constants.DELETE_OBJECTIVE_SUCCESS:
        return {
          ...state,
          waiting: false,
        };
      case constants.ADD_OBJECTIVE_REQUEST:
        return {
          ...state,
          modalToShow: 'objective',
          focusedObjective: { quarterId: action.quarterId },
          creating: true,
        };
      case constants.ADD_OBJECTIVE_SUCCESS:
        return {
          ...state,
          creating: false,
          focusedObjective: {
            id: action.objective.id,
            quarterId: action.quarterId,
          },
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (quarter.quarter === action.quarterId) {
                return {
                  ...quarter,
                  objectives: quarter.objectives.concat(action.objective),
                  quarter_status: 0,
                };
              }
              return quarter;
            }),
          },
        };
      case constants.GET_OBJECTIVES_REQUEST:
        return {
          ...state,
          waitingForObjectives: true,
        };
      case constants.GET_OBJECTIVES_SUCCESS:
        return {
          ...state,
          waitingForObjectives: false,
          objectivesByQuarter: {
            ...action.objectives,
            quarters: action.objectives.quarters.map((quarter) => ({
              ...quarter,
              objectives: quarter.objectives.map((objective) => ({
                ...objective,
                quarter_status: quarter.quarter_status,
              })),
            })),
          },
        };
      case constants.UPDATE_QUARTER_STATUS_REQUEST:
        return {
          ...state,
          waiting: true,
          objectivesByQuarter: {
            ...state.objectivesByQuarter,
            quarters: state.objectivesByQuarter.quarters.map((quarter) => {
              if (state.objectivesByQuarter.year === action.year
                && quarter.quarter === action.quarter) {
                return {
                  ...quarter,
                  quarter_status: action.status,
                  objectives: quarter.objectives.map((objective) => ({
                    ...objective,
                    quarter_status: action.status,
                  })),
                };
              }
              return quarter;
            }),
          },
        };
      default:
        return state;
    }
  }
}

module.exports = ProfileReducer;
