/* eslint-disable react/sort-comp,react/no-access-state-in-setstate */
const React = require('react');
const Widget = require('./Widget.react');
const Empty = require('./NotesEmpty.react');

module.exports = class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
    };
  }

  isDisabledClick() {
    return this.props.notes.length <= 1;
  }

  handleNextClick() {
    if (!this.isDisabledClick()) {
      const index = this.state.currentIndex === this.props.notes.length - 1
        ? 0 : this.state.currentIndex + 1;
      this.setState({ currentIndex: index });
    }
  }

  handlePrevClick() {
    if (!this.isDisabledClick()) {
      const index = this.state.currentIndex === 0
        ? this.props.notes.length - 1 : this.state.currentIndex - 1;
      this.setState({ currentIndex: index });
    }
  }

  getButtonStyle() {
    let style = 'wethod-icon-button profile-kudos__widget-notes-action-button';
    if (this.isDisabledClick()) {
      style += ' disabled';
    }
    return style;
  }

  getActions() {
    return (
      <span>
        <button type="button"
          className={this.getButtonStyle()}
          onClick={this.handlePrevClick.bind(this)}>
          <div className="wethod-icon wethod-icon-back wethod-icon-back--black" />
        </button>
        <button type="button"
          className={this.getButtonStyle()}
          onClick={this.handleNextClick.bind(this)}>
          <div className="wethod-icon wethod-icon-forward wethod-icon-forward--black" />
        </button>
      </span>
    );
  }

  getBody() {
    if (!this.props.notes.length) {
      return <Empty />;
    }
    return (
      <span className="profile-kudos__widget-notes-content">
        <div className="profile-kudos__widget-notes-body">
          “
          {this.props.notes[this.state.currentIndex]}
          ”
        </div>
        <div className="profile-kudos__widget-notes-footer">
          {this.state.currentIndex + 1}
          {' '}
          of
          {this.props.notes.length}
        </div>
      </span>
    );
  }

  render() {
    return (
      <Widget
        title="What people say"
        actions={this.getActions()}>
        {this.getBody()}
      </Widget>
    );
  }
};
