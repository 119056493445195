const { connect } = require('react-redux');
const constants = require('../constants');
const Component = require('../components/SidebarController.react');

const mapStateToProps = (state) => ({
  showSidebar: state.sidebarToShow === constants.TYPE_OPPORTUNITIES
    || state.sidebarToShow === constants.TYPE_PROJECTS,
  item: state.focusedItem,
});

module.exports = connect(mapStateToProps, null)(Component);
