const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const IconLinkTooltip = require('../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');

const OutstandingProject = ({ item }) => {
  const getPM = (pm) => {
    if (pm) {
      return `${pm.name} ${pm.surname}`;
    }
    return '-';
  };

  function getNonEmpty(value) {
    return value || '-';
  }

  function getFormattedDate(date) {
    return date ? moment(date).format('MMM YYYY') : '-';
  }

  return (
    <TableRow>
      <TableCell className="data-freezing-engine__table-outstanding-projects-name">{item.project.name}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-medium">{getNonEmpty(item.project.id_job_order)}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-medium">{getNonEmpty(item.client?.corporate_name)}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-medium">{getPM(item.pm)}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-date-start">{getFormattedDate(item.project.date_start)}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-small">{getNonEmpty(item.project.duration)}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-small">{`${item.project.probability}%`}</TableCell>
      <TableCell className="data-freezing-engine__table-outstanding-projects-column-small">
        <IconLinkTooltip label="Pipeline" borderless href={`#pipeline/projects?id=${item.project.id}`}>
          <Icon icon="pipeline" />
        </IconLinkTooltip>
      </TableCell>
    </TableRow>
  );
};

module.exports = OutstandingProject;
