const React = require('react');
const PropTypes = require('prop-types');
const Cleave = require('cleave.js/react');
const EventService = require('../../../../services/EventService');

class DateField extends React.Component {
  static isEmpty(value) {
    return !value || value.trim() === '';
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInit = this.onInit.bind(this);

    this.cleaveOptions = {
      date: true,
      datePattern: props.datePattern,
      delimiter: props.delimiter,
    };
  }

  componentDidMount() {
    this.cleave.setRawValue(this.getValue());
    this.validate(this.getValue());
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.getValue() !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onParentBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onBlur(e) {
    const event = this.formatEvent(e);

    this.onParentBlur(event);
  }

  onChange(e) {
    const event = this.formatEvent(e);

    this.validate(event.target.value);
    if (event.target.value !== this.getValue()) {
      this.onParentChange(event);
    }
  }

  onInit(cleave) {
    this.cleave = cleave;
  }

  /**
   * Return input value.
   * @return {string|*}
   */
  getValue() {
    if (DateField.isEmpty(this.props.value)) {
      return null;
    }
    return this.props.value;
  }

  getClassName() {
    let name = 'wethod-input-root';
    if (this.props.className) {
      name += ` ${this.props.className}`;
    }
    return name;
  }

  /**
   * Transforms and return the given event to be compliant with a classic HTML field.
   * This is needed because Cleave.js is basically a text input: its value is the one you see
   * formatted, to access the value you would expect from date input you must use
   * event.target.value.
   * @param e
   */
  formatEvent(e) {
    return EventService.cloneEvent(e, {
      name: this.props.name,
      value: e.target.value,
    });
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.cleave.setRawValue(this.getValue());
    this.validate(this.getValue());
  }

  render() {
    return (
      <Cleave
        id={this.props.id}
        className={this.getClassName()}
        readOnly={this.props.readOnly}
        required={this.props.required}
        disabled={this.props.disabled}
        aria-label={this.props.label}
        name={this.props.name}
        options={this.cleaveOptions}
        placeholder={this.props.placeholder}
        onInit={this.onInit}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.props.onFocus} />
    );
  }
}

DateField.defaultProps = {
  value: '',
  datePattern: ['d', 'm', 'Y'],
  delimiter: '/',
  id: undefined,
  className: undefined,
  placeholder: undefined,
  readOnly: undefined,
  disabled: undefined,
  required: undefined,
  name: undefined,
  onFocus: undefined,
  validate: undefined,
  onBlur: undefined,
  onChange: null,
  label: '',
  resetErrors: null,
};

DateField.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  /** Amount of decimal digits allowed. * */
  datePattern: PropTypes.arrayOf(PropTypes.oneOf(['d', 'm', 'y', 'Y'])),
  /**
   * Delimiter shown for thousands.
   * @see Cleave
   */
  delimiter: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @param value {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  onBlur: PropTypes.func,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
};

module.exports = DateField;
