'use strict';

/* eslint-disable max-len */

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.AddWidgetItemView = Marionette.ItemView.extend({
    className: 'wrapAddWidget',
    template: '#dashboardAddWidgetTemplate',
    ui: {
      settingsRegionEl: '[data-region="widgetSettings"]',
      tooltipEl: '[data-region="widgetTooltip"]',
      showWidgetTooltipEl: '[data-action="showWidgetTooltip"]',
      selectedWidgetEl: '[data-action="selectFilter"]',
      settingsEl: '[data-action="addWidget"]',
      cancelEl: '[data-action="cancelAddWidget"]',
      newsContainerEl: '[data-region="newsContaier"]',
      newsCountEl: '[data-region="countNews"]',
    },
    events: {
      'click @ui.settingsEl': 'showSettings',
      'touchstart @ui.settingsEl': 'showSettings',
      'click @ui.cancelEl': 'hideSettings',
      'touchstart @ui.cancelEl': 'hideSettings',
      'click @ui.showWidgetTooltipEl': 'showWidgetTooltip',
      'touchstart @ui.showWidgetTooltipEl': 'showWidgetTooltip',
      'click @ui.selectedWidgetEl': 'selectFilter',
      'touchstart @ui.selectedWidgetEl': 'selectFilter',
    },
    initialize: function (options) {
      this.options = options;
    },

    onRender: function () {
      var that = this;
      if (that.options.countNews > 0) {
        this.ui.newsCountEl.html(that.options.countNews);
      } else {
        this.ui.newsContainerEl.hide();
      }
    },

    showSettings: function (e) {
      e.preventDefault();
      this.ui.settingsRegionEl.addClass('isVisible');
    },

    showWidgetTooltip: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.ui.tooltipEl.addClass('isVisible');

      // Hide select when click on 'html'
      Wethod.onHtmlClick(this.ui.tooltipEl.selector, 'isVisible', 'remove');
    },

    selectFilter: function (e) {
      e.preventDefault();
      if (!this.ui.settingsEl.hasClass('loading')) {
        this.ui.settingsRegionEl.removeClass('isVisible');
        this.ui.settingsEl.addClass('loading');

        DashboardApp.insertWidget(e.currentTarget.dataset.value, this.options.widgetView, this.options.widgetView.ui.widgetRegion);
      }
    },

    hideSettings: function (e) {
      e.preventDefault();
      this.ui.settingsRegionEl.removeClass('isVisible');
    },
  });

  var dashboardGrid;

  // show item order after layout
  var orderItems = function () {
    var itemSort = [];
    var itemElems = dashboardGrid.packery('getItemElements');
    $(itemElems).each(function (i, itemElem) {
      if (parseInt($(itemElem).data('item')) !== 0) {
        itemSort.push({
          id: parseInt($(itemElem).data('item')),
          sort: i,
        });
      }
    }).promise().done(function () {
      DashboardApp.orderDashboard(itemSort);
    });
  };

  this.WidgetLoadingItemView = Marionette.ItemView.extend({
    className: 'widget',
    template: '#dashboardPackeryTemplate',
    reorderOnSort: false,
    attributes: function () {
      return { 'data-item': this.model.get('id') || 0 };
    },

    ui: {
      widgetRegion: '[data-region="widgetTemplate"]',
    },
    initialize: function (options) {
      this.options = options;
    },

    onRender: function () {
      DashboardApp.renderWidget(this, this.ui.widgetRegion);
    },

    onBeforeDestroy: function () {
      dashboardGrid.packery('remove', this.el);// .packery('shiftLayout');
      dashboardGrid.packery();
      orderItems();
    },
  });
  this.WidgetsCollectionView = Marionette.CollectionView.extend({
    className: 'widgetWrap padding_tb',
    childView: DashboardApp.WidgetLoadingItemView,
    _alreadyShown: false,
    initialize: function (options) {
      this.options = options;
    },

    onShow: function () {
      if ($(window).width() > 600) {
        this._alreadyShown = true;
        // dashboardGrid
        dashboardGrid = $('.widgetWrap').packery({
          itemSelector: '.widget',
          columnWidth: '.widget',
          rowHeight: '.widget',
          percentPosition: true,
          // initLayout: false
        });

        dashboardGrid.on('dragItemPositioned', orderItems);
        // make all items draggable
        this.$el.find('.widget').each(function (i, gridItem) {
          var draggie = new Draggabilly(gridItem);
          // bind drag events to Packery
          dashboardGrid.packery('bindDraggabillyEvents', draggie);
        });
      }
    },

    addChild: function () {
      if (!this._alreadyShown) {
        Marionette.CollectionView.prototype.addChild.apply(this, arguments);
      } else {
        var view = Marionette.CollectionView.prototype.addChild.apply(this, arguments);
        dashboardGrid.packery('appended', view.$el).packery('layout');
        var gridItem = view.$el[0];
        var draggie = new Draggabilly(gridItem);
        dashboardGrid.packery('bindDraggabillyEvents', draggie);
      }
    },
  });

  // SEARCH HINT
  this.WidgetSearchLoadingTemplateView = Marionette.ItemView.extend({
    className: 'searchFeedback',
    template: '#widgetSearchLoadingTemplate',
  });
  this.WidgetSearchNotFoundTemplateView = Marionette.ItemView.extend({
    className: 'searchFeedback',
    template: '#widgetSearchNotFoundTemplateView',
  });
  this.WidgetSalesFunnelHintView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#widgetSalesFunnelHintTemplate',
    ui: {
      hintEL: '[data-action="selectHint"]',
    },
    events: {
      'click @ui.hintEL': 'selectHint',
      'touchstart @ui.hintEL': 'selectHint',
    },
    initialize: function (options) {
      this.options = options;
    },

    selectHint: function (e) {
      e.preventDefault();
      // aggiorno l'input con l'hint inserito dall'utente
      this.options.parentView.ui.inputHintEl.val(this.model.get('label'));
      // aggiorno le options del padre
      this.options.parentView.options.filterValue = this.model.get('id');
      this.options.parentView.options.filterLabel = this.model.get('label');
      // aggiorno lo stato del mdl
      this.options.parentView.ui.wrapInputHintEl.find('[data-region="mdl-input"]')
        .addClass('is-dirty');
      // mostro il bottone di salvataggio
      this.options.parentView.ui.saveEl.show();
    },
  });
  this.WidgetSalesFunnelHintsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'list',
    childView: DashboardApp.WidgetSalesFunnelHintView,
  });
});
