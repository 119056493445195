const { connect } = require('react-redux');
const Component = require('../../../components/Footer/DefaultFooterContent/DefaultFooterContent.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  selectedVersion: state.targetVersion,
});

const mapDispatchToProps = (dispatch) => ({
  showModalBudgetSubmit: () => dispatch(actions.showModalBudgetSubmit()),
  showModalBudgetApproval: () => dispatch(actions.showModalBudgetApproval()),
  showModalBudgetDraft: () => dispatch(actions.showModalBudgetDraft()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
