const React = require('react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const getLastThursday = require('../../../../../../services/DateService').getLastThursdayOfMonth;

module.exports = class InOutTrend extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      in: '#4ED88D',
      out: '#DB4D69',
    };

    this.timeFilter = TimeFilterFactory(this.props.timeFilter);

    this.todayBreakpointIndex = this.timeFilter.getBreakpointIndexForDate(moment().format());

    this.options = {
      chart: {
        type: 'column',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
        spacingBottom: 0,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            color: '#a5a5a5',
          },
        },
        gridLineWidth: 0,
      },
      xAxis: {
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          pointWidth: 5,
          borderWidth: 0,
          borderRadius: 4,
        },
      },
    };
  }

  componentDidMount() {
    this.renderChart();
  }

  getChartOptions() {
    const categories = this.getCategories();
    return {
      ...this.options,
      series: this.getSeries(),
      xAxis: {
        ...this.options.xAxis,
        categories,
        max: categories.length - 1,
        min: 0,
      },
    };
  }

  getCategories() {
    return this.timeFilter
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries() {
    const { peopleIn, peopleOut } = this.props;
    const peopleInSeries = this.getPeopleInSeries(peopleIn);
    const peopleOutSeries = this.getPeopleOutSeries(peopleOut);

    return [peopleInSeries, peopleOutSeries];
  }

  getPeopleInSeries(series) {
    const data = series.map((period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      let className;
      if (index >= this.todayBreakpointIndex) {
        className = 'demographics__chart--disabled'; // Set opacity to column of current period (and future ones)
      }
      return {
        ...period,
        className,
        x: index,
        y: period.value,
      };
    });

    return {
      name: 'In',
      data,
      color: this.colors.in,
    };
  }

  getPeopleOutSeries(series) {
    const data = series.map((period) => {
      // Prevent date to be wrongly placed in following quarter
      const date = getLastThursday(period.date);
      // Breakpoint corresponding the date of the point
      const index = this.timeFilter.getBreakpointIndexForDate(date);
      let className;
      if (index >= this.todayBreakpointIndex) {
        className = 'demographics__chart--disabled'; // Set opacity to column of current period (and future ones)
      }
      return {
        ...period,
        className,
        x: index,
        y: period.value,
      };
    });

    return {
      name: 'Out',
      data,
      color: this.colors.out,
    };
  }

  getPeopleOutTotal() {
    const { peopleOut } = this.props;
    return peopleOut.reduce((sum, quarter) => sum + quarter.value, 0);
  }

  getPeopleInTotal() {
    const { peopleIn } = this.props;
    return peopleIn.reduce((sum, quarter) => sum + quarter.value, 0);
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  render() {
    return (
      <div className="demographics__chart--in-out demographics__chart">
        <div>
          <div className="demographics__total demographics__total--in">{this.getPeopleInTotal()}</div>
          <div className="demographics__total demographics__total--out">{this.getPeopleOutTotal()}</div>
        </div>
        <div ref={(chartContainer) => this.chartContainer = chartContainer} />
      </div>
    );
  }
};
