const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../FilterAccordion/FilterAccordion.react');
const TextField = require('../../../inputs/TextField/OutlinedTextField/OutlinedTextField.react');

const TextFilter = ({ name, value, onChange, expanded, label, placeholder, className }) => {
  function handleChange(e) {
    onChange(e.target.name, e.target.value);
  }

  return (
    <Accordion expanded={expanded} label={label} value={value} className={className}>
      <TextField onChange={handleChange}
        value={value}
        name={name}
        placeholder={placeholder} />
    </Accordion>
  );
};

TextFilter.defaultProps = {
  expanded: false,
  label: '',
  placeholder: '',
  className: '',
  value: '',
  onChange: null,
};

TextFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

module.exports = TextFilter;
