/* eslint-disable react/no-access-state-in-setstate,consistent-return,react/no-array-index-key,react/jsx-no-bind */
const React = require('react');
const TextInput = require('../../../../../../../common/react/material-input/SidebarTextInput.react');
const TextArea = require('../../../../../../../common/react/material-input/SidebarTextArea.react');
const Date = require('../DateController.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const DestinationSelect = require('../../../containers/sidebar/FavoriteCitySelect');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');

module.exports = class CarEditableDetails extends React.Component {
  handlePersonUpdate(type, index, field, value) {
    let list = this.props.travel.employees || [];
    const oldPerson = list[index];
    const updatedPerson = { ...oldPerson, [field]: value };
    list = [updatedPerson];

    this.props.onChange(type, list);
  }

  getEmployeesInfo() {
    if (this.props.travel.employees) {
      return this.props.travel.employees.map((employee, index) => (
        <div key={index}>
          <TextInput name="name"
            placeholder="Name"
            value={employee.name}
            onChange={(name, value) => this.handlePersonUpdate('employees', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span className="wethod-icon wethod-icon-pm" />
          </TextInput>
          <TextInput name="surname"
            placeholder="Surname"
            value={employee.surname}
            onChange={(name, value) => this.handlePersonUpdate('employees', index, name, value)}
            onValidate={this.props.onValidate}
            required>
            <span />
          </TextInput>
        </div>
      ));
    }
  }

  render() {
    return (
      <div>
        <DestinationSelect name="from"
          placeholder="From"
          destination={this.props.travel.from}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </DestinationSelect>
        <DestinationSelect name="to"
          placeholder="To"
          destination={this.props.travel.to}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span />
        </DestinationSelect>
        <Date name="date"
          editable
          isRoundTrip
          metadataService={this.props.metadataService}
          date={this.props.travel.date}
          return_date={this.props.travel.return_date}
          onChange={this.props.onChange} />
        {this.getEmployeesInfo()}
        <TextArea name="additional_requests"
          placeholder="Additional requests"
          value={this.props.travel.additional_requests}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </TextArea>
        <TravelOfficeSection travel={this.props.travel}
          editMode
          metadataService={this.props.metadataService}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <FileSection editMode carrier={this.props.travel} />
      </div>
    );
  }
};
