const React = require('react');
const CorrectionModal = require('./ProjectStatusCorrectionModal.react');

const MODAL_CORRECTION = 'modal-correction';

/**
 * This is a wrapper that allows displaying different modals related to project status correction
 * based on a condition.
 * @type {ProjectStatusCorrectionModalManager}
 */
module.exports = class ProjectStatusCorrectionModalManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalToShow: MODAL_CORRECTION,
    };

    this.showCorrectionModal = this.showCorrectionModal.bind(this);
  }

  showCorrectionModal() {
    this.setState({ modalToShow: MODAL_CORRECTION });
  }

  render() {
    switch (this.state.modalToShow) {
      default:
        return (
          <CorrectionModal correctionToApply={this.props.correctionToApply}
            closeModal={this.props.closeModal} />
        );
    }
  }
};
