const { connect } = require('react-redux');
const Component = require('../components/Travel.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  showSidebar: !!state.focusedTravel,
});

const mapDispatchToProps = (dispatch) => ({
  getTravels: () => dispatch(actions.filterTravels()),
  getTravelOffices: () => dispatch(actions.geTravelOffices()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
