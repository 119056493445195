const { connect } = require('react-redux');
const Companies = require('../components/Companies.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  loadedItems: state.loadedItems,
  totalItems: state.filteredItems.length,
});

const mapDispatchToProps = (dispatch) => ({
  orderClients: (col, order) => dispatch(actions.orderClients(col, order)),
  loadClients: (size, page) => dispatch(actions.loadMoreClients(size, page)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Companies);
