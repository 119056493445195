const constants = require('./constants');
const Model = require('./models/BriefingModel');
const DateService = require('../../../../../../services/DateService');

const getBriefingFailure = (error) => ({
  type: constants.GET_BRIEFING_FAILURE,
  error,
});

const getBriefingSuccess = (briefing, cachedOn) => ({
  type: constants.GET_BRIEFING_SUCCESS,
  briefing,
  cachedOn,
});

const getBriefingRequest = (date) => ({
  type: constants.GET_BRIEFING_REQUEST,
  date,
});

const getBriefing = (date, useCache = true) => (dispatch) => {
  dispatch(getBriefingRequest(date));
  const currentMonth = DateService.getMonth(moment());
  const currentDate = moment().month(currentMonth - 1);
  const isFuture = date.isAfter(currentDate);
  if (isFuture) {
    dispatch(getBriefingFailure('future-not-available'));
  } else {
    const model = new Model({ date: date.format('YYYY-MM') });
    $.when(model.getModel(useCache))
      .done((response) => {
        if (response.code === 404) {
          dispatch(getBriefingFailure('past-not-available'));
        } else {
          dispatch(getBriefingSuccess(response.data, response.cached_on));
        }
      });
  }
};

module.exports.updateTimeToRead = (time) => ({
  type: constants.UPDATE_TIME_TO_READ,
  time,
});

module.exports.getBriefing = getBriefing;
