const React = require('react');
const PropTypes = require('prop-types');

class VirtualListItems extends React.Component {
  getStyle() {
    return {
      top: this.props.offset,
    };
  }

  render() {
    return (
      <div className="wethod-virtual-list__items"
        style={this.getStyle()}
        ref={this.props.setRef}>{this.props.children}
      </div>
    );
  }
}

VirtualListItems.defaultProps = {
  children: null,
};

VirtualListItems.propTypes = {
  /**
   * Distance of this list first element from the top of the Content, in pixels.
   */
  offset: PropTypes.number.isRequired,
  /**
   * Nodes to use as list items.
   */
  children: PropTypes.node,
  /**
   * Function on render to get this HTMLElement.
   *
   * @param {ref} HTMLElemnt representing this component root.
   */
  setRef: PropTypes.func.isRequired,
};

module.exports = VirtualListItems;
