'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ClientsStore = require('../../../apps/core/modules/finance/clients/overview/store');
var ClientsReducer = require('../../../apps/core/modules/finance/clients/overview/reducer');
var Clients = require('../../../apps/core/modules/finance/clients/overview/index');
var ClientCompanyStore = require('../../../apps/core/modules/finance/clients/detail/company/store');
var ClientCompanyReducer = require('../../../apps/core/modules/finance/clients/detail/company/reducer');
var ClientCompany = require('../../../apps/core/modules/finance/clients/detail/company/index');
var ClientPersonStore = require('../../../apps/core/modules/finance/clients/detail/person/store');
var ClientPersonReducer = require('../../../apps/core/modules/finance/clients/detail/person/reducer');
var ClientPerson = require('../../../apps/core/modules/finance/clients/detail/person/index');

Wethod.module('FinanceApp.Client', function (Client, Wethod, Backbone, Marionette, $, _) {
  // richiesta spostamento record da un client ad un altro
  dispatcher.on('finance:client:moveRecords', function (options) {
    var view = options.view;
    var model = options.model;
    // pulisco options dai campi che non servono per la request
    delete options.view;
    delete options.model;

    view.ui.feedbackField.text('Loading...').addClass('messageModal__feedback--success');

    // chiamo l'API per lo switch dei dati
    var switchRequest = Wethod.request('switch:finance:client:recors', options);
    $.when(switchRequest).done(function (switchResponse) {
      if (!_.isUndefined(switchResponse)) {
        // triggero l'evento per la cancellazione di un modello
        dispatcher.trigger('finance:client:deleteClient', model, view);
      } else {
        view.destroy();
      }
    });
  });

  // TODO da rimuovere dopo aver inserito la ricerca con il nuovo componente
  // richiesta autocompletamento sui clienti
  dispatcher.on('finance:client:serchClient', function (search, view, model) {
    var reg = new RegExp(' ', 'g');
    search = search.replace(reg, ',');

    var params = { like: search };

    // loading
    var searchFeedbackTemplate = new Client.AutocompleteLoadingTemplateView();
    view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);

    var autocompleteRequest = Wethod.request('autocomplete:finance:client', params, model.get('id'));
    $.when(autocompleteRequest).done(function (autocompleteResponse) {
      if (!_.isUndefined(autocompleteResponse)) {
        // richiesta cancellata
        if (autocompleteResponse === 'aborted') {
          // loading
          searchFeedbackTemplate = new Client.AutocompleteLoadingTemplateView();
          view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
        } else if (_.isEmpty(autocompleteResponse)) {
          // not found
          searchFeedbackTemplate = new Client.AutocompleteEmptyRowItemView();
          view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
        } else {
          // show
          var autocompleteTemplate = new Client.AutocompleteRowsCollectionView({
            collection: autocompleteResponse,
          });
          view.ui.hintsRegion.html(autocompleteTemplate.render().$el);
        }
      } else {
        // not found
        searchFeedbackTemplate = new Client.AutocompleteEmptyRowItemView();
        view.ui.hintsRegion.html(searchFeedbackTemplate.render().$el);
      }
    });
  });

  /**
   * [funzione che inizializza il modulo recupera i dati dalle API e mostra il contenuto]
   * @param  {[type]} financeLayout [description]
   * @param search companies|people
   * @return {[type]}               [description]
   */
  this.InitClient = function (financeLayout, search) {
    var clientsRequest = Wethod.request('get:finance:clients');
    var groupRequest = Wethod.request('company:get:group');
    var bankAccountRequest = Wethod.request('get:company:bank-account', false); // Do not include archived
    var vatRateRequest = Wethod.request('get:company:vat-rate', false); // Do not include archived
    var countriesRequest = Wethod.request('get:countries');
    var provincesRequest = Wethod.request('get:provinces');
    var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
    $.when(clientsRequest, groupRequest, bankAccountRequest,
      vatRateRequest, countriesRequest, provincesRequest, paymentTermsRequest)
      .done(function (clientsResponse, groupResponse, bankAccountResponse, vatRateResponse,
        countriesResponse, provincesResponse, paymentTermsResponse) {
        var availableGroupCompanies = groupResponse.models ? groupResponse.toJSON() : [];
        var bankAccounts = bankAccountResponse.models ? bankAccountResponse.toJSON() : [];
        var vatRates = vatRateResponse.models ? vatRateResponse.toJSON() : [];
        var countries = countriesResponse || [];
        var provinces = provincesResponse || [];
        var paymentTerms = paymentTermsResponse.models ? paymentTermsResponse.toJSON() : [];

        // renderizzo il template del modulo
        financeLayout.render();

        var reducerWrapper = new ClientsReducer({
          type: search,
          people: clientsResponse.attributes.customers,
          companies: clientsResponse.attributes.clients,
          groups: clientsResponse.attributes.groups,
          tags: clientsResponse.attributes.tags,
          bankAccounts: bankAccounts,
          vatRates: vatRates,
          countries: countries,
          provinces: provinces,
          paymentTerms: paymentTerms,
          availableGroupCompanies: availableGroupCompanies, // All the group's companies
          hasIntercompanyGroup: Wethod.userInfo.get('group') !== null && availableGroupCompanies.length,
        });

        var store = ClientsStore(reducerWrapper.reduxReducer);
        var element = React.createElement(Clients, { store: store });
        var container = document.getElementById('financeRoot');
        if (container !== null) ReactDOM.render(element, container);
      });
  };

  this.InitPersonClient = function (financeLayout, id) {
    var clientRequest = Wethod.request('get:finance:client:person', id);
    var companiesRequest = Wethod.request('get:finance:companies');
    $.when(clientRequest, companiesRequest).done(function (clientResponse, companiesResponse) {
      // renderizzo il template del modulo
      financeLayout.render();

      var reducerWrapper = new ClientPersonReducer({
        person: clientResponse.get('person'),
        phones: clientResponse.get('phones'),
        companies: companiesResponse.toJSON(),
        knownTags: clientResponse.get('tags'),
        resources: clientResponse.get('resources'),
        projects: clientResponse.get('projects'),
        business_units: clientResponse.get('business_units'),
      });

      var store = ClientPersonStore(reducerWrapper.reduxReducer);
      var element = React.createElement(ClientPerson, { store: store });
      var container = document.getElementById('financeRoot');
      if (container !== null) ReactDOM.render(element, container);
    });
  };

  this.InitCompanyClient = function (financeLayout, id) {
    var clientRequest = Wethod.request('get:finance:client:company', id);
    var revenuesAuthorizationRequest = Wethod.request('get:finance:authorization', { app: 'income_statement' });
    var groupRequest = Wethod.request('company:get:group');
    var bankAccountRequest = Wethod.request('get:company:bank-account', false); // Do not include archived
    var vatRateRequest = Wethod.request('get:company:vat-rate', false); // Do not include archived
    var countriesRequest = Wethod.request('get:countries');
    var provincesRequest = Wethod.request('get:provinces');
    var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
    var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');

    $.when(clientRequest, revenuesAuthorizationRequest, groupRequest,
      bankAccountRequest, vatRateRequest, countriesRequest, provincesRequest,
      paymentTermsRequest, isValueAsUnitRequest)
      .done(function (clientResponse, revenuesAuthorizationResponse, groupResponse,
        bankAccountResponse, vatRateResponse, countriesResponse, provincesResponse,
        paymentTermsResponse, isValueAsUnitResponse) {
        var availableGroupCompanies = groupResponse.models ? groupResponse.toJSON() : [];
        var bankAccounts = bankAccountResponse.models ? bankAccountResponse.toJSON() : [];
        var vatRates = vatRateResponse.models ? vatRateResponse.toJSON() : [];
        var countries = countriesResponse || [];
        var provinces = provincesResponse || [];
        var paymentTerms = paymentTermsResponse.models ? paymentTermsResponse.toJSON() : [];
        var isValueAsUnit = isValueAsUnitResponse ? isValueAsUnitResponse.get('enabled') : null;

        // renderizzo il template del modulo
        financeLayout.render();

        var state = clientResponse.attributes;
        state.revenues_permissions = revenuesAuthorizationResponse.get('revenues');
        state.availableGroupCompanies = availableGroupCompanies; // All the group's companies
        state.hasIntercompanyGroup = Wethod.userInfo.get('group') !== null && availableGroupCompanies.length;
        state.bankAccounts = bankAccounts;
        state.vatRates = vatRates;
        state.countries = countries;
        state.provinces = provinces;
        state.paymentTerms = paymentTerms;
        state.isValueAsUnit = isValueAsUnit;

        var reducerWrapper = new ClientCompanyReducer(state);

        var store = ClientCompanyStore(reducerWrapper.reduxReducer);
        var element = React.createElement(ClientCompany, { store: store });
        var container = document.getElementById('financeRoot');
        if (container !== null) ReactDOM.render(element, container);
      });
  };
});
