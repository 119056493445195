const { connect } = require('react-redux');
const Component = require('../../components/Item/Item.react');

const mapStateToProps = (state) => ({
  isContactRequired: state.isContactRequired,
  itemsInSaving: state.waitingFor
    .filter((key) => key.indexOf('save-item') !== -1)
    .map((message) => {
      const stringId = message.match(/save-item-(\d+)/)[1];
      return parseInt(stringId);
    }),
  isPipedriveIntegrationEnabled: state.isPipedriveIntegrationEnabled,
});

module.exports = connect(mapStateToProps)(Component);
