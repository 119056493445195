const { connect } = require('react-redux');
const Component = require('../components/TimesheetLogTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.some((key) => key === 'get-timesheet-logs'),
  timesheetLogs: state.timesheetLogs,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  hasMorePages: state.hasMorePages,
  search: state.search,
  sort: state.sort,
  order: state.order,
  filters: state.filters,
});

const mapDispatchToProps = (dispatch) => ({
  getTimesheetLogs: (offset, limit, search, order, sort, filters) => dispatch(
    actions.getTimesheetLogs(offset, limit, search, order, sort, filters),
  ),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
