/* eslint-disable react/prefer-stateless-function,react/no-array-index-key */
const React = require('react');
const ProjectWeek = require('./ProjectWeek.react');
const Polyfill = require('../../../../../../services/Polyfill');

class ProjectWeeks extends React.Component {
  render() {
    let weeksStyle = {};
    if (this.props.visibility === false) {
      weeksStyle = { visibility: 'hidden' };
    }

    const daysArray = Polyfill.values(this.props.days);
    const weeksArray = [];
    for (let i = 0; i < daysArray.length; i += 5) {
      const week = [];
      for (let j = -1; j < 6; j++) {
        week.push(daysArray[i + j]);
      }
      weeksArray.push(week);
    }
    const weeks = weeksArray.map((week, index) => (
      <ProjectWeek
        projectId={this.props.projectId}
        days={week}
        key={index}
        eventTypes={this.props.eventTypes}
        eventAdded={this.props.eventAdded}
        eventDeleted={this.props.eventDeleted} />
    ));
    return (
      <div style={weeksStyle} className="employee__weeks">
        {weeks}
      </div>
    );
  }
}

module.exports = ProjectWeeks;
