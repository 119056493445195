const React = require('react');
const PropTypes = require('prop-types');

const Empty = ({ children }) => (
  <div className="wethod-file-section__empty">
    {children}
  </div>
);

Empty.defaultProps = {
  children: 'No files found yet.',
};

Empty.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
};

module.exports = Empty;
