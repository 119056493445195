const React = require('react');
const { Provider } = require('react-redux');
const Expenses = require('./containers/Expenses');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <Expenses />
  </Provider>
);
