/* eslint-disable react/sort-comp,class-methods-use-this,react/no-array-index-key,consistent-return,react/jsx-no-bind,jsx-a11y/anchor-is-valid */
const React = require('react');
const FollowUp = require('../../containers/FollowUp/FollowUpController');

module.exports = class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFollowUpForm: false,
    };

    this.typeNames = {
      timeline_call: 'Call',
      timeline_email: 'Email',
      timeline_meeting: 'Meeting',
    };
    this.iconClasses = {
      Call: 'wethod-icon-phone wethod-icon-phone--black',
      Email: 'wethod-icon-mail wethod-icon-mail--black',
      Meeting: 'wethod-icon-meeting wethod-icon-meeting--black',
    };
  }

  getCreationDate() {
    const momentDate = moment(this.props.timestamp);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    const createdBy = this.props.event.created_by;
    if (createdBy) {
      return `${createdBy.person.name} ${createdBy.person.surname}`;
    }
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate();
    if (creator) {
      return (
        <span>
          <span className="timeline-event-past__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-past__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-past__creation-time">{date}</span>
    );
  }

  getLogoClass() {
    return this.iconClasses[this.typeNames[this.props.type]];
  }

  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props.event);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props.event);
  }

  handleDoneClick(e) {
    e.preventDefault();
    const event = {
      ...this.props.event,
      confirmed: !this.props.event.confirmed,
      type: this.props.event.type.id,
    };
    delete event.followed_by;
    delete event.created_by;
    this.props.save(event);
  }

  getFollowUpButton() {
    if (this.props.event.followed_by === null) {
      return (
        <div className="wethod-button"
          onClick={() => {
            this.showFollowUpForm();
          }}>
          + Follow up
        </div>
      );
    }
  }

  showFollowUpForm() {
    this.setState({ showFollowUpForm: true });
  }

  hideFollowUp() {
    this.props.closeForm();
    this.setState({ showFollowUpForm: false });
  }

  getDoneButtonText() {
    const done = this.props.event.confirmed;
    if (!done) return 'Mark as done';
    return 'Mark as not done';
  }

  getContentStyle() {
    const done = this.props.event.confirmed;
    if (!done) return { borderLeft: '3px solid #DB4D69' };
  }

  render() {
    return (
      <div className="timeline-event-past" id={this.props.event.id}>
        <div className="timeline-event-past__type">
          <div className="timeline-event-past__type-logo-container">
            <div className={this.getLogoClass()} />
          </div>
          <div className="timeline-event-past__type-name">{this.typeNames[this.props.type]}</div>
        </div>
        <div className="timeline-event-past__creation">
          {this.getCreation()}
        </div>
        <div className="timeline-event-past__content" style={this.getContentStyle()}>
          <div className="timeline-event-past__description">{this.props.event.description}</div>
          <div className="timeline-event-past__actions">
            <div className="timeline-event-past__actions-left">
              {this.getFollowUpButton()}
            </div>
            <div className="timeline-event-past__actions-right">
              <div className="wethod-button"
                onClick={this.handleDoneClick.bind(this)}>
                {this.getDoneButtonText()}
              </div>
              <div className="clients__delete-button wethod-icon-button"
                onClick={this.handleDeleteClick.bind(this)}>
                <div className="wethod-icon-medium-delete" />
              </div>
              <a href="#"
                className="clients__delete-button wethod-icon-button"
                onClick={this.handleEditClick.bind(this)}>
                <div className="wethod-icon-medium-edit" />
              </a>
            </div>
          </div>
        </div>
        <FollowUp showForm={this.state.showFollowUpForm}
          {...this.props}
          hideFollowUp={this.hideFollowUp.bind(this)}
          openEditForm={this.showFollowUpForm.bind(this)} />
      </div>
    );
  }
};
