const React = require('react');
const PropTypes = require('prop-types');

const OpenSidebarButton = ({ onClick }) => (
  <button type="button"
    aria-label="Open sidebar"
    className="wethod-info-header__sidebar-button wethod-icon-button wethod-icon-button--no-border"
    onClick={onClick}>
    <div className="wethod-icon-forward wethod-icon-forward--black" />
  </button>
);

OpenSidebarButton.defaultProps = {
  onClick: null,
};

OpenSidebarButton.propTypes = {
  onClick: PropTypes.func,
};

module.exports = OpenSidebarButton;
