const React = require('react');
const InfoHeader = require('../../../../../common/react/InfoHeader/InfoHeader.react');
const Menu = require('../../../../../common/react/Menu/ListMenu/ListMenu.react');
const IconLink = require('../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Description = require('./ProjectDescription.react');
const IconButtonTooltip = require('../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Icon = require('../../../../../common/react/Icon/Icon.react');
const MoreOptionsMenuItem = require('../../budget/components/ProjectSidebar/MoreOptionMenuItem.react');

module.exports = class ProjectInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  handleDelegateClick() {
    if (!this.props.isLinked) this.props.showDelegateModal(this.props);
  }

  getDelegateButtonStyle() {
    let style = 'wethod-button invoice-plan__info-button';
    if (this.props.isLinked) style += ' disabled';
    return style;
  }

  getDescription() {
    return (
      <Description name={this.props.name}
        client={this.props.client.corporate_name}
        joc={this.props.project_type.color}
        isMaster={this.props.isMaster} />
    );
  }

  getOptionsMenu(projectId) {
    return (
      <Menu key="options"
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MoreOptionsMenuItem href={`#planning/production-plan?project_id:${projectId}`}>Production plan</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#timeline/${projectId}`}>Timeline</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project-status/${projectId}`}>Project status</MoreOptionsMenuItem>
      </Menu>
    );
  }

  getBillingGroupAction() {
    if (this.props.isMaster) {
      return (
        <button key="delegate" type="button" className="wethod-button invoice-plan__info-button" onClick={this.props.showLinkedProjectsModal}>
          Show linked projects
        </button>
      );
    }

    return (
      <button key="linked-projects" type="button" className={this.getDelegateButtonStyle()} onClick={this.handleDelegateClick.bind(this)}>
        Delegate invoice plan
      </button>
    );
  }

  setMenuAnchorRef(ref) {
    this.optionsButton = ref;
  }

  /**
   * Primary links: pipeline, canvas, budget, report, planning
   * Secondary links: production plan, project status, timeline
   * Secondary links are shown from a Menu when the 'more options' button is clicked
   */
  getActions() {
    return [
      this.getBillingGroupAction(),
      <IconLink key="pipeline"
        label="Pipeline"
        href={`#pipeline/projects?id=${this.props.id}`}
        target="_blank">
        <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
      </IconLink>,
      <IconLink key="budget"
        label="Budget"
        href={`#pipeline/budget/${this.props.id}`}
        target="_blank">
        <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
      </IconLink>,
      <IconLink key="canvas"
        label="Canvas"
        href={`#project/${this.props.id}/canvas`}
        target="_blank">
        <span className="wethod-icon wethod-icon-canvas wethod-icon-canvas--black" />
      </IconLink>,
      <IconLink key="planning"
        label="Planning"
        href={`#planning/people?project_id=${this.props.id}`}
        target="_blank">
        <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
      </IconLink>,
      <IconLink key="report"
        label="Report"
        href={`#project/${this.props.id}/report`}
        target="_blank">
        <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
      </IconLink>,
      <IconButtonTooltip key="more-options"
        label="More options"
        onClick={this.showMenu.bind(this)}
        rootRef={this.setMenuAnchorRef.bind(this)}>
        <Icon icon="more" />
      </IconButtonTooltip>,
      this.getOptionsMenu(this.props.id),
    ];
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  render() {
    return (
      <InfoHeader description={this.getDescription()} actions={this.getActions()} />
    );
  }
};
