module.exports.LOAD_CHANGELOG_REQUEST = 'LOAD_CHANGELOG_REQUEST';
module.exports.LOAD_CHANGELOG_SUCCESS = 'LOAD_CHANGELOG_SUCCESS';

module.exports.SORT_CHANGELOG_REQUEST = 'SORT_CHANGELOG_REQUEST';
module.exports.SORT_CHANGELOG_SUCCESS = 'SORT_CHANGELOG_SUCCESS';

module.exports.FILTER_CHANGELOG_REQUEST = 'FILTER_CHANGELOG_REQUEST';
module.exports.FILTER_CHANGELOG_SUCCESS = 'FILTER_CHANGELOG_SUCCESS';

module.exports.CHANGE_BU_FILTER = 'CHANGE_BU_FILTER';

module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
