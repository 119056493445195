/* eslint-disable react/prefer-stateless-function */
const React = require('react');

class PlannedPerson extends React.Component {
  render() {
    return (
      <div className="planned-person">{this.props.name}</div>
    );
  }
}

module.exports = PlannedPerson;
