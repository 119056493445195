/**
 * Update current timesheet url with given date and userId.
 * The userId can be omitted
 *
 * @param formattedDate
 * @param userId
 */
module.exports = (formattedDate, userId) => {
  const url = Backbone.history.getFragment();
  const queryString = url.split('?').length > 1 ? `?${url.split('?')[1]}` : '';
  const userParam = userId ? `/${userId}` : '';
  const newUrl = `timesheet${userParam}/date=${formattedDate}${queryString}`;
  Wethod.navigate(newUrl, { replace: true });
};
