const PropertyMetadata = require('./PropertyMetadata');
const PropertyMetadataConstraint = require('./PropertyMetadataConstraint');

module.exports = class PropertyMetadataService {
  /**
   *
   * @param {[]} metadataList
   * @param {{}} nameOverrides
   */
  constructor(metadataList = [], nameOverrides = { }) {
    /**
     * @type {PropertyMetadata[]}
     */
    this.list = metadataList.map((metadataObj) => {
      const metadata = new PropertyMetadata(metadataObj.name);
      metadata.canEdit = metadataObj.can_edit;
      metadata.reason = metadataObj.cannot_edit_reason;
      metadata.constraints = metadataObj.constraints
        .map((constraint) => new PropertyMetadataConstraint(constraint.name, constraint.value));
      return metadata;
    });
    this.nameOverrides = nameOverrides;
    this.map = this.getMap();
  }

  /**
   * Return map of metadata by name.
   * @private
   * @return {{}}
   */
  getMap() {
    const map = {};
    // eslint-disable-next-line no-underscore-dangle
    this.list.forEach((metadata) => {
      const originalName = metadata.name;
      const nameOverride = this.nameOverrides[originalName];
      const name = nameOverride || originalName;

      map[name] = metadata;
    });
    return map;
  }

  /**
   *
   * @param name
   * @return {PropertyMetadata}
   */
  getMetadata(name) {
    return this.map[name];
  }

  /**
   *
   * @param metadataName
   * @return {PropertyMetadataConstraint[]}
   */
  getConstraints(metadataName) {
    const metadata = this.getMetadata(metadataName);

    return metadata ? metadata.constraints : [];
  }

  /**
   *
   * @param metadataName
   * @param constraintName
   * @return {PropertyMetadataConstraint}
   */
  getConstraint(metadataName, constraintName) {
    const constraints = this.getConstraints(metadataName);

    return constraints.find((constraint) => constraint.name === constraintName);
  }

  /**
   *
   * @param metadataName
   * @param constraintName
   * @return {*}
   */
  getConstraintValue(metadataName, constraintName) {
    const constraint = this.getConstraint(metadataName, constraintName);

    return constraint ? constraint.value : null;
  }

  /**
   *
   * @param metadataName
   * @return {string}
   */
  getReason(metadataName) {
    const metadata = this.getMetadata(metadataName);

    return metadata ? metadata.reason : null;
  }

  /**
   * Return if property can be edited due to metadata.
   * Return true by default.
   * @param {string} metadataName
   * @return {boolean}
   */
  getCanEdit(metadataName) {
    const metadata = this.getMetadata(metadataName);

    return metadata ? metadata.canEdit : true;
  }

  /**
   * Return true if metadata list is not empty.
   * @return {boolean}
   */
  hasMetadata() {
    return this.list.length > 0;
  }
};
