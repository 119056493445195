const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../../../ShowIf/ShowIf.react');
const SearchInput = require('../../../Table2/TableSearch.react');
const Button = require('../../../TooltipFixed/IconButtonTooltip.react');
const PathModel = require('../models/Path');

const SearchBar = ({ path, onBackClick, onSearch, keyword }) => {
  const canGoBack = () => path && path.length() > 1;

  function updateHeight() {
    return null;
  }

  return (
    <div className="wethod-widget-files__collection-header">
      <ShowIf condition={canGoBack()}>
        <div className="wethod-widget-files__collection-back-button-container">
          <Button label="Back"
            onClick={onBackClick}
            className="wethod-icon-button--no-margin"
            borderless>
            <span className="wethod-icon-arrow-left wethod-icon-arrow-left--black" />
          </Button>
        </div>
      </ShowIf>
      <SearchInput updateHeight={updateHeight}
        filter={onSearch}
        className="wethod-widget-files__collection-search"
        keyword={keyword}
        placeholder="Search in this folder" />
    </div>
  );
};

SearchBar.defaultProps = {
  onBackClick: null,
  keyword: '',
  path: null,
};

SearchBar.propTypes = {
  path: PropTypes.instanceOf(PathModel),
  /**
   * The function to call when search keyword is updated.
   * @param keyword {string}
   */
  onSearch: PropTypes.func.isRequired,
  /**
   * The function to call when user clicks on back button.
   */
  onBackClick: PropTypes.func,
  /**
   * Initial value shown as keyword in the search bar.
   */
  keyword: PropTypes.string,
};

module.exports = SearchBar;
