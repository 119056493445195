const constants = require('./constants');

class OrdersReducer {
  static isOrderFocused(focusedItem, order) {
    return focusedItem != null && order != null && focusedItem.id === order.id;
  }

  static addFile(order, file) {
    if (!order || !file) {
      return order;
    }

    return {
      ...order,
      files: order.files.concat(file),
    };
  }

  static removeFile(order, file) {
    if (!order || !file) {
      return order;
    }

    return {
      ...order,
      files: order.files.filter((orderFile) => orderFile.id !== file.id),
    };
  }

  constructor(state) {
    this.state = {
      focusedItem: null,
      orderMetadata: [], // metadata related to order attributes
      ...state,
      waitingFor: [], // contains a key for each pending request
      modalToShow: null,
      modalData: null,
      size: 0,
      orders: [],
      selectedProjectAreas: [],
      deletedElements: 0,
      createdElements: 0,
      sortBy: {
        col: 'issue_date',
        order: 'desc',
      },
      serverErrorMessage: null,
      statusFilter: state.availableStatuses.map((status) => status.id),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.GET_ORDERS_REQUEST: {
        return {
          ...state,
          waitingFor: state.waitingFor.concat('load-orders'),
        };
      }
      case constants.GET_ORDERS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'load-orders'),
          orders: state.orders.concat(action.items),
          size: action.size,
          lastPage: action.items.length < action.size,
        };
      case constants.FILTER_ORDERS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('filter-orders'),
          orders: [],
          keyword: action.keyword,
          sortBy: { col: action.orderBy, order: action.sort },
          statusFilter: action.statusFilter,
        };
      case constants.FILTER_ORDERS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'filter-orders'),
          orders: action.items,
          lastPage: action.items.length < action.size,
        };
      case constants.GET_PROJECT_AREAS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('areas'),
          selectedProjectAreas: [],
        };
      case constants.GET_PROJECT_AREAS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'areas'),
          selectedProjectAreas: action.items,
        };
      case constants.GET_PROJECT_CURRENCIES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('currencies'),
          focusedItem: state.focusedItem ? { // Reset currencies list in the selected order
            ...state.focusedItem,
            currencies: [],
          } : state.focusedItem,
        };
      case constants.GET_PROJECT_CURRENCIES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'currencies'),
          focusedItem: state.focusedItem ? { // Update currencies list in the selected order
            ...state.focusedItem,
            currencies: action.items,
          } : state.focusedItem,
        };
      // case constants.CREATE_ORDER_REQUEST:
      case constants.UPDATE_ORDER_REQUEST:
      case constants.DELETE_ORDER_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-order'),
        };
      case constants.UPDATE_ORDER_SUCCESS: {
        let hasElementBeenAdded = false;
        let updatedOrders = state.orders.map((order) => {
          if (order.id === action.item.id) {
            return action.item;
          }
          return order;
        });
        if (!updatedOrders.some((order) => order.id === action.item.id)) {
          updatedOrders = [action.item].concat(updatedOrders);
          hasElementBeenAdded = true;
        }
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-order'),
          orders: updatedOrders,
          focusedItem: action.updateSidebar ? action.item : state.focusedItem,
          createdElements: hasElementBeenAdded ? state.createdElements + 1 : state.createdElements,
        };
      }
      case constants.UPDATE_ORDER_ERROR:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-order'),
          serverErrorMessage: action.message,
        };
      case constants.DELETE_ORDER_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-order'),
          orders: state.orders.filter((item) => item.id !== action.item.id),
          focusedItem: null,
          deletedElements: state.deletedElements + 1,
          serverErrorMessage: null, // Reset error
        };
      case constants.CREATE_ORDER_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-order'),
          focusedItem: null,
          serverErrorMessage: null,
          selectedProjectAreas: [],
        };
      case constants.DELETE_ORDER_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-order'),
        };
      case constants.SEND_ORDER_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(['send-order', `send:${action.item.id}`]),
        };
      case constants.SEND_ORDER_SUCCESS: {
        const updatedOrders = state.orders.map((order) => {
          if (order.id === action.item.id) {
            return {
              ...order,
              ...action.item,
            };
          }
          return order;
        });

        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'send-order' && key !== `send:${action.item.id}`),
          orders: updatedOrders,
        };
      }
      case constants.SEND_ORDER_ERROR:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'send-order' && key !== `send:${action.item.id}`),
          modalToShow: 'send-error',
        };
      case constants.APPROVE_ORDER_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(['approve-order', `approve:${action.item.id}`]),
        };
      case constants.APPROVE_ORDER_SUCCESS: {
        const updatedOrders = state.orders.map((order) => {
          if (order.id === action.item.id) {
            return {
              ...order,
              ...action.item,
            };
          }
          return order;
        });

        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'approve-order' && key !== `approve:${action.item.id}`),
          orders: updatedOrders,
          modalToShow: null,
        };
      }
      case constants.SET_PAID_ORDER_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(['order-set-paid', `set-paid:${action.item.id}`]),
        };
      case constants.SET_PAID_ORDER_SUCCESS: {
        const updatedOrders = state.orders.map((order) => {
          if (order.id === action.item.id) {
            return {
              ...order,
              ...action.item,
            };
          }
          return order;
        });

        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'order-set-paid' && key !== `set-paid:${action.item.id}`),
          orders: updatedOrders,
        };
      }
      case constants.EXPORT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('export'),
        };
      case constants.EXPORT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'export'),
          modalToShow: action.modalToShow,
        };
      case constants.SHOW_SIDEBAR: {
        return {
          ...state,
          focusedItem: action.id
            ? state.orders.filter((order) => order.id === action.id)[0]
            : {
              ...new Wethod.FinanceApp.Order.OrderModel().toJSON(),
              _fields: state.orderMetadata,
            },
        };
      }
      case constants.CLOSE_SIDEBAR: {
        return {
          ...state,
          focusedItem: null,
          serverErrorMessage: null,
          selectedProjectAreas: [],
        };
      }
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.DELETE_FILE_SUCCESS:
        return {
          ...state,
          // Remove file also from focused item when necessary
          focusedItem: OrdersReducer.isOrderFocused(state.focusedItem, action.item)
            ? OrdersReducer.removeFile(state.focusedItem, action.file)
            : state.focusedItem,
          orders: state.orders.map((item) => {
            if (item.id === action.item.id) {
              return OrdersReducer.removeFile(item, action.file);
            }
            return item;
          }),
        };
      case constants.UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          // Add file also to focused item when necessary
          focusedItem: OrdersReducer.isOrderFocused(state.focusedItem, action.item)
            ? OrdersReducer.addFile(state.focusedItem, action.file)
            : state.focusedItem,
          orders: state.orders.map((item) => {
            if (item.id === action.item.id) {
              return OrdersReducer.addFile(item, action.file);
            }
            return item;
          }),
        };
      case constants.RESET_ORDER_SERVER_ERROR:
        return {
          ...state,
          serverErrorMessage: null,
        };
      default:
        return state;
    }
  }
}

module.exports = OrdersReducer;
