/* eslint-disable react/sort-comp,react/prop-types */
const React = require('react');

module.exports = class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.chartOptions = {
      chart: {
        backgroundColor: 'transparent',
        type: 'solidgauge',
        margin: [3, 3, 3, 3],
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            backgroundColor: '#FFFFFF',
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          borderWidth: '8px',
          borderRadius: 0,
          dataLabels: {
            enabled: false,
          },
          linecap: 'square',
          stickyTracking: true,
          allowPointSelect: true,
        },
      },
    };
  }

  getChartOptions() {
    return {
      ...this.chartOptions,
      series: [
        {
          name: 'time',
          borderColor: '#F3F3F3',
          tooltip: {
            pointFormat: '',
          },
          data: [
            {
              color: '#F3F3F3',
              radius: '100%',
              innerRadius: '0%',
              y: this.props.timeProgress,
            },
          ],
        },
        {
          name: 'revenue pipeline',
          tooltip: {
            pointFormat: '',
          },
          data: [
            {
              color: '#48A5F9',
              radius: '88%',
              innerRadius: '80%',
              y: this.props.revenuePipelineProgress,
            },
          ],
        },
        {
          name: 'production value',
          cursor: 'pointer',
          tooltip: {
            pointFormat: '',
          },
          data: [
            {
              color: 'rgba(78,216,141,1)',
              radius: '100',
              innerRadius: '92%',
              y: this.props.productionValueProgress,
            },
          ],
        },
      ],
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  componentDidMount() {
    this.renderChart();
  }

  render() {
    return (
      <div className="widget-revenue-clock__chart"
        ref={(chartContainer) => this.chartContainer = chartContainer} />
    );
  }
};
