const React = require('react');
const format = require('../../../../../services/FormatService').formatDecimalNumber;
const Text = require('../../../../../common/react/material-input/SidebarReadOnlyField.react');
const Actions = require('../containers/CardActions');

const CardBodyView = ({
  planned = 0, invoiced = 0, deltaInvoiced,
  compact, currencyCode, createInvoice,
}) => {
  const formattedPlanned = format(planned, 2, false);
  const formattedInvoiced = format(invoiced, 2, false);

  const formattedToBeInvoiced = format(deltaInvoiced, 2, false);

  function formatWithCurrency(value) {
    return currencyCode ? `${value} ${currencyCode}` : value;
  }

  function getCompactView() {
    return (
      <div>
        <Text placeholder="Planned value" value={formattedPlanned} />
      </div>
    );
  }

  function getExtendedView() {
    return (
      <div>
        <Text placeholder="Planned value" value={formatWithCurrency(formattedPlanned)} />
        <div className="wethod-sidebar__multiple-input-row">
          <Text placeholder="Invoiced" value={formatWithCurrency(formattedInvoiced)} />
          <Text placeholder="To be invoiced" value={formatWithCurrency(formattedToBeInvoiced)} />
        </div>
        <Actions delta={deltaInvoiced} createInvoice={createInvoice} />
      </div>
    );
  }

  return compact ? getCompactView() : getExtendedView();
};

module.exports = CardBodyView;
