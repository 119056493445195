const React = require('react');
const FilledTag = require('../../../../../../common/react/Tag/FilledTag.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');

const CounterTag = ({ value, counterColor }) => {
  const formattedCounter = value > 99 ? '99+' : value;

  return (
    <FilledTag backgroundColor={counterColor}
      fontSize={Typography.SIZES.PX10}
      color={FilledTag.COLORS.WHITE}
      borderRadius={FilledTag.BORDER_RADIUS.ROUNDED}>
      {formattedCounter}
    </FilledTag>
  );
};

module.exports = CounterTag;
