const React = require('react');
const Modal = require('../../../../../../../../common/react/Modal/Modal.react');
const FormValidator = require('../../../../../../../../common/react/FormValidator/FormValidator.react');
const ModalContent = require('../../../containers/PersonalApiToken/TokenAddModal/TokenAddModalContent');
const FormMetadataManager = require('../../../../../../../../common/react/FormMetadataManager/FormMetadataManager.react');

const TokenAddModal = ({
  closeModal,
  tokenMetadata,
  error = '',
}) => (
  <Modal onClose={closeModal} title="Create new token">
    <FormValidator>
      <FormMetadataManager metadata={tokenMetadata}>
        <ModalContent closeModal={closeModal} error={error} />
      </FormMetadataManager>
    </FormValidator>
  </Modal>
);

module.exports = TokenAddModal;
