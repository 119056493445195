const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const Comparator = require('../../../../common/components/Comparator.react');
const Link = require('../../../../common/components/Link.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({
  backlog, backlogPrevMonth, backlogPrevYear, futureWeeks,
}) => {
  const getPrevMonthComparator = () => {
    const labels = {
      much_lower: 'dropping from',
      lower: 'dropping from',
      almost_equal: 'in line with',
      equal: 'equal to',
      greater: 'rising from',
      much_greater: 'rising from',
    };

    return <Comparator a={backlog} b={backlogPrevMonth} labels={labels} />;
  };

  const getPrevYearComparator = () => {
    const labels = {
      much_lower: 'dropping from',
      lower: 'dropping from',
      almost_equal: 'in line with',
      equal: 'equal to',
      greater: 'rising from',
      much_greater: 'rising from',
    };

    return <Comparator a={backlog} b={backlogPrevYear} labels={labels} />;
  };

  return (
    <Paragraph>
      The month closed with a backlog of
      {' '}
      <Link href="#reports/backlog">
        <NV
          value={backlog} />
      </Link>
      {' '}
      {getPrevMonthComparator()}
      {' '}
      the previous
      month, and
      {' '}
      {getPrevYearComparator()}
      {' '}
      the same time a year ago. Considering the average weekly production seen over
      the past 12 weeks, backlog is sufficient to guarantee production for
      {' '}
      <NV value={futureWeeks} />
      {' '}
      additional
      {' '}
      <NS
        quantity={futureWeeks}>
        weeks
      </NS>
      .
    </Paragraph>
  );
};
