const React = require('react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');

const EmployeeTagsItemDeleteModal = ({ onClose, onOk, onDeleteTag, feedback }) => (
  <Modal onClose={onClose} title="Delete Tag Confirm">
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Delete the tag <b>{onDeleteTag.name}</b>? Other users may use it.
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={(feedback) ? 'deleting...' : null}>
        <ModalFooterAction onClick={onClose}>Close</ModalFooterAction>
        <ModalFooterAction onClick={onOk}>Ok, Delete</ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

module.exports = EmployeeTagsItemDeleteModal;
