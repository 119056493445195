/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const SearchInput = require('../../../../../../common/react/SearchInput.react');
const Empty = require('../Empty.react');
const FavouriteCompany = require('./FavouriteCompany.react');
const FavouriteCustomer = require('./FavouriteCustomer.react');

module.exports = class FavouriteClients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredClients: this.props.clients,
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clients.length !== this.props.clients.length) {
      this.handleFilter(this.state.keyword);
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of clients with the given keyword
   * @param keyword
   */
  handleFilter(keyword) {
    this.setState({
      filteredClients: this.props.clients
        .filter((client) => this.found(keyword, client[client.type].name)
          || this.found(keyword, client.client.name)),
      keyword,
    });
  }

  getBody() {
    if (!this.props.clients.length) {
      return (
        <Empty
          title={(
            <div className="desk-widget-empty__pinboard-title">
              Bookmark
              <div className="wethod-icon-bookmark-empty wethod-icon-bookmark-empty--black desk-widget-empty__pinboard-bookmark" />
              your favourites client pages.
            </div>
          )}
          className="desk-widget-empty--activities"
          description="Favourites are saved here for a faster access."
          image={<div className="desk-widget-empty__pinboard--clients" />} />
      );
    }
    const clients = !this.state.filteredClients.length
      ? <div className="desk-widget__body--empty-text">No client found</div>
      : this.state.filteredClients.map((favourite) => (favourite.type === 'client'
        ? (
          <FavouriteCompany key={favourite.client.id}
            deleteFavourite={this.props.deleteFavourite}
            {...favourite} />
        )
        : (
          <FavouriteCustomer key={favourite.customer.id}
            deleteFavourite={this.props.deleteFavourite}
            {...favourite} />
        )));
    return (
      <div className="wethod-widget__body--content">
        <div className="wethod-widget__search desk-widget__item">
          <SearchInput filter={this.handleFilter.bind(this)}
            keyword={this.state.keyword} />
        </div>
        <div className="desk-widget__body--list">
          {clients}
        </div>
      </div>
    );
  }

  render() {
    return (this.getBody());
  }
};
