const React = require('react');
const Card = require('../../../../../../common/react/Card.react');
const Menu = require('../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MoreOptionsMenuItem = require('../../../budget/components/ProjectSidebar/MoreOptionMenuItem.react');

module.exports = class ProjectActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MoreOptionsMenuItem href={`#planning/production-plan?project_id:${projectId}`}>Production plan</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${projectId}/invoice-plan`}>Invoice plan</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project-status/${projectId}`}>Project status</MoreOptionsMenuItem>
      </Menu>
    );
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  render() {
    return (
      <Card>
        <div className="timeline-project-actions">
          <a
            href={`#pipeline/budget/${this.props.project.id}`}
            target="_blank"
            rel="noreferrer"
            className="pipeline__project-action"
            title="Budget">
            <div className="wethod-icon-budget" />
          </a>
          <a
            href={`#planning/people?project_id=${this.props.project.id}`}
            target="_blank"
            rel="noreferrer"
            className="pipeline__project-action"
            title="Planning">
            <div className="wethod-icon-planning" />
          </a>
          <a
            href={`#project/${this.props.project.id}/canvas`}
            target="_blank"
            rel="noreferrer"
            className="pipeline__project-action"
            title="Canvas">
            <div className="wethod-icon-canvas" />
          </a>
          <a
            href={`#project/${this.props.project.id}/report`}
            target="_blank"
            rel="noreferrer"
            className="pipeline__project-action"
            title="Report">
            <div className="wethod-icon-project-report" />
          </a>
          <a
            href={`#pipeline/projects?id=${this.props.project.id}`}
            target="_blank"
            rel="noreferrer"
            className="pipeline__project-action"
            title="Pipeline">
            <div className="wethod-icon-pipeline" />
          </a>
          <button type="button"
            className="wethod-icon-button"
            ref={(el) => this.optionsButton = el}
            onClick={this.showMenu.bind(this)}>
            <div className="wethod-icon wethod-icon-options" />
            {this.getOptionsMenu(this.props.project.id)}
          </button>
        </div>
      </Card>
    );
  }
};
