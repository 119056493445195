/* eslint-disable react/prop-types */
const React = require('react');
const MenuItem = require('./MenuItem.react');
const MenuItemDesk = require('./MenuItemDesk.react');

module.exports = ({ item, showArea, open, showItem, closeMenu }) => {
  switch (item.getLabel()) {
    case 'Desk':
      return (
        <MenuItemDesk item={item}
          onClick={closeMenu} />
      );
    default:
      return (
        <MenuItem item={item}
          open={open}
          showItem={showItem}
          areas={item.getMobileAreas()}
          showArea={showArea} />
      );
  }
};
