const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const Link = require('../../../../common/components/Link.react');
const DateService = require('../../../../../../../../../services/DateService');

module.exports = ({ date }) => {
  const isLastWeekOfMonth = () => {
    const nextWeek = date.clone().add(1, 'week');
    return DateService.getMonth(date) !== DateService.getMonth(nextWeek);
  };

  if (isLastWeekOfMonth()) {
    return (
      <Paragraph>
        (Oh! This month has come to an end. Check out the
        {' '}
        <Link href="/#reports/briefing/monthly">
          briefing of last month
        </Link>
        .)
      </Paragraph>
    );
  }
  return null;
};
