const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Model.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  erase: HTTPService.erase,
  urlRoot() {
    return `${APIURL}/billing-group/`;
  },
  getBillingGroup(groupId) {
    this.urlRoot = `${this.urlRoot()}${groupId}`;

    const defer = $.Deferred();

    $.when(this.fetch()).done((model) => {
      defer.resolve(model.attributes);
    });
  },
  delegateProject(projectId, masterProjectId) {
    const defer = $.Deferred();

    this.set('linked', projectId);
    this.set('master', masterProjectId);

    this.save(null, {
      success(model, response) {
        if (response.code === 200 || response.code === 201) {
          defer.resolve(model.toJSON());
        } else {
          defer.reject(response.message, response.code);
        }
      },
      error(model, response) {
        defer.reject(response.message, response.code);
      },
    });

    return defer.promise();
  },
  removeProject(projectId, groupId) {
    this.urlRoot = `${this.urlRoot()}${groupId}/project`;

    this.set({ id: projectId });
    const defer = $.Deferred();

    this.destroy({
      success(model, response) {
        defer.resolve(response.data);
      },
      error(model, response) {
        defer.reject(response.message, response.code);
      },
    });

    return defer.promise();
  },
});
