const React = require('react');

/**
 * Returns an array where each element represents a token of a string. Tokens matching the given regex are included into
 * an <em> tag.
 *
 * @param regex
 * @param string
 * @returns {Array}
 */
module.exports = (regex, string) => {
  const matches = string.match(regex);
  let originalTokens = string.split(regex);
  originalTokens = originalTokens.filter((token) => !regex.test(token));
  const highlightedTokens = [];
  for (let i = 0; i < originalTokens.length; i++) {
    highlightedTokens[i * 2] = originalTokens[i];
    if (i < originalTokens.length - 1) {
      highlightedTokens[i * 2 + 1] = React.createElement('em', null, matches[i]);
    }
  }
  return highlightedTokens;
};
