/* eslint-disable react/sort-comp,react/no-access-state-in-setstate,jsx-a11y/anchor-is-valid */
const React = require('react');
const CurrentItem = require('./SponsorItemCurrent.react');
const PastItem = require('./SponsorItemPast.react');

module.exports = class SponsorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showPast: false };
  }

  getCurrentList() {
    if (this.props.currentList.length > 0) {
      return this.props.currentList.map((item) => <CurrentItem key={item.id} {...item} />);
    }
    return <span className="profile-card__placeholder">{this.props.placeholder}</span>;
  }

  getPastList() {
    if (this.state.showPast) {
      return this.props.pastList.map((item) => <PastItem key={item.id} {...item} />);
    }
    return null;
  }

  togglePastList(e) {
    e.preventDefault();
    this.setState({ showPast: !this.state.showPast });
  }

  getShowAction() {
    if (this.props.pastList.length > 0) {
      if (this.state.showPast) {
        return (
          <a
            href="#"
            className="profile-sponsor__list-button"
            onClick={this.togglePastList.bind(this)}>
            Hide history
          </a>
        );
      }

      return (
        <a
          href="#"
          className="profile-sponsor__list-button"
          onClick={this.togglePastList.bind(this)}>
          Show history
        </a>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile-sponsor__list">
        <div className="profile-sponsor__list--current">{this.getCurrentList()}</div>
        {this.getShowAction()}
        <div className="profile-sponsor__list--past">{this.getPastList()}</div>
      </div>
    );
  }
};
