const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../../../Button/RoundedButton.react');

const SyncButton = ({ onClick }) => <Button onClick={onClick}>Sync folder</Button>;

SyncButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

module.exports = SyncButton;
