const React = require('react');
const ModalContent = require('../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../common/react/Modal/ModalFooterAction.react');

const ApprovalErrorBody = ({
  errors, onCancelClick, onPositiveButtonClick, isSaving, projectsInfo,
}) => {
  const projects = errors.map((error) => (
    <div
      key={error.project}
      className="planning-people-repeat-error__message-list-item">
      <span
        className="bold">
        {projectsInfo[error.project].name}
      </span>
    </div>
  ));

  const getFeedBack = () => {
    if (isSaving) {
      return 'Planning...';
    }
    return '';
  };

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Following projects can only be planned via{' '}
          <a href="/#planning/allocation-requests" target="_blank">
            allocation request
          </a>:
        </ModalBodyBlock>
        <ModalBodyBlock>{projects}</ModalBodyBlock>
        <ModalBodyBlock>
          Do you still want to repeat the other projects?
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={getFeedBack()}>
        <ModalFooterAction onClick={onCancelClick}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onPositiveButtonClick}>
          Yes
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = ApprovalErrorBody;
