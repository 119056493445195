const React = require('react');
const PropTypes = require('prop-types');
const TextInput = require('../TextInput/TextInput.react');

class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onChange(e) {
    e.persist();

    this.setState({ value: e.target.value }, () => {
      this.validate(e.target.value);
      this.onParentChange(e);
    });
  }

  validate(value) {
    if (this.props.validate) {
      this.props.validate(this.props.name, value);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
    });
  }

  render() {
    return (
      <TextInput
        rootRef={this.props.rootRef}
        value={this.state.value}
        id={this.props.id}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        name={this.props.name}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        onClick={this.props.onClick}
        onKeyUp={this.props.onKeyUp}
        required={this.props.required}
        aria-label={this.props.label}
        autoComplete={this.props.autoComplete}
        size={this.props.size} />
    );
  }
}

TextField.defaultProps = {
  value: '',
  id: undefined,
  placeholder: undefined,
  readOnly: undefined,
  disabled: undefined,
  autoComplete: 'off',
  name: undefined,
  size: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onKeyUp: undefined,
  rootRef: undefined,
  validate: undefined,
  required: undefined,
  onChange: undefined,
  label: undefined,
  resetErrors: null,
  onClick: undefined,
};

TextField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  name: PropTypes.string,
  /** The width, in characters * */
  size: PropTypes.number,
  /**
   * Function to call when value changes.
   * @param e {HTMLEvent}
   */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  /** Function to access to inptut DOM element
   * @param input {HTMLElement} DOM element ref
   * */
  rootRef: PropTypes.func,
};

module.exports = TextField;
