const React = require('react');
const PersonNote = require('../containers/modals/PersonNoteModal');
const UnsavedChanges = require('./modals/UnsavedChangesModal.react');
const SaveModal = require('../containers/modals/SaveModal');
const MissingVote = require('./modals/MissingVoteModal.react');

const Modal = ({ toShow, closeModal }) => {
  if (toShow) {
    switch (toShow.name) {
      case 'person-note':
        return (
          <PersonNote onCancelClick={closeModal}
            personId={toShow.personId}
            note={toShow.note} />
        );
      case 'save':
        return <SaveModal onCancelClick={closeModal} />;
      case 'unsaved-changes':
        return <UnsavedChanges onCancelClick={closeModal} />;
      case 'missing-vote':
        return <MissingVote onCancelClick={closeModal} step={toShow.step} />;
      default:
        return null;
    }
  }
  return null;
};

module.exports = Modal;
