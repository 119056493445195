'use strict';

Wethod.module('ReportApp.BubblesInvoice', function (BubblesInvoice, Wethod, Backbone, Marionette, $) {
  this.BubblesInvoiceModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/report/bubbles-invoice',
  });

  var API = {
    getBubblesInvoice: function () {
      var bubblesInvoiceModel = new BubblesInvoice.BubblesInvoiceModel();
      var defer = $.Deferred();

      bubblesInvoiceModel.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            var result = {
              data: response.data,
              cached_on: response.cached_on,
            };
            defer.resolve(result);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    bubbleInvoiceCacheClear: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/report/bubbles-invoice?cache=clear',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
  };

  // model
  Wethod.reqres.setHandler('get:report:bubbleInvoice', function () {
    return API.getBubblesInvoice();
  });
  // cache clear
  Wethod.reqres.setHandler('bubbleInvoice:cache:clear', function () {
    return API.bubbleInvoiceCacheClear();
  });
});
