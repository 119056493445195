const React = require('react');

module.exports = class SummaryAreaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.areaSummaryReference = null;
    this.onScroll = this.onScroll.bind(this);
    this.setAreaSummaryRef = this.setAreaSummaryRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollingComponentAmount !== this.props.scrollingComponentAmount) {
      if (this.props.horizontalScroll) {
        this.areaSummaryReference.scrollTo({ left: this.props.scrollingComponentAmount });
      }
    }
  }

  onScroll() {
    this.props
      .setScrollingComponentScrollAmount(this.areaSummaryReference.scrollLeft);
  }

  setAreaSummaryRef(el) {
    this.areaSummaryReference = el;
  }

  render() {
    if (this.props.horizontalScroll) {
      return (
        <div
          data-container-id="summary-area"
          className={`wethod-budget-summary__area-container ${this.props.className}`}
          ref={this.setAreaSummaryRef}
          onScroll={this.onScroll}>
          {this.props.children}
        </div>
      );
    }
    return (
      <div className={`wethod-budget-summary__area-container ${this.props.className}`}>{this.props.children}</div>
    );
  }
};
