'use strict';

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette, $, _) {
  this.getPermission = function (label) {
    switch (label) {
      case 'viewModule':
        return !!((ReportApp.authorization.get('view') || ReportApp.authorization.get('view_details')));
      default:
        if (!_.isUndefined(ReportApp.authorization.get(label))) {
          return ReportApp.authorization.get(label);
        }
        // console.log('ReportApp.authorization, label -> undefined');
        return false;
    }
  };

  this.getTimesheetPermission = function (label) {
    if (!_.isUndefined(ReportApp.timesheetAuthorization.get(label))) {
      return ReportApp.timesheetAuthorization.get(label);
    }
    return false;
  };
});
