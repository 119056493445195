const React = require('react');
const _ = require('underscore');
const Select = require('../../../../../../../common/react/inputs/Select/BasicSelect/BasicSelect.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const ProjectTypeCell = ({
  value, availableValues, onChange, name, label, readOnly, style, metadataService,
}) => {
  function handleChange(e) {
    const selected = availableValues.filter((type) => type.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues.map((type) => (
    <SelectItem key={type.id} value={type.id}>
      {_.unescape(type.name)}
    </SelectItem>
  ));

  return (
    <TableCell className="pipeline-item__project-type" style={style}>
      <InputMetadataManager metadataService={metadataService} name={name} canEdit={!readOnly}>
        <Select
          value={value}
          onChange={handleChange}
          label={label}>
          {getOptions()}
        </Select>
      </InputMetadataManager>
    </TableCell>
  );
};

module.exports = ProjectTypeCell;
