const React = require('react');
const FileManagerContentError = require('./FileManagerContentError.react');

const MissingProjectFolderMessage = () => (
  <FileManagerContentError title="Cannot find Google Drive folder">
    <div>
      This project was created before enabling the integration or the folder was moved.
      <br />
      Don&apos;t worry, just sync it!
    </div>
  </FileManagerContentError>
);

module.exports = MissingProjectFolderMessage;
