const React = require('react');

const EmployeeTagsTableHeader = () => (
  <div className="autoClip clear">
    <ul className="companyLabelRow">
      <li className="col_4 left">
        <span className="upperCase">Metadata Category</span>
      </li>
      <li className="col_4 left">
        <span className="upperCase">Values</span>
      </li>
      <li className="clear" />
    </ul>
  </div>
);

module.exports = EmployeeTagsTableHeader;
