/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');

module.exports = class Loading extends React.Component {
  render() {
    return (
      <div className="project-canvas-boards__loading">
        <span className="wethod-widget__empty-message">
          <Loader />
        </span>
      </div>
    );
  }
};
