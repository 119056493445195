const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');
const FiscalYearService = require('../../../../../../services/FiscalYearService');

module.exports = Backbone.Model.extend({
  initialize(options = {}) {
    const fiscalYearService = new FiscalYearService();
    const year = fiscalYearService.getShiftedYear(moment().year());
    let from = options.from
      ? moment(options.from)
      : moment(fiscalYearService.getFiscalYearStartDate(year));

    let to = options.to
      ? moment(options.to)
      : moment(fiscalYearService.getFiscalYearEndDate(year));
    const quarters = from.diff(to, 'years') === 0;

    from = from.format('YYYY');
    to = to.format('YYYY');
    this.urlRoot = `${APIURL}/hr-insights/chargeability?from=${from}&to=${to}&quarter=${quarters}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.attributes);
    });

    return defer.promise();
  },
});
