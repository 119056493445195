const React = require('react');
const HeaderDays = require('./HeaderDays.react');

class HeaderWeek extends React.Component {
  getCurrentMonth() {
    return this.monday.format('MMM');
  }

  getNextMonth() {
    return this.friday.format('MMM');
  }

  /**
   * Checks if there's a month's change during this week.
   * @returns {boolean}
   */
  isEdgeWeek() {
    const mondayMonth = this.monday.format('M');
    const fridayMonth = this.friday.format('M');

    return mondayMonth !== fridayMonth;
  }

  /**
   * Checks if a month's change happened during the last weekend.
   * @returns {boolean}
   */
  hasBeenEdgeWeekend() {
    const lastFriday = (this.monday.clone()).subtract(3, 'days');
    const mondayMonth = this.monday.format('M');
    const lastFridayMonth = lastFriday.format('M');

    return mondayMonth !== lastFridayMonth;
  }

  /**
   * Checks if a month's change will happen during the next weekend.
   * @returns {boolean}
   */
  willBeEdgeWeekend() {
    const nextMonday = (this.friday.clone()).add(3, 'days');
    const fridayMonth = this.friday.format('M');
    const nextMondayMonth = nextMonday.format('M');

    return fridayMonth !== nextMondayMonth;
  }

  render() {
    this.monday = moment(this.props.days[0].date, 'YYYY-MM-DD');
    this.friday = moment(this.props.days[this.props.days.length - 1].date, 'YYYY-MM-DD');

    if (this.isEdgeWeek()) {
      this.state = {
        currentMonth: this.getCurrentMonth(),
        nextMonth: this.getNextMonth(),
      };
    } else if (this.hasBeenEdgeWeekend()) {
      this.state = {
        currentMonth: this.getCurrentMonth(),
        nextMonth: '',
      };
    } else if (this.willBeEdgeWeekend()) {
      this.state = {
        currentMonth: '',
        nextMonth: this.getCurrentMonth(),
      };
    } else {
      this.state = {
        currentMonth: '',
        nextMonth: '',
      };
    }

    return (
      <div className="header__week">
        <div className="month-name--left">{this.state.currentMonth}</div>
        <div className="month-name--right">{this.state.nextMonth}</div>
        <HeaderDays days={this.props.days} />
      </div>
    );
  }
}

module.exports = HeaderWeek;
