const React = require('react');
const PropTypes = require('prop-types');

class FormValidator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.updateErrors = this.updateErrors.bind(this);
    this.getFirstErrorMessage = this.getFirstErrorMessage.bind(this);
  }

  /**
   * Returns errors for the given name.
   * @param name
   * @returns {{validatorName,message}|null}
   */
  getErrors(name) {
    return this.state[name];
  }

  /**
   * Returns the first error's message for the given name.
   * @param name
   * @returns {string}
   */
  getFirstErrorMessage(name) {
    return this.hasError(name) ? this.getErrors(name)[0].message : '';
  }

  /**
   * Returns a key-value map where key is the a input related name and value is the messasge related
   * to the first error for that input.
   * This is useful for the children form in order to access errors.
   * @returns {{}}
   */
  getMessagesMap() {
    const errors = {};
    Object.keys(this.state).forEach((name) => {
      const message = this.getFirstErrorMessage(name);
      errors[name] = message;
    });
    return errors;
  }

  /**
   * Returns true if at least an errors exists for the given name.
   * @param name
   * @returns {boolean}
   */
  hasError(name) {
    const errors = this.state[name];
    return errors !== undefined && errors.length > 0;
  }

  updateErrors(name, errors) {
    this.setState({ [name]: errors });
  }

  /**
   * Return true if there are no errors.
   * @returns {boolean}
   */
  isValid() {
    let valid = true;

    const names = Object.keys(this.state);
    for (let i = 0; i < names.length && valid; i++) {
      valid = !this.hasError(names[i]);
    }
    return valid;
  }

  render() {
    return React
      .cloneElement(this.props.children, {
        updateErrors: this.updateErrors,
        errors: this.getMessagesMap(),
        isValid: this.isValid(),
      });
  }
}

FormValidator.propTypes = {
  /** Form that needs validation * */
  children: PropTypes.node.isRequired,
};

module.exports = FormValidator;
