/**
 * Parse a response and return the 'data' attributes or an empty array if response code is 404
 *
 * @param response
 */
const Parser = {
  parse(response) {
    const data = (response.data) ? response.data : response;
    switch (response.code) {
      case 404:
        return [];
      default:
        return data;
    }
  },
};

module.exports = Parser;
