/* eslint-disable react/prefer-stateless-function,react/prop-types,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
const React = require('react');

module.exports = class Overlay extends React.Component {
  render() {
    if (this.props.show) {
      return (
        <div className="app-nav__overlay"
          onClick={this.props.onClick} />
      );
    }
    return null;
  }
};
