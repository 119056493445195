'use strict';

Wethod.module('HeaderApp', function (HeaderApp) {
  this.startWithParent = false;

  var API = {
    showHeader: function () {
      HeaderApp.Header.Controller.showHeader();
    },
  };

  HeaderApp.on('start', function () {
    API.showHeader();
  });
});
