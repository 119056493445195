/* eslint-disable no-shadow */
/**
 *
 * @param key
 * @param items
 * @returns {Array} items filtered by key
 */
module.exports = (key, items) => {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  const found = (key, value) => {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  };

  const isInName = (key, template) => found(key, template.name);

  const isInCreatorName = (key, template) => found(key, template.created_by.name);

  const isInCreatorSurname = (key, template) => found(key, template.created_by.surname);

  const isInDescription = (key, template) => found(key, template.description);

  return items.filter((item) => {
    const foundInName = isInName(key, item);
    const foundInCreatorName = isInCreatorName(key, item);
    const foundInCreatorSurname = isInCreatorSurname(key, item);
    const foundInDescription = isInDescription(key, item);

    return foundInName || foundInCreatorName || foundInCreatorSurname || foundInDescription;
  });
};
