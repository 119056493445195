const React = require('react');
const PropTypes = require('prop-types');
const Avatars = require('../../../AvatarBox/AvatarBox.react');
const Menu = require('./CollaboratorsMenu.react');
const Tooltip = require('../../../TooltipFixed/Tooltip.react');

require('./style.scss');

class Collaborators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collaborators: [],
      openMenu: false,
    };

    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.setRef = this.setRef.bind(this);
    this.ref = null;
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    socket.emit('join', this.props.sectionId);
    socket.on('observers-change', this.updateCollaborators.bind(this));
  }

  componentWillUnmount() {
    socket.emit('leave', this.props.sectionId);
  }

  onCloseMenu() {
    this.setState({ openMenu: false });
  }

  onClick() {
    this.setState({ openMenu: true });
  }

  setRef(el) {
    this.ref = el;
  }

  getAvatars() {
    return this.state.collaborators.map((avatar) => ({ ...avatar, name: `${avatar.name} ${avatar.surname}` }));
  }

  updateCollaborators(users) {
    const collaborators = users.filter((user) => user.id !== Wethod.userInfo.attributes.usr_id);
    this.setState({ collaborators });
  }

  render() {
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <div className="wethod-collaborators" onClick={this.onClick}>
        <Tooltip label="On this page">
          <div ref={this.setRef}>
            <Avatars avatars={this.getAvatars()} />
          </div>
        </Tooltip>
        <Menu open={this.state.openMenu}
          onClose={this.onCloseMenu}
          collaborators={this.state.collaborators}
          anchorEl={this.ref} />
      </div>
    );
  }
}

Collaborators.propTypes = {
  sectionId: PropTypes.string.isRequired,
};

module.exports = Collaborators;
