/* eslint-disable react/prop-types */
const React = require('react');

module.exports = class NoteModalBody extends React.Component {
  handleChange(e) {
    this.props.updateNote(e.target.value);
  }

  render() {
    const textClass = this.props.textareaClass ? this.props.textareaClass : 'timesheet-note__input';
    return (
      <textarea className={textClass}
        value={this.props.note}
        onChange={this.handleChange.bind(this)} />
    );
  }
};
