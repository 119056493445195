const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../modal2/Modal.react');
const Button = require('../Button/RoundedButton.react');

const CannotDownloadModal = ({ onCancelClick, data, className }) => {
  function getBody() {
    switch (data) {
      case 'not-found':
        return <span>Seems like this file <b>does not exist anymore</b>.</span>;
      default:
        return <span>Something went wrong, please retry or refresh the page.</span>;
    }
  }

  function getTitle() {
    return data === 'not-found' ? 'Cannot download' : 'Oops';
  }

  const style = className ? `wethod-modal2--medium ${className}` : 'wethod-modal2--medium';

  return (
    <Modal title={getTitle()} onCancelClick={onCancelClick} className={style}>
      {getBody()}
      <div className="wethod-modal2__actions">
        <Button onClick={onCancelClick}>Ok</Button>
      </div>
    </Modal>
  );
};

CannotDownloadModal.propTypes = {
  /**
   * Function called to close the modal: both for 'Close' button and 'X' button
   * @see Modal
   */
  onCancelClick: PropTypes.func.isRequired,
  /**
   * Error used to render the modal, represented by a string.
   * Accepted errors are 'not-found', 'server-error' or generic (no string given)
   */
  data: PropTypes.oneOf(['not-found', 'server-error']),
  /**
   * Class given to the modal.
   */
  className: PropTypes.string,
};

CannotDownloadModal.defaultProps = {
  data: null,
  className: null,
};

module.exports = CannotDownloadModal;
