/* eslint-disable no-shadow */
const canPerform = require('../services/canPerform');

const ProjectSelector = ({
  children, days, person, showMissingProjectModal, selectProject, showModal, projectSelected,
  recurringModeEnabled, plannedProjects,
}) => {
  const showProjectDetailsModal = (projects, from, to) => {
    showModal('plan-details', {
      projects,
      person,
      from,
      to,
    });
  };

  const showRepeatModal = (projects, from, to, days) => {
    showModal('repeat', {
      projects,
      person,
      from,
      to,
      days,
    });
  };

  /**
   * Return all the projects with at least 1 hour of plan in the given days.
   * @param days
   * @returns {*[]}
   */
  const getProjects = () => {
    const projectsMap = {};
    const projects = [];

    for (let i = 0; i < days.length; i++) {
      for (let j = 0; j < days[i].plans.length; j++) {
        const plan = days[i].plans[j];
        // We combine project id and allocation status in order to distinguish pending and approved allocation
        const projectKey = `${plan.project_id}${plan.is_pending ? '_pending' : ''}`;

        if (!projectsMap[projectKey]) {
          projects.push({
            id: plan.project_id,
            amount: plan.amount,
            is_pending: plan.is_pending,
          });
          projectsMap[projectKey] = { index: projects.length - 1 };
        } else {
          projects[projectsMap[projectKey].index].amount += plan.amount;
        }
      }
    }

    return projects;
  };

  /**
   * If recurring mode is enabled:
   *  - If selection's empty: show "missing-project" modal
   *  - Show "repeat" modal otherwise
   *
   * If recurring mode is NOT enabled:
   *  - If selection's empty: show "missing-project" modal
   *  - If selection contains just one project:
   *    * If user can edit project, select it
   *    * Otherwise show "plan-details" modal so the user see project's details
   *  - If selection contains more than one project, show "plan-details" modal so the user can decide which project to select
   */
  const select = () => {
    if (!projectSelected) {
      const projects = getProjects();
      if (!projects.length) {
        showMissingProjectModal();
      } else if (recurringModeEnabled) {
        showRepeatModal(projects, days[0].date, days[days.length - 1].date, days);
      } else if (projects.length === 1 && canPerform('modify', plannedProjects[projects[0].id])) {
        selectProject(projects[0].id);
      } else {
        showProjectDetailsModal(projects, days[0].date, days[days.length - 1].date);
      }
    }
  };

  return children({ selectProject: select });
};

module.exports = ProjectSelector;
