module.exports.LOAD_MORE_TRAVELS_REQUEST = 'LOAD_MORE_TRAVELS_REQUEST';
module.exports.LOAD_MORE_TRAVELS_SUCCESS = 'LOAD_MORE_TRAVELS_SUCCESS';

module.exports.CREATE_TRAVEL_REQUEST = 'CREATE_TRAVEL_REQUEST';
module.exports.CREATE_TRAVEL_SUCCESS = 'CREATE_TRAVEL_SUCCESS';

module.exports.UPDATE_TRAVEL_REQUEST = 'UPDATE_TRAVEL_REQUEST';
module.exports.UPDATE_TRAVEL_SUCCESS = 'UPDATE_TRAVEL_SUCCESS';
module.exports.UPDATE_TRAVEL_FAILURE = 'UPDATE_TRAVEL_FAILURE';

module.exports.DELETE_TRAVEL_REQUEST = 'DELETE_TRAVEL_REQUEST';
module.exports.DELETE_TRAVEL_SUCCESS = 'DELETE_TRAVEL_SUCCESS';
module.exports.DELETE_TRAVEL_FAILURE = 'DELETE_TRAVEL_FAILURE';

module.exports.FILTER_TRAVELS_REQUEST = 'FILTER_TRAVELS_REQUEST';
module.exports.FILTER_TRAVELS_SUCCESS = 'FILTER_TRAVELS_SUCCESS';

module.exports.GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
module.exports.GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';

module.exports.ADD_FAVORITE_REQUEST = ' ADD_FAVORITE_REQUEST';
module.exports.ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';

module.exports.DELETE_FAVORITE_REQUEST = 'DELETE_FAVORITE_REQUEST';
module.exports.DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';

module.exports.GET_TRAVEL_OFFICES_REQUEST = ' GET_TRAVEL_OFFICES_REQUEST';
module.exports.GET_TRAVEL_OFFICES_SUCCESS = ' GET_TRAVEL_OFFICES_SUCCESS';

module.exports.EXPORT_REQUEST = ' EXPORT_REQUEST';
module.exports.EXPORT_SUCCESS = ' EXPORT_SUCCESS';

module.exports.FILTER_FAVORITES = 'FILTER_FAVORITES';

module.exports.UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
module.exports.DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';

module.exports.SHOW_UPLOAD_ERROR_MODAL = 'SHOW_UPLOAD_ERROR_MODAL';
module.exports.SHOW_DOWNLOAD_ERROR_MODAL = 'SHOW_DOWNLOAD_ERROR_MODAL';
module.exports.SHOW_TRAVEL_OFFICE_MODAL = 'SHOW_TRAVEL_OFFICE_MODAL';
module.exports.SHOW_EXPORT_MODAL = 'SHOW_EXPORT_MODAL';
module.exports.SHOW_CATEGORY_DOWNLOAD_MODAL = 'SHOW_CATEGORY_DOWNLOAD_MODAL';
module.exports.SHOW_SERVER_ERROR_MODAL = 'SHOW_SERVER_ERROR_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_SIDEBAR = 'SHOW_SIDEBAR';
module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
