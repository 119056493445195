/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Empty extends React.Component {
  render() {
    return (
      <div className="wethod-widget__empty">
        <span className="wethod-widget__empty-message">
          No invoices to send, do you want to <a href="/#finance/invoice">add one</a>?
        </span>
      </div>
    );
  }
};
