const React = require('react');
const _ = require('underscore');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const InputValidator = require('../../../../../../../common/react/InputValidator/InputValidator.react');
const SelectItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItemColorful.react');
const RoundedSelect = require('../../../../../../../common/react/inputs/Select/ColorfulSelect/RoundedColorfulSelect/RoundedColorfulSelect.react');
const InputMetadataManager = require('../../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');

const JobOrderCategoryCell = ({
  updateErrors, availableValues, readOnly, value, color, onChange,
  error, name, label, style, disabled, metadataService,
}) => {
  const options = availableValues.map((joc) => (
    <SelectItem key={joc.id}
      value={joc.id}
      color={joc.color}>
      {_.unescape(joc.name)}
    </SelectItem>
  ));

  function handleChange(e) {
    const selected = availableValues.filter((joc) => joc.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  return (
    <TableCell className="pipeline-item__joc" style={style}>
      <InputValidator updateErrors={updateErrors} constraints={['required']}>
        <InputMetadataManager metadataService={metadataService} name={name} canEdit={!readOnly}>
          <RoundedSelect
            label={label}
            disabled={disabled}
            value={value}
            color={color}
            onChange={handleChange}
            errorText={error}>
            {options}
          </RoundedSelect>
        </InputMetadataManager>
      </InputValidator>
    </TableCell>
  );
};

module.exports = JobOrderCategoryCell;
