const React = require('react');
const PropTypes = require('prop-types');
const SelectBase = require('../Select.react');
const Button = require('./RoundedSelectButton.react');

const RoundedSelect = ({ children, ...rest }) => (
  <SelectBase button={Button} {...rest}>
    {children}
  </SelectBase>
);
RoundedSelect.defaultProps = {
  className: '',
};

RoundedSelect.propTypes = {
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

module.exports = RoundedSelect;
