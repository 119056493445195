const React = require('react');
const WarningAccordion = require('../WarningAccordion.react');
const OutstandingProjects = require('../../../containers/dashboard/outstanding-projects/OutstandingProjects');

const OutstandingProjectsAccordion = ({ counter }) => (
  <WarningAccordion
    title="Won projects at 90% of probability and opportunities"
    subtitle="Changes to data can be suspended only if it belongs to projects at 100% of probability and lost opportunities at 0% of probability."
    counter={counter}>
    <OutstandingProjects />
  </WarningAccordion>
);

module.exports = OutstandingProjectsAccordion;
