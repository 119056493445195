const Area = require('./Area');
const AccountArea = require('./AccountArea');

/**
 *
 * @param area
 * @param userPermissions
 * @return {Section|*}
 * @constructor
 */
const AreaFactory = (area, userPermissions) => {
  if (area.label === 'Account') {
    return new AccountArea(area, userPermissions);
  }
  return new Area(area, userPermissions);
};

module.exports = AreaFactory;
