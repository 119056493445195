const React = require('react');

module.exports = class ProjectTotal extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value;
  }

  getTotalProductionStyle(value) {
    if (!this.props.isTotalProductionAccettable(value)) {
      return {
        color: '#DB4D69',
      };
    }
    return null;
  }

  getAmount() {
    return this.props.value > 900 ? <span>&gt;900</span> : this.props.value;
  }

  getProductionValue() {
    return (this.props.totalProductionValue * this.props.value) / 100;
  }

  getValue() {
    const value = this.getProductionValue();
    if (value) return numeral(value).format('0,0');
    return '';
  }

  render() {
    return (
      <span className="production-plan-project__total" style={this.getTotalProductionStyle(this.props.value)}>
        <span>
          {this.getAmount()}
          {' '}
          %
        </span>
        <span
          className="production-plan-project__total production-plan-project__total-value--production">
          {this.getValue()}
        </span>
      </span>
    );
  }
};
