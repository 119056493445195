const React = require('react');
const FullView = require('./FullPlan.react');
const CompactView = require('./CompactPlan.react');

const Plan = ({ compact, plans, invoices, start }) => (
  compact
    ? <CompactView plans={plans} invoices={invoices} />
    : <FullView plans={plans} invoices={invoices} start={start} />);

module.exports = Plan;
