const { connect } = require('react-redux');
const Component = require('../../components/job-title/LevelEventsManager.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  addLevel: (level) => dispatch(actions.addLevel(level)),
  deleteLevel: (id) => dispatch(actions.deleteLevel(id)),
  updateLevel: (level) => dispatch(actions.updateLevel(level)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
