const React = require('react');
const Header = require('../SubsectionHeader.react');
// const Modal = require('../../containers/ModalController');
const Actions = require('../../containers/work-hour-capacity/WorkHourCapacityActions');
const List = require('../../containers/work-hour-capacity/TableWorkHourCapacity');

const WorkHourCapacity = () => (
  <div className="bottomSpace">
    <Header subtitle="Employee Capacity Groups"
      id="work-hour-capacity"
      description="Define a capacity group for team members that has a specific maximum amount of working hours per day of a week." />
    <Actions />
    <List />
    {/* <Modal /> */}
  </div>
);

module.exports = WorkHourCapacity;
