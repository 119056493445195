const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const NV = require('../../../../common/components/NumericValue.react');
const List = require('../../../../common/components/List.react');
const Link = require('../../../../common/components/Link.react');
const NS = require('../../NumerableSentence.react');

module.exports = ({ totalProjectsAmount, bestProjects }) => {
  const getProjectTypeName = (projectType) => (projectType ? (
    <span>
      (
      {_.unescape(projectType.name)}
      )
    </span>
  ) : null);

  const getProject = (project) => (
    <span key={project.project.id}>
      <Link
        href={`#project/${project.project.id}/report`}>
        {_.unescape(project.project.name)}
      </Link>
      {' '}
      {getProjectTypeName(project.project_type)}
      {' '}
      for
      {' '}
      {_.unescape(project.client.name)}
      {' '}
      with
      {' '}
      <NV
        value={project.production} />
    </span>
  );

  const getProjects = () => {
    const projects = bestProjects.map((project) => getProject(project));
    return <List list={bestProjects} terminator=".">{projects}</List>;
  };

  const getTopProjects = () => {
    if (bestProjects.length === 1) {
      return (
        <span>
          The project that produced the most is {getProjects()}
        </span>
      );
    }
    if (bestProjects.length > 1) {
      return (
        <span>
          The top <NV value={bestProjects.length} /> projects that produced the most are:
          {' '}
          {getProjects()}
        </span>
      );
    }
    return null;
  };

  return (
    <Paragraph>
      Overall, last month we worked on <NV value={totalProjectsAmount} />
      {' '}
      <NS
        quantity={totalProjectsAmount}>
        projects
      </NS>.
      {' '}
      {getTopProjects()}
    </Paragraph>
  );
};
