const request = require('./Request');

const BudgetArea = {
  getForProject(id) {
    return Wethod.request('get:finance:order:project:areas', id);
  },
  create(attributes, projectId) {
    return request({
      method: 'post',
      url: `/br/project/${projectId}/budget-area`,
      data: attributes,
    });
  },
  update(id, attributes) {
    return request({
      method: 'patch',
      url: `/br/budget-area/${id}`,
      data: attributes,
    });
  },
};

module.exports = BudgetArea;
