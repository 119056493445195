const { connect } = require('react-redux');
const Component = require('../components/KudosTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'kudos').length > 0,
  timeFilter: state.timeFilter,
  placeholder: 'Search PM',
  criteria: state.criteria,
  selectedCriterion: state.selectedPMCriterion,
  employees: state.filteredPM,
  role: 'as_pm',
  size: state.loadPM.size,
  page: state.loadPM.page,
  sortBy: state.sortByPM,
});

const mapDispatchToProps = (dispatch) => ({
  changeCriterion: (criterion) => dispatch(actions.updatePMCriterion(criterion)),
  loadEmployees: (size, page) => dispatch(actions.loadPM(size, page)),
  filter: (keyword) => dispatch(actions.filterPM(keyword)),
  sort: (col, order) => dispatch(actions.sortPM(col, order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
