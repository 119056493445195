const React = require('react');
const ForProjects = require('../../containers/ForFocusedProject');
const ForOpportunities = require('../../containers/ForFocusedOpportunity');
const OpportunityDescription = require('../opportunities/sidebar/OpportunitySidebarDescription.react');
const ProjectDescription = require('../projects/sidebar/ProjectSidebarDescription.react');

const ItemDescription = ({ creation }) => (creation ? (
  <div className="pipeline__sidebar-description pipeline__sidebar-description--spaced">
    <ForProjects><ProjectDescription creation={creation} /></ForProjects>
    <ForOpportunities><OpportunityDescription creation={creation} /></ForOpportunities>
  </div>
) : null);

module.exports = ItemDescription;
