/* eslint-disable no-param-reassign */
const React = require('react');
const InputWrapper = require('../../../../../../../../common/react/material-input/SidebarWrapperEditable.react');
const DatePicker = require('../../../../../../../../common/react/DatePicker.react');

const DateEndSelect = ({
  value, onChange, name, disabled, disableWeekend, disableBefore,
}) => {
  const className = disabled ? 'planning-people-repeat-date-end disabled' : 'planning-people-repeat-date-end';

  disableBefore = moment(disableBefore).isAfter(moment()) ? moment(disableBefore).toDate() : moment().add(1, 'day').toDate();

  return (
    <div className={className}>
      <InputWrapper placeholder="End" icon={<span />}>
        <DatePicker
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled}
          disableWeekend={disableWeekend}
          disableBefore={disableBefore} />
      </InputWrapper>
    </div>
  );
};

module.exports = DateEndSelect;
