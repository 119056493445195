const React = require('react');
const DaysCell = require('../Cell/ManDaysCell.react');

const AreaLevel = ({ children, previousValue }) => (
  <DaysCell previousValue={previousValue}>
    {children}
  </DaysCell>
);

module.exports = AreaLevel;
