const { connect } = require('react-redux');
const Component = require('../../../../components/sections/Economics/ProfitLossChart/ProfitLossChart.react');

const thousandsValue = (value) => Math.round(value / 1000);

const mapStateToProps = (state) => ({
  month: state.month,
  revenue: thousandsValue(state.month_revenue),
  externalCosts: thousandsValue(state.month_external_cost),
  externalCostsOnRevenues: state.month_external_cost_perc, // NULLABLE
  externalCostsOnForecast: state.external_cost_target_perc, // NULLABLE
  grossMargin: thousandsValue(state.month_gross_margin),
  grossMarginOnRevenues: state.month_gross_margin_perc, // NULLABLE
  grossMarginOnForecast: state.gross_margin_target_perc, // NULLABLE
  payroll: thousandsValue(state.month_payroll),
  payrollCostsOnRevenues: state.month_payroll_perc, // NULLABLE
  payrollCostsOnForecast: state.payroll_target_perc, // NULLABLE
  netMargin: thousandsValue(state.month_net_margin),
  netMarginOnRevenues: state.month_net_margin_perc, // NULLABLE
  netMarginOnForecast: state.net_margin_target_perc, // NULLABLE
  ga: thousandsValue(state.month_ga),
  gaOnRevenues: state.month_ga_perc, // NULLABLE
  gaOnForecast: state.ga_target_perc, // NULLABLE
  marketing: thousandsValue(state.month_marketing),
  marketingOnRevenues: state.month_marketing_perc, // NULLABLE
  marketingOnForecast: state.marketing_target_perc, // NULLABLE
  ebitda: thousandsValue(state.month_ebitda),
  ebitdaOnRevenues: state.month_ebitda_perc, // NULLABLE
  ebitdaOnForecast: state.ebitda_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
