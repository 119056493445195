'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var AlertsEngineStore = require('../../../apps/core/modules/settings/alerts-engine/store');
var AlertsEngineReducer = require('../../../apps/core/modules/settings/alerts-engine/reducer');
var AlertsEngine = require('../../../apps/core/modules/settings/alerts-engine/index');

Wethod.module('SettingsApp.AlertsManager', function (AlertsManager, Wethod, Backbone, Marionette, $, _) {
  AlertsManager.Controller = {
    showAlertsManager: function () {
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest)
        .done(function (authorizationResponse) {
          if (!_.isUndefined(authorizationResponse)) {
            Wethod.SettingsApp.authorization = authorizationResponse;

            if (Wethod.SettingsApp.getPermission('alerts_engine')) {
              // Initialize views
              AlertsManager.structureTemplate = new AlertsManager.StructureLayout();
              AlertsManager.structureTemplate.render();

              var loadingTemplate = new AlertsManager.LoadingMoreDataTemplateView();
              AlertsManager.structureTemplate.body.show(loadingTemplate);

              var alertsRequest = Wethod.request('get:settings:alerts');
              var prioritiesRequest = Wethod.request('get:settings:alerts:priorities');
              $.when(alertsRequest, prioritiesRequest)
                .done(function (alertsResponse, prioritiesResponse) {
                  var reducerWrapper = new AlertsEngineReducer({
                    alerts: alertsResponse,
                    priorities: prioritiesResponse,
                  });

                  var store = AlertsEngineStore(reducerWrapper.reduxReducer);
                  var element = React.createElement(AlertsEngine, { store: store });
                  var container = document.getElementById('alertsEngineRoot');
                  if (container !== null) ReactDOM.render(element, container);
                });
            } else {
              loadingView.destroy();
              var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
              Wethod.regions.body.show(permissionDeniedView);
            }
          }
        });
    },
  };
});
