/**
 * Filter items on a given key.
 * The key must be contained in some attributes for the item to be shown.
 * The item must also contain the given criterion.
 *
 * @param key
 * @param items
 * @param criterionId
 * @param role // Whether to filter employees based on pm or team member criteria
 * @returns {Array} items filtered by criterion
 */
module.exports = (key, items, criterionId, role) => {
  /**
   * Returns true if value contains key.
   *
   * @param keyword
   * @param value
   * @returns {boolean}
   */
  const found = (keyword, value) => {
    if (value) return value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    return false;
  };

  const isInName = (keyword, name) => found(keyword, _.unescape(name));

  const isInSurname = (keyword, surname) => found(keyword, _.unescape(surname));

  // Returns true if the item has data for the selected criterion.
  const isInCriterion = (selectedCriterion, employeeCriteria) => {
    const selectedCriterionFound = employeeCriteria
      .filter((criterion) => criterion.criterion_id === selectedCriterion);
    return selectedCriterionFound && selectedCriterionFound.length;
  };

  return items.filter((item) => ((isInName(key, item.name) || isInSurname(key, item.surname))
    && isInCriterion(criterionId, item[role])));
};
