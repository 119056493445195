const React = require('react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');
const AvailableSwitcher = require('../containers/AvailableSwitcher');
const NewButton = require('../containers/NewButton');

const Actions = ({ canEdit }) => (
  <div className="wethod-section-actions">
    <div className="wethod-section-actions__left">
      <AvailableSwitcher />
    </div>
    <div className="wethod-section-actions__right">
      <ShowIf condition={canEdit}>
        <NewButton />
      </ShowIf>
    </div>
  </div>
);

module.exports = Actions;
