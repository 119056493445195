const { connect } = require('react-redux');
const Component = require('../../../components/modal/TransferHoursModal/TransferHoursModal.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.some((key) => key === 'transfer-hours'),
  hasSelectedTimesheets: state.transferData.updatedTimesheets
    .some((timesheet) => timesheet.selected),
  updatedTimesheets: state.transferData.updatedTimesheets,
  filters: state.filters, // The filters are the ones used in the timesheets table
  selectAll: state.transferData.selectAll,
  frozenUpTo: state.frozenUpTo,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(actions.closeTransferHoursModal()),
  onSave: (selectAll, project, date, timesheets, filters) => dispatch(actions
    .transferHours(selectAll, project, date, timesheets, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
