const React = require('react');
const NS = require('../../common/components/NumerableSentence.react');

const NumerableSentence = ({ children, quantity }) => {
  const alternatives = {
    projects__1: 'project',
    weeks__1: 'week',
  };

  return <NS alternatives={alternatives} quantity={quantity}>{children}</NS>;
};

module.exports = NumerableSentence;
