const React = require('react');
const Person = require('./Person.react');

const People = ({ people, selectedProject }) => {
  const peopleFragment = people
    .map((person) => (
      <Person key={person.id}
        {...person}
        selectedProject={selectedProject} />
    ));

  return (
    <div className="planning-calendar__people">
      <div className="planning-calendar__people-content">
        {peopleFragment}
      </div>
    </div>
  );
};

module.exports = People;
