const React = require('react');
const ShowIf = require('../../../../../common/react/ShowIf/ShowIf.react');
const AvailabilityFilter = require('../containers/AvailabilityFilter');
const NewButton = require('../containers/NewButton');

const Actions = ({ permissions }) => (
  <div className="wethod-section-actions">
    <div className="wethod-section-actions__left">
      <AvailabilityFilter />
    </div>
    <div className="wethod-section-actions__right">
      <ShowIf condition={permissions.edit}>
        <NewButton />
      </ShowIf>
    </div>
  </div>
);

module.exports = Actions;
