const HTTPService = require('../../../../../services/HTTPService');
const ParserService = require('../../../../../services/ParserService');

module.exports = Backbone.Collection.extend({
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  url() {
    return `${APIURL}/travel/contact`;
  },
  getList() {
    const defer = $.Deferred();

    $.when(this.load()).done((model) => {
      defer.resolve(model.toJSON());
    });

    return defer.promise();
  },
});
