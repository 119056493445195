module.exports.SHOW_MODAL = 'SHOW_MODAL';
module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SAVE_PLAN_REQUEST = 'SAVE_PLAN_REQUEST';
module.exports.SAVE_PLAN_SUCCESS = 'SAVE_PLAN_SUCCESS';

module.exports.UPDATE_PLAN = 'UPDATE_PLAN';

module.exports.CHANGE_STATUS = 'CHANGE_STATUS';

module.exports.CHANGE_VIEW = 'CHANGE_VIEW';

module.exports.DELEGATE_PROJECT_REQUEST = 'DELEGATE_PROJECT_REQUEST';
module.exports.DELEGATE_PROJECT_SUCCESS = 'DELEGATE_PROJECT_SUCCESS';
module.exports.DELEGATE_PROJECT_FAILURE = 'DELEGATE_PROJECT_FAILURE';

module.exports.REVOKE_DELEGATION_REQUEST = 'REVOKE_DELEGATION_REQUEST';
module.exports.REVOKE_DELEGATION_SUCCESS = 'REVOKE_DELEGATION_SUCCESS';
module.exports.REVOKE_DELEGATION_FAILURE = 'REVOKE_DELEGATION_FAILURE';

module.exports.GET_INVOICE_PLAN_REQUEST = 'GET_INVOICE_PLAN_REQUEST';
module.exports.GET_INVOICE_PLAN_SUCCESS = 'GET_INVOICE_PLAN_SUCCESS';

module.exports.UPDATE_BILLING_GROUP = 'UPDATE_BILLING_GROUP';
