const { connect } = require('react-redux');
const Component = require('../../components/modals/AllocationConfirmDeleteModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'allocation-delete').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) => dispatch(actions.deleteAllocation(id)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
