const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const PickerBase = require('../DayPicker.react');
const Button = require('../../Select/OutlinedSelect/OutlinedSelectButton.react');

const OutlinedDayPicker = ({ ...rest }) => (
  <PickerBase button={Button} {...rest} />
);

OutlinedDayPicker.defaultProps = {
  prefix: undefined,
  suffix: undefined,
  helperText: undefined,
  errorText: undefined,
  label: null,
  required: undefined,
  id: undefined,
  validate: undefined,
  disabled: undefined,
  readOnly: undefined,
  onChange: null,
  placeholder: undefined,
  firstDayOfWeek: 1,
  className: '',
  initialMonth: undefined,
  value: undefined,
  disableBefore: undefined,
  disableBeforeEqual: undefined,
  disableWeekend: false,
  modifiers: {},
};

OutlinedDayPicker.propTypes = {
  /** Element to put before children * */
  prefix: PropTypes.node,
  /** Element to put after children * */
  suffix: PropTypes.node,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  name: PropTypes.string.isRequired,
  /**
   * Function to call when a new value is selected.
   * @param e {SyntheticEvent}
   * @param item {{}} selected item props
   */
  onChange: PropTypes.func,
  /** Element to use as label * */
  label: PropTypes.node,
  required: PropTypes.bool,
  id: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  /**
   * The first day of week to be shown in the calendar (sun=0, mon=1, .., sat=6)
   * default is set to 1 (monday).
   */
  firstDayOfWeek: PropTypes.number,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * The initial month shown in the calendar. If not specified, it's set to the month of the
   * startDate, or the current one.
   */
  initialMonth: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * The selected date shown in the calendar. If not specified, no date will be selected.
   */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * All days before this are disabled.
   */
  disableBefore: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * This day and all the ones before this are disabled.
   */
  disableBeforeEqual: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment),
    PropTypes.string]),
  /**
   * If set to true, Saturday and Sunday will be disabled in the calendar.
   */
  disableWeekend: PropTypes.bool,
  /**
   * The modifiers you want to use in style classes.
   * When a modifier matches a specific day, its day cells receives the modifier’s name as CSS
   * class.
   * @see react-day-picker
   */
  modifiers: PropTypes.shape({}),
};

module.exports = OutlinedDayPicker;
