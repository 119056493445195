'use strict';

Wethod.module('DashboardApp', function (DashboardApp) {
  this.startWithParent = false;

  var API = {
    showDashboard: function () {
      DashboardApp.Controller.showDashboard();
    },
  };

  DashboardApp.on('start', function () {
    dispatcher.trigger('set:active:tab', 'dashboard');
    API.showDashboard();
  });
});
