/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,eqeqeq */
const React = require('react');
const JobTitle = require('./JobTitle.react');

module.exports = class JobTitleList extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  toggleLabel(jobTitles) {
    return jobTitles.length ? <div className="dropdown__title">Job Titles</div> : null;
  }

  isSelected(jobTitle) {
    return Boolean(this.props.selectedJobTitles
      .find((selectedJobTitle) => selectedJobTitle.id == jobTitle.id));
  }

  onClick(jobTitle) {
    if (!this.isSelected(jobTitle)) {
      this.props.onSelect(jobTitle);
    } else {
      this.props.onRemove(jobTitle);
    }
  }

  render() {
    const jobTitles = this.props.jobTitles.map((jobTitle) => (
      <JobTitle
        active={this.isSelected(jobTitle)}
        key={jobTitle.id}
        jobTitle={jobTitle}
        onClick={this.onClick} />
    ));

    const style = !jobTitles.length ? { borderBottom: 'none' } : {};
    return (
      <div style={style} className="filter__block">
        {this.toggleLabel(jobTitles)}
        {jobTitles}
      </div>
    );
  }
};
