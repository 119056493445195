/* eslint-disable react/prop-types */
const React = require('react');
const Invoice = require('./Invoice.react');
const Model = require('../models/InvoiceWidget');
const Permissions = require('../models/InvoicePermissions');
const Loading = require('./Loading.react');
const Empty = require('./Empty.react');

module.exports = class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: true,
      permissions: null,
      invoices: [],
    };
  }

  componentDidMount() {
    if (this.props.config) {
      const model = new Model(this.props.config);
      const permissionsModel = new Permissions();

      $.when(permissionsModel.getModel(), model.getModel())
        .done((permissions, invoices) => this.setState({
          invoices,
          permissions,
          waiting: false,
        }));
    }
  }

  getBody() {
    if (!this.props.config) {
      return 'configure!';
    }
    if (this.state.waiting) {
      return <Loading />;
    }
    if (!this.state.invoices.length) {
      return <Empty />;
    }
    return this.state.invoices.map((invoice) => (
      <Invoice key={`${invoice.date}_${invoice.project.id}`}
        permissions={this.state.permissions}
        {...invoice} />
    ));
  }

  render() {
    const title = this.props.title ? this.props.title : 'Invoices';
    return (
      <div className="wethod-widget widget-invoices">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">{title}</span>
        </div>
        <div className="wethod-widget__body">{this.getBody()}</div>
      </div>
    );
  }
};
