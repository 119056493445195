const React = require('react');
const moment = require('moment');
const InputWrapper = require('../../../../../../../common/react/sidebar/SidebarInputWrapper.react');
const DateInput = require('../../../../../../../common/react/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const HoursSelect = require('./HoursSelect.react');
const Row = require('../../../../../../../common/react/sidebar/SidebarInputRow.react');
const DateService = require('../../../../../../../services/DateService');

const DailyHoursInput = ({
  day,
  readOnly,
  onChange,
  updateErrors,
  highlightWeekends,
}) => {
  const errorText = day.conflicting ? 'Conflict with allocation' : '';

  const dayName = moment(day.date).format('dddd');

  const rowClassName = DateService.isWeekend(day.date) && highlightWeekends ? 'allocation-request-sidebar__multiple-input-row--weekend-highlight' : '';

  return (
    <Row className={rowClassName}>
      <InputWrapper>
        <DateInput readOnly
          id={`day-${day.date}`}
          name={`day-${day.date}`}
          label={dayName}
          value={day.date}
          errorText={errorText} />
      </InputWrapper>
      <InputWrapper>
        <HoursSelect readOnly={readOnly}
          name={`hours-${day.date}`}
          date={day.date}
          id={`hours-${day.date}`}
          label="Hours"
          value={day.hours}
          onChange={onChange}
          updateErrors={updateErrors} />
      </InputWrapper>
    </Row>
  );
};

module.exports = DailyHoursInput;
