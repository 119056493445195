const { connect } = require('react-redux');
const TimesheetListComponent = require('../components/TimesheetList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projects: state.filteredProjects,
  isLoading: state.isLoading,
  allProjects: state.projects,
  filter: state.projectsFilter,
  scrollbarWidth: state.scrollbarWidth,
  userId: state.userId,
});

const mapDispatchToProps = (dispatch) => ({
  loadMoreProjects: (pageSize) => dispatch(actions.loadMoreProjects(pageSize)),
  alignHeaderColumns: (scrollbarWidth) => dispatch(actions.alignHeaderColumns(scrollbarWidth)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TimesheetListComponent);
