const { connect } = require('react-redux');
const Component = require('../../../components/modals/RatingModal/RatingModal.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-rating').length > 0,
  supplierId: state.info.id,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (supplierId, rating) => dispatch(actions.saveRating(supplierId, rating)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
