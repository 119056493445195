/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');

module.exports = class CannotSaveModal extends React.Component {
  render() {
    return (
      <Modal title="Cannot save" onCancelClick={this.props.onCancelClick}>
        <div className="production-plan__modal">
          <p>
            In order to save a production plan you need to distribute the 100% of its value.
          </p>
          <p>
            You can easily check this by looking at the total: a red total means that your planning
            is someway different
            from 100%.
          </p>
          <div className="profile-contact-info__actions">
            <button type="button"
              onClick={this.props.onCancelClick}
              className="wethod-button">OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }
};
