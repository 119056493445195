const { connect } = require('react-redux');
const Component = require('../../components/advanced-search/TimesheetLogAdvancedSearch.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  show: state.sidebarToShow === 'advanced-search',
  filters: state.filters,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  applySearch: (filters) => dispatch(actions.applyAdvancedSearch(filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
