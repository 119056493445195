/* eslint-disable react/sort-comp,consistent-return */
const React = require('react');
const Goal = require('../../containers/Goals/Goal');
const Loading = require('../TextArea/Loading.react');
const Empty = require('./Empty.react');

module.exports = class Goals extends React.Component {
  componentDidMount() {
    this.props.getGoals(this.props.projectId);
  }

  isEmpty() {
    return this.props.goals.length <= 0;
  }

  getAddButton() {
    if (this.props.canEdit) {
      if (this.props.isWaitingForSave) {
        return (
          <button type="button" className="wethod-icon-button project-canvas__plus-button disabled">
            <div className="wethod-icon wethod-icon-medium-plus wethod-icon-medium-plus--black" />
          </button>
        );
      }
      return (
        <button type="button"
          className="wethod-icon-button project-canvas__plus-button"
          onClick={this.openNewGoalModal.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-plus wethod-icon-medium-plus--black" />
        </button>
      );
    }
  }

  openNewGoalModal() {
    this.props.showModal();
  }

  openGoalModal(goalId) {
    this.props.showModal(goalId);
  }

  getEmpty() {
    if (this.props.canEdit) {
      return (
        <Empty
          title="Focus on the goals"
          description="Set goals and targets for this project"
          action="Add goal"
          onClick={this.props.showModal}
          image={<div className="project-canvas-empty__goals" />} />
      );
    }
    return (
      <Empty
        description="No goals yet"
        image={<div className="project-canvas-empty__goals" />} />
    );
  }

  getBody() {
    if (this.props.isWaiting) {
      return (
        <Loading />
      );
    }
    if (this.isEmpty()) {
      return this.getEmpty();
    }
    return (
      this.props.goals.map((goal) => (
        <Goal
          key={goal.id}
          goal={goal}
          projectId={this.props.projectId}
          canEdit={this.props.canEdit}
          onClick={this.openGoalModal.bind(this)} />
      ))
    );
  }

  render() {
    return (
      <div className="wethod-widget project-canvas__widget project-canvas__widget--goals">
        <div className="wethod-widget__header project-canvas__widget">
          <span className="wethod-widget__title">Goals</span>
          {this.getAddButton()}
        </div>
        <div className="wethod-widget__body">
          {this.getBody()}
        </div>
      </div>
    );
  }
};
