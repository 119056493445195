const { connect } = require('react-redux');
const Component = require('../../../components/dashboard/intercompany/IntercompanyActivity.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.includes('intercompany-activity'),
  items: state.intercompanyActivityData.items,
  filters: state.intercompanyActivityData.filters,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  getItems:
    (offset, limit, search, order, sort, filters) => dispatch(actions
      .getIntercompanyActivities(offset, limit, search, order, sort, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
