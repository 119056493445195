const React = require('react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const ModalBodyBlock = require('../../../../../../../common/react/Modal/ModalBodyBlock.react');
const ModalFooter = require('../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');

/**
 * Generic modal to show when an error happens during timesheet compilation.
 * @param closeModal
 * @return {JSX.Element}
 * @constructor
 */
const ErrorModal = ({ closeModal }) => (
  <Modal onClose={closeModal} title="Ooops!">
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Something is wrong with your timesheet, please refresh the page and retry.
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter>
        <ModalFooterAction onClick={closeModal}>
          OK
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

module.exports = ErrorModal;
