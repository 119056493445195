const HTTPService = require('../../../../../../services/HTTPService');
const ParserService = require('../../../../../../services/ParserService');

module.exports = Backbone.Collection.extend({
  initialize(options = {}) {
    const year = options.year ? `?&year=${options.year}` : '';
    this.url = `${APIURL}/backlog/target${year}`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((collection) => {
      defer.resolve(collection.toJSON());
    });

    return defer.promise();
  },
});
