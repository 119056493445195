const React = require('react');
const { CSSTransition } = require('react-transition-group');
const Actions = require('../containers/Actions');
const Sidebar = require('../containers/sidebar/SupplierSidebar');
const Modal = require('../containers/Modal');
const Info = require('../containers/Info');
const RatingWidget = require('../containers/RatingWidget');
const Projects = require('./Projects.react');

module.exports = class Supplier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHeight: null,
    };
  }

  componentDidMount() {
    if (this.contentRef.offsetHeight !== this.state.contentHeight) {
      this.setState({ contentHeight: this.contentRef.offsetHeight });
    }
  }

  getRatingWidget() {
    if (this.props.showRating) {
      return <div className="supplier__content-side"><RatingWidget /></div>;
    }
    return null;
  }

  render() {
    return (
      <div className="supplier wethod-section-body">
        <Actions />
        <Info />
        <div className="supplier__content">
          {this.getRatingWidget()}
          <div className="supplier__content-center" ref={(ref) => this.contentRef = ref}>
            <Projects tableHeight={this.state.contentHeight} />
          </div>
        </div>
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <Sidebar />
        </CSSTransition>
        <Modal />
      </div>
    );
  }
};
