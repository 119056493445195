const React = require('react');
const modals = require('../../modals');
const ChangePricelistClientDefaultWarning = require('../../containers/modal/ChangePricelistClientDefaultWarning');
const SetPricelistClientDefaultWarning = require('../../containers/modal/SetPricelistClientDefaultWarning');
const EditPricelistWarning = require('../../containers/modal/EditPricelistWarning');
const ApiErrorModal = require('./ApiErrorModal.react');

module.exports = ({
  toShow,
  closeModal,
  modalData,
}) => {
  const getModal = () => {
    switch (toShow) {
      case modals.CHANGE_PRICELIST_CLIENT_DEFAULT_WARNING:
        return <ChangePricelistClientDefaultWarning data={modalData} onClose={closeModal} />;
      case modals.SET_PRICELIST_CLIENT_DEFAULT_WARNING:
        return <SetPricelistClientDefaultWarning data={modalData} onClose={closeModal} />;
      case modals.EDIT_PRICELIST_WARNING_WARNING:
        return <EditPricelistWarning data={modalData} onClose={closeModal} />;
      case modals.API_ERROR_MODAL:
        return <ApiErrorModal message={modalData} onClose={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
