class TableColumn {
  /**
   * Represents a single Table2 column configuration.
   * @param key {string} unique value to use as ID for the column inside a list
   * @param label {string}
   * @param visible {bool}
   * @param editable {bool} if its visibility can be changed
   * @param width {string} fixed width, can be expressed as "2", "2px", "2%"
   */
  constructor(key, label = '', visible = false, editable = true, width = '') {
    if (key === undefined) {
      throw new Error('"key" is required');
    }

    this.key = key;
    this.label = label;
    this.visible = visible;
    this.editable = editable;
    this.width = width;
    this.elasticWidth = width; // Automatically calculated only if column is "elastic"
  }

  setVisible() {
    this.visible = true;
  }

  setInvisible() {
    this.visible = false;
  }

  hasPercentageWidth() {
    return (/^\d+%$/).test(this.width);
  }

  hasPixelWidth() {
    return (/^\d+(px)?$/).test(this.width);
  }

  /**
   * Returns all columns whitout an expressed fixed witdh.
   * @return {boolean}
   */
  isElastic() {
    return this.width === '';
  }

  getNumericWidth() {
    const match = this.width.match(/(?<value>\d+).*/);
    return match ? parseInt(match.groups.value) : null;
  }
}

module.exports = TableColumn;
