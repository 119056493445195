const { connect } = require('react-redux');
const Component = require('../../components/Goals/Goals.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  goals: state.goals,
  projectId: state.info.id,
  canEdit: state.canEdit,
  isWaiting: state.waitingFor.filter((key) => key === 'goals').length > 0,
  isWaitingForSave: state.waitingFor.filter((key) => key === 'save-goal').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  getGoals: (projectId) => dispatch(actions.getGoals(projectId)),
  showModal: (goalId) => dispatch(actions.showGoalModal(goalId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
