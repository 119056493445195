const React = require('react');
const { getLanguageLevels } = require('../../../../services/LanguageService');

const LanguageLevelChart = ({ data }) => {
  function getLevels() {
    const levels = getLanguageLevels();

    return levels.map((lvl) => {
      const className = data === lvl.id
        ? 'knowledge__language-level--selected'
        : '';
      return (
        <div key={lvl.id} className="knowledge__language-level">
          <div className={className} />
        </div>
      );
    });
  }

  return (
    <div className="knowledge__language-chart">
      {getLevels()}
    </div>
  );
};

module.exports = LanguageLevelChart;
