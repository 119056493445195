const constants = require('./constants');

const updatePersonSuccess = (person) => ({
  type: constants.UPDATE_PERSON_SUCCESS,
  person,
});

const updatePersonRequest = () => ({
  type: constants.UPDATE_PERSON_REQUEST,
});

module.exports.updatePerson = (person) => (dispatch) => {
  dispatch(updatePersonRequest());
  const personModel = new Wethod.FinanceApp.Client.ClientModel({
    ...person,
    client: person.client.id,
  });
  Wethod.request('save:finance:client', personModel)
    .then(() => {
      dispatch(updatePersonSuccess(person));
    });
};

const addPersonTagSuccess = (tag) => ({
  type: constants.PERSON_TAG_SAVE_SUCCESS,
  tag,
});

const addPersonTagRequest = (tag) => ({
  type: constants.PERSON_TAG_SAVE_REQUEST,
  tag,
});

module.exports.addPersonTag = (personId, tag) => (dispatch) => {
  dispatch(addPersonTagRequest(tag));
  Wethod.request('add:finance:person:tag',
    {
      ...tag,
      customer_id: personId,
    })
    .then((response) => {
      dispatch(addPersonTagSuccess(response.data));
    });
};

const addPersonPhoneSuccess = (phone) => ({
  type: constants.PERSON_PHONE_ADD_SUCCESS,
  phone,
});

const addPersonPhoneRequest = (phone) => ({
  type: constants.PERSON_PHONE_ADD_REQUEST,
  phone,
});

module.exports.addPersonPhone = (personId, phone) => (dispatch) => {
  dispatch(addPersonPhoneRequest(phone));
  Wethod.request('add:finance:person:phone',
    {
      ...phone,
      customer_id: personId,
    })
    .then((response) => {
      dispatch(addPersonPhoneSuccess(response.data));
    });
};

const updatePersonPhoneSuccess = (phone) => ({
  type: constants.PERSON_PHONE_EDIT_SUCCESS,
  phone,
});

const updatePersonPhoneRequest = (phone) => ({
  type: constants.PERSON_PHONE_EDIT_REQUEST,
  phone,
});

module.exports.updatePersonPhone = (phone) => (dispatch) => {
  dispatch(updatePersonPhoneRequest(phone));
  const model = new Wethod.FinanceApp.Client.ClientPersonPhoneModel(phone);
  Wethod.request('update:finance:person:phone', model)
    .then(() => {
      dispatch(updatePersonPhoneSuccess(phone));
    });
};

const deletePersonPhoneSuccess = (phone) => ({
  type: constants.PERSON_PHONE_DELETE_SUCCESS,
  phone,
});

const deletePersonPhoneRequest = () => ({
  type: constants.PERSON_PHONE_DELETE_REQUEST,
});

module.exports.deletePersonPhone = (phone) => (dispatch) => {
  dispatch(deletePersonPhoneRequest());
  Wethod.request('delete:finance:person:phone', phone)
    .then(() => {
      dispatch(deletePersonPhoneSuccess(phone));
    });
};

const deletePersonTagSuccess = (tag) => ({
  type: constants.PERSON_TAG_DELETE_SUCCESS,
  tag,
});

const deletePersonTagRequest = (tag) => ({
  type: constants.PERSON_TAG_DELETE_REQUEST,
  tag,
});

module.exports.deletePersonTag = (personId, tag) => (dispatch) => {
  dispatch(deletePersonTagRequest(tag));
  Wethod.request('delete:finance:person:tag',
    {
      ...tag,
      customer_id: personId,
    })
    .then((response) => {
      dispatch(deletePersonTagSuccess(response.data));
    });
};

const saveResourceSuccess = (resource) => ({
  type: constants.RESOURCE_SAVE_SUCCESS,
  resource,
});

const saveResourceRequest = (resource) => ({
  type: constants.RESOURCE_SAVE_REQUEST,
  resource,
});

module.exports.saveResource = (personId, resource) => (dispatch) => {
  dispatch(saveResourceRequest(resource));
  Wethod.request('save:finance:client:person:resource', personId, resource)
    .then((response) => {
      dispatch(saveResourceSuccess(response.data));
    });
};

const deleteResourceSuccess = (resource) => ({
  type: constants.RESOURCE_DELETE_SUCCESS,
  resource,
});

const deleteResourceRequest = (resource) => ({
  type: constants.RESOURCE_DELETE_REQUEST,
  resource,
});

module.exports.deleteResource = (personId, resource) => (dispatch) => {
  dispatch(deleteResourceRequest(resource));
  const model = new Wethod.FinanceApp.Client.ClientResourceModel(resource);
  Wethod.request('delete:finance:client:resource', model)
    .then(() => {
      dispatch(deleteResourceSuccess(resource));
    });
};

const savePersonNoteSuccess = (note) => ({
  type: constants.PERSON_NOTE_SAVE_SUCCESS,
  notes: note,
});

const savePersonNoteRequest = () => ({
  type: constants.PERSON_NOTE_SAVE_REQUEST,
});

module.exports.savePersonNote = (note) => (dispatch) => {
  dispatch(savePersonNoteRequest());
  /* const personModel = new Wethod.FinanceApp.Client.ClientModel({
   ...person,
   client: person.company.id
   });
   Wethod.request('save:finance:client', personModel)
   .then(response => {
   dispatch(updatePersonSuccess(person))
   }); */
  setTimeout(() => {
    dispatch(savePersonNoteSuccess(note));
  }, 500);
};

const saveCompanySuccess = (company) => ({
  type: constants.COMPANY_SAVE_SUCCESS,
  company,
});

const saveCompanyRequest = () => ({
  type: constants.COMPANY_SAVE_REQUEST,
});

module.exports.saveCompany = (company) => (dispatch) => {
  dispatch(saveCompanyRequest());
  const model = new Wethod.FinanceApp.Client.ClientCompanyModel({
    ...company,
    corporate_name: company.name,
    group: company.group ? company.group.id : null,
  });
  Wethod.request('save:finance:client', model)
    .then((response) => {
      dispatch(saveCompanySuccess(response.attributes));
    });
};
