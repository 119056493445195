const React = require('react');
const PropTypes = require('prop-types');
const InfiniteScrollContainer = require('../../../InfiniteScrollContainer.react');
const SmallLoader = require('./SmallLoader.react');
const EmptySearch = require('./EmptySearch.react');
const FileItem = require('./FileItem.react');
const FileModel = require('../models/File');

const FileList = ({
  hasMore, loadMore, isLoading, files, isSearching, emptyFilesMessage, canEdit, onDownload,
  onDelete, onFolderClick,
}) => {
  /**
   * Sorting callback that places folders first in a list composed by folders and files.
   *
   * @param {FileModel} a
   * @param {FileModel} b
   * @return {number}
   */
  const folderFirstSort = (a, b) => {
    if (a.isFolder()) {
      return -1;
    }
    if (b.isFolder()) {
      return 1;
    }
    return 0;
  };

  const getFiles = () => files
    .sort(folderFirstSort)
    .map((file) => (
      <FileItem file={file}
        key={file.id}
        editable={canEdit}
        onDownload={onDownload}
        onDelete={onDelete}
        onFolderClick={onFolderClick} />
    ));

  const getContent = () => {
    if (isLoading) {
      return <SmallLoader />;
    }
    if (isSearching && !files.length) {
      return <EmptySearch />;
    }
    if (!files.length) {
      return emptyFilesMessage;
    }
    return getFiles();
  };

  const getWrapperClassName = () => {
    let name = 'wethod-widget-files__collection-content-wrapper';
    if (isLoading || !files.length) {
      name += ' wethod-widget-files__collection-content-wrapper--visible-message';
    }
    return name;
  };

  return (
    <InfiniteScrollContainer
      className="wethod-widget-files__collection"
      key={0}
      itemHeight={50}
      hasMore={hasMore}
      loadMore={loadMore}>
      <div className={getWrapperClassName()}>
        <div className="wethod-widget-files__collection-content">
          {getContent()}
        </div>
      </div>
    </InfiniteScrollContainer>
  );
};

FileList.defaultProps = {
  isLoading: false,
  isSearching: false,
  canEdit: false,
  onDownload: null,
  onDelete: null,
  onFolderClick: null,
};

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileModel)).isRequired,
  hasMore: PropTypes.bool.isRequired,
  /**
   * The function to call to load next results page.
   *
   * @param size {integer}
   */
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  emptyFilesMessage: PropTypes.node.isRequired,
  canEdit: PropTypes.bool,
  /**
   * The function to call to delete a file.
   *
   * @param file {FileModel}
   */
  onDelete: PropTypes.func,
  /**
   * The function to call to download a file.
   *
   * @param file {FileModel}
   */
  onDownload: PropTypes.func,
  /**
   * The function to let user navigate into the clicked folder.
   *
   * @param folder {FileModel}
   */
  onFolderClick: PropTypes.func,
};

module.exports = FileList;
