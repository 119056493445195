'use strict';

var SENTENCE_API = {
  getSentences: function (options) {
    var defer = $.Deferred();
    var url = '';

    if (options.context && options.labels) {
      url += '/message/?context=' + options.context + '&labels=' + options.labels;
    } else if (options.context) {
      url += '/message/?context=' + options.context;
    } else {
      url += '/message/';
    }

    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: APIURL + url,
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'json',
    }).done(function (response) {
      defer.resolve(response);
    }).fail(function () {
      defer.resolve(undefined);
    });

    return defer.promise();
  },
};

Wethod.reqres.setHandler('get:sentences', function (options) {
  return SENTENCE_API.getSentences(options);
});
