const { connect } = require('react-redux');
const Component = require('../../components/modal/DeleteOrderModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.filter((key) => key === 'save-order').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  deleteOrder: (order) => dispatch(actions.deleteOrder(order, true)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
