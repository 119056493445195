const React = require('react');
const CheckboxRow = require('../CheckboxRow.react');

const TimesheetStrategy = ({ timeLimit, waitingForSetting, onTimeLimitEnabledChange }) => {
  function handleLimitEnabledChange() {
    onTimeLimitEnabledChange({
      enabled: !timeLimit.enabled,
    });
  }
  const feedback = waitingForSetting ? 'Saving...' : null;
  return (
    <CheckboxRow
      id="timesheet-time-limit"
      name="timesheet-time-limit"
      label="Limit time tracking to project duration"
      value={timeLimit.enabled}
      description="Allow users to edit or record the amount of time worked on a project only during its duration"
      feedback={feedback}
      onChange={handleLimitEnabledChange}
      checked={timeLimit.enabled}
      disabled={waitingForSetting} />
  );
};

module.exports = TimesheetStrategy;
