const React = require('react');
const Numeric = require('./NumericSummaryCell/NumericSummaryCell.react');
const Status = require('./StatusSummaryCell/StatusSummaryCell.react');
const Empty = require('./NoSummaryCell.react');

module.exports = ({ attribute, values, ...rest }) => {
  const notNullValues = values.filter((value) => value.value !== null);

  switch (attribute.type) {
    case 'numeric':
      return (
        <Numeric func={attribute.summary}
          values={notNullValues}
          attribute={attribute}
          {...rest} />
      );
    case 'status':
      return <Status attribute={attribute} values={notNullValues} {...rest} />;
    default:
      return <Empty />;
  }
};
