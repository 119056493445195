/* eslint-disable consistent-return,class-methods-use-this,react/sort-comp,
 react/no-access-state-in-setstate */
const React = require('react');
const Pic = require('../../../../settings/profile/components/ProfilePicPicker.react');
const TextInput = require('../../../../../../../common/react/material-input/SidebarTextInput.react');
const NumericInput = require('../../../../../../../common/react/material-input/SidebarNumericInput.react');
const NoteInput = require('../../../../../../../common/react/material-input/SidebarTextArea.react');
const SearchSelect = require('../../../../../../../common/react/material-input/SidebarSearchSelectAddButton.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');
const GroupSelect = require('./GroupSearchSelectCreate.react');
const IntercompanyIdSelect = require('./IntercompanyIdSelect.react');
const BankAccountSelect = require('./BankAccountSelect.react');
const VatRateSelect = require('./VatRateSelect.react');
const PaymentTermsSelect = require('./PaymentTermsSelect.react');
const DateInput = require('../../../../../../../common/react/material-input/SidebarDateSelection.react');
const PhoneInput = require('../../../../../../../common/react/material-input/SidebarPhoneInput.react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');

module.exports = class CompanyEditableDetails extends React.Component {
  getName() {
    return this.props.client.corporate_name ? this.props.client.corporate_name : '';
  }

  getIntercompanyIdSelect() {
    if (this.props.hasIntercompanyGroup) {
      return (
        <IntercompanyIdSelect
          name="group_company"
          value={this.props.client.group_company}
          placeholder="Intercompany ID"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </IntercompanyIdSelect>
      );
    }
    return null;
  }

  handleDateChange(name, value) {
    if (value) {
      this.props.onChange(name, moment(value).format('YYYY-MM-DD'));
    } else {
      this.props.onChange(name, value);
    }
  }

  handleCountryChange(name, value) {
    this.props.onChange(name, value);

    if (!this.isItaly(value) && this.props.client.province) { // Province needs reset
      this.props.onChange('province', null);
    }
  }

  getExternalId() {
    if (this.hasExternalIds()) {
      return this.props.client.external_ids[0].external_id;
    }
  }

  /**
   * Check whether the client has external ids
   * @returns {boolean}
   */
  hasExternalIds() {
    return this.props.client.external_ids != null && this.props.client.external_ids.length > 0;
  }

  isItaly(country) {
    const name = country ? country.name : '';
    return name.toLowerCase() === 'italy';
  }

  // TODO: change PicPicker!
  render() {
    return (
      <div>
        <div className="wethod-sidebar__picture">
          <Pic name={this.getName()} editable={false} />
        </div>
        <div className="clients__sidebar--multiple-input-row">
          <div className="clients__sidebar--name clients__sidebar--multiple-input-element">
            <TextInput
              name="corporate_name"
              value={this.props.client.corporate_name}
              placeholder="Name"
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}
              required>
              <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
            </TextInput>
          </div>
          <div className="clients__sidebar--acronym clients__sidebar--multiple-input-element">
            <TextInput
              name="acronym"
              value={this.props.client.acronym}
              placeholder="Acronym"
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}
              readOnly={!this.props.canEdit}
              required />
          </div>
        </div>
        <TextInput
          name="legal_name"
          value={this.props.client.legal_name}
          placeholder="Legal name"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        {this.getIntercompanyIdSelect()}
        <GroupSelect
          name="group"
          value={this.props.client.group}
          placeholder="Group"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}
          items={this.props.groups}
          onSave={this.props.onGroupSave}>
          <span />
        </GroupSelect>
        <TextInput
          name="street"
          value={this.props.client.street}
          placeholder="Address"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </TextInput>
        <TextInput
          name="town"
          value={this.props.client.town}
          placeholder="Town"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
        <div className="clients__sidebar--multiple-input-row">
          <div className="clients__sidebar--multiple-input-element">
            <NumericInput
              name="zip_code"
              value={this.props.client.zip_code}
              placeholder="ZIP"
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}
              formatOptions={{ delimiter: '', stripLeadingZeroes: false }}
              integer>
              <span />
            </NumericInput>
          </div>
          <div className="clients__sidebar--multiple-input-element">
            <SearchSelect
              name="country"
              placeholder="Country"
              value={this.props.client.country}
              items={this.props.countries}
              onChange={this.handleCountryChange.bind(this)}
              onValidate={this.props.onValidate}
              readOnly={!this.props.canEdit} />
          </div>
        </div>
        <ShowIf condition={this.isItaly(this.props.client.country)}>
          <div className="clients__sidebar-province">
            <SearchSelect
              name="province"
              placeholder="Province"
              value={this.props.client.province}
              items={this.props.provinces}
              onChange={this.props.onChange}
              onValidate={this.props.onValidate}
              readOnly={!this.props.canEdit}>
              <span />
            </SearchSelect>
          </div>
        </ShowIf>
        <TextInput
          name="website"
          value={this.props.client.website}
          placeholder="Website"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span className="wethod-icon wethod-icon-internet wethod-icon-internet--black" />
        </TextInput>
        <TextInput
          name="linkedin"
          value={this.props.client.linkedin}
          placeholder="LinkedIn"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </TextInput>
        <TextInput
          name="vat"
          value={this.props.client.vat}
          placeholder="VAT Number"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </TextInput>
        <TextInput
          name="company_registration_number"
          value={this.props.client.company_registration_number}
          placeholder="Co. Reg. No."
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </TextInput>
        <TextInput
          name="tax_reference"
          value={this.props.client.tax_reference}
          placeholder="Tax Code"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </TextInput>
        <TextInput
          name="sdi_code"
          value={this.props.client.sdi_code}
          placeholder="SDI code"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </TextInput>
        <TextInput
          name="payment_condition"
          value={this.props.client.payment_condition}
          placeholder="Payment Conditions"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </TextInput>
        <PaymentTermsSelect
          value={this.props.client.payment_term}
          items={this.props.paymentTerms}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span />
        </PaymentTermsSelect>
        <BankAccountSelect value={this.props.client.bank_account}
          items={this.props.bankAccounts}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <VatRateSelect value={this.props.client.vat_rate}
          items={this.props.vatRates}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <TextInput
          name="email"
          value={this.props.client.email}
          placeholder="Email"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          email>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </TextInput>
        <TextInput
          name="pec"
          value={this.props.client.pec}
          placeholder="PEC address"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          email>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </TextInput>
        <TextInput
          name="administration_email"
          value={this.props.client.administration_email}
          placeholder="Email 2"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          email>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </TextInput>
        <PhoneInput
          name="phone"
          value={this.props.client.phone}
          placeholder="Phone"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          readOnly={!this.props.canEdit}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </PhoneInput>
        <NoteInput
          name="notes"
          value={this.props.client.notes}
          placeholder="Notes"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </NoteInput>
        <ShowIf condition={this.hasExternalIds()}>
          <ReadOnlyField placeholder="External code" value={this.getExternalId()}>
            <span />
          </ReadOnlyField>
        </ShowIf>
        <h3 className="wethod-sidebar__subtitle">Declaration of intent</h3>
        <DateInput name="intent_date"
          placeholder="Date"
          value={this.props.client.intent_date}
          onChange={this.handleDateChange.bind(this)}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        </DateInput>
        <TextInput name="intent_code"
          placeholder="Protocol"
          value={this.props.client.intent_code}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span />
        </TextInput>
      </div>
    );
  }
};
