/* eslint-disable react/prop-types */
const React = require('react');
const DesktopNav = require('./desktop/Nav.react');
const MobileNav = require('./mobile/Nav.react');
const NavModel = require('../../models/AppNav/Nav');
const AppNavModel = require('../../models/AppNav/AppNavModel');
const LaptopAndAbove = require('../../../media-queries/LaptopAndAbove.react');
const Mobile = require('../../../media-queries/Mobile.react');

module.exports = class AppNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = { permissions: null };
  }

  componentDidMount() {
    const appNavModel = new AppNavModel();

    $.when(appNavModel.getUserPermissions()).done((permissions) => {
      // TODO come capisco se bloccato?
      this.setState({ permissions });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.permissions && this.state.permissions) {
      this.props.onHeightChange(60);
    }
  }

  render() {
    const userPermissions = this.state.permissions;
    if (this.state.permissions) {
      const model = new NavModel(userPermissions);

      return (
        <div>
          <LaptopAndAbove><DesktopNav model={model} /></LaptopAndAbove>
          <Mobile><MobileNav model={model} /></Mobile>
        </div>
      );
    }
    return null;
  }
};
