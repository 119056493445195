const React = require('react');
const MultipleTable = require('./MultipleTable.react');
const LevelsTable = require('../containers/LevelsTable');
const EmployeesTable = require('../containers/EmployeesTable');
const Search = require('../../../../../../common/react/Table2/TableSearch.react');
const Footer = require('./TableFooter.react');
const Loading = require('./Loading.react');

module.exports = class ChargeabilityTable extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions')
      .outerHeight(true) + 24);
  }

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getSearch() {
    return (
      <Search
        placeholder="Search team member"
        filter={this.props.filterEmployees} />
    );
  }

  updateTableHeight() {
    this.setState({ tableHeight: ChargeabilityTable.getTableMaxHeight() });
  }

  render() {
    if (this.props.isWaiting) {
      return <Loading />;
    }

    return (
      <MultipleTable columns={10}
        className="chargeability__multiple-table"
        search={this.getSearch()}
        footer={<Footer />}
        height={this.state.tableHeight}
        maxHeight={this.state.tableHeight}>
        <LevelsTable key="levels" />
        <EmployeesTable key="employees" />
      </MultipleTable>
    );
  }
};
