const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/GA.react');

const mapStateToProps = (state) => ({
  ga: state.month_ga,
  targetType: state.target_type,
  month: state.month,
  costsOnProduction: state.month_ga_perc, // NULLABLE
  planned: state.ga_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
