const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const List = require('../../../../common/components/List.react');
const NS = require('../../NumerableSentence.react');
const Link = require('../../../../common/components/Link.react');

module.exports = ({ opportunities }) => {
  const getProjectTypeName = (projectType) => (projectType ? (
    <span>
      (
      {_.unescape(projectType.name)}
      )
    </span>
  ) : null);

  const getProject = (project) => (
    <span key={project.project.id}>
      <Link
        href={`#project/${project.project.id}/canvas`}>
        {_.unescape(project.project.name)}
      </Link>
      {' '}
      for
      {' '}
      {_.unescape(project.client.name)}
      {' '}
      {getProjectTypeName(project.project_type)}
    </span>
  );

  const getProjects = () => {
    const projects = opportunities.map((project) => getProject(project));
    return <List list={opportunities} terminator=".">{projects}</List>;
  };

  if (opportunities.length) {
    return (
      <Paragraph>
        <NS quantity={opportunities.length}>These opportunities are:</NS>
        {' '}
        {getProjects()}
      </Paragraph>
    );
  }
  return null;
};
