const React = require('react');

const BudgetFooterAction = ({
  children,
  noBorder,
  classNameButton,
}) => {
  const className = noBorder
    ? `wethod-budget-footer__action wethod-budget-footer__action--no-border ${classNameButton || null}`
    : `wethod-budget-footer__action ${classNameButton || null}`;
  return (
    <div className={className}>
      {children}
    </div>
  );
};

module.exports = BudgetFooterAction;
