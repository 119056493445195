const $ = require('jquery');
const constants = require('./constants');
const PropertyMetadataService = require('../../../../../services/PropertyMetadataService/PropertyMetadataService');
const TimesheetModel = require('../../../../../models/Timesheet');
const PropertyMetadata = require('../../../../../models/PropertyMetadata');
const modals = require('./modals');
const PropertyMetadataModel = require('../../../../../models/PropertyMetadata');

const resetTimesheetsToTransfer = () => ({
  type: constants.RESET_TIMESHEETS_TO_TRANSFER,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

const closeTransferHoursModal = () => (dispatch) => {
  dispatch(resetTimesheetsToTransfer());
  dispatch(closeModal());
};

module.exports.closeTransferHoursModal = closeTransferHoursModal;

const showModal = (key, data) => ({
  type: 'SHOW_MODAL',
  key,
  data,
});
module.exports.showModal = showModal;

module.exports.showTransferHoursModal = () => showModal(
  modals.TRANSFER_HOURS, null,
);

const showErrorModal = (message) => showModal(
  modals.ERROR, { message },
);

const getTimesheetsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_TIMESHEETS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getTimesheetsSuccess = (timesheets) => ({
  type: constants.GET_TIMESHEETS_SUCCESS,
  timesheets,
});

const getTimesheets = (offset, limit, search, order, sort, filters) => (dispatch) => {
  const options = {
    offset, limit, search, order, sort,
  };

  dispatch(getTimesheetsRequest(offset, limit, search, order, sort, options));

  const getListRequest = TimesheetModel.getAll(options, filters);
  $.when(getListRequest)
    .done((timesheets) => dispatch(getTimesheetsSuccess(timesheets)));
};

module.exports.getTimesheets = getTimesheets;

const getTimesheetsToTransferRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_TIMESHEETS_TO_TRANSFER_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getTimesheetsToTransferSuccess = (timesheets) => ({
  type: constants.GET_TIMESHEETS_TO_TRANSFER_SUCCESS,
  timesheets,
});

/**
 * Get timesheets list to show in the transfer hours modal
 * @param offset
 * @param limit
 * @param search
 * @param order
 * @param sort
 * @param filters
 * @returns {(function(*): void)|*}
 */
module.exports.getTimesheetsToTransfer = (
  offset, limit, search, order, sort, filters,
) => (dispatch) => {
  const options = {
    offset, limit, search, order, sort,
  };

  dispatch(getTimesheetsToTransferRequest(offset, limit, search, order, sort, options));

  const getListRequest = TimesheetModel.getAll(options, filters);
  $.when(getListRequest)
    .done((timesheets) => dispatch(getTimesheetsToTransferSuccess(timesheets)));
};

module.exports.showAdvancedSearch = () => ({
  type: constants.SHOW_ADVANCED_SEARCH,
});

module.exports.closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

const applyAdvancedSearch = (filters) => ({
  type: constants.APPLY_ADVANCED_SEARCH,
  filters,
});
module.exports.applyAdvancedSearch = applyAdvancedSearch;

const transferHoursRequest = () => ({
  type: constants.TRANSFER_HOURS_REQUEST,
});

const transferHoursSuccess = () => ({
  type: constants.TRANSFER_HOURS_SUCCESS,
});

const transferHoursError = (error) => ({
  type: constants.TRANSFER_HOURS_ERROR,
  error,
});

module.exports.transferHours = (selectAll, projectId, date, timesheets, filters) => (dispatch) => {
  dispatch(transferHoursRequest());

  const transferRequest = TimesheetModel
    .transferHours(selectAll, projectId, date, timesheets, filters);
  $.when(transferRequest)
    .done(() => {
      dispatch(transferHoursSuccess());
      dispatch(closeTransferHoursModal());
      dispatch(applyAdvancedSearch({}));
    })
    .fail((error) => {
      const typeOfErrors = {
        transferredHoursExcessErrors: 'You are trying to transfer more hours than the available ones.',
        missingTimetrackingErrors: 'You are trying to transfer hours of a timetracking that does not exists.',
        mismatchedWeekDaysErrors: 'Mismatched week days error',
        frozenPeriodErrors: 'You are trying to transfer hours of a frozen period.',
      };
      let message = 'Some error occurred while transferring the hours. ';

      // eslint-disable-next-line no-restricted-syntax
      for (const type in error) {
        // eslint-disable-next-line no-prototype-builtins
        if (typeOfErrors.hasOwnProperty(type)) {
          message += `\n${typeOfErrors[type]}`;
        }
      }
      dispatch(showErrorModal(message));
      dispatch(transferHoursError(error));
    });
};

module.exports.selectAllTimesheetsToTransfer = () => ({
  type: constants.SELECT_ALL_TRANSFER,
});

module.exports.selectTimesheetToTransfer = (id, selected) => ({
  type: constants.SELECT_TIMESHEET_TO_TRANSFER,
  id,
  selected,
});

module.exports.updateHoursToTransfer = (id, hours) => ({
  type: constants.UPDATE_HOURS_TO_TRANSFER,
  id,
  hours,
});

const getFrozenPeriodRequest = () => ({
  type: constants.GET_FROZEN_PERIOD_REQUEST,
});

const getFrozenPeriodSuccess = (lastDayOfIsoMonth) => ({
  type: constants.GET_FROZEN_PERIOD_SUCCESS,
  frozenUpTo: lastDayOfIsoMonth,
});
const getFrozenPeriodError = () => ({
  type: constants.GET_FROZEN_PERIOD_ERROR,
  frozenUpTo: null,
});

module.exports.getFrozenPeriod = () => (dispatch) => {
  dispatch(getFrozenPeriodRequest());

  const propertyMetadataRequest = PropertyMetadata.list(PropertyMetadataModel.TIMESHEET_ENTITY);
  $.when(propertyMetadataRequest)
    .done((data) => {
      const propertyMetadataService = new PropertyMetadataService(data);
      const dateConstraint = propertyMetadataService.getConstraint('date', 'greater_than');
      dispatch(getFrozenPeriodSuccess(dateConstraint.value));
    })
    .fail(() => dispatch(getFrozenPeriodError()));
};
