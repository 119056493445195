const React = require('react');
const Name = require('../Cell/NameCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const Levels = require('./JobTitleLevels.react');
const ExternalCosts = require('../Cell/ExternalCostCell.react');
const DaysCell = require('../Cell/ManDaysCell.react');
const Cost = require('../Cell/CostCell.react');
const Price = require('../Cell/PriceCell.react');
const IconCell = require('../Cell/IconCell.react');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsMenuItem = require('../MoreOptionsButton/MoreOptionsMenuItem.react');
const Row = require('../Row.react');
const TaskLabel = require('../RowLabel/RowLabelFactory.react');
const CurrencyManager = require('../../../containers/CurrencyManager');
const RowHorizontalScroll = require('../RowHorizontalScroll.react');

const JobTitle = ({
  dayId, name, days, cost, price, editableBudget, updateLevel, showDeleteModal,
  availableLevels, budgetLevelId, previous, taskId, jobTitleId, deleted = false,
  created = false, column,
}) => {
  const levels = availableLevels.filter((level) => level.is_visible)
    .map((level) => {
      if (level.level.id === budgetLevelId) {
        return {
          id: dayId,
          placeholder: false,
          days,
          was: previous,
        };
      }
      return {
        id: level.id,
        placeholder: true,
      };
    });

  function saveLevel(quantity) {
    updateLevel(dayId, dayId, quantity);
  }

  function handleDeleteClick() {
    showDeleteModal(dayId, taskId, jobTitleId);
  }

  const editable = editableBudget && !deleted;

  if (column === 'left') {
    return (
      <Row className="wethod-budget-task__job-title">
        <TaskLabel exists={created} existed={deleted} />
        <Name className="wethod-budget-task__job-title-name" tooltip={name}>{name}</Name>
        <PercentageCell />
      </Row>
    );
  }
  if (column === 'center') {
    return (
      <RowHorizontalScroll className="wethod-budget-task__job-title">
        <Levels items={levels} saveLevel={saveLevel} editable={editable} />
      </RowHorizontalScroll>
    );
  }
  return (
    <Row className="wethod-budget-task__job-title">
      <CurrencyManager>
        <ExternalCosts value={0} />
      </CurrencyManager>
      <PercentageCell />
      <DaysCell>{days}</DaysCell>
      <Cost>{cost}</Cost>
      <Price>{price}</Price>
      <IconCell />
      <MoreOptions editable={editable}>
        <MoreOptionsMenuItem onClick={handleDeleteClick}>Delete</MoreOptionsMenuItem>
      </MoreOptions>
    </Row>
  );
};

module.exports = JobTitle;
