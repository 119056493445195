const React = require('react');
const TextArea = require('../../../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const Select = require('../../../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../../../../../../../common/react/inputs/Select/SelectItem.react');
const ModalContent = require('../../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooter = require('../../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../../common/react/Modal/ModalFooterAction.react');
const InputValidator = require('../../../../../../../../../common/react/InputValidator/InputValidator.react');
const EmptyApproversMessage = require('./EmptyApproversMessage.react');
const ModalBodyBlock = require('../../../../../../../../../common/react/Modal/ModalBodyBlock.react');

module.exports = class SubmitStatusButtonModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      recipient: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
  }

  componentDidMount() {
    this.props.getAvailableApprovers();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSendClick() {
    this.props.submitBudget(this.state.message, this.state.recipient);
  }

  getFeedback() {
    return this.isSaving() ? 'Sending...' : '';
  }

  getApproverItems() {
    return this.props.availableApprovers.map((approver) => (
      <SelectItem key={approver.id}
        value={approver.id}>{approver.name}
      </SelectItem>
    ));
  }

  isSaving() {
    return this.props.isPending('change-status');
  }

  isLoadingApprovers() {
    return this.props.isPending('get-approvers');
  }

  render() {
    return (
      <ModalContent isLoading={this.isLoadingApprovers()}>
        <ModalBody>
          <ModalBodyBlock>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
              <Select value={this.state.recipient}
                empty={<EmptyApproversMessage />}
                placeholder="Send to"
                onChange={this.onChange}
                errorText={this.props.errors.recipient}
                name="recipient">
                {this.getApproverItems()}
              </Select>
            </InputValidator>
          </ModalBodyBlock>
          <ModalBodyBlock>
            Feel free to attach a message:
          </ModalBodyBlock>
          <ModalBodyBlock>
            <TextArea name="message"
              disabled={this.isSaving()}
              value={this.state.message}
              onChange={this.onChange} />
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={this.getFeedback()}>
          <ModalFooterAction onClick={this.props.closeModal} disabled={this.isSaving()}>
            Cancel
          </ModalFooterAction>
          <ModalFooterAction onClick={this.onSendClick}
            disabled={this.isSaving() || !this.props.isValid}>
            Send
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    );
  }
};
