/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const PercentageValue = require('../../../../common/components/PercentageValue.react');
const Link = require('../../../../common/components/Link.react');

module.exports = class ProductionAgainstLastYear extends React.Component {
  render() {
    const ratio = this.props.prev_year_delta_prod;
    if (ratio === null) {
      return null;
    } if (ratio === 0) {
      return (
        <Paragraph>We had a similar production compared to the same week last year.</Paragraph>
      );
    }
    return (
      <Paragraph>
        We produced{' '}
        <PercentageValue value={Math.abs(ratio)} />
        {' '}
        <Link
          href="/#reports/backlog">
          {ratio > 0 ? 'more' : 'less'}
        </Link>
        {' '}
        compared to the same week last year.
      </Paragraph>
    );
  }
};
