/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label */
const React = require('react');
const Company = require('../containers/Company');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const Search = require('../containers/SearchBox');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');

module.exports = class Companies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: {
        col: 'name',
        order: 'asc',
      },
    };
  }

  getCompanies() {
    if (this.props.isLoading) return <Loader />;
    if (this.props.loadedItems.length === 0) return [];
    return this.props.loadedItems.map((item) => <Company key={item.id} {...item} />);
  }

  hasMore() {
    return this.props.loadedItems.length < this.props.totalItems;
  }

  loadMore(size, page) {
    this.props.loadClients(size, page);
  }

  getSearch() {
    return <Search />;
  }

  handleSort(col, order) {
    this.props.orderClients(col, order);

    this.setState({
      sortBy: {
        col,
        order,
      },
    });
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  render() {
    return (
      <div className="clients-list">
        <Table columns={5} maxHeight={this.props.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell className="clients-people__column--profile-pic" />
              <TableCell
                className="clients-companies__column--name"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('name')}
                name="name">
                Name
              </TableCell>
              <TableCell
                className="clients-companies__column--acronym"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('acronym')}
                name="acronym">
                Acronym
              </TableCell>
              <TableCell
                className="clients-companies__column--group"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('group')}
                name="group">
                Group
              </TableCell>
              <TableCell className="clients-companies__column--actions" />
            </TableRow>
          </TableHead>
          <TableBody
            hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty="There's nothing here! What about adding a company?">
            {this.getCompanies()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
