const React = require('react');
const PropTypes = require('prop-types');

require('./style.scss');

const Ellipser = ({ children, maxWidth }) => {
  const style = { maxWidth };
  return (
    <div className="wethod-ellipser" style={style}>{children}</div>
  );
};

Ellipser.defaultProps = {
  maxWidth: 'auto',
  children: null,
};

Ellipser.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

module.exports = Ellipser;
