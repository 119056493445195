const React = require('react');
const Header = require('../SubsectionHeader.react');
const Actions = require('../../containers/location/Actions');
const List = require('../../containers/location/Table');
const Modal = require('../../containers/ModalController');

const Locations = () => (
  <div className="bottomSpace">
    <Header subtitle="Company Locations"
      id="location"
      description="Below you can add the locations in which your company offices are placed. This will allow you to set holidays and closures based on where your employee work." />
    <Actions />
    <List />
    <Modal />
  </div>
);

module.exports = Locations;
