/* eslint-disable react/sort-comp,class-methods-use-this,comma-dangle */
const React = require('react');
const Supplier = require('../containers/Supplier');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const TableSearch = require('./Search.react');

module.exports = class SuppliersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.suppliers__actions')
      .outerHeight(true) + 24);
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getSuppliers() {
    return this.props.suppliers.map((item) => (
      <Supplier key={item.id}
        supplier={item} />
    ));
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const offset = (page - 1) * size;
      this.props.getSuppliers(offset, size, this.props.keyword, this.props.sortBy.col,
        this.props.sortBy.order, this.props.bu);
    }
  }

  filter(keyword) {
    this.props.getSuppliers(0, this.props.pageLimit, keyword, this.props.sortBy.col,
      this.props.sortBy.order, this.props.bu);
  }

  getTagCellStyle() {
    let style = 'suppliers__column--tag';
    if (!this.props.statusEnabled && !this.props.showRating) {
      style += ' suppliers__column--tag-large';
    } else if (!this.props.statusEnabled || !this.props.showRating) {
      style += ' suppliers__column--tag-medium';
    }

    return style;
  }

  getSort(col) {
    const currentSort = this.props.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  onSort(col, order) {
    if (!this.props.isWaiting) {
      this.props.getSuppliers(0, this.props.pageLimit, this.props.keyword, col, order,
        this.props.bu);
    }
  }

  getHeadCells() {
    const headCellList = []
      .concat(
        <TableCell key="name"
          sort={this.getSort('name')}
          onSort={this.onSort.bind(this)}
          name="name"
          className="suppliers__column--name">
          Name
        </TableCell>
      )
      .concat(
        <TableCell key="email"
          sort={this.getSort('email')}
          onSort={this.onSort.bind(this)}
          name="email"
          className="suppliers__column--email">
          Email
        </TableCell>
      )
      .concat(
        <TableCell key="tag"
          name="tag"
          className={this.getTagCellStyle()}>
          Tags
        </TableCell>
      );

    if (this.props.showRating) {
      headCellList.push(
        <TableCell key="rating"
          sort={this.getSort('rating')}
          onSort={this.onSort.bind(this)}
          name="rating"
          className="suppliers__column--rating">
          Rating
        </TableCell>
      );
    }

    if (this.props.statusEnabled) {
      headCellList.push(
        <TableCell key="status"
          sort={this.getSort('status')}
          onSort={this.onSort.bind(this)}
          name="status"
          className="suppliers__column--status">
          Status
        </TableCell>
      );
    }

    headCellList.push(<TableCell key="actions"
      className="suppliers__column--actions" />);

    return headCellList;
  }

  getTotalColumns() {
    let counter = 6;
    if (this.props.statusEnabled) {
      counter += 1;
    }
    if (this.props.showRating) {
      counter += 1;
    }

    return counter;
  }

  render() {
    return (
      <div>
        <Table columns={this.getTotalColumns()}
          maxHeight={this.state.tableHeight}
          search={(
            <TableSearch keyword={this.props.keyword}
              statusEnabled={this.props.statusEnabled}
              canSearchByRating={this.props.showRating}
              filter={this.filter.bind(this)} />
          )}>
          <TableHead>
            <TableRow>
              {this.getHeadCells()}
            </TableRow>
          </TableHead>
          <TableBody
            hasMore={this.props.hasMore}
            loadMore={this.loadMore.bind(this)}
            empty="There's nothing here! What about adding a supplier?">
            {this.getSuppliers()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
