const request = require('./Request');

const ApiToken = {
  list() {
    return request({
      method: 'get',
      url: '/api-token',
    });
  },
  create(attributes) {
    return request({
      method: 'post',
      url: '/api-token',
      data: attributes,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/api-token/${id}`,
    });
  },
};

module.exports = ApiToken;
