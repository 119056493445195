const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../Select/Select.react');
const Button = require('./RadialSelectButton.react');

const RadialSelect = ({ children, color, ...rest }) => (
  <Select button={Button} buttonProps={{ color }} {...rest}>
    {children}
  </Select>
);

RadialSelect.defaultProps = {
  helperText: undefined,
  errorText: undefined,
  color: undefined,
};

RadialSelect.propTypes = {
  /**
   * List containing suggestions that can be selected.
   */
  children: PropTypes.node.isRequired,
  /** Text to display below children * */
  helperText: PropTypes.string,
  /** Error message to display below children * */
  errorText: PropTypes.string,
  color: PropTypes.string,
};

module.exports = RadialSelect;
