const request = require('./Request');

const IntercompanyActivity = {
  // List of all pending intercompany activities for the company as client company
  list(from, to) {
    return request({
      method: 'get',
      url: `/intercompany/activities?from=${from}&to=${to}`,
    });
  },
};

module.exports = IntercompanyActivity;
