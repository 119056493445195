module.exports = class TaskNodePrevious {
  constructor(externalCost, markup, quantity, exists, levels = []) {
    this.externalCost = externalCost;
    this.markup = markup;
    this.quantity = quantity;
    this.exists = exists;
    this.levels = levels;
  }

  getLevels() {
    return this.levels;
  }

  getExternalCost() {
    return this.externalCost;
  }

  getMarkup() {
    return this.markup;
  }

  getQuantity() {
    return this.quantity;
  }

  getExists() {
    return this.exists;
  }

  toJson() {
    return {
      external_cost: this.getExternalCost(),
      markup: this.getMarkup(),
      quantity: this.getQuantity(),
      exists: this.getExists(),
      levels: this.getLevels().map((level) => level.toJson()),
    };
  }
};
