const React = require('react');
const Modal = require('./InfoModal.react');

const DisableRecurringModeModal = ({ onCancelClick }) => (
  <Modal title="Cannot select project" onCancelClick={onCancelClick}>
    If you want to select a project please
    {' '}
    <b>
      disable
      recurring mode
    </b>
    {' '}
    first.
  </Modal>
);

module.exports = DisableRecurringModeModal;
