const { connect } = require('react-redux');
const Component = require('../../components/advanced-search/PipelineAdvancedSearch.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  show: state.sidebarToShow === 'advanced-search',
  filters: state.filters,
  businessUnit: state.buFilter,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  type: state.type,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  applySearch: (filters) => dispatch(actions.applyAdvancedSearch(filters)),
  onClose: () => dispatch(actions.closeSidebar()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
