/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Avatar = require('../../../../../../../common/react/AvatarBox/Avatar.react');

module.exports = class MemberElement extends React.Component {
  getCompleteName() {
    return `${this.props.name} ${this.props.surname}`;
  }

  handleDelete() {
    this.props.onDelete(this.props.id);
  }

  getDelete() {
    if (this.props.onDelete) {
      return (
        <button type="button"
          className="wethod-icon-button wethod-icon-button--medium project-canvas-kanban-sidebar__delete"
          onClick={this.handleDelete.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--black" />
        </button>
      );
    }
  }

  getStyle() {
    let style = 'project-canvas-kanban-sidebar-member__element';
    if (this.props.onDelete) style += ' project-canvas-kanban-sidebar__button-input--deletable';
    if (this.props.disabled) style += ' disabled';
    return style;
  }

  render() {
    return (
      <div className={this.getStyle()} key={this.props.id}>
        <div>
          <div className="project-canvas-menu-members__pic">
            <Avatar name={this.getCompleteName()} pic={this.props.pic} />
          </div>
          <div className="project-canvas-menu-members__result--name">
            {this.getCompleteName()}
          </div>
        </div>
        {this.getDelete()}
      </div>
    );
  }
};
