const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');
const Select = require('../../../../../../../common/react/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../../../../../../common/react/inputs/Select/SelectItem.react');
const TextArea = require('../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const PositiveButton = require('../../../../../../../common/react/Button/PositiveButton.react');

module.exports = class ApprovedNotificationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recipient: null,
      message: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onSkipClick = this.onSkipClick.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSendClick() {
    this.props.approveOrder(this.props.order, this.state.recipient, this.state.message);
  }

  onSkipClick() {
    this.props.approveOrder(this.props.order);
  }

  getRecipientOptions() {
    return this.props.recipients.map((recipient) => (
      <SelectItem key={recipient.id} value={recipient.id}>{recipient.alias}</SelectItem>));
  }

  getFeedback() {
    if (this.props.isSending) {
      return <div className="profile-contact-info__feedback">Sending...</div>;
    }
    return null;
  }

  getActions() {
    return (
      <div className="orders__approve-payment-modal__actions">
        <Button onClick={this.onSkipClick} disabled={this.props.isSending}>
          Skip notification
        </Button>
        <PositiveButton
          disabled={!this.canSend()}
          onClick={this.onSendClick}>
          Send
        </PositiveButton>
      </div>
    );
  }

  canSend() {
    return !this.props.isSending && this.state.recipient !== null;
  }

  render() {
    return (
      <Modal title="Approve payment"
        onCancelClick={this.props.onCancelClick}
        className="orders__approve-payment-modal">
        <div className="orders__approve-payment-modal__body">
          <p>
            Feel free to send a notification.
          </p>
          <Select placeholder="Send to..."
            disabled={this.props.isSending}
            value={this.state.recipient}
            onChange={this.onChange}
            name="recipient"
            empty="No recipients available"
            id="approve-notification-recipient">
            {this.getRecipientOptions()}
          </Select>
          <TextArea onChange={this.onChange}
            disabled={this.props.isSending}
            name="message"
            id="approve-notification-message"
            value={this.state.message}
            placeholder="Leave a message"
            rows={10} />
        </div>
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
