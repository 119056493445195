const React = require('react');
const PropTypes = require('prop-types');

const ValueCounter = ({ value, name }) => {
  const getClassName = () => {
    let style = 'wethod-advanced-filters-accordion-value-counter';
    if (value > 0) {
      style += ' wethod-advanced-filters-accordion-value-counter--visible';
    }
    return style;
  };

  return (
    <div className={getClassName()} data-testid={name}>{value}</div>
  );
};

ValueCounter.defaultProps = {
  value: 0,
  name: 'value-counter',
};

ValueCounter.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
};

module.exports = ValueCounter;
