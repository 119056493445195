/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class SectionParagraph extends React.Component {
  render() {
    return (
      <p className="briefing-section__paragraph">{this.props.children}</p>
    );
  }
};
