/* eslint-disable react/no-array-index-key */
const React = require('react');
const PlannedPerson = require('./PlannedPerson.react');

class PlannedPeople extends React.Component {
  getSize() {
    const size = this.props.people.length;
    if (size === 1) {
      return '1 person';
    }
    return `${size} people`;
  }

  getPlannedPeople() {
    return this.props.people.map((person, index) => <PlannedPerson name={person} key={index} />);
  }

  render() {
    return (
      <div className="planned-people">
        <div className="planned-people__size">{this.getSize()}</div>
        <div className="planned-people__list">
          {this.getPlannedPeople()}
        </div>
      </div>
    );
  }
}

module.exports = PlannedPeople;
