const React = require('react');
const AutocompleteFilter = require('../../../../../../../common/react/AdvancedSearch/filters/AutocompleteFilter/AutocompleteFilter.react');
const AutocompleteModel = require('../../../../../../../models/PipelineAutocomplete');

module.exports = class AccountFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getAccounts(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON().map((account) => account.hint),
        isLoading: false,
      }));
  }

  render() {
    return (
      <AutocompleteFilter name={this.props.name}
        label={this.props.label}
        placeholder="Search account"
        value={this.props.value}
        onChange={this.props.onChange}
        expanded={this.props.expanded}
        isLoading={this.state.isLoading}
        onFilter={this.onFilter}
        availableValues={this.state.availableItems} />
    );
  }
};
