const { connect } = require('react-redux');
const Component = require('../components/ModalController.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  toShow: state.modalToShow,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
