/* eslint-disable class-methods-use-this,react/sort-comp,consistent-return,jsx-a11y/anchor-is-valid,react/jsx-no-bind,prefer-destructuring */
const React = require('react');
const FollowUp = require('../../../containers/FollowUp/FollowUpController');

module.exports = class FollowUpPast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFollowUpForm: false,
    };

    this.iconClasses = {
      Call: 'wethod-icon-phone wethod-icon-phone--black',
      Email: 'wethod-icon-mail wethod-icon-mail--black',
      Meeting: 'wethod-icon-meeting wethod-icon-meeting--black',
    };
  }

  getCreationDate(day) {
    const momentDate = moment(day);
    const date = momentDate.format('dddd, D.M.YYYY');
    const hour = momentDate.format('HH:mm');
    return `${date} at ${hour}`;
  }

  getCreator() {
    return this.props.created_by;
  }

  getCreation() {
    const creator = this.getCreator();
    const date = this.getCreationDate(this.props.timestamp);
    if (creator) {
      return (
        <span>
          <span className="timeline-event-log__creator">{creator}</span>
          {' '}
          -
          <span
            className="timeline-event-log__creation-time">
            {date}
          </span>
        </span>
      );
    }
    return (
      <span className="timeline-event-log__creation-time">{date}</span>
    );
  }

  scrollToEvent(id) {
    $('html, body').animate({
      scrollTop: ($(`#${id}`).offset().top - 126),
    }, 500);
  }

  handleParentEventTypeClick(e) {
    e.preventDefault();
    this.scrollToEvent(this.parentEvent.event.id);
  }

  getLogoClass() {
    return this.iconClasses[this.props.event.type.label];
  }

  getParentLink() {
    if (this.parentEvent) {
      return (
        <div className="timeline-event-log__creation">
          This is a follow-up to the
          {' '}
          <a href="#"
            onClick={this.handleParentEventTypeClick.bind(this)}>
            {this.parentEvent.event.type.label}
          </a>
          {' '}
          which
          {' '}
          {this.parentEvent.created_by}
          {' '}
          had
          on
          {' '}
          {this.getCreationDate(this.parentEvent.timestamp)}
        </div>
      );
    }
    return null;
  }

  getDoneButtonText() {
    const done = this.props.event.confirmed;
    if (!done) {
      return 'Mark as done';
    }
    return 'Mark as not done';
  }

  getContentStyle() {
    const done = this.props.event.confirmed;
    if (!done) {
      return { borderLeft: '3px solid #DB4D69' };
    }
  }

  getFollowUpButton() {
    if (this.props.event.followed_by === null) {
      return (
        <div className="wethod-button"
          onClick={() => {
            this.showFollowUpForm();
          }}>
          + Follow up
        </div>
      );
    }
  }

  showFollowUpForm() {
    this.setState({ showFollowUpForm: true });
  }

  hideFollowUp() {
    this.props.closeForm();
    this.setState({ showFollowUpForm: false });
  }

  handleDeleteClick(e) {
    e.preventDefault();
    this.props.openModal(this.props.event);
  }

  handleEditClick(e) {
    e.preventDefault();
    this.props.openEditForm(this.props.event);
  }

  handleDoneClick(e) {
    e.preventDefault();
    const event = {
      ...this.props.event,
      confirmed: !this.props.event.confirmed,
      type: this.props.event.type.id,
    };
    delete event.followed_by;
    delete event.created_by;
    this.props.save(event);
  }

  render() {
    this.parentEvent = this.props.events
      .filter((event) => event.event.followed_by === this.props.event.id)[0];

    return (
      <div className="timeline-event-past" id={this.props.event.id}>
        <div className="timeline-event-past__type">
          <div className="timeline-event-past__type-logo-container">
            <div className={this.getLogoClass()} />
          </div>
          <div className="timeline-event-past__type-name">{this.props.event.type.label}</div>
        </div>
        <div className="timeline-event-past__creation">
          {this.getCreation()}
          {this.getParentLink()}
        </div>
        <div className="timeline-event-past__content" style={this.getContentStyle()}>
          <div className="timeline-event-past__description">{this.props.event.description}</div>
          <div className="timeline-event-past__actions">
            <div className="timeline-event-past__actions-left">
              {this.getFollowUpButton()}
            </div>
            <div className="timeline-event-past__actions-right">
              <div className="wethod-button"
                onClick={this.handleDoneClick.bind(this)}>
                {this.getDoneButtonText()}
              </div>
              <div className="clients__delete-button wethod-icon-button"
                onClick={this.handleDeleteClick.bind(this)}>
                <div className="wethod-icon-medium-delete" />
              </div>
              <a href="#"
                className="clients__delete-button wethod-icon-button"
                onClick={this.handleEditClick.bind(this)}>
                <div className="wethod-icon-medium-edit" />
              </a>
            </div>
          </div>
        </div>
        <FollowUp showForm={this.state.showFollowUpForm}
          {...this.props}
          hideFollowUp={this.hideFollowUp.bind(this)}
          openEditForm={this.showFollowUpForm.bind(this)} />
      </div>
    );
  }
};
