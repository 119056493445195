const React = require('react');
const SearchAndSelectModal = require('../../../SearchAndSelectModal/SearchAndSelectModal.react');
const ProductModel = require('../../../../../../../../models/Product');
const ProductOption = require('./ProductOption.react');

const MISSING_PERMISSION_ERROR = 'MISSING_PERMISSION_ERROR';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

module.exports = class ProductInsertModalContents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      isLoading: false,
      error: '',
      checked: [],
    };

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onInsertClick = this.onInsertClick.bind(this);
    this.getProducts = this.getProducts.bind(this);
  }

  onCheckboxChange(e) {
    const changedProductId = parseInt(e.target.value);
    const newChecked = [...this.state.checked];

    if (this.state.checked.includes(changedProductId)) {
      this.setState({ checked: newChecked.filter((id) => id !== changedProductId) });
    } else {
      this.setState({ checked: newChecked.concat(changedProductId) });
    }
  }

  onInsertClick() {
    const productsToInsert = this.state.checked.map((id, index) => ({
      id,
      sort: this.props.nextTaskOrder + index,
    }));

    this.props.insertProducts(this.props.areaId, productsToInsert);
  }

  getProducts(keyword = '') {
    this.setState({ isLoading: true, error: '' });
    const filters = {
      search: keyword,
      available: true,
    };
    ProductModel.list(filters)
      .done((products) => this.setState({ products }))
      .fail((message, code) => this.setState({ error: ProductInsertModalContents.getError(code) }))
      .always(() => this.setState({ isLoading: false }));
  }

  getErrorMessage() {
    if (this.missingPermissions()) {
      return 'You are not allowed to see products. You can change this from Permissions';
    }
    return '';
  }

  static getError(code) {
    if (code === 403) {
      return MISSING_PERMISSION_ERROR;
    }
    return UNKNOWN_ERROR;
  }

  /**
   * Return true if no product is currently selected.
   * @return {boolean}
   */
  emptySelection() {
    return this.state.checked.length === 0;
  }

  missingPermissions() {
    return this.state.error === MISSING_PERMISSION_ERROR;
  }

  isSaving() {
    return this.props.isPending('insert-products');
  }

  /**
   * Return true if given productId is currently checked.
   * @param productId
   * @return {boolean}
   */
  isChecked(productId) {
    return this.state.checked.includes(productId);
  }

  hasNoItems() {
    return this.state.products.length === 0;
  }

  renderProducts() {
    return this.state.products
      .map((product) => {
        const checked = this.isChecked(product.id);

        return (
          <ProductOption key={`product-${product.id}`}
            name={product.name}
            description={product.description}
            checked={checked}
            id={product.id}
            onChange={this.onCheckboxChange} />
        );
      });
  }

  render() {
    return (
      <SearchAndSelectModal hasNoOptions={this.hasNoItems()}
        loadOptions={this.getProducts}
        isSaving={this.isSaving()}
        isLoading={this.state.isLoading}
        title="Add product"
        closeModal={this.props.closeModal}
        isEmptySelection={this.emptySelection()}
        onSaveClick={this.onInsertClick}
        saveButtonLabel="Insert"
        searchPlaceholder="Search for products"
        emptyMessage="No products found"
        errorMessage={this.getErrorMessage()}>
        {this.renderProducts()}
      </SearchAndSelectModal>
    );
  }
};
