const PropTypes = require('prop-types');
const React = require('react');
const Star = require('./Star.react');

class Rating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedValue: null, // The value on which the user's pointer actually is, if exists
    };
  }

  onMouseEnterStar(value) {
    if (!this.props.readOnly) {
      this.setState({ focusedValue: parseInt(value) });
    }
  }

  onMouseLeaveStar() {
    if (!this.props.readOnly) {
      this.setState({ focusedValue: null });
    }
  }

  getStars() {
    return new Array(this.props.max).fill(0)
      .map((star, index) => {
        const value = index + 1;
        return (
          <Star label={`${value} star`}
            key={value}
            readOnly={this.props.readOnly}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            name={this.props.name}
            full={this.isStarFull(index)}
            onMouseEnter={this.onMouseEnterStar.bind(this)}
            onMouseLeave={this.onMouseLeaveStar.bind(this)}
            checked={value === this.props.value}
            value={value} />
        );
      });
  }

  getStyle() {
    let style = 'wethod-rating';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  getNumericValue() {
    if (this.props.showNumericValue) {
      return <div className="wethod-rating__value">{this.props.value}</div>;
    }
    return null;
  }

  /**
   * Return if a star must be displayed as full.
   * A star is full if one of the following is true:
   * - Its index is lower than the current rating value
   * - Its index is lower than the current focused rating value
   * @param index
   * @returns {boolean}
   */
  isStarFull(index) {
    return index < this.props.value || index < this.state.focusedValue;
  }

  render() {
    return (
      <div className={this.getStyle()}>
        <div className="wethod-rating__star-list">{this.getStars()}</div>
        {this.getNumericValue()}
      </div>
    );
  }
}

Rating.defaultProps = {
  name: 'rating',
  onChange: null,
  disabled: false,
  readOnly: false,
  className: null,
  max: 5,
  value: 0,
  showNumericValue: false,
};

Rating.propTypes = {
  /**
   * Used to group stars. All star in the same rating must have the same name.
   */
  name: PropTypes.string,
  /**
   * Used to customize CSS class.
   */
  className: PropTypes.string,
  /**
   * The current rating.
   */
  value: PropTypes.number,
  /**
   * The max possible rating.
   */
  max: PropTypes.number,
  /**
   * Callback fired when a star is selected.
   *
   * @param {string} value The `value` of the selected star
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the switch will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the switch cannot be edited.
   */
  readOnly: PropTypes.bool,
  /**
   * If `true`, show numeric 'value' near the input.
   */
  showNumericValue: PropTypes.bool,
};

module.exports = Rating;
