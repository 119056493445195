const { connect } = require('react-redux');
const Component = require('../components/ProductionPlanList.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projects: state.projects,
  isWaiting: state.waitingFor.filter((key) => key === 'load-projects' || key === 'filter-projects').length > 0,
  keyword: state.keyword,
  typeFilter: state.typeFilter,
  hasMore: !state.lastPage,
  size: state.size,
  bu: state.buFilter,
});

const mapDispatchToProps = (dispatch) => ({
  loadMore: (size, page, keyword, type, bu) => dispatch(actions
    .loadProjects(size, page, keyword, type, bu)),
  filter: (size, keyword, type, bu) => dispatch(actions.filterProjects(size, keyword, type, bu)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
