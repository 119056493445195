const React = require('react');
const { CSSTransition } = require('react-transition-group');
const VersionService = require('../services/VersionService');
const CalculatorManager = require('../containers/Calculator/CalculatorManager');
const Header = require('./Header/Header.react');
const PermissionManager = require('../containers/PermissionManager');
const Footer = require('../containers/Footer/Footer');
const SocketManager = require('./SocketManager.react');
const SectionHeader = require('../../../../../common/react/SectionHeader/components/SectionHeader.react');
const Modal = require('../containers/Modal');
const ProjectSidebar = require('../containers/ProjectSidebar/ProjectSidebar');
const RequestManager = require('../containers/RequestManager');
const InteractionBlocker = require('./InteractionBlocker.react');
const Calculator = require('./Calculator/Calculator.react');

module.exports = class BudgetContent extends React.Component {
  constructor(props) {
    super(props);

    this.listeners = [{
      eventName: 'budget-info-update',
      callback: props.updateInfoFromCollaborator,
    }, {
      eventName: 'budget-area-update',
      callback: props.updateAreaFromCollaborator,
    }, {
      eventName: 'budget-task-info-update',
      callback: props.updateTaskInfoFromCollaborator,
    }, {
      eventName: 'budget-task-level-amount-update',
      callback: props.updateTaskLevelAmountFromCollaborator,
    }, {
      eventName: 'budget-task-values-update',
      callback: props.updateTaskValuesFromCollaborator,
    }, {
      eventName: 'budget-delete-task',
      callback: props.deleteTaskFromCollaborator,
    }, {
      eventName: 'budget-create-tasks',
      callback: props.createTaskFromCollaborator,
    }, {
      eventName: 'budget-create-area',
      callback: props.createAreaFromCollaborator,
    }, {
      eventName: 'budget-task-update-order',
      callback: props.updateTaskOrderFromCollaborator,
    }, {
      eventName: 'budget-change-status',
      callback: props.changeStatusFromCollaborator,
    }, {
      eventName: 'budget-remove-job-title',
      callback: props.removeJobTitleFromCollaborator,
    }, {
      eventName: 'budget-add-job-title',
      callback: props.insertJobTitleFromCollaborator,
    }, {
      eventName: 'budget-change-pricelist',
      callback: props.changePricelistFromCollaborator,
    }];
  }

  componentDidMount() {
    this.props.getVersionList();
    this.props.getProjectStatusCorrection();
  }

  componentDidUpdate(prevProps) {
    const changedVersion = prevProps.currentVersion !== this.props.currentVersion;
    const changedBudgetStatus = prevProps.budgetStatus !== this.props.budgetStatus;
    const loadedVersionList = prevProps.versions.length === 0 && this.props.versions.length > 0;

    if (changedVersion || changedBudgetStatus || loadedVersionList) {
      this.getVersionComparison();
    }
  }

  getVersionComparison() {
    if (this.needComparison()) {
      const from = this.props.currentVersion;
      const to = from - 1;
      this.props.getVersionComparison([from, to]);
    }
  }

  /**
   * Check if currentVersion is the last know version.
   * @return {boolean}
   */
  currentIsLastVersion() {
    return this.props.currentVersion === this.props.versions.length;
  }

  needComparison() {
    return VersionService.isSubmitted(this.props.budgetStatus)
      && this.props.currentVersion > 1 && this.currentIsLastVersion();
  }

  render() {
    return (
      <div className="wethod-section-body wethod-budget">
        <SectionHeader
          sectionId={`budget-${this.props.projectId}`}
          current_section="Budget"
          preview_anchor_id="overview"
          tour_id={395352}
          tour_start_page="pipeline/budget/14"
          helper_url="budget/index/"
          big
          bookmark_fragment={`project/${this.props.projectId}/budget`} />
        <SocketManager listeners={this.listeners}>
          <div className="wethod-budget-content">
            <div className="wethod-budget-scrollable-content">
              <Header />
              <PermissionManager>
                <CalculatorManager>
                  <Calculator budgetLevels={this.props.budgetLevels} />
                </CalculatorManager>
              </PermissionManager>
            </div>
            <Footer />
          </div>
        </SocketManager>
        <Modal />
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <ProjectSidebar />
        </CSSTransition>
        <RequestManager>
          <InteractionBlocker />
        </RequestManager>
      </div>
    );
  }
};
