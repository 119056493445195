/* eslint-disable react/no-array-index-key */
const React = require('react');
const $ = require('jquery');
const moment = require('moment');
const Table = require('../../../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../../../common/react/Table2/TableBody.react');
const TableRow = require('../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../common/react/Table2/TableHead.react');
const Project = require('../containers/DailyProject');
const DaySelect = require('../containers/DaySelect');
const Loader = require('../../../../../../../common/react/Loader/Loader.react');
const FeatureService = require('../../services/FeatureService');
const HolidayTextFormatter = require('../../common/HolidayTextFormatter.react');
const CapacityManager = require('../containers/CapacityManager');
const PermissionManager = require('./PermissionManager.react');

module.exports = class DailyTimesheet extends React.Component {
  constructor(props) {
    super(props);

    this.dailyHours = null;
    this.daysList = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  }

  getTableHeight() {
    const daySelectHeight = $('.timesheet-weekly__day-select')
      .is(':visible') ? $('.timesheet-weekly__day-select').outerHeight(true) : 0;
    return this.props.tableHeight ? this.props.tableHeight - daySelectHeight : 0;
  }

  getSelectedDayLabel() {
    return moment(this.props.date).format('ddd').toLowerCase();
  }

  /**
   * Return capacity for the selected day.
   * @return {number}
   */
  getSelectedDayCapacity() {
    if (FeatureService.isEmployeeCapacityEnabled()) {
      return this.props.getDayCapacity(this.props.date);
    }
    return 8;
  }

  getEmptyContent() {
    if (this.props.isWaitingForProjects) return <Loader />;
    return (
      <span>
        There's nothing here! What about
        <a href="/#pipeline/projects"> starting a new project</a>?
      </span>
    );
  }

  getProjects() {
    if (!this.props.isWaitingForProjects) {
      return this.props.projects.map((project) => {
        if (FeatureService.isEmployeeCapacityEnabled()) {
          return (
            <CapacityManager key={project.project.id}>
              <PermissionManager>
                <Project
                  project={project}
                  selectedDayLabel={this.getSelectedDayLabel()}
                  internationalCalendar={this.props.internationalCalendar}
                  isWaitingProject={this.props.isWaitingProject}
                  updateHours={this.props.updateHours}
                  deleteHours={this.props.deleteHours} />
              </PermissionManager>
            </CapacityManager>
          );
        }
        return (
          <PermissionManager>
            <Project
              key={project.project.id}
              project={project}
              selectedDayLabel={this.getSelectedDayLabel()}
              internationalCalendar={this.props.internationalCalendar}
              isWaitingProject={this.props.isWaitingProject}
              updateHours={this.props.updateHours}
              deleteHours={this.props.deleteHours} />
          </PermissionManager>
        );
      });
    }
    return [];
  }

  getTotalColumns() {
    return this.props.internationalCalendar ? 14 : 12;
  }

  getTotalHours() {
    return this.props.allProjects.reduce((sum, project) => sum + project.hours, 0);
  }

  /**
   * Return total amount of week capacity.
   * @return {number}
   */
  getWeeklyCapacity() {
    if (FeatureService.isEmployeeCapacityEnabled()) {
      return this.props.getWeekCapacity();
    }
    return 40;
  }

  getHours(day) {
    return this.props.allProjects.reduce((sum, project) => sum + project.detail[day], 0);
  }

  getDailyHours() {
    const dailyHours = [];
    this.daysList.map((day) => dailyHours[day] = this.getHours(day));
    return dailyHours;
  }

  getSummaryCells() {
    let { daysList } = this;
    if (!this.props.internationalCalendar) {
      daysList = daysList.slice(0, 5);
    }
    const days = daysList.map((day) => {
      const date = moment(this.props.date).day(day).format('YYYY-MM-DD');
      const dailyCapacity = FeatureService.isEmployeeCapacityEnabled()
        ? this.props.getDayCapacity(date) : 8;
      const workedHours = this.dailyHours[day];
      if (FeatureService.isEmployeeCapacityEnabled() && this.props.isHoliday(date)) {
        return (
          <TableCell
            key={day}
            className="timesheet-weekly__cell-day hidden-mobile">
            <HolidayTextFormatter>{`${workedHours}/${dailyCapacity}`}</HolidayTextFormatter>
          </TableCell>
        );
      }
      return (
        <TableCell
          key={day}
          className="timesheet-weekly__cell-day hidden-mobile">
          {`${workedHours}/${dailyCapacity}`}
        </TableCell>
      );
    });
    if (FeatureService.isEmployeeCapacityEnabled() && this.props.isHoliday(this.props.date)) {
      return []
        .concat(<TableCell key="spacer" className="timesheet-weekly__cell-spacer" />)
        .concat(days)
        .concat(
          <TableCell
            key="hours"
            className="timesheet-weekly__cell-total hidden-mobile">
            {`${this.getTotalHours()}/${this.getWeeklyCapacity()}`}
          </TableCell>,
        )
        .concat(
          <TableCell
            key="selected"
            className="timesheet-weekly__cell-total timesheet-weekly__cell-total--mobile">
            <HolidayTextFormatter>{`${this.dailyHours[this.getSelectedDayLabel()]}/${this.getSelectedDayCapacity()}`}</HolidayTextFormatter>
          </TableCell>,
        );
    }
    return []
      .concat(<TableCell key="spacer" className="timesheet-weekly__cell-spacer" />)
      .concat(days)
      .concat(
        <TableCell
          key="hours"
          className="timesheet-weekly__cell-total hidden-mobile">
          {`${this.getTotalHours()}/${this.getWeeklyCapacity()}`}
        </TableCell>,
      )
      .concat(
        <TableCell
          key="selected"
          className="timesheet-weekly__cell-total timesheet-weekly__cell-total--mobile">
          {`${this.dailyHours[this.getSelectedDayLabel()]}/${this.getSelectedDayCapacity()}`}
        </TableCell>,
      );
  }

  getHeadCells() {
    let { daysList } = this;
    if (!this.props.internationalCalendar) {
      daysList = daysList.slice(0, 5);
    }

    const days = daysList.map((day, index) => {
      const date = moment(this.props.date).day(day).format('YYYY-MM-DD');
      const label = day.charAt(0).toUpperCase();
      if (FeatureService.isEmployeeCapacityEnabled()) {
        if (this.props.isHoliday(date)) {
          return (
            <TableCell key={index}
              className="timesheet-weekly__cell-day hidden-mobile">
              <HolidayTextFormatter>{label}</HolidayTextFormatter>
            </TableCell>
          );
        }
        return (
          <TableCell key={index}
            className="timesheet-weekly__cell-day hidden-mobile">
            {label}
          </TableCell>
        );
      }
      return (
        <TableCell key={index}
          className="timesheet-weekly__cell-day hidden-mobile">
          {label}
        </TableCell>
      );
    });
    return [
      <TableCell key="options" className="timesheet-weekly__cell-options" />,
      <TableCell key="project" className="timesheet-weekly__cell-project">Project</TableCell>,
      <TableCell key="auto_timesheet" className="timesheet-weekly__cell-automatic" />,
      <TableCell key="job_order" className=" timesheet-weekly__cell-job-order hidden-mobile">
        Job order
      </TableCell>,
      <TableCell key="notes" className="timesheet-weekly__cell-notes hidden-mobile">
        Notes
      </TableCell>,
    ].concat(days)
      .concat(
        <TableCell key="hours" className="timesheet-weekly__cell-total hidden-mobile">Tot.
          Hours
        </TableCell>,
      )
      .concat(
        <TableCell
          key="selected"
          className="timesheet-weekly__cell-total timesheet-weekly__cell-total--mobile">
          Hours
        </TableCell>,
      );
  }

  render() {
    this.dailyHours = this.getDailyHours();
    return (
      <div>
        <DaySelect
          isHoliday={FeatureService.isEmployeeCapacityEnabled() ? this.props.isHoliday : null}
          isWaiting={this.props.isWaiting}
          date={this.props.date}
          dailyHours={this.dailyHours}
          internationalCalendar={this.props.internationalCalendar} />
        <Table columns={this.getTotalColumns()}
          maxHeight={this.getTableHeight()}
          search={this.props.search}>
          <TableHead>
            <TableRow className="timesheet-weekly__row">
              {this.getHeadCells()}
            </TableRow>
            <TableRow className="timesheet-weekly__summary">
              {this.getSummaryCells()}
            </TableRow>
          </TableHead>
          <TableBody
            editable
            hasMore={this.props.hasMore}
            loadMore={this.props.loadMore}
            empty={this.getEmptyContent()}>
            {this.getProjects()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
