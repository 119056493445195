const React = require('react');
const SectionHeader = require('../../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const MonthSelector = require('../containers/MonthSelector');
const Actions = require('../containers/Actions');
const Content = require('../containers/Content');

module.exports = class Briefing extends React.Component {
  componentDidMount() {
    this.props.getBriefing(this.props.date);
  }

  render() {
    return (
      <div className="briefing wethod-section-body">
        <SectionHeader current_section="Monthly Briefing" helper_url="reports/index/#monthly-briefing" />
        <div className="briefing-actions">
          <MonthSelector />
        </div>
        <Content actions={<Actions />} />
      </div>
    );
  }
};
