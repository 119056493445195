const React = require('react');
const PropTypes = require('prop-types');
const Pic = require('../ProfilePic.react');

require('./style.scss');

// eslint-disable-next-line react/prop-types
const Avatar = ({ name, pic, style, initialsOnly, size }) => {
  const getFormattedName = () => {
    if (initialsOnly) {
      const nameWords = name.trim().split(' ');
      const firstWord = nameWords[0];

      if (nameWords.length > 1) {
        const lastWord = nameWords[nameWords.length - 1];
        return `${firstWord.charAt(0)}${lastWord.charAt(0)}`;
      }

      return firstWord.charAt(0);
    }
    return name;
  };

  const getClassName = () => `wethod-avatar wethod-avatar--${size}`;

  return (
    <div className={getClassName()} style={style}>
      <Pic name={getFormattedName()} pic={pic} />
    </div>
  );
};

Avatar.defaultProps = {
  pic: '',
  initialsOnly: true,
  size: 'small',
};

Avatar.propTypes = {
  /**
   * Avatar name.
   */
  name: PropTypes.string.isRequired,
  /**
   * If name must be formatted to display only initials of the first and last word.
   */
  initialsOnly: PropTypes.bool,
  /**
   * URL of an image that visually represents this avatar.
   */
  pic: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'big']),
};

module.exports = Avatar;
