/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');
const Body = require('../../../containers/modals/Portfolio/PortfolioBody');

module.exports = class PortfolioModal extends React.Component {
  render() {
    return (
      <Modal title="Edit Portfolio" onCancelClick={this.props.onCancelClick}>
        <Body />
      </Modal>
    );
  }
};
