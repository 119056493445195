/* eslint-disable consistent-return,class-methods-use-this */
const React = require('react');
const Pic = require('../../../../settings/profile/components/ProfilePicPicker.react');
const ReadOnlyField = require('../../../../../../../common/react/material-input/SidebarReadOnlyField.react');

module.exports = class ContactReadOnlyDetails extends React.Component {
  getPhoneNumber() {
    if (this.props.client.phones) return this.props.client.phones.number;
  }

  getName() {
    let name = this.props.client.name ? this.props.client.name : '';
    name = this.props.client.surname ? `${name} ${this.props.client.surname}` : name;

    return name;
  }

  getUrl(link) {
    if (link) {
      if (link.indexOf('http') !== -1) return link;
      return `http://${link}`;
    }
    return '';
  }

  // TODO: change PicPicker!!
  render() {
    const company = this.props.client.client ? this.props.client.client : {};
    return (
      <div>
        <div className="wethod-sidebar__picture">
          <Pic name={this.getName()} editable={false} />
        </div>
        <ReadOnlyField placeholder="Name" value={this.props.client.name}>
          <span className="wethod-icon wethod-icon-pm" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Surname" value={this.props.client.surname}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Company" value={company.corporate_name} link={`/#finance/client/company/${company.id}`}>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Email" value={this.props.client.email} link={`mailto:${this.props.client.email}`}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Phone" value={this.getPhoneNumber()} link={`tel:${this.getPhoneNumber()}`}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="LinkedIn" value={this.props.client.linkedin} link={this.getUrl(this.props.client.linkedin)}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Role" value={this.props.client.role}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Notes" value={this.props.client.notes}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </ReadOnlyField>
      </div>
    );
  }
};
