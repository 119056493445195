const React = require('react');
const Button = require('../../../../../../../common/react/Button/RoundedButton.react');
const ArchivedFilter = require('../../containers/job-title/ArchivedFilter');

const Actions = ({ onAdd, isAddDisabled }) => (
  <div className="company-settings-margin-top-xl company-settings-flex--horizontal-spaced">
    <Button onClick={onAdd}
      disabled={isAddDisabled}
      className="company-settings__button company-settings__button--blue company-settings-margin-bottom-xl">
      + Add job title
    </Button>
    <ArchivedFilter />
  </div>
);

module.exports = Actions;
