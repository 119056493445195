const React = require('react');
const IconButtonTooltip = require('../../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

const SupplierSidebarActions = ({ onDelete }) => (
  <div>
    <IconButtonTooltip label="Delete supplier" onClick={onDelete} borderless>
      <span className="wethod-icon-delete wethod-icon-delete--black" />
    </IconButtonTooltip>
  </div>
);

module.exports = SupplierSidebarActions;
