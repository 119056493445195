const React = require('react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const EmployeesRow = require('./EmployeesRow.react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const Loader = require('../../../../../../common/react/Loader/Loader.react');
const Typography = require('../../../../../../common/react/Typography/Typography.react');

module.exports = class EmployeesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };
  }

  handleSort(col, order) {
    if (!this.props.isWaiting) {
      this.props.sort(col, order);
    }
  }

  handleMouseEnter() {
    this.setState({ showTooltip: true });
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  getCategories() {
    const timeFilter = TimeFilterFactory(this.props.timeFilter);
    return timeFilter
      .getBreakpoints()
      .map((breakpoint) => {
        const label = breakpoint.getLabel();
        return <Typography weight="bold" key={label}>{label}</Typography>;
      });
  }

  getSort(col) {
    const currentSort = this.props.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  getLoadedEmployees() {
    return this.props.employees.slice(0, this.props.page * this.props.size);
  }

  getEmployees() {
    const employees = this.getLoadedEmployees();

    return employees.map((employee) => (
      <EmployeesRow
        key={employee.id}
        employee={employee}
        levels={this.props.levels}
        selectedLevel={this.props.selectedLevel} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return 'No employees found';
  }

  hasMore() {
    const loaded = this.props.page * this.props.size;
    return this.props.employees.length > loaded;
  }

  render() {
    return (
      <Table columns={4} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="name" className="chargeability__column-name">
              <Typography weight="bold">Team member</Typography>
            </TableCell>
            <TableCell name="trend" className="chargeability__column-trend">
              {this.getCategories()}
            </TableCell>
            <TableCell name="avg_chargeability"
              colSpan={2}
              onSort={this.handleSort.bind(this)}
              sort={this.getSort('avg_chargeability')}
              className="chargeability__column-avg"
              onMouseEnter={this.handleMouseEnter.bind(this)}
              onMouseLeave={this.handleMouseLeave.bind(this)}>
              <Typography weight="bold">Avg.</Typography>
            </TableCell>
            {
              this.state.showTooltip && (
                <TableCell className="chargeability__tooltip tooltipReact">
                  <span style={{ position: 'relative', bottom: '50%' }}>
                    Average chargeability
                  </span>
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.props.loadEmployees}
          empty={this.getEmptyContent()}>
          {this.getEmployees()}
        </TableBody>
      </Table>
    );
  }
};
