const React = require('react');
const PropTypes = require('prop-types');
const TableEmptyRow = require('./TableEmptyRow.react');
const TableLoadingRow = require('./TableLoadingRow.react');
const TableColumnList = require('./models/TableColumnList');

/**
 * An expandable table body able to:
 * - calculate scrollbar width, if present
 * - calculate page size (contained rows are always paginated). Page size is based on given
 * maxBodyHeight
 * - load more results when scroll reach the bottom
 * - show empty row if there are non results available
 * - show loading row when more results are coming
 */
class TableBody extends React.Component {
  constructor(props) {
    super(props);

    this.bodyRef = null;
    // WARNING: fixed row height leads to miscalculation in
    // pagination when the actual row height is smaller
    this.rowHeight = this.props.editable ? 64 : 52;
    this.pageSize = null;

    this.ref = this.ref.bind(this);
  }

  componentDidMount() {
    if (!this.isEmpty()) {
      this.props.updateScrollBarWidth(this.getScrollBarWidth());
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.isLoading()) {
      const nextScrollBarWidth = this.getScrollBarWidth();
      // When props.bodyHeight is set, load first page
      if (this.pageSize === null) {
        this.pageSize = Math.ceil(this.props.maxBodyHeight / this.rowHeight);
        this.props.loadMore(this.pageSize, 1);
      }
      // If scrollBarWidth changes, update it
      if (prevProps.scrollBarWidth !== nextScrollBarWidth) {
        this.props.updateScrollBarWidth(nextScrollBarWidth);
      }
    }
  }

  onScroll(e) {
    if (!this.isLoading() && this.props.hasMore && this.boundReached(e.target.scrollTop)) {
      const page = Math.ceil(this.props.children.length / this.pageSize);
      this.props.loadMore(this.pageSize, page + 1);
    }
  }

  getScrollBarWidth() {
    const bodyWidth = this.bodyRef.offsetWidth;
    const rowRef = this.bodyRef.firstElementChild;
    const rowWidth = rowRef.offsetWidth;
    return bodyWidth - rowWidth;
  }

  getChildren() {
    const children = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, { visibleColumns: this.props.visibleColumns }));

    if (this.props.hasMore) {
      if (!this.isEmpty()) {
        return children.concat(<TableLoadingRow key="loading" columns={this.props.columns} />);
      }
      return <TableLoadingRow columns={this.props.columns} />;
    }
    if (!this.isEmpty()) {
      return children;
    }
    if (this.props.empty) { // If a custom TableEmpty is provided, use it
      return <TableEmptyRow columns={this.props.columns}>{this.props.empty}</TableEmptyRow>;
    }
    return <TableEmptyRow columns={this.props.columns} />;
  }

  getStyle() {
    const maxHeight = this.props.bodyHeight;
    if (Number.isInteger(maxHeight)) {
      return {
        maxHeight: `${maxHeight}px`,
        overflow: 'auto',
      };
    }
    return null;
  }

  getClassName() {
    return this.props.editable ? 'wethod-table__body wethod-table__body--editable' : 'wethod-table__body';
  }

  /**
   * Returns true if body is loading.
   * What can cause loading:
   * - props.bodyHeight not set: this means Table is still calculating the space available for
   * rendering
   *
   * @return {boolean}
   */
  isLoading() {
    return this.props.bodyHeight === null;
  }

  isEmpty() {
    return React.Children.toArray(this.props.children).length === 0;
  }

  /**
   * Returns true if body has been scrolled all the way down.
   * WARNING: fixed row height leads to miscalculation when the actual row height is smaller
   * @param scrollTop
   * @return {boolean}
   */
  boundReached(scrollTop) {
    const rowsHeight = this.props.children.length * this.rowHeight;
    return scrollTop >= rowsHeight - this.props.bodyHeight;
  }

  ref(ref) {
    this.bodyRef = ref;
    if (this.props.rootRef) {
      this.props.rootRef(this.bodyRef);
    }
  }

  render() {
    return (
      <tbody className={this.getClassName()}
        data-testid="wethod-table-body"
        ref={this.ref}
        style={this.getStyle()}
        onScroll={this.onScroll.bind(this)}>
        {this.getChildren()}
      </tbody>
    );
  }
}

TableBody.defaultProps = {
  editable: false,
  maxBodyHeight: null,
  bodyHeight: null,
  children: [],
  empty: null,
  columns: 0,
  scrollBarWidth: null,
  rootRef: null,
  visibleColumns: new TableColumnList(),
  updateScrollBarWidth: null,
};

TableBody.propTypes = {
  /**
   * The function to call to update scrollbar's width.
   *
   * @see Table2
   *
   * @param value {integer}
   */
  updateScrollBarWidth: PropTypes.func,
  /**
   * The function to call to load next results page.
   *
   * @see Table2
   *
   * @param size {integer}
   * @param page {integer}
   */
  loadMore: PropTypes.func.isRequired,
  /**
   * True if there are more results to display.
   */
  hasMore: PropTypes.bool.isRequired,
  /**
   * If rows can be edited inline.
   */
  editable: PropTypes.bool,
  maxBodyHeight: PropTypes.number,
  bodyHeight: PropTypes.number,
  children: PropTypes.node,
  empty: PropTypes.node,
  /**
   * The number of columns in the table.
   */
  columns: PropTypes.number,
  scrollBarWidth: PropTypes.number,
  rootRef: PropTypes.func,
  /**
   * List of visible TableColum.
   * If empty, this props is ignored and all column are considered visible.
   */
  visibleColumns: PropTypes.instanceOf(TableColumnList),
};

module.exports = TableBody;
