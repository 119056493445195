const React = require('react');

const NewButton = ({ onClick }) => (
  <button type="button"
    className="wethod-button"
    onClick={onClick}>
    New product
  </button>
);

module.exports = NewButton;
