const React = require('react');
const ModalContent = require('../../../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../../../common/react/Modal/ModalBody.react');
const ModalFooter = require('../../../../../../../../../common/react/Modal/ModalFooter.react');
const ModalFooterAction = require('../../../../../../../../../common/react/Modal/ModalFooterAction.react');
const TextArea = require('../../../../../../../../../common/react/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');

module.exports = class NotesModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { text: this.props.text };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChange(e) {
    this.setState({ text: e.target.value });
  }

  onSave() {
    this.props.saveInfo({ notes: this.state.text });
  }

  getFeedback() {
    return this.isSaving() ? 'Saving...' : '';
  }

  isSaving() {
    return this.props.isPending('save-budget-info');
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <TextArea value={this.state.text}
            rows={5}
            readOnly={!this.props.budgetIsEditable}
            onChange={this.onChange}
            name="text" />
        </ModalBody>
        <ModalFooter feedback={this.getFeedback()}>
          <ModalFooterAction onClick={this.props.onClose} disabled={!this.props.budgetIsEditable}>
            Close
          </ModalFooterAction>
          <ModalFooterAction onClick={this.onSave} disabled={!this.props.budgetIsEditable}>
            Save
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    );
  }
};
