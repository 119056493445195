const { connect } = require('react-redux');
const Component = require('../components/ProjectOptionsCell.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = (dispatch) => ({
  addFavourite: (projectId) => dispatch(actions.addFavorite(projectId)),
  removeFavourite: (projectId, favouriteId) => dispatch(actions
    .removeFavorite(projectId, favouriteId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
