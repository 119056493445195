const React = require('react');

module.exports = class Empty extends React.Component {
  getClassName() {
    return `wethod-widget__empty ${this.props.className}`;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="desk-widget-empty__image">
          {this.props.image}
        </div>
        <div className="desk-widget-empty__text">
          <div className="desk-widget-empty__text-title">{this.props.title}</div>
          <div className="desk-widget-empty__text-description">{this.props.description}</div>
        </div>
      </div>
    );
  }
};
