const React = require('react');
const PropTypes = require('prop-types');
const Avatar = require('./Avatar.react');
const ShowIf = require('../ShowIf/ShowIf.react');

const AvatarBox = ({
  className,
  avatars,
  max,
}) => {
  const list = avatars.slice(0, max).map((avatar, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Avatar key={index}
      style={{ zIndex: max - index }}
      name={avatar.name}
      pic={avatar.pic} />
  ));

  const excess = avatars.length - max;

  const tooMany = excess > 0;

  /**
   * Get correct class name for the component.
   */
  function getClassName() {
    const baseClass = 'wethod-avatar-box';
    if (className) {
      return `${baseClass} ${className}`;
    }
    return baseClass;
  }

  return (
    <div className={getClassName()}>
      <div className="wethod-avatar-box__list">
        {list}
      </div>
      <ShowIf condition={tooMany}>
        <Avatar name={`+${excess}`} initialsOnly={false} />
      </ShowIf>
    </div>
  );
};

AvatarBox.defaultProps = {
  max: 2,
  className: undefined,
};

AvatarBox.propTypes = {
  /**
   * List of avatars to display.
   */
  avatars: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    pic: PropTypes.string,
  })).isRequired,
  /**
   * Max amount of avatars to display. A counter is displayed to summarize others.
   */
  max: PropTypes.number,
  /**
   * Class name to add to the component.
   */
  className: PropTypes.string,
};

module.exports = AvatarBox;
