const constants = require('./constants');
const ActivityCollection = require('./models/ActivityCollection');
const ActivityStatusModel = require('../project-canvas/models/BoardAttributeValueModel');
const FavouriteModel = require('./models/FavouriteModel');
const SearchModel = require('./models/SearchModel');

Wethod.module('ReactBind', () => {
  const getActivitiesSuccess = (activities) => ({
    type: constants.GET_ACTIVITIES_SUCCESS,
    activities,
  });

  const getActivitiesRequest = () => ({
    type: constants.GET_ACTIVITIES_REQUEST,
  });

  module.exports.getActivities = () => (dispatch) => {
    dispatch(getActivitiesRequest());
    const model = new ActivityCollection();
    $.when(model.getList())
      .done((activities) => dispatch(getActivitiesSuccess(activities)));
  };

  const updateStatusSuccess = (itemId, status) => ({
    type: constants.UPDATE_STATUS_SUCCESS,
    itemId,
    status,
  });

  module.exports.updateActivityStatus = (status, itemId, boardId, attributeId) => (dispatch) => {
    const model = new ActivityStatusModel({ value: status }, {
      boardId,
      itemId,
      attributeId,
    });
    $.when(model.saveValue())
      .done(() => dispatch(updateStatusSuccess(itemId, status)));
  };

  const getFavouritesSuccess = (favourites) => ({
    type: constants.GET_FAVOURITES_SUCCESS,
    favourites,
  });

  const getFavouritesRequest = () => ({
    type: constants.GET_FAVOURITES_REQUEST,
  });

  module.exports.getFavourites = () => (dispatch) => {
    dispatch(getFavouritesRequest());
    const model = new FavouriteModel();
    $.when(model.getList())
      .done((favourites) => dispatch(getFavouritesSuccess(favourites)));
  };

  const deleteResourceFavouritesSuccess = (resourceId, resourceType) => ({
    type: constants.DELETE_RESOURCE_FAVOURITES_SUCCESS,
    resourceId,
    resourceType,
  });

  const deleteResourceFavouritesRequest = (resourceId, resourceType) => ({
    type: constants.DELETE_RESOURCE_FAVOURITES_REQUEST,
    resourceId,
    resourceType,
  });

  module.exports.deleteResourceFavourites = (resourceId, type) => (dispatch) => {
    dispatch(deleteResourceFavouritesRequest(resourceId, type));
    const model = new FavouriteModel();
    $.when(model.deleteResourceFavourites(resourceId, type))
      .done(() => dispatch(deleteResourceFavouritesSuccess(resourceId, type)));
  };

  const deleteSearchSuccess = (searchId) => ({
    type: constants.DELETE_SEARCH_SUCCESS,
    searchId,
  });

  const deleteSearchRequest = (searchId) => ({
    type: constants.DELETE_SEARCH_REQUEST,
    searchId,
  });

  module.exports.deleteSearch = (searchId) => (dispatch) => {
    dispatch(deleteSearchRequest(searchId));
    const model = new SearchModel();
    $.when(model.deleteSearch(searchId))
      .done(() => dispatch(deleteSearchSuccess(searchId)));
  };

  const updateSearchLabelSuccess = (searchId, search) => ({
    type: constants.UPDATE_SEARCH_LABEL_SUCCESS,
    searchId,
    search,
  });

  const updateSearchLabelRequest = (searchId) => ({
    type: constants.UPDATE_SEARCH_LABEL_REQUEST,
    searchId,
  });

  module.exports.updateSearchLabel = (searchId, search) => (dispatch) => {
    dispatch(updateSearchLabelRequest(searchId));
    const model = new SearchModel();
    $.when(model.updateSearchLabel(searchId, search))
      .done(() => dispatch(updateSearchLabelSuccess(searchId, search)));
  };

  module.exports.closeModal = () => ({
    type: constants.MODAL_CLOSE,
  });
});
