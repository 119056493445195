const React = require('react');

const PlanEditorBlock = ({
  children, onMouseMove, onMouseDown, highlighted, onClick,
}) => {
  const style = highlighted ? { background: 'rgba(72,165,249,0.3)' } : null;

  return (
    <div
      className="planning-calendar__editor-block"
      style={style}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onClick={onClick}>
      {children}
    </div>
  );
};

module.exports = PlanEditorBlock;
