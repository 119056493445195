const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('../Icon/Icon.react');
const IconButtonTooltip = require('../TooltipFixed/IconButtonTooltip.react');

const ModalHeader = ({ title, onClose }) => (
  <div className="wethod-modal__header">
    <h6 className="wethod-modal__title">{title}</h6>
    <IconButtonTooltip label="Close"
      className="wethod-modal__header-close-button"
      borderless
      onClick={onClose}>
      <Icon icon="discard" size="medium" />
    </IconButtonTooltip>
  </div>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = ModalHeader;
