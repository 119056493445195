/**
 * Check if the logged user has the permission to do the given action on the given project's planning.
 * @param {string} permission String permission as 'modify' or 'view', it will automatically append '_other' to check permission on other
 * @param {object} project project data object from server
 */
module.exports = (action, project) => {
  const projectType = project.type ? project.type : project.job_order_category;
  const userId = Wethod.userInfo.attributes.employee_id;
  const auth = Wethod.PlanningApp.getPermission(action);
  const authOther = Wethod.PlanningApp.getPermission(`${action}_other`);
  const { shared } = projectType;

  const isPm = project.pm.id === userId;
  const isAccount = project.account && project.account.id === userId;

  return shared || (auth && (isPm || isAccount || authOther));
};
