/* eslint-disable camelcase */
const React = require('react');
const DateRange = require('../../../../../../common/react/material-input/SidebarDateRangeDoubleSelection.react');
const Date = require('../../../../../../common/react/material-input/SidebarDateSelection.react');
const FieldFactory = require('./categories/FieldFactory.react');
const ReadOnlyDateRange = require('./ReadOnlyDateRangeField.react');

module.exports = ({
  editable, isRoundTrip, date, return_date, onChange, name,
  metadataService,
}) => {
  if (editable) {
    const dateGreaterThen = metadataService.getConstraintValue('date', 'greater_than');
    if (isRoundTrip) {
      return (
        <FieldFactory name="date"
          metadataService={metadataService}
          readOnlyComponent={(
            <ReadOnlyDateRange date={date}
              returnDate={return_date}
              isRoundTrip={isRoundTrip} />
)}>
          <DateRange name="range_date"
            placeholder={{ start: 'Departure', end: 'Return' }}
            value={{ start_date: date, end_date: return_date }}
            onChange={onChange}
            disableBeforeEqual={dateGreaterThen}
            required>
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          </DateRange>
        </FieldFactory>
      );
    }
    return (
      <FieldFactory name={name}
        metadataService={metadataService}
        readOnlyComponent={<ReadOnlyDateRange date={date} />}>
        <Date name={name}
          disableBeforeEqual={dateGreaterThen}
          placeholder="Departure"
          value={date}
          onChange={onChange}
          required>
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        </Date>
      </FieldFactory>
    );
  }
  return <ReadOnlyDateRange date={date} isRoundTrip={isRoundTrip} returnDate={return_date} />;
};
