/* eslint-disable react/sort-comp */
const React = require('react');

module.exports = class CalendarColumnDay extends React.Component {
  isWeekend() {
    const weekDay = moment(this.props.date).day();
    return weekDay === 0 || weekDay === 6;
  }

  getClassName() {
    let name = 'project-canvas-gantt__calendar-content-column project-canvas-gantt__calendar-column--day';
    if (this.isWeekend()) {
      name += ' project-canvas-gantt__calendar-column--day-weekend';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()} />
    );
  }
};
