'use strict';

Wethod.module('ReportApp.threeYP', function (threeYP, Wethod, Backbone, Marionette, $) {
  // model & collection select dei poject type
  this.ThreeYPModel = Wethod.Utility.BaseModel.extend({
    _bu: '',
    initialize: function (options) {
      this._bu = options.bu.join();
    },
    urlRoot: function () {
      var url = APIURL + '/report/revenue-pipeline';
      url += '?bu=' + this._bu;
      return url;
    },
  });

  // base graph model
  this.GraphModel = Wethod.Utility.BaseModel.extend();

  // model collection graph detail
  this.DetailModel = Wethod.Utility.BaseModel.extend();

  this.GraphDetailModel = Wethod.Utility.BaseModel.extend();
  this.GraphDetailsCollection = Wethod.Utility.BaseCollection.extend({
    model: threeYP.GraphDetailModel,
  });

  var API = {
    getThreeYP: function (bu) {
      var record = new threeYP.ThreeYPModel({ bu: bu });
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:report:threeYP', function (bu) {
    return API.getThreeYP(bu);
  });
});
