'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var SalesOverviewStore = require('../../apps/core/modules/report/sales-overview/store');
var SalesOverviewReducer = require('../../apps/core/modules/report/sales-overview/reducer');
var SalesOverview = require('../../apps/core/modules/report/sales-overview/index');
var BacklogStore = require('../../apps/core/modules/report/backlog/store');
var BacklogReducer = require('../../apps/core/modules/report/backlog/reducer');
var Backlog = require('../../apps/core/modules/report/backlog/index');
var BudgetChangelogStore = require('../../apps/core/modules/report/budget-changelog/store');
var BudgetChangelogReducer = require('../../apps/core/modules/report/budget-changelog/reducer');
var BudgetChangelog = require('../../apps/core/modules/report/budget-changelog/index');
var ReviewChangelogStore = require('../../apps/core/modules/report/project-review-changelog/store');
var ReviewChangelogReducer = require('../../apps/core/modules/report/project-review-changelog/reducer');
var ReviewChangelog = require('../../apps/core/modules/report/project-review-changelog/index');
var DemographicsStore = require('../../apps/core/modules/report/demographics/store');
var DemographicsReducer = require('../../apps/core/modules/report/demographics/reducer');
var Demographics = require('../../apps/core/modules/report/demographics/index');
var ChargeabilityStore = require('../../apps/core/modules/report/chargeability/store');
var ChargeabilityReducer = require('../../apps/core/modules/report/chargeability/reducer');
var Chargeability = require('../../apps/core/modules/report/chargeability/index');
var KudosStore = require('../../apps/core/modules/report/kudos/store');
var KudosReducer = require('../../apps/core/modules/report/kudos/reducer');
var Kudos = require('../../apps/core/modules/report/kudos/index');
var KnowledgeStore = require('../../apps/core/modules/report/knowledge/store');
var KnowledgeReducer = require('../../apps/core/modules/report/knowledge/reducer');
var Knowledge = require('../../apps/core/modules/report/knowledge/index');

Wethod.module('ReportApp', function (ReportApp, Wethod, Backbone, Marionette, $, _, Header) {
  ReportApp.Controller = {
    showBubblesInvoice: function () {
      var reportAuthorizationRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthorizationRequest).done(function (reportAuthorizationResponse) {
        var permissionDeniedView;

        if (_.isUndefined(reportAuthorizationResponse)) {
          permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        Wethod.ReportApp.authorization = reportAuthorizationResponse;

        // app layout
        var layout = new ReportApp.StructureLayoutView();
        if (Wethod.ReportApp.getPermission('view_bubbles')) {
          Wethod.ReportApp.BubblesInvoice.initBubblesInvoice(layout);
        } else {
          permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },

    showProductionValue: function () {
      var reportAuthorizationRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthorizationRequest).done(function (reportAuthorizationResponse) {
        var permissionDeniedView;
        if (_.isUndefined(reportAuthorizationResponse)) {
          permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        Wethod.ReportApp.authorization = reportAuthorizationResponse;

        // app layout
        var layout = new ReportApp.StructureLayoutView();

        // TODO da controllare se serve permesso generico
        if (Wethod.ReportApp.getPermission('view_3yp')) {
          Wethod.ReportApp.ProductionValue.InitProductionValue(layout);
        } else {
          permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },

    showProjectChangelog: function () {
      var reportAuthRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthRequest).done(function (reportAuthResponse) {
        Wethod.ReportApp.authorization = reportAuthResponse;
        if (_.isUndefined(reportAuthResponse) || !Wethod.ReportApp.getPermission('view_3yp')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        } else {
          var reportLayout = new ReportApp.StructureLayoutView();
          var projectChangelogLayout = new ReportApp.ProjectChangelog.Layout();
          var sectionHeaderModel = new Header.SectionHeaderModel({
            current_section: 'Projects Log',
            preview_anchor_id: 'project-changelog',
            helper_url: 'reports/index/#project-changelog',
          });
          var changelogHeaderRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });
          reportLayout.render();
          reportLayout.reportBody.show(projectChangelogLayout);
          projectChangelogLayout.headerSection.show(changelogHeaderRegion);
          projectChangelogLayout.placeFixedHeader();
        }
      });
    },

    showBudgetChangelog: function () {
      var reportAuthRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthRequest).done(function (reportAuthResponse) {
        Wethod.ReportApp.authorization = reportAuthResponse;
        if (_.isUndefined(reportAuthResponse) || !Wethod.ReportApp.getPermission('view_3yp')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        } else {
          // Layout
          var budgetChangelogLayoutView = new ReportApp.BudgetChangelogLayoutView();
          budgetChangelogLayoutView.render();

          // Render react components
          var reducerWrapper = new BudgetChangelogReducer();
          var store = BudgetChangelogStore(reducerWrapper.reduxReducer);
          var element = React.createElement(BudgetChangelog,
            { store: store });

          var container = document.getElementById('budgetChangelogRoot');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
        }
      });
    },

    showSalesOverview: function () {
      var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');

      $.when(isValueAsUnitRequest).done(function (isValueAsUnitResponse) {
        var isValueAsUnit = isValueAsUnitResponse ? isValueAsUnitResponse.get('enabled') : null;

        // Layout
        var salesOverviewLayoutView = new ReportApp.SalesOverviewLayoutView();
        salesOverviewLayoutView.render();

        // Render react components
        var reducerWrapper = new SalesOverviewReducer({
          isValueAsUnit: isValueAsUnit,
        });
        var store = SalesOverviewStore(reducerWrapper.reduxReducer);
        var element = React.createElement(SalesOverview, { store: store });
        var container = document.getElementById('salesOverviewRoot');

        if (container !== null) {
          ReactDOM.render(element, container);
        }
      });
    },

    showBacklog: function () {
      // Layout
      var backlogLayoutView = new ReportApp.BacklogLayoutView();
      backlogLayoutView.render();

      // Render react components
      var reducerWrapper = new BacklogReducer();
      var store = BacklogStore(reducerWrapper.reduxReducer);
      var element = React.createElement(Backlog, { store: store });
      var container = document.getElementById('backlogRoot');

      if (container !== null) {
        ReactDOM.render(element, container);
      }
    },

    showReviewChangelog: function () {
      // loading
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var pipelineAuthRequest = Wethod.request('get:pipeline:authorization');
      var reviewAuthRequest = Wethod.request('get:company:pipeline:review:config');
      $.when(reviewAuthRequest, pipelineAuthRequest)
        .done(function (reviewPermissions, pipelinePermissions) {
          var cannotSeePipeline = _.isUndefined(pipelinePermissions) || !pipelinePermissions.get('view');
          var reviewNotEnabled = _.isUndefined(reviewPermissions) || !reviewPermissions.get('enabled');

          if (cannotSeePipeline || reviewNotEnabled) {
            loadingView.destroy();
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }

          Wethod.ReportApp.authorization = pipelinePermissions;
          // Layout
          var reviewChangelogLayoutView = new ReportApp.ReviewChangelogLayoutView();
          reviewChangelogLayoutView.render();

          // Render react components
          var reducerWrapper = new ReviewChangelogReducer();
          var store = ReviewChangelogStore(reducerWrapper.reduxReducer);
          var element = React.createElement(ReviewChangelog, { store: store });
          var container = document.getElementById('root');

          if (container !== null) {
            ReactDOM.render(element, container);
          }
        });
    },

    showTimesheetRoadrunner: function () {
      // todo check permissions
      var reportLayout = new ReportApp.StructureLayoutView();
      var timesheetRoadrunnerLayout = new Wethod.ReportApp.TimesheetRoadrunner.Layout();

      reportLayout.render();
      reportLayout.reportBody.show(timesheetRoadrunnerLayout);
    },

    showDemographics: function () {
      var reportAuthRequest = Wethod.request('get:report:authorization');
      var settingsAuthRequest = Wethod.request('get:settings:authorization');
      $.when(reportAuthRequest, settingsAuthRequest).done(function
      (reportAuthResponse, settingsAuthResponse) {
        Wethod.ReportApp.authorization = reportAuthResponse;
        if (_.isUndefined(reportAuthResponse) || !Wethod.ReportApp.getPermission('view_demographics')) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        } else {
          var canEditSettings = !_.isUndefined(settingsAuthResponse) && settingsAuthResponse.get('company');

          // Layout
          var reactLayoutView = new ReportApp.ReactLayoutView();
          reactLayoutView.render();

          // Render react components
          var reducerWrapper = new DemographicsReducer({ canEditSettings: canEditSettings });
          var store = DemographicsStore(reducerWrapper.reduxReducer);
          var element = React.createElement(Demographics,
            { store: store });

          var container = document.getElementById('root');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
        }
      });
    },

    showChargeability: function () {
      var reportAuthRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthRequest)
        .done(function (reportAuthResponse) {
          Wethod.ReportApp.authorization = reportAuthResponse;
          if (_.isUndefined(reportAuthResponse) || !Wethod.ReportApp.getPermission('view_chargeability')) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          } else {
            // Layout
            var reactLayoutView = new ReportApp.ReactLayoutView();
            reactLayoutView.render();

            // Render react components
            var reducerWrapper = new ChargeabilityReducer({});
            var store = ChargeabilityStore(reducerWrapper.reduxReducer);
            var element = React.createElement(Chargeability, { store: store });

            var container = document.getElementById('root');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          }
        });
    },

    showKudos: function () {
      var kudosPermissionsRequest = Wethod.request('profile:permissions:get');
      var reviewSettingsRequest = Wethod.request('get:company:pipeline:review:config');
      $.when(kudosPermissionsRequest, reviewSettingsRequest)
        .done(function (permissionsResponse, reviewSettingsResponse) {
          Wethod.ReportApp.authorization = permissionsResponse;
          var cannotSeeKudos = _.isUndefined(permissionsResponse) || !permissionsResponse.get('metrics_other');
          var reviewNotEnabled = _.isUndefined(reviewSettingsResponse) || !reviewSettingsResponse.get('enabled');

          if (cannotSeeKudos || reviewNotEnabled) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          } else {
            // Layout
            var reactLayoutView = new ReportApp.ReactLayoutView();
            reactLayoutView.render();

            // Render react components
            var reducerWrapper = new KudosReducer({});
            var store = KudosStore(reducerWrapper.reduxReducer);
            var element = React.createElement(Kudos, { store: store });

            var container = document.getElementById('root');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          }
        });
    },

    showKnowledge: function () {
      var reportAuthRequest = Wethod.request('get:report:authorization');
      $.when(reportAuthRequest)
        .done(function (reportAuthResponse) {
          Wethod.ReportApp.authorization = reportAuthResponse;
          if (_.isUndefined(reportAuthResponse) || !Wethod.ReportApp.getPermission('view_knowledge')) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          } else {
            // Layout
            var reactLayoutView = new ReportApp.ReactLayoutView();
            reactLayoutView.render();

            // Render react components
            var reducerWrapper = new KnowledgeReducer({});
            var store = KnowledgeStore(reducerWrapper.reduxReducer);
            var element = React.createElement(Knowledge, { store: store });

            var container = document.getElementById('root');
            if (container !== null) {
              ReactDOM.render(element, container);
            }
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
