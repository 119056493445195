const React = require('react');
const Month = require('./ProjectMonth.react');
const MonthEditable = require('./ProjectMonthEditable.react');
const DateService = require('../../../../../../../services/DateService');

module.exports = class ProjectMonthController extends React.Component {
  getPlanning() {
    if (this.props.planned !== null) return { ...this.props.planned };
    return {
      amount: 0,
      delta: 0,
      month: this.props.date,
    };
  }

  /**
   * Check if this month is Jan or Dec and if it's in the duration of a project (but is not the first or last month of the project).
   * @returns {boolean}
   */
  isBoundary() {
    if (this.props.isProjectMonth) {
      return (this.props.index === 0 && this.props.date !== this.props.dateStart)
        || (this.props.index === 11 && this.props.date !== this.props.dateEnd);
    }
    return false;
  }

  render() {
    if (this.props.mode === 'view' || this.props.mode === 'saving' || DateService.isPastMonth(this.props.date)) {
      return (
        <Month {...this.props.planned}
          totalProductionValue={this.props.totalProductionValue}
          isProjectMonth={this.props.isProjectMonth}
          isBoundary={this.isBoundary()}
          isPastMonth={DateService.isPastMonth(this.props.date)} />
      );
    }
    return (
      <MonthEditable {...this.getPlanning()}
        totalProductionValue={this.props.totalProductionValue}
        isProjectMonth={this.props.isProjectMonth}
        date={this.props.date}
        isBoundary={this.isBoundary()}
        move={this.props.move}
        moveProject={this.props.moveProject}
        onDragProjectStart={this.props.onDragProjectStart}
        projectId={this.props.projectId}
        isLastProjectMonth={this.props.date === this.props.dateEnd}
        editMonth={this.props.editMonth} />
    );
  }
};
