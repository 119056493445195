const { connect } = require('react-redux');
const Component = require('../components/WeeksBacklogTrend.react');

const mapStateToProps = (state) => ({
  isLoading: state.isLoadingBacklog || state.isLoadingProduction,
  backlogs: state.backlogs,
  avgProductions: state.avgProductions,
});

module.exports = connect(mapStateToProps)(Component);
