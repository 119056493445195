const { connect } = require('react-redux');
const Component = require('../../../components/sections/Economics/Payroll.react');

const mapStateToProps = (state) => ({
  payroll: state.month_payroll,
  targetType: state.target_type,
  costsOnProduction: state.month_payroll_perc, // NULLABLE
  planned: state.payroll_target_perc, // NULLABLE
});

module.exports = connect(mapStateToProps)(Component);
