const React = require('react');
const Header = require('../SubsectionHeader.react');
const Modal = require('../../containers/ModalController');
const Actions = require('../../containers/job-title/Actions');
const List = require('../../containers/job-title/Table');
const LevelEventsManager = require('../../containers/job-title/LevelEventsManager');

const JobTitles = () => (
  <div>
    <Header subtitle="Job Titles"
      id="job-title"
      description="In order to have a detailed estimate of resources you need in a project budget, specify a list of job titles in your company and assign a single user level each." />
    <Actions />
    <List />
    <Modal />
    <LevelEventsManager />
  </div>
);

module.exports = JobTitles;
