/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');

module.exports = class HeadCellView extends React.Component {
  render() {
    return (
      <TableCell key={this.props.id} className="project-canvas-spreadsheet__cell">{this.props.name}</TableCell>
    );
  }
};
