const React = require('react');
const ModalContent = require('./ApprovalStatusButtonModalContent.react');
const Modal = require('../../../../../../../../../common/react/Modal/Modal.react');
const RequestManager = require('../../../../../containers/RequestManager');

const ApprovalStatusButtonModal = ({ closeModal }) => (
  <Modal onClose={closeModal} title="Approve this budget?">
    <RequestManager>
      <ModalContent closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = ApprovalStatusButtonModal;
