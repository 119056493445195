const { connect } = require('react-redux');
const Component = require('../../../components/sections/Production/ProductionChart.react');

const thousandsValue = (value) => Math.round(value / 1000);

const mapStateToProps = (state) => ({
  target: thousandsValue(state.monthly_revenue_target),
  thisMonth: thousandsValue(state.month_revenue),
  twoMonthsAgo: thousandsValue(state.prev_month_revenue),
  threeMonthsAgo: thousandsValue(state.two_months_ago_revenue),
  lastYear: thousandsValue(state.prev_year_revenue),
  lastForecast: thousandsValue(state.last_production_plan),
  minForecast: thousandsValue(state.min_production_plan),
  maxForecast: thousandsValue(state.max_production_plan),
});

module.exports = connect(mapStateToProps)(Component);
