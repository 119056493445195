const React = require('react');
const PropTypes = require('prop-types');
const Path = require('../models/Path');
const Place = require('./BreadcrumbPlace.react');

const Breadcrumb = ({ path }) => {
  const places = path.map((component) => <Place key={component.id} place={component} />);

  if (path.isEmpty()) {
    return null;
  }
  return (
    <div className="wethod-widget-files__breadcrumb">
      <div className="wethod-icon-medium-folder wethod-icon-medium-folder--black" />
      {places}
    </div>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.instanceOf(Path).isRequired,
};

module.exports = Breadcrumb;
