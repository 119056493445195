/* eslint-disable react/sort-comp,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('../../../../../../common/react/CollapsibleCard.react');
const formatters = require('../utilities/formatters');

module.exports = class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getPmName() {
    return `${this.props.project.pm.person.name} ${this.props.project.pm.person.surname}`;
  }

  getAccountName() {
    if (this.props.project.account) return `${this.props.project.account.person.name} ${this.props.project.account.person.surname}`;
    return null;
  }

  getDuration() {
    const month = moment(this.props.project.date_start).format('MMMM YYYY');
    const duration = this.props.project.duration === 1
      ? `${this.props.project.duration} month` : `${this.props.project.duration} months`;

    return `${month} - ${duration}`;
  }

  getProjectValue() {
    const { project } = this.props;
    let value = project.estimate * 1000;
    if (project.budget) {
      value = project.budget.final_net_price;
    }
    return formatters.getFormattedMoneyValue(value);
  }

  getAccountComponent() {
    const account = this.getAccountName();
    if (account !== null) {
      return (
        <div className="client-info-row">
          <div className="client-info-row__title">Account</div>
          <div className="client-info-row__value">{account}</div>
        </div>
      );
    }
    return null;
  }

  getPoComponent() {
    const po = this.props.project.client_po;
    if (po !== null) {
      return (
        <div className="client-info-row">
          <div className="client-info-row__title">Client PO</div>
          <div className="client-info-row__value">{po}</div>
        </div>
      );
    }
    return null;
  }

  getJobOrderComponent() {
    const jobOrder = this.props.project.job_order;
    if (jobOrder !== null) {
      return (
        <div className="client-info-row">
          <div className="client-info-row__title">Job Order</div>
          <div className="client-info-row__value">{jobOrder}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Card
        title="Project details"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <div>
          <div className="client-info-row">
            <div className="client-info-row__title">Value</div>
            <div className="client-info-row__value">{this.getProjectValue()}</div>
          </div>
          {this.getJobOrderComponent()}
          <div className="client-info-row">
            <div className="client-info-row__title">PM</div>
            <div className="client-info-row__value">{this.getPmName()}</div>
          </div>
          {this.getAccountComponent()}
          <div className="client-info-row">
            <div className="client-info-row__title">Duration</div>
            <div className="client-info-row__value">{this.getDuration()}</div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Job Order Category</div>
            <div className="client-info-row__value">
              {this.props.project.project_type.name}
            </div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Probability</div>
            <div className="client-info-row__value">
              {this.props.project.probability}
              {' '}
              %
            </div>
          </div>
          {this.getPoComponent()}
        </div>
      </Card>
    );
  }
};
