const { connect } = require('react-redux');
const Component = require('../../components/CreationModal/TemplateListModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  projectId: state.project.id,
  isWaitingForCreation: state.waitingFor.filter((key) => key === 'creation').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  showCreationModeModal: () => dispatch(actions.showCreationModeModal()),
  createBudget: (projectId, templateId) => dispatch(actions
    .createBudget(projectId, templateId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
