const React = require('react');
const isEqual = require('react-fast-compare');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const Tooltip = require('../../../../../../common/react/TooltipFixed/Tooltip.react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');

const Actions = ({ permissions, filters, showTransferHoursModal, getStandardFilters }) => {
  const hasFilters = !isEqual(filters, {});
  const filtersFormatted = getStandardFilters(filters);
  const hasOneProjectFilter = filtersFormatted.project && filtersFormatted.project.length === 1;
  const hasOneJobOrderFilter = filtersFormatted.job_order
    && filtersFormatted.job_order.length === 1;

  /**
   * Enable transfer hours button when:
   * - There are filters
   * - Only one filter (project or job order) is selected
   * @type {boolean}
   */
  const transferHoursEnabled = hasFilters
    && (hasOneProjectFilter || hasOneJobOrderFilter)
    && !(hasOneProjectFilter && hasOneJobOrderFilter);

  const getTooltipLabel = () => {
    if (!hasFilters) {
      return 'Apply filters to transfer hours';
    }
    if (!transferHoursEnabled) { // No project or job order selected, or more than one selected
      return 'Filter one project or job order at a time to transfer hours';
    }
    return '';
  };

  const getButton = () => (
    <Button
      onClick={showTransferHoursModal}
      disabled={!transferHoursEnabled}>
      Transfer Hours
    </Button>
  );

  return (
    <div className="wethod-section-actions">
      <div className="wethod-section-actions__left" />
      <div className="wethod-section-actions__right">
        <ShowIf condition={permissions.edit_other}>
          <ShowIf condition={!transferHoursEnabled}
            showElse={getButton()}>
            <Tooltip label={getTooltipLabel()}>
              {getButton()}
            </Tooltip>
          </ShowIf>
        </ShowIf>
      </div>
    </div>
  );
};

module.exports = Actions;
