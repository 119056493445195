const HTTPService = require('../../../../../../../../services/HTTPService');
const ParserService = require('../../../../../../../../services/ParserService');

module.exports = Backbone.Collection.extend({
  initialize(options) {
    this.url = `${APIURL}/crm/board/client/${options.id}/projects`;
  },
  load: HTTPService.load,
  parse: ParserService.parse,
  persist: HTTPService.persist,
  model: Backbone.Model.extend(),
  getModel() {
    const defer = $.Deferred();

    $.when(this.load()).done((collection) => {
      defer.resolve(collection.toJSON());
    });

    return defer.promise();
  },
});
