const React = require('react');

module.exports = class SocketManager extends React.Component {
  componentDidMount() {
    this.registerListeners();
  }

  componentWillUnmount() {
    this.deregisterListeners();
  }

  registerListeners() {
    this.props.listeners.forEach((listener) => socket.on(listener.eventName, listener.callback));
  }

  deregisterListeners() {
    this.props.listeners.forEach((listener) => socket.off(listener.eventName));
  }

  render() {
    return this.props.children;
  }
};
