const React = require('react');
const PropTypes = require('prop-types');

/**
 * @deprecated
 */
class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value : '',
      constraints: this.props.constraints ? this.props.constraints.split(',') : [],
      isValid: false,
      placeholder: this.props.placeholder,
    };

    this.errors = [];
    this.state.isValid = this.isValid();
  }

  componentDidUpdate(prevProps, prevState) {
    // If value has been reset from props
    if (this.props.value !== prevProps.value) {
      const value = this.props.value ? this.props.value : '';
      this.updateValue(value);
    } else if (this.state.value !== prevState.value) {
      this.updateIsValid();
    }
  }

  handleChange(e) {
    if (!this.props.disabled) {
      this.setState({ value: e.target.value }, this.afterChange.bind(this));
    }
  }

  handleBlur() {
    if (this.needsPlaceholder()) {
      this.setState({ placeholder: this.props.placeholder });
    }
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value);
    }
  }

  handleFocus() {
    this.setState({ placeholder: '' });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  getClassName() {
    let name = 'wethod-input';
    if (this.isRequired()) {
      name += ' wethod-input--required';
    }
    return name;
  }

  required() {
    return this.state.value.trim() !== '';
  }

  isValid() {
    this.errors = this.validate();
    if (this.props.onValidate) {
      this.props.onValidate(this.props.name, this.errors);
    }
    return this.errors.length === 0;
  }

  validate() {
    const errors = [];
    for (let i = 0; i < this.state.constraints.length; i++) {
      if (!this[this.state.constraints[i]]()) {
        errors.push(this.state.constraints[i]);
      }
    }
    return errors;
  }

  afterChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, this.state.value);
    }
  }

  needsPlaceholder() {
    return this.state.value === '';
  }

  isRequired() {
    return this.state.constraints.indexOf('required') !== -1;
  }

  updateValue(value) {
    this.setState({
      value,
    });
  }

  updateIsValid() {
    this.setState({
      isValid: this.isValid(),
    });
  }

  render() {
    return (
      <span className={this.getClassName()}>
        <textarea disabled={this.props.disabled}
          placeholder={this.state.placeholder}
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          rows={this.props.rows}
          name={this.props.name}
          maxLength={this.props.maxLength} />
      </span>
    );
  }
}

TextArea.defaultProps = {
  value: '',
  constraints: '',
  placeholder: '',
  onValidate: null,
  disabled: false,
  onBlur: null,
  onFocus: null,
  rows: 3,
  maxLength: null,
};

TextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  /**
   * A comma separated string of 'constraints' that will be checked at any blur.
   * Supported constraints: 'required'.
   */
  constraints: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  /**
   * Function called when value change. Use it in to react to invalid values.
   *
   * @param name {string}
   * @param errors {[]}
   */
  onValidate: PropTypes.func,
  /**
   * Function called when value change.
   *
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Function called when focus has left this element.
   *
   * @param value {string}
   */
  onBlur: PropTypes.func,
  /**
   * Function called when the element receives focus.
   */
  onFocus: PropTypes.func,
};

module.exports = TextArea;
