/**
 * @type {Path}
 */
module.exports = class Path {
  /**
   * Path constructor.
   * @param {PathComponent[]} components
   */
  constructor(components = []) {
    this.components = components;
  }

  /**
   * Return path with added element on last position.
   * @param {PathComponent} component
   * @return {Path}
   */
  add(component) {
    if (!component) {
      return this;
    }
    const newComponents = this.components.concat(component);
    if (newComponents.length > 1) {
      const lastIndex = newComponents.length - 1;
      newComponents[lastIndex - 1].child = component.id;
    }

    return new Path(newComponents);
  }

  /**
   * Return path with last element removed.
   * @return {Path}
   */
  pop() {
    let lastIndex = this.components.length - 1;
    const newComponents = this.components.slice(0, lastIndex);
    lastIndex -= lastIndex;
    newComponents[lastIndex].child = null;

    return new Path(newComponents);
  }

  isEmpty() {
    return this.components.length === 0;
  }

  /**
   * @return {PathComponent|null}
   */
  getLast() {
    const size = this.components.length;
    if (size) {
      return this.components[size - 1];
    }
    return null;
  }

  map(callback) {
    return this.components.map(callback);
  }

  length() {
    return this.components.length;
  }
};
