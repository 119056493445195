const { connect } = require('react-redux');
const Component = require('../../../components/job-order-template/template/Template.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  waitingForDelete: state.waitingFor.filter((key) => key.startsWith('delete-template-')),
  waitingForSave: state.waitingFor.filter((key) => key.startsWith('save-template-')),
});

const mapDispatchToProps = (dispatch) => ({
  showDeleteModal: (template) => dispatch(actions.showDeleteJobOrderTemplateConfirmModal(template)),
  deleteNewTemplate: () => dispatch(actions.deleteNewJobOrderTemplate()),
  onSave: (template) => dispatch(actions.saveJobOrderTemplate(template)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
