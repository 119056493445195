/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events */
const React = require('react');
const Block = require('./PlanEditorBlock.react');

const PlanEditorDaily = ({
  days, selectedProject, selectedBlocks, onMouseOverBlock, editorMode, isHighlighted, updateMode,
  onBlockClick, onMouseOut,
}) => {
  /**
   * Return the amount of hours planned for the given projectId in the given block.
   * @param projectId
   * @param block
   * @returns {*}
   */
  const getProjectHoursInBlock = (projectId, block) => {
    if (!block) {
      return 0;
    }
    return block.hours.filter((hourProjectId) => hourProjectId === projectId).length;
  };

  /**
   * Return the amount of non-planned hours for the given block.
   * @param block
   * @returns {number}
   */
  const getFreeHoursInBlock = (block) => {
    if (!block) {
      return 0;
    }
    return 4 - block.hours.length;
  };

  /**
   * Return the selected block for the given date, if any.
   * @param date
   * @returns {*}
   */
  const getSelectedBlock = (date) => {
    const selectedBlockId = selectedBlocks.filter((blockId) => blockId.indexOf(date) !== -1)[0];
    const dayBlocks = getBlocks().filter((dayBlocks) => dayBlocks[0].day.date === date)[0];

    return dayBlocks ? dayBlocks.filter((block) => block.id === selectedBlockId)[0] : null;
  };

  /**
   * Each day is made up of 2 block, one is a companion of the other.
   * Return the companion for the given block.
   * @param block
   * @returns {*}
   */
  const getCompanionBlock = (block) => {
    const dayBlocks = getBlocks()
      .filter((dayBlocks) => dayBlocks[0].day.date === block.day.date)[0];
    return dayBlocks.filter((dayBlock) => dayBlock.id !== block.id)[0];
  };

  /**
   * Return a list of changes for selectedProject in the given day.
   * A change is an object like {day:'2020-06-20', amount:6}, change.amount is the new hours' amount for the selectedProject in the given day.
   * @param mode add|remove
   * @param block hours currently planned for the selectedBlock
   * @returns {[]}
   */
  const getChanges = (mode, block) => {
    const { day } = block;
    const { plans } = day;
    const changes = [];
    // The block - belonging to the given day - on which the cursor is currently over
    const currentBlock = block;
    // The block - belonging to the given day - selected but not currently focused by the cursor
    const selectedBlock = getSelectedBlock(block.day.date);

    if (mode === 'add') {
      const companionBlock = getCompanionBlock(block);
      const companionBlockHours = getProjectHoursInBlock(selectedProject.id, companionBlock);
      // The hours' amount - contained in the selected block - which can be planned with the selected project
      const selectedBlockHours = getFreeHoursInBlock(selectedBlock)
        + getProjectHoursInBlock(selectedProject.id, selectedBlock);
      // The hours' amount - contained in the current block - which can be planned with the selected project
      const currentBlockHours = getFreeHoursInBlock(currentBlock)
        + getProjectHoursInBlock(selectedProject.id, currentBlock);
      // If a selected block exists, use it. Use companion block otherwise
      const amount = selectedBlock ? selectedBlockHours + currentBlockHours
        : companionBlockHours + currentBlockHours;

      const change = {
        day: day.date,
        amount,
      };
      changes.push(change);
    } else if (mode === 'remove') {
      const dayProjectHours = plans
        .reduce((sum, plan) => (plan.project_id === selectedProject.id
          ? sum + plan.amount : sum), 0);
      // The hours' amount - contained in the selected block - which can be planned with the selected project
      const selectedBlockHours = getProjectHoursInBlock(selectedProject.id, selectedBlock);
      // The hours' amount - contained in the current block - which can be planned with the selected project
      const currentBlockHours = getProjectHoursInBlock(selectedProject.id, currentBlock);
      const change = {
        day: day.date,
        amount: dayProjectHours - selectedBlockHours - currentBlockHours,
      };
      changes.push(change);
    }
    return changes;
  };

  const onMouseMove = (block) => {
    if (editorMode) {
      const changes = getChanges(editorMode, block);
      onMouseOverBlock(block.id, changes);
    }
  };

  const onClick = (block) => {
    const hasSelectedProjectPlan = block.hours
      .filter((hour) => hour === selectedProject.id).length > 0;
    const mode = hasSelectedProjectPlan ? 'remove' : 'add';
    const changes = getChanges(mode, block);

    onBlockClick(changes, mode);
  };

  const onMouseDown = (block) => {
    const hasSelectedProjectPlan = block.hours
      .filter((hour) => hour === selectedProject.id).length > 0;

    if (hasSelectedProjectPlan) {
      updateMode('remove');
    } else {
      updateMode('add');
    }
  };

  /**
   * Return the list of hours belonging to the given day. Each hour is represented by the id of the project it contains.
   * @param day
   * @returns {[]}
   */
  const getHoursInDay = (day) => {
    const hours = [];
    for (let i = 0; i < day.plans.length; i++) {
      const plan = day.plans[i];
      for (let j = 0; j < plan.amount; j++) {
        hours.push(plan.project_id);
      }
    }
    return hours;
  };

  /**
   * Return the blocks which compose this editor.
   * @returns {*}
   */
  const getBlocks = () => days.map((day) => {
    const hours = getHoursInDay(day);
    return [{
      id: `${day.date}_0`,
      day,
      hours: hours.filter((hour, index) => index < 4),
    }, {
      id: `${day.date}_1`,
      day,
      hours: hours.filter((hour, index) => index >= 4),
    }];
  });

  const getBlocksFragment = () => getBlocks().map((blocks) => (
    <div key={blocks[0].id} className="planning-calendar__row-day">
      <Block
        key={blocks[0].id}
        onMouseMove={() => onMouseMove(blocks[0])}
        onClick={() => onClick(blocks[0])}
        onMouseDown={() => onMouseDown(blocks[0])}
        highlighted={isHighlighted(blocks[0].id)} />
      <Block
        key={blocks[1].id}
        onMouseMove={() => onMouseMove(blocks[1])}
        onClick={() => onClick(blocks[1])}
        onMouseDown={() => onMouseDown(blocks[1])}
        highlighted={isHighlighted(blocks[1].id)} />
    </div>
  ));

  return (
    <div className="planning-calendar__editor" onMouseOut={onMouseOut}>
      {getBlocksFragment()}
    </div>
  );
};

module.exports = PlanEditorDaily;
