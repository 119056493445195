const React = require('react');
const PropTypes = require('prop-types');
const Manager = require('./AdvancedSearchManager.react');
const Body = require('./SegmentBody.react');

class SegmentManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.segment.name,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleSave(activeFilters) {
    const updatedSegment = { ...this.props.segment, filters: activeFilters, name: this.state.name };
    this.props.onSave(updatedSegment);
  }

  getTitle() {
    return this.props.segment.name ? 'Edit segment' : 'New segment';
  }

  render() {
    return (
      <Manager show={this.props.show}
        filters={this.props.segment.filters}
        onClose={this.props.onClose}
        onSave={this.handleSave}
        canSave={this.props.canSave}
        title={this.getTitle()}>
        <Body name={this.state.name}
          onNameChange={this.handleNameChange}
          errors={this.props.errors}
          updateErrors={this.props.updateErrors}>
          {this.props.children}
        </Body>
      </Manager>
    );
  }
}

SegmentManager.propTypes = {
  /**
   * Segment
   * contains the id, the name and the filters
   * @see AdvancedSearchManager
   */
  segment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filters: PropTypes.shape({}),
  }),
  canSave: PropTypes.bool,
  errors: PropTypes.shape({}),
  updateErrors: PropTypes.func.isRequired,
  /**
   * Whether to show or hide the advanced search sidebar
   * @see AdvancedSearchManager
   */
  show: PropTypes.bool.isRequired,
  /**
   * @see Sidebar
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @see Sidebar
   */
  onSave: PropTypes.func.isRequired,
  /**
   * List of filters shown in the advanced search
   * @see AdvancedSearchManager
   */
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    // Required check
    if (!prop) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`);
    }

    React.Children.forEach(prop, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.name === undefined || child.props.name === null) {
          // 'Name' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
        if (child.props.label === undefined || child.props.label === null) {
          // 'Label' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
      } else {
        // Type check
        error = new Error(`Invalid prop \`children\` of type \`${typeof child}\` supplied to \`${componentName}\`, children should be valid elements.`);
      }
    });
    return error;
  }, // isRequired
};

SegmentManager.defaultProps = {
  canSave: true,
  errors: {},
  segment: { id: null, name: '', filters: {} },
  children: undefined,
};

module.exports = SegmentManager;
