/* eslint-disable class-methods-use-this */
const React = require('react');
const TrendsItem = require('../TrendsItem.react');
const { formatDate } = require('../../../../../../../../services/FormatService');
const Legend = require('./Legend.react');

module.exports = class TrendKeepTheLightsOff extends React.Component {
  componentDidMount() {
    this.props.getTrend(this.props.employee);
  }

  /**
   * Returns the series' trend. The string returned must match with one of the colors' key given in TrendsItem
   * component.
   *
   * @returns {string}
   */
  getSeriesTrendType() {
    const firstValue = this.props.data[0].y;
    const lastValue = this.props.data[this.props.data.length - 1].y;

    if (this.props.data.length > 0) {
      if (lastValue > firstValue) return 'negative';
    }
    return 'positive';
  }

  /**
   * Returns a formatted label for the given point.
   *
   * @param point
   * @param series
   * @param x
   * @param y
   * @returns {string}
   */
  getPointLabel(point, series, x, y) {
    if (point.index === series.data.length - 1) { // is last point
      const value = y;
      const formattedValue = numeral(value).format('+0,0.[0]');
      if (value === 1) return `${formattedValue} day`;
      return `${formattedValue} days`;
    }
    return '';
  }

  /**
   * Returns the type of the given value. The string returned must match with one of the colors' key given in TrendsItem
   * @param value
   * @returns {string}
   */
  getPointType(value) {
    if (value < 1) return 'positive';
    if (value <= 2) return 'neutral';
    return 'negative';
  }

  /**
   * Returns a formatted tooltip for the given point.
   *
   * @param x
   * @param y
   * @returns {string}
   */
  getTooltip(x, y) {
    const monday = formatDate(moment(x));
    const friday = formatDate(moment(x).add(4, 'days'));
    let value = numeral(y).format('+0,0.[0]');
    if (y === 1) value = `${value} day`;
    else value = `${value} days`;
    return `${monday} - ${friday} <br/> <b>${value}</b>`;
  }

  render() {
    return (
      <TrendsItem
        {...this.props}
        className="profile-trends__item--keep-the-lights-off"
        getSeriesTrendType={this.getSeriesTrendType.bind(this)}
        getPointLabel={this.getPointLabel}
        getPointType={this.getPointType}
        getTooltip={this.getTooltip.bind(this)}
        legend={<Legend />} />
    );
  }
};
