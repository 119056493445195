/* eslint-disable react/no-access-state-in-setstate,consistent-return,react/sort-comp,class-methods-use-this */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class WeeksBacklogTrend extends React.Component {
  isSet(values) {
    return values && values.length > 0;
  }

  getWeeks() {
    if (!this.props.isLoading && this.isSet(this.props.avgProductions)
      && this.isSet(this.props.backlogs)) {
      const weeks = this.props.backlogs.map((backlog, i) => {
        const avgProduction = this.props.avgProductions[i];
        if (avgProduction && avgProduction.value && avgProduction.value !== '0') {
          return {
            value: backlog.value / avgProduction.value,
            week: backlog.week,
          };
        }
        return {
          value: null,
          week: backlog.week,
        };
      });
      return weeks;
    }
    return [];
  }

  format(value) {
    return `${numeral(value).format('0,0.[0]')} W`;
  }

  tooltipFormatter(point) {
    const pointDate = new Date(point.key);
    const start = moment(pointDate).startOf('isoWeek');
    const end = moment(pointDate).endOf('isoWeek');
    const formatted = `${start.format('YYYY-MM-DD')}/${end.format('DD')}`;
    return `<div>
                    ${formatted}<br/>
                    <b>${this.format(point.y)}</b>
                </div>`;
  }

  /**
   * Returns a formatted label for the given point.
   *
   * @param point
   * @param series
   * @param x
   * @param y
   * @param lastPointIndex
   * @returns {string}
   */
  getPointLabel(point, series, x, y, lastPointIndex) {
    if (point.index === lastPointIndex) { // is last point
      return `${this.format(y)}`;
    }
    return '';
  }

  render() {
    return (
      <Trend
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        title="Backlog (number of weeks)"
        description="The number of weeks needed to consume the backlog, based on average weekly production"
        getPointLabel={this.getPointLabel.bind(this)}
        weeks={this.getWeeks()}
        isLoading={this.props.isLoading} />
    );
  }
};
