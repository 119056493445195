const React = require('react');

/**
 * Injects methods useful to deal with an item.
 * @param children
 * @param item
 * @param isValueAsUnit
 * @param availableMetadata
 * @param rest
 * @returns {React.DetailedReactHTMLElement<{getValue: (function(): number), getMargin: (function(): number), getExternalCosts: (function(): number), canEditFeedback: (function(): boolean)}, HTMLElement>}
 * @constructor
 */
const ItemManager = ({ children, item, isValueAsUnit, availableMetadata, ...rest }) => {
  const hasValidBudget = item.budget.id !== null;

  /**
   * Calc the value of the project in units
   * @returns {number}
   */
  const getValueAsUnit = () => Math.round(item.project.estimate * 1000);

  /**
   * Calc the value of the project in K, rounded
   * @returns {number}
   */
  const getValueAsK = () => Math.round(item.project.estimate);

  /**
   * Returns project value in K or units, depending on the company preferences.
   * @returns {number}
   */
  const getValue = () => (isValueAsUnit ? getValueAsUnit() : getValueAsK());

  const getMargin = () => (hasValidBudget ? Math.round(item.budget.percent_margin) : null);

  const getItemMetadataValue = (metadataId) => {
    const found = item.metadata.filter((metadata) => metadata.metadata_id === metadataId);
    return found.length ? found[0].selected_value : '';
  };

  const getMetadata = () => availableMetadata.map((metadata) => ({
    ...metadata,
    value: getItemMetadataValue(metadata.id),
  }));

  /**
   * Return external costs percentage derived from the actual budget.
   * @returns {number} Total external costs percentage
   */
  const getActualExternalCostsPercentage = () => {
    const value = item.project.estimate * 1000;
    const costs = item.budget.external_cost;

    return value ? (costs / value) * 100 : 0;
  };

  /**
   * Return external costs value from the actual budget.
   * @returns {number} Total external costs value
   */
  const getActualExternalCostsValue = () => item.budget.external_cost;

  /**
   * Returns external costs percentage estimated in pipeline by the user.
   * @returns {number} Percentage of external costs on the item value
   */
  const getEstimatedExternalCostsPercentage = () => item.project.external_cost;

  /**
   * Returns external costs estimated in pipeline by the user as value.
   * @returns {number} Value of external costs on the item value
   */
  const getEstimatedExternalCostsValue = () => {
    const value = item.project.estimate * 1000;
    const costs = item.project.external_cost;

    return value ? (value * costs) / 100 : 0;
  };

  /**
   * Returns item external costs in percentage.
   * Estimated costs are used if item has no budget, otherwise costs are deduced from budget.
   * @returns {number} Percentage of external costs on the item value
   */
  const getExternalCostsPercentage = () => {
    const costs = hasValidBudget ? getActualExternalCostsPercentage()
      : getEstimatedExternalCostsPercentage();

    return Math.round(costs);
  };

  /**
   * Returns item external costs value in K or unit.
   * Estimated costs are used if item has no budget, otherwise costs are taken from budget.
   * @returns {number} Value of external costs on the item value
   */
  const getExternalCostsValue = () => {
    let costs = hasValidBudget ? getActualExternalCostsValue()
      : getEstimatedExternalCostsValue();

    costs = isValueAsUnit ? costs : costs / 1000;

    return Math.round(costs);
  };

  /**
   * Check whether the item needs to edit feedback, according to the settings of its project type
   * @returns {boolean}
   */
  const canEditFeedback = () => item.project_type.won_lost_feedback
    || item.project_type.won_lost_feedback_required;

  return React
    .cloneElement(children, {
      getValue,
      getMargin,
      getExternalCostsPercentage,
      getExternalCostsValue,
      canEditFeedback,
      getMetadata,
      isValueAsUnit,
      ...rest,
    });
};

module.exports = ItemManager;
