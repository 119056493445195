/* eslint-disable react/sort-comp,react/no-access-state-in-setstate */
const React = require('react');
const Menu = require('../../../../../../common/react/Menu/Menu.react');
const Quantity = require('./QuantitySelector.react');

module.exports = class PeopleAmountSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      employees: this.props.employees != null ? this.props.employees : 0,
      guests: this.props.guests != null ? this.props.guests : 0,
    };
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  // Reset people amount and close menu
  handleCancel() {
    const employees = this.props.employees != null ? this.props.employees : 0;
    const guests = this.props.guests != null ? this.props.guests : 0;
    this.setState({
      employees,
      guests,
    });
    this.hideMenu();
  }

  handleSave() {
    const changes = {};

    const isEmployeeChanged = this.state.employees !== this.props.employees;
    const isGuestsChanged = this.state.guests !== this.props.guests;
    if (isEmployeeChanged || isGuestsChanged) {
      changes.employees = this.state.employees;
      changes.guests = this.state.guests;

      this.props.onChange('people_list', changes);
    }

    this.hideMenu();
  }

  handleIncrease(type) {
    const newAmount = this.state[type] + 1;
    this.setState({ [type]: newAmount });
  }

  handleDecrease(type) {
    const newAmount = this.state[type] - 1;
    this.setState({ [type]: newAmount });
  }

  // At lest one employee is required if no guests are selected
  getMinEmployees() {
    return this.state.guests >= 1 ? 0 : 1;
  }

  // At lest one guest is required if no employees are selected
  getMinGuests() {
    return this.state.employees >= 1 ? 0 : 1;
  }

  getBody() {
    return (
      <div className="travel__people-amount-select">
        <div className="travel__people-amount-select--row">
          <span className="travel__people-amount-select--label">Employees</span>
          <Quantity name="employees"
            quantity={this.state.employees}
            min={this.getMinEmployees()}
            onDecrease={this.handleDecrease.bind(this)}
            onIncrease={this.handleIncrease.bind(this)} />
        </div>
        <div className="travel__people-amount-select--row">
          <span className="travel__people-amount-select--label">Visitors</span>
          <Quantity name="guests"
            quantity={this.state.guests}
            min={this.getMinGuests()}
            onDecrease={this.handleDecrease.bind(this)}
            onIncrease={this.handleIncrease.bind(this)} />
        </div>
        <div className="travel__people-amount-select--buttons">
          <button type="button"
            className="wethod-button wethod-button--no-border"
            onClick={this.handleCancel.bind(this)}>Dismiss
          </button>
          <button type="button"
            className="wethod-button travel__people-amount-select--done-btn"
            onClick={this.handleSave.bind(this)}>Done
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button type="button"
          className="wethod-button travel__selection-labels"
          onClick={this.showMenu.bind(this)}
          ref={(el) => this.button = el}>
          {this.props.label}
        </button>
        <Menu anchorEl={this.button} open={this.state.showMenu} onClose={this.hideMenu.bind(this)}>
          {this.getBody()}
        </Menu>
      </div>
    );
  }
};
