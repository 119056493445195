/* eslint-disable react/prop-types,react/sort-comp,react/no-array-index-key,no-param-reassign,
 react/no-access-state-in-setstate */
const React = require('react');
const Tag = require('../HighlightTag.react');
const Input = require('./Input.react');

/**
 * A tagging component with live search.
 * A tag without id is considered displayed but not saved, the delete logic is displayed only for
 * saved tags. A tag is required to have the following structure:
 * {
 *    id: 0,
 *    name: 'VR',
 *    count: 1 -> how many times this tag has been used
 * }
 *
 * PROPS
 * tags: tags already added
 * knownTags: tags used to inflate the live search select
 * handleAdd: function(value) to add a new tag to the list
 * handleDelete: function(value) to remove a tag from the list
 * editable: (bool) if tags can be edited or not
 * placeholder: by default is "Add tag"
 *
 * CONSTRAINTS
 * You cannot add two times the same tag to the list.
 *
 * @type {module.TagsInput}
 */
module.exports = class TagsInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
    };
  }

  /**
   * Returns tags displayed but not already saved.
   */
  getUnsavedTags() {
    return this.state.tags.filter((tag) => tag.id === null || tag.id === undefined);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.tags.length !== nextProps.tags.length || this.getUnsavedTags().length > 0) {
      this.setState({ tags: nextProps.tags });
    }
  }

  handleDelete(id, name) {
    this.props.handleDelete({ id, name });
  }

  getDelete(tag) {
    if (this.props.editable && tag.id && this.props.handleDelete) {
      return this.handleDelete.bind(this);
    }
    return null;
  }

  getTags() {
    return this.state.tags.map((tag, index) => (
      <Tag key={index}
        id={tag.id}
        highlight={tag.highlight}
        onDelete={this.getDelete(tag)}>
        {tag.name}
      </Tag>
    ));
  }

  /**
   * Returns true if tags contains a tag with name = value.
   * @param value
   * @returns {boolean}
   */
  isInTags(value) {
    for (let i = 0; i < this.props.tags.length; i++) {
      if (this.props.tags[i].name === value) return true;
    }
    return false;
  }

  /**
   * Sets {highlight: true} for the the tag with the given name.
   * @param name
   */
  highlightTag(name) {
    const tags = this.state.tags.map((tag) => {
      delete tag.highlight;
      if (tag.name === name) {
        return {
          ...tag,
          highlight: true,
        };
      }
      return tag;
    });
    this.setState({ tags });
  }

  getInput() {
    if (this.props.editable) {
      return (
        <Input add={this.props.handleAdd}
          isInTags={this.isInTags.bind(this)}
          highlight={this.highlightTag.bind(this)}
          placeholder={this.props.placeholder}
          knownTags={this.props.knownTags} />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="wethod-tags">
        {this.getTags()}
        {this.getInput()}
      </div>
    );
  }
};
