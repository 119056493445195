const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ProjectStatusSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  projectStatus: state.projects.filter((projectStatus) => projectStatus.project.id
    === state.focusedProjectId)[0],
  projectInfo: state.projectsInfo
    .filter((projectInfo) => projectInfo.project.id === state.focusedProjectId)[0],
  isLoadingInfo: state.isLoadingProjectInfo.filter((project) => project
    === state.focusedProjectId).length > 0,
  date: state.date,
});

const mapDispatchToProps = (dispatch) => ({
  closeSidebar: () => dispatch(actions.closeSidebar()),
  getProjectInfo: (date, projectId) => dispatch(actions.getProjectInfo(date, projectId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
