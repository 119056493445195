/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Modal = require('../../../../../../../../common/react/modal2/Modal.react');

const ConfirmDeleteModal = ({
  data, onClose, onDelete, isDeleting,
}) => {
  const handleDelete = () => {
    onDelete(data);
  };

  const getFeedBack = () => {
    if (isDeleting) {
      return <div className="profile-contact-info__feedback">Deleting</div>;
    }
    return null;
  };

  return (
    <Modal title="Delete Supplier"
      onCancelClick={onClose}
      className="suppliers__modal">
      <span>
        Are you sure you want to <b>delete</b> this supplier?
      </span>
      <div className="profile-contact-info__actions">
        <button type="button"
          onClick={onClose}
          className="wethod-button wethod-button--warning">
          Cancel
        </button>
        <button type="button"
          onClick={handleDelete}
          className="wethod-button wethod-button--confirm">
          Delete
        </button>
      </div>
      {getFeedBack()}
    </Modal>
  );
};

module.exports = ConfirmDeleteModal;
