const React = require('react');
const Modal = require('../../../../../../../common/react/modal2/Modal.react');

const SendOrderErrorModal = ({ onCancelClick }) => (
  <Modal title="Something went wrong"
    onCancelClick={onCancelClick}
    className="orders__export-success-modal">
    <p>We are currently unable to send this email, please retry later.</p>
    <div className="orders__export-success-modal__actions">
      <button type="button" onClick={onCancelClick} className="wethod-button">Ok</button>
    </div>
  </Modal>
);

module.exports = SendOrderErrorModal;
