const { connect } = require('react-redux');
const Component = require('../components/EmployeesTable.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  employees: state.filteredEmployees,
  levels: state.levels,
  selectedLevel: state.selectedLevel,
  isWaiting: state.waitingFor.filter((key) => key === 'chargeability').length > 0,
  timeFilter: state.timeFilter,
  page: state.load.page,
  size: state.load.size,
  sortBy: state.sortBy,
});

const mapDispatchToProps = (dispatch) => ({
  loadEmployees: (size, page) => dispatch(actions.loadEmployees(size, page)),
  sort: (col, order) => dispatch(actions.sortEmployees(col, order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
