const React = require('react');

/**
 * Keeps an updated list of job order categories, which are updated in older sections.
 * Allows the children to update the categories in the list.
 *
 * @type {JobOrderCategoriesManager}
 */
module.exports = class JobOrderCategoriesManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobOrderCategories: this.props.jobOrderCategories,
    };

    this.onCategoryAdded = this.onCategoryAdded.bind(this);
    this.onCategoryDeleted = this.onCategoryDeleted.bind(this);
    this.onCategoryUpdated = this.onCategoryUpdated.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.updateCategories = this.updateCategories.bind(this);
  }

  componentDidMount() {
    dispatcher.on('project:type:added', this.onCategoryAdded);
    dispatcher.on('project:type:deleted', this.onCategoryDeleted);
    dispatcher.on('project:type:updated', this.onCategoryUpdated);
  }

  /**
   * Add the given job order category model to the list
   * @param model
   */
  onCategoryAdded(model) {
    if (model != null) {
      const joc = model.toJSON();
      this.setState((prevState) => ({
        ...prevState,
        jobOrderCategories: prevState.jobOrderCategories.concat(joc),
      }));
    }
  }

  /**
   * Remove from the list of job order categories the one with the given id
   * @param id
   */
  onCategoryDeleted(id) {
    this.setState((prevState) => ({
      ...prevState,
      jobOrderCategories: prevState.jobOrderCategories.filter((joc) => joc.id !== id),
    }));
  }

  /**
   * Update the job order category in the list with the data from the model
   * @param model
   */
  onCategoryUpdated(model) {
    if (model != null) {
      const joc = model.toJSON();
      this.updateCategory(joc);
    }
  }

  /**
   * Update the job order category in the list with the given data
   * @param joc
   */
  updateCategory(joc) {
    if (joc != null) {
      this.setState((prevState) => ({
        ...prevState,
        jobOrderCategories: prevState.jobOrderCategories.map((category) => {
          if (category.id === joc.id) {
            return { ...joc };
          }
          return category;
        }),
      }));
    }
  }

  updateCategories(jocList) {
    if (jocList) {
      this.setState({ jobOrderCategories: jocList });
    }
  }

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props,
      jobOrderCategories: this.state.jobOrderCategories,
      // onJobOrderCategoryChange: this.updateCategory,
      onJobOrderCategoryChange: this.updateCategories,
    });
  }
};
