/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind */
const React = require('react');
const MilestoneElement = require('./MilestoneElement.react');
const DayPicker = require('../../../../../../../../../../common/react/DayCalendar.react');

module.exports = class MilestoneDateMenu extends React.Component {
  handleDayClick(day) {
    const formattedDate = moment(day).format('YYYY-MM-DD');

    this.props.onChange(formattedDate);
  }

  getMilestoneDatePicker() {
    if (this.props.value) {
      return (
        <div>
          <div className="project-canvas-menu-milestone__element--selected">
            <MilestoneElement onClick={this.props.onMilestoneClick}
              milestone={{ name: this.props.value.type }} />
          </div>
          <div className="project-canvas-menu-milestone__date-picker">
            <DayPicker
              startDate={this.props.value.date}
              onDayClick={this.handleDayClick.bind(this)} />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="project-canvas-menu project-canvas-menu-milestone__list">
          {this.getMilestoneDatePicker()}
        </div>
      </div>
    );
  }
};
