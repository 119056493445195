'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $) {
  this.AuthorizationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var fragment = APIURL + '/authorization/settings/apppermissions?app=dashboard';
      return fragment;
    },
  });
  // invoice
  this.AuthorizationInvoiceModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var fragment = APIURL + '/authorization/settings/apppermissions?app=invoices';
      return fragment;
    },
  });

  var API = {
    getAuthorization: function () {
      var getAuthorization = new DashboardApp.AuthorizationModel();
      var defer = $.Deferred();

      getAuthorization.fetch({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getInvoiceAuthorization: function () {
      var getInvoiceAuthorization = new DashboardApp.AuthorizationInvoiceModel();
      var defer = $.Deferred();

      getInvoiceAuthorization.fetch({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(model);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
  };

  // dashboard
  Wethod.reqres.setHandler('get:dashboard:authorization', function () {
    return API.getAuthorization();
  });
  // invoice
  Wethod.reqres.setHandler('get:dashboard:invoice:authorization', function () {
    return API.getInvoiceAuthorization();
  });
});
