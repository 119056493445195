/* eslint-disable react/prefer-stateless-function */
const React = require('react');

module.exports = class Tips extends React.Component {
  render() {
    return (
      <div className="backlog__tips-container">
        <div className="backlog-trend__overview">
          <h3 className="backlog-trend__title">Tips</h3>
          <div className="backlog-trend__description">Coming soon</div>
        </div>
      </div>
    );
  }
};
