const { connect } = require('react-redux');
const Component = require('../components/ItemsHeader.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  type: state.type,
});

const mapDispatchToProps = (dispatch) => ({
  changeColumnConfiguration: (section, columns) => dispatch(actions
    .changeColumnConfiguration(section, columns)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
