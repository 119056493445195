const React = require('react');

const EmptyApproversMessage = () => (
  <div className="hello">
    <div>
      No one in your company can approve a budget.
    </div>
    <div>
      You can fix this from <span className="bold">Permissions</span>.
    </div>
  </div>
);

module.exports = EmptyApproversMessage;
