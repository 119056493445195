const React = require('react');
const HeaderWindow = require('./HeaderWindow.react');
const LeftColumnWindow = require('./LeftColumnWindow.react');

module.exports = class StickyGrid extends React.Component {
  getComponents() {
    const components = [];
    const { headerRenderer } = this.props;
    const { leftColumnRenderer } = this.props;
    if (headerRenderer) {
      components.push({
        position: 'top',
        component: ({ left, items }) => (
          <HeaderWindow
            key="sticky-top"
            renderer={headerRenderer}
            height={this.props.headerHeight}
            left={this.props.leftColumnWidth + left}
            items={items} />
        ),
      });
    }
    if (leftColumnRenderer) {
      components.push({
        position: 'left',
        component: ({ top, items }) => (
          <LeftColumnWindow
            key="sticky-left"
            renderer={leftColumnRenderer}
            width={this.props.leftColumnWidth}
            top={top}
            items={items} />
        ),
      });
    }
    return components;
  }

  getComponentsSizes() {
    return {
      top: {
        height: this.props.headerHeight,
      },
      left: {
        width: this.props.leftColumnWidth,
      },
    };
  }

  render() {
    return this.props.children({
      stickyElements: this.getComponents(),
      stickyElementsSize: this.getComponentsSizes(),
    });
  }
};
