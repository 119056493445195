/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return, react/no-array-index-key, react/jsx-no-bind, no-use-before-define,
 react/no-access-state-in-setstate */
const React = require('react');
const Sidebar = require('../../../../../../../common/react/sidebar/Sidebar.react');
const Menu = require('../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const ReadOnlyBody = require('./ProjectStatusSidebarBody.react');
const IconLink = require('../../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../../../../../../common/react/Icon/Icon.react');
const IconButtonTooltip = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * project: object corresponding the focused project to show in the sidebar
 * projectInfo
 * isLoadingInfo
 * date
 *
 * closeSidebar
 * getProjectInfo
 *
 * @type {module.ProjectStatusSidebar}
 */
module.exports = class ProjectStatusSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  componentDidMount() {
    if (this.props.projectStatus && !this.props.projectInfo && !this.props.isLoadingInfo) {
      this.props.getProjectInfo(this.props.date, this.props.projectStatus.project.id);
    }
  }

  getBody() {
    if (this.props.projectStatus) {
      return (
        <ReadOnlyBody
          areas={this.props.projectStatus.areas}
          info={this.props.projectInfo}
          isLoadingInfo={this.props.isLoadingInfo || !this.props.projectInfo}
          date={this.props.date} />
      );
    }

    return <div />;
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MenuItem className="wethod-menu__item--link">
          <a href={`#timeline/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Timeline
          </a>
        </MenuItem>
        <MenuItem className="hidden-mobile wethod-menu__item--link">
          <a href={`#planning/production-plan?project_id:${projectId}`}
            target="_blank"
            rel="noreferrer">
            Production plan
          </a>
        </MenuItem>
        <MenuItem className="hidden-mobile wethod-menu__item--link">
          <a href={`/#project/${projectId}/invoice-plan`}
            target="_blank"
            rel="noreferrer">
            Invoice plan
          </a>
        </MenuItem>
      </Menu>
    );
  }

  setMenuAnchorRef(ref) {
    this.optionsButton = ref;
  }

  getActions() {
    const projectId = this.props.projectStatus ? this.props.projectStatus.project.id : '';
    return (
      <div>
        <IconLink label="Canvas"
          borderless
          href={`#project/${projectId}/canvas`}
          target="_blank">
          <span className="wethod-icon wethod-icon-canvas wethod-icon-canvas--black" />
        </IconLink>
        <IconLink label="Planning"
          borderless
          href={`#planning/people?project_id=${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
        </IconLink>
        <IconLink label="Budget"
          borderless
          href={`#pipeline/budget/${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
        </IconLink>
        <IconLink label="Report"
          borderless
          href={`#project/${projectId}/report`}
          target="_blank">
          <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
        </IconLink>
        <IconLink label="Pipeline"
          borderless
          href={`#pipeline/projects?id=${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
        </IconLink>
        <IconButtonTooltip label="More options"
          borderless
          onClick={this.showMenu.bind(this)}
          rootRef={this.setMenuAnchorRef.bind(this)}>
          <Icon icon="more" />
        </IconButtonTooltip>
        {this.getOptionsMenu(projectId)}
      </div>
    );
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  render() {
    return (
      <Sidebar
        title="Project status"
        canEdit={false}
        onClose={this.props.closeSidebar}
        onCancel={this.props.closeSidebar}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
