const React = require('react');

module.exports = class MultipleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchHeight: 0,
      footerHeight: 0,
    };
  }

  getSearch() {
    const { search } = this.props;

    if (search) {
      return {
        ...search,
        props: {
          ...search.props,
          updateHeight: this.updateHeight.bind(this),
        },
      };
    }
    return null;
  }

  getFooter() {
    const { footer } = this.props;

    if (footer) {
      return {
        ...footer,
        props: {
          ...footer.props,
          updateHeight: this.updateHeight.bind(this),
        },
      };
    }
    return null;
  }

  getContent() {
    const minBodyHeight = this.getRelativeBodyHeight(this.props.minHeight);
    const maxBodyHeight = this.getRelativeBodyHeight(this.props.maxHeight);

    return this.props.children.map((children) => ({
      ...children,
      props: {
        ...children.props,
        columns: this.props.columns,
        updateHeight: this.updateHeight.bind(this),
        minHeight: minBodyHeight,
        maxHeight: maxBodyHeight,
      },
    }));
  }

  /**
   * Calculates and returns body height by using tableHeight as total and subtracting all the
   * elements other from body.
   * @param tableHeight
   * @return {*}
   */
  getRelativeBodyHeight(tableHeight) {
    if (tableHeight) {
      return tableHeight - this.state.searchHeight - this.state.footerHeight - 4;
    }
    return null;
  }

  getClassName() {
    let style = 'multiple-table';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  /**
   * Updates name's height.
   *
   * @param name head|search|footer
   * @param value new height's value
   */
  updateHeight(name, value) {
    this.setState({ [`${name}Height`]: value });
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getSearch()}
        <div className="multiple-table-list"
          style={{ height: this.getRelativeBodyHeight(this.props.height) }}>
          {this.getContent()}
        </div>
        {this.getFooter()}
      </div>
    );
  }
};
