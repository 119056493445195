const React = require('react');
const PropTypes = require('prop-types');

const CannotFindProjecFolderModalBody = ({ createProjectFolder, path, waiting }) => {
  const getFeedback = () => {
    if (waiting) {
      return <div className="profile-contact-info__feedback">Creating</div>;
    }
    return null;
  };

  const formattedPath = path.join(' > ');

  return (
    <div>
      <div>
        We cannot find
        folder <span style={{ fontStyle: 'italic' }}>{formattedPath}</span>.
        <br /> <br />
        Do you want to create it?
      </div>
      <div className="profile-contact-info__actions">
        <button type="button"
          onClick={createProjectFolder}
          className="wethod-button">Yes
        </button>
      </div>
      {getFeedback()}
    </div>
  );
};

CannotFindProjecFolderModalBody.propTypes = {
  createProjectFolder: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  waiting: PropTypes.bool.isRequired,
};

module.exports = CannotFindProjecFolderModalBody;
