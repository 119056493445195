/* eslint-disable camelcase */
const React = require('react');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const PV = require('../../../../common/components/PercentageValue.react');
const Comparator = require('../../../containers/sections/Time/TargetComparator');

module.exports = ({
  chargeability, prev_week_chargeability, prev_year_chargeability, target,
}) => (
  <Paragraph>
    The <span className="bold">chargeability</span> of the week is
    {' '}
    <Comparator value={chargeability} /> its target of <PV value={target} />. The week before was
    {' '}
    <PV value={prev_week_chargeability} />, while was <PV value={prev_year_chargeability} />
    {' '}
    the same week last year.
  </Paragraph>
);
