/* eslint-disable react/prop-types,react/sort-comp,jsx-a11y/alt-text */
const React = require('react');

/**
 * An enhanced image input with:
 *
 * VALIDATION
 * Checks if the file you're trying to add is actually an image (PNG, JPEG, GIF).
 * Pass a size prop to limit accepted file sizes.
 *
 * LIFECYCLE HOOKS
 * onChange, onValidate
 *
 * OTHER PROPS
 * name, src, size
 *
 * @type {module.ImageInput}
 */
module.exports = class ImageInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      src: this.props.src ? this.props.src : '',
      constraints: this.getConstraints(),
      file: null,
    };

    this.errors = [];
    this.accept = ['image/png', 'image/jpeg', 'image/gif'];
  }

  getConstraints() {
    const constraints = ['accepted'];
    if (this.props.size) constraints.push('size');
    return constraints;
  }

  readerOnLoad(e) {
    const { result } = e.target;
    this.setState({ src: result });
    this.props.onChange(this.state.file);
  }

  componentDidMount() {
    this.reader = new FileReader();
    this.reader.onload = this.readerOnLoad.bind(this);
  }

  size(file) {
    return file.size <= this.props.size * 1000;
  }

  accepted(file) {
    for (let i = 0; i < this.accept.length; i++) {
      if (file.type === this.accept[i]) return true;
    }
    return false;
  }

  validate(file) {
    const errors = [];
    for (let i = 0; i < this.state.constraints.length; i++) {
      if (!this[this.state.constraints[i]](file)) errors.push(this.state.constraints[i]);
    }
    return errors;
  }

  isValid(file) {
    this.errors = this.validate(file);
    if (this.props.onValidate) this.props.onValidate(this.props.name, this.errors);
    return this.errors.length === 0;
  }

  onChange(e) {
    const file = e.target.files[0];
    if (this.isValid(file)) {
      this.setState({ file });
      this.reader.readAsDataURL(file);
    }
  }

  onUploadClick() {
    this.input.click();
  }

  getContent() {
    if (this.state.src) {
      return (
        <div className="wethod-image-input__preview">
          <img src={this.state.src} />
        </div>
      );
    }
    return (
      <button type="button"
        className="wethod-button"
        onClick={this.onUploadClick.bind(this)}>
        Upload from computer
      </button>
    );
  }

  render() {
    return (
      <div className="wethod-image-input">
        <input ref={(input) => this.input = input}
          type="file"
          name={this.props.name}
          accept={this.accept.toString()}
          onChange={this.onChange.bind(this)} />
        {this.getContent()}
      </div>
    );
  }
};
