/* eslint-disable consistent-return,react/jsx-no-bind */
const React = require('react');
const Actions = require('../../../../../../../common/react/widgets/Files/components/FileActions.react');
const Loading = require('../../../../../../../common/react/widgets/Files/components/SmallLoader.react');

const File = ({ file, onDownload, onDelete, downloadable, isWaiting }) => {
  const handleDownload = () => {
    if (downloadable && onDownload) {
      onDownload(file);
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(file);
    }
  };

  const getDownloadStyle = () => {
    let style = 'wethod-icon-button wethod-icon-button--no-border';
    if (!downloadable || !onDownload) {
      style += ' disabled';
    }
    return style;
  };

  const getMoreActions = () => {
    if (onDelete) {
      if (isWaiting) {
        return (
          <Loading />
        );
      }
      return (
        <Actions onDeleteClick={handleDelete} />
      );
    }
  };

  return (
    <div className="travel-files__item">
      <span className="travel-files__item--name">
        {file.name}
      </span>
      <span className="travel-files__item--actions">
        <button type="button"
          className={getDownloadStyle()}
          onClick={handleDownload}>
          <div className="wethod-icon wethod-icon-download wethod-icon-download--black" />
        </button>
        {getMoreActions()}
      </span>
    </div>
  );
};

module.exports = File;
