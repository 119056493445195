/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,consistent-return,prefer-destructuring */
const React = require('react');

module.exports = class Event extends React.Component {
  constructor(props) {
    super(props);

    this.typeNames = {
      timeline_call: 'Call',
      timeline_email: 'Email',
      timeline_meeting: 'Meeting',
    };
    this.iconClasses = {
      Call: 'wethod-icon-phone',
      Email: 'wethod-icon-mail',
      Meeting: 'wethod-icon-meeting',
      Event: 'wethod-icon-event',
      Ship: 'wethod-icon-ship',
      Travel: 'wethod-icon-travel',
      Closing: 'wethod-icon-closing',
      Other: 'wethod-icon-other',
    };
  }

  getDate() {
    return moment(this.props.timestamp).format('D MMM');
  }

  getIconClass(type) {
    return this.iconClasses[type];
  }

  isHeatmapEvent() {
    return this.props.type === 'heatmap_event';
  }

  getIcon() {
    if (this.typeNames[this.props.type]) {
      return <div className={this.getIconClass(this.typeNames[this.props.type])} />;
    }
    if (this.isHeatmapEvent()) {
      return <div className={this.getIconClass(this.props.event.type.name)} />;
    }
    return <div className={this.getIconClass(this.props.event.type.label)} />;
  }

  toggleDetails() {
    if (this.props.focusedEvent && this.props.focusedEvent.id === this.props.event.id) {
      this.props.hideDetails();
    } else {
      this.props.showDetails({
        ...this.props.event,
        isFollowUp: this.props.type === 'timeline_follow_up',
        isHeatmapEvent: this.isHeatmapEvent(),
      });
    }
  }

  handleClick() {
    this.toggleDetails();
  }

  getClassName() {
    let name = 'timeline-event-future';
    if (this.props.focusedEvent && this.props.event.id === this.props.focusedEvent.id) {
      name += ' timeline-event-future--selected';
    }
    return name;
  }

  render() {
    return (
      <div className={this.getClassName()} onClick={this.handleClick.bind(this)}>
        <div className="timeline-event-future__date">{this.getDate()}</div>
        <div className="timeline-event-future__type">
          {this.getIcon()}
        </div>
      </div>
    );
  }
};
