const React = require('react');

module.exports = class AutoSizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 1,
      height: 1,
    };
  }

  componentDidMount() {
    this.setState({
      width: this.el.parentElement.offsetWidth,
      height: this.el.parentElement.offsetHeight,
    });
  }

  render() {
    return (
      <div ref={(el) => (this.el = el)}>
        {this.props.children({
          width: this.state.width,
          height: this.state.height,
        })}
      </div>
    );
  }
};
