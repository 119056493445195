'use strict';

var TranslatorService = require('../../../apps/core/modules/pipeline/services/TranslatorService');

/* eslint-disable default-case */
Wethod.module('PipelineApp.Pipeline', function (Pipeline, Wethod, Backbone, Marionette, $, _) {
  // model & collection select probability
  this.ProbabilityModel = Wethod.Utility.BaseModel.extend({});
  this.ProbabilityCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.ProbabilityModel,
  });

  // model & collection select external cost
  this.ExternalCostModel = Wethod.Utility.BaseModel.extend({});
  this.ExternalCostCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.ExternalCostModel,
  });

  // model & collection select mesi
  this.YearModel = Wethod.Utility.BaseModel.extend({});
  this.MonthModel = Wethod.Utility.BaseModel.extend({});
  this.MonthCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.MonthModel,
  });

  // model & collection select dei poject type
  this.ProjecTypetModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/projecttype/',
  });
  this.ProjecTypesCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/projecttype/',
    model: Pipeline.ProjecTypetModel,
  });

  // model & collection select dei poject type
  this.ProjecLabelModel = Wethod.Utility.BaseModel.extend();
  this.ProjecLabelsCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/projectlabel/',
    model: Pipeline.ProjecLabelModel,
    comparator: function (model) {
      return model.get('sort');
    },
  });

  this.ClientModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/client/',
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        // eslint-disable-next-line default-case
        switch (key) {
          case 'corporate_name':
          case 'acronym':
            var field = '';
            // eslint-disable-next-line default-case
            switch (key) {
              case 'corporate_name':
                field = 'Client name';
                break;
              case 'acronym':
                field = 'Client acronym';
                break;
            }
            if (attrs[key].trim() === '') {
              error[key] = 'The ' + field + ' must be NOT empty';
              errors.push(error);
            }
            break;
        }
      });

      return (errors.length > 0) ? errors : false;
    },
  });

  this.ContactModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/crm/customer/',
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        switch (key) {
          case 'name':
          case 'surname':
          case 'email':
            var field = '';
            switch (key) {
              case 'name':
                field = 'Contact name';
                break;
              case 'surname':
                field = 'Contact surname';
                break;
              case 'email':
                field = 'Contact email';
                break;
            }
            if (attrs[key].trim() === '') {
              error[key] = 'The ' + field + ' must be NOT empty';
              errors.push(error);
            }
            break;
        }
      });

      return (errors.length > 0) ? errors : false;
    },
  });

  // model & collection relativi ai progetti
  this.requestPipeline = [];

  this.PipelineModel = Wethod.Utility.BaseModel.extend({});
  this.PipelineCollection = Wethod.Utility.BaseCollection.extend({
    url: function () {
      var filtersString = TranslatorService.serializeFilters(this.options.filters);
      var address = APIURL + '/pipeline?'
        + 'limit=' + this.options.limit
        + '&offset=' + this.options.offset
        + '&order=' + this.options.orderBy
        + '&sort=' + this.options.sort;

      if (this.options.bu !== 'all') {
        address += '&bu=' + this.options.bu;
      }

      if (filtersString !== '') {
        address += '&' + filtersString;
      }

      return address;
    },
    model: Pipeline.PipelineModel,
  });

  this.ProjectSafeModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/pipeline',
  });

  // madal model
  this.ModalModel = Wethod.Utility.BaseModel.extend();

  // invoiceplanboard model
  this.InvoiceModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/invoiceplan/',
  });

  this.InvoiceDetailModel = Wethod.Utility.BaseModel.extend();
  this.InvoiceDetailCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.InvoiceDetailModel,
  });

  this.InvoiceOffsetlModel = Wethod.Utility.BaseModel.extend();
  this.InvoiceOffsetCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.InvoiceOffsetlModel,
  });

  // model & collection relativi all'autocompletamento
  this.AutocompleteModel = Wethod.Utility.BaseModel.extend({});
  this.AutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.whatSearch)) ? '?on=' + this.options.whereSearch + '&like=' + this.options.whatSearch : '?on=' + this.options.whereSearch;
      if (this.options.client_id) {
        params += '&client_id=' + this.options.client_id;
      }

      return APIURL + '/pipeline/autocomplete' + params;
    },
    model: Pipeline.AutocompleteModel,
  });

  this.ProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/project/',
    defaults: function () {
      return {
        project: {
          id: null,
          name: null,
          description: null,
          id_project_type: null,
          id_project_label: null,
          id_client: null,
          id_customer: null,
          id_budget: null,
          id_job_order: null,
          client_po: null,
          id_pm: null,
          id_account: null,
          estimate: 0,
          probability: 0,
          date_start: null,
          duration: null,
          external_cost: 0,
          archived: 0,
          visibility: 1,
        },
      };
    },
  });

  this.InvoicePlanModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/invoiceplan/',
  });

  this.InvoicePlanAmountModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/invoiceplanamount/',
  });

  // metadata
  this.MetadataModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/projectmetadata/',
  });
  this.MetadataCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/metadata/',
    model: Pipeline.MetadataModel,
  });

  this.MetadataValuesModel = Wethod.Utility.BaseModel.extend({});
  this.MetadataValuesCollection = Wethod.Utility.BaseCollection.extend({
    model: Pipeline.MetadataValuesModel,
  });

  var API = {
    getPipelineProjects: function (options) {
      options = options || {};
      var collection = new Pipeline.PipelineCollection(options);
      var defer = $.Deferred();

      collection.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.reject(undefined);
        },
      });
      return defer.promise();
    },
    getProjectInfo: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/project/' + options.projectId + '/info',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
    getProjectLastReview: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/review/last-request/' + options.projectId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
    requestProjectReview: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/review/request/' + options.projectId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
    saveProjects: function (projectModel, changes) {
      var defer = $.Deferred();

      projectModel.save(changes, {
        success: function (model, response) {
          if (response.code === 200 || response.code === 201) {
            defer.resolve(model, response);
          } else {
            defer.reject(response);
          }
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
        patch: true,
      });

      return defer.promise();
    },
    deleteProject: function (projectModel) {
      var safeProject = new Pipeline.ProjectSafeModel({ id: projectModel.get('id') });
      var defer = $.Deferred();

      safeProject.destroy({
        success: function (model, response) {
          if (response.code === 200) {
            defer.resolve(response);
          } else {
            defer.reject(response.message);
          }
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    archiveProject: function (projectModel, archive) {
      var defer = $.Deferred();

      var changes = {
        archived: archive,
      };

      $.when(Wethod.request('save:pipeline:project', projectModel, changes))
        .done(function (model) {
          defer.resolve(model);
        })
        .fail(function (model) {
          defer.reject(model);
        });

      return defer.promise();
    },
    saveClient: function (clientModel) {
      var defer = $.Deferred();

      clientModel.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    saveContact: function (model) {
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel) {
          defer.resolve(responseModel);
        },
        error: function (responseModel, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getProjectType: function () {
      var record = new Pipeline.ProjecTypesCollection();
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getProjectLabel: function () {
      var record = new Pipeline.ProjecLabelsCollection();
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getAutoComplete: function (data) {
      var record = new Pipeline.AutocompleteCollection(data);
      var defer = $.Deferred();

      record.fetch({
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    getInvocePlan: function (projectId) {
      var invocePlane = new Pipeline.InvoicePlanModel({ id: projectId });
      var defer = $.Deferred();

      invocePlane.fetch({
        success: function (data, response) {
          var isLinked = false;
          if (response.code === 301) {
            isLinked = true;
            defer.resolve(data, isLinked);
          } else {
            defer.resolve(data, isLinked);
          }
        },
        error: function (response) {
          defer.reject(response);
        },
      });
      return defer.promise();
    },
    saveInvoicePlan: function (data, projectId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'PUT',
        crossDomain: true,
        url: APIURL + '/invoiceplan/' + projectId,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve(response);
        } else {
          defer.reject(response);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });
      return defer.promise();
    },
    saveInvoicePlanAmount: function (data) {
      var invocePlaneAmount = new Pipeline.InvoicePlanAmountModel(data);
      var defer = $.Deferred();

      invocePlaneAmount.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });
      return defer.promise();
    },
    deleteInvoicePlanAmount: function (data) {
      var invocePlaneAmount = new Pipeline.InvoicePlanAmountModel(data);
      var defer = $.Deferred();

      invocePlaneAmount.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.reject(undefined);
        },
        wait: true,
      });

      return defer.promise();
    },
    sendShareProject: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/pipeline/' + options.projectId + '/share-with/' + options.employeeId,
        xhrFields: {
          withCredentials: true,
        },
        data: {
          path: options.path,
          message: options.message,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    sendSearchShare: function (options) {
      var message = options.message;
      var employeeId = options.employeeId;
      var path = options.path;

      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/pipeline/share-search/' + employeeId,
        xhrFields: {
          withCredentials: true,
        },
        data: {
          message: message,
          path: path,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
    saveFavouriteSearch: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/desk/research',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(options),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
    getProjectMetadata: function () {
      var metadata = new Pipeline.MetadataCollection();
      var defer = $.Deferred();

      metadata.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    saveProjectMetadata: function (projectMetadataData) {
      var projectMetadata = new Pipeline.MetadataModel(projectMetadataData);
      var defer = $.Deferred();

      projectMetadata.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    deleteProjectMetadata: function (projectMetadataData) {
      var projectMetadata = new Pipeline.MetadataModel(projectMetadataData);
      var defer = $.Deferred();

      projectMetadata.destroy({
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.reject(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    getIntercompanyInvitationInfo: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/intercompany/invitation/' + options.token,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code !== 200) {
          defer.reject(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    getIntercompanyConnectableProjects: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/intercompany/projects',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    getIntercompanySupplierInfo: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/intercompany/info/by-supplier/' + options.projectId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    getIntercompanyTaskInfo: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/intercompany/info/by-task/' + options.taskId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    connectProject: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/intercompany/connect/' + options.token + '/with-project/' + options.projectId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code !== 200) {
          defer.reject(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function (response) {
        defer.reject(response);
      });
      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:pipeline', function (options) {
    return API.getPipelineProjects(options);
  });

  Wethod.reqres.setHandler('save:pipeline:project', function (model, changes) {
    return API.saveProjects(model, changes);
  });
  Wethod.reqres.setHandler('delete:pipeline:project', function (model) {
    return API.deleteProject(model);
  });
  Wethod.reqres.setHandler('archive:pipeline:project', function (model, archive) {
    return API.archiveProject(model, archive);
  });
  Wethod.reqres.setHandler('get:pipeline:projects:type', function () {
    return API.getProjectType();
  });
  Wethod.reqres.setHandler('get:pipeline:projects:label', function () {
    return API.getProjectLabel();
  });

  Wethod.reqres.setHandler('get:pipeline:autocomplete', function (data) {
    return API.getAutoComplete(data);
  });
  Wethod.reqres.setHandler('save:pipeline:client', function (model) {
    return API.saveClient(model);
  });
  Wethod.reqres.setHandler('save:pipeline:contact', function (model) {
    return API.saveContact(model);
  });

  // send share project
  Wethod.reqres.setHandler('send:pipeline:project:shareProject', function (options) {
    return API.sendShareProject(options);
  });

  // send search share
  Wethod.reqres.setHandler('send:pipeline:search:share', function (options) {
    return API.sendSearchShare(options);
  });

  // save search as favourite
  Wethod.reqres.setHandler('save:pipeline:favourite:search', function (options) {
    return API.saveFavouriteSearch(options);
  });

  // invoice plan
  Wethod.reqres.setHandler('get:pipeline:invocePlan', function (projectId) {
    return API.getInvocePlan(projectId);
  });
  Wethod.reqres.setHandler('save:pipeline:invoicePlan', function (data, projectId) {
    return API.saveInvoicePlan(data, projectId);
  });
  Wethod.reqres.setHandler('save:pipeline:invocePlanAmount', function (data) {
    return API.saveInvoicePlanAmount(data);
  });
  Wethod.reqres.setHandler('delete:pipeline:invocePlanAmount', function (data) {
    return API.deleteInvoicePlanAmount(data);
  });

  // metadata
  Wethod.reqres.setHandler('get:pipeline:projects:metadata', function () {
    return API.getProjectMetadata();
  });
  Wethod.reqres.setHandler('save:pipeline:project:metadata', function (projMetadataData) {
    return API.saveProjectMetadata(projMetadataData);
  });
  Wethod.reqres.setHandler('delete:pipeline:project:metadata', function (projMetadataData) {
    return API.deleteProjectMetadata(projMetadataData);
  });

  // intercompany
  Wethod.reqres.setHandler('pipeline:intercompany:invitation:info', function (options) {
    return API.getIntercompanyInvitationInfo(options);
  });
  Wethod.reqres.setHandler('pipeline:intercompany:connectable:projects', function (options) {
    return API.getIntercompanyConnectableProjects(options);
  });
  Wethod.reqres.setHandler('pipeline:intercompany:project:connect', function (options) {
    return API.connectProject(options);
  });
  Wethod.reqres.setHandler('pipeline:intercompany:supplier:details', function (options) {
    return API.getIntercompanySupplierInfo(options);
  });
  Wethod.reqres.setHandler('pipeline:intercompany:task:details', function (options) {
    return API.getIntercompanyTaskInfo(options);
  });

  Wethod.reqres.setHandler('get:project:info', function (options) {
    return API.getProjectInfo(options);
  });

  Wethod.reqres.setHandler('get:project:review:last', function (options) {
    return API.getProjectLastReview(options);
  });
  Wethod.reqres.setHandler('project:review:request', function (options) {
    return API.requestProjectReview(options);
  });
});
