/* eslint-disable react/sort-comp */
const React = require('react');
const TextInput = require('../../../../../../../../common/react/TextInput.react');
const NumericInput = require('../../../../../../../../common/react/NumericInput.react');

module.exports = class KeyResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      target: this.props.target.toString(),
      errors: [],
    };

    this.timer = 0;
  }

  save() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.state.errors.length) {
        this.props.editKeyResult({
          id: this.props.id,
          name: this.state.name,
          progress: this.props.progress,
          target: parseInt(this.state.target),
        }, this.props.quarterId, this.props.objectiveId, true);
      }
    }, 500);
  }

  onChange(name, value) {
    const nextState = { ...this.state };
    nextState[name] = value;
    this.setState(nextState, () => {
      this.save();
    });
  }

  addError(name) {
    // Passing a function to setState lets you avoid async state update problems.
    if (this.state.errors.indexOf(name) === -1) {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.concat(name),
      }), () => {
        this.props.updateFormErrors(this.props.id, 'add');
      });
    }
  }

  removeError(name) {
    this.setState((previousState) => ({
      ...previousState,
      errors: previousState.errors.filter((error) => error !== name),
    }), () => {
      if (!this.state.errors.length) {
        this.props.updateFormErrors(this.props.id, 'remove');
      }
    });
  }

  onValidate(name, errors) {
    if (errors.length > 0) {
      this.addError(name);
    } else {
      this.removeError(name);
    }
  }

  getInputClassName(name) {
    let className = `profile-objectives__key-result-${name} profile-objectives__key-result-${name}-col`;
    if (this.state.errors.indexOf(name) !== -1) {
      className = `${className} wethod-modal2-input--error`;
    }
    return className;
  }

  onDeleteClick() {
    this.props.deleteKeyResult({ ...this.props }, this.props.quarterId, this.props.objectiveId);
    this.props.updateFormErrors(this.props.id, 'remove');
  }

  render() {
    return (
      <div className="profile-objectives__key-result">
        <span className={this.getInputClassName('name')}>
          <TextInput name="name"
            value={this.state.name}
            constraints="required"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
        </span>
        <span className={this.getInputClassName('target')}>
          <NumericInput name="target"
            value={this.state.target}
            constraints="required,integer"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
        </span>
        <button type="button"
          onClick={this.onDeleteClick.bind(this)}
          className="profile-languages__item-delete wethod-icon-button">
          <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
        </button>
      </div>
    );
  }
};
