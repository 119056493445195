const constants = require('./constants');
const BusinessUnitService = require('../../../../../services/BusinessUnitService');

class BudgetChangelogReducer {
  constructor(state) {
    this.businessUnitService = new BusinessUnitService();

    this.state = {
      ...state,
      logs: [],
      waitingFor: [],
      modal: null,
      modalData: null,
      size: 0,
      keyword: null,
      sortBy: {
        col: 'approved_on',
        order: 'desc',
      },
      buEnabled: this.businessUnitService.isEnabled(),
      buFilter: this.businessUnitService.getLastSelected(),
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.LOAD_CHANGELOG_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('changelog'),
        };
      case constants.LOAD_CHANGELOG_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'changelog'),
          logs: state.logs.concat(action.items),
          size: action.size,
          lastPage: action.items.length < action.size,
        };
      case constants.FILTER_CHANGELOG_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('changelog'),
          logs: [],
        };
      case constants.FILTER_CHANGELOG_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'changelog'),
          logs: action.items,
          lastPage: action.items.length < action.size,
          keyword: action.keyword,
          sortBy: { col: action.orderBy, order: action.sort },
          buFilter: action.bu,
        };
      case constants.SHOW_MODAL:
        return {
          ...state,
          modal: action.name,
          modalData: action.data,
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
          modalData: null,
        };
      default:
        return state;
    }
  }
}

module.exports = BudgetChangelogReducer;
