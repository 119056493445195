const { connect } = require('react-redux');
const Component = require('../components/ProductSearch.react');
// const actions = require('../actions');

const mapStateToProps = (state) => ({
  search: state.search,
});

const mapDispatchToProps = () => ({
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
