const { connect } = require('react-redux');
const Component = require('../components/ProjectSelector.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  projectSelected: state.selected_project,
  recurringModeEnabled: state.recurring_mode_enabled,
  plannedProjects: state.planned_projects,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (name, data) => dispatch(actions.showModal(name, data)),
  selectProject: (projectId) => dispatch(actions.getProjectAvailability(projectId)),
  showMissingProjectModal: () => dispatch(actions.showModal('missing-project')),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
