const React = require('react');

const ProjectSidebarDescription = () => (
  <span>
    A project is a won opportunity, and its probability is 90% or higher.{' '}
    Otherwise, create a new project opportunity from <a href="/#pipeline/opportunities">Pipeline Opportunities</a>
  </span>
);

module.exports = ProjectSidebarDescription;
