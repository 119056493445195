/* eslint-disable react/prop-types,react/no-array-index-key */
const React = require('react');
const IntercompanyActivity = require('./IntercompanyActivityController.react');
const Model = require('../models/IntercompanyActivityWidget');
const Loading = require('../../Loading.react');
const Empty = require('./Empty.react');

module.exports = class IntercompanyActivities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: true,
      activities: [],
    };
  }

  componentDidMount() {
    const model = new Model();

    $.when(model.getModel()).done((activities) => this.setState({
      activities,
      waiting: false,
    }));
  }

  getBody() {
    if (this.state.waiting) {
      return <Loading />;
    }
    if (!this.state.activities.length) {
      return <Empty />;
    }
    return this.state.activities.map((activity, index) => (
      <IntercompanyActivity key={index}
        type={activity.type}
        activity={activity} />
    ));
  }

  render() {
    const title = this.props.title ? this.props.title : 'Intercompany activity';
    return (
      <div className="wethod-widget widget-intercompany">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">{title}</span>
        </div>
        <div className="wethod-widget__body">{this.getBody()}</div>
      </div>
    );
  }
};
