const { connect } = require('react-redux');
const Component = require('../../components/Boards/DeleteModal/ConfirmAttributeDeleteModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (boardId, attributeId) => dispatch(actions.deleteBoardStructureAttribute(boardId,
    attributeId)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
