const React = require('react');
const Actions = require('../containers/Actions');
const ExpensesList = require('../containers/ExpensesList');
const Sidebar = require('../containers/SidebarController');
const Modal = require('../containers/ModalController');
const PermissionManager = require('../containers/PermissionManager');

const Expenses = () => (
  <div className="wethod-section-body expenses">
    <PermissionManager>
      <Actions />
    </PermissionManager>
    <ExpensesList />
    <Sidebar />
    <Modal />
  </div>
);

module.exports = Expenses;
