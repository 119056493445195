const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

/* eslint-disable no-param-reassign,class-methods-use-this,no-throw-literal,consistent-return */
module.exports = class TimeFilter {
  /**
   * @param period {int} a number of years
   * @param frame {string} a string representing a subset of the given period (i.e. a quarter, a
   *   month, a week, ecc.)
   */
  constructor(period, frame) {
    this.period = period;
    this.fiscalYearService = new FiscalYearService();

    this.currentYear = this.fiscalYearService.getShiftedYear(moment().day('Thursday').year());
    this.frame = frame;
  }

  /**
   * Return an array of month between start and end, formatted as 'YYYY-MM-DD'.
   *
   * @param start Moment
   * @param end Moment
   * @returns {Array} of months formatted as 2019-10-12
   */
  getMonths(start, end) {
    const length = end.diff(start, 'months') + 1;
    const months = [];
    for (let i = 0; i < length; i++) {
      const month = start.clone();
      months.push(month.add(i, 'months').format('YYYY-MM-DD'));
    }
    return months;
  }

  // eslint-disable-next-line no-unused-vars
  setFrame(value) {
    throw 'Please instantiate a getMonthsInFrame method';
  }

  /**
   * Return an array of months belonging to the filter's period.
   *
   * @returns {Array} of months formatted as 2019-10-12
   */
  getMonthsInPeriod() {
    const endMonth = this.fiscalYearService.getFiscalYearEndDate(this.currentYear).month() + 1;
    const endYear = endMonth === 12 ? this.currentYear : this.currentYear + 1;
    const end = moment(`${endYear}-${endMonth}-01`);
    const start = end.clone().subtract(12 * this.period - 1, 'months');
    return this.getMonths(start, end);
  }

  /**
   * Return an array of months belonging to the filter's period's frame.
   *
   * @returns {Array} of months formatted as 2019-10-12
   */
  getMonthsInFrame() {
    throw 'Please instantiate a getMonthsInFrame method';
  }

  /**
   * Returns the label used for this filter.
   *
   * @return {string}
   */
  getLabel() {
    throw 'Please instantiate a getLabel method';
  }

  toJSON() {
    return {
      period: this.period,
      frame: this.frame,
    };
  }

  /**
   * Returns a list of breakpoints used by the filter.
   * A breakpoint is a way to group months contained in filter.
   *
   * @see TimeBreakpoint
   *
   * @return {Array} of TimeBreakpoint
   */
  getBreakpoints() {
    throw 'Please instantiate a getBreakpoints method';
  }

  /**
   * Returns the breakpoint's index to which date belongs.
   * Breakpoints list must be the same returned by getBreakpoints().
   *
   * @param date
   * @return {number}
   */
  // eslint-disable-next-line no-unused-vars
  getBreakpointIndexForDate(date) {
    throw 'Please instantiate a getCurrentBreakpoint method';
  }

  /**
   * Returns the start date of the period (corresponding the first day of the first included year)
   * @returns {moment.Moment}
   */
  getStartDate() {
    return this.fiscalYearService
      .getFiscalYearStartDate(this.currentYear - (this.period - 1));
  }

  /**
   * Returns the end date of the period (corresponding the last day of current year)
   * @returns {moment.Moment}
   */
  getEndDate() {
    return this.fiscalYearService
      .getFiscalYearEndDate(this.currentYear);
  }
};
