const { connect } = require('react-redux');
const Component = require('../../components/modal/ExportModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'export').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  export: (from, to) => dispatch(actions.export(from, to)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
