const { connect } = require('react-redux');
const Component = require('../../components/sidebar/OrderSidebar.react');
const actions = require('../../actions');
const { getPermission } = require('../../services/authManager');

const mapStateToProps = (state) => ({
  item: state.focusedItem || {},
  canEdit: getPermission('edit', state.focusedItem),
  isSaving: state.waitingFor.filter((key) => key === 'save-order').length > 0,
  validationError: state.serverErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (item) => dispatch(actions.updateOrder(item)),
  onDelete: (order) => dispatch(actions.showModal('delete', order)),
  onClose: () => dispatch(actions.closeSidebar()),
  resetOrderServerError: () => dispatch(actions.resetOrderServerError()),
  approveOrder: (order) => dispatch(actions.approveOrder(order)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
