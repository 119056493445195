'use strict';

Wethod.module('ReportApp.Report', function (Report, Wethod, Backbone, Marionette, $, _) {
  this.ReportModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/report/',
  });

  /**
   * Hour/User's table's footer model.
   */
  this.HoursUsersFooterModel = Wethod.Utility.BaseModel.extend({});

  /**
   * Hour/User's table's body collection.
   */
  this.HoursUsersDetailsCollection = Wethod.Utility.BaseCollection.extend({
    model: Report.ReportModel,

    /**
     * Calculate the sum for each numeric column in Hour/User's table.
     * @returns {{last_week: {planned: number, worked: number}, total: {planned: number, worked:
     *   number}}}
     */
    getTotals: function () {
      var totals = {
        last_week: {
          planned: 0,
          worked: 0,
        },
        total: {
          planned: 0,
          worked: 0,
        },
      };

      _.each(this.toJSON(), function (report) {
        totals.last_week.planned += parseFloat(report.last_week_planned_hours);
        totals.last_week.worked += parseFloat(report.last_week_worked_hours);
        totals.total.planned += parseFloat(report.planned_hours);
        totals.total.worked += parseFloat(report.worked_hours);
      });

      return totals;
    },
  });

  // Invoices model and collection
  this.invoiceModel = Wethod.Utility.BaseModel.extend({});

  this.InvoicesCollection = Wethod.Utility.BaseCollection.extend({
    model: Report.invoiceModel,
  });

  this.CompositeInvoiceModel = Wethod.Utility.BaseModel.extend({});

  // Order model and collection
  this.orderModel = Wethod.Utility.BaseModel.extend({});

  this.OrdersCollection = Wethod.Utility.BaseCollection.extend({
    model: Report.orderModel,
  });

  this.CompositeOrderModel = Wethod.Utility.BaseModel.extend({});

  var API = {

    getReport: function () {
      var getReport = new Report.ReportModel();
      var defer = $.Deferred();

      getReport.fetch({
        success: function (model) {
          defer.resolve(model);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getProjectReport: function (id) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/report/project/?project_id=' + id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    reportReload: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/report/?cache=clear',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getHoursUsersWeek: function (options) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/report/project/' + options.project_id + '/timetracking-by-employee?from=' + options.from + '&to=' + options.to,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response.data);
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('report:get:all', function () {
    return API.getReport();
  });
  Wethod.reqres.setHandler('report:get:project', function (id) {
    return API.getProjectReport(id);
  });
  Wethod.reqres.setHandler('report:reload', function () {
    return API.reportReload();
  });
  Wethod.reqres.setHandler('report:hours-users:get', function (options) {
    return API.getHoursUsersWeek(options);
  });
});
