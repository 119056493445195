const constants = require('./constants');

module.exports.toggleAlert = (id) => {
  Wethod.request('toggle:settings:alert', { index: id });

  return {
    type: constants.TOGGLE_ALERT,
    id,
  };
};

module.exports.editAlertParam = (param) => {
  Wethod.request('update:settings:alert', {
    index: param.alertId,
    key: param.key,
    value: param.value,
  });

  return {
    type: constants.EDIT_ALERT_PARAM,
  };
};

module.exports.editAlertFailed = (message) => ({
  type: constants.EDIT_ALERT_PARAM_FAILED,
  message,
});

module.exports.editAlertPriority = (priority) => {
  Wethod.request('update:settings:alert:priority', {
    alertId: priority.alertId,
    priorityId: priority.id,
  });
  return {
    type: constants.EDIT_ALERT_PRIORITY,
  };
};

module.exports.filterAlerts = (keyword) => ({
  type: constants.FILTER_ALERTS,
  keyword,
});
