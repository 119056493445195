'use strict';

Wethod.module('SettingsApp.RuleManager', function (RuleManager, Wethod) {
  this.TerminalModel = Wethod.Utility.BaseModel.extend({
    parseToJson: function () {
      var json = {
        type: 'terminal',
        operator: this.get('operator'),
        subject: this.get('left').parseToJson(),
        target: this.get('right').parseToJson(),
      };
      return json;
    },
  });
});
