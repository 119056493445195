'use strict';

/* eslint-disable no-underscore-dangle */

Wethod.module('ReportApp.ProjectChangelog', function (ProjectChangelog, Wethod, Backbone, Marionette, $) {
  this.Layout = Marionette.LayoutView.extend({
    template: '#reportProjecChangelogLayoutTemplate',
    className: 'reportProjectChangelog pageSection',
    ui: {
      headerActions: '.reportProjectChengelog__fixedContent.pageSection',
      spacer: '.reportProjectChengelog__fixedContentPush',
    },
    regions: {
      coll: '[data-region="projectChangelogColl"]',
      listLoading: '[data-region="projectChangelogListLoading"]',
      headerSection: '[data-region="reportSectionHeader"]',
    },
    manageTopContainerBorder: function () {
      $(window).scroll(function () {
        if ($(window).scrollTop()) {
          this.ui.headerActions.css({ marginTop: 0 });
        } else {
          this.ui.headerActions.css({ marginTop: '2px' });
        }
      }.bind(this));
    },
    /**
     * Place fixed header taking care of space occupied by app header and section header.
     */
    placeFixedHeader: function () {
      Wethod.placeFixedHeader(this.ui.headerActions, this.ui.spacer);
    },
    onShow: function () {
      var collRequest = Wethod.request('get:report:project-changelog:coll');
      $.when(collRequest).done(function (collResponse) {
        var collView = new ProjectChangelog.CollView({
          collection: collResponse,
          layout: this,
        });
        this.coll.show(collView);
      }.bind(this));
      this.coll.show(new ProjectChangelog.LoadingView());

      dispatcher.on('app:banner:closed', this.placeFixedHeader.bind(this));
      this.manageTopContainerBorder();
    },
    onDestroy: function () {
      $(window).off('scroll');
    },
  });

  this.ModelView = Marionette.ItemView.extend({
    template: '#reportProjectChangelogModelTemplate',
    tagName: 'li',
    className: 'recordList__record',
  });

  this.EmptyView = Marionette.ItemView.extend({
    template: '#reportProjectChangelogEmptyTemplate',
    className: 'recordList__record',
    tagName: 'li',
  });

  this.CollView = Marionette.CollectionView.extend({
    childView: this.ModelView,
    emptyView: this.EmptyView,
    tagName: 'ul',
    className: 'recordList',
    _loading: false,
    initialize: function () {
      dispatcher.on('html:page:scroll', function () {
        if (this._loading === false) {
          var scroll = $(window).scrollTop();
          var preload = 50;
          var wHeigh = $(window).height();
          var dHeight = $(document).height();

          if (scroll + preload >= dHeight - wHeigh) {
            this._loading = true;
            var region = this.options.layout.regions.listLoading;
            dispatcher.trigger('show:page:loading', region);

            var collRequest = Wethod.request('get:report:project-changelog:coll', this.collection);
            $.when(collRequest).done(function () {
              dispatcher.trigger('remove:page:loading', region);
              if (this.collection.length >= this.collection._offset) {
                this._loading = false; // there are more record to fetch
              }
            }.bind(this));
          }
        }
      }, this, dispatcher);
    },
  });

  this.LoadingView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });
});
