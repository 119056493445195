const React = require('react');

module.exports = class CalendarHeaderColumnMonth extends React.Component {
  getClassName() {
    const isToday = this.props.column.month() === moment().month();
    let name = 'project-canvas-gantt__calendar-header-column project-canvas-gantt__calendar-column--month';
    if (isToday) {
      name += ' project-canvas-gantt__calendar-header-column--today';
    }
    return name;
  }

  getYear() {
    const firstMonthOfYear = this.props.column.month() === 0;
    if (firstMonthOfYear) {
      return (
        <span
          className="project-canvas-gantt__calendar-header-year">
          {this.props.column.format('YYYY')}
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getYear()}
        {this.props.column.format('MMMM')}
      </div>
    );
  }
};
