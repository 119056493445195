/* eslint-disable class-methods-use-this,react/sort-comp */
const React = require('react');
const Trend = require('./TrendsItem.react');

module.exports = class TrendAverageProfitability extends React.Component {
  /**
   * Returns the total value for the given months.
   * @param months
   * @return {number}
   */
  getTotal(months) {
    if (months.length) {
      const initialValue = {
        value: 0,
        projects: 0,
      };

      const total = months.reduce((sum, month) => ({
        value: sum.value + month.total_margin,
        projects: sum.projects + month.projects,
      }), initialValue);

      return total.projects ? (total.value / total.projects) * 100 : 0;
    }
    return null;
  }

  format(value) {
    return `${numeral(value).format('0,0.[0]')} %`;
  }

  tooltipFormatter(point) {
    return `<b>${this.format(point.y)}</b>`;
  }

  getTotalInFrame() {
    const value = this.getTotal(this.props.months);
    return this.format(value);
  }

  render() {
    return (
      <Trend
        totalInFrame={this.getTotalInFrame()}
        tooltipFormatter={this.tooltipFormatter.bind(this)}
        getTotal={this.getTotal.bind(this)}
        title="Avg. Profitability"
        className="client-trend--average-profitability"
        {...this.props} />
    );
  }
};
